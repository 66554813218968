// @flow
import { values, pipe, filter, propEq, prop, both, map } from 'ramda';

import {
  defaultStateDataCurrent,
  getStateCurrentValue,
  createStateCurrentValueFromFirstArray,
  type State,
} from 'app/common/ui-components/filter';

import { GET_ORGS, type OrgItem } from 'app/common/api/requests/food/schools';
import { api } from 'app/api';

import orgTypeFilter from '../orgType';


type OrgItemClear = { id: string, title: string }
type OrgItemMapper = (OrgItem) => OrgItemClear

const orgItems = async (state: State) => {
  const orgType = getStateCurrentValue(orgTypeFilter, state)[0];

  const res = await api.request(GET_ORGS, {
    error: 'Не удалось получить список заведений',
  });

  if (res) {
    const orgs = pipe(
      prop('schools'),
      values,
      filter(both(
        propEq('type', orgType),
        propEq('approved', true),
      )),
      map<OrgItem, OrgItemClear, OrgItemMapper>(({
        id,
        title,
        deleted,
      }) => ({
        id: String(id),
        title: deleted ? `${title} (удалена)` : title,
      })),
    )(res);

    const currentState = (
      orgs.length === 1
        ? createStateCurrentValueFromFirstArray(orgs)
        : defaultStateDataCurrent
    );
    return ([orgs, currentState]);
  }

  return [[], defaultStateDataCurrent];
};

export default orgItems;
