import { useCallback } from 'react'
import { PlainRadioGroup } from 'app/common/ui-next/plain-radio-button'
import { useDataBoxSelector } from 'app/common/data-box-store'
import { orgOrUserFilter, orgOrUserFilterSelection } from 'app/dataProviders/filter-items'
import styles from '../Filters.scss'


type Props = {
  id: string
  pending: boolean
}
export const OrgsOrUserFilter = ({ id, pending }: Props) => {
  const data = useDataBoxSelector(orgOrUserFilter, () => orgOrUserFilter.data)
  const current = useDataBoxSelector(orgOrUserFilterSelection, () => orgOrUserFilterSelection.data)

  const onChangeHandler = useCallback((value: string) => {
    orgOrUserFilterSelection.updateData(() => value)
  }, [])

  return (
    current && <PlainRadioGroup
      id={id}
      label="Выборка покупателя"
      items={data}
      current={current}
      disabled={pending}
      onChange={onChangeHandler}
      className={styles.column}
    />
  )
}
