// @flow
import React, { useCallback, useState } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';
import { DotsLoader } from 'app/common/components/Loader';

import styles from './DishesKitSimple.scss';


type Props = {
  id: number,
  title: string,
  cost: string,
  loading: boolean,
  onRestore: (id: number, title: string) => Promise<boolean>,
}

const DishesKitSimple = ({ id, title, cost, loading, onRestore }: Props) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [restoring, setRestoring] = useState(false);

  const handleCancel = useCallback(() => {
    setConfirmationVisible(false);
  }, []);

  const handleConfirmation = useCallback(() => {
    setConfirmationVisible(true);
  }, []);

  const handleRestore = useCallback(async () => {
    setRestoring(true);
    setConfirmationVisible(false);
    await onRestore(id, title);
    setRestoring(false);
  }, [onRestore, id, title]);

  return (
    <div className={styles.root}>
      {loading
        && <div className={styles.blockedWhileLoaded} />}

      {loading
        && <DotsLoader className={styles.loader} />}

      <div className={styles.header}>
        <div className={styles.headerTitle}>{title}</div>
        <div className={styles.headerCost}>{cost}</div>
      </div>

      <PlainButton onClick={handleConfirmation} disabled={loading || restoring}>
        {restoring ? 'Восстановление...' : 'Восстановить'}
      </PlainButton>

      {confirmationVisible
        && <PlainConfirmModal
          messageText={`Вы уверены, что хотите восстановить комплект «${title}»?`}
          cancelButtonTitle="Отмена"
          confirmButtonTitle="Восстановить"
          onCancel={handleCancel}
          onConfirm={handleRestore}
        />}
    </div>
  );
};

export default DishesKitSimple;
