import { DISHES_FILTER_SET, DISHES_FILTER_CLEAR, DISHES_SEARCH } from 'app/actions/dishes/filter';


const defaultState = {
  searchString: '',
  fields: {},
};

const filter = (state = defaultState, action) => {
  switch (action.type) {
    case DISHES_SEARCH: {
      return { ...state, searchString: action.payload };
    }

    case DISHES_FILTER_SET: {
      return { ...state, fields: { ...action.payload } };
    }

    case DISHES_FILTER_CLEAR: {
      return { ...defaultState, searchString: state.searchString };
    }

    default:
  }
  return state;
};

export default filter;
