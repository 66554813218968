import { createSelector } from 'reselect';


export default createSelector(
  [
    (state, props) => {
      const { restrictions } = props;
      if (restrictions) {
        if (restrictions instanceof Array && restrictions.length) {
          return restrictions.find(item => (
            typeof item.condition === 'function' ? item.condition(state, props) : !!item.condition
          ));
        }
        return (typeof restrictions.condition === 'function'
          ? restrictions.condition(state, props)
          : restrictions.condition
        ) && restrictions;
      }
      return null;
    },
  ],
  restriction => (restriction ? restriction.component : null),
);
