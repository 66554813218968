
// @flow
import type { Request } from '../../index';

type DailySellsUrlParams = {
  orgId: number | string,
}

export const GET_DAILY_SELLS: Request<
  void,
  string,
  DailySellsUrlParams
> = [
  'food/school/{orgId}/daily_sales_sum',
  'get',
  ['data', 'sum'],
];
