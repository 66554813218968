import { useRef, useCallback, useState } from 'react'
import { equals } from 'ramda'

import useCallbackRef from './useCallbackRef'


type Options<T> = {
  initialState?: T,
  onChange?: (value: T) => void
}

export default function useStateUnduplicated<T>(options?: Options<T>): [T | undefined, (value: T) => void] {
  const { initialState, onChange } = options || {} as Options<T>

  const previosStateRef = useRef<T | undefined>(initialState)
  const [state, setState] = useState<T | undefined>(initialState)

  const _onChange = useCallbackRef(onChange)

  const setStateUnduplicated = useCallback((value: T) => {
    if (equals(previosStateRef.current, value)) { return }
    setState(value)
    _onChange.current?.(value)
    previosStateRef.current = value
  }, [_onChange])

  return [state, setStateUnduplicated]
}
