import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { GET_ORGS, convertParams, convertResult } from 'app/common/api/requests/food/schools/get'
import { api } from 'app/api'
import { OrgType } from 'app/dataTypes/org/types'
import { OrgsData } from 'app/dataTypes/org'
import { ItemType } from 'app/common/ui-next/plain-selector'

import { orgTypesFilterSelection } from './orgTypesFilter'


/**
 * Data
*/

export type Orgs = Array<ItemType>
class OrgsFilterDataBox extends DataBox<Orgs> { }

export const defaultOrgs: Orgs = []
export const orgsFilter = createDataBox('orgsFilter', defaultOrgs, OrgsFilterDataBox)

const convertFromExtended = (orgs: OrgsData): Array<ItemType> => {
  const itemsArray = Array.from(orgs.items.values())
  return itemsArray.map(({ ID, title }) => ({ id: ID, title }))
}

export const defaultOrgsDataReceiver = async () => {
  const orgType = orgTypesFilterSelection.getData() as OrgType[]
  const res = await api.request(GET_ORGS, {
    error: 'Не удалось получить данные заведений',
    params: {
      limit: 1000,
      orgType: orgType[0],
      deleted: false,
      // approved: true,
    },
    convertions: {
      convertParams,
      convertResult,
    },
  })
  return res ? convertFromExtended(res) : null
}

orgsFilter.addDataReceiver(defaultOrgsDataReceiver)

/**
 * Selection
 */

export const defaultOrgsSelection: Array<string> = []

export const orgsFilterSelection = createDataBoxDefault<Array<string>>('orgsFilterSelection', defaultOrgsSelection)
