// @flow
import PlainIcon, { type Props } from './plain-icon';
import { iconTypes } from './icon-types';
import { colorTypes } from './colors';


export default PlainIcon;

const iconType = iconTypes;
const iconColor = colorTypes;

export {
  iconTypes, // deprecated
  colorTypes, // deprecated
  iconType,
  iconColor,
};

export type { Props };
