// @flow
import React, { type Node } from 'react';

import moment from 'app/common/lib/momentRange';
import getNumEnding from 'app/common/lib/getNumEnding';

import PlainHelperText from './plain-helper-text';


export type HelperPeriodInfoProps = {
  className?: string,
  dateFrom?: string,
  dateTo?: string,
  dateFormat?: string,
  invalideDateErrorText?: string,
  showPlaceholder?: boolean,
  placeholder?: string,
}

const PlainHelperPeriodInfo = ({
  className,
  dateFrom,
  dateTo,
  dateFormat,
  invalideDateErrorText = 'Заполните верно обе даты',
  showPlaceholder,
  placeholder = '\u00A0',
}: HelperPeriodInfoProps): Node => {
  if (showPlaceholder) {
    return (
      <PlainHelperText className={className}>
        {placeholder}
      </PlainHelperText>
    );
  }


  const mFrom = moment(dateFrom, dateFormat);
  const mTo = moment(dateTo, dateFormat);

  if (!(mFrom.isValid() && mTo.isValid())) {
    return (
      <PlainHelperText className={className} overflowVisible error>
        {invalideDateErrorText}
      </PlainHelperText>
    );
  }

  const days = moment.range(mFrom, mTo).diff('days') + 1;

  return (
    <PlainHelperText className={className} overflowVisible success>
      {`Выбран период в ${days} ${getNumEnding(days, ['день', 'дня', 'дней'])}`}
    </PlainHelperText>
  );
};

export default PlainHelperPeriodInfo;
