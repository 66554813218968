import { useCallback, useMemo } from 'react'

import CommonPage from 'app/common/templates/CommonPage'
import EMoney from 'app/components/finances/EMoney'
import EMoneyLegacy from 'app/components/finances/EMoneyLegacy'
import BySellType from 'app/components/finances/BySellType'
import CashBox from 'app/components/finances/CashBox'
import { EmoneySales } from 'app/components/finances/EmoneySales'
import { getReportTitle, IsReportEnabled } from 'app/components/finances'
import Loading from 'app/common/components/Loader'
import { FinanceReportType as FReport, isFinanceReport } from 'app/dataTypes/reports/finances'

import getBaseUrl from './getBaseUrl'


const rootUrl = getBaseUrl()

type Props = {
  match: {
    params: {
      report: string,
    }
  },
  history: {
    push: Function,
    replace: Function,
  }
}

const FinancePage = ({ match, history }: Props) => {
  const { params: { report } } = match
  const { push } = history

  const redirectToRoot = useCallback(() => {
    push(rootUrl)
  }, [push])

  const reportTitle = useMemo(() => (
    isFinanceReport(report) ? `Отчет: ${getReportTitle(report)}` : 'Неизвестный отчет'
  ), [report])

  return (
    <CommonPage header={reportTitle}>
      <IsReportEnabled
        report={report}
        fallback={<Loading />}
        onReportDisabled={redirectToRoot}
      >
        {report === FReport.EmoneyLegacy && <EMoneyLegacy />}
        {report === FReport.Emoney && <EMoney />}
        {report === FReport.BySellType && <BySellType />}
        {report === FReport.CashBox && <CashBox />}
        {report === FReport.EmoneySales && <EmoneySales />}
      </IsReportEnabled>
    </CommonPage>
  )
}

export default FinancePage
