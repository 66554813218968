import { Ref, ChangeEvent } from 'react'

import { Tr, Td, getPropsHeader } from 'app/common/ui-components/table'
import CellContentFIO from 'app/components/common/cellContents/CellContentFIO'

import { idsFixed, useColumnPropsFixed } from './columns'
import CellContentEmployeePhoto from './CellContentEmployeePhoto'
import type { Item } from './useEAWTableItems'

import CellConstraints from './CellConstraints'


type Props = {
  className?: string,
  settings: Record<string, boolean>,
  reference?: Ref<'tr'>,
  baseUrl: string,
  disabled: boolean,
  writeAccess: boolean,
  onUpdateEmployees: (employeeId?: number) => Promise<void> | void,
  onMouseEnter?: (e: ChangeEvent<HTMLElement>) => void,
  onMouseLeave?: (e: ChangeEvent<HTMLElement>) => void,
} & Item

const EAWTableRowFixed = ({
  className,
  settings,
  reference,
  baseUrl,
  disabled,
  writeAccess,
  onUpdateEmployees,
  onMouseEnter,
  onMouseLeave,
  id,
  photo,
  name,
  nameLastFirst,
  nameMiddle,
  birthdate,
  employeeType,
}: Props) => {
  const headers = getPropsHeader()
  const props = useColumnPropsFixed()

  const content = [
    <CellContentEmployeePhoto
      photoUrl={photo}
      name={name}
      employeeId={id}
      onUpdateEmployees={onUpdateEmployees}
      disabled={!writeAccess}
    />,
    <CellContentFIO
      linkTo={`${baseUrl}/${employeeType}/employee/${id}`}
      nameLastFirst={nameLastFirst}
      nameMiddle={nameMiddle}
      birthdate={birthdate}
    />,
  ]

  return (
    <Tr
      className={className}
      reference={reference}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      interlineBorder
    >
      {idsFixed.map((columnId, i) => (
        settings[columnId]
          ? <Td
            key={columnId}
            {...props[columnId]}
            {...headers(columnId)}
          >
            <CellConstraints extended={!!photo}>
              {content[i]}
            </CellConstraints>
          </Td>
          : null
      ))}
    </Tr>
  )
}

export default EAWTableRowFixed
