import {
  SplittingType,
  AllocationType,
  SplitTypeID,
  defaultSplitItem,
  isAllocationType,
  SplitItem,
} from 'app/common/api/requests/food/subsidy_split'

import SubsidiesData from './SubsidiesData'
import useSubsidiesData from './useSubsidiesData'


export default SubsidiesData

export {
  useSubsidiesData,
  SplitTypeID,
  SplittingType,
  AllocationType,
  defaultSplitItem,
  isAllocationType,
}

export type { SplitItem }
