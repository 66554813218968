import evolve from 'ramda/es/evolve';
import map from 'ramda/es/map';
import addIndex from 'ramda/es/addIndex';

import { CONFIRMATION_SHOW, CONFIRMATION_HIDE, CONFIRMATION_UPDATE_BUTTONS } from 'app/common/actions/confirmation';


const mapIndexed = addIndex(map);

const defaultState = {
  text: '',
  buttons: [],
};

const confirmation = (state = defaultState, action) => {
  switch (action.type) {
    case CONFIRMATION_SHOW: {
      return { ...action.payload };
    }
    case CONFIRMATION_HIDE: {
      return defaultState;
    }
    case CONFIRMATION_UPDATE_BUTTONS: {
      return evolve({
        buttons: mapIndexed((props, i) => ({ ...props, ...action.payload[i] })),
      }, state);
    }
    default:
  }
  return state;
};

export default confirmation;
