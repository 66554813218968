import React from 'react';
import styles from './IconTimeOut.scss';


const IconTimeOut = props => (
  <svg viewBox="0 0 140 140" width="140px" height="140px" {...props}>
    <path className={styles.back} d="M139.894 69.999c0-38.66-31.316-70-69.946-70C31.316 0 0 31.339 0 69.999C0 108.659 31.316 140 69.947 140 C108.577 140 139.894 108.659 139.894 69.999" />
    <path className={styles.front} d="M140 20.009c0-9.74-7.89-17.636-17.622-17.636s-17.623 7.896-17.623 17.636 c0 9.74 7.891 17.636 17.623 17.636S140 29.749 140 20.009" />
    <path className={styles.front} d="M72.006 99.218v-5.202h-4.267v5.202c0 1.179-0.955 2.134-2.132 2.134h-6.148 c-1.178 0-2.133 0.956-2.133 2.136c0 1.179 0.955 2.134 2.133 2.134h8.281h4.267h8.281c1.178 0 2.133-0.955 2.133-2.134 c0-1.18-0.955-2.136-2.133-2.136h-6.148C72.961 101.352 72.006 100.396 72.006 99.218" />
    <path className={styles.front} d="M105.436 45.975H34.31c-1.54 0-2.788 1.248-2.788 2.789v39.187c0 1.541 1.248 2.789 2.788 2.789h71.125 c1.539 0 2.787-1.248 2.787-2.789V48.764C108.223 47.223 106.975 45.975 105.436 45.975" />
    <path className={styles.back} d="M63.66 62.72c0-1.648-1.335-2.984-2.982-2.984s-2.982 1.336-2.982 2.984c0 1.649 1.335 2.984 2.982 2.984 S63.66 64.369 63.66 62.72" />
    <path className={styles.back} d="M82.05 62.72c0-1.648-1.335-2.984-2.982-2.984c-1.646 0-2.982 1.336-2.982 2.984 c0 1.649 1.336 2.984 2.982 2.984C80.715 65.705 82.05 64.369 82.05 62.72" />
    <path className={styles.back} d="M83.502 82.013c-0.743 0.596-1.812 0.591-2.522-0.043c-2.571-2.289-6.664-3.664-11.106-3.664 c-4.444 0-8.536 1.375-11.107 3.664c-0.71 0.634-1.779 0.639-2.523 0.043c-0.966-0.772-1.001-2.236-0.069-3.051 c3.31-2.899 8.339-4.636 13.699-4.636c5.358 0 10.389 1.736 13.698 4.636C84.503 79.776 84.468 81.24 83.502 82.013" />
    <path className={styles.frontStrocked} strokeWidth="2" strokeMiterlimit="10" d="M106.841 42.7H32.904 c-2.573 0-4.66 2.088-4.66 4.663v41.99c0 2.575 2.087 4.663 4.66 4.663h73.937c2.574 0 4.66-2.088 4.66-4.663v-41.99 C111.501 44.788 109.415 42.7 106.841 42.7z" />
    <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M123.752 10.633 c-2.87-0.473-5.918 0.399-8.13 2.613c-2.444 2.446-3.252 5.91-2.422 9.029" />
    <polygon fill="#FFFFFF" points="124.211 6.799 128.834 13.246 120.942 14.029" />
    <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M121.005 29.385 c2.869 0.473 5.917-0.398 8.13-2.613c2.76-2.762 3.433-6.821 2.017-10.215" />
    <polygon fill="#FFFFFF" points="120.545 33.218 115.923 26.771 123.813 25.988 " />
  </svg>
);

export default IconTimeOut;
