import api from 'app/actions/api';

export const KIT_RESTORE_REQUEST = 'orgs/kits/KIT_RESTORE_REQUEST';
export const KIT_RESTORE_SUCCESS = 'orgs/kits/KIT_RESTORE_SUCCESS';
export const KIT_RESTORE_FAILURE = 'orgs/kits/KIT_RESTORE_FAILURE';

export const kitRestoreRequest = payload => ({
  type: KIT_RESTORE_REQUEST,
  payload,
});

export const kitRestoreSuccess = payload => ({
  type: KIT_RESTORE_SUCCESS,
  payload,
});

export const kitRestoreFailure = payload => ({
  type: KIT_RESTORE_FAILURE,
  payload,
});

export const kitRestore = (orgId, kitId) => async (dispatch) => {
  dispatch(kitRestoreRequest({ orgId, kitId }));
  try {
    await api(`food/schools/${orgId}/complexes/${kitId}/restore`, 'post');
    dispatch(kitRestoreSuccess({ orgId, kitId }));
    return true;
  } catch (error) {
    dispatch(kitRestoreFailure({ err: 'Не удалось восстановить комплект', error }));
    return false;
  }
};
