// @flow
import React, { useEffect, useState, useCallback } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import Loading from 'app/common/components/Loader';
import EmptyWarn from 'app/common/layouts/EmptyWarn';

import useProductList from './useProductList';
import ProductListTable from './ProductListTable';
import styles from './ProductList.scss';


const pauseBeforeLoad = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

type Props = {
  className?: string,
  orgId: number,
  orderId: number,
}

const ProductList = ({ className, orgId, orderId }: Props) => {
  const [state, { request }] = useProductList({ orgId });

  const [loading, setLoading] = useState(true);
  const [readyToLoad, setReadyToLoad] = useState<number | null>(null);

  const handlePrepareRequest = useCallback(async () => {
    setLoading(true);
    // пауза перед запросом данных, так как развернутая строка таблицы
    // может быстро быть переключена при управлении с клавиатуры
    await pauseBeforeLoad(300);
    setReadyToLoad(orderId);
  }, [orderId]);

  const handleFinalizeRequest = useCallback(async () => {
    if (readyToLoad && readyToLoad === orderId) {
      await request(orderId);
      setLoading(false);
    }
  }, [readyToLoad, orderId, request]);

  useEffect(() => {
    handleFinalizeRequest();
  }, [handleFinalizeRequest]);

  useEffect(() => {
    handlePrepareRequest();
  }, [handlePrepareRequest]);

  const nothing = isEmpty(state);

  return (
    <div className={className}>
      {loading
        && <Loading className={styles.loading} />}

      {!loading
        && nothing
        && <EmptyWarn>{'Нет данных'}</EmptyWarn>}

      {!loading
        && !nothing
        && <ProductListTable data={state} />}
    </div>
  );
};

export default ProductList;
