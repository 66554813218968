// @flow
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../ui/Button';
import styles from './Warning.scss';

type Props = {
  onBack: (e: SyntheticEvent<HTMLElement>) => void,
  onAccept: (e: SyntheticEvent<HTMLElement>) => void,
  header: string,
  text: string,
  children: Node,
  disabled?: boolean,
  acceptBtText?: string,
  error?: string,
};

const Warning = ({ onBack, header, text, children, disabled, onAccept, acceptBtText, error }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <a href="back" className={styles.back} onClick={onBack}>{'←'}</a>
      <div className={styles.exclamation}>{'!'}</div>
      <div className={styles.header}>{header}</div>
      <div className={styles.text}>{text}</div>
      <div className={styles.additional}>
        {children}
      </div>
      {error && <div className={styles.error}>{error}</div>}
      <Button className={styles.submit} disabled={disabled} onClick={onAccept}>
        {acceptBtText || t('login:warning_modal_submit_bt')}
      </Button>
    </div>
  );
};

export default Warning;
