// @flow
import React from 'react';
import classNames from 'classnames';

import PlainInputFocused from 'app/common/ui/PlainInput/PlainInputFocused';
import PlainInputIcon from 'app/common/ui/PlainInputIcon';
import nope from 'app/common/lib/nope';

import styles from './PlainInputSearch.scss';


type Props = {
  id?: string,
  className?: string,
  placeholder?: string,
  searchString: string,
  handleSearch: (e: SyntheticEvent<HTMLInputElement>) => void,
  handleClearSearch: (e: SyntheticEvent<HTMLButtonElement>) => void,
  disabled?: boolean,
}

const PlainInputSearch = ({
  id,
  className,
  placeholder = 'Поиск',
  searchString,
  handleSearch,
  handleClearSearch,
  disabled,
}: Props) => {
  const rootClassName = classNames(styles.search, className);

  return (
    <form role="search" onSubmit={nope} className={rootClassName}>
      <PlainInputFocused
        id={id}
        component={PlainInputIcon}
        placeholder={placeholder}
        onChange={handleSearch}
        value={searchString}
        disabled={disabled}
        icon={searchString
          && <button
            type="button"
            className={styles.clearSearch}
            onClick={handleClearSearch}
          >
            {'×'}
          </button>}
      />
    </form>
  );
};

export default PlainInputSearch;
