import { CompensationInfoStatus } from 'app/dataTypes/compensation/info'


export const cancelAvailable = (status: CompensationInfoStatus) => {
  return status === CompensationInfoStatus.Ready
}

export const recordAvailable = (status: CompensationInfoStatus) => {
  return (
    status !== CompensationInfoStatus.InProgress
    && status !== CompensationInfoStatus.Ready
  )
}
