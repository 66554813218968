// @flow
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';


const groupItems = () => ([[{
  id: 'group_purchases',
  title: 'типу продаж',
}], defaultStateDataCurrent]);

export default groupItems;
