// @flow
import { useState, useCallback } from 'react';
import { path } from 'ramda';

import { POST_VENDING_ITEMS_CATEGORIES, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/categories/post';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';
import { api } from 'app/api';


export default function useCategoryCreate(placeId?: string, onSuccess?: (c: Category) => void): {
  createCategory: (f: HTMLFormElement) => Promise<boolean>,
  submitting: boolean,
  errorMessage: string,
  category: string,
  handleChangeInput: (e: SyntheticEvent<HTMLInputElement>) => void,
  reset: () => void,
} {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [category, setCategory] = useState('');

  const handleChangeInput = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setCategory(value);
  }, []);

  const reset = useCallback(() => {
    setCategory('');
    setErrorMessage('');
  }, []);

  const createCategory = useCallback(async (form: HTMLFormElement) => {
    const title = path(['category', 'value'], form);

    if (placeId && title) {
      setSubmitting(true);
      const res = await api.request(POST_VENDING_ITEMS_CATEGORIES, {
        error: (error: Error) => {
          const responseStatus = path(['response', 'status'], error);
          if (responseStatus === 422) {
            const errorText = 'Такая категория уже существует';
            setErrorMessage(errorText);
            return errorText;
          }
          const errorText = 'Не удалось cоздать категорию';
          setErrorMessage(errorText);
          return errorText;
        },

        params: {
          placeId,
          title,
          index: 0,
        },
        convertions: {
          convertParams,
          convertResult,
        },
      });

      if (res) {
        if (onSuccess) {
          onSuccess(res);
        }
        setSubmitting(false);
        return true;
      }
      setSubmitting(false);
    }

    return false;
  }, [placeId, onSuccess]);

  return {
    createCategory,
    submitting,
    errorMessage,
    category,
    handleChangeInput,
    reset,
  };
}
