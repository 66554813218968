// @flow
import * as React from 'react';
import Sticky from 'react-sticky-el';
import classNames from 'classnames/bind';
import path from 'ramda/es/path';

import StickyContext from './StickyContext';
import { sumTo, MAX_STICKY_NESTING } from './helpers';
import styles from './Sticky.scss';

const cx = classNames.bind(styles);

// DEPRECATED, для старых браузеров
/**
 * Компонент Sticky, расширяет react-sticky-el до
 * возможности применения несколько раз на одной странице
 * в разных компонентах, учитывая высоту уже прилипших частей
 * Только 5 элементов с прилипанием на странице (можно увеличить в withElHeightArray)

 * Новый элемент Sticky, его новые props:
 *    shadowClassName -- класс стилей тени
 *    number -- номер прилипаемого элемента
 *    так же можно использовать props от react-sticky-el
 *    trackState -- отслеживать состояние прилипания (timer)
 *
 * Не забыть натянуть withStickyRows на родительский компонент в котором будут
 * использоваться прилипающие заголовки Sticky
 */

// onFixedToggle - тормозит переключение состояния, поэтому таймер

type StProps = {
  ro: {
    observe: (el?: React.Ref<any>) => void,
  },
  number: number,
  topShift: number,
  children: any,
  onStickyToggle: Function,
  stickyClassName?: string,
  shadowClassName?: string,
  isShadow?: boolean,
  className?: string,
  itemsSticky: Array<boolean>,
  trackState?: boolean,
  stopUseGPU?: boolean,
}

class St extends React.Component<StProps> {
  wrapper: React.ElementRef<Sticky> | null;

  timer: any | null;

  constructor() {
    super();
    this.rf = this.rf.bind(this);
    this.refSticky = this.refSticky.bind(this);
    this.checkFixedState = this.checkFixedState.bind(this);
  }

  componentDidMount() {
    const { trackState } = this.props;
    if (trackState) {
      this.timer = setInterval(this.checkFixedState, 60);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  checkFixedState() {
    const { itemsSticky, number, onStickyToggle } = this.props;
    if (number < 0) return;
    const fixed = path(['wrapperEl', 'style', 'position'], this.wrapper) === 'fixed';
    if (itemsSticky[number - 1] !== fixed) {
      onStickyToggle(number, fixed);
    }
  }

  rf(el: React.ElementRef<'div'> | null) {
    const { ro } = this.props;
    if (el && ro) {
      ro.observe(el);
    }
  }

  refSticky(ref: React.ElementRef<Sticky> | null) {
    this.wrapper = ref;
  }

  render() {
    const {
      topShift,
      children,
      onStickyToggle,
      stickyClassName,
      shadowClassName,
      isShadow,
      number,
      className,
      trackState,
      itemsSticky,
      stopUseGPU,
      ...props
    } = this.props;

    return (
      // styles.fixMinHeight -- начинает проскакивать вверх при раскомментировании
      <div ref={this.rf} id={number}/* className={styles.fixMinHeight} */>
        <Sticky
          ref={this.refSticky}
          topOffset={-topShift}
          stickyStyle={topShift ? { marginTop: topShift } : {}}
          stickyClassName={classNames(isShadow ? shadowClassName : null, stickyClassName)}
          className={cx({ GPUDisable: stopUseGPU }, className)}
          holderProps={{ style: { minHeight: 0 } }}
          style={{ position: 'relative' }}
          hideOnBoundaryHit={false}
          boundaryElement=".block"
          // $FlowFixMe
          {...props}
        >
          {children}
        </Sticky>
      </div>
    );
  }
}

type StickyProps = {
  children: any,
  number?: number,
  trackState?: boolean,
  stopUseGPU?: boolean,
}

const NewSticky = ({ children, number = 1, trackState, stopUseGPU, ...props }: StickyProps) => (
  <StickyContext.Consumer>
    {({ elHeights, ro, itemsSticky, onStickyToggle }) => {
      const topShift = sumTo(number, elHeights);
      return (
        <St
          trackState={trackState}
          ro={ro}
          topShift={topShift}
          number={number}
          isShadow={!itemsSticky[number]} // следующий элемент прилип
          itemsSticky={itemsSticky}
          onStickyToggle={onStickyToggle}
          stopUseGPU={stopUseGPU}
          // $FlowFixMe
          {...props}
        >
          {typeof children === 'function'
            ? children(itemsSticky[number > 0 ? number - 1 : MAX_STICKY_NESTING - 1])
            : children}
        </St>
      );
    }}
  </StickyContext.Consumer>
);

export default NewSticky;
