export enum FullNameType {
  LastFM = 'last_F_M',
  LastFirstMiddle = 'last_first_middle',
  LastFirst = 'last_first'
}

export class Name {
  readonly first: string

  readonly last: string

  readonly middle: string

  readonly gender: 'male' | 'female' | 'androgynous'

  constructor({ first, last, middle, gender }: {
    first: string,
    last: string,
    middle: string,
    gender: 'male' | 'female' | 'androgynous'
  }) {
    this.first = first
    this.last = last
    this.middle = middle
    this.gender = gender
  }

  get F(): string {
    return this.first[0] || ''
  }

  get L(): string {
    return this.last[0] || ''
  }

  get M(): string {
    return this.middle[0] || ''
  }

  toString = (stringType: FullNameType = FullNameType.LastFirstMiddle) => {
    const nameKeys = stringType.split('_')
    return (nameKeys.map((nameKey): string => (this[nameKey] || '')).join(' ').trim()
      || this.first)
  }
}
