import { createSelector } from 'reselect';

import get from 'app/common/lib/get';
// import selectMenuCategories from 'app/selectors/OrgsList/selectMenuCategories';
import selectMenuSellPoints from 'app/selectors/OrgsList/selectMenuSellPoints';


const selectProps = createSelector(
  [
    (state, { kitId }) => get(
      state,
      ['orgs', 'kits', 'items', kitId, 'title'],
      'неизвестный комплект',
    ),
    selectMenuSellPoints,
    // selectMenuCategories,
  ],
  (kitName, sellPoints/* , categories */) => ({
    kitName,
    sellPoints,
    // categories,
  }),
);

export default selectProps;
