
import { OrgType } from 'app/dataTypes/org/types'

import useOrgGroups from './useOrgGroups'
import useOrgGroupsActions from './useOrgGroupsActions'
import useLocked from './useLocked'


type Options = {
  onError?: (key: string, value: string) => void
  onSuccessDeleteGroup?: (orgGroupID: string) => void
}

export default function useOrgGroupsWithActions(orgType?: OrgType, options?: Options) {
  const { onError, onSuccessDeleteGroup } = options || {}
  const { orgGroupsByOrgType, loading, updateOrgGroups, request } = useOrgGroups()

  const [locked, lockItem] = useLocked()
  const actions = useOrgGroupsActions(orgType, {
    onSuccess: updateOrgGroups,
    onError,
    onLockOrgGroup: lockItem,
    onSuccessDeleteGroup,
  })

  return {
    orgGroupsByOrgType,
    loading,
    request,
    locked,
    ...actions,
  }
}
