import type { Rights } from 'app/common/constants/rights'


export const allGranted: Rights = {
  Заявки: {
    Заявки: {
      read: true,
      edit: true,
    },
  },
  Продажи: {
    Продажи: {
      read: true,
      edit: true,
    },
  },
  Пользователи: {
    Учащиеся: {
      read: true,
      edit: true,
    },
    'Мои сотрудники': {
      read: true,
      edit: true,
    },
    'Сотрудники ОУ': {
      read: true,
      edit: true,
    },
  },
  Настройки: {
    'Категории меню': {
      read: true,
      edit: true,
    },
    Реквизиты: {
      read: true,
      edit: true,
    },
    'Настройки ОУ': {
      read: true,
      edit: true,
    },
    Данные: {
      read: true,
      edit: true,
    },
    Сеть: {
      read: true,
      edit: true,
    },
    Устройства: {
      read: true,
      edit: true,
    },
    Система: {
      read: true,
      edit: true,
    },
    Дотации: {
      read: true,
      edit: true,
    },
    Дополнительно: {
      read: true,
      edit: true,
    },
  },
  Меню: {
    Меню: {
      read: true,
      edit: true,
    },
  },
  Блюда: {
    'Категории блюд': {
      read: true,
      edit: true,
    },
    'Блюда школы': {
      read: true,
      edit: true,
    },
    'Блюда поставщика': {
      read: true,
      edit: true,
    },
  },
  Комплекты: {
    Комплекты: {
      read: true,
      edit: true,
    },
    'Состав комплектов': {
      read: true,
      edit: true,
    },
  },
  Касса: {
    Касса: {
      read: true,
      edit: true,
    },
  },
}

export default allGranted
