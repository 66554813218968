// @flow
import type { ParamsPOST } from 'app/dataTypes/vending/PlanogramCopy.types';

import type { Request } from '../../../../../index';


type ResultApi = 'success'

type UrlParams = {
  fromMachineId: string,
  toMachineId: string,
}

export const convertParams = (params: ParamsPOST): [null, UrlParams] => {
  const { fromMachineId, toMachineId } = params;

  return [null, { fromMachineId, toMachineId }];
};

export const POST_COPY_PLANORGAM_SLOTS: Request<
  ParamsPOST,
  ResultApi,
  UrlParams
> = [
  'data/vending/machines/{fromMachineId}/slots/copy/{toMachineId}',
  'post',
  ['data', 'result'],
];
