// @flow
import { columns } from './columns';


export type HeaderCells = Array<{
  key: string,
  value: string,
  alignLeft?: boolean,
  alignRight?: boolean,
  alignCenter?: boolean,
}>

export const cells: HeaderCells = [{
  key: columns[0],
  value: '',
  alignLeft: true,
}, {
  key: columns[1],
  value: '№\u00A0ячейки',
  alignLeft: true,
}, {
  key: columns[2],
  value: 'Наименование',
  alignLeft: true,
}, {
  key: columns[3],
  value: 'Фото',
  alignCenter: true,
}, {
  key: columns[4],
  value: 'Кол-во на\u00A0остатке',
  alignCenter: true,
}, {
  key: columns[5],
  value: 'Будет добавлено',
  alignCenter: true,
}, {
  key: columns[6],
  value: 'Станет после добавления',
  alignCenter: true,
}];

export default function useHeaderCellsData(headers?: Array<string>): HeaderCells {
  if (headers) {
    return cells.map((props, i) => ({ ...props, value: headers[i] }));
  }
  return cells;
}
