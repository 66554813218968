// @flow
import React, { type Node, type ElementRef, Component } from 'react';
import path from 'ramda/es/path';
import classNames from 'classnames/bind';
import replace from 'ramda/es/replace';

import IconPen from 'app/common/icons/IconPen';
import { DotsLoader } from 'app/common/components/Loader';
import setInputSelection from 'app/common/lib/setInputSelection';

import IconSave from './icons/IconSave';
import styles from './EditableProp.scss';


// DEPRECATED
const cx = classNames.bind(styles);
type Props = {
  className?: string,
  value: string,
  name: string,
  errorText?: string,
  onSave?: (Object) => Promise<void>,
  handleSave?: (Object, Function) => void,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void, // нет внутреннего state для value
  middle?: boolean,
  right?: boolean,
  justify?: boolean,
  children?: (s: boolean, v: string, c: Function) => Node,
};

type State = {
  editing: boolean,
  saving: boolean,
  value: string,
  error: string,
  showChildDialog: boolean,
};

class EditableProp extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { value } = props;
    this.state = {
      editing: false,
      saving: false,
      value, // если нет onChange, используется state.value
      error: '',
      showChildDialog: false,
    };
    this.handleEditSave = this.handleEditSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCloseChildDialog = this.handleCloseChildDialog.bind(this);
    this.inputRef = this.inputRef.bind(this);
    this.editingRef = this.editingRef.bind(this);
    this.spanRef = this.spanRef.bind(this);
    this.save = this.save.bind(this);
  }

  // $FlowFixMe
  UNSAFE_componentWillReceiveProps(nextProps: Props) { // eslint-disable-line
    const { value } = this.props;
    if (nextProps.value !== value) {
      this.setState({ value: nextProps.value });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { editing } = this.state;
    const { input, editingWin, span } = this;
    if (!input || !editingWin || !span) return;
    if ((prevState.editing !== editing) && editing) {
      // if (document.body) {
      //   document.body.classList.add('ReactModal__Body--open');
      // }
      input.focus();

      const inputRect = input.getBoundingClientRect();
      const spanRect = span.getBoundingClientRect();

      const {
        // left: inputLeft,
        top: inputTop,
        bottom: inputBottom,
        // right: inputRight,
      } = inputRect;

      const {
        // left: spanLeft,
        top: spanTop,
        bottom: spanBottom,
        right: spanRight,
      } = spanRect;

      const dx = 10;
      const dy = 10;
      const height = inputBottom - inputTop + 2 * dy; // eslint-disable-line no-mixed-operators
      const width = 100 + dx;
      const left = spanRight - width;
      const top = (spanTop + spanBottom) / 2 - height / 2;

      const widthSaveIcon = 20;
      const widthSave = 8 + widthSaveIcon + dx; // 8 - margin-right у кнопки сохранения // eslint-disable-line no-mixed-operators

      editingWin.style.left = `${left}px`;
      editingWin.style.width = `${width + widthSave}px`;
      editingWin.style.top = `${top}px`;
      editingWin.style.height = `${height}px`;
      input.style.width = '100px';
    }
  }

  handleEditSave(e: SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    const { saving } = this.state;
    if (!saving) {
      this.setState(({ editing }) => ({ editing: !editing }));
    }
  }

  handleChange(e: SyntheticEvent<HTMLInputElement>) {
    const { value: val } = e.currentTarget;
    const value = replace(',', '.', val);
    this.setState({ value });
  }

  // Сохранение происходит при потере фокуса, если при этом нажали кнопку сохранения
  // или в handleKeyDown при нажатии Enter
  async handleBlur(e: SyntheticEvent<HTMLInputElement>) {
    const { saving } = this.state;
    const { name } = this.props;
    const receivedName = String(name);
    if (saving) {
      e.preventDefault();
      return;
    }

    // Объект, на который щелкнули должен быть кнопкой save
    const targetName = path(['relatedTarget', 'name'], e);
    if (targetName !== receivedName) {
      this.setState({ editing: false });
      return;
    }
    this.save();
  }

  handleFocus(e: SyntheticEvent<HTMLInputElement>) { // eslint-disable-line class-methods-use-this
    const elem = e.currentTarget;
    const { value } = elem;
    setInputSelection(0, value.length, elem);
  }

  handleKeyDown(e: SyntheticKeyboardEvent<HTMLElement>) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.save();
    }
  }

  handleCloseChildDialog(e: SyntheticEvent<HTMLElement>) {
    if (e && e.preventDefault) e.preventDefault();
    this.setState({
      showChildDialog: false,
    });
  }

  async save() {
    const {
      onSave,
      onChange,
      name,
      value,
      children,
      handleSave,
    } = this.props;
    const { value: stateValue } = this.state;
    const sintEvent = {
      target: {
        name,
        value: onChange ? value : stateValue,
      },
      currentTarget: {
        name,
        value: onChange ? value : stateValue,
      },
    };

    // сохранение через children (рекомендуется использовать,
    // если нужен какой то выбор параметров перед сохранением)
    if (typeof children === 'function') {
      this.setState({
        showChildDialog: true,
        editing: false,
      });
    }

    // сохранение через callback
    if (handleSave) {
      this.setState({ saving: true });
      handleSave(sintEvent, () => {
        this.setState({ saving: false, editing: false });
      });
      return;
    }

    // сохранение через Promise (asinc/await)
    if (onSave) {
      try {
        this.setState({ saving: true });
        await onSave(sintEvent); // async, save must be success
      } catch (error) {
        this.setState({ error: 'Не удалось сохранить' });
      }
      this.setState({ saving: false, editing: false });
    }
  }

  inputRef(el: ElementRef<'input'> | null) {
    this.input = el;
  }

  editingRef(el: ElementRef<'div'> | null) {
    this.editingWin = el;
  }

  spanRef(el: ElementRef<'span'> | null) {
    this.span = el;
  }

  input: ElementRef<'input'> | null; // eslint-disable-line

  editingWin: ElementRef<'div'> | null;

  span: ElementRef<'span'> | null;

  render() {
    const {
      middle,
      right,
      justify,
      className,
      onChange,
      value,
      name,
      errorText,
      children,
    } = this.props;

    const {
      editing,
      saving,
      error,
      showChildDialog,
      value: stateValue,
    } = this.state;

    return (
      <div>
        <div
          className={cx({
            content: true,
            middle,
            right,
            justify,
          }, className)}
        >
          <div className={styles.text}>
            {editing
              && <div className={styles.editing} ref={this.editingRef}>
                <input
                  value={onChange ? value : stateValue}
                  className={styles.input}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  ref={this.inputRef}
                  onChange={onChange || this.handleChange}
                  onKeyDown={this.handleKeyDown}
                />
                <button name={name} onClick={this.handleEditSave} type="button" className={styles.button}>
                  <SaveBt saving={saving} />
                </button>
              </div>}
            <span ref={this.spanRef}>{value}</span>
          </div>
          <div className={styles.control}>
            <button
              name={name}
              onClick={this.handleEditSave}
              type="button"
              className={styles.button}
              data-focus="keep"
            >
              <IconPen
                className={styles.iconPen}
                data-focus="keep"
              />
            </button>
          </div>
        </div>
        {(errorText || error)
          && <div className={styles.errorText}>
            {errorText || error}
          </div>}
        {typeof children === 'function' && children(
          showChildDialog,
          stateValue,
          this.handleCloseChildDialog,
        )}
      </div>
    );
  }
}

const SaveBt = ({ saving }: { saving: boolean }) => (saving
  ? <DotsLoader simple />
  : <IconSave className={styles.iconSave} />);

export default EditableProp;
