// @flow
import { useCallback } from 'react';

import cssStickyRoot from './css-sticky-root';


type Props = {
  number: number,
  stickyClassName?: string,
  shadowClassName?: string,
  skipNextShift?: boolean,
}

export default function useSticky({ number, stickyClassName, shadowClassName, skipNextShift }: Props) {
  const handleChangeStickyState = useCallback((
    node: HTMLElement,
    sticked: boolean,
    nextItemSticked?: boolean = false,
  ) => {
    if (stickyClassName) {
      if (sticked) {
        node.className = `${node.className} ${stickyClassName}`; // eslint-disable-line no-param-reassign
      } else {
        node.className = node // eslint-disable-line no-param-reassign
          .className
          .split(' ')
          .filter(classN => (classN !== stickyClassName))
          .join(' ');
      }
    }

    if (shadowClassName) {
      if (sticked && !nextItemSticked) {
        node.className = `${node.className} ${shadowClassName}`; // eslint-disable-line no-param-reassign
      } else {
        node.className = node // eslint-disable-line no-param-reassign
          .className
          .split(' ')
          .filter(classN => (classN !== shadowClassName))
          .join(' ');
      }
    }
  }, [stickyClassName, shadowClassName]);


  const handleStickyRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      cssStickyRoot.observe(node);
      cssStickyRoot.addStickyStateHandler(number, handleChangeStickyState);
      if (skipNextShift) {
        cssStickyRoot.addShiftSkip(number);
      }
    }
  }, [handleChangeStickyState, number, skipNextShift]);

  return handleStickyRef;
}
