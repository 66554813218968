// @flow
import React, { type Node } from 'react';

import styles from './plain-radio-button.scss';


export type LevelShiftProps = {
  children: Node,
}

const LevelShift = ({ children }: LevelShiftProps): Node => {
  return (
    <div className={styles.level1}>
      {children}
    </div>
  );
};

export default LevelShift;
