// @flow
import { useMemo } from 'react';
// import moment from 'moment-timezone';

import { useFilterData } from 'app/common/ui-components/filter';
// import { getValue } from 'app/common/ui-components/filter';
import { reportTypes } from 'app/common/api/requests/reports/perform';

import generateParamsByOrgs from './generateParamsByOrgs';
import generateParamsOptions from './generateParamsOptions';
import type { FilterParams } from '../FilterParams.type';
// import periodFilter, { dateFormat as dateFormatPeriod } from '../ReportFilter/filters/period';
import type { Params } from './types';


export default function useGenerateParams(): [Params, boolean] {
  const [,,, { getApplied }, filterParams] = useFilterData<FilterParams>();

  const res: [Params, boolean] = useMemo(() => {
    const applied = getApplied();

    if (applied) {
      const [options, onlymail] = generateParamsOptions(applied, filterParams);

      const params = generateParamsByOrgs(applied);

      // const [, to] = getValue(periodFilter, applied);
      // const mDateTo = moment(to, dateFormatPeriod);
      const aggregated = false;

      // const aggregated = (
      //   params.details_group === 'by_group_complexes'
      //   && !mDateTo.isSame(moment(), 'month')
      // );

      return [{
        report_type: (
          aggregated
            ? reportTypes.REPOST_PURCHASES_BY_GROUP_COMPLEXES_AGGREGATED
            : reportTypes.REPORT_PURCHASES_BY_GROUP_COMPLEXES_2
        ),
        params,
        options,
      }, onlymail];
    }
    return [null, false];
  }, [getApplied, filterParams]);

  return res;
}
