// @flow
import { replace, update } from 'ramda';

import { priceRe } from 'app/common/constants/regex';

import styles from './commonBalanceProps.scss';


const onInputsUpdateDataPrepare = (
  index: number,
  value: string,
  current: Array<string>,
): Array<string> => {
  const replacedDot = replace(/,/, '.', value);
  const replacedMinus = replace(/[-−\-–]/, '-', replacedDot);

  if (priceRe.test(replacedMinus)) {
    return update(index, replacedMinus, current);
  }
  return current;
};

const onInputsUpdateAfterBlurDataPrepare = (
  index: number,
  value: string,
  current: Array<string>,
): Array<string> => {
  const [, min, max] = current;
  const parsedMin = parseFloat(min);
  const parsedMax = parseFloat(max);
  if (index === 1) {
    return update(index, String(parsedMin > parsedMax ? parsedMax : parsedMin), current);
  }
  if (index === 2) {
    return update(index, String(parsedMax < parsedMin ? parsedMin : parsedMax), current);
  }
  return current;
};

const commonBalanceItems = {
  label: 'Значение баланса',
  inputClassName: styles.commonBalanceInput,
  onInputsUpdateDataPrepare,
  onInputsUpdateAfterBlurDataPrepare,
};

export default commonBalanceItems;
