// @flow
import { useCallback } from 'react';
import { toPairs, groupBy, prop, map, filter, find, propEq, isEmpty } from 'ramda';

import { PUT_VENDING_ITEMS_CATEGORIES, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/categories/put';
import { api } from 'app/api';
import type { Categories, Category } from 'app/dataTypes/vending/production/Categories.types';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';


const getCategoryTitle = (categoryId: string, categories: Categories): string => (
  (find(propEq('id', categoryId), categories) || { title: '???' }).title
);

export default function useDelete(
  dishes: Array<Dish>,
  onSuccess: (category: Array<Category>) => void,
  categories: Categories,
): (d?: Dish) => Promise<boolean> {
  const deleteDishes = useCallback(async (removeDishDirectly?: Dish) => {
    const removeThemAll: Array<Dish> = removeDishDirectly ? [removeDishDirectly] : dishes;
    const pairsByCategoryId = toPairs<Array<Dish>, { [key: string]: Array<Dish> }>(
      groupBy(prop('categoryId'), removeThemAll),
    );

    const resAll = await Promise.all(pairsByCategoryId.map(([categoryId, categoryDishes]) => (
      api.request(PUT_VENDING_ITEMS_CATEGORIES, {
        error: `Не удалось удалить продукцию из категории «${getCategoryTitle(categoryId, categories)}»`,
        params: {
          categoryId,
          removeFoodItems: map(prop('foodItemId'), categoryDishes),
        },
        convertions: {
          convertParams,
          convertResult,
        },
      })
    )));

    // $FlowFixMe
    const res: Array<Category> = filter(Boolean, resAll);

    if (!isEmpty(res)) {
      onSuccess(res);
      return true;
    }
    return false;
  }, [dishes, onSuccess, categories]);

  return deleteDishes;
}
