import { useMemo } from 'react'
import { filter } from 'ramda'

import * as rootRights from 'app/common/constants/rights/rootRights'
import * as specificRights from 'app/common/constants/rights/specificRights'
import { RightsPathUnrestricted } from 'app/rights/types'
import { OrgType } from 'app/dataTypes/org/types'

import { pageTypes, PageType } from '../pageTypes'


const allOrgTypes = [OrgType.School, OrgType.Preschool, OrgType.HighSchool, OrgType.Enterprise]
const exceptEnterprise = [OrgType.School, OrgType.Preschool, OrgType.HighSchool]
const createBasePath = (url: string) => url
const createBasePathWithDate = (url: string, date?: string) => (
  date ? `${url}/${date}` : url
)

type MenuItem = {
  type: PageType,
  title: string,
  orgTypes: Array<OrgType>,
  rightsPath: RightsPathUnrestricted
  createBasePath: (url: string, date?: string) => string,
  othersPaths?: Array<RightsPathUnrestricted>
}

const defaultMenuItemProps = {
  orgTypes: allOrgTypes,
  createBasePath,
}

const menuTitles: Array<MenuItem> = [{
  ...defaultMenuItemProps,
  type: pageTypes.menu,
  title: 'Меню буфета',
  orgTypes: exceptEnterprise,
  rightsPath: [rootRights.MENU, specificRights.MENU] as const,
  createBasePath: createBasePathWithDate,
}, {
  ...defaultMenuItemProps,
  type: pageTypes.kits,
  title: 'Комплекты',
  rightsPath: [rootRights.COMPLEXES, specificRights.COMPLEXES] as const,
  createBasePath: createBasePathWithDate,
}, {
  ...defaultMenuItemProps,
  type: pageTypes.dishes,
  title: 'Продукция',
  rightsPath: [rootRights.DISHES, specificRights.SCHOOL_DISHES] as const,
  othersPaths: [[rootRights.DISHES, specificRights.DISH_CATEGORIES] as const],
}, {
  ...defaultMenuItemProps,
  type: pageTypes.orders,
  title: 'Заявки',
  rightsPath: [rootRights.ORDERS, specificRights.ORDERS] as const,
}, {
  ...defaultMenuItemProps,
  type: pageTypes.settings,
  title: 'Настройки',
  rightsPath: [rootRights.SETTINGS, specificRights.SCHOOL_SETTINGS] as const,
}]

export default function useNavigationItems(orgType: OrgType) {
  const res = useMemo(() => {
    return filter(({ orgTypes }) => orgTypes.includes(orgType), menuTitles)
  }, [orgType])

  return res
}
