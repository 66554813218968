import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import { packFilterSet, packFilterClear } from 'app/actions/reports/filters/pack';
import { mailFilterSet, MAIL_AND_VIEW } from 'app/actions/reports/filters/mail';
import { itemsNum } from 'app/common/lib/object';
import withReset from 'app/components/Reports/hocs/withReset';


const selectIsEnabled = createSelector(
  [
    state => state.reports.filters.orgs.selected,
    state => state.reports.filters.details,
  ],
  (orgsSelected, details) => {
    if (!details) {
      return false;
    }
    const itemsN = itemsNum(orgsSelected);
    return (itemsN > 1 || itemsN === 0);
  },
);

export default compose(
  connect(
    state => ({
      pack: state.reports.filters.pack,
      enabled: selectIsEnabled(state),
      isEmail: !!state.user.email,
    }),
    dispatch => ({
      handleChange: (e) => {
        const { checked } = e.currentTarget;
        dispatch(packFilterSet(checked));
        if (checked) {
          dispatch(mailFilterSet(MAIL_AND_VIEW));
        }
      },
      reset: () => {
        dispatch(packFilterClear());
      },
    }),
  ),

  withReset,

  branch(({ enabled }) => !enabled, renderNothing),
);
