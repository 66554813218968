// @flow
import React from 'react';
import cn from 'classnames/bind';

import styles from './month-selector-item.scss';


const cx = cn.bind(styles);

type Props = {
  id: string,
  children: string,
  current?: boolean,
  disabled?: boolean,
}

const MonthSelectorItem = ({ id, children, current, disabled }: Props) => {
  return (
    <span
      id={id}
      className={cx(styles.item, { item__current: current, disabled })}
      disabled={disabled}
    >
      {children}
    </span>
  );
};

export default MonthSelectorItem;
