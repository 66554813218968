// @flow
import React from 'react';

import OveflowedText from 'app/common/components/OveflowedText';

import styles from './PlainList.scss';


type Props = {
  title: Array<string>,
  className?: string,
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
}

const ViewTitleAsArray = ({ title, className, onClick }: Props) => (
  <div // eslint-disable-line jsx-a11y/click-events-have-key-events
    className={className}
    onClick={onClick}
  >
    <OveflowedText>
      {`${title[0]}\xa0/`}
      <span className={styles.titleOpt}>
        {' '}
        {title[1]}
      </span>
    </OveflowedText>
  </div>
);

export default ViewTitleAsArray;
