import api from 'app/actions/api';

export const KIT_DELETE_REQUEST = 'orgs/kits/KIT_DELETE_REQUEST';
export const KIT_DELETE_SUCCESS = 'orgs/kits/KIT_DELETE_SUCCESS';
export const KIT_DELETE_FAILURE = 'orgs/kits/KIT_DELETE_FAILURE';

export const kitDeleteRequest = payload => ({
  type: KIT_DELETE_REQUEST,
  payload,
});

export const kitDeleteSuccess = payload => ({
  type: KIT_DELETE_SUCCESS,
  payload,
});

export const kitDeleteFailure = payload => ({
  type: KIT_DELETE_FAILURE,
  payload,
});

export const kitDelete = (orgId, kitId) => async (dispatch) => {
  dispatch(kitDeleteRequest({ orgId, kitId }));
  try {
    await api(`food/schools/${orgId}/complexes/${kitId}`, 'delete');
    dispatch(kitDeleteSuccess({ orgId, kitId }));
    return true;
  } catch (error) {
    dispatch(kitDeleteFailure({ err: 'Не удалось удалить комплект', error }));
    return false;
  }
};
