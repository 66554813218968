import { Ref } from 'react'

import { Tr, Th, getPropsId } from 'app/common/ui-components/table'
import { Settings } from 'app/reducers/settings/employeesAtWork'

import { idsMain, titlesMain, useColumnPropsMain } from './columns'


type Props = {
  className?: string,
  settings: Settings,
  reference?: Ref<'tr'>,
  hidden?: boolean,
  dummy?: boolean,
}

const EAWTableTHeadMain = ({
  className,
  settings,
  reference,
  hidden,
  dummy,
}: Props) => {
  const cellProps = getPropsId(dummy)
  const props = useColumnPropsMain()

  return (
    <Tr
      className={className}
      reference={reference}
      hidden={hidden}
      header
    >
      {idsMain.map((id, i) => (
        settings[id]
          ? <Th
            key={id}
            {...props}
            {...cellProps(id)}
          >
            {titlesMain[i]}
          </Th>
          : null
      ))}
    </Tr>
  )
}

export default EAWTableTHeadMain
