import { ChangeEvent, useCallback, useMemo } from 'react'
import { isEmpty, path, reduce } from 'ramda'

import { Loading } from 'app/common/templates-next/page-template'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'

import CopySettings from '../../CopySettings'
import CopySettingsContainer from '../../common/CopySettingsContainer'
import { useRequestsData } from '../RequestsData'
import useIgnoreTime, { Data } from './useIgnoreTime'
import RoleItem from '../../common/RoleItem'
import { packId, unpackId } from '../../common/helpers'

import styles from './IgnoreTime.scss'


const DAYS_MAX = 365 as const
const DAYS_MIN = 1 as const

type Props = {
  disabled: boolean
}

const IgnoreTime = ({ disabled }: Props) => {
  const {
    content: {
      oldOrdersRestrictions: {
        loading,
        school,
        errors,
      },
    },
    modify: {
      oldOrdersRestrictions: {
        updateField,
      },
    },
  } = useRequestsData()

  const actualItems = useMemo(() => {
    return school.filter(({ role_title: title }) => (
      /Администратор/.test(title)
      || /Ответственный за питание/.test(title)
    ))
  }, [school])

  const requestItems = useMemo(() => {
    return reduce((acc, {
      id,
      ignore_time: ignoreTime,
      current_period_without_restrictions,
      current_period_days,
      role_id,
    }) => {
      acc[String(id)] = {
        ignore_time: !!ignoreTime,
        current_period_without_restrictions,
        current_period_days,
        role_id,
        role_type: 'school',
      }
      return acc
    }, {} as Data, actualItems)
  }, [actualItems])

  const submit = useIgnoreTime({ data: requestItems })

  const handleChangeCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget
    updateField(parseInt(value, 10), 'ignore_time', checked)
  }, [updateField])

  const dayChange = useCallback((days, from) => {
    const [period, id] = unpackId(from)
    updateField(id, `${period}_period_days`, days)
  }, [updateField])

  const restrictionEnable = useCallback((checked, from) => {
    const [period, id] = unpackId(from)
    updateField(id, `${period}_period_without_restrictions`, checked)
  }, [updateField])


  const nothing = isEmpty(school)

  return (
    <CopySettingsContainer>
      <div>
        {'Исключение ограничений на создание и\u00A0редактирование заявок. Укажите количество дней, в\u00A0течение которых сотрудникам можно работать с\u00A0заявками после завершения дня:'}

        {loading && nothing && <Loading />}

        {actualItems
          .map(({
            id,
            ignore_time: ignoreTime,
            role_title: roleTitle,
            current_period_without_restrictions: currentPeriodWithoutRestrictions,
            current_period_days: currentPeriodDays,
          }) => {
            const error = path([id, 'past'], errors)

            return (
              <div className={styles.row} key={id}>
                <PlainCheckbox
                  id={`current_${id}`}
                  value={String(id)}
                  className={styles.checkbox}
                  label={roleTitle}
                  checked={ignoreTime}
                  disabled={disabled}
                  onChange={handleChangeCheckbox}
                  hideLabel
                />

                <RoleItem
                  key={id}
                  className={styles.shift}
                  id={packId(id, 'current')}
                  title={roleTitle}
                  disabled={disabled || !ignoreTime}
                  days={currentPeriodDays}
                  maxDays={DAYS_MAX}
                  minDays={DAYS_MIN}
                  error={error}
                  withoutRestrictions={currentPeriodWithoutRestrictions}
                  onChangeDays={dayChange}
                  onChangeRestriction={restrictionEnable}
                />
              </div>
            )
          })}
      </div>

      {!disabled
        && <CopySettings onSubmit={submit} />}
    </CopySettingsContainer>
  )
}

export default IgnoreTime
