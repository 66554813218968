// flow
import * as React from 'react';
import classNames from 'classnames/bind';
import defaultProps from 'recompose/defaultProps';
import componentFromProp from 'recompose/componentFromProp';
import renameProp from 'recompose/renameProp';
import Tooltip from 'app/common/ui/Tooltip';

import { DotsLoader } from 'app/common/components/Loader';
import ErrorString from 'app/common/ui/ErrorString';

import InputCharsCount from './InputCharsCount';
import styles from './PlainInput.scss';


const cx = classNames.bind(styles);
const DefaultInput = renameProp('reference', 'ref')('input');
const enhance = defaultProps({ component: DefaultInput });
const Input = enhance(componentFromProp('component'));

export type Props = {
  id?: string,
  label?: string,
  value?: string,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  isError?: boolean,
  isValid?: boolean,
  errorText?: string,
  style?: Object,
  labelStyle?: Object,
  placeholder?: string,
  required?: boolean,
  className?: string,
  disabled?: boolean,
  loading?: boolean,
  centered?: boolean,
  name?: string,
  reference?: (ref: HTMLInputElement | null) => void,
  component?: React.ElementType,
  hideErrorString?: boolean,
  maxChars?: number,
};

const PlainInput = ({
  id,
  label,
  style,
  value,
  onChange,
  onBlur,
  isError,
  isValid,
  errorText,
  labelStyle,
  placeholder,
  required,
  className,
  disabled,
  loading,
  centered,
  name,
  reference,
  component,
  hideErrorString,
  maxChars,
  ...other
}: Props) => (
  <div className={className} style={style}>
    {label
      && <label htmlFor={id} className={styles.label} style={labelStyle}>
        {label}
        {required && <span className={styles.star}>{'&nbsp;*'}</span>}
      </label>}
    <Tooltip text={disabled ? value : ''}>
      <div className={styles.inputArea}>
        <Input
          className={cx(styles.input, {
            error: isError,
            success: isValid,
            disabled: disabled || loading,
            centered,
          })}
          name={name || id}
          id={id}
          value={value || value === 0 ? value : ''}
          onChange={onChange}
          onBlur={onBlur}
          style={style}
          placeholder={placeholder}
          readOnly={disabled || loading}
          reference={reference}
          component={component}
          disabled={disabled}
          {...other}
        />
        {maxChars && <InputCharsCount count={value.length} maxCount={maxChars} />}
        {loading && <DotsLoader className={styles.loader} />}
      </div>
    </Tooltip>
    {!hideErrorString // не показывать отступ для текста ошибки под элементом
      && <ErrorString messageMode={!isError}>{errorText}</ErrorString>}
  </div>
);

export default PlainInput;
