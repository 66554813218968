// @flow
import isEmpty from 'ramda/es/isEmpty';
import head from 'ramda/es/head';
import map from 'ramda/es/map';

import { defaultStateDataCurrent } from 'app/common/ui-components/filter';
import { GET_ORG_TYPES, orgTypes } from 'app/common/api/requests/food/school_types';
import { api } from 'app/api';


export { orgTypes };

export const arrange = [
  orgTypes.PRESCHOOL,
  orgTypes.SCHOOL,
  orgTypes.HIGH_SCHOOL,
  orgTypes.ENTERPRISE,
];

const titles = {
  [orgTypes.PRESCHOOL]: 'Дошкольное образовательное учреждение (ДОУ)',
  [orgTypes.SCHOOL]: 'Общеобразовательное учреждение (ОУ)',
  [orgTypes.HIGH_SCHOOL]: 'Образовательное учреждение высшего образования (ВУЗ)',
  [orgTypes.ENTERPRISE]: 'Предприятие',
};

const orgTypeItems = async () => {
  const res = await api.request(GET_ORG_TYPES, {
    error: 'Не удалось получить типы заведений',
  });

  if (res && !isEmpty(res)) {
    const items = map(id => ({
      id,
      title: titles[id] || id,
    }), res);

    const selected = (
      items[orgTypes.SCHOOL]
        ? orgTypes.SCHOOL
        : head(res)
    );

    return ([items, [selected]]);
  }
  return [[], defaultStateDataCurrent];
};

export default orgTypeItems;
