// @flow
import React, { type Node, useMemo, useCallback } from 'react';
import { range } from 'ramda';

import Rulers from '../_parts/rulers';
import { useTheme, type Theme } from '../_parts/planogram-theme';
import { PlanogramData, PlanogramItem } from '../_parts/planogram-data-class';
import { defaultTheme, defaultThemeEditionMode } from './default-theme';
import ItemTemplate, { type ProductData, type SlotData } from './item-template';

import styles from './planogram-storage.scss';


const defaultEmptyDataContent = <div>{'Планограмма: данные отсутствуют'}</div>;
const emtySet = new Set();
const emptyPlanogram = new PlanogramData();

export type PlanogramStorageProps = {
  children?: Node,
  editMode?: boolean,
  selectionMode?: boolean,
  selectedSlots?: Set<string>,
  theme?: Theme,
  className?: string,
  data?: PlanogramData<PlanogramItem>,
  onSlotsSelect?: (Array<{ slotId: string, selected: boolean }>) => void,
  onSlotVisible?: (id: string) => ProductData | null | Promise<ProductData | null>,
  onProductIdChange?: (id: string) => ProductData | null | Promise<ProductData | null>,
  onClickAddProduct?: (slotId: string) => void,
  onClickIncreaseProduct?: (product: ProductData, slot: SlotData) => void,
  onClickDecreaseProduct?: (product: ProductData, slot: SlotData) => void,
}

const PlanogramStorage = ({
  children = defaultEmptyDataContent,
  editMode = false,
  selectionMode,
  selectedSlots = emtySet,
  theme,
  className,
  data = emptyPlanogram,
  onSlotsSelect,
  onSlotVisible,
  onProductIdChange,
  onClickAddProduct,
  onClickIncreaseProduct,
  onClickDecreaseProduct,
}: PlanogramStorageProps): Node => {
  const currentTheme = theme || (editMode ? defaultThemeEditionMode : defaultTheme);
  const containerWithThemeRef = useTheme(currentTheme);

  const [selectedColumns, selectedRows] = useMemo(() => {
    const allColumns = new Set(range(0, data.columns));
    const allRows = new Set(range(0, data.rows));

    data.forEachSlot(({ column, row, width, id }) => {
      if (selectedSlots.has(id)) return;
      allRows.delete(row);
      allColumns.delete(column);
      if (width > 1) {
        let excess = width - 1;
        while (excess > 0) {
          excess -= 1;
          allColumns.delete(column + excess);
        }
      }
    });
    return [allColumns, allRows];
  }, [data, selectedSlots]);

  const handleSelectColumn = useCallback((checked: boolean, index: number) => {
    if (onSlotsSelect) {
      const res = [];
      data.forEachSlot(({ column, width, id }) => {
        if (index === column || index === column + width - 1) {
          res.push({ slotId: id, selected: checked });
        }
      });
      onSlotsSelect(res);
    }
  }, [data, onSlotsSelect]);

  if (data.empty) {
    return children;
  }

  return (
    <Rulers
      className={className}
      columns={data.columns}
      rows={data.rows}
      reference={containerWithThemeRef}
      selectionMode={selectionMode}
      selectedColumns={selectedColumns}
      selectedRows={selectedRows}
      onSelectColumn={handleSelectColumn}
      onSelectRow={() => {}}
    >
      {data.map((row, i) => (
        <div className={styles.row} key={i}>
          {row.map(({
            id,
            maxGoods,
            width,
            height,
            column: columnIndex,
            row: rowIndex,
          }: PlanogramItem) => {
            const { amount, productId } = data.contains[rowIndex][columnIndex];
            const name = data.naming[rowIndex][columnIndex];
            const goods = amount === null ? '0' : String(amount);

            return (
              <ItemTemplate
                key={id}
                slotId={id}
                editMode={editMode}
                number={name}
                amount={goods}
                amountMax={String(maxGoods)}
                productId={productId}
                colorDanger={amount === 0}
                colorMiddle={amount !== null && (amount > 0 && amount < maxGoods)}
                colorFull={amount === maxGoods}
                colorNeutral={amount === null}
                widthFactor={width}
                heightFactor={height}
                selectionMode={selectionMode}
                selected={selectedSlots.has(id)}
                onSelect={onSlotsSelect}
                onSlotVisible={onSlotVisible}
                onProductIdChange={onProductIdChange}
                onClickAddProduct={onClickAddProduct}
                onClickIncreaseProduct={onClickIncreaseProduct}
                onClickDecreaseProduct={onClickDecreaseProduct}
              />
            );
          })}
        </div>
      ))}
    </Rulers>
  );
};

export default PlanogramStorage;
