// @flow
import { useCallback } from 'react';

import type { Item } from './useAddRemoveState';


type Options = {
  alreadyAdded: Map<string, any>,
  willAddItems: Map<string, Item>,
  willRemoveItems: Map<string, Item>,
  setItemAsAdded: (item: Item) => void,
  setItemAsRemoved: (item: Item) => void,
  restoreAsAdded: (item: Item) => void,
  restoreAsNotAdded: (item: Item) => void,
}

export default function useHandleAddRemove({
  alreadyAdded,
  setItemAsAdded,
  setItemAsRemoved,
  restoreAsAdded,
  restoreAsNotAdded,
}: Options): (id: string, checked: boolean, title: string) => void {
  const handleCheck = useCallback((id: string, checked: boolean, title: string) => {
    if (alreadyAdded.has(id)) {
      if (checked) {
        restoreAsAdded({ id, title });
        return;
      }
      setItemAsRemoved({ id, title });
      return;
    }
    if (checked) {
      setItemAsAdded({ id, title });
      return;
    }
    restoreAsNotAdded({ id, title });
  }, [
    alreadyAdded,
    setItemAsAdded,
    setItemAsRemoved,
    restoreAsAdded,
    restoreAsNotAdded,
  ]);

  return handleCheck;
}
