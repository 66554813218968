
// @flow
import pipe from 'ramda/es/pipe';
import path from 'ramda/es/path';
import evolve from 'ramda/es/evolve';
import map from 'ramda/es/map';

import api from 'app/actions/api';
import action from 'app/common/lib/action';
import { getLastFirstM } from 'app/common/lib/names';


export const SALES_DATA_REQUEST = 'reports/SALES_DATA_REQUEST';
export const SALES_DATA_SUCCESS = 'reports/SALES_DATA_SUCCESS';
export const SALES_DATA_FAILURE = 'reports/SALES_DATA_FAILURE';
export const SALES_DATA_CLEAR = 'reports/SALES_DATA_CLEAR';


const mapPurchases = map(({
  customer_name: name,
  ...other
}) => ({
  fio: getLastFirstM(name), // преобразуем сразу для сортировки
  ...other,
}));

const convertResult = pipe(
  path(['data', 'data']),
  evolve({
    purchases: mapPurchases,
  }),
);

export const salesDataFetch = (reportId: number) => async (dispatch: Function) => {
  const params = { report_id: reportId };

  dispatch(action(SALES_DATA_REQUEST, reportId));

  try {
    const res = await api('reports/data', 'get', { params });
    const data = convertResult(res);
    dispatch(action(SALES_DATA_SUCCESS, data));
  } catch (error) {
    dispatch(action(
      SALES_DATA_FAILURE,
      { err: 'Не удалось получить данные отчета', error },
    ));
  }
};
