import {
  DO_NOT_MAIL,
  MAIL_FILTER_SET,
  MAIL_FILTER_DEFAULT,
} from 'app/actions/reports/filters/mail';


const defaultState = DO_NOT_MAIL;

const group = (state = defaultState, { type, payload }) => {
  if (type === MAIL_FILTER_SET) {
    return payload;
  }
  if (type === MAIL_FILTER_DEFAULT) {
    return defaultState;
  }
  return state;
};

export default group;
