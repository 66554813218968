// @flow
import React, { type Node } from 'react';

import { Table, Tr, Th } from 'app/common/ui-components/table';


export const columnId = 'header_school_title';
export const columnHeaderText = 'Школа';

type Props = {
  className: string,
}

const HeaderLeftContent = ({ className }: Props): Node => {
  return (
    <Table className={className}>
      <thead>
        <Tr header>
          <Th scope="col" id={columnId} alignLeft>
            {columnHeaderText}
          </Th>
        </Tr>
      </thead>
    </Table>
  );
};

export default HeaderLeftContent;
