import dissoc from 'ramda/es/dissoc';

import { KITS_SUCCESS_DELETED, KITS_STATE_CLEAR } from 'app/actions/orgs/kits';
import { KIT_RESTORE_SUCCESS } from 'app/actions/orgs/kits/restore';


const defaultState = {};

const itemsDeleted = (state = defaultState, action) => {
  if (action.type === KITS_SUCCESS_DELETED) {
    return { ...action.payload };
  }
  if (action.type === KITS_STATE_CLEAR) {
    return defaultState;
  }
  if (action.type === KIT_RESTORE_SUCCESS) {
    return dissoc(`${action.payload.kitId}`, state);
  }
  return state;
};

export default itemsDeleted;
