import nest from 'recompose/nest';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import PlainFilter, { FilterOptionsModal } from 'app/common/ui/PlainFilter';
import withModal from 'app/common/containers/withModal';

import withEmployees from './hocs/withEmployees';
import OpenModal from './OpenModal';
import styles from './FilterItems.scss';


const filterProps = {
  className: styles.filter,
  title: 'Сотрудник:',
  maxRows: 3,
};

const modalProps = {
  header: 'Выбор сотрудника',
  enableSelectAll: true,
  nothingIsAll: true,
};

const TheEmployeesFilter = nest(
  withProps(filterProps)(PlainFilter),
  withProps(modalProps)(
    nest(OpenModal, FilterOptionsModal),
  ),
);

export default compose(
  withModal,
  withEmployees,
)(TheEmployeesFilter);
