// @flow
import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation, Trans } from 'react-i18next';

import PlainPassword from 'app/common/ui/PlainPassword';
import Button from 'app/common/ui/Button';
import Modal from 'app/common/components/Modal';
import type { passValidType } from 'app/common/lib/validate/password';
import Circle from 'app/common/ui/Circle';

import enhancePasswordChange from './enhancePasswordChange';
import styles from './PasswordChange.scss';


const cx = classNames.bind(styles);

type Props = {
  currentPassword: string,
  newPassword1: string,
  newPassword2: string,
  change: (e: SyntheticEvent<HTMLInputElement>) => void,
  pass1ValidProps: passValidType,
  pass1Valid: boolean,
  pass2Valid: boolean,
  oldPassValid: boolean,
  save: (e: SyntheticEvent<HTMLElement>) => void,
  saving: boolean,
  oldPassError: string,
  close: (e: SyntheticEvent<HTMLElement>) => void,
};

const PasswordChange = ({
  currentPassword,
  newPassword1,
  newPassword2,
  change,
  pass1ValidProps: { lowercase, uppercase, numbers, amount },
  pass1Valid,
  pass2Valid,
  oldPassValid,
  save,
  saving,
  oldPassError,
  close,
}: Props) => {
  const { t } = useTranslation('profile');

  return (
    <Modal close={close}>
      <form onSubmit={save} className={styles.root}>
        <h2>
          <Trans i18nKey="profile:password_modal_header">
            {'Смена пароля'}
          </Trans>
        </h2>
        <h3 className={styles.old}>
          <Trans i18nKey="profile:password_modal_header_current">
            {'Текущий пароль'}
          </Trans>
        </h3>
        <PlainPassword
          name="password"
          id="password"
          placeholder={t('password_modal_enter_current_label')}
          value={currentPassword}
          onChange={change}
          isError={!!oldPassError}
          errorText={oldPassError}
        />
        <h3 className={styles.new}>
          <Trans i18nKey="profile:password_modal_header_new">
            {'Новый пароль'}
          </Trans>
        </h3>
        <div className={styles.label}>
          <Trans i18nKey="profile:password_modal_must">
            {'Пароль должен содержать'}
          </Trans>
          {':'}
        </div>
        <div className={styles.strings}>
          <div className={cx({ valid: lowercase })}>
            <Circle success={lowercase} error={!lowercase} />
            {' '}
            <Trans i18nKey="profile:password_modal_must_eng_smalls">
              {'Английские маленькие буквы'}
            </Trans>
          </div>
          <div className={cx({ valid: uppercase })}>
            <Circle success={uppercase} error={!uppercase} />
            {' '}
            <Trans i18nKey="profile:password_modal_must_engs_bigs">
              {'Английские большие буквы'}
            </Trans>
          </div>
          <div className={cx({ valid: numbers })}>
            <Circle success={numbers} error={!numbers} />
            {' '}
            <Trans i18nKey="profile:password_modal_must_number">
              {'Минимум одну цифру'}
            </Trans>
          </div>
          <div className={cx({ valid: amount })}>
            <Circle success={amount} error={!amount} />
            {' '}
            <Trans i18nKey="profile:password_modal_must_more_then_8">
              {'Более 8 символов'}
            </Trans>
          </div>
        </div>

        <div className={styles.input}>
          <PlainPassword
            name="newpassword1"
            placeholder={t('password_modal_enter_new')}
            value={newPassword1}
            onChange={change}
            isValid={pass1Valid}
          />
        </div>
        <div className={styles.input}>
          <PlainPassword
            name="newpassword2"
            value={newPassword2}
            placeholder={t('password_modal_repeat_new')}
            onChange={change}
            isValid={pass2Valid}
          />
        </div>
        {newPassword1 && !pass1Valid && <div className={styles.errorLine}>
          <Trans i18nKey="profile:password_modal_error_not_safe">
            {'Не выполнены условия безопасного пароля!'}
          </Trans>
        </div>}
        {newPassword2 && !pass2Valid && <div className={styles.errorLine}>
          <Trans i18nKey="profile:password_modal_error_not_equals">
            {'Пароли не совпадают!'}
          </Trans>
        </div>}
        <div className={styles.button}>
          <Button onClick={close} value={t('back_bt')} className={styles.close} outline />
          <Button
            submit
            value={t('submit_change_bt')}
            disabled={!(pass1Valid && pass2Valid && oldPassValid && !saving)}
          />
        </div>
      </form>
    </Modal>
  );
};

export default enhancePasswordChange(PasswordChange);
