// @flow
import React, { type Node, Fragment, useState, useCallback } from 'react';

import PlainMessageModal from 'app/common/ui-next/plain-message-modal';

import { NumberInput } from './number-input';
import { PlanogramItem, createPlanogramItem } from '../_parts/planogram-data-class';


type Props = {
  item: PlanogramItem,
  goods: number,
  onChange: (PlanogramItem) => Promise<void> | void,
  onBeginEdit: (id: string) => void,
  onStopEdit: (id: string) => void,
}

const MaxGoodsControl = ({ item, goods, onChange, onBeginEdit, onStopEdit }: Props): Node => {
  const { id, maxGoods } = item;
  const [maxGoodsError, setMaxGoodsError] = useState(false);

  const handleChangeMaxGoods = useCallback((newValue: number) => {
    if (newValue < goods) {
      setMaxGoodsError(true);
      return item.maxGoods;
    }
    onChange(createPlanogramItem({ ...item, maxGoods: newValue }));
    return newValue;
  }, [item, onChange, goods]);

  return (
    <Fragment>
      <NumberInput
        id={id}
        value={maxGoods}
        onChange={handleChangeMaxGoods}
        onBeginEdit={onBeginEdit}
        onStopEdit={onStopEdit}
      />
      {maxGoodsError
        && <PlainMessageModal
          onClose={setMaxGoodsError}
          messageText={'Максимальное число вмещаемости товара\nне может быть меньше количества товара на остатке.'}
          closeButtonTitle="OK"
          externalState
        />}
    </Fragment>
  );
};

export default MaxGoodsControl;
