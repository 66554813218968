// @flow
import React, { type Node, useMemo, useState, useCallback } from 'react';

import RequestsContext from './RequestsContext';
import useGeneralSettings from './useGeneralSettings';
import useOrdersTimeRestrictions from './timeRestriction/useOrdersTimeRestrictions';
import useOldOrdersRestrictions from './useOldOrdersRestrictions';


export type RequestsDataProps = {
  children?: Node,
  orgId: number,
}

/**
 *  Данные формы и методы работы с ними
 */

const RequestsData = ({ children, orgId }: RequestsDataProps) => {
  const [submitting, setSubmitting] = useState(false);

  const [
    generalSettings,
    generalSettingsRequests,
    generalSettingsModify,
  ] = useGeneralSettings(orgId);

  const [
    timeRestrictions,
    timeRestrictionsRequests,
    timeRestrictionsModify,
  ] = useOrdersTimeRestrictions(orgId);

  const [
    oldOrdersRestrictions,
    oldOrdersRestrictionsRequests,
    oldOrdersRestrictionsModify,
  ] = useOldOrdersRestrictions(orgId);


  // Восстановление данных формы (запрос всех данных)
  const handleRequestAll = useCallback(async () => {
    await Promise.all([
      generalSettingsRequests.request(),
      timeRestrictionsRequests.request(),
      oldOrdersRestrictionsRequests.request(),
    ]);
  }, [
    generalSettingsRequests,
    timeRestrictionsRequests,
    oldOrdersRestrictionsRequests,
  ]);

  // Проверка правильности введения данных
  const handleCheckErrors = useCallback(() => {
    return (
      generalSettingsRequests.checkErrors()
      && timeRestrictionsRequests.checkErrors()
      && oldOrdersRestrictionsRequests.checkErrors()
    );
  }, [generalSettingsRequests, timeRestrictionsRequests, oldOrdersRestrictionsRequests]);


  // Запрос отправки всех данных
  const handleSubmitAll = useCallback(async (overrideOrgIds?: Array<number>) => {
    setSubmitting(true);
    const [res1, res2, res3] = await Promise.all([
      generalSettingsRequests.submit(overrideOrgIds),
      timeRestrictionsRequests.submit(overrideOrgIds),
      oldOrdersRestrictionsRequests.submit(overrideOrgIds),
    ]);
    setSubmitting(false);
    return res1 && res2 && res3;
  }, [
    generalSettingsRequests,
    timeRestrictionsRequests,
    oldOrdersRestrictionsRequests,
  ]);


  const content = useMemo(() => ({
    generalSettings,
    timeRestrictions,
    oldOrdersRestrictions,
    submitting,
  }), [
    generalSettings,
    timeRestrictions,
    oldOrdersRestrictions,
    submitting,
  ]);

  const request = useMemo(() => ({
    generalSettings: generalSettingsRequests,
    timeRestrictions: timeRestrictionsRequests,
    oldOrdersRestrictions: oldOrdersRestrictionsRequests,
    submitAll: handleSubmitAll,
    requestAll: handleRequestAll,
    checkErrors: handleCheckErrors,
  }), [
    generalSettingsRequests,
    timeRestrictionsRequests,
    oldOrdersRestrictionsRequests,
    handleSubmitAll,
    handleRequestAll,
    handleCheckErrors,
  ]);

  const modify = useMemo(() => ({
    generalSettings: generalSettingsModify,
    timeRestrictions: timeRestrictionsModify,
    oldOrdersRestrictions: oldOrdersRestrictionsModify,
  }), [
    generalSettingsModify,
    timeRestrictionsModify,
    oldOrdersRestrictionsModify,
  ]);

  const contextValue = {
    content,
    request,
    modify,
  };


  return (
    <RequestsContext.Provider value={contextValue}>
      {children}
    </RequestsContext.Provider>
  );
};

export default RequestsData;
