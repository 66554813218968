/* eslint-disable ft-flow/no-types-missing-file-annotation */
import { connect } from 'react-redux';
import omit from 'ramda/es/omit';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';
import type { HOC } from 'recompose';

import selectTypes from 'app/selectors/Settings/Dishes/selectTypes';
import type { ImageParamsType } from 'app/common/components/ImagePrepare/ImagePrepare';
import {
  showConfirmation as showConfirmationAction,
  hideConfirmation as hideConfirmationAction,
} from 'app/common/actions/confirmation';
import { popupErr as popupErrAction, popup as popupAction } from 'app/common/actions/popup';


// TODO когда нибудь убрать этот бред :), сделать через props
type Actions = {
  dishesChangeItem?: (e: SyntheticEvent<HTMLElement>) => void, // редактирование настроек, если не задано, то не будет соотв. иконки
  dishesRemoveItem: (e: SyntheticEvent<HTMLElement>) => void, // удаление блюда
  dishesFieldEdit: (any) => Promise<boolean>, // async Изменение одного поля блюда для текущего раздела
  dishesFieldEditOriginal: (any) => Promise<boolean>, // async Изменение одного поля блюда в оригинальном блюде (изображение и тип)
  dishesAddToSelection: (string | number) => void, // добавление в выделенные по id
  dishesRemoveFromSelection: (string | number) => void, // удаление из выделенных по id
  getDeleteText: (void) => string, // получение текста для предупреждении об удалении (нажатие х)
};

type Props = {
  connectActions: (Function, { id: string }) => Actions,
  getSaveParams: Function,
  checked?: boolean,
  loading?: boolean,
  deleteText?: string,
};

const enhanceDish: HOC<*, Props> = compose(
  connect(
    (state, props) => ({
      types: selectTypes(state, props),
      settings: props.settings || state.settings.dishes,
    }),
    (dispatch, props) => ({
      // TODO рефакторить это, actions должны приходить через props
      ...(props.connectActions ? props.connectActions(dispatch, props) : {}),
      popupErr: msg => dispatch(popupErrAction(msg)),
      popup: msg => dispatch(popupAction(msg)),
      showConfirmation: payload => dispatch(showConfirmationAction(payload)),
      hideConfirmation: (e) => {
        if (e) {
          e.preventDefault();
        }
        dispatch(hideConfirmationAction());
      },
    }),
  ),

  withState('loadingSaleType', 'setLoadingSaleType', false),

  withHandlers({
    toggleItem: ({ id, checked, dishesAddToSelection, dishesRemoveFromSelection }) => () => (
      checked ? dishesRemoveFromSelection(id) : dishesAddToSelection(id)
    ),

    handleEdit: ({ id, orgId, dishesChangeItem }) => (e) => {
      e.preventDefault();
      if (typeof dishesChangeItem === 'function') {
        dishesChangeItem(id, orgId);
      }
    },

    handleDelete: ({
      dishesRemoveItem,
      showConfirmation,
      hideConfirmation,
      params,
      deleteText,
      getDeleteText,
    }) => (e) => {
      e.preventDefault();
      showConfirmation({
        text: deleteText
          || (typeof getDeleteText === 'function' && getDeleteText())
          || `Вы уверены? Удалить "${params.title}"?`,
        buttons: [{
          value: 'Удалить',
          error: true,
          onClick: dishesRemoveItem,
          key: 'remove',
        }, {
          value: 'Отмена',
          outline: true,
          onClick: hideConfirmation,
          key: 'cancel',
        }],
      });
    },

    handleDeleteImage: ({
      id,
      dishesFieldEditOriginal,
      showConfirmation,
      hideConfirmation,
      params,
      popup,
    }) => (e) => {
      e.preventDefault();
      showConfirmation({
        text: `Вы уверены? Удалить изображение из блюда "${params.title}"?`,
        buttons: [{
          value: 'Удалить',
          error: true,
          onClick: (event) => {
            event.preventDefault();
            hideConfirmation();
            const res = dishesFieldEditOriginal(id, 'photo', null);
            if (res) {
              popup('Изображение успешно удалено');
            }
          },
          key: 'remove',
        }, {
          value: 'Отмена',
          outline: true,
          onClick: hideConfirmation,
          key: 'cancel',
        }],
      });
    },


    handleSaveProp: ({ id, dishesFieldEdit, getSaveParams, popup }) => (e, cb) => {
      const { value, name } = e.currentTarget;
      // окно с выбором типа сохранения
      getSaveParams(async (isCancelled, params) => {
        if (!isCancelled) {
          const res = await dishesFieldEdit(id, name, value, params);
          if (res) {
            popup('Значение поля успешно изменено');
          }
        }
        cb();
      });
    },

    handleLoadImage: ({ popupErr }) => async (filename) => {
      if (!filename) {
        popupErr('Ошибка загрузки файла');
      }
    },

    handleSaveImage: ({ id, dishesFieldEditOriginal, popup }) => async (imageParams: ImageParamsType) => {
      const res = await dishesFieldEditOriginal(id, 'photo', imageParams);
      if (res) {
        popup('Изображение успешно изменено');
      }
      return res;
    },

    handleSaleType: ({ id, dishesFieldEditOriginal, setLoadingSaleType, popup }) => async (e) => {
      const { name, value } = e.currentTarget;
      setLoadingSaleType(true);
      const res = await dishesFieldEditOriginal(id, name, value);
      if (res) {
        popup('Значение поля успешно изменено');
      }
      setLoadingSaleType(false);
    },

  }),

  mapProps(
    props => omit([
      'dishesChangeItem',
      'dishesRemoveItem',
      'dishesFieldEdit',
      'dishesFieldEditOriginal',
      'dishesAddToSelection',
      'dishesRemoveFromSelection',
      'showConfirmation',
      'hideConfirmation',
      'popupErr',
      'popup',
      ...(props.dishesChangeItem ? [] : ['handleEdit']), // не выводить шестиренку в setup, если нет соответствующего action
      ...(props.dishesRemoveItem ? [] : ['handleDelete']), // не выводить крестик
    ], props),
  ),
);

export default enhanceDish;
