// @flow
import mergeDeepWith from 'ramda/es/mergeDeepWith';
import set from 'ramda/es/set';
import lensPath from 'ramda/es/lensPath';
import prop from 'ramda/es/prop';

import {
  PROPS_SUCCESS,
  PROPS_CLEAR,
  PROPS_EDIT,
} from 'app/actions/props';

import { PROPS_SUBMIT_SUCCESS } from 'app/actions/props/propsSubmit';


export type PropsFormProps = {
  person: {
    name: {
      last: string,
      first: string,
      middle: string,
    },
    phone: string,
    email: string,
    comment: string,
  },
  accountant: {
    name: {
      last: string,
      first: string,
      middle: string,
    },
    phone: string,
    email: string,
    comment: string,
  },
  requisites: {
    org_type: string,
    title: string,
    address_for_reports: 'law_address' | 'address' | 'post_address',
    law_address: string,
    address: string,
    post_address: string,
    inn: string,
    kpp: string,
    orgn: string,
    okpo: string,
    bik: string,
    bank: string,
    bank_address: string,
    corr: string,
    rc: string,
    oktmo: string,
    kbk: string,
    lc: string,
  },
};

const defaultState: PropsFormProps = {
  person: {
    name: {
      last: '',
      first: '',
      middle: '',
    },
    phone: '',
    email: '',
    comment: '',
  },
  accountant: {
    name: {
      last: '',
      first: '',
      middle: '',
    },
    phone: '',
    email: '',
    comment: '',
  },
  requisites: {
    org_type: '',
    title: '',
    address_for_reports: 'law_address',
    law_address: '',
    address: '',
    post_address: '',
    inn: '',
    kpp: '',
    orgn: '',
    okpo: '',
    bik: '',
    bank: '',
    bank_address: '',
    corr: '',
    rc: '',
    oktmo: '',
    kbk: '',
    lc: '',
  },
};

const data = (state: PropsFormProps = defaultState, { type, payload }: Object) => {
  switch (type) {
    case PROPS_SUBMIT_SUCCESS:
    case PROPS_SUCCESS: {
      return mergeDeepWith((a, b) => (b === null || b === undefined ? a : b), defaultState, payload);
    }
    case PROPS_EDIT: {
      return set(lensPath(prop('path', payload) || []), prop('value', payload), state);
    }
    case PROPS_CLEAR: {
      return defaultState;
    }
    default:
  }
  return state;
};

export default data;
