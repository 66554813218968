// @flow
import React from 'react';
// import { useSelector, shallowEqual } from 'react-redux';

import {
  OrgsFilter,
  YearsFilter,
  ClassesFilter,
  EducationLevels,
  StudentsFilter,
  MailFilter,
  SubsidiesFilter,
  SumsAndKits,
  DateFromToFilter,
  // SubsidySplitFilter,
  DetailsFilter,
  GroupFilter,
  // SortFilter,
  // PackFilter,
  ClassTypesFilter,
  SubsidyTypesFilter,
  ViewTypesFilter,
  OrgsTypesFilter,
  EmployeesFilter,
} from 'app/components/Reports/FilterItems';

import { groupsByReport } from 'app/actions/reports/filters/group';
import ReportSection from 'app/components/Reports/common/ReportSection';
import withResetAllFilters from 'app/components/Reports/hocs/withResetAllFilters';
import ResetFilterLink from 'app/components/common/reports/ResetFilterLink';
import Generate from 'app/components/Reports/Generate';
import { subsidiesReportGenerate } from 'app/actions/reports/subsidies/generate';
import { SUBSIDIES_SET_REPORT_ID } from 'app/actions/reports/subsidies';
import * as customerTypes from 'app/common/constants/schools/customers';
import useReportCancel from 'app/components/common/reports/useReportCancel';

import selectIsEnableClassesFilter from './selectors/selectIsEnableClassesFilter';
import selectClassItemsByEduLevelsFilter from './selectors/selectClassItemsByEduLevelsFilter';
import selectIsEnableStudentsFilter from './selectors/selectIsEnableStudentsFilter';
import selectIsEnableEducationLevelsFilter from './selectors/selectIsEnableEducationLevelsFilter';
import selectIsEnableEmployeesFilter from './selectors/selectIsEnableEmployeesFilter';
import selectIsEnableDetails from './selectors/selectIsEnableDetails';
import styles from './ReportFilter.scss';


type Props = {
  handleResetAll: (e: SyntheticEvent<HTMLElement>) => void,
  resetStatus: boolean,
}

const ReportFilter = ({ handleResetAll, resetStatus }: Props) => {
  // const orgsSelected = useSelector(state => state.reports.filters.orgs.selected, shallowEqual);
  // // const disableGenerate = isEmpty(orgsSelected);

  const processCancel = useReportCancel();

  return (
    <ReportSection
      header="Фильтры отчета «Дотации»"
      stickyNumber={1}
      headerBar={
        <ResetFilterLink
          title="Сбросить все фильтры"
          onClick={handleResetAll}
        />
      }
      noSpace
    >
      <div className={styles.columns}>
        <div className={styles.column}>
          <OrgsTypesFilter resetStatus={resetStatus} />
          <OrgsFilter
            resetStatus={resetStatus}
            skipRequestData // данные для фильтра запрашиваются в OrgsTypesFilter
          />
          <ClassTypesFilter resetStatus={resetStatus} />
          <EducationLevels
            resetStatus={resetStatus}
            selectIsEnable={selectIsEnableEducationLevelsFilter}
          />
          <ClassesFilter
            resetStatus={resetStatus}
            selectIsEnable={selectIsEnableClassesFilter}
            selectItems={selectClassItemsByEduLevelsFilter}
          />
          <StudentsFilter
            resetStatus={resetStatus}
            selectIsEnable={selectIsEnableStudentsFilter}
          />
          <EmployeesFilter
            resetStatus={resetStatus}
            selectIsEnable={selectIsEnableEmployeesFilter}
            override={{ customerId: customerTypes.EMPLOYEE_COMMON }}
          />
          <DetailsFilter
            resetStatus={resetStatus}
            selectIsEnable={selectIsEnableDetails}
          />
          <GroupFilter
            pair={groupsByReport.subsidies_detailed}
            resetStatus={resetStatus}
          />
          {/* <SortFilter resetStatus={resetStatus} /> */}
          {/* <PackFilter resetStatus={resetStatus} /> */}
        </div>
        <div className={styles.column}>
          <YearsFilter resetStatus={resetStatus} />
          <DateFromToFilter resetStatus={resetStatus} />
          <SubsidyTypesFilter resetStatus={resetStatus} />
          <SubsidiesFilter resetStatus={resetStatus} />
          <SumsAndKits resetStatus={resetStatus} />
          <ViewTypesFilter resetStatus={resetStatus} />
          {/* <SubsidySplitFilter resetStatus={resetStatus} /> */}
          <MailFilter resetStatus={resetStatus} />
        </div>
      </div>
      <Generate
        // disabled={disableGenerate}
        className={styles.generate}
        actionGenerate={subsidiesReportGenerate}
        actionSetReportId={SUBSIDIES_SET_REPORT_ID}
        onProcessCancel={processCancel}
      />
    </ReportSection>
  );
};

export default withResetAllFilters(ReportFilter);
