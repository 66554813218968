// @flow
import values from 'ramda/es/values';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import { map, values as valuesR } from 'ramda';

import { defaultStateDataCurrent, type State } from 'app/common/ui-components/filter';
import { GET_CLASSES_LIST, convertParams, convertResult } from 'app/common/api/requests/reports/klasses_list/get';
import { api } from 'app/api';

import type { FilterParams } from './FIlterParams';

const convertResultToOldType = (data: ClassesListType): OldClassesListType => {
  return map(({
    ID,
    title,
  }) => {
    return ({
      id: parseInt(ID, 10),
      title,
    })
  }, valuesR(data))
}

const filterGetItemsClasses = async (state: State, params?: FilterParams) => {
  const orgId: number | void = prop('orgId', params || {});
  const currentYearId: string | void = path(['year', 'current', 0], state);
  const yearId: number | null = currentYearId ? parseInt(currentYearId, 10) : null;

  if (orgId && yearId) {
    const res = await api.request(GET_CLASSES_LIST, {
      error: 'Не удалось получить данные классов',
      params: {
        schoolId: orgId,
        schoolYearId: yearId,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      const result = convertResultToOldType(res)
      return ([result, defaultStateDataCurrent]);
    }
  }

  return ([[], defaultStateDataCurrent]);
};

export default filterGetItemsClasses;
