// @flow
import { dataKey as vendingDataKey } from 'app/components/OrgsList/Vending';

import getParentBaseUrl from '../OrgsListPage/getBaseUrl';


type Options = {
  id?: string,
  section?: string,
  categoryId?: string,
}

const getBaseUrl = (options: Options) => {
  const { id, section, categoryId } = options;

  if (id && section && categoryId) {
    return `/${vendingDataKey}/${id}/${section}/${categoryId}`;
  }

  if (id && section) {
    return `/${vendingDataKey}/${id}/${section}`;
  }

  if (id) {
    return `/${vendingDataKey}/${id}`;
  }

  return getParentBaseUrl();
};

export default getBaseUrl;
