// @flow
import React, { Fragment, useState, useCallback } from 'react'
import { ReactI18NextChild } from 'react-i18next'

import Modal, { ModalHeader } from 'app/common/components/Modal'

import { EditGroceryKitForm } from './EditGroceryKitForm'
import styles from './EditKit.scss'


type Props = {
  kitId: number, // если не указан, создается новый комплект
  orgId: number,
  date: string,
  children?: (toggle: () => void) => ReactI18NextChild,
}

export const EditGroceryKit = ({
  kitId,
  orgId,
  date,
  children,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false)
  const toggleModal = useCallback(() => {
    setModalVisible(appear_ => !appear_)
  }, [])

  if (!children) {
    return null
  }

  return (
    <Fragment>
      {children(toggleModal)}
      {modalVisible
        && <Modal toggleModal={toggleModal} className={styles.root}>
          <ModalHeader className={styles.header}>
            {'Редактирование стоимости продуктового набора'}
          </ModalHeader>

          <EditGroceryKitForm
            kitId={kitId}
            orgId={orgId}
            date={date}
            toggleModal={toggleModal}
          />
        </Modal>}
    </Fragment>
  )
}
