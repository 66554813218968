// @flow
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';
import { foodModes } from 'app/dataTypes/foodModes';


const filterGetItemsFoodModes = () => ([[{
  id: foodModes.Afternoon,
  title: 'Полдник',
}, {
  id: foodModes.Breakfast,
  title: 'Завтрак',
}, {
  id: foodModes.Dinner,
  title: 'Обед',
}], defaultStateDataCurrent]);

export default filterGetItemsFoodModes;
