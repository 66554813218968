// @flow
import * as React from 'react';
import { DotsLoader } from 'app/common/components/Loader';
import LineInput from 'app/common/login/ui/LineInput';

import styles from './Loading.scss';


type Props = {
  children: React.Element<LineInput>,
}

const Loading = ({ children }: Props) => (
  <div className={styles.root}>
    {children}
    <div className={styles.loading}>
      <DotsLoader simple />
    </div>
  </div>
);

export default Loading;
