export default [
  { id: 'Алтайский край', title: 'Алтайский край' },
  { id: 'Амурская область', title: 'Амурская область' },
  { id: 'Архангельская область', title: 'Архангельская область' },
  { id: 'Астраханская область', title: 'Астраханская область' },
  { id: 'Белгородская область', title: 'Белгородская область' },
  { id: 'Брянская область', title: 'Брянская область' },
  { id: 'Владимирская область', title: 'Владимирская область' },
  { id: 'Волгоградская область', title: 'Волгоградская область' },
  { id: 'Вологодская область', title: 'Вологодская область' },
  { id: 'Воронежская область', title: 'Воронежская область' },
  { id: 'Москва', title: 'Москва' },
  { id: 'Еврейская автономная область', title: 'Еврейская автономная область' },
  { id: 'Забайкальский край', title: 'Забайкальский край' },
  { id: 'Ивановская область', title: 'Ивановская область' },
  { id: 'Иные территории, включая город и космодром Байконур', title: 'Иные территории, включая город и космодром Байконур' },
  { id: 'Иркутская область', title: 'Иркутская область' },
  { id: 'Кабардино-Балкарская Республика', title: 'Кабардино-Балкарская Республика' },
  { id: 'Калининградская область', title: 'Калининградская область' },
  { id: 'Калужская область', title: 'Калужская область' },
  { id: 'Камчатский край', title: 'Камчатский край' },
  { id: 'Карачаево-Черкесская Республика', title: 'Карачаево-Черкесская Республика' },
  { id: 'Кемеровская область', title: 'Кемеровская область' },
  { id: 'Кировская область', title: 'Кировская область' },
  { id: 'Костромская область', title: 'Костромская область' },
  { id: 'Краснодарский край', title: 'Краснодарский край' },
  { id: 'Красноярский край', title: 'Красноярский край' },
  { id: 'Курганская область', title: 'Курганская область' },
  { id: 'Курская область', title: 'Курская область' },
  { id: 'Ленинградская область', title: 'Ленинградская область' },
  { id: 'Липецкая область', title: 'Липецкая область' },
  { id: 'Магаданская область', title: 'Магаданская область' },
  { id: 'Московская область', title: 'Московская область' },
  { id: 'Мурманская область', title: 'Мурманская область' },
  { id: 'Ненецкий автономный округ', title: 'Ненецкий автономный округ' },
  { id: 'Нижегородская область', title: 'Нижегородская область' },
  { id: 'Новгородская область', title: 'Новгородская область' },
  { id: 'Новосибирская область', title: 'Новосибирская область' },
  { id: 'Омская область', title: 'Омская область' },
  { id: 'Оренбургская область', title: 'Оренбургская область' },
  { id: 'Орловская область', title: 'Орловская область' },
  { id: 'Пензенская область', title: 'Пензенская область' },
  { id: 'Пермский край', title: 'Пермский край' },
  { id: 'Приморский край', title: 'Приморский край' },
  { id: 'Псковская область', title: 'Псковская область' },
  { id: 'Республика Адыгея (Адыгея)', title: 'Республика Адыгея (Адыгея)' },
  { id: 'Республика Алтай', title: 'Республика Алтай' },
  { id: 'Республика Башкортостан', title: 'Республика Башкортостан' },
  { id: 'Республика Бурятия', title: 'Республика Бурятия' },
  { id: 'Республика Дагестан', title: 'Республика Дагестан' },
  { id: 'Республика Ингушетия', title: 'Республика Ингушетия' },
  { id: 'Республика Калмыкия', title: 'Республика Калмыкия' },
  { id: 'Республика Карелия', title: 'Республика Карелия' },
  { id: 'Республика Коми', title: 'Республика Коми' },
  { id: 'Республика Крым', title: 'Республика Крым' },
  { id: 'Республика Марий Эл', title: 'Республика Марий Эл' },
  { id: 'Республика Мордовия', title: 'Республика Мордовия' },
  { id: 'Республика Саха (Якутия)', title: 'Республика Саха (Якутия)' },
  { id: 'Республика Северная Осетия - Алания', title: 'Республика Северная Осетия - Алания' },
  { id: 'Республика Татарстан (Татарстан)', title: 'Республика Татарстан (Татарстан)' },
  { id: 'Республика Тыва', title: 'Республика Тыва' },
  { id: 'Республика Хакасия', title: 'Республика Хакасия' },
  { id: 'Ростовская область', title: 'Ростовская область' },
  { id: 'Рязанская область', title: 'Рязанская область' },
  { id: 'Самарская область', title: 'Самарская область' },
  { id: 'Санкт-Петербург', title: 'Санкт-Петербург' },
  { id: 'Саратовская область', title: 'Саратовская область' },
  { id: 'Сахалинская область', title: 'Сахалинская область' },
  { id: 'Свердловская область', title: 'Свердловская область' },
  { id: 'Севастополь', title: 'Севастополь' },
  { id: 'Смоленская область', title: 'Смоленская область' },
  { id: 'Ставропольский край', title: 'Ставропольский край' },
  { id: 'Тамбовская область', title: 'Тамбовская область' },
  { id: 'Тверская область', title: 'Тверская область' },
  { id: 'Томская область', title: 'Томская область' },
  { id: 'Тульская область', title: 'Тульская область' },
  { id: 'Тюменская область', title: 'Тюменская область' },
  { id: 'Удмуртская Республика', title: 'Удмуртская Республика' },
  { id: 'Ульяновская область', title: 'Ульяновская область' },
  { id: 'Хабаровский край', title: 'Хабаровский край' },
  { id: 'Ханты-Мансийский автономный округ - Югра', title: 'Ханты-Мансийский автономный округ - Югра' },
  { id: 'Челябинская область', title: 'Челябинская область' },
  { id: 'Чеченская Республика', title: 'Чеченская Республика' },
  { id: 'Чувашская Республика - Чувашия', title: 'Чувашская Республика - Чувашия' },
  { id: 'Чукотский автономный округ', title: 'Чукотский автономный округ' },
  { id: 'Ямало-Ненецкий автономный округ', title: 'Ямало-Ненецкий автономный округ' },
  { id: 'Ярославская область', title: 'Ярославская область' },
];
