// @flow
import React, { type Node } from 'react';

import styles from './SubHeader.scss';


type Props = {
  children: Node,
}

const SubHeader = ({ children }: Props) => {
  return (
    <div className={styles.subHeader}>
      {children}
    </div>
  );
};

export default SubHeader;
