// @flow
import map from 'ramda/es/map';

import type { StateByFilterId } from 'app/common/ui-components/filter';
import type { VendingMachinesParamsState } from 'app/dataProviders/VendingDataProvider';


type Additional = {
  search?: string,
  limit?: number,
  offset?: number,
}

const filter2MachinesRequestParams = (
  filterApplied: StateByFilterId | null,
  additional: Additional,
): VendingMachinesParamsState => {
  const { search, limit, offset } = additional;

  return ({
    search: search || undefined,
    limit: limit || undefined,
    offset: offset || undefined,
    city_id: filterApplied ? map(parseInt, filterApplied.city) : undefined,
    district_id: filterApplied ? map(parseInt, filterApplied.district) : undefined,
    model_id: filterApplied ? map(parseInt, filterApplied.model) : undefined,
  });
};

export default filter2MachinesRequestParams;
