// @flow
// @flow-runtime warn
import empty from 'app/common/images/empty.png';
import * as orgsTypes from 'app/common/constants/schools/types';

import type { Request } from '../index';


type DomainParams = {
  domain: string
}

type DomainResult = {
  result?: 'success',
  logo_url: ?string,
  secondary_logo_url: ?string,
  company: ?string,
  phone: ?string,
  project_name: ?string,
  country_iso_code: ?string,
  enable_reassign_identifiers_by_parents: boolean,
  email_force_auth: boolean,
  android_app_url: ?string,
  ios_app_url: ?string,
  email: ?string,
  identifier_format: ?string,
  parent_registration_types: Array<any>, // ???
  privacy_consent_document_url: ?string,
  privacy_policy_document_url: ?string,
  school_type: $Values<typeof orgsTypes>,
  terms_of_use_document_url: ?string,
}

export const defaultState: DomainResult = {
  logo_url: empty,
  secondary_logo_url: empty,
  company: '',
  phone: localStorage.getItem('phone') || '',
  project_name: '',
  country_iso_code: 'RU',
  enable_reassign_identifiers_by_parents: false,
  email_force_auth: false,
  android_app_url: '',
  ios_app_url: '',
  email: '',
  identifier_format: null,
  parent_registration_types: [],
  privacy_consent_document_url: null,
  privacy_policy_document_url: null,
  school_type: orgsTypes.SCHOOL,
  terms_of_use_document_url: null,
};


export const GET_DOMAIN_DATA: Request<DomainParams, DomainResult> = ['domain', 'get', ['data']];
