// @flow
import { Switch, Route, Redirect } from 'react-router'

import OrgsMenuPage from './OrgsMenuPage'
import OrgsKitsPage from './OrgsKitsPage'
import OrgsDishesPage from './OrgsDishesPage'
import OrgsOrdersPage from './OrgsOrdersPage'
import OrgsSettingsPage from './OrgsSettingsPage'
import useUrlBases from './useUrlBases'
import { pageTypes } from './pageTypes'


const OrgsPage = () => {
  const urlBases = useUrlBases()
  const menuBase = urlBases.get(pageTypes.menu)
  const kitsBase = urlBases.get(pageTypes.kits)
  const dishesBase = urlBases.get(pageTypes.dishes)
  const ordersBase = urlBases.get(pageTypes.orders)
  const settingsBase = urlBases.get(pageTypes.settings)

  return (
    <Switch>
      {/* МЕНЮ */}
      <Route path={menuBase} component={OrgsMenuPage} exact />
      <Route path={`${menuBase}/:date`} component={OrgsMenuPage} exact />
      <Route path={`${menuBase}/:date/:sellPointId`} component={OrgsMenuPage} exact />
      <Route path={`${menuBase}/:date/:sellPointId/:act`} component={OrgsMenuPage} />

      {/* КОМПЛЕКТЫ */}
      <Redirect from={`${kitsBase}/:date`} to={`${kitsBase}/:date/current`} exact />
      <Route path={kitsBase} component={OrgsKitsPage} exact />
      <Route path={`${kitsBase}/:date`} component={OrgsKitsPage} exact />
      <Route path={`${kitsBase}/:date/:act`} component={OrgsKitsPage} exact />
      <Route path={`${kitsBase}/:date/:act/:kitId`} component={OrgsKitsPage} />

      {/* ПРОДУКЦИЯ (DISHES) */}
      <Route path={dishesBase} component={OrgsDishesPage} exact />
      <Route path={`${dishesBase}/:categoryId`} component={OrgsDishesPage} exact />
      <Route path={`${dishesBase}/:categoryId/:process`} component={OrgsDishesPage} />

      {/* ЗАЯВКИ (ORDERS) */}
      <Route path={ordersBase} component={OrgsOrdersPage} exact />
      <Route path={`${ordersBase}/:ordersSection`} component={OrgsOrdersPage} />

      {/* НАСТРОЙКИ */}
      <Route path={settingsBase} component={OrgsSettingsPage} exact />
      <Route path={`${settingsBase}/:type`} component={OrgsSettingsPage} exact />
      <Route path={`${settingsBase}/:type/:option`} component={OrgsSettingsPage} />

      {/* В ОСТАЛЬНЫХ СЛУЧАЯХ (редирект в список заведений) */}
      <Redirect to="/orgslist" />
    </Switch>
  )
}

export default OrgsPage
