// @flow
import React, { type Node } from 'react';

import OrgsAvailableTypesContext from './OrgsAvailableTypesContext';
import useOrgTypes from './useOrgTypes';


type Props = {
  children: Node
}

const OrgsAvailableTypesProvider = ({ children }: Props) => {
  const value = useOrgTypes();

  return (
    <OrgsAvailableTypesContext.Provider value={value}>
      {children}
    </OrgsAvailableTypesContext.Provider>
  );
};

export default OrgsAvailableTypesProvider;
