import uniqid from 'uniqid'
import { useCallback, useEffect, useMemo } from 'react'
import { sortBy, prop, propEq, find, all, isEmpty } from 'ramda'

import { DataBox, useDataBoxSelector, useDataBoxStatus } from 'app/common/data-box-store'

import PlainItemsSelector, { Props as PlainItemsSelectorProps } from '../../plain-selector/plain-items-selector'
import { ItemType } from '../../plain-selector'


export enum StatusOnEmpty {
  Loading = 'loading',
  Disabled = 'disabled',
  Hidden = 'hidden'
}

const sortByAlphabet = sortBy(prop('title'))
const emptyArray = []

type Props = {
  dataBoxData?: DataBox<Array<ItemType>>
  dataBoxSelection?: DataBox<Array<string>>
  statusOnEmpty?: StatusOnEmpty
  resetOnDismount?: boolean
} & Omit<PlainItemsSelectorProps, 'items' | 'selection' | 'onChange'>

const PlainItemsSelectorBox = ({
  id,
  dataBoxData = new DataBox<Array<ItemType>>(uniqid('empty-data-'), []),
  dataBoxSelection = new DataBox<Array<string>>(uniqid('empty-selection-'), []),
  statusOnEmpty,
  resetOnDismount,
  loading,
  defaultSelection: defaultSelectionOverride,
  sortFunc = sortByAlphabet,
  ...props
}: Props) => {
  const { multiple } = props
  const data = useDataBoxSelector(dataBoxData, () => dataBoxData.data)
  const status = statusOnEmpty && isEmpty(data) ? statusOnEmpty : undefined

  const defaultSelection = useMemo(() => {
    if (defaultSelectionOverride) {
      if (all(val => !!find(propEq('id', val), data), defaultSelectionOverride)) {
        return defaultSelectionOverride
      }
    }
    if (!multiple && data[0] && data[0].id) {
      return [data[0].id]
    }
    return emptyArray
  }, [data, defaultSelectionOverride, multiple])

  const dataRecords = useMemo(() => DataBox.arrayToRecords(data), [data])
  const ready = useDataBoxStatus(dataBoxData, () => dataBoxData.ready)
  const selection = useDataBoxSelector(dataBoxSelection, () => dataBoxSelection.data)

  const handleChange = useCallback((val: Array<string>) => {
    dataBoxSelection.updateData(() => val)
  }, [dataBoxSelection])

  useEffect(() => {
    dataBoxSelection.setDefault(defaultSelection)
  }, [defaultSelection, dataBoxSelection])

  useEffect(() => {
    dataBoxData.subscribe(
      multiple
        ? () => dataBoxSelection.resetToDefault()
        : () => {
          const hasData = !isEmpty(dataBoxData.data)
          if (hasData) {
            const defaulId = defaultSelectionOverride ? defaultSelectionOverride[0] : null
            const defaultIndex = dataBoxData.data.findIndex(item => item.id === defaulId)
            const indexSelected = defaultIndex ?? 0
            dataBoxSelection.updateData(() => [dataBoxData.data[indexSelected].id])
          }
        },
    )

    return () => {
      if (resetOnDismount) {
        dataBoxData.reset()
        dataBoxSelection.reset()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataBoxData, dataBoxSelection, multiple])

  if (status === StatusOnEmpty.Hidden) return null

  return (
    <PlainItemsSelector
      id={id}
      items={dataRecords}
      selection={selection}
      onChange={handleChange}
      loading={!ready || loading || status === StatusOnEmpty.Loading}
      defaultSelection={defaultSelection}
      sortFunc={sortFunc}
      disabled={status === StatusOnEmpty.Disabled}
      {...props}
    />
  )
}

export default PlainItemsSelectorBox
