// @flow
import { propEq, slice, move, pipe, map } from 'ramda';

import PlanogramItem, { type RawDataItem } from './planogram-item';
import PlanogramData, { type IPlanogramData, type Contains } from './planogram-data';
import { createGrid } from './create-grid';
import { createPlanogramItem } from './create-planogram-item';


interface IPlanogramDataExt<T: PlanogramItem> extends IPlanogramData<T> {
  duplicate(rawItems?: Array<RawDataItem>): IPlanogramData<T>,
  canBeMergedLeft(column: number, row: number): boolean,
  canBeMergedRight(column: number, row: number): boolean,
  canBeSplitted(column: number, row: number): boolean,
  getMergedLeft(column: number, row: number | Array<T>): Array<T>,
  getMergedRight(column: number, row: number | Array<T>): Array<T>,
  getSplitted(column: number, row: number | Array<T>): Array<T>,
  getMoved(indexSource: number, indexDestination: number, row: number | Array<T>): Array<T>
}

export default class PlanogramDataExt<T: PlanogramItem = PlanogramItem>
  extends PlanogramData<T>
  implements IPlanogramDataExt<T> {
  constructor(
    items?: Array<Array<T>> = [],
    naming?: Array<Array<string>>,
    contains?: Array<Array<Contains>>,
  ) {
    super(items, naming, contains);
  }

  duplicate(rawItems?: Array<RawDataItem>): PlanogramDataExt<T> {
    const grid: Array<Array<T>> = rawItems ? pipe(
      map(createPlanogramItem),
      x => createGrid<T>(x),
    )(rawItems) : this.items;

    return new PlanogramDataExt(grid, this.naming, this.contains);
  }

  canBeMergedLeft(column: number, row: number): boolean {
    const currentRow = this.getRow(row);
    const sourceItem = this.findItemInRow(propEq('column', column), currentRow);
    if (!sourceItem || column === 0) return false;
    const { width, column: sourceColumn } = sourceItem;
    const index = this.findIndexInRow(propEq('column', sourceColumn), currentRow);
    const { amount = 0 } = this.contains[row][column] || {};
    const { amount: previosCellAmount = 0 } = this.contains[row][column - 1] || {};

    return (!amount
      && width === 1
      && index > 0
      && currentRow[index - 1].width === 1
      && !previosCellAmount
    );
  }

  canBeMergedRight(column: number, row: number): boolean {
    const currentRow = this.getRow(row);
    const sourceItem = this.findItemInRow(propEq('column', column), currentRow);
    if (!sourceItem || column === this.columns - 1) return false;
    const { width, column: sourceColumn } = sourceItem;
    const index = this.findIndexInRow(propEq('column', sourceColumn), currentRow);
    const { amount = 0 } = this.contains[row][column] || {};
    const { amount: nextCellAmount = 0 } = this.contains[row][column + 1] || {};

    return (!amount
      && width === 1
      && index < currentRow.length - 1
      && currentRow[index + 1].width === 1
      && !nextCellAmount
    );
  }

  canBeSplitted(column: number, row: number): boolean {
    const currentRow = this.getRow(row);
    const sourceItem = this.findItemInRow(propEq('column', column), currentRow);
    if (!sourceItem) return false;
    const { width } = sourceItem;
    const { amount = 0 } = this.contains[row][column] || {};
    return (!amount && width === 2);
  }

  getMergedLeft(column: number, row: number | Array<T>): Array<T> {
    const currentRow = this.getRow(row);
    const sourceIndex = this.findIndexInRow(propEq('column', column), currentRow);
    const targetIndex = sourceIndex - 1;
    if (sourceIndex === -1 || targetIndex < 0) {
      console.error('👻 PlanorgamDataExt.getMergedLeft, не найдены изменяемые элементы');
      return [];
    }

    const namesStartIndex = sourceIndex + 1;
    const namesLength = currentRow.length;
    const restOfRow = (namesStartIndex < namesLength
      ? slice(namesStartIndex, namesLength, currentRow)
      : []);

    const removed = currentRow[targetIndex].duplicate(() => ({
      width: 0,
    }));

    const merged = currentRow[sourceIndex].duplicate(() => ({
      width: 2,
      column: currentRow[targetIndex].column,
    }));

    return this.getChangedNames(targetIndex - 1, [removed, merged, ...restOfRow]);
  }

  getMergedRight(column: number, row: number | Array<T>): Array<T> {
    const currentRow = this.getRow(row);
    const sourceIndex = this.findIndexInRow(propEq('column', column), currentRow);
    const targetIndex = sourceIndex + 1;
    if (sourceIndex === -1 || targetIndex >= currentRow.length) {
      console.error('👻 PlanorgamDataExt.getMergedRight, не найдены изменяемые элементы');
      return [];
    }

    const namesStartIndex = sourceIndex + 2;
    const namesLength = currentRow.length;
    const restOfRow = (namesStartIndex < namesLength
      ? slice(namesStartIndex, namesLength, currentRow)
      : []);

    const removed = currentRow[targetIndex].duplicate(() => ({
      width: 0,
    }));

    const merged = currentRow[sourceIndex].duplicate(() => ({
      width: 2,
    }));

    return this.getChangedNames(sourceIndex - 1, [removed, merged, ...restOfRow]);
  }

  getSplitted(column: number, row: number | Array<T>): Array<T> {
    const currentRow = this.getRow(row);
    const sourceIndex = this.findIndexInRow(propEq('column', column), currentRow);
    if (sourceIndex === -1) {
      console.error('👻 PlanorgamData.getSplitted, не найдены изменяемый элемент');
      return [];
    }

    const namesStartIndex = sourceIndex + 1;
    const namesLength = currentRow.length;
    const restOfRow = (namesStartIndex < namesLength
      ? slice(namesStartIndex, namesLength, currentRow)
      : []);

    const splitted1 = currentRow[sourceIndex].duplicate(() => ({
      width: 1,
    }));

    const splitted2 = currentRow[sourceIndex + 1]
      // .createId()
      .duplicate(({ column: col }) => ({
        column: col + 1,
        width: 1,
      }));

    return this.getChangedNames(sourceIndex, [splitted1, splitted2, ...restOfRow]);
  }

  getMoved(
    indexSource: number,
    indexDestination: number,
    row: number | Array<T>,
  ): Array<T> {
    const currentRow = this.getRow(row);

    const workingSegmentStartIndex = Math.min(indexDestination, indexSource);
    const workingSegmentFinalIndex = Math.max(indexDestination, indexSource) + 1;
    const workingSegment = slice(workingSegmentStartIndex, workingSegmentFinalIndex, currentRow);

    const workingSegmentStartColumnNumber = workingSegment[0].column;

    const workingSegmentMoved = (
      indexDestination < indexSource
        ? move(-1, 0, workingSegment)
        : move(0, -1, workingSegment)
    );

    const workingSegmentColumnsRemapped = PlanogramData.updateColumnProp(
      workingSegmentStartColumnNumber,
      workingSegmentMoved,
    );

    const workingSegmentNamesUpdated = this.getChangedNames(
      workingSegmentStartIndex,
      workingSegmentColumnsRemapped,
    );

    return workingSegmentNamesUpdated;
  }
}
