// @flow
import React, { type Node, useCallback } from 'react';

import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useDebouncedSearch from 'app/common/hooks/useDebouncedSearch';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import type { HandleModalDisplayed } from 'app/common/ui-next/plain-modal';

import styles from './HeaderContent.scss';


const emptyFunc = () => {};

type Props = {
  header: string,
  onSearch?: (string) => void,
  modalDisplayed: HandleModalDisplayed,
  children: Node
}

const HeaderContent = ({
  header,
  onSearch = emptyFunc,
  modalDisplayed,
  children,
}: Props): Node => {
  const handleReset = useCallback(() => onSearch(''), [onSearch]);
  const [searchString, handleSearch, handleClearSearch] = useDebouncedSearch(onSearch, handleReset);

  return (
    <ControlsBar className={styles.root}>
      <span className={styles.header}>
        {header}
      </span>

      {onSearch !== emptyFunc
        && <PlainInputSearch
          placeholder="Поиск"
          searchString={searchString}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        />}

      <div className={styles.space} />

      <div className={styles.children}>{children}</div>

      <PlainButtonAsLink onClick={modalDisplayed}>
        <PlainIcon
          width={17}
          height={17}
          className={styles.icon}
          type={iconTypes.CROSS_BOLD}
          colorType={colorTypes.COLOR_GRAY}
        />
      </PlainButtonAsLink>

    </ControlsBar>
  );
};

export default HeaderContent;
