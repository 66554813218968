// @flow
import { useMemo } from 'react';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import identity from 'ramda/es/identity';
import pipe from 'ramda/es/pipe';
import complement from 'ramda/es/complement';

import { useFilterData, appliedFilterConvert, appliedFilterKeysRename } from 'app/common/ui-components/filter';
import { type ReportsPerformAccountingCustomersParams } from 'app/common/api/requests/reports/perform';

import orgsFilter from 'app/components/common/reports/filters/org';
import yearFilter from 'app/components/common/reports/filters/year';
import buildingFilter from 'app/components/common/reports/filters/building';
import sellPointFilter from 'app/components/common/reports/filters/sellPoint';
import customerTypeFilter from 'app/components/common/reports/filters/customerType';
import classFilter from 'app/components/common/reports/filters/class';
import sellTypeFilter from 'app/components/common/reports/filters/sellType';
import foodModeFilter from 'app/components/common/reports/filters/foodMode';
// import mailFilter from 'app/components/common/reports/filters/mail'; // когда добавится отправка на email

import showDataFilter from '../ReportFilter/filters/showData';
import groupFilter from '../ReportFilter/filters/group';
import periodFilter from '../ReportFilter/filters/period';


const toInt = (val: string) => parseInt(val, 10);
const mapToInt = map(toInt);
const toBoolean = complement(complement(prop(0)));

const renameMapper = {
  [buildingFilter]: 'building_id',
  [customerTypeFilter]: 'customer_type',
  [foodModeFilter]: 'food_modes',
  [classFilter]: 'klass_ids',
  [sellPointFilter]: 'sale_point_id',
  [sellTypeFilter]: 'sale_type',
  [orgsFilter]: 'school_ids',
  [yearFilter]: 'schoolyear_id',

  [periodFilter]: 'period',
  [groupFilter]: 'group_purchases',
  [showDataFilter]: 'show_unpaid',
};

const convertLimits = {
  building_id: mapToInt,
  customer_type: identity,
  food_modes: identity,
  klass_ids: mapToInt,
  sale_point_id: mapToInt,
  sale_type: prop(0),
  school_ids: mapToInt,
  schoolyear_id: pipe(mapToInt, prop(0)),
};

const convertParams = {
  period: identity,
  group_purchases: toBoolean,
  show_unpaid: toBoolean,
};

export type Params = $PropertyType<ReportsPerformAccountingCustomersParams, 'params'> | null

export default function useGenerateParams(): Params {
  const [,,, { getAppliedFilterActive }] = useFilterData();

  const params: Params = useMemo(() => {
    const data = getAppliedFilterActive();
    if (!data) {
      return null;
    }
    const renamed = appliedFilterKeysRename(renameMapper, data);
    const limits = appliedFilterConvert(convertLimits, renamed);
    const { period: [from, to], ...others } = appliedFilterConvert(convertParams, renamed);

    return {
      limits,
      from,
      to,
      ...others,
    };
  }, [getAppliedFilterActive]);

  return params;
}
