import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { CONTENT_MARGIN, CONTENT_MARGIN_COLLAPSED } from './constants';


const enhanceIndent = compose(
  connect(
    state => ({
      leftIndent: state.settings.sidemenu.wide ? CONTENT_MARGIN : CONTENT_MARGIN_COLLAPSED,
    }),
  ),
);

export default enhanceIndent;
