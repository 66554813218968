// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames';

import { Table, Tr, Th } from 'app/common/ui-components/table';

import { columnId, columnHeaderText } from './HeaderLeftContent';
import { columnFooterText } from './FooterLeftContent';
import type { Multiple } from '../useReportData';


type Props = {
  className?: string,
  titles: $PropertyType<Multiple, 'titles'>,
  tbodyRef?: Ref<'tbody'>,
  sameRowBase?: string,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  children: (content: string) => Node,
}

const MiddleLeftContent = ({
  className,
  titles,
  tbodyRef,
  sameRowBase,
  onMouseEnter,
  onMouseLeave,
  children,
}: Props) => {
  return (
    <Table className={className}>
      <thead>
        <Tr header hidden>
          <Th scope="col">
            {columnHeaderText}
          </Th>
        </Tr>
      </thead>

      <tbody ref={tbodyRef}>
        {titles.map(({ key, title }, i) => (
          <Tr
            key={key}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={cn(sameRowBase && `${sameRowBase}${i}`)}
          >
            <Th
              id={key}
              headers={columnId}
              scope="row"
              alignLeft
            >
              {children(title)}
            </Th>
          </Tr>
        ))}
      </tbody>

      <tfoot>
        <Tr hidden>
          <Th scope="row" alignRight>
            {columnFooterText}
          </Th>
        </Tr>
      </tfoot>
    </Table>
  );
};

export default MiddleLeftContent;
