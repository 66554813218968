import { ReactNode } from 'react'

import styles from './FormGridTemplate.scss'


type Props = {
  children: ReactNode
  title: string
}

const FormGridTemplate = ({ children, title }: Props) => {
  return (
    <table className={styles.root}>
      <thead>
        <tr>
          <th>{title}</th>
          <th>{'Показывать в форме создания справки'}</th>
          <th>{'Выбрано принудительно'}</th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  )
}

export default FormGridTemplate
