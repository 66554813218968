import { DataBox, createDataBox } from 'app/common/data-box-store'
import { GeneralSettings } from 'app/dataTypes/generalSettings'
import { GET_GENERAL_SETTINGS, convertResult } from 'app/common/api/requests/food/general_settings/get'
import { POST_GENERAL_SETTINGS, convertParams, convertResult as convertResultPOST } from 'app/common/api/requests/food/general_settings/post'
import { api } from 'app/api'


export const defaultGeneralSettings: GeneralSettings = {
  useNomenclature: false,
  parallelsForCommonOrder: [],
  eMoneyEnabled: false,
  useGrocerySetPrice: false,
  useСompensations: false,
  useCommonOrderComplexChoose: false,
}

export class GeneralSettingsDataBox extends DataBox<GeneralSettings> {
  static requestData = async () => {
    const res = await api.request(GET_GENERAL_SETTINGS, {
      error: 'Запрос настроек поставщика не удался',
      convertions: {
        convertResult,
      },
    })
    return res
  }

  static submitData = async (params: GeneralSettings) => {
    const res = await api.request(POST_GENERAL_SETTINGS, {
      error: 'Сохранение настроек поставщика не удалось',
      params,
      convertions: {
        convertParams,
        convertResult: convertResultPOST,
      },
    })
    return res
  }
}

export const generalSettings = createDataBox('generalSettings', defaultGeneralSettings, GeneralSettingsDataBox)

generalSettings.addDataReceiver(GeneralSettingsDataBox.requestData)
