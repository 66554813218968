import { ReactNode, useCallback, useState } from 'react'

import PlainModal, { HandleModalDisplayed, PlainModalContent, PlainModalFooter, PlainModalHeader } from 'app/common/ui-next/plain-modal'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'

import OrgGroupForm from './OrgGroupForm'
import { FormField } from './OrgGroupFormFields'


type Props = {
  ID: string
  children: (toggle: HandleModalDisplayed) => ReactNode
  submit: (ID: string, title: string) => Promise<boolean>
  errors: Record<string, string>
  resetErrors: () => void
  value: string
}

const EditOrgGroupModal = ({
  ID,
  children,
  submit,
  errors,
  resetErrors,
  value,
}: Props) => {
  const [valueExist, setValueExist] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = useCallback(async (form: HTMLFormElement) => {
    const formData = new FormData(form)
    setSubmitting(true)
    const title = formData.get(FormField.Name) as string
    const res = await submit(ID, title)
    setSubmitting(false)
    setValueExist(false)
    return res
  }, [submit, ID])

  return (
    <PlainModal
      formId="editOrgGroup"
      controlBy={children}
      onSubmit={handleSubmit}
    >
      <PlainModalHeader>
        {'Редактирование группы'}
      </PlainModalHeader>

      <PlainModalContent>
        <OrgGroupForm
          onValueExist={setValueExist}
          onResetErrors={resetErrors}
          error={errors.title}
          defaultValue={value}
        />
      </PlainModalContent>

      <PlainModalFooter>
        {toggleModal => (
          <ControlsBar right>
            <PlainButton outline disabled={submitting} onClick={toggleModal}>
              {'Отмена'}
            </PlainButton>
            <PlainButton type="submit" disabled={submitting || !valueExist}>
              {submitting ? 'Сохранение...' : 'Сохранить'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}

export default EditOrgGroupModal
