// @flow
import React from 'react';

import ProductList from './ProductList';


type Props = {
  className?: string,
  orgId: number,
  orderId: number,
}

const ExpandedContent = ({ className, orgId, orderId }: Props) => {
  return (
    <ProductList
      className={className}
      orgId={orgId}
      orderId={orderId}
    />
  );
};

export default ExpandedContent;
