// @flow
import React, { Fragment, type Node, useEffect } from 'react';

import PlainInput from 'app/common/ui-next/plain-input';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import styles from './AddNewEnterprise.scss';


export type FormFields = {
  titleShort: HTMLInputElement,
  titleComplete: HTMLInputElement
}

type Props = {
  disabled?: boolean,
  error?: string,
  onMount?: () => void,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
}

const AddNewEnterprise = ({ disabled, error, onMount, onChange }: Props): Node => {
  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);

  return (
    <Fragment>
      <PlainLabel htmlFor="title" displayAsBlock>
        {'Краткое наименование'}
      </PlainLabel>
      <PlainInput
        id="titleShort"
        onChange={onChange}
        className={styles.inputTitle}
        disabled={disabled}
        error={!!error}
        displayAsBlock
      />
      <PlainHelperText error>
        {error}
      </PlainHelperText>

      <PlainLabel htmlFor="titleComplete" displayAsBlock>
        {'Полное наименование'}
      </PlainLabel>
      <PlainInput
        id="titleComplete"
        onChange={onChange}
        disabled={disabled}
        displayAsBlock
      />
    </Fragment>
  );
};

export default AddNewEnterprise;
