// @flow
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import PlainLabel from 'app/common/ui-next/plain-label';
import PlainInput from 'app/common/ui/PlainInput';

import { useImportXLSData } from '../ImportXLSData';
import styles from './ImportReportEmail.scss';


type Props = {
  className?: string,
}

const ImportReportEmail = ({ className }: Props) => {
  const { email: userEmail } = useSelector(state => state.user, shallowEqual);
  const { content: { email, errors }, modify: { emailUpdate } } = useImportXLSData();

  useEffect(() => {
    if (userEmail) {
      emailUpdate(userEmail);
    }
  }, [userEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeEmail = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    emailUpdate(value);
  }, [emailUpdate]);


  return (
    <div className={className}>
      <PlainLabel
        className={styles.label}
        htmlFor="email"
      >
        {'Импорт займет продолжительное время.<br />После подтверждения вы будете перенаправлены в раздел «Продукция»,<br />а по завершении на указанный ниже e-mail будет выслан отчёт.'}
      </PlainLabel>
      <PlainInput
        className={styles.input}
        onChange={handleChangeEmail}
        id="email"
        value={email}
        isError={Boolean(errors.email)}
        errorText={errors.email}
      />
    </div>
  );
};

export default ImportReportEmail;
