import { ReactNode, useCallback } from 'react'

import PlainModal, { HandleModalDisplayed, PlainModalFooter, PlainModalHeader, PlainModalContent } from 'app/common/ui-next/plain-modal'
import PlainInputSearch from 'app/common/ui-next/plain-input-search'
import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'

import { isEmpty } from 'ramda'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import useSearch from './useSearch'
import OrgsSelectorItems from './OrgsSelectorItems'
import CheckboxOrRadio from './CheckboxOrRadio'
import useType from './useType'
import TypeSwitcher from './TypeSwitcher'

import styles from './OrgsSelectorModal.scss'
import useSelection from './useSelection'
import useActualItemsList from './useActualItemsList'
import useLinks from './useLinks'


type Item = { ID: string, title: string, disabled?: boolean, orgGroupID?: string | null }

type Props<T extends Item> = {
  header: string
  orgItems: Map<string, T>
  orgsGroupItems?: Map<string, T>
  multiple?: boolean
  show?: boolean
  emptyMessage?: string
  onChange?: (selection: Array<string>) => void
  children: (toggle: HandleModalDisplayed) => ReactNode
  itemsSelected?: Array<string>
}

export default function OrgsSelectorModal<T extends Item>({
  header,
  orgItems,
  orgsGroupItems = new Map(),
  multiple,
  show,
  emptyMessage,
  onChange,
  children,
  itemsSelected = [],
}: Props<T>) {
  const showOrgGroups = !!multiple && !isEmpty(orgsGroupItems)

  const { type, setType } = useType(showOrgGroups)

  const actualItems = useActualItemsList({ type, orgsGroupItems, orgItems })
  const { value, itemsFiltered, search, clearSearch } = useSearch(actualItems)

  const { getLinkedOrgs } = useLinks({ orgItems })

  const { selection, orgsSelection, toggleItem, toggleAll, selectedAll } = useSelection({
    type,
    getLinkedOrgs,
    orgs: orgItems,
    orgGroups: orgsGroupItems,
    externalState: itemsSelected,
  })

  const onChangeRef = useCallbackRef(onChange)

  const handleSubmit = useCallback(() => {
    onChangeRef.current?.(Array.from(orgsSelection.values()))
    return true
  }, [onChangeRef, orgsSelection])

  return (
    <PlainModal
      formId="orgsSelector"
      show={show}
      controlBy={children}
      className={styles.root}
      onSubmit={handleSubmit}
    >
      <PlainModalHeader>{header}</PlainModalHeader>
      <PlainModalContent unscrollable>
        {showOrgGroups
          && <TypeSwitcher
            className={styles.typeSwitcher}
            type={type}
            onChange={setType}
          />}

        <PlainInputSearch
          className={styles.search}
          clearSearch={clearSearch}
          onChange={search}
          value={value}
          ContainerComponent="div"
        />
      </PlainModalContent>

      <PlainModalContent unscrollable paddingFree>
        {multiple
          && <CheckboxOrRadio
            name="all"
            className={styles.itemAll}
            label="Выбрать все"
            value="all"
            onChange={toggleAll}
            checked={selectedAll}
          />}

        <ScrollbarsWShadow
          autoHeight
          autoHeightMin={100}
          autoHeightMax="50vh"
        >
          <OrgsSelectorItems
            className={styles.items}
            items={itemsFiltered}
            multiple={multiple}
            emptyMessage={emptyMessage}
            onChange={toggleItem}
            selected={selection}
          />
        </ScrollbarsWShadow>
      </PlainModalContent>

      <PlainModalFooter>
        {toggle => (
          <ControlsBar middle>
            <PlainButton
              onClick={toggle}
              outline
            >
              {'Отмена'}
            </PlainButton>
            <PlainButton
              type="submit"
              disabled={!onChange}
            >
              {'Выбрать'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}
