// @flow
import React from 'react';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';

import styles from './RolesTable.scss';


const getLabel = (title: string, k: number, i: number) => {
  const appName = k === 0 ? 'Клиент' : 'WEB';
  const access = i === 0 ? 'Чтение' : 'Запись';

  return `${title} — ${appName} — ${access}`;
};

type Item = {
  value: string,
  checked: boolean,
  disabled: boolean,
};

type Props = {
  title: string,
  items: Array<[Item, Item]>,
  loading: boolean,
  onChange: (SyntheticEvent<HTMLInputElement>) => void,
};

const Row = ({ title, items, loading, onChange }: Props) => console.log(items) || (
  <tr className={styles.row}>
    <td>{title}</td>
    {items.map((pair, k) => pair.map((item, i) => (<td key={item.value}>
      <PlainCheckbox
        label={getLabel(title, k, i)}
        value={item.value}
        className={styles.checkbox}
        checked={item.checked}
        disabled={item.disabled || loading}
        onChange={onChange}
        hideLabel
      />
    </td>)))}
  </tr>
);

export default Row;
