// @flow
import { connect } from 'react-redux';
import isEmpty from 'ramda/es/isEmpty';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import action from 'app/common/lib/action';

import {
  subsidiesFetch,
  SUBSIDIES_CLEAR,
} from 'app/actions/subsidies';


export type SummaryType = {
  split1: number,
  split2: number,

  count: number,
  count_common: number,
  count_personal: number,
  students_common_count: number,
  students_count: number,
  students_personal_count: number,
  total: number,
  total_common: number,
  total_personal: number,
}

export default compose(
  connect(
    state => ({
      summary: state.reports.subsidies.data.summary,
      // subsidy1Title: state.subsidies.splitsAll.part1Title,
      // subsidy2Title: state.subsidies.splitsAll.part2Title,
      params: state.reports.subsidies.params,
    }),
  ),

  // Нужно подгрузить названия субсидий если есть разделение
  lifecycle({
    componentDidMount() {
      const { dispatch, summary: { split1, split2 } } = this.props;
      // это больше не работает
      if (split1 || split2) {
        dispatch(subsidiesFetch());
      }
    },
    componentDidUpdate(prevProps) {
      const { dispatch, summary: { split1, split2 } } = this.props;
      // это больше не работает
      if ((split1 || split2) && (split1 !== prevProps.split1 || split2 !== prevProps.split2)) {
        dispatch(subsidiesFetch());
      }
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(action(SUBSIDIES_CLEAR));
    },
  }),

  // Не отображать при пустом summary
  branch(
    ({ summary }) => isEmpty(summary),
    renderNothing,
  ),
);
