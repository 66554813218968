// @flow
import React from 'react';
import Checkbox from 'app/common/ui/Checkbox';
import checkboxSettings from 'app/common/containers/settings/checkboxSettings';
import SettingsGear from 'app/common/components/Settings';
import WithHOC from 'app/common/components/WithHOC';

import styles from './OrgsListGear.scss';

// глючит, нажатия со второго раза только
// const GearCheckbox = checkboxSettings(Checkbox);

export type DishesTableSettings = {
  rows: number,
  hideDeleted: boolean,
};

const OrgsListGear = () => (<SettingsGear dataKey="schools" linkClassName={styles.root}>
  {({ saveSettings }) => (<WithHOC
    component={Checkbox}
    hoc={checkboxSettings}
    label={<span>{'Скрывать удаленные'}</span>}
    name="hideDeleted"
    saveSettings={saveSettings}
    className={styles.gearCheck}
    settingsKey="schools"
  />)}
</SettingsGear>);

export default OrgsListGear;
