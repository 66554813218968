// @flow
import { useContext, useMemo } from 'react';
import pipe from 'ramda/es/pipe';
import toPairs from 'ramda/es/toPairs';
import filter from 'ramda/es/filter';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';

import FilterDataContext, { type FilterContextType } from './filter-data-context';
import type { StateByFilterId, AppliedState } from './filter-state-applied';
import type { Actions } from './use-filter';


export default function useFilterData<Params>(): FilterContextType<Params> {
  return useContext(FilterDataContext);
}

export const useFilterActions = (): Actions => {
  const [,,, actions] = useContext(FilterDataContext);
  return actions;
};

export const useFilterParams = (): mixed => {
  const [,,,, params] = useContext(FilterDataContext);
  return params;
};

export const useFilterAppliedRaw = (): AppliedState => {
  const [appliedState] = useContext(FilterDataContext);
  return appliedState;
};

export const useFilterApplied = (filterId?: string, index?: number): | null
  | StateByFilterId
  | string
  | Array<string> => {
  const [appliedState] = useContext(FilterDataContext);
  const { applied, active } = appliedState;

  const res = useMemo(() => {
    if (applied && active) {
      if (filterId) {
        if (typeof index === 'number') {
          const appliedItem: null | string = (
            (active[filterId] && path([filterId, index], applied)) || null
          );
          return appliedItem;
        }
        const appliedItems: null | Array<string> = (
          (active[filterId] && applied[filterId]) || null
        );
        return appliedItems;
      }
      const stateByFilterId: StateByFilterId = pipe(
        toPairs,
        filter(([key]) => prop(key, active)),
      )(applied);
      return stateByFilterId;
    }
    return null;
  }, [active, applied, filterId, index]);

  return res;
};
