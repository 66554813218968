// @flow
import React from 'react';
import classNames from 'classnames';

import IconView from 'app/common/icons/IconView';
import IconEdit from 'app/common/icons/IconEdit';

import Row from './Row';
import enhanceRolesTable from './enhanceRolesTable';
import styles from './RolesTable.scss';


type Props = {
  scopes: Array<string>,
  rows: Array<Object>,
  title: string,
  loading: boolean,
  onChange: (Event) => void,
  tableClassName?: string,
  replace: {
    [key: string]: string
  }
};

const RolesTable = ({ scopes, rows, title, loading, onChange, tableClassName, replace }: Props) => (<div className={styles.root}>
  <div className={styles.header}>{replace[title] || title}</div>
  <table className={classNames(styles.table, tableClassName)}>
    <thead>
      <tr>
        <th>&nbsp;</th>
        {scopes.map(key => (<th colSpan="2" key={key}>
          {key}
        </th>))}
      </tr>
      <tr className={styles.headRow}>
        <th>{'Права'}</th>
        {scopes.map(key => [
          <th key={`${key}_view`}>
            <IconView />
          </th>,
          <th key={`${key}_edit`}>
            <IconEdit />
          </th>,
        ])}
      </tr>
    </thead>
    <tbody>
      {rows.map(({ title: tt, ...props }) => (
        <Row
          {...props}
          title={replace[tt] || tt}
          replace={replace}
          loading={loading}
          onChange={onChange}
        />
      ))}
    </tbody>
  </table>
</div>);

export default enhanceRolesTable(RolesTable);
