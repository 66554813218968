import { useState, useCallback, useEffect, useMemo } from 'react'


type Requests = Array<() => Promise<void>>

export default function useOverallRequest(requestsNonMemoized: Requests): [
  boolean,
  () => Promise<void>
] {
  const [loading, setLoading] = useState(true)

  const requestsMemoized = useMemo(() => (
    requestsNonMemoized
  ), requestsNonMemoized) // eslint-disable-line react-hooks/exhaustive-deps

  const requestAll = useCallback(async () => {
    setLoading(true)
    await Promise.all(requestsMemoized.map(cb => cb()))
    setLoading(false)
  }, [requestsMemoized])

  useEffect(() => {
    requestAll()
  }, [requestAll])

  return [loading, requestAll]
}
