// применить только к указанному комплекту на выбранную дату
export const ONLY_CHOSEN_COMPLEX_FOR_CHOSEN_DATE = 'only_chosen_complex_for_chosen_date';
// применить только к указанному комплекту на выбранную дату и все будущие дни, начиная с выбранной даты
export const ONLY_CHOSEN_COMPLEX_FOR_FUTURE = 'only_chosen_complex_for_future';
// применить ко всем комплектам школы на выбранную дату, где используется указанное блюдо
export const ALL_SCHOOL_COMPLEXES_FOR_CHOSEN_DATE = 'all_school_complexes_for_chosen_date';
// применить ко всем комплектам школы на выбранную дату и все будущие дни, начиная с выбранной даты, где используется указанное блюдо
export const ALL_SCHOOL_COMPLEXES_FOR_FUTURE = 'all_school_complexes_for_future';
// применить ко всем комплектам всех школы на выбранную дату, где используется указанное блюдо
export const ALL_SCHOOLS_FOR_CHOSEN_DATE = 'all_schools_for_chosen_date';
// применить ко всем комплектам всех школына выбранную дату и все будущие дни, начиная с выбранной даты, где используется указанное блюдо
export const ALL_SCHOOLS_FOR_FUTURE = 'all_schools_for_future';

// группы
export const ONE_SCHOOL = 'one_school';
export const ALL_SCHOOLS = 'all_schools';
