// @flow
import { is, reject } from 'ramda';


// let idCount = 0;

export type RawDataItem = {
  id: string,
  nonTrustedSlotName: string,
  maxGoods: number,
  column: number,
  row: number,
  width: number,
  height: number,
  // _destroy?: true
}

type RawDataItemPartial = {
  id?: string,
  nonTrustedSlotName?: string,
  maxGoods?: number,
  column?: number,
  row?: number,
  width?: number,
  height?: number,
  // _destroy?: true
}

export default class PlanogramItem {
  id: string;

  nonTrustedSlotName: string;

  maxGoods: number;

  column: number;

  row: number;

  width: number;

  height: number;

  // destroy: boolean; // Deprecated // теперь ячейки не удаляются, а уменьшается их width до 0
  // TODO требуется рефакторинг в ts

  constructor(options: RawDataItem) {
    Object.assign(this, options);
  }

  get raw(): RawDataItem {
    return reject(is(Function), this);
  }

  duplicate(updateProps?: (self: PlanogramItem) => RawDataItemPartial): PlanogramItem {
    const data = reject(is(Function), this);
    const newProps: RawDataItem = {
      ...data,
      ...(updateProps ? updateProps(this) : {}),
    };
    return new PlanogramItem(newProps);
  }

  // createId(): PlanogramItem { // deprecated // ячейки больше не удаляются
  //   this.id = `new${++idCount}`;
  //   return this.duplicate();
  // }

  // isCreatedItem(): boolean {
  //   return /^new/.test(this.id);
  // }
}
