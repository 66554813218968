import { FunctionComponentElement } from 'react'

import useRightsByOrgData from './useRightsByOrgData'
import childrenWithDisabledProp from '../utils/childrenWithDisabledProp'
import extractRightsByPaths from '../utils/extractRightsByPaths'
import { RightsPathUnrestricted } from '../types'

/**
 *  Для оборачивания компонентов которым требуются права доступа.
 *  ПО УМОЛЧАНИЮ:
 *  Возвращает null если нет прав даже на чтение.
 *  Добавляются дополнительные props:
 *    + disabled - true, пока права не загружены или есть права только на чтение
 */

type Props = {
  children: FunctionComponentElement<{ disabled?: boolean }>
  pathTo: RightsPathUnrestricted
  othersPaths?: Array<RightsPathUnrestricted>
  combineWithOthersPaths?: 'all' | 'any'
  orgId: number
  // readOnlyHidden?: boolean, // depreceted, use readAffect: 'hide' + editAffect: 'hide'
  readAffect?: 'hide' | 'disable' | 'ignore'
  editAffect?: 'hide' | 'disable' | 'ignore'
}

const WithRightsByOrg = ({
  children,
  pathTo,
  othersPaths = [],
  combineWithOthersPaths = 'all',
  orgId,
  readAffect = 'hide',
  editAffect = 'disable',
}: Props) => {
  const [{ su, roleIdByOrgId, rights, ready }] = useRightsByOrgData()

  // права не готовы - disabled
  if (!ready) {
    if (readAffect === 'hide' && editAffect === 'hide') {
      return null
    }
    return childrenWithDisabledProp(children, true)
  }

  // полный доступ
  if (su) {
    return childrenWithDisabledProp(children, false)
  }

  const roleId = roleIdByOrgId[String(orgId)]
  const { edit, read } = extractRightsByPaths({
    paths: [pathTo, ...othersPaths].map(pth => ([roleId, ...pth])),
    rights,
    combineWithOthersPaths,
  })

  const hide = (
    (readAffect === 'hide' && !read)
    || (editAffect === 'hide' && !edit)
  )
  if (hide) return null

  const disable = (
    (readAffect === 'disable' && !read)
    || (editAffect === 'disable' && !edit)
  )
  if (disable) return childrenWithDisabledProp(children, true)

  return children
}

export default WithRightsByOrg
