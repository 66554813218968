// @flow
import React, { type Node } from 'react';

import styles from './right-bar-content.scss';


type Props = {
  children: Node,
}

const RightBarContent = ({ children }: Props) => (
  <div className={styles.root}>
    {children}
  </div>
);

export default RightBarContent;
