// @flow
import { map } from 'ramda';

import type { Categories, Params } from 'app/dataTypes/vending/production/Categories.types';

import type { Request } from '../../../../index';


type ParamsApi = {
  place_id: number | string,
}

type ResultApi = Array<{
  id: number,
  place_id: number,
  title: string,
  index: number | null,
  deleted: boolean,
  deletable: boolean,
  quantity: number
}>

export const convertParams = ({ placeId }: Params): [ParamsApi, null] => {
  return [{ place_id: placeId }, null];
};

export const convertResult = (data: ResultApi): Categories => {
  return map(({
    id,
    place_id: placeId,
    title,
    index,
    deleted,
    deletable,
    quantity,
  }) => ({
    id: String(id),
    placeId: String(placeId),
    title,
    index,
    deleted,
    deletable,
    quantity,
  }), data);
};

export const GET_VENDING_ITEMS_CATEGORIES: Request<Params, Categories, void> = [
  'data/vending/items/categories',
  'get',
  ['data', 'data'],
];
