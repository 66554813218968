// @flow

import * as vendingSatuses from './vendingStatuses';


const spotStatus = (vendingEnabled: boolean, hasMachines: boolean) => {
  if (vendingEnabled) {
    return vendingSatuses.VENDING_ENABLED;
  }
  if (!vendingEnabled && hasMachines) {
    return vendingSatuses.VENDING_READ_ONLY;
  }
  return vendingSatuses.VENDING_DISABLED;
};

export default spotStatus;
