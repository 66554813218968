import { Fragment, useCallback, useMemo, ChangeEvent } from 'react'

import { PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid'
import type { SingleRequisites, PaymentOrderPurpose, PaymentOrderCreateMethod } from 'app/dataTypes/requisites'
import PlainSelect, { UniProps } from 'app/common/ui/PlainSelect'
import PlainCheckbox, { PlainCheckboxGroup } from 'app/common/ui-next/plain-checkbox'
import { paymentOrderCreateMethods } from 'app/dataTypes/requisites'
import PlainFieldset from 'app/common/ui-next/plain-fieldset'

import { paymentOrderMethodItems } from './paymentOrderMethodItems'
import { paymentOrderPurposeItems } from './paymentOrderPurposesItems'
// import { paymentOrderCreateMethodsItems } from './paymentOrderCreateMethodsItems';
import { ChangeHandlerType } from '../useData'

import styles from './AdditionalForm.scss'


type Props = {
  value: SingleRequisites
  valueBasePath: string
  onChange: ChangeHandlerType
  loading?: boolean
  locked?: boolean
}

const AdditionalForm = ({
  value,
  valueBasePath,
  onChange,
  loading,
  locked,
}: Props) => {
  const handleChangeField = useCallback((e: UniProps) => {
    const { value: val, name } = e.currentTarget
    onChange(`${valueBasePath}.${name}`, String(val))
  }, [valueBasePath, onChange])

  const handleUpdatePurposes = useCallback((val: string, checked: boolean) => {
    const newValue = new Set(value.paymentOrderPurpose)
    if (checked) {
      newValue.add(val as PaymentOrderPurpose)
    } else {
      newValue.delete(val as PaymentOrderPurpose)
    }
    onChange(`${valueBasePath}.paymentOrderPurpose`, newValue)
  }, [value.paymentOrderPurpose, valueBasePath, onChange])

  const handleUpdateCreateMethods = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value: val } = e.currentTarget
    const newValue = new Set(value.paymentOrderCreateMethods)
    if (checked) {
      newValue.add(val as PaymentOrderCreateMethod)
    } else {
      newValue.delete(val as PaymentOrderCreateMethod)
    }
    onChange(`${valueBasePath}.paymentOrderCreateMethods`, newValue)
  }, [value.paymentOrderCreateMethods, valueBasePath, onChange])

  const currentPaymentOrderPurposes = useMemo(() => {
    return Array.from(value.paymentOrderPurpose.values())
  }, [value.paymentOrderPurpose])

  const currentPaymentOrderCreateMethods = useMemo(() => {
    return Array.from(value.paymentOrderCreateMethods.values())
  }, [value.paymentOrderCreateMethods])

  return (
    <Fragment>
      <PlainColumns className={styles.row}>
        <PlainColumn div={2}>
          <PlainFieldset
            legend="Выставление квитанции:"
            name="paymentOrderCreateMethods"
            className={styles.fieldSet}
            legendClassName={styles.legend}
          >
            <PlainCheckbox
              label="С помощью API внешних систем"
              value={paymentOrderCreateMethods.Api}
              name="paymentOrderCreateMethods"
              disabled
              onChange={handleUpdateCreateMethods}
              checked
            />
            <PlainCheckbox
              label="Вручную в web-приложении «Личный кабинет сотрудника ОУ»"
              value={paymentOrderCreateMethods.Web}
              name="paymentOrderCreateMethods"
              disabled={loading || locked}
              onChange={handleUpdateCreateMethods}
              checked={currentPaymentOrderCreateMethods.includes(paymentOrderCreateMethods.Web)}
            />
            <PlainCheckbox
              label="Автоматически по заданным параметрам"
              value={paymentOrderCreateMethods.Auto}
              name="paymentOrderCreateMethods"
              disabled
              onChange={handleUpdateCreateMethods}
            />
          </PlainFieldset>
        </PlainColumn>
      </PlainColumns>

      <PlainColumns className={styles.row}>
        <PlainColumn div={4}>
          <PlainSelect
            label="Вид квитанции"
            name="paymentOrderMethod"
            current={value.paymentOrderMethod}
            placeholder="Не указано"
            onChange={handleChangeField}
            disabled={loading || locked}
            data={paymentOrderMethodItems}
            uni
          />
        </PlainColumn>
      </PlainColumns>

      <PlainColumns className={styles.row}>
        <PlainColumn div={2}>
          <PlainCheckboxGroup
            label="Назначение платежа"
            id="paymentOrderPurpose"
            items={paymentOrderPurposeItems}
            current={currentPaymentOrderPurposes}
            disabled={loading || locked}
            onChange={handleUpdatePurposes}
            skipUpperSpace
            singleMustChecked
          />
        </PlainColumn>
      </PlainColumns>
    </Fragment>
  )
}

export default AdditionalForm
