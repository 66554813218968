// @flow
import type { Request } from '../../index';

/**
 *  Запросы получения, удаления и изменения/добавления продукции
 */

export type FoodItemsRequestParams = {
  saleType?: string,
  search?: string,
  limit?: number,
  offset?: number,
  nomenclature?: string,
}

export type FoodItem = {
  id: number,
  title: string,
  photoUrl: string | null,
  cost: string,
  portion: string,
  saleType: string,
  nomenclature: string | null,
}

export type FoodItems = {
  [id: string]: FoodItem,
}

export type FoodItemsRequestResult = {
  result: 'success',
  count: number,
  items: FoodItems,
}

export const GET_FOOD_ITEMS: Request<
  FoodItemsRequestParams,
  FoodItemsRequestResult
> = [
  'food/items',
  'get',
  ['data'],
];

// удаление

export type FoodItemsDeleteRequestParams = {
  item_id: Array<number>,
}

export const DELETE_FOOD_ITEMS: Request<
  FoodItemsDeleteRequestParams,
  'success'
> = [
  'food/items',
  'delete',
  ['data', 'result'],
];

// создание

export type PhotoValue = {
  file: string,
  o?: number,
  w: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
}

export type FoodItemsPostRequestParams = {
  id?: number, // только при редактировании
  title: string,
  photo?: PhotoValue,
  portion?: string,
  cost: number,
  saleType: string,
}

export type FoodItemsPostRequestResult = {
  result: 'success',
  [id: string]: FoodItem,
}

export const POST_FOOD_ITEMS: Request<
  FoodItemsPostRequestParams,
  FoodItemsPostRequestResult
> = [
  'food/items',
  'post',
  ['data'],
];
