// @flow
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { popup } from 'app/common/actions/popup';


export default function usePopup() {
  const dispatch = useDispatch();

  const handlePopup = useCallback((message: string) => dispatch(popup(message)), [dispatch]);

  return handlePopup;
}
