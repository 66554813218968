// @flow
import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';

import filterGetItemsRoles from './filterGetItemsRoles';


const FILTER_BY_ROLE: 'role' = 'role';
const FILTER_BY_JOB_PLACE: 'jobPlace' = 'jobPlace';

export const filterIds = { FILTER_BY_ROLE, FILTER_BY_JOB_PLACE };

const filterItems: Descriptions<> = [{

  /* фильтр по должности */
  filterId: FILTER_BY_ROLE,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Должность',
    header: 'Выберите должность',
    colorInverse: true,
  },
  getItemsAndCurrent: filterGetItemsRoles,
}];

export default filterItems;
