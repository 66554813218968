import { ComplexGroceryType } from 'app/dataTypes/org/menu'

import type { Request } from '../../../index'


export type ComplexGrocerySetParams = {
  school_id: number,
  complex_id: number,
  date: string,
  price: number | null
}

export const convertParams = (data: ComplexGroceryType): [ComplexGrocerySetParams, null] => {
  const result: ComplexGrocerySetParams = {
    school_id: data.orgId,
    complex_id: data.kitId,
    date: data.date,
    price: data.grocerySetPrice ? Number(data.grocerySetPrice) : null,
  }
  return [result, null]
}

type ApiResult = {
  result: 'success',
}

export const convertResult = (result: ApiResult): boolean => {
  return !!result
}

export const POST_COMPLEX_GROCERY_SET: Request<ComplexGrocerySetParams, boolean, void> = [
  'food/menu/complex_grocery_set',
  'post',
  ['data'],
]
