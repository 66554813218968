// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';
import { studentStatuses as ss } from 'app/common/api/requests/reports/perform';

import { filterId } from './filterId';


export const studentStatusActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const filterActive = studentStatusActive;

export const minorStudentStatusSelected = (state: State): boolean => {
  const studentStatuses = getStateCurrentValue(filterId, state) || [];
  return (
    studentStatuses.length === 1
    && studentStatuses[0] !== ss.ACTIVE
  );
};

export const majorStudentStatusSelected = (state: State): boolean => {
  const studentStatuses = getStateCurrentValue(filterId, state) || [];
  return (
    studentStatuses.length === 1
    && studentStatuses[0] === ss.ACTIVE
  );
};
