import { useCallback } from 'react'
import moment from 'moment-timezone'

import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog'
import PlainLink from 'app/common/ui-next/plain-link'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import RestrictionsSettingsModal from 'app/components/common/RestrictionsSettingsModal'
import history from 'app/history'
import { replaceLast, replaceLastN } from 'app/common/lib/path'
import Modal from 'app/common/components/Modal'

import { WithRightsForOrg } from 'app/rights/RightsForOrg'
import * as rootRights from 'app/common/constants/rights/rootRights'
import * as specificRights from 'app/common/constants/rights/specificRights'

import PlainLinkTooltiped from './PlainLinkTooltiped'
import RefreshOrders from './RefreshOrders'
import CopyKit from '../../CopyKit'
import Dishes2MenuForm from './Dishes2MenuForm'
import Kit2MenuForm from './Kit2MenuForm'
import AdditionalSettingsModal from './AdditionalSettingsModal'
import { ValidityByDate } from '../../../commonComponents/ValidityByDate'

import styles from './RightBarContent.scss'


const menuRights = [rootRights.MENU, specificRights.MENU] as const
const complexesRigths = [rootRights.COMPLEXES, specificRights.COMPLEXES] as const
const complexesCompositionRigths = [rootRights.COMPLEXES, specificRights.COMPLEXES_COMPOSITION] as const


type Props = {
  id: number
  title: string
  orgID: string
  date: string
  noDishes: boolean
  subsidyOrders: boolean
  deletable: boolean
  fixedPriceEnabled: boolean
  activeAction: string | null
  hideDishes2Menu?: boolean
  hideKit2Menu?: boolean
  hideSettings?: boolean
  deleteKit: () => Promise<void>
  hideKit: () => Promise<void>
  buffetZone: boolean
  hotMealsZone: boolean
  denyParentMoneySales: boolean
  denyMoreThanOneSales: boolean
  useAsAdditional: boolean
}

const RightBarContent = ({
  id,
  title,
  orgID,
  date,
  noDishes,
  subsidyOrders,
  deletable,
  fixedPriceEnabled,
  activeAction,
  hideDishes2Menu,
  hideKit2Menu,
  hideSettings,
  deleteKit,
  hideKit,
  buffetZone,
  hotMealsZone,
  denyParentMoneySales,
  denyMoreThanOneSales,
  useAsAdditional,
}: Props) => {
  const { pathname } = window.location
  const basePath = activeAction ? replaceLastN(pathname, 2) : replaceLast(pathname)
  const returnPath = `${basePath}/current`

  const handleReturnFromAction = useCallback(() => {
    history.push(returnPath)
  }, [returnPath])

  return (
    <WithRightsForOrg pathTo={complexesRigths} readAffect="hide" editAffect="hide">
      <ValidityByDate affect="hide">
        <div>
          <ul className={styles.root}>
            {!hideDishes2Menu
              && <li>
                <WithRightsForOrg pathTo={menuRights} editAffect="hide">
                  <PlainLinkTooltiped
                    to={`${basePath}/dishes2menu/${id}`}
                    disabled={noDishes || activeAction === 'dishes2menu'}
                  >
                    {'Добавить эту продукцию в меню'}
                  </PlainLinkTooltiped>
                </WithRightsForOrg>
              </li>}

            {!hideKit2Menu
              && <li>
                <WithRightsForOrg pathTo={menuRights} editAffect="hide">
                  <PlainLinkTooltiped
                    to={`${basePath}/kit2menu/${id}`}
                    disabled={noDishes || activeAction === 'kit2menu'}
                  >
                    {'Добавить комплект в меню'}
                  </PlainLinkTooltiped>
                </WithRightsForOrg>
              </li>}

            <li>
              <PlainLinkTooltiped
                to={`${basePath}/copyKit/${id}`}
                disabled={noDishes || activeAction === 'copyKit'}
              >
                {'Копировать комплект'}
              </PlainLinkTooltiped>
            </li>

            {/* Скрытие */}
            {noDishes
              && subsidyOrders
              && <li>
                <ConfirmationDialog
                  onConfirm={hideKit}
                  text={`Вы уверены, что хотите скрыть комплект «${title}»?`}
                  confirmButtonText="Скрыть"
                  confirmButtonProps={{ warning: true }}
                >
                  {handleConfirm => (
                    <PlainButtonAsLink onClick={handleConfirm}>
                      {'Скрыть комплект'}
                    </PlainButtonAsLink>
                  )}
                </ConfirmationDialog>
              </li>}

            {/* Удаление link */}
            {noDishes
              && deletable
              && !subsidyOrders
              && <li>
                <ConfirmationDialog
                  onConfirm={deleteKit}
                  text={`Вы уверены, что хотите удалить комплект «${title}»?`}
                  confirmButtonText="Удалить"
                  confirmButtonProps={{ error: true }}
                >
                  {handleConfirm => (
                    <PlainButtonAsLink onClick={handleConfirm}>
                      {'Удалить комплект'}
                    </PlainButtonAsLink>
                  )}
                </ConfirmationDialog>
              </li>}

            {!noDishes
              && fixedPriceEnabled
              && <li>
                <RefreshOrders
                  orgId={parseInt(orgID, 10)}
                  complexId={id}
                  date={date}
                  title={title}
                  message={`Обновить комплект «${title}» в уже созданных заявках на ${moment(date).format('DD.MM.YYYY')}?`}
                >
                  {handleConfirmationRefreshOrders => (
                    <PlainButtonAsLink onClick={handleConfirmationRefreshOrders}>
                      {'Обновить заявки'}
                    </PlainButtonAsLink>
                  )}
                </RefreshOrders>
              </li>}

            {!hideSettings
              && <li>
                <PlainLink to={`${basePath}/settings/${id}`} disabled={activeAction === 'settings'}>
                  {'Настройки'}
                </PlainLink>
              </li>}

            {!hideSettings
              && <AdditionalSettingsModal
                orgID={orgID}
                complexID={String(id)}
                buffetZone={buffetZone}
                hotMealsZone={hotMealsZone}
                denyParentMoneySales={denyParentMoneySales}
                denyMoreThanOneSales={denyMoreThanOneSales}
                useAsAdditional={useAsAdditional}
              >
                {toggle => (
                  <PlainButtonAsLink onClick={toggle}>
                    {'Дополнительные настройки'}
                  </PlainButtonAsLink>
                )}
              </AdditionalSettingsModal>}
          </ul>

          {/* Добавление блюд комплекта в меню */}
          {activeAction === 'dishes2menu'
            && <WithRightsForOrg pathTo={complexesCompositionRigths} editAffect="hide">
              <Modal close={returnPath}>
                <Dishes2MenuForm
                  orgId={orgID}
                  kitId={id}
                  date={date}
                  returnTo2={returnPath}
                />
              </Modal>
            </WithRightsForOrg>}


          {/* Добавление комплекта в меню */}
          {activeAction === 'kit2menu'
            && <WithRightsForOrg pathTo={complexesCompositionRigths} editAffect="hide">
              <Modal close={returnPath}>
                <Kit2MenuForm
                  orgId={parseInt(orgID, 10)}
                  kitId={id}
                  date={date}
                  returnTo2={returnPath}
                />
              </Modal>
            </WithRightsForOrg>}

          {/* Копирование комплекта */}
          {activeAction === 'copyKit'
            && <CopyKit
              orgID={orgID}
              kitID={String(id)}
              header={`Копирование комплекта «${title}»`}
              date={date}
              fixedPriceEnabled={fixedPriceEnabled}
              onClose={handleReturnFromAction}
            />}

          {/* Настройки комплекта */}
          {activeAction === 'settings'
            && <RestrictionsSettingsModal
              header="Настройки"
              headPhrase={`Для кого используется комплект «${title}»`}
              orgId={parseInt(orgID, 10)}
              complexId={id}
              onClose={handleReturnFromAction}
            />}
        </div>
      </ValidityByDate>
    </WithRightsForOrg>
  )
}

export default RightBarContent
