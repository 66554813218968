// @flow
import React, { useEffect } from 'react';

import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';

import useBuildingsData from '../../commonHooks/useBuildingsData';
import type { MenuTargetItemState } from './useMenuTargetItemState';
import useMenuTargetItemHandling from './useMenuTargetItemHandling';

/**
 *  Выбор корпуса для меню
 */

type Props = {
  orgId: number,
  direction: 'from' | 'to',
  buildingState: MenuTargetItemState,
  disabled?: boolean,
  onLoading?: (state: boolean) => void,
  defaultState?: number | null,
}

const MenuBuildingSelector = ({
  orgId,
  buildingState,
  direction,
  disabled,
  onLoading,
  defaultState,
}: Props) => {
  const [[{ itemsCurrent: buildings }, buildingsLoading]] = useBuildingsData(orgId);

  const [buildingCurrent, buildingCurrentSingle] = buildingState;
  const handleRadioButtonChange = useMenuTargetItemHandling(
    buildingState,
    buildings,
    defaultState,
  );

  const unicSetId = `buildings_${direction}`;

  useEffect(() => {
    if (onLoading) {
      onLoading(buildingsLoading);
    }
  }, [buildingsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (buildingCurrentSingle) {
    return null;
  }

  return (
    <PlainFieldset
      legend={direction === 'from' ? 'Из корпуса' : 'В корпус'}
      name={unicSetId}
    >
      {buildings.map(({ id, title }) => (
        <PlainRadioButton
          key={id}
          value={String(id)}
          checked={buildingCurrent === id}
          label={title}
          name={unicSetId}
          disabled={buildingsLoading || disabled}
          onChange={handleRadioButtonChange}
        />
      ))}
    </PlainFieldset>
  );
};

export default MenuBuildingSelector;
