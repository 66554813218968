import { combineReducers } from 'redux';

import items from './items';
import options from './options';
import roles from './roles';
import hireform from './hireform';
import filter from './filter';
import employee from './employee';

export default combineReducers({ items, options, roles, hireform, filter, employee });
