// @flow
import React from 'react';
import classNames from 'classnames/bind';

import Tooltip from 'app/common/ui/Tooltip';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import IconMail from './icons/IconMail';
import styles from './history-mail-button.scss';


const cx = classNames.bind(styles);

type Props = {
  className?: string,
  disabled?: boolean,
  toolTipText?: string,
  onClick: () => void | Promise<void>,
}

const HistoryMailButton = ({
  className,
  disabled,
  toolTipText = 'Отправить файл на e-mail',
  onClick,
}: Props) => {
  return (
    <Tooltip text={toolTipText} className={className}>
      <PlainButtonAsLink onClick={onClick} disabled={disabled}>
        <IconMail className={cx(styles.icon, { disabled })} />
      </PlainButtonAsLink>
    </Tooltip>
  );
};

export default HistoryMailButton;
