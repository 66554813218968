// @flow

export interface IObservable {
  subscribe(callback: () => void): () => void,
  updateSubscribers(): void
}

export default class Observable implements IObservable {
  #subscribers: Set<() => void>;

  updateSubscribers: () => void;

  constructor() {
    this.#subscribers = new Set();
    this.updateSubscribers = this.updateSubscribers.bind(this);
  }

  subscribe(callback: () => void, additional?: () => void) {
    this.#subscribers.add(callback);
    return () => {
      if (additional) {
        additional();
      }
      this.#subscribers.delete(callback);
    };
  }

  updateSubscribers() {
    this.#subscribers.forEach(callback => callback());
  }
}
