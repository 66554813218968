import { ID } from 'app/common/ui-next/_filter-compilations/predefined-ids'
import { canMailingTo } from 'app/common/ui-next/_filter-compilations/mailing'


export type ApiCorrectionsParams = {
  report_type: string
  params: {
    from: string,
    to: string,
    operation_type: 'purchases' | 'subsidies', //  тип операции, purchases - продажи, subsidies - дотации
    operation_age: 'selected' | 'all', //          давность операции, all - все время, selected - выбранный период
    limits: {
      school_ids: number[],
    },
  },
  options?: {
    send_to_email?: string, // отправить на почту
  },
}

const geFormDataFromSelector = (formItem: ID, formData: FormData) => {
  const value = formData.getAll(formItem) as (Array<string> | undefined)
  if (value && value.length) return value
  return []
}

export const convertParams = (formData: FormData): [ApiCorrectionsParams, null] => {
  const orgIDs = geFormDataFromSelector(ID.org, formData)
  const operationsType = (formData.get(ID.operationsType)) as 'purchases' | 'subsidies'
  const operationsAge = (formData.get(ID.operationAge)) as 'selected' | 'all'
  const periodFrom = formData.get(`${ID.periodDateFormat}From`) as string
  const periodTo = formData.get(`${ID.periodDateFormat}To`) as string
  const email = canMailingTo(formData)
  const options = email ? { send_to_email: email } : {}
  return [{
    report_type: 'corrections',
    params: {
      from: periodFrom,
      to: periodTo,
      operation_type: operationsType,
      operation_age: operationsAge,
      limits: {
        school_ids: orgIDs.map(schoolID => parseInt(schoolID, 10)),
      },
    },
    options,
  }, null]
}
