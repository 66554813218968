import get from 'app/common/lib/get';
import {
  EMPLOYEES_REQUEST,
  EMPLOYEES_SUCCESS,
  EMPLOYEES_SUCCESS_ONE,
  EMPLOYEES_FAILURE,
  EMPLOYEES_CLEAR,
  EMPLOYEES_CANCEL,
} from 'app/actions/employees';

import {
  EMPLOYEES_SET_PAGE,
  EMPLOYEES_SEARCH,
  EMPLOYEES_NEED_UPDATE,
} from 'app/actions/employees/options';

const defaultState = {
  loading: true,
  count: 0,
  page: 1,
  searchString: '',
  needUpdate: false,
};

const options = (state = defaultState, action) => {
  if (action.type === EMPLOYEES_SUCCESS) {
    return ({
      ...state,
      count: get(action, ['payload', 'count'], 0),
      loading: false,
    });
  }
  if (action.type === EMPLOYEES_SUCCESS_ONE) {
    return ({
      ...state,
      loading: false,
    });
  }
  if (action.type === EMPLOYEES_REQUEST) {
    return ({
      ...state,
      loading: true,
    });
  }
  if (action.type === EMPLOYEES_FAILURE) {
    return ({
      ...state,
      loading: false,
    });
  }
  if (action.type === EMPLOYEES_CLEAR) {
    return defaultState;
  }
  if (action.type === EMPLOYEES_SET_PAGE) {
    return ({
      ...state,
      page: action.payload,
    });
  }
  if (action.type === EMPLOYEES_SEARCH) {
    return ({
      ...state,
      searchString: action.payload,
    });
  }
  if (action.type === EMPLOYEES_NEED_UPDATE) {
    return ({
      ...state,
      needUpdate: action.payload,
    });
  }
  if (action.type === EMPLOYEES_CANCEL) {
    return ({
      ...state,
      loading: false,
    });
  }
  return state;
};

export default options;
