import { ReactNode, createContext, useMemo } from 'react'
import { identity } from 'ramda'

import SimpleDate from 'app/common/classes/simple-date'
import { useRequestOrgs } from 'app/dataProviders/org/hooks'
import { OrgsData } from 'app/dataTypes/org'


const transform = identity

const defaultState: OrgsData = {
  items: new Map(),
  total: 0,
}

type ContextProps = {
  validity: boolean
  loading: boolean
  contextAvailable: boolean
}

export const ValidityByDateContext = createContext<ContextProps>({
  validity: false,
  loading: true,
  contextAvailable: false,
})

type Props = {
  orgID: string
  currentDate: SimpleDate
  children: ReactNode
}

export const ValidityByDateProvider = ({ orgID, currentDate, children }: Props) => {
  const { orgs, loading } = useRequestOrgs({ params: { ID: orgID }, transform, defaultState })
  const org = orgs.items.get(orgID)
  const validity = org?.availableFrom ? currentDate.isSameOrAfter(org?.availableFrom) : true

  const value = useMemo(() => {
    return { validity, loading, contextAvailable: true }
  }, [validity, loading])

  return (
    <ValidityByDateContext.Provider value={value}>
      {children}
    </ValidityByDateContext.Provider>
  )
}
