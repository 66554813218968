// @flow
import { useState, useEffect, useCallback } from 'react';

import usePopup from 'app/common/hooks/usePopup';


type ProcessStatus = 'initial' | 'spin' | 'error' | 'hidden'

type State = {
  processId: number | null, // устанавливается при успешном начале генерации
  generatedReportId: number | null, // устанавливается при успешном окончании генерации
  progress: number,
  processStatus: ProcessStatus,
}

export type Handlers = {
  onCancel: () => void,
}

export type ProcessGenerateOptions = {
  ready: any, // изменение данного свойства приведет к перезапуску процесса индикации
  interval?: number,
  onlyMail?: boolean,
  onProcessRequest: () => Promise<number | string | null>, // string - текст ошибки, null - ничего не делать
  onProcessCheckStatus: (reportId: number) => Promise<number | string>, // string - текст ошибки
  onProcessCancel?: (reportId: number) => Promise<void>,
}

export default function useProcessGenerate({
  ready,
  interval = 3000,
  waitBeforeHide = 1000,
  onlyMail,
  onProcessRequest,
  onProcessCheckStatus,
  onProcessCancel,
}: ProcessGenerateOptions): [State, Handlers] {
  const [processId, setProcessId] = useState<null | number>(null);
  const [generatedReportId, setGeneratedReportId] = useState<null | number>(null);
  const [processStatus, setProcessStatus] = useState<ProcessStatus>('hidden');
  const [progress, setProgress] = useState(0);

  const [, setTimerId] = useState(null);
  const popup = usePopup();

  const stopAndClear = useCallback((status: ProcessStatus) => {
    setTimerId((id) => {
      if (id) clearTimeout(id);
      return null;
    });
    setProcessId(null);
    setProcessStatus(status);
    setProgress(0);
  }, []);


  // вызов через интервал
  const handleProgress = useCallback(async () => {
    if (!processId) return;
    setProcessStatus('spin');

    const currentProgress = await onProcessCheckStatus(processId);

    if (typeof currentProgress === 'number') {
      setProgress(currentProgress);
      if (currentProgress === 100) {
        setTimeout(() => {
          setProcessStatus('hidden');
          setGeneratedReportId(processId);
        }, waitBeforeHide);
        return;
      }
      // следующее ожидание
      setTimerId(setTimeout(handleProgress, interval));
      return;
    }
    setProcessStatus('error');
  }, [interval, processId, onProcessCheckStatus, waitBeforeHide]);


  // начало
  const requestProcess = useCallback(async () => {
    if (processStatus === 'initial') {
      return; // ничего не делать, если уже идет начальный процесс
    }
    stopAndClear('initial'); // остановка предыдущего процесса, если он был

    const res = await onProcessRequest();

    if (typeof res === 'number') {
      setProcessId(res);
      return;
    }
    if (typeof res === 'string') {
      setProcessStatus('error');
    }
    // ничего не делать, если null
  }, [onProcessRequest, processStatus, stopAndClear]);


  // только отправка на почту, без индикации процесса
  const requestOnlyMail = useCallback(async () => {
    const res = await onProcessRequest();
    if (typeof res === 'number') {
      popup('Отчет успешно добавлен в очередь на генерацию и после завершения будет отправлен на ваш e-mail');
    }
  }, [onProcessRequest, popup]);


  // запуск
  useEffect(() => {
    if (ready) {
      if (!onlyMail) {
        requestProcess();
        return;
      }
      requestOnlyMail();
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps


  // запуск таймера
  useEffect(() => {
    if (processId) {
      setTimerId(setTimeout(handleProgress, interval));
    }
  }, [processId]); // eslint-disable-line react-hooks/exhaustive-deps


  // сброс сгенерированного если изменилась функция начала генерации
  useEffect(() => {
    setGeneratedReportId(null);
  }, [onProcessRequest]);


  // destructor
  useEffect(() => () => {
    setTimerId((id) => {
      clearTimeout(id);
      return null;
    });
  }, []);


  // cancel
  const handleCancel = useCallback(() => {
    stopAndClear('hidden');
    if (processId && onProcessCancel) {
      onProcessCancel(processId);
    }
  }, [stopAndClear, onProcessCancel, processId]);


  return [{
    processId,
    generatedReportId,
    progress,
    processStatus,
  }, {
    onCancel: handleCancel,
  }];
}
