import {
  ORGS_SELLS_SUCCESS,
  ORGS_SELLS_CLEAR,
} from 'app/actions/orgs/sells';


const defaultState = {
};

const sells = (state = defaultState, action) => {
  switch (action.type) {
    case ORGS_SELLS_SUCCESS: {
      return { ...state, ...action.payload };
    }

    case ORGS_SELLS_CLEAR: {
      return defaultState;
    }

    default:
  }
  return state;
};

export default sells;
