/* eslint-disable ft-flow/no-types-missing-file-annotation */
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import type { HOC } from 'recompose';

import Loader from 'app/common/components/Loader';

import { kitsNeedUpdate } from 'app/actions/orgs/kits/options';
import { kitsFetch, kitsStateClear } from 'app/actions/orgs/kits';
import selectOrgsKits from 'app/selectors/OrgsList/selectOrgsKits';


// -----------------
// Комплекты организации (школы)
// -----------------

type Props = {
  orgId: string | number,
  date: string,
  deleted?: boolean,
  selectOrgsKits?: (Object, Object) => Object | Array<Object>,
  skipEmpty?: boolean,
};

const withOrgsKits: HOC<*, Props> = compose(
  connect(
    (state, props) => console.log(props) || ({
      // items: (props.selectOrgsKits
      //   ? props.selectOrgsKits(state, props)
      //   : selectOrgsKits(state, props)),
      items: selectOrgsKits(state, props),
      loading: state.orgs.kits.options.loading,
      needUpdate: state.orgs.kits.options.needUpdate,
    }),
  ),

  lifecycle({
    componentDidMount() {
      const { dispatch, orgId, date, deleted, skipEmpty } = this.props;
      dispatch(kitsFetch(orgId, deleted
        ? { deleted }
        : { date, deleted, include_subsidy_orders: true }, !skipEmpty));
    },
    async componentDidUpdate(prevProps) {
      const { dispatch, orgId, date, deleted, needUpdate, skipEmpty } = this.props;
      // --- обновление после окна редактирования
      if (needUpdate) {
        if (needUpdate !== prevProps.needUpdate) {
          await dispatch(kitsFetch(orgId, deleted
            ? { deleted }
            : { date, deleted, include_subsidy_orders: true }, !skipEmpty));
        }
        dispatch(kitsNeedUpdate(false));
      }
      // изменение параметров, требуется обновление данных
      if (prevProps.date !== date
        || prevProps.deleted !== deleted
        || prevProps.orgId !== orgId
      ) {
        dispatch(kitsFetch(orgId, deleted
          ? { deleted }
          : { date, deleted, include_subsidy_orders: true }, !skipEmpty));
      }
    },
    componentWillUnmount() {
      this.props.dispatch(kitsStateClear());
    },
  }),

  branch(
    props => (props.loading && !props.items.length),
    renderComponent(Loader),
  ),
);

export default withOrgsKits;
