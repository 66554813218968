import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import Message from 'app/common/ui/Message';


export default compose(
  connect(
    state => ({
      text: state.confirmation.text,
      buttons: state.confirmation.buttons,
    }),
  ),

  branch(props => !props.buttons.length, renderNothing),
)(Message);
