import { createPath } from '../../routePaths'
import { getParam } from '../../routeProps'


export enum SettingsRoute {
  Documents = 'documents'
}

export const isSettingsRoute = (val: any): val is SettingsRoute => {
  return Object.values(SettingsRoute).includes(val)
}

export const pathMask = '/compensation/settings/:subSection'

type Options = {
  subSection?: SettingsRoute
}

export const getURL = (options: Options = {} as Options) => {
  return createPath(options, pathMask)
}

export const getSection = (props: any) => {
  const section = getParam('subSection', props)
  if (isSettingsRoute(section)) return section
  return null
}
