import { ReactNode } from 'react'

import PlainButton from 'app/common/ui-next/plain-button'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal'

import useRestrictions from './useRestrictions'
import RestrictionsSettingsForm from './RestrictionsSettingsForm'
import styles from './RestrictionsSettingsModal.scss'


const emptyProps = Object.freeze({})

type Props = {
  header: string
  headPhrase: string
  orgId?: number
  complexId?: number
  dishId?: number
  children?: (handleVisibility: HandleModalDisplayed) => ReactNode
  onClose?: () => void
}

const RestrictionsSettingsModal = ({
  header,
  headPhrase,
  orgId,
  complexId,
  dishId,
  children,
  onClose,
}: Props) => {
  const [{
    restrictions,
    loading,
    saving,
  }, {
    request,
    change,
    updateAllowances,
    submit,
  }] = useRestrictions({ orgId, complexId, dishId, onSubmitSuccess: onClose })

  const viewProps = children ? emptyProps : { show: true }

  return (
    <PlainModal
      className={styles.root}
      controlBy={children}
      onSubmit={submit}
      {...viewProps}
    >
      <PlainModalHeader
        onCrossClick={onClose}
      >
        {header}
      </PlainModalHeader>
      <PlainModalContent className={styles.headPhrase}>
        {headPhrase}
      </PlainModalContent>
      <PlainModalContent className={styles.form}>
        <RestrictionsSettingsForm
          orgID={String(orgId)}
          onLoadFormData={request}
          onChange={change}
          onUpdateAllowances={updateAllowances}
          restrictions={restrictions}
          disabled={loading || saving}
        />
      </PlainModalContent>
      <PlainModalFooter>
        {handleModalDisplayed => (
          <ControlsBar right>
            <PlainButton
              onClick={onClose || handleModalDisplayed}
              outline
            >
              {'Отмена'}
            </PlainButton>
            <PlainButton
              disabled={loading || saving}
              type="submit"
            >
              {saving ? 'Сохранение...' : 'Сохранить'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}

export default RestrictionsSettingsModal
