// @flow
import React, { useCallback, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import not from 'ramda/es/not';

import Tooltip from 'app/common/ui/Tooltip';
import Button from 'app/common/ui/Button';
import PlainMenu from 'app/common/ui/PlainMenu';
import PlainMenuItem from 'app/common/ui/PlainMenu/PlainMenuItem';
import ClickOutside from 'app/common/ui/ClickOutside';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'app/common/components/Modal';
import PlainSelect from 'app/common/ui/PlainSelect';
import Dishes2MenuFormOriginal from 'app/components/OrgsList/OrgsKits/KitsList/DishesKitExtended/Dishes2MenuForm';
import { withWriteAccess } from 'app/common/containers/withRights';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import Link from 'app/common/lib/link';

import {
  withDishesOrgDishesRights,
  withMenuMenuRights,
} from 'app/containers/rights';

import enhanceChangeItems from './enhanceChangeItems';
import withSelectedDishes from './withSelectedDishes';
import CopyCheckedModal from './CopyCheckedModal';
import styles from './ChangeItems.scss';


const Dishes2MenuForm = withSelectedDishes(Dishes2MenuFormOriginal);

const MenuAccessedItem = withMenuMenuRights(withWriteAccess(PlainMenuItem));
const DishesAccessedItem = withDishesOrgDishesRights(withWriteAccess(PlainMenuItem));

type Props = {
  orgId: string,
  selection: Array<number>,
  numText: string,
  handleToggleMenu: (e: SyntheticEvent<HTMLElement>) => void,
  handleCloseMenu: (e: SyntheticEvent<HTMLElement>) => void,
  handleDelete: (e: SyntheticEvent<HTMLElement>) => void,
  handleMoveModalToggle: (e: SyntheticEvent<HTMLElement>) => void,
  handleDeleteModalToggle: (e: SyntheticEvent<HTMLElement>) => void,
  handleMove: (e: SyntheticEvent<HTMLElement>) => void,
  handleDropdownToggle: (void) => void,
  handleChangeCategory: (e: Object | SyntheticEvent<HTMLElement>) => void,
  isDropdown: boolean,
  isMoveModal: boolean,
  isDeleteModal: boolean,
  itemsList: Array<string>,
  categories: Array<Object>,
  currentCategoryId: number,
  returnTo: string,
  process: string,
  pathname: string,
  className?: string,
  disabled?: boolean,
  hideAdditionToMenu?: boolean,
}

const tipEditItems = 'Выберите хотя бы одну продукцию';
const ChangeItems = ({
  orgId,
  selection,
  numText,
  handleToggleMenu,
  handleCloseMenu,
  handleDelete,
  handleMoveModalToggle,
  handleDeleteModalToggle,
  handleMove,
  handleDropdownToggle,
  handleChangeCategory,
  isDropdown,
  isMoveModal,
  isDeleteModal,
  itemsList,
  categories,
  currentCategoryId,
  returnTo,
  process,
  pathname,
  className,
  disabled,
  hideAdditionToMenu,
}: Props) => {
  const [copyToModal, setCopyToModal] = useState(false);
  const toggleCopyToModal = useCallback(() => setCopyToModal(not), []);

  return (
    <div className={className}>
      <ClickOutside className={styles.root} onClickOutside={handleCloseMenu}>
        <Tooltip text={!selection.length ? tipEditItems : ''}>
          <Button onClick={handleToggleMenu} disabled={disabled || !selection.length}>
            <span>{`Изменить${numText}`}</span>
            <span className={styles.arrow} />
          </Button>
        </Tooltip>

        <CopyCheckedModal
          orgId={parseInt(orgId, 10)}
          show={copyToModal}
          onDisplayStatusChange={setCopyToModal}
          names={itemsList}
          ids={selection}
        />

        {isDropdown
          && <PlainMenu className={styles.menu}>
            <MenuAccessedItem
              onPropagationCatch={handleDropdownToggle}
              orgId={orgId}
            >
              <button type="button" onClick={toggleCopyToModal}>
                {'Копировать в другое заведение'}
              </button>
            </MenuAccessedItem>
            {!hideAdditionToMenu
              && <MenuAccessedItem
                onPropagationCatch={handleDropdownToggle}
                orgId={orgId}
              >
                <Link to={`${pathname}/dishes2menu`}>
                  {'Добавить продукцию в меню'}
                </Link>
              </MenuAccessedItem>}
            <MenuAccessedItem
              orgId={orgId}
            >
              <button onClick={handleMoveModalToggle} type="button">
                {'Переместить'}
              </button>
            </MenuAccessedItem>
            <DishesAccessedItem
              orgId={orgId}
            >
              <button onClick={handleDeleteModalToggle} type="button">
                {'Удалить'}
              </button>
            </DishesAccessedItem>
          </PlainMenu>}

        {isDeleteModal
          && <Modal toggleModal={handleDeleteModalToggle}>
            <form onSubmit={handleDelete}>
              <ModalHeader>{'Удаление продукции'}</ModalHeader>
              <ModalContent maxHeight={300}>
                <ul className={styles.content}>
                  {itemsList.map(item => <li key={item} className={styles.item}>{item}</li>)}
                </ul>
              </ModalContent>
              <ModalFooter>
                <ControlsBar middle>
                  <PlainButton type="submit">{'Удалить'}</PlainButton>
                  <PlainButton outline onClick={handleDeleteModalToggle}>{'Отмена'}</PlainButton>
                </ControlsBar>
              </ModalFooter>
            </form>
          </Modal>}

        {isMoveModal
          && <Modal>
            <form onSubmit={handleMove}>
              <ModalHeader>{'Перемещение продукции'}</ModalHeader>
              <div maxHeight={300} className={styles.columns}>
                <div className={styles.blockFrom}>
                  <Scrollbars
                    autoHeight
                    autoHeightMin={20}
                    autoHeightMax={300}
                  >
                    <ul className={styles.blockFromList}>
                      {itemsList.map(item => <li key={item} className={styles.item}>{item}</li>)}
                    </ul>
                  </Scrollbars>
                </div>
                <div className={styles.arrowTo}>&rarr;</div>
                <div className={styles.blockTo}>
                  <PlainSelect
                    className={styles.blockToSelector}
                    data={categories}
                    placeholder="Выберите категорию"
                    current={currentCategoryId}
                    onChange={handleChangeCategory}
                    uni
                    hideErrorString
                  />
                </div>
              </div>
              <ModalFooter>
                <ControlsBar middle>
                  <PlainButton type="submit">{'Переместить'}</PlainButton>
                  <PlainButton outline onClick={handleMoveModalToggle}>{'Отмена'}</PlainButton>
                </ControlsBar>
              </ModalFooter>
            </form>
          </Modal>}

        {/* Добавление блюд в меню */}
        <Modal
          isOpen={process === 'dishes2menu'}
          close={returnTo}
        >
          <Dishes2MenuForm
            orgId={orgId}
            returnTo2={returnTo}
          />
        </Modal>

      </ClickOutside>
    </div>
  );
};

export default enhanceChangeItems(ChangeItems);
