import remove from 'ramda/es/remove';
import drop from 'ramda/es/drop';
import append from 'ramda/es/append';
import genId from 'app/common/lib/genId';

import {
  REMOVE_FIRST_POPUP,
  REMOVE_POPUP,
  POPUP_MESSAGE,
  REMOVE_ALL_POPUPS,
} from 'app/common/actions/popup';


const defaultState = [];

/**
* Формат сообщения:
* {
*   msg: String,
*   type: 'err' || 'warn' || undefined,
*   id: Number,
*   error: Object,
* }
* payload: { [key]: 'message' }
* key = err, warn или msg, остальные ключи игнорируются
*/

export default function reducer(state = defaultState, action) {
  if (action.type === REMOVE_ALL_POPUPS) {
    return defaultState;
  }

  if (action.type === REMOVE_FIRST_POPUP) {
    return drop(1, state);
  }

  if (action.type === REMOVE_POPUP) {
    return remove(action.payload, 1, state);
  }

  if (action.type === POPUP_MESSAGE || action.type.match(/FAIL/)) {
    let keyName = action.payload.err && 'err';
    if (!keyName) {
      keyName = action.payload.warn && 'warn';
    }
    if (!keyName) {
      keyName = action.payload.msg && 'msg';
    }
    if (!keyName) return state;
    return append({
      msg: action.payload[keyName],
      id: genId(),
      type: keyName,
    }, state);
  }

  return state;
}
