import { useCallback, ReactNode, memo, ChangeEvent } from 'react'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'

import CopySettingsContainer from '../common/CopySettingsContainer'
import type { SubsidyOrdersRestrictions } from './useSubsidyOrdersRestrictionsOpt'


type Props = {
  currentState: SubsidyOrdersRestrictions
  onChange: (key: 'personal', value: any) => void
  disabled?: boolean
  children: ReactNode
  hasTopMargin?: boolean
}

const SORPersonalOpt = memo<Props>(({ currentState, onChange, disabled, children, hasTopMargin }: Props) => {
  const changeOption = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    onChange('personal', checked)
  }, [onChange])

  return (
    <CopySettingsContainer hasTopMargin={hasTopMargin}>
      <PlainCheckbox
        value="personal"
        label={
          <span>
            {'Ограничить '}
            <strong>
              {'индивидуальные справки'}
            </strong>
            {' периодами действий в течении учебного года (помесячно)'}
          </span>
        }
        disabled={disabled}
        checked={currentState.personal.enabled}
        onChange={changeOption}
      />

      {!disabled && children}
    </CopySettingsContainer>
  )
})

export default SORPersonalOpt
