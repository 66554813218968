import moment from 'moment'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { getDisabledDaysFunc } from 'app/common/ui-next/plain-date-period'
import { subscibeTo } from 'app/common/data-box-store'
import { periodFilter, schoolYearFilter, schoolYearFilterSelection } from 'app/dataProviders/filter-items'
import SimpleDate from 'app/common/classes/simple-date'

import { PlainDatePeriodBox } from 'app/common/ui-next/_filter-compilations/plain-date-period'


export const dateFormat = 'DD-MM-YYYY'

const defaultPeriod = ([from, to]: [SimpleDate | null, SimpleDate | null]) => {
  const currentDate = moment()

  // на случай периода прошлых учебных лет
  if (from && to && !currentDate.isBetween(from.createMoment(), to.createMoment(), 'day')) {
    return [from.getString(dateFormat), to.getString(dateFormat)]
  }

  const firstDayOfMonth = moment().startOf('month')

  const defaultDateFrom = firstDayOfMonth.format(dateFormat)
  const currentDateTo = currentDate.format(dateFormat)

  return [defaultDateFrom, currentDateTo]
}


type Props = {
  id: string
  pending: boolean
}

export const PeriodFilter = ({ id, pending }: Props) => {
  const [enabledDates, setEnabledDates] = useState<string[]>(['', ''])
  const [schoolYear, setSchoolYear] = useState<[SimpleDate | null, SimpleDate | null]>([null, null])


  const schoolYearHandler = useCallback(([schoolYearID]: Array<string>) => {
    const schoolYearSelected = schoolYearFilter.getData().find(({ id: ID }) => (ID === schoolYearID))
    const { dateStart, dateFinish } = schoolYearSelected || { dateStart: null, dateFinish: null }
    setSchoolYear([dateStart, dateFinish])
    const currentPeriod = defaultPeriod([dateStart, dateFinish])
    setEnabledDates(currentPeriod)
    periodFilter.setDefault(currentPeriod)
    periodFilter.updateData(() => currentPeriod)
  }, [])


  const dateDisabledStatus = useMemo(() => {
    const [dateStart, dateFinish] = schoolYear
    if (dateStart && dateFinish) {
      const res = getDisabledDaysFunc(dateStart.getAsISO8601(), dateFinish.getAsISO8601())
      return res
    }
    return () => false
  }, [schoolYear])


  useEffect(() => {
    const schoolYearUnsubsribe = subscibeTo<string[]>(schoolYearFilterSelection.id, schoolYearHandler)
    return (() => {
      schoolYearUnsubsribe()
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const changePeriodToDefault = useCallback(() => {
    return defaultPeriod([null, null])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledDates])


  return (
    <PlainDatePeriodBox
      id={id}
      label="Период времени"
      loading={pending}
      dateFormat={dateFormat}
      dataBoxData={periodFilter}
      getDefaultState={changePeriodToDefault}
      dateDisabledStatus={dateDisabledStatus}
    />
  )
}
