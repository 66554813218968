// @flow
import React from 'react';

import Tooltip from 'app/common/ui-next/plain-tooltip';


type Props = {
  children: any,
  className?: string,
  style?: Object,
  enableOverflow?:boolean
}

const OveflowedText = ({ children, className, style }: Props) => {
  return (
    <Tooltip text={children} className={className} style={style} enableOverflow hideWithoutOverflow>
      {children}
    </Tooltip>
  );
};

export default OveflowedText;
