// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as details } from 'app/components/common/reports/filters/details';
import { flags as group } from '../group';


const sortActivity = (state: State) => {
  return (
    details.detailsChecked(state)
    && group.isActive(state)
    && group.byUsersSelected(state)
  );
};

export default sortActivity;
