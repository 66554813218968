// @flow
import React, { Fragment, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import filter from 'ramda/es/filter';
import includes from 'ramda/es/includes';
import toLower from 'ramda/es/toLower';
import pipe from 'ramda/es/pipe';
import sortBy from 'ramda/es/sortBy';
import prop from 'ramda/es/prop';
import isEmpty from 'ramda/es/isEmpty';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';

import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useSearch from 'app/common/hooks/useSearch';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';
import Loading from 'app/common/components/Loader';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainDropdown, { PlainDropdownDefitem as DItem } from 'app/common/ui-next/plain-dropdown';

import useOrgs, { type Item } from './useOrgs';
import * as employeeTypes from '../../employeeTypes';
import useRoles, { type RoleItem } from '../../OneEmployee/SectionJobPlaces/useRoles';

import styles from './Step2Form.scss';


const cx = classNames.bind(styles);
const emptyArray = [];

type Props = {
  selectedOrg: number | null,
  selectedRole: number | null,
  onChangeOrg: (e: SyntheticEvent<HTMLInputElement>) => void,
  onSelectRole: ({ id: number }) => void,
  errorOrg: string,
  errorRole: string,
  orgPrefix: string,
  employeeType: $Values<typeof employeeTypes>,
  onSuccess?: () => void,
}

const Step2Form = ({
  selectedOrg,
  selectedRole,
  onChangeOrg,
  onSelectRole,
  errorOrg,
  errorRole,
  orgPrefix,
  employeeType,
  onSuccess,
}: Props) => {
  const [searchString, handleSearch, handleClearSearch] = useSearch();
  const [items, loading] = useOrgs();

  const filterItems = useCallback((items_: Array<Item>) => {
    if (!searchString) {
      return items_;
    }
    const searchNormalised = toLower(searchString);
    return filter(({ title }) => {
      const titleNormalised = toLower(title);
      return includes(searchNormalised, titleNormalised);
    }, items_);
  }, [searchString]);

  const itemsFilteredAndSorted = useMemo(() => pipe(
    filterItems,
    sortBy(prop('title')),
  )(items), [items, filterItems]);

  const nothing = isEmpty(itemsFilteredAndSorted);


  const [rolesGroups, loadingRoles] = useRoles();
  const roles = rolesGroups[employeeType] || emptyArray;


  const currentRoleTitle = useMemo(() => {
    const r: RoleItem | void = find(propEq('id', selectedRole), roles);
    return r ? r.title : 'Не указанa';
  }, [roles, selectedRole]);


  return (
    <Fragment>
      <PlainLabel id="role_label" skipUpperSpace displayAsBlock>
        {'Должность'}
      </PlainLabel>

      <PlainDropdown
        id="role"
        labelId="role_label"
        className={styles.role}
        itemsContainerClassName={styles.role}
        onChange={onSelectRole}
        disabled={loadingRoles}
        placeholder={
          <DItem
            id={selectedRole || -1}
            disabled={loadingRoles}
          >
            {currentRoleTitle}
          </DItem>
        }
        defaultSkin
        errored={!!errorRole}
      >
        {roles.map(({ title: title_, id: id_ }) => (
          <DItem key={id_} id={id_} active={id_ === selectedRole}>
            {title_}
          </DItem>
        ))}
      </PlainDropdown>

      {errorRole
        && <PlainHelperText error overflowVisible>{errorRole}</PlainHelperText>}


      {/* <SelectRoles
        className={styles.roles}
        labelClassName={styles.rolesLabel}
        label="Должность"
        name="role_id"
        current={selectedRole}
        placeholder="Не указанa"
        onChange={onSelectRole}
        disabled={loading}
        isError={errorRole}
        errorText={errorRole}
        uni
      /> */}

      {employeeType === employeeTypes.ATWORK
        && <Fragment>
          <PlainLabel displayAsBlock htmlFor="orgs_search">
            {'Заведение'}
          </PlainLabel>

          <PlainInputSearch
            id="orgs_search"
            className={styles.search}
            handleClearSearch={handleClearSearch}
            handleSearch={handleSearch}
            searchString={searchString}
          />

          <ScrollbarsWShadow
            autoHeight
            autoHeightMin={100}
            autoHeightMax={210}
            className={cx(styles.orgs, { error: !!errorOrg })}
          >
            {loading && <Loading className={styles.loading} />}
            {!nothing
              ? itemsFilteredAndSorted.map(({ id, title }) => {
                const elemId = `${orgPrefix}${id}`;

                return (
                  <PlainRadioButton
                    key={id}
                    name="orgs"
                    label={title}
                    value={elemId}
                    className={styles.radio}
                    onChange={onChangeOrg}
                    checked={id === selectedOrg}
                  />);
              })
              : <EmptyWarn>
                {'Нет записей, удовлетворяющих условиям'}
              </EmptyWarn>}
          </ScrollbarsWShadow>

          {errorOrg
            && <PlainHelperText error overflowVisible>{errorOrg}</PlainHelperText>}
        </Fragment>}
    </Fragment>
  );
};

export default Step2Form;
