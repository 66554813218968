import { Fragment, useCallback, useState, useRef, ReactNode, ChangeEvent, MouseEvent, useEffect } from 'react'
import classNames from 'classnames/bind'

import Tr, { type TrProps } from './tr'
import Td from './td'

import styles from './tr-expandable.scss'


const cx = classNames.bind(styles)

type Props = {
  children: ReactNode
  tableRows?: number // depricated, use columnsAmount
  columnsAmount?: number
  expandedContent: ReactNode
  id: string
  expanded: boolean
  tinted?: boolean
  onExpand: (id: string | null) => void
  className?: string
  classNameFocused?: string
  classNameExpanded?: string
  styleExpanded?: {}
  style?: {}
  disableExpand?: boolean
} & TrProps

export const TrExpandable = ({
  children,
  tableRows,
  columnsAmount,
  expandedContent,
  id,
  expanded,
  tinted,
  onExpand,
  className,
  classNameFocused,
  classNameExpanded,
  styleExpanded,
  style,
  disableExpand,
  ...props
}: Props) => {
  const [focused, setFocused] = useState(false)
  const { disabled } = props
  const inputRef = useRef<HTMLInputElement | null>(null)

  const currentClassName = cx(
    styles.root,
    { disabled, disableExpand, tinted },
    className,
    focused && classNameFocused,
    expanded && classNameExpanded,
  )

  // focus
  const handleFocus = useCallback(() => {
    if (!expanded && !disableExpand) {
      onExpand(id)
    }
    setFocused(true)
  }, [onExpand, id, expanded, disableExpand])

  const handleBlur = useCallback(() => {
    setFocused(false)
  }, [])


  // changing handlers
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    if (checked && !disableExpand) {
      onExpand(expanded ? null : id)
    }
  }, [onExpand, id, expanded, disableExpand])

  const handleClickTr = useCallback((e: MouseEvent<HTMLElement>) => {
    const { clientX, clientY } = e
    const clickedByFocus = clientX === 0 && clientY === 0
    if (!focused && inputRef.current) {
      inputRef.current.focus()
    }
    if (!disableExpand) {
      onExpand(expanded && !clickedByFocus ? null : id)
    }
  }, [disableExpand, onExpand, id, expanded, focused])

  useEffect(() => {
    if (disableExpand) {
      onExpand(null)
    }
  }, [disableExpand]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Fragment>
      <Tr
        className={currentClassName}
        onClick={handleClickTr}
        style={style}
        {...props}
      >
        {children}
        <td // eslint-disable-line jsx-a11y/control-has-associated-label
          className="visuallyHidden"
        >
          <input
            ref={inputRef}
            checked={expanded}
            name="tr_expandable"
            value={id}
            id={id}
            type="radio"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </td>
      </Tr>

      {expanded
        && <Tr hoverHighlighted={false} style={styleExpanded}>
          <Td
            colSpan={columnsAmount || tableRows}
            className={styles.expandedContentTd}
            stripStyles
          >
            {expandedContent}
          </Td>
        </Tr>}
    </Fragment>
  )
}
