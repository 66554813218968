// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import { NavLink } from 'app/common/lib/link';
import defaultProps from 'recompose/defaultProps';
import componentFromProp from 'recompose/componentFromProp';

import styles from './NavItem.scss';

export type Item = {
  title: string,
  id: number | string,
  path?: string,
  activeClassName?: string,
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
  active?: boolean,
  component?: React.Element<any>,
}

const cx = classNames.bind(styles);
const enhance = defaultProps({ component: NavLink });
const Nav = enhance(componentFromProp('component'));

const mapItems = (items: Array<Item>): React.Node => items.map(({
  title,
  id,
  path,
  onClick,
  active,
  activeClassName,
  component,
}: Item) => (
  <Nav
    key={id}
    to={path}
    name={id}
    onClick={onClick}
    className={cx(styles.link, { active })}
    activeClassName={activeClassName || styles.active}
    component={component}
  >
    {title}
  </Nav>
));

export default mapItems;
