
// @flow
import {
  BALANCE_SET_REPORT_ID,
  BALANCE_SET_XLS_FILE_ID,
  BALANCE_RESET_PARAMS,
  BALANCE_VIEW_PARAMS_CREATE,
} from 'app/actions/reports/balance';

import { BALANCE_ALL } from 'app/actions/reports/filters/commonBalance';
import { BY_ALPHABET } from 'app/actions/reports/filters/sort';


export type BalanceParamsType = {
  reportId: number,
  xlsFileId: number,
  balanceFilter: string,
  balanceMin: number,
  balanceMax: number,
  sort: string,
  current: boolean,
}

const defaultState: BalanceParamsType = {
  reportId: 0,
  xlsFileId: 0,
  balanceFilter: BALANCE_ALL,
  balanceMin: 0,
  balanceMax: 0,
  // group(pin): "by_groups_and_types" not used
  sort: BY_ALPHABET,
  current: true,
};

const params = (state: BalanceParamsType = defaultState, { type, payload }: Object) => {
  if (type === BALANCE_RESET_PARAMS) {
    return defaultState;
  }
  if (type === BALANCE_SET_REPORT_ID) {
    return { ...state, reportId: payload, xlsFileId: 0 };
  }
  if (type === BALANCE_SET_XLS_FILE_ID) {
    return { ...state, xlsFileId: payload };
  }
  if (type === BALANCE_VIEW_PARAMS_CREATE) {
    return { ...state, ...payload };
  }
  return state;
};

export default params;
