import { createContext } from 'react'

import {
  type State,
  type ApiHandlers,
  type ModifyHandlers,
  defaultState,
  defaultApiHandlers,
  defaultModifyHandlers,
} from './useSubsidies'


type SubsidiesContextType = [
  State,
  ApiHandlers,
  ModifyHandlers
]

const defaultContext: SubsidiesContextType = [
  defaultState,
  defaultApiHandlers,
  defaultModifyHandlers,
]

export default createContext<SubsidiesContextType>(defaultContext)
