// @flow
import React from 'react';

import Modal from 'app/common/components/Modal';
import Checkbox from 'app/common/ui/Checkbox';
import RadioButton, { RadioButtonGroup } from 'app/common/ui/RadioButton';
import { ButtonsBar } from 'app/common/ui/Button';
import {
  ONLY_CHOSEN_COMPLEX_FOR_CHOSEN_DATE,
  ONLY_CHOSEN_COMPLEX_FOR_FUTURE,
  ALL_SCHOOL_COMPLEXES_FOR_CHOSEN_DATE,
  ALL_SCHOOL_COMPLEXES_FOR_FUTURE,
  ALL_SCHOOLS_FOR_CHOSEN_DATE,
  ALL_SCHOOLS_FOR_FUTURE,
  ONE_SCHOOL,
  ALL_SCHOOLS,
} from 'app/constants/orgs/kits/fieldEdit';

import styles from './ValueSaveOptionsDialog.scss';
import enhanceValueSaveOptionsDialog from './enhanceValueSaveOptionsDialog';


type Props = {
  isOpen: boolean,
  onClose: (e: SyntheticEvent<HTMLElement>) => void,
  schoolName: string,
  dateString: string,
  handleSubmit: (e: SyntheticEvent<HTMLElement>) => void,
  handleChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  saving: boolean,
  allSchools: boolean,
  extended: string,
};

const ValueSaveOptionsDialog = ({
  isOpen,
  onClose,
  schoolName,
  dateString,
  handleSubmit,
  handleChange,
  saving,
  allSchools,
  extended,
}: Props) => (<Modal isOpen={isOpen} className={styles.root}>
  <form onSubmit={handleSubmit}>
    {/* $FlowFixMe */}
    <Checkbox
      label={`Cохранить только в ${schoolName}`}
      disabled
      checked
      value={ONE_SCHOOL}
      onChange={handleChange}
    />
    <RadioButtonGroup
      valueSelected={!allSchools && extended}
      onChange={handleChange}
      name="one_school"
    >
      <RadioButton
        label={`Применить только для этого комплекта на ${dateString}`}
        value={ONLY_CHOSEN_COMPLEX_FOR_CHOSEN_DATE}
        disabled={allSchools || saving}
      />
      <RadioButton
        label={`Применить только для этого комплекта на ${dateString} и будущие дни*`}
        value={ONLY_CHOSEN_COMPLEX_FOR_FUTURE}
        disabled={allSchools || saving}
      />
      <RadioButton
        label={`Применить для всех комплектов на ${dateString}`}
        value={ALL_SCHOOL_COMPLEXES_FOR_CHOSEN_DATE}
        disabled={allSchools || saving}
      />
      <RadioButton
        label={`Применить для всех комплектов на ${dateString} и будущие дни*`}
        value={ALL_SCHOOL_COMPLEXES_FOR_FUTURE}
        disabled={allSchools || saving}
      />
    </RadioButtonGroup>

    {/* $FlowFixMe */}
    <Checkbox
      label="Сохранить во всех доступных вам школах, где используется эта продукция в комплекте"
      value={ALL_SCHOOLS}
      onChange={handleChange}
      checked={allSchools}
    />
    <RadioButtonGroup
      valueSelected={allSchools && extended}
      onChange={handleChange}
      name="all_schools"
    >
      <RadioButton
        label={`Применить для всех комплектов на ${dateString}`}
        value={ALL_SCHOOLS_FOR_CHOSEN_DATE}
        disabled={!allSchools || saving}
      />
      <RadioButton
        label={`Применить для всех комплектов на ${dateString} и будущие дни*`}
        value={ALL_SCHOOLS_FOR_FUTURE}
        disabled={!allSchools || saving}
      />
    </RadioButtonGroup>
    <p>{`* — c ${dateString}`}</p>
    <ButtonsBar
      right={[{
        key: 'cancel',
        value: 'Отмена',
        outline: true,
        onClick: onClose,
      }, {
        key: 'submit',
        value: saving ? 'Применяется...' : 'Применить',
        submit: true,
        disabled: saving,
      }]}
    />
  </form>
</Modal>);

export default enhanceValueSaveOptionsDialog(ValueSaveOptionsDialog);
