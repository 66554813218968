import { useMemo } from 'react'
import { Column } from './types'


export function useColumnTitles(currency: string = '₽') {
  const columns = useMemo(() => {
    return {
      [Column.Title]: 'Наименование',
      [Column.Date]: 'Период',
      [Column.Children]: 'Кол-во детей',
      [Column.Sum]: `Общая сумма компенсаций, ${currency}`,
      [Column.Action]: 'Действие',
    }
  }, [currency])

  return columns
}
