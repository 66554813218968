import { useState, useCallback, useEffect } from 'react'
import prop from 'ramda/es/prop'
import { useLocation } from 'react-router'

import { api } from 'app/api'
import { GET_ROLE } from 'app/common/api/requests/food/school/role'
import type { Rights } from 'app/common/constants/rights'

import {
  fullAccessObject,
  type RightsPath,
  type RightsObject,
  getAccessObject,
} from '../types'

import { onlyRead } from '../Rights'


const defaultRights: Rights = onlyRead

export type State = {
  rights: Rights,
  su: boolean,
  ready: boolean,
}

export const defaultState: State = {
  rights: defaultRights,
  su: false,
  ready: false,
}

export type Handlers = {
  request: () => void | Promise<void>,
  // getReadAccess: (RightsPath) => boolean,
  // getWriteAccess: (RightsPath) => boolean,
}

export const defaultHandlers: Handlers = {
  request: () => { },
  // getReadAccess: () => false,
  // getWriteAccess: () => false,
}

export type Options<T extends keyof Rights> = {
  orgId: number,
  preferred?: RightsPath<T>,
  onUpdatePreferredRights?: (rights: RightsObject | null) => void,
}

export default function useRightsForOrg<T extends keyof Rights>({
  orgId,
  preferred,
  onUpdatePreferredRights,
}: Options<T>): [State, Handlers] {
  const { pathname } = useLocation()

  const [rights, setRights] = useState<Rights>(defaultRights)
  const [su, setSU] = useState<boolean>(false) // is superuser
  const [ready, setReady] = useState<boolean>(false)


  useEffect(() => {
    if (!preferred || !onUpdatePreferredRights) {
      return
    }
    if (!ready) {
      onUpdatePreferredRights(null)
      return
    }
    if (su) {
      onUpdatePreferredRights(fullAccessObject)
      return
    }
    onUpdatePreferredRights(getAccessObject(preferred, rights))
  }, [rights, su, ready, preferred, onUpdatePreferredRights])


  // const getReadAccess = useCallback((rpath: RightsPath) => {
  //   if (su) return true;
  //   return path([...rpath, 'read'], rights);
  // }, [su, rights]);


  // const getWriteAccess = useCallback((rpath: RightsPath) => {
  //   if (su) return true;
  //   return path([...rpath, 'edit'], rights);
  // }, [su, rights]);


  const handleRequest = useCallback(async () => {
    const res = await api.request(GET_ROLE, {
      error: 'Не удалось получить права пользователя для текущей организации',
      requestPathParams: {
        orgId,
      },
    })

    if (res === '') {
      setReady(true)
      setSU(true)
      setRights(defaultRights)
      return
    }

    if (res) {
      const haveRights = prop('rights', res)
      if (haveRights) {
        setReady(true)
        setSU(false)
        setRights(haveRights)
        return
      }
    }
    setReady(false)
    setSU(false)
    setRights(defaultRights)
  }, [orgId])


  // периодическое обновление прав в том числе при смене pathname
  useEffect(() => {
    handleRequest()
  }, [handleRequest, pathname])


  return [{
    rights,
    su,
    ready,
  }, {
    request: handleRequest,
    // getReadAccess,
    // getWriteAccess,
  }]
}
