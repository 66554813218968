// @flow
import React, { type Node, useEffect } from 'react';

import OrgsContext from './OrgsContext';
import useOrgs, { type Options } from './useOrgs';

/**
 *  Компонент-поставщик данных заведений в контекст,
 *  в том числе текущего
 */

type Props = {
  orgId: number, // id текущего
  children: Node,
} & Options

const OrgsDataProvider = ({ children, orgId, ...options }: Props) => {
  const value = useOrgs(orgId, options);

  const [, { request }] = value;

  useEffect(() => {
    request();
  }, [request]);

  return (
    <OrgsContext.Provider value={value}>
      {children}
    </OrgsContext.Provider>
  );
};

export default OrgsDataProvider;
