// @flow
import { useState, useCallback, useEffect } from 'react';

import { type UniProps } from 'app/common/ui/PlainSelect';


type Options = {
  defaultSellPoint: number | null,
  onChange: (number) => void,
}

export default function useSellPointControl({ defaultSellPoint, onChange }: Options): [
  number | null,
  (UniProps) => void
] {
  const [sellPoint, setSellPoint] = useState<number | null>(null);

  const handleChange = useCallback((e: UniProps) => {
    const { value } = e.currentTarget;
    const sellPointId = parseInt(value, 10);
    onChange(sellPointId);
    setSellPoint(sellPointId);
  }, [onChange]);

  useEffect(() => {
    setSellPoint(defaultSellPoint);
  }, [defaultSellPoint]);

  return [sellPoint, handleChange];
}
