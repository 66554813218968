// @flow
import { map } from 'ramda';

import type { Params, PlanogramRawData, PlanogramRawDataItem } from 'app/dataTypes/vending/Planogram.types';

import type { Request } from '../../../../index';


type ResultApi = Array<{
  id: number,
  number: string,
  capacity: number,
  contains: number,
  width: string,
  height: string,
  row: string,
  column: string,
}>

type UrlParams = {
  machineId: string,
}

export const convertParams = (params: Params): [null, UrlParams] => {
  return [null, params];
};

export const convertResult = (data: ResultApi): PlanogramRawData => {
  return map(({
    id,
    number,
    capacity,
    width,
    height,
    row,
    column,
  }): PlanogramRawDataItem => {
    const rowInt = parseInt(row, 10);
    const columnInt = parseInt(column, 10);

    return ({
      id: String(id) || '',
      nonTrustedSlotName: number || '',
      maxGoods: capacity || 0,
      width: parseInt(width, 10) || 1,
      height: parseInt(height, 10) || 1,
      row: rowInt ? rowInt - 1 : 0,
      column: columnInt ? columnInt - 1 : 0,
    });
  }, data);
};

export const GET_PLANORGAM_SLOTS: Request<
  Params,
  PlanogramRawData,
  UrlParams
> = [
  'data/vending/machines/{machineId}/slots',
  'get',
  ['data', 'data'],
];
