// @flow
import * as checkEmployeeStatuses from 'app/constants/employees/existing';

import type { Request } from '../index';


export { checkEmployeeStatuses };

export type CheckEmployeeRequest = {
  query_type: 'full',
  firstname: 'string',
  middlename: string,
  lastname: string,
  gender: 'male' | 'female',
  birthdate: string
} | {
  query_type: 'name',
  firstname: string,
  lastname: string,
} | {
  query_type: 'phone',
  phone: string,
} | {
  query_type: 'email',
  email: string,
} | {
  query_type: 'snils',
  snils: string,
}

export type CheckEmployeeResult = {
  result: 'success',
  status: $Values<typeof checkEmployeeStatuses>,
  id?: number // в случае this_supplier
}

export const GET_CHECK_EMPLOYEE_EXISTS: Request<
  CheckEmployeeRequest,
  CheckEmployeeResult,
  void
> = [
  'food/check_employee_exists',
  'get',
  ['data'],
];
