import { reduce } from 'ramda'
import { useCallback, useMemo } from 'react'


type Item = { ID: string, orgGroupID?: string | null }

type Options<T extends Item> = {
  orgItems: Map<string, T>
}

export default function useLinks<T extends Item>({
  orgItems,
}: Options<T>) {
  const orgsByGroupID = useMemo(() => {
    return reduce((acc, { ID, orgGroupID }) => {
      if (orgGroupID) {
        const target = acc.get(orgGroupID) || []
        target.push(ID)
        if (target.length === 1) acc.set(orgGroupID, target)
      }
      return acc
    }, new Map<string, Array<string>>(), Array.from(orgItems.values()))
  }, [orgItems])

  const handleGetLinkedOrgs = useCallback((orgGroupID: string): Array<string> | undefined => {
    return orgsByGroupID.get(orgGroupID)
  }, [orgsByGroupID])

  return {
    getLinkedOrgs: handleGetLinkedOrgs,
  }
}
