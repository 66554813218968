// @flow
import { useEffect, useState } from 'react';

import { GET_FOOD_ITEMS, convertParams, convertResult } from 'app/common/api/requests/food/items/get';
import type { Dish, Dishes, Params } from 'app/dataTypes/Dishes.types';
import { api } from 'app/api';


export default function useDishes(
  { limit, offset, search }: Params,
  externalRequest?: (Params) => Promise<Dishes | null>,
): [Array<Dish>, number, boolean] {
  const [loading, setLoading] = useState(true);
  const [dishes, setDishes] = useState<Array<Dish>>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const request = async () => {
      setLoading(true);
      const res = await (
        externalRequest
          ? externalRequest({ limit, offset, search })
          : api.request(GET_FOOD_ITEMS, {
            error: 'Не удалось получить доступную продукцию',
            params: {
              limit,
              offset,
              search,
            },
            convertions: {
              convertParams,
              convertResult,
            },
          })
      );

      if (res) {
        setDishes(res.items);
        setTotal(res.total);
      }
      setLoading(false);
    };

    request();
  }, [limit, offset, search, externalRequest]);

  return [dishes, total, loading];
}
