// @flow
import React from 'react';

import Checkbox from 'app/common/ui/Checkbox';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';
import SettingsGear from 'app/common/components/Settings';

import styles from './OrgsDishesEditListGear.scss';


export type DishesTableSettings = {
  rows: number,
  title: boolean,
  photo: boolean,
  portion: boolean,
  cost: boolean,
};

const OrgsDishesEditListGear = ({ className }: { className?: string }) => (<SettingsGear dataKey="orgsDishesEditList" className={className}>
  {({ saveSettings }) => (<div>
    <div className={styles.gearHeader}>{'Столбцы:'}</div>
    {/* Чекбоксы в настройках */}
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Фото"
      name="photo"
      saveSettings={saveSettings}
      className={styles.gearCheck}
      settingsKey="orgsDishesEditList"
    />
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Порция"
      name="portion"
      saveSettings={saveSettings}
      className={styles.gearCheck}
      settingsKey="orgsDishesEditList"
    />
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Стоимость"
      name="cost"
      saveSettings={saveSettings}
      className={styles.gearCheck}
      settingsKey="orgsDishesEditList"
    />
  </div>)}
</SettingsGear>);

export default OrgsDishesEditListGear;
