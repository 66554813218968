// @flow
import map from 'ramda/es/map';
import reduce from 'ramda/es/reduce';

import type { RestrictionByRoleMap, Params, ParamsPOST } from 'app/dataTypes/OrgSettingsSubsidies.types';
import { toCamelCase, toSnakeCase } from 'app/common/lib/object';

import type { Request } from '../../index';


type RestrictionByRoleAPI = {
  id: number,
  role_id: number,
  role_title: string,
  past_period_without_restrictions: boolean,
  past_period_days: number,
  order_type: 0 | 1,
}

type Result = {
  [id: string]: RestrictionByRoleAPI
}

type UrlParams = {
  orgId: number | string,
}

// GET

export const convertParams = (params: Params): [null, UrlParams] => {
  return [null, params];
};

export const convertResult = (data: Result): RestrictionByRoleMap => {
  return map(toCamelCase, data);
};

export const GET_OLD_SUBSIDY_ORDERS_RESTRICTIONS: Request<
  Params,
  RestrictionByRoleMap,
  UrlParams
> = [
  'food/schools/{orgId}/old_subsidy_orders_restrictions',
  'get',
  ['data', 'restrictions'],
];

// POST

type ParamsApiPOST = {
  schools_ids: Array<string> | Array<number>,
  restrictions: {
    [id: string]: {
      role_id: number,
      past_period_without_restrictions: boolean,
      past_period_days: number,
      order_type: 0 | 1,
    }
  }
}

export const convertParamsPOST = ({ orgIds, restrictions: rst }: ParamsPOST): [ParamsApiPOST, null] => {
  const restrictions = reduce((acc, { id, roleTitle, ...others }) => {
    const next = {
      ...acc,
      [String(id)]: toSnakeCase(others),
    };
    return next;
  }, {}, rst);

  const params = {
    schools_ids: orgIds,
    restrictions,
  };
  return [params, null];
};

export const POST_OLD_SUBSIDY_ORDERS_RESTRICTIONS: Request<
  ParamsPOST,
  'success',
  void
> = [
  'food/schools/old_subsidy_orders_restrictions',
  'post',
  ['data', 'result'],
];
