import { MouseEvent, ReactNode, useCallback, useState } from 'react'

import useCallbackRef from 'app/common/hooks/useCallbackRef'

import { PlainConfirmModal, PlainConfirmModalProps, ButtonProps } from './plain-confirm-modal'


type Props = {
  children: (beginConfirmation: () => void, stopConfirmation: () => void) => ReactNode
  onConfirm: () => Promise<boolean>
  closeRightAway?: boolean
  cancelButtonTitlePending?: string
  confirmButtonTitlePending?: string
  getConfirmButtonProps?: (pending: boolean) => ButtonProps
  getCancelButtonProps?: (pending: boolean) => ButtonProps
} & Omit<PlainConfirmModalProps, 'onCancel' | 'onConfirm'>

export const PlainConfirm = ({
  children,
  onConfirm,
  closeRightAway,
  cancelButtonTitlePending,
  confirmButtonTitlePending,
  getConfirmButtonProps,
  getCancelButtonProps,
  cancelButtonTitle,
  confirmButtonTitle,
  ...props
}: Props) => {
  const [confirmDisplayed, setConfirmDisplayed] = useState(false)
  const [pending, setPending] = useState(false)

  const onConfirmRef = useCallbackRef(onConfirm)

  const handleBeginConfirmation = useCallback((e?: MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setConfirmDisplayed(true)
  }, [])

  const handleStopConfirmation = useCallback((e?: MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setConfirmDisplayed(false)
  }, [])

  const handleConfirm = useCallback(async (e?: MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (closeRightAway) {
      onConfirmRef.current?.()
      handleStopConfirmation()
      return
    }
    setPending(true)
    const res = await onConfirmRef.current?.()
    setPending(false)
    if (res) handleStopConfirmation()
  }, [onConfirmRef, handleStopConfirmation, closeRightAway])

  const okButtonProps = pending ? { disabled: true } : {}

  const additionalProps = {
    confirmButtonProps: (
      getConfirmButtonProps
        ? getConfirmButtonProps(pending)
        : okButtonProps
    ),
    cancelButtonProps: (
      getCancelButtonProps
        ? getCancelButtonProps(pending)
        : {}
    ),
  }

  return (
    <>
      {children(handleBeginConfirmation, handleStopConfirmation)}
      {confirmDisplayed
        && <PlainConfirmModal
          onCancel={handleStopConfirmation}
          onConfirm={handleConfirm}
          cancelButtonTitle={
            cancelButtonTitlePending && pending
              ? cancelButtonTitlePending
              : cancelButtonTitle
          }
          confirmButtonTitle={
            confirmButtonTitlePending && pending
              ? confirmButtonTitlePending
              : confirmButtonTitle
          }
          {...props}
          {...additionalProps}
        />}
    </>
  )
}
