// @flow
import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import petrovich from 'petrovich';


export default function useStep2FormHeader() {
  const { firstname, lastname, gender } = useSelector(state => state.employees.hireform, shallowEqual);

  const res = useMemo(() => {
    const person = petrovich({ first: firstname, last: lastname, gender }, 'genitive');
    return `Принять ${person.last} ${person.first}`;
  }, [firstname, lastname, gender]);

  return res;
}
