import { createSelector } from 'reselect';
import equals from 'ramda/es/equals';


export default createSelector(
  [
    state => state.employees.employee.fields,
    state => state.employees.employee.employeeform,
  ],
  (fields, form) => equals(fields, form),
);

export const selectIsGeneralFieldsNotModified = createSelector(
  [
    state => state.employees.employee.fields,
    state => state.employees.employee.employeeform,
  ],
  (fields, form) => equals(fields.name, form.name)
    && fields.gender === form.gender
    && fields.birthdate === form.birthdate,
);

export const selectIsPhoneNotModified = createSelector(
  [
    state => state.employees.employee.fields,
    state => state.employees.employee.employeeform,
  ],
  (fields, form) => fields.phone === form.phone,
);

export const selectIsEmailNotModified = createSelector(
  [
    state => state.employees.employee.fields,
    state => state.employees.employee.employeeform,
  ],
  (fields, form) => fields.email === form.email,
);
