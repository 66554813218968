// @flow
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';
import { statuses } from 'app/common/api/requests/food/schools/cafeteria_orders';


export { statuses };

const filterGetItemsStatuses = () => ([[{
  id: String(statuses.STATUS_DELETED),
  title: 'Удалена',
}, {
  id: String(statuses.STATUS_SPENT),
  title: 'Проведена',
}, {
  id: String(statuses.STATUS_PENDING),
  title: 'Ожидает проведения',
}, {
  id: String(statuses.STATUS_PENDING_ISSUE),
  title: 'Ожидает выдачи',
}], defaultStateDataCurrent]);

export default filterGetItemsStatuses;
