import { BALANCE_DATA_SUCCESS } from 'app/actions/reports/balance/data';

const defaultState = {
  summary: {},
  schools: [],
  groups: [],
  users: [],
};

const data = (state = defaultState, { type, payload }) => {
  if (type === BALANCE_DATA_SUCCESS) {
    return ({ ...defaultState, ...payload });
  }
  return state;
};

export default data;
