// TODO move to dataProviders
import { useSelector, shallowEqual } from 'react-redux'

import type { ComplexItem } from 'app/common/api/requests/food/schools/complexes'


export type Complex = {
  isNew?: boolean,
} & ComplexItem

export type Complexes = Record<string, Complex>

export default function useKits() {
  const kits: Complexes = useSelector((state: any) => state.orgs.kits.items, shallowEqual)
  return kits
}
