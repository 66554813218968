// @flow
import React, { type Node, useCallback } from 'react';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import usePopup from 'app/common/hooks/usePopup';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal';

import {
  useMachineSubmit,
  useVendingMachines,
  useMachineForm,
} from 'app/dataProviders/VendingDataProvider';

import AddMachine from './AddMachine';
import styles from './AddMachineModal.scss';


type Props = {
  children: (HandleModalDisplayed) => Node
}

const AddMachineModal = ({ children }: Props) => {
  const popup = usePopup();
  const [submitting, submit] = useMachineSubmit();
  const [,, requestMachines] = useVendingMachines();
  const [, { clear }] = useMachineForm();

  const submitModal = useCallback(async () => {
    const res = await submit();
    if (res) {
      popup('Аппарат успешно добавлен');
      requestMachines();
      clear();
    }
    return res;
  }, [submit, popup, requestMachines, clear]);

  return (
    <PlainModal
      formId="add_machine"
      controlBy={children}
      onSubmit={submitModal}
      className={styles.root}
    >
      <PlainModalHeader>
        {'Добавление аппарата'}
      </PlainModalHeader>

      <PlainModalContent>
        <AddMachine disabled={submitting} />
      </PlainModalContent>

      <PlainModalFooter>
        {toggleModal => (
          <ControlsBar right>

            <PlainButton
              onClick={toggleModal}
              outline
            >
              {'Отмена'}
            </PlainButton>

            <PlainButton
              type="submit"
              disabled={submitting}
            >
              {submitting ? 'Добавление...' : 'Добавить'}
            </PlainButton>

          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  );
};

export default AddMachineModal;
