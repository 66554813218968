// @flow
import { useCallback, useMemo, useEffect } from 'react';
import { assoc, filter, keys } from 'ramda';

import {
  type VersionsState,
  useMachineForm,
  useMachineValidate,
  useVersions,
} from 'app/dataProviders/VendingDataProvider';


export default function useActualVersions(modelSelection: Array<string>): {
  versions: VersionsState,
  loadingVersions: boolean,
  versionSelection: Array<string>,
  changeCurrentVersion: Array<string> => void,
} {
  const [{ versionId }, { update }] = useMachineForm();
  const [,, resetErrors] = useMachineValidate();

  const [versionsAvailable, loadingVersions, requestVersions] = useVersions();

  const versions = useMemo(() => {
    return filter(({ modelId: mId }) => {
      return modelSelection.includes(mId);
    }, versionsAvailable);
  }, [versionsAvailable, modelSelection]);

  const versionSelection = useMemo(() => (
    versionId
      ? [String(versionId)]
      : []
  ), [versionId]);

  const changeCurrentVersion = useCallback(([versionId_]: Array<string>) => {
    resetErrors();
    update(assoc('versionId', parseInt(versionId_, 10) || 0));
  }, [update, resetErrors]);


  useEffect(() => {
    requestVersions();
  }, [requestVersions]);

  useEffect(() => {
    const versionIds = keys(versions);
    if (versionIds.length === 1) {
      changeCurrentVersion(versionIds);
    }
  }, [modelSelection]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    versions,
    loadingVersions,
    versionSelection,
    changeCurrentVersion,
  };
}
