// @flow
import { type State } from 'app/common/ui-components/filter';

import type { CommonParams } from './CommonParams.type';


const DO_NOT_MAIL: 'do_not_mail' = 'do_not_mail';
const MAIL_AND_VIEW: 'mail_and_view' = 'mail_and_view';
const MAIL_ONLY: 'mail_only' = 'mail_only';

export const mailTypes = { DO_NOT_MAIL, MAIL_AND_VIEW, MAIL_ONLY };

const doNotMail = {
  id: DO_NOT_MAIL,
  title: 'Не отправлять',
};

const mailAndView = (disabled: boolean) => ({
  id: MAIL_AND_VIEW,
  title: 'Отправить файл на e-mail по завершении формирования',
  disabled,
});

const mailOnly = (disabled: boolean) => ({
  id: MAIL_ONLY,
  title: 'Отправить файл на e-mail без формирования отчета на экране',
  disabled,
});

const mailItems = (_: State, params: CommonParams) => {
  const { email } = params || {};

  return ([[doNotMail, mailAndView(!email), mailOnly(!email)], [DO_NOT_MAIL]]);
};

export const mailItemsDisabledSending = () => {
  return ([[doNotMail, mailAndView(true), mailOnly(true)], [DO_NOT_MAIL]]);
};

export default mailItems;
