import SimpleDate from 'app/common/classes/simple-date'


export type CompensationTransactionsParams = {
  dateFrom: SimpleDate
  dateTo: SimpleDate
  studentID: string
}

export enum OperationType {
  Unknown = -1,
  Accrual = 0, //                               начисление компенсации
  CancellationAccrual = 1, //                   отмена начисления компенсации
  WithdrawalCurrentAccount = 2, //              вывод по заявлению на р/с
  WithdrawalPersonalAccount = 3, //             вывод на л/с
  CancellationWithdrawalPersonalAccount = 4 //  отмена вывода по заявлению на р/с
}

export const isOperationType = (val: any): val is OperationType => {
  return typeof val === 'number' && Object.values(OperationType).includes(val)
}

type ValueNumber = string
type ValueSign = '+' | '\u2212'

export type Transaction = {
  ID: string
  date: SimpleDate
  value: `${ValueSign}${ValueNumber}`
  valueStatus: 'negative' | 'positive'
  operationType: OperationType
  comment: string
}

export type TransactionList = Array<Transaction>

export type CompensationTransactions = {
  totalBalance: string
  transactions: TransactionList
}
