// @flow
import React, { type Node } from 'react';

import styles from './SummaryTitle.scss';


type Props = {
  children: Node
}

const SummaryTitle = ({ children }: Props): Node => {
  return (
    <div className={styles.root}>
      {children}
    </div>
  );
};

export default SummaryTitle;
