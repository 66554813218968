import { ReactNode, createContext, useState, useContext, useEffect, Dispatch, SetStateAction } from 'react'


type CheckedState = [string | null, Dispatch<SetStateAction<string | null>>]

const emptyContext: CheckedState = [null, () => {
  console.log('%c👻 Missing Context Provider (useCheckStateContext)', 'color: LawnGreen')
}]

const CheckStateContext = createContext<CheckedState>(emptyContext)

export const useCheckStateContext = () => useContext(CheckStateContext)

type Props = {
  children: ReactNode | ((state: string | null) => ReactNode)
  resetKey?: any
}

export const CheckStateProvider = ({ children, resetKey }: Props): ReactNode => {
  const contextValue = useState<null | string>(null)
  const [state, setState] = contextValue

  useEffect(() => {
    setState(null)
  }, [resetKey]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CheckStateContext.Provider value={contextValue}>
      {typeof children === 'function'
        ? children(state)
        : children}
    </CheckStateContext.Provider>
  )
}
