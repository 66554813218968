import { useCallback, useEffect, useState } from 'react'

import { api } from 'app/api'
import { OrgsLimitsType } from 'app/dataTypes/org/limits'
import { GET_SCHOOL_LIMITS, convertParams, convertResult } from 'app/common/api/requests/food/schools/limits/get'


type Options = {
  orgId: number
  autoload?: boolean
}
export function useOrgLimits({ orgId, autoload = true }: Options) {
  const [loading, setLoading] = useState(true)

  const [limits, setLimits] = useState<OrgsLimitsType | null>(null)

  const request = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_SCHOOL_LIMITS, {
      error: 'Не удалось получить лимиты для школы',
      params: {
        orgId,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })
    if (res) {
      setLimits(res)
    }
    setLoading(false)
  }, [orgId])

  useEffect(() => {
    if (autoload) {
      request()
    }
  }, [request, autoload])

  return {
    limits,
    loading,
    request,
  }
}
