// @flow
import React, { useCallback } from 'react';

import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useDebouncedSearch from 'app/common/hooks/useDebouncedSearch';
import { ButtonsBar, OpenFilterButton } from 'app/common/ui/Button';
import { useFilterData } from 'app/common/ui-components/filter';


type Props = {
  onSearch: (string) => void,
  onFilterToggle: ((boolean => boolean) | boolean) => void,
  filterActive: boolean,
}

const FilterBar = ({ onSearch, onFilterToggle, filterActive }: Props) => {
  const handleReset = useCallback(() => onSearch(''), [onSearch]);
  const [searchString, handleSearch, handleClearSearch] = useDebouncedSearch(onSearch, handleReset);

  const [{ applied }] = useFilterData();

  const handleToggleFilter = useCallback((e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { body } = document;
    const { documentElement } = document;
    // скрол вверх
    if (body) body.scrollTop = 0; // For Safari
    if (documentElement) documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // показ окна с фильтрами
    onFilterToggle(state => !state);
  }, [onFilterToggle]);

  return (
    <ButtonsBar>
      {/* Кнопка открытия фильтра */}
      <OpenFilterButton
        onClick={handleToggleFilter}
        checked={!!applied}
        opened={filterActive}
      />
      {/* Форма поиска */}
      <PlainInputSearch
        placeholder="Поиск по номеру"
        searchString={searchString}
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      />
    </ButtonsBar>
  );
};

export default FilterBar;
