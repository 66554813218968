export const getOppositeFieldName = (fieldName: string) => {
  const count = parseInt(fieldName.replace(/^\D+/, ''), 10)
  const newCount = count === 1 ? '2' : '1'
  return fieldName.replace(String(count), newCount)
}

export const getSiblingFieldName = (fieldName: string) => {
  if (fieldName.includes('fixed')) return fieldName.replace('fixed', 'sum')
  if (fieldName.includes('sum')) return fieldName.replace('sum', 'fixed')
  return fieldName
}
