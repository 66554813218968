// @flow
import { defaultStateDataCurrent, type StateDataCurrent } from 'app/common/ui-components/filter';
import { orderTypes } from 'app/dataTypes/reports/orderTypes';


type Item = { id: string, title: string };

const common = {
  id: orderTypes.Common,
  title: 'Общая',
};

const personal = {
  id: orderTypes.Personal,
  title: 'Индивидуальная',
};

const orderTypeItems = (): [Array<Item>, StateDataCurrent] => (
  [[common, personal], defaultStateDataCurrent]
);

export default orderTypeItems;
