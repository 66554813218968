import { useState, useCallback } from 'react'

import {
  propEq,
  find,
  values,
  sort,
  subtract,
  map,
  pipe,
  assocPath,
  reduce,
  isEmpty,
  lensIndex,
  over,
  filter,
  omit,
  fromPairs,
} from 'ramda'

import { api } from 'app/api'

import {
  GET_SUBSIDY_SPLIT,
  POST_SUBSIDY_SPLIT,
  type SplitItem,
  SplittingType,
  SplitTypeID,
  type SubsidySplitResult,
  createDefaultSplitItem,
  splitTypeIDsAvailable,
} from 'app/common/api/requests/food/subsidy_split'

import extractSplitsAllErrors, { ErrorsState } from './extractSplitsAllErrors'


/**
 *  Данные формы настройки дотаций
 */

type IndexById = { [id: string]: number }
type UseCommonData = { [splitTypeId: string]: boolean }

export type State = {
  splits: Array<SplitItem>
  splittingType: SplittingType | null
  enableCompensationOrders: boolean
  modified: boolean
  loading: boolean
  submitting: boolean
  indexById: IndexById
  errors: ErrorsState
  commonDataById: UseCommonData
}

export const defaultState: State = {
  splits: [],
  splittingType: null,
  enableCompensationOrders: false,
  modified: false,
  loading: true,
  submitting: false,
  indexById: {},
  errors: {},
  commonDataById: {},
}

export type ApiHandlers = {
  request: () => void | Promise<void>,
  submit: () => boolean | Promise<boolean>,
  checkErrors: () => boolean,
}

export const defaultApiHandlers: ApiHandlers = {
  request: () => { },
  submit: () => false,
  checkErrors: () => false,
}

export type ModifyHandlers = {
  changeSplittingType: (type: SplittingType | null) => void,
  changeSplitItemField: (splitTypeId: SplitTypeID | null, fieldName: string, fieldValue: any) => void,
  updateSplitItem: (splitTypeId: SplitTypeID | null, updateFunc: (SplitItem) => SplitItem) => void,
  onUseCommonData: (splitTypeId: SplitTypeID | null, use: boolean) => void,
}

export const defaultModifyHandlers: ModifyHandlers = {
  changeSplittingType: () => { },
  changeSplitItemField: () => { },
  updateSplitItem: () => { },
  onUseCommonData: () => { },
}

export default function useSubsidies(): [State, ApiHandlers, ModifyHandlers] {
  const [splitsAllState, setSplitsAllState] = useState<Array<SplitItem>>([])
  const [splittingTypeState, setSplittingTypeState] = useState<SplittingType | null>(null)
  const [enableCompensationOrders, setEnableCompensationOrders] = useState<boolean>(false)
  const [modified, setModified] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  // состояние использования данных из indexById === 1
  const [commonDataById, setCommonDataById] = useState<UseCommonData>({} as UseCommonData)

  // индексация splitTypeId
  const [indexById, setIndexById] = useState<IndexById>({} as IndexById)

  // состояние с ошибками splitTypeId => fieldName => errorText
  const [errors, setErrors] = useState<ErrorsState>({} as ErrorsState)


  // применение данных возвращаемых запросами
  const updateStatesByRequestResult = useCallback((res: SubsidySplitResult) => {
    const {
      splits,
      subsidy_splitting: splittingType,
      enable_compensation_orders: eCO,
    } = res

    setSplittingTypeState(splittingType)
    setEnableCompensationOrders(eCO)

    // SPLITTING ALL
    if (splittingType === 'all') { // реализован пока один вариант - 'all'
      const arrangedSplits = pipe(
        values,
        sort(subtract),
        map((id) => {
          const currentSplit: SplitItem | void = find(propEq('split_type_id', id), splits)
          if (!currentSplit) {
            return createDefaultSplitItem(id)
          }
          if (id === SplitTypeID.Common) {
            return currentSplit
          }
          const partsByKlassesGroups = id === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
          return (currentSplit.use_common_order_settings
            ? { // заменяем на данные из splitTypeIdsAvailable.COMMON, если стоит соответствующий флаг
              ...currentSplit,
              ...omit(
                ['id', 'split_type_id'],
                find(propEq('split_type_id', SplitTypeID.Common), splits),
              ) || {},
              parts_by_klasses_groups: partsByKlassesGroups,
            }
            : {
              ...currentSplit,
              parts_by_klasses_groups: partsByKlassesGroups,
            })
        }),
      )(splitTypeIDsAvailable)
      setSplitsAllState(arrangedSplits)
      // индексация по id
      setIndexById(arrangedSplits.reduce((acc, { split_type_id: id }, idx) => {
        acc[String(id)] = idx
        return acc
      }, {}))
      // состояния элементов Toggle использования данных из первого разделения
      setCommonDataById(
        fromPairs(
          map(
            ({ split_type_id: stid, use_common_order_settings: use }) => ([String(stid), !!use]),
            arrangedSplits,
          ),
        ),
      )
    }
    // end SPLITTING ALL
  }, [])


  // Запрос данных
  const handleRequestSubsidySplit = useCallback(async () => {
    setLoading(true)
    const res: SubsidySplitResult | null = await api.request(GET_SUBSIDY_SPLIT, {
      error: 'Не удалось получить данные по дотациям',
    })

    setLoading(false)

    if (res) {
      setErrors({})
      setModified(false)
      updateStatesByRequestResult(res)
    }
  }, [updateStatesByRequestResult])


  // Сохранение данных
  const handleSubmitSubsidySplit = useCallback(async () => {
    if (!modified) {
      return false
    }
    const commonData = splitsAllState[indexById[String(SplitTypeID.Common)]]
    setSubmitting(true)
    const res: SubsidySplitResult | null = await api.request(POST_SUBSIDY_SPLIT, {
      error: 'Не удалось сохранить данные по дотациям',
      params: {
        splits: map((splitItem: SplitItem) => {
          const { id, split_type_id: splitTypeId } = splitItem
          if (splitTypeId === SplitTypeID.Common) return splitItem
          if (commonDataById[String(splitTypeId)]) {
            const splitData = {
              ...commonData,
              split_type_id: splitTypeId,
              use_common_order_settings: true,
            }
            return (id
              ? { ...splitData, id }
              : splitData
            )
          }
          return ({
            ...splitItem,
            use_common_order_settings: false,
          })
        }, splitsAllState),
        subsidy_splitting: splittingTypeState,
      },
    })
    setSubmitting(false)
    if (res) {
      setModified(false)
      updateStatesByRequestResult(res)
      return true
    }
    return false
  }, [indexById, splitsAllState, splittingTypeState, commonDataById, updateStatesByRequestResult, modified])


  // изменение типа разделения по видам бюджета
  const changeSplittingType = useCallback((type) => {
    setModified(true)
    setErrors({})
    setSplittingTypeState(type)
  }, [])


  // изменение поля
  const changeSplitItemField = useCallback((
    splitTypeId: SplitTypeID | null,
    fieldName: string,
    fieldValue: any,
  ) => {
    const index = indexById[String(splitTypeId)]
    if (typeof index === 'number') {
      const path = [index, fieldName]
      setErrors({})
      setModified(true)
      setSplitsAllState(assocPath(path, fieldValue))
    }
  }, [indexById])


  // изменение полей массово
  const updateSplitItem = useCallback((
    splitTypeId: SplitTypeID | null,
    updateFunc: (splitItem: SplitItem) => SplitItem,
  ) => {
    setErrors({})
    setModified(true)
    const index = lensIndex<SplitItem>(indexById[String(splitTypeId)])
    setSplitsAllState(over(index, updateFunc))
  }, [indexById])


  // "использовать параметры из общих справок"
  const handleUseCommonData = useCallback((splitTypeId: SplitTypeID | null, use: boolean) => {
    if (!splitTypeId || splitTypeId === SplitTypeID.Common) {
      // форма с типом данных COMMON не может использовать свои же данные
      return
    }
    setErrors({})
    setModified(true)
    setCommonDataById(currentState => ({
      ...currentState,
      [String(splitTypeId)]: use,
    }))
  }, [])


  // проверка ошибок
  const checkErrors = useCallback(() => {
    if (!splittingTypeState) {
      return true
    }

    const splitsForErrorsCheck = filter(({ split_type_id: splitTypeId }: SplitItem) => {
      return !commonDataById[String(splitTypeId)]
    }, splitsAllState)

    const currentErrors = reduce(extractSplitsAllErrors, {} as ErrorsState, splitsForErrorsCheck)
    setErrors(currentErrors)
    if (isEmpty(currentErrors)) {
      return true
    }
    return false
  }, [splitsAllState, commonDataById, splittingTypeState])


  return [{
    splits: splitsAllState,
    splittingType: splittingTypeState,
    enableCompensationOrders,
    modified,
    loading,
    submitting,
    indexById,
    errors,
    commonDataById,
  }, {
    request: handleRequestSubsidySplit,
    submit: handleSubmitSubsidySplit,
    checkErrors,
  }, {
    changeSplittingType,
    changeSplitItemField,
    updateSplitItem,
    onUseCommonData: handleUseCommonData,
  }]
}
