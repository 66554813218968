// @flow
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import UserMenu from 'app/common/components/UserMenu';
import Link from 'app/common/lib/link';
import { logout } from 'app/common/actions/user';


const UserMenuLocal = () => {
  const { t } = useTranslation('app');

  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);


  return (
    <UserMenu>
      <Link to="/profile">
        {t('user_menu_item_profile')}
      </Link>
      <Link to="/about">
        {t('user_menu_item_about')}
      </Link>
      {'---'}
      <button onClick={handleLogout} type="button">
        {t('user_menu_item_logout')}
      </button>
    </UserMenu>
  );
};

export default UserMenuLocal;
