// @flow
import { map } from 'ramda';

import type { Params, PlanogramContent, PlanogramSlotContent } from 'app/dataTypes/vending/PlanogramContent.types';

import type { Request } from '../../../../../index';


type ResultApi = Array<{
  slot_id: number,
  item_id: number | null,
  amount: number | null,
}>

type UrlParams = {
  machineId: string,
}

export const convertParams = (params: Params): [null, UrlParams] => {
  return [null, params];
};

export const convertResult = (data: ResultApi): PlanogramContent => {
  return new Map(map(({
    slot_id: id,
    item_id: productId,
    amount,
  }): [string, PlanogramSlotContent] => {
    const slotId = String(id);

    return [slotId, {
      slotId,
      productId: productId ? String(productId) : null,
      amount: typeof amount === 'number' ? amount : null,
    }];
  }, data));
};

export const GET_PLANORGAM_SLOTS_CONTENT: Request<
  Params,
  PlanogramContent,
  UrlParams
> = [
  'data/vending/machines/{machineId}/slots/content',
  'get',
  ['data', 'data'],
];
