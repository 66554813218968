import { keys } from 'ramda'

import SimpleDate from 'app/common/classes/simple-date'
import toFixedValue from 'app/common/lib/toFixedValue'
import { ReportEmoneySalesData } from 'app/dataTypes/reports/data'


const dateFormat = 'YYYY-MM-DD' as const

type School = {
  school_id: number
  school_title: string
  data: Record<string, { //  данные за день
    s_sum: number //            списания ЭДС
    u_ret_sum: number //        возвраты ЭДС
    transfer_sum: number //     сумма для перевода
    corrs_sum: number //        сумма корректировок
    purchases_sum: number //    сумма продаж
  }>
}

export type ApiResultEmoneySales = {
  date_from: string
  date_to: string
  schools: Array<School>
  total: Record<string, { // итоговые значения по датам
    sales_sum: number //        сумма продаж
    transfer_sum: number //     сумма для перевода
  }>
  summary: { //              итоговые суммы
    sales_sum: number //        сумма продаж
    corrs_sum: number //        сумма корректировок
    s_sum: number //            сумма списаний ЭДС
    u_ret_sum: number //        сумма возвратов ЭДС
    transfer_sum: number //     сумма для перевода ЭДС
  }
}

export const convertResultEmoneySales = (res: ApiResultEmoneySales): ReportEmoneySalesData => {
  const {
    summary,
    total,
    schools,
  } = res

  const headers = keys(total)
    .map(date => new SimpleDate(date, dateFormat))
    .sort()
    .map(date => ({
      ID: date.getStringFormatted(dateFormat),
      date,
    }))

  const orgs = schools
    .map(({ data, school_id: id, school_title: title }) => ({
      ID: String(id),
      title,
      data: headers.map(({ ID }) => {
        const {
          s_sum: sSum,
          u_ret_sum: uRetSum,
          transfer_sum: transferSum,
          corrs_sum: corrsSum,
          purchases_sum: purchasesSum,
        } = data[ID]

        return {
          ID,
          corrsSum: toFixedValue(corrsSum),
          purchasesSum: toFixedValue(purchasesSum),
          totalSum: toFixedValue(corrsSum + purchasesSum),
          sSum: toFixedValue(sSum),
          uRetSum: toFixedValue(uRetSum),
          transferSum: toFixedValue(transferSum),
        }
      }),
    }))

  const totalData = headers.map(({ ID }) => {
    const {
      sales_sum: salesSum,
      transfer_sum: transferSum,
    } = total[ID]

    return {
      ID,
      salesSum: toFixedValue(salesSum),
      transferSum: toFixedValue(transferSum),
    }
  })

  const {
    sales_sum: salesSum,
    transfer_sum: transferSum,
    s_sum: sSum,
    u_ret_sum: uRetSum,
    corrs_sum: corrsSum,
  } = summary

  return {
    summary: {
      salesSum: toFixedValue(salesSum),
      corrsSum: toFixedValue(corrsSum),
      totalSum: toFixedValue(salesSum + corrsSum),
      sSum: toFixedValue(sSum),
      uRetSum: toFixedValue(uRetSum),
      transferSum: toFixedValue(transferSum),
    },
    headers,
    orgs,
    total: totalData,
  }
}
