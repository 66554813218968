// @flow
import React, { type Node } from 'react';

import SettingsContext, { defaultSettings } from './settings-context';
import useSettings from './use-settings';


type Props = {
  children: Node,
  root?: $Keys<typeof defaultSettings> | 'all' | null,
}

const SettingsDataProvider = ({ children, root }: Props) => {
  const value = useSettings(root);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsDataProvider;
