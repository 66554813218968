import { Children, ReactNode, ElementType } from 'react'
import cn from 'classnames/bind'

import { NavItem, isNavItem } from './nav-item'

import styles from './plain-nav.scss'


const cx = cn.bind(styles)

type Props = {
  children: ReactNode,
  className?: string,
  NavComponent?: ElementType
}

const PlainNav = ({ children, className, NavComponent = 'a' }: Props) => {
  return (
    <nav className={cx(styles.root, className)}>
      {Children.map(children, (child) => {
        if (isNavItem(child)) {
          const { props, key } = child
          const { children: ch, active, disabled, ...others } = props // eslint-disable-line react/prop-types

          if (disabled) {
            return (
              <span className={cx({ active, disabled })} key={key}>
                {ch}
              </span>
            )
          }

          return (
            <NavComponent {...others} className={cx({ active })} key={key}>
              {ch}
            </NavComponent>
          )
        }
        return child
      })}
    </nav>
  )
}

PlainNav.Item = NavItem

export default PlainNav
