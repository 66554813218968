// @flow
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import prop from 'ramda/es/prop';

import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';
import { dateFormat } from 'app/dataTypes/OrgSettingsChanges.types';

import periodFilter, { periodItems } from './period';
import sourceFilter, { sourceItems, sourceActivity } from './source';
import actorFilter, { actorItems, actorActivity } from './actor';
import filterDataFilter, { filterDataItems } from './filterData';
import type { FilterParams } from './FIlterParams.type';


const filterItems: Descriptions<FilterParams> = [{

  /* фильтр по периоду */
  filterId: periodFilter,
  filterType: filterTypes.PLAIN_PERIOD,
  props: {
    label: 'Период времени',
    fromLabel: 'от',
    toLabel: 'до',
    dateFormat,
    periodLimit: 1,
    periodLimitUnit: 'months',
    buttonResetLabel: null,
  },
  getItemsAndCurrent: periodItems,
  relations: [filterDataFilter],
}, {

  /* данные для следующих двух фильтров */
  filterId: filterDataFilter,
  filterType: filterTypes.HIDDEN_DATA,
  multiple: false,
  getItemsAndCurrent: filterDataItems,
  relations: [sourceFilter, actorFilter],
}, {

  /* фильтр по месту изменения */
  filterId: sourceFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Место изменения',
    header: 'Выберите место изменения',
    sortFunc: sort(descend(prop('title'))),
    buttonResetLabel: null,
    colorInverse: true,
  },
  getItemsAndCurrent: sourceItems,
  activity: sourceActivity,
}, {

  /* фильтр по производившему изменение */
  filterId: actorFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Кто внес изменения',
    header: 'Выберите вносившего изменения',
    sortFunc: sort(descend(prop('title'))),
    buttonResetLabel: null,
    colorInverse: true,
  },
  getItemsAndCurrent: actorItems,
  activity: actorActivity,
}];

export default filterItems;
