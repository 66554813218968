import { useCallback } from 'react'
import { api } from 'app/api'
import { POST_REPORTS_PERFORM, convertParams, convertResult } from 'app/common/api/requests/reports/perform/post'
import { RequestParams } from 'app/dataTypes/reports'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import usePopup from 'app/common/hooks/usePopup'


type Options = {
  onReportID: (reportID: string | null) => void
  isMailingOnly: (formData: FormData) => boolean
}


export function useReportPerform({ onReportID, isMailingOnly }: Options) {
  const onReportIDRef = useCallbackRef(onReportID)
  const isMailingOnlyRef = useCallbackRef(isMailingOnly)
  const popup = usePopup()

  const perform = useCallback(async ({ formData, reportType }: RequestParams) => {
    const mailingOnly = isMailingOnlyRef.current?.(formData) || false
    const res = await api.request(POST_REPORTS_PERFORM, {
      error: 'Не удалось запустить генерацию отчета',
      params: {
        formData,
        reportType,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })
    if (mailingOnly) {
      popup('Отчет успешно добавлен в очередь на генерацию и после завершения будет отправлен на ваш e-mail')
      onReportIDRef.current?.(null)
      return
    }
    onReportIDRef.current?.(res)
  }, [isMailingOnlyRef, onReportIDRef, popup])


  return {
    perform,
  }
}
