import React, { useCallback, useRef, useState } from 'react'

import { RowLayoutWithSticky, RowLayoutContent, Header } from 'app/common/layouts/RowLayout'
import SaveCancelButtons from 'app/components/common/SaveCancelButtons'
import { useWriteAccess } from 'app/rights/RightsForOrg'
import Prompt from 'app/components/common/Prompt'
import { useLastChange } from 'app/dataProviders/OrgSettingsHistoryProvider'
import { ContentGroup } from 'app/common/templates-next/page-template'

import MultipleSubmitSettings from '../MultipleSubmitSettings'
import { rightsPath } from '../rightsPath'
import useRejectSubsidiesPeriodOpt from './useRejectSubsidiesPeriodOpt'
import CopySettings from '../CopySettings'
import RejectSubsidiesPeriodOpt from './RejectSubsidiesPeriodOpt'
import SORCommonOptPeriods from './SORCommonOptPeriods'
import SORPersonalOpt from './SORPersonalOpt'
import useSubsidyOrdersRestrictionsOpt from './useSubsidyOrdersRestrictionsOpt'
import useOverallRequest from './useOverallRequest'
import useOverallSubmit from './useOverallSubmit'
import HistoryLink from '../HistoryLink'
import sections, { sectionTitles } from '../sections'
import useRestrictionsByRole from './useRestrictionsByRole'
import RestrictionsByRoleOpt from './RestrictionsByRoleOpt'
import * as ot from './orderTypes'
import MultipleModalAdditionalContent from './MultipleModalAdditionalContent'
import CampSubsidyOrders from './CampSubsidy/CampSubsidyOrders'
import useCampSubsidyOrders from './CampSubsidy/useCampSubsidyOrders'
import useCampSubsidyOrdersErrors from './CampSubsidy/useCampSubsidyOrdersErrors'
import useGeneralSettingsRequest from './useGeneralSettingsRequestSingleton'
import useOverallCheckErrors from './useOverallCheckErrors'
import SubsidyCategories from './SubsidyCategories/SubsidyCategories'

import styles from './Subsidies.scss'


const currentSection = sections.ORGS_SETTINGS_SUBSIDIES
const header = sectionTitles[currentSection]

type Props = {
  orgId: number,
}

const Subsidies = ({ orgId }: Props) => {
  const [, updateLastChange] = useLastChange()
  const formRef = useRef(null)
  const [submitting, setSubmitting] = useState(false)

  const requestGeneralSettings = useGeneralSettingsRequest(orgId)

  const [{
    state: rejectSubsidiesPeriod,
    modified: modifiedRSP,
  }, {
    request: requestRSP,
    submit: submitRSP,
    change: changeRSP,
  }] = useRejectSubsidiesPeriodOpt(orgId, requestGeneralSettings)

  const [{
    state: subsidyOrdersRestrictions,
    modified: modifiedSOR,
    // periodsLimit,
    // limitsLoading,
  }, {
    request: requestSOR,
    submit: submitSOR,
    submitCommon: submitCommonSOR,
    submitPersonal: submitPersonalSOR,
    change: changeSOR,
    resetDates,
  }] = useSubsidyOrdersRestrictionsOpt({ orgId })

  const [{
    restrictionsByRole,
    modified: modifiedRBR,
    errors: errorsRBR,
  }, {
    request: requestRBR,
    submitCommon: submitCommonRBR,
    submitPersonal: submitPersonalRBR,
    submit: submitRBR,
    checkErrors: checkErrorsRBR,
    checkErrorsCommon,
    checkErrorsPersonal,
    restrictionEnableChange,
    daysChange,
  }] = useRestrictionsByRole(orgId, subsidyOrdersRestrictions)

  const {
    state: campSubsidyOrders,
    modified: modifiedCSO,
    modifyRef: modifyRefCSO,
    request: requestCSO,
    submit: submitCSO,
    changeUsingOfPresets: changeUsingOfCampSubsidyPresets,
    changePresets: changeCampSubsidyPresets,
  } = useCampSubsidyOrders(orgId, requestGeneralSettings)

  const [errorsCSO, checkErrorsCSO] = useCampSubsidyOrdersErrors(campSubsidyOrders, modifyRefCSO)

  const [loading, request] = useOverallRequest([requestRSP, requestSOR, requestRBR, requestCSO])
  const submit = useOverallSubmit([submitRSP, submitSOR, submitRBR, submitCSO])
  const checkErrors = useOverallCheckErrors([checkErrorsRBR, checkErrorsCSO])

  const handleSave = useCallback((/* e: SyntheticEvent<HTMLButtonElement> */) => {
    if (formRef.current) {
      (formRef.current as any).submit.click()
    }
  }, [])

  const writeAccess = useWriteAccess(rightsPath)
  const disabled = !writeAccess || loading || submitting // || limitsLoading;
  const modified = modifiedRSP || modifiedSOR || modifiedRBR || modifiedCSO

  const {
    common: { enabled: enabledCommon },
    personal: { enabled: enabledPersonal },
  } = subsidyOrdersRestrictions

  return (
    <RowLayoutWithSticky
      number={3}
      content={
        <RowLayoutContent borderBottom className={styles.topbar}>
          <div>
            <Header>{header}</Header>
            <HistoryLink sectionTitle={header} />
          </div>
          {modified
            && <SaveCancelButtons
              onSave={handleSave}
              onCancel={request}
              // checksBeforeSave={checkErrors}
              disabled={loading || submitting}
              saving={submitting}
              cancelling={modified && loading}
            />}
        </RowLayoutContent>
      }
    >
      <RowLayoutContent>
        <MultipleSubmitSettings
          additionalContent={<MultipleModalAdditionalContent />}
          onSubmitArray={submit}
          onChangeSubmitState={setSubmitting}
          onSuccess={updateLastChange}
          onShowModal={checkErrors}
          formId="subsidies_form"
        >
          {(toggleMultipleSubmitModal, multipleSubmit) => (
            <form onSubmit={multipleSubmit} ref={formRef} id="subsidies_form">
              <ContentGroup header="Общие настройки">

                <RejectSubsidiesPeriodOpt
                  currentState={rejectSubsidiesPeriod}
                  onChange={changeRSP}
                  disabled={disabled}
                  hasTopMargin
                >
                  <CopySettings onSubmit={submitRSP} />
                </RejectSubsidiesPeriodOpt>

              </ContentGroup>

              {/* общие справки */}

              <ContentGroup
                header={
                  <span>
                    {'Настройки по '}
                    <strong>{'общим справкам'}</strong>
                    {' на льготное '}
                    <strong>{'питание в школе'}</strong>
                  </span>
                }
              >

                <SORCommonOptPeriods
                  currentState={subsidyOrdersRestrictions}
                  onChange={changeSOR}
                  onReset={resetDates}
                  // periodsLimit={periodsLimit}
                  disabled={disabled}
                  hasTopMargin
                >
                  <CopySettings onSubmit={submitCommonSOR} />
                </SORCommonOptPeriods>

                {subsidyOrdersRestrictions.common.enabled
                  && <RestrictionsByRoleOpt
                    orderType={ot.orderCommon}
                    restrictions={restrictionsByRole}
                    errors={errorsRBR}
                    disabled={disabled || !enabledCommon}
                    loading={loading}
                    onChangeDays={daysChange}
                    onChangeRestriction={restrictionEnableChange}
                  >
                    <CopySettings onSubmit={submitCommonRBR} onBeforeModalOpen={checkErrorsCommon} />
                  </RestrictionsByRoleOpt>}

                <SubsidyCategories
                  orgID={String(orgId)}
                  subsidyType="common"
                  app="cafeteria"
                  hasTopMargin
                />

              </ContentGroup>

              {/* индивидуальные справки */}

              <ContentGroup
                header={
                  <span>
                    {'Настройки по '}
                    <strong>{'индивидуальным справкам'}</strong>
                    {' на льготное '}
                    <strong>{'питание в школе'}</strong>
                  </span>
                }
              >

                <SORPersonalOpt
                  currentState={subsidyOrdersRestrictions}
                  onChange={changeSOR}
                  disabled={disabled}
                  hasTopMargin
                >
                  <CopySettings onSubmit={submitPersonalSOR} />
                </SORPersonalOpt>

                {subsidyOrdersRestrictions.personal.enabled
                  && <RestrictionsByRoleOpt
                    orderType={ot.orderPersonal}
                    restrictions={restrictionsByRole}
                    errors={errorsRBR}
                    disabled={disabled || !enabledPersonal}
                    loading={loading}
                    onChangeDays={daysChange}
                    onChangeRestriction={restrictionEnableChange}
                  >
                    <CopySettings onSubmit={submitPersonalRBR} onBeforeModalOpen={checkErrorsPersonal} />
                  </RestrictionsByRoleOpt>}

                <SubsidyCategories
                  orgID={String(orgId)}
                  subsidyType="personal"
                  app="cafeteria"
                  hasTopMargin
                />

              </ContentGroup>

              {/* возмещения дотаций для лагерей */}

              <ContentGroup
                header={
                  <span>
                    {'Настройки по '}
                    <strong>{'индивидуальным справкам'}</strong>
                    {' на льготное '}
                    <strong>{'питание в пришкольном лагере'}</strong>
                  </span>
                }
              >

                <CampSubsidyOrders
                  disabled={disabled}
                  campSubsidyOrders={campSubsidyOrders}
                  errors={errorsCSO}
                  onChangeUsingOfPresets={changeUsingOfCampSubsidyPresets}
                  onChangePresets={changeCampSubsidyPresets}
                  hasTopMargin
                >
                  <CopySettings onSubmit={submitCSO} onBeforeModalOpen={checkErrorsCSO} />
                </CampSubsidyOrders>

                {!campSubsidyOrders.camp_subsidy_orders_use_presets
                  && <SubsidyCategories
                    orgID={String(orgId)}
                    subsidyType="personal"
                    app="camp"
                    hasTopMargin
                  />}

              </ContentGroup>

              <button type="submit" name="submit" className={styles.hidden}>
                {'Сохранить'}
              </button>
            </form>
          )}
        </MultipleSubmitSettings>

        <Prompt
          when={modified}
          message="Вы не сохранили изменения, перейти в любом случае?"
        />
      </RowLayoutContent>
    </RowLayoutWithSticky>
  )
}

export default Subsidies
