import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { connect } from 'react-redux';

import { fetchSettings } from 'app/common/actions/settings/load';
import { dealerFetch } from 'app/actions/dealer';
import { fetchUser } from 'app/common/actions/user';
import { dishesTypesFetch } from 'app/actions/dishes/types';
import { fetchBalance } from 'app/common/actions/user/balance';
import { fetchDocuments } from 'app/common/actions/documents';
// import { userRightsFetch } from 'app/actions/userRights';
import withLog from 'app/common/containers/withLog';

import { needRefresh } from 'app/common/actions/settings/current';
import { serviceWorkerUpdate } from 'app/common/components-next/service-worker';


export default compose(
  connect(
    state => ({
      isNeedRefresh: state.settings.current.needRefresh,
    }),
  ),

  withHandlers({
    fetchData: ({ dispatch }) => () => {
      dispatch(fetchSettings());
      dispatch(dealerFetch());
      dispatch(fetchUser());
      // заменено на app/rights/Rights
      // dispatch(userRightsFetch());
      dispatch(fetchDocuments());
      dispatch(dishesTypesFetch());
      dispatch(fetchBalance());
    },
  }),

  lifecycle({
    componentDidMount() {
      this.saved = document.getElementsByTagName('body')[0].getAttribute('style');
      document.getElementsByTagName('body')[0].removeAttribute('style');
      this.props.fetchData();
    },
    componentDidUpdate(prevProps) {
      const { dispatch, fetchData, isNeedRefresh } = this.props;
      if (isNeedRefresh !== prevProps.isNeedRefresh && isNeedRefresh) {
        dispatch(needRefresh(false));
        fetchData();
      }
      if (this.props.location.pathname !== prevProps.location.pathname) {
        dispatch(fetchUser(true));
        // заменено на app/rights/Rights
        // dispatch(userRightsFetch());
        dispatch(fetchBalance());
        dispatch(fetchDocuments());
        serviceWorkerUpdate();
      }
    },
    componentWillUnmount() {
      document.getElementsByTagName('body')[0].setAttribute('style', this.saved);
    },
  }),

  withLog,

  branch(
    ({ isNeedRefresh }) => isNeedRefresh,
    renderNothing,
  ),
);
