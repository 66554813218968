// @flow
import React from 'react';
import cn from 'classnames';

import type { DisplayableData } from '../displayableData';
import MultiLineContainer from './MultiLineContainer';
import Entry, { iconTypes } from './Entry';
import multiLineCellTypes from './multiLineCellTypes';
import DoubleEntries from './DoubleEntries';

import styles from './MultiLineCellDouble.scss';


const emptyCell = '—';

type Props = {
  className?: string,
  type?: $Values<typeof multiLineCellTypes>,
  showDuty?: boolean,
  borderRight?: boolean,
  data: DisplayableData,
  restrictWidth?: number,
}

const MultiLineCellDoubleTotal = ({
  className,
  type,
  showDuty,
  borderRight,
  data,
  restrictWidth,
}: Props) => {
  const {
    singleCount,
    orderCount,
    singleSum,
    orderSum,
    hasDuty,
    dutySum,
    orderSubsidiesSum,
    singleSubsidiesSum,
    totalOrderWithoutSubsidiesSum,
    totalSingleWithoutSubsidiesSum,
  } = data;

  const bottomCell = type === multiLineCellTypes.TOTAL;

  return (
    <MultiLineContainer
      className={cn(styles.root, className)}
      bottomSpace={bottomCell}
      borderRight={borderRight}
      restrictWidth={restrictWidth}
      type={type}
    >
      <DoubleEntries>
        <Entry dipper>
          {singleCount}
        </Entry>
        <Entry dipper>
          {orderCount}
        </Entry>
      </DoubleEntries>

      {bottomCell
        && <DoubleEntries>
          <Entry>
            {totalSingleWithoutSubsidiesSum || emptyCell}
          </Entry>
          <Entry>
            {totalOrderWithoutSubsidiesSum || emptyCell}
          </Entry>
        </DoubleEntries>}

      {bottomCell
        && <DoubleEntries>
          <Entry>
            {singleSubsidiesSum || emptyCell}
          </Entry>
          <Entry>
            {orderSubsidiesSum || emptyCell}
          </Entry>
        </DoubleEntries>}

      <DoubleEntries>
        <Entry
          type={type}
          icon={iconTypes.USER_SINGLE}
          iconic
          summary
        >
          {singleSum}
        </Entry>
        <Entry
          type={type}
          icon={iconTypes.USER_COUPLE}
          iconic
          summary
        >
          {orderSum}
        </Entry>
      </DoubleEntries>

      {showDuty
        ? <Entry
          duty={hasDuty}
          type={type}
        >
          {dutySum}
        </Entry>
        : null}

    </MultiLineContainer>
  );
};

export default MultiLineCellDoubleTotal;
