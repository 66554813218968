import { ReactNode, useEffect, useState } from 'react'

import CommonPage from 'app/common/templates/CommonPage'
import CSSSticky from 'app/common/ui-components/css-sticky'
import { DotsLoader } from 'app/common/components/Loader'
import { RightsForOrgProvider } from 'app/rights/RightsForOrg'
import { useOrg } from 'app/dataProviders/org/OrgsDataProvider'
import type { RightsPath, RightsObject } from 'app/rights/types'
import { OrgType, isOrgType } from 'app/dataTypes/org/types'
import { Rights } from 'app/common/constants/rights'

import AccessDenied from './AccessDenied'
import Navigation from './Navigation'
import useNavigationAdditionalLinks from './useNavigationAdditionalLinks'
import getParentBaseUrl from '../getBaseUrl'

import styles from './OrgsPageTemplate.scss'

/**
 *  Шаблон страниц раздела с общей навигацией
 */

const emptyLinks = []

type Props<T extends keyof Rights> = {
  children: ReactNode | ((type: OrgType, placeForGuests: boolean) => ReactNode)
  orgId: number
  date?: string
  rightsPath: RightsPath<T>
}

export default function OrgsPageTemplate<T extends keyof Rights>({
  children,
  orgId,
  date,
  rightsPath,
}: Props<T>) {
  const [loading, setLoading] = useState(true)
  // данные текущего заведения для заголовка и пр.
  const [orgProps, requestOrg] = useOrg(orgId)
  const { title: header, deleted, type, food_place_for_guests: placeForGuests } = orgProps

  useEffect(() => {
    (async () => {
      setLoading(true)
      await requestOrg()
      setLoading(false)
    })()
  }, [requestOrg])

  const parentBaseUrl = getParentBaseUrl()
  const links = useNavigationAdditionalLinks(parentBaseUrl, type)
  const additionalNavLinks = loading ? emptyLinks : links

  // доступ на просмотр раздела
  // пока данные по доступу не готовы, страница разблокирована,
  // это для того чтоб не было AccessDenied, при обновлении страницы
  const [access, setAccess] = useState<RightsObject | null>(null)
  const { read: readAccess } = access || { read: true }

  if (!readAccess) {
    return (
      <AccessDenied />
    )
  }

  const orgType = isOrgType(type) ? type as OrgType : null

  if (!orgType) {
    return null
  }

  return (
    <RightsForOrgProvider
      orgId={orgId}
      preferred={rightsPath}
      onUpdatePreferredRights={setAccess}
    >
      <CommonPage
        header={header || <DotsLoader simple />}
        headerSuffix={deleted ? ' (удалена)' : ''}
        stickable
      >
        <CSSSticky
          number={2}
          className={styles.stickable}
        >
          <Navigation
            orgID={String(orgId)}
            date={date}
            orgType={orgType}
            additionalLinks={additionalNavLinks}
          />
        </CSSSticky>

        {typeof children === 'function'
          ? children(orgType, placeForGuests)
          : children}
      </CommonPage>
    </RightsForOrgProvider>
  )
}
