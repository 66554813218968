
import { map, values } from 'ramda'
import { StudentListType, Params } from 'app/dataTypes/studentList'
import { Request } from '../../index'


type Student = {
  id: number,
  name: {
    first: string,
    last: string,
    middle: string,
  },
}


type ResultApi = Record<string, Student>

export const convertResult = (apiData: ResultApi): StudentListType => {
  return map(({
    id,
    name,
  }) => {
    return ({
      ID: String(id),
      name,
    })
  }, values(apiData))
}

export type ApiParams = {
  school_id: number,
  klass_id?: number,
  status?: string,
}

export const convertParams = (params: Params): [ApiParams, null] => {
  const {
    schoolId,
    classId,
    status,
  } = params
  return [{
    school_id: parseInt(schoolId, 10),
    klass_id: parseInt(classId, 10),
    status,
  }, null]
}

export const GET_STUDENTS_LIST: Request<Params, StudentListType, void> = [
  'reports/students_list',
  'get',
  ['data', 'students'],
]
