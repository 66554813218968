import { createSelector } from 'reselect';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import keys from 'ramda/es/keys';
import unnest from 'ramda/es/unnest';
import uniq from 'ramda/es/uniq';
import filter from 'ramda/es/filter';
import find from 'ramda/es/find';
import test from 'ramda/es/test';

import { selectIsAll as selectIsAllELevels } from 'app/components/Reports/FilterItems/hocs/withEducationLevels';


const selectAllowedParallelsList = createSelector(
  [
    state => state.reports.filters.educationLevels.data,
    state => state.reports.filters.educationLevels.selected,
  ],
  (educationLevels, selected) => pipe(
    keys,
    map(id => (educationLevels[id] || [])),
    unnest,
    uniq,
  )(selected),
);

const selectClassItemsByEduLevelsFilter = createSelector(
  [
    state => state.reports.filters.classes.data,
    selectAllowedParallelsList,
    selectIsAllELevels,
  ],
  (classes, allowedParallelsList, isAllELevelsSelected) => {
    if (isAllELevelsSelected) {
      return classes;
    }
    const arrAllowedRg = map(p => new RegExp(`^${p}[^\\d|$]`), allowedParallelsList);
    return filter(
      // фильтрация классов по совпадению начала имени
      // класса с хотя бы одним именем параллели из массива доступных параллелей
      ({ title }) => find(rg => test(rg, title), arrAllowedRg),
      classes,
    );
  },
);

export default selectClassItemsByEduLevelsFilter;
