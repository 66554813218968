// @flow
import pipe from 'ramda/es/pipe'
import split from 'ramda/es/split'
import adjust from 'ramda/es/adjust'
import replace from 'ramda/es/replace'
import join from 'ramda/es/join'

/**
 * convert to necessary numeric format
 * @param {String, Number} variable
 * @returns {String}
 */

function toFixedValue(variable: number | string | null | void) {
  if (variable === 0) return '0,00'
  if (variable !== undefined && variable !== null && variable !== '') {
    const number = Number(variable)
    if (Number.isNaN(number)) return ''
    const val = number.toFixed(2)
    const res: string = pipe(
      split('.'),
      adjust(0, replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')), // обновить ramda до 0.26.1
      join(','),
      replace(/-/, '−'),
    )(val)
    return res
  }
  return ''
}

/**
 * convert to necessary numeric format
 * Результат - строка, целое число, не дробное
 * @param {String, Number} variable
 * @returns {String}
 */

export function toFixedValueInt(variable?: number | string | null | void) {
  if (variable === 0) return '0'
  if (variable !== undefined && variable !== null && variable !== '') {
    const number = Number(variable)
    if (Number.isNaN(number)) return ''
    const val = number.toFixed()
    const res = pipe(
      replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0'), // обновить ramda до 0.26.1
      replace(/-/, '−'),
    )(val)
    return res
  }
  return ''
}

/**
 * convert to necessary numeric format
 * Результат - строка c символом рубля в конце
 * @param {String, Number} variable
 * @returns {String}
 */

export const toFixedValueCurrency = (val?: number | string | null | void, isInteger?: boolean, currency: string = '₽') => (
  `${isInteger ? toFixedValueInt(val) : toFixedValue(val)}\u00A0${currency}`
)

export default toFixedValue

/**
 * Функция, которая возвращает функцию,
 * возвращающую имя класса в зависимости
 * от положительного или отрицательного
 * значения 'value'
 */

export const makeCnbyValue = (
  classNameNegative: string,
  classNamePositive: string,
  classNameZero?: string,
) => (value: string) => {
  if (!value || value === '0' || value === '0.00' || value === '0,00') {
    return classNameZero || classNamePositive
  }
  if (value[0] === '−') {
    return classNameNegative
  }
  return classNamePositive
}


export const fromFixedValue = (value: string | null) => {
  if (!value) return null
  return parseFloat(value.replace(',', '.').replace(/\s/g, ''))
}
