import { ParamsPOST } from 'app/dataTypes/org/limits'
import type { Request } from '../../../index'


export type ApiParams = {
  schools_ids: Array<number>;
  limits: {
    cafeteria_limits_readonly_for_schools: boolean;
    max_cafe_order_minus: number;
    max_cafe_minus: number;
    cafe_day_limit: number;
    max_camp_cafe_order_minus: number;
    split_student_day_limit: boolean
  }
}

export const convertParams = ({ schoolsIds, orgLimits }: ParamsPOST): [ApiParams, null] => {
  const result: ApiParams = {
    schools_ids: schoolsIds.map(Number),
    limits: {
      cafeteria_limits_readonly_for_schools: orgLimits.cafeteriaLimitsReadonlyForSchools,
      max_cafe_order_minus: orgLimits.maxCafeOrderMinus,
      max_cafe_minus: orgLimits.maxCafeMinus,
      cafe_day_limit: orgLimits.cafeDayLimit,
      max_camp_cafe_order_minus: orgLimits.maxCampCafeOrderMinus,
      split_student_day_limit: orgLimits.splitStudentDayLimit,
    },
  }
  return [result, null]
}

type ApiResult = {
  result: 'success',
}

export const convertResult = (result: ApiResult): true => {
  return !!result
}

export const POST_SCHOOL_LIMITS: Request<ParamsPOST, true, void> = [
  'food/schools/limits',
  'post',
  ['data'],
]
