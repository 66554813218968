import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';


export default compose(
  withState('accepted', 'setAccepted', false),
  withState('agreementText', 'showAgreementText', false),

  withHandlers({
    handleCheck: ({ setAccepted }) => (e) => {
      setAccepted(e.currentTarget.checked);
    },

    handleRead: ({ showAgreementText }) => (e) => {
      e.preventDefault();
      showAgreementText(true);
    },

    handleHide: ({ showAgreementText }) => (e) => {
      e.preventDefault();
      showAgreementText(false);
    },
  }),
);
