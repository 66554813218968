import {
  KITS_REQUEST,
  KITS_SUCCESS,
  KITS_SUCCESS_DELETED,
  KITS_FAILURE,
  KITS_STATE_CLEAR,
} from 'app/actions/orgs/kits';

import { ITEMSEDIT_SUCCESS } from 'app/actions/orgs/kits/itemsEdit';
import { KITS_NEED_UPDATE } from 'app/actions/orgs/kits/options';

const defaultState = {
  count: 0,
  loading: true,
  needUpdate: false,
};

const options = (state = defaultState, action) => {
  if (action.type === KITS_REQUEST) {
    return ({ ...state, loading: true });
  }
  if (action.type === KITS_SUCCESS || action.type === KITS_SUCCESS_DELETED) {
    return ({ ...state, count: action.payload.count, loading: false });
  }
  if (action.type === KITS_FAILURE) {
    return ({ ...state, loading: false });
  }
  if (action.type === KITS_STATE_CLEAR) {
    return defaultState;
  }
  if (action.type === ITEMSEDIT_SUCCESS) {
    return ({ ...state, needUpdate: true });
  }
  if (action.type === KITS_NEED_UPDATE) {
    return ({ ...state, needUpdate: action.payload });
  }
  return state;
};

export default options;
