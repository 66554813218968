
import styles from './customer-view.scss'
import { Customer, isCustomerType } from './types'


const CustomerView = ({ fullName, description, phone, accountNumber, type }: Customer) => {
  const unknownCustomer = !type || !isCustomerType(type)

  return (
    <div>
      <div className={styles.fullName}>
        {fullName || 'Пользователь без имени'}
      </div>

      {description
        && <div className={styles.description}>
          {description}
        </div>}

      {(phone || accountNumber)
        && <div className={styles.secondBlock}>
          {phone && <div>{phone}</div>}
          {accountNumber && <div>{`ЛС: ${accountNumber}`}</div>}
        </div>}

      {unknownCustomer
        && <div className={styles.unknown}>
          {'Неизвестный тип пользователя'}
        </div>}
    </div>
  )
}

export default CustomerView
