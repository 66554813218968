// @flow
import { useCallback, useState } from 'react';

import { api } from 'app/api';
import { POST_COPY_PLANORGAM_SLOTS, convertParams } from 'app/common/api/requests/data/vending/machines/slots/copy/post';
import type { ParamsPOST } from 'app/dataTypes/vending/PlanogramCopy.types';


export type CopyParams = {
  fromMachineId: ?string,
  toMachineId: ?string,
}

export default function useCopySlots(
  { fromMachineId, toMachineId }: CopyParams,
  onSuccess?: (ParamsPOST) => void,
): [boolean, () => Promise<boolean>] {
  const [pending, setPending] = useState(false);

  const submit = useCallback(async () => {
    if (!fromMachineId || !toMachineId) return false;
    setPending(true);
    const res = await api.request(POST_COPY_PLANORGAM_SLOTS, {
      error: 'Не удалось скопировать планограмму',
      params: {
        fromMachineId,
        toMachineId,
      },
      convertions: {
        convertParams,
      },
    });

    setPending(false);
    if (res && onSuccess) {
      onSuccess({ fromMachineId, toMachineId });
    }
    return !!res;
  }, [fromMachineId, toMachineId, onSuccess]);

  return [pending, submit];
}
