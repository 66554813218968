import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import prop from 'ramda/es/prop';
import assoc from 'ramda/es/assoc';
import dissoc from 'ramda/es/dissoc';
import values from 'ramda/es/values';
import length from 'ramda/es/length';
import keys from 'ramda/es/keys';
import isEmpty from 'ramda/es/isEmpty';
import pipe from 'ramda/es/pipe';
import identity from 'ramda/es/identity';

import withFilteredItemsBySearch from 'app/common/containers/withFilteredItemsBySearch';


export const idPrefix = 'fo_';

const itemsToArraySelector = createSelector(
  [
    ({ items }) => items,
    ({ sortFunc }) => sortFunc,
  ],
  (items, sortFunc) => pipe(
    values,
    sortFunc || identity,
  )(items),
);

const itemsToArray = mapProps((props) => {
  const { itemsToArrayFunc } = props;
  const arrayItems = itemsToArrayFunc ? itemsToArrayFunc(props) : itemsToArraySelector(props);
  return assoc('items', arrayItems, props);
});

const isAllSelector = createSelector(
  ({ items }) => items,
  ({ selectedItemsLocal }) => selectedItemsLocal,
  (items, selectedItemsLocal) => (length(keys(items)) === length(keys(selectedItemsLocal))),
);
const withIsAllSelected = mapProps(props => assoc('isAllSelected', isAllSelector(props), props));


/**
* Props:
*   header: string,
*   enableSelectAll: boolean,
*   nothingIsAll: boolean,
*   items: Array<Object>,
*/

const defaultState = {};

export default compose(
  withState('selectedItemsLocal', 'setSelectedItemsLocal', defaultState),

  withIsAllSelected,

  withHandlers({
    handleCheck: ({ setSelectedItemsLocal, items, singleSelectable }) => (e) => {
      const { value: valuePrefixed, checked } = e.currentTarget;
      const value = valuePrefixed.replace(idPrefix, '');
      if (checked) {
        const assign = assoc(value, prop(value, items));
        setSelectedItemsLocal(singleSelectable ? assign({}) : assign);
      } else if (!singleSelectable) {
        setSelectedItemsLocal(
          dissoc(value),
        );
      }
    },
    handleSelectAll: ({ setSelectedItemsLocal, items }) => (e) => {
      const { checked } = e.currentTarget;
      if (checked) {
        setSelectedItemsLocal({ ...items });
      } else {
        setSelectedItemsLocal({});
      }
    },
    handleSubmit: ({
      selectedItemsLocal,
      onSubmit,
      toggleModal,
      items,
      nothingIsAll,
      isAllSelected,
    }) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { name } = e.target;
      if (name !== 'filterOptionsModal') return;
      if (onSubmit) {
        onSubmit(isEmpty(selectedItemsLocal) && nothingIsAll
          ? { ...items }
          : { ...selectedItemsLocal }, isAllSelected);
      }
      toggleModal();
    },
  }),

  // items: Object
  withFilteredItemsBySearch,
  // items: Object (filtered by search)
  // search
  // handleSearch
  // handleClearSearch

  lifecycle({
    componentDidMount() {
      const { setSelectedItemsLocal, selectedItems } = this.props;
      setSelectedItemsLocal({ ...selectedItems });
    },
    componentDidUpdate(prevProps) {
      const { setSelectedItemsLocal, selectedItems, isOpen, handleClearSearch } = this.props;
      if (selectedItems !== prevProps.selectedItems) {
        setSelectedItemsLocal({ ...selectedItems });
      }
      if (isOpen !== prevProps.isOpen && !isOpen) {
        setSelectedItemsLocal({ ...selectedItems });
        handleClearSearch();
      }
    },
  }),

  // преобразуем объект items в массив
  itemsToArray,
);
