import prop from 'ramda/es/prop';
import path from 'ramda/es/path';

import { removeToken } from 'app/common/lib/token';
import shopUserToken from 'app/common/lib/shopUserToken';
import api from 'app/actions/api';


export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'USER_FAIL';

export const USER_LOGOUT = 'USER_LOGOUT';

export function logout() {
  removeToken();
  localStorage.lockedUntil = Date.now();
  return {
    type: USER_LOGOUT,
  };
}

function requestUser() {
  return {
    type: USER_REQUEST,
  };
}

function receiveUser(payload) {
  return {
    type: USER_SUCCESS,
    payload,
  };
}

function failedUser(err) {
  return {
    type: USER_FAIL,
    payload: err,
  };
}

export const fetchUser = silent => async (dispatch) => {
  if (!silent) {
    dispatch(requestUser());
  }

  try {
    const res = await api('account', 'get');
    const data = prop('data', res) || {};
    const token = path(['shop', 'user_token'], data) || null;
    shopUserToken.setToken(token);
    dispatch(receiveUser(data));
    return data;
  } catch (error) {
    if (!silent) {
      dispatch(failedUser({ err: 'Ошибка запроса данных аккаунта пользователя', error }));
    }
    return null;
  }
};
