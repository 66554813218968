// @flow
import React from 'react';
import moment from 'moment-timezone';

import IconFullScreen from 'app/common/icons/IconFullScreen';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import useGenerateFileRequests from 'app/components/common/reports/history/useGenerateFileRequests';
import { GenerateFile } from 'app/common/ui-components/progress-indicator';
import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';

import styles from './HeaderBar.scss';


type Props = {
  onFullscreen: (e: SyntheticEvent<HTMLElement>) => void,
  reportId: number,
}

const HeaderBar = ({ onFullscreen, reportId }: Props) => {
  const reportFileName = `accounting_customers_${moment().format('YYYYMMDD_HHmmss')}.xlsx`;
  const generateFileRequests = useGenerateFileRequests(reportId, reportFileName);

  return (
    <div className={styles.root}>
      <GenerateFile
        className={styles.download}
        labelClassName={styles.downloadLabel}
        label="Скачать как"
        generateAndDownloadPrompt="Сгенерировать и скачать в формате «документ Excel»"
        downloadPrompt="Скачать в формате «документ Excel»"
        {...generateFileRequests}
      >
        <PlainIcon
          width={20}
          height="auto"
          type={iconTypes.EXCEL}
        />
      </GenerateFile>

      <HistoryLink
        className={styles.historyButton}
        reportType={reportTypes.REPORT_PURCHASES_BY_FUNDS}
      />

      <PlainButtonAsLink
        id="fullscreen"
        ariaLabel="Открыть в полном окне"
        onClick={onFullscreen}
      >
        <IconFullScreen className={styles.fullscreenIcon} />
      </PlainButtonAsLink>
    </div>
  );
};

export default HeaderBar;
