import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import get from 'app/common/lib/get';
import { withTranslation } from 'react-i18next';

import { setToken } from 'app/common/lib/token';

import api from '../../../api';
import apiTestLogin from '../../apiTestLogin';
import { validateLogin, validatePassword } from '../../../validate';


// type Props = {
//   setLoginInfo: (Object) => void,
//   loginInfo: LoginInfo,
// };

export default compose(
  withTranslation(),

  withState('loading', 'setLoading', false),

  withState(
    'isLoginValid',
    'setLoginValid',
    ({ loginInfo, countryISOCode }) => !!(loginInfo.login
      && validateLogin(countryISOCode, loginInfo.login)),
  ),
  withState('isLogin', 'loginReady', ({ loginInfo }) => !!(loginInfo.login && loginInfo.accepted)),
  // withState('isPassword', 'passwordReady', false),

  withState('isPasswordValid', 'setPasswordValid', false),
  withState('loginError', 'loginErrorSet', ''),
  withState('passwordError', 'passwordErrorSet', ''),

  withState('login', 'changeLogin', ({ loginInfo }) => (loginInfo.login || '')),
  withState('password', 'changePassword', ''),


  withHandlers({
    handleLogin: ({ changeLogin, setLoginValid, countryISOCode }) => (e) => {
      const { value } = e.currentTarget;
      changeLogin(value);
      setLoginValid(validateLogin(countryISOCode, value));
    },

    handlePassword: ({ changePassword, setPasswordValid }) => (e) => {
      changePassword(e.currentTarget.value);
      setPasswordValid(validatePassword(e.currentTarget.value));
    },

    back: ({ loginReady }) => (e) => {
      e.preventDefault();
      loginReady(false);
    },

    submitLogin: ({
      login,
      loginErrorSet,
      loginReady,
      setLoading,
      isLoginValid,
      setLoginInfo,
      exampleFormat,
      emailForceAuth,
      t,
    }) => (e) => {
      e.preventDefault();
      if (!isLoginValid) {
        loginErrorSet(emailForceAuth
          ? t('login:login_email_error_format', 'Логин введен неверно.')
          : t('login:login_error_format', { format: exampleFormat }));
        return;
      }
      apiTestLogin(login, (props) => {
        loginReady(true);
        setLoginInfo(props);
      }, loginErrorSet, () => setLoading(true), () => setLoading(false));
    },

    submitPassword: ({
      login,
      password,
      passwordErrorSet,
      // passwordReady,
      setLoading,
      isPasswordValid,
      loading,
      loginInfo,
      setLoginInfo,
      t,
    }) => (e) => {
      e.preventDefault();
      if (!isPasswordValid || loading) {
        return;
      }
      const data = { login, password };
      if (loginInfo.agreementUrl && loginInfo.accepted) {
        data.terms_of_use_accepted = true;
      }
      setLoading(true);
      api('auth', 'post', { data })
        .then((response) => {
          if (get(response, 'data.result', null) === 'success') {
            // passwordReady(true); // не надо
            setToken(get(response, 'data.token', ''));
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);

          if (error.response) {
            if (error.response.status === 403) {
              setLoginInfo({ authDisabled: true });
            } else if (error.response.status === 404) {
              passwordErrorSet(t('login:unknown_pass'));
            } else if (error.response.status === 400) {
              passwordErrorSet(t('login:nopass'));
            } else {
              passwordErrorSet(t('login:request_error'));
            }
          } else {
            passwordErrorSet(t('login:connect_error'));
          }
          setLoading(false);
        });
    },

  }),
);
