// @flow
import isEmpty from 'ramda/es/isEmpty';

/**
* Очередь вызовов
*/
class Queue {
  queue: Array<Function>;

  isQueue: boolean;

  paused: boolean;

  constructor() {
    this.queue = [];
    this.isQueue = false;
    this.paused = false;
  }

  resume = async () => {
    if (isEmpty(this.queue)) return;
    this.isQueue = true;
    this.paused = false;
    do {
      if (this.paused) return;
      const func = this.queue.shift();
      if (typeof func === 'function') {
        await func(); // eslint-disable-line no-await-in-loop
      }
    } while (!isEmpty(this.queue));
    this.isQueue = false;
  };

  // очередь из массива
  begin = async (params: Array<Function>) => {
    this.stop();
    this.queue = [...params];
    await this.resume();
  };

  // каждый следующий вызов добавляет элемент в очередь
  open = async (cb: Function, noDuplications: boolean = false) => {
    if (typeof cb === 'function') {
      if (!(noDuplications && this.queue.includes(cb))) {
        this.queue.push(cb);
      }
      if (this.isQueue) return;
    }
    await this.resume();
  };

  // остановка очереди
  stop = () => {
    this.queue = [];
    this.isQueue = false;
    this.paused = false;
  };

  // пауза очереди
  pause = () => {
    this.paused = true;
  };
}

export default Queue;
