// @flow
import React, { type Node, useState, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import PlainMenu, { PlainMenuItem } from 'app/common/ui/PlainMenu';

import styles from './menu-item-dropdown.scss';


/**
 *  Выпадающее меню в главном баре приложения
 */

const cx = classNames.bind(styles);

type Props = {
  id: string,
  children: Node,
  title: string,
  active?: boolean,
}

const MenuItemDropdown = ({ children, title, active, id }: Props) => {
  // const [buttonRef, handleClick] = useBlurOnMouseEvent(toggleDropdown);
  const buttonRef = useRef<HTMLElement | null>(null);


  const [dropped, setDropped] = useState<boolean>(false);
  const toggleDropdown = useCallback(() => {
    setDropped((state) => {
      const newState = !state;
      if (buttonRef.current) {
        buttonRef.current.setAttribute('aria-expanded', String(newState));
      }
      return newState;
    });
  }, []);

  const handleClick = useCallback((e: SyntheticMouseEvent<any>) => {
    toggleDropdown();
    // antifocus
    const { clientX, clientY } = e;
    if (buttonRef.current && clientX !== 0 && clientY !== 0) {
      buttonRef.current.blur();
    }
  }, [toggleDropdown]);

  const handleClickOutside = useCallback((e: SyntheticMouseEvent<*>) => {
    // $FlowFixMe
    if (!e.target.closest(`#${id}`) && buttonRef.current) {
      buttonRef.current.setAttribute('aria-expanded', 'false');
      setDropped(false);
    }
  }, [id]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <li className={styles.root}>
      <button
        type="button"
        id={id}
        ref={buttonRef}
        className={cx(styles.button, { active: active || dropped })}
        onClick={handleClick}
        aria-haspopup="listbox"
      >
        {title}
        <span className={cx(styles.arrow, { active: active || dropped })} />
      </button>
      {dropped && <PlainMenu className={styles.menu}>
        {children}
      </PlainMenu>}
    </li>
  );
};

export const MenuDropdownItem = PlainMenuItem;

export default MenuItemDropdown;
