// @flow
import { useState, useCallback } from 'react';
import has from 'ramda/es/has';
import omit from 'ramda/es/omit';
import keys from 'ramda/es/keys';
import map from 'ramda/es/map';
import filter from 'ramda/es/filter';
import prop from 'ramda/es/prop';


type ItemsObject = { [id: string]: string };

export default function useEditList(
  items: Object,
  existingItems: Object,
  onSubmit: (Array<number>, Array<number>) => void,
): [
  {
    addList: ItemsObject,
    removeList: ItemsObject,
    submitting: boolean,
  },
  {
    addToSelection: (id: number) => void,
    removeFromSelection: (id: number) => void,
    addToSelectionAll: () => void,
    removeFromSelectionAll: () => void,
    handleSubmit: (e?: SyntheticEvent<HTMLElement>) => Promise<void>,
  },
] {
  const [addList, setAddList] = useState<ItemsObject>({});
  const [removeList, setRemoveList] = useState<ItemsObject>({});
  const [submitting, setSubmitting] = useState(false);

  const addToSelection = useCallback(
    (id: number) => {
      if (has(id)(removeList)) {
        // если есть в удаленных, то просто убираем оттуда
        setRemoveList(rl => omit([id], rl));
      } else {
        // если нет то добавляем в добавленные
        setAddList(al => ({ ...al, [id]: items[id].title }));
      }
    },
    [items, removeList],
  );

  const removeFromSelection = useCallback(
    (id: number) => {
      if (has(id)(addList)) {
        // если уже есть в добавленных, просто убираем оттуда
        setAddList(al => omit([id], al));
      } else {
        // если - нет, то добавляем в удаленные
        setRemoveList(rl => ({ ...rl, [id]: items[id].title }));
      }
    },
    [addList, items, setAddList, setRemoveList],
  );

  const addToSelectionAll = useCallback(() => {
    const itemKeys = keys(items);
    // очистка удаляемых (так как добваляем все, удаляемых не будет (на текущей странице))
    setRemoveList(omit(itemKeys));
    // нужны итемы, которые еще не добавлены
    const itemsActual = filter(({ id }) => !prop(id, existingItems), items);
    // добавление недобавленных
    setAddList(existed => ({
      ...existed,
      ...map(({ title }) => title, itemsActual),
    }));
  }, [items, setAddList, setRemoveList, existingItems]);

  const removeFromSelectionAll = useCallback(() => {
    const itemKeys = keys(items);
    // очистка добавляемых (так как удаляем все, добавляемых не будет (на текущей странице))
    setAddList(omit(itemKeys));
    // нужны итемы, которые уже добавлены
    const itemsActual = existingItems;
    // удаление добавленых
    setRemoveList(existed => ({
      ...existed,
      ...map(({ title }) => title, itemsActual),
    }));
  }, [items, setAddList, setRemoveList, existingItems]);

  const handleSubmit = useCallback(
    async (e?: SyntheticEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      setSubmitting(true);
      await onSubmit(
        map(a => parseInt(a, 10), keys(addList)),
        map(a => parseInt(a, 10), keys(removeList)),
      );
      setSubmitting(false);
    },
    [addList, removeList, onSubmit, setSubmitting],
  );

  return [
    {
      addList,
      removeList,
      submitting,
    },
    {
      addToSelection,
      removeFromSelection,
      addToSelectionAll,
      removeFromSelectionAll,
      handleSubmit,
    },
  ];
}
