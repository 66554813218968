import React from 'react';
import Button from 'app/common/ui/Button';
import classNames from 'classnames';

import styles from './ImageClickable.scss';


type Props = {
  src: string,
  locked?: boolean,
  className: string,
  onReplace: (e: SyntheticEvent<HTMLElement>) => void,
  onDelete: (e: SyntheticEvent<HTMLElement>) => void,
}

const ImageClickable = ({ src, locked, className, onReplace, onDelete, ...others }: Props) => (
  <div className={classNames(styles.root, className)} {...others}>
    <img src={src} className={styles.img} alt="" />
    {!locked
      && <div className={styles.overlay}>
        <div className={styles.center}>
          <Button value="Изменить" className={styles.button} onClick={onReplace} />
        </div>
        <div className={styles.center}>
          <Button value="Удалить" className={styles.button} onClick={onDelete} error />
        </div>
      </div>}
  </div>
);

export default ImageClickable;
