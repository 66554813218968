import moment from 'moment'

import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { ItemType } from 'app/common/ui-next/plain-selector'


export type Year = Array<ItemType>

class YearFilterDataBox extends DataBox<Year> { }

const getYearsToCurrent = (): number[] => {
  const startYear = 2000
  const currentYear = moment().year()
  const years: number[] = []

  for (let year = startYear; year <= currentYear; year += 1) {
    years.push(year)
  }

  return years
}

const getCurrentOrPreviousYear = () => {
  const now = moment()
  if (now.month() === 0) {
    return now.subtract(1, 'years').year()
  }
  return now.year()
}

export const defaultYear: Year = []
export const yearFilter = createDataBox('yearFilter', defaultYear, YearFilterDataBox)

export const defaultYearData = (): Array<ItemType> => {
  const years = getYearsToCurrent()
  return years.map(year => ({ id: year.toString(), title: year.toString() }))
}

export const getDefaultYearIndex = () => {
  const curYear = getCurrentOrPreviousYear().toString()
  const curId = defaultYearData().find((filteredItem) => {
    if (filteredItem.title === curYear) {
      return true
    }
    return false
  })
  return [curId?.title ?? '2000']
}

// export const defaultYearDataReceiverAbort = getOrgsApi.abort

// selection

export const defaultYearSelection: Array<string> = getDefaultYearIndex()
export const yearFilterSelection = createDataBoxDefault<Array<string>>('yearFilterSelection', defaultYearSelection)
