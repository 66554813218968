// @flow
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';


const accountItems = () => ([[{
  id: 'customer_account_number',
  title: 'л/с покупателя',
}, {
  id: 'customer_phone',
  title: 'телефон покупателя',
}], defaultStateDataCurrent]);

export default accountItems;
