// @flow
import isEmpty from 'ramda/es/isEmpty';
import values from 'ramda/es/values';
import path from 'ramda/es/path';

import { defaultStateDataCurrent, type State } from 'app/common/ui-components/filter';
import { api } from 'app/api';
import { GET_DISTRICTS } from 'app/common/api/requests/food/districts';

/**
 *  Запрос данных фильтра по районам
 */

const filterGetItemsDistricts = async (state: State) => {
  const currentCityId: string | void = path(['city', 'current', 0], state);
  const cityId: number | null = currentCityId ? parseInt(currentCityId, 10) : null;

  if (cityId) {
    const res = await api.request(GET_DISTRICTS, {
      error: 'Не удалось получить данные районов',
      params: {
        city_id: cityId,
      },
    });

    if (res) {
      if (isEmpty(res)) {
        return ([[{ id: '0', title: 'Все' }], ['0']]);
      }
      const districtsList = values(res);
      const selectedDistrictId = path([0, 'id'], districtsList);
      return ([districtsList, [selectedDistrictId]]);
    }
  }

  return ([[], defaultStateDataCurrent]);
};

export default filterGetItemsDistricts;
