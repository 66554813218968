// @flow
import { createContext } from 'react';

import type { Buildings, Building } from './BuildingsTypes';


type BuildingsContext = {
  buildings: Buildings,
  loading: boolean,
  selected: ?Building,
  toggleSelector: () => void,
}

const defaultContext: BuildingsContext = {
  buildings: {},
  loading: true,
  selected: null,
  toggleSelector: () => {},
};

export default createContext<BuildingsContext>(defaultContext);
