import { useEffect, useCallback } from 'react'


type Options = {
  id: string
  onCollapse: () => void
}

export default function useEvents({ id, onCollapse }: Options) {
  // Закрытие при клике вне
  const handleClickOutside = useCallback((e: MouseEvent) => {
    const eventTargetElement = e.target as Element
    if (
      eventTargetElement
      && !eventTargetElement.closest(`#${id}`) // клик на самом элементе не закрывает dropdown
      && !eventTargetElement.closest('.doNotCloseDropdown') // клик на любой элемент с этим классом не закрывает dropdown
    ) {
      onCollapse()
    }
  }, [id, onCollapse])

  // События
  useEffect(() => {
    window.addEventListener('click', handleClickOutside, false)
    window.addEventListener('scroll', onCollapse, false)
    return () => {
      window.removeEventListener('click', handleClickOutside)
      window.removeEventListener('scroll', onCollapse)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
