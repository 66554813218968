// @flow
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';

import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const IDENTIFIERS_AVAILABILITY_REQUEST = 'employee/IDENTIFIERS_AVAILABILITY_REQUEST';
export const IDENTIFIERS_AVAILABILITY_SUCCESS = 'employee/IDENTIFIERS_AVAILABILITY_SUCCESS';
export const IDENTIFIERS_AVAILABILITY_FAILURE = 'employee/IDENTIFIERS_AVAILABILITY_FAILURE';

export const availabilityFetch = (id: number): Object => async (dispatch) => {
  dispatch(action(IDENTIFIERS_AVAILABILITY_REQUEST, { id }));
  try {
    const res = await api(`food/employee/${id}/card/new`, 'get');
    const availability = pipe(
      prop('data'),
      omit(['result']),
    )(res);
    dispatch(action(IDENTIFIERS_AVAILABILITY_SUCCESS, availability));
    return availability;
  } catch (error) {
    dispatch(action(IDENTIFIERS_AVAILABILITY_FAILURE, {
      err: 'Не удалось получить данные о возможных идентификаторах',
      error,
      id,
    }));
    return null;
  }
};
