// @flow
import { propEq } from 'ramda';

import {
  defaultStateDataCurrent,
  type StateDataItem,
  type StateDataCurrent,
} from 'app/common/ui-components/filter';

import { saleTypes } from 'app/dataTypes/reports/saleTypes';

import sellTypeItemsDefault from './sellTypeItemsDefault';


const sellTypeItemsData = [sellTypeItemsDefault(), defaultStateDataCurrent];

const sellTypeItems = (): [Array<StateDataItem>, StateDataCurrent] => {
  return sellTypeItemsData;
};

export const onlyComplex = (): [Array<StateDataItem>, StateDataCurrent] => ([
  sellTypeItemsDefault().filter(propEq('id', saleTypes.Complex)),
  [saleTypes.Complex],
]);

export default sellTypeItems;
