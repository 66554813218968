
import { ReactNode } from 'react'
import styles from './report-filter-layout.scss'


type Props = {
  column1: ReactNode
  column2: ReactNode
}

const ReportFilterLayout = ({ column1, column2 }: Props) => {
  return (
    <div className={styles.columns}>
      <div>{column1}</div>
      <div>{column2}</div>
    </div>
  )
}

export default ReportFilterLayout
