// @flow
import slice from 'ramda/es/slice';


const skipExtraDays = (days: Array<any>) => {
  if (days[days.length - 1].day > 13) {
    return slice(0, days.length - 14, days);
  }
  if (days[days.length - 1].day > 6) {
    return slice(0, days.length - 7, days);
  }
  return days;
};

export default skipExtraDays;
