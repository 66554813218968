// @flow
import React, { type Node } from 'react';
import path from 'ramda/es/path';

import { RowLayoutWithSticky, RowLayoutContent, Header, Header2 } from 'app/common/layouts/RowLayout';
import { OptionProp, OptionProps } from 'app/common/ui-next/option-props';
import { useVendingMachineSingle, useRelatedOrgs } from 'app/dataProviders/VendingDataProvider';
import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';

import EditMachineButton from './editMachine/EditMachineButton';
import useModelById from '../useModelById';
import useVersionById from '../useVersionById';
import styles from './GeneralSection.scss';


type Props = {
  header: string,
  loading: boolean,
  onDeleteSuccess: () => void,
}

const GeneralSection = ({ header, loading, onDeleteSuccess }: Props): Node => {
  const [{ title, number, orgId, modelId, versionId }] = useVendingMachineSingle();
  const relatedOrgs = useRelatedOrgs();
  const model = useModelById(modelId);
  const version = useVersionById(versionId);

  const relatedOrgTitle = path([orgId, 'title'], relatedOrgs);

  return (
    <RowLayoutWithSticky
      number={3}
      content={
        <RowLayoutContent borderBottom className={styles.topbar}>
          <div className={styles.topbarHeader}>
            <Header>
              {header}
            </Header>
            <Header2>
              {`Модель аппарата: ${model || '...'} ${version || ''}`}
            </Header2>
          </div>
          <EditMachineButton loading={loading} onDeleteSuccess={onDeleteSuccess} />
        </RowLayoutContent>
      }
    >
      <RowLayoutContent className={styles.props}>
        <OptionProps>
          <OptionProp title="Наименование аппарата" loading={loading}>
            {title}
          </OptionProp>
        </OptionProps>
        <OptionProps>
          <OptionProp title="Номер аппарата" loading={loading}>
            {number}
          </OptionProp>
        </OptionProps>
        <OptionProps>
          <OptionProp title="Ошибки" loading={loading}>
            <PlainIcon type={iconTypes.OK} />
          </OptionProp>
        </OptionProps>
        <OptionProps>
          <OptionProp title="Количество товара" loading={loading}>
            {'100%'}
          </OptionProp>
        </OptionProps>
        <OptionProps>
          <OptionProp title="Последняя загрузка" loading={loading}>
            {'—'}
          </OptionProp>
        </OptionProps>
        <OptionProps>
          <OptionProp title="Последняя покупка" loading={loading}>
            {'—'}
          </OptionProp>
        </OptionProps>
        <OptionProps>
          <OptionProp title="Место размещения" loading={loading || !relatedOrgTitle}>
            {relatedOrgTitle}
          </OptionProp>
        </OptionProps>
      </RowLayoutContent>
    </RowLayoutWithSticky>
  );
};

export default GeneralSection;
