// @flow
import React from 'react';

import IconLightCross from 'app/common/icons/IconLightCross';
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import ValueSaveOptionsDialog from '../ValueSaveOptionsDialog';
import EditablePropWLocalWriteAccess from '../EditablePropWLocalWriteAccess';
import useDishDelete from './useDishDelete';


type Props = {
  id: number,
  orgId: number,
  date: string,
  sellPointId: number,
  title: string,
  portion: string,
  cost: string,
  className?: string,
  writeAccess: boolean,
}

const MenuItemDish = ({
  id,
  orgId,
  date,
  sellPointId,
  title,
  portion,
  cost,
  className,
  writeAccess,
}: Props) => {
  const deleteDish = useDishDelete({ dishId: id, orgId, date, sellPointId });

  return (
    <div className={className}>
      <div>{title}</div>

      <EditablePropWLocalWriteAccess
        orgId={orgId}
        right
        value={portion}
        name="portion"
        externalWriteAcces={writeAccess}
      >
        {(showDialog, value, closeOptionsDialog) => (<ValueSaveOptionsDialog
          isOpen={showDialog}
          value={value}
          onClose={closeOptionsDialog}
          orgId={orgId}
          date={date}
          id={id}
          property="portion"
        />)}
      </EditablePropWLocalWriteAccess>

      <EditablePropWLocalWriteAccess
        orgId={orgId}
        right
        value={cost}
        name="cost"
        externalWriteAcces={writeAccess}
      >
        {(showDialog, value, closeOptionsDialog) => (<ValueSaveOptionsDialog
          isOpen={showDialog}
          value={value}
          onClose={closeOptionsDialog}
          orgId={orgId}
          date={date}
          id={id}
          property="cost"
        />)}
      </EditablePropWLocalWriteAccess>

      <div>
        {writeAccess
          && <ConfirmationDialog
            onConfirm={deleteDish}
            text={`Вы уверены, что хотите удалить «${title}» из меню?`}
            confirmButtonText="Удалить"
            confirmButtonProps={{ error: true }}
          >
            {handleConfirm => (<PlainButtonAsLink onClick={handleConfirm}>
              <span className="visuallyHidden">{'Удалить'}</span>
              <IconLightCross />
            </PlainButtonAsLink>)}
          </ConfirmationDialog>}
      </div>
    </div>
  );
};

export default MenuItemDish;
