// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import styles from './Content.scss';


const cx = classNames.bind(styles);

export type RowLayoutContentProps = {
  className?: string,
  paddingfree?: boolean,
  borderTop?: boolean,
  borderBottom?: boolean,
  flex?: boolean,
  children: Node
}

const RowLayoutContent = ({
  className,
  paddingfree,
  borderTop,
  borderBottom,
  flex,
  children,
}: RowLayoutContentProps) => (
  <div
    className={cx(styles.content, {
      padding: !paddingfree,
      borderTopNone: borderTop === false,
      borderTop,
      borderBottom,
      flex,
    }, className)}
  >
    {children}
  </div>
);

export default RowLayoutContent;
