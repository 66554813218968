// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import passwords from 'app/common/containers/passwords';
import { changePasswordProcess, profileClearKey } from 'app/common/actions/profile/password';
// import { validatePassword, isValid } from 'app/common/lib/validate/password';
// import type { passValidType } from 'app/common/lib/validate/password';

export default compose(
  connect(
    state => ({
      saving: state.profile.saving,
      oldPassError: state.profile.oldPassError,
    }),
  ),


  withState('currentPassword', 'changeCurrentPassword', ''),


  passwords,
  // withState('newPassword1', 'changeNewPassword1', ''),
  // withState('newPassword2', 'changeNewPassword2', ''),


  withHandlers({
    change: ({
      dispatch,
      changeCurrentPassword,
      changeNewPassword1,
      changeNewPassword2,
      oldPassError,
    }) => (e) => {
      const { name, value } = e.target;
      switch (name) {
        case 'password': {
          changeCurrentPassword(value);
          if (oldPassError) {
            dispatch(profileClearKey('oldPassError'));
          }
          break;
        }
        case 'newpassword1': {
          changeNewPassword1(value);
          break;
        }
        case 'newpassword2': {
          changeNewPassword2(value);
          break;
        }
        default:
      }
    },

    save: ({ dispatch, currentPassword, newPassword1, close }) => (e) => {
      e.preventDefault();
      dispatch(changePasswordProcess(currentPassword, newPassword1, () => {
        close();
      }));
    },
  }),


  mapProps(
    ({
      currentPassword,
      newPassword1,
      newPassword2,
      change,
      save,
      saving,
      oldPassError,
      close,
      pass1ValidProps,
      pass1Valid,
      pass2Valid,
    }) => {
      const oldPassValid = currentPassword.length >= 3;
      return {
        currentPassword,
        newPassword1,
        newPassword2,
        change,
        save,
        saving,
        oldPassValid,
        oldPassError,
        close,
        pass1ValidProps,
        pass1Valid,
        pass2Valid,
      };
    },
  ),
);
