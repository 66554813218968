// @flow
import React from 'react';
import classNames from 'classnames/bind';

import PlainButton from '../plain-button/plain-button';
import IconCheck from './icons/icon-check';
import styles from './plain-button-filter-control.scss';


const cx = classNames.bind(styles);

export type PlainButtonFilterControlProps = {
  opened?: boolean,
  checked?: boolean,
  onClick: (e: SyntheticEvent<HTMLElement>) => void,
  disabled?: boolean,
}

const PlainButtonFilterControl = ({
  opened,
  checked,
  onClick,
  disabled,
}: PlainButtonFilterControlProps) => (
  <div className={styles.root}>
    {checked && <div className={cx(styles.check, { checked })}>
      <IconCheck className={styles.checkIcon} />
    </div>}
    <PlainButton
      onClick={onClick}
      className={cx({ checkedButton: checked })}
      disabled={disabled}
    >
      <span>{'Фильтры'}</span>
      <span className={cx({ arrow: !opened, arrowUp: opened })} />
    </PlainButton>
  </div>
);

export default PlainButtonFilterControl;
