// @flow
import React from 'react';

import { Tr, Th } from 'app/common/ui-components/table';
import { useCurrencySign, options } from 'app/common/components-next/currency';

import { CellRestriction, restrictions } from '../MultiLineCell';


const emptyProps = Object.freeze({});

type Props = {
  sortKey?: string,
  sortDirection?: 'up' | 'down',
  onSort?: (direction: "up" | "down", key: string) => void,
  hidden?: boolean,
  sellTypeGroupped?: boolean,
}

const RightColumnHead = ({ sortKey, sortDirection, onSort, hidden, sellTypeGroupped }: Props) => {
  const commaCurrency = useCurrencySign(options.COMMA_SPACE_BEFORE);

  const cellRestriction = (
    sellTypeGroupped
      ? restrictions.WIDE_PADDING_ARROW
      : restrictions.TIGHT_PADDING_ARROW
  );

  const props = hidden ? emptyProps : {
    id: 'column_total',
    sortKey,
    sortDirection,
    onSortArrowClick: onSort,
    alignCenter: true,
  };

  return (
    <thead>
      <Tr
        hidden={hidden}
        header
      >
        <Th
          scope="col"
          sortDummy={hidden}
          {...props}
        >
          <CellRestriction type={cellRestriction}>
            {`Всего${commaCurrency}`}
          </CellRestriction>
        </Th>
      </Tr>
    </thead>
  );
};

export default RightColumnHead;
