import { useCallback, useEffect } from 'react'

import { Loading } from 'app/common/templates-next/page-template'
import { CafeteriaSettings } from 'app/dataTypes/subsidy/settings'

import { CategoryCafeteriaSettingsForm } from './CategoryCafeteriaSettingsForm'
import { useCafeteriaErrors } from './useCafeteriaErrors'
import extractAccruals from './extractAccruals'
import extractAccrualsFor from './extractAccrualsFor'
import extractInclude from './extractInclude'
import extractApplies from './extractApplies'
import extractSchema from './extractSchema'
import { useFormPortal } from './useFormPortal'
import { useCategorySettingsSubmit } from './useCategorySettingsSubmit'


type Props = {
  ID: string
  orgID: string,
  settings: CafeteriaSettings
  loading?: boolean
  onDataUpdate: () => void
  onCreateFormID: (ID: string) => string
  updateSize?: () => void
  onSuccess?: () => void
  onPending?: (pending: boolean) => void
}

export const CategoryCafeteriaSettings = ({
  ID,
  orgID,
  settings,
  loading,
  onDataUpdate,
  onCreateFormID,
  updateSize,
  onSuccess,
  onPending,
}: Props) => {
  const { accrualsShiftsAmount } = settings

  const formID = onCreateFormID(ID)

  const { errors, checkErrors, resetErrors } = useCafeteriaErrors(accrualsShiftsAmount)

  const extractData = useCallback((formData: FormData) => {
    const accruals = extractAccruals(formData, accrualsShiftsAmount)
    const accrualsFor = extractAccrualsFor(formData)
    const include = extractInclude(formData)
    const applies = extractApplies(formData)
    const schema = extractSchema(formData)

    return {
      app: 'cafeteria' as const,
      accrualsShiftsAmount,
      accruals,
      accrualsFor,
      include,
      applies: applies || undefined,
      schema: schema || undefined,
    }
  }, [accrualsShiftsAmount])

  const { submit, submitting } = useCategorySettingsSubmit({
    allowanceID: ID,
    orgID,
    formID,
    onSuccess,
    onPending,
    onCheckErrors: checkErrors,
    onExtractData: extractData,
  })

  useEffect(() => {
    onDataUpdate()
  }, [settings]) // eslint-disable-line react-hooks/exhaustive-deps

  const formPortal = useFormPortal(formID, submit)

  return (
    <div>
      {loading
        ? <Loading />
        : <>
          <CategoryCafeteriaSettingsForm
            ID={ID}
            orgID={orgID}
            formID={formID}
            settings={settings}
            disabled={submitting}
            errors={errors}
            resetErrors={resetErrors}
            updateSize={updateSize}
          />
          {formPortal}
        </>}
    </div>
  )
}
