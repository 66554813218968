import { useState } from 'react'

import { PlainCheckboxGroup } from '../../plain-checkbox'
import { ID } from '../predefined-ids'


const items = [{
  id: ID.details,
  title: 'Детализированный отчет',
}]

const Details = () => {
  const [value, setValue] = useState<Array<string>>([])

  return (
    <PlainCheckboxGroup
      id={ID.details}
      items={items}
      current={value}
      onValue={setValue}
      keepPadding
    />
  )
}

export default Details
