import type { Request } from '../../../index'


type DownloadFileParams = {
  template: 'school_payment_order' | 'camp_payment_order',
}

export const GET_REQUISITES_EXAMPLE: Request<DownloadFileParams, void, void> = [
  'food/requisites/example',
  'get',
  ['data'],
]
