// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as orgType } from '../orgType';


const orgActivity = (state: State) => {
  return (
    orgType.filterActive(state)
    && orgType.orgTypeSelected(state)
  );
};

export default orgActivity;
