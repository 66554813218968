// @flow
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import always from 'ramda/src/always';
import T from 'ramda/src/T';
import toLower from 'ramda/src/toLower';

import { phoneReKG, phoneRe, phoneReKZ, phoneReUA } from 'app/common/constants/regex';
import type { CountryCode, CountryCodeUpper } from './types';


const validatePhoneByISO = (code: CountryCode | CountryCodeUpper, phone: string) => {
  const iso = toLower(code);
  return cond([
    [equals('kg'), always(phoneReKG.test(phone))],
    [equals('kz'), always(phoneReKZ.test(phone))],
    [equals('ua'), always(phoneReUA.test(phone))],
    [T, always(phoneRe.test(phone))],
  ])(iso);
};

export default validatePhoneByISO;
