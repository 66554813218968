// @flow
import React from 'react';

import styles from './DetailsHeader.scss';


type Props = {
  reportTitle?: string,
}

const DetailsHeader = ({ reportTitle }: Props) => (
  <div className={styles.root}>
    {'Детализация отчета'}
    {' '}
    {reportTitle
      && <span>
        {'«'}
        {reportTitle}
        {'»'}
      </span>}
  </div>
);

export default DetailsHeader;
