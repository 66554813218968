// @flow
import React, { type Node, useState, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { FilterDataProvider } from 'app/common/ui-components/filter';
import { Space } from 'app/common/layouts/RowLayout';

import ReportFilter, { filterItems } from './ReportFilter';
import ReportGenerate from './ReportGenerate';
import Report from './Report';


const Balance = (): Node => {
  const email: string | null = useSelector(state => state.user.email, shallowEqual);
  const filterParams = useMemo(() => ({ email }), [email]);

  const [reportId, setReportId] = useState<null | number>(null);

  return (
    <FilterDataProvider filters={filterItems} params={filterParams}>
      <ReportGenerate onGenerate={setReportId}>
        {processing => <ReportFilter loading={processing} />}
      </ReportGenerate>
      <Space />
      <Report reportId={reportId} />
    </FilterDataProvider>
  );
};

export default Balance;
