import { useCallback } from 'react'

import {
  POST_SCHOOL_SUBSIDY_ORDERS_SETTINGS,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/schools/subsidy_orders_settings/post'

import { Settings } from 'app/dataTypes/subsidy/settings'
import { api } from 'app/api'


type Options = {
  orgID: string
  allowanceID: string
}

export default function useSubsidySettingsActions({ orgID, allowanceID }: Options) {
  const submit = useCallback(async (settings: Settings) => {
    const res = await api.request(POST_SCHOOL_SUBSIDY_ORDERS_SETTINGS, {
      error: 'Не удалось сохранить настройки категории льгот',
      params: {
        orgID,
        allowanceID,
        settings,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      return true
    }
    return false
  }, [orgID, allowanceID])


  return { submit }
}
