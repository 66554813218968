// @flow
import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import LineInput from 'app/common/login/ui/LineInput';
import Modal from 'app/common/components/Modal';
import Circle from 'app/common/ui/Circle';
import type { passValidType } from 'app/common/lib/validate/password';

import enhanceChangePassword from './enhanceChangePassword';
import styles from './ChangePassword.scss';


const cx = classNames.bind(styles);

type Props = {
  newPassword1: string,
  newPassword2: string,
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  handleLogout: (e: SyntheticEvent<HTMLElement>) => void,
  handleSubmit: (e: SyntheticEvent<HTMLElement>) => void,
  pass1ValidProps: passValidType,
  pass1Valid: boolean,
  pass2Valid: boolean,
  saving: boolean,
};

const ChangePassword = ({
  newPassword1,
  newPassword2,
  handleChange,
  handleLogout,
  handleSubmit,
  pass1ValidProps: { lowercase, uppercase, numbers, amount },
  pass1Valid,
  pass2Valid,
  saving,
}: Props) => {
  const { t } = useTranslation('profile');

  return (
    <Modal className={styles.root} modalClassName={styles.modal} overlayClassName={styles.overlay}>
      <a
        href="logout"
        className={styles.back}
        onClick={handleLogout}
        title={t('return_to_login', 'Вернуться к вводу логина')}
      >
        {'←'}
      </a>

      <h1 className={styles.header}>
        {t('password_modal_header', 'Смена пароля')}
      </h1>
      <p className={styles.info}>
        {t('temp_password_warning_line1', 'Вы вошли используя временный пароль.')}
        <br />
        {t('temp_password_warning_line2', 'Для продолжения работы необходимо указать новый.')}
        <br />
      </p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.label}>
          {`${t('password_modal_must', 'Пароль должен содержать')}:`}
        </div>
        <div className={styles.strings}>
          <div className={cx({ valid: lowercase })}>
            <Circle success={lowercase} error={!lowercase} />
            &nbsp;
            {t('password_modal_must_eng_smalls', 'Английские маленькие буквы')}
          </div>
          <div className={cx({ valid: uppercase })}>
            <Circle success={uppercase} error={!uppercase} />
            &nbsp;
            {t('password_modal_must_engs_bigs', 'Английские большие буквы')}
          </div>
          <div className={cx({ valid: numbers })}>
            <Circle success={numbers} error={!numbers} />
            &nbsp;
            {t('password_modal_must_number', 'Минимум одну цифру')}
          </div>
          <div className={cx({ valid: amount })}>
            <Circle success={amount} error={!amount} />
            &nbsp;
            {t('password_modal_must_more_then_8', 'Более 8 символов')}
          </div>
        </div>
        <div className={styles.input}>
          <LineInput
            name="newpassword1"
            placeholder={t('password_modal_enter_new', 'Введите новый пароль')}
            value={newPassword1}
            onChange={handleChange}
            type="password"
            isValid={pass1Valid}
          />
        </div>
        <div className={styles.input}>
          <LineInput
            name="newpassword2"
            placeholder={t('password_modal_repeat_new', 'Повторите новый пароль')}
            value={newPassword2}
            onChange={handleChange}
            type="password"
            isValid={pass2Valid}
          />
        </div>
        {newPassword1 && !pass1Valid && <div className={styles.errorLine}>
          {t('password_modal_error_not_safe', 'Не выполнены условия безопасного пароля!')}
        </div>}
        {newPassword2 && !pass2Valid && <div className={styles.errorLine}>
          {t('password_modal_error_not_equals', 'Пароли не совпадают!')}
        </div>}
        <ControlsBar middle>
          <PlainButton
            onClick={handleLogout}
            className={styles.close}
            outline
          >
            {t('logout', 'Выйти')}
          </PlainButton>
          <PlainButton
            type="submit"
            disabled={!(pass1Valid && pass2Valid && !saving)}
          >
            {saving
              ? t('bt_password_submitting', 'Обновление...')
              : t('bt_password_submit', 'Обновить')}
          </PlainButton>
        </ControlsBar>
      </form>
    </Modal>
  );
};

export default enhanceChangePassword(ChangePassword);
