// @flow
import React, { Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'ramda/es/isEmpty';
import path from 'ramda/es/path';

import Loading from 'app/common/components/Loader';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { Table } from 'app/common/ui-components/table';
import type { Settings } from 'app/reducers/settings/dishes';

import { useDishesData } from '../DishesData';
import { dataKey } from '../dataKey';
import DishesTableRowItem from './DishesTableRow';
import DishesTableTHead from './DishesTableTHead';


/**
 *  Таблица с блюдами
 *   + заголовок таблицы с прилипанием и тенью
 *   + индикация загрузки блюд
 *   + информация об отсутствии блюд
 *   + вкл/откл. столбцов от настроек в редаксе
 */

type Props = {
  locked?: boolean,
}

const DishesTable = ({ locked }: Props) => {
  const [{ dishes, loading, nomenclatureActive }] = useDishesData();
  const tableSettings: Settings = useSelector(path(['settings', dataKey]), shallowEqual);
  const nothing = isEmpty(dishes);

  return (
    <>
      {loading
        && nothing
        && <Loading />}

      {!loading
        && nothing
        && <EmptyWarn>{'Нет записей, удовлетворяющих условиям'}</EmptyWarn>}

      {!nothing
        && <Table wide>
          <DishesTableTHead
            settings={tableSettings}
            nomenclatureActive={nomenclatureActive}
          />
          <tbody>
            {dishes.map(({ id, ...props }) => (
              <DishesTableRowItem
                key={id}
                id={id}
                settings={tableSettings}
                nomenclatureActive={nomenclatureActive}
                loading={loading}
                locked={locked}
                {...props}
              />
            ))}
          </tbody>
        </Table>}
    </>
  );
};

export default DishesTable;
