// @flow
import type { State } from 'app/common/ui-components/filter';
import { flags as org } from 'app/components/common/reports/filters/org';

import { flags as sellTypeOnlyComplex } from './sellTypeOnlyComplex';


const customerTypeActivity = (state: State) => {
  return (
    org.filterActive(state)
    && org.singleOrgSelected(state)
    && sellTypeOnlyComplex.filterActive(state)
  );
};

export default customerTypeActivity;
