// @flow
import { useState, useCallback } from 'react';


export type Item = {
  id: string,
  title: string,
}

const updateMap = (item: Item, replace?: boolean) => (current) => {
  return new Map(replace ? [] : current).set(item.id, item);
};

const removeFromMap = (item: Item) => (current) => {
  const clone = new Map(current);
  clone.delete(item.id);
  return clone;
};

export default function useAddRemoveState(singleSelectable?: boolean): {
  willAddItems: Map<string, Item>,
  willRemoveItems: Map<string, Item>,
  setItemAsAdded: (item: Item, replace?: boolean) => void,
  setItemAsRemoved: (item: Item) => void,
  restoreAsAdded: (item: Item) => void,
  restoreAsNotAdded: (item: Item) => void,
  } {
  const [willAddItems, setAddItems] = useState<Map<string, Item>>(new Map());
  const [willRemoveItems, setRemoveItems] = useState<Map<string, Item>>(new Map());

  const setItemAsAdded = useCallback((item: Item) => {
    setAddItems(updateMap(item, singleSelectable));
  }, [singleSelectable]);

  const setItemAsRemoved = useCallback((item: Item) => {
    setRemoveItems(updateMap(item));
  }, []);

  const restoreAsAdded = useCallback((item: Item) => {
    setRemoveItems(removeFromMap(item));
  }, []);

  const restoreAsNotAdded = useCallback((item: Item) => {
    setAddItems(removeFromMap(item));
  }, []);

  return {
    willAddItems,
    willRemoveItems,
    setItemAsAdded,
    setItemAsRemoved,
    restoreAsAdded,
    restoreAsNotAdded,
  };
}
