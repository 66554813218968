// @flow
import React, { useCallback, useState } from 'react';
import replace from 'ramda/es/replace';
import isEmpty from 'ramda/es/isEmpty';
import classNames from 'classnames';

import Category, { defaultLang } from 'app/components/common/Category';
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog';
import Tooltip from 'app/common/ui/Tooltip';
import AddItemModal from 'app/components/common/AddItemModal';
import Loader from 'app/common/components/Loader';
import { PlainFilterComplete, badgesHideCrossProps } from 'app/common/ui/PlainFilter';
import BuildingsData, { BuildingsView, type Building } from 'app/components/common/BuildingsData';
import RowLayout, { Header, HeaderContent, RowLayoutContent } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainLinkAsButton from 'app/common/ui-next/plain-link-as-button';
import PlainButton from 'app/common/ui-next/plain-button';
import { useWriteAccess } from 'app/rights/RightsForOrg';
import PlainMessageModal from 'app/common/ui-next/plain-message-modal';
import { useLastChange } from 'app/dataProviders/OrgSettingsHistoryProvider';

import renderAddSellPointError from './error';
import lang from './SellPoints.lang';
import useSellPointsData, { type SellPoints, type SellPointItem } from '../../commonHooks/useSellPointsData';
import useSellPointsSelection from './useSellPointsSelection';
import useSellPointsOperate from './useSellPointsOperate';
import useSellPointForOrder from './useSellPointForOrder';
import { rightsPath } from '../rightsPath';
import HistoryLink from '../HistoryLink';
import sections, { sectionTitles } from '../sections';

import styles from './SellPoints.scss';


const currentSection = sections.ORGS_SETTINGS_SELLPOINTS;
const header = sectionTitles[currentSection];

const itemLang = {
  ...defaultLang,
  confirmRestoreText: 'Вы уверены, что хотите восстановить точку продаж?',
  confirmDeleteText: 'Вы уверены, что хотите перемесить точку продаж в удаленные?',
  tooltipRestoreText: 'Восстановить точку продаж',
  tooltipDeleteText: 'Удалить точку продаж',
  cantDeleteSellPoint: 'Нельзя удалить все точки продаж, должна остаться хотя бы одна',
  cantDeleteSellPointButton: 'Принять',
};

type Props = {
  basePath: string,
  option?: string,
  orgId: number,
}

const SellPointsComponent = ({
  basePath,
  option,
  orgId,
}: Props) => {
  const [, updateLastChange] = useLastChange();

  const [selectedBuilding, setSelectedBuilding] = useState<Building | null>(null);

  const [
    [{ itemsCurrent, itemsDeleted, sellPoints }, sellPointsLoading],
    [setSellPoints],
  ] = useSellPointsData(orgId, selectedBuilding && selectedBuilding.id);

  const {
    selection,
    selectionType,
    selectionReset,
    selectedAll,
    handleToggleItem,
    selectionPhrase,
  } = useSellPointsSelection(itemsCurrent.length);

  const disableRestoreButton = isEmpty(selection) || selectionType === 'active';
  const disableDeleteButton = isEmpty(selection) || selectionType === 'deleted';

  const handleResultUpdate = useCallback((result: SellPoints) => {
    setSellPoints(currentSellPoints => ({ ...currentSellPoints, ...result }));
    selectionReset();
  }, [setSellPoints, selectionReset]);

  const {
    processing,
    handleSellPointUpdateRequest,
    handleAddSellPoint,
    handleDeleteSellPoint,
    handleRestoreSellPoint,
    handleDeleteSellPoints,
    handleRestoreSellPoints,
    handleReplaceSellPointName,
    addingError,
    resetAddingError,
    previosSellPointTtile,
  } = useSellPointsOperate(
    orgId,
    selectedBuilding && selectedBuilding.id,
    selection,
    selectionPhrase,
    handleResultUpdate,
    updateLastChange,
  );

  const [
    sellPointForOrder,
    sellPointsReadyForOrder,
    handleChangeSellPointForOrder,
  ] = useSellPointForOrder(sellPoints, handleSellPointUpdateRequest);

  const writeAccess = useWriteAccess(rightsPath);

  const deleteButton = handleClick => (
    <Tooltip text={disableDeleteButton ? lang.tooltipArrayDeleteText : null}>
      <PlainButton
        disabled={!writeAccess || disableDeleteButton || sellPointsLoading}
        onClick={handleClick}
      >
        {lang.removeArrayButtonText}
      </PlainButton>
    </Tooltip>
  );

  const restoreButton = handleClick => (
    <Tooltip text={disableRestoreButton ? lang.tooltipArrayRestoreText : null}>
      <PlainButton
        disabled={!writeAccess || disableRestoreButton || sellPointsLoading}
        onClick={handleClick}
      >
        {lang.restoreArrayButtonText}
      </PlainButton>
    </Tooltip>
  );

  return (
    <BuildingsData
      orgId={orgId}
      selectorModalTitle="Выберите корпус ОУ"
      onChangeSelected={setSelectedBuilding}
    >
      <RowLayout paddingfree>
        <CSSSticky
          number={3}
          className={styles.stickable}
          shadowClassName={styles.shadow}
          stickyClassName={styles.sticky}
        >
          <HeaderContent className={classNames(styles.topBorderOnSticky, styles.headerContent)}>
            <div>
              <Header>{header}</Header>
              <HistoryLink sectionTitle={header} />
            </div>
            <BuildingsView title="Корпус ОУ:" />
          </HeaderContent>

          <RowLayoutContent>
            <ControlsBar>
              <PlainLinkAsButton to={`${basePath}/add`} disabled={!writeAccess}>
                {'Добавить'}
              </PlainLinkAsButton>

              {/* Кнопка удаления выделенного */}
              {selectedAll
                ? <PlainMessageModal
                  messageText={itemLang.cantDeleteSellPoint}
                  closeButtonTitle={itemLang.cantDeleteSellPointButton}
                >
                  {deleteButton}
                </PlainMessageModal>
                : <ConfirmationDialog
                  onConfirm={handleDeleteSellPoints}
                  text={replace(/%confirmText%/, selectionPhrase, lang.confirmArrayDeleteText)}
                  confirmButtonText={lang.removeArrayButtonText}
                  confirmButtonProps={{ error: true }}
                >
                  {deleteButton}
                </ConfirmationDialog>}

              {/* Кнопка восстановления выделенного */}
              <ConfirmationDialog
                onConfirm={handleRestoreSellPoints}
                text={replace(/%confirmText%/, selectionPhrase, lang.confirmArrayRestoreText)}
                confirmButtonText={lang.restoreArrayButtonText}
              >
                {restoreButton}
              </ConfirmationDialog>

            </ControlsBar>
          </RowLayoutContent>
        </CSSSticky>

        <RowLayoutContent className={styles.rowPreHeader}>
          <PlainFilterComplete
            id="sellPointsForOrder"
            title="Точка выдачи для заявок:"
            modalTitle="Выбор точки выдачи для заявок"
            itemsObj={sellPointsReadyForOrder}
            selectedArr={sellPointForOrder}
            // $FlowFixMe
            onChangeRaw={handleChangeSellPointForOrder}
            light={false}
            loading={sellPointsLoading}
            badgeProps={badgesHideCrossProps}
            disabled={!writeAccess}
            linkAfterBadge
            singleSelectable
            selectedAllStateEnabled={false}
          />
        </RowLayoutContent>

        <RowLayoutContent>
          <div className={styles.headerline}>
            <div className={styles.header}>{'Действующие точки продаж:'}</div>
            <div className={styles.header}>{'Удаленные точки продаж:'}</div>
          </div>
        </RowLayoutContent>

        <RowLayoutContent paddingfree>
          {sellPointsLoading
            ? <Loader />
            : <div className={styles.content}>
              <div className={styles.column}>
                {itemsCurrent.map((props: SellPointItem) => (
                  <Category
                    key={props.id}
                    id={props.id}
                    title={props.title}
                    locked={!writeAccess}
                    loading={processing}
                    checked={selection.includes(props.id)}
                    onSaveTitle={handleReplaceSellPointName}
                    onToggleItem={handleToggleItem}
                    onDelete={handleDeleteSellPoint}
                    onRestore={handleRestoreSellPoint}
                    lang={itemLang}
                    deleteDisabled={itemsCurrent.length === 1}
                  />
                ))}
              </div>
              <div className={styles.column}>
                {itemsDeleted.map((props: SellPointItem) => (
                  <Category
                    key={props.id}
                    id={props.id}
                    title={props.title}
                    locked={!writeAccess}
                    loading={processing}
                    checked={selection.includes(props.id)}
                    onSaveTitle={handleReplaceSellPointName}
                    onToggleItem={handleToggleItem}
                    onDelete={handleDeleteSellPoint}
                    onRestore={handleRestoreSellPoint}
                    lang={itemLang}
                    deleted
                  />
                ))}
              </div>
            </div>}
          {option === 'add'
            && writeAccess
            && <AddItemModal
              onSubmit={handleAddSellPoint}
              error={renderAddSellPointError(addingError, previosSellPointTtile, orgId)}
              returnPath={basePath}
              resetError={resetAddingError}
            />}
        </RowLayoutContent>
      </RowLayout>
    </BuildingsData>
  );
};

export default SellPointsComponent;
