// @flow
import React, { type Node, useCallback } from 'react';
import cn from 'classnames';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainTooltip from 'app/common/ui-next/plain-tooltip';
import type { HandleModalDisplayed } from 'app/common/ui-next/plain-modal';

import styles from './FooterContent.scss';


type Props = {
  submitButtonTitle: string,
  submitButtonTitleInProcess: string,
  disableSubmitReason: string,
  form: HTMLFormElement | null,
  disableSubmit?: boolean,
  displayModal: HandleModalDisplayed,
  submitting?: boolean,
  hideLegend?: boolean,
}

const FooterContent = ({
  submitButtonTitle,
  submitButtonTitleInProcess,
  disableSubmitReason,
  form,
  disableSubmit,
  displayModal,
  submitting,
  hideLegend,
}: Props): Node => {
  const clickSubmit = useCallback(() => {
    if (form) {
      form.submit.click();
    }
  }, [form]);

  return (
    <ControlsBar className={styles.footerControls} middle>
      <PlainTooltip text={disableSubmit ? disableSubmitReason : ''}>
        <PlainButton
          disabled={disableSubmit || submitting}
          onClick={clickSubmit}
        >
          {submitting ? submitButtonTitleInProcess : submitButtonTitle}
        </PlainButton>
      </PlainTooltip>

      <PlainButton
        outline
        onClick={displayModal}
      >
        {'Отмена'}
      </PlainButton>

      {!hideLegend
        && <ul className={styles.legend}>
          <li>
            <span className={cn(styles.legendColor, styles.alreadyAdded)} />
            <span>{'Продукция уже в вашем списке'}</span>
          </li>
          <li>
            <span className={cn(styles.legendColor, styles.currentlyAdded)} />
            <span>{'Новая выбранная продукция'}</span>
          </li>
        </ul>}
    </ControlsBar>
  );
};

export default FooterContent;
