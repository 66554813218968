// @flow
import isEmpty from 'ramda/es/isEmpty';
import values from 'ramda/es/values';
import head from 'ramda/es/head';
import prop from 'ramda/es/prop';

import { defaultStateDataCurrent } from 'app/common/ui-components/filter';
import { api } from 'app/api';
import { GET_CITIES } from 'app/common/api/requests/food/cities';

/**
 *  Запрос данных фильтра по населенным пунктам
 */

const filterGetItemsCities = async () => {
  const res = await api.request(GET_CITIES, {
    error: 'Не удалось получить данные населенных пунктов',
  });

  if (res && !isEmpty(res)) {
    const citiesList = values(res);
    const selectedCityId = prop('id', head(citiesList));
    return ([citiesList, [selectedCityId]]);
  }

  return ([[], defaultStateDataCurrent]);
};

export default filterGetItemsCities;
