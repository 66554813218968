import { ReactNode, useEffect, useCallback, useRef, RefCallback, useSyncExternalStore, forwardRef, useImperativeHandle } from 'react'

import sticky, { stuckAttributeName, shadowAttributeName } from './sticky-class'
import useID from './use-id'


const subsribe = x => sticky.subscribe(x)

type Props = {
  children: (ref: RefCallback<any>, stuck: boolean, shadow: boolean) => ReactNode,
  applyToChildrenTh?: boolean,
  stickyBottom?: boolean,
  resetShift?: boolean,
  skipShift?: boolean,
  updateKey?: Object | Array<any>,
}

const Sticky = forwardRef(({
  children,
  applyToChildrenTh,
  stickyBottom,
  resetShift,
  skipShift,
  updateKey,
}: Props, ref) => {
  const getID = useID()

  const refElement = useRef<HTMLElement | null>(null)

  useImperativeHandle(ref, () => {
    return refElement.current
  })

  const handleRef = useCallback((el: HTMLElement) => {
    if (el) {
      sticky.add(el, getID(stickyBottom), { applyToChildrenTh, resetShift, skipShift })
    }
    if (refElement.current) {
      sticky.remove(refElement.current)
    }
    refElement.current = el
  }, [applyToChildrenTh, stickyBottom, getID, resetShift, skipShift])

  const handleChangeStickyState = useCallback(() => {
    const el = refElement.current
    return !!el && el.hasAttribute(stuckAttributeName)
  }, [])

  const handleChangeShadowState = useCallback(() => {
    const el = refElement.current
    return !!el && el.hasAttribute(shadowAttributeName)
  }, [])

  const stuck = useSyncExternalStore(subsribe, handleChangeStickyState)
  const shadow = useSyncExternalStore(subsribe, handleChangeShadowState)

  useEffect(() => {
    return () => {
      if (refElement.current) {
        sticky.remove(refElement.current)
      }
    }
  }, [])

  useEffect(() => {
    if (updateKey && refElement.current) {
      sticky.updateStyles(refElement.current, getID())
    }
  }, [updateKey])

  return (
    <>
      {children(handleRef, stuck, shadow)}
    </>
  )
})

export default Sticky
