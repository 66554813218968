// @flow
import { useEffect, useMemo } from 'react';
import { reduce, path } from 'ramda';

import {
  useVendingMachines,
  useVendingMachinesParams,
  useRelatedOrgs,
  useVendingMachineSingle,
} from 'app/dataProviders/VendingDataProvider';


type Data = {
  [id: string]: {
    id: string,
    title: string,
  }
}

type Options = {
  except: ?string,
}

export default function useVendingMachinesWithPlaces({ except }: Options): [Data, boolean] {
  const [, updateParams] = useVendingMachinesParams();
  const [{ modelId: currentModelId }] = useVendingMachineSingle();

  useEffect(() => {
    updateParams({ limit: 10000 });
  }, [updateParams]);

  const [{ data: items }, loading, requestMachines] = useVendingMachines();

  const relatedOrgs = useRelatedOrgs();

  useEffect(() => {
    requestMachines();
  }, [requestMachines]);

  const data = useMemo(() => {
    return reduce((acc, { id, title, school_id: orgId, model_id: modelId }) => {
      const currentId = String(id);
      if (currentId !== except && currentModelId === modelId) {
        const orgTitle = path([orgId, 'title'], relatedOrgs);
        acc[currentId] = {
          id: currentId,
          title: `${title} (${orgTitle || '...заведение не найдено'})`,
          sortable: `${orgTitle || ''}${title}`,
        };
      }
      return acc;
    }, {}, items);
  }, [items, except, relatedOrgs, currentModelId]);

  return [data, loading];
}
