import mapProps from 'recompose/mapProps';
import compose from 'recompose/compose';
import prop from 'ramda/es/prop';
import { withTranslation } from 'react-i18next';

import Restore from '../Restore';


const enhance = compose(
  withTranslation(),

  mapProps((({ handleReturnFromActivation, watEntered, fields, t, ...props }) => ({
    ...props,
    header: t('login:register_activation_title'),
    text1: `${t('login:register_activation_info')}:`,
    text2: `${t('login:register_activation_invite')} ${watEntered} ${prop('login', fields)}.`,
    lockLogin: true,
    hideLogin: true,
    handleReturn: handleReturnFromActivation,
    newPassTitle: t('login:register_activation_new_pass_title'),
  }))),
);

export default enhance(Restore);
