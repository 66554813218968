import { ReactNode, useCallback, useState } from 'react'

import { CompensationInfo, CompensationInfoByOrgID } from 'app/dataTypes/compensation/info'
import { CheckStateProvider, fancyRowRootClassName, Table, useCopyColumnsWidths, useTopShiftState } from 'app/common/ui-components/table'
import Sticky from 'app/common/templates-next/sticky'

import { TableHeader } from './TableHeader'
import { useColumnProps, useColumnsArranged, useColumnTitles, useHeaderColumnProps } from './columns'
import { TableRow } from './TableRow'

import styles from './DataTable.scss'


type Props = {
  data: CompensationInfoByOrgID
  currency: string
  date: string
  onAction: (params: {
    orgID: string,
    action: 'record' | 'cancel' | 'interrupt',
  }) => Promise<boolean>
  onRowDataUpdateRequired: (orgID: string) => void
  onRequestInfo: (orgID: string) => Promise<CompensationInfo | null>
  compensationDetailsTable: (params: { orgID: string, allowanceID: string }) => ReactNode
  compensationDetailsTableFooter: () => ReactNode
  selectionMode: null | 'record' | 'cancel'
  selection: Set<string>
  onChangeSelection: (orgID: string, checked: boolean) => void
  onChangeSelectionAll: (selectedAll: boolean) => void
  selectedAll: boolean
  showSelectAllCheckbox: boolean
}

export const DataTable = ({
  data,
  currency,
  date,
  onAction,
  onRowDataUpdateRequired,
  onRequestInfo,
  compensationDetailsTable,
  compensationDetailsTableFooter,
  selectionMode,
  selection,
  onChangeSelection,
  onChangeSelectionAll,
  selectedAll,
  showSelectAllCheckbox,
}: Props) => {
  const columns = useColumnsArranged()
  const titles = useColumnTitles(currency)
  const headerColumnProps = useHeaderColumnProps()
  const columnProps = useColumnProps()

  const [updateRowNode, calcTopShift, nodes] = useTopShiftState()

  const [updateKey, setUpdateKey] = useState([])
  const [trFrom, trTo] = useCopyColumnsWidths(updateKey, { useClassIndexes: true })
  const updateColumnsWidths = useCallback(() => { setUpdateKey([]) }, [])

  return (
    <Table wide className={styles.root}>
      <thead>
        <Sticky ref={trFrom} applyToChildrenTh>
          {(ref, stuck, shadow) => (
            <TableHeader
              ref={ref}
              columns={columns}
              titles={titles}
              stuck={stuck}
              shadow={shadow}
              columnProps={headerColumnProps}
              onChangeSelectionAll={onChangeSelectionAll}
              selectedAll={selectedAll}
              showSelectAllCheckbox={showSelectAllCheckbox}
            />
          )}
        </Sticky>
      </thead>

      <tbody className={fancyRowRootClassName}>
        <CheckStateProvider>
          {Array.from(data.values()).map((compensationInfo, index) => (
            <TableRow
              key={compensationInfo.orgID}
              date={date}
              ID={compensationInfo.orgID}
              columns={columns}
              columnProps={columnProps}
              compensationInfo={compensationInfo}
              trElem={nodes[String(index)]}
              index={index}
              calcTopShift={calcTopShift}
              updateRowNode={updateRowNode}
              onAction={onAction}
              onUpdateRequired={onRowDataUpdateRequired}
              onRequestInfo={onRequestInfo}
              innerTableHeaderRef={trTo}
              onRowDataUpdated={updateColumnsWidths}
              compensationDetailsTable={compensationDetailsTable}
              compensationDetailsTableFooter={compensationDetailsTableFooter}
              selectionMode={selectionMode}
              selection={selection}
              onChangeSelection={onChangeSelection}
            />
          ))}
        </CheckStateProvider>
      </tbody>
    </Table>
  )
}
