// @flow
import React, { type Node, Fragment, useCallback, memo } from 'react';
import cn from 'classnames/bind';

import { TrSelectable, TdLabel, Td, getPropsHeader } from 'app/common/ui-components/table';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import Picture from 'app/common/ui-next/picture';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Dish as CommonDish } from 'app/dataTypes/Dishes.types';
import CellContentSaleType from 'app/components/common/cellContents/CellContentSaleType';
import CellContentPhoto from 'app/components/common/cellContents/CellContentPhoto';
import { useSaleTypesData } from 'app/dataProviders/SaleTypesData';
import type { ImageParamsType } from 'app/common/components/ImagePrepare/ImagePrepare';

import type { RowCell } from './useRowCellsData';
import OptionsCell from './OptionsCell';
import useFieldSubmit from './useFieldSubmit';
import { columnTypes as ct } from './columns';
import CellEditableProp from './CellEditableProp';

import styles from './Row.scss';


const cx = cn.bind(styles);

type Props = {
  id: string,
  foodItemId: string,
  title: string,
  relatedOrg: { id: null | string, title: string },
  checked: boolean,
  disabled: boolean,
  cells: Array<RowCell>,
  onChange: (id: string, chkd: boolean, title: string) => void,
  onDelete: (dish: Dish) => Promise<boolean> | boolean,
  onUpdateDish: (dish: Dish) => void,
  onUpdateDishAsCommon: (dish: CommonDish) => void,
}

const Row = ({
  id,
  foodItemId,
  title,
  relatedOrg,
  checked,
  disabled,
  cells,
  onChange,
  onDelete,
  onUpdateDish,
  onUpdateDishAsCommon,
}: Props): Node => {
  const getHeaderProps = getPropsHeader();

  const handleClick = useCallback((id_: string, chk: boolean) => {
    onChange(id_, chk, title);
  }, [title, onChange]);

  const [saleTypes, loadingSaleTypes] = useSaleTypesData();
  const { title: orgTitle } = relatedOrg;
  const [fieldSubmit, fieldSubmitPlaceBased] = useFieldSubmit({
    id,
    foodItemId,
    updateDish: onUpdateDish,
    updateDishAsCommon: onUpdateDishAsCommon,
  });

  const handleSaveImage = useCallback(async (imagePrepare: ImageParamsType) => {
    const res = await fieldSubmit('photo', imagePrepare);
    return res;
  }, [fieldSubmit]);

  const handleDeleteImage = useCallback(async () => {
    const res = await fieldSubmit('photo', null);
    return res;
  }, [fieldSubmit]);

  const lock = false;
  const htmlFor = `vending_${id}`;

  return (
    <TrSelectable
      id={id}
      onClick={handleClick}
      selected={checked}
      className={cx({ currentlySelected: checked })}
      disabled={disabled}
      interlineBorder
      alignTop
    >
      {checkboxProps => (
        <Fragment>
          {cells.map(({ key, value, extra, ...props }) => {
            switch (key) {
              case ct.ID:
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    className={styles.cellCheckbox}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    <PlainCheckbox
                      label={cells[1].value}
                      skipLabel
                      {...checkboxProps}
                      id={htmlFor}
                      disabled={disabled}
                    />
                  </TdLabel>
                );

              case ct.PHOTO:
                return (
                  <Td
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    {lock
                      ? <Picture
                        className={styles.photo}
                        alt="Фото продукции"
                        src={value}
                        width="100px"
                        height="auto"
                      />
                      : <CellContentPhoto
                        photoUrl={value}
                        className={styles.photo}
                        deleteConfirmText={`Вы уверены? Удалить фотографию ${title}?`}
                        onSaveImage={handleSaveImage}
                        onDeleteImage={handleDeleteImage}
                      />}
                  </Td>
                );

              case ct.PORTION:
                return (
                  <Td
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    <CellEditableProp
                      value={value}
                      fieldName="portion"
                      orgTitle={orgTitle}
                      onSubmit={fieldSubmitPlaceBased}
                    />
                  </Td>
                );


              case ct.COST:
                return (
                  <Td
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    <CellEditableProp
                      value={value}
                      fieldName="cost"
                      orgTitle={orgTitle}
                      onSubmit={fieldSubmitPlaceBased}
                    />
                  </Td>
                );

              case ct.TYPE:
                return (
                  <Td
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    <CellContentSaleType
                      id={id}
                      currentSaleType={value}
                      pending={loadingSaleTypes}
                      saleTypes={saleTypes}
                      onChangeSaleType={fieldSubmit}
                    />
                  </Td>
                );

              case ct.OPTIONS:
                return (
                  <Td
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    {extra
                      && <OptionsCell
                        dish={extra}
                        onDelete={onDelete}
                      />}
                  </Td>
                );

              default:
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    {value}
                  </TdLabel>
                );
            }
          })}
        </Fragment>
      )}
    </TrSelectable>
  );
};

// $FlowFixMe
export default memo<Props>(Row);
