import { useCallback, useState } from 'react'
import cn from 'classnames/bind'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainIcon, { iconType } from 'app/common/ui-next/plain-icon'
import useExample from 'app/dataProviders/requisites/hooks/useExample'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import styles from './DownloadExample.scss'


const cx = cn.bind(styles)

type Props = {
  type: 'school' | 'camp'
  filename: string
}

const DownloadExample = ({ type, filename }: Props) => {
  const [disabled, setDisabled] = useState(false)
  const download = useExample({ type, filename })
  const downloadRef = useCallbackRef(download)

  const handleClick = useCallback(async () => {
    setDisabled(true)
    await downloadRef.current?.()
    setDisabled(false)
  }, [downloadRef])

  return (
    <PlainButtonAsLink
      onClick={handleClick}
      linkClassName={styles.root}
      disabled={disabled}
    >
      <span>{'Скачать образец квитанции:'}</span>
      <span>&nbsp;</span>
      <PlainIcon
        className={cx(styles.icon, { disabled })}
        width={30}
        height="auto"
        type={iconType.PDF32}
      />
    </PlainButtonAsLink>
  )
}

export default DownloadExample
