import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import { useLocation } from 'react-router'

import { api } from 'app/api'
import { GET_RIGHTS, convertResult } from 'app/common/api/requests/food/roles/current/rights'
import type { Rights } from 'app/common/constants/rights'
import action from 'app/common/lib/action'
import { USER_RIGHTS_SUCCESS } from 'app/actions/userRights'

import { allGranted } from './allGranted'
import { onlyRead } from './onlyRead'


const defaultRights = onlyRead

export type State = {
  rights: Rights
  su: boolean
  ready: boolean
}

export const defaultState: State = {
  rights: defaultRights,
  su: false,
  ready: false,
}

export type Handlers = {
  request: () => void | Promise<void>
}

export const defaultHandlers: Handlers = {
  request: () => { },
}

export default function useRights(): [State, Handlers] {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [rights, setRights] = useState<Rights>(defaultRights)
  const [su, setSU] = useState<boolean>(false) // is superuser
  const [ready, setReady] = useState<boolean>(false)


  const handleRequest = useCallback(async () => {
    const res = await api.request(GET_RIGHTS, {
      error: 'Не удалось получить права пользователя',
      convertions: {
        convertResult,
      },
    })

    if (res === '') {
      setReady(true)
      setSU(true)
      setRights(defaultRights)
      // для совместимости
      dispatch(action(USER_RIGHTS_SUCCESS, allGranted))
      return
    }

    if (res) {
      const haveRights: Rights = res
      if (haveRights && !isEmpty(haveRights)) {
        setReady(true)
        setSU(false)
        setRights(haveRights)
        // для совместимости
        dispatch(action(USER_RIGHTS_SUCCESS, haveRights))
        return
      }
    }
    setReady(false)
    setSU(false)
    setRights(defaultRights)
    // для совместимости
    dispatch(action(USER_RIGHTS_SUCCESS, defaultRights))
  }, [dispatch])


  // периодическое обновление прав при смене pathname
  useEffect(() => {
    handleRequest()
  }, [handleRequest, pathname])


  return [{
    rights,
    su,
    ready,
  }, {
    request: handleRequest,
  }]
}
