import drop from 'ramda/es/drop';
import append from 'ramda/es/append';
import genId from 'app/common/lib/genId';

import { CLEAR_ERRORS } from 'app/common/actions/errors';


const maxLength = 5;
const defaultState = [];

const errorSettings = {
  showErrors: true,
};

export const errorDisplayBlocking = () => {
  errorSettings.showErrors = false;
};

export default function reducer(state = defaultState, action) {
  if (action.type === CLEAR_ERRORS) {
    return defaultState;
  }
  if (action.type.match(/FAIL/)) {
    const { showErrors } = errorSettings;
    const errorAsString = typeof action.payload.error === 'object' ? action.payload.error.toString() : '';
    if (!showErrors || errorAsString === 'Cancel' || /CanceledError/.test(errorAsString)) {
      return state;
    }
    const newState = append({ ...action.payload.error, id: genId() }, state);
    return (newState.length > maxLength
      ? drop(1, newState)
      : newState
    );
  }
  return state;
}
