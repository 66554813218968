import { useCallback, useState } from 'react'


import { api } from 'app/api'
import { CorrectionPurchasesType, CorrectionSubsidiesType, OperationType } from 'app/dataTypes/reports/data'
import { GET_CORRECTIONS_DETAILS, convertParams, convertResult } from 'app/common/api/requests/reports/corrections_details/get'


interface Props {
  date: string;
  schoolID: string;
  operationType: OperationType;
  currentPage?: number
  perPage?: number
  setTotalCount?: (count: number) => void
}
export function useCorrectionsDetails({
  date,
  schoolID,
  operationType,
  currentPage = 1,
  perPage = 10,
  setTotalCount = () => { },
}: Props) {
  const [details, setDetails] = useState<Array<CorrectionPurchasesType | CorrectionSubsidiesType>>([])
  const [loading, setLoading] = useState(true)

  const requestDetails = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_CORRECTIONS_DETAILS, {
      error: 'Не удалось загрузить данные по корректировкам',
      params: {
        date,
        schoolID,
        operationType,
        limit: perPage * currentPage,
        offset: perPage * currentPage - perPage,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })

    setDetails(res?.corrections || [])
    setTotalCount(res?.count || 0)
    setLoading(false)
  }, [date, operationType, currentPage, perPage, schoolID, setTotalCount])

  const detailsPerPage = details.slice(currentPage * perPage - perPage, currentPage * perPage)
  return {
    correctionsDetails: detailsPerPage,
    requestDetails,
    loading,
    setLoading,
  }
}
