import always from 'ramda/es/always';
import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  EMPLOYEES_FILTER_REQUEST,
  EMPLOYEES_FILTER_SUCCESS,
  EMPLOYEES_FILTER_FAILURE,
  EMPLOYEES_FILTER_CLEAR,
  EMPLOYEES_FILTER_DELETE,
  EMPLOYEES_FILTER_SET,
  EMPLOYEES_FILTER_UPDATE,
} from 'app/actions/reports/filters/employees';


const defaultState = {
  data: {},
  selected: {},
  loading: true,
  active: false,
};

const students = (state = defaultState, { type, payload }) => {
  switch (type) {
    case EMPLOYEES_FILTER_REQUEST: {
      return { ...state, loading: true };
    }
    case EMPLOYEES_FILTER_SUCCESS: {
      return evolve({
        data: always(payload),
        loading: always(false),
      }, state);
    }
    case EMPLOYEES_FILTER_FAILURE: {
      return { ...state, loading: false };
    }
    case EMPLOYEES_FILTER_CLEAR: {
      return defaultState;
    }
    case EMPLOYEES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case EMPLOYEES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case EMPLOYEES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default students;
