import { createSelector } from 'reselect';
import path from 'ramda/es/path';
import trim from 'ramda/es/trim';


const selectItemsText = createSelector(
  [
    state => state.orgs.dishes.selection.items,
    state => state.orgs.dishes.items,
  ],
  (ids, items) => ids.map(id => trim(path([id, 'title'], items))),
);

export default selectItemsText;
