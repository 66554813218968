// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as orgsOrUser } from '../orgsOrUser';


const detailsActivity = (state: State) => {
  return (
    orgsOrUser.filterActive(state)
      ? orgsOrUser.byOrgsSelected(state)
      : true
  );
};

export default detailsActivity;
