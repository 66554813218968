import { useCallback, useEffect, useState } from 'react'

import {
  GET_SCHOOL_SUBSIDY_ORDERS_SETTINGS,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/schools/subsidy_orders_settings/get'

import { Params, Settings } from 'app/dataTypes/subsidy/settings'
import { api } from 'app/api'


type Options = {
  autoload?: boolean
} & Params

const defaultSettings: Settings = {
  app: 'cafeteria',
  accrualsShiftsAmount: 3,
  accruals: [],
  accrualsFor: {
    single: [false, false],
    class: [false, false],
    dayCare: [false, false],
  },
  include: {
    saturdays: [false, false],
  },
}

export default function useSubsidySettings({ orgID, app, allowanceID, autoload = true }: Options): [
  Settings,
  boolean,
  () => Promise<void>
] {
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState<Settings>(defaultSettings)

  const request = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_SCHOOL_SUBSIDY_ORDERS_SETTINGS, {
      error: 'Не удалось получить настройки категории льгот',
      params: {
        app,
        orgID,
        allowanceID,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })
    setLoading(false)

    if (res) {
      setSettings(res)
    }
  }, [orgID, app, allowanceID])

  useEffect(() => {
    if (autoload) {
      request()
    }
  }, [request, autoload])

  return [settings, loading, request]
}
