// @flow
import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import config from 'app/config';


const dataKey: 'vending' = 'vending';

type ColumnVisibility = {
  place: boolean,
  title: boolean,
  number: boolean,
  goodsAmount: boolean,
  loadedAt: boolean,
  lastSellAt: boolean,
  errors: boolean,
  actions: boolean,
}

const defaultColumnVisibility = {
  place: true,
  title: true,
  number: true,
  goodsAmount: true,
  loadedAt: true,
  lastSellAt: true,
  errors: true,
  actions: true,
};

export type Settings = {
  rows: number,
  ...ColumnVisibility,
}

export const vendingDefaultState: Settings = {
  rows: config.perPageItems,
  ...defaultColumnVisibility,
};

export default makeSettingsReducer(dataKey, vendingDefaultState);
