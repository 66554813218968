// @flow
import { balanceTypes } from 'app/dataTypes/reports/balanceTypes';


export { balanceTypes };

export const all = {
  id: balanceTypes.All,
  title: 'Все',
};

export const school = {
  id: balanceTypes.Cafeteria,
  title: 'Питание в школе',
};

export const camp = {
  id: balanceTypes.Camp,
  title: 'Питание в пришкольном лагере',
};

export const itemsAvailable = { all, school, camp };

const balanceTypeItems = () => (
  [[all, school, camp], [balanceTypes.All]]
);

export default balanceTypeItems;
