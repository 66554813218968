// @flow
import React, { type Node } from 'react';

import PlainNav, { NavItem } from 'app/common/ui/PlainNav';

import { availableSections, getSectionTitle } from '../sections';
import styles from './Navigation.scss';


type Props = {
  parentUrl?: string,
  baseUrl: string,
}

const Navigation = ({ parentUrl, baseUrl }: Props): Node => {
  return (
    <div className={styles.root}>
      <PlainNav className={styles.mainNav}>
        {availableSections.map(s => (
          <NavItem
            key={s}
            to={`${baseUrl}/${s}`}
          >
            {getSectionTitle(s)}
          </NavItem>
        ))}
      </PlainNav>

      {parentUrl
        && <PlainNav>
          <NavItem to={parentUrl}>
            {'все аппараты'}
          </NavItem>
        </PlainNav>}
    </div>
  );
};

export default Navigation;
