import { useRef, useLayoutEffect, MutableRefObject } from 'react'


export default function useCallbackRef<T extends unknown[], R extends unknown>(
  callback: ((...args: [...T]) => R) | undefined
): MutableRefObject<((...args: [...T]) => R) | void> {
  const callbackRef = useRef<((...args: [...T]) => R) | undefined>(callback)

  useLayoutEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return callbackRef
}
