// @flow
import React from 'react';
import compose from 'recompose/compose';

import Button from 'app/common/ui/Button';
import Tooltip from 'app/common/ui/Tooltip';
import PlainInputFocused from 'app/common/ui/PlainInput/PlainInputFocused';
import PlainInputIcon from 'app/common/ui/PlainInputIcon';
import {
  withDishesCategoriesRights,
  withDishesOrgDishesRights,
  withCurrentOrgRights,
} from 'app/containers/rights';
import { withWriteAccess, bothRights } from 'app/common/containers/withRights';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import { WithRightsForOrg } from 'app/rights/RightsForOrg';
import * as rootRights from 'app/common/constants/rights/rootRights';
import * as specificRights from 'app/common/constants/rights/specificRights';
import PlainButton from 'app/common/ui-next/plain-button';
import { replaceLast } from 'app/common/lib/path';
import * as orgsTypes from 'app/common/constants/schools/types';
import { StatusMessage } from 'app/common/templates-next/page-template';

import enhanceOrgsDishes from './enhanceOrgsDishes';
import Categories, { useCategories, useCategoryHeader } from './Categories';
import Dishes from './Dishes';
import OrgsDishesGear from './OrgsDishesGear';
import AddCategory from './AddCategory';
import ChangeItems from './ChangeItems';
import EditList from './EditList';
import CopyDishesModal from './CopyDishesModal';

import styles from './OrgsDishes.scss';


const tipEditCategory = 'Выберите категорию в колонке слева для редактирования списка продукции в ней';
const categoriesEditAccess = compose(
  withCurrentOrgRights,
  withWriteAccess,
  withDishesCategoriesRights,
  withWriteAccess,
);
const dishesEditAccess = compose(
  withCurrentOrgRights,
  withWriteAccess,
  withDishesOrgDishesRights,
  withWriteAccess,
);

type DLEProps = {
  tooltip: string,
  disabled: boolean,
  pathname: string,
};

const AddCategoryAccessed = categoriesEditAccess(AddCategory);
const EditListAccessed = dishesEditAccess(EditList);

const CategoriesAccessed = bothRights(withCurrentOrgRights, withDishesCategoriesRights)(Categories);

const dishesRightsPath = [rootRights.DISHES, specificRights.SCHOOL_DISHES];

// const ChangeItemsAccessed = compose(
//   withCurrentOrgRights,
//   withWriteAccess,
//   eitherWriteAccess(
//     withMenuMenuRights,
//     withDishesOrgDishesRights
//   )
// )(ChangeItems);

const DishesListEditButton = dishesEditAccess(({
  tooltip,
  disabled,
  pathname,
}: DLEProps) => (<div className={styles.item}>
  <Tooltip text={tooltip}>
    <Button
      href={`${pathname}/editlist`}
      value="Редактирование списка продукции"
      disabled={disabled}
    />
  </Tooltip>
</div>));

type TotalCopyProps = {
  orgID: string,
  orgType: string
}

const TotalCopy = dishesEditAccess(({ orgID, orgType }: TotalCopyProps) => (
  <div className={styles.globalCopy}>
    <CopyDishesModal orgID={orgID} orgType={orgType}>
      {toggleModal => (<PlainButtonAsLink
        id="copy_button"
        ariaLabel="Копировать весь список продукции"
        onClick={toggleModal}
        className={styles.logout}
        linkClassName={styles.logoutLink}
      >
        {'Копировать весь список продукции'}
      </PlainButtonAsLink>)}
    </CopyDishesModal>
  </div>));

type Props = {
  orgID: string,
  orgType: $Values<typeof orgsTypes>,
  basePath: string,
  categoryId?: string,
  process: string,
  pathname: string,

  handleSearch: (e: SyntheticEvent<HTMLElement>) => void,
  handleClearSearch: (e: SyntheticEvent<HTMLElement>) => void,
  searchString: string,

  onCategoryInvalid: () => void,
}

const OrgsDishes = ({
  orgID,
  orgType,
  basePath,
  categoryId,
  process,
  pathname,

  handleSearch,
  handleClearSearch,
  searchString,

  onCategoryInvalid,
}: Props) => {
  const orgIdNumber = parseInt(orgID, 10);
  const returnTo = replaceLast(pathname);

  const [
    { categoryList, loading },
    { request: requestCategories, moveItem, createCategory, deleteCategory },
  ] = useCategories({
    orgId: orgIdNumber,
    pathTemplate: `${basePath}/:categoryId`,
  });

  const currentCategoryId = categoryId;
  const header = useCategoryHeader({ categoryList, currentCategoryId, onCategoryInvalid });

  const isCategorySelected = categoryId && categoryId !== 'all';


  return (
    <div className={styles.root}>
      <div className={styles.content}>

        {/* Колонка с категориями */}
        <div className={styles.leftColumn}>

          <AddCategoryAccessed
            orgId={orgIdNumber}
            onSubmitCategory={createCategory}
          >
            {toggle => (
              <PlainButton
                className={styles.addCategory}
                onClick={toggle}
              >
                {'Добавить категорию'}
              </PlainButton>
            )}
          </AddCategoryAccessed>

          <div className={styles.categories}>
            <CategoriesAccessed
              currentCategoryId={categoryId}
              orgId={orgIdNumber}
              categoryList={categoryList}
              loading={loading}
              moveItem={moveItem}
              onDeleteCategory={deleteCategory}
            />
          </div>
          <TotalCopy orgID={orgID} orgType={orgType} />
        </div>

        {/* Колонка с продукцией */}
        <div className={styles.rightColumn}>
          <div className={styles.headerLine}>
            <h2 className={styles.header}>{header}</h2>
            <WithRightsForOrg
              pathTo={dishesRightsPath}
              readAffect="hide"
              editAffect="ignore"
            >
              <OrgsDishesGear />
            </WithRightsForOrg>
          </div>
          <div className={styles.buttons}>
            {/* Запуск редактирования списка блюд */}
            <DishesListEditButton
              pathname={pathname}
              tooltip={!isCategorySelected ? tipEditCategory : ''}
              disabled={!isCategorySelected || process === 'editlist'}
            />
            {/* Поиск */}
            <WithRightsForOrg
              pathTo={dishesRightsPath}
              readAffect="hide"
              editAffect="ignore"
            >
              <div className={styles.item}>
                <form role="search">
                  <PlainInputFocused
                    component={PlainInputIcon}
                    placeholder="Поиск"
                    onChange={handleSearch}
                    value={searchString}
                    icon={
                      searchString
                      && <a href="clear" className={styles.clearSearch} onClick={handleClearSearch}>{'×'}</a>
                    }
                  />
                </form>
              </div>
            </WithRightsForOrg>
            {/* Кнопка с выпадающим меню "Изменить" */}
            <WithRightsForOrg pathTo={dishesRightsPath} readAffect="hide" editAffect="hide">
              <ChangeItems
                className={styles.item}
                orgId={orgID}
                categoryId={categoryId}
                returnTo={returnTo}
                process={process}
                pathname={pathname}
                categoryList={categoryList}
                updateCategories={requestCategories}
                hideAdditionToMenu={orgType === orgsTypes.ENTERPRISE}
              />
            </WithRightsForOrg>
          </div>

          {/* Таблица с продукцией */}
          <WithRightsForOrg
            pathTo={dishesRightsPath}
            readAffect="hide"
            editAffect="ignore"
            viewOnHide={
              <StatusMessage>
                {'Отсутствуют права на просмотр содержимого'}
              </StatusMessage>
            }
          >
            <Dishes
              orgId={orgID}
              orgType={orgType}
              categoryId={categoryId}
              categoryList={categoryList}
              updateCategories={requestCategories}
              disableDishGear={orgType === orgsTypes.ENTERPRISE}
            />
          </WithRightsForOrg>
        </div>
      </div>

      {process === 'editlist'
        && <EditListAccessed
          returnTo={returnTo}
          orgId={orgID}
          categoryId={categoryId}
        />}
    </div>
  );
};

export default enhanceOrgsDishes(OrgsDishes);
