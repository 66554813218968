// @flow
import path from 'ramda/es/path';
import pipe from 'ramda/es/pipe';
import api from 'app/actions/api';
import { stringListToObject } from 'app/common/lib/object';


export const CUSTOMERS_FILTER_REQUEST = 'reports/CUSTOMERS_FILTER_REQUEST';
export const CUSTOMERS_FILTER_SUCCESS = 'reports/CUSTOMERS_FILTER_SUCCESS';
export const CUSTOMERS_FILTER_FAILURE = 'reports/CUSTOMERS_FILTER_FAILURE';
export const CUSTOMERS_FILTER_CLEAR = 'reports/CUSTOMERS_FILTER_CLEAR';
export const CUSTOMERS_FILTER_DELETE = 'reports/CUSTOMERS_FILTER_DELETE';
export const CUSTOMERS_FILTER_SET = 'reports/CUSTOMERS_FILTER_SET';
export const CUSTOMERS_FILTER_UPDATE = 'reports/CUSTOMERS_FILTER_UPDATE';


const customersFilterRequest = () => ({
  type: CUSTOMERS_FILTER_REQUEST,
});

const customersFilterSuccess = (payload: Object) => ({
  type: CUSTOMERS_FILTER_SUCCESS,
  payload,
});

const customersFilterFailure = (payload: Object) => ({
  type: CUSTOMERS_FILTER_FAILURE,
  payload,
});

export const customersFilterClear = () => ({
  type: CUSTOMERS_FILTER_CLEAR,
});

export const customersFilterDelete = (payload: Array<string | number>) => ({
  type: CUSTOMERS_FILTER_DELETE,
  payload,
});

export const customersFilterSet = (payload: Object) => ({
  type: CUSTOMERS_FILTER_SET,
  payload,
});
// DEPRECATED
export const customersFilterFetch = (customConvertList2Obj?: (Array<string>) => Object) => async (dispatch: Function): Object => {
  dispatch(customersFilterRequest());
  try {
    const res = await api('reports/customer_types', 'get');
    const convert = pipe(path(['data', 'customer_types']), customConvertList2Obj || stringListToObject);
    const data = convert(res);
    dispatch(customersFilterSuccess(data));
    return data;
  } catch (error) {
    dispatch(customersFilterFailure({ err: 'Не удалось получить данные фильтра по типу покупателя', error }));
    return null;
  }
};
