// @flow
import React from 'react';
import Checkbox from 'app/common/ui/Checkbox';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';
import SettingsGear from 'app/common/components/Settings';

import { useDishesData } from '../Dishes/DishesData';

import styles from './SettingsGear.scss';


export type DishesTableSettings = {
  rows: number,
  title: boolean,
  photo: boolean,
  restrictions: boolean,
  portion: boolean,
  cost: boolean,
  saleType: boolean,
  category: boolean,
  setup: boolean,
  nomenclature: boolean,
}

type Props = {
  disabledSetup: boolean,
  hidePhoto?: boolean,
}

const DishesGear = ({ disabledSetup, hidePhoto }: Props) => {
  const [{ nomenclatureActive }] = useDishesData();

  return (
    <SettingsGear dataKey="dishes">
      {({ saveSettings }) => (<div>
        <div className={styles.header}>{'Столбцы:'}</div>
        {/* Чекбоксы в настройках */}
        {nomenclatureActive
            && <WithHOC
              component={Checkbox}
              hoc={CheckboxSettings}
              label="ID"
              name="id"
              saveSettings={saveSettings}
              className={styles.check}
              settingsKey="dishes"
            />}
        {nomenclatureActive
            && <WithHOC
              component={Checkbox}
              hoc={CheckboxSettings}
              label="Номенклатура"
              name="nomenclature"
              saveSettings={saveSettings}
              className={styles.check}
              settingsKey="dishes"
            />}
        {/* <WithHOC
            component={Checkbox}
            hoc={CheckboxSettings}
            label="Наименование"
            name="title"
            saveSettings={saveSettings}
            className={styles.check}
            settingsKey="dishes"
          /> */}
        {!hidePhoto
            && <WithHOC
              component={Checkbox}
              hoc={CheckboxSettings}
              label="Фото"
              name="photo"
              saveSettings={saveSettings}
              className={styles.check}
              settingsKey="dishes"
            />}
        <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="Порция"
          name="portion"
          saveSettings={saveSettings}
          className={styles.check}
          settingsKey="dishes"
        />
        <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="Стоимость"
          name="cost"
          saveSettings={saveSettings}
          className={styles.check}
          settingsKey="dishes"
        />
        <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="Тип продукции"
          name="saleType"
          saveSettings={saveSettings}
          className={styles.check}
          settingsKey="dishes"
        />
        {!disabledSetup
            && <WithHOC
              component={Checkbox}
              hoc={CheckboxSettings}
              label="Действия"
              name="options"
              saveSettings={saveSettings}
              className={styles.check}
              settingsKey="dishes"
            />}
      </div>)}
    </SettingsGear>
  );
};

export default DishesGear;
