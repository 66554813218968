// @flow
import { connect } from 'react-redux';
import type { HOC } from 'recompose';
import toPairs from 'ramda/es/toPairs';


type Props = {
  title: string,
  items: Object,
};

const enhanceInfoItem: HOC<*, Props> = connect(
  (state, props) => ({
    items: toPairs(props.items),
  }),
);

export default enhanceInfoItem;
