// @flow
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';

import { defaultStateDataCurrent, getStateCurrentValue, type State } from 'app/common/ui-components/filter';
import { GET_BUILDINGS } from 'app/common/api/requests/food/schools/buildings';
import { api } from 'app/api';

import orgFilter from '../org';


const buildingItems = async (state: State) => {
  const orgIds = getStateCurrentValue(orgFilter, state);
  const orgId = orgIds[0];

  if (orgIds.length === 1 && orgId) {
    const res = await api.request(GET_BUILDINGS, {
      error: 'Не удалось получить данные корпусов',
      requestPathParams: {
        orgId,
      },
    });

    if (res && !isEmpty(res)) {
      const items = values(res);
      return ([items, defaultStateDataCurrent]);
    }
  }
  return [[], defaultStateDataCurrent];
};

export default buildingItems;
