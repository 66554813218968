// @flow
import { useCallback, useState, useEffect } from 'react';
import map from 'ramda/es/map';
import toPairs from 'ramda/es/toPairs';

import { GET_SALE_TYPES } from 'app/common/api/requests/food/items/sale_types';
import { api } from 'app/api';


type SaleTypeItem = {
  id: string,
  title: string,
}

export type SaleTypes = Array<SaleTypeItem>
export const defaultSaleTypesState = [];
export type SaleTypesState = [SaleTypes, boolean]

export default function useSaleTypes(): SaleTypesState {
  const [loading, setLoading] = useState(true);
  const [saleTypes, setSaleTypes] = useState<SaleTypes>(defaultSaleTypesState);

  // запрос данных
  const handleRequestDishes = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_SALE_TYPES, {
      error: 'Не удалось получить типы продукции',
    });
    setLoading(false);

    if (res) {
      setSaleTypes(map(([id, { title }]) => ({ id, title }), toPairs(res)));
    }
  }, []);

  useEffect(() => {
    handleRequestDishes();
  }, [handleRequestDishes]);

  return [saleTypes, loading];
}
