import { isEmpty } from 'ramda'

import styles from './html-select.scss'


type Props = {
  name?: string
  selection?: Array<string>
  multiple?: boolean
  items: Array<{
    id: string
    title: string
  } | {
    value: string
    title: string
  }>
}

const HTMLSelect = ({
  name,
  selection = [],
  multiple,
  items,
}: Props) => {
  const current = multiple ? selection : selection[0]

  return (
    <select
      id={name}
      name={name}
      multiple={multiple}
      value={current}
      className={styles.root}
      onChange={() => {}}
      disabled={multiple ? isEmpty(current) : !current}
    >
      {items.map((item) => {
        if ('id' in item) {
          const { id, title } = item
          return <option value={id} key={id} aria-label={title} />
        }
        if ('value' in item) {
          const { value, title } = item
          return <option value={value} key={value} aria-label={title} />
        }
        return null as never
      })}
    </select>
  )
}

export default HTMLSelect
