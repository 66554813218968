import { useState, useCallback, useRef, ChangeEvent } from 'react'
import toPairs from 'ramda/es/toPairs'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import Prompt from 'app/components/common/Prompt'
import usePopup from 'app/common/hooks/usePopup'
import SaveCancelButtons from 'app/components/common/SaveCancelButtons'
import { PageTemplateContent, Header2 } from 'app/common/templates-next/page-template'
import Sticky from 'app/common/templates-next/sticky'
import { useEditGeneralSettings } from 'app/dataProviders/generalSettings/hooks'
import { OptionLabelsType } from 'app/dataTypes/generalSettings'

import styles from './SectionComplexes.scss'


const optionLabels: OptionLabelsType = {
  useGrocerySetPrice: 'Использовать стоимость продуктового набора',
}

type Props = {
  disabled?: boolean
}

const SectionComplexes = ({ disabled }: Props) => {
  const {
    generalSettings,
    loading,
    modified,
    submit,
    request,
    update,
  } = useEditGeneralSettings()

  const [saving, setSaving] = useState(false)
  const popup = usePopup()

  const settingsItems = toPairs(generalSettings)

  const handleSubmit = useCallback(async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSaving(true)
    const res = await submit()
    if (res) {
      popup('Настройки продукции успешно сохранены')
    }
    setSaving(false)
  }, [submit, popup])

  const formRef = useRef<HTMLFormElement | null>(null)
  const handleSave = useCallback(() => {
    if (formRef.current) {
      const submitButton = formRef.current
        .submit as unknown as HTMLButtonElement
      submitButton.click()
    }
  }, [])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.currentTarget
      update(value, checked)
    },
    [update],
  )

  return (
    <>
      <Sticky>
        {(ref, _, shadow) => (
          <PageTemplateContent
            horizontalLayout
            shadowBottom={shadow}
            reference={ref}
          >
            <Header2 skipMargin>{'Комплекты'}</Header2>
            {modified && !disabled && (
              <SaveCancelButtons
                onSave={handleSave}
                onCancel={request}
                disabled={loading || saving}
                saving={saving}
                cancelling={modified && loading}
              />
            )}
          </PageTemplateContent>
        )}
      </Sticky>

      <PageTemplateContent>
        <form onSubmit={handleSubmit} ref={formRef}>
          {settingsItems.map(([id, value]) => (
            optionLabels[id] ? (
              <PlainCheckbox
                key={id}
                value={id}
                label={optionLabels[id]}
                disabled={loading || saving || disabled}
                checked={!!value}
                onChange={handleChange}
              />
            ) : null
          ))}

          <button type="submit" name="submit" className={styles.hidden} disabled={disabled}>
            {'Сохранить'}
          </button>
        </form>

        <Prompt
          when={modified}
          message="Вы не сохранили изменения, перейти в любом случае?"
        />
      </PageTemplateContent>
    </>
  )
}

export default SectionComplexes
