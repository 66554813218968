// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as orgsOrUser } from 'app/components/common/reports/filters/orgsOrUser';


const commonBalanceActivity = (state: State) => {
  return (
    orgsOrUser.filterActive(state)
      ? orgsOrUser.byOrgsSelected(state)
      : true
  );
};

export default commonBalanceActivity;
