// @flow
import React, { type Node, Fragment, useMemo } from 'react';
import { map, values, nth } from 'ramda';

import { Table, useCopyColumnsWidths } from 'app/common/ui-components/table';
import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow';
import Pager from 'app/common/ui/Paging';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import type { Paging } from 'app/common/hooks/usePagingExtended';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Dish as CommonDish } from 'app/dataTypes/Dishes.types';
import Loading from 'app/common/components/Loader';
import type { Categories } from 'app/dataTypes/vending/production/Categories.types';
import { useRelatedOrgs } from 'app/dataProviders/VendingDataProvider';

import type { ColumnVisibility } from './columns';
import HeaderRow from './HeaderRow';
import Row from './Row';
import useHeaderCellsData from './useHeaderCellsData';
import useRowCellsData from './useRowCellsData';

import styles from './ProductsTable.scss';


type RelatedOrg = { id: null | string, title: string }

type Props = {
  dishes: Array<Dish>,
  categories: Categories,
  loading: boolean,
  total: number,
  paging: Paging,
  columnSettings: ColumnVisibility,
  selection: Map<string, boolean>,
  selectedAll: boolean,
  selectItem: (id: string, checked: boolean) => void,
  onDelete: (dish: Dish) => Promise<boolean> | boolean,
  onUpdateDish: (dish: Dish) => void,
  onUpdateDishAsCommon: (dish: CommonDish) => void,
}

const ProductsTable = ({
  dishes,
  categories,
  loading,
  total,
  paging,
  columnSettings,
  selection,
  selectedAll,
  selectItem,
  onDelete,
  onUpdateDish,
  onUpdateDishAsCommon,
}: Props): Node => {
  const { pageSize, currentPage, onPage } = paging;
  const headerCells = useHeaderCellsData(columnSettings);

  const categoryTitlesMapById = useMemo(() => {
    return new Map(map(({ id, title }) => ([id, title]), categories));
  }, [categories]);

  const rowsData = useRowCellsData(dishes, columnSettings, categoryTitlesMapById);

  const relatedOrgs = useRelatedOrgs();
  const relatedOrg: RelatedOrg = useMemo(() => {
    const { id, title } = nth(0, values(relatedOrgs)) || { id: null, title: '' };
    return { id: id ? String(id) : null, title };
  }, [relatedOrgs]);

  const [refFrom, refTo] = useCopyColumnsWidths(rowsData);

  return (
    <Fragment>
      <div className={styles.headerContainer}>
        <Table className={styles.table}>
          <thead>
            <HeaderRow
              data={headerCells}
              reference={refTo}
              onChange={selectItem}
              checked={selectedAll}
            />
          </thead>
        </Table>
        {loading
          && total > 0
          && <Loading className={styles.loadingOverContent} />}
      </div>

      <ScrollbarsWShadow
        fillAllSpace
      >
        {loading
          && total === 0
          && <Loading />}

        {!loading
          && total === 0
          && <EmptyWarn>
            {'Нет записей, удовлетворяющих условиям'}
          </EmptyWarn>}

        {total > 0
          && <Table className={styles.table}>
            <thead>
              <HeaderRow
                data={headerCells}
                reference={refFrom}
                checked={false}
                hidden
                dummy
              />
            </thead>

            <tbody>
              {rowsData.map((cells, i) => {
                const { id, title, foodItemId } = dishes[i];

                return (
                  <Row
                    key={id}
                    id={id}
                    foodItemId={foodItemId}
                    title={title}
                    relatedOrg={relatedOrg}
                    cells={cells}
                    disabled={loading}
                    onChange={selectItem}
                    onDelete={onDelete}
                    checked={selection.get(id)}
                    onUpdateDish={onUpdateDish}
                    onUpdateDishAsCommon={onUpdateDishAsCommon}
                  />
                );
              })}
            </tbody>
          </Table>}
      </ScrollbarsWShadow>

      <div className={styles.footer}>
        <Pager
          total={total}
          pageSize={pageSize}
          currentPage={currentPage}
          currentPageSize={dishes.length}
          onPage={onPage}
        />
      </div>
    </Fragment>
  );
};

export default ProductsTable;
