// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import SettingsGear from 'app/common/components/Settings';
import IconCross from 'app/common/icons/IconCross';

import styles from './TableWin.scss';


type Props = {
  children: React.Node,
  onClose: (e: SyntheticEvent<HTMLElement>) => void,
  title: string,
}

const TableWin = ({ children, onClose, title }: Props) => (<div className={styles.root}>
  <div className={styles.header}>
    <h2 className={styles.title}>{title}</h2>
    <SettingsGear
      dataKey="history"
      title="Количество записей"
      className={styles.headerIcon}
    />
    <Link to="close" className={styles.headerIcon} onClick={onClose}>
      <IconCross className={styles.iconClose} />
    </Link>
  </div>
  {children}
</div>);

export default TableWin;
