// @flow
import type { Request } from '../../index';


type Params = {
  school_id: string,
  date: string,
  complex_id: Array<string>,
}

type Result = {
  result: 'success',
  [id: string]: string,
}


export const GET_COMPLEX_PHOTO: Request<
  Params,
  Result,
  void
> = [
  'food/menu/complex_photo',
  'get',
  ['data'],
];
