export const REMOVE_ALL_POPUPS = 'REMOVE_ALL_POPUPS';
export const REMOVE_FIRST_POPUP = 'REMOVE_FIRST_POPUP';
export const REMOVE_POPUP = 'REMOVE_POPUP';
export const POPUP_MESSAGE = 'POPUP_MESSAGE';
export const TEST_FAIL = 'TEST_FAIL';


// Удаление первого сообщения
export const clearPopups = () => ({
  type: REMOVE_ALL_POPUPS,
});

// Удаление первого сообщения
export const removeFirstPopup = () => ({
  type: REMOVE_FIRST_POPUP,
});

// Удаление первого сообщения
export const removePopup = n => ({
  type: REMOVE_POPUP,
  payload: n,
});

// Всплывающее сообщение
export const popup = msg => ({
  type: POPUP_MESSAGE,
  payload: {
    msg,
  },
});

// Всплывающее сообщение об ошибке
export const popupErr = err => ({
  type: POPUP_MESSAGE,
  payload: {
    err,
  },
});

// Всплывающее предупреждение
export const popupWarn = warn => ({
  type: POPUP_MESSAGE,
  payload: {
    warn,
  },
});

// Тестовое сообщение об ошибке
export const testErrorPopup = () => ({
  type: TEST_FAIL,
  payload: {
    err: `Тестовое сообщение об ошибке ${new Date().getUTCMilliseconds()}`,
  },
});

// Тестовое предупреждение
export const testWarningPopup = () => ({
  type: TEST_FAIL,
  payload: {
    warn: `Что-то пошло не так ${new Date().getUTCMilliseconds()}`,
  },
});

// Тестовое сообщение
export const testMessagePopup = () => ({
  type: TEST_FAIL,
  payload: {
    msg: `Все системы работают нормально ${new Date().getUTCMilliseconds()}`,
  },
});
