// @flow
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';
import { arrangeByArray } from 'app/common/lib/sort';

import orgTypesFilter, { arrange, orgTypeItems } from 'app/components/common/reports/filters/orgType';
import orgsFilter, { orgItems, orgActivity } from 'app/components/common/reports/filters/org';
import yearFilter, { yearItems, yearActivity } from 'app/components/common/reports/filters/year';
import buildingFilter, { buildingItems, buildingActivity } from 'app/components/common/reports/filters/building';
import sellPointFilter, { sellPointItems, sellPointActivity } from 'app/components/common/reports/filters/sellPoint';
import customerTypeFilter, { onlyStudents, customerTypeActivity } from 'app/components/common/reports/filters/customerType';
import fundFilter, { fundItems } from 'app/components/common/reports/filters/fund';
import sellTypeFilter, { onlyComplex } from 'app/components/common/reports/filters/sellType';
import mailFilter, { mailItems, mailProps } from 'app/components/common/reports/filters/mail';
import foodModeFilter, { foodModeItems, foodModeActivity } from 'app/components/common/reports/filters/foodMode';

import dateFilter, { dateItems, dateProps } from './date';


type Params = {
  email: string | null
}

const filterItems: Descriptions<Params> = [{
  filterId: orgTypesFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Тип заведения',
    header: 'Выбор типа заведения',
    sortFunc: arrangeByArray(arrange, prop('id')),
  },
  getItemsAndCurrent: orgTypeItems,
  relations: [orgsFilter],
}, {

  /* фильтр по заведению */
  filterId: orgsFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Заведение',
    header: 'Выбор заведения',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: orgItems,
  relations: [yearFilter, buildingFilter, sellPointFilter, customerTypeFilter],
  activity: orgActivity,
}, {

  /* фильтр по корпусу */
  filterId: buildingFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Корпус',
    header: 'Выбор корпуса',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: buildingItems,
  relations: [sellPointFilter],
  activity: buildingActivity,
}, {

  /* фильтр по точке продаж */
  filterId: sellPointFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Точка продаж',
    header: 'Выбор точки продаж',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: sellPointItems,
  activity: sellPointActivity,
}, {

  /* фильтр по типу покупателей */
  filterId: customerTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Тип покупателя',
    header: 'Выбор типа покупателя',
  },
  getItemsAndCurrent: onlyStudents,
  activity: customerTypeActivity,
}, {

  /* фильтр по виду средств */
  filterId: fundFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Вид средств',
    header: 'Выбор вида средств',
  },
  getItemsAndCurrent: fundItems,
}, {

  /* фильтр по учебному году */
  filterId: yearFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Учебный год',
    header: 'Выбор учебного года',
    sortFunc: sort(descend(prop('title'))),
  },
  getItemsAndCurrent: yearItems,
  relations: [dateFilter],
  activity: yearActivity,
}, {

  /* фильтр по дате */
  filterId: dateFilter,
  filterType: filterTypes.PLAIN_DATE,
  getProps: dateProps,
  getItemsAndCurrent: dateItems,
}, {

  /* фильтр по типу продаж */
  filterId: sellTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Тип продаж',
    header: 'Выбор типа продаж',
  },
  getItemsAndCurrent: onlyComplex,
}, {

  /* фильтр режима питания */
  filterId: foodModeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Режим питания',
    header: 'Выбор режима питания',
  },
  getItemsAndCurrent: foodModeItems,
  activity: foodModeActivity,
}, {

  /* фильтр отправки на email */
  filterId: mailFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  getProps: mailProps,
  getItemsAndCurrent: mailItems,
}];

export default filterItems;
