import { CompensationInfo, CompensationInfoParams, CompensationInfoStatus } from 'app/dataTypes/compensation/info'

import type { Request } from '../../../index'
import { UrlParams } from '../_common'


type ApiParams = {
  year: number
  month: number
  extended: boolean
}

export const convertParams = (params: CompensationInfoParams): [ApiParams, UrlParams] => {
  const { orgID, year, month, extended } = params
  return [{
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    extended: !!extended,
  }, {
    orgID,
  }]
}

type ApiResult = {
  result: 'success'
  status: 0
} | {
  result: 'success'
  status: 1
  current_progress: number
  handler_id: number
} | {
  result: 'success'
  status: 2
  students_count: number
  total_sum: number
  allowances?: Array<{
    allowance: string
    students_count: number
    total_sum: number
  }>
}

export const convertResult = (result: ApiResult, params: CompensationInfoParams): CompensationInfo => {
  const { status } = result
  const { orgID, orgTitle } = params
  const commonParams = { orgID, orgTitle }
  if (status === 1) {
    const { current_progress: currentProgress, handler_id: handlerID } = result
    const progress = parseInt(String(currentProgress), 10)
    if (progress >= 0) {
      return {
        ...commonParams,
        status: CompensationInfoStatus.InProgress,
        handlerID: String(handlerID),
        progress,
      }
    }
    return {
      ...commonParams,
      status: CompensationInfoStatus.Error,
      handlerID: String(handlerID),
    }
  }

  if (status === 2) {
    const { students_count: studentsCount, total_sum: totalSum, allowances: llwncs } = result
    const allowances = (
      llwncs && llwncs instanceof Array
        ? llwncs.map(({ allowance, students_count: stdntsCnt, total_sum: ttlSm }) => ({
          allowanceID: allowance,
          studentsCount: stdntsCnt,
          totalSum: ttlSm,
        }), llwncs)
        : null
    )
    return {
      ...commonParams,
      status: CompensationInfoStatus.Ready,
      studentsCount,
      totalSum,
      allowances,
    }
  }

  return {
    ...commonParams,
    status: CompensationInfoStatus.Default,
  }
}

export const GET_COMPENSATION_INFO: Request<CompensationInfoParams, CompensationInfo, UrlParams> = [
  'food/compensation/{orgID}/info',
  'get',
  ['data'],
]
