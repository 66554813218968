// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as group } from './group';
import { flags as groupComplexesSpecial } from './groupComplexesSpecial';


const activity = (state: State) => {
  return (
    group.byAnyComplexesNotSelected(state)
    && groupComplexesSpecial.byAnyComplexesNotSelected(state)
  );
};

export default activity;
