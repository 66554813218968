// @flow
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import isEmpty from 'ramda/es/isEmpty';

import Button from 'app/common/ui/Button';
import Tooltip from 'app/common/ui/Tooltip';

import useEditList from './useEditList';
import useFlagAllSelected from './useFlagAllSelected';
import InfoItem from './InfoItem';
import styles from './EditList.scss';


type Props = {
  items: Object,
  existingItems?: Object,
  onSubmit: (Array<number>, Array<number>) => void,
  children: (
    addList: Object,
    removeList: Object,
    addToSelection: Function,
    removeFromSelection: Function,
    addToSelectionAll?: Function,
    removeFromSelectionAll?: Function,
    selectedAll?: boolean
  ) => React.Node,
  loading?: boolean,
  closePath: string,
  submitBtTitle: string,
  alreadyLegendLabel: string,
  selectedLegendLabel: string,
  tooltipText: string,
};

const EditList = ({
  items,
  existingItems = {},
  onSubmit,
  children,
  loading,
  closePath,
  submitBtTitle,
  alreadyLegendLabel,
  selectedLegendLabel,
  tooltipText,
}: Props) => {
  const [{
    addList,
    removeList,
    submitting,
  }, {
    addToSelection,
    removeFromSelection,
    addToSelectionAll,
    removeFromSelectionAll,
    handleSubmit,
  }] = useEditList(items, existingItems, onSubmit);

  const selectedAll = useFlagAllSelected(items, existingItems, addList);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.items}>
          {typeof children === 'function'
            ? children(
              addList,
              removeList,
              addToSelection,
              removeFromSelection,
              addToSelectionAll,
              removeFromSelectionAll,
              selectedAll,
            )
            : children}
        </div>
        <div className={styles.info}>
          <Scrollbars>
            <InfoItem key={1} items={addList} title="Будет добавлено" className={styles.infoItem} />
            <InfoItem key={2} items={removeList} title="Будет удалено" className={styles.infoItem} />
          </Scrollbars>
        </div>
      </div>

      <div className={styles.footer}>
        <form onSubmit={handleSubmit}>
          <Tooltip
            text={isEmpty(addList) && isEmpty(removeList) ? tooltipText : null}
            className={styles.footerTooltip}
          >
            <Button
              value={submitting ? 'Сохранение...' : submitBtTitle}
              disabled={loading || submitting || (isEmpty(addList) && isEmpty(removeList))}
              submit
            />
          </Tooltip>
          <Button value="Отмена" outline className={styles.cancelBt} href={closePath} />
        </form>
        <div className={styles.legend}>
          <div>
            <span className={styles.alreadyLegend} />
            {alreadyLegendLabel}
          </div>
          <div>
            <span className={styles.selectedLegend} />
            {selectedLegendLabel}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditList;
