import api from 'app/actions/api';


export const DISHES_FIELD_EDIT_REQUEST = 'DISHES_FIELD_EDIT_REQUEST';
export const DISHES_FIELD_EDIT_SUCCESS = 'DISHES_FIELD_EDIT_SUCCESS';
export const DISHES_FIELD_EDIT_FAILURE = 'DISHES_FIELD_EDIT_FAILURE';

const dishesFieldEditRequest = fieldName => ({
  type: DISHES_FIELD_EDIT_REQUEST,
  payload: fieldName,
});

const dishesFieldEditSuccess = payload => ({
  type: DISHES_FIELD_EDIT_SUCCESS,
  payload,
});

const dishesFieldEditFailure = payload => ({
  type: DISHES_FIELD_EDIT_FAILURE,
  payload,
});

export const dishesFieldEdit = (id, field, value, types) => async (dispatch) => {
  dispatch(dishesFieldEditRequest(field));
  try {
    const dataToSend = { id, field, new_value: value };
    if (types) dataToSend.item_types = types;
    const { data } = await api('food/item/field', 'put', { data: dataToSend });
    const { result, ...others } = data;
    dispatch(dishesFieldEditSuccess(others));
    return true;
  } catch (error) {
    dispatch(dishesFieldEditFailure({ err: 'Изменение поля не удалось', error }));
    return false;
  }
};
