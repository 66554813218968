import { omit } from 'ramda'

import type { Rights } from 'app/common/constants/rights'

import type { Request } from '../../../index'


export type GetRightsResult = Rights & { result: 'success' } | ''

export const convertResult = (res: GetRightsResult): Rights | '' => {
  return res ? omit(['result'], res) : ''
}

export const GET_RIGHTS: Request<
  void,
  GetRightsResult,
  void
> = ['food/roles/current/rights', 'get', ['data']]
