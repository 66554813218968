// @flow
// @flow-runtime warn
import { createContext } from 'react';

import type { Common } from './settings-types';


export const defaultSettings = {
  common: ({
    currentSellPointIdByPersonId: {},
    currentSellPointByPersonIdAndSchoolId: {},
    currentBuildingId: null,
  }: Common),
  cabinet: ({}: Object),
  journal: ({}: Object),
  food: ({}: Object),
  dealer: ({}: Object),
};

type SettingsContextType = [[typeof defaultSettings, boolean], {
  requestSettings: (root: $Keys<typeof defaultSettings> | 'all' | void) => Promise<void> | void,
  saveSettingsKeyValue: (settingsSection: $Keys<typeof defaultSettings> | 'all' | null,
    key: string,
    value: mixed
  ) => Promise<void> | void,
}]

const defaultContext: SettingsContextType = [[defaultSettings, true], {
  requestSettings: () => {},
  saveSettingsKeyValue: () => {},
}];

export default createContext<SettingsContextType>(defaultContext);
