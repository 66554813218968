import { type ReactNode } from 'react'

import { Rights } from 'app/common/constants/rights'

import RightsForOrgContext from './RightsForOrgContext'
import useRightsForOrg, { type Options } from './useRightsForOrg'


type Props<T extends keyof Rights> = {
  children: ReactNode
} & Options<T>

export default function RightsForOrgProvider<T extends keyof Rights>({ children, ...options }: Props<T>) {
  const value = useRightsForOrg(options)

  return (
    <RightsForOrgContext.Provider value={value}>
      {children}
    </RightsForOrgContext.Provider>
  )
}
