// @flow
import React from 'react';

import Link from 'app/common/lib/link';
import Tabs, { TabItem } from 'app/common/ui/Tabs';

const ORDERS: 'orders' = 'orders';
const PRODUCTION: 'production' = 'production';

export const ordersSections = { ORDERS, PRODUCTION };


type Props = {
  type: 'orders' | 'production',
  baseUrl: string,
  className?: string,
}

const OrgOrdersOrProductionTabs = ({ type, baseUrl, className }: Props) => {
  return (
    <div className={className}>
      <Tabs>
        <TabItem active={type === ordersSections.ORDERS}>
          <Link to={`${baseUrl}/${ordersSections.ORDERS}`}>
            {'Заявки'}
          </Link>
        </TabItem>

        <TabItem active={type === ordersSections.PRODUCTION}>
          <Link to={`${baseUrl}/${ordersSections.PRODUCTION}`}>
            {'Продукция из заявок'}
          </Link>
        </TabItem>
      </Tabs>
    </div>
  );
};

export default OrgOrdersOrProductionTabs;
