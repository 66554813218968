
enum Status {
  Current = 'current',
  Hidden = 'hidden',
  Deleted = 'deleted'
}

export const status2TranslationMap: Map<string, string> = new Map([
  [Status.Current, 'Текущие комплекты'],
  [Status.Hidden, 'Скрытые комплекты'],
  [Status.Deleted, 'Удаленные комплекты'],
])

export const status2ColorMap: Map<string, string> = new Map([
  [Status.Current, '#4caf50'],
  [Status.Hidden, '#ef6c00'],
  [Status.Deleted, '#e91e63'],
])
