// @flow

const validatePreviewData = (data: Array<Array<any>>): boolean => {
  if (!(data instanceof Array) || data.length < 1) {
    return false;
  }
  if (!(data[0] instanceof Array) || data[0].length < 1) {
    return false;
  }

  return true;
};

export default validatePreviewData;
