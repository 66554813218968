// @flow
import { useContext } from 'react';

import OrgSettingsHistoryContext from './OrgSettingsHistoryContext';


export const useChanges = () => {
  const { changes } = useContext(OrgSettingsHistoryContext);
  return changes;
};

export const useChangesData = () => {
  const { changes: [data] } = useContext(OrgSettingsHistoryContext);
  return data;
};

export const useChangesActions = () => {
  const { changes: [, actions] } = useContext(OrgSettingsHistoryContext);
  return actions;
};

export const useLastChange = () => {
  const { lastChange } = useContext(OrgSettingsHistoryContext);
  return lastChange;
};

export const usePaging = () => {
  const { paging } = useContext(OrgSettingsHistoryContext);
  return paging;
};
