import classNames from 'classnames'

import styles from './picture.scss'


type Props = {
  alt: string,
  src: string,
  srcX2?: string,
  width?: string,
  height?: string,
  className?: string,
  pictureClassName?: string,
  onImageLoaded?: () => void,
}

const Picture = ({
  alt,
  src,
  srcX2,
  width,
  height,
  className,
  pictureClassName,
  onImageLoaded,
}: Props) => {
  return (
    <picture className={pictureClassName}>
      <img
        className={classNames(styles.root, className)}
        src={src}
        srcSet={srcX2 && [`${src} 1x`, `${srcX2} 2x`].join(', ')}
        alt={alt}
        width={width || 'auto'}
        height={height || 'auto'}
        onLoad={onImageLoaded}
      />
    </picture>
  )
}

export default Picture
