// @flow
import React, { useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames/bind';

import { Td, TrExpandable, useCheckStateContext, getPropsHeader } from 'app/common/ui-components/table';

import type { Item } from '../useOrgsOrdersTableItems';
import { statuses } from '../useOrdersData';
import ExpandedContent from './ExpandedContent';
import type { Columns } from '../columns';
import useCellsContent from './useCellsContent';

import styles from './OrgsOrderTableRow.scss';


const cx = classNames.bind(styles);

type Props = {
  index: number,
  orgId: number,
  disabled?: boolean,
  updateRowNode: (?HTMLTableRowElement, number) => void,
  node: ?HTMLTableRowElement,
  columnIds: Columns,
  requestTopShift: (index: number) => number,
  item: Item,
}

const OrgsOrderTableRow = ({
  index,
  orgId,
  disabled,
  updateRowNode,
  node,
  columnIds,
  requestTopShift,
  item,
}: Props) => {
  const { id, status } = item;
  const headers = getPropsHeader();
  const idCurrent = String(id);

  const [expandedId, setExpandedId] = useCheckStateContext();

  const content = useCellsContent(columnIds, item);

  const expanded = expandedId === idCurrent;
  const notExpanded = Boolean(expandedId && expandedId !== idCurrent);
  const classByStatus = { [`status${String(status)}`]: true };


  const headerDrawing = useRef(null);
  const reference = useCallback((el: ?HTMLTableRowElement) => {
    updateRowNode(el, index);
  }, [index, updateRowNode]);


  const calculateHeader = useCallback(() => {
    if (expanded && node) {
      const trElem = node;
      const {
        top: trTop,
        bottom: trBottom,
      } = trElem.getBoundingClientRect();
      const height = trBottom - trTop;

      if (headerDrawing.current) {
        const headerDrawingElem = headerDrawing.current;
        const top = requestTopShift(index);
        headerDrawingElem.style.top = `${top}px`;
        headerDrawingElem.style.height = `${height}px`;
      }
    }
  }, [expanded, index, requestTopShift, node]);


  // on expand
  useEffect(() => {
    calculateHeader();
  }, [expanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <TrExpandable
      className={styles.root}
      reference={reference}
      id={idCurrent}
      disabled={disabled}
      expandedContent={
        <ExpandedContent
          orgId={orgId}
          orderId={id}
          className={cx(styles.expandedContent, classByStatus)}
        />
      }
      expanded={expanded}
      onExpand={setExpandedId}
      tinted={notExpanded || (!expandedId && status === statuses.STATUS_DELETED)}
      tableRows={content.length + 1}
      classNameExpanded={cx(styles.expandedHeader, classByStatus)}
      hoverHighlighted={notExpanded}
      interlineBorder
      alignTop
    >
      {columnIds.map((columnId, i) => (
        <Td
          key={columnId}
          alignRight={columnId === 'opt'}
          {...headers(columnId)}
        >
          {content[i]}
        </Td>
      ))}
      {expanded
        && <td
          className={styles.headerDrawing}
          aria-hidden="true"
          ref={headerDrawing}
        />}
    </TrExpandable>
  );
};

export default OrgsOrderTableRow;
