// @flow
import { useCallback, type Ref, useRef } from 'react';

import useCombinedRefs from './useCombinedRefs';

/**
 *  Скрытие фокуса элемента, если событие пришло с мыши
 */

export default function useBlurOnMouseEventRef(
  externalRef?: Ref<any>,
  onClick?: (e: SyntheticEvent<any>) => void | Promise<void>,
) {
  const elemRef = useRef<HTMLElement | null>(null);

  const combinedRef = useCombinedRefs(externalRef, elemRef);

  const handleClick = useCallback((e: SyntheticMouseEvent<any>) => {
    if (onClick) {
      onClick(e);
    }
    const { clientX, clientY } = e;
    if (combinedRef.current && clientX !== 0 && clientY !== 0) {
      combinedRef.current.blur();
    }
  }, [onClick, combinedRef]);

  return [combinedRef, handleClick];
}
