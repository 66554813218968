// @flow
import React, { type Node, useCallback, useState } from 'react';
import trim from 'ramda/es/trim';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainInputFocused from 'app/common/ui/PlainInput/PlainInputFocused';

import styles from './AddCategory.scss';


type Props = {
  children: (HandleModalDisplayed) => Node,
  onSubmitCategory: (string, (string) => void) => Promise<boolean>
}

const AddCategory = ({ children, onSubmitCategory }: Props) => {
  const [categoryName, setCategoryName] = useState('');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleEdit = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setError('');
    setCategoryName(value);
  }, []);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const res = await onSubmitCategory(trim(categoryName), setError);
    setSubmitting(false);
    return res;
  }, [categoryName, onSubmitCategory]);


  return (
    <PlainModal
      controlBy={children}
      onSubmit={handleSubmit}
    >
      <PlainModalHeader>{'Добавить новую категорию'}</PlainModalHeader>
      <PlainModalContent>
        <PlainInputFocused
          className={styles.input}
          placeholder="Имя категории"
          onChange={handleEdit}
          disabled={submitting}
          value={categoryName}
          isError={!!error}
          errorText={error}
        />
      </PlainModalContent>
      <PlainModalFooter>
        {handleModalDisplayed => (
          <ControlsBar middle>
            <PlainButton type="submit" disabled={!categoryName}>
              {submitting ? 'Добавление...' : 'Добавить'}
            </PlainButton>

            <PlainButton
              outline
              onClick={handleModalDisplayed}
            >
              {'Отмена'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  );
};

export default AddCategory;
