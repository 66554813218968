// @flow
import * as React from 'react';
import moment from 'moment-timezone';
import MaskedInput from 'react-maskedinput';
import classNames from 'classnames/bind';

import DatePicker from 'app/common/ui/DatePicker';
import withDateInput from 'app/common/containers/withDateInput';

import styles from './LineDateInput.scss';


const cx = classNames.bind(styles);

type Props = {
  value: string,
  handleChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  handleBlur: (e: SyntheticEvent<HTMLInputElement>) => void,
  name?: string,
  id?: string,
  placeholder?: string,
  errorText?: string,
  disabled?: boolean,
  loading?: boolean,
  locked?: boolean,
  className?: string,
  errorClassName?: string,
  bold?: boolean,
  align?: string,
  momentDate: typeof moment,
  handleDayPick: (date: typeof Date) => void,
  disabledDaysInPicker: (date: typeof Date) => boolean,
  mask: string,
  fieldFormat: string,
  update: boolean,
  setUpdate: (boolean) => void,
  openOn?: typeof Date,
};

type State = {
  isFocus: boolean,
};

class LineDateInput extends React.Component<Props, State> {
  state = {
    isFocus: false,
  };

  componentDidUpdate(prevProps) {
    const { update, setUpdate } = this.props;
    if (update && !prevProps.update) {
      setUpdate(false);
    }
  }

  handleBlur = (e) => {
    const { handleBlur, setUpdate } = this.props;
    this.setState({ isFocus: false });
    setUpdate(true);
    handleBlur(e);
  };

  handleFocus = () => {
    this.setState({ isFocus: true });
  };

  input: ?HTMLInputElement;

  render() {
    const {
      value,
      handleChange,
      handleBlur,
      name,
      id,
      placeholder,
      errorText,
      disabled,
      loading,
      locked,
      className,
      errorClassName,
      bold,
      align,
      momentDate,
      handleDayPick,
      disabledDaysInPicker,
      mask,
      fieldFormat,
      update,
      setUpdate,
      openOn,
      ...other
    } = this.props;
    const alignClass = align || 'center';
    const { isFocus } = this.state;
    const defaultOpen = openOn && openOn instanceof Date ? openOn : new Date();

    return (
      <div className={className}>
        <div className={styles.main}>
          <label
            htmlFor={name}
            className={cx({
              label: true,
              focused: isFocus || value,
              disabled: disabled || loading,
              [alignClass]: true,
            })}
          >
            {placeholder}
          </label>
          {!update
            && <MaskedInput
              mask={mask}
              className={cx({
                input: true,
                iconed: true,
                empty: !isFocus && !value,
                // disabled: disabled || loading,
                [alignClass]: true,
              })}
              name={name}
              id={name}
              value={momentDate ? momentDate.format(fieldFormat) : ''}
              onChange={handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur} // не работает из-за ремаунта? TODO зменить MaskedInput на свой компонент
              disabled={disabled || loading}
              {...other}
            />}
          <DatePicker
            name={name || id}
            day={momentDate ? momentDate.toDate() : defaultOpen}
            className={styles.datePicker}
            onChangeDay={handleDayPick}
            disabledDays={disabledDaysInPicker}
            highlighted={!disabled}
            disabled={disabled || loading}
            showYearMonthSelector
          />
        </div>
        {!locked
          && <div
            className={cx({
              line: true,
              bold,
              active: !disabled && (isFocus || value),
              error: !!errorText,
            })}
          />}
        <div
          className={cx(
            styles.errorText,
            styles[alignClass],
            errorClassName,
          )}
        >
          {errorText || '\xa0'}
        </div>
      </div>
    );
  }
}

export default withDateInput(LineDateInput);
