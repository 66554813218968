// @flow
import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';

import filterGetItemsCities from 'app/components/common/filter/requests/filterGetItemsCities';
import filterGetItemsDistricts from 'app/components/common/filter/requests/filterGetItemsDistricts';

import filterGetItemsMenuPresence from './filterGetItemsMenuPresence';

/**
 *  Описание фильтров
 */

const filterItems: Descriptions<> = [{

  /* фильтр по населенному пункту */
  filterId: 'city',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Населенный пункт',
    header: 'Выберите населенный пункт',
    colorInverse: true,
  },
  getItemsAndCurrent: filterGetItemsCities,
  relations: ['district'], // фильтр по району зависит от состояния этого фильтра
}, {

  /* фильтр по району */
  filterId: 'district',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Район',
    header: 'Выберите район',
    colorInverse: true,
  },
  getItemsAndCurrent: filterGetItemsDistricts,
}, {

  /* фильтр по наличию меню */
  filterId: 'menu_presence',
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  multiple: false,
  props: {
    label: 'Наличие меню на сегодня',
  },
  getItemsAndCurrent: filterGetItemsMenuPresence,
}];

export default filterItems;
