// @flow
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import isEmpty from 'ramda/es/isEmpty';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import Loading from 'app/common/components/Loader';
import { useOrgsData, useDateFrom } from 'app/dataProviders/org/OrgsDataProvider';
import { PlainItemsSelector } from 'app/common/ui-next/plain-selector';
import PlainInputDate from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import useHandles from './useHahdles';
import type { Checks } from '../CopyMenuFromToOrgs';
import useTargetsString from '../useTargetsString';
import useOrgSelector from './useOrgSelector';
import type { Errors } from '../errors';

import {
  MenuSellPointSelector,
  MenuBuildingSelector,
  useMenuTargetItemState,
  useMultipleBooleanState,
} from '../../MenuTargetSelectors';

import styles from './CopyFrom.scss';


export const dateFormat = 'YYYY-MM-DD';
const cx = classNames.bind(styles);
const targetPhraseOptions = { delimiter: ', ', delimiterLast: ' и ' };
const targetsToArray = ['корпус', 'точку продаж', 'категорию меню'];
const targetsFromArray = ['дату', 'заведение', 'корпус', 'точку продаж', 'категорию меню'];

type Props = {
  orgId: number,
  buildingId: number | null,
  sellPointId: number | null,
  date: string,
  hideControls?: boolean,
  formRef: {
    current: ?HTMLFormElement,
  },
  optionsChecks: Checks,
  onClose: (e?: SyntheticEvent<HTMLElement>) => void,
  onSubmitting?: (status: boolean) => void,
  onLoading?: (loading: boolean) => void,
  resetErrors: () => void,
  validate: () => boolean,
  errors: Errors,
}

const CopyFrom = ({
  orgId,
  buildingId,
  sellPointId,
  date,
  hideControls,
  formRef,
  optionsChecks,
  onClose,
  onSubmitting,
  onLoading,
  resetErrors,
  validate,
  errors,
}: Props) => {
  const [dateValue, setDateValue] = useState(date);
  const handleDateChange = useCallback((newDate: string) => {
    setDateValue(newDate);
    resetErrors();
  }, [resetErrors]);

  const [{ orgs: orgsState, loading: loadingOrgs }] = useOrgsData();
  const actualFromDate = useDateFrom(orgsState, { orgId });

  const [orgIdFrom, orgIdSelectorProps] = useOrgSelector(orgsState, { date: dateValue, orgId });
  const nothing = isEmpty(orgIdSelectorProps.items);

  // состояния из/в куда копировать
  const sellPointToState = useMenuTargetItemState();
  const sellPointFromState = useMenuTargetItemState();
  const buildingToState = useMenuTargetItemState();
  const buildingFromState = useMenuTargetItemState();

  const [sellPointIdTo, sellPointToSingle] = sellPointToState;
  const [sellPointIdFrom, sellPointFromSingle] = sellPointFromState;
  const [buildingIdTo, buildingToSingle] = buildingToState;
  const [buildingIdFrom, buildingFromSingle] = buildingFromState;


  // генерация строки подзаголовка "куда"
  const targetsToAllowances = useMemo(() => (
    [!buildingToSingle, !sellPointToSingle]
  ), [buildingToSingle, sellPointToSingle]);

  const targetsToString = useTargetsString(
    targetsToArray,
    targetsToAllowances,
    targetPhraseOptions,
  );


  // генерация строки подзаголовка "откуда"
  const targetsFromAllowances = useMemo(() => (
    [true, true, !buildingFromSingle, !sellPointFromSingle]
  ), [buildingFromSingle, sellPointFromSingle]);

  const targetsFromString = useTargetsString(
    targetsFromArray,
    targetsFromAllowances,
    targetPhraseOptions,
  );

  const {
    handleSubmit,
    saving,
    formId,
  } = useHandles({
    optionsChecks,
    orgIdFrom,
    currentDate: dateValue,
    orgId,
    date,
    onClose,
    onSubmitting,
    sellPointIdTo,
    sellPointIdFrom,
    buildingIdTo,
    buildingIdFrom,
    validate,
  });

  const disabledDays = useCallback((dayFormatted: string, format: string, unit?: string = 'day') => (
    actualFromDate
      ? !moment(dayFormatted, format).isAfter(actualFromDate, unit)
      : false
  ), [actualFromDate]);

  const [loading, setLoading] = useMultipleBooleanState(loadingOrgs);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit} ref={formRef} id={formId}>
        {targetsToString
          && <div className={cx(styles.subHeader, styles.subHeader__top)}>
            {`Выберите ${targetsToString} в текущем заведении, куда копировать меню`}
          </div>}

        <MenuBuildingSelector
          orgId={orgId}
          buildingState={buildingToState}
          direction="to"
          disabled={saving || loading}
          onLoading={setLoading}
          defaultState={buildingId}
        />

        <MenuSellPointSelector
          orgId={orgId}
          buildingId={buildingIdTo}
          sellPointState={sellPointToState}
          direction="to"
          disabled={saving || loading}
          onLoading={setLoading}
          defaultState={sellPointId}
        />

        <div className={styles.subHeader}>
          {`Выберите ${targetsFromString}, откуда копировать меню`}
        </div>

        <PlainLabel htmlFor="date_from">
          {'С даты'}
        </PlainLabel>

        <PlainInputDate
          id="date_from"
          value={dateValue}
          onDateChange={handleDateChange}
          dateFormat={dateFormat}
          disabled={loading || saving}
          dateDisabledStatus={disabledDays}
          error={Boolean(errors.date)}
          calendarCloseOnClick
          displayAsBlock
        />

        {errors.date
          && <PlainHelperText overflowVisible error>
            {errors.date}
          </PlainHelperText>}

        {loading
          && nothing
          && <Loading className={styles.loader} />}

        {!nothing
          && <PlainItemsSelector
            label="Из заведения"
            header="Выберите откуда копировать меню"
            loading={loading}
            disabled={saving}
            {...orgIdSelectorProps}
          />}

        {orgIdFrom
          && <MenuBuildingSelector
            orgId={orgIdFrom}
            buildingState={buildingFromState}
            direction="from"
            disabled={saving || loading}
            onLoading={setLoading}
          />}

        {orgIdFrom
          && <MenuSellPointSelector
            orgId={orgIdFrom}
            buildingId={buildingIdFrom}
            sellPointState={sellPointFromState}
            direction="from"
            disabled={saving || loading}
            onLoading={setLoading}
          />}

        {/* скрытые элементы управления формой */}
        <ControlsBar className={cx(styles.buttons, { hideControls })} right>
          <PlainButton outline onClick={onClose}>
            {'Отмена'}
          </PlainButton>
          <PlainButton type="submit" name="submit" disabled={loading || saving}>
            {saving ? 'Копирование...' : 'Копировать'}
          </PlainButton>
        </ControlsBar>

      </form>
    </div>
  );
};

export default CopyFrom;
