export const IDENTIFIER_CARD = 'card' as const
export const IDENTIFIER_KEYCHAIN = 'keychain' as const
export const IDENTIFIER_KEYCHAIN_LEATHER = 'keychain_leather' as const
export const IDENTIFIER_BRACELET_SILICONE = 'bracelet_silicone' as const
export const IDENTIFIER_BRACELET_LEATHER = 'bracelet_leather' as const
export const IDENTIFIER_BANK_CARD = 'bank_card' as const
export const IDENTIFIER_VIRTUAL_CARD = 'virtual_card' as const
export const IDENTIFIER_SOMETHING = 'something' as const
export const IDENTIFIER_RFID_TAG = 'rfid_tag' as const
export const IDENTIFIER_SBERBANK_CARD = 'sberbank_card' as const
export const IDENTIFIER_BRACELET_FITNESS = 'bracelet_fitness' as const
export const IDENTIFIER_SMART_WATCH = 'smart_watch' as const
export const IDENTIFIER_BRACELET_KHAKI = 'bracelet_khaki' as const
export const IDENTIFIER_SMARTPHONE = 'smartphone' as const
export const IDENTIFIER_COBRAND_CARD = 'cobrand_card' as const
export const IDENTIFIER_USER_PHOTO = 'user_photo' as const
export const IDENTIFIER_TINKOFF = 'tinkoff_card' as const
