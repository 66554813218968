// @flow
import React, { Fragment, useCallback } from 'react';
import classNames from 'classnames/bind';

import PlainButton from 'app/common/ui-next/plain-button';
import PlainInput from 'app/common/ui/PlainInput';

import AddingFormImage from './AddingFormImage';
import AddingFormSaleType from './AddingFormSaleType';
import AddingFormErrors from './AddingFormErrors';
import useAddingForm from './useAddingForm';
import AddingFormItem from './AddingFormItem';

import { useDishesData } from '../DishesData';
import styles from './AddingForm.scss';


const cx = classNames.bind(styles);

type SaleType = {
  id: string,
  title: string,
}

type Props = {
  saleTypes: Array<SaleType>,
  saleTypesLoading: boolean,
  lockedWithError?: string,
  onChangeNomenclature: (s: string) => void,
  onChangeTitle: (s: string) => void,
  onChangePortion: (s: string) => void,
  onChangeCost: (s: string) => void,
  onAddSuccess: () => void | Promise<void>,
}

const AddingForm = ({
  saleTypes,
  saleTypesLoading,
  lockedWithError,
  onChangeNomenclature,
  onChangeTitle,
  onChangePortion,
  onChangeCost,
  onAddSuccess,
}: Props) => {
  const [{ nomenclatureActive }] = useDishesData();

  const [{
    nomenclature,
    title,
    photo,
    portion,
    cost,
    saleType,
    submitting,
    errors,
  }, {
    submit,
  }] = useAddingForm({
    onChangeNomenclature,
    onChangeTitle,
    onChangePortion,
    onChangeCost,
    nomenclatureActive,
  });


  const handleSubmitForm = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await submit();
    if (res) {
      // обновление основного списка блюд
      onAddSuccess();
    }
  }, [submit, onAddSuccess]);


  const locked = Boolean(lockedWithError);

  return (
    <Fragment>
      <div className={cx(styles.group, { error: locked })}>
        <div>{'Добавить свою продукцию'}</div>
        <form
          className={cx(styles.form, { nomenclatureLayout: nomenclatureActive })}
          onSubmit={handleSubmitForm}
        >
          {nomenclatureActive
            && (
              <AddingFormItem
                id="form_nomen"
                label="Номенклатура*"
              >
                <PlainInput
                  {...nomenclature}
                  id="form_nomen"
                  disabled={submitting}
                />
              </AddingFormItem>
            )}

          <AddingFormItem
            id="form_title"
            label="Наименование*"
          >
            <PlainInput
              {...title}
              id="form_title"
              disabled={submitting}
            />
          </AddingFormItem>

          <AddingFormItem
            id="form_photo"
            label="Фото"
            center
          >
            <AddingFormImage {...photo} />
          </AddingFormItem>

          <AddingFormItem
            id="form_portion"
            label="Порция"
          >
            <PlainInput
              {...portion}
              id="form_portion"
              disabled={submitting}
            />
          </AddingFormItem>

          <AddingFormItem
            id="form_cost"
            label="Стоимость*"
          >
            <PlainInput
              {...cost}
              id="form_cost"
              disabled={submitting}
            />
          </AddingFormItem>

          <AddingFormItem
            id="form_sale_type"
            label="Тип продукции*"
          >
            <AddingFormSaleType
              {...saleType}
              id="form_sale_type"
              loading={saleTypesLoading}
              saleTypes={saleTypes}
              disabled={submitting}
            />
          </AddingFormItem>

          <AddingFormItem
            id="submit"
          >
            <PlainButton
              type="submit"
              className={styles.button}
              disabled={submitting || locked}
            >
              {!submitting ? 'Добавить' : '...'}
            </PlainButton>
          </AddingFormItem>
        </form>
      </div>

      <AddingFormErrors
        errors={errors}
        additional={lockedWithError}
      />
    </Fragment>
  );
};

export default AddingForm;
