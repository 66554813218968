// @flow
import type { ProductData, SlotData } from 'app/common/ui-components/planograms';


export const defaultProduct: ProductData = {};
export const defaultSlot: SlotData = {};

export const extractNumber = (field: $Keys<SlotData>, currentSlot: SlotData | null): number => {
  if (currentSlot) {
    const extracted = parseInt(currentSlot[field], 10);
    if (!Number.isNaN(extracted)) {
      return extracted;
    }
  }
  return 0;
};
