import { useEffect, useState } from 'react'

import { subscibeTo } from 'app/common/data-box-store'

import Mailing, { Props as MailingProps } from './mailing'


type Props = {
  visibleFromBox: string
} & MailingProps

const MailingBox = ({ visibleFromBox, ...others }: Props) => {
  const [filterVisibility, setFilterVisibility] = useState(false)

  useEffect(() => {
    const unsubsribe = subscibeTo<boolean>(visibleFromBox, setFilterVisibility)
    return unsubsribe
  }, [visibleFromBox])

  if (filterVisibility) {
    return <Mailing {...others} />
  }

  return null
}

export default MailingBox
