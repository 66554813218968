// @flow
const canvas = document.createElement('canvas');
canvas.height = 1;
canvas.width = 1;
const context = canvas.getContext('2d');


export const toRGBA = (color: string, alpha: number = 1): Array<number> => {
  /**
   * Turns any valid canvas fillStyle into a 4-element Uint8ClampedArray with bytes
   * for R, G, B, and A. Invalid styles will return [0, 0, 0, 0]. Examples:
   * color_convert.to_rgb_array('red')  # [255, 0, 0, 255]
   * color_convert.to_rgb_array('#ff0000')  # [255, 0, 0, 255]
   * color_convert.to_rgb_array('garbagey')  # [0, 0, 0, 0]
   */
  // Setting an invalid fillStyle leaves this unchanged
  context.fillStyle = 'rgba(0, 0, 0, 0)';
  // We're reusing the canvas, so fill it with something predictable
  context.clearRect(0, 0, 1, 1);
  context.fillStyle = color;
  context.fillRect(0, 0, 1, 1);

  return [...Array.from(context.getImageData(0, 0, 1, 1).data).slice(0, 3), alpha];
};
