import { createSelector } from 'reselect';
// import path from 'ramda/es/path';

import { replaceLast } from 'app/common/lib/path';

// import selectSellPoints from './selectSellPoints';
// import selectCategories from './selectCategories';

const selectProps = createSelector([
  // selectSellPoints,
  // selectCategories,
  // state => state.orgs.menu.sellPoints,
  // state => state.orgs.menu.categories,

  state => state.orgs.dishes.items,
  state => state.orgs.kits.items,

  state => state.orgs.menu.items,
  state => state.orgs.menu.kits,

  // (_, { categoryId }) => categoryId,
  (_, { sellPointId }) => sellPointId,
  (_, { mDate }) => mDate,
  (_, { pathname }) => pathname,

  state => state.settings.orgsMenuEdit,
], (
  // sellPoints, // hook ->
  // categories, // hook ->
  // sellPointsState, // hook ->
  // categoriesState, // hook ->
  // в диалоге для добавления
  dishesState,
  kitsState,
  // в меню уже добавленные
  orgsMenuItemsState,
  orgsMenuKitsState,

  // categoryId,
  sellPointId,
  mDate,
  pathname,

  settings,
) => ({
  // sellPoints, // hook ->
  // categories, // hook ->
  // sellPointsState, // hook ->
  // categoriesState, // hook ->
  dishesState,
  kitsState,
  orgsMenuItemsState,
  orgsMenuKitsState,
  // currentCategoryName: [
  //   `Точка продаж: «${path([sellPointId, 'title'], sellPointsState) || '...'}»`,
  //   `Категория меню: «${path([categoryId, 'title'], categoriesState) || '...'}»`,
  // ], // hook ->
  // header: (mDate && mDate.isValid()) ? `Меню на ${mDate.format('D MMMM YYYY')}` : 'Меню',
  returnTo: replaceLast(pathname),
  settings,
}));

export default selectProps;
