// @flow
import React from 'react';

import Picture from 'app/common/ui-next/picture';

import styles from './logo.scss';


type Props = {
  logoUrl: string,
  alt: string,
}

const Logo = ({ logoUrl, alt }: Props) => {
  return (
    /empty.png$/.test(logoUrl)
      ? null
      : <Picture alt={alt} src={logoUrl} className={styles.root} />
  );
};

export default Logo;
