import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import styles from './panel.scss'


const TRANSITION_OUT_TIME = 300 as const

type Props = {
  className?: string
  children: (activeActual: boolean, updateSize: () => void) => ReactNode
  active: boolean
  style: Record<string, number | string>
}

const Panel = ({ className, children, active, style }: Props) => {
  const [transition, setTransition] = useState(active)

  const refEl = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState(0)

  const updateSize = useCallback(() => {
    setHeight(refEl.current?.scrollHeight || 0)
  }, [])

  useEffect(() => {
    if (active) {
      setHeight(refEl.current?.scrollHeight || 0)
      setTransition(active)
    } else {
      setTimeout(() => {
        setTransition(active)
      }, TRANSITION_OUT_TIME)
      setHeight(0)
    }
  }, [active])

  return (
    <div
      className={cn(styles.root, className)}
      ref={refEl}
      style={{ ...style, maxHeight: height }}
    >
      {children(active || transition, updateSize)}
    </div>
  )
}

export default Panel
