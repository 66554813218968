// @flow
import React, { type Node } from 'react';

import { api } from 'app/api';
import PlainLink from 'app/common/ui-next/plain-link';

import type { Payment } from '../useReportData';
import { columns } from './columns';
import styles from './RowCell.scss';


type Props = {
  columnId: $Values<typeof columns>,
  actualPayment: Payment
}

const RowCell = ({ columnId, actualPayment }: Props): Node => {
  const {
    id,
    source,
    receiverPerson,
    receiverDescription,
    date,
    sum,
    comment,
    receipt = '',
  } = actualPayment;

  const receiptLink = `${api.baseURL}${receipt.replace(/^(\/rest\/|\/)/, '')}`;

  switch (columnId) {
    case columns.ID:
      return id;

    case columns.SOURCE:
      return source;

    case columns.RECEIVER:
      return (
        <div>
          <div>{receiverPerson}</div>
          {receiverDescription
            && <div className={styles.dipper}>
              {receiverDescription}
            </div>}
        </div>
      );

    case columns.DATE:
      return date;

    case columns.SUM:
      return (
        <div>
          <div>{sum}</div>
          {receipt
            && <div>
              <PlainLink to={receiptLink} external>
                {'Чек'}
              </PlainLink>
            </div>}
        </div>
      );

    case columns.COMMENT:
      return comment;

    default:
      return null;
  }
};

export default RowCell;
