// @flow
import React, { useEffect } from 'react';

import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';

import useSellPointsData from '../../commonHooks/useSellPointsData';
import type { MenuTargetItemState } from './useMenuTargetItemState';
import useMenuTargetItemHandling from './useMenuTargetItemHandling';

/**
 *  Выбор точки продаж для меню
 */

type Props = {
  orgId: number,
  buildingId: number | null,
  direction: 'from' | 'to',
  sellPointState: MenuTargetItemState,
  disabled?: boolean,
  onLoading?: (state: boolean) => void,
  defaultState?: number | null,
}

const MenuSellPointSelector = ({
  orgId,
  buildingId,
  sellPointState,
  direction,
  disabled,
  onLoading,
  defaultState,
}: Props) => {
  const [[{ itemsCurrent: sellPoints }, sellPointsLoading]] = useSellPointsData(orgId, buildingId);

  const [sellPointCurrent, sellPointsCurrentSingle] = sellPointState;
  const handleRadioButtonChange = useMenuTargetItemHandling(
    sellPointState,
    sellPoints,
    defaultState,
  );

  const unicSetId = `sellPoints_${direction}`;

  useEffect(() => {
    if (onLoading) {
      onLoading(sellPointsLoading);
    }
  }, [sellPointsLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  if (sellPointsCurrentSingle) {
    return null;
  }

  return (
    <PlainFieldset
      legend={direction === 'from' ? 'Из точки продаж' : 'В точку продаж'}
      name={unicSetId}
    >
      {sellPoints.map(({ id, title }) => (
        <PlainRadioButton
          key={id}
          value={String(id)}
          checked={sellPointCurrent === id}
          label={title}
          name={unicSetId}
          disabled={sellPointsLoading || disabled}
          onChange={handleRadioButtonChange}
        />
      ))}
    </PlainFieldset>
  );
};

export default MenuSellPointSelector;
