// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import useBlurOnMouseEvent from 'app/common/hooks/useBlurOnMouseEvent';

import styles from './plain-toggle.scss';


const cx = classNames.bind(styles);

export type PlainToggleProps = {
  checked: boolean,
  value: string,
  disabled?: boolean,
  name?: string,
  id?: string,
  className?: string,
  label?: string,
  labelClassName?: string,
  coloredReverse?: boolean, // deprecated use invertColors
  invertColors?: boolean,
  bottomSpace?: boolean,
  prefix?: Node | string | null,
  suffix?: Node | null,
  suffixClassName?: string,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
}

const PlainToggle = ({
  checked,
  value,
  disabled,
  name,
  id,
  className,
  label,
  labelClassName,
  coloredReverse,
  invertColors,
  bottomSpace,
  prefix = 'Вкл',
  suffix,
  suffixClassName,
  onChange,
}: PlainToggleProps) => {
  const currentId = id || `${name || ''}_${value}`;
  const [elemRef, handleClick] = useBlurOnMouseEvent();

  return (
    <label
      htmlFor={currentId}
      className={cx(styles.root, { disabled }, className)}
    >
      {label
        && <span className={cx(styles.label, { disabled }, labelClassName)}>
          {label}
        </span>}
      <span className={styles.mainGroup}>
        {prefix
          && (typeof prefix === 'string'
            ? <span className={cx(styles.prefixDefault, { disabled })}>{prefix}</span>
            : <span className={styles.prefix}>{prefix}</span>)}
        <input
          name={name}
          value={value}
          disabled={disabled}
          id={currentId}
          type="checkbox"
          className={styles.input}
          onChange={onChange}
          checked={checked}
          onClick={handleClick}
          ref={elemRef}
        />
        <span className={styles.toggleElement}>
          <span className={styles.track} />
          <span className={cx(styles.thumb, { invertColors: invertColors || coloredReverse, disabled })} />
        </span>
        {suffix && <span className={cx(styles.suffix, { disabled }, suffixClassName)}>{suffix}</span>}
      </span>
      {bottomSpace && <span className={styles.bottomSpace} />}
    </label>
  );
};

export default PlainToggle;
