// @flow
import { useState, useCallback } from 'react';
import filter from 'ramda/es/filter';
import flatten from 'ramda/es/flatten';

// import { GET_MENU_CATEGORIES } from 'app/common/api/requests/food/schools/menu_categories';
// import { GET_BUILDINGS } from 'app/common/api/requests/food/schools/buildings';
import { api } from 'app/api';
import usePopupErr from 'app/common/hooks/usePopupErr';
import { menuNeedUpdate } from 'app/actions/orgs/menu/options';

import {
  type CopyMenuJob,
  POST_COPY_MENU_JOBS,
} from 'app/common/api/requests/food/menu/copy_menu_between_schools';

import requesAllSellPoints, { type SellPointsByBuildingIds } from './requesAllSellPoints';
import type { Checks } from '../CopyMenuFromToOrgs';
// import extractActualIds from './extractActualIds';

/**
 *  Hook для копирования меню внутри организации
 */

const nonEmptyFields = (value: any) => (value !== null && value !== undefined);
export const formId = 'copy_menu_inside';

type Options = {
  orgId: number,
  buildingId: number | null,
  dateFrom: string,
  datesTo: Array<string>,
  optionsChecks: Checks,
  onSubmitting?: (status: boolean) => void,
  onClose: (void) => void,
  validate: (any) => boolean,
}

export default function useCopyInside({
  orgId,
  buildingId,
  dateFrom,
  datesTo,
  optionsChecks,
  onSubmitting,
  onClose,
  validate,
}: Options) {
  const [saving, setSaving] = useState(false);
  const [popupErr, popup, dispatch] = usePopupErr();


  const setProcessing = useCallback((status: boolean) => {
    setSaving(status);
    if (onSubmitting) {
      onSubmitting(status);
    }
  }, [onSubmitting]);


  const handleSubmit = useCallback(async (e: SyntheticInputEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id } = e.target;
    // эта ли форма сабмитится
    if (id !== formId) {
      return;
    }

    if (!validate({ datesTo })) return;

    setProcessing(true);

    // все категории меню для этой организации
    // const categories = await api.request(GET_MENU_CATEGORIES, {
    //   error: 'Не удалось получить данные категорий меню при копировании',
    //   requestPathParams: { orgId },
    // });

    // if (!categories) {
    //   setProcessing(false);
    //   return;
    // }

    // все корпуса в этой организации
    // const buildings = await api.request(GET_BUILDINGS, {
    //   error: 'Не удалось получить данные корпусов при копировании меню',
    //   requestPathParams: { orgId },
    // });

    // if (!buildings) {
    //   setProcessing(false);
    //   return;
    // }

    // идентификаторы актуальных корпусов, [null] - нет корпусов (запрос будет без указания корпуса)
    // const buildingIds: Array<number> | [null] = extractActualIds(buildings, null);
    const buildingIds = [buildingId];

    // все корпуса + точки продаж
    // точки продаж зависят от корпуса, в каждом корпусе свои точки продаж
    // возвращаются уже актуальные (delete: false), null - запрос завершился с ошибкой
    const sellPointsByBuildingIds: SellPointsByBuildingIds | null = await (
      requesAllSellPoints(orgId, buildingIds)
    );

    if (!sellPointsByBuildingIds) {
      setProcessing(false);
      popupErr('Не удалось получить данные точек продаж при копировании меню');
      return;
    }

    // массив jobs на основе собранных данных
    const copyMenuJobs: Array<CopyMenuJob> = flatten(datesTo.map(dateTo => sellPointsByBuildingIds.map(({ buildingId, sellPointIds }) => sellPointIds.map(sellPointId => filter(nonEmptyFields, ({
      school_id_from: orgId,
      school_id_to: orgId,
      date_from: dateFrom,
      date_to: dateTo,
      from_building_id: buildingId,
      to_building_id: buildingId,
      from_sale_point_id: sellPointId,
      to_sale_point_id: sellPointId,
      items: optionsChecks.copyitems,
      complexes: optionsChecks.copykits,
      copy_settings: optionsChecks.copysettings,
    }))))));
    // запуск jobs
    const res = await api.request(POST_COPY_MENU_JOBS, {
      error: 'Не удалось скопировать меню',
      params: {
        jobs: copyMenuJobs,
      },
    });

    setProcessing(false);

    if (res) {
      popup('Меню было успешно скопировано');
      dispatch(menuNeedUpdate(true));
      onClose();
    }
  }, [
    datesTo,
    orgId,
    setProcessing,
    dateFrom,
    popupErr,
    optionsChecks,
    dispatch,
    onClose,
    popup,
    validate,
    buildingId,
  ]);

  return {
    saving,
    submit: handleSubmit,
  };
}
