import { useMemo } from 'react'
import { Column } from './types'


export function useColumnTitles(currency: string = '₽') {
  const columns = useMemo(() => {
    return {
      [Column.Date]: 'Дата',
      [Column.Sum]: `Сумма, ${currency}`,
      [Column.Type]: 'Тип',
      [Column.Comment]: 'Комментарий',
    }
  }, [currency])

  return columns
}
