// @flow
import { useState, useCallback, Fragment, ReactNode, ChangeEvent } from 'react'
import isEmpty from 'ramda/es/isEmpty'

import usePopup from 'app/common/hooks/usePopup'
import MultipleSubmit, { MultipleSubmitIndicator } from 'app/components/common/MultipleSubmit'
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider'

/**
 *  Дополнительная возможность сохраненмя настроек в другие организации
 *  При нажатии кнопки "Сохранить", которая обернута в этот компонент,
 *  появляется окно с выбором организаций, в которые произойдет сохранение
 */

type Props = {
  multipleAfterContent?: ReactNode,
  additionalContent?: ReactNode,
  disableMultiple?: boolean,
  onSuccess?: (res: Array<boolean>) => Promise<void> | void,
  // onSubmit?: (orgId: number) => boolean | Promise<boolean>,
  onSubmitArray?: (orgIds?: Array<number>) => boolean | Promise<boolean>,
  onChangeSubmitState: (submitting: boolean) => void,
  onShowModal?: () => boolean, // можно предварительно сделать тест на ошибки
  children: (a: () => void, b: (e: ChangeEvent<HTMLFormElement>) => void) => ReactNode,
  formId: string,
}

const MultipleSubmitSettings = ({
  multipleAfterContent,
  additionalContent,
  disableMultiple,
  onSuccess,
  // onSubmit,
  onSubmitArray,
  onChangeSubmitState,
  onShowModal,
  children,
  formId,
}: Props) => {
  const popup = usePopup()
  const [optionsModalDisplayed, setOptionsModalDisplayed] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [{ orgs, loading, currentOrg }] = useOrgsData()
  const { id: currentOrgId, title: currentOrgTitle } = currentOrg
  const onlyOneOrg = isEmpty(orgs)

  const handleMassSubmitSettingsArray = useCallback(async (orgIds: Array<number>) => {
    if (!onSubmitArray) return
    setOptionsModalDisplayed(false)

    onChangeSubmitState(true)
    setSubmitting(true)
    const res = await onSubmitArray([currentOrgId, ...orgIds])
    onChangeSubmitState(false)
    setSubmitting(false)

    if (res) {
      if (onSuccess) {
        onSuccess([true])
      }
      popup('Сохранение настроек успешно запущено')
    }
  }, [onSubmitArray, onChangeSubmitState, popup, currentOrgId, onSuccess])


  const handleToggleOptionsModal = useCallback(() => {
    setOptionsModalDisplayed(current => !current)
  }, [])


  const handleSubmitOptionsModal = useCallback((e?: ChangeEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
      const { id } = e.target
      if (id !== formId) {
        return
      }
    }
    if (!onShowModal || onShowModal()) {
      setOptionsModalDisplayed(true)
    }
  }, [formId, onShowModal])


  return (
    <Fragment>
      {children(handleToggleOptionsModal, handleSubmitOptionsModal)}
      {optionsModalDisplayed
        && (
          <MultipleSubmit
            orgs={orgs}
            loading={loading}
            header="Сохранение настроек"
            buttonOkTitle="Сохранить"
            buttonOkTitleSubmitting="Сохраняется..."
            orgsSelectorTitle="Сохранить настройки в заведения:"
            onClickOk={handleMassSubmitSettingsArray}
            onClickCancel={handleToggleOptionsModal}
            checkboxSaveOnlyLabel={`Сохранить только в ${currentOrgTitle}`}
            checkboxRevealOrgsSelectorLabel="Сохранить во всех доступных вам заведениях типа ОУ"
            disableMultiple={disableMultiple}
            multipleAfterContent={multipleAfterContent}
            additionalContent={additionalContent}
            skipSelection={onlyOneOrg}
          />
        )}
      <MultipleSubmitIndicator show={submitting} />
    </Fragment>
  )
}

export default MultipleSubmitSettings
