// @flow
import type { Params, Customer } from 'app/dataTypes/reports/Customer.types';
import { customerTypes, type CustomerType } from 'app/dataTypes/reports/customerTypes';
import { getFIO } from 'app/common/lib/names';

import type { Request } from '../index';

// DEPRECATED новая версия запроса src/common/api/requests/reports/find_customer/get.ts

type ParamsApi = {
  search: string
}

export const convertParams = ({ search }: Params): [ParamsApi, null] => (
  [{ search }, null]
);

type ResultApi = {
  id: number,
  name: {
    first: string,
    last: string,
    middle: string,
  },
  type: 'Student' | 'Employee',
  description: string,
  phone: string,
  account_number: null,
} | null

const castToCustomerType = (input: 'Student' | 'Employee'): CustomerType | void => {
  switch (input) {
    case 'Student': return customerTypes.Student;
    case 'Employee': return customerTypes.Employee;
    default: return undefined;
  }
};

export const convertResult = (data: ResultApi): Customer | null => {
  if (data) {
    const {
      id,
      name,
      type,
      description,
      phone,
      account_number: accountNumber,
    } = data;

    return {
      id: String(id),
      fullName: getFIO(name),
      type: castToCustomerType(type),
      description,
      phone,
      accountNumber,
    };
  }
  return null;
};

export const GET_FIND_CUSTOMER: Request<
  Params,
  Customer | null,
  void
> = [
  'reports/find_customer',
  'get',
  ['data', 'customer'],
];
