// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as customerType } from '../customerType';


const studentSubsidyTypeActivity = (state: State) => {
  return (
    customerType.filterActive(state)
    && customerType.onlyStudentCustomerSelected(state)
  );
};

export default studentSubsidyTypeActivity;
