// @flow
import React, { useCallback, useEffect } from 'react';
import moment from 'moment-timezone';

import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider';
import PlainDatePicker from 'app/common/ui-next/plain-date-picker';
import PlainDateSwitcher from 'app/common/ui-next/plain-date-switcher';


type Props = {
  date: string,
  dateFormat: string,
  onDateChange: (day: string, format: string) => void,
}

const DateNavigation = ({ date, dateFormat, onDateChange }: Props) => {
  const [{ currentOrg: { from } }] = useOrgsData();

  const disabledDays = useCallback((dayFormatted: string, format: string, unit?: string = 'day') => (
    from ? moment(dayFormatted, format).isBefore(from, unit) : false
  ), [from]);

  useEffect(() => {
    if (from && disabledDays(date, dateFormat)) {
      onDateChange(moment(from).add(1, 'days').format(dateFormat), dateFormat);
    }
  }, [from, date, dateFormat]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PlainDateSwitcher
      date={date}
      dateFormat={dateFormat}
      onDateChange={onDateChange}
      dateDisabledStatus={disabledDays}
    >
      <PlainDatePicker
        date={date}
        dateFormat={dateFormat}
        onDateChange={onDateChange}
        dateDisabledStatus={disabledDays}
      />
    </PlainDateSwitcher>
  );
};

export default DateNavigation;
