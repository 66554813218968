import { Column } from './types'

import styles from './useHeaderColumnProps.scss'


export const headerProps = {
  [Column.Status]: { alignLeft: true, className: styles.columnTitle },
  [Column.Date]: { alignLeft: true },
  [Column.Receiver]: { alignLeft: true },
  [Column.Org]: { alignLeft: true },
  [Column.Value]: { alignRight: true },
  [Column.Action]: { alignRight: true },
}

export function useHeaderColumnProps() {
  return headerProps
}
