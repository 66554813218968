// @flow
import { useState, useCallback } from 'react';
import append from 'ramda/es/append';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';
import equals from 'ramda/es/equals';

import getNumEnding from 'app/common/lib/getNumEnding';

import lang from './SellPoints.lang';


const defaultSelection = [];

export default function useSellPointsSelection(allCount: number) {
  const [selection, setSelection] = useState<Array<number>>(defaultSelection);
  const [selectionType, setSelectionType] = useState<'active' | 'deleted'>('active');

  const selectedNum = selection.length;
  const selectionPhrase: string = (selectedNum > 1
    ? `${selectedNum} ${getNumEnding(selectedNum, lang.namesByNumber)}`
    : lang.namesByNumber[0]);

  const handleToggleItem = useCallback((id: number, checked: boolean, deleted?: boolean) => {
    const incomingType = deleted ? 'deleted' : 'active';
    setSelectionType(incomingType);
    if (selectionType !== incomingType && checked) {
      setSelection([id]);
      return;
    }
    setSelection(checked ? append(id) : filter(complement(equals(id))));
  }, [selectionType]);

  const selectionReset = useCallback(() => {
    setSelection(defaultSelection);
  }, []);

  const selectedAll = selectedNum === allCount;

  return {
    selection,
    selectionType,
    selectionReset,
    selectedAll,
    handleToggleItem,
    selectionPhrase,
  };
}
