// @flow
import React from 'react';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import { useTranslation } from 'react-i18next';

import { useVersion, getVersionsHistoryLink } from 'app/common/components-next/service-worker';

import Header from './Header';
import styles from './AboutVersion.scss';

/**
 *  Подраздел о версии приложения
 *  Данные из ServiceWorkerLoader
 */


const AboutVersion = () => {
  const { buildVersion, buildDate, loading, needUpdateType, handleUpdate } = useVersion();
  const { t } = useTranslation('about');
  const externalLink = getVersionsHistoryLink();

  return (loading
    ? null
    : <div>
      <Header>{t('header_version')}</Header>
      <p className={styles.p}>
        {`Версия приложения — ${buildVersion} от ${buildDate} `}
        {needUpdateType
          && <PlainButtonAsLink
            onClick={handleUpdate}
          >
            {t('update_link_title', 'Обновить')}
          </PlainButtonAsLink>}
      </p>

      {externalLink
        && <a
          href={externalLink}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.historyLink}
        >
          {t('update_history_button', 'История обновлений')}
        </a>}
    </div>
  );
};

export default AboutVersion;
