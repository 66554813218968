// @flow
import prop from 'ramda/es/prop';
import api from 'app/actions/api';
import { toCamelCase } from 'app/common/lib/object';


export const PASS_SEND_TO_FILE: 'file' = 'file';
export const PASS_SEND_TO_EMAIL: 'email' = 'email';
export const PASS_SEND_TO_SMS: 'sms' = 'sms';
export type PassSendToType =
  | typeof PASS_SEND_TO_FILE
  | typeof PASS_SEND_TO_EMAIL
  | typeof PASS_SEND_TO_SMS;

export const ACCOUNT_ACTIVE: 'active' = 'active';
export const ACCOUNT_BLOCKED: 'blocked' = 'blocked';
export type ActiveType =
  | typeof ACCOUNT_ACTIVE
  | typeof ACCOUNT_BLOCKED
  | 'activate'; // ??? activate

export const EMPLOYEE_SETTINGS_REQUEST = 'EMPLOYEE_SETTINGS_REQUEST';
export const EMPLOYEE_SETTINGS_SUCCESS = 'EMPLOYEE_SETTINGS_SUCCESS';
export const EMPLOYEE_SETTINGS_FAILURE = 'EMPLOYEE_SETTINGS_FAILURE';
export const EMPLOYEE_SETTINGS_CLEAR = 'EMPLOYEE_SETTINGS_CLEAR';

const employeeSettingsRequest = payload => ({
  type: EMPLOYEE_SETTINGS_REQUEST,
  payload,
});

const employeeSettingsSuccess = payload => ({
  type: EMPLOYEE_SETTINGS_SUCCESS,
  payload,
});

const employeeSettingsFailure = payload => ({
  type: EMPLOYEE_SETTINGS_FAILURE,
  payload,
});

export const employeeSettingsClear = () => ({
  type: EMPLOYEE_SETTINGS_CLEAR,
});

export const employeeSettingsFetch = (id: number | string) => async (dispatch: Function) => {
  dispatch(employeeSettingsRequest(id));
  try {
    const res = await api(`food/employees/${id}/settings`, 'get');
    const settings = toCamelCase(prop('data', res));
    dispatch(employeeSettingsSuccess(settings));
    return settings;
  } catch (error) {
    dispatch(employeeSettingsFailure({ err: 'Запрос настроек сотрудника не удался', error }));
    return null;
  }
};
