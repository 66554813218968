// @flow
import { HIREFORM_CHANGE_FIELD, HIREFORM_CLEAR } from 'app/actions/employees/hireform';

export type HireFields = {
  firstname: string,
  middlename: ?string,
  lastname: string,
  gender: 'male' | 'female' | null,
  birthdate: ?string,
  phone: ?string,
  email: ?string,
  invite_date: ?string,
  order_date: ?string,
  order_num: string,
  role_id: ?number,
  school_id: ?Array<number>,
  snils: ?string,
}

export const defaultState: HireFields = {
  firstname: '',
  middlename: '',
  lastname: '',
  gender: 'male',
  birthdate: '',
  phone: '',
  email: '',
  invite_date: '',
  order_date: '',
  order_num: '',
  role_id: null,
  school_id: [],
  snils: '',
};

const hireform = (state: Object = defaultState, action: Object) => {
  if (action.type === HIREFORM_CHANGE_FIELD) {
    return ({ ...state, ...action.payload });
  }
  if (action.type === HIREFORM_CLEAR) {
    return defaultState;
  }
  return state;
};

export default hireform;
