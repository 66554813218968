// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';

import styles from './option-props.scss';


export type OptionPropsProps = {
  children: Node,
  className?: string,
}

const OptionProps = ({ children, className }: OptionPropsProps) => (
  <dl className={classNames(styles.root, className)}>
    {children}
  </dl>
);

export default OptionProps;
