// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Switch, Redirect } from 'react-router';
import PropsRoute from 'app/common/components/PropsRoute';

import OneEmployee, { GENERAL_SECTION, allowedSections } from 'app/components/Settings/Employees/OneEmployee';
import * as router from 'app/selectors/router';
import { replaceLast } from 'app/common/lib/path';

// $FlowFixMe
const enhance = connect(
  (state, props) => ({
    title: `Настройки - ${state.dealer.project_name}`,
    baseUrl: replaceLast(router.getUrl(props)),
    id: router.getId(props),
    section: router.getSection(props),
    type: router.getType(props),
    act: router.getAct(props),
  }),
);

type Props = {
  title: string,
  baseUrl: string,
  id: string,
  section: string,
  type: string,
  act: string,
  cancelModal?: (Event) => void,
};

const OneEmployeePage = ({ title, baseUrl, id, section, type, act, cancelModal }: Props) => (<div>
  <Helmet>
    <title>{title}</title>
  </Helmet>
  <Switch>
    {!allowedSections.includes(section)
        && <Redirect to={`/settings/employees/${type}/employee/${id}/${GENERAL_SECTION}`} />}
    <PropsRoute
      path="/settings/employees/:type/employee/:id/:section/:act"
      exact
      component={OneEmployee}
      props={{ id, baseUrl, section, act, cancelModal }}
    />
    <PropsRoute
      path="/settings/employees/:type/employee/:id/:section"
      exact
      component={OneEmployee}
      props={{ id, baseUrl, section, cancelModal }}
    />
  </Switch>
</div>);

export default enhance(OneEmployeePage);
