import { ChangeEvent, forwardRef, useCallback } from 'react'

import useDebouncedSearch from 'app/common/hooks/useDebouncedSearch'
import ControlsBar, { ControlsBarSpace } from 'app/common/ui-next/controls-bar'
import { OpenFilterButton } from 'app/common/ui-next/plain-button'
import { useFilterData } from 'app/common/ui-components/filter'
import PlainInputSearch from 'app/common/ui-next/plain-input-search'

import { orgTypes } from './useOrgsAndRoles'
import AddNewEnterpriseModal from './AddNewEnterpriseModal'


type Props = {
  onSearch: (s: string) => void
  onFilterToggle: (b: ((a: boolean) => boolean) | boolean) => void
  filterActive: boolean
  orgType: (typeof orgTypes)[keyof typeof orgTypes]
  onNewOrgAdded: () => void
}

const OrgsListFilterBar = forwardRef<HTMLDivElement, Props>(({
  onSearch,
  onFilterToggle,
  filterActive,
  orgType,
  onNewOrgAdded,
}, ref) => {
  const handleReset = useCallback(() => onSearch(''), [onSearch])
  const [searchString, handleSearch, handleClearSearch] = useDebouncedSearch(onSearch, handleReset)

  const [{ applied }] = useFilterData()

  const handleToggleFilter = useCallback((e: ChangeEvent<HTMLElement>) => {
    e.preventDefault()
    const { body } = document
    const { documentElement } = document
    // скрол вверх
    if (body) body.scrollTop = 0 // For Safari
    if (documentElement) documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    // показ окна с фильтрами
    onFilterToggle(state => !state)
  }, [onFilterToggle])

  return (
    <ControlsBar ref={ref}>
      {/* Кнопка открытия фильтра */}
      <OpenFilterButton
        onClick={handleToggleFilter}
        checked={!!applied}
        opened={filterActive}
      />
      {/* Форма поиска */}
      <PlainInputSearch
        placeholder="Поиск"
        value={searchString}
        onChange={handleSearch}
        clearSearch={handleClearSearch}
      />
      {orgType === orgTypes.ENTERPRISE
        && <ControlsBarSpace />}
      {orgType === orgTypes.ENTERPRISE
        && <AddNewEnterpriseModal onSuccess={onNewOrgAdded} />}
    </ControlsBar>
  )
})

export default OrgsListFilterBar
