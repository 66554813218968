import { useCallback, useMemo } from 'react'
import moment from 'moment-timezone'

import { dateFormatDefault as selectorDateFormat } from 'app/common/ui-next/plain-selector'

import { dateFormat } from './useJobsDateState'


export default function useJobsDateRestriction(restrictedDate: string) {
  const dateFromSelectorFormatted = useMemo(() => {
    return moment(restrictedDate, dateFormat).format(selectorDateFormat)
  }, [restrictedDate])

  const getDisabledDay = useCallback((day: string) => {
    return dateFromSelectorFormatted === day
  }, [dateFromSelectorFormatted])

  return getDisabledDay
}
