import { reduce, reduced } from 'ramda'


/**
 *  Расчет высоты выпадающего списка в зависимости от доступного места
 *  и высоты составляющих его элементов (выравнивание по ним)
 */

const calcListHeight = (listDOMElement: HTMLElement | null, buttonDOMElement: HTMLElement | null): number => {
  if (!listDOMElement || !buttonDOMElement) return 0

  const heights = Array.prototype.map.call(listDOMElement.querySelectorAll('li'), (elem): number => {
    const { height } = elem.getBoundingClientRect()
    return height
  }) as Array<number>

  if (heights.length === 0) return 0
  if (heights.length === 1) return heights[0]

  const { bottom } = buttonDOMElement.getBoundingClientRect()
  const availableSpace = document.documentElement ? document.documentElement.clientHeight - bottom : 0
  const listHeight = reduce((acc, val) => {
    if (acc + val > availableSpace) return reduced(acc)
    return acc + val
  }, 0, heights) || heights[0]

  return listHeight
}

export default calcListHeight
