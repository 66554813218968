import React from 'react';
import config from 'app/config';


type Props = {
  company: string,
}

const Copyright = ({ company }: Props) => (<div>
  {company
      && <span>
        {`©${' '}${company},${' '}${config.copyrightYear}`}
      </span>}
</div>);

export default Copyright;
