import { Children, isValidElement, cloneElement, FunctionComponentElement } from 'react'

/**
 *  Children с добавлением свойства disabled
 */

const childrenWithDisabledProp = (
  children: FunctionComponentElement<{ disabled?: boolean }>,
  disabled: boolean,
) => {
  return Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { disabled })
    }
    return child
  })
}

export default childrenWithDisabledProp
