// @flow
import { foodModes } from 'app/dataTypes/foodModes';

import type { Request } from '../../index';


type GetRestrictionsParams = {
  school_id?: number, // если комлект или блюдо школы
  complex_id?: number, // если комплект
  item_id?: number, // если блюдо
}

export type GetRestrictionsResult = {
  for_school_employees: boolean, // для сотрудников ОУ
  for_supplier_employees: boolean, // для сотрудников ПП
  for_students_without_subsidies: boolean, // для учащихся без льгот
  for_allowances: Array<string>, // список льгот
  parallels: { // по параллелям
    [key: string]: boolean,
  },
  food_modes: { // по режиму питания
    [$Values<typeof foodModes>]: boolean,
  },
  day_cares: { // по группам продленного дня
    [key: string]: boolean,
  },
  ages: {
    [key: string]: boolean,
  },
  camps: {
    [key: string]: boolean,
  },
  shifts: {
    shift_1: boolean,
    shift_2: boolean,
    shift_3: boolean,
  }
}

export const defaultRestrictionsResult: GetRestrictionsResult = {
  for_school_employees: false,
  for_supplier_employees: false,
  for_students_without_subsidies: false,
  for_allowances: [],
  parallels: {},
  food_modes: {
    [foodModes.Afternoon]: false,
    [foodModes.Breakfast]: false,
    [foodModes.Dinner]: false,
  },
  day_cares: {},
  ages: {},
  camps: {},
  shifts: {
    shift_1: true,
    shift_2: true,
    shift_3: true,
  },
};

export const GET_RESTRICTIONS: Request<
  GetRestrictionsParams,
  GetRestrictionsResult,
  void
> = [
  'food/complexes/restrictions',
  'get',
  ['data', 'restrictions'],
];

type PutRestrictionsParams = {
  ...$Exact<GetRestrictionsParams>,
  restrictions: GetRestrictionsResult
}

type PutRestrictionsResult = 'success';

export const PUT_RESTRICTIONS: Request<
  PutRestrictionsParams,
  PutRestrictionsResult,
  void
> = [
  'food/complexes/restrictions',
  'put',
  ['data', 'result'],
];
