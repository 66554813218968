// @flow
import { map } from 'ramda';

import { defaultStateDataCurrent, type StateDataItem } from 'app/common/ui-components/filter';

import { api } from 'app/api';
import { GET_ALLOWANCES } from 'app/common/api/requests/food/allowances';


const employeeItems = async (): Promise<[Array<StateDataItem>, Array<string>]> => {
  const res = await api.request(GET_ALLOWANCES, {
    error: 'Не удалось получить категории льгот',
  });

  if (res && res instanceof Array) {
    return ([map(val => ({ id: val, title: val }), res), defaultStateDataCurrent]);
  }
  return [[], defaultStateDataCurrent];
};

export default employeeItems;
