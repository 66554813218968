// @flow
import { useMemo } from 'react';
import { find, propEq } from 'ramda';

import type { Category, Categories } from 'app/dataTypes/vending/production/Categories.types';


export default function useCurrentCategory(categoryId?: string, categories: Categories): Category | null {
  const res = useMemo(() => {
    return categoryId ? find(propEq('id', categoryId), categories) : null;
  }, [categoryId, categories]);

  return res || null;
}
