import * as React from 'react';
import nope from 'app/common/lib/nope';


const StickyContext = React.createContext({
  elHeights: [0, 0, 0, 0, 0],
  reference: nope,
  itemsSticky: [false, false, false, false, false],
  onStickyToggle: nope,
});

export default StickyContext;
