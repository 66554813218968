// @flow
import { useState, useCallback, useEffect } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import { api } from 'app/api';

import {
  GET_ORGS,
  type OrgsResult,
  type OrgsSchools,
  type OrgItem,
  type OrgsParams,
  orgTypes,
} from 'app/common/api/requests/food/schools';


export { orgTypes };
export type { OrgsSchools, OrgItem };

type Options = {
  params: OrgsParams | null,
  resetPagination: () => void,
  onDataFetch?: (data: OrgsResult) => void
}

export default function useOrgsAndRoles({
  params,
  resetPagination,
  onDataFetch,
}: Options) {
  const [orgs, setOrgs] = useState<OrgsSchools>({});
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleRequest = useCallback(async () => {
    if (!params) return;

    setLoading(true);
    const res = await api.request(GET_ORGS, {
      error: 'Не удалось получить данные заведений',
      params: {
        ...params,
        include: ['roles'],
      },
    });
    setLoading(false);

    if (res) {
      if (onDataFetch) {
        onDataFetch(res);
      }
      const { schools, count: itemsCount } = res;
      if (itemsCount && isEmpty(schools)) {
        resetPagination();
        return;
      }
      setOrgs(schools);
      setCount(itemsCount);
    }
  }, [params, resetPagination, onDataFetch]);


  useEffect(() => {
    handleRequest();
  }, [handleRequest]);


  return {
    orgs,
    count,
    loading: params ? loading : true,
  };
}
