// @flow
import React from 'react';

import ImagePrepare, { type ImageParamsType } from 'app/common/components/ImagePrepare';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import OveflowedText from 'app/common/components/OveflowedText';

import styles from './AddingFormImage.scss';


export type { ImageParamsType };

type Props = {
  photo: ?ImageParamsType,
  filenameLocal: string,
  onSubmit: (ImageParamsType, string) => boolean,
}

const AddingFormImage = ({ onSubmit, photo, filenameLocal }: Props) => {
  return (
    <div className={styles.root}>
      <ImagePrepare
        onSubmit={onSubmit}
        rectMinSizeWidth={100}
        rectMinSizeHeight={133}
        rectProportion={0.75}
      >
        {handleFileOpen => (!photo
          ? <PlainButtonAsLink className={styles.addLink} onClick={handleFileOpen}>
            {'+ Добавить фото'}
          </PlainButtonAsLink>
          : <PlainButtonAsLink className={styles.filename} onClick={handleFileOpen}>
            <OveflowedText>
              {filenameLocal}
            </OveflowedText>
          </PlainButtonAsLink>)}
      </ImagePrepare>

      {!filenameLocal
        && <div className={styles.extentions}>
          {'jpeg, png, gif, tiff'}
        </div>}
    </div>
  );
};

export default AddingFormImage;
