// @flow
import { useState, useCallback, useMemo, useEffect } from 'react';
import path from 'ramda/es/path';
import findIndex from 'ramda/es/findIndex';
import propEq from 'ramda/es/propEq';
import adjust from 'ramda/es/adjust';
import isEmpty from 'ramda/es/isEmpty';
import __ from 'ramda/es/__';
import F from 'ramda/es/F';
import mergeRight from 'ramda/es/mergeRight';

import usePopupErr from 'app/common/hooks/usePopupErr';
import usePaging, { type Paging, defaultPaging } from 'app/common/hooks/usePaging';
import { api } from 'app/api';
import { COPY_FOOD_ITEMS } from 'app/common/api/requests/food/items/copy';

import {
  GET_FOOD_ITEMS,
  DELETE_FOOD_ITEMS,
  type FoodItem,
} from 'app/common/api/requests/food/items';

import {
  PUT_FOOD_ITEM_FIELD,
  type FoodItemFieldRequestParams,
  type SaveType,
  type FoodItemFieldRequestResult,
  type PhotoNewValue,
} from 'app/common/api/requests/food/item/field';

import convertDishesApiData from './helpers/convertDishesApiData';
import { useGeneralSettings } from 'app/dataProviders/generalSettings/hooks';

/**
 *  Работа с блюдами поставщика
 */

export type { SaveType, Paging, FoodItem, PhotoNewValue };
export { defaultPaging };

const emptyFunc = () => { };

const requestDishesError = 'Не удалось получить данные блюд';

const editFieldSuccess = 'Значение поля успешно изменено';
const editFieldError = 'Изменение поля не удалось (api)';
const editFieldErrorApi = 'Изменение поля не удалось';

const deletePhotoSuccess = 'Изображение блюда успешно удалено';
const deletePhotoError = 'Удаление изображения блюда не удалось';
const deletePhotoErrorApi = 'Удаление изображения блюда не удалось (api)';

const updatePhotoSuccess = 'Изображение блюда успешно заменено на новое';
const updatePhotoError = 'Изменение изображения блюда не удалось';
const updatePhotoErrorApi = 'Изменение изображения блюда не удалось (api)';

const deleteDishesError = 'Удаление элементов не удалось';
const deleteDishesSuccess = (itemsLength: number) => (
  itemsLength > 1 ? `Блюда (${itemsLength}) успешно удалены` : 'Блюдо успешно удалено'
);

const copyDishesError = 'Копирование блюд не удалось';
const copyDishesSuccess = (itemsLength: number) => (
  itemsLength > 1 ? `Блюда (${itemsLength}) успешно скопированы` : 'Блюдо успешно скопировано'
);

export type State = {
  dishes: Array<FoodItem>,
  nomenclatureActive: boolean,
  total: number,
  limit: number,
  loading: boolean,
}

export const defaultState: State = {
  dishes: [],
  nomenclatureActive: false,
  total: 0,
  limit: 25,
  loading: true,
};

export type ApiHandlers = {
  request: () => void | Promise<void>,
  fieldUpdate: (params: FoodItemFieldRequestParams) => boolean | Promise<boolean>,
  delete: (dishesIds: Array<number>) => boolean | Promise<boolean>,
  copy: (dishesIds: Array<number>) => boolean | Promise<boolean>,
  photoUpdate: (id: number, value: PhotoNewValue) => boolean | Promise<boolean>,
  photoDelete: (id: number) => boolean | Promise<boolean>,
}

export const defaultApiHandlers = {
  request: emptyFunc,
  fieldUpdate: F,
  delete: F,
  copy: F,
  photoUpdate: F,
  photoDelete: F,
};

export type Options = {
  limit?: number,
  search?: string,
  saleType?: string,
  onDishes?: (dishes: Array<FoodItem>) => void,
}

const defaultOptions: {
  limit: number,
} = {
  limit: 25,
};

export default function useDishes(options?: Options = {}): [State, ApiHandlers, Paging] {
  const { limit, search, saleType, onDishes } = { ...defaultOptions, ...options };
  const [popupErr, popup] = usePopupErr();

  const { generalSettings: { useNomenclature: nomenclatureActive } } = useGeneralSettings();

  const [dishes, setDishes] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const paging = usePaging();
  const { currentPage, setCurrentPage } = paging;


  // преобразование результата запроса FoodItemFieldRequestResult и помещение его в состояние
  const moveFoodItemResult2State = useCallback((res: FoodItemFieldRequestResult, dishId: number) => {
    const dishItem = path([dishId], res);
    if (!dishItem || isEmpty(dishItem)) {
      return false;
    }
    const index = findIndex(propEq('id', dishId), dishes);
    if (index === -1) {
      return false;
    }
    setDishes(adjust(index, mergeRight(__, dishItem)));
    return true;
  }, [dishes]);


  // запрос данных
  const handleRequestDishes = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_FOOD_ITEMS, {
      cancelable: true,
      error: requestDishesError,
      params: {
        limit,
        search,
        offset: (currentPage - 1) * limit,
        saleType,
      },
    });
    setLoading(false);

    if (res) {
      const [items, totalCount] = convertDishesApiData(res);
      if (isEmpty(items) && totalCount && currentPage > 1) {
        // нет данных на странице >1, редирект на первую
        setCurrentPage(1);
      }
      setDishes(items);
      setTotal(totalCount);
    }
  }, [limit, search, currentPage, saleType, setCurrentPage]);


  // изменение поля в одном элементе
  const handleUpdateField = useCallback(async (params: FoodItemFieldRequestParams) => {
    const { id } = params;
    if (!id) {
      popupErr(editFieldErrorApi);
      return false;
    }
    const res = await api.request(PUT_FOOD_ITEM_FIELD, {
      error: editFieldError,
      params,
    });

    if (res && moveFoodItemResult2State(res, id)) {
      popup(editFieldSuccess);
      return true;
    }
    popupErr(editFieldErrorApi);
    return false;
  }, [popup, popupErr, moveFoodItemResult2State]);


  // удаление фотографии
  const handlePhotoDelete = useCallback(async (id: number) => {
    const res = await api.request(PUT_FOOD_ITEM_FIELD, {
      error: deletePhotoError,
      params: {
        id,
        field: 'photo',
        new_value: null,
      },
    });

    if (res && moveFoodItemResult2State(res, id)) {
      popup(deletePhotoSuccess);
      return true;
    }
    popupErr(deletePhotoErrorApi);
    return false;
  }, [popup, popupErr, moveFoodItemResult2State]);


  // обновление фотографии
  const handlePhotoUpdate = useCallback(async (id: number, value: PhotoNewValue) => {
    if (!id) {
      popupErr(updatePhotoErrorApi);
      return false;
    }
    const res = await api.request(PUT_FOOD_ITEM_FIELD, {
      error: updatePhotoError,
      params: {
        id,
        field: 'photo',
        new_value: value,
      },
    });

    if (res && moveFoodItemResult2State(res, id)) {
      popup(updatePhotoSuccess);
      return true;
    }
    popupErr(updatePhotoErrorApi);
    return false;
  }, [popup, popupErr, moveFoodItemResult2State]);


  // удаление блюд
  const handleDeleteDishes = useCallback(async (itemIds: Array<number>) => {
    const res = await api.request(DELETE_FOOD_ITEMS, {
      error: deleteDishesError,
      params: {
        item_id: itemIds,
      },
    });

    if (res) {
      popup(deleteDishesSuccess(itemIds.length));
      handleRequestDishes();
      return true;
    }
    return false;
  }, [handleRequestDishes, popup]);


  // копирование блюд
  const handleCopyDishes = useCallback(async (itemIds: Array<number>) => {
    const res = await api.request(COPY_FOOD_ITEMS, {
      error: copyDishesError,
      params: {
        items: itemIds,
      },
    });

    if (res) {
      popup(copyDishesSuccess(itemIds.length));
      handleRequestDishes();
      return true;
    }
    return false;
  }, [handleRequestDishes, popup]);


  // сброс номера страницы
  useEffect(() => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  }, [search, saleType]); // eslint-disable-line react-hooks/exhaustive-deps

  // запрос блюд
  useEffect(() => {
    handleRequestDishes();
  }, [handleRequestDishes]);

  // обновление состояния блюд
  useEffect(() => {
    if (onDishes) {
      onDishes(dishes);
    }
  }, [dishes]); // eslint-disable-line react-hooks/exhaustive-deps


  const state = useMemo(() => ({
    dishes,
    nomenclatureActive,
    total,
    limit,
    loading,
  }), [dishes, total, limit, loading, nomenclatureActive]);

  const apiHandlers = useMemo(() => ({
    request: handleRequestDishes,
    fieldUpdate: handleUpdateField,
    delete: handleDeleteDishes,
    copy: handleCopyDishes,
    photoDelete: handlePhotoDelete,
    photoUpdate: handlePhotoUpdate,
  }), [
    handleRequestDishes,
    handleUpdateField,
    handleDeleteDishes,
    handleCopyDishes,
    handlePhotoDelete,
    handlePhotoUpdate,
  ]);

  return [state, apiHandlers, paging];
}
