import { useMemo } from 'react'
import { reduce } from 'ramda'

import toFixedValue from 'app/common/lib/toFixedValue'

import { HeaderRow1, HeaderRow2 } from './columns'
import type { CSchool, Total } from '../useReportData'


export type ActualItem = Record<string, string> & { key: string }


export default function useActualItems(data: Array<CSchool>, dataTotal: Total) {
  const res = useMemo(() => {
    let index = 0
    const [items,
      /* , complexTotal, complexRetTotal, personalTotal, personalRetTotal, totalSum */] = reduce((acc, {
      school_title: title,
      complex_s: complexS,
      complex_ret: complexRet,
      complex_com: complexCom,
      personal_s: personalS,
      personal_ret: personalRet,
      personal_com: personalCom,
      total,
    }): [Array<ActualItem>/* , number, number, number, number, number */] => {
      const item: ActualItem = ({
        key: `${title}${++index}`, // eslint-disable-line no-plusplus
        [HeaderRow1.TITLE]: title,
        [HeaderRow2.PERSONAL_S]: toFixedValue(personalS),
        [HeaderRow2.PERSONAL_COM]: toFixedValue(personalCom),
        [HeaderRow2.PERSONAL_RET]: toFixedValue(personalRet),
        [HeaderRow2.COMPLEX_S]: toFixedValue(complexS),
        [HeaderRow2.COMPLEX_COM]: toFixedValue(complexCom),
        [HeaderRow2.COMPLEX_RET]: toFixedValue(complexRet),
        [HeaderRow1.TOTAL]: toFixedValue(total),
      })
      acc[0].push(item)
      // acc[1] += complex
      // acc[2] += complexRet
      // acc[3] += personal
      // acc[4] += personalRet
      // acc[5] += total
      return acc
    }, [[] as Array<ActualItem>/* , 0, 0, 0, 0, 0 */], data)

    const {
      complex_s: complexS,
      complex_ret: complexRet,
      complex_com: complexCom,
      personal_s: personalS,
      personal_ret: personalRet,
      personal_com: personalCom,
      total: totalSum,
    } = dataTotal

    const total: ActualItem = {
      key: 'Итого',
      [HeaderRow1.TITLE]: 'Итого',
      [HeaderRow2.PERSONAL_S]: toFixedValue(personalS),
      [HeaderRow2.PERSONAL_COM]: toFixedValue(personalCom),
      [HeaderRow2.PERSONAL_RET]: toFixedValue(personalRet),
      [HeaderRow2.COMPLEX_S]: toFixedValue(complexS),
      [HeaderRow2.COMPLEX_COM]: toFixedValue(complexCom),
      [HeaderRow2.COMPLEX_RET]: toFixedValue(complexRet),
      [HeaderRow1.TOTAL]: toFixedValue(totalSum),
    }

    return { items, total }
  }, [data, dataTotal])

  return res
}
