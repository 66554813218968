// @flow
import React, { useCallback, useEffect } from 'react';

import FiltersDropDownLayout, { Column } from 'app/common/layouts/FiltersDropDownLayout';
import { FilterItem, useFilterData } from 'app/common/ui-components/filter';

import { filterIds } from './filterItems';
import styles from './FilterWindow.scss';


type Props = {
  onFilter: ((boolean => boolean) | boolean) => void,
  filterActive: boolean,
  enableHiring: boolean,
  loading?: boolean,
}

const FilterWindow = ({ onFilter, filterActive, enableHiring, loading }: Props) => {
  const handleCloseFilterWindow = useCallback(() => {
    onFilter(false);
  }, [onFilter]);

  const [,,, { filtersLoad, resetAll, applyFilters }] = useFilterData();

  useEffect(() => {
    filtersLoad();
  }, [filtersLoad]);


  if (!filterActive) {
    return null;
  }


  return (
    <FiltersDropDownLayout
      onClose={handleCloseFilterWindow}
      arrowClassName={enableHiring ? styles.filterArrowWithHiringButton : styles.filterArrow}
      loading={loading}
      onApplyFilter={applyFilters}
      onResetAll={resetAll}
    >
      <Column>
        <FilterItem filterId={filterIds.FILTER_BY_ROLE} />
      </Column>
    </FiltersDropDownLayout>
  );
};

export default FilterWindow;
