import mapProps from 'recompose/mapProps';

import i18next from 'app/i18n';

import Restore from '../Restore';


const enhance = mapProps(props => ({
  header: i18next.t('login:account_activation'),
  text1: `${i18next.t('login:account_activation_invite')}:`,
  lockLogin: true,
  ...props,
}));

export default enhance(Restore);
