// @flow
import React, { type Node } from 'react';

import { Table, Tr, Th } from 'app/common/ui-components/table';

import { columnId } from './HeaderLeftContent';


export const columnFooterText = 'Итого:';
export const rowId = 'header_total';

type Props = {
  className: string,
}

const FooterLeftContent = ({ className }: Props): Node => {
  return (
    <Table className={className}>
      <tfoot>
        <Tr>
          <Th
            headers={columnId}
            id={rowId}
            scope="row"
            alignRight
          >
            {columnFooterText}
          </Th>
        </Tr>
      </tfoot>
    </Table>
  );
};

export default FooterLeftContent;
