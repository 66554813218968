// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as group } from '../group';
import { flags as groupComplexesSpecial } from '../groupComplexesSpecial';


const excludeOVZActivity = (state: State) => {
  return (
    (
      group.filterActive(state)
      && (
        group.byGroupComplexesSelected(state)
        || group.byCampComplexesSelected(state))
    ) || (
      groupComplexesSpecial.filterActive(state)
      && (
        groupComplexesSpecial.byGroupComplexesSpecialSelected(state)
        || groupComplexesSpecial.byCampComplexesSpecialSelected(state)
      )
    )
  );
};

export default excludeOVZActivity;
