// @flow
import { useRef, useCallback } from 'react';


export default function useCopyContentWidths(widthCorrection?: number = 0, onCopySuccess?: () => void) {
  const refFrom = useRef<HTMLElement | null>(null);
  const refTargetsArray = useRef<Array<HTMLElement | null>>([]);

  const handleResize = useCallback((entries) => {
    entries.forEach(({ contentRect }) => {
      const { width } = contentRect;

      refTargetsArray.current.forEach((node: HTMLElement | null) => {
        if (!node) return;
        const targetStyle = window.getComputedStyle(node, null);
        const padding = (parseInt(targetStyle.getPropertyValue('padding-left'), 10)
          + parseInt(targetStyle.getPropertyValue('padding-right'), 10));
        node.style.width = `${width + padding + widthCorrection}px`; // eslint-disable-line no-param-reassign
      });
      if (onCopySuccess) {
        onCopySuccess();
      }
    });
  }, [widthCorrection, onCopySuccess]);

  const ro = useRef(new ResizeObserver(handleResize));

  const refFromFunc = useCallback((node: ?HTMLElement) => {
    if (!node) return;
    refFrom.current = node;
    ro.current.observe(node);
  }, []);

  const refTargetFunc = useCallback((node: ?HTMLElement) => {
    if (!node) return;
    refTargetsArray.current = refTargetsArray.current.filter(item => Boolean(item));
    refTargetsArray.current.push(node);
  }, []);


  return [refFromFunc, refTargetFunc];
}
