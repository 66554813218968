// @flow
import { useMemo } from 'react';

import type { Category } from 'app/dataTypes/vending/production/Categories.types';


type Options = {
  currentCategory: Category | null,
  defaultCategoryId: string,
}

export default function useContentHeader({ currentCategory, defaultCategoryId }: Options): string {
  const header = useMemo(() => {
    if (currentCategory) {
      const { id, title } = currentCategory;
      if (id === defaultCategoryId) {
        return 'Вся продукция';
      }
      return `Категория продукции: ${title}`;
    }
    return '...';
  }, [currentCategory, defaultCategoryId]);

  return header;
}
