// @flow
import React, { type Ref, type ElementType, type Node, useCallback } from 'react';
import classNames from 'classnames/bind';

import styles from './PlainMenuItem.scss';


type Props = {
  onPropagationCatch?: | ((e: SyntheticEvent<HTMLElement>) => void)
  | ((e: SyntheticKeyboardEvent<HTMLElement>) => void),
  disabled?: boolean,
  reference?: Ref<ElementType>,
  children: Node,
}

const cx = classNames.bind(styles);

const PlainMenuItem = ({ onPropagationCatch, children, disabled, reference }: Props) => {
  const handleClick = useCallback((e: SyntheticEvent<HTMLElement>) => {
    if (!disabled && onPropagationCatch) {
      onPropagationCatch(e);
    }
  }, [disabled, onPropagationCatch]);

  const handleKeyUp = useCallback((e: SyntheticKeyboardEvent<HTMLElement>) => {
    const whichKey = e.key;
    if (!disabled && whichKey === 'Enter' && onPropagationCatch) {
      onPropagationCatch(e);
    }
  }, [disabled, onPropagationCatch]);


  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={cx({ root: true, disabled })}
      onClick={handleClick}
      onKeyUp={handleKeyUp}
      ref={reference}
    >
      {children}
    </div>
  );
};

export default PlainMenuItem;
