// @flow
import { type State } from 'app/common/ui-components/filter';

import { studentSubsidyTypeActivity as studentSubsidyTypeActivityDefault } from 'app/components/common/reports/filters/studentSubsidyType';

import { flags as period } from './period';
import { flags as group } from './group';


const studentSubsidyTypeActivity = (state: State) => {
  return (
    studentSubsidyTypeActivityDefault(state)
    && !(
      period.filterActive(state)
      && period.finalDateInPreviosMonthOrBefore(state)
      && group.filterActive(state)
      && (group.byGroupComplexesSelected(state) || group.byCampComplexesSelected(state))
    )
  );
};

export default studentSubsidyTypeActivity;
