import { useMemo } from 'react'
import { map, pipe, values } from 'ramda'

import { SideNavItem } from 'app/pages/_layouts/SideNavLayout'
import { iconType } from 'app/common/ui-next/plain-icon'

import { SettingsRoute, getURL } from './router'


const translation = (section: SettingsRoute): string => {
  if (section === SettingsRoute.Documents) return 'Документы'
  return '' as never
}

const icons = (section: SettingsRoute): string => {
  if (section === SettingsRoute.Documents) return iconType.DOCUMENTS
  return iconType.DOCUMENTS
}

const widths = (/* section: SettingsRoute */): string => {
  return '20'
}

export default function useSideNavItems(): Array<SideNavItem> {
  const res = useMemo(() => {
    return pipe(
      map<SettingsRoute, SideNavItem>(subSection => ({
        ID: subSection,
        path: getURL({ subSection }),
        title: translation(subSection),
        iconType: icons(subSection),
        iconWidth: widths(),
        disabled: false,
      })),
    )(values(SettingsRoute))
  }, [])

  return res
}
