// https://github.com/shokai/async-singleton
const last = arr => arr[arr.length - 1];

function singletonAsync(func, { trailing } = {}) {
  if (typeof func !== 'function') throw new Error('argument is not function.');
  let running = false;
  let queue = [];
  return (...args) => new Promise(async (resolve) => { // eslint-disable-line
    if (running) return queue.push({ resolve, args });
    running = true;
    let result = await func(...args);
    resolve(result);
    if (queue.length > 0) {
      if (trailing) {
        const { args } = last(queue); // eslint-disable-line no-shadow
        result = await func(...args);
      }
      queue.forEach(({ resolve }) => resolve(result)); // eslint-disable-line no-shadow
      queue = [];
    }
    running = false;
  });
}

export default singletonAsync;
