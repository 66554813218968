// @flow
import React, { Fragment, useState, useCallback, type Node } from 'react';
import moment from 'moment-timezone';

import PlainButton from 'app/common/ui-next/plain-button';
import { GET_FOOD_ITEMS_EXPORT } from 'app/common/api/requests/food/items/export';
import Modal from 'app/common/components/Modal';
import { api } from 'app/api';
import Loading from 'app/common/components/Loader';

import styles from './ExportButton.scss';

/**
 *  Экспорт файла XLSX с блюдами
 */

type Props = {
  children: Node
}

const ExportButton = ({ children }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = useCallback(async () => {
    setLoading(true);
    await api.download(GET_FOOD_ITEMS_EXPORT, {
      filename: `products_${moment().format('YYYYMMDD_HHmmss')}.xlsx`,
    });
    setLoading(false);
  }, []);

  return (
    <Fragment>
      <PlainButton onClick={handleDownload}>
        {children}
      </PlainButton>
      <Modal isOpen={loading} className={styles.root}>
        {'Подождите,'}
        <br />
        {'идет формирование файла'}
        <Loading className={styles.loading} />
      </Modal>
    </Fragment>
  );
};

export default ExportButton;
