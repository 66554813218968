// @flow
import { useMemo } from 'react';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import sortWith from 'ramda/es/sortWith';
import descend from 'ramda/es/descend';
import prop from 'ramda/es/prop';
import moment from 'moment-timezone';

import { getLastFM } from 'app/common/lib/names';
import toFixedValue from 'app/common/lib/toFixedValue';
import { foodModes, type FoodMode } from 'app/dataTypes/foodModes';
import * as orgsTypes from 'app/common/constants/schools/types';

import {
  type CafeOrders,
  type CafeOrder,
  roles,
  type Role,
  statuses,
  type Status,
} from './useOrdersData';


const getRoleTitle = (role: ?Role) => {
  if (role === roles.ROLE_CAFETERIA) {
    return 'Сотрудник столовой';
  }
  if (role === roles.ROLE_SCHOOL) {
    return 'Сотрудник ОУ';
  }
  if (role === roles.ROLE_DEALER) {
    return 'Сотрудник оператора';
  }
  if (role === roles.ROLE_ADMIN) {
    return 'Администратор системы';
  }
  if (role === roles.ROLE_TEMPLATE) {
    return 'Создано по шаблону';
  }
  return '';
};

const getFoodModeTitle = (mode: ?FoodMode) => {
  if (mode === foodModes.Breakfast) {
    return 'Завтрак';
  }
  if (mode === foodModes.Afternoon) {
    return 'Полдник';
  }
  if (mode === foodModes.Dinner) {
    return 'Обед';
  }
  return '';
};

const getStatusTitle = (status: ?Status) => {
  // Удалена (серый цвет), Проведена (зеленый цвет), ожидает проведения (оранжевый цвет), Ожидает выдачи (красный цвет), Ожидает подтверждения (синий цвет)
  if (status === statuses.STATUS_DELETED) {
    return 'Удалена';
  }
  if (status === statuses.STATUS_SPENT) {
    return 'Проведена';
  }
  if (status === statuses.STATUS_PENDING) {
    return 'Ожидает проведения';
  }
  if (status === statuses.STATUS_PENDING_ISSUE) {
    return 'Ожидает выдачи';
  }
  // if (status === statuses.???) {
  //   return 'Ожидает подтверждения'; // (синий цвет)
  // }
  return '';
};

const createTotal = (
  total: ?number,
  students: ?number,
  orgType: $Values<typeof orgsTypes>,
  currency: string,
) => {
  const totalText = total ? toFixedValue(total) : '—';

  if (orgType === orgsTypes.ENTERPRISE) {
    const studentsText = students ? String(students) : '—';
    return `${studentsText}/${totalText}\u00A0${currency}`;
  }

  return totalText;
};


export type Item = {
  id: number,
  date: string,
  name: string,
  role: string,
  foodMode: string,
  students: string,
  total: string,
  status: ?Status,
  statusTitle: string,
  group: string,
}

export default function useOrgsOrdersTableItems(
  orders: CafeOrders,
  orgType: $Values<typeof orgsTypes>,
  currency: string,
) {
  const items: Array<Item> = useMemo(() => pipe(
    values,
    sortWith([
      descend(prop('date')),
      descend(prop('id')),
    ]),
    map(({
      id,
      date,
      created_user_role: role,
      created_user_name: name,
      food_mode: mode,
      students_count: students,
      total,
      status,
      group_title: groupTitle,
    }: CafeOrder) => {
      const mDate = moment(date);

      return ({
        id,
        date: date && mDate.isValid() ? mDate.format('DD.MM.YYYY') : '',
        name: name && typeof name === 'object' ? getLastFM(name) : '',
        role: getRoleTitle(role),
        foodMode: getFoodModeTitle(mode),
        students: students ? String(students) : '—',
        total: createTotal(total, students, orgType, currency),
        status,
        statusTitle: getStatusTitle(status),
        group: groupTitle || '',
      }: Item);
    }),
  )(orders), [orders]);

  return items;
}
