import { ReactNode } from 'react'

import styles from './FormSection.scss'


type Props = {
  children: ReactNode
}

export const FormSection = ({ children }: Props) => {
  return (
    <div className={styles.section}>{children}</div>
  )
}
