// @flow
import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';


export const getRelatedOrgIds = (machine: MachineSingle): Array<number> | null => {
  const { orgId } = machine;
  if (orgId) {
    return [orgId];
  }

  return null;
};
