import { defaultMonthData, defaultOrgTypesDataReceiver, defaultOrgsDataReceiver, defaultYearData, getDefaultYearIndex, getPrevMonthId, monthFilter, monthFilterSelection, orgTypesFilter, orgTypesFilterSelection, orgsFilter, orgsFilterSelection, yearFilter, yearFilterSelection } from 'app/dataProviders/filter-items'


export const filterInit = () => {
  orgTypesFilter.addDataReceiver(
    defaultOrgTypesDataReceiver,
  )
  orgTypesFilter.dataReceive()
  orgTypesFilter.setStatusReady()


  orgsFilter.addDataReceiver(
    defaultOrgsDataReceiver,
  )
  orgsFilter.dataReceive()
  orgsFilter.setStatusReady()


  yearFilter.updateData(
    defaultYearData,
  )
  yearFilter.setStatusReady()

  yearFilterSelection.updateData(
    getDefaultYearIndex,
  )
  yearFilterSelection.setStatusReady()


  monthFilter.updateData(
    defaultMonthData,
  )
  monthFilter.setStatusReady()

  monthFilterSelection.updateData(
    getPrevMonthId,
  )
  monthFilterSelection.setStatusReady()

  return () => {
    orgTypesFilterSelection.reset()
    orgsFilterSelection.reset()
    yearFilterSelection.reset()
    monthFilterSelection.reset()

    orgTypesFilter.reset()
    orgsFilter.reset()
    yearFilter.reset()
    monthFilter.reset()
  }
}


export const resetToDefaultAll = () => {
  orgTypesFilterSelection.resetToDefault()
  orgsFilterSelection.resetToDefault()
  yearFilterSelection.resetToDefault()
  monthFilterSelection.resetToDefault()
}
