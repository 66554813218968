// @flow
import { map, isEmpty } from 'ramda';
import moment from 'moment-timezone';

import type { CashBoxResult, Payment, Multiple } from 'app/dataTypes/reports/finances/CashBox.types.js';
import toFixedValue from 'app/common/lib/toFixedValue';


type SummaryApi = {
  total: string,
  count: number,
}

export type ColumnApi = {
  title: string,
  key: string,
  from_at: string,
  to_at: string,
}

type RowApi = {
  title: string,
  key: string,
  school_id: number,
}

type ValuesApi = {
  [key: string]: {
    [key: string]: string
  }
}

type PaymentApi = {
  subject_name: string | null,
  subject_type: string | null,
  subject_klass: string | null,
  id: number,
  time: string,
  value: string,
  comment: string,
}

type SourcesApi = Array<{
  count: number,
  title: string,
  total: string,
}>

type ResultApi = {
  summary?: SummaryApi,
  sources?: SoyrcesApi,
  details?: {
    payments?: Array<PaymentApi>,
    table?: {
      columns?: Array<ColumnApi>,
      rows?: Array<RowApi>,
      values?: ValuesApi,
    }
  }
}

const covertPayments = (data?: Array<PaymentApi>): Array<Payment> | null => {
  if (!data || isEmpty(data)) return null;

  return map(({
    id,
    source,
    subject_name: name,
    subject_type: type,
    subject_klass: klass,
    time,
    value,
    comment,
    receipt,
  }): Payment => ({
    id: String(id),
    source,
    receiverPerson: name || '',
    receiverDescription: klass ? `${type || ''}, ${klass}` : type || '',
    date: moment(time).format('DD.MM.YYYY HH:mm'),
    sum: toFixedValue(value),
    comment,
    receipt: receipt || '',
  }), data);
};

const convertMultiple = (
  columns?: Array<ColumnApi>,
  rows?: Array<RowApi>,
  sums?: ValuesApi,
): Multiple | null => {
  if (
    !columns || isEmpty(columns)
    || !rows || isEmpty(rows)
    || !sums || isEmpty(sums)
  ) return null;

  const totalsByRowKey = new Map < string, number> ();
  const totalsByColumnKey = new Map < string, number> ();

  const dates = map(({ key, title, from_at: date }) => {
    const daily = /\.\d+$/.test(title);
    return ({
      key,
      date: title.replace(/\.\d+$/, ''),
      dateFull: daily ? title : `${title} ${moment(date).format('YYYY')}`,
    });
  }, columns);

  const titles = map(({ key, title }) => ({ key, title }), rows);

  const values = map(({ key }) => {
    let totalInRow = 0;
    const res = map(({ key: k }) => {
      const sum = parseInt(
        (
          parseFloat(sums[key][k] || 0)
            .toFixed(2)
            .replace(/\./, '')
        ), 10,
      );
      totalInRow += sum;
      totalsByColumnKey.set(k, (totalsByColumnKey.get(k) || 0) + sum);
      return toFixedValue(sums[key][k] || '');
    }, columns);
    totalsByRowKey.set(key, totalInRow / 100);
    return res;
  }, rows);

  const totalsRows = map(({ key }) => ({
    key,
    value: toFixedValue(totalsByRowKey.get(key)),
  }), titles);

  const totalsColumns = map(({ key }) => ({
    key,
    value: toFixedValue((totalsByColumnKey.get(key) || 0) / 100),
  }), dates);

  return {
    dates,
    titles,
    values,
    totalsRows,
    totalsColumns,
  };
};

export const convertResult = (data: ResultApi): CashBoxResult => {
  const { summary, details = {}, sources } = data;
  const { payments, table = {} } = details;
  const { columns, rows, values } = table;

  return {
    summary: summary && !isEmpty(summary) ? {
      ...summary,
      sources: sources.map(({ total, ...others }) => ({
        total: toFixedValue(total),
        ...others,
      })),
    } : null,
    payments: covertPayments(payments),
    multiple: convertMultiple(columns, rows, values),
  };
};
