// @flow
import React, { useEffect } from 'react';

import FiltersPlainLayout, { Column } from 'app/common/layouts/FiltersPlainLayout';
import { FilterItem, useFilterData } from 'app/common/ui-components/filter';


type Props = {
  loading: boolean,
  foodModeEnable: boolean,
}

const FilterWindow = ({ loading, foodModeEnable }: Props) => {
  const [,,, { filtersLoad, resetAll, applyFilters }] = useFilterData();

  useEffect(() => {
    filtersLoad();
  }, [filtersLoad]);


  return (
    <FiltersPlainLayout
      loading={loading}
      onApplyFilter={applyFilters}
      onResetAll={resetAll}
    >
      <Column>
        <FilterItem filterId="year" />
        <FilterItem filterId="period" />
      </Column>
      <Column>
        {foodModeEnable
          && <FilterItem filterId="foodMode" />}
        <FilterItem filterId="class" />
      </Column>
      <Column>
        <FilterItem filterId="status" />
      </Column>
    </FiltersPlainLayout>
  );
};

export default FilterWindow;
