// @flow
import React, { Fragment, memo } from 'react';

import useSortState, { sortRequest } from 'app/common/hooks/useSortState';
import CSSSticky from 'app/common/ui-components/css-sticky';

import HScrollbarsWShadow, {
  useSyncScrolls,
  HScrollContainer,
  HScrollableContent,
} from 'app/common/ui-components/h-scrollbars-w-shadow';

import {
  Table,
  useCopyColumnsWidths,
  useHover,
  useCopyContentWidths,
  useHoverByClass,
} from 'app/common/ui-components/table';

import type { ReportSchool, ReportTotalOnDate, ReportTotal } from '../useReportData';
import useTotalsSummary from '../useTotalsSummary';
import useTotals from '../useTotals';
import useDates from '../useDates';
import useActualSchoolsData from './useActualSchoolsData';
import LeftColumnHead from './LeftColumnHead';
import LeftColumnBody from './LeftColumnBody';
import LeftColumnFooter from './LeftColumnFooter';
import ContentHead from './ContentHead';
import ContentBody from './ContentBody';
import ContentFooter from './ContentFooter';
import RightColumnHead from './RightColumnHead';
import RightColumnBody from './RightColumnBody';
import RightColumnFooter from './RightColumnFooter';

import styles from './Schools.scss';


const sameRowClassName: 'row_' = 'row_';
const sameColumnClassName: 'column_' = 'column_';

type Props = {
  data: Array<ReportSchool>,
  totals: Array<ReportTotalOnDate>,
  summary: ReportTotal,
  hideUnspent: boolean,
  sellTypeGroupped: boolean,
}

const Schools = memo < Props > (({ data, totals, summary, hideUnspent, sellTypeGroupped }: Props) => {
  const [sort, handleSort] = useSortState('down', 'title');
  const actualData = useActualSchoolsData(data, sort);
  const totalsData = useTotals(totals);
  const datesData = useDates(totals);
  const totalsSummary = useTotalsSummary(summary);

  const [refFrom, refTo] = useCopyColumnsWidths(/* updateKey */);
  const [topScrollerProps, middleScrollerProps, bottomScrollerProps, updateBottomScroll] = useSyncScrolls();
  const [elementSource, elementsTarget] = useCopyContentWidths(1, updateBottomScroll);

  const [refTableBody, onMouseEnter, onMouseLeave] = useHover({
    numberOfChunks: 3,
    classNameBase: sameRowClassName,
  });
  const hoverProps = { reference: refTableBody, onMouseEnter, onMouseLeave };
  const [onCellMouseEnter, onCellMouseLeave] = useHoverByClass(sameColumnClassName);

  return (
    <Fragment>
      {/* I Слой, прилипающий заголовок */}
      <CSSSticky
        number={3}
        className={styles.stickable}
        stickyClassName={styles.sticked}
        shadowClassName={styles.shadow}
      >
        <HScrollContainer>

          {/* I - Left */}
          <Table className={styles.tableFixedLeft}>
            <LeftColumnHead
              onSort={handleSort}
              {...sortRequest('title', sort)}
            />
          </Table>

          {/* I - Middle */}
          <HScrollableContent>
            <HScrollbarsWShadow
              hideVerticalScrollBar
              hideHorizontalScrollBar
              absolutelyHideHorizontal_FixFF
              {...topScrollerProps}
            >
              <Table reference={elementsTarget}>
                <ContentHead
                  items={datesData}
                  reference={refTo}
                  onCellMouseEnter={onCellMouseEnter}
                  onCellMouseLeave={onCellMouseLeave}
                  sameColumnClassName={sameColumnClassName}
                />
              </Table>
            </HScrollbarsWShadow>
          </HScrollableContent>

          {/* I - Right */}
          <Table className={styles.tableFixedRight}>
            <RightColumnHead
              onSort={handleSort}
              sellTypeGroupped={sellTypeGroupped}
              {...sortRequest('totalSortable', sort)}
            />
          </Table>
        </HScrollContainer>
      </CSSSticky>

      <div>
        {/* II Слой, контент с данными */}
        <HScrollContainer>

          {/* II - Left */}
          <Table className={styles.tableFixedLeft}>
            {/* <LeftColumnHead hidden /> */}
            <LeftColumnBody
              data={actualData}
              sameRowClassName={sameRowClassName}
              {...hoverProps}
            />
            {/* <LeftColumnFooter hidden /> */}
          </Table>

          {/* II - Middle */}
          <HScrollableContent>
            <HScrollbarsWShadow
              hideVerticalScrollBar
              hideHorizontalScrollBar
              absolutelyHideHorizontal_FixFF
              {...middleScrollerProps}
            >
              <Table
                reference={elementSource}
                className={styles.middleContentTable}
              >
                <ContentHead
                  items={datesData}
                  reference={refFrom}
                  hidden
                />
                <ContentBody
                  data={actualData}
                  sameRowClassName={sameRowClassName}
                  sameColumnClassName={sameColumnClassName}
                  onCellMouseEnter={onCellMouseEnter}
                  onCellMouseLeave={onCellMouseLeave}
                  showDuty={!hideUnspent}
                  sellTypeGroupped={sellTypeGroupped}
                  {...hoverProps}
                />
                <ContentFooter
                  sellTypeGroupped={sellTypeGroupped}
                  totals={totalsData}
                  hidden
                />
              </Table>
            </HScrollbarsWShadow>
          </HScrollableContent>

          {/* II - Right */}
          <Table className={styles.tableFixedRight}>
            {/* <RightColumnHead hidden /> */}
            <RightColumnBody
              data={actualData}
              sameRowClassName={sameRowClassName}
              showDuty={!hideUnspent}
              sellTypeGroupped={sellTypeGroupped}
              {...hoverProps}
            />
            {/* <RightColumnFooter summary={totalsSummary} hidden /> */}
          </Table>
        </HScrollContainer>

        {/* III Слой, прилипающий подвал */}
        <CSSSticky
          number={-1}
          shadowClassName={styles.stickyBottom}
          className={styles.footer}
        >
          <HScrollContainer>
            {/* III - Left */}
            <Table className={styles.tableFixedLeft}>
              <LeftColumnFooter
                showDuty={!hideUnspent}
              />
            </Table>

            {/* III - Middle */}
            <HScrollableContent>
              <HScrollbarsWShadow
                hideVerticalScrollBar
                {...bottomScrollerProps}
              >
                <Table
                  reference={elementsTarget}
                  className={styles.middleContentTable}
                >
                  <ContentFooter
                    reference={refTo}
                    totals={totalsData}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    sameColumnClassName={sameColumnClassName}
                    showDuty={!hideUnspent}
                    sellTypeGroupped={sellTypeGroupped}
                  />
                </Table>
              </HScrollbarsWShadow>
            </HScrollableContent>

            {/* III - Right */}
            <Table className={styles.tableFixedRight}>
              <RightColumnFooter
                summary={totalsSummary}
                showDuty={!hideUnspent}
                sellTypeGroupped={sellTypeGroupped}
              />
            </Table>
          </HScrollContainer>
        </CSSSticky>
      </div>
    </Fragment>
  );
});

export default Schools;
