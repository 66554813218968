import { ROLES_SUCCESS } from 'app/actions/admin/roles';

const defaultState = {
  expired: 0,
  // ...
};

const roles = (state = defaultState, action) => {
  if (action.type === ROLES_SUCCESS) {
    return { ...action.payload };
  }
  return state;
};

export default roles;
