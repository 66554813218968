import { useCallback, useState } from 'react'
import cn from 'classnames/bind'
import { isEmpty } from 'ramda'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import useOrgAllowances from 'app/dataProviders/subsidy/allowances/hooks/useOrgAllowances'
import { Loading } from 'app/common/templates-next/page-template'
import useOrgAllowancesActions from 'app/dataProviders/subsidy/allowances/hooks/useOrgAllowancesActions'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import usePopup from 'app/common/hooks/usePopup'
import useSubsidySettingsCopy from 'app/dataProviders/subsidy/settings/hooks/useSubsidySettingsCopy'

import CategoriesAccordion from './CategoriesAccordion'
import AddRemoveCategories from './AddRemoveCategories'
import CopySettings from '../../CopySettings'
import { CategorySettings } from './CategorySettings'

import styles from './SubsidyCategories.scss'


const cx = cn.bind(styles)

type Props = {
  orgID: string
  hasTopMargin?: boolean
  subsidyType: 'common' | 'personal'
  app: 'camp' | 'cafeteria'
}

const SubsidyCategories = ({ orgID, hasTopMargin, subsidyType, app }: Props) => {
  const [allowances, loading, request] = useOrgAllowances({ orgID, app, used: true, subsidyType })
  const requestRef = useCallbackRef(request)

  const { update, remove } = useOrgAllowancesActions({ orgID, app })
  const copyAllSettings = useSubsidySettingsCopy({
    orgID,
    subsidyCategoriesType: subsidyType === 'personal' ? app : 'common',
  })
  const updateRef = useCallbackRef(update)
  const removeRef = useCallbackRef(remove)

  const popup = usePopup()

  const [pending, setPending] = useState(false)

  const updateCategories = useCallback(async (categoryIDs: Array<string>) => {
    const res = await updateRef.current?.(categoryIDs)
    if (res) {
      requestRef.current?.()
      popup('Категории льгот успешно изменены')
    }
    return !!res
  }, [updateRef, requestRef, popup])

  const deleteCategory = useCallback(async (ID: string) => {
    const res = await removeRef.current?.(ID)
    if (res) {
      requestRef.current?.()
    }
    return !!res
  }, [removeRef, requestRef])

  const createFormID = useCallback((ID: string) => {
    return `categorySettingsForm${ID}`
  }, [])

  const panelContent = useCallback((props: {
    ID: string
    active: boolean
    updateSize: () => void
    closeActivePanel: () => void
  }) => (
    props.active
      && <CategorySettings
        ID={props.ID}
        orgID={orgID}
        app={app}
        onDataUpdate={props.updateSize}
        onCreateFormID={createFormID}
        updateSize={props.updateSize}
        onSuccess={props.closeActivePanel}
        onPending={setPending}
      />
  ), [app, createFormID, orgID])

  return (
    <div className={cx(styles.root, { hasTopMargin })}>
      <div className={styles.addRemoveAndCopy}>
        <div>
          <h3 className={styles.header}>{'Категории льгот'}</h3>
          {subsidyType === 'personal'
            && <AddRemoveCategories
              orgID={orgID}
              subsidyType={subsidyType}
              app={app}
              updateCategories={updateCategories}
            >
              {toggle => (
                <PlainButtonAsLink onClick={toggle}>
                  {'Редактировать список категорий'}
                </PlainButtonAsLink>
              )}
            </AddRemoveCategories>}
        </div>
        <CopySettings onSubmit={copyAllSettings} />
      </div>

      {loading && isEmpty(allowances)
        ? <Loading />
        : <CategoriesAccordion
          items={allowances}
          locked={loading || pending}
          deleteCategory={deleteCategory}
          onCreateFormID={createFormID}
          panelContent={panelContent}
        />}
    </div>
  )
}

export default SubsidyCategories
