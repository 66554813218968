// @flow
const ms2min = (ms: number): string => {
  if (!ms || ms < 0) return '';
  const sec = Math.floor(ms / 1000);
  const min = Math.floor(sec / 60);
  const bits = sec % 60;
  return (`${min}:${bits < 10 ? 0 : ''}${bits}`);
};

export default ms2min;
