// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as currentStateDate } from 'app/components/common/reports/filters/currentStateDate';


const BALANCE_ALL: 'def' = 'def';
const BALANCE_NEGATIVE: 'neg' = 'neg';
const BALANCE_ZERO: 'zero' = 'zero';
const BALANCE_POSITIVE: 'pos' = 'pos';
const BALANCE_USER: 'user' = 'user';

export const commonBalanceTypes = {
  BALANCE_ALL,
  BALANCE_NEGATIVE,
  BALANCE_ZERO,
  BALANCE_POSITIVE,
  BALANCE_USER,
};

const def = {
  id: BALANCE_ALL,
  title: 'Все',
};

const neg = {
  id: BALANCE_NEGATIVE,
  title: 'Отрицательный баланс',
};

const zero = {
  id: BALANCE_ZERO,
  title: 'Нулевой баланс',
};

const pos = {
  id: BALANCE_POSITIVE,
  title: 'Положительный баланс',
};

const user = {
  id: BALANCE_USER,
  title: 'Свой диапазон от %text% до %text%',
};

const defaultCurrentState = [BALANCE_ALL, '', ''];
const defaultState = [[def, neg, zero, pos, user], defaultCurrentState];
const zeroOmmitedState = [[def, neg, pos, user], defaultCurrentState];

const commonBalanceItems = (state: State) => (
  currentStateDate.dateRadioSelected(state)
    ? zeroOmmitedState
    : defaultState
);

export default commonBalanceItems;
