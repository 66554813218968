// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import ErrorString from 'app/common/ui/ErrorString';

import IconOk from './icons/IconOk';
import EyeIcon from './icons/EyeIcon';
import EyeOffIcon from './icons/EyeOffIcon';
import styles from './PlainPassword.scss';


const cx = classNames.bind(styles);

type Props = {
  value?: string,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  name?: string,
  placeholder?: string,
  isError?: boolean,
  errorText?: string,
  isValid?: boolean,
  id?: string,
}

type State = {
  show: boolean,
}

class PlainPassword extends React.Component<Props, State> {
  static defaultProps = {
    isError: false,
    isValid: false,
  };

  state: State = { show: false };

  handleToggle = (e: SyntheticEvent<HTMLElement>): void => {
    e.preventDefault();
    this.setState(
      ({ show }) => ({ show: !show }),
    );
    if (this.input) {
      this.input.focus();
    }
  };

  refInput = (r: HTMLInputElement | null): void => {
    this.input = r;
  };

  input: ?HTMLInputElement;

  props: Props;

  render() {
    const {
      value,
      onChange,
      name,
      placeholder,
      isError,
      errorText,
      isValid,
      id,
    } = this.props;
    const { show } = this.state;
    return (
      <div>
        <div className={styles.main}>
          <input
            ref={this.refInput}
            className={cx({
              input: true,
              error: isError,
              success: isValid,
            })}
            name={name || id}
            id={id}
            value={value || ''}
            onChange={onChange}
            placeholder={placeholder}
            type={show ? '' : 'password'}
          />
          <div className={styles.icons}>
            {isValid && <IconOk className={styles.ok} />}
            <a href="togglePassword" onClick={this.handleToggle}>
              {show
                ? <EyeOffIcon className={styles.eye} />
                : <EyeIcon className={styles.eye} />}
            </a>
          </div>
        </div>
        <ErrorString>{errorText}</ErrorString>
      </div>
    );
  }
}

export default PlainPassword;
