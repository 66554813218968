// @flow
import RowLayout from './RowLayout';
import Header from './Header';
import Header2 from './Header2';
import Space from './Space';
import HeaderContent from './HeaderContent';
import RowLayoutContent from './RowLayoutContent';
import HeaderSupLink from './HeaderSupLink';
import RowLayoutWithSticky from './RowLayoutWithSticky';
import PaginationFooterWithSticky from './PaginationFooterWithSticky';
import RowLayoutFullscreenContent from './RowLayoutFullscreenContent';


export default RowLayout;

export {
  Header,
  Header2,
  Space,
  HeaderContent, // deprecated
  RowLayoutContent,
  HeaderSupLink,
  RowLayoutWithSticky,
  PaginationFooterWithSticky,
  RowLayoutFullscreenContent,
};
