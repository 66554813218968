import PageTemplate from './page-template'
import PageTemplateContent from './page-template-content'
import Header from './header'
import Header2 from './header2'
import SkeletonLoading from './skeleton-loading'
import Loading from './loading'
import StatusMessage from './status-message'
import SkeletonItem from './skeleton-item'
import Space from '../report-template/space'
import ContentGroup from './content-group'
import DotsLoader from './dots-loader'
import LoadingModal from './loading-modal'
import { SkeletonLoadingTBody } from './skeleton-loading-tbody'


export default PageTemplate

export {
  PageTemplateContent,
  Header,
  Header2,
  SkeletonLoading,
  Loading,
  StatusMessage,
  SkeletonItem,
  Space,
  ContentGroup,
  DotsLoader,
  LoadingModal,
  SkeletonLoadingTBody,
}
