import { Params, Settings } from 'app/dataTypes/subsidy/settings'
import { isCommonAllowance } from 'app/dataTypes/subsidy/allowances'

import type { Request } from '../../../index'
import { CafeteriaSettingsAPI, convertAsCafeteria, MAX_SHIFTS } from './specific/cafeteria'
import { CampSettingsAPI, convertAsCamp } from './specific/camp'


export { MAX_SHIFTS }

type ParamsAPI = {
  order_type: 'cafeteria' | 'camp' | 'common'
  allowance?: string
}

type PathParams = {
  orgID: string
}

export const convertParams = ({
  orgID,
  app,
  allowanceID,
}: Params): [ParamsAPI, PathParams] => {
  const common = isCommonAllowance(allowanceID)
  const orderType = common ? 'common' : app

  return [{
    order_type: orderType,
    ...(common ? {} : { allowance: allowanceID }),
  }, { orgID }]
}

export type ResultAPI = {
  settings: CafeteriaSettingsAPI | CampSettingsAPI,
  complexes?: Array<{ id: number, title: string }>
}

export const convertResult = ({ settings, complexes = [] }: ResultAPI, params: Params): Settings => {
  const complexesMap = new Map(complexes.map(({ id, title }) => ([id, title])))

  const { app, allowanceID } = params
  const common = isCommonAllowance(allowanceID)

  if (app === 'cafeteria') {
    return convertAsCafeteria(
      settings as CafeteriaSettingsAPI,
      complexesMap,
      common,
    )
  }

  return convertAsCamp(
    settings as CampSettingsAPI,
    complexesMap,
  )
}

export const GET_SCHOOL_SUBSIDY_ORDERS_SETTINGS: Request<Params, Settings, PathParams> = [
  'food/schools/{orgID}/subsidy_orders_settings',
  'get',
  ['data'],
]
