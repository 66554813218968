// @flow
import React from 'react';

import { Td, Tr, getPropsHeader } from 'app/common/ui-components/table';
import { DotsLoader } from 'app/common/components/Loader';

import { idsRow, props } from './columns';
import type { RowItem } from './useOrgsListTableItems';
import OrgsListTableCellLinks from './OrgsListTableCellLinks';
import OrgsListTableCellSettings from './OrgsListTableCellSettings';
import OrgsListTableCellPresence from './OrgsListTableCellPresence';


type Props = {
  disabled: boolean
} & RowItem

const OrgsListTableRow = ({ disabled, ...item }: Props) => {
  const {
    title,
    sells,
    debts,
    presence,
    links,
    settings,
    id,
    locked,
  } = item;
  const headers = getPropsHeader();

  const content = [
    title,
    presence.personal === null
      ? <DotsLoader simple />
      : <OrgsListTableCellPresence status={presence.personal} />,
    presence.complex === null
      ? <DotsLoader simple />
      : <OrgsListTableCellPresence status={presence.complex} />,
    sells.personal === null ? <DotsLoader simple /> : sells.personal,
    sells.complex === null ? <DotsLoader simple /> : sells.complex,
    debts === null ? <DotsLoader simple /> : debts,
    <OrgsListTableCellLinks orgId={id} items={links} />,
    <OrgsListTableCellSettings orgId={id} link={settings} />,
  ];

  return (
    <Tr
      disabled={disabled || locked}
      interlineBorder
      alignTop
    >
      {idsRow.map((columnId, i) => (
        <Td
          key={columnId}
          {...headers(columnId)}
          {...props[columnId]}
        >
          {content[i]}
        </Td>
      ))}
    </Tr>
  );
};

export default OrgsListTableRow;
