// @flow
import React from 'react';
import isEmpty from 'ramda/es/isEmpty';

import PlainLink from 'app/common/ui-next/plain-link';
import { WithRightsByOrg } from 'app/rights/RightsByOrg';
import { type RightsPath } from 'app/rights';


import styles from './OrgsListTableCellLinks.scss';


type Props = {
  orgId: number,
  items: Array<[string, string, RightsPath]>,
}

const OrgsListTableCellLinks = ({ orgId, items }: Props) => {
  if (isEmpty(items)) {
    return null;
  }

  return (
    <nav className={styles.root}>
      <ul className={styles.list}>
        {items.map(([path, title, rights, othersPaths]) => (
          <li key={path}>
            <WithRightsByOrg
              orgId={orgId}
              pathTo={rights}
              othersPaths={othersPaths}
              combineWithOthersPaths="any"
              editAffect="ignore"
            >
              <PlainLink to={path}>
                {title}
              </PlainLink>
            </WithRightsByOrg>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default OrgsListTableCellLinks;
