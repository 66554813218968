import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import omit from 'ramda/es/omit';
import map from 'ramda/es/map';
import propEq from 'ramda/es/propEq';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import values from 'ramda/es/values';
import find from 'ramda/es/find';

import {
  DOCUMENT_PUBLIC_OFFER,
  DOCUMENT_PRIVACY_POLICY,
  DOCUMENT_PRIVACY_CONSENT,
} from 'app/common/constants/documents';

import { defaultState } from 'app/common/reducers/documents';


const timeFormat = 'DD.MM.YYYY';

const getEditedTime = (doc) => {
  const mUpdated = moment(doc.updated_at);
  if (doc.updated_at && mUpdated.isValid()) {
    return mUpdated.format(timeFormat);
  }
  const mCreated = moment(doc.created_at);
  if (doc.created_at && mCreated.isValid()) {
    return mCreated.format(timeFormat);
  }
  return '';
};

const getAcceptedTime = (doc) => {
  const mAccepted = moment(doc.accepted_at);
  if (doc.accepted_at && mAccepted.isValid()) {
    return mAccepted.format(timeFormat);
  }
  return '';
};

const createPrivacy = (policy, consent, policyDefaultTitle) => {
  const {
    id: policyId,
    undated: undatedPolicy,
    title: policyTitle,
    changes: policyChanges,
    url: policyUrl,
  } = policy;

  const {
    id: consentId,
    changes: consentChanges,
    url: consentUrl,
  } = consent;

  return {
    id: policyId,
    ids: [policyId, consentId],
    type: DOCUMENT_PRIVACY_POLICY,
    editedAt: getEditedTime(policy),
    acceptedAt: undatedPolicy ? '' : getAcceptedTime(policy),
    title: policyTitle || policyDefaultTitle,
    changes: policyChanges + consentChanges,
    urls: [policyUrl, consentUrl],
  };
};

const selectDocuments = createSelector(
  [
    state => state.about.provider.documents,
    state => state.about.result,
    (_, { t }) => t,
  ],
  (documents, result, t) => {
    if (result !== 'success') return null;
    const docs = values(omit([Object.keys(defaultState)], documents));

    const offers = pipe(
      filter(propEq('type', DOCUMENT_PUBLIC_OFFER)),
      map(({ id, title, type, changes, url, undated, ...others }) => ({
        id,
        title: title || t('offer_default_title', 'Оферта'),
        type,
        editedAt: getEditedTime(others),
        acceptedAt: undated ? '' : getAcceptedTime(others),
        changes,
        url,
      })),
    )(docs);

    const policy = find(propEq('type', DOCUMENT_PRIVACY_POLICY), docs);
    const consent = find(propEq('type', DOCUMENT_PRIVACY_CONSENT), docs);
    const privacy = (policy && consent
      ? [createPrivacy(policy, consent, t('policy_default_title', 'Политика'))]
      : []);

    return { offers, privacy };
  },
);

export default selectDocuments;
