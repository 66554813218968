import { useCallback, useState } from 'react'


export default function useLocked() {
  const [locked, setLocked] = useState(new Set<string>())
  const handleLockElement = useCallback((ID: string, lock: boolean) => {
    setLocked((prevState) => {
      const nextState = new Set(prevState)
      if (lock) {
        nextState.add(ID)
      } else {
        nextState.delete(ID)
      }
      return nextState
    })
  }, [])

  return [locked, handleLockElement] as [typeof locked, typeof handleLockElement]
}
