// @flow
import { useState, useCallback, useMemo } from 'react';

import prop from 'ramda/es/prop';
import find from 'ramda/es/find';
import indexBy from 'ramda/es/indexBy';
import map from 'ramda/es/map';
import isEmpty from 'ramda/es/isEmpty';
import assocPath from 'ramda/es/assocPath';
import propEq from 'ramda/es/propEq';
import evolve from 'ramda/es/evolve';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import toPairs from 'ramda/es/toPairs';
import keys from 'ramda/es/keys';
import pick from 'ramda/es/pick';
import __ from 'ramda/es/__';

import { type Descriptions } from './filter-description';
import { type AppliedState, defaultAppliedState } from './filter-state-applied';
import useUpdateRelationsState from './use-update-relations-state';

import {
  type State,
  getDefaultState,
  emptyState,
  defaultStateDataCurrent,
  defaultStateDataItems,
} from './filter-state';


const emptyFunc = () => {};

export type Actions = {
  filtersLoad: () => Promise<void> | void,
  updateCurrentState: (filterId: string, values: Array<string>) => void,
  resetAll: () => void,
  applyFilters: () => void,
  updateActivity: (filterId: string, active: boolean) => void,
  updateData: (filterId: string) => Promise<void> | void,
  resetFilter: (filterId: string) => void,
  getAppliedFilterActive: () => Array<[string, Array<string>]> | null,
  getApplied: () => { [key: string]: Array<string> } | null,
}

export const defaultActions: Actions = {
  filtersLoad: emptyFunc,
  updateCurrentState: emptyFunc,
  resetAll: emptyFunc,
  applyFilters: emptyFunc,
  updateActivity: emptyFunc,
  updateData: emptyFunc,
  resetFilter: emptyFunc,
  getAppliedFilterActive: () => null,
  getApplied: () => null,
};


export default function useFilter<Params = void>(
  filterDescriptions: Descriptions<Params>,
  params?: Params,
): [AppliedState, State, Descriptions<Params>, Actions, Params | void] {
  // состояние фильтров
  const [state, setState] = useState<State>(
    () => getDefaultState(map(prop('filterId'), filterDescriptions)),
  );

  // примененный фильтр, параметры по которым осуществляется фильтрация
  const [appliedState, setAppliedState] = useState<AppliedState>(defaultAppliedState);

  // начальная инициализация фильтра данными (например при открытии окна фильтра)
  const filtersLoad = useCallback(async () => {
    const newState = emptyState;

    for (const fiterDescription of filterDescriptions) { // eslint-disable-line no-restricted-syntax
      const { filterId, getItemsAndCurrent, activity } = fiterDescription;

      const active = activity ? activity(newState, params) : true;
      const [dataList, current] = (active
        ? await getItemsAndCurrent(newState, params) // eslint-disable-line no-await-in-loop
        : [[], defaultStateDataCurrent]);

      newState[filterId] = {
        filterId,
        items: indexBy(prop('id'), dataList),
        current: current || defaultStateDataCurrent,
        defaultCurrent: current || defaultStateDataCurrent,
        active,
      };
    }

    setState(newState);
  }, [filterDescriptions, params]);

  // обновление зависимых фильтров
  const handleUpdateRelations = useCallback(async (filterId: string): Promise<Array<string>> => {
    const { relations } = find(propEq('filterId', filterId), filterDescriptions) || {};
    if (relations && !isEmpty(relations)) {
      for (const relationId of relations) { // eslint-disable-line no-restricted-syntax
        const { getItemsAndCurrent, activity } = find(propEq('filterId', relationId), filterDescriptions) || {};
        if (!getItemsAndCurrent) {
          throw (new Error(`Не найден связанный фильтр ${relationId}`));
        }
        // очистить items, чтоб была индикация загрузки
        setState(evolve({
          [relationId]: {
            items: () => defaultStateDataItems,
          },
        }));

        const active = activity ? activity(state, params) : true;
        const [dataList, current] = (active
          ? await getItemsAndCurrent(state, params) // eslint-disable-line no-await-in-loop
          : [[], defaultStateDataCurrent]);

        setState(evolve({
          [relationId]: {
            items: () => indexBy(prop('id'), dataList),
            current: () => (current || defaultStateDataCurrent),
            defaultCurrent: () => (current || defaultStateDataCurrent),
          },
        }));
      }
    }
    return relations || [];
  }, [filterDescriptions, params, state]);

  // состояние для обновления зависимых фильтров
  // обновление происходит в следующем цикле рендера
  const addFilterForUpdateRelations = useUpdateRelationsState(handleUpdateRelations);

  // обновление состояния current определенного фильтра с идентификатором filterId
  // обычно вызывается в onChange контрола
  const updateCurrentState = useCallback((filterId: string, v: Array<string>) => {
    setState(assocPath([filterId, 'current'], v));
    addFilterForUpdateRelations(filterId);
  }, [addFilterForUpdateRelations]);

  // применить фильтр
  const applyFilters = useCallback(() => {
    setAppliedState({
      applied: map(prop('current'), state),
      active: map(prop('active'), state),
    });
  }, [state]);

  // обновление флага активности
  const updateActivity = useCallback((filterId: string, active: boolean) => {
    setState(assocPath([filterId, 'active'], active));
  }, []);

  // обновление данных (при появлении фильтра)
  const updateData = useCallback(async (filterId: string) => {
    const { getItemsAndCurrent } = find(propEq('filterId', filterId), filterDescriptions) || {};
    const [list, current] = await getItemsAndCurrent(state, params); // eslint-disable-line no-await-in-loop
    setState(evolve({
      [filterId]: {
        items: () => indexBy(prop('id'), list),
        current: () => (current || defaultStateDataCurrent),
        defaultCurrent: () => (current || defaultStateDataCurrent),
      },
    }));
    addFilterForUpdateRelations(filterId);
  }, [filterDescriptions, params, state, addFilterForUpdateRelations]);

  const reset = useCallback((filterId: string): ((State) => State) => {
    return evolve({
      [filterId]: ({ defaultCurrent, current, ...others }) => ({
        current: defaultCurrent,
        defaultCurrent,
        ...others,
      }),
    });
  }, []);

  // reset данных (при скрытии фильтра)
  const resetFilter = useCallback((filterId: string) => {
    setState(reset(filterId));
    addFilterForUpdateRelations(filterId);
  }, [reset, addFilterForUpdateRelations]);

  // reset всех фильтров
  const resetAll = useCallback(() => {
    (async () => {
      const body = document.getElementsByTagName('body')[0];
      body.style.cursor = 'progress';
      await filtersLoad();
      body.style.cursor = 'default';
      setAppliedState(defaultAppliedState);
    })();
  }, [filtersLoad]);

  // получение данных примененного фильтра // deprecated
  const getAppliedFilterActive = useCallback((): Array<[string, Array<string>]> | null => {
    const { applied, active } = appliedState;
    if (!applied) return null;
    return pipe(
      toPairs,
      filter(([key]) => active[key]),
    )(applied);
  }, [appliedState]);

  // получение данных примененного фильтра
  const getApplied = useCallback((): { [key: string]: Array<string> } | null => {
    const { applied, active } = appliedState;
    if (!applied) return null;

    return pipe(
      filter(Boolean),
      keys,
      pick(__, applied),
    )(active);
  }, [appliedState]);


  const res = useMemo(() => ([
    appliedState,
    state,
    filterDescriptions, {
      filtersLoad,
      updateCurrentState,
      resetAll,
      applyFilters,
      updateActivity,
      updateData,
      resetFilter,
      getAppliedFilterActive,
      getApplied,
    }, params,
  ]), [
    appliedState,
    state,
    filterDescriptions,
    filtersLoad,
    updateCurrentState,
    resetAll,
    applyFilters,
    updateActivity,
    updateData,
    resetFilter,
    getAppliedFilterActive,
    params,
    getApplied,
  ]);

  return res;
}
