// @flow
import React, { useState, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import RowLayout, { Header } from 'app/common/layouts/RowLayout';
import Modal, { ModalHeader } from 'app/common/components/Modal';
import { availabilityFetch } from 'app/actions/employees/employee/identifiers/availability';
import { checkRequest } from 'app/actions/employees/employee/identifiers/check';
import PlainButton from 'app/common/ui-next/plain-button';
import { IdentifiersData, IdentifiersList, AddIdentifierForm } from 'app/common/components-next/identifiers';
import CSSSticky from 'app/common/ui-components/css-sticky';

import useRequests from './useRequests';
import styles from './SectionIdentifiers.scss';


type Props = {
  personId: number,
  title: string,
  locked: boolean,
  baseUrl: string,
}

const SectionIdentifiers = ({ personId, title, locked }: Props) => {
  const { timezone } = useSelector(state => state.user, shallowEqual);

  const [viewAddIdentifier, setAddIdentifierVisibility] = useState(false);
  const toggleAddIdentifier = useCallback(() => {
    setAddIdentifierVisibility(current => !current);
  }, []);

  const {
    handleDataRequest,
    handleBlockIdentifier,
    handleUnblockIdentifier,
    handleChangeIdentifierAttribute,
    handleSubmitNewIdentifier,
  } = useRequests(personId);

  if (!personId) return null;

  return (
    <IdentifiersData
      locked={locked}
      personId={personId}
      timezone={timezone}
      onDataRequest={handleDataRequest}
      onBlockIdentifier={handleBlockIdentifier}
      onUnblockIdentifier={handleUnblockIdentifier}
      // REASSIGN недоступно здесь
      // reassignEnabled={reassignEnabled}
      // reassignTargets={reassignTargets}
      // reassignItemView={StudentItem}
      // onReassignIdentitier={handleReassignIdentifier}
      onChangeIdentifierAttribute={handleChangeIdentifierAttribute}
      onAddIdentifier={handleSubmitNewIdentifier}
    >
      <CSSSticky
        number={3}
        stickyClassName={styles.sticky}
        shadowClassName={styles.shadow}
      >
        <RowLayout first flex>
          <Header>{title}</Header>

          {!locked
            && <PlainButton onClick={toggleAddIdentifier}>
              {'Добавить идентификатор'}
            </PlainButton>}
        </RowLayout>
      </CSSSticky>

      <IdentifiersList className={styles.content} />

      <Modal
        isOpen={viewAddIdentifier && !locked}
        toggleModal={toggleAddIdentifier}
      >
        <ModalHeader>{'Добавление идентификатора'}</ModalHeader>
        <AddIdentifierForm
          onClose={toggleAddIdentifier}
          personId={personId}
          availabilityFetch={availabilityFetch}
          checkRequest={checkRequest}
        />
      </Modal>
    </IdentifiersData>
  );
};

export default SectionIdentifiers;
