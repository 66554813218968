import cn from 'classnames/bind'
import { useEffect, useMemo, useState, RefCallback } from 'react'

import styles from './page-template-content.scss'


const cx = cn.bind(styles)

type Props = {
  Component?: React.ElementType
  className?: string
  skipPadding?: boolean
  borderBottom?: boolean
  borderTop?: boolean
  smoothCorners?: boolean
  smoothCornersTop?: boolean
  smoothCornersBottom?: boolean
  shadowTop?: boolean
  shadowBottom?: boolean
  spaceBetweenVertical?: boolean
  skip?: boolean
  animate?: boolean
  horizontalLayout?: boolean
  horizontalLayoutTop?: boolean
  hasBottomSpace?: boolean
  reference?: RefCallback<any>
  children: React.ReactNode
}

const PageTemplateContent = ({
  Component = 'div',
  className,
  skipPadding,
  borderBottom = true,
  borderTop,
  smoothCorners,
  smoothCornersTop,
  smoothCornersBottom,
  shadowTop,
  shadowBottom,
  spaceBetweenVertical,
  skip,
  animate,
  horizontalLayout,
  horizontalLayoutTop,
  hasBottomSpace,
  reference,
  children,
}: Props) => {
  const [step, setStep] = useState<number>(0)
  const [maxHeight, setMaxHeight] = useState(0)
  const [animateFinal, setAnimateFinal] = useState(false)

  const style = useMemo(() => {
    if (step === 1) return { maxHeight: 0 }
    if (step > 1) return { maxHeight }
    return {}
  }, [maxHeight, step])

  const handleMount = (node: HTMLElement | null) => {
    if (reference) reference(node)
    if (!animate) return
    // TODO сейчас не работает для изначально видимых блоков
    if (node && node.getBoundingClientRect) {
      const { top, bottom } = node.getBoundingClientRect()
      const newHeight = Math.ceil(bottom - top)
      if (newHeight > 1 && !step) {
        setTimeout(() => setAnimateFinal(true), 200)
        setMaxHeight(newHeight)
        setStep(1)
        return
      }
      setStep(2)
    }
  }

  useEffect(() => {
    if (!animate) return
    if (skip) {
      setStep(0)
      setAnimateFinal(false)
    }
  }, [skip, animate])

  if (skip) return null

  const rootClassName = cx(styles.root, {
    padding: !skipPadding,
    borderTop,
    borderBottom,
    smoothCorners,
    smoothCornersTop,
    smoothCornersBottom,
    shadowTop,
    shadowBottom,
    spaceBetweenVertical,
    animate,
    animateInside: animate && !!step,
    animateFinal,
    horizontalLayout,
    horizontalLayoutTop,
    hasBottomSpace,
  }, className)

  return (
    <Component className={rootClassName} ref={handleMount} style={style}>
      {children}
    </Component>
  )
}

export default PageTemplateContent
