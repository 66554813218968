import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const RIGHTS_REQUEST = 'admin/RIGHTS_REQUEST';
export const RIGHTS_SUCCESS = 'admin/RIGHTS_SUCCESS';
export const RIGHTS_FAILURE = 'admin/RIGHTS_FAILURE';
export const RIGHTS_CLEAR = 'admin/RIGHTS_CLEAR';
export const RIGHTS_CHANGE = 'admin/RIGHTS_CHANGE';

export const rightsRequest = () => ({
  type: RIGHTS_REQUEST,
});

export const rightsSuccess = payload => ({
  type: RIGHTS_SUCCESS,
  payload,
});

export const rightsFailure = payload => ({
  type: RIGHTS_FAILURE,
  payload,
});

export const rightsClear = () => ({
  type: RIGHTS_CLEAR,
});

export const rightsChange = payload => ({
  type: RIGHTS_CHANGE,
  payload,
});

export const rightsFetch = roleId => async (dispatch) => {
  dispatch(rightsRequest());
  try {
    const res = await api(`food/roles/${roleId}/rights`, 'get');
    const rights = get(res, ['data', 'sections'], {});
    dispatch(rightsSuccess(rights));
    return true;
  } catch (error) {
    dispatch(rightsFailure({ err: 'Не удалось получить настройки прав пользователей', error }));
    return false;
  }
};
