// @flow
import React from 'react';
import classNames from 'classnames';

import styles from './RowLayout3d.scss';


type Props = {
  children: any,
  className?: string,
}

const RowLayout3d = ({ children, className }: Props) => (
  <div className={classNames(styles.root, className)}>
    {children}
  </div>
);

export default RowLayout3d;
