// @flow
import { useMemo } from 'react';

import { useVendingMachineSingle } from 'app/dataProviders/VendingDataProvider';

import type { CopyParams } from './useCopySlots';


export default function useCopySlotsParams(
  selectedIds: Array<string>,
  direction: 'from' | 'to',
): CopyParams {
  const [{ id: machineIdRaw }] = useVendingMachineSingle();
  const machineId = machineIdRaw ? String(machineIdRaw) : null;

  const params = useMemo(() => (
    direction === 'to'
      ? { fromMachineId: machineId, toMachineId: selectedIds[0] || null }
      : { fromMachineId: selectedIds[0] || null, toMachineId: machineId }
  ), [direction, machineId, selectedIds]);

  return params;
}
