// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import identity from 'ramda/es/identity';
import evolve from 'ramda/es/evolve';

import type { Sort } from 'app/common/hooks/useSortState';
import { tableColumnSort } from 'app/common/lib/sort';

import type { ReportSchool } from '../useReportData';
import { displayableData, type DisplayableData } from '../displayableData';


export type SchoolDisplayableItem = {
  id: number,
  title: string,
  byDate: Array<{
    date: string,
    data: DisplayableData,
  }>,
  total: DisplayableData,
  totalSortable: number,
}

export default function useActualSchoolsData(
  data: Array<ReportSchool>,
  sort: Sort,
): Array<SchoolDisplayableItem> {
  const { sortKey, sortDirection } = sort;

  const actualData: Array<SchoolDisplayableItem> = useMemo(() => {
    return map(({ school_id: id, school_title: title, total, data: dataByDate }: ReportSchool) => ({
      id,
      title,
      byDate: map(evolve({ date: identity, data: displayableData }), dataByDate),
      total: displayableData(total),
      totalSortable: prop('total_sales_sum', total),
    }), data);
  }, [data]);

  const actualDataSorted: Array<SchoolDisplayableItem> = useMemo(() => {
    return tableColumnSort(sortDirection, prop(sortKey), actualData);
  }, [actualData, sortDirection, sortKey]);

  return actualDataSorted;
}
