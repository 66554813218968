import api from 'app/actions/api';


export const SAVE_TOKEN_REQUEST = 'SAVE_TOKEN_REQUEST';
export const SAVE_TOKEN_SUCCESS = 'SAVE_TOKEN_SUCCESS';
export const SAVE_TOKEN_FAILURE = 'SAVE_TOKEN_FAILURE';

export const saveTokenRequest = () => ({
  type: SAVE_TOKEN_REQUEST,
});

export const saveTokenSuccess = data => ({
  type: SAVE_TOKEN_SUCCESS,
  payload: data,
});

export const saveTokenFailure = errString => ({
  type: SAVE_TOKEN_FAILURE,
  payload: { err: errString },
});


/**
* Сохранение токена внешнего сервиса
*/
export const saveToken = (service, token, vendor) => async (dispatch) => {
  const data = { service, token, vendor };
  dispatch(saveTokenRequest());
  try {
    await api('account/tokens', 'post', { data });
    dispatch(saveTokenSuccess({ [token]: { service, token, vendor } }));
  } catch (error) {
    dispatch(saveTokenFailure({ err: 'Ошибка синхронизации логина', error }));
  }
};
