// @flow
import React from 'react';
import moment from 'moment-timezone';
import { useSelector, shallowEqual } from 'react-redux';

import SettingsGear from 'app/common/components/Settings';
import IconFullScreen from 'app/common/icons/IconFullScreen';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import useGenerateFileRequests from 'app/components/common/reports/history/useGenerateFileRequests';
import { GenerateFile } from 'app/common/ui-components/progress-indicator';
import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';

import styles from './HeaderBar.scss';


type Props = {
  onFullscreen: (e: SyntheticEvent<HTMLElement>) => void,
}

const HeaderBar = ({ onFullscreen }: Props) => {
  // отключена генерация файла
  // const { reportId, details } = useSelector(state => state.reports.subsidies.params, shallowEqual);
  const { reportId } = useSelector(state => state.reports.subsidies.params, shallowEqual);

  const reportFileName = `subsidies_detailed_${moment().format('YYYYMMDD_HHmmss')}.xlsx`;
  const generateFileRequests = useGenerateFileRequests(reportId, reportFileName);

  return (
    <div className={styles.root}>
      {/* отключена генерация файла */}
      {/* {details
        && <GenerateFile */}
      <GenerateFile
        className={styles.download}
        labelClassName={styles.downloadLabel}
        label="Скачать как"
        generateAndDownloadPrompt="Сгенерировать и скачать в формате «документ Excel»"
        downloadPrompt="Скачать в формате «документ Excel»"
        {...generateFileRequests}
      >
        <PlainIcon
          width={20}
          height="auto"
          type={iconTypes.EXCEL}
        />
      </GenerateFile>

      <div className={styles.historyContent}>
        <HistoryLink reportType={reportTypes.REPORT_SUBSIDIES_DETAILED} />
      </div>

      <SettingsGear
        dataKey="reports"
        title="Количество записей"
      />
      <PlainButtonAsLink
        id="fullscreen"
        ariaLabel="Открыть в полном окне"
        onClick={onFullscreen}
      >
        <IconFullScreen className={styles.fullscreenIcon} />
      </PlainButtonAsLink>
    </div>
  );
};

export default HeaderBar;
