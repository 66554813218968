// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';

import styles from './FiltersDropDownLayout.scss';


type Props = {
  children: Node,
  className?: string
}

const Column = ({ children, className }: Props) => (
  <div className={classNames(styles.col, className)}>
    {children}
  </div>
);

export default Column;
