import { CompensationInfoStatus } from 'app/dataTypes/compensation/info'


export const getStatusColor = (status: CompensationInfoStatus) => {
  if (status === CompensationInfoStatus.Error)
    return '#eb4d4d'
  if (status === CompensationInfoStatus.InProgress)
    return '#ef6c00'
  if (status === CompensationInfoStatus.Ready)
    return '#4caf50'

  return '#616161'
}
