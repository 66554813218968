// @flow
import PlainInput from './PlainInput';
import PlainInputPhone from './PlainInputPhone';
import PlainInputFocused from './PlainInputFocused';
import PlainInputDouble from './PlainInputDouble';
import PlainInputPhoneCC from './PlainInputPhoneCC';
import PlainInputSNILS from './PlainInputSNILS';
import PlainInputSearch from './PlainInputSearch';
import PlainInputW26 from './PlainInputW26';
import PlainInputW34 from './PlainInputW34';
import PlainInputW58 from './PlainInputW58';
import PlainInputTime from './PlainInputTime';
import useValidatePhoneCC from './useValidatePhoneCC';

export default PlainInput;
export {
  PlainInputPhone,
  PlainInputFocused,
  PlainInputDouble,
  PlainInputPhoneCC,
  PlainInputSNILS,
  PlainInputSearch,
  PlainInputW26,
  PlainInputW34,
  PlainInputW58,
  PlainInputTime,
  useValidatePhoneCC,
};
