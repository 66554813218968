// @flow
import { useState, useCallback, useMemo } from 'react';
import descend from 'ramda/es/descend';
import sort from 'ramda/es/sort';
import prop from 'ramda/es/prop';
import split from 'ramda/es/split';
import map from 'ramda/es/map';

import { api } from 'app/api';
import { GET_CHANGES, convertParams, convertResult } from 'app/common/api/requests/food/schools/changes';
import type { Change, ChangesData, Count, Params } from 'app/dataTypes/OrgSettingsChanges.types';


export type State = {
  changes: Array<Change>,
  loading: boolean,
  count: Count,
}

export type Actions = {
  request: () => Promise<void> | void,
}

export const defaultState: State = {
  changes: [],
  loading: true,
  count: 0,
};

export const defaultActions: Actions = {
  request: () => {},
};

export default function useChanges(params: Params): [State, Actions] {
  const [changes, setChanges] = useState<ChangesData>([]);
  const [count, setCount] = useState<Count>(0);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    const { section } = params;
    if (!section) {
      return;
    }
    setLoading(true);
    const res = await api.request(GET_CHANGES, {
      error: 'Не удалось получить данные по изменениям настроек',
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      const [changes_, count_] = res;
      const sortedChanges = sort(descend(prop('createdSeconds')), changes_);
      setChanges(sortedChanges);
      setCount(count_);
    }
    setLoading(false);
  }, [params]);

  const changesMultiline = useMemo(() => {
    return map(({ description, ...others }) => ({
      description: split('\n', description),
      ...others,
    }), changes);
  }, [changes]);

  return [{
    changes: changesMultiline,
    count,
    loading,
  }, {
    request,
  }];
}
