
import { CopyParams } from 'app/dataTypes/subsidy/settings'

import { Request } from '../../../index'


type ParamsAPI = {
  to: Array<number>
  order_type: 'common' | 'cafeteria' | 'camp'
}

type PathParams = {
  orgID: string
}

export const convertParams = ({ orgID, to, subsidyCategoriesType }: CopyParams): [ParamsAPI, PathParams] => {
  return [{
    to: to.map(val => parseInt(val, 10)),
    order_type: subsidyCategoriesType,
  }, { orgID }]
}

export const convertResult = ({ result }: { result: 'success' }) => {
  return !!result
}

export const POST_SCHOOL_SUBSIDY_ORDERS_SETTINGS_COPY: Request<CopyParams, boolean, PathParams> = [
  'food/schools/{orgID}/subsidy_orders_settings_copy',
  'post',
  ['data'],
]
