// @flow
import React, { useCallback } from 'react';
import path from 'ramda/es/path';
import values from 'ramda/es/values';

import CommonPage from 'app/common/templates/CommonPage';
import VendingMachine, { defaultSection } from 'app/components/VendingMachine';
import { useVendingMachineSingle, useRelatedOrgs } from 'app/dataProviders/VendingDataProvider';
import { DotsLoader } from 'app/common/components/Loader';

import getParentBaseUrl from './getParentBaseUrl';
import getBaseUrl from './getBaseUrl';


const parentBaseUrl = getParentBaseUrl();

type Props = {
  match: {
    params: {
      id?: string,
      section?: string,
      categoryId?: string,
    },
  },
  history: {
    push: (path: string) => void,
    replace: (path: string) => void,
  },
  location: {
    pathname: string,
  }
}

const VendingMachinePage = ({ match, history }: Props) => {
  const { params: { id, section, categoryId } } = match;
  const { replace, push } = history;

  const redirectToDefault = useCallback(() => {
    replace(getBaseUrl({ id, section: defaultSection }));
  }, [id, replace]);

  const redirectToCategory = useCallback((newCategoryId: string, writeToHistory?: boolean = false) => {
    const redirectTo = getBaseUrl({ id, section, categoryId: newCategoryId });
    if (writeToHistory) {
      push(redirectTo);
      return;
    }
    replace(redirectTo);
  }, [id, replace, push, section]);

  const redirectToParent = useCallback(() => {
    replace(parentBaseUrl);
  }, [replace]);

  const currentSection = section || '';
  const currentCategoryId = categoryId;

  const [{ title }] = useVendingMachineSingle();
  const relatedOrgs = useRelatedOrgs();

  const relatedOrgTitle = path([0, 'title'], values(relatedOrgs));

  const header = (
    relatedOrgTitle && title
      ? `${relatedOrgTitle}: ${title}`
      : <DotsLoader simple />
  );

  return (
    <CommonPage header={header} stickable>
      <VendingMachine
        id={parseInt(id, 10)}
        baseUrl={getBaseUrl({ id })}
        currentSection={currentSection}
        currentCategoryId={currentCategoryId}
        redirectToDefaulSection={redirectToDefault}
        redirectToParent={redirectToParent}
        redirectToCategory={redirectToCategory}
        parentUrl={parentBaseUrl}
      />
    </CommonPage>
  );
};

export default VendingMachinePage;
