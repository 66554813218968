import { ReactNode, useCallback } from 'react'
import cn from 'classnames/bind'

import PlainIcon, { iconType, iconColor } from 'app/common/ui-next/plain-icon'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import styles from './report-section-fullscreen.scss'


const cx = cn.bind(styles)

type Props = {
  children: ReactNode
  fullscreen: boolean
}

const ReportSectionFullscreen = ({ children, fullscreen }: Props) => {
  return (
    <div className={cx({ fullscreen })}>
      {children}
    </div>
  )
}

type PropsFS = {
  className?: string
  toggleFullscreenState: (dispatch: (state: boolean) => boolean) => void
}

export const FullscreenDefaultButton = ({ className, toggleFullscreenState }: PropsFS) => {
  const handleClick = useCallback(() => {
    toggleFullscreenState(prevState => !prevState)
  }, [toggleFullscreenState])

  return (
    <PlainButtonAsLink onClick={handleClick}>
      <PlainIcon
        className={cx(styles.icon, className)}
        type={iconType.FULLSCREEN}
        color={iconColor.COLOR_GRAY}
        width={18}
      />
    </PlainButtonAsLink>
  )
}

export default ReportSectionFullscreen
