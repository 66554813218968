// @flow
import React from 'react';

import MultiLineContainer from './MultiLineContainer';
import Entry from './Entry';


type Props = {
  className?: string,
  showDuty?: boolean,
  title1?: string,
  title2?: string,
  title3?: string,
  title4?: string,
  title5?: string,
}

const MultiLineTotalTitles = ({
  className,
  showDuty,
  title1,
  title2,
  title3,
  title4,
  title5,
}: Props) => {
  return (
    <MultiLineContainer className={className} bottomSpace>
      <Entry alignRight>{title1 || ''}</Entry>
      <Entry alignRight>{title2 || ''}</Entry>
      <Entry alignRight>{title3 || ''}</Entry>
      <Entry alignRight>{title4 || ''}</Entry>
      {showDuty ? <Entry alignRight>{title5 || ''}</Entry> : null}
    </MultiLineContainer>
  );
};

export default MultiLineTotalTitles;
