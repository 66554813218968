import { useState, useCallback } from 'react'
import assoc from 'ramda/es/assoc'
import dissoc from 'ramda/es/dissoc'
import ifElse from 'ramda/es/ifElse'
import has from 'ramda/es/has'
import reduce from 'ramda/es/reduce'


export type DaysState = Record<string, string>

type Options = {
  multiple?: boolean,
}

const defaultState = {}

export default function useDaysSelectedState(options?: Options) {
  const { multiple } = options || {}
  const [days, setDays] = useState<DaysState>(defaultState)

  const dayToggle = useCallback((key: string) => {
    if (multiple) {
      setDays(ifElse(has(key), dissoc(key), assoc(key, key)))
      return
    }
    setDays(assoc(key, key, defaultState))
  }, [multiple])

  const update = useCallback((dateItems: Array<string>) => {
    setDays(reduce((acc, val) => {
      acc[val] = val
      return acc
    }, {}, dateItems))
  }, [])

  return [days, {
    dayToggle,
    update,
  }] as const
}
