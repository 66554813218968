import { useCallback, useState } from 'react'
import { pick } from 'ramda'

import { api } from 'app/api'
import { POST_SCHOOL_LIMITS, convertResult, convertParams } from 'app/common/api/requests/food/schools/limits/post'
import { OrgsLimitsType } from 'app/dataTypes/org/limits'


type Options = {
  orgId: number
  data: OrgsLimitsType | null
  onSubmited: () => void
}

export function useOrgLimitsSubmit({ orgId, data, onSubmited }: Options) {
  const [saving, setSaving] = useState(false)

  const submit = useCallback(async (
    overrideOrgIds?: Array<number>,
    onlyThisFields?: Array<keyof OrgsLimitsType>,
  ) => {
    if (!data) return false
    setSaving(true)
    const schoolsIds = overrideOrgIds ? overrideOrgIds.map(String) : [orgId]
    const res = await api.request(POST_SCHOOL_LIMITS, {
      error: 'Не удалось получить настройки класса из набора 2',
      convertions: {
        convertResult,
        convertParams,
      },
      params: {
        schoolsIds,
        orgLimits: (onlyThisFields
          ? pick(onlyThisFields, data)
          : data),
      },
    })
    setSaving(false)
    onSubmited()
    return !!res
  }, [orgId, data, onSubmited])


  return {
    submit,
    saving,
  }
}
