
export enum Section {
  Production = 'production',
  Complexes = 'complexes',
  Reports = 'reports',
  OrgGroups = 'orggroups'
}

export const isSection = (value: any): value is Section[keyof Section] => {
  return Object.values(Section).includes(value)
}

export const defaultSection = Section.Production
