
import MaskedInput from 'react-maskedinput';
import withProps from 'recompose/withProps';

import PlainInput from './PlainInput';


const w58validate = char => /[a-fA-F0-9]/.test(char);
const w58transform = char => char.toUpperCase();

const PlainInputW34 = withProps({
  component: MaskedInput,
  mask: 'XX XX XX XX XX XX XX',
  formatCharacters: {
    X: {
      validate: w58validate,
      transform: w58transform,
    },
  },
})(PlainInput);

export default PlainInputW34;
