// @flow
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import prop from 'ramda/es/prop';
import { useTranslation, Trans } from 'react-i18next';

import Modal from 'app/common/components/Modal';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import Button from 'app/common/ui/Button';
import styles from './Privacy.scss';


type PrivacyType = {
  ids?: Array<number>,
  title?: string,
  urls?: [string, string],
  changes?: string,
};

type Props = {
  doc: PrivacyType,
  accepted?: boolean,
  accepting?: boolean,
  handleChangeAccepted?: (e: SyntheticEvent<HTMLElement>) => void,
  handleLogout?: (e: SyntheticEvent<HTMLElement>) => void,
  handleAcceptPrivacy?: (e: SyntheticEvent<HTMLElement>) => void,
  handleClose?: (e: SyntheticEvent<HTMLElement>) => void,
};

const Privacy = ({
  doc: { urls, title, changes },
  accepted,
  accepting,
  handleLogout,
  handleChangeAccepted,
  handleAcceptPrivacy,
  handleClose,
}: Props) => {
  const agreementLink = prop(1, urls);
  const conditionsLink = prop(0, urls);
  const { t } = useTranslation('about');

  return (
    <Modal close={handleClose}>
      <div className={styles.content}>
        <h1>{title || t('privacy_header')}</h1>

        <div>
          <p>
            <a href={agreementLink} target="_blank" rel="noopener noreferrer">
              {t('privacy_agreement_link')}
            </a>
          </p>
          <p>
            <a href={conditionsLink} target="_blank" rel="noopener noreferrer">
              {t('conditions_agreement_link')}
            </a>
          </p>

          {changes
            && <p>
              {t('changes_title')}
              {':'}
            </p>}
          {changes
            && <Scrollbars style={{ width: '100%', height: 200, display: 'block' }} className={styles.text}>
              <div dangerouslySetInnerHTML={{ __html: changes }} />
            </Scrollbars>}

          {!handleClose
            && <PlainCheckbox
              value="privacy_agree"
              label={<span>
                <Trans i18nKey="about:privacy_agree">
                  {'Согласен на '}
                  <a href={agreementLink} target="_blank" rel="noopener noreferrer">
                    {'обработку персональных данных'}
                  </a>
                  {' и принимаю '}
                  <a href={conditionsLink} target="_blank" rel="noopener noreferrer">
                    {'условия политики их обработки'}
                  </a>
                </Trans>
              </span>}
              checked={accepted}
              onChange={handleChangeAccepted}
              disabled={accepting}
            />}

        </div>

        {!handleClose
          ? <div className={styles.buttons}>
            <Button
              outline
              onClick={handleLogout}
            >
              <Trans i18nKey="about:disagree">
                {'Не принимаю (выйти из приложения)'}
              </Trans>
            </Button>
            <Button
              disabled={!accepted || accepting}
              onClick={handleAcceptPrivacy}
            >
              {accepting ? t('privacy_submit_bt_loading') : t('privacy_submit_bt')}
            </Button>
          </div>
          : <div className={styles.buttons}>
            <Button onClick={handleClose}>
              {t('close')}
            </Button>
          </div>}
      </div>
    </Modal>
  );
};

export default Privacy;
