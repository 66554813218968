import { sortStringsAsNumbers } from 'app/common/lib/sort'
import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { defaultYearSelection, yearFilter, yearFilterSelection } from 'app/dataProviders/filter-items'
import { prop } from 'ramda'


const sortYears = sortStringsAsNumbers('up', prop('title'))

type Props = {
  id: string
  pending: boolean
}

export const YearFilter = ({ id, pending }: Props) => {
  return (
    <PlainItemsSelectorBox
      id={id}
      label="Год"
      header="Выберите год"
      dataBoxData={yearFilter}
      dataBoxSelection={yearFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection={false}
      defaultSelection={defaultYearSelection}
      disabled={pending}
      sortFunc={sortYears}
      resetOnDismount
    />
  )
}
