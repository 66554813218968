// @flow

type Column = {
  id: string,
  title: string,
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
  },
}

const columns: Array<Column> = [{
  id: 'place',
  title: 'Место размещения',
  props: {
    alignLeft: true,
  },
}, {
  id: 'title',
  title: 'Наименование',
  props: {
    alignLeft: true,
  },

}, {
  id: 'number',
  title: 'Номер и модель',
  props: {
    alignLeft: true,
  },

}, {
  id: 'goodsAmount',
  title: 'Кол-во товара',
  props: {
    alignLeft: true,
  },

}, {
  id: 'loadedAt',
  title: 'Последняя загрузка',
  props: {
    alignLeft: true,
  },

}, {
  id: 'lastSellAt',
  title: 'Последняя покупка',
  props: {
    alignLeft: true,
  },

}, {
  id: 'actions',
  title: '',
  props: {
    alignRight: true,
  },

}];

export type ColumnVisibility = {
  place: boolean,
  title: boolean,
  number: boolean,
  goodsAmount: boolean,
  loadedAt: boolean,
  lastSellAt: boolean,
  errors: boolean,
  actions: boolean,
}

export const defaultColumnVisibility = {
  place: true,
  title: true,
  number: true,
  goodsAmount: true,
  loadedAt: true,
  lastSellAt: true,
  errors: true,
  actions: true,
};

export default columns;
