import api from 'app/actions/api';


/**
* Запрос типов блюд
*/

export const DISHES_TYPES_REQUEST = 'DISHES_TYPES_REQUEST';
export const DISHES_TYPES_SUCCESS = 'DISHES_TYPES_SUCCESS';
export const DISHES_TYPES_FAILURE = 'DISHES_TYPES_FAILURE';

const dishesTypesRequest = id => ({
  type: DISHES_TYPES_REQUEST,
  payload: id,
});

const dishesTypesSuccess = payload => ({
  type: DISHES_TYPES_SUCCESS,
  payload,
});

const dishesTypesFailure = payload => ({
  type: DISHES_TYPES_FAILURE,
  payload,
});

export const dishesTypesFetch = () => async (dispatch) => {
  dispatch(dishesTypesRequest());
  try {
    const res = await api('food/items/sale_types', 'get');
    dispatch(dishesTypesSuccess(res.data));
  } catch (error) {
    dispatch(dishesTypesFailure({ err: 'Запрос типов блюд не удался', error }));
  }
};
