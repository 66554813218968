import Observable from 'app/common/classes/observable'
import { prop, reduce, sortBy } from 'ramda'


export const stuckAttributeName = 'stuck'
export const shadowAttributeName = 'shadow'

type StickyItem = {
  height: number
  applyToChildrenTh: boolean
  resetShift: boolean
  skipShift: boolean
  stuck: boolean
  node: HTMLElement | null
}

const emptyStickyItem: StickyItem = {
  height: 0,
  applyToChildrenTh: false,
  resetShift: false,
  skipShift: false,
  stuck: false,
  node: null,
}

type AddOptions = {
  applyToChildrenTh?: boolean
  resetShift?: boolean
  skipShift?: boolean
}

const defaultAddOptions: AddOptions = {
  applyToChildrenTh: false,
  resetShift: false,
  skipShift: false,
}

const createStyle = (ID: number, shift: number = 0) => {
  if (ID > 0) {
    return `position: sticky; top: ${shift}px; z-index: 1;`
  }
  return `position: sticky; bottom: ${shift}px; z-index: 1;`
}

const assignStyle = (node: Element, style: string, applyToChildrenTh: boolean) => {
  const stylingNodes = applyToChildrenTh ? node.querySelectorAll('th') : [node]
  stylingNodes.forEach(nodeItem => nodeItem.setAttribute('style', style))
}

/**
 * Sticky class
 */

class Sticky extends Observable {
  IDByNode: Map<Element, number> = new Map()

  stickyItemByID: Map<number, StickyItem> = new Map()

  ro: ResizeObserver

  ioByID: Map<number, IntersectionObserver> = new Map()

  constructor() {
    super()
    this.ro = new ResizeObserver(this.onResize)
  }

  calcShift(targetID: number): number {
    const { resetShift: rsh } = this.stickyItemByID.get(targetID) || emptyStickyItem
    if (rsh) return 0
    const items = Array.from(this.stickyItemByID.entries())
    // !TODO, сейчас не учитывает отрицательные значения (прилипание снизу)
    const actualItems = items.filter(([ID]) => (ID > 0 && ID < targetID))
    const actualSortedItems = sortBy(prop(0), actualItems)
    const shift = reduce((acc, [, { height, resetShift, skipShift }]) => {
      const actualHeight = skipShift ? 0 : height
      return resetShift ? actualHeight : (acc + actualHeight)
    }, 0, actualSortedItems)

    return shift
  }

  updateStyles = (el: HTMLElement, ID: number) => {
    const current = this.stickyItemByID.get(ID) || emptyStickyItem
    const shift = this.calcShift(ID)
    assignStyle(el, createStyle(ID, shift), current.applyToChildrenTh)
  }

  onResize = (entries: Array<ResizeObserverEntry>) => {
    entries.forEach(({ target }: ResizeObserverEntry) => {
      const { height = 0 } = target.getBoundingClientRect()
      const ID = this.IDByNode.get(target) || 0
      if (!ID) {
        console.warn('👻 not found ID by node (sticky, onResize)')
        return
      }
      const current = this.stickyItemByID.get(ID) || emptyStickyItem
      this.stickyItemByID.set(ID, { ...current, height })

      const shift = this.calcShift(ID)
      assignStyle(target, createStyle(ID, shift), current.applyToChildrenTh)
    })
  }

  onIntersect = (entries: Array<IntersectionObserverEntry>) => {
    const entrie = entries[0]
    const { target, intersectionRatio } = entrie
    const ID = this.IDByNode.get(target) || 0
    if (!ID) {
      console.warn('👻 not found ID by node (sticky, onIntersect)')
      return
    }
    const stuckStatus = intersectionRatio < 1
    const current = this.stickyItemByID.get(ID) || emptyStickyItem
    this.stickyItemByID.set(ID, { ...current, stuck: stuckStatus })

    const items = Array.from(this.stickyItemByID.entries())
    const sorted = sortBy(prop(0), items)

    sorted.forEach(([id, { node, stuck }], index, allItems) => {
      if (node) {
        if (id < 0) { // TODO прилипание снизу
          node.toggleAttribute(stuckAttributeName, stuck)
          node.toggleAttribute(shadowAttributeName, stuck)
          return
        }
        node.toggleAttribute(stuckAttributeName, stuck)
        const nextItemStucked = allItems[index + 1] && allItems[index + 1][1].stuck
        const shadow = stuck && !nextItemStucked
        node.toggleAttribute(shadowAttributeName, shadow)
      }
    })

    this.updateSubscribers()
  }

  addResizeObserver(el: HTMLElement) {
    this.ro.observe(el)
  }

  addIntersectionObserver(ID: number, el: HTMLElement) {
    const shift = this.calcShift(ID)
    const intersectionMargin = ID > 0
      ? `${-shift - 1}px 0px 0px 0px`
      : `0px 0px ${-shift - 1}px 0px`

    const currentIntersectionObserver = this.ioByID.get(ID)
    if (currentIntersectionObserver) {
      currentIntersectionObserver.disconnect()
    }

    const io = new IntersectionObserver(this.onIntersect, {
      threshold: 1,
      rootMargin: intersectionMargin,
    })
    io.observe(el)

    this.ioByID.set(ID, io)
  }

  add(el: HTMLElement, ID: number, addOptions?: AddOptions) {
    const {
      applyToChildrenTh = false,
      resetShift = false,
      skipShift = false,
    } = { ...defaultAddOptions, ...addOptions }

    assignStyle(el, createStyle(ID), applyToChildrenTh)

    this.IDByNode.set(el, ID)
    const { height = 0 } = el.getBoundingClientRect()
    this.stickyItemByID.set(ID, {
      ...emptyStickyItem,
      height,
      applyToChildrenTh,
      resetShift,
      skipShift,
      node: el,
    })

    this.addResizeObserver(el)
    this.addIntersectionObserver(ID, el)
  }

  remove(el: HTMLElement) {
    this.ro.unobserve(el)
    const ID = this.IDByNode.get(el)
    this.IDByNode.delete(el)
    if (ID) {
      this.stickyItemByID.delete(ID)
      const io = this.ioByID.get(ID)
      if (io) {
        io.disconnect()
      }
    }
  }
}

export default new Sticky()
