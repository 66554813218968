import type { Rights } from 'app/common/constants/rights'


export type RightsPath<T extends keyof Rights> = readonly [T, keyof Rights[T]]
export type RightsPathUnrestricted = readonly [string, string]

export type RightsObject = {
  edit: boolean,
  read: boolean,
}

export const fullAccessObject: RightsObject = {
  edit: true,
  read: true,
}

export function getAccessObject<T extends keyof Rights>(
  pathToRights: RightsPath<T>,
  rights: Rights,
): RightsObject {
  const [p1, p2] = pathToRights
  const rightsObject = rights?.[p1]?.[p2] || { edit: false, read: true }
  return rightsObject as RightsObject
}

export function getWriteAccess<T extends keyof Rights>(
  pathToRights: RightsPath<T>,
  rights: Rights,
): boolean {
  const { edit } = getAccessObject(pathToRights, rights)
  return edit
}

export function getReadAccess<T extends keyof Rights>(
  pathToRights: RightsPath<T>,
  rights: Rights,
): boolean {
  const { read } = getAccessObject(pathToRights, rights)
  return read
}
