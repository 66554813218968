import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import drop from 'ramda/es/drop';
import concat from 'ramda/es/concat';
import omit from 'ramda/es/omit';

import {
  withRights,
  withAccess,
  // withAdminAccess,
  // withAdminRights,
  getReadAccess,
  getAdminReadAccess,
  withReadAccess,
} from 'app/common/containers/withRights';

import { router } from 'app/selectors/router';


// Роль для прав на основе школ
const selectRoleId = createSelector(
  (state, props) => {
    const orgId = prop('orgId', props) || router.getOrgId(props);
    if (!orgId) {
      console.log('Нет orgId!', props);
      return undefined;
    }

    return (
      path(['orgs', 'orgs', 'schools', orgId, 'role_id'], state)
      || path(['orgs', 'current', 'schools', orgId, 'role_id'], state)
    );
  },
  roleId => roleId,
);

// Селектор пути в состоянии для получения прав на основе школ
const selectBySchool = rightsPath => createSelector(
  [
    selectRoleId,
    (state, props) => !!path(['orgs', 'orgs', 'roles', selectRoleId(state, props), 'rights'], state),
    (state, props) => !!path(['orgs', 'current', 'roles', selectRoleId(state, props), 'rights'], state),
  ],
  (roleId, testPath1, testPath2) => {
    if (!roleId || !(testPath1 || testPath2)) {
      return rightsPath;
    }
    const dropped = drop(1, rightsPath);
    return concat(
      testPath1
        ? ['orgs', 'orgs', 'roles', roleId, 'rights']
        : ['orgs', 'current', 'roles', roleId, 'rights'],
      dropped,
    );
  },
);

// Меню школ
const toMenuMenu = selectBySchool(['userRights', 'Меню', 'Меню']);
export const withMenuMenuRights = withRights(toMenuMenu);
export const withMenuMenuAccess = withAccess(toMenuMenu);

// Блюда школы
const DishesOrgDishes = selectBySchool(['userRights', 'Блюда', 'Блюда школы']);
export const withDishesOrgDishesRights = withRights(DishesOrgDishes);
export const withDishesOrgDishesAccess = withAccess(DishesOrgDishes);

// Блюда поставщика
export const withDishesDishesRights = withRights(['userRights', 'Блюда', 'Блюда поставщика']);
export const withDishesDishesAccess = withAccess(['userRights', 'Блюда', 'Блюда поставщика']);

// Категории блюд школы
const toDishesCategories = selectBySchool(['userRights', 'Блюда', 'Категории блюд']);
export const withDishesCategoriesRights = withRights(toDishesCategories);
// export const withDishesCategoriesAccess = withAccess(toDishesCategories);
// Категории блюд всегда доступны на чтение, поэтому Access не используется

// Комллекты блюд школы
const toKitsKits = selectBySchool(['userRights', 'Комплекты', 'Комплекты']);
export const withKitsKitsRights = withRights(toKitsKits);
export const withKitsKitsAccess = withAccess(toKitsKits);

// Состав комплектов блюд школы
const toKitsConsist = selectBySchool(['userRights', 'Комплекты', 'Состав комплектов']);
export const withKitsConsistRights = withRights(toKitsConsist);
export const withKitsConsistAccess = withAccess(toKitsConsist);

// Категории меню
export const withSettingsMenuCategoriesRights = withRights(['userRights', 'Настройки', 'Категории меню']);
export const withSettingsMenuCategoriesAccess = withAccess(['userRights', 'Настройки', 'Категории меню']);

// Реквизиты
export const withSettingsPropsRights = withRights(['userRights', 'Настройки', 'Реквизиты']);
export const withSettingsPropsAccess = withAccess(['userRights', 'Настройки', 'Реквизиты']);

// Реквизиты -- нет больше такого поля в правах
// export const withSettingsSubsidiesRights = withRights(['userRights', 'Настройки', 'Дотации']);
// export const withSettingsSubsidiesAccess = withAccess(['userRights', 'Настройки', 'Дотации']);

// Сотрудники
export const withUsersMyEmployeesRights = withRights(['userRights', 'Пользователи', 'Мои сотрудники']);
export const withUsersMyEmployeesAccess = withAccess(['userRights', 'Пользователи', 'Мои сотрудники']);

// Доступ основанный на статусе образовательной организации ('deleted' & restore date ('from'))
const selectAccess = createSelector(
  [
    (state, props) => path(['orgs', 'current', 'schools', router.getOrgId(props)], state),
    (stste, props) => router.getDate(props),
  ],
  (org, date) => {
    if (!org) return true;
    const { deleted, from } = org;
    return !(deleted || (from && date && !(new Date(date) > new Date(from))));
  },
);

export const withCurrentOrgRights = compose(
  withRouter,
  connect(
    (state, props) => ({
      writeAccess: selectAccess(state, props),
    }),
  ),
);


// права на весь раздел Настройки в главном меню
// не показываем если во все подразделы нет прав
export const selectSettingsAccessIndex = createSelector(
  [
    // блюда
    state => getReadAccess(state, { statePath: ['userRights', 'Блюда', 'Блюда поставщика'] }),
    // сотрудники
    state => getReadAccess(state, { statePath: ['userRights', 'Пользователи', 'Мои сотрудники'] }),
    // администрирование (только админы, тип пользователя 'user')
    getAdminReadAccess,
    // реквизиты
    state => getReadAccess(state, { statePath: ['userRights', 'Настройки', 'Реквизиты'] }),
  ],
  (...args) => args.indexOf(true),
);

export const withSettingsAccess = compose(
  connect(
    (state, props) => ({
      readAccess: selectSettingsAccessIndex(state, props) !== -1,
    }),
  ),
  withReadAccess,
);

export const omitExtra = mapProps(omit(['match', 'location', 'history']));
