// @flow
import { useEffect } from 'react';

import { availableSections, defaultSection, sections } from './sections';


export default function useValidSection(
  section: string,
  redirectToDefaulSection: () => void,
): $Values<typeof sections> {
  const valid = availableSections.includes(section);

  useEffect(() => {
    if (!valid) {
      redirectToDefaulSection();
    }
  }, [valid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    valid
      ? ((section: any): $Values<typeof sections>)
      : defaultSection
  );
}
