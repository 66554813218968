// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames';
import { range } from 'ramda';

import { HorizontalRuleItem, VerticalRuleItem } from './rule-items';
import styles from './rulers.scss';


const emtySet = new Set();

type Props = {
  children: Node,
  className?: string,
  columns: number,
  rows: number,
  reference?: Ref<any>,
  selectionMode?: boolean,
  selectedColumns?: Set<number>,
  selectedRows?: Set<number>,
  onSelectColumn?: (checked: boolean, index: number) => void,
  onSelectRow?: (checked: boolean, index: number) => void,
}

const Rulers = ({
  children,
  className,
  columns,
  rows,
  reference,
  selectionMode,
  selectedColumns = emtySet,
  selectedRows = emtySet,
  onSelectColumn,
  onSelectRow,
}: Props): Node => {
  if (!columns || !rows) {
    return children;
  }

  const columnNumbers = range(1, columns + 1);
  const rowNumbers = range(1, rows + 1);

  return (
    <div className={cn(styles.root, className)} ref={reference}>
      <div />

      <div className={styles.horizontalItems}>
        {columnNumbers.map((number, index) => (
          <HorizontalRuleItem
            key={number}
            title={String(number)}
            index={index}
            checkbox={selectionMode}
            checked={selectedColumns.has(index)}
            onChange={onSelectColumn}
          />
        ))}
      </div>

      <div className={styles.verticalItems}>
        {rowNumbers.map((number, index) => (
          <VerticalRuleItem
            key={number}
            title={String(number)}
            index={index}
            checkbox={selectionMode}
            checked={selectedRows.has(index)}
            onChange={onSelectRow}
          />
        ))}
      </div>

      <div>{children}</div>
    </div>
  );
};

export default Rulers;
