// @flow
import { createSelector } from 'reselect';
import prop from 'ramda/es/prop';

import { tableColumnSort, tableColumnSortClass } from 'app/common/lib/sort';


/**
 * Селектор данных школ с учетом сортировки
 */

export type OutgoingProps = {
  idx: number,
  schoolTitle: string,
  data: Array<Object>,
  total: number,
  split1?: number,
  split2?: number,
}


type Selector = (state: Object, props: Object) => Array<OutgoingProps>
const selectSchools: Selector = createSelector(
  [
    (_, { sort: { sortDirection } }) => sortDirection,
    (_, { sort: { sortKey } }) => sortKey,
    state => state.reports.subsidies.data.schools,
  ],
  (dir, key, items) => {
    if (key === 'schoolTitle') {
      return tableColumnSortClass(dir, prop(key), items);
    }
    return tableColumnSort(dir, prop(key), items);
  },
);

export default selectSchools;
