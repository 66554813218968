// @flow
import * as React from 'react';
import classNames from 'classnames';

import styles from './TabPanel.scss';


type Props = {
  children: React.Node,
  index: number,
  active?: boolean,
  className?: string,
  activeClassName?: string,
}

const TabPanel = ({ children, index, active, activeClassName, className }: Props) => {
  const visibleClassName = active ? activeClassName || styles.active : null;

  return (
    <div
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      aria-hidden={!active}
      className={classNames(styles.root, visibleClassName, className)}
    >
      {children}
    </div>
  );
};

export default TabPanel;
