// @flow
import { useState, useCallback } from 'react';
import map from 'ramda/es/map';
import isEmpty from 'ramda/es/isEmpty';
import path from 'ramda/es/path';

import type {
  OrgsResult,
  Rights,
  RoleItem,
  OrgItem,
} from 'app/common/api/requests/food/schools';

import type { RightsPath } from '../types';


export type RightsState = {
  [roleId: string]: Rights,
}
const defaultRightsState: RightsState = {};

type RoleIdByOrgIdState = {
  [orgId: string]: string,
}
const defaultRoleIdByOrgIdState: RoleIdByOrgIdState = {};

export type State = {
  rights: RightsState,
  roleIdByOrgId: RoleIdByOrgIdState,
  su: boolean,
  ready: boolean,
}
export const defaultState: State = {
  rights: defaultRightsState,
  roleIdByOrgId: defaultRoleIdByOrgIdState,
  su: false,
  ready: false,
};

export type Handlers = {
  initRights: (data: OrgsResult) => void,
  visibleByRights: (orgId: number, rightsPath: RightsPath) => boolean,
}

export const defaultHandlers: Handlers = {
  initRights: () => {},
  visibleByRights: () => false,
};

export default function useRightsByOrg(): [State, Handlers] {
  const [rights, setRights] = useState<RightsState>(defaultRightsState); // rights by roleId
  const [roleIdByOrgId, setRoleIdByOrgId] = useState<RoleIdByOrgIdState>(defaultRoleIdByOrgIdState);
  const [su, setSU] = useState<boolean>(false); // is superuser
  const [ready, setReady] = useState<boolean>(false);

  const initRights = useCallback(({ schools: orgs, roles }: OrgsResult) => {
    setReady(true);
    if (isEmpty(roles)) {
      setRights(defaultRightsState);
      setRoleIdByOrgId(defaultRoleIdByOrgIdState);
      setSU(true);
      return;
    }
    setSU(false);
    setRights(
      map(({ rights: r }: RoleItem) => r, roles),
    );
    setRoleIdByOrgId(
      map(({ role_id: roleId }: OrgItem) => roleId, orgs),
    );
  }, []);

  const visibleByRights = useCallback((orgId: number, rightsPath: RightsPath) => {
    const { read } = path([roleIdByOrgId[String(orgId)], ...rightsPath], rights) || {};
    return Boolean(read);
  }, [rights, roleIdByOrgId]);

  return [{
    rights,
    roleIdByOrgId,
    su,
    ready,
  }, {
    initRights,
    visibleByRights,
  }];
}
