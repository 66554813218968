import { Redirect } from 'react-router'

import OrgsSettings, { defaultSection, dataKey } from 'app/components/OrgsList/OrgsSettings'
import * as rootRights from 'app/common/constants/rights/rootRights'
import * as specificRights from 'app/common/constants/rights/specificRights'

import OrgsPageTemplate from '../OrgsPageTemplate'
import getBaseUrl from '../getBaseUrl'


export { dataKey }
const rights = [rootRights.SETTINGS, specificRights.SCHOOL_SETTINGS] as const

type Props = {
  match: {
    params: {
      orgId: string,
      type?: string,
      option: string,
    },
  },
}

export default function OrgsSettingsPage({ match }: Props) {
  const { params: { orgId: orgID, type, option } } = match
  const orgId = parseInt(orgID, 10)
  const baseUrl = getBaseUrl({ orgId: orgID, section: dataKey })
  const redirectUrl = `${baseUrl}/${defaultSection}`

  if (!type) {
    return (
      <Redirect to={redirectUrl} />
    )
  }

  return (
    <OrgsPageTemplate orgId={orgId} rightsPath={rights}>
      {orgType => (
        <OrgsSettings
          orgId={orgId}
          orgType={orgType}
          type={type}
          option={option}
          basePath={baseUrl}
        />
      )}
    </OrgsPageTemplate>
  )
}
