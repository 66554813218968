// @flow
import path from 'ramda/es/path';
import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const CLASSES_FILTER_REQUEST = 'reports/CLASSES_FILTER_REQUEST';
export const CLASSES_FILTER_SUCCESS = 'reports/CLASSES_FILTER_SUCCESS';
export const CLASSES_FILTER_FAILURE = 'reports/CLASSES_FILTER_FAILURE';
export const CLASSES_FILTER_CLEAR = 'reports/CLASSES_FILTER_CLEAR';
export const CLASSES_FILTER_DELETE = 'reports/CLASSES_FILTER_DELETE';
export const CLASSES_FILTER_SET = 'reports/CLASSES_FILTER_SET';
export const CLASSES_FILTER_UPDATE = 'reports/CLASSES_FILTER_UPDATE';


export const classesFilterFetch = (orgId: number, yearId: number) => async (dispatch: Function) => {
  const params = { school_id: orgId, schoolyear_id: yearId };
  dispatch(action(CLASSES_FILTER_REQUEST));
  try {
    const res = await api('reports/klasses_list', 'get', { params });
    const classes = path(['data', 'klasses'], res);
    dispatch(action(CLASSES_FILTER_SUCCESS, classes));
    return classes;
  } catch (error) {
    dispatch(action(CLASSES_FILTER_FAILURE, {
      err: 'Не удалось получить данные фильтра по классам',
      error,
    }));
    return null;
  }
};
