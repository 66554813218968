// @flow
import React, { Fragment } from 'react';

import SummaryItem from 'app/components/Reports/common/SummaryItem';
import { type SubsidiesParamsType } from 'app/reducers/reports/subsidies/params';
import * as viewTypesAvailable from 'app/actions/reports/filters/viewTypesAvailable';
import * as subsidyTypesAvailable from 'app/actions/reports/filters/subsidyTypesAvailable';
import { useCurrencySign } from 'app/common/components-next/currency';

import enhanceSummary, { type SummaryType } from './enhanceSummary';
import getSummaryParams from './getSummaryParams';
import styles from './Summary.scss';


type Props = {
  summary: SummaryType,
  subsidy1Title: string,
  subsidy2Title: string,
  params: SubsidiesParamsType,
}

const Summary = ({
  summary,
  subsidy1Title = '...',
  subsidy2Title = '...',
  params: { subsidyType, viewType },
}: Props) => {
  const { split1, split2 } = summary;
  const [count, studentsCount, total] = getSummaryParams(summary, subsidyType);

  const {
    total_common: totalCommon,
    total_personal: totalPersonal,
    count_common: countCommon,
    count_personal: countPersonal,
  } = summary;

  const showPersonal = (subsidyType === subsidyTypesAvailable.SUBSIDY_TYPE_ALL
    || subsidyType === subsidyTypesAvailable.SUBSIDY_TYPE_PERSONAL);

  const showCommon = (subsidyType === subsidyTypesAvailable.SUBSIDY_TYPE_ALL
    || subsidyType === subsidyTypesAvailable.SUBSIDY_TYPE_COMMON);

  const currency = useCurrencySign();
  const unit = viewType === viewTypesAvailable.VIEW_TYPE_SUM ? currency : '';


  return (
    <Fragment>
      <div className={styles.row}>
        {/* personal */}
        {showPersonal
          && <div className={styles.col}>
            <SummaryItem
              title="По индивидуальным справкам"
              value={viewType === viewTypesAvailable.VIEW_TYPE_SUM ? totalPersonal : countPersonal}
              unit={unit}
              whole={viewType === viewTypesAvailable.VIEW_TYPE_QUANTITY}
            />
          </div>}

        {/* common */}
        {showCommon
          && <div className={styles.col}>
            <SummaryItem
              title="По общим справкам"
              value={viewType === viewTypesAvailable.VIEW_TYPE_SUM ? totalCommon : countCommon}
              unit={unit}
              whole={viewType === viewTypesAvailable.VIEW_TYPE_QUANTITY}
            />
          </div>}

        <div className={styles.col}>
          <SummaryItem
            title="Кол-во учащихся"
            value={studentsCount}
            whole
          />
        </div>

      </div>
      {/* новая строка */}
      <div className={styles.row}>

        {typeof split1 === 'number'
          && <div className={styles.col}>
            <SummaryItem
              title={subsidy1Title}
              value={split1}
              unit={currency}
            />
          </div>}

        {typeof split2 === 'number'
          && <div className={styles.col}>
            <SummaryItem
              title={subsidy2Title}
              value={split2}
              unit={currency}
            />
          </div>}

        {typeof count === 'number'
          && viewType === viewTypesAvailable.VIEW_TYPE_QUANTITY
          && <div className={styles.col}>
            <SummaryItem
              title="Кол-во начислений"
              value={count}
              whole
            />
          </div>}

        <div className={styles.col}>
          <SummaryItem
            title="ИТОГО"
            value={total}
            unit={currency}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default enhanceSummary(Summary);
