import { createSelector } from 'reselect';

import { selectClassType, selectOrgId, selectYearId, selectOrgsType } from 'app/components/Reports/FilterItems/hocs/commonSelectors';
import * as availableClassTypes from 'app/actions/reports/filters/classTypesAvailable';
import * as orgsTypes from 'app/common/constants/schools/types';


const selectIsEnableEducationLevels = createSelector(
  [
    selectOrgId,
    selectYearId,
    selectClassType,
    selectOrgsType,
  ],
  (orgId, yearId, classType, orgsType) => Boolean(
    orgId // выбрана школа
    && yearId // выбран уч. год
    && classType === availableClassTypes.CLASS_TYPE_COMMON // обычный класс, но не ГПД
    && orgsType === orgsTypes.SCHOOL, // фильтр есть только для школ
  ),
);

export default selectIsEnableEducationLevels;
