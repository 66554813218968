// @flow
import React from 'react';
import classNames from 'classnames/bind';

import RadioButton from 'app/common/ui/RadioButton';
import { DotsLoader } from 'app/common/components/Loader';
import ErrorString from 'app/common/ui/ErrorString';

import styles from './PlainGenderSelect.scss';


type Props = {
  label?: string,
  value?: string,
  onChange?: (e: SyntheticEvent<HTMLElement>) => void,
  name?: string,
  isError?: boolean,
  loading?: boolean,
  disabled?: boolean,
  className?: string,
  errorText?: string,
};

const cx = classNames.bind(styles);

const PlainGenderSelect = ({
  label,
  value,
  onChange,
  name,
  isError,
  loading,
  disabled,
  className,
  errorText,
}: Props) => (
  <div className={classNames(styles.root, className)}>
    {label && <div className={styles.label}>{label}</div>}
    <div className={cx({ control: true, error: isError, disabled: disabled || loading })}>
      <RadioButton
        label="М"
        name={name}
        value="male"
        checked={value === 'male'}
        onChange={onChange}
        disabled={disabled || loading}
      />
      <RadioButton
        label="Ж"
        name={name}
        value="female"
        checked={value === 'female'}
        onChange={onChange}
        disabled={disabled || loading}
      />
      {loading && <DotsLoader className={styles.loader} />}
    </div>
    <ErrorString>{errorText}</ErrorString>
  </div>
);

PlainGenderSelect.defaultProps = {
  value: 'male',
};

export default PlainGenderSelect;
