import { type ReactNode } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'


const renderNoScroll = () => <span />

type Props = {
  children: ReactNode,
  className?: string,
  maxHeight?: number | string,
  hideHorizontalScrollBar?: boolean,
  autoHeight?: boolean,
}

const ContentScrollbars = ({
  children,
  className,
  maxHeight = '60vh',
  hideHorizontalScrollBar,
  autoHeight = true,
}: Props): ReactNode => {
  return (
    <Scrollbars
      autoHeight={autoHeight}
      autoHeightMin={10}
      autoHeightMax={maxHeight}
      className={className}
      renderTrackHorizontal={hideHorizontalScrollBar ? renderNoScroll : undefined}
    >
      {children}
    </Scrollbars>
  )
}

export default ContentScrollbars
