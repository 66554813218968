import api from 'app/actions/api';


export const ORGS_SELLS_SUCCESS = 'ORGS_SELLS_SUCCESS';
export const ORGS_SELLS_CLEAR = 'ORGS_SELLS_CLEAR';
export const ORGS_SELLS_FAILURE = 'ORGS_SELLS_FAILURE';

const orgsSellsSuccess = payload => ({
  type: ORGS_SELLS_SUCCESS,
  payload,
});

export const orgsSellsClear = () => ({
  type: ORGS_SELLS_CLEAR,
});

const orgsSellsFailure = payload => ({
  type: ORGS_SELLS_FAILURE,
  payload,
});

export const orgsSellsFetch = id => async (dispatch) => {
  try {
    const { data } = await api(`food/school/${id}/daily_sales_sum`, 'get');
    dispatch(orgsSellsSuccess({ [id]: (data && data.sum) || '-' }));
  } catch (error) {
    dispatch(orgsSellsFailure({ error }));
  }
};
