import { ReactNode, useCallback, useState } from 'react'

import CopyKit from './CopyKit'


type Props = {
  orgID: string
  kitID: string | Array<string>
  header: string
  date: string
  fixedPriceEnabled?: boolean
  children: (toggle: () => void) => ReactNode
}

const CopyKitModal = ({
  orgID,
  kitID,
  header,
  date,
  fixedPriceEnabled,
  children,
}: Props) => {
  const [displayed, setDisplayed] = useState(false)

  const toggle = useCallback(() => {
    setDisplayed(state => !state)
  }, [])

  return (
    <>
      {children(toggle)}
      {displayed
        && <CopyKit
          orgID={orgID}
          kitID={kitID}
          header={header}
          date={date}
          fixedPriceEnabled={fixedPriceEnabled || false}
          onClose={toggle}
        />}
    </>
  )
}

export default CopyKitModal
