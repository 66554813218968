import HistoryModal from 'app/common/ui-components/history-modal'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import useGenerateFileHistory, { reportTypes } from './useGenerateFileHistory'


export { reportTypes }

type Props = {
  className?: string
  title?: string
  reportType: typeof reportTypes[keyof typeof reportTypes]
}

const HistoryLink = ({
  className,
  title = 'История загрузок',
  reportType,
}: Props) => {
  const historyModalProps = useGenerateFileHistory(reportType)

  return (
    <HistoryModal {...historyModalProps}>
      {toggle => (
        <PlainButtonAsLink onClick={toggle} className={className}>
          {title}
        </PlainButtonAsLink>
      )}
    </HistoryModal>
  )
}

export default HistoryLink
