// @flow
import React, { type Node, useCallback, useEffect, useState, useMemo } from 'react';

import isProduction from 'app/common/lib/isProduction';
import { errorDisplayBlocking } from 'app/common/reducers/errors';

import CriticalUpdateDummy from './critical-update-dummy';
import { requestVersion, compareVersions, buildVersion, buildDate } from './versions';
import VersionContext from './version-context';
import serviceWorkerInit from './service-worker-init';
import RecentlyUpdatedModal from './recently-updated-modal';

/**
 *  Звгрузчик service-worker`a
 *  Также отвечает за обновление и версию приложения
 */

const LOCAL_STORAGE_RESENTLY_UPDATED = 'recentlyUpdated';

type Props = {
  children: Node,
  logo: string,
  logoDescription: string,
  company: string,
  phone: string,
  androidAppUrl: string,
  iosAppUrl: string,
  updateIllustration: Node,
  recentlyUpdateDescriptionLink?: string,
  _showCriticalUpdateDummy?: boolean,
}

const ServiceWorkerLoader = ({
  children,
  logo,
  logoDescription,
  company,
  phone,
  androidAppUrl,
  iosAppUrl,
  updateIllustration,
  recentlyUpdateDescriptionLink,
  _showCriticalUpdateDummy,
}: Props) => {
  // показать наличие обновления
  const [update, setUpdate] = useState<'critical' | 'minor' | null>(_showCriticalUpdateDummy ? 'critical' : null);

  // есть новый service-worker (обновление готово)
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  // процесс загрузки версии
  const [loading, setLoading] = useState(false);
  // показать окно об успешности обновления
  const [recentlyUpdatedView, setRecentlyUpdatedView] = useState(false);
  // зaкрыть окно об успешности обновления
  const handleCloseRecentlyUpdatedView = useCallback(() => {
    setRecentlyUpdatedView(false);
    localStorage.setItem(LOCAL_STORAGE_RESENTLY_UPDATED, '');
  }, []);

  const handleRequestVersion = useCallback(async (otherwiseMinor: boolean) => {
    try {
      setLoading(true);
      const version = await requestVersion();
      setLoading(false);
      const comparedRes = compareVersions(version, buildVersion);

      if (!isProduction()) {
        console.log('serverVersion: ', version);
        console.log('buildVersion: ', buildVersion);
      }

      if (comparedRes === 'failed') {
        console.error('!!! Не удалось сравнить версии приложения');
        console.error(`version: ${version}`);
        console.error(`buildVersion: ${buildVersion}`);
      }

      if (comparedRes === 'critical') {
        setUpdate('critical');
        return;
      }

      if (comparedRes === 'minor') {
        setUpdate('minor');
        return;
      }

      if (otherwiseMinor) {
        setUpdate('minor');
      }
    } catch (error) {
      console.error('!!! Не удалось получить версию приложения');
      console.error(error);
    }
  }, []);


  const handleSWUpdate = useCallback((event: Object) => {
    if (!isProduction()) {
      console.log('service-worker updated success', event);
    }

    handleRequestVersion(true);
    setWaitingWorker(event.sw || null);
  }, [handleRequestVersion]);


  // on press button
  const handleUpdate = useCallback(() => {
    if (waitingWorker) {
      errorDisplayBlocking(); // так как после SKIP_WAITING будет перезагрузка браузера, блокируем вывод ошибок
      localStorage.setItem(LOCAL_STORAGE_RESENTLY_UPDATED, 'true');
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      setUpdate(null);
    }
    if (!isProduction() && !waitingWorker) {
      console.error('!!! undefined waitingWorker');
    }
  }, [waitingWorker]);


  useEffect(() => {
    serviceWorkerInit({
      onWaiting: handleSWUpdate,
    });

    // показать окно о недавнем обновлении
    const recentlyUpdated = localStorage.getItem(LOCAL_STORAGE_RESENTLY_UPDATED);
    if (recentlyUpdated) {
      setRecentlyUpdatedView(true);
    }

    // Если есть новый service-worker в ожидании, то после обновления страницы (размонтирования ServiceWorkerLoader)
    // этот новый service-worker получит управление (обновится приложение)
    // Неа, не обновится ) не работает
    return () => {
      if (waitingWorker) {
        waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    };
  }, []) // eslint-disable-line


  const contextValue = useMemo(() => ({
    needUpdateType: update,
    buildVersion,
    buildDate,
    loading,
    handleUpdate,
    recentlyUpdateDescriptionLink,
  }), [update, loading, handleUpdate, recentlyUpdateDescriptionLink]);


  return (
    <VersionContext.Provider value={contextValue}>
      {recentlyUpdatedView
        && <RecentlyUpdatedModal
          version={buildVersion}
          onClose={handleCloseRecentlyUpdatedView}
          recentlyUpdateDescriptionLink={recentlyUpdateDescriptionLink}
        />}
      {update === 'critical'
        ? <CriticalUpdateDummy
          onUpdateButtonClick={handleUpdate}
          logoUrl={logo}
          logoDescription={logoDescription}
          company={company}
          phone={phone}
          androidAppUrl={androidAppUrl}
          iosAppUrl={iosAppUrl}
          updateIllustration={updateIllustration}
        />
        : children}
    </VersionContext.Provider>
  );
};

export default ServiceWorkerLoader;
