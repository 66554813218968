// @flow
import React, { useCallback, useState, Fragment } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';
import Loader from 'app/common/components/Loader';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import { useCurrencySign } from 'app/common/components-next/currency';
import detailsFilter from 'app/components/common/reports/filters/details';
import { useFilterApplied } from 'app/common/ui-components/filter';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';
import EmptyReportCSS from 'app/components/common/reports/EmptyReportCSS';

import HeaderBar from './HeaderBar';
import Summary from './Summary';
import Details from './Details';
import useReportData from './useReportData';

import styles from './Report.scss';


const empty = {};

type Props = {
  reportId: number | null,
}

const Report = ({ reportId }: Props) => {
  const currencySign = useCurrencySign();

  const [fs, changeFullScreen] = useState(false);
  const toggleFS = useCallback((e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    changeFullScreen(fullscreen => !fullscreen);
  }, []);

  const [data, loading] = useReportData(reportId);
  const { summary, details, school_ids: schoolIds } = data || empty;
  const { schools } = details || empty;

  const detailsChecked = Boolean(useFilterApplied(detailsFilter, 0));

  const nothing = (
    detailsChecked
      ? !schools || isEmpty(schools)
      : !summary
  );

  if (!reportId) {
    return (
      <EmptyReportCSS
        stickyNumber={2}
        headerBar={
          <HistoryLink reportType={reportTypes.REPORT_FINANCES_BY_SELL_TYPE} />
        }
      />
    );
  }

  return (
    <ReportSectionCSS
      stickyNumber={2}
      header="Отчет «Финансы по типам продаж»"
      headerBar={
        <HeaderBar
          onFullscreen={toggleFS}
          reportId={reportId}
        />
      }
      fullscreen={fs}
      wideContent
    >
      {loading
        ? <Loader />

        : <Fragment>
          {summary
            && <Summary
              loading={loading}
              summary={summary}
              currencySign={currencySign}
            />}

          {detailsChecked
            && <DetailsHeader reportTitle="Финансы по типам продаж" />}

          {detailsChecked
            && schools
            && schoolIds
            && <Details
              data={schools}
              ids={schoolIds}
            />}

          {nothing
            && <RowLayoutContent borderTop paddingfree>
              <EmptyWarn className={styles.empty}>
                {'Данные отсутствуют'}
              </EmptyWarn>
            </RowLayoutContent>}
        </Fragment>}
    </ReportSectionCSS>
  );
};

export default Report;
