import { ChangeEvent, useCallback } from 'react'
import { isEmpty } from 'ramda'

import { StatusMessage } from 'app/common/templates-next/page-template'

import useCallbackRef from 'app/common/hooks/useCallbackRef'
import CheckboxOrRadio from './CheckboxOrRadio'


type Props = {
  className?: string
  items: Array<{
    ID: string,
    title: string,
    disabled?: boolean,
    leftShiftLevel?: number
  }>
  multiple?: boolean
  selected?: Set<string>
  emptyMessage?: string
  onChange?: (ID: string) => void
}

const OrgsSelectorItems = ({
  className,
  items,
  multiple,
  selected = new Set(),
  emptyMessage,
  onChange,
}: Props) => {
  const empty = isEmpty(items)

  const onChangeRef = useCallbackRef(onChange)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    onChangeRef.current?.(value)
  }, [onChangeRef])

  return (
    <div className={className}>
      {empty

        ? <StatusMessage>
          {emptyMessage || 'Нет записей, удовлетворяющих условиям'}
        </StatusMessage>

        : items.map(({ ID, title, leftShiftLevel = 0, disabled }) => (
          <CheckboxOrRadio
            key={ID}
            value={ID}
            name="items"
            radio={!multiple}
            label={title}
            disabled={disabled}
            onChange={handleChange}
            level={leftShiftLevel}
            checked={selected.has(ID)}
          />
        ))}
    </div>
  )
}

export default OrgsSelectorItems
