// @flow
import {
  EMPLOYEES_FILTER_SET,
  EMPLOYEES_FILTER_CLEAR,
} from 'app/actions/employees/filter';

const defaultState = {
  role_id: null,
};
type Filter = typeof defaultState;
export type { Filter };

const filter = (state: Filter = defaultState, action: any) => {
  if (action.type === EMPLOYEES_FILTER_CLEAR) {
    return defaultState;
  }
  if (action.type === EMPLOYEES_FILTER_SET) {
    return ({ ...state, ...action.payload });
  }
  return state;
};

export default filter;
