import { map, reject } from 'ramda'

import { StatementList, Params } from 'app/dataTypes/compensation/statement'
import toFixedValue from 'app/common/lib/toFixedValue'
import SimpleDate from 'app/common/classes/simple-date'

import type { Request } from '../../../index'
import { knownStatus, statusToApi } from './statuses/_common'


const apiDateFormat = 'YYYY-MM-DD' as const
const convertIDs = (IDs: Array<string> | undefined) => IDs?.map(val => parseInt(val, 10))
const convertStatusesIDs = (IDs: Array<string> | undefined) => IDs?.map(statusToApi)

type ApiParams = {
  date_from: string
  date_to: string
  school_id?: Array<number>
  klass_id?: Array<number>
  status?: Array<number>
  student_id?: Array<number>
  limit?: number
  offset?: number
}

export const convertParams = (params: Params): [ApiParams, null] => {
  const {
    dateFrom,
    dateTo,
    orgIDs,
    classIDs,
    statusIDs,
    studentIDs,
    limit,
    offset,
  } = params

  return [reject(val => val === undefined, {
    date_from: dateFrom.getString(apiDateFormat),
    date_to: dateTo.getString(apiDateFormat),
    school_id: convertIDs(orgIDs),
    klass_id: convertIDs(classIDs),
    status: convertStatusesIDs(statusIDs),
    student_id: convertIDs(studentIDs),
    limit: typeof limit === 'number' ? limit : undefined,
    offset: typeof offset === 'number' ? offset : undefined,
  }), null]
}

type ApiResult = {
  count: number,
  withdraw_orders: Array<{
    id: number
    status: number
    datetime: string
    value: number
    student_fullname: string
    parent_fullname: string
    school_title: string
    klass_title: string
  }>
}

export const convertResult = ({ count, withdraw_orders: data }: ApiResult): StatementList => {
  const statements = map(({
    id,
    status,
    datetime,
    value,
    student_fullname: studentFullname,
    parent_fullname: parentFullname,
    school_title: schoolTitle,
    klass_title: classTitle,
  }) => ({
    ID: String(id),
    statusID: knownStatus(status),
    date: new SimpleDate(datetime),
    value: toFixedValue(value),
    studentFullname: (studentFullname || '').trim(),
    parentFullname: (parentFullname || '').trim(),
    schoolTitle,
    classTitle,
  }), data)

  return { total: count, statements }
}

export const GET_COMPENSATION_WITHDRAW_ORDERS: Request<Params, StatementList, void> = [
  'food/compensation/withdraw_orders',
  'get',
  ['data'],
]
