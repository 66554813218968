import { ChangeEvent, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { path } from 'ramda'

import PlainToggle from 'app/common/ui-next/plain-toggle'
import { saveSettingsKey } from 'app/common/actions/settings/save'

import styles from './ReportFavoritesSwitcher.scss'


type Props = {
  reportKey: string
}

export const ReportFavoritesSwitcher = ({ reportKey }: Props) => {
  const dispatch = useDispatch()

  const chosen = !!useSelector(path(['settings', 'fav', reportKey]), shallowEqual) as boolean
  const handleSwitchChosen = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    dispatch(saveSettingsKey('fav', { [reportKey]: checked }))
  }, [dispatch, reportKey])

  return (
    <>
      <div className={styles.favorites}>
        <div className={styles.favoritesLabel}>{'Избранные отчеты'}</div>
        <PlainToggle
          value={reportKey}
          checked={chosen}
          onChange={handleSwitchChosen}
          prefix={chosen ? 'Вкл' : 'Выкл'}
        />
      </div>
      <div className={styles.comment}>
        {'Будет отображаться в верхнем меню'}
      </div>
    </>
  )
}
