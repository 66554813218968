// @flow
import React, { type Node } from 'react';

import { RowLayoutWithSticky, RowLayoutContent, Header } from 'app/common/layouts/RowLayout';
import EmptyWarn from 'app/common/layouts/EmptyWarn';


type Props = {
  header: string,
}

const SectionEmpty = ({ header }: Props): Node => {
  return (
    <RowLayoutWithSticky
      number={3}
      content={
        <RowLayoutContent borderBottom>
          <Header>
            {header}
          </Header>
        </RowLayoutContent>
      }
    >
      <EmptyWarn>{'Раздел пока не готов'}</EmptyWarn>
    </RowLayoutWithSticky>
  );
};

export default SectionEmpty;
