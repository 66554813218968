import { subscibeTo } from 'app/common/data-box-store'
import CustomerSearch from 'app/common/ui-next/_filter-compilations/customer-search'
import { useCallback, useEffect, useState } from 'react'
import { OrgsOrUserIDs, customerSearchFilter, customerSearchPhraseFilter, defaultCustomerPhrase, defaultCustomerSearch, orgOrUserFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

export const CustomerSearchFilter = ({ id, pending }: Props) => {
  const [filterVisibility, setFilterVisibility] = useState<boolean>(false)

  const orgOrUserFilterHandler = useCallback((data: OrgsOrUserIDs) => {
    if (data === OrgsOrUserIDs.ByUser) {
      setFilterVisibility(true)
      return
    }
    setFilterVisibility(false)
  }, [])


  useEffect(() => {
    const orgOrUserUnsubsribe = subscibeTo<OrgsOrUserIDs>(orgOrUserFilterSelection.id, orgOrUserFilterHandler)

    return (() => {
      orgOrUserUnsubsribe()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    filterVisibility
    && <CustomerSearch
      id={id}
      loading={pending}
      dataBoxSearchPhrase={customerSearchPhraseFilter}
      dataBoxCustomer={customerSearchFilter}
      defaultCustomer={defaultCustomerSearch}
      defaultSearchPhrase={defaultCustomerPhrase}
      label="Укажите л/с или телефон"
    />
  )
}
