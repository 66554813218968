const dur = '1500ms'
const begin1 = '500ms'
const begin2 = '1000ms'
const values = 'lightgray;black;lightgray;lightgray;'

const IconBtnGo = props => (
  <svg viewBox="0 0 80 20" width="80px" height="20px" {...props}>
    <circle fill="lightgray" fillRule="nonzero" cx="10" cy="10" r="10">
      <animate
        attributeName="fill"
        values={values}
        dur={dur}
        repeatCount="indefinite"
      />
    </circle>
    <circle fill="lightgray" fillRule="nonzero" cx="40" cy="10" r="10">
      <animate
        attributeName="fill"
        values={values}
        dur={dur}
        repeatCount="indefinite"
        begin={begin1}
      />
    </circle>
    <circle fill="lightgray" fillRule="nonzero" cx="70" cy="10" r="10">
      <animate
        attributeName="fill"
        values={values}
        dur={dur}
        repeatCount="indefinite"
        begin={begin2}
      />
    </circle>
  </svg>
)

export default IconBtnGo
