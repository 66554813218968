// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const CATEGORIES_REQUEST = 'orgs/menu/CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'orgs/menu/CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'orgs/menu/CATEGORIES_FAILURE';

/**
* Получение категорий блюд для школы
*/

export const categoriesRequest = (payload: string) => ({
  type: CATEGORIES_REQUEST,
  payload,
});

export const categoriesSuccess = (payload: Object) => ({
  type: CATEGORIES_SUCCESS,
  payload,
});

export const categoriesFailure = (payload: Object) => ({
  type: CATEGORIES_FAILURE,
  payload,
});

export const categoriesFetch = (orgId: string) => async (dispatch: Function) => {
  dispatch(categoriesRequest(orgId));
  try {
    const res = await api(`food/schools/${orgId}/menu_categories`, 'get');
    dispatch(categoriesSuccess(get(res, ['data', 'categories'], {})));
    return true;
  } catch (error) {
    dispatch(categoriesFailure({ err: 'Запрос категорий меню не удался', error }));
    return false;
  }
};
