// @flow
import { useMemo } from 'react';

import { OrgType } from 'app/dataTypes/org/types';


const getTitle = (type: string): string | null => {
  if (type === OrgType.School) {
    return 'Вернуться к списку ОУ';
  }
  if (type === OrgType.Preschool) {
    return 'Вернуться к списку ДОУ';
  }
  if (type === OrgType.HighSchool) {
    return 'Вернуться к списку ВУЗов';
  }
  if (type === OrgType.Enterprise) {
    return 'Вернуться к списку предприятий';
  }
  return null;
};

const getUrlSlug = (type: string): string | null => {
  if (type === OrgType.School) {
    return 'school';
  }
  if (type === OrgType.Preschool) {
    return 'preschool';
  }
  if (type === OrgType.HighSchool) {
    return 'high_school';
  }
  if (type === OrgType.Enterprise) {
    return 'enterprise';
  }
  return null;
};

type Link = {
  to: string,
  title: string,
}

export default function useNavigationAdditionalLinks(baseUrl: string, type: string): Array<Link> {
  const links = useMemo(() => {
    const title = getTitle(type);
    const slug = getUrlSlug(type);

    if (title && slug) {
      return [{
        title,
        to: `${baseUrl}/${slug}`,
      }];
    }

    return [];
  }, [type, baseUrl]);

  return links;
}
