// @flow
import React, { useCallback, useState, useContext, Fragment, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames/bind';
import identity from 'ramda/es/identity';
import groupBy from 'ramda/es/groupBy';
import { useDispatch } from 'react-redux';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import * as statuses from 'app/common/constants/identifiers/statuses';
import Modal, { ModalHeader, ModalHeader2, ModalContent } from 'app/common/components/Modal';
import { popup } from 'app/common/actions/popup';
import * as types from 'app/common/constants/identifiers/types';
import { identifiersNames } from 'app/common/constants/identifiers';
import PropIdentifierNumbers from './prop-identifier-numbers';

import { IdentifiersContext } from '../identifiers-data';
import FormBlock from './form-block';
import FormReassign from './form-reassign';
import IconyByType from '../identifiers-icons/icon-by-type';
import styles from './identifier-item-controls.scss';


const cx = classNames.bind(styles);
const emptyArray = [];

type Props = {
  id: number,
  number: ?string,
  numbers: Array<?string>,
  status: $Values<typeof statuses>,
  unblockDisabled?: boolean,
  blockDisabled?: boolean,
  type: $Values<typeof types>,
  isPayDelayed: boolean,
}

const IdentifierItemControls = ({
  id,
  number,
  numbers,
  status,
  unblockDisabled = false,
  blockDisabled = false,
  type,
  isPayDelayed,
}: Props) => {
  const { t } = useTranslation('identifiers-next');
  const dispatch = useDispatch();

  const {
    content: { reassignTargets: targetsAll, personId, reassignEnabled },
    request: { unblock },
    views: { reassignItemView },
  } = useContext(IdentifiersContext);

  const { reassignTargets = emptyArray, sources = emptyArray } = useMemo(
    () => groupBy(({ id: targetId }) => (targetId === personId ? 'sources' : 'reassignTargets'), targetsAll),
    [targetsAll, personId],
  );

  const reassignSource = sources[0];


  const [processing, setProcessing] = useState(false);

  const [blockModalVisible, setBlockModalVisible] = useState(false);
  const toggleBlockModal = useCallback(() => {
    setBlockModalVisible(state => !state);
  }, []);

  const [reassignModalVisible, setReassignModalVisible] = useState(false);
  const toggleReassignModal = useCallback(() => {
    setReassignModalVisible(state => !state);
  }, []);


  const handleUnblock = useCallback(async () => {
    setProcessing(true);
    const res = await unblock(id);
    setProcessing(false);
    if (res) {
      dispatch(popup(t('block_modal_success_unblocked')));
    }
  }, [id, unblock, t, dispatch]);

  return (
    <Fragment>
      <div className={styles.root}>
        {status === statuses.STATUS_ACTIVE
          && !blockDisabled
          && <PlainButtonAsLink
            disabled={processing}
            onClick={toggleBlockModal}
            linkClassName={cx(styles.button, { block: !processing })}
          >
            {t('block_bt')}
          </PlainButtonAsLink>}

        {status === statuses.STATUS_BLOCKED
          && !unblockDisabled
          && <PlainButtonAsLink
            disabled={processing}
            onClick={handleUnblock}
            linkClassName={cx(styles.button, { unblock: !processing })}
          >
            {t('unblock_bt')}
          </PlainButtonAsLink>}

        {status === statuses.STATUS_ACTIVE
          && type !== types.IDENTIFIER_VIRTUAL_CARD
          && reassignEnabled
          && !isPayDelayed
          && reassignTargets
          && reassignTargets.length > 1
          && reassignSource
          && <PlainButtonAsLink
            disabled={processing}
            onClick={toggleReassignModal}
            linkClassName={styles.button}
          >
            {t('reassign_bt')}
          </PlainButtonAsLink>}
      </div>

      {blockModalVisible
        && <Modal toggleModal={toggleBlockModal}>
          <ModalHeader>
            <Trans i18nKey="identifiers-next:block_modal_header">
              {'Заблокировать карту с номером'}
              <br />
              {{ number: numbers.find(identity) || number || '???' }}
            </Trans>
          </ModalHeader>

          <FormBlock
            id={id}
            onCancel={toggleBlockModal}
            onSuccess={toggleBlockModal}
          />

        </Modal>}

      {reassignModalVisible
        && <Modal toggleModal={toggleReassignModal}>
          <ModalHeader>{t('reassign_modal_header')}</ModalHeader>
          <ModalContent>
            <ModalHeader2>{identifiersNames[type]}</ModalHeader2>
          </ModalContent>

          <ModalContent className={styles.number}>
            <IconyByType type={type} />

            <PropIdentifierNumbers
              numbers={numbers}
              defaultNumber={number || '???'}
            />
          </ModalContent>

          {reassignItemView
            ? <FormReassign
              identifierId={id}
              onCancel={toggleReassignModal}
              onSuccess={toggleBlockModal}
              reassignTargets={reassignTargets}
              reassignSource={reassignSource}
              reassignItemView={reassignItemView}
            />
            : 'Не передан параметр \'reassignItemView\''}

        </Modal>}

    </Fragment>
  );
};

export default IdentifierItemControls;
