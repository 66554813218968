// @flow
import React, { type Node, Children, cloneElement } from 'react';
import cn from 'classnames';

import CSSSticky from 'app/common/ui-components/css-sticky';

import RowLayout from './RowLayout';
import styles from './RowLayoutWithSticky.scss';


type Props = {
  number?: number,
  className?: string,
  children: Node,
  content: Node,
}

const renderContentItem = (comp, i) => {
  if (i > 0) return comp;
  const { props: { className = '' } } = comp;
  const newClassName = cn(className, styles.topBorderOnSticky);
  return cloneElement(comp, { className: newClassName });
};

const RowLayoutWithSticky = ({ number = 1, className, children, content }: Props) => {
  return (
    <RowLayout paddingfree className={className}>
      <CSSSticky
        number={number}
        className={styles.stickable}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
      >
        {Children.map(content, renderContentItem)}
      </CSSSticky>

      {children}
    </RowLayout>
  );
};

export default RowLayoutWithSticky;
