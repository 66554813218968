// @flow
import sort from 'ramda/es/sort';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';
import { arrangeByArray } from 'app/common/lib/sort';

import orgTypesFilter, { arrange, orgTypeItems } from 'app/components/common/reports/filters/orgType';
import orgsFilter, { orgItems } from 'app/components/common/reports/filters/org';
import sellTypeFilter, { sellTypeItems } from 'app/components/common/reports/filters/sellType';
import mailFilter, { mailItems, mailProps, mailActivity } from 'app/components/common/reports/filters/mail';
import detailsFilter, { detailsItems } from 'app/components/common/reports/filters/details';

import periodFilter, { periodItems, periodProps } from './period';


type Params = {
  email: string | null
}

const filterItems: Descriptions<Params> = [{
  filterId: orgTypesFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Тип заведения',
    header: 'Выбор типа заведения',
    sortFunc: arrangeByArray(arrange, prop('id')),
  },
  getItemsAndCurrent: orgTypeItems,
  relations: [orgsFilter],
}, {

  /* фильтр по заведению */
  filterId: orgsFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Заведение',
    header: 'Выбор заведения',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: orgItems,
}, {

  /* фильтр детализация */
  filterId: detailsFilter,
  filterType: filterTypes.PLAIN_CHECKBOX_GROUP,
  props: {
    label: null, // 'Детализированный отчет' из items
    keepPadding: true,
  },
  getItemsAndCurrent: detailsItems,
}, {

  /* фильтр по периоду */
  filterId: periodFilter,
  filterType: filterTypes.PLAIN_PERIOD,
  getProps: periodProps,
  getItemsAndCurrent: periodItems,
}, {

  /* фильтр по типу продаж */
  filterId: sellTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Тип продаж',
    header: 'Выбор типа продаж',
  },
  getItemsAndCurrent: sellTypeItems,
}, {

  /* фильтр отправки на email */
  filterId: mailFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  getProps: mailProps,
  getItemsAndCurrent: mailItems,
  activity: mailActivity,
}];

export default filterItems;
