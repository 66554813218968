import { PACK_FILTER_SET, PACK_FILTER_CLEAR } from 'app/actions/reports/filters/pack';


const defaultState = false;

const pack = (state = defaultState, { type, payload }) => {
  if (type === PACK_FILTER_SET) {
    return payload;
  }
  if (type === PACK_FILTER_CLEAR) {
    return defaultState;
  }
  return state;
};

export default pack;
