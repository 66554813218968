// @flow
import type { Request } from '../index';


export type ApiParams = {
  school_id: number,
  customer_type: string,
}

type Employee = {
  id: number,
  name: {
    first: string,
    last: string,
    middle: string,
  },
}

type Result = {
  [id: string]: Employee,
}

export const GET_EMPLOYEES_LIST: Request<
  ApiParams,
  Result,
  void
> = [
  'reports/employees_list',
  'get',
  ['data', 'employees'],
];
