import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import map from 'ramda/es/map';
import evolve from 'ramda/es/evolve';
import values from 'ramda/es/values';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import append from 'ramda/es/append';
import equals from 'ramda/es/equals';
import reject from 'ramda/es/reject';

import { popup } from 'app/common/actions/popup';
import { getFIO } from 'app/common/lib/names';
import { employeeSessionsFetch, employeeSessionsClear } from 'app/actions/employees/employee/settings/sessions';
import { removeSessions } from 'app/actions/employees/employee/settings/sessions/remove';
import withPaging from 'app/common/containers/withPaging';
import withShadows from 'app/common/containers/withShadows';


const viewDate = date => moment(date).format('DD.MM.YYYY HH:mm');

const selectHistory = createSelector(
  [
    state => state.employees.employee.sessions.sessions,
  ],
  pipe(
    values,
    map(evolve({
      created: viewDate,
      authAt: viewDate,
    })),
  ),
);

export default compose(
  connect(
    (state, props) => ({
      items: selectHistory(state, props),
      rows: state.settings.sessions.rows,
      total: state.employees.employee.sessions.sessionsCount,
      title: `Список сессий — ${getFIO(state.employees.employee.fields.name)}`,
    }),
  ),

  withPaging(),
  // setCurrentPage,
  // handlePage,
  // currentPage

  withState('loading', 'setLoading', true),
  withState('selected', 'setSelected', []),

  withHandlers({
    handleCheck: ({ items, setSelected }) => (e) => {
      const { value, checked } = e.currentTarget;
      if (value === 'all') {
        setSelected(checked ? map(prop('id'), items) : []);
        return;
      }
      setSelected(checked
        ? append(parseInt(value, 10))
        : reject(equals(parseInt(value, 10))));
    },
    handleDelete: ({
      id,
      rows,
      dispatch,
      selected,
      setLoading,
      setSelected,
      setCurrentPage,
    }) => async (param) => {
      const ids = (param === 'selection'
        ? selected
        : [parseInt(param, 10)]
      );
      setLoading(true);
      const res = await dispatch(removeSessions(id, ids));
      if (res) {
        setSelected([]);
        const multiple = param === 'selection' && selected.length > 1;
        dispatch(popup(`${multiple ? 'Сессии' : 'Сессия'} успешно удален${multiple ? 'ы' : 'а'}`));
        setCurrentPage(1);
        await dispatch(employeeSessionsFetch(id, rows));
      }
      setLoading(false);
    },
  }),

  lifecycle({
    async componentDidMount() {
      const { id, dispatch, rows, setLoading } = this.props;
      await dispatch(employeeSessionsFetch(id, rows));
      setLoading(false);
    },
    async componentDidUpdate(prevProps) {
      const { id, currentPage, dispatch, rows, setCurrentPage, setLoading } = this.props;
      if (rows !== prevProps.rows) {
        setCurrentPage(1);
        return;
      }
      if (currentPage !== prevProps.currentPage) {
        setLoading(true);
        await dispatch(employeeSessionsFetch(id, rows, (currentPage - 1) * rows));
        setLoading(false);
      }
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(employeeSessionsClear());
    },
  }),

  withShadows,
);
