import type { Request } from '../index'


export enum AllocationType {
  Fixed = 1, //     Фиксированный
  Estimated = 2, // Расчетный
  Combined = 3 //   Фиксированно-расчетный
}

export const isAllocationType = (v: any): v is AllocationType => {
  return typeof v === 'number' && !!AllocationType[v]
}

export enum SplittingType {
  All = 'all',
  Separate = 'separate'
}

export enum SplitTypeID {
  Common = 1, //                                           Распределение бюджета по общим справкам
  PersonalExptDayCares = 2, //                             Распределение бюджета по индивидуальным справкам (за исключением ГПД)
  PersonalOnlyDayCares = 3, //                             Распределение бюджета по индивидуальным справкам (только ГПД)
  PersonalCompensationToParentChildrenDisabilities = 4, // Распределение бюджета по индивидуальным справкам на школьное питание с компенсацией родителю (только для учащихся с домашним обучением - Дети-инвалиды)
  PersonalCompensationToParentDisabilities = 5 //          Распределение бюджета по индивидуальным справкам на школьное питание с компенсацией родителю (только для учащихся с домашним обучением - ОВЗ)
}

export const isSplitTypeID = (v: any): v is SplitTypeID => {
  return typeof v === 'number' && !!SplitTypeID[v]
}

export const splitTypeIDsAvailable: Array<SplitTypeID> = (
  Object.values(SplitTypeID).filter(v => !Number.isNaN(Number(v))) as Array<number>
)

export type SplitItem = {
  id?: number, //                       отсутствует у новых (незаполненных) и только на фронте
  order_sum: number | null, //          сумма справки, null в варианте - для всех льгот
  part1_title: string | null, //        название первой составляющей
  part1_sum: number | string | null, // сумма первой составляющей
  part1_percent: string | null, //      процент первой составляющей
  part1_fixed: boolean, //              флаг фиксированности первой составлящей
  part2_title: string | null, //        название второй составляющей
  part2_percent: string | null, //      процент второй составляющей
  part2_sum: number | string | null, // сумма второй составляющей
  part2_fixed: boolean, //              флаг фиксированности второй составлящей

  parts_by_klasses_groups: boolean, //          флаг зависимости составляющих от группы классов
  // настройки составляющих для 1-4 классов
  part1_klasses_1_4_title: string | null, //    название первой составляющей
  part1_klasses_1_4_sum: number | string, //    сумма первой составляющей
  part1_klasses_1_4_percent: string | null, //  процент первой составляющей
  part1_klasses_1_4_fixed: boolean | null, //   флаг фиксированности первой составлящей
  part2_klasses_1_4_title: string | null, //    название второй составляющей
  part2_klasses_1_4_percent: string | null, //  процент второй составляющей
  part2_klasses_1_4_sum: number | string, //    сумма второй составляющей
  part2_klasses_1_4_fixed: boolean | null, //   флаг фиксированности второй составлящей
  // настройки составляющих для 5-11 классов
  part1_klasses_5_11_title: string | null, //   название первой составляющей
  part1_klasses_5_11_sum: number | string, //   сумма первой составляющей
  part1_klasses_5_11_percent: string | null, // процент первой составляющей
  part1_klasses_5_11_fixed: boolean | null, //  флаг фиксированности первой составлящей
  part2_klasses_5_11_title: string | null, //   название второй составляющей
  part2_klasses_5_11_percent: string | null, // процент второй составляющей
  part2_klasses_5_11_sum: number | string, //   сумма второй составляющей
  part2_klasses_5_11_fixed: boolean | null, //  флаг фиксированности второй составлящей

  second_food_compensation_enabled?: boolean, //      Компенсация второго питания для 1-4 классов
  second_food_compensation_shift_1_sum?: number, //   Сумма компенсации второго питания для 1-4 классов 1 смены
  second_food_compensation_shift_2_sum?: number, //   Сумма компенсации второго питания для 1-4 классов 2 смены

  allowance: Array<string>, //              наименование льготы
  allocation_type_id: AllocationType, //    вид распределения
  split_type_id: SplitTypeID | null, //     группа распределения
  use_common_order_settings?: boolean, //   использовать параметры распределения из общих справок (toggle)
  //     средняя стоимость в не учебные дни
  average_cost_on_day_offs_1_4_enabled: boolean | null,
  average_cost_on_day_offs_1_4_breakfast: number | string,
  average_cost_on_day_offs_1_4_dinner: number | string,
  average_cost_on_day_offs_1_4_afternoon: number | string,
  average_cost_on_day_offs_5_11_enabled: boolean | null,
  average_cost_on_day_offs_5_11_breakfast: number | string,
  average_cost_on_day_offs_5_11_dinner: number | string,
  average_cost_on_day_offs_5_11_afternoon: number | string,
}

export const defaultSplitItem: SplitItem = {
  order_sum: null,
  part1_title: null,
  part1_sum: null,
  part1_percent: null,
  part1_fixed: true,
  part2_title: null,
  part2_percent: null,
  part2_sum: null,
  part2_fixed: false,

  parts_by_klasses_groups: false,
  part1_klasses_1_4_title: null,
  part1_klasses_1_4_sum: 0,
  part1_klasses_1_4_percent: null,
  part1_klasses_1_4_fixed: null,
  part2_klasses_1_4_title: null,
  part2_klasses_1_4_percent: null,
  part2_klasses_1_4_sum: 0,
  part2_klasses_1_4_fixed: null,
  part1_klasses_5_11_title: null,
  part1_klasses_5_11_sum: 0,
  part1_klasses_5_11_percent: null,
  part1_klasses_5_11_fixed: null,
  part2_klasses_5_11_title: null,
  part2_klasses_5_11_percent: null,
  part2_klasses_5_11_sum: 0,
  part2_klasses_5_11_fixed: null,

  second_food_compensation_enabled: false,
  second_food_compensation_shift_1_sum: 0,
  second_food_compensation_shift_2_sum: 0,

  allowance: [],
  allocation_type_id: AllocationType.Fixed,
  split_type_id: null,
  average_cost_on_day_offs_1_4_enabled: null,
  average_cost_on_day_offs_1_4_breakfast: 0,
  average_cost_on_day_offs_1_4_dinner: 0,
  average_cost_on_day_offs_1_4_afternoon: 0,
  average_cost_on_day_offs_5_11_enabled: null,
  average_cost_on_day_offs_5_11_breakfast: 0,
  average_cost_on_day_offs_5_11_dinner: 0,
  average_cost_on_day_offs_5_11_afternoon: 0,
}

export const createDefaultSplitItem = (splitTypeID: SplitTypeID | null = null) => ({
  ...defaultSplitItem,
  split_type_id: splitTypeID,
})

export type SubsidySplitResult = {
  result: 'success',
  splits: Array<SplitItem>,
  subsidy_splitting: SplittingType | null,
  enable_compensation_orders: boolean,
}

export type SubsidySplitParams = {
  splits: Array<SplitItem>,
  subsidy_splitting: SplittingType | null,
}

export const GET_SUBSIDY_SPLIT: Request<void, SubsidySplitResult, null> = [
  'food/subsidy_split',
  'get',
  ['data'],
]

export const POST_SUBSIDY_SPLIT: Request<SubsidySplitParams, SubsidySplitResult, null> = [
  'food/subsidy_split',
  'post',
  ['data'],
]
