import { OperationType as OT } from 'app/dataTypes/compensation/transations'


type Props = {
  operationType: OT
}

export const OperationType = ({ operationType }: Props) => {
  return (
    <span>
      {operationType === OT.Unknown && '—'}
      {operationType === OT.Accrual && 'Начисление компенсации'}
      {operationType === OT.CancellationAccrual && 'Отмена начисления компенсации'}
      {operationType === OT.WithdrawalCurrentAccount && 'Вывод по заявлению на р/с'}
      {operationType === OT.WithdrawalPersonalAccount && 'вывод на л/с'}
      {operationType === OT.CancellationWithdrawalPersonalAccount && 'отмена вывода по заявлению на р/с'}
    </span>
  )
}
