import { SummaryItem } from 'app/common/templates-next/report-template/summary'
import { PlainColumn } from 'app/common/ui/PlainGrid'
import { SummaryRow } from 'app/components/common/reports/summary'
import { SummaryType } from 'app/dataTypes/reports/data'


type Props = {
  summaryData: SummaryType
  currency: string
  loading?: boolean
}

export const Summary = ({ summaryData, currency, loading }: Props) => {
  return (
    <SummaryRow baseline={false}>
      <PlainColumn div={2}>
        <SummaryRow baseline={false}>
          <SummaryItem
            key="Sales"
            title="Продажи"
            value={`${summaryData.salesSum} ${currency}`}
            pending={loading}
          />
          <SummaryItem
            key="Corrections"
            title="Корректировки"
            value={`${summaryData.corrsSum} ${currency}`}
            pending={loading}
          />
        </SummaryRow>
        <SummaryRow baseline={false}>
          <SummaryItem
            key="TotalSum"
            title="Итого по продажам"
            value={`${summaryData.totalSum} ${currency}`}
            pending={loading}
          />
        </SummaryRow>
      </PlainColumn>
      <PlainColumn div={2}>
        <SummaryRow baseline={false}>
          <SummaryItem
            key="S_sum"
            title="Списания ЭДС"
            value={`${summaryData.sSum} ${currency}`}
            pending={loading}
          />
          <SummaryItem
            key="ReturnSum"
            title="Возвраты ЭДС"
            value={`${summaryData.uRetSum} ${currency}`}
            pending={loading}

          />
        </SummaryRow>
        <SummaryRow baseline={false}>
          <SummaryItem
            key="TransferSum"
            title="Сумма для перевода (без возн.)"
            value={`${summaryData.transferSum} ${currency}`}
            pending={loading}
          />
        </SummaryRow>
      </PlainColumn>
    </SummaryRow>
  )
}
