// @flow
import moment from 'moment-timezone';

import type { PlainDatePeriodRestProps } from 'app/common/ui-components/filter';


const disabledDays = (dayFormatted: string, format: string, unit?: string = 'day') => {
  const mDay = moment(dayFormatted, format);
  const mToday = moment().subtract(1, 'day');
  // Даты из будущего и сегодня заблокированы
  return mDay.isAfter(mToday, unit);
};

const periodProps = (): PlainDatePeriodRestProps => {
  return ({
    label: 'Период времени',
    buttonResetLabel: 'Сбросить выбор',
    fromLabel: 'от',
    toLabel: 'до',
    dateDisabledStatus: disabledDays,
  });
};

export default periodProps;
