// @flow
import { useMemo } from 'react';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import when from 'ramda/es/when';
import complement from 'ramda/es/complement';
import always from 'ramda/es/always';
import omit from 'ramda/es/omit';

import { useFilterData } from 'app/common/ui-components/filter';

import periodFilter from './filter/period';


const toInt = (val: string) => parseInt(val, 10);

type Result = {
  limit: number,
  offset: number,
  id?: number | null,
  year_id?: number | null,
  month?: number | null,
  food_mode?: Array<string>,
  klasses_ids?: Array<number>,
  status?: Array<number>,
}

type AdditionalParams = {
  limit: number,
  offset: number,
  id?: number | null,
}

export default function useFilterParams(
  { limit, offset, id }: AdditionalParams,
  foodModeEnable: boolean,
): Result {
  const [{ applied: filterApplied }] = useFilterData();

  const result = useMemo(() => {
    const additional = { limit, offset, id };
    return (filterApplied
      ? pipe(
        when(complement(always(foodModeEnable)), omit(['food_mode'])),
      )({
        ...additional,
        year_id: toInt(path(['year', 0], filterApplied)) || null,
        date_from: path([periodFilter, 0], filterApplied),
        date_to: path([periodFilter, 1], filterApplied),
        food_mode: prop('foodMode', filterApplied),
        klasses_ids: map(toInt, prop('class', filterApplied)),
        status: map(toInt, prop('status', filterApplied)),
      })
      : additional
    );
  }, [filterApplied, limit, offset, id, foodModeEnable]);

  return result;
}
