// @flow
import React, { type Node, Fragment, useCallback, useState, useEffect, useRef } from 'react';
import { isEmpty } from 'ramda';
import cn from 'classnames/bind';
import VisibilitySensor from 'react-visibility-sensor';

import PlainButton from 'app/common/ui-next/plain-button';
import { DotsLoader } from 'app/common/components/Loader';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';

import Photo from './photo';
import ProductTitle from './product-title';
import styles from './item-template.scss';


const cx = cn.bind(styles);

export type ProductData = {
  id: string,
  cost: string,
  portion: string,
  title: string,
  photoURL: string | null,
  deleted: boolean,
}

export type SlotData = {
  id: string,
  amount: string,
  amountMax: string,
  number: string,
}

type Props = {
  slotId: string,
  editMode?: boolean,
  number: string,
  amount: string,
  amountMax: string,
  productId: string | null,
  colorNeutral: boolean,
  colorMiddle: boolean,
  colorFull: boolean,
  colorDanger: boolean,
  widthFactor: number,
  heightFactor: number,
  selectionMode?: boolean,
  selected?: boolean,
  onSelect?: (Array<{ slotId: string, selected: boolean }>) => void,
  onSlotVisible?: (productId: string) => ProductData | null | Promise<ProductData | null>,
  onProductIdChange?: (productId: string) => ProductData | null | Promise<ProductData | null>,
  onClickAddProduct?: (slotId: string) => void,
  onClickIncreaseProduct?: (product: ProductData, slot: SlotData) => void,
  onClickDecreaseProduct?: (product: ProductData, slot: SlotData) => void,
}

const ItemTemplate = ({
  slotId,
  editMode = false,
  number,
  amount,
  amountMax,
  productId,
  colorNeutral,
  colorMiddle,
  colorFull,
  colorDanger,
  widthFactor,
  // heightFactor,
  selectionMode,
  selected,
  onSelect,
  onSlotVisible,
  onProductIdChange,
  onClickAddProduct,
  onClickIncreaseProduct,
  onClickDecreaseProduct,
}: Props): Node => {
  const mount = useRef(true);

  const hasProduct = !!productId;
  const [loadingProduct, setLoadingProduct] = useState(hasProduct);

  const contentClassName = cx(styles.content, {
    colorNeutral,
    colorMiddle,
    colorDanger,
    colorFull,
    selected: selectionMode && selected,
  }, {
    editMode,
    hasProduct,
    noProduct: !hasProduct,
  });

  const size = {
    width: `calc(var(--unit-width, 190px) * ${widthFactor})`,
  };

  const [data, setData] = useState({});
  const { title, photoURL, cost = '', portion, deleted } = data;

  const handleVisible = useCallback((visible) => {
    (async () => {
      if (productId && onSlotVisible && visible && isEmpty(data)) {
        setLoadingProduct(true);
        const res = await onSlotVisible(productId);
        if (res) {
          setData(res);
        }
        setLoadingProduct(false);
      }
    })();
  }, [onSlotVisible, data, productId]);

  const handleAddProduct = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onClickAddProduct) {
      onClickAddProduct(slotId);
    }
  }, [onClickAddProduct, slotId]);

  const handleIncreaseProduct = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onClickIncreaseProduct) {
      onClickIncreaseProduct(data, { id: slotId, amount, amountMax, number });
    }
  }, [onClickIncreaseProduct, slotId, amount, amountMax, number, data]);

  const handleDecreaseProduct = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onClickDecreaseProduct) {
      onClickDecreaseProduct(data, { id: slotId, amount, amountMax, number });
    }
  }, [onClickDecreaseProduct, slotId, amount, amountMax, number, data]);

  const handleChangeCheckbox = useCallback(() => {
    if (onSelect) {
      onSelect([{ slotId, selected: !selected }]);
    }
  }, [onSelect, slotId, selected]);

  useEffect(() => {
    if (mount.current) {
      mount.current = false;
      return;
    }
    if (productId && onProductIdChange) {
      (async () => {
        setLoadingProduct(true);
        const res = await onProductIdChange(productId);
        if (res) {
          setData(res);
        }
        setLoadingProduct(false);
      })();
    }
  }, [productId]);

  const disabledIncrease = loadingProduct || deleted || amount === amountMax;
  const disabledDecrease = loadingProduct;

  return (
    <VisibilitySensor onChange={handleVisible} partialVisibility delayedCall>
      <div className={styles.root} style={size}>
        <div // eslint-disable-line jsx-a11y/click-events-have-key-events
          className={contentClassName}
          onClick={handleChangeCheckbox}
        >

          <div className={styles.number}>
            {`№ ${number}`}
          </div>

          <div className={styles.amount}>
            {`${amount} из ${amountMax}`}
          </div>

          {hasProduct
            && <Fragment>
              <div className={styles.cost}>
                {cost}
              </div>
              <Photo
                alt={title}
                photoURL={photoURL}
                className={styles.photo}
              />
              <ProductTitle
                className={styles.title}
                title={title}
                portion={portion}
              />

              {editMode
                && <div className={styles.controls}>
                  <div className={styles.increase}>
                    <PlainButton
                      className={cx(styles.themeButton, { disabled: disabledIncrease })}
                      onClick={handleIncreaseProduct}
                      disabled={disabledIncrease}
                      compact
                    >
                      {'Подгрузить'}
                    </PlainButton>
                  </div>
                  <div className={styles.decrease}>
                    <PlainButton
                      className={cx(styles.themeButton, { disabled: disabledDecrease })}
                      onClick={handleDecreaseProduct}
                      disabled={disabledDecrease}
                      compact
                    >
                      {'Изъять'}
                    </PlainButton>
                  </div>
                </div>}
            </Fragment>}

          {!hasProduct
            && <div className={styles.addProduct}>
              {editMode
                && <PlainButton
                  className={styles.themeButton}
                  onClick={handleAddProduct}
                  compact
                >
                  {'Добавить товар'}
                </PlainButton>}
            </div>}
        </div>

        {selectionMode
          && <PlainCheckbox
            className={styles.checkbox}
            value={slotId}
            label={title}
            checked={selected}
            onChange={handleChangeCheckbox}
            hideLabel
          />}

        {loadingProduct
          && <div className={styles.loader}>
            <DotsLoader />
          </div>}
      </div>
    </VisibilitySensor>
  );
};

export default ItemTemplate;
