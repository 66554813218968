// @flow
import { useState, useCallback, useEffect } from 'react';
import assoc from 'ramda/es/assoc';
import { useSelector, shallowEqual } from 'react-redux';

import { type CheckEmployeeResult } from 'app/common/api/requests/food/check_employee_exists';


/**
 *  Ошибки и результаты валидации формы
 */

const requiredFields = ['lastname', 'firstname'];

type CommonExist = $Diff<CheckEmployeeResult, { result: any }>

export type OptionsState = {
  required: Array<string>,
  errorRequired: string,
  errorUser: string,
  errorPhone: string,
  errorEmail: string,
  errorSnils: string,
  userExist: ?CommonExist,
  phoneExist: ?{ ...CommonExist, name: string },
  emailExist: ?CommonExist,
  snilsExist: ?CommonExist,
}

type OptionsStateValues = null | string | CommonExist | { ...CommonExist, name: string } | Array<string>

const defaultState: OptionsState = {
  required: [],
  errorRequired: '',
  errorUser: '',
  errorPhone: '',
  errorEmail: '',
  errorSnils: '',
  userExist: null,
  phoneExist: null,
  emailExist: null,
  snilsExist: null,
};

export type UpdateOptionsFunction = (key: $Keys<OptionsState>, value: OptionsStateValues) => void
type ResetFunction = () => void

export default function useHireFormOptions(): [OptionsState, UpdateOptionsFunction, ResetFunction] {
  const [state, setState] = useState(defaultState);

  const snilsRequired = useSelector(state_ => state_.dealer.snils_required, shallowEqual);

  const update = useCallback((key: $Keys<OptionsState>, value: OptionsStateValues) => (
    setState(assoc(key, value))
  ), []);

  const reset = useCallback(() => {
    setState({
      ...defaultState,
      required: snilsRequired ? [...requiredFields, 'snils'] : requiredFields,
    });
  }, [snilsRequired]);

  useEffect(() => {
    setState(assoc('required', snilsRequired ? [...requiredFields, 'snils'] : requiredFields));
  }, [snilsRequired]);


  return [state, update, reset];
}
