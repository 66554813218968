// @flow
import { useSelector, shallowEqual } from 'react-redux';

import { selectCurrencySign } from 'app/common/components-next/currency';
import { toFixedValueCurrency } from 'app/common/lib/toFixedValue';


type Options = {
  hideBalance?: boolean
}

export default function useBalance({ hideBalance }: Options = {}): string {
  const {
    balance: {
      cafeteria,
      primary,
      primaryBalanceHidden,
    },
    type,
  } = useSelector(state => state.user, shallowEqual);

  const balance = type === 'parent' ? primary : cafeteria;
  const currency = useSelector(selectCurrencySign);

  if (primaryBalanceHidden || hideBalance || balance === null) {
    return '';
  }

  return toFixedValueCurrency(balance, false, currency);
}
