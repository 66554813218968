// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import useBlurOnMouseEvent from 'app/common/hooks/useBlurOnMouseEvent';
import { emptyObject } from 'app/common/lib/empty';

import { SurfaceType } from '../plain-dropdown-surface';
import styles from './plain-button-as-link.scss';

/**
 * CONTROL ELEMENTS v2
 *
 * Кнопка в стиле ссылки
 * ------
 * Используется для ссылок, не ведущих на другую страницу
 */

const cx = classNames.bind(styles);

export type Props = {
  children: Node,
  type?: 'button' | 'submit', // 'reset' type forbidden, do not use
  id?: string,
  ariaLabel?: string,
  disabled?: boolean,
  className?: string,
  linkClassName?: string,
  tabIndex?: string,
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void | Promise<void>,
  onFocus?: (e: SyntheticEvent<HTMLButtonElement>) => void,
  onBlur?: (e: SyntheticEvent<HTMLButtonElement>) => void,
  ariaHaspopup?: SurfaceType,
  'aria-haspopup'?: SurfaceType,
  ariaExpanded?: 'true' | 'false',
  'aria-expanded'?: 'true' | 'false',
}

const PlainButtonLink = ({
  children,
  type,
  id,
  ariaLabel,
  disabled,
  tabIndex,
  onClick,
  className,
  linkClassName,
  ariaHaspopup,
  ariaExpanded,
  onFocus,
  onBlur,
}: Props) => {
  const [elemRef, handleClick] = useBlurOnMouseEvent(onClick);

  const props = {
    id,
    'aria-label': ariaLabel,
    onClick: handleClick,
    disabled,
    className: cx(styles.root, className),
    ref: elemRef,
    'aria-haspopup': ariaHaspopup,
    'aria-expanded': ariaExpanded,
    onFocus,
    onBlur,
    ...(tabIndex ? { tabIndex } : emptyObject),
  };

  const childrenContainer = (
    <span
      className={cx({ link: !disabled, disabled }, linkClassName)}
    >
      {children}
    </span>
  );

  // type must be static:
  if (type === 'submit') {
    return (
      <button type="submit" {...props}>
        {childrenContainer}
      </button>
    );
  }

  // default type: "button"
  return (
    <button type="button" {...props}>
      {childrenContainer}
    </button>
  );
};

export default PlainButtonLink;
