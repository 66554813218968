import { ReactNode } from 'react'
import cn from 'classnames'

import styles from './SupportContactFormTemplate.scss'


type Props = {
  className?: string
  children: ReactNode
}

const SupportContactFormTemplate = ({ className, children }: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      {children}
    </div>
  )
}

export default SupportContactFormTemplate
