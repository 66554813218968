// @flow
import React, { useState, useCallback, Fragment, type Node } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import usePopup from 'app/common/hooks/usePopup';
import MultipleSubmit, { CopySettingsButton, MultipleSubmitIndicator } from 'app/components/common/MultipleSubmit';
import Tooltip from 'app/common/ui/Tooltip';
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider';

import CopyMultipleModalAdditionalContent from './CopyMultipleModalAdditionalContent';
import styles from './CopySettings.scss';


/**
 *  Копирование настроек заведения в другие заведения
 */

const additionalContent = <CopyMultipleModalAdditionalContent />;

type Props = {
  onSubmit: (orgId: Array<number>) => boolean | Promise<boolean>,
  onBeforeModalOpen?: () => boolean,
}

const CopySettings = ({ onSubmit, onBeforeModalOpen }: Props): Node => {
  const popup = usePopup();
  const [copying, setCopying] = useState(false);
  const [copyingModalDisplayed, setCopyingModalDisplayed] = useState(false);
  const closeModal = useCallback(() => setCopyingModalDisplayed(false), []);
  const openModal = useCallback(() => {
    if (onBeforeModalOpen && !onBeforeModalOpen()) {
      return;
    }
    setCopyingModalDisplayed(true);
  }, [onBeforeModalOpen]);

  const [{ orgs, loading }] = useOrgsData();

  const handleMassSubmitSettings = useCallback(async (orgIds: Array<number>) => {
    if (isEmpty(orgIds)) {
      setCopyingModalDisplayed(false);
      return;
    }

    setCopying(true);
    const res = await onSubmit(orgIds);
    setCopying(false);

    if (res) {
      popup('Копирование настроек успешно запущено');
      setCopyingModalDisplayed(false);
    }
  }, [onSubmit, popup]);

  if (isEmpty(orgs)) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip text="Копирование настроек в другие ОУ" className={styles.bt}>
        <CopySettingsButton onClick={openModal} />
      </Tooltip>
      {copyingModalDisplayed
        && <MultipleSubmitIndicator show={copying}>
          <MultipleSubmit
            orgs={orgs}
            loading={loading}
            header="Копирование настроек"
            buttonOkTitle="Копировать"
            buttonOkTitleSubmitting="Копируется..."
            emptyOrgsMessage="Некуда копировать. Отсутствуют другие заведения текущего типа"
            orgsSelectorTitle="Копировать настройки в заведения типа ОУ:"
            onClickOk={handleMassSubmitSettings}
            onClickCancel={closeModal}
            submitting={copying}
            additionalContent={additionalContent}
          />
        </MultipleSubmitIndicator>}
    </Fragment>
  );
};

export default CopySettings;
