// @flow
import React from 'react';
import classNames from 'classnames';

import styles from './RowLayout.scss';


type Props = {
  children: any;
  className?: string,
}

const Header2 = ({ children, className }: Props) => (
  <h3 className={classNames(styles.header2, className)}>
    {children}
  </h3>
);

export default Header2;
