import { connect } from 'react-redux';
import {
  dishesFieldEdit as dishesFieldEditAction,
  dishesFieldEditOriginal as dishesFieldEditOriginalAction,
} from 'app/actions/orgs/dishes/field';


const withDishEditFieldsActions = connect(
  null,
  (dispatch, { orgId }) => ({
    dishesFieldEdit: (...params) => dispatch(dishesFieldEditAction(orgId, ...params)),
    dishesFieldEditOriginal: (...params) => dispatch(dishesFieldEditOriginalAction(...params)),
  }),
);

export default withDishEditFieldsActions;
