// @flow
import { prop } from 'ramda';

import { orgsOrUser, type OrgsOrUser } from 'app/dataTypes/reports/orgsOrUser';

import { filterId } from './filterId';


type AppliedState = {
  [string]: Array<string>,
  ...
}

const getFromApplied = (applied: AppliedState): OrgsOrUser | null => {
  const [type] = prop(filterId, applied) || [];
  if (type === orgsOrUser.ByOrgs) {
    return orgsOrUser.ByOrgs;
  }
  if (type === orgsOrUser.ByUser) {
    return orgsOrUser.ByUser;
  }
  return null;
};

export default getFromApplied;
