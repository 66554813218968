// @flow
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Picture from 'app/common/ui-next/picture';

import styles from './Logo.scss';


const Logotip = () => {
  const { project_name: alt, logo_w_url: logoUrl } = useSelector(state => state.dealer, shallowEqual);

  return (
    /empty.png$/.test(logoUrl)
      ? null
      : <Picture alt={alt} src={logoUrl} height="48px" className={styles.root} />
  );
};

export default Logotip;
