// @flow
import { useState, useCallback } from 'react';
import { without } from 'ramda';


const PAUSE_BEFORE_DISPLAY = 700;

export default function usePending(defaultState?: Array<string>): [
  Set<string>, {
    add: (val: Array<string>, pause?: number) => void,
    remove: (val: Array<string>) => void,
    reset: () => void,
}] {
  const [pending, setPending] = useState({
    actual: !!defaultState,
    items: new Set(defaultState),
  });

  const handleAdd = useCallback((
    newValue: Array<string>,
    pause?: number = PAUSE_BEFORE_DISPLAY,
  ) => {
    setPending(state => ({ ...state, actual: true }));
    setTimeout(() => {
      setPending(state => (
        state.actual
          ? {
            ...state,
            items: new Set([...newValue, ...Array.from(state.items)]),
          }
          : state
      ));
    }, pause);
  }, []);

  const handleRemove = useCallback((removeValues: Array<string>) => {
    setPending(({ actual, items: prevItems }) => {
      const items = new Set(without(removeValues, [...Array.from(prevItems)]));
      return ({
        items,
        actual: items.size ? actual : false,
      });
    });
  }, []);

  const handleReset = useCallback(() => {
    setPending({
      items: new Set(),
      actual: false,
    });
  }, []);

  return [pending.items, {
    add: handleAdd,
    remove: handleRemove,
    reset: handleReset,
  }];
}
