import { test } from 'ramda'
import { useCallback } from 'react'
import BigNumber from 'bignumber.js' // eslint-disable-line import/no-extraneous-dependencies

import { useCurrencySign } from 'app/common/components-next/currency'

import FixedAllocationControls from './FixedAllocationControls'
import EstimatedAllocationControls from './EstimatedAllocationControls'
import BudgetNamesControls from './BudgetNamesControls'
import { getOppositeFieldName, getSiblingFieldName } from './getRelationField'

import {
  AllocationType,
  SplitTypeID,
  SplitItem,
} from '../SubsidiesData'

import styles from './DistributionControls.scss'


const placeholderSubsidyType = 'Введите название бюджета'
const focusedSubsidyType1 = 'Пример: Областной бюджет'
const focusedSubsidyType2 = 'Пример: Городской бюджет'
const chooseFixedLabel = 'Фиксированный размер дотации'
const chooseEstimatedLabel = 'Укажите процентное соотношение между видами бюджета:'
const placeholderFixed = 'Введите сумму'
const placeholderEstimated = 'Введите значение'
const focusedFixed = 'Сумма'
const focusedEstimated = 'Значение'

const BN = BigNumber.clone({ DECIMAL_PLACES: 15 })

const createFieldName = ({ name, prefix, suffix, number }: {
  name: string,
  prefix: string,
  suffix?: string,
  number: string | number,
}) => {
  return [`${prefix}${number}`, suffix, name].filter(Boolean).join('_')
}


type Props = {
  title?: string
  splitTypeId: SplitTypeID | null
  suffix?: string,
  prefix?: string,
  displayedData: SplitItem
  errors: Record<string, string>
  disabled?: boolean
  fixedAllocationControlsChooseLabel?: string
  changeSplitItemField: (splitTypeId: SplitTypeID | null, fieldName: string, fieldValue: any) => void
  updateSplitItem: (splitTypeId: SplitTypeID | null, updateFunc: (SplitItem: any) => SplitItem) => void
}

const DistributionControls = ({
  title = 'Укажите названия бюджета:',
  splitTypeId,
  suffix = '',
  prefix = 'part',
  displayedData,
  errors,
  disabled,
  fixedAllocationControlsChooseLabel,
  changeSplitItemField,
  updateSplitItem,
}: Props) => {
  const suffixPre = suffix ? `${suffix}_` : ''

  const allocationTypeId = displayedData.allocation_type_id

  const titleFieldName1 = createFieldName({ name: 'title', prefix, suffix, number: 1 })
  const titleFieldName2 = createFieldName({ name: 'title', prefix, suffix, number: 2 })
  const fixedFieldName1 = createFieldName({ name: 'fixed', prefix, suffix, number: 1 })
  const fixedFieldName2 = createFieldName({ name: 'fixed', prefix, suffix, number: 2 })
  const sumFieldName1 = createFieldName({ name: 'sum', prefix, suffix, number: 1 })
  const sumFieldName2 = createFieldName({ name: 'sum', prefix, suffix, number: 2 })
  const percentFieldName1 = createFieldName({ name: 'percent', prefix, suffix, number: 1 })
  const percentFieldName2 = createFieldName({ name: 'percent', prefix, suffix, number: 2 })

  const handleChangeTitle = useCallback((value: string | null, fieldName: string) => {
    changeSplitItemField(splitTypeId, fieldName, value)
  }, [changeSplitItemField, splitTypeId])

  const handleFixedSwitch = useCallback((fieldName: string) => {
    updateSplitItem(splitTypeId, (prevSplitItem: SplitItem) => {
      const sum = prevSplitItem[sumFieldName1] || prevSplitItem[sumFieldName2] || 0
      return ({
        ...prevSplitItem,
        [fieldName]: true,
        [getOppositeFieldName(fieldName)]: false,
        [getSiblingFieldName(fieldName)]: sum,
        [getSiblingFieldName(getOppositeFieldName(fieldName))]: null,
      })
    })
  }, [splitTypeId, updateSplitItem, sumFieldName1, sumFieldName2])

  const handleFixedChange = useCallback((value: string | null, fieldName: string) => {
    changeSplitItemField(splitTypeId, fieldName, value)
  }, [splitTypeId, changeSplitItemField])

  const handleEstimatedChange = useCallback((value: string, fieldName: string) => {
    const valueWithDot = value.replace(',', '.')
    if (test(/^\d*\.?\d{0,15}$/, valueWithDot)) {
      const valueNumber = new BN(valueWithDot || 0)
      const value100 = new BN(100)
      if (valueNumber.isGreaterThan(value100)) return
      updateSplitItem(splitTypeId, prevSplitItem => ({
        ...prevSplitItem,
        [fieldName]: valueWithDot,
        [getOppositeFieldName(fieldName)]: value100.minus(valueNumber),
      }))
    }
  }, [splitTypeId, updateSplitItem])

  const currency = useCurrencySign()

  return (
    <div className={styles.root}>
      <BudgetNamesControls
        groupID={String(splitTypeId)}
        partName={prefix}
        partSuffix={`${suffixPre}title`}
        label={title}
        placeholder={placeholderSubsidyType}
        focusedLabel1={focusedSubsidyType1}
        focusedLabel2={focusedSubsidyType2}
        disabled={disabled}
        value1={displayedData[titleFieldName1]}
        value2={displayedData[titleFieldName2]}
        onChange={handleChangeTitle}
        error1={errors[titleFieldName1]}
        error2={errors[titleFieldName2]}
      />

      {(allocationTypeId === AllocationType.Fixed || allocationTypeId === AllocationType.Combined)
        && <FixedAllocationControls
          groupID={String(splitTypeId)}
          partName={prefix}
          partRadioSuffix={`${suffixPre}fixed`}
          partFieldSuffix={`${suffixPre}sum`}
          label={fixedAllocationControlsChooseLabel || chooseFixedLabel}
          placeholder={placeholderFixed}
          focusedLabel={focusedFixed}
          disabled={disabled}
          currency={currency}
          checked1={!!displayedData[fixedFieldName1]}
          checked2={!!displayedData[fixedFieldName2]}
          onRadioClick1={handleFixedSwitch}
          onRadioClick2={handleFixedSwitch}
          value1={displayedData[sumFieldName1] === null ? null : String(displayedData[sumFieldName1])}
          value2={displayedData[sumFieldName2] === null ? null : String(displayedData[sumFieldName2])}
          onChange={handleFixedChange}
          error1={errors[sumFieldName1]}
          error2={errors[sumFieldName2]}
        />}

      {allocationTypeId === AllocationType.Estimated
        && <EstimatedAllocationControls
          groupID={String(splitTypeId)}
          partName={prefix}
          partSuffix={`${suffixPre}percent`}
          label={chooseEstimatedLabel}
          placeholder={placeholderEstimated}
          focusedLabel={focusedEstimated}
          disabled={disabled}
          value1={displayedData[percentFieldName1]}
          value2={displayedData[percentFieldName2]}
          onChange={handleEstimatedChange}
          error1={errors[percentFieldName1]}
          error2={errors[percentFieldName2]}
        />}
    </div>
  )
}

export default DistributionControls
