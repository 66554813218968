/**
 * Функция возвращает окончание для множественного
 * числа слова на основании числа и массива окончаний
 * param  iNumber Integer Число на основе которого нужно сформировать окончание
 * param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['яблоко', 'яблока', 'яблок']
 * return String
 */
function getNumEnding(num: string | number, aEndings: [string, string, string]) {
  let sEnding;
  let i;
  const currentNum = typeof num === 'string' ? parseInt(num.replace(/\s/g, ''), 10) : num;
  const iNum = currentNum % 100;
  if (iNum >= 11 && iNum <= 19) {
    sEnding = aEndings[2]; // eslint-disable-line prefer-destructuring
  } else {
    i = iNum % 10;
    switch (i) {
      case (1): sEnding = aEndings[0]; break; // eslint-disable-line prefer-destructuring
      case (2):
      case (3):
      case (4): sEnding = aEndings[1]; break; // eslint-disable-line prefer-destructuring
      default: sEnding = aEndings[2]; // eslint-disable-line prefer-destructuring
    }
  }
  return sEnding;
}

export default getNumEnding;
