// @flow
import React from 'react';

import { Td, Tr, getPropsHeader } from 'app/common/ui-components/table';

import { idsEnterprise, propsEnterprise } from './columns';
import { type RowItem } from './useOrgsListTableItems';
import OrgsListTableCellLinks from './OrgsListTableCellLinks';
import OrgsListTableCellSettings from './OrgsListTableCellSettings';
import EnterpriseTitle from './EnterpriseTitle';


type Props = {
  disabled: boolean
} & RowItem

const OrgsListTableRowEnterprise = ({ disabled, ...item }: Props) => {
  const { title, todaySalesSum, monthSalesSum, forGuestIcon, links, settings, id, locked } = item;
  const headers = getPropsHeader();

  const content = [
    <EnterpriseTitle forGuestIcon={forGuestIcon}>{title}</EnterpriseTitle>,
    todaySalesSum,
    monthSalesSum,
    <OrgsListTableCellLinks orgId={id} items={links} />,
    <OrgsListTableCellSettings orgId={id} link={settings} />,
  ];

  return (
    <Tr
      disabled={disabled || locked}
      interlineBorder
      alignTop
    >
      {idsEnterprise.map((columnId, i) => (
        <Td
          key={columnId}
          {...headers(columnId)}
          {...propsEnterprise[columnId]}
        >
          {content[i]}
        </Td>
      ))}
    </Tr>
  );
};

export default OrgsListTableRowEnterprise;
