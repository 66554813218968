import { controls, selection } from './formParts/accrualFormCampControls'


export const checkErrorsCampAccruals = (err: Map<any, any>, formData: FormData) => {
  const allowanceID = formData.get('allowanceID') as string
  if (!allowanceID) throw new Error('Нужен параметр allowanceID в formData (checkErrorsCampAccruals)')

  const complexes = formData.getAll(controls.complexesList)

  if (formData.get(controls.activeCheckbox)) {
    if (formData.get(controls.selectionRadio) === selection.percent) {
      const percent: File | string | null = formData.get(controls.percentInput)
      if (percent instanceof File || !percent || parseInt(percent, 10) > 100) {
        err.set(controls.percentInput, 'Требуется значение от 0 до 100')
      }
      if (complexes.length === 0) {
        err.set(controls.complexesList, 'Выберите хотя бы один комплект:')
      }
    }
    if (formData.get(controls.selectionRadio) === selection.complexes) {
      if (complexes.length === 0) {
        err.set(controls.complexesList, 'Выберите хотя бы один комплект:')
      }
    }
  }
}
