import {
  DISHES_ADD_REQUEST,
  DISHES_ADD_SUCCESS,
  DISHES_ADD_FAILURE,
  DISHES_ADD_CHANGE_FIELD,
  DISHES_ADD_CLEAR,
} from 'app/actions/dishes/add';

const defaultState = {
  adding: false,
  fields: {
    title: '',
    photo: '',
    restrictions: '',
    portion: '',
    cost: '',
    saleType: null,
  },
};

const add = (state = defaultState, action) => {
  switch (action.type) {
    case DISHES_ADD_REQUEST: {
      return { ...state, adding: true };
    }

    case DISHES_ADD_SUCCESS: {
      return { ...state, adding: false };
    }

    case DISHES_ADD_FAILURE: {
      return { ...state, adding: false };
    }

    case DISHES_ADD_CHANGE_FIELD: {
      return { ...state, fields: { ...state.fields, ...action.payload } };
    }

    case DISHES_ADD_CLEAR: {
      return { ...defaultState };
    }
    default:
  }
  return state;
};

export default add;
