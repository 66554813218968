// @flow
import map from 'ramda/es/map';

import { rootRights, specificRights, type RightsPath } from 'app/rights';

import { dataKey as menuDataKey } from '../../OrgsMenu';
import { dataKey as kitsDataKey } from '../../OrgsKits';
import { dataKey as dishesDataKey } from '../../OrgsDishes';
import { dataKey as ordersDataKey } from '../../OrgsOrders';


/**
 *  Возвращает массив tripples [ссылка, имя, права]
 *  для создания навигации на дочерние страницы организаций
 */

const linksArange = [menuDataKey, dishesDataKey, kitsDataKey, ordersDataKey];
const linksArangeEnterprise = [dishesDataKey, kitsDataKey, ordersDataKey];
const titles = {
  [menuDataKey]: 'Меню буфета',
  [kitsDataKey]: 'Комплекты',
  [dishesDataKey]: 'Продукция',
  [ordersDataKey]: 'Заявки',
};
const rights = {
  [menuDataKey]: [rootRights.MENU, specificRights.MENU],
  [kitsDataKey]: [rootRights.COMPLEXES, specificRights.COMPLEXES],
  [dishesDataKey]: [rootRights.DISHES, specificRights.SCHOOL_DISHES],
  [ordersDataKey]: [rootRights.ORDERS, specificRights.ORDERS],
};
const othersRights = {
  [menuDataKey]: [],
  [kitsDataKey]: [],
  [dishesDataKey]: [[rootRights.DISHES, specificRights.DISH_CATEGORIES]],
  [ordersDataKey]: [],
};

const getLinksData = (
  orgId: string,
  getChildPageBaseUrl: ({ orgId: string, section: string }) => string,
  enterprise?: boolean,
): Array<[string, string, RightsPath]> => {
  return map(
    id => ([
      getChildPageBaseUrl({ orgId, section: id }),
      titles[id],
      rights[id],
      othersRights[id],
    ]),
    enterprise ? linksArangeEnterprise : linksArange,
  );
};

export default getLinksData;
