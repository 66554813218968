import { useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import pipe from 'ramda/es/pipe'
import filter from 'ramda/es/filter'
import isEmpty from 'ramda/es/isEmpty'

import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


type Props = {
  orgID: string
  onChangeTabIndex?: (index: number) => void
  children: (
    currentTabIndex: number,
    setActiveTabIndex: (index: number) => void,
    hasOtherOrgs: boolean,
    loading: boolean
  ) => ReactNode
}

const TabState = ({ orgID, onChangeTabIndex, children }: Props) => {
  const [{ orgs, loading }] = useOrgsData()

  const nothing = useMemo(() => {
    return pipe(
      filter(({ deleted, id }) => (!deleted && id !== parseInt(orgID, 10))),
      isEmpty
    )(orgs)
  }, [orgID, orgs])

  const hasOtherOrgs = loading || !nothing
  const [activeTabIndex, setActiveTabIndex] = useState(hasOtherOrgs ? 1 : 2)
  const onChangeTabIndexRef = useCallbackRef(onChangeTabIndex)

  useEffect(() => {
    const index = hasOtherOrgs ? 1 : 2
    setActiveTabIndex(index)
    onChangeTabIndexRef.current?.(index)
  }, [hasOtherOrgs])

  const changeTabIndex = useCallback((index: number) => {
    setActiveTabIndex(index)
    onChangeTabIndexRef.current?.(index)
  }, [onChangeTabIndexRef])

  return (
    <>
      {children(activeTabIndex, changeTabIndex, hasOtherOrgs, loading)}
    </>
  )
}

export default TabState
