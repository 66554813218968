// @flow
import { useEffect } from 'react';

import { useVendingMachineSingle, useMachineRequest } from 'app/dataProviders/VendingDataProvider';


export default function useRequestValidMachine(idRequested: number): [boolean, boolean] {
  const [{ id }] = useVendingMachineSingle();
  const [requesting, requestMachine] = useMachineRequest();

  useEffect(() => {
    requestMachine(idRequested);
  }, [idRequested]); // eslint-disable-line react-hooks/exhaustive-deps

  return [!!id, requesting];
}
