import { useCallback, useEffect, useRef, useState } from 'react'
import { unnest } from 'ramda'

import useCallbackRef from 'app/common/hooks/useCallbackRef'


const useUpdateRelationsState = (onUpdateRelations: (id: string) => Promise<Array<string>>) => {
  const currentlyUpdate = useRef(false)
  const [updateRelations, setUpdateRelations] = useState(new Set<string>())

  const onUpdateRelationsRef = useCallbackRef(onUpdateRelations)

  const addFilterForUpdateRelations = useCallback((filterId: string) => {
    setUpdateRelations((previos) => {
      if (previos.has(filterId)) return previos
      const next = new Set(previos)
      next.add(filterId)
      return next
    })
  }, [])

  const handleUpdateAll = useCallback(async () => {
    if (!currentlyUpdate.current && updateRelations.size) {
      currentlyUpdate.current = true
      const ids = Array.from(updateRelations)
      const res = await Promise.all(ids.map(id => onUpdateRelationsRef.current?.(id) || []))
      setUpdateRelations(new Set(unnest(res)))
      currentlyUpdate.current = false
    }
  }, [updateRelations, onUpdateRelationsRef])

  useEffect(() => {
    handleUpdateAll()
  }, [handleUpdateAll])

  return addFilterForUpdateRelations
}

export default useUpdateRelationsState
