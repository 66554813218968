// @flow
import type { Request } from '../../index';
import type { FoodItems } from './index';


export type FoodItemsCopyRequestParams = {
  items: Array<number>,
}

export type FoodItemsCopyRequestResult = FoodItems

export const COPY_FOOD_ITEMS: Request<
  FoodItemsCopyRequestParams,
  FoodItemsCopyRequestResult
> = [
  'food/items/copy',
  'post',
  ['data', 'items'],
];
