import { useCallback, MouseEvent } from 'react'
import classNames from 'classnames/bind'

import IconLightCross from 'app/common/icons/IconLightCross'

import PlainTooltip from '../plain-tooltip'
import styles from './plain-badge.scss'

/**
 *  Элемент Badge
 */

const cx = classNames.bind(styles)

export type PlainBadgeProps<T> = {
  id: T,
  title: string,
  error?: boolean,
  light?: boolean,
  width?: number,
  className?: string,
  disabled?: boolean,
  onClose?: (el: MouseEvent<HTMLButtonElement>, id: T) => void,
  onClick?: (el: MouseEvent<HTMLSpanElement>, id: T) => void,
}

const PlainBadge = ({
  id,
  title,
  error,
  light,
  width,
  className,
  disabled,
  onClose,
  onClick,
}: PlainBadgeProps<string>) => {
  const handleClickCross = useCallback((el: MouseEvent<HTMLButtonElement>) => {
    if (!disabled && onClose) {
      onClose(el, id)
    }
  }, [onClose, id, disabled])

  const handleTextClick = useCallback((el: MouseEvent<HTMLSpanElement>) => {
    if (!disabled && onClick) {
      onClick(el, id)
    }
  }, [onClick, id, disabled])


  const titleContent = (
    onClick && !disabled
      ? (
        <button
          type="button"
          className={cx(styles.button, styles.buttonText)}
          aria-label={title}
          onClick={handleTextClick}
        >
          {title}
        </button>
      )
      : title
  )

  return (
    <span className={cx(styles.root, { error, light, disabled }, className)}>
      <span
        className={styles.text}
        style={width ? { maxWidth: width } : undefined}
      >
        <PlainTooltip text={title} hideWithoutOverflow>
          {titleContent}
        </PlainTooltip>
      </span>

      {onClose
        && <button
          type="button"
          className={cx(styles.button, { disabled })}
          name={`cross_${id}`}
          onClick={handleClickCross}
          aria-label="Удалить"
        >
          <IconLightCross className={styles.iconCross} />
        </button>}
    </span>
  )
}

export default PlainBadge
