// @flow
import values from 'ramda/es/values';
import filter from 'ramda/es/filter';
import identity from 'ramda/es/identity';
import invertObj from 'ramda/es/invertObj';
import map from 'ramda/es/map';
import moment from 'moment-timezone';

import {
  timeFormat,
  type Params as ComponentParams,
  type Change as ComponentChange,
  type ChangesData,
  type Count,
} from 'app/dataTypes/OrgSettingsChanges.types';

import sections from 'app/components/OrgsList/OrgsSettings/sections';

import type { Request } from '../../../index';


const timezone = moment.tz.guess();

const FEED_GENERAL: 10001 = 10001; // - общие настройки
const FEED_LIMITS: 10002 = 10002; // - лимиты
const FEED_REQUESTS: 10003 = 10003; // - комплексное питание и личные продажи
const FEED_SUBSIDIES: 10004 = 10004; // - дотации
const FEED_SELLPOINTS: 10005 = 10005; // - точки продаж

export const feeds = {
  FEED_GENERAL,
  FEED_LIMITS,
  FEED_REQUESTS,
  FEED_SUBSIDIES,
  FEED_SELLPOINTS,
};

export const feed2Section = {
  [FEED_GENERAL]: sections.ORGS_SETTINGS_GENERAL,
  [FEED_LIMITS]: sections.ORGS_SETTINGS_LIMITS,
  [FEED_REQUESTS]: sections.ORGS_SETTINGS_REQUESTS,
  [FEED_SUBSIDIES]: sections.ORGS_SETTINGS_SUBSIDIES,
  [FEED_SELLPOINTS]: sections.ORGS_SETTINGS_SELLPOINTS,
};

export const section2Feed = invertObj(feed2Section);

type Params = {
  limit?: number,
  offset?: number,
  from_date?: string,
  to_date?: string,
  source_id?: number,
  actor_id?: string,
  feed_id?: $Values<typeof feeds>
}

type Change = {
  id: number,
  created: string,
  source: string,
  actor: string,
  description: string,
}

type Result = {
  changes: {
    [id: string]: Change,
  },
  changes_count: number,
}

type UrlParams = {
  orgId: number | string,
}

export const convertParams = (params: ComponentParams): [Params, UrlParams] => {
  return [filter(identity, {
    limit: params.limit,
    offset: params.offset,
    from_date: params.from,
    to_date: params.to,
    source_id: params.sourceId,
    actor_id: params.actorId,
    feed_id: section2Feed[params.section],
  }), {
    orgId: params.orgId,
  }];
};

export const convertResult = (data: Result): [ChangesData, Count] => {
  const { changes, changes_count: count } = data;
  if (changes && typeof count === 'number') {
    return [map(({ id, created, ...others }: Change): ComponentChange => {
      const date = moment(created).tz(timezone);
      return ({
        id: String(id),
        created: date.format(timeFormat),
        createdSeconds: date.unix(),
        ...others,
      });
    }, values(changes)), count];
  }
  return [[], 0];
};

export const GET_CHANGES: Request<
  ComponentParams,
  [ChangesData, Count],
  UrlParams,
> = [
  'food/schools/{orgId}/changes',
  'get',
  ['data'],
];
