// @flow
import React, { useCallback, useEffect, type Node } from 'react';

import FiltersDropDownLayout from 'app/common/layouts/FiltersDropDownLayout';
import { useFilterData } from 'app/common/ui-components/filter';


type Props = {
  onFilterToggle: ((boolean => boolean) | boolean) => void,
  loading?: boolean,
  arrowClassName?: string,
  children: Node,
}

const CommonFilterWindow = ({
  onFilterToggle,
  loading,
  arrowClassName,
  children,
}: Props) => {
  const handleCloseFilterWindow = useCallback(() => {
    onFilterToggle(false);
  }, [onFilterToggle]);

  const [,,, { filtersLoad, resetAll, applyFilters }] = useFilterData();

  useEffect(() => {
    filtersLoad();
  }, [filtersLoad]);


  return (
    <FiltersDropDownLayout
      onClose={handleCloseFilterWindow}
      arrowClassName={arrowClassName}
      loading={loading}
      onApplyFilter={applyFilters}
      onResetAll={resetAll}
    >
      {children}
    </FiltersDropDownLayout>
  );
};

export default CommonFilterWindow;
