// @flow
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import evolve from 'ramda/es/evolve';


export type SortState = {
  sortKey1: string,
  sortDirection1: 'up' | 'down',
  sortKey2: string,
  sortDirection2: 'up' | 'down',
}

export type SortHandle = (d: 'up' | 'down', key: string) => void

type OutcomingProps = {
  handleSort1: SortHandle,
  handleSort2: SortHandle,
  sort: SortState,
}

const withSortStateDouble = (
  defaultSortDirection1: string,
  defaultSortKey1: string,
  defaultSortDirection2: string,
  defaultSortKey2: string,
) => compose(
  withState('sort', 'setSort', {
    sortDirection1: defaultSortDirection1,
    sortKey1: defaultSortKey1,
    sortDirection2: defaultSortDirection2,
    sortKey2: defaultSortKey2,
  }),

  withHandlers({
    handleSort1: ({ setSort }) => (direction, key) => {
      setSort(evolve({
        sortKey1: () => key,
        sortDirection1: () => direction,
      }));
    },
    handleSort2: ({ setSort }) => (direction, key) => {
      setSort(evolve({
        sortKey2: () => key,
        sortDirection2: () => direction,
      }));
    },
  }),

  mapProps(({ setSort, ...props }) => ({
    ...props,
  }: OutcomingProps)),
);

export default withSortStateDouble;
