// @flow
import React, { type Node, Fragment, memo } from 'react';

import Loader from 'app/common/components/Loader';
import EmptyReportCSS from 'app/components/common/reports/EmptyReportCSS';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';
import { useCurrencySign } from 'app/common/components-next/currency';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { RowLayoutContent } from 'app/common/layouts/RowLayout';

import HeaderBar from './HeaderBar';
import Summary, { SummarySpecial, SummarySpecialCamp } from './Summary';
import Schools from './Schools';
import Groups from './Groups';
import Sales from './Sales';
import Items, { ItemsSpecial } from './Items';
import useReportData from './useReportData';
import useDisplaySettings from './useDisplaySettings';

import styles from './Report.scss';


type Props = {
  reportId: number | null,
}

const Report = memo < Props > (({ reportId }: Props): Node => {
  const currency = useCurrencySign();

  const [data, loading] = useReportData(reportId);

  const {
    summary,
    totals,
    schools,
    groups,
    purchases,
    items,
    complexes,
    details_group: detailsGroup,
    exclude_OVZ: excludeOVZ,
  } = data || {};

  const nothing = !(summary || schools || groups || purchases || items || complexes);

  const displaySettings = useDisplaySettings(!loading);

  const { byGroupComplexes, byCampComplexes, details } = displaySettings;

  if (!reportId) {
    return (
      <EmptyReportCSS
        stickyNumber={2}
        headerBar={
          <HistoryLink reportType={reportTypes.REPORT_BALANCES} />
        }
      />
    );
  }

  return (
    <ReportSectionCSS
      stickyNumber={2}
      header="Отчет «Продажи»"
      headerBar={
        <HeaderBar reportId={reportId} hideDownload={!details} />
      }
      wideContent
    >
      {loading
        ? <Loader />
        : <Fragment>

          {/* summaries */}

          {!byGroupComplexes
            && !byCampComplexes
            && summary
            && <Summary
              summary={summary}
              currency={currency}
            />}

          {byGroupComplexes
            && summary
            && <SummarySpecial
              summary={summary}
              currency={currency}
              excludeOVZ={excludeOVZ}
            />}

          {byCampComplexes
            && summary
            && <SummarySpecialCamp
              summary={summary}
              currency={currency}
              excludeOVZ={excludeOVZ}
            />}

          {/* end summaries */}

          {!byGroupComplexes
            && !byCampComplexes
            && schools
            && totals
            && <Schools schools={schools} totals={totals} />}

          {groups
            && totals
            && <Groups groups={groups} totals={totals} />}

          {purchases
            && <Sales purchases={purchases} displaySettings={displaySettings} />}

          {!byGroupComplexes
            && !byCampComplexes
            && (items || complexes)
            && <Items
              items={items}
              complexes={complexes}
              skipPortion={detailsGroup === 'by_items_and_complexes_and_cost'}
            />}

          {(byGroupComplexes || byCampComplexes)
            && complexes
            && <ItemsSpecial complexes={complexes} />}

          {nothing
            && <RowLayoutContent borderTop paddingfree>
              <EmptyWarn className={styles.empty}>
                {'Данные отсутствуют'}
              </EmptyWarn>
            </RowLayoutContent>}
        </Fragment>}
    </ReportSectionCSS>
  );
});

export default Report;
