import { Tr, Th } from 'app/common/ui-components/table'
import cn from 'classnames'

import type { Columns } from './columns'


type Props = {
  columns: Columns
  firstCellClassName: string
  shadow?: boolean
  stuck?: boolean
}

const HeaderRow = ({
  columns,
  firstCellClassName,
  shadow,
  stuck,
}: Props) => {
  const shadowAvailable = ({ rowSpan = 0, colSpan = 0 }: { rowSpan?: number, colSpan?: number }) => (
    rowSpan === 2 || colSpan === 1 || !colSpan
  )

  const topCompensateAvailable = ({ rowSpan = 0, colSpan = 0 }: { rowSpan?: number, colSpan?: number }) => (
    rowSpan === 2 || colSpan > 1
  )

  return (
    <>
      <Tr
        headerHasBorderBottom={false}
        header
      >
        {columns.headerRow1.map(({ id, title, props }, index) => (
          <Th
            key={id}
            className={cn(index === 0 ? firstCellClassName : undefined)}
            borderShadowBottom={shadow && shadowAvailable(props)}
            borderTopCompensate={stuck && topCompensateAvailable(props)}
            borderBottom
            bgFilled
            {...props}
          >
            {title}
          </Th>
        ))}
      </Tr>
      <Tr
        headerHasBorderBottom={false}
        header
      >
        {columns.headerRow2.map(({ id, title, props }) => (
          <Th
            key={id}
            borderShadowBottom={shadow && shadowAvailable(props)}
            borderTopCompensate={stuck && topCompensateAvailable(props)}
            borderBottom
            bgFilled
            ignoreFirstChild
            ignoreLastChild
            {...props}
          >
            {title}
          </Th>
        ))}
      </Tr>
    </>
  )
}

export default HeaderRow
