// @flow
import { useState, useCallback } from 'react';
import path from 'ramda/es/path';

import { api } from 'app/api';

import {
  GET_ORGS,
  type OrgItem,
  defaultOrgItem,
} from 'app/common/api/requests/food/schools';


export default function useOrg(orgId: number): [OrgItem, () => Promise<OrgItem | null>] {
  const [org, setOrg] = useState<OrgItem>(defaultOrgItem);

  const handleRequest = useCallback(async () => {
    const res = await api.request(GET_ORGS, {
      error: 'Не удалось получить данные текущего заведения',
      params: {
        id: orgId,
      },
    });

    if (res) {
      const itsOrg: OrgItem | null = path(['schools', String(orgId)], res) || null;
      if (itsOrg) {
        setOrg(itsOrg);
      }
      return itsOrg;
    }
    return null;
  }, [orgId]);

  return [org, handleRequest];
}
