// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as sa from 'app/actions/reports/filters/classTypes';
import * as availableClassTypes from 'app/actions/reports/filters/classTypesAvailable';
import * as orgsTypes from 'app/common/constants/schools/types';

import { selectOrgId, selectOrgsType } from './hocs/commonSelectors';
import styles from './FilterItems.scss';


const emptyObj = {};

const selectIsEnableDefault = createSelector(
  [
    selectOrgId,
    selectOrgsType, // текущий фильтр зависит от типа заведения
  ],
  (orgId, orgsType) => Boolean(
    orgId
    && orgsType === orgsTypes.SCHOOL, // только в школах
  ),
);

type ClassTypes = {
  [key: string]: {
    id: $Values<typeof availableClassTypes>,
    title: string,
  }
}

type Props = {
  resetStatus: boolean,
  selectIsEnable?: (state: Object) => boolean,
}

const ClassTypesFilter = ({ resetStatus, selectIsEnable }: Props) => {
  const dispatch = useDispatch();

  // все типы
  const itemsObj: ClassTypes = useSelector(state => state.reports.filters.classTypes.data, shallowEqual);

  // выбранное
  const selectedItems: ClassTypes = useSelector(state => state.reports.filters.classTypes.selected, shallowEqual);

  // и массив с выбранным
  const selectedArr: Array<$Values<typeof availableClassTypes>> = mapObjPropToArray('id')(selectedItems);


  const handleReset = useCallback(() => {
    dispatch(action(sa.CLASS_TYPES_FILTER_SET, emptyObj));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(sa.CLASS_TYPES_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(sa.CLASS_TYPES_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE
   */

  const isEnable = useSelector(selectIsEnable || selectIsEnableDefault);

  useEffect(() => {
    dispatch(action(sa.CLASS_TYPES_FILTER_UPDATE, { active: isEnable }));
  }, [isEnable]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!isEnable) {
    return null;
  }


  return (
    <PlainFilterComplete
      id="classType"
      className={styles.filter}
      title="Тип класса:"
      modalTitle="Выбор типа класса"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default ClassTypesFilter;
