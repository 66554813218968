import { reject } from 'ramda'

import { FinanceReportType as FReport } from 'app/dataTypes/reports/finances'
import { useGeneralSettings } from 'app/dataProviders/generalSettings/hooks'

import { reportsArrange } from './availableReports'


export default function useReportsEnabled(): [typeof reportsArrange, boolean] {
  const {
    generalSettings: { eMoneyEnabled },
    loading,
  } = useGeneralSettings()

  const enabledReports = (
    eMoneyEnabled
      ? reportsArrange
      : reject(report => (
        report === FReport.EmoneyLegacy || report === FReport.Emoney
      ), reportsArrange)
  )

  return [enabledReports, loading]
}
