// @flow
import React, { useCallback, type Node } from 'react';

import { PlainRadioGroup } from 'app/common/ui-next/plain-radio-button';

import type { StateDataItem } from '../filter-state';
import LabelWithDate, { hasDatePlaceholder } from './label-with-date';


export type RadioDateProps = {
  id: string,
  label: string,
  className?: string,
  items: { [key: string]: StateDataItem },
  current: Array<string>,
  loading?: boolean,
  skipUpperSpace?: boolean,
  dateFormat: string,
  afterContent?: Node,
  onUpdate: (id: string, values: Array<string>) => void,
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean,
}

const RadioDate = ({
  id,
  label,
  className,
  items,
  current,
  loading,
  skipUpperSpace,
  dateFormat,
  afterContent,
  onUpdate,
  dateDisabledStatus,
}: RadioDateProps): Node => {
  const [currentItemId, date] = current;

  const handleChange = useCallback((itemId: string) => {
    onUpdate(id, [itemId, date]);
  }, [onUpdate, id, date]);

  const mapItemPropsBeforeRender = useCallback((itemProps) => {
    const { id: itemId, title: itemTitle, disabled } = itemProps;

    if (typeof itemTitle === 'string' && hasDatePlaceholder(itemTitle)) {
      const handleChangeDate = (date_: string) => {
        onUpdate(id, [currentItemId, date_]);
      };

      return {
        ...itemProps,
        title: (
          <LabelWithDate
            label={itemTitle}
            date={date}
            dateFormat={dateFormat}
            onChange={handleChangeDate}
            dateDisabledStatus={dateDisabledStatus}
            disabled={disabled || currentItemId !== itemId}
          />
        ),
      };
    }
    return itemProps;
  }, [date, currentItemId, id, onUpdate, dateFormat, dateDisabledStatus]);

  return (
    <PlainRadioGroup
      id={id}
      label={label}
      items={items}
      current={currentItemId}
      disabled={loading}
      onChange={handleChange}
      className={className}
      skipUpperSpace={skipUpperSpace}
      mapItemPropsBeforeRender={mapItemPropsBeforeRender}
      afterContent={afterContent}
    />
  );
};

export default RadioDate;
