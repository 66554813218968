import { type ReactNode } from 'react'
import classNames from 'classnames/bind'

import styles from './plain-dropdown-defitem.scss'


const cx = classNames.bind(styles)

export type DropdownDefitemProps = {
  className?: string
  id: number | string // eslint-disable-line react/no-unused-prop-types
  children: ReactNode
  disabled?: boolean
  active?: boolean
  selected?: boolean // eslint-disable-line react/no-unused-prop-types
  [key: string]: any
}

const PlainDropdownDefitem = ({ children, className, disabled, active }: DropdownDefitemProps) => {
  return (
    <span className={cx(styles.root, { disabled, active }, className)}>
      {children}
    </span>
  )
}

export default PlainDropdownDefitem
