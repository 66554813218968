// @flow
import type { Request } from '../index';


type ReportCancelParams = {
  report_id: number | string,
}

type ReportCancelResult = 'success'

export const POST_CANCEL: Request<
  ReportCancelParams,
  ReportCancelResult,
  void
> = [
  'reports/cancel',
  'post',
  ['data', 'result'],
];
