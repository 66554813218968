import { ID } from 'app/common/ui-next/_filter-compilations/predefined-ids'
import { canMailingTo } from 'app/common/ui-next/_filter-compilations/mailing'


export type ApiEmoneySalesParams = {
  report_type: string
  params: {
    date_from: string,
    date_to: string,
  },
  options?: {
    send_to_email?: string, // отправить на почту
  },
}

export const convertParams = (formData: FormData): [ApiEmoneySalesParams, null] => {
  const periodFrom = formData.get(`${ID.periodDateFormat}From`) as string
  const periodTo = formData.get(`${ID.periodDateFormat}To`) as string
  const email = canMailingTo(formData)
  const options = email ? { send_to_email: email } : {}
  return [{
    report_type: 'finances_e_money_sales',
    params: {
      date_from: periodFrom,
      date_to: periodTo,
    },
    options,
  }, null]
}
