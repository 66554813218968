// @flow
const ID: 'id' = 'id';
const NAME: 'name' = 'name';
const PHOTO: 'photo' = 'photo';
const PORTION: 'portion' = 'portion';
const COST: 'cost' = 'cost';
const TYPE: 'type' = 'type';
const CATEGORY: 'category' = 'category';
const OPTIONS: 'options' = 'options';

export const columnTypes = {
  ID,
  NAME,
  PHOTO,
  PORTION,
  COST,
  TYPE,
  CATEGORY,
  OPTIONS,
};

export const columns = [
  ID,
  NAME,
  PHOTO,
  PORTION,
  COST,
  TYPE,
  CATEGORY,
  OPTIONS,
];

export type ColumnVisibility = {
  photo: boolean,
  portion: boolean,
  cost: boolean,
  type: boolean,
  category: boolean,
  options: boolean,
}

export const defaultColumnVisibility: ColumnVisibility = {
  photo: true,
  portion: true,
  cost: true,
  type: true,
  category: true,
  options: true,
};
