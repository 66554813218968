import { Redirect } from 'react-router'

import SectionOrgGroups from 'app/components/Settings/Additional/SectionOrgGroups'
import { OrgType, isOrgType } from 'app/dataTypes/org/types'
import { useOrgTypes, useOrgKnownTypes } from 'app/dataProviders/org/orgTypes/OrgsAvailableTypesProvider'
import OrgTypeNavigation from 'app/pages/_common/OrgTypeNavigation'

import { baseURL } from './baseURL'


type Props = {
  orgType?: string
  disabled?: boolean
}

const SectionOrgGroupsPage = ({ orgType, disabled }: Props) => {
  const [types, loading] = useOrgTypes()
  const actualTypes = useOrgKnownTypes(types, { arrange: 'default' })
  const currentTypeValid = isOrgType(orgType)
  const currentType = currentTypeValid ? orgType as OrgType : undefined

  if (!currentTypeValid && actualTypes[0]) {
    return <Redirect from={baseURL} to={`${baseURL}/${actualTypes[0]}`} />
  }

  return (
    <SectionOrgGroups
      orgType={currentType}
      disabled={disabled}
      orgTypesNavigation={
        <OrgTypeNavigation
          loading={loading}
          orgTypes={actualTypes}
          linkPathBy={baseURL}
        />
      }
    />
  )
}

export default SectionOrgGroupsPage
