import { createSelector } from 'reselect';
import groupBy from 'ramda/es/groupBy';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import trim from 'ramda/es/trim';
import path from 'ramda/es/path';
import sort from 'ramda/es/sort';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import toFixedValue from 'app/common/lib/toFixedValue';


const notFound = 'Без категории';

const prepare = categories => map(({ id, title, cost, portion, category_id: catId }) => ({
  id,
  title: title ? trim(title) : '???',
  cost: toFixedValue(parseFloat(cost)) || '',
  portion: portion || '',
  dishCategoryId: catId || -1,
  dishCategoryIndex: catId && path([catId, 'index'], categories) || 0,
  dishCategoryTitle: path([catId, 'title'], categories) || notFound,
}));

const selectItems = createSelector(
  [
    state => state.orgs.menu.items.items,
    state => state.orgs.dishes.categories.categories,
  ],
  (items, categories) => {
    return pipe(
      values,
      prepare(categories),
      groupBy(prop('dishCategoryId')),
      values,
      sort(ascend(path([0, 'dishCategoryIndex']))),
      map(sort(ascend(prop('title')))),
    )(items);
  },
);

export default selectItems;
