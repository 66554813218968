// @flow
import api from 'app/actions/api';
import length from 'ramda/es/length';


export const REMOVE_SESSIONS_REQUEST = 'REMOVE_SESSIONS_REQUEST';
export const REMOVE_SESSIONS_SUCCESS = 'REMOVE_SESSIONS_SUCCESS';
export const REMOVE_SESSIONS_FAILURE = 'REMOVE_SESSIONS_FAILURE';

const removeSessionsRequest = (payload: Object) => ({
  type: REMOVE_SESSIONS_REQUEST,
  payload,
});

const removeSessionsSuccess = (payload: Object) => ({
  type: REMOVE_SESSIONS_SUCCESS,
  payload,
});

const removeSessionsFailure = (payload: Object) => ({
  type: REMOVE_SESSIONS_FAILURE,
  payload,
});

export const removeSessions = (id: number | string, ids: Array<number>) => async (dispatch: Function) => {
  const data = { ids };
  dispatch(removeSessionsRequest({ id, ids }));
  try {
    await api(`food/employees/${id}/auth/sessions`, 'delete', { data });
    dispatch(removeSessionsSuccess(data));
    return true;
  } catch (error) {
    dispatch(removeSessionsFailure({
      err: `Запрос остановки ${length(ids) > 1 ? 'сессий' : 'сессии'} сотрудника не удался`,
      error,
    }));
    return false;
  }
};
