// @flow
import * as React from 'react';

import caretPosition from 'app/common/lib/caretPosition';

import PlainInput from './PlainInput';
import type { Props } from './PlainInput';


const position = (function () { // eslint-disable-line func-names
  let current = 0;
  return {
    set(p) {
      current = p;
    },
    get() {
      return current;
    },
  };
}());

class PlainInputFocused extends React.Component<Props & { icon: React.Node }> {
  input: React.ElementRef<any> | null;

  componentDidMount() {
    const elem = this.input;
    if (elem) {
      elem.focus();
    }
    // Ставим курсор в сохраненную позицию текста
    // иногда нужно при поиске с использованием этого элемента,
    // елемент полностью размонтируется и позиция курсора сбрасывается
    if (elem) {
      const { value } = this.props;
      elem.focus();
      const len = (value && value.length) || 0;
      const pos = position.get();
      if (pos > len) {
        position.set(elem.selectionStart || 0);
      } else {
        caretPosition(elem, pos);
      }
    }
  }

  componentDidUpdate() {
    // const elem = this.input;
    // неправильно постоянно сбрасывать фокус
    // if (elem) {
    //   elem.focus();
    // }
  }

  componentWillUnmount() {
    if (this.input && this.input.selectionStart) {
      position.set(this.input.selectionStart);
    }
  }

  refInput = (elem: React.ElementRef<any> | null) => {
    if (elem) {
      this.input = elem;
    }
  };

  render() {
    const { onChange, component: Component, ...other } = this.props;
    return (
      Component
        ? <Component {...other} reference={this.refInput} onChange={onChange} />
        : <PlainInput {...other} reference={this.refInput} onChange={onChange} />
    );
  }
}

export default PlainInputFocused;
