// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import path from 'ramda/es/path';
import reduce from 'ramda/es/reduce';
import map from 'ramda/es/map';
import always from 'ramda/es/always';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';
import isEmpty from 'ramda/es/isEmpty';
import forEach from 'ramda/es/forEach';
import values from 'ramda/es/values';
import trim from 'ramda/es/trim';

import { validatePhoneByISO } from 'app/common/lib/phone';
import { emailRe, snilsRe } from 'app/common/constants/regex';
import { api } from 'app/api';
import { GET_CHECK_EMPLOYEE_EXISTS, checkEmployeeStatuses } from 'app/common/api/requests/food/check_employee_exists';

import useRequired from './useRequired';
import { type ErrorGroups, type ErrorFields } from './useErrors';


type Options = {
  setErrors: (ErrorFields, ErrorGroups) => void,
}

export default function useValidate({ setErrors }: Options) {
  const form = useSelector(state => state.employees.employee.employeeform, shallowEqual);
  const { id: currentId } = form;
  const isoCode = useSelector(state => state.dealer.country_iso_code, shallowEqual);

  const testFields = useRequired();

  const isEmptyByFieldName = useCallback((fieldName: string): boolean => {
    const trimmed = trim(path(fieldName.split('.'), form) || '');
    if (trimmed === '  ') { // что то странное в пустом поле snils
      return true;
    }
    return !trim;
  }, [form]);

  const handleValidate = useCallback(async () => {
    const errorFieldsByGroup = testFields(isEmptyByFieldName);
    const nonEmptyGroups = filter(complement(isEmpty), errorFieldsByGroup);

    // обязательные поля
    if (!isEmpty(nonEmptyGroups)) {
      const errorFields: ErrorFields = reduce((acc, fields) => {
        forEach((field) => { acc[field] = true; }, fields);
        return acc;
      }, {}, values(nonEmptyGroups));
      const errorGroups: ErrorGroups = map(
        always('Заполните обязательные поля'),
        filter(complement(isEmpty), nonEmptyGroups),
      );
      setErrors(errorFields, errorGroups);
      return false;
    }

    if (form.phone && !validatePhoneByISO(isoCode, form.phone)) {
      setErrors({ phone: true }, { contacts: 'Номер телефона введен не полностью' });
      return false;
    }

    if (form.email && !emailRe.test(form.email)) {
      setErrors({ email: true }, { contacts: 'Email введен неверно' });
      return false;
    }

    if (form.snils && !snilsRe.test(form.snils)) {
      setErrors({ snils: true }, { documents: 'Номер СНИЛС введен не полностью' });
      return false;
    }

    // Далее проверки через api

    // Проверка наличия сотрудника
    const fNameTrimmed = form.name.first.trim();
    const mNameTrimmed = form.name.middle.trim();
    const lNameTrimmed = form.name.last.trim();

    if (fNameTrimmed && mNameTrimmed && lNameTrimmed && form.gender && form.birthdate) {
      const existing = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
        params: {
          query_type: 'full',
          firstname: fNameTrimmed,
          middlename: mNameTrimmed,
          lastname: lNameTrimmed,
          gender: form.gender,
          birthdate: form.birthdate,
        },
      });

      if (!existing || !existing.status) {
        setErrors({
          'name.first': true,
          'name.last': true,
          'name.middle': true,
          gender: true,
          birthdate: true,
        }, { contacts: 'Не удалось проверить наличие сотрудника в базе. Возможно отсутствует соединение' });
        return false;
      }

      if (existing.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND && existing.id !== currentId) {
        setErrors({
          'name.first': true,
          'name.last': true,
          'name.middle': true,
          gender: true,
          birthdate: true,
        }, { contacts: 'Такой сотрудник уже существует' });
        return false;
      }
    }

    // остальное проверяется при submit

    // Проверка наличия телефона
    // if (form.phone) { // введен телефон (он необязательный)
    //   const phoneBusy = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
    //     params: {
    //       query_type: 'phone',
    //       phone: form.phone.replace(/\s+/g, ''),
    //     },
    //   });

    //   if (!phoneBusy || !phoneBusy.status) {
    //     setErrors({ phone: true }, { contacts: 'Не удалось проверить номер телефона. Возможно отсутствует соединение' });
    //     return false;
    //   }

    //   if (phoneBusy.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND && phoneBusy.id !== currentId) {
    //     setErrors({ phone: true }, { contacts: 'Телефон уже используется другим пользователем' });
    //     return false;
    //   }
    // }

    // Проверка занятости email
    // if (form.email) { // введен email
    //   const emailBusy = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
    //     params: {
    //       query_type: 'email',
    //       email: form.email.trim(),
    //     },
    //   });

    //   if (!emailBusy || !emailBusy.status) {
    //     setErrors({ email: true }, { contacts: 'Не удалось проверить email. Возможно отсутствует соединение' });
    //     return false;
    //   }

    //   if (emailBusy.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND && emailBusy.id !== currentId) {
    //     setErrors({ email: true }, { contacts: 'Email уже используется другим пользователем' });
    //     return false;
    //   }
    // }

    // Проверка занятости snils
    // if (form.snils) {
    //   const snilsBusy = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
    //     params: {
    //       query_type: 'snils',
    //       snils: form.snils.trim(),
    //     },
    //     additionalAxiosOptions: {
    //       paramsSerializer: (params) => { // пробел должен быть '%20' вместо '+' для местного api
    //         const searchParams = new URLSearchParams(params);
    //         return searchParams.toString().replace('+', '%20');
    //       },
    //     },
    //   });

    //   if (!snilsBusy || !snilsBusy.status) {
    //     setErrors({ snils: true }, { documents: 'Не удалось проверить номер СНИЛС. Возможно отсутствует соединение' });
    //     return false;
    //   }

    //   if (snilsBusy.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND && snilsBusy.id !== currentId) {
    //     setErrors({ snils: true }, { documents: 'Номер SNILS уже используется другим пользователем' });
    //     return false;
    //   }
    // }


    return true;
  }, [
    setErrors,
    isEmptyByFieldName,
    testFields,
    isoCode,
    form.name.first,
    form.name.last,
    form.name.middle,
    form.gender,
    form.birthdate,
    form.phone,
    form.email,
    form.snils,
    currentId,
  ]);

  return handleValidate;
}
