// @flow
import moment from 'moment-timezone';
import map from 'ramda/es/map';
import range from 'ramda/es/range';

import skipExtraDays from './skip-extra-days';


export type DayItem = {
  dateTimeFormatted: string,
  day: number,
  today: boolean,
  weekday: number,
  selected: boolean,
  outrange: boolean,
}

const getDaysOfMonth = (
  cursor: string,
  dateFormat: string,
): Array<DayItem> => {
  const mCursor = moment(cursor, dateFormat);
  if (!mCursor.isValid()) {
    return [];
  }
  const currentMonth = mCursor.month();
  mCursor.startOf('month').startOf('week');

  const todayFormatted = moment().format(dateFormat);

  const days = map((/* shift */) => {
    const dayFormatted = mCursor.format(dateFormat);
    const day = mCursor.date();
    const weekday = mCursor.day();
    const month = mCursor.month();

    // increase day
    mCursor.add(1, 'days');

    return ({
      dateTimeFormatted: dayFormatted,
      day,
      today: dayFormatted === todayFormatted,
      weekday,
      selected: false,
      outrange: month !== currentMonth,
    });
  }, range(0, 42));

  return skipExtraDays(days);
};

export default getDaysOfMonth;
