import { identity } from 'ramda'

import PlainFieldset from 'app/common/ui-next/plain-fieldset'

import StatusFilterItem from './status-filter-item'
import styles from './plain-status-filter.scss'


type Props = {
  title: string
  currentStatus: string
  status2ColorMap: Map<string, string>
  status2TranslationMap: Map<string, string>
  onClickItem: (status: string) => void
  onTranslate?: (s: string) => string
}

const PlainStatusFilter = ({
  title,
  currentStatus,
  status2ColorMap,
  status2TranslationMap,
  onClickItem,
  onTranslate = identity,
}: Props) => {
  const items = Array.from(status2TranslationMap.entries())

  return (
    <div className={styles.root}>
      <PlainFieldset
        legend={title}
        name="status"
        className={styles.fieldset}
        legendClassName={styles.legend}
        skipUpperSpace
      >
        {items.map(([status, i18string]) => (
          <StatusFilterItem
            key={status}
            title={onTranslate(i18string)}
            status={status}
            active={status === currentStatus}
            color={status2ColorMap.get(status)}
            onClick={onClickItem}
          />
        ))}
      </PlainFieldset>
    </div>
  )
}

export default PlainStatusFilter
