// @flow
import React, { Fragment, useCallback } from 'react';

import { Table } from 'app/common/ui-components/table';
import CSSSticky from 'app/common/ui-components/css-sticky';

import type { CSchool } from '../useReportData';
import useActualItems, { type ActualItem } from './useActualItems';
import { useColumns } from './columns';
import HeaderRow from './HeaderRow';
import Row from './Row';

import styles from './Details.scss';


type Props = {
  data: Array<CSchool>,
}

const Details = ({ data }: Props) => {
  const columns = useColumns();

  const itemsActual = useActualItems(data);

  const getColumnDataById = useCallback((actualItem: ActualItem) => (id: $Keys<ActualItem>) => actualItem[id], []);

  return (
    <Fragment>
      <CSSSticky
        number={3}
        className={styles.stickableHeader}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <HeaderRow
              columns={columns}
            />
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <thead>
          <HeaderRow
            columns={columns}
            hidden
            dummy
          />
        </thead>
        <tbody>
          {itemsActual.map((data_) => {
            const { id } = data_;

            return (
              <Row
                key={id}
                columns={columns}
                getColumnDataById={getColumnDataById(data_)}
              />
            );
          })}
        </tbody>
      </Table>

    </Fragment>
  );
};

export default Details;
