// @flow
import React, { type Node, Fragment, useMemo } from 'react';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { Summary as SummaryType } from '../types';


type Props = {
  summary: SummaryType,
  currency: string,
}

const Summary = ({ summary, currency }: Props): Node => {
  const {
    purchases_sum: purchasesSum,
    subsidies_sum: subsidiesSum,
    customers_count: customersCount,
  } = summary;

  const rows = useMemo(() => {
    const withoutSubsidiesSum = purchasesSum - subsidiesSum;
    const withoutSubsidiesPersent = !purchasesSum ? 0 : withoutSubsidiesSum / purchasesSum * 100;
    const subsidiesPersent = !purchasesSum ? 0 : subsidiesSum / purchasesSum * 100;

    const wsSumText = `${toFixedValue(withoutSubsidiesSum)} ${currency}`;
    const wsPercentText = `${toFixedValue(withoutSubsidiesPersent)} %`;

    const sSumText = `${toFixedValue(subsidiesSum)} ${currency}`;
    const sPercentText = `${toFixedValue(subsidiesPersent)} %`;

    const totalCount = String(customersCount);
    const totalSum = `${toFixedValue(purchasesSum)} ${currency}`;

    const Row1 = [
      ['Итого без учета дотаций', [wsSumText, wsPercentText]],
      ['Дотации', [sSumText, sPercentText]],
    ];

    const Row2 = [
      ['Итого покупателей', totalCount],
      ['Итого', totalSum],
    ];

    return [Row1, Row2];
  }, [currency, purchasesSum, subsidiesSum, customersCount]);


  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default Summary;
