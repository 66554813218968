import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import {
  IDENTIFIER_CARD,
  IDENTIFIER_KEYCHAIN,
  IDENTIFIER_KEYCHAIN_LEATHER,
  IDENTIFIER_BRACELET_SILICONE,
  IDENTIFIER_BRACELET_LEATHER,
  IDENTIFIER_BANK_CARD,
  IDENTIFIER_VIRTUAL_CARD,
  IDENTIFIER_SOMETHING,
  IDENTIFIER_RFID_TAG,
} from 'app/common/constants/identifiers/types';


const identifiersDefaultState = {
  [IDENTIFIER_CARD]: { blocked: true, withdrawn: true },
  [IDENTIFIER_KEYCHAIN]: { blocked: true, withdrawn: true },
  [IDENTIFIER_KEYCHAIN_LEATHER]: { blocked: true, withdrawn: true },
  [IDENTIFIER_BRACELET_SILICONE]: { blocked: true, withdrawn: true },
  [IDENTIFIER_BRACELET_LEATHER]: { blocked: true, withdrawn: true },
  [IDENTIFIER_BANK_CARD]: { blocked: true, withdrawn: true },
  [IDENTIFIER_VIRTUAL_CARD]: { blocked: true, withdrawn: true },
  [IDENTIFIER_SOMETHING]: { blocked: true, withdrawn: true },
  [IDENTIFIER_RFID_TAG]: { blocked: true, withdrawn: true },
};

export default makeSettingsReducer('identifiers', identifiersDefaultState);
