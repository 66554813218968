import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { path } from 'ramda'

import { Params } from 'app/dataTypes/compensation/statement'
import PlainPaging from 'app/common/ui-next/plain-paging'
import usePagingExtended from 'app/common/hooks/usePagingExtended'
import { compensationStatementsDataKey, Settings } from 'app/reducers/settings/compensationStatements'

import { Filters } from './filters'
import { Result } from './result/Result'


export const Statements = () => {
  const [params, setParams] = useState<null | Params>(null)

  const settings: Settings = useSelector<any, any>(path(['settings', compensationStatementsDataKey]), shallowEqual)
  const { rows: perPage } = settings
  const {
    currentPage,
    pageSize,
    offset,
    onPage,
    resetPagination,
  } = usePagingExtended(perPage)

  const [total, setTotal] = useState(0)

  useEffect(() => {
    resetPagination()
  }, [total]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Filters
      onSubmit={setParams}
      footerBar={
        params && <PlainPaging
          total={total}
          perPage={perPage}
          currentPageIndex={currentPage - 1}
          currentPageSize={pageSize}
          onPage={onPage}
        />
      }
    >
      {params
        ? <Result
          params={params}
          offset={offset}
          perPage={perPage}
          onTotal={setTotal}
        />
        : null}
    </Filters>
  )
}
