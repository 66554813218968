import { useEffect, useMemo, useState } from 'react'
import PlainLink from 'app/common/ui-next/plain-link'

import { PlainRadioGroup } from '../../plain-radio-button'
import { MailID, ID } from '../predefined-ids'
import styles from './mailing.scss'


const useItems = (disabled?: boolean) => {
  const res = useMemo(() => {
    return [{
      id: MailID.DoNotMail,
      title: 'Не отправлять',
    }, {
      id: MailID.MailAndView,
      title: 'Отправить файл на e-mail по завершении формирования',
      disabled,
    }, {
      id: MailID.MailOnly,
      title: 'Отправить файл на e-mail без формирования отчета на экране',
      disabled,
    }]
  }, [disabled])
  return res
}

export type Props = {
  disabled?: boolean
  email?: string | null
  profileLink?: string
}

const Mailing = ({ disabled, email, profileLink }: Props) => {
  const [value, setValue] = useState<MailID>(MailID.DoNotMail)
  const items = useItems(!email)

  useEffect(() => {
    if (!email) {
      setValue(MailID.DoNotMail)
    }
  }, [email])

  const label = (
    email
      ? (
        <span>
          <span>{'Отправить отчет на '}</span>
          <span className={styles.email}>{email}</span>
        </span>
      )
      : 'Отправить отчет на email'
  )

  const afterContent = (
    !email
    && !disabled
      ? (
        <div className={styles.comment}>
          <span className={styles.warning}>{'В профиле e-mail не указан. '}</span>
          {profileLink
            && <PlainLink to={profileLink} className={styles.link}>
              {'Перейти в профиль'}
            </PlainLink>}
        </div>
      )
      : null
  )

  return (
    <PlainRadioGroup
      id={ID.mailing}
      label={label}
      afterContent={afterContent}
      items={items}
      current={value}
      onChange={setValue}
      disabled={disabled}
      keepPadding
    />
  )
}

export default Mailing
