// @flow
import { useCallback, useState, useRef } from 'react';

import {
  GET_FOOD_PLACE_GUESTS,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/school/food_place_guests/get';

import {
  POST_FOOD_PLACE_GUESTS,
  convertParams as convertParamsPOST,
  convertResult as convertResultPOST,
} from 'app/common/api/requests/food/school/food_place_guests/post';

import type { FoodPlaceGuests } from 'app/dataTypes/org/settings/FoodPlaceGuests.types';
import { api } from 'app/api';


export default function useFoodPlaceGuests(orgId: string, setModified: (boolean) => void): {
  request: () => Promise<void>,
  submit: () => Promise<boolean>,
  orgsSelection: Array<string>,
  handleChangeOrgsSelection: (val: Array<string>) => void,
} {
  const [orgsSelection, setOrgsSelection] = useState<FoodPlaceGuests>([]);
  const orgsSelectionRef = useRef(orgsSelection);
  orgsSelectionRef.current = orgsSelection;

  const request = useCallback(async () => {
    const res = await api.request(GET_FOOD_PLACE_GUESTS, {
      error: 'Не удалось получить заведения, сотрудники которых могут питаться здесь',
      params: {
        orgId,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });
    if (res) {
      setOrgsSelection(res);
      setModified(false);
    }
  }, [orgId, setModified]);

  const handleChangeOrgsSelection = useCallback((val: Array<string>) => {
    setOrgsSelection(val);
    setModified(true);
  }, [setModified]);

  const submit = useCallback(async () => {
    const res = await api.request(POST_FOOD_PLACE_GUESTS, {
      error: 'Не удалось сохранить заведения, сотрудники которых могут питаться здесь',
      params: {
        orgId,
        guestsIds: orgsSelectionRef.current,
      },
      convertions: {
        convertParams: convertParamsPOST,
        convertResult: convertResultPOST,
      },
    });
    if (res) {
      setModified(false);
      setOrgsSelection(res);
      return true;
    }
    return false;
  }, [orgId, setModified]);

  return {
    request,
    submit,
    orgsSelection,
    handleChangeOrgsSelection,
  };
}
