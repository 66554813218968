// @flow
import React, { type Ref, type Node } from 'react';

import { TrSelectable, Th, ThLabel, getPropsId } from 'app/common/ui-components/table';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';

import type { HeaderCells } from './useHeaderCellsData';
import styles from './HeaderRow.scss';


const nothing = () => {};

type Props = {
  dummy?: boolean,
  reference?: Ref<'tr'>,
  onChange?: (id: string, state: boolean) => void,
  hidden?: boolean,
  data: HeaderCells,
  checked: boolean,
}

const HeaderRow = ({ dummy, hidden, onChange, reference, data, checked }: Props): Node => {
  const idProps = getPropsId(dummy);

  return (
    <TrSelectable
      id={dummy ? '__' : 'all'}
      reference={reference}
      onClick={onChange || nothing}
      selected={checked}
      hidden={hidden}
      header
    >
      {checkboxProps => (
        data.map(({ key, value, ...others }) => {
          switch (key) {
            case 'id':
              return (
                <ThLabel
                  key={key}
                  scope="col"
                  htmlFor="all"
                  {...idProps(key)}
                  {...others}
                >
                  {dummy
                    ? <div className={styles.checkboxDummy} />
                    : <PlainCheckbox
                      label="Выбрать все"
                      skipLabel
                      {...checkboxProps}
                    />}
                </ThLabel>
              );
            default:
              return (
                <Th
                  key={key}
                  scope="col"
                  {...idProps(key)}
                  {...others}
                >
                  {value}
                </Th>
              );
          }
        })
      )}
    </TrSelectable>
  );
};

export default HeaderRow;
