import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

// import Props from 'app/components/Settings/Props';
import { withAdminRights } from 'app/common/containers/withRights';
import CommonPage from 'app/common/templates/CommonPage';
import { RightsProvider } from 'app/rights/Rights';

import {
  selectSettingsAccessIndex,
  // withSettingsPropsRights,
} from 'app/containers/rights';

import AdminPage from './pages/AdminPage';
import SubsidiesPage from './pages/SubsidiesPage';
import { dishesPageRoutes } from './pages/DishesPage';
import { employeesPageRoutes } from './pages/EmployeesPage';
import { additionalPageRoutes } from './pages/AdditionalPage';
import { requisitesPageRoutes } from './pages/RequisitesPage';


const selectDefaultRedirect = createSelector(
  [
    selectSettingsAccessIndex,
  ],
  (index) => {
    const redirects = [
      '/settings/dishes',
      '/settings/employees',
      '/settings/admin',
      '/settings/requisites',
      '/settings/subsidies',
      '/settings/additional',
    ];
    return (index !== -1 ? redirects[index] : '/orgslist');
  },
);

const withRedirectPath = connect(
  (state, props) => ({
    defaultRedirect: selectDefaultRedirect(state, props),
  }),
);

const AdminRoutes = withAdminRights(({ readAccess }) => (readAccess
  ? <Route path="/settings/admin" component={AdminPage} />
  : <Redirect to="/orgslist" />
));

// const PropsRoutes = withSettingsPropsRights(({ readAccess }) => (readAccess
//   ? <Route path="/settings/props" component={Props} />
//   : <Redirect to="/orgslist" />
// ));

const SettingsPage = withRedirectPath(({ defaultRedirect }) => (
  <RightsProvider>
    <CommonPage header="Настройки">
      <Switch>
        {dishesPageRoutes}
        {additionalPageRoutes}
        {employeesPageRoutes}
        {requisitesPageRoutes}

        {/* TODO refactoring */}
        <Route path="/settings/admin" component={AdminRoutes} />
        {/* <Route path="/settings/props" component={PropsRoutes} /> */}
        <Route path="/settings/subsidies" component={SubsidiesPage} />
        <Redirect from="/settings" to={defaultRedirect} />
      </Switch>
    </CommonPage>
  </RightsProvider>
));

export default SettingsPage;
