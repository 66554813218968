import React from 'react';
import classNames from 'classnames/bind';

import { IconDone, IconFail, IconClose } from './icons';
import styles from './Popup.scss';


const cx = classNames.bind(styles);
const messageTimeout = 10 * 1000;

// const propTypes = {
//   children: React.PropTypes.string.isRequired,
//   type: React.PropTypes.string,
//   className: React.PropTypes.string,
//   onClose: React.PropTypes.func,
//   idx: React.PropTypes.number,
// };

class Popup extends React.Component {
  state = {
    fade: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ fade: true });
      setTimeout(() => {
        const { idx, onClose } = this.props;
        if (typeof onClose === 'function') {
          onClose(idx);
        }
      }, 500);
    }, messageTimeout);
  }

  handleClick = (e) => {
    e.preventDefault();
    const { idx, onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose(idx);
    }
  };

  render() {
    const {
      children,
      type,
      className,
      idx, // eslint-disable-line no-unused-vars
      onClose, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;
    const { fade } = this.state;

    const elements = [];
    if (typeof children === 'string') {
      children.split('\n').forEach((el, i) => {
        elements.push(el);
        elements.push(<br key={i} />);
      });
    }

    return (
      <div
        className={cx({
          root: true,
          warn: type === 'warn',
          err: type === 'err',
          fade,
        }, className)}
        {...other}
      >
        <div className={styles.head}>
          <a href="close" onClick={this.handleClick}>
            <IconClose className={styles.close} />
          </a>
        </div>
        <div className={styles.content}>
          <div className={styles.icon}>
            {(type === 'msg') && <IconDone />}
            {(type === 'err') && <IconFail />}
          </div>
          <div className={styles.text}>
            {elements.length ? elements : children}
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
