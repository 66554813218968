// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as studentSubsidyType } from '../studentSubsidyType';


const orderTypeActivity = (state: State) => {
  return (
    studentSubsidyType.filterActive(state)
    && studentSubsidyType.onlyWithSubsidiesSelected(state)
  );
};

export default orderTypeActivity;
