// @flow
import React from 'react';

import Tooltip from 'app/common/ui/Tooltip';
import PlainLink from 'app/common/ui-next/plain-link';
import { rootRights, specificRights } from 'app/rights';
import { WithRightsByOrg } from 'app/rights/RightsByOrg';
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';

import styles from './OrgsListTableCellSettings.scss';


const pathToRights = [rootRights.SETTINGS, specificRights.SCHOOL_SETTINGS];

type Props = {
  link: string,
  orgId: number,
}

const OrgsListTableCellSettings = ({ link, orgId }: Props) => {
  if (!link) {
    return null;
  }

  return (
    <WithRightsByOrg orgId={orgId} pathTo={pathToRights}>
      <PlainLink
        to={link}
        className={styles.gear}
      >
        <Tooltip text="Настройки заведения">
          <PlainIcon
            className={styles.icon}
            type={iconTypes.GEAR_LIGHT}
            colorType={colorTypes.COLOR_DARKGRAY}
            width={20}
            height={20}
          />
        </Tooltip>
      </PlainLink>
    </WithRightsByOrg>
  );
};

export default OrgsListTableCellSettings;
