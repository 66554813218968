import { useEffect, ReactNode } from 'react'

import { useSideNavigationGlobalState } from './use-side-navigation-global-state'

import styles from './side-navigation-container.scss'


type Props = {
  children: ReactNode
  isWide?: boolean // deprecated
  lock?: boolean
}

const SideNavigationContainer = ({
  children,
  isWide: initialState = true, // eslint-disable-line @typescript-eslint/no-unused-vars
  lock = false,
}: Props) => {
  const { setLock } = useSideNavigationGlobalState()

  useEffect(() => {
    setLock(lock)
  }, [lock]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      {children}
    </div>
  )
}

export default SideNavigationContainer
