
// @flow
import path from 'ramda/es/path';
import evolve from 'ramda/es/evolve';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import assoc from 'ramda/es/assoc';
import addIndex from 'ramda/es/addIndex';

import api from 'app/actions/api';
import { toCamelCase } from 'app/common/lib/object';
import { getLastFirstM } from 'app/common/lib/names';
import action from 'app/common/lib/action';


export const BALANCE_DATA_REQUEST = 'reports/BALANCE_DATA_REQUEST';
export const BALANCE_DATA_SUCCESS = 'reports/BALANCE_DATA_SUCCESS';
export const BALANCE_DATA_FAILURE = 'reports/BALANCE_DATA_FAILURE';
export const BALANCE_DATA_CLEAR = 'reports/BALANCE_DATA_CLEAR';

const mapIdx = addIndex(map);
const convertResult = pipe(
  path(['data', 'data']),
  toCamelCase,
  evolve({
    users: mapIdx(({ customerType, id, klass, role, name, ...others }, i) => ({
      ...others,
      name: getLastFirstM(name),
      id: i,
      group: klass || role,
    })),
    groups: mapIdx((obj, i) => assoc('id', i, obj)), // need normal id
  }),
);

export const balanceDataFetch = (reportId: number) => async (dispatch: Function) => {
  const params = { report_id: reportId };

  dispatch(action(BALANCE_DATA_REQUEST, reportId));

  try {
    const res = await api('reports/data', 'get', { params });
    const data = convertResult(res);
    dispatch(action(BALANCE_DATA_SUCCESS, data));
  } catch (error) {
    dispatch(action(
      BALANCE_DATA_FAILURE,
      { err: 'Не удалось получить данные отчета', error },
    ));
  }
};
