import { useCallback, useEffect } from 'react'

import { ReportSection, ResetLink } from 'app/common/templates-next/report-template'
import ReportFilterForm from 'app/common/templates-next/report-template/report-filter-form'
import ReportFilterLayout from 'app/common/templates-next/report-template/report-filter-layout'
import { ID } from 'app/common/ui-next/_filter-compilations/predefined-ids'
import { OrgType } from 'app/dataTypes/org/types'
import { Header2, PageTemplateContent } from 'app/common/templates-next/page-template'
import { filterInit, resetToDefaultAll } from './filter-init'
import { MonthFilter, OrgTypesFilter, OrgsFilter, YearFilter } from './filterItems'


type ParamsType = {
  orgIDs: Array<string>,
  month: string,
  year: string,
  orgTypeId: OrgType,
}


type Props = {
  onSubmit: (params: ParamsType) => void
}

export const Filters = ({ onSubmit }: Props) => {
  const resetAll = () => {
    resetToDefaultAll()
  }

  const perform = useCallback(async (formData: FormData) => {
    const orgIDs = (formData.getAll(ID.org) || []) as Array<string>
    const month = (formData.get(ID.month)) as string
    const year = (formData.get(ID.year)) as string
    const orgTypeId = (formData.get(ID.orgType)) as OrgType
    onSubmit({ orgIDs, month, year, orgTypeId })
  }, [onSubmit])

  const performWithAdditionalData = useCallback(async (formData: FormData) => {
    const res = await perform(formData)
    return res
  }, [perform])

  useEffect(() => {
    return filterInit()
  }, [])

  return (
    <>
      <PageTemplateContent>
        <Header2 skipMargin>
          {'Начисления компенсаций'}
        </Header2>
      </PageTemplateContent>

      <ReportSection
        header="Фильтры"
        headerBar={
          <ResetLink onClick={resetAll}>
            {'Сбросить все фильтры'}
          </ResetLink>
        }
        resetStickyShift={false}
      >
        <ReportFilterForm
          onSubmit={performWithAdditionalData}
          submitButtonTitle="Показать"
        >
          {pending => (
            <ReportFilterLayout
              column1={
                <>
                  <OrgTypesFilter
                    id={ID.orgType}
                    pending={pending}
                  />
                  <OrgsFilter
                    id={ID.org}
                    pending={pending}
                  />
                </>
              }
              column2={
                <>
                  <YearFilter
                    id={ID.year}
                    pending={pending}
                  />
                  <MonthFilter
                    id={ID.month}
                    pending={pending}
                  />
                </>
              }
            />
          )}
        </ReportFilterForm>
      </ReportSection>
    </>
  )
}
