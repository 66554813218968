// @flow
import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import config from 'app/config';


export const settingsKey: 'employeesAtWork' = 'employeesAtWork';

export type Settings = {
  rows: number,
  photo: boolean,
  fio: boolean,
  jobPlace: boolean,
  position: boolean,
  phone: boolean,
  email: boolean,
  ident: boolean,
}

const employeesAtWorkDefaultState: Settings = {
  rows: config.perPageItems,
  photo: true,
  fio: true,
  jobPlace: true,
  position: true,
  phone: true,
  email: true,
  ident: true,
};

export default makeSettingsReducer(settingsKey, employeesAtWorkDefaultState);
