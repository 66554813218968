
import {
  MENU_COPYALL_SUCCESS,
} from 'app/actions/orgs/menu/copy';

import {
  ITEMSEDIT_SUCCESS,
} from 'app/actions/orgs/menu/itemsEdit';

import {
  KITSEDIT_SUCCESS,
} from 'app/actions/orgs/menu/kitsEdit';

import {
  MENU_NEED_UPDATE,
} from 'app/actions/orgs/menu/options';

const defaultState = {
  needUpdate: false,
};

const options = (state = defaultState, action) => {
  if (action.type === MENU_COPYALL_SUCCESS
    || action.type === ITEMSEDIT_SUCCESS
    || action.type === KITSEDIT_SUCCESS) {
    return ({ ...state, needUpdate: true });
  }
  if (action.type === MENU_NEED_UPDATE) {
    return ({ ...state, needUpdate: action.payload });
  }
  return state;
};

export default options;
