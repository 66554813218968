import SideNavigation from './side-navigation'
import SideNavigationContainer from './side-navigation-container'
import SideNavigationTargetContent from './side-navigation-target-content'
import SideNavigationItem from './side-navigation-item'


export default SideNavigation

export {
  SideNavigationContainer,
  SideNavigationTargetContent,
  SideNavigationItem,
}

/**
 *  <SideNavigationContainer>
 *    <SideNavigation>
 *      {пункты меню SideNavigationItem}
 *    </SideNavigation>
 *    <SideNavigationTargetContent>
 *      {контент справа от навигации}
 *    </SideNavigationTargetContent>
 *  </SideNavigationContainer>
 */
