// @flow
import React, { type Node, useCallback } from 'react';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import usePopup from 'app/common/hooks/usePopup';
import { useMachineSubmit, useMachineDelete, useVendingMachineSingle } from 'app/dataProviders/VendingDataProvider';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal';

import EditMachine from './EditMachine';
import DeleteMachineButton from './DeleteMachineButton';
import styles from './EditMachineModal.scss';


type Props = {
  children: (HandleModalDisplayed) => Node,
  onDeleteSuccess: () => void,
}

const EditMachineModal = ({ children, onDeleteSuccess }: Props): Node => {
  const popup = usePopup();
  const [submitting, submit] = useMachineSubmit();
  const [deleting, deleteMachine] = useMachineDelete();
  const [{ title }] = useVendingMachineSingle();

  const submitModal = useCallback(async () => {
    const res = await submit();
    if (res) {
      popup(`Аппарат «${title}» успешно сохранен`);
    }
    return res;
  }, [submit, popup, title]);

  return (
    <PlainModal
      formId="edit_machine"
      controlBy={children}
      onSubmit={submitModal}
      className={styles.root}
    >
      <PlainModalHeader>
        {'Редактирование аппарата'}
      </PlainModalHeader>

      <PlainModalContent>
        <EditMachine disabled={submitting} />
      </PlainModalContent>

      <PlainModalFooter>
        {toggleModal => (
          <ControlsBar>
            <DeleteMachineButton
              className={styles.buttonDelete}
              onDelete={deleteMachine}
              onDeleteSuccess={onDeleteSuccess}
              title={title}
              disabled={submitting || deleting}
            />

            <PlainButton
              onClick={toggleModal}
              outline
            >
              {'Отмена'}
            </PlainButton>

            <PlainButton
              type="submit"
              disabled={submitting}
            >
              {submitting ? 'Сохранение...' : 'Сохранить'}
            </PlainButton>

          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  );
};

export default EditMachineModal;
