import { useCallback, useMemo } from 'react'
import { pipe, values, path } from 'ramda'

import RowLayout3d from 'app/common/layouts/RowLayout3d'
import { PlainFilterComplete } from 'app/common/ui/PlainFilter'
// import PlainToggle from 'app/common/ui-next/plain-toggle'

import {
  AllocationType,
  useSubsidiesData,
  defaultSplitItem,
  SplitTypeID,
  isAllocationType,
} from '../SubsidiesData'

import SecondMealControls from './SecondMealControls'
import DistributionControls from './DistributionControls'
// import { AverageCostVacancyDays } from './AverageCostVacancyDays'
import { AverageCostVacancyDaysByMeal } from './AverageCostVacancyDaysByMeal'

import styles from './SplittingAllItem.scss'

/**
 *  Отдельный элемент распределения типа 'all'
 */

const titleCommon = 'Укажите названия бюджета:'
const title14Classes = 'Укажите названия бюджета для 1–4 классов / для учащихся 7–11 лет:'
const title511Classes = 'Укажите названия бюджета для 5–11 классов / для учащихся 12–17 лет:'
const titleType = 'Вид распределения:'
const titleTypeModal = 'Выбор вида распределения'
const titleTypeEmpty = 'Выберите вид распределения'
const placeholderEstimated = 'Введите значение'
const focusedEstimated = 'Значение'
const headerSplitType1 = 'Распределение бюджета по общим справкам (1-4 классы / для учащихся 7–11 лет, за исключением ГПД)'
const headerSplitType2 = 'Распределение бюджета по индивидуальным справкам (за исключением ГПД)'
const headerSplitType3 = 'Распределение бюджета по индивидуальным справкам (только ГПД)'
const headerSplitType4 = 'Распределение бюджета по индивидуальным справкам на школьное питание с компенсацией родителю (только для учащихся на домашнем обучении — Дети-инвалиды)'
const headerSplitType5 = 'Распределение бюджета по индивидуальным справкам на школьное питание с компенсацией родителю (только для учащихся на домашнем обучении — ОВЗ)'
// const switcherLabel = 'Использовать параметры распределения из общих справок'
const secondMealCompensationLabel1 = 'Компенсация второго питания для I смены'
const secondMealCompensationLabel2 = 'для II смены'
const secondMealCompensationCombinedLabel1 = 'Компенсация второго питания для 1–4 классов / для учащихся 7–11 лет'
const secondMealCompensationCombinedLabel2 = 'для 5–11 классов / для учащихся 12–17 лет'
const averageCostVacancyDaysLabel1 = 'Средняя стоимость в неучебные дни для 1–4 классов / для учащихся 7–11 лет:'
const averageCostVacancyDaysLabel2 = 'Средняя стоимость в неучебные дни для 5–11 классов / для учащихся 12–17 лет:'
const labelAfternoon = 'Полдник'
const labelBreakfast = 'Завтрак'
const labelDinner = 'Обед'


const allocationsCommon = {
  [String(AllocationType.Fixed)]: { id: AllocationType.Fixed, title: 'Фиксированный' },
  [String(AllocationType.Estimated)]: { id: AllocationType.Estimated, title: 'Расчетный' },
}

const allocationsChildrenDisabilities = {
  ...allocationsCommon,
  [String(AllocationType.Combined)]: { id: AllocationType.Combined, title: 'Фиксировано-расчетный' },
}

const getId: ((o: Record<string, { id: number, title: string }>) => number | undefined) = pipe(values, path([0, 'id']))

type Props = {
  className?: string
  splitTypeId: SplitTypeID | null
  disabled?: boolean
}

const SplittingAllItem = ({ className, splitTypeId, disabled }: Props) => {
  const [
    { splits, loading, indexById, errors, commonDataById, submitting }, ,
    { changeSplitItemField, updateSplitItem /* , onUseCommonData */ },
  ] = useSubsidiesData()

  const currentSplit = splits[indexById[String(splitTypeId)]] || defaultSplitItem

  const currentErrors: Record<string, string> = errors[String(splitTypeId)] || {}

  const currentlyUseCommonData = commonDataById[String(splitTypeId)] || false
  const commonData = splits[indexById[String(SplitTypeID.Common)]]
  const displayedData = currentlyUseCommonData && commonData ? commonData : currentSplit

  const allocationTypeId = displayedData.allocation_type_id
  const selectedAllocation = useMemo(() => [allocationTypeId], [allocationTypeId])

  const handleChangeAllocation = useCallback((
    allocation: Record<string, { id: number, title: string }>,
  ) => {
    const allocationType = getId(allocation)
    if (isAllocationType(allocationType)) {
      changeSplitItemField(splitTypeId, 'allocation_type_id', allocationType)
    }
  }, [changeSplitItemField, splitTypeId])

  // const handleClickToggle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   const { checked } = e.currentTarget
  //   onUseCommonData(splitTypeId, checked)
  // }, [splitTypeId, onUseCommonData])


  if (!splitTypeId) {
    return null
  }

  return (
    <RowLayout3d className={className}>
      <div className={styles.row}>
        <PlainFilterComplete
          id={`${splitTypeId}_allocationType`}
          className={styles.allocationSelector}
          title={titleType}
          modalTitle={titleTypeModal}
          itemsObj={
            splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
              ? allocationsChildrenDisabilities
              : allocationsCommon
          }
          selectedArr={selectedAllocation}
          // @ts-ignore
          onChangeRaw={handleChangeAllocation}
          light={false}
          singleSelectable
          selectedAllStateEnabled={false}
          toggleModalOnCrossClick
          noDataText={titleTypeEmpty}
          loading={loading}
          disabled={currentlyUseCommonData || submitting || disabled}
        />

        <div className={styles.headerContainer}>
          <h3 className={styles.header}>
            {splitTypeId === SplitTypeID.Common
              && headerSplitType1}
            {splitTypeId === SplitTypeID.PersonalExptDayCares
              && headerSplitType2}
            {splitTypeId === SplitTypeID.PersonalOnlyDayCares
              && headerSplitType3}
            {splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
              && headerSplitType4}
            {splitTypeId === SplitTypeID.PersonalCompensationToParentDisabilities
              && headerSplitType5}
          </h3>
          {/* {splitTypeId !== SplitTypeID.Common
            && <PlainToggle
              checked={currentlyUseCommonData}
              prefix=""
              labelClassName={styles.switcherLabel}
              className={styles.switcher}
              value={`${splitTypeId}_switcher`}
              label={switcherLabel}
              onChange={handleClickToggle}
              disaabled={disabled}
            />} */}
        </div>
      </div>

      {splitTypeId !== SplitTypeID.PersonalCompensationToParentChildrenDisabilities
        && <div className={styles.row}>
          <DistributionControls
            title={titleCommon}
            splitTypeId={splitTypeId}
            displayedData={displayedData}
            errors={currentErrors}
            disabled={currentlyUseCommonData || submitting || disabled}
            changeSplitItemField={changeSplitItemField}
            updateSplitItem={updateSplitItem}
          />
        </div>}

      {splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
        && <div className={styles.row}>
          <DistributionControls
            title={title14Classes}
            suffix="klasses_1_4"
            splitTypeId={splitTypeId}
            displayedData={displayedData}
            errors={currentErrors}
            disabled={currentlyUseCommonData || submitting || disabled}
            changeSplitItemField={changeSplitItemField}
            updateSplitItem={updateSplitItem}
          />
        </div>}

      {splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
        && <div className={styles.row}>
          <DistributionControls
            title={title511Classes}
            suffix="klasses_5_11"
            splitTypeId={splitTypeId}
            displayedData={displayedData}
            errors={currentErrors}
            disabled={currentlyUseCommonData || submitting || disabled}
            fixedAllocationControlsChooseLabel={
              allocationTypeId === AllocationType.Combined
                ? 'Фиксировано-расчетный размер дотаций'
                : undefined
            }
            changeSplitItemField={changeSplitItemField}
            updateSplitItem={updateSplitItem}
          />
        </div>}

      {splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
        && displayedData.second_food_compensation_enabled !== undefined
        && displayedData.second_food_compensation_shift_1_sum !== undefined
        && displayedData.second_food_compensation_shift_2_sum !== undefined
        && <SecondMealControls
          className={styles.topSpace}
          label1={
            AllocationType.Combined
              ? secondMealCompensationCombinedLabel1
              : secondMealCompensationLabel1
          }
          label2={
            AllocationType.Combined
              ? secondMealCompensationCombinedLabel2
              : secondMealCompensationLabel2
          }
          placeholder={placeholderEstimated}
          focused={focusedEstimated}
          value1={
            allocationTypeId === AllocationType.Combined
              ? 'расчетный'
              : String(displayedData.second_food_compensation_shift_1_sum)
          }
          value2={
            allocationTypeId === AllocationType.Combined
              ? 'отсутствует'
              : String(displayedData.second_food_compensation_shift_2_sum)
          }
          editDisabled={allocationTypeId === AllocationType.Combined || disabled}
          checked={displayedData.second_food_compensation_enabled}
          onCheck={val => changeSplitItemField(splitTypeId, 'second_food_compensation_enabled', val)}
          onChange1={val => changeSplitItemField(splitTypeId, 'second_food_compensation_shift_1_sum', val)}
          onChange2={val => changeSplitItemField(splitTypeId, 'second_food_compensation_shift_2_sum', val)}
        />}

      {/* {(splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
        || splitTypeId === SplitTypeID.PersonalCompensationToParentDisabilities)
        && <AverageCostVacancyDays
          id={splitTypeId}
          label={averageCostVacancyDaysLabel}
          cost={displayedData.average_cost_on_day_offs}
          placeholder={placeholderEstimated}
          focused={focusedEstimated}
          loading={loading}
          disabled={disabled}
          onChange={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs', val)}
        />} */}

      {(splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
        || splitTypeId === SplitTypeID.PersonalCompensationToParentDisabilities)
        && <AverageCostVacancyDaysByMeal
          ID={`${splitTypeId}_1_4`}
          label={averageCostVacancyDaysLabel1}
          labelAfternoon={labelAfternoon}
          labelBreakfast={labelBreakfast}
          labelDinner={labelDinner}
          valueEnable={displayedData.average_cost_on_day_offs_1_4_enabled}
          valueAfternoon={displayedData.average_cost_on_day_offs_1_4_afternoon}
          valueBreakfast={displayedData.average_cost_on_day_offs_1_4_breakfast}
          valueDinner={displayedData.average_cost_on_day_offs_1_4_dinner}
          placeholder={placeholderEstimated}
          focused={focusedEstimated}
          loading={loading}
          disabled={disabled}
          onChangeValueEnable={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_1_4_enabled', val)}
          onChangeValueAfternoon={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_1_4_afternoon', val)}
          onChangeValueBreakfast={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_1_4_breakfast', val)}
          onChangeValueDinner={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_1_4_dinner', val)}
        />}

      {(splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
        || splitTypeId === SplitTypeID.PersonalCompensationToParentDisabilities)
        && <AverageCostVacancyDaysByMeal
          ID={`${splitTypeId}_5_11`}
          label={averageCostVacancyDaysLabel2}
          labelAfternoon={labelAfternoon}
          labelBreakfast={labelBreakfast}
          labelDinner={labelDinner}
          valueEnable={displayedData.average_cost_on_day_offs_5_11_enabled}
          valueAfternoon={displayedData.average_cost_on_day_offs_5_11_afternoon}
          valueBreakfast={displayedData.average_cost_on_day_offs_5_11_breakfast}
          valueDinner={displayedData.average_cost_on_day_offs_5_11_dinner}
          placeholder={placeholderEstimated}
          focused={focusedEstimated}
          loading={loading}
          disabled={disabled}
          onChangeValueEnable={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_5_11_enabled', val)}
          onChangeValueAfternoon={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_5_11_afternoon', val)}
          onChangeValueBreakfast={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_5_11_breakfast', val)}
          onChangeValueDinner={val => changeSplitItemField(splitTypeId, 'average_cost_on_day_offs_5_11_dinner', val)}
        />}
    </RowLayout3d>
  )
}

export default SplittingAllItem
