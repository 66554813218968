import {
  GROUP_FILTER_SET,
  GROUP_FILTER_DEFAULT,
  GROUP_FILTER_UPDATE,
} from 'app/actions/reports/filters/group';


const defaultState = {
  current: null,
  active: false,
};

const group = (state = defaultState, { type, payload }) => {
  if (type === GROUP_FILTER_SET) {
    return ({ ...state, current: payload });
  }
  if (type === GROUP_FILTER_UPDATE) {
    return ({ ...state, ...payload });
  }
  if (type === GROUP_FILTER_DEFAULT) {
    return defaultState;
  }
  return state;
};

export default group;
