// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withTranslation } from 'react-i18next';

import { profileClearKey, lockedTimeToProfile } from 'app/common/actions/profile/password';
import { loginCode, changeLoginProcess } from 'app/common/actions/profile/login';
import { profileChangeKeys } from 'app/common/actions/profile';
import { logout as logoutAction } from 'app/common/actions/user';
import { validateLogin } from 'app/common/login/validate';


export default compose(
  withTranslation('profile'),

  connect(
    state => ({
      requesting: state.profile.requesting,
      saving: state.profile.saving,
      resultTo: state.profile.resultTo,
      requestCodeError: state.profile.requestCodeError,
      codeError: state.profile.codeError,
      lockedUntil: state.profile.lockedUntil,
      emailForceAuth: state.dealer.email_force_auth,
    }),
  ),


  withState('validLogin', 'setValidLogin', true),
  withState('requestCode', 'changeRequestCode', ''),
  withState('savedValue', 'changeSavedValue', ''), // сохраняем логин для запроса
  withState('code', 'changeCode', ''),
  withState('requestLocked', 'changeRequestLocked', 0),
  withState('actualErrorText', 'setActualErrorText', ''),


  withHandlers({
    // Изменение полей
    handleChange: ({
      dispatch,
      changeRequestCode,
      changeCode,
      requestCodeError,
      codeError,
      emailForceAuth,
      setValidLogin,
      setActualErrorText,
      t,
    }) => (e) => {
      const { name, value: valueUntrimmed } = e.target;
      const value = valueUntrimmed.trim();

      const isValid = validateLogin(
        'ru', // не имеет значение
        value,
        {
          types: emailForceAuth ? ['email'] : ['phone', 'email'],
          strict: true,
        },
      );

      setValidLogin(isValid);

      if (!isValid) {
        setActualErrorText(
          emailForceAuth
            ? t('error_login_invalid_email', 'Введите ваш e-mail')
            : t('error_login_invalid_email_phone', 'Введите ваш номер телефона или e-mail'),
        );
      }

      switch (name) {
        case 'requestcode': {
          changeRequestCode(value);
          if (requestCodeError) {
            dispatch(profileClearKey('requestCodeError'));
          }
          break;
        }
        case 'code': {
          changeCode(value);
          if (codeError) {
            dispatch(profileClearKey('codeError'));
          }
          break;
        }
        default:
      }
    },

    // Запрос кода
    handleRequestCode: ({
      dispatch,
      requestCode,
      changeSavedValue,
      requestCodeError,
      validLogin,
      actualErrorText,
      t,
    }) => (e) => {
      e.preventDefault();
      if (!validLogin) {
        dispatch(profileChangeKeys({
          requestCodeError: actualErrorText,
        }));
        return;
      }
      changeSavedValue(requestCode);
      if (requestCodeError) {
        dispatch(profileClearKey('requestCodeError'));
      }
      dispatch(loginCode(requestCode));
    },

    // Запррос на смену
    handleSubmitCode: ({ dispatch, code, savedValue }) => (e) => {
      e.preventDefault();
      dispatch(changeLoginProcess(savedValue, code));
    },

    // Выход
    handleLogout: ({ dispatch }) => (e) => {
      e.preventDefault();
      dispatch(logoutAction());
    },
  }),


  lifecycle({
    componentDidMount() {
      this.props.dispatch(lockedTimeToProfile());
      if (this.props.lockedUntil) {
        const time = this.props.lockedUntil - Date.now();
        this.props.changeRequestLocked((time > 0) ? Math.round(time / 1000) : 0);
      }
      this.timer = setInterval(() => {
        const t = this.props.lockedUntil - Date.now();
        // console.log('interval:', this.props.lockedUntil, Date.now(), t, this.props.requestLocked);
        this.props.changeRequestLocked((t > 0) ? Math.round(t / 1000) : 0);
      }, 1000);
    },

    // $FlowFixMe
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.lockedUntil !== nextProps.lockedUntil) {
        const time = nextProps.lockedUntil - Date.now();
        // console.log('willreceive:', time, this.props.requestLocked);
        this.props.changeRequestLocked((time > 0) ? Math.round(time / 1000) : 0);
      }
    },

    componentWillUnmount() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
  }),

);
