// @flow
import { useCallback, useEffect, useRef } from 'react';
import path from 'ramda/es/path';
import isEmpty from 'ramda/es/isEmpty';

import type { MenuTargetItemState } from './useMenuTargetItemState';


type Item = {
  id: number,
  ...
}

export default function useMenuTargetItemHandling<T: Item>(
  targetState: MenuTargetItemState,
  items: Array<T>,
  defaultState?: number | null, // выбранный id по умолчанию, должен быть в items
) {
  const [,, sellPointsCurrentChange] = targetState;
  const itemsOnceLoaded = useRef(false);

  const handleRadioButtonChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    sellPointsCurrentChange({ id: parseInt(value, 10), single: false });
  }, [sellPointsCurrentChange]);

  // обновление выбранного id при смене состояния
  useEffect(() => {
    // подразумевается что defaultState (id) есть в items
    const id: number | null = (defaultState && !itemsOnceLoaded.current
      ? defaultState
      : path([0, 'id'], items)
    ) || null;

    if (!isEmpty(items)) {
      itemsOnceLoaded.current = true;
    }

    sellPointsCurrentChange({
      id,
      single: id ? items.length === 1 : true,
    });
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  return handleRadioButtonChange;
}
