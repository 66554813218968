// @flow
import React, { type Node } from 'react';
import cn from 'classnames';

import * as types from 'app/common/constants/identifiers/types';

import Card from './icons/card.svg';
import Keychain from './icons/keychain.svg';
import LeatherBracelet from './icons/leather_bracelet.svg';
import SiliconeBracelet from './icons/silicone_bracelet.svg';
import LeatherKeychain from './icons/leather_keychain.svg';
// здесь есть fill и stroke, нужно доработать в figma, если будет исп-ся
// import BraceletAndKeychain from './icons/bracelet_and_keychain.svg';
import CardBank from './icons/card_bank.svg';
import VirtualCard from './icons/virtual_card.svg';
import RfidTag from './icons/rfid_tag.svg';
import HakiBracelet from './icons/haki_bracelet.svg';
import SmartWatch from './icons/smart_watch.svg';
import FitnesBracelet from './icons/fitnes_bracelet.svg';
import FaceID from './icons/face_id.svg';
import TinkoffCard from './icons/tinkoff_card.svg';

import styles from './icon-by-type.scss';


type Props = {
  type: $Values<typeof types>,
  className?: string,
}

const IconByType = ({ type, className }: Props): Node => {
  const classNames = cn(styles.colorStroked, className);

  switch (type) {
    case types.IDENTIFIER_CARD:
      return (
        <Card className={classNames} />
      );

    case types.IDENTIFIER_KEYCHAIN_LEATHER:
      return (
        <LeatherKeychain className={classNames} />
      );

    case types.IDENTIFIER_BANK_CARD:
    case types.IDENTIFIER_SBERBANK_CARD:
      return (
        <CardBank className={classNames} />
      );

    case types.IDENTIFIER_KEYCHAIN:
      return (
        <Keychain className={classNames} />
      );

    case types.IDENTIFIER_BRACELET_LEATHER:
      return (
        <LeatherBracelet className={classNames} />
      );

    case types.IDENTIFIER_BRACELET_SILICONE:
      return (
        <SiliconeBracelet className={classNames} />
      );

    case types.IDENTIFIER_VIRTUAL_CARD:
      return (
        <VirtualCard className={classNames} />
      );

    case types.IDENTIFIER_RFID_TAG:
      return (
        <RfidTag className={classNames} />
      );

    case types.IDENTIFIER_BRACELET_KHAKI:
      return (
        <HakiBracelet className={classNames} />
      );

    case types.IDENTIFIER_SMART_WATCH:
      return (
        <SmartWatch className={classNames} />
      );

    case types.IDENTIFIER_BRACELET_FITNESS:
      return (
        <FitnesBracelet className={classNames} />
      );

    case types.IDENTIFIER_USER_PHOTO:
      return (
        <FaceID className={classNames} />
      );

    case types.IDENTIFIER_TINKOFF:
      return (
        <TinkoffCard className={classNames} />
      );

    default:
  }

  return (
    <Card className={classNames} />
  );
};

export default IconByType;
