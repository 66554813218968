import { useCallback } from 'react'

import { CompensationDetails } from 'app/dataTypes/compensation/details'
import { useCopyColumnsWidths } from 'app/common/ui-components/table'

import { DetailsTable } from './DetailsTable'
import { DetailsTableFooter } from './DetailsTableFooter'


type Options = {
  details: CompensationDetails
  onRequest: (params: { orgID: string, allowanceID: string }) => Promise<void>
  currency: string
}

export function useDetailsTable(options: Options) {
  const { details, onRequest, currency } = options

  const [trFrom, trTo] = useCopyColumnsWidths(details, { useClassIndexes: true })

  const renderDetailsTable = useCallback((params: { orgID: string, allowanceID: string }) => {
    const { orgID, allowanceID } = params
    return (
      <DetailsTable
        orgID={orgID}
        allowanceID={allowanceID}
        compensationDetails={details}
        currency={currency}
        onRequest={onRequest}
        trHeaderRef={trFrom}
      />
    )
  }, [details, onRequest, currency, trFrom])

  const renderDetailsTableFooter = useCallback(() => {
    return (
      <DetailsTableFooter
        trRef={trTo}
        compensationDetails={details}
      />
    )
  }, [details, trTo])

  return {
    renderDetailsTable,
    renderDetailsTableFooter,
  }
}
