// @flow
import React, { type Node } from 'react';

import useValidSection from './useValidSection';
import useRequestValidMachine from './useRequestValidMachine';
import VendingMachineMain from './VendingMachineMain';


type Props = {
  id: number,
  baseUrl: string,
  currentSection: string,
  currentCategoryId?: string,
  parentUrl?: string,
  redirectToDefaulSection: () => void,
  redirectToParent: () => void,
  redirectToCategory: (id: string, history?: boolean) => void,
}

const VendingMachine = ({
  id,
  baseUrl,
  currentSection,
  currentCategoryId,
  parentUrl,
  redirectToDefaulSection,
  redirectToParent,
  redirectToCategory,
}: Props): Node => {
  const sectionValid = useValidSection(currentSection, redirectToDefaulSection);

  const [success, loading] = useRequestValidMachine(id);

  return (
    <VendingMachineMain
      currentSection={sectionValid}
      currentCategoryId={currentCategoryId}
      baseUrl={baseUrl}
      loading={loading || !success}
      parentUrl={parentUrl}
      redirectToParent={redirectToParent}
      redirectToCategory={redirectToCategory}
    />
  );
};

export default VendingMachine;
