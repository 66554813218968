import { useDispatch } from 'react-redux'
import { useCallback, useState } from 'react'

import useComplexActions from 'app/dataProviders/complexes/single/hooks/useComplexActions'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { kitsNeedUpdate } from 'app/actions/orgs/kits/options'
import usePopup from 'app/common/hooks/usePopup'


type Options = {
  orgID: string
  complexID: string
}

export default function useSubmit({ orgID, complexID }: Options): [
  boolean,
  (form: HTMLFormElement) => Promise<boolean>
] {
  const dispatch = useDispatch()
  const popup = usePopup()

  const [submitting, setSubmitting] = useState(false)
  const { submit: submitComplex } = useComplexActions({ orgID, complexID })
  const submitComplexRef = useCallbackRef(submitComplex)

  const submit = useCallback(async (form: HTMLFormElement) => {
    const formData = new FormData(form)

    setSubmitting(true)
    const buffetZone = !!formData.get('buffetZone')
    const hotMealsZone = !!formData.get('hotMealsZone')
    const denyParentMoneySales = !!formData.get('denyParentMoneySales')
    const denyMoreThanOneSales = !!formData.get('denyMoreThanOneSales')
    const useAsAdditional = !!formData.get('useAsAdditional')

    const res = await submitComplexRef.current?.({
      buffetZone,
      hotMealsZone,
      denyParentMoneySales,
      denyMoreThanOneSales,
      useAsAdditional,
    })
    setSubmitting(false)

    if (res) {
      popup('Настройки успешно применены')
      dispatch(kitsNeedUpdate(true))
    }

    return !!res
  }, [submitComplexRef, dispatch, popup])

  return [submitting, submit]
}
