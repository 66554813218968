// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';

import { dishesSearch as dishesSearchAction } from 'app/actions/orgs/dishes/options';


const enhanceOrgsDishes = compose(
  connect(
    state => ({
      searchString: state.orgs.dishes.options.searchString,
    }),
  ),

  withHandlers({
    handleSearch: ({ dispatch }) => (e) => {
      dispatch(dishesSearchAction(e.currentTarget.value));
    },
    handleClearSearch: ({ dispatch }) => (e) => {
      e.preventDefault();
      dispatch(dishesSearchAction(''));
    },
  }),
);

export default enhanceOrgsDishes;
