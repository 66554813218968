// @flow
import { useCallback } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import { saveSettingsKey } from 'app/common/actions/settings/save'


type LimitsSettings = {
  changeLimitsBlockedUntil: number,
}

type HandleSave = (ls: LimitsSettings) => void

export default function useLimitsSettings(): [LimitsSettings, HandleSave] {
  const dispatch = useDispatch()

  const limitsSettings = useSelector(state => (state as any).settings.limits, shallowEqual)

  const handleSaveSettings = useCallback((ls: LimitsSettings) => {
    dispatch(saveSettingsKey('limits', ls))
  }, [dispatch])

  return [limitsSettings, handleSaveSettings]
}
