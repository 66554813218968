// @flow
import React, { type Element } from 'react';

import PlainRadioButton from 'app/common/ui-next/plain-radio-button';

import styles from './RadioButton.scss';


type Props = {
  checked?: boolean,
  disabled?: boolean,
  label?: string | Element<any>,
  value?: string,
  name?: string,
  id?: string,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  className?: string,
  hideLabel?: boolean,
  style?: Object,
  additional?: any,
  description?: any, // uses in RadioButtonGroup
};

// !!1 deprecated, use PlainRadioButton

const RadioButton = ({ additional, className, label = '', name = '', value = '', description, ...other }: Props) => {
  if (additional) {
    return (
      <div className={styles.controls}>
        <PlainRadioButton
          {...other}
          label={label}
          name={name}
          value={value}
          className={className}
        />
        <div className={styles.additional}>{additional}</div>
      </div>
    );
  }

  return (
    <PlainRadioButton
      {...other}
      label={label}
      name={name}
      value={value}
      className={className}
    />
  );
};

export default RadioButton;
