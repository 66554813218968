// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import usePaging from 'app/common/hooks/usePaging';


export default function useOrgsOrdersPagination() {
  const { currentPage, handlePage, setCurrentPage } = usePaging();
  const limit = useSelector(state => state.settings.schools.rows, shallowEqual);
  const offset = (currentPage - 1) * limit;

  const resetPagination = useCallback(() => {
    setCurrentPage(1);
  }, [setCurrentPage]);

  return { limit, offset, currentPage, handlePage, resetPagination };
}
