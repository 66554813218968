import { MenuItems, MenuItem } from 'app/common/ui-components/app-bar'
import { withSettingsAccess } from 'app/containers/rights'
import { useGeneralSettings } from 'app/dataProviders/generalSettings/hooks'


const SettingsMenuItem = withSettingsAccess(MenuItem)

type Props = {
  pathname: string,
}

const AppMenuItems = ({ pathname }: Props) => {
  const { generalSettings, loading } = useGeneralSettings()

  return (
    <MenuItems>
      <MenuItem
        to="/orgslist"
        active={/^\/(orgs|orgslist)(\/|$)/.test(pathname)}
      >
        {'Заведения'}
      </MenuItem>

      <MenuItem
        to="/reports"
        active={/^\/(reports)(\/|$)/.test(pathname)}
      >
        {'Отчеты'}
      </MenuItem>

      <MenuItem
        to="/finances"
        active={/^\/(finances)(\/|$)/.test(pathname)}
      >
        {'Финансы'}
      </MenuItem>

      {(generalSettings.useСompensations || loading)
        && <MenuItem
          to="/compensation"
          disabled={loading}
          active={/^\/(compensation)(\/|$)/.test(pathname)}
        >
          {'Компенсация'}
        </MenuItem>}

      <SettingsMenuItem
        to="/settings"
        active={/^\/(settings)(\/|$)/.test(pathname)}
      >
        {'Настройки'}
      </SettingsMenuItem>
    </MenuItems>

  )
}

export default AppMenuItems
