// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import ErrorString from 'app/common/ui/ErrorString';

import styles from './PlainInputIcon.scss';


const cx = classNames.bind(styles);
type Props = {
  value?: string,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  name?: string,
  id?: string,
  placeholder?: string,
  isError: boolean,
  errorText?: string,
  isValid?: boolean,
  icon?: any,
  reference?: (a: HTMLInputElement | null) => void,
  className?: string,
  label?: string,
  required?: boolean,
  disabled?: boolean,
};

/**
 * Упрощенная версия PlainInput с поддержкой иконок
 * и выделением цветом успешного состояния
 */
const PlainInputIcon = ({
  value,
  onChange,
  name,
  id,
  placeholder,
  isError,
  errorText,
  isValid,
  icon,
  reference,
  className,
  label,
  required,
  disabled,
}: Props) => (<div className={className}>
  {label
      && <label htmlFor={id || name} className={styles.label}>
        {label}
        {required && <span className={styles.star}>{'&nbsp;*'}</span>}
      </label>}
  <div className={styles.main}>
    <input
      name={name || id}
      id={id}
      className={
        cx({
          input: true,
          error: !!isError,
          success: !!isValid,
        })
      }
      value={value || ''}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled} // TODO сделать как в PlainInputs, если понадобится отображать содержимое при disabled
      ref={reference}
    />
    {icon
        && <div className={styles.icons}>
          {icon}
        </div>}
  </div>
  {isError && <ErrorString>{errorText}</ErrorString>}
</div>);

export default PlainInputIcon;
