import { ChangeEvent, useCallback, useState } from 'react'

import PlainInputMasked, { maskTypes } from 'app/common/ui-next/plain-input-masked'
import PlainLabel from 'app/common/ui-next/plain-label'
import { PlainColumn, PlainColumns } from 'app/common/ui/PlainGrid'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import { ScheduleItemType } from 'app/dataTypes/requisites/SupportScheduleType'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'

import styles from './ScheduleItemForm.scss'
import { getSupportScheduleError, getSupportScheduleFinishError, getSupportScheduleStartError } from '../../errors/checkSupportScheduleErrors'
import { timeValidate } from '../../utilities/timeUtilities'

type KeyTimeType = 'keyStart' | 'keyFinish'

type Props = {
  formID: string
  label: string
  divLabel?: number
  scheduleValue: ScheduleItemType
  checkBoxLabel?: string
  disabled?: boolean
  loading?: boolean
  errors: Record<string, string>
  onChange?: (
    ID: string,
    keyField: keyof ScheduleItemType,
    newValue: string | boolean,
    onCheckError?: boolean
  ) => void
}


type TimeoutIdType = {
  [k: string]: NodeJS.Timeout | null
}

const timeoutId: TimeoutIdType = {}

const clearTimeoutHandler = (formID: string) => {
  const resId = timeoutId[formID]
  if (resId !== null && resId !== undefined) {
    clearTimeout(resId)
  }
}

export function ScheduleItemForm({
  formID,
  label,
  divLabel,
  scheduleValue,
  disabled,
  loading,
  errors,
  checkBoxLabel,
  onChange,
}: Props) {
  const onChangeRef = useCallbackRef(onChange)
  const [isFocus, setFocus] = useState<boolean>(true)
  const isShowError = !isFocus && !scheduleValue.keyTimeless


  const timeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, id } = e.target
      const res = timeValidate(value)
      onChangeRef.current?.(formID, id as KeyTimeType, res)
    },
    [onChangeRef, formID]
  )


  const checkBoxHandler = useCallback(
    (e: any) => {
      const { checked } = e.target
      if (checked) {
        onChangeRef.current?.(formID, 'keyStart', '')
        onChangeRef.current?.(formID, 'keyFinish', '')
      }
      onChangeRef.current?.(formID, 'keyTimeless', checked, true)
      clearTimeoutHandler(formID)
      setFocus(true)
    },
    [onChangeRef, formID]
  )


  const fillEmptyHandler = useCallback(
    (fieldId: KeyTimeType) => {
      if (scheduleValue[fieldId]) {
        const res = scheduleValue[fieldId].replace(/_/g, '0')
        onChangeRef.current?.(formID, fieldId as keyof ScheduleItemType, res, true)
      }
    },
    [onChangeRef, scheduleValue, formID]
  )


  const onBlurHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target
    fillEmptyHandler(id as KeyTimeType)
    clearTimeoutHandler(formID)
    timeoutId[formID] = setTimeout(() => {
      setFocus(false)
    }, 300)
  }, [fillEmptyHandler, formID])


  const onFocusHandler = useCallback(() => {
    clearTimeoutHandler(formID)
    setFocus(true)
  }, [formID])


  const errorText = isShowError && getSupportScheduleError(formID, errors)

  return (
    <div className={getScrollToErrorClassName(!!errorText)}>
      <PlainColumns className={styles.row}>
        <PlainColumn div={divLabel}>
          <PlainLabel htmlFor="workDay" className={styles.label}>
            {label}
          </PlainLabel>
        </PlainColumn>

        <PlainColumn className={styles.column}>
          <PlainInputMasked
            value={scheduleValue.keyStart}
            className={styles.inputTime}
            displayType="input"
            id="keyStart"
            autoFocus={isFocus}
            loading={loading}
            disabled={disabled || scheduleValue.keyTimeless}
            predefinedMaskType={maskTypes.MASK_TIME}
            onChange={timeHandler}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            error={isShowError && !!getSupportScheduleStartError(formID, errors)}
          />
        </PlainColumn>

        <PlainColumn className={styles.column}>
          <PlainInputMasked
            value={scheduleValue.keyFinish}
            className={styles.inputTime}
            displayType="input"
            id="keyFinish"
            loading={loading}
            disabled={disabled || scheduleValue.keyTimeless}
            predefinedMaskType={maskTypes.MASK_TIME}
            onChange={timeHandler}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
            error={isShowError && !!getSupportScheduleFinishError(formID, errors)}
          />
        </PlainColumn>

        <PlainColumn className={styles.column}>
          {checkBoxLabel && (
            <PlainCheckbox
              value="workDay"
              checked={scheduleValue.keyTimeless}
              disabled={loading || disabled}
              label={checkBoxLabel}
              onChange={checkBoxHandler}
            />
          )}
        </PlainColumn>
      </PlainColumns>
      {!!errorText
        && <PlainHelperText className={styles.error} error>
          {errorText}
        </PlainHelperText>}
    </div>

  )
}
