import { Fragment } from 'react'

import { Table, Tr, Th } from 'app/common/ui-components/table'
import { OperationType, Org, TotalDataItem } from 'app/dataTypes/reports/data'
import { useCurrencySign } from 'app/common/components-next/currency'
import PlainTooltip from 'app/common/ui-next/plain-tooltip'
import styles from './LeftTable.scss'
import { DualLineCell } from './DualLineCell'


type Props = {
  className?: string
  sameRowClassBase?: string
  data?: Array<Org>
  total?: Array<TotalDataItem>
  operationType?: OperationType
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
}

const LeftTable = ({
  className,
  sameRowClassBase = 'row_',
  data,
  total,
  operationType,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const middlePosition = !!data
  const bottomPosition = !!total
  const currency = useCurrencySign()
  return (
    <Table className={className}>
      <thead>
        <Tr header hidden={middlePosition || bottomPosition}>
          <Th
            scope="col"
            alignLeft
            bgFilled
          >
            {'Заведение'}
          </Th>
        </Tr>
      </thead>

      {data && operationType
        && <tbody>
          {data.map(({
            ID,
            title,
          }, rowIndex) => (
            <Fragment key={ID}>
              <Tr
                className={`${sameRowClassBase}${rowIndex}`}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                interlineBorder
              >
                <Th
                  alignLeft
                  className={styles.org}
                >
                  <PlainTooltip
                    className={styles.limitedCell}
                    text={title}
                  >
                    {title}
                  </PlainTooltip>
                </Th>
                <Th
                  alignLeft
                  borderBottom={false}
                  className={styles.heightRow}
                >
                  <DualLineCell
                    line1={operationType === 'purchases' ? 'Продажи:' : 'Дотации:'}
                    line2="Корректировки:"
                  />
                </Th>
              </Tr>
            </Fragment>
          ))}
        </tbody>}

      {total
        && <tfoot>
          <Tr
            hidden={!bottomPosition}
            headerHasBorderBottom={false}
            header
          >
            <Th
              className={styles.org}
              alignLeft
              bgFilled
            >
              &nbsp;
            </Th>
            <Th
              className={styles.summary}
              alignLeft
              bgFilled
            >
              {`Итого, ${currency}:`}
            </Th>
          </Tr>
        </tfoot>}
    </Table>
  )
}

export default LeftTable
