import { useState } from 'react'

import { useCurrencySign } from 'app/common/components-next/currency'
import { Space } from 'app/common/templates-next/report-template'
import { CompensationTransactionsParams } from 'app/dataTypes/compensation/transations'

import { Filters } from './filters'
import { Result } from './result/Result'


export const Recipients = () => {
  const [params, setParams] = useState<null | CompensationTransactionsParams>(null)
  const currency = useCurrencySign()

  return (
    <Filters onSubmit={setParams}>
      {params
        && <>
          <Space />
          <Result
            params={params}
            currency={currency}
          />
        </>}
    </Filters>
  )
}
