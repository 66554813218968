import { ChangeEvent } from 'react'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import styles from './reset-link.scss'


type Props = {
  children: string,
  onClick: (e: ChangeEvent<HTMLButtonElement>) => void,
}

const ResetFilterLink = ({ children, onClick }: Props) => (
  <PlainButtonAsLink
    linkClassName={styles.resetLink}
    onClick={onClick}
  >
    {children}
  </PlainButtonAsLink>
)

export default ResetFilterLink
