// @flow
import { useMemo, useState, useEffect } from 'react';
import prop from 'ramda/es/prop';
import complement from 'ramda/es/complement';
import propEq from 'ramda/es/propEq';

import { useFilterData, appliedFilterConvert } from 'app/common/ui-components/filter';
import detailsFilter from 'app/components/common/reports/filters/details';
import orgFilter from 'app/components/common/reports/filters/org';

import showDataFilter from '../ReportFilter/filters/showData';
import groupFilter from '../ReportFilter/filters/group';

/**
 *  Управление отображением данных
 */

const toBoolean = complement(complement(prop(0)));
const hasSingle = propEq('length', 1);

const convertMapper = {
  [detailsFilter]: toBoolean,
  [groupFilter]: toBoolean,
  [showDataFilter]: toBoolean,
  [orgFilter]: hasSingle,
};

type AppliedParams = {
  details: boolean,
  unspent: boolean,
  groupped: boolean,
  singleOrg: boolean,
}

const defaultState: AppliedParams = {
  details: false,
  unspent: false,
  groupped: false,
  singleOrg: false,
};

export default function useAppliedFilter(stateSwitch: boolean): AppliedParams {
  const [,,, { getAppliedFilterActive }] = useFilterData();
  const [params, setParams] = useState(defaultState);

  const calcParams = useMemo(() => {
    const filterData = getAppliedFilterActive();
    if (filterData) {
      const converted = appliedFilterConvert(convertMapper, filterData);
      return {
        details: converted[detailsFilter],
        unspent: converted[showDataFilter],
        groupped: converted[groupFilter],
        singleOrg: converted[orgFilter],
      };
    }
    return defaultState;
  }, [getAppliedFilterActive]);

  useEffect(() => {
    if (stateSwitch) {
      setParams(calcParams);
    }
  }, [stateSwitch]); // eslint-disable-line react-hooks/exhaustive-deps

  return params;
}
