// @flow
import React from 'react';
// import moment from 'moment-timezone';

// import useGenerateFileRequests from 'app/components/common/reports/history/useGenerateFileRequests';
// import { GenerateFile } from 'app/common/ui-components/progress-indicator';
// import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';

import styles from './HeaderBar.scss';


type Props = {
  reportId: number,
}

const HeaderBar = ({ reportId }: Props) => { // eslint-disable-line
  // const reportFileName = `purchases_by_group_complexes_${moment().format('YYYYMMDD_HHmmss')}.xlsx`;
  // const generateFileRequests = useGenerateFileRequests(reportId, reportFileName);

  return (
    <div className={styles.root}>
      {/* <GenerateFile
        className={styles.download}
        labelClassName={styles.downloadLabel}
        label="Скачать как"
        generateAndDownloadPrompt="Сгенерировать и скачать в формате «документ Excel»"
        downloadPrompt="Скачать в формате «документ Excel»"
        {...generateFileRequests}
      >
        <PlainIcon
          width={20}
          height="auto"
          type={iconTypes.EXCEL}
        />
      </GenerateFile> */}

      <div className={styles.historyContent}>
        <HistoryLink reportType={reportTypes.REPORT_PURCHASES} />
      </div>
    </div>
  );
};

export default HeaderBar;
