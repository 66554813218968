// @flow
import React, { type Node, Fragment } from 'react';

import { Table, useCopyColumnsWidths } from 'app/common/ui-components/table';
import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow';
import Loading from 'app/common/components/Loader';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import type { Dish } from 'app/dataTypes/Dishes.types';
import Pager from 'app/common/ui/Paging';
import type { Paging } from 'app/common/hooks/usePagingExtended';

import useHeaderCellsData from './useHeaderCellsData';
import useRowCellsData from './useRowCellsData';
import HeaderRow from './HeaderRow';
import Row from './Row';
import type { ColumnVisibility } from './columns';

import styles from './AvailableDishesTable.scss';


type Props = {
  alreadyAdded: Map<string, any>,
  willAddItems: Map<string, any>,
  willRemoveItems: Map<string, any>,
  disabledItems: Map<string, any>,
  dishes: Array<Dish>,
  loading?: boolean,
  disabled?: boolean,
  total: number,
  paging: Paging,
  columnSettings: ColumnVisibility,
  onChange: (id: string, chkd: boolean, title: string) => void,
  singleSelectable?: boolean,
}

const AvailableDishesTable = ({
  alreadyAdded,
  willAddItems,
  willRemoveItems,
  disabledItems,
  dishes,
  loading,
  disabled,
  total,
  paging,
  columnSettings,
  onChange,
  singleSelectable,
}: Props): Node => {
  const headerCells = useHeaderCellsData(columnSettings);
  const rowsData = useRowCellsData(dishes, columnSettings);
  const { pageSize, currentPage, onPage } = paging;

  const [refFrom, refTo] = useCopyColumnsWidths(rowsData);

  return (
    <Fragment>
      <div className={styles.headerContainer}>
        <Table className={styles.table}>
          <thead>
            <HeaderRow
              data={headerCells}
              reference={refTo}
            />
          </thead>
        </Table>
        {loading
          && total > 0
          && <Loading className={styles.loadingOverContent} />}
      </div>

      <ScrollbarsWShadow
        autoHeight
        autoHeightMin={300}
        autoHeightMax="56vh"
        fillAllSpace
      >
        {loading
          && total === 0
          && <Loading />}

        {!loading
          && total === 0
          && <EmptyWarn>
            {'Нет записей, удовлетворяющих условиям'}
          </EmptyWarn>}

        {total > 0
          && <Table className={styles.table}>
            <thead>
              <HeaderRow
                data={headerCells}
                reference={refFrom}
                hidden
                dummy
              />
            </thead>

            <tbody>
              {rowsData.map((cells) => {
                const id = cells[0].value;
                const title = cells[1].value;

                return (
                  <Row
                    key={id}
                    id={id}
                    title={title}
                    cells={cells}
                    alreadyAdded={alreadyAdded.has(id)}
                    disabledItem={disabledItems.has(id)}
                    disabled={loading || disabled}
                    checked={
                      (alreadyAdded.has(id) && !willRemoveItems.has(id))
                      || (!alreadyAdded.has(id) && willAddItems.has(id))
                    }
                    onChange={onChange}
                    asRadio={singleSelectable}
                  />
                );
              })}
            </tbody>
          </Table>}
      </ScrollbarsWShadow>

      <div className={styles.footer}>
        <Pager
          total={total}
          pageSize={pageSize}
          currentPage={currentPage}
          currentPageSize={dishes.length}
          onPage={onPage}
        />
      </div>
    </Fragment>
  );
};

export default AvailableDishesTable;
