import { FunctionComponentElement, ReactNode } from 'react'

import { Rights } from 'app/common/constants/rights'

import { getAccessObject, type RightsPath } from '../types'
import useRightsData from './useRightsData'
import childrenWithDisabledProp from '../utils/childrenWithDisabledProp'
import { rootRights, specificRights } from '..'


const adminRights = [rootRights.SU, specificRights.SU] as const

type Props<T extends keyof Rights> = {
  children: FunctionComponentElement<{ disabled?: boolean }>
  pathTo: RightsPath<T> | typeof adminRights
  readOnlyHidden?: boolean
  disabled?: boolean
  readAffect?: 'hide' | 'disable' | 'ignore'
  editAffect?: 'hide' | 'disable' | 'ignore'
  viewOnHide?: ReactNode
}

/**
 *  Для оборачивания компонентов которым требуются права доступа.
 *  Возвращает null если нет прав даже на чтение.
 *  Добавляются дополнительные props:
 *    + disabled - true, пока права не загружены или есть права только на чтение
 */

function WithRights<T extends keyof Rights>({
  children,
  pathTo,
  readOnlyHidden,
  disabled,
  readAffect = 'hide',
  editAffect = 'disable',
  viewOnHide,
}: Props<T>) {
  const [{ su, rights, ready }] = useRightsData()

  const adminRightsDemand = pathTo[0] === rootRights.SU && pathTo[0] === rootRights.SU

  // права не готовы - disabled
  if (!ready) {
    if (readOnlyHidden) {
      return null
    }
    return childrenWithDisabledProp(children, true)
  }

  if (adminRightsDemand && !su) {
    return null
  }

  if (disabled) {
    return childrenWithDisabledProp(children, true)
  }

  // полный доступ
  if (su || adminRightsDemand) {
    return childrenWithDisabledProp(children, false)
  }

  const { edit, read } = getAccessObject(pathTo as RightsPath<T>, rights)

  const hide = (
    (readAffect === 'hide' && !read)
    || (editAffect === 'hide' && !edit)
  )
  if (hide) return viewOnHide

  const disable = (
    (readAffect === 'disable' && !read)
    || (editAffect === 'disable' && !edit)
  )
  if (disable) return childrenWithDisabledProp(children, true)

  return children
}

export default WithRights
