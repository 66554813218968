// @flow

const COMMON: 'common' = 'common';
const TOTAL: 'total' = 'total';

const multiLineCellTypes = {
  COMMON,
  TOTAL,
};

export default multiLineCellTypes;
