// @flow
import moment from 'moment-timezone';


export const disabledTodayAndFuture = (date: typeof Date) => {
  const mToday = moment();
  const mDate = moment(date);
  if (!mDate.isSameOrAfter(mToday, 'day')) {
    return false;
  }
  return true;
};

export const disabledFuture = (date: typeof Date) => {
  const mToday = moment();
  const mDate = moment(date);
  if (!mDate.isAfter(mToday, 'day')) {
    return false;
  }
  return true;
};

/**
 * Учебный год по умолчанию в формате 'YYYY-MM-DD' (начало 1 август - конец 31 июля)
 */
export const getDefaultAcademicYear = () => {
  const year = moment().year();
  const month = moment().month(); // 0 - январь
  return ({
    start: (month < 7
      ? moment([year - 1, 7]).format('YYYY-MM-DD')
      : moment([year, 7]).format('YYYY-MM-DD')),
    finish: (month < 7
      ? moment([year, 6]).endOf('month').format('YYYY-MM-DD')
      : moment([year + 1, 6]).endOf('month').format('YYYY-MM-DD')),
  });
};

export const defaultAcademicYear = getDefaultAcademicYear();

/**
 * Возвращает валидный moment по строке.
 * Если входящая дата неверна, возвращает moment сегодняшнего дня
 */
export const getValidMomentOrToday = (date?: string) => {
  const m1 = moment(date);
  return (m1.isValid() ? m1 : moment());
};

/**
 * Возвращает валидный moment по строке date или undefined.
 * Undefined возвращается при отсутствии date или его неверном формате
 */
export const getValidMomentOrUndefined = (date?: string) => {
  if (!date) return undefined;
  const m1 = moment(date);
  return (m1.isValid() ? m1 : undefined);
};
