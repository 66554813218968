// @flow
import React, { type Node } from 'react';

import useDishes, { type Options } from './useDishes';
import DishesDataContext from './DishesDataContext';


type Props = {
  children: Node,
  ...$Exact<Options>,
}

const DishesData = ({ children, ...options }: Props) => {
  const value = useDishes(options);

  return (
    <DishesDataContext.Provider value={value}>
      {children}
    </DishesDataContext.Provider>
  );
};

export default DishesData;
