// @flow
import React, { type Node } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Loading from 'app/common/components/Loader';

import Stub from './Stub';


type Props = {
  children: Node,
}

const MustEnableEMoney = ({ children }: Props) => {
  const { must_enable_e_money: mustEnableEMoney, loading } = useSelector(state => state.user, shallowEqual);

  if (loading) {
    return (<Loading />);
  }

  if (mustEnableEMoney) {
    return (<Stub />);
  }

  return children;
};

export default MustEnableEMoney;
