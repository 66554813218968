// @flow
import React, { type Node } from 'react';

import type { SelectedItem } from './SlotsDishes';
import styles from './ModifyContent.scss';


type Props = {
  items: Array<SelectedItem>,
  title: string,
}

const ModifyContent = ({ items, title }: Props): Node => {
  return (
    <div className={styles.root}>
      <p>{title}</p>
      {items.map(({ slotId, productTitle, amountModify }) => (
        <dl key={slotId}>
          <dt>{productTitle}</dt>
          <dd>{amountModify}</dd>
        </dl>
      ))}
    </div>
  );
};

export default ModifyContent;
