// @flow
import { type ReportsPerformBalancesParams } from 'app/common/api/requests/reports/perform';

import { getValue } from 'app/common/ui-components/filter';
import currentStateDateFilter from 'app/components/common/reports/filters/currentStateDate';
import customerSearchFilter, { customerTypes as ctSearch } from 'app/components/common/reports/filters/customerSearch';
import { customerTypes as ctReport } from 'app/components/common/reports/filters/customerType';
import balanceTypeFilter from 'app/components/common/reports/filters/balanceType';


const search2ReportMap: { [$Values < typeof ctSearch >]: $Values<typeof ctReport> } = {
  [ctSearch.Employee]: ctReport.EMPLOYEES,
  [ctSearch.Student]: ctReport.STUDENTS,
};

type State = { [key: string]: any }
type Res = $PropertyType<ReportsPerformBalancesParams, 'params'>

const generateParamsByUser = (state: State): Res => {
  const [current, date] = getValue(currentStateDateFilter, state);
  const [customerId, customerType] = getValue(customerSearchFilter, state);
  const [balanceType] = getValue(balanceTypeFilter, state);

  const limits = (
    customerType === ctSearch.Student ? {
      student_ids: [parseInt(customerId, 10)],
      customer_type: [search2ReportMap[customerType]],
      balance_type: balanceType,
    } : {
      employee_ids: [parseInt(customerId, 10)],
      customer_type: [search2ReportMap[customerType]],
      balance_type: balanceType,
    }
  );

  return {
    limits,
    customer_search: 'by_customer_data',
    details: true,
    details_group: 'by_users',
    date,
    current: current === 'current',
  };
};

export default generateParamsByUser;
