import { createSelector } from 'reselect';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import groupWith from 'ramda/es/groupWith';
import slice from 'ramda/es/slice';

import toFixedValue from 'app/common/lib/toFixedValue';


export type UserByGroupType = {
  id: string,
  name: string,
  group: string,
  balance: string,
  customerId: number,
  availableForRefund: number,
}

const group = groupWith((a, b) => (a.group === b.group));

const selectUsersByGroup = createSelector(
  [
    ({ users }) => users,
    ({ currentPage, rows }) => ((currentPage - 1) * rows),
    ({ currentPage, rows }) => (currentPage * rows),
  ],
  (users, from, to) => pipe(
    slice(from, to),
    map(({ id, name, group: gp, balance, customerId, availableForRefund }) => ({
      id,
      name,
      group: gp || 'Неизвестный класс\u00A0/ должность',
      balance: toFixedValue(balance),
      customerId,
      availableForRefund,
    }: UserByGroupType)),
    group,
  )(users),
);

export default selectUsersByGroup;
