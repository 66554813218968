// @flow
import React, { type Node, Component, type Element } from 'react';
import classNames from 'classnames';

import Loader from 'app/common/components/Loader';

import RadioButton from './RadioButton';
import styles from './RadioButtonGroup.scss';


type Props = {
  children: any, // TODO
  className?: string,
  classNameLabel?: string,
  defaultSelected?: any,
  name: string, // нужно, когда несколько групп на странице
  onChange: Function,
  style?: Object,
  valueSelected?: any,
  label?: string | Node,
  loading?: boolean,
  id?: string,
};

type State = {
  numberCheckedRadioButtons: number,
  selected: string,
};

class RadioButtonGroup extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      numberCheckedRadioButtons: 0,
      selected: '',
    };
  }

  // $FlowFixMe
  UNSAFE_componentWillMount() { // eslint-disable-line
    let cnt = 0;
    let selected = '';
    const { valueSelected, defaultSelected, children } = this.props;
    if (valueSelected !== undefined) {
      selected = valueSelected;
    } else if (defaultSelected !== undefined) {
      selected = defaultSelected;
    }

    React.Children.forEach(children, (option) => {
      if (this.hasCheckAttribute(option)) cnt += 1;
    }, this);

    this.setState({
      numberCheckedRadioButtons: cnt,
      selected,
    });
  }

  // $FlowFixMe
  UNSAFE_componentWillReceiveProps(nextProps: Props) { // eslint-disable-line
    if (nextProps.hasOwnProperty('valueSelected')) { // eslint-disable-line no-prototype-builtins
      this.setState({ selected: nextProps.valueSelected });
    }
  }

  getSelectedValue() {
    const { selected } = this.state;
    return selected;
  }

  setSelectedValue(newSelectionValue: string) {
    this.updateRadioButtons(newSelectionValue);
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value: newSelection } = event.currentTarget;
    const { numberCheckedRadioButtons } = this.state;
    this.updateRadioButtons(newSelection);

    // Successful update
    if (numberCheckedRadioButtons === 0) {
      const { onChange } = this.props;
      if (onChange) onChange(event, newSelection);
    }
  };

  hasCheckAttribute = (radioButton: Element<typeof RadioButton>) => radioButton.props.hasOwnProperty('checked') // eslint-disable-line no-prototype-builtins
      && radioButton.props.checked;

  updateRadioButtons(newSelection: string) {
    const { numberCheckedRadioButtons } = this.state;
    if (numberCheckedRadioButtons === 0) {
      this.setState({ selected: newSelection });
    } else {
      console.log(`Cannot select a different radio button while another radio button
        has the 'checked' property set to true.`);
    }
  }

  clearValue() {
    this.setSelectedValue('');
  }

  render() {
    const { children, name, loading, style, className, label, classNameLabel, id } = this.props;
    const { selected } = this.state;
    const options = React.Children.map(children, (option) => {
      const {
        name: opName, // eslint-disable-line no-unused-vars
        value: opValue,
        label: opLabel,
        onChange, // eslint-disable-line no-unused-vars
        description,
        ...other
      } = option.props;

      return (
        <React.Fragment>
          <RadioButton
            {...other}
            name={name}
            key={opValue}
            value={opValue}
            label={opLabel}
            onChange={this.handleChange}
            checked={opValue === selected}
          />
          {typeof description === 'function'
            ? description(styles.description)
            : description}
        </React.Fragment>
      );
    }, this);

    return (loading
      ? <Loader className={styles.loader} />
      : <fieldset
        id={id}
        style={style}
        className={classNames(className, styles.fieldset)}
      >
        {label
          && <legend className={classNames(styles.legend, classNameLabel)}>
            {label}
          </legend>}
        {options}
      </fieldset>
    );
  }
}

export default RadioButtonGroup;
