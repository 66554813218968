// @flow
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import { validatePassword, isValid } from 'app/common/lib/validate/password';
import type { passValidType } from 'app/common/lib/validate/password';


export default compose(
  withState('newPassword1', 'changeNewPassword1', ''),
  withState('newPassword2', 'changeNewPassword2', ''),
  mapProps(
    ({ newPassword1, newPassword2, ...others }) => {
      const pass1ValidProps: passValidType = validatePassword(newPassword1);
      const pass1Valid: boolean = isValid(pass1ValidProps);
      const pass2Valid: boolean = (newPassword1 === newPassword2) && isValid(newPassword2);
      return ({
        newPassword1,
        newPassword2,
        pass1ValidProps,
        pass1Valid,
        pass2Valid,
        ...others,
      });
    },
  ),
);
