import moment from 'moment-timezone';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import defaultProps from 'recompose/defaultProps';
import mapProps from 'recompose/mapProps';
import withProps from 'recompose/withProps';
import lifecycle from 'recompose/lifecycle';
import omit from 'ramda/es/omit';

import nope from 'app/common/lib/nope';

/**
 * HOC для текстовых полей с выпадающим календарем
 *
 */
// type Props = {
//   disabledDaysInPicker: (typeof Date) => boolean,
//   onChangeDate: (string, string, string) => void,
//   onBlurDate: (string, string, string) => void,
// }

export default compose(
  defaultProps({
    label: '',
    value: '',
    mask: '11.11.1111',
    placeholder: '__.__.____',
    format: 'DD.MM.YYYY',
    fieldFormat: 'DD.MM.YYYY',
    disabled: false,
    emptyUnpossible: false,
  }),

  withState('momentDate', 'setMomentDate', null),
  withState('update', 'setUpdate', false),
  withState('error', 'setError', ''),

  withHandlers({
    isDisabled: ({ disabledDaysInPicker }) => (mDate) => {
      if (typeof disabledDaysInPicker === 'function') {
        return disabledDaysInPicker(mDate.toDate());
      }
      return false;
    },
    // вызов из календаря
    handleDayPick: ({ onChangeDate, format, name, setMomentDate }) => (day) => {
      const mDay = moment(day);
      setMomentDate(mDay);
      if (typeof onChangeDate === 'function') {
        onChangeDate(mDay.format(format), 'valid', name);
      }
    },
  }),

  withHandlers({
    updateDate: ({
      fieldFormat,
      format,
      name,
      emptyUnpossible,
      isDisabled,
      setMomentDate,
    }) => (value, cb = nope, invalidType) => {
      if (!value) { // пустое поле
        if (emptyUnpossible) return false;
        cb('', emptyUnpossible ? invalidType : 'valid');
        setMomentDate(null);
        return true;
      }
      const mDate = moment(value, fieldFormat, true);
      const valid = mDate.isValid();
      const disabled = isDisabled(mDate);
      if (valid && !disabled) {
        setMomentDate(mDate);
        cb(
          mDate.format(format),
          'valid',
          name,
        );
        return true;
      }
      if (valid && disabled) {
        cb(
          mDate.format(format),
          'disabled',
          name,
        );
        return true;
      }
      cb(
        mDate.format(format),
        invalidType,
        name,
      );
      return false;
    },
  }),

  // события из поля
  withHandlers({
    handleChange: ({ onChangeDate, updateDate, disabled, loading, setError }) => (e) => {
      if (disabled || loading) return;
      const { value } = e.target;
      if (!updateDate(value, onChangeDate)) {
        setError('Неверный формат даты');
        return;
      }
      setError('');
    },
    handleBlur: ({ onBlurDate, updateDate, disabled, loading, setError }) => (e) => {
      if (disabled || loading) return;
      setError('');
      const { value } = e.target;
      updateDate(value, onBlurDate, 'invalid');
    },
  }),

  lifecycle({
    componentDidUpdate(prevProps) {
      const { value, format, setMomentDate } = this.props;
      if (value !== prevProps.value) {
        const mDay = moment(value, format);
        setMomentDate(mDay);
      }
    },
  }),

  withProps(({ error, errorText }) => ({
    errorText: error || errorText,
  })),

  mapProps(omit([
    'error',
    'setError',
    'onBlurDate',
    'onChangeDate',
    'emptyUnpossible',
    'setMomentDate',
    'isDisabled',
    'updateDate',
  ])),
);
