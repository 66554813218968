import { ReactNode } from 'react'

import styles from './report-header-bar-items.scss'


type Props = {
  children: ReactNode
}

const ReportHeaderBarItems = ({ children }: Props) => {
  return (
    <div className={styles.root}>{children}</div>
  )
}

export default ReportHeaderBarItems
