// @flow
import path from 'ramda/es/path';

/**
 *   Быстрый доступ к id первого объекта из набора объектов
 */
const getFirstId = (orgs: { [id: string]: { id: string } }) => {
  for (const key in orgs) { // eslint-disable-line
    const id: string | void = path([key, 'id'], orgs);
    return id;
  }
  return undefined;
};

export default getFirstId;
