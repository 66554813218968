// @flow
import React, { type Node, type Ref } from 'react';
import classNames from 'classnames/bind';

import Link from 'app/common/lib/link';
import { emptyObject } from 'app/common/lib/empty';

import styles from './plain-link.scss';


const cx = classNames.bind(styles);

export type PlainLinkProps = {
  children: Node,
  to: string,
  disabled?: boolean,
  className?: string,
  tabIndex?: string,
  ariaLabel?: string,
  external?: boolean,
  reference?: Ref<any>,
}

const PlainLink = ({
  children,
  to,
  disabled,
  className,
  tabIndex,
  ariaLabel,
  external,
  reference,
}: PlainLinkProps) => {
  const props = {
    'area-label': ariaLabel,
    className: cx(styles.root, { disabled }, className),
    ref: reference,
    ...(tabIndex ? { tabIndex } : emptyObject),
  };

  if (external) {
    return (
      <a href={to} {...props} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  if (disabled) {
    return (
      <a role="link" aria-disabled="true" {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default PlainLink;
