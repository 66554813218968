// @flow
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { sellPointsFetch } from 'app/actions/orgs/menu/sellPoints';


type Options = {
  orgId: string,
}

export default function useDataLoad({ orgId }: Options): boolean {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const dataLoad = useCallback(async () => {
    setLoading(true);
    const res = await dispatch(sellPointsFetch(orgId));
    if (res) {
      setLoading(false);
    }
  }, [orgId, dispatch]);

  useEffect(() => {
    dataLoad();
  }, [dataLoad]);

  return loading;
}
