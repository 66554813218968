// @flow
import React, { type Node, Fragment, useState, useCallback, useEffect } from 'react';
import { not } from 'ramda';
import { Scrollbars } from 'react-custom-scrollbars-2';

import {
  RowLayoutWithSticky,
  RowLayoutContent,
  Header,
  RowLayoutFullscreenContent,
  Space,
} from 'app/common/layouts/RowLayout';

import { PlanogramStorage, createPlanogramData } from 'app/common/ui-components/planograms';
import { GET_VENDING_ITEMS, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/get';
import { api } from 'app/api';
import { useVendingMachineSingle } from 'app/dataProviders/VendingDataProvider';
import { toFixedValueCurrency } from 'app/common/lib/toFixedValue';

import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import Loading from 'app/common/components/Loader';
import EmptyWarn from 'app/common/layouts/EmptyWarn';

import AddProduct from './AddProduct';
import usePlanogramData from '../_planogram/usePlanogramData';
import IncreaseProduct from './IncreaseProduct';
import DecreaseProduct from './DecreaseProduct';
import MassIncreaseProduct from './MassIncreaseProduct';
import MassDecreaseProduct from './MassDecreaseProduct';

import styles from './StorageSection.scss';


type Props = {
  header: string,
  loading?: boolean,
  machineId: string | null,
}

const StorageSection = ({
  header,
  loading: loadingExternal,
  machineId,
}: Props): Node => {
  const [{ placeId }] = useVendingMachineSingle();

  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = useCallback(() => {
    setEditMode(not);
  }, []);

  const {
    data,
    loading: loadingData,
    request: requestPlanogram,
    setPlanogramSlotData,
  } = usePlanogramData(machineId, createPlanogramData);

  useEffect(() => {
    requestPlanogram();
  }, [requestPlanogram]);

  const loading = loadingData || loadingExternal;

  const handleSlotData = useCallback(async (id: string) => {
    const res = await api.request(GET_VENDING_ITEMS, {
      error: 'Не удалось получить продукцию из ячейки',
      params: {
        ids: [id],
        placeId,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res && res.data && res.data[0]) {
      const { cost, ...others } = res.data[0];
      return {
        cost: toFixedValueCurrency(cost, false),
        ...others,
      };
    }
    return null;
  }, [placeId]);

  const [selection, setSelection] = useState(new Set());
  const handleSelect = useCallback((incoming: Array<{ slotId: string, selected: boolean }>) => {
    setSelection((current) => {
      const newSelection = new Set(current);
      incoming.forEach(({ slotId, selected }) => {
        if (selected) {
          newSelection.add(slotId);
          return;
        }
        newSelection.delete(slotId);
      });
      return newSelection;
    });
  }, []);


  return (
    <RowLayoutWithSticky
      number={3}
      content={
        <RowLayoutContent borderBottom>
          <Header>
            {header}
          </Header>
        </RowLayoutContent>
      }
    >
      <RowLayoutFullscreenContent>
        {toggleFullscreen => (
          <Fragment>
            <ControlsBar spaceBetween className={styles.controls}>
              <ControlsBar>
                <PlainButton
                  danger={editMode}
                  onClick={toggleEditMode}
                  disabled={loading}
                >
                  {editMode
                    ? 'Выйти из режима загрузки товара'
                    : 'Загрузить товар'}
                </PlainButton>
              </ControlsBar>
              <ControlsBar right>
                <PlainButtonAsLink
                  id="fullscreen"
                  ariaLabel="Открыть в полном окне"
                  onClick={toggleFullscreen}
                >
                  <PlainIcon
                    width={20}
                    height="auto"
                    type={iconTypes.FULLSCREEN}
                    colorType={colorTypes.COLOR_GRAY}
                  />
                </PlainButtonAsLink>
              </ControlsBar>
            </ControlsBar>

            {editMode
              && <ControlsBar className={styles.controls}>
                {machineId
                  && <MassIncreaseProduct
                    data={data}
                    placeId={placeId}
                    machineId={machineId}
                    onSuccess={setPlanogramSlotData}
                  >
                    {display => (
                      <PlainButton onClick={display} disabled={loading}>
                        {'Заполнить все ячейки до 100%'}
                      </PlainButton>
                    )}
                  </MassIncreaseProduct>}

                {machineId
                  && <MassDecreaseProduct
                    data={data}
                    placeId={placeId}
                    machineId={machineId}
                    onSuccess={setPlanogramSlotData}
                  >
                    {display => (
                      <PlainButton onClick={display} disabled={loading}>
                        {'Изъять весь товар'}
                      </PlainButton>
                    )}
                  </MassDecreaseProduct>}
              </ControlsBar>}

            <Space />

            <Scrollbars
              autoHeight
              autoHeightMin="100%"
              autoHeightMax="100%"
            >
              <DecreaseProduct
                machineId={machineId}
                onSuccess={setPlanogramSlotData}
              >
                {decProduct => (
                  <IncreaseProduct
                    machineId={machineId}
                    onSuccess={setPlanogramSlotData}
                  >
                    {incProduct => (
                      <AddProduct
                        machineId={machineId}
                        placeId={placeId}
                        onSuccess={setPlanogramSlotData}
                      >
                        {addProduct => (
                          <PlanogramStorage
                            data={data}
                            editMode={editMode}
                            selectionMode={editMode}
                            selectedSlots={selection}
                            onSlotsSelect={handleSelect}
                            onSlotVisible={handleSlotData}
                            onClickAddProduct={addProduct}
                            onProductIdChange={handleSlotData}
                            onClickIncreaseProduct={incProduct}
                            onClickDecreaseProduct={decProduct}
                          >
                            {loading
                              ? <Loading />
                              : <EmptyWarn skipLeftPadding>
                                {'Данные планограммы отсутствуют для данного аппарата'}
                              </EmptyWarn>}
                          </PlanogramStorage>
                        )}
                      </AddProduct>
                    )}
                  </IncreaseProduct>
                )}
              </DecreaseProduct>
            </Scrollbars>
          </Fragment>
        )}
      </RowLayoutFullscreenContent>
    </RowLayoutWithSticky>
  );
};

export default StorageSection;
