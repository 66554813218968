// @flow
import React from 'react';

import { dateFormat as dateFormatByActions } from 'app/actions/reports/filters/dateFromTo';
import { PlainHelperPeriodInfo } from 'app/common/ui-next/plain-helper-text';
import PlainInputDate from 'app/common/ui-next/plain-input-date';

import styles from '../FilterItems.scss';


type Props = {
  dateFrom: string,
  dateTo: string,
  disabled?: boolean,
  dateFormat?: string,
  handleChangeFrom: (a: string, b?: string) => void,
  handleChangeTo: (a: string, b?: string) => void,
  disabledDays: (dayFormatted: string, format: string, unit?: string) => boolean,
  reset: (e: SyntheticEvent<HTMLElement>) => void,
}

const DateFilter = ({
  dateFrom,
  dateTo,
  disabled,
  dateFormat = dateFormatByActions,
  handleChangeFrom,
  handleChangeTo,
  disabledDays,
  reset,
}: Props) => (
  <div className={styles.filter}>
    <div className={styles.label}>
      {'Период времени:'}
      <a href="reset" className={styles.resetLink} onClick={reset}>
        {'Сбросить выбор'}
      </a>
    </div>
    <div className={styles.itemsRow}>
      <div className={styles.itemsRowItem}>{'от'}</div>
      <PlainInputDate
        id="from"
        value={dateFrom}
        onDateChange={handleChangeFrom}
        dateFormat={dateFormat}
        dateDisabledStatus={disabledDays}
        className={styles.itemsRowItem}
        loading={disabled}
      />
      <div className={styles.itemsRowItem}>{'до'}</div>
      <PlainInputDate
        id="to"
        value={dateTo}
        onDateChange={handleChangeTo}
        dateFormat={dateFormat}
        dateDisabledStatus={disabledDays}
        className={styles.itemsRowItem}
        loading={disabled}
      />
    </div>
    <PlainHelperPeriodInfo
      dateFrom={dateFrom}
      dateTo={dateTo}
      dateFormat={dateFormat}
    />
  </div>
);

export default DateFilter;
