// @flow
import { useState, useCallback } from 'react';


const defaultState = new Set<number>();

export default function useCheckedOrgs(): [Set<number>, (number, boolean) => void, () => void] {
  const [checkedOrgs, setCheckedOrgs] = useState<Set<number>>(defaultState);

  const change = useCallback((orgId: number, checked: boolean) => {
    setCheckedOrgs((currentState) => {
      if (checked) {
        return new Set<number>(currentState).add(orgId);
      }
      currentState.delete(orgId);
      return new Set<number>(currentState);
    });
  }, []);

  const reset = useCallback(() => {
    setCheckedOrgs(defaultState);
  }, []);

  return [checkedOrgs, change, reset];
}
