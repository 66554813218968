
import { Schema } from 'app/dataTypes/subsidy/settings'

import { controls } from './formParts/SchemaForm'


export default function extractSchema(formData: FormData): Schema | null {
  if (formData.get(controls.hasSchema)) {
    return {
      common: [
        !!formData.get(`${controls.common}1`),
        !!formData.get(`${controls.common}2`),
      ],
      individual: [
        !!formData.get(`${controls.individual}1`),
        !!formData.get(`${controls.individual}2`),
      ],
      both: [
        !!formData.get(`${controls.both}1`),
        !!formData.get(`${controls.both}2`),
      ],
    }
  }

  return null
}
