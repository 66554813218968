// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const ADDIDENTIFIER_SUBMIT_REQUEST = 'ADDIDENTIFIER_SUBMIT_REQUEST';
export const ADDIDENTIFIER_SUBMIT_SUCCESS = 'ADDIDENTIFIER_SUBMIT_SUCCESS';
export const ADDIDENTIFIER_SUBMIT_FAILURE = 'ADDIDENTIFIER_SUBMIT_FAILURE';

const addIdentifierSubmitRequest = payload => ({
  type: ADDIDENTIFIER_SUBMIT_REQUEST,
  payload,
});

const addIdentifierSubmitSuccess = payload => ({
  type: ADDIDENTIFIER_SUBMIT_SUCCESS,
  payload,
});

const addIdentifierSubmitFailure = payload => ({
  type: ADDIDENTIFIER_SUBMIT_FAILURE,
  payload,
});

export const addIdentifierSubmit = (id: number | string, data: Object) => async (dispatch: Function) => {
  dispatch(addIdentifierSubmitRequest(data));
  try {
    const result = await api(`food/employee/${id}/card`, 'post', { data });
    dispatch(addIdentifierSubmitSuccess(get(result, ['data', 'cards'], {})));
    return true;
  } catch (error) {
    const errorText = get(error, ['response', 'data', 'message'], '');
    if (errorText === 'wrong card number format') { // 422
      dispatch(addIdentifierSubmitFailure({ err: 'Неверный формат номера идентификатора', error }));
      return false;
    }
    if (errorText === 'wrong card type') {
      dispatch(addIdentifierSubmitFailure({ err: 'Неверный тип идентификатора', error }));
      return false;
    }
    if (errorText === 'card number already used') {
      dispatch(addIdentifierSubmitFailure({ err: 'Номер карты уже занят', error }));
      return false;
    }
    if (errorText === 'card number not in school white list') {
      dispatch(addIdentifierSubmitFailure({ err: 'Номер карты не находится в списке разрешенных', error }));
      return false;
    }

    dispatch(addIdentifierSubmitFailure({ err: 'Не удалось добавить идентификатор', error }));
    return false;
  }
};
