// @flow
import React from 'react';
import classNames from 'classnames';

import bua from 'app/common/images/flag-bua.png';

import styles from './flags.scss';


type Props = {
  className?: string,
}

const FlagBua = ({ className }: Props) => (
  <div className={classNames(styles.root, className)}>
    <img src={bua} className={styles.ru} alt="flag_bua" />
  </div>
);

export default FlagBua;
