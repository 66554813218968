import { createSelector } from 'reselect';
import isEmpty from 'ramda/es/isEmpty';
import complement from 'ramda/es/complement';

import { selectOrgId } from '../../../FilterItems/hocs/commonSelectors';


const isNotEmpty = complement(isEmpty);

const selectIsEnableDetails = createSelector(
  state => state.reports.filters.orgsTypes.selected,
  selectOrgId,
  (orgsTypes, orgId) => isNotEmpty(orgsTypes) && orgId,
);

export default selectIsEnableDetails;
