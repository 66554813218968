import { ReactNode } from 'react'
import cn from 'classnames/bind'

import styles from './CopySettingsContainer.scss'


const cx = cn.bind(styles)

type Props = {
  children: ReactNode
  hasTopMargin?: boolean
  lowTopMargin?: boolean
  className?: string
}

const CopySettingsContainer = ({ children, hasTopMargin, lowTopMargin, className }: Props) => {
  return (
    <div className={cx(styles.root, { hasTopMargin, lowTopMargin }, className)}>
      {children}
    </div>
  )
}

export default CopySettingsContainer
