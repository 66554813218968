import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import path from 'ramda/es/path';


const selectDishes = createSelector(
  [
    state => state.orgs.dishes.selection.items,
    state => state.orgs.dishes.items,
  ],
  (selection, dishes) => ({
    dishes: selection.map(id => ({ id, title: path([id, 'title'], dishes) })),
  }),
);

export default connect(selectDishes);
