import moment from 'moment'

import { useCallback, useEffect } from 'react'
import { periodFilter } from 'app/dataProviders/filter-items'
import SimpleDate from 'app/common/classes/simple-date'

import { PlainDatePeriodBox } from 'app/common/ui-next/_filter-compilations/plain-date-period'


export const dateFormat = 'DD-MM-YYYY'

const defaultPeriod = ([from, to]: [SimpleDate | null, SimpleDate | null]) => {
  const currentDate = moment()

  // на случай периода прошлых учебных лет
  if (from && to && !currentDate.isBetween(from.createMoment(), to.createMoment(), 'day')) {
    return [from.getString(dateFormat), to.getString(dateFormat)]
  }

  const firstDayOfMonth = moment().startOf('month')

  const defaultDateFrom = firstDayOfMonth.format(dateFormat)
  const currentDateTo = currentDate.format(dateFormat)

  return [defaultDateFrom, currentDateTo]
}


type Props = {
  id: string
  pending: boolean
}

export const PeriodFilter = ({ id, pending }: Props) => {
  const changePeriodToDefault = useCallback(() => {
    return defaultPeriod([null, null])
  }, [])

  useEffect(() => {
    changePeriodToDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PlainDatePeriodBox
      id={id}
      label="Период времени"
      loading={pending}
      dateFormat={dateFormat}
      dataBoxData={periodFilter}
      getDefaultState={changePeriodToDefault}
    />
  )
}
