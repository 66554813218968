import {
  DISHES_ADD_TO_SELECTION,
  DISHES_REMOVE_FROM_SELECTION,

  DISHES_REMOVE_ITEMS_REQUEST,
  DISHES_REMOVE_ITEMS_SUCCESS,
  DISHES_REMOVE_ITEMS_FAILURE,

  DISHES_MOVE_ITEMS_REQUEST,
  DISHES_MOVE_ITEMS_SUCCESS,
  DISHES_MOVE_ITEMS_FAILURE,

  DISHES_CLEAR_SELECTION,
  DISHES_SELECT,

  // DISHES_COPY_ITEMS_REQUEST,
  // DISHES_COPY_ITEMS_SUCCESS,
  // DISHES_COPY_ITEMS_FAILURE,
} from 'app/actions/orgs/dishes/selection';

import {
  DISHES_REQUEST,
} from 'app/actions/orgs/dishes';

const defaultState = {
  items: [],
  action: '',
};

const selection = (state = defaultState, action) => {
  switch (action.type) {
    case DISHES_SELECT: {
      return { ...state, items: action.payload };
    }
    case DISHES_CLEAR_SELECTION: {
      return { ...state, items: [] };
    }
    case DISHES_ADD_TO_SELECTION: {
      return { ...state, items: [...state.items, action.payload] };
    }

    case DISHES_REMOVE_FROM_SELECTION: {
      return { ...state, items: state.items.filter(item => (item !== action.payload)) };
    }

    case DISHES_REMOVE_ITEMS_REQUEST: {
      return { ...state, action: 'delete' };
    }

    case DISHES_REMOVE_ITEMS_SUCCESS: {
      return { ...defaultState };
    }

    case DISHES_REMOVE_ITEMS_FAILURE: {
      return { ...state, action: '' };
    }

    case DISHES_MOVE_ITEMS_REQUEST: {
      return { ...state, action: 'move' };
    }

    case DISHES_MOVE_ITEMS_SUCCESS: {
      return { ...defaultState };
    }

    case DISHES_MOVE_ITEMS_FAILURE: {
      return { ...state, action: '' };
    }

    // case DISHES_COPY_ITEMS_REQUEST: {
    //   return { ...state, action: 'copy' };
    // }
    //
    // case DISHES_COPY_ITEMS_SUCCESS: {
    //   return { ...defaultState };
    // }
    //
    // case DISHES_COPY_ITEMS_FAILURE: {
    //   return { ...state, action: '' };
    // }

    case DISHES_REQUEST: {
      return { ...defaultState };
    }

    default:
  }
  return state;
};

export default selection;
