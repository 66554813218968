// @flow
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment-timezone';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// import pjson from '../../package.json';
import './imports';


export const availableLanguages = [
  'ru', // русский
  'ky', // киргизский
  'bua', // бурятский
  'kk', // казахский
];
export type Lang = 'ru' | 'ky' | 'bua' | 'kk';

const defaultLanguage = 'ru';

export const months = {
  ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
  ky: ['Үчтүн Айы', 'Бирдин Айы', 'Жалган Куран', 'Чын Куран', 'Бугу', 'Кулжа', 'Теке', 'Баш Оона', 'Аяк Оона', 'Тогуздун айы', 'Жетинин Айы', 'Бештин Айы'],
  kk: ['Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан'],
  bua: ['Нэгэ\u00A0һара', 'Хоёр\u00A0һара', 'Гурба\u00A0һара', 'Дүрбэ\u00A0һара', 'Таба\u00A0һара', 'Зургаа\u00A0һара', 'Долоо\u00A0һара', 'Найма\u00A0һара', 'Юһэ\u00A0һара', 'Арба\u00A0һара', 'Арбан\u00A0нэгэ\u00A0һара', 'Арбан\u00A0хоёр\u00A0һара'],
};

export const monthsInGenitiveCase = {
  ru: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
};

export const getMonthInGenitiveCase = (monthNumber: number): string => {
  return monthsInGenitiveCase.ru[monthNumber - 1];
};

const weekdaysShort = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
};

const weekdaysLong = {
  ru: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  ky: ['Жекшемби', 'Дүйшөмбү', 'Шейшемби', 'Шаршемби', 'Бейшемби', 'Жума', 'Ишемби'],
  kk: ['Жексенбі', 'Дүйсенбі', 'Сейсенбі', 'Сәрсенбі', 'Бейсенбі', 'Жұма', 'Сенбі'],
  bua: ['Гарагай 1', 'Гарагай 2', 'Гарагай 3', 'Гарагай 4', 'Гарагай 5', 'Гарагай 6', 'Гарагай 7'],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({

    whitelist: availableLanguages,
    preload: availableLanguages,
    // updateMissing: true,
    // initImmediate: false,
    fallbackLng: defaultLanguage,
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // initImmediate: false,
    ns: [
      'login',
      'app',
      'failure',
      'identifiers-next',
      'about',
      'profile',
      'ui',
      'sw',
    ],
    defaultNS: 'login',
    backend: {
      loadPath: '/locales/{{ns}}.{{lng}}.json',
      // queryStringParams: {
      //   v: Date.now(), // pjson.version, // версия из package.json заменена на текущее время
      // },
    },
  });

// Изменение некоторых параметров при смене языка по событию
const handleLanguageChange = (lng: Lang) => {
  const html = document.documentElement;
  if (html) {
    html.setAttribute('lang', lng);
  }

  const dateLocale = availableLanguages.includes(lng)
    ? lng
    : defaultLanguage;

  moment.locale(dateLocale, {
    months: months[dateLocale] || months.ru,
    // $FlowFixMe
    weekdaysShort: weekdaysShort[dateLocale] || weekdaysShort.ru,
    // $FlowFixMe
    weekdaysLong: weekdaysLong[dateLocale] || weekdaysLong.ru,
    longDateFormat: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
      L: 'DD.MM.YYYY',
      LL: 'MMMM Do YYYY',
      LLL: 'MMMM Do YYYY LT',
      LLLL: 'dddd, MMMM Do YYYY LT',
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 4, // Used to determine first week of the year.
    },
  });
};

// При детекте языка меняем язык moment
i18n.on('languageChanged', handleLanguageChange);

// функция для наружного использовния для переключения языка
// используется в ui-элементе переключателя языка
export const changeLanguage = async (lang: Lang) => {
  try {
    await i18n.changeLanguage(lang);
    // const html = document.documentElement;
    // html.setAttribute('lang', lang);
    handleLanguageChange(lang);
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

// важно -- влияет на время в идентификаторах
moment.tz.setDefault('Europe/Moscow');

export default i18n;
