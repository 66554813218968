// @flow
import * as React from 'react';
import { withContentRect } from 'react-measure';
// import loadImage from 'blueimp-load-image';
import { path, prop } from 'ramda';

import Modal from 'app/common/components/Modal';
import Button from 'app/common/ui/Button';
import Loader from 'app/common/components/Loader';
import postImage from 'app/common/actions/files/postImage';
import MovingRect from 'app/common/components/MovingRect';
import type {
  MeasureEntryType,
  MeasureBoundsType,
  CropType,
} from 'app/common/components/MovingRect/MovingRect';
import config from 'app/config';
import { api } from 'app/api';
import { GET_IMAGES_TMP, convertParams } from 'app/common/api/requests/images/tmp/get';

import styles from './ImagePrepare.scss';


export type ImageParamsType = {
  file: string,
  o?: number,
  w: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
};

type Props = {
  className?: string,
  children: (Function) => React.Node,
  onSubmit?: (ImageParamsType, string) => void,
  onLoadFile?: (string) => void,
  measureRef: Function,
  rectMinSizeWidth: number,
  rectMinSizeHeight: number,
  rectProportion: number,
  contentRect: {
    bounds: MeasureBoundsType,
    entry: MeasureEntryType,
  },
}

type State = {
  file: ?File | string,
  loading: boolean, // показ изображения
  uploading: boolean, // загрузка на сервер, как временный файл
  percentage: number, // процент загрузкки во временный файл
  saving: boolean, // процесс сохранения (в родителе)
  imageParams: ImageParamsType,
}

class ImagePrepare extends React.Component<Props, State> {
  state = {
    file: null,
    loading: false, // показ изображения
    uploading: false, // загрузка на сервер, как временный файл
    percentage: 0, // процент загрузкки во временный файл
    saving: false, // процесс сохранения (в родителе)
    imageParams: {
      file: '',
      o: 1,
      w: config.modalMinWidth,
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
    },
  };

  componentDidUpdate(prevProps, prevState) {
    const { file } = this.state;
    if (prevState.file && !file && this.fileSelector) {
      this.fileSelector.value = '';
    }
  }

  refInput = (ref) => {
    this.fileSelector = ref;
  };

  refModal = (ref) => {
    this.modal = ref;
  };

  refImage = async (ref) => {
    this.image = ref;
    const { measureRef } = this.props;
    const { file } = this.state;
    measureRef(ref);
    if (file) {
      this.setState({ loading: true });
      // show file (backend)
      this.setState({ uploading: true });
      await this.postImageToServer();
      this.setState({ loading: false });
      if (this.image) {
        const { imageParams } = this.state;

        const res = await api.request(GET_IMAGES_TMP, {
          error: 'Не удалось загрузить изображение',
          params: {
            fileName: imageParams.file,
          },
          convertions: {
            convertParams,
          },
          additionalAxiosOptions: {
            responseType: 'arraybuffer',
          },
        });
        if (!res) return;
        const format = path(['headers', 'content-type'], res);
        const file = prop('data', res);
        const blob = new Blob([file], { type: format });
        const urlCreator = window.URL || window.webkitURL;
        this.image.src = urlCreator.createObjectURL(blob);

        this.image.onload = () => {
          this.setState({ uploading: false });
        };
      }

      // show file (loadImage)
      // loadImage(
      //   this.state.file,
      //   async (img, data) => {
      //     if (!img) return;
      //     if (data && data.exif && data.exif.get) {
      //       this.setState(state => ({ imageParams: { ...state.imageParams, o: data.exif.get('Orientation') } }));
      //     }
      //     img.setAttribute('class', styles.img);
      //     ref.appendChild(img);
      //     this.setState({ loading: false, uploading: true });
      //     await this.postImageToServer();
      //     this.setState({ uploading: false });
      //   }, {
      //     minWidth: config.modalMinWidth,
      //     minHeight: 300,
      //     maxHeight: config.dishPreviewHeight,
      //     orientation: true,
      //     meta: true,
      //     canvas: true,
      //     // cover: true,
      //     crop: true,
      //     // contain: true,
      //   }
      // );

      // show file (js)
      // const reader = new FileReader();
      // reader.onload = async (e) => {
      //   this.image.src = e.target.result;
      //   this.setState({ loading: false, uploading: true });
      //   await this.postImageToServer();
      //   this.setState({ uploading: false });
      // };
      // this.setState({ loading: true });
      // reader.readAsDataURL(this.state.file);
    }
  };

  postImageToServer = async () => {
    const { onLoadFile } = this.props;
    const { file } = this.state;
    if (!file) return;
    const data = new FormData();
    data.set('file', file);
    const postedFile = await postImage({
      data,
      onUploadProgress: this.hanldeUploadProgress,
    });
    this.setState(state => ({ imageParams: { ...state.imageParams, file: postedFile } }));
    if (onLoadFile) {
      onLoadFile(postedFile);
    }
  };

  hanldeUploadProgress = (e) => {
    const percentage = Math.round((e.loaded * 100) / e.total);
    this.setState({ percentage });
  };

  handleFileOpen = (e) => {
    e.preventDefault();
    if (this.fileSelector) {
      this.fileSelector.click();
    }
  };

  handleAddFile = () => {
    if (!this.fileSelector || !this.fileSelector.files || !this.fileSelector.files.length) return;
    this.setState({ file: this.fileSelector.files[0] });
  };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ file: null, percentage: 0 });
  };

  handleMakeCrop = (crop: CropType) => {
    this.setState(state => ({ imageParams: { ...state.imageParams, ...crop } }));
  };

  handleSubmitImage = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { onSubmit } = this.props;
    const { imageParams, file } = this.state;
    if (onSubmit) {
      this.setState({ saving: true });
      const { o, ...saveParams } = imageParams;
      const fileName: string = file && file instanceof File ? file.name : file || '';
      const res = await onSubmit({ ...saveParams }, fileName);
      if (res) this.setState({ file: null, percentage: 0 });
      this.setState({ saving: false });
    }
  };

  fileSelector: React.ElementRef<'input'> | null; // eslint-disable-line

  modal: React.ElementRef<'div'> | null;

  image: React.ElementRef<'img'> | null;

  render() {
    const {
      className,
      children,
      contentRect: {
        entry: { top, left, right, bottom, height, width },
        bounds,
      },
      rectMinSizeWidth,
      rectMinSizeHeight,
      rectProportion,
    } = this.props;
    const imageRect = this.image
      ? { top, left, right, bottom, height, width }
      : {};
    const { loading, saving, uploading, percentage, file } = this.state;
    return (
      <div className={className}>
        {children(this.handleFileOpen)}
        <input
          type="file"
          ref={this.refInput}
          accept="image/png, image/jpg, image/jpeg, image/gif, image/tiff"
          style={{ display: 'none' }}
          onChange={this.handleAddFile}
        />
        {file
          && <Modal className={styles.modal}>
            <div className={styles.content} ref={this.refModal}>
              <div className={styles.container}>
                {/* js version */}
                <img ref={this.refImage} className={styles.img} alt="" />
                {/* loadImage version */}
                {/* <div ref={this.refImage} /> */}
                <MovingRect
                  entry={imageRect}
                  bounds={bounds}
                  onCrop={this.handleMakeCrop}
                  proportion={rectProportion}
                  minSizeWidth={rectMinSizeWidth}
                  minSizeHeight={rectMinSizeHeight}
                  isHidden={uploading}
                />
              </div>
            </div>
            {loading && <Loader className={styles.loader} />}
            <div className={styles.uploadProgress} style={{ width: `${percentage}%` }} />
            <form onSubmit={this.handleSubmitImage} className={styles.form}>
              <Button
                value="Отмена"
                className={styles.cancel}
                onClick={this.handleClose}
                outline
              />
              <Button
                value={saving ? 'Сохраняется...' : 'Применить'}
                submit
                disabled={loading || saving || uploading}
              />
            </form>
          </Modal>}
      </div>
    );
  }
}

export default withContentRect(['bounds'])(ImagePrepare);
