// @flow
import * as React from 'react';

type Props = {
  onMouseUpOutside: (e: SyntheticMouseEvent<HTMLElement>) => void,
  children: any,
}

export default class MouseUpOutside extends React.Component<Props> {
  componentDidMount() {
    if (window) {
      window.addEventListener('mouseup', this.handle, true);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('mouseup', this.handle, true);
    }
  }

  refC = (ref: React.ElementRef<'span'> | null) => {
    this.container = ref;
  };

  handle = (e: SyntheticMouseEvent<HTMLElement>) => {
    if (!(e.target instanceof window.HTMLInputElement)) {
      return;
    }
    const { onMouseUpOutside } = this.props;
    const el = this.container;
    if (el && !el.contains(e.target)) {
      onMouseUpOutside(e);
    }
  };

  container: React.ElementRef<'span'> | null;

  render() {
    const { children, onMouseUpOutside, ...props } = this.props;
    return <span {...props} ref={this.refC}>{children}</span>;
  }
}
