import { useCallback, useEffect, useState } from 'react'

import { CompensationInfoByOrgID, CompensationInfoStatus } from 'app/dataTypes/compensation/info'
import { cancelAvailable, recordAvailable } from 'app/dataProviders/compensation/status'


const createSetByStatus = (
  data: CompensationInfoByOrgID,
  statusAvailable: (status: CompensationInfoStatus) => boolean,
) => {
  const array = Array
    .from(data, ([, { orgID, status }]) => statusAvailable(status) && orgID)
    .filter((v: any): v is string => typeof v === 'string')

  return new Set(array)
}

export function useSelection(data: CompensationInfoByOrgID) {
  const [selectionMode, setSelectionMode] = useState<null | 'record' | 'cancel'>(null)

  const [selection, setSelection] = useState<Set<string>>(new Set())
  const [allSelectedNumber, setAllSelectedNumber] = useState(0)

  const changeSelection = useCallback((orgID: string, checked: boolean) => {
    setSelection((prevState) => {
      const nextState = new Set(prevState)
      if (checked) {
        nextState.add(orgID)
      } else {
        nextState.delete(orgID)
      }
      return nextState
    })
  }, [])

  const reset = useCallback(() => {
    setSelectionMode(null)
  }, [])

  const selectAll = useCallback((mode: 'record' | 'cancel') => {
    const selectionAll = createSetByStatus(
      data,
      mode === 'record' ? recordAvailable : cancelAvailable,
    )
    setSelection(selectionAll)
    setAllSelectedNumber(selectionAll.size)
  }, [data])

  const selectNothing = useCallback(() => {
    setSelection(new Set())
    setAllSelectedNumber(0)
  }, [])

  const changeSelectionAll = useCallback((selectedAll: boolean) => {
    if (selectedAll && selectionMode) {
      selectAll(selectionMode)
      return
    }
    selectNothing()
  }, [selectionMode, selectAll, selectNothing])

  useEffect(() => {
    if (!selectionMode) {
      selectNothing()
      return
    }
    selectAll(selectionMode)
  }, [selectionMode]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    selection,
    selectionMode,
    setSelectionMode,
    changeSelection,
    reset,
    selectedAll: !!allSelectedNumber && allSelectedNumber === selection.size,
    changeSelectionAll,
  }
}
