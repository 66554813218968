// @flow
import React, { useCallback, type Node, useMemo } from 'react';
import { update, slice, reduce, values } from 'ramda';

import { PlainRadioGroup } from 'app/common/ui-next/plain-radio-button';

import type { StateDataItem } from '../filter-state';
import LabelWithInputs, { placeholdersNumber } from './label-with-inputs';


export type RadioTextProps = {
  id: string,
  label: string,
  className?: string,
  items: { [key: string]: StateDataItem },
  current: Array<string>,
  loading?: boolean,
  skipUpperSpace?: boolean,
  afterContent?: Node,
  inputClassName?: string,
  onUpdate: (id: string, values: Array<string>) => void,
  onInputsUpdateDataPrepare?: (index: number, value: string, current: Array<string>) => Array<string>,
  onInputsUpdateAfterBlurDataPrepare?: (index: number, value: string, current: Array<string>) => Array<string>,
}

const RadioText = ({
  id,
  label,
  className,
  items,
  current,
  loading,
  skipUpperSpace,
  afterContent,
  inputClassName,
  onUpdate,
  onInputsUpdateDataPrepare = update,
  onInputsUpdateAfterBlurDataPrepare = update,
}: RadioTextProps): Node => {
  const handleChange = useCallback((itemId: string) => {
    const [, ...inputsVal] = current;
    onUpdate(id, [itemId, ...inputsVal]);
  }, [onUpdate, id, current]);

  const inputIndexesByItemId = useMemo(() => {
    return reduce((acc, { id: itemId, title }) => {
      acc[itemId] = acc.count;
      if (typeof title === 'string') {
        acc.count += placeholdersNumber(title);
      }
      return acc;
    }, { count: 1 }, values(items));
  }, [items]);

  const mapItemPropsBeforeRender = useCallback((itemProps) => {
    const { id: itemId, title: itemTitle, disabled } = itemProps;
    const slots = typeof itemTitle === 'string' ? placeholdersNumber(itemTitle) : 0;

    if (slots) {
      const startIndexInValues = inputIndexesByItemId[itemId];
      const endIndexInValues = startIndexInValues + slots;

      const handleChangeInputs = (e: SyntheticEvent<HTMLInputElement>) => {
        const { id: elemId, value } = e.currentTarget;
        const index = parseInt(elemId.replace(/^\D+/g, ''), 10);
        if (!Number.isNaN(index)) {
          const newCurrent = onInputsUpdateDataPrepare(startIndexInValues + index, value, current);
          onUpdate(id, newCurrent);
        }
      };

      const handleBlurInputs = (e: SyntheticEvent<HTMLInputElement>) => {
        const { id: elemId, value } = e.currentTarget;
        const index = parseInt(elemId.replace(/^\D+/g, ''), 10);
        if (!Number.isNaN(index)) {
          const newCurrent = onInputsUpdateAfterBlurDataPrepare(startIndexInValues + index, value, current);
          onUpdate(id, newCurrent);
        }
      };

      const currentValues = slice(startIndexInValues, endIndexInValues, current);
      const [currentItemId] = current;

      return {
        ...itemProps,
        title: (
          <LabelWithInputs
            id={itemId}
            inputClassName={inputClassName}
            label={typeof itemTitle === 'string' ? itemTitle : ''} // flow
            values={currentValues}
            onChange={handleChangeInputs}
            onBlur={handleBlurInputs}
            disabled={disabled || currentItemId !== itemId}
          />
        ),
      };
    }
    return itemProps;
  }, [
    id,
    onUpdate,
    current,
    inputIndexesByItemId,
    inputClassName,
    onInputsUpdateDataPrepare,
    onInputsUpdateAfterBlurDataPrepare,
  ]);

  const [currentItemId] = current;

  return (
    <PlainRadioGroup
      id={id}
      label={label}
      items={items}
      current={currentItemId}
      disabled={loading}
      onChange={handleChange}
      className={className}
      skipUpperSpace={skipUpperSpace}
      mapItemPropsBeforeRender={mapItemPropsBeforeRender}
      afterContent={afterContent}
    />
  );
};

export default RadioText;
