// @flow
import * as React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import Link from 'app/common/lib/link';
import IconCross from 'app/common/icons/IconCross';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import config from 'app/config';

import styles from './Modal.scss';


ReactModal.setAppElement('#root');
export const getRoot = () => document.querySelector('#modal');

export type ModalProps = {
  children: any,
  close?: string | ((e: SyntheticEvent<HTMLElement>) => void), // deprecated
  toggleModal?: (e: SyntheticEvent<HTMLElement>) => void,
  className?: string,
  overlayClassName?: string,
  modalClassName?: string,
  isOpen?: boolean,
}

const Modal = ({
  close,
  toggleModal,
  children,
  className,
  overlayClassName,
  modalClassName,
  isOpen = true,
}: ModalProps) => (
  <ReactModal
    isOpen={isOpen}
    parentSelector={getRoot}
    className={classNames(styles.modal, modalClassName)}
    overlayClassName={classNames(styles.root, overlayClassName)}
    aria={{ labelledby: 'heading' }}
    role="dialog"
  >
    <div className={className} style={{ minWidth: config.modalMinWidth || 400 }}>
      {close
        && <Link
          to={typeof close === 'string' ? close : 'close'}
          onClick={typeof close === 'function' ? close : toggleModal}
          className={styles.close}
        >
          <IconCross className={styles.icon} />
        </Link>}
      {toggleModal
        && <PlainButtonAsLink
          id="generate_button"
          ariaLabel="Закрыть окно"
          onClick={toggleModal}
          className={styles.close}
        >
          <IconCross className={styles.icon} />
        </PlainButtonAsLink>}
      {children}
    </div>
  </ReactModal>
);

export default Modal;
