import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import path from 'ramda/es/path';


export default compose(
  connect(
    (state, props) => ({
      checked: path(['settings', props.settingsKey, props.name], state) || false,
    }),
  ),

  withHandlers({
    handleChange: ({ saveSettings }) => (e) => {
      e.preventDefault();
      const { name, checked } = e.target;
      saveSettings({ [name]: checked });
    },
  }),

  mapProps(({ saveSettings, dispatch, settingsKey, ...props }) => props),
);
