// @flow
import type { Request } from '../index';


export type ApiParams = {
  school_id: number,
  klass_id?: number,
  status?: string,
}

type Student = {
  id: number,
  name: {
    first: string,
    last: string,
    middle: string,
  },
}

type Result = {
  [id: string]: Student,
}

export const GET_STUDENTS_LIST: Request<
  ApiParams,
  Result,
  void
> = [
  'reports/students_list',
  'get',
  ['data', 'students'],
];
