import { useMemo } from 'react'
import { reduce } from 'ramda'

import toFixedValue from 'app/common/lib/toFixedValue'
import { SummaryItem } from 'app/common/templates-next/report-template/summary'
import { SummaryRow } from 'app/components/common/reports/summary'
import { CompensationInfoByOrgID, CompensationInfoStatus } from 'app/dataTypes/compensation/info'


type Props = {
  data: CompensationInfoByOrgID
  currency: string
  loading: boolean
}

export const Summary = ({ data, currency, loading }: Props) => {
  const sumInfo = useMemo(() => {
    const sum = reduce((acc, val) => {
      if (val.status === CompensationInfoStatus.Ready) {
        acc += val.totalSum // eslint-disable-line no-param-reassign
      }
      return acc
    }, 0, Array.from(data.values()))

    return `${toFixedValue(sum)} ${currency}`
  }, [data, currency])

  return (
    <SummaryRow baseline={false}>
      <SummaryItem
        key="CompensationInfoSummary"
        title="Общая сумма компенсаций"
        value={sumInfo}
        pending={loading}
      />
    </SummaryRow>
  )
}
