import lifecycle from 'recompose/lifecycle';
import pipe from 'ramda/es/pipe';
import split from 'ramda/es/split';
import drop from 'ramda/es/drop';

import { getIdByPath } from 'app/common/constants/logMatches';
import { saveNavigationLog } from 'app/common/actions/log';


/**
 * Запись логов перехода по разделам
 * Лог ведется на сервере
 * Для конкретного приложения нужно настраивать logMatches
 */

const getPathnameArr = pipe(
  split('/'),
  drop(1),
);

// сделано для 4го роутера
// для другой версии надо добавить или проверить, чтоб работало
export default lifecycle({
  componentDidMount() {
    const { dispatch, location: { pathname } } = this.props;
    const logId = getIdByPath(getPathnameArr(pathname));
    dispatch(saveNavigationLog(logId));
  },
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      const { pathname } = location;
      const { pathname: prevPathname } = prevProps.location;
      const logId = getIdByPath(getPathnameArr(pathname));
      const prevLogId = getIdByPath(getPathnameArr(prevPathname));
      if (logId !== prevLogId) {
        const { dispatch } = this.props;
        dispatch(saveNavigationLog(logId));
      }
    }
  },
});
