// @flow
import React, { type Ref } from 'react';

import { Tr, Th } from 'app/common/ui-components/table';

import { Entry, CellRestriction, restrictions } from '../MultiLineCell';
import type { KlassesEmployeesDisplayableItem } from './useActualData';
import { titleColumnId, getRowId } from './columnsAndRowsIds';

import styles from './LeftColumnBody.scss';


type Props = {
  reference: Ref<'tbody'>,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  data: Array<KlassesEmployeesDisplayableItem>,
  sameRowClassName: string,
}

const LeftColumnBody = ({
  reference,
  onMouseEnter,
  onMouseLeave,
  data,
  sameRowClassName,
}: Props) => {
  return (
    <tbody ref={reference}>
      {data.map(({ id, title }, i) => (
        <Tr
          key={id}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={`${sameRowClassName}${i}`}
        >
          <Th
            stripPadding
            scope="row"
            alignLeft
            headers={titleColumnId}
            id={getRowId(i)}
            className={styles.cell}
          >
            <CellRestriction type={restrictions.COMMON_PADDING}>
              <Entry alignLeft skipPadding>
                {title}
              </Entry>
            </CellRestriction>
          </Th>
        </Tr>
      ))}
    </tbody>
  );
};

export default LeftColumnBody;
