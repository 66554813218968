// @flow

const MASK_PHONE_RU: 'mask_phone_ru' = 'mask_phone_ru';
const MASK_PHONE_KG: 'mask_phone_kg' = 'mask_phone_kg';
const MASK_PHONE_KZ: 'mask_phone_kz' = 'mask_phone_kz';
const MASK_PHONE_UA: 'mask_phone_ua' = 'mask_phone_ua';

const MASK_SNILS: 'mask_snils' = 'mask_snils';
const MASK_DATE: 'mask_date' = 'mask_date';
const MASK_DAY_MONTH: 'mask_day_month' = 'mask_day_month';
const MASK_TIME: 'mask_time' = 'mask_time';

export const maskTypes = {
  MASK_PHONE_RU,
  MASK_PHONE_KG,
  MASK_PHONE_KZ,
  MASK_PHONE_UA,
  MASK_SNILS,
  MASK_DATE,
  MASK_DAY_MONTH,
  MASK_TIME,
};
