import {
  CATEGORIES_ADD_REQUEST,
  CATEGORIES_ADD_SUCCESS,
  CATEGORIES_ADD_FAILURE,
  CATEGORIES_ADD_CLEAR_ERROR,
} from 'app/actions/categories/add';


const defaultState = {
  error: '',
  loading: false,
};

const selection = (state = defaultState, action) => {
  switch (action.type) {
    case CATEGORIES_ADD_REQUEST: {
      return { ...state, loading: true };
    }

    case CATEGORIES_ADD_SUCCESS: {
      return { ...defaultState };
    }

    case CATEGORIES_ADD_FAILURE: {
      return { loading: false, error: action.payload.err };
    }

    case CATEGORIES_ADD_CLEAR_ERROR: {
      return { ...state, error: '' };
    }

    default:
  }
  return state;
};

export default selection;
