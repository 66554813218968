// @flow
import classNames from 'classnames/bind';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';

import styles from './ListItem.scss';


const cx = classNames.bind(styles);

type Props = {
  id: number | string,
  value: string,
  isCurrent: boolean,
  onClick: (value: number | string) => void,
  handleClick?: (e: SyntheticEvent<HTMLElement>) => void,
};

const ListItem = compose(
  withHandlers({
    handleClick: ({ id, onClick }: Props) => () => {
      onClick(id);
    },
  }),
  mapProps(({ value, isCurrent, handleClick }: Props) => ({
    onClick: handleClick,
    className: cx({ item: true, isCurrent }),
    children: value,
  })),
)('div');

export default ListItem;
