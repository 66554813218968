// @flow
import React, { Fragment, useState, useCallback, useMemo } from 'react';
import filter from 'ramda/es/filter';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';

import PlainActionsButtons from 'app/common/ui-next/plain-actions-button';
import PlainButton from 'app/common/ui-next/plain-button';
import Tooltip from 'app/common/ui/Tooltip';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'app/common/components/Modal';
import ControlsBar from 'app/common/ui-next/controls-bar';
import { useSelectionData } from 'app/components/common/SelectionData';

import { useDishesData, type FoodItem } from './DishesData';

/**
 *  Кнопка с выпадающим меню с действиями над выделенным
 */

const DishesActionsButton = () => {
  const [actionInProgress, setActionInProgress] = useState<'copy' | 'delete' | null>(null);

  const [{ selection }] = useSelectionData();
  const [
    { dishes, nomenclatureActive },
    { delete: deleteDishes, copy: copyDishes },
  ] = useDishesData();

  const [itemsSelected, selectedNumber]: [Array<FoodItem>, number] = useMemo(() => {
    const selected = filter(({ id }) => selection[String(id)], dishes);
    return [selected, selected.length];
  }, [dishes, selection]);

  // modals
  const [showDeleteModal, setDeleteModalVisible] = useState(false);
  const toggleDeleteModal = useCallback(() => setDeleteModalVisible(state => !state), []);
  const [showCopyModal, setCopyModalVisible] = useState(false);
  const toggleCopyModal = useCallback(() => setCopyModalVisible(state => !state), []);

  // delete items
  const handleDishesRemoveItems = useCallback(async (e) => {
    e.preventDefault();
    setActionInProgress('delete');
    const res = await deleteDishes(map(prop('id'), itemsSelected));
    setActionInProgress(null);

    if (res) {
      setDeleteModalVisible(false);
    }
  }, [itemsSelected, deleteDishes]);

  // copy items
  const handleDishesCopyItems = useCallback(async (e) => {
    e.preventDefault();
    setActionInProgress('copy');
    const res = await copyDishes(map(prop('id'), itemsSelected));
    setActionInProgress(null);

    if (res) {
      setCopyModalVisible(false);
    }
  }, [itemsSelected, copyDishes]);


  return (
    <Fragment>
      <Tooltip
        text={!selectedNumber ? 'Необходимо выбрать хотя бы одну продукцию' : null}
        enableOverflow
      >
        <PlainActionsButtons
          collapsed
          value="Изменить"
          number={selectedNumber}
          valueMask="Изменить (%n%)"
          disabled={!selectedNumber}
        >
          {!nomenclatureActive // нельзя копировать, если используется номенклатура
            && (
              <PlainButton
                id="copy"
                onClick={toggleCopyModal}
                disabled={!selectedNumber || !!actionInProgress}
              >
                {selectedNumber > 0
                  ? `Копировать (${selectedNumber})`
                  : 'Копировать'}
              </PlainButton>
            )}

          <PlainButton
            id="delete"
            onClick={toggleDeleteModal}
            disabled={!selectedNumber || !!actionInProgress}
          >
            {selectedNumber > 0
              ? `Удалить (${selectedNumber})`
              : 'Удалить'}
          </PlainButton>
        </PlainActionsButtons>
      </Tooltip>

      {showDeleteModal
        && (
          <Modal toggleModal={toggleDeleteModal}>
            <form onSubmit={handleDishesRemoveItems}>
              <ModalHeader>{'Удаление продукции'}</ModalHeader>
              <ModalContent maxHeight={300}>
                <ul>
                  {itemsSelected.map(({ id, title }) => <li key={id}>{title}</li>)}
                </ul>
              </ModalContent>
              <ModalFooter>
                <ControlsBar middle>
                  <PlainButton
                    type="submit"
                    disabled={!selectedNumber || !!actionInProgress}
                    danger
                  >
                    {actionInProgress === 'delete' ? 'Удаляется...' : 'Удалить'}
                  </PlainButton>
                  <PlainButton outline onClick={toggleDeleteModal}>{'Отмена'}</PlainButton>
                </ControlsBar>
              </ModalFooter>
            </form>
          </Modal>
        )}

      {showCopyModal
        && (
          <Modal toggleModal={toggleCopyModal}>
            <form onSubmit={handleDishesCopyItems}>
              <ModalHeader>{'Копирование продукции'}</ModalHeader>
              <ModalContent maxHeight={300}>
                <ul>
                  {itemsSelected.map(({ id, title }) => <li key={id}>{title}</li>)}
                </ul>
              </ModalContent>
              <ModalFooter>
                <ControlsBar middle>
                  <PlainButton
                    type="submit"
                    disabled={!selectedNumber || !!actionInProgress}
                  >
                    {actionInProgress === 'copy' ? 'Копируется...' : 'Копировать'}
                  </PlainButton>
                  <PlainButton outline onClick={toggleCopyModal}>{'Отмена'}</PlainButton>
                </ControlsBar>
              </ModalFooter>
            </form>
          </Modal>
        )}
    </Fragment>
  );
};

export default DishesActionsButton;
