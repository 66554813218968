import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { has } from 'ramda'

import PlainCheckbox, { LevelShift } from 'app/common/ui-next/plain-checkbox'
import PlainFieldset from 'app/common/ui-next/plain-fieldset'
import { useGeneralSettings } from 'app/dataProviders/generalSettings/hooks'

import styles from './AdditionalSettingsForm.scss'


type CurrentValues = {
  buffetZone: boolean
  hotMealsZone: boolean
  denyParentMoneySales: boolean
  denyMoreThanOneSales: boolean
  useAsAdditional: boolean
}

const defaultCurrent: CurrentValues = {
  buffetZone: true,
  hotMealsZone: true,
  denyParentMoneySales: false,
  denyMoreThanOneSales: false,
  useAsAdditional: false,
}

type Props = {
  buffetZone: boolean
  hotMealsZone: boolean
  denyParentMoneySales: boolean
  denyMoreThanOneSales: boolean
  useAsAdditional: boolean
  disabled?: boolean
}

const AdditionalSettingsForm = ({
  buffetZone,
  hotMealsZone,
  denyParentMoneySales,
  denyMoreThanOneSales,
  useAsAdditional,
  disabled,
}: Props) => {
  const [currentValues, setCurrentValues] = useState({
    buffetZone,
    hotMealsZone,
    denyParentMoneySales,
    denyMoreThanOneSales,
    useAsAdditional,
  })

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.currentTarget
    if (has(name, defaultCurrent)) {
      setCurrentValues(prevState => ({
        ...prevState,
        [name]: checked,
      }))
    }
  }, [])

  const {
    generalSettings: { useCommonOrderComplexChoose },
    loading: loadingGeneralSettings,
  } = useGeneralSettings()

  useEffect(() => {
    setCurrentValues({
      buffetZone,
      hotMealsZone,
      denyParentMoneySales,
      denyMoreThanOneSales,
      useAsAdditional,
    })
  }, [
    buffetZone,
    hotMealsZone,
    denyParentMoneySales,
    denyMoreThanOneSales,
    useAsAdditional,
  ])

  return (
    <div className={styles.root}>
      <PlainFieldset
        className={styles.sellPlaceGroup}
        legend="Место продажи"
      >
        <LevelShift>
          <PlainCheckbox
            label="зона буфета"
            name="buffetZone"
            value="active"
            checked={currentValues.buffetZone}
            onChange={handleChange}
            disabled={disabled}
          />
          <PlainCheckbox
            label="зона горячего питания"
            name="hotMealsZone"
            value="active"
            checked={currentValues.hotMealsZone}
            onChange={handleChange}
            disabled={disabled}
          />
        </LevelShift>
      </PlainFieldset>

      <PlainCheckbox
        label="Не продавать за родительские средства"
        name="denyParentMoneySales"
        value="active"
        checked={currentValues.denyParentMoneySales}
        onChange={handleChange}
        disabled={disabled}
      />
      <PlainCheckbox
        label="Не продавать более 1 шт."
        name="denyMoreThanOneSales"
        value="active"
        checked={currentValues.denyMoreThanOneSales}
        onChange={handleChange}
        disabled={disabled}
      />

      {useCommonOrderComplexChoose
        && !loadingGeneralSettings
        && <PlainCheckbox
          label="Использовать как дополнительный комплект при создании справок с выбором «один из множества»"
          name="useAsAdditional"
          value="active"
          checked={currentValues.useAsAdditional}
          onChange={handleChange}
          disabled={disabled}
        />}
    </div>
  )
}

export default AdditionalSettingsForm
