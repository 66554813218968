import mapProps from 'recompose/mapProps';

export default mapProps((props) => {
  if (!props.pickByFilters) return props;
  const { items, pickByFilters, ...others } = props;
  return ({
    items: items.filter(({ filters }) => !(filters && !filters.includes(pickByFilters))),
    ...others,
  });
});
