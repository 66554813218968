// @flow
import { api } from 'app/api';
import { GET_VENDING_MACHINES_VERSIONS, convertResult } from 'app/common/api/requests/data/vending/machines/versions/get';
import type { MachinesVersion } from 'app/dataTypes/vending/MachinesVersion.types';


const requestVersions = async (): Promise<Array<MachinesVersion> | null> => {
  const res = await api.request(GET_VENDING_MACHINES_VERSIONS, {
    error: 'Не удалось получить версии моделей аппаратов',
    convertions: {
      convertResult,
    },
  });

  return res;
};

export default requestVersions;
