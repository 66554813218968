// @flow
import { useMemo } from 'react';
import { prop, pipe, map } from 'ramda';

import { tableColumnSort } from 'app/common/lib/sort';
import { getLastFirstM } from 'app/common/lib/names';

import type { Purchase } from '../types';

export type PurchaseItem = {
  ...Purchase,
  fio: string,
}

export default function useSortedSales(
  purchases: Array<Purchase>,
  sortKey: string,
  sortDirection: 'up' | 'down',
): Array<PurchaseItem> {
  const sorted = useMemo(() => pipe(
    map((purchaseItem) => {
      const { customer_name: name } = purchaseItem;
      return ({
        ...purchaseItem,
        fio: getLastFirstM(name),
      });
    }),
    tableColumnSort(sortDirection, prop(sortKey)),
  )(purchases), [sortDirection, sortKey, purchases]);

  return sorted;
}
