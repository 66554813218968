// @flow
import React, { type Node, useCallback } from 'react';
import cn from 'classnames';

import PlainButton from 'app/common/ui-next/plain-button';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import ControlsBar from 'app/common/ui-next/controls-bar';
import { Header } from 'app/common/layouts/RowLayout';

import styles from './FiltersPlainLayout.scss';


type Props = {
  children: Node,
  loading?: boolean,
  disabled?: boolean,
  skipHeader?: boolean,
  submitTitle?: string,
  resetButton?: boolean,
  footerClassName?: string,
  extraContent?: Node,
  onResetAll?: () => void,
  onApplyFilter: (e: SyntheticEvent<HTMLElement>) => void,
}

const FilterPlainLayout = ({
  children,
  loading,
  disabled,
  skipHeader,
  submitTitle = 'Сформировать',
  resetButton,
  footerClassName,
  extraContent,
  onResetAll,
  onApplyFilter,
}: Props): Node => {
  const handleSubmitFilter = useCallback((e) => {
    e.preventDefault();
    if (e.target.id !== 'filter') return;
    onApplyFilter(e);
  }, [onApplyFilter]);


  return (
    <form onSubmit={handleSubmitFilter} id="filter">
      {!skipHeader
        && <Header className={styles.header}>
          <span>{'Фильтры'}</span>
          {onResetAll
            && <PlainButtonAsLink linkClassName={styles.resetLink} onClick={onResetAll}>
              {'Сбросить все фильтры'}
            </PlainButtonAsLink>}
        </Header>}
      <div className={styles.content}>
        {children}
      </div>
      <ControlsBar className={cn(styles.buttons, footerClassName)} right>
        {extraContent}
        {resetButton
          && <PlainButton onClick={onResetAll} outline>
            {'Сбросить все фильтры'}
          </PlainButton>}
        <PlainButton type="submit" disabled={disabled || loading}>
          {submitTitle}
        </PlainButton>
      </ControlsBar>
    </form>
  );
};

export default FilterPlainLayout;
