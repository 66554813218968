// @flow
import React, { type Node, Children, cloneElement } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import pick from 'ramda/es/pick';

import {
  Droppable,
  Draggable,
  type DraggableId,
  type DraggableChildrenFn,
} from 'react-beautiful-dnd';

import styles from './plain-draggable-list.scss';

/**
 * Список draggable елементов.
 * Для корректной работы нужно оборачивать в PlainDraggableListProvider (index.js).
 * Можно добавить возможность перетаскивания между несколькими колонками (react-beautiful-dnd).
 */

type DraggableProps = {
  // required
  draggableId: DraggableId,
  index: number, // !!! Must be consecutive. [0, 1, 2] and not [1, 2, 8]
  children: DraggableChildrenFn,
  // optional
  isDragDisabled: ?boolean,
  disableInteractiveElementBlocking: ?boolean,
  shouldRespectForcePress: ?boolean,
};

const draggableOptionalProps = [
  'isDragDisabled',
  'disableInteractiveElementBlocking',
  'shouldRespectForcePress',
];

type DraggableOptionalProps = $Diff<DraggableProps, {
  draggableId: any,
  index: any,
  children: any,
}>

type Props = {
  children: Node,
  className?: string,
  droppableId: string,
  injectDraggableStateSnapshot?: boolean,
  autoHeight?: boolean,
}

const PlainDraggableList = ({
  children,
  className,
  droppableId,
  injectDraggableStateSnapshot,
  autoHeight,
}: Props) => {
  const additionalProps = autoHeight ? {
    autoHeight: true,
    autoHeightMin: '100%',
    autoHeightMax: '100%',
  } : {};

  return (
    <Droppable droppableId={droppableId}>
      {({ droppableProps, innerRef: droppableRef, placeholder }) => (
        <Scrollbars className={className} {...additionalProps}>
          <ul
            {...droppableProps}
            ref={droppableRef}
            className={styles.list}
          >
            {Children.map(children, (comp, index) => {
              const { key, props } = comp;
              const someProps: DraggableOptionalProps = pick(draggableOptionalProps, props);

              return (
                <Draggable
                  {...someProps}
                  key={key}
                  draggableId={key}
                  index={index}
                >
                  {({ innerRef: draggableRef, draggableProps, dragHandleProps }, draggableStateSnapshot) => (
                    <li
                      ref={draggableRef}
                      {...draggableProps}
                      {...dragHandleProps}
                    >
                      {injectDraggableStateSnapshot
                        ? cloneElement(comp, { draggableStateSnapshot })
                        : comp}
                    </li>
                  )}
                </Draggable>
              );
            })}
            {placeholder}
          </ul>
        </Scrollbars>
      )}
    </Droppable>
  );
};

export default PlainDraggableList;
