import api from './api';
import config from '../config.js';

export const DEALER_REQUEST = 'DEALER_REQUEST';
export const DEALER_SUCCESS = 'DEALER_SUCCESS';
export const DEALER_FAILURE = 'DEALER_FAILURE';
/**
* Получение данных дилера
*
*/
export const dealerRequest = () => ({
  type: DEALER_REQUEST,
});

export const dealerSuccess = payload => ({
  type: DEALER_SUCCESS,
  payload,
});

export const dealerFailure = payload => ({
  type: DEALER_FAILURE,
  payload,
});

// TODO привести в порядок
export const dealerFetch = () => async (dispatch) => {
  const parts = document.location.hostname.split('.').reverse();
  const domain = parts[0] === 'localhost' ? 'sms-dnevnik.com' : `${parts[1]}.${parts[0]}`;
  dispatch(dealerRequest());

  try {
    const res = await api('domain', 'get', { params: { domain } });
    if (res.data.phone) localStorage.phone = res.data.phone;
    if (res.data.email) localStorage.email = res.data.email;
    dispatch(dealerSuccess({
      logo_url: res.data.logo_url ? res.data.logo_url : config.defaultLogo,
      logo_w_url: res.data.secondary_logo_url
        ? res.data.secondary_logo_url
        : config.defaultLogoWhite,
      company: res.data.company || config.copyrightName,
      phone: res.data.phone || localStorage.phone || null,
      email: res.data.email || localStorage.email || null,
      project_name: res.data.project_name,
      country_iso_code: res.data.country_iso_code,
      email_force_auth: res.data.email_force_auth || false,
      e_money_enabled: res.data.e_money_enabled || false,
      snils_required: res.data.snils_required || false,
    }));
  } catch (err) {
    dispatch(dealerFailure({
      logo_url: config.defaultLogo,
      logo_w_url: config.defaultLogoWhite,
      company: config.copyrightName,
      phone: localStorage.phone || null,
      email: localStorage.email || null,
      country_iso_code: 'RU',
      e_money_enabled: false,
      snils_required: false,
    }));
  }
};
