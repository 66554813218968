// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';

import RowLayout, { /* Header, */HeaderContent, RowLayoutContent, Space } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { useCurrencySign } from 'app/common/components-next/currency';
import Stub from 'app/common/layouts/Stub';
import { useProcessGenerate, DefaultProgressModal } from 'app/common/ui-components/progress-indicator';

import OrgOrdersOrProductionTabs, { ordersSections } from '../OrgOrdersOrProductionTabs';
import FilterWindow from './filter/FilterWindow';
import { getTitles } from './columns';
import useFilterParams from './useFilterParams';
import useOOPGenerateRequests from './useOOPGenerateRequests';
import useOOPGenerateItems from './useOOPGenerateItems';
import OOPTable from './OOPTable';

import styles from './OrgsOrdersProductionMain.scss';


const cx = classNames;

type Props = {
  orgId: number,
  foodModeEnable: boolean,
  baseUrl: string,
}

const OrgsOrdersProductionMain = ({ orgId, foodModeEnable, baseUrl }: Props) => {
  const currency = useCurrencySign();
  const titles = getTitles(currency);

  const filterParams = useFilterParams(foodModeEnable, orgId);
  const generateRequests = useOOPGenerateRequests(filterParams);

  const [{
    generatedReportId,
    progress,
    processStatus,
  }, { onCancel }] = useProcessGenerate({ ready: filterParams, ...generateRequests });

  const [items, loading] = useOOPGenerateItems(generatedReportId);

  const reportFileName = (filterParams
    ? `orders_productions_${filterParams.from}-${filterParams.to}`
    : 'orders_productions');


  return (
    <Fragment>
      <RowLayout paddingfree>

        <CSSSticky
          number={3}
          className={styles.stickable}
          shadowClassName={styles.shadow}
          stickyClassName={styles.sticky}
        >
          <HeaderContent
            paddingfree
            className={cx(styles.headerContent, styles.topBorderOnSticky)}
          >
            <OrgOrdersOrProductionTabs
              className={styles.headerContentTabs}
              baseUrl={baseUrl}
              type={ordersSections.PRODUCTION}
            />
            {/* <Header>{'Заявки'}</Header> */}
          </HeaderContent>
        </CSSSticky>

        {/* Окно с фильтром */}
        <RowLayoutContent borderTop>
          <FilterWindow
            foodModeEnable={foodModeEnable}
            loading={loading || processStatus !== 'hidden'}
          />
        </RowLayoutContent>
      </RowLayout>

      <Space />

      {/* Таблица с данными или заглушка */}
      <RowLayout paddingfree>
        {generatedReportId
          ? <OOPTable
            reportId={generatedReportId}
            items={items}
            loading={loading}
            titles={titles}
            reportFileName={reportFileName}
          />
          // Первоначально в разделе выводится заглушка
          : <Stub />}
      </RowLayout>

      {processStatus !== 'hidden'
        && <DefaultProgressModal
          progress={progress}
          isError={processStatus === 'error'}
          onCancel={onCancel}
        />}

    </Fragment>
  );
};

export default OrgsOrdersProductionMain;
