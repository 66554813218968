// @flow
import React from 'react';
import cn from 'classnames/bind';

import mixPropsWithDefaults from './mix-props-with-defaults';
import { classByColoringType, colorTypes } from './colors';
import { iconTypes } from './icon-types';
import IconSelector from './icon-selector';
import { getPredefinedProps } from './props';


export type Props = {
  type: $Values<typeof iconTypes>,
  className?: string,
  width?: number | string,
  height?: number | string,
  colorType?: $Values<typeof colorTypes>,
  color?: $Values<typeof colorTypes>,
  disabled?: boolean,
}

const PlainIcon = ({
  type,
  className,
  width,
  height,
  colorType: colorTypeMaybe,
  color: colorMaybe,
  disabled,
}: Props) => {
  const { defaultColor, coloringType, ...others } = getPredefinedProps(type);
  const colorType = colorMaybe || colorTypeMaybe || defaultColor;
  const colorClassName = classByColoringType(coloringType, colorType, disabled);

  return (
    <IconSelector
      type={type}
      className={cn(colorClassName, className)}
      {...mixPropsWithDefaults(others, { width, height })}
    />
  );
};

export default PlainIcon;
