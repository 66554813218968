// @flow
import api from 'app/actions/api';

export const KITSEDIT_REQUEST = 'orgs/menu/KITSEDIT_REQUEST';
export const KITSEDIT_SUCCESS = 'orgs/menu/KITSEDIT_SUCCESS';
export const KITSEDIT_FAILURE = 'orgs/menu/KITSEDIT_FAILURE';

export const kitsEditRequest = (payload: Object) => ({
  type: KITSEDIT_REQUEST,
  payload,
});

export const kitsEditSuccess = () => ({
  type: KITSEDIT_SUCCESS,
});

export const kitsEditFailure = (payload: Object) => ({
  type: KITSEDIT_FAILURE,
  payload,
});

type Params = {
  date: string,
  sellPointId: string | number,
}

export const kitsEdit = (orgId: string | number, {
  date,
  sellPointId,
}: Params, addList?: Array<number>, removeList?: Array<number>) => async (dispatch: Function) => {
  const dataToSend = {
    school_id: orgId,
    date,
    sale_point_id: sellPointId,
    add_complexes: addList,
    remove_complexes: removeList,
  };
  dispatch(kitsEditRequest(dataToSend));
  try {
    await api('food/menu/complexes_add_remove', 'post', { data: dataToSend });
    dispatch(kitsEditSuccess());
    return true;
  } catch (error) {
    dispatch(kitsEditFailure({ err: 'Не удалось модифицировать меню', error }));
    return false;
  }
};
