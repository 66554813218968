// @flow
import React, { type Node, useCallback } from 'react';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';


type Props = {
  index: number,
  label: string,
  checked: boolean,
  onChange: (checked: boolean, index: number) => void,
}

const RuleCheckbox = ({ index, label, checked, onChange }: Props): Node => {
  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked: nextCheckState } = e.currentTarget;
    onChange(nextCheckState, index);
  }, [index, onChange]);

  return (
    <PlainCheckbox
      value={`index_${index}`}
      label={label}
      checked={checked}
      onChange={handleChange}
      hideLabel
    />
  );
};

export default RuleCheckbox;
