
import moment from 'moment-timezone'
import find from 'ramda/es/find'
import comparator from 'ramda/es/comparator'
import last from 'ramda/es/last'
import sort from 'ramda/es/sort'
import pipe from 'ramda/es/pipe'
import { SchoolYearListType, SchoolYearType, Params } from 'app/dataTypes/schoolYearsList'
import SimpleDate from 'app/common/classes/simple-date'
import { isEmpty, map, values } from 'ramda'
import { Request } from '../../index'


const getCurrentSchoolYear = (schoolYearlist: SchoolYearListType): SchoolYearType => {
  const byToday = find(
    ({ dateStart, dateFinish }) => (
      moment().isBetween(dateStart.createMoment(), dateFinish.createMoment(), null, '[]')
    ),
    schoolYearlist,
  )
  const currentYear = byToday || pipe(
    sort(comparator<SchoolYearType>(
      (a, b) => a.dateFinish.createMoment().isBefore(b.dateFinish.createMoment()),
    )),
    last,
  )(schoolYearlist) as SchoolYearType

  return currentYear
}

type ResultApi = Record<string, {
  id: number,
  title: string,
  date_start: string,
  date_finish: string,
}>

export const convertResult = (apiData: ResultApi): SchoolYearListType => {
  const years: SchoolYearListType = map(({
    id,
    title,
    date_start,
    date_finish,
  }) => {
    return ({
      ID: String(id),
      title: title.replace(/-/, '–'), // замена на нормальное тире
      dateStart: new SimpleDate(date_start),
      dateFinish: new SimpleDate(date_finish),
      current: false,
    })
  }, values(apiData))

  if (isEmpty(years)) return years

  const { ID: IDCurrent } = getCurrentSchoolYear(years)

  return map(({ ID, ...others }) => ({
    ID,
    ...others,
    current: ID === IDCurrent,
  }), years)
}

export type ApiParams = {
  school_id: number,
}

export const convertParams = (params: Params): [ApiParams, null] => {
  const { schoolID } = params
  return [{
    school_id: parseInt(schoolID, 10),
  }, null]
}

export const GET_SCHOOLYEARS: Request<Params, SchoolYearListType, void> = [
  'reports/schoolyears_list',
  'get',
  ['data', 'schoolyears'],
]
