// @flow
import React, { type Node, type Ref } from 'react';
import classNames from 'classnames/bind';

import styles from './td.scss';


/**
 * UI COMPONENTS v2
 *
 * Ячейка таблицы
 * ------
 * Семантически верная таблица. Ячейка таблицы (td)
 */

const cx = classNames.bind(styles);

export type TdProps = {
  children: Node,
  className?: string,
  stripStyles?: boolean,
  stripPadding?: boolean,
  alignLeft?: boolean,
  alignCenter?: boolean,
  alignRight?: boolean,
  colSpan?: number,
  rowSpan?: number,
  reference?: Ref<'td'>,
  headers?: string,
  first?: boolean,
  last?: boolean,
  hidden?: boolean,
  bgFilled?: boolean,
  ignoreFirstChild?: boolean,
  borderLeft?: boolean,
  borderRight?: boolean,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
}

const Td = ({
  children,
  className,
  stripStyles,
  stripPadding,
  alignLeft,
  alignCenter,
  alignRight,
  colSpan,
  rowSpan,
  reference,
  headers,
  first,
  last,
  hidden,
  bgFilled,
  ignoreFirstChild,
  borderLeft,
  borderRight,
  onMouseEnter,
  onMouseLeave,
}: TdProps) => {
  const cn = {
    root: !stripStyles,
    rootPadding: !stripStyles && !stripPadding,
    alignLeft,
    alignCenter,
    alignRight,
    first,
    last,
    hidden,
    bgFilled,
    ignoreFirstChild,
    borderLeft,
    borderRight,
  };

  if (hidden) {
    return null;
  }

  const props = {
    colSpan,
    rowSpan,
    headers,
    onMouseEnter,
    onMouseLeave,
  };

  return (
    <td className={cx(cn, className)} ref={reference} {...props}>
      {children}
    </td>
  );
};

export default Td;
