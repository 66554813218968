// @flow
import React from 'react';
import styles from './KitTooltip.scss';


type Item = {
  id: number,
  title: string,
  portion: string,
}

type Props = {
  items: Array<Item>
}

const KitTooltip = ({ items }: Props) => (
  <div className={styles.root}>
    {items.map(item => (
      <div key={item.id} className={styles.row}>
        <div className={styles.left}>{item.title}</div>
        <div className={styles.right}>{item.portion}</div>
      </div>
    ))}
  </div>
);

export default KitTooltip;
