// @flow
import values from 'ramda/es/values';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import pick from 'ramda/es/pick';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';

import { defaultStateDataCurrent } from 'app/common/ui-components/filter';
import { GET_ROLES } from 'app/common/api/requests/food/roles';
import { api } from 'app/api';


const filterGetItemsRoles = async () => {
  const res = await api.request(GET_ROLES, {
    error: 'Не удалось получить данные для фильтра по должности',
  });

  if (res) {
    const roles = prop('roles', res);

    if (roles) {
      const rolesArray = pipe(
        values,
        filter(complement(prop('for_all_schools'))),
        map(pick(['id', 'title'])),
      )(roles);
      return ([rolesArray, defaultStateDataCurrent]);
    }
  }

  return ([[], defaultStateDataCurrent]);
};

export default filterGetItemsRoles;
