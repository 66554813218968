import { useCallback } from 'react'
import { Route } from 'react-router'

import Dishes, { dataKey } from 'app/components/Settings/Dishes'

import { withDishesDishesRights } from 'app/containers/rights'
import Sticky from 'app/common/templates-next/sticky'
import { rootRights, specificRights } from 'app/rights'
import { useRightsByPath } from 'app/rights/Rights'

import Navigation from '../Navigation'
import AccessDenied from '../AccessDenied'
import { baseUrl as rootBaseUrl } from '../baseUrl'

/**
 *  Страница Настройки/Блюда
 */

export const baseUrl = `${rootBaseUrl}/${dataKey}`
const providerDishesRights = [rootRights.DISHES, specificRights.PROVIDER_DISHES] as const

type Props = {
  readAccess: boolean,
  writeAccess: boolean,
  history: {
    push: (path: string, state?: any) => void,
  },
  match: {
    params: {
      act?: string,
    }
  }
}

const DishesPage = withDishesDishesRights(({ history, match }: Props) => {
  const { push } = history
  const { params: { act } } = match
  const { write, read } = useRightsByPath({ pathTo: providerDishesRights })

  // const handleRequestRouterAction = useCallback((requestAct: string) => {
  //   push(`${baseUrl}/${requestAct}`)
  // }, [push])

  const handleRequestRouterHome = useCallback(() => {
    push(baseUrl)
  }, [push])


  return (
    <>
      <Sticky>
        {(ref, stuck) => (
          <Navigation ref={ref} stuck={stuck} />
        )}
      </Sticky>

      {read
        ? <Dishes
          writeAccess={write}
          baseUrl={baseUrl}
          act={act}
          // onRequestRouterAction={handleRequestRouterAction}
          onRequestRouterHome={handleRequestRouterHome}
        />
        : <AccessDenied />}
    </>
  )
})

export const dishesPageRoutes = [
  <Route key="dishes1" path={`${baseUrl}/:act`} component={DishesPage} exact />,
  <Route key="dishes2" path={baseUrl} component={DishesPage} />,
]
