// @flow
import { useCallback, useRef } from 'react';
import path from 'ramda/es/path';


type Props = {
  onScroll: (e: SyntheticEvent<HTMLElement>) => void,
  reference: MutableRefObject<any>,
  id: string,
}

export default function useSyncScrolls(): [
  Props,
  Props,
  Props,
  () => void
  ] {
  const lastKnownScrollPosition = useRef<number>(0);
  const ticking = useRef<boolean>(false);
  const refTopScrollable = useRef<any>(null);
  const refMiddleScrollable = useRef<any>(null);
  const refBottomScrollable = useRef<any>(null);

  // двигаем скрол, чтоб он обновился и появился
  const doUpdateBottom = useCallback(() => {
    const scrollbars = refBottomScrollable.current;
    if (scrollbars) {
      scrollbars.scrollLeft(1);
      scrollbars.scrollLeft();
    }
  }, []);

  // синхронизация горизонтального скролинга для различных частей таблицы
  const handleScroll = useCallback((e: SyntheticEvent<HTMLElement>) => {
    // $FlowFixMe
    e.stopImmediatePropagation();
    if (!e.currentTarget) return;
    const { scrollLeft } = e.currentTarget;
    lastKnownScrollPosition.current = scrollLeft;
    const id = path(['currentTarget', 'parentNode', 'id'], e);

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        if (refTopScrollable.current && id !== 'hs') {
          refTopScrollable.current.view.scrollLeft = lastKnownScrollPosition.current;
        }
        if (refMiddleScrollable.current && id !== 'ms') {
          refMiddleScrollable.current.view.scrollLeft = lastKnownScrollPosition.current;
        }
        if (refBottomScrollable.current && id !== 'bs') {
          refBottomScrollable.current.view.scrollLeft = lastKnownScrollPosition.current;
        }
        ticking.current = false;
      });
      ticking.current = true;
    }
  }, []);


  const topScrollerProps = {
    onScroll: handleScroll,
    reference: refTopScrollable,
    id: 'hs',
  };

  const middleScrollerProps = {
    onScroll: handleScroll,
    reference: refMiddleScrollable,
    id: 'ms',
  };

  const bottomScrollerProps = {
    onScroll: handleScroll,
    reference: refBottomScrollable,
    id: 'bs',
  };


  return [topScrollerProps, middleScrollerProps, bottomScrollerProps, doUpdateBottom];
}
