// @flow
import type { Category, ParamsDELETE } from 'app/dataTypes/vending/production/Categories.types';

import type { Request } from '../../../../index';


type ParamsURL = {
  categoryId: number | string,
}

type ResultApi = {
  id: number,
  place_id: number,
  title: string,
  index: number | null,
  deleted: true,
  deletable: boolean,
}

export const convertParams = ({ categoryId }: ParamsDELETE): [null, ParamsURL] => {
  return [null, { categoryId }];
};

export const convertResult = (data: ResultApi): Category => {
  const {
    id,
    place_id: placeId,
    title,
    index,
    deleted,
    deletable,
  } = data;

  return {
    id: String(id),
    placeId: String(placeId),
    title,
    index,
    deleted,
    deletable,
    quantity: 0,
  };
};

export const DELETE_VENDING_ITEMS_CATEGORIES: Request<ParamsDELETE, Category, ParamsURL> = [
  'data/vending/items/categories/{categoryId}',
  'delete',
  ['data', 'data'],
];
