// @flow
import React, { useMemo, Fragment } from 'react';
import classNames from 'classnames/bind';
import { useSelector, shallowEqual } from 'react-redux';

import usePaging from 'app/common/hooks/usePaging';
import Paging from 'app/common/ui/Paging';

import {
  Table,
  Tr,
  Th,
  Td,
} from 'app/common/ui-components/table';

import useBalanceRefunds from './use-balance-refunds';
import styles from './fund-return-history.scss';


const cx = classNames.bind(styles);
const perPage = 3;

type Props = {
  customerId: number,
  invalidateHistory?: boolean,
}

const FundReturnHistory = ({ customerId, invalidateHistory }: Props) => {
  const { currentPage, handlePage } = usePaging();

  const params = useMemo(() => ({
    customer_id: customerId,
    limit: perPage,
    offset: (currentPage - 1) * perPage,
  }), [customerId, currentPage, invalidateHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  const { timezone } = useSelector(state => state.user, shallowEqual);
  const [refunds, count, loading] = useBalanceRefunds(params, timezone);

  return (
    <div className={cx(styles.root, { active: !loading && refunds.length })}>
      <h2 className={styles.header}>{'История возвратов:'}</h2>
      <Table className={styles.table}>
        <thead>
          <Tr header>
            <Th alignRight>{'Дата'}</Th>
            <Th alignLeft>{'Время'}</Th>
            <Th alignLeft>{'Сумма'}</Th>
            <Th alignLeft>{'Комментарий'}</Th>
          </Tr>
        </thead>
        <tbody>
          {refunds.map(({ id, date, value, comment, time }) => (
            <Tr key={id}>
              <Td alignRight>{date}</Td>
              <Td>{time}</Td>
              <Td>{value}</Td>
              <Td>{comment}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>

      {(count > perPage)
          && <Paging
            className={styles.pager}
            total={count}
            pageSize={perPage}
            currentPage={currentPage}
            currentPageSize={refunds.length}
            onPage={handlePage}
          />}

    </div>
  );
};

export default FundReturnHistory;
