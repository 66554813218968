import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import CommonPage from 'app/common/templates/CommonPage';

import getBaseUrl from './getBaseUrl';
import ProfilePage, { baseDefaultSection } from './ProfilePage';


// const baseUrl = getBaseUrl();
const baseSection = getBaseUrl({ section: ':section' });

const ProfilePageRoot = () => (
  <CommonPage header="Мой профиль">
    <Switch>
      <Route path={baseSection} component={ProfilePage} exact />
      <Redirect to={baseDefaultSection} />
    </Switch>
  </CommonPage>
);

export default ProfilePageRoot;
