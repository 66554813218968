// @flow
import { useMemo } from 'react';

import { useCurrencySign } from 'app/common/components-next/currency';


const ID: 'id' = 'id';
const SOURCE: 'source' = 'source';
const RECEIVER: 'receiver' = 'receiver';
const DATE: 'date' = 'date';
const SUM: 'sum' = 'sum';
const COMMENT: 'comment' = 'comment';

export const columns = {
  ID,
  SOURCE,
  RECEIVER,
  DATE,
  SUM,
  COMMENT,
};

export type ColumnProps = {
  id: $Values<typeof columns>,
  title: string,
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
  },
}

export function useColumns(): Array<ColumnProps> {
  const currencySign = useCurrencySign();

  const res: Array<ColumnProps> = useMemo(() => {
    return [{
      id: ID,
      title: 'Номер платежа',
      props: {
        alignLeft: true,
      },
    }, {
      id: SOURCE,
      title: 'Источник',
      props: {
        alignLeft: true,
      },
    }, {
      id: RECEIVER,
      title: 'Получатель',
      props: {
        alignLeft: true,
      },
    }, {
      id: DATE,
      title: 'Дата и время',
      props: {
        alignCenter: true,
      },
    }, {
      id: SUM,
      title: `Сумма,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: COMMENT,
      title: 'Комментарий',
      props: {
        alignRight: true,
      },
    }];
  }, [currencySign]);

  return res;
}
