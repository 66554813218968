import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames/bind'

import styles from './Content.scss'


const cx = classNames.bind(styles)

/**
 *  Контент для заголовка. Внутри можно размещать Header и другие элементы.
 *  Создан для горизонтальной линии, отделяющей заголовок от остального контента.
 *  Родительский RowLayout должен быть paddingfree
 *  DEPRECATED use RowLayoutContent
 */

type Props = {
  className?: string
  paddingfree?: boolean
  borderBottom?: boolean
  children: ReactNode
}

const HeaderContent = forwardRef<HTMLDivElement, Props>(({
  className,
  paddingfree,
  borderBottom,
  children,
}, ref) => (
  <div className={cx(styles.header, { padding: !paddingfree, borderBottom }, className)} ref={ref}>
    {children}
  </div>
))

export default HeaderContent
