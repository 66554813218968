import { createSelector } from 'reselect';
import pick from 'ramda/es/pick';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import path from 'ramda/es/path';

import selectMenuSellPoints from 'app/selectors/OrgsList/selectMenuSellPoints';


const selectDishes = createSelector(
  [
    (state, { kitId }) => path(['orgs', 'kits', 'items', kitId, 'items'], state) || {},
  ],
  dishes => pipe(
    values,
    map(pick(['id', 'title'])),
  )(dishes),
);

const selectProps = createSelector(
  [
    (_, { dishes }) => dishes,
    selectDishes,
    selectMenuSellPoints,
  ],
  (dishesFromProps, dishesFromKit, sellPoints) => ({
    dishes: dishesFromProps || dishesFromKit,
    sellPoints,
  }),
);

export default selectProps;
