// @flow
import { useMemo } from 'react';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import path from 'ramda/es/path';
import fromPairs from 'ramda/es/fromPairs';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import isEmpty from 'ramda/es/isEmpty';
import complement from 'ramda/es/complement';
import anyPass from 'ramda/es/anyPass';
import isNil from 'ramda/es/isNil';
import when from 'ramda/es/when';

import { useFilterData } from 'app/common/ui-components/filter';
import { reportTypes, type ReportsPerformPurchasesByFundsParams } from 'app/common/api/requests/reports/perform';
import { emptyObject } from 'app/common/lib/empty';

import orgsFilter from 'app/components/common/reports/filters/org';
import buildingFilter from 'app/components/common/reports/filters/building';
import sellPointFilter from 'app/components/common/reports/filters/sellPoint';
import fundFilter from 'app/components/common/reports/filters/fund';
import foodModeFilter from 'app/components/common/reports/filters/foodMode';
import mailFilter, { readyToUse, onlyMail } from 'app/components/common/reports/filters/mail';

import dateFilter from '../ReportFilter/filters/date';


const toInt = (val: string) => parseInt(val, 10);
const notNil = complement(isNil);
const convertIntengers = (key: string) => pipe(prop(key), when(notNil, map(toInt)));
const convertStrings = (key: string) => prop(key);
const convertString0 = (key: string) => path([key, 0]);

type Source = {
  [key: string]: Array<string>
}

const convertionLimits = {
  school_ids: convertIntengers(orgsFilter),
  building_id: convertIntengers(buildingFilter),
  sale_point_id: convertIntengers(sellPointFilter),
  funds: convertString0(fundFilter),
  food_modes: convertStrings(foodModeFilter),
};

const convertionParams = {
  date: convertString0(dateFilter),
};

const convertionEmail = (email: ?string) => ({
  send_to_email: (source: Source) => (
    email && readyToUse(path([mailFilter, 0], source))
      ? email
      : null
  ),
  onlymail: (source: Source) => (
    Boolean(email && onlyMail(path([mailFilter, 0], source)))
  ),
});

const converter = (source: Source) => {
  return pipe(
    map(func => func(source)),
    filter(complement(anyPass([isNil, isEmpty]))),
  );
};

export type Params = ReportsPerformPurchasesByFundsParams | null

export default function useGenerateParams() {
  const [,,, { getAppliedFilterActive }, filterParams] = useFilterData();

  const res: [Params, boolean] = useMemo(() => {
    const data = getAppliedFilterActive();
    if (!data) {
      return [null, false];
    }

    const source: Source = fromPairs(data);
    const email = filterParams && prop('email', filterParams);

    const convert = converter(source);

    const limits = convert(convertionLimits);
    const params = { ...convert(convertionParams), limits };
    const { onlymail, ...options } = convert(convertionEmail(email));
    const hasOptions = !isEmpty(options);

    return [{
      report_type: reportTypes.REPORT_PURCHASES_BY_FUNDS,
      params,
      ...(hasOptions ? { options } : emptyObject),
    }, onlymail];
  }, [getAppliedFilterActive, filterParams]);

  return res;
}
