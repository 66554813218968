import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'


type Props = {
  name: string
  title: string
  data: [boolean, boolean]
  disabled?: boolean
  formID: string
  onModify?: () => void
}

const FormGridRow = ({
  name,
  title,
  data,
  disabled,
  formID,
  onModify,
}: Props) => {
  const [pair, setPair] = useState(data)

  const handleCheckbox0 = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setPair(([, val]) => ([checked, val]))
    if (onModify) {
      onModify()
    }
  }, [onModify])

  const handleCheckbox1 = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setPair(([val]) => ([val, checked]))
    if (onModify) {
      onModify()
    }
  }, [onModify])

  useEffect(() => {
    setPair(data)
  }, [data])

  return (
    <tr>
      <td>{title}</td>
      <td>
        <PlainCheckbox
          label={`${title} - Показывать в форме создания справки`}
          hideLabel
          name={`${name}1`}
          value="active"
          checked={pair[0]}
          onChange={handleCheckbox0}
          disabled={disabled}
          form={formID}
        />
      </td>
      <td>
        <PlainCheckbox
          label={`${title} - Выбрано принудительно`}
          hideLabel
          name={`${name}2`}
          value="active"
          checked={pair[1]}
          onChange={handleCheckbox1}
          disabled={disabled}
          form={formID}
        />
      </td>
    </tr>
  )
}

export default FormGridRow
