import { createSelector } from 'reselect';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import toString from 'ramda/es/toString';
import slice from 'ramda/es/slice';

import toFixedValue from 'app/common/lib/toFixedValue';


export type GroupType = {
  id: number,
  customerType: string,
  klassTitle: string,
  count: string,
  pos: string,
  neg: string,
  negHighlight: boolean,
  negPercents: string,
  total: string,
}

const selectActualGroups = createSelector(
  [
    ({ groups }) => groups,
    ({ currentPage, rows }) => ((currentPage - 1) * rows),
    ({ currentPage, rows }) => (currentPage * rows),
  ],
  (groups, from, to) => pipe(
    slice(from, to),
    map(({ id, customerType, klassTitle, count, pos, neg, negPercents, total }) => ({
      id,
      customerType,
      klassTitle,
      count: toString(count),
      pos: toFixedValue(pos),
      neg: toFixedValue(neg),
      negHighlight: neg < 0,
      negPercents: toFixedValue(negPercents),
      total: toFixedValue(total),
    }: GroupType)),
  )(groups),
);

export default selectActualGroups;
