import { useCallback, useState, useRef, ChangeEvent } from 'react'
import { slice, append, isEmpty } from 'ramda'
import cn from 'classnames/bind'

import PlainFile from 'app/common/ui/PlainFile'
import ProgressBar from 'app/common/ui/ProgressBar'
import { api } from 'app/api'
import { type FileType } from 'app/common/api'
import OverflowedText from 'app/common/components/OveflowedText'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import FileView, { CrossButton } from './FileView'
import { t } from './translate'
import styles from './AddFiles.scss'


const supportedFiles = 'PDF'
const supportedAttachments = [
  'application/pdf',
]

const cx = cn.bind(styles)

type Props = {
  disabled?: boolean
  onAddFiles: (files: Record<string, FileType>) => void
  onRemoveFile: (id: string) => void
  files: Array<FileType>
  label: string
  maxFiles?: number
  maxFileSize?: number
}

export const AddFiles = ({
  disabled,
  onAddFiles,
  onRemoveFile,
  files,
  label,
  maxFiles = 1,
  maxFileSize = 5242880,
}: Props) => {
  const [progress, setProgress] = useState(0)
  const [loadingFileName, setLoadingFileName] = useState('')
  const [exceedFiles, setExceedFiles] = useState([] as Array<string>)
  const [invalidFiles, setInvalidFiles] = useState([] as Array<string>)
  const dropzone = useRef(null)

  const handleProgress = useCallback(({ loaded, total }) => {
    setProgress(Math.round((loaded * 100) / total))
  }, [])

  const handleOpenClick = useCallback(() => {
    if (dropzone.current) {
      (dropzone.current as any).open()
    }
  }, [])

  const handleAddFiles = useCallback(async (acceptedFiles = [] as Array<File>) => {
    if (acceptedFiles.length) {
      // берем сколько вмещается
      const fit = maxFiles - files.length
      const len = (acceptedFiles.length < fit) ? acceptedFiles.length : fit

      const actualFiles = slice(0, len, acceptedFiles)
      let submitedFiles = {}

      for await (const file of actualFiles) { // eslint-disable-line no-restricted-syntax
        setLoadingFileName(file.name)
        const res = await api.submitFile(file, handleProgress)
        if (res) {
          setLoadingFileName('')
          submitedFiles = { ...submitedFiles, ...res }
        }
      }

      onAddFiles(submitedFiles)
    }
  }, [handleProgress, files, onAddFiles, maxFiles])


  const handleRemoveFile = useCallback((e: ChangeEvent<HTMLButtonElement>) => {
    const { id } = e.currentTarget
    onRemoveFile(id)
  }, [onRemoveFile])


  const handleAddInvalidFormatFile = useCallback((name: string) => {
    setInvalidFiles(append(name))
  }, [])

  const handleClearInvalidFormat = useCallback(() => {
    setInvalidFiles([])
  }, [])

  const handleAddExceedSizeFile = useCallback((name: string) => {
    setExceedFiles(append(name))
  }, [])

  const handleClearExceedSize = useCallback(() => {
    setExceedFiles([])
  }, [])

  return (
    <PlainFile
      id="files"
      label={label}
      className={styles.root}
      classNameLabel={styles.label}
      comment={!files.length ? t('form_files_comment', {
        supportedFiles,
        maxSize: String(Math.round(maxFileSize / 1050578)),
        maxFiles: String(maxFiles),
      }) : ''}
      ref={dropzone}
      accept={supportedAttachments.join(', ')}
      maxSize={maxFileSize}
      onDrop={handleAddFiles}
      disablePreview
      disabled={disabled}
      onInvalidFormat={handleAddInvalidFormatFile}
      onExceedSize={handleAddExceedSizeFile}
    >
      {(dragState: { isDragActive: boolean, isDragReject: boolean }) => (
        <div>
          {files.map(({ id, name, thumb_url: urlThumb, url }) => (
            <FileView
              key={id}
              id={id}
              name={name}
              urlThumb={urlThumb}
              url={url}
              onCrossClick={handleRemoveFile}
            />
          ))}

          {loadingFileName
            && <div className={styles.loading}>
              <ProgressBar progress={progress} />
              <OverflowedText className={styles.loadingFileName}>
                {loadingFileName}
              </OverflowedText>
            </div>}

          {!isEmpty(exceedFiles)
            && <div className={styles.unAccepted}>
              {t('form_error_unaccepted_size_message', { files: exceedFiles.join(', ') })}
              <CrossButton onCrossClick={handleClearExceedSize} />
            </div>}

          {!isEmpty(invalidFiles)
            && <div className={styles.unAccepted}>
              {t('form_error_unaccepted_format_message', { files: invalidFiles.join(', ') })}
              <CrossButton onCrossClick={handleClearInvalidFormat} />
            </div>}

          {disabled
            && <div className={cx(styles.dropArea, { maxFiles: files.length >= maxFiles })}>
              <div>
                {!disabled
                  && t('form_files_limit_exceeded')}
              </div>
            </div>}

          {files.length < maxFiles
            && !disabled
            && <div className={cx(styles.dropArea, { ...dragState })}>
              <div>
                <PlainButtonAsLink
                  onClick={handleOpenClick}
                  ariaLabel={t('form_files_select_1')}
                >
                  {t('form_files_select_1')}
                </PlainButtonAsLink>
                <span>{` ${t('form_files_select_2')}`}</span>
              </div>
            </div>}
        </div>
      )}
    </PlainFile>
  )
}
