// @flow
import React from 'react';
import classNames from 'classnames/bind';

import { type Status } from '../../useOrdersData';
import styles from './CellStatus.scss';


const cx = classNames.bind(styles);

type Props = {
  number: number,
  status: ?Status,
  statusTitle: string,
}

const CellStatus = ({ number, status, statusTitle }: Props) => {
  const classByStatus = { [`status${String(status)}`]: true };

  return (
    <div className={styles.root}>
      <div className={styles.indicator}>
        <div className={cx(styles.circle, classByStatus)} />
      </div>
      <div className={styles.number}>{number}</div>
      <div className={cx(styles.status, classByStatus)}>{statusTitle}</div>
    </div>
  );
};

export default CellStatus;
