// @flow
import React, { Fragment, useEffect, memo, useState, type Node } from 'react';

import { useProcessGenerate, DefaultProgressModal, getProcessMessages } from 'app/common/ui-components/progress-indicator';
import useReportCancel from 'app/components/common/reports/useReportCancel';

import useGenerateParams from './useGenerateParams';
import useGenerateRequests from './useGenerateRequests';


type Props = {
  onGenerate: (reportId: number | null) => void,
  children: (boolean) => Node,
}

const ReportGenerate = memo<Props>(({ onGenerate, children }: Props) => {
  const [requesting, setRequesting] = useState(false);
  const [params, onlyMail] = useGenerateParams();
  const generateRequests = useGenerateRequests(params, setRequesting);
  const processCancel = useReportCancel();

  const [generateState, generateHandlers] = useProcessGenerate({
    ready: params,
    onProcessCancel: processCancel,
    onlyMail,
    ...generateRequests,
  });
  const { generatedReportId, progress, processStatus, processId } = generateState;
  const [message, messageError] = getProcessMessages(processId);
  const { onCancel } = generateHandlers;

  useEffect(() => {
    onGenerate(generatedReportId);
  }, [generatedReportId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {children(requesting || processStatus !== 'hidden')}
      {processStatus !== 'hidden'
        && <DefaultProgressModal
          progress={progress}
          isError={processStatus === 'error'}
          onCancel={onCancel}
          message={message}
          messageError={messageError}
        />}
    </Fragment>
  );
});

export default ReportGenerate;
