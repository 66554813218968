// @flow
import mapObjIndexed from 'ramda/es/mapObjIndexed';
import api from 'app/actions/api';
import get from 'app/common/lib/get';
import incommingMapper from './incommingMapper';


export const EMPLOYEE_EDIT_REQUEST = 'EMPLOYEE_EDIT_REQUEST';
export const EMPLOYEE_EDIT_SUCCESS = 'EMPLOYEE_EDIT_SUCCESS';
export const EMPLOYEE_EDIT_FAILURE = 'EMPLOYEE_EDIT_FAILURE';

const employeeEditRequest = payload => ({
  type: EMPLOYEE_EDIT_REQUEST,
  payload,
});

const employeeEditSuccess = payload => ({
  type: EMPLOYEE_EDIT_SUCCESS,
  payload,
});

const employeeEditFailure = payload => ({
  type: EMPLOYEE_EDIT_FAILURE,
  payload,
});

export const employeeSubmit = (id: number | string, fields: Object) => async (dispatch: Function) => {
  const dataToSend = { id, ...fields };
  dispatch(employeeEditRequest());
  try {
    const res = await api('food/employee', 'put', { data: dataToSend });
    const result = mapObjIndexed(incommingMapper, get(res, ['data', 'fields'], {}));
    dispatch(employeeEditSuccess(result));
    return true;
  } catch (error) {
    dispatch(employeeEditFailure({ err: 'Не удалось обновить данные сотрудника', error }));
    return false;
  }
};
