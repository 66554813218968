import { ChangeEvent, useCallback } from 'react'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'

import CopySettings from '../../CopySettings'
import { useRequestsData } from '../RequestsData'
import CopySettingsContainer from '../../common/CopySettingsContainer'


type Props = {
  disabled?: boolean
}

const DisableDesktopOrders = ({ disabled }: Props) => {
  const {
    content: {
      generalSettings: {
        state,
        loading,
      },
      submitting,
    },
    request: {
      generalSettings: {
        submit,
      },
    },
    modify: {
      generalSettings: {
        updateField,
      },
    },
  } = useRequestsData()

  const {
    disable_desktop_orders: disableDesktopOrders,
  } = state

  const submitAllowEditCafeOrderItems = useCallback((orgIds: Array<number>) => {
    return submit(orgIds, ['disable_desktop_orders'])
  }, [submit])

  const handleCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget
    updateField(value, checked)
  }, [updateField])

  return (
    <CopySettingsContainer lowTopMargin>
      <PlainCheckbox
        value="disable_desktop_orders"
        id="disable_desktop_orders"
        label="Запретить создавать и редактировать заявки в настольном приложении «Аргус.Клиент»"
        disabled={disabled || loading || submitting}
        checked={disableDesktopOrders}
        onChange={handleCheckboxChange}
      />

      {!disabled
        && <CopySettings
          onSubmit={submitAllowEditCafeOrderItems}
        />}
    </CopySettingsContainer>
  )
}

export default DisableDesktopOrders
