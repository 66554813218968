// @flow
import React, { type Node, useCallback, useState, Children } from 'react';
import classNames from 'classnames/bind';
import { useSelector, shallowEqual } from 'react-redux';

import ClickOutside from 'app/common/ui/ClickOutside';
import Avatar from 'app/common/ui/Avatar';
import PlainMenu from 'app/common/ui/PlainMenu';
import PlainMenuItem from 'app/common/ui/PlainMenu/PlainMenuItem';
import PlainDivider from 'app/common/ui/PlainMenu/PlainDivider';
import OveflowedText from 'app/common/components/OveflowedText';
import { getLastFM } from 'app/common/lib/names';

import useBalance from './useBalance';
import styles from './UserMenu.scss';


const cx = classNames.bind(styles);

type Props = {
  active?: boolean,
  hideBalance?: boolean,
  children: Node,
}

const UserMenu = ({
  active,
  hideBalance,
  children,
}: Props) => {
  // const dispatch = useDispatch();
  const balance = useBalance({ hideBalance });

  const { name, avatar, gender } = useSelector(state => state.user, shallowEqual);
  const actualName = getLastFM(!name.last ? { last: name.first || name.middle } : name);


  // const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);


  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsOpen(state => !state);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);


  return (
    <ClickOutside
      className={cx({ main: true, active: active || isOpen })}
      onClickOutside={handleCloseMenu}
    >
      <button type="button" onClick={toggleMenu} className={styles.root}>
        <div className={styles.namebalance}>
          <OveflowedText>{actualName}</OveflowedText>
          <div>{balance}</div>
        </div>
        <Avatar
          src={avatar}
          gender={gender}
          className={cx({ ava: true, active: active || isOpen })}
        />
        <span className={cx({ arrow: true, active: active || isOpen })} />
      </button>

      {isOpen
        && <PlainMenu className={styles.menu}>
          {Children.map(children, comp => (
            typeof comp === 'string' && comp === '---'
              ? <PlainDivider />
              : <PlainMenuItem onPropagationCatch={toggleMenu}>
                {comp}
              </PlainMenuItem>
          ))}
        </PlainMenu>}

    </ClickOutside>
  );
};

export default UserMenu;
