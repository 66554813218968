// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';
import { popupWarn } from 'app/common/actions/popup';

export const NEW_KIT_REQUEST = 'orgs/newKit/NEW_KIT_REQUEST';
export const NEW_KIT_SUCCESS = 'orgs/newKit/NEW_KIT_SUCCESS';
export const NEW_KIT_FAILURE = 'orgs/newKit/NEW_KIT_FAILURE';

/**
* Создание нового комплекта
*/

export const newKitRequest = (payload: Object) => ({
  type: NEW_KIT_REQUEST,
  payload,
});

export const newKitSuccess = (payload: Object) => ({
  type: NEW_KIT_SUCCESS,
  payload,
});

export const newKitFailure = (payload: Object) => ({
  type: NEW_KIT_FAILURE,
  payload,
});

type Params = {
  title: string,
  isFixedPrice: boolean,
  fixedPrice: string,
};

export const newKit = (orgId: string | number, params?: Params) => async (dispatch: Function) => {
  const data = params ? {
    title: params.title,
    fixed_price_enabled: params.isFixedPrice,
    fixed_price: params.fixedPrice,
  } : {};
  dispatch(newKitRequest(data));
  try {
    const res = await api(`food/schools/${orgId}/complexes`, 'post', { data });
    const kit = get(res, ['data', 'complexes'], {});
    if (!kit.id) {
      dispatch(popupWarn('Сервер не вернул созданный комплект'));
      return 0;
    }
    dispatch(newKitSuccess({
      [kit.id]: { ...kit, isNew: true },
    }));
    // тест нового комплекта
    // dispatch(newKitSuccess({
    //   '219': {
    //     id: 219,
    //     title: 'Тестовый комплект',
    //     fixed_price_enabled: true,
    //     fixed_price: '223.33',
    //     isNew: true
    //   },
    // }));
    return 0;
  } catch (error) {
    const status = get(error, ['response', 'status'], 1);
    dispatch(newKitFailure({ err: 'Создание комплекта не удалось', error }));
    return status;
  }
};
