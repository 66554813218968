import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  VIEW_TYPES_FILTER_CLEAR,
  VIEW_TYPES_FILTER_DELETE,
  VIEW_TYPES_FILTER_SET,
  VIEW_TYPES_FILTER_UPDATE,
} from 'app/actions/reports/filters/viewTypes';

import * as viewTypesAvailable from 'app/actions/reports/filters/viewTypesAvailable';


export const defaultState = {
  data: {
    [viewTypesAvailable.VIEW_TYPE_SUM]: {
      id: viewTypesAvailable.VIEW_TYPE_SUM,
      title: 'Сумма',
    },
    [viewTypesAvailable.VIEW_TYPE_QUANTITY]: {
      id: viewTypesAvailable.VIEW_TYPE_QUANTITY,
      title: 'Количество',
    },
  },
  selected: {},
  active: false,
};

const subsidyTypes = (state = defaultState, { type, payload }) => {
  switch (type) {
    case VIEW_TYPES_FILTER_CLEAR: {
      return defaultState;
    }
    case VIEW_TYPES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case VIEW_TYPES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case VIEW_TYPES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default subsidyTypes;
