// @flow
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import prop from 'ramda/es/prop';

import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';

import filterGetItemsYears from '../filterCommon/filterGetItemsYears';
import filterGetItemsFoodModes from '../filterCommon/filterGetItemsFoodModes';
import filterGetItemsClasses from '../filterCommon/filterGetItemsClasses';
import filterGetItemsStatuses from '../filterCommon/filterGetItemsStatuses';
import type { FilterParams } from '../filterCommon/FIlterParams';
import periodFilter, { periodItems, periodProps } from './period';


const filterItems: Descriptions<FilterParams> = [{

  /* фильтр по учебному году */
  filterId: 'year',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Учебный год',
    header: 'Выберите учебный год',
    colorInverse: true,
    sortFunc: sort(descend(prop('title'))),
  },
  getItemsAndCurrent: filterGetItemsYears,
  relations: ['class', 'month'], // фильтры по классу и месяцам зависят от состояния этого фильтра
}, {

  /* фильтр по периоду */
  filterId: periodFilter,
  filterType: filterTypes.PLAIN_PERIOD,
  getProps: periodProps,
  getItemsAndCurrent: periodItems,
}, {

  /* фильтр по режиму питания */
  filterId: 'foodMode',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Режим питания',
    header: 'Выберите режим питания',
    colorInverse: true,
  },
  getItemsAndCurrent: filterGetItemsFoodModes,
}, {

  /* фильтр по классам */
  filterId: 'class',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Класс',
    header: 'Выберите класс',
    colorInverse: true,
  },
  getItemsAndCurrent: filterGetItemsClasses,
}, {

  /* фильтр по статусу */
  filterId: 'status',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Статус',
    header: 'Выберите статус',
    colorInverse: true,
  },
  getItemsAndCurrent: filterGetItemsStatuses,
}];

export default filterItems;
