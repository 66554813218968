// @flow
import React, { Fragment, useCallback, useState } from 'react';
import not from 'ramda/es/not';

import IconLightCross from 'app/common/icons/IconLightCross';
// import IconSettings from 'app/common/icons/IconSettings';
import Tooltip from 'app/common/ui/Tooltip';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
// import RestrictionsSettingsModal from 'app/components/common/RestrictionsSettingsModal';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';

import { useDishesData } from '../DishesData';
import styles from './DishesTableOptions.scss';

/**
 *  Ячейка таблицы с настройками
 */

type Props = {
  id: number,
  title: string,
}

const DishesTableOptions = ({ id, title }: Props) => {
  const [, { delete: deleteDishes }] = useDishesData();

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const toggleDeleteConfirmation = useCallback(() => setDeleteConfirmationVisible(not), []);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteDish = useCallback(async () => {
    setDeleting(true);
    const res = await deleteDishes([id]);
    setDeleting(false);

    if (res) {
      setDeleteConfirmationVisible(false);
    }
  }, [id, deleteDishes]);

  return (
    <Fragment>
      {/* <RestrictionsSettingsModal
        header="Настройки"
        headPhrase={`Для кого используется продукция «${title}»`}
        dishId={id}
      >
        {handleToggle => (
          <PlainButtonAsLink
            className={styles.item}
            // onClick={toggleDishSettings}
            onClick={handleToggle}
          >
            <Tooltip text="Настроить блюдо">
              <IconSettings
                className={styles.iconSettings}
              />
            </Tooltip>
          </PlainButtonAsLink>
        )}
      </RestrictionsSettingsModal> */}

      <PlainButtonAsLink
        className={styles.item}
        onClick={toggleDeleteConfirmation}
      >
        <Tooltip text="Удалить блюдо">
          <IconLightCross
            className={styles.iconCross}
          />
        </Tooltip>
      </PlainButtonAsLink>

      {deleteConfirmationVisible
        && <PlainConfirmModal
          messageText={`Вы уверены? Удалить «${title}»?`}
          cancelButtonTitle="Отмена"
          confirmButtonTitle={deleting ? 'Удаление…' : 'Удалить'}
          confirmButtonProps={{ disabled: deleting }}
          onCancel={toggleDeleteConfirmation}
          onConfirm={handleDeleteDish}
          critical
        />}
    </Fragment>
  );
};

export default DishesTableOptions;
