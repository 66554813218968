// @flow
import React from 'react';

import EditableProp from 'app/common/ui/EditableProp';
import { withDishesOrgDishesRights } from 'app/containers/rights';


type Props = {
  externalWriteAcces: boolean,
  right: boolean,
  value: string,
  name: string,
  readAccess: boolean,
  writeAccess: boolean,
  children: Function,
}

const EditablePropWLocalWriteAccess = ({
  externalWriteAcces,
  right,
  value,
  name,
  // readAccess,
  writeAccess,
  children,
}: Props) => {
  const writeAccessGranted = externalWriteAcces && writeAccess;

  if (writeAccessGranted) {
    return (
      <EditableProp
        right={right}
        value={value}
        name={name}
      >
        {children}
      </EditableProp>
    );
  }

  return <div>{value}</div>;
};

export default withDishesOrgDishesRights(EditablePropWLocalWriteAccess);
