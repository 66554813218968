// @flow
import React from 'react';

import { Tr, Td } from 'app/common/ui-components/table';

import {
  MultiLineCellTotal,
  multiLineCellTypes,
  MultiLineCellDoubleTotal,
  CellRestriction,
  restrictions,
} from '../MultiLineCell';

import { type DisplayableData } from '../displayableData';
import { totalColumnId, totalRowId } from './columnsAndRowsIds';


const emptyProps = Object.freeze({});

type Props = {
  hidden?: boolean,
  showDuty?: boolean,
  summary: DisplayableData,
  sellTypeGroupped?: boolean,
}

const RightColumnFooter = ({ hidden, showDuty, summary, sellTypeGroupped }: Props) => {
  const Cell = sellTypeGroupped ? MultiLineCellDoubleTotal : MultiLineCellTotal;
  const cellRestriction = sellTypeGroupped ? restrictions.WIDE : restrictions.TIGHT;

  const props = hidden ? emptyProps : {
    headers: `${totalColumnId} ${totalRowId}`,
  };

  return (
    <tfoot>
      <Tr
        hidden={hidden}
        footer
      >
        <Td
          stripPadding
          {...props}
        >
          <CellRestriction type={cellRestriction}>
            <Cell
              data={summary}
              showDuty={showDuty}
              type={multiLineCellTypes.TOTAL}
            />
          </CellRestriction>
        </Td>
      </Tr>
    </tfoot>
  );
};

export default RightColumnFooter;
