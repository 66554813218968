import { useCallback, useState } from 'react'

import {
  POST_COMPENSATION_WITHDRAW_ORDERS_UPDATE,
  convertResult,
  convertParams,
} from 'app/common/api/requests/food/compensation/withdraw_orders/update/post'

import { api } from 'app/api'
import { ChangeStatusParams } from 'app/dataTypes/compensation/statement/status'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


type Options = {
  onChangeStatusSuccess?: (ID: string) => void
}

export function useStatementStatusesActions({ onChangeStatusSuccess }: Options = {}) {
  const [pendingIDs, setPendingIDs] = useState(new Set<string>())

  const onChangeStatusSuccessRef = useCallbackRef(onChangeStatusSuccess)

  const modifyPendingIDs = useCallback((action: 'add' | 'remove', ID: string) => {
    setPendingIDs((prev) => {
      const next = new Set(prev)
      if (action === 'add') next.add(ID)
      if (action === 'remove') next.delete(ID)
      return next
    })
  }, [])

  const changeStatus = useCallback(async (params: ChangeStatusParams) => {
    const { statementID } = params
    modifyPendingIDs('add', statementID)
    const res = await api.request(POST_COMPENSATION_WITHDRAW_ORDERS_UPDATE, {
      error: 'Не удалось изменить статус заявки',
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      onChangeStatusSuccessRef.current?.(statementID)
    }
    modifyPendingIDs('remove', statementID)

    return !!res
  }, [modifyPendingIDs, onChangeStatusSuccessRef])

  return {
    pendingIDs,
    changeStatus,
  }
}
