// @flow
import { defaultStateDataCurrent, type StateDataCurrent } from 'app/common/ui-components/filter';


const STUDENTS: 'Учащиеся' = 'Учащиеся';
const EMPLOYEES: 'Сотрудники' = 'Сотрудники';
const GUEST: 'Гость' = 'Гость';

export const customerTypes = { STUDENTS, EMPLOYEES, GUEST };
type Item = { id: string, title: string };

const students = {
  id: STUDENTS,
  title: 'Учащиеся',
};

const employees = {
  id: EMPLOYEES,
  title: 'Сотрудники',
};

const guests = {
  id: GUEST,
  title: 'Гость',
};

const customerTypeItems = (): [Array<Item>, StateDataCurrent] => (
  [[students, employees, guests], defaultStateDataCurrent]
);
export const onlyStudents = (): [Array<Item>, StateDataCurrent] => (
  [[students], defaultStateDataCurrent]
);
export const exceptGuests = (): [Array<Item>, StateDataCurrent] => (
  [[students, employees], defaultStateDataCurrent]
);

export default customerTypeItems;
