import { useState } from 'react'


export default function useType(groupsEnabled: boolean) {
  const [type, setType] = useState<'orgs' | 'orgGroups'>('orgs')

  return {
    type: groupsEnabled ? type : 'orgs',
    setType,
  }
}
