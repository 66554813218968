// @flow
import { useEffect, type Node } from 'react';

import useReportsEnabled from './useReportsEnabled';


type Props = {
  children: Node,
  report: string,
  fallback?: Node,
  onReportDisabled?: () => void,
}

const IsReportEnabled = ({ children, report, fallback, onReportDisabled }: Props) => {
  const [enabledReports, loading] = useReportsEnabled();
  const enabled = enabledReports.includes(report);

  useEffect(() => {
    if (onReportDisabled && !loading && !enabled) {
      onReportDisabled();
    }
  }, [loading, enabled]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading && fallback) {
    return (fallback);
  }

  if (loading || !enabled) {
    return null;
  }

  return (children);
};

export default IsReportEnabled;
