// @flow
import path from 'ramda/es/path';
import pipe from 'ramda/es/pipe';
import api from 'app/actions/api';
import { toCamelCase } from 'app/common/lib/object';


export const YEARS_FILTER_REQUEST = 'reports/YEARS_FILTER_REQUEST';
export const YEARS_FILTER_SUCCESS = 'reports/YEARS_FILTER_SUCCESS';
export const YEARS_FILTER_FAILURE = 'reports/YEARS_FILTER_FAILURE';
export const YEARS_FILTER_CLEAR = 'reports/YEARS_FILTER_CLEAR';
export const YEARS_FILTER_DELETE = 'reports/YEARS_FILTER_DELETE';
export const YEARS_FILTER_SET = 'reports/YEARS_FILTER_SET';
export const YEARS_FILTER_UPDATE = 'reports/YEARS_FILTER_UPDATE';


const yearsFilterRequest = () => ({
  type: YEARS_FILTER_REQUEST,
});

const yearsFilterSuccess = (payload: Object) => ({
  type: YEARS_FILTER_SUCCESS,
  payload,
});

const yearsFilterFailure = (payload: Object) => ({
  type: YEARS_FILTER_FAILURE,
  payload,
});

export const yearsFilterClear = () => ({
  type: YEARS_FILTER_CLEAR,
});

export const yearsFilterDelete = (payload: Array<string | number>) => ({
  type: YEARS_FILTER_DELETE,
  payload,
});

export const yearsFilterSet = (payload: Object) => ({
  type: YEARS_FILTER_SET,
  payload,
});

export const yearsFilterUpdate = (payload: Object) => ({
  type: YEARS_FILTER_UPDATE,
  payload,
});

export const yearsFilterFetch = (orgId: number) => async (dispatch: Function): Object => {
  const params = { school_id: orgId };
  dispatch(yearsFilterRequest());
  try {
    const res = await api('reports/schoolyears_list', 'get', { params });
    const data = pipe(
      path(['data', 'schoolyears']),
      toCamelCase,
    )(res);
    dispatch(yearsFilterSuccess(data));
    return data;
  } catch (error) {
    dispatch(yearsFilterFailure({ err: 'Не удалось получить данные учебных лет', error }));
    return null;
  }
};
