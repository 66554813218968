import cn from 'classnames'

import styles from './skeleton-item.scss'


type Props = {
  className?: string
  style?: { [key: string]: string }
}

const SkeletonItem = ({ className, style }: Props) => {
  return (
    <div
      className={cn(styles.root, className)}
      style={style}
    />
  )
}

export default SkeletonItem
