// @flow
import React from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';

import styles from './weekdays-grid.scss';


const cx = classNames.bind(styles);

type Props = {
  className?: string,
}

const WeekdaysGrid = ({ className }: Props) => {
  const weekdays = moment.weekdays(true);
  const weekdaysShort = moment.weekdaysShort(true);

  return (
    <div className={cx(styles.root, className)}>
      {weekdays.map((day, i) => (
        <abbr
          key={day}
          title={day}
          className={cx(styles.item, { holiday: i === 6 })}
        >
          {weekdaysShort[i]}
        </abbr>
      ))}
    </div>
  );
};

export default WeekdaysGrid;
