// @flow
import { useMemo } from 'react';
import { filter } from 'ramda';

import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';

import type { State as SelectionState } from '../../_selection/useSelectionState';


export default function useSelectedDishes(
  dishes: Array<Dish>,
  selection: SelectionState,
): Array<Dish> {
  const res = useMemo(() => {
    return filter(({ id }) => !!selection.get(id), dishes);
  }, [dishes, selection]);

  return res;
}
