// @flow
import { reportTypes as subReports } from 'app/common/api/requests/reports/perform';


const emptySubsidiesGrouped: 'subsidies_grouped' = 'subsidies_grouped';
const emptySubsidiesAct: 'subsidies_act' = 'subsidies_act';

const subreports = [subReports.REPORT_SUBSIDIES_DETAILED, emptySubsidiesGrouped, emptySubsidiesAct];
const subreportTitles = ['Детализированный', 'Сгруппированный', 'Акт'];
const subreportsNonEmpty = [subReports.REPORT_SUBSIDIES_DETAILED];

export const getSubReports = () => {
  return subreports;
};

export const getSubreportTitles = () => {
  return subreportTitles;
};

export const getNonEmptySubreports = () => {
  return subreportsNonEmpty;
};
