// @flow
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import always from 'ramda/src/always';
import T from 'ramda/src/T';


export const getPlaceholder = cond([
  [equals('kz'), always('+7 (___) ___-____')],
  [equals('kg'), always('+996 ___ ___ ___')],
  [equals('ua'), always('+380 __ ___ __ __')],
  [T, always('+7 (___) ___-__-__')],
]);

export const getPrefix = cond([
  [equals('kz'), always('+7 (')],
  [equals('kg'), always('+996 ')],
  [equals('ua'), always('+380 ')],
  [T, always('+7 (')],
]);
