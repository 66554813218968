import api from 'app/actions/api';

/**
/* отправляет изображение на сервер для временного хранения
*/

const postImage = async (rest) => {
  try {
    const { data } = await api('images/tmp', 'post', rest);
    return (data && data.file);
  } catch (error) {
    return '';
  }
};

export default postImage;
