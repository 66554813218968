import { headerProps } from './useHeaderColumnProps'


const columnProps = {
  ...headerProps,
}

export function useColumnProps() {
  return columnProps
}
