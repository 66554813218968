import React from 'react';

import styles from './controls-bar-space.scss';


const ControlsBarSpace = () => {
  return (
    <div className={styles.root} />
  );
};

export default ControlsBarSpace;
