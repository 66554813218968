// @flow
import React, { type Node, useMemo, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { map, prop, path } from 'ramda';

import usePagingExtended from 'app/common/hooks/usePagingExtended';
import type { Dishes, Params } from 'app/dataTypes/Dishes.types';

import AvailableDishesTable from './AvailableDishesTable';
import useAddRemoveState, { type Item } from './useAddRemoveState';
import useHandleAddRemove from './useHandleAddRemove';
import useDishes from './useDishes';
import { dataKey } from './AvailableDishesTable/dataKey';


type Props = {
  search: string,
  locked: boolean,
  alreadyAdded: Map<string, any>,
  disabledItems: Map<string, any>,
  onChangeItemsForAdd?: (Array<Item>) => void,
  onChangeItemsForRemove?: (Array<Item>) => void,
  onProductionLoaded?: (Array<string>) => void,
  singleSelectable?: boolean,
  externalRequest?: (Params) => Promise<Dishes | null>
}

const AvailableDishes = ({
  alreadyAdded,
  disabledItems,
  search,
  locked,
  onChangeItemsForAdd,
  onChangeItemsForRemove,
  onProductionLoaded,
  singleSelectable,
  externalRequest,
}: Props): Node => {
  const { rows: limit, ...columnSettings } = useSelector(path(['settings', dataKey]), shallowEqual);

  const paging = usePagingExtended(limit);
  const { offset } = paging;

  const {
    willAddItems,
    willRemoveItems,
    setItemAsAdded,
    setItemAsRemoved,
    restoreAsAdded,
    restoreAsNotAdded,
  } = useAddRemoveState(singleSelectable);

  const [dishes, total, loading] = useDishes({ limit, offset, search }, externalRequest);

  useEffect(() => {
    if (onProductionLoaded) {
      onProductionLoaded(map(prop('id'), dishes));
    }
  }, [dishes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheck = useHandleAddRemove({
    alreadyAdded,
    willAddItems,
    willRemoveItems,
    setItemAsAdded,
    setItemAsRemoved,
    restoreAsAdded,
    restoreAsNotAdded,
  });

  const willAddItemsArray = useMemo(() => Array.from(willAddItems.values()), [willAddItems]);
  const willRemoveItemsArray = useMemo(() => Array.from(willRemoveItems.values()), [willRemoveItems]);

  useEffect(() => {
    if (onChangeItemsForAdd) {
      onChangeItemsForAdd(willAddItemsArray);
    }
  }, [willAddItemsArray]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onChangeItemsForRemove) {
      onChangeItemsForRemove(willRemoveItemsArray);
    }
  }, [willRemoveItemsArray]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AvailableDishesTable
      alreadyAdded={alreadyAdded}
      willAddItems={willAddItems}
      willRemoveItems={willRemoveItems}
      disabledItems={disabledItems}
      dishes={dishes}
      loading={loading}
      disabled={locked}
      total={total}
      onChange={handleCheck}
      paging={paging}
      columnSettings={columnSettings}
      singleSelectable={singleSelectable}
    />
  );
};

export default AvailableDishes;
