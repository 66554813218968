// @flow
import { useCallback, useState, useRef } from 'react';

import { GET_ORG_GENERAL_SETTINGS, POST_ORG_GENERAL_SETTINGS } from 'app/common/api/requests/food/schools/general_settings';
import { api } from 'app/api';


export default function useGeneralSettings(orgId: string, setModified: (boolean) => void): {
  request: () => Promise<void>,
  submit: () => Promise<boolean>,
  cafeteriaEnabled: boolean,
  handleChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  } {
  const [cafeteriaEnabled, setCafeteriaEnabled] = useState(false);
  const cafeteriaEnabledRef = useRef(cafeteriaEnabled);
  cafeteriaEnabledRef.current = cafeteriaEnabled;

  const request = useCallback(async () => {
    const res = await api.request(GET_ORG_GENERAL_SETTINGS, {
      error: 'Не удалось получить настройки заведения',
      requestPathParams: { orgId },
    });
    if (res) {
      const { cafeteria_enabled: enabled } = res;
      setCafeteriaEnabled(enabled);
      setModified(false);
    }
  }, [orgId, setModified]);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setCafeteriaEnabled(checked);
    setModified(true);
  }, [setModified]);

  const submit = useCallback(async () => {
    const res = await api.request(POST_ORG_GENERAL_SETTINGS, {
      error: 'Не удалось сохранить настройки заведения',
      requestPathParams: { orgId },
      params: {
        settings: {
          cafeteria_enabled: cafeteriaEnabledRef.current,
        },
      },
    });
    if (res) {
      const { cafeteria_enabled: enabled } = res;
      setCafeteriaEnabled(enabled);
      setModified(false);
      return true;
    }
    return false;
  }, [orgId, setModified]);

  return {
    request,
    submit,
    cafeteriaEnabled,
    handleChange,
  };
}
