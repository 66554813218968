import classNames from 'classnames'

import ThreeDotsAnim from './three-dots-anim'
import styles from './dots-loader.scss'


type Props = {
  className?: string
  simple?: boolean
}

const DotsLoader = ({ className, simple }: Props) => (
  simple
    ? <ThreeDotsAnim className={classNames(styles.loader, className)} />
    : <div className={classNames(styles.root, className)}>
      <ThreeDotsAnim className={styles.loader} />
    </div>
)

export default DotsLoader
