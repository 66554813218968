export const DISHES_NEED_UPDATE = 'orgs/DISHES_NEED_UPDATE';
export const DISHES_FILTER_SET = 'orgs/DISHES_FILTER_SET';
export const DISHES_FILTER_CLEAR = 'orgs/DISHES_FILTER_CLEAR';
export const DISHES_SEARCH = 'orgs/DISHES_SEARCH';
export const DISHES_SET_PAGE = 'orgs/DISHES_SET_PAGE';

export const dishesNeedUpdate = payload => ({
  type: DISHES_NEED_UPDATE,
  payload,
});

export const dishesFilterSet = payload => ({
  type: DISHES_FILTER_SET,
  payload,
});

export const dishesFilterClear = () => ({
  type: DISHES_FILTER_CLEAR,
});

export const dishesSearch = payload => ({
  type: DISHES_SEARCH,
  payload,
});

export const dishesSetPage = payload => ({
  type: DISHES_SET_PAGE,
  payload,
});
