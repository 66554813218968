// @flow
import api from 'app/actions/api';

export const KIT_CLEAR_REQUEST = 'orgs/kits/KIT_CLEAR_REQUEST';
export const KIT_CLEAR_SUCCESS = 'orgs/kits/KIT_CLEAR_SUCCESS';
export const KIT_CLEAR_SUCCESS_FUTURE_ONLY = 'orgs/kits/KIT_CLEAR_SUCCESS_FUTURE_ONLY';
export const KIT_CLEAR_FAILURE = 'orgs/kits/KIT_CLEAR_FAILURE';

export const CHOOSEN_DATE = 'chosen_date';
export const FUTURE = 'future';
export const CHOSEN_DATE_AND_FUTURE = 'chosen_date_and_future';

export const kitClearRequest = (payload: Object) => ({
  type: KIT_CLEAR_REQUEST,
  payload,
});

export const kitClearSuccess = (payload: string | number) => ({
  type: KIT_CLEAR_SUCCESS,
  payload,
});

export const kitClearFutureOnlySuccess = (payload: string | number) => ({
  type: KIT_CLEAR_SUCCESS_FUTURE_ONLY,
  payload,
});

export const kitClearFailure = (payload: Object) => ({
  type: KIT_CLEAR_FAILURE,
  payload,
});

export type Extended = 'chosen_date' | 'future' | 'chosen_date_and_future';

type Params = {
  date: string,
  kitId: string | number,
  extended: Extended,
};

export const kitClear = (
  orgId: string | number,
  {
    date,
    kitId,
    extended,
  }: Params,
) => async (dispatch: Function) => {
  const dataToSend = {
    school_id: orgId,
    complex_id: kitId,
    date,
    extended,
  };
  dispatch(kitClearRequest(dataToSend));
  try {
    await api('food/complexes/clear', 'put', { data: dataToSend });
    if (extended === FUTURE) {
      dispatch(kitClearFutureOnlySuccess(kitId));
    } else {
      dispatch(kitClearSuccess(kitId));
    }
    return true;
  } catch (error) {
    dispatch(kitClearFailure({ err: 'Не удалось очистить комплект', error }));
    return false;
  }
};
