// @flow
import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import equals from 'ramda/es/equals';
import includes from 'ramda/es/includes';


type Options = {
  basePath: string,
  names: { [string]: string },
  shallow?: boolean,
  favorites: Array<string>,
  pathname: string,
  linkPropsByKey?: { [string]: Object },
}

export type Fav = {
  link: string,
  title: string,
  active: boolean,
  props?: Object,
}

export default function useFav({
  basePath,
  names,
  shallow,
  favorites,
  pathname,
  linkPropsByKey = {},
}: Options) {
  const favStatuses = useSelector(state => state.settings.fav, shallowEqual);

  const res: Array<Fav> = useMemo(() => {
    return pipe(
      filter(fav => favStatuses[fav]),
      map((fav): Fav => {
        const link = `${basePath}/${fav}`;

        return ({
          title: names[fav],
          link,
          active: (
            shallow
              ? includes(link, pathname)
              : equals(link, pathname)
          ),
          ...(linkPropsByKey[fav] ? { props: linkPropsByKey[fav] } : Object.freeze({})),
        });
      }),
    )(favorites);
  }, [basePath, favStatuses, names, pathname, shallow, favorites, linkPropsByKey]);

  return res;
}
