// @flow
import { useMemo } from 'react';
import { reduce } from 'ramda';

import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Categories, Category } from 'app/dataTypes/vending/production/Categories.types';


export default function useRelatedCategories(
  selectedDishes: Array<Dish>,
  categories: Categories,
): Map<string, Category> {
  const res = useMemo(() => {
    const categoryIdsRelatedMap = reduce((acc, { categoryId }) => {
      acc[categoryId] = true;
      return acc;
    }, {}, selectedDishes);

    return reduce((acc, val) => {
      const { id } = val;
      if (categoryIdsRelatedMap[id]) {
        acc.set(id, val);
      }
      return acc;
    }, new Map(), categories);
  }, [selectedDishes, categories]);

  return res;
}
