// @flow

type Options = {
  report?: string,
  subReport?: string,
}

const emptyOptions: Options = Object.freeze({});

const getBaseUrl = (options: Options = emptyOptions) => {
  const { report, subReport } = options;

  if (report && subReport) {
    return `/reports/${report}/${subReport}`;
  }

  if (report) {
    return `/reports/${report}`;
  }

  return '/reports';
};

export default getBaseUrl;
