// @flow
import { paymentOrderPurposes } from 'app/dataTypes/requisites';


export const paymentOrderPurposeItems = [{
  id: paymentOrderPurposes.ActivityKind,
  title: 'Вид деятельности',
}, {
  id: paymentOrderPurposes.FoodType,
  title: 'Вид питания',
}, {
  id: paymentOrderPurposes.ClassGroup,
  title: 'Класс/группа',
}, {
  id: paymentOrderPurposes.OrgName,
  title: 'Наименование заведения',
}, {
  id: paymentOrderPurposes.PersonalAccount,
  title: 'Лицевой счет',
}];
