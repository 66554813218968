// @flow
import { useCallback, useRef } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import { extractIndexByBase } from './helpers';


/**
 *  Подсветка столбцов таблицы при наведении
 *  Можно использовать для строк
 *  НЕ поддерживает перманентно выделенные строки! (для этого есть useHover)
 *
 */

export default function useHoverByClass(classNameBase: string) {
  const savedItems = useRef([]);

  const handleMouseEnter = useCallback((e: SyntheticMouseEvent<HTMLElement>) => {
    const colIndex = extractIndexByBase(classNameBase, e.currentTarget.className);
    if (colIndex === null) {
      console.error(`Не удалось узнать индекс строки таблицы (устновите класс "${classNameBase}" у Td, Th элементов)`);
      return;
    }

    const columnCells = document.querySelectorAll(`.${classNameBase}${colIndex}`);
    savedItems.current = columnCells;

    for (let i = 0, l = columnCells.length; i < l; i++) {
      if (columnCells[i]) {
        columnCells[i].classList.add('hover');
      }
    }
  }, [classNameBase]);


  const handleMouseLeave = useCallback((e: SyntheticMouseEvent<HTMLElement>) => {
    const colIndex = extractIndexByBase(classNameBase, e.currentTarget.className);
    if (colIndex === null) {
      console.error(`Не удалось узнать индекс строки таблицы (устновите класс "${classNameBase}" у Td, Th элементов)`);
      return;
    }

    const columnCells = savedItems.current && !isEmpty(savedItems.current)
      ? savedItems.current
      : document.querySelectorAll(`.${classNameBase}${colIndex}`);

    for (let i = 0, l = columnCells.length; i < l; i++) {
      if (columnCells[i]) {
        columnCells[i].classList.remove('hover');
      }
    }
  }, [classNameBase]);

  return [handleMouseEnter, handleMouseLeave];
}
