// @flow

const groupItems = () => ([[{
  id: 'by_groups_and_types',
  title: 'типам пользователя и классам',
}, {
  id: 'by_purchases',
  title: 'продажам',
}, {
  id: 'by_items_and_complexes',
  title: 'продукции и комплектам',
}, {
  id: 'by_items_and_complexes_and_cost',
  title: 'стоимости продукции и комплектам',
}, {
  id: 'by_group_complexes',
  title: 'комплектам (групповой талон)',
}, {
  id: 'by_camp_complexes',
  title: 'комплектам лагеря (групповой талон)',
}], ['by_groups_and_types']]);

export default groupItems;
