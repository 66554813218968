import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react'
import cn from 'classnames/bind'

import Panel from './panel'
import useFancyStyles, { emptyStyle, emptyStyleBorder } from './use-fancy-styles'
import PanelHeader from './panel-header'

import styles from './plain-accordion.scss'


const cx = cn.bind(styles)

type Props = {
  className?: string
  items?: Array<{ ID: string, title: string | ReactNode }>
  locked?: boolean | Set<string>
  panelContent?: (props: {
    ID: string,
    active: boolean,
    updateSize: () => void,
    setActiveID: Dispatch<SetStateAction<string | null>>,
    closeActivePanel: () => void
  }) => ReactNode
  headerContent?: (props: {
    ID: string,
    active: boolean,
    hover: boolean,
    setActiveID: Dispatch<SetStateAction<string | null>>
    closeActivePanel: () => void
  }) => ReactNode
  fancyColor?: string
  fancyColorTitle?: string
  getHeaderClassName?: (params: { active: boolean }) => string | undefined
  getContentClassName?: (params: { active: boolean }) => string | undefined
  getItemClassName?: (params: { active: boolean }) => string | undefined
}

const PlainAccordion = ({
  className,
  items = [],
  locked,
  panelContent = () => <div>{'Нет данных'}</div>,
  headerContent,
  fancyColor,
  fancyColorTitle,
  getHeaderClassName,
  getContentClassName,
  getItemClassName,
}: Props) => {
  const [activeID, setActiveID] = useState<string | null>(null)

  const closeActivePanel = useCallback(() => {
    setActiveID(null)
  }, [])

  const {
    fancyStyleHeader,
    facyStylePanel,
    fancyStyleTitle,
  } = useFancyStyles(fancyColor, fancyColorTitle)

  const handleClickHeader = useCallback((ID: string) => {
    setActiveID(current => (current === ID ? null : ID))
  }, [])

  return (
    <ul className={cx(styles.root, className)}>
      {items.map(({ ID, title }) => {
        const active = activeID === ID
        const itemLocked = (
          typeof locked === 'boolean'
            ? locked
            : locked?.has(ID)
        )

        return (
          <li
            key={ID}
            className={cx(styles.li, { notActive: !active }, getItemClassName?.({ active }))}
          >
            <PanelHeader
              ID={ID}
              title={title}
              onClick={handleClickHeader}
              locked={itemLocked}
              className={cx(
                styles.header,
                { active, locked: itemLocked },
                getHeaderClassName?.({ active })
              )}
              titleStyle={active && fancyColor ? fancyStyleTitle : emptyStyle}
              style={active && fancyColor ? fancyStyleHeader : emptyStyleBorder}
            >
              {hover => headerContent?.({
                ID,
                active,
                hover,
                setActiveID,
                closeActivePanel,
              })}
            </PanelHeader>

            <Panel
              active={active}
              className={getContentClassName?.({ active })}
              style={active && fancyColor ? facyStylePanel : emptyStyleBorder}
            >
              {(activeActual, updateSize) => panelContent({
                ID,
                active: activeActual,
                updateSize,
                setActiveID,
                closeActivePanel,
              })}
            </Panel>
          </li>
        )
      })}
    </ul>
  )
}

export default PlainAccordion
