// @flow
import filter from 'ramda/es/filter';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import head from 'ramda/es/head';
import toUpper from 'ramda/es/toUpper';
import trim from 'ramda/es/trim';
import join from 'ramda/es/join';
import curry from 'ramda/es/curry';
import prop from 'ramda/es/prop';
import __ from 'ramda/es/__';


export type Name = {
  first?: string,
  last?: string,
  middle?: string,
  gender?: 'male' | 'female' | 'androgynous',
}

const defMaper = name => pipe(prop(__, name), trim);

const oneLetter = word => `${toUpper(head(trim(word)))}.`;

const makeNameStringBy = curry((keys, cb, name) => {
  if (!name || typeof name !== 'object') return '';
  return pipe(
    filter(key => name[key]),
    map(cb(name)),
    join(' '),
  )(keys) || '???';
});

const lastFirstM = curry((name, key) => (
  (key === 'middle')
    ? oneLetter(name[key])
    : trim(name[key])
));

export const getLastFirstM: (name: { first: string, last: string, middle: string }) => string = (
  makeNameStringBy(['last', 'first', 'middle'], lastFirstM)
);

export const getLastFirst = makeNameStringBy(['last', 'first'], defMaper);
export const getFirstLastName = makeNameStringBy(['first', 'last'], defMaper);
export const getFIO = makeNameStringBy(['last', 'first', 'middle'], defMaper);

const lastFM = curry((name, key) => (
  (key === 'first' || key === 'middle')
    ? oneLetter(name[key])
    : trim(name[key])
));

export const getLastFM = makeNameStringBy(['last', 'first', 'middle'], lastFM);

// export const getFIO2 = name => (name && [name.lastname, name.firstname, name.middlename].join(' ').trim());
