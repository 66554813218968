// @flow
import { fundTypes } from 'app/common/api/requests/reports/perform';


export { fundTypes };

const fundItems = () => ([[{
  id: fundTypes.FUND_BUDGET,
  title: 'Бюджетные средства',
}, {
  id: fundTypes.FUND_PARENTS,
  title: 'Родительские средства',
}], [fundTypes.FUND_BUDGET]]);

export default fundItems;
