// @flow
import React, { type ElementType, type ElementRef } from 'react';
import classNames from 'classnames/bind';

import Link from 'app/common/lib/link';
import nope from 'app/common/lib/nope';
import styles from './Button.scss';


const cx = classNames.bind(styles);
const renderContent = (children: any) => ((children && children instanceof Array)
  ? children.map((child, i) => (<span key={i}>{child}</span>))
  : <span>{children}</span>);

type Props = {
  disabled?: boolean,
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
  value?: string,
  error?: boolean,
  warning?: boolean,
  outline?: boolean,
  reject?: boolean,
  certColor?: number,
  href?: string,
  className?: string,
  style?: Object,
  children?: any,
  submit?: boolean,
  small?: boolean,
  handleClick?: (e: SyntheticEvent<HTMLElement>) => void,
  autoFocus?: boolean,
  component?: ElementType,
};

const defaultProps = {
  disabled: false,
  error: false,
  warning: false,
  outline: false,
  reject: false,
  small: false,
  autoFocus: false,
};

export default class Button extends React.Component<Props> {
  static defaultProps = defaultProps;

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus && this.button) {
      this.button.focus();
    }
  }

  // TODO удалить и не использовать
  handleClick = (event: SyntheticEvent<HTMLElement>) => {
    const { onClick, disabled } = this.props;
    if (onClick && typeof onClick === 'function') {
      event.preventDefault();
      onClick(event);
    }
    if (disabled) {
      event.preventDefault();
    }
  };

  buttonRef = (ref: ElementRef<'button' | 'a'> | null) => {
    this.button = ref;
  };

  button: ElementRef<'button' | 'a'> | null; // eslint-disable-line react/sort-comp

  render() {
    const {
      disabled,
      onClick,
      children,
      value,
      className,
      style,
      href,
      outline,
      reject,
      error,
      warning,
      certColor,
      submit,
      small,
      handleClick,
      component,
      ...other
    } = this.props;
    const def = !(disabled || outline || reject || error || warning || certColor);
    const Component = component || (submit ? 'button' : Link);

    // тест на наличие handleClick
    if (handleClick) {
      console.log('!depricated call of handleClick!');
    }

    return (
      <Component
        className={cx({
          root: true,
          disabled,
          error,
          warning,
          outline,
          reject,
          [`color${certColor || ''}`]: !!certColor, // TODO вынести в отд. компонент (исп. только в голосовании)
          def,
          small,
        }, className)}
        style={style}
        to={Component === Link ? (href || '') : undefined}
        href={Component !== Link ? (href || '') : undefined}
        onClick={disabled ? nope : (onClick || handleClick)}
        type={submit && 'submit'}
        ref={this.buttonRef}
        // $FlowFixMe
        {...other}
      >
        {renderContent(value || children)}
      </Component>
    );
  }
}
