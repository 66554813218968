import { createSelector } from 'reselect';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import slice from 'ramda/es/slice';

import toFixedValue from 'app/common/lib/toFixedValue';
import { isSplits, mkSplitSum } from 'app/actions/reports/subsidies/data';


/**
 * Селектор актуальных данных учеников
 *  1. выбор только для текущей страницы,
 *  2. преобразование стоимости в строку с 2мя точками после запятой
 */

export type StudentType = {
  idx: number,
  title: string,
  classTitle: string,
  data: Array<Object>,
  total: string,
  // orders: Array<Object>, // удалено отсюда, но остается в состоянии
  ordersNum: number,
}

const selectActualStudents = createSelector(
  [
    ({ students }) => students,
    ({ currentPage, rows }) => ((currentPage - 1) * rows),
    ({ currentPage, rows }) => (currentPage * rows),
  ],
  (students, from, to) => pipe(
    slice(from, to),
    map(({ total, split1, split2, ...others }) => ({
      ...others,
      total: isSplits(split1, split2)
        ? mkSplitSum(split1, split2)
        : toFixedValue(total),
    }: StudentType)),
  )(students),
);

export default selectActualStudents;
