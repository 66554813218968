// @flow
import { type Node, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { equals, map } from 'ramda';
import { useLocalStorage } from 'react-use';

import simpleHash from 'app/common/lib/simpleHash';
import { name as emptyName } from 'app/common/reducers/user';


const emptyNameHashed = map(simpleHash, emptyName);

type Props = {
  onAnotherUser: () => void,
}

const AnotherUserLoggedIn = ({ onAnotherUser }: Props): Node => {
  const { loading, name: currentUser } = useSelector(state => state.user, shallowEqual);

  const [lastUserHashed, setLastUserHashed] = useLocalStorage('dernier');

  useEffect(() => {
    if (loading) return;
    const currentUserHashed = map(simpleHash, currentUser);

    if (
      lastUserHashed
      && !equals(currentUserHashed, emptyNameHashed)
      && !equals(lastUserHashed, currentUserHashed)
    ) {
      onAnotherUser();
    }
    setLastUserHashed(currentUserHashed);
  }, [currentUser, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default AnotherUserLoggedIn;
