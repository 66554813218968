// @flow
import moment from 'moment-timezone';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';

import { type State } from 'app/common/ui-components/filter';
import { dateFormat } from 'app/common/ui-next/plain-date-period';

/**
 *  Данные для элемента фильтрации "Период времени" (filterId: 'period')
 */

const filterGetItemsPeriod = (state: State) => {
  const { year: { items, current } } = state;
  const selectedYearId = prop(0, current);
  const dateStart = path([selectedYearId, 'date_start'], items);
  const dateFinish = path([selectedYearId, 'date_finish'], items);
  const mDateStart = moment(dateStart);
  const mDateFinish = moment(dateFinish);

  const mToday = moment();

  const selectedDate = (
    mToday.isBetween(mDateStart, mDateFinish, 'day', [])
      ? mToday.format(dateFormat)
      : mDateFinish.format(dateFormat)
  );

  const period = [selectedDate, selectedDate];

  return ([period, period]);
};

export default filterGetItemsPeriod;
