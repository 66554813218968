import { controls } from './formParts/IncludeForm'


export const checkErrorsInclude = (err: Map<any, any>, formData: FormData) => {
  if (!(
    formData.get(`${controls.saturdays}1`)
    || formData.get(`${controls.saturdays}2`)
  )) {
    err.set('include', 'Выберите хотя бы один пункт')
  }
}
