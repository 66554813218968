// @flow
import React, { useCallback, useEffect, useState } from 'react';
import pipe from 'ramda/es/pipe';
import toPairs from 'ramda/es/toPairs';
import filter from 'ramda/es/filter';
import map from 'ramda/es/map';
import values from 'ramda/es/values';
import { useDispatch } from 'react-redux';

import { checkStatuses, identifiers as identifiersTypesNames } from 'app/common/constants/identifiers';

import { type CheckResult } from './entering-identifier';
import { type IdentifierTypeInfo } from './entering-identifier/select-type-form';
import { type IdentifierFormat } from './entering-identifier/entering-form';
import AddingIdentifier from './adding-identifier';


type Params = {
  type: $Keys<typeof identifiersTypesNames>,
  number: string,
}

type AvailabilityResult = {
  types: ?{
    [key: string]: IdentifierTypeInfo,
  },
  formats: ?{
    [key: IdentifierFormat]: boolean,
  },
}

type Props = {
  personId: number,
  onClose: (e?: SyntheticEvent<HTMLElement>) => void,
  checkRequest?: (personId: number, number: string) => Promise<CheckResult>,
  addIdentifierSubmit?: (personId: number, params: Params) => Promise<boolean>, // deprecated
  availabilityFetch?: (personId: number) => Promise<AvailabilityResult>,
  onCheckIdentifier?: (personId: number, number: string) => Promise<CheckResult>,
  onAddIdentifier?: (personId: number, params: Params) => Promise<boolean>, // deprecated
  onFetchAvailability?: (personId: number) => Promise<AvailabilityResult>,
}

const AddIdentifierForm = ({
  personId,
  onClose,
  checkRequest,
  addIdentifierSubmit,
  availabilityFetch,
  onAddIdentifier,
  onCheckIdentifier,
  onFetchAvailability,
}: Props) => {
  const dispatch = useDispatch();

  const [types, setTypes] = useState([]);
  const [formats, setFormats] = useState([]);
  const [loading, setLoading] = useState(true);

  const requestAvailabitity = useCallback(async (...params) => {
    if (availabilityFetch) {
      const res = await dispatch(availabilityFetch(...params));
      return res;
    }
    if (onFetchAvailability) {
      const res = await onFetchAvailability(...params);
      return res;
    }
    console.error('Не передан обязательный параметр availabilityFetch (redux) или onFetchAvailability');
    return { types: null, formats: null };
  }, [availabilityFetch, onFetchAvailability, dispatch]);

  useEffect(() => {
    const run = async () => {
      const { types: typesRes, formats: formatsRes } = await requestAvailabitity(personId);
      if (typesRes && formatsRes) {
        setTypes(values(typesRes));
        setFormats(pipe(
          toPairs,
          filter(([_, value]) => value), // eslint-disable-line no-unused-vars
          map(([key]) => key),
        )(formatsRes));
      }
      setLoading(false);
    };
    run();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleIdentifierCheck = useCallback(async (...params) => {
    if (checkRequest) {
      const res = await dispatch(checkRequest(...params));
      return res;
    }
    if (onCheckIdentifier) {
      const res = await onCheckIdentifier(...params);
      return res;
    }
    console.error('Не передан обязательный параметр checkRequest (redux) или onCheckIdentifier');
    return { error: checkStatuses.UNKNOWN };
  }, [dispatch, checkRequest, onCheckIdentifier]);

  // deprecated
  const handleIdentifierSubmit = useCallback(async (...params) => {
    if (addIdentifierSubmit) {
      const res = await dispatch(addIdentifierSubmit(...params));
      return res;
    }
    if (onAddIdentifier) {
      const res = onAddIdentifier(...params);
      return res;
    }
    return false;
  }, [dispatch, addIdentifierSubmit, onAddIdentifier]);

  return (
    <AddingIdentifier
      loading={loading}
      types={types}
      availableFormats={formats}
      personId={personId}
      onCancel={onClose}
      onIdentifierCheck={handleIdentifierCheck}
      onIdentifierSubmit={onAddIdentifier || addIdentifierSubmit ? handleIdentifierSubmit : undefined}
    />
  );
};

export default AddIdentifierForm;
