// @flow
import React, { useMemo, Fragment } from 'react';
import splitEvery from 'ramda/es/splitEvery';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { SummaryItem as SummaryItemType } from './useReportData';


type Props = {
  summary: Array<SummaryItemType>,
  loading: boolean,
  currencySign: string,
}

const Summary = ({ summary, loading, currencySign }: Props) => {
  const rows = useMemo(() => {
    return pipe(
      map(({ title, sum }) => ([title, `${toFixedValue(sum)} ${currencySign}`])),
      splitEvery(3),
    )(summary);
  }, [summary, currencySign]);

  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
              loading={loading || !summary}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default Summary;
