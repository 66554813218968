// @flow

export const ids: [
  'title',
  'cost',
  'count',
  'total',
] = [
  'title',
  'cost',
  'count',
  'total',
];

export const getTitles = (currency: string) => ([
  'Наименование',
  `Цена,\u00A0${currency}`,
  'Количество',
  `Стоимость,\u00A0${currency}`,
]);
