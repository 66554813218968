import { ChangeEvent, useCallback } from 'react'

import PlainInput from 'app/common/ui-next/plain-input'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


type Props = {
  index: number
  value: string
  disabled?: boolean
  error?: boolean
  onChange: (index: number, value: string) => void
  onBlur?: () => void
  onFocus?: () => void
}

const SupportContactFormValue = ({ index, value, disabled, error, onChange, onBlur, onFocus }: Props) => {
  const onChangeRef = useCallbackRef(onChange)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value: val } = e.currentTarget
    onChangeRef.current?.(index, val)
  }, [index, onChangeRef])

  return (
    <PlainInput
      value={value}
      disabled={disabled}
      error={error}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  )
}

export default SupportContactFormValue
