// @flow
import React from 'react';

import { FilterDataProvider } from 'app/common/ui-components/filter';

import VendingMain from './VendingMain';
import filterItems from './filter/filterItems';


type Props = {
  onRedirectToVendingMachine: (id: string, section?: string) => void,
}

const Vending = ({ onRedirectToVendingMachine }: Props) => {
  return (
    <FilterDataProvider filters={filterItems}>
      <VendingMain onRedirectToVendingMachine={onRedirectToVendingMachine} />
    </FilterDataProvider>
  );
};

export default Vending;
