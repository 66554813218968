// @flow
import type { DetailsGroup } from 'app/common/api/requests/reports/perform';

import accountItems from './accountItems';
import accountActivity from './accountActivity';
import { filterId } from './filterId';
import * as flags from './flags';


export default filterId;
export { accountItems, accountActivity, flags };
export type { DetailsGroup };
