// @flow


export const foodModes = Object.freeze({
  Breakfast: 'breakfast',
  Dinner: 'dinner',
  Afternoon: 'afternoon',
});

export type FoodMode = $Values<typeof foodModes>
export const allFoodModes: Array<FoodMode> = [foodModes.Breakfast, foodModes.Dinner, foodModes.Afternoon];
