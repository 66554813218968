import { ReactNode, useState, useCallback, useMemo, useEffect, MouseEvent } from 'react'
import cn from 'classnames/bind'
import not from 'ramda/es/not'

import useCallbackRef from 'app/common/hooks/useCallbackRef'

import useEvents from './use-events'
import PlainSurface from '../plain-surface'
import useCorrectPosition from './use-correct-position'
import Portal from './portal'

import styles from './plain-dropdown-surface.scss'

/**
 *  Выпадающий элемент в виде плоскости
 */

const cx = cn.bind(styles)

export enum SurfaceType {
  Dialog = 'dialog',
  Menu = 'menu',
  Listbox = 'listbox',
  Tree = 'tree',
  Grid = 'grid'
}

type AreaProps = {
  'aria-haspopup': SurfaceType
  'aria-expanded': 'true' | 'false'
}

export type Props = {
  // children - должен возвращать елемент, который открывает/закрывает surface
  children: (
    onClick: () => void,
    opened: boolean,
    areaProps: AreaProps
  ) => ReactNode
  className?: string
  id: string,
  type: SurfaceType
  surfaceContent: (
    | ReactNode
    | ((toggle: (event?: MouseEvent<HTMLElement>) => void, opened: boolean) => ReactNode)
    // ! Не нужно передавать event в toggle для елементов 'Link' или 'a',
    // так как это приведет к закрытию dropdown до того как успеет срабоать ссылка
  )
  surfaceContentClassName?: string
  containerComponent?: keyof JSX.IntrinsicElements
  buttonContainerClassName?: string
  ariaLabelledby?: string
  ariaDescribedby?: string
  contentPadding?: number // TODO -> theme
  onOpenState?: (state: boolean) => void
}

const PlainDropDownSurface = ({
  children,
  className,
  id,
  type,
  surfaceContent,
  surfaceContentClassName,
  containerComponent = 'div',
  buttonContainerClassName,
  ariaLabelledby,
  ariaDescribedby,
  contentPadding = 25,
  onOpenState,
}: Props) => {
  const [opened, setOpened] = useState(false)

  const onOpenStateRef = useCallbackRef(onOpenState)

  useEffect(() => {
    onOpenStateRef.current?.(opened)
  }, [opened])

  const handleToggle = useCallback((e?: MouseEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setOpened(not)
  }, [])

  const handleCollapse = useCallback(() => {
    setOpened(false)
  }, [])


  const rootId = `_${id}`
  useEvents({ id: rootId, onCollapse: handleCollapse })


  const {
    buttonRef,
    surfaceRef,
    buttonCopyRef,
    classes,
  } = useCorrectPosition(contentPadding)


  const buttonAreaProps: AreaProps = useMemo(() => ({
    'aria-haspopup': type,
    'aria-expanded': opened ? 'true' : 'false',
  }), [type, opened])

  const surfaceClassName = cx(
    styles.surface,
    classes,
    surfaceContentClassName,
  )

  const button = (
    <span
      ref={buttonRef}
      className={cx({ opened }, styles.buttonContainer, buttonContainerClassName)}
    >
      {children(handleToggle, opened, buttonAreaProps)}
    </span>
  )

  return (
    <span className={cx(styles.root, className)} id={rootId}>
      {opened
        && <Portal>
          <PlainSurface
            id={rootId}
            containerComponent={containerComponent}
            className={cx({ opened }, surfaceClassName)}
            ref={surfaceRef}
            role={type}
            contentPadding={contentPadding}
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedby}
          >
            <div className={styles.surfaceContent}>
              {typeof surfaceContent === 'function'
                ? surfaceContent(handleToggle, opened)
                : surfaceContent}
              <span className={styles.buttonCopy} ref={buttonCopyRef}>
                {children(handleToggle, opened, buttonAreaProps)}
              </span>
            </div>
          </PlainSurface>
        </Portal>}
      {button}
    </span>
  )
}

export default PlainDropDownSurface
