// @flow
import React, { Fragment, useState, useCallback, type Node } from 'react';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import Modal, { ModalHeader } from 'app/common/components/Modal';

import FundReturn from './fund-return';
import FundReturnHistory from './fund-return-history';


type Props = {
  children: Node,
  customerId: number,
  name: string,
  group: string,
  onRefund?: () => void,
}

const FundReturnModal = ({ children, customerId, name, group, onRefund }: Props) => {
  const [open, setOpen] = useState(false);
  const [invalidateHistory, setInvalidateHistory] = useState();

  const handleToggleModal = useCallback(() => {
    setOpen(state => !state);
  }, []);

  const handleRefund = useCallback(() => {
    setInvalidateHistory(state => !state);
    if (onRefund) {
      onRefund();
    }
  }, [onRefund]);

  return (
    <Fragment>
      <PlainButtonAsLink onClick={handleToggleModal}>
        {children}
      </PlainButtonAsLink>
      {open && <Modal toggleModal={handleToggleModal}>
        <ModalHeader>
          {`Возврат средств — ${name}${group ? ', ' : ''}${group}`}
        </ModalHeader>

        <FundReturn customerId={customerId} onRefund={handleRefund} />
        <FundReturnHistory customerId={customerId} invalidateHistory={invalidateHistory} />

      </Modal>}
    </Fragment>
  );
};

export default FundReturnModal;
