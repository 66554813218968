// @flow
import React, { Fragment } from 'react';
import sort from 'ramda/es/sort';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';
import isEmpty from 'ramda/es/isEmpty';

import { PlainItemsSelector } from 'app/common/ui-next/plain-selector';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import SubHeader from './SubHeader';
import useKits from './useKits';


const sortByTitle = sort(ascend(prop('title')));
const defaultSelection = [];

type Props = {
  orgId: number,
  date: string,
  currentKitID: string,
  selection: Array<string>,
  error?: string,
  disabled: boolean,
  onTargetKitChange: (x: Array<string>) => void,
}

const CopyKitFormOtherKit = ({
  orgId,
  date,
  currentKitID,
  selection,
  error,
  disabled,
  onTargetKitChange,
}: Props) => {
  const [kits, loading] = useKits({ orgId, date, omitID: currentKitID });

  const nothing = !loading && isEmpty(kits);

  return (
    <Fragment>
      <SubHeader>
        {'Выберите комплект'}
      </SubHeader>

      {!nothing
        && <PlainItemsSelector
          label="В комплект"
          header="Выберите куда копировать меню"
          pleaseSelectText="Выберите комплект"
          loading={loading}
          disabled={disabled}
          selectedAllOnEmptySelection={false}
          items={kits}
          selection={selection}
          defaultSelection={defaultSelection}
          onChange={onTargetKitChange}
          sortFunc={sortByTitle}
        />}

      {error
        && <PlainHelperText overflowVisible error>
          {error}
        </PlainHelperText>}
    </Fragment>
  );
};

export default CopyKitFormOtherKit;
