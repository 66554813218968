// @flow
import React, { Fragment, type Node } from 'react';
import classNames from 'classnames';

import { DotsLoader } from 'app/common/components/Loader';

import styles from './option-prop.scss';


export type OptionPropProps = {
  title: string,
  children: Node,
  className?: string,
  loading?: boolean,
}

const OptionProp = ({ title, children, className, loading }: OptionPropProps) => (
  <Fragment>
    <dt className={classNames(styles.dt, className)}>{title}</dt>
    <dd className={styles.dd}>
      {loading
        ? <DotsLoader simple />
        : children}
    </dd>
  </Fragment>
);

export default OptionProp;
