// @flow
import React from 'react';
import cn from 'classnames/bind';

import styles from './EmptyWarn.scss';


const cx = cn.bind(styles);

type Props = {
  children: string,
  className?: string,
  skipLeftPadding?: boolean,
  skipPadding?: boolean,
}

const EmptyWarn = ({ children, className, skipLeftPadding, skipPadding }: Props) => (
  <div className={cx(styles.root, { skipLeftPadding, skipPadding }, className)}>
    {children}
  </div>
);

export default EmptyWarn;
