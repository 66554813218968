
// @flow
import moment from 'moment-timezone';
import path from 'ramda/es/path';
import evolve from 'ramda/es/evolve';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import length from 'ramda/es/length';
import addIndex from 'ramda/es/addIndex';

import api from 'app/actions/api';
import { getLastFirst } from 'app/common/lib/names';
import action from 'app/common/lib/action';
import toFixedValue from 'app/common/lib/toFixedValue';


export const SUBSIDIES_DATA_REQUEST = 'reports/SUBSIDIES_DATA_REQUEST';
export const SUBSIDIES_DATA_SUCCESS = 'reports/SUBSIDIES_DATA_SUCCESS';
export const SUBSIDIES_DATA_FAILURE = 'reports/SUBSIDIES_DATA_FAILURE';
export const SUBSIDIES_DATA_CLEAR = 'reports/SUBSIDIES_DATA_CLEAR';

const dateFormat = 'YYYY-MM-DD';

/**
 * HELPERS
 */

// map с индексом
const mapIdx = addIndex(map);

// проверка на сумму с разделением
export const isSplits = (split1: number | string, split2: number | string) => ((split1 || split1 === 0) && (split2 || split2 === 0));

// создание сдвоенной суммы с разделением
export const mkSplitSum = (split1: number | string, split2: number | string) => (`${toFixedValue(split1) || '??'}/${toFixedValue(split2) || '??'}`);

// создание дат для заголовка центральной таблицы
const makeDateTitle = (date, month, year) => {
  if (date) {
    return moment(date, dateFormat).format('DD.MM');
  }
  if (month && year) {
    return moment(`${year}-${month}`, 'YYYY-MM').format('MMMM YYYY');
    // const sMonth = String(month);
    // return `${year}.${length(sMonth) > 1 ? sMonth : `0${sMonth}`}`;
  }
  return '??.??';
};

/**
 * Конвертация перед помещением в сотояние
 */

// функция преобразования данных
const mapData = mapIdx(({ count, sum, split1: s1, split2: s2 }, j) => ({
  idx: j,
  // данные с суммами конвертируем в строки
  // т.к. нет необходимости в их оригинальном виде виде
  // для сортировки например
  sum: isSplits(s1, s2)
    ? mkSplitSum(s1, s2)
    : toFixedValue(sum),
  count: count ? String(count) : '0',
}));

// функция преобразования справок
const mapDataOrders = mapIdx(({ ...props }, j) => ({ idx: j, ...props }));

// Функция преобразования студентов
const mapStudents = mapIdx(({
  student_name: name,
  klass_title: classTitle,
  data,
  orders,
  total,
  split1,
  split2,
}, i) => ({
  idx: i,
  name, // необходимо для диалогового окна о текущих справках
  title: getLastFirst(name), // Фамилия Имя
  classTitle,
  data: mapData(data),
  orders: mapDataOrders(orders),
  ordersNum: length(orders),
  total: parseFloat(total),
  split1: parseFloat(split1),
  split2: parseFloat(split2),
}));

// Функция преобразования классов
const mapClasses = mapIdx(({
  klass_title: classTitle,
  data,
  total,
  split1,
  split2,
}, i) => ({
  idx: i,
  classTitle,
  data: mapData(data),
  total: parseFloat(total),
  split1: parseFloat(split1),
  split2: parseFloat(split2),
}));

// Функция преобразования школ
const mapSchools = mapIdx(({
  school_title: schoolTitle,
  data,
  total,
  split1,
  split2,
}, i) => ({
  idx: i,
  schoolTitle,
  data: mapData(data),
  total: parseFloat(total),
  split1: parseFloat(split1),
  split2: parseFloat(split2),
}));

// Функция преобразования данных итоговой строки
export type TotalType = {
  idx: number,
  title: string,
  sum: string,
  split1?: string,
  split2?: string,
  count: string,
}
const mapTotal = mapIdx(({
  date,
  month,
  year,
  sum,
  split1,
  split2,
  count,
}, i) => {
  const countString = count ? String(count) : '0';
  return ({
    idx: i,
    title: makeDateTitle(date, month, year),
    sum: isSplits(split1, split2)
      ? mkSplitSum(split1, split2)
      : `${toFixedValue(sum)} (${countString})`,
    count: countString,
  }: TotalType);
});

// Преобразование всего
const convertResult = pipe(
  path(['data', 'data']),
  (data) => {
    const { klasses_and_orders: klassesAndOrders } = data;
    if (!klassesAndOrders) { // преобразование данных только для группировки не по классам и справкам
      // из-за того что totals так же есть в случае этой группировки и его не нужно преобразовывать
      // там другой формат
      return evolve({
        // данные по ученикам
        students: mapStudents,
        // данные по классам
        klasses: mapClasses,
        // данные по школам
        schools: mapSchools,
        // данные для итоговой строки,
        // на основе этих же данных строится заголовок с датами
        totals: mapTotal,
      }, data);
    }
    return data;
  },
);

/**
 * Запрос данных отчета
 */

export const subsidiesDataFetch = (reportId: number) => async (dispatch: Function) => {
  const params = { report_id: reportId };

  dispatch(action(SUBSIDIES_DATA_REQUEST, reportId));

  try {
    const res = await api('reports/data', 'get', { params });
    const data = convertResult(res);
    dispatch(action(SUBSIDIES_DATA_SUCCESS, data));
  } catch (error) {
    dispatch(action(
      SUBSIDIES_DATA_FAILURE,
      { err: 'Не удалось получить данные отчета', error },
    ));
  }
};
