// @flow
import React, { type Node, useCallback, useState, useMemo } from 'react';
import { slice } from 'ramda';

import PlainModal, {
  PlainModalHeader,
  PlainModalContainer,
  PlainModalContent,
  PlainModalFooter,
} from 'app/common/ui-next/plain-modal';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import PlainDropdown, { PlainDropdownDefitem } from 'app/common/ui-next/plain-dropdown';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Category, Categories } from 'app/dataTypes/vending/production/Categories.types';

import styles from './DishesMoveModal.scss';


type Props = {
  dishes: Array<Dish>,
  categories: Categories,
  relatedCategories: Map<string, Category>,
  moveSelection: (categoryId: string) => Promise<boolean>,
  onClose: () => void,
}

const DishesMoveModal = ({ dishes, categories, relatedCategories, moveSelection, onClose }: Props): Node => {
  const [pending, setPending] = useState(false);
  const [selected, setSelected] = useState<null | { id: string }>(null);

  const handleSubmit = useCallback(async () => {
    if (selected) {
      setPending(true);
      const res = await moveSelection(selected.id);
      setPending(false);
      if (res) {
        onClose();
      }
    }
    return false;
  }, [moveSelection, selected, onClose]);

  const actualCategories = useMemo(() => {
    return slice(1, Infinity, categories);
  }, [categories]);

  return (
    <PlainModal
      className={styles.root}
      onSubmit={handleSubmit}
      show
    >
      <PlainModalHeader onCrossClick={onClose}>
        {'Перемещение продукции'}
      </PlainModalHeader>

      <PlainModalContainer className={styles.container}>
        <PlainModalContent className={styles.contentFrom}>
          <ul>
            {dishes.map(({ id, title }) => (
              <li key={id}>{title}</li>
            ))}
          </ul>
        </PlainModalContent>

        <PlainModalContent className={styles.arrowTo} unscrollable>
          &rarr;
        </PlainModalContent>

        <PlainModalContent className={styles.contentTo} unscrollable>
          <PlainDropdown
            id="categories"
            label="Выберите категорию"
            className={styles.categorySelector}
            labelClassName={styles.categorySelectorLabel}
            onChange={setSelected}
            defaultSkin
            placeholder={
              <PlainDropdownDefitem id="placeholder">
                {'Выберите категорию'}
              </PlainDropdownDefitem>
            }
          >
            {actualCategories.map(({ id, title }) => (
              <PlainDropdownDefitem
                key={id}
                id={id}
                selected={!!selected && id === selected.id}
                disabled={!!relatedCategories.get(id)}
              >
                {title}
              </PlainDropdownDefitem>
            ))}
          </PlainDropdown>
        </PlainModalContent>
      </PlainModalContainer>

      <PlainModalFooter>
        <ControlsBar middle>
          <PlainButton type="submit" disabled={!selected}>
            {pending ? 'Перемещение...' : 'Переместить'}
          </PlainButton>
          <PlainButton
            outline
            onClick={onClose}
          >
            {'Отмена'}
          </PlainButton>
        </ControlsBar>
      </PlainModalFooter>
    </PlainModal>
  );
};

export default DishesMoveModal;
