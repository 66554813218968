// @flow
import React, { type Node, useState, useCallback } from 'react';
import cn from 'classnames/bind';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  // useModalState,
  type HandleModalDisplayed,
  type PlainModalProps,
} from 'app/common/ui-next/plain-modal';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainTooltip from 'app/common/ui-next/plain-tooltip';
import type { ProductData, SlotData } from 'app/common/ui-components/planograms';
import PlainInput from 'app/common/ui-next/plain-input';

import ProductPhoto from './ProductPhoto';
import styles from './ProductContainsModalTemplate.scss';


const cx = cn.bind(styles);

type Props = {
  ...PlainModalProps,
  header: string,
  disableSubmit?: boolean,
  disableSubmitReason?: string,
  submitButtonTitle?: string,
  submitButtonTitleInProcess?: string,
  slotData: SlotData,
  productData: ProductData,
  value: number | null,
  willBeValue?: number,
  valueError?: boolean,
  specialButtonTitle?: string,
  onSubmit: () => Promise<boolean>,
  onChange?: (value: number | null) => void,
  onClickSpecialButton?: () => void,
  children?: (HandleModalDisplayed) => Node,
}

const ProductContainsModalTemplate = ({
  header,
  disableSubmit,
  disableSubmitReason = '',
  submitButtonTitle = 'Подтвердить',
  submitButtonTitleInProcess = 'Подтвердить...',
  slotData,
  productData,
  value,
  willBeValue,
  valueError,
  specialButtonTitle = 'Заполнить на 100%',
  onSubmit,
  onChange,
  onClickSpecialButton,
  children,
  ...others
}: Props): Node => {
  const [submitting, setSubmitting] = useState(false);
  const { title = '[Товар не найден]', photoURL, cost, portion } = productData;
  const { number, amountMax, amount } = slotData;

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    if (onChange) {
      const { value: val } = e.currentTarget;
      if (val === '') {
        onChange(null);
        return;
      }
      const num = parseInt(val, 10);
      if (!Number.isNaN(num)) {
        onChange(num);
      }
    }
  }, [onChange]);

  return (
    <PlainModal
      {...others}
      formId="increase/decrease"
      onSubmit={onSubmit}
      className={styles.root}
      controlBy={children}
      onSubmitStatusChange={setSubmitting}
    >
      <PlainModalHeader>
        {header}
      </PlainModalHeader>

      <PlainModalContent className={styles.content}>
        <ProductPhoto
          alt={title}
          className={styles.photo}
          photoURL={photoURL}
        />
        <div className={styles.number}>
          {`№ ${number}`}
        </div>
        <div className={styles.title}>
          {portion ? `${title}, ${portion}` : title}
        </div>
        <div className={styles.cost}>
          {cost}
        </div>
        <div className={styles.amount}>
          <dl>
            <dt>{'Остаток'}</dt>
            <dd>{`${amount} из ${amountMax}`}</dd>
            <dt className={cx({ valueError })}>
              {'Кол-во после подгрузки'}
            </dt>
            <dd className={cx({ valueError })}>
              {`${willBeValue === undefined ? amount : willBeValue} из ${amountMax}`}
            </dd>
          </dl>
        </div>
        <div className={styles.controls}>
          <ControlsBar middle>
            <PlainInput
              className={styles.input}
              onChange={handleChange}
              value={value !== null ? String(value) : ''}
              autoFocus
            />
            <PlainButton outline onClick={onClickSpecialButton}>
              {specialButtonTitle}
            </PlainButton>
          </ControlsBar>
        </div>
      </PlainModalContent>

      <PlainModalFooter>
        {handleModalDisplayed => (
          <ControlsBar right>
            <PlainTooltip text={disableSubmit ? disableSubmitReason : ''}>
              <PlainButton
                type="submit"
                disabled={disableSubmit || submitting}
              >
                {submitting ? submitButtonTitleInProcess : submitButtonTitle}
              </PlainButton>
            </PlainTooltip>

            <PlainButton
              outline
              onClick={handleModalDisplayed}
            >
              {'Отмена'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>

    </PlainModal>
  );
};

export default ProductContainsModalTemplate;
