import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import throttle from 'lodash.throttle';
import type { HOC } from 'recompose';

import { dishesFetch as dishesFetchAction } from 'app/actions/dishes/items';
import { listeditClear as listeditClearAction } from 'app/actions/orgs/dishes/listedit';

import Loader from 'app/common/components/Loader';
import withPaging from 'app/common/containers/withPaging';
import config from 'app/config';

import selectDishes from './selectDishes';


type Props = {
}

const enhanceDishesList: HOC<*, Props> = compose(
  connect(
    (state, props) => ({
      rows: state.settings.orgsDishesEditList.rows || config.perPageItems,
      settings: state.settings.orgsDishesEditList,
      total: state.dishes.items.count,
      items: selectDishes(state, props),
      existingItems: state.orgs.dishes.items || {},
      loading: state.dishes.items.loading,
      addList: state.orgs.dishes.listedit.add,
      removeList: state.orgs.dishes.listedit.remove,
      searchString: state.dishes.filter.searchString,
    }),
    dispatch => ({
      fetchDishes: params => dispatch(dishesFetchAction(params)),
      fetchDishesThrottled: throttle(
        params => dispatch(dishesFetchAction(params)),
        config.searchThrottleTime,
      ),
      listeditClear: () => dispatch(listeditClearAction()),
    }),
  ),

  withPaging(),

  lifecycle({
    componentDidMount() {
      const { rows, currentPage, searchString } = this.props;
      this.props.fetchDishes({
        limit: rows,
        offset: rows * (currentPage - 1),
        ...(searchString ? { search: searchString } : {}),
      });
    },
    componentDidUpdate(prevProps) {
      const { rows, currentPage, searchString, setCurrentPage } = this.props;
      // сброс страницы на 1, если изменилось количество на странице
      if (rows !== prevProps.rows && currentPage > 1) {
        setCurrentPage(1);
        return;
      }
      // изменение параметров, требуется обновление данных
      if (rows !== prevProps.rows || currentPage !== prevProps.currentPage) {
        this.props.fetchDishes({
          limit: rows,
          offset: rows * (currentPage - 1),
          ...(searchString ? { search: searchString } : {}),
        });
      }
      // отдельно для поиска
      if (searchString !== prevProps.searchString) {
        this.props.fetchDishesThrottled({
          limit: rows,
          offset: rows * (currentPage - 1),
          ...(searchString ? { search: searchString } : {}),
        });
      }
    },
    componentWillUnmount() {
      this.props.listeditClear();
    },

  }),

  branch(
    props => props.loading && !props.items.length,
    renderComponent(Loader),
  ),

);

export default enhanceDishesList;
