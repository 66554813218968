// @flow
import isEmpty from 'ramda/es/isEmpty';


export const errorsMapperCopyTo = [{
  fromKey: 'currentDate',
  errKey: 'date',
  validate: (date: string) => Boolean(date),
  text: 'Требуется правильная дата',
}, {
  fromKey: 'orgsSelected',
  errKey: 'org',
  validate: (orgsSelected: Array<string>) => !isEmpty(orgsSelected),
  text: 'Выберите хотя бы одно заведение',
}];

export const errorsMapperCopyFrom = [{
  fromKey: 'currentDate',
  errKey: 'date',
  validate: (date: string) => Boolean(date),
  text: 'Требуется правильная дата',
}];

export const errorsMapperCopyInside = [{
  fromKey: 'datesTo',
  validate: (dates: Array<string>) => !isEmpty(dates),
  text: 'Выберите хотя бы одну дату',
}];

const emptyMapper = [];

export const getMapperByTabNum = (tabNum: number) => {
  if (tabNum === 1) {
    return errorsMapperCopyFrom;
  }
  if (tabNum === 2) {
    return errorsMapperCopyTo;
  }
  if (tabNum === 3) {
    return errorsMapperCopyInside;
  }
  return emptyMapper;
};

export type Errors = {
  date?: string,
  org?: string,
  datesTo?: string,
}
