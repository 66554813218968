
import { ClassesListType, Params } from 'app/dataTypes/classesList'
import { map, values } from 'ramda'
import { Request } from '../../index'


type ResultApi = Record<number, {
  id: number
  title: string
}>

export const convertResult = (apiData: ResultApi): ClassesListType => {
  return map(({
    id,
    title,
  }) => {
    return ({
      ID: String(id),
      title,
    })
  }, values(apiData))
}

export type ApiParams = {
  school_id: number,
  schoolyear_id: number,
}

export const convertParams = (params: Params): [ApiParams, null] => {
  const {
    schoolId,
    schoolYearId,
  } = params

  return [{
    school_id: parseInt(schoolId, 10),
    schoolyear_id: parseInt(schoolYearId, 10),
  }, null]
}

export const GET_CLASSES_LIST: Request<Params, ClassesListType, void> = [
  'reports/klasses_list',
  'get',
  ['data', 'klasses'],
]
