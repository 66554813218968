// @flow
const OK: 'IconOk' = 'IconOk'
const SHOPING: 'IconShoping' = 'IconShoping'
const SHOPING_FILLED: 'IconShopingFilled' = 'IconShopingFilled'
const STORAGE: 'IconStorage' = 'IconStorage'
const STORAGE_FULL: 'IconStorageFull' = 'IconStorageFull'
const STORAGE_HALF: 'IconStorageHalf' = 'IconStorageHalf'
const GEAR_LIGHT: 'IconGearLight' = 'IconGearLight'
const GEAR_SPARSE: 'IconGearSparse' = 'IconGearSparse'
const INFO: 'IconInfo' = 'IconInfo'
const AUTHORIZATION_DATA: 'IconAuthorizationData' = 'IconAuthorizationData'
const LINKED_USERS: 'IconLinkedUsers' = 'IconLinkedUsers'
const USER_LINK_BLUE: 'IconUserLinkBlue' = 'IconUserLinkBlue'
const USER_LINK_GREEN: 'IconUserLinkGreen' = 'IconUserLinkGreen'
const GENERAL_SETTINGS: 'IconGeneralSettings' = 'IconGeneralSettings'
const LIMITS: 'IconLimits' = 'IconLimits'
const REQUESTS: 'IconRequests' = 'IconRequests'
const SELLPOINTS: 'IconSellpoints' = 'IconSellpoints'
const SUBSIDIES: 'IconSubsidies' = 'IconSubsidies'
const REPORT: 'IconReport' = 'IconReport'
const EXCEL: 'IconExcel' = 'IconExcel'
const USER_SINGLE: 'IconUserSingle' = 'IconUserSingle'
const USER_COUPLE: 'IconUserCouple' = 'IconUserCouple'
const CALENDAR: 'IconCalendar' = 'IconCalendar'
const CALENDAR1: 'IconCalendar1' = 'IconCalendar1'
const CALENDAR2: 'IconCalendar2' = 'IconCalendar2'
const PEN: 'IconPen' = 'IconPen'
const LIGHT_CROSS: 'IconLightCross' = 'IconLightCross'
const OK_CIRCLE: 'IconOkCircle' = 'IconOkCircle'
const REORDER: 'IconReorder' = 'IconReorder'
const ADD_TO_RIGHT: 'IconAddToRight' = 'IconAddToRight'
const SPLIT: 'IconSplit' = 'IconSplit'
const NO_PHOTO: 'IconNoPhoto' = 'IconNoPhoto'
const FULLSCREEN: 'IconFullscreen' = 'IconFullscreen'
const MEN: 'IconMen' = 'IconMen'
const EATING_PLACE: 'IconEatingPlace' = 'IconEatingPlace'
// lk
const CROSS_BOLD: 'IconCrossBold' = 'IconCrossBold'
const CHECKED_DOUBLE: 'IconCheckedDouble' = 'IconCheckedDouble'
const CHECKED: 'IconChecked' = 'IconChecked'
const ERROR_CIRCLE: 'IconErrorCircle' = 'IconErrorCircle'
const EXCEL32: 'IconExcel32' = 'IconExcel32'
const IMG32: 'IconImg32' = 'IconImg32'
const PAPER_CLIP: 'IconPaperClip' = 'IconPaperClip'
const PDF32: 'IconPdf32' = 'IconPdf32'
const UPLOAD_FILE: 'IconUploadFile' = 'IconUploadFile'
const VIDEO_CONFERENCE: 'IconVideoConference' = 'IconVideoConference'
const WORD32: 'IconWord32' = 'IconWord32'
const THEME_BW: 'IconThemeBW' = 'IconThemeBW'
const THEME_COLOR: 'IconThemeColor' = 'IconThemeColor'
const PORTION: 'IconPortion' = 'IconPortion'
const DISH: 'IconDish' = 'IconDish'
const COMPLEX: 'IconComplex' = 'IconComplex'
const COMPLEXES: 'IconComplexes' = 'IconComplexes'
const GROUP_BUILDINGS: 'IconGroupBuildings' = 'IconGroupBuildings'
const MOUSE: 'IconMouse' = 'IconMouse'
const CUTLERY: 'IconCutlery' = 'IconCutlery'
const CROSS_CIRCLE: 'IconCrossCircle' = 'IconCrossCircle'
const PLUS: 'IconPlus' = 'IconPlus'
const PRODUCTION: 'IconProduction' = 'IconProduction'
const REPORTS: 'IconReports' = 'IconReports'
const EXCHANGE: 'IconExchange' = 'IconExchange'
const EXCHANGE_CIRCLE: 'IconExchangeCircle' = 'IconExchangeCircle'
const APP_ATTENDANCE: 'IconAppAttendance' = 'IconAppAttendance'
const APP_CAFETERIA: 'IconAppCafeteria' = 'IconAppCafeteria'
const ARROW_RIGHT: 'IconArrowRight' = 'IconArrowRight'
const GEAR: 'IconGear' = 'IconGear'
const CAFETERIA: 'IconCafeteria' = 'IconCafeteria'
const ATTENDANCE: 'IconAttendance' = 'IconAttendance'
const CAMP: 'IconCamp' = 'IconCamp'
// edu
const STUDENTS: 'IconStudents' = 'IconStudents'
const PEOPLE: 'IconPeople' = 'IconPeople'
const PEOPLE_PLUS: 'IconPeoplePlus' = 'IconPeoplePlus'
const GLASSES: 'IconGlasses' = 'IconGlasses'
const COUPLE: 'IconCouple' = 'IconCouple'
const SCHEDULE: 'IconSchedule' = 'IconSchedule'
const PLAN: 'IconPlan' = 'IconPlan'
const MARKS: 'IconMarks' = 'IconMarks'
const BOOK: 'IconBook' = 'IconBook'
const CLONE: 'IconClone' = 'IconClone'
const GRAPH: 'IconGraph' = 'IconGraph'
const GRAPH_INCREASE: 'IconGraphIncrease' = 'IconGraphIncrease'
const BALL: 'IconBall' = 'IconBall'
const STAR: 'IconStar' = 'IconStar'
const BOOK_EXCLAMATION: 'IconBookExclamation' = 'IconBookExclamation'
const MESSAGES: 'IconMessages' = 'IconMessages'
const TRASH_CAN: 'IconTrashCan' = 'IconTrashCan'
const MALE: 'IconMale' = 'IconMale'
const FEMALE: 'IconFemale' = 'IconFemale'
const MAIL: 'IconMail' = 'IconMail'
const FAMILY: 'IconFamily' = 'IconFamily'
const EJECT: 'IconEject' = 'IconEject'
const BLANK: 'IconBlank' = 'IconBlank'
const OLD_PHONE: 'IconOldPhone' = 'IconOldPhone'
const BUILDINGS: 'IconBuildings' = 'IconBuildings'
const ID_CARD: 'IconIdCard' = 'IconIdCard'
// food
const DOCUMENTS: 'IconDocuments' = 'IconDocuments'


export const iconTypes = {
  OK,
  SHOPING,
  SHOPING_FILLED,
  STORAGE,
  STORAGE_FULL,
  STORAGE_HALF,
  GEAR_LIGHT,
  GEAR_SPARSE,
  INFO,
  AUTHORIZATION_DATA,
  LINKED_USERS,
  USER_LINK_BLUE,
  USER_LINK_GREEN,
  GENERAL_SETTINGS,
  LIMITS,
  REQUESTS,
  SELLPOINTS,
  SUBSIDIES,
  PRODUCTION,
  REPORT,
  REPORTS,
  EXCEL,
  USER_SINGLE,
  USER_COUPLE,
  CALENDAR,
  CALENDAR1,
  CALENDAR2,
  PEN,
  LIGHT_CROSS,
  OK_CIRCLE,
  REORDER,
  ADD_TO_RIGHT,
  SPLIT,
  NO_PHOTO,
  FULLSCREEN,
  MEN,
  EATING_PLACE,

  CROSS_BOLD,
  CHECKED_DOUBLE,
  CHECKED,
  ERROR_CIRCLE,
  EXCEL32,
  IMG32,
  PAPER_CLIP,
  PDF32,
  UPLOAD_FILE,
  VIDEO_CONFERENCE,
  WORD32,
  THEME_BW,
  THEME_COLOR,
  PORTION,
  DISH,
  COMPLEX,
  COMPLEXES,
  GROUP_BUILDINGS,
  MOUSE,
  CUTLERY,
  CROSS_CIRCLE,
  PLUS,
  EXCHANGE,
  EXCHANGE_CIRCLE,
  APP_ATTENDANCE,
  APP_CAFETERIA,
  ARROW_RIGHT,
  GEAR,
  CAFETERIA,
  ATTENDANCE,
  CAMP,

  STUDENTS,
  PEOPLE,
  PEOPLE_PLUS,
  GLASSES,
  COUPLE,
  SCHEDULE,
  PLAN,
  MARKS,
  BOOK,
  CLONE,
  GRAPH,
  GRAPH_INCREASE,
  BALL,
  STAR,
  BOOK_EXCLAMATION,
  MESSAGES,
  TRASH_CAN,
  MALE,
  FEMALE,
  MAIL,
  FAMILY,
  EJECT,
  BLANK,
  OLD_PHONE,
  BUILDINGS,
  ID_CARD,

  DOCUMENTS,
}
