import React, { useCallback, useState, ReactNode, memo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import classNames from 'classnames/bind'

import styles from './scrollbars-w-shadow.scss'


const cx = classNames.bind(styles)

type Props = {
  className?: string,
  autoHeight?: boolean,
  autoHeightMin?: number | string,
  autoHeightMax?: number | string,
  children: ReactNode,
  style?: Object,
  fillAllSpace?: boolean,
}

const ScrollbarsWShadow = memo<Props>(({
  className,
  autoHeight,
  autoHeightMin,
  autoHeightMax,
  children,
  style,
  fillAllSpace,
}: Props) => {
  const [isTopShadow, setTopShadow] = useState(false)
  const [isBottomShadow, setBottomShadow] = useState(false)
  const [isScrollActive, setScrollActive] = useState(false)


  const handleUpdate = useCallback((values) => {
    // начальная позиция
    if (values.top === 0) {
      if ((values.scrollHeight - 1) <= values.clientHeight) {
        // контент внутри скрола меньше или равен контейнеру
        if (isBottomShadow) setBottomShadow(false)
        if (isScrollActive) setScrollActive(false)
      } else {
        if (!isBottomShadow) setBottomShadow(true)
        if (!isScrollActive) setScrollActive(true)
      }
      if (isTopShadow) setTopShadow(false)
      return
    }
    // setTop(values.top);
    // остальные позиции
    let topS = false
    let bottomS = false
    if ((values.scrollHeight - 1) > values.clientHeight) {
      topS = true
      bottomS = true
      if (values.top === 0) {
        topS = false
      } else if (values.top > 0.98) {
        bottomS = false
      }
    }
    if (isTopShadow !== topS) setTopShadow(topS)
    if (isBottomShadow !== bottomS) setBottomShadow(bottomS)
  }, [isBottomShadow, isTopShadow, isScrollActive])

  const fillAllSpaceStyle = fillAllSpace ? { width: '100%', height: '100%' } : {}


  return (
    <Scrollbars
      className={cx(className, { isTopShadow, isBottomShadow })}
      onUpdate={handleUpdate}
      autoHeight={autoHeight}
      autoHeightMin={autoHeightMin}
      autoHeightMax={autoHeightMax}
      style={{ ...fillAllSpaceStyle, ...style }}
    >
      {children}
    </Scrollbars>
  )
})

export default ScrollbarsWShadow
