import { useCallback, ChangeEvent } from 'react'

import { type Settings } from 'app/reducers/settings/dishes'
import { Tr, Th } from 'app/common/ui-components/table'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import { useSelectionData } from 'app/components/common/SelectionData'
import { useCurrencySign, options as co } from 'app/common/components-next/currency'
import Sticky from 'app/common/templates-next/sticky'

import styles from './DishesTableTHead.scss'

/**
 *  Заголовочная часть таблицы. Используется в 2х местах, поэтому вынесена в отдельный компонент.
 */


type Props = {
  settings: Settings,
  nomenclatureActive: boolean,
}

const DishesTableTHead = ({ settings, nomenclatureActive }: Props) => {
  const {
    check: showCheck,
    photo: showPhoto,
    portion: showPortion,
    cost: showCost,
    saleType: showSaleType,
    options: showOptions,
    nomenclature: showNomenclature,
    id: showId,
  } = settings

  const [{ allSelected }, { updateAll }] = useSelectionData()

  const handleChangeCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    updateAll(checked)
  }, [updateAll])

  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE)

  return (
    <thead>
      <Sticky applyToChildrenTh updateKey={settings}>
        {(ref, stuck, shadow) => {
          const commonProps = {
            borderShadowBottom: shadow,
            bgFilled: stuck,
          }

          return (
            <Tr header reference={ref}>
              {showCheck
                && <Th alignLeft className={styles.columnCheckbox} {...commonProps}>
                  <PlainCheckbox
                    label="Выбрать все"
                    hideLabel
                    value="all"
                    className={styles.checkbox}
                    checked={allSelected}
                    onChange={handleChangeCheckbox}
                  />
                </Th>}

              {showId
                && nomenclatureActive
                && <Th alignLeft {...commonProps}>{'ID'}</Th>}

              {showNomenclature
                && nomenclatureActive
                && <Th alignLeft {...commonProps}>{'Номенклатура'}</Th>}

              <Th alignLeft {...commonProps}>{'Наименование'}</Th>

              {showPhoto
                && <Th alignCenter {...commonProps}>{'Фото'}</Th>}

              {showPortion
                && <Th alignLeft {...commonProps}>{'Порция'}</Th>}

              {showCost
                && <Th alignLeft {...commonProps}>{`Цена${currency}`}</Th>}

              {showSaleType
                && <Th alignLeft {...commonProps}>{'Тип\u00A0продукции'}</Th>}

              {showOptions
                && <Th alignRight {...commonProps}>{''}</Th>}
            </Tr>
          )
        }}
      </Sticky>
    </thead>
  )
}

export default DishesTableTHead
