// @flow

export const columns: [
  'id',
  'name',
  'title',
  'photo',
  'amount',
  'amountAdd',
  'amountMax',
] = [
  'id',
  'name',
  'title',
  'photo',
  'amount',
  'amountAdd',
  'amountMax',
];
