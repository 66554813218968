import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { assoc, isEmpty, prop } from 'ramda'
import cn from 'classnames'

import { AccrualCamp } from 'app/dataTypes/subsidy/settings'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import PlainRadioButton from 'app/common/ui-next/plain-radio-button'
import PlainInput from 'app/common/ui-next/plain-input'
import PlainLabel from 'app/common/ui-next/plain-label'
import useComplexes from 'app/dataProviders/subsidy/complexes/hooks/useComplexes'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import Complexes from './Complexes'
import { useLost } from './useLost'
import LostItemsWarning from './LostItemsWarning'
import { controls, selection } from './accrualFormCampControls'

import styles from './AccrualFormCamp.scss'


export type Errors = Map<string, string>
const app = 'camp' as const

type Props = {
  ID: string
  orgID: string
  data: AccrualCamp
  formID: string
  disabled?: boolean
  errors: Errors
  resetErrors: () => void
  updateSize?: () => void
}

export const AccrualFormCamp = ({
  ID,
  orgID,
  data,
  formID,
  disabled,
  errors,
  resetErrors,
  updateSize,
}: Props) => {
  const [{ active, percent, complexes }, setData] = useState(data)
  const handleActive = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setData(assoc('active', checked))
    resetErrors()
  }, [resetErrors])
  const handlePercent = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (/^[0-9]*$/.test(value)) {
      setData(assoc('percent', value))
      resetErrors()
    }
  }, [resetErrors])

  const initialState = {
    selection: !!data.percent || !data.complexes.length ? 'percent' : 'complexes',
    percentForComplexes: !!(data.percent && data.complexes.length),
  }

  const [select, setSelect] = useState(initialState.selection)
  const handleSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget
    if (checked && (value === 'percent' || value === 'complexes')) {
      setSelect(value)
      resetErrors()
    }
  }, [resetErrors])

  const [availableComplexes, loadingComplexes] = useComplexes({ orgID, app, allowanceID: ID })
  const [complexesSelected, setComplexesSelected] = useState(new Set(complexes.map(prop('ID'))))

  const errorPercent = errors.get(controls.percentInput)
  const errorComplexes = errors.get(controls.complexesList)

  const lostItems = useLost({
    loading: loadingComplexes,
    selected: complexes,
    items: availableComplexes,
    onLost: updateSize,
  })

  useEffect(() => {
    setData(data)
    resetErrors()
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateSize?.()
  }, [select, errors, availableComplexes, active]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      <PlainCheckbox
        label="Индивидуальные настройки"
        name={controls.activeCheckbox}
        value="active"
        checked={active}
        onChange={handleActive}
        disabled={disabled}
        form={formID}
      />

      <div className={cn(styles.columns, getScrollToErrorClassName(!!errorPercent))}>
        <div>
          <PlainRadioButton
            label="Процент"
            name={controls.selectionRadio}
            disabled={disabled || !active}
            value={selection.percent}
            checked={select === selection.percent}
            onChange={handleSelect}
            form={formID}
          />

          <div className={styles.percent}>
            <PlainLabel
              htmlFor={controls.percentInput}
              disabled={disabled || !active || select === selection.complexes}
            >
              {'Процент льгот:'}
            </PlainLabel>
            <PlainInput
              id={controls.percentInput}
              name={controls.percentInput}
              disabled={disabled || !active || select === selection.complexes}
              value={percent}
              onChange={handlePercent}
              form={formID}
            />
          </div>

          {errorPercent
              && <PlainHelperText error>{errorPercent}</PlainHelperText>}

          {active
            && select === selection.percent
            && <Complexes
              label="Комплекты на которые начисляются льготы:"
              name={controls.complexesList}
              items={availableComplexes}
              checked={complexesSelected}
              setChecked={setComplexesSelected}
              loading={loadingComplexes}
              form={formID}
              disabled={disabled}
            />}

          {active
            && select === selection.percent
            && !isEmpty(lostItems)
            && <LostItemsWarning items={lostItems} />}
        </div>

        <div>
          <PlainRadioButton
            label="Комплекты"
            name={controls.selectionRadio}
            value={selection.complexes}
            disabled={disabled || !active}
            checked={select === selection.complexes}
            onChange={handleSelect}
            form={formID}
          />

          {errorComplexes
            && select === selection.complexes
            && <PlainHelperText
              className={getScrollToErrorClassName()}
              error
            >
              {errorComplexes}
            </PlainHelperText>}

          {active
            && select === selection.complexes
            && <Complexes
              label="Комплекты на которые начисляются льготы:"
              name={controls.complexesList}
              items={availableComplexes}
              checked={complexesSelected}
              setChecked={setComplexesSelected}
              loading={loadingComplexes}
              form={formID}
              disabled={disabled}
            />}

          {active
            && select === selection.complexes
            && !isEmpty(lostItems)
            && <LostItemsWarning items={lostItems} />}
        </div>
      </div>
    </div>
  )
}
