import { reject } from 'ramda'

import { ChangeStatusParams } from 'app/dataTypes/compensation/statement/status'

import type { Request } from '../../../../index'
import { statusToApi } from '../statuses/_common'


type ApiParams = {
  withdraw_order_id: number
  status: number
  comment?: string
}

export const convertParams = ({
  statementID,
  newStatusID,
  comment,
}: ChangeStatusParams): [ApiParams, null] => {
  return [reject(val => val === undefined, {
    withdraw_order_id: parseInt(statementID, 10),
    status: statusToApi(newStatusID),
    comment,
  }), null]
}

export const convertResult = (): boolean => {
  return true
}

export const POST_COMPENSATION_WITHDRAW_ORDERS_UPDATE: Request<ChangeStatusParams, boolean, void> = [
  'food/compensation/withdraw_orders/update',
  'post',
  ['data', 'result'],
]
