// @flow
import filter from 'ramda/es/filter';
import pipe from 'ramda/es/pipe';
import groupBy from 'ramda/es/groupBy';

import * as statuses from 'app/common/constants/identifiers/statuses';

import { type IdentifierType } from './identifiers-data';


const arrangeItemsInsideGroups = (
  identifiersByType: Array<IdentifierType>,
  blocked: boolean,
  withdrawn: boolean,
): Array<IdentifierType> => {
  const {
    payWaiting_ = [],
    active_ = [],
    blocked_ = [],
    withdrawn_ = [],
    others_ = [],
  } = pipe(
    filter(({ status }) => (
      (blocked || status !== statuses.STATUS_BLOCKED)
      && (withdrawn || status !== statuses.STATUS_WITHDRAWN)
    )),
    groupBy(({ status }) => {
      switch (status) {
        case statuses.STATUS_PAY_WAITING:
          return 'payWaiting_';
        case statuses.STATUS_ACTIVE:
          return 'active_';
        case statuses.STATUS_BLOCKED:
          return 'blocked_';
        case statuses.STATUS_WITHDRAWN:
          return 'withdrawn_';
        default:
      }
      return 'others_';
    }),
  )(identifiersByType);

  return [
    ...payWaiting_,
    ...active_,
    ...blocked_,
    ...withdrawn_,
    ...others_,
  ];
};

export default arrangeItemsInsideGroups;
