// @flow
import type { Request } from '../index';


type Params = {
  report_type: string,
}

export const GET_LAST_CACHE_UPDATE: Request<
  Params,
  string,
  void
> = [
  'reports/last_cache_update',
  'get',
  ['data', 'updated_at'],
];
