// @flow
import { useMemo, useCallback, useState } from 'react';
import sort from 'ramda/es/sort';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import { useActualOrgs2Selector, type OrgsSchools, type ActualOrgs } from 'app/dataProviders/org/OrgsDataProvider';
import getFirstId from 'app/components/common/getFirstId';

/**
 *  Управление id организации откуда копировать и default value
 */

type Options = {
  orgId?: number,
  date?: string | null,
}

type SelectorProps = {
  items: ActualOrgs,
  selection: Array<string>,
  defaultSelection: Array<string>,
  onChange?: (values: Array<string>) => void,
  sortFunc: (Array<Object>) => Array<Object>,
}

export default function useOrgId(orgsState: OrgsSchools, options: Options): [number | null, SelectorProps] {
  const orgs = useActualOrgs2Selector(orgsState, options);

  const defaultSelection = useMemo(() => {
    const id = getFirstId(orgs);
    return id ? [id] : [];
  }, [orgs]);

  const [orgIdFrom, setOrgIdFrom] = useState<Array<string>>(defaultSelection);
  const handleChangeOrg = useCallback((values: Array<string>) => {
    setOrgIdFrom(values);
  }, [setOrgIdFrom]);

  const orgIdFromValue = orgIdFrom[0] ? parseInt(orgIdFrom, 10) : null;

  const selectorProps = useMemo(() => ({
    selection: orgIdFrom,
    defaultSelection,
    onChange: handleChangeOrg,
    items: orgs,
    sortFunc: sort(ascend(prop('title'))),
  }), [orgs, handleChangeOrg, defaultSelection, orgIdFrom]);

  return [orgIdFromValue, selectorProps];
}
