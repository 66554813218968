// @flow
import toPairs from 'ramda/es/toPairs';
import reduce from 'ramda/es/reduce';
import map from 'ramda/es/map';
import addIndex from 'ramda/es/addIndex';
import prop from 'ramda/es/prop';
import moment from 'moment-timezone';

import type {
  TimeRestrictions,
  EnabledTimeRestrictions,
  ArrangeFoodModes,
  Params,
  ParamsPOST,
  TimeRestrictionItemStrict,
} from 'app/dataTypes/OrgSettingsTimeRestrictions.types';

import { foodModes as fm, type FoodMode } from 'app/dataTypes/foodModes';

import type { Request } from '../../index';


const reducex = addIndex(reduce);
const apiTimeFormat: 'HH:mm' = 'HH:mm';

type TimeRestrictionApiItem = {
  time: string | null,
  day_mode: 'before' | 'today' | null,
}

type TimeRestrictionApiGroup = {
  '1': TimeRestrictionApiItem,
  '2': TimeRestrictionApiItem,
  '3': TimeRestrictionApiItem,
}

type Result = {
  enabled: boolean,
  restrictions: {
    afternoon: TimeRestrictionApiGroup,
    breakfast: TimeRestrictionApiGroup,
    dinner: TimeRestrictionApiGroup,
  },
  result: 'success',
}

type UrlParams = {
  orgId: number | string,
}

const apiFoodKey = (key: FoodMode): $Keys<$PropertyType<Result, 'restrictions'>> => key;

// GET

export const convertParams = (params: Params): [null, UrlParams] => {
  return [null, params];
};

const arrangeFoodModes: ArrangeFoodModes = [fm.Breakfast, fm.Dinner, fm.Afternoon];
const createTime = (date: string | null) => {
  if (date) {
    const [hours, minutes] = date.split(':');
    if (hours && minutes && !hours.replace(/\d+/, '') && !minutes.replace(/\d+/, '')) {
      return new Date(2000, 0, 1, parseInt(hours, 10), parseInt(minutes, 10));
    }
  }
  return null;
};

export const convertResult = (data: Result): [TimeRestrictions, EnabledTimeRestrictions] => {
  const { enabled, restrictions } = data;
  const timeRestrictions = reduce((acc, key) => {
    const groupPairs = toPairs(restrictions[apiFoodKey(key)]);
    map(([index, { time, day_mode: dayMode }]) => {
      const groupIndex = parseInt(index, 10) - 1;
      const restriction = { time: createTime(time), dayMode };
      acc[groupIndex] = (
        acc[groupIndex] instanceof Array
          ? [...acc[groupIndex], restriction]
          : [restriction]
      );
    }, groupPairs);
    return acc;
  }, [], arrangeFoodModes);

  return [timeRestrictions, enabled];
};

export const GET_CAFETERIA_ORDERS_TIME_RESTRICTIONS: Request<
  Params,
  [TimeRestrictions, EnabledTimeRestrictions],
  UrlParams
> = [
  'food/schools/{orgId}/cafeteria_orders_time_restrictions',
  'get',
  ['data'],
];

// POST

type TimeRestrictionApiGroupPOST = {
  '1': TimeRestrictionApiItem,
  '2': TimeRestrictionApiItem,
  '3': TimeRestrictionApiItem,
}

type ParamsApiPOST = {
  schools_ids: Array<string> | Array<number>,
  enabled: boolean,
  restrictions: {
    afternoon: TimeRestrictionApiGroupPOST,
    breakfast: TimeRestrictionApiGroupPOST,
    dinner: TimeRestrictionApiGroupPOST,
  },
}

const timezone = moment.tz.guess();

const convertTime = ({ time, dayMode }: TimeRestrictionItemStrict): TimeRestrictionApiItem => {
  return {
    day_mode: dayMode,
    time: moment(time).tz(timezone).format(apiTimeFormat),
  };
};

export const convertParamsPOST = (params: ParamsPOST): [ParamsApiPOST, null] => {
  const { orgIds, enabled, restrictions: componentRestrictions } = params;
  const restrictions = reducex((acc, key, foodIndex) => {
    const foodKey = apiFoodKey(key);
    const targetGroups = acc[foodKey] || {};
    const newGroups = reducex((accGroups, group, groupIndex) => {
      const foodItem = prop(foodIndex, group);
      accGroups = { // eslint-disable-line no-param-reassign
        ...accGroups,
        [String(groupIndex + 1)]: convertTime(foodItem),
      };
      return accGroups;
    }, {}, componentRestrictions);

    acc[foodKey] = {
      ...targetGroups,
      ...newGroups,
    };
    return acc;
  }, {}, arrangeFoodModes);

  return [{ enabled, restrictions, schools_ids: orgIds }, null];
};

export const POST_CAFETERIA_ORDERS_TIME_RESTRICTIONS: Request<ParamsPOST, 'success', void> = [
  'food/schools/cafeteria_orders_time_restrictions',
  'post',
  ['data', 'result'],
];
