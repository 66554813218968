// @flow
import React, { type Node, Fragment, useCallback } from 'react';

import { PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainInput from 'app/common/ui/PlainInput';
import PlainTextArea from 'app/common/ui/PlainTextArea';
import type { SingleRequisites } from 'app/dataTypes/requisites';

import styles from './BankForm.scss';


type Props = {
  value: SingleRequisites,
  valueBasePath: string,
  onChange: (string, string) => void,
  loading?: boolean,
  locked?: boolean,
}

const BankForm = ({
  value,
  valueBasePath,
  onChange,
  loading,
  locked,
}: Props): Node => {
  const showExtraFields = value.orgType === 'Бюджетная организация';

  const handleChangeField = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: val, name } = e.currentTarget;
    onChange(`${valueBasePath}.${name}`, val);
  }, [valueBasePath, onChange]);

  return (
    <Fragment>
      {/* ROW 9 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={4}>
          <PlainInput
            label="БИК"
            name="bik"
            value={value.bik}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="Банк"
            name="bank"
            value={value.bank}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="Корреспондентский счет"
            name="corr"
            value={value.corr}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="Расчетный счет"
            name="rc"
            value={value.rc}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
      </PlainColumns>

      {/* 2nd ROW для "Бюджетная организация" */}
      {showExtraFields
        && <PlainColumns className={styles.row}>
          <PlainColumn div={4}>
            <PlainInput
              label="Л/с"
              name="lc"
              value={value.lc}
              onChange={handleChangeField}
              loading={loading}
              disabled={locked}
              placeholder="Не указано"
            />
          </PlainColumn>
        </PlainColumns>}


      {/* ROW 10 */}
      <PlainColumns className={styles.rowAlignTop}>
        <PlainColumn div={2}>
          <PlainTextArea
            label="Адрес"
            name="bankAddress"
            value={value.bankAddress}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
        <PlainColumn div={4}>
          <PlainInput
            label="Вид деятельности"
            name="paymentOrderServiceCode"
            value={value.paymentOrderServiceCode}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
      </PlainColumns>

    </Fragment>
  );
};

export default BankForm;
