import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import omit from 'ramda/es/omit';
import filter from 'ramda/es/filter';
import identity from 'ramda/es/identity';

import get from 'app/common/lib/get';


const enhanceRolesTable = compose(
  mapProps(
    ({ data, title, arrange, arrangeRows, ...others }) => {
      const arranged = arrangeRows ? map(key => data[key], arrangeRows) : [];
      const rest = arrangeRows ? omit(arrangeRows, data) : data;
      const rows = [...filter(identity, arranged), ...values(rest)];

      const groups = arrange || pipe(
        values,
        map(({ key }) => key),
      )(get(rows, [0, 'scopes'], {}));

      return ({
        title,
        scopes: groups,
        rows: rows.map(({ key, scopes }) => ({
          key,
          title: key,
          items: groups.map(group => ([{
            value: [title, 'subsections', key, 'scopes', group, 'read'].join('.'),
            checked: get(scopes, [group, 'read', 'checked'], false),
            disabled: get(scopes, [group, 'read', 'disabled'], false),
          }, {
            value: [title, 'subsections', key, 'scopes', group, 'edit'].join('.'),
            checked: get(scopes, [group, 'edit', 'checked'], false),
            disabled: get(scopes, [group, 'edit', 'disabled'], false),
          }])),
        })),
        ...others,
      });
    },
  ),
);

export default enhanceRolesTable;
