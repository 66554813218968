import { combineReducers } from 'redux';

import types from './types';
import items from './items';
import filter from './filter';
import selection from './selection';
import add from './add';
import library from './library';

export default combineReducers({
  types,
  items,
  filter,
  selection,
  add,
  library,
});
