// @flow
import React, { Fragment, Children } from 'react';

import PlainTooltip from 'app/common/ui-next/plain-tooltip';

import styles from './MultilineCell.scss';


type Props = {
  children: Array<string>
}

const MultilineCell = ({ children }: Props) => {
  return (
    <Fragment>
      {Children.map(children, comp => (
        <PlainTooltip
          text={comp}
          className={styles.row}
          hideWithoutOverflow
        >
          {comp}
        </PlainTooltip>
      ))}
    </Fragment>
  );
};

export default MultilineCell;
