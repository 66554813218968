// @flow
import React, { useCallback, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useDebouncedSearch from 'app/common/hooks/useDebouncedSearch';
import { ButtonsBar, OpenFilterButton } from 'app/common/ui/Button';
import { useFilterData } from 'app/common/ui-components/filter';
import PlainButton from 'app/common/ui-next/plain-button';
import { HIREFORM_CLEAR } from 'app/actions/employees/hireform';
import action from 'app/common/lib/action';

import HireFormModal from './HireFormModal';
import * as employeeTypes from '../employeeTypes';


type Props = {
  employeeType: $Values<typeof employeeTypes>,
  onSearch: (string) => void,
  onFilterToggle: ((boolean => boolean) | boolean) => void,
  filterActive: boolean,
  enableHiring: boolean,
  onSuccess?: () => void
}

const ActionsBar = ({
  onSearch,
  onFilterToggle,
  filterActive,
  enableHiring,
  employeeType,
  onSuccess,
}: Props) => {
  const dispatch = useDispatch();
  const handleReset = useCallback(() => onSearch(''), [onSearch]);
  const [searchString, handleSearch, handleClearSearch] = useDebouncedSearch(onSearch, handleReset);

  const [{ applied }] = useFilterData();

  const handleToggleFilter = useCallback((e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { body } = document;
    const { documentElement } = document;
    // скрол вверх
    if (body) body.scrollTop = 0; // For Safari
    if (documentElement) documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // показ окна с фильтрами
    onFilterToggle(state => !state);
  }, [onFilterToggle]);

  const [hireModal, showHireModal] = useState(false);

  const showModal = useCallback(() => {
    showHireModal(true);
  }, []);

  const hideModal = useCallback(() => {
    showHireModal(false);
    dispatch(action(HIREFORM_CLEAR));
  }, [dispatch]);


  return (
    <ButtonsBar>
      {/* Найм */}
      {enableHiring
        && <Fragment>
          <PlainButton onClick={showModal}>
            {'Прием сотрудника'}
          </PlainButton>

          {hireModal
            && <HireFormModal
              onClose={hideModal}
              onSuccess={onSuccess}
              employeeType={employeeType}
            />}
        </Fragment>}

      {/* Кнопка открытия фильтра */}
      <OpenFilterButton
        onClick={handleToggleFilter}
        checked={!!applied}
        opened={filterActive}
      />

      {/* Форма поиска */}
      <PlainInputSearch
        placeholder="Поиск"
        searchString={searchString}
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      />
    </ButtonsBar>
  );
};

export default ActionsBar;
