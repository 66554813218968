import api from 'app/actions/api';


export const JOBPLACES_DELETE_REQUEST = 'employee/JOBPLACES_DELETE_REQUEST';
export const JOBPLACES_DELETE_SUCCESS = 'employee/JOBPLACES_DELETE_SUCCESS';
export const JOBPLACES_DELETE_FAILURE = 'employee/JOBPLACES_DELETE_FAILURE';

const jobplacesDeleteRequest = payload => ({
  type: JOBPLACES_DELETE_REQUEST,
  payload,
});

const jobplacesDeleteSuccess = payload => ({
  type: JOBPLACES_DELETE_SUCCESS,
  payload,
});

const jobplacesDeleteFailure = payload => ({
  type: JOBPLACES_DELETE_FAILURE,
  payload,
});

export const jobplacesDelete = (employeeId, orgId) => async (dispatch) => {
  dispatch(jobplacesDeleteRequest({ employeeId, orgId }));
  try {
    await api(`food/employees/${employeeId}/schools/${orgId}`, 'delete');
    dispatch(jobplacesDeleteSuccess(orgId));
    return true;
  } catch (error) {
    dispatch(jobplacesDeleteFailure({ err: 'Удаление места работы не удалось', error }));
    return false;
  }
};
