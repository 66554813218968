// @flow
import path from 'ramda/es/path';

import {
  getStateCurrentValue,
  getStateItems,
  type State,
  type PlainDateRestProps,
} from 'app/common/ui-components/filter';

import { getDisabledDaysFunc } from 'app/common/ui-next/plain-date-period';
import convertDatesFormat from 'app/common/lib/convertDatesFormat';

import yearFilter, { dateFormat as requestDateFormat } from 'app/components/common/reports/filters/year';
import { dateFormat } from './dateItems';


const dateProps = (state: State): PlainDateRestProps => {
  const yearCurrentIds = getStateCurrentValue(yearFilter, state);
  const years = getStateItems(yearFilter, state);
  const selectedYearId = yearCurrentIds[0];

  const [dateStart, dateFinish] = convertDatesFormat(requestDateFormat, dateFormat, [
    path([selectedYearId, 'date_start'], years) || null,
    path([selectedYearId, 'date_finish'], years) || null,
  ]);

  const dateDisabledStatus = getDisabledDaysFunc(dateStart, dateFinish);

  return ({
    id: 'date',
    label: 'Дата',
    dateDisabledStatus,
    dateFormat,
    displayAsBlock: true,
  });
};

export default dateProps;
