// @flow
import { useMemo } from 'react';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import when from 'ramda/es/when';
import complement from 'ramda/es/complement';
import always from 'ramda/es/always';
import omit from 'ramda/es/omit';

import { useFilterData } from 'app/common/ui-components/filter';

import type { Limits, OOPGenerateParams } from './OOPGenerateParams';


const toInt = (val: string) => parseInt(val, 10);

export default function useFilterParams(foodModeEnable: boolean, orgId: number): OOPGenerateParams | null {
  const [{ applied: filterApplied }] = useFilterData();

  const result: OOPGenerateParams | null = useMemo(() => {
    if (!filterApplied) {
      return null;
    }
    const limits: Limits = pipe(
      when(complement(always(foodModeEnable)), omit(['food_mode'])),
    )({
      klasses_ids: map(toInt, prop('class', filterApplied)),
      statuses: map(toInt, prop('status', filterApplied)),
      food_modes: prop('foodMode', filterApplied),
    });

    return ({
      from: path(['period', 0], filterApplied),
      to: path(['period', 1], filterApplied),
      school_id: orgId,
      limits,
    });
  }, [filterApplied, foodModeEnable, orgId]);


  return result;
}
