// @flow
import React, { type Node } from 'react';
import cn from 'classnames/bind';

import { DotsLoader } from 'app/common/components/Loader';

import styles from './item-template.scss';


const cx = cn.bind(styles);

type Props = {
  goods: number,
  children: Node,
  bottomText: string,
  doubleWidth?: boolean,
  pending?: boolean,
  tinted?: boolean,
  colored?: boolean,
}

const ItemTemplate = ({
  goods,
  children,
  bottomText,
  doubleWidth,
  pending,
  tinted,
  colored,
}: Props): Node => {
  return (
    <div className={cx(styles.root, { doubleWidth })}>
      <div className={cx(styles.content, { colored })}>
        <div className={styles.text}>
          {goods > 0
            ? `${goods} из`
            : (doubleWidth && 'Пусто' || '0')}
        </div>

        {children}

        <div className={styles.text}>
          {`№${bottomText}`}
        </div>
      </div>

      {(pending || tinted)
        && <div className={styles.tinted} />}

      {pending
        && <DotsLoader className={styles.dots} />}
    </div>
  );
};

export default ItemTemplate;
