// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import styles from './Modal.scss';


type Props = {
  className?: string,
  children: Node,
}

const ModalHeader = ({ className, children }: Props) => (
  <div className={classNames(styles.modalFooter, className)}>
    {children}
  </div>
);

export default ModalHeader;
