// @flow
import React from 'react';
import { Switch, Route } from 'react-router';

import getBaseUrl from './getBaseUrl';
import FinancesRootPage from './FinancesRootPage';
import FinancePage from './FinancePage';


const rootUrl = getBaseUrl();
const baseUrlWithSection = getBaseUrl({ report: ':report' });

const FinancesPageRoutes = () => {
  return (
    <Switch>
      <Route path={rootUrl} component={FinancesRootPage} exact />
      <Route path={baseUrlWithSection} component={FinancePage} exact />
    </Switch>
  );
};

export default FinancesPageRoutes;
