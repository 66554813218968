import { Table } from 'app/common/ui-components/table'
import Sticky from 'app/common/templates-next/sticky'

import type { CSchool, Total } from '../useReportData'
import useActualItems from './useActualItems'
import { useColumns } from './columns'
import HeaderRows from './HeaderRows'
import Row from './Row'

import styles from './Details.scss'


type Props = {
  data: Array<CSchool>
  total: Total
}

const Details = ({ data, total }: Props) => {
  const columns = useColumns()
  const itemsActual = useActualItems(data, total)


  return (
    <Table className={styles.table}>
      <Sticky>
        {(ref, stuck, shadow) => (
          <thead ref={ref}>
            <HeaderRows
              firstCellClassName={styles.title}
              columns={columns}
              shadow={shadow}
              stuck={stuck}
            />
          </thead>
        )}
      </Sticky>

      <tbody>
        {itemsActual.items.map((rowData) => {
          const { key } = rowData

          return (
            <Row
              key={key}
              columns={columns}
              firstCellClassName={styles.title}
              cellContent={id => rowData[id]}
            />
          )
        })}
      </tbody>

      <tfoot>
        <Sticky stickyBottom>
          {(ref, _, shadow) => (
            <Row
              ref={ref}
              columns={columns}
              firstCellClassName={styles.title}
              cellContent={id => itemsActual.total[id]}
              shadowTop={shadow}
              total
            />
          )}
        </Sticky>
      </tfoot>
    </Table>
  )
}

export default Details
