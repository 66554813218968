import { itemsAvailable } from 'app/components/common/reports/filters/balanceType';
import { type State } from 'app/common/ui-components/filter';

import { flags as groups } from './group';
import { flags as sort } from './sort';


const { all, school, camp } = itemsAvailable;

const allDisabled = { ...all, disabled: true };
const schoolDisabled = { ...school, disabled: true };
const campDisabled = { ...camp, disabled: true };

const balanceTypeItems = (state: State) => (
  (
    groups.isActive(state)
    && groups.byGroupsAndTypesSelected(state)
  ) || (
      sort.isActive(state)
      && sort.byClassesSelected(state)
    )
    ? [[schoolDisabled, campDisabled], [school.id]]
    : [[school, camp], [school.id]]
);

export default balanceTypeItems;
