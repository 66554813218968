// @flow
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';
import api from 'app/actions/api';
import { toCamelCase } from 'app/common/lib/object';


export const EMPLOYEE_SESSIONS_REQUEST = 'EMPLOYEE_SESSIONS_REQUEST';
export const EMPLOYEE_SESSIONS_SUCCESS = 'EMPLOYEE_SESSIONS_SUCCESS';
export const EMPLOYEE_SESSIONS_FAILURE = 'EMPLOYEE_SESSIONS_FAILURE';
export const EMPLOYEE_SESSIONS_CLEAR = 'EMPLOYEE_SESSIONS_CLEAR';

const employeeSessionsRequest = payload => ({
  type: EMPLOYEE_SESSIONS_REQUEST,
  payload,
});

const employeeSessionsSuccess = payload => ({
  type: EMPLOYEE_SESSIONS_SUCCESS,
  payload,
});

const employeeSessionsFailure = payload => ({
  type: EMPLOYEE_SESSIONS_FAILURE,
  payload,
});

export const employeeSessionsClear = () => ({
  type: EMPLOYEE_SESSIONS_CLEAR,
});

export const employeeSessionsFetch = (id: number | string, limit: number, offset: number): ?Object => async (dispatch) => {
  const params = { limit, offset };
  dispatch(employeeSessionsRequest({ id, ...params }));
  try {
    const res = await api(`food/employees/${id}/auth/sessions`, 'get', { params });
    const data = pipe(
      prop('data'),
      omit(['result']),
      toCamelCase,
    )(res);
    dispatch(employeeSessionsSuccess(data));
  } catch (error) {
    dispatch(employeeSessionsFailure({ err: 'Запрос списка сессий сотрудника не удался', error }));
  }
};
