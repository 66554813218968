/* eslint-disable ft-flow/no-types-missing-file-annotation */
import { createSelector } from 'reselect';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import values from 'ramda/es/values';

import comparator from 'ramda/es/comparator';
import toLower from 'ramda/es/toLower';
import sort from 'ramda/es/sort';
import path from 'ramda/es/path';
import trim from 'ramda/es/trim';

import get from 'app/common/lib/get';
import toFixedValue from 'app/common/lib/toFixedValue';


export const defSettings = {
  selectable: true,
  photo: true,
  portion: true,
  cost: true,
  setup: false,
};

export type SettingsType = {
  selectable: boolean,
  photo: boolean,
  portion: boolean,
  cost: boolean,
  setup: boolean,
};

type Props = {
  statePath?: string | Array<string>,
  settings?: Object,
};

const sortByCategoryTitle = sort(comparator((dish1, dish2) => (
  toLower(path(['params', 'title'], dish1)) < toLower(path(['params', 'title'], dish2))
)));

const selectDishes = createSelector(
  [
    (state, props: Props) => get(state, props.statePath, state.orgs.dishes.items),
    (_, props: Props) => props.settings,
  ],
  (items, settings) => pipe(
    values,
    map(item => ({
      key: item.id,
      id: item.id,
      settings: settings || defSettings,
      params: {
        title: trim(item.title),
        photo: item.photoUrl,
        portion: item.portion,
        cost: toFixedValue(parseFloat(item.cost)),
      },
      locked: true,
    })),
    sortByCategoryTitle,
  )(items),
);

export default selectDishes;
