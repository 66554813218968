import { Provider } from 'react-redux'
import { Route } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import moment from 'moment-timezone'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import App from 'app/components/App'
import 'app/common/fonts/fonts.scss'
import 'app/common/fonts/fonts'

import store from './store'
import history from './history'
import './styles/index.scss'


// Русский момент во всем проекте
moment.locale('ru')

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffb400',
      main: '#ffb400',
      dark: '#ffb400',
    },
    contrastThreshold: 1,
  },
})

const Application = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <Route component={App} />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default Application
