// @flow
import { useEffect, useState, useCallback, useMemo } from 'react';
import { sortWith, ascend, prop, concat, pipe, sum, map, reject, identity, move } from 'ramda';

import { GET_VENDING_ITEMS_CATEGORIES, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/categories/get';
import type { Categories, Category } from 'app/dataTypes/vending/production/Categories.types';
import { api } from 'app/api';


type Transform = (Array<Category>) => Array<Category>

const createCategoriesAll = (
  defaultCategoryId: string,
  transform: Transform,
): Transform => pipe(
  reject(prop('deleted')),
  sortWith([
    ascend(prop('index')),
    ascend(prop('title')),
  ]),
  items => concat([{
    id: defaultCategoryId,
    title: 'Все',
    deletable: false,
    deleted: false,
    index: -1,
    placeId: '',
    quantity: sum(map(prop('quantity'), items)),
  }], items),
  transform,
);

export default function useCategories(placeId?: string, defaultCategoryId: string): {
  categories: Categories,
  loading: boolean,
  newCategoryAdd: (c: Category) => void,
  setCategories: ((Categories => Categories) | Categories) => void,
  moveCategory: (from: number, to: number) => void,
  updateCategoryQuantity: (c: Category | Array<Category>) => void,
} {
  const [categories, setCategories] = useState<Categories>([]);
  const [loading, setLoading] = useState(true);
  const [transform, setTransform] = useState<Transform>((): Transform => identity);

  useEffect(() => {
    if (!placeId) return;
    const request = async () => {
      setLoading(true);
      const res = await api.request(GET_VENDING_ITEMS_CATEGORIES, {
        error: 'Не удалось получить категории',
        params: {
          placeId,
        },
        convertions: {
          convertParams,
          convertResult,
        },
      });

      if (res) {
        setCategories(res);
      }
      setLoading(false);
    };

    request();
  }, [placeId]);

  const newCategoryAdd = useCallback((c: Category) => {
    setCategories(current => ([...current, c]));
  }, []);

  const moveCategory = useCallback((from: number, to: number) => {
    setTransform((): Transform => move(from, to));
  }, []);

  const handleSetCategories = useCallback((fn: (Categories => Categories) | Categories): void => {
    setTransform((): Transform => identity);
    setCategories(fn);
  }, []);

  const updateCategoryQuantity = useCallback((cat: Category | Array<Category>) => {
    const quantityById = new Map(map(
      ({ id, quantity }) => ([id, quantity]),
      cat instanceof Array ? cat : [cat],
    ));
    setCategories(map((category) => {
      const { id } = category;
      const quantity = quantityById.get(id);
      if (quantity !== undefined) {
        return { ...category, quantity };
      }
      return category;
    }));
  }, []);

  const categoriesAll = useMemo(() => {
    return createCategoriesAll(defaultCategoryId, transform)(categories);
  }, [categories, defaultCategoryId, transform]);

  return {
    categories: categoriesAll,
    loading,
    newCategoryAdd,
    setCategories: handleSetCategories,
    moveCategory,
    updateCategoryQuantity,
  };
}
