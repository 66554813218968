import React from 'react';
import classNames from 'classnames/bind';

import styles from './PlainGrid.scss';


const cx = classNames.bind(styles);

type Props = {
  children: any,
  div?: number,
  className?: string,
}

const PlainColumn = ({ children, div, className }: Props) => (<div className={cx({ column: true, [`div${div}`]: true }, className)}>
  {children}
</div>);

PlainColumn.defaultProps = {
  div: 1,
};

export default PlainColumn;
