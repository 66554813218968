import { Fragment, useCallback, useState } from 'react'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainTooltip from 'app/common/ui-next/plain-tooltip'
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon'

import ClearKitForm from './ClearKitForm'


type Props = {
  orgID: string,
  kitId: number,
  date: string,
  title: string,
}

const MainContentClearAll = ({ orgID, kitId, date, title }: Props) => {
  const [clearAll, showClearAll] = useState(false)

  const showModal = useCallback(() => {
    showClearAll(true)
  }, [])

  const hideModal = useCallback(() => {
    showClearAll(false)
  }, [])

  return (
    <Fragment>
      <PlainButtonAsLink onClick={showModal}>
        <PlainTooltip text="Очистить комплект">
          <PlainIcon
            width={14}
            height="auto"
            type={iconTypes.LIGHT_CROSS}
            colorType={colorTypes.COLOR_BLACK}
          />
        </PlainTooltip>
      </PlainButtonAsLink>

      {clearAll
        && <ClearKitForm
          orgId={orgID}
          kitId={kitId}
          date={date}
          title={title}
          onSuccess={hideModal}
          onCancel={hideModal}
        />}
    </Fragment>
  )
}

export default MainContentClearAll
