// @flow
import React, { useCallback } from 'react';

import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useDebouncedSearch from 'app/common/hooks/useDebouncedSearch';
import { useFilterData } from 'app/common/ui-components/filter';
import ControlsBar from 'app/common/ui-next/controls-bar';
import { OpenFilterButton } from 'app/common/ui-next/plain-button';
import { scrollTop } from 'app/common/lib/scroll';


type Props = {
  onSearch: (s: string) => void,
  onFilterToggle: (b: ((a: boolean) => boolean) | boolean) => void,
  filterActive: boolean,
}

const VendingFilterBar = ({ onSearch, onFilterToggle, filterActive }: Props) => {
  const resetSearch = useCallback(() => onSearch(''), [onSearch]);
  const [searchString, search, clearSearch] = useDebouncedSearch(onSearch, resetSearch);

  const [{ applied }] = useFilterData();

  const handleToggleFilter = useCallback((e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    scrollTop();
    onFilterToggle(state => !state);
  }, [onFilterToggle]);

  return (
    <ControlsBar>
      {/* Кнопка открытия фильтра */}
      <OpenFilterButton
        onClick={handleToggleFilter}
        checked={!!applied}
        opened={filterActive}
      />
      {/* Форма поиска */}
      <PlainInputSearch
        placeholder="Поиск"
        searchString={searchString}
        handleSearch={search}
        handleClearSearch={clearSearch}
      />
    </ControlsBar>
  );
};

export default VendingFilterBar;
