import { Fragment, useRef, ChangeEvent, KeyboardEvent, useCallback, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import cn from 'classnames/bind'

import { DotsLoader } from 'app/common/templates-next/page-template'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import useStateUnduplicated from 'app/common/hooks/useStateUnduplicated'

import PlainTooltip from '../plain-tooltip'
import CharsCounter from './helpers/chars-counter'

import styles from './plain-input.scss'


// TODO место для иконки-текста справа, в том числе для пополнения счета в финансах
// по примеру ui/PlainInput

const cx = cn.bind(styles)

export type Ref = HTMLInputElement

export type PlainInputProps = {
  id?: string,
  name?: string,
  className?: string,
  inputClassName?: string,
  placeholder?: string,
  value?: string,
  defaultValue?: string,
  disabled?: boolean,
  displayAsBlock?: boolean,
  loading?: boolean,
  readOnly?: boolean,
  error?: boolean,
  success?: boolean,
  maxChars?: number,
  autoComplete?: string,
  type?: 'tel' | 'password' | 'text' | 'number',
  autoFocus?: boolean,
  form?: string,
  wide?: boolean,
  'data-testid'?: string,
  required?: boolean,
  inputmode?: 'numeric'
  onChange?: (e: ChangeEvent<Ref>) => void,
  onValue?: (value: string, e: ChangeEvent<Ref>) => void,
  onValueExist?: (hasValue: boolean) => void
  onBlur?: (e: ChangeEvent<Ref>) => void,
  onFocus?: (e: ChangeEvent<Ref>) => void,
  onKeyPress?: (e: KeyboardEvent<Ref>) => void,
  onKeyUp?: (e: KeyboardEvent<Ref>) => void,
  onKeyDown?: (e: KeyboardEvent<Ref>) => void,
}

const PlainInput = forwardRef<Ref, PlainInputProps>(({
  id,
  name,
  className,
  inputClassName,
  placeholder,
  value,
  defaultValue,
  disabled,
  displayAsBlock,
  loading,
  readOnly,
  error,
  success,
  maxChars,
  autoComplete,
  type,
  autoFocus,
  form,
  wide,
  'data-testid': dataTestId,
  required,
  inputmode,
  onChange,
  onValue,
  onValueExist,
  onBlur,
  onFocus,
  onKeyPress,
  onKeyUp,
  onKeyDown,
}, ref) => {
  const inputRef = useRef<Ref | null>(null)
  useImperativeHandle(ref, () => inputRef.current!)

  const [, setValueExist] = useStateUnduplicated({ initialState: !!value, onChange: onValueExist })
  const [charCount, setCharCount] = useState(value?.length || 0)

  const rootClassName = cx(styles.root, { displayAsBlock }, { wide }, className)

  const onChangeRef = useCallbackRef(onChange)
  const onValueRef = useCallbackRef(onValue)

  const handleChange = useCallback((e: ChangeEvent<Ref>) => {
    const { value: currentValue } = e.currentTarget
    const currentValueTrimed = currentValue.trim()

    const inputCharsCount = currentValueTrimed.length
    setCharCount(inputCharsCount)

    const inputHasValue = inputCharsCount > 0
    setValueExist(inputHasValue)

    onChangeRef.current?.(e)
    onValueRef.current?.(currentValue, e)
  }, [onChangeRef, onValueRef, setValueExist])

  useEffect(() => {
    const inputEl = inputRef.current
    if (inputEl) {
      const inputCharsCount = inputEl.value.length
      setCharCount(inputCharsCount)

      const inputHasValue = inputCharsCount > 0
      setValueExist(inputHasValue)
    }
  }, [])

  const events = {
    onChange: handleChange,
    onBlur,
    onFocus,
    onKeyPress,
    onKeyUp,
    onKeyDown,
  }

  const content = (
    <Fragment>
      <input
        id={id}
        name={name}
        ref={inputRef}
        type={type}
        className={cx(styles.input, { error, success }, inputClassName)}
        placeholder={placeholder}
        disabled={disabled || loading}
        readOnly={readOnly}
        tabIndex={readOnly ? -1 : 0}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
        form={form}
        data-testid={dataTestId}
        required={required}
        inputMode={inputmode}
        {...events}
      />

      {loading
        && <DotsLoader className={styles.loader} />}

      {maxChars
        && !disabled
        && !loading
        && <CharsCounter
          className={styles.charsCounter}
          count={charCount}
          maxCount={maxChars}
        />}
    </Fragment>
  )

  return (
    disabled
      ? <PlainTooltip text={value} className={rootClassName}>
        {content}
      </PlainTooltip>
      : <div className={rootClassName}>
        {content}
      </div>
  )
})

export default PlainInput
