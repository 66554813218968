import { classesListFilter, classesListFilterSelection, customerSearchFilter, customerSearchPhraseFilter, customerTypeFilter, customerTypeFilterSelection, defaultOrgOrUserData, defaultOrgTypesDataReceiver, defaultOrgsDataReceiver, defaultStatementStatusesDataReceiver, getCustomerSearchDataReceiver, orgOrUserFilter, orgOrUserFilterSelection, orgTypesFilter, orgTypesFilterSelection, orgsFilter, orgsFilterSelection, periodFilter, schoolYearFilter, schoolYearFilterSelection, statementStatusesFilter, statementStatusesFilterSelection, studentListFilter, studentListFilterSelection } from 'app/dataProviders/filter-items'


export const filterInit = () => {
  customerSearchFilter.addDataReceiver(
    getCustomerSearchDataReceiver,
  )
  customerSearchFilter.dataReceive()
  customerSearchFilter.setStatusReady()

  orgOrUserFilter.updateData(
    defaultOrgOrUserData,
  )
  orgOrUserFilter.setStatusReady()

  orgTypesFilter.addDataReceiver(
    defaultOrgTypesDataReceiver,
  )
  orgTypesFilter.dataReceive()
  orgTypesFilter.setStatusReady()

  orgsFilter.addDataReceiver(
    defaultOrgsDataReceiver,
  )
  orgsFilter.dataReceive()
  orgsFilter.setStatusReady()

  statementStatusesFilter.addDataReceiver(
    defaultStatementStatusesDataReceiver,
  )
  statementStatusesFilter.dataReceive()
  statementStatusesFilter.setStatusReady()


  return () => {
    customerSearchPhraseFilter.reset()
    orgOrUserFilterSelection.reset()
    orgsFilterSelection.reset()
    orgTypesFilterSelection.reset()
    customerTypeFilterSelection.reset()
    schoolYearFilterSelection.reset()
    classesListFilterSelection.reset()
    studentListFilterSelection.reset()
    statementStatusesFilterSelection.reset()

    customerSearchFilter.reset()
    orgOrUserFilter.reset()
    orgsFilter.reset()
    orgTypesFilter.reset()
    customerTypeFilter.reset()
    schoolYearFilter.reset()
    classesListFilter.reset()
    studentListFilter.reset()
    statementStatusesFilter.reset()
    periodFilter.reset()
  }
}

export const resetToDefaultAll = () => {
  customerSearchPhraseFilter.resetToDefault()
  customerSearchFilter.resetToDefault()
  orgOrUserFilterSelection.resetToDefault()
  orgsFilterSelection.resetToDefault()
  orgTypesFilterSelection.resetToDefault()
  customerTypeFilterSelection.resetToDefault()
  schoolYearFilterSelection.resetToDefault()
  classesListFilterSelection.resetToDefault()
  studentListFilterSelection.resetToDefault()
  statementStatusesFilterSelection.resetToDefault()
  periodFilter.resetToDefault()
}
