// @flow
import { useMemo } from 'react';

import type { ReportTotal } from './useReportData';
import { displayableDataTotal, type DisplayableData } from './displayableData';


export default function useTotalsSummary(data: ReportTotal): DisplayableData {
  const res = useMemo(() => {
    return displayableDataTotal(data);
  }, [data]);

  return res;
}
