// @flow
import React from 'react';

import { FilterDataProvider } from 'app/common/ui-components/filter';
import { RightsByOrgProvider } from 'app/rights/RightsByOrg';

import orgsListFilterItems from './filter/orgsListFilterItems';
import OrgsListMain, { type Props } from './OrgsListMain';


const OrgsList = (props: Props) => {
  return (
    <FilterDataProvider filters={orgsListFilterItems}>
      <RightsByOrgProvider>
        <OrgsListMain {...props} />
      </RightsByOrgProvider>
    </FilterDataProvider>
  );
};

export default OrgsList;
