import api from 'app/actions/api';


export const ORGS_PRESENCE_SUCCESS = 'ORGS_PRESENCE_SUCCESS';
export const ORGS_PRESENCE_CLEAR = 'ORGS_PRESENCE_CLEAR';
export const ORGS_PRESENCE_FAILURE = 'ORGS_PRESENCE_FAILURE';

const orgsPresenceSuccess = payload => ({
  type: ORGS_PRESENCE_SUCCESS,
  payload,
});

export const orgsPresenceClear = () => ({
  type: ORGS_PRESENCE_CLEAR,
});
const orgsPresenceFailure = payload => ({
  type: ORGS_PRESENCE_FAILURE,
  payload,
});

export const orgsPresenceFetch = id => async (dispatch) => {
  try {
    const { data } = await api(`food/school/${id}/menu_presence`, 'get');
    dispatch(orgsPresenceSuccess({ [id]: (data && data.presence) }));
  } catch (error) {
    dispatch(orgsPresenceFailure({ error }));
  }
};
