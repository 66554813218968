import moment from 'moment-timezone';
// узнать почему слэши в переключателях


const firstDayOfWeek = 1;

const localeUtils = {
  formatDay: d => moment(d).format('dddd, D MMMM YYYY'),
  // `${weekdaysLong[d.getDay()]}, ${d.getDate()} ${months(locale)[d.getMonth()]} ${d.getFullYear()}`,
  formatWeekdayShort: index => moment.weekdaysShort()[index], // weekdaysShort[index],
  formatWeekdayLong: index => moment.weekdays()[index], // weekdaysLong[index],
  getFirstDayOfWeek: () => firstDayOfWeek,
  getMonths: () => moment.months(),
  formatMonthTitle: d => moment(d).format('MMMM YYYY'), // `${months(locale)[d.getMonth()]} ${d.getFullYear()}`,
};

// default utils:

// formatDay: formatDay,
// formatMonthTitle: formatMonthTitle,
// formatWeekdayShort: formatWeekdayShort,
// formatWeekdayLong: formatWeekdayLong,
// getFirstDayOfWeek: getFirstDayOfWeek,
// getMonths: getMonths


export default localeUtils;
