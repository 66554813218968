// @flow
import { type State } from 'app/common/ui-components/filter';

import { studentActivity as studentActivityDefault } from 'app/components/common/reports/filters/student';
import { flags as currentStateDateFlags } from 'app/components/common/reports/filters/currentStateDate';


const studentActivity = (state: State) => {
  return (
    studentActivityDefault(state)
    && currentStateDateFlags.currentStateRadioSelected(state)
  );
};

export default studentActivity;
