// @flow
import { type Node } from 'react';

import useImportXLSData from './useImportXLSData';


type Props = {
  children: Node,
}

const ImportedDataReady = ({ children }: Props) => {
  const { content: { fileId } } = useImportXLSData();
  const dataReady = Boolean(fileId);

  return (dataReady ? children : null);
};

export default ImportedDataReady;
