import { ReactNode, forwardRef } from 'react'
import cn from 'classnames'

import styles from './plain-surface.scss'


export type Props = {
  children: ReactNode,
  className?: string,
  id?: string,
  containerComponent?: keyof JSX.IntrinsicElements
  role?: string,
  contentPadding?: number,
  'aria-labelledby'?: string,
  'aria-describedby'?: string,
}

const PlainSurface = forwardRef<any, Props>(({
  children,
  className,
  id,
  containerComponent: ElementType = 'div',
  role,
  contentPadding = 25,
  'aria-labelledby': ariaLabelledby,
  'aria-describedby': ariaDescribedby,
}: Props, ref) => {
  const style = { padding: contentPadding }
  return (
    <ElementType
      // @ts-ignore
      ref={ref}
      style={style}
      className={cn(styles.root, className)}
      id={id}
      role={role}
      aria-labelledby={ariaLabelledby}
      aria-describedby={ariaDescribedby}
    >
      {children}
    </ElementType>
  )
})

export default PlainSurface
