// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames/bind';

import { Tr, Td, Th, getPropsHeader } from 'app/common/ui-components/table';

import type { RowCells } from './useRowCellsData';
import styles from './Row.scss';


const cx = cn.bind(styles);

type Props = {
  data: RowCells,
  hidden?: boolean,
  reference?: Ref<'tr'>,
  hilighted?: boolean,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
}

const Row = ({
  data,
  hidden,
  reference,
  hilighted,
  onMouseEnter,
  onMouseLeave,
}: Props): Node => {
  const headerLinkProps = getPropsHeader();

  return (
    <Tr
      reference={reference}
      hidden={hidden}
      className={cx({ hilighted })}
    >
      {data.map(({ value, head, ...others }) => {
        const { key } = others;
        const Cell = head ? Th : Td;

        return (
          <Cell
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...(hidden ? {} : headerLinkProps(key))}
            {...others}
          >
            {value}
          </Cell>
        );
      })}
    </Tr>
  );
};

export default Row;
