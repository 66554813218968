// @flow
import moment from 'moment-timezone';

import { type StateDataCurrent } from 'app/common/ui-components/filter';


export const dateFormat = 'YYYY-MM-DD';

type Item = { id: string, title: string };

const current = {
  id: 'current',
  title: 'Текущее состояние',
};

const date = {
  id: 'date',
  title: 'Выбор даты %date%',
};

const currentStateDateItems = (): [Array<Item>, StateDataCurrent] => (
  [[current, date], ['current', moment().format(dateFormat)]]
);

export default currentStateDateItems;
