import dissocPath from 'ramda/es/dissocPath';
import assocPath from 'ramda/es/assocPath';

import {
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAILURE,
  CATEGORY_COUNT_SET,
} from 'app/actions/orgs/dishes/categories';

import { ADD_CATEGORY_SUCCESS } from 'app/actions/orgs/dishes/addCategory';
import { DELETE_CATEGORY_SUCCESS } from 'app/actions/orgs/dishes/deleteCategory';


const defaultState = {
  loading: false,
  categories: {},
  result: null,
};

const categories = (state = defaultState, { type, payload }) => {
  switch (type) {
    case CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }

    case CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: { ...state.categories, ...payload.categories },
      };
    }

    case DELETE_CATEGORY_SUCCESS: {
      return dissocPath(['categories', `${payload}`], state);
    }

    case CATEGORY_COUNT_SET: {
      return assocPath(['categories', payload.categoryId, 'dishes_count'], payload.count, state);
    }

    default:
  }
  return state;
};

export default categories;
