// @flow

export default (str: string | null): number => {
  let hash = 0;
  if (!str) return hash;
  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) - hash) + str.charCodeAt(i); // eslint-disable-line
    hash = hash & hash; // eslint-disable-line
  }
  return hash;
};
