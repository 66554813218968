import path from 'ramda/es/path';

import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const SELLPOINTS_CHANGE_ARRAY_REQUEST = 'orgs/settings/SELLPOINTS_CHANGE_ARRAY_REQUEST';
export const SELLPOINTS_CHANGE_ARRAY_SUCCESS = 'orgs/settings/SELLPOINTS_CHANGE_ARRAY_SUCCESS';
export const SELLPOINTS_CHANGE_ARRAY_FAILURE = 'orgs/settings/SELLPOINTS_CHANGE_ARRAY_FAILURE';

export const changeSellPointsArray = (orgId, data) => async (dispatch) => {
  dispatch(action(SELLPOINTS_CHANGE_ARRAY_REQUEST, { orgId, data }));
  try {
    const res = await api(`food/schools/${orgId}/sale_points/deleted`, 'put', { data });
    const sellpoints = path(['data', 'sale_points'], res);
    dispatch(action(SELLPOINTS_CHANGE_ARRAY_SUCCESS, sellpoints));
    return true;
  } catch (error) {
    dispatch(action(SELLPOINTS_CHANGE_ARRAY_FAILURE, {
      err: 'Не удалось модифицировать точки продаж',
      error,
      orgId,
      data,
    }));
    return false;
  }
};
