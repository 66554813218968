// @flow
import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';

import HistoryDownloadButton from './history-download-button';
import HistoryMailButton from './history-mail-button';

import styles from './reports-history-default-options.scss';

/**
 *  Ячейка с кнопками загрузки и отправки по email
 *  в таблице истории генерации файлов отчетов
 */

const INTERVAL = 3000;

type Props = {
  reportId: number,
  status: number,
  email?: string | null,
  onSendFileToEmail: (reportId: number, email: string) => Promise<void>,
  onDownloadFile: (reportId: number) => Promise<void>,
  onCheckStatus: (reportId: number) => Promise<number | null>
}

const ReportsHistoryDefaultOptions = ({
  reportId,
  status,
  email,
  onSendFileToEmail,
  onDownloadFile,
  onCheckStatus,
}: Props) => {
  const [process, setProcess] = useState(0);
  const intervalId = useRef(null);

  const [downloadingFile, setDownloadingFile] = useState(false);

  const downloadFile = useCallback(async () => {
    setDownloadingFile(true);
    await onDownloadFile(reportId);
    setDownloadingFile(false);
  }, [reportId, onDownloadFile]);


  const [sendingFile, setSendingFile] = useState(false);

  const sendFileToEmail = useCallback(async () => {
    if (!email) return;
    setSendingFile(true);
    await onSendFileToEmail(reportId, email);
    setSendingFile(false);
  }, [reportId, email, onSendFileToEmail]);


  const buttonsReady = !(status < 100);
  const cancelled = status === -2;
  const error = status < 0;
  const inProgress = !error && !buttonsReady;


  // таймер, не проверено
  const runInterval = useCallback(() => {
    setInterval(async () => {
      const res = await onCheckStatus(reportId);
      if (typeof res === 'number') {
        setProcess(res);
        intervalId.current = runInterval();
      }
    }, INTERVAL);
  }, [reportId, onCheckStatus]);


  // запуск и очистка таймера, не проверено
  useEffect(() => {
    if (inProgress && process < 100 && process >= 0) {
      intervalId.current = runInterval();
    }
  }, [inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, []);


  return (
    <div className={styles.root}>
      {buttonsReady
        && <Fragment>
          <HistoryDownloadButton
            className={styles.item}
            disabled={downloadingFile}
            onClick={downloadFile}
          />

          {email
            && <HistoryMailButton
              className={styles.item}
              disabled={sendingFile}
              onClick={sendFileToEmail}
            />}
        </Fragment>}

      {error
        && !cancelled
        && <span className={styles.error}>{'ошибка'}</span>}

      {cancelled
        && <span className={styles.error}>{'отменен'}</span>}

      {inProgress
        && <span className={styles.error}>{`${process || status}%`}</span>}
    </div>
  );
};

export default ReportsHistoryDefaultOptions;
