import { ChangeEvent, useCallback } from 'react'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import PlainRadioButton from 'app/common/ui-next/plain-radio-button'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


type Props = {
  name: string
  title: string
  data: [boolean, boolean]
  disabled?: boolean
  formID: string
  onChange?: (name: string, checked: boolean, index: number) => void
}

const FormGridRowRadio = ({
  name,
  title,
  data: pair,
  disabled,
  formID,
  onChange,
}: Props) => {
  const onChangeRef = useCallbackRef(onChange)

  const handleCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name: nm } = e.currentTarget
    onChangeRef.current?.(
      name,
      checked,
      parseInt(nm.replace(/\D/g, ''), 10) - 1
    )
  }, [onChangeRef, name])

  return (
    <tr>
      <td>{title}</td>
      <td>
        <PlainCheckbox
          label={`${title} - Показывать в форме создания справки`}
          hideLabel
          name={`${name}1`}
          value="active"
          checked={pair[0]}
          onChange={handleCheckbox}
          disabled={disabled}
          form={formID}
        />
      </td>
      <td>
        <PlainRadioButton
          label={`${title} - Выбрано принудительно`}
          hideLabel
          name={`${name}2`}
          value="active"
          checked={pair[1]}
          onChange={handleCheckbox}
          disabled={disabled}
          form={formID}
        />
      </td>
    </tr>
  )
}

export default FormGridRowRadio
