// @flow
import { api } from 'app/api';
import { GET_PLANORGAM_SLOTS_CONTENT, convertParams, convertResult } from 'app/common/api/requests/data/vending/machines/slots/content/get';
import type { PlanogramContent } from 'app/dataTypes/vending/PlanogramContent.types';


const requestPlanogramSlotsContent = async (machineId: string): Promise<PlanogramContent | null> => {
  const res = await api.request(GET_PLANORGAM_SLOTS_CONTENT, {
    error: 'Не удалось получить наполнение ячеек планограммы',
    params: {
      machineId,
    },
    convertions: {
      convertParams,
      convertResult,
    },
  });

  return res;
};

export default requestPlanogramSlotsContent;
