import {
  LISTEDIT_ADD,
  LISTEDIT_REMOVE,
  LISTEDIT_CLEAR,
  LISTEDIT_ITEM_SAVE,
} from 'app/actions/orgs/dishes/listedit';

const defaultState = {
  add: [],
  remove: [],
  items: {},
  sending: false,
};

const listedit = (state = defaultState, action) => {
  if (action.type === LISTEDIT_ADD) {
    return state.remove.includes(action.payload)
      // если есть в удаленных, то просто убираем оттуда
      ? { ...state, remove: state.remove.filter(key => key !== action.payload) }
      // если нет то добавляем в добавленные
      : { ...state, add: [...state.add, action.payload] };
  }
  if (action.type === LISTEDIT_REMOVE) {
    return state.add.includes(action.payload)
      // если уже есть в добавленных, просто убираем оттуда
      ? { ...state, add: state.add.filter(key => key !== action.payload) }
      // если - нет, то добавляем в удаленные
      : { ...state, remove: [...state.remove, action.payload] };
  }
  if (action.type === LISTEDIT_ITEM_SAVE) {
    return ({ ...state, items: { ...state.items, ...action.payload } });
  }
  if (action.type === LISTEDIT_CLEAR) {
    return defaultState;
  }
  return state;
};

export default listedit;
