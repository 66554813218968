// @flow
import React, { useMemo, useCallback, type Node } from 'react';
import classNames from 'classnames/bind';
import toPairs from 'ramda/es/toPairs';
import keys from 'ramda/es/keys';
import length from 'ramda/es/length';
import assoc from 'ramda/es/assoc';
import sortBy from 'ramda/es/sortBy';
import prop from 'ramda/es/prop';
import MaskedInput from 'react-maskedinput';
import normalizeString from 'app/common/lib/normalizeString';
import { useTranslation } from 'react-i18next';

import PlainInput from 'app/common/ui/PlainInput';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { ModalFooter } from 'app/common/components/Modal';

import identifiersFormats, {
  identifiersNames,
  identifiersMasks,
  identifiersValidators,
  identifiersTransforms,
} from 'app/common/constants/identifiers/formats';

import styles from './entering-form.scss';


const cx = classNames.bind(styles);

export type IdentifierFormat = $Values<typeof identifiersFormats> | string

export type Values = {
  [key: IdentifierFormat]: string,
}

type Props = {
  values: {
    [key: IdentifierFormat]: string,
  },
  current: IdentifierFormat,
  error: string,
  hideButtons?: boolean,
  checking?: boolean,
  locked?: boolean,
  formId: string,
  onSetCurrent: (current: IdentifierFormat) => void,
  onSetValues: (values: Values | (v: Values) => Values) => void,
  onCancel: () => void,
  onIdentifierCheck: (e: SyntheticInputEvent<HTMLFormElement>) => Promise<void>,
  onResetError: () => void,
  children?: Node,
}

const EnteringForm = ({
  values,
  current,
  error,
  hideButtons,
  checking,
  locked,
  formId,
  onSetCurrent,
  onSetValues,
  onCancel,
  onIdentifierCheck,
  onResetError,
  children,
}: Props) => {
  const { t } = useTranslation('identifiers-next');

  const items = useMemo(() => sortBy(prop(0), toPairs(values)), [values]);
  const len = length(items);
  const availableKeys = keys(values);

  const handleChangeValues = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    const key: IdentifierFormat | void = availableKeys.find(k => k === name);
    if (key) {
      onResetError();
      onSetValues(assoc(name, value));
    }
  }, [onSetValues, availableKeys, onResetError]);

  const handleChangeCurrent = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (checked) {
      const key: IdentifierFormat | void = availableKeys.find(k => k === value);
      if (key) {
        onResetError();
        onSetCurrent(key);
      }
    }
  }, [onSetCurrent, availableKeys, onResetError]);

  return (
    <form onSubmit={onIdentifierCheck} id={`identifierCheck_${formId}`}>
      {items.map(([key, value]) => {
        const mask = identifiersMasks[key];
        const validator = identifiersValidators[key];
        const transform = identifiersTransforms[key];
        const disabled = key !== current || checking || locked;
        const isError = error && key === current;
        const errorText = key === current ? error : '';
        const className = cx(styles[key], { errorSpace: isError });

        const commonProps = {
          name: key,
          value,
          disabled,
          className,
          onChange: handleChangeValues,
          isError,
          errorText,
        };

        const props = mask || validator || transform ? {
          component: MaskedInput,
          mask,
          formatCharacters: validator || transform ? {
            X: {
              validate: validator,
              transform,
            },
          } : null,
          ...commonProps,
        } : commonProps;

        const radioId = `${formId}_${key}`;

        return (
          <div className={styles.row} key={key}>
            <label htmlFor={radioId} className={styles.label}>
              {identifiersNames[key] || normalizeString(key)}
            </label>

            {len > 1
              && <PlainRadioButton
                id={radioId}
                label={identifiersNames[key]}
                hideLabel
                name="identifier"
                value={key}
                onChange={handleChangeCurrent}
                checked={key === current}
                className={styles.radio}
                disabled={checking || locked}
              />}

            <div className={styles.inputContainer}>
              <PlainInput {...props} />
            </div>
          </div>
        );
      })}
      {children}
      {!hideButtons
        && <ModalFooter className={styles.footer}>
          <ControlsBar right>
            <PlainButton outline onClick={onCancel}>
              {t('identifier_entering_bt_cancel')}
            </PlainButton>
            <PlainButton type="submit" disabled={checking || locked}>
              {checking
                ? t('identifier_entering_bt_check_checking')
                : t('identifier_entering_bt_check')}
            </PlainButton>
          </ControlsBar>
        </ModalFooter>}
    </form>
  );
};
export default EnteringForm;
