// @flow
import React from 'react';

import Button, { ButtonsBar } from 'app/common/ui/Button';
import Modal from 'app/common/components/Modal';
import PlainInput from 'app/common/ui/PlainInput';

import styles from './AddItemModal.scss';
import enhanceAddItemModal from './enhanceAddItemModal';


export const lang = {
  header: 'Добавление точки продаж',
  label: 'Введите имя точки продаж:',
  submitButtonText: 'Добавить',
  cancelButtonText: 'Отмена',
}; // TODO сделать через пропс, когда будет использоваться в другом месте


type Props = {
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  handleSubmit: (e: SyntheticEvent<HTMLElement>) => void,
  value: string,
  saving: boolean,
  error: any,
  returnPath: string,
}

const AddItemModal = ({
  handleChange,
  handleSubmit,
  value,
  saving,
  error,
  returnPath,
}: Props) => (
  <Modal close={returnPath}>
    <h2 className={styles.header}>{lang.header}</h2>
    <form onSubmit={handleSubmit}>
      <div className={styles.fields}>
        <PlainInput
          label={lang.label}
          onChange={handleChange}
          disabled={saving}
          value={value}
          isError={!!error}
          errorText={error}
        />
      </div>
      <ButtonsBar className={styles.buttons}>
        <Button submit disabled={saving || !value || !!error}>
          {lang.submitButtonText}
        </Button>
        <Button href={returnPath} outline>
          {lang.cancelButtonText}
        </Button>
      </ButtonsBar>
    </form>
  </Modal>
);

export default enhanceAddItemModal(AddItemModal);
