import React, { useCallback, useEffect, useState } from 'react'
import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { subscibeTo } from 'app/common/data-box-store'
import { sortClasses } from 'app/common/lib/sort'
import { prop } from 'ramda'
import { OrgsOrUserIDs, classesListFilter, classesListFilterSelection, defaultClassesListDataReceiver, defaultClassesListSelection, orgOrUserFilterSelection, orgsFilterSelection, schoolYearFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

const sortByTitle = sortClasses('down', prop('title'))

export const ClassesListFilter = ({ id, pending }: Props) => {
  const [isReady, setReady] = useState<boolean>(false)
  const [isReadyOrgs, setReadyOrgs] = useState<boolean>(false)
  const [filterVisibility, setFilterVisibility] = useState<boolean>(true)

  const orgsHandler = useCallback((data: string[]) => {
    if (data.length === 1) {
      setReadyOrgs(true)
      return
    }
    classesListFilter.resetToDefault()
    setReadyOrgs(false)
  }, [])

  const schoolYearHandler = useCallback((data: string[]) => {
    if (data.length === 1) {
      classesListFilter.addDataReceiver(
        defaultClassesListDataReceiver,
      )
      classesListFilter.dataReceive()
      classesListFilter.setStatusReady()
      setReady(true)
      return
    }
    classesListFilter.resetToDefault()
    setReady(false)
  }, [])

  const orgOrUserFilterHandler = useCallback((data: OrgsOrUserIDs) => {
    if (data === OrgsOrUserIDs.ByOrgs) {
      setFilterVisibility(true)
      return
    }
    classesListFilter.resetToDefault()
    setFilterVisibility(false)
    setReady(false)
  }, [])


  useEffect(() => {
    const orgsUnsubsribe = subscibeTo<string[]>(orgsFilterSelection.id, orgsHandler)
    const schoolYearUnsubsribe = subscibeTo<string[]>(schoolYearFilterSelection.id, schoolYearHandler)
    const orgOrUserUnsubsribe = subscibeTo<OrgsOrUserIDs>(orgOrUserFilterSelection.id, orgOrUserFilterHandler)

    return (() => {
      orgsUnsubsribe()
      schoolYearUnsubsribe()
      orgOrUserUnsubsribe()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    filterVisibility && isReadyOrgs && isReady
    && <PlainItemsSelectorBox
      id={id}
      label="Класс"
      header="Выбор класса"
      dataBoxData={classesListFilter}
      dataBoxSelection={classesListFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      disabled={pending}
      multiple
      sortFunc={sortByTitle}
      defaultSelection={defaultClassesListSelection}
    />
  )
}
