// @flow
import { useState, useCallback, useMemo, useEffect } from 'react';

import {
  GET_IMPORT_DATA_TYPES,
  type ImportDataTypesResult,
} from 'app/common/api/requests/food/items/import_data_types';

import { api } from 'app/api';

/**
 *  Hook используемых в блюдах наименований колонок
 */

export default function useDataTypes(): [
  Array<string>, {
  request: () => Promise<void>,
}] {
  const [dataTypes, setDataTypes] = useState([]);

  const handleRequestDataTypes = useCallback(async () => {
    const res: ImportDataTypesResult | null = await api.request(GET_IMPORT_DATA_TYPES);
    if (res && (res instanceof Array)) {
      setDataTypes(res);
    }
  }, []);

  useEffect(() => {
    handleRequestDataTypes();
  }, [handleRequestDataTypes]);

  const apiHadlers = useMemo(() => ({
    request: handleRequestDataTypes,
  }), [handleRequestDataTypes]);


  return [dataTypes, apiHadlers];
}
