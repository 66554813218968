import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { api } from 'app/api'
import { GET_STUDENTS_LIST, convertParams, convertResult } from 'app/common/api/requests/reports/students_list/get'
import { StudentListType } from 'app/dataTypes/studentList'
import { ItemType } from 'app/common/ui-next/plain-selector'
import { orgsFilterSelection } from './orgsFilter'
import { classesListFilterSelection } from './classesListFilter'


type StudentList = Array<ItemType>

class StudentListFilterDataBox extends DataBox<StudentList> { }

export const defaultStudentList: StudentList = []
export const studentListFilter = createDataBox('studentListFilter', defaultStudentList, StudentListFilterDataBox)


const convertFromExtended = (studentList: StudentListType): StudentList => {
  return studentList.map(({ ID, name }) => {
    const title = Object.values(name).join(' ')
    return ({ id: ID, title })
  })
}


export const defaultStudentListDataReceiver = async () => {
  const orgIds = orgsFilterSelection.getData()
  const orgId = orgIds[0]
  const classIds = classesListFilterSelection.getData()
  const classId = classIds[0]

  const res = await api.request(GET_STUDENTS_LIST, {
    error: 'Не удалось получить данные классов',
    params: {
      schoolId: orgId,
      classId,
    },
    convertions: {
      convertParams,
      convertResult,
    },
  })
  return res ? convertFromExtended(res) : []
}

export const defaultStudentListSelection: Array<string> = []
export const studentListFilterSelection = createDataBoxDefault<Array<string>>('studentListFilterSelection', defaultStudentListSelection)
