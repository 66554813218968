// @flow

type Options = {
  orgType?: string,
}


const getBaseUrl = (options?: Options): string => {
  const { orgType } = options || {};

  if (orgType) {
    return `/orgslist/${orgType}`;
  }
  return '/orgslist';
};

export default getBaseUrl;
