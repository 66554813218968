// @flow
import React from 'react';

import PlainDropdown, { PlainDropdownDefitem as DItem } from 'app/common/ui-next/plain-dropdown';
import { DotsLoader } from 'app/common/components/Loader';

import styles from './AddingFormSaleType.scss';


type Props = {
  id: string,
  value: string,
  onChange: (itemProps: { id: string }) => void,
  loading: boolean,
  disabled: boolean,
  saleTypes: Array<{
    id: string,
    title: string,
  }>,
  errored: boolean,
}

const AddingFormSaleType = ({ id, value, onChange, loading, disabled, saleTypes, errored }: Props) => {
  return (
    <PlainDropdown
      defaultSkin
      id={id}
      label="Тип продаж"
      labelClassName="visuallyHidden"
      onChange={onChange}
      disabled={disabled}
      errored={errored}
      placeholder={
        value
          ? <DItem id={value} disabled={disabled}>
            {disabled ? <DotsLoader simple /> : value}
          </DItem>
          : <DItem id="-1" disabled={disabled} className={styles.placeholder}>
            {loading ? <DotsLoader simple /> : 'Выберите...'}
          </DItem>
      }
    >
      {saleTypes.map(({ id: id_, title }) => (id_ !== value
        ? <DItem key={id_} id={id_}>{title}</DItem>
        : null
      ))}
    </PlainDropdown>
  );
};

export default AddingFormSaleType;
