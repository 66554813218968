// @flow
import React from 'react';
import classNames from 'classnames/bind';

import IconStudent from 'app/common/icons/IconStudent';
import IconStudentFemale from 'app/common/icons/IconStudentFemale';
import IconStudentMale from 'app/common/icons/IconStudentMale';
import styles from './Avatar.scss';


const cx = classNames.bind(styles);

type Props = {
  src?: string,
  gender?: string,
  className?: string,
  active?: boolean,
};

class Avatar extends React.Component<Props> {
  renderIcon = () => {
    const { className, active, gender } = this.props;
    const iconClass = cx({ icon: true, activeSvg: active }, className);

    switch (gender) {
      case 'female': {
        return <IconStudentFemale className={iconClass} />;
      }
      case 'male': {
        return <IconStudentMale className={iconClass} />;
      }
      default: {
        return <IconStudent className={iconClass} />;
      }
    }
  };

  renderAva = (image: string) => {
    const { className, active } = this.props;
    const imageClass = cx({ ava: true, active }, className);

    return (
      <span className={imageClass}>
        <img src={image} alt="" />
      </span>
    );
  };

  render() {
    const { src } = this.props;
    return (src ? this.renderAva(src) : this.renderIcon());
  }
}

export default Avatar;
