import api from 'app/actions/api';
import action from 'app/common/lib/action';
import { popup } from 'app/common/actions/popup';
import i18n from 'app/i18n';


export const CHANGE_FIO_REQUEST = 'profile/CHANGE_FIO_REQUEST';
export const CHANGE_FIO_SUCCESS = 'profile/CHANGE_FIO_SUCCESS';
export const CHANGE_FIO_FAILURE = 'profile/CHANGE_FIO_FAILURE';

export const changeFIO = name => async (dispatch) => {
  dispatch(action(CHANGE_FIO_REQUEST));

  try {
    await api('account', 'put', {
      data: { name },
    });
    dispatch(action(CHANGE_FIO_SUCCESS, { name: { ...name } }));
    dispatch(popup(i18n.t('profile:msg_fio_changed_success')));
    return true;
  } catch (error) {
    dispatch(action(CHANGE_FIO_FAILURE, {
      err: i18n.t('profile:error_fio_change_failed'),
      error,
    }));
    return false;
  }
};
