import { createSelector } from 'reselect';
import values from 'ramda/es/values';
import pipe from 'ramda/es/pipe';
import sortBy from 'ramda/es/sortBy';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import trim from 'ramda/es/trim';

import toFixedValue from 'app/common/lib/toFixedValue';


const selectDishes = createSelector(
  [
    state => state.dishes.items.items,
  ],
  pipe(
    values,
    map(({ id, title, photoUrl, portion, cost }) => ({
      id,
      title: title ? trim(title) : '???',
      photo: photoUrl || '',
      portion,
      cost: toFixedValue(cost),
    })),
    sortBy(prop('title')),
  ),
);

export default selectDishes;
