// @flow

export type Sort = 'by_alphabet' | 'by_classes'

const BY_ALPHABET: 'by_alphabet' = 'by_alphabet';
const BY_CLASSES: 'by_classes' = 'by_classes';

export const sortTypes = { BY_ALPHABET, BY_CLASSES };

const sortItems = () => ([[{
  id: BY_ALPHABET,
  title: 'алфавиту',
}, {
  id: BY_CLASSES,
  title: 'классам',
}], [BY_ALPHABET]]);

export default sortItems;
