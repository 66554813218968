// @flow
import React from 'react';
import cn from 'classnames';

import styles from './PlainGrid.scss';


type Props = {
  children: any,
  className?: string,
}

const PlainColumns = ({ children, className }: Props) => (<div className={cn(styles.columns, className)}>
  {children}
</div>);

export default PlainColumns;
