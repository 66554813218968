import { KnownStatementStatus } from 'app/dataTypes/compensation/statement/status'


export const knownStatus = (status: string | number): string => {
  const s = String(status)
  if (s === '0') return KnownStatementStatus.Draft
  if (s === '1') return KnownStatementStatus.Consideration
  if (s === '2') return KnownStatementStatus.Processing
  if (s === '3') return KnownStatementStatus.Approved
  if (s === '4') return KnownStatementStatus.Rejected
  if (s === '5') return KnownStatementStatus.Deleted
  if (s === '6') return KnownStatementStatus.Canceled

  return s
}

export const statusToApi = (status: string): number => {
  if (status === KnownStatementStatus.Draft) return 0
  if (status === KnownStatementStatus.Consideration) return 1
  if (status === KnownStatementStatus.Processing) return 2
  if (status === KnownStatementStatus.Approved) return 3
  if (status === KnownStatementStatus.Rejected) return 4
  if (status === KnownStatementStatus.Deleted) return 5
  if (status === KnownStatementStatus.Canceled) return 6

  return parseInt(status, 10)
}
