// @flow
import type { Request } from '../index';

type ReportsStatusParams = {
  report_id: number,
}

type ReportsPerformResult = number

export const GET_REPORTS_STATUS: Request<
  ReportsStatusParams,
  ReportsPerformResult,
  void
> = [
  'reports/status',
  'get',
  ['data', 'status_in_percents'],
];


// DEPRECATED use src/common/api/requests/reports/status/get