import { forwardRef } from 'react'
import cn from 'classnames'

import PlainNav, { NavItem } from 'app/common/ui/PlainNav'
import { WithRightsForOrg } from 'app/rights/RightsForOrg'
import PlainLink from 'app/common/ui-next/plain-link'
import { OrgType } from 'app/dataTypes/org/types'

import useUrlBases from '../useUrlBases'
import useNavigationItems from './useNavigationItems'

import styles from './Navigation.scss'

/**
 *  Навигация ко всем подразделам определенного заведения
 */

type Props = {
  className?: string
  orgID: string
  date?: string
  orgType: OrgType
  additionalLinks?: Array<{
    title: string
    to: string
  }>,
}

const Navigation = forwardRef<HTMLDivElement, Props>(
  ({ className, orgID, date, orgType, additionalLinks }: Props, ref) => {
    const navItems = useNavigationItems(orgType)
    const urls = useUrlBases(orgID)

    return (
      <div className={cn(styles.root, className)} ref={ref}>
        <PlainNav>
          {navItems.map(({ type, title, createBasePath, rightsPath, othersPaths }) => {
            const pathTo = urls.get(type) || ''
            return (
              <WithRightsForOrg
                key={pathTo}
                pathTo={rightsPath}
                editAffect="ignore"
                othersPaths={othersPaths}
                combineWithOthersPaths="any"
              >
                <NavItem to={createBasePath(pathTo, date)}>
                  {title}
                </NavItem>
              </WithRightsForOrg>
            )
          })}
        </PlainNav>

        {additionalLinks
          && additionalLinks.length === 1
          && <PlainLink to={additionalLinks[0].to}>
            {additionalLinks[0].title}
          </PlainLink>}

        {additionalLinks
          && additionalLinks.length > 1
          && <PlainNav>
            {additionalLinks.map(({ title, to }) => (
              <NavItem to={to} key={to}>
                {title}
              </NavItem>
            ))}
          </PlainNav>}
      </div>
    )
  },
)

export default Navigation
