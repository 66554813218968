// @flow
import React, { useMemo, type ComponentType } from 'react';
import classNames from 'classnames/bind';
import length from 'ramda/es/length';
import isEmpty from 'ramda/es/isEmpty';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Pager from 'app/common/ui/Paging';
import { Table, useCopyColumnsWidths } from 'app/common/ui-components/table';
import { type Settings } from 'app/reducers/settings/dishes';
import shadows from 'app/common/containers/shadows';
import Loading from 'app/common/components/Loader';

import { useDishesData } from '../DishesData';
import { type FoodItem, type Paging } from './useAddingDishes';
import { DishesTableTHead, DishesTableRowItem } from '../DishesTable';
import styles from './AddingDishes.scss';


const cx = classNames.bind(styles);

type Props = {
  dishes: Array<FoodItem>,
  total: number,
  paging: Paging,
  settings: Settings,
  loading: boolean,
  emptyWarn: string,
}

type PropsWShadows = {
  ...Props,
  updateScroll: (Object) => void,
  isTopShadow: boolean,
  isBottomShadow: boolean,
}

const AddingDishes = ({
  dishes,
  total,
  paging,
  settings,
  loading,
  emptyWarn,
  updateScroll,
  isTopShadow,
  isBottomShadow,
}: PropsWShadows) => {
  const { currentPage, handlePage } = paging;
  const { rows: limit } = settings;

  const [{ nomenclatureActive }] = useDishesData();

  const nothing = isEmpty(dishes);

  const tableSettings = useMemo(() => {
    return {
      ...settings,
      check: false,
      options: false,
      photo: false,
    };
  }, [settings]);

  const updateKey = useMemo(() => ([]), [tableSettings, dishes]); // eslint-disable-line react-hooks/exhaustive-deps
  const [refFrom, refTo] = useCopyColumnsWidths(updateKey);


  return (
    <div className={styles.root}>
      {!nothing
        && <Table className={styles.table}>
          <DishesTableTHead
            settings={tableSettings}
            nomenclatureActive={nomenclatureActive}
            reference={refTo}
          />
        </Table>}

      {loading
        && !nothing
        && <Loading className={styles.loadingOverContent} />}

      <div className={styles.scrollbarsContainer}>
        <Scrollbars
          style={{ width: '100%', height: '100%' }}
          className={cx({
            isTopShadow,
            isBottomShadow,
          })}
          onUpdate={updateScroll}
        >
          {!loading
            && nothing
            && <div className={styles.emptyWarn}>{emptyWarn}</div>}

          {loading
            && nothing
            && <Loading />}

          {!nothing
            && <Table className={styles.table}>
              <DishesTableTHead
                settings={tableSettings}
                nomenclatureActive={nomenclatureActive}
                reference={refFrom}
                hidden
                dummy
              />
              <tbody>
                {dishes.map(({ id, ...props }) => (
                  <DishesTableRowItem
                    key={id}
                    id={id}
                    settings={tableSettings}
                    nomenclatureActive={nomenclatureActive}
                    loading={loading}
                    locked
                    {...props}
                  />
                ))}
              </tbody>
            </Table>}
        </Scrollbars>
      </div>

      <Pager
        className={styles.pager}
        total={total}
        pageSize={limit}
        currentPage={currentPage}
        currentPageSize={length(dishes)}
        onPage={handlePage}
      />
    </div>
  );
};


const AddingDishesWShadows: ComponentType<Props> = shadows(AddingDishes);
export default AddingDishesWShadows;
