// @flow
import React from 'react';
import classNames from 'classnames/bind';
import Link from 'app/common/lib/link';

import styles from './SideNav.scss';


const cx = classNames.bind(styles);

type Props = {
  children?: any,
  title?: string,
  to?: string,
  active?: boolean,
  collapsed?: boolean,
}

const SideNavItem = ({ children, title, to = '', active, collapsed }: Props) => (
  <Link
    className={cx({ item: true, active })}
    to={to}
  >
    {children && <div className={styles.icon}>{children}</div>}
    <div className={cx({ title: true, hidden: collapsed })}>
      {title}
    </div>
  </Link>
);

SideNavItem.defaultProps = {
  to: 'none',
  title: 'Нет записей',
};

export default SideNavItem;
