import { ReactNode } from 'react'

import SideNavigation, {
  SideNavigationContainer,
  SideNavigationTargetContent,
  SideNavigationItem,
} from 'app/common/ui-components/side-navigation'
import Sticky from 'app/common/templates-next/sticky'
// import isProduction from 'app/common/lib/isProduction'

import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon'
import { Section } from './sections'

import styles from './AdditionalPageSideNav.scss'


type Props = {
  currentSection: Section
  baseURL: string
  children: ReactNode
}

const AdditionalPageSideNav = ({ currentSection, baseURL, children }: Props) => {
  return (
    <SideNavigationContainer>
      <Sticky skipShift>
        {ref => (
          <div ref={ref} className={styles.side}>
            <SideNavigation>
              <SideNavigationItem
                key={Section.Production}
                title="Продукция"
                to={`${baseURL}/${Section.Production}`}
                active={currentSection === Section.Production}
              >
                <PlainIcon type={iconTypes.PRODUCTION} />
              </SideNavigationItem>

              <SideNavigationItem
                key={Section.Complexes}
                title="Комплекты"
                to={`${baseURL}/${Section.Complexes}`}
                active={currentSection === Section.Complexes}
              >
                <PlainIcon type={iconTypes.COMPLEXES} />
              </SideNavigationItem>

              <SideNavigationItem
                key={Section.Reports}
                title="Отчёты"
                to={`${baseURL}/${Section.Reports}`}
                active={currentSection === Section.Reports}
              >
                <PlainIcon type={iconTypes.REPORTS} />
              </SideNavigationItem>

              <SideNavigationItem
                key={Section.OrgGroups}
                title="Группы заведений"
                to={`${baseURL}/${Section.OrgGroups}`}
                active={currentSection === Section.OrgGroups}
              >
                <PlainIcon type={iconTypes.GROUP_BUILDINGS} />
              </SideNavigationItem>
            </SideNavigation>
          </div>
        )}
      </Sticky>

      <SideNavigationTargetContent>
        {children}
      </SideNavigationTargetContent>
    </SideNavigationContainer>

  )
}

export default AdditionalPageSideNav
