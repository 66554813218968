import { EMPLOYEE_REQUEST, EMPLOYEE_SUCCESS, EMPLOYEE_CLEAR } from 'app/actions/employees/employee';

import {
  EMPLOYEEFORM_CHANGE_FIELD,
  EMPLOYEEFORM_SUBMIT_REQUEST,
  EMPLOYEEFORM_SUBMIT_SUCCESS,
  EMPLOYEEFORM_SUBMIT_FAILURE,
} from 'app/actions/employees/employee/employeeform';

import {
  EMPLOYEE_SCHOOLS_SUCCESS,
  EMPLOYEE_SCHOOLS_CLEAR,
  EMPLOYEE_SCHOOLS_ADD,
  EMPLOYEE_SCHOOLS_CHANGE_FIELD,
} from 'app/actions/employees/employee/jobplaces';

import {
  JOBPLACES_SUBMIT_REQUEST,
  JOBPLACES_SUBMIT_SUCCESS,
  JOBPLACES_SUBMIT_FAILURE,
} from 'app/actions/employees/employee/jobplaces/submit';


const defaultState = {
  loading: true,
  formModified: false,
  sending: false,
};

const fields = (state = defaultState, { type }) => {
  if (type === EMPLOYEE_REQUEST) {
    return ({ ...state, loading: true });
  }
  if (type === EMPLOYEE_SUCCESS || type === EMPLOYEE_SCHOOLS_SUCCESS) {
    return ({ ...state, loading: false });
  }

  // forms change
  if (
    type === EMPLOYEEFORM_CHANGE_FIELD
    || type === EMPLOYEE_SCHOOLS_ADD
    || type === EMPLOYEE_SCHOOLS_CHANGE_FIELD
  ) {
    return ({ ...state, formModified: true });
  }

  // forms submit
  if (type === EMPLOYEEFORM_SUBMIT_REQUEST || type === JOBPLACES_SUBMIT_REQUEST) {
    return ({ ...state, sending: true });
  }
  if (type === EMPLOYEEFORM_SUBMIT_SUCCESS || type === JOBPLACES_SUBMIT_SUCCESS) {
    return ({ ...state, sending: false, formModified: false });
  }
  if (type === EMPLOYEEFORM_SUBMIT_FAILURE || type === JOBPLACES_SUBMIT_FAILURE) {
    return ({ ...state, sending: false });
  }

  // default state
  if (type === EMPLOYEE_CLEAR || type === EMPLOYEE_SCHOOLS_CLEAR) {
    return defaultState;
  }

  return state;
};

export default fields;
