// @flow
import type { Request } from '../../index';


type Result = 'success'

type UrlParams = {
  userId: number | string,
}

export const DELETE_EMPLOYEE: Request<
  void,
  Result,
  UrlParams
> = [
  'food/employees/{userId}',
  'delete',
  ['data', 'result'],
];
