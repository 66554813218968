import React from 'react';

const EyeOffIcon = props => (<svg viewBox="0 0 22 22" width="22px" height="22px" {...props}>
  <path d="M5.215 10.906c0.717-0.725 1.554-1.293 2.48-1.691c0.997-0.424 2.077-0.625 3.154-0.61l1.78-1.798 c-1.9-0.377-3.901-0.183-5.698 0.585C5.768 7.888 4.725 8.599 3.827 9.506c-0.896 0.904-1.602 1.961-2.093 3.135l1.806 0.773 C3.933 12.475 4.497 11.631 5.215 10.906z" />
  <path d="M16.485 8.517l-1.402 1.415c0.418 0.285 0.811 0.609 1.171 0.975c0.717 0.725 1.278 1.568 1.675 2.508 l1.806-0.773c-0.49-1.174-1.198-2.23-2.095-3.135C17.283 9.141 16.891 8.815 16.485 8.517z" />
  <path d="M9.816 15.249c0.283 0.12 0.593 0.188 0.918 0.188c1.317 0 2.387-1.079 2.387-2.409 c0-0.328-0.067-0.643-0.186-0.928L9.816 15.249z" />
  <path d="M4.21 19.51l-1.388-1.398L17.26 3.527l1.388 1.403L4.21 19.51z" />
</svg>);

export default EyeOffIcon;
