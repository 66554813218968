import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import equals from 'ramda/es/equals';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import comparator from 'ramda/es/comparator';
import sort from 'ramda/es/sort';
import last from 'ramda/es/last';
import prop from 'ramda/es/prop';
import indexBy from 'ramda/es/indexBy';
import map from 'ramda/es/map';
import evolve from 'ramda/es/evolve';
import replace from 'ramda/es/replace';
import find from 'ramda/es/find';

import {
  yearsFilterFetch,
  yearsFilterClear,
  yearsFilterDelete,
  yearsFilterSet,
  yearsFilterUpdate,
} from 'app/actions/reports/filters/years';
import { isSingle } from 'app/common/lib/object';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';

import { selectOrgId, selectOrgsType } from './commonSelectors';


const selectIsAll = createSelector(
  [
    state => state.reports.filters.years.data,
    state => state.reports.filters.years.selected,
  ],
  (data, selected) => (!isEmpty(data) && equals(data, selected)),
);

const selectItems = createSelector(
  state => state.reports.filters.years.data,
  map(evolve({ title: replace(/-/, '–') })),
);

const selectIsEnableCommon = createSelector(
  [
    selectOrgId,
    (state, { disableOnDateCurrent }) => state.reports.filters.date.current && disableOnDateCurrent,
  ],
  (orgId, disableOnDateCurrent) => (
    !!orgId // выбрана одна организация
    && !disableOnDateCurrent // установлена дата на которую запрашиваентся отчет (не текущее состояние)
  ),
);

export default compose(
  connect(
    (state, props) => {
      const selectIsEnable = prop('selectIsEnable', props) || selectIsEnableCommon;

      return ({
        items: selectItems(state),
        selectedItems: state.reports.filters.years.selected,
        loading: state.reports.filters.years.loading,
        isSelectedAllState: selectIsAll(state),
        orgId: selectOrgId(state),
        orgsType: selectOrgsType(state),
        isEnabled: selectIsEnable(state, props),
        active: state.reports.filters.years.active,
      });
    },
  ),

  withHandlers({
    reset: ({ dispatch, items }) => (resetDefault) => {
      if (!resetDefault) {
        dispatch(yearsFilterClear());
        return;
      }
      const itemsArray = values(items);
      const byToday = find(
        ({ dateStart, dateFinish }) => moment().isBetween(dateStart, dateFinish, null, '[]'),
        itemsArray,
      );
      const defaultValue = byToday || pipe(
        sort(comparator((a, b) => moment(a.dateFinish).isBefore(b.dateFinish))),
        last,
      )(itemsArray);
      dispatch(yearsFilterSet(indexBy(prop('id'), [defaultValue])));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset(true);
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        toggleModal();
        return;
      }
      dispatch(yearsFilterDelete([name]));
    },
    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(yearsFilterSet(submitted));
    },
  }),

  lifecycle({
    // componentDidMount() {
    //   const { isEnabled, dispatch } = this.props;
    //   if (isEnabled) {
    //     dispatch(yearsFilterUpdate({ active: isEnabled }));
    //   }
    // },
    async componentDidUpdate(prevProps) {
      const { dispatch, orgId, reset, isEnabled, orgsType } = this.props;
      // при смене типа организации нужно обнудить двнные, чтоб по ним не сработали другие фильтры
      if (orgsType !== prevProps.orgsType) {
        reset(false);
      }
      if (orgId !== prevProps.orgId && orgId) {
        await dispatch(yearsFilterFetch(orgId));
        reset(true);
      }
      resetOnStatusChange(this.props, prevProps);
      if (isEnabled !== prevProps.isEnabled) {
        dispatch(yearsFilterUpdate({ active: isEnabled }));
      }
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(yearsFilterClear());
    },
  }),

  branch(({ isEnabled }) => !isEnabled, renderNothing),
);
