// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';

import toFixedValue from 'app/common/lib/toFixedValue';

import { ids as columnIds } from './columns';
import type { SchoolData } from '../useReportData';


export type ActualItem = {
  id: number,
  [$Values<typeof columnIds>]: string,
}

export default function useActualItems(ids: Array<number>, data: Array<SchoolData>) {
  const res: Array<ActualItem> = useMemo(() => {
    return map(({
      school_title: title,
      complex,
      personal,
      total,
    }: SchoolData, i): ActualItem => ({
      id: ids[i],
      [columnIds.TITLE]: title,
      [columnIds.COMPLEX]: toFixedValue(complex),
      [columnIds.PERSONAL]: toFixedValue(personal),
      [columnIds.TOTAL]: toFixedValue(total),
    }), data);
  }, [data, ids]);

  return res;
}
