import { useMemo } from 'react'
import { range, isEmpty } from 'ramda'

import PlainPagingItem from './plain-paging-item'
import styles from './plain-paging.scss'


const MAX_ITEMS = 10
const FILLER = '...'
const FILLER_IDS = [-100, -200]
const EDGE = 3

export type PlainPagingProps = {
  total?: number,
  perPage?: number,
  currentPageIndex?: number,
  currentPageSize?: number,
  onPage?: (pageIndex: number) => void
}

const PlainPaging = ({
  total = 0,
  perPage = 0,
  currentPageIndex = 0,
  currentPageSize = 0,
  onPage,
}: PlainPagingProps) => {
  const wholePages = Math.floor(total / perPage)
  const left = total % perPage || perPage
  const currentlyLeft = currentPageSize || (
    currentPageIndex < wholePages ? perPage : left
  )
  const pages = left && left !== perPage ? wholePages + 1 : wholePages
  const begin = currentPageIndex * perPage + 1
  const currentPageNumber = currentPageIndex + 1

  const infoText = (
    (currentlyLeft === 1 && `Показанa одна запись (всего ${total})`)
    || (currentlyLeft === 2 && `Показаны две записи (всего ${total})`)
    || `Показаны записи с ${begin} по ${begin + currentlyLeft - 1} (всего ${total})`
  )

  const previosIndex = currentPageIndex - 1
  const nextIndex = currentPageIndex + 1

  const items = useMemo(() => {
    if (pages < MAX_ITEMS) {
      return range(1, pages + 1)
    }
    // 1 2 3 ... 14 [15] 16 ... 28 19 30
    const maxItemsCorrected = MAX_ITEMS - 2
    if (
      currentPageIndex > maxItemsCorrected - 1 - EDGE
      && currentPageIndex < pages - maxItemsCorrected + EDGE
    ) {
      const nearFromCurrent = Math.floor(maxItemsCorrected / 2)
      const middle = range(currentPageIndex - nearFromCurrent + 2, currentPageIndex + nearFromCurrent + 1)
      return [1, FILLER_IDS[0], ...middle, FILLER_IDS[1], pages]
    }
    // 1 ... 26 27 [28] 19 30
    if (currentPageIndex > maxItemsCorrected - 1) {
      return [1, FILLER_IDS[0], ...range(pages - maxItemsCorrected + 1, pages + 1)]
    }
    // 1 2 3 [4] 5 6... 30
    return [...range(1, maxItemsCorrected + 1), FILLER_IDS[1], pages]
  }, [pages, currentPageIndex])

  const nothingToSwitch = isEmpty(items) || total <= perPage


  return (
    <div className={styles.root}>
      <div>{infoText}</div>

      {!nothingToSwitch
        && <div className={styles.pages}>
          <PlainPagingItem
            key="<"
            value={previosIndex}
            disabled={previosIndex < 0}
            onClick={onPage}
          >
            {'<'}
          </PlainPagingItem>

          {items.map(item => (
            FILLER_IDS.includes(item)

              ? <PlainPagingItem
                key={item}
                disabled
              >
                {FILLER}
              </PlainPagingItem>

              : <PlainPagingItem
                key={item}
                value={item - 1}
                onClick={onPage}
                active={item === currentPageNumber}
              >
                {item}
              </PlainPagingItem>
          ))}

          <PlainPagingItem
            key=">"
            value={nextIndex}
            disabled={nextIndex > pages - 1}
            onClick={onPage}
          >
            {'>'}
          </PlainPagingItem>
        </div>}
    </div>
  )
}

export default PlainPaging
