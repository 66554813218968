import { SplitTypeID, useSubsidiesData } from '../SubsidiesData'
import SplittingAllItem from './SplittingAllItem'

import styles from './SubsidiesSplittingAll.scss'


type Props = {
  disabled?: boolean
}

const SubsidiesSplittingAll = ({ disabled }: Props) => {
  const [{ splits, enableCompensationOrders }] = useSubsidiesData()

  return (
    <div>
      {splits.map(({ split_type_id: splitTypeId }) => (
        !enableCompensationOrders
          && (
            splitTypeId === SplitTypeID.PersonalCompensationToParentChildrenDisabilities
            || splitTypeId === SplitTypeID.PersonalCompensationToParentDisabilities // TODO why doesnt work?
          )
          ? null
          : <SplittingAllItem
            key={splitTypeId}
            splitTypeId={splitTypeId}
            className={styles.item}
            disabled={disabled}
          />
      ))}
    </div>
  )
}

export default SubsidiesSplittingAll
