import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import replace from 'ramda/es/replace';
import toString from 'ramda/es/toString';
import isEmpty from 'ramda/es/isEmpty';
import values from 'ramda/es/values';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import omit from 'ramda/es/omit';

import {
  commonBalanceFilterSetSelected,
  commonBalanceFilterSetRange,
  commonBalanceFilterClear,
  commonBalanceFilterInit,
  data as radioItems,
  BALANCE_ZERO,
  BALANCE_ALL,
} from 'app/actions/reports/filters/commonBalance';
import { priceRe } from 'app/common/constants/regex';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';

const allowedItems = map(prop('id'), values(radioItems));

export default compose(
  connect(
    state => ({
      min: state.reports.filters.commonBalance.min,
      max: state.reports.filters.commonBalance.max,
      data: state.reports.filters.commonBalance.data,
      selected: state.reports.filters.commonBalance.selected,
      dateIsCurrent: state.reports.filters.date.current,
    }),
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(commonBalanceFilterClear());
    },
    handleSwitch: ({ dispatch }) => (e) => {
      const { value } = e.currentTarget;
      if (!allowedItems.includes(value)) return;
      dispatch(commonBalanceFilterSetSelected(value));
    },
    handleChange: ({ dispatch }) => (e) => {
      const { name, value } = e.currentTarget;
      if (name !== 'min' && name !== 'max') return;
      const replacedDot = replace(/,/, '.', value);
      const replaced = replace(/[-−\-–]/, '-', replacedDot);
      if (priceRe.test(replaced)) {
        dispatch(commonBalanceFilterSetRange({ name, value: replaced }));
      }
    },
    handleBlur: ({ dispatch, min, max }) => (e) => {
      const { name, value } = e.currentTarget;
      if (value === '') return;
      const parsedMin = parseFloat(min);
      const parsedMax = parseFloat(max);
      if (name === 'min') {
        dispatch(commonBalanceFilterSetRange({
          name,
          value: toString(parsedMin > parsedMax ? parsedMax : parsedMin) }));
        return;
      }
      if (name === 'max') {
        dispatch(commonBalanceFilterSetRange({
          name,
          value: toString(parsedMax < parsedMin ? parsedMin : parsedMax) }));
      }
    },
  }),

  lifecycle({
    componentDidMount() {
      const { dispatch, dateIsCurrent } = this.props;
      dispatch(commonBalanceFilterInit(
        dateIsCurrent
          ? radioItems
          : omit([BALANCE_ZERO], radioItems),
      ));
    },
    componentDidUpdate(prevProps) {
      const { reset, data, dateIsCurrent, dispatch } = this.props;
      if (data !== prevProps.data && isEmpty(prevProps.data) && !isEmpty(data)) {
        reset();
      }
      if (dateIsCurrent !== prevProps.dateIsCurrent) {
        dispatch(commonBalanceFilterSetSelected(BALANCE_ALL));
        dispatch(commonBalanceFilterInit(
          dateIsCurrent
            ? radioItems
            : omit([BALANCE_ZERO], radioItems),
        ));
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(commonBalanceFilterClear());
    },
  }),

  mapProps(({ min, max, ...rest }) => ({
    min: replace(/-/, '−', min),
    max: replace(/-/, '−', max),
    ...rest,
  })),
);
