// @flow
import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import values from 'ramda/es/values';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';
import propEq from 'ramda/es/propEq';
import pick from 'ramda/es/pick';
import map from 'ramda/es/map';


export type Item = {
  id: number,
  title: string,
}

export default function useStep2FormHeader(): [Array<Item>, boolean] {
  const { schools, loading } = useSelector(state => state.orgs.orgs, shallowEqual);

  const res = useMemo(() => pipe(
    values,
    filter(complement(propEq('deleted', true))),
    map(pick(['id', 'title'])),
  )(schools), [schools]);

  return [res, loading];
}
