// @flow
import React, { Fragment, type Ref } from 'react';
import classNames from 'classnames/bind';
import isEmpty from 'ramda/es/isEmpty';

import { Tr, Th, Td } from 'app/common/ui-components/table';
import OveflowedText from 'app/common/components/OveflowedText';

import styles from './ClassesAndOrdersRow.scss';


const cx = classNames.bind(styles);

const emptyPersonal = [{
  category: '\u00A0',
  count: 0,
  sum: '0.00',
}];

type Props = {
  className?: string,
  hidden?: boolean,
  title: string,
  common: {
    count: number,
    sum: string,
  },
  personal: Array<{
    category: string,
    count: number,
    sum: string,
  }>,
  personalCount: number,
  mouseHandlers?: {
    onMouseEnter: (e: SyntheticMouseEvent<HTMLElement>) => void,
    onMouseLeave: (e: SyntheticMouseEvent<HTMLElement>) => void,
  },
  rowRef?: Ref<'tr'>,
  highlight?: boolean,
  viewCommon?: boolean,
  viewPersonal?: boolean,
}

const ClassesAndOrdersRow = ({
  className,
  hidden,
  title,
  common,
  personal,
  personalCount,
  mouseHandlers = {},
  rowRef,
  highlight,
  viewCommon = true,
  viewPersonal = true,
}: Props) => {
  const rows = isEmpty(personal) && viewCommon ? emptyPersonal : personal;

  return (
    <Fragment>
      {rows.map(({ category, count, sum }, i) => {
        const rowId = `class_${i}`;
        const rowKey = `${title}_${i}`;

        return (
          <Tr
            key={rowKey}
            hidden={hidden}
            className={cx(className, { highlight })}
            reference={i === 0 ? rowRef : undefined}
            header={highlight}
            {...mouseHandlers}
          >
            {!i // охватывающие ячейки
              && <Fragment>
                <Th
                  headers="header_title"
                  scope="row"
                  rowSpan={viewPersonal && personalCount ? personalCount : 1}
                  id={rowId}
                  className={styles.borderRight}
                  alignLeft
                >
                  <OveflowedText>
                    {title}
                  </OveflowedText>
                </Th>
                {viewCommon
                  && <Fragment>
                    <Td
                      headers={`${rowId} header_common_count`}
                      rowSpan={viewPersonal && personalCount ? personalCount : 1}
                      alignCenter
                    >
                      {common.count}
                    </Td>
                    <Td
                      headers={`${rowId} header_common_sum`}
                      rowSpan={viewPersonal && personalCount ? personalCount : 1}
                      className={cx({ borderRight: viewPersonal })}
                      alignCenter
                    >
                      {common.sum}
                    </Td>
                  </Fragment>}
              </Fragment>}
            {viewPersonal
              && <Fragment>
                <Td
                  headers={`${rowId} header_personal_category`}
                  className={styles.tdCategory}
                  alignLeft
                >
                  <OveflowedText>
                    {category}
                  </OveflowedText>
                </Td>
                <Td headers={`${rowId} header_personal_count`} alignCenter>
                  {count}
                </Td>
                <Td headers={`${rowId} header_personal_sum`} alignCenter>
                  {sum}
                </Td>
              </Fragment>}
          </Tr>
        );
      })}
    </Fragment>
  );
};

export default ClassesAndOrdersRow;
