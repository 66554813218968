// @flow
import React, { type Node } from 'react';


type Props = {
  className?: string,
  title?: string,
  portion?: string,
}

const ProductTitle = ({
  className,
  title,
  portion,
}: Props): Node => {
  return (
    <div className={className}>
      {`${
        title || '[Товар не найден]'
      }${
        portion ? ', ' : ''
      }${
        portion || ''
      }`}
    </div>
  );
};

export default ProductTitle;
