import { useCallback, useState } from 'react'
// import { not } from 'ramda'

export function useFilterHandler() {
  const [filterOpened, setFilterOpened] = useState(false)
  const [isActiveFilter, setActive] = useState<boolean>(false)

  const toggleFilter = useCallback(() => {
    setFilterOpened(prev => !prev)
  }, [])

  const setActiveFilter = useCallback((status: boolean) => {
    setActive(status)
  }, [])

  return { filterOpened, toggleFilter, isActiveFilter, setActiveFilter }
}
