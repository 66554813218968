
import { CampSettings } from 'app/dataTypes/subsidy/settings'

import { Errors } from './useCampErrors'
import { AccuralForFormCamp } from './formParts/AccuralForFormCamp'
import { FormSection } from './FormSection'
import { AccrualFormCamp } from './formParts/AccrualFormCamp'


type Props = {
  ID: string
  orgID: string
  formID: string
  settings: CampSettings
  disabled?: boolean
  errors: Errors
  resetErrors: () => void
  updateSize?: () => void
}

export const CategoryCampSettingsForm = ({
  ID,
  orgID,
  formID,
  settings,
  disabled,
  errors,
  resetErrors,
  updateSize,
}: Props) => {
  const { accrual, accrualFor } = settings

  return (
    <>
      <FormSection>
        <AccrualFormCamp
          ID={ID}
          orgID={orgID}
          data={accrual}
          formID={formID}
          disabled={disabled}
          errors={errors}
          resetErrors={resetErrors}
          updateSize={updateSize}
        />
      </FormSection>

      <FormSection>
        <AccuralForFormCamp
          data={accrualFor}
          disabled={disabled}
          formID={formID}
          errors={errors}
          resetErrors={resetErrors}
        />
      </FormSection>
    </>
  )
}
