import { useEffect, useRef, useState } from 'react'
import { equals } from 'ramda'

import { Request } from 'app/common/api/requests'
import { api } from 'app/api'
import { Convertions } from 'app/common/api/Api'
import shallowEqual from 'app/common/lib/shallowEqual'


const compareMethods = {
  shallow: shallowEqual,
  equals,
}

export type Options<Data, P = undefined> = {
  params: Partial<P>
  autoload?: boolean
  autoupdate?: boolean
  requestValue: Request<P, Data, any> // TODO
  error?: string | ((err: Error) => string)
  convertions: Convertions<P, Data>
  beforeRequest?: () => Partial<P>
  defaultState: Data
  compare?: keyof typeof compareMethods
}

const defaultParams = {
  params: null,
  autoload: true,
  autoupdate: true,
  error: 'Произошла неизвестная ошибка',
  compare: 'equals',
}

const emptyRequest: () => Promise<boolean> = () => Promise.resolve(false)

export function useCommonDataProvider<Data, P = undefined>(options: Options<Data, P>) {
  const {
    params,
    autoload,
    autoupdate,
    requestValue,
    error,
    convertions,
    beforeRequest,
    defaultState,
    compare,
  } = { ...defaultParams, ...options }

  const inProcess = useRef(false)

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<Data>(defaultState)

  const prevParamsRef = useRef(params)
  const requestRef = useRef<(params_?: Partial<P>) => Promise<boolean>>(emptyRequest)

  const request = async (params_?: Partial<P>) => {
    if (inProcess.current) return false
    inProcess.current = true
    setLoading(true)
    const additioalParams: Partial<P> = beforeRequest ? beforeRequest() : {}
    const paramsAll = {
      ...(params || {}),
      ...additioalParams,
      ...(params_ || {}),
    } as P

    const res = await api.request<P, Data>(requestValue, {
      error,
      params: paramsAll,
      convertions,
    })

    if (res) {
      setData(res)
      setLoading(false)
      inProcess.current = false
      return true
    }
    inProcess.current = false
    setLoading(false)
    return false
  }

  useEffect(() => {
    if (!compareMethods[compare](prevParamsRef.current, params)) {
      requestRef.current = request
      prevParamsRef.current = params
      if (autoupdate) request()
    }
  })

  useEffect(() => {
    requestRef.current = request
    if (autoload) {
      request()
    }
  }, [autoload]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    loading,
    request: requestRef.current,
    setData,
  }
}
