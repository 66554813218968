// @flow
import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import i18n from 'app/i18n';

import Flag from './flags/Flag';
import styles from './LangItem.scss';


const cx = classNames.bind(styles);

export type LangItemProps = {
  id: string,
  onClick?: (id: string) => void | Promise<void>,
  active?: boolean,
}

const LangItem = ({ id, onClick, active }: LangItemProps) => {
  const handleClick = useCallback((e) => {
    e.preventDefault();
    if (onClick) {
      onClick(id);
    }
  }, [id, onClick]);

  return (
    <a href={id} onClick={handleClick} className={styles.root}>
      <Flag lng={id} className={cx({ iconActive: active })} />
      <span className={cx(styles.title, { titleActive: active })}>
        {i18n.t(`ui:lang_${id}`)}
      </span>
    </a>
  );
};

export default LangItem;
