import petrovich from 'petrovich'

import { Name, FullNameType } from './name'


export default class Person {
  readonly #id: string

  readonly #first: string

  readonly #last: string

  readonly #middle: string

  readonly #gender: 'male' | 'female' | 'androgynous'

  readonly #timezone: string

  constructor({ id, ID, first, last, middle, gender, timezone }: {
    id?: string | number,
    ID?: string,
    first?: string | null,
    last?: string | null,
    middle?: string | null,
    gender?: 'male' | 'female' | 'androgynous',
    timezone?: string | null
  } = {}) {
    this.#id = String(id) || ID || ''
    this.#first = (first || '').trim()
    this.#last = (last || '').trim()
    this.#middle = (middle || '').trim()
    this.#gender = gender || 'androgynous'
    this.#timezone = timezone || 'Europe/Moscow'
  }

  get id(): string {
    return this.#id
  }

  get ID(): string {
    return this.#id
  }

  getName(
    case_: (
      | 'nominative' // именительный (кто? что?)
      | 'genitive' // родительный (кого? чего?)
      | 'dative' // дательный (кому? чему?)
      | 'accusative' // винительный (кого? что?)
      | 'instrumental' // творительный (кем? чем?)
      | 'prepositional' // предложный (о ком? о чем?)
    ) = 'nominative',
  ): Name {
    return new Name(petrovich({
      first: this.#first,
      last: this.#last,
      middle: this.#middle,
      gender: this.#gender,
    }, case_))
  }

  get kogoChego(): Name {
    return this.getName('genitive')
  }

  get komuChemu(): Name {
    return this.getName('dative')
  }

  get kogoChto(): Name {
    return this.getName('accusative')
  }

  get kemChem(): Name {
    return this.getName('instrumental')
  }

  get oKomOChom(): Name {
    return this.getName('prepositional')
  }

  static FullNameType = FullNameType

  toString(fullNameType?: FullNameType): string {
    return (new Name({
      first: this.#first,
      last: this.#last,
      middle: this.#middle,
      gender: this.#gender,
    })).toString(fullNameType)
  }

  get lastFM(): string { // deprecated
    return this.getName().toString(FullNameType.LastFM)
  }

  get lastFirstMiddle(): string { // deprecated
    return this.getName().toString(FullNameType.LastFirstMiddle)
  }

  get gender(): 'male' | 'female' | 'androgynous' {
    return this.#gender
  }

  get timezone(): string {
    return this.#timezone
  }
}
