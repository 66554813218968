// @flow
import React from 'react';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import styles from './ResetFilterLink.scss';


type LinkProps = {
  title: string,
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
}

const ResetFilterLink = ({ title, onClick }: LinkProps) => (
  <PlainButtonAsLink
    linkClassName={styles.resetLink}
    onClick={onClick}
  >
    {title}
  </PlainButtonAsLink>
);

export default ResetFilterLink;
