import { CampSettings } from 'app/dataTypes/subsidy/settings'

import { mustBePair } from '../helpers'


export type CampSettingsAPI = {
  accrual_customized: boolean, // флаг индивидуальных настроек
  accrual_on_complexes: Array<number>, // список комплектов
  accrual_on_percents: number | null, // значение процента
  accrual_type: 'percents' | 'complexes_grocery_set_cost' | null, // тип начисления: percents - процент, complexes_grocery_set_cost - комплекты (продуктовый набор)
  for_klass_orders_enabled: boolean, // флаг отображения начисления льгот при продажах в лагере
  for_klass_orders_checked: boolean, // флаг принудительного выбора начисления льгот при продажах в лагере
}


export const convertAsCamp = (settings: CampSettingsAPI, complexesMap: Map<number, string>): CampSettings => {
  const {
    accrual_customized: active,
    accrual_on_complexes: complexesIds,
    accrual_on_percents: percent,
    accrual_type: type,
    for_klass_orders_enabled: enabled,
    for_klass_orders_checked: checked,
  } = settings

  return {
    app: 'camp' as const,
    accrual: {
      active,
      type: type === 'percents' ? 'percent' : type,
      percent: typeof percent === 'number' ? String(percent) : '',
      complexes: complexesIds.map(key => ({
        ID: String(key),
        title: complexesMap.get(key) || String(key),
      })),
    },
    accrualFor: {
      camp: mustBePair([enabled, checked]),
    },
  }
}

export const convertAsCampPOST = (settings: CampSettings): CampSettingsAPI => {
  const {
    accrual: {
      active,
      type,
      percent,
      complexes,
    },
    accrualFor: {
      camp: [enabled, checked],
    },
  } = settings

  return {
    accrual_customized: active,
    accrual_on_complexes: complexes.map(({ ID }) => parseInt(ID, 10)),
    accrual_on_percents: percent ? parseInt(percent, 10) : null,
    accrual_type: type === 'percent' ? 'percents' : type,
    for_klass_orders_enabled: enabled,
    for_klass_orders_checked: checked,
  }
}
