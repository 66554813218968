// @flow
import { map, prop } from 'ramda';

import type { MachinesVersion } from 'app/dataTypes/vending/MachinesVersion.types';

import type { Request } from '../../../../index';


export type ResultApi = {
  data: Array<{
    capacity: number,
    id: number,
    model_id: number,
    slots: Array<Array<string>>,
    title: string
  }>,
  meta: {},
  result?: 'success',
}

type Versions = Array<MachinesVersion>

export const convertResult = ({ data }: ResultApi): Versions => {
  return map(versionRecord => ({
    id: String(prop('id', versionRecord)),
    title: prop('title', versionRecord),
    modelId: String(prop('model_id', versionRecord)),
    capacity: prop('capacity', versionRecord),
    slots: prop('slots', versionRecord),
  }), data);
};

export const GET_VENDING_MACHINES_VERSIONS: Request<void, Versions, void> = [
  'data/vending/machines/versions',
  'get',
  ['data'],
];
