// Flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './TabItem.scss';

const cx = classNames.bind(styles);
type Props = {
  children: any,
  active?: boolean,
};

const TabItem = ({ children, active }: Props) => (<div className={cx({ root: true, active })}>
  {typeof children === 'function'
    ? children({ active })
    : children}
</div>);

export default TabItem;
