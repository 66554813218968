// @flow
import moment from 'moment-timezone';

import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';
import { dateFormat } from 'app/common/ui-next/plain-date-period';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const finalDateInPreviosMonthOrBefore = (state: State): boolean => {
  const [, dateTo] = getStateCurrentValue(filterId, state) || [];
  if (!dateTo) return false;

  const mDateTo = moment(dateTo, dateFormat);
  const valid = Boolean(dateTo && mDateTo.isValid());

  if (!valid) {
    console.error(new Error('👻 Невалидная дата dateTo в определении finalDateInPreviosMonthOrBefore'));
    return false;
  }

  return mDateTo.isSameOrBefore(
    moment().subtract(1, 'months'),
    'month',
  );
};

export const finalDateInCurrentMonth = (state: State): boolean => {
  const [, dateTo] = getStateCurrentValue(filterId, state) || [];
  const mDateTo = moment(dateTo, dateFormat);
  const valid = Boolean(dateTo && mDateTo.isValid());

  if (!valid) {
    console.error(new Error('👻 Невалидная дата dateTo в определении finalDateInCurrentMonth'));
    return false;
  }

  return mDateTo.isSame(moment(), 'month');
};
