// @flow
import React, { useState, useCallback, type Node, Fragment } from 'react';
import not from 'ramda/es/not';

import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';
import PlainTooltip from 'app/common/ui-next/plain-tooltip';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';


type Props = {
  dish: Dish,
  onDelete: (Dish) => Promise<boolean> | boolean
}

const OptionsCell = ({ dish, onDelete }: Props): Node => {
  const { title } = dish;
  const deleteConfirmText = `Вы уверены, что хотите удалить продукцию «${title}» из списка вендинговой машины?`;
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const toggleDeleteConfirmation = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) e.preventDefault();
    setDeleteConfirmationVisible(not);
  }, []);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    const res = await onDelete(dish);
    if (res) {
      setDeleteConfirmationVisible(false);
    }
    setDeleting(false);
  }, [dish, onDelete]);

  return (
    <Fragment>
      <PlainButtonAsLink onClick={toggleDeleteConfirmation}>
        <PlainTooltip text="Удалить продукцию">
          <PlainIcon
            width={12}
            height={12}
            type={iconTypes.LIGHT_CROSS}
            colorType={colorTypes.COLOR_DARKGRAY}
          />
        </PlainTooltip>
      </PlainButtonAsLink>

      {deleteConfirmationVisible
        && <PlainConfirmModal
          messageText={deleteConfirmText}
          cancelButtonTitle="Отмена"
          confirmButtonTitle={deleting ? 'Удаление…' : 'Удалить'}
          confirmButtonProps={{ disabled: deleting }}
          onCancel={toggleDeleteConfirmation}
          onConfirm={handleDelete}
          critical
        />}
    </Fragment>
  );
};

export default OptionsCell;
