import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import has from 'ramda/es/has';
import toPairs from 'ramda/es/toPairs';

import { mailFilterSet, allOptions, mailFilterDefault } from 'app/actions/reports/filters/mail';
import withReset from 'app/components/Reports/hocs/withReset';


const options = toPairs(allOptions);

export default compose(
  connect(
    state => ({
      enabled: state.reports.filters.details, // показывать только если стоит галка детализации
      sendTo: state.reports.filters.mail,
      isPack: state.reports.filters.pack,
      email: state.user.email,
      options,
      loading: state.user.loading || state.user.result !== 'success',
    }),
    dispatch => ({
      handleChange: (e) => {
        const { value } = e.currentTarget;
        if (has(value, allOptions)) {
          dispatch(mailFilterSet(value));
        }
      },
      reset: () => {
        dispatch(mailFilterDefault());
      },
    }),
  ),

  withReset,

  branch(({ enabled }) => !enabled, renderNothing),
);
