// @flow
import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import path from 'ramda/es/path';
import clone from 'ramda/es/clone';
import assoc from 'ramda/es/assoc';

import api from 'app/actions/api';
import action from 'app/common/lib/action';

import { defaultSettings } from './settings-context';


export default function useSettings(root_: $Keys<typeof defaultSettings> | 'all' | null | void) {
  const dispatch = useDispatch();

  const [settings, setSettings] = useState(defaultSettings);

  const [loading, setLoading] = useState(true);

  const requestSettings = useCallback(async (
    settingsSection: $Keys<typeof defaultSettings> | 'all' | void,
  ) => {
    const root = settingsSection || root_ || (process.env.APP_NAME || process.env.REACT_APP_NAME) || 'all';
    const requestPath = 'settings';
    const type = 'get';
    const rest = root ? {
      params: {
        root,
      },
    } : {};

    try {
      setLoading(true);
      const res = await api(requestPath, type, rest);
      const settingsResult = path(['data', 'settings'], res);
      if (!settingsResult) {
        console.error(`Изменилась структура API в ${requestPath} ${type}`);
        return;
      }

      setSettings(root === 'all'
        ? settingsResult
        : assoc(root, settingsResult));

      setLoading(false);
    } catch (error) {
      const errorSave = {
        err: 'Не удалось получить настройки',
        path: requestPath,
        rest,
        type,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
    }
  }, [dispatch, root_]);

  useEffect(() => {
    if (root_) {
      requestSettings(root_);
    }
  }, [root_]); // eslint-disable-line react-hooks/exhaustive-deps


  const saveSettingsKeyValue = useCallback(async (
    settingsSection: $Keys<typeof defaultSettings> | 'all' | null,
    key: string,
    value: mixed,
  ) => {
    const root = settingsSection || root_ || (process.env.APP_NAME || process.env.REACT_APP_NAME) || 'all';
    const requestPath = 'settings';
    const type = 'post';
    const savePair = { [key]: value };
    const rest = {
      data: {
        root,
        ...savePair,
      },
    };

    try {
      const res = await api(requestPath, type, rest);
      const settingsResult = path(['data', 'settings'], res);
      if (!settingsResult) {
        console.error(`Изменилась структура API в ${requestPath} ${type}`);
        return;
      }

      setSettings(root === 'all'
        ? settingsResult
        : assoc(root, settingsResult));
    } catch (error) {
      const errorSave = {
        err: 'Не удалось сохранить настройки',
        path: requestPath,
        rest,
        type,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
    }
  }, [dispatch, root_]);


  const memoizedResult = useMemo(() => ([[settings, loading], {
    requestSettings,
    saveSettingsKeyValue,
  }]), [
    settings,
    loading,
    requestSettings,
    saveSettingsKeyValue,
  ]);


  return memoizedResult;
}
