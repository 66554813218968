// @flow
import React from 'react';

import Checkbox from 'app/common/ui/Checkbox';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';
import SettingsGear from 'app/common/components/Settings';
import { settingsKey } from 'app/reducers/settings/orgsOrders';
import type { Settings } from 'app/reducers/settings/orgsOrders';

import styles from './OrgsOrdersGear.scss';


type Props = {
  ids: Array<$Keys<Settings>>,
  settingsTitles: { [key: $Keys<Settings>]: string }
}

const OrgsOrdersGear = ({ ids, settingsTitles }: Props) => (
  <SettingsGear dataKey={settingsKey}>
    {({ saveSettings }) => (<div>
      <div className={styles.gearHeader}>{'Столбцы:'}</div>

      {/* Чекбоксы в настройках */}
      {ids.map((id) => {
        if (id === 'id') {
          return null;
        }
        return (
          <WithHOC
            key={id}
            component={Checkbox}
            hoc={CheckboxSettings}
            label={settingsTitles[id]}
            name={id}
            saveSettings={saveSettings}
            className={styles.gearCheck}
            settingsKey={settingsKey}
          />
        );
      })}
    </div>)}
  </SettingsGear>
);

export default OrgsOrdersGear;
