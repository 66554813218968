// @flow
import type { Request } from '../../index';


export type Result = {
  enabled: boolean,
}

export const defaultSettings: Result = {
  enabled: false,
};

export const GET_VENDING_SETTINGS: Request<void, Result, void> = [
  'data/vending/settings',
  'get',
  ['data', 'data'],
];
