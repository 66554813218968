// @flow
import React from 'react';

import { Table, Th, Tr, Td } from 'app/common/ui-components/table';
import { type Sale } from 'app/common/api/requests/food/schools/cafeteria_orders';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import ComplexTooltip from 'app/common/ui-components/complex-tooltip';

import useProductListTableData from './useProductListTableData';
import styles from './ProductListTable.scss';


type Props = {
  data: Array<Sale>
}

const ProductListTable = ({ data }: Props) => {
  const items = useProductListTableData(data);
  const currency = useCurrencySign(co.SPACE_BEFORE);

  return (
    <Table className={styles.root}>
      <thead>
        <Tr header className={styles.tableRow}>
          <Th alignLeft>{'Наименование'}</Th>
          <Th>{`Цена,${currency}`}</Th>
          <Th>{'Количество'}</Th>
          <Th>{`Стоимость,${currency}`}</Th>
        </Tr>
      </thead>
      <tbody>
        {items.map(({ id, title, cost, count, total, type, dishes }) => (
          <Tr key={id} className={styles.tableRow} hoverHighlighted={false}>
            <Td>
              {type === 'complex' && dishes
                ? <ComplexTooltip
                  items={dishes}
                  header={title}
                  headerSuffix={`${cost}${currency}`}
                >
                  <span className={styles.tooltipExist}>{title}</span>
                </ComplexTooltip>
                : title}
            </Td>
            <Td alignCenter>{cost}</Td>
            <Td alignCenter>{count}</Td>
            <Td alignCenter>{total}</Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProductListTable;
