import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const ADD_CATEGORY_REQUEST = 'orgs/ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'orgs/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'orgs/ADD_CATEGORY_FAILURE';

const addCategoryRequest = payload => ({
  type: ADD_CATEGORY_REQUEST,
  payload,
});

const addCategorySuccess = payload => ({
  type: ADD_CATEGORY_SUCCESS,
  payload,
});

const addCategoryFailure = payload => ({
  type: ADD_CATEGORY_FAILURE,
  payload,
});

export const addCategory = (orgId, categoryName) => async (dispatch) => {
  const dataToSend = { title: categoryName, school_id: orgId };
  dispatch(addCategoryRequest(categoryName));
  try {
    const { data } = await api('food/menu/item_category', 'post', { data: dataToSend });
    dispatch(addCategorySuccess(data));
    return 0;
  } catch (error) {
    const errCode = get(error, 'response.status', 500);
    if (errCode === 422) {
      dispatch(addCategoryFailure({ err: 'Такая категория уже существует', error }));
    } else {
      dispatch(addCategoryFailure({ err: 'Не удалось создать категорию', error }));
    }
    return errCode;
  }
};
