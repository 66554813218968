import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const RIGHTS_SAVE_REQUEST = 'admin/RIGHTS_SAVE_REQUEST';
export const RIGHTS_SAVE_SUCCESS = 'admin/RIGHTS_SAVE_SUCCESS';
export const RIGHTS_SAVE_FAILURE = 'admin/RIGHTS_SAVE_FAILURE';

export const rightsSaveRequest = () => ({
  type: RIGHTS_SAVE_REQUEST,
});

export const rightsSaveSuccess = payload => ({
  type: RIGHTS_SAVE_SUCCESS,
  payload,
});

export const rightsSaveFailure = payload => ({
  type: RIGHTS_SAVE_FAILURE,
  payload,
});

export const rightsSave = roleId => async (dispatch, getState) => {
  dispatch(rightsSaveRequest());
  const data = { sections: getState().admin.rights.sections };
  try {
    const res = await api(`food/roles/${roleId}/rights`, 'put', { data });
    const rightsSaved = get(res, ['data', 'sections'], {});
    dispatch(rightsSaveSuccess(rightsSaved));
    return true;
  } catch (error) {
    dispatch(rightsSaveFailure({ err: 'Не удалось сохранить настройки прав пользователей', error }));
    return false;
  }
};
