import { useState, useCallback, useEffect } from 'react'
import { assocPath } from 'ramda'

import { api } from 'app/api'
import { GET_REQUISITES, convertResult } from 'app/common/api/requests/food/requisites/get'

import {
  mustBePaymentOrderKind,
  Requisites,
  PaymentOrderPurpose,
  PaymentOrderKind,
  SingleRequisites,
  PaymentOrderCreateMethod,
  SupportContactsItemType,
} from 'app/dataTypes/requisites'

import { defaultState } from './useDataDefaults'


export type ChangeHandlerType = (
  pathToFielld: string,
  newValue: (
    | string
    | boolean
    | null
    | Set<PaymentOrderPurpose>
    | Set<PaymentOrderCreateMethod>
    | SupportContactsItemType
  ),
  onCheckError?: boolean
) => void

export type UpdateHandlerType = (
  state: Requisites | ((prevState: Requisites) => Requisites),
  onCheckError?: boolean
) => void

const useData = () => {
  const [pending, setPending] = useState(true)
  const [data, setData] = useState<Requisites>(defaultState)
  const [modified, setModified] = useState<boolean>(false)
  const [checkError, setCheckError] = useState<boolean>(false)

  const request = useCallback(async () => {
    setPending(true)
    const res = await api.request(GET_REQUISITES, {
      error: 'Не удалось получить данные реквизитов',
      convertions: {
        convertResult,
      },
    })

    if (res) {
      setData(res)
      setModified(false)
    }
    setPending(false)
  }, [])

  useEffect(() => {
    request()
  }, [request])

  const change: ChangeHandlerType = useCallback((pathToFielld, newValue, onCheckError) => {
    const pathAsArray = pathToFielld.split('.').map(v => (/^\d+$/.test(v) ? parseInt(v, 10) : v))
    if (pathAsArray[0] === 'requisites') {
      const [, targetKindString, targetField] = pathAsArray
      const targetKind = typeof targetKindString === 'string' ? mustBePaymentOrderKind(targetKindString) : null
      if (targetKind) {
        setData((prevState) => {
          const requisites: Map<PaymentOrderKind, SingleRequisites> = new Map(prevState.requisites)
          const singleRequisites = prevState.requisites.get(targetKind)
          if (!singleRequisites) {
            console.warn('👻 Не удалось обновить состояние Requisites')
            return prevState
          }
          requisites.set(targetKind, {
            ...singleRequisites,
            [targetField]: newValue,
          })
          return { ...prevState, requisites }
        })
        setModified(true)
      }
      return
    }
    setData(assocPath(pathAsArray, newValue))
    setModified(true)
    if (onCheckError) {
      setCheckError(true)
    } else {
      setCheckError(false)
    }
  }, [])

  const update: UpdateHandlerType = useCallback((state, onCheckError) => {
    setData(state)
    setModified(true)
    if (onCheckError) {
      setCheckError(true)
    } else {
      setCheckError(false)
    }
  }, [])

  return {
    data,
    pending,
    modified,
    reset: request,
    change,
    update,
    checkError,
  }
}

export default useData
