import { Dispatch, SetStateAction, useCallback } from 'react'

import { AgreementDocumentListType, AgreementDocumentType } from 'app/dataTypes/agreement/documents'
import { useAgreementDocumentsActionsBase } from './useAgreementDocumentsActionsBase'


type Options = {
  onSubmitSuccess: () => void
  onDeleteSuccess: () => void
  setData: Dispatch<SetStateAction<AgreementDocumentListType>>
}

export function useAgreementDocumentsActions(options: Options) {
  const { onSubmitSuccess, onDeleteSuccess, setData } = options

  const submitSuccess = useCallback((item: AgreementDocumentType) => {
    onSubmitSuccess()
    const { kind: sourceKind } = item
    setData((prevState) => {
      return [
        ...prevState.filter(({ kind }) => (kind !== sourceKind), prevState),
        item,
      ]
    })
  }, [setData, onSubmitSuccess])

  const deleteSuccess = useCallback((deletedID: string) => {
    onDeleteSuccess()
    setData((prevState) => {
      return prevState.filter(({ ID }) => (ID !== deletedID), prevState)
    })
  }, [setData, onDeleteSuccess])

  const {
    submitDocument,
    deleteDocument,
  } = useAgreementDocumentsActionsBase({
    onSubmitSuccess: submitSuccess,
    onDeleteSuccess: deleteSuccess,
  })

  return {
    submitDocument,
    deleteDocument,
  }
}
