// @flow
import React, { useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import { ModalContent, ModalFooter } from 'app/common/components/Modal';
import PlainTextArea from 'app/common/ui/PlainTextArea';
import { popup } from 'app/common/actions/popup';

import { IdentifiersContext } from '../identifiers-data';
import styles from './form-block.scss';


type Props = {
  id: number,
  onCancel: () => void,
  onSuccess: () => void,
};

const FormBlock = ({ id, onCancel, onSuccess }: Props) => {
  const { t } = useTranslation('identifiers-next');
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const handleChangeReasonText = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setReason(value);
  }, []);

  const { request: { block } } = useContext(IdentifiersContext);

  const [processing, setProcessing] = useState(false);

  const handleBlock = useCallback(async (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    setProcessing(true);
    const res = await block(id, reason);
    setProcessing(false);
    if (res) {
      dispatch(popup(t('block_modal_success_blocked')));
      onSuccess();
    }
  }, [id, block, reason, t, dispatch, onSuccess]);

  const limitError = reason.length < 3 || reason.length > 255;

  return (
    <form onSubmit={handleBlock}>
      <ModalContent>
        <PlainTextArea
          placeholder={t('block_modal_placeholder')}
          value={reason}
          onChange={handleChangeReasonText}
          hideErrorString
        />
        <PlainHelperText error={limitError} className={styles.helperString}>
          {/* {reason.length ? `От 3 до 255 символов (${reason.length})` : ''} */}
          {reason.length
            ? t('block_modal_helper_text', { minLength: 3, maxLength: 255, currentLength: reason.length })
            : ''}
        </PlainHelperText>
      </ModalContent>

      <ModalFooter>
        <ControlsBar right>

          <PlainButton onClick={onCancel} outline>
            {t('block_modal_calcel')}
          </PlainButton>

          <PlainButton
            type="submit"
            disabled={processing || limitError}
          >
            {processing
              ? t('block_modal_submit_loading')
              : t('block_modal_submit')}
          </PlainButton>

        </ControlsBar>
      </ModalFooter>
    </form>
  );
};

export default FormBlock;
