import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { api } from 'app/api'
import { GET_CLASSES_LIST, convertParams, convertResult } from 'app/common/api/requests/reports/klasses_list/get'
import { ClassesListType } from 'app/dataTypes/classesList'
import { ItemType } from 'app/common/ui-next/plain-selector'

import { orgsFilterSelection } from './orgsFilter'
import { schoolYearFilterSelection } from './schoolYearFilter'


type ClassesList = Array<ItemType>

class ClassesListFilterDataBox extends DataBox<ClassesList> { }

export const defaultClassesList: ClassesList = []
export const classesListFilter = createDataBox('classesListFilter', defaultClassesList, ClassesListFilterDataBox)

const convertFromExtended = (classesList: ClassesListType): ClassesList => {
  return classesList.map(({ ID, title }) => ({ id: ID, title }))
}


export const defaultClassesListDataReceiver = async () => {
  const orgIds = orgsFilterSelection.getData()
  const orgId = orgIds[0]
  const yearIds = schoolYearFilterSelection.getData()
  const yearId = yearIds[0]
  if (!orgId || !yearId) return []

  const res = await api.request(GET_CLASSES_LIST, {
    error: 'Не удалось получить данные классов',
    params: {
      schoolId: orgId,
      schoolYearId: yearId,
    },
    convertions: {
      convertParams,
      convertResult,
    },
  })
  return res ? convertFromExtended(res) : []
}


export const defaultClassesListSelection: Array<string> = []
export const classesListFilterSelection = createDataBoxDefault<Array<string>>('classesListFilterSelection', defaultClassesListSelection)
