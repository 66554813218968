// @flow
import { useState, useCallback, useMemo } from 'react';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import evolve from 'ramda/es/evolve';
import always from 'ramda/es/always';
import update from 'ramda/es/update';
import repeat from 'ramda/es/repeat';
import assocPath from 'ramda/es/assocPath';
import T from 'ramda/es/T';
import F from 'ramda/es/F';
import { useDispatch } from 'react-redux';

import {
  POST_LOAD_IMPORT_FILE,
  type ImportFileResult,
} from 'app/common/api/requests/food/items/load_import_file';

import {
  POST_PROCEED_IMPORT_FILE,
  type ProceedImportFileParams,
  type ProceedImportFileResult,
} from 'app/common/api/requests/food/items/proceed_import_file';

import { api } from 'app/api';
import { popup } from 'app/common/actions/popup';

import validatePreviewData from './validatePreviewData';


export type Options = {
  headersInside: boolean,
  allSchools: boolean,
  allMenu: boolean,
  allMenuSingle: boolean,
  allMenuComplex: boolean,
}

export const defaultOptions = {
  headersInside: false,
  allSchools: false,
  allMenu: false,
  allMenuSingle: false,
  allMenuComplex: false,
};

export type ImportedFileData = {
  fileId: number,
  fileName: string,
  previewData: Array<Array<any>>,
  fileFormatInvalid: boolean,
  options: Options,
  dataTypes: Array<string | null>,
  email: string,
  errors: {
    email: string,
  }
}

export const defaultImportedFileData: ImportedFileData = {
  fileId: 0,
  fileName: '',
  previewData: [],
  fileFormatInvalid: false,
  options: defaultOptions,
  dataTypes: [],
  email: '',
  errors: {
    email: '',
  },
};

export type ApiHandlers = {
  requestFileData: (
    file: File,
    onProgress: ({ loaded: number, total: number }) => void
  ) => void | Promise<void>,
  submit: () => boolean | Promise<boolean>,
}

export type ModifyHandlers = {
  reset: () => void,
  invalidate: () => void,
  optionUpdate: (keyName: string, checked: boolean) => void,
  setDataTypeByPosition: (position: number, value: string | null) => void,
  emailUpdate: (value: string) => void,
}

/**
 *  Hook для хранения и работы с данными импортируемого файла
 */

export default function useImportedFileData(): [ImportedFileData, ApiHandlers, ModifyHandlers] {
  const dispatch = useDispatch();
  const [importedFileData, setImportedFileData] = useState<ImportedFileData>(defaultImportedFileData);


  const validateAndUpdateFileData = useCallback((data: {
    fileId: number,
    fileName: string,
    previewData: Array<Array<any>>,
  }) => {
    if (validatePreviewData(data.previewData)) {
      setImportedFileData({
        ...defaultImportedFileData,
        ...data,
        dataTypes: repeat(null, data.previewData[0].length),
      });
      return;
    }
    setImportedFileData({
      ...defaultImportedFileData,
      fileName: data.fileName,
      fileFormatInvalid: true,
    });
  }, []);


  const validateForm = useCallback(() => {
    const { email } = importedFileData;
    if (!email) {
      setImportedFileData(assocPath(['errors', 'email'], 'Заполните обязательное поле'));
      return false;
    }
    return true;
  }, [importedFileData]);


  const handleRequestFileData = useCallback(async (
    file: File,
    onProgress: ({ loaded: number, total: number }) => void,
  ) => {
    const params = new FormData();
    params.append('import_file', file);

    const res: ImportFileResult | null = await api.request(POST_LOAD_IMPORT_FILE, {
      params,
      additionalAxiosOptions: {
        onUploadProgress: onProgress,
      },
      error: (error) => {
        const status = path(['response', 'status'], error);
        if (status === 415) {
          setImportedFileData({
            ...defaultImportedFileData,
            fileName: prop('name', file),
            fileFormatInvalid: true,
          });
          return 'Не удалось прочитать файл';
        }
        return 'Не удалось загрузить файл';
      },
    });

    if (res) {
      validateAndUpdateFileData({
        fileId: prop('file_id', res),
        fileName: prop('name', file),
        previewData: prop('preview_data', res),
      });
    }
  }, [validateAndUpdateFileData]);


  const handleReset = useCallback(() => {
    setImportedFileData(defaultImportedFileData);
  }, []);


  const handleInvalidate = useCallback(() => {
    setImportedFileData({
      ...defaultImportedFileData,
      fileName: importedFileData.fileName,
      fileFormatInvalid: true,
    });
  }, [importedFileData]);


  const handleOptionUpdate = useCallback((keyName: string, checked: boolean) => {
    let options = {
      [keyName]: always(checked),
    };
    setImportedFileData((currentState) => {
      const {
        options: {
          allSchools,
          allMenuSingle,
          allMenuComplex,
        },
      } = currentState;

      // связанные опции
      if (checked) {
        if (keyName === 'allMenu' && !allSchools) {
          options = { ...options, allSchools: T };
        }
        if (keyName === 'allMenu') {
          options = { ...options, allMenuSingle: T, allMenuComplex: T };
        }
      }
      if (!checked) {
        if (keyName === 'allMenuSingle' && !allMenuComplex) {
          options = { ...options, allMenu: F };
        }
        if (keyName === 'allMenuComplex' && !allMenuSingle) {
          options = { ...options, allMenu: F };
        }
        if (keyName === 'allMenu') {
          options = { ...options, allMenuComplex: F, allMenuSingle: F };
        }
      }
      return evolve({ options }, currentState); // изменятся только существующие опции
    });
  }, []);


  const handleSetDataTypeByPosition = useCallback((position: number, value: string | null) => {
    setImportedFileData(evolve({ dataTypes: update(position, value) }));
  }, []);


  const handleEmailUpdate = useCallback((value: string) => {
    setImportedFileData(evolve({
      email: always(value),
      errors: {
        email: always(''),
      },
    }));
  }, []);


  const handleSubmit = useCallback(async () => {
    if (!validateForm()) {
      return false;
    }

    const {
      fileId,
      options: {
        headersInside,
        allSchools,
        allMenuSingle,
        allMenuComplex,
      },
      dataTypes,
      email,
    } = importedFileData;

    const params: ProceedImportFileParams = {
      file_id: fileId,
      email,
      headers: headersInside,
      data_types: dataTypes,
      options: {
        all_schools: allSchools,
        all_menu_single: allMenuSingle,
        all_menu_complex: allMenuComplex,
      },
    };

    const res: ProceedImportFileResult | null = await api.request(POST_PROCEED_IMPORT_FILE, { params });

    if (res) {
      dispatch(popup('Файл отправлен на импорт, по завершении на указанный e-mail будет выслан отчёт'));
      return true;
    }
    return false;
  }, [dispatch, importedFileData, validateForm]);


  const apiHadlers = useMemo(() => ({
    requestFileData: handleRequestFileData,
    submit: handleSubmit,
  }), [handleRequestFileData, handleSubmit]);

  const modifyHadlers = useMemo(
    () => ({
      reset: handleReset,
      invalidate: handleInvalidate,
      optionUpdate: handleOptionUpdate,
      setDataTypeByPosition: handleSetDataTypeByPosition,
      emailUpdate: handleEmailUpdate,
    }),
    [
      handleReset,
      handleInvalidate,
      handleOptionUpdate,
      handleSetDataTypeByPosition,
      handleEmailUpdate,
    ],
  );


  return [importedFileData, apiHadlers, modifyHadlers];
}
