// @flow
import React from 'react';

import { appTypes } from 'app/common/api';

import Offer from 'app/common/components/Documents/Offer';
import Privacy from 'app/common/components/Documents/Privacy';
import Agreement from './Agreement';
import enhanceAbout from './enhanceAbout';
import OfferItem from './OfferItem';
import Header from './Header';
import AboutVersion from './AboutVersion';

import styles from './About.scss';

type Provider = {
  name: string,
  address: string,
  phone: string,
  email: string,
  inn: string,
  ogrn: string,
};

type Admin = {
  name: string,
  address: string,
  email: string,
  inn: string,
  ogrn: string,
  phone: string,
  terms_of_use_url: string,
};

type Props = {
  logo: string,
  showPublicOffer: (e: SyntheticEvent<HTMLElement>) => void,
  showPrivacy: (e: SyntheticEvent<HTMLElement>) => void,
  showUserAgreement: (e: SyntheticEvent<HTMLElement>) => void,
  handleClose: (e: SyntheticEvent<HTMLElement>) => void,
  offerId: ?number,
  privacyId: ?number,
  agreement: boolean,
  provider: Provider,
  admin: Admin,
  documents: {
    offers: Array<Object>,
    privacy: Array<Object>,
  },
  disableAgreement: boolean,
  t: (s: string, t: Object) => string,
};

const About = ({
  logo,
  showPublicOffer,
  showPrivacy,
  showUserAgreement,
  handleClose,
  offerId,
  privacyId,
  agreement,
  provider,
  admin,
  documents,
  disableAgreement,
  t,
}: Props) => {
  const disabledByCabinetType = (
    process.env.APP_NAME
    || process.env.REACT_APP_NAME
  ) === appTypes.APP_TYPE_FOOD;

  return (
    <div className={styles.root}>
      <div>

        {/* Инфа о поставщике (TODO в отдельный компонент) */}
        <Header onTop>{t('header')}</Header>
        {(provider.name || provider.address || provider.inn || provider.ogrn)
          && <div className={styles.p}>
            {provider.name && <div>{provider.name}</div>}
            {provider.address && <div>{provider.address}</div>}
            {provider.inn && <div>{t('inn', { inn: provider.inn })}</div>}
            {provider.ogrn && <div>{t('ogrn', { ogrn: provider.ogrn })}</div>}
          </div>}
        {(provider.phone || provider.email)
          && <div className={styles.p}>
            {provider.phone && <dl>
              <dt>
                {t('phone_short')}
                {':'}
              </dt>
              <dd>{provider.phone}</dd>
            </dl>}
            {provider.email && <dl>
              <dt>{'E-mail:'}</dt>
              <dd>{provider.email}</dd>
            </dl>}
          </div>}
        {documents.offers.length > 0
          && !disabledByCabinetType
          && documents.offers.map(offer => (<OfferItem
            key={offer.id}
            offer={offer}
            className={styles.p}
            onClick={showPublicOffer}
          />))}
        {documents.privacy.length > 0
          && !disabledByCabinetType
          && documents.privacy.map(privacy1 => (<OfferItem
            key={privacy1.id}
            offer={privacy1}
            className={styles.p}
            onClick={showPrivacy}
          />))}

        {/* Инфа об администраторе (выключено) */}
        {/* <h2 style={{ paddingTop: 30 }}>Администратор</h2> */}
        {/* {(admin.name || admin.address || admin.inn || admin.ogrn)
          && <div className={styles.p}>
            {admin.name && <div>{admin.name}</div>}
            {admin.address && <div>{admin.address}</div>}
            {admin.inn && <div>{`ИНН ${admin.inn}`}</div>}
            {admin.ogrn && <div>{`ОГРН ${admin.ogrn}`}</div>}
          </div>} */}
        {/* {(admin.phone || admin.email)
          && <div className={styles.p}>
            {admin.phone && <dl>
              <dt>Тел.:</dt>
              <dd>{admin.phone}</dd>
            </dl>}
            {provider.email && <dl>
              <dt>E-mail:</dt>
              <dd>{admin.email}</dd>
            </dl>}
          </div>} */}

        {admin.terms_of_use_url
          && !disabledByCabinetType
          && !disableAgreement
          && <div className={styles.p}>
            <a href="showUserAgreement" onClick={showUserAgreement}>
              {t('agreement_link')}
            </a>
          </div>}

        {/* Инфа о версии */}
        <AboutVersion />
      </div>

      <div>
        <img src={logo} className={styles.logo} alt="logo" />
      </div>
      {offerId
        && <Offer doc={documents.offers.find(o => o.id === offerId) || {}} handleClose={handleClose} />}
      {privacyId
        && <Privacy doc={documents.privacy[0]} handleClose={handleClose} />}
      {agreement
        && <Agreement close={handleClose} url={admin.terms_of_use_url} />}
    </div>
  );
};

export default enhanceAbout(About);
