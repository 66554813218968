// @flow

// DEPRECATED
export const GROUP_FILTER_SET = 'reports/GROUP_FILTER_SET';
export const GROUP_FILTER_UPDATE = 'reports/GROUP_FILTER_UPDATE';
export const GROUP_FILTER_DEFAULT = 'reports/GROUP_FILTER_DEFAULT';

export const GROUP_SPECIAL_FILTER_SET = 'reports/GROUP_SPECIAL_FILTER_SET';
export const GROUP_SPECIAL_FILTER_UPDATE = 'reports/GROUP_SPECIAL_FILTER_UPDATE';
export const GROUP_SPECIAL_FILTER_DEFAULT = 'reports/GROUP_SPECIAL_FILTER_DEFAULT';

export const BY_GROUPS_AND_TYPES: 'by_groups_and_types' = 'by_groups_and_types';
export const BY_USERS: 'by_users' = 'by_users';

export const BY_STUDENTS: 'by_students' = 'by_students';
export const BY_CLASSES: 'by_klasses' = 'by_klasses';
export const BY_CLASSES_AND_ORDERS: 'by_klasses_and_orders' = 'by_klasses_and_orders';

export const BY_PURCHASES: 'by_purchases' = 'by_purchases';
export const BY_ITEMS_AND_COMPLEXES: 'by_items_and_complexes' = 'by_items_and_complexes';
export const BY_GROUP_COMPLEXES: 'by_group_complexes' = 'by_group_complexes';

export const lang = {
  [BY_GROUPS_AND_TYPES]: 'типам пользователя и классам',
  [BY_USERS]: 'пользователям',

  [BY_STUDENTS]: 'пользователям и справкам',
  [BY_CLASSES]: 'классам',
  [BY_CLASSES_AND_ORDERS]: 'классам и справкам',

  // BY_GROUPS_AND_TYPES
  [BY_PURCHASES]: 'продажам',
  [BY_ITEMS_AND_COMPLEXES]: 'продукции и комплектам',
  [BY_GROUP_COMPLEXES]: 'комплектам (групповой талон)',
};

export const getTitle = (key: string): string => {
  return lang[key] || '???';
};

export const groupsByReport = {
  balances: [BY_GROUPS_AND_TYPES, BY_USERS],
  subsidies_detailed: [BY_STUDENTS, BY_CLASSES, BY_CLASSES_AND_ORDERS],
  purchases: [BY_GROUPS_AND_TYPES, BY_PURCHASES, BY_ITEMS_AND_COMPLEXES, BY_GROUP_COMPLEXES],
};
