import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { defaultStatementStatusesSelection, statementStatusesFilter, statementStatusesFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

export const StatementStatusesFilter = ({ id, pending }: Props) => {
  return (
    <PlainItemsSelectorBox
      id={id}
      label="Статус заявок"
      header="Выбор статуса заявок"
      dataBoxData={statementStatusesFilter}
      dataBoxSelection={statementStatusesFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      disabled={pending}
      multiple
      defaultSelection={defaultStatementStatusesSelection}
    />
  )
}
