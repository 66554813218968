// @flow
import React from 'react';
import classNames from 'classnames/bind';
import defaultProps from 'recompose/defaultProps';
import componentFromProp from 'recompose/componentFromProp';

import styles from './Table.scss';


const cx = classNames.bind(styles);
const enhance = defaultProps({ component: 'div' });
const Div = enhance(componentFromProp('component'));

export type Props = {
  grow?: number,
  width?: number,
  children: any,
  className?: string,
  alignRight?: boolean,
  alignMiddle?: boolean,
  hovered?: boolean,
};

const Cell = ({
  children,
  grow = 1,
  width,
  className,
  alignRight,
  alignMiddle,
  hovered,
  ...others
}: Props) => {
  const style = {
    // $FlowFixMe
    ...(grow ? { flexGrow: grow } : {}),
    ...(width ? { flexBasis: width } : {}),
  };

  return (
    <Div
      className={cx(styles.cell, {
        alignRight,
        alignMiddle,
        hovered,
      }, className)}
      style={style}
      // $FlowFixMe
      {...others}
    >
      {children}
    </Div>
  );
};

export default Cell;
