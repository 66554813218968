import { useState } from 'react'

import { Space } from 'app/common/templates-next/report-template'

import { Filters } from './filters'
import { Result } from './result'


const Corrections = () => {
  const [reportID, setReportID] = useState<null | string>(null)
  return (
    <>
      <Filters onReportID={setReportID} />
      <Space />
      <Result reportID={reportID} onReportID={setReportID} />
    </>
  )
}

export default Corrections
