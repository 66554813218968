// @flow
import React, { Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import length from 'ramda/es/length';
import isEmpty from 'ramda/es/isEmpty';

import { Table } from 'app/common/ui-components/table';
import CSSSticky from 'app/common/ui-components/css-sticky';
import useSortState from 'app/common/hooks/useSortState';
import usePaging from 'app/common/hooks/usePaging';
import Paging from 'app/common/ui/Paging';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import useSortedItems from './useSortedItems';
import useActualItems from './useActualItems';
import ItemsHeaderRow from './ItemsHeaderRow';
import ItemRow from './ItemRow';
import { getColumns } from './columns';
import type { Item, Complex } from '../types';

import styles from './Items.scss';

/**
 *  Отчет с детализацией по продукции и комплектам
 */

type Props = {
  items?: Array<Item>,
  complexes?: Array<Complex>,
  skipPortionAndPriceColumns?: boolean,
  skipPortion?: boolean,
}

const Items = ({
  items = [],
  complexes = [],
  skipPortionAndPriceColumns = false,
  skipPortion = false,
}: Props) => {
  const currency = useCurrencySign(co.SPACE_BEFORE);
  const [{ sortDirection, sortKey }, handleSort] = useSortState('down', 'title');
  const { currentPage, handlePage } = usePaging();

  const rows = useSelector(state => state.settings.reports.rows, shallowEqual);

  const [kitsSorted, itemsSorted] = useSortedItems(items, complexes, sortKey, sortDirection);
  const total = length(kitsSorted) + length(itemsSorted);
  const itemsActual = useActualItems(kitsSorted, itemsSorted, rows * (currentPage - 1), rows * currentPage);

  // const [refFrom, refTo] = useCopyColumnsWidths(/* updateKey */); // не надо - все колонки fixed 20%

  const currentColumns = getColumns({
    skipPortion,
    special: skipPortionAndPriceColumns,
  });

  if (isEmpty(kitsSorted) && isEmpty(itemsSorted)) {
    return null;
  }

  return (
    <Fragment>
      <DetailsHeader reportTitle="Продажи" />

      <CSSSticky
        number={3}
        className={styles.stickableHeader}
        stickyClassName={styles.sticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <ItemsHeaderRow
              // reference={refTo}
              columns={currentColumns}
              sortKey={sortKey}
              sortDirection={sortDirection}
              onSort={handleSort}
              sortable
            />
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <thead>
          <ItemsHeaderRow
            // reference={refFrom}
            columns={currentColumns}
            sortable
            hidden
            dummy
          />
        </thead>
        <tbody>
          {itemsActual.map((itemActual) => {
            const { id } = itemActual;

            return (
              <ItemRow
                key={id}
                columns={currentColumns}
                currency={currency}
                {...itemActual}
              />
            );
          })}
        </tbody>
      </Table>


      {/* ПОДВАЛ */}
      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        {/* Постарничная навигация */}
        <Paging
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={length(itemsActual)}
          onPage={handlePage}
        />

      </CSSSticky>
      {/* кон. подвала */}

    </Fragment>
  );
};

export default Items;
