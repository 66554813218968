import { ReportDataParams, ReportData } from 'app/dataTypes/reports/data'
import { ReportType, AnyReportType } from 'app/dataTypes/reports'
import { FinanceReportType } from 'app/dataTypes/reports/finances'

import type { Request } from '../../index'

import { convertResultCorrections, type ApiResultCorrections } from './_predefined/common/corrections'
import { convertResultEmoneySales, type ApiResultEmoneySales } from './_predefined/finances/emoney-sales'


type ApiParams = {
  report_id: number,
}

export const convertParams = <RT extends AnyReportType>(params: ReportDataParams<RT>): [ApiParams, null] => {
  const { reportID } = params
  return [{ report_id: parseInt(reportID, 10) }, null]
}

type ApiResult =
  | ApiResultCorrections
  | ApiResultEmoneySales

export const convertResult = <RT extends AnyReportType>(
  res: ApiResult,
  params: ReportDataParams<RT>,
): ReportData<RT> => {
  const { reportType } = params

  if (reportType === ReportType.Corrections) {
    return convertResultCorrections(res as ApiResultCorrections) as ReportData<RT>
  }
  if (reportType === FinanceReportType.EmoneySales) {
    return convertResultEmoneySales(res as ApiResultEmoneySales) as ReportData<RT>
  }
  // if (reportType === ReportType.OtherReport) return convertOther() as ReportData<RT>

  return {} as never
}

export const GET_REPORTS_DATA: Request<
  ReportDataParams<ReportType>,
  ReportData<ReportType>,
  void
> = ['reports/data', 'get', ['data', 'data']]
