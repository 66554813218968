// @flow
import React, { memo } from 'react';

import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import FiltersPlainLayout, { Column } from 'app/common/layouts/FiltersPlainLayout';
import { FilterItem, useFilterData } from 'app/common/ui-components/filter';

import ResetFilterLink from 'app/components/common/reports/ResetFilterLink';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';

import orgsOrUserFilter, { flags as orgsOrUser } from 'app/components/common/reports/filters/orgsOrUser';
import customerSearchFilter, { flags as customerSearch } from 'app/components/common/reports/filters/customerSearch';
import orgTypesFilter from 'app/components/common/reports/filters/orgType';
import orgsFilter from 'app/components/common/reports/filters/org';
import yearFilter from 'app/components/common/reports/filters/year';
import customerTypeFilter from 'app/components/common/reports/filters/customerType';
import classFilter from 'app/components/common/reports/filters/class';
import detailsFilter from 'app/components/common/reports/filters/details';
import mailFilter from 'app/components/common/reports/filters/mail';
import studentStatusFilter from 'app/components/common/reports/filters/studentStatus';
import studentFilter from 'app/components/common/reports/filters/student';
import employeeFilter from 'app/components/common/reports/filters/employee';
import currentStateDateFilter from 'app/components/common/reports/filters/currentStateDate';
import balanceTypeFilter from 'app/components/common/reports/filters/balanceType';

import groupFilter from './filters/group';
import sortFilter from './filters/sort';
import commonBalanceFilter from './filters/commonBalance';

import styles from './ReportFilter.scss';


type Props = {
  loading?: boolean,
}

const ReportFilter = memo<Props>(({ loading }: Props) => {
  const [, current,, { resetAll, applyFilters }] = useFilterData();

  const foundCustomerWithType = (
    customerSearch.customerFound(current)
    && customerSearch.foundCustomerHasType(current)
  );

  const disabled = (
    orgsOrUser.byUserSelected(current)
    && !foundCustomerWithType
  );

  return (
    <ReportSectionCSS
      header="Фильтры отчета «Остаток средств»"
      stickyNumber={1}
      skipNextShift
      headerBar={
        <ResetFilterLink
          title="Сбросить все фильтры"
          onClick={resetAll}
        />
      }
    >

      {/* Окно с фильтром */}
      <RowLayoutContent paddingfree>
        <FiltersPlainLayout
          onApplyFilter={applyFilters}
          onResetAll={resetAll}
          loading={loading}
          disabled={disabled}
          skipHeader
        >
          <Column className={styles.column}>
            <FilterItem filterId={orgsOrUserFilter} />
            <FilterItem filterId={customerSearchFilter} />
            <FilterItem filterId={orgTypesFilter} />
            <FilterItem filterId={orgsFilter} />
            <FilterItem filterId={customerTypeFilter} />
            <FilterItem filterId={studentStatusFilter} />
            <FilterItem filterId={classFilter} />
            <FilterItem filterId={studentFilter} />
            <FilterItem filterId={employeeFilter} />
            <FilterItem filterId={detailsFilter} />
            <FilterItem filterId={groupFilter} />
            <FilterItem filterId={sortFilter} />
          </Column>
          <Column className={styles.column}>
            <FilterItem filterId={yearFilter} />
            <FilterItem filterId={currentStateDateFilter} />
            <FilterItem filterId={balanceTypeFilter} />
            <FilterItem filterId={commonBalanceFilter} />
            <FilterItem filterId={mailFilter} />
          </Column>
        </FiltersPlainLayout>
      </RowLayoutContent>
    </ReportSectionCSS>
  );
});

export default ReportFilter;
