import { controls } from './formParts/AccuralsForForm'


export const checkErrorsAccrualsFor = (err: Map<string, string>, formData: FormData) => {
  if (!(
    formData.get(`${controls.single}1`)
    || formData.get(`${controls.single}2`)
    || formData.get(`${controls.class}1`)
    || formData.get(`${controls.class}2`)
    || formData.get(`${controls.dayCare}1`)
    || formData.get(`${controls.dayCare}2`)
  )) {
    err.set('accrualsFor', 'Выберите хотя бы один пункт')
  }
}
