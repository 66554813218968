// @flow
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import ClassesAndOrders from './ClassesAndOrders';

/**
 *  Показывать отчет, только когда есть соответствующие данные
 */

const GroupsViewer = () => {
  const view = !!useSelector(state => state.reports.subsidies.data.klasses_and_orders, shallowEqual);

  return (view ? <ClassesAndOrders /> : null);
};

export default GroupsViewer;
