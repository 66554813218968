// @flow
import { useCallback, useState, useEffect, useMemo } from 'react';
import prop from 'ramda/es/prop';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import groupBy from 'ramda/es/groupBy';
import evolve from 'ramda/es/evolve';
import pick from 'ramda/es/pick';
import map from 'ramda/es/map';

import { GET_ROLES, type Roles, type Role } from 'app/common/api/requests/food/roles';
import { api } from 'app/api';

import * as employeeTypes from '../../employeeTypes';


const defaultRoles: Roles = {};
const defaultGroups = (object: Object) => ({
  [employeeTypes.OFFICE]: [],
  [employeeTypes.ATWORK]: [],
  ...object,
});

export type RoleItem = {
  id: number,
  title: string,
}

export default function useRoles(): [{
  [$Values<typeof employeeTypes>]: Array<RoleItem>,
}, boolean] {
  const [roles, setRoles] = useState(defaultRoles);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_ROLES, {
      error: 'Не удалось получить данные должностей',
    });

    if (res) {
      const r: Roles = prop('roles', res) || defaultRoles;
      setRoles(r);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    request();
  }, [request]);

  const groups = useMemo(() => {
    return pipe(
      values,
      groupBy(({ for_all_schools: forAllSchools }: Role) => (
        forAllSchools
          ? employeeTypes.OFFICE
          : employeeTypes.ATWORK
      )),
      defaultGroups,
      evolve({
        [employeeTypes.OFFICE]: map(pick(['id', 'title'])),
        [employeeTypes.ATWORK]: map(pick(['id', 'title'])),
      }),
    )(roles);
  }, [roles]);

  return [groups, loading];
}
