// @flow
import { createContext } from 'react';

import { defaultVendingStatus, type VendingStatus } from './status/useVendingStatus';
import { defaultVendingMachines, type VendingMachines } from './machines/useVendingMachines';
import { defaultVendingMachinesParams, type VendingMachinesParams } from './machines/useVendingMachinesParams';

import { defaultVendingMachineSingle, type VendingMachineSingle } from './machineSingle/useVendingMachineSingle';
import { defaultMachineForm, type MachineForm } from './machineSingle/useMachineForm';
import { defaultMachineValidate, type MachineValidate } from './machineSingle/useMachineValidate';
import { defaultMachineSubmit, type MachineSubmit } from './machineSingle/useMachineSubmit';
import { defaultMachineRequest, type MachineRequest } from './machineSingle/useMachineRequest';
import { defaultMachineDelete, type MachineDelete } from './machineSingle/useMachineDelete';

import { defaultRelatedOrgs, type RelatedOrgs } from './orgs/useRelatedOrgs';
import { defaultAvailableOrgs, type AvailableOrgs } from './orgs/useAvailableOrgs';
import { defaultModels, type Models } from './models/useModels';
import { defaultVersions, type Versions } from './versions/useVersions';


export type {
  VendingStatus,
  VendingMachines,
  VendingMachinesParams,
  VendingMachineSingle,
  MachineForm,
  MachineValidate,
  MachineSubmit,
  MachineRequest,
  MachineDelete,
  RelatedOrgs,
  AvailableOrgs,
  Models,
  Versions,
};

type VendingDataContext = {
  status: VendingStatus,
  machines: VendingMachines,
  machinesParams: VendingMachinesParams,
  machineSingle: VendingMachineSingle,
  machineForm: MachineForm,
  machineValidate: MachineValidate,
  machineSubmit: MachineSubmit,
  machineRequest: MachineRequest,
  machineDelete: MachineDelete,
  relatedOrgs: RelatedOrgs,
  availableOrgs: AvailableOrgs,
  models: Models,
  versions: Versions,
}

const defaultContext: VendingDataContext = {
  status: defaultVendingStatus,
  machines: defaultVendingMachines,
  machinesParams: defaultVendingMachinesParams,
  machineSingle: defaultVendingMachineSingle,
  machineForm: defaultMachineForm,
  machineValidate: defaultMachineValidate,
  machineSubmit: defaultMachineSubmit,
  machineRequest: defaultMachineRequest,
  machineDelete: defaultMachineDelete,
  relatedOrgs: defaultRelatedOrgs,
  availableOrgs: defaultAvailableOrgs,
  models: defaultModels,
  versions: defaultVersions,
};

export default createContext<VendingDataContext>(defaultContext);
