import dissoc from 'ramda/es/dissoc';
import omit from 'ramda/es/omit';

import { OFFER_ACCEPT_SUCCESS } from 'app/common/actions/documents/acceptOffer';
import { PRIVACY_ACCEPT_SUCCESS } from 'app/common/actions/documents/acceptPrivacy';
import {
  DOCUMENTS_REQUEST,
  DOCUMENTS_SUCCESS,
  // DOCUMENTS_FAILURE,
} from 'app/common/actions/documents';

export const defaultState = {
  loading: true,
  expired: 0,
  result: null,
};

const documents = (state = defaultState, action) => {
  if (action.type === DOCUMENTS_REQUEST) {
    return { ...state, loading: true };
  }
  if (action.type === DOCUMENTS_SUCCESS) {
    return { ...state, ...action.payload, loading: false };
  }
  if (action.type === OFFER_ACCEPT_SUCCESS) {
    return dissoc(action.payload, state);
  }
  if (action.type === PRIVACY_ACCEPT_SUCCESS) {
    return omit(action.payload, state);
  }
  return state;
};

export default documents;
