// import path from 'ramda/es/path';
// import values from 'ramda/es/values';
// import map from 'ramda/es/map';
// import fromPairs from 'ramda/es/fromPairs';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';

import api from 'app/actions/api';


export const USER_RIGHTS_REQUEST = 'USER_RIGHTS_REQUEST';
export const USER_RIGHTS_SUCCESS = 'USER_RIGHTS_SUCCESS';
export const USER_RIGHTS_FAILURE = 'USER_RIGHTS_FAILURE';
export const USER_RIGHTS_GRANT_SU = 'USER_RIGHTS_GRANT_SU';

const userRightsRequest = () => ({
  type: USER_RIGHTS_REQUEST,
});

const userRightsSuccess = payload => ({
  type: USER_RIGHTS_SUCCESS,
  payload,
});

const userRightsFailure = payload => ({
  type: USER_RIGHTS_FAILURE,
  payload,
});

// export const userRightsGrantSU = () => ({
//   type: USER_RIGHTS_GRANT_SU,
// });

// Запрос прав. Если тип пользователя 'user' пирийдет пустой объект.
// user имеет полные права
export const userRightsFetch = () => async (dispatch, getState) => {
  if (getState().userRights.expired > Date.now()) return {};
  dispatch(userRightsRequest());
  try {
    const res = await api('food/roles/current/rights', 'get');
    const data = pipe(
      prop('data'),
      omit(['result']),
    )(res) || {};
    // const res = await api(`food/roles/${roleId}/rights`, 'get');
    // const userRights = pipe(
    //   path(['data', 'sections']),
    //   values,
    //   map(({ key, subsections }) => ([key, pipe(
    //     values,
    //     map(({ key: key2, scopes }) => ([key2, {
    //       read: path(['WEB', 'read', 'checked'], scopes),
    //       edit: path(['WEB', 'edit', 'checked'], scopes),
    //     }])),
    //     fromPairs
    //   )(subsections)])),
    //   fromPairs
    // )(res);
    dispatch(userRightsSuccess({ ...data, expired: Date.now() + 360000 }));
    return data;
  } catch (error) {
    dispatch(userRightsFailure({ err: 'Не удалось получить права пользователя', error }));
    return false;
  }
};
