import { GeneralSettings } from 'app/dataTypes/generalSettings'

import type { Request } from '../../index'


export type GeneralSettingsParams = {
  settings: {
    use_nomenclature?: boolean,
    parallels_for_common_order?: Array<number>,
    e_money_enabled?: boolean,
    use_grocery_set_price?: boolean,
  }
}

export const convertParams = (data: GeneralSettings): [GeneralSettingsParams, null] => {
  const result = {
    use_nomenclature: data.useNomenclature,
    parallels_for_common_order: data.parallelsForCommonOrder,
    e_money_enabled: data.eMoneyEnabled,
    use_grocery_set_price: data.useGrocerySetPrice,
  }
  return [{ settings: result }, null]
}

type ApiResult = {
  use_nomenclature: boolean
  parallels_for_common_order: Array<number>
  e_money_enabled: boolean
  use_grocery_set_price: boolean
  use_compensations: boolean
  use_common_order_complex_choose: boolean
}

export const convertResult = (result: ApiResult): GeneralSettings => {
  const {
    use_nomenclature: useNomenclature,
    parallels_for_common_order: parallelsForCommonOrder,
    e_money_enabled: eMoneyEnabled,
    use_grocery_set_price: useGrocerySetPrice,
    use_compensations: useСompensations,
    use_common_order_complex_choose: useCommonOrderComplexChoose,
  } = result

  return {
    useNomenclature,
    parallelsForCommonOrder,
    eMoneyEnabled,
    useGrocerySetPrice,
    useСompensations,
    useCommonOrderComplexChoose,
  }
}

export const POST_GENERAL_SETTINGS: Request<GeneralSettingsParams, GeneralSettings, void> = [
  'food/general_settings',
  'post',
  ['data', 'settings'],
]
