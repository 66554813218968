import { dishesFieldEdit as dishesFieldEditAction } from 'app/actions/dishes/field';
import { listeditAdd, listeditRemove } from 'app/actions/orgs/dishes/listedit';


// возвращает функции для работы с апи и данными блюд
const connectActions = dispatch => ({
  dishesRemoveItem: null,
  dishesFieldEdit: null,
  dishesFieldEditOriginal: (...params) => dispatch(dishesFieldEditAction(...params)),
  dishesAddToSelection: id => dispatch(listeditAdd(parseInt(id, 10))),
  dishesRemoveFromSelection: id => dispatch(listeditRemove(parseInt(id, 10))),
});

export default connectActions;
