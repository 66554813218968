import isEmpty from 'ramda/es/isEmpty'

import { Table } from 'app/common/ui-components/table'
import Loading from 'app/common/components/Loader'
import EmptyWarn from 'app/common/layouts/EmptyWarn'

import Sticky from 'app/common/templates-next/sticky'
import OrgsListTableTHead from './OrgsListTableTHead'
import OrgsListTableRow from './OrgsListTableRow'
import OrgsListTableRowEnterprise from './OrgsListTableRowEnterprise'
import type { RowItem } from './useOrgsListTableItems'
import useAdditionalDataQueue from './useAdditionalDataQueue'
import { orgTypes } from '../useOrgsAndRoles'


type Props = {
  items: Array<RowItem>
  loading: boolean
  orgType: (typeof orgTypes)[keyof typeof orgTypes]
}

const OrgsListTable = ({ items, loading, orgType }: Props) => {
  const nothing = isEmpty(items)
  const [sellsById, debtsById, presenceById] = useAdditionalDataQueue(items)

  return (
    <>
      {nothing
        && loading
        && <Loading />}

      {!loading
        && nothing
        && <EmptyWarn>
          {'Нет записей, удовлетворяющих условиям'}
        </EmptyWarn>}

      {!nothing
        && <Table wide>
          <Sticky>
            {(ref, stuck, shadow) => (
              <thead ref={ref}>
                <OrgsListTableTHead
                  enterprise={orgType === orgTypes.ENTERPRISE}
                  shadow={shadow}
                  stuck={stuck}
                />
              </thead>
            )}
          </Sticky>

          <tbody>
            {items.map((item) => {
              const { id, presence, sells, debts } = item

              if (orgType === orgTypes.ENTERPRISE) {
                return (
                  <OrgsListTableRowEnterprise
                    key={id}
                    disabled={loading}
                    {...item}
                  />
                )
              }

              return (
                <OrgsListTableRow
                  key={id}
                  disabled={loading}
                  {...item}
                  sells={sellsById[String(id)] || sells}
                  debts={debtsById[String(id)] || debts}
                  presence={presenceById[String(id)] || presence}
                />
              )
            })}
          </tbody>
        </Table>}
    </>
  )
}

export default OrgsListTable
