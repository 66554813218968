// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './PlainGrid.scss';


const cx = classNames.bind(styles);
type Props = {
  children: any,
  header?: string | React.Element<any>,
  isError?: boolean,
  error?: any,
  className?: string,
  skipMargin?: boolean,
}

const PlainGroup = ({ children, header, isError, error, className, skipMargin }: Props) => (<div className={cx({ isError, error, margin: !skipMargin }, styles.group, className)}>
  {header && <div className={styles.groupHeader}>{header}</div>}
  {children}
  {error && <div className={styles.errorText}>{error}</div>}
</div>);

export default PlainGroup;
