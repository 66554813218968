import { useCallback, useState, type ReactNode } from 'react'
import cn from 'classnames/bind'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainLinkAsButton from 'app/common/ui-next/plain-link-as-button'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon'
import PlainModal, { PlainModalHeader, PlainModalContent, PlainModalFooter } from 'app/common/ui-next/plain-modal'

import styles from './tile.scss'


export enum TileType {
  Application = 'Application',
  Report = 'Report'
}

const cx = cn.bind(styles)

const DefaultIcon = () => (
  <PlainIcon
    type={iconTypes.REPORT}
    width={80}
    height={80}
    colorType={colorTypes.COLOR_PRIMARY}
  />
)

type Props = {
  title?: string
  preTitle?: string
  swapTitleSizes?: boolean
  info?: string
  link?: string
  linkButtonTitle?: string
  hideLinkButton?: boolean
  linkButtonArrow?: boolean
  showMoreButton?: boolean
  moreButtonTitle?: string
  iconContent?: ReactNode
  type?: TileType
  compact?: boolean
  children?: ReactNode
}

const Tile = ({
  title = '',
  preTitle,
  swapTitleSizes = false,
  info = '',
  link = '',
  linkButtonTitle = 'Перейти',
  hideLinkButton = false,
  linkButtonArrow = false,
  showMoreButton,
  moreButtonTitle = 'Подробнее',
  iconContent = <DefaultIcon />,
  type = TileType.Application,
  compact = false,
  children,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const toggleModal = useCallback(() => {
    setModalOpen(current => !current)
  }, [])

  const preTitleClassName = cx({
    preTitle: !swapTitleSizes,
    mainTitle: swapTitleSizes,
    [type]: swapTitleSizes,
  })

  const titleClassName = cx({
    mainTitle: !swapTitleSizes,
    preTitle: swapTitleSizes,
    [type]: !swapTitleSizes,
  })

  return (
    <div className={cx(styles.root, { compact }, styles[type])}>
      <div className={styles.header}>
        <div className={styles.icon}>
          {iconContent}
        </div>
        <div className={cx(styles.title)}>
          {preTitle && <div className={preTitleClassName}>{preTitle}</div>}
          <div className={titleClassName}>{title}</div>
        </div>
      </div>

      {!compact
        && <div className={cx(styles.content, { withMoreButton: showMoreButton })}>
          <div className={cx(styles.contentText, { withMoreButton: showMoreButton })}>
            {info}
          </div>

          {showMoreButton
            && <PlainButtonAsLink onClick={toggleModal} className={styles.contentLink}>
              {moreButtonTitle}
            </PlainButtonAsLink>}
        </div>}

      <div>
        {children}

        <ControlsBar className={styles.controls} right>
          {!hideLinkButton
            && <PlainLinkAsButton to={link} disabled={!link}>
              {linkButtonTitle}
              {linkButtonArrow
                && <PlainIcon
                  type={iconTypes.ARROW_RIGHT}
                  colorType={colorTypes.COLOR_WHITE}
                  width={22}
                  height={22}
                />}
            </PlainLinkAsButton>}
        </ControlsBar>
      </div>

      {modalOpen
        && <PlainModal className={styles.modal} show>
          <PlainModalHeader>
            {title}
          </PlainModalHeader>
          <PlainModalContent>
            {info}
          </PlainModalContent>
          <PlainModalFooter>
            <ControlsBar right>
              <PlainLinkAsButton to={link} disabled={!link}>
                {linkButtonTitle}
              </PlainLinkAsButton>
            </ControlsBar>
          </PlainModalFooter>
        </PlainModal>}
    </div>
  )
}

export default Tile
