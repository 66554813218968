// @flow
import { useCallback, useState, useEffect } from 'react';
import { reject, assoc, forEach, prop } from 'ramda';

import toFixedValue from 'app/common/lib/toFixedValue';
import { api } from 'app/api';
import { GET_DAILY_SELLS } from 'app/common/api/requests/food/school/daily_sales_sum';
import { GET_DEBTS } from 'app/common/api/requests/food/school/debts';
import { GET_MENU_PRESENCE } from 'app/common/api/requests/food/school/menu_presence';

import orgsQueue from './orgsQueue';
import { type RowItem } from './useOrgsListTableItems';


type SellsState = {
  [id: string]: {
    complex: string,
    personal: string,
  },
}

type DebtsState = {
  [id: string]: string,
}

type PresenceState = {
  [id: string]: {
    complex: string,
    personal: string,
  },
}

export default function useAdditionalDataQueue(items: Array<RowItem>): [
  SellsState,
  DebtsState,
  PresenceState
] {
  const [sells, setSells] = useState<SellsState>({});
  const [debts, setDebts] = useState<DebtsState>({});
  const [presence, setPresence] = useState<PresenceState>({});

  const requestSells = useCallback(async (id: number) => {
    const res = await api.request(GET_DAILY_SELLS, {
      requestPathParams: {
        orgId: id,
      },
    });

    if (res) {
      setSells(assoc(id, {
        complex: toFixedValue(prop('complex', res)),
        personal: toFixedValue(prop('personal', res)),
      }));
    }
  }, []);

  const requestDebts = useCallback(async (id: number) => {
    const res = await api.request(GET_DEBTS, {
      requestPathParams: {
        orgId: id,
      },
    });

    if (res) {
      setDebts(assoc(id, toFixedValue(Math.abs(parseFloat(res)))));
    }
  }, []);

  const requestPresence = useCallback(async (id: number) => {
    const res = await api.request(GET_MENU_PRESENCE, {
      requestPathParams: {
        orgId: id,
      },
    });

    if (res) {
      setPresence(assoc(id, {
        complex: String(prop('complex', res)),
        personal: String(prop('personal', res)),
      }));
    }
  }, []);

  const requestAll = useCallback(async (id: number) => {
    await Promise.all([
      requestSells(id),
      requestDebts(id),
      requestPresence(id),
    ]);
  }, [
    requestSells,
    requestDebts,
    requestPresence,
  ]);

  useEffect(() => {
    const actualItems = reject(prop('locked'), items);

    forEach(({ id, locked }: RowItem) => {
      orgsQueue.open(() => requestAll(id), true);
    }, actualItems);

    return () => {
      orgsQueue.stop();
      setSells({});
      setDebts({});
      setPresence({});
    };
  }, [items, requestAll]);

  return [sells, debts, presence];
}
