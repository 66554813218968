// @flow
import React, { useCallback } from 'react';
import Button from 'app/common/ui/Button';

import styles from './DebugButton.scss';


export const keyName = 'stickyType';

export const stickyType = {
  CSS: 'css',
  JS: 'js',
};

export const getStickyType = () => {
  // тип 'js' должен быть явно установлен, иначе 'css'
  if (localStorage.getItem(keyName) === stickyType.JS) return stickyType.JS;
  return stickyType.CSS;
};

const DebugButton = () => {
  const current = getStickyType();

  const handleToggle = useCallback((e) => {
    e.preventDefault();
    localStorage.setItem(keyName, current === stickyType.CSS
      ? stickyType.JS
      : stickyType.CSS);
    document.location.reload();
  }, [current]);

  return (
    <div>
      <div className={styles.info}>
        {current === stickyType.JS
          ? 'Используется медленное программное прилипание заголовков (js)'
          : 'Используется быстрое браузерное прилипание заголовков (если поддерживается) (css)'}
      </div>
      <Button value="Переключить" small className={styles.button} onClick={handleToggle} />
    </div>
  );
};

export default DebugButton;
