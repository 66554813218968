// @flow
import React, { type Ref } from 'react';

import { Tr, Th, getPropsId } from 'app/common/ui-components/table';

import { ids } from './columns';


type Props = {
  reference?: Ref<'tr'>,
  hidden?: boolean,
  dummy?: boolean,
  titles: Array<string>,
}

const OOPTableTHead = ({
  reference,
  hidden,
  dummy,
  titles,
}: Props) => {
  const cellProps = getPropsId(dummy);

  return (
    <Tr
      reference={reference}
      hidden={hidden}
      header
    >
      {ids.map((id, i) => (
        <Th
          key={id}
          alignLeft
          {...cellProps(id)}
        >
          {titles[i]}
        </Th>
      ))}
    </Tr>

  );
};

export default OOPTableTHead;
