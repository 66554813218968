// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import { withTranslation } from 'react-i18next';

import config from 'app/config';
import ClickOutside from 'app/common/ui/ClickOutside';
import IconGear from 'app/common/icons/IconGear';
import NumberSwitcher from 'app/common/ui/NumberSwitcher';
import styles from './DefaultSettings.scss';

const cx = classNames.bind(styles);

/**
* Стандартный компонент для настроек с выбором количества
* строк на странице
*/

type Props = {
  settings: Object,
  saveSettings: (e: SyntheticEvent<HTMLElement>) => void,
  showDefault?: boolean,
  perPageItemsVars?: Array<number>,
  children: React.Node | Function,
  title?: string,
  className?: string,
  linkClassName?: string,
  onOpenClose?: (open: boolean) => void,
  t: (s: string) => string,
}

type State = {
  isOpen: boolean,
}

class DefaultSettings extends React.Component<Props, State> {
  static defaultProps = {
    showDefault: true,
  };

  state = {
    isOpen: false,
  };

  toggleDropdown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { onOpenClose } = this.props;
    const { isOpen: open } = this.state;
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
    if (onOpenClose && typeof onOpenClose === 'function') {
      onOpenClose(!open);
    }
  };

  collapse = () => {
    const { isOpen } = this.state;
    if (!isOpen) return;
    const { onOpenClose } = this.props;

    this.setState({
      isOpen: false,
    });
    if (onOpenClose && typeof onOpenClose === 'function') {
      onOpenClose(false);
    }
  };

  renderContent = () => {
    const {
      settings,
      saveSettings,
      perPageItemsVars,
      title,
      showDefault,
      children,
      t,
    } = this.props;
    const keys = Object.keys(settings);
    const controls = keys.map((key) => {
      if (key === 'rows') {
        return (
          <NumberSwitcher
            key={key}
            title={title || t('settings_dropdown_lines_number_label')}
            data={perPageItemsVars || config.perPageItemsVars}
            current={settings[key]}
            name="rows"
            handleChange={saveSettings}
          />
        );
      }
      return null;
    });

    return (
      <div className={styles.content}>
        {showDefault && controls}
        {children
          && typeof children === 'function'
          && children({ settings, saveSettings })}
      </div>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { className, linkClassName } = this.props;
    return (
      <ClickOutside className={cx(styles.main, className)} onClickOutside={this.collapse}>
        <a
          href="open"
          onClick={this.toggleDropdown}
          className={cx(styles.button, linkClassName)}
        >
          <IconGear className={cx({ icon: true, active: isOpen })} />
        </a>
        {isOpen && this.renderContent()}
      </ClickOutside>
    );
  }
}

export default withTranslation('ui')(DefaultSettings);
