import { map, pipe, toPairs } from 'ramda'

import { StatementStatusItem, StatementStatusMap } from 'app/dataTypes/compensation/statement/status'

import type { Request } from '../../../../index'
import { knownStatus } from './_common'


type ApiResult = Record<string, string>

export const convertResult = (res: ApiResult): StatementStatusMap => {
  return new Map(pipe(
    toPairs<ApiResult, string>,
    map<[string, string], [string, StatementStatusItem]>(
      ([status, title]) => {
        const ID = knownStatus(status)
        return ([ID, { ID, title }])
      },
    ),
  )(res))
}

export const GET_COMPENSATION_WITHDRAW_ORDERS_STATUSES: Request<void, StatementStatusMap, void> = [
  'food/compensation/withdraw_orders/statuses',
  'get',
  ['data', 'statuses'],
]
