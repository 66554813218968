import cn from 'classnames'

import SupportContactFormTemplate from './SupportContactFormTemplate'
import styles from './SupportContactFormHeader.scss'


type Props = {
  className?: string
}

const SupportContactFormHeader = ({ className }: Props) => {
  return (
    <SupportContactFormTemplate className={cn(styles.root, className)}>
      <div>{'Позиция'}</div>
      <div>{'Вид'}</div>
      <div>{'Контакты'}</div>
      <div />
      <div />
    </SupportContactFormTemplate>
  )
}

export default SupportContactFormHeader
