// @flow
import React, { type Node } from 'react';
import cn from 'classnames';

import ItemTemplate from './item-template';
import { NumberStatic } from './number-input';
import LegendPointer from './legend-pointer';
import styles from './legend.scss';


type Props = {
  className?: string,
}

const Legend = ({ className }: Props): Node => {
  return (
    <div className={cn(styles.root, className)}>
      <ItemTemplate
        goods={10}
        bottomText="22"
        doubleWidth
      >
        <NumberStatic value={10} />
      </ItemTemplate>
      <div className={styles.pointers}>
        <LegendPointer>
          {'Число на остатке'}
        </LegendPointer>
        <LegendPointer>
          {'Вмещаемость ячейки'}
        </LegendPointer>
        <LegendPointer>
          {'Номер ячейки'}
        </LegendPointer>
      </div>
    </div>
  );
};

export default Legend;
