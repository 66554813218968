import { isEmpty } from 'ramda'

import Sticky from 'app/common/templates-next/sticky'
import { Table } from 'app/common/ui-components/table'
import { TransactionList } from 'app/dataTypes/compensation/transations'
import { SkeletonLoadingTBody, StatusMessage } from 'app/common/templates-next/page-template'

import { TableHeader } from './TableHeader'
import { useColumnProps, useColumnTitles, useColumnsArranged, useHeaderColumnProps } from './columns'
import { TableRow } from './TableRow'


type Props = {
  currency: string
  transactions: TransactionList
  loading?: boolean
}

export const TransactionsTable = ({
  currency,
  transactions,
  loading,
}: Props) => {
  const nothing = isEmpty(transactions)
  const columns = useColumnsArranged()
  const titles = useColumnTitles(currency)
  const headerColumnProps = useHeaderColumnProps()
  const columnProps = useColumnProps()

  return (
    <Table wide>
      <thead>
        <Sticky applyToChildrenTh>
          {(ref, stuck, shadow) => (
            <TableHeader
              ref={ref}
              columns={columns}
              titles={titles}
              stuck={stuck}
              shadow={shadow}
              columnProps={headerColumnProps}
            />
          )}
        </Sticky>
      </thead>

      <tbody>
        {nothing
          && loading
          && <SkeletonLoadingTBody
            rows={5}
            columns={columns.length}
            onlyRows
          />}

        {nothing
          && !loading
          && <StatusMessage asTableRow colSpan={columns.length}>
            {'Нет записей, удовлетворяющих условиям'}
          </StatusMessage>}

        {!nothing
          && transactions.map(transaction => (
            <TableRow
              key={transaction.ID}
              columns={columns}
              columnProps={columnProps}
              transaction={transaction}
            />
          ))}
      </tbody>
    </Table>
  )
}
