// @flow
import { useMemo } from 'react';

import { useCurrencySign } from 'app/common/components-next/currency';


const TITLE: 'title' = 'title';
const COMPLEX: 'complex' = 'complex';
const PERSONAL: 'personal' = 'personal';
const TOTAL: 'total' = 'total';

export const ids = {
  TITLE,
  COMPLEX,
  PERSONAL,
  TOTAL,
};

export type ColumnProps = {
  id: $Values<typeof ids>,
  title: string,
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
  },
}

export function useColumns(): Array<ColumnProps> {
  const currencySign = useCurrencySign();

  const res: Array<ColumnProps> = useMemo(() => {
    const columns = [{
      id: TITLE,
      title: 'Заведение',
      props: {
        alignLeft: true,
      },
    }, {
      id: PERSONAL,
      title: `Личные продажи,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: COMPLEX,
      title: `Комплексное питание,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: TOTAL,
      title: `Итого,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }];

    return columns;
  }, [currencySign]);

  return res;
}
