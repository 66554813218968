// @flow
// @flow-runtime warn

export const balanceReservesStatuses = {
  wait: (0: 0),
  processed: (1: 1),
  deleted: (-1: 2 | -1),
};

export type BalanceReservesItem = {
  created_at: ?string,
  sum_from_balance: ?number,
  sum_from_subsidy: ?number,
  comment: ?string,
  canceled_at: ?string,
  status: ?$Values<typeof balanceReservesStatuses>,
}

export type BalanceReservesRequestResult = {
  count: number,
  [key: string]: BalanceReservesItem
}

export type BalanceReservesRequestParams = {
  customer_id: number,
  status?: $Values<typeof balanceReservesStatuses>,
  from_date?: string,
  to_date?: string,
  offset?: number,
  limit?: number,
}
