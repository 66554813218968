// @flow
import isEmpty from 'ramda/es/isEmpty';

import { getStateCurrentValue, type State } from 'app/common/ui-components/filter';

import detailsFilter from 'app/components/common/reports/filters/details';


const showDataActivity = (state: State) => {
  const detailsIds = getStateCurrentValue(detailsFilter, state);

  return (
    !isEmpty(detailsIds)
  );
};

export default showDataActivity;
