import { ReactNode, useMemo } from 'react'

import PlainAccordion from 'app/common/ui-next/plain-accordion'
import { getColorFromTheme, Theme } from 'app/common/components-next/color-theme'

import AccordionItemHeaderActive from './AccordionItemHeaderActive'
import AccordionItemHeader from './AccordionItemHeader'

import styles from './CategoriesAccordion.scss'


type Item = { ID: string, title: string, deletable: boolean }
const getHeaderClassName = ({ active }) => (active ? undefined : styles.notActiveHeader)

type Props = {
  items: Array<Item>
  locked?: boolean
  deleteCategory: (ID: string) => Promise<boolean>
  panelContent: (props: {
    ID: string,
    active: boolean,
    updateSize: () => void,
    closeActivePanel: () => void
  }) => ReactNode
  onCreateFormID: (ID: string) => string
}

const CategoriesAccordion = ({ items, locked, deleteCategory, panelContent, onCreateFormID }: Props) => {
  const primaryColor = getColorFromTheme(Theme.primaryColor, '#ffb400')
  const linkColor = getColorFromTheme(Theme.linkColor, '#e6a200')

  const itemsByID = useMemo(() => {
    return new Map(items.map(item => [item.ID, item]))
  }, [items])

  return (
    <PlainAccordion
      className={styles.root}
      items={items}
      fancyColor={primaryColor}
      fancyColorTitle={linkColor}
      locked={locked}
      getHeaderClassName={getHeaderClassName}
      headerContent={({ ID, active, hover, closeActivePanel }) => (
        active
          ? <AccordionItemHeaderActive
            ID={ID}
            locked={locked}
            onCreateFormID={onCreateFormID}
            onCancel={closeActivePanel}
          />
          : <AccordionItemHeader
            hover={hover}
            item={itemsByID.get(ID)}
            locked={locked}
            deleteCategory={deleteCategory}
          />
      )}
      panelContent={panelContent}
    />
  )
}

export default CategoriesAccordion
