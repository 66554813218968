// @flow
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import type { HOC } from 'recompose';


type Props = {
  currentPage?: number,
  setCurrentPage?: (number) => void,
};

const withPaging = (currentPagePropName?: string, setCurrentPagePropName?: string): HOC<*, Props> => compose(
  (currentPagePropName && setCurrentPagePropName
    ? mapProps(props => ({
      ...props,
      currentPage: props[currentPagePropName],
      setCurrentPage: props[setCurrentPagePropName],
    }))
    : withState('currentPage', 'setCurrentPage', 1)
  ),

  withHandlers({
    handlePage: ({ setCurrentPage }) => (e) => {
      e.preventDefault();
      const page = parseInt(e.currentTarget.name, 10);
      setCurrentPage(page);
    },
  }),
);

export default withPaging;
