// @flow
import React, { Fragment, type Node } from 'react';

import { Table, useCopyColumnsWidths } from 'app/common/ui-components/table';
import CSSSticky from 'app/common/ui-components/css-sticky';

import type { Payment } from '../useReportData';
import HeaderRow from './HeaderRow';
import { useColumns } from './columns';
import Row from './Row';

import styles from './DetailsSolo.scss';


type Props = {
  payments: Array<Payment>
}

const DetailsSolo = ({ payments }: Props): Node => {
  const columns = useColumns();

  const [refFrom, refTo] = useCopyColumnsWidths();

  return (
    <Fragment>
      <CSSSticky
        number={3}
        className={styles.stickableHeader}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <HeaderRow
              columns={columns}
              reference={refTo}
            />
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <thead>
          <HeaderRow
            columns={columns}
            reference={refFrom}
            hidden
            dummy
          />
        </thead>
        <tbody>
          {payments.map((actualPayment) => {
            const { id } = actualPayment;

            return (
              <Row
                key={id}
                columns={columns}
                actualPayment={actualPayment}
              />
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default DetailsSolo;
