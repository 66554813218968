import { forwardRef } from 'react'
import cn from 'classnames/bind'

import PlainNav, { NavItem } from 'app/common/ui/PlainNav'
import { rootRights, specificRights } from 'app/rights'
import { WithRights } from 'app/rights/Rights'

import styles from './Navigation.scss'


const cx = cn.bind(styles)

const dishesRights = [rootRights.DISHES, specificRights.PROVIDER_DISHES] as const
const employeesRights = [rootRights.USERS, specificRights.SCHOOL_EMPLOYEES] as const
const requisitesRights = [rootRights.SETTINGS, specificRights.REQUISITES] as const
const subsidiesRights = [rootRights.SETTINGS, specificRights.SUBSIDIES] as const
const adminRights = [rootRights.SU, specificRights.SU] as const
const additionalRights = [rootRights.SETTINGS, specificRights.ADDITIONAL] as const

type Props = {
  stuck?: boolean
}

const Navigation = forwardRef<HTMLDivElement, Props>(({ stuck }: Props, ref) => {
  return (
    <div className={cx(styles.root, { stuck })} ref={ref}>
      <PlainNav>
        <WithRights pathTo={dishesRights} readAffect="hide" editAffect="ignore">
          <NavItem to="/settings/dishes">{'Продукция'}</NavItem>
        </WithRights>

        <WithRights pathTo={employeesRights} readAffect="hide" editAffect="ignore">
          <NavItem to="/settings/employees">{'Сотрудники'}</NavItem>
        </WithRights>

        <WithRights pathTo={requisitesRights} readAffect="hide" editAffect="ignore">
          <NavItem to="/settings/requisites">{'Реквизиты'}</NavItem>
        </WithRights>

        <WithRights pathTo={subsidiesRights} readAffect="hide" editAffect="ignore">
          <NavItem to="/settings/subsidies">{'Дотации'}</NavItem>
        </WithRights>

        <WithRights pathTo={adminRights} readOnlyHidden readAffect="hide" editAffect="ignore">
          <NavItem to="/settings/admin">{'Администрирование'}</NavItem>
        </WithRights>

        <WithRights pathTo={additionalRights} readAffect="hide" editAffect="ignore">
          <NavItem to="/settings/additional">{'Дополнительно'}</NavItem>
        </WithRights>
      </PlainNav>
    </div>
  )
})

export default Navigation
