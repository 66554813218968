// @flow
import { type State } from 'app/common/ui-components/filter';
import { flags as currentStateDate } from 'app/components/common/reports/filters/currentStateDate';
import { yearActivity as yearActivityDefault } from 'app/components/common/reports/filters/year';


const yearActivity = (state: State) => {
  return (
    yearActivityDefault(state)
    && !currentStateDate.currentStateRadioSelected(state)
  );
};

export default yearActivity;
