// @flow
import React from 'react';
import classNames from 'classnames/bind';

import nope from 'app/common/lib/nope';
import styles from './Button.scss';


const cx = classNames.bind(styles);
type Props = {
  children: any,
  type: 'submit' | 'reset' | 'button',
  className?: string,
  disabled?: boolean,
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
};

const Button = ({ children, type, className, disabled, onClick }: Props) => (<button
  type={type}
  className={cx(styles.root, className, { disabled })}
  onClick={disabled ? nope : onClick}
>
  {children}
</button>);

export default Button;
