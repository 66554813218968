// @flow
import React from 'react';

import sections, { availableSections } from 'app/components/OrgsList/OrgsSettings/sections';
import { OrgSettingsHistoryProvider } from 'app/dataProviders/OrgSettingsHistoryProvider';
import * as orgsTypes from 'app/common/constants/schools/types';

import General from './General';
import Limits from './Limits';
import SellPoints from './SellPoints';
import Requests from './Requests';
import Subsidies from './Subsidies';
import Cafeteria from './Cafeteria';
import HistoryFilterProvider from './HistoryLink/filters/HistoryFilterProvider';


type Props = {
  type?: string,
  orgId: number,
  orgType: $Values<typeof orgsTypes>,
  option?: string,
  basePath: string,
}

const ContentView = ({ type, orgId, orgType, option, basePath }: Props) => {
  // $FlowFixMe
  const section: $Values<typeof sections> | void = availableSections.includes(type) ? type : undefined;

  return (
    <HistoryFilterProvider orgId={orgId} section={section}>
      {filterParams => (
        <OrgSettingsHistoryProvider {...filterParams}>
          {type === sections.ORGS_SETTINGS_GENERAL
            && <General orgId={String(orgId)} orgType={orgType} />}
          {type === sections.ORGS_SETTINGS_LIMITS
            && <Limits orgId={orgId} />}
          {type === sections.ORGS_SETTINGS_SELLPOINTS
            && <SellPoints orgId={orgId} option={option} basePath={basePath} />}
          {type === sections.ORGS_SETTINGS_SUBSIDIES
            && <Subsidies orgId={orgId} />}
          {type === sections.ORGS_SETTINGS_REQUESTS
            && <Requests orgId={orgId} orgType={orgType} />}
          {type === sections.ORGS_SETTINGS_CAFETERIA
            && <Cafeteria orgId={orgId} />}
        </OrgSettingsHistoryProvider>
      )}
    </HistoryFilterProvider>
  );
};

export default ContentView;
