// @flow
import React, { type Ref, type ComponentType } from 'react';

import withElHeightArray from './withElHeightArray';
import StickyContext from './StickyContext';


type Props = {
  elHeights: Array<number>,
  ro: (el?: Ref<any>) => void,
  onStickyToggle: (fixed?: boolean) => void,
  itemsSticky: Array<boolean>,
}

const withStickyRows = (Component: ComponentType<any>) => {
  const Wrapper = ({ elHeights, ro, onStickyToggle, itemsSticky, ...props }: Props) => (
    <StickyContext.Provider
      value={{
        elHeights,
        ro,
        onStickyToggle,
        itemsSticky,
      }}
    >
      <Component {...props} />
    </StickyContext.Provider>
  );

  return withElHeightArray(Wrapper);
};

export default withStickyRows;
