import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { ItemType } from 'app/common/ui-next/plain-selector'


export type CustomerType = Array<ItemType>

class CustomerTypeFilterDataBox extends DataBox<CustomerType> { }

export const defaultCustomerType: CustomerType = []
export const customerTypeFilter = createDataBox('customerTypeFilter', defaultCustomerType, CustomerTypeFilterDataBox)


export const defaultCustomerTypeData = () => {
  return [{ id: '0', title: 'Учащиеся' }]
}


export const defaultCustomerTypeSelection: Array<string> = ['0']
export const customerTypeFilterSelection = createDataBoxDefault<Array<string>>('customerTypeFilterSelection', defaultCustomerTypeSelection)
