// @flow
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { employeeformChangeField } from 'app/actions/employees/employee/employeeform';


type Options = {
  beforeChange: () => void,
}

export default function useFieldsChange({ beforeChange }: Options): [
  (e: SyntheticEvent<HTMLInputElement>, clearedValue?: string) => void,
  (newDate: string, id?: string) => void,
] {
  const dispatch = useDispatch();

  const handleChangeField = useCallback((e: SyntheticEvent<HTMLInputElement>, clearedValue?: string) => {
    const { name, value } = e.currentTarget;
    beforeChange();
    dispatch(employeeformChangeField({
      [name]:
        name === 'phone' ? clearedValue : value,
    }));
  }, [dispatch, beforeChange]);


  const handleChangeDateField = useCallback((newDate: string, id?: string) => {
    if (id) {
      beforeChange();
      dispatch(employeeformChangeField({ [id]: newDate }));
    }
  }, [dispatch, beforeChange]);

  return [handleChangeField, handleChangeDateField];
}
