import React from 'react';
import classNames from 'classnames';

import Checkbox from './Checkbox';
import styles from './CheckboxGroup.scss';


type Props = {
  id?: string,
  children: any,
  label?: string,
  className?: string,
  classNameLabel?: string,
  style?: Object,
  itemComponent?: React.ReactElement,
};

type CheckProps = {
  value?: string,
  name?: string,
};

const CheckboxGroup = ({
  id,
  children,
  label,
  className,
  classNameLabel,
  style,
  itemComponent,
}: Props) => {
  const Check = itemComponent || Checkbox;
  return (
    <fieldset
      id={id}
      style={style}
      className={classNames(className, styles.fieldset)}
    >
      {label
        && <legend
          className={classNames(styles.legend, classNameLabel)}
        >
          {label}
        </legend>}
      {React.Children.map(children, ({ props }: CheckProps) => <Check {...props} key={props.value || props.name} className={styles.item} />)}
    </fieldset>
  );
};

export default CheckboxGroup;
