import {
  DISHES_ADD_TO_SELECTION,
  DISHES_REMOVE_FROM_SELECTION,

  DISHES_REMOVE_ITEMS_REQUEST,
  DISHES_REMOVE_ITEMS_SUCCESS,
  DISHES_REMOVE_ITEMS_FAILURE,

  DISHES_COPY_ITEMS_REQUEST,
  DISHES_COPY_ITEMS_SUCCESS,
  DISHES_COPY_ITEMS_FAILURE,

  DISHES_REPLACE_SELECTION_ARR,
  DISHES_REMOVE_FROM_SELECTION_ALL,
} from 'app/actions/dishes/selection';

import {
  DISHES_REQUEST,
} from 'app/actions/dishes/items';

const defaultState = {
  items: [],
  action: '',
};

const selection = (state = defaultState, action) => {
  switch (action.type) {
    case DISHES_REPLACE_SELECTION_ARR: {
      return { ...state, items: action.payload };
    }

    case DISHES_REMOVE_FROM_SELECTION_ALL: {
      return { ...state, items: [] };
    }

    case DISHES_ADD_TO_SELECTION: {
      return { ...state, items: [...state.items, action.payload] };
    }

    case DISHES_REMOVE_FROM_SELECTION: {
      return { ...state, items: state.items.filter(item => (item !== action.payload)) };
    }

    case DISHES_REMOVE_ITEMS_REQUEST: {
      return { ...state, action: 'delete' };
    }

    case DISHES_REMOVE_ITEMS_SUCCESS: {
      return { ...defaultState };
    }

    case DISHES_REMOVE_ITEMS_FAILURE: {
      return { ...state, action: '' };
    }


    case DISHES_COPY_ITEMS_REQUEST: {
      return { ...state, action: 'copy' };
    }

    case DISHES_COPY_ITEMS_SUCCESS: {
      return { ...defaultState };
    }

    case DISHES_COPY_ITEMS_FAILURE: {
      return { ...state, action: '' };
    }

    case DISHES_REQUEST: {
      return { ...defaultState };
    }

    default:
  }
  return state;
};

export default selection;
