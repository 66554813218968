// @flow

const getProcessMessages = (processId: ?(number | string)): [string, string] => {
  const details = processId ? ` №${processId}` : '';

  return [
    `Подождите,\nидет формирование отчета${details}`,
    `Ошибка\nпри формировании отчета${details}`,
  ];
};

export default getProcessMessages;
