// @flow

const maskW58 = (w58: string | null) => {
  if (typeof w58 === 'string') {
    return w58.replace(/^\S\S\s\S\S\s\S\S\s\S\S\s\S\S/, '** ** ** ** **');
  }
  return w58;
};

export default maskW58;
