export const ORDERS = 'Заявки' as const
export const SALES = 'Продажи' as const
export const STUDENTS = 'Учащиеся' as const
export const MY_EMPLOYEES = 'Мои сотрудники' as const
export const SCHOOL_EMPLOYEES = 'Сотрудники ОУ' as const
export const MENU_CATEGORIES = 'Категории меню' as const
export const REQUISITES = 'Реквизиты' as const
export const SCHOOL_SETTINGS = 'Настройки ОУ' as const
export const DATA = 'Данные' as const
export const NETWORK = 'Сеть' as const
export const DEVICES = 'Устройства' as const
export const SYSTEM = 'Система' as const
export const MENU = 'Меню' as const
export const DISH_CATEGORIES = 'Категории блюд' as const
export const SCHOOL_DISHES = 'Блюда школы' as const
export const PROVIDER_DISHES = 'Блюда поставщика' as const
export const COMPLEXES = 'Комплекты' as const
export const COMPLEXES_COMPOSITION = 'Состав комплектов' as const
export const CASHBOX = 'Касса' as const
export const SUBSIDIES = 'Дотации' as const
export const ADDITIONAL = 'Дополнительно' as const
export const SU = 'su' as const
