// @flow
import { pipe, map } from 'ramda';

import PlanogramItem, { type RawDataItem } from './planogram-item';
import { createPlanogramItem } from './create-planogram-item';
import { createGrid } from './create-grid';
import PlanogramData from './planogram-data';


export function createPlanogramData(items?: Array<RawDataItem> = []): PlanogramData<PlanogramItem> {
  const grid = pipe(
    map(createPlanogramItem),
    createGrid,
  )(items);

  return new PlanogramData<PlanogramItem>(grid);
}
