// @flow
import { useState, useCallback, useMemo } from 'react';
import complement from 'ramda/es/complement';
import filter from 'ramda/es/filter';
import propEq from 'ramda/es/propEq';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';

import { api } from 'app/api';
import { GET_ORGS } from 'app/common/api/requests/food/schools';


/**
 *  Организации использующиеся в списке мест размещения аппаратов
 */

type Item = {
  id: string,
  title: string,
}

type OrgsState = {
  [key: string]: Item,
}

const defaultState: OrgsState = {};

export type AvailableOrgs = [OrgsState, boolean, () => void | Promise<void>]
export const defaultAvailableOrgs: AvailableOrgs = [defaultState, true, () => {}];

export default function useAvailableOrgs(): AvailableOrgs {
  const [orgs, setOrgs] = useState<OrgsState>(defaultState);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_ORGS, {
      error: 'Не удалось получить образовательные учереждения',
      params: {
        type: 'school',
      },
    });

    if (res && res.schools) {
      const { schools } = res;

      setOrgs(pipe(
        filter(complement(propEq('deleted', true))),
        map(({ id, title }) => ({ id: String(id), title })),
      )(schools));
    }
    setLoading(false);
  }, []);

  const res: AvailableOrgs = useMemo(() => [orgs, loading, request], [orgs, loading, request]);
  return res;
}
