// @flow
import type { Dish, ParamsPUT } from 'app/dataTypes/vending/production/Dishes.types';
import { productionSaveType } from 'app/dataTypes/vending/production/productionSaveType';

import type { Request } from '../../../../index';


type ParamsApi = {
  id: number,
  field: string,
  new_value: string,
  item_types: Array<'place' | 'all'>,
}

type ResultApi = {
  result: 'success',
  id: number,
  title: string,
  category_id: number,
  food_item_id: number,
  place_id: number,
  cost: string,
  portion: string,
  photoUrl: string | null,
  saleType: string | null,
  deleted: boolean,
}

const productionSaveTypesConvert = (type: $Values<typeof productionSaveType>): 'place' | 'all' => {
  switch (type) {
    case productionSaveType.All:
      return 'all';
    case productionSaveType.Place:
      return 'place';
    default:
  }
  return 'place';
};

export const convertParams = (params: ParamsPUT): [ParamsApi, null] => {
  const { field, id, newValue, productionSaveTypes } = params;

  return [{
    id: parseInt(id, 10),
    field,
    new_value: newValue,
    item_types: productionSaveTypes.map(productionSaveTypesConvert),
  }, null];
};

export const convertResult = (data: ResultApi): Dish => {
  const {
    id,
    title,
    category_id: categoryId,
    food_item_id: foodItemId,
    place_id: placeId,
    cost,
    portion,
    photoUrl,
    saleType,
    deleted,
  } = data;

  return {
    id: String(id),
    title,
    categoryId: String(categoryId),
    foodItemId: String(foodItemId),
    placeId: String(placeId),
    cost: cost || '',
    portion: portion || '',
    photoURL: photoUrl || null,
    saleType: saleType || null,
    deleted: deleted || false,
  };
};

export const PUT_VENDING_ITEMS_FIELD: Request<ParamsPUT, Dish, void> = [
  'data/vending/items/field',
  'put',
  ['data'],
];
