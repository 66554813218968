import { useCallback, useMemo, ReactNode } from 'react'
import moment from 'moment-timezone'
import range from 'ramda/es/range'
import T from 'ramda/es/T'
import reduce from 'ramda/es/reduce'

import PlainModal, {
  PlainModalContent,
  PlainModalHeader,
  type HandleModalDisplayed,
} from '../plain-modal'

import PlainList from '../plain-list'
import MonthSelectorItem from './month-selector-item'
import styles from './month-selector.scss'


type Props = {
  monthDate: string,
  dateFormat: string,
  onMonth: (m: number) => void,
  onYear: (y: number) => void,
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean,
  children: (handleVisibility: HandleModalDisplayed) => ReactNode,
}

const MonthSelector = ({
  monthDate,
  dateFormat,
  onMonth,
  onYear,
  dateDisabledStatus,
  children,
}: Props) => {
  const months = moment.months()
  const [currentMonth, currentYear] = useMemo((): [number, number] => {
    const mDate = moment(monthDate, dateFormat)
    return [mDate.month(), mDate.year()]
  }, [monthDate, dateFormat])

  const years = useMemo(() => range(currentYear - 5, currentYear + 7), [currentYear])

  const disabledYears = useMemo(() => {
    return reduce((acc, val) => {
      acc[val] = (
        dateDisabledStatus
          ? dateDisabledStatus(moment({ year: val }).format(dateFormat), dateFormat, 'years')
          : false
      )
      return acc
    }, {}, years)
  }, [years, dateFormat, dateDisabledStatus])

  const disabledMonths = useMemo(() => {
    return reduce((acc, val) => {
      acc[val] = (
        dateDisabledStatus
          ? dateDisabledStatus(moment({ year: currentYear, month: val }).format(dateFormat), dateFormat, 'months')
          : false
      )
      return acc
    }, {}, range(0, 12))
  }, [currentYear, dateFormat, dateDisabledStatus])

  const handleYearClick = useCallback(({ id }) => {
    onYear(parseInt(id, 10))
  }, [onYear])

  const handleMonthClick = useCallback(({ id }) => {
    onMonth(parseInt(id, 10))
  }, [onMonth])


  return (
    <PlainModal
      formId="month-selector"
      controlBy={children}
      onSubmit={T}
      className="doNotCloseDropdown"
    >
      <PlainModalHeader>
        {`${months[currentMonth]} ${currentYear}`}
      </PlainModalHeader>

      <PlainModalContent className={styles.content}>

        <PlainList
          ariaLabel="Выбор месяца"
          onClick={handleMonthClick}
          id="months_list"
          noScroll
        >
          {months.map((monthName, i) => (
            <MonthSelectorItem
              key={monthName}
              id={String(i)}
              current={i === currentMonth}
              disabled={disabledMonths[i]}
            >
              {monthName}
            </MonthSelectorItem>
          ))}
        </PlainList>

        <PlainList
          ariaLabel="Выбор года"
          onClick={handleYearClick}
          id="years_list"
          noScroll
        >
          {years.map(year => (
            <MonthSelectorItem
              key={year}
              id={String(year)}
              current={year === currentYear}
              disabled={disabledYears[year]}
            >
              {String(year)}
            </MonthSelectorItem>
          ))}
        </PlainList>

      </PlainModalContent>
    </PlainModal>
  )
}

export default MonthSelector
