// @flow
import React, { useMemo, Fragment } from 'react';
import prop from 'ramda/es/prop';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { SummaryData } from './useReportData';


type Props = {
  summary: SummaryData | null,
  loading?: boolean,
  currencySign: string,
}

const Summary = ({ summary, loading, currencySign }: Props) => {
  const rows = useMemo(() => {
    const { complex, personal, total } = summary || {};

    const complexText = typeof complex === 'number' ? `${toFixedValue(complex)} ${currencySign}` : '';
    const personalText = typeof personal === 'number' ? `${toFixedValue(personal)} ${currencySign}` : '';
    const totalText = typeof total === 'number' ? `${toFixedValue(total)} ${currencySign}` : '';

    const row = [
      ['Списано за компл. питание', complexText],
      ['Списано за личные продажи', personalText],
      ['Итого списано', totalText],
    ];

    return [row.filter(prop(1))];
  }, [summary, currencySign]);

  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
              loading={loading || !summary}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default Summary;
