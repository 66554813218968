// @flow

const isProduction = () => {
  if (!process.env.REACT_APP_NAME) { // old
    return process.env.NODE_ENV === 'production';
  }
  return process.env.REACT_APP_TYPE === 'production';
};

export default isProduction;
