import { RefCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'

import { CompensationDetails } from 'app/dataTypes/compensation/details'
import { Table, Td, Th, Tr } from 'app/common/ui-components/table'
import toFixedValue from 'app/common/lib/toFixedValue'
import { StatusMessage, SkeletonLoadingTBody } from 'app/common/templates-next/page-template'


type Props = {
  orgID: string
  allowanceID: string
  compensationDetails: CompensationDetails
  currency: string
  trHeaderRef: RefCallback<'tr'>
  onRequest: (params: { orgID: string, allowanceID: string }) => Promise<void>
}

export const DetailsTable = ({
  orgID,
  allowanceID,
  compensationDetails,
  currency,
  trHeaderRef,
  onRequest,
}: Props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      setLoading(true)
      await onRequest({ orgID, allowanceID })
      setLoading(false)
    })()
  }, [onRequest, orgID, allowanceID])

  const { students } = compensationDetails

  const empty = !loading && isEmpty(students)

  return (
    <Table wide>
      <thead>
        <Tr header reference={trHeaderRef}>
          <Th className="index_0" alignLeft>{'Класс'}</Th>
          <Th className="index_1" alignLeft>{'ФИО'}</Th>
          <Th className="index_2" alignLeft>{'Кол-во дней'}</Th>
          <Th className="index_3" alignLeft>{`Сумма компенсации, ${currency}`}</Th>
        </Tr>
      </thead>

      {empty
        && <tbody>
          <StatusMessage colSpan={4} asTableRow>
            {'Нет данных'}
          </StatusMessage>
        </tbody>}

      {loading
        && <SkeletonLoadingTBody rows={5} columns={4} />}

      {!loading
        && <tbody>
          {students.map(({ ID, classTitle, name, days, sum }) => (
            <Tr key={ID}>
              <Td>{classTitle}</Td>
              <Td>{name.lastFM}</Td>
              <Td>{days}</Td>
              <Td>{toFixedValue(sum)}</Td>
            </Tr>
          ))}
        </tbody>}
    </Table>
  )
}
