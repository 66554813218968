// @flow
import { useEffect, useState } from 'react';
import { filter, reduce, map, path } from 'ramda';

import { GET_VENDING_ITEMS, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/get';
import { api } from 'app/api';
import { PlanogramData, PlanogramItem } from 'app/common/ui-components/planograms';


export type ActualSlotData = {
  slotId: string,
  slotName: string,
  amount: number,
  amountMax: number,
  productId: string,
  productTitle: string,
  productPhoto: string | null,
  deleted: boolean,
}

export default function useActualSlotsData(
  data: PlanogramData<PlanogramItem>,
  placeId: string,
): [Array<ActualSlotData>, boolean] {
  const [loading, setLoading] = useState(true);
  const [actualSlotsData, setActualSlotsData] = useState<Array<ActualSlotData>>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const dataList = data.asList();
      const namesList = data.asListNaming();
      const containsList = data.asListContains();

      let i = 0;
      const dataListAll = dataList.map((item) => {
        const { width } = item.raw;
        const obj = {
          ...item.raw,
          slotName: namesList[i],
          ...containsList[i],
        };
        i += width;
        return obj;
      });

      const dataListWithProductOnly: Array<{
        _destroy?: true,
        amount: number | null,
        column: number,
        height: number,
        id: string,
        maxGoods: number,
        slotName: string,
        nonTrustedSlotName: string,
        productId: string,
        row: number,
        width: number
        // $FlowFixMe
      }> = filter(({ productId }) => !!productId, dataListAll);

      const res = await api.request(GET_VENDING_ITEMS, {
        error: 'Не удалось получить продукцию из ячеек',
        params: {
          placeId,
          ids: dataListWithProductOnly.map(({ productId }) => productId),
        },
        convertions: {
          convertParams,
          convertResult,
        },
      });

      if (res && res.data) {
        const { data: products } = res;
        const productsById = reduce((acc, val) => {
          const { id } = val;
          acc[id] = val;
          return acc;
        }, {}, products);

        setActualSlotsData(map(({
          id,
          slotName,
          amount,
          maxGoods,
          productId,
        }) => ({
          slotId: id,
          slotName,
          amount: amount || 0,
          amountMax: maxGoods,
          productId,
          productTitle: path([productId, 'title'], productsById) || '???',
          productPhoto: path([productId, 'photoURL'], productsById) || null,
          deleted: !!path([productId, 'deleted'], productsById),
        }), dataListWithProductOnly));
      }
      setLoading(false);
    })();
  }, [placeId, data]);

  return [actualSlotsData, loading];
}
