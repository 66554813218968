// @flow
import React, { type Node, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import identity from 'ramda/es/identity';
import all from 'ramda/es/all';

import PlainModal, { PlainModalContent, PlainModalHeader, PlainModalFooter } from 'app/common/ui-next/plain-modal';
import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { copyAllDishes } from 'app/actions/orgs/dishes/copy';
import { popup } from 'app/common/actions/popup';
import { dishesNeedUpdate } from 'app/actions/orgs/dishes/options';
import OrgsDataProvider from 'app/dataProviders/org/OrgsDataProvider';
import * as orgsTypes from 'app/common/constants/schools/types';
import type { OrgItem } from 'app/common/api/requests/food/schools';
import OrgsAndGroupsSelector from 'app/components/common/OrgsAndGroupsSelector';

// import OrgsRadios from './OrgsRadios';
// import OrgsChecks from './OrgsChecks';
import styles from './CopyDishesModal.scss';


const allOk = all(identity);
const defaultSelection = [];

const orgsFilterCb = (item: OrgItem): boolean => {
  const { cafeteria_enabled: cafeteriaEnabled, type } = item;
  if (type === orgsTypes.ENTERPRISE) return cafeteriaEnabled;
  return true;
};

type Props = {
  children: (() => void) => Node,
  orgID: string,
  orgType: string,
}

const CopyDishesModal = ({ children, orgID, orgType }: Props) => {
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [from, setFrom] = useState<Array<string>>([]);
  const [to, setTo] = useState<Array<string>>([]);

  const handleSubmit = useCallback(async () => {
    const orgIdInt = parseInt(orgID, 10);

    if (activeTabIndex === 1 && from[0]) {
      setSaving(true);
      const res = await dispatch(copyAllDishes(parseInt(from, 10), orgIdInt));
      setSaving(false);

      if (res) {
        setFrom([]);
        dispatch(popup('Продукция успешно скопирована'));
        dispatch(dishesNeedUpdate(true));
        return true;
      }
    }

    if (activeTabIndex === 2 && to[0]) {
      setSaving(true);
      const res = await Promise.all(to.map(id => dispatch(copyAllDishes(orgIdInt, parseInt(id, 10)))));
      setSaving(false);

      if (allOk(res)) {
        dispatch(popup('Продукция успешно скопирована'));
        setTo([]);
        dispatch(dishesNeedUpdate(true));
        setActiveTabIndex(1);
        return true;
      }
    }

    return false;
  }, [orgID, activeTabIndex, dispatch, from, to]);

  const ready = activeTabIndex === 1 ? !!from[0] : !!to[0];

  const ignoreCurrent = useCallback(({ ID }: { ID: string }) => {
    return orgID !== ID;
  }, [orgID]);

  return (
    <OrgsDataProvider
      orgId={parseInt(orgID, 10)}
      filterCb={orgsFilterCb}
      excludeCurrent
      sameType
    >
      <PlainModal
        formId="copyAll"
        controlBy={children}
        onSubmit={handleSubmit}
        className={styles.modal}
      >
        <PlainModalHeader>
          {'Копирование списка продукции'}
        </PlainModalHeader>

        <PlainModalContent className={styles.tabs}>
          <Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
            <Tab index={1}>{'Из другого заведения'}</Tab>
            <Tab index={2}>{'В другое заведение'}</Tab>
          </Tabs>
        </PlainModalContent>

        <PlainModalContent
          containerClassName={styles.contentContainer}
        >
          <TabPanels
            value={activeTabIndex}
            className={styles.panels}
          >

            <TabPanel index={1}>
              <OrgsAndGroupsSelector
                orgType={orgType}
                label="Из заведения"
                header="Выберите откуда копировать продукцию"
                pleaseSelectText="Выберите заведение"
                disabled={saving}
                selectedAllOnEmptySelection={false}
                selection={from}
                defaultSelection={defaultSelection}
                onChange={setFrom}
                filterFunc={ignoreCurrent}
              />

              {/* <OrgsRadios
                ariaLabelledby={getTabIdByNumber(1)}
                disabled={saving}
                currentId={from}
                onChange={setFrom}
                onOrgs={setFrom}
              /> */}
            </TabPanel>

            <TabPanel index={2}>
              <OrgsAndGroupsSelector
                orgType={orgType}
                label="В заведения"
                header="Выберите куда копировать продукцию"
                pleaseSelectText="Выберите заведение"
                disabled={saving}
                multiple
                selectedAllOnEmptySelection={false}
                selection={to}
                defaultSelection={defaultSelection}
                onChange={setTo}
                filterFunc={ignoreCurrent}
              />

              {/* <OrgsChecks
                ariaLabelledby={getTabIdByNumber(2)}
                disabled={saving}
                current={to}
                onChange={handleChangeTo}
              /> */}
            </TabPanel>

          </TabPanels>
          <button type="submit" className={styles.hidden}>
            {'Копировать'}
          </button>
        </PlainModalContent>

        <PlainModalFooter className={styles.footer}>
          {handleModalDisplayed => (
            <ControlsBar right>
              <PlainButton outline onClick={handleModalDisplayed}>
                {'Отмена'}
              </PlainButton>
              <PlainButton type="submit" disabled={saving || !ready}>
                {saving ? 'Копирование...' : 'Копировать'}
              </PlainButton>
            </ControlsBar>
          )}
        </PlainModalFooter>

      </PlainModal>
    </OrgsDataProvider>
  );
};

export default CopyDishesModal;
