// @flow
import { values, isEmpty, map } from 'ramda';

import { defaultStateDataCurrent, getStateCurrentValue, type State } from 'app/common/ui-components/filter';
import { GET_CLASSES_LIST, convertParams, convertResult } from 'app/common/api/requests/reports/klasses_list/get';
import { api } from 'app/api';

import orgFilter from '../org';
import yearFilter, { yearItems } from '../year';


const getDefaultYear = async (state: State): Promise<string> => {
  const [, currentYearState] = await yearItems(state);
  return currentYearState[0];
};

type OldClassesListType = {
  id: number,
  title: string,
}

const convertResultToOldType = (data: ClassesListType): OldClassesListType => {
  return map(({
    ID,
    title,
  }) => {
    return ({
      id: parseInt(ID, 10),
      title,
    })
  }, values(data))
}

const classItems = async (state: State) => {
  const orgIds = getStateCurrentValue(orgFilter, state);
  const orgId = parseInt(orgIds[0], 10);

  const yearIds = getStateCurrentValue(yearFilter, state);

  const yearId = yearIds[0] || await getDefaultYear(state);
  const res = await api.request(GET_CLASSES_LIST, {
    error: 'Не удалось получить данные классов',
    params: {
      schoolId: orgId,
      schoolYearId: parseInt(yearId, 10),
    },
    convertions: {
      convertParams,
      convertResult,
    },
  });

  if (res) {
    const result = convertResultToOldType(res)
    return ([result, defaultStateDataCurrent]);
  }

  return [[], defaultStateDataCurrent];
};

export default classItems;
