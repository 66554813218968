// @flow
import React, { type Ref } from 'react';

import { Tr, Td, getPropsHeader } from 'app/common/ui-components/table';

import type { ColumnProps } from './DetailsBudget/columns';


type Props = {
  reference?: Ref<'tr'>,
  columns: Array<ColumnProps>,
  columnDataById: (id: string) => string | Array<string>,
}

const Row = ({ reference, columns, columnDataById }: Props) => {
  const headerLinkProps = getPropsHeader();

  return (
    <Tr reference={reference}>
      {columns.map(({ id, props, cellComponent: Cell }) => (
        <Td
          key={id}
          {...headerLinkProps(id)}
          {...props}
        >
          <Cell>
            {columnDataById(id)}
          </Cell>
        </Td>
      ))}
    </Tr>
  );
};

export default Row;
