// @flow
import React from 'react';

import { checkEmployeeStatuses } from 'app/common/api/requests/food/check_employee_exists';


type Props = {
  id?: number,
  status: $Values<typeof checkEmployeeStatuses>,
}


const EmailExist = ({ status }: Props) => {
  if (status) {
    return (
      <span>
        {'Этот email уже используется другим пользователем'}
      </span>
    );
  }
  return null;
};

export default EmailExist;
