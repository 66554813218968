import { ROLES_SUCCESS } from 'app/actions/employees/roles';

const defaultState = {};

const roles = (state = defaultState, action) => {
  if (action.type === ROLES_SUCCESS) {
    return action.payload;
  }
  return state;
};

export default roles;
