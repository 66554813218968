// @flow
import { useCallback, useState } from 'react';
import { map, prop } from 'ramda';

import { PUT_VENDING_ITEMS_CATEGORIES, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/categories/put';
import { api } from 'app/api';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';

import type { Item } from '../../../_externalProduction/useAddRemoveState';


export default function useSubmit(
  categoryId: string,
  willAddItems: Array<Item>,
  willRemoveItems: Array<Item>,
  onSuccess: (category: Category) => void,
): [() => Promise<boolean>, boolean] {
  const [submitting, setSubmitting] = useState(false);

  const categoryChange = useCallback(async () => {
    setSubmitting(true);
    const res = await api.request(PUT_VENDING_ITEMS_CATEGORIES, {
      error: 'Не удалось добавить/удалить продукцию из категории',
      params: {
        categoryId,
        addFoodItems: map(prop('id'), willAddItems),
        removeFoodItems: map(prop('id'), willRemoveItems),
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      setSubmitting(false);
      onSuccess(res);
      return true;
    }
    setSubmitting(false);
    return false;
  }, [categoryId, willAddItems, willRemoveItems, onSuccess]);

  return [categoryChange, submitting];
}
