// @flow
import React, { Component, type ElementRef } from 'react';
import classNames from 'classnames/bind';
import prop from 'ramda/es/prop';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';

import { DotsLoader } from 'app/common/components/Loader';
import Tooltip from 'app/common/ui/Tooltip';
import ErrorString from 'app/common/ui/ErrorString';
import IconCross from 'app/common/icons/IconCross';

import PlainList from './PlainList';
import ClickOutside from '../ClickOutside';
import ViewTitleAsArray from './PlainList/ViewTitleAsArray';
import styles from './PlainSelect.scss';


export type PlainSelectData = {
  id: number | string,
  title: string,
};

const cx = classNames.bind(styles);

export type UniProps = {
  target: { name?: string, value: string | number, id?: string },
  currentTarget: { name?: string, value: string | number, id?: string },
  preventDefault: (void) => void,
}

export type OnChangeFunc = (p: UniProps) => void

type Item = {
  id: number | string,
  title: string,
}

type Props = {
  id?: string,
  label?: string,
  onChange?: OnChangeFunc,
  isError?: boolean,
  isValid?: boolean,
  errorText?: string,
  style?: Object,
  labelStyle?: Object,
  placeholder?: string,
  required?: boolean,
  data?: Array<Item>,
  current: ?(string | number),
  disabled?: boolean,
  name?: string,
  className?: string,
  labelClassName?: string,
  outsideClassName?: string,
  autocomplete?: boolean,
  loading?: boolean,
  emptySelectable?: boolean,
  // унификация всех onChange для разных элементов
  // onChange({ target: { name: string, value: string | number } })
  // всегда использовать, без uni устаревший вариант
  uni?: boolean,
  hideErrorString?: boolean,
  onClear?: (e: SyntheticEvent<HTMLElement>) => void,
};

type State = {
  open: boolean,
  openDirection: string,
}

const defaultProps = {
  label: '',
};

class PlainSelect extends Component<Props, State> {
  static defaultProps = defaultProps;

  constructor() {
    super();
    this.state = {
      open: false,
      openDirection: 'bottom',
    };
    this.updateSizes = this.updateSizes.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.refList = this.refList.bind(this);
    this.refInput = this.refInput.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleCollapse);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { open } = this.state;
    if (open !== prevState.open && open) {
      this.updateSizes(true);
      setTimeout(this.updateSizes, 10);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleCollapse);
  }

  updateSizes(hidden?: boolean) {
    const { input, list } = this;
    if (!input || !list) return;

    const clientInputRect = input.getBoundingClientRect();
    const clientListRect = list.getBoundingClientRect();

    // отступ с каждой стороны на сколько выпадающий список шире оригинального поля
    const dx = 0;
    // смещение вверх от оригинального поля
    const dy = 0;

    const {
      left: inputLeft,
      top: inputTop,
      bottom: inputBottom,
      right: inputRight,
    } = clientInputRect;

    const {
      // left: listLeft,
      top: listTop,
      // right: listRight,
      bottom: listBottom,
    } = clientListRect;

    const rootWidth = inputRight - inputLeft;
    // const listWidth = listRight - listLeft;
    const listHeight = listBottom - listTop;

    list.style.left = `${inputLeft - dx}px`;
    list.style.width = `${rootWidth + (2 * dx)}px`;
    if (hidden) {
      list.style.opacity = '0';
    } else {
      list.style.opacity = '1';
    }
    if (!document.documentElement) return;
    if (inputTop + listHeight < document.documentElement.clientHeight) {
      list.style.top = `${inputBottom - dy - 1}px`;
      this.setState({ openDirection: 'bottom' });
    } else {
      // не умещается, выпадение вверх
      list.style.top = `${inputTop - listHeight + dy + 1}px`; // eslint-disable-line no-mixed-operators
      this.setState({ openDirection: 'top' });
    }
  }

  refList(ref: ElementRef<'div'> | null) {
    this.list = ref;
  }

  refInput(ref: ElementRef<'input'> | null) {
    this.input = ref;
  }

  handleChange(value: number | string) {
    const {
      uni,
      onChange,
      disabled,
      loading,
      name,
      id,
    } = this.props;
    this.handleCollapse();
    if (typeof onChange === 'function' && !disabled && !loading) {
      if (uni) {
        onChange({
          target: { name: name || id, value, id },
          currentTarget: { name: name || id, value, id },
          preventDefault: () => {},
        });
      } else { // depricated
        // $FlowFixMe
        onChange(value);
      }
    }
  }

  handleCollapse() {
    this.setState({ open: false });
  }

  handleOpen() {
    const { disabled, loading } = this.props;
    if (disabled || loading) return;
    this.setState({ open: true });
  }

  handleToggle(e: SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    const { disabled, loading } = this.props;
    if (disabled || loading) return;
    this.setState(({ open }) => ({ open: !open }));
  }

  updateSizes: (hidden?: boolean) => void // eslint-disable-line

  list: ElementRef<'div'> | null;

  input: ElementRef<'input'> | null;

  render() {
    const {
      id,
      name,
      label,
      style,
      isError,
      isValid,
      errorText,
      labelStyle,
      placeholder,
      required,
      current,
      data = [],
      disabled,
      loading,
      className,
      labelClassName,
      outsideClassName,
      autocomplete,
      emptySelectable,
      hideErrorString,
      onClear,
    } = this.props;
    const emptyTitle = placeholder || 'Не указано';
    const { open, openDirection } = this.state;
    const readyData = emptySelectable ? [{ id: '', title: 'Не указано' }, ...data] : data;
    const curr = find(propEq('id', current), data);
    const title = prop('title', curr) || emptyTitle;
    const isTitleArray = title instanceof Array;
    const isClearIcon = typeof onClear === 'function';

    return (
      <ClickOutside
        onClickOutside={this.handleCollapse}
        className={cx(styles.clickOutside, outsideClassName)}
      >
        {label
          && <label htmlFor={id} className={cx(styles.label, labelClassName)} style={labelStyle}>
            {label}
            {required && <span className={styles.star}>{' *'}</span>}
          </label>}
        <Tooltip text={disabled ? title : ''}>
          <div style={style} className={className}>
            <div className={styles.dropArea}>
              <input
                className={cx({
                  input: true,
                  error: isError,
                  success: isValid,
                  disabled: disabled || loading,
                  active: open,
                  topOpened: open && openDirection === 'top',
                  bottomOpened: open && openDirection === 'bottom',
                  empty: !curr, // title === emptyTitle
                  withClear: isClearIcon,
                })}
                name={name}
                ref={this.refInput}
                id={id}
                value={isTitleArray ? '' : title}
                style={style}
                placeholder={placeholder}
                onFocus={this.handleOpen}
                autoComplete={autocomplete || 'off'}
                readOnly
              />
              {isTitleArray
                && <ViewTitleAsArray
                  title={title}
                  className={cx(styles.asArray, { withClear: isClearIcon })}
                  onClick={this.handleToggle}
                />}
              {isClearIcon
                && <a href="clear" onClick={onClear} name={name} id={id}>
                  <IconCross className={styles.iconClear} />
                </a>}
              {open
                && <PlainList
                  reference={this.refList}
                  data={readyData}
                  onChange={this.handleChange}
                  current={current}
                  roundCorners={openDirection}
                />}
              {loading && <DotsLoader className={styles.loader} />}
              {!disabled && !loading
                && <div // eslint-disable-line jsx-a11y/click-events-have-key-events
                  className={cx({ arrow: true, active: open })}
                  onClick={this.handleToggle}
                />}
            </div>
            {!hideErrorString
              && <ErrorString>{errorText}</ErrorString>}
          </div>
        </Tooltip>
      </ClickOutside>
    );
  }
}

export default PlainSelect;
