import { path, reduce } from 'ramda'

import { Rights } from 'app/common/constants/rights'


const combine = (val1: boolean, val2: boolean, type: 'any' | 'all') => (
  type === 'any' ? val1 || val2 : val1 && val2
)

type Options = {
  paths: Array<ReadonlyArray<string>>
  rights: Rights | {}
  combineWithOthersPaths: 'any' | 'all'
}

export default function extractRightsByPaths({
  paths,
  rights,
  combineWithOthersPaths = 'all',
}: Options) {
  const { edit = false, read = false } = reduce((acc: { edit?: boolean, read?: boolean }, val) => {
    const currentPath = val
    const { edit: currentEdit, read: currentRead } = acc
    const { edit: nextEdit, read: nextRead } = (
      path<{ edit: boolean, read: boolean }>(currentPath, rights)
      || { edit: false, read: false }
    )
    if (currentEdit === undefined || currentRead === undefined) {
      return { edit: nextEdit, read: nextRead }
    }
    return {
      edit: combine(currentEdit, nextEdit, combineWithOthersPaths),
      read: combine(currentRead, nextRead, combineWithOthersPaths),
    }
  }, { edit: undefined, read: undefined }, paths)

  return { edit, read }
}
