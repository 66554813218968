// @flow
import axios, { CancelToken } from 'axios';
import clone from 'ramda/es/clone';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import isEmpty from 'ramda/es/isEmpty';
import forEachObjIndexed from 'ramda/es/forEachObjIndexed';

import isProduction from 'app/common/lib/isProduction';

// import store from 'app/store';
import { getToken } from '../lib/token';
import shopUserToken from '../lib/shopUserToken';
import action from '../lib/action';

import * as appTypes from './appTypes';
import * as apiTypes from './apiTypes';
import * as apiURLs from './apiURLs';
import requestsShop from './requestsShop';
import requestsGeneral from './requestsGeneral';
import ApiString from './ApiString';
import type { FilesType } from './flowTypes';
import type { Request, RequestMethod } from './requests';


type ApiType = $Values<typeof apiTypes>

// const { dispatch /* , getState */ } = store;
// console.log('dispatch', dispatch);

const urlBeginsWithHttp = url => !url.search('http');

const dummy: (a: any) => void = () => { };

export type ErrorType = string | (err: Error) => string

type Convertions<Params, Result> = {
  convertParams?: (params: Params) => [any, any],
  convertResult?: (data: any, params: Params) => Result,
}

class Api {
  apiType: ApiType = apiTypes.API_GENERAL;

  timeout: number = 30000;

  apiString: ApiString = new ApiString(apiURLs.generalApi);

  headers = {};

  requests = requestsGeneral; // не нужно

  store = null;

  cancel = null;

  constructor(apiType?: ApiType, timeout?: number | null, store?: Object | null) {
    if (store) {
      this.store = store;
    }
    if (timeout) {
      this.timeout = timeout;
    }
    if (apiType && apiType === apiTypes.API_SHOP) {
      this.apiType = apiType;
      this.requests = requestsShop;
      this.apiString = new ApiString(apiURLs.shopApi);
    }
    if (apiType && apiType === apiTypes.API_WS) {
      this.apiType = apiType;
      // TODO this.requests =
      this.apiString = new ApiString(apiURLs.wsApi);
    }
    if (apiType && apiType === apiTypes.API_ELJUR) {
      this.apiType = apiType;
      // TODO this.requests =
      this.apiString = new ApiString(apiURLs.eljurApi);
    }

    if ((process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_CABINET) {
      if (apiType === apiTypes.API_GENERAL) {
        this.apiString.addReplacer('new-lk2.sms-dnevnik.com', 'https://api2.sms-dnevnik.com/rest/');
        this.apiString.addReplacer('new-lk.sms-dnevnik.com', 'https://api0.sms-dnevnik.com/rest/');
        this.apiString.addReplacer('school.admgornnov.ru', 'https://apiskud.admgornnov.ru/rest/');
        this.apiString.addReplacer('new-cabinet.kengudetyam.ru', 'https://api5.kengudetyam.ru/rest/');
        this.apiString.addReplacer('new-cabinet.karta51.ru', 'https://api2.karta51.ru/rest/');
      }
      if (apiType === apiTypes.API_ELJUR) {
        this.apiString.addReplacer('new-lk2.sms-dnevnik.com', 'https://new-lk2.sms-dnevnik.com/eljur/api/');
      }
    }

    if ((process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_JOURNAL) {
      if (apiType === apiTypes.API_GENERAL) {
        this.apiString.addReplacer('new-edu2.sms-dnevnik.com', 'https://api2.sms-dnevnik.com/rest/');
        this.apiString.addReplacer('new-edu.sms-dnevnik.com', 'https://api0.sms-dnevnik.com/rest/');
        this.apiString.addReplacer('edaskud.admgornnov.ru', 'https://apiskud.admgornnov.ru/rest/');
        this.apiString.addReplacer('journal.karta51.ru', 'https://api2.karta51.ru/rest/');
      }
    }

    if ((process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_FOOD) {
      if (apiType === apiTypes.API_GENERAL) {
        this.apiString.addReplacer('new-food2.sms-dnevnik.com', 'https://api2.sms-dnevnik.com/rest/');
        this.apiString.addReplacer('new-food.sms-dnevnik.com', 'https://api0.sms-dnevnik.com/rest/');
        this.apiString.addReplacer('edaskud.admgornnov.ru', 'https://apiskud.admgornnov.ru/rest/');
        this.apiString.addReplacer('new-dining.karta51.ru', 'https://api2.karta51.ru/rest/');
      }
    }
  }

  /**
   *  Добавление заголовков к настройкам текущего api
   */

  // public
  addHeaders(headers: Object) {
    this.headers = { ...this.headers, ...headers };
  }

  /**
   *  Получение заголовков, для использования внутри класса
   */

  // private
  getHeaders() {
    if (this.apiType === apiTypes.API_GENERAL) {
      return {
        'Argus-App-Type': (process.env.APP_NAME || process.env.REACT_APP_NAME),
        'Argus-Auth-Token': getToken() || '',
        ...this.headers,
      };
    }
    if (this.apiType === apiTypes.API_SHOP) {
      return {
        Authorization: shopUserToken.getToken(),
        ...this.headers,
      };
    }
    return this.headers;
  }

  /**
   *  Получение конкретных данных запроса на основе объекта запроса и настроек текущего api
   */

  static getRequest<Params, Result, PathParams>(
    // $FlowFixMe
    requestProps: Request<Params, Result, PathParams>,
    requestPathParams?: PathParams,
  ): [
      string,
      RequestMethod,
      Array<string>
    ] {
    const [requestPath, ...others] = requestProps;

    if (!requestPathParams || isEmpty(requestPathParams)) {
      return [requestPath, ...others];
    }

    let newRequestPath = requestPath;

    forEachObjIndexed((value, key) => {
      newRequestPath = newRequestPath.replace(`{${key}}`, value);
    }, requestPathParams);

    return [newRequestPath, ...others];
  }

  get baseURL() {
    return this.apiString.value;
  }

  /**
  *  Общая функция отправки запроса
  */

  async commonRequest(url: string, method: RequestMethod, rest?: Object, headers?: Object) {
    const fullPath = urlBeginsWithHttp(url) ? url : this.apiString.value + url;
    // $FlowFixMe
    const res = await axios({
      url: fullPath,
      headers: headers ? { ...this.getHeaders(), ...headers } : this.getHeaders(),
      method,
      timeout: this.timeout,
      ...(rest || {}),
    });

    return res;
  }

  /**
   *  Общая функция отправки запроса с возможностью прерывания
   */

  async commonRequestCancelable(url: string, method: RequestMethod, rest?: Object, headers?: Object) {
    if (typeof this.cancel === 'function') {
      this.cancel();
    }

    const res = await this.commonRequest(url, method, {
      cancelToken: new CancelToken((c) => {
        this.cancel = c;
      }),
      ...rest,
    }, headers);

    return res;
  }

  /**
   *  Основная функция отправки запроса
   */

  async request<Params, Result, PathParams>(
    requestProps: Request<Params, Result, PathParams>,
    options?: {
      params?: Params,
      requestPathParams?: PathParams,
      error?: ErrorType,
      onChangeLoadingState?: ((boolean => boolean) | boolean) => void,
        headers ?: Object,
        additionalAxiosOptions ?: Object,
        cancelable ?: boolean,
        convertions ?: Convertions < Params, Result >
    } = { },
  ): Promise < Result | null > {
  const { dispatch } = this.store || {};
  const {
    params: paramsIn,
    error,
    requestPathParams: rpp,
    onChangeLoadingState = dummy,
    headers,
    additionalAxiosOptions = {},
    cancelable,
    convertions,
  } = options;

  const { convertParams, convertResult } = convertions || {};
  const [params, requestPathParams] = convertParams && paramsIn ? convertParams(paramsIn) : [paramsIn, rpp];

  const [requestPath, type, dataPath] = Api.getRequest(requestProps, requestPathParams);

  const rest = (type === 'get'
    ? { params, ...additionalAxiosOptions }
    : { data: params, ...additionalAxiosOptions });

  try {
    onChangeLoadingState(true);
      const res = (cancelable
      ? await this.commonRequestCancelable(requestPath, type, rest, headers)
      : await this.commonRequest(requestPath, type, rest, headers));
    onChangeLoadingState(false);
      const resultData = path(dataPath, res);
    return convertResult ? convertResult(resultData, paramsIn) : resultData;
  } catch(err) {
    if (!isProduction()) {
      console.warn('[API 👻]', err);
    }
    const errAsString = err.toString();
    if (errAsString === 'Cancel' || errAsString === 'CanceledError: canceled') {
      return null;
    }
    const errorSave = {
      err: typeof error === 'function' ? error(err) : error,
      path: requestPath,
      type,
      headers: this.getHeaders(),
      error: clone(err),
    };
    const errorSave2 = (!error // maybe silent mode
      ? errorSave : {
        ...errorSave,
        err: typeof error === 'function' ? error(err) : error,
      });
    if (dispatch) {
      dispatch(action('REQUEST_FAILURE', errorSave2));
    }
    return null;
  }
}

  /**
   *  Отправка файла на сервер (стандартная версия)
   */

  async submitFile(
  file: File,
  onProgress ?: (progressEvent: { loaded: number, total: number }) => void,
) {
  const data = new FormData();
  data.append('files[]', file);
  const res: FilesType | null = await this.request(requestsGeneral.File_POST, {
    params: data,
    error: 'Не удалось загрузить файл',
    ...(onProgress ? { additionalAxiosOptions: { onUploadProgress: onProgress } } : {}),
  });
  return res;
}

  /**
   *  Запрос, результатом которого является файл
   */

  async download < Params, Result, PathParams > (
  requestProps: Request < Params, Result, PathParams >,
    options: {
  filename: string,
    params ?: Object,
    requestPathParams ?: PathParams,
    error ?: string | (err: Error) => string,
      onChangeLoadingState ?: ((boolean => boolean) | boolean) => void,
        headers ?: Object,
        additionalAxiosOptions ?: Object,
        linkProps ?: {
          skipDownload?: boolean,
          target?: string,
        },
        convertions ?: Convertions < Params, Result >
    }
) {
  const { dispatch } = this.store || {};
  const {
    filename,
    params: paramsIn,
    error,
    requestPathParams: rpp,
    // onChangeLoadingState = dummy,
    headers,
    additionalAxiosOptions = {},
    linkProps = {},
    convertions,
  } = options;


  const { convertParams } = convertions || {};
  const [params, requestPathParams] = convertParams && paramsIn ? convertParams(paramsIn) : [paramsIn, rpp];


  const [requestPath, type] = Api.getRequest(requestProps, requestPathParams);
  if (!requestPath) {
    return false;
  }

  if (!filename) {
    console.error('!!! Имя файла обязательный параметр для Api.download');
    return false;
  }

  const rest = (type === 'get'
    ? { params, responseType: 'arraybuffer', ...additionalAxiosOptions }
    : { data: params, responseType: 'arraybuffer', ...additionalAxiosOptions });

  try {
    const res = await this.commonRequest(requestPath, type, rest, headers);
    const format = path(['headers', 'content-type'], res);
    const file = prop('data', res);
    const blob = new Blob([file], { type: format });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    if (!linkProps.skipDownload) {
      link.download = filename;
    }
    if (linkProps.target) {
      link.target = linkProps.target;
    }
    const { body } = document;
    if (body) {
      body.appendChild(link);
      link.click();
      body.removeChild(link);
    }
  } catch (err) {
    const errorSave = {
      err: typeof error === 'function' ? error(err) : error,
      path: requestPath,
      type,
      headers: this.getHeaders(),
      error: clone(err),
    };
    const errorSave2 = (!error // maybe silent mode
      ? errorSave : {
        ...errorSave,
        err: typeof error === 'function' ? error(err) : error,
      });
    if (dispatch) {
      dispatch(action('DOWNLOAD_FAILURE', errorSave2));
    }
    return false;
  }
  return true;
}
}

export default Api;
