// @flow
import React, { Fragment, type Node } from 'react';
import { Route } from 'react-router';

import Requisites from 'app/components/Settings/Requisites';
import CSSSticky from 'app/common/ui-components/css-sticky';

import Navigation from '../Navigation';
import { baseUrl as rootBaseUrl } from '../baseUrl';
import styles from './RequisitesPage.scss';

/**
 *  Страница Настройки/Дополнительно
 */

export const baseUrl = `${rootBaseUrl}/requisites`;

const RequisitesPage = (): Node => {
  return (
    <Fragment>
      <CSSSticky
        number={1}
        className={styles.stickableMenu}
      >
        <Navigation />
      </CSSSticky>

      <Requisites />
    </Fragment>
  );
};

export const requisitesPageRoutes = [
  <Route key="requisites" path={baseUrl} component={RequisitesPage} />,
];
