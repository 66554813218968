// @flow
import React, { useState, useCallback } from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { PlainItemsSelector } from 'app/common/ui-next/plain-selector';
import * as dm from 'app/dataTypes/dayModes';
import * as dmt from 'app/dataTypes/DayModes.types';

import styles from './TimeRestrictionsFoodMode.scss';


const timePickerLocalization = {
  invalidDateMessage: 'Неверный формат времени',
};

const dayModes = {
  [dm.TODAY]: { id: dm.TODAY, title: 'В день питания' },
  [dm.BEFORE]: { id: dm.BEFORE, title: 'За день до питания' },
};

const foodModeTitles = ['Завтрак', 'Обед', 'Полдник'];

const createTime = (time: string): Date | null => {
  const date = new Date(`Jan 1 2000 ${time} GMT+3`);
  if (date.toString() === 'Invalid Date') {
    return null;
  }
  return date;
};

type Props = {
  disabled?: boolean,
  fmNumber: number,
  groupNumber: number,
  time: Date,
  dayMode: dmt.Before | dmt.Today,
  onUpdateTime: (gNum: number, fmNum: number, value: Date) => void,
  onUpdateDayMode: (gNum: number, fmNum: number, value: dmt.Before | dmt.Today) => void,
}

const TimeRestrictionsFoodMode = ({
  disabled,
  fmNumber,
  groupNumber,
  time,
  dayMode,
  onUpdateTime,
  onUpdateDayMode,
}: Props) => {
  const [localError, setLocalError] = useState(false);
  const handleLocalError = useCallback((errNode) => {
    setLocalError(!!errNode);
  }, []);

  const error = '';
  const hasError = !!error || localError;

  const handleChange = useCallback((_, time_) => {
    const dateTime = createTime(time_);
    if (dateTime) {
      const cloned = new Date(dateTime.getTime());
      onUpdateTime(groupNumber, fmNumber, cloned);
    }
  }, [groupNumber, fmNumber, onUpdateTime]);

  const handleBlur = useCallback((time_) => {
    const dateTime = createTime(time_);
    if (dateTime) {
      const cloned = new Date(dateTime.getTime());
      onUpdateTime(groupNumber, fmNumber, cloned);
      return;
    }
    if (time) {
      const cloned = new Date(time.getTime());
      onUpdateTime(groupNumber, fmNumber, cloned);
    }
  }, [groupNumber, fmNumber, onUpdateTime, time]);

  const handleDayModeChange = useCallback((value: Array<any>) => {
    onUpdateDayMode(groupNumber, fmNumber, value[0]);
  }, [groupNumber, fmNumber, onUpdateDayMode]);

  return (
    <div className={styles.root}>
      <FormControl
        error={!!error}
        margin="dense"
        className={styles.time}
        fullWidth
      >
        <KeyboardTimePicker
          ampm={false}
          variant="inline"
          label={foodModeTitles[fmNumber]}
          value={time}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          error={hasError}
          onError={handleLocalError}
          fullWidth
          clearable="false"
          autoOk
          {...timePickerLocalization}
        />
        <FormHelperText>{error || <span>&nbsp;</span>}</FormHelperText>
      </FormControl>

      <PlainItemsSelector
        label="Ограничить"
        header="Выберите ограничение"
        buttonResetLabel=""
        disabled={disabled}
        selectedAllOnEmptySelection={false}
        items={dayModes}
        selection={[dayMode]}
        onChange={handleDayModeChange}
      />
    </div>
  );
};

export default TimeRestrictionsFoodMode;
