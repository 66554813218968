// @flow
import { map, pipe, when, always, assoc } from 'ramda';

import type { ParamsPOST, PlanogramRawData } from 'app/dataTypes/vending/Planogram.types';

import type { Request } from '../../../../index';
import { convertResult } from './get';


export { convertResult };

type ParamsApi = {
  data: Array<{
    id?: number,
    number: string,
    capacity: number,
    width: string,
    height: string,
    row: string,
    column: string,
    _destroy?: true,
  }>
}

type UrlParams = {
  machineId: string,
}

export const convertParams = (params: ParamsPOST): [ParamsApi, UrlParams] => {
  const { data, machineId } = params;
  const dataApi = map(({
    id,
    nonTrustedSlotName,
    maxGoods,
    column,
    row,
    height,
    width,
    _destroy,
  }) => {
    const idInt = parseInt(id, 10);

    return pipe(
      when(always(Boolean(idInt)), assoc('id', idInt)),
      when(always(Boolean(_destroy)), assoc('_destroy', _destroy)),
    )({
      number: nonTrustedSlotName,
      capacity: maxGoods,
      column: `${column + 1}.0`,
      row: `${row + 1}.0`,
      height: `${height}.0`,
      width: `${width}.0`,
    });
  }, data);

  return [{ data: dataApi }, { machineId }];
};

export const POST_PLANORGAM_SLOTS: Request<
  ParamsPOST,
  PlanogramRawData,
  UrlParams
> = [
  'data/vending/machines/{machineId}/slots',
  'post',
  ['data', 'data'],
];
