import React from 'react';

const IconGooglePlay = props => (<svg viewBox="0 0 22 22" width="22px" height="22px" {...props}>
  <path fill="#349886" id="f1" d="M4.297 4.311c-0.035 0.124-0.059 0.258-0.059 0.41v12.56c0 0.149 0.024 0.282 0.058 0.404l7.048-6.677 L4.297 4.311z" />
  <path fill="#3DB39E" id="f2" d="M14.096 8.401l-8.23-4.578c-0.328-0.2-0.65-0.25-0.923-0.181l7.079 6.726L14.096 8.401z" />
  <path fill="#F4B459" id="f3" d="M17.131 10.089l-2.197-1.222l-2.25 2.131l2.291 2.18l2.138-1.175 C18.156 11.409 17.775 10.452 17.131 10.089z" />
  <path fill="#E2574C" id="f4" d="M4.921 18.352c0.277 0.077 0.608 0.032 0.944-0.174l8.255-4.532l-2.112-2.009L4.921 18.352z" />
</svg>);

export default IconGooglePlay;
