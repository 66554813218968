// @flow
import React, { type ComponentType, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { removeParam } from 'app/common/lib/params';
import isProduction from 'app/common/lib/isProduction';

import Loader from './Loader';
import container from './container';
import styles from './Login.scss';


const ver = (
  isProduction()
    ? ''
    : 'build date: [AIV]{date}[/AIV]'
);

type Props = {
  loading?: boolean,
  error?: boolean,
  component: ComponentType<Object>,
  logo: string,
  company: string,
  silence?: boolean,
  handleRestore: (e: SyntheticEvent<HTMLElement>) => void,
  handleMain: (e: SyntheticEvent<HTMLElement>) => void,
  title: string,
}

const Login = ({ loading, error, component: Comp, silence, title, ...other }: Props) => {
  useEffect(() => {
    removeParam('token');
  }, []);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Loader loading={loading} error={error} />
      {!silence
        && (
          <div className={styles.container}>
            <Comp
              {...other} // eslint-disable-line react/jsx-props-no-spreading
            />
            <div className={styles.ver}>{ver}</div>
          </div>
        )}
    </div>
  );
};

export default container(Login);
