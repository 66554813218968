import type { Requisites } from 'app/dataTypes/requisites'

import { convertResult } from './convertions'
import type { Request } from '../../index'


export { convertResult }

export const GET_REQUISITES: Request<void, Requisites, void> = [
  'food/requisites2',
  'get',
  ['data'],
]
