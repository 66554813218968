// @flow
import api from 'app/actions/api';
import { ACCOUNT_ACTIVE, ACCOUNT_BLOCKED } from '.';

export const CHANGE_ACTIVITY_REQUEST = 'CHANGE_ACTIVITY_REQUEST';
export const CHANGE_ACTIVITY_SUCCESS = 'CHANGE_ACTIVITY_SUCCESS';
export const CHANGE_ACTIVITY_FAILURE = 'CHANGE_ACTIVITY_FAILURE';

const changeActivityRequest = payload => ({
  type: CHANGE_ACTIVITY_REQUEST,
  payload,
});

const changeActivitySuccess = payload => ({
  type: CHANGE_ACTIVITY_SUCCESS,
  payload,
});

const changeActivityFailure = payload => ({
  type: CHANGE_ACTIVITY_FAILURE,
  payload,
});

export const changeActivity = (id: number | string, disable: boolean) => async (dispatch: Function, getState: Function) => {
  const status = disable ? ACCOUNT_BLOCKED : ACCOUNT_ACTIVE;
  const oldStatus = getState().employees.employee.settings.status;
  dispatch(changeActivityRequest({ id, status, oldStatus }));
  try {
    await api(`food/employees/${id}/auth/${disable ? 'disable' : 'enable'}`, 'put');
    dispatch(changeActivitySuccess({ id, status, oldStatus }));
  } catch (error) {
    dispatch(changeActivityFailure({
      err: `Запрос ${disable ? 'блокировки' : 'разблокировки'} не удался`,
      error,
      id,
      status,
      oldStatus,
    }));
  }
};
