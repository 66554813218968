// @flow
import React, { type Node } from 'react';

import styles from './legend-container.scss';


type Props = {
  legend: ?Node,
  children: Node,
}

const LegendContainer = ({ children, legend }: Props): Node => {
  if (!legend) {
    return children;
  }

  return (
    <div className={styles.root}>
      {children}
      <div className={styles.legend}>
        {legend}
      </div>
    </div>
  );
};

export default LegendContainer;
