// @flow
import React from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { getFIO } from 'app/common/lib/names';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import PlainButton from 'app/common/ui-next/plain-button';

import PasswordChange from './PasswordChange';
import PhoneChange from './PhoneChange';
import EmailChangeMaterial from './EmailChange/EmailChangeMaterial';
import FIOChange from './FIOChange';
import selectRestrictions from './selectRestrictions';
import TmpModalStarter from './TmpModalStarter';

import styles from './Profile.scss';


// $FlowFixMe
const enhance = connect(
  (state, props) => ({
    fio: getFIO(state.user.name),
    email: state.user.email,
    phone: state.user.phone,
    restrictions: selectRestrictions(state, props),
    loading: (
      state.user.loading
      || state.dealer.loading
    ),
  }),
);

type Props = {
  fio?: string,
  email?: string,
  phone?: string,
  className?: string,
  restrictions: {
    fio: boolean,
    phone: boolean,
    email: boolean,
    // password: boolean,
  },
  loading: boolean,
  introText: string,
  showRemoveLink?: boolean,
  showFio?: boolean,
  onClickRemoveLink?: () => void,
  disableFIOEdit?: boolean,
  children?: any,
  phoneEnabled?: boolean,
};

const Profile = ({
  fio,
  email,
  phone,
  className,
  restrictions,
  loading,
  introText,
  showRemoveLink,
  showFio = true,
  onClickRemoveLink,
  disableFIOEdit,
  children,
  phoneEnabled,
}: Props) => {
  const { t } = useTranslation('profile');

  return (
    <div className={classNames(styles.root, className)}>
      {(introText || showRemoveLink)
        && <div className={styles.intro}>
          {introText
            && <div className={styles.introText}>
              {introText}
            </div>}
          {showRemoveLink
            && onClickRemoveLink
            && <PlainButtonAsLink
              onClick={onClickRemoveLink}
              className={styles.remove}
            >
              <Trans i18nKey="profile:remove_profile_link">
                {'Удалить профиль'}
              </Trans>
            </PlainButtonAsLink>}
        </div>}

      {showFio
        && restrictions.fio
        && !loading
        && <div className={styles.service}>
          <h2>
            <Trans i18nKey="profile:fio_title">
              {'Фамилия, Имя и Отчество'}
            </Trans>
          </h2>
          <div className={styles.item}>
            <div className={styles.value}>{fio}</div>
            {!disableFIOEdit
              && <div className={styles.action}>
                <TmpModalStarter
                  startModal={close => <FIOChange close={close} />}
                >
                  {open => (
                    <PlainButton onClick={open} className={styles.button} outline>
                      {fio ? t('fio_edit_bt') : t('fio_add_bt')}
                    </PlainButton>
                  )}
                </TmpModalStarter>
              </div>}
          </div>
          <div className={styles.itemComment}>
            <Trans i18nKey="profile:fio_info">
              {'Фамилия, Имя и Отчество используется для идентификации'}
              {'пользователя и при обращении сотрудников поставщика услуги.'}
            </Trans>
          </div>
        </div>}

      {restrictions.phone
        && !loading
        && (
          phoneEnabled === true
          || (phoneEnabled === false && phone)
          || typeof phoneEnabled !== 'boolean'
        )
        && <div className={styles.service}>
          <h2>
            <Trans i18nKey="profile:phone_title">
              {'Телефон'}
            </Trans>
          </h2>
          <div className={styles.item}>
            <div className={styles.value}>{phone}</div>

            {phoneEnabled
              && <div className={styles.action}>
                <TmpModalStarter
                  startModal={close => <PhoneChange close={close} />}
                >
                  {open => (
                    <PlainButton onClick={open} className={styles.button} outline>
                      {phone ? t('phone_edit_bt') : t('phone_add_bt')}
                    </PlainButton>
                  )}
                </TmpModalStarter>
              </div>}
          </div>
          <div className={styles.itemComment}>
            <Trans i18nKey="profile:phone_info">
              {'Телефон используется как логин при авторизации, для информирования'}
              {'различных уведомлений от сервиса, как номер лицевого счета'}
              {'при пополнении счета за услуги и питание детей.'}
            </Trans>
          </div>
        </div>}

      {restrictions.email
        && !loading
        && <div className={styles.service}>
          <h2>
            <Trans i18nKey="profile:email_title">
              {'Электронная почта'}
            </Trans>
          </h2>
          <div className={styles.item}>
            <div className={styles.value}>{email}</div>
            <div className={styles.action}>
              <TmpModalStarter
                startModal={close => <EmailChangeMaterial close={close} />}
              >
                {open => (
                  <PlainButton onClick={open} className={styles.button} outline>
                    {email ? t('email_edit_bt') : t('email_add_bt')}
                  </PlainButton>
                )}
              </TmpModalStarter>
            </div>
          </div>
          <div className={styles.itemComment}>
            <Trans i18nKey="profile:email_info">
              {'E-mail используется как логин при авторизации и для информирования'}
              {'различных уведомлений от сервиса.'}
            </Trans>
          </div>
        </div>}

      <div className={styles.service}>
        <h2>
          <Trans i18nKey="profile:password_title">
            {'Пароль'}
          </Trans>
        </h2>
        <div className={styles.item}>
          <div className={styles.value}>{'**********'}</div>
          <div className={styles.action}>
            <TmpModalStarter
              startModal={close => <PasswordChange close={close} />}
            >
              {open => (
                <PlainButton onClick={open} className={styles.button} outline>
                  <Trans i18nKey="profile:password_edit_bt">
                    {'Изменить пароль'}
                  </Trans>
                </PlainButton>
              )}
            </TmpModalStarter>
          </div>
        </div>
      </div>

      {children}
    </div>
  );
};

export default enhance(Profile);
