// @flow
import { useMemo } from 'react';
import { reduce } from 'ramda';

import toFixedValue from 'app/common/lib/toFixedValue';

import { ids as columnIds } from './columns';
import type { CSchool } from '../useReportData';


export type ActualItem = {
  id: string,
  [$Values<typeof columnIds>]: string,
}

export default function useActualItems(data: Array<CSchool>): Array<ActualItem> {
  const res = useMemo(() => {
    const [items, complexTotal, personalTotal] = reduce((acc, {
      school_title: title,
      complex,
      personal,
    }): [Array<ActualItem>, number, number] => {
      const item: ActualItem = ({
        id: title,
        [columnIds.TITLE]: title,
        [columnIds.COMPLEX]: toFixedValue(complex),
        [columnIds.PERSONAL]: toFixedValue(personal),
        [columnIds.TOTAL]: toFixedValue(complex + personal),
      });
      acc[0].push(item);
      acc[1] += complex;
      acc[2] += personal;
      return acc;
    }, [[], 0, 0], data);

    const total: ActualItem = {
      id: 'Итого',
      [columnIds.TITLE]: 'Итого',
      [columnIds.COMPLEX]: toFixedValue(complexTotal),
      [columnIds.PERSONAL]: toFixedValue(personalTotal),
      [columnIds.TOTAL]: toFixedValue(complexTotal + personalTotal),
    };

    return [...items, total];
  }, [data]);

  return res;
}
