import { useCallback, useEffect } from 'react'

import { StatusMessage } from 'app/common/templates-next/page-template'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import OrgListItem from './OrgListItem'
import styles from './OrgList.scss'


type Props = {
  orgGroupID: string
  active?: boolean
  items?: Array<{ ID: string, title: string }>
  disabled?: boolean
  onRemoveFromList?: (orgGroupID: string, orgID: string) => Promise<boolean>
  onChangeData?: () => void
}

const OrgList = ({ orgGroupID, active, items, disabled, onRemoveFromList, onChangeData }: Props) => {
  const onRemoveFromListRef = useCallbackRef(onRemoveFromList)
  const onChangeDataRef = useCallbackRef(onChangeData)

  const handleRemoveOrg = useCallback(async (orgID: string) => {
    const res = await onRemoveFromListRef.current?.(orgGroupID, orgID)
    return !!res
  }, [onRemoveFromListRef, orgGroupID])

  useEffect(() => {
    if (active) {
      onChangeDataRef.current?.()
    }
  }, [items])

  return (
    <div>
      {!items
        ? <StatusMessage className={styles.emptyMessage}>
          {'Отсутствуют заведения в группе'}
        </StatusMessage>
        : <ul className={styles.list}>
          {items.map(({ ID, title }) => (
            <OrgListItem
              key={ID}
              ID={ID}
              title={title}
              disabled={disabled}
              onCrossClick={handleRemoveOrg}
            />
          ))}
        </ul>}
    </div>
  )
}

export default OrgList
