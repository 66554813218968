// @flow
import React from 'react';

import PlainBadge from 'app/common/ui/PlainBadge';

import styles from './PlainFilter.scss';


type Props = {
  className?: string,
  item: {
    id: string | number,
    title: string,
  },
  title: string,
  light?: boolean,

}

const PlainFilterStatic = ({
  className,
  item: { id, title: itemTitle },
  title,
  light,
}: Props) => (
  <div className={className}>
    <div className={styles.headerBlock}>
      <div>{title}</div>
    </div>
    <div className={styles.badges}>
      <PlainBadge
        id={id}
        title={itemTitle}
        light={light}
      />
    </div>
  </div>
);

export default PlainFilterStatic;
