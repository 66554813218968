import { createSelector } from 'reselect';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';
import propEq from 'ramda/es/propEq';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import drop from 'ramda/es/drop';


const selectCategories = createSelector(
  [
    (_, { categoryList }) => categoryList,
    (_, { categoryId }) => parseInt(categoryId, 10),
  ],
  (categoryList, categoryId) => pipe(
    drop(1),
    map(({ id, title }) => ({ id: parseInt(id, 10), title })),
    filter(complement(propEq('id', categoryId))),
  )(categoryList),
);

export default selectCategories;
