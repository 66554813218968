// @flow
import * as React from 'react';
import path from 'ramda/es/path';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Table } from 'app/common/components/Table';
import Paging from 'app/common/ui/Paging';
import Sticky from 'app/common/components/Sticky';

import ts from './NinePartsTable.scss';


const renderNoScroll = () => <span />;
let lastKnownScrollPosition = 0;
let ticking = false;

/**
 * Таблица из 9 частей, со скролингом и прилипанием
 */

type Props = {
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  currentPage: number,
  total: number,
  rows: number,
  currentPageSize: number,
  topLeftContent: React.Node,
  topCenterContent: React.Node,
  topRightContent: React.Node,
  middleLeftContent: React.Node,
  middleCenterContent: React.Node,
  middleRightContent: React.Node,
  bottomLeftContent: React.Node,
  bottomCenterContent: React.Node,
  bottomRightContent: React.Node,
  leftWidth: number,
  rightWidth: number,
}

const NinePartsTable = ({
  handlePage,
  currentPage,
  total,
  rows,
  currentPageSize,
  topLeftContent,
  topCenterContent,
  topRightContent,
  middleLeftContent,
  middleCenterContent,
  middleRightContent,
  bottomLeftContent,
  bottomCenterContent,
  bottomRightContent,
  leftWidth,
  rightWidth,
}: Props) => {
  const t1Style = { width: leftWidth + 1 };
  const t3Style = { width: rightWidth + 1 };

  // скролинг
  const [headerScrollableEl, refHeaderScrollable] = React.useState(null);
  const [middleScrollableEl, refMiddleScrollable] = React.useState(null);
  const [bottomScrollableEl, refBottomScrollable] = React.useState(null);
  const handleScroll = React.useCallback(
    (e) => {
      e.stopImmediatePropagation();
      if (!e.currentTarget) return;
      const { scrollLeft } = e.currentTarget;
      lastKnownScrollPosition = scrollLeft;
      const id = path(['currentTarget', 'parentNode', 'id'], e);
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (headerScrollableEl && id !== 'hs') {
            headerScrollableEl.view.scrollLeft = lastKnownScrollPosition;
          }
          if (middleScrollableEl && id !== 'ms') {
            middleScrollableEl.view.scrollLeft = lastKnownScrollPosition;
          }
          if (bottomScrollableEl && id !== 'bs') {
            bottomScrollableEl.view.scrollLeft = lastKnownScrollPosition;
          }
          ticking = false;
        });
        ticking = true;
      }
    },
  );

  return (
    <React.Fragment>

      {/* Заголовок таблицы с прилипанием */}
      <Sticky
        number={2}
        trackState
        stickyClassName={ts.shadow}
        className={ts.stickyHeader}
      >
        <div className={ts.tripleTable}>

          {/* Левая часть таблицы */}
          <Table className={ts.table1} style={t1Style}>
            {topLeftContent}
          </Table>

          <Scrollbars
            className={ts.scrollbars}
            renderTrackHorizontal={renderNoScroll}
            onScroll={handleScroll}
            ref={refHeaderScrollable}
            id="hs"
          >
            <div className={ts.table2}>
              <Table>
                {topCenterContent}
              </Table>
            </div>
          </Scrollbars>

          {/* Правая часть таблицы */}
          <Table className={ts.table3} style={t3Style}>
            {topRightContent}
          </Table>
        </div>
      </Sticky>
      {/* Конец прилипания и заголовка таблицы */}

      <div className="stickStart">

        {/* Середина таблицы */}
        <div className={ts.tripleTable}>

          {/* Левая часть таблицы */}
          <Table className={ts.table1} style={t1Style}>
            {middleLeftContent}
          </Table>

          {/* *** *** *** ***  */}
          {/* Середина таблицы */}
          <Scrollbars
            className={ts.scrollbars}
            onScroll={handleScroll}
            renderTrackHorizontal={renderNoScroll}
            ref={refMiddleScrollable}
            id="ms"
          >
            <div className={ts.table2}>
              <Table>
                {middleCenterContent}
              </Table>
            </div>
          </Scrollbars>

          {/* Правая часть таблицы */}
          <Table className={ts.table3} style={t3Style}>
            {middleRightContent}
          </Table>
        </div>

        {/* Подвал с прилипанием */}
        <Sticky
          mode="bottom"
          number={-1}
          boundaryElement=".stickStart"
          style={{ position: 'relative' }}
          stickyClassName={ts.bottomShadow}
          hideOnBoundaryHit
        >
          <div className={ts.tripleTable}>
            {/* Левая часть таблицы */}
            <Table className={ts.table1} style={t1Style}>
              {bottomLeftContent}
            </Table>

            {/* Середина таблицы */}
            <Scrollbars
              className={ts.scrollbars}
              onScroll={handleScroll}
              ref={refBottomScrollable}
              id="bs"
            >
              <div className={ts.table2}>
                <Table>
                  {bottomCenterContent}
                </Table>
              </div>
            </Scrollbars>

            {/* Правая часть таблицы */}
            <Table className={ts.table3} style={t3Style}>
              {bottomRightContent}
            </Table>
          </div>

          {/* Постарничная навигация */}
          {(total > rows)
            && <Paging
              className={ts.pager}
              total={total}
              pageSize={rows}
              currentPage={currentPage}
              currentPageSize={currentPageSize}
              onPage={handlePage}
            />}
        </Sticky>

      </div>
      {/* end className="stickStart" */}
    </React.Fragment>
  );
};

export default NinePartsTable;
