import * as React from 'react';
import nope from 'app/common/lib/nope';


const ParamsContext = React.createContext({
  params: {},
  pathname: '',
  push: nope,
  replace: nope,
  pathMask: '',
});

export default ParamsContext;
