// @flow
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';

import usePopup from 'app/common/hooks/usePopup';
import { itemsEdit } from 'app/actions/orgs/menu/itemsEdit';
import type { ValidateFunc } from 'app/common/hooks/useErrors';


type Item = {
  id: number,
  title: string,
}

type Options = {
  sellPointId: null | number,
  onClose: () => void,
  dishes: Array<Item>,
  orgId: string,
  date: string,
  validate: ValidateFunc,
}

export default function useSubmit({
  sellPointId,
  onClose,
  dishes,
  orgId,
  date,
  validate,
}: Options): [(e: SyntheticEvent<HTMLFormElement>) => Promise<void>, boolean] {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const popup = usePopup();

  const submit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validate({ date, sellPointId })) return;
    if (!sellPointId) return;

    setSaving(true);
    const dishesIds = map(prop('id'), dishes);

    const res = await dispatch(itemsEdit(orgId, {
      date,
      sellPointId,
    }, dishesIds));

    setSaving(false);
    if (res) {
      popup('Продукция была успешно добавлена в меню');
      onClose();
    }
  }, [
    date,
    dishes,
    dispatch,
    onClose,
    orgId,
    popup,
    sellPointId,
    validate,
  ]);

  return [submit, saving];
}
