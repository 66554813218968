import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { ItemType } from 'app/common/ui-next/plain-selector'


/**
 * Data
*/

export type OperationsTypes = Array<ItemType>

class OperationsTypesFilterDataBox extends DataBox<OperationsTypes> { }

export const defaultOperationsTypes: OperationsTypes = []
export const operationsTypesFilter = createDataBox('operationsTypesFilter', defaultOperationsTypes, OperationsTypesFilterDataBox)

export const defaultOperationsTypesData = () => {
  return [
    { id: 'purchases', title: 'Продажи' },
    { id: 'subsidies', title: 'Дотации' },
  ]
}

operationsTypesFilter.updateData(defaultOperationsTypesData)
operationsTypesFilter.setStatusReady()

/**
 * Selection
 */

export const defaultOperationsTypesSelection: Array<string> = ['purchases']
export const operationsTypesFilterSelection = createDataBoxDefault<Array<string>>('operationsTypesFilterSelection', defaultOperationsTypesSelection)
