import { ReactNode } from 'react'
import cn from 'classnames/bind'

import { AvailableDisplayName } from './available-display-name'
import { type HandleModalDisplayed } from './use-modal-displayed'

import styles from './plain-modal-footer.scss'


const cx = cn.bind(styles)

type Props = {
  children: ReactNode | ((toggle: HandleModalDisplayed) => ReactNode)
  className?: string
  handleModalDisplayed?: HandleModalDisplayed
  paddingFree?: boolean
}

const PlainModalFooter = ({
  children,
  className,
  handleModalDisplayed,
  paddingFree,
}: Props): ReactNode => {
  return (
    <div className={cx({ modalFooter: !paddingFree, hasBorder: true }, className)}>
      {typeof children === 'function'
        && handleModalDisplayed
        && children(handleModalDisplayed)}

      {typeof children !== 'function' && children}
    </div>
  )
}

PlainModalFooter.displayName = AvailableDisplayName.PlainModalFooter

export default PlainModalFooter
