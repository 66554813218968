// @flow
import { useRef, useCallback } from 'react';


/**
 *  Скрытие фокуса элемента, если событие пришло с мыши
 */

export default function (onClick?: (e: SyntheticEvent<any>) => void | Promise<void>) {
  const elemRef = useRef<HTMLElement | null>(null);

  const handleClick = useCallback((e: SyntheticMouseEvent<any>) => {
    if (onClick) {
      onClick(e);
    }
    const { clientX, clientY } = e;
    if (elemRef.current && clientX !== 0 && clientY !== 0) {
      elemRef.current.blur();
    }
  }, [onClick]);

  return [elemRef, handleClick];
}
