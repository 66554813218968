// @flow
import React, { type Node } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';

import AddCategoryModal from './AddCategoryModal';


type Props = {
  className?: string,
  placeId?: string,
  loading?: boolean,
  onSuccess: (c: Category) => void,
}

const AddCategory = ({ className, placeId, loading, onSuccess }: Props): Node => {
  return (
    <AddCategoryModal
      placeId={placeId}
      onSuccess={onSuccess}
    >
      {toggle => (
        <PlainButton
          className={className}
          disabled={loading}
          onClick={toggle}
        >
          {'Добавить категорию'}
        </PlainButton>
      )}
    </AddCategoryModal>
  );
};

export default AddCategory;
