import { useCallback, useState, ReactNode, Fragment, ChangeEvent } from 'react'
import cn from 'classnames'

import Tr, { type TrProps } from './tr'


type CheckboxParams = {
  checked: boolean,
  disabled?: boolean,
  value: string,
  id: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  onFocus: () => void,
  onBlur: () => void,
}

type Props = {
  onClick: (id: string, state: boolean) => void
  children: ReactNode | ((checkboxParams: CheckboxParams) => ReactNode)
  id: string
  selected: boolean
  className?: string
  classNameFocused?: string
} & Omit<TrProps, 'onClick'>

export const TrSelectable = ({
  children,
  id,
  onClick,
  selected,
  className,
  classNameFocused,
  ...props
}: Props) => {
  const [focused, setFocused] = useState(false)

  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setFocused(false)
  }, [])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    onClick(id, checked)
  }, [id, onClick])

  const currentClassName = focused ? cn(className, classNameFocused) : className

  // const hasHiddenCheckbox = !typeof children === 'function';
  const { header, disabled } = props
  const HiddenCellComponent = header ? 'th' : 'td'

  return (
    <Tr className={currentClassName} {...props}>
      {typeof children === 'function'
        ? children({
          checked: selected,
          disabled,
          value: id,
          id,
          onChange: handleChange,
          onFocus: handleFocus,
          onBlur: handleBlur,
        })
        : <Fragment>
          {children}
          <HiddenCellComponent className="visuallyHidden">
            <input
              checked={selected}
              value={id}
              id={id}
              type="checkbox"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </HiddenCellComponent>
        </Fragment>}
    </Tr>
  )
}
