// @flow
import React, { useMemo } from 'react';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';

import { PlainGroup, PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainInput from 'app/common/ui/PlainInput';
import { dateFormat } from 'app/actions/employees/employee/jobplaces';
import PlainInputDate from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import PlainDropdown, { PlainDropdownDefitem as DItem } from 'app/common/ui-next/plain-dropdown';

import type { RoleItem } from '../useRoles';
import useJPlace from './useJPlace';
import JPlaceHeader from './JPlaceHeader';
import styles from './JPlace.scss';


type Props = {
  id: number,
  orgId: number,
  title: string,
  roleId: number,
  inviteDate: ?string,
  orderDate: ?string,
  orderNum: ?string,
  deleteAvailable: boolean,
  locked: boolean,
  roles: Array<RoleItem>
}

const JPlace = ({
  id,
  orgId,
  title,
  roleId,
  inviteDate,
  orderDate,
  orderNum,
  deleteAvailable,
  locked,
  roles,
}: Props) => {
  const [{
    loading,
    saving,
  }, {
    changeDate,
    changeField,
    changeRole,
    deleteJPlace,
  }] = useJPlace(id, orgId);

  const currentRoleTitle = useMemo(() => {
    const { title: res } = find(propEq('id', roleId), roles) || {};
    return res || 'Не используется';
  }, [roleId, roles]);

  return (
    <PlainGroup
      header={
        <JPlaceHeader
          handleDelete={deleteJPlace}
          deleteAvailable={deleteAvailable && !locked}
        />
      }
      className={styles.place}
    >
      <PlainColumns>
        <PlainColumn>
          <label htmlFor="org" className={styles.orgLabel}>{'Образовательная организация'}</label>
          <div id="org" className={styles.org}>{title}</div>
        </PlainColumn>
      </PlainColumns>

      <PlainColumns>
        <PlainColumn div={2} className={styles.role}>
          <PlainLabel id="role_label">
            {'Должность'}
          </PlainLabel>

          <PlainDropdown
            id="role"
            labelId="role_label"
            itemsContainerClassName={styles.role}
            onChange={changeRole}
            disabled={locked || saving || loading}
            placeholder={
              <DItem
                id={roleId || -1}
                disabled={locked || saving || loading}
              >
                {currentRoleTitle}
              </DItem>
            }
            defaultSkin
          >
            {roles.map(({ title: title_, id: id_ }) => (
              <DItem key={id_} id={id_} active={id_ === roleId}>
                {title_}
              </DItem>
            ))}
          </PlainDropdown>
        </PlainColumn>
      </PlainColumns>

      <PlainColumns className={styles.lastRow}>
        <PlainColumn div={5}>
          <PlainLabel htmlFor="invite_date">
            {'Дата приема'}
          </PlainLabel>

          <PlainInputDate
            id="invite_date"
            value={inviteDate || ''}
            onDateChange={changeDate}
            dateFormat={dateFormat}
            loading={loading}
            disabled={locked || saving}
            calendarCloseOnClick
          />

          <PlainHelperText oldUpperSpace>
            &nbsp;
          </PlainHelperText>

        </PlainColumn>

        <PlainColumn div={5}>
          <PlainInput
            label="№ приказа"
            name="order_num"
            value={orderNum}
            onChange={changeField}
            loading={loading}
            disabled={locked || saving}
          />
        </PlainColumn>

        <PlainColumn div={5}>
          <PlainLabel htmlFor="order_date">
            {'Дата приказа'}
          </PlainLabel>

          <PlainInputDate
            id="order_date"
            value={orderDate || ''}
            onDateChange={changeDate}
            dateFormat={dateFormat}
            loading={loading}
            disabled={locked || saving}
            calendarCloseOnClick
          />

          <PlainHelperText oldUpperSpace>
            &nbsp;
          </PlainHelperText>

        </PlainColumn>
      </PlainColumns>
    </PlainGroup>
  );
};

export default JPlace;
