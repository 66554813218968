// @flow
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
// import mapProps from 'recompose/mapProps';
import assoc from 'ramda/es/assoc';


/**
 * Сохранение размеров (ширины) колонок таблицы
 * для дальнейшего применения этих размеров в другой таблице
 * sizes: { [string]: number }
 */
export default compose(
  withState('sizes', 'setSizes', {}),
  withHandlers({
    refTd: ({ setSizes }) => (el: React.ElementRef<'td'> | null) => {
      if (!el) return;
      const name = el.getAttribute('name');
      const { left, right } = el.getBoundingClientRect();
      // $FlowFixMe
      setSizes(items => ({ ...items, [name]: right - left }));
    },
  }),
  // mapProps(props => (props))
);

type SizesProps = {
  maxSizes?: { [string]: number },
}

/**
 * Сохранение размеров колонок с учетом максимальных размеров
 * в overflow сохораняется переполнение размера тех колонок, которые превысили максимальный размер
 * sizes: { [string]: number, overflow: { [string]: number } }
 */
export const conglomerationTableSizesE = ({ maxSizes }: SizesProps) => compose(
  withState('sizes', 'setSizes', {}),
  withHandlers({
    refTd: ({ setSizes }) => (el: React.ElementRef<'td'> | null) => {
      if (!el) return;
      const name = el.getAttribute('name');
      const { left, right } = el.getBoundingClientRect();
      const diff = right - left;
      const max = maxSizes && name && maxSizes[name];
      if (!max) {
        setSizes(items => ({
          ...items,
          // $FlowFixMe
          [name]: diff,
        }));
        return;
      }
      const overflow = diff > max ? diff - max : 0;
      if (overflow) {
        setSizes(state => ({
          ...state,
          // $FlowFixMe
          [name]: diff > max ? max : diff,
          overflow: assoc(name, overflow, state.overflow),
        }));
      } else {
        setSizes(state => ({
          ...state,
          // $FlowFixMe
          [name]: diff,
        }));
      }
    },
  }),
  // mapProps(props => (props))
);

/**
 * Сохранение выстот строк таблицы, для применения в другой таблице
 * Так же добавлено отслеживание изменения размеров
 * Идентификация строк таблицы осуществяется по id элемента (<tr id="1" ...)
 * heights: { [string]: number }
 */
export const conglomerationRowsHeightsE = ({ minHeight = 0 }: { minHeight?: number }) => compose(
  withState('heights', 'setHeights', {}),

  withHandlers({
    onRowResize: ({ setHeights }) => (entries) => {
      const items = {};
      entries.forEach((entry) => {
        const { height } = entry.contentRect;
        const { id } = entry.target;
        const current = items[id] || 0;
        const newVal = Math.max(current, minHeight, height) || minHeight;
        items[id] = newVal;
      });
      setHeights(old => ({ ...old, ...items }));
    },
  }),

  withState('ro', 'setRo', ({ onRowResize }) => new ResizeObserver(onRowResize)),

  withHandlers({
    refTr: ({ ro }) => (el: React.ElementRef<any> | null) => {
      if (ro && el) {
        ro.observe(el);
      }
    },
  }),
  // mapProps(props => (props))
);
