import set from 'ramda/es/set';
import lensPath from 'ramda/es/lensPath';
import { RIGHTS_SUCCESS, RIGHTS_CLEAR, RIGHTS_CHANGE } from 'app/actions/admin';
import { RIGHTS_SAVE_SUCCESS } from 'app/actions/admin/save';

const defaultState = {
  sections: {},
  savedSections: {},
};

const rights = (state = defaultState, action) => {
  if (action.type === RIGHTS_SUCCESS
    || action.type === RIGHTS_SAVE_SUCCESS) {
    return ({
      sections: { ...action.payload },
      savedSections: { ...action.payload },
    });
  }
  if (action.type === RIGHTS_CLEAR) {
    return defaultState;
  }
  if (action.type === RIGHTS_CHANGE) {
    return set(
      lensPath(['sections', ...action.payload.path.split('.'), 'checked']),
      action.payload.checked,
      state,
    );
  }
  return state;
};

export default rights;
