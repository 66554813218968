import PlainCheckbox, { type Props as CheckboxProps } from '../plain-checkbox'
import PlainRadioButton, { type Props as RadioButtonProps } from '../plain-radio-button'


type Props = {
  radio: boolean
} & Omit<CheckboxProps, 'hideLabel' | 'skipLabel'> & RadioButtonProps

const PlainItemsModalCheckbox = ({ radio, ...props }: Props) => {
  if (radio) {
    return (
      <PlainRadioButton
        {...props}
      />
    )
  }

  return (
    <PlainCheckbox
      {...props}
    />
  )
}

export default PlainItemsModalCheckbox
