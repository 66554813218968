// @flow
import React, { type Node, useState, useCallback, useMemo } from 'react';
import { map } from 'ramda';

import { RowLayoutContent, Header } from 'app/common/layouts/RowLayout';
import type { Category, Categories } from 'app/dataTypes/vending/production/Categories.types';
import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useDebouncedSearch from 'app/common/hooks/useDebouncedSearch';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import PlainActionsButton from 'app/common/ui-next/plain-actions-button';
import SaleTypesData from 'app/dataProviders/SaleTypesData';

import AddRemoveProducts from './AddRemoveProducts';
import AddRemoveButton from './AddRemoveButton';
import VendingDishesGear from './Products/VendingDishesGear';
import ProductsTable from './Products/table/ProductsTable';
import useProductsTableData from './Products/useProductsTableData';
import useSelectionState from '../../_selection/useSelectionState';
import useDelete from './Products/useDelete';
import useSelectedDishes from './useSelectedDishes';
import DishesBatchActions from './DishesBatchActions';
import useRelatedCategories from './useRelatedCategories';
import useMove from './Products/useMove';

import styles from './Content.scss';


const tooltipWhileDisabled = 'Выберите категорию в колонке слева для редактирования списка продукции в ней';

type Props = {
  category: Category | null,
  categories: Categories,
  placeId: string,
  header: string,
  allDishesCategoryId: string,
  onSuccessAddRemoveProducts: (category: Category | Array<Category>) => void,
}

const Content = ({
  category,
  categories,
  placeId,
  header,
  allDishesCategoryId,
  onSuccessAddRemoveProducts,
}: Props): Node => {
  const [search, setSearch] = useState('');
  const handleResetSearch = useCallback(() => {
    setSearch('');
  }, []);

  const [searchString, handleSearch, handleClearSearch] = useDebouncedSearch(setSearch, handleResetSearch);

  const { id: categoryId } = category || { id: null };

  const { updateDish, updateDishAsCommon, ...productsTableData } = useProductsTableData({
    placeId,
    category,
    search,
    allDishesCategoryId,
  });

  const { dishes } = productsTableData;
  const defaultSelectionState = useMemo(() => {
    return new Map(map(({ id }) => ([id, false]), dishes));
  }, [dishes]);
  const { selection, selectItem, selectedAll } = useSelectionState(defaultSelectionState);

  const selectedDishes = useSelectedDishes(dishes, selection);
  const relatedCategories = useRelatedCategories(selectedDishes, categories);
  const deleteDish = useDelete(selectedDishes, onSuccessAddRemoveProducts, categories);
  const selected = selectedDishes.length;
  const moveDish = useMove(selectedDishes, onSuccessAddRemoveProducts, categories);

  return (
    <SaleTypesData>
      <RowLayoutContent flex>
        <Header>
          {header}
        </Header>
        <VendingDishesGear />
      </RowLayoutContent>

      <RowLayoutContent borderTop={false}>
        <ControlsBar>
          {(!categoryId || categoryId === allDishesCategoryId)
            && <AddRemoveButton
              disabled
              tooltip={categoryId === allDishesCategoryId ? tooltipWhileDisabled : ''}
            />}

          {categoryId
            && categoryId !== allDishesCategoryId
            && <AddRemoveProducts
              categoryId={categoryId}
              placeId={placeId}
              onSuccess={onSuccessAddRemoveProducts}
            >
              {toggle => <AddRemoveButton onClick={toggle} />}
            </AddRemoveProducts>}

          <PlainInputSearch
            placeholder="Поиск"
            searchString={searchString}
            handleSearch={handleSearch}
            handleClearSearch={handleClearSearch}
          />

          <DishesBatchActions
            dishes={selectedDishes}
            relatedCategories={relatedCategories}
            allCategories={categories}
            deleteSelection={deleteDish}
            moveSelection={moveDish}
          >
            {({ onDeleteDishes, onMoveDishes }) => (
              <PlainActionsButton
                className={styles.actionButton}
                value="Изменить"
                number={selected}
                valueMask="Изменить (%n%)"
                disabled={!selected}
                collapsed
              >
                <PlainButton key="move" onClick={onMoveDishes}>
                  {`Переместить (${selected})`}
                </PlainButton>
                <PlainButton key="del" onClick={onDeleteDishes}>
                  {`Удалить (${selected})`}
                </PlainButton>
              </PlainActionsButton>
            )}
          </DishesBatchActions>
        </ControlsBar>
      </RowLayoutContent>

      <RowLayoutContent className={styles.data} paddingfree>
        <ProductsTable
          selection={selection}
          selectedAll={selectedAll}
          categories={categories}
          selectItem={selectItem}
          onDelete={deleteDish}
          onUpdateDish={updateDish}
          onUpdateDishAsCommon={updateDishAsCommon}
          {...productsTableData}
        />
      </RowLayoutContent>
    </SaleTypesData>
  );
};

export default Content;
