import {
  GET_COMPENSATION_TRANSACTIONS,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/compensation/transactions/get'

import { useCommonDataProvider, Options } from 'app/dataProviders/useCommonDataProvider'
import { CompensationTransactions, CompensationTransactionsParams } from 'app/dataTypes/compensation/transations'


const defaultState: CompensationTransactions = {
  totalBalance: '...',
  transactions: [],
}

type Op = Omit<Options<
  CompensationTransactions,
  CompensationTransactionsParams
>, 'requestValue' | 'convertions' | 'defaultState'>

export function useTransactions(options: Op) {
  const res = useCommonDataProvider({
    ...options,
    requestValue: GET_COMPENSATION_TRANSACTIONS,
    error: 'Не удалось получить список заявок на вывод компенсации',
    convertions: {
      convertParams,
      convertResult,
    },
    defaultState,
  })

  return res
}
