import { useCallback, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { map } from 'ramda'


type Options = {
  initialDate: string
  multiple?: boolean
}

export const dateFormat = 'YYYY-MM-DD'
export default function useJobsDateState({ initialDate, multiple }: Options) {
  const [dateTo, setDateTo] = useState<string>(initialDate)
  const [dateSelection, setDateSelection] = useState<Array<string>>([])

  const setDateToMultiple = useCallback((selection: Array<string>, selectionDateFormat: string) => {
    setDateSelection(map(value => moment(value, selectionDateFormat).format(dateFormat), selection))
  }, [])

  useEffect(() => {
    setDateTo(initialDate)
  }, [initialDate])

  return {
    dateTo: multiple ? dateSelection : dateTo,
    setDateTo,
    setDateToMultiple,
  }
}
