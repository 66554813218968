import { useCallback } from 'react'
import { reject, append, equals } from 'ramda'

import { useEditGeneralSettings } from 'app/dataProviders/generalSettings/hooks'


export default function useParallels() {
  const {
    generalSettings: { parallelsForCommonOrder },
    loading,
    submitting,
    modified,
    update,
    submit,
  } = useEditGeneralSettings()

  const handleChange = useCallback((key: number, checked: boolean) => {
    update(
      'parallelsForCommonOrder',
      checked
        ? append(key, parallelsForCommonOrder)
        : reject(equals(key), parallelsForCommonOrder),
    )
  }, [parallelsForCommonOrder, update])

  return {
    parallels: parallelsForCommonOrder,
    loading,
    submitting,
    modified,
    change: handleChange,
    submit,
  }
}
