import { Fragment, ComponentType, ReactNode } from 'react'
import cn from 'classnames'

import IconCross from 'app/common/icons/IconCross'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import { AvailableDisplayName } from './available-display-name'
import { type HandleModalDisplayed } from './use-modal-displayed'

import styles from './plain-modal-header.scss'


type Props = {
  children: ReactNode | ((toggle: HandleModalDisplayed) => ReactNode)
  className?: string
  handleModalDisplayed?: HandleModalDisplayed
  onCrossClick?: () => void
  closeButtonAriaLabel?: string
  doNotCloseDropdown?: boolean
  hideCloseButton?: boolean
  headerComponent?: ComponentType<any> | string
}

const PlainModalHeader = ({
  children,
  className,
  handleModalDisplayed,
  onCrossClick,
  closeButtonAriaLabel = 'Закрыть окно',
  doNotCloseDropdown = true,
  hideCloseButton,
  headerComponent = 'h1',
}: Props): ReactNode => {
  const rootClassName = cn(styles.modalHeader, className)
  const HeaderComponent = headerComponent

  return (
    <Fragment>
      {typeof children !== 'function'
        && <HeaderComponent id="heading" className={rootClassName}>
          {children}
        </HeaderComponent>}

      {typeof children === 'function'
        && handleModalDisplayed
        && <HeaderComponent id="heading" className={rootClassName}>
          {children(handleModalDisplayed)}
        </HeaderComponent>}

      {handleModalDisplayed
        && !hideCloseButton
        && <PlainButtonAsLink
          ariaLabel={closeButtonAriaLabel}
          onClick={onCrossClick || handleModalDisplayed}
          className={cn(styles.close, doNotCloseDropdown ? 'doNotCloseDropdown' : '')}
        >
          <IconCross className={styles.icon} />
        </PlainButtonAsLink>}
    </Fragment>
  )
}

PlainModalHeader.displayName = AvailableDisplayName.PlainModalHeader

export default PlainModalHeader
