import { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import Paging from 'app/common/ui/Paging'
import EmptyWarn from 'app/common/layouts/EmptyWarn'
import { useInitFunc as useRightsByOrgInitFunc } from 'app/rights/RightsByOrg'
import { useOrgsAvailableTypes } from 'app/dataProviders/org/orgTypes/OrgsAvailableTypesProvider'
import { PageTemplateContent } from 'app/common/templates-next/page-template'
import Sticky from 'app/common/templates-next/sticky'

import useOrgsAndRoles, { orgTypes } from './useOrgsAndRoles'
import OrgsListFilterBar from './OrgsListFilterBar'
import FilterWindow from './filter/FilterWindow'
import OrgsListTable from './OrgsListTable/OrgsListTable'
import useOrgsListTableItems from './OrgsListTable/useOrgsListTableItems'
import useOrgsListPagination from './useOrgsListPagination'
import useFilterParams from './useFilterParams'


export type Props = {
  currentOrgType: (typeof orgTypes)[keyof typeof orgTypes]
  getChildPageBaseUrl: (x: { orgId: string, section: string }) => string
}

const OrgsListMain = ({
  currentOrgType,
  getChildPageBaseUrl,
}: Props) => {
  const unknownOrgType = currentOrgType === orgTypes.UNKNOWN
  const excludeDeleted = !!(useSelector((state: any) => state.settings.schools.hideDeleted, shallowEqual))
  const { limit, offset, currentPage, handlePage, resetPagination } = useOrgsListPagination()

  const [search, setSearch] = useState<string>('')
  const [filterActive, setFilterActive] = useState(false)

  const [, loadingTypes] = useOrgsAvailableTypes()

  // параметры для запроса
  const [params, rebuildParams] = useFilterParams({
    limit,
    offset,
    search,
    excludeDeleted,
    currentOrgType,
    unknownOrgType,
  })

  // метод для инициализации прав
  const initRights = useRightsByOrgInitFunc()

  // запрос данных
  const {
    orgs,
    count: total,
    loading: loadingOrgs,
  } = useOrgsAndRoles({ params, resetPagination, onDataFetch: initRights })

  const orgItems = useOrgsListTableItems(orgs, { getChildPageBaseUrl })
  const loading = loadingTypes || loadingOrgs

  return (
    <>
      <Sticky>
        {(ref, stuck, shadow) => (
          <>
            <PageTemplateContent
              reference={ref}
              borderBottom={!filterActive}
              shadowBottom={shadow}
            >
              <OrgsListFilterBar
                onSearch={setSearch}
                onFilterToggle={setFilterActive}
                filterActive={filterActive}
                orgType={currentOrgType}
                onNewOrgAdded={rebuildParams}
              />
            </PageTemplateContent>

            {/* Окно с фильтром */}
            <PageTemplateContent skip={!filterActive} skipPadding>
              {!unknownOrgType
                && <FilterWindow
                  onFilterToggle={setFilterActive}
                  filterActive={filterActive}
                  loading={loading}
                  hideArrow={stuck}
                />}
            </PageTemplateContent>
          </>
        )}
      </Sticky>

      <PageTemplateContent skipPadding>
        {unknownOrgType
          && <EmptyWarn>
            {'Не найдены известные типы заведений'}
          </EmptyWarn>}

        {/* Таблица с данными */}
        {!unknownOrgType
          && <OrgsListTable
            items={orgItems}
            loading={loading}
            orgType={currentOrgType}
          />}
      </PageTemplateContent>

      <Sticky stickyBottom>
        {(ref, _, shadow) => (
          <PageTemplateContent reference={ref} shadowTop={shadow} skipPadding>
            <Paging
              total={total}
              pageSize={limit}
              currentPage={currentPage}
              currentPageSize={orgItems.length}
              onPage={handlePage}
            />
          </PageTemplateContent>
        )}
      </Sticky>
    </>
  )
}

export default OrgsListMain
