import React, { ChangeEvent } from 'react'
import { toPairs } from 'ramda'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'

import Shift from './Shift'


type Props = {
  title: string,
  checked: boolean,
  disabled: boolean,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  data: { [key: string]: boolean },
  dataKey: string,
  getSubTitle?: (key: string) => string,
}

const CheckGroup = ({ title, checked, disabled, onChange, data, dataKey, getSubTitle }: Props) => {
  const mainId = `for_${dataKey}`

  return (
    <>
      <PlainCheckbox
        value={mainId}
        id={mainId}
        label={title}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />

      {checked
        && (
          <Shift>
            {toPairs(data).map(([key, value]) => {
              const id = `${dataKey}.${key}`

              return (
                <PlainCheckbox
                  key={id}
                  value={id}
                  id={id}
                  label={getSubTitle ? getSubTitle(key) : key}
                  disabled={disabled}
                  checked={value}
                  onChange={onChange}
                />
              )
            })}
          </Shift>
        )}
    </>
  )
}

export default CheckGroup
