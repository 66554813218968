/* eslint-disable ft-flow/no-types-missing-file-annotation */
import React from 'react';
import classNames from 'classnames/bind';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import Tooltip from 'app/common/ui/Tooltip';
import IconLightCross from 'app/common/icons/IconLightCross';
import EditableProp from 'app/common/ui/EditableProp';
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog';
import IconRecovery from 'app/common/icons/IconRecovery';

import enhanceCategory from './enhanceCategory';
import type { Lang } from './enhanceCategory';

import styles from './Category.scss';


/**
 * Item для разделов "категории меню", "точки продаж" и других подобных,
 * где есть 2 столбца: "текущие" и "удаленные"
 * Поддерживает изменение имени, выделение и кнопки переноса в противоположный столбец
 */

const cx = classNames.bind(styles);

type Props = {
  handleToggleItem: (e: SyntheticEvent<HTMLElement>) => void,
  onDelete: (id: number) => void,
  onRestore: (id: number) => void,
  handleSave: (params: Object) => void,
  handleEdit: (e: SyntheticEvent<HTMLElement>) => void,
  id: number,
  deleted?: boolean,
  title: string,
  checked: boolean,
  loading: boolean,
  error: string,
  locked: boolean,
  lang: Lang,
  deleteDisabled: boolean,
};

const Category = ({
  handleToggleItem,
  onDelete,
  onRestore,
  handleSave,
  handleEdit,
  id,
  deleted,
  title,
  checked,
  loading,
  error,
  locked,
  lang,
  deleteDisabled,
}: Props) => (<div className={cx({ root: true, loading, active: checked })}>
  {!locked
      && <div className={styles.check}>
        <PlainCheckbox
          label="title"
          onChange={handleToggleItem}
          checked={checked}
          value={id}
          disabled={loading}
          hideLabel
        />
      </div>}
  <div className={styles.name} style={{ minWidth: 0 }}>
    {(deleted || locked)
      ? <div className={styles.text}>{title}</div>
      : <EditableProp
        value={title}
        onSave={handleSave}
        name={id}
        errorText={error}
        onChange={handleEdit}
      />}
  </div>
  {!locked
      && !deleteDisabled
      && <div className={styles.control}>
        {deleted

          ? <ConfirmationDialog
            onConfirm={onRestore}
            text={lang.confirmRestoreText}
            confirmButtonText={lang.restoreButtonText}
            confirmParams={id}
          >
            <a href="restore">
              <Tooltip text={lang.tooltipRestoreText}><IconRecovery className={styles.icon} /></Tooltip>
            </a>
          </ConfirmationDialog>

          : <ConfirmationDialog
            onConfirm={onDelete}
            text={lang.confirmDeleteText}
            confirmButtonText={lang.removeButtonText}
            confirmParams={id}
          >
            <a href="delete">
              <Tooltip text={lang.tooltipDeleteText}><IconLightCross className={styles.icon} /></Tooltip>
            </a>
          </ConfirmationDialog>}
      </div>}
</div>);

export default enhanceCategory(Category);
