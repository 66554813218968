// @flow
import React, { type Node } from 'react';
import cn from 'classnames';

import styles from './LinksTable.scss';


type Props = {
  children: Node,
  className?: string,
}

const LinksTable = ({ children, className }: Props) => {
  return (
    <table className={cn(styles.root, className)}>
      {children}
    </table>
  );
};

export default LinksTable;
