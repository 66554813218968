// @flow
import React from 'react';

import * as types from 'app/common/constants/identifiers/types';

import IconyByType from '../identifiers-icons/icon-by-type';
import styles from './identifier-item.scss';


type Props = {
  title: string,
  type: $Values<typeof types>,
}

const IdentifierItemEmpty = ({ title, type }: Props) => (
  <div className={styles.root}>
    <div className={styles.typePictureColumn}>
      <IconyByType type={type} />
    </div>

    <div className={styles.contentColumn}>
      <div className={styles.empty}>{title}</div>
    </div>
  </div>
);

export default IdentifierItemEmpty;
