// @flow
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import reduce from 'ramda/es/reduce';

import api from 'app/actions/api';
import { getLastFirstM } from 'app/common/lib/names';


export const STUDENTS_FILTER_REQUEST = 'reports/STUDENTS_FILTER_REQUEST';
export const STUDENTS_FILTER_SUCCESS = 'reports/STUDENTS_FILTER_SUCCESS';
export const STUDENTS_FILTER_FAILURE = 'reports/STUDENTS_FILTER_FAILURE';
export const STUDENTS_FILTER_CLEAR = 'reports/STUDENTS_FILTER_CLEAR';
export const STUDENTS_FILTER_DELETE = 'reports/STUDENTS_FILTER_DELETE';
export const STUDENTS_FILTER_SET = 'reports/STUDENTS_FILTER_SET';
export const STUDENTS_FILTER_UPDATE = 'reports/STUDENTS_FILTER_UPDATE';

const studentsFilterRequest = () => ({
  type: STUDENTS_FILTER_REQUEST,
});

const studentsFilterSuccess = (payload: Object) => ({
  type: STUDENTS_FILTER_SUCCESS,
  payload,
});

const studentsFilterFailure = (payload: Object) => ({
  type: STUDENTS_FILTER_FAILURE,
  payload,
});

export const studentsFilterClear = () => ({
  type: STUDENTS_FILTER_CLEAR,
});

export const studentsFilterDelete = (payload: Array<string | number>) => ({
  type: STUDENTS_FILTER_DELETE,
  payload,
});

export const studentsFilterSet = (payload: Object) => ({
  type: STUDENTS_FILTER_SET,
  payload,
});

export const studentsFilterUpdate = (payload: Object) => ({
  type: STUDENTS_FILTER_UPDATE,
  payload,
});

const assembleParams = (orgId: number, classId: ?number, statuses: Array<string>) => {
  if (statuses.length < 2) {
    return ({
      school_id: orgId,
      // $FlowFixMe
      ...(classId ? { klass_id: classId } : {}),
      ...(statuses[0] ? { status: statuses[0] } : {}),
    });
  }

  return statuses.map(status => ({
    school_id: orgId,
    ...(classId ? { klass_id: classId } : {}),
    status,
  }));
};

const convertResult = pipe(
  path(['data', 'students']),
  map((item) => {
    const name = prop('name', item);
    return { ...item, title: getLastFirstM(name) };
  }),
);

export const studentsFilterFetch = (orgId: number, classId: ?number, status: Array<string>) => async (dispatch: Function) => {
  const params = assembleParams(orgId, classId, status);
  dispatch(studentsFilterRequest());
  try {
    if (params instanceof Array) {
      const resArray = await Promise.all(params.map(params_ => api('reports/students_list', 'get', { params: params_ })));
      const res = reduce((acc, value) => ({ ...acc, ...convertResult(value) }), {}, resArray);
      dispatch(studentsFilterSuccess(res));
    } else {
      const res = await api('reports/students_list', 'get', { params });
      dispatch(studentsFilterSuccess(convertResult(res)));
    }
  } catch (error) {
    dispatch(studentsFilterFailure({ err: 'Не удалось получить данные фильтра по ученикам', error }));
  }
};
