// @flow
import { identifiers } from 'app/common/constants/identifiers';
import isProduction from 'app/common/lib/isProduction';

import logo from './images/defLogo.png';
import logoWhite from './images/defLogoWhite.png';

// DEPRECATED

const apiUrlReplace = {
  'new-food2.sms-dnevnik.com': 'https://api2.sms-dnevnik.com/rest/',
  'new-food.sms-dnevnik.com': 'https://api0.sms-dnevnik.com/rest/',
  'new-dining.arhkids.ru': 'https://api.arhkids.ru/rest/',
};

// url для отдельного сервера в киргизии
// Нет тестового варианта
const apiUrlKG = 'https://api.elmektep.kg/rest/';

const config = {
  appType: 'food',
  appName: 'Личный кабинет',
  apiUrlReplace,
  apiUrl: (
    isProduction()
      ? 'https://api.in-shkola.ru/rest/'
      : 'https://api0.sms-dnevnik.com/rest/'
  ),

  apiUrlKG,

  commonError: 'Опс! Что-то пошло не так. Попробуйте позже.',
  copyrightName: 'ООО «Инноват»',
  copyrightYear: new Date().getFullYear(),
  defaultLogo: logo,
  defaultLogoWhite: logoWhite,
  downloadAppStore: '',
  downloadGooglePlay: '',
  updateInterval: 30 * 1000,
  perPageItems: 25, // количество элементов на одной странице
  perPageItemsVars: ( // варианты выбора количества элементов на одной странице
    isProduction()
      ? [25, 50, 100]
      : [5, 25, 50, 100]
  ),
  messageTimeout: 10 * 1000, // время существования сообщения для пользователя
  dateFormat: 'YYYY-MM-DD', // формат даты в адресной строке
  dateFormatPresentative: 'DD.MM.YYYY', // формат даты при отображении данных
  containerWidth: 1100, // ширина основного контейнера в приложении
  requestExpiredTime: 320000, // время повторного запроса (для некоторых данных)
  searchThrottleTime: 2500, // период посылки запросов поиска к апи
  maxFiles: 3, // максимальное количество загружаемых файлов
  maxFileSize: 5242880, // максимальный размер загружаемого файла

  // Типы приложений и их соответстия символьным строкам
  // Возможно их не будет
  applications: {
    all: ['assistance'],
    assistance: { fullName: 'Техническая поддержка', name: 'Поддержка', type: 'Техническая' },
  },

  modalMinWidth: 375,
  dishPreviewHeight: 500,

  // Доступные идентификаторы
  identifiers,

};

export default config;
