import { createSelector } from 'reselect';
import map from 'ramda/es/map';


type Props = {
  items: Array<Object>,
  existingItems: Object, // TODO возможно надо заменить на путь в состоянии и брать оттуда
  addList: Object,
  removeList: Object,
};

const selectFlags = createSelector(
  [
    (_, { items }: Props) => items || [],
    (_, { existingItems }: Props) => existingItems || {},
    (_, { addList }: Props) => addList || {},
    (_, { removeList }: Props) => removeList || {},
  ],
  (items, existingItems, addList, removeList) => ({
    items: map(item => ({
      ...item,
      success: !!existingItems[item.id],
      checked: existingItems[item.id]
        ? !removeList[item.id]
        : !!addList[item.id],
    }), items),
  }),
);

export default selectFlags;
