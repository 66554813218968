// @flow
import React from 'react';
import values from 'ramda/es/values';
import identity from 'ramda/es/identity';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames/bind';

import PlainBadge from 'app/common/ui/PlainBadge';
import nope from 'app/common/lib/nope';

import styles from './PlainFilter.scss';


const rowHeight = 36;
const cx = classNames.bind(styles);

type FilterItemProps = {
  id: string | number,
  title: string,
}


const badges = (selectedItems, onClose, isSelectedAllState, light, someProps) => {
  if (!selectedItems && !isSelectedAllState) return null;
  if (isSelectedAllState) {
    return (
      <PlainBadge
        key="all"
        id="all"
        title="Все"
        onClose={onClose}
        light={light}
        // $FlowFixMe
        {...someProps}
      />
    );
  }

  const items = (selectedItems instanceof Array
    ? selectedItems
    : values(selectedItems));
  return items.filter(identity).map(props => (
    <PlainBadge
      key={props.id}
      onClose={onClose}
      light={light}
      // $FlowFixMe
      {...props}
      {...someProps}
    />
  ));
};

type Props = {
  title: string,
  className?: string,
  childrenClassName?: string,
  titleClassName?: string,
  selectedItems: Array<FilterItemProps> | { [string]: mixed },
  isSelectedAllState?: boolean,
  children?: any,
  maxRows: number,
  loading?: boolean,
  noData?: boolean,
  hideNoData?: boolean,
  noDataText?: string,
  onReset?: (e: SyntheticEvent<HTMLElement>) => void,
  onItemRemove?: (e: SyntheticEvent<HTMLButtonElement>) => void,
  light?: boolean,
  disabled?: boolean,
  badgeProps?: { [string]: mixed },
  errorText?: string,
  linkAfterBadge?: boolean,
  ariaLabelledby?: string,
}

const PlainFilter = ({
  title,
  className,
  childrenClassName,
  titleClassName,
  selectedItems,
  isSelectedAllState,
  children,
  maxRows,
  loading,
  noData,
  hideNoData,
  noDataText,
  onReset,
  onItemRemove,
  light,
  disabled,
  badgeProps,
  errorText,
  linkAfterBadge,
  ariaLabelledby,
}: Props) => (
  <div
    aria-labelledby={ariaLabelledby}
    className={cx({ reverseRoot: linkAfterBadge }, className)}
  >
    <div className={styles.headerBlock}>
      {title
        && <div className={cx(styles.titleItem, { disabled }, titleClassName)}>{title}</div>}

      {!linkAfterBadge
        && !disabled
        && <div className={cx(styles.titleItem, childrenClassName)}>{children}</div>}

      {disabled && <div className={styles.disabled}>{'\u00A0\u00A0\u00A0\u00A0неактивно'}</div>}

      {onReset
        && !noData
        && !loading
        && !disabled
        && <div>
          <a href="reset" className={styles.resetLink} onClick={onReset}>
            {'Сбросить выбор'}
          </a>
        </div>}
    </div>
    <div className={cx(styles.badges, { space: linkAfterBadge, loading, disabled })}>
      {!hideNoData
        && noData
        && !loading
        && <PlainBadge
          id="empty"
          title={noDataText || 'Нет данных'}
          error
        />}
      {(maxRows && !noData)
        ? <Scrollbars
          autoHeight
          autoHeightMin={0}
          autoHeightMax={maxRows * rowHeight}
        >
          <div>
            {badges(
              selectedItems,
              loading || disabled ? nope : onItemRemove,
              isSelectedAllState,
              light,
              badgeProps,
            )}
          </div>
        </Scrollbars>
        : badges(
          selectedItems,
          loading || disabled ? nope : onItemRemove,
          isSelectedAllState,
          light,
          badgeProps,
        )}
    </div>
    {linkAfterBadge
      && !disabled
      && <div
        className={cx(styles.childrenAfter, { reverseTypeSpaces: linkAfterBadge }, childrenClassName)}
      >
        {children}
      </div>}
    {errorText && <div className={styles.error}>{errorText}</div>}
  </div>
);

export default PlainFilter;
