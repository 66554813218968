// @flow
import React, { useState, useCallback } from 'react';

import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';
import PlainButton from 'app/common/ui-next/plain-button';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';

import styles from './GreenLinkRow.scss';


type Props = {
  fio: string,
  onDeleteLink: () => Promise<boolean>,
}

const GreenLinkRow = ({ fio, onDeleteLink }: Props) => {
  const [confirm, setConfirm] = useState(false);

  const openConfirm = useCallback(() => setConfirm(true), []);
  const closeConfirm = useCallback(() => setConfirm(false), []);

  const clickConfirm = useCallback(async () => {
    const res = await onDeleteLink();
    if (res) {
      closeConfirm();
    }
  }, [onDeleteLink, closeConfirm]);


  return (
    <tr>
      <td className={styles.icon}>
        <PlainIcon type={iconTypes.USER_LINK_GREEN} />
      </td>

      <td className={styles.title}>
        {'WEB-приложение «Личный кабинет сотрудника ОУ»'}
      </td>

      <td>{fio}</td>

      <td>
        <PlainButton onClick={openConfirm} danger compact>
          {'Убрать связь'}
        </PlainButton>
        {confirm
          && <PlainConfirmModal
            messageText="Вы уверены? Удалить связь?"
            confirmButtonTitle="Удалить"
            onConfirm={clickConfirm}
            onCancel={closeConfirm}
            critical
          />}
      </td>
    </tr>
  );
};

export default GreenLinkRow;
