
import {
  SALES_SET_REPORT_ID,
  SALES_SET_XLS_FILE_ID,
  SALES_RESET_PARAMS,
  SALES_VIEW_PARAMS_CREATE,
} from 'app/actions/reports/sales';


const defaultState = {
  reportId: 0,
  xlsFileId: 0,
  enableReportDownload: false,
  groupType: null,
};

const params = (state = defaultState, { type, payload }) => {
  if (type === SALES_RESET_PARAMS) {
    return defaultState;
  }
  if (type === SALES_SET_REPORT_ID) {
    return { ...state, reportId: payload, xlsFileId: 0 };
  }
  if (type === SALES_SET_XLS_FILE_ID) {
    return { ...state, xlsFileId: payload };
  }
  if (type === SALES_VIEW_PARAMS_CREATE) {
    return { ...state, ...payload };
  }
  return state;
};

export default params;
