import { useCallback, useMemo, useState } from 'react'
import { T } from 'ramda'

import PlainSelector, { PlainSelectorStatic } from 'app/common/ui-next/plain-selector'
import { useOrgsData } from 'app/dataProviders/org/hooks'
import { useOrgGroups } from 'app/dataProviders/org/orgGroup/hooks'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import { OrgType, isOrgType } from 'app/dataTypes/org/types'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { Org } from 'app/dataTypes/org'

import HTMLSelect, { emptyFunc } from './HTMLSelect'
import OrgsSelectorModal from './OrgsSelectorModal'
import useFilter from './useFilter'
import { sortOrgsByTitle } from './utility/sortOrgs'


type Props = {
  className?: string
  orgType?: string
  name?: string
  disabled?: boolean
  label?: string
  header?: string
  colorInverse?: boolean
  multiple?: boolean
  buttonResetLabel?: string
  buttonSelectLabel?: string
  selection?: Array<string>
  defaultSelection?: Array<string>
  selectedAllOnEmptySelection?: boolean
  pleaseSelectText?: string
  skipUpperSpace?: boolean
  orgs?: Map<string, Org>
  onChange?: (values: Array<string>) => void
  filterFunc?: (org: Org) => boolean
}

const OrgsAndGroupsSelector = ({
  className,
  orgType,
  name,
  disabled,
  label = 'Выбор',
  header = 'Выберите',
  colorInverse,
  multiple,
  buttonResetLabel,
  buttonSelectLabel = 'Выбрать',
  selection,
  defaultSelection,
  selectedAllOnEmptySelection = true,
  pleaseSelectText,
  skipUpperSpace,
  orgs: externalOrgs,
  onChange,
  filterFunc = T,
}: Props) => {
  const validOrgType = isOrgType(orgType) ? orgType as OrgType : undefined
  const { orgs, loading: orgsLoading } = useOrgsData({
    orgType: validOrgType,
    deleted: false,
  }, externalOrgs)
  const sortedOrgs = sortOrgsByTitle(orgs)
  const orgsMap = useFilter(filterFunc, sortedOrgs.items)

  const { orgGroupsByOrgType, loading: orgGroupsLoading } = useOrgGroups()
  const loading = orgsLoading || orgGroupsLoading

  const onChangeRef = useCallbackRef(onChange)

  const itemsList = useMemo(() => {
    return Array.from(orgsMap.values())
  }, [orgsMap])

  const handleMakeTitle = useCallback((ID: string) => {
    return orgsMap.get(ID)?.title || 'Неизвестный элемент'
  }, [orgsMap])

  const [modalVisible, setModalVisible] = useState(false)
  const handleBadgeClickCross = useCallback((ID: string, removeCb: (ID: string) => void) => {
    if (!multiple) {
      setModalVisible(true)
      return
    }
    removeCb(ID)
  }, [multiple])

  if (!loading && selectedAllOnEmptySelection && itemsList.length <= 1) {
    const { ID, title } = itemsList.values().next().value
    return (
      <>
        <PlainSelectorStatic
          className={className}
          onChange={onChange}
          label={label}
          id={ID}
          title={title}
          colorInverse={colorInverse}
          disabled={disabled}
          skipUpperSpace={skipUpperSpace}
        />
        <HTMLSelect
          name={name}
          selection={selection}
          multiple={multiple}
          items={itemsList}
        />
      </>
    )
  }

  return (
    <>
      <PlainSelector
        className={className}
        skipUpperSpace={skipUpperSpace}
        loading={loading}
        disabled={disabled}
        label={label}
        buttonResetLabel={buttonResetLabel}
        colorInverse={colorInverse}
        selection={selection}
        defaultSelection={defaultSelection}
        onChange={onChangeRef.current || emptyFunc}
        makeSortable={handleMakeTitle}
        makeTitle={handleMakeTitle}
        selectedAllOnEmptySelection={selectedAllOnEmptySelection}
        onBadgeCrossClick={handleBadgeClickCross}
        pleaseSelectText={pleaseSelectText}
      >
        {(itemsSelected, update) => (
          <OrgsSelectorModal
            show={modalVisible}
            header={header}
            orgItems={orgsMap}
            orgsGroupItems={validOrgType ? orgGroupsByOrgType[validOrgType] : undefined}
            onChange={update}
            multiple={multiple}
            itemsSelected={itemsSelected}
          >
            {toggle => (
              <PlainButtonAsLink onClick={toggle} disabled={disabled}>
                {buttonSelectLabel}
              </PlainButtonAsLink>
            )}
          </OrgsSelectorModal>
        )}
      </PlainSelector>

      <HTMLSelect
        name={name}
        selection={selection}
        multiple={multiple}
        items={itemsList}
      />
    </>
  )
}

export default OrgsAndGroupsSelector
