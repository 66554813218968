// @flow
import React, { forwardRef, type Node } from 'react';
import classNames from 'classnames/bind';
import split from 'ramda/es/split';
import intersperse from 'ramda/es/intersperse';

import isProduction from 'app/common/lib/isProduction';

import styles from './plain-label.scss';


const cx = classNames.bind(styles);

export type Props = {
  children: Node,
  id?: string,
  htmlFor?: string,
  className?: string,
  disabled?: boolean,
  skipUpperSpace?: boolean,
  displayAsBlock?: boolean,
  hidden?: boolean,
}

const PlainLabel = forwardRef(({
  children,
  id,
  htmlFor,
  className,
  disabled,
  skipUpperSpace,
  displayAsBlock,
  hidden,
}: Props, ref) => {
  if (!isProduction() && !htmlFor && !id) {
    console.error('Не указан один из обязательных параметров `htmlFor` или `id`');
  }

  return (
    <label
      id={id}
      htmlFor={htmlFor}
      ref={ref}
      className={cx(styles.root, { disabled, skipUpperSpace, displayAsBlock, hidden }, className)}
    >
      {typeof children === 'string'
        ? intersperse('###', split('<br />', children)).map((str, i) => (
          str === '###'
            ? <br key={i} />
            : str
        ))
        : children}
    </label>
  );
});

export default PlainLabel;
