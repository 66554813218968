// @flow
import React from 'react';

import { Translation } from 'react-i18next';

import styles from './Loader.scss';


type State = {
  progress: number,
};

type Props = {
  loading: boolean,
  error: boolean,
};


class Loader extends React.Component<Props, State> {
  state = {
    progress: 0,
  };

  componentDidMount() {
    let i = 2;
    this.timer = setInterval(() => {
      const { progress } = this.state;
      i = (i > 0 ? i - 0.05 : 0.05);
      if (progress < 80) {
        this.setState(({ progress: pg }) => ({ progress: pg === 0 ? 40 : pg + i }));
      } else {
        clearInterval(this.timer);
      }
    }, 25);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  timer: IntervalID;

  props: Props;

  render() {
    const { error, loading } = this.props;
    const { progress } = this.state;
    return (
      <div
        className={error ? styles.error : styles.root}
        style={{ width: `${loading && !error ? progress : 100}%` }}
      >
        {error
          && <Translation>
            {t => <span>{t('connect_error_app')}</span>}
          </Translation>}
      </div>
    );
  }
}

export default Loader;
