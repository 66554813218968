// @flow
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames/bind';

import ListItem from './ListItem';
import ViewTitleAsArray from './ViewTitleAsArray';
import styles from './PlainList.scss';


const cx = classNames.bind(styles);

const list = (data, onChange, current) => data.map(({ id, title }) => (<ListItem
  value={title instanceof Array ? <ViewTitleAsArray title={title} /> : title}
  id={id}
  key={id}
  onClick={onChange}
  isCurrent={id === current}
/>));

type Data = {
  id: string | number,
  title: string,
}

type Props = {
  data: Array<Data>,
  onChange: (value: number | string) => void,
  current?: string | number,
  reference: Function,
  roundCorners: 'top' | 'bottom',
}

const PlainList = ({ data, onChange, current, reference, roundCorners }: Props) => (
  <div
    className={cx(styles.root, {
      roundTop: roundCorners === 'top',
      roundBottom: roundCorners === 'bottom',
    })}
    ref={reference}
  >
    {data && data.length > 10
      ? <Scrollbars style={{ width: '100%', height: 290, display: 'block' }}>
        {list(data, onChange, current)}
      </Scrollbars>
      : null}

    {data && data.length && (data.length <= 10)
      ? list(data, onChange, current)
      : null}

    {!(data && data.length) && <ListItem value="Нет значений" id={-1} />}
  </div>
);

export default PlainList;
