import { useMemo } from 'react'
import cn from 'classnames/bind'
import { range } from 'ramda'

import SkeletonItem from './skeleton-item'
import styles from './skeleton-loading.scss'


const cx = cn.bind(styles)

type Props = {
  rows?: number
  minWidthPercent?: number
  maxWidthPercent?: number
  skipPadding?: boolean
  className?: string
  align?: 'left' | 'right' | 'center'
  itemHeight?: number
  itemWidth?: 'random' | number
}

const SkeletonLoading = ({
  rows = 5,
  minWidthPercent = 50,
  maxWidthPercent = 100,
  skipPadding,
  className,
  align = 'left',
  itemHeight = 17.25,
  itemWidth = 'random',
}: Props) => {
  const items = useMemo(() => {
    return range(0, rows).map(index => ({
      index,
      width: typeof itemWidth === 'number'
        ? `${itemWidth}%`
        : `${Math.floor(Math.random() * (maxWidthPercent - minWidthPercent + 1)) + minWidthPercent}%`,
    }))
  }, [rows, minWidthPercent, maxWidthPercent, itemWidth])

  return (
    <div className={cx(styles.root, { padding: !skipPadding }, className)}>
      {items.map(({ index, width }) => (
        <SkeletonItem
          key={index}
          className={cx(styles.item, { [align]: true })}
          style={{ width, height: `${itemHeight}px` }}
        />
      ))}
    </div>
  )
}

export default SkeletonLoading
