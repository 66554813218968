// @flow
import { createContext } from 'react';

import { type Descriptions, defaultDescriptions } from './filter-description';
import { type State, emptyState } from './filter-state';
import { type AppliedState, defaultAppliedState } from './filter-state-applied';
import { type Actions, defaultActions } from './use-filter';


export type FilterContextType<Params> = [
  AppliedState,
  State,
  Descriptions<Params>,
  Actions,
  Params,
]

type Params = mixed

const defaultContext: FilterContextType<Params> = [
  defaultAppliedState,
  emptyState,
  defaultDescriptions,
  defaultActions,
  undefined,
];

export default createContext<FilterContextType<Params>>(defaultContext);
