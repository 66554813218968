// @flow
import React, { useMemo, useCallback, useState } from 'react';
import assoc from 'ramda/es/assoc';
import values from 'ramda/es/values';
import sort from 'ramda/es/sort';
import pipe from 'ramda/es/pipe';
import replace from 'ramda/es/replace';
import test from 'ramda/es/test';
import { useDispatch } from 'react-redux';

import PlainInput from 'app/common/ui/PlainInput';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import { popup } from 'app/common/actions/popup';
import { priceUnsignedRe } from 'app/common/constants/regex';

import useBalance from './use-balance';
import styles from './fund-return.scss';


type FieldsType = {
  sum: string,
  comment: string,
}

const defaultFieldsState = {
  sum: '',
  comment: '',
};

type Props = {
  customerId: number,
  onRefund?: () => void,
}

const FundReturn = ({ customerId, onRefund }: Props) => {
  const dispatch = useDispatch();

  const params = useMemo(() => ({ customer_id: customerId }), [customerId]);
  const [
    [{ available_for_refund: available, balance }, loading],
    actions,
  ] = useBalance(params, onRefund);

  const [refunding, setRefunding] = useState(false);

  const [fields, setFields] = useState<FieldsType>(defaultFieldsState);
  const [errors, setErrors] = useState<FieldsType>(defaultFieldsState);

  const hasErrors = useCallback(() => {
    const { sum, comment } = fields;
    const sumNum = parseFloat(sum);
    let newErrors = defaultFieldsState;
    if (!available) {
      newErrors = assoc('sum', 'Доступная сумма для возврата равна 0 или не установлена', newErrors);
    } else if (!sumNum || sumNum < 0 || sumNum > available) {
      newErrors = assoc('sum', 'Необходимо указать сумму равную или меньше суммы доступной для возврата', newErrors);
    }
    if (!comment) {
      newErrors = assoc('comment', 'Необходимо указать комментарий', newErrors);
    }

    if (newErrors.sum || newErrors.comment) {
      setErrors(newErrors);
      return true;
    }
    return false;
  }, [available, fields]);

  const handleRefund = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { sum, comment } = fields;
    if (hasErrors()) return;
    setRefunding(true);
    const res = await actions.refund({
      comment,
      customer_id: customerId,
      sum: parseFloat(replace(',', '.', sum)),
    });
    setRefunding(false);
    if (res) {
      dispatch(popup(`Средства успешно возвращены (${sum}₽)`));
      setFields(defaultFieldsState);
    }
  }, [fields, customerId, actions, dispatch, hasErrors]);

  const handleChange = useCallback((name: string) => (e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setErrors(defaultFieldsState);
    if (name === 'sum') {
      const replaced = replace(',', '.', value);
      if (!test(priceUnsignedRe, replaced)) return;
    }
    setFields(assoc(name, value));
  }, []);

  const viewErrors = useMemo(() => pipe(
    values,
    sort((a, b) => {
      if (!!a === !!b) return 0;
      if (a) return -1;
      return 1;
    }),
  )(errors), [errors]);

  return (
    <div className={styles.root}>
      <ControlsBar className={styles.controlsBar}>
        <PlainInput
          className={styles.col1}
          label="Текущий баланс:"
          value={balance}
          hideErrorString
          loading={loading}
          disabled
        />
        <PlainInput
          className={styles.col2}
          label="Доступный к возврату баланс:"
          value={available}
          hideErrorString
          loading={loading}
          disabled
        />
      </ControlsBar>
      <form onSubmit={handleRefund}>
        <ControlsBar className={styles.controlsBar}>
          <PlainInput
            className={styles.col1}
            onChange={handleChange('sum')}
            label="Сумма возврата:"
            value={fields.sum}
            hideErrorString
            isError={Boolean(errors.sum)}
            loading={loading}
            disabled={refunding}
          />
          <PlainInput
            className={styles.col2}
            onChange={handleChange('comment')}
            label="Комментарий:"
            value={fields.comment}
            hideErrorString
            isError={Boolean(errors.comment)}
            loading={loading}
            disabled={refunding}
          />
          <PlainButton disabled={loading} type="submit">
            {'Вернуть'}
          </PlainButton>
        </ControlsBar>
      </form>
      {viewErrors.map((err, i) => (
        <PlainHelperText key={i} className={styles.error} error>
          {err}
        </PlainHelperText>
      ))}
    </div>
  );
};

export default FundReturn;
