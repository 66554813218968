import {
  SUBSIDYSPLIT_FILTER_SET,
  SUBSIDYSPLIT_FILTER_DEFAULT,
  SUBSIDYSPLIT_FILTER_UPDATE,
} from 'app/actions/reports/filters/subsidySplit';


const defaultState = {
  split: false,
  active: false,
};

const subsidySplit = (state = defaultState, { type, payload }) => {
  if (type === SUBSIDYSPLIT_FILTER_SET) {
    return { ...state, split: payload };
  }
  if (type === SUBSIDYSPLIT_FILTER_UPDATE) {
    return { ...state, ...payload };
  }
  if (type === SUBSIDYSPLIT_FILTER_DEFAULT) {
    return defaultState;
  }
  return state;
};

export default subsidySplit;
