// @flow
import reduce from 'ramda/es/reduce';
import keys from 'ramda/es/keys';


type DefProps = {
  width?: number | string,
  height?: number | string,
}

// заменяются отсутствующие или пустые поля на defaults
// убрираются поля с `auto` у width и height, так как `auto` ведет себя как 100%
const mixPropsWithDefaults = (defProps: DefProps, props: Object) => {
  return reduce((acc, val) => {
    if (acc[val] === undefined || acc[val] === null) {
      acc[val] = defProps[val];
    }
    if (val === 'width' || val === 'height') {
      if (acc[val] === 'auto') {
        delete acc[val];
      }
    }
    return acc;
  }, { ...props }, keys(defProps));
};

export default mixPropsWithDefaults;
