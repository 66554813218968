// @flow
import prop from 'ramda/es/prop';
import set from 'ramda/es/set';
import lensProp from 'ramda/es/lensProp';

import api from 'app/actions/api';
import getRandomExpired from 'app/common/lib/expired';


export const CURRENT_ORG_REQUEST = 'CURRENT_ORG_REQUEST';
export const CURRENT_ORG_SUCCESS = 'CURRENT_ORG_SUCCESS';
export const CURRENT_ORG_FAILURE = 'CURRENT_ORG_FAILURE';

/**
* Получение данных о текущей организации
*/
export const currentOrgRequest = () => ({
  type: CURRENT_ORG_REQUEST,
});

export const currentOrgSuccess = (payload: Object) => ({
  type: CURRENT_ORG_SUCCESS,
  payload,
});

export const currentOrgFailure = (payload: Object) => ({
  type: CURRENT_ORG_FAILURE,
  payload,
});

type ParamsProps = {
  id: string,
  'include[]'?: string | Array<string>,
};

export const currentOrgFetch = (params: ParamsProps) => async (dispatch: Function, getState: Function) => {
  const state = getState();
  const id = prop('id', params);
  if (state.orgs.current.schools[id] && state.orgs.current.expired > Date.now()) return;
  dispatch(currentOrgRequest());
  try {
    const res = await api('food/schools', 'get', { params });
    const data = prop('data', res) || {};
    dispatch(currentOrgSuccess(set(lensProp('expired'), getRandomExpired(), data)));
  } catch (error) {
    dispatch(currentOrgFailure({ err: 'Запрос данных образовательной организации не удался', error }));
  }
};
