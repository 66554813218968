// @flow
import zipWith from 'ramda/es/zipWith';
import find from 'ramda/es/find';
import not from 'ramda/es/not';

import { GET_MENU_SALE_POINTS, type MenuSalePointsResult } from 'app/common/api/requests/food/schools/sale_points';
import { api } from 'app/api';

import extractActualIds from './extractActualIds';

/**
 *  Запрос всех доступных точек продаж для организации
 */

export type SellPointsByBuildingIds = Array<{
  buildingId: number | null,
  sellPointIds: Array<number | null>,
}>

// конвертация результата запроса
const convertResult = (
  buildingIds: Array<number> | [null],
  results: Array<MenuSalePointsResult>,
): SellPointsByBuildingIds => zipWith(
  (buildingId: number, sellPointsRes: MenuSalePointsResult) => ({
    buildingId,
    sellPointIds: extractActualIds(sellPointsRes, null),
  }),
  buildingIds,
  results,
);

// main
const requesAllSellPoints = async (
  orgId: number,
  buildingIds: Array<number> | [null],
): Promise<SellPointsByBuildingIds | null> => {
  const results = await Promise.all(buildingIds.map(buildingId => api.request(GET_MENU_SALE_POINTS, {
    requestPathParams: { orgId },
    params: buildingId ? { building_id: buildingId } : {},
  })));
  if (find(not, results)) {
    return null;
  }
  return convertResult(buildingIds, ((results: any): Array<MenuSalePointsResult>));
};

export default requesAllSellPoints;
