// @flow
import type { Request } from '../../index';


export type RequestParams = {
  school_id_from: number, // школа из которой копировать
  school_id_to: number, // целевая школа
  items_ids?: Array<number> // опционально id продукции (блюд)
}

export type RequestResult = 'success';

export const POST_COPY_ITEMS_BETWEEN_SCHOOLS: Request<
  RequestParams,
  RequestResult,
  void
> = [
  'food/items/copy_items_between_schools',
  'post',
  ['data', 'result'],
];
