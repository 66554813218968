// @flow
import VendingDataProvider from './VendingDataProvider';
import * as vendingStatuses from './status/vendingStatuses';
import type { Machine, VendingMachinesState } from './machines/useVendingMachines';
import type { State as VendingMachinesParamsState } from './machines/useVendingMachinesParams';
import type { ModelsState } from './models/useModels';
import type { VersionsState } from './versions/useVersions';

/**
 *  Вендинговые аппараты
 */

import {
  useVendingStatus,
  useVendingMachines,
  useVendingMachinesParams,
  useVendingMachineSingle,
  useMachineForm,
  useMachineValidate,
  useMachineSubmit,
  useMachineRequest,
  useMachineDelete,
  useRelatedOrgs,
  useAvailableOrgs,
  useModels,
  useVersions,
} from './vendingDataHooks';


export default VendingDataProvider;

export {
  vendingStatuses,
  useVendingStatus,
  useVendingMachines,
  useVendingMachinesParams,
  useVendingMachineSingle,
  useMachineForm,
  useMachineValidate,
  useMachineSubmit,
  useMachineRequest,
  useMachineDelete,
  useRelatedOrgs,
  useAvailableOrgs,
  useModels,
  useVersions,
};

export type {
  Machine,
  VendingMachinesState,
  VendingMachinesParamsState,
  ModelsState,
  VersionsState,
};
