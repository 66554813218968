// @flow
import React, { type Node } from 'react';

import styles from './menu-items.scss';


type Props = {
  children: Node,
}

const MenuItems = ({ children }: Props) => (
  <ul className={styles.root}>
    {children}
  </ul>
);

export default MenuItems;
