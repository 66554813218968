// @flow
import React from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';

import AddMachineModal from './addMachine/AddMachineModal';


const VendingAddMachineBar = () => {
  return (
    <ControlsBar right>
      <AddMachineModal>
        {toggle => (
          <PlainButton onClick={toggle}>
            {'Добавить аппарат'}
          </PlainButton>
        )}
      </AddMachineModal>
    </ControlsBar>
  );
};

export default VendingAddMachineBar;
