// @flow
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import prop from 'ramda/es/prop';
import head from 'ramda/es/head';
import sort from 'ramda/es/sort';
import map from 'ramda/es/map';
import evolve from 'ramda/es/evolve';
import replace from 'ramda/es/replace';
import descend from 'ramda/es/descend';
import pipe from 'ramda/es/pipe';
import toString from 'ramda/es/toString';

import { defaultStateDataCurrent, type State } from 'app/common/ui-components/filter';
import { GET_SCHOOLYEARS } from 'app/common/api/requests/reports/schoolyears_list';
import { api } from 'app/api';

import type { FilterParams } from './FIlterParams';


const filterGetItemsYears = async (_: State, params?: FilterParams) => {
  const orgId = prop('orgId', params || {});

  if (orgId) {
    const res = await api.request(GET_SCHOOLYEARS, {
      error: 'Не удалось получить данные учебных лет',
      params: {
        school_id: orgId,
      },
    });

    if (res && !isEmpty(res)) {
      const items = pipe(
        values,
        sort(descend(prop('title'))), // сортировка только для selected, в окно выбора items попадают объектом
        map(evolve({ title: replace(/-/, '–') })), // нормальные тире
      )(res);

      const selected = pipe(
        head,
        prop('id'),
        toString,
      )(items);
      return ([items, [selected]]);
    }
  }
  return [[], defaultStateDataCurrent];
};

export default filterGetItemsYears;
