
// @flow
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import pipe from 'ramda/es/pipe';
import propSatisfies from 'ramda/es/propSatisfies';
import values from 'ramda/es/values';
import unnest from 'ramda/es/unnest';
import keys from 'ramda/es/keys';

import { popup } from 'app/common/actions/popup';
import { api } from 'app/api';
import { type CopyMenuJob, POST_COPY_MENU_JOBS } from 'app/common/api/requests/food/menu/copy_menu_between_schools';
import { type OrgsSchools } from 'app/dataProviders/org/OrgsDataProvider';
import { GET_MENU_SALE_POINTS, type MenuSalePointsResult } from 'app/common/api/requests/food/schools/sale_points';

import type { Checks } from '../CopyMenuFromToOrgs';


const nonEmptyFields = (value: any) => (value !== null && value !== undefined);
const formId = 'copyTo';

const getActualSalePoints = (salePoints: MenuSalePointsResult | null): Array<number> => {
  if (!salePoints) return [];

  return pipe(
    values,
    filter(propSatisfies(deleted => !deleted, 'deleted')),
    map(prop('id')),
  )(salePoints);
};

type Props = {
  onClose: (void) => void,
  onSubmitting?: (status: boolean) => void,
  orgId: number,
  currentDate: string | null,
  date: string,
  email: string,
  optionsChecks: Checks,
  orgsSelected: Array<string>,
  availableOrgs: OrgsSchools,
  sellPointIdFrom: number | null,
  validate: (any) => boolean,
}

const useHandles = ({
  onClose,
  onSubmitting,
  orgId,
  currentDate,
  date,
  email,
  optionsChecks,
  orgsSelected,
  availableOrgs,
  sellPointIdFrom,
  validate,
}: Props) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);


  const requestSalePoints = useCallback(async (id: string): Promise<Array<number> | null> => {
    const res = await api.request(GET_MENU_SALE_POINTS, {
      error: 'Не удалось загрузить точки продаж',
      requestPathParams: { orgId: id },
    });
    return getActualSalePoints(res);
  }, []);


  // отправка запроса на копирование
  const handleSubmit = useCallback(async (e: SyntheticInputEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id } = e.target;
    // эта ли форма сабмитится
    if (id !== formId) {
      return;
    }

    if (!validate({ currentDate, orgsSelected })) return;

    setSaving(true);
    if (onSubmitting) {
      onSubmitting(true);
    }

    const targetOrgs = isEmpty(orgsSelected) ? keys(availableOrgs) : orgsSelected;
    const sPointFrom: number | void = sellPointIdFrom || (await requestSalePoints(String(orgId)) || [])[0];

    const fromProps: {
      date_from: string,
      from_sale_point_id?: number,
      school_id_from?: number,
    } = {
      date_from: date,
      ...(sPointFrom ? { from_sale_point_id: sPointFrom } : { school_id_from: orgId }),
    };

    const salePoints: Array<Array<number>> = [];
    for (const orgTo of targetOrgs) { // eslint-disable-line no-restricted-syntax
      const points = await requestSalePoints(orgTo) || []; // eslint-disable-line no-await-in-loop
      salePoints.push(points);
    }

    const copyMenuJobs: Array<CopyMenuJob> = map((sPointTo: number) => {
      const res = filter(nonEmptyFields, ({
        ...fromProps,
        date_to: currentDate,
        to_sale_point_id: sPointTo,
        items: optionsChecks.copyitems,
        complexes: optionsChecks.copykits,
        copy_settings: optionsChecks.copysettings,
      }));
      return res;
    }, unnest(salePoints));

    const res = await api.request(POST_COPY_MENU_JOBS, {
      error: 'Не удалось скопировать меню',
      params: email && copyMenuJobs.length > 1
        ? { jobs: copyMenuJobs, email }
        : { jobs: copyMenuJobs },
    });

    setSaving(false);
    if (onSubmitting) {
      onSubmitting(false);
    }

    if (res) {
      dispatch(popup('Меню было успешно скопировано'));
      onClose();
    }
  }, [
    dispatch,
    orgsSelected,
    currentDate,
    orgId,
    date,
    email,
    onClose,
    optionsChecks,
    onSubmitting,
    sellPointIdFrom,
    availableOrgs,
    requestSalePoints,
    validate,
  ]);


  return {
    handleSubmit,
    saving,
    formId,
  };
};

export default useHandles;
