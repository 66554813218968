// @flow
import pipe from 'ramda/es/pipe';
import defaultTo from 'ramda/es/defaultTo';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';

import {
  type ReportsDataOrderPurchasesResult,
  type Purchase,
} from 'app/common/api/requests/reports/data.orders_purchases';

import toFixedValue from 'app/common/lib/toFixedValue';


const defaultToArray = defaultTo([]);

export type PurchaseItem = {
  id: number,
  title: string,
  cost: string,
  count: string,
  total: string,
}

const apiResult2PurchaseItems: (ReportsDataOrderPurchasesResult) => Array<PurchaseItem> = pipe(
  prop('purchases'),
  defaultToArray,
  map(({ id, title, cost, count, total }: Purchase): PurchaseItem => ({
    id,
    title: title || '???',
    cost: toFixedValue(cost),
    count: typeof count === 'number' ? String(count) : '—',
    total: toFixedValue(total),
  })),
);

export default apiResult2PurchaseItems;
