
import pathSatisfies from 'ramda/es/pathSatisfies';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';


export default branch(
  pathSatisfies(dt => !dt, ['info', 'datetime']),
  renderNothing,
);
