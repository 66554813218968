// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';
import { studentSubsidyTypes } from 'app/dataTypes/reports/studentSubsidyTypes';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const onlyWithSubsidiesSelected = (state: State) => {
  const studentSubsidyTypeSelected = getStateCurrentValue(filterId, state);
  return (
    studentSubsidyTypeSelected.length === 1
    && studentSubsidyTypeSelected[0] === studentSubsidyTypes.WithSubsidies
  );
};

export const onlyWithoutSubsidiesSelected = (state: State) => {
  const studentSubsidyTypeSelected = getStateCurrentValue(filterId, state);
  return (
    studentSubsidyTypeSelected.length === 1
    && studentSubsidyTypeSelected[0] === studentSubsidyTypes.WithoutSubsidies
  );
};
