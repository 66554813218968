// @flow
import React, { memo } from 'react';

import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import FiltersPlainLayout, { Column } from 'app/common/layouts/FiltersPlainLayout';
import { FilterItem, useFilterData } from 'app/common/ui-components/filter';

import ResetFilterLink from 'app/components/common/reports/ResetFilterLink';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';

import orgTypesFilter from 'app/components/common/reports/filters/orgType';
import orgsFilter from 'app/components/common/reports/filters/org';
import yearFilter from 'app/components/common/reports/filters/year';
import buildingFilter from 'app/components/common/reports/filters/building';
import sellPointFilter from 'app/components/common/reports/filters/sellPoint';
import customerTypeFilter from 'app/components/common/reports/filters/customerType';
import classFilter from 'app/components/common/reports/filters/class';
import detailsFilter from 'app/components/common/reports/filters/details';
import sellTypeFilter from 'app/components/common/reports/filters/sellType';
import mailFilter from 'app/components/common/reports/filters/mail';
import foodModeFilter from 'app/components/common/reports/filters/foodMode';

import showDataFilter from './filters/showData';
import groupFilter from './filters/group';
import periodFilter from './filters/period';

import styles from './ReportFilter.scss';


type Props = {
  loading?: boolean,
}

const ReportFilter = memo<Props>(({ loading }: Props) => {
  const [,,, { resetAll, applyFilters }] = useFilterData();

  // useEffect(() => {
  //   filtersLoad();
  // }, [filtersLoad]);

  return (
    <ReportSectionCSS
      header="Фильтры отчета «Учет выдачи»"
      stickyNumber={1}
      skipNextShift
      headerBar={
        <ResetFilterLink
          title="Сбросить все фильтры"
          onClick={resetAll}
        />
      }
    >

      {/* Окно с фильтром */}
      <RowLayoutContent paddingfree>
        <FiltersPlainLayout
          onApplyFilter={applyFilters}
          onResetAll={resetAll}
          loading={loading}
          skipHeader
        >
          <Column className={styles.column}>
            <FilterItem filterId={orgTypesFilter} />
            <FilterItem filterId={orgsFilter} />
            <FilterItem filterId={buildingFilter} />
            <FilterItem filterId={sellPointFilter} />
            <FilterItem filterId={customerTypeFilter} />
            <FilterItem filterId={classFilter} />
            <FilterItem filterId={detailsFilter} />
            <FilterItem filterId={showDataFilter} />
            <FilterItem filterId={groupFilter} />
          </Column>
          <Column className={styles.column}>
            <FilterItem filterId={yearFilter} />
            <FilterItem filterId={periodFilter} />
            <FilterItem filterId={sellTypeFilter} />
            <FilterItem filterId={foodModeFilter} />
            <FilterItem filterId={mailFilter} />
          </Column>
        </FiltersPlainLayout>
      </RowLayoutContent>
    </ReportSectionCSS>
  );
});

export default ReportFilter;
