// @flow
import { useEffect, useRef, useCallback, type ElementRef } from 'react';

import lightenDarkenColor from 'app/common/lib/lightenDarkenColor';

import type { Theme } from './theme.type';


export default function useTheme(theme: Theme): ElementRef<any> {
  const rootEl = useRef(null);
  const {
    unitHeight,
    unitWidth,
    ruleThickness,
    edgingThickness,
    colorDanger,
    colorMiddle,
    colorNeutral,
    colorFull,
    colorButton,
    colorButtonHover,
    selectionTint,
  } = theme;

  const handleTheme = useCallback((elem: ElementRef<any> | null) => {
    rootEl.current = elem;
    if (elem) {
      elem.style.setProperty('--unit-height', unitHeight);
      elem.style.setProperty('--unit-width', unitWidth);
      elem.style.setProperty('--rule-thickness', ruleThickness);
      elem.style.setProperty('--edging-thickness', edgingThickness);
      elem.style.setProperty('--color-danger', colorDanger);
      elem.style.setProperty('--color-middle', colorMiddle);
      elem.style.setProperty('--color-full', colorFull);
      elem.style.setProperty('--color-neutral', colorNeutral);

      elem.style.setProperty('--color-danger-darken', lightenDarkenColor(colorDanger, selectionTint));
      elem.style.setProperty('--color-middle-darken', lightenDarkenColor(colorMiddle, selectionTint));
      elem.style.setProperty('--color-full-darken', lightenDarkenColor(colorFull, selectionTint));
      elem.style.setProperty('--color-neutral-darken', lightenDarkenColor(colorNeutral, selectionTint));

      elem.style.setProperty('--color-button', colorButton);
      elem.style.setProperty('--color-button-hover', colorButtonHover);
    }
  }, [
    unitHeight,
    unitWidth,
    ruleThickness,
    edgingThickness,
    colorDanger,
    colorMiddle,
    colorFull,
    colorNeutral,
    colorButton,
    colorButtonHover,
    selectionTint,
  ]);

  useEffect(() => {
    handleTheme(rootEl.current);
  }, [handleTheme]);

  return handleTheme;
}
