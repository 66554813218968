import { useRef, useCallback, useState, ReactNode } from 'react'
import classNames from 'classnames'
import not from 'ramda/es/not'

import PlainToggle from 'app/common/ui-next/plain-toggle'
import { DotsLoader } from 'app/common/components/Loader'
import RowLayout, { Header, HeaderContent, RowLayoutContent, Header2 } from 'app/common/layouts/RowLayout'
import CSSSticky from 'app/common/ui-components/css-sticky'
import Prompt from 'app/components/common/Prompt'
import SaveCancelButtons from 'app/components/common/SaveCancelButtons'
import { useWriteAccess } from 'app/rights/RightsForOrg'
import { useCurrencySign } from 'app/common/components-next/currency'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import { useLastChange } from 'app/dataProviders/OrgSettingsHistoryProvider'
import isProduction from 'app/common/lib/isProduction'

import { useOrgLimits } from 'app/dataProviders/org/limits/hooks/useOrgLimits'
import { OrgsLimitsID, OrgsLimitsTxt } from 'app/dataTypes/org/limits'
import { useOrgLimitsSubmit } from 'app/dataProviders/org/limits/hooks/useOrgLimitsSubmit'
import MultipleSubmitSettings from '../MultipleSubmitSettings'
import OptionsSet from './OptionsSet'
import { rightsPath } from '../rightsPath'
import PersonalLimitsReset, { resetTypes } from './PersonalLimitsReset'
import useMultipleBlockedMilliseconds from './useMultipleBlockedMilliseconds'
import MultipleModalAdditionalContent from './MultipleModalAdditionalContent'
import MultipleModalBlockInfo from './MultipleModalBlockInfo'
import HistoryLink from '../HistoryLink'
import sections, { sectionTitles } from '../sections'
import useGeneralSettings from '../General/useGeneralSettings'

import styles from './Limits.scss'
import CopySettingsContainer from '../common/CopySettingsContainer'
import CopySettings from '../CopySettings'
import { useChangeState } from './useChangeState'


const currentSection = sections.ORGS_SETTINGS_LIMITS
const header = sectionTitles[currentSection]

type Props = {
  orgId: number,
}

const Limits = ({ orgId }: Props) => {
  const [, updateLastChange] = useLastChange()

  const writeAccess = useWriteAccess(rightsPath)
  const currency = useCurrencySign()

  const { limits, request, loading } = useOrgLimits({ orgId })
  const { data, modified, handleUnModify, changeCheckbox, changeInput } = useChangeState({ limits })
  const { submit, saving } = useOrgLimitsSubmit({ orgId, data, onSubmited: handleUnModify })

  const formRef = useRef<null | HTMLFormElement>(null)
  const [submitting, setSubmitting] = useState(false)

  const handleSave = useCallback((/* e: SyntheticEvent<HTMLButtonElement> */) => {
    if (formRef.current) {
      (formRef.current.submit as any).click()
    }
  }, [])


  const [ignoreBlocking, setIgnoreBlocking] = useState(false)
  const [milliseconds, blockNow] = useMultipleBlockedMilliseconds()
  const remainingBlockingMilliseconds = ignoreBlocking ? 0 : milliseconds
  const handleMultipleRequestSuccess = useCallback((successed: Array<boolean>) => {
    updateLastChange()
    // successed - массив применений, если больше 1,
    // то произошло массовое применение и нужно блокировать на сутки
    if (successed.length > 1 && typeof blockNow === 'function') {
      blockNow()
    }
  }, [blockNow, updateLastChange])

  const [{
    data: { camp_enabled: campEnabled },
    loading: loadingGeneralSettings,
  }] = useGeneralSettings(String(orgId))

  return (
    <RowLayout paddingfree>
      <CSSSticky
        number={3}
        className={styles.stickable}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
      >
        <HeaderContent
          className={classNames(styles.topBorderOnSticky, styles.headerContent)}
          borderBottom
        >
          <div>
            <Header>{header}</Header>
            <HistoryLink sectionTitle={header} />
          </div>
          {modified
            && (
              <SaveCancelButtons
                onSave={handleSave}
                onCancel={request}
                disabled={loading || submitting}
                saving={submitting || saving}
                cancelling={modified && loading}
              />
            )}
        </HeaderContent>
      </CSSSticky>

      <RowLayoutContent>
        <MultipleSubmitSettings
          multipleAfterContent={<MultipleModalBlockInfo remaining={remainingBlockingMilliseconds} />}
          additionalContent={<MultipleModalAdditionalContent />}
          disableMultiple={remainingBlockingMilliseconds > 0}
          onSuccess={handleMultipleRequestSuccess}
          onSubmitArray={submit}
          onChangeSubmitState={setSubmitting}
          formId="limits_form"
        >
          {(toggleMultipleSubmitModal, multipleSubmit): ReactNode => (
            <form onSubmit={multipleSubmit} ref={formRef} id="limits_form">
              {(loading || data?.cafeteriaLimitsReadonlyForSchools === undefined)

                ? <DotsLoader simple />

                // Разрешить редактирование настроек лимитов в других кабинетах
                : (
                  <PlainToggle
                    checked={data.cafeteriaLimitsReadonlyForSchools}
                    prefix=""
                    suffix={OrgsLimitsTxt.cafeteriaLimitsReadonlyForSchools}
                    suffixClassName={styles.toggleLabel}
                    value={OrgsLimitsID.cafeteriaLimitsReadonlyForSchools}
                    onChange={e => changeCheckbox(e, OrgsLimitsID.cafeteriaLimitsReadonlyForSchools)}
                    disabled={!writeAccess}
                    coloredReverse
                  />
                )}


              <div className={styles.items}>
                <Header2 className={styles.header2}>{'Лимиты для питания в классах и ГПД'}</Header2>

                {/* Максимальный минус по заявкам */}
                <CopySettingsContainer className={styles.item}>
                  <OptionsSet
                    className={styles.optItem}
                    label={OrgsLimitsTxt.maxCafeOrderMinus}
                    name={OrgsLimitsID.maxCafeOrderMinus}
                    value={data.maxCafeOrderMinus}
                    loading={loading}
                    saving={submitting}
                    disabled={!writeAccess}
                    onChange={changeInput}
                    currency={currency}
                  >
                    <PersonalLimitsReset
                      remainingBlockingMilliseconds={remainingBlockingMilliseconds}
                      type={resetTypes.ORDERS}
                      onSuccess={handleMultipleRequestSuccess}
                    >
                      {OrgsLimitsTxt.resetPersonalLimits}
                    </PersonalLimitsReset>
                  </OptionsSet>

                  {writeAccess
                    && <CopySettings onSubmit={orgs => submit(orgs, [OrgsLimitsID.maxCafeOrderMinus])} />}
                </CopySettingsContainer>

                {/* Максимальный минус по личным продажам */}
                <CopySettingsContainer className={styles.item}>
                  <OptionsSet
                    className={styles.optItem}
                    label={OrgsLimitsTxt.maxCafeMinus}
                    name={OrgsLimitsID.maxCafeMinus}
                    value={data.maxCafeMinus}
                    loading={loading}
                    saving={submitting}
                    disabled={!writeAccess}
                    onChange={changeInput}
                    currency={currency}
                  >
                    <PersonalLimitsReset
                      remainingBlockingMilliseconds={remainingBlockingMilliseconds}
                      type={resetTypes.PERSONAL}
                      onSuccess={handleMultipleRequestSuccess}
                    >
                      {OrgsLimitsTxt.resetPersonalLimits}
                    </PersonalLimitsReset>
                  </OptionsSet>

                  {writeAccess
                    && <CopySettings onSubmit={orgs => submit(orgs, [OrgsLimitsID.maxCafeMinus])} />}
                </CopySettingsContainer>

                {/* Ежедневный лимит расходов учащихся */}
                <CopySettingsContainer className={styles.item}>
                  <OptionsSet
                    className={styles.optItem}
                    label={OrgsLimitsTxt.cafeDayLimit}
                    name={OrgsLimitsID.cafeDayLimit}
                    value={data.cafeDayLimit}
                    loading={loading}
                    saving={submitting}
                    disabled={!writeAccess}
                    onChange={changeInput}
                    currency={currency}
                  >
                    <PlainCheckbox
                      id={OrgsLimitsID.splitStudentDayLimit}
                      name={OrgsLimitsID.splitStudentDayLimit}
                      value={OrgsLimitsID.splitStudentDayLimit}
                      checked={data.splitStudentDayLimit}
                      label="Распределять лимит на все точки продаж"
                      onChange={e => changeCheckbox(e, OrgsLimitsID.splitStudentDayLimit)}
                    />
                  </OptionsSet>
                  {writeAccess
                    && <CopySettings onSubmit={orgs => submit(orgs, [OrgsLimitsID.cafeDayLimit])} />}
                </CopySettingsContainer>
              </div>

              {campEnabled
                && <div className={styles.items}>
                  <Header2 className={styles.header2}>{'Лимиты для питания в пришкольном лагере'}</Header2>

                  {/* Максимальный минус по заявкам */}
                  <CopySettingsContainer className={styles.item}>
                    <OptionsSet
                      className={styles.optItem}
                      label={OrgsLimitsTxt.maxCampCafeOrderMinus}
                      name={OrgsLimitsID.maxCampCafeOrderMinus}
                      value={data.maxCampCafeOrderMinus}
                      loading={loading || loadingGeneralSettings}
                      saving={submitting}
                      disabled={!writeAccess}
                      onChange={changeInput}
                      currency={currency}
                    />

                    {writeAccess
                      && <CopySettings
                        onSubmit={orgs => submit(orgs, [OrgsLimitsID.maxCampCafeOrderMinus])}
                      />}
                  </CopySettingsContainer>
                </div>}

              {!isProduction()
                && (
                  <PlainCheckbox
                    value="ignoreBlocking"
                    checked={ignoreBlocking}
                    label="Игнорировать блокировку (только на тестовом)"
                    onChange={() => setIgnoreBlocking(not)}
                  />
                )}

              <button type="submit" name="submit" className={styles.hidden}>
                {'Сохранить'}
              </button>
            </form>
          )}
        </MultipleSubmitSettings>

        <Prompt
          when={modified}
          message="Вы не сохранили изменения, перейти в любом случае?"
        />
      </RowLayoutContent>
    </RowLayout>
  )
}

export default Limits
