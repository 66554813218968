// @flow
import { dateFormat as plainDateFormat } from 'app/common/ui-next/plain-input-date';


// фильтр на основе компонента PlainItemsSelector (ui-next/palin-selector/palin-items-selector),
// один или множественный выбор в диалоговом окне
const PLAIN_ITEMS_SELECTOR: 'plainItemsSelector' = 'plainItemsSelector';
// группа радио-кнопок, единичный выбор
const PLAIN_RADIO_GROUP: 'plainRadioGroup' = 'plainRadioGroup';
// группа чекбоксов, множественный выбор
const PLAIN_CHECKBOX_GROUP: 'plainCheckboxGroup' = 'plainCheckboxGroup';
// период времени
const PLAIN_PERIOD: 'plainPeriod' = 'plainPeriod';
// дата
const PLAIN_DATE: 'plainDate' = 'plainDate';
// скрытый, данные для других фильтров
const HIDDEN_DATA: 'hiddenData' = 'hiddenData';
// набор радиокнопок + поле выбора даты
const RADIO_DATE: 'radioDate' = 'radioDate';
// набор радиокнопок + поля для ввода текста
const RADIO_TEXT: 'radioText' = 'radioText';
// поиск пользователя и добаление его id в current
const CUSTOMER_SEARCH: 'customerSearch' = 'customerSearch';

export const filterItemTypes = {
  PLAIN_ITEMS_SELECTOR,
  PLAIN_RADIO_GROUP,
  PLAIN_CHECKBOX_GROUP,
  PLAIN_PERIOD,
  PLAIN_DATE,
  HIDDEN_DATA,
  RADIO_DATE,
  RADIO_TEXT,
  CUSTOMER_SEARCH,
};

export { plainDateFormat };
