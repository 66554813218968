import { createSelector } from 'reselect';
import path from 'ramda/es/path';
import isEmpty from 'ramda/es/isEmpty';


const selectKit = createSelector(
  [
    (state, { kitId }) => path(['orgs', 'kits', 'items', kitId], state) || {},
  ],
  ({
    fixed_price_enabled: fixedPriceEnabled = false,
    fixed_price: fixedPrice = '',
    title = '',
    subsidy_orders: subsidyOrders,
  }) => ({
    fixedPriceEnabled,
    fixedPrice,
    title,
    enableSubsidyOrders: subsidyOrders && !isEmpty(subsidyOrders),
  }),
);

export default selectKit;
