import { api } from 'app/api'
import { GET_ORG_TYPES, convertResult } from 'app/common/api/requests/food/school_types/get'
import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { ItemType } from 'app/common/ui-next/plain-selector'
import { OrgTypesList } from 'app/dataTypes/org'


/**
 * Data
*/

export type OrgTypes = Array<ItemType>
class OrgTypesFilterDataBox extends DataBox<OrgTypes> { }

export const defaultOrgTypes: OrgTypes = []
export const orgTypesFilter = createDataBox('orgsTypesFilter', defaultOrgTypes, OrgTypesFilterDataBox)

const convertFromExtended = (orgsTypeList: OrgTypesList[]): Array<ItemType> => {
  return orgsTypeList.map(({ ID, title }) => ({ id: ID, title }))
}

export const defaultOrgTypesDataReceiver = async () => {
  const res = await api.request(GET_ORG_TYPES, {
    error: 'Не удалось получить типы заведений',
    convertions: {
      convertResult,
    },
  })
  return res ? convertFromExtended(res) : null
}

orgTypesFilter.addDataReceiver(defaultOrgTypesDataReceiver)

/**
 * Selection
 */

export const defaultOrgTypesSelection: Array<string> = []
export const orgTypesFilterSelection = createDataBoxDefault<Array<string>>('orgTypesFilterSelection', defaultOrgTypesSelection)
