// @flow


const groupItems = () => ([[{
  id: 'none',
  title: 'без группировки',
}, {
  id: 'by_group_complexes',
  title: 'комплектам (групповой талон)',
}, {
  id: 'by_camp_complexes',
  title: 'комплектам лагеря (групповой талон)',
}], ['none']]);

export default groupItems;
