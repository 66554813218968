import rSet from 'ramda/es/set';
import lensPath from 'ramda/es/lensPath';

import { MENU_REQUEST, MENU_SUCCESS, MENU_FAILURE } from 'app/actions/orgs/menu';
import { FIELDEDIT_SUCCESS } from 'app/actions/orgs/menu/fieldEdit';


const defaultState = {
  loading: true,
  items: {},
};

const items = (state = defaultState, action) => {
  if (action.type === MENU_REQUEST) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === MENU_SUCCESS) {
    return {
      ...state,
      loading: false,
      items: action.payload,
    };
  }

  if (action.type === MENU_FAILURE) {
    return {
      ...state,
      loading: false,
      items: {},
    };
  }

  if (action.type === FIELDEDIT_SUCCESS) {
    return rSet(lensPath(['items', ...action.payload.path]), action.payload.value, state);
  }

  return state;
};

export default items;
