import { useMemo } from 'react'

import { useCurrencySign } from 'app/common/components-next/currency'


export enum HeaderRow1 {
  TITLE = 'title',
  COMPLEX = 'complex',
  PERSONAL = 'personal',
  TOTAL = 'total'
}

export enum HeaderRow2 {
  COMPLEX_S = 'complex_s',
  COMPLEX_RET = 'complex_ret',
  COMPLEX_COM = 'complex_com',
  PERSONAL_S = 'personal_s',
  PERSONAL_RET = 'personal_ret',
  PERSONAL_COM = 'personal_com'
}

export type Column = HeaderRow1 | HeaderRow2

export type ColumnProps = {
  id: Column,
  title: string,
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
    colSpan?: number,
    rowSpan?: number,
    borderRight?: boolean,
  },
}

export const contentRow: Array<{
  id: Column
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
    borderRight?: boolean,
  }
}> = [{
  id: HeaderRow1.TITLE,
  props: { alignLeft: true, borderRight: true },
}, {
  id: HeaderRow2.PERSONAL_S,
  props: { alignRight: true },
}, {
  id: HeaderRow2.PERSONAL_COM,
  props: { alignRight: true },
}, {
  id: HeaderRow2.PERSONAL_RET,
  props: { alignRight: true, borderRight: true },
}, {
  id: HeaderRow2.COMPLEX_S,
  props: { alignRight: true },
}, {
  id: HeaderRow2.COMPLEX_COM,
  props: { alignRight: true },
}, {
  id: HeaderRow2.COMPLEX_RET,
  props: { alignRight: true, borderRight: true },
}, {
  id: HeaderRow1.TOTAL,
  props: { alignRight: true },
}]

export type Columns = {
  headerRow1: Array<ColumnProps>
  headerRow2: Array<ColumnProps>
  contentRow: typeof contentRow
}

export function useColumns(): Columns {
  const currencySign = useCurrencySign()

  const headerRow1: Array<ColumnProps> = useMemo(() => {
    return [{
      id: HeaderRow1.TITLE,
      title: 'Заведение',
      props: {
        alignLeft: true,
        rowSpan: 2,
        borderRight: true,
      },
    }, {
      id: HeaderRow1.PERSONAL,
      title: 'Личные продажи',
      props: {
        alignCenter: true,
        colSpan: 3,
        borderRight: true,
      },
    }, {
      id: HeaderRow1.COMPLEX,
      title: 'Комплексное питание',
      props: {
        alignCenter: true,
        colSpan: 3,
        borderRight: true,
      },
    }, {
      id: HeaderRow1.TOTAL,
      title: `Итого,\u00A0${currencySign}`,
      props: {
        alignRight: true,
        rowSpan: 2,
      },
    }]
  }, [currencySign])

  const headerRow2: Array<ColumnProps> = useMemo(() => {
    return [{
      id: HeaderRow2.PERSONAL_S,
      title: `Списания\u00A0ЭДС,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: HeaderRow2.PERSONAL_COM,
      title: `Вознаграждение,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: HeaderRow2.PERSONAL_RET,
      title: `Возвраты,\u00A0${currencySign}`,
      props: {
        alignRight: true,
        borderRight: true,
      },
    }, {
      id: HeaderRow2.COMPLEX_S,
      title: `Списания\u00A0ЭДС,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: HeaderRow2.COMPLEX_COM,
      title: `Вознаграждение,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: HeaderRow2.COMPLEX_RET,
      title: `Возвраты,\u00A0${currencySign}`,
      props: {
        alignRight: true,
        borderRight: true,
      },
    }]
  }, [currencySign])

  return {
    headerRow1,
    headerRow2,
    contentRow,
  }
}
