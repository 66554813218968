import { Fragment, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import isEmpty from 'ramda/es/isEmpty'
import path from 'ramda/es/path'

import { Table, useHoverByClass } from 'app/common/ui-components/table'
import Loading from 'app/common/components/Loader'
import EmptyWarn from 'app/common/layouts/EmptyWarn'
import { useVendingMachines, useVendingMachinesParams } from 'app/dataProviders/VendingDataProvider'
import { PaginationFooterWithSticky } from 'app/common/layouts/RowLayout'
import Sticky from 'app/common/templates-next/sticky'

import THeadRow from './THeadRow'
import TBodyRow from './TBodyRow'
import useTableItems, { sameGroup } from './useTableItems'
import usePagination from './usePagination'
import { dataKey } from '../dataKey'

import styles from './VendingMachinesTable.scss'


type Props = {
  onRedirectToVendingMachine: (id: string) => void,
}

const VendingMachinesTable = ({ onRedirectToVendingMachine }: Props) => {
  const { rows: limit, ...columnSettings }: any = useSelector(path(['settings', dataKey]), shallowEqual)
  const { offset, currentPage, handlePage, resetPagination } = usePagination(limit)
  const [, updateParams] = useVendingMachinesParams()

  useEffect(() => {
    updateParams({ limit, offset })
  }, [limit, offset, updateParams])

  const [machines, loading, requestMachines] = useVendingMachines()
  const { meta: { available } } = machines

  useEffect(() => {
    requestMachines()
  }, [requestMachines])

  const items = useTableItems(machines)

  const nothing = isEmpty(items)

  useEffect(() => {
    if (nothing && currentPage > 0) {
      resetPagination()
    }
  }, [nothing, currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

  const [onMouseEnter, onMouseLeave] = useHoverByClass(sameGroup)


  return (
    <Fragment>
      {nothing
        && loading
        && <Loading />}

      {!loading
        && nothing
        && <EmptyWarn>
          {'Нет записей, удовлетворяющих условиям'}
        </EmptyWarn>}

      {!nothing
        && <Table className={styles.table}>
          <Sticky>
            {(ref, stuck, shadow) => (
              <thead ref={ref}>
                <THeadRow
                  stuck={stuck}
                  shadow={shadow}
                  settings={columnSettings}
                />
              </thead>
            )}
          </Sticky>
          <tbody className={styles.tbody}>
            {items.map((item) => {
              const { id } = item

              return (
                <TBodyRow
                  settings={columnSettings}
                  key={id}
                  loading={loading}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onRedirectToVendingMachine={onRedirectToVendingMachine}
                  {...item}
                />
              )
            })}
          </tbody>
        </Table>}

      <PaginationFooterWithSticky
        total={available}
        pageSize={limit}
        currentPage={currentPage}
        currentPageSize={items.length}
        onPage={handlePage}
      />
    </Fragment>
  )
}

export default VendingMachinesTable
