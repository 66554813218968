import set from 'ramda/es/set';
import lensPath from 'ramda/es/lensPath';
import dissoc from 'ramda/es/dissoc';

import {
  EMPLOYEE_SCHOOLS_SUCCESS,
  EMPLOYEE_SCHOOLS_CLEAR,
  EMPLOYEE_SCHOOLS_ADD,
  EMPLOYEE_SCHOOLS_CHANGE_FIELD,
} from 'app/actions/employees/employee/jobplaces';

import { JOBPLACES_SUBMIT_SUCCESS } from 'app/actions/employees/employee/jobplaces/submit';
import { JOBPLACES_DELETE_SUCCESS } from 'app/actions/employees/employee/jobplaces/delete';


const defaultState = {};

const jobplaces = (state = defaultState, { type, payload }) => {
  if (type === EMPLOYEE_SCHOOLS_SUCCESS || type === JOBPLACES_SUBMIT_SUCCESS) {
    return ({ ...payload });
  }
  if (type === EMPLOYEE_SCHOOLS_ADD) {
    return ({ ...state, ...payload });
  }
  if (type === EMPLOYEE_SCHOOLS_CLEAR) {
    return defaultState;
  }
  if (type === EMPLOYEE_SCHOOLS_CHANGE_FIELD) {
    const { id, name, value } = payload;
    return set(lensPath([id, name]), value, state);
  }
  if (type === JOBPLACES_DELETE_SUCCESS) {
    return dissoc(payload, state);
  }
  return state;
};

export default jobplaces;
