import { Fragment } from 'react'

import { Table, Tr, Th } from 'app/common/ui-components/table'
import PlainTooltip from 'app/common/ui-next/plain-tooltip'
import { OrgItemType, TotalItemType } from 'app/dataTypes/reports/data/finances/emoneySales'

import { MultilineCell } from './MultilineCell'
import styles from './LeftTable.scss'


type Props = {
  className?: string
  sameRowClassBase?: string
  data?: Array<OrgItemType>
  total?: Array<TotalItemType>
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
}

const LeftTable = ({
  className,
  sameRowClassBase = 'row_',
  data,
  total,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const middlePosition = !!data
  const bottomPosition = !!total

  return (
    <Table className={className}>
      <thead>
        <Tr header hidden={middlePosition || bottomPosition}>
          <Th
            scope="col"
            alignLeft
            bgFilled
          >
            {'Заведение'}
          </Th>
        </Tr>
      </thead>

      {data
        && <tbody>
          {data.map(({ ID, title }, rowIndex) => (
            <Fragment key={ID}>
              <Tr
                className={`${sameRowClassBase}${rowIndex}`}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                interlineBorder
              >
                <Th
                  alignLeft
                  className={styles.org}
                  borderBottom={rowIndex === data.length - 1}
                >
                  <PlainTooltip
                    className={styles.limitedCell}
                    text={title}
                  >
                    {title}
                  </PlainTooltip>
                </Th>
                <Th
                  ignoreLastChild
                  alignLeft
                >
                  <MultilineCell>
                    <span>{'Продажи:'}</span>
                    <span>{'Корректировки:'}</span>
                    <span>{'Итого:'}</span>
                    <span>{'Списания ЭДС:'}</span>
                    <span>{'Возвраты ЭДС:'}</span>
                    <span>{'К переводы (без возн.):'}</span>
                  </MultilineCell>
                </Th>
              </Tr>
            </Fragment>
          ))}
        </tbody>}

      {total
        && <tfoot>
          <Tr
            hidden={!bottomPosition}
            headerHasBorderBottom={false}
            header
          >
            <Th
              className={styles.org}
              borderTopCompensate
              alignLeft
              bgFilled
            >
              &nbsp;
            </Th>
            <Th
              className={styles.summary}
              borderTopCompensate
              ignoreLastChild
              alignLeft
              bgFilled
            >
              <MultilineCell>
                <span>{'Итого продажи:'}</span>
                <span>{'Сумма для перевода ЭДС:'}</span>
              </MultilineCell>
            </Th>
          </Tr>
        </tfoot>}
    </Table>
  )
}

export default LeftTable
