// @flow
import React, { Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import {
  Table,
  useHoverByClass,
  useCopyColumnsWidths,
} from 'app/common/ui-components/table';

import CSSSticky from 'app/common/ui-components/css-sticky';
import * as subsidyTypes from 'app/actions/reports/filters/subsidyTypesAvailable';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import useActualClassesAndOrders, { type ClassesAndOrdersView } from './useActualClassesAndOrders';
import useActualTotals from './useActualTotals';
import ClassesAndOrdersTHead from './ClassesAndOrdersTHead';
import ClassesAndOrdersRow from './ClassesAndOrdersRow';

import styles from './ClassesAndOrders.scss';


const sameRowBase = 'row_';
const copySizesOptions = { compensation: [1] };

const ClassesAndOrders = () => {
  const { subsidyType } = useSelector(state => state.reports.subsidies.params, shallowEqual);

  const viewCommon = (subsidyType === subsidyTypes.SUBSIDY_TYPE_ALL
    || subsidyType === subsidyTypes.SUBSIDY_TYPE_COMMON);
  const viewPersonal = (subsidyType === subsidyTypes.SUBSIDY_TYPE_ALL
    || subsidyType === subsidyTypes.SUBSIDY_TYPE_PERSONAL);

  const classesAndOrders = useActualClassesAndOrders();
  const totals = useActualTotals();

  const [refFrom1, refTo1] = useCopyColumnsWidths(null, copySizesOptions);
  const [refFrom2, refTo2] = useCopyColumnsWidths(null, copySizesOptions);
  const [refFrom3, refTo3] = useCopyColumnsWidths(null, copySizesOptions);

  const [onMouseEnter, onMouseLeave] = useHoverByClass(sameRowBase);
  const mouseHandlers = { onMouseEnter, onMouseLeave };

  return (
    <Fragment>
      <DetailsHeader reportTitle="Дотации" />

      {/* ЗАГОЛОВОК */}
      <CSSSticky
        number={2}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <ClassesAndOrdersTHead
            rowRefFirst={refTo1}
            rowRefSecond={refTo2}
            viewCommon={viewCommon}
            viewPersonal={viewPersonal}
          />
        </Table>
      </CSSSticky>

      {/* КОНТЕНТ */}
      <Table className={styles.table}>
        <ClassesAndOrdersTHead
          rowRefFirst={refFrom1}
          rowRefSecond={refFrom2}
          viewCommon={viewCommon}
          viewPersonal={viewPersonal}
          dummy
          hidden
        />
        <tbody>
          {classesAndOrders.map(({ title, common, personal, personalCount }: ClassesAndOrdersView, r) => {
            const rowClassName = `${sameRowBase}${r}`;

            return (
              <ClassesAndOrdersRow
                key={title}
                className={rowClassName}
                title={title}
                common={common}
                personal={personal}
                personalCount={personalCount}
                mouseHandlers={mouseHandlers}
                viewCommon={viewCommon}
                viewPersonal={viewPersonal}
              />
            );
          })}
        </tbody>
        <tfoot>
          <ClassesAndOrdersRow
            viewCommon={viewCommon}
            viewPersonal={viewPersonal}
            rowRef={refFrom3}
            hidden
            {...totals}
          />
        </tfoot>
      </Table>

      {/* ПОДВАЛ */}
      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        <Table className={styles.table}>
          <tfoot>
            <ClassesAndOrdersRow
              className={styles.table}
              rowRef={refTo3}
              viewCommon={viewCommon}
              viewPersonal={viewPersonal}
              highlight
              {...totals}
            />
          </tfoot>
        </Table>
      </CSSSticky>
    </Fragment>
  );
};

export default ClassesAndOrders;
