import { useCallback } from 'react'

import {
  POST_SCHOOL_ALLOWANCES,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/schools/subsidy_orders_allowances/post'

import { api } from 'app/api'
import { MutationParams } from 'app/dataTypes/subsidy/allowances'


export default function useOrgAllowancesActions({
  orgID,
  app,
}: Omit<MutationParams, 'allowanceIDs' | 'allowanceAddID' | 'allowanceRemoveID'>) {
  const add = useCallback(async (alloanceID: string) => {
    const res = await api.request(POST_SCHOOL_ALLOWANCES, {
      error: 'Не удалось добавить льготу',
      params: {
        app,
        orgID,
        allowanceAddID: alloanceID,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      return true
    }
    return false
  }, [orgID, app])

  const remove = useCallback(async (alloanceID: string) => {
    const res = await api.request(POST_SCHOOL_ALLOWANCES, {
      error: 'Не удалось удалить льготу',
      params: {
        app,
        orgID,
        allowanceRemoveID: alloanceID,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      return true
    }
    return false
  }, [orgID, app])

  const update = useCallback(async (allowanceIDs: Array<string>) => {
    const res = await api.request(POST_SCHOOL_ALLOWANCES, {
      error: 'Не удалось обновить льготы',
      params: {
        app,
        orgID,
        allowanceIDs,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      return true
    }
    return false
  }, [orgID, app])

  return { add, remove, update }
}
