// @flow
import React, { useMemo, useContext, useCallback } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { toFixedValueCurrency } from 'app/common/lib/toFixedValue';
import { OptionProps, OptionProp } from 'app/common/ui-next/option-props';
import * as statuses from 'app/common/constants/identifiers/statuses';
import { useCurrencySign } from 'app/common/components-next/currency';

import { IdentifiersContext, type IdentifierType, moduleTypes } from '../identifiers-data';
import PropIdentifierNumbers from './prop-identifier-numbers';
import PropStatus from './prop-status';
import IconyByType from '../identifiers-icons/icon-by-type';
import ModulesSwitcher from './modules-switcher';
import IdentifierItemControls from './identifier-item-controls';

import styles from './identifier-item.scss';


type Props = {
  identifierData: IdentifierType
}

const IdentifierItem = ({ identifierData }: Props) => {
  const { t } = useTranslation('identifiers-next');
  const currency = useCurrencySign();

  const {
    id,
    w26,
    w34,
    w58,
    number,
    type,
    is_access_card: isAccessCard,
    is_cafe_card: isCafeCard,
    status,
    status_expiry: expiry,
    updated,
    created: utCreated,
    cost,
    unblock_disabled: unblockDisabled,
    comment,
  } = identifierData;

  const {
    content: { timezone, locked, hiddenModuleSwitchers },
    request: { moduleSwitch },
  } = useContext(IdentifiersContext);

  const numbers = useMemo(() => ([w26, w34, w58]), [w26, w34, w58]);

  const mCreated = moment(utCreated).tz(timezone);
  const created = mCreated.isValid() ? mCreated.format('DD.MM.YYYY H:mm') : '—';

  const handleChangeModule = useCallback((moduleType: $Values<typeof moduleTypes>, checked: boolean) => {
    moduleSwitch(id, moduleType, checked);
  }, [moduleSwitch, id]);

  return (
    <div className={styles.root}>
      <div className={styles.typePictureColumn}>
        <IconyByType type={type} />
      </div>

      <div className={styles.contentColumn}>
        <PropIdentifierNumbers
          numbers={numbers}
          defaultNumber={number || '???'}
        />
        <OptionProps>
          <PropStatus
            status={status}
            expiry={expiry}
            updated={updated}
            timezone={timezone}
          />
          {comment
            && <OptionProp title={t('title_comment')}>
              {comment}
            </OptionProp>}
          <OptionProp title={t('title_created')}>
            {created}
          </OptionProp>
          <OptionProp title={t('title_cost')}>
            {cost ? toFixedValueCurrency(cost, false, currency) : '—'}
          </OptionProp>
        </OptionProps>

        {!locked
          && <IdentifierItemControls
            id={id}
            number={number}
            numbers={numbers}
            type={type}
            status={status}
            unblockDisabled={unblockDisabled}
            isPayDelayed={status === statuses.STATUS_ACTIVE && !!expiry}
          />}
      </div>

      <div className={styles.servicesColumn}>
        {status !== statuses.STATUS_WITHDRAWN
          && <ModulesSwitcher
            id={id}
            locked={locked}
            label={t('label_services_switcher')}
            isCafeCard={isCafeCard}
            isAccessCard={isAccessCard}
            onChange={handleChangeModule}
            hiddenModuleSwitchers={hiddenModuleSwitchers}
          />}
      </div>
    </div>
  );
};

export default IdentifierItem;
