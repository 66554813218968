// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames/bind';

import OverflowedText from 'app/common/components/OveflowedText';

import styles from './CellNumberAndModel.scss';


const cx = classNames.bind(styles);

type Props = {
  number: string,
  model: string,
}

const CellNumberAndModel = ({ number, model }: Props) => {
  return (
    <Fragment>
      <OverflowedText className={styles.number}>
        {number}
      </OverflowedText>
      <OverflowedText className={cx(styles.model, { small: !!number })}>
        {model}
      </OverflowedText>
    </Fragment>
  );
};

export default CellNumberAndModel;
