// @flow
import { useEffect, useState } from 'react';
import { map, pipe, filter, propEq, reject } from 'ramda';

import { GET_VENDING_ITEMS, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/get';
import { api } from 'app/api';

import type { Item } from '../../../_externalProduction/useAddRemoveState';


type Params = {
  placeId: string,
  categoryId: string,
  foodItemIds: Array<string>,
}

export default function useAlreadyAdded({ placeId, categoryId, foodItemIds }: Params): [
  Map<string, Item>,
  Map<string, Item>,
  boolean,
] {
  const [alreadyAdded, setAlreadyAdded] = useState<Map<string, Item>>(new Map());
  const [disabledItems, setDisabledItems] = useState<Map<string, Item>>(new Map());
  const [loading, setLoading] = useState(true);

  // console.log('alreadyAdded', Array.from(alreadyAdded.values()));
  // console.log('disabledItems', Array.from(disabledItems.values()));

  useEffect(() => {
    if (!placeId) return;
    const request = async () => {
      setLoading(true);
      const res = await api.request(GET_VENDING_ITEMS, {
        error: 'Не удалось получить уже добавленную продукцию',
        params: {
          placeId,
          foodItemIds,
          deleted: false,
        },
        convertions: {
          convertParams,
          convertResult,
        },
      });

      if (res && res.data) {
        const { data } = res;
        setAlreadyAdded(new Map(
          pipe(
            filter(propEq('categoryId', categoryId)),
            map(({ foodItemId, title }) => ([
              foodItemId,
              { id: foodItemId, title },
            ])),
          )(data),
        ));
        setDisabledItems(new Map(
          pipe(
            reject(propEq('categoryId', categoryId)),
            map(({ foodItemId, title }) => ([
              foodItemId,
              { id: foodItemId, title },
            ])),
          )(data),
        ));
      }
      setLoading(false);
    };

    request();
  }, [placeId, categoryId, foodItemIds]);

  return [alreadyAdded, disabledItems, loading];
}
