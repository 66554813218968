// @flow
import React, { type Ref } from 'react';
import cn from 'classnames';

import { Tr, Th } from 'app/common/ui-components/table';

import type { DisplayableDate } from '../useDates';


const emptyProps = Object.freeze({});

type Props = {
  hidden?: boolean,
  items: Array<DisplayableDate>,
  reference?: Ref<'tr'>,
  onCellMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  sameColumnClassName?: string,
}

const ContentHead = ({
  hidden,
  items,
  reference,
  onCellMouseEnter,
  onCellMouseLeave,
  sameColumnClassName,
}: Props) => {
  return (
    <thead>
      <Tr
        hidden={hidden}
        reference={reference}
        header
      >
        {items.map(({ date, title }, i) => {
          const props = hidden ? emptyProps : {
            id: `column_date_${date}`,
            alignCenter: true,
          };

          return (
            <Th
              key={date}
              scope="col"
              onMouseEnter={onCellMouseEnter}
              onMouseLeave={onCellMouseLeave}
              className={cn(sameColumnClassName && `${sameColumnClassName}${i}`)}
              {...props}
            >
              {title}
            </Th>
          );
        })}
      </Tr>
    </thead>
  );
};

export default ContentHead;
