// @flow
import React, { type Node } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import CommonPageTemplate from '../CommonPageTemplate/CommonPageTemplate';
import useTitle from './useTitle';


type Props = {
  children: Node,
  header: string | Node,
  headerSuffix?: string,
  stickable?: boolean,
  rightSideNode?: Node,
}

const CommonPage = ({ children, header, headerSuffix, stickable, rightSideNode }: Props) => {
  const { project_name: projectName } = useSelector(state => state.dealer, shallowEqual);

  // заголовок браузера
  const title = useTitle({ projectName, header });

  return (
    <CommonPageTemplate
      title={title}
      rightSideNode={rightSideNode}
      stickable={stickable}
      header={
        headerSuffix
          ? <span>
            {header}
            {headerSuffix}
          </span>
          : header
      }
    >
      {children}
    </CommonPageTemplate>
  );
};

export default CommonPage;
