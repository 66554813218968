// @flow
import React, { useEffect, type Node, Fragment } from 'react';

import { getSubReportsInReport, getSubReportsInReportTitles } from './availableReports';
import ReportTabs from './ReportTabs';


type Props = {
  baseUrl: string,
  subReport?: string,
  redirectToReport: (subReport: string) => void,
  report: string,
  children: Node,
}

const SubReportChooser = ({
  baseUrl,
  subReport,
  redirectToReport,
  report,
  children,
}: Props) => {
  const subReports = getSubReportsInReport(report);
  const titles = getSubReportsInReportTitles(report);

  useEffect(() => {
    if (!subReport) {
      redirectToReport(subReports[0]);
    }
  }, [report, subReport, subReports, redirectToReport]);

  if (!subReport) {
    return null;
  }

  return (
    <Fragment>
      {subReports.length > 1
        && <ReportTabs
          baseUrl={baseUrl}
          currentSubReport={subReport}
          subReports={subReports}
          tabTitles={titles}
        />}
      {children}
    </Fragment>
  );
};

export default SubReportChooser;
