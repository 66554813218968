export type CompensationAction = {
  orgID: string
  year: string
  month: string
  action: 'record' | 'cancel' | 'interrupt'
}

export const isCompensationAction = (params: any): params is CompensationAction => {
  const { orgID, year, month, action } = params
  return !!(orgID && year && month && (
    action === 'record'
    || action === 'cancel'
    || action === 'interrupt'
  ))
}
