// @flow
import Stores from './stores';
import Copyright from './copyright';
import Footer from './footer';
import useFooterData from './use-footer-data';


export default Footer;

export {
  Stores,
  Copyright,
  useFooterData,
};
