// @flow
import { useCallback } from 'react';

import usePaging from 'app/common/hooks/usePaging';


export default function usePagination(limit: number) {
  const { currentPage, handlePage, setCurrentPage } = usePaging();
  const offset = (currentPage - 1) * limit;

  const resetPagination = useCallback(() => {
    setCurrentPage(1);
  }, [setCurrentPage]);

  return { offset, currentPage, handlePage, resetPagination };
}
