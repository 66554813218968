// @flow

/**
 *  Установка курсора в нужную позицию (брузеронезависимое)
 */

function setCaretPosition(elem: ?HTMLInputElement, caretPos: number) {
  if (!elem) return;

  setTimeout(() => { // for safari
    elem.setSelectionRange(caretPos, caretPos);
  }, 0);
}

export default setCaretPosition;
