// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import values from 'ramda/es/values';
import head from 'ramda/es/head';
import prop from 'ramda/es/prop';
import pipe from 'ramda/es/pipe';
import type { HOC } from 'recompose';

import { rolesFetch } from 'app/actions/admin/roles';
import selectRolesData from './selectRolesData';


type Props = {
  id: number | string,
  onLoad: (number) => void,
  loading?: boolean,
};

const withRoles: HOC<*, Props> = compose(
  connect(
    (state, props) => {
      const { loading } = props;
      return ({
        data: selectRolesData(state, props),
        loading: loading || state.admin.options.loading,
      });
    },
  ),

  lifecycle({
    async componentDidMount() {
      const { dispatch, onLoad } = this.props;
      const res = await dispatch(rolesFetch());
      if (onLoad) {
        onLoad(
          pipe(values, head, prop('id'))(res),
        );
      }
    },
  }),
);

export default withRoles;
