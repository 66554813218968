// @flow

export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

export type Params = {
  orgId: number,
  sellPointId: number, // точка продаж
  date: string,
  add: Array<number>, // id добавляемых блюд / комплектов
  remove: Array<number>, // id удаляемых блюд / комплектов
}
