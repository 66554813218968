// @flow
import * as sections from './sections';


const allowedSections = [
  sections.GENERAL_SECTION,
  sections.IDENTIFIERS_SECTION,
  sections.JOBPLACES_SECTION,
  sections.EVENTS_SECTION,
  sections.SETTINGS_SECTION,
];

export default allowedSections;
