// @flow
import React from 'react'

import { ReactI18NextChild } from 'react-i18next'
import { DotsLoader } from 'app/common/components/Loader'

import styles from './ExtKitLayout.scss'


type Props = {
  loading?: boolean,
  headerContent: ReactI18NextChild,
  headerGroceryPrice: ReactI18NextChild,
  leftBarContent: ReactI18NextChild,
  mainContent: ReactI18NextChild,
  rightBarContent: ReactI18NextChild,
  footerContent: ReactI18NextChild,
}

export const ExtKitLayout = ({
  loading,
  headerContent,
  headerGroceryPrice,
  leftBarContent,
  mainContent,
  rightBarContent,
  footerContent,
}: Props) => {
  return (
    <div className={styles.root}>
      {loading
        && <div className={styles.blockedWhileLoaded} />}

      <div className={styles.content}>
        <div className={styles.header}>{headerContent}</div>
        <div className={styles.headerGroceryPrice}>{headerGroceryPrice}</div>
        <div className={styles.leftBar}>{leftBarContent}</div>
        <div className={styles.main}>{mainContent}</div>
        <div className={styles.rightBar}>{rightBarContent}</div>
        <div className={styles.footer}>{footerContent}</div>
      </div>

      {loading && <DotsLoader className={styles.loader} />}
    </div>
  )
}
