import PlainButton from 'app/common/ui-next/plain-button'
import { PlainConfirm } from 'app/common/ui-next/plain-confirm-modal'


type Props = {
  title: string
  disabled?: boolean
  confirmText: string
  onConfirm: () => Promise<boolean>
}

export const ActionButtonWithConfirm = ({
  title,
  disabled,
  confirmText,
  onConfirm,
}: Props) => {
  return (
    <PlainConfirm
      messageText={confirmText}
      onConfirm={onConfirm}
      closeRightAway
    >
      {beginConfirmation => (
        <PlainButton
          onClick={beginConfirmation}
          disabled={disabled}
          compact
        >
          {title}
        </PlainButton>
      )}
    </PlainConfirm>
  )
}
