// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import length from 'ramda/es/length';

import {
  Row as RowOrig,
  Cell as CellOrig,
  HeaderRowSortableStateless as HeaderRowSortable,
  HeaderCell as HeaderCellOrig,
  HeaderRow,
  withIndex,
} from 'app/common/components/Table';

import OveflowedText from 'app/common/components/OveflowedText';
import type { SortHandle, SortState } from 'app/common/containers/withSortState';
import type { TotalType } from 'app/actions/reports/subsidies/data';
import conglomerationTableSizes from 'app/common/containers/conglomerationTableSizes';
import NinePartsTable from 'app/components/Reports/common/NinePartsTable';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import enhanceSchools from './enhanceSchools';
import type { SchoolType } from './selectActual';

import ts from '../Table.scss';


// Больше не задействовано в этом отчете

const cx = classNames.bind(ts);

const table1Width = 200;
const table3Width = 130;
const table2Width = 1100 - (table1Width + table3Width);

const defaultHoverState = [-1, -1];
const Cell = withIndex(defaultHoverState)(CellOrig);
const HeaderCell = withIndex(defaultHoverState)(HeaderCellOrig);
const Row = withIndex(defaultHoverState)(RowOrig);

/**
 * Таблица отчета по классам
 */

type Props = {
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  refTd: (el: React.ElementRef<any> | null) => void,
  schools: Array<SchoolType>,
  totals: Array<TotalType>,
  currentPage: number,
  total: number,
  rows: number,
  sort: SortState,
  handleSort: SortHandle,
  sizes: Object,
  openInfoIdx: number,
}

const Schools = ({
  handlePage,
  refTd,
  schools,
  totals,
  currentPage,
  total,
  rows,
  sort,
  handleSort,
  sizes,
}: Props) => {
  // state индекс ячейки под мышкой [rowNumber, colNumber]
  const [hoverIndex, handleHover] = React.useState(defaultHoverState);
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <React.Fragment>
      <DetailsHeader reportTitle="Дотации" />
      <NinePartsTable
        handlePage={handlePage}
        currentPage={currentPage}
        total={total}
        rows={rows}
        currentPageSize={length(schools)}
        leftWidth={table1Width}
        rightWidth={table3Width}
        topLeftContent={
          <HeaderRowSortable
            className={ts.headerRow}
            onSort={handleSort}
            currentSortKey={sort.sortKey}
            currentSortDirection={sort.sortDirection}
          >
            <HeaderCell
              className={cx(ts.cell, ts.firstCell, ts.hCell)}
              key="schoolTitle"
              sortKey="schoolTitle"
            >
              {'Школа'}
            </HeaderCell>
          </HeaderRowSortable>
        }
        topCenterContent={
          <HeaderRow
            className={ts.headerRow}
          >
            {totals.map(({ idx, title }) => (<HeaderCell
              key={idx}
              className={cx(ts.cell, ts.hCell)}
              alignRight
              width={sizes[`sum_${idx}`]}
              idx={[-1, idx]}
              hovered={idx === hoverIndex[1]}
              onHover={handleHover}
            >
              {title}
            </HeaderCell>))}
          </HeaderRow>
        }
        topRightContent={
          <HeaderRowSortable
            className={ts.headerRow}
            onSort={handleSort}
            currentSortKey={sort.sortKey}
            currentSortDirection={sort.sortDirection}
          >
            <HeaderCell
              className={cx(ts.cell, ts.hCell, ts.lastCell)}
              key="total"
              sortKey="total"
              alignRight
            >
              {`Всего${currency}`}
            </HeaderCell>
          </HeaderRowSortable>
        }
        middleLeftContent={
          schools.map(({ idx, schoolTitle }) => (<Row
            key={idx}
            idx={[idx, -1]}
            onHover={handleHover}
            hovered={idx === hoverIndex[0]}
          >
            <Cell className={cx(ts.cell, ts.firstCell)}>
              <OveflowedText>
                {schoolTitle}
              </OveflowedText>
            </Cell>
          </Row>))
        }
        middleCenterContent={
          schools.map(({ idx, data }) => (<Row
            key={idx}
            hovered={idx === hoverIndex[0]}
          >
            {data.map(({ idx: idx2, sum }) => (<Cell
              key={idx2}
              className={cx(ts.cell, { tinted: sum === '0.00' })}
              width={sizes[`sum_${idx2}`]}
              alignRight
              idx={[idx, idx2]}
              hovered={idx2 === hoverIndex[1]}
              onHover={handleHover}
            >
              {sum}
            </Cell>))}
          </Row>))
        }
        middleRightContent={
          schools.map(({ idx, total: t }) => (<Row
            key={idx}
            idx={[idx, -1]}
            onHover={handleHover}
            hovered={idx === hoverIndex[0]}
          >
            <Cell className={cx(ts.cell, ts.lastCell)} alignRight>
              <OveflowedText>
                {t}
              </OveflowedText>
            </Cell>
          </Row>))
        }
        bottomLeftContent={
          <Row className={ts.disableHover}>
            <Cell className={cx(ts.cell, ts.fCell)} alignRight>{'Итого:'}</Cell>
          </Row>
        }
        bottomCenterContent={
          <Row className={ts.disableHover}>
            {totals.map(({ idx, sum }) => (<Cell
              key={idx}
              className={cx(ts.cell, ts.fCell, ts.backlightCell)}
              width={sizes[`sum_${idx}`]}
              alignRight
              idx={[-1, idx]}
              hovered={idx === hoverIndex[1]}
              onHover={handleHover}
            >
              {sum}
            </Cell>))}
          </Row>
        }
        bottomRightContent={
          <Row className={ts.disableHover}>
            <Cell className={cx(ts.cell, ts.fCell)}>&nbsp;</Cell>
          </Row>
        }
      />

      {/* Скрытая таблица для расчета ширины колонок */}
      {/* Внутренняя часть */}
      <table
        className={ts.hiddenTable}
        style={{ minWidth: table2Width - 4 }}
        ref={refTd}
        name="middle"
      >
        <thead>
          <tr>
            {totals.map(({ idx, title }) => (<td key={idx} className={ts.cellHidden} ref={refTd} name={`sum_${idx}`}>
              {title}
            </td>))}
          </tr>
        </thead>
        <tbody>
          {schools.map(({ idx, data }) => (<tr key={idx}>
            {data.map(({ idx: idx2, sum }) => (<td key={idx2} className={ts.cellHidden}>
              {sum}
            </td>))}
          </tr>))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default enhanceSchools(conglomerationTableSizes(Schools));
