// @flow
import React from 'react';
import classNames from 'classnames/bind';
import styles from './Cell.scss';


const cx = classNames.bind(styles);

type Props = {
  grow?: number,
  children: any,
  className?: string,
  alignRight?: boolean,
  alignMiddle?: boolean,
};

const Cell = ({ children, grow = 1, className, alignRight, alignMiddle }: Props) => (<div
  className={cx({
    root1: true,
    [`grow${grow}`]: true,
    alignRight,
    alignMiddle,
  }, className)}
>
  {children}
</div>);

export default Cell;
