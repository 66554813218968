// @flow
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';
import path from 'ramda/es/path';
import cond from 'ramda/es/cond';
import equals from 'ramda/es/equals';
import always from 'ramda/es/always';
import T from 'ramda/es/T';

import api from 'app/actions/api';
import action from 'app/common/lib/action';
import { checkStatuses } from 'app/common/constants/identifiers';


export const IDENTIFIER_CHECK_REQUEST = 'employee/IDENTIFIER_CHECK_REQUEST';
export const IDENTIFIER_CHECK_SUCCESS = 'employee/IDENTIFIER_CHECK_SUCCESS';
export const IDENTIFIER_CHECK_FAILURE = 'employee/IDENTIFIER_CHECK_FAILURE';

export const checkRequest = (id: number, num: string): Object => async (dispatch) => {
  const data = { number: num };
  dispatch(action(IDENTIFIER_CHECK_REQUEST, { id, data }));
  try {
    const res = await api(`food/employee/${id}/card/check`, 'post', { data });
    const checkResult = pipe(
      prop('data'),
      omit(['result']),
    )(res);
    dispatch(action(IDENTIFIER_CHECK_SUCCESS, checkResult));
    return checkResult;
  } catch (error) {
    const code = path(['response', 'data', 'symbol'], error);
    const res = cond([
      [equals('number_already_used'), always(checkStatuses.ALREADY_USED)],
      [equals('number_not_found'), always(checkStatuses.UNKNOWN)],
      [equals('number_not_found_in_white_list'), always(checkStatuses.BLOCKED)],
      [T, always(checkStatuses.UNSUPPORTED)],
    ])(code);
    // не нужно отображать сообщение об ошибке, когда идентификатор не определен
    // if (res !== checkStatuses.UNKNOWN) {
    //   dispatch(action(IDENTIFIER_CHECK_FAILURE, {
    //     err: 'Не удалось получить тип идентификатора',
    //     error,
    //     id,
    //     data,
    //   }));
    // }
    return { error: res };
  }
};
