// @flow
import React, { type Ref, useCallback, useMemo } from 'react';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';

import { PlainGroup, PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainDropdown, { PlainDropdownDefitem as DItem } from 'app/common/ui-next/plain-dropdown';
import { DotsLoader } from 'app/common/components/Loader';

import useFoodProvider from './useFoodProvider';
import type { RoleItem } from './useRoles';
import styles from './JobPlacesOffice.scss';


export const dateFormat = 'YYYY-MM-DD';

type Props = {
  formRef: Ref<'form'>,
  locked: boolean,
  roles: Array<RoleItem>,
  currentRoleId: ?number,
  onChangeRole: ({ id: number }) => void,
  onSubmit: () => Promise<void>,
}

const JobPlacesOffice = ({
  formRef,
  locked,
  roles,
  currentRoleId,
  onChangeRole,
  onSubmit,
}: Props) => {
  const title = useFoodProvider();

  const submit = useCallback((e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  }, [onSubmit]);

  const currentRoleTitle = useMemo(() => {
    const r: ?RoleItem = find(propEq('id', currentRoleId), roles);
    return (r ? r.title : 'Не используется');
  }, [currentRoleId, roles]);

  return (
    <form onSubmit={submit} className={styles.form} ref={formRef}>
      <PlainGroup
        header="Место работы"
        className={styles.place}
      >
        <PlainColumns>
          <PlainColumn>
            <PlainLabel htmlFor="org" className={styles.orgLabel}>
              {'Поставщик питания'}
            </PlainLabel>
            <div id="org" className={styles.org}>
              {title || <DotsLoader simple />}
            </div>
          </PlainColumn>
        </PlainColumns>

        <PlainColumns>
          <PlainColumn div={2} className={styles.role}>
            <PlainLabel id="role_label">
              {'Должность'}
            </PlainLabel>

            <PlainDropdown
              id="role"
              labelId="role_label"
              itemsContainerClassName={styles.role}
              onChange={onChangeRole}
              disabled={locked}
              placeholder={
                <DItem
                  id={currentRoleId || -1}
                  disabled={locked}
                >
                  {currentRoleTitle}
                </DItem>
              }
              defaultSkin
            >
              {roles.map(({ title: title_, id: id_ }) => (
                <DItem key={id_} id={id_} active={id_ === currentRoleId}>
                  {title_}
                </DItem>
              ))}
            </PlainDropdown>

          </PlainColumn>
        </PlainColumns>

      </PlainGroup>

      {!locked
        && <button
          type="submit"
          name="submit"
          className={styles.submitHidden}
        >
          {'Сохранить'}
        </button>}
    </form>
  );
};

export default JobPlacesOffice;
