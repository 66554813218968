import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon'
import { rootRights, specificRights } from 'app/rights'
import { WithRightsForOrg } from 'app/rights/RightsForOrg'

import { ValidityByDate } from '../../../commonComponents/ValidityByDate'
import NewEditKit from '../../NewEditKit'
import styles from './HeaderContent.scss'


const complexesRigths = [rootRights.COMPLEXES, specificRights.COMPLEXES] as const

type Props = {
  id: number
  orgID: string
  title: string
  cost: string | '—'
  currency: string
}

const HeaderContent = ({ id, orgID, title, cost, currency }: Props) => {
  return (
    <div className={styles.root}>
      <h3 className={styles.header}>{title}</h3>

      <div className={styles.price}>
        <span>{cost}</span>
        {cost !== '—' && <span>{currency}</span>}
      </div>

      <div className={styles.edit}>
        <WithRightsForOrg pathTo={complexesRigths} editAffect="hide">
          <ValidityByDate affect="hide">
            <NewEditKit orgId={parseInt(orgID, 10)} kitId={id}>
              {toggleNewEditKit => (
                <PlainButtonAsLink
                  ariaLabel="Редактирование названия и стоимости комплекта"
                  onClick={toggleNewEditKit}
                >
                  <PlainIcon
                    width={15}
                    height={15}
                    type={iconTypes.PEN}
                    colorType={colorTypes.COLOR_DARKGRAY}
                  />
                </PlainButtonAsLink>
              )}
            </NewEditKit>
          </ValidityByDate>
        </WithRightsForOrg>
      </div>
    </div>
  )
}

export default HeaderContent
