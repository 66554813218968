import { useCallback, useRef, useEffect, ChangeEvent } from 'react'
import classNames from 'classnames'
import nest from 'recompose/nest'
import isEmpty from 'ramda/es/isEmpty'

import RowLayout, { HeaderContent, RowLayoutContent, Header } from 'app/common/layouts/RowLayout'
import PlainButton from 'app/common/ui-next/plain-button'
import CSSSticky from 'app/common/ui-components/css-sticky'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import Loading from 'app/common/components/Loader'
import useScrollToError from 'app/common/hooks/useScrollToError'

import SubsidiesData, { useSubsidiesData, SplittingType } from './SubsidiesData'
import SubsidiesSplittingAll from './SubsidiesSplittingAll'
import useParallels from './useParallels'
import ParallelsForm from './ParallelsForm'

import styles from './Subsidies.scss'


const comment = 'Распределение дотации на виды бюджета. Распределение необходимо для учета дотаций с городского бюджета и краевого (областного, федерального). Распределение будет рассчитываться в отчете «Дотации/Акт приемки».'
const labelSplit = 'Распределять дотации на виды бюджета'

type Props = {
  writeAccess: boolean,
}

const Subsidies = ({ writeAccess }: Props) => {
  const scrollToError = useScrollToError()

  const [{
    splits,
    splittingType,
    modified: modifiedSubsidies,
    loading: loadingSubsidies,
    submitting: submittingSubsidies,
  }, {
    request,
    submit: submitSubsidies,
    checkErrors,
  }, {
    changeSplittingType,
  }] = useSubsidiesData()

  const {
    parallels,
    modified: modifiedParallels,
    loading: loadingParallels,
    submitting: submittingParallels,
    change,
    submit: submitParallels,
  } = useParallels()

  useEffect(() => {
    request()
  }, [request])

  const formRef = useRef(null)

  const handleSave = useCallback(() => {
    if (formRef.current) {
      (formRef.current as any).submit.click()
    }
  }, [])

  const handleSubmit = useCallback((e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!writeAccess) return
    if (!checkErrors()) {
      scrollToError()
      return
    }
    submitSubsidies()
    submitParallels()
  }, [checkErrors, submitSubsidies, submitParallels, scrollToError, writeAccess])

  const handleSplittingCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    if (checked) {
      changeSplittingType(SplittingType.All)
      return
    }
    changeSplittingType(null)
  }, [changeSplittingType])


  const submitting = submittingSubsidies || submittingParallels
  const modified = modifiedSubsidies || modifiedParallels


  return (
    <RowLayout paddingfree>
      <CSSSticky
        number={2}
        className={styles.stickableHeader}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
      >
        <HeaderContent
          className={classNames(styles.topBorderOnSticky, styles.headerContent)}
          borderBottom
        >
          <Header>{'Дотации'}</Header>
          {!writeAccess && <div>{'Отсутствует доступ на редактирование'}</div>}
          {modified
            && writeAccess
            && (
              <PlainButton
                disabled={!modified || submitting}
                onClick={handleSave}
              >
                {submitting ? 'Сохранение...' : 'Сохранить'}
              </PlainButton>
            )}
        </HeaderContent>
      </CSSSticky>

      <RowLayoutContent>
        <form onSubmit={handleSubmit} ref={formRef}>
          <ParallelsForm
            parallelsChecked={parallels}
            disable={submitting || loadingParallels || !writeAccess}
            onChange={change}
          />

          <PlainCheckbox
            value="splitOrNot"
            label={labelSplit}
            checked={Boolean(splittingType)}
            disabled={loadingSubsidies || !writeAccess}
            onChange={handleSplittingCheck}
          />

          {/* Комментарий мелким шрифтом */}
          <div className={styles.comment}>{comment}</div>

          {loadingSubsidies
            && isEmpty(splits)
            && <Loading />}

          {splittingType === SplittingType.All
            && <SubsidiesSplittingAll disabled={!writeAccess} />}

          <button type="submit" name="submit" className={styles.hidden}>
            {'Сохранить'}
          </button>
        </form>
      </RowLayoutContent>
    </RowLayout>
  )
}

export default nest(SubsidiesData, Subsidies)
