import { Header2 } from 'app/common/templates-next/page-template'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'


type Props = {
  formID?: string
  title: string
  modified?: boolean
  disabled?: boolean
  submitting?: boolean
  onClickCancel?: () => void
  onClickSave?: () => void
}

const HeaderWithSubmit = ({
  formID,
  title,
  modified,
  disabled,
  submitting,
  onClickCancel,
  onClickSave,
}: Props) => {
  return (
    <ControlsBar spaceBetween top>
      <Header2 skipMargin>
        {title}
      </Header2>
      {!!formID
        && modified
        && <ControlsBar>
          <PlainButton
            onClick={onClickCancel}
            disabled={submitting}
            outline
            form={formID}
          >
            {'Отмена'}
          </PlainButton>
          <PlainButton
            onClick={onClickSave}
            type="submit"
            disabled={disabled || submitting}
            form={formID}
          >
            {submitting ? 'Сохранение...' : 'Сохранить'}
          </PlainButton>
        </ControlsBar>}
    </ControlsBar>
  )
}

export default HeaderWithSubmit
