// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import * as statuses from 'app/common/constants/identifiers/statuses';
import { OptionProp } from 'app/common/ui-next/option-props';

import { Status } from './markup';


type Props = {
  status: $Values<typeof statuses>,
  expiry: ?string,
  updated: ?string,
  timezone: string,
};

const PropStatus = ({ status, expiry: utExpiry, updated: utUpdated, timezone }: Props) => {
  const { t } = useTranslation('identifiers-next');
  const expiry = moment(utExpiry).tz(timezone).format('DD.MM.YYYY H:mm');
  const updated = moment(utUpdated).tz(timezone).format('DD.MM.YYYY H:mm');

  return (
    <OptionProp title="Статус">
      <Status status={status}>
        {status === statuses.STATUS_ACTIVE && t('status_active')}
        {status === statuses.STATUS_WITHDRAWN && t('status_withdrawn')}
        {status === statuses.STATUS_BLOCKED && t('status_blocked')}
        {status === statuses.STATUS_PAY_WAITING && t('status_pay_waiting')}
        {status === statuses.STATUS_RETURN && t('status_return')}
      </Status>
      {' '}

      {status === statuses.STATUS_ACTIVE
        && utExpiry
        && t('status_info_active_n_expiry', { updated, expiry })}

      {status !== statuses.STATUS_ACTIVE
        && utExpiry
        && utExpiry !== utUpdated
        && t('status_info_expiry', { updated, expiry })}

      {(!utExpiry || utExpiry === utUpdated)
        && utUpdated
        && t('status_info_updated', { updated })}

    </OptionProp>
  );
};

export default PropStatus;
