// @flow
import React, { type Node, Fragment, useMemo, useCallback, useState } from 'react';
import { groupBy, prop, map } from 'ramda';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
} from 'app/common/ui-next/plain-modal';

import getNumEnding from 'app/common/lib/getNumEnding';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';

import styles from './DishesDeleteModal.scss';


const byNum = ['категории', 'категорий', 'категорий'];
const getCategoryName = (catId?: string, cats: Map<string, Category>) => {
  return ((catId && cats.get(catId)) || { title: '???' }).title;
};

type Props = {
  dishes: Array<Dish>,
  relatedCategories: Map<string, Category>,
  onClose: () => void,
  deleteSelection: () => Promise<boolean>,
}

const DishesDeleteModal = ({ dishes, relatedCategories, onClose, deleteSelection }: Props): Node => {
  const categoriesNumber = relatedCategories.size;
  const [pending, setPending] = useState(false);

  const categoryName = (
    categoriesNumber === 1
      ? getCategoryName(dishes[0].categoryId, relatedCategories)
      : ''
  );

  const describe = (
    categoriesNumber === 1
      ? `Следующая продукция из категории «${categoryName}» будет удалена из нее:`
      : `Следующая продукция из ${categoriesNumber} ${getNumEnding(categoriesNumber, byNum)} будет удалена из них.`
  );

  const handleSubmit = useCallback(async () => {
    setPending(true);
    const res = await deleteSelection();
    setPending(false);
    if (res) onClose();
  }, [deleteSelection, onClose]);

  const data: Array<{ id: string, title: string, items: Array<Dish> }> = useMemo(() => {
    const dishesByCategoryId = groupBy(prop('categoryId'), dishes);
    return map(({ id, title }) => ({
      id,
      title,
      items: dishesByCategoryId[id],
    }), Array.from(relatedCategories.values()));
  }, [dishes, relatedCategories]);

  return (
    <PlainModal
      className={styles.root}
      show
    >
      <PlainModalHeader onCrossClick={onClose}>
        {'Удаление продукции'}
      </PlainModalHeader>

      <PlainModalContent>
        <p className={styles.describe}>{describe}</p>

        {data.map(({ id: categoryId, title: categoryTitle, items }) => (
          <Fragment key={categoryId}>
            {categoriesNumber > 1
              && <p className={styles.describe}>{`${categoryTitle}:`}</p>}
            <ul>
              {items.map(({ id, title }) => (
                <li key={id}>{title}</li>
              ))}
            </ul>
          </Fragment>
        ))}
      </PlainModalContent>

      <PlainModalFooter>
        <ControlsBar middle>
          <PlainButton onClick={handleSubmit}>
            {pending ? 'Удаление...' : 'Удалить'}
          </PlainButton>
          <PlainButton
            outline
            onClick={onClose}
          >
            {'Отмена'}
          </PlainButton>
        </ControlsBar>
      </PlainModalFooter>
    </PlainModal>
  );
};

export default DishesDeleteModal;
