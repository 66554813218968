import { ReactNode } from 'react'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import { CompensationDetailsModal } from './CompensationDetailsModal'


type Props = {
  orgID: string
  orgTitle: string
  date: string
  children: string
  compensationDetailsTable: (params: { orgID: string, allowanceID: string }) => ReactNode
  compensationDetailsTableFooter: () => ReactNode
}

export const AllowanceCell = ({
  orgID,
  orgTitle,
  date,
  children,
  compensationDetailsTable,
  compensationDetailsTableFooter,
}: Props) => {

  return (
    <CompensationDetailsModal
      title={`Детализация компенсаций ${date}, ${orgTitle}, категория «${children}»`}
      compensationDetailsTable={compensationDetailsTable({ orgID, allowanceID: children })}
      compensationDetailsTableFooter={compensationDetailsTableFooter()}
    >
      {toggle => (
        <PlainButtonAsLink onClick={toggle}>
          {children}
        </PlainButtonAsLink>
      )}
    </CompensationDetailsModal>
  )
}
