// @flow
import values from 'ramda/es/values';
import prop from 'ramda/es/prop';
import reduce from 'ramda/es/reduce';
import pipe from 'ramda/es/pipe';

import { defaultStateDataCurrent } from 'app/common/ui-components/filter';
import { api } from 'app/api';
import { GET_ORGS } from 'app/common/api/requests/food/schools';


const filterGetItemsRoles = async () => {
  const res = await api.request(GET_ORGS, {
    error: 'Не удалось получить данные для фильтра по месту работы',
  });

  if (res) {
    const schools = prop('schools', res);

    if (schools) {
      const jobPlacesArray = pipe(
        values,
        reduce((acc, { id, title, deleted }) => {
          if (deleted) {
            return acc;
          }
          acc.push({ id, title });
          return acc;
        }, []),
      )(schools);

      return ([jobPlacesArray, defaultStateDataCurrent]);
    }
  }

  return ([[], defaultStateDataCurrent]);
};

export default filterGetItemsRoles;
