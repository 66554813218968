// @flow
import { useState, useCallback } from 'react';
import path from 'ramda/es/path';

import { balanceReservesStatuses } from 'app/common/api/general.types/cafe.balance.reserves';


export const statusAny: 999 = 999;
type StatusType = $Values<typeof balanceReservesStatuses> | 999

export default function useReservationFilter(defaultFrom: string, defaultTo: string) {
  const [status, setstatus] = useState<StatusType>(statusAny);
  const dateFromState = useState<string>(defaultFrom);
  const dateToState = useState<string>(defaultTo);

  const handleStatus = useCallback((e: Object) => {
    const value = path(['currentTarget', 'value'], e);
    setstatus(value);
  }, []);

  return [[status, handleStatus], dateFromState, dateToState];
}
