// @flow
import type { Request } from '../../../index';

type UrlParams = {
  orgId: number | string,
  complexId: number | string,
}

type ComplexUpdateSoldItemsParams = {
  date: string,
}

type ComplexUpdateSoldItemsResult = 'success'

export const POST_COMPLEX_UPDATE_SOLD_ITEMS: Request<
  ComplexUpdateSoldItemsParams,
  ComplexUpdateSoldItemsResult,
  UrlParams
> = [
  'food/schools/{orgId}/complexes/{complexId}/update_sold_items',
  'post',
  ['data', 'result'],
];
