import { useMemo } from 'react'
import { map } from 'ramda'

import { Tr, Th } from 'app/common/ui-components/table'
import { useCurrencySign } from 'app/common/components-next/currency'

import { idsHeaderFirstRow, idsHeaderSecondRow, idsEnterprise, getTitles, propsHeader as commonProps, propsEnterprise } from './columns'
import GearContainer from './GearContainer'


const empty = []

type Props = {
  enterprise?: boolean
  shadow?: boolean
  stuck?: boolean
}

const OrgsListTableTHead = ({ enterprise, shadow, stuck }: Props) => {
  const currency = useCurrencySign()
  const titles = useMemo(() => getTitles(currency), [currency])
  const source1 = enterprise ? idsEnterprise : idsHeaderFirstRow
  const source2 = enterprise ? empty : idsHeaderSecondRow
  const propsCommon = enterprise ? propsEnterprise : commonProps

  const props = useMemo(() => {
    return map(({ rowSpan, colSpan, ...others }) => {
      const topCompensateAvailable = enterprise || rowSpan === 2 || colSpan > 1
      const shadowAvailable = enterprise || rowSpan === 2 || colSpan === 1 || !colSpan
      return {
        borderShadowBottom: shadow && shadowAvailable,
        borderTopCompensate: stuck && topCompensateAvailable,
        bgFilled: true,
        borderBottom: true,
        rowSpan,
        colSpan,
        ...others,
      }
    }, propsCommon)
  }, [propsCommon, stuck, shadow, enterprise])

  return (
    <>
      <Tr header headerHasBorderBottom={false}>
        {source1.map(id => (
          <Th
            key={id}
            {...(props[id])}
          >
            {id === 'settings'
              ? <GearContainer />
              : titles[id]}
          </Th>
        ))}
      </Tr>
      <Tr header headerHasBorderBottom={false}>
        {source2.map(id => (
          <Th
            key={id}
            {...(props[id])}
            ignoreFirstChild
            ignoreLastChild
          >
            {id === 'settings'
              ? <GearContainer />
              : titles[id]}
          </Th>
        ))}
      </Tr>
    </>
  )
}

export default OrgsListTableTHead
