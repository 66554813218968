
// все финансовые отчеты
export enum FinanceReportType {
  EmoneyLegacy = 'emoney-legacy',
  Emoney = 'emoney',
  BySellType = 'by-sell-type',
  CashBox = 'cashbox',
  EmoneySales = 'emoney-sales'
}

export const isFinanceReport = (mayBeReport: string): mayBeReport is FinanceReportType => {
  return Object.values(FinanceReportType).includes(mayBeReport as FinanceReportType)
}
