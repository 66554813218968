// @flow
import path from 'ramda/es/path';

import { dateFormat } from 'app/common/api/requests/food/schools/cafeteria_orders';
import { getStateCurrentValue, getStateItems, type State } from 'app/common/ui-components/filter';
import { calcPeriodFromStart } from 'app/common/ui-next/plain-date-period';

import yearFilter from 'app/components/common/reports/filters/year';


const dummyItems = [{ id: 'dummy', title: 'dummy' }];

const periodItems = (state: State) => {
  const yearCurrentIds = getStateCurrentValue('year', state);
  const years = getStateItems(yearFilter, state);
  const selectedYearId = yearCurrentIds[0];

  const dateStart = path([selectedYearId, 'date_start'], years);
  const dateFinish = path([selectedYearId, 'date_finish'], years);
  const period = calcPeriodFromStart(dateStart, dateFinish, 'month', dateFormat);

  return ([dummyItems, period]);
};

export default periodItems;
