import { useCallback } from 'react'

import { api } from 'app/api'
import { PUT_EDIT_COMPLEX } from 'app/common/api/requests/food/schools/complexes'


type ComplexData = {
  // TODO
  // photo
  // fixed_price_enabled
  // fixed_price
  // title
  buffetZone: boolean
  hotMealsZone: boolean
  denyParentMoneySales: boolean
  denyMoreThanOneSales: boolean
  useAsAdditional: boolean
}

type Options = {
  orgID: string
  complexID: string
}

export default function useComplexActions({ orgID, complexID }: Options) {
  const submit = useCallback(async ({
    buffetZone,
    hotMealsZone,
    denyParentMoneySales,
    denyMoreThanOneSales,
    useAsAdditional,
  }: ComplexData) => {
    const res = await api.request(PUT_EDIT_COMPLEX, {
      error: 'Не удалось сохранить данные комплекта',
      params: {
        // TODO
        // photo
        // fixed_price_enabled
        // fixed_price
        // title
        buffet_zone: buffetZone,
        hot_meals_zone: hotMealsZone,
        deny_parent_money_sales: denyParentMoneySales,
        deny_more_than_one_sales: denyMoreThanOneSales,
        use_as_additional: useAsAdditional,
      },
      requestPathParams: {
        orgId: orgID,
        complexId: complexID,
      },
      // TODO
      // convertions: {
      //   convertParams,
      //   convertResult,
      // },
    })

    if (res) {
      return true
    }
    return false
  }, [orgID, complexID])

  return { submit }
}
