import type { Request } from '../../index'


export const dateFormat: 'DD.MM' = 'DD.MM'

export type SubsidyOrdersRestrictions = {
  common: { // для общих справок
    enabled: boolean, // включено
    type: 'month' | 'periods', // ограничение периодами (по умолчанию) или месяцами как для индивидуальных
    period_1: [string, string], // даты начала и конца 1 периода
    period_2: [string, string], // даты начала и конца 2 периода
  },
  personal: { // для индивидуальных справок
    enabled: boolean, // включено
  }
}

export const defaultSubsidyOrdersRestrictions: SubsidyOrdersRestrictions = {
  common: {
    enabled: false,
    type: 'periods',
    period_1: ['', ''],
    period_2: ['', ''],
  },
  personal: {
    enabled: false,
  },
}

type UrlParams = {
  orgId: string | number,
}

export const GET_SUBSIDY_ORDERS_RESTRICTIONS: Request<
  void,
  SubsidyOrdersRestrictions,
  UrlParams
> = ([
  'food/schools/{orgId}/subsidy_orders_restrictions',
  'get',
  ['data', 'restrictions'],
])

// POST
type Params = {
  schools_ids: Array<string> | Array<number>,
  restrictions: {
    common?: {
      enabled: boolean,
      type: 'month' | 'periods',
      period_1: [string, string],
      period_2: [string, string],
    },
    personal?: {
      enabled: boolean,
    }
  },
}

export const POST_SUBSIDY_ORDERS_RESTRICTIONS: Request<
  Params,
  'success',
  void
> = ([
  'food/schools/subsidy_orders_restrictions',
  'post',
  ['data', 'result'],
])
