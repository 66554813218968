import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import path from 'ramda/es/path';

import {
  DATEFROMTO_FILTER_SET,
  dateFormat,
} from 'app/actions/reports/filters/dateFromTo';

import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import action from 'app/common/lib/action';

import { selectYearId } from './commonSelectors';


const selectStartDate = createSelector(
  [
    selectYearId,
    state => state.reports.filters.years.data,
  ],
  (yearId, years) => {
    const dateFromSelectedYear = path([yearId, 'dateStart'], years);
    // ограничение по выбранному учебному году, если выбран
    if (dateFromSelectedYear) {
      return moment(dateFromSelectedYear, dateFormat);
    }
    // иначе без без ограничений в прошлом, но подтягивать в lifecycle парную дату при изменении
    return null;
  },
);

const selectFinDate = createSelector(
  [
    selectYearId,
    state => state.reports.filters.years.data,
  ],
  (yearId, years) => {
    const dateFinSelectedYear = path([yearId, 'dateFinish'], years);
    // ограничение по выбранному учебному году, если выбран
    if (dateFinSelectedYear) {
      return moment.min(moment(dateFinSelectedYear, dateFormat), moment());
    }
    // иначе без ограничений в прошлом, но подтягивать в lifecycle парную дату при изменении
    return moment();
  },
);

export default compose(
  connect(
    (state, props) => ({
      dateFrom: state.reports.filters.dateFromTo.dateFrom,
      dateTo: state.reports.filters.dateFromTo.dateTo,
      loading: state.reports.filters.years.active && state.reports.filters.years.loading,
      mRestrictedStartDate: selectStartDate(state, props),
      mRestrictedFinalDate: selectFinDate(state, props),
    }),
    dispatch => ({
      handleChangeFrom: (date) => {
        if (date) {
          dispatch(action(DATEFROMTO_FILTER_SET, { dateFrom: date }));
        }
      },
      handleChangeTo: (date) => {
        if (date) {
          dispatch(action(DATEFROMTO_FILTER_SET, { dateTo: date }));
        }
      },
      dispatch,
    }),
  ),

  withHandlers({
    // инициализация по умолчанию,
    // диапазон равен выбранному месяцу назад от даты 'сегодня'
    // учитывая учебный год, если он установлен
    reset: ({ mRestrictedStartDate, mRestrictedFinalDate, dispatch }) => (e) => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
      const dateTo = mRestrictedFinalDate.format(dateFormat);
      const mMonthLimitedFrom = mRestrictedFinalDate.clone().startOf('month');
      const dateFrom = (mRestrictedStartDate
        ? moment.max(mMonthLimitedFrom, mRestrictedStartDate).format(dateFormat)
        : mMonthLimitedFrom.format(dateFormat));
      dispatch(action(DATEFROMTO_FILTER_SET, { dateFrom, dateTo }));
    },
    disabledDays: ({ mRestrictedStartDate, mRestrictedFinalDate }) => (dayFormatted: string, format: string, unit?: string = 'day') => {
      const mDay = moment(dayFormatted, format);
      const mToday = moment();
      if (!mRestrictedStartDate || !mRestrictedFinalDate) {
        return mRestrictedFinalDate
          ? mDay.isAfter(moment.min(mToday, mRestrictedFinalDate), unit)
          : mDay.isAfter(mToday, unit);
      }
      const mFinaleDate = moment.min(mToday, mRestrictedFinalDate);
      return !mDay.isBetween(mRestrictedStartDate, mFinaleDate, unit, '[]');
    },
  }),

  lifecycle({
    componentDidMount() {
      const { reset } = this.props;
      setTimeout(() => { // иначе выдает ощибку Maximum update depth exceeded
        reset();
      }, 1000);
    },
    componentDidUpdate(prevProps) {
      const {
        dispatch,
        dateFrom,
        dateTo,
        mRestrictedStartDate,
        mRestrictedFinalDate,
        reset,
        limitPeriod = 1,
        limitType = 'years',
      } = this.props;

      if (limitType && prevProps.limitType !== limitType) {
        const mDateTo = moment(dateTo, dateFormat);
        // притягиваем dateTo, если получается диапазон больше заданного
        const mDateToMax = moment(dateFrom, dateFormat).add(limitPeriod, limitType).subtract(1, 'days');
        const newDateTo = moment.min(mDateToMax, mDateTo).format(dateFormat);
        dispatch(action(DATEFROMTO_FILTER_SET, { dateTo: newDateTo }));
      }
      if (dateFrom !== prevProps.dateFrom) {
        // меняем дату to если from изменилась
        const mDateTo = moment(dateTo, dateFormat);
        const mDateFrom = moment(dateFrom, dateFormat);
        if (mDateFrom.isAfter(mDateTo, 'day')) {
          // если новая дата перескачила dateTo, то уравниваем
          dispatch(action(DATEFROMTO_FILTER_SET, { dateTo: dateFrom }));
        } else {
          // притягиваем dateTo, если получается диапазон больше заданного
          const mDateToMax = moment(dateFrom, dateFormat).add(limitPeriod, limitType).subtract(1, 'days');
          const newDateTo = moment.min(mDateToMax, mDateTo).format(dateFormat);
          dispatch(action(DATEFROMTO_FILTER_SET, { dateTo: newDateTo }));
        }
      }
      if (dateTo !== prevProps.dateTo) {
        // меняем дату from если to изменилась
        const mDateFrom = moment(dateFrom, dateFormat);
        const mDateTo = moment(dateTo, dateFormat);
        if (mDateTo.isBefore(mDateFrom, 'day')) {
          // если новая дата перескачила dateTo, то уравниваем
          dispatch(action(DATEFROMTO_FILTER_SET, { dateFrom: dateTo }));
        } else {
          // притягиваем dateFrom, если получается диапазон больше заданного
          const mDateFromMin = moment(dateTo, dateFormat).subtract(limitPeriod, limitType).add(1, 'days');
          const newDateFrom = moment.max(mDateFromMin, mDateFrom).format(dateFormat);
          dispatch(action(DATEFROMTO_FILTER_SET, { dateFrom: newDateFrom }));
        }
      }

      if (
        // если изменились даты ограничения
        mRestrictedStartDate !== prevProps.mRestrictedStartDate
        || mRestrictedFinalDate !== prevProps.mRestrictedFinalDate
      ) {
        if (
          // и текущие даты периода не вписываются
          !(moment(dateFrom).isBetween(mRestrictedStartDate, mRestrictedFinalDate, 'day', '[]')
          && moment(dateTo).isBetween(mRestrictedStartDate, mRestrictedFinalDate, 'day', '[]'))
        ) {
          // сбрасываем
          reset();
        }
      }
      resetOnStatusChange(this.props, prevProps);
    },
  }),

);
