import { ReportSection, Stub, ReportDetailsRoot } from 'app/common/templates-next/report-template'
import { ReportStatusIndicator } from 'app/components/common/reports/v3/ReportStatusIndicator'
import { useReportResult } from 'app/dataProviders/reports/hooks'
import { FinanceReportType } from 'app/dataTypes/reports/finances'
import { SummaryType } from 'app/dataTypes/reports/data/finances/emoneySales'

import { useCurrencySign } from 'app/common/components-next/currency'
import { Summary } from './Summary'
import { TableLayout } from './tableEmoneySales/TableLayout'


const emptySummary: SummaryType = {
  salesSum: '0.00',
  corrsSum: '0.00',
  totalSum: '0.00',
  sSum: '0.00',
  uRetSum: '0.00',
  transferSum: '0.00',
}

type Props = {
  reportID: null | string
  resetReport: () => void
}

export const Result = ({ reportID, resetReport }: Props) => {
  const currency = useCurrencySign()

  const { requestResult, data, loading } = useReportResult(reportID, FinanceReportType.EmoneySales)
  const { summary: summaryData = null } = data || {}

  const header = !reportID ? '' : 'Отчет «Продажи — ЭДС»'

  return (
    <ReportSection
      header={header}
      skipPadding
    >
      {reportID
        && <ReportStatusIndicator
          reportID={reportID}
          onFail={resetReport}
          onSuccess={requestResult}
        />}

      {!reportID
        && <Stub />}

      {reportID
        && <Summary
          summaryData={summaryData || emptySummary}
          currency={currency}
          loading={loading}
        />}

      {data
        && <ReportDetailsRoot header="Детализация отчета «Продажи — ЭДС»">
          <TableLayout data={data} />
        </ReportDetailsRoot>}
    </ReportSection>
  )
}
