/* eslint-disable ft-flow/no-types-missing-file-annotation */
import { createSelector } from 'reselect';
import sortWith from 'ramda/es/sortWith';
import lensPath from 'ramda/es/lensPath';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import values from 'ramda/es/values';
import prop from 'ramda/es/prop';
import ascend from 'ramda/es/ascend';
import descend from 'ramda/es/descend';
import view from 'ramda/es/view';
import isEmpty from 'ramda/es/isEmpty';
import sum from 'ramda/es/sum';
import trim from 'ramda/es/trim';
import { filter } from 'ramda';

import toFixedValue from 'app/common/lib/toFixedValue';
import get from 'app/common/lib/get';


type Props = {
  statePath?: string | Array<string>,
};

const dishes = pipe(values, map(dish => ({
  id: dish.id,
  title: trim(dish.title),
  portion: dish.portion,
  cost: toFixedValue(parseFloat(dish.cost)),
})));

const calcCost = (items, isFixed, fixedPrice) => {
  if (isFixed && fixedPrice !== undefined) {
    return toFixedValue(parseFloat(fixedPrice));
  }
  if (items && !isEmpty(items)) {
    return toFixedValue(parseFloat(
      pipe(values, map(prop('cost')), sum)(items),
    ));
  }
  return '—';
};

const selectOrgsKits = createSelector(
  [
    (state, props: Props) => get(state, props.statePath, state.orgs.kits.items),
  ],
  items => pipe(
    values,
    filter(({ buffet_zone: buffetZone }) => buffetZone),
    map(item => ({
      key: item.id,
      id: item.id,
      isNew: Boolean(item.isNew),
      isEmpty: isEmpty(item.items || ''),
      isDeletable: item.deletable,
      subsidyOrders: Boolean(item.subsidy_orders && !isEmpty(item.subsidy_orders)),
      params: {
        id: item.id,
        title: trim(item.title),
        dishes: dishes(item.items),
        cost: calcCost(item.items, item.fixed_price_enabled, item.fixed_price),
      },
    })),
    sortWith([
      descend(prop('isNew')),
      ascend(prop('isEmpty')),
      ascend(view(lensPath(['params', 'title']))),
    ]),
  )(items),
);

export default selectOrgsKits;
