// @flow
import React from 'react';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import CopySettingsIcon from './icons/copySettingsIcon.svg';
import styles from './CopySettingsButton.scss';


type Props = {
  onClick: (e?: SyntheticEvent<HTMLButtonElement>) => void
}

const CopySettingsButton = ({ onClick }: Props) => {
  return (
    <PlainButtonAsLink onClick={onClick}>
      <CopySettingsIcon className={styles.icon} />
    </PlainButtonAsLink>
  );
};

export default CopySettingsButton;
