// @flow
import React, { useCallback } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';


type Props = {
  onSave: (e: SyntheticEvent<HTMLButtonElement>) => Promise<void> | void,
  onCancel: (e: SyntheticEvent<HTMLButtonElement>) => Promise<void> | void,
  checksBeforeSave?: () => boolean,
  disabled?: boolean,
  saving?: boolean,
  cancelling?: boolean,
}

const SaveCancelButtons = ({ onSave, onCancel, checksBeforeSave, disabled, saving, cancelling }: Props) => {
  const handleChecksBeforeSave = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    if (!checksBeforeSave || checksBeforeSave()) {
      onSave(e);
      return;
    }

    // скролл до элемента с ошибкой
    setTimeout(() => {
      const erElements = document.getElementsByClassName('scrollToError');
      if (erElements && erElements[0]) {
        erElements[0].scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
      }
    }, 500);
  }, [checksBeforeSave, onSave]);


  return (
    <ControlsBar>
      <PlainButton
        onClick={onCancel}
        disabled={disabled}
        outline
      >
        {cancelling ? 'Отменяется...' : 'Отмена'}
      </PlainButton>

      <PlainButton
        onClick={handleChecksBeforeSave}
        disabled={disabled}
      >
        {saving ? 'Сохранение...' : 'Сохранить'}
      </PlainButton>
    </ControlsBar>
  );
};

export default SaveCancelButtons;
