import { ReactNode } from 'react'
import styles from './html-select.scss'


type Props = {
  name?: string
  value?: string
  children?: ReactNode
}

const HTMLSelect = ({ name, value, children }: Props) => {
  return (
    <select
      name={name}
      value={value}
      className={styles.root}
      onChange={() => {}}
      disabled={!value}
    >
      {children}
    </select>
  )
}

export default HTMLSelect
