// @flow
import {
  defaultStateDataCurrent,
  getStateItems,
  type State,
} from 'app/common/ui-components/filter';

import filterDataFilter from '../filterData';
import { getActorsData } from '../filterData/filterDataItems';


const actorItems = async (state: State) => {
  const items = getStateItems(filterDataFilter, state);
  const actors = getActorsData(items);

  return ([actors, defaultStateDataCurrent]);
};

export default actorItems;
