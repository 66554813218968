// @flow
import { type OrgItem } from 'app/common/api/requests/food/schools';

/**
 *  Проверка на необходимость выбора доп. параметров цели для копирования,
 *  Если точка продаж или корпус единственный, то не нужно указывать эти параметры
 */

const hasSingleTarget = (item: OrgItem) => {
  const {
    building_count: buildingCount,
    sale_points_count: salePointsCount,
    // menu_categories_count: menuCategoriesCount, // больше нет категорий
  } = item;

  return !(
    buildingCount > 1
    || salePointsCount > 1
    // || menuCategoriesCount > 1
  );
};

export default hasSingleTarget;
