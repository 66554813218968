// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import { Scrollbars } from 'react-custom-scrollbars-2';
import isEmpty from 'ramda/es/isEmpty';

import { Table, Row, Cell, HeaderCell, HeaderRow } from 'app/common/components/Table';
import Pager from 'app/common/ui/Paging';
import Loader, { DotsLoader } from 'app/common/components/Loader';

import TableWin from '../TableWin';
import withHistoryData from './withHistoryData';
import styles from './HistoryTable.scss';


const cx = classNames.bind(styles);

type HistoryItem = {
  id: number,
  created: string,
  source: string,
  actor: string,
  description: Array<string>,
};

type Props = {
  title: string,
  items: Array<HistoryItem>,
  total: number,
  rows: number,
  onClose: (SyntheticEvent<HTMLElement>) => void,
  currentPage: number,
  handlePage: (SyntheticEvent<HTMLElement>) => void,
  loading: boolean,
  updateScroll: (Object) => void,
  isTopShadow: boolean,
  isBottomShadow: boolean,
};

const HistoryTable = ({
  title,
  items,
  total,
  rows,
  onClose,
  // постраничная навигация
  currentPage,
  handlePage,
  loading,
  // тени
  updateScroll,
  isTopShadow,
  isBottomShadow,
}: Props) => (<TableWin
  title={title}
  onClose={onClose}
>
  <React.Fragment>
    <Table>
      <HeaderRow className={classNames(styles.row, styles.headRow)}>
        <HeaderCell grow={3}>{'Дата изменения'}</HeaderCell>
        <HeaderCell grow={3}>{'Место'}</HeaderCell>
        <HeaderCell grow={4}>{'Кто внес изменения'}</HeaderCell>
        <HeaderCell grow={6}>{'Что было изменено'}</HeaderCell>
      </HeaderRow>
      {loading && isEmpty(items)
        ? <Loader />
        : <Scrollbars
          autoHeight
          autoHeightMin={100}
          autoHeightMax={310}
          className={cx({ isTopShadow, isBottomShadow })}
          onUpdate={updateScroll}
        >
          {isEmpty(items) && <div className={styles.emptyWarn}>
            {'Данные об изменениях отсутствуют'}
          </div>}
          {items.map(({
            id,
            created,
            source,
            actor,
            description,
          }) => (<Row className={styles.row} key={id}>
            <Cell grow={3}>{created}</Cell>
            <Cell grow={3}>{source}</Cell>
            <Cell grow={4}>{actor}</Cell>
            <Cell grow={6}>
              {description.map((item, i) => <div key={i}>{item}</div>)}
            </Cell>
            {loading && <DotsLoader className={styles.dotsLoader} />}
          </Row>))}
        </Scrollbars>}
    </Table>
    {(total > rows)
        && <Pager
          className={styles.pager}
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={items.length}
          onPage={handlePage}
        />}
  </React.Fragment>
</TableWin>);

export default withHistoryData(HistoryTable);
