import { createSelector } from 'reselect';

import { USER_TYPE_STUDENT, USER_TYPE_SUPERVISOR } from 'app/common/constants/users';


const selectRestrictions = createSelector(
  [
    state => state.user.type,
    state => state.dealer.email_force_auth,
  ],
  (userType, emailForceAuth) => ({
    fio: userType !== USER_TYPE_STUDENT && userType !== USER_TYPE_SUPERVISOR,
    phone: userType !== USER_TYPE_SUPERVISOR && !emailForceAuth,
    email: userType !== USER_TYPE_SUPERVISOR,
    password: true,
  }),

);

export default selectRestrictions;
