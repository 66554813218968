// @flow
import React, { type Node } from 'react';

import { Table, Tr, Td } from 'app/common/ui-components/table';


type Props = {
  className: string,
}

const FooterRightContent = ({ className }: Props): Node => {
  return (
    <Table className={className}>
      <tfoot>
        <Tr>
          <Td>
            &nbsp;
          </Td>
        </Tr>
      </tfoot>
    </Table>
  );
};

export default FooterRightContent;
