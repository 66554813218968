import { createSelector } from 'reselect';
import keys from 'ramda/es/keys';


const selectSelectedAll = createSelector(
  [
    state => state.orgs.dishes.selection.items,
    state => state.orgs.dishes.items,
  ],
  (selection, dishes) => (keys(dishes).length === selection.length),
);

export default selectSelectedAll;
