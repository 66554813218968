// @flow
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import nope from 'app/common/lib/nope';

import mapItems from '../mapItems';
import type { Item } from '../mapItems';
import styles from './NavWArrows.scss';


type Props = {
  items: Array<Item>,
}

type State = {
  showArrows: boolean,
}

class NavWArrows extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.ro = new ResizeObserver(this.onNavResize);
  }

  state = {
    showArrows: false,
  };

  onNavResize = () => {
    const { showArrows } = this.state;
    if (this.elRoot
      && this.elItems
      && this.elItems.offsetWidth
      && this.elRoot.offsetWidth) {
      if (showArrows) {
        if (this.elRoot.offsetWidth >= this.elItems.offsetWidth) {
          this.setState({ showArrows: false });
        }
      } else if (this.elRoot.offsetWidth < this.elItems.offsetWidth) {
        this.setState({ showArrows: true });
        if (this.elItems) {
          this.elItems.scrollLeft = 50;
        }
      }
    }
  };

  refRoot = (el: React.ElementRef<'div'> | null) => {
    if (el) {
      this.ro.observe(el);
    }
    this.elRoot = el;
  };

  refItems = (el: React.ElementRef<'div'> | null) => {
    this.elItems = el;
  };

  refContainer = (el: React.ElementRef<'div'> | null) => {
    this.elContainer = el;
  };

  handleScrollRight = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.elContainer) {
      this.elContainer.scrollLeft += 50;
    }
  };

  handleScrollLeft = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    if (this.elContainer) {
      this.elContainer.scrollLeft -= 50;
    }
  };

  ro: Object;

  elItems: React.ElementRef<'div'> | null;

  elContainer: React.ElementRef<'div'> | null;

  elRoot: React.ElementRef<'div'> | null;

  render() {
    const { items } = this.props;
    const { showArrows } = this.state;
    return (
      <div ref={this.refRoot} className={styles.root}>
        {showArrows && <div>
          <a href="prev" onMouseDown={this.handleScrollLeft} onClick={nope}>
            <span className={styles.leftArrow} />
          </a>
        </div>}
        <div ref={this.refContainer} className={styles.container}>
          <div ref={this.refItems} className={styles.items}>
            {mapItems(items)}
          </div>
        </div>
        {showArrows && <div>
          <a href="next" onMouseDown={this.handleScrollRight} onClick={nope}>
            <span className={styles.rightArrow} />
          </a>
        </div>}
      </div>
    );
  }
}

export default NavWArrows;
