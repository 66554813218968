import { ROLES_SUCCESS, ROLES_REQUEST } from 'app/actions/admin/roles';

const defaultState = {
  loading: true,
};

const options = (state = defaultState, { type }) => {
  if (type === ROLES_SUCCESS) {
    return { ...state, loading: false };
  }
  if (type === ROLES_REQUEST) {
    return { ...state, loading: true };
  }
  return state;
};

export default options;
