import { ChangeEvent, useCallback } from 'react'
import cn from 'classnames'

import LineInput from 'app/common/ui/LineInput'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import styles from './BudgetNamesControls.scss'


type Props = {
  groupID: string
  partName: string
  partSuffix?: string
  label: string
  placeholder: string
  focusedLabel1: string
  focusedLabel2: string
  disabled?: boolean
  value1: string | null
  value2: string | null
  onChange: (value: string, fieldName: string) => void
  error1?: string
  error2?: string
}

const BudgetNamesControls = ({
  groupID,
  partName,
  partSuffix,
  label,
  placeholder,
  focusedLabel1,
  focusedLabel2,
  disabled,
  value1,
  value2,
  onChange,
  error1,
  error2,
}: Props) => {
  const fieldName1 = `${partName}1_${partSuffix}_${groupID}`
  const fieldName2 = `${partName}2_${partSuffix}_${groupID}`

  const onChangeRef = useCallbackRef(onChange)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    const count = parseInt(name.replace(/^\D+/, ''), 10)
    onChangeRef.current?.(value, `${partName}${count}_${partSuffix}`)
  }, [onChangeRef, partName, partSuffix])

  return (
    <div className={cn(styles.root, styles.column1)}>
      <div className={styles.label}>{label}</div>
      <div className={getScrollToErrorClassName(!!(error1 || error2))} />
      <LineInput
        placeholder={placeholder}
        focused={focusedLabel1}
        value={value1}
        name={fieldName1}
        onChange={handleChange}
        isError={!!error1}
        errorText={error1}
        disabled={disabled}
      />
      <LineInput
        placeholder={placeholder}
        focused={focusedLabel2}
        value={value2}
        name={fieldName2}
        onChange={handleChange}
        isError={!!error2}
        errorText={error2}
        disabled={disabled}
      />
    </div>
  )
}

export default BudgetNamesControls
