import { ReactNode, Children, useRef, useEffect, useState, useCallback, useMemo, MouseEvent, cloneElement, ReactElement } from 'react'
import classNames from 'classnames/bind'
import groupBy from 'ramda/es/groupBy'

import IconDropDown from 'app/common/icons/dropdown.svg'
import { Scrollbars } from 'react-custom-scrollbars-2'

import { DotsLoader } from 'app/common/templates-next/page-template'
import calcListHeight from './calc-list-height'
import useCorrectPosition from './use-correct-position'
import HTMLSelect from './html-select'

import styles from './plain-dropdown.scss'


const cx = classNames.bind(styles)
const emptyArray = []
const findFocus = () => document.activeElement

export type PlainDropdownProps = {
  id: string
  label?: string
  labelId?: string
  placeholder?: ReactNode
  children: ReactNode
  className?: string
  arrowClassName?: string
  buttonClassName?: string
  itemClassName?: string
  itemsContainerClassName?: string
  labelClassName?: string
  defaultSkin?: boolean
  disabled?: boolean
  loading?: boolean
  errored?: boolean
  hideLabel?: boolean
  keepSelectedElementInList?: boolean
  onChange?: (targetProps: any /* TODO props элемента списка */) => void | Promise<void>
}

const PlainDropdown = ({
  id,
  label,
  labelId: labelIdProp,
  placeholder,
  children,
  className,
  arrowClassName,
  buttonClassName,
  itemClassName,
  itemsContainerClassName,
  labelClassName,
  defaultSkin,
  disabled,
  loading,
  errored,
  hideLabel,
  keepSelectedElementInList,
  onChange,
}: PlainDropdownProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const itemsRef = useRef<HTMLUListElement | null>(null)
  const ddRef = useRef<HTMLDivElement | null>(null)

  /**
   *  Перемещение фокуса внутри списка
   */

  const moveFocusInsideList = useCallback((direction: string) => {
    const currentFocus = findFocus()
    if (!currentFocus) return
    const listDOMElement = itemsRef.current
    if (!listDOMElement) return
    const items = Array.from(listDOMElement.querySelectorAll('li'))
    const number = items.length

    switch (direction) {
    case 'first': {
      items[0].focus()
      break
    }
    case 'next': {
      const whichOne = items.indexOf(currentFocus as HTMLLIElement)
      if (whichOne === number - 1 && buttonRef.current) {
        buttonRef.current.focus()
        break
      }
      items[whichOne + 1].focus()
      break
    }
    case 'prev': {
      const whichOne = items.indexOf(currentFocus as HTMLLIElement)
      if (whichOne === 0 && buttonRef.current) {
        buttonRef.current.focus()
        break
      }
      items[whichOne - 1].focus()
      break
    }
    case 'last': {
      items[number - 1].focus()
      break
    }
    default:
    }
  }, [])

  /**
   *  Открытие/закрытие списка
   */

  const [listboxHidden, setListboxHidden] = useState(true)

  useCorrectPosition(buttonRef, ddRef, listboxHidden)

  const hanldeToggleListbox = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const { clientX, clientY } = e
      if (disabled) return
      if (buttonRef.current && clientX !== 0 && clientY !== 0) {
        buttonRef.current.blur()
      }
      setListboxHidden((state) => {
        if (buttonRef.current) {
          buttonRef.current.setAttribute('aria-expanded', String(state))
        }
        return !state
      })
    },
    [disabled],
  )

  const handleShowListbox = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.setAttribute('aria-expanded', 'true')
      setListboxHidden(false)
    }
  }, [])

  const handleCollapseListbox = useCallback(() => {
    if (buttonRef.current) {
      buttonRef.current.setAttribute('aria-expanded', 'false')
      setListboxHidden(true)
    }
  }, [])

  /**
   *  Закрытие при клике вне dropdown
   */

  const handleClickOutside = useCallback((e: globalThis.MouseEvent) => {
    if (!(e.target as HTMLElement).closest(`#${id}`)) {
      if (buttonRef.current) {
        buttonRef.current.setAttribute('aria-expanded', 'false')
      }
      setListboxHidden(true)
    }
  }, [id])

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    window.addEventListener('scroll', handleCollapseListbox)
    return () => {
      window.removeEventListener('click', handleClickOutside)
      window.removeEventListener('scroll', handleCollapseListbox)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  Группировка активный (выбранный) элемент и другие
   */

  const { items = emptyArray, selectedItem = emptyArray } = useMemo(() => {
    if (keepSelectedElementInList) {
      const childrenElements = Children.toArray(children)
      let SelectedComponent: ReactElement | undefined
      const elementsWithActive = childrenElements.map((Component) => {
        if (typeof Component === 'object' && 'props' in Component) {
          const { props: { selected } } = Component
          if (selected) {
            SelectedComponent = Component
            return cloneElement(Component, { ...Component.props, active: true })
          }
        }
        return Component
      })
      return { items: elementsWithActive, selectedItem: [SelectedComponent] }
    }

    return groupBy((Component) => {
      if (typeof Component === 'object' && 'props' in Component) {
        const { props: { selected } } = Component
        return selected ? 'selectedItem' : 'items'
      }
      return '0'
    }, Children.toArray(children))
  }, [children, keepSelectedElementInList])

  /**
   *  Обработка события выбора элемента
   */

  const handleClickElement = useCallback((e?: MouseEvent<HTMLElement>) => {
    const isMouseClick = Boolean(e && e.clientX && e.clientY)
    const currentFocus = findFocus()

    if (currentFocus && currentFocus.id && onChange) {
      const clickedItemId = currentFocus.id.replace(/^.+_item_/, '')
      const ClickedComponent = items.find((Component) => {
        if (typeof Component === 'object' && 'props' in Component) {
          const { props: { id: itemId } } = Component
          return String(itemId) === clickedItemId
        }
        return false
      })

      if (typeof ClickedComponent === 'object' && 'props' in ClickedComponent) {
        const { props: targetProps } = ClickedComponent
        if (targetProps && !targetProps.disabled) {
          handleCollapseListbox()
          // выделяем элемент фокусом, только если был выбор клавиатурой (не мышью)
          if (buttonRef.current && !isMouseClick) {
            buttonRef.current.focus()
          }
          onChange(targetProps)
        }
      }
    }
  }, [items, onChange, handleCollapseListbox])

  /**
   *  Обработка событий с клавиатуры
   */

  const handleDoKeyAction = useCallback((e) => {
    e.stopPropagation()
    const currentFocus = findFocus()
    const whichKey = e.key

    switch (whichKey) {
    case 'Enter':
      handleClickElement()
      break
    case 'ArrowDown':
      if (buttonRef.current && currentFocus === buttonRef.current) {
        handleShowListbox()
        moveFocusInsideList('first')
        break
      }
      moveFocusInsideList('next')
      break
    case 'ArrowUp':
      if (buttonRef.current && currentFocus === buttonRef.current) {
        handleShowListbox()
        moveFocusInsideList('last')
        break
      }
      moveFocusInsideList('prev')
      break
    case 'Escape':
      handleShowListbox()
      if (buttonRef.current) {
        buttonRef.current.focus()
      }
      break
    default:
    }
  }, [handleClickElement, handleShowListbox, moveFocusInsideList])

  /**
   *   Коррекция размеров  при открытии списка
   */

  const [autoHeightMax, setAutoHeightMax] = useState(0)
  useEffect(() => {
    if (!listboxHidden) {
      const height = calcListHeight(itemsRef.current, buttonRef.current)
      setAutoHeightMax(height)
    }
  }, [listboxHidden])

  /**
   *  Связь с label
   */

  const labelId = label ? `label_${id}` : labelIdProp

  const buttonContentClasses = cx(
    styles.buttonContent,
    {
      defaultSkinBorder: defaultSkin && listboxHidden && !errored,
      defaultSkinBorderActive: defaultSkin && !listboxHidden,
      defaultSkinButtonContent: defaultSkin,
      defaultSkinBorderError: defaultSkin && errored,
      disabled,
    },
    buttonClassName,
  )

  const ddIconClasses = cx(
    styles.ddIconContainer,
    {
      disabled,
      ddIconContainerAdditionalBorder: !defaultSkin,
    },
    arrowClassName,
  )

  const arrowSkin = defaultSkin ? (
    <div className={cx(styles.defaultSkinDDIcon, { flip: !listboxHidden })} />
  ) : (
    <IconDropDown className={cx(styles.ddIcon, { flip: !listboxHidden })} />
  )

  const listContainerClasses = cx(
    styles.listContainer,
    {
      listboxHidden,
      defaultSkinListContainer: defaultSkin,
    },
    itemsContainerClassName,
  )

  const listItemClasses = cx(
    styles.listItem,
    {
      defaultSkinBorder: defaultSkin && listboxHidden,
      defaultSkinBorderActive: defaultSkin && !listboxHidden,
      defaultSkinListItem: defaultSkin,
    },
    itemClassName,
  )

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          id={labelId}
          className={cx(labelClassName, { hidden: hideLabel })}
        >
          {label}
        </label>
      )}

      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={cx(styles.main, className)}
        onClick={handleClickElement}
        onKeyUp={handleDoKeyAction}
      >
        <button
          id={id}
          type="button"
          ref={buttonRef}
          aria-haspopup="listbox"
          onClick={hanldeToggleListbox}
          className={cx(styles.button, { disabled })}
          tabIndex={disabled ? -1 : 0}
        >
          <span className={buttonContentClasses}>
            {selectedItem[0] || placeholder}
            <span className={ddIconClasses}>{arrowSkin}</span>
          </span>
        </button>

        <div className={listContainerClasses} ref={ddRef}>
          <Scrollbars
            autoHeight
            autoHeightMin={0}
            autoHeightMax={autoHeightMax}
          >
            <ul
              ref={itemsRef}
              role="listbox"
              aria-labelledby={labelId}
              className={cx(styles.list)}
            >
              {items.map((Component) => {
                if (typeof Component === 'object' && 'props' in Component) {
                  const { key, props: { id: itemId } } = Component
                  return (
                    <li
                      key={key}
                      className={listItemClasses}
                      id={`${id}_item_${itemId}`}
                      role="option" // eslint-disable-line jsx-a11y/role-has-required-aria-props
                      tabIndex={-1}
                    >
                      {Component}
                    </li>
                  )
                }
                return null
              })}
            </ul>
          </Scrollbars>
        </div>

        {loading && <DotsLoader className={styles.loader} />}
      </div>

      {typeof selectedItem[0] === 'object' && 'props' in selectedItem[0]
        && <HTMLSelect name={id} value={selectedItem[0].props.id}>
          {selectedItem[0].props.id
            && <option // eslint-disable-line jsx-a11y/control-has-associated-label
              value={selectedItem[0].props.id}
            />}
        </HTMLSelect>}
    </>
  )
}

export default PlainDropdown
