// @flow
import React, { Suspense, useEffect, useState, Fragment } from 'react';
import { getToken, subscribeToken, unsubscribeToken, setToken as setTokenGlobal } from 'app/common/lib/token';
import isEmpty from 'ramda/es/isEmpty';
import pick from 'ramda/es/pick';

import App from 'app';
import ServiceWorkerLoader from 'app/common/components-next/service-worker';
import { getDomain, removeParam } from 'app/common/lib/params';
import Loading from 'app/common/components/Loader';
import OfflineIndicator from 'app/common/components-next/offline-indicator';
import ColorThemeProvider from 'app/common/components-next/color-theme';
import i18n from 'app/i18n';
import UpdateIllustration from 'app/common/icons/update-illustration.svg';
import AuthorizationCross from 'app/common/authorization-cross';

import Login from '../Login';
import useDomainData from './useDomainData';
import api from '../api';

import styles from './Bundle.scss';


const requestToken = async (cross: string) => {
  try {
    const { data } = await api('auth/cross', 'post', { data: { token: cross } });
    return data.token || null;
  } catch (error) {
    console.error(error);
  }
  return null;
};

const Bundle = () => {
  const domain = getDomain();
  const [token, setToken] = useState<string>(getToken());
  const [domainData, loading] = useDomainData(domain);

  const {
    secondary_logo_url: secondaryLogo,
    logo_url: logo,
    company,
    phone,
    parent_registration_types: availableRegTypes,
    country_iso_code: countryISOCode,
    android_app_url: androidAppUrl,
    ios_app_url: iosAppUrl,
    school_type: orgType,
    email_force_auth: emailForceAuth,
  } = domainData;

  const docs = pick([
    'terms_of_use_document_url',
    'privacy_consent_document_url',
    'privacy_policy_document_url',
  ], domainData);
  const mobileAppsLinks = { iosAppUrl, androidAppUrl };
  const termsAcceptRequired = isEmpty(availableRegTypes);


  useEffect(() => {
    subscribeToken(setToken);
    removeParam('token');

    return () => {
      unsubscribeToken(setToken);
    };
  }, []);


  return (
    <Fragment>
      <OfflineIndicator
        offlineMessage={i18n.t('offline_message', 'Отсутствует соединение с Интернет')}
      />
      <AuthorizationCross
        fallback={<Loading />}
        onToken={setTokenGlobal}
        requestToken={requestToken}
      >
        <ColorThemeProvider>
          <Suspense fallback={<Loading />}>
            {loading
              ? <Loading />
              : <ServiceWorkerLoader
                logo={secondaryLogo || ''}
                logoDescription={company || ''}
                company={company || ''}
                phone={phone || ''}
                androidAppUrl={androidAppUrl || ''}
                iosAppUrl={iosAppUrl || ''}
                updateIllustration={
                  <UpdateIllustration className={styles.updateIllustration} />
                }
              >
                {token
                  ? <App />
                  : <Login
                    logo={logo}
                    company={company}
                    phone={phone}
                    availableRegTypes={availableRegTypes}
                    countryISOCode={countryISOCode}
                    orgType={orgType}
                    emailForceAuth={emailForceAuth}
                    docs={docs}
                    mobileAppsLinks={mobileAppsLinks}
                    termsAcceptRequired={termsAcceptRequired}
                  />}
              </ServiceWorkerLoader>}
          </Suspense>
        </ColorThemeProvider>
      </AuthorizationCross>
    </Fragment>
  );
};

export default Bundle;
