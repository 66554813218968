// @flow
import { range, map } from 'ramda';


const A = 'А'.charCodeAt(0);

export const createDefaultSlotNaming = (columns: number, rows: number): Array<Array<string>> => {
  const yArray = range(1, rows + 1);
  const xArray = range(1, columns + 1);
  return map(y => (
    map(x => `${String.fromCharCode(A + y)}-${x}`, xArray)
  ), yArray);
};
