
// @flow
import moment from 'moment-timezone';

import { type Params, dateFormat } from 'app/dataTypes/OrgMenuAddRemove.types';

import type { Request } from '../../index';


const dateFormatApi: 'YYYY-MM-DD' = 'YYYY-MM-DD';

type ParamsApi = {
  school_id: number,
  sale_point_id: number, // точка продаж
  date: string,
  add_complexes: Array<number>, // id добавляемых блюд
  remove_complexes: Array<number>, // id удаляемых блюд
}

type Result = 'success'

export const convertParams = (params: Params): [ParamsApi, null] => {
  const { orgId, sellPointId, date, add, remove } = params;

  return [{
    school_id: orgId,
    sale_point_id: sellPointId,
    date: moment(date, dateFormat).format(dateFormatApi),
    add_complexes: add,
    remove_complexes: remove,
  }, null];
};

export const POST_COMPLEXES_ADD_REMOVE: Request<
  Params,
  Result,
  void
> = [
  'food/menu/complexes_add_remove',
  'post',
  ['data', 'result'],
];
