// @flow
import React, { useMemo } from 'react';

import { FilterDataProvider } from 'app/common/ui-components/filter';
import * as orgsTypes from 'app/common/constants/schools/types';

import OrgsOrdersMain from './OrgsOrdersMain';
import filterItems from './filter/filterItems';
import useFoodModeFlag from './useFoodModeFlag';


type Props = {
  orgId: number,
  orgType: $Values<typeof orgsTypes>,
  baseUrl: string,
  placeForGuests: boolean,
}

const OrgsOrders = ({ orgId, orgType, baseUrl, placeForGuests }: Props) => {
  const foodModeEnable = useFoodModeFlag(orgId);

  const filterParams = useMemo(() => ({
    orgId,
  }), [orgId]);


  return (
    <FilterDataProvider
      filters={filterItems}
      params={filterParams}
    >
      <OrgsOrdersMain
        orgId={orgId}
        orgType={orgType}
        placeForGuests={placeForGuests}
        foodModeEnable={foodModeEnable}
        baseUrl={baseUrl}
      />
    </FilterDataProvider>
  );
};

export default OrgsOrders;
