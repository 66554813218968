import { useSyncExternalStore } from 'react'

import DataBox from './data-box'


export function useDataBoxSelector<Snapshot>(dbx: DataBox, select: () => Snapshot): Snapshot {
  return useSyncExternalStore<Snapshot>(dbx.subscribe, select)
}

export function useDataBoxStatus<Snapshot>(dbx: DataBox, select: () => Snapshot): Snapshot {
  return useSyncExternalStore<Snapshot>(dbx.subscribeOnStatus, select)
}
