// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import type { HOC } from 'recompose';

import Loader from 'app/common/components/Loader';
import { menuFetch } from 'app/actions/orgs/menu';
import { menuNeedUpdate } from 'app/actions/orgs/menu/options';
import { categoriesFetch } from 'app/actions/orgs/dishes/categories';
import { withMenuMenuRights, withCurrentOrgRights } from 'app/containers/rights';
import { bothRights } from 'app/common/containers/withRights';

import selectProps from './selectProps';

type Props = {
  orgId: string,
  date: string,
  sellPointId: string,
  // categoryId: string,
  items: Array<Array<Object>>,
  kits: Array<Object>,
  // total: string,
}

const enhanceMenuTable: HOC<*, Props> = compose(
  connect(selectProps),
  // PROPS:
  // ----
  // items
  // kits
  // total
  // needUpdate
  // loading

  // права на редактирование
  bothRights(withMenuMenuRights, withCurrentOrgRights),
  // writeAccess
  // readAccess -- not used

  // withState('loading', 'setLoading', true),

  branch(
    props => !(
      props.orgId
      && props.date
      && props.sellPointId
      && props.sellPointId !== 'none'
      // && props.categoryId
      // && props.categoryId !== 'none'
    ),
    renderComponent(Loader),
  ),

  lifecycle({
    componentDidMount() {
      this.props.dispatch(menuFetch(
        this.props.orgId,
        this.props.date,
        this.props.sellPointId,
        // this.props.categoryId
      ));
      this.props.dispatch(categoriesFetch(this.props.orgId));
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.orgId !== prevProps.orgId
        || this.props.date !== prevProps.date
        || this.props.sellPointId !== prevProps.sellPointId
        // || this.props.categoryId !== prevProps.categoryId
        || (this.props.needUpdate !== prevProps.needUpdate && this.props.needUpdate)
      ) {
        this.props.dispatch(menuFetch(
          this.props.orgId,
          this.props.date,
          this.props.sellPointId,
          // this.props.categoryId
        ));
      }
      if (this.props.needUpdate !== prevProps.needUpdate && this.props.needUpdate) {
        this.props.dispatch(menuNeedUpdate(false));
      }
      if (this.props.orgId !== prevProps.orgId) {
        this.props.dispatch(categoriesFetch(this.props.orgId));
      }
    },
  }),
);

export default enhanceMenuTable;
