// @flow
import pipe from 'ramda/es/pipe';
import pick from 'ramda/es/pick';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import sortBy from 'ramda/es/sortBy';
import toLower from 'ramda/es/toLower';
import prop from 'ramda/es/prop';

import {
  type FoodItem,
  type FoodItemsRequestResult,
} from 'app/common/api/requests/food/items';


type Dishes = Array<FoodItem>
type Total = number

const convertDishesApiData: (res: FoodItemsRequestResult) => ([Dishes, Total]) = pipe(
  pick(['items', 'count']),
  ({ items = {}, count = 0 }) => ([
    pipe(
      values,
      map(({
        id,
        title,
        photoUrl,
        portion,
        cost,
        saleType,
        nomenclature,
      }: FoodItem) => ({
        id,
        title: title || '',
        photoUrl: photoUrl || '',
        portion: portion || '',
        cost: cost || '',
        saleType,
        nomenclature: nomenclature || '',
      }: FoodItem)),
      sortBy(pipe(prop('title'), toLower)),
    )(items),
    count,
  ]),
);

export default convertDishesApiData;
