import api from 'app/actions/api';


export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS';
export const DOCUMENTS_FAILURE = 'DOCUMENTS_FAILURE';

export const documentsRequest = () => ({
  type: DOCUMENTS_REQUEST,
});

export const documentsSuccess = payload => ({
  type: DOCUMENTS_SUCCESS,
  payload,
});

export const documentsFailure = payload => ({
  type: DOCUMENTS_FAILURE,
  payload,
});


/**
* Запрос данных по документам соглашений
*/
export const fetchDocuments = (accept, silent = false) => async (dispatch, getState) => {
  if (getState().documents.expired > Date.now()) return;

  const params = accept !== undefined ? { accept } : {};

  if (!silent) dispatch(documentsRequest());
  try {
    const { data } = await api('account/agreement/documents', 'get', { params });
    dispatch(documentsSuccess({ ...data, expired: Date.now() + 300000 }));
  } catch (error) {
    if (silent) return;
    dispatch(documentsFailure({ err: 'Не удалось получить данные по документам соглашений', error }));
  }
};
