// @flow
import { createContext } from 'react';

import {
  type State,
  type Actions,
  defaultState,
  defaultActions,
} from './useSelection';


type SelectionDataContextType = [
  State,
  Actions,
]

const defaultContext: SelectionDataContextType = [
  defaultState,
  defaultActions,
];

export default createContext<SelectionDataContextType>(defaultContext);
