// @flow
import React, { type Node } from 'react';

import Stub from 'app/common/layouts/Stub';

import ReportSectionCSS from './ReportSectionCSS';


type Props = {
  headerBar?: Node,
  stickyNumber?: number,
}

const EmptyReportCSS = ({ headerBar, stickyNumber }: Props) => (
  <ReportSectionCSS
    headerBar={headerBar}
    stickyNumber={stickyNumber}
    paddingFree
  >
    <Stub />
  </ReportSectionCSS>
);

export default EmptyReportCSS;
