// @flow
import type { Request } from '../../../index'


type ImageParamsType = {
  file: string,
  o?: number,
  w: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
}

// get complexes

export type GetComplexesParams = {
  date?: string, // при установке вернется список заполненных комплектов с составом на эту дату
  deleted?: boolean,
  visible?: boolean,
  include_subsidy_orders?: boolean, // при установке вернется список заполненных комплектов со списком справок на льготное питание
}

export type DishItem = {
  id: number,
  title: string,
  photoUrl: string,
  portion: string,
  cost: number,
  saleType: string,
  index: number,
}

export type ComplexItem = {
  id: number,
  title: string,
  fixed_price_enabled: boolean,
  visible: boolean,
  deletable: boolean,
  cost?: string,
  fixed_price?: string,
  grocery_set_price: string | null,

  buffet_zone: boolean, // зона буфета
  hot_meals_zone: boolean, // зона горячего питания
  deny_parent_money_sales: boolean, // Не продавать за родительские средства
  deny_more_than_one_sales: boolean,
  use_as_additional: boolean, // Использовать как дополнительный комплект при создании справок с выбором “один из множества”

  subsidy_orders?: {
    [id: string]: { id: number },
  },
  items?: {
    [id: string]: DishItem
  },
  photo_url: string | null,
}

export type GetComplexesResult = {
  [id: string]: ComplexItem,
}

type ComplexUrlParams = {
  orgId: number | string,
}

export const GET_COMPLEXES: Request<GetComplexesParams, GetComplexesResult, ComplexUrlParams> = [
  'food/schools/{orgId}/complexes',
  'get',
  ['data', 'complexes'],
]

// new complex

export type NewKitParams = {
  title: string,
  fixed_price_enabled?: boolean, // опционально, устанавливает наличие фиксированной цены
  fixed_price?: string, // опционально, устанавливает фиксированную цену
}

export type NewComplexResult = {
  id: number,
  title: string,
  fixed_price_enabled: boolean,
  fixed_price?: string,
  deletable: boolean,
  photo_url: '',
  visible: boolean,
}

export const POST_NEW_COMPLEX: Request<NewKitParams, NewComplexResult, ComplexUrlParams> = [
  'food/schools/{orgId}/complexes',
  'post',
  ['data', 'complexes'],
]

// edit complex

export type KitParams = {
  photo?: ImageParamsType | null, // опционально, устанавливает фото
  fixed_price_enabled?: boolean, // опционально, устанавливает наличие фиксированной цены
  fixed_price?: number, // опционально, устанавливает фиксированную цену
  title?: string, // опционально, устанавливает новое наименование
  buffet_zone?: boolean, // зона буфета
  hot_meals_zone?: boolean, // зона горячего питания
  deny_parent_money_sales?: boolean, // Не продавать за родительские средства
  deny_more_than_one_sales?: boolean, // Не продавать более 1 шт
  use_as_additional?: boolean
}

export type ComplexResult = {
  id: number,
  title: string,
  fixed_price_enabled: boolean,
  fixed_price?: string,
  deletable: boolean,
  photo_url: string,
  visible: boolean,
  buffet_zone: boolean,
  hot_meals_zone: boolean,
  deny_parent_money_sales: boolean,
  deny_more_than_one_sales: boolean,
  use_as_additional: boolean
}

export type ComplexEditUrlParams = {
  orgId: string,
  complexId: string,
}

export const PUT_EDIT_COMPLEX: Request<KitParams, ComplexResult, ComplexEditUrlParams> = [
  'food/schools/{orgId}/complexes/{complexId}',
  'put',
  ['data', 'complexes'],
]
