import { ElementRef, useCallback, useMemo, useRef } from 'react'
import { values, pipe, identity } from 'ramda'

import PlainButtonAsLink from '../plain-button-as-link'
import PlainSelector from './plain-selector'
import PlainItemsModal from './plain-items-modal'
import PlainSelectorStatic from './plain-selector-static'
import HTMLSelect from './html-select'
import { ItemType, ItemsDataType } from './types'


const findItem = (targetID: string, items: ItemsDataType): ItemType | null => {
  if (items instanceof Array) {
    return items.find(({ id }) => id === targetID) || null
  }
  return items[targetID] || null
}

const defaultItems: ItemsDataType = {}

export type Props = {
  id?: string //                                             ID компонента (для html-атрибута name)
  items?: ItemsDataType //                                   Список элементов (данные для выбора)
  selection?: Array<string> //                               Текущее значение
  defaultSelection?: Array<string> //                        Значение по умолчанию
  onChange?: (values: Array<string>) => void
  sortFunc?: (values: Array<ItemType>) => Array<ItemType> // Функция сортировки в окне выбора

  className?: string //                                      Класс для контейнера
  classNameLabel?: string //                                 Класс для элемента label ("Выбор:")
  classNameBadges?: string //                                Класс для блока выбранных значений
  loading?: boolean //                                       Индикация процесса загрузки
  disabled?: boolean //                                      Заблокированное состояние
  multiple?: boolean //                                      Режим множественного выбора
  colorInverse?: boolean //                                  Инверсия цвета badges (для отображения на темных поверхностях)
  label?: string //                                          Текст заголовка ("Выбор:")
  header?: string //                                         Текст заголовка в окне выбора ("Выберите")
  selectedAllOnEmptySelection?: boolean //                   Отображать состояние "Все" при отсутствии выбора (включено по умолчанию)
  pleaseSelectText?: string //                               Сообщение об отсутствии выбора на badge ("Выберите")
  buttonResetLabel?: string //                               Текст ссылки сброса выбора ("Сбросить выбор")
  buttonSelectLabel?: string //                              Текст ссылки открывающей окно выбора ("Выбрать")
  skipUpperSpace?: boolean //                                Убрать верхний отступ
  hideButtonReset?: boolean //                               Скрыть ссылку "Сбросить выбор"
  hideBadgesIfNothingSelected?: boolean //                   Скрыть badges при отсутствии выбора
  hideLegendIfSelection?: boolean //                         Скрыть легенду и ссылки при наличии выбора (selection не пустой [])
  badgeMaxWidth?: number //                                  Максимальная ширина значка (badge)
  doOnBadgeCrossClick?: 'clear' | 'open' //                  Действие при клике на крестик
  doOnBadgeTextClick?: 'open' //                             Действие при клике на текст значка
  emptyTitle?: string //                                     Сообщение о несовпадении выбранного элемента с items или об отсутствии title
  disableAutoSelect?: boolean //                             Отключить автоматическое выбор элемента (если в данных находится всего лишь
  //                                                         один элемент, этот элемент автоматически выбирается и срабатывает onChange)
}

/**
 *  + Если выбран один элемент и установлено selectedAllOnEmptySelection (по умолчанию), то нажатие на крестик открывает окно выбора
 * если не установлен doOnBadgeCrossClick
 */

const PlainItemsSelector = ({
  id,
  items = defaultItems,
  selection,
  defaultSelection,
  onChange,
  sortFunc = identity,

  className,
  classNameLabel,
  classNameBadges,
  loading,
  disabled,
  multiple,
  colorInverse,
  label = 'Выбор',
  header = 'Выберите',
  selectedAllOnEmptySelection = true,
  pleaseSelectText,
  buttonResetLabel,
  buttonSelectLabel = 'Выбрать',
  skipUpperSpace = false,
  hideButtonReset = false,
  hideBadgesIfNothingSelected = false,
  hideLegendIfSelection = false,
  badgeMaxWidth,
  doOnBadgeCrossClick,
  doOnBadgeTextClick,
  emptyTitle = 'Неизвестный элемент',
  disableAutoSelect = false,
}: Props) => {
  const modalRef = useRef<ElementRef<typeof PlainItemsModal>>(null)

  const handleBadgeCrossClick = useCallback((ID: string, removeItemFn: (ID: string) => void) => {
    if (doOnBadgeCrossClick === 'open') {
      modalRef.current?.changeModalDisplayed(true)
      return
    }
    if (doOnBadgeCrossClick === 'clear') {
      removeItemFn(ID)
      return
    }
    if (!multiple) {
      modalRef.current?.changeModalDisplayed(true)
      return
    }
    removeItemFn(ID)
  }, [multiple, doOnBadgeCrossClick])

  const handleBadgeTextClick = useCallback((/* ID: string */) => {
    if (doOnBadgeTextClick === 'open') {
      modalRef.current?.changeModalDisplayed(true)
    }
  }, [doOnBadgeTextClick])

  const handleUpdateSelection = useCallback((ids: Array<string>) => {
    if (onChange) {
      onChange(ids)
    }
  }, [onChange])

  const itemsList: Array<ItemType> = useMemo(() => (
    items instanceof Array
      ? sortFunc(items)
      : pipe(values, sortFunc)(items)
  ), [items, sortFunc])

  const getTitle = useCallback((targetID: string): string => (
    findItem(targetID, items)?.title || emptyTitle
  ), [items, emptyTitle])

  // Только один элемент доступен для выбора
  // и есть флаг selectedAllOnEmptySelection (он по умолчанию)
  if (!disableAutoSelect && itemsList.length === 1 && !loading && selectedAllOnEmptySelection) {
    return (
      <>
        <PlainSelectorStatic
          className={className}
          classNameLabel={classNameLabel}
          classNameBadges={classNameBadges}
          onChange={onChange}
          label={label}
          id={String(itemsList[0].id)}
          title={itemsList[0].title}
          colorInverse={colorInverse}
          disabled={disabled}
          skipUpperSpace={skipUpperSpace}
        />
        <HTMLSelect
          name={id}
          selection={selection}
          multiple={multiple}
          items={itemsList}
        />
      </>
    )
  }

  return (
    <PlainSelector
      id={id}
      className={className}
      classNameLabel={classNameLabel}
      classNameBadges={classNameBadges}
      skipUpperSpace={skipUpperSpace}
      loading={loading}
      disabled={disabled}
      label={label}
      buttonResetLabel={buttonResetLabel}
      hideButtonReset={hideButtonReset}
      colorInverse={colorInverse}
      selection={selection}
      defaultSelection={defaultSelection}
      onChange={handleUpdateSelection}
      makeSortable={getTitle} // по умолчанию сортировка badges будет по заголовку
      makeTitle={getTitle}
      selectedAllOnEmptySelection={selectedAllOnEmptySelection}
      pleaseSelectText={pleaseSelectText}
      hideBadgesIfNothingSelected={hideBadgesIfNothingSelected}
      hideLegendIfSelection={hideLegendIfSelection}
      badgeMaxWidth={badgeMaxWidth}
      onBadgeCrossClick={handleBadgeCrossClick}
      onBadgeTextClick={doOnBadgeTextClick ? handleBadgeTextClick : undefined}
      multiple={multiple}
    >
      {(itemsSelected, update) => (
        <PlainItemsModal
          formID={id}
          onSubmit={update}
          multiple={!!multiple}
          header={header}
          items={itemsList}
          itemsSelected={itemsSelected}
          selectedAllOnEmptySelection={selectedAllOnEmptySelection}
          ref={modalRef}
        >
          {(handleModalDisplayed) => {
            return (
              <PlainButtonAsLink
                onClick={handleModalDisplayed}
                disabled={disabled}
              >
                {buttonSelectLabel}
              </PlainButtonAsLink>
            )
          }}
        </PlainItemsModal>
      )}
    </PlainSelector>
  )
}

export default PlainItemsSelector
