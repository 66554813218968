import { ReactNode, useEffect, useState } from 'react'
import getParams, { removeParam } from 'app/common/lib/params'

import useCallbackRef from '../hooks/useCallbackRef'


type Props = {
  onToken?: (token: string) => void
  requestToken?: (cross: string) => Promise<string | null>
  children: ReactNode | ((pending: boolean) => ReactNode)
  fallback?: ReactNode
}

const AuthorizationCross = ({ onToken, requestToken, children, fallback }: Props) => {
  const [pending, setPending] = useState(false)

  const requestTokenRef = useCallbackRef(requestToken)
  const onTokenRef = useCallbackRef(onToken)

  useEffect(() => {
    const params = getParams()
    if (params.cross) {
      const request = async () => {
        setPending(true)
        const token = await requestTokenRef.current?.(params.cross)
        if (token) {
          onTokenRef.current?.(token)
        }
        setPending(false)
        removeParam('cross')
      }
      request()
    }
  }, [requestTokenRef, onTokenRef])

  if (fallback && pending) {
    return (
      <>
        {fallback}
      </>
    )
  }

  return (
    <>
      {typeof children === 'function'
        ? children(pending)
        : children}
    </>
  )
}

export default AuthorizationCross
