// @flow
import React, { type Ref } from 'react';

import { Tr, Th } from 'app/common/ui-components/table';

import type { SchoolDisplayableItem } from './useActualSchoolsData';
import { Entry, CellRestriction, restrictions } from '../MultiLineCell';

import styles from './LeftColumnBody.scss';


type Props = {
  reference: Ref<'tbody'>,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  data: Array<SchoolDisplayableItem>,
  sameRowClassName: string,
}

const LeftColumnBody = ({
  reference,
  onMouseEnter,
  onMouseLeave,
  data,
  sameRowClassName,
}: Props) => {
  return (
    <tbody ref={reference}>
      {data.map(({ id, title }, i) => (
        <Tr
          key={id}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={`${sameRowClassName}${i}`}
        >
          <Th
            stripPadding
            scope="row"
            alignLeft
            headers="column_school_title"
            id={`row_school_${i}`}
            className={styles.cell}
          >
            <CellRestriction type={restrictions.COMMON_PADDING}>
              <Entry alignLeft skipPadding>
                {title}
              </Entry>
            </CellRestriction>
          </Th>
        </Tr>
      ))}
    </tbody>
  );
};

export default LeftColumnBody;
