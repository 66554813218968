// @flow
import React from 'react';

import PlainModal, { PlainModalHeader, PlainModalContent, PlainModalFooter } from 'app/common/ui-next/plain-modal';
import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';

import useHireForm from './useHireForm';
import useStep2FormHeader from './useStep2FormHeader';
import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import useHireFormStep2, { orgPrefix } from './useHireFormStep2';
import * as employeeTypes from '../../employeeTypes';

import styles from './HireFormModal.scss';


type Props = {
  onClose: (e?: SyntheticEvent<HTMLElement>) => void,
  onSuccess?: () => void,
  employeeType: $Values<typeof employeeTypes>,
}

const HireFormModal = ({ onClose, onSuccess, employeeType }: Props) => {
  const [step, options, {
    submitStep1,
    changeField,
    changeDateField,
  }, submittingStep1] = useHireForm();

  const [
    { selectedOrg, selectedRole },
    { changeOrg, selectRole, submitStep2 },
    { errorOrg, errorRole },
    submittingStep2,
  ] = useHireFormStep2({ onClose, onSuccess, employeeType });

  const step2Header = useStep2FormHeader();

  if (step === 1) {
    return (
      <PlainModal
        show
        onSubmit={submitStep1}
        className={styles.root}
      >
        <PlainModalHeader onCrossClick={onClose}>
          {'Прием сотрудника'}
        </PlainModalHeader>

        <PlainModalContent paddingFree>
          <Step1Form
            options={options}
            loading={submittingStep1}
            onChangeField={changeField}
            onChangeDateField={changeDateField}
          />
        </PlainModalContent>

        <PlainModalFooter>
          <ControlsBar right>
            <PlainButton
              onClick={onClose}
              outline
            >
              {'Отмена'}
            </PlainButton>
            <PlainButton
              type="submit"
              disabled={submittingStep1}
            >
              {'Далее'}
            </PlainButton>
          </ControlsBar>
        </PlainModalFooter>
      </PlainModal>
    );
  }

  if (step === 2) {
    return (
      <PlainModal
        show
        onSubmit={submitStep2}
        className={styles.root}
      >
        <PlainModalHeader onCrossClick={onClose}>
          {step2Header}
        </PlainModalHeader>
        <PlainModalContent maxHeight={600}>

          <Step2Form
            selectedOrg={selectedOrg}
            selectedRole={selectedRole}
            onChangeOrg={changeOrg}
            onSelectRole={selectRole}
            errorOrg={errorOrg}
            errorRole={errorRole}
            orgPrefix={orgPrefix}
            employeeType={employeeType}
          />

        </PlainModalContent>
        <PlainModalFooter>
          <ControlsBar right>
            <PlainButton
              onClick={onClose}
              outline
            >
              {'Отмена'}
            </PlainButton>
            <PlainButton
              type="submit"
              disabled={submittingStep2}
            >
              {submittingStep2 ? 'Принимается...' : 'Принять'}
            </PlainButton>
          </ControlsBar>
        </PlainModalFooter>
      </PlainModal>
    );
  }

  return null;
};

export default HireFormModal;
