// @flow
import React, { type Node, type Ref } from 'react';
import classNames from 'classnames/bind';

import styles from './table.scss';


/**
 * UI COMPONENTS v2
 *
 * Таблица
 * ------
 * Семантически верная таблица. Родительский элемент (table)
 */

const cx = classNames.bind(styles);

type Props = {
  children: Node,
  className?: string,
  stripStyles?: boolean,
  reference?: Ref<'table'>,
  wide?: boolean,
  style?: { [key: string]: string }
}

const Table = ({ children, className, stripStyles, reference, wide, style }: Props) => (
  <table className={cx({ root: !stripStyles, wide }, className)} ref={reference} style={style}>
    {children}
  </table>
);

export default Table;
