// @flow
import React, { type Ref } from 'react';

import { Tr, Th, getPropsId } from 'app/common/ui-components/table';

import { type ColumnProps } from './DetailsBudget/columns';


type Props = {
  dummy?: boolean,
  reference?: Ref<'tr'>,
  hidden?: boolean,
  columns: Array<ColumnProps>,
}

const HeaderRow = ({ dummy, hidden, reference, columns }: Props) => {
  const idProps = getPropsId(dummy);

  return (
    <Tr
      reference={reference}
      hidden={hidden}
      header
    >
      {columns.map(({ id, title, props }) => (
        <Th
          scope="col"
          key={id}
          {...idProps(id)}
          {...props}
        >
          {title}
        </Th>
      ))}
    </Tr>
  );
};

export default HeaderRow;
