import { ReportParams } from 'app/dataTypes/reports'

import type { Request } from '../../index'


type ApiParams = {
  report_id: number,
}

export const convertParams = (params: ReportParams): [ApiParams, null] => {
  const { reportID } = params
  return [{ report_id: parseInt(reportID, 10) }, null]
}

export const convertResult = (): true => {
  return true
}

export const POST_REPORT_CANCEL: Request<
  ReportParams,
  true,
  void
> = ['reports/cancel', 'post', ['data', 'result']]
