// @flow
import { useMemo } from 'react';
import slice from 'ramda/es/slice';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import concat from 'ramda/es/concat';
import addIndex from 'ramda/es/addIndex';
import sortWith from 'ramda/es/sortWith';
import ascend from 'ramda/es/ascend';
import { v4 as uuid } from 'uuid';

import toFixedValue from 'app/common/lib/toFixedValue';
import type { DishItem } from 'app/common/ui-components/complex-tooltip';

import type { ItemSorted, ComplexSorted } from './useSortedItems';


const mapIndexed = addIndex(map);

export type ActualItem = {
  id: string,
  title: string,
  portion: string,
  dishItems: ?Array<DishItem>,
  cost: string,
  n: number,
  sum: string,
}

export default function useActualItems(
  kits: Array<ComplexSorted>,
  items: Array<ItemSorted>,
  from: number,
  to: number,
): Array<ActualItem> {
  const itemsResult: Array<ActualItem> = useMemo(() => pipe(
    slice(from, to),
    map(({
      title,
      portion,
      cost,
      n,
      sum,
      count,
      ...other
    }: ItemSorted | ComplexSorted): ActualItem => {
      const dishItems = prop('items', other);
      const nString = n || n === 0 ? String(n) : '';
      const countString = count || count === 0 ? String(count) : '';

      return ({
        id: uuid(),
        title: title || '\u00A0',
        portion: portion || '\u00A0',
        dishItems: (dishItems
          ? pipe(
            sortWith([
              ascend(prop('index')),
              ascend(prop('title')),
            ]),
            mapIndexed(({ cost: _, ...props }, ix): DishItem => ({ ...props, id: ix })),
          )(dishItems)
          : null),
        cost: toFixedValue(cost),
        n: nString || countString,
        sum: toFixedValue(sum),
      });
    }),
  )(concat(kits, items)), [from, to, kits, items]);

  return itemsResult;
}
