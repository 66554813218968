// @flow
import type { Category, ParamsPOST } from 'app/dataTypes/vending/production/Categories.types';

import type { Request } from '../../../../index';


type ParamsApi = {
  place_id: number | string,
  title: string,
  index: number,
}

type ResultApi = {
  id: number,
  place_id: number,
  title: string,
  index: number | null,
  deleted: boolean,
  deletable: boolean,
}

export const convertParams = ({ placeId, title, index }: ParamsPOST): [ParamsApi, null] => {
  return [{
    place_id: placeId,
    title,
    index,
  }, null];
};

export const convertResult = (data: ResultApi): Category => {
  const {
    id,
    place_id: placeId,
    title,
    index,
    deleted,
    deletable,
  } = data;

  return {
    id: String(id),
    placeId: String(placeId),
    title,
    index,
    deleted,
    deletable,
    quantity: 0,
  };
};

export const POST_VENDING_ITEMS_CATEGORIES: Request<ParamsPOST, Category, void> = [
  'data/vending/items/categories',
  'post',
  ['data', 'data'],
];
