// @flow
import { getStateCurrentValue, type State } from 'app/common/ui-components/filter';

import filterDataFilter from '../filterData';
import { getActorsDataLengthFromCurrent } from '../filterData/filterDataItems';


const actorActivity = (state: State) => {
  const current = getStateCurrentValue(filterDataFilter, state);
  const dataLen = getActorsDataLengthFromCurrent(current);

  return dataLen > 1;
};

export default actorActivity;
