import { useCallback, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { assocPath, findIndex, isEmpty, sortBy, remove, path } from 'ramda'

import { SupportContactsType, SupportContactsItemType } from 'app/dataTypes/requisites'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { StatusMessage } from 'app/common/templates-next/page-template'
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal'
import { UpdateHandlerType } from './useData'
import SupportContactForm, { SupportContactFormHeader } from './form/SupportContactForm'

import useContactRemoveConfirmation from './useContactRemoveConfirmation'
import styles from './SupportContacts.scss'


const createID = () => `new${uuid()}`

const createEmptyFormData = (position: number): SupportContactsItemType => ({
  ID: createID(),
  position: String(position),
  kind: '',
  samePositionValues: [1, 2].map(() => ({
    ID: createID(),
    value: '',
  })),
})

type Props = {
  data: SupportContactsType
  errors: Record<string, string>
  loading?: boolean
  locked?: boolean
  onUpdate: UpdateHandlerType
}

const SupportContacts = ({
  data,
  errors,
  loading,
  locked,
  onUpdate,
}: Props) => {
  const onUpdateRef = useCallbackRef(onUpdate)

  const items = useMemo(() => {
    return sortBy(({ position }) => parseInt(position, 10), data)
  }, [data])

  const lastItemPositionNaN = parseInt(items.length ? items[items.length - 1].position : '', 10)
  const lastItemPosition = Number.isNaN(lastItemPositionNaN) ? items.length : lastItemPositionNaN

  const handleAddItem = useCallback(() => {
    const emptyData = createEmptyFormData(lastItemPosition + 1)
    onUpdateRef.current?.((prevState) => {
      const { supportContacts } = prevState
      return assocPath(['supportContacts', supportContacts.length], emptyData, prevState)
    })
  }, [lastItemPosition, onUpdateRef])

  const handleChangeField = useCallback((targetID: string, fieldName: 'position' | 'kind', val: string) => {
    onUpdateRef.current?.((prevState) => {
      const { supportContacts } = prevState
      const index = findIndex(({ ID }) => (ID === targetID), supportContacts)
      if (index !== -1) {
        return assocPath(['supportContacts', index, fieldName], val, prevState)
      }
      console.warn('👻 Не удалось изменить данные Requisites в в компоненте SupportContacts')
      return prevState
    })
  }, [onUpdateRef])

  const handleRemoveContact = useCallback((targetID: string) => {
    onUpdateRef.current?.((prevState) => {
      const { supportContacts } = prevState
      const index = findIndex(({ ID }) => (ID === targetID), supportContacts)
      if (index !== -1) {
        return { ...prevState, supportContacts: remove(index, 1, supportContacts) }
      }
      console.warn('👻 Не удалось удалить запись контактов из Requisites в компоненте в SupportContacts')
      return prevState
    })
  }, [onUpdateRef])

  const handleChangeValue = useCallback((targetID: string, valueIndex: number, value: string) => {
    onUpdateRef.current?.((prevState) => {
      const { supportContacts } = prevState
      const index = findIndex(({ ID }) => (ID === targetID), supportContacts)
      if (index !== -1) {
        const valueID = path([index, 'samePositionValues', valueIndex, 'ID'], supportContacts) || createID()
        return assocPath(['supportContacts', index, 'samePositionValues', valueIndex], {
          ID: valueID,
          value,
        }, prevState)
      }
      console.warn('👻 Не удалось изменить данные samePositionValues в Requisites в компоненте SupportContacts')
      return prevState
    })
  }, [onUpdateRef])

  const handleCheckError = useCallback(() => {
    onUpdateRef.current?.(prev => prev, true)
  }, [onUpdateRef])

  const {
    showRemoveConfirm,
    removedItemKind,
    remove: removeBegin,
    removeCancel,
    removeConfirm,
  } = useContactRemoveConfirmation(data, handleRemoveContact)

  const empty = isEmpty(items)
  const pending = locked || loading

  return (
    <>
      {empty && !pending && <StatusMessage className={styles.emptyMessage}>
        {'Пока контакты отсутствуют'}
      </StatusMessage>}

      {!empty && <SupportContactFormHeader className={styles.header} />}

      {items.map(({ ID, position, kind, samePositionValues }) => (
        <SupportContactForm
          key={ID}
          ID={ID}
          className={styles.item}
          errors={errors}
          position={String(position)}
          kind={kind}
          disabled={pending}
          values={samePositionValues}
          onCheckError={handleCheckError}
          onChange={handleChangeField}
          onRemove={removeBegin}
          onChangeValue={handleChangeValue}
        />
      ))}

      <PlainButtonAsLink
        disabled={pending}
        className={styles.addLink}
        onClick={handleAddItem}
      >
        {'+ Добавить строку с контактом'}
      </PlainButtonAsLink>

      {showRemoveConfirm
        && <PlainConfirmModal
          messageText={
            removedItemKind
              ? `Подтвердите удаление записи «${removedItemKind}»`
              : 'Подтвердите удаление записи'
          }
          onCancel={removeCancel}
          onConfirm={removeConfirm}
        />}
    </>
  )
}

export default SupportContacts
