import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import equals from 'ramda/es/equals';
import isEmpty from 'ramda/es/isEmpty';
import prop from 'ramda/es/prop';

import {
  studentsFilterFetch,
  studentsFilterClear,
  studentsFilterDelete,
  studentsFilterSet,
  studentsFilterUpdate,
} from 'app/actions/reports/filters/students';
import { isSingleWithId, isSingle } from 'app/common/lib/object';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import * as orgsTypes from 'app/common/constants/schools/types';

import {
  selectOrgId,
  selectClassId,
  selectStatusesIds,
  selectIsMinorStatuses,
  selectOrgsType,
} from './commonSelectors';


const selectIsAll = createSelector(
  [
    state => state.reports.filters.students.data,
    state => state.reports.filters.students.selected,
  ],
  // (data, selected) => (!isEmpty(data) && equals(data, selected))
  (data, selected) => (isEmpty(selected) || equals(data, selected)),
);

const selectIsEnableCommon = createSelector(
  [
    selectOrgId,
    state => state.reports.filters.customers.selected,
    selectClassId,
    state => state.reports.filters.date.current,
    selectIsMinorStatuses,
    selectOrgsType,
  ],
  (orgId, customers, classId, dateCurrent, minorStatusesSelected, orgsType) => !!(
    orgId // выбрана одна школа
    && isSingleWithId('Учащиеся')(customers) // пользователи -- только "Ученики"
    && dateCurrent // выбрано "текущее состояние" вместо даты
    && (classId || minorStatusesSelected) // если выбран класс или статус учеников не "текущие"
    && orgsType !== orgsTypes.ENTERPRISE // на предприятии нет учеников
  ),
);

export default compose(
  connect(
    (state, props) => {
      const selectIsEnable = prop('selectIsEnable', props) || selectIsEnableCommon;

      return ({
        items: state.reports.filters.students.data,
        selectedItems: state.reports.filters.students.selected,
        loading: state.reports.filters.students.loading,
        orgId: selectOrgId(state, props),
        classId: selectClassId(state, props),
        isSelectedAllState: selectIsAll(state, props),
        isEnabled: selectIsEnable(state, props),
        noData: isEmpty(state.reports.filters.students.data),
        active: state.reports.filters.students.active,
        statusIds: selectStatusesIds(state),
      });
    },
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(studentsFilterSet({}));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset();
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems, reset }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        reset();
        return;
      }
      dispatch(studentsFilterDelete([name]));
    },
    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(studentsFilterSet(submitted));
    },
  }),

  lifecycle({
    compoentDidMount() {
      const { isEnabled, dispatch } = this.props;
      if (isEnabled) {
        dispatch(studentsFilterUpdate({ active: isEnabled }));
      }
    },
    async componentDidUpdate(prevProps) {
      const { dispatch, orgId, classId, reset, isEnabled, statusIds } = this.props;
      if (
        orgId !== prevProps.orgId
        || classId !== prevProps.classId
        || statusIds !== prevProps.statusIds
      ) {
        if (orgId && (classId || !isEmpty(statusIds))) {
          await dispatch(studentsFilterFetch(orgId, classId, statusIds));
          reset();
        }
      }
      if (isEnabled !== prevProps.isEnabled) {
        dispatch(studentsFilterUpdate({ active: isEnabled }));
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(studentsFilterClear());
    },
  }),

  branch(({ isEnabled }) => !isEnabled, renderNothing),
);
