// @flow
import React, { useState, useRef, useCallback, useEffect, type Node } from 'react';
import classNames from 'classnames/bind';
import test from 'ramda/es/test';
import replace from 'ramda/es/replace';

import IconPen from 'app/common/icons/IconPen';
import { priceUnsignedRe } from 'app/common/constants/regex';
import setInputSelection from 'app/common/lib/setInputSelection';

import PlainButtonAsLink from '../plain-button-as-link';
import SaveButton from './save-button';
import styles from './plain-editable-prop.scss';

/**
 *  Компонент для редактирования параметра, например, в ячейке таблицы
 */

const cx = classNames.bind(styles);
const submitButtonId = 'submit_button';
const editingWinId = 'editing_win';

export type EditablePropProps = {
  className?: string,
  alignRight?: boolean,
  alignCenter?: boolean,
  value: string,
  saving?: boolean,
  onSubmit?: (value: string) => void,
  floatType?: boolean, // вводить можно только дробное число
  children: Node, // здесь отображение value родителем
}

const PlainEditableProp = ({
  className,
  alignRight,
  alignCenter,
  value,
  saving,
  onSubmit,
  floatType,
  children,
}: EditablePropProps) => {
  const winRef = useRef();
  const valueRef = useRef();
  const inputRef = useRef();

  const [localValue, setLocalValue] = useState(value);
  const [editing, setEditing] = useState(false);

  // callbacks

  // коррекция положения окна относительно контэйнера с value
  const correctWinPosition = useCallback(() => {
    const input = inputRef.current;
    const win = winRef.current;
    const span = valueRef.current;
    if (!input || !win || !span) return;

    const inputRect = input.getBoundingClientRect();
    const spanRect = span.getBoundingClientRect();

    const {
      // left: inputLeft,
      top: inputTop,
      bottom: inputBottom,
      // right: inputRight,
    } = inputRect;

    const {
      // left: spanLeft,
      top: spanTop,
      bottom: spanBottom,
      right: spanRight,
    } = spanRect;

    const dx = 10;
    const dy = 10;
    const height = inputBottom - inputTop + 2 * dy; // eslint-disable-line no-mixed-operators
    const width = 100 + dx;
    const left = spanRight - width;
    const top = (spanTop + spanBottom) / 2 - height / 2;

    const widthSaveIcon = 20;
    const widthSave = 8 + widthSaveIcon + dx; // 8 - margin-right у кнопки сохранения // eslint-disable-line no-mixed-operators

    win.style.left = `${left}px`;
    win.style.width = `${width + widthSave}px`;
    win.style.top = `${top}px`;
    win.style.height = `${height}px`;
    win.style.opacity = '1';
  }, []);

  // закрытие окна редактирования, сброс всех значаний
  const handleCancelEditing = useCallback(() => {
    setEditing(false);
    setLocalValue(value);
  }, [value]);

  // клик вне окна редактирования (закрытие с отменой)
  const handleClickOutside = useCallback((e: SyntheticMouseEvent<HTMLElement>) => {
    // $FlowFixMe
    if (e.target.closest && !e.target.closest(`#${editingWinId}`)) {
      handleCancelEditing();
    }
  }, [handleCancelEditing]);

  // показ окна редактирования
  const handleEdit = useCallback((/* e: SyntheticEvent<HTMLButtonElement> */) => {
    setEditing(true);
  }, []);

  // изменение локального состояния value
  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: value_ } = e.currentTarget;
    if (floatType) {
      const replaced = replace(',', '.', value_);
      if (test(priceUnsignedRe, replaced)) {
        setLocalValue(replaced);
      }
      return;
    }
    setLocalValue(value_);
  }, [floatType]);

  const handleFocus = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const elem = e.currentTarget;
    const { value: val } = elem;
    setInputSelection(0, val.length, elem);
  }, []);

  // выбор нового значения пользователем
  const handleFormSubmit = useCallback((e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit) {
      onSubmit(localValue);
    }
  }, [onSubmit, localValue]);

  // эффекты

  useEffect(() => {
    // при открытии формы редактирования
    if (editing) {
      // отслеживание клика за границей окна редактирования
      window.addEventListener('click', handleClickOutside);
      // фокус на поле ввода при открытии окна
      if (inputRef.current) {
        inputRef.current.focus();
      }
      correctWinPosition();
    }
    // при закрытии, click outside больше не нужен
    if (!editing) {
      window.removeEventListener('click', handleClickOutside);
    }
  }, [editing]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // при изменении внешнего value все закрываем и обновляем внутренний
    handleCancelEditing();
  }, [handleCancelEditing]);

  useEffect(() => {
    // отмена редактирования при скролинге
    window.addEventListener('scroll', handleCancelEditing);
    return () => {
      window.removeEventListener('scroll', handleCancelEditing);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={className}>
      <div
        className={cx(styles.main, { alignRight, alignCenter })}
      >
        {editing
          && <div className={styles.editingFormContainer} ref={winRef} id={editingWinId}>
            <form onSubmit={handleFormSubmit} className={styles.editingForm}>
              <input
                value={localValue}
                className={styles.input}
                ref={inputRef}
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <SaveButton
                id={submitButtonId}
                className={styles.button}
                saving={saving}
              />
            </form>
          </div>}

        <span ref={valueRef} className={styles.staticValue}>
          {children}
        </span>

        <PlainButtonAsLink
          onClick={handleEdit}
          className={styles.button}
          type="button"
        >
          <IconPen
            className={styles.iconPen}
          />
        </PlainButtonAsLink>
      </div>
    </div>
  );
};

export default PlainEditableProp;
