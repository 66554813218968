import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { defaultOperationAgeSelection, operationAgeFilter, operationAgeFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

export const OperationAgeFilter = ({ id, pending }: Props) => {
  return (
    <PlainItemsSelectorBox
      id={id}
      label="Давность операции"
      header="Выбор давности операции"
      dataBoxData={operationAgeFilter}
      dataBoxSelection={operationAgeFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      defaultSelection={defaultOperationAgeSelection}
      disabled={pending}
    />

  )
}
