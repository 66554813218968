// @flow
import * as orgTypes from 'app/common/constants/schools/types';
import type { Request } from '../index';


export { orgTypes };
export type OrgTypesResult = Array<string>

// DEPRECATED use src/common/api/requests/food/school_types/get
export const GET_ORG_TYPES: Request<
  void,
  OrgTypesResult,
  void
> = [
  'food/school_types',
  'get',
  ['data', 'school_types'],
];
