// @flow
import path from 'ramda/es/path';
import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const BUILDINGS_FILTER_REQUEST = 'reports/BUILDINGS_FILTER_REQUEST';
export const BUILDINGS_FILTER_SUCCESS = 'reports/BUILDINGS_FILTER_SUCCESS';
export const BUILDINGS_FILTER_FAILURE = 'reports/BUILDINGS_FILTER_FAILURE';
export const BUILDINGS_FILTER_CLEAR = 'reports/BUILDINGS_FILTER_CLEAR';
export const BUILDINGS_FILTER_DELETE = 'reports/BUILDINGS_FILTER_DELETE';
export const BUILDINGS_FILTER_SET = 'reports/BUILDINGS_FILTER_SET';
export const BUILDINGS_FILTER_UPDATE = 'reports/BUILDINGS_FILTER_UPDATE';

export const buildingsFilterFetch = (orgId: number) => async (dispatch: Function) => {
  dispatch(action(BUILDINGS_FILTER_REQUEST));
  try {
    const res = await api(`food/schools/${orgId}/buildings`, 'get');
    dispatch(action(BUILDINGS_FILTER_SUCCESS, path(['data', 'buildings'], res)));
  } catch (error) {
    dispatch(action(BUILDINGS_FILTER_FAILURE, { err: 'Не удалось получить данные фильтра по корпусам', error }));
  }
};
