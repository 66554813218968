import { Applies } from 'app/dataTypes/subsidy/settings'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import { FormGridRow, FormGridTemplate } from './FormGridTemplate'


export type Errors = Map<string, string>

export const controls = {
  hasApplies: 'hasApplies',
  cafeteria: 'cafeteria',
} as const

type Props = {
  data: Applies
  disabled?: boolean
  formID: string
  errors: Errors
  resetErrors: () => void
}

const AppliesForm = ({ data, disabled, formID, errors, resetErrors }: Props) => {
  const error = errors.get('applies')

  return (
    <>
      <input
        type="checkbox"
        name={controls.hasApplies}
        value="active"
        style={{ display: 'none' }}
        form={formID}
        readOnly
        checked
      />
      <FormGridTemplate
        title="Льготное питание:"
      >
        <FormGridRow
          name={controls.cafeteria}
          title="Справка для льготного питания"
          data={data.cafeteria}
          disabled={disabled}
          formID={formID}
          onModify={resetErrors}
        />
      </FormGridTemplate>
      <PlainHelperText
        className={getScrollToErrorClassName(!!error)}
        error
      >
        {error}
      </PlainHelperText>
    </>
  )
}

export default AppliesForm
