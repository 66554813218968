import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';

import user from 'app/common/reducers/user';
import profile from 'app/common/reducers/profile';
import { USER_LOGOUT } from 'app/common/actions/user';
import confirmation from 'app/common/reducers/confirmation';
import about from 'app/common/reducers/about';
import popup from 'app/common/reducers/popup';
import errors from 'app/common/reducers/errors';
import documents from 'app/common/reducers/documents';

import dealer from './dealerReducer';

import apps from './apps';
import settings from './settings';
import dishes from './dishes';
import categories from './categories';
import orgs from './orgs';
import employees from './employees';
import admin from './admin';
import props from './props';
import userRights from './userRights';
import reports from './reports';
// import subsidies from './subsidies';


const appReducer = history => combineReducers({
// const appReducer = combineReducers({
  // router: routerReducer,
  router: connectRouter(history),
  errors,
  user,
  dealer,
  popup,
  apps,
  settings,
  dishes,
  categories,
  profile,
  confirmation,
  orgs,
  employees,
  admin,
  about,
  props,
  documents,
  userRights,
  reports,
  // subsidies,
});

const createRootReducer = history => (state, action) => {
  if (action.type === USER_LOGOUT) {
    // При выходе вернуть пустой стор, но с информацией о поставщике
    // TODO убрать
    return appReducer(history)({ dealer: state.dealer }, action);
  }

  return appReducer(history)(state, action);
};

export default createRootReducer;
