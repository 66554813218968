// @flow
import type { HistoryModalProps } from 'app/common/ui-components/history-modal';
import { useChanges } from 'app/dataProviders/OrgSettingsHistoryProvider';

import renderCellContent from './renderCellContent';


const columnIdsList = ['created', 'source', 'actor', 'description'];
const columnNamesList = ['Дата изменения', 'Место', 'Кто внес изменения', 'Что было изменено'];
const alignLeft = { alignLeft: true };
const columnPropsList = [alignLeft, alignLeft, alignLeft, alignLeft];

type HistoryProps = $Diff<HistoryModalProps, { children: any }>


export default function useHistoryProps(): HistoryProps {
  const [{ changes, loading }, { request }] = useChanges();

  return {
    title: 'История изменений',
    emptyWarnMessage: 'Отсутствуют записи в истории',
    loading,
    columnIdsList,
    columnNamesList,
    columnPropsList,
    itemsList: changes,
    onRequestHistory: request,
    renderCellContent,
  };
}
