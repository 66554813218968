// @flow
import React, { Fragment, type Node } from 'react';
import { Helmet } from 'react-helmet';

import CSSSticky from 'app/common/ui-components/css-sticky';

import styles from './CommonPageTemplate.scss';


type Props = {
  title: string,
  header: string | Node,
  stickable?: boolean,
  rightSideNode?: Node,
  children: Node,
}

const CommonPageTemplate = ({ title, header, stickable, rightSideNode, children }: Props) => {
  const headerNode = (
    rightSideNode
      ? (<div className={styles.headerContainer}>
        <h1>{header}</h1>
        {rightSideNode}
      </div>)
      : <h1>{header}</h1>
  );

  const headerNodeComplex = (
    stickable
      ? (<CSSSticky
        number={1}
        className={styles.stickable}
      >
        {headerNode}
      </CSSSticky>)
      : headerNode
  );


  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {headerNodeComplex}
      {children}
    </Fragment>
  );
};

export default CommonPageTemplate;
