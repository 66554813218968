// @flow
import React from 'react';

import Paging from 'app/common/ui/Paging';
import { usePaging } from 'app/dataProviders/OrgSettingsHistoryProvider';

import styles from './HistoryModalFooter.scss';


type Props = {
  currentPageSize: number,
}

const HistoryModalFooter = ({ currentPageSize }: Props) => {
  const { currentPage, pageSize, total, onPage } = usePaging();

  return (
    <Paging
      className={styles.paging}
      total={total}
      pageSize={pageSize}
      currentPage={currentPage}
      currentPageSize={currentPageSize}
      onPage={onPage}
    />
  );
};

export default HistoryModalFooter;
