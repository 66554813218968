// @flow
import api from 'app/actions/api';

import { categoryCountSet } from './categories';


export const DISHES_REQUEST = 'orgs/DISHES_REQUEST';
export const DISHES_SUCCESS = 'orgs/DISHES_SUCCESS';
export const DISHES_FAILURE = 'orgs/DISHES_FAILURE';
export const DISHES_STATE_CLEAR = 'orgs/DISHES_STATE_CLEAR';

/**
* Запрос блюд для школы
*/

export const dishesRequest = (payload: Object) => ({
  type: DISHES_REQUEST,
  payload,
});

export const dishesSuccess = (payload: Object) => ({
  type: DISHES_SUCCESS,
  payload,
});

export const dishesFailure = (payload: Object) => ({
  type: DISHES_FAILURE,
  payload,
});

export const dishesStateClear = () => ({
  type: DISHES_STATE_CLEAR,
});

type Params = {
  saleType?: string,
  search?: string,
  limit?: number,
  offset?: number,
  category_id?: number, // если не указано, возвращаются все блюда школы
};

export const dishesFetch = (orgId: string, params?: Params) => async (dispatch: Function) => {
  dispatch(dishesRequest(params || {}));
  try {
    const { data } = await api(`food/schools/${orgId}/items`, 'get', { params });
    dispatch(dishesSuccess(data));
    if (!params || !params.category_id || params.saleType || params.search) {
      return;
    }
    const { category_id: categoryId } = params;
    const { count } = data;
    dispatch(categoryCountSet({ categoryId, count }));
  } catch (error) {
    dispatch(dishesFailure({ err: 'Запрос блюд школы не удался', error }));
  }
};

export const DISHES_CANCEL = 'orgs/DISHES_CANCEL';

export const dishesCancel = () => ({
  type: DISHES_CANCEL,
});

export const dishesFetchSearch = (orgId: string, params?: Params) => async (dispatch: Function) => {
  dispatch(dishesRequest(params || {}));
  try {
    const { data } = await api(`food/schools/${orgId}/items`, 'get', { params });
    dispatch(dishesSuccess(data));
  } catch (error) {
    dispatch(dishesCancel());
  }
};
