// @flow
import React, { Fragment } from 'react';
import FavPanel from 'app/common/components/FavPanel';

import {
  useReportsEnabled as useFinancesReportsEnabled,
  getReportTitlesAll as getFinancesReportTitlesAll,
} from '../finances';

import { useReportsEnabled, getReportTitlesAll } from '../Reports';


const emptyArray = [];

const FavoritesBar = () => {
  const [enabledReports, loadingReports] = useReportsEnabled();
  const favorites = loadingReports ? emptyArray : enabledReports;

  const [enabledFinancesReports, loadingFinancesReports] = useFinancesReportsEnabled();
  const financesFavorites = loadingFinancesReports ? emptyArray : enabledFinancesReports;

  return (
    <Fragment>
      <FavPanel
        basePath="/reports"
        favorites={favorites}
        names={getReportTitlesAll()}
        showConditionReg={/^\/(reports)(\/|$)/}
        shallow
      />
      <FavPanel
        basePath="/finances"
        favorites={financesFavorites}
        names={getFinancesReportTitlesAll()}
        showConditionReg={/^\/(finances)(\/|$)/}
      />
    </Fragment>
  );
};

export default FavoritesBar;
