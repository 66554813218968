// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as orgType } from '../orgType';
import { flags as org } from '../org';
import { flags as customerType } from '../customerType';
import { flags as classFlag } from '../class';
import { flags as studentStatus } from '../studentStatus';


const studentActivity = (state: State) => {
  return (
    orgType.filterActive(state)
    && orgType.nonEnterpriseOrgSelected(state)
    && org.filterActive(state)
    && org.singleOrgSelected(state)
    && customerType.filterActive(state)
    && customerType.onlyStudentCustomerSelected(state)
    && ((
      classFlag.filterActive(state)
      && classFlag.singleClassSelected(state)
    ) || (
      studentStatus.filterActive(state)
      && studentStatus.minorStudentStatusSelected(state)
    ))
  );
};

export default studentActivity;
