// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import path from 'ramda/es/path';

import usePaging from 'app/common/hooks/usePaging';
import { settingsKey } from 'app/reducers/settings/employeesAtWork';


export default function useEmployeesPagination() {
  const { currentPage, handlePage, setCurrentPage } = usePaging();
  const settings = useSelector(state => state.settings, shallowEqual);
  const limit = path([settingsKey, 'rows'], settings);
  const { current: { loading } } = settings;

  const offset = (currentPage - 1) * limit;

  const resetPagination = useCallback(() => {
    setCurrentPage(1);
  }, [setCurrentPage]);

  return {
    limit,
    offset,
    currentPage,
    handlePage,
    resetPagination,
    loading,
  };
}
