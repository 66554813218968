// @flow
import React, { Fragment, useEffect, useRef, useState } from 'react';

import PlainInputDate, { type PlainInputDateProps } from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';


export type PlainInputDateCompProps = {
  errorText?: string,
  label?: string,
  hideErrorString?: boolean,
  skipUpperSpace?: boolean,
  ...PlainInputDateProps,
}

const PlainInputDateComp = ({
  errorText = 'Требуется правильная дата',
  label,
  hideErrorString = true,
  skipUpperSpace,
  ...restProps
}: PlainInputDateCompProps) => {
  const firstRender = useRef(true);
  const [error, setError] = useState('');
  const { id, value } = restProps;

  useEffect(() => {
    if (!firstRender.current) {
      setError(value ? '' : errorText);
      return;
    }
    firstRender.current = false;
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {label
        && <PlainLabel htmlFor={id} skipUpperSpace={skipUpperSpace}>
          {`${label}:`}
        </PlainLabel>}

      <PlainInputDate
        {...restProps}
      />

      {(!hideErrorString || error)
        && <PlainHelperText error>
          {error}
        </PlainHelperText>}
    </Fragment>
  );
};

export default PlainInputDateComp;
