// @flow
import React, { useCallback, useState } from 'react';

import PlainDropdown, { PlainDropdownDefitem as DItem } from 'app/common/ui-next/plain-dropdown';
import { DotsLoader } from 'app/common/components/Loader';

/**
 *  Ячейка таблицы с выбором типа продаж
 */


type Props = {
  id: number,
  currentSaleType: ?string,
  loading: boolean,
  saleTypes: Array<{
    id: string,
    title: string,
  }>,
  onChangeSaleType: (id: string) => boolean | Promise<boolean>,
}

const DishesTableSaleTypes = ({
  id,
  loading,
  currentSaleType,
  saleTypes,
  onChangeSaleType,
}: Props) => {
  const [disabled, setDisabled] = useState(false);

  const handleChangeSaleType = useCallback(async (itemProps: { id: string }) => {
    const { id: id_ } = itemProps;
    setDisabled(true);
    await onChangeSaleType(id_);
    setDisabled(false);
  }, [onChangeSaleType]);


  return (
    <PlainDropdown
      defaultSkin
      id={`header_${id}`}
      label="Тип продаж"
      labelClassName="visuallyHidden"
      onChange={handleChangeSaleType}
      disabled={loading || disabled}
      placeholder={
        currentSaleType
          ? <DItem id={currentSaleType} disabled={disabled}>
            {disabled ? <DotsLoader simple /> : currentSaleType}
          </DItem>
          : <DItem id={-1} disabled={disabled}>
            {loading ? <DotsLoader simple /> : 'Не задан'}
          </DItem>
      }
    >
      {saleTypes.map(({ id: id_, title }) => (id_ !== currentSaleType
        ? <DItem key={id_} id={id_}>{title}</DItem>
        : null
      ))}
    </PlainDropdown>
  );
};

export default DishesTableSaleTypes;
