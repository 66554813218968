// @flow
import React, { type Ref } from 'react';

import { Tr, Th, getPropsId } from 'app/common/ui-components/table';

import type { HeaderCells } from './useHeaderCellsData';


type Props = {
  dummy?: boolean,
  reference?: Ref<'tr'>,
  hidden?: boolean,
  data: HeaderCells,
}

const HeaderRow = ({ dummy, hidden, reference, data }: Props) => {
  const idProps = getPropsId(dummy);

  return (
    <Tr
      reference={reference}
      hidden={hidden}
      header
    >
      {data.map(({ value, ...others }) => (
        <Th
          scope="col"
          {...idProps(others.key)}
          {...others}
        >
          {value}
        </Th>
      ))}
    </Tr>
  );
};

export default HeaderRow;
