// @flow
import React, { useMemo } from 'react'
import { Moment } from 'moment-timezone'

import { getMonthInGenitiveCase } from 'app/i18n'

type Props = {
  action?: string,
  mDate: Moment,
}


export const HeaderOrgsKits = ({ mDate, action }: Props) => {
  const commonHeader = useMemo(() => {
    if (!mDate || !mDate.isValid()) {
      return 'Комплекты'
    }

    const day = Number(mDate.format('D'))
    const monthNumber = Number(mDate.format('M'))
    const year = mDate.format('YYYY')

    const monthInGenitiveCase = getMonthInGenitiveCase(monthNumber)
    return `Комплекты от ${day} ${monthInGenitiveCase} ${year}`
  }, [mDate])

  return (
    <>
      {/* <h2 className={className}> */}
      {action !== 'deleted'
        && action !== 'hidden'
        && commonHeader}
      {action === 'deleted'
        && 'Удаленные комплекты'}
      {action === 'hidden'
        && 'Скрытые комплекты'}
      {/* </h2> */}
    </>
  )
}
