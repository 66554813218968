import api from 'app/actions/api';

export const DELETE_CATEGORY_REQUEST = 'orgs/DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'orgs/DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'orgs/DELETE_CATEGORY_FAILURE';

const deleteCategoryRequest = payload => ({
  type: DELETE_CATEGORY_REQUEST,
  payload,
});

const deleteCategorySuccess = payload => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload,
});

const deleteCategoryFailure = payload => ({
  type: DELETE_CATEGORY_FAILURE,
  payload,
});

export const deleteCategory = (orgId, categoryId) => async (dispatch) => {
  const data = { category_id: categoryId, school_id: orgId };
  dispatch(deleteCategoryRequest(data));
  try {
    await api('food/menu/item_category', 'delete', { data });
    dispatch(deleteCategorySuccess(categoryId));
    return true;
  } catch (error) {
    dispatch(deleteCategoryFailure({ err: 'Не удалось удалить категорию', error }));
    return false;
  }
};
