// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import CSSSticky from 'app/common/ui-components/css-sticky';

import styles from './ReportSectionCSS.scss';


const cx = classNames.bind(styles);

type Props = {
  children: any,
  header?: string,
  headerBar?: Node,
  headerAdditional?: Node,
  stickyNumber?: number,
  skipNextShift?: boolean,
  // reference?: (el: React.ElementRef<'div'> | null) => void,
  top?: boolean,
  noSpace?: boolean,
  wideContent?: boolean,
  paddingFree?: boolean,
  fullscreen?: boolean,
  space?: boolean,
}

const ReportSectionCSS = ({
  children,
  header,
  headerBar,
  headerAdditional,
  stickyNumber,
  skipNextShift,
  // reference,
  top,
  noSpace,
  wideContent,
  paddingFree,
  fullscreen,
  space,
}: Props) => {
  const headerNode = (
    <div className={styles.headerBlock}>
      <h2>{header}</h2>
      <div>{headerBar}</div>
    </div>
  );

  return (
    <div className={cx(styles.root, { fullscreen, top, noSpace, space })}>
      {(header || headerBar)
        && <CSSSticky
          number={stickyNumber || 1}
          shadowClassName={styles.shadow}
          stickyClassName={styles.sticky}
          skipNextShift={skipNextShift}
        >
          {headerAdditional
            ? <div>
              {headerNode}
              {headerAdditional}
            </div>
            : headerNode}
        </CSSSticky>}
      <div className={cx(styles.content, { wideContent, paddingFree })}>
        {children}
      </div>
    </div>
  );
};

export default ReportSectionCSS;
