// @flow
import { useCallback } from 'react';

import usePopupErr from 'app/common/hooks/usePopupErr';
import { POST_ITEMS_ADD_REMOVE, convertParams as convertParamsDishes } from 'app/common/api/requests/food/menu/menu_items_add_remove';
import { menuNeedUpdate } from 'app/actions/orgs/menu/options';
import { api } from 'app/api';


type Options = {
  dishId: number,
  orgId: number,
  date: string,
  sellPointId: number,
}

export default function useDishDelete({ dishId, orgId, sellPointId, date }: Options) {
  const [, popup, dispatch] = usePopupErr();

  const deleteDish = useCallback(async () => {
    const res = await api.request(POST_ITEMS_ADD_REMOVE, {
      error: 'Не удалось удалить продукцию из меню',
      params: {
        orgId,
        sellPointId,
        date,
        add: [],
        remove: [dishId],
      },
      convertions: {
        convertParams: convertParamsDishes,
      },
    });

    if (res) {
      dispatch(menuNeedUpdate(true));
      popup('Продукция была успешно удалена из меню');
    }
  }, [
    orgId,
    sellPointId,
    date,
    dishId,
    dispatch,
    popup,
  ]);

  return deleteDish;
}
