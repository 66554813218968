import { useState, useCallback, useEffect } from 'react'

import {
  GET_SCHOOL_ALLOWANCES,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/schools/subsidy_orders_allowances/get'

import { api } from 'app/api'
import { Allowances, CommonAlowance } from 'app/dataTypes/subsidy/allowances'


const defaultAllowances = [] as Allowances

type Options = {
  orgID: string
  used?: boolean
  app: 'cafeteria' | 'camp'
  subsidyType?: 'common' | 'personal'
}

export default function useOrgAllowances({
  orgID,
  used,
  app,
  subsidyType,
}: Options): [Allowances, boolean, () => Promise<void>] {
  const [loading, setLoading] = useState(true)
  const [allowances, setAllowances] = useState<Allowances>(defaultAllowances)

  const handleRequest = useCallback(async () => {
    let res: Allowances | null = [] as Allowances

    if (subsidyType === 'personal' || !subsidyType) {
      setLoading(true)
      res = await api.request(GET_SCHOOL_ALLOWANCES, {
        error: 'Не удалось получить список льгот',
        params: {
          app,
          used,
          orgID,
          subsidyType: 'personal',
        },
        convertions: {
          convertParams,
          convertResult,
        },
      })
      setLoading(false)

      if (res && subsidyType) {
        setAllowances(res)
        return
      }
    }

    const commonAllowances = Object.values(CommonAlowance).map(ID => ({
      ID,
      title: ID,
      deletable: false,
    }))

    if (subsidyType === 'common') {
      setAllowances(commonAllowances)
      setLoading(false)
      return
    }

    if (!subsidyType && res) {
      setAllowances([...res, ...commonAllowances])
      setLoading(false)
      return
    }

    setAllowances(defaultAllowances)
    setLoading(false)
  }, [app, used, subsidyType, orgID])

  useEffect(() => {
    handleRequest()
  }, [handleRequest])

  return [
    allowances,
    loading,
    handleRequest,
  ]
}
