import { useCallback } from 'react'

import { GET_REQUISITES_EXAMPLE } from 'app/common/api/requests/food/requisites/example/get'
import { api } from 'app/api'


type Options = {
  type: 'school' | 'camp'
  filename: string
}

export default function useExample({ type, filename }: Options) {
  const download = useCallback(async () => {
    await api.download(GET_REQUISITES_EXAMPLE, {
      error: 'Не удалось загрузить файл',
      filename,
      params: {
        template: type === 'camp' ? 'camp_payment_order' : 'school_payment_order',
      },
    })
  }, [type, filename])

  return download
}
