// @flow
import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import OrgsAvailableTypesProvider from 'app/dataProviders/org/orgTypes/OrgsAvailableTypesProvider';
import VendingDataProvider from 'app/dataProviders/VendingDataProvider';

import OrgsListPage from './OrgsListPage';
import getBaseUrl from './getBaseUrl';


const baseUrl = getBaseUrl();
const baseUrlWithType = getBaseUrl({ orgType: ':orgType' });

const OrgsListPageRoot = () => {
  return (
    <OrgsAvailableTypesProvider>
      <VendingDataProvider>
        <Switch>
          <Route path={baseUrl} component={OrgsListPage} exact />
          <Route path={baseUrlWithType} component={OrgsListPage} exact />
          <Redirect to={baseUrl} />
        </Switch>
      </VendingDataProvider>
    </OrgsAvailableTypesProvider>
  );
};

export default OrgsListPageRoot;
