// @flow
import { useMemo } from 'react';
import prop from 'ramda/es/prop';

import { tableColumnSort } from 'app/common/lib/sort';

import { pipe, toLower } from 'ramda';
import type { Item, Complex } from '../types';


export type ItemSorted = {
  title: string,
  portion: ?string,
  cost: number,
  n: number,
  sum: number,
}

export type ComplexSorted = {
  title: string,
  portion: null,
  items: Array<{
    title: string,
    cost: ?string,
    portion: ?string,
    index: number,
  }>,
  cost: number,
  n: number,
  sum: number,
}

export default function useSortedItems(
  items: Array<Item>,
  complexes: Array<Complex>,
  sortKey: string,
  sortDirection: 'up' | 'down',
): [
    Array<ComplexSorted>,
    Array<ItemSorted>
  ] {
  const areAllComplexesStrings = complexes.every(value => typeof value[sortKey] === 'string');
  const areAllItemsStrings = items.every(value => typeof value[sortKey] === 'string');

  const sortedKits = useMemo(
    () => tableColumnSort(sortDirection, areAllComplexesStrings ? pipe(prop(sortKey), toLower) : prop(sortKey), complexes),
    [sortDirection, sortKey, complexes],
  );

  const sortedItems = useMemo(
    () => tableColumnSort(sortDirection, areAllItemsStrings ? pipe(prop(sortKey), toLower) : prop(sortKey), items),
    [sortDirection, sortKey, items],
  );

  return [sortedKits, sortedItems];
}
