import { MutationParams, Settings } from 'app/dataTypes/subsidy/settings'
import { isCommonAllowance } from 'app/dataTypes/subsidy/allowances'

import { Request } from '../../../index'
import { convertResult as convertResultGET } from './get'
import { CafeteriaSettingsAPI, convertAsCafeteriaPOST } from './specific/cafeteria'
import { CampSettingsAPI, convertAsCampPOST } from './specific/camp'


type ParamsAPI = {
  order_type: 'cafeteria' | 'camp' | 'common'
  allowance?: string
  settings: ResultAPI['settings']
}

type PathParams = {
  orgID: string
}

export const convertParams = (mutationParams: MutationParams): [ParamsAPI, PathParams] => {
  const {
    orgID,
    allowanceID,
    settings,
  } = mutationParams

  const { app } = settings
  const settingsAPI = app === 'cafeteria' ? convertAsCafeteriaPOST(settings) : convertAsCampPOST(settings)

  const common = isCommonAllowance(allowanceID)
  const orderType = common ? 'common' : app

  return [{
    order_type: orderType,
    ...(common ? {} : { allowance: allowanceID }),
    settings: settingsAPI,
  }, { orgID }]
}

export type ResultAPI = {
  settings: CafeteriaSettingsAPI | CampSettingsAPI
  complexes?: Array<{ id: number, title: string }>
}

export const convertResult = (res: ResultAPI, params: MutationParams) => {
  return convertResultGET(res, { ...params, app: params.settings.app })
}

export const POST_SCHOOL_SUBSIDY_ORDERS_SETTINGS: Request<MutationParams, Settings, PathParams> = [
  'food/schools/{orgID}/subsidy_orders_settings',
  'post',
  ['data'],
]
