// @flow
import React from 'react';

import type { State, PlainRadioGroupRestProps } from 'app/common/ui-components/filter';
import PlainLink from 'app/common/ui-next/plain-link';

import type { CommonParams } from './CommonParams.type';
import styles from './mailProps.scss';


const getPropsMail = (_: State, params: CommonParams): PlainRadioGroupRestProps => {
  const { email = null } = params || {};

  return {
    label: (email
      ? <span>
        <span>{'Отправить отчет на '}</span>
        <span className={styles.email}>{email}</span>
      </span>
      : 'Отправить отчет на email'),

    afterContent: (!email
      ? <div className={styles.comment}>
        <span className={styles.warning}>{'В профиле e-mail не указан. '}</span>
        <PlainLink to="/profile" className={styles.link}>{'Перейти в профиль'}</PlainLink>
      </div>
      : null),
  };
};

export default getPropsMail;
