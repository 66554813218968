// @flow
import mailItems, { mailTypes, mailItemsDisabledSending } from './mailItems';
import mailProps from './mailProps';
import mailActivity from './mailActivity';


const filterId: 'mail' = 'mail';
export default filterId;

export const readyToUse = (key: ?string): boolean => (
  key === mailTypes.MAIL_AND_VIEW || key === mailTypes.MAIL_ONLY
);

export const onlyMail = (key: ?string): boolean => (
  key === mailTypes.MAIL_ONLY
);

export {
  mailItems,
  mailProps,
  mailTypes,
  mailActivity,
  mailItemsDisabledSending,
};
