import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import get from 'app/common/lib/get';
import path from 'ramda/es/path';
import { withTranslation } from 'react-i18next';

import { setToken } from 'app/common/lib/token';
import { LOCKED_UNTIL } from 'app/common/constants/localStorage';

import api from '../../api';
import apiTestLogin from '../apiTestLogin';
import { validateLogin, validateCode } from '../../validate';


export default compose(
  withState('testing', 'setTesting', false),
  withState('loading', 'setLoading', false),
  withState('login', 'changeLogin', ({ loginInfo }) => (loginInfo.login || '')),
  withState(
    'isLoginValid',
    'setLoginValid',
    ({ loginInfo, countryISOCode, emailForceAuth }) => !!(loginInfo.login
      && validateLogin(
        countryISOCode, // не имеет значение
        loginInfo.login,
        {
          types: emailForceAuth ? ['email'] : ['phone', 'email'],
        },
      )
    ),
  ),
  withState('loginError', 'setLoginError', ''),
  withState('timeLeft', 'setTimeLeft', ''),

  withState('codeSent', 'setCodeSent', false),

  withState('sending', 'setSending', false),
  withState('code', 'changeCode', ''),
  withState('isCodeValid', 'setCodeValid', false),
  withState('codeError', 'setCodeError', ''),

  withState('token', 'setTokenLocal', ''),
  withState('passError', 'setPassError', ''),

  withState('codeTo', 'setCodeTo', ''),

  withTranslation(),

  withHandlers({
    handleLogin: ({
      changeLogin,
      setLoginValid,
      loginError,
      setLoginError,
      countryISOCode,
      emailForceAuth,
    }) => (e) => {
      const { value } = e.currentTarget;
      changeLogin(value);

      setLoginValid(validateLogin(
        countryISOCode, // не имеет значение
        value,
        {
          types: emailForceAuth ? ['email'] : ['phone', 'email'],
        },
      ));

      if (loginError) {
        setLoginError('');
      }
    },

    submitLogin: ({
      login,
      setLoginError,
      setCodeSent,
      setLoading,
      isLoginValid,
      loading,
      loginInfo,
      setLoginInfo,
      testing,
      setTesting,
      setCodeTo,
      exampleFormat,
      t,
      emailForceAuth,
    }) => async (e) => {
      if (e) {
        e.preventDefault();
      }
      if (!isLoginValid) {
        setLoginError(emailForceAuth
          ? t('login:login_email_error_format', 'Адрес электронной почты введен неверно.')
          : t('login:login_error_format', { format: exampleFormat }));
        return;
      }

      if (loading || testing || ((localStorage[LOCKED_UNTIL] - Date.now()) > 0)) {
        return;
      }

      if (login !== loginInfo.login) {
        // проверка логина, если не проверено
        await apiTestLogin(
          login,
          setLoginInfo,
          setLoginError,
          () => setTesting(true),
          () => setTesting(false),
        );
      }
      // запрос кода
      setLoading(true);
      api('auth/confirmation', 'post', { data: { login } })
        .then((response) => {
          const result = path(['data', 'result'], response);
          if (result === 'success') {
            const timeLeft = path(['data', 'time_left'], response) + 1;
            const email = path(['data', 'email'], response);
            const phone = path(['data', 'phone'], response);
            const lockedUntil = Date.now() + parseInt(timeLeft, 10);
            localStorage[LOCKED_UNTIL] = lockedUntil;
            if (phone || email) {
              setCodeTo(phone || email);
            }
            setCodeSent(true);
          } else {
            setLoginError(t('login:request_error'));
          }
          setLoading(false);
        })
        .catch((error) => {
          let errorText = t('login:connect_error');
          const status = path(['response', 'status'], error);
          if (status === 404) {
            errorText = t('login:unknown_login');
          } else if (status === 429) {
            errorText = t('login:code_timeout_error');
          } else if (status) {
            errorText = t('login:request_error');
          }
          setLoginError(errorText);
          setLoading(false);
        });
    },

    handleCode: ({ changeCode, codeError, seCodeError, setCodeValid }) => (e) => {
      const { value } = e.currentTarget;
      setCodeValid(validateCode(value));
      changeCode(value);
      if (codeError) {
        seCodeError('');
      }
    },

    submitCode: ({ login, code, isCodeValid, sending, setSending, setCodeError, setTokenLocal, t }) => (e) => {
      e.preventDefault();
      if (!isCodeValid || sending) return;
      setSending(true);
      api('auth/confirmation/check', 'post', { data: { login, confirmation: code } })
        .then((response) => {
          const token = get(response, 'data.token', null);
          if (get(response, 'data.result', null) === 'success' && token) {
            setTokenLocal(token);
          } else {
            setCodeError(t('login:request_error'));
          }
          setSending(false);
        })
        .catch((error) => {
          console.log(error);
          const status = path(['response', 'status'], error);
          let errorText = t('login:connect_error');
          if (status === 403) {
            errorText = t('login:code_error');
          } else if (status === 404) {
            errorText = t('login:unknown_login');
          } else if (status) {
            errorText = t('login:request_error');
          }
          setCodeError(errorText);
          setSending(false);
        });
    },

    submitPass: ({ setSending, code, setPassError, token, loginInfo, t }) => (password) => {
      const data = { password, confirmation: code };
      if (loginInfo.agreementUrl && loginInfo.accepted) {
        data.terms_of_use_accepted = true;
      }
      setSending(true);
      api('auth/password', 'post', { data }, { 'Argus-Auth-Token': token || '' })
        .then((response) => {
          const newToken = get(response, 'data.token', null);
          if (get(response, 'data.result', null) === 'success' && newToken) {
            setToken(newToken);
          } else {
            setPassError(t('login:request_error'));
          }
          setSending(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response.status === 400) {
              setPassError(t('login:request_error'));
            } else if (error.response.status === 403) {
              setPassError(t('login:code_error'));
            } else {
              setPassError(t('login:request_error'));
            }
          } else {
            setPassError(t('login:connect_error'));
          }
          setSending(false);
        });
    },

    handleBack: ({ setTokenLocal }) => (e) => {
      e.preventDefault();
      setTokenLocal('');
    },
  }),


  lifecycle({
    componentDidMount() {
      const { hideLogin, submitLogin } = this.props;
      if (hideLogin) {
        submitLogin();
      }
      const calcTime = () => {
        const left = localStorage[LOCKED_UNTIL] - Date.now();
        if (left > 0) {
          const sec = Math.floor(left / 1000);
          const min = Math.floor(sec / 60);
          const bits = sec % 60;
          this.props.setTimeLeft(`${min}:${bits < 10 ? 0 : ''}${bits}`);
        } else if (this.props.timeLeft) {
          this.props.setTimeLeft('');
        }
      };
      calcTime();
      this.timer = setInterval(calcTime, 1000);
    },
    componentWillUnmount() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
  }),
);
