// @flow
import React, { Fragment, useState, useCallback } from 'react';
import filter from 'ramda/es/filter';
import append from 'ramda/es/append';

import Modal from 'app/common/components/Modal';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainEditableProp from 'app/common/ui-next/plain-editable-prop';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';

import { type SaveType } from '../DishesData';
import styles from './DishesTableEditableProp.scss';

/**
 *  Ячейка таблицы с редактируемым полем
 */

// доступные для выбора опции
const availableOptions = ['school', 'single', 'complex'];

type Props = {
  value: string,
  onSubmit: (value: string, options: Array<SaveType>) => boolean | Promise<boolean>,
  floatType?: boolean,
}

const DishesTableEditableProp = ({ value, onSubmit, floatType }: Props) => {
  const [value2Submit, setValue2Submit] = useState<null | string>(null);
  const [saving, setSaving] = useState(false);
  const [valueParams, setValueParams] = useState<Array<SaveType>>(['supplier']);

  const cancelModal = useCallback(() => {
    setValue2Submit(null);
  }, []);

  const handleUpdateValue2Submit = useCallback((val: string) => {
    setValue2Submit(val);
  }, []);


  const handleChangeSavingOption = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: val, checked } = e.currentTarget;
    if (availableOptions.includes(val)) {
      if (checked) {
        setValueParams(append(val));
      } else {
        setValueParams(filter(item => item !== val));
      }
    }
  }, []);

  const handleSubmit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!value2Submit) return;

    setSaving(true);
    const res = await onSubmit(value2Submit, valueParams);
    setSaving(false);

    if (res) {
      setValue2Submit(null);
    }
  }, [onSubmit, value2Submit, valueParams]);


  return (
    <Fragment>
      <PlainEditableProp
        value={value}
        onSubmit={handleUpdateValue2Submit}
        floatType={floatType}
      >
        {value}
      </PlainEditableProp>

      {value2Submit
        && <Modal toggleModal={cancelModal} className={styles.modal}>
          <div className={styles.modalContent}>
            <form onSubmit={handleSubmit}>
              <PlainCheckbox
                label="Сохранить только в списке продукции поставщика"
                id="supplier"
                value="supplier"
                name="supplier"
                checked={valueParams.includes('supplier')}
                onChange={handleChangeSavingOption}
                disabled
              />
              <PlainCheckbox
                label="Сохранить во всех доступных вам школах, где используется эта продукция"
                id="school"
                value="school"
                name="school"
                checked={valueParams.includes('school')}
                onChange={handleChangeSavingOption}
              />
              <PlainLabel
                id="allSchoolsLabel"
                className={styles.label}
              >
                {'Внести изменения в меню во всех доступных вам школах:'}
              </PlainLabel>

              <PlainFieldset
                ariaLabelledby="allSchoolsLabel"
                className={styles.level1}
                name="allSchools"
              >
                <PlainCheckbox
                  label="Применить к одиночной продукции"
                  id="single"
                  value="single"
                  name="single"
                  checked={valueParams.includes('single')}
                  onChange={handleChangeSavingOption}
                />
                <PlainCheckbox
                  label="Применить к продукции в комплектах"
                  id="complex"
                  value="complex"
                  name="complex"
                  checked={valueParams.includes('complex')}
                  onChange={handleChangeSavingOption}
                />
              </PlainFieldset>

              <ControlsBar middle className={styles.buttons}>
                <PlainButton onClick={cancelModal} outline>
                  {'Отмена'}
                </PlainButton>
                <PlainButton type="submit" autoFocus>
                  {saving ? 'Сохраняется…' : 'Сохранить'}
                </PlainButton>
              </ControlsBar>
            </form>
          </div>
        </Modal>}
    </Fragment>
  );
};

export default DishesTableEditableProp;
