import api from 'app/actions/api';

export const KIT_HIDE_REQUEST = 'orgs/kits/KIT_HIDE_REQUEST';
export const KIT_HIDE_SUCCESS = 'orgs/kits/KIT_HIDE_SUCCESS';
export const KIT_HIDE_FAILURE = 'orgs/kits/KIT_HIDE_FAILURE';

export const kitHideRequest = payload => ({
  type: KIT_HIDE_REQUEST,
  payload,
});

export const kitHideSuccess = payload => ({
  type: KIT_HIDE_SUCCESS,
  payload,
});

export const kitHideFailure = payload => ({
  type: KIT_HIDE_FAILURE,
  payload,
});

export const kitHide = (orgId, kitId) => async (dispatch) => {
  dispatch(kitHideRequest({ orgId, kitId }));
  try {
    await api(`food/schools/${orgId}/complexes/${kitId}/toggle_visibility`, 'post');
    dispatch(kitHideSuccess({ orgId, kitId }));
    return true;
  } catch (error) {
    dispatch(kitHideFailure({ err: 'Не удалось скрыть комплект', error }));
    return false;
  }
};
