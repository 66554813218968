import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import lifecycle from 'recompose/lifecycle';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import trim from 'ramda/es/trim';
import { withTranslation } from 'react-i18next';

import { getDomain } from 'app/common/lib/params';
import { validateLoginAsPhone, validateLoginAsEmail } from 'app/common/login/validate';

import api from '../../api';
import { Text } from '../Agreement';
import LoginExist from './LoginExist';
import LoginSuccess from './LoginSuccess';
import { REG_BY_EMAIL, REG_BY_PHONE } from './Register';
import login2Strict from '../../login2Strict';


const NEXT_LOGIN_EXIST = 'exist';
const NEXT_LOGIN_SUCCESS = 'success';

type AvailableRegTypes = Array<typeof REG_BY_EMAIL | typeof REG_BY_PHONE> | []
type CheckLoginRet = typeof REG_BY_EMAIL | typeof REG_BY_PHONE | ''

const checkLogin = (availableRegTypes: AvailableRegTypes, login: string): CheckLoginRet => {
  const loginAsEmail = availableRegTypes.includes(REG_BY_EMAIL) && validateLoginAsEmail(login);
  if (loginAsEmail) return REG_BY_EMAIL;
  const loginAsPhone = availableRegTypes.includes(REG_BY_PHONE) && validateLoginAsPhone(login);
  if (loginAsPhone) return REG_BY_PHONE;
  return '';
};

export default compose(
  withTranslation(),

  mapProps((props) => {
    const { availableRegTypes, t } = props;
    const wat = availableRegTypes
      .join(` ${t('login:register_or')} `)
      .replace('phone', t('login:register_required_phone'));
    return ({
      ...props,
      wat,
    });
  }),

  withState('watEntered', 'setWatEntered', ''),
  withState('url', 'setAgreementUrl', ''),
  withState('next', 'setNext', ''),
  withState('fields', 'setFields', ({ termsAcceptRequired }) => ({
    login: '',
    firstName: '',
    lastName: '',
    middleName: '',
    privacy: false,
    terms: !termsAcceptRequired,
  })),
  withState('error', 'setError', ''),
  withState('submitting', 'setSubmitting', false),

  withHandlers({
    errorsCheck: ({ setError, availableRegTypes, fields, setWatEntered, t, exampleFormat }) => () => {
      const { login } = fields;
      const res = checkLogin(availableRegTypes, trim(login));

      if (!res) {
        if (availableRegTypes.length === 1 && availableRegTypes[0] === REG_BY_PHONE) {
          setError(t('login:login_phone_error_format', { format: exampleFormat }));
          return true;
        }
        if (availableRegTypes.length === 1 && availableRegTypes[0] === REG_BY_EMAIL) {
          setError(t('login:login_email_error_format'));
          return true;
        }
        setError(t('login:login_error_format', { format: exampleFormat }));
        return true;
      }
      setWatEntered(res.replace('phone', t('login:register_required_phone')));
      return false;
    },
  }),

  withHandlers({
    // fields handlers
    handleFieldChange: ({ setFields, setError }) => (e) => {
      const { name, value } = e.currentTarget;
      setFields(props => ({ ...props, [name]: value }));
      setError('');
    },
    handleCheckChange: ({ setFields }) => (e) => {
      const { value, checked } = e.currentTarget;
      setFields(props => ({ ...props, [value]: checked }));
    },
    // agreement handlers
    handleShow: ({ setAgreementUrl, docs }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      setAgreementUrl(prop(name, docs));
    },
    handleHide: ({ setAgreementUrl }) => (e) => {
      e.preventDefault();
      setAgreementUrl('');
    },
    // next step handlers
    handleReturn: ({ setNext }) => (e) => {
      e.preventDefault();
      setNext('');
    },
    // submitting
    submitNewLogin: ({
      errorsCheck,
      setSubmitting,
      fields,
      setNext,
      setError,
      setLoginInfo,
      t,
    }) => (e) => {
      e.preventDefault();
      if (errorsCheck()) return;
      const domain = getDomain();
      const { login, firstName, lastName, middleName } = fields;
      const data = {
        domain,
        login: login2Strict(login),
        first_name: trim(firstName),
        middle_name: trim(middleName),
        last_name: trim(lastName),
      };
      setSubmitting(true);
      api('cabinet/register/parent', 'post', { data }).then(() => {
        setLoginInfo(props => ({ ...props, login }));
        setNext(NEXT_LOGIN_SUCCESS);
        setSubmitting(false);
      }).catch((error) => {
        setSubmitting(false);
        const status = path(['response', 'status'], error);
        if (status === 409) {
          setNext(NEXT_LOGIN_EXIST);
          return;
        }
        setError(t('login:register_failed'));
      });
    },
  }),

  branch(
    ({ url }) => url,
    renderComponent(Text),
  ),

  branch(
    ({ next }) => (next === NEXT_LOGIN_EXIST),
    renderComponent(LoginExist),
  ),

  branch(
    ({ next }) => (next === NEXT_LOGIN_SUCCESS),
    renderComponent(LoginSuccess),
  ),

  lifecycle({
    componentDidMount() {
      window.scrollTo(0, 0);
    },
  }),

  mapProps((props) => {
    const { login, firstName, lastName, privacy, terms } = props.fields;
    return ({
      ...props,
      readyToNext: privacy && terms && trim(login) && trim(firstName) && trim(lastName),
    });
  }),
);
