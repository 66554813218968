// @flow
import React from 'react';
import classNames from 'classnames';

import { useCurrencySign, options as co } from 'app/common/components-next/currency';

import MenuTableClearAll from './MenuTableClearAll';
import styles from './Cells.scss';
import stylesTable from './MenuTable.scss';


type Props = {
  orgId: number,
  sellPointId: number,
  sellPointTitle: string,
  date: string,
  dishesToRemove: Array<number>,
  kitsToRemove: Array<number>,
}

const MenuTableHeader = ({
  orgId,
  sellPointId,
  sellPointTitle,
  date,
  dishesToRemove,
  kitsToRemove,
}: Props) => {
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <div className={classNames(styles.row, styles.header)}>
      <div>&nbsp;</div>
      <div className={styles.rowLvl2}>
        <div>{'Наименование'}</div>
        <div className={stylesTable.right}>{'Порция'}</div>
        <div className={stylesTable.right}>{`Цена${currency}`}</div>
        <div>
          <MenuTableClearAll
            orgId={orgId}
            sellPointId={sellPointId}
            sellPointTitle={sellPointTitle}
            date={date}
            dishesToRemove={dishesToRemove}
            kitsToRemove={kitsToRemove}
          />
        </div>
      </div>
      <div>&nbsp;</div>
    </div>
  );
};

export default MenuTableHeader;
