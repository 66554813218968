import { ChangeEvent, useCallback, useState } from 'react'
import { RowLayoutWithSticky, RowLayoutContent } from 'app/common/layouts/RowLayout'
import { WithRights } from 'app/rights/Rights'

import * as rootRights from 'app/common/constants/rights/rootRights'
import * as specificRights from 'app/common/constants/rights/specificRights'
import Prompt from 'app/components/common/Prompt'

import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal'
import RequisitesForm from './RequisitesForm'
import useSubmit from './useSubmit'
import useData from './useData'
import useErrors from './errors/useSupportErrors'
import HeaderWithSubmit from '../../common/HeaderWithSubmit'


const formID = 'submit_requisites'

const rightsPath = [rootRights.SETTINGS, specificRights.REQUISITES] as const

const cancelMessage = 'Вы не сохранили изменения, отменить в любом случае?'
// const saveMessage = 'Cохранить изменения?'
const defaultConfirmButtonTitle = 'Да'
const defaultCancelButtonTitle = 'Нет'

const Requisites = () => {
  const { data, pending, change, modified, reset, update, checkError } = useData()
  const { errors, checkErrors, resetErrors } = useErrors({ data, checkError })
  const { submitting, submit } = useSubmit({ data, onSuccess: reset, onBeforeSubmit: checkErrors })
  const hasErrors = !!Object.keys(errors).length

  const [showConfirm, setShowConfirm] = useState<'cancel' | 'save' | null>(null)

  const resetDataHandler = useCallback(() => {
    reset()
    setShowConfirm(null)
    resetErrors()
  }, [reset, resetErrors])

  const submitDataHandler = useCallback(async (e: ChangeEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    submit()
  }, [submit])

  return (
    <RowLayoutWithSticky
      number={2}
      content={
        <RowLayoutContent borderBottom>
          <HeaderWithSubmit
            formID={formID}
            title="Реквизиты"
            modified={modified}
            disabled={hasErrors}
            submitting={submitting}
            onClickCancel={() => setShowConfirm('cancel')}
          />
        </RowLayoutContent>
      }
    >
      <RowLayoutContent>
        <WithRights pathTo={rightsPath}>
          <RequisitesForm
            formID={formID}
            data={data}
            errors={errors}
            loading={pending}
            modified={modified}
            onChange={change}
            onUpdate={update}
            onSubmit={submitDataHandler}
          />
        </WithRights>
      </RowLayoutContent>
      {showConfirm && (
        <PlainConfirmModal
          messageText={cancelMessage}
          onCancel={() => setShowConfirm(null)}
          onConfirm={resetDataHandler}
          confirmButtonTitle={defaultConfirmButtonTitle}
          cancelButtonTitle={defaultCancelButtonTitle}
        />
      )}
      <Prompt when={modified} message="Вы не сохранили изменения, перейти в любом случае?" />
    </RowLayoutWithSticky>
  )
}

export default Requisites
