/* eslint-disable ft-flow/no-types-missing-file-annotation */
import curry from 'ramda/es/curry';
import path from 'ramda/es/path';
import pipe from 'ramda/es/pipe';
import pickAll from 'ramda/es/pickAll';
import split from 'ramda/es/split';
import drop from 'ramda/es/drop';

import { replaceLast, replaceLastN } from 'app/common/lib/path';
import history from 'app/history';

// ALL DEPRICATED, DO NOT USE

export const getOrgId = props => (
  (props.match && props.match.params && props.match.params.orgId) || null
);

export const getType = props => (
  (props.match && props.match.params && props.match.params.type) || null
);

export const getCategoryId = props => (
  (props.match && props.match.params && props.match.params.categoryId) || null
);

export const getParams = props => (
  (props.match && props.match.params) || null
);

export const getPathname = props => (
  (props.location && props.location.pathname) || ''
);

export const getProcess = props => (
  (props.match && props.match.params && props.match.params.process) || null
);

export const getSection = props => (
  (props.match && props.match.params && props.match.params.section) || null
);

export const getId = props => (
  (props.match && props.match.params && props.match.params.id) || null
);

export const getAct = props => (
  (props.match && props.match.params && props.match.params.act) || null
);

export const getDate = props => (
  (props.match && props.match.params && props.match.params.date) || null
);

export const getSellPointId = props => (
  (props.match && props.match.params && props.match.params.sellPointId) || null
);

export const getUrl = props => (
  (props.match && props.match.url) || null
);

export const getKitId = props => (
  (props.match && props.match.params && props.match.params.kitId) || null
);

export const getField = props => (
  (props.match && props.match.params && props.match.params.field) || null
);

const getRouterVars = (names: Array<string>, props: Object) => (
  (props && props.match && props.match.params)
    ? names.map(name => (props.match.params[name] || null))
    : []
);

const getParam = curry((name, props) => path(['match', 'params', name], props));
const getAllParams = curry((arrPrams, props) => pipe(
  path(['match', 'params']),
  pickAll(arrPrams),
)(props));

const router = {
  // возврат на n элементов пути
  locationReplace: (n) => {
    const { location: { pathname } } = history;
    const steps = Math.abs(n);
    if (steps > 1) {
      history.push(replaceLastN(pathname, steps));
      return;
    }
    history.push(replaceLast(pathname));
  },

  getAllParams, // в приоритете использовать это

  getPathname: props => path(
    ['location', 'pathname'],
    typeof props === 'object' ? props : history,
  ),

  getPathnameArr(props) {
    return pipe(
      this.getPathname,
      split('/'),
      drop(1),
    )(props);
  },

  // остальное не нужно и постепенно убрать
  getParam,
  getOrgId: getParam('orgId'),
  getField: getParam('field'),
  getDate: getParam('date'),
};

// Это медленно:
// const router = new Proxy(routerT, {
//   get(target, prop) {
//     if (!target[prop] && prop.match(/^get\S+$/)) {
//       const name = join('', adjust(toLower, 0, prop.replace(/^get(\S+)$/, '$1')));
//       return path(['match', 'params', name]);
//     }
//     return target[prop];
//   },
// });

export { router };
export default getRouterVars;
