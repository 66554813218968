import cn from 'classnames/bind'

import nope from 'app/common/lib/nope'

import styles from './plain-paging-item.scss'


const cx = cn.bind(styles)

type Props = {
  children: number | string,
  value?: number,
  active?: boolean,
  disabled?: boolean,
  onClick?: (value: number) => void
}

const PlainPagingItem = ({ children, value, active, disabled, onClick = nope }: Props) => {
  const handleClick = () => {
    if (typeof value === 'number') {
      onClick(value)
    }
  }

  return (
    <button
      type="button"
      className={cx(styles.root, { active, disabled })}
      disabled={disabled || active}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

export default PlainPagingItem
