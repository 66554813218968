// @flow
import isEmpty from 'ramda/es/isEmpty';
import reduce from 'ramda/es/reduce';

import type { RoleType, PeriodType } from './types';


const validateDays = (daysEntered: ?number) => {
  if (daysEntered || daysEntered === 0) {
    return true;
  }
  return false;
};

type Item = {
  id: number,
  current_period_without_restrictions: boolean,
  current_period_days: ?number,
  past_period_without_restrictions: boolean,
  past_period_days: ?number,
  role_type: RoleType,
}

type Errors = {
  [id: string]: {
    past: string,
    current: string,
  }
}

const errorText = 'Укажите количество дней или выберите «без ограничений»';

const extractOldOrdersRestrictionsErrors = (
  rst: Array<Item>,
  checkRoleType?: RoleType,
  checkPeriod?: PeriodType,
): Errors => {
  const enabledPeriod = (canCheck: PeriodType) => (checkPeriod ? checkPeriod === canCheck : true);
  const enabledRoleType = (canCheck: RoleType) => (checkRoleType ? checkRoleType === canCheck : true);

  return reduce((acc, {
    id,
    current_period_without_restrictions: current,
    current_period_days: currentDays,
    past_period_without_restrictions: past,
    past_period_days: pastDays,
    role_type: roleType,
  }) => {
    if (!enabledRoleType(roleType)) {
      return acc;
    }
    const localAcc = {};
    if (enabledPeriod('current') && !current && !validateDays(currentDays)) {
      localAcc.current = errorText;
    }
    if (enabledPeriod('past') && !past && !validateDays(pastDays)) {
      localAcc.past = errorText;
    }
    if (!isEmpty(localAcc)) {
      acc[String(id)] = localAcc;
    }
    return acc;
  }, {}, rst);
};

export default extractOldOrdersRestrictionsErrors;
