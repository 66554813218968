// @flow
import type { Request } from '../../index';
import type { FoodItem } from '../items';

/**
 *  Действия с полями продукции
 */

export type SaveType = 'supplier' | 'school' | 'single' | 'complex'

export type PhotoNewValue = {
  file: string,
  o?: number,
  w: number,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
}

export type FoodItemFieldRequestParams = {
  id: number,
  field: string,
  new_value: string | null,
  item_types?: Array<SaveType>,
}

export type FoodItemPhotoFieldRequestParams = {
  id: number,
  field: string,
  new_value: PhotoNewValue | null,
}

export type FoodItemFieldRequestResult = {
  result: 'success',
  [id: string]: FoodItem,
}

export const PUT_FOOD_ITEM_FIELD: Request<
  FoodItemFieldRequestParams | FoodItemPhotoFieldRequestParams,
  FoodItemFieldRequestResult
> = [
  'food/item/field',
  'put',
  ['data'],
];
