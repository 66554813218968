export enum CustomerType {
  Employee = 'Employee',
  Student = 'Student'
}

export const isCustomerType = (val: any): val is CustomerType => {
  return Object.values(CustomerType).includes(val)
}

export type Customer = {
  ID: string
  fullName: string
  type: CustomerType | null
  description: string
  phone: string
  accountNumber: string | null
}
