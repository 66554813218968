import pipe from 'ramda/es/pipe'
import values from 'ramda/es/values'
import map from 'ramda/es/map'
import isEmpty from 'ramda/es/isEmpty'
import sum from 'ramda/es/sum'
import prop from 'ramda/es/prop'
import sortWith from 'ramda/es/sortWith'
import lensPath from 'ramda/es/lensPath'
import ascend from 'ramda/es/ascend'
import descend from 'ramda/es/descend'
import view from 'ramda/es/view'

import { type ComplexItem, type DishItem } from 'app/common/api/requests/food/schools/complexes'
import toFixedValue from 'app/common/lib/toFixedValue'


export type Dish = {
  id: number,
  title: string,
  portion: string,
  cost: string,
  index: number,
}

const mapDishes: (items: { [id: string]: DishItem }) => Dish[] = pipe(
  values,
  map(({ id, title, portion, cost, index }: DishItem) => ({
    id,
    title: (title || '').trim(),
    portion: portion || '',
    cost: toFixedValue(cost),
    index,
  })),
  sortWith([
    ascend(prop('index')),
    ascend(prop('title')),
  ]),
)

const calcCost = (isFixed: boolean, fixedPrice?: string, items?: { [id: string]: DishItem }): string => {
  if (isFixed && fixedPrice !== undefined && fixedPrice !== null) {
    return toFixedValue(fixedPrice)
  }
  if (items && !isEmpty(items)) {
    return toFixedValue(
      pipe(
        values,
        (list: DishItem[]) => list.map(item => item.cost),
        sum,
      )(items),
    )
  }
  return '—'
}


export type Complex = {
  key: number,
  id: number,
  newItem: boolean,
  empty: boolean,
  deletable: boolean,
  subsidyOrders: boolean,
  photo: string | null,
  params: {
    id: number,
    title: string,
    dishes: Array<Dish>,
    cost: string,
    grocerySetPrice: string | null,
    fixedPriceEnabled: boolean,
    buffetZone: boolean,
    hotMealsZone: boolean,
    denyParentMoneySales: boolean,
    denyMoreThanOneSales: boolean,
    useAsAdditional: boolean
  }
}

type ComplexesInItem = {
  isNew?: boolean,
  // ...$Exact<ComplexItem>
} & ComplexItem

export type ComplexesIn = {
  [id: string]: ComplexesInItem,
}

export const Kits2ExtendedItems: (ComplexesIn: ComplexesIn) => Array<Complex> = pipe(
  values,
  map(({
    id,
    title,
    fixed_price_enabled: fpEnabled,
    fixed_price: fp,
    grocery_set_price: grocerySetPrice,
    isNew,
    items,
    deletable,
    subsidy_orders: subsidyOrders,
    photo_url: photo,
    buffet_zone: buffetZone,
    hot_meals_zone: hotMealsZone,
    deny_parent_money_sales: denyParentMoneySales,
    deny_more_than_one_sales: denyMoreThanOneSales,
    use_as_additional: useAsAdditional,
  }: ComplexesInItem): Complex => ({
    key: id,
    id,
    newItem: Boolean(isNew),
    empty: isEmpty(items || ''),
    deletable,
    subsidyOrders: Boolean(subsidyOrders && !isEmpty(subsidyOrders)),
    photo,
    params: {
      id,
      title: (title || '').trim(),
      dishes: items && !isEmpty(items) ? mapDishes(items) : [],
      cost: calcCost(fpEnabled, fp, items),
      fixedPriceEnabled: fpEnabled,
      grocerySetPrice: toFixedValue(grocerySetPrice),
      buffetZone,
      hotMealsZone,
      denyParentMoneySales,
      denyMoreThanOneSales,
      useAsAdditional,
    },
  })),
  sortWith([
    descend(prop('newItem')),
    ascend(prop('empty')),
    ascend(view(lensPath(['params', 'title']))),
  ]),
)
