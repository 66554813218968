// @flow
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { kitsFetch, kitsStateClear } from 'app/actions/orgs/kits';
import { kitsNeedUpdate } from 'app/actions/orgs/kits/options';

import { Kits2ExtendedItems, type Complex } from './kits2ExtendedItemsList';
import useKits from '../useKits';

/**
 *  Данные для расширенного отображения комплектов
 */

type Params = {
  date: string,
}

export default function useOrgsKitsExtended(orgId: string, { date }: Params): [Array<Complex>, boolean] {
  const dispatch = useDispatch();
  const { loading, needUpdate } = useSelector(state => state.orgs.kits.options, shallowEqual);

  const kits = useKits();
  const items = useMemo(() => Kits2ExtendedItems(kits), [kits]);

  useEffect(() => {
    if (needUpdate) {
      dispatch(kitsNeedUpdate(false));
    }

    dispatch(kitsFetch(orgId, {
      date,
      deleted: false,
      visible: true,
      include_subsidy_orders: true,
    }, true));
  }, [needUpdate, date, orgId, dispatch]);

  useEffect(() => () => {
    dispatch(kitsStateClear());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [items, loading];
}
