// @flow
import { useCallback } from 'react';
import { map } from 'ramda';
import type { OnDragEndResponder } from 'react-beautiful-dnd';

import { PlanogramDataExt, PlanogramItem } from '../_parts/planogram-data-class';


type Options = {
  data: PlanogramDataExt<PlanogramItem>,
  onChangeData?: (Array<PlanogramItem>) => Promise<void> | void,
  pendingAdd: (val: Array<string>, pause?: number) => void,
  pendingRemove: (val: Array<string>) => void,
  replacedAdd: (Array<PlanogramItem>) => void,
  replacedRemove: (Array<PlanogramItem>) => void,
  rowActiveReset: () => void,
}

export default function useDragEnd({
  data,
  onChangeData,
  pendingAdd,
  pendingRemove,
  replacedAdd,
  replacedRemove,
  rowActiveReset,
}: Options): OnDragEndResponder {
  const handleDragEnd = useCallback(async ({ destination, source }) => {
    rowActiveReset();
    if (!destination || !onChangeData) return;
    const { index: indexDestination, droppableId: targetRowId } = destination;
    const { index: indexSource, droppableId: sourceRowId } = source;
    if (sourceRowId !== targetRowId) return;
    const rowIndex = parseInt(sourceRowId.replace(/\D+/, ''), 10);
    if (Number.isNaN(rowIndex)) return;

    const moved = data.getMoved(indexSource, indexDestination, rowIndex);
    const busyIds = map(({ id }) => id, moved);

    replacedAdd(moved);
    pendingAdd(busyIds);

    await onChangeData(moved);

    pendingRemove(busyIds);
    replacedRemove(moved);
  }, [
    data,
    onChangeData,
    pendingAdd,
    pendingRemove,
    replacedAdd,
    replacedRemove,
    rowActiveReset,
  ]);

  return handleDragEnd;
}
