// @flow
import React, { useContext, useMemo } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import values from 'ramda/es/values';
import length from 'ramda/es/length';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import { DotsLoader } from 'app/common/components/Loader';

import BuildingsContext from './BuildingsContext';


type Props = {
  className?: string,
  title: string,
}

const BuildingsView = ({
  className,
  title,
}: Props) => {
  const {
    buildings,
    selected,
    loading,
    toggleSelector,
  } = useContext(BuildingsContext);

  const itemsNumber = useMemo(() => length(values(buildings)), [buildings]);

  if (itemsNumber < 2) {
    return null;
  }

  return (
    <div className={className}>
      {title}
      &nbsp;
      {loading || !selected || isEmpty(selected)
        ? <DotsLoader simple />
        : <PlainButtonAsLink onClick={toggleSelector}>
          {selected.title}
        </PlainButtonAsLink>}
    </div>
  );
};

export default BuildingsView;
