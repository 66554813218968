// @flow
import React from 'react';
import prop from 'ramda/es/prop';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Picture from 'app/common/ui-next/picture';

import CrossButton from './CrossButton';
import getSrc from './getSrc';
import styles from './FileView.scss';


const limitTextLength = (name: string, length?: number = 50): string => {
  if (name && name.length > length) {
    const match = name.match(/\.[0-9a-z]+$/i);
    if (match && match[0]) {
      return `${name.slice(0, length - 3)}..${match[0]}`;
    }
  }
  return name;
};

type Props = {
  className?: string,
  name: string,
  urlThumb: ?string,
  url: string,
  onCrossClick?: (e: SyntheticEvent<HTMLButtonElement>) => void,
  id?: number | string,
}

const FileView = ({ className, name, urlThumb, url, onCrossClick, id }: Props) => {
  const { t } = useTranslation('statements');

  const format = prop(0, url.match(/\.[0-9a-z]+$/i)) || null;
  const src = getSrc(format, urlThumb);


  return (
    <div className={classNames(styles.root, className)}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
      >
        {typeof src === 'string'
          ? <Picture
            alt={t('attached_file_alt', { name })}
            className={styles.preview}
            src={src}
            width="40px"
            height="40px"
          />
          : src}
        <span className={styles.name}>{limitTextLength(name)}</span>
      </a>

      {onCrossClick
        && <CrossButton id={id} onCrossClick={onCrossClick} />}
    </div>
  );
};

export default FileView;
