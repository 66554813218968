// @flow
import { values, map, filter } from 'ramda';

import {
  defaultStateDataCurrent,
  getStateCurrentValue,
  type State,
  type StateDataItem,
  type StateDataCurrent,
} from 'app/common/ui-components/filter';

import { GET_STUDENTS_LIST } from 'app/common/api/requests/reports/students_list';
import { api } from 'app/api';
import { getLastFirstM } from 'app/common/lib/names';

import orgFilter from '../org';
import classFilter from '../class';
import studentStatusFilter from '../studentStatus';


const studentItems = async (state: State): Promise<[Array<StateDataItem>, StateDataCurrent]> => {
  const orgIds = getStateCurrentValue(orgFilter, state) || [];
  const orgId = parseInt(orgIds[0], 10);

  const classIds = getStateCurrentValue(classFilter, state) || [];
  const classId = classIds[0] ? parseInt(classIds[0], 10) : undefined;

  const [status] = getStateCurrentValue(studentStatusFilter, state) || [];

  const res = await api.request(GET_STUDENTS_LIST, {
    error: 'Не удалось получить данные учеников',
    params: filter(Boolean, {
      school_id: orgId,
      klass_id: classId,
      status,
    }),
  });

  if (res) {
    const items: Array<StateDataItem> = map(({ id, name }) => ({
      id: String(id),
      title: getLastFirstM(name),
    }), values(res));

    return ([items, defaultStateDataCurrent]);
  }
  return [[], defaultStateDataCurrent];
};

export default studentItems;
