// @flow
import { type Theme } from '../_parts/planogram-theme';


export const defaultTheme: Theme = {
  ruleThickness: '22px',
  unitHeight: '138px',
  unitWidth: '190px',
  edgingThickness: '5px',
  colorDanger: '#facdcd',
  colorMiddle: '#fdf3da',
  colorFull: '#cefad0',
  colorNeutral: '#e3e9eb',
  colorButton: '#899fae',
  colorButtonHover: '#5f798b',
  selectionTint: -10,
};

export const defaultThemeEditionMode: Theme = {
  ...defaultTheme,
  unitHeight: '190px',
};
