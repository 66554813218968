// @flow
import isEmpty from 'ramda/es/isEmpty';

import { getStateCurrentValue, type State } from 'app/common/ui-components/filter';

import detailsFilter from 'app/components/common/reports/filters/details';
import sellTypesFilter from 'app/components/common/reports/filters/sellType';


const groupActivity = (state: State) => {
  const detailsIds = getStateCurrentValue(detailsFilter, state);
  const sellTypesIds = getStateCurrentValue(sellTypesFilter, state);

  return (
    !isEmpty(detailsIds)
    && isEmpty(sellTypesIds)
  );
};

export default groupActivity;
