// @flow
import Api, { apiTypes } from 'app/common/api';
import singletonAsync from 'app/common/lib/singletonAsync';


// DEPRECATED
// это не нужно использовать. Оно для совместимости
// Нужно использовать app/api

const api: Api = new Api(apiTypes.API_GENERAL);
api.addHeaders({ 'Argus-School-Id': localStorage.getItem('schoolId') || '' });
const axiosWHeaders = api.commonRequest.bind(api);

export const apiCancelable = {
  request: api.commonRequestCancelable.bind(api),
};
export const apiRequestSingle = singletonAsync(axiosWHeaders);


// + удаление одинаковых запросов подряд
let lastUrl: string = '';
let lastMethod: string = '';

const apiDuplicatesFree = (
  url: string,
  method: string,
  rest?: any,
  removeDuplicates?: boolean,
): Promise<any> => {
  if (removeDuplicates && url === lastUrl && method === lastMethod) {
    return new Promise(resolve => resolve({}));
  }
  lastUrl = url;
  lastMethod = method;
  return axiosWHeaders(url, method, rest);
};

export default apiDuplicatesFree;
