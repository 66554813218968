// @flow
import React from 'react';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import Message from './Message';

const enhance = compose(
  withState('show', 'setShow', false),
  withHandlers({
    toggleMessage: ({ setShow, show }) => (e) => {
      if (e) e.preventDefault();
      setShow(!show);
    },
  }),
);

type Props = {
  text: string,
  children: (Function) => any,
  toggleMessage: (Event) => void,
  show: boolean,
}

const MessageOk = ({ text, children, toggleMessage, show }: Props) => (<span>
  {children(toggleMessage)}
  {show
      && <Message
        text={text}
        buttons={[{
          value: 'OK',
          key: 'ok',
          onClick: toggleMessage,
        }]}
      />}
</span>);

export default enhance(MessageOk);
