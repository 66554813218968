import SettingsGear from 'app/common/components/Settings'
import { compensationStatementsDataKey } from 'app/reducers/settings/compensationStatements'


type Props = {
  className?: string
}

export const OrgsListGear = ({ className }: Props) => (
  <SettingsGear dataKey={compensationStatementsDataKey} className={className} />
)
