// @flow
import React from 'react';
import cn from 'classnames';
import styles from './Table.scss';


type Props = {
  children: any,
  className?: string,
  height?: number,
  width?: number,
};

const HeaderRow = ({
  children,
  className,
  height,
  width,
}: Props) => {
  const style = {
    // $FlowFixMe
    ...(height || height === 0 ? { height } : {}),
    ...(width || width === 0 ? { width } : {}),
  };
  return (
    <div className={cn(styles.headerRow, className)} style={style}>
      {children}
    </div>
  );
};

export default HeaderRow;
