// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const isActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const byUsersSelected = (state: State): boolean => {
  const [groupBy] = getStateCurrentValue(filterId, state) || [];
  return groupBy === 'by_users';
};

export const byGroupsAndTypesSelected = (state: State): boolean => {
  const [groupBy] = getStateCurrentValue(filterId, state) || [];
  return groupBy === 'by_groups_and_types';
};
