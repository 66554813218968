// @flow
import React, { useState, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import ModalExtended from 'app/common/components/ModalExtended';
import toLower from 'ramda/es/toLower';
import find from 'ramda/es/find';
import trim from 'ramda/es/trim';

import DishesGear from 'app/components/Settings/SettingsGear/DishesGear';
import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs';
import { useSaleTypesData } from 'app/dataProviders/SaleTypesData';

import ImportXLS from './ImportXLS';
import AddingForm from './AddingForm';
import AddingDishes from './AddingDishes';
import useParamsWDebouncedTitle from './useParamsWDebouncedTitle';
import useAddingDishes from './useAddingDishes';
import { useDishesData } from '../DishesData';

import styles from './Add.scss';


const titleMinLengthForShowSimilar = 3;
const getEmptyWarn = (entered: number) => {
  if (!entered || entered < titleMinLengthForShowSimilar) {
    return 'Начните вводить наименование';
  }
  return 'Нет похожей продукции';
};

type Props = {
  closePath: string,
  onClose: () => void,
}

const Add = ({
  closePath,
  onClose,
}: Props) => {
  const [{ nomenclatureActive }, { request }] = useDishesData();
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [saleTypes, loadingSaleTypes] = useSaleTypesData();
  const settings = useSelector(state => state.settings.dishes, shallowEqual);
  const { rows: limit } = settings;

  const [
    [nomenclatureDeb, setNomenclature],
    [titleDeb, setTitle],
    [portion, setPortion],
    [cost, setCost],
  ] = useParamsWDebouncedTitle(titleMinLengthForShowSimilar);

  const [{ dishes, total, loading }, paging] = useAddingDishes({
    search: titleDeb,
    limit,
    nomenclature: nomenclatureDeb,
  });

  const duplicateId: number | null = useMemo(() => {
    const nomenclatureToFind = trim(nomenclatureDeb);
    const titleToFind = trim(toLower(titleDeb));
    const portionToFind = trim(toLower(portion));
    const costToFind = parseFloat(trim(cost)) || '';

    const { id = null } = find(({
      title,
      portion: portion_,
      cost: cost_,
      nomenclature: nomenclature_,
    }) => {
      return (
        titleToFind === toLower(title)
        && portionToFind === toLower(portion_)
        && costToFind === (parseFloat(cost_) || '')
      ) || (
        nomenclatureActive
        && nomenclatureToFind === nomenclature_
      );
    }, dishes) || {};

    return id;
  }, [titleDeb, portion, cost, dishes, nomenclatureDeb, nomenclatureActive]);

  const lockedWithError = duplicateId ? 'Продукция с такими параметрами уже существует' : '';
  const emptyWarn = getEmptyWarn(titleDeb.length);


  return (
    <ModalExtended
      closePath={closePath}
      header="Добавление продукции"
      className={styles.modal}
    >
      <Tabs className={styles.tabs} value={activeTabIndex} onChange={setActiveTabIndex}>
        <Tab index={1}>{'Добавить вручную'}</Tab>
        <Tab index={2}>{'Импорт'}</Tab>
      </Tabs>

      <TabPanels value={activeTabIndex} className={styles.panels}>
        <TabPanel
          className={styles.panel}
          activeClassName={styles.paneActive}
          index={1}
        >
          <AddingForm
            saleTypes={saleTypes}
            saleTypesLoading={loadingSaleTypes}
            lockedWithError={lockedWithError}
            onChangeNomenclature={setNomenclature}
            onChangeTitle={setTitle}
            onChangePortion={setPortion}
            onChangeCost={setCost}
            onAddSuccess={request}
          />
          <div className={styles.headerRow}>
            <div className={styles.header}>{'Похожая продукция'}</div>
            <DishesGear disabledSetup hidePhoto />
          </div>
          <AddingDishes
            dishes={dishes}
            total={total}
            paging={paging}
            settings={settings}
            loading={loading}
            emptyWarn={emptyWarn}
          />
        </TabPanel>

        <TabPanel
          className={styles.panel}
          activeClassName={styles.paneActive}
          index={2}
        >
          <ImportXLS
            onClose={onClose}
            onSuccess={request}
          />
        </TabPanel>
      </TabPanels>
    </ModalExtended>
  );
};

export default Add;
