// @flow
import mapProps from 'recompose/mapProps';
import classNames from 'classnames/bind';

import styles from './Circle.scss';


const cx = classNames.bind(styles);

type Props = {
  success?: boolean,
  error?: boolean,
  className?: string,
};

const Circle = mapProps(({ className, success, error }: Props) => ({
  className: cx(styles.root, className, { success, error }),
}))('span');

export default Circle;
