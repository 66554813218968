// @flow
import React from 'react';
import cn from 'classnames/bind';

import { DotsLoader } from 'app/common/components/Loader';

import RenderValue from './RenderValue';
import styles from './SummaryItem.scss';


const cx = cn.bind(styles);

type Props = {
  title: string,
  value: string | Array<string>,
  loading?: boolean,
  className?: string,
  danger?: boolean,
}

const SummaryItem = ({ title, value, loading, className, danger }: Props) => {
  return (
    <div>
      <div className={cx(styles.root, { danger }, className)}>
        <div className={styles.title}>{title}</div>
        {loading
          ? <DotsLoader className={styles.loading} />
          : <RenderValue value={value} />}
      </div>
    </div>
  );
};

export default SummaryItem;
