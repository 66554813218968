import styles from './DualLineCell.scss'


type Props = {
  line1: string
  line2?: string
}

export const DualLineCell = ({ line1, line2 }: Props) => {
  return (
    <div>
      <div className={styles.line}>{line1}</div>
      <div className={styles.line}>{line2}</div>
    </div>
  )
}
