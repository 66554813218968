// @flow
import React, { type Node } from 'react';
import moment from 'moment-timezone';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';

import {
  type HistoryItem as ApiHistoryItem,
  type HistoryResult,
} from 'app/common/api/requests/reports/history';


export type HistoryItem = {
  id: string,
  title: string,
  milliseconds: number,
  date: string,
  options: Node,
  email: string | null,
  status: number,
}

const convertApiHistoryItems = (history: HistoryResult, email: string | null): Array<HistoryItem> => {
  return pipe(
    values,
    map(({ id, title, created_at: createdAt, status }: ApiHistoryItem): HistoryItem => {
      const mCreated = moment(createdAt);

      return ({
        id: String(id),
        title: title || '???',
        milliseconds: mCreated.valueOf(), // для сортировки
        date: mCreated.format('DD.MM.YYYY HH:mm:ss'),
        options: <div />,
        email,
        status,
      });
    }),
    sort(descend(prop('milliseconds'))),
  )(history);
};

export default convertApiHistoryItems;
