// @flow
export const COMMONBALANCE_FILTER_INIT = 'reports/COMMONBALANCE_FILTER_INIT';
export const COMMONBALANCE_FILTER_CLEAR = 'reports/COMMONBALANCE_FILTER_CLEAR';
export const COMMONBALANCE_FILTER_DELETE = 'reports/COMMONBALANCE_FILTER_DELETE';
export const COMMONBALANCE_FILTER_SET_SELECTED = 'reports/COMMONBALANCE_FILTER_SET_SELECTED';
export const COMMONBALANCE_FILTER_SET_RANGE = 'reports/COMMONBALANCE_FILTER_SET_RANGE';


export const BALANCE_ALL = 'def';
export const BALANCE_NEGATIVE = 'neg';
export const BALANCE_ZERO = 'zero';
export const BALANCE_POSITIVE = 'pos';
export const BALANCE_USER = 'user';

type BalanceType = (typeof BALANCE_ALL
  | typeof BALANCE_NEGATIVE
  | typeof BALANCE_ZERO
  | typeof BALANCE_POSITIVE
  | typeof BALANCE_USER)

type Props = {
  balanceFilter: BalanceType,
  balanceMin: number,
  balanceMax: number,
}

export const isBalanceNegative = ({ balanceFilter, balanceMin, balanceMax }: Props) => (
  balanceFilter === BALANCE_NEGATIVE
  || (balanceFilter === BALANCE_USER && balanceMin < 0 && balanceMax < 0)
);

export const isBalancePositive = ({ balanceFilter }: Props) => (
  balanceFilter === BALANCE_POSITIVE
  // || (balanceFilter === BALANCE_USER && balanceMin >= 0 && balanceMax >= 0)
);

export const isBalanceZero = ({ balanceFilter, balanceMin, balanceMax }: Props) => (
  balanceFilter === BALANCE_ZERO
  || (balanceFilter === BALANCE_USER && balanceMin === 0 && balanceMax === 0)
);

export const data = {
  [BALANCE_ALL]: {
    id: BALANCE_ALL,
    title: 'Все',
  },
  [BALANCE_NEGATIVE]: {
    id: BALANCE_NEGATIVE,
    title: 'Отрицательный баланс',
  },
  [BALANCE_ZERO]: {
    id: BALANCE_ZERO,
    title: 'Нулевой баланс',
  },
  [BALANCE_POSITIVE]: {
    id: BALANCE_POSITIVE,
    title: 'Положительный баланс',
  },
  [BALANCE_USER]: {
    id: BALANCE_USER,
    title: 'Свой диапазон',
  },
};

export const commonBalanceFilterInit = (d: Object = data) => ({
  type: COMMONBALANCE_FILTER_INIT,
  payload: d,
});

export const commonBalanceFilterClear = () => ({
  type: COMMONBALANCE_FILTER_CLEAR,
});

export const commonBalanceFilterDelete = (payload: Array<string | number>) => ({
  type: COMMONBALANCE_FILTER_DELETE,
  payload,
});

export const commonBalanceFilterSetSelected = (payload: string) => ({
  type: COMMONBALANCE_FILTER_SET_SELECTED,
  payload,
});

export const commonBalanceFilterSetRange = (payload: Object) => ({
  type: COMMONBALANCE_FILTER_SET_RANGE,
  payload,
});
