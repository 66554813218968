import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { assoc, isEmpty, prop } from 'ramda'
import cn from 'classnames'

import { Accrual } from 'app/dataTypes/subsidy/settings'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import PlainRadioButton from 'app/common/ui-next/plain-radio-button'
import PlainInput from 'app/common/ui-next/plain-input'
import PlainLabel from 'app/common/ui-next/plain-label'
import useComplexes from 'app/dataProviders/subsidy/complexes/hooks/useComplexes'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import Complexes from './Complexes'
import { useLost } from './useLost'
import LostItemsWarning from './LostItemsWarning'
import { controls, selection } from './accrualFormControls'

import styles from './AccrualForm.scss'


export type Errors = Map<string, string>

type Props = {
  ID: string
  orgID: string
  app: 'camp' | 'cafeteria'
  data: Accrual
  formID: string
  disabled?: boolean
  errors: Errors
  resetErrors: () => void
  updateSize?: () => void
}

const AccrualForm = ({
  ID,
  orgID,
  app,
  data,
  formID,
  disabled,
  errors,
  resetErrors,
  updateSize,
}: Props) => {
  const [{ num, active, sum, complexes }, setData] = useState(data)
  const handleActive = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setData(assoc('active', checked))
    resetErrors()
  }, [resetErrors])
  const handleSum = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (/^[0-9]*[.|,]?[0-9]*$/.test(value)) {
      setData(assoc('sum', value))
      resetErrors()
    }
  }, [resetErrors])

  const initialState = {
    selection: !!data.sum || !data.complexes.length ? 'sum' : 'complexes',
    sumForComplexes: !!(data.sum && data.complexes.length),
  }

  const [sumForComplexes, setSumForComplexes] = useState(initialState.sumForComplexes)
  const handleSumForComplexes = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setSumForComplexes(checked)
    resetErrors()
  }, [resetErrors])

  const [select, setSelect] = useState(initialState.selection)
  const handleSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget
    if (checked && (value === 'sum' || value === 'complexes')) {
      setSumForComplexes(false)
      setSelect(value)
      resetErrors()
    }
  }, [resetErrors])

  const [availableComplexes, loadingComplexes] = useComplexes({ orgID, app, allowanceID: ID })
  const [complexesSelected, setComplexesSelected] = useState(new Set(complexes.map(prop('ID'))))

  const errorSum = errors.get(`${controls.sumInput}${num}`)
  const errorComplexes = errors.get(`${controls.complexesList}${num}`)

  const lostItems = useLost({
    loading: loadingComplexes,
    selected: complexes,
    items: availableComplexes,
    onLost: updateSize,
  })

  useEffect(() => {
    setData(data)
    resetErrors()
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateSize?.()
  }, [select, sumForComplexes, errors, availableComplexes]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      <PlainCheckbox
        label="Индивидуальные настройки"
        name={`${controls.activeCheckbox}${num}`}
        value="active"
        checked={active}
        onChange={handleActive}
        disabled={disabled}
        form={formID}
      />

      <div className={cn(styles.columns, getScrollToErrorClassName(!!errorSum))}>
        <div>
          <PlainRadioButton
            label="Сумма"
            name={`${controls.selectionRadio}${num}`}
            disabled={disabled || !active}
            value={selection.sum}
            checked={select === selection.sum}
            onChange={handleSelect}
            form={formID}
          />

          <div className={styles.sum}>
            <PlainLabel
              htmlFor={`${controls.sumInput}${num}`}
              disabled={disabled || !active || select === selection.complexes}
            >
              {'Сумма льгот:'}
            </PlainLabel>
            <PlainInput
              id={`${controls.sumInput}${num}`}
              name={`${controls.sumInput}${num}`}
              disabled={disabled || !active || select === selection.complexes}
              value={sum}
              onChange={handleSum}
              form={formID}
            />
          </div>

          {errorSum
              && <PlainHelperText error>{errorSum}</PlainHelperText>}

          <PlainCheckbox
            className={styles.sumForComplexes}
            label="Начислять сумму только на комплекты"
            name={`${controls.sumForComplexesCheckbox}${num}`}
            value="active"
            checked={sumForComplexes}
            onChange={handleSumForComplexes}
            disabled={disabled || !active || select === selection.complexes}
            form={formID}
          />

          {errorComplexes
            && sumForComplexes
            && <PlainHelperText
              className={getScrollToErrorClassName()}
              error
            >
              {errorComplexes}
            </PlainHelperText>}

          {active
            && sumForComplexes
            && <Complexes
              label="Комплекты на которые начисляются льготы:"
              name={`${controls.complexesList}${num}`}
              items={availableComplexes}
              checked={complexesSelected}
              setChecked={setComplexesSelected}
              loading={loadingComplexes}
              form={formID}
              disabled={disabled}
              hideLabel
            />}

          {active
            && sumForComplexes
            && !isEmpty(lostItems)
            && <LostItemsWarning items={lostItems} />}
        </div>

        <div>
          <PlainRadioButton
            label="Комплекты"
            name={`${controls.selectionRadio}${num}`}
            value={selection.complexes}
            disabled={disabled || !active}
            checked={select === selection.complexes}
            onChange={handleSelect}
            form={formID}
          />

          {errorComplexes
            && select === selection.complexes
            && <PlainHelperText
              className={getScrollToErrorClassName()}
              error
            >
              {errorComplexes}
            </PlainHelperText>}

          {active
            && select === selection.complexes
            && <Complexes
              label="Комплекты на которые начисляются льготы:"
              name={`${controls.complexesList}${num}`}
              items={availableComplexes}
              checked={complexesSelected}
              setChecked={setComplexesSelected}
              loading={loadingComplexes}
              form={formID}
              disabled={disabled}
            />}

          {active
            && select === selection.complexes
            && !isEmpty(lostItems)
            && <LostItemsWarning items={lostItems} />}
        </div>
      </div>
    </div>
  )
}

export default AccrualForm
