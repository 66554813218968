// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import styles from './AddingFormItem.scss';


type Props = {
  id: string,
  children: Node,
  label?: string,
  center?: boolean,
}

const cx = classNames.bind(styles);

const AddingFormItem = ({ id, children, label, center }: Props) => {
  return (
    <div className={styles.root}>
      {label
        ? <label className={cx(styles.label, { center })} htmlFor={id}>{label}</label>
        : <div className={styles.label}>&nbsp;</div>}
      {children}
    </div>
  );
};

export default AddingFormItem;
