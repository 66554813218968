// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import pick from 'ramda/es/pick';
import all from 'ramda/es/all';
import petrovich from 'petrovich';
import qs from 'qs';

import { emailRe, snilsRe } from 'app/common/constants/regex';
import { api } from 'app/api';
import { GET_CHECK_EMPLOYEE_EXISTS, checkEmployeeStatuses } from 'app/common/api/requests/food/check_employee_exists';
import { GET_CHECK_EMPLOYEE_BIRTHDATE } from 'app/common/api/requests/food/check_employee_birthdate';
import { GET_EMPLOYEES } from 'app/common/api/requests/food/employees';
import { getLastFM } from 'app/common/lib/names';
import { validatePhoneByISO } from 'app/common/lib/phone';

import { type UpdateOptionsFunction, type OptionsState } from './useHireFormOptions';


export default function useValidateStep1(options: OptionsState, updateState: UpdateOptionsFunction) {
  const fields = useSelector(state => state.employees.hireform, shallowEqual);
  const isoCode = useSelector(state => state.dealer.country_iso_code, shallowEqual);

  const {
    firstname,
    lastname,
    middlename,
    gender,
    phone,
    email,
    snils,
    birthdate,
  } = fields;

  const { required } = options;

  const validate = useCallback(async () => {
    if (!all(key => Boolean(fields[key]), required)) {
      updateState('errorRequired', 'Заполните обязательные поля');
      return false;
    }

    if (phone && !validatePhoneByISO(isoCode, phone)) {
      updateState('errorPhone', 'Номер телефона введен не полностью');
      return false;
    }

    if (email && !emailRe.test(email)) {
      updateState('errorEmail', 'Email введен неверно');
      return false;
    }

    if (snils && !snilsRe.test(snils)) {
      updateState('errorSnils', 'Номер СНИЛС введен не полностью');
      return false;
    }

    // Далее проверки через api

    // Проверка наличия сотрудника
    const fNameTrimmed = firstname.trim();
    const mNameTrimmed = middlename.trim();
    const lNameTrimmed = lastname.trim();

    let errorWhileValidation = false;

    const checkErrors = (error) => {
      const message = path(['response', 'data', 'message'], error);
      errorWhileValidation = true;
      if (message === 'too late birthdate') {
        updateState('errorBirhdate', 'Сотрудник должен быть старше 18 лет');
        return;
      }
      updateState('errorBirhdate', 'Дата рождения сотрудника неверна');
    };

    if (birthdate) {
      await api.request(GET_CHECK_EMPLOYEE_BIRTHDATE, {
        params: {
          birthdate,
        },
        error: checkErrors,
      });
    }

    if (errorWhileValidation) return false;

    if (fNameTrimmed && mNameTrimmed && lNameTrimmed && gender && birthdate) {
      const existing = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
        params: {
          query_type: 'full',
          firstname: fNameTrimmed,
          middlename: mNameTrimmed,
          lastname: lNameTrimmed,
          gender,
          birthdate,
        },
      });

      if (!existing || !existing.status) {
        updateState('errorUser', 'Не удалось проверить наличие сотрудника в базе. Возможно отсутствует соединение');
        return false;
      }

      if (
        existing.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND
        && existing.status !== checkEmployeeStatuses.EMPLOYEE_OTHER_DEALER
      ) {
        const { result, ...others } = existing;
        updateState('userExist', { ...others });
        return false;
      }
    }

    // проверка телефона
    if (phone) { // введен телефон (он необязательный)
      const phoneBusy = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
        params: {
          query_type: 'phone',
          phone: phone.replace(/\s+/g, ''),
        },
      });

      if (!phoneBusy || !phoneBusy.status) {
        updateState('errorPhone', 'Не удалось проверить номер телефона. Возможно отсутствует соединение');
        return false;
      }

      if (phoneBusy.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND) {
        const { id } = phoneBusy;
        const { result, ...existRest } = phoneBusy;
        if (id) {
          const res = await api.request(GET_EMPLOYEES, { id });
          const one = path(['employees', id], res) || {};
          const { first = '', last = '', middle = '' } = prop('name', one) || {};
          const genderObj = pick(['gender'], one) || {};
          const nameBy = petrovich({ first, last, middle, ...genderObj }, 'instrumental');
          updateState('phoneExist', { ...existRest, name: getLastFM(nameBy) });
          return false;
        }
        updateState('phoneExist', { ...existRest });
        return false;
      }
    }

    // Проверка занятости email
    if (email) { // введен email
      const emailBusy = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
        params: {
          query_type: 'email',
          email: email.trim(),
        },
      });

      if (!emailBusy || !emailBusy.status) {
        updateState('errorEmail', 'Не удалось проверить email. Возможно отсутствует соединение');
        return false;
      }

      if (emailBusy.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND) {
        const { result, ...others } = emailBusy;
        updateState('emailExist', others);
        return false;
      }
    }

    // Проверка занятости snils
    if (snils) {
      const snilsBusy = await api.request(GET_CHECK_EMPLOYEE_EXISTS, {
        params: {
          query_type: 'snils',
          snils: snils.trim(),
        },
        additionalAxiosOptions: {
          paramsSerializer: {
            encode: qs.parse,
            // для местного api пробел должен быть '%20', а не '+'
            serialize: params => qs.stringify(params).replace('+', '%20'),
          },
        },
      });

      if (!snilsBusy || !snilsBusy.status) {
        updateState('errorSnils', 'Не удалось проверить номер СНИЛС. Возможно отсутствует соединение');
        return false;
      }

      if (snilsBusy.status !== checkEmployeeStatuses.EMPLOYEE_NOT_FOUND) {
        const { result, ...others } = snilsBusy;
        updateState('snilsExist', others);
        return false;
      }
    }

    return true;
  }, [
    firstname,
    lastname,
    middlename,
    phone,
    email,
    snils,
    birthdate,
    gender,
    updateState,
    required,
    fields,
    isoCode,
  ]);

  return validate;
}
