// @flow
import { calcPeriodFromStart } from 'app/common/ui-next/plain-date-period';
import { dateFormat } from 'app/common/api/requests/reports/perform';


const dummyItems = [{ id: 'dummy', title: 'dummy' }];

const periodItems = () => {
  const period = calcPeriodFromStart(null, null, 'month', dateFormat);

  return ([dummyItems, period]);
};

export default periodItems;
