// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import path from 'ramda/es/path';

import { employeeformSubmitSuccess } from 'app/actions/employees/employee/employeeform';
import { popup } from 'app/common/actions/popup';
import { api } from 'app/api';
import { PUT_EMPLOYEE } from 'app/common/api/requests/food/employee';
import trimParams from 'app/common/lib/trimParams';

import { type ErrorGroups, type ErrorFields } from './useErrors';
import null2emptyString from './null2emptyString';


type Options = {
  validate: () => Promise<boolean>,
  onChangeSubmittingStatus: (boolean) => void,
  setErrors: (ErrorFields, ErrorGroups) => void,
}

export default function useSubmit({ validate, onChangeSubmittingStatus, setErrors }: Options) {
  const dispatch = useDispatch();
  const form = useSelector(state => state.employees.employee.employeeform, shallowEqual);

  const submit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    onChangeSubmittingStatus(true);
    const formValid = await validate();

    if (formValid) {
      const { photo_url: x, ...others } = form;
      const params = trimParams(others);

      const res = await api.request(PUT_EMPLOYEE, {
        error: (error) => {
          const message = path(['response', 'data', 'message'], error);
          if (message === 'mobile phone number already exists') {
            setErrors({ phone: true }, { contacts: 'Телефон уже используется другим пользователем' });
            return 'Не удалось обновить данные сотрудника. Телефон уже используется другим пользователем';
          }
          if (message === 'unknown mobile phone number format') {
            setErrors({ phone: true }, { contacts: 'Телефон введен неверно' });
            return 'Не удалось обновить данные сотрудника. Телефон введен неверно';
          }
          if (message === 'email address already exists') {
            setErrors({ email: true }, { contacts: 'Email уже используется другим пользователем' });
            return 'Не удалось обновить данные сотрудника. Email уже используется другим пользователем';
          }
          if (message === 'unknown email address format') {
            setErrors({ email: true }, { contacts: 'Email введен неверно' });
            return 'Не удалось обновить данные сотрудника. Email введен неверно';
          }
          if (message === 'snils required') {
            setErrors({ snils: true }, { documents: 'Номер СНИЛС обязателен для заполнения' });
            return 'Не удалось обновить данные сотрудника. Не введен номер СНИЛС';
          }
          if (message === 'unknown snils format') {
            setErrors({ snils: true }, { documents: 'Номер СНИЛС введен неверно' });
            return 'Не удалось обновить данные сотрудника. Номер СНИЛС введен неверно';
          }
          if (message === 'snils already exists') {
            setErrors({ snils: true }, { documents: 'Номер СНИЛС уже используется другим пользователем' });
            return 'Не удалось обновить данные сотрудника. Номер СНИЛС уже используется другим пользователем';
          }

          if (message === 'too late birthdate') {
            setErrors({ birthdate: true }, { general: 'Сотрудник должен быть старше 18 лет' });
            return 'Не удалось обновить данные сотрудника. Сотрудник должен быть старше 18 лет';
          }

          return 'Не удалось обновить данные сотрудника';
        },
        params,
      });

      if (res) {
        dispatch(employeeformSubmitSuccess(null2emptyString(res)));
        dispatch(popup('Данные сотрудника успешно обновлены'));
      }
    }
    onChangeSubmittingStatus(false);
  }, [dispatch, setErrors, form, validate, onChangeSubmittingStatus]);

  return submit;
}
