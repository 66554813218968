// @flow
import React, { type Node, Fragment, useMemo } from 'react';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { Summary as SummaryType } from '../types';


const convertToString = (val: number | void, currency: string) => {
  return (typeof val === 'number'
    ? `${toFixedValue(val)} ${currency}`
    : '—'
  );
};

type Props = {
  summary: SummaryType,
  currency: string,
}

const SummarySpecial = ({ summary, currency }: Props): Node => {
  const rows = useMemo(() => {
    const {
      Всего: totalSum,
      'Краевой бюджет': regionSum,
      ОВЗ: OVZSum,
      'Родительская плата': parentsSum,
      'Федеральный бюджет': federalSum,
    } = summary;

    const totalSumText = convertToString(totalSum, currency);
    const regionSumText = convertToString(regionSum, currency);
    const OVZSumText = convertToString(OVZSum, currency);
    const parentsSumText = convertToString(parentsSum, currency);
    const federalSumText = convertToString(federalSum, currency);

    return [
      // Row 1
      [
        ['Всего', totalSumText],
        ['Родительская плата', parentsSumText],
      ],
      // Row 2
      [
        ['Федеральный бюджет', federalSumText],
        ['Краевой бюджет', regionSumText],
        ['ОВЗ', OVZSumText],
      ],
    ];
  }, [summary, currency]);

  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default SummarySpecial;
