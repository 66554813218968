// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import styles from './ModalHeaders.scss';


type Props = {
  className?: string,
  children: Node,
}

export const ModalHeader = ({ className, children }: Props) => (
  <h1 id="heading" className={classNames(styles.modalHeader, className)}>
    {children}
  </h1>
);

export const ModalHeader2 = ({ className, children }: Props) => (
  <h2 className={classNames(styles.modalHeader2, className)}>
    {children}
  </h2>
);
