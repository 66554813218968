// @flow
import { getStateIsFilterActive, getStateCurrentValue, type State } from 'app/common/ui-components/filter';

import { filterId } from './filterId';
import { sortTypes } from './sortItems';


export const isActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const byClassesSelected = (state: State): boolean => {
  const [groupBy] = getStateCurrentValue(filterId, state) || [];
  return groupBy === sortTypes.BY_CLASSES;
};
