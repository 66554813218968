export const timeCompare = (keyStart: string, keyFinish: string) => {
  const [hours1, minutes1] = keyStart.split(':').map(Number)
  const [hours2, minutes2] = keyFinish.split(':').map(Number)
  const date1 = new Date(0, 0, 0, hours1, minutes1)
  const date2 = new Date(0, 0, 0, hours2, minutes2)

  if (date1 >= date2) {
    return true
  }
  return false
}


export const timeValidate = (value: string): string => {
  const withoutSpaceMask = value.replace(/\s/g, '')
  const hours0 = Number(withoutSpaceMask[0])
  const hours1 = Number(withoutSpaceMask[1])
  const minutes0 = Number(withoutSpaceMask[3])
  let res = withoutSpaceMask
  if (hours0 > 2) {
    res = `${withoutSpaceMask.substring(0, 0)}_${withoutSpaceMask.substring(1)}`
  }
  if (hours0 === 2 && hours1 > 3) {
    res = `${withoutSpaceMask.substring(0, 1)}_${withoutSpaceMask.substring(2)}`
  }
  if (minutes0 > 5) {
    res = `${withoutSpaceMask.substring(0, 3)}_${withoutSpaceMask.substring(4)}`
  }
  return res
}
