import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import { createSelector } from 'reselect';

import { getFIO } from 'app/common/lib/names';
import { employeeFetchName } from 'app/actions/employees/employee';

import { GENERAL_SECTION } from './sections';


const selectFIO = createSelector(
  state => state.employees.employee.fields.name,
  getFIO,
);

const enhanceOneEmployee = compose(
  connect(
    state => ({
      title: selectFIO(state),
    }),
  ),

  lifecycle({
    componentDidMount() {
      const { dispatch, id, section } = this.props;
      if (section === GENERAL_SECTION) return;
      dispatch(employeeFetchName(id));
    },
  }),
);

export default enhanceOneEmployee;
