import cn from 'classnames/bind'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import Sticky from 'app/common/templates-next/sticky'

import styles from './Header.scss'


const cx = cn.bind(styles)

type Props = {
  showRecordButton: boolean
  showCancelButton: boolean
  onSelectionMode: (mode: 'record' | 'cancel') => void
  disabled: boolean
}

export const Header = ({ showRecordButton, showCancelButton, onSelectionMode, disabled }: Props) => {
  return (
    <Sticky resetShift>
      {(ref, stuck) => (
        <div className={cx(styles.root, { stuck })} ref={ref}>
          {showRecordButton
            && <PlainButtonAsLink
              className={styles.item}
              onClick={() => onSelectionMode('record')}
              disabled={disabled}
            >
              {'Выбрать заведения для фиксации'}
            </PlainButtonAsLink>}

          {showCancelButton
            && <PlainButtonAsLink
              className={styles.item}
              onClick={() => onSelectionMode('cancel')}
              disabled={disabled}
            >
              {'Выбрать заведения для удаления фиксации'}
            </PlainButtonAsLink>}

          {!showRecordButton
            && !showCancelButton
            && <span>&nbsp;</span>}
        </div>
      )}
    </Sticky>
  )
}
