// @flow
import React from 'react';
import { iconTypes } from './icon-types';

import * as icons from './svg';


type Props = {
  type: $Values<typeof iconTypes>,
  className?: string,
  width?: number,
  height?: number,
}

const IconSelector = ({ type, ...others }: Props) => {
  if (icons[type]) {
    const Component = icons[type];
    return <Component {...others} />;
  }

  return '👻';
};

export default IconSelector;
