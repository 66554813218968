// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconCheck from 'app/common/icons/IconCheck';
import styles from './OfferItem.scss';

type OfferType = {
  id: number,
  title: string,
  type: string,
  editedAt: string,
  acceptedAt: string,
  changes: string,
  url: string,
};

type Props = {
  offer: OfferType,
  onClick: (e: SyntheticEvent<HTMLElement>) => void,
  className: string,
};

const OfferItem = ({ offer, onClick, className }: Props) => {
  const { t } = useTranslation('about');

  return (
    <div className={className}>
      <div>
        <a href="showoffer" name={offer.id} onClick={onClick}>{offer.title}</a>
        &nbsp;&nbsp;&nbsp;
        {offer.editedAt && <span className={styles.small}>
          {t('edition_from', { editedAt: offer.editedAt })}
        </span>}
      </div>
      {offer.acceptedAt
        && <div>
          <IconCheck className={styles.check} />
          {' '}
          <span className={styles.small}>
            {t('document_accepted', { acceptedAt: offer.acceptedAt })}
          </span>
        </div>}
    </div>
  );
};

export default OfferItem;
