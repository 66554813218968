// @flow
import React, { Fragment, useState, type Node, useCallback } from 'react';

import Modal, { ModalHeader } from 'app/common/components/Modal';

import NewEditKitForm from './NewEditKitForm';
import styles from './NewEditKit.scss';


type Props = {
  kitId?: number, // если не указан, создается новый комплект
  orgId: number,
  children?: (toggle: () => void) => Node,
}

const NewEditKit = ({
  kitId,
  orgId,
  children,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = useCallback(() => {
    setModalVisible(appear_ => !appear_);
  }, []);

  if (!children) {
    return null;
  }

  return (
    <Fragment>
      {children(toggleModal)}
      {modalVisible
        && <Modal toggleModal={toggleModal} className={styles.root}>
          <ModalHeader className={styles.header}>
            {!kitId
              ? 'Создание комплекта'
              : 'Редактирование названия и стоимости комплекта'}
          </ModalHeader>

          <NewEditKitForm
            kitId={kitId}
            orgId={orgId}
            toggleModal={toggleModal}
          />
        </Modal>}
    </Fragment>
  );
};

export default NewEditKit;
