import PlainLinkAsButton from 'app/common/ui-next/plain-link-as-button'
import ControlsBar from 'app/common/ui-next/controls-bar'
import { ModalExtendedWSearch } from 'app/common/components/ModalExtended'
import EditList, { withFlagsSelected } from 'app/common/components/EditList'
import withOrgsDishes from 'app/containers/OrgsList/withOrgsDishes'
import PlainTable from 'app/common/components/PlainTable'
import withDishEditFieldsActions from 'app/containers/OrgsList/withDishEditFieldsActions'
import Dish, { DishesTableHeader } from 'app/components/common/Dish'
import { WithRightsForOrg } from 'app/rights/RightsForOrg'
import * as rootRights from 'app/common/constants/rights/rootRights'
import * as specificRights from 'app/common/constants/rights/specificRights'

import withDishesItemsAndSubmit from './withDishesItemsAndSubmit'
import withExistingItems from './withExistingItems'
// import { writeConsist, omitExtra } from './rights';
import { ValidityByDate } from '../../../commonComponents/ValidityByDate'

import styles from './FooterContent.scss'


const complexesRigths = [rootRights.COMPLEXES, specificRights.COMPLEXES] as const
const complexesCompositionRigths = [rootRights.COMPLEXES, specificRights.COMPLEXES_COMPOSITION] as const

// @ts-ignore
const LocalEditList = withDishesItemsAndSubmit(EditList)
// @ts-ignore
const DishesTable = withOrgsDishes(withFlagsSelected(PlainTable))
const LocalDishesTable = withExistingItems(DishesTable)
const LocalDish = withDishEditFieldsActions(Dish)


type Props = {
  id: number,
  title: string,
  orgId: number,
  date: string,
  loading: boolean,
  basePath: string,
  activeAction: string | null,
}

const FooterContent = ({
  id,
  title,
  orgId,
  date,
  loading,
  basePath,
  activeAction,
}: Props) => {
  const returnPath = `${basePath}/current`

  return (
    <div className={styles.root}>
      <ControlsBar right>
        <WithRightsForOrg pathTo={complexesRigths} editAffect="hide">
          <WithRightsForOrg pathTo={complexesCompositionRigths} editAffect="hide">
            <ValidityByDate affect="hide">
              <PlainLinkAsButton
                disabled={loading}
                to={`${basePath}/addremove/${id}`}
              >
                {'Добавить продукцию'}
              </PlainLinkAsButton>
            </ValidityByDate>
          </WithRightsForOrg>
        </WithRightsForOrg>
      </ControlsBar>

      {activeAction === 'addremove'
        && <WithRightsForOrg pathTo={complexesRigths} editAffect="hide">
          <WithRightsForOrg pathTo={complexesCompositionRigths} editAffect="hide">
            <ValidityByDate affect="hide">
              <ModalExtendedWSearch
                closePath={returnPath}
                className={styles.addremove}
                header={`Редактирование комплекта — ${title}`}
              >
                {searchString => (
                  <LocalEditList
                    // @ts-ignore
                    orgId={orgId}
                    kitId={id}
                    date={date}
                    closePath={returnPath}
                    submitBtTitle="Сохранить в комплект"
                    alreadyLegendLabel="Продукция уже в комплекте"
                    selectedLegendLabel="Продукция будет добавлена"
                    tooltipText="Необходимо выбрать/удалить хотя бы одну продукцию"
                  >
                    {(
                      addList,
                      removeList,
                      addToSelection,
                      removeFromSelection,
                      addToSelectionAll,
                      removeFromSelectionAll,
                      selectedAll,
                    ) => (<LocalDishesTable
                      orgId={orgId}
                      kitId={id}
                      header={DishesTableHeader}
                      searchString={searchString}
                      addList={addList}
                      removeList={removeList}
                      emptyWarn="Нет продукции, удовлетворяющей условиям"
                      selectedAll={selectedAll}
                      onSelectAll={addToSelectionAll}
                      onUnselectAll={removeFromSelectionAll}
                    >
                      {(itemProps, isLoading) => (<LocalDish
                        {...itemProps}
                        loading={isLoading}
                        dishesAddToSelection={addToSelection}
                        dishesRemoveFromSelection={removeFromSelection}
                      />)}
                    </LocalDishesTable>)}
                  </LocalEditList>)}
              </ModalExtendedWSearch>
            </ValidityByDate>
          </WithRightsForOrg>
        </WithRightsForOrg>}
    </div>
  )
}

export default FooterContent
