import { NavLink } from 'app/common/lib/link';
import classNames from 'classnames/bind';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';

import defaultProps from 'recompose/defaultProps';
import componentFromProp from 'recompose/componentFromProp';
import nope from 'app/common/lib/nope';
import styles from './NavItem.scss';


const cx = classNames.bind(styles);

const enhance = compose(
  // mapProps(props => console.log(props) || props),
  defaultProps({ component: NavLink }),
  mapProps(({ active, activeClassName, className, disabled, ...props }) => ({
    ...props,
    activeClassName: activeClassName || styles.active,
    className: cx(styles.link, className, { active, disabled }),
    ...(disabled ? { onClick: nope } : {}),
  })),
);

const NavItem = enhance(componentFromProp('component'));

export default NavItem;
