// @flow
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'ramda/es/map';

import Loader from 'app/common/components/Loader';
import { ModalContent } from 'app/common/components/Modal';
import { identifiers as identifiersTypesNames, identifiersNames } from 'app/common/constants/identifiers';

import EnteringIdentifier, { type CheckResult } from './entering-identifier';
import Availability, { type AvailabilityType } from './availability';
import { type IdentifierTypeInfo } from './entering-identifier/select-type-form';
import { type IdentifierFormat } from './entering-identifier/entering-form';

import styles from './adding-identifier.scss';


type Props = {
  loading: boolean,
  types: Array<IdentifierTypeInfo>,
  availableFormats: Array<IdentifierFormat>,
  personId: number,
  onCancel: () => void,
  onIdentifierCheck?: (personId: number, identNumber: string) => Promise<CheckResult>,
  // next one - deprecated
  onIdentifierSubmit?: (personId: number, {
    type: $Keys<typeof identifiersTypesNames>,
    number: string,
  }) => Promise<boolean>,
};

const AddingIdentifier = ({
  loading,
  types,
  availableFormats,
  personId,
  onCancel,
  onIdentifierCheck,
  onIdentifierSubmit,
}: Props) => {
  const { t } = useTranslation('identifiers-next');

  const availabilityTypes: Array<AvailabilityType> = useMemo(() => map(({ id, ...props }) => ({
    ...props,
    id,
    title: identifiersNames[id],
  }), types), [types]);

  return (
    <div className={styles.root}>
      <ModalContent>
        <div className={styles.desc}>
          {t('identifier_adding_available_list_label')}
        </div>
        {loading
          ? <Loader className={styles.loader} />
          : <Availability types={availabilityTypes} />}
        <div className={styles.desc}>
          {t('identifier_adding_insufficient_funds_comment')}
        </div>
      </ModalContent>

      {!loading
        && <EnteringIdentifier
          formId="adding"
          types={types}
          availableFormats={availableFormats}
          personId={personId}
          onCancel={onCancel}
          onIdentifierCheck={onIdentifierCheck}
          onIdentifierSubmit={onIdentifierSubmit}
        />}
    </div>
  );
};

export default AddingIdentifier;
