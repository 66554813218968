import { useCallback, useEffect, useRef, useState } from 'react'

import DefaultProgressModal from 'app/common/ui-components/progress-indicator/default-progress-modal'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


const progressError = (p: number) => (p < 0)
const progressContinues = (p: number) => (p < 100 && p >= 0)
const progressFinished = (p: number) => (p === 100)
const progressValid = (p: any): p is number => (
  typeof p === 'number'
  && !Number.isNaN(p)
  && p >= 0
  && p <= 100
)
const errorDefault = -1

type Props = {
  reportID: string
  interval?: number
  onCheckStatus?: () => Promise<number | null>
  onCancel?: () => void
  onStopProgress?: () => void
  onSuccessProgress?: () => void
}

const RenderReportIndicator = ({
  reportID,
  interval = 3000,
  onCheckStatus,
  onCancel,
  onStopProgress,
  onSuccessProgress,
}: Props) => {
  const timeoutID = useRef<null | number>(null)
  const [progress, setProgress] = useState(0)
  const continues = useRef(() => progressContinues(progress))

  const _onCheckStatus = useCallbackRef(onCheckStatus)
  const _onSuccessProgress = useCallbackRef(onSuccessProgress)
  const checkStatus = useCallback(async () => {
    const res = await _onCheckStatus.current?.()
    if (!progressValid(res)) {
      setProgress(errorDefault)
      return
    }
    setProgress(res)
    if (progressContinues(res)) {
      timeoutID.current = setTimeout(checkStatus, interval) as unknown as number
    }
    if (progressFinished(res)) {
      _onSuccessProgress.current?.()
    }
  }, [_onCheckStatus, interval, _onSuccessProgress])

  const _onStopProgress = useCallbackRef(onStopProgress)
  const _onCancel = useCallbackRef(onCancel)
  const handleCancel = useCallback(() => {
    if (continues.current?.()) {
      _onStopProgress.current?.()
    }
    _onCancel.current?.()
  }, [continues, _onStopProgress, _onCancel])

  useEffect(() => {
    if (!onCheckStatus || !onCancel || !onStopProgress || !onSuccessProgress) {
      console.warn('👻 Индикатор статуса <Render /> может работать некорректно, так как ему не переданы все обработчики событий')
    }
    timeoutID.current = setTimeout(checkStatus, interval) as unknown as number
    return () => {
      if (onStopProgress && progressContinues(progress)) {
        onStopProgress()
      }
      setProgress(0)
      if (timeoutID.current) {
        clearTimeout(timeoutID.current)
        timeoutID.current = null
      }
    }
  }, [reportID]) // eslint-disable-line react-hooks/exhaustive-deps

  if (progressFinished(progress)) {
    return null
  }

  return (
    <DefaultProgressModal
      progress={progress}
      isError={progressError(progress)}
      onCancel={handleCancel}
      message={`Подождите,\nидет формирование отчета №${reportID}`}
      messageError={`Ошибка\nпри формировании отчета №${reportID}`}
    />
  )
}

export default RenderReportIndicator
