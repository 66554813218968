// @flow
import React, { Fragment, type Node } from 'react';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { DefaultProgressModal, getProcessMessages } from 'app/common/ui-components/progress-indicator';

import enhanceGenerate from './enhanceGenerate';


type Props = {
  className?: string,
  handleGenerateStart: (e: SyntheticEvent<HTMLElement>) => void,
  handleGenerateStop: (e: SyntheticEvent<HTMLElement>) => void,
  isOpen: boolean,
  progress: number,
  loading: boolean,
  isError: boolean,
  disabled?: boolean,
  reportId: number,
  extraContent?: Node,
}

const Generate = ({
  className,
  isOpen,
  handleGenerateStart,
  handleGenerateStop,
  progress,
  loading,
  isError,
  disabled,
  reportId,
  extraContent,
}: Props) => {
  const [message, messageError] = getProcessMessages(reportId);

  return (
    <Fragment>
      <ControlsBar right className={className}>
        <div>{extraContent}</div>
        <PlainButton
          onClick={handleGenerateStart}
          disabled={loading || disabled}
        >
          {loading ? 'Подождите...' : 'Сформировать'}
        </PlainButton>
      </ControlsBar>

      {isOpen
        && <DefaultProgressModal
          progress={progress}
          isError={isError}
          // $FlowFixMe
          onCancel={handleGenerateStop}
          message={message}
          messageError={messageError}
        />}
    </Fragment>
  );
};

export default enhanceGenerate(Generate);
