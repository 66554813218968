// @flow
import type { Request } from '../../index';


export type Role = {
  id: number,
  title: string,
  available_for_part_time_job: boolean,
  for_all_schools: boolean,
  school_id: Array<number>,
}

export type Roles = {
  [id: string]: Role,
}

export type GetRolesResult = {
  result: 'success',
  count: number,
  roles: Roles,
}

export const GET_ROLES: Request<
  void,
  GetRolesResult,
  void,
> = [
  'food/roles',
  'get',
  ['data'],
];
