import { useCallback, useEffect, useState } from 'react'

import { api } from 'app/api'
import { GET_ORGS, convertParams, convertResult } from 'app/common/api/requests/food/schools/get'
import { OrgsData, Params } from 'app/dataTypes/org'


export type Options<T> = {
  transform: (data: OrgsData) => T
  defaultState: T
  params?: Params
  replaceBy?: T
  autoload?: boolean
}

export default function useRequestOrgs<T>(options: Options<T>) {
  const {
    defaultState,
    transform,
    params: globalParams = {},
    replaceBy,
    autoload = true,
  } = options

  const {
    limit,
    offset,
    ID,
    orgType,
    search,
    deleted,
    cityIDs,
    districtIDs,
    hasTodayMenu,
    orgGroupID,
  } = globalParams

  const [loading, setLoading] = useState(true)

  const [orgs, setOrgs] = useState<T>(defaultState)

  const request = useCallback(async (params: Params = {}) => {
    setLoading(true)
    const res = await api.request(GET_ORGS, {
      error: 'Не удалось получить данные заведений',
      params: {
        limit,
        offset,
        ID,
        orgType,
        search,
        deleted,
        cityIDs,
        districtIDs,
        hasTodayMenu,
        orgGroupID,
        ...params,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })
    setLoading(false)

    if (res) {
      setOrgs(transform(res))
    }
  }, [
    transform,
    limit,
    offset,
    ID,
    orgType,
    search,
    deleted,
    cityIDs,
    districtIDs,
    hasTodayMenu,
    orgGroupID,
  ])

  useEffect(() => {
    if (!autoload) return
    if (replaceBy) {
      setLoading(false)
      return
    }
    request()
  }, [request, replaceBy, autoload])

  return {
    orgs: replaceBy || orgs,
    loading,
    request,
  }
}
