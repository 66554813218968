// import map from 'ramda/es/map';
// import hasIn from 'ramda/es/hasIn';
// import omit from 'ramda/es/omit';
// import { USER_RIGHTS_SUCCESS, USER_RIGHTS_GRANT_SU } from 'app/actions/userRights';
import mergeDeepRight from 'ramda/es/mergeDeepRight';
import { USER_RIGHTS_SUCCESS } from 'app/actions/userRights';


// !!! Эти права влияют только на employees
// Для пользователей типа user доступны все разделы

const defaultState = {
  expired: 0,
  Меню: {
    Меню: {
      read: true,
      edit: false,
    },
  },
  Блюда: {
    'Категории блюд': {
      read: true,
      edit: false,
    },
    'Блюда школы': {
      read: true,
      edit: false,
    },
    'Блюда поставщика': {
      read: true,
      edit: false,
    },
  },
  Комплекты: {
    Комплекты: {
      read: true,
      edit: false,
    },
    'Состав комплектов': {
      read: true,
      edit: false,
    },
  },
  Заявки: {
    Заявки: {
      read: true,
      edit: false,
    },
  },
  Продажи: {
    Продажи: {
      read: true,
      edit: false,
    },
  },
  Пользователи: {
    Учащиеся: {
      read: true,
      edit: false,
    },
    'Мои сотрудники': {
      read: true,
      edit: false,
    },
    'Сотрудники ОУ': {
      read: true,
      edit: false,
    },
  },
  Настройки: {
    Устройства: {
      read: true,
      edit: false,
    },
    Сеть: {
      read: true,
      edit: false,
    },
    Система: {
      read: true,
      edit: false,
    },
    'Командная строка': {
      read: true,
      edit: false,
    },
    'Категории меню': {
      read: true,
      edit: false,
    },
    Реквизиты: {
      read: true,
      edit: false,
    },
    'Настройки ОУ': {
      read: true,
      edit: false,
    },
  },
};

// const makeSU = state => map(obj => (
//   hasIn('read', obj) ? { read: true, edit: true } : makeSU(obj)
// ), state);

// const makeSU = state => state;

// const su = makeSU(omit(['expired'], defaultState));

const userRights = (state = defaultState, { type, payload }) => {
  if (type === USER_RIGHTS_SUCCESS) {
    return mergeDeepRight(state, payload);
  }
  // if (type === USER_RIGHTS_GRANT_SU) {
  //   return ({ ...state, ...su });
  // }
  return state;
};

export default userRights;
