import { ReactNode } from 'react'

import RightsContext from './RightsContext'
import useRights from './useRights'


type Props = {
  children: ReactNode
}

const RightsProvider = ({ children }: Props) => {
  const value = useRights()

  return (
    <RightsContext.Provider value={value}>
      {children}
    </RightsContext.Provider>
  )
}

export default RightsProvider
