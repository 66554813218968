import { Column } from './types'


export const headerProps = {
  [Column.Date]: { alignLeft: true },
  [Column.Sum]: { alignLeft: true },
  [Column.Type]: { alignLeft: true },
  [Column.Comment]: { alignLeft: true },
}

export function useHeaderColumnProps() {
  return headerProps
}
