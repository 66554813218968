// @flow
import moment from 'moment-timezone';

import toFixedValue from 'app/common/lib/toFixedValue';

import type { Daily, Monthly, DailyTotals, MonthlyTotals } from './types';


type Data =
  | Daily
  | Monthly
  | DailyTotals
  | MonthlyTotals

// Форматирование сумм
export const sumToFixedValue = (data: Data) => toFixedValue(data.sum);

// Преобразование заголовков для дневного периода
export const headerDaily = (date: string) => moment(date).format('DD.MM');

// Преобразование дат для по-месячного периода
export const headerMonthly = (month: number, year: number) => moment({ month: month - 1, year }).format('MMMM YYYY');

export const headerByTimeMode = (data: Data): string => {
  if (data.date) {
    return headerDaily(data.date);
  }
  if (typeof data.month === 'number' && typeof data.year === 'number') {
    return headerMonthly(data.month, data.year);
  }
  return '???';
};
