import { useMemo } from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames/bind'
import splitEvery from 'ramda/es/splitEvery'

import getDaysOfMonth, { type DayItem } from './helpers/get-days-of-month'
import DaysGridItem from './days-grid-item'
import type { DaysState } from './use-days-slected-state'

import styles from './days-grid.scss'


const cx = classNames.bind(styles)
const daysPerWeek = 7

type Props = {
  className?: string,
  daysSelected: DaysState,
  monthDate: string,
  dateFormat: string,
  markedDatesFrom?: string,
  markedDatesTo?: string,
  weekSelection?: boolean,
  onClickDay: (dayFarmatted: string, format: string) => void,
  dateDisabledStatus?: (dayFarmatted: string, format: string) => boolean,
}

const DaysGrid = ({
  className,
  daysSelected,
  monthDate,
  dateFormat,
  markedDatesFrom,
  markedDatesTo,
  weekSelection,
  onClickDay,
  dateDisabledStatus,
}: Props) => {
  const monthdays: Array<Array<DayItem>> = useMemo(() => {
    return splitEvery(daysPerWeek, getDaysOfMonth(monthDate, dateFormat))
  }, [monthDate, dateFormat])

  const marks: Array<Array<boolean>> = useMemo(() => {
    const mMarkedStart = markedDatesFrom && moment(markedDatesFrom, dateFormat)
    const mMarkedFin = markedDatesTo && moment(markedDatesTo, dateFormat)
    const hasStartDate = mMarkedStart && mMarkedStart.isValid()
    const hasFinDate = mMarkedFin && mMarkedFin.isValid()

    return monthdays.map(week => week.map(({ dateTimeFormatted }) => {
      if (hasStartDate && hasFinDate) {
        return moment(dateTimeFormatted, dateFormat).isBetween(mMarkedStart, mMarkedFin, 'day', '[]')
      }
      if (hasFinDate) {
        return moment(dateTimeFormatted, dateFormat).isSameOrBefore(mMarkedFin, 'day')
      }
      if (hasStartDate) {
        return moment(dateTimeFormatted, dateFormat).isSameOrAfter(mMarkedStart, 'day')
      }
      return false
    }))
  }, [markedDatesFrom, markedDatesTo, dateFormat, monthdays])


  return (
    <div className={cx(styles.root, className)}>

      {monthdays.map((week, weekIndex) => {
        const weekSelected = (
          weekSelection
          && week.find(({ dateTimeFormatted }) => !!daysSelected[dateTimeFormatted], week)
        )

        return (
          <div
            className={cx(styles.week, { weekSelected })}
            key={weekIndex} // eslint-disable-line react/no-array-index-key
          >
            {week.map(({ day, dateTimeFormatted, today, weekday, outrange }, dayIndex) => (
              <DaysGridItem
                key={dateTimeFormatted}
                day={day}
                dateTimeFormatted={dateTimeFormatted}
                today={today}
                holyday={weekday === 0}
                selected={!!daysSelected[dateTimeFormatted]}
                outrange={outrange}
                disabled={
                  dateDisabledStatus
                    ? dateDisabledStatus(dateTimeFormatted, dateFormat)
                    : false
                }
                dateFormat={dateFormat}
                onClick={onClickDay}
                mark={marks[weekIndex][dayIndex]}
              />
            ))}
          </div>
        )
      })}

    </div>
  )
}

export default DaysGrid
