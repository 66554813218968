import { useCallback, useState } from 'react'
import { filter } from 'ramda'

import { type CopyMenuJob, POST_COPY_MENU_JOBS } from 'app/common/api/requests/food/menu/copy_menu_between_schools'
import usePopup from 'app/common/hooks/usePopup'
import { api } from 'app/api'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import { Job } from './useJobs'


// TODO move to api
const convertID = (id: string | undefined) => {
  return id ? parseInt(id, 10) : undefined
}

// TODO move to api
const convertJobs = (jobs: Array<Job>): Array<CopyMenuJob> => {
  return jobs.map(({
    orgIDFrom,
    orgIDTo,
    dateFrom,
    dateTo,
    buildingIDFrom,
    buildingIDTo,
    salePointIDFrom,
    salePointIDTo,
    items,
    complexes,
    copyComplexPhoto,
    copySettings,
    contentOnly,
    changeSoldItems,
    keepComplexPrice,
    complexesIDs,
    sourceComplexID,
    targetComplexID,
    copyExtendedSettings,
    keepGrocerySetPrice,
  }) => filter(val => (val !== undefined), {
    school_id_from: convertID(orgIDFrom),
    school_id_to: convertID(orgIDTo),
    date_from: dateFrom,
    date_to: dateTo,
    from_building_id: convertID(buildingIDFrom),
    to_building_id: convertID(buildingIDTo),
    from_sale_point_id: convertID(salePointIDFrom),
    to_sale_point_id: convertID(salePointIDTo),
    items,
    complexes,
    copy_complex_photo: copyComplexPhoto,
    copy_settings: copySettings,
    content_only: contentOnly,
    change_sold_items: changeSoldItems,
    keep_complex_price: keepComplexPrice,
    complexes_ids: complexesIDs ? complexesIDs.map(n => parseInt(n, 10)) : undefined,
    source_complex_id: convertID(sourceComplexID),
    target_complex_id: convertID(targetComplexID),
    copy_extended_settings: copyExtendedSettings,
    keep_grocery_set_price: keepGrocerySetPrice,
  }))
}

type Options = {
  email?: string
  onSuccess?: () => void
  onValidate?: () => boolean
  failMessage?: string
  successMessage?: string
}

export default function useJobsSubmit(jobs: Array<Job>, {
  email,
  onSuccess,
  onValidate,
  failMessage = 'Не удалось скопировать комплект',
  successMessage = 'Комплект был успешно скопирован',
}: Options): [() => Promise<boolean>, boolean] {
  const popup = usePopup()
  const [submitting, setSubmitting] = useState(false)

  const onSuccessRef = useCallbackRef(onSuccess)
  const onValidateRef = useCallbackRef(onValidate)

  const submit = useCallback(async () => {
    if (onValidateRef.current && !onValidateRef.current()) return false
    setSubmitting(true)

    const res = await api.request(POST_COPY_MENU_JOBS, {
      error: failMessage,
      params: {
        jobs: convertJobs(jobs),
        ...(email ? { email } : {}),
      },
    })

    if (res) {
      popup(successMessage)
      onSuccessRef.current?.()
    }

    setSubmitting(false)
    return false
  }, [
    jobs,
    email,
    onSuccessRef,
    popup,
    failMessage,
    successMessage,
    onValidateRef,
  ])

  return [submit, submitting]
}
