// @flow
import { useMemo } from 'react';
import path from 'ramda/es/path';
import filter from 'ramda/es/filter';

import { useFilterData } from 'app/common/ui-components/filter';
import { type GetEmployeesRequest } from 'app/common/api/requests/food/employees';

import { filterIds } from './EmployeesAtWorkFilter/filterItems';


const toInt = (val: string) => parseInt(val, 10);

const clearParams = filter((val) => {
  const type = typeof val;
  return (type === 'number' || type === 'string');
});

type AdditionalParams = {
  limit: number,
  offset: number,
  search: string,
  loading: boolean,
}

export default function useEmployeesDataParams(additionalParams: AdditionalParams): ?GetEmployeesRequest {
  const [{ applied: filterApplied }] = useFilterData();
  const { limit, offset, search, workplace, loading } = additionalParams;

  const result = useMemo(() => {
    if (loading) return null;
    const additional = { limit, offset, search, workplace };

    const params: GetEmployeesRequest = clearParams(
      filterApplied
        ? {
          ...additional,
          role_id: toInt(path([filterIds.FILTER_BY_ROLE, 0], filterApplied)) || null,
          school_id: toInt(path([filterIds.FILTER_BY_JOB_PLACE, 0], filterApplied)) || null,
        }
        : additional,
    );
    return params;
  }, [filterApplied, limit, offset, search, workplace, loading]);

  return result;
}
