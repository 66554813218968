// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import mapProps from 'recompose/mapProps';

import styles from './Table.scss';


const enhance = mapProps(({ sortKey, currentSortKey, currentSortDirection, ...props }) => ({
  ...props,
  sortKey,
  ...(currentSortKey === sortKey ? {
    sortedUp: currentSortDirection === 'up',
    sortedDown: currentSortDirection === 'down',
  } : {}),
}));

const cx = classNames.bind(styles);

type Props = {
  grow?: number,
  width?: number,
  children: any,
  className?: string,
  alignRight?: boolean,
  alignMiddle?: boolean,
  handleSort?: (e: SyntheticEvent<HTMLElement>) => void,
  sortKey?: string,
  sortedUp?: boolean,
  sortedDown?: boolean,
  hovered?: boolean,
};

const HeaderCell = ({
  children,
  grow,
  width,
  className,
  alignRight,
  alignMiddle,
  handleSort,
  sortKey,
  sortedUp,
  sortedDown,
  hovered,
  ...others
}: Props) => {
  const style = {
    // $FlowFixMe
    ...(grow ? { flexGrow: grow } : {}),
    ...(width ? { flexBasis: width } : {}),
  };
  return (
    <div
      className={cx({
        headerCell: true,
        alignRight,
        alignMiddle,
        hovered,
      }, className)}
      style={style}
      // $FlowFixMe
      {...others}
    >
      {children}
      {handleSort
        && sortKey
        && <a href="sortby" name={sortKey} onClick={handleSort}>
          <div
            className={cx({
              sortable: handleSort && !sortedUp && !sortedDown,
              sortedUp,
              sortedDown,
            })}
          />
        </a>}
    </div>
  );
};

HeaderCell.defaultProps = {
  grow: 1,
};

export default enhance(HeaderCell);
