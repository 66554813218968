import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import config from 'app/config';


const orgsDishesEditListDefaultState = {
  rows: config.perPageItems,
  title: true,
  photo: true,
  portion: true,
  cost: true,
  setup: true,
};

export default makeSettingsReducer('orgsDishesEditList', orgsDishesEditListDefaultState);
