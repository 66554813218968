// @flow
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { popup, popupErr } from 'app/common/actions/popup';


export default function usePopupErr(): [
(string) => void,
(string) => void,
Function
] {
  const dispatch = useDispatch();

  const handlePopup = useCallback((message: string) => dispatch(popup(message)), [dispatch]);
  const handlePopupErr = useCallback((message: string) => dispatch(popupErr(message)), [dispatch]);

  return [handlePopupErr, handlePopup, dispatch];
}
