// @flow
import { useState, useCallback, useEffect, useRef } from 'react';
import moment from 'moment-timezone';

import { dateFormat as dateFormatDefault } from './date-format';
import type { FormatValues } from './formatValues';


type Options = {
  id?: string,
  date?: string,
  dateFormat?: string,
  initialDate?: string,
  initialDateFormat?: string,
  onChange?: (value: string, id?: string) => void,
}

export default function usePlainInputDate({
  id,
  date,
  dateFormat,
  onChange,
}: Options): [
    string,
    (FormatValues) => void,
    (e: SyntheticEvent<HTMLInputElement>) => void
  ] {
  const firstRender = useRef(true);

  // state format DD.MM.YYYY
  const [dateValue, setDateValue] = useState < string > (moment(date, dateFormat).format(dateFormatDefault));

  useEffect(() => {
    if (date && dateFormat && !firstRender.current) {
      setDateValue(moment(date, dateFormat).format(dateFormatDefault));
    }
    firstRender.current = false;
  }, [date, dateFormat]);

  const handleDateValueChange = useCallback((values: FormatValues) => {
    const { formattedValue, dayFormat, valid } = values;
    if (onChange) {
      const outgoingValue = valid ? moment(formattedValue, dayFormat).format(dateFormat) : '';
      onChange(outgoingValue, id);
    }
    setDateValue(formattedValue);
  }, [setDateValue, onChange, dateFormat, id]);

  const handleBlur = useCallback(() => {
    setDateValue(moment(date, dateFormat).format(dateFormatDefault));
  }, [date, dateFormat]);

  return [dateValue, handleDateValueChange, handleBlur];
}
