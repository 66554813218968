import { useCallback, useMemo } from 'react'

import PlainDropdownSurface, { SurfaceType } from '../plain-dropdown-surface'
import PlainIcon, { iconType, iconColor } from '../plain-icon'
import PlainCalendar from '../plain-calendar'
import { dateFormat as dateFormatDefault } from '../plain-calendar/date-format'

import styles from './plain-date-picker.scss'


export type PlainDatePickerProps = {
  className?: string
  date?: string
  dateFormat?: string
  calendarCloseOnClick?: boolean // deprecated
  closeOnClick?: boolean
  weekSelection?: boolean
  onDateChange?: (date: string, format: string) => void
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean
}

const PlainDatePicker = ({
  className,
  date,
  dateFormat = dateFormatDefault,
  calendarCloseOnClick,
  closeOnClick: closeOnClick_ = true,
  weekSelection,
  onDateChange,
  dateDisabledStatus,
}: PlainDatePickerProps) => {
  const closeOnClick = typeof calendarCloseOnClick === 'boolean' ? calendarCloseOnClick : closeOnClick_
  const dateClick = useCallback((toggle: () => void) => (date_: string, format_: string) => {
    if (onDateChange) {
      onDateChange(date_, format_)
    }
    if (closeOnClick) {
      toggle()
    }
  }, [onDateChange, closeOnClick])

  const daysSelected = useMemo(() => {
    if (date) {
      return {
        [date]: date,
      }
    }
    return {}
  }, [date])

  const renderCalendar = useCallback((toggle: () => void) => (
    <PlainCalendar
      onDayClick={dateClick(toggle)}
      daysSelected={daysSelected}
      dateFormat={dateFormat}
      dateDisabledStatus={dateDisabledStatus}
      weekSelection={weekSelection}
    />
  ), [dateClick, dateFormat, daysSelected, dateDisabledStatus, weekSelection])

  return (
    <PlainDropdownSurface
      id="date"
      type={SurfaceType.Grid}
      surfaceContent={renderCalendar}
      className={className}
    >
      {(onClick, opened, areaProps) => (
        <button
          type="button"
          onClick={onClick}
          className={styles.button}
          {...areaProps}
        >
          <PlainIcon
            type={iconType.CALENDAR}
            colorType={opened ? iconColor.COLOR_PRIMARY : iconColor.COLOR_GRAY}
            width={18}
            height="auto"
          />
        </button>
      )}
    </PlainDropdownSurface>
  )
}

export default PlainDatePicker
