// @flow
import React, { Fragment, useState, useCallback } from 'react';

import RowLayout, { Header } from 'app/common/layouts/RowLayout';
import type { SettingsType, ModifyInfoType, IpInfoType } from 'app/reducers/employees/employee/settings';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import Modal from 'app/common/components/Modal';
import CSSSticky from 'app/common/ui-components/css-sticky';
import history from 'app/history';
import { replaceLastN } from 'app/common/lib/path';

import enhanceSettings from './enhanceSettings';
import Activity from './Activity';
import ModifyInfo from './ModifyInfo';
import IpInfo from './IpInfo';
// import * as sections from '../sections';
import HistoryTable from './HistoryTable';
import SessionsTable from './SessionsTable';
import ActivateForm from './Activity/ActivateForm';
import DeleteUser from './DeleteUser';

import styles from './SectionSettings.scss';


type Props = {
  title: string,
  personId: number,
  settings: SettingsType,
  created: ModifyInfoType,
  lastChange: ModifyInfoType,
  lastVisit: IpInfoType,
  lastLogin: IpInfoType,
  // baseUrl: string,
  locked: boolean,
};

const SectionSettings = ({
  title,
  personId,
  settings: { status },
  created,
  lastChange,
  // baseUrl,
  lastVisit,
  lastLogin,
  locked,
}: Props) => {
  const [showHistory, setHistoryVisible] = useState(false);
  const toggleHistory = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    setHistoryVisible(current => !current);
  }, []);

  const [showSessions, setSessionsVisible] = useState(false);
  const toggleSessions = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    setSessionsVisible(current => !current);
  }, []);

  const [activate, setActivate] = useState < 'activate' | 'reactivate' | '' > ('');
  const handleBeginActivate = useCallback(() => setActivate('activate'), []);
  const handleBeginReactivate = useCallback(() => setActivate('reactivate'), []);
  const hideActivateModal = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    setActivate('');
  }, []);

  const redirectToEmployees = useCallback(() => {
    const { location: { pathname } } = history;
    history.push(replaceLastN(pathname, 3));
  }, []);

  return (
    <Fragment>
      <CSSSticky
        number={3}
        stickyClassName={styles.sticky}
        shadowClassName={styles.shadow}
      >
        <RowLayout first flex>
          <Header>{title}</Header>
        </RowLayout>
      </CSSSticky>

      <RowLayout className={styles.main} flex>
        <div>
          <Activity
            personId={personId}
            status={status}
            // baseUrl={`${baseUrl}/${sections.SETTINGS_SECTION}`}
            locked={locked}
            onBeginActivate={handleBeginActivate}
            onBeginReactivate={handleBeginReactivate}
            onShowSessions={toggleSessions}
          />
          <ModifyInfo className={styles.item} title="Создан" info={created} />
          <ModifyInfo className={styles.item} title="Последнее изменение" info={lastChange} />
          {status
            && <div className={styles.link}>
              <PlainButtonAsLink onClick={toggleHistory}>
                {'История изменений'}
              </PlainButtonAsLink>
            </div>}
          <IpInfo className={styles.item} title="Последняя авторизация" info={lastLogin} />
          <IpInfo className={styles.item} title="Последнее посещение" info={lastVisit} />
        </div>

        {!locked
          && <DeleteUser userId={personId} onSuccess={redirectToEmployees}>
            {onClick => (
              <PlainButtonAsLink linkClassName={styles.deleteUserLink} onClick={onClick}>
                {'Удалить'}
              </PlainButtonAsLink>
            )}
          </DeleteUser>}
      </RowLayout>

      {activate
        && <Modal>
          <ActivateForm
            onClose={hideActivateModal}
            id={personId}
            act={activate}
            comment={activate === 'reactivate'
              ? 'Пароль пользователя сбросится на новый, сгенерированный случайным образом'
              : ''}
          />
        </Modal>}

      {showSessions
        && <Modal>
          <SessionsTable
            id={personId}
            onClose={toggleSessions}
          />
        </Modal>}

      {showHistory
        && <Modal>
          <HistoryTable
            id={personId}
            onClose={toggleHistory}
          />
        </Modal>}
    </Fragment>
  );
};

export default enhanceSettings(SectionSettings);
