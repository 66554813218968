import compose from 'recompose/compose';
import withState from 'recompose/withState';
import lifecycle from 'recompose/lifecycle';
import { connect } from 'react-redux';

import PlainSelect from 'app/common/ui/PlainSelect';
import { counterpartiesFetch } from 'app/actions/props/counterparties';


export default compose(
  connect(),

  withState('data', 'setData', []),
  withState('loading', 'setLoading', true),

  lifecycle({
    async componentDidMount() {
      const { dispatch, setData, setLoading } = this.props;
      const arr = await dispatch(counterpartiesFetch());
      setLoading(false);
      if (!arr) return;
      setData(arr.map(item => ({ id: item, title: item })));
    },
  }),
)(PlainSelect);
