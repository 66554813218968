import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer'
import config from 'app/config'


export const compensationStatementsDataKey: 'compensationStatements' = 'compensationStatements'

export type Settings = {
  rows: number
}

export const compensationStatementsState: Settings = {
  rows: config.perPageItems,
}

export default makeSettingsReducer(compensationStatementsDataKey, compensationStatementsState)
