import length from 'ramda/es/length'

import Pager from 'app/common/ui/Paging'

import { useDishesData } from './DishesData'


const DishesTableFooter = () => {
  const [
    { dishes, total, limit },,
    { currentPage, handlePage },
  ] = useDishesData()

  return (
    <Pager
      total={total}
      pageSize={limit}
      currentPage={currentPage}
      currentPageSize={length(dishes)}
      onPage={handlePage}
    />
  )
}

export default DishesTableFooter
