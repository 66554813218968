// @flow
import { useState, useCallback, useMemo } from 'react';
import F from 'ramda/es/F';
import assocPath from 'ramda/es/assocPath';

import {
  GET_CAFETERIA_ORDERS_TIME_RESTRICTIONS,
  POST_CAFETERIA_ORDERS_TIME_RESTRICTIONS,
  convertResult,
  convertParams,
  convertParamsPOST,
} from 'app/common/api/requests/food/schools/cafeteria_orders_time_restrictions';

import { api } from 'app/api';
import type { TimeRestrictions, TimeRestrictionsStrict, EnabledTimeRestrictions } from 'app/dataTypes/OrgSettingsTimeRestrictions.types';
import * as dmt from 'app/dataTypes/DayModes.types';

import { setNullsToDefault } from './defaultRestrictions';

/**
 *  Настройки ограничений для сотрудников ОУ.
 *  Ограничить создание и редактирование заявок до...
 */

const emptyFunc = () => {};

export type State = {
  enabled: EnabledTimeRestrictions,
  restrictions: TimeRestrictionsStrict,
  loading: boolean,
  modified: boolean,
  error: string,
}

export const defaultState: State = {
  enabled: false,
  restrictions: [],
  loading: true,
  modified: false,
  error: '',
};

export type ApiHandlers = {
  request: () => void | Promise<void>,
  submit: (overrideOrgIds?: Array<number>) => boolean | Promise<boolean>,
  checkErrors: () => boolean,
}

export const defaultApiHandlers: ApiHandlers = {
  request: emptyFunc,
  submit: F,
  checkErrors: F,
};

export type ModifyHandlers = {
  updateEnabled: (boolean) => void,
  updateTime: (gNum: number, fmNum: number, value: Date) => void,
  updateDayMode: (gNum: number, fmNum: number, value: dmt.Today | dmt.Before) => void,
}

export const defaultModifyHandlers: ModifyHandlers = {
  updateEnabled: emptyFunc,
  updateTime: emptyFunc,
  updateDayMode: emptyFunc,
};

type Options = {
  onModify?: (boolean) => void,
}

export default function useOrdersTimeRestrictions(orgId: number, options?: Options): [
  State,
  ApiHandlers,
  ModifyHandlers
] {
  const { onModify } = options || {};
  const [modified, setModified] = useState(false);

  const [timeRestrictions, setTimeRestrictions] = useState<TimeRestrictionsStrict>([]);
  const [enabled, setEnabled] = useState<EnabledTimeRestrictions>(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // инициализация состояния данными из запросами
  const updateState = useCallback((data: [TimeRestrictions, EnabledTimeRestrictions]) => {
    const [restrictionsData, enabledData] = data;
    setEnabled(enabledData);
    setTimeRestrictions(setNullsToDefault(restrictionsData));
  }, []);

  // запрос данных
  const requestTimeRestrictions = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_CAFETERIA_ORDERS_TIME_RESTRICTIONS, {
      error: 'Не удалось получить данные ограничения заявок по времени',
      params: { orgId },
      convertions: {
        convertResult,
        convertParams,
      },
    });
    setLoading(false);

    if (res) {
      updateState(res);
      setError('');
      setModified(false);
      if (onModify) {
        onModify(false);
      }
    }
  }, [orgId, updateState, onModify, setLoading]);


  // отправка данных
  const submitTimeRestrictions = useCallback(async (overrideOrgIds?: Array<number>) => {
    const orgIds = overrideOrgIds || [orgId];

    const res = await api.request(POST_CAFETERIA_ORDERS_TIME_RESTRICTIONS, {
      error: 'Не удалось сохранить данные ограничения заявок по времени',
      params: {
        orgIds: orgIds.map(String),
        enabled,
        restrictions: timeRestrictions,
      },
      convertions: {
        convertParams: convertParamsPOST,
      },
    });

    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true;
    }

    if (res) {
      setError('');
      setModified(false);
      if (onModify) {
        onModify(false);
      }
      return true;
    }
    return false;
  }, [orgId, onModify, timeRestrictions, enabled]);


  const handleCheckErrors = useCallback(() => {
    return true;
  }, []);


  const handleUpdateEnabled = useCallback((checked: boolean) => {
    if (onModify) {
      onModify(true);
    }
    setModified(true);
    setEnabled(checked);
  }, [onModify]);

  const handlUpdateTime = useCallback((gNum: number, fmNum: number, value: Date) => {
    if (onModify) {
      onModify(true);
    }
    setModified(true);
    setTimeRestrictions(assocPath([gNum, fmNum, 'time'], value));
  }, [onModify]);

  const handleUpdateDayMode = useCallback((gNum: number, fmNum: number, value: dmt.Today | dmt.Before) => {
    if (onModify) {
      onModify(true);
    }
    setModified(true);
    setTimeRestrictions(assocPath([gNum, fmNum, 'dayMode'], value));
  }, [onModify]);


  const state = useMemo(() => ({
    restrictions: timeRestrictions,
    enabled,
    loading,
    modified,
    error,
  }), [
    timeRestrictions,
    loading,
    error,
    modified,
    enabled,
  ]);

  const apiHandlers = useMemo(() => ({
    request: requestTimeRestrictions,
    submit: submitTimeRestrictions,
    checkErrors: handleCheckErrors,
  }), [
    requestTimeRestrictions,
    submitTimeRestrictions,
    handleCheckErrors,
  ]);

  const modifyHandlers = useMemo(() => ({
    updateTime: handlUpdateTime,
    updateEnabled: handleUpdateEnabled,
    updateDayMode: handleUpdateDayMode,
  }), [
    handleUpdateEnabled,
    handlUpdateTime,
    handleUpdateDayMode,
  ]);

  return [state, apiHandlers, modifyHandlers];
}
