// @flow
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { checkStatuses } from 'app/common/constants/identifiers';
import { ModalContent } from 'app/common/components/Modal';

import styles from './check-status-error.scss';


type Props = {
  error: $Values<typeof checkStatuses>
};

const CheckStatusError = ({ error }: Props) => {
  const { t } = useTranslation('identifiers-next');
  const { phone, email } = useSelector(state => state.dealer, shallowEqual);

  if (error === checkStatuses.BLOCKED) {
    return (
      <ModalContent>
        <span className={styles.danger}>
          {t('identifier_entering_number_not_found')}
        </span>
        <br />
        <span>{t('identifier_entering_call_dealer')}</span>
        <div className={styles.dealerPhone}>{phone}</div>
        <div className={styles.dealerEmail}>{email}</div>
      </ModalContent>
    );
  }

  if (error === checkStatuses.ALREADY_USED) {
    return (
      <ModalContent>
        <span className={styles.danger}>{t('identifier_entering_number_already_used')}</span>
      </ModalContent>
    );
  }

  return null;
};

export default CheckStatusError;
