// @flow
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import values from 'ramda/es/values';
import identity from 'ramda/es/identity';
import filter from 'ramda/es/filter';
import keys from 'ramda/es/keys';
import omit from 'ramda/es/omit';
import head from 'ramda/es/head';
import groupBy from 'ramda/es/groupBy';
import test from 'ramda/es/test';

import api from 'app/actions/api';
import action from 'app/common/lib/action';

import {
  MAIL_ONLY,
  MAIL_AND_VIEW,
} from '../filters/mail';


const getArrayOfId = pipe(values, map(prop('id')));

export const SUBSIDIES_GENERATE_REQUEST = 'reports/SUBSIDIES_GENERATE_REQUEST';
export const SUBSIDIES_GENERATE_SUCCESS = 'reports/SUBSIDIES_GENERATE_SUCCESS';
export const SUBSIDIES_GENERATE_FAILURE = 'reports/SUBSIDIES_GENERATE_FAILURE';

export const subsidiesReportGenerate = () => async (dispatch: Function, getState: Function) => {
  const state = getState();

  const mailOption = state.reports.filters.mail;
  const sendTo = (mailOption === MAIL_ONLY || mailOption === MAIL_AND_VIEW
    ? state.user.email
    : null);

  const sumsAndKits = groupBy(
    ({ id }) => (test(/^sum_/, id) ? 'sums' : 'complexes'),
    values(state.reports.filters.sumsAndKits.selected),
  );

  const someLimits = {
    school_ids: getArrayOfId(state.reports.filters.orgs.selected), // id школ
    schoolyear_id: head(getArrayOfId(state.reports.filters.years.selected)), // id учебного года
    chains: getArrayOfId(state.reports.filters.educationLevels.selected), // уровни образования (в случае одной школы)
    klass_ids: getArrayOfId(state.reports.filters.classes.selected), // id классов, будет принято в случае отсутствия student_ids, имеет приоритет над school_ids, chains
    student_ids: getArrayOfId(state.reports.filters.students.selected), // id учащихся, имеет приоритет над всеми прочими фильтрами в случае customer_type==[Учащиеся]
    employee_ids: getArrayOfId(state.reports.filters.employees.selected),
    allowances: getArrayOfId(state.reports.filters.subsidies.selected), // типы льгот
    sums: map(({ title }) => parseFloat(title), sumsAndKits.sums || []), // суммы справок
    complexes: map(prop('id'), sumsAndKits.complexes || []), // id комплектов в справках
    order_type: getArrayOfId(state.reports.filters.subsidyTypes.selected)[0] || 'all', // тип справки (все, общая, индивидуальная)
    view_type: getArrayOfId(state.reports.filters.viewTypes.selected)[0] || 'sum', // вид отображения (сумма, количество)
  };

  const notActiveFilters = {
    years_ids: !state.reports.filters.years.active,
    schoolyear_id: !state.reports.filters.years.active,
    chains: !state.reports.filters.educationLevels.active,
    klass_ids: !state.reports.filters.classes.active,
    student_ids: !state.reports.filters.students.active,
    employee_ids: !state.reports.filters.employees.active,
    allowances: !state.reports.filters.subsidies.active,
    sums: !state.reports.filters.sumsAndKits.active,
    complexes: !state.reports.filters.sumsAndKits.active,
    order_type: false,
    view_type: false,
  };

  const limits = omit(pipe(
    filter(identity),
    keys,
  )(notActiveFilters), someLimits);

  const params = {
    from: state.reports.filters.dateFromTo.dateFrom,
    to: state.reports.filters.dateFromTo.dateTo,
    // split_mode: state.reports.filters.subsidySplit.split, // разделять дотации на 2 части, параметр будет принят только в случае если в school_ids менее 2 значений и период выборки меньше или равен месяцу
    limits,
    details: state.reports.filters.details, // детализированный отчет
    details_group: state.reports.filters.group.active ? state.reports.filters.group.current : undefined, // фильтр, определяющий набор данных - суммы по классам и типам пользователя или по каждому пользователю
  };

  const options = state.reports.filters.details ? {
    send_to_email: sendTo, // отправить на почту по завершении формирования
    additional: {
      // all_schools_zip_file: state.reports.filters.pack, // включить в файл архив по всем школам
    },
  } : {};

  const data = {
    report_type: 'subsidies_detailed',
    params,
    options,
  };

  dispatch(action(SUBSIDIES_GENERATE_REQUEST, data));

  try {
    const res = await api('reports/perform', 'post', { data });
    const reportId = path(['data', 'report_id'], res);
    dispatch(action(SUBSIDIES_GENERATE_SUCCESS, reportId));
    return reportId;
  } catch (error) {
    dispatch(action(
      SUBSIDIES_GENERATE_FAILURE,
      { err: 'Не удалось сгенерировать отчет', error },
    ));
    return 0;
  }
};
