import React from 'react';
import { Route } from 'react-router';

type Props = {
  props: Object,
  component: any,
};

const PropsRoute = ({ props, component: Component, ...others }: Props) => <Route {...others} render={routerProps => <Component {...props} {...routerProps} />} />;

export default PropsRoute;
