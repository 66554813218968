export type Allowance = {
  ID: string
  title: string
  deletable: boolean
}

export type Allowances = Array<Allowance>

export type Params = {
  orgID: string
  used?: boolean
  app: 'cafeteria' | 'camp'
  subsidyType: 'common' | 'personal'
}

export enum CommonAlowance {
  GeneralRef = 'Общая справка'
}

export const isCommonAllowance = (value: any): value is CommonAlowance => {
  return Object.values(CommonAlowance).includes(value)
}

export type MutationParams = {
  orgID: string
  app: 'cafeteria' | 'camp'
  allowanceIDs?: Array<string>,
  allowanceAddID?: string
  allowanceRemoveID?: string
}
