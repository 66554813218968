// @flow
import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import propEq from 'ramda/es/propEq';
import find from 'ramda/es/find';
import filter from 'ramda/es/filter';

import { PlainItemsSelector, type PlainItemsSelectorProps } from 'app/common/ui-next/plain-selector';
import { PlainRadioGroup, type PlainRadioGroupProps } from 'app/common/ui-next/plain-radio-button';
import { PlainCheckboxGroup, type PlainCheckboxGroupProps } from 'app/common/ui-next/plain-checkbox';
import { PlainDatePeriod, type PlainDatePeriodProps } from 'app/common/ui-next/plain-date-period';
import { emptyObject as emptyProps } from 'app/common/lib/empty';

import PlainInputDateComp, { type PlainInputDateCompProps } from './compilations/plain-input-date-comp';
import RadioDate, { type RadioDateProps } from './compilations/radio-date';
import RadioText, { type RadioTextProps } from './compilations/radio-text';
import CustomerSearch, { type CustomerSearchProps } from './compilations/customer-search';
import useFilterData from './use-filter-data';
import type { State } from './filter-state';
import type { FilterItemDescription } from './filter-description';
import { filterItemTypes } from './filter-item-types';

/**
 *  Управляющий элемент фильтра
 *  Тип зависит от filterType
 */

// PLAIN_ITEMS_SELECTOR
export type PlainItemsSelectorRestProps = $Diff<PlainItemsSelectorProps, {
  items: any,
  multiple: any,
  onChange: any,
  selection: any,
  loading: any,
  defaultState: any,
}>

const defaultPlainItemsSelectorRestProps = {
  label: 'Фильтр',
  header: 'Выберите',
};

// PLAIN_RADIO_GROUP
export type PlainRadioGroupRestProps = $Diff<PlainRadioGroupProps, {
  id: any,
  items: any,
  onChange: any,
  disabled: any,
}>

const defaultPlainRadioGroupRestProps = {
  label: 'Фильтр',
};

// PLAIN_CHECKBOX_GROUP
export type PlainCheckboxGroupRestProps = $Diff<PlainCheckboxGroupProps, {
  id: any,
  items: any,
  onChange: any,
  disabled: any,
}>

const defaultPlainCheckboxGroupRestProps = {
  label: 'Фильтр',
};

// PLAIN_PERIOD
export type PlainDatePeriodRestProps = $Diff<PlainDatePeriodProps, {
  loading: any,
  dateFrom: any,
  dateTo: any,
  onChange: any,
  reset: any,
}>

const defaultPlainDatePeriodRestProps = emptyProps;

// PLAIN_DATE
export type PlainDateRestProps = {
  label?: string,
  ...$Diff<$Exact<PlainInputDateCompProps>, {
    loading: any,
    value: any,
    onDateChange: any,
  }>
}

const defaultPlainDateRestProps = emptyProps;

// RADIO_DATE
export type RadioDateRestProps = $Diff<RadioDateProps, {
  id: any,
  items: any,
  current: any,
  loading: any,
  onUpdate: any,
}>

const defaultRadioDateRestProps = {
  label: 'Дата',
  dateFormat: 'YYYY-MM-DD',
};

// RADIO_TEXT
export type RadioTextRestProps = $Diff<RadioTextProps, {
  id: any,
  items: any,
  current: any,
  loading: any,
  onUpdate: any,
}>

const defaultRadioTextRestProps = emptyProps;

// CUSTOMER_SEARCH
export type CustomerSearchRestProps = $Diff<CustomerSearchProps, {
  id: any,
  items: any,
  current: any,
  loading: any,
  onUpdate: any,
}>

const defaultCustomerSearchRestProps = emptyProps;

type Props<Params> = {
  filterId: string,
  className?: string,
  activity?: (State, ?Params) => boolean,
  skipUpperSpace?: boolean,
}

function FilterItem<Params = void>({
  filterId,
  className,
  activity: localActivity,
  skipUpperSpace,
}: Props<Params>) {
  const [, state, desc, actions, params] = useFilterData();
  const { updateCurrentState, updateActivity, updateData, resetFilter } = actions;
  const { items, current, defaultCurrent, active } = state[filterId] || {};

  const {
    filterType,
    multiple,
    props,
    getProps,
    activity,
  }: FilterItemDescription<Params> = find(propEq('filterId', filterId), desc);

  // возможно есть функция для генерации props
  const componentProps = {
    ...props || {},
    ...(getProps ? getProps(state, params) : {}),
  };

  // handlers для обработки обновления конкретного типа элемента фильтра
  const handleUpdate = useCallback((values: Array<string>) => {
    updateCurrentState(filterId, values);
  }, [updateCurrentState, filterId]);

  const handleUpdateRadio = useCallback((value: string) => {
    updateCurrentState(filterId, [value]);
  }, [updateCurrentState, filterId]);

  const handleUpdateCheckbox = useCallback((value: string, checked: boolean) => {
    updateCurrentState(
      filterId,
      checked
        ? [value, ...current]
        : filter(key => (key !== value), current),
    );
  }, [updateCurrentState, filterId, current]);

  const handleUpdatePeriod = useCallback(([value1, value2]: [string, string]) => {
    updateCurrentState(filterId, [value1, value2]);
  }, [updateCurrentState, filterId]);

  const handleResetPeriod = useCallback(() => {
    resetFilter(filterId);
  }, [resetFilter, filterId]);

  const handleUpdateDate = useCallback((formattedValue: string) => {
    if (formattedValue) {
      updateCurrentState(filterId, [formattedValue, formattedValue]);
    }
  }, [updateCurrentState, filterId]);


  const startItems = useRef(items);
  const loading = useMemo(() => (
    items === startItems.current
  ), [items]);


  const visible = useMemo(() => {
    const currentAcivity = localActivity || activity;
    if (currentAcivity) {
      return currentAcivity(state, params);
    }
    return true;
  }, [activity, state, params, localActivity]);

  useEffect(() => {
    updateActivity(filterId, visible);
    if (visible) {
      updateData(filterId);
    }
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!active) {
    return null;
  }


  if (filterType === filterItemTypes.PLAIN_ITEMS_SELECTOR) {
    const restProps: PlainItemsSelectorRestProps = componentProps || defaultPlainItemsSelectorRestProps;

    return (
      <PlainItemsSelector
        items={items}
        multiple={multiple}
        selection={current}
        loading={loading}
        defaultSelection={defaultCurrent}
        onChange={handleUpdate}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  if (filterType === filterItemTypes.PLAIN_RADIO_GROUP) {
    const restProps: PlainRadioGroupRestProps = componentProps || defaultPlainRadioGroupRestProps;

    return (
      <PlainRadioGroup
        id={filterId}
        items={items}
        current={current[0]}
        disabled={loading}
        onChange={handleUpdateRadio}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  if (filterType === filterItemTypes.PLAIN_CHECKBOX_GROUP) {
    const restProps: PlainCheckboxGroupRestProps = componentProps || defaultPlainCheckboxGroupRestProps;

    return (
      <PlainCheckboxGroup
        id={filterId}
        items={items}
        current={current}
        disabled={loading}
        onChange={handleUpdateCheckbox}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  if (filterType === filterItemTypes.PLAIN_PERIOD) {
    const [dateFrom = '', dateTo = ''] = current;
    const restProps: PlainDatePeriodRestProps = componentProps || defaultPlainDatePeriodRestProps;

    return (
      <PlainDatePeriod
        loading={loading}
        dateFrom={dateFrom}
        dateTo={dateTo}
        onChange={handleUpdatePeriod}
        reset={handleResetPeriod}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  if (filterType === filterItemTypes.PLAIN_DATE) {
    const [value = ''] = current;
    const { ...restProps }: PlainDateRestProps = componentProps || defaultPlainDateRestProps;

    return (
      <PlainInputDateComp
        value={value}
        onDateChange={handleUpdateDate}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  if (filterType === filterItemTypes.RADIO_DATE) {
    const { ...restProps }: RadioDateRestProps = componentProps || defaultRadioDateRestProps;

    return (
      <RadioDate
        id={filterId}
        items={items}
        current={current}
        onUpdate={updateCurrentState}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  if (filterType === filterItemTypes.RADIO_TEXT) {
    const { ...restProps }: RadioTextRestProps = componentProps || defaultRadioTextRestProps;

    return (
      <RadioText
        id={filterId}
        items={items}
        current={current}
        onUpdate={updateCurrentState}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  if (filterType === filterItemTypes.CUSTOMER_SEARCH) {
    const { ...restProps }: CustomerSearchRestProps = componentProps || defaultCustomerSearchRestProps;

    return (
      <CustomerSearch
        id={filterId}
        current={current}
        onUpdate={updateCurrentState}
        {...restProps}
        className={className}
        skipUpperSpace={skipUpperSpace}
      />
    );
  }

  return <div>{'Неизвестный фильтр'}</div>;
}

export default FilterItem;
