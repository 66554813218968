// @flow
import * as React from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import { Scrollbars } from 'react-custom-scrollbars-2';

import SubsidiesSheet, { SheetsHeader, SheetsItem } from 'app/common/components/SubsidiesSheet';
import enhanceSubsidyInfo from './enhanceSubsidyInfo';
import styles from './SubsidyInfo.scss';


const rowHeight = 50;
const maxRows = 4;
type Props = {
  title: string,
  onClickClose: (e: SyntheticEvent<HTMLElement>) => void,
  items: Array<Object>,
}

const SubsidyInfo = ({ title, onClickClose, items }: Props) => (
  <React.Fragment>
    <h2 className={styles.title}>{title}</h2>
    <SubsidiesSheet className={styles.content}>
      <SheetsHeader />
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={maxRows * rowHeight}
      >
        {items.map(({ idx: key, ...props }) => <SheetsItem key={key} {...props} />)}
      </Scrollbars>
    </SubsidiesSheet>
    <div className={styles.footer}>
      <PlainButton onClick={onClickClose}>
        {'Закрыть'}
      </PlainButton>
    </div>
  </React.Fragment>
);

export default enhanceSubsidyInfo(SubsidyInfo);
