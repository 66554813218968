import { OrgGroupsByOrgType, ParamsPOST } from 'app/dataTypes/org/orgGroup'
import { OrgType } from 'app/dataTypes/org/types'

import { Request } from '../../index'
import { convertResult } from './common'


export { convertResult }

type ParamsAPI = {
  title: string
  school_type: string
}

export const convertParams = (params: ParamsPOST): [ParamsAPI, null] => {
  const { title, orgType } = params
  return ([{
    title,
    school_type: orgType === OrgType.HighSchool ? 'high_school' : orgType,
  }, null])
}

export const POST_ORG_GROUP: Request<ParamsPOST, OrgGroupsByOrgType, void> = [
  'food/school_groups',
  'post',
  ['data', 'school_groups'],
]
