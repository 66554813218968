import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';

import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import flatten from 'ramda/es/flatten';

import genId from 'app/common/lib/genId';
import { needRefresh } from 'app/common/actions/settings/current';
// import { logout } from 'app/common/actions/user';
import { clearErrors } from 'app/common/actions/errors';


const selectProps = createSelector(
  [
    (_, { text }) => text,
    state => state.dealer.phone,
  ],
  (text, phone) => ({
    textSplitted: text && pipe(
      map(item => [item, <br key={genId()} />]),
      flatten,
    )(text.split('\n')),
    phone,
  }),
);

const enhanceConnectionTimeOut = compose(
  connect((state, props) => ({
    ...selectProps(state, props),
  })),

  withHandlers({
    handleRefresh: ({ dispatch }) => () => {
      dispatch(clearErrors());
      dispatch(needRefresh(true));
    },
    // handleLogout: ({ dispatch }) => (e) => {
    //   e.preventDefault();
    //   dispatch(logout());
    // },
  }),
);

export default enhanceConnectionTimeOut;
