import {
  APPS_REQUEST,
  APPS_SUCCESS,
  APPS_FAILURE,
} from '../../actions/apps';


const defaultState = {
  loading: false,
  apps: {},
  result: null,
};

const apps = (state = defaultState, action) => {
  switch (action.type) {
    case APPS_REQUEST: {
      return { ...state, loading: true };
    }

    case APPS_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    case APPS_FAILURE: {
      return { ...state, loading: false };
    }

    default:
  }
  return state;
};

export default apps;
