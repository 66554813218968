// @flow
import React, { Fragment, useState, useCallback, useContext } from 'react';
import reduce from 'ramda/es/reduce';
import head from 'ramda/es/head';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { identifiersRegex, identifiersReplace } from 'app/common/constants/identifiers/formats';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { ModalFooter } from 'app/common/components/Modal';
import { checkStatuses, identifiers as identifiersTypesNames } from 'app/common/constants/identifiers';
import { popup } from 'app/common/actions/popup';
import { toFixedValueCurrency } from 'app/common/lib/toFixedValue';
import { useCurrencySign } from 'app/common/components-next/currency';

import { IdentifiersContext } from '../../identifiers-data';
import EnteringForm, { type IdentifierFormat, type Values } from './entering-form';
import SelectTypeForm, { type IdentifierTypeInfo } from './select-type-form';
import CheckStatusError from './check-status-error';
import IdentifierView, { type IdentifierType } from './identifier-view';
import styles from './entering-identifier.scss';


export type CheckResult = {
  type: IdentifierType,
  price: number,
  result: 'success',
} | {
  error: $Values<typeof checkStatuses>,
}

const getDefaultValues = (identifiers): Values => reduce((acc, key) => ({ ...acc, [key]: '' }), {}, identifiers);

type Props = {
  onBeforeSubmit?: () => Promise<boolean>,
  onCancel: () => void,
  onIdentifierCheck?: (personId: number, identNumber: string) => Promise<CheckResult>,
  onIdentifierSubmit?: (personId: number, {
    type: $Keys<typeof identifiersTypesNames>,
    number: string,
  }) => Promise<boolean>,
  personId: number,
  availableFormats: Array<IdentifierFormat>,
  types: Array<IdentifierTypeInfo>,
  formId: string,
  hidePrice?: boolean,
}

const EnteringIdentifier = ({
  onBeforeSubmit, // в магазине требуется сначала выдать товар, прежде чем привязать идентификатор
  onIdentifierSubmit,
  onCancel,
  onIdentifierCheck,
  personId,
  availableFormats,
  types,
  formId,
  hidePrice,
}: Props) => {
  const { t } = useTranslation('identifiers-next');
  const dispatch = useDispatch();
  const currency = useCurrencySign();

  const [current, setCurrent] = useState<IdentifierFormat>(head(availableFormats));
  const [values, setValues] = useState<Values>(getDefaultValues(availableFormats));
  const [type, setType] = useState<IdentifierType>('');
  const [price, setPrice] = useState('');
  const [checkStatusError, setCheckStatusError] = useState<$Values<typeof checkStatuses> | ''>('');
  const [checkError, setCheckError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [checking, setChecking] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Обновлять данные не нужно, при условии если
  // компонент монтируется после загрузки данных
  // useEffect(() => {
  //   setValues(getDefaultValues(availableFormats));
  //   setCurrent(head(availableFormats));
  // }, [availableFormats]);

  const handleReset = useCallback(() => {
    setCheckError('');
    setCheckStatusError('');
    setType('');
  }, []);

  /**
   * Проверка введенного идентификатора сервером
   */

  const handleIdentifierCheck = useCallback(async (e: SyntheticInputEvent<HTMLFormElement>) => {
    const { id: fId } = e.target;
    if (fId !== `identifierCheck_${formId}`) return;
    e.preventDefault();
    if (!values[current]) {
      setCheckError(t('identifier_entering_error_empty'));
      return;
    }
    const isValid = identifiersRegex.validate(current, values[current]);
    if (!isValid) {
      setCheckError(t('identifier_entering_error_format'));
      return;
    }
    setCheckError('');
    if (onIdentifierCheck) {
      setChecking(true);
      const valueToCheck = identifiersReplace.replace(current, values[current]);
      const res = await onIdentifierCheck(personId, valueToCheck);
      setChecking(false);
      if (res.error) {
        setCheckStatusError(res.error);
        return;
      }
      setType(res.type);
      setPrice(typeof res.price === 'number'
        ? toFixedValueCurrency(res.price, true, currency)
        : '');
    }
  }, [personId, current, values, onIdentifierCheck, t, formId, currency]);

  /**
   * Добавление идентификатора
   */

  const {
    request: { addNewIdentifier },
  } = useContext(IdentifiersContext);

  const handleIdentifierSubmit = useCallback(async (e: SyntheticInputEvent<HTMLFormElement>) => {
    const { id: fId } = e.target;
    if (fId !== `identifierSubmit_${formId}`) return;
    e.preventDefault();
    if (!type) {
      setSubmitError(t('identifier_entering_type_not_selected'));
      return;
    }
    setSubmitting(true);
    if (onBeforeSubmit) {
      const res = await onBeforeSubmit();
      if (!res) return;
    }
    const number = identifiersReplace.replace(current, values[current]);

    // begin deprecated block
    if (onIdentifierSubmit) {
      const res = await onIdentifierSubmit(personId, { type, number });
      if (res) {
        dispatch(popup(t('identifier_entering_submit_success')));
        onCancel();
        return;
      }
    // end deprecated block
    } else if (addNewIdentifier) {
      const res = await addNewIdentifier(type, number);
      if (res) {
        dispatch(popup(t('identifier_entering_submit_success')));
        onCancel();
        return;
      }
    }
    setSubmitting(false);
  }, [
    dispatch,
    onCancel,
    onBeforeSubmit,
    onIdentifierSubmit,
    formId,
    personId,
    values,
    current,
    type,
    t,
    addNewIdentifier,
  ]);

  return (
    <Fragment>
      <EnteringForm
        formId={formId}
        values={values}
        current={current}
        onSetCurrent={setCurrent}
        onSetValues={setValues}
        onCancel={onCancel}
        error={checkError}
        onIdentifierCheck={handleIdentifierCheck}
        onResetError={handleReset}
        hideButtons={!!type || checkStatusError === checkStatuses.UNKNOWN}
        checking={checking}
        locked={submitting}
      >
        {checkStatusError && <CheckStatusError error={checkStatusError} />}
      </EnteringForm>
      <form onSubmit={handleIdentifierSubmit} id={`identifierSubmit_${formId}`}>
        {checkStatusError === checkStatuses.UNKNOWN
          && <SelectTypeForm
            current={type}
            types={types}
            onSetType={setType}
            disabled={checking || submitting}
            error={submitError}
            hidePrice={hidePrice}
          />}
        {!!type && checkStatusError !== checkStatuses.UNKNOWN
          && <IdentifierView
            type={type}
            price={price}
          />}
        {(!!type || checkStatusError === checkStatuses.UNKNOWN)
          && <ModalFooter className={styles.footer}>
            <ControlsBar right>
              <PlainButton outline onClick={onCancel}>
                {t('identifier_entering_bt_cancel')}
              </PlainButton>
              <PlainButton type="submit" disabled={submitting}>
                {submitting
                  ? t('identifier_entering_bt_submit_submitting')
                  : t('identifier_entering_bt_submit')}
              </PlainButton>
            </ControlsBar>
          </ModalFooter>}
      </form>
    </Fragment>
  );
};

export default EnteringIdentifier;
