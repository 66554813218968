// @flow
import React, { Fragment, useCallback } from 'react';
import classNames from 'classnames/bind';

import { Td, TrSelectable, TdLabel } from 'app/common/ui-components/table';
import { type Settings } from 'app/reducers/settings/dishes';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import { useSelectionData } from 'app/components/common/SelectionData';
import { useSaleTypesData } from 'app/dataProviders/SaleTypesData';

import DishesTablePhoto from './DishesTablePhoto';
import DishesTableOptions from './DishesTableOptions';
import DishesTableEditableProp from './DishesTableEditableProp';
import { useDishesData, type SaveType, type PhotoNewValue } from '../DishesData';

import DishesTableSaleTypes from './DishesTableSaleTypes';
import styles from './DishesTableRow.scss';

/**
 *  Строка в таблице блюд
 */

// TODO replace cells by app/components/common/cellContents/CellContent...

const cx = classNames.bind(styles);

type Props = {
  id: number,
  title: string,
  photoUrl: string | null,
  cost: string,
  portion: string,
  saleType: string,
  settings: Settings,
  loading: boolean,
  locked?: boolean,
  nomenclatureActive: boolean,
  nomenclature: string | null,
}

const DishesTableRow = ({
  id,
  title,
  photoUrl,
  portion,
  cost,
  saleType,
  settings,
  loading,
  locked,
  nomenclatureActive,
  nomenclature,
}: Props) => {
  const {
    check: showCheck,
    photo: showPhoto,
    portion: showPortion,
    cost: showCost,
    saleType: showSaleType,
    options: showOptions,
    nomenclature: showNomenclature,
    id: showId,
  } = settings;

  const [{ selection }, { update: selectionUpdate }] = useSelectionData();
  const selected = selection[String(id)];
  const [, { fieldUpdate, photoDelete, photoUpdate }] = useDishesData();

  const [saleTypes, loadingSaleTypes] = useSaleTypesData();

  const handleClick = useCallback((_, checked) => {
    selectionUpdate(id, checked);
  }, [selectionUpdate, id]);

  const makeHandleSubmitProp = useCallback((propName: string) => (value: string, options: Array<SaveType>) => {
    return fieldUpdate({
      id,
      field: propName,
      new_value: value,
      item_types: options,
    });
  }, [fieldUpdate, id]);

  const handlePhotoDelete = useCallback(() => {
    return photoDelete(id);
  }, [photoDelete, id]);

  const handlePhotoAdd = useCallback((photoParams: PhotoNewValue) => {
    return photoUpdate(id, photoParams);
  }, [photoUpdate, id]);

  const handleChangeSaleType = useCallback((saleTypeId: string) => {
    return fieldUpdate({
      id,
      field: 'saleType',
      new_value: saleTypeId,
    });
  }, [fieldUpdate, id]);

  const rowId = `row_${id}`;
  const TitleTd = showCheck ? TdLabel : Td;
  const titleProps = showCheck ? { htmlFor: rowId } : {};

  return (
    <TrSelectable
      key={id}
      id={rowId}
      selected={selected}
      disabled={loading}
      onClick={handleClick}
      className={cx(styles.root, { selected })}
    >
      {checkboxProps => (
        <Fragment>
          {showCheck
            && <TdLabel
              htmlFor={rowId}
              className={styles.columnCheckbox}
            >
              <PlainCheckbox
                label={title}
                skipLabel
                {...checkboxProps}
              />
            </TdLabel>}

          {showId
            && nomenclatureActive
            // {/* $FlowFixMe */}
            && <TitleTd
              {...titleProps}
            >
              {id}
            </TitleTd>}

          {showNomenclature
            && nomenclatureActive
            // {/* $FlowFixMe */}
            && <TitleTd
              {...titleProps}
            >
              {nomenclature}
            </TitleTd>}

          {/* $FlowFixMe */}
          <TitleTd
            {...titleProps}
            className={styles.title}
          >
            {title}
          </TitleTd>

          {showPhoto
            && <Td
              alignCenter
            >
              <DishesTablePhoto
                title={title}
                photoUrl={photoUrl || ''}
                locked={locked}
                onDelete={handlePhotoDelete}
                onPhoto={handlePhotoAdd}
              />
            </Td>}

          {showPortion
            && <Td>
              {locked
                ? portion
                : <DishesTableEditableProp
                  value={portion}
                  onSubmit={makeHandleSubmitProp('portion')}
                />}
            </Td>}

          {showCost
            && <Td>
              {locked
                ? cost
                : <DishesTableEditableProp
                  value={cost}
                  onSubmit={makeHandleSubmitProp('cost')}
                  floatType
                />}
            </Td>}

          {showSaleType
            && <Td
              className={styles.columnSaleTypes}
            >
              {locked
                ? saleType
                : <DishesTableSaleTypes
                  id={id}
                  loading={loadingSaleTypes}
                  currentSaleType={saleType}
                  saleTypes={saleTypes}
                  onChangeSaleType={handleChangeSaleType}
                />}
            </Td>}

          {showOptions
            && <Td
              alignRight
            >
              {!locked
                && <DishesTableOptions
                  id={id}
                  title={title}
                />}
            </Td>}
        </Fragment>
      )}
    </TrSelectable>
  );
};

export default DishesTableRow;
