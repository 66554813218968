// @flow
import React, { Fragment, useState, useCallback } from 'react';

import Loader from 'app/common/components/Loader';
import EmptyReportCSS from 'app/components/common/reports/EmptyReportCSS';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';
import { useCurrencySign } from 'app/common/components-next/currency';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { RowLayoutContent } from 'app/common/layouts/RowLayout';

import HeaderBar from './HeaderBar';
import useReportData from './useReportData';
import useDisplaySettings, { sortTypes } from './useDisplaySettings';
import InvalidateWarning from './InvalidateWarning';
import Summary, { IndividualSummary } from './Summary';
import Schools from './Schools';
import Groups from './Groups';
import Users from './Users';
import UsersByGroup from './UsersByGroup';

import styles from './Report.scss';


type Props = {
  reportId: number | null,
}

const Report = ({ reportId }: Props) => {
  const [invalidReport, invalidateReport] = useState(false);
  const handleInvalidate = useCallback(() => invalidateReport(true), []);

  const currency = useCurrencySign();

  const [data, loading] = useReportData(reportId);
  const { summary, schools, groups, users } = data || {};
  const { commonBalance, sort, byUserReport, byOrgsReport } = useDisplaySettings(!loading);

  const nothing = !(summary || schools || groups || users);

  if (!reportId) {
    return (
      <EmptyReportCSS
        stickyNumber={2}
        headerBar={
          <HistoryLink reportType={reportTypes.REPORT_BALANCES} />
        }
      />
    );
  }

  return (
    <ReportSectionCSS
      stickyNumber={2}
      header="Отчет «Остаток средств»"
      headerBar={
        <HeaderBar reportId={reportId} />
      }
      headerAdditional={
        <InvalidateWarning active={invalidReport} />
      }
      wideContent
    >
      {loading
        ? <Loader />

        : <Fragment>
          {byUserReport
            && <IndividualSummary
              summary={summary}
              loading={loading}
              currency={currency}
            />}

          {summary
            && byOrgsReport
            && commonBalance
            && <Summary
              summary={summary}
              loading={loading}
              currency={currency}
              settings={commonBalance}
            />}

          {schools
            && byOrgsReport
            && commonBalance
            && <Schools
              data={schools}
              settings={commonBalance}
            />}

          {groups
            && byOrgsReport
            && commonBalance
            && <Groups
              data={groups}
              settings={commonBalance}
            />}

          {users
            && byOrgsReport
            && sort === sortTypes.BY_ALPHABET
            && <Users
              data={users}
              onInvalidateReport={handleInvalidate}
            />}

          {users
            && byOrgsReport
            && sort === sortTypes.BY_CLASSES
            && <UsersByGroup
              data={users}
              onInvalidateReport={handleInvalidate}
            />}

          {nothing
            && <RowLayoutContent borderTop paddingfree>
              <EmptyWarn className={styles.empty}>
                {'Данные отсутствуют'}
              </EmptyWarn>
            </RowLayoutContent>}
        </Fragment>}
    </ReportSectionCSS>
  );
};

export default Report;
