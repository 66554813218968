import React, { useCallback, useEffect, useState } from 'react'
import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { subscibeTo } from 'app/common/data-box-store'
import { OrgsOrUserIDs, defaultOrgTypesDataReceiver, orgOrUserFilterSelection, orgTypesFilter, orgTypesFilterSelection } from 'app/dataProviders/filter-items'
import { OrgType } from 'app/dataTypes/org'


type Props = {
  id: string
  pending: boolean
}

export const OrgTypesFilter = ({ id, pending }: Props) => {
  const [filterVisibility, setFilterVisibility] = useState<boolean>(true)

  const orgOrUserFilterHandler = useCallback((data: OrgsOrUserIDs) => {
    if (data === OrgsOrUserIDs.ByOrgs) {
      orgTypesFilter.addDataReceiver(
        defaultOrgTypesDataReceiver,
      )
      orgTypesFilter.dataReceive()
      orgTypesFilter.setStatusReady()
      setFilterVisibility(true)
      return
    }
    orgTypesFilter.resetToDefault()
    setFilterVisibility(false)
  }, [])


  useEffect(() => {
    const unsubsribe = subscibeTo<OrgsOrUserIDs>(orgOrUserFilterSelection.id, orgOrUserFilterHandler)
    return (() => {
      unsubsribe()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    filterVisibility
    && <PlainItemsSelectorBox
      id={id}
      label="Тип заведения"
      header="Выбор типа заведения"
      dataBoxData={orgTypesFilter}
      dataBoxSelection={orgTypesFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      defaultSelection={[OrgType.School]}
      disabled={pending || true}
    />
  )
}
