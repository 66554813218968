// @flow
export const CAFETERIA_SETTINGS_READONLY_FOR_SCHOOLS: 'cafeteria_settings_readonly_for_schools' = 'cafeteria_settings_readonly_for_schools';
export const USE_PIN: 'use_pin' = 'use_pin';
export const FREE_CAFE_ORDER_DAY: 'free_cafe_order_day' = 'free_cafe_order_day';
export const ORDER_TARGETS_ENABLED: 'order_targets_enabled' = 'order_targets_enabled';
export const ENABLE_SUBSIDIES_FOR_ORDERS: 'enable_subsidies_for_orders' = 'enable_subsidies_for_orders';
export const ENABLE_SUBSIDIES_FOR_SINGLE_SALES: 'enable_subsidies_for_single_sales' = 'enable_subsidies_for_single_sales';
export const ALLOW_CASH_SALES: 'allow_cash_sales' = 'allow_cash_sales';
export const ALLOW_BANK_CARD_SALES: 'allow_bank_card_sales' = 'allow_bank_card_sales';
export const ALLOW_GUESS_SALES: 'allow_guess_sales' = 'allow_guess_sales';
export const ALLOW_PARENTS_TO_PARTICIPATE_IN_ORDERS: 'allow_parents_to_participate_in_orders' = 'allow_parents_to_participate_in_orders';
export const ALLOW_STUDENTS_TO_PARTICIPATE_IN_ORDERS: 'allow_students_to_participate_in_orders' = 'allow_students_to_participate_in_orders';
export const CAFETERIA_ENABLED: 'cafeteria_enabled' = 'cafeteria_enabled';

// not_used
// export const USE_FOOD_MODE: 'use_food_mode' = 'use_food_mode';
// export const ENABLED_ALLOW_CASH_SALES: 'enabled_allow_cash_sales' = 'enabled_allow_cash_sales';
// export const FOOD_MODES: 'food_modes' = 'food_modes';
