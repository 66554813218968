// @flow
import * as React from 'react';
import classnames from 'classnames/bind';
import withProps from 'recompose/withProps';

import Link from 'app/common/lib/link';
import { withRouterParamsE } from 'app/common/context/WithRouterParams';
import type { RouterParamsProps } from 'app/common/context/WithRouterParams';
import Button from 'app/common/ui/Button';

import styles from './SubsidiesSheet.scss';


const cx = classnames.bind(styles);

const SmallButton = withProps({
  small: true,
  className: styles.smallButton,
})(Button);

type Props = {
  showLinkToAll?: boolean,
  noItems?: boolean,
  ...$Exact<RouterParamsProps>,
}

const SheetsFooter = ({ showLinkToAll, noItems, baseUrl = '' }: Props) => (
  <div className={cx(styles.footer, { noItems })}>
    <SmallButton
      href={`${baseUrl}/add`}
      value="+ Добавить справку"
    />
    {showLinkToAll
      ? <Link
        to={`${baseUrl}/all`}
        className={styles.link}
      >
        {'Показать все справки'}
      </Link>
      : null}
  </div>
);

export default withRouterParamsE(['act'])(SheetsFooter);
