import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import { withTranslation } from 'react-i18next';

import config from 'app/config';
import Loader from 'app/common/components/Loader';
import { fetchAbout, aboutStateClear } from 'app/common/actions/about';
import selectDocuments from './selectDocuments';


export default compose(
  withTranslation('about'),

  connect(
    (state, props) => ({
      logo: state.dealer.logo_url || config.defaultLogo,
      isLoading: state.about.loading,
      provider: state.about.provider,
      admin: state.about.admin,
      documents: selectDocuments(state, props),
    }),
  ),

  lifecycle({
    componentDidMount() {
      const { dispatch } = this.props;
      dispatch(fetchAbout());
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(aboutStateClear());
    },
  }),

  withState('offerId', 'setOfferId', null),
  withState('privacyId', 'setPivacyId', null),
  withState('agreement', 'setAgreement', false),

  withHandlers({
    showPublicOffer: ({ setOfferId }) => (e) => {
      const { name } = e.target;
      e.preventDefault();
      setOfferId(parseInt(name, 10));
    },
    showPrivacy: ({ setPivacyId }) => (e) => {
      const { name } = e.target;
      e.preventDefault();
      setPivacyId(parseInt(name, 10));
    },
    showUserAgreement: ({ setAgreement }) => (e) => {
      e.preventDefault();
      setAgreement(true);
    },
    handleClose: ({ setOfferId, setPivacyId, setAgreement }) => (e) => {
      e.preventDefault();
      setOfferId(null);
      setPivacyId(null);
      setAgreement(false);
    },
  }),

  branch(
    props => props.isLoading,
    renderComponent(Loader),
  ),
);
