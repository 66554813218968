// @flow
import React, { useEffect, memo } from 'react';

import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import FiltersPlainLayout, { Column } from 'app/common/layouts/FiltersPlainLayout';
import { FilterItem, useFilterData } from 'app/common/ui-components/filter';

import ResetFilterLink from 'app/components/common/reports/ResetFilterLink';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';

import orgTypesFilter from 'app/components/common/reports/filters/orgType';
import orgsFilter from 'app/components/common/reports/filters/org';
import mailFilter from 'app/components/common/reports/filters/mail';
import detailsFilter from 'app/components/common/reports/filters/details';

import periodFilter from './filters/period';
import paymentSourceFilter from './filters/paymentSource';
import styles from './ReportFilter.scss';


type Props = {
  loading?: boolean,
}

const ReportFilter = memo<Props>(({ loading }: Props) => {
  const [,,, { filtersLoad, resetAll, applyFilters }] = useFilterData();

  useEffect(() => {
    filtersLoad();
  }, [filtersLoad]);

  return (
    <ReportSectionCSS
      header="Фильтры отчета «Касса в столовой»"
      stickyNumber={1}
      skipNextShift
      headerBar={
        <ResetFilterLink
          title="Сбросить все фильтры"
          onClick={resetAll}
        />
      }
    >
      {/* Окно с фильтром */}
      <RowLayoutContent paddingfree>
        <FiltersPlainLayout
          onApplyFilter={applyFilters}
          onResetAll={resetAll}
          loading={loading}
          skipHeader
        >
          <Column className={styles.column}>
            <FilterItem filterId={orgTypesFilter} />
            <FilterItem filterId={orgsFilter} />
            <FilterItem filterId={paymentSourceFilter} />
            <FilterItem filterId={detailsFilter} />
          </Column>
          <Column className={styles.column}>
            <FilterItem filterId={periodFilter} />
            <FilterItem filterId={mailFilter} />
          </Column>
        </FiltersPlainLayout>
      </RowLayoutContent>
    </ReportSectionCSS>
  );
});

export default ReportFilter;
