// @flow
import { useState, useCallback, useEffect } from 'react';
import prop from 'ramda/es/prop';

import { api } from 'app/api';
import { GET_COMPLEX_PHOTO } from 'app/common/api/requests/food/menu/complex_photo';


type Options = {
  orgId: string,
  complexId: string,
  date: string,
}

export default function usePhoto({ orgId, complexId, date }: Options, updatePhotoKey: any): [
  string,
  ((string => string) | string) => void
] {
  const state = useState<string>('');
  const [, setImage] = state;

  const requestPhoto = useCallback(async () => {
    const res = await api.request(GET_COMPLEX_PHOTO, {
      error: `Не удалось получить фотографию для комплекта ${complexId}`,
      params: {
        school_id: orgId,
        complex_id: [complexId],
        date,
      },
    });
    if (res) {
      setImage(prop(complexId, res) || '');
    }
  }, [orgId, complexId, date, setImage]);

  useEffect(() => {
    requestPhoto();
  }, [requestPhoto, updatePhotoKey]);

  return state;
}
