// @flow
import React, { type Node, Fragment, useCallback, memo } from 'react';
import cn from 'classnames/bind';

import { TrSelectable, TdLabel, getPropsHeader } from 'app/common/ui-components/table';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import Picture from 'app/common/ui-next/picture';

import type { RowCell } from './useRowCellsData';
import styles from './Row.scss';


const cx = cn.bind(styles);
const tooltip = 'Продукция добавлена в другую категорию';

type Props = {
  id: string,
  title: string,
  alreadyAdded: boolean,
  checked: boolean,
  disabledItem: boolean,
  disabled: boolean,
  cells: Array<RowCell>,
  onChange: (id: string, chkd: boolean, title: string) => void,
  asRadio?: boolean,
}

// $FlowFixMe
const Row = memo<Props>(({
  id,
  title,
  alreadyAdded,
  checked,
  disabledItem,
  disabled,
  cells,
  onChange,
  asRadio,
}: Props): Node => {
  const getHeaderProps = getPropsHeader();

  const handleClick = useCallback((identificator: string, chk: boolean) => {
    onChange(identificator, chk, title);
  }, [title, onChange]);

  const commonProps = disabledItem ? { tooltip } : {};
  const CheckComponent = asRadio ? PlainRadioButton : PlainCheckbox;

  const htmlFor = `product_${id}`;

  return (
    <TrSelectable
      id={id}
      onClick={handleClick}
      className={cx({ alreadyAdded, currentlyAdded: !alreadyAdded && checked, disabledItem })}
      selected={checked || disabledItem}
      disabled={disabled}
      interlineBorder
      alignTop
    >
      {checkboxProps => (
        <Fragment>
          {cells.map(({ key, value, ...props }) => {
            switch (key) {
              case 'id':
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    className={styles.cellId}
                    {...commonProps}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    <CheckComponent
                      name={id}
                      label={cells[1].value}
                      skipLabel
                      {...checkboxProps}
                      id={htmlFor}
                      disabled={disabledItem || disabled}
                    />
                  </TdLabel>
                );

              case 'photo':
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    {...commonProps}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    {value
                      ? <Picture
                        className={styles.photo}
                        alt="Фото продукции"
                        src={value}
                        width="100px"
                        height="auto"
                      />
                      : ' '}
                  </TdLabel>
                );

              default:
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    {...commonProps}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    {value}
                  </TdLabel>
                );
            }
          })}
        </Fragment>
      )}
    </TrSelectable>
  );
});

export default Row;
