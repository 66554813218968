// @flow
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import lensProp from 'ramda/es/lensProp';
// import set from 'ramda/es/set';
// import keys from 'ramda/es/keys';
// import pipe from 'ramda/es/pipe';
// import rmap from 'ramda/es/map';
// import pickBy from 'ramda/es/pickBy';
// import all from 'ramda/es/all';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import type { HOC } from 'recompose';

import { popup } from 'app/common/actions/popup';
import { sellPointsFetch } from 'app/actions/orgs/menu/sellPoints';
// import { categoriesFetch } from 'app/actions/orgs/menu/categories';
import { kitsEdit } from 'app/actions/orgs/menu/kitsEdit';

import selectProps from './selectProps';


type Props = {
  orgId: string,
  kitId: number,
  date: string,
  returnTo2: string,
};

const enhanceKit2MenuForm: HOC<*, Props> = compose(
  connect(selectProps),
  // kitName: string,
  // sellPoints: Object[],

  withState('current', 'setCurrent', {
    sellPoint: null,
    // category: {},
  }),
  withState('loading', 'setLoading', true),
  withState('saving', 'setSaving', false),

  withRouter,

  withHandlers({
    handleChange: ({ setCurrent }) => (e) => {
      const { name, value } = e.currentTarget;
      setCurrent(old => ({ ...old, [name]: value }));
    },
    // handleCheck: ({ setCurrent }) => (e) => {
    //   const { value, checked } = e.currentTarget;
    //   setCurrent(old => ({
    //     ...old,
    //     category: set(lensProp(value), checked, old.category),
    //   }));
    // },
    handleSubmit: ({
      setSaving,
      dispatch,
      orgId,
      date,
      current,
      kitId,
      history,
      returnTo2,
      sellPoints,
    }) => async (e) => {
      e.preventDefault();
      setSaving(true);
      // const { sellPoint, category } = current;
      const { sellPoint } = current;
      const addList = [kitId];
      // const categories = pipe(
      //   pickBy(val => val),
      //   keys,
      //   rmap(item => parseInt(item, 10))
      // )(category);
      const res = await dispatch(kitsEdit(orgId, {
        date,
        sellPointId: sellPoints.length === 1
          ? sellPoints[0].id
          : sellPoint,
      }, addList));
      if (res) {
        dispatch(popup('Комплект был успешно добавлен в меню'));
        history.push(returnTo2);
      }
      // const res = await Promise.all(categories.map(categoryId =>
      //   dispatch(kitsEdit(orgId, {
      //     date,
      //     sellPointId: sellPoints.length === 1
      //       ? sellPoints[0].id
      //       : sellPoint,
      //     categoryId,
      //   }, addList))
      // ));
      // if (all(a => a)(res)) {
      //   dispatch(popup('Комплект был успешно добавлен в меню'));
      //   history.push(returnTo2);
      // }
      setSaving(false);
    },
  }),

  lifecycle({
    async componentDidMount() {
      // const [res1, res2] = await Promise.all([
      //   this.props.dispatch(sellPointsFetch(this.props.orgId)),
      //   this.props.dispatch(categoriesFetch(this.props.orgId)),
      // ]);
      // if (res1 && res2) {
      //   this.props.setLoading(false);
      // }
      const res = await this.props.dispatch(sellPointsFetch(this.props.orgId));
      if (res) {
        this.props.setLoading(false);
      }
    },
  }),
);

export default enhanceKit2MenuForm;
