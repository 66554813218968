// @flow

/**
 * Статусы справки на питание
 */

export const ACTIVE: 'active' = 'active'; // справка активна (может быть только одна такая)
export const PENDING: 'pending' = 'pending'; // справка в ожидании
export const DELETED: 'deleted' = 'deleted'; // справка удалена
export const FINISHED: 'finished' = 'finished'; // справка завершена
