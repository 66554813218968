import mapObjIndexed from 'ramda/es/mapObjIndexed';

// меняем null на пустую строку во входящих данных
const incommingMapper = (value) => {
  if (typeof value === 'object') {
    return (value === null ? '' : mapObjIndexed(incommingMapper, value));
  }
  return value;
};

export default incommingMapper;
