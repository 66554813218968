// @flow
import type { Request } from '../index';


export type DistrictsParams = {
  city_id: number,
}

export type District = {
  id: number,
  title: string,
}

export type DistrictsResult = {
  [id: string]: District
}

export const GET_DISTRICTS: Request<
  DistrictsParams,
  DistrictsResult,
  void
> = [
  'food/districts',
  'get',
  ['data', 'districts'],
];
