// @flow
import { getStateCurrentValue, type State } from 'app/common/ui-components/filter';

import filterDataFilter from '../filterData';
import { getSourcesDataLengthFromCurrent } from '../filterData/filterDataItems';


const sourceActivity = (state: State) => {
  const current = getStateCurrentValue(filterDataFilter, state);
  const dataLen = getSourcesDataLengthFromCurrent(current);

  return dataLen > 1;
};

export default sourceActivity;
