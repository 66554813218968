import { useMemo } from 'react'
import { Column } from './types'


export function useColumnTitles(currency: string = '₽') {
  const columns = useMemo(() => {
    return {
      [Column.Status]: 'Номер/Статус',
      [Column.Date]: 'Дата',
      [Column.Receiver]: 'Получатель компенсации',
      [Column.Org]: 'Заведение',
      [Column.Value]: `Сумма на\u00A0вывод, ${currency}`,
      [Column.Action]: 'Действие',
    }
  }, [currency])

  return columns
}
