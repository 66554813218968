
import { SELLPOINTS_SUCCESS } from 'app/actions/orgs/menu/sellPoints';

const defaultState = {};

const sellPoints = (state = defaultState, action) => {
  if (action.type === SELLPOINTS_SUCCESS) {
    return { ...action.payload };
  }
  return state;
};

export default sellPoints;
