// @flow
import { useState, useCallback, useMemo } from 'react';
import map from 'ramda/es/map';
import pick from 'ramda/es/pick';
import includes from 'ramda/es/includes';
import assocPath from 'ramda/es/assocPath';
import groupBy from 'ramda/es/groupBy';
import prop from 'ramda/es/prop';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';
import propEq from 'ramda/es/propEq';

import {
  GET_OLD_ORDERS_RESTRICTIONS,
  POST_OLD_ORDERS_RESTRICTIONS,
} from 'app/common/api/requests/food/schools/old_orders_restrictions';

import { api } from 'app/api';

import extractOldOrdersRestrictionsErrors from './extractOldOrdersRestrictionsErrors';
import type { RoleType, PeriodType } from './types';

/**
 *  Настройки ограничений ролей для сотрудников ОУ и ПП.
 */

export type OldOrdersRestrictionsItem = {
  id: number,
  role_type: RoleType,
  role_title: string,
  role_id: number,
  current_period_without_restrictions: boolean, // без ограничений по текущему периоду
  current_period_days: number | null, // ограничения по текущему периоду
  past_period_without_restrictions: boolean, // без ограничений по прошлому периоду
  past_period_days: number | null, // ограничения по прошлому периоду
  allow_submit_orders_from_web?: boolean,
  ignore_time?: boolean,
}

type OldOrdersRestrictions = { [id: string]: OldOrdersRestrictionsItem };

export const defaultOldOrdersRestrictions = {};

const fieldsCanModify = [
  'current_period_without_restrictions',
  'current_period_days',
  'past_period_without_restrictions',
  'past_period_days',
  'allow_submit_orders_from_web',
  'ignore_time',
];

const getActualFieldsByPeriod = (period?: 'past' | 'current') => {
  if (period === 'past') {
    return ['role_id', 'role_type', 'past_period_without_restrictions', 'past_period_days'];
  }
  if (period === 'current') {
    return ['role_id', 'role_type', 'current_period_without_restrictions', 'current_period_days'];
  }
  return ['role_id', 'role_type', ...fieldsCanModify];
};

type ErrorsType = {
  [id: string]: {
    past: string,
    current: string,
  },
}
export type State = {
  supplier: Array<OldOrdersRestrictionsItem>,
  school: Array<OldOrdersRestrictionsItem>,
  modified: boolean,
  errors: ErrorsType,
  loading: boolean,
}

export const defaultState: State = {
  supplier: [],
  school: [],
  modified: false,
  errors: {},
  loading: true,
};

export type ApiHandlers = {
  request: () => void | Promise<void>,
  submit: (
    overrideOrgIds?: Array<number>,
    roleType?: RoleType,
    period?: PeriodType
  ) => boolean | Promise<boolean>,
  checkErrors: () => boolean,
  checkErrorsByRoleAndPeriod: (roleType?: RoleType, period?: PeriodType) => () => boolean,
}

export type ModifyHandlers = {
  updateField: (number, string, any) => void,
}

type Options = {
  onModify?: (boolean) => void,
}

export default function useOldOrdersRestrictions(orgId: number, options?: Options): [
  State,
  ApiHandlers,
  ModifyHandlers
] {
  const { onModify } = options || {};
  const [modified, setModified] = useState(false);
  const [errors, setErrors] = useState < ErrorsType > ({});

  const [
    oldOrdersRestrictions,
    setOldOrdersRestrictions,
  ] = useState < OldOrdersRestrictions > (defaultOldOrdersRestrictions);
  const [loading, setLoading] = useState(true);


  // запрос данных
  const requestOldOrdersRestrictions = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_OLD_ORDERS_RESTRICTIONS, {
      error: 'Не удалось получить данные прав по работе с заявками',
      requestPathParams: {
        schoolId: orgId,
      },
    });
    setLoading(false);

    if (res) {
      setOldOrdersRestrictions(res);
      setModified(false);
      if (onModify) {
        onModify(false);
      }
      setErrors({});
    }
  }, [orgId, onModify, setLoading]);


  // отправка данных
  const submitOldOrdersRestrictions = useCallback(async (overrideOrgIds?: Array<number>, roleType?: RoleType, period?: PeriodType) => {
    const orgIds = overrideOrgIds || [orgId];
    const res = await api.request(POST_OLD_ORDERS_RESTRICTIONS, {
      error: 'Не удалось сохранить данные прав по работе с заявками',
      params: {
        schools_ids: orgIds.map(String),
        restrictions: map(
          pick(getActualFieldsByPeriod(period)),
          roleType
            ? filter(propEq('role_type', roleType), oldOrdersRestrictions)
            : oldOrdersRestrictions,
        ),
      },
    });

    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true;
    }

    if (res) {
      setModified(false);
      if (onModify) {
        onModify(false);
      }
      setErrors({});
      return true;
    }
    return false;
  }, [orgId, onModify, oldOrdersRestrictions]);


  // проверка на ошибки
  const handleCheckErrors = useCallback(() => {
    const extractedErrors = extractOldOrdersRestrictionsErrors(values(oldOrdersRestrictions));
    setErrors(extractedErrors);
    return isEmpty(extractedErrors);
  }, [oldOrdersRestrictions]);

  const checkErrorsByRoleAndPeriod = useCallback((roleType?: RoleType, period?: PeriodType) => () => {
    const extractedErrors = extractOldOrdersRestrictionsErrors(
      values(oldOrdersRestrictions),
      roleType,
      period,
    );
    setErrors(extractedErrors);
    return isEmpty(extractedErrors);
  }, [oldOrdersRestrictions]);


  // изменение данных
  const handleUpdateField = useCallback((id: number, name: string, value: any) => {
    if (includes(name, fieldsCanModify)) {
      setModified(true);
      if (onModify) {
        onModify(true);
      }
      setErrors({});
      setOldOrdersRestrictions(assocPath([id, name], value));
    }
  }, [onModify]);


  const state = useMemo(() => {
    const { supplier = [], school = [] } = groupBy(
      prop('role_type'),
      values(oldOrdersRestrictions),
    ) || {};

    return ({ supplier, school, modified, errors, loading });
  }, [oldOrdersRestrictions, modified, errors, loading]);

  const apiHandlers = useMemo(() => ({
    request: requestOldOrdersRestrictions,
    submit: submitOldOrdersRestrictions,
    checkErrors: handleCheckErrors,
    checkErrorsByRoleAndPeriod,
  }), [
    requestOldOrdersRestrictions,
    submitOldOrdersRestrictions,
    handleCheckErrors,
    checkErrorsByRoleAndPeriod,
  ]);

  const modifyHandlers = useMemo(() => ({
    updateField: handleUpdateField,
  }), [handleUpdateField]);

  return [state, apiHandlers, modifyHandlers];
}
