// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as details } from 'app/components/common/reports/filters/details';
import { flags as org } from 'app/components/common/reports/filters/org';
import { flags as customerType } from 'app/components/common/reports/filters/customerType';


const groupActivity = (state: State) => {
  return (
    details.detailsChecked(state)
    && org.singleOrgSelected(state)
    && (
      customerType.allCustomersSelected(state)
      || customerType.onlyStudentCustomerSelected(state)
      || customerType.onlyEmployeeCustomerSelected(state)
    )
  );
};

export default groupActivity;
