import { Params, Allowances } from 'app/dataTypes/subsidy/allowances'

import type { Request } from '../../../index'


type ParamsAPI = {
  dealer?: boolean
  order_type?: 'cafeteria' | 'camp'
}

type PathParams = {
  orgID: string
}

export const convertParams = ({ orgID, app, used }: Params): [ParamsAPI, PathParams] => {
  return [{
    ...(!used ? { dealer: true } : {}),
    order_type: app,
  }, { orgID }]
}

type ResultAPI = { allowances: Array<string> }

export const convertResult = ({ allowances }: ResultAPI): Allowances => {
  return allowances.map(key => ({ ID: key, title: key, deletable: true }))
}

export const GET_SCHOOL_ALLOWANCES: Request<Params, Allowances, PathParams> = [
  'food/schools/{orgID}/subsidy_orders_allowances',
  'get',
  ['data'],
]
