
// @flow
import * as schoolTypes from 'app/common/constants/schools/types';
import type { Rights } from 'app/common/constants/rights';

import type { Request } from '../../index';


export const orgTypes = schoolTypes;
export type { Rights };

export type OrgsParams = {
  limit?: number,
  offset?: number,
  id?: number,
  include?: ['roles'],
  type?: string,
  search?: string, // поиск по имени организации
  deleted?: boolean,
  city_id?: Array<number>,
  district_id?: Array<number>,
  has_today_menu?: boolean,
} | void

export type OrgItem = {
  id: number,
  type: string, // GET /food/school_types "school", "preschool", "enterprise", "high_school"
  title: string,
  deleted: boolean,
  approved: boolean,
  from: string | null, // "2018-06-13", // дата начала работы
  building_count: number,
  menu_categories_count: number,
  sale_points_count: number,
  role_id?: number, // id роли пользователя в поле roles
  district_id: number,
  city_id: number,
  cafeteria_enabled: boolean,
  food_place_for_guests: boolean,
  today_sales_sum: number,
  month_sales_sum: number,
  school_group_id: number,
}

export const defaultOrgItem: OrgItem = {
  id: 0,
  type: schoolTypes.ENTERPRISE, // меньше всего опций, исчезающие опции выглядят не очень
  title: '',
  deleted: false,
  approved: true,
  from: null,
  building_count: 0,
  menu_categories_count: 0,
  sale_points_count: 0,
  district_id: 0,
  city_id: 0,
  cafeteria_enabled: false,
  food_place_for_guests: false,
  today_sales_sum: 0,
  month_sales_sum: 0,
  school_group_id: 0,
};

export type RoleItem = {
  id: number,
  title: string,
  available_for_part_time_job: boolean,
  for_all_schools: boolean,
  rights: Rights,
}

export type OrgsSchools = {
  [id: string]: OrgItem
}

export type UserRoles = {
  [id: string]: RoleItem
}

export type OrgsResult = {
  result: 'success',
  schools: OrgsSchools,
  roles?: UserRoles,
  count: number,
}


// теперь это организации, но запрос остался старый
export const GET_ORGS: Request<
  OrgsParams,
  OrgsResult,
  void
> = [
  'food/schools',
  'get',
  ['data'],
];
