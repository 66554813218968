import styles from './InProgress.scss'


type Props = {
  amount: number,
}

export const InProgress = ({ amount = 100 }: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <div>{`${amount}%`}</div>
      </div>
      <div className={styles.line}>
        <div
          className={styles.lineColored}
          style={{ width: `${amount}%` }}
        />
      </div>
    </div>
  )
}
