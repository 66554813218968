// @flow
import React from 'react';
import classNames from 'classnames';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import renderComponent from 'recompose/renderComponent';
import mapProps from 'recompose/mapProps';
import compose from 'recompose/compose';

import ImageClickable from 'app/common/ui/ImageClickable';
import ImagePrepare from 'app/common/components/ImagePrepare';
import ImagePlaceholder from 'app/common/icons/ImagePlaceholder';

import styles from './ImageSelector.scss';


const Image = compose(
  branch(({ photo }) => !photo, renderNothing),
  mapProps(({ photo, className, ...props }) => ({
    ...props,
    src: photo,
    className: classNames(className, styles.image),
  })),
)('img');

const enhance = branch(
  ({ locked, showPlaceholder }) => (locked && !showPlaceholder),
  renderComponent(Image),
);

type Props = {
  onSaveImage: (e: SyntheticEvent<HTMLElement>) => void,
  onClickDelete: (e: SyntheticEvent<HTMLElement>) => void,
  photo?: string,
  showPlaceholder?: boolean,
  showExtensions?: boolean,
  linkText?: string,
  className?: string,
  locked?: boolean,
};

const ImageSelector = enhance(({
  onSaveImage,
  onClickDelete,
  photo,
  showPlaceholder,
  showExtensions,
  linkText,
  className,
  locked,
}: Props) => (<ImagePrepare
  onSubmit={onSaveImage}
  rectMinSizeWidth={100}
  rectMinSizeHeight={133}
  rectProportion={0.75}
>
  {
    handleFileOpen => (
      <div className={className}>
        {!photo
          ? <div className={styles.empty}>
            {showPlaceholder
                && <div className={styles.placeholder}>
                  <ImagePlaceholder />
                </div>}
            {!locked
                && <a href="add" onClick={handleFileOpen} className={styles.link}>
                  {linkText || '+ Добавить фото'}
                </a>}
            {showExtensions
                && !locked
                && <div className={styles.extensions}>
                  {'.jpeg, .png, .gif, .tiff'}
                </div>}
          </div>
          : <ImageClickable
            src={photo}
            className={styles.photo}
            onReplace={handleFileOpen}
            onDelete={onClickDelete}
          />}
      </div>
    )
  }
</ImagePrepare>));

export default ImageSelector;
