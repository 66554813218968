import { useCallback } from 'react'

import { api } from 'app/api'
import { GET_REPORTS_STATUS, convertParams, convertResult } from 'app/common/api/requests/reports/status/get'


export function useReportCheckStatus(reportID: string) {
  const checkStatus = useCallback(async () => {
    const res = await api.request(GET_REPORTS_STATUS, {
      error: 'Не удалось получить статус генерации файла',
      params: { reportID },
      convertions: {
        convertParams,
        convertResult,
      },
    })

    return res
  }, [reportID])

  return checkStatus
}
