import { Statement } from 'app/dataTypes/compensation/statement'
import { ChangeStatusParams, StatementStatusMap } from 'app/dataTypes/compensation/statement/status'

import { Column } from '../columns'
import { Status } from './Status'
import { Actions } from './Actions'


type Props = {
  statement: Statement
  columnKey: Column
  onChangeStatus: (params: ChangeStatusParams) => Promise<boolean>
  disabled?: boolean
  statuses: StatementStatusMap
}

export const Cell = ({
  statement,
  columnKey,
  onChangeStatus,
  disabled,
  statuses,
}: Props) => {
  const {
    ID,
    statusID,
    date,
    value,
    parentFullname,
    studentFullname,
    classTitle,
    schoolTitle,
  } = statement

  if (columnKey === Column.Status) {
    return (
      <Status
        ID={ID}
        statusTitle={statuses.get(statusID)?.title || 'Неизвестный статус'}
      />
    )
  }

  if (columnKey === Column.Date) {
    return <div>{date.getString('DD.MM.YYYY')}</div>
  }

  if (columnKey === Column.Receiver) {
    return (
      <div>
        <div>{parentFullname}</div>
        <div>{`(${studentFullname})`}</div>
      </div>
    )
  }

  if (columnKey === Column.Org) {
    return <div>{[schoolTitle, classTitle].join(', ')}</div>
  }

  if (columnKey === Column.Value) {
    return <div>{value}</div>
  }

  if (columnKey === Column.Action) {
    return (
      <Actions
        ID={ID}
        statusID={statusID}
        onChangeStatus={onChangeStatus}
        disabled={disabled}
      />
    )
  }

  return <div>{'—'}</div>
}
