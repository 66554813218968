// @flow
import { type Node, useMemo } from 'react';
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import fromPairs from 'ramda/es/fromPairs';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import isEmpty from 'ramda/es/isEmpty';
import complement from 'ramda/es/complement';
import anyPass from 'ramda/es/anyPass';
import isNil from 'ramda/es/isNil';

import { useFilterApplied, type StateByFilterId } from 'app/common/ui-components/filter';
import type { Params } from 'app/dataTypes/OrgSettingsChanges.types';

import type { FilterParams } from './FIlterParams.type';
import periodFilter from './period';
import sourceFilter from './source';
import actorFilter from './actor';


const convert = {
  from: path([periodFilter, 0]),
  to: path([periodFilter, 1]),
  sourceId: path([sourceFilter, 0]),
  actorId: path([actorFilter, 0]),
};

const createConverter = (source: StateByFilterId) => {
  return pipe(
    map(func => func(source)),
    filter(complement(anyPass([isNil, isEmpty]))),
  );
};


type Props = {
  children: (Params) => Node,
  ...FilterParams
}

const HistoryFilterParams = ({ children, ...others }: Props) => {
  const applied = useFilterApplied();

  const params: Params = useMemo(() => {
    if (!applied) {
      return { ...others };
    }
    // $FlowFixMe
    const converter = createConverter(fromPairs(applied));
    return {
      ...others,
      ...converter(convert),
    };
  }, [applied, others]);

  return (
    children(params)
  );
};

export default HistoryFilterParams;
