// @flow
import { useCallback } from 'react';
import { toPairs, groupBy, prop, map, filter, find, propEq, isEmpty, unnest } from 'ramda';

import { PUT_VENDING_ITEMS_CATEGORIES, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/categories/put';
import { api } from 'app/api';
import type { Categories, Category } from 'app/dataTypes/vending/production/Categories.types';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';


const getCategoryTitle = (categoryId: string, categories: Categories): string => (
  (find(propEq('id', categoryId), categories) || { title: '???' }).title
);

export default function useMove(
  dishes: Array<Dish>,
  onSuccess: (category: Array<Category>) => void,
  categories: Categories,
): (categoryTarget: string) => Promise<boolean> {
  const moveDishes = useCallback(async (categoryTarget: string) => {
    const pairsByCategoryId = toPairs<Array<Dish>, { [key: string]: Array<Dish> }>(
      groupBy(prop('categoryId'), dishes),
    );

    const resAll = await Promise.all(
      unnest(
        pairsByCategoryId.map(([categoryId, categoryDishes]) => [
          api.request(PUT_VENDING_ITEMS_CATEGORIES, {
            error: `Не удалось переместить продукцию из категории «${getCategoryTitle(categoryId, categories)}» в категорию «${getCategoryTitle(categoryTarget, categories)}» (удаление)`,
            params: {
              categoryId,
              removeFoodItems: map(prop('foodItemId'), categoryDishes),
            },
            convertions: {
              convertParams,
              convertResult,
            },
          }),
          api.request(PUT_VENDING_ITEMS_CATEGORIES, {
            error: `Не удалось переместить продукцию из категории «${getCategoryTitle(categoryId, categories)}» в категорию «${getCategoryTitle(categoryTarget, categories)}»`,
            params: {
              categoryId: categoryTarget,
              addFoodItems: map(prop('foodItemId'), categoryDishes),
            },
            convertions: {
              convertParams,
              convertResult,
            },
          }),
        ]),
      ),
    );

    // $FlowFixMe
    const res: Array<Category> = filter(Boolean, resAll);

    if (!isEmpty(res)) {
      onSuccess(res);
      return true;
    }
    return false;
  }, [dishes, onSuccess, categories]);

  return moveDishes;
}
