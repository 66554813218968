// @flow
// удаление последнего элемента пути
export const replaceLast = (str: string): string => (
  str && typeof str === 'string' && str.replace(/\/[^/]+\/?$/, '')
) || '';
// удаление последнего слэша пути, если есть
export const replaceLastSlash = (str: string): string => (
  str && typeof str === 'string' && str.replace(/\/$/, '')
) || '';
// удаление последних n элементов пути
export const replaceLastN = (str: string, n: number): string => {
  if (!str || typeof str !== 'string') return '';
  const regex = new RegExp(`(\/[^/]+\/?){${n || 1}}$`); // eslint-disable-line
  return str.replace(regex, '');
};
