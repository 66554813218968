// @flow
import React from 'react';

import PlainInputDate from 'app/common/ui-next/plain-input-date';

import styles from './label-with-date.scss';


export const datePlaceholder = '%date%';
export const hasDatePlaceholder = (label: string) => new RegExp(datePlaceholder).test(label);

type Props = {
  disabled?: boolean,
  label: string,
  date: string,
  dateFormat: string,
  onChange: (date: string) => void,
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean,
}

const LabelWithDate = ({
  disabled,
  label,
  date,
  dateFormat,
  onChange,
  dateDisabledStatus,
}: Props) => {
  const splitted = label.split(datePlaceholder);
  return (
    <span>
      <span>{splitted[0]}</span>
      <PlainInputDate
        id="date"
        value={date}
        className={styles.input}
        onDateChange={onChange}
        dateFormat={dateFormat}
        disabled={disabled}
        dateDisabledStatus={dateDisabledStatus}
      />
      <span>{splitted[1]}</span>
    </span>
  );
};

export default LabelWithDate;
