// @flow
import PlainInputDate, { type PlainInputDateProps } from './plain-input-date';
import { dateFormat } from './date-format';
import type { FormatValues } from './formatValues';


export default PlainInputDate;

export {
  dateFormat,
};

export type { PlainInputDateProps, FormatValues };
