// @flow
import React from 'react';

import failIllustration from 'app/common/images-runtime/fail-read-file-illustration.svg';
import Modal from 'app/common/components/Modal';
import PlainButton from 'app/common/ui-next/plain-button';

import { useImportXLSData } from '../ImportXLSData';
import styles from './ReadFileFailed.scss';

/**
 *  Окно иллюстрирующее ошибку чтения файла при импорте
 */

const ReadFileFailed = () => {
  const dealerPhone = localStorage.getItem('phone') || '';
  const { content: { fileName, fileFormatInvalid }, modify } = useImportXLSData();

  return (
    <Modal isOpen={fileFormatInvalid} className={styles.root}>
      <article className={styles.info}>
        <h2 className={styles.header}>
          {'Файл '}
          <span className={styles.filename}>{fileName || 'неизвестный'}</span>
          {' не удалось прочитать'}
        </h2>
        <p>
          {'Загрузите другой файл или обратитесь в техническую поддержку '}
          <br />
          {dealerPhone && <span className={styles.dealerPhone}>{dealerPhone}</span>}
        </p>
        <PlainButton
          className={styles.button}
          onClick={modify.reset}
        >
          {'Загрузить другой файл'}
        </PlainButton>
      </article>
      <img
        className={styles.illustration}
        src={failIllustration}
        alt="Неудачное чтение файла"
      />
    </Modal>
  );
};

export default ReadFileFailed;
