// @flow
import type { Requisites } from 'app/dataTypes/requisites'

import { convertResult, convertParams } from './convertions'

import type { Request } from '../../index'


export { convertResult, convertParams }

export const POST_REQUISITES: Request<Requisites, Requisites, void> = [
  'food/requisites2',
  'post',
  ['data'],
]
