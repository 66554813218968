// @flow
import React, { Children, useCallback, useRef, type Element, type ComponentType, type ChildrenArray } from 'react';
import classNames from 'classnames/bind';
import prop from 'ramda/es/prop';
import { Scrollbars } from 'react-custom-scrollbars-2';

import styles from './plain-list.scss';


const defaultListMaxHeight = 300;
const findFocus = () => document.activeElement;


/**
 *  СПИСОК ЭЛЕМЕНТОВ
 */

const cx = classNames.bind(styles);
export type ChildrenItemProps = { id: string, [string]: any, ... }

export type Props<T: ChildrenItemProps> = {
  id: string,
  children: ChildrenArray<Element<ComponentType<T>>>,
  ariaLabel?: string,
  areaLabelledBy?: string,
  itemClassName?: string,
  noScroll?: boolean,
  scrollPadding?: boolean,
  autoHeight?: boolean,
  maxHeight?: number,
  minHeight?: number,

  onClick?: (targetProps: ChildrenItemProps) => void,
  onOpenToggle?: (id: string) => void,
  onDelete?: (id: string) => void,
  onEdit?: (id: string) => void,
}

function PlainList<T: ChildrenItemProps>({
  id,
  children,
  ariaLabel,
  areaLabelledBy,
  itemClassName,
  noScroll = false,
  scrollPadding = true,
  autoHeight = true,
  maxHeight,
  minHeight,

  onClick,
  onOpenToggle,
  onDelete,
  onEdit,
}: Props<T>) {
  const itemsRef = useRef(null);
  const items = Children.toArray(children);

  /**
   *  Перемещение фокуса внутри списка
   */

  const moveFocusInsideList = useCallback((direction: string) => {
    const currentFocus = findFocus();
    const listDOMElement = itemsRef.current;
    if (!listDOMElement) return;
    const nodes = Array.from(listDOMElement.querySelectorAll('li'));
    const number = nodes.length;

    switch (direction) {
      case 'first': {
        nodes[0].focus();
        break;
      }
      case 'next': {
        const whichOne = nodes.indexOf(currentFocus);
        if (whichOne === number - 1) {
          nodes[0].focus();
          break;
        }
        nodes[whichOne + 1].focus();
        break;
      }
      case 'prev': {
        const whichOne = nodes.indexOf(currentFocus);
        if (whichOne === 0) {
          nodes[number - 1].focus();
          break;
        }
        nodes[whichOne - 1].focus();
        break;
      }
      case 'last': {
        nodes[number - 1].focus();
        break;
      }
      default:
    }
  }, []);


  /**
   *  Поступление фокуса
   */

  const handleFocus = useCallback(() => {
    const currentFocus = findFocus();
    if (currentFocus === itemsRef.current) {
      const listDOMElement = itemsRef.current;
      if (!listDOMElement) return;
      const nodes = Array.from(listDOMElement.querySelectorAll('li'));
      nodes[0].focus();
    }
  }, []);

  /**
   *  Обработка события выбора элемента
   */

  const handleClickElement = useCallback(() => {
    const currentFocus = findFocus();

    if (currentFocus && currentFocus.id && onClick) {
      const clickedItemId = currentFocus.id.replace(/^.+_item_/, '');

      const { props: targetProps } = items.find(
        ({ props }) => {
          const itemId = prop('id', props);
          return (String(itemId) === clickedItemId);
        },
      ) || {};
      if (targetProps && typeof targetProps === 'object') {
        // $FlowFixMe
        onClick(targetProps);
      }
    }
  }, [items, onClick]);


  /**
   *  Обработка события открытия/разворачивание элемента
   */

  const handleOpenElement = useCallback(() => {
    const currentFocus = findFocus();

    if (currentFocus && currentFocus.id && onOpenToggle) {
      const focusedItemId = currentFocus.id.replace(/^.+_item_/, '');
      onOpenToggle(focusedItemId);
    }
  }, [onOpenToggle]);


  /**
   *  Обработка события yдаление элемента
   */

  const handleDeleteElement = useCallback(() => {
    const currentFocus = findFocus();

    if (currentFocus && currentFocus.id && onDelete) {
      const focusedItemId = currentFocus.id.replace(/^.+_item_/, '');
      onDelete(focusedItemId);
    }
  }, [onDelete]);


  /**
   *  Обработка события редактирование элемента
   */

  const handleEditElement = useCallback(() => {
    const currentFocus = findFocus();

    if (currentFocus && currentFocus.id && onEdit) {
      const focusedItemId = currentFocus.id.replace(/^.+_item_/, '');
      onEdit(focusedItemId);
    }
  }, [onEdit]);


  /**
   *  Обработка событий с клавиатуры
   */

  const handleDoKeyAction = useCallback((e) => {
    const whichKey = e.key;

    switch (whichKey) {
      case 'Enter':
        handleClickElement();
        break;
      case 'ArrowDown':
        moveFocusInsideList('next');
        break;
      case 'ArrowUp':
        moveFocusInsideList('prev');
        break;
      case 'ArrowRight':
      case ' ':
        handleOpenElement();
        break;
      case 'Backspace':
        handleDeleteElement();
        break;
      case 'ArrowLeft':
        handleEditElement();
        break;
      default:
    }
  }, [
    handleClickElement,
    moveFocusInsideList,
    handleOpenElement,
    handleDeleteElement,
    handleEditElement,
  ]);


  let firstNotDisabledItemIndex = 0;
  items.find(({ props }) => {
    const disabled = prop('disabled', props);
    if (!disabled) return true;
    firstNotDisabledItemIndex += 1;
    return false;
  });

  const clickable = !!onClick;

  const list = (
    <ul
      id={id}
      className={cx(styles.list, { scrollPadding: scrollPadding && !noScroll })}
      ref={itemsRef}
      role="listbox"
      aria-label={ariaLabel}
      aria-labelledby={areaLabelledBy}
      // tabIndex="0"
      onClick={handleClickElement}
      onKeyUp={handleDoKeyAction}
      onFocus={handleFocus}
    >
      {items.map((comp, i) => {
        const { key, props } = comp;

        const itemId = prop('id', props);
        const disabled = prop('disabled', props);

        if (!itemId && itemId !== 0) return comp;
        const tabIndexByIndex = i === firstNotDisabledItemIndex ? '0' : '-1';

        return (
          <li
            key={key}
            className={cx(styles.listItem, { disabled, clickable }, itemClassName)}
            id={`${id}_item_${itemId}`}
            role="option" // eslint-disable-line jsx-a11y/role-has-required-aria-props
            tabIndex={disabled ? '' : tabIndexByIndex}
          >
            {comp}
          </li>
        );
      })}
    </ul>
  );

  if (noScroll) return list;

  return (
    <Scrollbars
      autoHeight={autoHeight}
      autoHeightMin={minHeight}
      autoHeightMax={maxHeight || defaultListMaxHeight}
    >
      {list}
    </Scrollbars>
  );
}

export default PlainList;
