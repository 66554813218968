// @flow
import ResizeObserver from 'resize-observer-polyfill';
import sum from 'ramda/es/sum';
import take from 'ramda/es/take';
import map from 'ramda/es/map';
import addIndex from 'ramda/es/addIndex';


const mapIndexed = addIndex(map);

class CSSStickyRoot {
  ro: ResizeObserver;

  io: IntersectionObserver | null = null;

  heights: Array<number> = [];

  heightsBottom: Array<number> = [];

  iObservers: Array<IntersectionObserver | null> = [];

  sticked: Array<boolean> = [];

  shiftSkips: Array<boolean> = [];

  nodes: Array<HTMLElement | null> = [];

  onChangeStickyStateCallbacks: Array<(
    node: HTMLElement,
    sticked: boolean,
    nextItemSticked?: boolean
  ) => void | null> = [];


  constructor() {
    this.handleStiсkyIntersect = this.handleStiсkyIntersect.bind(this);
    this.handleStickyResize = this.handleStickyResize.bind(this);
    this.ro = new ResizeObserver(this.handleStickyResize);
  }


  handleStickyResize(entries: Object) {
    entries.forEach(({ contentRect, target }) => {
      const number = parseInt(target.id, 10);
      const { height } = contentRect;
      if (height >= 0 && !Number.isNaN(number)) {
        const index = Math.abs(number) - 1;
        this.nodes[number] = target;
        if (number > 0) {
          this.heights[index] = height;
          const topShift = this.sumActualHeights(index);
          target.style.top = index ? `${topShift}px` : 0; // eslint-disable-line no-param-reassign
          this.addIntersectionObserver(target, number, topShift);
        } else {
          this.heightsBottom[index] = height;
          const bottomShift = sum(take(index, this.heightsBottom));
          target.style.bottom = index ? `${bottomShift}px` : 0; // eslint-disable-line no-param-reassign
          this.addIntersectionObserver(target, number, bottomShift);
        }
      }
    });
  }


  handleStiсkyIntersect(entries: Array<Object>) {
    const entrie = entries[0];
    const sticked: boolean = entrie.intersectionRatio < 1;

    const { target } = entrie;
    const number = parseInt(target.id, 10);
    this.sticked[number] = sticked;
    // обход всех callbacks, чтоб учесть наличие прилипания низлежащих элементов для тени
    for (let i = number; i !== 0; i = number > 0 ? i - 1 : i + 1) {
      if (this.onChangeStickyStateCallbacks[i] && this.nodes[i]) {
        this.onChangeStickyStateCallbacks[i](this.nodes[i], this.sticked[i], this.sticked[i + 1]);
      }
    }
  }


  addIntersectionObserver(target: HTMLElement, number: number, shift: number) {
    const intersectionMargin = number > 0
      ? `${-shift - 1}px 0px 0px 0px` // -1 - для того чтоб элемент считался пересекшим край
      : `0px 0px ${-shift - 1}px 0px`;

    const currentObserver = this.iObservers[number - 1];
    if (currentObserver) {
      currentObserver.disconnect();
    }

    const io = new IntersectionObserver(this.handleStiсkyIntersect, {
      threshold: 1,
      rootMargin: intersectionMargin,
    });
    io.observe(target);

    this.iObservers[number - 1] = io;
  }


  observe(node: HTMLElement) {
    this.ro.observe(node);
  }


  addStickyStateHandler(
    number: number,
    onChangeStickyState: (node: HTMLElement, sticked: boolean) => void | null,
  ) {
    this.onChangeStickyStateCallbacks[number] = onChangeStickyState;
  }


  addShiftSkip(number: number) {
    this.shiftSkips[number - 1] = true;
  }


  sumActualHeights(index: number) {
    return sum(mapIndexed(
      (height, i) => (height && !this.shiftSkips[i] ? height : 0),
      take(index, this.heights),
    ));
  }
}

const cssStickyRoot = new CSSStickyRoot();

export default cssStickyRoot;
