// @flow
import api from 'app/actions/api';
import pick from 'ramda/es/pick';
import get from 'app/common/lib/get';

export const KITS_REQUEST = 'orgs/KITS_REQUEST';
export const KITS_SUCCESS = 'orgs/KITS_SUCCESS';
export const KITS_SUCCESS_DELETED = 'orgs/KITS_SUCCESS_DELETED';
export const KITS_FAILURE = 'orgs/KITS_FAILURE';
export const KITS_STATE_CLEAR = 'orgs/KITS_STATE_CLEAR';

/**
* Запрос комплектов для школы
*/

export const kitsRequest = (payload: Object) => ({
  type: KITS_REQUEST,
  payload,
});

export const kitsSuccess = (payload: Object) => ({
  type: KITS_SUCCESS,
  payload,
});

export const kitsSuccessDeleted = (payload: Object) => ({
  type: KITS_SUCCESS_DELETED,
  payload,
});

export const kitsFailure = (payload: Object) => ({
  type: KITS_FAILURE,
  payload,
});

export const kitsStateClear = () => ({
  type: KITS_STATE_CLEAR,
});

type Params = {
  date?: string,
  deleted?: boolean,
  visible?: boolean,
  include_subsidy_orders?: boolean,
}

export const kitsFetch = (orgId: string, params?: Params, isMixed: boolean) => async (dispatch: Function) => {
  dispatch(kitsRequest({ orgId, ...params }));
  try {
    if (isMixed) {
      const [res1, res2] = await Promise.all([
        api(`food/schools/${orgId}/complexes`, 'get', { params: pick(['include_subsidy_orders', 'visible'], params) }),
        api(`food/schools/${orgId}/complexes`, 'get', { params }),
      ]);

      const data = {
        ...get(res1, ['data', 'complexes'], {}),
        ...get(res2, ['data', 'complexes'], {}),
      };

      if (params && params.deleted) {
        dispatch(kitsSuccessDeleted(data));
        return;
      }
      dispatch(kitsSuccess(data));
    } else {
      const res = await api(`food/schools/${orgId}/complexes`, 'get', { params });
      // удаленные только в isMixed
      dispatch(kitsSuccess(get(res, ['data', 'complexes'], {})));
    }
  } catch (error) {
    dispatch(kitsFailure({ err: 'Запрос комплектов блюд школы не удался', error }));
  }
};
