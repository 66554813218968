import { useCallback } from 'react'
import cn from 'classnames/bind'

import { Table, Tr, Th, Td, ThProps } from 'app/common/ui-components/table'
import { HeaderItem } from 'app/dataTypes/reports/data'
import { useCurrencySign } from 'app/common/components-next/currency'
import { OrgItemType, TotalItemType } from 'app/dataTypes/reports/data/finances/emoneySales'

import { MultilineCell } from './MultilineCell'
import styles from './MainTable.scss'


const cx = cn.bind(styles)

type Props = {
  className?: string
  sameRowClassBase?: string
  headers?: Array<HeaderItem>
  orgs?: Array<OrgItemType>
  total?: Array<TotalItemType>
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
}

const MainTable = ({
  className,
  sameRowClassBase = 'row_',
  headers,
  orgs,
  total,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const currency = useCurrencySign()
  const middlePosition = !!orgs

  const thProps = { scope: 'col', bgFilled: true } as Partial<ThProps>
  const thPropsSub = { ...thProps, alignRight: true }

  const shiftClassName = (hasLeftBorder: boolean) => cx(styles.column, { hasLeftBorder })

  const renderValue = useCallback((val: string | null | void) => (
    !val ? '—' : `${val} ${currency}`
  ), [currency])

  return (
    <Table className={className}>
      {headers
        && <thead>
          <Tr header hidden={middlePosition}>
            {headers.map(({ ID, date }, i) => (
              <Th
                {...thPropsSub}
                className={shiftClassName(i !== 0)}
                key={ID}
                ignoreFirstChild
              >
                {date.getStringFormatted('DD.MM')}
              </Th>
            ))}
          </Tr>
        </thead>}

      {orgs
        && <tbody>
          {orgs.map(({ ID, data }, rowIndex) => (
            <Tr
              key={ID}
              className={`${sameRowClassBase}${rowIndex}`}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              interlineBorder
            >
              {data.map(({
                ID: dataID,
                purchasesSum,
                corrsSum,
                totalSum,
                sSum,
                uRetSum,
                transferSum,
              }, colIndex) => (
                <Td
                  key={dataID}
                  ignoreFirstChild={colIndex === 0}
                  className={shiftClassName(colIndex !== 0)}
                  alignRight
                >
                  <MultilineCell>
                    <span>{renderValue(purchasesSum)}</span>
                    <span>{renderValue(corrsSum)}</span>
                    <span>{renderValue(totalSum)}</span>
                    <span>{renderValue(sSum)}</span>
                    <span>{renderValue(uRetSum)}</span>
                    <span>{renderValue(transferSum)}</span>
                  </MultilineCell>
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>}

      {total
        && <tfoot>
          <Tr>
            {total.map(({ ID, salesSum, transferSum }, colIndex) => (
              <Td
                className={cx(styles.summary, shiftClassName(colIndex !== 0))}
                key={ID}
                ignoreFirstChild
                alignRight
                bgFilled
              >
                <MultilineCell>
                  <span>{renderValue(salesSum)}</span>
                  <span>{renderValue(transferSum)}</span>
                </MultilineCell>
              </Td>
            ))}
          </Tr>
        </tfoot>}
    </Table>
  )
}

export default MainTable
