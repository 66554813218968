// @flow
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';


/**
 *  Надстройка над состоянием для изменения его с задержкой пользовательского ввода
 */

type Options = {
  minLength?: number,
}

export default function useDebouncedChange(
  onSearch: (value: string) => void, // вызывается через 500ms после остановки ввода
  options?: Options,
) {
  const { minLength } = options || {};
  const [value, setValue] = useState<string>('');

  const handleSearch = useCallback(() => {
    if (minLength && value.length < minLength) {
      onSearch('');
      return;
    }
    onSearch(value);
  }, [onSearch, value, minLength]);

  useDebounce(handleSearch, 500, [value]);

  return [value, setValue];
}
