// @flow
import { useCallback } from 'react';

import { api } from 'app/api';
import { POST_PLANORGAM_SLOTS, convertParams, convertResult } from 'app/common/api/requests/data/vending/machines/slots/post';
import { PlanogramDataExt, PlanogramItem } from 'app/common/ui-components/planograms';


export default function usePlanorgamSubmit<T: PlanogramDataExt<PlanogramItem>>(
  machineId: ?string,
  onSuccess: ((T => T) | T) => void,
): (Array<PlanogramItem>) => Promise<void> {
  const submit = useCallback(async (data: Array<PlanogramItem>) => {
    if (!machineId) return;
    const res = await api.request(POST_PLANORGAM_SLOTS, {
      error: 'Не удалось сохранить изменения в планограмме',
      params: {
        machineId,
        data: data.map(item => item.raw),
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      onSuccess(prevPlanogram => prevPlanogram.duplicate(res));
    }
  }, [machineId, onSuccess]);

  return submit;
}
