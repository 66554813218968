// @flow

class ShopUserToken {
  setToken(token: string) { // eslint-disable-line class-methods-use-this
    localStorage.setItem('shopUser', token);
  }

  getToken() { // eslint-disable-line class-methods-use-this
    return localStorage.getItem('shopUser');
  }
}

export default new ShopUserToken();
