// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const customerFound = (state: State) => {
  const [customerId] = getStateCurrentValue(filterId, state) || [];
  return !!customerId;
};

export const foundCustomerHasType = (state: State) => {
  const [, customerType] = getStateCurrentValue(filterId, state) || [];
  return !!customerType;
};
