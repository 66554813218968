import { useCallback, useMemo, ChangeEvent, ReactNode, forwardRef, useImperativeHandle, useRef, ElementRef } from 'react'
import { values, isEmpty, includes, toLower, filter, keys, prop, map, sortBy, pipe } from 'ramda'

import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow'
import EmptyWarn from 'app/common/layouts/EmptyWarn'
import PlainInputSearch from 'app/common/ui-next/plain-input-search'
import useSearch from 'app/common/hooks/useSearch'

import PlainButton from '../plain-button'
import ControlsBar from '../controls-bar'
import useItemsModalState from './use-items-modal-state'
import PlainItemsModalCheckbox from './plain-items-modal-checkbox'
import { ItemType } from './types'

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from '../plain-modal'

import styles from './plain-items-modal.scss'


/**
 *  Модальное окно с выбором из списка
 */

const prefix = 'fo_'
export type PlainItemsModalRefHandle = { changeModalDisplayed: HandleModalDisplayed }

type Props = {
  formID?: string
  multiple: boolean
  header: string
  emptyListMessage?: string
  itemsSelected: Array<string>
  items: Array<ItemType>
  selectedAllOnEmptySelection?: boolean
  children: (x: HandleModalDisplayed) => ReactNode
  onSubmit: (values: Array<string>) => void
}

const PlainItemsModal = forwardRef<PlainItemsModalRefHandle, Props>(({
  formID,
  multiple,
  header,
  emptyListMessage,
  itemsSelected,
  items,
  selectedAllOnEmptySelection,
  children,
  onSubmit,
}, ref) => {
  const [selected, { toggle, update }] = useItemsModalState({ multiple })
  const [searchString, handleSearch, handleClearSearch] = useSearch()

  const selectedAll = items.length === keys(selected).length

  const handleSubmit = useCallback(() => {
    onSubmit(
      selectedAllOnEmptySelection && selectedAll
        ? []
        : values(selected),
    )
    return true
  }, [selected, onSubmit, selectedAll, selectedAllOnEmptySelection])

  const handleChangeCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    const clearReg = new RegExp(`^${prefix}`)
    const someID = value.replace(clearReg, '')
    toggle(someID)
  }, [toggle])

  const handleChangeCheckboxAll = useCallback(() => {
    if (selectedAll) {
      update([])
      return
    }
    update(map(prop('id'), items))
  }, [update, items, selectedAll])

  const handleModalVisible = useCallback((/* visible: boolean */) => {
    update(itemsSelected)
  }, [update, itemsSelected])


  const filterItems = useCallback((items_: Array<ItemType>) => {
    if (!searchString) {
      return items_
    }
    const searchNormalised = toLower(searchString)
    return filter(({ title }) => {
      const titleNormalised = toLower(title)
      return includes(searchNormalised, titleNormalised)
    }, items_)
  }, [searchString])

  const itemsFilteredAndSorted = useMemo(() => pipe(
    filterItems,
    sortBy(prop('sortable')),
  )(items), [items, filterItems])

  const nothing = isEmpty(itemsFilteredAndSorted)

  const modalRef = useRef<ElementRef<typeof PlainModal>>(null)
  useImperativeHandle(ref, () => ({
    changeModalDisplayed: (value) => {
      modalRef.current?.changeModalDisplayed(value)
    },
  }), [])

  return (
    <PlainModal
      formId={formID}
      controlBy={children}
      onSubmit={handleSubmit}
      onDisplayStatusChange={handleModalVisible}
      className={styles.root}
      ref={modalRef}
    >
      <PlainModalHeader>
        {header}
      </PlainModalHeader>

      <PlainModalContent unscrollable>
        <PlainInputSearch
          className={styles.search}
          clearSearch={handleClearSearch}
          onChange={handleSearch}
          value={searchString}
          ContainerComponent="div"
        />
      </PlainModalContent>

      {multiple
        && <PlainModalContent unscrollable>
          <PlainItemsModalCheckbox
            name="all"
            radio={!multiple}
            label="Выбрать все"
            value="all"
            onChange={handleChangeCheckboxAll}
            checked={selectedAll}
          />
        </PlainModalContent>}

      <PlainModalContent paddingFree unscrollable>
        <ScrollbarsWShadow
          autoHeight
          autoHeightMin={100}
          autoHeightMax={210}
        >
          {!nothing
            ? itemsFilteredAndSorted.map(({ id, title, leftShiftLevel = 0, disabled }) => {
              const elemId = `${prefix}${id}`

              return (
                <PlainItemsModalCheckbox
                  key={id}
                  name="items"
                  radio={!multiple}
                  label={title}
                  value={elemId}
                  disabled={disabled}
                  className={styles.checkbox}
                  onChange={handleChangeCheckbox}
                  level={leftShiftLevel}
                  checked={Boolean(selected[id])}
                />)
            })
            : <EmptyWarn>
              {emptyListMessage || 'Нет записей, удовлетворяющих условиям'}
            </EmptyWarn>}

        </ScrollbarsWShadow>
      </PlainModalContent>

      <PlainModalFooter>
        {handleModalDisplayed => (
          <ControlsBar middle>
            <PlainButton
              onClick={handleModalDisplayed}
              outline
            >
              {'Отмена'}
            </PlainButton>
            <PlainButton
              type="submit"
            >
              {'Выбрать'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
})

export default PlainItemsModal
