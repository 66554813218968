// @flow
import React, { useMemo } from 'react';

import { FilterDataProvider } from 'app/common/ui-components/filter';

import OrgsOrdersProductionMain from './OrgsOrdersProductionMain';
import filterItems from './filter/filterItems';
import useFoodModeFlag from '../useFoodModeFlag';


type Props = {
  orgId: number,
  baseUrl: string,
}

const OrgsOrdersProduction = ({ orgId, baseUrl }: Props) => {
  const foodModeEnable = useFoodModeFlag(orgId);

  const filterParams = useMemo(() => ({
    orgId,
  }), [orgId]);


  return (
    <FilterDataProvider
      filters={filterItems}
      params={filterParams}
    >
      <OrgsOrdersProductionMain
        orgId={orgId}
        foodModeEnable={foodModeEnable}
        baseUrl={baseUrl}
      />
    </FilterDataProvider>
  );
};

export default OrgsOrdersProduction;
