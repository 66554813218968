// @flow
import React, { type Node } from 'react';

import withSearch from 'app/common/containers/withSearch';
import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useSearch from 'app/common/hooks/useSearch';

import ModalExtended from './ModalExtended';
import styles from './ModalExtendedWSearch.scss';


type Props = {
  children?: (string) => Node | Node,
  close?: (e: SyntheticEvent<HTMLElement>) => void,
  closePath?: string,
  isOpen?: boolean,
  className?: string,
  header: string,
  extra?: Node,
}

const ModalExtendedWSearch = ({
  children,
  close,
  closePath,
  isOpen,
  className,
  header,
  extra,
}: Props) => {
  const [searchString, handleSearch, handleClearSearch] = useSearch();

  return (
    <ModalExtended
      isOpen={isOpen}
      close={close}
      className={className}
      closePath={closePath}
      header={header}
      extra={[
        <PlainInputSearch
          placeholder="Поиск"
          className={styles.search}
          handleClearSearch={handleClearSearch}
          handleSearch={handleSearch}
          searchString={searchString}
        />,
        extra,
      ]}
    >
      {typeof children === 'function'
        ? children(searchString)
        : children}
    </ModalExtended>
  );
};

export default withSearch(ModalExtendedWSearch);
