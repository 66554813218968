// @flow
import React, { Fragment, type Node } from 'react';

import CSSSticky from 'app/common/ui-components/css-sticky';
import { useVendingMachineSingle } from 'app/dataProviders/VendingDataProvider';

import { sections, getSectionTitle } from './sections';
import SectionEmpty from './SectionEmpty';
import GeneralSection from './GeneralSection/GeneralSection';
import PlanogramSection from './PlanogramSection/PlanogramSection';
import StorageSection from './StorageSection/StorageSection';
import ProductionSection from './ProductionSection/ProductionSection';
import Navigation from './Navigation';

import styles from './VendingMachineMain.scss';


type Props = {
  baseUrl: string,
  currentSection: string,
  currentCategoryId?: string,
  loading: boolean,
  parentUrl?: string,
  redirectToParent: () => void,
  redirectToCategory: (id: string, history?: boolean) => void,
}

const VendingMachineMain = ({
  baseUrl,
  currentSection,
  currentCategoryId,
  loading,
  parentUrl,
  redirectToParent,
  redirectToCategory,
}: Props): Node => {
  const props = {
    loading,
    header: getSectionTitle(currentSection),
  };

  const [{ id: machineIdRaw }] = useVendingMachineSingle();
  const machineId = machineIdRaw ? String(machineIdRaw) : null;

  return (
    <Fragment>
      <CSSSticky number={2} className={styles.stickableMenu}>
        <Navigation
          parentUrl={parentUrl}
          baseUrl={baseUrl}
        />
      </CSSSticky>

      {currentSection === sections.GENERAL
        && <GeneralSection
          onDeleteSuccess={redirectToParent}
          {...props}
        />}

      {currentSection === sections.PLANOGRAM
        && <PlanogramSection
          machineId={machineId}
          {...props}
        />}

      {currentSection === sections.STORAGE
        && <StorageSection
          machineId={machineId}
          {...props}
        />}

      {currentSection === sections.PRODUCTION
        && <ProductionSection
          currentCategoryId={currentCategoryId}
          redirectToCategory={redirectToCategory}
          {...props}
        />}

      {currentSection !== sections.GENERAL
        && currentSection !== sections.PLANOGRAM
        && currentSection !== sections.PRODUCTION
        && currentSection !== sections.STORAGE
        && <SectionEmpty header={getSectionTitle(currentSection)} />}

    </Fragment>
  );
};

export default VendingMachineMain;
