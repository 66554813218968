import { useState } from 'react'

import { Space } from 'app/common/templates-next/report-template'

import { Filters } from './filters'
import { Result } from './result'


export const EmoneySales = () => {
  const [reportID, setReportID] = useState<null | string>(null)

  return (
    <>
      <Filters onReportID={setReportID} />
      <Space />
      <Result reportID={reportID} resetReport={() => setReportID(null)} />
    </>
  )
}
