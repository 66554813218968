import { ReactNode } from 'react'

import ControlsBar, { ControlsBarSpace } from 'app/common/ui-next/controls-bar'


type Props = {
  orgTypesNavigation: ReactNode
  children: ReactNode
}

const OrgGroupsToolbar = ({ orgTypesNavigation, children }: Props) => {
  return (
    <ControlsBar>
      {orgTypesNavigation}
      <ControlsBarSpace />
      {children}
    </ControlsBar>
  )
}

export default OrgGroupsToolbar
