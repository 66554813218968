
// @flow
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import filter from 'ramda/es/filter';

import { popup } from 'app/common/actions/popup';
import { api } from 'app/api';
import { menuNeedUpdate } from 'app/actions/orgs/menu/options';

import {
  type CopyMenuJob,
  POST_COPY_MENU_JOBS,
} from 'app/common/api/requests/food/menu/copy_menu_between_schools';

import type { Checks } from '../CopyMenuFromToOrgs';


const nonEmptyFields = (value: any) => (value !== null && value !== undefined);
const formId = 'copyFrom';

type Options = {
  onClose: (void) => void,
  onSubmitting?: (status: boolean) => void,
  orgIdFrom: ?number,
  currentDate: string | null,
  orgId: number,
  date: string,
  optionsChecks: Checks,
  sellPointIdTo: number | null,
  sellPointIdFrom: number | null,
  buildingIdTo: number | null,
  buildingIdFrom: number | null,
  validate: (any) => boolean,
}

const useHandles = ({
  orgIdFrom,
  currentDate,
  orgId,
  date,
  onClose,
  optionsChecks,
  onSubmitting,
  sellPointIdTo,
  sellPointIdFrom,
  buildingIdTo,
  buildingIdFrom,
  validate,
}: Options) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const handleSubmit = useCallback(async (e: SyntheticInputEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id } = e.target;
    // та ли форма
    if (id !== formId) {
      return;
    }

    if (!orgIdFrom) return;
    if (!validate({ currentDate })) return;

    const copyMenuJob: CopyMenuJob = filter(nonEmptyFields, {
      school_id_from: orgIdFrom,
      school_id_to: orgId,
      date_from: currentDate,
      date_to: date,
      from_building_id: buildingIdFrom,
      to_building_id: buildingIdTo,
      from_sale_point_id: sellPointIdFrom,
      to_sale_point_id: sellPointIdTo,
      items: optionsChecks.copyitems,
      complexes: optionsChecks.copykits,
      copy_settings: optionsChecks.copysettings,
    });

    setSaving(true);
    if (onSubmitting) {
      onSubmitting(true);
    }

    const res = await api.request(POST_COPY_MENU_JOBS, {
      error: 'Не удалось скопировать меню',
      params: { jobs: [copyMenuJob] },
    });

    setSaving(false);
    if (onSubmitting) {
      onSubmitting(false);
    }

    if (res) {
      dispatch(popup('Меню было успешно скопировано'));
      dispatch(menuNeedUpdate(true));
      onClose();
    }
  }, [
    dispatch,
    orgIdFrom,
    currentDate,
    date,
    onClose,
    orgId,
    optionsChecks,
    onSubmitting,
    sellPointIdTo,
    sellPointIdFrom,
    buildingIdTo,
    buildingIdFrom,
    validate,
  ]);

  return {
    handleSubmit,
    saving,
    formId,
  };
};

export default useHandles;
