// @flow
import { useState, useCallback } from 'react';
import F from 'ramda/es/F';

import { api } from 'app/api';
import { POST_VENDING_MACHINES } from 'app/common/api/requests/data/vending/machines/post';
import { PUT_VENDING_MACHINES } from 'app/common/api/requests/data/vending/machines/put';
import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';

import api2machineState from './api2machineState';


export type MachineSubmit = [boolean, () => boolean | Promise<boolean>]
export const defaultMachineSubmit: MachineSubmit = [false, F];

export default function useMachineSubmit(
  machine: MachineSingle,
  validate: () => boolean,
  onEditSuccess?: (MachineSingle) => void,
): MachineSubmit {
  const { id } = machine;
  const [submitting, setSubmitting] = useState(false);


  const createNew = useCallback(async () => {
    const { title, number, modelId, orgId, versionId } = machine;
    const res = await api.request(POST_VENDING_MACHINES, {
      error: 'Не удалось добавить новый аппарат',
      params: {
        title,
        number,
        model_id: modelId,
        school_id: orgId,
        version_id: versionId,
      },
    });
    return !!res;
  }, [machine]);


  const edit = useCallback(async () => {
    const { title, number, modelId, orgId, versionId } = machine;

    const res = await api.request(PUT_VENDING_MACHINES, {
      error: 'Не удалось обновить данные аппарата',
      params: {
        title,
        number,
        model_id: modelId,
        school_id: orgId,
        version_id: versionId,
      },
      requestPathParams: {
        machineId: id,
      },
    });

    if (res && onEditSuccess) {
      onEditSuccess(api2machineState(res));
    }

    return !!res;
  }, [machine, id, onEditSuccess]);


  const submit = useCallback(async () => {
    if (!validate()) return false;
    setSubmitting(true);
    const res = await (id ? edit() : createNew());
    setSubmitting(false);
    return res;
  }, [id, createNew, edit, validate]);

  return [submitting, submit];
}
