// @flow
import { useRef, useEffect, type ElementRef } from 'react';


export type Opt = {
  // расстояния от края иконки до края календаря справа и вверху
  dx?: number,
  dy?: number,
  // допуск на сколько можно выйти календарю за нижний край
  admission?: number,
  // приязка по горизонтали
  align?: 'left' | 'right'
}

const useSizing = (isOpen: boolean, options: Opt = {}) => {
  const { dx = 0, dy = 0, admission = 30, align = 'left' } = options;

  const rootRef = useRef<ElementRef<'a'> | null>(null);
  const calendarRef = useRef<ElementRef<'div'> | null>(null);

  // ------------------------------------
  // Процесс открытия/закрытия
  // ____________________________________
  useEffect(() => {
    // если закрытие, то не нужно
    if (!isOpen) return;

    const {
      top: rootTop,
      bottom: rootBottom,
      right: rootRight,
      left: rootLeft,
    } = rootRef.current ? rootRef.current.getBoundingClientRect() : {};

    const {
      left: calendarLeft,
      top: calendarTop,
      right: calendarRight,
      bottom: calendarBottom,
    } = calendarRef.current ? calendarRef.current.getBoundingClientRect() : {};

    const calendarWidth = calendarRight - calendarLeft;
    const calendarHeight = calendarBottom - calendarTop;

    if (calendarRef.current) {
      if (align === 'right') {
        calendarRef.current.style.left = `${rootRight - calendarWidth + dx}px`; // eslint-disable-line no-mixed-operators
      } else {
        calendarRef.current.style.left = `${rootLeft - dx}px`; // eslint-disable-line no-mixed-operators
      }
    }

    // проверка, умещается ли в видимую область
    if (!document.documentElement) return;
    if (rootTop + calendarHeight - admission < document.documentElement.clientHeight) { // eslint-disable-line no-mixed-operators
      if (calendarRef.current) {
        calendarRef.current.style.top = `${rootTop - dy}px`;
      }
    } else if (calendarRef.current) {
      // не умещается, выпадение вверх
      calendarRef.current.style.top = `${rootBottom - calendarHeight + dy}px`; // eslint-disable-line no-mixed-operators
    }

    // возвращаем видимость
    if (calendarRef.current) {
      calendarRef.current.style.opacity = '1';
    }
  }, [isOpen, dx, dy, admission, align]);

  return { rootRef, calendarRef };
};

export default useSizing;
