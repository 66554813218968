import { useEffect } from 'react'

import { useDataBoxSelector, useDataBoxStatus } from 'app/common/data-box-store'

import { generalSettings } from '../dataBox'


export function useGeneralSettings() {
  const data = useDataBoxSelector(generalSettings, () => generalSettings.data)
  const loading = useDataBoxStatus(generalSettings, () => generalSettings.pending)

  useEffect(() => {
    generalSettings.dataReceive()
  }, [])

  return {
    generalSettings: data,
    loading,
  }
}
