import { useEffect } from 'react'
import classNames from 'classnames/bind'

import PlainBadge from '../plain-badge'
import styles from './plain-selector-static.scss'


const cx = classNames.bind(styles)

type Props = {
  className?: string
  classNameLabel?: string
  classNameBadges?: string
  label: string
  id: string
  title: string
  colorInverse?: boolean
  disabled?: boolean
  skipUpperSpace?: boolean
  onChange?: (values: Array<string>) => void
}

const PlainSelectorStatic = ({
  className,
  classNameLabel,
  classNameBadges,
  label,
  id,
  title,
  colorInverse,
  disabled,
  skipUpperSpace,
  onChange,
}: Props) => {
  useEffect(() => {
    if (onChange) {
      onChange([id])
    }
  }, [id, onChange])

  return (
    <div className={cx(styles.root, { skipUpperSpace }, className)}>
      <fieldset className={styles.fieldset}>
        <legend className={cx(styles.label, classNameLabel)}>{label}</legend>
        <div className={classNameBadges}>
          <PlainBadge
            light={colorInverse}
            id={id}
            title={title}
            disabled={disabled}
          />
        </div>
      </fieldset>
    </div>
  )
}

export default PlainSelectorStatic
