import type { GeneralSettings } from 'app/dataTypes/org/settings/GeneralSettings'


export const defaultGeneralSettings: GeneralSettings = {
  allow_cash_sales: false,
  allow_bank_card_sales: false,
  allow_guess_sales: false,
  cafeteria_enabled: false,
  cafeteria_settings_readonly_for_schools: false,
  enable_subsidies_for_orders: false,
  enable_subsidies_for_single_sales: false,
  enabled_allow_cash_sales: false,
  food_modes: [],
  free_cafe_order_day: false,
  order_targets_enabled: false,
  use_food_mode: false,
  use_pin: false,
  one_order_per_day_mode: false,
  allow_edit_cafeteria_order_items_with_supplier_confirmation: false,
  disable_past_days_purchases: false,
  disable_edit_reject_cafeteria_subsidies_period: false,
  prevent_change_settings: true,
  allow_parents_to_participate_in_orders: false,
  allow_students_to_participate_in_orders: false,
  camp_subsidy_orders_use_presets: false,
  camp_subsidy_orders_preset_values: [],
  camp_enabled: false,
  disable_orders_for_5_to_11_parallels: true,
  use_qr_instead_of_barcode: true,
  order_second_step_auto_select: true,
  count_personal_sales_with_complex_as_complex_sales: false,
  disable_desktop_orders: true,
  display_date_time_and_place_of_printing_in_receipts: false,
}
