import React from 'react';

const IconCheck = props => (<svg viewBox="0 0 18 18" width="18px" height="18px" {...props}>
  <path
    fill="#6B6B6B"
    d="M17.687 1.001c-0.363-0.292-0.894-0.235-1.187 0.128L7.615 12.153L1.278 8.436 C0.959 8.249 0.541 8.287 0.262 8.555c-0.339 0.325-0.35 0.862-0.025 1.201l6.566 6.841c0.123 0.127 0.272 0.245 0.434 0.338 c0.919 0.522 2.087 0.201 2.609-0.718l0.056-0.097L17.89 2.075C18.092 1.722 18.013 1.265 17.687 1.001z"
  />
</svg>);

export default IconCheck;
