import { ReactNode, useCallback } from 'react'

import { PageTemplateContent, Header2, Loading } from 'app/common/templates-next/page-template'
import Sticky from 'app/common/templates-next/sticky'
import { OrgType } from 'app/dataTypes/org/types'
import { useOrgGroupsWithActions } from 'app/dataProviders/org/orgGroup/hooks'
import PlainButton from 'app/common/ui-next/plain-button'
import { useOrgsData } from 'app/dataProviders/org/hooks'

import useCallbackRef from 'app/common/hooks/useCallbackRef'
import OrgGroupsToolbar from './OrgGroupsToolbar'
import useErrors from './editOrgGroup/useErrors'
import AddOrgGroupModal from './editOrgGroup/AddOrgGroupModal'
import OrgGroups from './OrgGroups'
import OrgList from './OrgList'
import GroupHeaderToolbar from './GroupHeaderToolbar'
import { useOrgsByOrgGroupID } from './useOrgsByOrgGroupID'


type Props = {
  orgType?: OrgType
  disabled?: boolean
  orgTypesNavigation: ReactNode
}

const SectionOrgGroups = ({ orgType, disabled, orgTypesNavigation }: Props) => {
  const { orgs, loading: orgsLoading, request: requestOrgs } = useOrgsData({ orgType })
  const requestOrgsRef = useCallbackRef(requestOrgs)
  const orgsByOrgGroupID = useOrgsByOrgGroupID(orgs.items)

  const handleSuccessDeleteGroup = useCallback(() => {
    requestOrgs()
  }, [requestOrgs])

  const { errors, setError, reset: resetErrors } = useErrors()
  const {
    orgGroupsByOrgType,
    loading: orgGroupsLoading,
    submitGroup,
    deleteGroup,
    editGroup,
    locked,
    addOrgsToGroup,
    removeOrgsFromGroup,
  } = useOrgGroupsWithActions(orgType, {
    onError: setError,
    onSuccessDeleteGroup: handleSuccessDeleteGroup,
  })

  const loading = orgGroupsLoading || orgsLoading
  const hasOrgGroups = !!(orgType && orgGroupsByOrgType[orgType]?.size)

  const handleAddRemoveOrgs = useCallback(async (
    orgGroupID: string,
    addOrgs: Array<string>,
    removeOrgs: Array<string>,
  ) => {
    const res1 = await addOrgsToGroup(orgGroupID, addOrgs)
    const res2 = await removeOrgsFromGroup(orgGroupID, removeOrgs)
    if (res1 || res2) requestOrgsRef.current?.()
  }, [addOrgsToGroup, removeOrgsFromGroup, requestOrgsRef])

  const handleRemoveSingleOrg = useCallback(async (orgGroupID: string, orgID: string) => {
    const res = await removeOrgsFromGroup(orgGroupID, [orgID])
    if (res) requestOrgsRef.current?.()
    return res
  }, [removeOrgsFromGroup, requestOrgsRef])

  return (
    <>
      <Sticky>
        {(ref, _, shadow) => (
          <PageTemplateContent horizontalLayout shadowBottom={shadow} reference={ref}>
            <Header2 skipMargin>{'Группы заведений'}</Header2>
          </PageTemplateContent>
        )}
      </Sticky>

      <PageTemplateContent>
        <OrgGroupsToolbar
          orgTypesNavigation={orgTypesNavigation}
        >
          <AddOrgGroupModal
            submit={submitGroup}
            errors={errors}
            resetErrors={resetErrors}
          >
            {toggle => (
              <PlainButton onClick={toggle} disabled={!orgType || disabled}>
                {'Добавить группу'}
              </PlainButton>
            )}
          </AddOrgGroupModal>
        </OrgGroupsToolbar>
      </PageTemplateContent>

      {orgType
        && <PageTemplateContent skipPadding>
          <OrgGroups
            locked={loading || locked || disabled}
            orgGroups={orgGroupsByOrgType[orgType]}
            headerContent={({ ID }) => ( // TODO fix react/no-unstable-nested-components
              <GroupHeaderToolbar
                ID={ID}
                locked={locked.has(ID) || disabled}
                orgsData={orgs}
                orgGroups={orgGroupsByOrgType[orgType]}
                onDeleteGroup={deleteGroup}
                onEditGroup={editGroup}
                onAddRemoveOrgs={handleAddRemoveOrgs}
                errors={errors}
                resetErrors={resetErrors}
              />
            )}
            getOrgsCount={ID => (orgsByOrgGroupID.get(ID)?.length || 0)}
            panelContent={({ ID, updateSize, active }) => ( // TODO fix react/no-unstable-nested-components
              <OrgList
                orgGroupID={ID}
                active={active}
                disabled={disabled}
                onChangeData={updateSize}
                items={orgsByOrgGroupID.get(ID)}
                onRemoveFromList={handleRemoveSingleOrg}
              />
            )}
          />
        </PageTemplateContent>}

      {(!orgType
        || (loading && !hasOrgGroups))
        && <PageTemplateContent>
          <Loading />
        </PageTemplateContent>}
    </>
  )
}

export default SectionOrgGroups
