// @flow
import React, { type Node, Fragment, useMemo } from 'react';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { Summary as SummaryType } from '../types';


const convertToString = (val: number | void, currency: string) => {
  return (typeof val === 'number'
    ? `${toFixedValue(val)} ${currency}`
    : '—'
  );
};

type Props = {
  summary: SummaryType,
  currency: string,
  excludeOVZ?: boolean,
}

const SummarySpecial = ({ summary, currency, excludeOVZ }: Props): Node => {
  const rows = useMemo(() => {
    const {
      purchases_sum: purchasesSum,
      subsidies_sum: subsidiesSum,
      common_subsidies_sum: commonSubsidiesSum, // сумма дотаций по общей справке
      personal_subsidies_sum: personalSubsidiesSum, // сумма дотаций по индивидуальной справке, кроме СОП и ОВЗ
      SOP_subsidies_sum: SOPSubsidiesSum, // сумма дотаций по справке СОП
      OVZ_subsidies_sum: OVZSubsidiesSum, // сумма дотаций по справке ОВЗ
      ukr_subsidies_sum: ukrSubsidiesSum,
      contracts_subsidies_sum: contractsSubsidiesSum,
      mobilized_subsidies_sum: mobilizedSubsidiesSum,
    } = summary;

    const purchasesSumText = convertToString(purchasesSum, currency);
    const commonSubsidiesSumText = convertToString(commonSubsidiesSum, currency);
    const personalSubsidiesSumText = convertToString(personalSubsidiesSum, currency);
    const SOPSubsidiesSumText = convertToString(SOPSubsidiesSum, currency);
    const OVZSubsidiesSumText = convertToString(OVZSubsidiesSum, currency);
    const paidText = convertToString(purchasesSum - subsidiesSum, currency);
    const ukrSubsidiesSumText = convertToString(ukrSubsidiesSum, currency);
    const contractsSubsidiesSumText = convertToString(contractsSubsidiesSum, currency);
    const mobilizedSubsidiesSumText = convertToString(mobilizedSubsidiesSum, currency);

    return [
      // Row 1
      [
        ['Всего средств', purchasesSumText],
        ['По президентской', commonSubsidiesSumText],
        ['Льготный', personalSubsidiesSumText],
      ],
      // Row 2
      [
        ['СОП', SOPSubsidiesSumText],
        ...(excludeOVZ ? [] : [['ОВЗ', OVZSubsidiesSumText]]),
        ['Беженцы из Украины', ukrSubsidiesSumText],
      ],
      // Row 3
      [
        ['Дети контрактников', contractsSubsidiesSumText],
        ['Дети мобилизованных', mobilizedSubsidiesSumText],
        ['Платный', paidText],
      ],
    ];
  }, [summary, currency]);

  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default SummarySpecial;
