/* eslint-disable ft-flow/no-types-missing-file-annotation */
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
// import mapProps from 'recompose/mapProps';
import type { HOC } from 'recompose';
import isNil from 'ramda/es/isNil';
import path from 'ramda/es/path';

import Loader from 'app/common/components/Loader';
import withPaging from 'app/common/containers/withPaging';

import { dishesFetch, dishesFetchSearch, dishesStateClear } from 'app/actions/orgs/dishes';
import selectOrgsDishes from 'app/selectors/OrgsList/selectOrgsDishes';


// -----------------
// Блюда организации (школы)
// -----------------

type Props = {
  searchString?: string,
  orgId: string | number,
  selectOrgsDishes?: (Object, Object) => Object | Array<Object>,
  settings?: Object,
};

const withOrgsDishes: HOC<*, Props> = compose(
  connect(
    (state, props) => {
      const rows = path(['settings', 'rows'], props);
      return ({
        rows: rows || state.settings.orgsDishes.rows,
        searchString: isNil(props.searchString)
          ? state.orgs.dishes.options.searchString
          : props.searchString,
        items: props.selectOrgsDishes
          ? props.selectOrgsDishes(state, props)
          : selectOrgsDishes(state, props),
        total: state.orgs.dishes.options.count,
        loading: state.orgs.dishes.options.loading,
      });
    },
  ),

  withPaging(),

  lifecycle({
    componentDidMount() {
      const { rows, currentPage, searchString } = this.props;
      this.props.dispatch(dishesFetch(this.props.orgId, {
        limit: rows,
        offset: rows * (currentPage - 1),
        ...(searchString ? { search: searchString } : {}),
      }));
    },
    componentDidUpdate(prevProps) {
      const { rows, currentPage, searchString, setCurrentPage } = this.props;
      // сброс страницы на 1, если изменилось количество на странице
      if (rows !== prevProps.rows) {
        setCurrentPage(1);
      }
      // изменение параметров, требуется обновление данных
      if (rows !== prevProps.rows || currentPage !== prevProps.currentPage) {
        this.props.dispatch(dishesFetch(this.props.orgId, {
          limit: rows,
          offset: rows * (currentPage - 1),
          ...(searchString ? { search: searchString } : {}),
        }));
      }
      // отдельно для поиска
      if (searchString !== prevProps.searchString) {
        this.props.dispatch(dishesFetchSearch(this.props.orgId, {
          limit: rows,
          offset: rows * (currentPage - 1),
          ...(searchString ? { search: searchString } : {}),
        }));
      }
    },
    componentWillUnmount() {
      this.props.dispatch(dishesStateClear());
    },
  }),

  branch(
    props => props.loading && !props.items.length,
    renderComponent(Loader),
  ),

  // mapProps(
  //   (props) => {
  //     console.log(props);
  //     return props
  //   }
  // )

);

export default withOrgsDishes;
