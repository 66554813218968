// @flow
import React, { type Node, useEffect, useMemo, useRef } from 'react';

import usePagingExtended from 'app/common/hooks/usePagingExtended';
import type { Params } from 'app/dataTypes/OrgSettingsChanges.types';

import OrgSettingsHistoryContext from './OrgSettingsHistoryContext';
import useChanges from './useChanges';
import useLastChange from './useLastChange';


type Props = {
  children: Node,
  ...Params,
}

const perPage = 10;

const OrgSettingsHistoryProvider = ({
  children,
  orgId,
  from,
  to,
  sourceId,
  actorId,
  section,
}: Props) => {
  const first = useRef(true);
  const lastChange = useLastChange(orgId, section);
  const [, requestLastChange] = lastChange;

  const limit = perPage;
  const paging = usePagingExtended(limit);
  const { offset } = paging;

  const params = useMemo(() => ({
    orgId,
    from,
    to,
    sourceId,
    actorId,
    section,
    offset,
    limit,
  }), [
    orgId,
    from,
    to,
    sourceId,
    actorId,
    section,
    offset,
    limit,
  ]);

  const changes = useChanges(params);
  const [{ count }, { request }] = changes;

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }
    request();
  }, [request]);

  useEffect(() => {
    requestLastChange();
  }, [requestLastChange]);

  const value = useMemo(() => ({
    changes,
    lastChange,
    paging: {
      ...paging,
      total: count,
    },
  }), [changes, lastChange, paging, count]);

  return (
    <OrgSettingsHistoryContext.Provider value={value}>
      {children}
    </OrgSettingsHistoryContext.Provider>
  );
};

export default OrgSettingsHistoryProvider;
