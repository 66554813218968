// @flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './OrgsListTableCellPresence.scss';


const cx = classNames.bind(styles);

type Props = {
  status: string,
}

const OrgsListTableCellPresence = ({ status }: Props) => {
  if (status === 'true' || status === 'false') {
    return (
      <span className={cx({ warn: status === 'false' })}>
        {status === 'true' ? 'Есть' : 'Нет'}
      </span>
    );
  }

  return status;
};

export default OrgsListTableCellPresence;
