// @flow
import React, { Fragment, useCallback } from 'react';
import range from 'ramda/es/range';
import replace from 'ramda/es/replace';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';

import styles from './ParallelsForm.scss';


const allParallels = range(1, 12);

type Props = {
  disable?: boolean,
  parallelsChecked: Array<number>,
  onChange: (key: number, checked: boolean) => void,
}

const ParallelsForm = ({
  disable,
  parallelsChecked,
  onChange,
}: Props) => {
  const handleChangeCheckbox = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    const key = parseInt(replace(/^parallel_/, '', value), 10);
    onChange(key, checked);
  }, [onChange]);


  return (
    <Fragment>
      <label>
        {'Разрешить использовать параллели для общих справок'}
      </label>
      <div className={styles.checks}>
        {allParallels.map((parallel) => {
          const id = `parallel_${String(parallel)}`;

          return (
            <PlainCheckbox
              key={parallel}
              value={id}
              id={id}
              label={String(parallel)}
              checked={parallelsChecked.includes(parallel)}
              disabled={disable}
              onChange={handleChangeCheckbox}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default ParallelsForm;
