import { ChangeEvent, useCallback } from 'react'

import { CompensationInfoStatus } from 'app/dataTypes/compensation/info'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import styles from './Status.scss'


const getStatusDescription = (status: CompensationInfoStatus) => {
  if (status === CompensationInfoStatus.Error)
    return 'Ошибка'
  if (status === CompensationInfoStatus.InProgress)
    return 'Данные расчитываются'
  if (status === CompensationInfoStatus.Ready)
    return 'Данные зафиксированы'
  if (status === CompensationInfoStatus.Pending)
    return 'Загрузка...'
  return 'Данные не зафиксированы'
}

type Props = {
  status: CompensationInfoStatus
  title: string
  handlerID?: string
  orgID: string
  selectionMode: boolean
  selected: boolean
  onClick: (orgID: string, checked: boolean) => void
}

export const Status = ({
  title,
  status,
  handlerID,
  orgID,
  selectionMode,
  selected,
  onClick,
}: Props) => {
  const statusDescription = getStatusDescription(status)

  const onClickRef = useCallbackRef(onClick)

  const handleClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget
    onClickRef.current?.(value, checked)
  }, [onClickRef])

  return (
    <div className={styles.root}>
      {selectionMode
        ? <PlainCheckbox
          id={orgID}
          className={styles.checkbox}
          label={title}
          value={orgID}
          checked={selected}
          onChange={handleClick}
          hideLabel
        />
        : <div className={styles.indicator}>
          <div className={styles.circle} />
        </div>}

      <label className={styles.title} htmlFor={orgID}>{title}</label>

      <div className={styles.status}>
        <div>{statusDescription}</div>
        {handlerID && <div>{`${handlerID}`}</div>}
      </div>
    </div>
  )
}
