import { ReactNode, useCallback, useState } from 'react'
import cn from 'classnames/bind'

import fancyRowStyles from './fancy-row-header.scss'


const cx = cn.bind(fancyRowStyles)

const pauseBeforeLoad = (pause: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, pause)
  })
}

type Props = {
  children: (info: {
    beforeLoadData: (pause?: number) => Promise<void>
    afterLoadData: () => void
    loading: boolean
  }) => ReactNode
  fancy?: boolean
}

export const TrExpandedContent = ({ children, fancy }: Props) => {
  const [loading, setLoading] = useState(true)

  const beforeLoadData = useCallback(async (pause = 300) => {
    setLoading(true)
    await pauseBeforeLoad(pause)
  }, [])

  const afterLoadData = useCallback(() => {
    setLoading(false)
  }, [])

  return (
    <div className={cx({ fancyExpandedContent: fancy })}>
      {children({
        beforeLoadData,
        afterLoadData,
        loading,
      })}
    </div>
  )
}
