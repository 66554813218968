import { FinanceReportType as FReport } from 'app/dataTypes/reports/finances'


const reports = new Map([
  [FReport.EmoneyLegacy, {
    key: FReport.EmoneyLegacy,
    title: 'ЭДС (версия 1.0)',
    description: 'В отчете «ЭДС» можно посмотреть информацию по движению электронных денежных средств покупателей, по сумме полученной на р/с, размерам вознаграждений оператору системы и банку-партнеру. Имеется возможность посмотреть корректировки операций и детализацию по средствам, ожидающим поступления со счетов покупателей, которые имеют задолженность.',
  }],
  [FReport.Emoney, {
    key: FReport.Emoney,
    title: 'ЭДС (версия 2.0)',
    description: 'В отчете «ЭДС» можно посмотреть информацию по движению электронных денежных средств покупателей, по сумме полученной на р/с, размерам вознаграждений оператору системы и банку-партнеру. Имеется возможность посмотреть корректировки операций и детализацию по средствам, ожидающим поступления со счетов покупателей, которые имеют задолженность.',
  }],
  [FReport.BySellType, {
    key: FReport.BySellType,
    title: 'Финансы по типам продаж',
    description: 'В отчете «Финансы по типам продаж» можно посмотреть информацию по суммам списаний со счетов покупателей, с разбивкой на комплексное питание и личные продажи. Имеется возможность посмотреть детализацию всех операций и выгрузить результат в файл в формате excel.',
  }],
  [FReport.CashBox, {
    key: FReport.CashBox,
    title: 'Касса в столовой',
    description: 'В отчете «Касса в столовой» можно посмотреть информацию по наличным средствам, поступающим в кассу столовой при расчете с покупателями или при пополнении лицевого счета пользователей. Имеется возможность посмотреть детализацию всех операций и выгрузить результат в файл в формате excel.',
  }],
  [FReport.EmoneySales, {
    key: FReport.EmoneySales,
    title: 'Продажи — ЭДС',
    description: 'В отчете «Продажи — ЭДС» можно посмотреть сравнение по движению электронных денежных средств покупателей и продаж с детализацией по школам.',
  }],
])

export const reportsArrange = [
  FReport.EmoneyLegacy,
  FReport.Emoney,
  FReport.BySellType,
  FReport.CashBox,
  FReport.EmoneySales,
]

export const getReportTitle = (report: FReport): string => {
  const reportInfo = reports.get(report)
  return reportInfo ? reportInfo.title : 'Неизвестный'
}

export const getReportDescription = (report: FReport): string => {
  const reportInfo = reports.get(report)
  return reportInfo ? reportInfo.description : '…'
}

const titles = {}

reports.forEach(({ title }, key) => {
  titles[key] = title
})

export const getReportTitlesAll = () => titles
