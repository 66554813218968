import { useCallback, useEffect, useState } from 'react'

import {
  GET_COMPENSATION_WITHDRAW_ORDERS_STATUSES,
  convertResult,
} from 'app/common/api/requests/food/compensation/withdraw_orders/statuses/get'

import { api } from 'app/api'
import { StatementStatusMap } from 'app/dataTypes/compensation/statement/status'


type Options = { autoload?: boolean }
const defaultOptions: Options = { autoload: true }

export function useStatementStatuses(options: Options = {}) {
  const { autoload } = { ...defaultOptions, ...options }
  const [statuses, setStatuses] = useState<StatementStatusMap>(new Map())
  const [loading, setLoading] = useState(true)

  const request = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_COMPENSATION_WITHDRAW_ORDERS_STATUSES, {
      error: 'Не удалось получить статусы заявок на вывод компенсации',
      convertions: {
        convertResult,
      },
    })
    setLoading(false)

    if (res) {
      setStatuses(res)
    }
  }, [])

  useEffect(() => {
    if (autoload) {
      request()
    }
  }, [request, autoload])

  return { statuses, loading, request }
}
