import { ReactNode, useCallback, useState } from 'react'

import PlainModal, {
  PlainModalContent,
  PlainModalFooter,
  PlainModalHeader,
  HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal'

import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'

import CategoriesCheckBoxes from './CategoriesCheckBoxes'


type Props = {
  orgID: string
  subsidyType: 'common' | 'personal'
  app: 'camp' | 'cafeteria'
  updateCategories: (ids: Array<string>) => Promise<boolean>
  children: (toggle: HandleModalDisplayed) => ReactNode
}

const AddRemoveCategories = ({ orgID, subsidyType, app, updateCategories, children }: Props) => {
  const [saving, setSaving] = useState(false)

  const handleSubmit = useCallback(async (form: HTMLFormElement) => {
    const formData = new FormData(form)
    const categories = formData.getAll('categories') as Array<string>
    setSaving(true)
    const res = await updateCategories(categories)
    setSaving(false)
    return res
  }, [updateCategories])

  const [loading, setLoading] = useState(true)

  return (
    <PlainModal
      formId="addRemoveCategory"
      controlBy={children}
      onSubmit={handleSubmit}
    >
      <PlainModalHeader>{'Редактирование списка льгот'}</PlainModalHeader>

      <PlainModalContent paddingFree>
        <CategoriesCheckBoxes
          orgID={orgID}
          subsidyType={subsidyType}
          app={app}
          locked={saving}
          setLoading={setLoading}
        />
      </PlainModalContent>

      <PlainModalFooter>
        {toggleModal => (
          <ControlsBar right>
            <PlainButton outline onClick={toggleModal}>
              {'Отмена'}
            </PlainButton>
            <PlainButton type="submit" disabled={loading || saving}>
              {saving
                ? 'Применение...'
                : 'Применить'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}

export default AddRemoveCategories
