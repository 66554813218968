// @flow
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation, Trans } from 'react-i18next';

import Modal from 'app/common/components/Modal';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import Button from 'app/common/ui/Button';
import styles from './Offer.scss';

type OfferType = {
  id?: number,
  title?: string,
  url?: string,
  changes?: string,
  accepted?: boolean,
  created_at?: string,
  updated_at?: string,
};

type Props = {
  doc: OfferType,
  accepted?: boolean,
  accepting?: boolean,
  handleChangeAccepted?: (e: SyntheticEvent<HTMLElement>) => void,
  handleLogout?: (e: SyntheticEvent<HTMLElement>) => void,
  handleAcceptOffer?: (e: SyntheticEvent<HTMLElement>) => void,
  handleClose?: (e: SyntheticEvent<HTMLElement>) => void,
};

const Offer = ({
  doc,
  accepted,
  accepting,
  handleLogout,
  handleChangeAccepted,
  handleAcceptOffer,
  handleClose,
}: Props) => {
  const { t } = useTranslation('about');

  return (
    <Modal close={handleClose}>
      <div className={styles.content}>
        <h1>{doc.title || t('offer_header')}</h1>

        <div>

          <a href={doc.url} target="_blank" rel="noopener noreferrer">
            {t('pdf_link')}
          </a>
          {doc.changes
            && <p>
              {t('changes_title')}
              {':'}
            </p>}

          {doc.changes
            && <Scrollbars style={{ width: '100%', height: 200, display: 'block' }} className={styles.text}>
              <div dangerouslySetInnerHTML={{ __html: doc.changes }} />
            </Scrollbars>}

          {!handleClose
            && <PlainCheckbox
              value="offer_agree"
              label={t('offer_agree')}
              checked={accepted}
              onChange={handleChangeAccepted}
              disabled={accepting}
            />}

        </div>

        {!handleClose
          ? <div className={styles.buttons}>
            <Button
              outline
              onClick={handleLogout}
            >
              <Trans i18nKey="about:disagree">
                {'Не принимаю (выйти из приложения)'}
              </Trans>
            </Button>
            <Button
              disabled={!accepted || accepting}
              onClick={handleAcceptOffer}
            >
              {accepting ? t('privacy_submit_bt_loading') : t('privacy_submit_bt')}
            </Button>
          </div>
          : <div className={styles.buttons}>
            <Button onClick={handleClose}>
              {t('close')}
            </Button>
          </div>}

      </div>
    </Modal>
  );
};

export default Offer;
