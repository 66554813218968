import { useState, useEffect, useCallback, ChangeEvent } from 'react'
import { not } from 'ramda'


export type HandleModalDisplayed = (
  toggle: ((state: boolean) => boolean) | boolean | ChangeEvent<any> | void
) => void

type Options = {
  externalState: boolean
  onClose?: () => void
  onDisplayStatusChange?: (v: boolean) => void
}

export default function useModalDisplayed({
  externalState,
  onClose,
  onDisplayStatusChange,
}: Options) {
  const [modalDisplayed, setModalDisplayed] = useState<boolean>(false)

  const handleModalDisplayed: HandleModalDisplayed = useCallback((param) => {
    if (typeof param === 'boolean' || typeof param === 'function') {
      setModalDisplayed(param)
      return
    }
    setModalDisplayed(not)
  }, [])

  useEffect(() => {
    if (onClose && externalState === false) {
      onClose() // осторожно, может происходить зацикливание
    }
    if (onDisplayStatusChange) {
      onDisplayStatusChange(modalDisplayed)
    }
  }, [modalDisplayed]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setModalDisplayed(externalState)
  }, [externalState])

  return [modalDisplayed, handleModalDisplayed] as const
}
