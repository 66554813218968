import cn from 'classnames'

import IconLoaderClock from 'app/common/icons/IconLoaderClock'

import styles from './loading.scss'


type Props = {
  className?: string,
}

const Loading = ({ className }: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      <IconLoaderClock className={styles.icon} />
    </div>
  )
}

export default Loading
