import { Rights } from 'app/common/constants/rights'

import { type RightsPath, getWriteAccess } from '../types'
import useRightsForOrgData from './useRightsForOrgData'


export default function useWriteAccess<T extends keyof Rights>(rightPath: RightsPath<T>) {
  const [{
    su,
    rights,
    ready,
  }] = useRightsForOrgData()


  return (
    ready
    && (su || getWriteAccess(rightPath, rights))
  )
}
