// @flow
import React, { Fragment, type ElementRef } from 'react';
import classNames from 'classnames/bind';

import { Table, Row, Cell, HeaderRowSortable, HeaderCell } from 'app/common/components/Table';
import Paging from 'app/common/ui/Paging';
import type { SortHandle, SortState } from 'app/common/containers/withSortState';
import conglomerationTableSizes from 'app/common/containers/conglomerationTableSizes';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import enhanceSchools from './enhanceSchools';
import type { SchoolType } from './selectActual';

import ts from '../Table.scss';
import styles from './Schools.scss';


const cx = classNames.bind(ts);

type Settings = {
  schoolTitle: boolean,
  count: boolean,
  pos: boolean,
  neg: boolean,
  negPercents: boolean,
  total: boolean,
}

type Props = {
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  refTd: (el: ElementRef<'td'> | null) => void,
  schools: Array<SchoolType>,
  currentPage: number,
  total: number,
  rows: number,
  sort: SortState,
  handleSort: SortHandle,
  settings: Settings,
  sizes: Object,
}

const Schools = ({
  handlePage,
  refTd,
  schools,
  currentPage,
  total,
  rows,
  sort,
  handleSort,
  settings,
  sizes,
}: Props) => {
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <Fragment>
      <DetailsHeader reportTitle="Остаток средств" />

      {/* ЗАГОЛОВОК */}
      <CSSSticky
        number={2}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <HeaderRowSortable
            className={ts.header}
            onSort={handleSort}
            currentSortKey={sort.sortKey}
            currentSortDirection={sort.sortDirection}
          >
            {settings.schoolTitle
              && <HeaderCell
                className={cx(ts.cell, ts.firstCell)}
                width={sizes.schoolTitle}
                key="schoolTitle"
                sortKey="schoolTitle"
              >
                {'Заведение'}
              </HeaderCell>}
            {settings.count
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.count}
                key="count"
                sortKey="count"
              >
                {'Кол-во человек'}
              </HeaderCell>}
            {settings.pos
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.pos}
                key="pos"
                sortKey="pos"
              >
                {`Положительный баланс${currency}`}
              </HeaderCell>}
            {settings.neg
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.neg}
                key="neg"
                sortKey="neg"
              >
                {`Отрицательный баланс${currency}`}
              </HeaderCell>}
            {settings.negPercents
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.negPercents}
                key="negPercents"
                sortKey="neg"
              >
                {'%\u00A0с\u00A0отриц. балансом'}
              </HeaderCell>}
            {settings.total
              && <HeaderCell
                className={cx(ts.cell, ts.lastCell)}
                alignRight
                width={sizes.total}
                key="total"
                sortKey="total"
              >
                {`ИТОГО${currency}`}
              </HeaderCell>}
          </HeaderRowSortable>
          {/* </Sticky> */}
        </Table>
      </CSSSticky>
      <Table className={styles.table}>
        {schools.map(({
          id,
          schoolTitle,
          count,
          pos,
          neg,
          negHighlight,
          negPercents,
          total: balance,
        }) => (
          <Row key={id + schoolTitle}>
            {settings.schoolTitle
              && <Cell className={cx(ts.cell, ts.firstCell)} width={sizes.schoolTitle}>
                {schoolTitle}
              </Cell>}
            {settings.count
              && <Cell className={ts.cell} alignRight width={sizes.count}>
                {count}
              </Cell>}
            {settings.pos
              && <Cell className={ts.cell} alignRight width={sizes.pos}>
                {pos}
              </Cell>}
            {settings.neg
              && <Cell className={ts.cell} alignRight width={sizes.neg}>
                <span className={cx({ highlight: negHighlight })}>{neg}</span>
              </Cell>}
            {settings.negPercents
              && <Cell className={ts.cell} alignRight width={sizes.negPercents}>
                {negPercents}
              </Cell>}
            {settings.total
              && <Cell className={cx(ts.cell, ts.lastCell)} alignRight width={sizes.total}>
                {balance}
              </Cell>}
          </Row>))}
      </Table>

      {/* Постраичная навигация */}
      {(total > rows)
        && <Paging
          className={ts.pager}
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={schools.length}
          onPage={handlePage}
        />}

      {/* Скрытая таблица для расчета ширины колонок */}
      <table className={ts.hiddenTable}>
        <thead>
          <tr>
            <td className={cx(ts.cell, ts.firstCell)} ref={refTd} name="schoolTitle">
              {'Образовательная организация'}
            </td>
            <td className={ts.cell} ref={refTd} name="count">
              {'Кол-во человек'}
            </td>
            <td className={ts.cell} ref={refTd} name="pos">
              {`Положительный баланс${currency}`}
            </td>
            <td className={ts.cell} ref={refTd} name="neg">
              {`Отрицательный баланс${currency}`}
            </td>
            <td className={ts.cell} ref={refTd} name="negPercents">
              {'%\u00A0с\u00A0отриц. балансом'}
            </td>
            <td className={cx(ts.cell, ts.lastCell)} ref={refTd} name="total">
              {`ИТОГО${currency}`}
            </td>
          </tr>
        </thead>
        <tbody>
          {schools.map(({
            id,
            schoolTitle,
            count,
            pos,
            neg,
            // negHighlight,
            negPercents,
            total: balance,
          }) => (
            <tr key={id + schoolTitle}>
              <td className={cx(ts.cell, ts.firstCell)}>{schoolTitle}</td>
              <td className={ts.cell}>{count}</td>
              <td className={ts.cell}>{pos}</td>
              <td className={ts.cell}>{neg}</td>
              <td className={ts.cell}>{negPercents}</td>
              <td className={cx(ts.cell, ts.lastCell)}>{balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default enhanceSchools(conglomerationTableSizes(Schools));
