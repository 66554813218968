// @flow
import { prop } from 'ramda';

import { filterId } from './filterId';
import type { Sort } from './sortItems';


type AppliedState = {
  [string]: Array<string>,
  ...
}

const getFromApplied = (applied: AppliedState): ?Sort => {
  const [type] = prop(filterId, applied) || [];
  // $FlowFixMe
  return type;
};

export default getFromApplied;
