// @flow
import React, { useEffect, type Node } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import path from 'ramda/es/path';

import CommonPage from 'app/common/templates/CommonPage';
import { currentOrgFetch } from 'app/actions/orgs/current';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { DotsLoader } from 'app/common/components/Loader';
import { RightsForOrgProvider } from 'app/rights/RightsForOrg';
import * as orgsTypes from 'app/common/constants/schools/types';

import Navigation from './Navigation';
import useNavigationAdditionalLinks from './useNavigationAdditionalLinks';
import getParentBaseUrl from '../getBaseUrl';

import styles from './OrgsPageTemplate.scss';

/**
 *  Шаблон страниц раздела с прилипающим меню
 */

const emptyLinks = [];

type Props = {
  children: Node | (type: $Values<typeof orgsTypes>) => Node,
  orgID: string,
  date?: string,
}

const OrgsPageTemplate = ({ children, orgID, date }: Props) => {
  const dispatch = useDispatch();
  const { title: header, deleted, type } = useSelector(
    path(['orgs', 'current', 'schools', orgID]),
    shallowEqual,
  ) || {};
  const loading = useSelector(state => state.orgs.current.loading, shallowEqual);

  const parentBaseUrl = getParentBaseUrl();
  const links = useNavigationAdditionalLinks(parentBaseUrl, type);
  const additionalNavLinks = loading ? emptyLinks : links;

  useEffect(() => {
    if (orgID) {
      dispatch(currentOrgFetch({ id: orgID, 'include[]': 'roles' }));
    }
  }, [orgID, dispatch]);


  return (
    <RightsForOrgProvider orgId={parseInt(orgID, 10)}>
      <CommonPage
        header={header || <DotsLoader simple />}
        headerSuffix={deleted ? ' (удалена)' : ''}
        stickable
      >
        <CSSSticky
          number={2}
          className={styles.stickable}
        >
          <Navigation
            orgID={orgID}
            date={date}
            orgType={type}
            additionalLinks={additionalNavLinks}
          />
        </CSSSticky>
        {typeof children === 'function'
          ? children(type)
          : children}
      </CommonPage>
    </RightsForOrgProvider>
  );
};

export default OrgsPageTemplate;
