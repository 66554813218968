import { useMemo, useRef } from 'react'
import { equals, keys, map } from 'ramda'


export default function useMemoKeys(data: { [key: string]: boolean }) {
  const cache = useRef<Array<string> | null>(null)
  const onlyKeys = useMemo(() => map(String, keys(data)), [data])

  const memoKeys = useMemo(() => {
    if (!cache.current || !equals(onlyKeys, cache.current)) {
      cache.current = onlyKeys
    }
    return cache.current
  }, [onlyKeys])

  return memoKeys
}
