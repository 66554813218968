import { isEmpty } from 'ramda'

import Sticky from 'app/common/templates-next/sticky'
import { useCurrencySign } from 'app/common/components-next/currency'
import { SkeletonLoadingTBody, StatusMessage } from 'app/common/templates-next/page-template'
import { ChangeStatusParams, StatementStatusMap } from 'app/dataTypes/compensation/statement/status'
import { Statement } from 'app/dataTypes/compensation/statement'

import {
  CheckStateProvider,
  fancyRowRootClassName,
  Table,
  // useCopyColumnsWidths,
  useTopShiftState,
} from 'app/common/ui-components/table'

import { TableHeader } from './TableHeader'
import { useColumnProps, useColumnTitles, useColumnsArranged, useHeaderColumnProps } from './columns'
import { TableRow } from './TableRow'


type Props = {
  statuses: StatementStatusMap
  statements: Array<Statement>
  onChangeStatus: (params: ChangeStatusParams) => Promise<boolean>
  loading: boolean
  pendingIDs: Set<string>
}

export const StatementsTable = ({ statuses, statements, onChangeStatus, loading, pendingIDs }: Props) => {
  const currency = useCurrencySign()

  const nothing = isEmpty(statements)
  const columns = useColumnsArranged()
  const titles = useColumnTitles(currency)
  const headerColumnProps = useHeaderColumnProps()
  const columnProps = useColumnProps()

  const [updateRowNode, calcTopShift, nodes] = useTopShiftState()

  return (
    <Table wide>
      <thead>
        <Sticky applyToChildrenTh>
          {(ref, stuck, shadow) => (
            <TableHeader
              ref={ref}
              columns={columns}
              titles={titles}
              stuck={stuck}
              shadow={shadow}
              columnProps={headerColumnProps}
            />
          )}
        </Sticky>
      </thead>

      <tbody className={fancyRowRootClassName}>
        {nothing
          && loading
          && <SkeletonLoadingTBody
            rows={5}
            columns={columns.length}
            onlyRows
          />}

        {nothing
          && !loading
          && <StatusMessage asTableRow colSpan={columns.length}>
            {'Нет записей, удовлетворяющих условиям'}
          </StatusMessage>}

        {!nothing
          && <CheckStateProvider>
            {statements.map((statement, index) => (
              <TableRow
                key={statement.ID}
                columns={columns}
                columnProps={columnProps}
                statement={statement}
                index={index}
                calcTopShift={calcTopShift}
                trElem={nodes[String(index)]}
                updateRowNode={updateRowNode}
                onChangeStatus={onChangeStatus}
                disabled={loading || pendingIDs.has(statement.ID)}
                // innerTableHeaderRef={trTo}
                statuses={statuses}
              />
            ))}
          </CheckStateProvider>}
      </tbody>
    </Table>
  )
}
