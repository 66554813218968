import React from 'react';
import trim from 'ramda/es/trim';

import LineInput from '../../../ui/LineInput';
import Button from '../../../ui/Button';
import container from './container';
import styles from './LoginPass.scss';


type Props = {
  loading: boolean,
  back: (e: SyntheticEvent<HTMLElement>) => void,

  login: string,
  isLoginValid: boolean,
  isLogin: boolean,
  handleLogin: (e: SyntheticEvent<HTMLElement>) => void,
  loginError: string,
  submitLogin: (e: SyntheticEvent<HTMLElement>) => void,

  password: string,
  isPasswordValid: boolean,
  handlePassword: (e: SyntheticEvent<HTMLElement>) => void,
  passwordError: string,
  submitPassword: (e: SyntheticEvent<HTMLElement>) => void,

  t: (s: string) => string,
  exampleFormat: string,
  emailForceAuth: boolean,
};

const LoginPass = ({
  loading,
  back,

  login,
  // isLoginValid,
  isLogin,
  handleLogin,
  loginError,
  submitLogin,

  password,
  isPasswordValid,
  handlePassword,
  passwordError,
  submitPassword,

  t,
  exampleFormat,
  emailForceAuth,
}: Props) => (!isLogin

  ? <form className={styles.form} onSubmit={submitLogin}>
    <LineInput
      name={`login_${(process.env.APP_NAME || process.env.REACT_APP_NAME)}`}
      placeholder={emailForceAuth
        ? t('login:login_label_email_only')
        : t('login:login_label')}
      type="text"
      isError={!!loginError}
      errorText={loginError}
      comment={emailForceAuth
        ? ''
        : `${t('login:login_description')} ${exampleFormat}`}
      onChange={handleLogin}
      value={login}
      autoFocus
      wide
    />
    <Button
      type="submit"
      className={styles.submit}
      disabled={loading || !trim(login)}
    >
      {loading
        ? t('login:login_submit_bt_loading')
        : t('login:login_submit_bt')}
    </Button>
  </form>

  : <form className={styles.form} onSubmit={submitPassword}>
    <a href="back" className={styles.back} onClick={back}>{'←'}</a>
    <LineInput
      name={`password_${(process.env.APP_NAME || process.env.REACT_APP_NAME)}`}
      placeholder={t('login:pass_label')}
      type="password"
      isError={!!passwordError}
      errorText={passwordError}
      onChange={handlePassword}
      value={password}
      autoFocus
    />
    <Button
      type="submit"
      className={styles.submit}
      disabled={!isPasswordValid || loading}
    >
      {loading
        ? t('login:pass_submit_bt_loading')
        : t('login:pass_submit_bt')}
    </Button>
  </form>
);

export default container(LoginPass);
