import withProps from 'recompose/withProps';
import MaskedInput from 'react-maskedinput';
import PlainInput from './PlainInput';


export default withProps({
  component: MaskedInput,
  mask: '111-111-111 11',
  placeholder: '___-___-___ __',
})(PlainInput);
