import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import { withTranslation } from 'react-i18next';

import Warning from '../Warning';
import LoginActivation from './LoginActivation';


const LoginSuccess = compose(
  withState('activate', 'setActivate', false),

  withHandlers({
    handleActivate: ({ setActivate }) => (e) => {
      e.preventDefault();
      setActivate(true);
    },
    handleReturnFromActivation: ({ setActivate }) => (e) => {
      if (e) {
        e.preventDefault();
      }
      setActivate(false);
    },
  }),

  branch(
    ({ activate }) => activate,
    renderComponent(LoginActivation),
  ),

  withTranslation(),

  mapProps(({ handleReturn, handleRestore, watEntered, fields, handleActivate, t, ...props }) => {
    const wat = `${watEntered} ${fields.login}`;
    const infoLocal = t('login:register_confirm_info', { wat });
    return ({
      ...props,
      onBack: handleReturn,
      onAccept: handleActivate,
      header: `${t('login:register_confirm_title')} ${watEntered}`,
      text: infoLocal,
      acceptBtText: t('login:register_confirm_receive_code_bt'),
    });
  }),
)(Warning);

export default LoginSuccess;
