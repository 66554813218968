// @flow
import React, { useMemo, Fragment } from 'react';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { Summary } from './useReportData';


type Props = {
  summary: Summary,
  loading?: boolean,
  currencySign: string,
}

const EmoneySummary = ({ summary, loading, currencySign }: Props) => {
  const rows = useMemo(() => {
    const {
      s_sum: sSum, // Списания ЭДС
      c_sum: cSum, // Сумма для перевода
      u_com_sum: uComSum, // реестр U (вознаграждение оператору)
      u_ret_sum: uRetSum, // реестр U (сумма возвратов)
      u_percents: uPercents, // Вознаграждение оператора, %
      s_skipped_sum: sSkippedSum, // Ожидают списания ЭДС
    } = summary;

    const sSumText = `${toFixedValue(sSum)} ${currencySign}`;
    const cSumText = `${toFixedValue(cSum)} ${currencySign}`;
    const uComSumText = `${toFixedValue(uComSum)} ${currencySign}`;
    const uRetSumText = `${toFixedValue(uRetSum)} ${currencySign}`;
    const uPercentsText = `${toFixedValue(uPercents)} %`;
    const sSkippedSumText = `${toFixedValue(sSkippedSum)} ${currencySign}`;

    return [
      // Row 1
      [
        ['Списания ЭДС', sSumText],
        ['Возвраты ЭДС', uRetSumText],
        ['Вознаграждение оператора', [uComSumText, uPercentsText]],
      ],
      // Row 2
      [
        ['Сумма для перевода', cSumText],
        ['Банк перевел', '—'],
        ['Ожидают списания ЭДС', sSkippedSumText],
      ],
    ];
  }, [summary, currencySign]);


  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
              loading={loading || !summary}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default EmoneySummary;
