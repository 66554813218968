// @flow
import { useCallback } from 'react';
import prop from 'ramda/es/prop';

import { api } from 'app/api';
import usePopup from 'app/common/hooks/usePopup';
import type { ImageParamsType } from 'app/common/components/ImagePrepare/ImagePrepare';
import { POST_COMPLEX_PHOTO_ADD_REMOVE } from 'app/common/api/requests/food/menu/complex_photo_add_remove';


type Actions = {
  saveImage: (ImageParamsType) => Promise<boolean>,
  deleteImage: () => Promise<boolean>,
}

type Options = {
  orgId: string,
  complexId: string,
  date: string,
}

export default function usePhoto(
  { orgId, complexId, date }: Options,
  onPhoto: ((string => string) | string) => void,
): Actions {
  const popup = usePopup();

  const handleSaveImage = useCallback(async (imageParams: ImageParamsType) => {
    const res = await api.request(POST_COMPLEX_PHOTO_ADD_REMOVE, {
      error: 'Не удалось изменить фотографию',
      params: {
        school_id: orgId,
        complex_id: complexId,
        date,
        photo: imageParams,
      },
    });

    if (res) {
      onPhoto(prop(complexId, res) || '');
      popup('Фотография успешно изменена');
      return true;
    }
    return false;
  }, [popup, orgId, complexId, date, onPhoto]);

  const handleDeleteImage = useCallback(async () => {
    const res = await api.request(POST_COMPLEX_PHOTO_ADD_REMOVE, {
      error: 'Не удалось изменить фотографию',
      params: {
        school_id: orgId,
        complex_id: complexId,
        date,
        photo: null,
      },
    });

    if (res) {
      onPhoto(prop(complexId, res) || '');
      popup('Фотография успешно удалена');
      return true;
    }
    return false;
  }, [popup, orgId, complexId, date, onPhoto]);


  return {
    saveImage: handleSaveImage,
    deleteImage: handleDeleteImage,
  };
}
