// @flow
import { createContext } from 'react';

import { defaultPaging, type Paging } from 'app/common/hooks/usePagingExtended';

import { defaultState, defaultActions, type State, type Actions } from './useChanges';
import { type State as LastChangeState, defaultState as defaultLastChangeState } from './useLastChange';


type OrgSettingsHistoryContext = {
  changes: [State, Actions],
  lastChange: LastChangeState,
  paging: Paging,
}

const defaultContext: OrgSettingsHistoryContext = {
  changes: [defaultState, defaultActions],
  lastChange: defaultLastChangeState,
  paging: defaultPaging,
};

export default createContext<OrgSettingsHistoryContext>(defaultContext);
