import { path } from 'ramda'
import { ReactElement, ReactNode, isValidElement } from 'react'


type Path = {
  pathname: string,
  search: string,
  hash: string,
}

type RelativeRoutingType = 'route' | 'path'

export type Props = {
  children: ReactNode
  disabled?: boolean
  active?: boolean
  className?: string
  to: string | Path
  reloadDocument?: boolean
  replace?: boolean
  state?: any
  preventScrollReset?: boolean
  relative?: RelativeRoutingType
}

export const NavItem = ({
  children,
  className,
  disabled, // eslint-disable-line @typescript-eslint/no-unused-vars
  active, // eslint-disable-line @typescript-eslint/no-unused-vars
  to, // eslint-disable-line @typescript-eslint/no-unused-vars
  reloadDocument, // eslint-disable-line @typescript-eslint/no-unused-vars
  replace, // eslint-disable-line @typescript-eslint/no-unused-vars
  state, // eslint-disable-line @typescript-eslint/no-unused-vars
  preventScrollReset, // eslint-disable-line @typescript-eslint/no-unused-vars
  relative, // eslint-disable-line @typescript-eslint/no-unused-vars
}: Props) => {
  return (
    <span className={className}>{children}</span>
  )
}

NavItem.displayName = 'NavItem'

export const isNavItem = (comp: ReactNode): comp is ReactElement<Props, typeof NavItem> => {
  const displayName = path(['type', 'displayName'], comp)
  return isValidElement(comp) && displayName === 'NavItem'
}
