// @flow
import React, { useState, useCallback, useMemo, useContext } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import RowLayout, { Header } from 'app/common/layouts/RowLayout';
import { identifiers as identifierNames } from 'app/common/constants/identifiers';
import * as statuses from 'app/common/constants/identifiers/statuses';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import { DotsLoader } from 'app/common/components/Loader';

import { type IdentifierType, IdentifiersContext } from './identifiers-data';
import IdentifierItem, { IdentifierItemEmpty } from './identifier-item';
import arrangeItemsInsideGroups from './arrange-items-inside-groups';
import styles from './identifiers-group.scss';


const cx = classNames.bind(styles);

/**
 *  Блок идентификаторов одного типа с фильтрацией
 *  по заблокированным и изъятым
 */

type Props = {
  type: $Keys<typeof identifierNames>,
  identifiersByType: Array<IdentifierType>,
  className?: string,
}

const IdentifiersGroup = ({ type, identifiersByType, className }: Props) => {
  const { t } = useTranslation('identifiers-next');

  const [blocked, setBlocked] = useState(false);
  const [withdrawn, setWithdrawn] = useState(false);

  const valueBlocked = `${type}_${statuses.STATUS_BLOCKED}`;
  const valueWithdrawn = `${type}_${statuses.STATUS_WITHDRAWN}`;

  const handleCheckChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (value === valueBlocked) {
      setBlocked(checked);
    }
    if (value === valueWithdrawn) {
      setWithdrawn(checked);
    }
  }, [valueBlocked, valueWithdrawn]);

  const identifiersFiltered = useMemo(
    () => arrangeItemsInsideGroups(identifiersByType, blocked, withdrawn),
    [identifiersByType, blocked, withdrawn],
  );

  const nothingToView = isEmpty(identifiersFiltered);

  const { loading: { identifiers: loading } } = useContext(IdentifiersContext);
  const labelId = `${type}_label`;

  return (
    <RowLayout className={cx(styles.root, { loading }, className)}>
      <Header>
        {identifierNames[type]}
        {loading && <DotsLoader className={styles.loader} simple />}
      </Header>

      <div className={styles.filterBar}>
        <div className={styles.filterLabel} id={labelId}>
          {`${t('filter_switch_label')}:`}
        </div>
        <PlainFieldset
          className={styles.filter}
          ariaLabelledby={labelId}
          name={type}
        >
          <PlainCheckbox
            label={t('filter_switch_blocked')}
            value={valueBlocked}
            checked={blocked}
            onChange={handleCheckChange}
            className={styles.filterItem}
            disabled={loading}
          />
          <PlainCheckbox
            label={t('filter_switch_withdrawn')}
            value={valueWithdrawn}
            checked={withdrawn}
            onChange={handleCheckChange}
            className={styles.filterItem}
            disabled={loading}
          />
        </PlainFieldset>
      </div>

      {nothingToView
        && <IdentifierItemEmpty
          title={t('empty_active')}
          type={type}
        />}

      {identifiersFiltered.map((identifierData) => {
        const { id } = identifierData;
        return (
          <IdentifierItem key={id} identifierData={identifierData} />
        );
      })}
    </RowLayout>
  );
};

export default IdentifiersGroup;
