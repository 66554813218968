// @flow
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';


export type SortState = {
  sortKey: string,
  sortDirection: 'up' | 'down',
}

export type SortHandle = (d: 'up' | 'down', key: string) => void

type OutcomingProps = {
  handleSort: SortHandle,
  sort: SortState,
}

const withSortState = (defaultSortDirection: string, defaultSortKey: string) => compose(
  withState('sort', 'setSort', {
    sortKey: defaultSortKey,
    sortDirection: defaultSortDirection,
  }),

  withHandlers({
    handleSort: ({ setSort }) => (direction, key) => {
      setSort({
        sortKey: key,
        sortDirection: direction,
      });
    },
  }),

  mapProps(({ setSort, ...props }) => ({
    ...props,
  }: OutcomingProps)),
);

export default withSortState;
