import { useCallback } from 'react'

import { api } from 'app/api'
import { POST_REPORT_CANCEL, convertParams, convertResult } from 'app/common/api/requests/reports/cancel/post'


export function useReportCancel(reportID: string) {
  const cancel = useCallback(async () => {
    await api.request(POST_REPORT_CANCEL, {
      error: 'Не удалось отменить генерацию отчета',
      params: { reportID },
      convertions: {
        convertParams,
        convertResult,
      },
    })
  }, [reportID])

  return cancel
}
