// @flow
import isProduction from 'app/common/lib/isProduction';

import NewSticky from './NewSticky';
import CssSticky from './CssSticky';
import withStickyRows from './withStickyRows';

import DebugButton, { stickyType, getStickyType } from './DebugButton';
import { sumTo } from './helpers';


// $FlowFixMe
const supported = () => (CSS.supports('position', 'sticky') ? CssSticky : NewSticky);
const switched = () => (getStickyType() === stickyType.JS
  ? NewSticky
  : supported());

// если production, включаем метод прилипания который поддерживается, иначе который установлен в localStorage (DebugButton)
export default (!isProduction() ? switched() : supported());


export { withStickyRows, DebugButton, sumTo };
