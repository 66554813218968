// @flow
import React, { useState } from 'react';
import classNames from 'classnames';

import RowLayout, { HeaderContent, RowLayoutContent, Header } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { settingsKey } from 'app/reducers/settings/employeesAtWork';

import EmployeesTabs from '../EmployeesTabs';
import * as employeeTypes from '../employeeTypes';
import EmployeesGearAtWork from '../../SettingsGear/EmployeesGearAtWork';
import ActionsBar from '../common/ActionsBar';
import FilterWindow from './EmployeesAtWorkFilter/FilterWindow';
import EAWTable from '../common/EmployeesTable';
import useEAWTableItems from '../common/EmployeesTable/useEAWTableItems';
import useEmployeesData from './useEmployeesData';
import useEmployeesPagination from './useEmployeesPagination';
import useEmployeesDataParams from './useEmployeesDataParams';

import styles from './EmployeesAtWorkMain.scss';


export type Props = {
  type: $Values<typeof employeeTypes>,
  baseUrl: string,
  writeAccess: boolean,
}

const EmployeesAtWorkMain = ({
  type,
  baseUrl,
  writeAccess,
}: Props) => {
  const [filterActive, setFilterActive] = useState(false);

  const [search, setSearch] = useState < string > ('');
  const {
    limit,
    offset,
    currentPage,
    handlePage,
    resetPagination,
    loading: loadingSettings,
  } = useEmployeesPagination();
  const params = useEmployeesDataParams({ limit, offset, search, loading: loadingSettings, workplace: 'school' });

  const [
    { employees, loading, loadingItem, count },
    { updateEmployees, requestEmployees },
  ] = useEmployeesData({ params, onPageReset: resetPagination });

  const items = useEAWTableItems(employees, type);
  const enableHiring = writeAccess;


  return (
    <RowLayout paddingfree>
      <CSSSticky
        number={2}
        className={styles.stickable}
        stickyClassName={styles.sticky}
        shadowClassName={styles.shadow}
      >

        {/* строка заголовка */}
        <HeaderContent
          className={classNames(styles.topBorderOnSticky, styles.headerContainer)}
        >
          <Header>{'Сотрудники'}</Header>
          <EmployeesGearAtWork />
        </HeaderContent>

        {/* строка c табами */}
        <RowLayoutContent paddingfree>
          <EmployeesTabs type={type} baseUrl={baseUrl} />
        </RowLayoutContent>

        {/* строка c кнопкой приема сотрудника, фильтром и поиском */}
        <RowLayoutContent>
          <ActionsBar
            employeeType={type}
            onSearch={setSearch}
            onFilterToggle={setFilterActive}
            filterActive={filterActive}
            enableHiring={enableHiring}
            onSuccess={requestEmployees}
          />
        </RowLayoutContent>
      </CSSSticky>

      {/* Окно с фильтром */}
      <FilterWindow
        onFilter={setFilterActive}
        filterActive={filterActive}
        enableHiring={enableHiring}
        loading={loading}
      />

      <EAWTable
        items={items}
        total={count}
        currentPage={currentPage}
        rowsPerPage={limit}
        baseUrl={baseUrl}
        loading={loading}
        loadingRow={loadingItem}
        onUpdateEmployees={updateEmployees}
        onPageClick={handlePage}
        settingsKey={settingsKey}
        writeAccess={writeAccess}
      />
    </RowLayout>
  );
};

export default EmployeesAtWorkMain;
