import FiltersDropDownLayout from 'app/common/layouts/FiltersDropDownLayout'
import classNames from 'classnames/bind'
import { useCallback, useEffect } from 'react'
import PlainDropdown, { PlainDropdownDefitem } from 'app/common/ui-next/plain-dropdown'
import { shallowEqual, useSelector } from 'react-redux'
import { path } from 'ramda'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import styles from './Filters.scss'
import enhanceFilters from './enhanceFilters'

const cx = classNames.bind(styles)

type SaleType = {
  id: string
  title: string
}

type Props = {
  loading: boolean,
  types: Array<SaleType>,
  handleSelectItem: (selected: any) => void,
  handleClearFilter: () => void,
  handleApplyFilter: () => void,
  filter: any,
  isFilterApplied: boolean,
  filterActive: boolean,
  settingsDataKey: string,
  toggleFilter: () => void,
  setActiveFilter: (status: boolean) => void,
}

const Filters = ({
  loading,
  types,
  handleSelectItem,
  handleClearFilter,
  handleApplyFilter,
  filter,
  isFilterApplied,
  filterActive,
  settingsDataKey,
  toggleFilter,
  setActiveFilter,
}: Props) => {
  const settings: any = useSelector(path(['settings', settingsDataKey]), shallowEqual)
  const onSelecRef = useCallbackRef(handleSelectItem)
  const onClearRef = useCallbackRef(handleClearFilter)
  const onChangeStatusRef = useCallbackRef(setActiveFilter)

  useEffect(() => {
    onChangeStatusRef.current?.(filterActive)
  }, [onChangeStatusRef, filterActive])


  const handleChange = useCallback((selected: any) => {
    onSelecRef.current?.({ saleType: selected.id })
  }, [onSelecRef])

  const handleResetFilter = useCallback(() => {
    onClearRef.current?.()
    onSelecRef.current?.({ saleType: '' })
  }, [onClearRef, onSelecRef])


  if (!settings.saleType) {
    return null
  }

  return (
    <FiltersDropDownLayout
      arrowLeftShift={isFilterApplied ? 230 : 220}
      arrowHide
      onClose={toggleFilter}
      onApplyFilter={handleApplyFilter}
      onResetAll={handleResetFilter}
    >
      <span className={styles.label}>{'Тип продукции:'}</span>
      <PlainDropdown
        className={styles.dropdown}
        placeholder={
          <PlainDropdownDefitem id={filter.id} className={styles.placeholder}>
            <span>
              {filter.saleType?.length ? filter.saleType : 'Выберите тип продукции'}
            </span>
          </PlainDropdownDefitem>
        }
        id="saleType"
        loading={loading}
        onChange={handleChange}
        defaultSkin
      >
        {types?.map((type) => {
          return (
            <PlainDropdownDefitem
              id={type.id}
              key={type.id}
              className={cx(styles.dropdown, styles.item)}
              active={type.id === filter.saleType}
            >
              {type.title}
            </PlainDropdownDefitem>
          )
        })}
      </PlainDropdown>
    </FiltersDropDownLayout>
  )
}

export default enhanceFilters(Filters)
