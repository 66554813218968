import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';


export default compose(

  withState('searchString', 'setSearchString', ''),

  withHandlers({
    handleSearch: ({ setSearchString }) => (e) => {
      setSearchString(e.currentTarget.value);
    },
    handleClearSearch: ({ setSearchString }) => (e) => {
      e.preventDefault();
      setSearchString('');
    },
  }),
);
