// @flow
import type { Request } from '../../index';

import { formats } from '../generate_file';
import { reportTypes } from '../perform';

export { formats, reportTypes };

type HistoryParams = {
  limit?: number,
  offset?: number,
  history_type?: 'all',
  report_type?: $Values<typeof reportTypes>,
  report_format?: $Values<typeof formats>,
}

export type HistoryItem = {
  id: number,
  title: string,
  downloads_count: number,
  created_at: string, // "2017-08-20T14:33:01+03:00",
  status: number, // -1 - если ошибка, иначе процент готовности (0-100)
}

export type HistoryResult = {
  [id: string]: HistoryItem,
}

export const GET_HISTORY: Request<
  HistoryParams,
  HistoryResult,
  void
> = [
    'reports/history',
    'get',
    ['data', 'reports'],
  ];
