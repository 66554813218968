// @flow
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { validatePassword, isValid } from 'app/common/lib/validate/password';
import type { passValidType } from 'app/common/lib/validate/password';


// type Props = {
//   loading: bool,
//   onSubmit: (string) => void,
// };

export default compose(

  withState('newPassword1', 'changeNewPassword1', ''),
  withState('newPassword2', 'changeNewPassword2', ''),

  withHandlers({
    change: ({ changeNewPassword1, changeNewPassword2 }) => (e) => {
      const { name, value } = e.currentTarget;
      switch (name) {
        case 'newpassword1': {
          changeNewPassword1(value);
          break;
        }
        case 'newpassword2': {
          changeNewPassword2(value);
          break;
        }
        default:
      }
    },

    submit: ({ newPassword1, onSubmit }) => (e) => {
      e.preventDefault();
      if (typeof onSubmit === 'function') {
        onSubmit(newPassword1);
      }
    },
  }),


  mapProps(
    ({ newPassword1, newPassword2, change, submit, loading, error, newPassTitle }) => {
      const pass1ValidProps: passValidType = validatePassword(newPassword1);
      const pass1Valid: boolean = isValid(pass1ValidProps);
      const pass2Valid: boolean = (newPassword1 === newPassword2) && isValid(newPassword2);
      return {
        newPassword1,
        newPassword2,
        change,
        pass1ValidProps,
        pass1Valid,
        pass2Valid,
        submit,
        loading,
        error,
        newPassTitle,
      };
    },
  ),
);
