import { useCallback, useEffect } from 'react'

import { useDataBoxSelector, createDataBoxDefault } from 'app/common/data-box-store'

import { PlainCheckboxGroup } from '../../plain-checkbox'


export const DetailsCheckID = 'details'
export const detailsBoxID = 'details'

const items = [{
  id: DetailsCheckID,
  title: 'Детализированный отчет',
}]

const detailsFilter = createDataBoxDefault<boolean>(detailsBoxID, false)
const emptyDetails = []
const filledDetails = [DetailsCheckID]

type Props = {
  id?: string
  disabled?: boolean
  defaultSelection?: boolean
}

const DetailsBox = ({ id = 'details', disabled, defaultSelection }: Props) => {
  const checked = useDataBoxSelector(detailsFilter, () => detailsFilter.data)

  const handleChange = useCallback(([details]: Array<string>) => {
    detailsFilter.updateData(() => !!details)
  }, [])

  useEffect(() => {
    if (defaultSelection) {
      detailsFilter.setDefault(defaultSelection)
      detailsFilter.updateData(() => defaultSelection)
    }
  }, [defaultSelection])

  useEffect(() => () => {
    detailsFilter.reset()
  }, [])

  return (
    <PlainCheckboxGroup
      id={id}
      items={items}
      current={checked ? filledDetails : emptyDetails}
      onValue={handleChange}
      disabled={disabled}
      keepPadding
    />
  )
}

export default DetailsBox
