// @flow
import React, { type Node } from 'react';

import RuleCheckbox from './rule-checkbox';
import styles from './rule-items.scss';


type Props = {
  title: string,
  index: number,
  checkbox?: boolean,
  checked?: boolean,
  onChange?: (checked: boolean, index: number) => void,
}

export const HorizontalRuleItem = ({ index, title, checkbox, checked = false, onChange }: Props): Node => (
  <div className={styles.horizontalRuleItem}>
    {checkbox && onChange
      ? <RuleCheckbox
        index={index}
        label={`Выделить строку ${index + 1}`}
        onChange={onChange}
        checked={checked}
      />
      : title}
  </div>
);

export const VerticalRuleItem = ({ index, title, checkbox, checked = false, onChange }: Props): Node => (
  <div className={styles.verticalRuleItem}>
    {checkbox && onChange
      ? <RuleCheckbox
        index={index}
        label={`Выделить колонку ${index + 1}`}
        onChange={onChange}
        checked={checked}
      />
      : title}
  </div>
);
