import { useState, useCallback, useEffect } from 'react'
import { has } from 'ramda'

import { api } from 'app/api'

import {
  GET_REPORTS_LIST,
  POST_REPORTS_LIST,
  MutableParams,
  defaultMutableParams,
  onlyMutableAndEnabledParams,
  ReportsListResult,
} from 'app/common/api/requests/food/reports_list'


type State = {
  reportsList: MutableParams,
  loading: boolean,
  modified: boolean,
}

type Actions = {
  request: () => Promise<void>,
  submit: () => Promise<boolean>,
  update: (name: string, value: boolean) => void,
}

export default function useReportsList(): [State, Actions] {
  const [reportsList, setReportsList] = useState<MutableParams>(defaultMutableParams)
  const [loading, setLoading] = useState(true)
  const [modified, setModified] = useState(false)


  const handleRequest = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_REPORTS_LIST, {
      error: 'Запрос настроек отчетов не удался',
    })
    setLoading(false)

    if (res) {
      setReportsList(onlyMutableAndEnabledParams(res as ReportsListResult))
      setModified(false)
    }
  }, [])

  useEffect(() => {
    handleRequest()
  }, [handleRequest])


  const handleSubmit = useCallback(async () => {
    const res = await api.request(POST_REPORTS_LIST, {
      error: 'Сохранение настроек отчетов не удалось',
      params: {
        reports: reportsList,
      },
    })

    if (res) {
      setReportsList(onlyMutableAndEnabledParams(res as ReportsListResult))
      setModified(false)
      return true
    }
    return false
  }, [reportsList])


  const handleUpdate = useCallback((name: string, value: boolean | Array<number>) => {
    const optionAvailable = has(name, reportsList)
    if (optionAvailable) {
      setReportsList(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setModified(true)
    }
  }, [reportsList])


  return [{
    reportsList,
    loading,
    modified,
  }, {
    request: handleRequest,
    submit: handleSubmit,
    update: handleUpdate,
  }]
}
