// @flow
import { useEffect } from 'react';

import type { Category } from 'app/dataTypes/vending/production/Categories.types';


type Options = {
  category: Category | null,
  defaultCategoryId: string,
  ready: boolean,
  redirectToCategory: (id: string, history?: boolean) => void,
}

export default function useValidationCurrentCategory({
  category,
  defaultCategoryId,
  ready,
  redirectToCategory,
}: Options) {
  useEffect(() => {
    if (category && category.id === defaultCategoryId) {
      return;
    }
    if (ready && !category) {
      redirectToCategory(defaultCategoryId);
    }
  }, [category, ready, defaultCategoryId]); // eslint-disable-line react-hooks/exhaustive-deps
}
