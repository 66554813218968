// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames';

import { Table, Tr, Th, Td } from 'app/common/ui-components/table';

import type { Multiple } from '../useReportData';
import { columnId, columnHeaderText } from './HeaderRightContent';


type Props = {
  className?: string,
  totalsRows: $PropertyType<Multiple, 'totalsRows'>,
  tbodyRef?: Ref<'tbody'>,
  sameRowBase?: string,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  children: (content: string) => Node,
}

const MiddleRightContent = ({
  className,
  totalsRows,
  tbodyRef,
  sameRowBase,
  onMouseEnter,
  onMouseLeave,
  children,
}: Props): Node => {
  return (
    <Table className={className}>
      <thead>
        <Tr header hidden>
          <Th scope="col">
            {columnHeaderText}
          </Th>
        </Tr>
      </thead>
      <tbody ref={tbodyRef}>
        {totalsRows.map(({ key, value }, i) => (
          <Tr
            key={key}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={cn(sameRowBase && `${sameRowBase}${i}`)}
          >
            <Td headers={columnId} alignRight>
              {children(value)}
            </Td>
          </Tr>
        ))}
      </tbody>
      <tfoot>
        <Tr hidden>
          <Td>&nbsp;</Td>
        </Tr>
      </tfoot>
    </Table>
  );
};

export default MiddleRightContent;
