// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import length from 'ramda/es/length';
import { useSelector, shallowEqual } from 'react-redux';

import {
  Table,
  Tr,
  Th,
  Td,
  useHover,
  useHoverByClass,
} from 'app/common/ui-components/table';

import type { SortHandle, SortState } from 'app/common/containers/withSortState';
import type { TotalType } from 'app/actions/reports/subsidies/data';
import CSSSticky from 'app/common/ui-components/css-sticky';
import HScrollbarsWShadow, { useSyncScrolls } from 'app/common/ui-components/h-scrollbars-w-shadow';
import Paging from 'app/common/ui/Paging';
import * as viewTypesAvailable from 'app/actions/reports/filters/viewTypesAvailable';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import CellContentOverflow from '../../../common/CellContent/CellContentOverflow';
import CellContentModalByClickLink from '../../../common/CellContent/CellContentModalByClickLink';
import enhanceStudents from './enhanceStudents';
import SubsidyInfo from './SubsidyInfo';
import type { StudentType } from './selectActual';

import styles from './Students.scss';


const cx = classNames.bind(styles);
const sameColumnBase = 'column_';


/**
 * Таблица отчета по ученикам
 */

type Props = {
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  students: Array<StudentType>,
  totals: Array<TotalType>,
  currentPage: number,
  total: number,
  rows: number,
  sort: SortState,
  handleSort: SortHandle,
}

const Students = ({
  handlePage,
  students,
  totals,
  currentPage,
  total,
  rows,
  sort: { sortDirection, sortKey },
  handleSort,
}: Props) => {
  const viewType = useSelector(state => state.reports.subsidies.params.viewType, shallowEqual);
  const timeMode = useSelector(state => state.reports.subsidies.data.time_mode, shallowEqual);
  const [refTableBody, onMouseEnter, onMouseLeave] = useHover({ numberOfChunks: 3 });
  const [onCellMouseEnter, onCellMouseLeave] = useHoverByClass(sameColumnBase);

  const [topScrollerProps, middleScrollerProps, bottomScrollerProps] = useSyncScrolls();
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <Fragment>
      <DetailsHeader reportTitle="Дотации" />

      {/* ЗАГОЛОВОК */}
      <CSSSticky
        number={2}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        {/* Видимый заголовок первой таблицы */}
        <Table className={styles.leftFixed}>
          <thead>
            <Tr header>
              <Th
                id="header1"
                className={cx(styles.headerCell, styles.headerCellTitle)}
                sortKey="title"
                sortDirection={sortKey === 'title' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignLeft
              >
                {'ФИО'}
              </Th>
              <Th
                id="header2"
                className={cx(styles.headerCell, styles.headerCellClassTitle)}
                sortKey="classTitle"
                sortDirection={sortKey === 'classTitle' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignRight
              >
                {'Класс'}
              </Th>
              <Th
                id="header3"
                className={cx(styles.headerCell, styles.headerCellOrdersNum)}
                sortKey="ordersNum"
                sortDirection={sortKey === 'ordersNum' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignRight
              >
                {'Спр-ки'}
              </Th>
            </Tr>
          </thead>
        </Table>

        <div className={styles.hScrollableContent}>
          <HScrollbarsWShadow
            hideVerticalScrollBar
            hideHorizontalScrollBar
            absolutelyHideHorizontal_FixFF
            {...topScrollerProps}
          >
            <Table className={styles.middleVariable}>
              <thead>
                <Tr header>
                  {totals.map(({ idx: cellIdx, title }, j) => (
                    <Th
                      key={cellIdx}
                      id={`headerMiddle${j}`}
                      className={cx(styles.headerCell, `${sameColumnBase}${j}`)}
                      onMouseEnter={onCellMouseEnter}
                      onMouseLeave={onCellMouseLeave}
                      alignRight
                    >
                      <CellContentOverflow
                        className={cx(styles.cellContentSum, {
                          wide: timeMode === 'monthly',
                        })}
                      >
                        {title}
                      </CellContentOverflow>
                    </Th>
                  ))}
                </Tr>
              </thead>
            </Table>
          </HScrollbarsWShadow>
        </div>

        <Table className={styles.rightFixed}>
          <thead>
            <Tr header>
              <Th
                id="headerLast"
                className={cx(styles.headerCell, styles.headerCellTotal)}
                sortKey="total"
                sortDirection={sortKey === 'total' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignRight
              >
                {`Всего${currency}`}
              </Th>
            </Tr>
          </thead>
        </Table>
      </CSSSticky>
      {/* кон. заголовка */}

      {/* СЕРЕДИНА */}
      <div className={styles.content}>
        <Table className={styles.leftFixed}>
          <tbody ref={refTableBody}>
            {students.map(({ idx, title, classTitle, ordersNum }, i) => (
              <Tr
                key={idx}
                className={`index_${i}`}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                hoverHighlighted={false}
              >
                <Td headers="header1">
                  <CellContentOverflow className={styles.cellContentTitle}>
                    {title}
                  </CellContentOverflow>
                </Td>
                <Td headers="header2" alignRight>
                  <CellContentOverflow className={styles.cellContentClassTitle}>
                    {classTitle}
                  </CellContentOverflow>
                </Td>
                <Td headers="header3" alignRight>
                  <CellContentModalByClickLink
                    className={styles.cellContentOrdersNum}
                    modalContentFunc={toggleModal => (
                      <SubsidyInfo idx={idx} onClickClose={toggleModal} />
                    )}
                  >
                    {ordersNum}
                  </CellContentModalByClickLink>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>

        <div className={styles.hScrollableContent}>
          <HScrollbarsWShadow
            hideVerticalScrollBar
            hideHorizontalScrollBar
            absolutelyHideHorizontal_FixFF
            {...middleScrollerProps}
          >
            <Table className={styles.middleVariable}>
              <tbody ref={refTableBody}>
                {students.map(({ idx, data }, i) => (
                  <Tr
                    key={idx}
                    className={`index_${i}`}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    hoverHighlighted={false}
                  >
                    {data.map(({ idx: cellIdx, sum, count }, j) => (
                      <Td
                        key={cellIdx}
                        headers={`headerMiddle${j}`}
                        className={`${sameColumnBase}${j}`}
                        onMouseEnter={onCellMouseEnter}
                        onMouseLeave={onCellMouseLeave}
                        alignRight
                      >
                        <CellContentOverflow
                          className={cx(styles.cellContentSum, {
                            tinted: sum === '0.00' || count === '0',
                            wide: timeMode === 'monthly',
                          })}
                        >
                          {viewType === viewTypesAvailable.VIEW_TYPE_SUM ? sum : count}
                        </CellContentOverflow>
                      </Td>
                    ))}
                  </Tr>
                ))}
              </tbody>
            </Table>
          </HScrollbarsWShadow>
        </div>

        <Table className={styles.rightFixed}>
          <tbody ref={refTableBody}>
            {students.map(({ idx, total: ttl }, i) => (
              <Tr
                key={idx}
                className={`index_${i}`}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                hoverHighlighted={false}
              >
                <Td
                  headers="headerLast"
                  alignRight
                >
                  <CellContentOverflow className={styles.cellContentTotal}>
                    {ttl}
                  </CellContentOverflow>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* кон. середины */}

      {/* ПОДВАЛ */}
      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        <div className={styles.footerTableContent}>
          <Table className={styles.leftFixed}>
            <tfoot>
              <Tr hoverHighlighted={false}>
                <Th colSpan={3} id="hTotal" alignRight>{'Итого:'}</Th>
              </Tr>
            </tfoot>
          </Table>

          <div className={styles.hScrollableContent}>
            <HScrollbarsWShadow
              hideVerticalScrollBar
              {...bottomScrollerProps}
            >
              <Table className={styles.middleVariable}>
                <tfoot>
                  <Tr hoverHighlighted={false}>
                    {totals.map(({ idx, sum, count }, j) => (
                      <Td
                        key={idx}
                        headers={`headerMiddle${j} hTotal`}
                        className={cx(styles.hilightedCell, `${sameColumnBase}${j}`)}
                        onMouseEnter={onCellMouseEnter}
                        onMouseLeave={onCellMouseLeave}
                        alignRight
                      >
                        <CellContentOverflow
                          className={cx(styles.cellContentSum, {
                            wide: timeMode === 'monthly',
                          })}
                        >
                          {viewType === viewTypesAvailable.VIEW_TYPE_SUM ? sum : count}
                        </CellContentOverflow>
                      </Td>
                    ))}
                  </Tr>
                </tfoot>
              </Table>
            </HScrollbarsWShadow>
          </div>

          <div className={styles.rightFixed} />
        </div>

        {/* Постарничная навигация */}
        {(total > rows)
          && <Paging
            total={total}
            pageSize={rows}
            currentPage={currentPage}
            currentPageSize={length(students)}
            onPage={handlePage}
          />}

      </CSSSticky>
      {/* кон. подвала */}

    </Fragment>
  );
};

export default enhanceStudents(Students);
