// @flow
import * as React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import { getRoot } from 'app/common/components/Modal/Modal';
import ModalExtendedHeader from './ModalExtendedHeader';
import styles from './ModalExtended.scss';


type Props = {
  children: any,
  header?: any,
  extra?: any,
  close?: (e: SyntheticEvent<HTMLElement>) => void,
  className?: string,
  isFooter?: boolean, // depricated
  footerHeight?: number,
  footer?: any, // устаревшее, можно использовать flex для заполнения всего окна по вертикали
  isOpen?: boolean,
  overlayClassName?: string,
  closePath?: string,
};

const ModalExtended = ({
  close,
  children,
  header,
  extra,
  className,
  isFooter,
  footerHeight,
  footer,
  isOpen,
  overlayClassName,
  closePath,
}: Props) => (<ReactModal
  isOpen={isOpen}
  parentSelector={getRoot}
  className={classNames(styles.modal, className)}
  overlayClassName={classNames(styles.root, overlayClassName)}
  contentLabel="Modal"
>
  <div>
    <ModalExtendedHeader
      header={header}
      onIconCrossClick={close}
      className={styles.headerBolck}
      closePath={closePath}
      extra={extra}
    />
    <div className={styles.content} style={isFooter ? { bottom: footerHeight || 71 } : null}>
      {children}
    </div>
    {/* depricated */}
    {isFooter
        && <div className={styles.footer} style={{ height: footerHeight || 71 }}>
          {typeof footer === 'function' ? footer() : footer}
        </div>}
    {/* depricated */}
  </div>
</ReactModal>);

ModalExtended.defaultProps = {
  isOpen: true,
};

export default ModalExtended;
