// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import path from 'ramda/es/path';

import { api } from 'app/api';
import usePopupErr from 'app/common/hooks/usePopupErr';

import { GET_VENDING_MACHINES } from 'app/common/api/requests/data/vending/machines/get';
import { GET_VENDING_SETTINGS } from 'app/common/api/requests/data/vending/settings.get';

import * as vendingStatuses from './vendingStatuses';
import spotStatus from './spotStatus';


export type VendingStatus = [$Values<typeof vendingStatuses>, boolean]
export const defaultVendingStatus: VendingStatus = [vendingStatuses.VENDING_DISABLED, true];

export default function useVendingStatus(): VendingStatus {
  const [popupErr] = usePopupErr();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(vendingStatuses.VENDING_ENABLED);

  const requestVendingAvailability = useCallback(async () => {
    const res = await api.request(GET_VENDING_SETTINGS, {
      error: 'Не удалось получить настройки вендинга',
    });

    if (res) {
      const enabled = path(['enabled'], res);
      return (typeof enabled === 'boolean' ? enabled : null);
    }
    return null;
  }, []);

  const requestAvailableMachines = useCallback(async () => {
    const res = await api.request(GET_VENDING_MACHINES, {
      error: 'Не удалось получить количество вендинговых аппаратов',
      params: { limit: 1 },
    });

    if (res) {
      const available = path(['meta', 'available'], res);
      return (typeof available === 'number' ? available : null);
    }
    return null;
  }, []);


  const handleRequestStatus = useCallback(async () => {
    setLoading(true);
    const [enabled, machines] = await Promise.all([
      requestVendingAvailability(),
      requestAvailableMachines(),
    ]);
    setLoading(false);

    if (enabled === null || machines === null) {
      popupErr('Не удалось получить статус вендинга');
      return;
    }
    setStatus(spotStatus(enabled, machines > 0));
  }, [popupErr, requestVendingAvailability, requestAvailableMachines]);


  useEffect(() => {
    handleRequestStatus();
  }, [handleRequestStatus]);


  const res = [status, loading];
  const memoized = useMemo(() => res, res); // eslint-disable-line react-hooks/exhaustive-deps

  return memoized;
}
