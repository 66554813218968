// @flow
import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import config from 'app/config';


const availableDishesEditListDataKey: 'vendingDishesEditList' = 'vendingDishesEditList';

type ColumnVisibilityAvailableDishesEditList = {
  photo: boolean,
  portion: boolean,
  cost: boolean,
}

const defaultColumnVisibilityAvailableDishesEditList: ColumnVisibilityAvailableDishesEditList = {
  photo: true,
  portion: true,
  cost: true,
};

export type Settings = {
  rows: number,
  ...ColumnVisibilityAvailableDishesEditList,
}

export const vendingDefaultState: Settings = {
  rows: config.perPageItems,
  ...defaultColumnVisibilityAvailableDishesEditList,
};

export default makeSettingsReducer(availableDishesEditListDataKey, vendingDefaultState);
