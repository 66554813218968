import { ReportType, RequestParams, ReportID } from 'app/dataTypes/reports'

import { FinanceReportType } from 'app/dataTypes/reports/finances'
import { convertParams as convertParamsCorrections, ApiCorrectionsParams } from './_predefined/common/corrections'
import { convertParams as convertParamsEmoneySales, ApiEmoneySalesParams } from './_predefined/finances/emoney-sales'

import { Request } from '../..'


export const dateFormat = 'YYYY-MM-DD'


type ApiResult = {
  report_id: number
  result: 'success'
}


export const convertParams = ({
  formData,
  reportType,
}: RequestParams): [ApiCorrectionsParams | ApiEmoneySalesParams | null, any] => {
  // do common convert params
  if (reportType === ReportType.Corrections) {
    return convertParamsCorrections(formData)
  }
  if (reportType === FinanceReportType.EmoneySales) {
    return convertParamsEmoneySales(formData)
  }
  return [null, null]
}

export const convertResult = (data: ApiResult) => {
  const { report_id: reportID } = data
  return String(reportID)
}

export const POST_REPORTS_PERFORM: Request<
  RequestParams,
  ReportID,
  void
> = ['reports/perform', 'post', ['data']]
