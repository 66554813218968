
import { useMemo } from 'react'
import { filter, isEmpty, map, pipe, values } from 'ramda'

import { Complexes, Complex } from './useKits'


export default function useKitsIDs(kits: Complexes) {
  const ids = useMemo(() => {
    return pipe(
      values<Complexes, string>,
      filter<Complex>(({ items }) => !!(items && !isEmpty(items))),
      map(({ id }: Complex) => String(id))
    )(kits)
  }, [kits])

  return ids
}
