// @flow
import React, { Fragment, useMemo } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import classNames from 'classnames';

import { Table, useCopyColumnsWidths } from 'app/common/ui-components/table';
import CSSSticky from 'app/common/ui-components/css-sticky';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import Loading from 'app/common/components/Loader';
import { Header, HeaderContent } from 'app/common/layouts/RowLayout';
import IconExcel from 'app/common/icons/IconExcel';
import { GenerateFile } from 'app/common/ui-components/progress-indicator';
import HistoryModal from 'app/common/ui-components/history-modal';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import useGenerateFileHistory, { reportTypes } from 'app/components/common/reports/history/useGenerateFileHistory';
import useGenerateFileRequests from 'app/components/common/reports/history/useGenerateFileRequests';

import type { PurchaseItem } from './apiResult2PurchaseItems';
import OOPTableTHead from './OOPTableTHead';
import OOPTableRow from './OOPTableRow';

import styles from './OOPTable.scss';


const cx = classNames;

type Props = {
  items: Array<PurchaseItem>,
  loading: boolean,
  titles: Array<string>,
  reportId: number,
  reportFileName: string,
}

const OrgsOrdersProductionTable = ({
  items,
  loading,
  titles,
  reportId,
  reportFileName,
}: Props) => {
  const nothing = isEmpty(items);

  const updateKey = useMemo(() => ([]), [items]); // eslint-disable-line react-hooks/exhaustive-deps
  const [refFrom, refTo] = useCopyColumnsWidths(updateKey);

  const generateFileRequests = useGenerateFileRequests(reportId, reportFileName);
  const historyModalProps = useGenerateFileHistory(reportTypes.REPORT_ORDERS_PURCHASES);

  return (
    <Fragment>
      <CSSSticky
        number={4}
        className={styles.stickable}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
      >
        <HeaderContent className={cx(styles.headerContent, styles.topBorderOnSticky)}>
          <Header>{'Продукция из заявок'}</Header>

          <GenerateFile
            labelClassName={styles.downloadLabel}
            label="Скачать как"
            generateAndDownloadPrompt="Сгенерировать и скачать в формате «документ Excel»"
            downloadPrompt="Скачать в формате «документ Excel»"
            {...generateFileRequests}
          >
            <IconExcel className={styles.downloadIcon} />
          </GenerateFile>

          <HistoryModal {...historyModalProps}>
            {toggle => (
              <PlainButtonAsLink onClick={toggle} className={styles.historyButton}>
                {'История загрузок'}
              </PlainButtonAsLink>
            )}
          </HistoryModal>
        </HeaderContent>

        <HeaderContent paddingfree>
          <Table className={styles.table}>
            <thead>
              <OOPTableTHead
                reference={refTo}
                titles={titles}
              />
            </thead>
          </Table>
        </HeaderContent>

        {loading
          && !nothing
          && <Loading className={styles.loadingOverContent} />}
      </CSSSticky>

      {nothing
        && loading
        && <Loading />}

      {nothing
        && !loading
        && <EmptyWarn>{'Нет записей, удовлетворяющих условиям'}</EmptyWarn>}

      {!nothing
        && <Table className={styles.table}>
          <thead>
            <OOPTableTHead
              reference={refFrom}
              titles={titles}
              hidden
            />
          </thead>
          <tbody>
            {items.map(item => (
              <OOPTableRow key={item.id} disabled={loading} {...item} />
            ))}
          </tbody>
        </Table>}
    </Fragment>
  );
};

export default OrgsOrdersProductionTable;
