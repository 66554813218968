import { ChangeEvent, type Ref } from 'react'

import { Tr, Td, getPropsHeader } from 'app/common/ui-components/table'

import { idsMain, useColumnPropsMain } from './columns'
import type { Item } from './useEAWTableItems'
import CellConstraints from './CellConstraints'


type Props = {
  className?: string,
  settings: Record<string, boolean>,
  reference?: Ref<'tr'>,
  disabled: boolean,
  onMouseEnter?: (e: ChangeEvent<HTMLElement>) => void,
  onMouseLeave?: (e: ChangeEvent<HTMLElement>) => void,
} & Item

const EAWTableRowMain = ({
  className,
  settings,
  reference,
  disabled,
  onMouseEnter,
  onMouseLeave,
  // id,
  jobPlace,
  position,
  phone,
  email,
  ident,
  photo,
  // inviteDate,
}: Props) => {
  const headers = getPropsHeader()
  const props = useColumnPropsMain()

  const content = [
    jobPlace,
    position,
    phone,
    email,
    ident,
    // inviteDate,
  ]

  return (
    <Tr
      className={className}
      reference={reference}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      interlineBorder
    >
      {idsMain.map((columnId, i) => (
        settings[columnId]
          ? <Td
            key={columnId}
            {...props}
            {...headers(columnId)}
          >
            <CellConstraints extended={!!photo}>
              {content[i]}
            </CellConstraints>
          </Td>
          : null
      ))}
    </Tr>
  )
}

export default EAWTableRowMain
