import { useState, useCallback } from 'react'

import { api } from 'app/api'
import { POST_REQUISITES, convertParams, convertResult } from 'app/common/api/requests/food/requisites/post'
import type { Requisites } from 'app/dataTypes/requisites'
import usePopup from 'app/common/hooks/usePopup'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


type Options = {
  data: Requisites
  onSuccess: (requestRes: Requisites) => void
  onBeforeSubmit?: (data: Requisites) => boolean
}

export default function useSubmit({ data, onSuccess, onBeforeSubmit }: Options) {
  const [submitting, setSubmitting] = useState(false)
  const popup = usePopup()

  const onSuccessRef = useCallbackRef(onSuccess)
  const onBeforeSubmitRef = useCallbackRef(onBeforeSubmit)

  const submit = useCallback(async () => {
    // e.preventDefault()

    if (onBeforeSubmitRef.current && !onBeforeSubmitRef.current(data)) {
      return
    }

    setSubmitting(true)
    const res = await api.request(POST_REQUISITES, {
      error: 'Не удалось сохранить данные реквизитов',
      params: data,
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      onSuccessRef.current?.(res)
      popup('Данные реквизитов успешно сохранены')
    }
    setSubmitting(false)
  }, [data, onSuccessRef, onBeforeSubmitRef, popup])

  return {
    submitting,
    submit,
  }
}
