import { useCallback, useEffect } from 'react'

import { DataBox, useDataBoxSelector, createDataBoxDefault } from 'app/common/data-box-store'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import {
  PlainDatePeriod,
  PlainDatePeriodProps,
  calcPeriodFromStart,
  disabledFutureDays,
} from '../../plain-date-period'


export const datePeriodBoxID = 'period'
type DatePeriod = Array<string>
const periodFilter = createDataBoxDefault<DatePeriod>(datePeriodBoxID, calcPeriodFromStart(null, null))
const getDefaultStateDefault = () => calcPeriodFromStart(null, null)

type Props = {
  dataBoxData?: DataBox<DatePeriod>
  getDefaultState?: () => DatePeriod
} & Omit<PlainDatePeriodProps, 'dateFrom' | 'dateTo' | 'onChange'>

const PlainDatePeriodBox = ({
  dataBoxData = periodFilter,
  getDefaultState = getDefaultStateDefault,
  dateDisabledStatus = disabledFutureDays,
  ...others
}: Props) => {
  const defaultState = useCallbackRef(getDefaultState)
  const [dateFrom, dateTo] = useDataBoxSelector(dataBoxData, () => dataBoxData.data)

  const handleChange = useCallback(([from, to]: Array<string>) => {
    dataBoxData.updateData(() => [from, to])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reset = useCallback(() => {
    dataBoxData.resetToDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const defaultData = defaultState.current?.()
    if (defaultData) {
      dataBoxData.setDefault(defaultData)
      dataBoxData.updateData(() => defaultData)
    }
  }, [defaultState, dataBoxData])

  useEffect(() => () => {
    dataBoxData.reset()
  }, [dataBoxData])

  return (
    <PlainDatePeriod
      {...others}
      dateFrom={dateFrom}
      dateTo={dateTo}
      onChange={handleChange}
      dateDisabledStatus={dateDisabledStatus}
      reset={reset}
    />
  )
}

export default PlainDatePeriodBox
