// @flow
import React from 'react';

import type { DisplayableData } from '../displayableData';
import styles from './MultiLineCellStub.scss';


type Props = {
  data: DisplayableData,
}

const MultiLineCellDumb = ({ data }: Props) => {
  const { totalSum } = data;

  return (
    <div className={styles.root}>{totalSum}</div>
  );
};

export default MultiLineCellDumb;
