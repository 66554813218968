import {
  GET_COMPENSATION_WITHDRAW_ORDERS,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/compensation/withdraw_orders/get'

import { useCommonDataProvider, Options } from 'app/dataProviders/useCommonDataProvider'
import { StatementList, Params } from 'app/dataTypes/compensation/statement'


const defaultState: StatementList = {
  total: 0,
  statements: [],
}

type Op = Omit<Options<StatementList, Params>, 'requestValue' | 'convertions' | 'defaultState'>

export function useStatements(options: Op) {
  const res = useCommonDataProvider({
    ...options,
    requestValue: GET_COMPENSATION_WITHDRAW_ORDERS,
    error: 'Не удалось получить список заявок на вывод компенсации',
    convertions: {
      convertParams,
      convertResult,
    },
    defaultState,
  })

  return res
}
