// @flow
import React from 'react';

import ErrorStub from 'app/common/layouts/ErrorStub';

import ReportSectionCSS from './ReportSectionCSS';
import styles from './ErrorReportCSS.scss';


type Props = {
  stickyNumber?: number,
}

const ErrorReportCSS = ({ stickyNumber }: Props) => (
  <ReportSectionCSS
    stickyNumber={stickyNumber}
    paddingFree
  >
    <ErrorStub className={styles.stub} />
  </ReportSectionCSS>
);

export default ErrorReportCSS;
