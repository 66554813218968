// @flow
import { useCallback, useState } from 'react';
import { path } from 'ramda';

import { api } from 'app/api';
import { GET_FIND_CUSTOMER, convertResult, convertParams } from 'app/common/api/requests/reports/find_customer';
import type { Customer } from 'app/dataTypes/reports/Customer.types';


const createCustomerValues = (customer: ?Customer): Array<string> => {
  const { id, type } = customer || {};
  if (id) {
    if (type) {
      return [id, type];
    }
    return [id];
  }
  return [];
};

type Options = {
  id: string,
  searchPhrase: string,
  setSearchPhrase: (string) => void,
  setCustomer: (Customer | null | 'not_found') => void,
  onCustomer: (id: string, values: Array<string>) => void,
}

export default function useActions({ id, searchPhrase, setSearchPhrase, setCustomer, onCustomer }: Options): [
  boolean,
  (e: SyntheticEvent<HTMLInputElement>) => void,
  (e: SyntheticKeyboardEvent<HTMLInputElement>) => void,
  () => Promise<void>
] {
  const [pending, setPending] = useState(false);

  const request = useCallback(async () => {
    setPending(true);
    const res = await api.request(GET_FIND_CUSTOMER, {
      error: 'Поиск не удалось осуществить',
      params: {
        search: searchPhrase,
      },
      convertions: {
        convertResult,
        convertParams,
      },
    });

    setCustomer(res && res.id ? res : 'not_found');
    onCustomer(id, createCustomerValues(res));
    setPending(false);
  }, [searchPhrase, setCustomer, onCustomer, id]);

  const changeSearchPhrase = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchPhrase(value);
  }, [setSearchPhrase]);

  const keyPress = useCallback((e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const key = path(['key'], e);
    if (key === 'Enter') {
      e.preventDefault();
      request();
    }
  }, [request]);

  return [pending, changeSearchPhrase, keyPress, request];
}
