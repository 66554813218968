// @flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './InvalidateWarning.scss';


const cx = classNames.bind(styles);

type Props = {
  active: boolean,
}

const InvalidateWarning = ({ active }: Props) => {
  return (
    <div className={cx(styles.root, { active })}>
      {'Так как был произведен возврат средств, текущий отчет больше не является актуальным'}
    </div>
  );
};

export default InvalidateWarning;
