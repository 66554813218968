import { useCallback } from 'react'

import { ReportSection, Stub, ReportDetailsRoot } from 'app/common/templates-next/report-template'
import { ReportStatusIndicator } from 'app/components/common/reports/v3/ReportStatusIndicator'
import { ReportType } from 'app/dataTypes/reports'
import { useReportResult } from 'app/dataProviders/reports/hooks'
import Loader from 'app/common/components/Loader'

import TableLayout from './tableCorrections/TableLayout'


type Props = {
  reportID: null | string
  onReportID: (reportID: string | null) => void
}

export const Result = ({
  reportID,
  onReportID,
}: Props) => {
  const resetReport = useCallback(() => {
    onReportID(null)
  }, [onReportID])

  const { requestResult, data } = useReportResult(reportID, ReportType.Corrections)

  const reportEmpty = !(reportID)
  const reportPrepare = !(reportID && data)

  const header = reportEmpty ? '' : 'Отчет «Корректировки»'

  return (
    <ReportSection
      header={header}
      skipPadding
    >
      {reportID
        && <ReportStatusIndicator
          reportID={reportID}
          onFail={resetReport}
          onSuccess={requestResult}
        />}

      {reportEmpty
        && <Stub />}

      {!reportEmpty && reportPrepare
        && <Loader />}

      {data
        && <ReportDetailsRoot header="Детализация отчета «Корректировки»">
          <TableLayout data={data} />
        </ReportDetailsRoot>}
    </ReportSection>
  )
}
