import { reduce, values } from 'ramda'

import { OrgGroupsByOrgType } from 'app/dataTypes/org/orgGroup'
import { OrgType, isOrgType } from 'app/dataTypes/org/types'


export type ApiResult = Record<string, {
  id: number
  title: string
  school_type: string
}>

export const convertResult = (res: ApiResult): OrgGroupsByOrgType => {
  return reduce((acc, val) => {
    const { id, title, school_type: sType } = val
    const orgType = isOrgType(sType) ? sType as OrgType : (
      sType === 'high_school' ? OrgType.HighSchool : OrgType.Unknown
    )
    const current = acc[orgType] || new Map()
    current.set(String(id), { ID: String(id), title, orgType })
    if (current.size === 1) {
      acc[orgType] = current
    }
    return acc
  }, {} as OrgGroupsByOrgType, values(res))
}
