import { Ref } from 'react'

import { Tr, Th, getPropsId } from 'app/common/ui-components/table'

import { idsFixed, titlesFixed, useColumnPropsFixed } from './columns'


type Props = {
  className?: string,
  settings: Record<string, boolean>,
  reference?: Ref<'tr'>,
  hidden?: boolean,
  dummy?: boolean,
}

const EAWTableTHeadFixed = ({
  className,
  settings,
  reference,
  hidden,
  dummy,
}: Props) => {
  const cellProps = getPropsId(dummy)
  const props = useColumnPropsFixed()

  return (
    <Tr
      className={className}
      reference={reference}
      hidden={hidden}
      header
    >
      {idsFixed.map((id, i) => (
        settings[id]
          ? <Th
            key={id}
            {...props[id]}
            {...cellProps(id)}
          >
            {titlesFixed[i]}
          </Th>
          : null
      ))}
    </Tr>
  )
}

export default EAWTableTHeadFixed
