
import { CorrectionsDetailsType, Params } from 'app/dataTypes/reports/data'
import { v4 as uuid } from 'uuid'
import SimpleDate from 'app/common/classes/simple-date'
import { Request } from '../../index'


type ResultApi = {
  result: string,
  count: number,
  corrections: Array<CorrectionsPurchasesResultApi | CorrectionsSubsidiesApi>
}

type CorrectionsPurchasesResultApi = {
  datetime: string // время корректировки
  purchase_id: number, // id продажи
  type: string, // тип (личная продажа, комплексное питание)
  action: string, // действие
  diff: number, // сумма корректировки
  purchase_datetime: string, // время продажи
}

type CorrectionsSubsidiesApi = {
  datetime: string, // время корректировки дотации
  sum: number, // сумма корректировки дотации
  type: string, // тип (личная продажа, комплексное питание)
  action: string, // действие
  purchase_datetime: string, // время продажи
  item_title: string // наименование продукции
}

const apiDateFormat = 'DD.MM.YYYY HH:MM' as const

export const convertResult = (apiData: ResultApi): CorrectionsDetailsType => {
  const { count, corrections } = apiData

  return {
    count,
    corrections: corrections.map((item) => {
      if ('purchase_id' in item) {
        return {
          id: uuid(),
          datetime: new SimpleDate(item.datetime).getString(apiDateFormat),
          purchaseID: item.purchase_id.toString(),
          type: item.type,
          action: item.action,
          diff: item.diff,
          purchaseDatetime: new SimpleDate(item.purchase_datetime).getString(apiDateFormat),
        }
      }
      return {
        id: uuid(),
        datetime: new SimpleDate(item.datetime).getString(apiDateFormat),
        sum: item.sum,
        type: item.type,
        action: item.action,
        purchaseDatetime: new SimpleDate(item.purchase_datetime).getString(apiDateFormat),
        itemTitle: item.item_title,
      }
    }),
  }
}

export type ApiParams = {
  date: string
  school_id: number
  operation_type: 'purchases' | 'subsidies'
  limit: number,
  offset: number
}

export const convertParams = (params: Params): [ApiParams, null] => {
  const { date, schoolID, operationType, limit, offset } = params
  return [{
    date,
    school_id: parseInt(schoolID, 10),
    operation_type: operationType,
    limit,
    offset,
  }, null]
}

export const GET_CORRECTIONS_DETAILS: Request<Params, CorrectionsDetailsType, void> = [
  'reports/corrections_details',
  'get',
  ['data'],
]
