import { createSelector } from 'reselect';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import get from 'app/common/lib/get';


const selectTypes = createSelector(
  [
    state => get(state, 'dishes.types.saleTypes', {}),
  ],
  items => pipe(
    values,
    map(({ id, title }) => ({ id: id || title, title })),
  )(items),
);

export default selectTypes;
