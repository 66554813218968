// @flow
import type { Request } from '../../index';


export type ImportDataTypesResult = Array<string>

export const defaultResult: ImportDataTypesResult = [];

export const GET_IMPORT_DATA_TYPES: Request<void, ImportDataTypesResult> = [
  'food/items/import_data_types',
  'get',
  ['data', 'types'],
];
