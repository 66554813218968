import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';

import get from 'app/common/lib/get';
import { rightsFetch, rightsChange, rightsClear } from 'app/actions/admin';
import { rightsSave } from 'app/actions/admin/save';
import { popup } from 'app/common/actions/popup';
import selectRights from './selectRights';


const changeRightsIfNotDisabled = (dispatch, rights, path, checked) => {
  if (!get(rights, [path.split('.'), 'disabled'], false)) {
    dispatch(rightsChange({ path, checked }));
  }
};

const enhanceAdmin = compose(
  connect(selectRights),
  // isModified
  // rights

  withState('loading', 'setLoading', true),
  withState('saving', 'setSaving', false),
  withState('currentRole', 'setCurrentRole', null),

  withHandlers({
    handleRolesLoad: ({ setCurrentRole }) => (id) => {
      if (id) {
        setCurrentRole(id);
      }
    },
    handleChangeCurrentRole: ({ setCurrentRole, isModified }) => (e) => {
      if (isModified && !confirm('Вы не сохранили изменения, изменить тип пользователя? Измененные данные будут потеряны')) { // eslint-disable-line
        return;
      }
      const { value } = e.currentTarget;
      setCurrentRole(value);
    },
    handleCheck: ({ dispatch, rights }) => (e) => {
      const { value, checked } = e.currentTarget;
      dispatch(rightsChange({ path: value, checked }));
      // связанные галки
      // включаем на чтение, если было включено на редактирование
      if (checked && value.includes('.edit')) {
        const siblingPath = value.replace('.edit', '.read');
        changeRightsIfNotDisabled(dispatch, rights, siblingPath, checked);
      // выключаем редактирование, если было выключено чтение
      } else if (!checked && value.includes('.read')) {
        const siblingPath = value.replace('.read', '.edit');
        changeRightsIfNotDisabled(dispatch, rights, siblingPath, checked);
      }
      // выключаем все в составе комплекта, если был выключен просмотр комплекта
      if (!checked && value === 'Комплекты.subsections.Комплекты.scopes.WEB.read') {
        const linkedPath1 = 'Комплекты.subsections.Состав комплектов.scopes.WEB.read';
        const linkedPath2 = 'Комплекты.subsections.Состав комплектов.scopes.WEB.edit';
        changeRightsIfNotDisabled(dispatch, rights, linkedPath1, checked);
        changeRightsIfNotDisabled(dispatch, rights, linkedPath2, checked);
      // включаем просмотр комплекта, если было вклюючено что-то в составе комплекта
      } else if (
        checked
        && (value === 'Комплекты.subsections.Состав комплектов.scopes.WEB.read'
        || value === 'Комплекты.subsections.Состав комплектов.scopes.WEB.edit')
      ) {
        const linkedPath = 'Комплекты.subsections.Комплекты.scopes.WEB.read';
        changeRightsIfNotDisabled(dispatch, rights, linkedPath, checked);
      }
    },
    adminSubmit: ({ dispatch, currentRole, setSaving }) => async (e) => {
      e.preventDefault();
      setSaving(true);
      const res = await dispatch(rightsSave(currentRole));
      if (res) {
        dispatch(popup('Настройки успешно сохранены'));
      }
      setSaving(false);
    },
    handleBeforeUnload: ({ isModified }) => () => {
      if (isModified) {
        return 'Вы не сохранили изменения!';
      }
      return null;
    },
  }),

  lifecycle({
    async componentDidMount() {
      const { setLoading, currentRole, dispatch, handleBeforeUnload } = this.props;
      if (currentRole) {
        await dispatch(rightsFetch(currentRole));
        setLoading(false);
      }
      window.onbeforeunload = handleBeforeUnload;
    },
    async componentDidUpdate(prevProps) {
      const {
        currentRole,
        dispatch,
        setLoading,
      } = this.props;
      if (currentRole && currentRole !== prevProps.currentRole) {
        setLoading(true);
        await dispatch(rightsFetch(currentRole));
        setLoading(false);
      }
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      window.onbeforeunload = null;
      dispatch(rightsClear());
    },
  }),
);

export default enhanceAdmin;
