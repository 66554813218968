import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';

import Loader from 'app/common/components/Loader';
import { employeeSettingsFetch, employeeSettingsClear } from 'app/actions/employees/employee/settings';


export default compose(
  connect(
    state => ({
      settings: state.employees.employee.settings.settings,
      created: state.employees.employee.settings.created,
      lastChange: state.employees.employee.settings.lastChange,
      lastVisit: state.employees.employee.settings.lastVisit,
      lastLogin: state.employees.employee.settings.lastLogin,
      loading: state.employees.employee.settings.result !== 'success',
    }),
  ),

  lifecycle({
    componentDidMount() {
      const { dispatch, personId } = this.props;
      dispatch(employeeSettingsFetch(personId));
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(employeeSettingsClear());
    },
  }),

  branch(({ loading }) => loading, renderComponent(Loader)),
);
