// @flow
import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';

import styles from './offline-indicator.scss';


const cx = classNames.bind(styles);

/**
 *  Индикация отсутствия соединения с Интернет
 */

type Props = {
  offlineMessage: string
}

const OfflineIndicator = ({ offlineMessage }: Props) => {
  const [offline, setOffline] = useState(false);
  const [active, setActive] = useState(false);

  const handleGoOnline = useCallback(() => {
    setActive(false);
    setTimeout(() => setOffline(false), 450);
  }, []);

  const handleGoOffline = useCallback(() => {
    setOffline(true);
    setTimeout(() => setActive(true), 60);
  }, []);

  useEffect(() => {
    window.addEventListener('online', handleGoOnline);
    window.addEventListener('offline', handleGoOffline);

    return () => {
      window.removeEventListener('online', handleGoOnline);
      window.removeEventListener('offline', handleGoOffline);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    offline
      ? <div className={cx(styles.root, { active })}>
        {offlineMessage}
      </div>
      : null
  );
};

export default OfflineIndicator;
