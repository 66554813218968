// @flow
import React from 'react';
import trim from 'ramda/es/trim';

import Button from '../../ui/Button';
import LineInput from '../../ui/LineInput';
import NewPass from '../NewPass';
import container from './container';
import type { LoginInfo } from '../container';
import styles from './Restore.scss';


const sendTo = (codeTo?: string, { emailText, phoneText }, canBeSendToEmail: boolean) => {
  if (codeTo) {
    return (/@/.test(codeTo)
      ? `. ${emailText} ${codeTo}`
      : `. ${phoneText} ${codeTo}`);
  }
  if (canBeSendToEmail) {
    return `. ${emailText}`;
  }

  return `. ${phoneText}`;
};


type Props = {
  loading: boolean,
  testing: boolean,
  handleMain: (e: SyntheticEvent<HTMLElement>) => void,
  handleReturn: (e: SyntheticEvent<HTMLElement>) => void,

  login: string,
  handleLogin: (e: SyntheticEvent<HTMLElement>) => void,
  isLoginValid: boolean,
  submitLogin: (e: SyntheticEvent<HTMLElement>) => void,
  loginError: string,
  timeLeft: string,
  codeSent: boolean,

  sending: boolean,
  code: string,
  isCodeValid: boolean,
  handleCode: (e: SyntheticEvent<HTMLElement>) => void,
  codeError: string,
  submitCode: (e: SyntheticEvent<HTMLElement>) => void,
  handleBack: (e: SyntheticEvent<HTMLElement>) => void,

  token: string,
  submitPass: (string) => void,
  passError: string,

  header?: string,
  text1?: string,
  text2?: string,
  lockLogin?: boolean,
  hideLogin?: boolean,

  loginInfo: LoginInfo,
  codeTo?: string,
  newPassTitle?: string,

  exampleFormat: string,
  t: (s: string, c?: string | Object) => string,
  emailForceAuth: boolean,
};

const Restore = ({
  loading,
  testing,
  handleMain,
  handleReturn,
  login,
  handleLogin,
  // isLoginValid,
  submitLogin,
  loginError,
  timeLeft,
  codeSent,
  sending,
  code,
  isCodeValid,
  handleCode,
  codeError,
  submitCode,
  handleBack,
  token,
  submitPass,
  passError,
  header,
  text1,
  text2,
  lockLogin,
  hideLogin,
  loginInfo,
  codeTo,
  newPassTitle,
  exampleFormat,
  t,
  emailForceAuth,
}: Props) => (token

  ? <div className={styles.root}>
    <a href="back" className={styles.back} onClick={handleBack}>{'←'}</a>
    <NewPass
      loading={sending}
      error={passError}
      onSubmit={submitPass}
      newPassTitle={newPassTitle}
    />
  </div>

  : <div className={styles.root}>
    <a href="back" className={styles.back} onClick={handleReturn || handleMain}>{'←'}</a>
    <div className={styles.header}>
      {`${header || t('login:restore_title')}`}
    </div>
    <form className={styles.form} onSubmit={submitLogin}>
      {!hideLogin && <div className={styles.text}>
        {text1 || `${t('login:restore_login_invite')}:`}
      </div>}
      {!hideLogin && !lockLogin
        && <LineInput
          name="login"
          value={login}
          onChange={handleLogin}
          placeholder={emailForceAuth
            ? t('login:restore_login_label_email_only')
            : t('login:restore_login_label')}
          type="text"
          locked={lockLogin}
          isError={!!loginError}
          errorText={loginError}
          comment={emailForceAuth
            ? ''
            : `${t('login:login_description')} ${exampleFormat}`}
          wide
        />}
      {lockLogin && <div>{login}</div>}
      {hideLogin && <div className={styles.space} />}
      {/* {loginError && <div className={styles.error}>{loginError}</div>} */}
      {timeLeft && <div className={styles.info}>
        {t('login:restore_code_timeout_info')}
        {' '}
        {timeLeft}
      </div>}
      <Button type="submit" disabled={!trim(login) || loading || testing || timeLeft} className={styles.button}>
        {loading && t('login:restore_code_receive_submit_bt_loading')}
        {testing && t('login:restore_code_receive_submit_bt_test')}
        {!loading && !testing && (
          (timeLeft && codeSent)
            ? t('login:restore_code_receive_submit_bt_sent')
            : t('login:restore_code_receive_submit_bt')
        )}
      </Button>
    </form>
    <form className={styles.form} onSubmit={submitCode}>
      <div className={styles.text}>
        {text2
          || <span>
            {`${t('login:restore_code_invite')}${loginInfo.login && sendTo(codeTo, {
              emailText: t('login:restore_code_wait_on_mail', 'Код придет на email'),
              phoneText: t('login:restore_code_wait_on_phone', 'Код придет на телефонный номер'),
            }, loginInfo.code2Mail)}:`}
          </span>}
      </div>
      <LineInput
        name="code"
        placeholder={(t('login:restore_code_label'))}
        type="text"
        value={code}
        onChange={handleCode}
        isError={!!codeError}
        errorText={codeError}
      />
      <Button type="submit" className={styles.button} disabled={!isCodeValid || sending}>
        {sending
          ? t('login:restore_code_submit_bt_loading')
          : t('login:restore_code_submit_bt')}
      </Button>
    </form>
  </div>
);

export default container(Restore);
