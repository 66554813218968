// @flow
export const BALANCE_FILTER_SET = 'reports/BALANCE_FILTER_SET';
export const BALANCE_FILTER_CLEAR = 'reports/BALANCE_FILTER_CLEAR';

type Payload = {
  name: 'min' | 'max',
  value: string,
}

export const balanceFilterSet = (payload: Payload) => ({
  type: BALANCE_FILTER_SET,
  payload,
});

export const balanceFilterClear = () => ({
  type: BALANCE_FILTER_CLEAR,
});
