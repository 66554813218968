// @flow
import { handleDisconnect } from 'app/common/actions/settings/current';
import api from 'app/actions/api';

export const CATEGORIES_REQUEST = 'orgs/CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'orgs/CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'orgs/CATEGORIES_FAILURE';
export const CATEGORY_COUNT_SET = 'orgs/CATEGORY_COUNT_SET';

/**
* Получение категорий блюд для школы
*/

export const categoriesRequest = () => ({
  type: CATEGORIES_REQUEST,
});

export const categoriesSuccess = (payload: Object) => ({
  type: CATEGORIES_SUCCESS,
  payload,
});

export const categoriesFailure = (payload: Object) => ({
  type: CATEGORIES_FAILURE,
  payload,
});

export const categoryCountSet = (payload: { categoryId: number, count: number }) => ({
  type: CATEGORY_COUNT_SET,
  payload,
});

export const categoriesFetch = (orgId: string) => async (dispatch: Function, getState: Function) => {
  if (getState().orgs.dishes.categories.expired > Date.now()) return;
  dispatch(categoriesRequest());
  try {
    const { data } = await api(`food/schools/${orgId}/item_categories`, 'get');
    dispatch(categoriesSuccess(data));
  } catch (error) {
    if (handleDisconnect(error, dispatch)) {
      dispatch(categoriesFailure({ err: 'Запрос категорий блюд для школы не удался', error }));
    }
  }
};
