import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Button from 'app/common/ui/Button';
import LineInput from 'app/common/login/ui/LineInput';
import Modal from 'app/common/components/Modal';

import enhanceFIOChange from './enhanceFIOChange';
import styles from './FIOChange.scss';


type Props = {
  submit: (e: SyntheticEvent<HTMLElement>) => void,
  close: (e: SyntheticEvent<HTMLElement>) => void,
  change: (e: SyntheticEvent<HTMLInputElement>) => void,
  name: {
    first: string,
    last: string,
    middle: string,
  },
  saving: boolean,
}

const FIOChange = ({ submit, close, change, name, saving }: Props) => {
  const { t } = useTranslation('profile');

  return (
    <Modal close={close}>
      <div className={styles.root}>
        <h2 className={styles.header}>
          <Trans i18nKey="profile:fio_modal_header">
            {'Редактирование ФИО'}
          </Trans>
        </h2>
        <div className={styles.content}>
          <form onSubmit={submit}>
            <div className={styles.input}>
              <LineInput
                placeholder={`${t('fio_last')}*`}
                name="last"
                value={name.last}
                onChange={change}
                isError={false}
                errorText=""
              />
            </div>
            <div className={styles.input}>
              <LineInput
                placeholder={`${t('fio_first')}*`}
                name="first"
                value={name.first}
                onChange={change}
                isError={false}
                errorText=""
              />
            </div>
            <div className={styles.input}>
              <LineInput
                placeholder={t('fio_middle')}
                name="middle"
                value={name.middle}
                onChange={change}
                isError={false}
                errorText=""
              />
            </div>
            <div className={styles.buttons}>
              <Button
                submit
                value={t('submit_change_bt')}
                disabled={saving || !name.first || !name.last}
              />
            </div>

          </form>
        </div>
      </div>
    </Modal>
  );
};

export default enhanceFIOChange(FIOChange);
