import { Dispatch, ReactNode, SetStateAction, useMemo } from 'react'

import { OrgGroup } from 'app/dataTypes/org/orgGroup'
import PlainAccordion from 'app/common/ui-next/plain-accordion'
import {
  getColorFromTheme,
  Theme,
} from 'app/common/components-next/color-theme'

import styles from './OrgGroups.scss'


const getHeaderClassName = ({ active }) => (
  active ? styles.headerActive : styles.header
)
const getContentClassName = () => styles.content
const getItemClassName = () => styles.groupItem

type Props = {
  orgGroups?: Map<string, OrgGroup>
  locked?: boolean | Set<string>
  headerContent?: (props: {
    ID: string
    active: boolean
    hover: boolean
    setActiveID: Dispatch<SetStateAction<string | null>>
    closeActivePanel: () => void
  }) => ReactNode
  getOrgsCount?: (ID: string) => number
  panelContent: (props: {
    ID: string
    active: boolean
    updateSize: () => void
    closeActivePanel: () => void
  }) => ReactNode
}

const OrgGroups = ({
  orgGroups = new Map(),
  locked,
  panelContent,
  headerContent,
  getOrgsCount,
}: Props) => {
  const primaryColor = getColorFromTheme(Theme.primaryColor, '#ffb400')
  const linkColor = getColorFromTheme(Theme.linkColor, '#e6a200')

  const orgGroupsTitled = useMemo(() => {
    return Array.from(orgGroups.values()).map(({ ID, title }) => {
      const orgsCount = getOrgsCount ? getOrgsCount(ID) : null

      return {
        ID,
        title: (
          <>
            <span className={styles.title}>{title}</span>
            {typeof orgsCount === 'number' && <span>{` (${orgsCount})`}</span>}
          </>
        ),
      }
    })
  }, [orgGroups, getOrgsCount])

  return (
    <PlainAccordion
      className={styles.root}
      items={orgGroupsTitled}
      fancyColor={primaryColor}
      fancyColorTitle={linkColor}
      locked={locked}
      getHeaderClassName={getHeaderClassName}
      getContentClassName={getContentClassName}
      getItemClassName={getItemClassName}
      headerContent={headerContent}
      panelContent={panelContent}
    />
  )
}

export default OrgGroups
