// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';
import prop from 'ramda/es/prop';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray, isSingleWithId } from 'app/common/lib/object';
import { tableColumnSortClass as sortClass } from 'app/common/lib/sort';
import action from 'app/common/lib/action';
import * as cl from 'app/actions/reports/filters/classes';
import * as statuses from 'app/common/constants/students/statuses';
import * as orgsTypes from 'app/common/constants/schools/types';
import * as customerTypes from 'app/common/constants/schools/customers';

import { selectOrgId, selectYearId, selectOrgsType } from './hocs/commonSelectors';
import styles from './FilterItems.scss';


const sortFunc = sortClass('down', prop('title'));

const selectIsEnableDefault = createSelector(
  [
    selectOrgId,
    selectYearId,
    state => state.reports.filters.customers.selected,
    state => state.reports.filters.studentStatuses,
    state => state.reports.filters.date.current,
    selectOrgsType,
  ],
  (orgId, yearId, customers, studentStatuses, currentState, orgsType) => {
    // на предприятии нет классов или групп
    if (orgsType === orgsTypes.ENTERPRISE) {
      return false;
    }
    // школы, ВУЗы и дошкольные обр. уч
    return Boolean(
      orgId // выбрана школа
      && yearId // выбран уч. год
      && isSingleWithId(customerTypes.STUDENT)(customers) // Тип покупателя "Учащиеся" (single selected)
      && (
        (studentStatuses.active // Задействован фильтр по статусу учеников
          && isSingleWithId(statuses.ACTIVE)(studentStatuses.selected)) // и статус учеников - "текущие" (single selected)
        || !currentState // или задействован фильтр на конкретную дату
      ),
    );
  },
);

const selectClassesDefault = state => state.reports.filters.classes.data;

type ClassesTypes = {
  [key: string]: {
    id: number,
    title: string,
  }
}

type Props = {
  resetStatus: boolean,
  selectIsEnable?: (state: Object) => boolean,
  selectItems?: (state: Object) => Object,
}

const ClassesFilter = ({ resetStatus, selectIsEnable, selectItems }: Props) => {
  const dispatch = useDispatch();

  // данные классов
  const itemsObj: ClassesTypes = useSelector(selectItems || selectClassesDefault, shallowEqual);
  const loading = useSelector(state => state.reports.filters.classes.loading, shallowEqual);

  // выбранное
  const selectedItems: ClassesTypes = useSelector(state => state.reports.filters.classes.selected, shallowEqual);

  // и массив id с выбранным
  const selectedArr: Array<number> = mapObjPropToArray('id')(selectedItems);

  // дополнительные параметры для запроса
  const orgId = useSelector(selectOrgId);
  const yearId = useSelector(selectYearId);

  // выбранный тип заведкния, для получения заголовка фильтра
  const orgsType = useSelector(selectOrgsType);
  const filterTitle = orgsType === orgsTypes.SCHOOL ? 'Класс:' : 'Группа:';
  const filterModalTitle = orgsType === orgsTypes.SCHOOL ? 'Выбор класса' : 'Выбор группы';


  const handleReset = useCallback(() => {
    dispatch(action(cl.CLASSES_FILTER_SET, {}));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(cl.CLASSES_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(cl.CLASSES_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE и запрос данных
   */

  const isEnable = useSelector(selectIsEnable || selectIsEnableDefault);

  useEffect(() => {
    dispatch(action(cl.CLASSES_FILTER_UPDATE, { active: isEnable }));

    if (isEnable) {
      (async () => {
        const res = await dispatch(cl.classesFilterFetch(orgId, yearId));
        if (res) {
          handleReset();
        }
      })();
    }
  }, [isEnable, orgId, yearId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isEnable) {
    return null;
  }


  return (
    <PlainFilterComplete
      id="classes"
      loading={loading}
      className={styles.filter}
      title={filterTitle}
      modalTitle={filterModalTitle}
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      sortFunc={sortFunc}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default ClassesFilter;
