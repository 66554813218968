import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { ItemType } from 'app/common/ui-next/plain-selector'


export type OrgOrUser = Array<ItemType>


export enum OrgsOrUserIDs {
  ByOrgs = 'by_orgs',
  ByUser = 'by_user'
}

class OrgOrUserFilterDataBox extends DataBox<OrgOrUser> { }

export const defaultOrgOrUser: OrgOrUser = []
export const orgOrUserFilter = createDataBox('orgOrUserFilter', defaultOrgOrUser, OrgOrUserFilterDataBox)


export const defaultOrgOrUserData = () => {
  return [{
    id: OrgsOrUserIDs.ByOrgs,
    title: 'по заведениям',
  }, {
    id: OrgsOrUserIDs.ByUser,
    title: 'по данным пользователя',
  }]
}

// selection

export const defaultOrgOrUserSelection: string = OrgsOrUserIDs.ByOrgs
export const orgOrUserFilterSelection = createDataBoxDefault<string>('orgOrUserFilterSelection', defaultOrgOrUserSelection)
