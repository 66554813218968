// @flow
import React, { type Node } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import PlainTooltip from 'app/common/ui-next/plain-tooltip';


type Props = {
  tooltip?: string,
  disabled?: boolean,
  onClick?: () => void,
}

const AddRemoveButton = ({ tooltip, disabled, onClick }: Props): Node => {
  return (
    <PlainTooltip text={disabled ? tooltip : ''}>
      <PlainButton
        onClick={onClick}
        disabled={disabled}
      >
        {'Редактирование списка продукции'}
      </PlainButton>
    </PlainTooltip>
  );
};

export default AddRemoveButton;
