// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';
import pick from 'ramda/es/pick';
import reduce from 'ramda/es/reduce';
import values from 'ramda/es/values';
import keys from 'ramda/es/keys';
import isEmpty from 'ramda/es/isEmpty';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray, isSingle } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as orgTypes from 'app/common/constants/schools/types';
import * as ot from 'app/actions/reports/filters/orgsTypes';
import { orgsFilterFetch } from 'app/actions/reports/filters/orgs';

import styles from './FilterItems.scss';


type OrgsType = {
  [key: string]: {
    id: number,
    title: string,
    type: $Values<typeof orgTypes>,
    periods: Array<{
      from: ?string,
      to: ?string,
    }>,
  }
}

type OrgsTypesType = {
  [key: string]: {
    id: $Values<typeof orgTypes>,
    title: string,
  }
}

const emptyObj = {};
const selectAvailableOrgsTypes = createSelector(
  [
    state => state.reports.filters.orgs.data,
    state => state.reports.filters.orgsTypes.data,
  ],
  (orgs: OrgsType, orgsTypes: OrgsTypesType) => {
    const availableTypes = reduce((acc, { type }) => ({
      ...acc,
      [type]: true,
    }), {}, values(orgs));

    const availableTypesArray = keys(availableTypes);
    return pick(availableTypesArray, orgsTypes);
  },
);

const transformOrgsTypes2Array = ({ items }: { items: OrgsTypesType, ... }) => {
  const arrange = [orgTypes.PRESCHOOL, orgTypes.SCHOOL, orgTypes.HIGH_SCHOOL, orgTypes.ENTERPRISE];
  return reduce((acc, value) => (items[value] ? [
    ...acc,
    items[value],
  ] : acc), [], arrange);
};

type Props = {
  resetStatus: boolean,
}

const OrgsTypesFilter = ({ resetStatus }: Props) => {
  const dispatch = useDispatch();
  const [defaultOrgType, setDefaultOrgType] = useState(emptyObj);

  // все элементы
  const itemsObj: OrgsTypesType = useSelector(selectAvailableOrgsTypes);

  // выбранные
  const selectedItems: OrgsTypesType = useSelector(state => state.reports.filters.orgsTypes.selected, shallowEqual);

  // и массив с выбранным
  const selectedArr: Array<$Values<typeof orgTypes>> = mapObjPropToArray('id')(selectedItems);


  const handleReset = useCallback(() => {
    dispatch(action(ot.ORGS_TYPES_FILTER_SET, defaultOrgType));
  }, [defaultOrgType, dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(ot.ORGS_TYPES_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Обновление возможных типов, выбор SCHOOL  по умолчанию,
   *  установка состояния для reset
   */

  useEffect(() => {
    if (isSingle(itemsObj)) {
      const defaultObj = { ...itemsObj };
      setDefaultOrgType(defaultObj);
      dispatch(action(ot.ORGS_TYPES_FILTER_SET, defaultObj));
      return;
    }
    if (itemsObj[orgTypes.SCHOOL]) { // только если есть тип SCHOOL у данного поставщика
      const defaultObj = pick([orgTypes.SCHOOL], itemsObj);
      setDefaultOrgType(defaultObj);
      dispatch(action(ot.ORGS_TYPES_FILTER_SET, defaultObj));
    }
  }, [itemsObj, dispatch]);

  /**
   *  Запрос организаций, нужен, чтоб отфильтровать типы по имеющимся
   */

  useEffect(() => {
    dispatch(orgsFilterFetch());

    return () => {
      dispatch(action(ot.ORGS_TYPES_FILTER_CLEAR));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <PlainFilterComplete
      id="orgsTypes"
      className={styles.filter}
      title="Тип заведения:"
      modalTitle="Выбор типа заведения"
      itemsObj={itemsObj}
      // $FlowFixMe
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      // $FlowFixMe
      itemsToArrayFunc={transformOrgsTypes2Array}
      light={false}
      singleSelectable
      toggleModalOnCrossClick
      loading={isEmpty(itemsObj)}
      noDataText="Выберите тип заведения"
      selectedAllStateEnabled={false}
      nothingIsAll
    />
  );
};

export default OrgsTypesFilter;
