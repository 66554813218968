import { useCallback, useEffect, useState } from 'react'

import {
  GET_SCHOOL_SUBSIDY_ORDERS_COMPLEXES,
  convertParams,
  convertResult,
} from 'app/common/api/requests/food/schools/subsidy_orders_complexes/get'

import { Params, Complexes } from 'app/dataTypes/subsidy/complexes'
import { api } from 'app/api'


type Options = {
  autoload?: boolean
} & Params

const defaultSettings: Complexes = []

export default function useComplexes({ orgID, app, allowanceID, autoload = true }: Options): [
  Complexes,
  boolean,
  () => Promise<void>
] {
  const [loading, setLoading] = useState(true)
  const [complexes, setComplexes] = useState<Complexes>(defaultSettings)

  const request = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_SCHOOL_SUBSIDY_ORDERS_COMPLEXES, {
      error: 'Не удалось получить комплекты',
      params: {
        app,
        orgID,
        allowanceID,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })
    setLoading(false)

    if (res) {
      setComplexes(res)
    }
  }, [orgID, app, allowanceID])

  useEffect(() => {
    if (autoload) {
      request()
    }
  }, [request, autoload])

  return [complexes, loading, request]
}
