// @flow
import { useMemo } from 'react';
import slice from 'ramda/es/slice';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import addIndex from 'ramda/es/addIndex';
import sortWith from 'ramda/es/sortWith';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';
import moment from 'moment-timezone';

import toFixedValue from 'app/common/lib/toFixedValue';
import type { DishItem } from 'app/common/ui-components/complex-tooltip';

import type { PurchaseItem } from './useSortedSales';
import getCustomerName from './getCustomerName';
import type { DisplaySettings } from '../types';


const mapIndexed = addIndex(map);

export type ActualDishOrComplex = {
  id: number,
  title: string,
  cost: string,
  number: number,
  complexItems: ?Array<DishItem> // если отсутствует, то обычное блюдо
}

export type ActualSale = {
  id: number,
  fio: string,
  class: string,
  date: string,
  time: string,
  type: 'personal' | 'complex',
  items: Array<ActualDishOrComplex>,
  sum: string,
  accountNumber: string | null,
  phone: string | null,
}

export default function useActualSales(
  sales: Array<PurchaseItem>,
  { showAccountNumber, showPhone }: DisplaySettings,
  from: number,
  to: number,
): Array<ActualSale> {
  const salesResult = useMemo(() => pipe(
    slice(from, to),
    map(({
      id,
      fio,
      customer_type: customer,
      klass_title: klass,
      time,
      sale_type: saleType,
      items,
      sum,
      customer_account_number: customerAccountNumber,
      customer_phone: customerPhone,
    }): ActualSale => {
      const mDate = moment(time);

      return ({
        id, // оно же номер заказа
        fio,
        class: [getCustomerName(customer), klass || ''].join(', '),
        date: mDate.format('DD.MM.YYYY'),
        time: mDate.format('HH:mm'),
        type: saleType,
        items: mapIndexed(({ title, cost, n, sold_complex_items: soldComplexItems }, i) => ({
          id: i,
          title,
          cost: toFixedValue(cost),
          number: n,
          complexItems: (soldComplexItems
            ? pipe(
              sortWith([
                ascend(prop('index')),
                ascend(prop('title')),
              ]),
              mapIndexed(({ cost: _, ...props }, ix): DishItem => ({ ...props, id: ix })),
            )(soldComplexItems)
            : null),
        }), items),
        sum: toFixedValue(sum),
        accountNumber: (showAccountNumber && customerAccountNumber) || null,
        phone: (showPhone && customerPhone) || null,
      });
    }),
  )(sales), [from, to, sales, showAccountNumber, showPhone]);

  return salesResult;
}
