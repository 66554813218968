import { forwardRef } from 'react'
import { Tr, Th } from 'app/common/ui-components/table'

import { Column } from './columns'


type Props = {
  columns: Array<Column>
  titles: Record<Column, string>
  stuck: boolean
  shadow: boolean
  columnProps: Record<Column, {
    className?: string
    alignLeft?: boolean
    alignRight?: boolean
    alignCenter?: boolean
  }>
}

export const TableHeader = forwardRef<'tr', Props>(({
  columns,
  titles,
  stuck,
  shadow,
  columnProps,
}: Props, ref) => {
  return (
    <Tr reference={ref} header>
      {columns.map(key => (
        <Th
          key={key}
          bgFilled={stuck}
          borderShadowBottom={shadow}
          {...columnProps[key]}
        >
          {titles[key]}
        </Th>
      ))}
    </Tr>
  )
})
