// @flow
import { type State } from 'app/common/ui-components/filter';
import { customerTypeItems as customerTypeItemsDefault, onlyStudents } from 'app/components/common/reports/filters/customerType';

import { flags as group } from './group';


const customerTypeItems = async (state: State) => {
  return (
    (group.byGroupComplexesSelected(state) || group.byCampComplexesSelected(state))
      ? onlyStudents()
      : customerTypeItemsDefault()
  );
};

export default customerTypeItems;
