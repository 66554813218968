import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import uniqid from 'uniqid'
import { path } from 'ramda'

import PlainInput from 'app/common/ui-next/plain-input'
import PlainLabel from 'app/common/ui-next/plain-label'
import PlainButton from 'app/common/ui-next/plain-button'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainHelperText from 'app/common/ui-next/plain-helper-text'
import { DataBox, useDataBoxSelector } from 'app/common/data-box-store'

import CustomerView from './customer-view'
import { Customer } from './types'


export type CustomerSearchProps = {
  id: string,
  current?: Array<string>,
  label: string,
  loading?: boolean,
  skipUpperSpace?: boolean,
  className?: string,
  dataBoxSearchPhrase?: DataBox<string>
  defaultSearchPhrase?: string
  dataBoxCustomer?: DataBox<Customer | null>
  defaultCustomer?: Customer | null
}

const CustomerSearch = ({
  id,
  label,
  loading,
  skipUpperSpace,
  className,
  dataBoxSearchPhrase = new DataBox<string>(uniqid('empty-searchPhrase-'), ''),
  defaultSearchPhrase = '',
  dataBoxCustomer = new DataBox<Customer | null>(uniqid('empty-selection-'), {} as Customer),
  defaultCustomer = {} as Customer,
}: CustomerSearchProps) => {
  const [pending, setPending] = useState<boolean>(false)
  const [input, setInput] = useState<string>('')
  const searchPhrase = useDataBoxSelector(dataBoxSearchPhrase, () => dataBoxSearchPhrase.data)
  const customer = useDataBoxSelector(dataBoxCustomer, () => dataBoxCustomer.data)


  const changeSearchPhrase = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setInput(value)
  }, [])


  const requestCustomerHandler = useCallback(() => {
    setPending(true)
    dataBoxSearchPhrase.updateData(() => input)
  }, [dataBoxSearchPhrase, input])


  const handleKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const key = path(['key'], e)
    if (key === 'Enter') {
      e.preventDefault()
      requestCustomerHandler()
    }
  }, [requestCustomerHandler])


  useEffect(() => {
    if (searchPhrase.length) {
      dataBoxCustomer.dataReceive()
      dataBoxCustomer.setStatusReady()
    }
    setPending(false)
  }, [searchPhrase, dataBoxCustomer])


  const initDefault = useCallback(() => {
    dataBoxSearchPhrase.updateData(() => defaultSearchPhrase)
    dataBoxCustomer.updateData(() => defaultCustomer)
  }, [dataBoxCustomer, dataBoxSearchPhrase, defaultCustomer, defaultSearchPhrase])

  useEffect(() => {
    initDefault()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={className}>
      <PlainLabel
        htmlFor={id}
        skipUpperSpace={skipUpperSpace}
        displayAsBlock
      >
        {label}
      </PlainLabel>
      <ControlsBar>
        <PlainInput
          id={`phrase${id}`}
          value={input}
          loading={loading}
          onChange={changeSearchPhrase}
          onKeyDown={handleKeyPress}
          // error={!customer?.ID}
          disabled={pending}
        />

        <PlainButton
          disabled={!input || pending}
          onClick={requestCustomerHandler}
        >
          {pending ? 'Поиск...' : 'Найти'}
        </PlainButton>
      </ControlsBar>

      {!customer?.ID && customer !== null
        && <PlainHelperText error>
          {'Пользователь не найден'}
        </PlainHelperText>}
      {customer?.ID
        && <input
          name={id}
          style={{ display: 'none' }}
          onChange={() => { }}
          value={customer.ID}
        />}
      {customer?.ID
        && <CustomerView {...customer} />}
    </div>
  )
}

export default CustomerSearch
