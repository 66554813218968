// @flow
export const MAX_STICKY_NESTING = 6;

/**
 *  Суммирует первые n элементов из arr
 */

export const sumTo = (n: number, arr: number[]) => {
  let res = 0;
  for (let i = 0; i < n - 1; i += 1) {
    res += arr[i];
  }
  return res;
};
