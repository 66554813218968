// @flow
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import range from 'ramda/es/range';

import { DotsLoader } from 'app/common/templates-next/page-template';

import PlainInputDate, { dateFormat as dateFormatDefault } from '../plain-input-date';
import PlainButtonAsLink from '../plain-button-as-link';
import { PlainHelperPeriodInfo } from '../plain-helper-text';

import styles from './plain-date-period.scss';


const cx = classNames.bind(styles);

export type Props = {
  id?: string | Array<string>,
  className?: string,
  loading?: boolean,
  disabled?: boolean,
  label?: string,
  buttonResetLabel?: ?string,
  fromLabel?: string,
  toLabel?: string,
  dateFrom: string,
  dateTo: string,
  dateFormat?: string,
  periodLimit?: number,
  periodLimitUnit?: 'months' | 'days' | 'years',
  calendarCloseOnClick?: boolean,
  hideYear?: boolean,
  skipUpperSpace?: boolean,
  onChange: (values: [string, string], dateFormat: string) => void,
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean,
  reset?: () => void,
}

const PlainDatePeriod = ({
  id,
  className,
  loading,
  disabled,
  label = 'Период времени',
  buttonResetLabel = 'Сбросить выбор',
  fromLabel = 'от',
  toLabel = 'до',
  dateFrom,
  dateTo,
  dateFormat = dateFormatDefault,
  periodLimit,
  periodLimitUnit = 'days',
  calendarCloseOnClick,
  hideYear,
  skipUpperSpace,
  onChange,
  dateDisabledStatus,
  reset,
}: Props) => {
  const [IDFrom, IDTo] = (
    id instanceof Array ? id : [id && `${id}From`, id && `${id}To`]
  );

  const handleChangeFrom = useCallback((formattedValue) => {
    if (formattedValue && dateTo) {
      const mDate = moment(formattedValue, dateFormat);
      const mDateTo = moment(dateTo, dateFormat);

      // перескок через вторую дату
      if (mDate.isSameOrAfter(mDateTo, 'day')) {
        onChange([mDate.format(dateFormat), mDate.format(dateFormat)], dateFormat);
        return;
      }

      // проверка лимита, если превышен, вторая дата будет подтянута
      if (periodLimit) {
        const mMinimumDate = mDateTo.clone().subtract(periodLimit, periodLimitUnit);
        if (mDate.isBefore(mMinimumDate)) {
          const mNewDateTo = mDate.clone().add(periodLimit, periodLimitUnit);
          onChange([mDate.format(dateFormat), mNewDateTo.format(dateFormat)], dateFormat);
          return;
        }
      }
      onChange([mDate.format(dateFormat), mDateTo.format(dateFormat)], dateFormat);
    }
    onChange([formattedValue, dateTo], dateFormat);
  }, [onChange, dateTo, periodLimit, periodLimitUnit, dateFormat]);

  const handleChangeTo = useCallback((formattedValue) => {
    if (formattedValue && dateFrom) {
      const mDate = moment(formattedValue, dateFormat);
      const mDateFrom = moment(dateFrom, dateFormat);

      if (mDate.isSameOrBefore(mDateFrom, 'day')) {
        onChange([mDate.format(dateFormat), mDate.format(dateFormat)], dateFormat);
        return;
      }

      // проверка лимита, если превышен, вторая дата будет подтянута
      if (periodLimit) {
        const mMaximumDate = mDateFrom.clone().add(periodLimit, periodLimitUnit);
        if (mDate.isAfter(mMaximumDate)) {
          const mNewDateFrom = mDate.clone().subtract(periodLimit, periodLimitUnit);
          onChange([mNewDateFrom.format(dateFormat), mDate.format(dateFormat)], dateFormat);
          return;
        }
      }
      onChange([mDateFrom.format(dateFormat), mDate.format(dateFormat)], dateFormat);
    }
    onChange([dateFrom, formattedValue], dateFormat);
  }, [onChange, dateFrom, periodLimit, periodLimitUnit, dateFormat]);

  const daysSelected: Array<string> | null = useMemo(() => {
    if (dateFrom && dateTo) {
      const mDateFrom = moment(dateFrom, dateFormat);
      const mDateTo = moment(dateTo, dateFormat);
      if (mDateFrom.isValid() && mDateTo.isValid()) {
        const days = mDateTo.diff(mDateFrom, 'days');
        return [
          dateFrom,
          ...range(0, days).map(() => (
            mDateFrom.add(1, 'days').format(dateFormat)
          )),
        ];
      }
    }
    return null;
  }, [dateFrom, dateTo, dateFormat]);

  return (
    <div className={cx(styles.root, { skipUpperSpace }, className)}>
      <div className={styles.defaultLayout}>
        <fieldset
          className={styles.fieldset}
        >
          <legend className={styles.label}>{label}</legend>

          {loading && <DotsLoader />}

          {/* bt Сбросить выбор */}
          {buttonResetLabel
            && <PlainButtonAsLink
              className={styles.btReset}
              linkClassName={styles.btResetLink}
              disabled={disabled}
              onClick={reset}
            >
              {buttonResetLabel}
            </PlainButtonAsLink>}

          <div className={styles.edit}>
            <label htmlFor="from" className={styles.editLabel}>
              {fromLabel}
            </label>
            <PlainInputDate
              id={IDFrom}
              loading={loading}
              disabled={disabled}
              value={dateFrom}
              onDateChange={handleChangeFrom}
              dateFormat={dateFormat}
              dateDisabledStatus={dateDisabledStatus}
              calendarCloseOnClick={calendarCloseOnClick}
              hideYear={hideYear}
            />

            <label htmlFor="to" className={styles.editLabel}>
              {toLabel}
            </label>
            <PlainInputDate
              id={IDTo}
              loading={loading}
              disabled={disabled}
              value={dateTo}
              onDateChange={handleChangeTo}
              dateFormat={dateFormat}
              dateDisabledStatus={dateDisabledStatus}
              calendarCloseOnClick={calendarCloseOnClick}
              hideYear={hideYear}
            />
          </div>
        </fieldset>
      </div>

      {daysSelected
        && <PlainHelperPeriodInfo
          showPlaceholder={loading}
          className={styles.periodInfo}
          dateFrom={dateFrom}
          dateTo={dateTo}
          dateFormat={dateFormat}
        />}
    </div>
  );
};

export default PlainDatePeriod;
