// @flow
import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import VendingDataProvider from 'app/dataProviders/VendingDataProvider';

import VendingMachinePage from './VendingMachinePage';
import getParentBaseUrl from './getParentBaseUrl';
import getBaseUrl from './getBaseUrl';


const parentBaseUrl = getParentBaseUrl();
const baseUrl = getBaseUrl({ id: ':id' });
const baseSection = getBaseUrl({ id: ':id', section: ':section' });
const baseCategotyId = getBaseUrl({ id: ':id', section: ':section', categoryId: ':categoryId' });

const OrgsListPageRoot = () => {
  return (
    <VendingDataProvider>
      <Switch>
        <Route path={baseUrl} component={VendingMachinePage} exact />
        <Route path={baseSection} component={VendingMachinePage} exact />
        <Route path={baseCategotyId} component={VendingMachinePage} exact />
        <Redirect to={parentBaseUrl} />
      </Switch>
    </VendingDataProvider>
  );
};

export default OrgsListPageRoot;
