// @flow
import React, { useEffect, useCallback } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import path from 'ramda/es/path';

import Loading from 'app/common/components/Loader';

import RoleItem from '../common/RoleItem';
import { packId, unpackId } from '../common/helpers';
import { useRequestsData, type RoleType, type PeriodType } from './RequestsData';
import CopySettings from '../CopySettings';

import styles from './OldOrdersRestrictionsForm.scss';


const DAYS_MAX_CURRENT: 365 = 365; // максимальное количество дней, которое можно выставить, для текущего периода
const DAYS_MAX_PAST: 28 = 28; // максимальное количество дней, которое можно выставить, для предыдущего периода
const DAYS_MIN_PAST: 1 = 1;

type Props = {
  type: RoleType,
  className?: string,
  disabled: boolean,
}

const OldOrdersRestrictionsForm = ({ type, className, disabled: disabledExternal }: Props) => {
  const {
    content: {
      oldOrdersRestrictions: {
        school,
        supplier,
        loading,
        errors,
      },
      submitting,
    },
    request: {
      oldOrdersRestrictions: {
        request,
        submit,
        checkErrorsByRoleAndPeriod,
      },
    },
    modify: {
      oldOrdersRestrictions: {
        updateField,
      },
    },
  } = useRequestsData();


  useEffect(() => {
    if (type === 'supplier') { // запрос загружает оба типа, но данный компонент вызывается на каждый тип
      request();
    }
  }, [request]); // eslint-disable-line react-hooks/exhaustive-deps


  const dayChange = useCallback((days, from) => {
    const [period, id] = unpackId(from);
    updateField(id, `${period}_period_days`, days);
  }, [updateField]);

  const restrictionEnable = useCallback((checked, from) => {
    const [period, id] = unpackId(from);
    updateField(id, `${period}_period_without_restrictions`, checked);
  }, [updateField]);

  const makeHandlerSubmit = useCallback((roleType: RoleType, period: PeriodType) => (orgIds: Array<number>) => {
    return submit(orgIds, roleType, period);
  }, [submit]);


  const data = type === 'school' ? school : supplier;
  const nothing = isEmpty(data);
  const disabled = disabledExternal || loading || submitting;


  return (
    <div className={className}>
      <div className={styles.commentContainer}>
        <div className={styles.comment}>
          {type === 'supplier'
            // для сотрудников ПП (поставщика питания)
            ? 'Ограничение на создание, редактирование, удаление заявок и продаж за прошлые дни в течении ТЕКУЩЕГО ОТЧЕТНОГО ПЕРИОДА*. Укажите количество дней, в течение которых сотрудникам можно работать с заявками после завершения дня:'
            // для сотрудников ОУ (образовательного учреждения)
            : 'Ограничение на создание, редактирование, проведение, удаление заявок за прошлые дни в течении ТЕКУЩЕГО ОТЧЕТНОГО ПЕРИОДА. Укажите количество дней, в течение которых сотрудникам можно работать с заявками после завершения дня:'}
        </div>

        {!disabled
          && <CopySettings
            onSubmit={makeHandlerSubmit(type, 'current')}
            onBeforeModalOpen={checkErrorsByRoleAndPeriod(type, 'current')}
          />}
      </div>

      {loading && nothing && <Loading />}

      <div className={styles.roleItems}>
        {data.map(({
          id,
          role_title: roleTitle,
          current_period_without_restrictions: currentPeriodWithoutRestrictions,
          current_period_days: currentPeriodDays,
        }) => {
          const error = path([id, 'current'], errors);

          return (
            <RoleItem
              key={id}
              id={packId(id, 'current')}
              className={styles.roleItem}
              title={roleTitle}
              days={currentPeriodDays}
              disabled={disabled}
              maxDays={DAYS_MAX_CURRENT}
              error={error}
              withoutRestrictions={currentPeriodWithoutRestrictions}
              onChangeDays={dayChange}
              onChangeRestriction={restrictionEnable}
            />
          );
        })}
      </div>

      <div className={styles.commentContainer}>
        <div className={styles.comment}>
          {type === 'supplier'
            // для сотрудников ПП (поставщика питания)
            ? 'Ограничение на создание, редактирование, проведение, удаление заявок и продаж за ПРЕДЫДУЩИЕ ОТЧЕТНЫЕ ПЕРИОДЫ*. Укажите до какого числа текущего отчетного периода, разрешено работать сотрудникам с заявками и продажами предыдущего отчетного периода:'
            // для сотрудников ОУ (образовательного учреждения)
            : 'Ограничение на создание, редактирование, проведение, удаление заявок за ПРЕДЫДУЩИЕ ОТЧЕТНЫЕ ПЕРИОДЫ. Укажите до какого числа текущего отчетного периода, разрешено работать сотрудникам с заявками предыдущего отчетного периода:'}
        </div>

        {!disabled
          && <CopySettings
            onSubmit={makeHandlerSubmit(type, 'past')}
            onBeforeModalOpen={checkErrorsByRoleAndPeriod(type, 'past')}
          />}
      </div>

      {loading && nothing && <Loading />}

      <div className={styles.roleItems}>
        {data.map(({
          id,
          role_title: roleTitle,
          past_period_without_restrictions: pastPeriodWithoutRestrictions,
          past_period_days: pastPeriodDays,
        }) => {
          const error = path([id, 'past'], errors);

          return (
            <RoleItem
              key={id}
              id={packId(id, 'past')}
              title={roleTitle}
              days={pastPeriodDays}
              disabled={disabled}
              maxDays={DAYS_MAX_PAST}
              minDays={DAYS_MIN_PAST}
              error={error}
              withoutRestrictions={pastPeriodWithoutRestrictions}
              onChangeDays={dayChange}
              onChangeRestriction={restrictionEnable}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OldOrdersRestrictionsForm;
