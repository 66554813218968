import { useState, useCallback, useEffect } from 'react'

import useLimitsSettings from './useLimitsSettings'


const UPDATE_INTERVAL = 1000
const BLOCK_INTERVAL = 3600000 // 1 час
let timer: null | ReturnType<typeof setTimeout> = null

export default function useMultipleBlockedMilliseconds(): [number, () => void] {
  const [{ changeLimitsBlockedUntil }, handleSaveSettings] = useLimitsSettings()
  const [remaining, setRemaining] = useState(0)


  const update = useCallback((r?: number) => {
    const rem = r ? r - Date.now() : changeLimitsBlockedUntil - Date.now()
    setRemaining(rem < 0 ? 0 : rem)
    if (rem > 0) {
      timer = setTimeout(update, UPDATE_INTERVAL)
    }
  }, [changeLimitsBlockedUntil])

  const blockNow = useCallback(() => {
    if (timer) clearTimeout(timer)
    const newTime = Date.now() + BLOCK_INTERVAL
    handleSaveSettings({ changeLimitsBlockedUntil: newTime })
    update(newTime)
  }, [update, handleSaveSettings])


  useEffect(() => {
    update()
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [changeLimitsBlockedUntil]) // eslint-disable-line react-hooks/exhaustive-deps

  return [remaining, blockNow]
}
