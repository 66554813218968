// @flow
import React, { Fragment, useMemo } from 'react';

import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';
import toFixedValue from 'app/common/lib/toFixedValue';

import type { Summary } from '../useReportData';
import type { CommonBalanceParams } from '../useDisplaySettings';


type Props = {
  summary: Summary,
  settings: CommonBalanceParams,
  currency: string,
  loading: boolean,
}

const SummaryComponent = ({ summary, settings, currency, loading }: Props) => {
  const rows = useMemo(() => {
    const { balanceNegative, balanceZero, balancePositive } = settings;
    const showPositiveValue = !balanceNegative && !balanceZero;
    const showNegativeValue = !balancePositive && !balanceZero;
    const showNegativePercents = !balanceNegative && !balanceZero && !balancePositive;
    const showTotal = !balanceNegative && !balancePositive && !balanceZero;

    const {
      count,
      neg,
      neg_percents: negPercents,
      pos,
      total,
    } = summary || {};

    const posText = `${toFixedValue(pos)} ${currency}`;
    const negText = `${toFixedValue(neg)} ${currency}`;
    const negPercentsText = `${toFixedValue(negPercents)} %`;
    const countText = String(count);
    const totalText = `${toFixedValue(total)} ${currency}`;

    const Row1 = [
      showPositiveValue ? ['Положительный баланс', posText] : null,
      showNegativeValue ? [
        'Отрицательный баланс',
        showNegativePercents ? [negText, negPercentsText] : negText,
      ] : null,
      ['Кол-во человек', countText],
    ].filter(Boolean);

    const Row2 = [
      ['ИТОГО', totalText],
    ];

    return (showTotal ? [Row1, Row2] : [Row1]);
  }, [summary, currency, settings]);


  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
              loading={loading}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default SummaryComponent;
