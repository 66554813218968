import { ChangeEvent, ReactNode, useCallback } from 'react'
import cn from 'classnames'

import PlainInput from 'app/common/ui-next/plain-input'
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainHelperText from 'app/common/ui-next/plain-helper-text'

import styles from './PresetValue.scss'


type Props = {
  className?: string
  index: number
  value: number
  showDeleteButton: boolean
  disabled?: boolean
  error?: string
  onDelete?: (index: number) => void
  onChange?: (index: number, value: number) => void
  children?: ReactNode
}

export const PresetValue = ({
  className,
  index,
  value,
  showDeleteButton = true,
  disabled,
  error,
  onDelete,
  onChange,
  children,
}: Props) => {
  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(index)
    }
  }, [index, onDelete])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return
    const { value: val } = e.currentTarget
    if (!val) {
      onChange(index, NaN)
    }
    const parsed = parseInt(val, 10)
    if (!Number.isNaN(parsed)) {
      onChange(index, parsed)
    }
  }, [index, onChange])

  return (
    <div className={error ? cn(className, 'scrollToError') : className}>
      <div className={styles.record}>
        <PlainInput
          className={styles.input}
          id={`camp_subsidy_orders_preset_${index}`}
          value={Number.isNaN(value) ? '' : String(value)}
          disabled={disabled}
          onChange={handleChange}
          error={!!error}
          displayAsBlock
        />
        {showDeleteButton
          && <PlainButtonAsLink
            className={styles.delete}
            disabled={disabled}
            onClick={handleDelete}
          >
            <PlainIcon
              type={iconTypes.LIGHT_CROSS}
              color={disabled ? colorTypes.COLOR_LIGHTGRAY : colorTypes.COLOR_GRAY}
            />
          </PlainButtonAsLink>}
        {children}
      </div>
      {!!error
        && <PlainHelperText error>{error}</PlainHelperText>}
    </div>
  )
}
