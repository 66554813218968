// @flow
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames/bind';
import moment from 'moment-timezone';
import objOf from 'ramda/es/objOf';
import F from 'ramda/es/F';
import reduce from 'ramda/es/reduce';

import PlainInputMasked from '../plain-input-masked/plain-input-masked';
import { maskTypes } from '../plain-input-masked/mask-types';
import PlainDropdownSurface, { SurfaceType } from '../plain-dropdown-surface';
import PlainIcon, { iconTypes, colorTypes } from '../plain-icon';
import PlainCalendar from '../plain-calendar';
import { dateFormat as dateFormatDefault } from './date-format';
import usePlainInputDate from './use-plain-input-date';

import styles from './plain-input-date.scss';

const cx = cn.bind(styles);

// type Exept = {
//   predefinedMaskType: any,
// }

export type PlainInputDateProps = {
  className?: string,
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean,
  onDateChange: (value: string, id?: string) => void,
  markedDatesFrom?: string,
  markedDatesTo?: string,
  calendarValues?: Array<string> | null,
  calendarCloseOnClick?: boolean,
  displayAsBlock?: boolean,
  dateFormat?: string,
  hideYear?: boolean,
  // ...$Rest<PlainInputMaskedProps, Exept>,
}&PlainInputMasketProps

const PlainInputDate = ({
  id,
  className,
  dateDisabledStatus,
  onDateChange,
  markedDatesFrom,
  markedDatesTo,
  calendarValues,
  calendarCloseOnClick = true,
  displayAsBlock,
  dateFormat = dateFormatDefault,
  hideYear,
  value: valueIncoming,
  disabled,
  ...others
}: PlainInputDateProps) => {
  const [value, dateValueChange, onBlur] = usePlainInputDate({
    id,
    date: valueIncoming,
    dateFormat,
    onChange: onDateChange,
  });

  const dateClick = useCallback((toggle: () => void) => (date: string, dayFormat: string) => {
    dateValueChange({
      formattedValue: date,
      valid: dateDisabledStatus ? !dateDisabledStatus(date, dayFormat) : true,
      dayFormat,
    });
    if (calendarCloseOnClick) {
      toggle();
    }
  }, [dateValueChange, dateDisabledStatus, calendarCloseOnClick]);

  const handleValueChange = useCallback(({ formattedValue: val }, { source }) => {
    if (source === 'event') { // только события изменения пользователем
      const mDate = moment(val, dateFormatDefault);
      const disabledStatus = dateDisabledStatus || F;
      const valid = val.length === dateFormatDefault.length && mDate.isValid();
      dateValueChange({
        formattedValue: val,
        valid: valid && !disabledStatus(val, dateFormatDefault),
        dayFormat: dateFormatDefault,
      });
    }
  }, [dateValueChange, dateDisabledStatus]);

  const daysSelected = useMemo(() => {
    if (calendarValues) {
      return reduce((acc, val) => {
        acc[val] = val;
        return acc;
      }, {}, calendarValues);
    }
    if (value) {
      const mValue = moment(value, dateFormatDefault);
      if (mValue.isValid()) {
        const fromValue = mValue.format(dateFormatDefault);
        return objOf(fromValue, fromValue);
      }
    }
    return {};
  }, [value, calendarValues]);

  const renderCalendar = (toggle: () => void) => (
    <PlainCalendar
      onDayClick={dateClick(toggle)}
      daysSelected={daysSelected}
      dateFormat={dateFormatDefault}
      dateDisabledStatus={dateDisabledStatus}
      markedDatesFrom={markedDatesFrom}
      markedDatesTo={markedDatesTo}
      hideYear={hideYear}
    />
  );

  return (
    <div className={cx(styles.root, { displayAsBlock, hideYear }, className)}>
      <PlainInputMasked
        id={id}
        name={id}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        {...others}
        onValueChange={handleValueChange}
        predefinedMaskType={hideYear ? maskTypes.MASK_DAY_MONTH : maskTypes.MASK_DATE}
      />
      <div className={styles.calendar}>
        <PlainDropdownSurface
          id="date"
          type={SurfaceType.Grid}
          surfaceContent={renderCalendar}
        >
          {(onClick, _, areaProps) => (
            <button
              type="button"
              className={cx(styles.button, { disabled })}
              onClick={onClick}
              disabled={disabled}
              {...areaProps}
            >
              <PlainIcon
                type={iconTypes.CALENDAR}
                colorType={disabled ? colorTypes.COLOR_GRAY : colorTypes.COLOR_PRIMARY}
                width={18}
                height="auto"
              />
            </button>
          )}
        </PlainDropdownSurface>
      </div>
    </div>
  );
};

export default PlainInputDate;
