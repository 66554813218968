// @flow
import { useState } from 'react';

import useDebouncedChange from 'app/common/hooks/useDebouncedChange';

type DebPair = [string, (s: string) => void]

export default function useParamsWDebouncedTitle(minLength: number): [DebPair, DebPair, DebPair, DebPair] {
  const [nomenclatureDeb, setNomenclature] = useState('');
  const [titleDeb, setTitle] = useState('');
  const [portionDeb, setPortion] = useState('');
  const [costDeb, setCost] = useState('');

  const [, titleChange] = useDebouncedChange(setTitle, { minLength });

  return [
    [nomenclatureDeb, setNomenclature],
    [titleDeb, titleChange],
    [portionDeb, setPortion],
    [costDeb, setCost],
  ];
}
