import { type ReactNode, useCallback, useEffect } from 'react'
import { values } from 'ramda'

import PlainButton from '../plain-button'
import ControlsBar from '../controls-bar'
import PlainCalendar, { useDaysSelectedState } from '../plain-calendar'

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from '../plain-modal'

/**
 *  Модальное окно с выбором дат
 */

type Props = {
  multiple: boolean
  dateItemsSelected: Array<string>
  children: (toggle: HandleModalDisplayed) => ReactNode
  onSubmit: (dates: Array<string>) => void
  getDayDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean
}

const PlainDateModal = ({ multiple, dateItemsSelected, children, onSubmit, getDayDisabledStatus }: Props) => {
  // текущий выбор на календаре
  const [daysSelected, {
    dayToggle,
    update: updateDaysSelected,
  }] = useDaysSelectedState({ multiple })

  const handleSubmit = useCallback(() => {
    onSubmit(values(daysSelected))
    return true
  }, [daysSelected, onSubmit])

  const handleClose = useCallback(() => {
    updateDaysSelected(dateItemsSelected)
  }, [updateDaysSelected, dateItemsSelected])

  useEffect(() => {
    updateDaysSelected(dateItemsSelected)
  }, [dateItemsSelected]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <PlainModal
      controlBy={children}
      onSubmit={handleSubmit}
      onClose={handleClose}
    >
      <PlainModalHeader>
        {'Выбор даты'}
      </PlainModalHeader>

      <PlainModalContent>
        <PlainCalendar
          daysSelected={daysSelected}
          onDayClick={dayToggle}
          dateDisabledStatus={getDayDisabledStatus}
        />
      </PlainModalContent>

      <PlainModalFooter>
        {handleModalDisplayed => (
          <ControlsBar middle>
            <PlainButton
              onClick={handleModalDisplayed}
              outline
            >
              {'Отмена'}
            </PlainButton>
            <PlainButton
              type="submit"
            >
              {'Выбрать'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}

export default PlainDateModal
