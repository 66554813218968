import React, { useCallback, useEffect, useState } from 'react'
import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { subscibeTo } from 'app/common/data-box-store'
import { OrgsOrUserIDs, defaultOrgsDataReceiver, defaultOrgsSelection, orgOrUserFilterSelection, orgTypesFilterSelection, orgsFilter, orgsFilterSelection } from 'app/dataProviders/filter-items'
import { OrgType } from 'app/dataTypes/org'


type Props = {
  id: string
  pending: boolean
}


export const OrgsFilter = ({ id, pending }: Props) => {
  const [isReady, setReady] = useState<boolean>(false)
  const [filterVisibility, setFilterVisibility] = useState<boolean>(true)

  const orgTypesFilterHandler = useCallback((data: OrgType[]) => {
    if (data.length === 1) {
      orgsFilter.addDataReceiver(
        defaultOrgsDataReceiver,
      )
      orgsFilter.dataReceive()
      setReady(true)
      return
    }
    orgsFilter.resetToDefault()
    setReady(false)
  }, [])


  const orgOrUserFilterHandler = useCallback((data: OrgsOrUserIDs) => {
    if (data === OrgsOrUserIDs.ByOrgs) {
      setFilterVisibility(true)
      return
    }
    orgsFilter.resetToDefault()
    setFilterVisibility(false)
    setReady(false)
  }, [])

  useEffect(() => {
    const orgOrUserUnsubsribe = subscibeTo<OrgsOrUserIDs>(orgOrUserFilterSelection.id, orgOrUserFilterHandler)
    const unsubsribeOrgType = subscibeTo<OrgType[]>(orgTypesFilterSelection.id, orgTypesFilterHandler)
    return (() => {
      orgOrUserUnsubsribe()
      unsubsribeOrgType()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    filterVisibility && isReady && <PlainItemsSelectorBox
      id={id}
      label="Заведение"
      header="Выбор заведения"
      dataBoxData={orgsFilter}
      dataBoxSelection={orgsFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      disabled={pending}
      multiple
      defaultSelection={defaultOrgsSelection}
    />
  )
}
