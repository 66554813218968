// @flow
import type { Request } from '../index';


type ColorsParams = {}

export type ColorsResult = {
  'primary-color': string,
  'active-primary-color': string,
  'hover-primary-color': string,
  'subitem-active-primary-color': string,
  'active-secondary-color': string,
  'link-color': string,
  'button-primary': string,
  'button-primary-hover': string
}

export const defaultState: ColorsResult = {
  'primary-color': '#ff0000',
  'active-primary-color': '#b802bb',
  'hover-primary-color': '#038dd1',
  'subitem-active-primary-color': '#01578b',
  'active-secondary-color': '#e3f2fd',
  'link-color': '#e203db',
  'button-primary': '#039be5',
  'button-primary-hover': '#0387c7',
};

export const GET_CABINET_COLORS: Request<ColorsParams, ColorsResult> = ['cabinet/colors', 'get', ['data', 'colors']];
