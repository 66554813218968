

import { CATEGORIES_SUCCESS } from 'app/actions/orgs/menu/categories';

const defaultState = {};

const categories = (state = defaultState, action) => {
  if (action.type === CATEGORIES_SUCCESS) {
    return { ...action.payload };
  }
  return state;
};

export default categories;
