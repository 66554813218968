// @flow
import React, { type Node, Fragment, useMemo } from 'react';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { Summary as SummaryType } from '../types';


const convertToString = (val: number | void, currency: string) => {
  return (typeof val === 'number'
    ? `${toFixedValue(val)} ${currency}`
    : '—'
  );
};

type Props = {
  summary: SummaryType,
  currency: string,
}

const SummarySpecial = ({ summary, currency }: Props): Node => {
  const rows = useMemo(() => {
    const {
      'Внутренний счет': internalSum,
      Наличные: cashSum,
      'Банковская карта': cardSum,
      'Итого покупателей': totalCustomer,
      Итого: total,
      'Сумма дотаций': subsidiesSum,
      'Итого без учета дотаций': totalWithoutSubsidies,
    } = summary;


    const internalSumText = convertToString(internalSum, currency);
    const cashSumText = convertToString(cashSum, currency);
    const cardSumText = convertToString(cardSum, currency);
    const totalCustomerText = totalCustomer.toString();
    const totalText = convertToString(total, currency);
    const subsidiesSumText = convertToString(subsidiesSum, currency);
    const totalWithoutSubsidiesText = convertToString(totalWithoutSubsidies, currency);

    return [
      // Row 1
      [
        ['Внутренний счет', internalSumText],
        ['Наличные', cashSumText],
        ['Банковская карта', cardSumText],
      ],
      // Row 2
      [
        ['Итого покупателей', totalCustomerText],
        ['Итого', totalText],
      ],
      // Row 3
      [
        ['Сумма дотаций', subsidiesSumText],
        ['Итого без учета дотаций', totalWithoutSubsidiesText],
      ],
    ];
  }, [summary, currency]);

  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default SummarySpecial;
