import { useCallback } from 'react'

import CommonPage from 'app/common/templates/CommonPage'
import FinancesRoot from 'app/components/finances'

import getBaseUrl from './getBaseUrl'


const baseUrl = getBaseUrl()

const FinancesRootPage = () => {
  const createLink = useCallback((reportKey: string) => {
    return `${baseUrl}/${reportKey}`
  }, [])

  return (
    <CommonPage header="Финансы">
      <FinancesRoot createLink={createLink} />
    </CommonPage>
  )
}

export default FinancesRootPage
