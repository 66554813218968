// @flow
import { isEmpty, find, findIndex, unnest } from 'ramda';

import Observable, { type IObservable } from './observable';


export interface IGrid<T> extends IObservable {
  items: Array<Array<T>>,
  columns: number,
  rows: number,
  empty: boolean,
  map(callbackfn: (value: Array<T>, index: number, array: Array<Array<T>>) => any): any,
  forEach: (callbackfn: (value: Array<T>, index: number, array: Array<Array<T>>) => void) => void,
  toString(): string,
  log(): void,
  asList(): $ReadOnlyArray<T>,
  getRow(row: number | Array<T>): Array<T>,
  findIndexInRow(cb: (T) => boolean, row: Array<T>): number,
  findItemInRow(cb: (T) => boolean, row: Array<T>): ?T
}

export default class Grid<T> extends Observable implements IGrid<T> {
  #items: Array<Array<T>>;

  #columns: number;

  #rows: number;

  map: (callbackfn: (value: Array<T>, index: number, array: Array<Array<T>>) => any) => any;

  forEach: (callbackfn: (value: Array<T>, index: number, array: Array<Array<T>>) => void) => void;

  constructor(
    items?: Array<Array<T>> = [],
    columns: number,
    rows: number,
  ) {
    super();
    this.#items = items;
    this.#columns = columns;
    this.#rows = rows;
    this.map = Array.prototype.map.bind(this.#items);
    this.forEach = Array.prototype.forEach.bind(this.#items);
  }

  get items(): Array<Array<T>> {
    return this.#items;
  }

  get columns(): number {
    return this.#columns;
  }

  get rows(): number {
    return this.#rows;
  }

  get empty(): boolean {
    return isEmpty(this.#items);
  }

  toString(): string {
    return JSON.stringify(this.#items, null, 4);
  }

  log(): void {
    console.log(this.#items);
  }

  asList(): $ReadOnlyArray<T> {
    return unnest<T>(this.#items);
  }

  getRow(row: number | Array<T>): Array<T> {
    const rowExtracted = row instanceof Array ? row : this.#items[row];
    if (!rowExtracted) {
      console.error('👻 Grid.getRow индекс строки вылетел за область доступных значений');
      return [];
    }
    return rowExtracted;
  }

  findIndexInRow(cb: (T) => boolean, row: Array<T>): number {
    return findIndex(cb, this.getRow(row));
  }

  findItemInRow(cb: (T) => boolean, row: Array<T>): ?T {
    return find(cb, this.getRow(row));
  }
}
