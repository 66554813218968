// @flow
import type { Request } from '../../index';


export type BuildingItem = {
  id: number,
  title: string,
}

export type MenuBuildingsResult = {
  [id: string]: BuildingItem
}

type MenuBuildingsUrlParams = {
  orgId: number | string,
}

export const GET_BUILDINGS: Request<
  void,
  MenuBuildingsResult,
  MenuBuildingsUrlParams
> = [
  'food/schools/{orgId}/buildings',
  'get',
  ['data', 'buildings'],
];
