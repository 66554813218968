import { CompensationAction } from 'app/dataTypes/compensation/action'

import type { Request } from '../../../index'


export type ApiParams = {
  year: number
  month: number
}

export type UrlParams = {
  orgID: string
  action: 'record' | 'cancel' | 'interrupt'
}

export const convertParams = (params: CompensationAction): [ApiParams, UrlParams] => {
  const { orgID, year, month, action } = params
  if (action !== 'record' && action !== 'cancel' && action !== 'interrupt') {
    throw new Error('Неверно передан параметр action в POST_COMPENSATION_ACTION')
  }
  return [{
    year: parseInt(year, 10),
    month: parseInt(month, 10),
  }, {
    orgID,
    action,
  }]
}

export const convertResult = (result: 'success'): boolean => {
  return !!result
}

export const POST_COMPENSATION_ACTION: Request<CompensationAction, boolean, UrlParams> = [
  'food/compensation/{orgID}/{action}',
  'post',
  ['data', 'result'],
]
