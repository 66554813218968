import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import withProps from 'recompose/withProps';
import lifecycle from 'recompose/lifecycle';

import { PASSWORD_EMPTY } from 'app/common/constants/password';
import { examplePhoneNumbers } from 'app/common/lib/phone';
import { appTypes } from 'app/common/api';

import Main from './Main';
import Restore from './Restore';
import Agreement from './Agreement';
import Activation from './Activation';
import Register from './Register';
import Blocked from './Blocked';


export type LoginInfo = {
  login: string,
  agreementUrl: string,
  accepted: boolean,
  passwordType: string,
  isLoginTemp: boolean,
  code2Mail: boolean,
  //  Если accepted === false и уже есть agreementUrl показываем окно соглашения
};

const defaultLoginInfo = {
  login: '',
  agreementUrl: '',
  accepted: true,
  passwordType: null,
  isLoginTemp: false,
  code2Mail: false,
  authDisabled: false,
};

// const components = {
//   main: () => Main,
//   restore: () => Restore,
//   agreement: () => Agreement,
//   activation: () => Activation,
//   register: () => Register,
// };

export default compose(
  withState('title', 'setTitle', ''),
  withState('component', 'setComponent', () => Main), // *** здесь можно подменить первое окно (для проверки)
  withState('loginInfo', 'setLoginInfo', defaultLoginInfo),
  withState('prevComp', 'setPrevComp', null),

  withProps(({ countryISOCode }) => ({ exampleFormat: examplePhoneNumbers.getByCode(countryISOCode) })),

  withHandlers({
    handleRestore: ({ setComponent, setPrevComp }) => (e) => {
      e.preventDefault();
      setComponent(() => Restore);
      setPrevComp(() => Main);
    },

    handleMain: ({ setComponent, setPrevComp }) => (e) => {
      e.preventDefault();
      setComponent(() => Main);
      setPrevComp(() => Main);
    },

    handleBack: ({ prevComp, setComponent }) => (e) => {
      e.preventDefault();
      setComponent(() => (prevComp || Main));
    },

    handleAccept: ({ prevComp, setComponent, loginInfo, setLoginInfo }) => (e) => {
      e.preventDefault();
      const { passwordType } = loginInfo;
      setLoginInfo({ ...loginInfo, accepted: true });
      if (passwordType === PASSWORD_EMPTY) {
        setComponent(() => (Activation));
      } else {
        setComponent(() => (prevComp || Main));
      }
    },

    handleRegister: ({ setComponent, setPrevComp }) => (e) => {
      e.preventDefault();
      setComponent(() => Register);
      setPrevComp(() => Main);
    },
  }),


  lifecycle({
    componentDidUpdate(prevProps) {
      const { loginInfo } = this.props;
      if (loginInfo !== prevProps.loginInfo) {
        const { setComponent, setPrevComp, termsAcceptRequired } = this.props;
        const { agreementUrl, accepted, authDisabled, passwordType } = loginInfo;
        const disabledByCabinetType = (
          process.env.APP_NAME || process.env.REACT_APP_NAME
        ) === appTypes.APP_TYPE_FOOD;

        if (!disabledByCabinetType && agreementUrl && accepted === false && termsAcceptRequired) {
          setComponent(() => Agreement);
          setPrevComp(() => Main);
        }
        if (authDisabled) {
          setComponent(() => Blocked);
          setPrevComp(() => Main);
        }
        if (passwordType === PASSWORD_EMPTY) {
          setComponent(() => (Activation));
          setPrevComp(() => Main);
        }
      }
    },
  }),

);
