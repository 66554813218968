import { useCallback, useState } from 'react'

import { Space } from 'app/common/templates-next/report-template'
import { useCompensationAction, useCompensationDetails, useCompensationInfoAll } from 'app/dataProviders/compensation/hooks'
import { useCurrencySign } from 'app/common/components-next/currency'

import { Filters } from './filters'
import { Result } from './result'
import { useDetailsTable } from './result/details/useDetailsTable'


type Params = {
  orgIDs: Array<string>
  month: string
  year: string
}

export const Accruals = () => {
  const [params, setParams] = useState<null | Params>(null)
  const { loading, loadingOrgs, data, refetchCompensationInfoByOrgID } = useCompensationInfoAll(params)
  const compensationAction = useCompensationAction(params, refetchCompensationInfoByOrgID)
  const currency = useCurrencySign()

  const singleOrgDataUpdate = useCallback((orgID: string) => {
    refetchCompensationInfoByOrgID(orgID, { pendingIndicate: false })
  }, [refetchCompensationInfoByOrgID])

  const singleOrgDataUpdateExtended = useCallback(async (orgID: string) => {
    const res = await refetchCompensationInfoByOrgID(orgID, { extended: true, pendingIndicate: false })
    return res
  }, [refetchCompensationInfoByOrgID])

  const { details, request } = useCompensationDetails({ month: params?.month, year: params?.year })
  const { renderDetailsTable, renderDetailsTableFooter } = useDetailsTable({
    details,
    onRequest: request,
    currency,
  })

  return (
    <>
      <Filters onSubmit={setParams} />

      {params
        && <>
          <Space />
          <Result
            loading={loadingOrgs}
            lockSelectionMode={loading}
            data={data}
            year={params.year}
            month={params.month}
            currency={currency}
            onAction={compensationAction}
            onRowDataUpdateRequired={singleOrgDataUpdate}
            onRequestInfo={singleOrgDataUpdateExtended}
            compensationDetailsTable={renderDetailsTable}
            compensationDetailsTableFooter={renderDetailsTableFooter}
          />
        </>}
    </>
  )
}
