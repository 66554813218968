// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames';

import { Table, Tr, Td } from 'app/common/ui-components/table';

import type { Multiple } from '../useReportData';
import { rowId } from './FooterLeftContent';


type Props = {
  className?: string,
  trClassName?: string,
  totalsColumns: $PropertyType<Multiple, 'totalsColumns'>,
  tableRef?: Ref<'table'>,
  trRef?: Ref<'tr'>,
  sameColumnBase?: string,
  onCellMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  children: (content: string) => Node,
}

const FooterMiddleContent = ({
  className,
  trClassName,
  totalsColumns,
  tableRef,
  trRef,
  sameColumnBase,
  onCellMouseEnter,
  onCellMouseLeave,
  children,
}: Props): Node => {
  return (
    <Table reference={tableRef} className={className}>
      <tfoot>
        <Tr reference={trRef} className={trClassName}>
          {totalsColumns.map(({ key, value }, i) => (
            <Td
              key={key}
              headers={cn(key, rowId)}
              className={cn(sameColumnBase && `${sameColumnBase}${i}`)}
              onMouseEnter={onCellMouseEnter}
              onMouseLeave={onCellMouseLeave}
              alignRight
            >
              {children(value)}
            </Td>
          ))}
        </Tr>
      </tfoot>
    </Table>
  );
};

export default FooterMiddleContent;
