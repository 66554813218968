// @flow
import trim from 'ramda/src/trim';

import { mask } from 'app/common/lib/phone';

import {
  phoneReRUOnlyNumbers,
  phoneReKGOnlyNumbers,
  phoneReUAOnlyNumbers,
  validateLoginAsEmail,
} from './validate';


const login2Strict = (loginOriginal: string) => {
  const login = trim(loginOriginal);

  if (validateLoginAsEmail(login)) {
    return login;
  }

  const numbers = login.replace(/\D+/g, '');

  if (phoneReRUOnlyNumbers.test(numbers)) {
    return mask.apply(numbers, 'ru');
  }
  if (phoneReKGOnlyNumbers.test(numbers)) {
    return mask.apply(numbers, 'kg');
  }
  if (phoneReUAOnlyNumbers.test(numbers)) {
    return mask.apply(numbers, 'ua');
  }
  return login;
};

export default login2Strict;
