// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import identity from 'ramda/es/identity';
import evolve from 'ramda/es/evolve';
import pipe from 'ramda/es/pipe';

import type { Sort } from 'app/common/hooks/useSortState';
import { tableColumnSortClass } from 'app/common/lib/sort';

import type { ReportKlass, ReportEmployee } from '../useReportData';
import { displayableData, type DisplayableData } from '../displayableData';


const emptyArray: Array<any> = [];

export type KlassesEmployeesDisplayableItem = {
  id: number,
  title: string,
  byDate: Array<{
    date: string,
    data: DisplayableData,
  }>,
  total: DisplayableData,
  totalSortable: number,
}

export default function useActualData(
  klasses: ?Array<ReportKlass> = emptyArray,
  employees: ?ReportEmployee,
  guest: ?ReportEmployee,
  sort: Sort,
): Array<KlassesEmployeesDisplayableItem> {
  const { sortKey, sortDirection } = sort;

  const actualKlasses: Array<KlassesEmployeesDisplayableItem> = useMemo(() => {
    return pipe(
      map(({ klass_id: id, klass_title: title, total, data: dataByDate }: ReportKlass) => ({
        id,
        title,
        byDate: map(evolve({ date: identity, data: displayableData }), dataByDate),
        total: displayableData(total),
        totalSortable: prop('total_sales_sum', total),
      })),
    )(klasses);
  }, [klasses]);

  const actualEmployees: Array<KlassesEmployeesDisplayableItem> = useMemo(() => {
    if (employees) {
      const { total, data: dataByDate } = employees;
      return [{
        id: -111,
        title: 'Сотрудники',
        byDate: map(evolve({ date: identity, data: displayableData }), dataByDate),
        total: displayableData(total),
        totalSortable: prop('total_sales_sum', total),
      }];
    }
    return emptyArray;
  }, [employees]);

  const actualGuest: Array<KlassesEmployeesDisplayableItem> = useMemo(() => {
    if (guest) {
      const { total, data: dataByDate } = guest;
      return [{
        id: -112,
        title: 'Гости',
        byDate: map(evolve({ date: identity, data: displayableData }), dataByDate),
        total: displayableData(total),
        totalSortable: prop('total_sales_sum', total),
      }];
    }
    return emptyArray;
  }, [guest]);

  const actualDataSorted: Array<KlassesEmployeesDisplayableItem> = useMemo(() => {
    if (sortKey === 'totalSortable') {
      return tableColumnSortClass(sortDirection, prop(sortKey), [
        ...actualGuest,
        ...actualEmployees,
        ...actualKlasses,
      ]);
    }

    return [
      ...actualGuest,
      ...actualEmployees,
      ...tableColumnSortClass(sortDirection, prop(sortKey), actualKlasses),
    ];
  }, [actualKlasses, actualEmployees, sortDirection, sortKey, actualGuest]);

  return actualDataSorted;
}
