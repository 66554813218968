// @flow
import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import styles from './sort-arrow-control.scss';


const cx = classNames.bind(styles);

export type SortArrowProps = {
  sortKey?: string,
  sortDirection?: 'up' | 'down' | 'unsorted',
  onClick?: (direction: 'up' | 'down', key: string) => void,
}

const SortArrowControl = ({ onClick, sortDirection = 'unsorted', sortKey }: SortArrowProps) => {
  const handleClick = useCallback(() => {
    if (onClick && sortKey) {
      onClick(sortDirection === 'up' ? 'down' : 'up', sortKey);
    }
  }, [onClick, sortDirection, sortKey]);

  if (!sortKey) {
    return null;
  }

  return (
    <PlainButtonAsLink onClick={handleClick} className={styles.root}>
      <div
        className={cx(styles.sortable, {
          sortedUp: sortDirection === 'up',
          sortedDown: sortDirection === 'down',
        })}
      />

    </PlainButtonAsLink>
  );
};

export default SortArrowControl;
