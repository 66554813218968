// @flow
import { useCallback, useState, useEffect } from 'react';
import path from 'ramda/es/path';

import { api } from 'app/api';
import { GET_REQUISITES } from 'app/common/api/requests/food/requisites/get';


export default function useFoodProvider() {
  const [title, setTitle] = useState<string>('');

  const requestData = useCallback(async () => {
    const res = await api.request(GET_REQUISITES, {
      error: 'Не удалось получить данные поставщика питания',
    });

    if (res) {
      const t = path(['requisites', 'title'], res);
      setTitle(t || '—');
    }
  }, []);

  useEffect(() => {
    requestData();
  }, [requestData]);

  return title;
}
