// @flow

export const columns: [
  'id',
  'name',
  'photo',
  'portion',
  'cost',
] = [
  'id',
  'name',
  'photo',
  'portion',
  'cost',
];

export type ColumnVisibility = {
  photo: boolean,
  portion: boolean,
  cost: boolean,
}

export const defaultColumnVisibility: ColumnVisibility = {
  photo: true,
  portion: true,
  cost: true,
};
