import { useCallback } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import isEmpty from 'ramda/es/isEmpty'

import { Table, Tr, Td, Th } from 'app/common/ui-components/table'
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog'
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainTooltip from 'app/common/ui-next/plain-tooltip'
import { dishDelete } from 'app/actions/orgs/kits/dishDelete'
import usePopup from 'app/common/hooks/usePopup'
import { rootRights, specificRights } from 'app/rights'
import { WithRightsForOrg } from 'app/rights/RightsForOrg'

import ValueSaveOptionsDialog from './ValueSaveOptionsDialog'
import type { Dish } from '../kits2ExtendedItemsList'
import MainContentClearAll from './MainContentClearAll'
import { EditablePropWithDisable } from './EditablePropWithDisable'

import styles from './MainKitContent.scss'


const dishesRights = [rootRights.DISHES, specificRights.PROVIDER_DISHES] as const
const kitsConsistRights = [rootRights.COMPLEXES, specificRights.COMPLEXES_COMPOSITION] as const

type Props = {
  className?: string
  dishes: Array<Dish>
  currency: string
  id: number
  orgID: string
  date: string
  title: string
  disabled?: boolean
}

export const MainKitContent = ({
  className,
  dishes,
  currency,
  id,
  orgID,
  date,
  title,
  disabled,
}: Props) => {
  const dispatch = useDispatch()
  const popup = usePopup()

  const handleDishDelete = useCallback((dishId: number) => {
    dispatch(dishDelete(orgID, { date, kitId: id }, dishId))
    popup('Блюдо было успешно удалено из комплекта')
  }, [dispatch, popup, date, id, orgID])


  if (isEmpty(dishes)) {
    return null
  }

  return (
    <Table className={cn(styles.root, className)}>
      <thead>
        <Tr hoverHighlighted={false} className={styles.header}>
          <Th stripPadding alignLeft>{'Наименование'}</Th>
          <Th stripPadding alignRight>{'Порция'}</Th>
          <Th stripPadding alignRight>{`Цена,${currency}`}</Th>
          <Th stripPadding alignCenter>
            {!disabled
              && <MainContentClearAll
                orgID={orgID}
                kitId={id}
                date={date}
                title={title}
              />}
          </Th>
        </Tr>
      </thead>
      <tbody>
        {dishes.map(({ id: itemId, title: itemTitle, portion, cost }) => (
          <Tr key={itemId} className={styles.row}>
            <Td stripPadding>{itemTitle}</Td>

            <Td className={styles.cellValue} stripPadding>
              <WithRightsForOrg pathTo={dishesRights} disabled={disabled}>
                <EditablePropWithDisable
                  className={styles.value}
                  value={portion}
                  name="portion"
                  right
                >
                  {(showDialog, value, closeOptionsDialog) => (
                    <ValueSaveOptionsDialog
                      isOpen={showDialog}
                      value={value}
                      onClose={closeOptionsDialog}
                      orgId={orgID}
                      kitId={id}
                      date={date}
                      id={itemId}
                      property="portion"
                    />)}
                </EditablePropWithDisable>
              </WithRightsForOrg>
            </Td>

            <Td className={styles.cellValue} stripPadding>
              <WithRightsForOrg pathTo={dishesRights} disabled={disabled}>
                <EditablePropWithDisable
                  className={styles.value}
                  value={cost}
                  name="cost"
                  disabled={disabled}
                  right
                >
                  {(showDialog, value, closeOptionsDialog) => (
                    <ValueSaveOptionsDialog
                      isOpen={showDialog}
                      value={value}
                      onClose={closeOptionsDialog}
                      orgId={orgID}
                      kitId={id}
                      date={date}
                      id={itemId}
                      property="cost"
                    />)}
                </EditablePropWithDisable>
              </WithRightsForOrg>
            </Td>

            <Td className={styles.cellCross} alignCenter stripPadding>
              {!disabled
                && <WithRightsForOrg pathTo={kitsConsistRights} editAffect="hide" readAffect="hide">
                  <ConfirmationDialog
                    onConfirm={handleDishDelete}
                    confirmParams={itemId}
                    text={`Вы уверены, что хотите удалить «${itemTitle}» из комплекта?`}
                    confirmButtonText="Удалить"
                    confirmButtonProps={{ error: true }}
                  >
                    {handleConfirm => (<PlainButtonAsLink onClick={handleConfirm}>
                      <PlainTooltip text="Удалить продукцию">
                        <PlainIcon
                          type={iconTypes.LIGHT_CROSS}
                          colorType={colorTypes.COLOR_DARKGRAY}
                        />
                      </PlainTooltip>
                    </PlainButtonAsLink>)}
                  </ConfirmationDialog>
                </WithRightsForOrg>}
            </Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  )
}
