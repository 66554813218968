import cond from 'ramda/es/cond';
import equals from 'ramda/es/equals';
import always from 'ramda/es/always';
import set from 'ramda/es/set';
import lensProp from 'ramda/es/lensProp';
import either from 'ramda/es/either';
import T from 'ramda/es/T';

import {
  PROPS_REQUEST,
  PROPS_SUCCESS,
  PROPS_FAILURE,
  PROPS_EDIT,
  PROPS_CLEAR,
} from 'app/actions/props';

import {
  PROPS_SUBMIT_REQUEST,
  PROPS_SUBMIT_SUCCESS,
  PROPS_SUBMIT_FAILURE,
} from 'app/actions/props/propsSubmit';

const defaultState = {
  loading: true,
  saving: false,
  isModified: false,
};

const options = (state = defaultState, { type }) => cond([
  [equals(PROPS_REQUEST),
    always(set(lensProp('loading'), true, state)),
  ],
  [either(equals(PROPS_SUCCESS), equals(PROPS_FAILURE)),
    always(set(lensProp('loading'), false, state)),
  ],
  [equals(PROPS_SUBMIT_REQUEST),
    always(set(lensProp('saving'), true, state)),
  ],
  [equals(PROPS_SUBMIT_FAILURE),
    always(set(lensProp('saving'), false, state)),
  ],
  [equals(PROPS_SUBMIT_SUCCESS),
    always({ ...state, saving: false, isModified: false }),
  ],
  [equals(PROPS_EDIT),
    always(set(lensProp('isModified'), true, state)),
  ],
  [equals(PROPS_CLEAR),
    always(defaultState),
  ],
  [T, always(state)],
])(type);

export default options;
