import { Tr, Th } from 'app/common/ui-components/table'

import VendingGear from './VendingGear'
import columns, { type ColumnVisibility } from './columns'


type Props = {
  settings: ColumnVisibility,
  stuck: boolean,
  shadow: boolean,
}

const THeadRow = ({ settings, stuck, shadow }: Props) => {
  const gear = <VendingGear />

  return (
    <Tr
      headerHasBorderBottom={false}
      header
    >
      {columns.map(({ id, title, props }) => (
        settings[id]
          ? <Th
            key={id}
            borderShadowBottom={shadow}
            borderTopCompensate={stuck}
            bgFilled
            borderBottom
            {...props}
          >
            {title || gear}
          </Th>
          : null
      ))}
    </Tr>
  )
}

export default THeadRow
