import { createSelector } from 'reselect';
import trim from 'ramda/es/trim';

import get from 'app/common/lib/get';


const selectItems = createSelector(
  [
    state => state.orgs.dishes.listedit.items,
    (_, props) => props.items || [],
  ],
  (dishes, items) => items.map(key => ({
    id: key,
    title: trim(get(dishes, [key], 'Неизвестная продукция')),
  })),
);

export default selectItems;
