import { createSelector } from 'reselect';
import get from 'app/common/lib/get';


const filterActiveSelector = createSelector(
  [
    state => get(state, 'dishes.filter.fields', {}),
  ],
  fields => !!Object.keys(fields).find(key => fields[key] !== null),
);

export default filterActiveSelector;
