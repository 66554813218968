import { ReactNode, useCallback, useState } from 'react'

import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'
import PlainInput from 'app/common/ui-next/plain-input'
import PlainLabel from 'app/common/ui-next/plain-label'

import PlainModal, {
  PlainModalContent,
  PlainModalFooter,
  PlainModalHeader,
  HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal'

import styles from './ActionButtonWithPrompt.scss'


type Props = {
  title: string
  confirmText: string
  onConfirm: (comment: string) => Promise<boolean>
  children: (toggle: HandleModalDisplayed) => ReactNode
}

export const ActionButtonWithPrompt = ({ title, confirmText, onConfirm, children }: Props) => {
  const [value, setValue] = useState('')

  const handleSubmit = useCallback((form: HTMLFormElement) => {
    const formData = new FormData(form)
    const comment = formData.get('comment') as string
    onConfirm(comment)
    return true
  }, [onConfirm])

  const statusChange = useCallback((display: boolean) => {
    if (!display) {
      setValue('')
    }
  }, [])

  return (
    <PlainModal
      formId="commentForm"
      onSubmit={handleSubmit}
      controlBy={children}
      className={styles.root}
      onDisplayStatusChange={statusChange}
    >
      <PlainModalHeader>
        {title}
      </PlainModalHeader>

      <PlainModalContent>
        <PlainLabel htmlFor="comment" displayAsBlock>
          {confirmText}
        </PlainLabel>
        <PlainInput
          name="comment"
          id="comment"
          onValue={setValue}
          value={value}
          autoFocus
          wide
        />
      </PlainModalContent>

      <PlainModalFooter>
        {toggle => (
          <ControlsBar middle>
            <PlainButton onClick={toggle} outline>
              {'Отмена'}
            </PlainButton>
            <PlainButton type="submit" disabled={!value}>
              {'Отправить'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}
