import { useCallback, useState } from 'react'

import { api } from 'app/api'
import { GET_REPORTS_DATA, convertParams, convertResult } from 'app/common/api/requests/reports/data/get'
import { AnyReportType } from 'app/dataTypes/reports'
import { ReportData, ReportDataParams } from 'app/dataTypes/reports/data'


export function useReportResult<RT extends AnyReportType>(reportID: string | null, reportType: RT) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ReportData<RT> | null>(null)

  const requestResult = useCallback(async () => {
    if (!reportID) return
    setData(null)
    setLoading(true)

    const params: ReportDataParams<RT> = { reportID, reportType }
    const res = await api.request(GET_REPORTS_DATA, {
      error: 'Не удалось получить данные результата отчета',
      params,
      convertions: {
        convertParams,
        convertResult: convertResult<RT>,
      },
    })

    if (res) {
      setData(res)
    }

    setLoading(false)
  }, [reportID, reportType])

  return {
    data,
    requestResult,
    loading,
  }
}
