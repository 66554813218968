import { reduce } from 'ramda'

import { OrgType } from 'app/dataTypes/org/types'
import { useMemo } from 'react'


const defaultArrange = [
  OrgType.School,
  OrgType.Preschool,
  OrgType.HighSchool,
  OrgType.Enterprise,
] as const

type Options<T extends 'default' | 'custom'> = {
  arrange: T
  customArrange?: T extends 'default' ? undefined : Array<OrgType>
}

export default function useOrgKnownTypes<T extends 'default' | 'custom'>(
  orgTypes: ReadonlyArray<string>,
  options: Options<T>
) {
  const { arrange, customArrange = defaultArrange } = options
  const actualArrange = arrange === 'default' ? defaultArrange : customArrange

  const res = useMemo(() => {
    const dataSet = new Set(orgTypes)

    return reduce((acc, val) => {
      if (dataSet.has(val)) {
        acc.push(val)
      }
      return acc
    }, [] as Array<OrgType>, actualArrange)
  }, [orgTypes, actualArrange])

  return res
}
