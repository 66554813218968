// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import LangSwitcher from '../../ui/LangSwitcher';
import { appTypes } from '../../api';

import IconApple from './icons/IconApple';
import IconGooglePlay from './icons/IconGooglePlay';
import styles from './stores.scss';


type Props = {
  iosAppUrl: ?string,
  androidAppUrl: ?string,
}

const Stores = ({ iosAppUrl, androidAppUrl }: Props) => {
  const { t } = useTranslation('app');

  return (
    <div className={styles.root}>
      {/* Перекоючение языка (только в кабинете родителей) */}
      {(process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_CABINET
        && <LangSwitcher className={styles.langSwitcher} />}
      {/* Ссылки на мобильные приложения */}
      {(androidAppUrl || iosAppUrl)
        && <span>
          {t('footer_mobile')}
          {':'}
          {'\u00A0'}
        </span>}
      {androidAppUrl
        && <a
          href={androidAppUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconGooglePlay className={styles.orig} />
          <IconGooglePlay className={styles.hover} />
        </a>}
      {iosAppUrl
        && <a
          href={iosAppUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconApple className={styles.orig} />
          <IconApple className={styles.hover} />
        </a>}
    </div>
  );
};

export default Stores;
