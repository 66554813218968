// @flow
import { useCallback, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import type { HireFields } from 'app/reducers/employees/hireform';
import { popup } from 'app/common/actions/popup';
import { hireformClear, hire } from 'app/actions/employees/hireform';
import useCallbackRef from 'app/common/hooks/useCallbackRef';

import * as employeeTypes from '../../employeeTypes';


export const orgPrefix = 'org_';

type Selected = {
  selectedOrg: null | number,
  selectedRole: null | number,
}

type Actions = {
  changeOrg: (e: SyntheticEvent<HTMLInputElement>) => void,
  selectRole: ({ id: number }) => void,
  submitStep2: () => Promise<boolean>,
}

type Errors = {
  errorOrg: string,
  errorRole: string,
}

const defaultErrors: Errors = {
  errorOrg: '',
  errorRole: '',
};

type Options = {
  onClose: (e?: SyntheticEvent<HTMLElement>) => void,
  onSuccess?: () => void,
  employeeType: $Values<typeof employeeTypes>,
}

export default function useHireFormStep2({ onClose, onSuccess, employeeType }: Options): [
  Selected,
  Actions,
  Errors,
  boolean
] {
  const dispatch = useDispatch();
  const form: HireFields = useSelector(state => state.employees.hireform, shallowEqual);

  const [errors, setErrors] = useState(defaultErrors);
  const [selectedOrg, setSelectedOrg] = useState<null | number>(null);
  const [submitting, setSubmitting] = useState(false);

  const handleChangeOrg = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (checked) {
      setErrors(defaultErrors);
      const clearReg = new RegExp(`^${orgPrefix}`);
      const id = value.replace(clearReg, '');
      setSelectedOrg(parseInt(id, 10));
    }
  }, []);

  const [selectedRole, setSelectedRole] = useState<null | number>(null);

  const handleSelectRole = useCallback(({ id }) => {
    setErrors(defaultErrors);
    setSelectedRole(id);
  }, []);

  const validate = useCallback(() => {
    const errorOrg = (
      !selectedOrg && employeeType === employeeTypes.ATWORK
        ? 'Выберите образовательную организацию'
        : ''
    );
    const errorRole = !selectedRole ? 'Выберите должность' : '';

    if (errorOrg || errorRole) {
      setErrors({ errorOrg, errorRole });
      return false;
    }
    return true;
  }, [selectedOrg, selectedRole, employeeType]);

  const onSuccessRef = useCallbackRef(onSuccess);

  const handleSubmitStep2 = useCallback(async () => {
    if (!validate()) {
      return false;
    }
    setSubmitting(true);
    const res = await dispatch(hire({
      ...form,
      role_id: selectedRole,
      school_id: selectedOrg && employeeType === employeeTypes.ATWORK ? [selectedOrg] : null,
    }));
    setSubmitting(false);

    if (res) {
      dispatch(popup(`${form.firstname} ${form.lastname} успешно принят`));
      dispatch(hireformClear());
      onSuccessRef.current?.();
      onClose();
    }
    return false; // всегда false, окно закрывается через onClose
  }, [
    dispatch,
    selectedOrg,
    selectedRole,
    validate,
    form,
    onClose,
    employeeType,
    onSuccessRef,
  ]);

  return [{
    selectedOrg,
    selectedRole,
  }, {
    changeOrg: handleChangeOrg,
    selectRole: handleSelectRole,
    submitStep2: handleSubmitStep2,
  }, errors, submitting];
}
