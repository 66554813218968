// @flow
import { type Node } from 'react';


type Options = {
  projectName?: string,
  header?: Node | string,
}

export default function useTitle({ projectName, header }: Options) {
  if (typeof header === 'string') {
    return (projectName ? `${header} - ${projectName}` : header);
  }
  return (projectName || '...');
}
