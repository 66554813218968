// @flow
import React, { Fragment, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import length from 'ramda/es/length';

import {
  Table,
  Tr,
  Th,
  Td,
  useCopyColumnsWidths,
} from 'app/common/ui-components/table';

import CSSSticky from 'app/common/ui-components/css-sticky';
import useSortState from 'app/common/hooks/useSortState';
import usePaging from 'app/common/hooks/usePaging';
import Paging from 'app/common/ui/Paging';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import ComplexTooltip from 'app/common/ui-components/complex-tooltip';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import CellContentOverflow from '../../../common/CellContent/CellContentOverflow';
import Individual from '../cells/Individual';
import useSortedSales from './useSortedSales';
import useActualSales, { type ActualSale, type ActualDishOrComplex } from './useActualSales';
import type { Purchase, DisplaySettings } from '../types';

import styles from './Sales.scss';

/**
 *  Отчет с детализацией по продажам
 */

type Props = {
  purchases: Array<Purchase>,
  displaySettings: DisplaySettings,
}

const Sales = ({ purchases, displaySettings }: Props) => {
  const [{ sortDirection, sortKey }, handleSort] = useSortState('down', 'id');
  const { currentPage, handlePage } = usePaging();

  const rows = useSelector(state => state.settings.reports.rows, shallowEqual);

  const salesSorted = useSortedSales(purchases, sortKey, sortDirection);
  const total = length(salesSorted);
  const salesActual = useActualSales(
    salesSorted,
    displaySettings,
    rows * (currentPage - 1),
    rows * currentPage,
  );

  const updateKey = useMemo(() => ([]), [sortDirection, sortKey, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps
  const [refFrom, refTo] = useCopyColumnsWidths(updateKey);

  const currency = useCurrencySign(co.SPACE_BEFORE);


  return (
    <Fragment>
      <DetailsHeader reportTitle="Продажи" />

      <CSSSticky
        number={3}
        className={styles.stickableHeader}
        stickyClassName={styles.sticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <Tr header reference={refTo}>
              <Th
                scope="col"
                id="header_id"
                sortKey="id"
                sortDirection={sortKey === 'id' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignLeft
              >
                {'Номер продажи'}
              </Th>

              <Th
                scope="col"
                id="header_fio"
                sortKey="fio"
                sortDirection={sortKey === 'fio' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignLeft
              >
                {'Покупатель'}
              </Th>

              <Th
                scope="col"
                id="header_type"
                alignCenter
              >
                {'Тип'}
              </Th>

              <Th
                scope="col"
                id="header_date"
                sortKey="time"
                sortDirection={sortKey === 'time' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignLeft
              >
                {'Дата'}
              </Th>

              <Th
                scope="col"
                id="header_items_title"
                alignLeft
              >
                {'Продукция'}
              </Th>

              <Th
                scope="col"
                id="header_items_cost"
                alignRight
              >
                {`Цена,${currency}`}
              </Th>

              <Th
                scope="col"
                id="header_items_number"
                alignRight
              >
                {'Кол-во'}
              </Th>

              <Th
                scope="col"
                id="header_sum"
                sortKey="sum"
                sortDirection={sortKey === 'sum' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignRight
              >
                {`Сумма,${currency}`}
              </Th>
            </Tr>
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <thead>
          <Tr header hidden reference={refFrom}>
            <Th
              scope="col"
              // id="header_id"
              alignLeft
            >
              <div className={styles.headerCellContentSorted}>
                {'Номер продажи'}
              </div>
            </Th>

            <Th
              scope="col"
              // id="header_fio"
              alignLeft
            >
              <div className={styles.headerCellContentSorted}>
                {'Покупатель'}
              </div>
            </Th>

            <Th
              scope="col"
              // id="header_type"
              alignCenter
            >
              <div className={styles.headerCellContent}>
                {'Тип'}
              </div>
            </Th>

            <Th
              scope="col"
              // id="header_date"
              alignLeft
            >
              <div className={styles.headerCellContentSorted}>
                {'Дата'}
              </div>
            </Th>

            <Th
              scope="col"
              // id="header_items_title"
              alignLeft
            >
              <div className={styles.headerCellContent}>
                {'Продукция'}
              </div>
            </Th>

            <Th
              scope="col"
              // id="header_items_cost"
              alignRight
            >
              <div className={styles.headerCellContent}>
                {`Цена,${currency}`}
              </div>
            </Th>

            <Th
              scope="col"
              // id="header_items_number"
              alignRight
            >
              <div className={styles.headerCellContent}>
                {'Кол-во'}
              </div>
            </Th>

            <Th
              scope="col"
              // id="header_sum"
              alignRight
            >
              <div className={styles.headerCellContentSorted}>
                {`Сумма,${currency}`}
              </div>
            </Th>
          </Tr>
        </thead>

        <tbody>
          {salesActual.map(({
            id,
            fio,
            class: klass,
            type,
            date,
            time,
            items,
            sum,
            accountNumber,
            phone,
          }: ActualSale) => (
            <Tr key={id}>
              <Td headers="header_id">{id}</Td>

              <Td headers="header_fio" className={styles.tdFio}>
                <CellContentOverflow>
                  {fio}
                </CellContentOverflow>

                <CellContentOverflow className={styles.tdDescription}>
                  {klass}
                </CellContentOverflow>

                {accountNumber
                  && <CellContentOverflow className={styles.tdDescription}>
                    {`л/с ${accountNumber}`}
                  </CellContentOverflow>}

                {phone
                  && <CellContentOverflow className={styles.tdDescription}>
                    {`тел. ${phone}`}
                  </CellContentOverflow>}
              </Td>

              <Td headers="header_type" alignCenter>
                <Individual individual={type === 'personal'} />
              </Td>

              <Td headers="header_date">
                <div>{date}</div>
                <div>{time}</div>
              </Td>

              <Td headers="header_items_title" className={styles.tdItemTitle}>
                <ul className={styles.list}>
                  {items.map(({ id: id_, title, complexItems, cost }: ActualDishOrComplex) => (
                    complexItems
                      // комплект
                      ? <li key={id_}>
                        <ComplexTooltip header={title} headerSuffix={`${cost}${currency}`} items={complexItems}>
                          <div className={styles.complexTitleContainer}>
                            <span className={styles.complexTitle}>{title}</span>
                          </div>
                        </ComplexTooltip>
                      </li>
                      // обычное блюдо
                      : <li key={id_}>
                        <CellContentOverflow key={id_}>
                          {title}
                        </CellContentOverflow>
                      </li>
                  ))}
                </ul>
              </Td>

              <Td headers="header_items_cost" alignRight>
                {items.map(({ id: id_, cost }: ActualDishOrComplex) => (
                  <div key={id_}>{cost}</div>
                ))}
              </Td>

              <Td headers="header_items_number" alignRight>
                {items.map(({ id: id_, number }: ActualDishOrComplex) => (
                  <div key={id_}>{number}</div>
                ))}
              </Td>

              <Td headers="header_sum" alignRight>{sum}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>


      {/* ПОДВАЛ */}
      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        {/* Постарничная навигация */}
        <Paging
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={length(salesActual)}
          onPage={handlePage}
        />

      </CSSSticky>
      {/* кон. подвала */}

    </Fragment>
  );
};

export default Sales;
