// @flow
import type { DetailsGroup } from 'app/common/api/requests/reports/perform';

import groupItems from './groupItems';
import groupActivity from './groupActivity';
import { filterId } from './filterId';
import * as flags from './flags';


export default filterId;
export { groupItems, groupActivity, flags };
export type { DetailsGroup };
