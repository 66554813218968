import { isEmpty } from 'ramda'
import { useEffect, useState } from 'react'


type Options = {
  loading?: boolean
  selected: Array<{ ID: string, title?: string }>
  items: Array<{ ID: string, title: string }>
  onLost?: () => void
}

export function useLost({ loading, items, selected, onLost }: Options) {
  const [lost, setLost] = useState<Array<{ ID: string, title?: string }>>([])
  const hasLost = !isEmpty(lost)

  useEffect(() => {
    if (hasLost) {
      onLost?.()
    }
  }, [hasLost])

  useEffect(() => {
    if (!loading) {
      if (!isEmpty(items) && !isEmpty(selected)) {
        const lostItems = new Map(selected.map(item => ([item.ID, item])))
        items.forEach(({ ID }) => {
          lostItems.delete(ID)
        })
        setLost(Array.from(lostItems.values()))
      }
    }
  }, [selected, items, loading])

  return lost
}
