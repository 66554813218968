import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';

import {
  dishesFilterSet as dishesFilterSetAction,
  dishesFilterClear as dishesFilterClearAction,
} from 'app/actions/dishes/filter';
import selectTypes from 'app/selectors/Settings/Dishes/selectTypes';
import filterActiveSelector from 'app/selectors/Settings/Dishes/filterActive';


export default compose(
  connect(
    (state, props) => ({
      loading: false, // state.dishes.items.loading,
      types: selectTypes(state, props),
      theFilter: state.dishes.filter.fields,
      isFilterApplied: true,
      filterActive: filterActiveSelector(state, props),
    }),
    dispatch => ({
      applyFilter: params => dispatch(dishesFilterSetAction(params)),
      handleClearFilter: () => {
        dispatch(dishesFilterClearAction());
      },
    }),
  ),

  withState('filter', 'setFilter', ({ theFilter }) => ({ ...theFilter })),


  withHandlers({
    handleSelectItem: ({ filter, setFilter }) => (newFilter) => {
      setFilter({ ...filter, ...newFilter });
    },

    handleApplyFilter: ({ filter, applyFilter }) => () => {
      applyFilter(filter);
    },
  }),


  mapProps(props => ({
    ...props,
    isFilterApplied: isEqual(props.filter, props.theFilter),
  })),

);
