// @flow
import { pipe, sortWith, ascend, prop, reduce } from 'ramda';


type Item = { row: number, column: number }

export function createGrid<T: Item>(items: Array<T>): Array<Array<T>> {
  const grid = pipe(
    // reject(propEq('destroy', true)),
    sortWith([
      ascend(prop('row')),
      ascend(prop('column')),
    ]),
    reduce((acc, val) => {
      const { row } = val;
      if (acc[row]) {
        acc[row].push(val);
      } else {
        acc[row] = [val];
      }
      return acc;
    }, []),
  )(items);

  return grid;
}
