// @flow
import React, { type Node } from 'react';

import FilterDataContext from './filter-data-context';
import useFilter from './use-filter';
import { type Descriptions } from './filter-description';


type Props<Params = void> = {
  children: Node,
  filters: Descriptions<Params>,
  params?: Params
}

function FilterDataProvider<Params = void>({ children, filters, params }: Props<Params>) {
  const value = useFilter<Params>(filters, params);

  return (
    <FilterDataContext.Provider value={value}>
      {children}
    </FilterDataContext.Provider>
  );
}

export default FilterDataProvider;
