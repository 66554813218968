// @flow
import React from 'react';
import classNames from 'classnames';

import styles from './RowLayout.scss';


type Props = {
  children: any;
  className?: string,
}

const Header = ({ children, className }: Props) => (
  <h2 className={classNames(styles.header, className)}>
    {children}
  </h2>
);

export default Header;
