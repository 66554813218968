// @flow
import React from 'react';
import classNames from 'classnames/bind';
import { Scrollbars } from 'react-custom-scrollbars-2';
import isEmpty from 'ramda/es/isEmpty';

import { Table, Row, Cell, HeaderCell, HeaderRow } from 'app/common/components/Table';
import Pager from 'app/common/ui/Paging';
import Loader, { DotsLoader } from 'app/common/components/Loader';
import Checkbox from 'app/common/ui/Checkbox';
import IconLightCross from 'app/common/icons/IconLightCross';
import Button from 'app/common/ui/Button';
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog';
import Tooltip from 'app/common/ui/Tooltip';

import TableWin from '../TableWin';
import withSessionsData from './withSessionsData';
import styles from './SessionsTable.scss';


const cx = classNames.bind(styles);

type SessionItem = {
  id: number,
  created: string,
  ip: string,
  browser: string,
  authAt: string,
};

type Props = {
  title: string,
  items: Array<SessionItem>,
  total: number,
  rows: number,
  onClose: (e: SyntheticEvent<HTMLElement>) => void,
  handleCheck: (e: SyntheticEvent<HTMLElement>) => void,
  handleDelete: (e: SyntheticEvent<HTMLElement>) => void,
  selected: Array<string>,
  currentPage: number,
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  loading: boolean,
  updateScroll: (Object) => void,
  isTopShadow: boolean,
  isBottomShadow: boolean,
}

const SessionsTable = ({
  title,
  items,
  total,
  rows,
  onClose,
  handleCheck,
  handleDelete,
  selected,
  // постраничная навигация
  currentPage,
  handlePage,
  loading,
  // тени
  updateScroll,
  isTopShadow,
  isBottomShadow,
}: Props) => (<TableWin
  title={title}
  dataKey="sessions"
  onClose={onClose}
>
  <React.Fragment>
    <div className={styles.button}>
      <ConfirmationDialog
        onConfirm={handleDelete}
        text={selected.length > 1
          ? `Вы уверены, что хотите удалить сессии ${selected.join(', ')}?`
          : `Вы уверены, что хотите удалить сессию ${selected[0]}?`}
        confirmButtonText="Удалить"
        confirmButtonProps={{ error: true }}
        confirmParams="selection"
      >
        {handleConfirm => (<Tooltip
          text={isEmpty(selected)
            ? 'Выберите хотя бы одну сессию'
            : 'Удалить выбранные сессии'}
        >
          <Button
            disabled={isEmpty(selected)}
            onClick={handleConfirm}
          >
            {'Удалить'}
          </Button>
        </Tooltip>)}
      </ConfirmationDialog>
    </div>
    <Table>
      <HeaderRow className={classNames(styles.row, styles.headRow)}>
        <HeaderCell grow={1}>
          {!loading && !isEmpty(items)
              // $FlowFixMe
              && <Checkbox
                className={styles.checkbox}
                value="all"
                checked={items.length === selected.length}
                onChange={handleCheck}
              />}
        </HeaderCell>
        <HeaderCell grow={2}>{'ID'}</HeaderCell>
        <HeaderCell grow={4}>{'Создана'}</HeaderCell>
        <HeaderCell grow={4}>{'Последний запрос'}</HeaderCell>
        <HeaderCell grow={4}>{'IP адрес'}</HeaderCell>
        <HeaderCell grow={4}>{'Браузер'}</HeaderCell>
        <HeaderCell grow={1}>&nbsp;</HeaderCell>
      </HeaderRow>
      {loading && isEmpty(items)
        ? <Loader />
        : <Scrollbars
          autoHeight
          autoHeightMin={100}
          autoHeightMax={310}
          className={cx({ isTopShadow, isBottomShadow })}
          onUpdate={updateScroll}
        >
          {isEmpty(items) && <div className={styles.emptyWarn}>
            {'Данные о сессиях отсутствуют'}
          </div>}
          {items.map(({
            id,
            created,
            ip,
            browser,
            authAt,
          }) => (<Row className={styles.row} key={id}>
            <Cell grow={1}>
              {/* $FLowFixMe */}
              <Checkbox
                label="" // TODO
                className={styles.checkbox}
                value={String(id)}
                checked={selected.includes(id)}
                onChange={handleCheck}
              />
            </Cell>
            <Cell grow={2}>{id}</Cell>
            <Cell grow={4}>{created}</Cell>
            <Cell grow={4}>{authAt}</Cell>
            <Cell grow={4}>{ip}</Cell>
            <Cell grow={4}>{browser}</Cell>
            <Cell grow={1}>
              <ConfirmationDialog
                onConfirm={handleDelete}
                text={`Вы уверены, что хотите удалить сессию ${id}?`}
                confirmButtonText="Удалить"
                confirmButtonProps={{ error: true }}
                confirmParams={id}
              >
                {handleConfirm => (<a href="delete" name={id} onClick={handleConfirm}>
                  <IconLightCross className={styles.cross} />
                </a>)}
              </ConfirmationDialog>
            </Cell>
            {loading && <DotsLoader className={styles.dotsLoader} />}
          </Row>))}
        </Scrollbars>}
    </Table>
    {(total > rows)
        && <Pager
          className={styles.pager}
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={items.length}
          onPage={handlePage}
        />}
  </React.Fragment>
</TableWin>);

export default withSessionsData(SessionsTable);
