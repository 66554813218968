import { connect } from 'react-redux';
import length from 'ramda/es/length';
import assoc from 'ramda/es/assoc';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';

import withSortStateDouble from 'app/common/containers/withSortStateDouble';
import withPaging from 'app/common/containers/withPaging';

import selectUsersByGroup from './selectUsersByGroup';
import selectActual from './selectActual';


const defaultSortKey1 = 'group';
const defaultSortDirection1 = 'down';
const defaultSortKey2 = 'name';
const defaultSortDirection2 = 'down';

export default compose(
  withSortStateDouble(
    defaultSortDirection1,
    defaultSortKey1,
    defaultSortDirection2,
    defaultSortKey2,
  ),

  connect(
    (state, props) => {
      const users = selectUsersByGroup(state, props);
      return ({
        users,
        rows: state.settings.reports.rows,
        total: length(users),
      });
    },
  ),

  withPaging(),
  // setCurrentPage,
  // handlePage,
  // currentPage

  mapProps(props => assoc('users', selectActual(props), props)),

  lifecycle({
    componentDidUpdate(prevProps) {
      const { rows, setCurrentPage } = this.props;
      if (rows !== prevProps.rows) {
        setCurrentPage(1);
      }
    },
  }),
);
