// @flow
import React from 'react';
import classNames from 'classnames';
import styles from './PlainMenu.scss';

type Props = {
  children: any,
  className?: string,
}

const PlainMenu = ({ children, className }: Props) => (<div className={classNames(styles.root, className)}>
  {children}
</div>);

export default PlainMenu;
