// @flow
import React from 'react';

import { useTranslation } from 'react-i18next';

/**
 *  Copiright приложения, в подвале
 */

type Props = {
  company: ?string,
  phone: ?string,
}

const Copyright = ({ company, phone }: Props) => {
  const companyString = company ? `${company}, ` : '';
  const year = new Date().getFullYear();
  const { t } = useTranslation('app');

  return (
    <div>
      {phone
        ? t('footer_copyright', { companyString, year, phone })
        : t('footer_copyright_no_phone', { companyString, year })}
    </div>
  );
};

export default Copyright;
