// @flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './chars-counter.scss';


const cx = classNames.bind(styles);

type Props = {
  className?: string,
  count: number,
  maxCount?: number,
}

const CharsCounter = ({ className, count, maxCount }: Props) => {
  const overflow = maxCount && count > maxCount;
  const success = count && !overflow;

  return (
    <div className={cx(styles.root, { overflow, success }, className)}>
      {count || maxCount}
    </div>
  );
};

export default CharsCounter;
