import { useCallback } from 'react'

import Reports from 'app/components/Reports'
import CommonPage from 'app/common/templates/CommonPage'
import getBaseUrl from './getBaseUrl'


const rootUrl = getBaseUrl()

const ReportsRootPage = () => {
  const createLink = useCallback((reportKey: string) => {
    return `${rootUrl}/${reportKey}`
  }, [])

  return (
    <CommonPage header="Отчеты">
      <Reports createLink={createLink} />
    </CommonPage>
  )
}

export default ReportsRootPage
