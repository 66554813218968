// @flow
import React, { Fragment, useCallback, useMemo, useEffect, type Node } from 'react';
import { assoc, isEmpty } from 'ramda';

import PlainInput from 'app/common/ui-next/plain-input';
import PlainLabel from 'app/common/ui-next/plain-label';
import { PlainItemsSelector } from 'app/common/ui-next/plain-selector';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import {
  useMachineForm,
  useMachineValidate,
  useAvailableOrgs,
} from 'app/dataProviders/VendingDataProvider';

import useActualModels from './useActualModels';
import useActualVersions from './useActualVersions';


const emptySelection = [];

type Props = {
  disabled: boolean,
}

const EditMachine = ({ disabled }: Props): Node => {
  const [{ title, number, orgId }, { fillFromData, update }] = useMachineForm();
  const [errors,, resetErrors] = useMachineValidate();

  useEffect(() => {
    fillFromData();
    return () => {
      resetErrors();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const [availableOrgs, loadingOrgs, requestOrgs] = useAvailableOrgs();
  useEffect(() => { requestOrgs(); }, [requestOrgs]);

  const orgSelection = useMemo(() => (orgId ? [String(orgId)] : []), [orgId]);

  const changeCurrentOrg = useCallback(([orgId_]: Array<string>) => {
    resetErrors();
    update(assoc('orgId', parseInt(orgId_, 10) || 0));
  }, [update, resetErrors]);


  const {
    models,
    loadingModels,
    modelSelection,
    changeCurrentModel,
  } = useActualModels();

  const modelNotYetSelected = isEmpty(modelSelection);

  const {
    versions,
    loadingVersions,
    versionSelection,
    changeCurrentVersion,
  } = useActualVersions(modelSelection);

  const changeInputField = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, id } = e.currentTarget;
    if (id === 'title' || id === 'number') {
      resetErrors();
      update(assoc(id, value));
    }
  }, [update, resetErrors]);


  return (
    <Fragment>
      <PlainItemsSelector
        label="Модель аппарата"
        header="Выберите модель аппарата"
        pleaseSelectText="Выберите модель аппарата"
        items={models}
        selection={modelSelection}
        loading={loadingModels}
        defaultSelection={emptySelection}
        onChange={changeCurrentModel}
        selectedAllOnEmptySelection={false}
        disabled={disabled}
      />
      <PlainHelperText error>
        {errors.modelId}
      </PlainHelperText>

      <PlainItemsSelector
        label="Версия аппарата"
        header="Выберите версию аппарата"
        pleaseSelectText="Выберите версию аппарата"
        items={versions}
        selection={versionSelection}
        loading={loadingVersions}
        defaultSelection={emptySelection}
        onChange={changeCurrentVersion}
        selectedAllOnEmptySelection={false}
        disabled={disabled || modelNotYetSelected}
      />
      <PlainHelperText error>
        {errors.versionId}
      </PlainHelperText>

      <PlainLabel htmlFor="title" displayAsBlock>
        {'Наименование аппарата'}
      </PlainLabel>
      <PlainInput
        id="title"
        value={title}
        onChange={changeInputField}
        disabled={disabled}
        error={!!errors.title}
        displayAsBlock
      />
      <PlainHelperText error>
        {errors.title}
      </PlainHelperText>

      <PlainLabel htmlFor="number" displayAsBlock>
        {'Номер аппарата'}
      </PlainLabel>
      <PlainInput
        id="number"
        value={number}
        onChange={changeInputField}
        error={!!errors.number}
        displayAsBlock
      />
      <PlainHelperText error>
        {errors.number}
      </PlainHelperText>

      <PlainItemsSelector
        label="Место размещения"
        header="Выберите заведение, где стоит аппарат"
        pleaseSelectText="Выберите заведение"
        items={availableOrgs}
        selection={orgSelection}
        loading={loadingOrgs}
        defaultSelection={emptySelection}
        onChange={changeCurrentOrg}
        selectedAllOnEmptySelection={false}
        disabled={disabled}
      />
      <PlainHelperText error>
        {errors.orgId}
      </PlainHelperText>
    </Fragment>
  );
};

export default EditMachine;
