import { ReactNode, RefCallback, useEffect } from 'react'
import { isEmpty } from 'ramda'

import { CompensationAllowances, CompensationInfo, CompensationInfoStatus } from 'app/dataTypes/compensation/info'
import { Table, Td, Th, Tr } from 'app/common/ui-components/table'
import { useCurrencySign } from 'app/common/components-next/currency'
import toFixedValue from 'app/common/lib/toFixedValue'
import { SkeletonLoading, StatusMessage } from 'app/common/templates-next/page-template'

import { AllowanceCell } from './AllowanceCell'


const emptyAllowances = [] as CompensationAllowances

type Props = {
  orgID: string
  date: string
  beforeLoadData: (pause?: number) => Promise<void>
  afterLoadData: (hasData: boolean) => void
  loading: boolean
  onDataRequest: (orgID: string) => Promise<CompensationInfo | null>
  compensationInfo: CompensationInfo
  compensationDetailsTable: (params: { orgID: string, allowanceID: string }) => ReactNode
  compensationDetailsTableFooter: () => ReactNode
  headerRef: RefCallback<'tr'>
}

export const SubTableInfo = ({
  orgID,
  date,
  beforeLoadData,
  afterLoadData,
  loading,
  onDataRequest,
  compensationInfo,
  compensationDetailsTable,
  compensationDetailsTableFooter,
  headerRef,
}: Props) => {
  const currency = useCurrencySign()
  const allowances = (
    (compensationInfo.status === CompensationInfoStatus.Ready
      && compensationInfo.allowances)
      || emptyAllowances
  )

  useEffect(() => {
    (async () => {
      await beforeLoadData()
      const res = await onDataRequest(orgID)
      const hasData = res?.status === CompensationInfoStatus.Ready && !!res.allowances
      afterLoadData(hasData)
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <SkeletonLoading rows={allowances.length || 5} />
    )
  }

  if (compensationInfo.status !== CompensationInfoStatus.Ready || isEmpty(allowances)) {
    return (
      <StatusMessage>{'Нет данных'}</StatusMessage>
    )
  }

  const { orgTitle } = compensationInfo

  return (
    <Table wide>
      <thead>
        <Tr header reference={headerRef}>
          <Th className="index_0" alignLeft>{'Категории льгот'}</Th>
          <Th className="index_1">{''}</Th>
          <Th className="index_2" alignRight>{'Кол-во детей'}</Th>
          <Th className="index_3" alignRight>{`Сумма компенсаций,\u00A0${currency}`}</Th>
          <Th className="index_4">{''}</Th>
        </Tr>
      </thead>
      <tbody>
        {allowances.map(({ allowanceID, studentsCount, totalSum }) => (
          <Tr key={allowanceID}>
            <Td>
              <AllowanceCell
                orgID={orgID}
                orgTitle={orgTitle}
                date={date}
                compensationDetailsTable={compensationDetailsTable}
                compensationDetailsTableFooter={compensationDetailsTableFooter}
              >
                {allowanceID}
              </AllowanceCell>
            </Td>
            <Td>{''}</Td>
            <Td alignRight>{studentsCount}</Td>
            <Td alignRight>{toFixedValue(totalSum)}</Td>
            <Td>{''}</Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  )
}
