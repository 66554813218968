// @flow
import keys from 'ramda/es/keys';
import reduce from 'ramda/es/reduce';
import isEmpty from 'ramda/es/isEmpty';

/**
 *  Извлечение массива идентификаторов из набора объектов
 *  Удаленные (key deleted) игнорируются
 *  В пустой результат может быть добавлен второй параметр
 */

type Extractable<T> = {
  [string]: {
    id: T,
    deleted?: boolean,
    ...
  }
}

export default function extractActualIds<T, K>(
  extractable: Extractable<T>,
  emptyFiller?: K,
): Array<T> | [K] {
  const res = reduce((acc, key) => {
    if (extractable[key] && !extractable[key].deleted && extractable[key].id) {
      acc.push(extractable[key].id);
    }
    return acc;
  }, [], keys(extractable));

  const empty: [K] = ([emptyFiller]: any);

  return (
    emptyFiller !== undefined && isEmpty(res)
      ? empty
      : res
  );
}
