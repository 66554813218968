// @flow
import React, { useContext, useState, useCallback, type ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { ModalContent, ModalFooter } from 'app/common/components/Modal';
import PlainDropdown from 'app/common/ui-next/plain-dropdown';
import PlainLabel from 'app/common/ui-next/plain-label';
import { popup } from 'app/common/actions/popup';

import { type ReassignTarget, IdentifiersContext } from '../identifiers-data';
import IconArrow from './icons/arrow.svg';
import styles from './form-reassign.scss';


type Props = {
  identifierId: number,
  onCancel: () => void,
  onSuccess: () => void,
  reassignSource: ReassignTarget,
  reassignTargets: Array<ReassignTarget>,
  reassignItemView: ComponentType<any>,
}

const FormReassign = ({
  identifierId,
  onCancel,
  onSuccess,
  reassignSource,
  reassignTargets,
  reassignItemView: Student,
}: Props) => {
  const { t } = useTranslation('identifiers-next');
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState(reassignTargets[0]);
  const handleChangeTarget = useCallback((targetProps) => {
    setSelectedTarget(targetProps);
  }, []);

  const { request: { reassign } } = useContext(IdentifiersContext);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { id: studentId } = selectedTarget;
    setProcessing(true);
    const res = await reassign(identifierId, studentId);
    setProcessing(false);
    if (res) {
      dispatch(popup(t('reassign_modal_success_reassigned')));
      onSuccess();
    }
  }, [selectedTarget, identifierId, reassign, dispatch, t, onSuccess]);

  return (
    <form onSubmit={handleSubmit}>
      <ModalContent className={styles.reassignContent}>
        <PlainLabel htmlFor="targetStudent">
          {t('reassign_modal_child_select_label')}
        </PlainLabel>

        <div className={styles.reassignControls}>
          <Student {...reassignSource} />
          <IconArrow className={styles.arrow} />
          <PlainDropdown
            id="targetStudent"
            labelId="targetStudent"
            onChange={handleChangeTarget}
            arrowClassName={styles.ddArrow}
            buttonClassName={styles.ddMain}
            itemClassName={styles.ddItem}
          >
            {reassignTargets.map((props) => {
              const { id } = props;
              return (
                <Student
                  {...props}
                  key={id}
                  selected={id === selectedTarget.id}
                />
              );
            })}
          </PlainDropdown>
        </div>

      </ModalContent>
      <ModalFooter>
        <ControlsBar right>

          <PlainButton onClick={onCancel} outline>
            {t('reassign_modal_calcel')}
          </PlainButton>

          <PlainButton
            type="submit"
            disabled={processing}
          >
            {processing
              ? t('reassign_modal_submit_loading')
              : t('reassign_modal_submit')}
          </PlainButton>

        </ControlsBar>
      </ModalFooter>
    </form>
  );
};

export default FormReassign;
