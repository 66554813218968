import { ReactNode } from 'react'

import SideNavigation, {
  SideNavigationContainer,
  SideNavigationTargetContent,
  SideNavigationItem,
} from 'app/common/ui-components/side-navigation'
import Sticky from 'app/common/templates-next/sticky'
import PlainIcon from 'app/common/ui-next/plain-icon'

import styles from './SideNavLayout.scss'


export type SideNavItem = {
  ID: string
  path: string
  title: string
  iconType: string
  iconWidth: string
  disabled: boolean
}

type Props = {
  items: Array<SideNavItem>
  activeID: string | null
  children: ReactNode
}

export const SideNavLayout = ({ items, activeID, children }: Props) => {
  return (
    <SideNavigationContainer>
      <Sticky skipShift>
        {ref => (
          <div ref={ref} className={styles.side}>
            <SideNavigation>
              {items.map(({ ID, title, path, iconType, iconWidth, disabled }) => (
                <SideNavigationItem
                  key={ID}
                  title={title}
                  to={path}
                  active={ID === activeID}
                  disabled={disabled}
                >
                  <PlainIcon
                    type={iconType}
                    width={iconWidth}
                    height="auto"
                  />
                </SideNavigationItem>
              ))}
            </SideNavigation>
          </div>
        )}
      </Sticky>
      <SideNavigationTargetContent>
        {children}
      </SideNavigationTargetContent>
    </SideNavigationContainer>
  )
}
