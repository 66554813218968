import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'

import { api } from 'app/api'
import { GET_COMPENSATION_INFO, convertParams, convertResult } from 'app/common/api/requests/food/compensation/info/get'
import { CompensationInfo, CompensationInfoByOrgID, CompensationInfoParams } from 'app/dataTypes/compensation/info'
import { useOrgsDataSimple } from 'app/dataProviders/org/hooks'
import { OrgType } from 'app/dataTypes/org/types'

import { createCompensationInfoError, createCompensationInfoPending } from '../create'


type Options = {
  orgIDs: Array<string>,
  month: string,
  year: string,
}

export function useCompensationInfoAll(options: Options | null) {
  const { orgIDs, month, year } = options || {}
  const [data, setData] = useState<CompensationInfoByOrgID>(new Map())
  const [loading, setLoading] = useState(true)

  const updateStateItem = useCallback((ID: string, compensationInfo: CompensationInfo) => {
    setData((prevState) => {
      const nextState = new Map(prevState)
      nextState.set(ID, compensationInfo)
      return nextState
    })
  }, [])

  const { orgs: { items: orgs }, loading: loadingOrgs } = useOrgsDataSimple({
    params: { ID: orgIDs, orgType: OrgType.School },
    autoload: !!options,
  })

  const requestCompensationInfo = useCallback(async (params: CompensationInfoParams) => {
    const { orgID } = params
    const res = await api.request(GET_COMPENSATION_INFO, {
      error: `Не удалось получить информацию по компенсациям для заведения с идентификатором ${orgID}`,
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    })
    return res
  }, [])

  const requestAll = useCallback(async () => {
    if (!year || !month || isEmpty(orgs)) return
    setLoading(true)
    setData(new Map(orgs.map(
      ({ ID, title }) => [ID, createCompensationInfoPending(ID, title)],
    )))

    for (const org of orgs) {// eslint-disable-line no-restricted-syntax
      const { ID, title } = org
      const res = await requestCompensationInfo({ // eslint-disable-line no-await-in-loop
        orgID: ID,
        orgTitle: title,
        year,
        month,
        extended: false,
      })
      setData((prevState) => {
        const nextState = new Map(prevState)
        nextState.set(ID, res || createCompensationInfoError(ID, title))
        return nextState
      })
    }
    setLoading(false)
  }, [orgs, year, month, requestCompensationInfo])

  useEffect(() => {
    requestAll()
  }, [requestAll])

  // REFETCH ///////

  const refetchCompensationInfoByOrgID = useCallback(async (orgID: string, opt?: {
    pendingIndicate?: boolean
    extended?: boolean
  }) => {
    const { extended, pendingIndicate } = { extended: false, pendingIndicate: true, ...opt }
    const previosData = data.get(orgID)
    if (!previosData || !year || !month) return null
    const savedData = { ...previosData }

    if (pendingIndicate) {
      updateStateItem(savedData.orgID, createCompensationInfoPending(savedData.orgID, savedData.orgTitle))
    }

    const res = await requestCompensationInfo({
      orgID: savedData.orgID,
      orgTitle: savedData.orgTitle,
      year,
      month,
      extended,
    })

    if (res) {
      updateStateItem(savedData.orgID, res)
    }
    return res
  }, [data, requestCompensationInfo, year, month, updateStateItem])

  return {
    data,
    loading,
    loadingOrgs,
    refetchCompensationInfoByOrgID,
  }
}
