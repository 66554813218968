// @flow
import React from 'react';
import find from 'ramda/es/find';
import identity from 'ramda/es/identity';
import { useTranslation } from 'react-i18next';

import { OptionProps, OptionProp } from 'app/common/ui-next/option-props';

import { Number } from './markup';
import styles from './prop-identifier-numbers.scss';


const titles = [
  'Wiegand 26',
  'Wiegand 34',
  'Wiegand 58',
];

type Props = {
  numbers: Array<?string>,
  defaultNumber: string,
}

const IdentifierNumbers = ({ numbers, defaultNumber }: Props) => {
  const showDefault = !find(identity, numbers);
  const { t } = useTranslation('identifiers-next');

  return (
    <OptionProps className={styles.root}>
      {showDefault
        && <div>
          <OptionProp
            title={t('default_number_title')}
            className={styles.prop}
          >
            <Number>{defaultNumber}</Number>
          </OptionProp>
        </div>}

      {numbers.map((num, i) => (num
        ? <div key={num}>
          <OptionProp
            title={titles[i] || t('default_number_title')}
            className={styles.prop}
          >
            <Number>{num}</Number>
          </OptionProp>
        </div>
        : null
      ))}
    </OptionProps>
  );
};

export default IdentifierNumbers;
