import api from 'app/actions/api';

export const APPS_REQUEST = 'APPS_REQUEST';
export const APPS_SUCCESS = 'APPS_SUCCESS';
export const APPS_FAILURE = 'APPS_FAILURE';
export const APPS_RESET = 'APPS_RESET';

const appsRequest = () => ({
  type: APPS_REQUEST,
});

const appsSuccess = payload => ({
  type: APPS_SUCCESS,
  payload,
});

const appsFailure = payload => ({
  type: APPS_FAILURE,
  payload,
});

export const appsFetch = () => async (dispatch) => {
  dispatch(appsRequest());
  try {
    const res = await api('dealer/apps', 'get');
    dispatch(appsSuccess(res.data));
  } catch (error) {
    dispatch(appsFailure({ err: 'Запрос приложений не удался', error }));
  }
};
