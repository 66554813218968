// @flow
import React, { type Node } from 'react';

import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';

import styles from './Iconic.scss';


const emptyProps = Object.freeze({});

type Props = {
  children: Node,
  icon?: $Values<typeof iconTypes>,
  sameWidthAs?: string,
}

const Iconic = ({ children, icon, sameWidthAs }: Props) => {
  const coupleIconProps = (
    icon === iconTypes.USER_COUPLE
      ? { height: 16, width: 'auto' }
      : emptyProps
  );

  return (
    <span className={styles.root}>
      {icon
        && <PlainIcon
          type={icon}
          className={styles.icon}
          colorType={colorTypes.COLOR_PRIMARY}
          {...coupleIconProps}
        />}

      {!sameWidthAs && children}

      {sameWidthAs
        && <span className={styles.sameWidthAs} aria-hidden="true">
          {sameWidthAs}
        </span>}

      {sameWidthAs
        && <span className={styles.sameWidth}>
          {children}
        </span>}
    </span>
  );
};

export default Iconic;
