// @flow
import type { Request } from '../index';


type SendFileParams = {
  report_id: number,
  email: string
}

type SendFileResult = 'success';

export const GET_SEND_FILE: Request<
  SendFileParams,
  SendFileResult,
  void
> = [
  'reports/send_file',
  'get',
  ['data', 'result'],
];

// 422 report error - в процессе формирования файла произошла ошибка
// 422 report is not ready - файл еще не готов
