// @flow
import React, { type Node } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames/bind';
import compose from 'recompose/compose';

import Dish from 'app/components/common/Dish';
import Button, { ButtonsBar } from 'app/common/ui/Button';
import Modal from 'app/common/components/Modal';
import Pager from 'app/common/ui/Paging';
import type { DishItemProps } from 'app/components/common/Dish/Dish';
import type { DishesTableSettings } from 'app/components/OrgsList/OrgsDishes/OrgsDishesGear';
import shadows from 'app/common/containers/shadows';
// import RestrictionsSettingsForm from 'app/components/common/RestrictionsSettingsForm';
import RestrictionsSettingsModal from 'app/components/common/RestrictionsSettingsModal';
import OveflowedText from 'app/common/components/OveflowedText';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import PlainLabel from 'app/common/ui-next/plain-label';

import { HeaderRow, HeaderCell } from 'app/common/components/Table';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import * as orgsTypes from 'app/common/constants/schools/types';

import {
  withDishesOrgDishesRights,
  withCurrentOrgRights,
  withDishesDishesRights,
  withMenuMenuRights,
} from 'app/containers/rights';
import { withWriteAccess, bothRights } from 'app/common/containers/withRights';

import enhanceDishes from './enhanceDishes';
import connectActions from './connectActions';

import styles from './Dishes.scss';


type P = { children: Node }
const Restricted = ({ children }: P) => (<div>{children}</div>);

// Права
const dishesEditAccess = bothRights(withCurrentOrgRights, withDishesOrgDishesRights);
const withChangeOthersDishesAccess = compose(
  bothRights(withDishesOrgDishesRights, withDishesDishesRights),
  withWriteAccess,
);
const withChangeMenuAccess = compose(withMenuMenuRights, withWriteAccess);

// Назнвчения прав
const OtherDishesAccessedItems = withChangeOthersDishesAccess(Restricted);
const AccessToMenu = withChangeMenuAccess(({ children }) => children);

const cx = classNames.bind(styles);

type Props = {
  orgId: string,
  orgType: $Values<typeof orgsTypes>,
  rows: number,
  total: number,
  currentPage: number,
  items: Array<DishItemProps>,
  getSaveParams: (void) => void,
  loading: boolean,
  settings: DishesTableSettings,
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  schoolName: string,
  checked: Array<number>,
  locked: Array<number>,
  disableDishGear?: boolean,

  updateScroll: (Object) => void,
  isTopShadow: boolean,
  isBottomShadow: boolean,

  isEditModModal: boolean,
  cancelEditModModal: (e: SyntheticEvent<HTMLElement>) => void,
  submitEditModModal: (e: SyntheticEvent<HTMLElement>) => void,
  handleEditModCheck: (e: SyntheticEvent<HTMLElement>) => void,
  saveParams: Array<string>,

  handleSelectAll: (e: SyntheticEvent<HTMLElement>) => void,
  selectedAll: boolean,
  writeAccess: boolean,

  handleDishSettings: (id: number) => void,
  handleDishSettingsClose: (e?: SyntheticEvent<HTMLElement>) => void,
  dishSettings: {
    id: ?number,
    title: string,
  },
};

const Dishes = dishesEditAccess(({
  orgId,
  orgType,
  rows,
  total,
  currentPage,
  items,
  getSaveParams,
  loading,
  settings,
  handlePage,
  schoolName,
  disableDishGear,

  updateScroll,
  isTopShadow,
  isBottomShadow,

  isEditModModal,
  cancelEditModModal,
  submitEditModModal,
  handleEditModCheck,
  saveParams,
  checked,
  locked,

  handleSelectAll,
  selectedAll,
  writeAccess,

  handleDishSettings,
  handleDishSettingsClose,
  dishSettings,
}: Props) => {
  const { id: dishId, title: dishTitle } = dishSettings;
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <div className={styles.root}>
      <HeaderRow className={styles.headerRow}>
        <HeaderCell className={styles.selectAllCell} grow={0}>
          {items.length
            ? <PlainCheckbox
              label="Выбрать все"
              value="selectAll"
              onChange={handleSelectAll}
              checked={selectedAll}
              hideLabel
            />
            : null}
        </HeaderCell>
        <HeaderCell grow={5}><OveflowedText>{'Наименование'}</OveflowedText></HeaderCell>
        {settings.photo
          && <HeaderCell className={styles.mayBeLast} alignMiddle grow={3}>
            <OveflowedText>{'Фото'}</OveflowedText>
          </HeaderCell>}
        {settings.portion
          && <HeaderCell className={styles.mayBeLast} alignRight grow={2}>
            <OveflowedText>{'Порция,\u00A0гр.'}</OveflowedText>
          </HeaderCell>}
        {settings.cost
          && <HeaderCell className={styles.mayBeLast} alignRight grow={2}>
            <OveflowedText>{`Стоимость${currency}`}</OveflowedText>
          </HeaderCell>}
        {settings.saleType
          && <HeaderCell className={styles.mayBeLast} grow={4}>
            <OveflowedText>{'Тип продукции'}</OveflowedText>
          </HeaderCell>}
        {settings.category
          && <HeaderCell className={styles.mayBeLast} grow={2}>
            <OveflowedText>{'Категория'}</OveflowedText>
          </HeaderCell>}
        {settings.setup
          && writeAccess
          && <HeaderCell className={styles.mayBeLast} grow={1}>&nbsp;</HeaderCell>}
      </HeaderRow>
      <div className={styles.items}>
        <Scrollbars
          className={cx({
            isTopShadow,
            isBottomShadow,
          })}
          onUpdate={updateScroll}
        >
          {items.length
            ? items.map(item => (
              <Dish
                id={item.id}
                orgId={orgId}
                key={item.id}
                params={item}
                loading={loading || locked.includes(item.id)}
                getSaveParams={getSaveParams}
                connectActions={connectActions}
                settings={settings}
                checked={checked.includes(item.id)}
                deleteText={`Вы уверены? Удалить блюдо "${item.title}" из списка школы?`}
                locked={!writeAccess}
                dishesChangeItem={handleDishSettings}
                disableDishGear={disableDishGear}
              />
            ))
            : <div className={styles.empty}>{'Нет продукции, удовлетворяющей условиям'}</div>}
        </Scrollbars>
      </div>
      {(total > rows)
        && <div className={styles.pager}>
          <Pager
            total={total}
            pageSize={rows}
            currentPage={currentPage}
            currentPageSize={items.length}
            onPage={handlePage}
          />
        </div>}

      {isEditModModal && <Modal close={cancelEditModModal} className={styles.modal}>
        <div className={styles.modalContent}>
          <form onSubmit={submitEditModModal}>
            <PlainCheckbox
              label={`Cохранить только в ${schoolName}`}
              id="school"
              value="school"
              name="school"
              checked={saveParams.includes('school')}
              onChange={handleEditModCheck}
              disabled
            />
            <OtherDishesAccessedItems>
              <PlainCheckbox
                label="Cохранить во всех доступных вам школах, где используется эта продукция + в списке продукции поставщика"
                id="all"
                value="all"
                name="all"
                checked={saveParams.includes('all')}
                onChange={handleEditModCheck}
              />
            </OtherDishesAccessedItems>
            {orgType !== orgsTypes.ENTERPRISE
              && <AccessToMenu>
                <PlainLabel
                  id="allSchoolsLabel"
                  className={styles.label}
                >
                  {'Внести изменения в меню во всех доступных вам школах:'}
                </PlainLabel>

                <PlainFieldset
                  ariaLabelledby="allSchoolsLabel"
                  className={styles.level1}
                  name="allSchools"
                >
                  <PlainCheckbox
                    label="Применить к одиночной продукции"
                    id="all_menu_single"
                    value="all_menu_single"
                    name="all_menu_single"
                    checked={saveParams.includes('all_menu_single')}
                    onChange={handleEditModCheck}
                  />
                  <PlainCheckbox
                    label="Применить к продукции в комплектах"
                    id="all_menu_complex"
                    value="all_menu_complex"
                    name="all_menu_complex"
                    checked={saveParams.includes('all_menu_complex')}
                    onChange={handleEditModCheck}
                  />
                </PlainFieldset>
              </AccessToMenu>}
            <ButtonsBar middle className={styles.buttons}>
              <Button value="Отмена" onClick={cancelEditModModal} outline />
              <Button value="Применить" submit autoFocus />
            </ButtonsBar>
          </form>
        </div>
      </Modal>}

      {/* Настройки блюда (иконка шестиренки) */}
      {writeAccess
        && dishId
        && <RestrictionsSettingsModal
          header="Настройки"
          headPhrase={`Для кого используется продукция «${dishTitle}»`}
          orgId={parseInt(orgId, 10)}
          dishId={dishId}
          onClose={handleDishSettingsClose}
        />}

      {/* {writeAccess
        && <Modal isOpen={!!dishSettings.id} close={handleDishSettingsClose}>
          <RestrictionsSettingsForm
            orgId={parseInt(orgId, 10)}
            dishId={dishSettings.id}
            onCancel={handleDishSettingsClose}
            title={`Для кого используется продукция «${dishSettings.title}»`}
          />
        </Modal>} */}

    </div>
  );
});

export default enhanceDishes(shadows(Dishes));
