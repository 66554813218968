import { ReactNode } from 'react'

import PageTemplate from 'app/common/templates-next/page-template'
import { useDomainProjectName } from 'app/dataProviders/domain'


type Props = {
  children: ReactNode
  header: string
}

export const ProjectPageLayout = ({ children, header }: Props) => {
  const projectName = useDomainProjectName()

  return (
    <PageTemplate
      header={header}
      title={projectName ? `${header} - ${projectName}` : header}
    >
      {children}
    </PageTemplate>
  )
}
