// @flow
import React, { type Node } from 'react';
import cn from 'classnames';

import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';
import Picture from 'app/common/ui-next/picture';

import styles from './photo.scss';


type Props = {
  className?: string,
  alt: string,
  photoURL: string | null,
}

const Photo = ({ className, alt, photoURL }: Props): Node => {
  return (
    <div className={cn(styles.root, className)}>
      {photoURL
        ? <Picture
          alt={alt}
          className={styles.picture}
          src={photoURL}
          height="67px"
          width="67px"
        />
        : <PlainIcon
          width="36px"
          height="auto"
          type={iconTypes.NO_PHOTO}
        />}
    </div>
  );
};

export default Photo;
