import { Route, Redirect } from 'react-router'

import SectionProduction from 'app/components/Settings/Additional/SectionProduction'
import SectionReports from 'app/components/Settings/Additional/SectionReports'
import Sticky from 'app/common/templates-next/sticky'
import { rootRights, specificRights } from 'app/rights'
import { useRightsByPath } from 'app/rights/Rights'

import SectionComplexes from 'app/components/Settings/Additional/SectionComplexes'
import Navigation from '../../Navigation'
import AdditionalPageSideNav from './AdditionalPageSideNav'
import { Section, isSection, defaultSection } from './sections'
import { baseURL } from './baseURL'
import SectionOrgGroupsPage from './SectionOrgGroupsPage'


const additionalRights = [rootRights.SETTINGS, specificRights.ADDITIONAL] as const

type Props = {
  match: {
    params: {
      section?: string
      orgType?: string
    }
  }
}

/**
 *  Страница Настройки / Дополнительно
 */

const AdditionalPage = ({ match: { params: { section, orgType } } }: Props) => {
  const currentSection = isSection(section) ? section as Section : null
  const { write, read } = useRightsByPath({ pathTo: additionalRights })


  if (!section || !currentSection) {
    return <Redirect from={baseURL} to={`${baseURL}/${defaultSection}`} />
  }

  return (
    <>
      <Sticky>
        {(ref, stuck) => (
          <Navigation ref={ref} stuck={stuck} />
        )}
      </Sticky>

      {read
        && <AdditionalPageSideNav
          baseURL={baseURL}
          currentSection={currentSection}
        >

          {currentSection === Section.Production
            && <SectionProduction disabled={!write} />}

          {currentSection === Section.Complexes
            && <SectionComplexes disabled={!write} />}

          {currentSection === Section.Reports
            && <SectionReports disabled={!write} />}

          {currentSection === Section.OrgGroups
            && <SectionOrgGroupsPage orgType={orgType} disabled={!write} />}

        </AdditionalPageSideNav>}
    </>
  )
}

export const additionalPageRoutes = [
  <Route key="additional0" path={`${baseURL}/:section/:orgType`} component={AdditionalPage} exact />,
  <Route key="additional1" path={`${baseURL}/:section`} component={AdditionalPage} exact />,
  <Route key="additional2" path={baseURL} component={AdditionalPage} />,
]
