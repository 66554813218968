import * as React from 'react';

type Props = {
  hoc: (Function) => Function,
  component: Function,
  props?: Object,
};

// Зачем? :)
// но чекбоксы без этого не работают, срабатывание только со второго раза
const WithHOC = ({ hoc, component, ...props }: Props) => {
  const Component = hoc(component);
  return <Component {...props} />;
};

export default WithHOC;
