// @flow
import { useSelector, shallowEqual } from 'react-redux';
import { path } from 'ramda';

import usePagingExtended from 'app/common/hooks/usePagingExtended';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Dish as CommonDish } from 'app/dataTypes/Dishes.types';
import type { Paging } from 'app/common/hooks/usePagingExtended';

import useDishes, { useRightUpdatableDishesParams } from './useDishes';
import type { ColumnVisibility } from './table/columns';
import { dataKey } from './table/dataKey';


type Options = {
  placeId: string,
  category: Category | null,
  search: string,
  allDishesCategoryId: string,
}

export default function useProductsTableData(options: Options): {
  dishes: Array<Dish>,
  loading: boolean,
  columnSettings: ColumnVisibility,
  paging: Paging,
  total: number,
  updateDishAsCommon: (dish: CommonDish) => void,
  updateDish: (dish: Dish) => void,
} {
  const { rows: limit, ...columnSettings } = useSelector(path(['settings', dataKey]), shallowEqual);
  const paging = usePagingExtended(limit);
  const { offset, resetPagination } = paging;

  const params = useRightUpdatableDishesParams({ ...options, limit, offset });
  const {
    dishes,
    total,
    loadingDishes,
    updateDishAsCommon,
    updateDish,
  } = useDishes(params, resetPagination);

  const { category } = options;
  const loading = loadingDishes || category === null;

  return {
    dishes,
    loading,
    columnSettings,
    paging,
    total,
    updateDishAsCommon,
    updateDish,
  };
}
