import { useMemo } from 'react'

import styles from './render-value.scss'


type Props = {
  value: string | Array<string>
}

const RenderValue = ({ value }: Props) => {
  const [values, lastValueIndex, series] = useMemo(() => {
    const valuesLocal = value instanceof Array ? value : [value]
    const lastValueIndexLocal = valuesLocal.length - 1
    const seriesLocal = valuesLocal.map(val => val.replace(
      /^([-−]?[\d\s]+)(\.\d\d)*([\s^\w]*)/,
      (_, p1, p2, p3) => [p1, p2, p3].join('_')
    ).split('_'))

    return [valuesLocal, lastValueIndexLocal, seriesLocal]
  }, [value])

  return (
    <div className={styles.root}>
      {series.map(([base, fraction, currency], i) => (
        <span key={values[i]}>
          {base}
          {fraction && <span className={styles.fraction}>{fraction}</span>}
          {currency && <span className={styles.currency}>{currency}</span>}
          {i < lastValueIndex && <span>{' / '}</span>}
        </span>
      ))}
    </div>
  )
}

export default RenderValue
