// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const FIELDEDIT_REQUEST = 'orgs/menu/FIELDEDIT_REQUEST';
export const FIELDEDIT_SUCCESS = 'orgs/menu/FIELDEDIT_SUCCESS';
export const FIELDEDIT_FAILURE = 'orgs/menu/FIELDEDIT_FAILURE';

export const fieldEditRequest = (payload: Object) => ({
  type: FIELDEDIT_REQUEST,
  payload,
});

export const fieldEditSuccess = (payload: Object) => ({
  type: FIELDEDIT_SUCCESS,
  payload,
});

export const fieldEditFailure = (payload: Object) => ({
  type: FIELDEDIT_FAILURE,
  payload,
});

type Params = {
  date: string,
  property: string,
  value: string,
  extended: string,
  updateAllLists: boolean,
};

export const fieldEdit = (orgId: string | number, id: string | number, {
  date,
  property,
  value,
  extended,
  updateAllLists,
}: Params) => async (dispatch: Function) => {
  const dataToSend = {
    school_id: orgId,
    item_id: id,
    date,
    property,
    value,
    extended,
    update_all_lists: updateAllLists,
  };
  dispatch(fieldEditRequest(dataToSend));
  try {
    const res = await api('food/menu/update_item', 'put', { data: dataToSend });
    dispatch(fieldEditSuccess({
      path: [id, property],
      value: get(res, ['data', 'fields', property], '???'),
    }));
    return true;
  } catch (error) {
    dispatch(fieldEditFailure({ err: 'Не удалось изменить блюдо', error }));
    return false;
  }
};
