// @flow
import { useCallback } from 'react';

import { api } from 'app/api';
import { POST_REPORTS_PERFORM_PURCHASES_BY_FUNDS } from 'app/common/api/requests/reports/perform';
import { GET_REPORTS_STATUS } from 'app/common/api/requests/reports/status';

import type { Params } from './useGenerateParams';


type Generate = {
  onProcessRequest: () => Promise<number | string | null>,
  onProcessCheckStatus: (reportId: number) => Promise<number | string>,
}

export type ChangeRequestStatus = (status: boolean) => void

export default function useGenerateRequests(
  params: Params,
  onChangeRequestStatus: ChangeRequestStatus,
): Generate {
  const onProcessRequest = useCallback(async () => {
    if (!params) return null;
    const error = 'Не удалось запустить генерацию отчета';
    onChangeRequestStatus(true);
    const res = await api.request(POST_REPORTS_PERFORM_PURCHASES_BY_FUNDS, {
      error,
      params,
    });
    onChangeRequestStatus(false);
    if (res) {
      return res;
    }
    return error;
  }, [params, onChangeRequestStatus]);

  const onProcessCheckStatus = useCallback(async (reportId: number) => {
    const error = 'Не удалось получить статус генерации отчета';
    const res = await api.request(GET_REPORTS_STATUS, {
      error,
      params: {
        report_id: reportId,
      },
    });

    if (typeof res === 'number') {
      return res;
    }
    return error;
  }, []);

  return {
    onProcessRequest,
    onProcessCheckStatus,
  };
}
