import moment from 'moment-timezone';

import {
  DATEFROMTO_FILTER_SET,
  DATEFROMTO_FILTER_CLEAR,
  dateFormat,
} from 'app/actions/reports/filters/dateFromTo';


const defaultState = {
  dateFrom: moment().format(dateFormat),
  dateTo: moment().format(dateFormat),
};

const dateFromTo = (state = defaultState, { type, payload }) => {
  if (type === DATEFROMTO_FILTER_SET) {
    return ({ ...state, ...payload });
  }
  if (type === DATEFROMTO_FILTER_CLEAR) {
    return defaultState;
  }
  return state;
};

export default dateFromTo;
