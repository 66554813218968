// @flow
import path from 'ramda/es/path';

import api from 'app/common/login/api';
import i18next from 'app/i18n';
// import { getDomain } from 'app/common/lib/params';
// import { setToken } from 'app/common/lib/token';
// import { appTypes } from 'app/common/api';

import login2Strict from '../login2Strict';


// const currentDomainForbidden = () => {
//   return (
//     (process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_CABINET
//     && getDomain() === 'arhtc.ru'
//   );
// };

// if (currentDomainForbidden()) {
//   setToken('');
// }

const apiTestLogin = (
  login: string,
  setLoginInfo: (Object) => void,
  setLoginError: (string) => void,
  onBegin?: (void) => void,
  onComplete?: (void) => void,
) => {
  if (!login || typeof setLoginInfo !== 'function' || typeof setLoginError !== 'function') {
    console.error('Неверно переданы обязательные параметры при вызове apiTestLogin');
    return;
  }
  if (typeof onBegin === 'function') {
    onBegin();
  }
  api('auth/info', 'get', { params: { login: login2Strict(login) } })
    .then((response) => {
      if (path(['data', 'result'], response) === 'success') {
        const agreementUrl = path(['data', 'terms_of_use_url'], response) || '';
        const authDisabled = path(['data', 'auth_disabled'], response) || false;
        setLoginInfo({
          login: authDisabled ? '' : login,
          agreementUrl,
          // если нет url текста соглашения, то оно автоматически считается принятым
          accepted: (agreementUrl
            ? path(['data', 'terms_of_use_accepted'], response) || false
            : true
          ),
          isLoginTemp: path(['data', 'login_temporary'], response) || false,
          passwordType: path(['data', 'password_type'], response) || null,
          code2Mail: path(['data', 'code_to_mail'], response) || false,
          authDisabled,
        });
      } else {
        setLoginError(i18next.t('login:request_error'));
      }
      if (typeof onComplete === 'function') {
        onComplete();
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          setLoginError(i18next.t('login:unknown_login'));
        } else {
          setLoginError(i18next.t('login:request_error'));
        }
      } else {
        setLoginError(i18next.t('login:connect_error'));
      }
      if (typeof onComplete === 'function') {
        onComplete();
      }
    });
};

export default apiTestLogin;
