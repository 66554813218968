// @flow
import React from 'react';
import classNames from 'classnames/bind';

// import Cell from 'app/components/ui/Cell';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import { DotsLoader } from 'app/common/components/Loader';
import { CellLabel, Cell, Row } from 'app/common/components/Table';

import enhanceDishesKit from './enhanceDishesKit';
import styles from './DishesKit.scss';

const cx = classNames.bind(styles);


// ----------------
// Универсальный компонет комплекта для таблицы комплектов
// Используется в окне добавления/удаления комплектов,
// в разделе комплектов школы НЕ используется,
// Через контейнер задаются actions для работы с блюдом,
// определенные для конкретного случая использования
// ----------------

type Props = {
  id: number,
  params: {
    id: number,
    title: string,
    dishes: Array<Object>,
    cost: string,
  },
  toggleItem: (e: SyntheticEvent<HTMLInputElement>) => void,
  loading: boolean,
  checked?: boolean, // выделен (чекбокс и желтый фон)
  locked?: boolean, // поля не редактируются (пока не редактируются и это не используется)
  selectable?: boolean, // чекбокс для выделения отсутствует
  success?: boolean, // выделен зеленым (только фон), сhecked не меняет цвет фона
  className?: string,
};

const DishesKit = ({
  id,
  params: { title, dishes, cost },
  toggleItem,
  loading,
  checked,
  // locked,
  selectable,
  success,
  className,
}: Props) => {
  const htmlFor = String(id);

  return (
    <Row className={cx(styles.row, className, { loading, success, active: checked })}>
      {selectable
        && <CellLabel htmlFor={htmlFor} width={50} grow={0}>
          <PlainCheckbox
            id={htmlFor}
            className={styles.check}
            value={String(id)}
            onChange={toggleItem}
            checked={checked}
            skipLabel
          />
        </CellLabel>}
      <CellLabel htmlFor={htmlFor} grow={3}>{title}</CellLabel>
      <CellLabel htmlFor={htmlFor} grow={8} className={styles.cellContainer}>
        {dishes.map(item => (<div key={item.id} className={styles.subRow}>
          <Cell grow={2}>{item.title}</Cell>
          <Cell grow={1} alignRight>{item.portion}</Cell>
          <Cell grow={1} alignRight>{item.cost}</Cell>
        </div>))}
      </CellLabel>
      <CellLabel htmlFor={htmlFor} grow={2} alignRight>{cost}</CellLabel>
      {loading && <DotsLoader className={styles.loader} />}
    </Row>
  );
};

DishesKit.defaultProps = {
  selectable: true,
};
export default enhanceDishesKit(DishesKit);
