// @flow
import isEmpty from 'ramda/es/isEmpty';
import values from 'ramda/es/values';

import { defaultStateDataCurrent } from 'app/common/ui-components/filter';
import { api } from 'app/api';
import { GET_VENDING_MACHINES_MODELS } from 'app/common/api/requests/data/vending/machines/models/get';

/**
 *  Запрос данных фильтра по моделям вендинговых аппаратов
 */

const filterGetItemsModels = async () => {
  const res = await api.request(GET_VENDING_MACHINES_MODELS, {
    error: 'Не удалось получить список моделей аппаратов',
  });

  if (res && res.data && res.data instanceof Array && !isEmpty(res.data)) {
    const models = values(res.data);
    return ([models, []]);
  }

  return ([[], defaultStateDataCurrent]);
};

export default filterGetItemsModels;
