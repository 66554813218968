import { values, map, filter, pipe, toPairs, unnest, groupWith, prop, sortBy } from 'ramda'
import { v4 as uuid } from 'uuid'

import {
  paymentOrderKinds,
  paymentOrderMethods,
  paymentOrderPurposes,
  paymentOrderCreateMethods,
  PaymentOrderKind,
  Requisites,
  SingleRequisites,
  PaymentOrderMethod,
  PaymentOrderPurpose,
  PaymentOrderCreateMethod,
  SupportContactsType,
  SupportContactsItemType,
} from 'app/dataTypes/requisites'

import { SupportScheduleType } from 'app/dataTypes/requisites/SupportScheduleType'


const kindsApi = Object.freeze({
  SCHOOL_ID: 0,
  CAMP_ID: 1,
})

const knownKinds = values(kindsApi)

export type SingleRequisitesApi = {
  org_type: string
  title: string
  law_address: string
  address: string
  post_address: string
  address_for_reports: 'law_address' | 'address' | 'post_address'
  inn: string
  kpp: string
  orgn: string
  okpo: string
  bik: string
  bank: string
  bank_address: string
  corr: string
  rc: string
  oktmo: string
  kbk: string
  lc: string
  payment_order_kind_id: number
  payment_order_method_id: 0 | 1 | 2 | null
  payment_order_service_code: string
  payment_order_purpose_item_ids: Array<0 | 1 | 2 | 3 | 4>
  payment_order_create_method_ids: Array<0 | 1 | 2>
}

export type PersonApi = {
  name: {
    first: string
    last: string
    middle: string
  },
  phone: string
  email: string
  comment: string
}

export type AccountantApi = {
  name: {
    first: string
    last: string
    middle: string
  },
  phone: string
  email: string
  comment: string
}

export type SupportScheduleApi = {
  workday_start: string | null
  workday_finish: string | null
  workday_timeless: boolean
  lunch_start: string | null
  lunch_finish: string | null
  lunch_timeless: boolean
  saturday_start: string | null
  saturday_finish: string | null
  saturday_dayoff: boolean
  sunday_start: string | null
  sunday_finish: string | null
  sunday_dayoff: boolean
}

type SupportContactApi = {
  id?: number
  position: number
  kind: string
  value: string
}

type ResultApi = {
  result: 'success',
  person: PersonApi,
  accountant: AccountantApi,
  payment_order_kind_ids: Array<number>,
  requisites: {
    [id: string]: SingleRequisitesApi
  },
  support_contacts: Array<SupportContactApi>
  support_schedule: SupportScheduleApi
}

// Типы заведений
// 0 - школа
// 1 - лагерь
const convertKind = (kind: number): PaymentOrderKind => {
  switch (kind) {
    case kindsApi.SCHOOL_ID:
      return paymentOrderKinds.school
    case kindsApi.CAMP_ID:
      return paymentOrderKinds.camp
    default:
      throw Error(`👻 convertKind необработанное значение ${kind}`)
  }
}

const convertKindApi = (kind: PaymentOrderKind): (typeof kindsApi)[keyof typeof kindsApi] => {
  switch (kind) {
    case paymentOrderKinds.school:
      return kindsApi.SCHOOL_ID
    case paymentOrderKinds.camp:
      return kindsApi.CAMP_ID
    default:
      throw Error(`👻 convertKindApi необработанное значение ${kind}`)
  }
}

// Вид квитанции payment_order_method_id
// 0 - он-лайн Сбербанк
// 1 - офф-лайн Сбербанк
// 2 - офф-лайн
const convertPaymentMethod = (id: 0 | 1 | 2 | null): PaymentOrderMethod | null => {
  switch (id) {
    case 0:
      return paymentOrderMethods.SberOnline
    case 1:
      return paymentOrderMethods.SberOffline
    case 2:
      return paymentOrderMethods.Offline
    default:
  }
  return null
}

const convertPaymentMethodApi = (method: PaymentOrderMethod | null): 0 | 1 | 2 => {
  switch (method) {
    case paymentOrderMethods.SberOnline:
      return 0
    case paymentOrderMethods.SberOffline:
      return 1
    case paymentOrderMethods.Offline:
      return 2
    default:
  }
  return 0 // выбирается SberOnline по умолчанию, но null не долджен приходить из апи и сюда по идее не доберется, хотя может )
}

// Назначение платежа payment_order_purpose_item_ids
// 0 - Вид питания
// 1 - Класс/Группа
// 2 - Наименование заведения
// 3 - Вид деятельности
// 4 - Лицевой счет
const convertOrderPurposes = (id: 0 | 1 | 2 | 3 | 4): PaymentOrderPurpose => {
  switch (id) {
    case 0:
      return paymentOrderPurposes.FoodType
    case 1:
      return paymentOrderPurposes.ClassGroup
    case 2:
      return paymentOrderPurposes.OrgName
    case 3:
      return paymentOrderPurposes.ActivityKind
    case 4:
      return paymentOrderPurposes.PersonalAccount
    default:
      throw Error(`👻 convertOrderPurposes необработанное значение ${id}`)
  }
}

const convertOrderPurposesApi = (purpose: PaymentOrderPurpose): 0 | 1 | 2 | 3 | 4 => {
  switch (purpose) {
    case paymentOrderPurposes.FoodType:
      return 0
    case paymentOrderPurposes.ClassGroup:
      return 1
    case paymentOrderPurposes.OrgName:
      return 2
    case paymentOrderPurposes.ActivityKind:
      return 3
    case paymentOrderPurposes.PersonalAccount:
      return 4
    default:
      throw Error(`👻 convertOrderPurposesApi необработанное значение ${purpose}`)
  }
}

// Управление квитанциями
// 0 - выставление с помощью API внешних систем
// 1 - выставление вручную в web-приложении "Личный кабинет сотрудника ОУ"
// 2 - выставление автоматически по заданным параметрам
const convertOrderCreateMethods = (id: 0 | 1 | 2): PaymentOrderCreateMethod => {
  switch (id) {
    case 0:
      return paymentOrderCreateMethods.Api
    case 1:
      return paymentOrderCreateMethods.Web
    case 2:
      return paymentOrderCreateMethods.Auto
    default:
      throw Error(`👻 convertOrderCreateMethods необработанное значение ${id}`)
  }
}

const convertOrderCreateMethodsApi = (method: PaymentOrderCreateMethod): 0 | 1 | 2 => {
  switch (method) {
    case paymentOrderCreateMethods.Api:
      return 0
    case paymentOrderCreateMethods.Web:
      return 1
    case paymentOrderCreateMethods.Auto:
      return 2
    default:
      throw Error(`👻 convertOrderPurposesApi необработанное значение ${method}`)
  }
}

const convertSingleRequisites = (singleRequisites: SingleRequisitesApi): SingleRequisites => {
  const {
    org_type: orgType,
    title,
    law_address: lawAddress,
    address,
    post_address: postAddress,
    address_for_reports: reportAddress,
    inn,
    kpp,
    orgn,
    okpo,
    bik,
    bank,
    bank_address: bankAddress,
    corr,
    rc,
    oktmo,
    kbk,
    lc,
    payment_order_kind_id: paymentOrderKindId,
    payment_order_method_id: paymentOrderMethod,
    payment_order_service_code: paymentOrderServiceCode,
    payment_order_purpose_item_ids: paymentOrderPurposesList,
    payment_order_create_method_ids: paymentOrderCreateMethodIds,
  } = singleRequisites

  return {
    kind: convertKind(paymentOrderKindId),
    orgType,
    title,
    lawAddress,
    address,
    postAddress,
    reportAddress,
    inn,
    kpp,
    orgn,
    okpo,
    bik,
    bank,
    bankAddress,
    corr,
    rc,
    oktmo,
    kbk,
    lc,
    paymentOrderMethod: convertPaymentMethod(paymentOrderMethod),
    paymentOrderServiceCode,
    paymentOrderPurpose: new Set(paymentOrderPurposesList.map(convertOrderPurposes)),
    paymentOrderCreateMethods: new Set(paymentOrderCreateMethodIds.map(convertOrderCreateMethods)),
  }
}

const convertResultSupportContacts = (data: ResultApi['support_contacts']): SupportContactsType => {
  const res = pipe(
    sortBy(prop('position')),
    groupWith<SupportContactApi>((
      { kind: k1, position: p1 },
      { kind: k2, position: p2 }
    ) => {
      return (k1 === k2 && p1 === p2)
    }),
    map((samePositionItems) => {
      const { id, kind, position } = samePositionItems[0]
      return ({
        ID: id ? String(id) : `new${uuid()}`,
        kind,
        position: String(position),
        samePositionValues: samePositionItems.map(({ id: idd, value }) => ({
          ID: idd ? String(idd) : `new${uuid()}`,
          value,
        })),
      })
    })
  )(data)
  return res
}

const convertResultSupportSchedule = (data: SupportScheduleApi): SupportScheduleType => {
  return {
    workday: {
      formID: 'workday',
      keyStart: data.workday_start ?? '',
      keyFinish: data.workday_finish ?? '',
      keyTimeless: data.workday_timeless,
    },
    lunch: {
      formID: 'lunch',
      keyStart: data.lunch_start ?? '',
      keyFinish: data.lunch_finish ?? '',
      keyTimeless: data.lunch_timeless,
    },
    saturday: {
      formID: 'saturday',
      keyStart: data.saturday_start ?? '',
      keyFinish: data.saturday_finish ?? '',
      keyTimeless: data.saturday_dayoff,
    },
    sunday: {
      formID: 'sunday',
      keyStart: data.sunday_start ?? '',
      keyFinish: data.sunday_finish ?? '',
      keyTimeless: data.sunday_dayoff,
    },
  }
}

export const convertResult = (
  {
    person,
    accountant,
    requisites: req,
    support_schedule,
    support_contacts,
  }: ResultApi
): Requisites => {
  const requisites = pipe(
    toPairs<SingleRequisitesApi>,
    filter<[string, SingleRequisitesApi]>(
      ([, k]) => (knownKinds as Array<number>).includes(k.payment_order_kind_id)
    ),
    map(([a, b]): [PaymentOrderKind, SingleRequisites] => {
      return [convertKind(parseInt(a, 10)), convertSingleRequisites(b)]
    }),
    x => new Map(x)
  )(req)

  const supportContacts = convertResultSupportContacts(support_contacts)
  const supportSchedule = convertResultSupportSchedule(support_schedule)

  return {
    person,
    accountant,
    requisites,
    supportContacts,
    supportSchedule,
  }
}

// => API

const convertSingleRequisitesApi = (singleRequisites: SingleRequisites): SingleRequisitesApi => {
  const {
    kind,
    orgType,
    title,
    lawAddress,
    address,
    postAddress,
    reportAddress,
    inn,
    kpp,
    orgn,
    okpo,
    bik,
    bank,
    bankAddress,
    corr,
    rc,
    oktmo,
    kbk,
    lc,
    paymentOrderMethod,
    paymentOrderServiceCode,
    paymentOrderPurpose,
    paymentOrderCreateMethods: createMethods,
  } = singleRequisites

  return {
    org_type: orgType,
    title,
    law_address: lawAddress,
    address,
    post_address: postAddress,
    address_for_reports: reportAddress,
    inn,
    kpp,
    orgn,
    okpo,
    bik,
    bank,
    bank_address: bankAddress,
    corr,
    rc,
    oktmo,
    kbk,
    lc,
    payment_order_kind_id: convertKindApi(kind),
    payment_order_method_id: convertPaymentMethodApi(paymentOrderMethod),
    payment_order_service_code: paymentOrderServiceCode,
    payment_order_purpose_item_ids: (
      Array.from(paymentOrderPurpose.values()).map(convertOrderPurposesApi)
    ),
    payment_order_create_method_ids: (
      Array.from(createMethods.values()).map(convertOrderCreateMethodsApi)
    ),
  }
}

type ParamsApi = {
  person: PersonApi,
  accountant: AccountantApi,
  requisites: {
    [id: string]: SingleRequisitesApi
  }
  support_contacts: any[]
  support_schedule: SupportScheduleApi
}

const convertParamsSupportContacts = (params: SupportContactsType): ResultApi['support_contacts'] => {
  return pipe(
    sortBy(({ position }) => parseInt(position, 10)),
    (items: SupportContactsType) => (
      items.map((item, index) => ({ ...item, position: String(index + 1) }))
    ),
    map<SupportContactsItemType, Array<SupportContactApi>>(({
      position,
      kind,
      samePositionValues,
    }) => {
      return map(({ ID, value }) => {
        const cID = ID && !/^new/.test(ID) ? { id: parseInt(ID, 10) } : {}
        return ({
          ...cID,
          position: parseInt(position, 10),
          kind,
          value,
        })
      }, samePositionValues)
    }),
    unnest
  )(params)
}

const convertParamsSupportSchedule = (param: SupportScheduleType): SupportScheduleApi => {
  return {
    workday_start: param.workday.keyStart ?? null,
    workday_finish: param.workday.keyFinish ?? null,
    workday_timeless: param.workday.keyTimeless,
    lunch_start: param.lunch.keyStart ?? null,
    lunch_finish: param.lunch.keyFinish ?? null,
    lunch_timeless: param.lunch.keyTimeless,
    saturday_start: param.saturday.keyStart ?? null,
    saturday_finish: param.saturday.keyFinish ?? null,
    saturday_dayoff: param.saturday.keyTimeless,
    sunday_start: param.sunday.keyStart ?? null,
    sunday_finish: param.sunday.keyFinish ?? null,
    sunday_dayoff: param.sunday.keyTimeless,
  }
}

export const convertParams = (params: Requisites): [ParamsApi, null] => {
  const { person, accountant, requisites: rMap, supportSchedule, supportContacts } = params

  const requisites = {}
  rMap.forEach((val, key) => {
    const kind = convertKindApi(key)
    requisites[String(kind)] = convertSingleRequisitesApi(val)
  })

  const supportContactsApi = convertParamsSupportContacts(supportContacts)
  const supportScheduleApi = convertParamsSupportSchedule(supportSchedule)

  return [{
    person,
    accountant,
    requisites,
    support_contacts: supportContactsApi,
    support_schedule: supportScheduleApi,
  }, null]
}
