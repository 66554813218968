// @flow
import React from 'react';
import classNames from 'classnames/bind';

import IconCross from 'app/common/icons/IconCross';
import Link from 'app/common/lib/link';
import OverflowedText from 'app/common/components/OveflowedText';

import styles from './ModalExtendedHeader.scss';


type Props = {
  header?: any,
  extra?: any,
  onIconCrossClick?: (e: SyntheticEvent<HTMLElement>) => void,
  closePath?: string,
  className?: string,
};

const ModalExtendedHeader = ({ header, extra, onIconCrossClick, closePath, className }: Props) => {
  if (!header) {
    return null;
  }

  return (
    <div className={classNames(styles.header, className)}>
      {typeof header === 'string'
        ? <OverflowedText className={styles.headerText}>
          {header}
        </OverflowedText>
        : header}

      {extra}

      {(onIconCrossClick || closePath)
        && <Link to={closePath || ''} onClick={onIconCrossClick} className={styles.close}>
          <IconCross />
        </Link>}
    </div>
  );
};

ModalExtendedHeader.defaultProps = {
  closePath: '',
};

export default ModalExtendedHeader;
