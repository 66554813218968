// @flow
import { useCallback } from 'react';

import { api } from 'app/api';
import { reportTypes, POST_REPORTS_PERFORM_ORDER_PURCHASES } from 'app/common/api/requests/reports/perform';
import { GET_REPORTS_STATUS } from 'app/common/api/requests/reports/status';

import type { OOPGenerateParams } from './OOPGenerateParams';


type Handlers = {
  onProcessRequest: () => Promise<number | string | null>,
  onProcessCheckStatus: (reportId: number) => Promise<number | string>,
}

export default function useOOPGenerateRequests(params: OOPGenerateParams | null): Handlers {
  const onProcessRequest = useCallback(async () => {
    if (!params) return null;
    const error = 'Не удалось запустить генерацию отчета';
    const res = await api.request(POST_REPORTS_PERFORM_ORDER_PURCHASES, {
      error,
      params: {
        report_type: reportTypes.REPORT_ORDERS_PURCHASES,
        params,
      },
    });
    if (res) {
      return res;
    }
    return error;
  }, [params]);

  const onProcessCheckStatus = useCallback(async (reportId: number) => {
    const error = 'Не удалось получить статус генерации отчета';
    const res = await api.request(GET_REPORTS_STATUS, {
      error,
      params: {
        report_id: reportId,
      },
    });

    if (typeof res === 'number') {
      return res;
    }
    return error;
  }, []);

  return {
    onProcessRequest,
    onProcessCheckStatus,
  };
}
