import React from 'react';


const IconOk = props => (
  <svg viewBox="0 0 14 22" width="22px" height="22px" {...props}>
    <path d="M12.973 7.412L11.59 6.021l-7.13 7.175l-3.077-3.098L0 11.49l4.46 4.489L12.973 7.412z" />
  </svg>
);

export default IconOk;
