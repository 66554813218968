import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { api } from 'app/api'
import { POST_COMPLEX_GROCERY_SET, convertParams, convertResult } from 'app/common/api/requests/food/menu/complex_grocery_set/post'
import { ComplexGroceryType } from 'app/dataTypes/org/menu'
import { kitGroceryPriceEdit } from 'app/actions/orgs/kits/kitPriceEdit'

/**
 *  Hook создания/редактирования комплекта
 */

type Result = {
  submit: () => Promise<boolean | null>,
  checkErrors: () => boolean
}

export function useEditGroceryKitFormSubmit({
  kitId,
  orgId,
  grocerySetPrice,
  date,
}: ComplexGroceryType): Result {
  const dispatch = useDispatch()


  const submit = useCallback(async () => {
    const res = await api.request(POST_COMPLEX_GROCERY_SET, {
      error: 'Не удалось сохранить стоимость продуктового набора',
      params: {
        kitId,
        orgId,
        date,
        grocerySetPrice,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })
    dispatch(kitGroceryPriceEdit({ id: kitId, grocery_set_price: grocerySetPrice }))
    return res
  }, [kitId, orgId, date, grocerySetPrice, dispatch])


  const checkErrors = () => { return true }

  return { submit, checkErrors }
}
