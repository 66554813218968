// @flow
import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import map from 'ramda/es/map';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';

import {
  PlainDateSelector,
  dateFormatDefault as selectorDateFormat,
} from 'app/common/ui-next/plain-selector';

import useCopyInside, { formId } from './useCopyInside';
import type { Checks } from '../CopyMenuFromToOrgs';
import type { Errors } from '../errors';

import styles from './CopyInside.scss';

/**
 *  Копирование внутри заведения на определенные даты
 */

export const dateFormat = 'YYYY-MM-DD';
const cx = classNames.bind(styles);

type Props = {
  orgId: number,
  date: string,
  formRef: {
    current: ?HTMLFormElement,
  },
  hideControls?: boolean,
  optionsChecks: Checks,
  onClose: (e?: SyntheticEvent<HTMLElement>) => void,
  onSubmitting?: (status: boolean) => void,
  resetErrors: () => void,
  validate: () => boolean,
  errors: Errors,
}

const CopyInside = ({
  orgId,
  date,
  formRef,
  hideControls,
  optionsChecks,
  onClose,
  onSubmitting,
  resetErrors,
  validate,
}: Props) => {
  const disabledDay = moment(date, dateFormat).format(selectorDateFormat);

  const [datesTo, setDatesTo] = useState<Array<string>>([]);
  const handleDatesToSelection = useCallback((selection: Array<string>, selectionDateFormat: string) => {
    resetErrors();
    setDatesTo(map(value => moment(value, selectionDateFormat).format(dateFormat), selection));
  }, [resetErrors]);

  const { saving, submit } = useCopyInside({
    orgId,
    dateFrom: date,
    datesTo,
    onSubmitting,
    onClose,
    optionsChecks,
    validate,
  });

  return (
    <div className={styles.root}>
      <form onSubmit={submit} ref={formRef} id={formId}>
        <div className={styles.subHeader}>
          {'Выберите дату или даты в текущем заведении, на которую копировать меню'}
        </div>
        <PlainDateSelector
          label="На дату"
          multiple
          disabledDay={disabledDay}
          onChange={handleDatesToSelection}
        />

        {/* скрытые элементы управления формой */}
        <ControlsBar className={cx(styles.buttons, { hideControls })} right>
          <PlainButton outline onClick={onClose}>
            {'Отмена'}
          </PlainButton>
          <PlainButton type="submit" name="submit" disabled={saving}>
            {saving ? 'Копирование...' : 'Копировать'}
          </PlainButton>
        </ControlsBar>
      </form>
    </div>
  );
};

export default CopyInside;
