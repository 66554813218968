
// @flow
import type { Request } from '../../index';

type DebtsUrlParams = {
  orgId: number | string,
}

export const GET_DEBTS: Request<
  void,
  string,
  DebtsUrlParams
> = [
  'food/school/{orgId}/debts',
  'get',
  ['data', 'sum'],
];
