import { Include } from 'app/dataTypes/subsidy/settings'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import { FormGridRow, FormGridTemplate } from './FormGridTemplate'


export const controls = {
  saturdays: 'saturdays',
} as const

type Props = {
  data: Include
  disabled?: boolean
  formID: string
  errors: Map<string, string>
  resetErrors: () => void
}

const IncludeForm = ({ data, disabled, formID, errors, resetErrors }: Props) => {
  const error = errors.get('include')

  return (
    <>
      <FormGridTemplate
        title="Начислять льготы по выходным (по субботам):"
      >
        <FormGridRow
          name={controls.saturdays}
          title="Включая субботы"
          data={data.saturdays}
          disabled={disabled}
          formID={formID}
          onModify={resetErrors}
        />
      </FormGridTemplate>
      <PlainHelperText
        className={getScrollToErrorClassName(!!error)}
        error
      >
        {error}
      </PlainHelperText>
    </>
  )
}

export default IncludeForm
