import { createSelector } from 'reselect';
import pipe from 'ramda/es/pipe';
import split from 'ramda/es/split';
import dropLast from 'ramda/es/dropLast';
import length from 'ramda/es/length';
import filter from 'ramda/es/filter';
import join from 'ramda/es/join';
import includes from 'ramda/es/includes';
import replace from 'ramda/es/replace';
import last from 'ramda/es/last';


const defaultEmptyObj = {};

/**
 * Расчет baseUrl, результат запоминается, для определенных входящих параметров
 * для быстрой повтороной выдачи
 * TODO отдельные инстансы для каждого использования в useRouterParams и withRouterParams
 */
export const createCalcBaseUrl = () => createSelector(
  [
    ({ pathname }) => pathname,
    ({ params }) => params || defaultEmptyObj,
    (_, except) => except,
  ],
  (pathname, params, except) => pipe(
    // удаляем /** - (react-router 3)
    path => (params.splat ? replace(`/${params.splat}`, '', path) : path),
    split('/'),
    // если в конце был '/', то последний элемент не считается
    (arr) => {
      if (!last(arr)) {
        return dropLast(1, arr);
      }
      return arr;
    },
    // удаляем с конца пути ровно то количество, что нашли по параметрам
    pathArr => dropLast(length(filter(
      item => !!(params[item] || includes(item, pathArr)),
      except,
    )), pathArr),
    join('/'),
  )(pathname),
);

export default createCalcBaseUrl();
