// @flow
import map from 'ramda/es/map';
import when from 'ramda/es/when';
import anyPass from 'ramda/es/anyPass';
import propEq from 'ramda/es/propEq';
import assoc from 'ramda/es/assoc';

import { type State } from 'app/common/ui-components/filter';

import { mailItems, mailTypes } from 'app/components/common/reports/filters/mail';


const mailItemsTransform = (getItems: typeof mailItems) => {
  return (state: State) => map(when(
    anyPass([
      propEq('id', mailTypes.MAIL_AND_VIEW),
      propEq('id', mailTypes.MAIL_ONLY),
    ]),
    assoc('disabled', true),
  ), getItems(state));
};

export default mailItemsTransform;
