// @flow
import React from 'react';
import classNames from 'classnames';

import normalizeString from 'app/common/lib/normalizeString';

import enhanceInfoItem from './enhanceInfoItem';
import styles from './InfoItem.scss';


type Props = {
  title: string,
  items: Array<[string, string]>,
  className: string,
};

const InfoItem = ({ title, items, className }: Props) => (<div className={classNames(styles.root, className)}>
  <div>
    {title}
      &nbsp;&nbsp;
    <span className={styles.num}>{items.length}</span>
  </div>
  <div className={styles.list}>
    {items.map(item => <div key={item[0]}>{normalizeString(item[1])}</div>)}
  </div>
</div>);

export default enhanceInfoItem(InfoItem);
