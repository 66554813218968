// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as org } from 'app/components/common/reports/filters/org';

import { flags as period } from './period';
import { flags as group } from './group';


const classActivity = (state: State) => {
  return (
    org.singleOrgSelected(state)
    && !(
      period.finalDateInPreviosMonthOrBefore(state)
      && group.byGroupComplexesSelected(state)
      && group.byCampComplexesSelected(state)
    )
  );
};

export default classActivity;
