// @flow
const normalizeString = (s: string): string => {
  if (!s || typeof s !== 'string') return s;
  const trimmed = s.trim();
  if (trimmed.length < 2) return trimmed;
  const lowerCase = trimmed.toLowerCase();
  return `${lowerCase[0].toUpperCase()}${lowerCase.slice(1)}`;
};

export default normalizeString;
