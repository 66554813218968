// @flow
import { createSelector } from 'reselect';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import omit from 'ramda/es/omit';

import { tableColumnSort, tableColumnSortClass } from 'app/common/lib/sort';


/**
 * Селектор данных учеников с учетом сортировки
 */

export type OutgoingProps = {
  idx: number,
  title: string,
  // name: {
  //   first: string,
  //   last: string,
  //   middle: string,
  // },
  classTitle: string,
  data: Array<{
    count: string,
    idx: number,
    sum: string,
  }>,
  total: number,
  // orders: Array<Object>,
  ordersNum: number,
  split1?: number,
  split2?: number,
}

const selectStudentsWithoutExtra = createSelector(
  state => state.reports.subsidies.data.students,
  map(omit(['orders', 'name'])),
);

type Selector = (state: Object, props: Object) => Array<OutgoingProps>
const selectStudents: Selector = createSelector(
  [
    (_, { sort: { sortDirection } }) => sortDirection,
    (_, { sort: { sortKey } }) => sortKey,
    selectStudentsWithoutExtra,
  ],
  (dir, key, items) => {
    if (key === 'classTitle') {
      return tableColumnSortClass(dir, prop(key), items);
    }
    return tableColumnSort(dir, prop(key), items);
  },
);

export default selectStudents;
