// @flow
import React, { type Node, Fragment, useState, Children } from 'react';

import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs';


type Props = {
  children: Node,
}

const TabsPerson = ({ children }: Props): Node => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(1);

  return (
    <Fragment>
      <Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
        <Tab index={1}>{'Руководитель'}</Tab>
        <Tab index={2}>{'Главный бухгалтер'}</Tab>
      </Tabs>

      <TabPanels value={activeTabIndex}>
        {Children.map(children, (child, index) => (
          <TabPanel index={index + 1} key={child.key}>
            {child}
          </TabPanel>
        ))}
      </TabPanels>
    </Fragment>
  );
};

export default TabsPerson;
