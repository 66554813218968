// @flow
import { useCallback, useState, useEffect } from 'react';

import { api } from 'app/api';
import { GET_DOMAIN_DATA, defaultState } from 'app/common/api/requests/common/domain';


export default function useDomainData(domain: string) {
  const [domainData, setDomainData] = useState(defaultState);
  const [loading, setLoading] = useState(true);

  const handleReuestData = useCallback(async () => {
    setLoading(true);
    const domainRes = await api.request(GET_DOMAIN_DATA, {
      error: 'Не удалось получить данные по классам', // не отображается, еще нет redux
      params: { domain },
    });
    setLoading(false);

    if (domainRes) {
      setDomainData({ ...defaultState, ...domainRes });
      return true;
    }

    return false;
  }, [domain]);


  useEffect(() => {
    handleReuestData();
  }, [handleReuestData]);

  return [domainData, loading];
}
