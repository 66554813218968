// @flow
import { map, values } from 'ramda';

import type { POSTParams, FoodPlaceGuests } from 'app/dataTypes/org/settings/FoodPlaceGuests.types';

import type { Request } from '../../../index';


type UrlParams = {
  orgId: string,
}

type ApiParams = {
  school_ids: Array<number>
}

export const convertParams = (params: POSTParams): [ApiParams, UrlParams] => {
  const { guestsIds, orgId } = params;
  return [{ school_ids: guestsIds.map(id => parseInt(id, 10)) }, { orgId }];
};

type ApiResult = {
  [id: string]: {
    id: number,
    title: string,
  }
}

export const convertResult = (res: ApiResult): FoodPlaceGuests => {
  return map(({ id }) => String(id), values(res));
};

export const POST_FOOD_PLACE_GUESTS: Request<
  POSTParams,
  FoodPlaceGuests,
  UrlParams
> = [
  'food/school/{orgId}/food_place_guests',
  'post',
  ['data', 'food_place_guests'],
];
