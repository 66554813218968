// @flow
import { range, map } from 'ramda';


export function createContains<T>(
  columns: number,
  rows: number,
  fill: (column: number, row: number) => T,
): Array<Array<T>> {
  const yArray = range(1, rows + 1);
  const xArray = range(1, columns + 1);

  return (
    map(row => (
      map(column => fill(column - 1, row - 1), xArray)
    ), yArray)
  );
}
