// @flow
import React, { type Node, Fragment } from 'react';

import Loader from 'app/common/components/Loader';
import EmptyReportCSS from 'app/components/common/reports/EmptyReportCSS';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';
import { useCurrencySign } from 'app/common/components-next/currency';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { RowLayoutContent } from 'app/common/layouts/RowLayout';

import HeaderBar from './HeaderBar';
import Summary from './Summary';
import Groups from './Groups';
import useReportData from './useReportData';
import useDisplaySettings from './useDisplaySettings';

import styles from './Report.scss';


type Props = {
  reportId: number | null,
}

const Report = ({ reportId }: Props): Node => {
  const currency = useCurrencySign();

  const [data, loading] = useReportData(reportId);

  const {
    summary,
    groups,
  } = data || {};

  const { showDetails } = useDisplaySettings(!loading);
  const nothing = showDetails ? !(summary || groups) : !summary;

  if (!reportId) {
    return (
      <EmptyReportCSS
        stickyNumber={2}
        headerBar={
          <HistoryLink reportType={reportTypes.REPORT_BALANCES} />
        }
      />
    );
  }

  return (
    <ReportSectionCSS
      stickyNumber={2}
      header="Отчет «Продажи по виду продукции»"
      headerBar={
        <HeaderBar reportId={reportId} />
      }
      paddingFree
    >
      {loading
        ? <Loader />
        : <Fragment>

          {summary
            && <Summary
              summary={summary}
              currency={currency}
            />}

          {showDetails
            && groups
            && <Groups groups={groups} />}

          {nothing
            && <RowLayoutContent borderTop paddingfree>
              <EmptyWarn className={styles.empty}>
                {'Данные отсутствуют'}
              </EmptyWarn>
            </RowLayoutContent>}
        </Fragment>}
    </ReportSectionCSS>
  );
};

export default Report;
