// @flow
import map from 'ramda/es/map';
import filter from 'ramda/es/filter';
import pipe from 'ramda/es/pipe';
import identity from 'ramda/es/identity';
import isEmpty from 'ramda/es/isEmpty';
import values from 'ramda/es/values';
import propEq from 'ramda/es/propEq';
import when from 'ramda/es/when';

import type { RestrictionByRoleMap, RestrictionByRole, OrderType } from 'app/dataTypes/OrgSettingsSubsidies.types';

import * as ot from './orderTypes';


export type Errors = {
  [id: string]: string,
}

const errorText = 'Укажите количество дней или выберите «без ограничений»';
export const emptyErrors: Errors = {};
const availableOrderTypes = values(ot);

const extractRBRErrors = (rbr: RestrictionByRoleMap, type?: OrderType): Errors => {
  const errors = pipe(
    when(
      () => availableOrderTypes.includes(type),
      filter(propEq('orderType', type)),
    ),
    map(({ pastPeriodDays, pastPeriodWithoutRestrictions }: RestrictionByRole) => {
      if (!pastPeriodWithoutRestrictions && !pastPeriodDays && pastPeriodDays !== 0) {
        return errorText;
      }
      return null;
    }),
    filter(identity),
  )(rbr);

  return (isEmpty(errors) ? emptyErrors : errors);
};

export default extractRBRErrors;
