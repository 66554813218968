// @flow
import { useMemo } from 'react';
import { reduce, concat, pipe, splitAt, map } from 'ramda';

import toFixedValue from 'app/common/lib/toFixedValue';

import type { Groups, Group } from '../types';
import { cells } from './useHeaderCellsData';


export const sameGroup = 'row_';

export type RowCells = Array<{
  key: string,
  value: string,
  rowSpan?: number,
  alignLeft?: boolean,
  alignRight?: boolean,
  alignCenter?: boolean,
  ignoreFirstChild?: boolean,
  head?: boolean,
  scope?: 'rowgroup',
  className?: string,
}>

const createRow = (group: Group, index: number): Array<RowCells> => {
  const { title: groupTitle, total, complexes } = group;
  const className = `${sameGroup}${index}`;

  return complexes.map(({ title, n, cost, sum }, i) => {
    const common = [{
      key: cells[1].key,
      value: title || '???',
      ignoreFirstChild: i !== 0,
      className,
    }, {
      key: cells[2].key,
      value: String(n),
      className,
    }, {
      key: cells[3].key,
      value: toFixedValue(cost),
      className,
    }, {
      key: cells[4].key,
      value: toFixedValue(sum),
      ignoreFirstChild: i !== 0,
      className,
    }];

    if (i === 0) {
      const rowSpan = complexes.length;
      const head = {
        key: cells[0].key,
        value: groupTitle || '???',
        rowSpan,
        alignLeft: true,
        head: true,
        scope: 'rowgroup',
        className,
      };
      const tail = {
        key: cells[5].key,
        value: toFixedValue(total),
        rowSpan,
        alignCenter: true,
        className,
      };

      return [head, ...common, tail];
    }
    return common;
  });
};

export default function useRowCellsData(groups: Groups): [Array<RowCells>, Array<RowCells>] {
  const [content, footer] = useMemo(() => {
    let index = 0;
    return pipe(
      splitAt(-1),
      map(
        reduce((acc, group) => {
          const rows = createRow(group, index++);
          return concat(acc, rows);
        }, []),
      ),
    )(groups);
  }, [groups]);

  return [content, footer];
}
