// @flow
import api from 'app/actions/api';

export const ITEMSEDIT_REQUEST = 'orgs/menu/ITEMSEDIT_REQUEST';
export const ITEMSEDIT_SUCCESS = 'orgs/menu/ITEMSEDIT_SUCCESS';
export const ITEMSEDIT_FAILURE = 'orgs/menu/ITEMSEDIT_FAILURE';

export const itemsEditRequest = (payload: Object) => ({
  type: ITEMSEDIT_REQUEST,
  payload,
});

export const itemsEditSuccess = () => ({
  type: ITEMSEDIT_SUCCESS,
});

export const itemsEditFailure = (payload: Object) => ({
  type: ITEMSEDIT_FAILURE,
  payload,
});

type Params = {
  date: string,
  sellPointId: string | number,
};

export const itemsEdit = (orgId: string | number, {
  date,
  sellPointId,
}: Params, addList: Array<number>, removeList?: Array<number>) => async (dispatch: Function) => {
  const dataToSend = {
    school_id: orgId,
    date,
    sale_point_id: sellPointId,
    add_dishes: addList,
    remove_dishes: removeList,
  };
  dispatch(itemsEditRequest(dataToSend));
  try {
    await api('food/menu/menu_items_add_remove', 'post', { data: dataToSend });
    dispatch(itemsEditSuccess());
    return true;
  } catch (error) {
    dispatch(itemsEditFailure({ err: 'Не удалось модифицировать меню', error }));
    return false;
  }
};
