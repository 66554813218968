import { useMemo } from 'react'
import { identity } from 'ramda'

import { Org, OrgsData, Params } from 'app/dataTypes/org'

import useRequestOrgs from './useRequestOrgs'


const defaultState: OrgsData = {
  items: new Map(),
  total: 0,
}

export default function useOrgsData(params: Params = {}, externalOrgs?: Map<string, Org>) {
  const replaceBy = useMemo(() => {
    return externalOrgs && {
      items: externalOrgs,
      total: externalOrgs.size,
    }
  }, [externalOrgs])

  const res = useRequestOrgs({
    transform: identity,
    defaultState,
    params,
    replaceBy,
  })
  return res
}
