// @flow
import mapProps from 'recompose/mapProps';
import omit from 'ramda/es/omit';


const omitProps = (propsArray: Array<string>) => mapProps(
  omit(propsArray),
);

export default omitProps;
