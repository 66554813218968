import { ReactNode } from 'react'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'

import PersonalLimitsMultipleReset from './PersonalLimitsMultipleReset'
import usePersonalLimitsReset, { resetTypes } from './usePersonalLimitsReset'
import MultipleModalAdditionalContent from './MultipleModalAdditionalContent'
import MultipleModalBlockInfo from './MultipleModalBlockInfo'


export { resetTypes }
type Keys = keyof typeof resetTypes

type Props = {
  children: ReactNode,
  type: typeof resetTypes[Keys],
  remainingBlockingMilliseconds: number,
  onSuccess: (res: Array<boolean>) => void,
}

const PersonalLimitsReset = ({
  children,
  type,
  remainingBlockingMilliseconds,
  onSuccess,
}: Props) => {
  const reset = usePersonalLimitsReset({ type })

  return (
    <PersonalLimitsMultipleReset
      onReset={reset}
      multipleAfterContent={<MultipleModalBlockInfo remaining={remainingBlockingMilliseconds} />}
      additionalContent={<MultipleModalAdditionalContent />}
      onSuccess={onSuccess}
      disableMultiple={remainingBlockingMilliseconds > 0}
    >
      {(beginProcess, submitting) => (
        <PlainButtonAsLink onClick={beginProcess} disabled={submitting}>
          {children}
        </PlainButtonAsLink>
      )}
    </PersonalLimitsMultipleReset>
  )
}

export default PersonalLimitsReset
