// @flow
import { useMemo } from 'react';
import { map, filter, pipe } from 'ramda';

import type { Dish } from 'app/dataTypes/Dishes.types';

import { cells } from './useHeaderCellsData';
import { type ColumnVisibility } from './columns';


export type RowCell = {
  key: string,
  value: string,
  alignLeft?: boolean,
  alignRight?: boolean,
  alignCenter?: boolean,
  className?: string,
}

const createRow = ({ id, title, photoUrl, portion, cost }: Dish): Array<RowCell> => (
  [{
    key: cells[0].key,
    value: id,
    alignLeft: true,
  }, {
    key: cells[1].key,
    value: title,
    alignLeft: true,
  }, {
    key: cells[2].key,
    value: photoUrl || '',
    alignCenter: true,
  }, {
    key: cells[3].key,
    value: portion,
    alignCenter: true,
  }, {
    key: cells[4].key,
    value: cost,
    alignCenter: true,
  }]);

const updateRowBySettings = (columnSettings: ColumnVisibility) => (
  filter(({ key }) => (columnSettings[key] || columnSettings[key] === undefined))
);

export default function useRowCellsData(
  dishes: Array<Dish>,
  columnSettings: ColumnVisibility,
): Array<Array<RowCell>> {
  const res = useMemo(() => {
    return map(pipe(
      createRow,
      updateRowBySettings(columnSettings),
    ), dishes);
  }, [dishes, columnSettings]);

  return res;
}
