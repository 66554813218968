// import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import defaultProps from 'recompose/defaultProps';
import componentFromProp from 'recompose/componentFromProp';
import withHandlers from 'recompose/withHandlers';
import compose from 'recompose/compose';
// import mapProps from 'recompose/mapProps';

import * as settingsActions from 'app/common/actions/settings/save';

import DefaultSettings from './DefaultSettings';

/**
* Компонент для вывода настроек
* Можно использовать <Settings component=AnotherSettingsComponent dataKey="passages" />
* По умолчанию используется компонент <DefaultSettings />
* dataKey: редюсер в settings, который отвечает за настройки соответствующего раздела
*/

export default compose(
  connect(
    (state, ownProps) => ({
      settings: state.settings[ownProps.dataKey],
    }),
    dispatch => ({
      actions: bindActionCreators(settingsActions, dispatch),
    }),
  ),

  defaultProps({ component: DefaultSettings }),

  withHandlers({
    saveSettings: props => (obj) => {
      props.actions.saveSettingsKey(props.dataKey, obj);
    },
  }),
  // mapProps((props) => {
  //   console.log('SettingsContainer: ', props);
  //   return props;
  // })
)(componentFromProp('component'));
