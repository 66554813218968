// @flow
import assoc from 'ramda/es/assoc';
import assocPath from 'ramda/es/assocPath';
import mergeDeepLeft from 'ramda/es/mergeDeepLeft';
import pipe from 'ramda/es/pipe';

import {
  EMPLOYEE_SETTINGS_REQUEST,
  EMPLOYEE_SETTINGS_SUCCESS,
  EMPLOYEE_SETTINGS_FAILURE,
  EMPLOYEE_SETTINGS_CLEAR,
  PASS_SEND_TO_FILE,
} from 'app/actions/employees/employee/settings';

import {
  CHANGE_ACTIVITY_REQUEST,
  CHANGE_ACTIVITY_SUCCESS,
  CHANGE_ACTIVITY_FAILURE,
} from 'app/actions/employees/employee/settings/activity';

import type { PassSendToType, ActiveType } from 'app/actions/employees/employee/settings';


const defaultState = {
  loading: true,
  result: null,
  settings: {
    status: null,
    passwordSendOptions: [PASS_SEND_TO_FILE],
  },
  created: {
    datetime: '',
    source: '',
    actor: {
      first: '',
      last: '',
      middle: '',
    },
  },
  lastChange: {
    datetime: '',
    source: '',
    actor: {
      first: '',
      last: '',
      middle: '',
    },
    description: '',
  },
  lastLogin: {
    datetime: '',
    ip: 'Неизвестно',
  },
  lastVisit: {
    datetime: '',
    ip: 'Неизвестно',
  },
};

export type SettingsType = {
  status: ?ActiveType,
  passwordSendOptions: Array<PassSendToType>,
};

export type ModifyInfoType = {
  datetime: string,
  source: string,
  actor: {
    first: string,
    last: string,
    middle: string,
  },
  description?: string,
};

export type IpInfoType = {
  datetime: string,
  ip: ?string,
};

const settings = (state: Object = defaultState, { type, payload }: Object) => {
  switch (type) {
    case EMPLOYEE_SETTINGS_REQUEST: {
      return { ...state, loading: true };
    }
    case EMPLOYEE_SETTINGS_SUCCESS: {
      return pipe(
        mergeDeepLeft(payload),
        assoc('loading', false),
      )(defaultState);
    }
    case EMPLOYEE_SETTINGS_FAILURE: {
      return { ...state, loading: false };
    }
    case EMPLOYEE_SETTINGS_CLEAR: {
      return defaultState;
    }
    case CHANGE_ACTIVITY_REQUEST: {
      return assocPath(['settings', 'status'], payload.status, state);
    }
    case CHANGE_ACTIVITY_SUCCESS: {
      return state;
    }
    case CHANGE_ACTIVITY_FAILURE: {
      return assocPath(['settings', 'status'], payload.oldStatus, state);
    }
    default:
  }
  return state;
};

export default settings;
