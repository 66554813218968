// @flow
import type { Request } from '../../index';
import type { ItemCategoriesResult } from '../schools/item_categories';


// создание
type PostItemCategoryParams = {
  title: string,
  school_id: number,
  index: number,
}

export const POST_ITEM_CATEGORY: Request<
  PostItemCategoryParams,
  ItemCategoriesResult,
  void
> = [
  'food/menu/item_category',
  'post',
  ['data', 'categories'],
];

// изменение
type PutItemCategoryParams = {
  title?: string,
  school_id: number,
  category_id: number,
  index?: number,
}

export const PUT_ITEM_CATEGORY: Request<
  PutItemCategoryParams,
  ItemCategoriesResult,
  void
> = [
  'food/menu/item_category',
  'put',
  ['data', 'categories'],
];

// удаление
type DeleteItemCategoryParams = {
  school_id: number,
  category_id: number,
}

export const DELETE_ITEM_CATEGORY: Request<
  DeleteItemCategoryParams,
  'success',
  void
> = [
  'food/menu/item_category',
  'delete',
  ['data', 'result'],
];
