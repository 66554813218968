import { createSelector } from 'reselect';
import equals from 'ramda/es/equals';

const selectRights = createSelector(
  [
    state => state.admin.rights.sections,
    state => state.admin.rights.savedSections,
  ],
  (sections, savedSections) => ({
    isModified: !equals(sections, savedSections),
    rights: sections,
  }),
);

export default selectRights;
