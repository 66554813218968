// @flow
import { createContext } from 'react';

import type { OrgsTypesState } from './useOrgTypes';


const defaultContext: OrgsTypesState = [
  [],
  true,
  () => {},
];

export default createContext<OrgsTypesState>(defaultContext);
