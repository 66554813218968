import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'ramda'

import { Requisites } from 'app/dataTypes/requisites'
import useScrollToError from 'app/common/hooks/useScrollToError'

import { checkSupportContactsErrors } from './checkSupportContactsErrors'
import { defaultErrorsState } from './types'
import { checkSupportScheduleErrors } from './checkSupportScheduleErrors'

type Props = {
  data: Requisites
  checkError: boolean
}

export default function useErrors({ data, checkError }: Props) {
  const [errors, setErrors] = useState(defaultErrorsState)

  const scrollToError = useScrollToError()

  const checkErrors = useCallback(({ supportContacts, supportSchedule }: Requisites) => {
    const supportContactsErrors = checkSupportContactsErrors(supportContacts)
    const supportScheduleErrors = checkSupportScheduleErrors(supportSchedule)
    setErrors({
      ...supportContactsErrors, ...supportScheduleErrors,
    })
    const hasNoErrorsContacts = isEmpty(supportContactsErrors)
    const hasNoErrorsSchedule = isEmpty(supportScheduleErrors)
    if (
      !hasNoErrorsContacts
      || !hasNoErrorsSchedule
    ) scrollToError()
    return hasNoErrorsContacts && hasNoErrorsSchedule
  }, [scrollToError])

  const resetErrors = useCallback(() => {
    setErrors(defaultErrorsState)
  }, [])

  useEffect(() => {
    if (checkError) {
      checkErrors(data)
    }
  }, [checkError]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    errors,
    checkErrors,
    resetErrors,
  }
}
