import { useMemo } from 'react'
import { isEmpty, groupBy, identity, filter, prop, __ } from 'ramda'

import Loading from 'app/common/components/Loader'
import EmptyWarn from 'app/common/layouts/EmptyWarn'
import Tile, { TileType } from 'app/common/templates-next/tile-template/tile'

import { reportsArrange, getReportTitle, getReportDescription } from './availableReports'
import { ReportFavoritesSwitcher } from '../common/reports/ReportFavoritesSwitcher'
import useReportsEnabled from './useReportsEnabled'

import styles from './FinancesRoot.scss'


type Props = {
  createLink: (reportKey: string) => string
}

const FinancesRoot = ({ createLink }: Props) => {
  const [enabledReports, loading] = useReportsEnabled()

  const enabledArranged = useMemo(() => {
    const enabledByKey = groupBy(identity, enabledReports)
    return filter(prop(__, enabledByKey), reportsArrange)
  }, [enabledReports])

  if (loading) {
    return (
      <Loading />
    )
  }

  const nothing = isEmpty(enabledArranged)
  if (nothing) {
    return (
      <EmptyWarn className={styles.emptyWarn}>
        {'Нет доступных отчетов'}
      </EmptyWarn>
    )
  }

  return (
    <div className={styles.root}>
      {enabledArranged.map((reportKey) => {
        return (
          <Tile
            key={reportKey}
            type={TileType.Report}
            link={createLink(reportKey)}
            title={getReportTitle(reportKey)}
            info={getReportDescription(reportKey)}
            linkButtonTitle="Открыть отчет"
            showMoreButton
          >
            <ReportFavoritesSwitcher reportKey={reportKey} />
          </Tile>
        )
      })}
    </div>
  )
}

export default FinancesRoot
