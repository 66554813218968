import { Td, Tr } from 'app/common/ui-components/table'
import { Transaction } from 'app/dataTypes/compensation/transations'

import { Column } from './columns'
import { Cell } from './cell'


type Props = {
  columns: Array<Column>
  columnProps: Record<Column, {
    alignLeft?: boolean
    alignRight?: boolean
    alignCenter?: boolean
  }>
  transaction: Transaction
}

export const TableRow = ({
  columns,
  columnProps,
  transaction,
}: Props) => {
  return (
    <Tr>
      {columns.map(key => (
        <Td key={key} {...columnProps[key]}>
          <Cell
            columnKey={key}
            transaction={transaction}
          />
        </Td>
      ))}

    </Tr>
  )
}
