// @flow
import { useCallback } from 'react';
import { reject, propEq } from 'ramda';

import { DELETE_VENDING_ITEMS_CATEGORIES, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/categories/delete';
import type { Categories } from 'app/dataTypes/vending/production/Categories.types';
import { api } from 'app/api';


export default function useCategoryDelete(
  setCategories: ((Categories => Categories) | Categories) => void,
): (categoryId: string) => Promise<boolean> {
  const deleteCategory = useCallback(async (categoryId: string) => {
    const res = await api.request(DELETE_VENDING_ITEMS_CATEGORIES, {
      error: 'Не удалось удалить категорию',
      params: {
        categoryId,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      setCategories(reject(propEq('id', categoryId)));
      return true;
    }
    return false;
  }, [setCategories]);

  return deleteCategory;
}
