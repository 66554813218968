import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { defaultMonthSelection, monthFilter, monthFilterSelection } from 'app/dataProviders/filter-items'
import { identity } from 'ramda'


type Props = {
  id: string
  pending: boolean
}

export const MonthFilter = ({ id, pending }: Props) => {
  return (
    <PlainItemsSelectorBox
      id={id}
      label="Месяц"
      header="Выберите месяц"
      dataBoxData={monthFilter}
      dataBoxSelection={monthFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection={false}
      defaultSelection={defaultMonthSelection}
      disabled={pending}
      sortFunc={identity}
      resetOnDismount
    />
  )
}
