import { SupportScheduleType } from 'app/dataTypes/requisites'

import { reduce } from 'ramda'
import { SupportErrors } from './types'
import { timeCompare } from '../utilities/timeUtilities'


const errorTextStart = 'Не указано время начала периода'
const errorTextFinish = 'Не указано время окончания периода'
const errorTextEmpty = 'Укажите диапазон'
const errorTextTimeCompare = 'Время начала периода больше времени окончания периода'
const createErrorPath = (ID: string, fieldName: string) => `supportSchedule.${ID}.${fieldName}`


export const checkSupportScheduleErrors = (supportSchedule: SupportScheduleType) => {
  const supportScheduleValues = Object.values(supportSchedule)
  return reduce((acc, { formID, keyStart, keyFinish, keyTimeless }) => {
    if (!formID) return acc
    if (!keyStart && keyFinish) {
      acc[createErrorPath(formID, 'keyStart')] = errorTextStart
    }
    if (keyStart && !keyFinish) {
      acc[createErrorPath(formID, 'keyFinish')] = errorTextFinish
    }

    if (!keyTimeless && !keyStart && !keyFinish) {
      acc[createErrorPath(formID, 'keyStart')] = errorTextEmpty
      acc[createErrorPath(formID, 'keyFinish')] = errorTextEmpty
    }

    if (!keyStart || !keyFinish) return acc

    const isNotValidCompare = timeCompare(keyStart, keyFinish)
    if (isNotValidCompare) {
      acc[createErrorPath(formID, 'keyStart')] = errorTextTimeCompare
      acc[createErrorPath(formID, 'keyFinish')] = errorTextTimeCompare
    }
    return acc
  }, {} as SupportErrors, supportScheduleValues)
}

export const getSupportScheduleStartError = (ID: string, errors: SupportErrors) => (
  errors[createErrorPath(ID, 'keyStart')]
)

export const getSupportScheduleFinishError = (ID: string, errors: SupportErrors) => (
  errors[createErrorPath(ID, 'keyFinish')]
)


export const getSupportScheduleError = (ID: string, errors: SupportErrors) => (
  getSupportScheduleStartError(ID, errors)
  || getSupportScheduleFinishError(ID, errors)
)
