import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import path from 'ramda/es/path';

import { dateFilterSet, dateFilterClear } from 'app/actions/reports/filters/date';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';

import { selectOrgId, selectYearId, selectCustomerId } from './commonSelectors';
import { selectIsAll as selectIsAllCustomers } from './withCustomers';


const selectStartYDate = createSelector(
  [
    selectYearId,
    state => state.reports.filters.years.data,
  ],
  (yearId, years) => (path([yearId, 'dateStart'], years) || null),
);

const selectFinYDate = createSelector(
  [
    selectYearId,
    state => state.reports.filters.years.data,
  ],
  (yearId, years) => (path([yearId, 'dateFinish'], years) || null),
);

// входит ли "сегодня" в выбранный учебный год
const selectIsTodayInsideSelectedYear = createSelector(
  [
    selectStartYDate,
    selectFinYDate,
  ],
  (ds, df) => moment().isBetween(ds, df, 'day', '[]'),
);

// Ограничивается ли выбор даты
const selectIsRestricted = createSelector(
  [
    selectOrgId,
    selectCustomerId,
    selectIsAllCustomers,
  ],
  (orgId, customerId, allCustomers) => (
    orgId && (customerId === 'Учащиеся' || allCustomers)
  ),
);

export default compose(
  connect(
    state => ({
      date: state.reports.filters.date.date,
      current: state.reports.filters.date.current,
      isRestricted: selectIsRestricted(state),
      isInside: selectIsTodayInsideSelectedYear(state),
      startYDate: selectStartYDate(state),
      finYDate: selectFinYDate(state),
      today: moment().format('YYYY-MM-DD'),
    }),
    dispatch => ({
      handleChangeDate: (date) => {
        if (date) {
          dispatch(dateFilterSet({ date }));
        }
      },
      handleSwitchType: (e) => {
        const { value } = e.currentTarget;
        dispatch(dateFilterSet(value === 'current'
          ? { current: true }
          : { current: false }));
      },
      reset: () => dispatch(dateFilterClear()),
      dispatch,
    }),
  ),

  withHandlers({
    disabledDays: ({ startYDate, finYDate, isRestricted, isInside, today }) => (dayFormatted: string, format: string, unit?: string) => {
      if (!isRestricted) return false;
      const mDay = moment(dayFormatted, format);
      if (!startYDate || !finYDate) {
        return mDay.isAfter(today, unit);
      }
      if (isInside) {
        return !mDay.isBetween(startYDate, today, unit, '[]');
      }
      return !mDay.isBetween(startYDate, finYDate, unit, '[]');
    },
  }),

  lifecycle({
    componentDidUpdate(prevProps) {
      // ОКЛЮЧЕНО ТАК КАК ТЕПЕРЬ НА ЭТО ВЛИЯЕТ ВЫБРАННАЯ ОРГАНИЗАЦИЯ, ЕЕ ДАТЫ ДЕЙСТВИЯ
      // const { dispatch, startYDate, isInside } = this.props;
      // if (startYDate !== prevProps.startYDate) {
      //   // меняем выбранную дату, если выбраннуй уч. год не включает "сегодня"
      //   dispatch(!isInside
      //     ? dateFilterSet(startYDate)
      //     : dateFilterClear());
      // }
      resetOnStatusChange(this.props, prevProps);
    },
  }),

);
