// @flow
import { connect } from 'react-redux';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import compose from 'recompose/compose';
import defaultProps from 'recompose/defaultProps';


export const defaultLang = {
  confirmRestoreText: 'Вы уверены, что хотите восстановить категорию?',
  confirmDeleteText: 'Вы уверены, что хотите перемесить категорию в удаленные?',
  tooltipRestoreText: 'Восстановить категорию',
  tooltipDeleteText: 'Удалить категорию',
  titleErrorText: 'Требуется хотя бы один символ',
  removeButtonText: 'Удалить',
  restoreButtonText: 'Восстановить',
};

type Lang = typeof defaultLang;
export type { Lang };

type Props = {
  id: number,
  deleted?: boolean,
  title: string,
  loading: boolean,
  onSaveTitle: (e: SyntheticEvent<HTMLElement>) => void,
  onDelete: (id: number) => void,
  onRestore: (id: number) => void,
  handleToggleItem: (id: number, checked: boolean, deleted?: boolean) => void,
  lang: Lang,
};

export default compose(
  defaultProps({ lang: defaultLang }),

  connect(
    (state, props: Props) => ({
      savedTitle: props.title,
    }),
  ),


  withState('title', 'setTitle', ({ title }) => title),
  withState('error', 'setError', ''),


  withHandlers({
    handleToggleItem: ({ onToggleItem, deleted }) => (e) => {
      const { value, checked } = e.currentTarget;
      const id = parseInt(value, 10);
      onToggleItem(id, checked, deleted);
    },
    handleSave: ({ id, title, setError, savedTitle, setTitle, onSaveTitle, lang }) => async () => {
      if (!title) {
        setError(lang.titleErrorText);
        setTitle(savedTitle);
        return;
      }
      onSaveTitle({ id, title });
    },

    handleEdit: ({ setTitle, setError }) => (e) => {
      setTitle(e.currentTarget.value);
      setError('');
    },
  }),

);
