// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import type { HOC } from 'recompose';

import { saveSettingsKey } from 'app/common/actions/settings/save';

import { CONTENT_MARGIN, CONTENT_MARGIN_COLLAPSED } from './constants';


type Item = {
  string: string,
  Icon: any,
  title: string
};

type Props = {
  lock?: string | boolean, // лочить свертываемость, если 'wide' в широком полложении, иначе в узком
  handleCollapse?: Function,
  activeName: string,
  items: Array<Item>,
  baseUrl: string,
  pickByFilters?: string,
  children: any,
  className?: string,
  isWide: boolean,
  onCollapse: Function,
};

const enhanceSideNav: HOC<Props, void> = compose(
  connect(
    (state, { lock }) => ({
      isWide: lock ? (lock === 'wide') : state.settings.sidemenu.wide, // TODO get
    }),
    dispatch => ({
      saveState: st => dispatch(saveSettingsKey('sidemenu', { wide: st })),
    }),
  ),


  withState('internalState', 'setInternalState', props => props.isWide),


  withHandlers({
    onCollapse: ({ isWide, saveState, handleCollapse, lock, setInternalState }) => (e) => {
      e.preventDefault();
      if (lock) {
        return;
      }
      if (typeof handleCollapse === 'function') {
        handleCollapse(!isWide ? CONTENT_MARGIN : CONTENT_MARGIN_COLLAPSED);
      }
      saveState(!isWide);
      setInternalState(!isWide);
    },
  }),


  lifecycle({
    componentDidMount() {
      if (this.props.lock) {
        this.props.saveState(this.props.isWide);
        this.props.setInternalState(this.props.isWide);
      }
    },

    componentDidUpdate(prevProps) {
      if (prevProps.lock !== this.props.lock) {
        if (this.props.lock) {
          this.props.saveState(this.props.isWide);
        } else {
          this.props.saveState(this.props.internalState);
        }
      }
    },
  }),
);

export default enhanceSideNav;
