import { values } from 'ramda'

import { FinanceReportType } from './finances'


// отчеты сделанные по 3й версии
export enum ReportType {
  Corrections = 'corrections',
  OtherReport = 'report' // placeholder for adding new report
}

export type AnyReportType = ReportType | FinanceReportType

export const isReport = (val: any): val is ReportType => {
  return values(ReportType).includes(val)
}

export type ReportID = string

export type ReportParams = {
  reportID: ReportID
}

export type RequestParams = {
  formData: FormData,
  reportType: AnyReportType
}
