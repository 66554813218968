import { useState, ChangeEvent } from 'react'
import { Prompt } from 'react-router-dom'

import { PlainGroup } from 'app/common/ui/PlainGrid'
import { paymentOrderKinds, Requisites } from 'app/dataTypes/requisites'

import PersonForm from './form/PersonForm'
import LegalForm from './form/LegalForm'
import BankForm from './form/BankForm'
import AdditionalForm from './form/AdditionalForm'
import TabsPerson from './TabsPerson'
import TabsPaymentOrderKinds from './TabsPaymentOrderKinds'
import { defaultSingle } from './useDataDefaults'
import DownloadExample from './DownloadExample'
import { SupportSchedule } from './SupportSchedule'
import SupportContacts from './SupportContacts'
import { ChangeHandlerType, UpdateHandlerType } from './useData'

import styles from './RequisitesForm.scss'
import { SupportErrors } from './errors/types'


const requisitesArrange = [paymentOrderKinds.school, paymentOrderKinds.camp]

type Props = {
  data: Requisites
  errors: SupportErrors
  disabled?: boolean
  loading?: boolean
  modified?: boolean
  formID: string
  onSubmit: (e: ChangeEvent<HTMLFormElement>) => Promise<void>
  onChange: ChangeHandlerType
  onUpdate: UpdateHandlerType
}

const RequisitesForm = ({
  data,
  errors,
  disabled,
  loading,
  modified,
  formID,
  onSubmit,
  onChange,
  onUpdate,
}: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(1)

  return (
    <form onSubmit={onSubmit} id={formID}>
      <PlainGroup header="Контактные лица">
        <TabsPerson>
          <PersonForm
            key="person"
            locked={disabled}
            loading={loading}
            value={data.person}
            valueBasePath="person"
            onChange={onChange}
          />
          <PersonForm
            key="accountant"
            locked={disabled}
            loading={loading}
            value={data.accountant}
            valueBasePath="accountant"
            onChange={onChange}
          />
        </TabsPerson>
      </PlainGroup>

      <PlainGroup
        header={<div>
          <div>{'Контакты для связи'}</div>
          <div>{'(контакты будут доступны для родителей и учащихся в web- и мобильном приложениях)'}</div>
        </div>}
      >
        <SupportContacts
          errors={errors}
          data={data.supportContacts}
          locked={disabled}
          loading={loading}
          onUpdate={onUpdate}
        />
        <SupportSchedule
          errors={errors}
          value={data.supportSchedule}
          locked={disabled}
          loading={loading}
          onChange={onChange}
        />
      </PlainGroup>

      <PlainGroup header="Юридические реквизиты">
        <TabsPaymentOrderKinds
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          skip={data.requisites.size < 2}
        >
          {requisitesArrange.map(kind => (
            data.requisites.has(kind)
              ? <LegalForm
                key={kind}
                locked={disabled}
                loading={loading}
                value={data.requisites.get(kind) || defaultSingle}
                valueBasePath={`requisites.${kind}`}
                onChange={onChange}
              />
              : null
          ))}
        </TabsPaymentOrderKinds>
      </PlainGroup>

      <PlainGroup header="Банковские реквизиты">
        <TabsPaymentOrderKinds
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          skip={data.requisites.size < 2}
        >
          {requisitesArrange.map(kind => (
            data.requisites.has(kind)
              ? <BankForm
                key={kind}
                locked={disabled}
                loading={loading}
                value={data.requisites.get(kind) || defaultSingle}
                valueBasePath={`requisites.${kind}`}
                onChange={onChange}
              />
              : null
          ))}
        </TabsPaymentOrderKinds>
      </PlainGroup>

      <PlainGroup
        className={styles.doubleLine}
        header="Управление квитанциями"
      >
        <TabsPaymentOrderKinds
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          skip={data.requisites.size < 2}
          addidionalContent={
            <DownloadExample
              type={activeTabIndex === 1 ? 'school' : 'camp'}
              filename={
                activeTabIndex === 1
                  ? 'Образец квитанции -- школа.pdf'
                  : 'Образец квитанции -- лагерь.pdf'
              }
            />
          }
        >
          {requisitesArrange.map(kind => (
            data.requisites.has(kind)
              ? <AdditionalForm
                key={kind}
                locked={disabled}
                loading={loading}
                value={data.requisites.get(kind) || defaultSingle}
                valueBasePath={`requisites.${kind}`}
                onChange={onChange}
              />
              : null
          ))}
        </TabsPaymentOrderKinds>
      </PlainGroup>

      <Prompt
        when={modified}
        message="Вы не сохранили изменения, перейти в любом случае?"
      />
    </form>
  )
}

export default RequisitesForm
