// @flow
import { useState, useCallback, useEffect } from 'react';

import { api } from 'app/api';
import { GET_REPORTS_DATA_FINANCES_E_MONEY } from 'app/common/api/requests/reports/data';
import type { ReportsDataFinancesEMoneyResult, CSchool, Total } from 'app/common/api/requests/reports/data.finances_e_money';

// import testData from './testData';


export type ReportData = ReportsDataFinancesEMoneyResult
export type { CSchool, Total };
export type Summary = $Diff<ReportsDataFinancesEMoneyResult, { c_schools: any }>

const defaultState: ReportsDataFinancesEMoneyResult = {
  c_schools: [],
  c_sum: null,
  s_skipped_sum: null,
  s_sum: null,
  u_percents: null,
  u_com_sum: null,
  u_ret_sum: null,
  total: {
    complex_s: null,
    complex_ret: null,
    complex_com: null,
    personal_s: null,
    personal_ret: null,
    personal_com: null,
    total: null,
  },
};

export default function useReportData(reportId: ?number): [ReportData, boolean] {
  const [reportData, setReportData] = useState<ReportData>(defaultState);
  const [loading, setLoading] = useState(false);

  const requestItems = useCallback(async () => {
    if (!reportId) return;

    setLoading(true);
    const res = await api.request(GET_REPORTS_DATA_FINANCES_E_MONEY, {
      error: 'Не удалось загрузить данные отчета',
      params: {
        report_id: reportId,
      },
    });

    if (res) {
      setReportData({ ...defaultState, ...res });
    }
    setLoading(false);
  }, [reportId]);


  useEffect(() => {
    requestItems();
  }, [requestItems]);

  return [reportData, loading];
}
