// @flow
import api from 'app/actions/api';

export const DISHDELETE_REQUEST = 'orgs/kits/DISHDELETE_REQUEST';
export const DISHDELETE_SUCCESS = 'orgs/kits/DISHDELETE_SUCCESS';
export const DISHDELETE_FAILURE = 'orgs/kits/DISHDELETE_FAILURE';

export const dishDeleteRequest = (payload: Object) => ({
  type: DISHDELETE_REQUEST,
  payload,
});

export const dishDeleteSuccess = (payload: Object) => ({
  type: DISHDELETE_SUCCESS,
  payload,
});

export const dishDeleteFailure = (payload: Object) => ({
  type: DISHDELETE_FAILURE,
  payload,
});

type Params = {
  date: string,
  kitId: string | number,
};

export const dishDelete = (
  orgId: string | number,
  {
    date,
    kitId,
  }: Params,
  dishId: number,
) => async (dispatch: Function) => {
  const dataToSend = {
    school_id: orgId,
    complex_id: kitId,
    date,
    remove_dishes: [dishId],
  };
  dispatch(dishDeleteRequest(dataToSend));
  try {
    await api('food/menu/complex_items_add_remove', 'post', { data: dataToSend });
    dispatch(dishDeleteSuccess({ kitId, dishId }));
    return true;
  } catch (error) {
    dispatch(dishDeleteFailure({ err: 'Не удалось удалить блюдо из комплекта', error }));
    return false;
  }
};
