// @flow

/**
 *  Запрос данных текущей организации + остальных того же типа или нет,
 *  в зависимости от переданных параметров
 *  DEPRECATED?
 */

import OrgsDataProvider from './OrgsDataProvider';
import useOrgsData from './useOrgsData';
import type { OrgsSchools, OrgItem } from './useOrgs';
import useActualOrgs from './useActualOrgs';
import useActualOrgs2Selector, { type ActualOrgs } from './useActualOrgs2Selector';
import useDateFrom from './useDateFrom';
import hasSingleTarget from './hasSingleTarget';
import useOrg from './useOrg';
import useOrgsMapFromOrgs from './useOrgsMapFromOrgs';

export default OrgsDataProvider;

export {
  useOrgsData,
  useActualOrgs,
  useDateFrom,
  hasSingleTarget,
  useOrg,
  useActualOrgs2Selector,
  useOrgsMapFromOrgs,
};

export type { OrgsSchools, OrgItem, ActualOrgs };
