import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import compose from 'recompose/compose';


export default compose(
  withState('isTopShadow', 'setTopShadow', ({ isTopShadow }) => isTopShadow || false),
  withState('isBottomShadow', 'setBottomShadow', ({ isBottomShadow }) => isBottomShadow || false),
  withState('isScrollActive', 'setScrollActive', ({ isScrollActive }) => isScrollActive || false),
  // withState('top', 'setTop', 0.0),


  withHandlers({
    updateScroll: ({
      setTopShadow,
      setBottomShadow,
      // top,
      // setTop,
      isTopShadow,
      isBottomShadow,
      isScrollActive,
      setScrollActive,
    }) => (values) => {
      // начальная позиция
      if (values.top === 0) {
        if ((values.scrollHeight - 1) <= values.clientHeight) {
          // контент внутри скрола меньше или равен контейнеру
          if (isBottomShadow) setBottomShadow(false);
          if (isScrollActive) setScrollActive(false);
        } else {
          if (!isBottomShadow) setBottomShadow(true);
          if (!isScrollActive) setScrollActive(true);
        }
        if (isTopShadow) setTopShadow(false);
        return;
      }
      // setTop(values.top);
      // остальные позиции
      let topS = false;
      let bottomS = false;
      if ((values.scrollHeight - 1) > values.clientHeight) {
        topS = true;
        bottomS = true;
        if (values.top === 0) {
          topS = false;
        } else if (values.top > 0.98) {
          bottomS = false;
        }
      }
      if (isTopShadow !== topS) setTopShadow(topS);
      if (isBottomShadow !== bottomS) setBottomShadow(bottomS);
    },
  }),
);
