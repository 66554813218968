// @flow
import React, { type Node, Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import length from 'ramda/es/length';
import isEmpty from 'ramda/es/isEmpty';

import { Table } from 'app/common/ui-components/table';
import CSSSticky from 'app/common/ui-components/css-sticky';
import usePaging from 'app/common/hooks/usePaging';
import Paging from 'app/common/ui/Paging';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import useActualItems from './useActualItems';
import ItemsHeaderRow from './ItemsHeaderRow';
import ItemRow from './ItemRow';
import { getColumns } from './columns';
import type { Complex } from '../types';

import styles from './Items.scss';


const emptyArray = [];
const columnsSpecial = getColumns({ special: true });

type Props = {
  complexes?: Array<Complex>,
}

const ItemsSpecial = ({ complexes = [] }: Props): Node => {
  const currency = useCurrencySign(co.SPACE_BEFORE);
  const { currentPage, handlePage } = usePaging();

  const rows = useSelector(state => state.settings.reports.rows, shallowEqual);

  const total = length(complexes);
  // $FlowFixMe // после переноса в отдельный раздел это будет скорректировано, в special Complex меньше данных
  const itemsActual = useActualItems(complexes, emptyArray, rows * (currentPage - 1), rows * currentPage);

  if (isEmpty(complexes)) {
    return null;
  }

  return (
    <Fragment>
      <DetailsHeader reportTitle="Продажи" />

      <CSSSticky
        number={3}
        className={styles.stickableHeader}
        stickyClassName={styles.sticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <ItemsHeaderRow
              // reference={refTo}
              columns={columnsSpecial}
              // sortKey={sortKey}
              // sortDirection={sortDirection}
              // onSort={handleSort}
            />
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <thead>
          <ItemsHeaderRow
            // reference={refFrom}
            columns={columnsSpecial}
            hidden
            dummy
          />
        </thead>
        <tbody>
          {itemsActual.map((itemActual) => {
            const { id } = itemActual;

            return (
              <ItemRow
                key={id}
                columns={columnsSpecial}
                currency={currency}
                {...itemActual}
              />
            );
          })}
        </tbody>
      </Table>

      {/* ПОДВАЛ */}
      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        {/* Постарничная навигация */}
        <Paging
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={length(itemsActual)}
          onPage={handlePage}
        />

      </CSSSticky>
      {/* кон. подвала */}

    </Fragment>
  );
};

export default ItemsSpecial;
