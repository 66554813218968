
// @flow
import type { Request } from '../../index';


export type SaleType = {
  title: string,
}

export type SaleTypesResult = {
  [id: string]: SaleType,
}

export const GET_SALE_TYPES: Request<
  null,
  SaleTypesResult
> = [
  'food/items/sale_types',
  'get',
  ['data', 'saleTypes'],
];
