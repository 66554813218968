// @flow
import React, { useState, useCallback, type Node, Fragment, Children } from 'react';
import cn from 'classnames';
import replace from 'ramda/es/replace';

import PlainMenu from 'app/common/ui/PlainMenu';
import PlainMenuItem from 'app/common/ui/PlainMenu/PlainMenuItem';

import PlainButton from '../plain-button';
import styles from './plain-actions-button.scss';


export type PlainActionsButtonProps = {
  children: Node,
  collapsed?: boolean,
  disabled?: boolean,
  valueMask?: string,
  number: number,
  value: string,
  className?: string,
}

const PlainActionsButton = ({
  children,
  collapsed,
  disabled,
  valueMask,
  number,
  value,
  className,
}: PlainActionsButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(open => !open);
  }, [setIsOpen]);


  return (
    <Fragment>
      {!collapsed && children}

      {collapsed
        && (
          <div className={cn(styles.buttonAndMenu, className)}>
            <PlainButton onClick={handleToggle} disabled={disabled}>
              <span>
                {number && valueMask
                  ? replace(/%n%/, number, valueMask)
                  : value}
              </span>
              <span className={styles.arrow} />
            </PlainButton>

            {isOpen
              && (
                <PlainMenu className={styles.menu}>
                  {Children.map(children, (comp) => {
                    if (!comp) return null;

                    const {
                      props: {
                        id,
                        children: title,
                        onClick,
                        disabled: itemDisabled,
                      },
                    } = comp;

                    return (
                      <PlainMenuItem key={id} onPropagationCatch={handleToggle}>
                        <button type="button" onClick={onClick} disabled={itemDisabled}>
                          {title}
                        </button>
                      </PlainMenuItem>
                    );
                  })}
                </PlainMenu>
              )}
          </div>
        )}
    </Fragment>
  );
};

export default PlainActionsButton;
