import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import length from 'ramda/es/length';
import isEmpty from 'ramda/es/isEmpty';
import pipe from 'ramda/es/pipe';
import keys from 'ramda/es/keys';
import prop from 'ramda/es/prop';

import {
  SUMSKITS_FILTER_CLEAR,
  SUMSKITS_FILTER_DELETE,
  SUMSKITS_FILTER_SET,
  SUMSKITS_FILTER_UPDATE,
  sumsAndKitsFetch,
} from 'app/actions/reports/filters/sumsAndKits';

import { isSingle } from 'app/common/lib/object';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import action from 'app/common/lib/action';
import { sortNumbersAndText } from 'app/common/lib/sort';
import * as subsidyTypesAvailable from 'app/actions/reports/filters/subsidyTypesAvailable';

import { selectOrgId, selectSubsidyType } from './commonSelectors';


const getLen = pipe(keys, length);
export const selectIsAll = createSelector(
  [
    state => state.reports.filters.sumsAndKits.data,
    state => state.reports.filters.sumsAndKits.selected,
  ],
  (data, selected) => (isEmpty(selected) || (getLen(data) === getLen(selected))),
);

const selectTitleAndHeader = createSelector(
  [
    selectSubsidyType,
  ],
  subsidyType => (subsidyType === subsidyTypesAvailable.SUBSIDY_TYPE_COMMON
    ? ['Комплекты справок:', 'Выбор комплектов справок']
    : ['Сумма или комплект справки:', 'Выбор сумм или комплекта справки']
  ),
);

export default compose(
  connect(
    (state, props) => {
      const [title, header] = selectTitleAndHeader(state);

      return ({
        items: state.reports.filters.sumsAndKits.data,
        selectedItems: state.reports.filters.sumsAndKits.selected,
        loading: state.reports.filters.sumsAndKits.loading,
        orgId: selectOrgId(state, props),
        isSelectedAllState: selectIsAll(state, props),
        isEnabled: !!selectOrgId(state, props), // выбрана одна школа
        active: state.reports.filters.sumsAndKits.active,
        sortFunc: sortNumbersAndText('down', prop('title')),
        title,
        header,
      });
    },
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(action(SUMSKITS_FILTER_SET, {}));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset();
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems, reset }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        reset();
        return;
      }
      dispatch(action(SUMSKITS_FILTER_DELETE, [name]));
    },

    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(action(SUMSKITS_FILTER_SET, submitted));
    },
  }),

  lifecycle({
    componentDidMount() {
      const { isEnabled, dispatch } = this.props;
      if (isEnabled) {
        dispatch(action(SUMSKITS_FILTER_UPDATE, { active: isEnabled }));
      }
    },
    async componentDidUpdate(prevProps) {
      const { dispatch, orgId, reset, isEnabled } = this.props;
      if (orgId !== prevProps.orgId) {
        if (orgId) {
          await dispatch(sumsAndKitsFetch(orgId));
          reset();
        }
      }
      if (isEnabled !== prevProps.isEnabled) {
        dispatch(action(SUMSKITS_FILTER_UPDATE, { active: isEnabled }));
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(action(SUMSKITS_FILTER_CLEAR));
    },
  }),

  branch(
    ({ isEnabled }) => !isEnabled,
    renderNothing,
  ),
);
