// @flow
import api from 'app/actions/api';
import { handleDisconnect } from 'app/common/actions/settings/current';

export const DISHES_COPYALL_REQUEST = 'orgs/dishes/DISHES_COPYALL_REQUEST';
export const DISHES_COPYALL_SUCCESS = 'orgs/dishes/DISHES_COPYALL_SUCCESS';
export const DISHES_COPYALL_FAILURE = 'orgs/dishes/DISHES_COPYALL_FAILURE';

const dishesCopyallRequest = payload => ({
  type: DISHES_COPYALL_REQUEST,
  payload,
});

const dishesCopyallSuccess = payload => ({
  type: DISHES_COPYALL_SUCCESS,
  payload,
});

const dishesCopyallFailure = payload => ({
  type: DISHES_COPYALL_FAILURE,
  payload,
});

export const copyAllDishes = (orgIdFrom: number, orgIdTo: number) => async (dispatch: Function) => {
  dispatch(dishesCopyallRequest({ orgIdFrom, orgIdTo }));
  try {
    const dataToSend = {
      school_id_from: orgIdFrom,
      school_id_to: orgIdTo,
    };
    await api('food/items/copy_items_between_schools', 'post', { data: dataToSend });
    dispatch(dishesCopyallSuccess({ orgIdFrom, orgIdTo }));
    return true;
  } catch (error) {
    if (handleDisconnect(error, dispatch)) {
      dispatch(dishesCopyallFailure({ err: 'Не удалось скопировать весь набор блюд', error }));
    }
    return false;
  }
};
