// @flow
import React, { type Node } from 'react';

import styles from './Shift.scss';


type Props = {
  children: Node,
}

const Shift = ({ children }: Props) => {
  return (
    <div className={styles.shift}>
      {children}
    </div>
  );
};

export default Shift;
