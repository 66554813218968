// @flow
import { menuPresenceValues } from './filterGetItemsMenuPresence';


const menuPresence2Api = (value: $Values<typeof menuPresenceValues>) => {
  if (value === menuPresenceValues.MENU_PRESENCE_TRUE) {
    return true;
  }
  if (value === menuPresenceValues.MENU_PRESENCE_FALSE) {
    return false;
  }

  return null;
};

export default menuPresence2Api;
