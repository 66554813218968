// @flow
import React, { type Node, useState, Fragment, useCallback } from 'react';

import PlainModal, {
  PlainModalContent,
  PlainModalFooter,
} from 'app/common/ui-next/plain-modal';

import PlainEditableProp from 'app/common/ui-next/plain-editable-prop';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import { productionSaveType } from 'app/dataTypes/vending/production/productionSaveType';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';

import styles from './CellEditableProp.scss';


type Props = {
  fieldName: string,
  value: string,
  floatType?: boolean,
  orgTitle: string,
  onSubmit: (field: string, value: string) => Promise<boolean>,
}

const CellEditableProp = ({ fieldName, value, floatType = false, orgTitle, onSubmit }: Props): Node => {
  const [pending, setPending] = useState(false);
  const [valueToSubmit, setValueToSubmit] = useState(null);

  const handleSubmit = useCallback(async () => {
    setPending(true);
    const res = await onSubmit(fieldName, valueToSubmit || '');
    setPending(false);
    if (res) setValueToSubmit(null);
    return res;
  }, [onSubmit, fieldName, valueToSubmit]);

  const handleClose = useCallback(() => {
    setValueToSubmit(null);
  }, []);

  return (
    <Fragment>
      <PlainEditableProp
        value={value}
        onSubmit={setValueToSubmit}
        floatType={floatType}
        alignCenter
      >
        {value}
      </PlainEditableProp>

      {valueToSubmit
        && <PlainModal
          show
          className={styles.modal}
          formId="editFieldOptions"
          onSubmit={handleSubmit}
        >
          <PlainModalContent className={styles.content}>
            <PlainCheckbox
              className={styles.checkbox}
              label={`Сохранить в текущее место размещения «${orgTitle}»`}
              value={productionSaveType.Place}
              checked
              disabled
            />
            <PlainCheckbox
              className={styles.checkbox}
              label="Сохранить во всех доступных местах размещения, где используется эта продукция + в списке продукции поставщика"
              value={productionSaveType.All}
              checked={false}
              disabled
            />
          </PlainModalContent>

          <PlainModalFooter>
            <ControlsBar middle>
              <PlainButton type="submit">
                {pending ? 'Применение...' : 'Применить'}
              </PlainButton>
              <PlainButton
                outline
                onClick={handleClose}
              >
                {'Отмена'}
              </PlainButton>
            </ControlsBar>
          </PlainModalFooter>

        </PlainModal>}
    </Fragment>
  );
};

export default CellEditableProp;
