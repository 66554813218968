const theToken = () => {
  let observers = [];

  const bypass = (val, prev) => {
    if (observers.length) {
      observers.forEach((cb) => {
        if (typeof cb === 'function') cb(val, prev);
      });
    }
  };

  return ({
    setToken: (val, isExternal = false) => {
      const prev = localStorage.token;
      localStorage.token = val;
      if (isExternal) {
        localStorage.tokenExternal = String(isExternal);
        return;
      }
      localStorage.tokenExternal = '';
      bypass(val, prev);
    },
    setTokenExternal: (isExternal = true) => {
      if (isExternal) {
        localStorage.tokenExternal = String(isExternal);
        return;
      }
      localStorage.tokenExternal = '';
    },
    removeToken: () => {
      const prev = localStorage.token;
      localStorage.token = '';
      localStorage.tokenExternal = '';
      bypass('', prev);
    },
    getToken: () => localStorage.token || '',
    subscribeToken: (cb) => {
      observers.push(cb);
    },
    unsubscribeToken: (cb) => {
      observers = observers.filter(item => (item !== cb));
    },
    getTokenIsExternal: () => Boolean(localStorage.tokenExternal),
  });
};

const tokenObj = theToken();

const {
  setToken,
  removeToken,
  getToken,
  subscribeToken,
  unsubscribeToken,
  getTokenIsExternal,
  setTokenExternal,
} = tokenObj;

export {
  setToken,
  removeToken,
  getToken,
  subscribeToken,
  unsubscribeToken,
  getTokenIsExternal,
  setTokenExternal,
};
