import { Switch, Route, Redirect } from 'react-router'

import OrgsListPage from './OrgsListPage'
import OrgsPage from './OrgsListPage/OrgsPage'
import FinancesPage from './FinancesPage'
import SettingsPage from './SettingsPage'
import ProfilePage from './ProfilePage'
import AboutPage from './AboutPage'
import ReportsPage from './ReportsPage'
import VendingMachinePage from './VendingMachinePage'
import { CompensationPage } from './CompensationPage'


const Pages = () => {
  return (
    <Switch>
      <Route path="/orgslist" component={OrgsListPage} />
      <Route path="/vending" component={VendingMachinePage} />
      <Route path="/orgs" component={OrgsPage} />
      <Route path="/reports" component={ReportsPage} />
      <Route path="/finances" component={FinancesPage} />
      <Route path="/settings" component={SettingsPage} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/compensation" component={CompensationPage} />
      <Redirect to="/orgslist" />
    </Switch>
  )
}

export default Pages
