import { useCallback, useEffect, useState } from 'react'
import { assocPath, map } from 'ramda'

import { Schema } from 'app/dataTypes/subsidy/settings'

import { FormGridRowRadio, FormGridTemplate } from './FormGridTemplate'


export const controls = {
  hasSchema: 'hasSchema',
  common: 'common',
  individual: 'individual',
  both: 'both',
  forceRadio: 'forceRadio',
} as const

type Props = {
  data: Schema
  disabled?: boolean
  formID: string
}

const SchemaForm = ({ data: dataInit, disabled, formID }: Props) => {
  const [data, setData] = useState(dataInit)

  const handleChange = useCallback((name: string, checked: boolean, index: number) => {
    setData((prevState) => {
      const newState: Schema = (
        index === 1
          ? map<any, any>((pair) => {
            const [val1, val2] = pair
            if (val2) return [val1, false]
            return pair
          }, prevState)
          : prevState
      )
      return assocPath([name, index], checked, newState)
    })
  }, [])

  useEffect(() => {
    setData(dataInit)
  }, [dataInit])

  return (
    <>
      <input
        type="checkbox"
        name={controls.hasSchema}
        value="active"
        style={{ display: 'none' }}
        form={formID}
        readOnly
        checked
      />
      <FormGridTemplate
        title="Схема работы общей справки:"
      >
        <FormGridRowRadio
          name={controls.common}
          title="Использовать только «Общую» справку"
          data={data.common}
          disabled={disabled}
          formID={formID}
          onChange={handleChange}
        />
        <FormGridRowRadio
          name={controls.individual}
          title="Исключить «Общую» справку для учащихся с «Индивидуальными» справками"
          data={data.individual}
          disabled={disabled}
          formID={formID}
          onChange={handleChange}
        />
        <FormGridRowRadio
          name={controls.both}
          title="Использовать «Общую» справку параллельно «Индивидуальной» справке"
          data={data.both}
          disabled={disabled}
          formID={formID}
          onChange={handleChange}
        />
      </FormGridTemplate>
    </>
  )
}

export default SchemaForm
