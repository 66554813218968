// @flow
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import PlainButton from 'app/common/ui-next/plain-button';

import IconChildrenAdd from './icon/IconTimeOut';
import enhanceConnectionTimeOut from './enhanceConnectionTimeOut';
import styles from './ConnectionTimeOut.scss';


type Props = {
  textSplitted?: Array<any>,
  title?: string,
  phone?: string,
  isOpen: [boolean],
  handleRefresh: (e: SyntheticEvent<HTMLElement>) => void,
  // handleLogout: (e: SyntheticEvent<HTMLElement>) => void,
}

const ConnectionTimeOut = ({
  textSplitted,
  title,
  phone,
  isOpen,
  handleRefresh,
  // handleLogout,
}: Props) => {
  const { t } = useTranslation('failure');
  const [displayed, setDisplayed] = useState(() => isOpen[0]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [timeFromButton, setTimeFromButton] = useState(0);

  const handleHide = useCallback(() => {
    setTimeFromButton(Date.now());
    setDisplayed(false);
  }, []);

  useEffect(() => {
    const newStateOpen = isOpen[0];
    if (!displayed && newStateOpen) { // включение видимости из вне, если выключено внутри
      if (Math.abs(timeFromButton - Date.now()) < 30000) {
        // если с момента последнего показа прошло меньше 30 сек, то не показываем окно
        return;
      }
    }
    setDisplayed(newStateOpen);
    if (newStateOpen) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(() => {
        setDisplayed(false);
      }, 300000);
      if (newTimeoutId) {
        setTimeoutId(newTimeoutId);
      }
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!displayed) {
    return null;
  }

  return (
    <div className={styles.over}>
      <div className={styles.content}>
        <div className={styles.infobox}>
          {/* <a href="logout" className={styles.logout} onClick={handleLogout}>
            {t('logout')}
          </a> */}
          <PlainButtonAsLink
            id="close_button"
            ariaLabel={t('close')}
            onClick={handleHide}
            className={styles.logout}
            linkClassName={styles.logoutLink}
          >
            {t('close')}
          </PlainButtonAsLink>

          <IconChildrenAdd />
          <div className={styles.h1}>
            {title || t('server_not_response')}
          </div>
          <div className={styles.text}>
            {textSplitted
              ? <span>{textSplitted}</span>
              : <span>
                {t('please_try_again')}
                <br />
              </span>}
            {phone
              && <span>
                <Trans i18nKey="failure:contact_service_provider">
                  {'Если проблема повторяется,'}
                  <br />
                  {'свяжитесь с поставщиком услуги'}
                </Trans>
                {':'}
              </span>}
            {phone && <span className={styles.phone}>
              <br />
              {phone}
            </span>}
          </div>
          <PlainButton onClick={handleRefresh}>{t('refresh_bt')}</PlainButton>
        </div>
      </div>
    </div>
  );
};

export default enhanceConnectionTimeOut(ConnectionTimeOut);
