import { Switch, Route, Redirect } from 'react-router'

import { useGeneralSettings } from 'app/dataProviders/generalSettings/hooks'

import { getURL, SectionRoute, pathMask } from './router'
import { CompensationPageSection } from './CompensationPageSection'


const root = getURL()
const accruals = getURL({ section: SectionRoute.Accruals })

export const CompensationPage = () => {
  const { generalSettings, loading } = useGeneralSettings()

  if (!(generalSettings.useСompensations || loading)) {
    return (
      <Redirect to="/orgslist" />
    )
  }

  return (
    <Switch>
      <Route path={pathMask} component={CompensationPageSection} />
      <Redirect from={root} to={accruals} exact />
    </Switch>
  )
}
