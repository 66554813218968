import evolve from 'ramda/es/evolve';
import add from 'ramda/es/add';
import omit from 'ramda/es/omit';

import get from 'app/common/lib/get';
import {
  DISHES_REQUEST,
  DISHES_SUCCESS,
  DISHES_FAILURE,
  DISHES_CANCEL,
} from 'app/actions/dishes/items';

import {
  DISHES_REMOVE_ITEMS_SUCCESS,
  DISHES_COPY_ITEMS_SUCCESS,
} from 'app/actions/dishes/selection';

import { DISHES_FIELD_EDIT_SUCCESS } from 'app/actions/dishes/field';

import {
  DISHES_ADD_SUCCESS,
} from 'app/actions/dishes/add';


const defaultState = {
  loading: false,
  items: {},
  result: null,
  count: 0, // Всего записей в базе
};

const items = (state = defaultState, action) => {
  switch (action.type) {
    case DISHES_REQUEST: {
      return { ...state, loading: true };
    }

    case DISHES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case DISHES_FAILURE:
    case DISHES_CANCEL: {
      return {
        ...state,
        loading: false,
      };
    }

    case DISHES_REMOVE_ITEMS_SUCCESS: {
      return evolve({
        items: omit(action.payload),
        count: add(-action.payload.length),
      })(state);
    }

    case DISHES_ADD_SUCCESS:
    case DISHES_COPY_ITEMS_SUCCESS: {
      const payloadItems = get(action, ['payload', 'items'], {});
      return {
        ...state,
        items: { ...state.items, ...payloadItems },
        count: state.count + Object.keys(payloadItems).length,
      };
    }

    case DISHES_FIELD_EDIT_SUCCESS: {
      return {
        ...state,
        items: {
          ...state.items,
          ...action.payload,
        },
      };
    }

    default:
  }
  return state;
};

export default items;
