// @flow
import { useState, useEffect, useMemo, useCallback } from 'react';
import { isEmpty, findIndex, propEq, over, lensIndex } from 'ramda';

import { GET_VENDING_ITEMS, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/get';
import { api } from 'app/api';
import type { Params, Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Dish as CommonDish } from 'app/dataTypes/Dishes.types';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';


type Props = {
  placeId: string,
  category: Category | null,
  search: string,
  allDishesCategoryId: string,
  limit: number,
  offset: number,
}

export function useRightUpdatableDishesParams({
  placeId,
  category,
  search,
  allDishesCategoryId,
  limit,
  offset,
}: Props): Params | null {
  const params = useMemo(() => {
    const { id } = category || {};
    if (id) {
      return {
        placeId,
        categoryId: id !== allDishesCategoryId ? id : undefined,
        search,
        limit,
        offset,
        deleted: false,
      };
    }
    return null;
  }, [placeId, category, search, allDishesCategoryId, limit, offset]);

  return params;
}


export default function useDishes(params: Params | null, resetPagination: () => void): {
  dishes: Array<Dish>,
  total: number,
  loadingDishes: boolean,
  updateDishAsCommon: (dish: CommonDish) => void,
  updateDish: (dish: Dish) => void,
} {
  const [production, setProduction] = useState<Array<Dish>>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!params) return;

    const request = async () => {
      setLoading(true);
      const res = await api.request(GET_VENDING_ITEMS, {
        error: 'Не удалось получить продукцию из категории',
        params,
        convertions: {
          convertParams,
          convertResult,
        },
      });

      if (res && res.data) {
        if (params.offset && isEmpty(res.data)) {
          resetPagination();
          return;
        }
        setProduction(res.data);
        setTotal(res.total);
      }
      setLoading(false);
    };

    request();
  }, [params, resetPagination]);

  const updateDishAsCommon = useCallback((commonDish: CommonDish) => {
    const { id, photoUrl, cost, portion, saleType } = commonDish;
    setProduction((currentDishes) => {
      const index = findIndex(propEq('foodItemId', id), currentDishes);
      if (index > -1) {
        return over(lensIndex(index), dish => ({
          ...dish,
          photoUrl,
          cost,
          portion,
          saleType,
        }), currentDishes);
      }
      return currentDishes;
    });
  }, []);

  const updateDish = useCallback((dish: Dish) => {
    const { id } = dish;
    setProduction((currentDishes) => {
      const index = findIndex(propEq('id', id), currentDishes);
      if (index > -1) {
        return over(lensIndex(index), current => ({
          ...current,
          ...dish,
        }), currentDishes);
      }
      return currentDishes;
    });
  }, []);

  return {
    dishes: production,
    total,
    loadingDishes: loading,
    updateDishAsCommon,
    updateDish,
  };
}
