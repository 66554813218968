import { forwardRef, ReactNode } from 'react'
import cn from 'classnames'

import styles from './header.scss'


type Props = {
  children: ReactNode
  className?: string
}

const Header = forwardRef<HTMLHeadingElement, Props>(({ children, className }: Props, ref) => {
  return (
    <h1 className={cn(styles.header, className)} ref={ref}>
      {children}
    </h1>
  )
})

export default Header
