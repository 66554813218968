
// @flow
import type { Request } from '../index';


export type DayCaresParams = {
  school_id?: number,
  schoolyear_id?: number,
  ids?: Array<number | string>,
};

export type CampItem = {
  id: number,
  title: string,
};

export type CampsResult = {
  [id: string]: CampItem
};

export const GET_CAMPS: Request<
  DayCaresParams,
  CampsResult,
  void
> = [
  'reports/camps_list',
  'get',
  ['data', 'camps'],
];
