// @flow
import React, { Fragment, useRef, useCallback, useState } from 'react';
import classNames from 'classnames';

import RowLayout, { Header, HeaderContent, RowLayoutContent } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import Prompt from 'app/components/common/Prompt';
import SaveCancelButtons from 'app/components/common/SaveCancelButtons';
import { useWriteAccess } from 'app/rights/RightsForOrg';
import { useLastChange } from 'app/dataProviders/OrgSettingsHistoryProvider';
import * as orgsTypes from 'app/common/constants/schools/types';
import { ContentGroup } from 'app/common/templates-next/page-template';

import TimeRestrictionsForm from './TimeRestrictionsForm';
import RequestsData, { useRequestsData } from './RequestsData';
import GeneralSettingsForm from './GeneralSettingsForm';
import OldOrdersRestrictionsForm from './OldOrdersRestrictionsForm';
import MultipleSubmitSettings from '../MultipleSubmitSettings';
import { rightsPath } from '../rightsPath';
import HistoryLink from '../HistoryLink';
import sections, { sectionTitles } from '../sections';
import MultipleModalAdditionalContent from './MultipleModalAdditionalContent';
import AllowSubmitOrdersFromWeb from './AllowSubmitOrdersFromWeb/AllowSubmitOrdersFromWeb';
import IgnoreTime from './IgnoreTime/IgnoreTime';
import DisableDesktopOrders from './DisableDesktopOrders';
// Временно отключено
// import AllowEditCafeteriaOrderItemsWithSupplierConfirmation from './AllowEditCafeteriaOrderItemsWithSupplierConfirmation';
// import PersonalSalesWithKitsAreConsideredComplexNutrition from './PersonalSalesWithKitsAreConsideredComplexNutrition';

import styles from './Requests.scss';

/**
 *  Комплексное питание и личные продажи
 *  Главный компонент формы
 */

const currentSection = sections.ORGS_SETTINGS_REQUESTS;
const header = sectionTitles[currentSection];

type Props = {
  orgType: $Values<typeof orgsTypes>,
}

const Requests = ({ orgType }: Props) => {
  const [, updateLastChange] = useLastChange();

  const {
    content: {
      generalSettings: {
        loading: loadingGeneralSettings,
        modified: modifiedGeneralSettings,
        state: {
          prevent_change_settings: preventModify,
        },
      },
      oldOrdersRestrictions: {
        loading: loadingOldOrdersRestrictions,
        modified: modifiedOldOrdersRestrictions,
      },
      timeRestrictions: {
        loading: loadingTimeRestrictions,
        modified: modifiedTimeRestrictions,
        enabled: enabledTimeRestrictions,
      },
    },
    request: {
      requestAll,
      submitAll,
      checkErrors,
    },
  } = useRequestsData();

  const formRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSave = useCallback(() => {
    if (formRef.current) {
      formRef.current.submit.click();
    }
  }, []);

  const loading = (
    loadingGeneralSettings
    || loadingOldOrdersRestrictions
    || loadingTimeRestrictions
  );

  const modified = (
    modifiedGeneralSettings
    || modifiedOldOrdersRestrictions
    || modifiedTimeRestrictions
  );

  const writeAccess = useWriteAccess(rightsPath);

  return (
    <Fragment>
      <RowLayout paddingfree>
        <CSSSticky
          number={3}
          className={styles.stickable}
          shadowClassName={styles.shadow}
          stickyClassName={styles.sticky}
        >
          <HeaderContent
            className={classNames(styles.topBorderOnSticky, styles.headerContent)}
            borderBottom
          >
            <div>
              <Header>
                {header}
                {preventModify
                  && !loadingGeneralSettings
                  && (
                    <div className={styles.lockMessage}>
                      {'Оператор системы установил ограничения по редактированию настроек'}
                    </div>
                  )}
              </Header>
              <HistoryLink sectionTitle={header} />
            </div>
            {modified
              && !preventModify
              && (
                <SaveCancelButtons
                  onSave={handleSave}
                  onCancel={requestAll}
                  checksBeforeSave={checkErrors}
                  disabled={loading || submitting}
                  saving={submitting}
                  cancelling={modified && loading}
                />
              )}
          </HeaderContent>
        </CSSSticky>

        <RowLayoutContent>
          <MultipleSubmitSettings
            additionalContent={<MultipleModalAdditionalContent />}
            onSubmitArray={submitAll}
            onChangeSubmitState={setSubmitting}
            formId="requests_form"
            onSuccess={updateLastChange}
          >
            {(toggleMultipleSubmitModal, multipleSubmit) => (
              <form onSubmit={multipleSubmit} ref={formRef} id="requests_form">

                <ContentGroup header="Настройки для сотрудников ОУ">
                  <TimeRestrictionsForm
                    className={styles.margin}
                    disabled={!writeAccess || preventModify}
                    onlyOneRestrictGroup={orgType === orgsTypes.ENTERPRISE}
                  />
                  {!enabledTimeRestrictions
                    && <OldOrdersRestrictionsForm type="school" disabled={!writeAccess || preventModify} />}
                  {/* Исключение ограничений на создание и редактирование заявок. Укажите количество дней, в течение которых сотрудникам можно работать с заявками после завершения дня: */}
                  <IgnoreTime disabled={!writeAccess || preventModify} />
                  {/* Разрешить проводить заявки, в web-приложении «Личный кабинет сотрудника ОУ», вместо сотрудников ПП */}
                  <AllowSubmitOrdersFromWeb disabled={!writeAccess || preventModify} />
                </ContentGroup>

                <ContentGroup header="Настройки для сотрудников ПП">
                  <OldOrdersRestrictionsForm
                    className={styles.margin}
                    type="supplier"
                    disabled={!writeAccess || preventModify}
                  />
                  {/* Запретить создавать и редактировать заявки в настольном приложении «Аргус.Клиент» */}
                  <DisableDesktopOrders />
                  {/* Временно отключено */}
                  {/* <AllowEditCafeteriaOrderItemsWithSupplierConfirmation /> */}
                </ContentGroup>

                <ContentGroup header="Общие настройки">
                  <GeneralSettingsForm
                    className={styles.margin}
                    disabled={!writeAccess || preventModify}
                  />
                  {/* Временно отключено */}
                  {/* <PersonalSalesWithKitsAreConsideredComplexNutrition /> */}
                </ContentGroup>

                <button type="submit" name="submit" className={styles.hidden}>
                  {'Сохранить'}
                </button>
              </form>
            )}
          </MultipleSubmitSettings>
        </RowLayoutContent>
      </RowLayout>

      <Prompt
        when={modified}
        message="Вы не сохранили изменения, перейти в любом случае?"
      />
    </Fragment>
  );
};


type NProps = {
  orgId: number,
  orgType: $Values<typeof orgsTypes>,
}

const Nested = ({ orgId, orgType }: NProps) => {
  return (
    <RequestsData orgId={orgId}>
      <Requests orgType={orgType} />
    </RequestsData>
  );
};

export default Nested;
