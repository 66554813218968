// @flow
import toLower from 'ramda/es/toLower';
import cond from 'ramda/src/cond';
import equals from 'ramda/src/equals';
import always from 'ramda/src/always';
import T from 'ramda/src/T';


const SPACE_BEFORE: 'spaceBefore' = 'spaceBefore';
const COMMA_SPACE_BEFORE: 'commaSpaceBefore' = 'commaSpaceBefore';
export const options = { SPACE_BEFORE, COMMA_SPACE_BEFORE };

const getSignByCode = (code: string, op?: 'spaceBefore' | 'commaSpaceBefore') => {
  const codeLower = toLower(code);

  const currency = cond([
    [equals('kg'), always('сом')],
    [equals('kz'), always('₸')],
    [equals('ua'), always('₽')], // ₴
    [T, always('₽')],
  ])(codeLower);

  if (!op) return currency;

  return `${op === SPACE_BEFORE ? '\u00A0' : ''}${op === COMMA_SPACE_BEFORE ? ',\u00A0' : ''}${currency}`;
};

export default getSignByCode;
