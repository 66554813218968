// @flow
import React, { type Node } from 'react';

import Link from 'app/common/lib/link';
import classNames from 'classnames/bind';

import styles from './icon-link.scss';


const cx = classNames.bind(styles);

type Props = {
  children: Node,
  to: string,
  active: boolean,
  areaLabel: string
}

const IconLink = ({ children, to, active, areaLabel }: Props) => (
  <Link to={to} className={cx(styles.root, { active })} area-label={areaLabel}>
    {children}
  </Link>
);

export default IconLink;
