// @flow
import { useRef, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';

import { getDefaultStartFinish } from './useDateLimits';


const getNYStart = (year: number) => (`${year}-12-31`);
const getNYFinish = (year: number) => (`${year}-01-01`);

export default function useDateDefaults(limitStart?: string, limitFinish?: string): [
  [string, string],
  [string, string],
] {
  const defaultDates = useRef(getDefaultStartFinish());
  const [defaultStart, defaultFinish] = defaultDates.current;

  useEffect(() => {
    const newDefaultStart = (
      limitStart
      && limitStart !== defaultStart
      && !moment(defaultStart).isBetween(limitStart, limitFinish, 'days', '()')
        ? limitStart
        : defaultStart
    );
    const newDefaultFinish = (
      limitFinish
      && limitFinish !== defaultFinish
      && !moment(defaultFinish).isBetween(limitStart, limitFinish, 'days', '()')
        ? limitFinish
        : defaultFinish
    );
    defaultDates.current = [newDefaultStart, newDefaultFinish];
  }, [limitStart, limitFinish, defaultStart, defaultFinish]);

  const res = useMemo(() => {
    const now = new Date();
    const currentMonth = now.getMonth() + 1;

    const period1Finish = (currentMonth > 8
      ? getNYStart(now.getFullYear())
      : getNYStart(now.getFullYear() - 1));

    const period2Start = (currentMonth > 8
      ? getNYFinish(now.getFullYear() + 1)
      : getNYFinish(now.getFullYear()));

    return [
      [defaultStart, period1Finish],
      [period2Start, defaultFinish],
    ];
  }, [defaultStart, defaultFinish]);

  return res;
}
