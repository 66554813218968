// @flow
import React from 'react';

import EmptyStubSvg from './EmptyStub.svg';
import styles from './EmptyStub.scss';


type Props = {
  text?: string,
}

const EmptyStub = ({
  text = 'Нет продукции в комплекте',
}: Props) => {
  return (
    <div className={styles.root}>
      <span className={styles.text}>{text}</span>
      <EmptyStubSvg className={styles.image} />
    </div>
  );
};

export default EmptyStub;
