import { ChangeEvent, useCallback } from 'react'
import cn from 'classnames'

import LineInput from 'app/common/ui/LineInput'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { priceUnsignedRe } from 'app/common/constants/regex'
import PlainLabel from 'app/common/ui-next/plain-label'

import styles from './AverageCostVacancyDaysByMeal.scss'


const useNumberHandler = (callback?: (value: number | string) => void) => {
  const callbackRef = useCallbackRef(callback)

  const handler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value: val } = e.currentTarget
    if (val === '') callbackRef.current?.(val)
    if (priceUnsignedRe.test(val)) {
      const valueWithDot = val.replace(',', '.')
      callbackRef.current?.(valueWithDot)
    }
  }, [callbackRef])

  return handler
}

const useBoolHandler = (callback?: (value: boolean) => void) => {
  const callbackRef = useCallbackRef(callback)

  const handler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    callbackRef.current?.(checked)
  }, [callbackRef])

  return handler
}

type Props = {
  ID: string
  className?: string
  label: string
  labelAfternoon: string
  labelBreakfast: string
  labelDinner: string
  valueEnable: boolean | null
  valueAfternoon: number | string
  valueBreakfast: number | string
  valueDinner: number | string
  placeholder: string
  focused: string
  loading: boolean
  disabled?: boolean
  onChangeValueEnable?: (value: boolean) => void
  onChangeValueAfternoon?: (value: number | string) => void
  onChangeValueBreakfast?: (value: number | string) => void
  onChangeValueDinner?: (value: number | string) => void
}

export const AverageCostVacancyDaysByMeal = ({
  ID,
  className,
  label,
  labelAfternoon,
  labelBreakfast,
  labelDinner,
  valueEnable,
  valueAfternoon,
  valueBreakfast,
  valueDinner,
  placeholder,
  focused,
  loading,
  disabled,
  onChangeValueEnable,
  onChangeValueAfternoon,
  onChangeValueBreakfast,
  onChangeValueDinner,
}: Props) => {
  const changeValueEnable = useBoolHandler(onChangeValueEnable)
  const changeValueAfternoon = useNumberHandler(onChangeValueAfternoon)
  const changeValueBreakfast = useNumberHandler(onChangeValueBreakfast)
  const changeValueDinner = useNumberHandler(onChangeValueDinner)

  const afternoonID = `_${ID}_value_cost_afternoon`
  const breakfastID = `_${ID}_value_cost_breakfast`
  const dinnerID = `_${ID}_value_cost_dinner`

  return (
    <div className={cn(styles.root, className)}>
      <PlainCheckbox
        value={`_${ID}_enable_cost`}
        label={label}
        onChange={changeValueEnable}
        checked={!!valueEnable}
        disabled={loading || disabled}
      />

      <PlainLabel htmlFor={breakfastID} className={styles.firstLabel}>
        {labelBreakfast}
      </PlainLabel>

      <LineInput
        id={breakfastID}
        name={breakfastID}
        className={styles.input}
        placeholder={placeholder}
        focused={focused}
        value={valueBreakfast}
        onChange={changeValueBreakfast}
        autoComplete="off"
        disabled={!valueEnable || disabled}
        loading={loading}
      />

      <PlainLabel htmlFor={dinnerID}>
        {labelDinner}
      </PlainLabel>

      <LineInput
        id={dinnerID}
        name={dinnerID}
        className={styles.input}
        placeholder={placeholder}
        focused={focused}
        value={valueDinner}
        onChange={changeValueDinner}
        autoComplete="off"
        disabled={!valueEnable || disabled}
        loading={loading}
      />

      <PlainLabel htmlFor={afternoonID}>
        {labelAfternoon}
      </PlainLabel>

      <LineInput
        id={afternoonID}
        name={afternoonID}
        className={styles.input}
        placeholder={placeholder}
        focused={focused}
        value={valueAfternoon}
        onChange={changeValueAfternoon}
        autoComplete="off"
        disabled={!valueEnable || disabled}
        loading={loading}
      />
    </div>
  )
}
