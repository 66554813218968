// @flow
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';


/**
 *  Надстройка над состоянием поиска с задержкой пользовательского ввода
 *
 *  Пример:
 *  const [search, setSearch] = useState();
 *  const [searchString, handleSearch, handleClearSearch] = useDebouncedSearch(setSearch, () => setSearch(''));
 *  useDataFetch({ search });
 *
 *  <PlainInputSearch
 *    searchString={searchString}
 *    handleSearch={handleSearch}
 *    handleClearSearch={handleClearSearch}
 *  />
 */

export default function useDebouncedSearch(
  onSearch: (value: string) => void, // вызывается через 500ms после остановки ввода
  onClearSearch?: () => void, // вызовется сразу при вызове исходяшей handleClearSearch
): [
  string,
  (e: SyntheticEvent<HTMLInputElement>) => void,
  () => void
] {
  const [value, setValue] = useState('');

  const handleSearch = useCallback(() => {
    onSearch(value);
  }, [onSearch, value]);

  const handleClearSearch = useCallback((/* :: e: SyntheticEvent<HTMLButtonElement> */) => {
    setValue('');
    if (onClearSearch) {
      onClearSearch();
    }
  }, [onClearSearch]);

  const handleChangeValue = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: currentValue } = e.currentTarget;
    setValue(currentValue);
  }, []);

  useDebounce(handleSearch, 500, [value]);

  return [value, handleChangeValue, handleClearSearch];
}
