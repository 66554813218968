import {
  DISHES_LIB_REQUEST,
  DISHES_LIB_SUCCESS,
  DISHES_LIB_FAILURE,
  DISHES_LIB_CLEAR,
} from 'app/actions/dishes/library';


const defaultState = {
  loading: false,
  items: {},
  result: null,
  count: 0, // Всего записей в базе
};

const library = (state = defaultState, action) => {
  switch (action.type) {
    case DISHES_LIB_REQUEST: {
      return { ...state, loading: true };
    }

    case DISHES_LIB_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case DISHES_LIB_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case DISHES_LIB_CLEAR: {
      return { ...defaultState };
    }

    default:
  }
  return state;
};

export default library;
