
import { Include } from 'app/dataTypes/subsidy/settings'

import { controls } from './formParts/IncludeForm'


export default function extractInclude(formData: FormData): Include {
  return {
    saturdays: [
      !!formData.get(`${controls.saturdays}1`),
      !!formData.get(`${controls.saturdays}2`),
    ],
  }
}
