// @flow
import React from 'react';
import isEmpty from 'ramda/es/isEmpty';
import { Trans, useTranslation } from 'react-i18next';

import LangSwitcher from 'app/common/ui/LangSwitcher';
import * as orgsTypes from 'app/common/constants/schools/types';
import { appTypes } from 'app/common/api';
import { VersionView } from 'app/common/components-next/service-worker';

import Picture from 'app/common/ui-next/picture';
import appStorePNG from 'app/common/images/app-store.png';
import appStore2xPNG from 'app/common/images/app-store-2x.png';
import googlePlayPNG from 'app/common/images/google-play.png';
import googlePlay2xPNG from 'app/common/images/google-play-2x.png';

import Logo from './Logo';
import Copyright from './Copyright';
import LoginPass from './LoginPass';
import styles from './Main.scss';


type Props = {
  handleRestore: (e: SyntheticEvent<HTMLElement>) => void,
  logo: string,
  company: string,
  loginInfo: Object,
  setLoginInfo: (inf: Object) => void,
  availableRegTypes: Array<string>,
  handleRegister: (e: SyntheticEvent<HTMLElement>) => void,
  countryISOCode: string,
  exampleFormat: string,
  mobileAppsLinks: {
    androidAppUrl: string,
    iosAppUrl: string,
  },
  orgType: $Values<typeof orgsTypes>,
  emailForceAuth: boolean,
}

const Main = ({
  handleRestore,
  logo,
  company,
  loginInfo,
  setLoginInfo,
  availableRegTypes,
  handleRegister,
  countryISOCode,
  exampleFormat,
  mobileAppsLinks,
  orgType,
  emailForceAuth,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Logo src={logo} />
      <div className={styles.header}>
        <div className={styles.header34}>
          {(orgType === orgsTypes.HIGH_SCHOOL
            && t('app:app_name_high_school'))
            || t('app:app_name')}
        </div>
        <div className={styles.header24}>
          {(orgType === orgsTypes.HIGH_SCHOOL
            && t('app:app_name_high_school_line2'))
            || t('app:app_name_line2')}
        </div>
      </div>
      <LoginPass
        loginInfo={loginInfo}
        setLoginInfo={setLoginInfo}
        countryISOCode={countryISOCode}
        exampleFormat={exampleFormat}
        emailForceAuth={emailForceAuth}
      />
      <div className={styles.links}>
        {!isEmpty(availableRegTypes)
          && <div className={styles.link}>
            <a href="register" onClick={handleRegister}>
              <Trans i18nKey="login:register_link">
                {'Регистрация'}
              </Trans>
            </a>
          </div>}
        <div className={styles.link}>
          <a href="restore" onClick={handleRestore}>
            <Trans i18nKey="login:recover_pass_link">
              {'Забыли пароль?'}
            </Trans>
          </a>
        </div>
      </div>

      {(process.env.APP_NAME || process.env.REACT_APP_NAME) !== 'food'
        && <div className={styles.hrLang}>
          <LangSwitcher className={styles.langSwitcher} />
        </div>}

      {(mobileAppsLinks.iosAppUrl || mobileAppsLinks.androidAppUrl)
        && (process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_CABINET
        && <div className={styles.apps}>
          {mobileAppsLinks.iosAppUrl
            && <a
              target="_blank"
              rel="noopener noreferrer"
              href={mobileAppsLinks.iosAppUrl}
              className={styles.appsLink}
            >
              <Picture
                alt="Приложение IOS"
                width="130px"
                src={appStorePNG}
                srcX2={appStore2xPNG}
              />
            </a>}
          {mobileAppsLinks.androidAppUrl
            && <a
              target="_blank"
              rel="noopener noreferrer"
              href={mobileAppsLinks.androidAppUrl}
              className={styles.appsLink}
            >
              <Picture
                alt="Приложение Google Play"
                width="130px"
                src={googlePlayPNG}
                srcX2={googlePlay2xPNG}
              />
            </a>}
        </div>}

      <div className={styles.footer}>
        <Copyright company={company} />
        <VersionView />
      </div>
    </div>
  );
};

export default Main;
