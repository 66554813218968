// @flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './RowLayout.scss';


/**
 *  Layoout для выделения контента белым фоном и бордером со скруглением 2px
 *  Распознает следующие друг за другом RowLayout (убирает скругдение на месте стыка)
 *
 *  Устаревшее применение (так не нужно делать):
 *  <RowLayout><Header>Заголовок</Header></RowLayout>
 *  <RowLayout>Контент</RowLayout>
 *
 *  Правильное применение (семантически верное):
 *  <RowLayout paddingfree>
 *    <!-- deprecated <HeaderContent><Header>Заголовок</Header></HeaderContent> -->
 *    <RowLayoutContent><Header>Заголовок</Header></RowLayoutContent>
 *    <RowLayoutContent>Контент1</RowLayoutContent>
 *    <RowLayoutContent>Контент2</RowLayoutContent>
 *  </RowLayout>
 *
 *  т.е. не нужно делать отдельный RowLayout, если данные в одном смысловом блоке
 *  Если нужна разделительная линия, то sможно сделать второй RowLayoutContent
 */

type Props = {
  children: any,
  className?: string,
  header?: boolean,
  flex?: boolean,
  inside?: boolean,
  first?: boolean,
  firstOf?: boolean,
  last?: boolean,
  lastOf?: boolean,
  single?: boolean,
  paddingfree?: boolean,
  borderTop?: boolean,
  borderBottom?: boolean,
}

const cx = classNames.bind(styles);

const RowLayout = ({
  children,
  className,
  header,
  flex,
  inside,
  first,
  firstOf,
  last,
  lastOf,
  single,
  paddingfree,
  borderTop,
  borderBottom,
}: Props) => (
  <article
    className={cx(styles.root, {
      header,
      flex,
      inside,
      first,
      firstOf,
      last,
      lastOf,
      single,
      paddingfree,
      borderTop,
      borderBottom,
    }, className)}
  >
    {children}
  </article>
);

export default RowLayout;
