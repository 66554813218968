
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { ACCOUNT_ACTIVE, ACCOUNT_BLOCKED, employeeSettingsFetch } from 'app/actions/employees/employee/settings'
import type { ActiveType } from 'app/actions/employees/employee/settings'
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog'
import Tooltip from 'app/common/ui/Tooltip'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainToggle from 'app/common/ui-next/plain-toggle'
import { changeActivity } from 'app/actions/employees/employee/settings/activity'

import Label from '../Label'
import Big from '../Big'
import IconReload from './icons/IconReload'
import styles from './Activity.scss'


type Props = {
  personId: number
  status: ActiveType | null
  locked: boolean
  onBeginActivate: () => void
  onBeginReactivate: () => void
  onShowSessions: () => void
}

const Activity = ({
  personId,
  status,
  locked,
  onBeginActivate,
  onBeginReactivate,
  onShowSessions,
}: Props) => {
  const dispatch = useDispatch()

  const handleChangeActivity = useCallback(async (toggled: boolean) => {
    await dispatch(changeActivity(personId, toggled))
    await dispatch(employeeSettingsFetch(personId))
  }, [])

  return (
    <div>
      <Label general>{'Статус'}</Label>

      {!status
          && <div className={styles.rowControl}>
            <Big>{'Не активен'}</Big>
            <PlainButtonAsLink onClick={onBeginActivate} className={styles.btActivate}>
              {'Активировать'}
            </PlainButtonAsLink>
          </div>}

      {status
          && locked
          && <div className={styles.rowControl}>
            <Big
              middle
              disabled={status === ACCOUNT_BLOCKED}
              success={status === ACCOUNT_ACTIVE}
            >
              {status === ACCOUNT_ACTIVE ? 'Активен' : 'Заблокирован'}
            </Big>
          </div>}

      {status
          && !locked
          && <div className={styles.rowControl}>
            <Big middle disabled={status === ACCOUNT_BLOCKED} success={status === ACCOUNT_ACTIVE}>{'Активен'}</Big>
            <ConfirmationDialog
              onConfirm={handleChangeActivity}
              confirmParams={status !== ACCOUNT_BLOCKED}
              text={`Вы уверены, что хотите ${status === ACCOUNT_BLOCKED ? 'разблокировать' : 'заблокировать'} пользователя?`}
              confirmButtonText={status === ACCOUNT_BLOCKED ? 'Разблокировать' : 'Заблокировать'}
              confirmButtonProps={{ error: status === ACCOUNT_ACTIVE }}
            >
              {handleConfirm => (
                <Tooltip text={status === ACCOUNT_BLOCKED ? 'Разблокировка аккаунта' : 'Блокировка аккаунта'}>
                  <PlainToggle
                    className={styles.toggle}
                    checked={status === ACCOUNT_BLOCKED}
                    value="accoutBlocked"
                    onChange={handleConfirm}
                    prefix=""
                    invertColors
                  />
                </Tooltip>
              )}
            </ConfirmationDialog>

            <Big middle disabled={status === ACCOUNT_ACTIVE} error={status === ACCOUNT_BLOCKED}>{'Заблокирован'}</Big>
            {status === ACCOUNT_ACTIVE
              && <PlainButtonAsLink onClick={onBeginReactivate} className={styles.reactivate}>
                <Tooltip text="Обновить данные авторизации">
                  <IconReload className={styles.icon} />
                </Tooltip>
              </PlainButtonAsLink>}
          </div>}
      <div className={styles.sessions}>
        <PlainButtonAsLink onClick={onShowSessions}>
          {'Сессии авторизаций'}
        </PlainButtonAsLink>
      </div>
    </div>
  )
}

export default Activity
