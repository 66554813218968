// @flow
import { useState, useCallback, useEffect } from 'react'
import { isEmpty, path } from 'ramda'

import { api } from 'app/api'
import { GET_CAMPS, type CampsResult } from 'app/common/api/requests/reports/camps_list'


const defaultCamps = {}

export default function useCampTitleById(campIds: Array<string>) {
  const [loading, setLoading] = useState(true)
  const [camps, setCamps] = useState<CampsResult>(defaultCamps)

  const handleRequest = useCallback(async () => {
    if (isEmpty(campIds)) return
    setLoading(true)

    const res: CampsResult | null = await api.request(GET_CAMPS, {
      error: 'Не удалось получить данные лагерей',
      params: {
        ids: campIds,
      },
    })

    if (res) {
      setCamps(res)
    }
    setLoading(false)
  }, [campIds])

  useEffect(() => {
    handleRequest()
  }, [handleRequest])


  const handleGetCampTitle = useCallback((id: string) => {
    if (loading) {
      return 'загружается...'
    }
    return path([id, 'title'], camps) || '[Неизвестный лагерь]'
  }, [camps, loading])


  return handleGetCampTitle
}
