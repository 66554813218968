import { useState, useCallback, useEffect, useMemo } from 'react'
import { sortBy, filter, map, propEq, complement, identity, prop } from 'ramda'


const defaultState = [] as Array<string>

export type Item = {
  value: string,
  title: string,
  sortable: number | string,
}

export const convertArray = (
  makeSortable: (value: string) => number | string,
  makeTitle: (value: string) => string,
): ((values: Array<string>) => Array<Item>) => map(value => ({
  value,
  title: makeTitle(value),
  sortable: makeSortable(value),
}))

/**
 *  Состояние для хранения выбранных данных
 *  Содержит отдельное поле для сортировки на случай хранения дат
 */

type Options = {
  makeSortable?: (value: string) => number | string
  makeTitle?: (value: string) => string
  defaultSelection?: Array<string>
  selection?: Array<string>
  onChange?: (values: Array<string>) => void
}

export default function useSelectorState({
  makeSortable = identity,
  makeTitle = identity,
  defaultSelection = defaultState,
  selection,
  onChange,
}: Options = {}) {
  const [state, setState] = useState<Array<Item>>(
    () => convertArray(makeSortable, makeTitle)(defaultSelection),
  )

  // обновление из окна выбора
  const handleUpdate = useCallback((values: Array<string>) => {
    setState(convertArray(makeSortable, makeTitle)(values))
    if (onChange) {
      onChange(values)
    }
  }, [makeSortable, makeTitle, onChange])

  // при изменении defaultSelection делаем update
  useEffect(() => {
    setState(convertArray(makeSortable, makeTitle)(defaultSelection))
  }, [defaultSelection]) // eslint-disable-line react-hooks/exhaustive-deps

  // обновление при изменении внешнего состояния
  const handleUpdateByExternal = useCallback((values: Array<string>) => {
    setState(convertArray(makeSortable, makeTitle)(values))
  }, [makeSortable, makeTitle])

  // при нажатии крестика на badge
  const handleRemove = useCallback((id: string) => {
    const newState = filter(complement(propEq('value', id)), state)
    setState(newState)
    if (onChange) {
      onChange(map(prop('value'), newState))
    }
  }, [onChange, state])

  // при нажатии кнопки сброса
  const handleReset = useCallback(() => {
    setState(convertArray(makeSortable, makeTitle)(defaultSelection))
    if (onChange) {
      onChange(defaultSelection)
    }
  }, [makeSortable, makeTitle, defaultSelection, onChange])

  const valuesSelected: Array<string> = useMemo(() => (
    map(prop('value'), state)
  ), [state])

  const itemsSelected: Array<Item> = useMemo(() => (
    sortBy(prop('sortable'), state)
  ), [state])

  // обновление внутреннего состояния при изменении внешнего
  useEffect(() => {
    if (selection) {
      handleUpdateByExternal(selection)
    }
  }, [selection, makeTitle, makeSortable]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    valuesSelected,
    itemsSelected,
    update: handleUpdate,
    remove: handleRemove,
    reset: handleReset,
  }
}
