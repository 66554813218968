import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';

import { removePopup, clearPopups } from 'app/common/actions/popup';
import { logout } from 'app/common/actions/user';
import { clearErrors } from 'app/common/actions/errors';

import selectErrors from './selectErrors';


const enhanceFailureHandler = compose(
  connect(
    (state, props) => ({
      messages: state.popup,
      ...selectErrors(state, props),
    }),
  ),
  // isDisconnected
  // isMaintenance
  // isForbidden
  // isUnauthorized

  withHandlers({
    handleRemoveItem: ({ dispatch }) => (idx) => {
      dispatch(removePopup(idx));
    },
  }),

  lifecycle({
    componentDidMount() {
      const { dispatch } = this.props;
      dispatch(clearErrors());
      dispatch(clearPopups());
    },
    componentDidUpdate() {
      const { dispatch, isUnauthorized } = this.props;
      // if (isUnauthorized || isForbidden) { // не надо разлогиниваться на isForbidden, forbidden бывает при оплате
      if (isUnauthorized) {
        dispatch(logout());
      }
    },
  }),
);

export default enhanceFailureHandler;
