import toFixedValue from 'app/common/lib/toFixedValue'


type Props = {
  value: number
}

export const Price = ({ value }: Props) => {
  return (
    <div>{toFixedValue(value)}</div>
  )
}
