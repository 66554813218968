// @flow
import React, { type Node, useEffect, useRef, useCallback } from 'react';
import cn from 'classnames/bind';

import { toRGBA } from 'app/common/lib/color';

import styles from './fancy-row-header.scss';


const cx = cn.bind(styles);

type Props = {
  expanded: boolean,
  rowIndex: number,
  trElem: ?HTMLTableRowElement,
  color?: string,
  borderBottom?: boolean,
  requestTopShift?: (index: number) => number,
}

const FancyRowHeader = ({
  expanded,
  rowIndex,
  trElem,
  color = 'gray',
  borderBottom,
  requestTopShift,
}: Props): Node => {
  const headerDrawing = useRef(null);

  const calculateHeader = useCallback(() => {
    if (expanded && trElem) {
      const {
        top: trTop,
        bottom: trBottom,
      } = trElem.getBoundingClientRect();
      const height = trBottom - trTop;

      if (headerDrawing.current) {
        const headerDrawingElem = headerDrawing.current;
        if (requestTopShift) {
          const top = requestTopShift(rowIndex);
          headerDrawingElem.style.top = `${top}px`;
        }
        headerDrawingElem.style.height = `${height}px`;
      }
    }
  }, [expanded, rowIndex, requestTopShift, trElem]);

  // on expand
  useEffect(() => {
    calculateHeader();
  }, [expanded, trElem]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!expanded) {
    return null;
  }

  return (
    <td
      className={cx(styles.headerDrawing, { borderBottom })}
      style={{
        backgroundColor: `rgba(${toRGBA(color, 0.05).join(',')})`,
        borderColor: color,
      }}
      aria-hidden="true"
      ref={headerDrawing}
    />
  );
};

export default FancyRowHeader;
