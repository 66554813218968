// @flow
import React, { useState, type ComponentType, useCallback } from 'react';
import nest from 'recompose/nest';
import { useSelector, shallowEqual } from 'react-redux';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';

// import RowLayout, { HeaderContent, RowLayoutContent, Header } from 'app/common/layouts/RowLayout';
import ControlsBar from 'app/common/ui-next/controls-bar';
import SelectionData, { useSelectionData } from 'app/components/common/SelectionData';
import SaleTypesData from 'app/dataProviders/SaleTypesData';
import Sticky from 'app/common/templates-next/sticky';
import { PageTemplateContent, Header2 } from 'app/common/templates-next/page-template';
import PlainButtonFilterControl from 'app/common/ui-next/plain-button-filter-control';

import { useFilterHandler } from './useFilterHandler';
import Add, { DishesAddButton } from './Add';
import DishesData from './DishesData';
import DishesGear from '../SettingsGear/DishesGear';
import DishesSearch from './DishesSearch';
import ExportButton from './ExportButton';
import DishesActionsButton from './DishesActionsButton';
import DishesTable from './DishesTable';
import DishesFooter from './DishesFooter';
import { dataKey } from './dataKey';
import Filters from './filters/Filters';

import styles from './Dishes.scss';


type Props = {
  writeAccess: boolean,
  baseUrl: string,
  act?: string,
  // onRequestRouterAction: (act: string) => void,
  onRequestRouterHome: () => void,
}

const Dishes = ({
  writeAccess,
  baseUrl,
  act,
  // onRequestRouterAction,
  onRequestRouterHome,
}: Props) => {
  const { filterOpened, toggleFilter, isActiveFilter, setActiveFilter } = useFilterHandler();
  const { rows: limit } = useSelector(state => state.settings.dishes, shallowEqual);
  const [search, setSearch] = useState('');
  const { saleType } = useSelector(state => state.dishes.filter.fields, shallowEqual);

  const [, { init: selectionInit }] = useSelectionData();

  const handleDishesUpdate = useCallback((dishes) => {
    selectionInit(map(prop('id'), dishes));
  }, [selectionInit]);

  return (
    <DishesData
      limit={limit}
      saleType={saleType}
      search={search}
      onDishes={handleDishesUpdate}
    >
      <SaleTypesData>
        <>
          <Sticky>
            {ref => (
              <PageTemplateContent
                className={styles.hasSettingsDropdown}
                reference={ref}
                horizontalLayout
              >
                <Header2 skipMargin>{'Список продукции'}</Header2>
                <DishesGear disabledSetup={!writeAccess} />
              </PageTemplateContent>
            )}
          </Sticky>
          <Sticky>
            {ref => ( // toolbar
              <>
                <PageTemplateContent reference={ref}>
                  <ControlsBar spaceBetween>
                    <ControlsBar left>
                      <DishesAddButton baseUrl={baseUrl} />
                      <PlainButtonFilterControl
                        onClick={toggleFilter}
                        checked={isActiveFilter}
                        opened={filterOpened}
                      />
                      <DishesSearch onChangeSearch={setSearch} />
                    </ControlsBar>
                    <ControlsBar right>
                      <ExportButton>{'Экспорт'}</ExportButton>
                      {writeAccess && <DishesActionsButton />}
                    </ControlsBar>
                  </ControlsBar>
                </PageTemplateContent>
                <PageTemplateContent skip={!filterOpened} skipPadding animate>
                  <Filters
                    settingsDataKey={dataKey}
                    toggleFilter={toggleFilter}
                    setActiveFilter={setActiveFilter}
                  />
                </PageTemplateContent>
              </>
            )}
          </Sticky>

          <PageTemplateContent skipPadding className={styles.tableHead}>
            <DishesTable locked={!writeAccess} />
          </PageTemplateContent>

          <Sticky stickyBottom>
            {(ref, stuck, shadow) => (
              <PageTemplateContent
                reference={ref}
                shadowTop={shadow}
                skipPadding
              >
                <DishesFooter />
              </PageTemplateContent>
            )}
          </Sticky>

          {/* Добавление блюд */}
          {act === 'add'
            && <Add
              closePath={baseUrl}
              onClose={onRequestRouterHome}
            />}
        </>
      </SaleTypesData>
    </DishesData>
  );
};

const nested: ComponentType<Props> = nest(SelectionData, Dishes);
export default nested;
