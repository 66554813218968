import path from 'ramda/es/path';

import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const SELLPOINTS_REQUEST = 'orgs/settings/SELLPOINTS_REQUEST';
export const SELLPOINTS_SUCCESS = 'orgs/settings/SELLPOINTS_SUCCESS';
export const SELLPOINTS_FAILURE = 'orgs/settings/SELLPOINTS_FAILURE';
export const SELLPOINTS_CLEAR = 'orgs/settings/SELLPOINTS_CLEAR';

export const SELLPOINTS_CHANGE_SELECTION = 'orgs/settings/SELLPOINTS_CHANGE_SELECTION';

export const fetchSellPointsSettings = orgId => async (dispatch) => {
  dispatch(action(SELLPOINTS_REQUEST, { orgId }));
  try {
    const res = await api(`food/schools/${orgId}/sale_points`, 'get');
    const sellpoints = path(['data', 'sale_points'], res);
    dispatch(action(SELLPOINTS_SUCCESS, sellpoints));
  } catch (error) {
    dispatch(action(SELLPOINTS_FAILURE, {
      err: 'Не удалось загрузить точки продаж',
      error,
      orgId,
    }));
  }
};
