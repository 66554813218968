// @flow
import { type State } from 'app/common/ui-components/filter';

import { classActivity as classActivityDefault } from 'app/components/common/reports/filters/class';
import { flags as year } from 'app/components/common/reports/filters/year';

import { flags as group } from './group';
import { flags as period } from './period';


const classActivity = (state: State) => {
  if (
    group.filterActive(state)
    && group.byGroupComplexesSelected(state)
    && group.byCampComplexesSelected(state)
    && period.finalDateInPreviosMonthOrBefore(state)
  ) {
    return false;
  }

  return (
    classActivityDefault(state)
    && year.filterActive(state)
    && year.singleYearSelected(state)
  );
};

export default classActivity;
