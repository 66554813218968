// @flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './status.scss';


const cx = classNames.bind(styles);

type Props = {
  statusKey: number,
  className?: string,
}

const status = ({ statusKey, className }: Props) => {
  const cn = cx({
    waiting: statusKey === 0,
    completed: statusKey === 1,
    deleted: statusKey === 2 || statusKey === -1,
  }, className);

  return (
    <span className={cn}>
      {statusKey === 0 && 'В ожидании'}
      {statusKey === 1 && 'Заявка проведена'}
      {(statusKey === 2 || statusKey === -1) && 'Удалено'}
    </span>
  );
};

export default status;
