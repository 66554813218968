// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const SELLPOINTS_REQUEST = 'orgs/menu/SELLPOINTS_REQUEST';
export const SELLPOINTS_SUCCESS = 'orgs/menu/SELLPOINTS_SUCCESS';
export const SELLPOINTS_FAILURE = 'orgs/menu/SELLPOINTS_FAILURE';

/**
* Получение категорий блюд для школы
*/

export const sellPointsRequest = (payload: string) => ({
  type: SELLPOINTS_REQUEST,
  payload,
});

export const sellPointsSuccess = (payload: Object) => ({
  type: SELLPOINTS_SUCCESS,
  payload,
});

export const sellPointsFailure = (payload: Object) => ({
  type: SELLPOINTS_FAILURE,
  payload,
});

export const sellPointsFetch = (orgId: string) => async (dispatch: Function) => {
  dispatch(sellPointsRequest(orgId));
  try {
    const res = await api(`food/schools/${orgId}/sale_points`, 'get');
    dispatch(sellPointsSuccess(get(res, ['data', 'sale_points'], {})));
    return true;
  } catch (error) {
    dispatch(sellPointsFailure({ err: 'Запрос точек продаж не удался', error }));
    return false;
  }
};
