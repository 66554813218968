// @flow
import React, { useState, useCallback } from 'react';
import classNames from 'classnames/bind';
import not from 'ramda/es/not';

import Link from 'app/common/lib/link';
import type { DraggableStateSnapshot } from 'app/common/ui-components/plain-draggable-list';
import ReorderIcon from 'app/common/icons/reorder.svg';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';
import IconLightCross from 'app/common/icons/IconLightCross';
import Tooltip from 'app/common/ui/Tooltip';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import isProduction from 'app/common/lib/isProduction';

import type { CategoryListItem } from '../CategoryListItem.type';
import styles from './CategoryItem.scss';


const cx = classNames.bind(styles);

type Props = {
  ...CategoryListItem,
  loading?: boolean,
  active: boolean,
  first: boolean,
  draggableStateSnapshot?: DraggableStateSnapshot,
  isDragDisabled?: boolean,
  onDeleteCategory: (categoryId: string) => Promise<boolean>,
}

const CategoryItem = ({
  id,
  title,
  index,
  count,
  path,
  deletable,
  loading,
  active,
  first,
  draggableStateSnapshot,
  isDragDisabled,
  onDeleteCategory,
}: Props) => {
  const deleteConfirmText = `Вы уверены, что хотите удалить категорию «${title}»?`;
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const toggleDeleteConfirmation = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) e.preventDefault();
    setDeleteConfirmationVisible(not);
  }, []);

  const handleDeleteImage = useCallback(async () => {
    setDeleting(true);
    const res = await onDeleteCategory(id);
    if (res) {
      setDeleteConfirmationVisible(false);
    }
    setDeleting(false);
  }, [id, onDeleteCategory]);

  const { isDragging } = draggableStateSnapshot || {};

  const rootClassName = cx(styles.root, {
    active,
    first,
    dragging: isDragging,
    loading: loading || deleting,
  });

  return (
    <Link to={path} className={rootClassName}>
      {/* index для отладки */}
      {!isProduction()
        && <span style={{
          position: 'absolute',
          left: 5,
          color: 'lightgray',
          fontSize: '12px',
        }}
        >
          {index}
        </span>}

      <span>{title}</span>
      <span className={styles.filler} />
      <span className={styles.count}>{count}</span>

      {isDragDisabled
        ? <span className={styles.reorderPlaceholder} />
        : <ReorderIcon className={styles.reorder} />}

      <span className={styles.delete}>
        {deletable
          && <PlainButtonAsLink onClick={toggleDeleteConfirmation}>
            <Tooltip text="Удалить категорию">
              <IconLightCross className={styles.iconCross} />
            </Tooltip>
          </PlainButtonAsLink>}
      </span>

      {deleteConfirmationVisible
        && <PlainConfirmModal
          messageText={deleteConfirmText}
          cancelButtonTitle="Отмена"
          confirmButtonTitle={deleting ? 'Удаление…' : 'Удалить'}
          confirmButtonProps={{ disabled: deleting }}
          onCancel={toggleDeleteConfirmation}
          onConfirm={handleDeleteImage}
          critical
        />}
    </Link>
  );
};

export default CategoryItem;
