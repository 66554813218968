// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import OveflowedText from 'app/common/components/OveflowedText';

import styles from './ErrorString.scss';


const cx = classNames.bind(styles);

type Props = {
  children?: React.Node,
  className?: string,
  messageMode?: boolean,
  enableOverflow?: boolean,
}

const ErrorString = ({ children, className, messageMode = false }: Props) => (
  <OveflowedText className={cx(styles.root, { error: !messageMode }, className)} enableOverflow>
    {children || '\u00A0'}
  </OveflowedText>
);

export default ErrorString;
