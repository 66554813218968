// @flow
import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import config from 'app/config';
import { dataKey } from 'app/components/Settings/Dishes/dataKey';


export type Settings = {
  rows: number,
  check: boolean,
  id: boolean,
  nomenclature: boolean,
  title: boolean,
  photo: boolean,
  portion: boolean,
  cost: boolean,
  saleType: boolean,
  options: boolean,
}

export const dishesDefaultState: Settings = {
  rows: config.perPageItems,
  check: true,
  id: true,
  nomenclature: true,
  title: true,
  photo: true,
  portion: true,
  cost: true,
  saleType: true,
  options: true,
};

export default makeSettingsReducer(dataKey, dishesDefaultState);
