// @flow
import React from 'react';

import OrgsListGear from '../OrgsListGear';
import styles from './GearContainer.scss';


type Props = {
  hidden?: boolean,
}

const GearContainer = ({ hidden }: Props) => {
  if (hidden) {
    return (
      <div className={styles.dummy} />
    );
  }

  return (
    <OrgsListGear />
  );
};

export default GearContainer;
