// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as orderType } from '../orderType';


const subsidyActivity = (state: State) => {
  return (
    orderType.filterActive(state)
    && orderType.onlyPersonalSelected(state)
  );
};

export default subsidyActivity;
