// @flow
import lensPath from 'ramda/es/lensPath';
import view from 'ramda/es/view';

// DEPRECATED
// TODO не использовать нигде, есть ramda для этого
const get = (obj: Object, path: Array<string> | string, def: any): any => {
  if (!(path instanceof Array) && typeof path !== 'string') {
    return def;
  }
  const lens = lensPath(typeof path === 'string' ? path.split('.') : path);
  const result = view(lens, obj);
  return (result === undefined ? def : result);
};

export default get;
