// @flow
import React from 'react';
import compose from 'recompose/compose';

import Modal from 'app/common/components/Modal';
import Checkbox from 'app/common/ui/Checkbox';
import RadioButton, { RadioButtonGroup } from 'app/common/ui/RadioButton';
import { ButtonsBar } from 'app/common/ui/Button';
import {
  ONLY_CHOSEN_SCHOOL_FOR_CHOSEN_DATE,
  ONLY_CHOSEN_SCHOOL_FOR_FUTURE,
  ALL_SCHOOLS_FOR_CHOSEN_DATE,
  ALL_SCHOOLS_FOR_FUTURE,
  UPDATE_ALL_LISTS,
  ONE_SCHOOL,
  ALL_SCHOOLS,
} from 'app/constants/orgs/menu/fieldEdit';

import {
  withDishesDishesRights,
  withDishesOrgDishesRights,
} from 'app/containers/rights';

import { bothRights, withWriteAccess } from 'app/common/containers/withRights';

import styles from './ValueSaveOptionsDialog.scss';
import enhanceValueSaveOptionsDialog from './enhanceValueSaveOptionsDialog';


// Права
const withChangeDishesAccess = compose(
  bothRights(withDishesDishesRights, withDishesOrgDishesRights),
  withWriteAccess,
);

// Назначение прав
const OtherDishesCheck = withChangeDishesAccess(Checkbox);

type Props = {
  orgId: string | number,
  isOpen: boolean,
  onClose: (e: SyntheticEvent<HTMLElement>) => void,
  schoolName: string,
  dateString: string,
  handleSubmit: (e: SyntheticEvent<HTMLElement>) => void,
  handleChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  saving: boolean,
  allSchools: boolean,
  extended: string,
  updateAllLists: boolean,
};

const ValueSaveOptionsDialog = ({
  orgId,
  isOpen,
  onClose,
  schoolName,
  dateString,
  handleSubmit,
  handleChange,
  saving,
  allSchools,
  extended,
  updateAllLists,
}: Props) => (<Modal isOpen={isOpen} className={styles.root}>
  <form onSubmit={handleSubmit}>
    <Checkbox
      label={`Сохранить меню только в ${schoolName}`}
      disabled
      checked
      value={ONE_SCHOOL}
      onChange={handleChange}
    />
    <RadioButtonGroup
      valueSelected={!allSchools && extended}
      onChange={handleChange}
      name="one_school"
    >
      <RadioButton
        label={`Применить к одиночным блюдам на ${dateString}`}
        value={ONLY_CHOSEN_SCHOOL_FOR_CHOSEN_DATE}
        disabled={allSchools || saving}
      />
      <RadioButton
        label={`Применить к одиночным блюдам на ${dateString} и будущие дни*`}
        value={ONLY_CHOSEN_SCHOOL_FOR_FUTURE}
        disabled={allSchools || saving}
      />
    </RadioButtonGroup>

    <Checkbox
      label="Сохранить в меню во всех доступных вам школах, где используется это блюдо"
      value={ALL_SCHOOLS}
      onChange={handleChange}
      checked={allSchools}
    />
    <RadioButtonGroup
      valueSelected={allSchools && extended}
      onChange={handleChange}
      name="all_schools"
    >
      <RadioButton
        label={`Применить к одиночным блюдам на ${dateString}`}
        value={ALL_SCHOOLS_FOR_CHOSEN_DATE}
        disabled={!allSchools || saving}
      />
      <RadioButton
        label={`Применить к одиночным блюдам на ${dateString} и будущие дни*`}
        value={ALL_SCHOOLS_FOR_FUTURE}
        disabled={!allSchools || saving}
      />
    </RadioButtonGroup>
    <OtherDishesCheck
      orgId={orgId}
      label="Сохранить в разделе «Блюда» образовательной организации и в списке блюд поставщика"
      value={UPDATE_ALL_LISTS}
      onChange={handleChange}
      checked={updateAllLists}
    />
    <p>{`* — c ${dateString}`}</p>
    <ButtonsBar
      right={[{
        key: 'cancel',
        value: 'Отмена',
        outline: true,
        onClick: onClose,
      }, {
        key: 'submit',
        value: saving ? 'Применяется...' : 'Применить',
        submit: true,
        disabled: saving,
      }]}
    />
  </form>
</Modal>);

export default enhanceValueSaveOptionsDialog(ValueSaveOptionsDialog);
