// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import length from 'ramda/es/length';
import { useSelector, shallowEqual } from 'react-redux';

import toFixedValue from 'app/common/lib/toFixedValue';


type ClassesAndOrders = {
  klass_title: string,
  data: {
    common_order: {
      count: number,
      sum: number,
    },
    personal_order: Array<{
      allowance: string,
      count: number,
      sum: number,
    }>,
  }
}

export type ClassesAndOrdersView = {
  title: string,
  common: {
    count: number,
    sum: string,
  },
  personal: Array<{
    category: string,
    count: number,
    sum: string,
  }>,
  personalCount: number,
}

export default function useActualClassesAndOrders(): Array<ClassesAndOrdersView> {
  const classsesAndOrders = useSelector(
    state => state.reports.subsidies.data.klasses_and_orders,
    shallowEqual,
  );

  const classsesAndOrdersActual = useMemo(
    () => map(({ klass_title: title, data }: ClassesAndOrders) => {
      const personal = prop('personal_order', data) || [];
      const personalCount = length(personal);

      return ({
        title,
        common: {
          count: path(['common_order', 'count'], data),
          sum: toFixedValue(path(['common_order', 'sum'], data)),
        },
        personal: map(({ allowance, count, sum }) => ({
          category: allowance,
          count,
          sum: toFixedValue(sum),
        }), personal),
        personalCount,
      });
    }, classsesAndOrders),
    [classsesAndOrders],
  );


  return classsesAndOrdersActual;
}
