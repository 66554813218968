import { useCallback, useMemo, ReactNode } from 'react'
import moment from 'moment-timezone'
import cn from 'classnames/bind'

import styles from './plain-date-switcher-double.scss'


const defaultDateFormat = 'DD.MM.YYYY' as const
const cx = cn.bind(styles)
const simbolPrev = '❮'
const simbolNext = '❯'

export type PlainDateSwitcherDoubleProps = {
  className?: string
  dateFrom?: string
  dateTo?: string
  dateFormat?: string
  displayDateFormat?: string
  onDateChange?: (dateFrom: string, dateTo: string, format: string) => void
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean
  children?: ReactNode
}

export const PlainDateSwitcherDouble = ({
  className,
  dateFrom,
  dateTo,
  dateFormat = defaultDateFormat,
  displayDateFormat = defaultDateFormat,
  onDateChange,
  dateDisabledStatus,
  children,
}: PlainDateSwitcherDoubleProps) => {
  const stepType = 'month' as const
  const displayDate = useMemo(() => {
    if (displayDateFormat === dateFormat) {
      return `${dateFrom} – ${dateTo}`
    }
    return `${moment(dateFrom, dateFormat).format(displayDateFormat)} – ${moment(dateTo, dateFormat).format(displayDateFormat)}`
  }, [dateFrom, dateTo, dateFormat, displayDateFormat])

  const [prev, next] = useMemo((): [[string, string], [string, string]] => ([[
    moment(dateFrom, dateFormat).subtract(1, stepType).format(dateFormat),
    moment(dateTo, dateFormat).subtract(1, stepType).format(dateFormat),
  ], [
    moment(dateFrom, dateFormat).add(1, stepType).format(dateFormat),
    moment(dateTo, dateFormat).add(1, stepType).format(dateFormat),
  ]]), [dateFrom, dateTo, dateFormat, stepType])

  const handleClickPrev = useCallback(() => {
    if (onDateChange) {
      onDateChange(...prev, dateFormat)
    }
  }, [onDateChange, prev, dateFormat])

  const handleClickNext = useCallback(() => {
    if (onDateChange) {
      onDateChange(...next, dateFormat)
    }
  }, [onDateChange, next, dateFormat])

  const [prevEnabled, nextEnabled] = useMemo(() => {
    if (dateDisabledStatus) {
      return [
        !dateDisabledStatus(prev[0], dateFormat, stepType),
        !dateDisabledStatus(next[1], dateFormat, stepType),
      ]
    }
    return [true, true]
  }, [dateDisabledStatus, prev, next, dateFormat, stepType])

  return (
    <div className={cx(styles.root, className)}>
      {prevEnabled
        ? <button type="button" className={styles.button} onClick={handleClickPrev}>
          {simbolPrev}
        </button>
        : <span className={styles.fakeButton}>
          {simbolPrev}
        </span>}

      <div className={styles.date}>{displayDate}</div>

      {nextEnabled
        ? <button
          type="button"
          className={cx(styles.button, { hasChildren: children })}
          onClick={handleClickNext}
        >
          {simbolNext}
        </button>
        : <span className={cx(styles.fakeButton, { hasChildren: children })}>
          {simbolNext}
        </span>}

      {children}
    </div>
  )
}
