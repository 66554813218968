import PlainCheckbox, { Props as CheckboxProps } from 'app/common/ui-next/plain-checkbox'
import PlainRadioButton, { Props as RadioButtonProps } from 'app/common/ui-next/plain-radio-button'


type Props = {
  radio?: boolean,
} & Omit<CheckboxProps, 'hideLabel' | 'skipLabel'> & RadioButtonProps

const CheckboxOrRadio = ({ radio, ...props }: Props) => {
  if (radio) {
    return (
      <PlainRadioButton
        {...props}
      />
    )
  }

  return (
    <PlainCheckbox
      {...props}
    />
  )
}

export default CheckboxOrRadio
