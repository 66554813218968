// @flow
import { useState, useCallback, useMemo } from 'react';
import assoc from 'ramda/es/assoc';
import test from 'ramda/es/test';
import replace from 'ramda/es/replace';
import trim from 'ramda/es/trim';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import identity from 'ramda/es/identity';
import pick from 'ramda/es/pick';

import { type ImageParamsType } from 'app/common/components/ImagePrepare';
import { priceUnsignedRe } from 'app/common/constants/regex';
import { api } from 'app/api';
import { POST_FOOD_ITEMS } from 'app/common/api/requests/food/items';
import usePopup from 'app/common/hooks/usePopup';


type FormState = {
  nomenclature: string,
  title: string,
  photo: ?ImageParamsType,
  portion: string,
  cost: string,
  saleType: string,
  submitting: boolean,
  errorText: string,
}

const defaultFormState: FormState = {
  nomenclature: '',
  title: '',
  photo: null,
  portion: '',
  cost: '',
  saleType: '',
  submitting: false,
  errorText: '',
};

export type FormErrorState = {
  nomenclature: string,
  title: string,
  cost: string,
  saleType: string,
}

const defaultErrorState: FormErrorState = {
  nomenclature: '',
  title: '',
  cost: '',
  saleType: '',
};

type BindData = {
  nomenclature: {
    value: string,
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
    isError: boolean,
  },
  title: {
    value: string,
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
    isError: boolean,
  },
  photo: {
    photo: ?ImageParamsType,
    filenameLocal: string,
    onSubmit: (photo: ImageParamsType, filename: string) => boolean,
  },
  portion: {
    value: string,
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  },
  cost: {
    value: string,
    onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
    isError: boolean,
  },
  saleType: {
    value: string,
    onChange: (itemProps: { id: string }) => void,
    errored: boolean,
  },
  submitting: boolean,
  errors: FormErrorState,
}

type ApiHandlers = {
  submit: () => boolean | Promise<boolean>
}

type Options = {
  onChangeNomenclature: (s: string) => void,
  onChangeTitle: (s: string) => void,
  onChangePortion: (s: string) => void,
  onChangeCost: (s: string) => void,
  nomenclatureActive: boolean,
}

export default function useAddingForm({
  onChangeNomenclature,
  onChangeTitle,
  onChangePortion,
  onChangeCost,
  nomenclatureActive,
}: Options): [
    BindData,
    ApiHandlers,
  ] {
  const [fields, setFields] = useState(defaultFormState);
  const [filenameLocal, setFilenameLocal] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(defaultErrorState);
  const popup = usePopup();


  const handleChangeNomenclature = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFields(assoc('nomenclature', value));
    onChangeNomenclature(value);
    setErrors(assoc('nomenclature', defaultErrorState.nomenclature));
  }, [onChangeNomenclature]);

  const handleChangeTitle = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFields(assoc('title', value));
    onChangeTitle(value);
    setErrors(assoc('title', defaultErrorState.title));
  }, [onChangeTitle]);

  const handleChangePhoto = useCallback((photo: ImageParamsType, filename: string) => {
    setFilenameLocal(filename);
    setFields(assoc('photo', photo));
    return true;
  }, []);

  const handleChangePortion = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFields(assoc('portion', value));
    onChangePortion(value);
  }, [onChangePortion]);

  const handleChangeCost = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const replaced = replace(',', '.', value);
    if (test(priceUnsignedRe, replaced)) {
      setFields(assoc('cost', replaced));
      onChangeCost(replaced);
      setErrors(assoc('cost', defaultErrorState.cost));
    }
  }, [onChangeCost]);

  const handleChangeSaleType = useCallback((itemProps: { id: string }) => {
    const { id } = itemProps;
    setFields(assoc('saleType', id));
    setErrors(assoc('saleType', defaultErrorState.saleType));
  }, []);

  const handleCheckErrors = useCallback(() => {
    let success = true;
    if (nomenclatureActive && !trim(fields.nomenclature)) {
      setErrors(assoc('nomenclature', 'Необходимо указать номенклатуру'));
      success = false;
    }
    if (!trim(fields.title)) {
      setErrors(assoc('title', 'Необходимо указать наименование'));
      success = false;
    }
    if (!trim(fields.cost)) {
      setErrors(assoc('cost', 'Необходимо указать стоимость'));
      success = false;
    }
    if (!trim(fields.saleType)) {
      setErrors(assoc('saleType', 'Необходимо указать тип продукции'));
      success = false;
    }
    return success;
  }, [fields.title, fields.cost, fields.saleType, fields.nomenclature, nomenclatureActive]);

  const handleSubmit = useCallback(async () => {
    if (handleCheckErrors()) {
      setSubmitting(true);
      const res = await api.request(POST_FOOD_ITEMS, {
        error: 'Не удалось создать продукцию',
        params: pipe(
          pick(
            nomenclatureActive
              ? ['nomenclature', 'title', 'photo', 'portion', 'cost', 'saleType']
              : ['title', 'photo', 'portion', 'cost', 'saleType'],
          ),
          filter(identity),
        )(fields),
      });
      setSubmitting(false);

      if (res) {
        popup('Продукция успешно создана');
        setFields(defaultFormState);
        setFilenameLocal('');
        return true;
      }
    }
    return false;
  }, [handleCheckErrors, fields, popup, nomenclatureActive]);


  const nomenclature = useMemo(() => ({
    value: fields.nomenclature,
    onChange: handleChangeNomenclature,
    isError: Boolean(errors.nomenclature),
  }), [fields.nomenclature, errors.nomenclature, handleChangeNomenclature]);

  const title = useMemo(() => ({
    value: fields.title,
    onChange: handleChangeTitle,
    isError: Boolean(errors.title),
  }), [fields.title, errors.title, handleChangeTitle]);

  const photo = useMemo(() => ({
    photo: fields.photo,
    filenameLocal,
    onSubmit: handleChangePhoto,
  }), [fields.photo, filenameLocal, handleChangePhoto]);

  const portion = useMemo(() => ({
    value: fields.portion,
    onChange: handleChangePortion,
  }), [fields.portion, handleChangePortion]);

  const cost = useMemo(() => ({
    value: fields.cost,
    onChange: handleChangeCost,
    isError: Boolean(errors.cost),
  }), [fields.cost, errors.cost, handleChangeCost]);

  const saleType = useMemo(() => ({
    value: fields.saleType,
    onChange: handleChangeSaleType,
    errored: Boolean(errors.saleType),
  }), [fields.saleType, errors.saleType, handleChangeSaleType]);


  return [{
    nomenclature,
    title,
    photo,
    portion,
    cost,
    saleType,
    submitting,
    errors,
  }, {
    submit: handleSubmit,
  }];
}
