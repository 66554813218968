import { Org, OrgsData } from 'app/dataTypes/org'


export function sortOrgsByTitle(orgsData: OrgsData): OrgsData {
  const sortedArray = Array.from(orgsData.items.entries()).sort((a, b) => {
    return a[1].title.localeCompare(b[1].title)
  })

  return {
    ...orgsData,
    items: new Map<string, Org>(sortedArray),
  }
}