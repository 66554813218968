import { useCallback, memo, ReactNode, ChangeEvent } from 'react'
import moment from 'moment-timezone'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import PlainRadioButton, { LevelShift } from 'app/common/ui-next/plain-radio-button'
import { PlainDatePeriod } from 'app/common/ui-next/plain-date-period'

import CopySettingsContainer from '../common/CopySettingsContainer'
import type { SubsidyOrdersRestrictions } from './useSubsidyOrdersRestrictionsOpt'
import { dateFormat } from './useDateLimits'

import styles from './SORCommonOptPeriods.scss'


const units = ['day', 'month', 'year'] as const
type Unit = (typeof units)[number]
const isUnit = (val: any): val is Unit => {
  return units.includes(val)
}

type Props = {
  children: ReactNode,
  currentState: SubsidyOrdersRestrictions,
  disabled?: boolean,
  // periodsLimit: [string, string],
  hasTopMargin?: boolean,
  onChange: (key: 'common_enabled' | 'common_type' | 'personal' | 'period_1' | 'period_2', value: any) => void,
  onReset: () => void,
}

const SORCommonOptPeriods = memo<Props>(({
  children,
  currentState,
  disabled,
  // periodsLimit,
  hasTopMargin,
  onChange,
  onReset,
}: Props) => {
  const changeOption = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    onChange('common_enabled', checked)
  }, [onChange])

  const changeType = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (value === 'periods' || value === 'month') {
      onChange('common_type', value)
    }
  }, [onChange])

  const handleChangePeriodI = useCallback((value) => {
    onChange('period_1', value)
  }, [onChange])
  const handleChangePeriodII = useCallback((value) => {
    onChange('period_2', value)
  }, [onChange])

  // const [limitStart, limitFinish] = periodsLimit;
  const {
    period_1: period1State,
    period_2: period2State,
  } = currentState.common

  const handleDateDisabledI = useCallback((dayFormatted: string, format: string, mayBeUnit: string = 'day') => {
    const unit = isUnit(mayBeUnit) ? mayBeUnit : 'day'
    const mDate = moment(dayFormatted, format)
    if (mDate.isValid()) {
      const year = moment(period2State[0], dateFormat).get('year')
      mDate.set('year', year)
      return mDate.isBetween(
        period2State[0],
        period2State[1],
        unit,
        '[]',
      )
    }
    return true
  }, [period2State])

  const handleDateDisabledII = useCallback((dayFormatted: string, format: string, mayBeUnit: string = 'day') => {
    const unit = isUnit(mayBeUnit) ? mayBeUnit : 'day'
    const mDate = moment(dayFormatted, format)
    if (mDate.isValid()) {
      const year = moment(period1State[0], dateFormat).get('year')
      mDate.set('year', year)
      return mDate.isBetween(
        period1State[0],
        period1State[1],
        unit,
        '[]',
      )
    }
    return true
  }, [period1State])

  const enabledPeriods = currentState.common.enabled && currentState.common.type === 'periods'

  return (
    <CopySettingsContainer hasTopMargin={hasTopMargin}>
      <div>
        <PlainCheckbox
          value="common"
          label={
            <span>
              {'Ограничить '}
              <strong>
                {'общие справки'}
              </strong>
              {' периодами действий в течении учебного года'}
            </span>
          }
          disabled={disabled}
          checked={currentState.common.enabled}
          onChange={changeOption}
        />

        <LevelShift>

          <PlainRadioButton
            name="common"
            value="periods"
            checked={currentState.common.type === 'periods'}
            label="по полугодиям"
            className={styles.radio}
            disabled={disabled || !currentState.common.enabled}
            onChange={changeType}
          />

          <div className={styles.dates}>
            <PlainDatePeriod
              label="I полугодие"
              dateFrom={currentState.common.period_1[0]}
              dateTo={currentState.common.period_1[1]}
              dateFormat={dateFormat}
              disabled={!enabledPeriods}
              onChange={handleChangePeriodI}
              dateDisabledStatus={handleDateDisabledI}
              reset={onReset}
              hideYear
            />

            <PlainDatePeriod
              label="II полугодие"
              dateFrom={currentState.common.period_2[0]}
              dateTo={currentState.common.period_2[1]}
              dateFormat={dateFormat}
              disabled={!enabledPeriods}
              onChange={handleChangePeriodII}
              dateDisabledStatus={handleDateDisabledII}
              reset={onReset}
              hideYear
            />
          </div>

          <PlainRadioButton
            name="common"
            value="month"
            checked={currentState.common.type === 'month'}
            label="помесячно"
            className={styles.radio}
            disabled={disabled || !currentState.common.enabled}
            onChange={changeType}
          />

        </LevelShift>
      </div>

      {!disabled && children}
    </CopySettingsContainer>
  )
})

export default SORCommonOptPeriods
