export type CompensationInfoParams = {
  orgID: string
  orgTitle: string
  year: string
  month: string
  extended?: boolean
}

export enum CompensationInfoStatus {
  Pending = 202,
  Error = -1,
  Default = 0,
  InProgress = 1,
  Ready = 2
}

export type CompensationAllowances = Array<{
  allowanceID: string
  studentsCount: number
  totalSum: number
}>

type CompensationInfoCommon = {
  orgID: string
  orgTitle: string
}

export type CompensationInfoDefault = CompensationInfoCommon & {
  status: CompensationInfoStatus.Default
}

export type CompensationInfoPending = CompensationInfoCommon & {
  status: CompensationInfoStatus.Pending
}

export type CompensationInfoError = CompensationInfoCommon & {
  status: CompensationInfoStatus.Error
  handlerID?: string
}

export type CompensationInfoInProgress = CompensationInfoCommon & {
  status: CompensationInfoStatus.InProgress
  progress: number
  handlerID: string
}

export type CompensationInfoReady = CompensationInfoCommon & {
  status: CompensationInfoStatus.Ready
  studentsCount: number
  totalSum: number
  allowances: CompensationAllowances | null
}

export type CompensationInfo = (
  CompensationInfoPending
  | CompensationInfoError
  | CompensationInfoDefault
  | CompensationInfoInProgress
  | CompensationInfoReady
)

export type CompensationInfoByOrgID = Map<string, CompensationInfo>
