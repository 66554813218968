// @flow
import type { Rights } from 'app/common/constants/rights';

import type { Request } from '../../index';


export type { Rights };

export type GetRoleResult = {
  result: 'success',
  id: number,
  title: string,
  available_for_part_time_job: boolean,
  for_all_schools: boolean,
  rights: Rights,
  school_id: Array<number>,
} | ''

type UrlParams = {
  orgId: number | string,
}

export const GET_ROLE: Request<
  void,
  GetRoleResult,
  UrlParams
> = [
    'food/school/{orgId}/role',
    'get',
    ['data'],
  ];
