// @flow
import * as React from 'react';
import MaskedInput from 'react-maskedinput';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import test from 'ramda/es/test';

import { DotsLoader } from 'app/common/components/Loader';
import ErrorString from 'app/common/ui/ErrorString';

import DatePicker from '../DatePicker';
import styles from './PlainDateInput.scss';


export const defaultDateFormat: 'DD.MM.YYYY' = 'DD.MM.YYYY';

const cx = classNames.bind(styles);

export type Props = {
  id?: string,
  name?: string,
  label?: string,
  style?: Object,
  value?: string,
  mask?: string,
  placeholder?: string,
  format?: string, // format входящей и исходящей даты
  fieldFormat?: string, // format даты в поле input
  disabledDaysInPicker?: (Date) => boolean,
  onChangeDate?: (a: string, b?: string, c?: string) => void,
  onBlur?: (a: string, b?: string, c?: string) => void,
  className?: string,
  popupTop?: boolean,
  disabled?: boolean,
  emptyUnpossible?: boolean,
  isError?: boolean,
  loading?: boolean,
  errorText?: string,
  hideErrorString?: boolean,
  errorEnableOverflow?: boolean,
  labelClassName?: string,
};

type State = {
  error: string,
  update: boolean,
}

export default class PlainDateInput extends React.Component<Props, State> {
  state = {
    error: '',
    update: false,
  };

  static defaultProps = {
    label: '',
    value: '',
    mask: '11.11.1111',
    placeholder: '__.__.____',
    format: defaultDateFormat,
    fieldFormat: 'DD.MM.YYYY',
    disabled: false,
    emptyUnpossible: false,
    isError: false,
    errorEnableOverflow:false,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { update } = this.state;
    if (update && !prevState.update) {
      this.setState({ update: false }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  isDisabled = (mDate: typeof moment) => {
    const { disabledDaysInPicker } = this.props;
    if (typeof disabledDaysInPicker === 'function') {
      return disabledDaysInPicker(mDate);
    }
    return false;
  };

  // из календаря
  handleDayPick = (day: typeof Date) => {
    const { onChangeDate, format, name } = this.props;
    if (typeof onChangeDate === 'function') {
      onChangeDate(moment(day).format(format), 'valid', name);
    }
  };

  // из поля
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => { // Здесь не было проверки на валидность, может повлиять на календари в jba/Admin
    if (!(e.target instanceof window.HTMLInputElement)) {
      return;
    }
    const { onChangeDate, fieldFormat, format, name, emptyUnpossible } = this.props;
    const { value: date } = e.target;
    const mDate = moment(date, fieldFormat, true);
    const disabledDate = this.isDisabled(mDate);
    const isValid = (date === '' && !emptyUnpossible) || (mDate.isValid() && !disabledDate); // ? moment | Date
    if (!(isValid || test(/_/, date))) {
      this.setState({ error: disabledDate ? 'Недопустимая дата' : 'Неверная дата' });
    } else {
      this.setState({ error: '' });
    }
    if (typeof onChangeDate === 'function') {
      onChangeDate(
        date === '' && isValid ? '' : mDate.format(format),
        isValid ? 'valid' : 'invalid',
        name,
      );
      // onChangeDate(mDate.format(format), isValid ? 'valid' : undefined, name);
    }
  };

  // из поля, при потере фокуса
  handleBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!(e.target instanceof window.HTMLInputElement)) {
      return;
    }
    const { onBlur, fieldFormat, format, emptyUnpossible, name } = this.props;
    const { value: date } = e.target;
    const mDate = moment(date, fieldFormat, true);
    const isValid = (date === '' && !emptyUnpossible) || (mDate.isValid() && !this.isDisabled(mDate));
    if (!isValid) {
      this.setState({ update: true });
    }
    if (typeof onBlur === 'function') {
      onBlur(
        date === '' && isValid ? '' : mDate.format(format),
        isValid ? 'valid' : 'invalid',
        name,
      );
    }
  };

  render() {
    const {
      id,
      name,
      label,
      style,
      value,
      format,
      fieldFormat,
      mask,
      placeholder,
      disabledDaysInPicker,
      onChangeDate, // eslint-disable-line no-unused-vars
      onBlur, // eslint-disable-line no-unused-vars
      className,
      popupTop,
      disabled,
      emptyUnpossible,
      isError,
      loading,
      errorText,
      hideErrorString,
      labelClassName,
      errorEnableOverflow,
      ...other
    } = this.props;
    const mDate = moment(value, format);
    const isDateValid = mDate.isValid(); // TODO state
    const { error, update } = this.state;
    const errorHere = error || errorText;

    return (
      <div style={style} className={cx(styles.root, className)}>
        {label
          && <label htmlFor={id} className={cx(styles.label, labelClassName)}>
            {label}
          </label>}
        <div className={styles.container}>
          <MaskedInput
            mask={mask}
            placeholder={placeholder}
            className={cx({
              input: true,
              disabled: disabled || loading,
              error: errorHere || isError,
            })}
            name={name || id}
            id={id || name}
            value={update ? null : mDate.format(fieldFormat)}
            onChange={this.handleChange}
            disabled={disabled || loading}
            onBlur={this.handleBlur}
            // $FlowFixMe
            {...other}
          />
          {loading && <DotsLoader className={styles.loader} />}
          <DatePicker
            name={name || id}
            day={isDateValid ? mDate.toDate() : new Date()}
            className={styles.datePicker}
            onChangeDay={this.handleDayPick}
            disabledDays={disabledDaysInPicker}
            popupTop={popupTop}
            highlighted={!disabled}
            disabled={disabled || loading}
            showYearMonthSelector
          />
        </div>
        {!hideErrorString && <ErrorString enableOverflow={errorEnableOverflow}>{errorHere}</ErrorString>}
      </div>
    );
  }
}
