import { createSelector } from 'reselect';
import getNumEnding from 'app/common/lib/getNumEnding';

const selectNumbers = createSelector(
  [
    state => state.orgs.dishes.selection.items.length,
  ],
  len => (
    len ? ` (${len} ${getNumEnding(len, ['продукция', 'продукции', 'продукций'])})` : ''
  ),
);

export default selectNumbers;
