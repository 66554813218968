
import withHandlers from 'recompose/withHandlers';
import compose from 'recompose/compose';

import omitProps from 'app/common/containers/omitProps';


type Props = {
  onHover?: (idx: any) => void,
  idx?: any,
};

// хреновина для отслеживания индекса, на которой находится мышка
// индекс idx элемента передается через props и может быть любым
// не юзается, если нет idx и onHover в props
// натягивается на Row или Cell
export default (def: any = -1) => compose(
  withHandlers(({ idx, onHover }: Props) => (
    ((idx || idx === 0) && typeof onHover === 'function')
      ? {
        onMouseEnter: () => () => onHover(idx),
        onMouseLeave: () => () => onHover(def),
      } : {}
  )),
  omitProps(['idx', 'onHover']),
);
