import React, { forwardRef, ReactElement } from 'react'
import classNames from 'classnames/bind'

import IconRwb from './icons/IconRwb'

import styles from './side-navigation.scss'
import { useSideNavigationGlobalState } from './use-side-navigation-global-state'
import { MENU_WIDTH, MENU_WIDTH_COLLAPSED } from './constants'


const cx = classNames.bind(styles)

const wideStyles = { width: MENU_WIDTH }
const narrowStyles = { width: MENU_WIDTH_COLLAPSED }

type Props = {
  className?: string,
  children: ReactElement<any> | Array<ReactElement<any>>,
}

const SideNavigation = forwardRef<HTMLDivElement, Props>(({
  className,
  children,
}, ref) => {
  const { status, lock, toggleStatus } = useSideNavigationGlobalState()

  const widthStyle = status === 'wide' ? wideStyles : narrowStyles

  return (
    <div className={cx(styles.root, className)} style={widthStyle} ref={ref}>

      {!lock
        && (
          <button
            aria-label="Toggle side navigation"
            type="button"
            className={styles.collapse}
            onClick={toggleStatus}
          >
            <IconRwb className={cx({ rw: true, flip: status === 'narrow' })} />
          </button>
        )}

      {children instanceof Array
        ? children.map(child => (child
          ? React.cloneElement(child, { collapsed: status === 'narrow' })
          : null))
        : React.cloneElement(children, { collapsed: status === 'narrow' })}

    </div>
  )
})

export default SideNavigation
