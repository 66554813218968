import { map } from 'ramda'

import { AddOrgsParams } from 'app/dataTypes/org/orgGroup/orgsInside'

import { Request } from '../../../index'


type ParamsAPI = {
  school_ids: Array<number>
}

type UrlParams = {
  orgGroupID: number | string,
}

export const convertParams = ({ orgGroupID, orgIDs }: AddOrgsParams): [ParamsAPI, UrlParams] => {
  return ([{ school_ids: map(parseInt, orgIDs) }, { orgGroupID }])
}

export const convertResult = (result: string) => {
  return result === 'success'
}

export const POST_ORG_GROUP_ADD_ORGS: Request<AddOrgsParams, boolean, void> = [
  'food/school_groups/{orgGroupID}/add_schools',
  'post',
  ['data', 'result'],
]
