// @flow
import { useState, useCallback } from 'react';
import { reduce, isEmpty } from 'ramda';

import { getErrorText } from './errors';


type ErrorsState = {
  [key: string]: string,
}

const defaultState: ErrorsState = {};

export default function useErrorState(handlers: Array<() => ?string>): [
  ErrorsState,
  () => boolean,
  () => void,
] {
  const [errors, setErrors] = useState<ErrorsState>(defaultState);

  const checkErrors = useCallback(() => {
    const newErrors = reduce((acc, val) => {
      const errorCode = val();
      if (errorCode) {
        acc[errorCode] = getErrorText(errorCode);
      }
      return acc;
    }, {}, handlers);

    setErrors(newErrors);
    return isEmpty(newErrors);
  }, [handlers]);

  const resetErrors = useCallback(() => (
    setErrors(defaultState)
  ), []);

  return [errors, checkErrors, resetErrors];
}
