import { useCallback } from 'react'

import { POST_OLD_ORDERS_RESTRICTIONS } from 'app/common/api/requests/food/schools/old_orders_restrictions'
import { api } from 'app/api'


export type Data = Record<string, {
  ignore_time: boolean
  current_period_without_restrictions: boolean
  current_period_days: number | null
  role_id: number
  role_type: 'school'
}>

type Options = {
  data: Data
}

export default function useIgnoreTime({ data }: Options) {
  const submit = useCallback(async (ids: Array<number>) => {
    const res = await api.request(POST_OLD_ORDERS_RESTRICTIONS, {
      error: 'Не удалось сохранить данные',
      params: {
        schools_ids: ids.map(String),
        restrictions: data,
      },
    })

    if (res) {
      return true
    }
    return false
  }, [data])

  return submit
}
