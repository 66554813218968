// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import renderComponent from 'recompose/renderComponent';
import type { HOC } from 'recompose';
import pipe from 'ramda/es/pipe';
import propEq from 'ramda/es/propEq';
import find from 'ramda/es/find';
import prop from 'ramda/es/prop';

import get from 'app/common/lib/get';
import withPaging from 'app/common/containers/withPaging';
import Loader from 'app/common/components/Loader';

import {
  dishesFetch as dishesFetchAction,
  dishesFetchSearch as dishesFetchSearchAction,
  dishesStateClear as dishesStateClearAction,
} from 'app/actions/orgs/dishes';

import {
  dishesSearch as dishesSearchAction,
  dishesSetPage,
  dishesNeedUpdate as dishesNeedUpdateAction,
} from 'app/actions/orgs/dishes/options';

import { dishesClearSelection, dishesSelect } from 'app/actions/orgs/dishes/selection';
// import { categoriesFetch as categoriesFetchAction } from 'app/actions/orgs/dishes/categories';

import selectDishes from './selectDishes';
import selectSelectedAll from './selectSelectedAll';


type Props = {
  orgId: string,
  categoryId?: string,
  // pathname: string,
};
const findItemName = (id, items) => pipe(find(propEq('id', id)), prop('title'))(items);
const enhanceDishes: HOC<*, Props> = compose(
  connect(
    (state, props) => ({
      rows: state.settings.orgsDishes.rows,
      total: parseInt(state.orgs.dishes.options.count, 10),
      currentPage: state.orgs.dishes.options.page,
      searchString: state.orgs.dishes.options.searchString,
      filter: state.orgs.dishes.options.filter,
      loading: state.orgs.dishes.options.loading,
      items: selectDishes(state, props),
      settings: state.settings.orgsDishes,
      schoolName: get(state, ['orgs', 'current', 'schools', props.orgId, 'title'], '') || 'текущей школе',
      checked: state.orgs.dishes.selection.items,
      locked: state.orgs.dishes.selection.action ? state.orgs.dishes.selection.items : [],
      isNeedUpdate: state.orgs.dishes.options.needUpdate,
      selectedAll: selectSelectedAll(state, props),
    }),
    (dispatch, props) => ({
      dishesFetch: params => dispatch(dishesFetchAction(props.orgId, params)),
      setCurrentPage: page => dispatch(dishesSetPage(page)),
      dishesStateClear: () => dispatch(dishesStateClearAction()),
      dishesFetchSearch: params => dispatch(dishesFetchSearchAction(props.orgId, params)),
      searchClear: () => dispatch(dishesSearchAction('')),
      dishesNeedUpdate: payload => dispatch(dishesNeedUpdateAction(payload)),
      dispatch,
    }),
  ),

  withPaging('currentPage', 'setCurrentPage'),

  lifecycle({
    componentDidMount() {
      const { rows, currentPage, searchString, filter, categoryId } = this.props;
      if (!categoryId) {
        return;
      }
      this.props.dishesFetch({
        category_id: categoryId === 'all' ? undefined : categoryId,
        limit: rows,
        offset: rows * (currentPage - 1),
        ...(searchString ? { search: searchString } : {}),
        ...filter,
      });
    },
    componentDidUpdate(prevProps) {
      const {
        rows,
        total,
        currentPage,
        searchString,
        filter,
        setCurrentPage,
        categoryId,
        searchClear,
        updateCategories,
        isNeedUpdate,
        dishesNeedUpdate,
      } = this.props;

      // возврат из дочернего окна (редактирования списка), перезапросить все данные
      if (isNeedUpdate && !prevProps.isNeedUpdate) {
        dishesNeedUpdate(false);
        setCurrentPage(1);
        searchClear();
        this.props.dishesFetch({
          category_id: categoryId === 'all' ? undefined : categoryId,
          limit: rows,
          ...filter,
        });
        updateCategories();
        return;
      }

      // вылет номера страницы за общее количество страниц
      if (
        (total !== prevProps.total || rows !== prevProps.rows)
        && currentPage > Math.ceil(total / rows)
      ) {
        setCurrentPage(1);
        return;
      }

      // изменение параметров, требуется обновление данных
      if (
        rows !== prevProps.rows
        || currentPage !== prevProps.currentPage
        || filter !== prevProps.filter
        || categoryId !== prevProps.categoryId
      ) {
        this.props.dishesFetch({
          category_id: categoryId === 'all' ? undefined : categoryId,
          limit: rows,
          offset: rows * (currentPage - 1),
          ...(searchString ? { search: searchString } : {}),
          ...filter,
        });
      }
      // отдельно для поиска с лимитом в единицу времени
      if (searchString !== prevProps.searchString) {
        this.props.dishesFetchSearch({
          category_id: categoryId === 'all' ? undefined : categoryId,
          limit: rows,
          // offset: rows * (currentPage - 1),
          ...(searchString ? { search: searchString } : {}),
          ...filter,
        });
      }
    },
    componentWillUnmount() {
      this.props.dishesStateClear();
    },
  }),

  withState('isEditModModal', 'showEditModModal', false),
  withState('saveParams', 'setSaveParams', ['school']),
  withState('saveParamsCallback', 'setSaveParamsCallback', null),
  withState('dishSettings', 'setDishSettings', { id: null, title: '' }),

  withHandlers({
    cancelEditModModal: ({ showEditModModal, saveParamsCallback }) => (e) => {
      e.preventDefault();
      showEditModModal(false);
      if (saveParamsCallback) saveParamsCallback(true);
    },

    submitEditModModal: ({ showEditModModal, saveParamsCallback, saveParams }) => (e) => {
      e.preventDefault();
      showEditModModal(false);
      if (saveParamsCallback) saveParamsCallback(false, saveParams);
    },

    getSaveParams: ({ showEditModModal, setSaveParamsCallback }) => (cb) => {
      showEditModModal(true);
      setSaveParamsCallback(() => cb);
    },

    handleEditModCheck: ({ saveParams, setSaveParams }) => (e) => {
      const { name, checked } = e.currentTarget;
      if (checked) {
        setSaveParams([...saveParams, name]);
      } else {
        setSaveParams(saveParams.filter(item => item !== name));
      }
    },

    handleSelectAll: ({ dispatch, items }) => (e) => {
      const { checked } = e.currentTarget;
      if (checked) {
        dispatch(dishesSelect(items.map(({ id }) => id)));
      } else {
        dispatch(dishesClearSelection());
      }
    },

    handleDishSettings: ({ items, setDishSettings }) => (itemId) => {
      const title = findItemName(itemId, items);
      setDishSettings({ id: itemId, title });
    },

    handleDishSettingsClose: ({ setDishSettings }) => (e) => {
      if (e && e.preventDefault) e.preventDefault();
      setDishSettings({ id: null, title: '' });
    },
  }),

  branch(
    props => props.loading && !props.items.length,
    renderComponent(Loader),
  ),
);

export default enhanceDishes;
