// @flow
import * as React from 'react';
import equals from 'ramda/es/equals';
import prop from 'ramda/es/prop';
// import pickBy from 'ramda/es/pickBy';
import classNames from 'classnames/bind';

import MouseUpOutside from 'app/common/ui/MouseUpOutside';

import styles from './MovingRect.scss';


const cx = classNames.bind(styles);

const ID_RECT = 'Rect';
const ID_TOPLEFT = 'TopLeft';
const ID_TOPRIGHT = 'TopRight';
const ID_BOTTOMRIGHT = 'BottomRight';
const ID_BOTTOMLEFT = 'BottomLeft';

export type MeasureEntryType = {
  bottom?: number,
  height?: number,
  left?: number,
  right?: number,
  top?: number,
  width?: number,
  x?: number,
  y?: number,
}

export type MeasureBoundsType = {
  bottom: number,
  height: number,
  left: number,
  right: number,
  top: number,
  width: number,
}

export type CropType = {
  x1: number,
  y1: number,
  x2: number,
  y2: number,
}

type MousePosProps = {
  clientX: number,
  clientY: number,
}

type Props = {
  entry: MeasureEntryType,
  bounds: MeasureBoundsType,
  onCrop: (CropType) => void,
  proportion?: number,
  minSizeWidth: number,
  minSizeHeight: number,
  isHidden: boolean,
}

type State = {
  bottom: number,
  height: number,
  left: number,
  right: number,
  top: number,
  width: number,
  currentProportion?: number,
  restrictWidth: number,
  restrictHeight: number,
  drag: ?string,
}

class MovingRect extends React.Component<Props, State> {
  state = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    currentProportion: 0,
    restrictWidth: 0,
    restrictHeight: 0,
    drag: null,
  };

  static defaultProps = {
    minSizeWidth: 1,
    minSizeHeight: 1,
  };

  // $FlowFixMe
  UNSAFE_componentWillReceiveProps(nextProps: Props) { // TODO change to componentDidUpdate
    const { entry, proportion } = this.props;
    if (!equals(entry, nextProps.entry)) {
      const { x, y, ...others } = nextProps.entry;

      const { width = 1, height = 1, bottom = 0 } = others;
      if (!proportion || proportion === 1) {
        // свободная рамка без пропорций, на весь кадр
        this.setState({
          ...others,
          restrictWidth: Math.floor(width),
          restrictHeight: Math.floor(height),
        }, this.updateSize);
      } else {
        // рамка с заданными пропорциями сторон (width/height)
        const currentProportion = width / height;
        this.setState({ currentProportion });
        if (proportion < currentProportion) {
          const newWidth = Math.floor(height * proportion);
          this.setState({
            ...others,
            bottom: Math.floor(bottom),
            height: Math.floor(height),
            width: newWidth,
            restrictWidth: newWidth,
            restrictHeight: Math.floor(bottom),
            right: others.left + newWidth,
          }, this.updateSize);
        } else {
          const newHeight = Math.floor(width / proportion);
          this.setState({
            ...others,
            height: newHeight,
            restrictWidth: others.right,
            restrictHeight: newHeight,
            bottom: others.top + newHeight,
          }, this.updateSize);
        }
      }
    }
  }

  // setStateChangedValues = (rect: MeasureEntryType) => {
  //   const changed = pickBy((val, key) => (this.state[key] !== val), rect); // eslint-disable-line react/destructuring-assignment
  //   this.setState(changed);
  // }

  updateSize = () => {
    const { onCrop, entry } = this.props;
    const { left, top, right, bottom } = this.state;
    if (onCrop) {
      // $FlowFixMe
      onCrop({
        w: entry.width,
        x1: left,
        y1: top,
        x2: right,
        y2: bottom,
      });
    }
  };

  restrictUpdate = (rect: MeasureEntryType): Object => {
    const res: MeasureEntryType = { ...rect };
    const { minSizeHeight, minSizeWidth, entry } = this.props;
    const {
      top,
      left,
      right,
      bottom,
      restrictHeight,
      restrictWidth,
      width,
      height,
    } = this.state;
    let locked = false;
    if (rect.top !== undefined) {
      if (rect.top < 0) {
        locked = true;
        res.top = 0;
      } else if (rect.top < bottom - restrictHeight) {
        locked = true;
        res.top = bottom - restrictHeight;
      } else if (rect.top > bottom - minSizeHeight) {
        locked = true;
        res.top = bottom - minSizeHeight;
      }
    }
    if (rect.left !== undefined) {
      if (rect.left < 0) {
        locked = true;
        res.left = 0;
      } else if (rect.left < right - restrictWidth) {
        locked = true;
        res.left = right - restrictWidth;
      } else if (rect.left > right - minSizeWidth) {
        locked = true;
        res.left = right - minSizeWidth;
      }
    }
    if (rect.right !== undefined) {
      if (rect.right > (entry.right || 0)) {
        locked = true;
        res.right = entry.right;
      } else if (rect.right > left + restrictWidth) {
        locked = true;
        res.right = left + restrictWidth;
      } else if (rect.right < left + minSizeWidth) {
        locked = true;
        res.right = left + minSizeWidth;
      }
    }
    if (rect.bottom !== undefined) {
      if (rect.bottom > (entry.bottom || 0)) {
        locked = true;
        res.bottom = entry.bottom;
      } else if (rect.bottom > top + restrictHeight) {
        locked = true;
        res.bottom = top + restrictHeight;
      } else if (rect.bottom < top + minSizeHeight) {
        locked = true;
        res.bottom = top + minSizeHeight;
      }
    }
    if (locked) {
      res.width = width;
      res.height = height;
    } else {
      res.width = (res.right || right) - (res.left || left);
      res.height = (res.bottom || bottom) - (res.top || top);
    }
    return res;
  };

  dragRect = (e: MousePosProps) => {
    const { width, height } = this.state;
    const { entry, bounds } = this.props;
    if (!e.clientX && !e.clientY) return;
    const res = {};
    const top = Math.floor(e.clientY - bounds.top - (height / 2));
    // костыль
    if (top + height <= (entry.height || 0) && top >= 0) {
      res.top = top;
      res.bottom = top + height;
    }
    const left = Math.floor(e.clientX - bounds.left - (width / 2));
    if (left + width <= (entry.width || 0) && left >= 0) {
      res.left = left;
      res.right = left + width;
    }
    this.setState(res);
  };

  dragTopLeft = (e: MousePosProps) => {
    const { right, bottom } = this.state;
    const { bounds, proportion } = this.props;
    if (!e.clientX && !e.clientY) return;

    const top = Math.floor(e.clientY - bounds.top);
    const height = bottom - top;
    let left = Math.floor(e.clientX - bounds.left);
    let width = right - left;

    if (proportion) {
      width = Math.floor(height * proportion);
      left = right - width;
    }

    const res = { top, left, height, width };
    this.setState(this.restrictUpdate(res));
  };

  dragTopRight = (e: MousePosProps) => {
    const { left, bottom } = this.state;
    const { bounds, proportion } = this.props;
    if (!e.clientX && !e.clientY) return;

    const top = Math.floor(e.clientY - bounds.top);
    const height = bottom - top;
    let right = Math.floor(e.clientX - bounds.left);
    let width = right - left;

    if (proportion) {
      width = Math.floor(height * proportion);
      right = left + width;
    }

    const res = { top, right, height, width };
    this.setState(this.restrictUpdate(res));
  };

  dragBottomRight = (e: MousePosProps) => {
    const { left, top } = this.state;
    const { bounds, proportion } = this.props;
    if (!e.clientX && !e.clientY) return;

    const bottom = Math.floor(e.clientY - bounds.top);
    const height = bottom - top;
    let right = Math.floor(e.clientX - bounds.left);
    let width = right - left;

    if (proportion) {
      width = Math.floor(height * proportion);
      right = left + width;
    }

    const res = { bottom, right, height, width };
    this.setState(this.restrictUpdate(res));
  };

  dragBottomLeft = (e: MousePosProps) => {
    const { right, top } = this.state;
    const { bounds, proportion } = this.props;
    if (!e.clientX && !e.clientY) return;

    const bottom = Math.floor(e.clientY - bounds.top);
    const height = bottom - top;
    let left = Math.floor(e.clientX - bounds.left);
    let width = right - left;

    if (proportion) {
      width = Math.floor(height * proportion);
      left = right - width;
    }

    const res = { bottom, left, height, width };
    this.setState(this.restrictUpdate(res));
  };

  handleMouseMove = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const { drag } = this.state;
    if (!drag) return;
    const { clientX, clientY } = e;
    switch (drag) {
      case ID_RECT: {
        this.dragRect({ clientX, clientY });
        break;
      }
      case ID_TOPLEFT: {
        this.dragTopLeft({ clientX, clientY });
        break;
      }
      case ID_TOPRIGHT: {
        this.dragTopRight({ clientX, clientY });
        break;
      }
      case ID_BOTTOMRIGHT: {
        this.dragBottomRight({ clientX, clientY });
        break;
      }
      case ID_BOTTOMLEFT: {
        this.dragBottomLeft({ clientX, clientY });
        break;
      }
      default:
    }
  };

  handleMouseDown = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const id = prop('id', e.target);
    this.setState({ drag: id });
  };

  handleMouseUp = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ drag: null });
    this.updateSize();
  };

  handleMouseLeave = () => {
    this.updateSize();
  };

  render() {
    const {
      currentProportion,
      restrictWidth,
      restrictHeight,
      drag,
      ...toStyle
    } = this.state;
    const { isHidden, entry } = this.props;
    if (isNaN(toStyle.right) // eslint-disable-line no-restricted-globals
      || isNaN(toStyle.left) // eslint-disable-line no-restricted-globals
      || isNaN(toStyle.top) // eslint-disable-line no-restricted-globals
      || isNaN(toStyle.bottom) // eslint-disable-line no-restricted-globals
      || isNaN(toStyle.width) // eslint-disable-line no-restricted-globals
      || isNaN(toStyle.height)) { // eslint-disable-line no-restricted-globals
      return null;
    }
    return (
      <MouseUpOutside onMouseUpOutside={this.handleMouseUp}>
        <div
          className={cx({ root: true, hidden: isHidden })}
          onMouseMove={this.handleMouseMove}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseLeave={this.handleMouseLeave}
          style={{ ...entry }}
        >
          <div
            id={ID_RECT}
            className={styles.rect}
            style={{ ...toStyle }}
            // onDrag={this.handleDrag}
          >
            <div
              id={ID_TOPLEFT}
              className={styles.controlTopLeft}
            />
            <div
              id={ID_TOPRIGHT}
              className={styles.controlTopRight}
            />
            <div
              id={ID_BOTTOMRIGHT}
              className={styles.controlBottomRight}
            />
            <div
              id={ID_BOTTOMLEFT}
              className={styles.controlBottomLeft}
            />
          </div>
        </div>
      </MouseUpOutside>
    );
  }
}

export default MovingRect;
