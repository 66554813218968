// @flow
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import toPairs from 'ramda/es/toPairs';
import fromPairs from 'ramda/es/fromPairs';
import filter from 'ramda/es/filter';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';


import api from 'app/actions/api';
import action from 'app/common/lib/action';
import * as splittings from 'app/constants/settings/subsidySplitting';
import { camelCase } from 'app/common/lib/object';


export const SUBSIDIES_REQUEST = 'SUBSIDIES_REQUEST';
export const SUBSIDIES_SUCCESS = 'SUBSIDIES_SUCCESS';
export const SUBSIDIES_FAILURE = 'SUBSIDIES_FAILURE';
export const SUBSIDIES_CLEAR = 'SUBSIDIES_CLEAR';
export const SUBSIDIES_CHANGE_SPLITTING = 'SUBSIDIES_CHANGE_SPLITTING';
export const SUBSIDIES_CHANGE_VALUE = 'SUBSIDIES_CHANGE_VALUE';
export const SUBSIDIES_NEW_SEPARATE = 'SUBSIDIES_NEW_SEPARATE';
export const SUBSIDIES_DEL_SEPARATE = 'SUBSIDIES_DEL_SEPARATE';
export const SUBSIDIES_REQUIRED_PROBLEM = 'SUBSIDIES_REQUIRED_PROBLEM';

// конвертация camelCase и number поля в string
export const convert = pipe(
  toPairs,
  filter(([key, val]) => !(key === 'id' && val < 0)), // удаляем id, если он < 0
  map(([key, val]) => ([
    camelCase(key),
    (typeof val === 'number' ? val.toString() : val),
  ])),
  map(([key, val]) => ([key, key === 'allowance' && !val ? [] : val])),
  fromPairs,
);

export const subsidiesFetch = () => async (dispatch: Function) => {
  dispatch(action(SUBSIDIES_REQUEST));
  try {
    const res = await api('food/subsidy_split', 'get');
    const splitting = path(['data', 'subsidy_splitting'], res);
    const splits = path(['data', 'splits'], res);
    const sp = {
      splitting,
      splitsAll: splitting === splittings.ALL ? convert(prop(0, splits)) : null,
      splitsSeparate: splitting === splittings.SEPARATE ? map(convert, splits) : null,
    };
    dispatch(action(SUBSIDIES_SUCCESS, sp));
    return sp;
  } catch (error) {
    dispatch(action(
      SUBSIDIES_FAILURE,
      { err: 'Не удалось получить данные по дотациям', error },
    ));
    return null;
  }
};
