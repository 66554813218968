import { shallowEqual, useSelector } from 'react-redux'


export function useUser() {
  const res: {
    loading: boolean
    email: string | null
  } = useSelector((state: any) => state.user, shallowEqual)

  return res
}
