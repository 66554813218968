// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import pick from 'ramda/es/pick';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as vt from 'app/actions/reports/filters/viewTypes';
import * as availableViewTypes from 'app/actions/reports/filters/viewTypesAvailable';
import { defaultState } from 'app/reducers/reports/filters/viewTypes';
import { BY_CLASSES_AND_ORDERS } from 'app/actions/reports/filters/group';

import styles from './FilterItems.scss';


const selectIsEnable = createSelector(
  [
    state => state.reports.filters.group.current,
  ],
  current => (current !== BY_CLASSES_AND_ORDERS),
);

type ViewTypes = {
  [key: string]: {
    id: $Values<typeof availableViewTypes>,
    title: string,
  }
}

type Props = {
  resetStatus: boolean,
}

const ViewTypesFilter = ({ resetStatus }: Props) => {
  const dispatch = useDispatch();
  const itemsObj: ViewTypes = useSelector(state => state.reports.filters.viewTypes.data, shallowEqual);

  // выбранное
  const selectedItems: ViewTypes = useSelector(state => state.reports.filters.viewTypes.selected, shallowEqual);
  // и массив с выбранным
  const selectedArr: Array<$Values<typeof availableViewTypes>> = mapObjPropToArray('id')(selectedItems);


  const handleReset = useCallback(() => {
    dispatch(action(
      vt.VIEW_TYPES_FILTER_SET,
      pick([availableViewTypes.VIEW_TYPE_SUM], defaultState.data),
    ));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(vt.VIEW_TYPES_FILTER_SET, submitted));
  }, [dispatch]);


  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(vt.VIEW_TYPES_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   *  to ENABLE or not to ENABLE
   */

  const isEnable = useSelector(selectIsEnable);
  useEffect(() => {
    dispatch(action(vt.VIEW_TYPES_FILTER_UPDATE, { active: isEnable }));
  }, [isEnable]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isEnable) {
    return null;
  }

  return (
    <PlainFilterComplete
      id="viewTypes"
      className={styles.filter}
      title="Вид отображения:"
      modalTitle="Выбор вида отображeния"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      singleSelectable
      toggleModalOnCrossClick
    />
  );
};

export default ViewTypesFilter;
