// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { DotsLoader } from 'app/common/components/Loader';
import Tooltip from 'app/common/ui/Tooltip';
import ErrorString from 'app/common/ui/ErrorString';

import InputCharsCount from '../PlainInput/InputCharsCount';
import styles from './PlainTextArea.scss';


const cx = classNames.bind(styles);

type Props = {
  id?: string,
  name?: string,
  label?: string,
  value: string,
  onChange?: (e: SyntheticEvent<HTMLElement>) => void,
  onFocus?: (e: SyntheticEvent<HTMLElement>) => void,
  onBlur?: (e: SyntheticEvent<HTMLElement>) => void,
  isError?: boolean,
  isValid?: boolean,
  errorText?: string,
  noresize?: boolean, // delete
  style?: Object,
  labelStyle?: Object,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  loading?: boolean,
  hideErrorString?: boolean,
  maxChars?: number,
}

type State = {
  // scroll: boolean,
  height: number, // TODO апдэйтить только если изменилась высота
  offset: number,
  focus: boolean,
}

class PlainTextArea extends React.Component<Props, State> {
  state = {
    // scroll: false,
    height: 0, // TODO апдэйтить только если изменилась высота
    offset: 0,
    focus: false,
  };

  handleFocus = (e: SyntheticEvent<HTMLElement>) => {
    const { onFocus } = this.props;
    this.setState({ focus: true });
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  };

  handleBlur = (e: SyntheticEvent<HTMLElement>) => {
    const { onBlur } = this.props;
    this.setState({ focus: false });
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  handleChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    if (!this.textarea) return;
    const isScroll = this.textarea.scrollHeight - this.textarea.clientHeight;
    const offset = isScroll ? (this.textarea.offsetWidth - this.textarea.clientWidth) : 0;
    const { onChange } = this.props;
    this.setState({
      // scroll: isScroll, // not used
      height: this.textarea.scrollHeight,
      offset,
    });
    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  handleScroll = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    if (this.scroll) {
      this.scroll.scrollTop(e.currentTarget.scrollTop);
    }
  };

  handleScrollFrame = (params: { scrollTop: number }) => {
    if (this.textarea) {
      this.textarea.scrollTop = params.scrollTop;
    }
  };

  refTextarea = (t: React.ElementRef<'textarea'> | null) => {
    this.textarea = t;
  };

  refScrollbars = (sc: React.ElementRef<Scrollbars> | null) => {
    this.scroll = sc;
  };

  textarea: React.ElementRef<'textarea'> | null;

  scroll: React.ElementRef<Scrollbars> | null;

  render() {
    const {
      id,
      name,
      label,
      isError,
      isValid,
      errorText,
      value,
      style,
      labelStyle,
      placeholder,
      required,
      disabled,
      loading,
      hideErrorString,
      maxChars,
    } = this.props;
    const { focus, offset, height } = this.state;

    return (
      <div>
        {label
          && <label htmlFor={id} className={styles.label} style={labelStyle}>
            {label}
            {required && <span className={styles.star}>{' *'}</span>}
          </label>}

        <Tooltip text={disabled ? value : ''}>
          <div
            className={cx({
              container: true,
              focus,
              error: isError,
              success: isValid,
              disabled: disabled || loading,
            })}
          >
            <textarea
              ref={this.refTextarea}
              className={cx({ textarea: true, disabled })}
              name={name || id}
              id={id}
              value={value}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onScroll={this.handleScroll}
              placeholder={placeholder}
              style={{ ...style, width: offset ? `calc(100%  ${offset}px)` : '100%' }}
              disabled={disabled || loading}
            />
            <div className={styles.scrollContainer}>
              <Scrollbars
                className={styles.scrollBar}
                onScrollFrame={this.handleScrollFrame}
                ref={this.refScrollbars}
              >
                <div style={{ width: 10, height }} />
              </Scrollbars>
            </div>
            {maxChars && <InputCharsCount count={value.length} maxCount={maxChars} />}
            {loading && <DotsLoader className={styles.loader} />}
          </div>
        </Tooltip>
        {!hideErrorString
          && <ErrorString>{errorText}</ErrorString>}
      </div>
    );
  }
}

export default PlainTextArea;
