import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { api } from 'app/api'
import { ItemType } from 'app/common/ui-next/plain-selector'
import { StatementStatusMap } from 'app/dataTypes/compensation/statement/status'
import { GET_COMPENSATION_WITHDRAW_ORDERS_STATUSES, convertResult } from 'app/common/api/requests/food/compensation/withdraw_orders/statuses/get'


type StatementStatuses = Array<ItemType>

class StatementStatusesFilterDataBox extends DataBox<StatementStatuses> { }

export const defaultStatementStatuses: StatementStatuses = []
export const statementStatusesFilter = createDataBox('statementStatusesFilter', defaultStatementStatuses, StatementStatusesFilterDataBox)

const convertFromExtended = (statuses: StatementStatusMap): Array<ItemType> => {
  const itemsArray = Array.from(statuses.values())
  const res = itemsArray.map(({ ID, title }) => ({ id: ID, title }))
  return res
}

export const defaultStatementStatusesDataReceiver = async () => {
  const res = await api.request(GET_COMPENSATION_WITHDRAW_ORDERS_STATUSES, {
    error: 'Не удалось получить статусы заявок на вывод компенсации',
    convertions: {
      convertResult,
    },
  })
  return res ? convertFromExtended(res) : null
}

export const defaultStatementStatusesSelection: Array<string> = []
export const statementStatusesFilterSelection = createDataBoxDefault<Array<string>>('statementStatusesFilterSelection', defaultStatementStatusesSelection)
