// @flow
import React, { type Node } from 'react';

import styles from './DoubleEntries.scss';


type Props = {
  children: Node,
}

const DoubleEntries = ({ children }: Props) => {
  return (
    <div className={styles.root}>
      {children}
    </div>
  );
};

export default DoubleEntries;
