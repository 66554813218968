import dissocPath from 'ramda/es/dissocPath'
import dissoc from 'ramda/es/dissoc'
import set from 'ramda/es/set'
import lensPath from 'ramda/es/lensPath'
import lensProp from 'ramda/es/lensProp'

import { KITS_SUCCESS, KITS_STATE_CLEAR } from 'app/actions/orgs/kits'
import { NEW_KIT_SUCCESS } from 'app/actions/orgs/kits/new'
import { DISHDELETE_SUCCESS } from 'app/actions/orgs/kits/dishDelete'
import { KIT_CLEAR_SUCCESS } from 'app/actions/orgs/kits/kitClear'
import { KIT_GROCERY_PRICE_EDIT, KIT_PRICE_EDIT_SUCCESS } from 'app/actions/orgs/kits/kitPriceEdit'
import { KIT_DELETE_SUCCESS } from 'app/actions/orgs/kits/delete'
import { KIT_HIDE_SUCCESS } from 'app/actions/orgs/kits/hide'
import { FIELDEDIT_SUCCESS } from 'app/actions/orgs/kits/fieldEdit'
import { Lens } from 'ramda'

function lensPropId(id: string): Lens<State, any> {
  return lensProp(id)
}
type State = Record<string, any>
const defaultState: State = {}

// eslint-disable-next-line @typescript-eslint/default-param-last
const items = (state = defaultState, action) => {
  if (action.type === KITS_SUCCESS) {
    return { ...action.payload }
  }
  if (action.type === KITS_STATE_CLEAR) {
    return defaultState
  }
  if (action.type === NEW_KIT_SUCCESS) {
    return { ...state, ...action.payload }
  }
  if (action.type === DISHDELETE_SUCCESS) {
    return dissocPath([
      `${action.payload.kitId}`,
      'items',
      `${action.payload.dishId}`,
    ], state)
  }
  if (action.type === KIT_CLEAR_SUCCESS) {
    return set(lensPath([`${action.payload}`, 'items']), {}, state)
  }
  if (action.type === KIT_PRICE_EDIT_SUCCESS) {
    const { id } = action.payload

    return set(
      lensPropId(id),
      { ...state[action.payload.id], ...action.payload },
      state
    )
  }
  if (action.type === KIT_GROCERY_PRICE_EDIT) {
    const { id } = action.payload
    return set(
      lensPropId(id),
      { ...state[action.payload.id], ...action.payload },
      state
    )
  }
  if (action.type === KIT_DELETE_SUCCESS) {
    const kitId = action.payload.kitId as never
    return dissoc(kitId, state)
  }
  if (action.type === KIT_HIDE_SUCCESS) {
    const kitId = action.payload.kitId as never
    return dissoc(kitId, state)
  }
  if (action.type === FIELDEDIT_SUCCESS) {
    const { path } = action.payload
    return set(lensPath(path as string[]), action.payload.value, state)
  }
  return state
}

export default items
