// @flow
import { useState, useCallback } from 'react';


const useSearch = (): [string, (e: SyntheticEvent<HTMLInputElement>) => void, () => void] => {
  const [searchString, setSearchString] = useState('');

  const handleSearch = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchString(value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchString('');
  }, []);

  return [
    searchString,
    handleSearch,
    handleClearSearch,
  ];
};

export default useSearch;
