
import { ReportDetailsRoot, ReportSection } from 'app/common/templates-next/report-template'
import { CompensationTransactionsParams } from 'app/dataTypes/compensation/transations'
import { useTransactions } from 'app/dataProviders/compensation/transactions/hooks'

import { Summary } from '../summary'
import { TransactionsTable } from './transactionsTable'


type Props = {
  params: CompensationTransactionsParams
  currency: string
}

export const Result = ({
  params,
  currency,
}: Props) => {
  const { data, loading } = useTransactions({ params })
  const { totalBalance, transactions } = data

  return (
    <ReportSection
      skipPadding
    >
      <Summary
        sum={totalBalance}
        currency={currency}
        loading={loading}
      />
      <ReportDetailsRoot header="История операций">
        <TransactionsTable
          currency={currency}
          transactions={transactions}
          loading={loading}
        />
      </ReportDetailsRoot>
    </ReportSection>
  )
}
