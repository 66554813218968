import { createSelector } from 'reselect';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import toString from 'ramda/es/toString';
import slice from 'ramda/es/slice';

import toFixedValue from 'app/common/lib/toFixedValue';


export type SchoolType = {
  id: number,
  schoolTitle: string,
  count: string,
  pos: string,
  neg: string,
  negHighlight: boolean,
  negPercents: string,
  total: string,
}

const selectActualSchools = createSelector(
  [
    ({ schools }) => schools,
    ({ currentPage, rows }) => ((currentPage - 1) * rows),
    ({ currentPage, rows }) => (currentPage * rows),
  ],
  (schools, from, to) => pipe(
    slice(from, to),
    map(({ schoolId, schoolTitle, count, pos, neg, negPercents, total }) => ({
      id: schoolId,
      schoolTitle,
      count: toString(count),
      pos: toFixedValue(pos),
      neg: toFixedValue(neg),
      negHighlight: neg < 0,
      negPercents: toFixedValue(negPercents),
      total: toFixedValue(total),
    }: SchoolType)),
  )(schools),
);

export default selectActualSchools;
