// @flow
import { useMemo, useState, useCallback } from 'react';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import filter from 'ramda/es/filter';
import isEmpty from 'ramda/es/isEmpty';

import { useFilterData } from 'app/common/ui-components/filter';
import type { OrgsParams } from 'app/common/api/requests/food/schools';

import menuPresence2Api from './filter/menuPresence2Api';
import { orgTypes } from './useOrgsAndRoles';

/**
 *  Создание объекта с параметрами для запроса из текущего состояния фильтра
 *  и переданных параметров AdditionalParams
 */

const toInt = (val: string) => parseInt(val, 10);
const removeEmtyParams = filter((val) => {
  if (val === null || val === undefined || val === '') return false;
  if (typeof val === 'object') {
    if (!val[0]) { // Вариант "Без района"
      return false;
    }
    return !isEmpty(val);
  }
  return true;
});

type AdditionalParams = {
  currentOrgType: ?$Values<typeof orgTypes>,
  unknownOrgType: boolean,
  limit: number,
  offset: number,
  search: string,
  excludeDeleted: boolean,
}

export default function useFilterParams({
  limit,
  offset,
  search,
  excludeDeleted,
  currentOrgType,
  unknownOrgType,
}: AdditionalParams): [OrgsParams | null, () => void] {
  const [{ applied: filterApplied }] = useFilterData();

  const [rebuildFlag, setRebuildFlag] = useState([]);
  const rebuild = useCallback(() => setRebuildFlag([]), []);

  const params = useMemo(() => (
    currentOrgType && !unknownOrgType
      ? removeEmtyParams({
        limit,
        offset,
        type: currentOrgType,
        search,
        deleted: excludeDeleted ? false : undefined,
        city_id: filterApplied ? map(toInt, prop('city', filterApplied)) : null,
        district_id: filterApplied ? map(toInt, prop('district', filterApplied)) : null,
        has_today_menu: filterApplied ? menuPresence2Api(path(['menu_presence', 0], filterApplied)) : null,
      })
      : null
  ), [ // eslint-disable-line react-hooks/exhaustive-deps
    limit,
    offset,
    unknownOrgType,
    currentOrgType,
    search,
    filterApplied,
    rebuildFlag,
    excludeDeleted,
  ]);

  return [params, rebuild];
}
