// @flow
import type { Request } from './requests';

// DEPRECATED, use requests/
const requestsGeneral: {
  [key: string]: Request<Object, Object, Object>
} = {
  // Операции с файлами
  File_POST: ['attachments/files', 'post', ['data', 'files']],

  // ------- COMMON ? ------- //

  // Домашние задания
  Homework_GET_assignments: ['data/homework/assignments', 'get', ['data']],
  Homework_GET_solutions: ['data/homework/solutions', 'get', ['data']],
  Homework_GET_verifications: ['data/homework/verifications', 'get', ['data']],
  Homework_DELETE_attached_file: ['data/attached/files/{attachedFileId}', 'delete', ['data']], // success: ''
  Homework_POST_solution_file: ['data/homework/solutions/{solutionId}/attach', 'post', ['data', 'data']], // success: SolutionType
  Homework_POST_create_solution: ['data/homework/solutions', 'post', ['data', 'data']],

  // Активность пользователей
  Activity_POST: ['data/activities/{uuid}', 'post', ['data']],

  // Группы продленного дня (GPD, day_cares)
  GPD_GET_list: ['data/day_cares', 'get', ['data']], // params: GPDRequestParams, result: GPDRequestResult

  // Сотрудник(и)
  Employees_GET: ['data/employees', 'get', ['data']], // params: EmployeesRequestParams, result: EmployeesRequestResult

  // Должности (roles)
  Roles_GET: ['data/roles', 'get', ['data', 'data']], // params: RolesRequestParams, result: RolesRequestResult


  // ------- COMMON-CAFETERIA ------- //

  Cafe_GET_balance_reserves: ['cafeteria/customer/balance_reserves', 'get', ['data', 'data']], // params: BalanceReservesRequestParams, result: BalanceReservesRequestResult
  Cafe_GET_balance: ['cafeteria/customer/balance_refund_details', 'get', ['data', 'data']], // params: BalanceRequestParams, result: BalanceRequestResult
  Cafe_GET_balance_refunds: ['cafeteria/customer/balance_refunds', 'get', ['data', 'data']], // params: BalanceRefundsRequestParams, result: BalanceRefundsRequestResult
  Cafe_POST_balance_refund: ['cafeteria/customer/balance_refund', 'post', ['data', 'data']], // params: BalanceRefundPOSTRequestParams, result: BalanceRefundPOSTRequestResult

  // ------- CABINET ------- //

  // Запрос стоимости тарифов и связанных с ним пакетов с учетом скидки
  Tariff_GET_price_discounted: ['cabinet/subscriptions/price', 'get', ['data', 'price']], // params: PriceDiscountedRequestParams, result: PriceDiscountedRequestResult

  // Родительский контроль
  Statements_GET_posts: ['cabinet/statement/posts', 'get', ['data', 'posts']],
  Statements_GET_recipients: ['cabinet/statement/recipients', 'get', ['data', 'recipients']],
  Statements_POST_new: ['cabinet/statement/posts', 'post', ['data', 'posts']],

  // ------- JOURNAL ------- //

  // Статистика классов/групп продленного дня
  Journal_Classes_GET_stats: ['journal/klasses/students/stats', 'get', ['data', 'stats']],

  // Ученики
  Journal_Students_GET_brief: ['journal/students', 'get', ['data']],
  // params: StudentsBriefRequestParams, result: StudentsBriefResult

  Journal_Students_GET_gpdfree: ['journal/day_cares/students/without', 'get', ['data']],
  // params: StudentsGPDFreeRequestParams result: StudentsGPDFreeResult

  Journal_Students_POST_deletefromgpd: ['journal/day_cares/{gpdId}/students/remove', 'post', ['data', 'result']],
  // params: student_id: Array<number> result: 'success' | null

  Journal_Students_POST_addtogpd: ['journal/day_cares/{gpdId}/students/add', 'post', ['data', 'result']],
  // params: student_id: Array<number> result: 'success' | null

  // Учебные года
  Journal_Schoolyears_GET: ['journal/settings/schoolyears', 'get', ['data', 'schoolyears']], // params: empty, result: SchoolyearsRequestResult

  // Проходы
  Journal_Attendance_GET_passages: ['journal/apps/attendance/passages', 'get', ['data']], // params: PassagesRequestParams, result: PassagesRequestResult

  // Отчеты (в том числе по проходам)
  Report_GET_FILE: ['reports/download_file', 'get', []], // params: { report_id: number }
  Report_POST_perform: ['reports/perform', 'post', ['data', 'report_id']], // params: PerformRequestParams, result: number
  Report_GET_status: ['reports/status', 'get', ['data', 'status_in_percents']], // params: { report_id: number }, result: number
  Report_GET_history: ['reports/history', 'get', ['data', 'reports']], // params: HistoryRequestParams, result: HistoryRequestResult
  Report_GET_send2email: ['reports/send_file', 'get', ['data', 'result']], // params: Send2emailRequestParams, result: 'success'
};

export default requestsGeneral;
