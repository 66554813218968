// @flow
import type { MachinesModel } from 'app/dataTypes/vending/MachinesModel.types';

import type { Request } from '../../../../index';


export type Result = {
  data: Array<MachinesModel>,
  meta: {},
  result?: 'success',
}

export const GET_VENDING_MACHINES_MODELS: Request<void, Result, void> = [
  'data/vending/machines/models',
  'get',
  ['data'],
];
