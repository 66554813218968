// @flow
import { values, map, filter } from 'ramda';

import {
  defaultStateDataCurrent,
  getStateCurrentValue,
  type State,
  type StateDataItem,
} from 'app/common/ui-components/filter';

import { GET_EMPLOYEES_LIST } from 'app/common/api/requests/reports/employees_list';
import { api } from 'app/api';
import { getLastFirstM } from 'app/common/lib/names';

import orgFilter from '../org';
import customerTypeFilter from '../customerType';


const employeeItems = async (state: State) => {
  const orgIds = getStateCurrentValue(orgFilter, state);
  const orgId = parseInt(orgIds[0], 10);

  const [customerType] = getStateCurrentValue(customerTypeFilter, state);

  const res = await api.request(GET_EMPLOYEES_LIST, {
    error: 'Не удалось получить данные сотрудников',
    params: filter(Boolean, {
      school_id: orgId,
      customer_type: customerType,
    }),
  });

  if (res) {
    const items: Array<StateDataItem> = map(({ id, name }) => ({
      id: String(id),
      title: getLastFirstM(name),
    }), values(res));

    return ([items, defaultStateDataCurrent]);
  }
  return [[], defaultStateDataCurrent];
};

export default employeeItems;
