// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import renderNothing from 'recompose/renderNothing';

import Button from '../Button';
import styles from './ButtonsBar.scss';


const cx = classNames.bind(styles);
const showButtonsByProps = arr => (arr instanceof Array
  ? arr.map(props => <Button {...props} />)
  : null);

type Props = {
  left?: Array<Object> | boolean,
  right?: Array<Object> | boolean,
  middle?: Array<Object> | boolean,
  className?: string,
  nowrap?: boolean,
  children?: Node,
};

// устаревшее (Array<Object>), лучше не использовать
const ButtonsFromProps = ({ left, right, middle, className, nowrap }: Props) => (<div className={cx(styles.root, className)}>
  <div className={cx(styles.buttons, { left, right, middle, nowrap })}>
    {showButtonsByProps(left || middle || right)}
  </div>
</div>);

const ButtonsFromChildren = ({ left, right, middle, children, className, nowrap }: Props) => (<div className={cx(styles.root, className)}>
  <div className={cx(styles.buttons, { left, right, middle, nowrap })}>
    {children}
  </div>
</div>);

export default compose(
  branch(
    ({ left, right, middle, children }) => !(left || right || middle || children),
    renderNothing,
  ),
  branch(({ children }) => children, renderComponent(ButtonsFromChildren)),
)(ButtonsFromProps);
