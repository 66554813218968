import SimpleDate from 'app/common/classes/simple-date'
import toFixedValue from 'app/common/lib/toFixedValue'
import { DataItem, ReportCorrectionsData } from 'app/dataTypes/reports/data'
import { map } from 'ramda'


const dateFormat = 'YYYY-MM-DD' as const

type ApiDataItemPurchases = {
  date: string
  purchases_sum: number
  corrections_sum: number
}

type ApiDataItemSubsidies = {
  date: string
  subsidies_sum: number
  corrections_sum: number
}

type ApiDataTotalItem = {
  date: string
  total: number
}

type ApiSchoolItem<T> = {
  school_title: string
  school_id: number
  data: Array<T>
}

export type ApiResultCorrections = {
  from: string
  to: string
  operation_type: 'purchases'
  schools: Array<ApiSchoolItem<ApiDataItemPurchases>>
  totals: Array<ApiDataTotalItem>
} | {
  from: string
  to: string
  operation_type: 'subsidies'
  schools: Array<ApiSchoolItem<ApiDataItemSubsidies>>
  totals: Array<ApiDataTotalItem>
}

const convertFromPurchase = ({
  date,
  purchases_sum: value,
  corrections_sum: correctionsValue,
}: ApiDataItemPurchases) => {
  return {
    ID: date,
    value: toFixedValue(value),
    correctionsValue: toFixedValue(correctionsValue),
  }
}

const convertFromSubsidy = ({
  date,
  subsidies_sum: value,
  corrections_sum: correctionsValue,
}: ApiDataItemSubsidies) => {
  return {
    ID: date,
    value: toFixedValue(value),
    correctionsValue: toFixedValue(correctionsValue),
  }
}

const convertSchools = <T>(convertItem: (item: T) => DataItem, schools: Array<ApiSchoolItem<T>>) => {
  return schools.map(({ school_title: title, school_id: schoolID, data }) => ({
    ID: String(schoolID),
    title,
    data: map(convertItem, data),
  }))
}

export const convertResultCorrections = (res: ApiResultCorrections): ReportCorrectionsData => {
  const { operation_type: opType, schools, totals } = res

  return {
    operationType: opType,
    headers: map(({ date }) => ({ ID: date, date: new SimpleDate(date, dateFormat) }), totals),
    orgs: opType === 'purchases'
      ? convertSchools(convertFromPurchase, schools)
      : convertSchools(convertFromSubsidy, schools),
    total: map(({ date, total }) => ({ ID: date, value: toFixedValue(total) }), totals),
  }
}
