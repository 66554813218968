import api from 'app/actions/api';


export const OFFER_ACCEPT_REQUEST = 'OFFER_ACCEPT_REQUEST';
export const OFFER_ACCEPT_SUCCESS = 'OFFER_ACCEPT_SUCCESS';
export const OFFER_ACCEPT_FAILURE = 'OFFER_ACCEPT_FAILURE';

export const offerAcceptRequest = () => ({
  type: OFFER_ACCEPT_REQUEST,
});

export const offerAcceptSuccess = data => ({
  type: OFFER_ACCEPT_SUCCESS,
  payload: data,
});

export const offerAcceptFailure = payload => ({
  type: OFFER_ACCEPT_FAILURE,
  payload,
});


/**
* Принятие договора оферты
*/
export const acceptOffer = offerId => async (dispatch) => {
  dispatch(offerAcceptRequest());
  try {
    await api('account/offers/accept', 'post', { data: { id: offerId } });
    dispatch(offerAcceptSuccess(offerId));
  } catch (error) {
    dispatch(offerAcceptFailure({ err: 'Не удалось отправить согласие с договором оферты', error }));
  }
};
