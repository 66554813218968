// @flow
export const MAIL_FILTER_SET = 'reports/MAIL_FILTER_SET';
export const MAIL_FILTER_DEFAULT = 'reports/MAIL_FILTER_DEFAULT';

export const DO_NOT_MAIL: 'do_not_mail' = 'do_not_mail';
export const MAIL_AND_VIEW: 'mail_and_view' = 'mail_and_view';
export const MAIL_ONLY: 'mail_only' = 'mail_only';
export const options = { DO_NOT_MAIL, MAIL_AND_VIEW, MAIL_ONLY };
export type SendTo = typeof DO_NOT_MAIL | typeof MAIL_ONLY | typeof MAIL_AND_VIEW;

export const isReportView = (s: SendTo) => (s === DO_NOT_MAIL || s === MAIL_AND_VIEW);

export const allOptions = {
  [DO_NOT_MAIL]: 'Не отправлять',
  [MAIL_AND_VIEW]: 'Отправить файл на e-mail по завершении формирования',
  [MAIL_ONLY]: 'Отправить файл на e-mail без формирования отчета на экране',
};

export const mailFilterSet = (payload: SendTo) => ({
  type: MAIL_FILTER_SET,
  payload,
});

export const mailFilterDefault = () => ({
  type: MAIL_FILTER_DEFAULT,
});
