import { createSelector } from 'reselect';

import { selectOrgId, selectOrgsType } from 'app/components/Reports/FilterItems/hocs/commonSelectors';
import * as orgsTypes from 'app/common/constants/schools/types';


const selectIsEnableEmployeesFilter = createSelector(
  [
    selectOrgId,
    selectOrgsType,
  ],
  (orgId, orgsType) => Boolean(
    orgId
    && orgsType === orgsTypes.ENTERPRISE,
  ),
);

export default selectIsEnableEmployeesFilter;
