import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import equals from 'ramda/es/equals';
import isEmpty from 'ramda/es/isEmpty';

import { isSingle } from 'app/common/lib/object';
import {
  balanceTypesFilterInit,
  balanceTypesFilterClear,
  balanceTypesFilterDelete,
  balanceTypesFilterSet,
} from 'app/actions/reports/filters/balanceTypes';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';


const selectIsAll = createSelector(
  [
    state => state.reports.filters.balanceTypes.data,
    state => state.reports.filters.balanceTypes.selected,
  ],
  (data, selected) => (!isEmpty(data) && equals(data, selected)),
);

export default compose(
  connect(
    state => ({
      items: state.reports.filters.balanceTypes.data,
      selectedItems: state.reports.filters.balanceTypes.selected,
      isSelectedAllState: selectIsAll(state),
    }),
  ),

  withHandlers({
    reset: ({ dispatch, items }) => () => {
      dispatch(balanceTypesFilterSet(items));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset();
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems, reset }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        reset();
        return;
      }
      dispatch(balanceTypesFilterDelete([name]));
    },
    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(balanceTypesFilterSet(submitted));
    },
  }),

  lifecycle({
    componentDidMount() {
      const { dispatch } = this.props;
      dispatch(balanceTypesFilterInit());
    },
    componentDidUpdate(prevProps) {
      const { reset, items } = this.props;
      if (items !== prevProps.items && isEmpty(prevProps.items) && !isEmpty(items)) {
        reset();
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(balanceTypesFilterClear());
    },
  }),
);
