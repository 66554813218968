// @flow
import { iconTypes } from 'app/common/ui-next/plain-icon';


const ORGS_SETTINGS_GENERAL: 'general' = 'general';
const ORGS_SETTINGS_LIMITS: 'limits' = 'limits';
const ORGS_SETTINGS_REQUESTS: 'requests' = 'requests';
const ORGS_SETTINGS_SUBSIDIES: 'subsidies' = 'subsidies';
const ORGS_SETTINGS_SELLPOINTS: 'sellpoints' = 'sellpoints';
const ORGS_SETTINGS_CAFETERIA: 'cafeteria' = 'cafeteria';

const sections = {
  ORGS_SETTINGS_GENERAL,
  ORGS_SETTINGS_LIMITS,
  ORGS_SETTINGS_REQUESTS,
  ORGS_SETTINGS_SUBSIDIES,
  ORGS_SETTINGS_SELLPOINTS,
  ORGS_SETTINGS_CAFETERIA,
};

export type Section = $Values<typeof sections>
export default sections;

export const availableSections: Array<$Values<typeof sections>> = [
  sections.ORGS_SETTINGS_GENERAL,
  sections.ORGS_SETTINGS_LIMITS,
  sections.ORGS_SETTINGS_REQUESTS,
  sections.ORGS_SETTINGS_SUBSIDIES,
  sections.ORGS_SETTINGS_SELLPOINTS,
  sections.ORGS_SETTINGS_CAFETERIA,
];

export const defaultSection = sections.ORGS_SETTINGS_GENERAL;

export const sectionTitles: { [key: Section]: string } = {
  [sections.ORGS_SETTINGS_GENERAL]: 'Общие настройки',
  [sections.ORGS_SETTINGS_LIMITS]: 'Лимиты',
  [sections.ORGS_SETTINGS_REQUESTS]: 'Комплексное питание и\u00A0личные\u00A0продажи',
  [sections.ORGS_SETTINGS_SUBSIDIES]: 'Дотации',
  [sections.ORGS_SETTINGS_SELLPOINTS]: 'Точки продаж',
  [sections.ORGS_SETTINGS_CAFETERIA]: 'Место питания',
};

export const sectionIcons: { [key: Section]: $Values<typeof iconTypes> } = {
  [sections.ORGS_SETTINGS_GENERAL]: iconTypes.GENERAL_SETTINGS,
  [sections.ORGS_SETTINGS_LIMITS]: iconTypes.LIMITS,
  [sections.ORGS_SETTINGS_REQUESTS]: iconTypes.REQUESTS,
  [sections.ORGS_SETTINGS_SUBSIDIES]: iconTypes.SUBSIDIES,
  [sections.ORGS_SETTINGS_SELLPOINTS]: iconTypes.SELLPOINTS,
  [sections.ORGS_SETTINGS_CAFETERIA]: iconTypes.EATING_PLACE,
};
