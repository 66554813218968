// @flow
import { useCallback, useState, useEffect, useMemo } from 'react';
import toPairs from 'ramda/es/toPairs';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import path from 'ramda/es/path';
import descend from 'ramda/es/descend';
import sort from 'ramda/es/sort';
import moment from 'moment-timezone';

import type { BalanceRefundsRequestResult, BalanceRefundsRequestParams, BalanceRefundsItem } from 'app/common/api/general.types/cafe.balance.refunds';
import { api, requestsGeneral } from 'app/api';
import toFixedValue from 'app/common/lib/toFixedValue';


export type Refunds = $Rest<BalanceRefundsRequestResult, { count: number }>
export type RefundsArrayType = Array<{ id: string, time: string, ...BalanceRefundsItem }>

export default function useBalance(
  params: BalanceRefundsRequestParams,
  timezone: string,
): [RefundsArrayType, number, boolean] {
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [refunds, setRefunds] = useState<Refunds>({});

  const handleRequest = useCallback(async () => {
    setLoading(true);
    const refundsRes: BalanceRefundsRequestResult | null = await api.request(requestsGeneral.Cafe_GET_balance_refunds, {
      error: 'Не удалось получить список возвратов',
      params,
    });
    setLoading(false);
    if (refundsRes) {
      const { count: c, ...others } = refundsRes;
      setRefunds(others);
      setCount(c);
      return true;
    }
    return false;
  }, [params]);

  useEffect(() => {
    handleRequest();
  }, [params]);

  const refundsList: RefundsArrayType = useMemo(() => {
    return pipe(
      toPairs,
      sort(descend(path([1, 'date']))),
      map(([id, { date, value, comment }]) => {
        const mDate = moment(date);

        return ({
          id,
          date: (mDate.isValid() ? mDate.tz(timezone).format('DD.MM.YYYY') : ''),
          time: (mDate.isValid() ? mDate.tz(timezone).format('hh:mm') : ''),
          value: toFixedValue(value),
          comment,
        });
      }),
    )(refunds);
  }, [refunds, timezone]);


  return [refundsList, count, loading];
}
