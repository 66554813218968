// @flow
import React, { type Node, useMemo } from 'react';
import { pipe, map, splitEvery } from 'ramda';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryWrap } from 'app/components/common/reports/summary';

import type { SummaryCamp as SummaryType } from '../types';


const convertToString = (val: number | void, currency: string) => {
  return (typeof val === 'number'
    ? `${toFixedValue(val)} ${currency}`
    : '—'
  );
};

type Props = {
  summary: SummaryType,
  currency: string,
  excludeOVZ?: boolean,
}

const SummarySpecial = ({ summary, currency, excludeOVZ }: Props): Node => {
  const rows = useMemo(() => {
    return pipe(
      map(({ title, sum, count }) => ([
        title, [
          convertToString(sum, currency),
          String(count),
        ],
      ])),
      splitEvery(3),
    )(summary);
  }, [summary, currency]);

  return (
    <SummaryWrap>
      {rows.map((row, i) => (
        row.map(([title, value]) => (
          <SummaryItem
            key={title}
            title={title}
            value={value}
          />
        ))
      ))}
    </SummaryWrap>
  );
};

export default SummarySpecial;
