// @flow
import React, { Fragment } from 'react';

import Empty from 'app/components/common/Empty';

import ReportFilter from './ReportFilter';
import Report from './Report';
import { getNonEmptySubreports } from './getSubReports';

import ts from './Report/Table.scss';


const nonEmptySubreports = getNonEmptySubreports();

type Props = {
  subReport: string,
}

const Subsidies = ({ subReport }: Props) => {
  return (
    nonEmptySubreports.includes(subReport)
      ? <Fragment>
        <ReportFilter />
        <Report />
        <div className={ts.bottomSpace} />
      </Fragment>
      : <Empty />
  );
};

export default Subsidies;
