// @flow
import map from 'ramda/es/map';

const appliedFilterKeysRename = (
  mapper: { [key: string]: string },
  source: Array<[string, Array<string>]>,
) => map(
  ([key, value]) => ([mapper[key] || key, value]),
  source,
);

export default appliedFilterKeysRename;
