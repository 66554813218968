// @flow
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';
import propEq from 'ramda/es/propEq';

import { defaultStateDataCurrent, getStateCurrentValue, type State } from 'app/common/ui-components/filter';
import { GET_MENU_SALE_POINTS } from 'app/common/api/requests/food/schools/sale_points';
import { api } from 'app/api';

import orgFilter from '../org';
import buildingFilter from '../building';


const sellPointItems = async (state: State) => {
  const orgIds = getStateCurrentValue(orgFilter, state);
  const orgId = orgIds[0];

  const buildingIds = getStateCurrentValue(buildingFilter, state);
  const buildingId = isEmpty(buildingIds) ? null : parseInt(buildingIds[0], 10);

  if (orgIds.length === 1 && orgId) {
    const res = await api.request(GET_MENU_SALE_POINTS, {
      error: 'Не удалось получить данные точек продаж',
      params: buildingId ? {
        building_id: buildingId,
      } : {},
      requestPathParams: {
        orgId,
      },
    });

    if (res && !isEmpty(res)) {
      const items = filter(complement(propEq('deleted', true)), values(res));
      return ([items, defaultStateDataCurrent]);
    }
  }
  return [[], defaultStateDataCurrent];
};

export default sellPointItems;
