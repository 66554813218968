// @flow
import { useState, useCallback, useEffect } from 'react';

import duplicateMapState from 'app/common/lib/duplicateMapState';


export default function useAmountAddBySlotId(defaultState: Map<string, number>): {
  amountAddBySlotId: Map<string, number>,
  increase: (slotId: string) => void,
  decrease: (slotId: string) => void,
  change: (slotId: string, value: number) => void,
} {
  const [amountAddBySlotId, setAmountAddBySlotId] = useState(defaultState);

  const increase = useCallback((slotId: string) => {
    setAmountAddBySlotId(duplicateMapState((id, val) => (
      id === slotId ? val + 1 : val
    )));
  }, []);

  const decrease = useCallback((slotId: string) => {
    setAmountAddBySlotId(duplicateMapState((id, val) => (
      id === slotId ? val - 1 : val
    )));
  }, []);

  const change = useCallback((slotId: string, value: number) => {
    setAmountAddBySlotId(duplicateMapState((id, val) => (
      id === slotId ? value : val
    )));
  }, []);

  useEffect(() => {
    setAmountAddBySlotId(defaultState);
  }, [defaultState]);

  return {
    amountAddBySlotId,
    increase,
    decrease,
    change,
  };
}
