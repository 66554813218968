// @flow
import type { Request } from '../../index';


type ImageParamsType = {
  file: string,
  o?: number,
  w: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
};

type Params = {
  school_id: string,
  complex_id: string,
  date: string,
  photo: ImageParamsType | null,
}

type Result = {
  result: 'success',
  [id: string]: string,
}

export const POST_COMPLEX_PHOTO_ADD_REMOVE: Request<
  Params,
  Result,
  void
> = [
  'food/menu/complex_photo_add_remove',
  'post',
  ['data'],
];
