// @flow
import React, { type Node, Fragment } from 'react';

import { Table, useCopyColumnsWidths } from 'app/common/ui-components/table';
import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow';
import Loading from 'app/common/components/Loader';
import EmptyWarn from 'app/common/layouts/EmptyWarn';

import type { ActualSlotData } from './useActualSlotsData';
import useHeaderCellsData from './useHeaderCellsData';
import HeaderRow from './HeaderRow';
import useRowCellsData from './useRowCellsData';
import Row from './Row';

import styles from './ProductsTable.scss';


type Props = {
  loading?: boolean,
  products: Array<ActualSlotData>,
  selection: Map<string, boolean>,
  selectedAll: boolean,
  headers?: Array<string>,
  onChange: (id: string, checked: boolean) => void,
  getModifyDiff: (amount: number, amountMax: number) => number,
  getModifyRest: (amount: number, amountMax: number) => number,
  amountAddBySlotId: Map<string, number>,
  increase: (slotId: string) => void,
  decrease: (slotId: string) => void,
  change: (slotId: string, value: number) => void,
}

const ProductsTable = ({
  loading,
  selection,
  selectedAll,
  products,
  headers,
  onChange,
  getModifyDiff,
  getModifyRest,
  amountAddBySlotId,
  increase,
  decrease,
  change,
}: Props): Node => {
  const headerCells = useHeaderCellsData(headers);
  const total = products.length;

  const rowsData = useRowCellsData(products, getModifyDiff, getModifyRest);

  const [refFrom, refTo] = useCopyColumnsWidths(products);

  return (
    <Fragment>
      <div className={styles.headerContainer}>
        <Table className={styles.table}>
          <thead>
            <HeaderRow
              data={headerCells}
              onChange={onChange}
              checked={selectedAll}
              reference={refTo}
            />
          </thead>
        </Table>

        {loading
          && total > 0
          && <Loading className={styles.loadingOverContent} />}
      </div>

      <ScrollbarsWShadow
        autoHeight
        autoHeightMin={300}
        autoHeightMax="56vh"
        fillAllSpace
      >
        {loading
          && total === 0
          && <Loading />}

        {!loading
          && total === 0
          && <EmptyWarn>
            {'Нет записей, удовлетворяющих условиям'}
          </EmptyWarn>}

        {total > 0
          && <Table className={styles.table}>
            <thead>
              <HeaderRow
                data={headerCells}
                reference={refFrom}
                checked={false}
                onChange={onChange}
                hidden
                dummy
              />
            </thead>

            <tbody>
              {rowsData.map((cells, i) => {
                const { slotId: id, productTitle: title } = products[i];

                return (
                  <Row
                    key={id}
                    id={id}
                    title={title}
                    cells={cells}
                    disabled={loading}
                    checked={selection.get(id)}
                    onChange={onChange}
                    amountAdd={amountAddBySlotId.get(id)}
                    increase={increase}
                    decrease={decrease}
                    change={change}
                  />
                );
              })}
            </tbody>
          </Table>}
      </ScrollbarsWShadow>
    </Fragment>
  );
};

export default ProductsTable;
