import { dataKey as menuDataKey } from 'app/components/OrgsList/OrgsMenu'
import { dataKey as kitsDataKey } from 'app/components/OrgsList/OrgsKits'
import { dataKey as dishesDataKey } from 'app/components/OrgsList/OrgsDishes'
import { dataKey as ordersDataKey } from 'app/components/OrgsList/OrgsOrders'
import { dataKey as settingsDataKey } from 'app/components/OrgsList/OrgsSettings'


export const pageTypes = Object.freeze({
  menu: menuDataKey,
  kits: kitsDataKey,
  dishes: dishesDataKey,
  orders: ordersDataKey,
  settings: settingsDataKey,
} as const)

export type PageType = typeof pageTypes[keyof typeof pageTypes]
