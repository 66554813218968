// @flow
import { useCallback } from 'react';
import { createGlobalState, useEffectOnce } from 'react-use';

import { api } from 'app/api';
import { GET_REPORTS_LIST, onlyAvailableReports } from 'app/common/api/requests/food/reports_list';

import { reportsArrange } from './availableReports';


const defaultActiveItems: typeof reportsArrange = [];
const useGlobalState = createGlobalState({
  busy: false,
  loading: true,
  activeItems: defaultActiveItems,
});

export default function useActiveReports(): [typeof reportsArrange, boolean] {
  const [activeState, setActiveState] = useGlobalState();

  const request = useCallback(async () => {
    const res = await api.request(GET_REPORTS_LIST, {
      error: 'Запрос настроек отчетов не удался',
    });
    if (res) {
      setActiveState({
        busy: false,
        loading: false,
        activeItems: onlyAvailableReports(res),
      });
    }
  }, [setActiveState]);

  useEffectOnce(() => {
    setActiveState((state) => {
      const { busy } = state;
      if (busy) {
        return state;
      }
      request();
      return { ...state, busy: true };
    });
  });

  return [activeState.activeItems, activeState.loading];
}
