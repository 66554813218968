import { ReactNode, useCallback, useState } from 'react'

import PlainModal, {
  HandleModalDisplayed,
  PlainModalContent,
  PlainModalFooter,
  PlainModalHeader,
} from 'app/common/ui-next/plain-modal'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'

import OrgGroupForm from './OrgGroupForm'
import { FormField } from './OrgGroupFormFields'


type Props = {
  children: (toggle: HandleModalDisplayed) => ReactNode
  submit: (title: string) => Promise<boolean>
  errors: Record<string, string>
  resetErrors: () => void
}

const AddOrgGroupModal = ({ children, submit, errors, resetErrors }: Props) => {
  const [valueExist, setValueExist] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = useCallback(
    async (form: HTMLFormElement) => {
      const formData = new FormData(form)
      setSubmitting(true)
      const title = formData.get(FormField.Name) as string
      const res = await submit(title.trim())
      setSubmitting(false)
      setValueExist(false)
      return res
    },
    [submit],
  )

  return (
    <PlainModal
      formId="addOrgGroup"
      controlBy={children}
      onSubmit={handleSubmit}
    >
      <PlainModalHeader>{'Добавление группы'}</PlainModalHeader>

      <PlainModalContent>
        <OrgGroupForm
          onValueExist={setValueExist}
          onResetErrors={resetErrors}
          error={errors.title}
        />
      </PlainModalContent>

      <PlainModalFooter>
        {toggleModal => (
          <ControlsBar right>
            <PlainButton outline disabled={submitting} onClick={toggleModal}>
              {'Отмена'}
            </PlainButton>
            <PlainButton type="submit" disabled={submitting || !valueExist}>
              {submitting ? 'Добавление...' : 'Добавить'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}

export default AddOrgGroupModal
