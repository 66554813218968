// @flow
import path from 'ramda/es/path';

import type { Machine, ModelsState, VersionsState } from 'app/dataProviders/VendingDataProvider';
import type { TableRowProps } from './TBodyRow';


type RelatedItems = {
  [id: string]: {
    id: number | string,
    title: string,
  }
}

const machine2TableRowProps = (
  relatedOrgs: RelatedItems,
  models: ModelsState,
  versions: VersionsState,
) => ({
  id,
  title,
  school_id: orgId,
  number,
  model_id: modelId,
  version_id: versionId,
}: Machine): TableRowProps => {
  return ({
    id,
    title,
    org: path([orgId, 'title'], relatedOrgs),
    number,
    model: `${path([modelId, 'title'], models) || ''} ${path([versionId, 'title'], versions) || ''}`,
    goodsAmountInPercent: 100,
    loadedAt: '—',
    lastSellAt: '—',
    errors: 0,
    sameOrg: false,
    rowSpan: 1,
    className: '',
  });
};

export default machine2TableRowProps;
