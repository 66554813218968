import always from 'ramda/es/always';
import evolve from 'ramda/es/evolve';
import assoc from 'ramda/es/assoc';

import {
  COMMONBALANCE_FILTER_INIT,
  COMMONBALANCE_FILTER_CLEAR,
  COMMONBALANCE_FILTER_DELETE,
  COMMONBALANCE_FILTER_SET_SELECTED,
  COMMONBALANCE_FILTER_SET_RANGE,
  BALANCE_ALL,
} from 'app/actions/reports/filters/commonBalance';


const defaultState = {
  data: {},
  selected: BALANCE_ALL,
  min: '',
  max: '',
};

const balanceTypes = (state = defaultState, { type, payload }) => {
  switch (type) {
    case COMMONBALANCE_FILTER_INIT: {
      return evolve({
        data: always(payload),
      }, state);
    }
    case COMMONBALANCE_FILTER_CLEAR: {
      return { ...state, selected: BALANCE_ALL, min: '', max: '' };
    }
    case COMMONBALANCE_FILTER_DELETE: {
      return assoc('selected', BALANCE_ALL, state);
    }
    case COMMONBALANCE_FILTER_SET_SELECTED: {
      return assoc('selected', payload, state);
    }
    case COMMONBALANCE_FILTER_SET_RANGE: {
      return assoc(payload.name, payload.value, state);
    }
    default:
  }
  return state;
};

export default balanceTypes;
