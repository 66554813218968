// @flow
import type { StateDataItem } from 'app/common/ui-components/filter';

import { saleTypes } from 'app/dataTypes/reports/saleTypes';


const sellTypeItemsDefaultData = [{
  id: saleTypes.Personal,
  title: 'Личные продажи',
}, {
  id: saleTypes.Complex,
  title: 'Комплексное питание',
}];

const sellTypeItemsDefault = (): Array<StateDataItem> => {
  return sellTypeItemsDefaultData;
};

export default sellTypeItemsDefault;
