// @flow
import React, { type Node, Fragment, useCallback } from 'react';

import { PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainInput from 'app/common/ui/PlainInput';
import PlainTextArea from 'app/common/ui/PlainTextArea';
import type { Person } from 'app/dataTypes/requisites';
import PlainInputMasked, { DealerPhoneMask, type NumberFormatValues } from 'app/common/ui-next/plain-input-masked';
import PlainLabel from 'app/common/ui-next/plain-label';

import styles from './PersonForm.scss';


type Props = {
  value: Person,
  valueBasePath: string,
  onChange: (string, string) => void,
  loading?: boolean,
  locked?: boolean,
}

const PersonForm = ({
  value,
  valueBasePath,
  onChange,
  loading,
  locked,
}: Props): Node => {
  const handleChangeField = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: val, name } = e.currentTarget;
    onChange(`${valueBasePath}.${name}`, val);
  }, [valueBasePath, onChange]);

  const handleChangePhone = useCallback((values: NumberFormatValues) => {
    onChange(`${valueBasePath}.phone`, values.value);
  }, [valueBasePath, onChange]);

  return (
    <Fragment>
      <PlainColumns className={styles.row}>
        <PlainColumn div={4}>
          <PlainInput
            label="Фамилия"
            name="name.last"
            value={value.name.last}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            autoComplete="family-name"
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="Имя"
            name="name.first"
            value={value.name.first}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            autoComplete="given-name"
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="Отчество"
            name="name.middle"
            value={value.name.middle}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            autoComplete="additional-name"
            placeholder="Не указано"
          />
        </PlainColumn>
      </PlainColumns>

      {/* ROW 2 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={4}>
          <PlainLabel htmlFor="phone" skipUpperSpace>
            {'Телефон'}
          </PlainLabel>
          <DealerPhoneMask>
            {maskType => (
              <PlainInputMasked
                id="phone"
                className={styles.inputMasked}
                value={value.phone}
                predefinedMaskType={maskType}
                disabled={locked}
                onValueChange={handleChangePhone}
              />
            )}
          </DealerPhoneMask>
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="E-mail"
            name="email"
            value={value.email}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            autoComplete="email"
            placeholder="Не указано"
          />
        </PlainColumn>
      </PlainColumns>

      {/* ROW 3 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={2}>
          <PlainTextArea
            label="Комментарий"
            name="comment"
            value={value.comment}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
      </PlainColumns>

    </Fragment>
  );
};

export default PersonForm;
