// @flow
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames/bind';

import Dish from 'app/components/common/Dish';
import Pager from 'app/common/ui/Paging';
import Cell from 'app/components/ui/Cell';
import shadows from 'app/common/containers/shadows';
import type { DishItemProps } from 'app/components/common/Dish/Dish';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';

import connectActions from './connectActions';
import enhanceDishesList from './enhanceDishesList';
import styles from './DishesList.scss';


const cx = classNames.bind(styles);

const classes = {
  checkbox: styles.cCheckbox,
  portion: styles.cPortion,
  cost: styles.cCost,
  setup: styles.cSetup,
};

type SettingsType = {
  title: true,
  photo: boolean,
  portion: boolean,
  cost: boolean,
  setup: true,
};

type Props = {
  items: Array<DishItemProps>,
  className: string,
  loading: boolean,
  existingItems: Object,
  addList: Array<number>,
  removeList: Array<number>,

  total: number,
  rows: number,
  currentPage: number,
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,

  updateScroll: (Object) => void,
  isTopShadow: boolean,
  isBottomShadow: boolean,

  settings: SettingsType,
}

const DishesList = ({
  items,
  className,
  loading,
  existingItems,
  addList,
  removeList,

  total,
  rows,
  currentPage,
  handlePage,

  updateScroll,
  isTopShadow,
  isBottomShadow,

  settings,
}: Props) => {
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.header}>
        <Cell className={cx(styles.middle, classes.checkbox)} grow={1}>&nbsp;</Cell>
        <Cell grow={5}>{'Наименование'}</Cell>
        {settings.photo && <Cell className={styles.middle} grow={3}>{'Фото'}</Cell>}
        {settings.portion && <Cell className={cx(styles.right, classes.portion)} grow={2}>{'Порция,\u00A0гр.'}</Cell>}
        {settings.cost && <Cell className={cx(styles.right, classes.cost)} grow={2}>{`Стоимость${currency}`}</Cell>}
        <Cell grow={1} className={classes.setup}>&nbsp;</Cell>
      </div>
      <div className={styles.items}>
        <Scrollbars
          className={cx({
            isTopShadow,
            isBottomShadow,
          })}
          onUpdate={updateScroll}
        >
          {items.length
            ? items.map((item) => {
              const itemExist = !!existingItems[item.id];
              const checked = itemExist
                ? !removeList.includes(item.id)
                : addList.includes(item.id);
              return (
                <Dish
                  id={item.id}
                  groupName="add_remove"
                  key={item.id}
                  params={item}
                  loading={loading}
                  getSaveParams={null}
                  connectActions={connectActions}
                  settings={settings}
                  checked={checked}
                  locked
                  success={itemExist}
                  classes={classes}
                />
              );
            })
            : <div className={styles.empty}>{'Нет продукции, удовлетворяющей условиям'}</div>}
        </Scrollbars>
      </div>
      {(total > rows)
        && <div className={styles.pager}>
          <Pager
            total={total}
            pageSize={rows}
            currentPage={currentPage}
            currentPageSize={items.length}
            onPage={handlePage}
          />
        </div>}
    </div>
  );
};

export default enhanceDishesList(shadows(DishesList));
