import { useCallback } from 'react'
import moment from 'moment-timezone'
import { Redirect } from 'react-router'

import OrgsKits, { dataKey } from 'app/components/OrgsList/OrgsKits'
import { withKitsKitsRights } from 'app/containers/rights'
import config from 'app/config'

import MustEnableEMoney from '../MustEnableEMoney'
import AccessDenied from '../AccessDenied'
import getBaseUrl from '../getBaseUrl'
import OrgsPageTemplateV3 from '../OrgsPageTemplateV3'


export { dataKey }

type Props = {
  match: {
    params: {
      orgId: string,
      date?: string,
      act?: string,
      kitId?: string,
    },
  },
  readAccess: boolean,
  history: {
    push: (p: string) => void,
  }
}

const OrgsKitsPage = ({ match, readAccess, history }: Props) => {
  const { params: { orgId, date, act, kitId } } = match
  const mDate = moment(date)
  const dateReady = date && mDate.isValid() ? date : null
  const today = moment().format(config.dateFormat)

  const baseUrl = getBaseUrl({ orgId, section: dataKey })
  const redirectUrl = `${baseUrl}/${today}/current`

  const redirectToDate = useCallback((day: string, format: string) => {
    const toDate = moment(day, format).format(config.dateFormat)
    history.push(`${baseUrl}/${toDate}`)
  }, [baseUrl, history])

  const redirectToAct = useCallback((act_: string) => {
    history.push(`${baseUrl}/${date}/${act_}`)
  }, [baseUrl, history, date])


  if (!readAccess) {
    return (
      <AccessDenied />
    )
  }

  if (!dateReady) {
    return (
      <Redirect to={redirectUrl} />
    )
  }

  return (
    date
      ? <OrgsPageTemplateV3 orgID={orgId} date={date}>
        {type => (
          <MustEnableEMoney>
            <OrgsKits
              orgID={orgId}
              kitID={kitId}
              orgType={type}
              date={date}
              dateFormat={config.dateFormat}
              filter={act}
              basePath={`${baseUrl}/${date}`}
              redirectToDate={redirectToDate}
              redirectToFilter={redirectToAct}
            />
          </MustEnableEMoney>
        )}
      </OrgsPageTemplateV3>
      : null
  )
}

export default withKitsKitsRights(OrgsKitsPage)
