// @flow
import React, { type Ref } from 'react';

import type { Place } from './useAtWorkJobPlaces';
import JPlace from './JPlace';
import type { RoleItem } from './useRoles';

import styles from './JobPlacesAtWork.scss';


type Props = {
  formRef: Ref<'form'>,
  locked: boolean,
  places: Array<Place>,
  roles: Array<RoleItem>,
  personId: number,
  submitDisabled: boolean,
  onSubmit: (e: SyntheticEvent<HTMLFormElement>) => Promise<void>,
}

const JobPlacesAtWork = ({
  formRef,
  locked,
  places,
  roles,
  personId,
  submitDisabled,
  onSubmit,
}: Props) => {
  return (
    <form onSubmit={onSubmit} className={styles.form} ref={formRef}>
      {places.map((props: Place) => (<JPlace
        key={props.id}
        {...props}
        orgId={personId} // перепутано в JPlace, но работает верно
        deleteAvailable={places.length > 1}
        roles={roles}
        locked={locked}
      />))}

      {!locked
        && <button
          type="submit"
          name="submit"
          className={styles.submitHidden}
          disabled={submitDisabled}
        >
          {'Сохранить'}
        </button>}
    </form>
  );
};

export default JobPlacesAtWork;
