// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { employeeSchoolsChangeField } from 'app/actions/employees/employee/jobplaces';
import { jobplacesDelete } from 'app/actions/employees/employee/jobplaces/delete';
import usePopup from 'app/common/hooks/usePopup';


type Status = {
  loading: boolean,
  saving: boolean,
}

type Actions = {
  changeDate: (newDate: string, elementId?: string) => void,
  changeField: (e: SyntheticEvent<HTMLInputElement>) => void,
  changeRole: ({ id: number }) => void,
  deleteJPlace: () => Promise<void>,
}

export default function useJPlace(id: number, orgId: number): [Status, Actions] {
  const dispatch = useDispatch();
  const { loading, saving } = useSelector(state => state.employees.employee.options, shallowEqual);
  const popup = usePopup();

  const changeDate = useCallback((newDate: string, elementId?: string) => {
    if (elementId) {
      dispatch(employeeSchoolsChangeField({ id, value: newDate, name: elementId }));
    }
  }, [dispatch, id]);

  const changeField = useCallback((e) => {
    const { value, name } = e.currentTarget;
    dispatch(employeeSchoolsChangeField({ id, value, name }));
  }, [dispatch, id]);

  const changeRole = useCallback(({ id: roleId }: { id: number }) => {
    dispatch(employeeSchoolsChangeField({ id, value: roleId, name: 'role_id' }));
  }, [dispatch, id]);

  const deleteJPlace = useCallback(async () => {
    const res = await dispatch(jobplacesDelete(orgId, id)); // перепутано orgId <=> id, но работает верно
    if (res) {
      popup('Место работы успешно удалено');
    }
  }, [dispatch, id, popup, orgId]);

  return [{
    loading,
    saving,
  }, {
    changeDate,
    changeField,
    changeRole,
    deleteJPlace,
  }];
}
