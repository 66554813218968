import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  FOODMODES_FILTER_CLEAR,
  FOODMODES_FILTER_DELETE,
  FOODMODES_FILTER_SET,
  FOODMODES_FILTER_UPDATE,
} from 'app/actions/reports/filters/foodModes';


const defaultState = {
  selected: {},
  loading: true,
  active: false,
};

const foodModes = (state = defaultState, { type, payload }) => {
  switch (type) {
    case FOODMODES_FILTER_CLEAR: {
      return defaultState;
    }
    case FOODMODES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case FOODMODES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case FOODMODES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default foodModes;
