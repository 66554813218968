import set from 'ramda/es/set';
import lensProp from 'ramda/es/lensProp';
import omit from 'ramda/es/omit';

import api from 'app/actions/api';
import get from 'app/common/lib/get';
import getRandomExpired from 'app/common/lib/expired';

export const ROLES_REQUEST = 'admin/ROLES_REQUEST';
export const ROLES_SUCCESS = 'admin/ROLES_SUCCESS';
export const ROLES_FAILURE = 'admin/ROLES_FAILURE';
export const ROLES_CLEAR = 'admin/ROLES_CLEAR';

export const rolesRequest = () => ({
  type: ROLES_REQUEST,
});

export const rolesSuccess = payload => ({
  type: ROLES_SUCCESS,
  payload,
});

export const rolesFailure = payload => ({
  type: ROLES_FAILURE,
  payload,
});

export const rolesFetch = () => async (dispatch, getState) => {
  const currentRoles = getState().admin.roles;

  if (currentRoles.expired >= Date.now()) {
    return omit(['expired'], currentRoles);
  }
  dispatch(rolesRequest());
  try {
    const res = await api('food/roles', 'get', {}, true);
    const roles = get(res, ['data', 'roles'], {});
    dispatch(rolesSuccess(set(lensProp('expired'), getRandomExpired(), roles)));
    return roles;
  } catch (error) {
    dispatch(rolesFailure({ err: 'Не удалось получить список типов пользователей', error }));
    return null;
  }
};
