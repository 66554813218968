// @flow
import React from 'react';
import moment from 'moment-timezone';

import type { State, RadioDateRestProps } from 'app/common/ui-components/filter';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import getCurrentYearPeriod, { dateFormat } from '../year/getCurrentYearPeriod';


const currentStateDateProps = (state: State): RadioDateRestProps => {
  const [from, to] = getCurrentYearPeriod(state);
  const mFrom = moment(from, dateFormat);
  const mTo = moment(to, dateFormat);
  const fromValid = from && mFrom.isValid();
  const toValid = to && mTo.isValid();

  const dateDisabledStatus = (fromValid && toValid
    ? (date: string, format: string, unit?: string) => {
      const mToday = moment();
      if (mToday.isBetween(mFrom, mTo, 'day')) {
        return !moment(date, format).isBetween(mFrom, mToday, unit);
      }
      return !moment(date, format).isBetween(mFrom, mTo, unit);
    }
    : (date: string, format: string, unit?: string) => {
      return moment(date, format).isAfter(moment(), unit);
    });

  const afterContent = (
    <PlainHelperText success oldUpperSpace>
      {'Остаток на начало дня'}
    </PlainHelperText>
  );

  return {
    label: 'Дата',
    dateFormat,
    dateDisabledStatus,
    afterContent,
  };
};

export default currentStateDateProps;
