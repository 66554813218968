// @flow
import React, { useState, useEffect, useCallback, SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'
// import assoc from 'ramda/es/assoc'

import { PlainColumns, PlainColumn, PlainGroup } from 'app/common/ui/PlainGrid'
import PlainInput from 'app/common/ui/PlainInput'
import PlainButton from 'app/common/ui-next/plain-button'
import ControlsBar from 'app/common/ui-next/controls-bar'
import { pricePositiveRe } from 'app/common/constants/regex'
import usePopup from 'app/common/hooks/usePopup'
import { useEditGroceryKitFormSubmit } from 'app/dataProviders/org/menu/complexGrocerySet/hooks'

import selectKit from './selectKit'
import styles from './EditKitForm.scss'

/**
 *  Форма создание/редактирование комплекта
 */

const getNewBtTitle = saving => (saving ? 'Создание...' : 'Создать')
const getEditBtTitle = saving => (saving ? 'Сохранение...' : 'Сохранить')

type Props = {
  kitId: number,
  orgId: number,
  date: string,
  toggleModal: () => void,
}

type FieldsType = {
  grocerySetPrice: string | null
  keepGrocerySetPrice: boolean
}

export const EditGroceryKitForm = ({
  kitId,
  orgId,
  date,
  toggleModal,
}: Props) => {
  const popup = usePopup()
  const { grocerySetPrice, keepGrocerySetPrice } = useSelector(state => selectKit(state, { kitId }))

  const [fields, setFields] = useState<FieldsType>({
    grocerySetPrice: null,
    keepGrocerySetPrice: true,
  })
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (kitId) {
      setFields({ grocerySetPrice, keepGrocerySetPrice })
    }
  }, [date, grocerySetPrice, keepGrocerySetPrice, kitId])


  const handleFieldChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    let newValue = value
    if (name !== 'grocerySetPrice') return
    if (newValue.includes(',')) {
      newValue = newValue.replace(',', '.')
    }
    if (!pricePositiveRe.test(newValue) || newValue[0] === '0') {
      return
    }
    setFields((prev) => { return { ...prev, grocerySetPrice: newValue } })
  }, [])

  const { submit, checkErrors } = useEditGroceryKitFormSubmit({
    kitId,
    orgId,
    grocerySetPrice: fields.grocerySetPrice,
    date,
  })

  const handleSubmit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (checkErrors()) {
      setSubmitting(true)
      const res = await submit()
      setSubmitting(false)

      if (res) {
        popup('Стоимость успешно обновлена')
        toggleModal()
      }
    }
  }, [submit, checkErrors, popup, toggleModal])

  const form = submit_ => (
    <form onSubmit={submit_}>
      <PlainGroup
        className={styles.content}
      >
        <PlainColumns>
          <PlainColumn>
            <PlainInput
              name="grocerySetPrice"
              label="Цена"
              onChange={handleFieldChange}
              value={fields.grocerySetPrice ?? ''}
              disabled={fields.keepGrocerySetPrice}
            />
          </PlainColumn>
        </PlainColumns>
      </PlainGroup>

      <ControlsBar right className={styles.buttons}>
        <PlainButton outline onClick={toggleModal}>
          {'Отмена'}
        </PlainButton>
        <PlainButton
          type="submit"
          disabled={submitting || (!kitId && fields.keepGrocerySetPrice)}
        >
          {!kitId
            ? getNewBtTitle(submitting)
            : getEditBtTitle(submitting)}
        </PlainButton>
      </ControlsBar>
    </form>
  )

  return form(handleSubmit)
}
