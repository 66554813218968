// @flow
// @flow-runtime warn
import { createContext, type ComponentType } from 'react';

import * as types from 'app/common/constants/identifiers/types';

import { type Identifiers, type ReassignTarget } from './identifiers-types';
import * as moduleTypes from './module-types';


export const defaultIdentifiers = {};
export const defaultReassignTargets = [];

type ContentType = {
  personId: number,
  locked: boolean,
  identifiers: Identifiers,
  timezone: string,
  reassignEnabled: boolean,
  reassignTargets: Array<ReassignTarget>,
  hiddenModuleSwitchers: Array<$Values<typeof moduleTypes>>,
}

const defaultContent: ContentType = {
  personId: 0,
  locked: false,
  identifiers: {},
  timezone: 'Europe/Moscow',
  reassignEnabled: false,
  reassignTargets: [],
  hiddenModuleSwitchers: [],
};

type LoadingType = {
  identifiers: boolean,
  newIdentifier: boolean,
}

const defaultLoading: LoadingType = {
  identifiers: true,
  newIdentifier: false,
};

type RequestsType = {
  identifiers: () => Promise<void> | void,
  block: (identifierId: number, reason: string, personId?: number) => Promise<boolean> | boolean,
  unblock: (identifierId: number, personId?: number) => Promise<boolean> | boolean,
  reassign: (identifierId: number, studentId: number) => Promise<boolean> | boolean,
  moduleSwitch: (
    identifierId: number,
    moduleType: $Values<typeof moduleTypes>,
    newValue: boolean
  ) => Promise<boolean> | boolean,
  addNewIdentifier: (type: $Values<typeof types>, number: string) => Promise<boolean> | boolean,
}

const defaultRequestFunc = () => false;
const defaultRequests = {
  identifiers: () => {},
  block: defaultRequestFunc,
  unblock: defaultRequestFunc,
  reassign: defaultRequestFunc,
  moduleSwitch: defaultRequestFunc,
  addNewIdentifier: defaultRequestFunc,
};

type ViewsType = {
  reassignItemView: ?ComponentType<any>,
}

const defaultViews = {
  reassignItemView: null,
};

type IdentifiersContextType = {
  content: ContentType,
  loading: LoadingType,
  request: RequestsType,
  views: ViewsType,
}

const defaultContext: IdentifiersContextType = {
  content: defaultContent,
  loading: defaultLoading,
  request: defaultRequests,
  views: defaultViews,
};

export default createContext<IdentifiersContextType>(defaultContext);
