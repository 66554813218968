// @flow

type Options = {
  report?: string,
}

const emptyOptions: Options = Object.freeze({});

const getBaseUrl = ({ report }: Options = emptyOptions) => {
  if (report) {
    return `/finances/${report}`;
  }
  return '/finances';
};

export default getBaseUrl;
