// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import { identifiersNames } from 'app/common/constants/identifiers';

import styles from './identifier-view.scss';


export type IdentifierType = $Keys<typeof identifiersNames> | ''

type Props = {
  type: IdentifierType,
  price: ?string,
};

const IdentifierView = ({ type, price }: Props) => {
  const { t } = useTranslation('identifiers-next');

  return (
    <div className={styles.result}>
      <div className={styles.resultRow}>
        <div className={styles.resultColLeft}>
          {t('identifier_entering_selected_type_label')}
        </div>
        <div className={styles.resultColRight}>
          {type !== '' ? identifiersNames[type] : '???'}
        </div>
      </div>
      {price
        && <div className={styles.resultRow}>
          <div className={styles.resultColLeft}>
            {t('identifier_entering_selected_cost_label')}
          </div>
          <div className={styles.resultColRight}>
            {price}
          </div>
        </div>}
    </div>
  );
};

export default IdentifierView;
