import {
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAILURE,
} from 'app/actions/categories/items';

import {
  CATEGORIES_CHANGE_ITEM_SUCCESS,
} from 'app/actions/categories/selection';

import {
  CATEGORIES_ADD_SUCCESS,
} from 'app/actions/categories/add';


const defaultState = {
  loading: false,
  categories: {},
  result: null,
};

const categories = (state = defaultState, action) => {
  switch (action.type) {
    case CATEGORIES_REQUEST: {
      return { ...state, loading: true };
    }

    case CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CATEGORIES_CHANGE_ITEM_SUCCESS: {
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.payload,
        },
      };
    }

    case CATEGORIES_ADD_SUCCESS: {
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.payload,
        },
      };
    }

    default:
  }
  return state;
};

export default categories;
