// @flow
import React, { Fragment, type Node, useState, useEffect } from 'react';
import intersperse from 'ramda/es/intersperse';
import split from 'ramda/es/split';

import PlainModal, { PlainModalHeader, PlainModalContent } from 'app/common/ui-next/plain-modal';
import Loading from 'app/common/components/Loader';

import styles from './MultipleSubmitIndicator.scss';


type Props = {
  children?: Node,
  show: boolean,
  message?: string,
}

const MultipleSubmitIndicator = ({
  children,
  show: externalShow,
  message = 'Подождите,\nидет сохранение данных',
}: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => { // задержка, чтоб окно не мигало
    if (externalShow) {
      setShow(true);
      return;
    }
    setTimeout(() => {
      setShow(false);
    }, 500);
  }, [externalShow]);

  return (
    <Fragment>
      {children}
      <PlainModal show={show}>
        <PlainModalHeader className={styles.header}>
          {intersperse('###', split('\n', message)).map((str, i) => (
            str === '###'
              ? <br key={i} />
              : str
          ))}
        </PlainModalHeader>
        <PlainModalContent>
          <Loading className={styles.loading} />
        </PlainModalContent>
      </PlainModal>
    </Fragment>
  );
};

export default MultipleSubmitIndicator;
