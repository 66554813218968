
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';

import SideNav from './SideNav';
import SideNavNew from './SideNavNew';

export default branch(
  ({ children }) => !!children,
  renderComponent(SideNavNew),
)(SideNav);
