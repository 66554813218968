import {
  ORGS_REQUEST,
  ORGS_SUCCESS,
  ORGS_FAILURE,
} from 'app/actions/orgs';


const defaultState = {
  loading: false,
  schools: {},
  result: null,
};

const current = (state = defaultState, action) => {
  switch (action.type) {
    case ORGS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case ORGS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case ORGS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
  }
  return state;
};

export default current;
