// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import Tooltip from 'app/common/ui-next/plain-tooltip';

import styles from './plain-helper-text.scss';

/**
 * TODO / пример использования в accessibility
<div class="form-control">
  <label for="my-input">Адрес электронной почты</label>
  <input id="my-input" aria-describedby="my-helper-text" />
  <span id="my-helper-text">Мы никогда не распостраним ваш адрес.</span>
</div>
 */

const cx = classNames.bind(styles);

export type HelperTextProps = {
  children?: any,
  className?: string,
  error?: boolean,
  success?: boolean,
  overflowVisible?: boolean,
  oldUpperSpace?: boolean,
}

const PlainHelperText = ({
  children,
  className,
  error,
  success,
  overflowVisible = false,
  oldUpperSpace,
}: HelperTextProps): Node => {
  const classOverall = cx(styles.root, { error, success, oldUpperSpace }, className);

  return (overflowVisible
    ? <div className={classOverall}>
      {children || '\u00A0'}
    </div>
    : <Tooltip className={classOverall} text={children} hideWithoutOverflow>
      {children || '\u00A0'}
    </Tooltip>
  );
};

export default PlainHelperText;
