// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withState from 'recompose/withState';

import Button, { ButtonsBar } from 'app/common/ui/Button';
import RadioButton, { RadioButtonGroup } from 'app/common/ui/RadioButton';
import type { PassSendToType, ActiveType } from 'app/actions/employees/employee/settings';
import { popup } from 'app/common/actions/popup';

import {
  PASS_SEND_TO_FILE,
  PASS_SEND_TO_EMAIL,
  PASS_SEND_TO_SMS,
  employeeSettingsFetch,
} from 'app/actions/employees/employee/settings';

import { activate } from 'app/actions/employees/employee/settings/activate';

import styles from './ActivateForm.scss';


const labels = {
  [PASS_SEND_TO_FILE]: 'В файл',
  [PASS_SEND_TO_EMAIL]: 'E-mail',
  [PASS_SEND_TO_SMS]: 'SMS',
};

type HOCProps = {
  id: string,
  onClose: (?SyntheticEvent<HTMLElement>) => void,
  act: ActiveType,
  sendTo: string,
  setSubmitting: (a: boolean) => void,
  setSendTo: (a: string) => void,
}

const enhance = compose(
  withState('sendTo', 'setSendTo', PASS_SEND_TO_FILE),
  withState('submitting', 'setSubmitting', false),

  connect(
    state => ({
      passwordSendOptions: state.employees.employee.settings.settings.passwordSendOptions,
    }),
    (dispatch, { id, setSendTo, setSubmitting, onClose, act, sendTo }: HOCProps) => ({
      handleSendTo: (e) => {
        const { value } = e.currentTarget;
        setSendTo(value);
      },
      handleActivate: async (e) => {
        e.preventDefault();
        setSubmitting(true);
        const res = await dispatch(activate(id, sendTo, act)); // ошибка во 2м аргументе?
        setSubmitting(false);
        if (res) {
          onClose();
          await dispatch(employeeSettingsFetch(id));
          const addText = (sendTo === PASS_SEND_TO_FILE
            ? '. Файл в папке загрузок вашего браузера'
            : ''
          );
          dispatch(popup(act === 'activate'
            ? `Активация прошла успешно${addText}`
            : `Обновление прошло успешно${addText}`));
        }
      },
    }),
  ),
);

type Props = {
  passwordSendOptions: Array<PassSendToType>,
  onClose: (e: SyntheticEvent<HTMLElement>) => void,
  comment?: string,
  sendTo: PassSendToType,
  handleSendTo: (e: SyntheticEvent<HTMLElement>) => void,
  handleActivate: (e: SyntheticEvent<HTMLElement>) => void,
  act: string,
  submitting: boolean,
}

const ActivateForm = ({
  passwordSendOptions,
  onClose,
  comment,
  sendTo,
  handleSendTo,
  handleActivate,
  act,
  submitting,
}: Props) => (<form className={styles.root} onSubmit={handleActivate}>
  {(passwordSendOptions.length > 1)
    ? <div>
      <div className={styles.title}>
        {act === 'activate'
          ? 'Каким образом уведомить об активации?'
          : 'Каким образом уведомить об обновлении?'}
      </div>
      <RadioButtonGroup
        name="notificationType"
        valueSelected={sendTo}
        onChange={handleSendTo}
        className={styles.radios}
      >
        {[PASS_SEND_TO_SMS, PASS_SEND_TO_EMAIL, PASS_SEND_TO_FILE]
          .filter(name => passwordSendOptions.includes(name))
          .map(name => (
            <RadioButton
              key={name}
              label={labels[name]}
              value={name}
              disabled={submitting}
            />
          ))}
      </RadioButtonGroup>
    </div>
    : <div className={styles.title}>
      {act === 'activate'
        ? 'Уведомить об активации распечатав файл'
        : 'Уведомить об обновлении распечатав файл'}
    </div>}

  {comment && <div className={styles.comment}>{comment}</div>}

  <ButtonsBar middle className={styles.buttons}>
    <Button outline onClick={onClose}>{'Отмена'}</Button>
    <Button submit disabled={submitting}>
      {submitting
        ? `${act === 'activate' ? 'Активация...' : 'Обновление...'}`
        : `${act === 'activate' ? 'Активировать' : 'Обновить'}`}
    </Button>
  </ButtonsBar>
</form>);

export default enhance(ActivateForm);
