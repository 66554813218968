// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';

import PlainModal, { PlainModalContent } from 'app/common/ui-next/plain-modal';
import PlainButton from 'app/common/ui-next/plain-button';

import Indicator from './indicator';
import styles from './default-progress-modal.scss';


type Props = {
  isError?: boolean,
  progress: number,
  message?: string,
  messageError?: string,
  onCancel?: () => void,
}

const DefaultProgressModal = ({
  progress,
  isError,
  message = 'Подождите,\nидет формирование отчета',
  messageError = 'Ошибка\nпри формировании отчета',
  onCancel,
}: Props) => {
  return (
    <PlainModal show>
      <PlainModalContent className={styles.content}>

        {!isError
          ? <div className={styles.contentInfo}>
            {message.split('\n').map((str, i) => (<Fragment key={String(i)}>
              {str}
              <br />
            </Fragment>))}
          </div>
          : <div className={classNames(styles.contentInfo, styles.contentInfo__error)}>
            {messageError.split('\n').map((str, i) => (<Fragment key={String(i)}>
              {str}
              <br />
            </Fragment>))}
          </div>}

        <Indicator progress={progress} />
        <PlainButton
          ariaLabel={`Отмена - прогресс ${progress}%`}
          disabled={!onCancel}
          onClick={onCancel}
          className={styles.cancelButton}
          outline
        >
          {'Отмена'}
        </PlainButton>

      </PlainModalContent>
    </PlainModal>
  );
};

export default DefaultProgressModal;
