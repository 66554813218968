import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import map from 'ramda/es/map';
import evolve from 'ramda/es/evolve';
import values from 'ramda/es/values';
import pipe from 'ramda/es/pipe';
import split from 'ramda/es/split';

import { getFIO } from 'app/common/lib/names';
import { employeeHistoryFetch, employeeHistoryClear } from 'app/actions/employees/employee/settings/history';
import withPaging from 'app/common/containers/withPaging';
import withShadows from 'app/common/containers/withShadows';


const selectHistory = createSelector(
  [
    state => state.employees.employee.history.changes,
  ],
  pipe(
    values,
    map(evolve({
      created: date => moment(date).format('DD.MM.YYYY HH:mm'),
      description: split('\n'),
    })),
  ),
);

export default compose(
  connect(
    (state, props) => ({
      title: `История изменений — ${getFIO(state.employees.employee.fields.name)}`,
      items: selectHistory(state, props),
      total: state.employees.employee.history.changesCount,
      rows: state.settings.history.rows,
    }),
  ),

  withState('loading', 'setLoading', true),

  withPaging(),
  // setCurrentPage,
  // handlePage,
  // currentPage

  lifecycle({
    async componentDidMount() {
      const { id, dispatch, rows, setLoading } = this.props;
      await dispatch(employeeHistoryFetch(id, rows));
      setLoading(false);
    },
    async componentDidUpdate(prevProps) {
      const { id, currentPage, dispatch, rows, setCurrentPage, setLoading } = this.props;
      if (rows !== prevProps.rows) {
        setCurrentPage(1);
        return;
      }
      if (currentPage !== prevProps.currentPage) {
        setLoading(true);
        await dispatch(employeeHistoryFetch(id, rows, (currentPage - 1) * rows));
        setLoading(false);
      }
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(employeeHistoryClear());
    },
  }),

  withShadows,
);
