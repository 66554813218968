// @flow
import React, { useCallback, useRef } from 'react';
import classNames from 'classnames/bind';

import Tr, { type TrProps } from './tr';
import styles from './tr-clickable.scss';


const cx = classNames.bind(styles);

type Props = {
  onClick?: (id: string, e: SyntheticEvent<HTMLElement>) => void,
  id: string,
  disabled?: boolean,
  className?: string,
} & TrProps

const TrClickable = ({ children, id, onClick, className, disabled, ...props }: Props) => {
  const rowNodeRef = useRef(null);

  const handleClick = useCallback((e: SyntheticMouseEvent<HTMLElement>) => {
    if (disabled) return;
    const { clientX, clientY } = e;
    if (rowNodeRef.current && clientX !== 0 && clientY !== 0) {
      rowNodeRef.current.blur();
    }

    if (onClick) {
      onClick(id, e);
    }
  }, [id, onClick, disabled]);

  const handleDoKeyAction = useCallback((e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (disabled) return;
    const whichKey = e.key;
    if (onClick && (whichKey === 'Enter' || whichKey === ' ')) {
      onClick(id, e);
    }
  }, [id, onClick, disabled]);

  return (
    <Tr
      className={cx(styles.root, { disabled }, className)}
      role="button"
      tabIndex={disabled ? '-1' : '0'}
      onClick={handleClick}
      onKeyUp={handleDoKeyAction}
      reference={rowNodeRef}
      {...props}
    >
      {children}
    </Tr>
  );
};

export default TrClickable;
