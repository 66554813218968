
import { AccrualCampFor } from 'app/dataTypes/subsidy/settings'

import { controls } from './formParts/AccuralForFormCamp'


export function extractCampAccrualFor(formData: FormData): AccrualCampFor {
  return {
    camp: [
      !!formData.get(`${controls.camp}1`),
      !!formData.get(`${controls.camp}2`),
    ],
  }
}
