import React from 'react';

const IconLoaderClock = props => (<svg viewBox="0 0 32 32" width="32px" height="32px" {...props}>
  <circle cx="16" cy="16" r="15" />
  <line stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" x1="16" y1="16" x2="16" y2="7.263">
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      from="0 16 16"
      to="360 16 16"
      dur="0.5s"
      repeatCount="indefinite"
    />
  </line>
  <line stroke="#FFFFFF" strokeWidth="4" strokeLinecap="round" strokeMiterlimit="10" x1="16" y1="16" x2="22" y2="16">
    <animateTransform
      attributeName="transform"
      attributeType="XML"
      type="rotate"
      from="0 16 16"
      to="360 16 16"
      dur="2s"
      repeatCount="indefinite"
    />
  </line>
</svg>);

export default IconLoaderClock;
