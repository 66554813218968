import { createPath } from '../routePaths'
import { getParam } from '../routeProps'


export enum SectionRoute {
  Accruals = 'accruals',
  Statements = 'statements',
  Recipients = 'recipients',
  Settings = 'settings'
}

export const isSectionRoute = (val: any): val is SectionRoute => {
  return Object.values(SectionRoute).includes(val)
}

export const pathMask = '/compensation/:section'

type Options = {
  section?: SectionRoute
}

export const getURL = (options: Options = {} as Options) => {
  return createPath(options, pathMask)
}

export const getSection = (props: any) => {
  const section = getParam('section', props)
  if (isSectionRoute(section)) return section
  return null
}
