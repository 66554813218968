import get from 'app/common/lib/get';
import { setToken } from 'app/common/lib/token';
import { popup } from 'app/common/actions/popup';
import api from 'app/actions/api';
import i18n from 'app/i18n';

const WRONG_PASSWORD = 'wrong current password';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const PROFILE_CLEAR_KEY = 'PROFILE_CLEAR_KEY';
export const PROFILE_RESET = 'PROFILE_RESET';


const changePasswordRequest = () => ({
  type: CHANGE_PASSWORD_REQUEST,
});

const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

const changePasswordFailure = payload => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
});

export const profileClearKey = payload => ({
  type: PROFILE_CLEAR_KEY,
  payload,
});

export const lockedTimeToProfile = () => {
  const lockedUntil = parseInt(localStorage.lockedUntil, 10) || 0;
  return ({
    type: PROFILE_RESET,
    payload: { lockedUntil },
  });
};

export const changePasswordProcess = (oldPass, newPass, cb) => (
  async (dispatch) => {
    dispatch(changePasswordRequest());

    try {
      const res = await api('account/password', 'post', {
        data: { current_password: oldPass, password: newPass },
      });
      dispatch(changePasswordSuccess());
      setToken(res.data.token);
      dispatch(popup(i18n.t('profile:msg_password_changed_success')));
      if (typeof cb === 'function') {
        setTimeout(cb, 50);
      }
    } catch (error) {
      const message = get(error, 'response.data.message', '');
      dispatch(changePasswordFailure({
        err: i18n.t('profile:error_password_change_failed'),
        oldPassError: message === WRONG_PASSWORD ? i18n.t('profile:error_current_password') : '',
        error,
      }));
    }
  }
);

export const changeTemporaryPassword = password => (
  async (dispatch) => {
    dispatch(changePasswordRequest());

    try {
      const res = await api('auth/password', 'post', { data: { password } });
      dispatch(changePasswordSuccess());
      setToken(res.data.token);
      dispatch(popup(i18n.t('profile:msg_password_changed_success')));
    } catch (error) {
      dispatch(changePasswordFailure({ err: i18n.t('profile:error_password_change_failed'), error }));
    }
  }
);
