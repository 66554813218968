import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import type { HOC } from 'recompose';

import { dishesSearch } from 'app/actions/dishes/filter';
import { listedit } from 'app/actions/orgs/dishes/listedit';
import { popup } from 'app/common/actions/popup';
import { dishesNeedUpdate } from 'app/actions/orgs/dishes/options';

type Props = {
  returnTo: string,
  orgId: string,
  categoryId: string,
}

const enhanceEditList: HOC<*, Props> = compose(
  withRouter,

  connect(
    state => ({
      addList: state.orgs.dishes.listedit.add,
      removeList: state.orgs.dishes.listedit.remove,
      searchString: state.dishes.filter.searchString,
    }),
  ),

  withState('sending', 'setSending', false),

  withHandlers({
    handleSearch: ({ dispatch }) => (e) => {
      dispatch(dishesSearch(e.currentTarget.value));
    },
    handleClearSearch: ({ dispatch }) => (e) => {
      e.preventDefault();
      dispatch(dishesSearch(''));
    },
    handleSubmit: ({
      dispatch,
      orgId,
      categoryId,
      addList,
      removeList,
      setSending,
      history,
      returnTo,
    }) => async (e) => {
      e.preventDefault();
      setSending(true);
      const res = await dispatch(listedit(orgId, categoryId, addList, removeList));
      history.push(returnTo);
      setSending(false);
      if (res) {
        dispatch(dishesNeedUpdate(true));
        dispatch(popup('Изменения успешно применены'));
      }
    },
  }),
);

export default enhanceEditList;
