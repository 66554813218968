import { useState, useCallback } from 'react'

import {
  isEmpty,
  pipe,
  values,
  any,
  identity,
  hasPath,
  assocPath,
  evolve,
  when,
  propEq,
  omit,
  always,
  map,
} from 'ramda'

import { api } from 'app/api'
import usePopup from 'app/common/hooks/usePopup'

import {
  GET_RESTRICTIONS,
  PUT_RESTRICTIONS,
  defaultRestrictionsResult,
  type GetRestrictionsResult,
} from 'app/common/api/requests/food/complexes/restrictions'


const anySelected: (o: { [key: string]: boolean }) => boolean = pipe(values, any(identity))

export type Restrictions = {
  // ...GetRestrictionsResult,
  for_students: boolean, // root of students branch
  allowances: boolean,
  for_allowances_camp: Array<string>,
  for_parallels: boolean,
  for_food_modes: boolean,
  for_day_cares: boolean,
  for_ages: boolean,
  for_camps: boolean,
  for_shifts: boolean,
} & GetRestrictionsResult

const defaultRestrictions: Restrictions = {
  ...defaultRestrictionsResult,
  for_students: true, // для отображения чекбокса "Для учеников"
  allowances: true, // чекбокс "имеющих льготы"
  for_allowances_camp: [],
  for_parallels: false, // чекбокс "из параллели"
  for_food_modes: false, // чекбокс "с режимом питания"
  for_day_cares: false, // чекбокс "из групп продленного дня"
  for_ages: false, // чекбокс "возраста"
  for_camps: false, // чекбокс "из групп лагеря"
  for_shifts: true,
}

type State = {
  restrictions: Restrictions,
  loading: boolean,
  saving: boolean,
}

type Actions = {
  request: () => Promise<void>
  change: (key: string, checked: boolean) => void
  updateAllowances: (updateFunc: (a: Array<string>) => Array<string>) => void
  submit: () => Promise<boolean>
}

type Options = {
  orgId?: number,
  complexId?: number,
  dishId?: number,
  onSubmitSuccess?: () => void,
}

export default function useRestrictions({
  orgId,
  complexId,
  dishId,
  onSubmitSuccess,
}: Options): [State, Actions] {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const popup = usePopup()
  const [restrictions, setRestrictions] = useState<Restrictions>(defaultRestrictions)


  const handleRequest = useCallback(async () => {
    setLoading(true)
    const res: GetRestrictionsResult | null = await api.request(GET_RESTRICTIONS, {
      error: 'Не удалось получить данные настроек',
      params: {
        school_id: orgId,
        complex_id: complexId,
        item_id: dishId,
      },
    })

    if (res) {
      setLoading(false)
      const withoutSubsidies = res.for_students_without_subsidies
      const withSubsidies = !isEmpty(res.for_allowances || [])
      const withParallels = anySelected(res.parallels)
      const withFoodModes = anySelected(res.food_modes)
      const withDayCares = anySelected(res.day_cares)
      const withAges = anySelected(res.ages)
      const withCamps = anySelected(res.camps)
      const withShifts = anySelected(res.shifts)

      setRestrictions({
        ...res,
        for_students: (
          withoutSubsidies
          || withSubsidies
          || withParallels
          || withFoodModes
          || withDayCares
          || withAges
          || withCamps
        ),
        allowances: withSubsidies,
        for_allowances_camp: res.for_allowances || [],
        for_parallels: withParallels,
        for_food_modes: withFoodModes,
        for_day_cares: withDayCares,
        for_ages: withAges,
        for_camps: withCamps,
        for_shifts: withShifts,
      })
    }
  }, [orgId, complexId, dishId])

  const handleChange = useCallback((key: string, checked: boolean) => {
    const path = key.split('.')
    if (hasPath(path, restrictions)) {
      setRestrictions(assocPath(path, checked))
    }
  }, [restrictions])

  const handleUpdateAllowances = useCallback((update: (a: Array<string>) => Array<string>) => {
    setRestrictions(evolve({ for_allowances: update }))
  }, [])


  const handleSubmit = useCallback(async () => {
    setSaving(true)
    const res = await api.request(PUT_RESTRICTIONS, {
      error: 'Не удалось сохранить данные настроек',
      params: {
        school_id: orgId,
        complex_id: complexId,
        item_id: dishId,
        // @ts-ignore
        restrictions: pipe(
          // @ts-ignore
          when(propEq('for_students', false), evolve({
            for_allowances: always([]),
            for_students_without_subsidies: always(false),
            parallels: map(always(false)),
            food_modes: map(always(false)),
            day_cares: map(always(false)),
            ages: map(always(false)),
            camps: map(always(false)),
            shifts: map(always(false)),
          })),
          when(propEq('allowances', false), evolve<any>({ for_allowances: always([]) })),
          when(propEq('for_parallels', false), evolve<any>({ parallels: map(always(false)) })),
          when(propEq('for_food_modes', false), evolve<any>({ food_modes: map(always(false)) })),
          when(propEq('for_day_cares', false), evolve<any>({ day_cares: map(always(false)) })),
          when(propEq('for_ages', false), evolve<any>({ ages: map(always(false)) })),
          when(propEq('for_camps', false), evolve<any>({ camps: map(always(false)) })),
          when(propEq('for_shifts', false), evolve<any>({ shifts: map(always(false)) })),
          omit([
            'for_students',
            'allowances',
            'for_allowances_camp',
            'for_parallels',
            'for_food_modes',
            'for_day_cares',
            'for_ages',
            'for_camps',
            'for_shifts',
          ]),
        )(restrictions),
      },
    })
    setSaving(false)

    if (res === 'success') {
      popup('Настройки успешно сохранены')
      if (!onSubmitSuccess) {
        return true
      }
      onSubmitSuccess()
    }
    return false
  }, [orgId, complexId, dishId, popup, restrictions, onSubmitSuccess])


  return [{
    restrictions,
    loading,
    saving,
  }, {
    request: handleRequest,
    change: handleChange,
    updateAllowances: handleUpdateAllowances,
    submit: handleSubmit,
  }]
}
