import keys from 'ramda/es/keys';

import api from 'app/actions/api';
import { popup } from 'app/common/actions/popup';
import { dishesNeedUpdate } from './options';
/**
* Выделение/выбор элементов в разделе блюд школы
*/
export const DISHES_ADD_TO_SELECTION = 'orgs/DISHES_ADD_TO_SELECTION';
export const DISHES_REMOVE_FROM_SELECTION = 'orgs/DISHES_REMOVE_FROM_SELECTION';
export const DISHES_CLEAR_SELECTION = 'orgs/DISHES_CLEAR_SELECTION';
export const DISHES_SELECT = 'orgs/DISHES_SELECT';

export const dishesAddToSelection = payload => ({
  type: DISHES_ADD_TO_SELECTION,
  payload,
});

export const dishesRemoveFromSelection = payload => ({
  type: DISHES_REMOVE_FROM_SELECTION,
  payload,
});

export const dishesClearSelection = () => ({
  type: DISHES_CLEAR_SELECTION,
});

export const dishesSelect = payload => ({
  type: DISHES_SELECT,
  payload,
});

/**
* Удаление блюд (!) из школы
*/

export const DISHES_REMOVE_ITEMS_REQUEST = 'orgs/DISHES_REMOVE_ITEMS_REQUEST';
export const DISHES_REMOVE_ITEMS_SUCCESS = 'orgs/DISHES_REMOVE_ITEMS_SUCCESS';
export const DISHES_REMOVE_ITEMS_FAILURE = 'orgs/DISHES_REMOVE_ITEMS_FAILURE';

const dishesRemoveItemsRequest = payload => ({
  type: DISHES_REMOVE_ITEMS_REQUEST,
  payload,
});

const dishesRemoveItemsSuccess = payload => ({
  type: DISHES_REMOVE_ITEMS_SUCCESS,
  payload,
});

const dishesRemoveItemsFailure = payload => ({
  type: DISHES_REMOVE_ITEMS_FAILURE,
  payload,
});


export const dishesRemoveItems = (orgId, someItems) => async (dispatch, getState) => {
  if (someItems) {
    if (someItems instanceof Array) {
      someItems.forEach(item => dispatch(dishesAddToSelection(item)));
    } else {
      dispatch(dishesAddToSelection(someItems));
    }
  }
  const state = getState();
  const items = someItems || state.orgs.dishes.selection.items;
  const needUpdate = items.length >= keys(state.orgs.dishes.items).length;
  dispatch(dishesRemoveItemsRequest({ items, orgId }));
  try {
    const data = { remove_dishes: items, school_id: orgId };
    await api('food/menu/items_add_remove', 'post', { data });
    if (needUpdate) {
      dispatch(dishesNeedUpdate(true));
    }
    dispatch(dishesRemoveItemsSuccess(items));
    dispatch(popup(items.length > 1
      ? `Блюда (${items.length}) успешно удалены из списка школы`
      : 'Блюдо успешно удалено из списка школы'));
  } catch (error) {
    dispatch(dishesRemoveItemsFailure({ err: 'Удаление элементов не удалось', error }));
  }
};


/**
* Пeренос элементов в другую категорию
*/

export const DISHES_MOVE_ITEMS_REQUEST = 'orgs/DISHES_MOVE_ITEMS_REQUEST';
export const DISHES_MOVE_ITEMS_SUCCESS = 'orgs/DISHES_MOVE_ITEMS_SUCCESS';
export const DISHES_MOVE_ITEMS_FAILURE = 'orgs/DISHES_MOVE_ITEMS_FAILURE';

const dishesMoveItemsRequest = payload => ({
  type: DISHES_MOVE_ITEMS_REQUEST,
  payload,
});

const dishesMoveItemsSuccess = payload => ({
  type: DISHES_MOVE_ITEMS_SUCCESS,
  payload,
});

const dishesMoveItemsFailure = payload => ({
  type: DISHES_MOVE_ITEMS_FAILURE,
  payload,
});

export const dishesMoveItems = (orgId, categoryId, items) => async (dispatch) => {
  dispatch(dishesMoveItemsRequest());
  try {
    const dataToSend = {
      school_id: orgId,
      category_id: categoryId, // целевая категория
      dishes: items, // id перемещаемых блюд
    };
    await api('food/menu/move_items_to_category', 'post', { data: dataToSend });
    dispatch(dishesMoveItemsSuccess(items));
    dispatch(popup(items.length > 1
      ? `Блюда (${items.length}) успешно перенесены`
      : 'Блюдо успешно перенесено'));
  } catch (error) {
    dispatch(dishesMoveItemsFailure({ err: 'Перенос блюд не удался', error }));
  }
};


// /**
// * Копирование элементов
// */
//
// export const DISHES_COPY_ITEMS_REQUEST = 'orgs/DISHES_COPY_ITEMS_REQUEST';
// export const DISHES_COPY_ITEMS_SUCCESS = 'orgs/DISHES_COPY_ITEMS_SUCCESS';
// export const DISHES_COPY_ITEMS_FAILURE = 'orgs/DISHES_COPY_ITEMS_FAILURE';
//
// const dishesCopyItemsRequest = () => ({
//   type: DISHES_COPY_ITEMS_REQUEST,
// });
//
// const dishesCopyItemsSuccess = payload => ({
//   type: DISHES_COPY_ITEMS_SUCCESS,
//   payload,
// });
//
// const dishesCopyItemsFailure = payload => ({
//   type: DISHES_COPY_ITEMS_FAILURE,
//   payload,
// });
//
// export const dishesCopyItems = () => async (dispatch, getState) => {
//   const dishes = getState().dishes;
//   dispatch(dishesCopyItemsRequest());
//   try {
//     const items = dishes.selection.items;
//     const { data } = await api('food/items/copy', 'post', { data: { items } });
//     dispatch(dishesCopyItemsSuccess(data));
//     dispatch(popup(items.length > 1
//       ? `Блюда (${items.length}) успешно скопированы`
//       : 'Блюдо успешно скопировано'));
//   } catch (error) {
//     dispatch(dishesCopyItemsFailure({ err: 'Копирование блюд не удалось', error }));
//   }
// };
