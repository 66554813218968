// @flow
import { useState, useCallback } from 'react';

import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';


const defaultState: MachineSingle = {
  id: 0,
  title: '',
  number: '',
  modelId: 0,
  orgId: 0,
  versionId: 0,
  placeId: '',
};

type FormActions = {
  update: ((MachineSingle => MachineSingle) | MachineSingle) => void,
  clear: () => void,
  fillFromData: () => void,
}
export type MachineForm = [MachineSingle, FormActions]
export const defaultMachineForm: MachineForm = [defaultState, {
  update: () => {},
  clear: () => {},
  fillFromData: () => {},
}];

export default function useMachineForm(single: MachineSingle): MachineForm {
  const [state, setState] = useState(defaultState);

  const clear = useCallback(() => {
    setState(defaultState);
  }, []);

  const fillFromData = useCallback(() => {
    setState(single);
  }, [single]);

  return [state, {
    update: setState,
    clear,
    fillFromData,
  }];
}
