// @flow
import React, { useCallback } from 'react';
import moment from 'moment-timezone';
import { Redirect } from 'react-router';

import OrgsMenu, { dataKey } from 'app/components/OrgsList/OrgsMenu';
import { withMenuMenuRights } from 'app/containers/rights';
import config from 'app/config';

import MustEnableEMoney from '../MustEnableEMoney';
import OrgsPageTemplateOld from '../OrgsPageTemplateOld';
import AccessDenied from '../AccessDenied';
import getBaseUrl from '../getBaseUrl';


export { dataKey };

type Props = {
  match: {
    params: {
      orgId: string,
      date?: string,
      sellPointId?: string,
      act?: string,
    },
  },
  readAccess: boolean,
  history: {
    push: (string) => void,
  }
}

const OrgsMenuPage = ({ match, readAccess, history }: Props) => {
  const { params: { orgId, date, sellPointId, act } } = match;
  const mDate = moment(date);
  const dateReady = date && mDate.isValid() ? date : null;
  const today = moment().format(config.dateFormat);

  const baseUrl = getBaseUrl({ orgId, section: dataKey });
  const redirectUrl = `${baseUrl}/${today}`;

  const redirectToDate = useCallback((day: string, format: string) => {
    const toDate = moment(day, format).format(config.dateFormat);
    history.push(sellPointId ? `${baseUrl}/${toDate}/${sellPointId}` : `${baseUrl}/${toDate}`);
  }, [baseUrl, history, sellPointId]);

  if (!readAccess) {
    return (
      <AccessDenied />
    );
  }

  if (!dateReady) {
    return (
      <Redirect to={redirectUrl} />
    );
  }

  return (
    <OrgsPageTemplateOld orgID={orgId} date={date}>
      <MustEnableEMoney>
        <OrgsMenu
          pathname={window.location.pathname}
          orgId={orgId}
          date={date}
          dateFormat={config.dateFormat}
          mDate={mDate}
          sellPointId={sellPointId}
          act={act}
          basePath={baseUrl}
          redirectToDate={redirectToDate}
        />
      </MustEnableEMoney>
    </OrgsPageTemplateOld>
  );
};

export default withMenuMenuRights(OrgsMenuPage);
