// @flow
import { useCallback, useRef } from 'react';

import { extractIndexByBase } from './helpers';


/**
 *  DEPRECATED use useHoverByClass (использование классов стабильнее при обновлении компонентов, не нужно полностью перемонтировать)
 *
 *  Подсаетка при наведении строк составной таблицы (несколько рядом росположенных таблиц)
 *  Поддерживает перманентно выделенные строки (currentSelection)
 *
 *  refTableBody надо навесить на <body> всех таблиц и указать количество их (numberOfChunks)
 *  и добавить handleMouseEnter, handleMouseLeave на события строк (Tr) таблицы
 *  и добавить класс 'index_N' к Tr элементам, где N номер строки
 */

type Props = {
  numberOfChunks?: number,
  currentSelection?: Array<boolean>,
  classNameBase?: string,
} | void

export default function useHover(props: Props) {
  const {
    numberOfChunks = 2,
    currentSelection = [],
    classNameBase = 'index_',
  } = props || {};

  const rowElements = useRef<Array<HTMLElement | null>>([]);

  // сохранение nodes таблиц
  const refTableBody = useCallback((node: ?HTMLElement) => {
    if (!node) {
      rowElements.current = [];
      return;
    }

    if (rowElements.current.length < numberOfChunks) {
      rowElements.current.push(node);
    }
  }, [numberOfChunks]);


  const handleMouseEnter = useCallback((e: SyntheticMouseEvent<HTMLElement>) => {
    const rowIndex = extractIndexByBase(classNameBase, e.currentTarget.className);
    if (rowIndex === null) {
      console.error('Не удалось узнать индекс строки таблицы (установите класс "index_N" у Tr элементов)');
      return;
    }

    for (let i = 0, l = rowElements.current.length; i < l; i++) {
      if (rowElements.current[i]) {
        rowElements.current[i].children[rowIndex].classList.add('hover');
      }
    }
  }, [classNameBase]);


  const handleMouseLeave = useCallback((e: SyntheticMouseEvent<HTMLElement>) => {
    const rowIndex = extractIndexByBase(classNameBase, e.currentTarget.className);
    if (rowIndex === null) {
      console.error('Не удалось узнать индекс строки таблицы (установите класс "index_N" у Tr элементов)');
      return;
    }

    for (let i = 0, l = rowElements.current.length; i < l; i++) {
      if (rowElements.current[i] && !currentSelection[rowIndex]) {
        rowElements.current[i].children[rowIndex].classList.remove('hover');
      }
    }
  }, [currentSelection, classNameBase]);


  return [refTableBody, handleMouseEnter, handleMouseLeave];
}
