// @flow
import { useState, useCallback, useMemo } from 'react';
import reduce from 'ramda/es/reduce';

import { api } from 'app/api';
import { GET_VENDING_MACHINES_VERSIONS, convertResult } from 'app/common/api/requests/data/vending/machines/versions/get';
import type { MachinesVersion } from 'app/dataTypes/vending/MachinesVersion.types';


export type VersionsState = {
  [key: string]: MachinesVersion,
}
const defaultState: VersionsState = {};

export type Versions = [VersionsState, boolean, () => void | Promise<void>]
export const defaultVersions: Versions = [defaultState, true, () => {}];


export default function useModels(): Versions {
  const [versions, setVersions] = useState<VersionsState>(defaultState);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_VENDING_MACHINES_VERSIONS, {
      error: 'Не удалось получить версии вендинговых аппаратов',
      convertions: {
        convertResult,
      },
    });

    if (res) {
      setVersions(reduce((acc, { id, ...others }) => {
        acc[id] = { id, ...others };
        return acc;
      }, {}, res));
    }
    setLoading(false);
  }, []);

  const res: Versions = useMemo(() => [
    versions,
    loading,
    request,
  ], [versions, loading, request]);

  return res;
}
