import lifecycle from 'recompose/lifecycle';


export const resetOnStatusChange = (props, prevProps) => {
  const { resetStatus, reset } = props;
  if (typeof reset !== 'function') return;
  if (resetStatus !== prevProps.resetStatus && resetStatus) {
    reset();
  }
};

export default lifecycle({
  componentDidUpdate(prevProps) {
    resetOnStatusChange(this.props, prevProps);
  },
});
