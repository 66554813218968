// @flow
import { useCallback, useState, useEffect } from 'react';
import path from 'ramda/es/path';

import { api } from 'app/api';
import { GET_EMPLOYEES } from 'app/common/api/requests/food/employees';


export default function useEmployeeData(personId: number): {
  loading: boolean,
  modified: boolean,
  resetModified: () => void,
  selectedRoleId: number | null,
  changeSelectedRoleId: ({ id: number }) => void,
} {
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(true);

  const requestData = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_EMPLOYEES, {
      error: 'Не удалось получить данные поставщика питания',
      params: {
        id: personId,
      },
    });

    if (res) {
      setModified(false);
      const emp = path(['employees', String(personId)], res);
      const { role_id: roleId } = emp || {};
      setSelectedRoleId(roleId || null);
    }
    setLoading(false);
  }, [personId]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const changeSelectedRoleId = useCallback(({ id }: { id: number }) => {
    setSelectedRoleId(id);
    setModified(true);
  }, []);

  const resetModified = useCallback(() => {
    setModified(false);
  }, []);


  return {
    loading,
    modified,
    resetModified,
    selectedRoleId,
    changeSelectedRoleId,
  };
}
