// @flow
export const DATE_FILTER_SET = 'reports/DATE_FILTER_SET';
export const DATE_FILTER_CLEAR = 'reports/DATE_FILTER_CLEAR';
export const dateFormat = 'YYYY-MM-DD';

export const dateFilterSet = (payload: Object) => ({
  type: DATE_FILTER_SET,
  payload,
});

export const dateFilterClear = () => ({
  type: DATE_FILTER_CLEAR,
});
