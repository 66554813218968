import { forwardRef, ReactNode } from 'react'
import cn from 'classnames/bind'

import { Tr, Td, Th } from 'app/common/ui-components/table'

import type { Columns } from './columns'
import styles from './Row.scss'


const cx = cn.bind(styles)

type Props = {
  columns: Columns
  firstCellClassName: string
  cellContent: (id: string) => ReactNode
  shadowTop?: boolean
  total?: boolean
}

const Row = forwardRef<'tr', Props>(({
  columns,
  firstCellClassName,
  cellContent,
  shadowTop,
  total = false,
}: Props, ref) => {
  return (
    <Tr className={cx({ total, shadowTop })} reference={ref}>
      {columns.contentRow.map(({ id, props }, index) => (
        index
          ? <Td
            key={id}
            {...props}
          >
            {cellContent(id)}
          </Td>
          : <Th
            key={id}
            className={firstCellClassName}
            {...props}
          >
            {cellContent(id)}
          </Th>
      ))}
    </Tr>
  )
})

export default Row
