// @flow
import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from 'app/common/ui/Tooltip';
import useBlurOnMouseEvent from 'app/common/hooks/useBlurOnMouseEvent';

import ModulesSwitcherIcon from './modules-switcher-icon';
import { moduleTypes } from '../../identifiers-data';
import styles from './modules-switcher-item.scss';


const cx = classNames.bind(styles);

type Props = {
  id: string,
  active: boolean,
  type: $Values<typeof moduleTypes>,
  disabled?: boolean,
  onChange: (type: $Values<typeof moduleTypes>, checked: boolean) => void,
}

const ModulesSwitcherItem = ({ active, type, id, disabled, onChange }: Props) => {
  const { t } = useTranslation('identifiers-next');
  const [checkboxRef, handleClick] = useBlurOnMouseEvent();

  const module = t(`identifier_module_info_${type}`);
  const info = t(
    active
      ? 'identifier_module_info_switched_on'
      : 'identifier_module_info_switched_off',
    { module },
  );

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    onChange(type, checked);
  }, [onChange, type]);

  return (
    <Tooltip text={info} className={styles.tooltip}>
      <label htmlFor={id} className={cx(styles.root, { disabled })}>
        <input
          id={id}
          type="checkbox"
          className={styles.input}
          checked={active}
          onChange={handleChange}
          onClick={handleClick}
          disabled={disabled}
          ref={checkboxRef}
        />
        <ModulesSwitcherIcon
          type={type}
          className={cx(styles.icon, { active })}
        />
        <span className={styles.info}>{info}</span>
      </label>
    </Tooltip>
  );
};

export default ModulesSwitcherItem;
