import { ChangeEvent, useCallback } from 'react'
import cn from 'classnames'

import LineInput from 'app/common/ui/LineInput'
import PlainRadioButton from 'app/common/ui-next/plain-radio-button'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { priceRe } from 'app/common/constants/regex'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import styles from './FixedAllocationControls.scss'


const getValueView = (value: string | null, disabled: boolean) => {
  if (value === null || value === '0') {
    if (disabled) return 'расчетный'
    return ''
  }
  return value
}

type Props = {
  groupID: string
  partName: string
  partRadioSuffix?: string
  partFieldSuffix?: string
  label: string
  placeholder: string
  focusedLabel: string
  disabled?: boolean
  currency: string
  checked1: boolean
  checked2: boolean
  onRadioClick1: (fullName: string) => void
  onRadioClick2: (fullName: string) => void
  value1: string | null
  value2: string | null
  onChange: (value: string | null, fieldName: string) => void
  error1?: string
  error2?: string
}

const FixedAllocationControls = ({
  groupID,
  partName,
  partRadioSuffix = 'fixed',
  partFieldSuffix = 'sum',
  label,
  placeholder,
  focusedLabel,
  disabled,
  currency,
  checked1,
  checked2,
  onRadioClick1,
  onRadioClick2,
  value1,
  value2,
  onChange,
  error1,
  error2,
}: Props) => {
  const onRadioClick1Ref = useCallbackRef(onRadioClick1)
  const onRadioClick2Ref = useCallbackRef(onRadioClick2)
  const onChangeRef = useCallbackRef(onChange)

  const radioName = `${partName}_${partRadioSuffix}_${groupID}`
  const radioValue1 = `${partName}1_${partRadioSuffix}_${groupID}`
  const radioValue2 = `${partName}2_${partRadioSuffix}_${groupID}`
  const fieldName1 = `${partName}1_${partFieldSuffix}_${groupID}`
  const fieldName2 = `${partName}2_${partFieldSuffix}_${groupID}`

  const value1View = getValueView(value1, checked2)
  const value2View = getValueView(value2, checked1)

  const handleRadioClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget
    if (checked) {
      const count = parseInt(value.replace(/^\D+/, ''), 10)
      if (count === 1) onRadioClick1Ref.current?.(`${partName}1_${partRadioSuffix}`)
      if (count === 2) onRadioClick2Ref.current?.(`${partName}2_${partRadioSuffix}`)
    }
  }, [onRadioClick1Ref, onRadioClick2Ref, partName, partRadioSuffix])

  const handleChangeField = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget

    if (priceRe.test(value)) {
      const valueWithDot = value.replace(',', '.')
      const count = parseInt(name.replace(/^\D+/, ''), 10)
      onChangeRef.current?.(valueWithDot, `${partName}${count}_${partFieldSuffix}`)
    }
  }, [onChangeRef, partName, partFieldSuffix])

  return (
    <>
      <div className={cn(styles.root, styles.column3)}>
        <div className={styles.label}>&nbsp;</div>

        <PlainRadioButton
          className={styles.radioFixed}
          label={label}
          checked={checked1}
          name={radioName}
          value={radioValue1}
          onChange={handleRadioClick}
          disabled={disabled}
        />
        <PlainRadioButton
          className={styles.radioFixed}
          label={label}
          checked={checked2}
          name={radioName}
          value={radioValue2}
          onChange={handleRadioClick}
          disabled={disabled}
        />
      </div>

      <div className={cn(styles.column, styles.column4)}>
        <div className={styles.label}>&nbsp;</div>
        <div className={getScrollToErrorClassName(!!(error1 || error2))} />

        <div className={styles.row}>
          <LineInput
            placeholder={placeholder}
            focused={focusedLabel}
            value={value1View}
            name={fieldName1}
            onChange={handleChangeField}
            disabled={disabled || !checked1}
            autoComplete="off"
            isError={!!error1}
            errorText={error1}
          />
          <span className={styles.unit}>{currency}</span>
        </div>

        <div className={styles.row}>
          <LineInput
            placeholder={placeholder}
            focused={focusedLabel}
            value={value2View}
            name={fieldName2}
            onChange={handleChangeField}
            disabled={disabled || !checked2}
            autoComplete="off"
            isError={!!error2}
            errorText={error2}
          />
          <span className={styles.unit}>{currency}</span>
        </div>
      </div>
    </>
  )
}

export default FixedAllocationControls
