// @flow
import React from 'react';

import Checkbox from 'app/common/ui/Checkbox';
import SettingsGear from 'app/common/components/Settings';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';

import { dataKey } from '../dataKey';
import columns from './columns';
import styles from './VendingGear.scss';


const excludeColumns = ['title', 'id', 'place', 'actions'];

const VendingGear = () => (
  <SettingsGear dataKey={dataKey}>
    {({ saveSettings }) => (<div>
      <div className={styles.gearHeader}>{'Столбцы:'}</div>
      {/* Чекбоксы в настройках */}
      {columns.map(({ id, title }) => (
        excludeColumns.includes(id)
          ? null
          : <WithHOC
            component={Checkbox}
            hoc={CheckboxSettings}
            label={title || 'Действия'}
            name={id}
            saveSettings={saveSettings}
            className={styles.gearCheck}
            settingsKey={dataKey}
          />
      ))}
    </div>)}
  </SettingsGear>
);

export default VendingGear;
