// @flow
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';

import type { State, PlainDatePeriodRestProps } from 'app/common/ui-components/filter';
import { getDisabledDaysFunc } from 'app/common/ui-next/plain-date-period';

/**
 *  Props для элемента фильтрации "Период времени" (filterId: 'period')
 */

const filterGetPropsPeriod = (state: State): PlainDatePeriodRestProps => {
  const { year: { items, current } } = state;
  const selectedYearId = prop(0, current);
  const dateStart = path([selectedYearId, 'date_start'], items);
  const dateFinish = path([selectedYearId, 'date_finish'], items);

  const dateDisabledStatus = getDisabledDaysFunc(dateStart, dateFinish);

  return ({
    label: 'Период времени',
    buttonResetLabel: 'Сбросить выбор',
    fromLabel: 'от',
    toLabel: 'до',
    dateDisabledStatus,
    periodLimit: 1,
    periodLimitUnit: 'months',
  });
};

export default filterGetPropsPeriod;
