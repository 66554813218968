export const controls = {
  activeCheckbox: 'active',
  selectionRadio: 'selection',
  percentInput: 'percent',
  complexesList: 'complexes',
  complexesListDefaults: 'complexes_defaults',
} as const

export const selection = {
  complexes: 'complexes',
  percent: 'percent',
} as const
