import {
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS,
  SETTINGS_FAILURE,
} from 'app/common/actions/settings/load';
import { NEED_REFRESH } from 'app/common/actions/settings/current';


const defaultState = {
  needRefresh: false, // требуется обновление (нажатие на кнопку 'обновить' в сообщении при потере соединения)
  loading: true, // процесс загрузки настроек
};

const current = (state = defaultState, action) => {
  if (action.type === NEED_REFRESH) {
    return { ...state, needRefresh: action.payload };
  }
  // процесс загрузки настроек
  if (action.type === SETTINGS_REQUEST) {
    return { ...state, loading: true };
  }
  if (action.type === SETTINGS_SUCCESS || action.type === SETTINGS_FAILURE) {
    return { ...state, loading: false };
  }
  return state;
};

export default current;
