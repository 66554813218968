import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'

import styles from './AccordionItemHeaderActive.scss'


type Props = {
  ID: string
  locked?: boolean
  onCreateFormID: (ID: string) => string
  onCancel: () => void
}

const AccordionItemHeaderActive = ({ ID, locked, onCreateFormID, onCancel }: Props) => {
  const formID = onCreateFormID(ID)
  return (
    <ControlsBar className={styles.root}>
      <PlainButton onClick={onCancel} outline>{'Отмена'}</PlainButton>
      <PlainButton type="submit" disabled={locked} form={formID}>{'Сохранить'}</PlainButton>
    </ControlsBar>
  )
}

export default AccordionItemHeaderActive
