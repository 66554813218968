// @flow
import React from 'react';

import { Tr, Th, Td } from 'app/common/ui-components/table';
import ComplexTooltip from 'app/common/ui-components/complex-tooltip';

import { type ActualItem } from './useActualItems';
import styles from './ItemRow.scss';

/**
 *  Обычная строка таблицы
 */

type Props = {
  currency: string,
  columns: Array<string>,
  ...$Exact<ActualItem>,
}

const ItemRow = ({ title, dishItems, currency, columns, ...others }: Props) => {
  const { sum, cost } = others;
  const rowId = `${title}${sum}`;

  return (
    <Tr>
      {columns.map(key => (key === 'title'

        ? <Th
          key={key}
          id={rowId}
          scope="row"
          alignLeft
        >
          {dishItems
            ? <ComplexTooltip header={title} headerSuffix={`${cost}${currency}`} items={dishItems}>
              <span className={styles.complexTitle}>{title}</span>
            </ComplexTooltip>
            : title}
        </Th>

        : <Td
          key={key}
          headers={`header_${key} ${rowId}`}
          alignRight
        >
          {others[key]}
        </Td>
      ))}
    </Tr>
  );
};

export default ItemRow;
