// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as details } from 'app/components/common/reports/filters/details';
import { flags as org } from 'app/components/common/reports/filters/org';


const groupActivity = (state: State) => {
  return (
    details.detailsChecked(state)
    && org.filterActive(state)
    && org.multipleOrgsSelected(state)
  );
};

export default groupActivity;
