// @flow
import { map, prop, values } from 'ramda';

import type { Dishes, Params } from 'app/dataTypes/Dishes.types';
import toFixedValue from 'app/common/lib/toFixedValue';

import type { Request } from '../../index';


type ParamsApi = {
  saleType?: string,
  search?: string,
  nomenclature?: string,
  limit?: number,
  offset?: number,
}

type ResultApi = {
  result: 'success',
  count: number,
  items: {
    [id: string]: {
      id: number,
      title: string,
      photoUrl: string | null,
      portion: string,
      cost: string,
      saleType: string,
      nomenclature: string | null,
    }
  }
}

export const convertParams = (params: Params): [ParamsApi, null] => {
  return [params, null];
};

export const convertResult = (data: ResultApi): Dishes => {
  const items = prop('items', data) || {};
  return ({
    total: data.count || 0,
    items: map(({
      id,
      title,
      photoUrl,
      portion,
      cost,
      saleType,
      nomenclature,
    }) => ({
      id: String(id),
      title,
      photoUrl: photoUrl || null,
      portion: portion || '',
      cost: toFixedValue(cost),
      saleType: saleType || '',
      nomenclature: nomenclature || '',
    }), values(items)),
  });
};

export const GET_FOOD_ITEMS: Request<Params, Dishes, void> = [
  'food/items',
  'get',
  ['data'],
];
