// @flow
import path from 'ramda/es/path';
import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const SELLPOINTS_FILTER_REQUEST = 'reports/SELLPOINTS_FILTER_REQUEST';
export const SELLPOINTS_FILTER_SUCCESS = 'reports/SELLPOINTS_FILTER_SUCCESS';
export const SELLPOINTS_FILTER_FAILURE = 'reports/SELLPOINTS_FILTER_FAILURE';
export const SELLPOINTS_FILTER_CLEAR = 'reports/SELLPOINTS_FILTER_CLEAR';
export const SELLPOINTS_FILTER_DELETE = 'reports/SELLPOINTS_FILTER_DELETE';
export const SELLPOINTS_FILTER_SET = 'reports/SELLPOINTS_FILTER_SET';
export const SELLPOINTS_FILTER_UPDATE = 'reports/SELLPOINTS_FILTER_UPDATE';

type Params = {
  building_id?: number | Array<number>
}

export const sellPointsFetch = (orgId: number, params?: Params) => async (dispatch: Function) => {
  dispatch(action(SELLPOINTS_FILTER_REQUEST));
  try {
    const res = await api(`food/schools/${orgId}/sale_points`, 'get', { params });
    dispatch(action(SELLPOINTS_FILTER_SUCCESS, path(['data', 'sale_points'], res)));
  } catch (error) {
    dispatch(action(SELLPOINTS_FILTER_FAILURE, { err: 'Не удалось получить данные фильтра по точкам продаж', error }));
  }
};
