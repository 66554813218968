// @flow
import React from 'react';

import styles from './CopyMultipleModalAdditionalContent.scss';


const CopyMultipleModalAdditionalContent = () => {
  return (
    <span className={styles.root}>
      {'Копирование настроек займет продолжительное время. Новые настройки вступят в силу в течении нескольких часов, в зависимости от количества заведений'}
    </span>
  );
};

export default CopyMultipleModalAdditionalContent;
