import { combineReducers } from 'redux';

import fields from './fields';
import options from './options';
import employeeform from './employeeform';
import validation from './validation';
import identifiers from './identifiers';
import jobplaces from './jobplaces';
import settings from './settings';
import history from './history';
import sessions from './sessions';

export default combineReducers({
  fields,
  options,
  employeeform,
  validation,
  identifiers,
  jobplaces,
  settings,
  history,
  sessions,
});
