// @flow
import { useMemo } from 'react';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import sortBy from 'ramda/es/sortBy';
import prop from 'ramda/es/prop';

import { type RightsPath } from 'app/rights';
import toFixedValue from 'app/common/lib/toFixedValue';

import { orgTypes, type OrgItem, type OrgsSchools } from '../useOrgsAndRoles';
import { dataKey as settingsDataKey } from '../OrgsSettings';
import { getLinksData } from './OrgsListTableCellLinks';


type Opt = {
  title?: string,
  deleted: boolean,
  approved: boolean,
}

const getTitleWithStatus = ({ title: tt, approved, deleted }: Opt) => {
  const title = tt || 'Заведение без названия';
  if (!approved) {
    return `${title} (не подтверждено)`;
  }
  if (deleted) {
    return `${title} (удалено)`;
  }
  return title;
};

export type RowItem = {
  id: number,
  title: string,
  sells: {
    complex: string | null, // null - loading
    personal: string | null, // null - loading
  },
  debts: string | null, // null - loading
  presence: {
    complex: string | null, // null - loading
    personal: string | null, // null - loading
  },
  links: Array<[string, string, RightsPath]>,
  settings: string,
  locked: boolean,
  forGuestIcon: boolean,
  todaySalesSum: string,
  monthSalesSum: string,
}

type Options = {
  getChildPageBaseUrl: ({ orgId: string, section: string }) => string,
}

export default function useOrgsOrdersTableItems(orders: OrgsSchools, options: Options): Array<RowItem> {
  const { getChildPageBaseUrl } = options;

  const items: Array<RowItem> = useMemo(() => pipe(
    values,

    // сортировка
    sortBy(prop('title')),

    // ремаппинг для таблицы
    map(({
      id,
      type,
      title,
      deleted,
      approved,
      cafeteria_enabled: cafeteriaEnabled,
      food_place_for_guests: foodPlaceForGuests,
      today_sales_sum: todaySalesSum,
      month_sales_sum: monthSalesSum,
    }: OrgItem): RowItem => {
      const enterprise = type === orgTypes.ENTERPRISE;
      const locked = deleted || !approved;
      const hideLinks = locked || (enterprise && !cafeteriaEnabled);

      return ({
        id,
        title: getTitleWithStatus({ title, deleted, approved }),
        locked,
        sells: {
          complex: locked ? '—' : null,
          personal: locked ? '—' : null,
        },
        debts: locked ? '—' : null,
        presence: {
          complex: locked ? '—' : null,
          peronal: locked ? '—' : null,
        },
        links: hideLinks ? [] : getLinksData(String(id), getChildPageBaseUrl, enterprise),
        settings: locked ? '' : getChildPageBaseUrl({ orgId: String(id), section: settingsDataKey }),
        forGuestIcon: enterprise && foodPlaceForGuests,
        todaySalesSum: enterprise ? toFixedValue(todaySalesSum) : '',
        monthSalesSum: enterprise ? toFixedValue(monthSalesSum) : '',
      });
    }),
  )(orders), [
    orders,
    getChildPageBaseUrl,
  ]);

  return items;
}
