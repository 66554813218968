import { ChangeEvent, useCallback } from 'react'
import cn from 'classnames'

import LineInput from 'app/common/ui/LineInput'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { priceUnsignedRe } from 'app/common/constants/regex'
import PlainLabel from 'app/common/ui-next/plain-label'

import styles from './SecondMealControls.scss'


type Props = {
  className?: string
  label1: string
  label2: string
  placeholder: string
  focused: string
  value1: string | null
  value2: string | null
  checked: boolean
  editDisabled?: boolean
  onCheck: (value: boolean) => void
  onChange1: (value: string | null) => void
  onChange2: (value: string | null) => void
}

const SecondMealControls = ({
  label1,
  label2,
  className,
  placeholder,
  focused,
  value1,
  value2,
  checked,
  editDisabled,
  onCheck,
  onChange1,
  onChange2,
}: Props) => {
  const onChange1Ref = useCallbackRef(onChange1)
  const onChange2Ref = useCallbackRef(onChange2)
  const onCheckRef = useCallbackRef(onCheck)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    if (priceUnsignedRe.test(value)) {
      const count = parseInt(name.replace(/^\D+/, ''), 10)
      const valueWithDot = value.replace(',', '.')
      if (count === 1) onChange1Ref.current?.(valueWithDot)
      if (count === 2) onChange2Ref.current?.(valueWithDot)
    }
  }, [onChange1Ref, onChange2Ref])

  const handleCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked: chk } = e.currentTarget
    onCheckRef.current?.(chk)
  }, [onCheckRef])

  return (
    <div className={cn(styles.root, className)}>
      <PlainCheckbox
        value="second_meal_check"
        label={label1}
        onChange={handleCheck}
        checked={checked}
      />
      <LineInput
        name="val1"
        className={styles.input}
        placeholder={placeholder}
        focused={focused}
        value={value1 === null ? '' : String(value1)}
        onChange={handleChange}
        autoComplete="off"
        disabled={!checked || editDisabled}
      />
      <PlainLabel htmlFor="val2" className={styles.label}>
        {label2}
      </PlainLabel>
      <LineInput
        name="val2"
        id="val2"
        className={styles.input}
        placeholder={placeholder}
        focused={focused}
        value={value2 === null ? '' : String(value2)}
        onChange={handleChange}
        autoComplete="off"
        disabled={!checked || editDisabled}
      />
    </div>
  )
}

export default SecondMealControls
