// @flow
import React from 'react';
import cn from 'classnames';

import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';
import PlainTooltip from 'app/common/ui-next/plain-tooltip';

import styles from './SubsidyFeeding.scss';


type Props = {
  title: string,
  className?: string,
}

const SubsidyFeeding = ({ title, className }: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      <PlainTooltip
        text={title}
        className={styles.title}
        hideWithoutOverflow
      >
        {title}
      </PlainTooltip>

      <PlainIcon
        className={styles.icon}
        type={iconTypes.OK_CIRCLE}
      />
    </div>
  );
};

export default SubsidyFeeding;
