// @flow
import React, { useCallback, type Node } from 'react';
import classNames from 'classnames/bind';
// $FlowFixMe
import { useEvent } from 'react-use';

import useSizing, { type Opt } from 'app/common/hooks/useSizing';

import ClickOutside from '../ClickOutside';
import styles from './Dropdown.scss';


const cx = classNames.bind(styles);

type DivProps = { children: Node, className?: string }
const Div = ({ children, className }: DivProps) => <div className={className}>{children}</div>;

type Props = {
  children: Node,
  disabled?: boolean,
  className?: string,
  isOpen: boolean,
  toggle: () => void,
  content: Node,
  options?: Opt,
  contentClassName?: string,
  bobupLink?: Boolean,
}

const Dropdown = ({
  children,
  disabled,
  className,
  isOpen,
  toggle,
  content,
  options,
  contentClassName,
  bobupLink,
}: Props) => {
  const toggleDropdown = useCallback((e) => {
    e.preventDefault();
    if (!disabled) {
      toggle();
    }
  }, [toggle, disabled]);

  const { rootRef, calendarRef } = useSizing(isOpen, options);

  const handleScroll = useCallback(() => {
    if (isOpen) {
      toggle();
    }
  }, [isOpen, toggle]);
  useEvent('scroll', handleScroll);

  const Outside = isOpen ? ClickOutside : Div;

  return (
    <Outside
      className={styles.main}
      onClickOutside={toggle}
    >
      <a
        href="dateSelect"
        ref={rootRef}
        className={cx(styles.openLink, { disabled, bobup: bobupLink && isOpen }, className)}
        onClick={toggleDropdown}
      >
        {children}
      </a>
      {isOpen
        && <div
          className={cx(styles.container, { open: isOpen }, contentClassName)}
          ref={calendarRef}
        >
          {content}
        </div>}
    </Outside>
  );
};

export default Dropdown;
