// @flow
import { useState, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { api } from 'app/api';
import { GET_HISTORY, reportTypes, type HistoryResult } from 'app/common/api/requests/reports/history';
import type { HistoryModalProps } from 'app/common/ui-components/history-modal';

import convertApiHistoryItems from './convertApiHistoryItems';
import renderFileHistoryCellContent from './renderFileHistoryCellContent';


export { reportTypes };

const columnIdsList = ['title', 'date', 'options'];
const columnNamesList = ['Название отчета', 'Дата', '\u00A0'];
const alignLeft = { alignLeft: true };
const alignCenter = { alignCenter: true };
const alignRight = { alignRight: true };
const columnPropsList = [alignLeft, alignCenter, alignRight];

type HistoryProps = $Diff<HistoryModalProps, { children: any }>

export default function useGenerateFileHistory(reportType: $Values<typeof reportTypes>): HistoryProps {
  const [history, setHistory] = useState<HistoryResult>({});
  const [loading, setLoading] = useState();
  const { email } = useSelector(state => state.user, shallowEqual);

  const handleRequestHistory = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_HISTORY, {
      error: 'Не удалось загрузить данные истории',
      params: {
        report_type: reportType,
        limit: 10,
      },
    });

    if (res) {
      setHistory(res);
    }
    setLoading(false);
  }, [reportType]);

  const itemsList = useMemo(() => (
    convertApiHistoryItems(history, email)
  ), [history, email]);

  return {
    title: 'Последние 10 загрузок',
    emptyWarnMessage: 'Отсутствуют записи в истории',
    loading,
    columnIdsList,
    columnNamesList,
    columnPropsList,
    itemsList,
    onRequestHistory: handleRequestHistory,
    renderCellContent: renderFileHistoryCellContent,
  };
}
