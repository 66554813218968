import { createContext } from 'react'

import {
  type State,
  type Handlers,
  defaultState,
  defaultHandlers,
} from './useRightsForOrg'


type RightsContextType = [
  State,
  Handlers
]

const defaultContext: RightsContextType = [
  defaultState,
  defaultHandlers,
]

export default createContext<RightsContextType>(defaultContext)
