import history from 'app/history'

import useFav from './useFav'
import FavPanelView from './FavPanelView'


/**
* Панель избранных (черная полоска под главным меню)
* Показывается если pathname не соответствует showConditionReg и showConditionReg указан
* или не указан showConditionReg
* или нет избранных
*
* Пример применения:
  <FavPanel
    basePath="/reports"
    favorites={favorites}
    names={names}
    showConditionReg={/^\/(reports)(\/|$)/}
  />
*/

type Props = {
  favorites: Array<string>
  showConditionReg?: RegExp
  names: Record<string, string>
  basePath: string
  shallow?: boolean
  linkPropsByKey?: Record<string, Object>
  darkTheme?: boolean
}

export const FavPanel = ({
  favorites,
  names,
  showConditionReg,
  basePath,
  shallow,
  linkPropsByKey,
  darkTheme,
}: Props) => {
  const { location: { pathname } } = history
  const fav = useFav({ favorites, names, basePath, shallow, pathname, linkPropsByKey })

  return (
    <FavPanelView
      fav={fav}
      showConditionReg={showConditionReg}
      darkTheme={darkTheme}
    />
  )
}

export default FavPanel
