// @flow
import React, { useMemo } from 'react';
import moment from 'moment-timezone';

import PlainModal, { PlainModalHeader, PlainModalContent, PlainModalFooter } from 'app/common/ui-next/plain-modal';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import config from 'app/config';
import { CHOOSEN_DATE, FUTURE, CHOSEN_DATE_AND_FUTURE } from 'app/actions/orgs/kits/kitClear';
import type { Extended } from 'app/actions/orgs/kits/kitClear';
import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';

import enhanceClearKitForm from './enhanceClearKitForm';
import styles from './ClearKitForm.scss';


type Props = {
  date: string,
  saving: boolean,
  title: string,
  extended: Extended,
  handleSubmit: () => Promise<boolean>,
  handleChange: (SyntheticEvent<HTMLInputElement>) => void,
  onSuccess: () => void,
  onCancel: () => void,
}

const ClearKitForm = ({
  date,
  saving,
  title,
  extended,
  handleSubmit,
  handleChange,
  onCancel,
}: Props) => {
  const datePresent = useMemo(() => (
    moment(date).format(config.dateFormatPresentative)
  ), [date]);

  return (
    <PlainModal
      formId="clear_kit"
      onSubmit={handleSubmit}
      show
    >
      <PlainModalHeader onCrossClick={onCancel}>
        {`Очистка комплекта «${title}»`}
      </PlainModalHeader>

      <PlainModalContent>
        <PlainFieldset
          legend={`Очистка комплекта «${title}»`}
          name="select"
          legendClassName="visuallyHidden"
        >
          <PlainRadioButton
            label={`Очистить на ${datePresent}`}
            name={CHOOSEN_DATE}
            value={CHOOSEN_DATE}
            disabled={saving}
            checked={extended === CHOOSEN_DATE}
            onChange={handleChange}
          />
          <PlainRadioButton
            label="Очистить на будущие дни*"
            name={FUTURE}
            value={FUTURE}
            disabled={saving}
            checked={extended === FUTURE}
            onChange={handleChange}
          />
          <PlainRadioButton
            label={`Очистить на ${datePresent} и на будущие дни*`}
            name={CHOSEN_DATE_AND_FUTURE}
            value={CHOSEN_DATE_AND_FUTURE}
            disabled={saving}
            checked={extended === CHOSEN_DATE_AND_FUTURE}
            onChange={handleChange}
          />
        </PlainFieldset>
        <div className={styles.footnote}>{`* — c ${datePresent}`}</div>
      </PlainModalContent>

      <PlainModalFooter>
        <ControlsBar right>
          <PlainButton
            outline
            onClick={onCancel}
          >
            {'Отмена'}
          </PlainButton>

          <PlainButton
            type="submit"
            disabled={saving}
          >
            {saving ? 'Очищение...' : 'Очистить'}
          </PlainButton>
        </ControlsBar>
      </PlainModalFooter>
    </PlainModal>
  );
};

export default enhanceClearKitForm(ClearKitForm);
