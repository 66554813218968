import api from 'app/actions/api';

export const LISTEDIT_ADD = 'orgs/dishes/LISTEDIT_ADD';
export const LISTEDIT_REMOVE = 'orgs/dishes/LISTEDIT_REMOVE';
export const LISTEDIT_ITEM_SAVE = 'orgs/dishes/LISTEDIT_ITEM_SAVE';
export const LISTEDIT_CLEAR = 'orgs/dishes/LISTEDIT_CLEAR';

const listEditItemSave = payload => ({
  type: LISTEDIT_ITEM_SAVE,
  payload,
});

const listeditAddId = payload => ({
  type: LISTEDIT_ADD,
  payload,
});

const listeditRemoveId = payload => ({
  type: LISTEDIT_REMOVE,
  payload,
});

export const listeditAdd = id => (dispatch, getState) => {
  const { items } = getState().dishes.items;
  dispatch(listeditAddId(id));
  dispatch(listEditItemSave({ [id]: items[id].title }));
};

export const listeditRemove = id => (dispatch, getState) => {
  const { items } = getState().dishes.items;
  dispatch(listeditRemoveId(id));
  dispatch(listEditItemSave({ [id]: items[id].title }));
};

export const listeditClear = () => ({
  type: LISTEDIT_CLEAR,
});

export const LISTEDIT_REQUEST = 'orgs/dishes/LISTEDIT_REQUEST';
export const LISTEDIT_SUCCESS = 'orgs/dishes/LISTEDIT_SUCCESS';
export const LISTEDIT_FAILURE = 'orgs/dishes/LISTEDIT_FAILURE';

export const listeditRequest = () => ({
  type: LISTEDIT_REQUEST,
});

export const listeditSuccess = payload => ({
  type: LISTEDIT_SUCCESS,
  payload,
});

export const listeditFailure = payload => ({
  type: LISTEDIT_FAILURE,
  payload,
});

export const listedit = (orgId, categoryId, addList, removeList) => async (dispatch) => {
  dispatch(listeditRequest());
  try {
    const dataToSend = {
      school_id: orgId,
      category_id: categoryId,
      add_dishes: addList,
      remove_dishes: removeList,
    };
    const { data } = await api('food/menu/items_add_remove', 'post', { data: dataToSend });
    dispatch(listeditSuccess(data));
    return true;
  } catch (error) {
    dispatch(listeditFailure({ err: 'Не удалось модифицировать список блюд', error }));
    return false;
  }
};
