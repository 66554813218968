// @flow
import { getStateCurrentValue, type State } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const currentStateRadioSelected = (state: State): boolean => {
  const [radioState] = getStateCurrentValue(filterId, state) || [];
  return radioState === 'current';
};

export const dateRadioSelected = (state: State): boolean => {
  const [radioState] = getStateCurrentValue(filterId, state) || [];
  return radioState === 'date';
};
