// @flow
import { getDomain } from 'app/common/lib/params';

const examplePhoneNumbers = {
  ru: '+79991234567',
  ua: '+380711234567',
  kg: '+996221234567',
  kz: '+79991234567',
  def: '+79991234567, +996221234567',

  getByCode(code: string) {
    if (getDomain() === 'in-shkola.ru') return this.def;
    return this[code] || this.def;
  },
};

export default examplePhoneNumbers;
