// @flow
import React, { useCallback } from 'react';

import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import nope from 'app/common/lib/nope';

import { useImportXLSData } from '../ImportXLSData';
import updateByCheckbox from './updateByCheckbox';
import styles from './ImportOptions.scss';


type Props = {
  className?: string,
}

const ImportOptions = ({ className }: Props) => {
  const { content: { options }, modify } = useImportXLSData();
  const handleCheckboxChange = useCallback(updateByCheckbox(modify.optionUpdate), []);

  return (
    <PlainFieldset
      legend="Выберите действия для существующей продукции:"
      className={className}
      name="options"
    >
      <PlainCheckbox
        label="Сохранить только в списке продукции поставщика"
        value="onlyProvider"
        checked
        onChange={nope}
        disabled
      />
      <PlainCheckbox
        label="Сохранить во всех школах, где используется эта продукция"
        value="allSchools"
        checked={options.allSchools}
        onChange={handleCheckboxChange}
        disabled={options.allMenu}
      />
      <PlainCheckbox
        label="Внести изменения в меню во всех школах"
        value="allMenu"
        id="allMenu"
        checked={options.allMenu}
        onChange={handleCheckboxChange}
      />
      <PlainFieldset
        ariaLabelledby="allMenu"
        className={styles.productionGroup}
        name="production"
      >
        <PlainCheckbox
          label="Применить к продукции"
          value="allMenuSingle"
          checked={options.allMenuSingle}
          onChange={handleCheckboxChange}
          disabled={!options.allMenu}
        />
        <PlainCheckbox
          label="Применить к продукции в комплектах"
          value="allMenuComplex"
          checked={options.allMenuComplex}
          onChange={handleCheckboxChange}
          disabled={!options.allMenu}
        />
      </PlainFieldset>
    </PlainFieldset>
  );
};

export default ImportOptions;
