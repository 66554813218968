import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import toLower from 'ramda/es/toLower';
import length from 'ramda/es/length';
import remove from 'ramda/es/remove';
import replace from 'ramda/es/replace';
import join from 'ramda/es/join';

import setCaretPosition from 'app/common/lib/caretPosition';
import omitProps from 'app/common/containers/omitProps';
import { getPlaceholder, getPrefix, transformByMask } from 'app/common/lib/phone';

import PlainInput from './PlainInput';

/**
 * Поле для ввода номера телефона с маской по коду страны
 *
    KZ - казахстан
    KG - киргизия
 */

const clearFromMaskReg_ = /[^+0-9]/g; // eslint-disable-line no-underscore-dangle

export default compose(
  withState('el', 'setEl', null),
  connect(
    (state, { value }) => {
      const code = toLower(state.dealer.country_iso_code);
      const placeholder = getPlaceholder(code);
      const prefix = getPrefix(code); // replace(/_.*_/g, '', placeholder);

      return ({
        placeholder,
        maxLength: length(placeholder),
        value: transformByMask(value, placeholder, prefix),
        type: 'tel',
        clearFromMaskReg: clearFromMaskReg_,
        prefix,
      });
    },
    () => ({}),
  ),
  withHandlers({
    onChange: ({ onChange, clearFromMaskReg }) => (e) => {
      const { value } = e.currentTarget;
      if (onChange) {
        const clearedValue = replace(clearFromMaskReg, '', value); // clearFromMask(value, placeholder);
        onChange(e, clearedValue);
      }
    },
    onKeyDown: ({ placeholder, el }) => (e) => {
      const { key } = e;

      // частный случай удаления
      if (key === 'Backspace' && el) {
        const { selectionStart, selectionEnd } = el;
        const { value } = e.currentTarget;
        // если нажали Backspace без выделения и удаляемый символ - заполняющий символ маски
        // нужно удалить все заполняющие начиная с этого симола до символа вписаного в маску
        // включая его самого (отчет к началу)
        if (selectionStart === length(value) && placeholder[selectionStart - 1] !== '_') {
          e.preventDefault();
          let currentPos = selectionStart;
          let number = 1;
          while (currentPos > 1 && placeholder[currentPos - 1] !== '_') {
            currentPos -= 1;
            number += 1;
          }
          const newValue = join('', remove(currentPos - 1, number, value));
          el.value = newValue; // eslint-disable-line no-param-reassign
          // случай когда курсор не в конце -- просто его двигаем, не удаляя заполняющий символ
          // если символ вписанный -- то обработка по умолчанию
        } else if (placeholder[selectionStart - 1] !== '_' && selectionStart === selectionEnd) {
          e.preventDefault();
          setCaretPosition(el, selectionStart - 1);
        }
      }
    },
    onFocus: ({ prefix, el }) => (e) => {
      const { value } = e.currentTarget;
      if (!value) {
        el.value = prefix; // eslint-disable-line no-param-reassign
      }
    },
    onBlur: ({ prefix, el }) => (e) => {
      const { value } = e.currentTarget;
      if (value === prefix) {
        el.value = ''; // eslint-disable-line no-param-reassign
      }
    },
    reference: ({ setEl }) => (el) => {
      if (el) {
        setEl(el);
      }
    },
  }),
  omitProps(['el', 'setEl', 'clearFromMaskReg']),
)(PlainInput);
