// @flow
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';

import { type ComplexItem, type GetComplexesResult } from 'app/common/api/requests/food/schools/complexes';
import { toFixedValueCurrency } from 'app/common/lib/toFixedValue';
import { sortByTitle } from 'app/common/lib/sort';


export type Item = {
  id: number,
  title: string,
  cost: string,
}

const kits2SimpleItems = (complexes: GetComplexesResult, currency: string): Array<Item> => pipe(
  values,
  map(({ id, title, fixed_price_enabled: fpEnabled, fixed_price: fp }: ComplexItem) => ({
    id,
    title: (title || '').trim(),
    cost: (
      fpEnabled && typeof fp === 'string' || typeof fp === 'number'
        ? toFixedValueCurrency(fp, false, currency)
        : ''
    ),
  })),
  sortByTitle,
)(complexes);

export default kits2SimpleItems;
