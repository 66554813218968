// @flow
import React, { type Node } from 'react';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainInputFocused from 'app/common/ui/PlainInput/PlainInputFocused';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';

import useCategoryCreate from './useCategoryCreate';
import styles from './AddCategoryModal.scss';


type Props = {
  children: (HandleModalDisplayed) => Node,
  placeId?: string,
  onSuccess: (c: Category) => void,
}

const AddCategoryModal = ({ children, placeId, onSuccess }: Props): Node => {
  const {
    createCategory,
    submitting,
    errorMessage,
    category,
    handleChangeInput,
    reset,
  } = useCategoryCreate(placeId, onSuccess);

  return (
    <PlainModal
      controlBy={children}
      onSubmit={createCategory}
      onClose={reset}
    >
      <PlainModalHeader>
        {'Добавить новую категорию'}
      </PlainModalHeader>
      <PlainModalContent>
        <PlainInputFocused
          id="category"
          className={styles.input}
          placeholder="Имя категории"
          onChange={handleChangeInput}
          disabled={submitting}
          value={category}
          isError={!!errorMessage}
          errorText={errorMessage}
        />
      </PlainModalContent>
      <PlainModalFooter>
        {handleModalDisplayed => (
          <ControlsBar middle>
            <PlainButton type="submit" disabled={!category}>
              {submitting ? 'Добавление...' : 'Добавить'}
            </PlainButton>
            <PlainButton
              outline
              onClick={handleModalDisplayed}
            >
              {'Отмена'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>

    </PlainModal>
  );
};

export default AddCategoryModal;
