// @flow
import { useCallback, useState, type Ref, useEffect } from 'react';


export default function useCorrectPosition(reset: boolean): [
  Ref<'div'>,
  boolean,
  boolean,
  boolean,
  boolean,
] {
  const [leftShift, setLeftShift] = useState(false); // сдвиг слева
  const [rightShift, setRightShift] = useState(false); // сдвиг справа
  const [dropBottom, setDropBottom] = useState(false); // выпадение вниз
  const [appear, setAppear] = useState(false); // выход из opacity 0

  const tooltipAppear = useCallback((el: ?HTMLElement) => {
    if (el) {
      const { left, right, top } = el.getBoundingClientRect() || {};
      setLeftShift(left < 0);
      setRightShift(!!document.documentElement && right > document.documentElement.clientWidth);
      setDropBottom(top < 0);
      setAppear(true);
    }
  }, []);

  useEffect(() => {
    if (reset) {
      setLeftShift(false);
      setRightShift(false);
      setDropBottom(false);
      setAppear(false);
    }
  }, [reset]);

  return [tooltipAppear, leftShift, rightShift, dropBottom, appear];
}
