// @flow
import map from 'ramda/es/map';


// меняем null на пустую строку в объекте со всеми вложенными
const null2emptyString = (obj: Object) => {
  return map((value) => {
    if (typeof value === 'object') {
      return (value === null ? '' : null2emptyString(value));
    }
    return value;
  }, obj);
};

export default null2emptyString;
