// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State, type StateByFilterId } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const byPurchasesSelected = (state: State): boolean => {
  const [groupBy] = getStateCurrentValue(filterId, state) || [];
  return groupBy === 'by_purchases';
};

export const byGroupComplexesSelected = (state: State): boolean => {
  const [groupBy] = getStateCurrentValue(filterId, state) || [];
  return groupBy === 'by_group_complexes';
};

export const byCampComplexesSelected = (state: State): boolean => {
  const [groupBy] = getStateCurrentValue(filterId, state) || [];
  return groupBy === 'by_camp_complexes';
};

export const byAnyComplexesNotSelected = (state: State): boolean => {
  const [groupBy] = getStateCurrentValue(filterId, state) || [];
  return (groupBy !== 'by_group_complexes' && groupBy !== 'by_camp_complexes');
};

export const byGroupComplexesApplied = (applied: StateByFilterId | null): boolean => {
  if (applied && applied[filterId]) {
    return applied[filterId].includes('by_group_complexes');
  }
  return false;
};

export const byCampComplexesApplied = (applied: StateByFilterId | null): boolean => {
  if (applied && applied[filterId]) {
    return applied[filterId].includes('by_camp_complexes');
  }
  return false;
};
