import React from 'react';

const IconDoubleMan = props => (<svg viewBox="0 0 20 20" width="20px" height="20px" {...props}>
  <path d="M12.18 10.813L12.18 10.813c-2.682 0-4.858 2.116-4.858 4.728v1.459h9.719V15.54C17.041 12.929 14.865 10.813 12.18 10.813 z" />
  <path d="M12.18 9.979c1.699 0 3.074-1.338 3.074-2.989S13.879 4 12.18 4c-1.697 0-3.072 1.338-3.072 2.989 S10.483 9.979 12.18 9.979z" />
  <path d="M7.819 9.979c0.588 0 1.136-0.164 1.604-0.443c-0.65-0.665-1.057-1.557-1.057-2.546c0-0.988 0.4-1.885 1.049-2.55 C8.95 4.163 8.404 4 7.819 4C6.122 4 4.746 5.338 4.746 6.989S6.122 9.979 7.819 9.979z" />
  <path d="M9.112 10.986c-0.412-0.109-0.845-0.174-1.293-0.174l0 0c-2.685 0-4.859 2.116-4.859 4.728v1.459h3.618V15.54 C6.578 13.637 7.588 11.962 9.112 10.986z" />
</svg>);

export default IconDoubleMan;
