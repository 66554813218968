import { useCallback, useEffect } from 'react'

import { ReportSection, ResetLink } from 'app/common/templates-next/report-template'
import ReportFilterForm from 'app/common/templates-next/report-template/report-filter-form'
import ReportFilterLayout from 'app/common/templates-next/report-template/report-filter-layout'
import { ID } from 'app/common/ui-next/_filter-compilations/predefined-ids'
import { DetailsBox, detailsBoxID } from 'app/common/ui-next/_filter-compilations/details'

// import { useUser } from 'app/dataProviders/user/hooks'
import { useReportPerform } from 'app/dataProviders/reports/hooks'
import { ReportType } from 'app/dataTypes/reports'

import { canMailingOnly, MailingBox } from 'app/common/ui-next/_filter-compilations/mailing'
import { OperationAgeFilter, OperationsTypesFilter, OrgTypesFilter, OrgsFilter, PeriodFilter } from './filterItems'
import styles from './Filters.scss'
import { filterInit, resetToDefaultAll } from './filter-init'


const isMailingOnly = (formData: FormData) => !!canMailingOnly(formData)

type Props = {
  onReportID: (reportID: string | null) => void
}

export const Filters = ({ onReportID }: Props) => {
  // const { email, loading } = useUser()

  const { perform } = useReportPerform({
    onReportID,
    isMailingOnly,
  })

  const resetAll = () => {
    resetToDefaultAll()
  }

  const performWithAdditionalData = useCallback(async (formData: FormData) => {
    await perform({ formData, reportType: ReportType.Corrections })
  }, [perform])

  useEffect(() => {
    return filterInit()
  }, [])

  return (
    <ReportSection
      header="Фильтры"
      headerBar={
        <ResetLink onClick={resetAll}>
          {'Сбросить все фильтры'}
        </ResetLink>
      }
      resetStickyShift={false}
      skipPadding
    >
      <div className={styles.formContent}>
        <ReportFilterForm
          onSubmit={performWithAdditionalData}
          submitButtonTitle="Показать"
        >
          {pending => (
            <ReportFilterLayout
              column1={
                <>
                  <OrgTypesFilter
                    id={ID.orgType}
                    pending={pending}
                  />
                  <OrgsFilter
                    id={ID.org}
                    pending={pending}
                  />
                  <DetailsBox
                    id={ID.details}
                    disabled
                    defaultSelection
                  />
                </>
              }
              column2={
                <>
                  <PeriodFilter
                    id={ID.periodDateFormat}
                    pending={pending}
                  />
                  <OperationsTypesFilter
                    id={ID.operationsType}
                    pending={pending}
                  />
                  <OperationAgeFilter
                    id={ID.operationAge}
                    pending={pending}
                  />
                  {/* опция временно отключена */}
                  {/* <MailingBox
                    visibleFromBox={detailsBoxID}
                    email={email}
                    disabled={pending || loading}
                  /> */}
                </>
              }
            />
          )}
        </ReportFilterForm>
      </div>
    </ReportSection>
  )
}
