// @flow
import React, { type Ref } from 'react';

import { Tr, Td } from 'app/common/ui-components/table';

import type { SchoolDisplayableItem } from './useActualSchoolsData';
import { MultiLineCell, MultiLineCellDouble } from '../MultiLineCell';


type Props = {
  reference: Ref<'tbody'>,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  data: Array<SchoolDisplayableItem>,
  sameRowClassName: string,
  sameColumnClassName: string,
  showDuty?: boolean,
  sellTypeGroupped?: boolean,
}

const ContentBody = ({
  reference,
  onMouseEnter,
  onMouseLeave,
  onCellMouseEnter,
  onCellMouseLeave,
  data,
  sameRowClassName,
  sameColumnClassName,
  showDuty,
  sellTypeGroupped,
}: Props) => {
  const Cell = sellTypeGroupped ? MultiLineCellDouble : MultiLineCell;

  return (
    <tbody ref={reference}>
      {data.map(({ id, byDate }: SchoolDisplayableItem, i) => {
        const maxIndex = byDate.length - 1;
        return (
          <Tr
            key={id}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`${sameRowClassName}${i}`}
          >
            {byDate.map(({ date, data: dataOnDate }, j) => {
              return (
                <Td
                  key={date}
                  stripPadding
                  headers={`column_date_${date} row_school_${i}`}
                  className={`${sameColumnClassName}${j}`}
                  onMouseEnter={onCellMouseEnter}
                  onMouseLeave={onCellMouseLeave}
                >
                  <Cell
                    data={dataOnDate}
                    showDuty={showDuty}
                    borderRight={j < maxIndex}
                  />
                </Td>
              );
            })}
          </Tr>
        );
      })}
    </tbody>
  );
};


export default ContentBody;
