// @flow
import type { StateDataItem } from 'app/common/ui-components/filter';

import { saleTypes } from 'app/dataTypes/reports/saleTypes';


const sellTypeOnlyComplexItemsDefaultData = [{
  id: saleTypes.Complex,
  title: 'Комплексное питание',
}];

const sellTypeOnlyComplexItemsDefault = (): Array<StateDataItem> => {
  return sellTypeOnlyComplexItemsDefaultData;
};

export default sellTypeOnlyComplexItemsDefault;
