// @flow
import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { Prompt, type Location } from 'react-router-dom';

import history from 'app/history';
import Modal from 'app/common/components/Modal';
import ContrlolsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';

import styles from './prompt-router-custom.scss';

/**
 *  Компонент заменяющий PromptRouter для отображения кастомного диалога
 *  вместо стандартного браузерного
 */

type Props = {
  when?: boolean,
  message: | string
  | ((location: Location) => (string | boolean)), // для совместимости
  confirmButtonText?: string,
  cancelButtonText?: string,
  shouldBlockNavigation?: (location: Location) => boolean;
}

const PromptRouterCustom = ({
  when,
  message,
  confirmButtonText = 'Да',
  cancelButtonText = 'Нет',
  shouldBlockNavigation,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);


  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }, []);

  const handleBlockedNavigation = useCallback((nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      if (
        (typeof when === 'boolean' && when)
        || (typeof shouldBlockNavigation === 'function' && shouldBlockNavigation(nextLocation))
        || (typeof message === 'function' && !message(nextLocation))
      ) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false; // нет перехода и нет стандартного сообщения
      }
    }
    return true;
  }, [confirmedNavigation, shouldBlockNavigation, message, when]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);


  return (
    <Fragment>
      <Prompt message={handleBlockedNavigation} />
      <Modal className={styles.modalContent} isOpen={modalVisible}>
        <div className={styles.message}>
          {typeof message === 'string' ? message : null}
          {typeof message === 'function' && lastLocation ? message(lastLocation) : null}
        </div>
        <ContrlolsBar middle>
          <PlainButton onClick={closeModal} outline>
            {cancelButtonText}
          </PlainButton>
          <PlainButton onClick={handleConfirm}>
            {confirmButtonText}
          </PlainButton>
        </ContrlolsBar>
      </Modal>
    </Fragment>
  );
};

export default PromptRouterCustom;
