// @flow
import React from 'react';
// import moment from 'moment-timezone';

// import SettingsGear from 'app/common/components/Settings';
import IconFullScreen from 'app/common/icons/IconFullScreen';
// import HistoryModal from 'app/common/ui-components/history-modal';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
// import useGenerateFileRequests from 'app/components/common/reports/history/useGenerateFileRequests';
// import { GenerateFile } from 'app/common/ui-components/progress-indicator';
// import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon';
// import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';

import styles from './HeaderBar.scss';


type Props = {
  onFullscreen: (e: SyntheticEvent<HTMLElement>) => void,
  reportId: number,
}

const HeaderBar = ({ onFullscreen/* , reportId */ }: Props) => {
  // const reportFileName = `accounting_customers_${moment().format('YYYYMMDD_HHmmss')}.xlsx`;
  // const generateFileRequests = useGenerateFileRequests(reportId, reportFileName);

  // ГЕНЕРАЦИЯ ФАЙЛА И ИСТОРИЯ ГЕНЕРАЦИИ ВРЕМЕННО ОТКЛЮЧЕНА ВВИДУ ОТСУТСТВИЯ РЕАЛИЗАЦИИ НА БЭКЕ

  return (
    <div className={styles.root}>
      {/* <GenerateFile
        className={styles.download}
        labelClassName={styles.downloadLabel}
        label="Скачать как"
        generateAndDownloadPrompt="Сгенерировать и скачать в формате «документ Excel»"
        downloadPrompt="Скачать в формате «документ Excel»"
        {...generateFileRequests}
      >
        <PlainIcon
          width={20}
          height="auto"
          type={iconTypes.EXCEL}
        />
      </GenerateFile>

      <HistoryLink
        className={styles.historyButton}
        reportType={reportTypes.REPORT_ACCOUNTING_CUSTOMERS}
      />
      */}

      {/* ЗДЕСЬ НЕ НУЖНО */}
      {/* <SettingsGear
        dataKey="reports"
        title="Количество записей"
      /> */}

      <PlainButtonAsLink
        id="fullscreen"
        ariaLabel="Открыть в полном окне"
        onClick={onFullscreen}
      >
        <IconFullScreen className={styles.fullscreenIcon} />
      </PlainButtonAsLink>
    </div>
  );
};

export default HeaderBar;
