// @flow
import { useCallback } from 'react';

import { api } from 'app/api';

import { POST_DROP_PERSONAL_LIMITS } from 'app/common/api/requests/food/schools/drop_personal_limits';


const ORDERS: 'orders' = 'orders';
const PERSONAL: 'personal' = 'personal';
export const resetTypes = { ORDERS, PERSONAL };

const defaultParams = {
  orders: false,
  personal: false,
};

type Options = {
  type: $Values<typeof resetTypes>,
}

export default function usePersonalLimitsReset(
  { type }: Options,
): (orgIds: Array<number>) => Promise<boolean> {
  const handleReset = useCallback(async (orgIds: Array<number>) => {
    const res = await api.request(POST_DROP_PERSONAL_LIMITS, {
      error: 'Не удалось сбросить личные лимиты',
      params: {
        schools_ids: orgIds.map(String),
        ...defaultParams,
        [String(type)]: true,
      },
    });

    if (res) {
      return true;
    }
    return false;
  }, [type]);

  return handleReset;
}
