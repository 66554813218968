// @flow
import { createContext } from 'react';

import {
  defaultState as defaultGeneralSettings,
  type State as GeneralSettings,
  type ApiHandlers as GeneralSettingsApi,
  type ModifyHandlers as GeneralSettingsModify,
} from './useGeneralSettings';

import {
  defaultState as defaultTimeRestrictions,
  type State as TimeRestrictions,
  type ApiHandlers as TimeRestrictionsApi,
  type ModifyHandlers as TimeRestrictionsModify,
  defaultModifyHandlers as defaultTimeRestrictionsModify,
} from './timeRestriction/useOrdersTimeRestrictions';

import {
  defaultState as defaultOldOrdersRestrictions,
  type State as OldOrdersRestrictions,
  type ApiHandlers as OldOrdersRestrictionsApi,
  type ModifyHandlers as OldOrdersRestrictionsModify,
} from './useOldOrdersRestrictions';


const emptyFunc = () => {};
const emptyBFunc = () => false;

const defaultApiHandlers = {
  request: emptyFunc,
  submit: emptyBFunc,
  checkErrors: emptyBFunc,
};

type ContentType = {
  generalSettings: GeneralSettings,
  timeRestrictions: TimeRestrictions,
  oldOrdersRestrictions: OldOrdersRestrictions,
  submitting: boolean,
}

const defaultContent: ContentType = {
  generalSettings: defaultGeneralSettings,
  timeRestrictions: defaultTimeRestrictions,
  oldOrdersRestrictions: defaultOldOrdersRestrictions,
  submitting: false,
};

type RequestsType = {
  generalSettings: GeneralSettingsApi,
  timeRestrictions: TimeRestrictionsApi,
  oldOrdersRestrictions: OldOrdersRestrictionsApi,
  submitAll: (overrideOrgIds?: Array<number>) => Promise<boolean> | boolean,
  requestAll: () => void | Promise<void>,
  checkErrors: () => boolean,
}

const defaultRequests: RequestsType = {
  generalSettings: defaultApiHandlers,
  timeRestrictions: defaultApiHandlers,
  // $FlowFixMe
  oldOrdersRestrictions: defaultApiHandlers,
  submitAll: emptyBFunc,
  requestAll: emptyFunc,
  checkErrors: emptyBFunc,
};

type ModifyType = {
  generalSettings: GeneralSettingsModify,
  timeRestrictions: TimeRestrictionsModify,
  oldOrdersRestrictions: OldOrdersRestrictionsModify,
}

const defaultModify: ModifyType = {
  generalSettings: { updateField: emptyFunc },
  timeRestrictions: defaultTimeRestrictionsModify,
  oldOrdersRestrictions: { updateField: emptyFunc },
};

export type RequestsContextType = {
  content: ContentType,
  request: RequestsType,
  modify: ModifyType,
}

const defaultContext: RequestsContextType = {
  content: defaultContent,
  request: defaultRequests,
  modify: defaultModify,
};

export default createContext<RequestsContextType>(defaultContext);
