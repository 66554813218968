// @flow
import { useState, useEffect, useCallback, useMemo } from 'react';
import values from 'ramda/es/values';
import groupBy from 'ramda/es/groupBy';
import pipe from 'ramda/es/pipe';

import { api } from 'app/api';

import {
  GET_MENU_SALE_POINTS,
  type MenuSalePointsResult,
  type SalePointItem,
} from 'app/common/api/requests/food/schools/sale_points';


const defaultSellPointsState = {};
export type SellPoints = MenuSalePointsResult
export type SellPointItem = SalePointItem

export default function useSellPointsData(
  orgId: number,
  buildingId: number | null,
  autoload?: boolean = true,
): [
  [
    {
      itemsCurrent: Array<SellPointItem>,
      itemsDeleted: Array<SellPointItem>,
      sellPoints: MenuSalePointsResult
    },
    boolean,
    () => Promise<void>
  ],
  [(((SellPoints) => SellPoints) | SellPoints) => void]
] {
  const [sellPoints, setSellPoints] = useState<MenuSalePointsResult>(defaultSellPointsState);
  const [sellPointsLoading, setSellPointsLoading] = useState(true);

  const handleRequestSellPoints = useCallback(async () => {
    if (!buildingId) return;
    setSellPointsLoading(true);
    const res = await api.request(GET_MENU_SALE_POINTS, {
      error: 'Не удалось загрузить точки продаж',
      requestPathParams: { orgId },
      params: {
        // теперь необязательный параметр в этом запросе
        // можно загружать точки продаж для всех корпусов сразу
        building_id: buildingId,
      },
    });
    setSellPointsLoading(false);

    if (res) {
      setSellPoints(res);
    }
  }, [orgId, buildingId]);

  useEffect(() => {
    if (autoload) {
      handleRequestSellPoints();
    }
  }, [autoload, handleRequestSellPoints]);


  const sellPointsGroupped = useMemo(() => {
    const {
      itemsCurrent = [],
      itemsDeleted = [],
    }: {
      itemsCurrent?: Array<SalePointItem>,
      itemsDeleted?: Array<SalePointItem>,
    } = pipe(
      values,
      groupBy(({ deleted }) => (deleted ? 'itemsDeleted' : 'itemsCurrent')),
    )(sellPoints);

    return {
      itemsCurrent,
      itemsDeleted,
      sellPoints,
    };
  }, [sellPoints]);


  return [
    [sellPointsGroupped, sellPointsLoading, handleRequestSellPoints],
    [setSellPoints],
  ];
}
