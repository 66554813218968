import { assoc } from 'ramda'
import { useCallback, useState } from 'react'


const defaultErrorsState = {}

export default function useErrors() {
  const [errors, setErrors] = useState<Record<string, string>>(defaultErrorsState)

  const setError = useCallback((key: string, value: string) => {
    setErrors(assoc(key, value))
  }, [])

  const reset = useCallback(() => {
    setErrors(defaultErrorsState)
  }, [])

  return {
    errors,
    setError,
    reset,
  }
}
