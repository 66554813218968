// @flow
import React, { type Node, useMemo, useEffect } from 'react';
import { pipe, filter, map } from 'ramda';

import { PlanogramData, PlanogramItem } from 'app/common/ui-components/planograms';
import type { PlanogramSlotContent } from 'app/dataTypes/vending/PlanogramContent.types';

import useActualSlotsData from './useActualSlotsData';
import ProductsTable from './ProductsTable';
import useSelectionState from '../../_selection/useSelectionState';
import useAmountAddBySlotId from './useAmountAddBySlotId';


export type SelectedItem = {
  ...PlanogramSlotContent,
  productTitle: string,
  amountModify: number,
}

type Props = {
  data: PlanogramData<PlanogramItem>,
  placeId: string,
  headers?: Array<string>,
  onSelect: (Array<SelectedItem>) => void,
  getModifyDiff: (amount: number, amountMax: number) => number,
  getModifyRest: (amount: number, amountMax: number) => number,
}

const SlotsDishes = ({ data, placeId, headers, onSelect, getModifyDiff, getModifyRest }: Props): Node => {
  const [actualSlotsData, loading] = useActualSlotsData(data, placeId);

  const [defaultSelectionState, defaultAmountAdd] = useMemo(() => {
    return [
      new Map(actualSlotsData.map(({ slotId }) => ([slotId, true]))),
      new Map(actualSlotsData.map(({ slotId, amount, amountMax }) => ([
        slotId,
        getModifyDiff(amount, amountMax),
      ]))),
    ];
  }, [actualSlotsData, getModifyDiff]);

  const { selection, selectItem, selectedAll } = useSelectionState(defaultSelectionState);
  const { amountAddBySlotId, increase, decrease, change } = useAmountAddBySlotId(defaultAmountAdd);

  useEffect(() => {
    onSelect(
      pipe(
        filter(({ slotId }) => !!selection.get(slotId)),
        map(({
          slotId,
          productId,
          amount,
          amountMax,
          productTitle,
        }) => {
          const amountModify = amountAddBySlotId.get(slotId);
          const maxDif = getModifyDiff(amount, amountMax);
          const validAmountModify = (
            amountModify !== undefined
            && amountModify <= maxDif
            && amountModify >= 0
              ? amountModify
              : maxDif
          );

          return ({
            slotId,
            productId,
            amount,
            productTitle,
            amountModify: validAmountModify,
          });
        }),
      )(actualSlotsData),
    );
  }, [selection, amountAddBySlotId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProductsTable
      loading={loading}
      products={actualSlotsData}
      headers={headers}
      selection={selection}
      selectedAll={selectedAll}
      onChange={selectItem}
      getModifyDiff={getModifyDiff}
      getModifyRest={getModifyRest}
      amountAddBySlotId={amountAddBySlotId}
      increase={increase}
      decrease={decrease}
      change={change}
    />
  );
};

export default SlotsDishes;
