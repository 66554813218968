// @flow
import React, { Fragment } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import EmptyWarn from 'app/common/layouts/EmptyWarn';
import Loading from 'app/common/components/Loader';

import DishesKitSimple from './DishesKitSimple';
import useOrgsKitsSimple from './useOrgsKitsSimple';


type Props = {
  act: 'deleted' | 'hidden',
  orgID: string,
}

const KitsListSimple = ({
  act,
  orgID,
}: Props) => {
  const [items, loading, { restore }] = useOrgsKitsSimple(orgID, { type: act });
  const nothing = isEmpty(items);

  if (loading && nothing) {
    return (
      <Loading />
    );
  }

  if (nothing) {
    return (
      <EmptyWarn>
        {'Нет комплектов в текущем подразделе'}
      </EmptyWarn>
    );
  }

  return (
    <Fragment>
      {items.map(({ id, title, cost }) => (<DishesKitSimple
        key={id}
        id={id}
        title={title}
        cost={cost}
        onRestore={restore}
        loading={loading}
      />))}
    </Fragment>
  );
};

export default KitsListSimple;
