// @flow
import { useState, useCallback } from 'react';
import F from 'ramda/es/F';

import { api } from 'app/api';
import { GET_VENDING_MACHINE_SINGLE } from 'app/common/api/requests/data/vending/machines/get';
import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';

import api2machineState from './api2machineState';


export type MachineRequest = [boolean, (machineId: number | string) => boolean | Promise<boolean>]
export const defaultMachineRequest: MachineRequest = [false, F];

export default function useMachineRequest(
  setMachine: ((MachineSingle => MachineSingle) | MachineSingle) => void,
): MachineRequest {
  const [requesting, setRequesting] = useState(false);

  const request = useCallback(async (machineId: number | string) => {
    setRequesting(true);
    const res = await api.request(GET_VENDING_MACHINE_SINGLE, {
      error: 'Не удалось получить данные аппарата',
      requestPathParams: {
        machineId,
      },
    });
    if (res) {
      setMachine(api2machineState(res));
    }
    setRequesting(false);

    return !!res;
  }, [setMachine]);

  return [requesting, request];
}
