import React, { useCallback } from 'react'

import CommonPage from 'app/common/templates/CommonPage'
import { IsReportEnabled, SubReportChooser, getReportTitle } from 'app/components/Reports'
import Loading from 'app/common/components/Loader'
import Accounting, { dataKey as accountingReport } from 'app/components/Reports/_Accounting'
import Balance, { dataKey as balanceReport } from 'app/components/Reports/_Balance'
import Sales, { dataKey as salesReport } from 'app/components/Reports/_Sales'
import SalesByGroupComplexes, { dataKey as salesByGroupComplexesReport } from 'app/components/Reports/_SalesByGroupComplexes'
import PurchasesByPaymentMode, { dataKey as purchasesByPaymentModeReport } from 'app/components/Reports/_PurchasesByPaymentMode'
import SalesByFunds, { dataKey as salesByFundsReport } from 'app/components/Reports/_SalesByFunds'
import Subsidies, { dataKey as subsidiesReport } from 'app/components/Reports/_Subsidies'
import Corrections, { dataKey as correctionsReport } from 'app/components/Reports/_Corrections'


import getBaseUrl from './getBaseUrl'


const rootUrl = getBaseUrl()

type Props = {
  match: {
    params: {
      report: string,
      subReport?: string,
    }
  },
  history: {
    push: Function,
    replace: Function,
  }
}

const ReportPage = ({ match, history }: Props) => {
  const { params: { report, subReport } } = match
  const { push, replace } = history

  const redirectToRoot = useCallback(() => {
    push(rootUrl)
  }, [push])

  const redirectToReport = useCallback((newReport: string) => {
    replace(getBaseUrl({ report, subReport: newReport }))
  }, [replace, report])

  return (
    <CommonPage header={`Отчет: ${getReportTitle(report)}`}>
      <IsReportEnabled
        report={report}
        fallback={<Loading />}
        onReportDisabled={redirectToRoot}
      >
        <SubReportChooser
          baseUrl={getBaseUrl({ report })}
          redirectToReport={redirectToReport}
          subReport={subReport}
          report={report}
        >
          {report === accountingReport && <Accounting />}
          {report === balanceReport && <Balance />}
          {report === salesReport && <Sales />}
          {report === salesByGroupComplexesReport && <SalesByGroupComplexes />}
          {report === purchasesByPaymentModeReport && <PurchasesByPaymentMode />}
          {report === salesByFundsReport && <SalesByFunds />}
          {report === correctionsReport && <Corrections />}
          {report === subsidiesReport && subReport && <Subsidies subReport={subReport} />}
        </SubReportChooser>
      </IsReportEnabled>
    </CommonPage>
  )
}

export default ReportPage
