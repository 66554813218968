// @flow
import reduce from 'ramda/es/reduce';
import reduced from 'ramda/es/reduced';
import addIndex from 'ramda/es/addIndex';
import replace from 'ramda/es/replace';

import { getPlaceholder, getPrefix } from 'app/common/lib/phone';

import { maskTypes } from './mask-types';
// import type { PlainInputMaskedProps } from './plain-input-masked';


const reduceIndexed = addIndex(reduce);

const formatByPlaceholder = (placeholder: string, prefix?: string = '') => (value: string) => {
  const source = value.split('').reverse();
  const placeholderCut = placeholder.substring(prefix.length);
  const x = reduceIndexed((acc, val, i) => {
    if (source.length === 0) return reduced(acc);
    return (placeholderCut[i] === '_'
      ? acc + source.pop()
      : acc + placeholderCut[i]);
  }, '', placeholderCut);
  return prefix + x;
};

type PredefinedParams = any

export const getPredefinedParams = (type: $Values<typeof maskTypes>): PredefinedParams => {
  if (type === maskTypes.MASK_SNILS) {
    const placeholder = '___-___-___ __';
    return ({
      format: placeholder.replace(/_/g, '#'),
      placeholder,
      mask: '_',
    });
  }

  if (type === maskTypes.MASK_DATE) {
    const placeholder = '__.__.____';
    return ({
      format: formatByPlaceholder(placeholder),
      placeholder,
      mask: '_',
    });
  }

  if (type === maskTypes.MASK_DAY_MONTH) {
    const placeholder = '__.__';
    return ({
      format: formatByPlaceholder(placeholder),
      placeholder,
      mask: '_',
    });
  }

  if (type === maskTypes.MASK_TIME) {
    const placeholder = '__ : __';
    return ({
      format: placeholder.replace(/_/g, '#'),
      placeholder,
      mask: '_',
    });
  }

  if (type === maskTypes.MASK_PHONE_RU) {
    const placeholder = getPlaceholder('ru');
    const prefix = getPrefix('ru');
    return ({
      format: formatByPlaceholder(placeholder, prefix), // placeholder.replace(/_/g, '#'),
      placeholder,
      prefix,
      removeFormatting: replace(/(^\+7|\D)/g, ''),
      allowEmptyFormatting: true,
      mask: '_',
    });
  }

  if (type === maskTypes.MASK_PHONE_KG) {
    const placeholder = getPlaceholder('kg');
    const prefix = getPrefix('kg');
    return ({
      format: placeholder.replace(/_/g, '#'),
      placeholder,
      prefix,
      removeFormatting: replace(/(^\+996|\D)/g, ''),
      allowEmptyFormatting: true,
      mask: '_',
    });
  }

  if (type === maskTypes.MASK_PHONE_KZ) {
    const placeholder = getPlaceholder('kz');
    const prefix = getPrefix('kz');
    return ({
      format: placeholder.replace(/_/g, '#'),
      placeholder,
      prefix,
      removeFormatting: replace(/(^\+7|\D)/g, ''),
      allowEmptyFormatting: true,
      mask: '_',
    });
  }

  if (type === maskTypes.MASK_PHONE_UA) {
    const placeholder = getPlaceholder('ua');
    const prefix = getPrefix('ua');
    return ({
      format: placeholder.replace(/_/g, '#'),
      placeholder,
      prefix,
      removeFormatting: replace(/(^\+380|\D)/g, ''),
      allowEmptyFormatting: true,
      mask: '_',
    });
  }

  return Object.freeze({});
};
