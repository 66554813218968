import { SupportScheduleType, ScheduleItemType } from './SupportScheduleType'


export type { SupportScheduleType, ScheduleItemType }

export const paymentOrderKinds = Object.freeze({
  school: 'school',
  camp: 'camp',
})

export type PaymentOrderKind = keyof typeof paymentOrderKinds
export const mustBePaymentOrderKind = (val: string): PaymentOrderKind | null => (
  paymentOrderKinds[val] ? val as PaymentOrderKind : null
)

export const reportAddresses = Object.freeze({
  law_address: 'law_address',
  address: 'address',
  post_address: 'post_address',
})

export type ReportAddress = keyof typeof reportAddresses

export const paymentOrderMethods = Object.freeze({
  SberOnline: 'SberOnline',
  SberOffline: 'SberOffline',
  Offline: 'Offline',
})

export type PaymentOrderMethod = keyof typeof paymentOrderMethods

export const paymentOrderPurposes = Object.freeze({
  FoodType: 'FoodType', // Вид питания
  ClassGroup: 'ClassGroup', // Класс/Группа
  OrgName: 'OrgName', // Наименование заведения
  ActivityKind: 'ActivityKind', // Вид деятельности
  PersonalAccount: 'PersonalAccount', // Лицевой счет
})

export type PaymentOrderPurpose = keyof typeof paymentOrderPurposes

export const paymentOrderCreateMethods = Object.freeze({
  Api: 'Api', // выставление с помощью API внешних систем
  Web: 'Web', // выставление вручную в web-приложении "Личный кабинет сотрудника ОУ"
  Auto: 'Auto', // выставление автоматически по заданным параметрам
})

export type PaymentOrderCreateMethod = keyof typeof paymentOrderCreateMethods

export type SingleRequisites = {
  kind: PaymentOrderKind,
  orgType: string,
  title: string,
  lawAddress: string,
  address: string,
  postAddress: string,
  reportAddress: ReportAddress,
  inn: string,
  kpp: string,
  orgn: string,
  okpo: string,
  bik: string,
  bank: string,
  bankAddress: string,
  corr: string,
  rc: string,
  oktmo: string,
  kbk: string,
  lc: string,
  paymentOrderMethod: PaymentOrderMethod | null,
  paymentOrderServiceCode: string,
  paymentOrderPurpose: Set<PaymentOrderPurpose>,
  paymentOrderCreateMethods: Set<PaymentOrderCreateMethod>,
}

export type Person = {
  name: {
    first: string,
    last: string,
    middle: string,
  },
  phone: string,
  email: string,
  comment: string,
}

export type SupportContactsItemType = {
  ID: string
  position: string
  kind: string
  samePositionValues: Array<{
    ID: string
    value: string
  }>
}

export type SupportContactsType = Array<SupportContactsItemType>

export type Requisites = {
  person: Person
  accountant: Person
  requisites: Map<PaymentOrderKind, SingleRequisites>
  supportContacts: SupportContactsType
  supportSchedule: SupportScheduleType
}
