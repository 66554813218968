import { useCallback, useRef } from 'react'

import { api } from 'app/api'
import { GET_ORG_GENERAL_SETTINGS } from 'app/common/api/requests/food/schools/general_settings'
import type { GeneralSettings } from 'app/dataTypes/org/settings/GeneralSettings'


export default function useGeneralSettingsRequestSingleton(orgId: number) {
  const requestPromise = useRef<null | Promise<GeneralSettings | null>>(null)

  const requestGeneralSettings = useCallback(async () => {
    const res: GeneralSettings | null = await api.request(GET_ORG_GENERAL_SETTINGS, {
      error: 'Не удалось получить общие настройки заведения',
      requestPathParams: {
        orgId,
      },
    })
    return res
  }, [orgId])

  const request = useCallback(async () => {
    if (!requestPromise.current) {
      requestPromise.current = requestGeneralSettings()
    }
    return requestPromise.current
  }, [requestGeneralSettings])

  return request
}
