import { createSelector } from 'reselect';
import length from 'ramda/es/length';
import path from 'ramda/es/path';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import keys from 'ramda/es/keys';
import isEmpty from 'ramda/es/isEmpty';
import identity from 'ramda/es/identity';
import moment from 'moment-timezone';

import * as availableClassTypes from 'app/actions/reports/filters/classTypesAvailable';
import * as availableSubsidyTypes from 'app/actions/reports/filters/subsidyTypesAvailable';
import * as orgsTypes from 'app/common/constants/schools/types';
import { BY_GROUP_COMPLEXES } from 'app/actions/reports/filters/group';


/**
 * Селекторы использующиеся в нескольких hoc'ах
 * Все возвращают id элемента, но только если он выбран один
 */

const getSingleId = pipe(
  values,
  arr => (length(arr) === 1
    ? path([0, 'id'], arr)
    : null
  ),
);

export const selectOrgId = createSelector(
  state => state.reports.filters.orgs.selected,
  getSingleId,
);

export const selectYearId = createSelector(
  state => state.reports.filters.years.selected,
  getSingleId,
);

export const selectClassId = createSelector(
  state => state.reports.filters.classes.selected,
  getSingleId,
);

// removed filter by status
// export const selectStatusId = createSelector(
//   state => state.reports.filters.studentsStatuses.selected,
//   getSingleId
// );

export const selectCustomerId = createSelector(
  state => state.reports.filters.customers.selected,
  getSingleId,
);

export const selectSalesType = createSelector(
  state => state.reports.filters.salesTypes.selected,
  getSingleId,
);

export const selectStatusesIds = createSelector(
  state => state.reports.filters.studentStatuses.selected,
  keys,
);

export const selectIsMinorStatuses = createSelector(
  [
    state => state.reports.filters.studentStatuses.active,
    state => state.reports.filters.studentStatuses.selected,
  ],
  (active, selected) => (
    active && !isEmpty(selected) && !selected.active
  ),
);

export const selectClassType: (
  (state: Object) => $Values<typeof availableClassTypes>
) = createSelector(
  state => state.reports.filters.classTypes.selected,
  getSingleId,
);

export const selectSubsidyTypeActivity: (
  (state: Object) => $Values<typeof availableSubsidyTypes>
) = createSelector(
  state => state.reports.filters.subsidyTypes.active,
  identity,
);

export const selectSubsidyType: (
  (state: Object) => $Values<typeof availableSubsidyTypes>
) = createSelector(
  state => state.reports.filters.subsidyTypes.selected,
  getSingleId,
);

export const selectOrgsType: (
  (state: Object) => $Values<typeof orgsTypes>
) = createSelector(
  state => state.reports.filters.orgsTypes.selected,
  getSingleId,
);

export const selectIsDetailsSpecial: (state: Object) => boolean = createSelector(
  [
    state => state.reports.filters.group.active,
    state => state.reports.filters.group.current,
    state => state.reports.filters.groupSpecial.active,
    state => state.reports.filters.groupSpecial.current,
  ],
  (groupActive, group, groupSpecialActive, groupSpecial) => {
    return (
      (groupActive && group === BY_GROUP_COMPLEXES)
      || (groupSpecialActive && groupSpecial)
    );
  },
);

export const selectIsOldPeriod: (state: Object) => boolean = createSelector(
  [
    state => state.reports.filters.dateFromTo.dateTo,
  ],
  (dateTo) => {
    return !moment(dateTo).isSame(moment(), 'month');
  },
);

export const selectOldPeriodAndDetailsSpecial: (state: Object) => boolean = createSelector(
  [
    selectIsDetailsSpecial,
    selectIsOldPeriod,
  ],
  (isDetailsSpecial, isOldPeriod) => (
    isDetailsSpecial && isOldPeriod
  ),
);
