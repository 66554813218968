import { memo, useEffect } from 'react'

import FiltersPlainLayout, { Column } from 'app/common/layouts/FiltersPlainLayout'
import { FilterItem, useFilterData } from 'app/common/ui-components/filter'
import { RowLayoutContent } from 'app/common/layouts/RowLayout'

import mailFilter from 'app/components/common/reports/filters/mail'
import detailsFilter from 'app/components/common/reports/filters/details'
import { ReportSection, ResetLink } from 'app/common/templates-next/report-template'

import periodFilter from './filters/period'
import styles from './ReportFilter.scss'


type Props = {
  loading: boolean,
}

const ReportFilter = memo<Props>(({ loading }: Props) => {
  const [,,, { filtersLoad, resetAll, applyFilters }] = useFilterData()

  useEffect(() => {
    filtersLoad()
  }, [filtersLoad])

  return (
    <ReportSection
      header="Фильтры отчета «ЭДС»"
      headerBar={
        <ResetLink onClick={resetAll}>
          {'Сбросить все фильтры'}
        </ResetLink>
      }
      resetStickyShift={false}
    >
      {/* Окно с фильтром */}
      <RowLayoutContent paddingfree>
        <FiltersPlainLayout
          onApplyFilter={applyFilters}
          onResetAll={resetAll}
          loading={loading}
          skipHeader
        >
          <Column className={styles.column}>
            <FilterItem filterId={periodFilter} />
            <FilterItem filterId={detailsFilter} />
          </Column>
          <Column className={styles.column}>
            <FilterItem filterId={mailFilter} />
          </Column>
        </FiltersPlainLayout>
      </RowLayoutContent>
    </ReportSection>
  )
})

export default ReportFilter
