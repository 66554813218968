import get from 'app/common/lib/get';
import { popup } from 'app/common/actions/popup';
import api from 'app/actions/api';
import i18n from 'app/i18n';

const WRONG_FORMAT = 400;
const ALREADY_USED = 409;
const ALREADY_SENT = 429;
const INVALID_CODE = 403;

export const CODE_EMAIL_REQUEST = 'CODE_EMAIL_REQUEST';
export const CODE_EMAIL_SUCCESS = 'CODE_EMAIL_SUCCESS';
export const CODE_EMAIL_FAILURE = 'CODE_EMAIL_FAILURE';


const codeEmailRequest = () => ({
  type: CODE_EMAIL_REQUEST,
});

const codeEmailSuccess = payload => ({
  type: CODE_EMAIL_SUCCESS,
  payload,
});

const codeEmailFailure = payload => ({
  type: CODE_EMAIL_FAILURE,
  payload,
});


export const emailCode = email => (
  async (dispatch) => {
    dispatch(codeEmailRequest());
    try {
      const res = await api('account/confirmation/email', 'post', {
        data: { email },
      });
      const lockedUntil = Date.now() + parseInt(get(res, 'data.time_left', ''), 10);
      localStorage.lockedUntil = lockedUntil;
      dispatch(codeEmailSuccess({
        resultTo: get(res, 'data.result_to', ''),
        lockedUntil,
        dateNow: Date.now(),
      }));
    } catch (error) {
      let requestCodeError = '';
      const errCode = get(error, 'response.status', '');

      if (errCode === WRONG_FORMAT) {
        requestCodeError = i18n.t('profile:error_enter_valid_email');
      }
      if (errCode === ALREADY_USED) {
        requestCodeError = i18n.t('profile:error_email_already_used');
      }
      if (errCode === ALREADY_SENT) {
        requestCodeError = i18n.t('profile:error_code_already_sent');
      }
      dispatch(codeEmailFailure({
        err: i18n.t('profile:error_code_receive_failed'),
        requestCodeError,
        error,
      }));
    }
  }
);


export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE';


const changeEmailRequest = () => ({
  type: CHANGE_EMAIL_REQUEST,
});

const changeEmailSuccess = payload => ({
  type: CHANGE_EMAIL_SUCCESS,
  payload,
});

const changeEmailFailure = payload => ({
  type: CHANGE_EMAIL_FAILURE,
  payload,
});

export const changeEmailProcess = (email, code, cb) => (
  async (dispatch) => {
    dispatch(changeEmailRequest());

    try {
      await api('account/email', 'post', {
        data: { email, confirmation: code },
      });
      dispatch(changeEmailSuccess({ email }));
      dispatch(popup(i18n.t('profile:msg_email_changed_success')));
      if (typeof cb === 'function') {
        cb();
      }
    } catch (error) {
      let codeError = '';
      const errCode = get(error, 'response.status', '');

      if (errCode === WRONG_FORMAT) {
        codeError = i18n.t('profile:error_email_not_valid');
      }
      if (errCode === ALREADY_USED) {
        codeError = i18n.t('profile:error_email_already_used');
      }
      if (errCode === INVALID_CODE) {
        codeError = i18n.t('profile:error_code_invalid');
      }
      dispatch(changeEmailFailure({
        err: i18n.t('profile:error_email_change_failed'),
        codeError,
        error,
      }));
    }
  }
);
