// @flow
import React from 'react';

import PlainSelect from '../PlainSelect';
import styles from './YearMonthForm.scss';


const currentYear = new Date().getFullYear();

type Props = {
  date: Object,
  localeUtils: Object,
  onMonthChange: Function,
  onYearChange: Function,
};

const YearMonthForm = ({ date, localeUtils, onMonthChange, onYearChange }: Props) => {
  const months = localeUtils.getMonths().map((month, i) => ({ id: i, title: month }));
  const years = [];
  for (let i = currentYear + 5; i >= 1900; i -= 1) {
    years.push({ id: i, title: i.toString() });
  }

  return (
    <div className="DayPicker-Caption">
      <div className={styles.root}>
        <PlainSelect
          data={months}
          style={{ width: 110 }}
          current={date.getMonth()}
          onChange={onMonthChange}
        />
        <PlainSelect
          style={{ marginLeft: 4, width: 80 }}
          data={years}
          current={date.getFullYear()}
          onChange={onYearChange}
        />
      </div>
    </div>
  );
};

export default YearMonthForm;
