// @flow
import React, { type Node, useRef, useState, Fragment } from 'react';
import cn from 'classnames';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalContainer,
  PlainModalFooter,
  // useModalState,
  type HandleModalDisplayed,
  type PlainModalProps,
} from 'app/common/ui-next/plain-modal';

import HeaderContent from './HeaderContent';
import ModifyList from './ModifyList';
import FooterContent from './FooterContent';
import Form from './Form';

import styles from './AddRemoveItemsModalTemplate.scss';


type Item = { id: string, title: string }

type Props = {
  ...PlainModalProps,
  header?: string,
  disableSubmit?: boolean,
  submitButtonTitle?: string,
  submitButtonTitleInProcess?: string,
  disableSubmitReason?: string,
  headerContent?: Node,
  mainContent: Node,
  mainContentClassName?: string,
  modifyContent?: Node,
  onSearch?: (string) => void,
  onSubmit: () => Promise<boolean>,
  willAddItems?: Array<Item>,
  willRemoveItems?: Array<Item>,
  hideWillRemove?: boolean,
  hideLegend?: boolean,
  children?: (HandleModalDisplayed) => Node,
}

const AddRemoveItemsModal = ({
  children,
  header = 'Редактирование списка продукции',
  submitButtonTitle = 'Сохранить в текущую категорию',
  submitButtonTitleInProcess = 'Сохранение...',
  disableSubmitReason = 'Необходимо выбрать/удалить хотя бы одну продукцию',
  headerContent,
  mainContent,
  mainContentClassName,
  modifyContent,
  onSearch,
  onSubmit,
  disableSubmit,
  willAddItems = [],
  willRemoveItems = [],
  hideWillRemove,
  hideLegend,
  className,
  ...others
}: Props): Node => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [submitting, setSubmitting] = useState(false);

  return (
    <PlainModal
      {...others}
      className={cn(styles.root, className)}
      controlBy={children}
    >
      <PlainModalHeader
        className={styles.header}
        headerComponent="div"
        hideCloseButton
      >
        {modalDisplayed => (
          <HeaderContent
            header={header}
            onSearch={onSearch}
            modalDisplayed={modalDisplayed}
          >
            {headerContent || ''}
          </HeaderContent>
        )}
      </PlainModalHeader>

      <PlainModalContainer>
        <PlainModalContent
          className={mainContentClassName}
          unscrollable
          paddingFree
        >
          {handleModalDisplayed => (
            <Form
              onSubmit={onSubmit}
              displayModal={handleModalDisplayed}
              reference={formRef}
              onSubmitStatusChange={setSubmitting}
            >
              {mainContent}
            </Form>
          )}
        </PlainModalContent>
        <PlainModalContent className={styles.modifyListContent}>
          {modifyContent
            || <Fragment>
              <ModifyList header="Будет добавлено" items={willAddItems} />
              {!hideWillRemove
                && <ModifyList header="Будет удалено" items={willRemoveItems} />}
            </Fragment>}
        </PlainModalContent>
      </PlainModalContainer>

      <PlainModalFooter
        className={styles.footer}
      >
        {handleModalDisplayed => (
          <FooterContent
            submitButtonTitle={submitButtonTitle}
            submitButtonTitleInProcess={submitButtonTitleInProcess}
            disableSubmitReason={disableSubmitReason}
            form={formRef.current}
            disableSubmit={disableSubmit}
            displayModal={handleModalDisplayed}
            submitting={submitting}
            hideLegend={hideLegend}
          />
        )}
      </PlainModalFooter>
    </PlainModal>
  );
};

export default AddRemoveItemsModal;
