import { Fragment } from 'react'
import { Route, Redirect } from 'react-router'

import CSSSticky from 'app/common/ui-components/css-sticky'
import OneEmployeePage from 'app/pages/OneEmployeePage'

import {
  EmployeesAtWork,
  EmployeesOffice,
  dataKey,
  employeeTypes,
} from 'app/components/Settings/Employees'
import { rootRights, specificRights } from 'app/rights'
import { useRightsByPath } from 'app/rights/Rights'

import Navigation from '../Navigation'
import AccessDenied from '../AccessDenied'
import { baseUrl as rootBaseUrl } from '../baseUrl'

import styles from './EmployeesPage.scss'


export const baseUrl = `${rootBaseUrl}/${dataKey}`
const allTypes = ['atwork', 'office']
const orgEmployeesRights = [rootRights.USERS, specificRights.SCHOOL_EMPLOYEES] as const

type Props = {
  match: {
    params: {
      type?: string,
    }
  }
}

/**
 *  Страница Настройки/Сотрудники
 */

const EmployeesPage = ({ match }: Props) => {
  const { params: { type } } = match
  const { write, read, su } = useRightsByPath({ pathTo: orgEmployeesRights })
  const currentEmployeeType = type as ('atwork' | 'office')

  if (!read) {
    return (
      <AccessDenied />
    )
  }

  if (
    (type && !allTypes.includes(type)) // неизвестный тип
    || (!su && type !== employeeTypes.ATWORK) // для обычных пользователей доступны только сотрудники ОУ
  ) {
    return (
      <Redirect to={`${baseUrl}/${employeeTypes.ATWORK}`} />
    )
  }

  return (
    <Fragment>
      <CSSSticky
        number={1}
        className={styles.stickableMenu}
      >
        <Navigation />
      </CSSSticky>

      {currentEmployeeType === employeeTypes.ATWORK
        && <EmployeesAtWork
          type={currentEmployeeType}
          baseUrl={baseUrl}
          writeAccess={write}
        />}

      {currentEmployeeType === employeeTypes.OFFICE
        && <EmployeesOffice
          type={currentEmployeeType}
          baseUrl={baseUrl}
          writeAccess={write}
        />}
    </Fragment>
  )
}

export const employeesPageRoutes = [
  <Route key="employeeSection" path={`${baseUrl}/:type/employee/:id/:section`} component={OneEmployeePage} exact />,
  <Route key="employee" path={`${baseUrl}/:type/employee/:id`} component={OneEmployeePage} exact />,
  <Route key="employees" path={`${baseUrl}/:type`} component={EmployeesPage} exact />,
  <Redirect key="employeesRedirect" from={baseUrl} to={`${baseUrl}/${employeeTypes.ATWORK}`} />,
]
