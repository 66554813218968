// @flow
import React, { type Node } from 'react';

import isProduction from 'app/common/lib/isProduction';

import styles from './footer.scss';

/**
 *  Контeйнер для подвала
 */

const ver = (
  isProduction()
    ? 'build date: [AIV]{date}[/AIV]'
    : 'build date: [AIV]{date}[/AIV] (dev)'
);

type Props = {
  children: Node
}

const Footer = ({ children }: Props): Node => {
  return (
    <div className={styles.container}>
      <hr className={styles.hr} />
      <div className={styles.content}>
        {children}
      </div>
      <div className={styles.ver}>{ver}</div>
    </div>
  );
};

export default Footer;
