import { createSelector } from 'reselect';
import flatten from 'ramda/es/flatten';
import pluck from 'ramda/es/pluck';
import sum from 'ramda/es/sum';
import pipe from 'ramda/es/pipe';

import toFixedValue from 'app/common/lib/toFixedValue';


const selectProps = createSelector(
  [
    (_, { items }) => items,
    (_, { kits }) => kits,
    state => state.orgs.menu.options.needUpdate,
    state => state.orgs.menu.items.loading,
    state => state.orgs.dishes.categories.loading,
  ],
  (items, kits, needUpdate, loadingItems, loadingCategories) => {
    return ({
      items,
      kits,
      total: `${toFixedValue(
        pipe(flatten, pluck('cost'), sum)(items)
        + pipe(pluck('cost'), sum)(kits),
      ) || 0}`,
      needUpdate,
      loading: loadingItems || loadingCategories,
    });
  },

);

export default selectProps;
