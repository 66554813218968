// @flow
import { productionTypes, type ProductionTypes } from 'app/dataTypes/reports/productionTypes';
import { foodModes, type FoodMode } from 'app/dataTypes/foodModes';
import { PaymentSource } from 'app/dataTypes/reports/finances/paymentSource';

import type { Request } from '../index';
import { statuses, type Status } from '../food/schools/cafeteria_orders';
import * as studentStatuses from './studentStatuses';
import { formats } from './generate_file';


export { foodModes, statuses, studentStatuses };
export type { Status };

const REPORT_BALANCES: 'balances' = 'balances';
const REPORT_SUBSIDIES_DETAILED: 'subsidies_detailed' = 'subsidies_detailed';
const REPORT_PURCHASES: 'purchases' = 'purchases';
const REPOST_PURCHASES_BY_GROUP_COMPLEXES_AGGREGATED: 'purchases_by_group_complexes_aggregated' = 'purchases_by_group_complexes_aggregated';
const REPORT_PURCHASES_BY_GROUP_COMPLEXES_2: 'purchases_by_group_complexes_2' = 'purchases_by_group_complexes_2';
const REPORT_PURCHASES_BY_PAYMENT_MODE: 'purchases_by_payment_mode' = 'purchases_by_payment_mode';
const REPORT_ORDERS_PURCHASES: 'orders_purchases' = 'orders_purchases';
const REPORT_ACCOUNTING_CUSTOMERS: 'accounting_customers' = 'accounting_customers';
const REPORT_PURCHASES_BY_FUNDS: 'purchases_by_funds' = 'purchases_by_funds';

const REPORT_FINANCES_E_MONEY: 'finances_e_money' = 'finances_e_money';
const REPORT_FINANCES_E_MONEY_LEGACY: 'finances_e_money_legacy' = 'finances_e_money_legacy';
const REPORT_FINANCES_BY_SELL_TYPE: 'finances_by_sale_types' = 'finances_by_sale_types';
const FINANCES_BY_CUSTOMER: 'finances_by_customer' = 'finances_by_customer';
const REPORT_FINANCES_CAFETERIA_CASH: 'cafeteria_cash' = 'cafeteria_cash';

export const reportTypes = {
  REPORT_BALANCES,
  REPORT_SUBSIDIES_DETAILED,
  REPORT_PURCHASES,
  REPOST_PURCHASES_BY_GROUP_COMPLEXES_AGGREGATED,
  REPORT_PURCHASES_BY_GROUP_COMPLEXES_2,
  REPORT_PURCHASES_BY_PAYMENT_MODE,
  REPORT_ORDERS_PURCHASES,
  REPORT_ACCOUNTING_CUSTOMERS,
  REPORT_PURCHASES_BY_FUNDS,

  REPORT_FINANCES_E_MONEY,
  REPORT_FINANCES_E_MONEY_LEGACY,
  REPORT_FINANCES_BY_SELL_TYPE,
  FINANCES_BY_CUSTOMER,
  REPORT_FINANCES_CAFETERIA_CASH,
};

export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

const FUND_BUDGET: 'budget' = 'budget';
const FUND_PARENTS: 'parents' = 'parents';

export const fundTypes = {
  FUND_BUDGET,
  FUND_PARENTS,
};

/**
 *  REPORT_BALANCES
 */

export type DetailsGroup = 'by_groups_and_types' | 'by_users'

export type ReportsPerformBalancesParams = {
  report_type: typeof REPORT_BALANCES, // тип отчета,
  params: {
    limits: {
      customer_type?: Array<'Учащиеся' | 'Сотрудники' | 'Гость'>, // тип покупателя
      students_statuses?: Array<$Values<typeof studentStatuses>>, // статус учащегося, active|expelled|graduated|pending
      school_ids?: Array<number>, // id школ
      klass_ids?: Array<number>, // id классов, будет принято в случае отсутствия student_ids, имеет приоритет над school_ids в случае customer_type==[Учащиеся], имеет приоритет над student_status
      student_ids?: Array<number>, // id учащихся, имеет приоритет над всеми прочими фильтрами в случае customer_type==[Учащиеся]
      employee_ids?: Array<number>, // id сотрудников, имеет приоритет над всеми прочими фильтрами в случае customer_type==[Сотрудники ОУ|Сотрудники КШП]
    },
    details: boolean, // детализированный отчет
    details_group: DetailsGroup, // фильтр, определяющий набор данных - суммы по классам и типам пользователя или по каждому пользователю
    date: string, // для вывода зафиксированных балансов
    current: boolean, // для вывода текущих балансов, дата игнорируется
    balance_sign?: Array<"pos" | "neg">, // положительный/отрицательный баланс
    balance_ranges?: {
      min: number,
      max: number,
    }, // диапазон балансов, имеет приоритет над balance_sign, не включает 0
    customer_search?: 'by_customer_data'
  },
  options: {
    send_to_email?: string, // отправить на почту по завершении формирования
    additional?: {
      all_schools_zip_file: boolean, // включить в файл архив по всем школам
    }
  }
}

/**
 *  REPORT_SUBSIDIES_DETAILED
 */

// type ReportsPerformSubsidiesDetailedParams = {
//   report_type: typeof REPORT_SUBSIDIES_DETAILED,
// } // TODO

/**
 *  REPORT_PURCHASES
 */

export type ReportsPerformPurchasesParams = {
  report_type: (
    | typeof REPORT_PURCHASES
    | typeof REPOST_PURCHASES_BY_GROUP_COMPLEXES_AGGREGATED
  ),
  params: {
    from: string,
    to: string,
    limits: {
      school_ids?: Array<number>, // id школ
      klass_ids?: Array<number>, // id классов
      customer_type?: Array<'Учащиеся' | 'Сотрудники' | 'Гость'>,
      student_ids?: Array<number>, // id учащихся
      employee_ids?: Array<number>, // id сотрудников (не может комбинироваться с id учащихся)
      sale_type?: 'personal' | 'complex', //  тип продаж (личные, комплексное питание)
      building_id?: Array<number>, //  id здания
      sale_point_id?: Array<number>, //  id точки продаж
      food_mode?: Array<'breakfast' | 'dinner' | 'afternoon'>,
      subsidies?: Array<'with_subsidies' | 'without_subsidies'>,
      order_type?: 'personal' | 'common' | 'all',
      allowances?: Array<string>,
      schoolyear_id?: number,
      item_type?: 'all' | 'items' | 'complexes',
    },
    details: boolean, // детализированный отчет
    details_group: | 'by_group_complexes'
    | 'by_groups_and_types'
    | 'by_purchases'
    | 'by_items_and_complexes'
    | 'by_items_and_complexes_and_cost',
    customer_search?: 'by_customer_data', // указание на то, что отчет формируется по одному покупателю, найденному через поиск
  },
  options: {
    send_to_email?: string, // отправить на почту по завершении формирования
  }
}

export const reportPurchaseItemType = (productionType: ProductionTypes): 'all' | 'items' | 'complexes' => {
  switch (productionType) {
    case productionTypes.Complex:
      return 'complexes';
    case productionTypes.Dish:
      return 'items';
    default:
  }
  return 'all';
};

/**
 *  REPORT_ORDERS_PURCHASES_BY_GROUP_COMPLEXES_AGGREGATED
 */

export type ReportsPerformPurchasesByGroupComplexes2Params = {
  report_type: (
    | typeof REPORT_PURCHASES_BY_GROUP_COMPLEXES_2
    | typeof REPOST_PURCHASES_BY_GROUP_COMPLEXES_AGGREGATED
  ),
  params: {
    from: string,
    to: string,
    limits: {
      school_ids?: Array<number>, // id школ
      klass_ids?: Array<number>, // id классов
      customer_type?: Array<'Учащиеся'>,
      student_ids?: Array<number>, // id учащихся
      employee_ids?: Array<number>, // id сотрудников (не может комбинироваться с id учащихся)
      sale_type?: 'complex', //  тип продаж (личные, комплексное питание)
      building_id?: Array<number>, //  id здания
      sale_point_id?: Array<number>, //  id точки продаж
      food_mode?: Array<'breakfast' | 'dinner' | 'afternoon'>,
      subsidies?: Array<'with_subsidies' | 'without_subsidies'>,
      order_type?: 'personal' | 'common' | 'all',
      allowances?: Array<string>,
      schoolyear_id?: number,
    },
    details: boolean, // детализированный отчет
    details_group: 'by_group_complexes',
  },
  options: {
    send_to_email?: string, // отправить на почту по завершении формирования
  }
}

/**
 *  REPORT_ORDERS_PURCHASES_BY_PAYMENT_MODE
 */

export type ReportsPerformPurchasesByPaymentModeParams = {
  report_type: typeof REPOST_PURCHASES_BY_PAYMENT_MODE,
  params: {
    from: string,
    to: string,
    limits: {
      school_ids?: Array<number>, // id школ
      klass_ids?: Array<number>, // id классов
      customer_type?: Array<'Учащиеся'>,
      student_ids?: Array<number>, // id учащихся
      employee_ids?: Array<number>, // id сотрудников (не может комбинироваться с id учащихся)
      sale_type?: 'complex', //  тип продаж (личные, комплексное питание)
      building_id?: Array<number>, //  id здания
      sale_point_id?: Array<number>, //  id точки продаж
      food_mode?: Array<'breakfast' | 'dinner' | 'afternoon'>,
      subsidies?: Array<'with_subsidies' | 'without_subsidies'>,
      order_type?: 'personal' | 'common' | 'all',
      allowances?: Array<string>,
      schoolyear_id?: number,
    },
    details: boolean, // детализированный отчет
    details_group: 'by_group_complexes',
  },
  options: {
    send_to_email?: string, // отправить на почту по завершении формирования
  }
}

/**
 *  REPORT_ORDERS_PURCHASES
 */

export type ReportsPerformOrdersPurchasesParams = {
  report_type: typeof REPORT_ORDERS_PURCHASES,
  params: {
    from: string,
    to: string,
    school_id: number,
    limits?: {
      klasses_ids?: Array<number>,
      day_cares_ids?: Array<number>, // можно комбинировать klasses_ids и day_cares_ids
      statuses?: Array<Status>,
      food_modes?: Array<FoodMode>,
    },
  },
  options?: {
    send_to_email: string, // отправить на почту
  }
}

/**
 *  REPORT_FINANCES_E_MONEY_LEGACY
 */

export type ReportsPerformFinancesEMoneyLegacyParams = {
  report_type: typeof REPORT_FINANCES_E_MONEY_LEGACY,
  params: {
    date_from: string,
    date_to: string,
  },
  options?: {
    send_to_email?: string, // отправить на почту
  }
}

/**
 *  REPORT_FINANCES_E_MONEY
 */

export type ReportsPerformFinancesEMoneyParams = {
  report_type: typeof REPORT_FINANCES_E_MONEY,
  params: {
    date_from: string,
    date_to: string,
  },
  options?: {
    send_to_email?: string, // отправить на почту
  }
}

/**
 *  REPORT_ACCOUNTING_CUSTOMERS
 */
export type ReportsPerformAccountingCustomersParams = {
  report_type: typeof REPORT_ACCOUNTING_CUSTOMERS,
  params: {
    from: string,
    to: string,
    limits?: {
      school_ids?: Array<number>,
      klass_ids?: Array<number>,
      schoolyear_id?: number,
      customer_type?: Array<'Учащиеся' | 'Сотрудники' | 'Гость'>,
      student_ids?: Array<number>,
      employees_ids?: Array<number>, // не может комбинироваться с id учащихся
      sale_type?: 'personal' | 'complex',
      building_id?: Array<number>,
      sale_point_id?: Array<number>,
      food_modes?: Array<FoodMode>,
    },
    group_purchases: boolean, // группировать личные и комплексные продажи
    show_unpaid: boolean, // отдельно показывать суммы непроведенных заявок (долг)
  },
  // options?: {
  //   send_to_email: string, // отправить на почту
  // }
}

/**
 *  REPORT_PURCHASES_BY_FUNDS
 */

export type ReportsPerformPurchasesByFundsParams = {
  report_type: typeof REPORT_PURCHASES_BY_FUNDS,
  params: {
    date: string,
    limits: {
      school_ids?: Array<number>, // id школ
      funds: 'budget' | 'parents', // вид средств (бюджетные/родительские)
      building_id?: Array<number>, //  id здания
      sale_point_id?: Array<number>, //  id точки продаж
      food_mode?: Array<FoodMode>,
    },
  },
  options?: {
    send_to_email?: string, // отправить на почту
  },
}




/**
 *  REPORT_FINANCES_BY_SELL_TYPE
 */

export type ReportsPerformFinancesBySellTypeParams = {
  report_type: typeof REPORT_FINANCES_BY_SELL_TYPE,
  params: {
    from: string,
    to: string,
    details: boolean,
    limits: {
      school_ids?: Array<number>, // id школ
      sale_type?: Array<'personal' | 'complex'>,
    },
  },
  options?: {
    send_to_email?: string, // отправить на почту
  }
}

/**
 *  REPORT_FINANCES_BY_CUSTOMER
 */

export type ReportsPerformFinancesByCustomerParams = {
  report_type: typeof FINANCES_BY_CUSTOMER,
  report_format: $Values<typeof formats>,
  params: {
    account_id: number,
    from_date: string,
    to_date: string,
    school_id?: number,
    for_camp?: boolean,
  },
}

/**
 *  REPORT_FINANCES_CAFETERIA_CASH
 */

export type ReportsPerformFinancesCafeteriaCashParams = {
  report_type: typeof REPORT_FINANCES_CAFETERIA_CASH,
  params: {
    from: string,
    to: string,
    details: boolean,
    limits: {
      school_ids?: Array<number>, // id школ
      sources?: Array<PaymentSource>
    }
  },
  options?: {
    send_to_email?: string, // отправить на почту
  }
}


/**
 *  Result
 */

export type ReportsPerformResult = number

// Далее запросы, отличаются только типы данных
// VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

const request = [
  'reports/perform',
  'post',
  ['data', 'report_id'],
];


/**
 *  POST_REPORTS_PERFORM_BALANCES
 */

export const POST_REPORTS_PERFORM_BALANCES: Request<
  ReportsPerformBalancesParams,
  ReportsPerformResult,
  void
> = request;


/**
 *  POST_REPORTS_PERFORM_PURCHASES
 */

export const POST_REPORTS_PERFORM_PURCHASES: Request<
  ReportsPerformPurchasesParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  REPORT_PURCHASES_BY_GROUP_COMPLEXES_2
 */

export const POST_REPORTS_PERFORM_PURCHASESPURCHASES_BY_GROUP_COMPLEXES_2: Request<
  ReportsPerformPurchasesByGroupComplexes2Params,
  ReportsPerformResult,
  void
> = request;

/**
 *  REPORT_PURCHASES_BY_PAYMENT_MODE
 */

export const POST_REPORTS_PERFORM_PURCHASES_BY_PAYMENT_MODE: Request<
  ReportsPerformPurchasesByPaymentModeParams,
  ReportsPerformResult,
  void
> = request;


/**
 *  POST_REPORTS_PERFORM_ORDER_PURCHASES
 */

export const POST_REPORTS_PERFORM_ORDER_PURCHASES: Request<
  ReportsPerformOrdersPurchasesParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  POST_REPORTS_PERFORM_FINANCES_E_MONEY_LEGACY
 */

export const POST_REPORTS_PERFORM_FINANCES_E_MONEY_LEGACY: Request<
  ReportsPerformFinancesEMoneyLegacyParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  POST_REPORTS_PERFORM_FINANCES_E_MONEY
 */

export const POST_REPORTS_PERFORM_FINANCES_E_MONEY: Request<
  ReportsPerformFinancesEMoneyParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  POST_REPORTS_PERFORM_ACCOUNTING_CUSTOMERS
 */

export const POST_REPORTS_PERFORM_ACCOUNTING_CUSTOMERS: Request<
  ReportsPerformAccountingCustomersParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  POST_REPORTS_PERFORM_PURCHASES_BY_FUNDS
 */

export const POST_REPORTS_PERFORM_PURCHASES_BY_FUNDS: Request<
  ReportsPerformPurchasesByFundsParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  POST_REPORTS_PERFORM_FINANCES_BY_SELL_TYPE
 */

export const POST_REPORTS_PERFORM_FINANCES_BY_SELL_TYPE: Request<
  ReportsPerformFinancesBySellTypeParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  POST_REPORTS_CABINET_FINANCES_BY_STUDENT
 */

export const POST_REPORTS_PERFORM_FINANCES_BY_CUSTOMER: Request<
  ReportsPerformFinancesByCustomerParams,
  ReportsPerformResult,
  void
> = request;

/**
 *  POST_REPORTS_CABINET_FINANCES_BY_STUDENT
 */

export const POST_REPORTS_PERFORM_FINANCES_CAFETERIA_CASH: Request<
  ReportsPerformFinancesCafeteriaCashParams,
  ReportsPerformResult,
  void
> = request;
