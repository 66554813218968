// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const FIELDEDIT_REQUEST = 'orgs/kits/FIELDEDIT_REQUEST';
export const FIELDEDIT_SUCCESS = 'orgs/kits/FIELDEDIT_SUCCESS';
export const FIELDEDIT_FAILURE = 'orgs/kits/FIELDEDIT_FAILURE';

export const fieldEditRequest = (payload: Object) => ({
  type: FIELDEDIT_REQUEST,
  payload,
});

export const fieldEditSuccess = (payload: Object) => ({
  type: FIELDEDIT_SUCCESS,
  payload,
});

export const fieldEditFailure = (payload: Object) => ({
  type: FIELDEDIT_FAILURE,
  payload,
});

type Params = {
  date: string,
  property: string,
  value: string,
  extended: string,
};

export const fieldEdit = (
  orgId: string | number,
  kitId: string | number,
  id: string | number,
  {
    date,
    property,
    value,
    extended,
  }: Params,
) => async (dispatch: Function) => {
  const dataToSend = {
    school_id: orgId,
    complex_id: kitId,
    item_id: id,
    date,
    property,
    value,
    extended,
  };
  dispatch(fieldEditRequest(dataToSend));
  try {
    const res = await api('food/menu/update_complex_item', 'put', { data: dataToSend });
    dispatch(fieldEditSuccess({
      path: [kitId, 'items', id, property],
      value: get(res, ['data', 'fields', property], '???'),
    }));
    return true;
  } catch (error) {
    dispatch(fieldEditFailure({ err: 'Не удалось изменить блюдо', error }));
    return false;
  }
};
