import get from 'app/common/lib/get';
import { popup } from 'app/common/actions/popup';
import api from 'app/actions/api';
import i18n from 'app/i18n';

const WRONG_FORMAT = 400; // ошибка в параметрах
const WRONG_USER = 404; // не удалось определить пользователя
const ALREADY_USED = 409; // номер телефона или адрес уже используются
const ALREADY_SENT = 429; // код подтверждения был отправлен ранее

const INVALID_CODE = 403;

export const CODE_LOGIN_REQUEST = 'CODE_LOGIN_REQUEST';
export const CODE_LOGIN_SUCCESS = 'CODE_LOGIN_SUCCESS';
export const CODE_LOGIN_FAILURE = 'CODE_LOGIN_FAILURE';


const codeLoginRequest = () => ({
  type: CODE_LOGIN_REQUEST,
});

const codeLoginSuccess = payload => ({
  type: CODE_LOGIN_SUCCESS,
  payload,
});

const codeLoginFailure = payload => ({
  type: CODE_LOGIN_FAILURE,
  payload,
});

export const loginCode = login => (
  async (dispatch) => {
    dispatch(codeLoginRequest());
    try {
      const res = await api('auth/confirmation', 'post', {
        data: { login },
      });
      const lockedUntil = Date.now() + parseInt(get(res, 'data.time_left', ''), 10);
      localStorage.lockedUntil = lockedUntil;
      dispatch(codeLoginSuccess({
        resultTo: get(res, 'data.result_to', ''),
        lockedUntil,
        dateNow: Date.now(),
      }));
    } catch (error) {
      let requestCodeError = '';
      const errCode = get(error, 'response.status', '');

      if (errCode === WRONG_FORMAT) {
        requestCodeError = i18n.t('profile:error_login_invalid');
      }
      if (errCode === ALREADY_USED) {
        requestCodeError = i18n.t('profile:error_login_already_used');
      }
      if (errCode === ALREADY_SENT) {
        requestCodeError = i18n.t('profile:error_code_already_sent');
      }
      if (errCode === WRONG_USER) {
        requestCodeError = i18n.t('profile:error_user_invalid');
      }
      dispatch(codeLoginFailure({
        err: i18n.t('profile:error_login_code_receive_failed'),
        requestCodeError,
        error,
      }));
    }
  }
);


export const CHANGE_LOGIN_REQUEST = 'CHANGE_LOGIN_REQUEST';
export const CHANGE_LOGIN_SUCCESS = 'CHANGE_LOGIN_SUCCESS';
export const CHANGE_LOGIN_FAILURE = 'CHANGE_LOGIN_FAILURE';


const changeLoginRequest = () => ({
  type: CHANGE_LOGIN_REQUEST,
});

export const changeLoginSuccess = payload => ({
  type: CHANGE_LOGIN_SUCCESS,
  payload,
});

const changeLoginFailure = payload => ({
  type: CHANGE_LOGIN_FAILURE,
  payload,
});

export const changeLoginProcess = (login, code) => (
  async (dispatch) => {
    dispatch(changeLoginRequest());

    try {
      await api('auth/login', 'post', {
        data: { login, confirmation: code },
      });
      dispatch(changeLoginSuccess({
        login,
        isEmail: login.includes('@'),
      }));
      dispatch(popup(i18n.t('profile:msg_login_changed_success')));
    } catch (error) {
      let codeError = '';
      const errCode = get(error, 'response.status', '');

      if (errCode === WRONG_FORMAT) {
        codeError = i18n.t('profile:error_login_not_email_ore_phone');
      }
      if (errCode === INVALID_CODE) {
        codeError = i18n.t('profile:error_code_invalid');
      }
      dispatch(changeLoginFailure({
        err: i18n.t('profile:error_login_change_failed'),
        codeError,
        error,
      }));
    }
  }
);
