// @flow
import { useCallback, useEffect, useState } from 'react';
import { keys, assoc } from 'ramda';

import { api } from 'app/api';
import { GET_ORG_GENERAL_SETTINGS, POST_ORG_GENERAL_SETTINGS_MASS } from 'app/common/api/requests/food/schools/general_settings';
import type { GeneralSettings } from 'app/dataTypes/org/settings/GeneralSettings';
import { defaultGeneralSettings } from 'app/dataProviders/org/generalSettingsOrg';

const availableKeys = keys(defaultGeneralSettings);

export default function useGeneralSettings(orgId: string): [{
  data: GeneralSettings,
  loading: boolean,
  saving: boolean,
  modified: boolean,
}, {
  request: () => Promise<void>,
  submit: (overrideOrgIds?: Array<number>) => Promise<boolean> | boolean,
}, {
  checkbox: (e: SyntheticEvent<HTMLInputElement>) => void,
}] {
  const [data, setData] = useState(defaultGeneralSettings);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [modified, setModified] = useState(false);

  const handleClickCheckbox = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (availableKeys.includes(value)) {
      setData(assoc(value, checked));
      setModified(true);
    }
  }, []);

  const handleSubmit = useCallback(async (overrideOrgIds?: Array<number>) => {
    const schoolsIds = overrideOrgIds ? overrideOrgIds.map(String) : [orgId];

    setSaving(true);
    const res = await api.request(POST_ORG_GENERAL_SETTINGS_MASS, {
      error: 'Не удалось сохранить настройки',
      params: {
        schools_ids: schoolsIds,
        settings: data,
      },
    });
    setSaving(false);
    setModified(false);

    return Boolean(res);
  }, [data, orgId]);

  const handleRequest = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_ORG_GENERAL_SETTINGS, {
      error: 'Не удалось получить настройки',
      requestPathParams: {
        orgId,
      },
    });

    if (res) {
      setData(res);
    }
    setLoading(false);
    setModified(false);
  }, [orgId]);


  useEffect(() => {
    handleRequest();
  }, [handleRequest]);

  return [{
    data,
    loading,
    saving,
    modified,
  }, {
    request: handleRequest,
    submit: handleSubmit,
  }, {
    checkbox: handleClickCheckbox,
  }];
}
