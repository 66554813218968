// @flow
import React from 'react';

import { RowLayoutWithSticky, RowLayoutContent, Header, Header2 } from 'app/common/layouts/RowLayout';
import Loading from 'app/common/components/Loader';

import useLinkedEmployee from './useLinkedEmployee';
import GreenLinkRow from './GreenLinkRow';
import LinksTable from './LinksTable';
import styles from './LinkedUsers.scss';


type Props = {
  header: string,
}

const LinkedUsers = ({ header }: Props) => {
  const [{ fio }, loading, deleteLink] = useLinkedEmployee();

  const hasCurrentLinks = !!fio;
  // const hasAvailableLinks = // пока это не реализовано, а может и не будет
  const notAvailableAny = !fio;

  return (
    <RowLayoutWithSticky
      number={3}
      content={
        <RowLayoutContent borderBottom>
          <Header>
            {header}
          </Header>
        </RowLayoutContent>
      }
    >
      {loading
        && <RowLayoutContent>
          <Loading />
        </RowLayoutContent>}

      {!loading
        && hasCurrentLinks
        && <RowLayoutContent>
          <Header2>{'Текущие связи'}</Header2>
          <LinksTable className={styles.content}>
            <GreenLinkRow fio={fio} onDeleteLink={deleteLink} />
          </LinksTable>
        </RowLayoutContent>}

      {!loading
        && notAvailableAny
        && <RowLayoutContent>
          <div className={styles.nothing}>{'Связи отсутствуют'}</div>
        </RowLayoutContent>}
    </RowLayoutWithSticky>
  );
};

export default LinkedUsers;
