// @flow
import React, { useCallback } from 'react';
import petrovich from 'petrovich';

import CellContentPhoto from 'app/components/common/cellContents/CellContentPhoto';
// import { personUpdate } from 'app/actions/person/update';
// import { studentFetch } from 'app/actions/users/students';
// import { employeeSubmit } from 'app/actions/employees/employee/edit';
// import { oneEmployeeFetch } from 'app/actions/employees';
import { PUT_EMPLOYEE } from 'app/common/api/requests/food/employee';
import { api } from 'app/api';
import usePopup from 'app/common/hooks/usePopup';
import { getFIO, type Name } from 'app/common/lib/names';

import type { ImageParamsType } from 'app/common/components/ImagePrepare/ImagePrepare';


type Props = {
  photoUrl: string,
  className?: string,
  name: Name,
  employeeId: number,
  disabled?: boolean,
  onUpdateEmployees: (employeeId?: number) => Promise<void> | void,
}

const CellContentEmployeePhoto = ({
  photoUrl,
  className,
  name,
  employeeId,
  disabled,
  onUpdateEmployees,
}: Props) => {
  const popup = usePopup();
  const nameGenitive = getFIO(petrovich(name, 'genitive'));


  const handleSaveImage = useCallback(async (imageParams: ImageParamsType) => {
    const isPhoto = !!photoUrl;

    const res = await api.request(PUT_EMPLOYEE, {
      error: isPhoto ? 'Не удалось изменить фотографию' : 'Не удалось добавить фотографию',
      params: {
        id: employeeId,
        photo: imageParams,
      },
    });

    if (res) {
      onUpdateEmployees(employeeId);
      popup(`Фотография успешно ${isPhoto ? 'изменена' : 'добавлена'}`);
      return true;
    }
    return false;
  }, [employeeId, photoUrl, popup, onUpdateEmployees]);


  const handleDeleteImage = useCallback(async () => {
    const res = await api.request(PUT_EMPLOYEE, {
      error: 'Не удалось удалить фотографию',
      params: {
        id: employeeId,
        photo: null,
      },
    });

    if (res) {
      onUpdateEmployees();
      popup('Изображение успешно удалено');
      return true;
    }
    return false;
  }, [employeeId, popup, onUpdateEmployees]);


  return (
    <CellContentPhoto
      photoUrl={photoUrl}
      className={className}
      deleteConfirmText={`Вы уверены? Удалить фотографию ${nameGenitive}?`}
      onSaveImage={handleSaveImage}
      onDeleteImage={handleDeleteImage}
      disabled={disabled}
    />
  );
};

export default CellContentEmployeePhoto;
