import { AddAgreementDocumnetParams, AgreementDocumentType, AgreementDocumentKind } from 'app/dataTypes/agreement/documents'
import { FileType } from 'app/common/api/flowTypes'

import type { Request } from '../../../index'
import { convertKind } from './get'


const convertKindToAPI = (kind: AgreementDocumentKind): 2 | 4 => {
  if (kind === AgreementDocumentKind.PublicOffer) return 2
  if (kind === AgreementDocumentKind.PersonalDataAgreement) return 4
  return null as never
}

type ApiParams = {
  document: {
    id: number | null
    kind_id: 2 | 4
    pdf_file_id: number
  }
}

export const convertParams = (params: AddAgreementDocumnetParams): [ApiParams, null] => {
  const { ID, kind, attachmentFileID } = params
  return [{
    document: {
      id: ID ? parseInt(ID, 10) : null,
      kind_id: convertKindToAPI(kind),
      pdf_file_id: parseInt(attachmentFileID, 10),
    },
  }, null]
}

type APIResult = {
  id: number
  kind_id: 2 | 4
  pdf_file: FileType
}

export const convertResult = (data: APIResult): AgreementDocumentType => {
  const {
    id,
    kind_id: kindId,
    pdf_file: pdfFile,
  } = data

  return {
    ID: String(id),
    kind: convertKind(kindId),
    pdfFile,
  }
}

export const POST_AGREEMENT_DOCUMENTS: Request<void, AgreementDocumentType, void> = [
  'food/agreement/documents',
  'post',
  ['data', 'document'],
]
