// @flow
import React, { useState, useCallback, useMemo } from 'react';
import nest from 'recompose/nest';
import values from 'ramda/es/values';
import ascend from 'ramda/es/ascend';
import sort from 'ramda/es/sort';
import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';
import isEmpty from 'ramda/es/isEmpty';
import length from 'ramda/es/length';
import pick from 'ramda/es/pick';
import keys from 'ramda/es/keys';

import { isSingle, mapObjPropToArray } from 'app/common/lib/object';

import PlainFilter from './PlainFilter';
import FilterOptionsModal from './FilterOptionsModal';
import OpenModal from './OpenModal';
import PlainFilterStatic from './PlainFilterStatic';


const byTitle = ascend(prop('title'));
const defaultSortFunc = sort(byTitle);
const Modal = nest(OpenModal, FilterOptionsModal);
const defEmpty = {};
const defEmptyArr = [];


type Item<T> = {
  id: T,
  title: string,
  leftShiftLevel?: number, // смещение элемента слева, для обозначения иерархии
  ...
} | {
  id: T,
  title: string,
  ...
}

type Props<T> = {
  id: string, // filterId
  title?: string,
  modalTitle?: string,
  className?: string,
  titleClassName?: string,
  loading?: boolean,
  maxRows?: number,
  light?: boolean,
  disabled?: boolean,
  badgeProps?: Object,
  errorText?: string,
  enableSelectAll?: boolean,
  nothingIsAll?: boolean, // если true, пустое выделение означает 'все'
  singleSelectable?: boolean,
  sortFunc?: (data: Array<Item<T>>) => Array<Item<T>>,
  itemsToArrayFunc?: (data: { [key: string]: Item<T> }) => Array<Item<T>>,
  itemsObj?: { [key: string]: Item<T> },
  selectedArr?: Array<T> | [T] | [],
  onChange?: (o: { id: string, value: Array<T> }) => void,
  onReset?: (filterId: string) => void,
  onChangeRaw?: ({ [key: string]: Item<T> }, id: string) => void,
  linkAfterBadge?: boolean,
  noDataText?: string,
  selectedAllStateEnabled?: boolean,
  hideNoData?: boolean,
  toggleModalOnCrossClick?: boolean,
  ariaLabelledby?: string,
  emptyListMessage?: string,
}

function PlainFilterComplete<T>({
  id,
  title = '',
  modalTitle = '',
  className,
  titleClassName,
  loading,
  maxRows = 3,
  light = true,
  disabled,
  badgeProps,
  errorText,
  enableSelectAll,
  nothingIsAll,
  singleSelectable,
  sortFunc = defaultSortFunc,
  itemsToArrayFunc,
  itemsObj = defEmpty,
  selectedArr = defEmptyArr,
  onChange,
  onReset,
  onChangeRaw,
  linkAfterBadge,
  noDataText,
  selectedAllStateEnabled = true,
  hideNoData,
  toggleModalOnCrossClick,
  ariaLabelledby,
  emptyListMessage,
}: Props<T>) {
  // выбираем выделенные объекты из набора всех объектов
  const selectedItems = useMemo(
    () => (
      pick(selectedArr, itemsObj)
    ),
    [selectedArr, itemsObj],
  );

  // вычисляем "выделено ли все"
  const isSelectedAllState = useMemo(() => (nothingIsAll
    ? isEmpty(selectedArr)
    : selectedAllStateEnabled && length(selectedArr) === length(values(itemsObj))
  ), [selectedArr, nothingIsAll, itemsObj, selectedAllStateEnabled]);

  // показ / скрытие модального окна
  const [modal, setModal] = useState(false);
  const toggleModal = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    setModal(a => !a);
  }, []);

  // обертка на функцию onChange родителя
  const handleChange = useCallback((items) => {
    // для совместимости
    if (onChangeRaw) {
      onChangeRaw(items, id);
    }
    // собираем простой массив из id и отправляем родителю
    if (onChange) {
      onChange({
        id,
        value: mapObjPropToArray('id')(items),
      });
    }
  }, [onChange, id, onChangeRaw]);

  // сброс выделенного при нажатии на "Сбросить выбор"
  const handleReset = useCallback((e) => {
    e.preventDefault();
    if (typeof onReset === 'function') {
      onReset(id);
    }
  }, [onReset, id]); // eslint-disable-line react-hooks/exhaustive-deps

  // сброс выделенного из радительского элемента
  // useEffect(() => {
  //   if (reset) {
  //     onReset();
  //   }
  // }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  // удаление элемента (нажатие на крестик у badge)
  const onItemRemove = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const selectedOne = isSingle(selectedItems);
    const { name } = e.currentTarget;
    // если удаляется элемент "все", показываем диалог с выбором
    if (name === 'all' || (toggleModalOnCrossClick && selectedOne)) {
      toggleModal();
      return;
    }
    // если в фильтре был выбран один элемент, то восстанавливаем значение по умолчанию
    if (selectedOne && typeof onReset === 'function') {
      onReset(id);
      return;
    }
    // в остальных случаях просто удаляем элемент и вызываем onChange родителя
    handleChange(omit([name], selectedItems));
  }, [handleChange, selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  // Modal submit, меняем выделенное
  const onSubmitModal = useCallback((submitedItems, isAll) => {
    handleChange(nothingIsAll && isAll ? {} : submitedItems);
  }, [nothingIsAll, handleChange]);

  if (!loading && length(keys(itemsObj)) === 1 && nothingIsAll) {
    return (
      <PlainFilterStatic
        className={className}
        item={values(itemsObj)[0]}
        title={title}
        light={light}
      />
    );
  }

  return (
    <div className={className}>
      <PlainFilter
        title={title}
        titleClassName={titleClassName}
        loading={loading}
        maxRows={maxRows}
        light={light}
        disabled={disabled}
        badgeProps={badgeProps}
        errorText={errorText}
        onItemRemove={onItemRemove}
        selectedItems={selectedItems}
        isSelectedAllState={isSelectedAllState}
        onReset={onReset ? handleReset : undefined}
        noData={!nothingIsAll && isEmpty(selectedItems)}
        hideNoData={hideNoData}
        noDataText={noDataText}
        linkAfterBadge={linkAfterBadge}
        ariaLabelledby={ariaLabelledby}
      >
        <Modal
          isOpen={modal}
          toggleModal={toggleModal}
          items={itemsObj}
          selectedItems={selectedItems}
          header={modalTitle}
          sortFunc={sortFunc}
          itemsToArrayFunc={itemsToArrayFunc}
          enableSelectAll={enableSelectAll}
          nothingIsAll={nothingIsAll}
          singleSelectable={singleSelectable}
          onSubmit={onSubmitModal}
          loading={loading}
          emptyListMessage={emptyListMessage}
        />
      </PlainFilter>
    </div>
  );
}

export default PlainFilterComplete;
