import { isEmpty } from 'ramda'

import { useCurrencySign, options as co } from 'app/common/components-next/currency'
import * as orgsTypes from 'app/common/constants/schools/types'
import * as rootRights from 'app/common/constants/rights/rootRights'
import * as specificRights from 'app/common/constants/rights/specificRights'
import { WithRightsForOrg } from 'app/rights/RightsForOrg'

import HeaderContent from './HeaderContent'
import LeftBarContent from './LeftBarContent'
import { MainKitContent } from './MainKitContent'
import RightBarContent from './RightBarContent'
import FooterContent from './FooterContent'
import useDeleteHideRestoreActions from './useDeleteHideRestoreActions'
import EmptyStub from './EmptyStub'
import { HeaderGroceryPrice } from './HeaderGroceryPrice/HeaderGroceryPrice'
import { ExtKitLayout } from './ExtKitLayout'
import { Complex } from '../kits2ExtendedItemsList'
import { ValidityByDate } from '../../../commonComponents/ValidityByDate'

import styles from './KitDishesExtended.scss'


const complexesCompositionRigths = [rootRights.COMPLEXES, specificRights.COMPLEXES_COMPOSITION] as const

type Props = {
  orgID: string
  orgType: string
  date: string
  kitId?: string
  useGrocerySetPrice: boolean
  action?: string
  loading: boolean
  basePath: string
} & Complex

export const KitDishesExtended = ({
  id,
  params,
  orgID,
  orgType,
  date,
  kitId = '',
  useGrocerySetPrice,
  action,
  subsidyOrders,
  deletable,
  loading,
  basePath,
}: Props) => {
  const {
    title,
    cost,
    grocerySetPrice,
    dishes,
    fixedPriceEnabled,
    buffetZone,
    hotMealsZone,
    denyParentMoneySales,
    denyMoreThanOneSales,
    useAsAdditional,
  } = params

  const currency = useCurrencySign(co.SPACE_BEFORE)
  const noDishes = isEmpty(dishes)
  const orgIdNum = parseInt(orgID, 10)
  const activeAction = parseInt(kitId, 10) === id && action ? action : null

  const [{ deleteKit, hideKit }, processing] = useDeleteHideRestoreActions(orgIdNum, id)
  const hideSomeOptions = orgType === orgsTypes.ENTERPRISE
  const content = {
    headerContent: (
      <HeaderContent
        id={id}
        orgID={orgID}
        title={title}
        cost={cost}
        currency={currency}
      />
    ),
    headerGroceryPrice: useGrocerySetPrice && (
      <HeaderGroceryPrice
        id={id}
        orgID={orgID}
        date={date}
        groceryPrice={grocerySetPrice}
        currency={currency}
      />
    ),
    leftBarContent: (
      <LeftBarContent
        id={id}
        orgID={orgID}
        title={title}
        date={date}
        subsidyOrders={subsidyOrders}
        updatePhotoKey={params}
      />
    ),
    mainContent: (
      noDishes
        ? <EmptyStub />
        : <div className={styles.mainContent}>
          <WithRightsForOrg pathTo={complexesCompositionRigths}>
            <ValidityByDate affect="hide">
              <MainKitContent
                className={styles.mainTable}
                dishes={dishes}
                currency={currency}
                orgID={orgID}
                id={id}
                date={date}
                title={title}
                // disable ==> from WithRightsForOrg
              />
            </ValidityByDate>
          </WithRightsForOrg>
        </div>
    ),
    rightBarContent: (
      <RightBarContent
        id={id}
        title={title}
        orgID={orgID}
        date={date}
        noDishes={noDishes}
        subsidyOrders={subsidyOrders}
        deletable={deletable}
        fixedPriceEnabled={fixedPriceEnabled}
        activeAction={activeAction}
        deleteKit={deleteKit}
        hideKit={hideKit}
        hideDishes2Menu={hideSomeOptions}
        hideKit2Menu={hideSomeOptions}
        hideSettings={hideSomeOptions}
        buffetZone={buffetZone}
        hotMealsZone={hotMealsZone}
        denyParentMoneySales={denyParentMoneySales}
        denyMoreThanOneSales={denyMoreThanOneSales}
        useAsAdditional={useAsAdditional}
      />
    ),
    footerContent: (
      <FooterContent
        id={id}
        title={title}
        orgId={parseInt(orgID, 10)}
        date={date}
        loading={loading || processing}
        basePath={basePath}
        activeAction={activeAction}
      />
    ),
  }

  return (
    <ExtKitLayout
      loading={loading || processing}
      {...content}
    />
  )
}
