// @flow
import React, { Fragment, useState, useCallback } from 'react';

import Loader from 'app/common/components/Loader';
import { useCurrencySign } from 'app/common/components-next/currency';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';
import EmptyReportCSS from 'app/components/common/reports/EmptyReportCSS';

import HeaderBar from './HeaderBar';
import Summary from './Summary';
import Schools from './Schools';
import KlassesEmployees from './KlassesEmployees';
import useReportData from './useReportData';
import useDisplayParams from './useDisplayParams';

import styles from './Report.scss';


const empty = {};

type Props = {
  reportId: number | null,
}

const Report = ({ reportId }: Props) => {
  const currency = useCurrencySign();

  const [fs, changeFullScreen] = useState(false);
  const toggleFS = useCallback((e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    changeFullScreen(fullscreen => !fullscreen);
  }, []);

  const [data, loading] = useReportData(reportId);
  const { details, groupped, unspent, singleOrg } = useDisplayParams(!loading);
  const {
    report_total: summary,
    guest,
    totals,
    schools,
    klasses,
    employees,
  } = data || empty;

  const nothing = !(summary || schools || klasses || employees || guest);

  if (!reportId) {
    return (
      <EmptyReportCSS
        stickyNumber={2}
      />
    );
  }

  return (
    <ReportSectionCSS
      stickyNumber={2}
      header="Отчет «Учет выдачи»"
      headerBar={
        <HeaderBar
          onFullscreen={toggleFS}
          reportId={reportId}
        />
      }
      fullscreen={fs}
      wideContent
    >
      {loading
        ? <Loader />

        : <Fragment>
          {/* Общая иформация */}
          {summary
            && <Summary
              loading={loading}
              currencySign={currency}
              summary={summary}
              unspent={unspent}
            />}

          {/* Далее таблицы с детализациями */}
          {details
            && <DetailsHeader reportTitle="Учет выдачи" />}

          {details
            && !singleOrg
            && schools
            && <Schools
              data={schools}
              totals={totals}
              summary={summary}
              hideUnspent={!unspent}
              sellTypeGroupped={groupped}
            />}

          {details
            && singleOrg
            && (klasses || employees || guest)
            && <KlassesEmployees
              klasses={klasses}
              employees={employees}
              guest={guest}
              totals={totals}
              summary={summary}
              hideUnspent={!unspent}
              sellTypeGroupped={groupped}
            />}

          {/* Нет обозначенных данных */}
          {nothing
            && <RowLayoutContent borderTop paddingfree>
              <EmptyWarn className={styles.empty}>
                {'Данные отсутствуют'}
              </EmptyWarn>
            </RowLayoutContent>}
        </Fragment>}
    </ReportSectionCSS>
  );
};

export default Report;
