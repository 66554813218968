import config from 'app/config.js';
import api, { apiCancelable } from 'app/actions/api';


/**
* Запрос блюд
*/

export const DISHES_REQUEST = 'DISHES_REQUEST';
export const DISHES_SUCCESS = 'DISHES_SUCCESS';
export const DISHES_FAILURE = 'DISHES_FAILURE';

export const dishesRequest = () => ({
  type: DISHES_REQUEST,
});

const dishesSuccess = payload => ({
  type: DISHES_SUCCESS,
  payload,
});

const dishesFailure = payload => ({
  type: DISHES_FAILURE,
  payload,
});

export const dishesFetch = someParams => async (dispatch) => {
  dispatch(dishesRequest());
  try {
    const params = someParams || { limit: config.perPageItems };
    const res = await api('food/items', 'get', { params });
    dispatch(dishesSuccess(res.data));
  } catch (error) {
    dispatch(dishesFailure({ err: 'Запрос блюд не удался', error }));
  }
};

export const DISHES_CANCEL = 'DISHES_CANCEL';

const dishesCancel = () => ({
  type: DISHES_CANCEL,
});

export const dishesFetchSearch = someParams => async (dispatch) => {
  dispatch(dishesRequest());
  try {
    const params = someParams || { limit: config.perPageItems };
    const res = await apiCancelable.request('food/items', 'get', { params });
    dispatch(dishesSuccess(res.data));
  } catch (error) {
    dispatch(dishesCancel());
  }
};
