import { ReactNode } from 'react'
import cn from 'classnames/bind'

import styles from './CellConstraints.scss'


const cx = cn.bind(styles)

type Props = {
  children: ReactNode
  extended: boolean
}

const CellConstraints = ({ children, extended }: Props) => {
  const textConstrants = typeof children === 'string'

  return (
    <div className={cx(styles.root, { extended, textConstrants })}>
      {children}
    </div>
  )
}

export default CellConstraints
