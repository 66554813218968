import { map } from 'ramda'
import { v4 as uuid } from 'uuid'

import {
  CompensationTransactionsParams,
  CompensationTransactions,
  OperationType,
  isOperationType,
} from 'app/dataTypes/compensation/transations'

import toFixedValue from 'app/common/lib/toFixedValue'
import SimpleDate from 'app/common/classes/simple-date'

import type { Request } from '../../../index'


const apiDateFormat = 'YYYY-MM-DD' as const

type ApiParams = {
  date_from: string
  date_to: string
  student_id: number
}

export const convertParams = (params: CompensationTransactionsParams): [ApiParams, null] => {
  const {
    dateFrom,
    dateTo,
    studentID,
  } = params

  return [{
    date_from: dateFrom.getString(apiDateFormat),
    date_to: dateTo.getString(apiDateFormat),
    student_id: parseInt(studentID, 10),
  }, null]
}

type ApiResult = {
  result: 'success'
  balance: number
  transactions: Array<{
    datetime: string
    value: number
    operation_type: number
    comment: string
  }>
}

export const convertResult = ({ balance, transactions }: ApiResult): CompensationTransactions => {
  return {
    totalBalance: toFixedValue(balance),
    transactions: map(({
      datetime,
      value,
      operation_type: operationType,
      comment,
    }) => ({
      ID: uuid(),
      date: new SimpleDate(datetime),
      value: `${value < 0 ? '\u2212' : '+'}${toFixedValue(Math.abs(value))}`,
      valueStatus: value < 0 ? 'negative' : 'positive',
      operationType: isOperationType(operationType) ? operationType : OperationType.Unknown,
      comment,
    }), transactions),
  }
}

export const GET_COMPENSATION_TRANSACTIONS: Request<
  CompensationTransactionsParams,
  CompensationTransactions,
  void
> = ([
  'food/compensation/transactions',
  'get',
  ['data'],
])
