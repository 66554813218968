import api from 'app/actions/api';

export const ABOUT_REQUEST = 'ABOUT_REQUEST';
export const ABOUT_SUCCESS = 'ABOUT_SUCCESS';
export const ABOUT_FAILURE = 'ABOUT_FAILURE';
export const ABOUT_STATE_CLEAR = 'ABOUT_STATE_CLEAR';

const aboutRequest = () => ({
  type: ABOUT_REQUEST,
});

const aboutSuccess = payload => ({
  type: ABOUT_SUCCESS,
  payload,
});

const aboutFailure = payload => ({
  type: ABOUT_FAILURE,
  payload,
});

export const aboutStateClear = () => ({
  type: ABOUT_STATE_CLEAR,
});

/**
* Запрос данных для раздела "о приложении"
*/
export const fetchAbout = () => async (dispatch) => {
  dispatch(aboutRequest());
  try {
    const { data } = await api(`${(process.env.APP_NAME || process.env.REACT_APP_NAME)}/about`, 'get');
    dispatch(aboutSuccess(data));
  } catch (error) {
    dispatch(aboutFailure({ err: 'Не удалось получить данные для раздела «О приложении»', error }));
  }
};
