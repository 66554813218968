// @flow
import React, { useEffect, type Ref } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { PlainGroup, PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainInput, { PlainInputDouble, PlainInputPhoneCC, PlainInputSNILS } from 'app/common/ui/PlainInput';
import PlainSelect from 'app/common/ui/PlainSelect';
import PlainGenderSelect from 'app/common/ui/PlainGenderSelect';
import { dateFormat } from 'app/actions/employees/employee/employeeform';
import subjectsOfRussia from 'app/common/constants/subjectsOfRussia';
import Prompt from 'app/components/common/Prompt';
import PlainInputDate from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import useFieldsChange from './useFieldsChange';
import useErrors from './useErrors';
import useValidate from './useValidate';
import useSubmit from './useSubmit';
import selectIsFormNotModified from '../selectIsFormNotModified';


type Props = {
  reference: Ref<'form'>,
  locked: boolean,
  submitting: boolean,
  onChangeSubmittingStatus: (boolean) => void,
}

const EmployeeForm = ({
  reference,
  locked,
  submitting: saving,
  onChangeSubmittingStatus,
}: Props) => {
  const { loading } = useSelector(state => state.employees.employee.options, shallowEqual);
  const formModified = !useSelector(selectIsFormNotModified);
  const form = useSelector(state => state.employees.employee.employeeform, shallowEqual);
  const [errors, { resetErrors, setErrors }] = useErrors();
  const [handleChangeField, handleChangeDateField] = useFieldsChange({ beforeChange: resetErrors });

  const validate = useValidate({ setErrors });
  const submit = useSubmit({ validate, onChangeSubmittingStatus, setErrors });

  useEffect(() => {
    resetErrors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <form onSubmit={submit} ref={reference}>
      <PlainGroup
        header="Основная информация"
        error={errors.general}
        isError={!!errors.general}
      >
        <PlainColumns>

          <PlainColumn div={3}>
            <PlainInput
              label="Фамилия"
              name="name.last"
              value={form.name.last}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoComplete="family-name"
              isError={errors['name.last']}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Имя"
              name="name.first"
              value={form.name.first}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoComplete="given-name"
              isError={errors['name.first']}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Отчество"
              name="name.middle"
              value={form.name.middle}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoComplete="additional-name"
              isError={errors['name.middle']}
            />
          </PlainColumn>

          <PlainColumn div={4}>
            <PlainGenderSelect
              label="Пол"
              name="gender"
              value={form.gender}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              isError={errors.gender}
            />
          </PlainColumn>

          <PlainColumn div={4}>
            <PlainLabel htmlFor="birthdate">
              {'Дата рождения'}
            </PlainLabel>

            <PlainInputDate
              id="birthdate"
              value={form.birthdate}
              onDateChange={handleChangeDateField}
              dateFormat={dateFormat}
              disabled={saving || locked}
              loading={loading}
              autoComplete="bday"
              error={errors.birthdate}
              calendarCloseOnClick
            />

            <PlainHelperText oldUpperSpace>
              &nbsp;
            </PlainHelperText>
          </PlainColumn>


        </PlainColumns>
      </PlainGroup>

      <PlainGroup
        header="Документы"
        error={errors.documents}
        isError={!!errors.documents}
      >
        <PlainColumns>

          <PlainColumn div={3}>
            <PlainInputDouble
              label="Серия и номер паспорта"
              name1="passport.series"
              value1={form.passport.series}
              name2="passport.num"
              value2={form.passport.num}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Место рождения"
              name="passport.birthplace"
              value={form.passport.birthplace}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainLabel htmlFor="passport.date">
              {'Дата выдачи'}
            </PlainLabel>

            <PlainInputDate
              id="passport.date"
              value={form.passport.date}
              onDateChange={handleChangeDateField}
              dateFormat={dateFormat}
              disabled={saving || locked}
              loading={loading}
              calendarCloseOnClick
            />

            <PlainHelperText oldUpperSpace>
              &nbsp;
            </PlainHelperText>

            {/* <PlainDateInput
              name="passport.date"
              label="Дата выдачи"
              value={form.passport.date}
              onChangeDate={handleChangeDateField}
              onBlur={handleChangeDateField}
              format={dateFormat}
              disabled={saving || locked}
              loading={loading}
            /> */}
          </PlainColumn>

          <PlainColumn div={1}>
            <PlainInput
              label="Кем выдан"
              name="passport.place"
              value={form.passport.place}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={2}>
            <PlainInput
              label="ИНН"
              name="inn"
              value={form.inn}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={2}>
            <PlainInput
              label="Медицинское страховое свидетельство"
              name="medical"
              value={form.medical}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={2}>
            <PlainInputSNILS
              label="Номер СНИЛС"
              value={form.snils || ''}
              name="snils"
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              isError={errors.snils}
            />
          </PlainColumn>

        </PlainColumns>
      </PlainGroup>

      <PlainGroup
        header="Контактные данные"
        error={errors.contacts}
        isError={!!errors.contacts}
      >
        <PlainColumns>

          <PlainColumn div={3}>
            <PlainInputPhoneCC
              label="Мобильный телефон"
              value={form.phone}
              name="phone"
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              isError={errors.phone}
              autoComplete="tel"
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Домашний телефон"
              name="homephone"
              value={form.homephone}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Рабочий телефон"
              name="workphone"
              value={form.workphone}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoComplete="tel"
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="E-mail"
              name="email"
              value={form.email}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoComplete="email"
              isError={errors.email}
            />
          </PlainColumn>

        </PlainColumns>

      </PlainGroup>

      <PlainGroup
        header="Адрес"
        error={errors.address}
        isError={!!errors.address}
      >

        <PlainColumns>

          <PlainColumn div={3}>
            <PlainSelect
              label="Страна"
              data={[
                { id: 'Беларусь', title: 'Беларусь' },
                { id: 'Казахстан', title: 'Казахстан' },
                { id: 'Кыргызстан', title: 'Кыргызстан' },
                { id: 'Россия', title: 'Россия' },
                { id: 'Украина', title: 'Украина' },
              ]}
              name="address.country"
              current={form.address.country}
              placeholder="Не указано"
              // $FlowFixMe
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoСomplete="country-name"
              uni
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainSelect
              label="Субъект федерации"
              data={subjectsOfRussia}
              name="address.subject"
              current={form.address.subject}
              placeholder="Не указано"
              // $FlowFixMe
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoСomplete="address-level1"
              uni
            />
          </PlainColumn>

        </PlainColumns>
        <PlainColumns>

          <PlainColumn div={3}>
            <PlainInput
              label="Почтовый индекс"
              name="address.index"
              value={form.address.index}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoСomplete="postal-code"
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Населенный пункт"
              name="address.town"
              value={form.address.town}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoСomplete="address-level2"
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Улица"
              name="address.street"
              value={form.address.street}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
              autoСomplete="street-address"
            />
          </PlainColumn>

          <PlainColumn div={5}>
            <PlainInput
              label="Дом"
              name="address.house"
              value={form.address.house}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={5}>
            <PlainInput
              label="Корпус"
              name="address.corp"
              value={form.address.corp}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

          <PlainColumn div={5}>
            <PlainInput
              label="Квартира"
              name="address.apart"
              value={form.address.apart}
              onChange={handleChangeField}
              disabled={saving || locked}
              loading={loading}
            />
          </PlainColumn>

        </PlainColumns>
      </PlainGroup>

      {!locked
        && <button type="submit" name="submit" style={{ display: 'none' }}>{'Отправить'}</button>}

      <Prompt
        when={formModified}
        message="Вы не сохранили изменения, перейти в любом случае?"
      />

    </form>
  );
};

export default EmployeeForm;
