// @flow
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { GET_LAST_CACHE_UPDATE } from 'app/common/api/requests/reports/last_cache_update';
import { api } from 'app/api';


export default function useExtraText(needText: boolean) {
  const [state, setState] = useState('');

  useEffect(() => {
    if (needText) {
      const request = async () => {
        const res = await api.request(GET_LAST_CACHE_UPDATE, {
          params: {
            report_type: 'purchases_by_group_complexes_aggregated',
          },
        });
        if (res) {
          const mDate = moment(res);
          if (mDate.isValid()) {
            setState(`Данные будут взяты из кэша. Дата и время последнего обновления — ${mDate.format('HH:mm DD.MM.YYYY')} →`);
          }
        }
      };
      request();
      return;
    }
    setState('');
  }, [needText]);

  return state;
}
