// @flow
import type { Request } from '../index';


export type City = {
  id: number,
  title: string,
}

export type CitiesResult = {
  [id: string]: City
}

export const GET_CITIES: Request<
  void,
  CitiesResult,
  void
> = [
  'food/cities',
  'get',
  ['data', 'cities'],
];
