// @flow

export type StateByFilterId = {
  [filterId: string]: Array<string>
}

export type ActiveByFilterId = {
  [filterId: string]: boolean
}

export type AppliedState = {
  applied: StateByFilterId | null,
  active: ActiveByFilterId,
}

export const defaultAppliedState: AppliedState = {
  applied: null, // null - фильтр не применен
  active: {},
};
