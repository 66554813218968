// @flow
import Modal from './Modal';
import { ModalHeader, ModalHeader2 } from './ModalHeaders';
import ModalFooter from './ModalFooter';
import ModalContent from './ModalContent';
import ModalContentSplit from './ModalContentSplit';
import ModalSplittedContent from './ModalSplittedContent';
import ModalOnClearBack from './ModalOnClearBack';

export default Modal;
export {
  ModalHeader,
  ModalHeader2,
  ModalFooter,
  ModalContent,
  ModalContentSplit,
  ModalSplittedContent,
  ModalOnClearBack,
};
