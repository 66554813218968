import { FunctionComponentElement, ReactNode, useContext } from 'react'

import childrenWithDisabledProp from 'app/rights/utils/childrenWithDisabledProp'
import { ValidityByDateContext } from './ValidityByDateProvider'


type Props = {
  disabled?: boolean
  affect: 'hide' | 'disable'
  viewOnHide?: ReactNode
  children: FunctionComponentElement<{ disabled?: boolean }>
}

export const ValidityByDate = ({
  disabled,
  affect,
  viewOnHide = null,
  children,
}: Props) => {
  const { loading, validity, contextAvailable } = useContext(ValidityByDateContext)

  if (!contextAvailable) {
    console.error('Отсутствует ValidityByDateProvider для ValidityByDate')
  }

  if (loading) {
    if (affect === 'hide') return null
    return childrenWithDisabledProp(children, true)
  }

  if (affect === 'hide' && !validity) return viewOnHide

  if (affect === 'disable') {
    return (
      childrenWithDisabledProp(children, disabled || !validity)
    )
  }

  return children
}
