// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import styles from './Header.scss';


const cx = classNames.bind(styles);

type Props = {
  children: Node,
  onTop?: boolean,
}

const Header = ({ children, onTop = false }: Props) => {
  return (
    <h2 className={cx(styles.root, { onTop })}>
      {children}
    </h2>
  );
};

export default Header;
