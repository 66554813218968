import get from 'app/common/lib/get';
import { popup } from 'app/common/actions/popup';
import api from 'app/actions/api';
import i18n from 'app/i18n';

const WRONG_FORMAT = 400;
const ALREADY_USED = 409;
const ALREADY_SENT = 429;
const INVALID_CODE = 403;

export const CODE_PHONE_REQUEST = 'CODE_PHONE_REQUEST';
export const CODE_PHONE_SUCCESS = 'CODE_PHONE_SUCCESS';
export const CODE_PHONE_FAILURE = 'CODE_PHONE_FAILURE';


const codePhoneRequest = () => ({
  type: CODE_PHONE_REQUEST,
});

const codePhoneSuccess = payload => ({
  type: CODE_PHONE_SUCCESS,
  payload,
});

const codePhoneFailure = payload => ({
  type: CODE_PHONE_FAILURE,
  payload,
});


export const phoneCode = phone => (
  async (dispatch) => {
    dispatch(codePhoneRequest());
    try {
      const res = await api('account/confirmation/phone', 'post', {
        data: { phone },
      });
      const lockedUntil = Date.now() + parseInt(get(res, 'data.time_left', ''), 10);
      localStorage.lockedUntil = lockedUntil;
      dispatch(codePhoneSuccess({
        resultTo: get(res, 'data.result_to', ''),
        lockedUntil,
      }));
    } catch (error) {
      let requestCodeError = '';
      const errCode = get(error, 'response.status', '');

      if (errCode === WRONG_FORMAT) {
        requestCodeError = i18n.t('profile:error_phone_format_invalid');
      }
      if (errCode === ALREADY_USED) {
        requestCodeError = i18n.t('profile:error_phone_already_used');
      }
      if (errCode === ALREADY_SENT) {
        requestCodeError = i18n.t('profile:error_code_already_sent');
      }
      dispatch(codePhoneFailure({
        err: i18n.t('profile:error_phone_code_receive_failed'),
        requestCodeError,
        error,
      }));
    }
  }
);


export const CHANGE_PHONE_REQUEST = 'CHANGE_PHONE_REQUEST';
export const CHANGE_PHONE_SUCCESS = 'CHANGE_PHONE_SUCCESS';
export const CHANGE_PHONE_FAILURE = 'CHANGE_PHONE_FAILURE';


const changePhoneRequest = () => ({
  type: CHANGE_PHONE_REQUEST,
});

const changePhoneSuccess = payload => ({
  type: CHANGE_PHONE_SUCCESS,
  payload,
});

const changePhoneFailure = payload => ({
  type: CHANGE_PHONE_FAILURE,
  payload,
});

export const changePhoneProcess = (phone, code, cb) => (
  async (dispatch) => {
    dispatch(changePhoneRequest());

    try {
      await api('account/phone', 'post', {
        data: { phone, confirmation: code },
      });
      dispatch(changePhoneSuccess({ phone }));
      dispatch(popup(i18n.t('profile:msg_phone_number_change_success')));
      if (typeof cb === 'function') {
        cb();
      }
    } catch (error) {
      let codeError = '';
      const errCode = get(error, 'response.status', '');

      if (errCode === WRONG_FORMAT) {
        codeError = i18n.t('profile:error_phone_format_invalid');
      }
      if (errCode === ALREADY_USED) {
        codeError = i18n.t('profile:error_phone_already_used');
      }
      if (errCode === INVALID_CODE) {
        codeError = i18n.t('profile:error_code_invalid');
      }
      dispatch(changePhoneFailure({
        err: i18n.t('profile:error_phone_change_failed'),
        codeError,
        error,
      }));
    }
  }
);
