// @flow
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';
import { arrangeByArray, sortClasses } from 'app/common/lib/sort';

import orgsOrUserFilter, { orgsOrUserItems } from 'app/components/common/reports/filters/orgsOrUser';
import customerSearchFilter, { customerSearchItems, customerSearchActivity } from 'app/components/common/reports/filters/customerSearch';
import orgTypesFilter, { arrange, orgTypeItems, orgTypeActivity } from 'app/components/common/reports/filters/orgType';
import orgsFilter, { orgItems, orgActivity } from 'app/components/common/reports/filters/org';
import yearFilter, { yearItems } from 'app/components/common/reports/filters/year';
import customerTypeFilter, { exceptGuests, customerTypeActivity } from 'app/components/common/reports/filters/customerType';
import classFilter, { classItems } from 'app/components/common/reports/filters/class';
import detailsFilter, { detailsItems, detailsActivity } from 'app/components/common/reports/filters/details';
import mailFilter, { mailItems, mailProps, mailActivity } from 'app/components/common/reports/filters/mail';
import studentStatusFilter, { exceptPending, studentStatusActivity } from 'app/components/common/reports/filters/studentStatus';
import studentFilter, { studentItems } from 'app/components/common/reports/filters/student';
import employeeFilter, { employeeItems } from 'app/components/common/reports/filters/employee';
import currentStateDateFilter, { currentStateDateItems, currentStateDateProps } from 'app/components/common/reports/filters/currentStateDate';
import balanceTypeFilter, { balanceTypeActivity } from 'app/components/common/reports/filters/balanceType';

import groupFilter, { groupItems, groupActivity } from './group';
import sortFilter, { sortItems, sortActivity } from './sort';
import commonBalanceFilter, { commonBalanceItems, commonBalanceProps, commonBalanceActivity } from './commonBalance';
import classActivity from './classActivity';
import employeeActivity from './employeeActivity';
import yearActivity from './yearActivity';
import studentActivity from './studentActivity';
import balanceTypeItems from './balanceTypeItems';


type Params = {
  email: string | null
}

const filterItems: Descriptions<Params> = [{
  filterId: orgsOrUserFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  props: {
    label: 'Выборка покупателя',
    inlineItems: true,
  },
  getItemsAndCurrent: orgsOrUserItems,
}, {

  /* фильтр по покупателю через его поиск */
  filterId: customerSearchFilter,
  filterType: filterTypes.CUSTOMER_SEARCH,
  props: {
    label: 'Укажите л/с или телефон',
  },
  getItemsAndCurrent: customerSearchItems,
  activity: customerSearchActivity,
}, {

  /* фильтр по типу заведения */
  filterId: orgTypesFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Тип заведения',
    header: 'Выбор типа заведения',
    sortFunc: arrangeByArray(arrange, prop('id')),
  },
  getItemsAndCurrent: orgTypeItems,
  activity: orgTypeActivity,
  relations: [orgsFilter],
}, {

  /* фильтр "Текущее сотояние или дата" */
  filterId: currentStateDateFilter,
  filterType: filterTypes.RADIO_DATE,
  getProps: currentStateDateProps,
  getItemsAndCurrent: currentStateDateItems,
  relations: [yearFilter, studentStatusFilter, studentFilter, commonBalanceFilter],
}, {

  /* фильтр по заведению */
  filterId: orgsFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Заведение',
    header: 'Выбор заведения',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: orgItems,
  activity: orgActivity,
  relations: [yearFilter, customerTypeFilter],
}, {

  /* фильтр по типу покупателей */
  filterId: customerTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Тип покупателя',
    header: 'Выбор типа покупателя',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: exceptGuests,
  activity: customerTypeActivity,
}, {

  /* фильтр по сотруднику */
  filterId: employeeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Сотрудники',
    header: 'Выбор сотрудников',
    sortFunc: sort(descend(prop('title'))),
  },
  getItemsAndCurrent: employeeItems,
  activity: employeeActivity,
}, {

  /* фильтр по статусу ученика */
  filterId: studentStatusFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Статус учащегося',
    header: 'Выбор статуса учащегося',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: exceptPending,
  activity: studentStatusActivity,
  relations: [studentFilter, classFilter],
}, {

  /* фильтр по учебному году */
  filterId: yearFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Учебный год',
    header: 'Выбор учебного года',
    sortFunc: sort(descend(prop('title'))),
  },
  getItemsAndCurrent: yearItems,
  relations: [classFilter],
  activity: yearActivity,
}, {

  /* фильтр по классу */
  filterId: classFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Класс',
    header: 'Выбор класса',
    sortFunc: sortClasses('down', prop('title')),
  },
  getItemsAndCurrent: classItems,
  relations: [studentFilter],
  activity: classActivity,
}, {

  /* фильтр по ученику */
  filterId: studentFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Учащиеся',
    header: 'Выбор учащихся',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: studentItems,
  activity: studentActivity,
}, {

  /* фильтр детализация */
  filterId: detailsFilter,
  filterType: filterTypes.PLAIN_CHECKBOX_GROUP,
  props: {
    label: null,
    keepPadding: true,
  },
  getItemsAndCurrent: detailsItems,
  activity: detailsActivity,
  relations: [groupFilter],
}, {

  /* фильтр группировки */
  filterId: groupFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  props: {
    label: 'Группировать по',
  },
  getItemsAndCurrent: groupItems,
  activity: groupActivity,
  relations: [balanceTypeFilter],
}, {

  /* фильтр сортировки */
  filterId: sortFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  props: {
    label: 'Сортировать по',
  },
  getItemsAndCurrent: sortItems,
  activity: sortActivity,
  relations: [balanceTypeFilter],
}, {

  /* фильтр "Значение баланса" */
  filterId: commonBalanceFilter,
  filterType: filterTypes.RADIO_TEXT,
  props: commonBalanceProps,
  getItemsAndCurrent: commonBalanceItems,
  activity: commonBalanceActivity,
}, {

  /* фильтр "Тип баланса" */
  filterId: balanceTypeFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  props: {
    label: 'Тип баланса',
  },
  getItemsAndCurrent: balanceTypeItems,
  // activity: balanceTypeActivity, // отображается даже в поиске по телефону
  relations: [classFilter],
}, {

  /* фильтр отправки на email */
  filterId: mailFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  getProps: mailProps,
  getItemsAndCurrent: mailItems,
  activity: mailActivity,
}];

export default filterItems;
