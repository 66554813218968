// @flow
import React from 'react';
import cn from 'classnames';

import type { DisplayableData } from '../displayableData';
import MultiLineContainer from './MultiLineContainer';
import Entry from './Entry';
import multiLineCellTypes from './multiLineCellTypes';

import styles from './MultiLineCell.scss';


const emptyCell = '—';

type Props = {
  className?: string,
  type?: $Values<typeof multiLineCellTypes>,
  showDuty?: boolean,
  borderLeft?: boolean,
  borderRight?: boolean,
  data: DisplayableData,
  restrictWidth?: number,
}

const MultiLineCellTotal = ({
  className,
  type = multiLineCellTypes.COMMON,
  showDuty,
  borderLeft,
  borderRight,
  data,
  restrictWidth,
}: Props) => {
  const {
    totalCount,
    totalSum,
    hasDuty,
    dutySum,
    subsidiesSum,
    totalWithoutSubsidiesSum,
  } = data;

  const bottomCell = type === multiLineCellTypes.TOTAL;

  return (
    <MultiLineContainer
      className={cn(styles.root, className)}
      bottomSpace={bottomCell}
      borderRight={borderRight}
      restrictWidth={restrictWidth}
      type={type}
    >
      <Entry
        borderLeft={borderLeft}
        dipper
      >
        {totalCount}
      </Entry>

      {bottomCell
        && <Entry
          borderLeft={borderLeft}
        >
          {totalWithoutSubsidiesSum || emptyCell}
        </Entry>}

      {bottomCell
        && <Entry
          borderLeft={borderLeft}
        >
          {subsidiesSum || emptyCell}
        </Entry>}

      {showDuty
        ? <Entry
          borderLeft={borderLeft}
          duty={hasDuty}
          type={type}
        >
          {dutySum}
        </Entry>
        : null}

      <Entry
        borderLeft={borderLeft}
        type={type}
        summary
      >
        {totalSum}
      </Entry>

    </MultiLineContainer>
  );
};

export default MultiLineCellTotal;
