import {
  EMPLOYEEFORM_ERROR,
  EMPLOYEEFORM_ERROR_CLEAR,
} from 'app/actions/employees/employee/validation';

const defaultState = {
  // [fieldname]: true
  group1: '',
  group3: '',
};

const validation = (state = defaultState, action) => {
  if (action.type === EMPLOYEEFORM_ERROR) {
    return ({
      ...action.payload,
    });
  }
  if (action.type === EMPLOYEEFORM_ERROR_CLEAR) {
    return defaultState;
  }
  return state;
};

export default validation;
