// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import Link from 'app/common/lib/link';

import styles from './Tab.scss';


const cx = classNames.bind(styles);
export const getTabIdByNumber = (index: number | string) => (`tab-${index}`);

type Props = {
  children: Node,
  index: number,
  to?: string,
  active?: boolean,
  onClick?: (index: number, e: SyntheticEvent<HTMLElement>) => void,
  className?: string,
}

const Tab = ({ children, index, to, active, onClick, className }: Props) => {
  const id = getTabIdByNumber(index);

  const handleClick = React.useCallback((e) => {
    if (to) return;
    e.preventDefault();
    if (typeof onClick === 'function') {
      onClick(index, e);
    }
  }, [to, onClick, index]);

  return (
    <Link
      to={to || id}
      id={id}
      aria-controls={`tabpanel-${index}`}
      className={cx(styles.root, { active }, className)}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export default Tab;
