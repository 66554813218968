// @flow
import React, { type Node } from 'react';

import useSaleTypes from './useSaleTypes';
import SaleTypesDataContext from './SaleTypesDataContext';


type Props = {
  children: Node,
}

const DishesData = ({ children }: Props) => {
  const value = useSaleTypes();

  return (
    <SaleTypesDataContext.Provider value={value}>
      {children}
    </SaleTypesDataContext.Provider>
  );
};

export default DishesData;
