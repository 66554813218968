// @flow
import React, { type Node, useMemo, useCallback } from 'react';
import { range, repeat, isEmpty, reduce, times, prop } from 'ramda';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal';

import { Table, Tr, Th, Td } from 'app/common/ui-components/table';
import EmptyWarn from 'app/common/layouts/EmptyWarn';

import useCallbackRef from 'app/common/hooks/useCallbackRef';
import styles from './history-modal.scss';


export type Item = {
  id: string,
  [key: string]: any,
}

export type RenderCellContent = (columnId: string, listItem: Item) => any

export type Props = {
  children: (HandleModalDisplayed) => Node,
  className?: string,
  title?: string,
  emptyWarnMessage?: string,
  loading?: boolean,
  columnIdsList: Array<string>,
  columnNamesList: Array<string>,
  itemsList: Array<Item>,
  columnPropsList?: Array<{
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
  }>,
  renderCellContent?: RenderCellContent,
  headerContent?: Node,
  headerContentClassName?: string,
  footerContent?: Node,
  mainContentMaxHeight?: string,
  onRequestHistory?: () => Promise<void> | void,
  onDisplayStatusChange?: (status: boolean) => void,
}

const HistoryModal = ({
  children,
  className,
  title = 'История',
  emptyWarnMessage = 'Отсутствуют записи в истории',
  loading,
  columnIdsList,
  columnNamesList,
  itemsList,
  columnPropsList,
  renderCellContent = prop,
  headerContent,
  headerContentClassName,
  footerContent,
  mainContentMaxHeight,
  onRequestHistory,
  onDisplayStatusChange,
}: Props) => {
  const nothing = isEmpty(itemsList);

  const _onRequestHistory = useCallbackRef(onRequestHistory);
  const _onDisplayStatusChange = useCallbackRef(onDisplayStatusChange);
  const handleDisplayStatusChange = useCallback((display: boolean) => {
    _onDisplayStatusChange.current?.(display);
    if (display) {
      _onRequestHistory.current?.();
    }
  }, [_onRequestHistory, _onDisplayStatusChange]);

  const columns = columnIdsList.length;
  const tableColPropsList = useMemo(() => {
    const emptyProps = {};
    return columnPropsList || repeat(emptyProps, columns);
  }, [columnPropsList, columns]);

  const defaultItems = useMemo(() => {
    const emptyRow = reduce((acc, val) => {
      acc[val] = '...';
      return acc;
    }, {}, columnIdsList);
    const emptyItems = times(index => ({ ...emptyRow, id: index }), 10);

    return emptyItems;
  }, [columnIdsList]);

  const items = nothing && loading ? defaultItems : itemsList;
  const viewEmptyWarn = nothing && !loading;

  return (
    <PlainModal
      controlBy={children}
      className={className || styles.root}
      onDisplayStatusChange={handleDisplayStatusChange}
    >
      <PlainModalHeader>{title}</PlainModalHeader>

      {headerContent
        && <PlainModalContent
          className={headerContentClassName}
          hideHorizontalScrollBar
          borderBottom
        >
          {headerContent}
        </PlainModalContent>}

      <PlainModalContent maxHeight={mainContentMaxHeight} paddingFree>
        <Table className={styles.table}>
          <thead>
            <Tr header>
              {columnIdsList.map((columnId, i) => (
                <Th key={columnId} {...tableColPropsList[i]}>
                  {columnNamesList[i]}
                </Th>
              ))}
            </Tr>
          </thead>
          <tbody>
            {!viewEmptyWarn
              && items.map((item) => {
                const { id } = item;
                return (
                  <Tr alignTop key={id}>
                    {columnIdsList.map((columnId, i) => (
                      <Td key={columnId} {...tableColPropsList[i]}>
                        {loading
                          ? <div className={styles.loading}>&nbsp;</div>
                          : renderCellContent(columnId, item)}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            {!viewEmptyWarn
              && items.length < 10
              && range(0, 10 - items.length).map(key => (
                <Tr alignTop key={key}>
                  {columnIdsList.map((columnId, i) => (
                    <Td key={columnId} {...tableColPropsList[i]}>
                      &nbsp;
                    </Td>
                  ))}
                </Tr>
              ))}
          </tbody>
        </Table>

        {viewEmptyWarn
          && <EmptyWarn>
            {emptyWarnMessage}
          </EmptyWarn>}
      </PlainModalContent>

      {footerContent
        && <PlainModalFooter>
          {footerContent}
        </PlainModalFooter>}
    </PlainModal>
  );
};

export default HistoryModal;
