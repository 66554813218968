import * as types from './types'
import allIdentifierTypes from './all'


export type IdentifierType = typeof types[keyof typeof types]

// Доступные идентификаторы
export const identifiers: Record<IdentifierType, string> = {
  [types.IDENTIFIER_CARD]: 'Карты',
  [types.IDENTIFIER_KEYCHAIN]: 'Брелки',
  [types.IDENTIFIER_KEYCHAIN_LEATHER]: 'Брелки кожаные',
  [types.IDENTIFIER_BRACELET_SILICONE]: 'Браслеты силиконовые',
  [types.IDENTIFIER_BRACELET_LEATHER]: 'Браслеты кожаные',
  [types.IDENTIFIER_BANK_CARD]: 'Банковские карты',
  [types.IDENTIFIER_VIRTUAL_CARD]: 'Виртуальные карты',
  [types.IDENTIFIER_SOMETHING]: 'Другие',
  [types.IDENTIFIER_RFID_TAG]: 'RFID-метки',
  [types.IDENTIFIER_SBERBANK_CARD]: 'Карты Сбербанка',
  [types.IDENTIFIER_BRACELET_FITNESS]: 'Фитнес браслеты',
  [types.IDENTIFIER_SMART_WATCH]: 'Умные часы',
  [types.IDENTIFIER_BRACELET_KHAKI]: 'Браслеты Хаки',
  [types.IDENTIFIER_SMARTPHONE]: 'Смартфоны',
  [types.IDENTIFIER_COBRAND_CARD]: 'Кобрендовые карты',
  [types.IDENTIFIER_USER_PHOTO]: 'Фото пользователя',
  [types.IDENTIFIER_TINKOFF]: 'Банковские карты «Тинькофф»',
}

export const identifiersNames: Record<IdentifierType, string> = {
  [types.IDENTIFIER_CARD]: 'Карта',
  [types.IDENTIFIER_KEYCHAIN]: 'Брелок',
  [types.IDENTIFIER_KEYCHAIN_LEATHER]: 'Брелок кожаный',
  [types.IDENTIFIER_BRACELET_SILICONE]: 'Браслет силиконовый',
  [types.IDENTIFIER_BRACELET_LEATHER]: 'Браслет кожаный',
  [types.IDENTIFIER_BANK_CARD]: 'Банковская карта',
  [types.IDENTIFIER_VIRTUAL_CARD]: 'Виртуальная карта',
  [types.IDENTIFIER_SOMETHING]: 'Другой',
  [types.IDENTIFIER_RFID_TAG]: 'RFID-метка',
  [types.IDENTIFIER_SBERBANK_CARD]: 'Карта Сбербанка',
  [types.IDENTIFIER_BRACELET_FITNESS]: 'Фитнес браслет',
  [types.IDENTIFIER_SMART_WATCH]: 'Умные часы',
  [types.IDENTIFIER_BRACELET_KHAKI]: 'Браслет Хаки',
  [types.IDENTIFIER_SMARTPHONE]: 'Смартфон',
  [types.IDENTIFIER_COBRAND_CARD]: 'Кобрендовая карта',
  [types.IDENTIFIER_USER_PHOTO]: 'Фото пользователя',
  [types.IDENTIFIER_TINKOFF]: 'Банковская карта «Тинькофф»',
}

// для совместимости (уже не используется в pcba)
export const identifiersSing = identifiersNames

const isIdentifierType = (val: any): val is IdentifierType => {
  return allIdentifierTypes.includes(val)
}

export const stringToType = (arg: string): IdentifierType | null => {
  if (isIdentifierType(arg)) {
    return arg
  }
  return null
}

export const checkStatuses = {
  READY: 'ready',
  UNKNOWN: 'unknown',
  BLOCKED: 'blocked',
  ALREADY_USED: 'already_used',
  UNSUPPORTED: 'unsupported',
} as const
