// @flow
import React, { Fragment, type Node } from 'react';
import { isEmpty } from 'ramda';

import { PlainItemsSelector } from 'app/common/ui-next/plain-selector';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import useVendingMachinesWithPlaces from './useVendingMachinesWithPlaces';


type Props = {
  currentMachineId: ?string,
  selectedMachineIds: Array<string>,
  onSelectMachineIds: (Array<string>) => void,
  error?: string,
  selectorLabel: string,
  selectorHeader: string,
}

const CopyFrom = ({
  currentMachineId,
  selectedMachineIds,
  onSelectMachineIds,
  error,
  selectorLabel,
  selectorHeader,
}: Props): Node => {
  const [data, loading] = useVendingMachinesWithPlaces({ except: currentMachineId });
  const nothing = isEmpty(data);

  return (
    <Fragment>
      {nothing
        && !loading
        && <EmptyWarn skipPadding>{'Отсутствуют аппараты, доступные для копирования'}</EmptyWarn>}

      {!nothing
        && <PlainItemsSelector
          label={selectorLabel}
          header={selectorHeader}
          pleaseSelectText="Выберите аппарат"
          loading={loading}
          disabled={loading}
          selectedAllOnEmptySelection={false}
          items={data}
          selection={selectedMachineIds}
          onChange={onSelectMachineIds}
          skipUpperSpace
        />}

      {error
        && <PlainHelperText overflowVisible error>
          {error}
        </PlainHelperText>}
    </Fragment>
  );
};

export default CopyFrom;
