// @flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './InputCharsCount.scss';


const cx = classNames.bind(styles);

type Props = {
  count: number,
  maxCount?: number,
}

const InputCharsCount = ({ count, maxCount }: Props) => {
  const overflow = maxCount && count > maxCount;
  const success = count && !overflow;

  return (
    <div className={cx(styles.root, { overflow, success })}>
      {count || maxCount}
    </div>
  );
};

export default InputCharsCount;
