// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';


/**
* Выделение элементов
*/

export const CATEGORIES_ADD_TO_SELECTION = 'CATEGORIES_ADD_TO_SELECTION';
export const CATEGORIES_REMOVE_FROM_SELECTION = 'CATEGORIES_REMOVE_FROM_SELECTION';

export const categoriesAddToSelection = (payload: Object) => ({
  type: CATEGORIES_ADD_TO_SELECTION,
  payload,
});

export const categoriesRemoveFromSelection = (id: number) => ({
  type: CATEGORIES_REMOVE_FROM_SELECTION,
  payload: id,
});


/**
* Изменение элементов
*/

export const CATEGORIES_CHANGE_ITEM_REQUEST = 'CATEGORIES_CHANGE_ITEM_REQUEST';
export const CATEGORIES_CHANGE_ITEM_SUCCESS = 'CATEGORIES_CHANGE_ITEM_SUCCESS';
export const CATEGORIES_CHANGE_ITEM_FAILURE = 'CATEGORIES_CHANGE_ITEM_FAILURE';

const categoriesChangeItemRequest = payload => ({
  type: CATEGORIES_CHANGE_ITEM_REQUEST,
  payload,
});

const categoriesChangeItemSuccess = payload => ({
  type: CATEGORIES_CHANGE_ITEM_SUCCESS,
  payload,
});

const categoriesChangeItemFailure = payload => ({
  type: CATEGORIES_CHANGE_ITEM_FAILURE,
  payload,
});

// изменение категории меню блюд
export const categoriesChangeItem = (payload: Object) => async (dispatch: Function) => {
  dispatch(categoriesChangeItemRequest(payload));
  try {
    const { data } = await api('food/menu/categories', 'put', { data: payload });
    if (typeof data !== 'object') {
      dispatch(categoriesChangeItemFailure({ err: 'Не удалось изменить категорию (api)' }));
    }
    const { result, ...other } = data;
    dispatch(categoriesChangeItemSuccess(other));
    // if (typeof cb === 'function') cb();
    return true;
  } catch (error) {
    dispatch(categoriesChangeItemFailure({ err: 'Не удалось изменить категорию', error }));
    return false;
  }
};

// изменение статуса категорий меню блюд
export const categoriesChangeStatus = (payload: {id: [string], deleted: boolean}) => async (dispatch: Function) => {
  dispatch(categoriesChangeItemRequest(payload));
  try {
    const res = await api('food/menu/categories/deleted', 'put', { data: payload });
    dispatch(categoriesChangeItemSuccess(get(res, ['data', 'categories'], {})));
    // if (typeof cb === 'function') cb();
    return true;
  } catch (error) {
    const err = (payload.id instanceof Array && payload.id.length > 1)
      ? 'Не удалось изменить категории'
      : 'Не удалось изменить категорию';
    dispatch(categoriesChangeItemFailure({ err, error }));
    return false;
  }
};
