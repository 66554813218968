// @flow
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';


const showDataItems = () => ([[{
  id: 'show_unpaid',
  title: 'непроведенные заявки',
}], defaultStateDataCurrent]);

export default showDataItems;
