// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNoting from 'recompose/renderNothing';
import componentFromProp from 'recompose/componentFromProp';
import { type HOC } from 'recompose';

import selectRestriction from './selectRestriction';


/**
/* Компонент проверки условий пользования кабинетом (временный пароль, требование email и т.п.)
*/

export type Restriction = {
  condition: (Object, Object) => boolean,
  component: any,
};

type Props = {
  restrictions: Array<Restriction>,
};

const enhance: HOC<*, Props> = compose(
  connect(
    (state, props) => ({
      restriction: selectRestriction(state, props),
    }),
  ),

  branch(props => !props.restriction, renderNoting),
);

export default enhance(componentFromProp('restriction'));
