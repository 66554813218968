// @flow
import React from 'react';

import styles from './MultipleModalAdditionalContent.scss';


const MultipleModalAdditionalContent = () => {
  return (
    <span className={styles.root}>
      {'Изменение лимитов займет продолжительное время. Новые лимиты вступят в силу в течении нескольких часов, в зависимости от количества заведений'}
    </span>
  );
};

export default MultipleModalAdditionalContent;
