// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';
import { orgTypes as ot } from 'app/common/api/requests/food/school_types';

import { filterId } from './filterId';


export const nonEnterpriseOrgSelected = (state: State): boolean => {
  const orgTypes = getStateCurrentValue(filterId, state);
  return orgTypes[0] !== ot.ENTERPRISE;
};

export const onlySchoolsSelected = (state: State): boolean => {
  const orgTypes = getStateCurrentValue(filterId, state);
  return orgTypes[0] === ot.SCHOOL;
};

export const orgTypeSelected = (state: State): boolean => {
  const orgTypes = getStateCurrentValue(filterId, state);
  return orgTypes.length === 1;
};

export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};
