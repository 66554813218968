import CSSSticky from 'app/common/ui-components/css-sticky'
import { rootRights, specificRights } from 'app/rights'
import { useRightsByPath } from 'app/rights/Rights'

import enhanceOneEmployee from './enhanceOneEmployee'
import StickySideNav from './StickySideNav'
import Menu from '../../Menu'
import EmployeeSection from './EmployeeSection'

import styles from './OneEmployee.scss'


const orgEmployeesRights = [rootRights.USERS, specificRights.SCHOOL_EMPLOYEES] as const

type Props = {
  // leftIndent: number,
  baseUrl: string,
  id: string,
  section: string,
  title: string,
  // cancelModal: (e: ChangeEvent<HTMLElement>) => void,
}

const OneEmployee = ({
  baseUrl,
  id,
  section,
  title,
}: Props) => {
  const personId = parseInt(id, 10)
  const { write } = useRightsByPath({ pathTo: orgEmployeesRights })


  return (
    <div>
      <CSSSticky
        className={styles.menu}
        stickyClassName={styles.sticky}
        number={1}
      >
        <Menu />
      </CSSSticky>
      <StickySideNav
        currentSection={section}
        baseUrl={baseUrl}
      >

        <EmployeeSection
          section={section}
          title={title}
          personId={personId}
          locked={!write}
          baseUrl={baseUrl}
        />

      </StickySideNav>
    </div>
  )
}

export default enhanceOneEmployee(OneEmployee)
