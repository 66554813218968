// @flow
import mapObjIndexed from 'ramda/es/mapObjIndexed';
import api from 'app/actions/api';
import get from 'app/common/lib/get';
import incommingMapper from './incommingMapper';


export const EMPLOYEE_REQUEST = 'EMPLOYEE_REQUEST';
export const EMPLOYEE_SUCCESS = 'EMPLOYEE_SUCCESS';
export const EMPLOYEE_FAILURE = 'EMPLOYEE_FAILURE';
export const EMPLOYEE_CLEAR = 'EMPLOYEE_CLEAR';

const employeeRequest = payload => ({
  type: EMPLOYEE_REQUEST,
  payload,
});

const employeeSuccess = payload => ({
  type: EMPLOYEE_SUCCESS,
  payload,
});

const employeeFailure = payload => ({
  type: EMPLOYEE_FAILURE,
  payload,
});

export const employeeClear = () => ({
  type: EMPLOYEE_CLEAR,
});

// запрос данных формы сотрудника
export const employeeFetch = (id: number | string): Object => async (dispatch) => {
  dispatch(employeeRequest(id));
  try {
    const res = await api('food/employee/details', 'get', { params: { id } });
    const fields = mapObjIndexed(incommingMapper, get(res, ['data', 'fields'], {}));
    dispatch(employeeSuccess(fields));
    return fields;
  } catch (error) {
    const errorCode = get(error, ['response', 'status'], 400);
    if (errorCode === 404) {
      dispatch(employeeFailure({ err: 'Сотрудник не найден', error }));
      return null;
    }
    dispatch(employeeFailure({ err: 'Запрос данных сотрудника не удался', error }));
    return null;
  }
};

// Только имя, для других подразделов
export const employeeFetchName = (id: number | string): Object => async (dispatch) => {
  dispatch(employeeRequest(id));
  try {
    const res = await api('food/employee/name', 'get', { params: { id } });
    const name = mapObjIndexed(incommingMapper, get(res, ['data', 'name'], {}));
    dispatch(employeeSuccess({ name }));
    return name;
  } catch (error) {
    const errorCode = get(error, ['response', 'status'], 400);
    if (errorCode === 404) {
      dispatch(employeeFailure({ err: 'Сотрудник не найден', error }));
      return null;
    }
    dispatch(employeeFailure({ err: 'Запрос данных сотрудника не удался', error }));
    return null;
  }
};
