// @flow
import React, { Fragment, useMemo, useCallback } from 'react';

import { Table } from 'app/common/ui-components/table';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { useCurrencySign } from 'app/common/components-next/currency';

import type { BudgetRecord } from '../useReportData';
import getColumns from './columns';
import useActualItems, { type ActualItem } from './useActualItems';
import HeaderRow from '../HeaderRow';
import Row from '../Row';

import styles from './DetailsParents.scss';


type Props = {
  details: Array<BudgetRecord>,
}

const DetailsParents = ({ details }: Props) => {
  const currencySign = useCurrencySign();
  const columns = useMemo(() => getColumns(currencySign), [currencySign]);

  const itemsActual = useActualItems(details);

  const getColumnDataById = useCallback((data: ActualItem) => (id: string) => data[id], []);

  return (
    <Fragment>
      <CSSSticky
        number={3}
        className={styles.stickableHeader}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <HeaderRow
              columns={columns}
            />
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <thead>
          <HeaderRow
            columns={columns}
            hidden
            dummy
          />
        </thead>
        <tbody>
          {itemsActual.map((data) => {
            const { title } = data;

            return (
              <Row
                key={title}
                columns={columns}
                columnDataById={getColumnDataById(data)}
              />
            );
          })}
        </tbody>
      </Table>

    </Fragment>
  );
};

export default DetailsParents;
