// @flow
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'ramda/es/map';
import find from 'ramda/es/find';

import PlainSelect from 'app/common/ui/PlainSelect';
import { identifiers as identifiersTypesNames } from 'app/common/constants/identifiers';
import { ModalContent } from 'app/common/components/Modal';
import { toFixedValueCurrency } from 'app/common/lib/toFixedValue';
import { useCurrencySign } from 'app/common/components-next/currency';

import IdentifierView, { type IdentifierType } from './identifier-view';
import styles from './select-type-form.scss';


export type { IdentifierType };

export type IdentifierTypeInfo = {
  id: IdentifierType,
  price: number,
  delay: number,
  enough: boolean,
}

type Props = {
  current: IdentifierType | '',
  types: Array<IdentifierTypeInfo>,
  disabled: boolean,
  error?: string,
  hidePrice?: boolean,
  onSetType: (type: IdentifierType | (type: IdentifierType) => IdentifierType) => void,
}

const SelectTypeForm = ({
  current,
  types,
  disabled,
  error,
  hidePrice,
  onSetType,
}: Props) => {
  const { t } = useTranslation('identifiers-next');
  const currency = useCurrencySign();

  const selectorData = useMemo(() => map(({ id }) => ({
    id,
    title: identifiersTypesNames[id] || '???',
  }), types), [types]);

  const price = useMemo(() => {
    const currentInfo = find(({ id }) => id === current, types) || {};
    const { price: p } = currentInfo;
    return typeof p === 'number'
      ? toFixedValueCurrency(p, true, currency)
      : '???';
  }, [types, current, currency]);

  const handleChangeType = useCallback((e) => {
    const { value } = e.currentTarget;
    // $FlowFixMe
    onSetType(value);
  }, [onSetType]);

  return (
    <ModalContent className={styles.root}>
      <div className={styles.danger}>
        {t('identifier_entering_error_unknown_type_message')}
      </div>
      <label htmlFor="id_type">
        {t('identifier_entering_select_type_label')}
      </label>
      <div className={styles.select}>
        <PlainSelect
          data={selectorData}
          name="id_type"
          current={current}
          placeholder={t('identifier_entering_select_type_placeholder')}
          onChange={handleChangeType}
          disabled={disabled}
          isError={!!error}
          errorText={error}
          uni
        />
      </div>
      {current
        && <IdentifierView
          type={current}
          price={!hidePrice ? price : null}
        />}
    </ModalContent>
  );
};

export default SelectTypeForm;
