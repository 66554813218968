import { Column } from './types'
import { headerProps } from './useHeaderColumnProps'


const columnProps = {
  ...headerProps,
  [Column.Title]: { alignLeft: true },
}

export function useColumnProps() {
  return columnProps
}
