// @flow
import {
  SUBSIDIES_DATA_SUCCESS,
  SUBSIDIES_DATA_CLEAR,
} from 'app/actions/reports/subsidies/data';

export type SummaryType = {
  split1: ?number,
  split2: ?number,

  count: ?number,
  count_common: ?number,
  count_personal: ?number,
  students_common_count: ?number,
  students_count: ?number,
  students_personal_count: ?number,
  total: ?number,
  total_common: ?number,
  total_personal: ?number,
}

const defaultSummary: SummaryType = {
  split1: null,
  split2: null,
  count: null,
  count_common: null,
  count_personal: null,
  students_common_count: null,
  students_count: null,
  students_personal_count: null,
  total: null,
  total_common: null,
  total_personal: null,
};

type ReportSubsidiesDataType = {
  time_mode: 'daily' | 'monthly' | null,
  split_mode: ?boolean,
  summary: SummaryType,
  totals: Array<Object>,
  students: Array<Object>,
  klasses: Array<Object>,
  schools: Array<Object>,
}

const defaultState = {
  time_mode: null, // daily|monthly, приходит всегда
  split_mode: false, // true | false, разделение субсидий
  summary: defaultSummary, // Итоговые числа над таблицей, всегда
  totals: [], // Итоговая строка внизу таблицы, для всех вариантов с детализацией
  students: [], // В случае выборки по одной школе и группировке по учащимся
  klasses: [], // В случае выборки по одной школе и группировке по классам
  schools: [], // В случае выборки по нескольким школам
  // klasses_and_orders: [] // В случае выборки по одной школе и группировки по классам и справкам
};

const data = (state: ReportSubsidiesDataType = defaultState, { type, payload }: Object) => {
  if (type === SUBSIDIES_DATA_SUCCESS) {
    return ({ ...defaultState, ...payload });
  }
  if (type === SUBSIDIES_DATA_CLEAR) {
    return defaultState;
  }
  return state;
};

export default data;
