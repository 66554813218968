// @flow
import { iconTypes } from './icon-types';
import { colorTypes } from './colors';


type Props = {
  coloringType: 'fill' | 'stroke' | 'none',
  width: number,
  height: number,
  defaultColor: $Values<typeof colorTypes>;
}

type PropsOverride = {
  coloringType?: 'fill' | 'stroke' | 'none',
  width?: number,
  height?: number,
  defaultColor?: $Values<typeof colorTypes>;
}

// свойства по-умолчанию для всех иконок
const defaultProps: Props = {
  coloringType: 'fill',
  width: 24,
  height: 24,
  defaultColor: colorTypes.COLOR_GRAY,
};

// переписывание свойств по-умолчанию для определенных иконок
const propsOverrides: {
  [$Values < typeof iconTypes >]: PropsOverride
} = {
  [iconTypes.OK]: {
    coloringType: 'stroke',
    defaultColor: colorTypes.COLOR_SUCCESS,
  },

  [iconTypes.USER_LINK_BLUE]: {
    coloringType: 'none',
    width: 36,
    height: 25,
  },

  [iconTypes.USER_LINK_GREEN]: {
    coloringType: 'none',
    width: 36,
    height: 25,
  },

  [iconTypes.EXCEL]: {
    coloringType: 'none',
    width: 30,
    height: 30,
  },

  [iconTypes.USER_SINGLE]: {
    width: 14,
    height: 16,
  },

  [iconTypes.USER_COUPLE]: {
    width: 24,
    height: 21,
  },

  [iconTypes.CALENDAR]: {
    width: 25,
    height: 22,
    defaultColor: colorTypes.COLOR_PRIMARY,
  },

  [iconTypes.PEN]: {
    width: 12,
    height: 12,
  },

  [iconTypes.LIGHT_CROSS]: {
    width: 12,
    height: 12,
    defaultColor: colorTypes.COLOR_DARKGRAY,
  },

  [iconTypes.OK_CIRCLE]: {
    coloringType: 'stroke',
    width: 16,
    height: 16,
    defaultColor: colorTypes.COLOR_WHITE,
  },

  [iconTypes.VIDEO_CONFERENCE]: {
    coloringType: 'none',
  },

  [iconTypes.UPLOAD_FILE]: {
    coloringType: 'fill',
    defaultColor: colorTypes.COLOR_PRIMARY,
  },

  [iconTypes.ADD_TO_RIGHT]: {
    width: 15,
    height: 12,
  },

  [iconTypes.SPLIT]: {
    width: 19,
    height: 17,
  },

  [iconTypes.PORTION]: {
    width: 10,
    height: 11,
  },

  [iconTypes.MOUSE]: {
    width: 24,
    height: 39,
  },

  [iconTypes.CROSS_BOLD]: {
    width: 16,
    height: 16,
  },

  [iconTypes.CHECKED]: {
    defaultColor: colorTypes.COLOR_PRIMARY,
  },

  [iconTypes.PLUS]: {
    width: 20,
    height: 20,
  },

  [iconTypes.EXCHANGE]: {
    width: 20,
    height: 20,
  },

  [iconTypes.EXCHANGE_CIRCLE]: {
    width: 18,
    height: 18,
  },

  [iconTypes.APP_ATTENDANCE]: {
    width: 70,
    height: 70,
    defaultColor: colorTypes.COLOR_PRIMARY,
  },

  [iconTypes.APP_CAFETERIA]: {
    width: 70,
    height: 70,
    defaultColor: colorTypes.COLOR_PRIMARY,
  },

  [iconTypes.GEAR]: {
    width: 22,
    height: 22,
  },

  // add here >>>
};

export const getPredefinedProps = (iconType: $Values<typeof iconTypes>): Props => {
  if (propsOverrides[iconType]) {
    return {
      ...defaultProps,
      ...propsOverrides[iconType],
    };
  }

  return defaultProps;
};
