import { ReactNode, Children, cloneElement, useCallback, Fragment, ChangeEvent, forwardRef, useImperativeHandle } from 'react'
import cn from 'classnames'
import ReactModal from 'react-modal'
import { path } from 'ramda'

import { isAvailableDisplayName } from './available-display-name'
import useModalDisplayed, { type HandleModalDisplayed } from './use-modal-displayed'

import styles from './plain-modal.scss'

/**
 *  Модальное окно
 */

ReactModal.setAppElement('#root')
const emptyFunc = () => { }

export const getModalContainer = () => {
  const elem = document.querySelector('#modal')
  if (!elem) {
    throw Error('👻 Не определен елемент для вывода окна диалога')
  }
  return elem
}

export type PlainModalRefHandle = { changeModalDisplayed: HandleModalDisplayed }

export type PlainModalProps = {
  className?: string
  children?: ReactNode
  formId?: string
  show?: boolean // DEPRECATED // use ref
  controlBy?: (handleVisibility: HandleModalDisplayed) => ReactNode, // кнопка для показа окна
  onSubmit?: (form: HTMLFormElement) => boolean | Promise<boolean> | Promise<void>
  onClose?: () => void
  onDisplayStatusChange?: (v: boolean) => void
  onSubmitStatusChange?: (v: boolean) => void
}

const PlainModal = forwardRef<PlainModalRefHandle, PlainModalProps>(({
  className,
  children,
  formId,
  show = false, // DEPRECATED
  controlBy,
  onSubmit,
  onClose,
  onDisplayStatusChange,
  onSubmitStatusChange = emptyFunc,
}, ref) => {
  const [modalDisplayed, handleModalDisplayed] = useModalDisplayed({
    externalState: !!show,
    onDisplayStatusChange,
    onClose,
  })

  const handleSubmit = useCallback(async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formElement = e.target as HTMLFormElement
    if (formId) {
      const { id } = formElement
      // эта ли форма сабмитится
      if (id !== formId) {
        return
      }
    }
    if (onSubmit) {
      onSubmitStatusChange(true)
      const res = await onSubmit(formElement)
      onSubmitStatusChange(false)
      if (res) {
        handleModalDisplayed(false)
      }
    }
  }, [onSubmit, handleModalDisplayed, formId, onSubmitStatusChange])

  useImperativeHandle(ref, () => ({ changeModalDisplayed: handleModalDisplayed }), [handleModalDisplayed])

  const FormContainer = onSubmit ? 'form' : Fragment
  const formSubmitProps = onSubmit ? { onSubmit: handleSubmit, id: formId } : {}

  return (
    <>
      {controlBy && controlBy(handleModalDisplayed)}
      {modalDisplayed && (
        <ReactModal
          isOpen
          parentSelector={getModalContainer}
          className={cn(styles.modal, className)}
          overlayClassName={styles.overlay}
          aria={{ labelledby: 'heading' }}
          role="dialog"
        >
          <FormContainer {...formSubmitProps}>
            {!children && (
              <div className={styles.empty}>
                {'Модальное окно, контент не задан'}
              </div>
            )}

            {Children.map(children, (Component) => {
              if (!Component) {
                return null
              }
              const displayName = path(['type', 'displayName'], Component)
              if (isAvailableDisplayName(displayName) && typeof Component === 'object' && 'props' in Component) {
                const { props } = Component
                return cloneElement(Component, { ...props, handleModalDisplayed })
              }
              console.log('%c👻 Неверный child для PlainModal', 'color: LawnGreen')
              return null
            })}
          </FormContainer>
        </ReactModal>
      )}
    </>
  )
})

export default PlainModal
