import styles from './Status.scss'


type Props = {
  ID: string
  statusTitle: string
}

export const Status = ({
  ID,
  statusTitle,
}: Props) => {
  return (
    <div className={styles.root}>
      <div className={styles.indicator}>
        <div className={styles.circle} />
      </div>

      <div className={styles.id}>{ID}</div>

      <div className={styles.status}>
        <div>{statusTitle}</div>
      </div>
    </div>
  )
}
