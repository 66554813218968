// @flow
import { useState, useCallback } from 'react';
import { isEmpty } from 'ramda';


type Actions = {
  updateMachineIds: (Array<string>) => void,
  checkErrors: () => ?string,
  reset: () => void,
}

const defaultState: Array<string> = [];

export default function useMachinesState(errorCode: string): [
  Array<string>,
  Actions
] {
  const [selectedMachineIds, setSelectedMachineIds] = useState<Array<string>>(defaultState);

  const reset = useCallback(() => {
    setSelectedMachineIds(defaultState);
  }, []);

  const checkErrors = useCallback(() => {
    if (isEmpty(selectedMachineIds)) {
      return errorCode;
    }
    return null;
  }, [selectedMachineIds, errorCode]);

  return [selectedMachineIds, {
    updateMachineIds: setSelectedMachineIds,
    checkErrors,
    reset,
  }];
}
