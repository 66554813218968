// @flow
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import defaultProps from 'recompose/defaultProps';
import type { HOC } from 'recompose';

import nope from 'app/common/lib/nope';

type Props = {
  id: number,
  checked?: boolean,
  kitRemoveFromSelection?: (number) => void,
  kitAddToSelection?: (number) => void,
};

const enhanceDishesKit: HOC<*, Props> = compose(
  defaultProps({
    kitRemoveFromSelection: nope,
    kitAddToSelection: nope,
  }),

  withHandlers({
    toggleItem: ({ id, checked, kitRemoveFromSelection, kitAddToSelection }) => () => (
      checked ? kitRemoveFromSelection(id) : kitAddToSelection(id)
    ),
  }),
);

export default enhanceDishesKit;
