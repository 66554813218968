// @flow
import { createContext } from 'react';

import {
  type State,
  type ApiHandlers,
  type Paging,
  defaultState,
  defaultApiHandlers,
  defaultPaging,
} from './useDishes';


type DishesDataContextType = [
  State,
  ApiHandlers,
  Paging
]

const defaultContext: DishesDataContextType = [
  defaultState,
  defaultApiHandlers,
  defaultPaging,
];

export default createContext<DishesDataContextType>(defaultContext);
