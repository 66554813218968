// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as orgFlags } from '../org';
import { flags as customerTypeFlags } from '../customerType';
import { flags as currentStateDateFlags } from '../currentStateDate';


const studentStatusActivity = (state: State): boolean => {
  return (
    orgFlags.singleOrgSelected(state)
    && customerTypeFlags.onlyStudentCustomerSelected(state)
    && currentStateDateFlags.currentStateRadioSelected(state)
  );
};

export default studentStatusActivity;
