import { isEmpty } from 'ramda'

import Loader from 'app/common/components/Loader'
import EmptyWarn from 'app/common/layouts/EmptyWarn'
import { RowLayoutContent } from 'app/common/layouts/RowLayout'
import { useCurrencySign } from 'app/common/components-next/currency'
import detailsFilter from 'app/components/common/reports/filters/details'
import { useFilterApplied } from 'app/common/ui-components/filter'
import DetailsHeader from 'app/components/common/reports/DetailsHeader'
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink'
import EmptyReportCSS from 'app/components/common/reports/EmptyReportCSS'

import { ReportSection, Stub } from 'app/common/templates-next/report-template'
import HeaderBar from './HeaderBar'
import Summary from './Summary'
import Details from './details'
import useReportData from './useReportData'

import styles from './Report.scss'


type Props = {
  reportId: number | null,
}

const Report = ({ reportId }: Props) => {
  const currencySign = useCurrencySign()

  const [data, loading] = useReportData(reportId)
  const { c_schools: schools, ...summary } = data
  const { total } = summary

  const detailsEnabled = Boolean(useFilterApplied(detailsFilter, 0))
  const schoolsEmpty = !schools || isEmpty(schools)

  const nothing = (
    detailsEnabled ? schoolsEmpty : !summary
  )

  if (!reportId) {
    return (
      <EmptyReportCSS
        stickyNumber={2}
        headerBar={
          <HistoryLink reportType={reportTypes.REPORT_FINANCES_E_MONEY} />
        }
      />
    )
  }
  const reportEmpty = !(reportId)
  const header = reportEmpty ? '' : 'Отчет «Корректировки»'

  return (
    <ReportSection
      header={header}
      headerBar={
        <HeaderBar reportId={reportId} />
      }
      fullscreenEnabled={!schoolsEmpty && detailsEnabled}
      skipPadding
    >
      {loading
        ? <Loader />

        : <>
          {summary
            && <Summary
              loading={loading}
              summary={summary}
              currencySign={currencySign}
            />}

          {detailsEnabled
            && <DetailsHeader reportTitle="ЭДС" />}

          {reportEmpty
            && <Stub />}

          {detailsEnabled
            && !schoolsEmpty
            && <Details data={schools} total={total} />}

          {nothing
            && <RowLayoutContent borderTop paddingfree>
              <EmptyWarn className={styles.empty}>
                {'Данные отсутствуют'}
              </EmptyWarn>
            </RowLayoutContent>}
        </>}
    </ReportSection>
  )
}

export default Report
