// @flow
import { useState, useEffect, useCallback, useMemo } from 'react';
import values from 'ramda/es/values';
import groupBy from 'ramda/es/groupBy';
import pipe from 'ramda/es/pipe';

import { api } from 'app/api';

import {
  GET_BUILDINGS,
  type BuildingItem,
  type MenuBuildingsResult,
} from 'app/common/api/requests/food/schools/buildings';


const defaultBuildingsState = {};
export type Buildings = MenuBuildingsResult
export type { BuildingItem };

export default function useBuildingsData(
  orgId: number,
  autoload?: boolean = true,
): [
  [
    {
      buildings: MenuBuildingsResult,
      itemsCurrent: Array<BuildingItem>,
    },
    boolean,
    () => Promise<void>
  ],
  [(((Buildings) => Buildings) | Buildings) => void]
] {
  const [buildings, setBuildings] = useState<Buildings>(defaultBuildingsState);
  const [buildingsLoading, setBuildingsLoading] = useState(true);

  const handleRequestBuildings = useCallback(async () => {
    setBuildingsLoading(true);
    const res = await api.request(GET_BUILDINGS, {
      error: 'Не удалось загрузить корпуса',
      requestPathParams: { orgId },
    });
    setBuildingsLoading(false);

    if (res) {
      setBuildings(res);
    }
  }, [orgId]);

  useEffect(() => {
    if (autoload) {
      handleRequestBuildings();
    }
  }, [autoload, handleRequestBuildings]);

  // конвертация в массив и деление на текущие и удаленные
  const buildingsGroupped = useMemo(() => {
    const { itemsCurrent = [] }: { itemsCurrent?: Array<BuildingItem> } = pipe(
      values,
      groupBy(({ deleted }) => (deleted ? 'itemsDeleted' : 'itemsCurrent')),
    )(buildings);

    return {
      itemsCurrent,
      buildings,
    };
  }, [buildings]);

  return [
    [buildingsGroupped, buildingsLoading, handleRequestBuildings],
    [setBuildings],
  ];
}
