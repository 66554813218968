import { OrgGroupsByOrgType, ParamsPUT } from 'app/dataTypes/org/orgGroup'

import { Request } from '../../index'
import { convertResult } from './common'


export { convertResult }

type ParamsAPI = {
  title: string
}

type UrlParams = {
  orgGroupID: number | string,
}

export const convertParams = (params: ParamsPUT): [ParamsAPI, UrlParams] => {
  const { title, orgGroupID } = params
  return ([{ title }, { orgGroupID }])
}

export const PUT_ORG_GROUP: Request<ParamsPUT, OrgGroupsByOrgType, UrlParams> = [
  'food/school_groups/{orgGroupID}',
  'put',
  ['data', 'school_groups'],
]
