import nest from 'recompose/nest';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import sort from 'ramda/es/sort';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import PlainFilter, { FilterOptionsModal } from 'app/common/ui/PlainFilter';
import withModal from 'app/common/containers/withModal';

import withStudents from './hocs/withStudents';
import OpenModal from './OpenModal';
import styles from './FilterItems.scss';


const byTitle = ascend(prop('title'));

const filterProps = {
  className: styles.filter,
  title: 'Учащиеся:',
  maxRows: 3,
};

const modalProps = {
  header: 'Выбор учащихся',
  enableSelectAll: true,
  nothingIsAll: true,
  sortFunc: sort(byTitle),
};

const TheStudentsFilter = nest(
  withProps(filterProps)(PlainFilter),
  withProps(modalProps)(
    nest(OpenModal, FilterOptionsModal),
  ),
);

export default compose(
  withModal,
  withStudents,
)(TheStudentsFilter);
