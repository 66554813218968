// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import DayPicker, { DateUtils } from 'react-day-picker';
import moment from 'moment-timezone';
import componentFromProp from 'recompose/componentFromProp';
import { withTranslation } from 'react-i18next';

import omitProps from 'app/common/containers/omitProps';
import config from 'app/config';
import PlainNav, { NavItem } from 'app/common/ui/PlainNav';
import i18n from 'app/i18n';

import IconCalendar from '../../icons/IconCalendar';
import ClickOutside from '../ClickOutside';
import localeUtils from '../localeUtils';
import YearMonthForm from './YearMonthForm';

import styles from './DatePicker.scss';
import '../calendar.scss';
import stylesYearsMonths from './YearsMonths.scss';


const Outside = componentFromProp('component');
const Div = omitProps(['onClickOutside'])('div');

type Props = {
  day: Object,
  day2?: Object,
  onChangeDay: Function,
  onChangeRange: Function,
  double?: boolean,
  disabledDays?: Function,
  popupTop?: boolean,
  week?: boolean,
  highlighted?: boolean,
  disabled?: boolean,
  className?: string,
  showYearMonthSelector?: boolean,
  name?: string,
  basePath?: string,
  push?: Function, // router push, need basePath
  t: (s: string, t: Object) => string,
  tReady: any,
}

type State = {
  isOpen: boolean,
  month: number,
  year: number,
}

const cx = classNames.bind(styles);

export default withTranslation('ui')(class DatePicker extends React.Component<Props, State> {
  // static defaultProps = {
  //   double: false, // Сдвоенный календарь
  //   popupTop: false, // Выпадать вверх
  //   week: false, // выбор недели, вместо дня
  //   highlighted: false, // подсветка всегда (не только при нажатии)
  //   disabled: false,
  //   showYearMonthSelector: false, // показывать выпадающие списки быстрого перемещения
  // };
  openLink: React.ElementRef<'a'> | null;

  calendar: React.ElementRef<'div'> | null;

  constructor({ day }: { day: Date }) {
    super();
    this.state = {
      isOpen: false,
      month: day ? day.getMonth() : new Date().getMonth(),
      year: day ? day.getFullYear() : new Date().getFullYear(),
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.collapse);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isOpen } = this.state;
    const { day } = this.props;

    if (day !== prevProps.day && day instanceof Date) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        month: day.getMonth(),
        year: day.getFullYear(),
      });
    }

    // расстояния от края иконки до края календаря справа и вверху
    const dx = 7;
    const dy = 5;

    // ------------------------------------
    // Процесс открытия/закрытия
    // ____________________________________
    if ((isOpen !== prevState.isOpen) && isOpen) {
      // допуск на сколько можно выйти календарю за нижний край
      const admission = 30;

      const elCalendar = this.calendar;
      const elOpenLink = this.openLink;

      const {
        top: openLinkTop,
        bottom: openLinkBottom,
        right: openLinkRight,
        left: openLinkLeft,
      } = elOpenLink ? elOpenLink.getBoundingClientRect() : {};

      const {
        left: calendarLeft,
        top: calendarTop,
        right: calendarRight,
        bottom: calendarBottom,
      } = elCalendar ? elCalendar.getBoundingClientRect() : {};

      const calendarWidth = calendarRight - calendarLeft;
      const calendarHeight = calendarBottom - calendarTop;


      if (!document.documentElement) return;

      // проверка, умещается ли в видимую область
      // -> по вертикали
      if (openLinkTop + calendarHeight - admission < document.documentElement.clientHeight) {
        // умещается
        if (elCalendar) {
          elCalendar.style.top = `${openLinkTop - dy}px`;
        }
      } else if (elCalendar) {
        // не умещается, выпадение вверх
        elCalendar.style.top = `${openLinkBottom - calendarHeight + dy}px`;
      }

      // -> по горизонтали (слева)
      if (openLinkRight + dx - calendarWidth > 0) {
        if (elCalendar) {
          elCalendar.style.left = `${openLinkRight - calendarWidth + dx}px`;
        }
      } else if (elCalendar) {
        elCalendar.style.left = `${openLinkLeft - dx}px`;
      }
    }

    // ------------------------------------
    // Процесс переключения
    // один месяц / два месяца
    // ____________________________________
    const { day2 } = this.props;

    if (prevProps.day2 !== day2) {
      const elCalendar = this.calendar;
      const elOpenLink = this.openLink;

      const {
        right: openLinkRight,
      } = elOpenLink ? elOpenLink.getBoundingClientRect() : {};

      const {
        left: calendarLeft,
        right: calendarRight,
      } = elCalendar ? elCalendar.getBoundingClientRect() : {};

      const calendarWidth = calendarRight - calendarLeft;

      if (!elCalendar) return;
      elCalendar.style.left = `${openLinkRight - calendarWidth + dx}px`;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.collapse);
  }

  handleMonthChange = (month: number) => {
    this.setState({ month });
  };

  handleYearChange = (year: number) => {
    this.setState({ year });
  };

  toggleDropdown = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }));
  };

  handleDayClick = (newDay: Date, _: any, e: SyntheticEvent<HTMLElement>) => { // в разных версиях разная последовательность параметров
    // новая версия: (newDay, _, e)
    // const [e, newDay] = ((process.env.APP_NAME || process.env.REACT_APP_NAME) === 'cabinet'
    //   ? params
    //   : [params[2], params[0]]
    // );
    e.preventDefault();
    const {
      day,
      day2,
      disabledDays,
      onChangeRange,
      onChangeDay,
      basePath,
      push,
      name,
    } = this.props;

    if ((typeof disabledDays === 'function') && disabledDays(newDay)) {
      return;
    }

    if (day2) {
      const range = DateUtils.addDayToRange(newDay, { from: day, to: day2 });
      if (typeof onChangeRange === 'function' && range.from && range.to) {
        onChangeRange(range);
      } else if (push && basePath) {
        push(`${basePath}/${moment(newDay).format(config.dateFormat)}`);
      }
    } else {
      if (typeof onChangeDay === 'function') {
        onChangeDay(newDay, name);
      } else if (push && basePath) {
        // this.getWeekFromDay(newDay);
        push(`${basePath}/${moment(newDay).format(config.dateFormat)}`);
      }
      this.collapse();
    }
  };

  handleChange2Single = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { day, onChangeDay } = this.props;
    if (typeof onChangeDay === 'function') {
      onChangeDay(day);
    }
  };

  handleChange2Double = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const { day, onChangeRange } = this.props;
    if (typeof onChangeRange === 'function') {
      onChangeRange({ from: day, to: day });
    }
  };

  collapse = () => {
    this.setState({
      isOpen: false,
    });
  };

  refOpenLink = (ref: React.ElementRef<'a'> | null) => {
    this.openLink = ref;
  };

  refCalendar = (ref: React.ElementRef<'div'> | null) => {
    this.calendar = ref;
  };

  renderDay = (day: Date) => {
    const date = day.getDate();
    const { week } = this.props;
    const weekHoverClass = week ? 'week-hover' : '';
    return (
      <div className={`DayPicker-Day-Container ${weekHoverClass}`}>
        { date }
      </div>
    );
  };

  renderCalendar = () => {
    const { isOpen, year, month } = this.state;

    if (!isOpen) return null;

    const {
      day,
      day2,
      double,
      disabledDays,
      popupTop,
      week,
      showYearMonthSelector,
      t,
    } = this.props;

    const periodName = (day2 && !week
      ? t('date_picker_select_period')
      : t('date_picker_select_week'));

    const header = (day2
      ? t('date_picker_select', { periodName })
      : t('date_picker_select_day'));

    const additional = (showYearMonthSelector ? {
      captionElement: <YearMonthForm
        onMonthChange={this.handleMonthChange}
        onYearChange={this.handleYearChange}
        date={{}} // данные добавятся в DayPicker
        localeUtils={{}} // данные добавятся в DayPicker
      />,
      month: new Date(year, month),
      className: stylesYearsMonths.additional,
    } : {});

    return (
      <div
        className={cx({
          calendar: true,
          popupDown: !popupTop,
          popupTop,
          open: isOpen,
        })}
        ref={this.refCalendar}
      >
        {double
          && <div className={styles.menu}>
            <PlainNav className={styles.menuFix}>
              <NavItem
                to="oneDaySelect"
                onClick={this.handleChange2Single}
                active={!day2}
              >
                {t('date_picker_type_menu_day_item')}
              </NavItem>
              <NavItem
                to="periodSelect"
                onClick={this.handleChange2Double}
                active={!!day2}
              >
                {t('date_picker_type_menu_period_item')}
              </NavItem>
            </PlainNav>
          </div>}
        {!showYearMonthSelector
          && <div className={styles.label}>{header}</div>}
        <DayPicker
          numberOfMonths={(day2 && !week) ? 2 : 1}
          initialMonth={day}
          onDayClick={this.handleDayClick}
          disabledDays={disabledDays}
          selectedDays={day2
            ? d => DateUtils.isDayInRange(d, { from: day, to: day2 })
            : d => DateUtils.isSameDay(day, d)}
          localeUtils={localeUtils}
          locale={i18n.language}
          renderDay={this.renderDay}
          enableOutsideDays
          {...additional}
        />
      </div>
    );
  };


  render() {
    const { isOpen } = this.state;
    const {
      day, // eslint-disable-line no-unused-vars
      day2, // eslint-disable-line no-unused-vars
      onChangeDay, // eslint-disable-line no-unused-vars
      onChangeRange, // eslint-disable-line no-unused-vars
      double, // eslint-disable-line no-unused-vars
      disabledDays, // eslint-disable-line no-unused-vars
      popupTop, // eslint-disable-line no-unused-vars
      week, // eslint-disable-line no-unused-vars
      basePath, // eslint-disable-line no-unused-vars
      showYearMonthSelector, // eslint-disable-line no-unused-vars
      highlighted,
      disabled,
      className,
      push, // eslint-disable-line no-unused-vars
      tReady, // eslint-disable-line no-unused-vars
      t, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;

    return (
      <Outside
        component={isOpen ? ClickOutside : Div}
        className={styles.main}
        onClickOutside={this.collapse}
      >
        <a
          href="dateSelect"
          ref={this.refOpenLink}
          className={cx({ openLink: true, disabled, open: isOpen }, className)}
          onClick={this.toggleDropdown}
          {...other}
        >
          <IconCalendar
            className={cx({
              icon: true,
              active: !disabled && (isOpen || highlighted),
            })}
          />
        </a>
        {this.renderCalendar()}
      </Outside>
    );
  }
});
