// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';
import isEmpty from 'ramda/es/isEmpty';
import path from 'ramda/es/path';
import filter from 'ramda/es/filter';

import { type SellPoints, type SellPointItem } from '../../commonHooks/useSellPointsData';


const defaultForOrderState = [];
type SellPointsUpdateRequestFunc = (data: Object, errorString: string) => Promise<boolean>

export default function useSellPointForOrder(
  sellPoints: SellPoints,
  handleSellPointUpdateRequest: SellPointsUpdateRequestFunc,
) {
  const [forOrder, setForOrder] = useState(defaultForOrderState);


  // меняем только на сервере
  const handleChangeSellPointForOrder = useCallback((currentSelected: SellPoints) => {
    const currentSelectedId = path([0, 'id'], values(currentSelected));

    handleSellPointUpdateRequest({
      id: currentSelectedId,
      for_order: true,
    }, 'Не удалось переназначить точку выдачи для заявок');
  }, [handleSellPointUpdateRequest]);


  // изменения на сервере подхватятся здесь
  useEffect(() => {
    if (isEmpty(sellPoints)) return;

    const forOrderItem: SellPointItem | void = pipe(
      values,
      find(propEq('for_order', true)),
    )(sellPoints);

    if (!forOrderItem) {
      console.error('Точка выдачи для заявок не определена на сервере');
      return;
    }

    setForOrder([forOrderItem.id]);
  }, [sellPoints]);


  const sellPointsReadyForOrder = useMemo(() => filter(propEq('deleted', false), sellPoints), [sellPoints]);


  return [
    forOrder,
    sellPointsReadyForOrder,
    handleChangeSellPointForOrder,
  ];
}
