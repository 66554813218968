// @flow
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'ramda/es/isEmpty';

import { POST_ITEMS_ADD_REMOVE, convertParams as convertParamsDishes } from 'app/common/api/requests/food/menu/menu_items_add_remove';
import { POST_COMPLEXES_ADD_REMOVE, convertParams as convertParamsKits } from 'app/common/api/requests/food/menu/complexes_add_remove';
import { menuNeedUpdate } from 'app/actions/orgs/menu/options';
import { api } from 'app/api';


type Options = {
  orgId: number,
  sellPointId: number,
  date: string,
  dishesToRemove: Array<number>,
  kitsToRemove: Array<number>,
  onRequestPending: (boolean) => void,
}

export default function useMenuClearAll({
  orgId,
  sellPointId,
  date,
  dishesToRemove,
  kitsToRemove,
  onRequestPending,
}: Options) {
  const dispatch = useDispatch();
  const deleteDishes = useCallback(async () => {
    if (isEmpty(dishesToRemove)) return true;
    const res = await api.request(POST_ITEMS_ADD_REMOVE, {
      error: 'Не удалось удалить продукцию из меню',
      params: {
        orgId,
        sellPointId,
        date,
        add: [],
        remove: dishesToRemove,
      },
      convertions: {
        convertParams: convertParamsDishes,
      },
    });

    return !!res;
  }, [
    orgId,
    sellPointId,
    date,
    dishesToRemove,
  ]);

  const deleteKits = useCallback(async () => {
    if (isEmpty(kitsToRemove)) return true;
    const res = await api.request(POST_COMPLEXES_ADD_REMOVE, {
      error: 'Не удалось удалить комплекты из меню',
      params: {
        orgId,
        sellPointId,
        date,
        add: [],
        remove: kitsToRemove,
      },
      convertions: {
        convertParams: convertParamsKits,
      },
    });

    return !!res;
  }, [
    orgId,
    sellPointId,
    date,
    kitsToRemove,
  ]);

  const clearAll = useCallback(async () => {
    onRequestPending(true);
    const [ok1, ok2] = await Promise.all([deleteKits(), deleteDishes()]);
    if (ok1 || ok2) {
      dispatch(menuNeedUpdate(true));
    }
    onRequestPending(false);
  }, [deleteKits, deleteDishes, onRequestPending, dispatch]);

  return clearAll;
}
