// @flow
import type { Request } from '../index';


export type DayCaresParams = {
  school_id?: number,
  schoolyear_id?: number,
  ids?: Array<number | string>,
};

export type DayCaresItem = {
  id: number,
  title: string,
};

export type DayCaresResult = {
  [id: string]: DayCaresItem
};

export const GET_DAY_CARES: Request<
  DayCaresParams,
  DayCaresResult,
  void
> = [
  'reports/day_cares_list',
  'get',
  ['data', 'day_cares'],
];
