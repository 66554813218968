// @flow
import React, { useCallback, useState, type Node } from 'react';
import classNames from 'classnames/bind';

import { DotsLoader } from 'app/common/components/Loader';

import styles from './Row.scss';


const cx = classNames.bind(styles);

type Props = {
  children: any,
  className?: string,
  hovered?: boolean,
  height?: number,
  width?: number,
  loading?: boolean,
  interline?: boolean,
  details?: Node | (cb: (e?: SyntheticEvent<HTMLElement>) => void) => Node,
};

const Row = ({
  children,
  className,
  hovered,
  height,
  width,
  loading,
  details,
  interline,
  ...others
}: Props) => {
  const style = {
    // $FlowFixMe
    ...(height || height === 0 ? { height } : {}),
    ...(width || width === 0 ? { width } : {}),
  };

  const [showDetails, setShowDetails] = useState(false);
  const handleToggleDetails = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setShowDetails(state => !state);
  }, []);

  const detailsContent = useCallback(handle => (typeof details === 'function'
    ? details(handle)
    : details), [details]);

  return (
    <div
      className={cx(styles.root, className, { hovered, interline, showDetails })}
      style={style}
      // $FlowFixMe
      {...others}
    >
      <div className={cx(styles.row, { compensation: showDetails })}>
        {typeof children === 'function'
          ? children(handleToggleDetails, showDetails)
          : children}

        {loading
          && <div className={styles.loaderContainer}>
            <DotsLoader className={styles.loader} />
          </div>}
      </div>

      {showDetails
        && detailsContent(handleToggleDetails)}
    </div>
  );
};

export default Row;
