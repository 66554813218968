import {
  ORGS_PRESENCE_SUCCESS,
  ORGS_PRESENCE_CLEAR,
} from 'app/actions/orgs/presence';


const defaultState = {
};

const presence = (state = defaultState, action) => {
  switch (action.type) {
    case ORGS_PRESENCE_SUCCESS: {
      return { ...state, ...action.payload };
    }

    case ORGS_PRESENCE_CLEAR: {
      return defaultState;
    }

    default:
  }
  return state;
};

export default presence;
