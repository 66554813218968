// @flow
import { useCallback, useState, useRef } from 'react';
import { find, propEq } from 'ramda';

import usePopupErr from 'app/common/hooks/usePopupErr';
import { PlanogramData, PlanogramItem, type RawDataItem, type IcommingContains } from 'app/common/ui-components/planograms';
import type { PlanogramContent } from 'app/dataTypes/vending/PlanogramContent.types';

import requestVersions from './requestVersions';
import requestPlanogramSlots from './requestPlanogramSlots';
import requestMachineParams from './requestMachineParams';
import requestPlanogramSlotsContent from './requestPlanogramSlotsContent';


export default function usePlanogramData<T: PlanogramData<PlanogramItem> = PlanogramData<PlanogramItem>>(
  machineId: ?string,
  createPlanogramData: (items?: Array<RawDataItem>) => T,
): {
  data: T,
  loading: boolean,
  setPlanogramData: ((T => T) | T) => void,
  setPlanogramSlotData: (PlanogramContent) => void,
  request: () => Promise<void>
} {
  const unsub = useRef(() => {});

  const [loading, setLoading] = useState(true);
  const [planogramData, setPlanogramData] = useState(createPlanogramData());
  const [popupErr] = usePopupErr();

  const updatePlanorgam = useCallback(() => {
    setPlanogramData((plngm) => {
      unsub.current();
      const newPlanogram = plngm.duplicate();
      newPlanogram.subscribe(updatePlanorgam);
      return newPlanogram;
    });
  }, []);

  const request = useCallback(async () => {
    if (!machineId) return;
    setLoading(true);

    const [versions, planogramRawData, machine, planogramContent] = await Promise.all([
      requestVersions(),
      requestPlanogramSlots(machineId),
      requestMachineParams(machineId),
      requestPlanogramSlotsContent(machineId),
    ]);

    if (versions && planogramRawData && machine && planogramContent) {
      const { version_id: versionId } = machine;
      const versionParams = find(propEq('id', String(versionId)), versions);
      if (versionParams) {
        const { slots } = versionParams;
        const planogram = createPlanogramData(planogramRawData);
        planogram.setNaming(slots);
        planogram.setContains(((planogramContent: any): Map<string, IcommingContains>));
        unsub.current = planogram.subscribe(updatePlanorgam);
        setPlanogramData(planogram);
        setLoading(false);
        return;
      }
      popupErr('Не удалось создать планограмму из полученных данных');
    }
    setLoading(false);
  }, [machineId, popupErr, createPlanogramData, updatePlanorgam]);

  const setPlanogramSlotData = useCallback((content: PlanogramContent) => {
    planogramData.setContains(((content: any): Map<string, IcommingContains>));
  }, [planogramData]);

  return {
    data: planogramData,
    loading,
    setPlanogramData,
    setPlanogramSlotData,
    request,
  };
}
