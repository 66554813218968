// @flow
import path from 'ramda/es/path';

import {
  getStateCurrentValue,
  getStateItems,
  type State,
  type PlainDatePeriodRestProps,
} from 'app/common/ui-components/filter';

import { getDisabledDaysFunc } from 'app/common/ui-next/plain-date-period';

import yearFilter from 'app/components/common/reports/filters/year';


const periodProps = (state: State): PlainDatePeriodRestProps => {
  const yearCurrentIds = getStateCurrentValue(yearFilter, state);
  const years = getStateItems(yearFilter, state);
  const selectedYearId = yearCurrentIds[0];

  const dateStart = path([selectedYearId, 'date_start'], years);
  const dateFinish = path([selectedYearId, 'date_finish'], years);

  const dateDisabledStatus = getDisabledDaysFunc(dateStart, dateFinish);

  return ({
    label: 'Период времени',
    buttonResetLabel: 'Сбросить выбор',
    fromLabel: 'от',
    toLabel: 'до',
    dateDisabledStatus,
    periodLimit: 1,
    periodLimitUnit: 'months',
  });
};

export default periodProps;
