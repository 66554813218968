// @flow
import moment from 'moment-timezone';

import styles from './columns.scss';


export const idsHeaderFirstRow: Array<string> = [
  'title',
  'presence',
  'sells',
  'debts',
  'links',
  'settings',
];

export const idsHeaderSecondRow: Array<string> = [
  'presencePersonal',
  'presenceComplex',
  'sellsPersonal',
  'sellsComplex',
];

export const idsRow: Array<string> = [
  'title',
  'presencePersonal',
  'presenceComplex',
  'sellsPersonal',
  'sellsComplex',
  'debts',
  'links',
  'settings',
];

export const idsEnterprise: Array<string> = [
  'title',
  'todaySalesSum',
  'monthSalesSum',
  'links',
  'settings',
];

export const propsHeader: any = {
  title: { alignLeft: true, rowSpan: 2 },
  presence: { alignCenter: true, colSpan: 2, rowSpan: 1, className: styles.restrictedWidth },
  presencePersonal: { alignCenter: true, className: styles.restrictedWidthHalf },
  presenceComplex: { alignCenter: true, className: styles.restrictedWidthHalf },
  sells: { alignCenter: true, colSpan: 2, rowSpan: 1, className: styles.restrictedWidth },
  sellsPersonal: { alignCenter: true, className: styles.restrictedWidthHalf },
  sellsComplex: { alignCenter: true, className: styles.restrictedWidthHalf },
  debts: { alignRight: true, rowSpan: 2, className: styles.restrictedWidth },
  links: { alignRight: true, rowSpan: 2 },
  settings: { alignRight: true, rowSpan: 2 },
};

export const props: any = {
  title: { alignLeft: true },
  presence: { alignLeft: true, className: styles.restrictedWidth },
  presencePersonal: { alignCenter: true, className: styles.restrictedWidthHalf },
  presenceComplex: { alignCenter: true, className: styles.restrictedWidthHalf },
  sells: { alignRight: true, className: styles.restrictedWidth },
  sellsPersonal: { alignCenter: true, className: styles.restrictedWidthHalf },
  sellsComplex: { alignCenter: true, className: styles.restrictedWidthHalf },
  debts: { alignRight: true, className: styles.restrictedWidth },
  links: { alignRight: true },
  settings: { alignRight: true },
};

export const propsEnterprise: any = {
  title: { alignLeft: true },
  sells: { alignRight: true, className: styles.restrictedWidth },
  todaySalesSum: { alignRight: true, className: styles.restrictedWidth },
  monthSalesSum: { alignRight: true, className: styles.restrictedWidth },
  debts: { alignRight: true, className: styles.restrictedWidth },
  presence: { alignLeft: true, className: styles.restrictedWidth },
  links: { alignRight: true },
  settings: { alignRight: true },
};

export const getTitles = (currency: string): { [string]: string } => ({
  title: 'Наименование',
  presence: 'Наличие меню на\u00A0сегодня\u00A0по',
  presencePersonal: 'буфету',
  presenceComplex: 'комплектам',
  sells: `Сумма продаж за\u00A0сегодня\u00A0по,\u00A0${currency}`,
  sellsPersonal: 'буфету',
  sellsComplex: 'комплексному питанию',
  todaySalesSum: `Сумма продаж за\u00A0${moment().format('DD.MM.YYYY')},\u00A0${currency}`,
  todaySalesSumPersonal: 'буфету',
  todaySalesSumComplex: 'комплексному питанию',
  monthSalesSum: `Сумма продаж за\u00A0${moment().format('MMMM').toLowerCase()},\u00A0${currency}`,
  debts: `Сумма долгов,\u00A0${currency}`,
  links: '\u00A0',
  settings: '',
});
