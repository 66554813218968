// @flow
import React, { useCallback, useState } from 'react';

import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';
import Loader from 'app/common/components/Loader';
import EmptyReport from 'app/components/Reports/common/EmptyReport';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';

import HeaderBar from './HeaderBar';
import Students from './Students';
import Classes from './Classes';
import Schools from './Schools';
import ClassesAndOrders from './ClassesAndOrders';
import Summary from './Summary';
import enhanceReport from './enhanceReport';


type Props = {
  loading: boolean,
  reportId: number,
}

const Report = ({ loading, reportId }: Props) => {
  const [fs, changeFullScreen] = useState(false);
  const toggleFS = useCallback(() => {
    changeFullScreen(fullscreen => !fullscreen);
  }, []);

  if (!reportId) {
    return (
      <EmptyReport
        headerBar={
          <HistoryLink reportType={reportTypes.REPORT_SUBSIDIES_DETAILED} />
        }
      />
    );
  }

  return (
    <ReportSectionCSS
      stickyNumber={1}
      header="Отчет «Дотации»"
      headerBar={
        <HeaderBar onFullscreen={toggleFS} />
      }
      fullscreen={fs}
      wideContent
      space
    >
      {loading
        ? <Loader />
        : <React.Fragment>
          {/* Общая иформация */}
          <Summary />
          {/* Далее таблицы с отчетами (каждая знает когда ей отображаться) */}
          <Students />
          <Classes />
          <Schools />
          <ClassesAndOrders />
        </React.Fragment>}
    </ReportSectionCSS>
  );
};

export default enhanceReport(Report);
