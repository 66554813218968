import { ForwardedRef, forwardRef, useCallback } from 'react'
import { isEmpty } from 'ramda'

import { OrgType } from 'app/dataTypes/org/types'
import { DotsLoader } from 'app/common/components/Loader'
import PlainNav, { NavItem } from 'app/common/ui/PlainNav'


const russian = {
  [OrgType.School]: 'ОУ',
  [OrgType.Preschool]: 'ДОУ',
  [OrgType.HighSchool]: 'ВУЗы',
  [OrgType.Enterprise]: 'Предприятия',
} as const

const translate = (orgType: OrgType): string => {
  const tp = russian[orgType]
  return tp || orgType
}

type Props = {
  className?: string
  orgTypes: Array<OrgType>
  loading?: boolean
  // hideSingle?: boolean
  linkPathBy: string | ((orgType: OrgType) => string)
}

const OrgTypeNavigation = forwardRef(
  (
    { className, orgTypes, loading, linkPathBy }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const empty = isEmpty(orgTypes)
    const single = orgTypes.length === 1

    const createLink = useCallback(
      (type: OrgType) => {
        return typeof linkPathBy === 'function'
          ? linkPathBy(type)
          : `${linkPathBy}/${type}`
      },
      [linkPathBy],
    )

    const showLoader = loading && empty
    const showContent = !loading && !empty && !single
    const showFiller = !loading && single

    return (
      <div className={className} ref={ref}>
        {showLoader && <DotsLoader simple />}

        {showContent && (
          <PlainNav>
            {orgTypes.map(type => (
              <NavItem key={type} to={createLink(type)}>
                {translate(type)}
              </NavItem>
            ))}
          </PlainNav>
        )}

        {showFiller && <span>&nbsp;</span>}
      </div>
    )
  },
)

export default OrgTypeNavigation
