import { DataBox, createDataBox } from 'app/common/data-box-store'


export type Period = Array<string>


class PeriodFilterDataBox extends DataBox<Period> { }

export const defaultPeriod: Period = []
export const periodFilter = createDataBox('periodFilter', defaultPeriod, PeriodFilterDataBox)
