import type { Request } from '../index';


export type CheckEmployeeRequest = {
  birthdate: string,
}

export type CheckEmployeeResult = {
  result: 'success',
}

export const GET_CHECK_EMPLOYEE_BIRTHDATE: Request<
  CheckEmployeeRequest,
  CheckEmployeeResult,
  void
> = [
  'food/check_employee_birthdate',
  'get',
  ['data'],
];
