// @flow
import React, { Fragment, type Node } from 'react';


type Props = {
  name: string,
  children: Node,
}

const CellPlace = ({ name, children }: Props): Node => {
  return (
    <Fragment>
      <div>{name}</div>
      {children}
    </Fragment>
  );
};

export default CellPlace;
