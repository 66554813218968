import api from 'app/actions/api';
import prop from 'ramda/es/prop';
import action from 'app/common/lib/action';


export const BALANCE_REQUEST = 'account/BALANCE_REQUEST';
export const BALANCE_SUCCESS = 'account/BALANCE_SUCCESS';
export const BALANCE_FAILURE = 'account/BALANCE_FAILURE';


export const fetchBalance = () => async (dispatch) => {
  dispatch(action(BALANCE_REQUEST));
  try {
    const { data } = await api('account/balance', 'get');
    const cafeteriaBalance = prop('cafeteria_balance', data);
    const primaryBalance = prop('primary_balance', data);
    const primaryBalanceHidden = prop('primary_balance_hidden', data);

    dispatch(action(BALANCE_SUCCESS, {
      primary: primaryBalance,
      cafeteria: cafeteriaBalance,
      primaryBalanceHidden,
    }));
  } catch (error) {
    dispatch(action(BALANCE_FAILURE, {
      err: 'Не удалось получить текущий баланс',
      error,
    }));
  }
};
