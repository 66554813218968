// @flow

type Props = {
  orgId?: string,
  section: string,
  date?: string,
}


const getBaseUrl = ({ orgId = ':orgId', section, date }: Props) => {
  if (date) {
    return `/orgs/${orgId}/${section}/${date}`;
  }
  return `/orgs/${orgId}/${section}`;
};

export default getBaseUrl;
