// @flow
import React from 'react';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import type { HandleModalDisplayed } from 'app/common/ui-next/plain-modal';
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider';


type Props = {
  toggleModal: HandleModalDisplayed,
  saving: boolean,
  disableSubmit: boolean,
}

const CopyCheckedControls = ({ toggleModal, saving, disableSubmit }: Props) => {
  const [{ loading }] = useOrgsData();

  return (
    <ControlsBar right>
      <PlainButton outline onClick={toggleModal}>
        {'Отмена'}
      </PlainButton>
      <PlainButton type="submit" disabled={disableSubmit || loading || saving}>
        {saving ? 'Копирование...' : 'Копировать'}
      </PlainButton>
    </ControlsBar>
  );
};

export default CopyCheckedControls;
