/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["elSurface"] }] */
import { useRef, useState, useCallback } from 'react'


export default function useCorrectPosition(contentPadding: number) {
  const buttonRef = useRef<HTMLElement | null>(null)
  const surfaceRef = useRef<HTMLElement | null>(null)
  const buttonCopyRef = useRef<HTMLElement | null>(null)
  const [appear, setAppear] = useState(false) // выход из opacity 0

  const handleSurfaceRef = useCallback((elSurface: HTMLElement | null) => {
    surfaceRef.current = elSurface
    const elOpenLink = buttonRef.current
    const elOpenLinkCopy = buttonCopyRef.current
    if (elSurface && elOpenLink) {
      const {
        top: openLinkTop,
        bottom: openLinkBottom,
        right: openLinkRight,
        left: openLinkLeft,
      } = elOpenLink ? elOpenLink.getBoundingClientRect() : {} as DOMRect

      const {
        left: surfaceLeft,
        top: surfaceTop,
        right: surfaceRight,
        bottom: surfaceBottom,
      } = elSurface ? elSurface.getBoundingClientRect() : {} as DOMRect

      const dx = 10
      const dy = 10
      const admission = 30 // допуск на сколько можно выйти за нижний край окна браузера

      const surfaceWidth = surfaceRight - surfaceLeft
      const surfaceHeight = surfaceBottom - surfaceTop

      if (document.documentElement) {
        // проверка, умещается ли в видимую область
        // -> по вертикали
        if (elSurface && openLinkTop + surfaceHeight - admission < document.documentElement.clientHeight) {
          // умещается
          elSurface.style.top = `${openLinkTop - dy}px`
          if (elOpenLinkCopy) {
            elOpenLinkCopy.style.top = `${-contentPadding + dy}px`
          }
        } else {
          // не умещается, выпадение вверх
          elSurface.style.top = `${openLinkBottom - surfaceHeight + dy}px`
          if (elOpenLinkCopy) {
            elOpenLinkCopy.style.bottom = `${-contentPadding + dy}px`
          }
        }

        // -> по горизонтали (слева)
        if (elSurface && openLinkRight + dx - surfaceWidth > 0) {
          // не умещается, выпадение влево
          elSurface.style.left = `${openLinkRight - surfaceWidth + dx}px`
          if (elOpenLinkCopy) {
            elOpenLinkCopy.style.right = `${-contentPadding + dx}px`
          }
        } else {
          // умещается
          elSurface.style.left = `${openLinkLeft - dx}px`
          if (elOpenLinkCopy) {
            elOpenLinkCopy.style.left = `${-contentPadding + dx}px`
          }
        }

        setAppear(true)
        return
      }
    }
    setAppear(false)
  }, [])

  return {
    buttonRef,
    surfaceRef: handleSurfaceRef,
    buttonCopyRef,
    classes: { appear },
  }
}
