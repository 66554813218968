// @flow
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import { Scrollbars } from 'react-custom-scrollbars-2';
import times from 'ramda/es/times';
import identity from 'ramda/es/identity';
import without from 'ramda/es/without';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import PlainDropdown, { PlainDropdownDefitem as DItem } from 'app/common/ui-next/plain-dropdown';

import { useImportXLSData, useDataTypes } from '../ImportXLSData';
import updateByCheckbox from './updateByCheckbox';
import styles from './ImportPreview.scss';


const cx = classNames.bind(styles);

/**
 *  Часть формы с предварительным просмотром данных и выбором заголовков
 */

const ImportPreview = () => {
  const { content: { previewData, options, dataTypes: currentSelected }, modify } = useImportXLSData();
  const numberOfColumns = previewData[0].length;

  const handleCheckboxChange = useCallback(updateByCheckbox(modify.optionUpdate), []);
  // const rows = options.headersInside ? previewData.slice(1) : previewData;
  const rows = previewData;

  const [dataTypes] = useDataTypes();

  const unused = useMemo(() => (
    without(currentSelected, dataTypes)
  ), [dataTypes, currentSelected]);

  const makeHandleSelectDropdownItem = useCallback((index: number) => ({ id }: { id: number | string }) => {
    modify.setDataTypeByPosition(index, id === -1 ? null : String(id));
  }, [modify]);


  const dropdown = (index: number) => (
    <PlainDropdown
      defaultSkin
      id={`header_${index}`}
      label="Выберите заголовок колонки для данных"
      labelClassName="visuallyHidden"
      onChange={makeHandleSelectDropdownItem(index)}
      placeholder={
        currentSelected[index]
          ? <DItem id={index}>{currentSelected[index]}</DItem>
          : <DItem id={-1}>{'Не используется'}</DItem>
      }
    >
      {currentSelected[index]
        && <DItem id={-1}>{'Не используется'}</DItem>}
      {unused.map(title => (
        <DItem key={title} id={title}>{title}</DItem>
      ))}
    </PlainDropdown>
  );


  return (
    <article className={styles.root}>
      <div className={styles.headerBlock}>
        <h3 className={styles.header}>
          {'Предпросмотр файла'}
        </h3>
        <PlainCheckbox
          label="Файл содержит заголовки"
          value="headersInside"
          checked={options.headersInside}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className={styles.headerComment}>{'Какие данные находятся в столбцах?'}</div>

      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={10000}
        style={{ width: '100%' }}
      >
        <div className={styles.content}>
          <table>
            <thead>
              <tr>
                {times(identity, numberOfColumns).map(index => (
                  <th key={index}>{dropdown(index)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr key={i} className={cx({ highlight: !i && options.headersInside })}>
                  {row.map((title, j) => (
                    <td key={j}>{String(title) || ''}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Scrollbars>
    </article>
  );
};

export default ImportPreview;
