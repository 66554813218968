// @flow
import type { Request } from '../../index';


export type ImportFileResult = {
  result: ?'success',
  file_id: number,
  preview_data: Array<Array<any>>
}

export const defaultResult: ImportFileResult = {
  result: null,
  file_id: 0,
  preview_data: [],
};

export const POST_LOAD_IMPORT_FILE: Request<
  FormData/* <{ import_file: File }> */,
  ImportFileResult
> = [
  'food/items/load_import_file',
  'post',
  ['data'],
];
