// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import Sticky, { withStickyRows } from 'app/common/components/Sticky';

import styles from './ReportSection.scss';


const cx = classNames.bind(styles);

type Props = {
  children: any,
  header: string,
  headerBar: React.Node,
  stickyNumber?: number,
  reference?: (el: React.ElementRef<'div'> | null) => void,
  top?: boolean,
  noSpace?: boolean,
  wideContent?: boolean,
  paddingFree?: boolean,
  fullscreen?: boolean,
};

const ReportSection = withStickyRows(({
  children,
  header,
  headerBar,
  stickyNumber,
  reference,
  top,
  noSpace,
  wideContent,
  paddingFree,
  fullscreen,
}: Props) => (
  <div className={cx(styles.root, { fullscreen, top, noSpace })}>
    {(header || headerBar)
      && <Sticky
        number={stickyNumber}
        disabled={!stickyNumber}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
        trackState
      >
        <div ref={reference} className={styles.headerBlock}>
          <h2>{header}</h2>
          <div>{headerBar}</div>
        </div>
      </Sticky>}
    <div className={cx(styles.content, { wideContent, paddingFree })}>
      {children}
    </div>
  </div>
));

export default ReportSection;
