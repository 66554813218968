// @flow
import type { Request } from '../index';

const XLSX: 'xlsx' = 'xlsx';
const ZIP: 'zip' = 'zip';
export const formats = { XLSX, ZIP };

type GenerateFileParams = {
  report_id: number,
  format: $Values<typeof formats>,
}

type GenerateFileResult = number

export const GET_GENERATE_FILE: Request<
  GenerateFileParams,
  GenerateFileResult,
  void
> = [
  'reports/generate_file',
  'get',
  ['data', 'report_file_id'],
];
