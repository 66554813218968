// @flow

export type passValidType = {
  lowercase: boolean,
  uppercase: boolean,
  numbers: boolean,
  amount: boolean,
};

export const validatePassword = (pass: string): passValidType => ({
  lowercase: /[a-z]/.test(pass),
  uppercase: /[A-Z]/.test(pass),
  numbers: /\d/.test(pass),
  amount: pass.length >= 8,
});

export const isValid = (pass: string | passValidType): boolean => {
  const res: passValidType = (typeof pass === 'object') ? pass : validatePassword(pass);
  return Object.keys(res).every(key => !!res[key]);
};
