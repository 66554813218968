import { MailID, ID } from '../predefined-ids'


export default function canMailingTo(formData: FormData): string | null {
  const email = formData.get(ID.email) as (string | null)
  const mailing = formData.get(ID.mailing) as (MailID | null)
  const hasEmailOption = email && (mailing === MailID.MailAndView || mailing === MailID.MailOnly)

  return hasEmailOption ? email : null
}
