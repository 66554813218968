import { createSelector } from 'reselect';
import last from 'ramda/es/last';


const selectErrors = createSelector(
  [
    state => state.errors,
  ],
  (errors) => {
    const lastError = last(errors);

    return ({
      isDisconnected: Boolean(lastError && !lastError.response),
      isMaintenance: Boolean(lastError && lastError.response && lastError.response.status === 503), // не пашет
      isForbidden: Boolean(lastError && lastError.response && lastError.response.status === 403),
      isUnauthorized: Boolean(lastError && lastError.response && lastError.response.status === 401),
    });
  },
);

export default selectErrors;
