// @flow
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Checkbox from 'app/common/ui/Checkbox';

import container from './container';
import Warning from '../Warning';
import Text from './Text';
import styles from './Agreement.scss';
import type { LoginInfo } from '../container';


type Props = {
  handleBack: (e: SyntheticEvent<HTMLElement>) => void,
  handleCheck: (e: SyntheticEvent<HTMLElement>) => void,
  handleAccept: (e: SyntheticEvent<HTMLElement>) => void,
  handleRead: (e: SyntheticEvent<HTMLElement>) => void,
  handleHide: (e: SyntheticEvent<HTMLElement>) => void,
  accepted: boolean,
  agreementText: boolean,
  loginInfo: LoginInfo,
};

const Agreement = ({
  handleBack,
  handleCheck,
  handleAccept,
  handleRead,
  handleHide,
  accepted,
  agreementText,
  loginInfo,
}: Props) => {
  const { t } = useTranslation();

  return (
    agreementText
      ? <Text url={loginInfo.agreementUrl} handleHide={handleHide} />
      : <Warning
        onBack={handleBack}
        onAccept={handleAccept}
        header={t('login:agreement_necessity')}
        text={t('login:agreement_read_and_accept')}
        disabled={!accepted}
      >
        <Checkbox
          value="agreement_accept"
          handleChange={handleCheck}
          checked={accepted}
          label={
            <Trans i18nKey="login:agreement_accept">
              {'Я принимаю условия '}
              <a href="terms" className={styles.link} onClick={handleRead}>
                {'пользовательского соглашения'}
              </a>
            </Trans>
          }
        />
      </Warning>
  );
};

export default container(Agreement);
