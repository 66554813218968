import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import nest from 'recompose/nest';
import mapProps from 'recompose/mapProps';
import compose from 'recompose/compose';

import omitProps from 'app/common/containers/omitProps';
import LineInputOriginal from 'app/common/login/ui/LineInput';
import LineDateInput from './LineDateInput';
import Loading from './Loading';


const updateProps = mapProps(({ loading, disabled, value, placeholder, ...props }) => ({
  ...props,
  disabled: disabled || loading,
  value: value && loading ? '' : value,
  placeholder: placeholder && loading ? '' : placeholder,
}));

const enhance = compose(
  branch(
    ({ loading }) => loading,
    renderComponent(updateProps(nest(Loading, LineInputOriginal))),
  ),
  omitProps(['loading']),
);

const LineInput = enhance(LineInputOriginal);

export default LineInput;
export { LineDateInput };
