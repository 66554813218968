const defaultState = {
  logo_url: '',
  logo_w_url: '',
  company: '',
  loading: false,
  project_name: 'Школьная карта',
  country_iso_code: 'RU',
  email_force_auth: false,
  e_money_enabled: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
  /*
    *  PAYLOAD: undefined
    */
    case 'DEALER_REQUEST': {
      return { ...state, loading: true };
    }

    /*
    *  PAYLOAD: {logo_url, logo_w_url, company}
    */
    case 'DEALER_SUCCESS': {
      return { ...state, loading: false, ...action.payload };
    }

    /*
    *  PAYLOAD: PAYLOAD: {logo_url, logo_w_url, company}
    */
    case 'DEALER_FAILURE': {
      return { ...state, loading: false, ...action.payload };
    }

    default:
      return state;
  }
}
