// @flow
import type { Request } from '../../index';


export type GetEmployeesRequest = {
  search?: string,
  id?: number,
  limit?: number,
  offset?: number,
  role_id?: number,
  school_id?: number,
}

export type Employee = {
  id: number,
  name: {
    last: ?string,
    first: ?string,
    middle: ?string,
  },
  photo: ?string,
  birthdate: ?string,
  phone: ?string,
  email: ?string,
  card: ?string,
  invite_date: ?string,
  gender: 'male' | 'female' | null,
  role_id: ?number,
  role_title: ?string,
  school_id: ?number,
  school_title: ?string,
}

export type Employees = {
  [id: string]: Employee,
}

export type GetEmployeesResult = {
  result: 'success',
  employees: Employees,
  count: number,
}

export const GET_EMPLOYEES: Request<
  GetEmployeesRequest,
  GetEmployeesResult,
  void
> = [
  'food/employees',
  'get',
  ['data'],
];
