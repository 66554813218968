// @flow
import { useMemo } from 'react';


export default function useFancyRowExpandedStyle(color: string): {} {
  const res = useMemo(() => {
    return {
      '--fancy-color': color,
    };
  }, [color]);

  return res;
}
