import { ReactNode, Children, cloneElement } from 'react'
import cn from 'classnames'
import { path } from 'ramda'

import { AvailableDisplayName, isAvailableDisplayName } from './available-display-name'
import type { HandleModalDisplayed } from './use-modal-displayed'
import styles from './plain-modal-container.scss'


type Props = {
  children: ReactNode
  className?: string
  handleModalDisplayed?: HandleModalDisplayed
}

const PlainModalContainer = ({ children, className, handleModalDisplayed }: Props): ReactNode => {
  return (
    <div className={cn(styles.root, className)}>
      {Children.map(children, (Component) => {
        if (!Component) {
          return null
        }
        const displayName = path(['type', 'displayName'], Component)
        if (isAvailableDisplayName(displayName) && typeof Component === 'object' && 'props' in Component) {
          const { props } = Component
          if (path(['unscrollable'], props)) {
            return cloneElement(Component, { ...props, handleModalDisplayed })
          }
          return (
            <div>
              {/* ^^^ div - because Scrollbars */}
              {cloneElement(Component, { ...props, handleModalDisplayed })}
            </div>
          )
        }
        console.log('%c👻 Неверный child для PlainModalContainer', 'color: LawnGreen')
        return null
      })}
    </div>
  )
}

PlainModalContainer.displayName = AvailableDisplayName.PlainModalContainer

export default PlainModalContainer
