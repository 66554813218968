import api from 'app/actions/api';

export const MENU_COPYALL_REQUEST = 'orgs/menu/MENU_COPYALL_REQUEST';
export const MENU_COPYALL_SUCCESS = 'orgs/menu/MENU_COPYALL_SUCCESS';
export const MENU_COPYALL_FAILURE = 'orgs/menu/MENU_COPYALL_FAILURE';

export const dateFormat = 'YYYY-MM-DD';

const menuCopyallRequest = payload => ({
  type: MENU_COPYALL_REQUEST,
  payload,
});

const menuCopyallSuccess = payload => ({
  type: MENU_COPYALL_SUCCESS,
  payload,
});

const menuCopyallFailure = payload => ({
  type: MENU_COPYALL_FAILURE,
  payload,
});

export const copyAllMenu = (
  from: Object,
  to: Object,
  isItems: boolean,
  isKits: boolean,
  copySettings: boolean,
  copyKitsMenuOnly: boolean,
) => async (dispatch) => {
  dispatch(menuCopyallRequest({ from, to, isItems, isKits, copySettings, copyKitsMenuOnly }));
  try {
    const dataToSend = {
      school_id_from: from.orgId,
      school_id_to: to.orgId,
      date_from: from.date,
      date_to: to.date,
      items: isItems,
      complexes: isKits,
      copy_settings: copySettings,
      ...(isKits ? { only_added_to_menu: copyKitsMenuOnly } : {}),
    };
    await api('food/menu/copy_menu_between_schools', 'post', { data: dataToSend });
    dispatch(menuCopyallSuccess({ from, to, isItems, isKits }));
    return true;
  } catch (error) {
    dispatch(menuCopyallFailure({ err: 'Не удалось скопировать меню', error }));
    return false;
  }
};
