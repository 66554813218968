import { createSelector } from 'reselect';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import sortBy from 'ramda/es/sortBy';
import prop from 'ramda/es/prop';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';
import trim from 'ramda/es/trim';


const selectDishes = createSelector(
  [
    state => state.orgs.dishes.items,
    (_, { categoryList }) => categoryList,
  ],
  (items, categoryList) => pipe(
    values,
    map(({
      id,
      title,
      photoUrl,
      restrictions,
      portion,
      cost,
      saleType, //  идентификатор типа блюда
      category_id: categoryId,
    }) => ({
      id,
      title: trim(title || '???'),
      photo: photoUrl || '',
      restrictions: restrictions || '',
      portion: portion || '',
      cost: parseFloat(cost) || '',
      saleType,
      category: trim(prop('title', find(propEq('id', String(categoryId)), categoryList)) || ''),
    })),
    sortBy(prop('title')),
  )(items),
);

export default selectDishes;
