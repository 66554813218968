
import { ChangeEvent, ReactNode, useCallback, useEffect } from 'react'
import { isEmpty } from 'ramda'

import PlainFieldset from 'app/common/ui-next/plain-fieldset'
import PlainRadioButton from 'app/common/ui-next/plain-radio-button'
import PlainLabel from 'app/common/ui-next/plain-label'
import PlainButton from 'app/common/ui-next/plain-button'

import CopySettingsContainer from '../../common/CopySettingsContainer'
import type { CampSubsidyOrdersState, ChangePresetAction } from './useCampSubsidyOrders'
import type { Errors } from './useCampSubsidyOrdersErrors'
import { PresetValue } from './PresetValue'

import styles from './CampSubsidyOrders.scss'


const header = 'Размер возмещения дотаций для индивидуальных справок для питания в пришкольном лагере'
const title1 = 'Свободное значение'
const title2 = 'Заданное значение'
const presetsLabel = 'Укажите заданные значения в процентах'
const addPreset = 'Добавить зачение'

type Props = {
  children: ReactNode
  disabled?: boolean
  campSubsidyOrders: CampSubsidyOrdersState
  errors: Errors
  hasTopMargin?: boolean
  onChangeUsingOfPresets: (presetsUsing: boolean) => void
  onChangePresets: (action: ChangePresetAction) => void
}

const CampSubsidyOrders = ({
  children,
  disabled,
  campSubsidyOrders,
  errors,
  hasTopMargin,
  onChangeUsingOfPresets,
  onChangePresets,
}: Props) => {
  const presetsValues = campSubsidyOrders.camp_subsidy_orders_preset_values
  const enjoyPresets = campSubsidyOrders.camp_subsidy_orders_use_presets
  const hasPresets = !!presetsValues && !isEmpty(presetsValues)

  const handleUsePresets = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget
    if (checked) {
      onChangeUsingOfPresets(value === 'use_presets')
    }
  }, [onChangeUsingOfPresets])

  const handleAddPreset = useCallback(() => {
    onChangePresets({ type: 'add' })
  }, [onChangePresets])

  const handleRemovePreset = useCallback((index: number) => {
    onChangePresets({ type: 'remove', index })
  }, [onChangePresets])

  const handleChangePreset = useCallback((index: number, value: number) => {
    onChangePresets({ type: 'edit', index, value })
  }, [onChangePresets])

  useEffect(() => {
    if (enjoyPresets && !hasPresets) {
      onChangePresets({ type: 'add' })
    }
  }, [hasPresets, enjoyPresets]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CopySettingsContainer hasTopMargin={hasTopMargin}>
      <div>
        <PlainFieldset
          legend={header}
          name="campSubsidy"
          skipUpperSpace
        >
          <PlainRadioButton
            label={title1}
            className={styles.radio}
            disabled={disabled}
            name="camp_subsidy"
            value="use_free"
            checked={!enjoyPresets}
            onChange={handleUsePresets}
          />
          <PlainRadioButton
            label={title2}
            className={styles.radio}
            disabled={disabled}
            name="camp_subsidy"
            value="use_presets"
            checked={enjoyPresets}
            onChange={handleUsePresets}
          />
        </PlainFieldset>

        {hasPresets
          && <PlainLabel id="presets" disabled={disabled || !enjoyPresets}>
            {presetsLabel}
            {presetsValues.map((value, index) => (
              <PresetValue
                key={index}
                className={styles.presetItem}
                disabled={disabled || !enjoyPresets}
                index={index}
                value={value}
                error={errors[String(index)]}
                onDelete={handleRemovePreset}
                onChange={handleChangePreset}
                showDeleteButton={presetsValues.length > 1}
              >
                {index === presetsValues.length - 1
                  && <PlainButton
                    className={styles.addPreset}
                    onClick={handleAddPreset}
                    disabled={disabled || !enjoyPresets}
                    compact
                  >
                    <span className={styles.addPresetIcon}>{'+'}</span>
                    &nbsp;
                    {addPreset}
                  </PlainButton>}
              </PresetValue>
            ))}
          </PlainLabel>}


      </div>

      {children}
    </CopySettingsContainer>
  )
}

export default CampSubsidyOrders
