// @flow
import React from 'react';
import classNames from 'classnames/bind';

import { Table, Row, Cell, HeaderCell, HeaderRow } from 'app/common/components/Table';
import getNumEnding from 'app/common/lib/getNumEnding';
import IconRecovery from 'app/common/icons/IconRecovery';
import IconCheck from 'app/common/icons/IconCheck';
import Tooltip from 'app/common/ui/Tooltip';
import OveflowedText from 'app/common/components/OveflowedText';

import styles from './availability.scss';


const cx = classNames.bind(styles);

export type AvailabilityType = {
  id: string,
  title: string,
  price: number,
  delay: number,
  enough: boolean,
}

type Props = {
  types: Array<AvailabilityType>,
}

const Availability = ({ types }: Props) => (
  <div>
    <Table>
      <HeaderRow>
        <HeaderCell grow={2} className={styles.price}>{'Стоимость'}</HeaderCell>
        <HeaderCell grow={6} className={styles.name}><span>{'Наименование'}</span></HeaderCell>
        <HeaderCell grow={4} className={styles.delay}>{'Отсрочка'}</HeaderCell>
      </HeaderRow>
      {types.map(({ id, title, price, delay, enough }) => {
        const days = (delay
          ? `${delay} ${getNumEnding(delay, ['день', 'дня', 'дней'])}`
          : 'Отсутствует');
        const tip1 = enough ? 'Достаточно' : 'Недостаточно';
        const tip2 = delay ? 'Отсрочка' : 'Нет отсрочки';
        return (
          <Row key={id}>
            <Cell grow={2} className={styles.cell} alignRight>
              <span>{`${price} ₽`}</span>
            </Cell>
            <Cell grow={6} className={styles.cell}>
              <Tooltip text={tip1}>
                <IconCheck className={cx(styles.icon, { activeCheck: enough })} />
              </Tooltip>
              <OveflowedText>
                <span>{title}</span>
              </OveflowedText>
            </Cell>
            <Cell grow={4} className={styles.cell}>
              <Tooltip text={tip2}>
                <IconRecovery className={cx(styles.icon, { activeDelay: !!delay })} />
              </Tooltip>
              <span>{days}</span>
            </Cell>
          </Row>
        );
      })}
    </Table>
  </div>
);

export default Availability;
