// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';


export const EMPLOYEE_IDENTIFIERS_REQUEST = 'EMPLOYEE_IDENTIFIERS_REQUEST';
export const EMPLOYEE_IDENTIFIERS_SUCCESS = 'EMPLOYEE_IDENTIFIERS_SUCCESS';
export const EMPLOYEE_IDENTIFIERS_FAILURE = 'EMPLOYEE_IDENTIFIERS_FAILURE';

const employeeIdentifiersRequest = payload => ({
  type: EMPLOYEE_IDENTIFIERS_REQUEST,
  payload,
});

const employeeIdentifiersSuccess = payload => ({
  type: EMPLOYEE_IDENTIFIERS_SUCCESS,
  payload,
});

const employeeIdentifiersFailure = payload => ({
  type: EMPLOYEE_IDENTIFIERS_FAILURE,
  payload,
});

export const employeeIdentifiersFetch = (id: number): Object => async (dispatch) => {
  dispatch(employeeIdentifiersRequest(id));
  try {
    const res = await api(`food/employee/${id}/cards`, 'get');
    const cards = get(res, ['data', 'cards'], {});
    dispatch(employeeIdentifiersSuccess(cards));
    return cards;
  } catch (error) {
    const errorCode = get(error, ['response', 'status'], 400);
    if (errorCode === 404) {
      dispatch(employeeIdentifiersFailure({ err: 'Сотрудник не найден', error }));
      return null;
    }
    dispatch(employeeIdentifiersFailure({ err: 'Запрос идентификаторов сотрудника не удался', error }));
    return null;
  }
};
