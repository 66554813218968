// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as org } from 'app/components/common/reports/filters/org';

import { flags as period } from './period';


const classActivity = (state: State) => {
  return (
    org.singleOrgSelected(state)
    && !(
      period.finalDateInPreviosMonthOrBefore(state)
    )
  );
};

export default classActivity;
