// @flow
import React, { type Node } from 'react';

import RowLayout from 'app/common/layouts/RowLayout';

import styles from './ContentWithCategoriesTemplate.scss';


type Props = {
  children: Node,
  sideTop?: Node,
  sideMain: Node,
  sideBottom?: Node,
}

const StatementsLayout = ({ children, sideTop, sideMain, sideBottom }: Props): Node => {
  return (
    <div className={styles.root}>
      <div className={styles.side}>
        {sideTop
          && <div className={styles.sideTop}>
            {sideTop}
          </div>}

        <RowLayout className={styles.sideMain} paddingfree>
          {sideMain}
        </RowLayout>

        {sideBottom
          && <div className={styles.sideBottom}>
            {sideBottom}
          </div>}
      </div>
      <div className={styles.main}>

        <RowLayout className={styles.mainContent} paddingfree>
          {children}
        </RowLayout>

      </div>
    </div>
  );
};

export default StatementsLayout;
