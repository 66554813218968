// @flow
import { useContext } from 'react';
import RequestsContext, { type RequestsContextType } from './RequestsContext';


const useRequestsData = (): RequestsContextType => useContext(RequestsContext);

export const useGenertalSettings = () => {
  const { content: { generalSettings } } = useContext(RequestsContext);
  return generalSettings;
};

export default useRequestsData;
