// @flow
import { useCallback, useRef, useEffect, type RefCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import forEach from 'ramda/es/forEach';
import isEmpty from 'ramda/es/isEmpty';

import { extractIndex } from './helpers';


/**
 *  Нужен, пока не будет работать position: sticky в таблицах для всех современных браузеров
 *  Hook, копирующий ширину столбцов таблицы на другую таблицу или (таблицы)
 *  Источник один, целей может быть несколько
 *  Используется для таблиц с отделенным заголовком для прилипания
 *
 *  Пример:
 *    const [refFrom, refTo] = useColumnsWidthsTo();
 *    ...
 *    <Table>
 *      <Tr header reference={refTo}>
 *        <Th>...
 *    ...
 *    <Table>
 *      <Tr header hidden reference={refFrom}>
 *        <Th class="index_N"> ... </Th>  // индекс нужен так как данные иногда приходят в случ. порядке
 *     или
 *        <Th> ... </Th>                  // но можно использовать updateKey (при его изменении все размеры обновятся рaзом)
 *    ...
 */

type UserOptionsType = {
  useClassIndexes?: boolean,
  compensation?: Array<number>
}

const defaultOptions = {
  useClassIndexes: false, // установить, если нужно использовать <Th class="index_N">
  compensation: [], // компенсация размеров
};

export default function useCopyColumnsWidths(
  updateKey: any = null,
  userOptions?: UserOptionsType = defaultOptions,
): [RefCallback<'tr'>, RefCallback<'tr'>] {
  const refFrom = useRef<HTMLElement | null>(null);
  const refTo = useRef<Array<HTMLElement>>([]);
  const options = { ...defaultOptions, ...userOptions };

  // работа обсервера
  const handleResize = useCallback((entries) => {
    forEach((parentNodeTarget) => {
      // const parentNodeTarget = refTo.current;
      // if (!parentNodeTarget) return;
      const headerTargetCells = parentNodeTarget.children;
      const compensateBorder = [...(options.compensation || [])];
      entries.forEach(({ contentRect, target: source }, index) => {
        const { width } = contentRect;

        const userIndex = options.useClassIndexes ? extractIndex(source.className) : null;
        const targetCell = userIndex === null ? headerTargetCells[index] : headerTargetCells[userIndex];
        if (!targetCell) return;

        const targetStyle = window.getComputedStyle(targetCell, null);
        const padding = (parseInt(targetStyle.getPropertyValue('padding-left'), 10)
          + parseInt(targetStyle.getPropertyValue('padding-right'), 10));
        const borderRight = parseInt(targetStyle.getPropertyValue('border-right-width'), 10);
        compensateBorder[index + 1] = borderRight;
        targetCell.style.width = `${Math.round(width) + padding + (compensateBorder[index] || 0)}px`;
      });
    }, refTo.current);
  }, [options.useClassIndexes, options.compensation]);


  const ro = useRef(new ResizeObserver(handleResize));


  // подключение обсервера
  const refFromFunc = useCallback((node: ?HTMLElement) => {
    if (!node) return;
    refFrom.current = node;
    const sourceCells = node.children;

    for (let i = 0, l = sourceCells.length; i < l; i++) {
      ro.current.observe(sourceCells[i]);
    }
  }, []);


  // принудительное обновление от внешнего ключа (например при смене видимости колонок)
  useEffect(() => {
    const sourceNodeTarget = refFrom.current;
    if (!sourceNodeTarget || isEmpty(refTo.current)) return;

    // отключаем обсервер
    ro.current.disconnect();

    forEach((parentNodeTarget) => {
      // const parentNodeTarget = refTo.current;
      const headerTargetCells = parentNodeTarget.children;
      // убираем назначенные стили ширины
      for (let i = 0, l = headerTargetCells.length; i < l; i++) {
        headerTargetCells[i].style.width = '';
      }
    }, refTo.current);

    // включаем обсервер снова
    refFromFunc(sourceNodeTarget);
  }, [updateKey, refFromFunc]);


  // для инициализации массивом target'ов
  const refToFunc = useCallback((elem: HTMLElement | null) => {
    if (elem) {
      refTo.current.push(elem);
    }
  }, []);


  return [refFromFunc, refToFunc];
}
