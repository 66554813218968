import { ReactNode, useEffect } from 'react'

import { isFinanceReport } from 'app/dataTypes/reports/finances'

import useReportsEnabled from './useReportsEnabled'


type Props = {
  children: ReactNode
  report: string
  fallback?: ReactNode
  onReportDisabled?: () => void
}

const IsReportEnabled = ({ children, report, fallback, onReportDisabled }: Props) => {
  const [enabledReports, loading] = useReportsEnabled()
  const enabled = isFinanceReport(report) && enabledReports.includes(report)

  useEffect(() => {
    if (onReportDisabled && !loading && !enabled) {
      onReportDisabled()
    }
  }, [loading, enabled]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading && fallback) {
    return (fallback)
  }

  if (loading || !enabled) {
    return null
  }

  return (children)
}

export default IsReportEnabled
