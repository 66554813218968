

import HistoryModal from 'app/common/ui-components/history-modal'
import { useCorrectionsDetails } from 'app/dataProviders/reports/correctionsDetails/hooks'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import { useState } from 'react'
import PlainPaging from 'app/common/ui-next/plain-paging'
import usePagingExtended from 'app/common/hooks/usePagingExtended'
import styles from './LinkWithDetails.scss'


type Props = {
  date: string;
  schoolID: string;
  toggleButtonTitle?: string;
  linkClassName?: string
}


const columnIdsList = ['datetime', 'type', 'sum', 'purchaseDatetime', 'action', 'itemTitle']
const columnNamesList = ['Дата и время', 'Тип операции', 'Сумма', 'Дата и время продажи', 'Действие', 'Продукция']
const alignLeft = { alignLeft: true }
const alignCenter = { alignCenter: true }
const alignRight = { alignRight: true }
const columnPropsList = [alignLeft, alignCenter, alignCenter, alignCenter, alignCenter, alignRight]

export const LinkWithDetailsSubsidies = ({ date, schoolID, toggleButtonTitle, linkClassName }: Props) => {
  const perPage = 10

  const {
    currentPage,
    pageSize,
    onPage,
  } = usePagingExtended(perPage)

  const [total, setTotalCount] = useState<number>(0)

  const {
    correctionsDetails,
    requestDetails,
    loading,
  } = useCorrectionsDetails({
    date,
    schoolID,
    operationType: 'subsidies',
    currentPage,
    perPage,
    setTotalCount,
  })


  return (
    <HistoryModal
      title="Детализация корректировок"
      columnIdsList={columnIdsList}
      columnNamesList={columnNamesList}
      columnPropsList={columnPropsList}
      itemsList={correctionsDetails}
      loading={loading}
      className={styles.root}
      emptyWarnMessage="Отсутствуют записи с корректировками"
      footerContent={
        correctionsDetails && <PlainPaging
          total={total}
          perPage={perPage}
          currentPageIndex={currentPage - 1}
          currentPageSize={pageSize}
          onPage={onPage}
        />
      }
      onRequestHistory={requestDetails}
      renderCellContent={(columnId, item) => {
        return <div className={styles.limitedCell}>{item[columnId]}</div>
      }}
    >
      {toggle => (
        <PlainButtonAsLink onClick={toggle} linkClassName={linkClassName}>
          {toggleButtonTitle}
        </PlainButtonAsLink>
      )}
    </HistoryModal>
  )
}
