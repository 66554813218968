import { RenderReportIndicator } from 'app/common/templates-next/report-template'
import { useReportCheckStatus, useReportCancel } from 'app/dataProviders/reports/hooks'


type Props = {
  reportID: string
  onFail?: () => void
  onSuccess?: () => void
}

export const ReportStatusIndicator = ({ reportID, onFail, onSuccess }: Props) => {
  const checkStatus = useReportCheckStatus(reportID)
  const reportCancel = useReportCancel(reportID)

  return (
    <RenderReportIndicator
      reportID={reportID}
      onCancel={onFail}
      onCheckStatus={checkStatus}
      onStopProgress={reportCancel}
      onSuccessProgress={onSuccess}
    />
  )
}
