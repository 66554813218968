import api, { apiCancelable } from 'app/actions/api';
import get from 'app/common/lib/get';


/**
* Запрос сотрудников
*/

export const EMPLOYEES_REQUEST = 'EMPLOYEES_REQUEST';
export const EMPLOYEES_SUCCESS = 'EMPLOYEES_SUCCESS';
export const EMPLOYEES_SUCCESS_ONE = 'EMPLOYEES_SUCCESS_ONE';
export const EMPLOYEES_FAILURE = 'EMPLOYEES_FAILURE';
export const EMPLOYEES_CLEAR = 'EMPLOYEES_CLEAR';

export const employeesRequest = payload => ({
  type: EMPLOYEES_REQUEST,
  payload,
});

const employeesSuccess = payload => ({
  type: EMPLOYEES_SUCCESS,
  payload,
});

const employeesSuccessOne = payload => ({
  type: EMPLOYEES_SUCCESS_ONE,
  payload,
});

const employeesFailure = payload => ({
  type: EMPLOYEES_FAILURE,
  payload,
});

export const employeesClear = () => ({
  type: EMPLOYEES_CLEAR,
});

type Params = {
  type?: string,
  search?: string,
  id?: number,
  limit?: number,
  offset?: number,
};

export const employeesFetch = (params: Params) => async (dispatch) => {
  dispatch(employeesRequest());
  try {
    const res = await api('food/employees', 'get', { params });
    dispatch(employeesSuccess(res.data));
  } catch (error) {
    dispatch(employeesFailure({ err: 'Запрос данных сотрудников не удался', error }));
  }
};

export const EMPLOYEES_CANCEL = 'EMPLOYEES_CANCEL';

const employeesCancel = payload => ({
  type: EMPLOYEES_CANCEL,
  payload,
});

export const employeesFetchSearch = (params: Params) => async (dispatch) => {
  dispatch(employeesRequest());
  try {
    const res = await apiCancelable.request('food/employees', 'get', { params });
    dispatch(employeesSuccess(res.data));
  } catch (error) {
    dispatch(employeesCancel());
  }
};

export const oneEmployeeFetch = (id, isSilent) => async (dispatch) => {
  if (!isSilent) dispatch(employeesRequest());
  try {
    const res = await api('food/employees', 'get', { params: { id } });
    const item = get(res, ['data', 'employees', id], {});
    if (!isSilent) dispatch(employeesSuccessOne(item));
    return item;
  } catch (error) {
    dispatch(employeesFailure({ err: 'Запрос данных сотрудника не удался', error }));
    return false;
  }
};
