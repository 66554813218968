import cn from 'classnames/bind'

import PlainInput, { PlainInputProps } from './plain-input'

import styles from './plain-input-number.scss'


const cx = cn.bind(styles)

type Props = {
  hideArrows?: boolean
} & PlainInputProps

const PlainInputNumber = ({ hideArrows, inputClassName, ...others }: Props) => {
  return (
    <PlainInput
      type="number"
      inputClassName={cx(inputClassName, { hideArrows })}
      inputmode="numeric"
      {...others}
    />
  )
}

export default PlainInputNumber
