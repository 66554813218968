import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import { withTranslation } from 'react-i18next';

import Warning from '../Warning';


const LoginExist = compose(
  withTranslation(),

  mapProps(({ handleReturn, handleRestore, watEntered, t, ...props }) => {
    const wat = watEntered.replace('номер', 'номером');
    const headerLocal = t('login:register_email_or_phone_exist_title', { wat });

    return ({
      ...props,
      onBack: handleReturn,
      onAccept: handleRestore,
      header: headerLocal,
      text: t('login:register_email_or_phone_exist_try'),
      acceptBtText: t('login:register_email_or_phone_exist_to_restore_pass_bt'),
    });
  }),
)(Warning);

export default LoginExist;
