// @flow
import { useCallback, useEffect, useState, useMemo } from 'react';
import map from 'ramda/es/map';
import omit from 'ramda/es/omit';

import { api } from 'app/api';
import usePopupErr from 'app/common/hooks/usePopupErr';
import { GET_COMPLEXES } from 'app/common/api/requests/food/schools/complexes';


type Options = {
  orgId: string | number,
  date: string,
  omitID: string,
}

type Kits = {
  [id: string]: {
    id: string,
    title: string,
  }
}

const defaultState: Kits = {};

export default function useKits({ orgId, date, omitID }: Options): [Kits, boolean] {
  const [kits, setKits] = useState(defaultState);
  const [loading, setLoading] = useState(true);
  const [popupErr] = usePopupErr();

  const requestKitsWithItems = useCallback(async () => {
    const res = await api.request(GET_COMPLEXES, {
      requestPathParams: {
        orgId,
      },
      params: {
        date,
      },
    });

    if (res) {
      return res;
    }
    return null;
  }, [orgId, date]);

  const requestEmptyKits = useCallback(async () => {
    const res = await api.request(GET_COMPLEXES, {
      requestPathParams: {
        orgId,
      },
      params: {
        visible: true,
      },
    });

    if (res) {
      return res;
    }
    return null;
  }, [orgId]);

  const request = useCallback(async () => {
    setLoading(true);
    const [res1, res2] = await Promise.all([requestKitsWithItems(), requestEmptyKits()]);
    if (res1 && res2) {
      const k: Kits = map(
        ({ id, title }) => ({ id: String(id), title }),
        { ...res1, ...res2 },
      );
      setKits(k);
      setLoading(false);
      return;
    }
    setLoading(false);
    popupErr('Не удалось получить все комплекты');
  }, [requestKitsWithItems, requestEmptyKits, popupErr]);

  useEffect(() => {
    request();
  }, [request]);

  const res = useMemo(() => {
    return omit([omitID], kits);
  }, [kits, omitID]);

  return [res, loading];
}
