// @flow
import { useState, useCallback } from 'react';
import path from 'ramda/es/path';

import { api } from 'app/api';
import { DELETE_EMPLOYEE } from 'app/common/api/requests/food/employees/delete';


type Options = {
  userId: string | number,
  onSuccess: () => void,
}

export default function useDeleteUser({ userId, onSuccess }: Options): [
  boolean,
  () => Promise<void>
] {
  const [deleting, setDeleting] = useState(false);

  const deleteUser = useCallback(async () => {
    setDeleting(true);
    const res = await api.request(DELETE_EMPLOYEE, {
      error: (error: Error) => {
        const message = path(['response', 'data', 'message'], error);
        if (message === 'employee balance is not zero') {
          return 'Не удалось удалить: баланс сотрудника не равен нулю';
        }
        if (message === 'employee has active identifier') {
          return 'Не удалось удалить: у сотрудника есть действующий идентификатор';
        }
        return 'Не удалось удалить пользователя';
      },
      requestPathParams: {
        userId,
      },
    });

    if (res) {
      setDeleting(false);
      onSuccess();
      return;
    }
    setDeleting(false);
  }, [onSuccess, userId]);

  return [deleting, deleteUser];
}
