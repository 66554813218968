// @flow
import { type ComponentType } from 'react';

import MultilineCell from '../Cells/MultilineCell';


export type ColumnProps = {
  id: string,
  title: string,
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
  },
  cellComponent: ComponentType<any> | string
}

const TITLE: 'title' = 'title';
const COUNT: 'count' = 'count';
const COST: 'cost' = 'cost';
const TOTAL: 'total' = 'total';

export const ids = {
  TITLE,
  COUNT,
  COST,
  TOTAL,
};

const getColumns: (cSign: string) => Array<ColumnProps> = cSign => ([{
  id: TITLE,
  title: 'Наименование',
  props: {
    alignLeft: true,
  },
  cellComponent: 'span',
}, {
  id: COST,
  title: `Стоимость,\u00A0${cSign}`,
  props: {
    alignRight: true,
  },
  cellComponent: MultilineCell,
}, {
  id: COUNT,
  title: 'Кол-во',
  props: {
    alignRight: true,
  },
  cellComponent: MultilineCell,
}, {
  id: TOTAL,
  title: `Всего,\u00A0${cSign}`,
  props: {
    alignRight: true,
  },
  cellComponent: MultilineCell,
}]);

export default getColumns;
