// @flow
import { useState, useCallback, useEffect } from 'react';

import { api } from 'app/api';
import { GET_REPORTS_DATA_BALANCES } from 'app/common/api/requests/reports/data';
// import { toCamelCase } from 'app/common/lib/object';
// import { getLastFirstM } from 'app/common/lib/names';

import type {
  Summary,
  School,
  Group,
  User,
  ReportsDataBalancesResult,
} from 'app/common/api/requests/reports/data.balances';


export type {
  Summary,
  School,
  Group,
  User,
};

// const mapIdx = addIndex(map);
// const convertResult = pipe(
//   toCamelCase,
//   evolve({
//     // $FlowFixMe
//     users: mapIdx(({ customerType, id, klass, role, name, ...others }, i) => ({
//       ...others,
//       name: getLastFirstM(name),
//       id: i,
//       group: klass || role,
//     })),
//     // $FlowFixMe
//     groups: mapIdx((obj, i) => assoc('id', i, obj)), // need normal id
//   })
// );


export type ReportData = ReportsDataBalancesResult | null

export default function useReportData(reportId: ?number): [ReportData, boolean] {
  const [reportData, setReportData] = useState<ReportData>(null);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    if (!reportId) return;

    setLoading(true);
    const res = await api.request(GET_REPORTS_DATA_BALANCES, {
      error: 'Не удалось загрузить данные отчета',
      params: {
        report_id: reportId,
      },
    });

    if (res) {
      setReportData(res);
    }
    setLoading(false);
  }, [reportId]);


  useEffect(() => {
    request();
  }, [request]);

  return [reportData, loading];
}
