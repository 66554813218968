// @flow
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import prop from 'ramda/es/prop';
import head from 'ramda/es/head';
import sort from 'ramda/es/sort';
import map from 'ramda/es/map';
import evolve from 'ramda/es/evolve';
import replace from 'ramda/es/replace';
import descend from 'ramda/es/descend';
import pipe from 'ramda/es/pipe';
import toString from 'ramda/es/toString';

import { defaultStateDataCurrent, getStateCurrentValue, type State, type StateDataItem } from 'app/common/ui-components/filter';
import { GET_SCHOOLYEARS, dateFormat } from 'app/common/api/requests/reports/schoolyears_list';
import { api } from 'app/api';

import orgFilter from '../org';


export { dateFormat };

const yearItems = async (state: State): Promise<[Array<StateDataItem>, Array<string>]> => {
  const orgIds = getStateCurrentValue(orgFilter, state);
  const orgId = orgIds[0];

  const res = await api.request(GET_SCHOOLYEARS, {
    error: 'Не удалось получить данные учебных лет',
    params: {
      school_id: parseInt(orgId, 10),
    },
  });

  if (res && !isEmpty(res)) {
    const items = pipe(
      values,
      sort(descend(prop('title'))), // последний год можно будет взять через head()
      map(evolve({ title: replace(/-/, '–') })), // нормальные тире
    )(res);

    const selected = pipe(
      head,
      prop('id'),
      toString,
    )(items);

    return ([items, [String(selected)]]);
  }

  return [[], defaultStateDataCurrent];
};

export default yearItems;
