// @flow
import values from 'ramda/es/values';
import filter from 'ramda/es/filter';
import identity from 'ramda/es/identity';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import toString from 'ramda/es/toString';
import evolve from 'ramda/es/evolve';

import {
  type Params as ComponentParams,
  type SourceData,
  type ActorData,
} from 'app/dataTypes/OrgSettingsChangesFilters.types';

import type { Request } from '../../../index';
import { feeds, section2Feed } from './index';


type Params = {
  from_date?: string,
  to_date?: string,
  feed_id?: $Values<typeof feeds>
}

type Source = {
  id: number,
  title: string,
}

type Actor = {
  id: string,
  title: string,
}

type Result = {
  sources: {
    [id: string]: Source,
  },
  actors: {
    [id: string]: Actor,
  },
}

type UrlParams = {
  orgId: number | string,
}

export const convertParams = (params: ComponentParams): [Params, UrlParams] => {
  return [filter(identity, {
    from_date: params.from,
    to_date: params.to,
    feed_id: section2Feed[params.section],
  }), {
    orgId: params.orgId,
  }];
};

export const convertResult = (data: Result): [SourceData, ActorData] => {
  return [
    map(evolve({ id: toString }), values(prop('sources', data) || {})),
    values(prop('actors', data) || {}),
  ];
};

export const GET_CHANGES_FILTERS: Request<
  ComponentParams,
  [SourceData, ActorData],
  UrlParams,
> = [
  'food/schools/{orgId}/changes/filters',
  'get',
  ['data'],
];
