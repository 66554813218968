// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import IconRwb from './icons/IconRwb';
import enhanceSideNav from './enhanceSideNav';
import styles from './SideNav.scss';


const cx = classNames.bind(styles);

type Props = {
  lock?: string | boolean,
  isWide: boolean,
  onCollapse: Function,
  className?: string,
  children: any,
};

const SideNav = ({
  lock,
  isWide,
  onCollapse,
  className,
  children,
}: Props) => (
  <div className={cx({ root: true, collapsed: !isWide }, className)}>
    {!lock
      && <a className={styles.collapse} onClick={onCollapse} href="collapse">
        <IconRwb className={cx({ rw: true, flip: !isWide })} />
      </a>}
    {children instanceof Array
      ? children.map(child => (child ? React.cloneElement(child, { collapsed: !isWide }) : null))
      : React.cloneElement(children, { collapsed: !isWide })}
  </div>
);

export default enhanceSideNav(SideNav);
