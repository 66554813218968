// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';
import { orderTypes } from 'app/dataTypes/reports/orderTypes';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const onlyCommonSelected = (state: State) => {
  const orderTypeSelected = getStateCurrentValue(filterId, state);
  return (
    orderTypeSelected.length === 1
    && orderTypeSelected[0] === orderTypes.Common
  );
};

export const onlyPersonalSelected = (state: State) => {
  const orderTypeSelected = getStateCurrentValue(filterId, state);
  return (
    orderTypeSelected.length === 1
    && orderTypeSelected[0] === orderTypes.Personal
  );
};

export const allSelected = (state: State) => {
  const orderTypeSelected = getStateCurrentValue(filterId, state);
  return (
    orderTypeSelected.length === 0 || orderTypeSelected.length === 2
  );
};
