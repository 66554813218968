// @flow
import { createContext } from 'react';


type VersionsContextType = {
  needUpdateType: null | 'critical' | 'minor',
  buildVersion: string,
  buildDate: string,
  loading: boolean,
  handleUpdate: () => void,
}

const defaultContext: VersionsContextType = {
  needUpdateType: null,
  buildVersion: '',
  buildDate: '',
  loading: true,
  handleUpdate: () => {},
};

export default createContext<VersionsContextType>(defaultContext);
