import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import path from 'ramda/es/path';
import replace from 'ramda/es/replace';
import pick from 'ramda/es/pick';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import join from 'ramda/es/join';
import descend from 'ramda/es/descend';
import ascend from 'ramda/es/ascend';
import sortWith from 'ramda/es/sortWith';
import petrovich from 'petrovich';

import { getFIO } from 'app/common/lib/names';
import toFixedValue from 'app/common/lib/toFixedValue';
import * as statuses from 'app/common/constants/students/subsidies/statuses';


// Селектор заголовка окна
type Props = {
  idx: number,
}
const selectTitle = createSelector(
  (state, { idx }) => path(['reports', 'subsidies', 'data', 'students', idx, 'name'], state),
  (person) => {
    const genetiveName = petrovich({ ...person }, 'genitive');
    return `Справки ${getFIO(genetiveName)}`;
  },
);

// Список комплектов через запятую из объекта комплектов
const foodkitsList = pipe(
  values,
  map(prop('title')),
  join(', '),
);

// последовательность сортировки по статусу
const statusSequence = {
  [statuses.ACTIVE]: 1,
  [statuses.PENDING]: 2,
  [statuses.FINISHED]: 3,
  [statuses.DELETED]: 4,
};

// функция сортировки справок
export const sortFoodorders = sortWith([
  // по дате начала (новые вверху)
  descend(prop('from')),
  // если дата начала совпадает, по статусу в соответствии statusSequence
  ascend(x => (prop(prop('status', x), statusSequence))),
]);

// функция преобразования справок
export const mapFoodorders = (foodkits: Object) => map(({
  idx,
  number,
  status,
  to,
  from,
  for_single_sales: forSingleSales,
  for_klass_orders: forKlassOrders,
  for_day_care: forDayCare,
  include_saturday: includeSaturday,
  sum,
  complexes,
  total_sum: total,
}) => ({
  idx,
  number,
  status,
  to: replace(/-/g, '.', to),
  from: replace(/-/g, '.', from),
  description: (sum
    // если есть сумма, то в описании сумма
    ? toFixedValue(sum)
    // иначе список комплектов на основе id комплектов из списка комплектов foodkits
    // выбираем по массиву id при помощи pick
    : foodkitsList(pick(complexes, foodkits))),
  total: toFixedValue(total),
  options: {
    forSingleSales,
    forKlassOrders,
    forDayCare,
    includeSaturday,
  },
  calculated: true,
  stoppable: false,
  deletable: false,
}));

// селектор данных справок по субсидиям
const selectSubsidies = createSelector(
  [
    (state, { idx }) => path(['reports', 'subsidies', 'data', 'students', idx, 'orders'], state),
    // данные комплектов берем из фильтра "суммы и комплекты"
    // этот фильтр будет заполнен, он зависит от выбора одной школы, так же как и текущий отчет
    state => state.reports.filters.sumsAndKits.data,
  ],
  (orders, sumsAndKits) => pipe(
    mapFoodorders(sumsAndKits),
    sortFoodorders,
  )(orders),
);

export default connect(
  (state, props: Props) => ({
    title: selectTitle(state, props),
    items: selectSubsidies(state, props),
  }),
);
