
export enum AvailableDisplayName {
  PlainModalContainer = 'PlainModalContainer',
  PlainModalContent = 'PlainModalContent',
  PlainModalHeader = 'PlainModalHeader',
  PlainModalFooter = 'PlainModalFooter'
}

export const isAvailableDisplayName = (name: any): name is AvailableDisplayName => {
  return Object.values(AvailableDisplayName).includes(name as AvailableDisplayName)
}
