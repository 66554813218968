// @flow
import { useState, useCallback, useRef } from 'react';


const APPEAR_DELAY = 500;

type State = {
  show: boolean,
  style: {
    left: number,
    top: number
  },
}

type Handlers = {
  onMouseEnter: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseMove: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave: (e: SyntheticMouseEvent<HTMLElement>) => void,
}

type MousePosition = {
  clientX: number,
  clientY: number,
}

export default function useTooltip(): [State, Handlers] {
  const [show, setShow] = useState(false);
  const [style, setStyle] = useState({ left: 0, top: 0 });
  const mousePosition = useRef<MousePosition>({ clientX: 0, clientY: 0 });
  const timeout = useRef<?TimeoutID>(null);


  const updateMousePosition = useCallback((m: MousePosition) => {
    mousePosition.current = m;
  }, []);

  const showTooltip = useCallback(() => {
    const { clientX, clientY } = mousePosition.current;
    setStyle({ left: clientX, top: clientY - 12 });
    setShow(true);
  }, []);


  const onMouseEnter = useCallback(() => {
    timeout.current = setTimeout(() => {
      showTooltip();
    }, APPEAR_DELAY);
  }, [showTooltip]);

  const onMouseMove = useCallback((e: SyntheticMouseEvent<HTMLElement>) => {
    const { clientX, clientY } = e;
    updateMousePosition({ clientX, clientY });
  }, [updateMousePosition]);

  const onMouseLeave = useCallback(() => {
    if (show) {
      setShow(false);
    }
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, [show]);


  return [{
    show,
    style,
  }, {
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
  }];
}
