
import { getFIO } from 'app/common/lib/names'

import { Params } from 'app/dataTypes/customer'
import { Customer, CustomerType } from 'app/common/ui-next/_filter-compilations/customer-search/types'
import type { Request } from '../../index'


type ParamsApi = {
  search: string
}

export const convertParams = ({ search }: Params): [ParamsApi, null] => (
  [{ search }, null]
)

type ResultApi = {
  id: number,
  name: {
    first: string,
    last: string,
    middle: string,
  },
  type: 'Student' | 'Employee',
  description: string,
  phone: string,
  account_number: null,
} | null

const castToCustomerType = (input: 'Student' | 'Employee'): CustomerType | null => {
  if (input === 'Student') {
    return CustomerType.Student
  } if (input === 'Employee') {
    return CustomerType.Employee
  }
  return null
}


export const convertResult = (data: ResultApi): Customer | null => {
  if (data) {
    const {
      id,
      name,
      type,
      description,
      phone,
      account_number: accountNumber,
    } = data
    return {
      ID: String(id),
      fullName: getFIO(name),
      type: castToCustomerType(type),
      description,
      phone,
      accountNumber,
    }
  }
  return null
}

export const GET_FIND_CUSTOMER: Request<Params, Customer | null, void> = [
  'reports/find_customer',
  'get',
  ['data', 'customer'],
]
