// @flow
import { calcPeriodFromStart, dateFormat } from 'app/common/ui-next/plain-date-period';


const dummyItems = [{ id: 'dummy', title: 'dummy' }];

const periodItems = () => {
  const period = calcPeriodFromStart();
  return ([dummyItems, period]);
};

export default periodItems;

export { dateFormat };
