import { useCallback, useState } from 'react'

import { checkErrorsCampAccruals } from './checkErrorsCampAccruals'
import { checkErrorsCampAccrualsFor } from './checkErrorsCampAccrualsFor'


export type Errors = Map<string, string>

const emptyErrors: Errors = new Map()

export function useCampErrors() {
  const [errors, setErrors] = useState<Errors>(emptyErrors)

  const resetErrors = useCallback(() => {
    setErrors(emptyErrors)
  }, [])

  const checkErrors = useCallback((formData: FormData) => {
    const err = new Map()

    checkErrorsCampAccruals(err, formData)
    checkErrorsCampAccrualsFor(err, formData)

    if (err.size) {
      setErrors(err)
      return false
    }
    return true
  }, [])

  return {
    errors,
    resetErrors,
    checkErrors,
  }
}
