// @flow
import React, { useCallback, useEffect } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';
import prop from 'ramda/es/prop';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as sp from 'app/actions/reports/filters/sellPoints';

import { selectOrgId } from './hocs/commonSelectors';
import styles from './FilterItems.scss';


const selectIsEnableDefault = createSelector(
  [
    selectOrgId,
  ],
  orgId => Boolean(
    orgId, // выбрана школа (одна)
  ),
);

const selectSellPointsDefault = createSelector(
  [
    state => state.reports.filters.sellPoints.data,
  ],
  filter(complement(prop('deleted'))),
);


type SellPointsType = {
  [key: string]: {
    id: number,
    title: string,
    deleted: boolean,
  }
}

type Props = {
  resetStatus: boolean,
  selectIsEnable?: (state: Object) => boolean,
}

const SellPointsFilter = ({ resetStatus, selectIsEnable }: Props) => {
  const dispatch = useDispatch();

  // данные точек продаж
  const itemsObj: SellPointsType = useSelector(selectSellPointsDefault);
  const loading = useSelector(state => state.reports.filters.sellPoints.loading, shallowEqual);

  // дaнные корпусов
  const buildingsSelected = useSelector(state => state.reports.filters.buildings.selected, shallowEqual);

  // выбранное
  const selectedItems: SellPointsType = useSelector(state => state.reports.filters.sellPoints.selected, shallowEqual);

  // и массив id с выбранным
  const selectedArr: Array<number> = mapObjPropToArray('id')(selectedItems);

  // дополнительные параметры для запроса
  const orgId = useSelector(selectOrgId);


  const filterTitle = 'Точка продаж:';
  const filterModalTitle = 'Выбор точки продаж';


  const handleReset = useCallback(() => {
    dispatch(action(sp.SELLPOINTS_FILTER_SET, {}));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(sp.SELLPOINTS_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(sp.SELLPOINTS_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE и запрос данных
   */

  const isEnable = useSelector(selectIsEnable || selectIsEnableDefault);

  useEffect(() => {
    dispatch(action(sp.SELLPOINTS_FILTER_UPDATE, { active: isEnable }));

    if (isEnable) {
      (async () => {
        const buildingIds = mapObjPropToArray('id')(buildingsSelected);
        const res = await dispatch(
          sp.sellPointsFetch(orgId, isEmpty(buildingIds) ? {} : { building_id: buildingIds }),
        );
        if (res) {
          handleReset();
        }
      })();
    }
  }, [isEnable, orgId, buildingsSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isEnable) {
    return null;
  }


  return (
    <PlainFilterComplete
      id="sellPoints"
      loading={loading}
      className={styles.filter}
      title={filterTitle}
      modalTitle={filterModalTitle}
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default SellPointsFilter;
