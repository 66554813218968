// @flow
import { propEq } from 'ramda';

import {
  defaultStateDataCurrent,
  type StateDataItem,
  type StateDataCurrent,
} from 'app/common/ui-components/filter';

import { productionTypes } from 'app/dataTypes/reports/productionTypes';

import productionTypeDefault from './productionTypeDefault';


const productionTypeItems = (): [Array<StateDataItem>, StateDataCurrent] => (
  [productionTypeDefault(), defaultStateDataCurrent]
);

export const onlyComplex = (): [Array<StateDataItem>, StateDataCurrent] => ([
  productionTypeDefault().filter(propEq('id', productionTypes.Complex)),
  [productionTypes.Complex],
]);

export default productionTypeItems;
