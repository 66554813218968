// @flow
import moment from 'moment-timezone';


type DisabledDays = (dayFarmatted: string, format: string, unit?: string) => boolean

export const disabledFutureDays = (dayFormatted: string, format: string, unit?: string = 'day') => {
  const mDay = moment(dayFormatted, format);
  const mToday = moment();
  // Даты из будущего заблокированы
  return mDay.isAfter(mToday, unit);
};

const allDaysEnabled: DisabledDays = () => false;

const getDisabledDaysFunc = (dateStart: ?string, dateFinish: ?string): DisabledDays => {
  if (!dateStart && !dateFinish) {
    return allDaysEnabled;
  }
  const mDateStart = moment(dateStart);
  const mDateFinish = moment(dateFinish);

  const dateStartValid = !!dateStart && mDateStart.isValid();
  const dateFinishValid = !!dateFinish && mDateFinish.isValid();

  if (dateStartValid && dateFinishValid) {
    return (date: string, format: string, unit: string = 'day') => (
      !moment(date, format).isBetween(mDateStart, mDateFinish, unit, [])
    );
  }
  if (dateStartValid) {
    return (date: string, format: string, unit: string = 'day') => (
      moment(date, format).isBefore(mDateStart, unit)
    );
  }
  if (dateFinishValid) {
    return (date: string, format: string, unit: string = 'day') => (
      moment(date, format).isAfter(mDateFinish, unit)
    );
  }
  return allDaysEnabled;
};

export default getDisabledDaysFunc;
