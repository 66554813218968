// @flow
import React, { type Ref } from 'react';

import { Tr, Td, getPropsHeader } from 'app/common/ui-components/table';

import type { ColumnProps } from './columns';
import type { Payment } from '../useReportData';
import RowCell from './RowCell';


type Props = {
  reference?: Ref<'tr'>,
  actualPayment: Payment,
  columns: Array<ColumnProps>,
}

const Row = ({ reference, actualPayment, columns }: Props) => {
  const headerLinkProps = getPropsHeader();

  return (
    <Tr reference={reference}>
      {columns.map(({ id, props }) => (
        <Td
          key={id}
          {...headerLinkProps(id)}
          {...props}
        >
          <RowCell columnId={id} actualPayment={actualPayment} />
        </Td>
      ))}
    </Tr>
  );
};

export default Row;
