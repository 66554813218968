import {
  CATEGORIES_ADD_TO_SELECTION,
  CATEGORIES_REMOVE_FROM_SELECTION,

  CATEGORIES_CHANGE_ITEM_REQUEST,
  CATEGORIES_CHANGE_ITEM_SUCCESS,
  CATEGORIES_CHANGE_ITEM_FAILURE,
} from 'app/actions/categories/selection';


const defaultState = {
  items: [],
  loading: false,
  deleted: false,
};

const selection = (state = defaultState, action) => {
  switch (action.type) {
    case CATEGORIES_ADD_TO_SELECTION: {
      return {
        ...state,
        items: [...(action.payload.deleted === state.deleted ? state.items : []), action.payload.id],
        deleted: action.payload.deleted,
      };
    }

    case CATEGORIES_REMOVE_FROM_SELECTION: {
      return { ...state, items: state.items.filter(item => (item !== action.payload)) };
    }

    case CATEGORIES_CHANGE_ITEM_REQUEST: {
      return { ...state, loading: true, items: [...action.payload.id] };
    }

    case CATEGORIES_CHANGE_ITEM_SUCCESS: {
      return { ...state, loading: false, items: [] };
    }

    case CATEGORIES_CHANGE_ITEM_FAILURE: {
      return { ...state, loading: false };
    }

    default:
  }
  return state;
};

export default selection;
