// @flow
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Button from 'app/common/ui/Button';
import PlainInputFocused from 'app/common/ui/PlainInput/PlainInputFocused';
import PlainInputIcon from 'app/common/ui/PlainInputIcon';
import ModalExtended from 'app/common/components/ModalExtended';
import shadows from 'app/common/containers/shadows';
import Tooltip from 'app/common/ui/Tooltip';

import DishesList from './DishesList';
import InfoItem from './InfoItem';
import enhanceEditList from './enhanceEditList';
import OrgsDishesEditListGear from './OrgsDishesEditListGear';

import styles from './EditList.scss';


type Props = {
  returnTo: string,
  addList: Array<number>,
  removeList: Array<number>,

  searchString: string,
  handleSearch: (e: SyntheticEvent<HTMLElement>) => void,
  handleClearSearch: (e: SyntheticEvent<HTMLElement>) => void,

  handleSubmit: (e: SyntheticEvent<HTMLElement>) => void,
  sending: boolean,
}

const EditList = ({
  returnTo,
  addList,
  removeList,

  searchString,
  handleSearch,
  handleClearSearch,

  handleSubmit,
  sending,
}: Props) => (<ModalExtended
  className={styles.root}
  header={
    <div className={styles.top}>
      <div className={styles.header}>{'Редактирование списка продукции'}</div>
      <PlainInputFocused
        component={PlainInputIcon}
        placeholder="Поиск"
        onChange={handleSearch}
        value={searchString}
        icon={
          searchString
            && <a href="clearSearch" className={styles.clearSearch} onClick={handleClearSearch}>{'×'}</a>
        }
      />
    </div>
  }
  extra={<OrgsDishesEditListGear className={styles.gear} />}
>
  <div className={styles.container}>

    <div className={styles.content}>

      {/* Список блюд */}
      <DishesList className={styles.items} />

      <div className={styles.info}>
        <Scrollbars>
          <InfoItem key={1} items={addList} title="Будет добавлено" className={styles.infoItem} />
          <InfoItem key={2} items={removeList} title="Будет удалено" className={styles.infoItem} />
        </Scrollbars>
      </div>
    </div>

    <div className={styles.footer}>
      <form onSubmit={handleSubmit}>
        <Tooltip
          className={styles.tooltip}
          text={!addList.length && !removeList.length ? 'Необходимо выбрать/удалить хотя бы одну продукцию' : null}
        >
          <Button
            value={sending ? 'Сохранение...' : 'Сохранить в текущую категорию'}
            disabled={sending || (!addList.length && !removeList.length)}
            submit
          />
        </Tooltip>
        <Button value="Отмена" outline className={styles.cancelBt} href={returnTo} />
      </form>
      <div className={styles.legend}>
        <div>
          <span className={styles.alreadyLegend} />
          {'Продукция уже в вашем списке'}
        </div>
        <div>
          <span className={styles.selectedLegend} />
          {'Новая выбранная продукция'}
        </div>
      </div>
    </div>

  </div>
</ModalExtended>);

export default enhanceEditList(shadows(EditList));
