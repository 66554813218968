import { OrgGroupsByOrgType, ParamsDELETE } from 'app/dataTypes/org/orgGroup'

import { Request } from '../../index'
import { convertResult } from './common'


export { convertResult }

type UrlParams = {
  orgGroupID: number | string,
}

export const convertParams = (params: ParamsDELETE): [null, UrlParams] => {
  const { orgGroupID } = params
  return ([null, { orgGroupID }])
}

export const DELETE_ORG_GROUP: Request<ParamsDELETE, OrgGroupsByOrgType, UrlParams> = [
  'food/school_groups/{orgGroupID}',
  'delete',
  ['data', 'school_groups'],
]
