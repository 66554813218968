// @flow
import React from 'react';

import PlainLabel from 'app/common/ui-next/plain-label';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';

import { moduleTypes } from '../../identifiers-data';
import ModulesSwitcherItem from './modules-switcher-item';
import styles from './modules-switcher.scss';


type Props = {
  id: number | string,
  label: string,
  locked?: boolean,
  isCafeCard: boolean,
  isAccessCard: boolean,
  onChange: (type: $Values<typeof moduleTypes>, checked: boolean) => void,
  hiddenModuleSwitchers: Array<$Values<typeof moduleTypes>>,
}

const ModulesSwitcher = ({
  id,
  label,
  locked,
  isCafeCard,
  isAccessCard,
  onChange,
  hiddenModuleSwitchers,
}: Props) => {
  const labelId = `services_${id}`;

  return (
    <div className={styles.root}>
      <PlainLabel id={labelId} className={styles.label}>{label}</PlainLabel>
      <PlainFieldset
        className={styles.items}
        ariaLabelledby={labelId}
        name={labelId}
      >
        {!hiddenModuleSwitchers.includes(moduleTypes.DININGROOM)
          && <ModulesSwitcherItem
            type={moduleTypes.DININGROOM}
            active={isCafeCard}
            id={`item_${id}_dining`}
            disabled={locked}
            onChange={onChange}
          />}
        {!hiddenModuleSwitchers.includes(moduleTypes.PASSING)
          && <ModulesSwitcherItem
            type={moduleTypes.PASSING}
            active={isAccessCard}
            id={`item_${id}_passing`}
            disabled={locked}
            onChange={onChange}
          />}
        {/* <ModulesSwitcherItem
          type={moduleTypes.LOCKERS}
          active={false}
          disabled={locked}
          id={`item_${id}_lockers`}
        />
        <ModulesSwitcherItem
          type={moduleTypes.VENDING}
          active={false}
          disabled={locked}
          id={`item_${id}_vending`}
        />
        <ModulesSwitcherItem
          type={moduleTypes.TRANSPORT}
          active={false}
          disabled={locked}
          id={`item_${id}_transport`}
        />
        <ModulesSwitcherItem
          type={moduleTypes.INTERCOM}
          active={false}
          disabled={locked}
          id={`item_${id}_intercom`}
        /> */}
      </PlainFieldset>
    </div>
  );
};

export default ModulesSwitcher;
