// @flow
import { map } from 'ramda';

import { api } from 'app/api';
import { GET_VENDING_ITEMS, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/get';
import type { Params, Dishes as CommonDishes } from 'app/dataTypes/Dishes.types';


export const createRequestVendingDishes = (placeId: string): ((Params) => Promise<CommonDishes | null>) => async ({ limit, offset, search }: Params): Promise<CommonDishes | null> => {
  const res = await api.request(GET_VENDING_ITEMS, {
    error: 'Не удалось получить товары для добавления',
    params: {
      placeId,
      deleted: false,
      limit,
      offset,
      search,
    },
    convertions: {
      convertParams,
      convertResult,
    },
  });

  if (res) {
    const { data, total } = res;
    return {
      items: map(({ id, title, photoURL, cost, portion, saleType }) => ({
        id,
        title,
        photoUrl: photoURL,
        cost,
        portion,
        saleType,
        nomenclature: null,
      }), data),
      total,
    };
  }
  return res;
};
