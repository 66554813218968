// @flow
import pipe from 'ramda/es/pipe';
import toPairs from 'ramda/es/toPairs';
import filter from 'ramda/es/filter';
import prop from 'ramda/es/prop';
import test from 'ramda/es/test';
import replace from 'ramda/es/replace';
import fromPairs from 'ramda/es/fromPairs';
import map from 'ramda/es/map';

import type { Request } from '../index';


type ReportsEnabled = {
  food_cabinet_sales_report_enabled: boolean, // продажи доступны
  food_cabinet_subsidies_report_enabled: boolean, // дотации доступны
  food_cabinet_balances_report_enabled: boolean, // остаток средств доступен
  food_cabinet_accounting_report_enabled: boolean, // учет выдачи доступен
  food_cabinet_purchases_by_funds_report_enabled: boolean, // продажи по видам средств
  food_cabinet_purchases_by_payment_mode_report_enabled: boolean, // продажи по способу расчета
  food_cabinet_corrections_report_enabled: boolean, // продажи по способу расчета
}

type ReportsUse = {
  food_cabinet_sales_report_use: boolean, // продажи используются
  food_cabinet_subsidies_report_use: boolean, // дотации используются
  food_cabinet_balances_report_use: boolean, // остаток средств используется
  food_cabinet_accounting_report_use: boolean, // учет выдачи используется
  food_cabinet_purchases_by_funds_report_use: boolean, // продажи по видам средств
  food_cabinet_purchases_by_payment_mode_report_use: boolean, // продажи по способу расчета
  food_cabinet_corrections_report_use: boolean, // продажи по способу расчета
}

export type ReportsListResult = {
  ...ReportsEnabled,
  ...ReportsUse,
}

export const GET_REPORTS_LIST: Request<
  void,
  ReportsListResult,
  void
> = [
    'food/reports_list',
    'get',
    ['data', 'reports'],
  ];

export type MutableParams = $Shape<ReportsUse>

export const defaultMutableParams: MutableParams = {
  food_cabinet_sales_report_use: false,
  food_cabinet_subsidies_report_use: false,
  food_cabinet_balances_report_use: false,
  food_cabinet_accounting_report_use: false,
  food_cabinet_purchases_by_funds_report_use: false,
  food_cabinet_corrections_report_use: false,
};

type PostReportsListParams = {
  reports: MutableParams,
}

export const POST_REPORTS_LIST: Request<
  PostReportsListParams,
  ReportsListResult,
  void
> = [
    'food/reports_list',
    'post',
    ['data', 'reports'],
  ];

export const onlyMutableParams: (ReportsListResult) => MutableParams = (
  pipe(
    toPairs,
    filter(pipe(prop(0), test(/_report_use$/))),
    fromPairs,
  )
);

export const onlyMutableAndEnabledParams = (params: ReportsListResult): MutableParams => (
  pipe(
    toPairs,
    filter(([key]) => (
      test(/_report_use$/, key)
      && params[replace(/_report_use$/, '_report_enabled', key)]
    )),
    fromPairs,
  )(params)
);

export const onlyAvailableReports = (params: ReportsListResult): Array<string> => (
  pipe(
    toPairs,
    filter(([key, value]) => (
      test(/_report_use$/, key)
      && value
      && params[replace(/_report_use$/, '_report_enabled', key)]
    )),
    map(pipe(
      prop(0),
      replace(/^food_cabinet_/, ''),
      replace(/_report_use$/, ''),
    )),
  )(params)
);
