import { useCallback, useMemo } from 'react'
import moment from 'moment-timezone'

import PlainButtonAsLink from '../plain-button-as-link'
import PlainSelector from './plain-selector'
import PlainDateModal from './plain-date-modal'
import { dateFormat as dateFormatDefault } from './date-format'


export type PlainDateSelectorProps = {
  id?: string
  className?: string
  loading?: boolean
  disabled?: boolean
  label?: string
  multiple?: boolean
  disabledDay?: string
  getDisabledDay?: (dayFormatted: string, format: string, unit?: string) => boolean
  // disabledFutureDays?: boolean, // TODO
  // disabledPastDays?: boolean, // TODO
  // disabledToday?: boolean, // TODO
  buttonResetLabel?: string
  buttonSelectLabel?: string
  onChange?: (datesFormatted: Array<string>, dateFormat: string) => void
}

const PlainDateSelector = ({
  id,
  className,
  loading,
  disabled,
  label = 'Выбор даты',
  multiple,
  disabledDay,
  getDisabledDay,
  buttonResetLabel,
  buttonSelectLabel = 'Выбрать',
  onChange,
}: PlainDateSelectorProps) => {
  const mDisabledDay = useMemo(() => {
    return moment(disabledDay, dateFormatDefault)
  }, [disabledDay])

  const getDayDisabledStatus = useCallback((day: string, format: string) => {
    return moment(day, format).isSame(mDisabledDay, 'day')
  }, [mDisabledDay])

  const handleUpdateSelection = useCallback((values: Array<string>) => {
    if (onChange) {
      onChange(values, dateFormatDefault)
    }
  }, [onChange])

  const handleMakeSortable = useCallback((value: string) => (
    moment(value, dateFormatDefault).unix()
  ), [])

  return (
    <PlainSelector
      id={id}
      className={className}
      loading={loading}
      disabled={disabled}
      label={label}
      multiple={multiple}
      buttonResetLabel={buttonResetLabel}
      onChange={handleUpdateSelection}
      makeSortable={handleMakeSortable}
      selectedAllOnEmptySelection={false}
    >
      {(itemsSelected, update) => (
        <PlainDateModal
          onSubmit={update}
          multiple={!!multiple}
          dateItemsSelected={itemsSelected}
          getDayDisabledStatus={getDisabledDay || getDayDisabledStatus}
        >
          {(handleModalDisplayed) => {
            return (
              <PlainButtonAsLink
                // className={styles.btSelect}
                onClick={handleModalDisplayed}
                disabled={disabled}
              >
                {buttonSelectLabel}
              </PlainButtonAsLink>
            )
          }}
        </PlainDateModal>
      )}
    </PlainSelector>
  )
}

export default PlainDateSelector
