/**
* Фильтрация вывода блюд, в том числе поиск
*/

export const DISHES_SEARCH = 'DISHES_SEARCH';
export const DISHES_FILTER_SET = 'DISHES_FILTER_SET';
export const DISHES_FILTER_CLEAR = 'DISHES_FILTER_CLEAR'; // exept search

export const dishesSearch = string => ({
  type: DISHES_SEARCH,
  payload: string,
});

export const dishesFilterSet = payload => ({
  type: DISHES_FILTER_SET,
  payload,
});

export const dishesFilterClear = () => ({
  type: DISHES_FILTER_CLEAR,
});
