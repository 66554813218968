// @flow
import React from 'react';

import type { Customer } from 'app/dataTypes/reports/Customer.types';
import { customerTypes } from 'app/dataTypes/reports/customerTypes';

import styles from './customer-view.scss';


const CustomerView = ({ fullName, description, phone, accountNumber, type }: Customer) => {
  const unknownCustomer = !type || !customerTypes[type];

  return (
    <div>
      <div className={styles.fullName}>
        {fullName || 'Пользователь без имени'}
      </div>

      {description
        && <div className={styles.description}>
          {description}
        </div>}

      {(phone || accountNumber)
        && <div className={styles.secondBlock}>
          {phone && <div>{phone}</div>}
          {accountNumber && <div>{`ЛС: ${accountNumber}`}</div>}
        </div>}

      {unknownCustomer
        && <div className={styles.unknown}>
          {'Неизвестный тип пользователя'}
        </div>}
    </div>
  );
};

export default CustomerView;
