// @flow
// @flow-runtime warn
import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import prop from 'ramda/es/prop';
import clone from 'ramda/es/clone';

import api from 'app/actions/api';
import action from 'app/common/lib/action';
import type { Buildings } from './BuildingsTypes';


const defaultBuildingsState = {};

export default function useBuildingsData(orgId: number, autoload: boolean = true) {
  const dispatch = useDispatch();

  const [buildings, setBuildings] = useState(defaultBuildingsState);
  const [buildingsLoading, setBuildingsLoading] = useState(true);


  const handleRequestBuildings = useCallback(async () => {
    const requestPath = `food/schools/${orgId}/buildings`;
    const type = 'get';

    try {
      setBuildingsLoading(true);
      const { data } = await api(requestPath, type);
      const buildingsRes: Buildings = prop('buildings', data) || defaultBuildingsState;
      setBuildings(buildingsRes);
      setBuildingsLoading(false);
    } catch (error) {
      const errorSave = {
        err: 'Не удалось получить корпуса школы',
        path: requestPath,
        type,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
    }
  }, [dispatch, orgId]);


  useEffect(() => {
    if (autoload) {
      handleRequestBuildings();
    }
  }, [autoload, handleRequestBuildings]);


  return [buildings, buildingsLoading, handleRequestBuildings];
}
