// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const MENU_REQUEST = 'orgs/menu/MENU_REQUEST';
export const MENU_SUCCESS = 'orgs/menu/MENU_SUCCESS';
export const MENU_KITS_SUCCESS = 'orgs/menu/MENU_KITS_SUCCESS';
export const MENU_FAILURE = 'orgs/menu/MENU_FAILURE';

/**
* Получение категорий блюд для школы
*/

const menuRequest = payload => ({
  type: MENU_REQUEST,
  payload,
});

const menuSuccess = payload => ({
  type: MENU_SUCCESS,
  payload,
});

const menuKitsSuccess = payload => ({
  type: MENU_KITS_SUCCESS,
  payload,
});

const menuFailure = payload => ({
  type: MENU_FAILURE,
  payload,
});

export const menuFetch = (
  orgId: string,
  date: string,
  sellPointId: string,
  // categoryId: string
) => async (dispatch: Function) => {
  const params = {
    date,
    sale_point_id: sellPointId,
    // menu_category_id: categoryId,
  };
  dispatch(menuRequest(orgId));
  try {
    const res = await api(`food/schools/${orgId}/menu`, 'get', { params });
    dispatch(menuSuccess(get(res, ['data', 'menu_items'], {})));
    dispatch(menuKitsSuccess(get(res, ['data', 'complexes'], {})));
    return true;
  } catch (error) {
    dispatch(menuFailure({ err: 'Запрос меню для школы не удался', error }));
    return false;
  }
};
