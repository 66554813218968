import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import length from 'ramda/es/length';
import isEmpty from 'ramda/es/isEmpty';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import pair from 'ramda/es/pair';
import fromPairs from 'ramda/es/fromPairs';
import keys from 'ramda/es/keys';
import prop from 'ramda/es/prop';

import {
  EDULEVELS_FILTER_CLEAR,
  EDULEVELS_FILTER_DELETE,
  EDULEVELS_FILTER_SET,
  EDULEVELS_FILTER_UPDATE,
  educationLevelsFilterFetch,
} from 'app/actions/reports/filters/educationLevels';

import { isSingle } from 'app/common/lib/object';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import action from 'app/common/lib/action';
import * as orgsTypes from 'app/common/constants/schools/types';

import { selectOrgId, selectYearId, selectOrgsType } from './commonSelectors';


const getLen = pipe(keys, length);

export const selectIsAll = createSelector(
  [
    state => state.reports.filters.educationLevels.data,
    state => state.reports.filters.educationLevels.selected,
  ],
  (data, selected) => (isEmpty(selected) || (getLen(data) === getLen(selected))),
);

const selectIsEnableCommon = createSelector(
  [
    selectOrgId,
    selectYearId,
    selectOrgsType,
  ],
  (orgId, yearId, orgsType) => Boolean(
    orgId // выбрана школа
    && yearId // выбран уч. год
    && orgsType === orgsTypes.SCHOOL, // тип заведения - школа
  ),
);

const selectItems = createSelector(
  [
    state => state.reports.filters.educationLevels.data,
  ],
  pipe(
    keys,
    map(id => pair(id, { id, title: id })),
    fromPairs,
  ),
);

export default compose(
  connect(
    (state, props) => {
      const selectIsEnable = prop('selectIsEnable', props) || selectIsEnableCommon;

      return ({
        items: selectItems(state, props),
        selectedItems: state.reports.filters.educationLevels.selected,
        loading: state.reports.filters.educationLevels.loading,
        orgId: selectOrgId(state, props),
        yearId: selectYearId(state, props),
        isSelectedAllState: selectIsAll(state, props),
        isEnabled: selectIsEnable(state, props),
        active: state.reports.filters.educationLevels.active,
      });
    },
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(action(EDULEVELS_FILTER_SET, {}));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset();
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems, reset }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        reset();
        return;
      }
      dispatch(action(EDULEVELS_FILTER_DELETE, [name]));
    },

    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(action(EDULEVELS_FILTER_SET, submitted));
    },
  }),

  lifecycle({
    componentDidMount() {
      const { isEnabled, dispatch } = this.props;
      if (isEnabled) {
        dispatch(action(EDULEVELS_FILTER_UPDATE, { active: isEnabled }));
      }
    },
    async componentDidUpdate(prevProps) {
      const { dispatch, orgId, yearId, reset, isEnabled } = this.props;
      // зависит от смены учебного года
      if (yearId !== prevProps.yearId) {
        if (orgId && yearId) {
          await dispatch(educationLevelsFilterFetch(orgId, yearId));
          reset();
        }
      }
      if (isEnabled !== prevProps.isEnabled) {
        dispatch(action(EDULEVELS_FILTER_UPDATE, { active: isEnabled }));
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(action(EDULEVELS_FILTER_CLEAR));
    },
  }),

  branch(
    ({ isEnabled, items, loading }) => (!isEnabled || (isEmpty(items) && !loading)),
    renderNothing,
  ),
);
