import React from 'react';
import styles from './FilesPreview.scss';
import IconCross from '../../../icons/IconCross';


type File = {
  id: number,
  path: string,
  type: string,
  name: string,
};

type Props = {
  files: Array<File>,
  handleDelete: (Object) => void,
};


class Item extends React.Component {
  state = {
    delete: false,
  };

  props: {
    id: number,
    path: string,
    type: string,
    name: string,
    handleDelete: (Object) => void,
  };

  handleDelete = (e) => {
    e.preventDefault();
    this.setState({ delete: true });
    this.props.handleDelete(
      { target: { name: this.props.id } },
      () => this.setState({ delete: false }),
    );
  };

  render() {
    const { name, type, path } = this.props;

    return (
      <div className={styles.item}>
        {((type === 'jpg' || type === 'jpeg' || type === 'bmp' || type === 'png') && path)
          ? <img src={path} alt="Предпросмотр" />
          : <div className={styles.name}>{name}</div>}

        {this.state.delete && <div className={styles.delete} />}

        <div className={styles.close}>
          <a href="#close" onClick={this.handleDelete}>
            <IconCross className={styles.icon} />
          </a>
        </div>
      </div>
    );
  }
}


const FilesPreview = ({ files, handleDelete }: Props) => (<div className={styles.root}>
  {files.map(item => <Item key={item.id} handleDelete={handleDelete} {...item} />)}
</div>);

export default FilesPreview;
