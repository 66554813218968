// @flow
import React, { Fragment, useCallback, useState, useMemo } from 'react';
import { find, propEq } from 'ramda';

import PlainDropdown, { PlainDropdownDefitem } from 'app/common/ui-next/plain-dropdown';
import type { SaleTypes } from 'app/dataProviders/SaleTypesData';

import styles from './CellContentSaleType.scss';


type Props = {
  id: number | string,
  currentSaleType: ?string,
  pending: boolean,
  saleTypes: SaleTypes,
  locked?: boolean,
  onChangeSaleType: (field: 'saleType' | 'photo', value: string) => Promise<boolean>,
}

const CellContentSaleType = ({
  id,
  pending,
  currentSaleType,
  saleTypes,
  locked,
  onChangeSaleType,
}: Props) => {
  const [disabled, setDisabled] = useState(false);

  const handleChangeSaleType = useCallback(async ({ id: value }: { id: string }) => {
    setDisabled(true);
    await onChangeSaleType('saleType', value);
    setDisabled(false);
  }, [onChangeSaleType]);

  const currentTitle = useMemo(() => {
    return (find(propEq('id', currentSaleType), saleTypes) || {}).title;
  }, [currentSaleType, saleTypes]);


  return (
    <div className={styles.root}>
      {locked
        ? <span>{currentTitle}</span>
        : <Fragment>
          <span
            className={styles.hiddenValue}
            aria-hidden="true"
          >
            {currentTitle}
          </span>

          <PlainDropdown
            defaultSkin
            id={`saleType_${id}`}
            label="Тип продаж"
            className={styles.content}
            labelClassName="visuallyHidden"
            onChange={handleChangeSaleType}
            disabled={pending || disabled}
            placeholder={
              <PlainDropdownDefitem
                id="placeholder"
                disabled={pending || disabled}
              >
                {'Не задан'}
              </PlainDropdownDefitem>
            }
          >
            {saleTypes.map(({ id: id_, title }) => (
              <PlainDropdownDefitem
                key={id}
                id={id_}
                selected={id_ === currentSaleType}
                disabled={pending || disabled}
              >
                {title}
              </PlainDropdownDefitem>
            ))}
          </PlainDropdown>
        </Fragment>}
    </div>
  );
};

export default CellContentSaleType;
