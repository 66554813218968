// @flow
import type { Request } from '../../../index';

type UrlParams = {
  orgId: number | string,
  complexId: number | string,
}

type ComplexToggleVisibilityResult = {
  id: number,
  title: string,
  fixed_price_enabled: boolean,
  visible: boolean,
  deletable: boolean,
  cost?: string,
  fixed_price?: string,
  subsidy_orders?: {
    [id: string]: { id: number },
  },
}

export const POST_COMPLEX_TOGGLE_VISIBILITY: Request<
  void,
  ComplexToggleVisibilityResult,
  UrlParams
> = [
  'food/schools/{orgId}/complexes/{complexId}/toggle_visibility',
  'post',
  ['data', 'complex'],
];
