// @flow
import React from 'react';

import {
  BY_CLASSES,
  BY_ALPHABET,
} from 'app/actions/reports/filters/sort';

import type { SortBy } from 'app/actions/reports/filters/sort';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';

import withSort from './hocs/withSort';
import styles from './FilterItems.scss';


type Props = {
  sort: SortBy,
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  customerId: 'Сотрудники' | 'Учащиеся',
}

const SortFilter = ({ sort, handleChange, customerId }: Props) => (
  <div className={styles.filter}>
    <div className={styles.label}>{'Сортировать по:'}</div>
    <div className={styles.ingroup}>
      <PlainRadioButton
        label="алфавиту"
        checked={sort === BY_ALPHABET}
        name={BY_ALPHABET}
        value={BY_ALPHABET}
        onChange={handleChange}
      />
      <PlainRadioButton
        label="классам"
        checked={sort === BY_CLASSES}
        name={BY_CLASSES}
        value={BY_CLASSES}
        onChange={handleChange}
        disabled={customerId === 'Сотрудники'}
      />
    </div>
  </div>
);

export default withSort(SortFilter);
