// @flow
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';
import api from 'app/actions/api';
import { toCamelCase } from 'app/common/lib/object';


export const EMPLOYEE_HISTORY_REQUEST = 'EMPLOYEE_HISTORY_REQUEST';
export const EMPLOYEE_HISTORY_SUCCESS = 'EMPLOYEE_HISTORY_SUCCESS';
export const EMPLOYEE_HISTORY_FAILURE = 'EMPLOYEE_HISTORY_FAILURE';
export const EMPLOYEE_HISTORY_CLEAR = 'EMPLOYEE_HISTORY_CLEAR';

const employeeHistoryRequest = payload => ({
  type: EMPLOYEE_HISTORY_REQUEST,
  payload,
});

const employeeHistorySuccess = payload => ({
  type: EMPLOYEE_HISTORY_SUCCESS,
  payload,
});

const employeeHistoryFailure = payload => ({
  type: EMPLOYEE_HISTORY_FAILURE,
  payload,
});

export const employeeHistoryClear = () => ({
  type: EMPLOYEE_HISTORY_CLEAR,
});

export const employeeHistoryFetch = (id: number | string, limit: number, offset: number): ?Object => async (dispatch) => {
  const params = { limit, offset };
  dispatch(employeeHistoryRequest(id));
  try {
    const res = await api(`food/employees/${id}/changes`, 'get', { params });
    const data = pipe(
      prop('data'),
      omit(['result']),
      toCamelCase,
    )(res);
    dispatch(employeeHistorySuccess(data));
  } catch (error) {
    dispatch(employeeHistoryFailure({ err: 'Запрос истории аккаунта не удался', error }));
  }
};
