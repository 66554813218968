// @flow
import * as muduleTypes from './module-types';

const types2attributes: {
  [key: $Values<typeof muduleTypes>]: string,
} = {
  [muduleTypes.DININGROOM]: 'is_cafe_card',
  [muduleTypes.PASSING]: 'is_access_card',
};

export default types2attributes;
