import { useCallback } from 'react'
import cn from 'classnames/bind'

import styles from './status-filter-item.scss'


const cx = cn.bind(styles)

type Props = {
  groupName?: string
  className?: string
  title: string
  status: string
  active?: boolean
  color?: string
  onClick: (status: string) => void
}

const StatusFilterItem = ({
  groupName = 'status',
  className,
  title,
  active,
  status,
  color,
  onClick,
}: Props) => {
  const handleChange = useCallback(() => {
    onClick(status)
  }, [status, onClick])

  return (
    <label className={cx(styles.root, { active }, className)} htmlFor={status}>
      <input
        type="radio"
        id={status}
        name={groupName}
        value={status}
        checked={active}
        onChange={handleChange}
        className="visuallyHidden"
      />

      {color
        && <span className={styles.circle} style={{ backgroundColor: color }} />}

      <span className={styles.label}>{title}</span>
    </label>
  )
}

export default StatusFilterItem
