// @flow
import { useCallback } from 'react';
import { map, when, propEq, mergeLeft } from 'ramda';

import { PUT_VENDING_ITEMS_CATEGORIES, convertParams, convertResult } from 'app/common/api/requests/data/vending/items/categories/put';
import type { Category, Categories, ParamsPUT } from 'app/dataTypes/vending/production/Categories.types';
import { api } from 'app/api';


export default function useCategoryChange(
  setCategories: ((Categories => Categories) | Categories) => void,
): [
  (params: ParamsPUT) => Promise<boolean>,
  (paramsArray: Array<ParamsPUT>) => Promise<boolean>,
] {
  const categoryChange = useCallback(async (params: ParamsPUT) => {
    const res = await api.request(PUT_VENDING_ITEMS_CATEGORIES, {
      error: 'Не удалось изменить категорию',
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      setCategories(map(when(
        propEq('id', res.id),
        mergeLeft(res),
      )));
      return true;
    }
    return false;
  }, [setCategories]);

  const categoryBatchChange = useCallback(async (paramsArray: Array<ParamsPUT>) => {
    const res = await Promise.all(paramsArray.map(params => api.request(PUT_VENDING_ITEMS_CATEGORIES, {
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    })));

    const resByCategoryId = new Map(
      res
        .filter(Boolean)
        .map((cat: Category) => ([cat.id, cat])),
    );

    if (resByCategoryId.size) {
      setCategories(map(cat => (
        resByCategoryId.get(cat.id)
          ? { ...cat, ...resByCategoryId.get(cat.id) }
          : cat
      )));
      return true;
    }
    return false;
  }, [setCategories]);

  return [categoryChange, categoryBatchChange];
}
