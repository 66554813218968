// @flow
import React from 'react';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import { useLastChange, useChangesData } from 'app/dataProviders/OrgSettingsHistoryProvider';
import HistoryModal from 'app/common/ui-components/history-modal';

import HistoryModalFooter from './HistoryModalFooter';
import useHistoryProps from './useHistoryProps';
import FilterWindow from './filters/FilterWindow';

import styles from './HistoryLink.scss';


type Props = {
  sectionTitle: string,
}

const HistoryLink = ({ sectionTitle }: Props) => {
  const [lastChange] = useLastChange();
  const { loading } = useChangesData();
  const { created = '???', actor = 'Неизвестный' } = lastChange || {};

  const historyModalProps = useHistoryProps();
  const { itemsList } = historyModalProps;

  if (!lastChange) {
    return null;
  }

  return (
    <HistoryModal
      {...historyModalProps}
      className={styles.root}
      title={`История изменений — ${sectionTitle}`}
      mainContentMaxHeight="40vh"
      headerContent={
        <FilterWindow loading={loading} />
      }
      footerContent={
        <HistoryModalFooter currentPageSize={itemsList.length} />
      }
    >
      {toggle => (
        <PlainButtonAsLink className={styles.link} onClick={toggle}>
          {'Последние изменения: '}
          <strong>{actor}</strong>
          {` ${created}`}
        </PlainButtonAsLink>
      )}
    </HistoryModal>
  );
};

export default HistoryLink;
