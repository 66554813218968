// @flow
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import RowLayout from 'app/common/layouts/RowLayout';

import StubSvg from './Stub.svg';
import styles from './Stub.scss';


const getText = (hasPhone?: boolean) => (hasPhone
  ? 'Для работы с меню и комплектами, в\u00A0вашем профиле необходимо включить работу через «ЭДС». Обратитесь к\u00A0поставщику услуги'
  : 'Для работы с меню и комплектами, в\u00A0вашем профиле необходимо включить работу через «ЭДС».'
);

const Stub = () => {
  const { phone } = useSelector(state => state.dealer, shallowEqual);
  const hasPhone = !!phone;

  return (
    <RowLayout className={styles.root}>
      <StubSvg className={styles.image} />
      <div className={styles.text}>
        {getText(hasPhone)}

        {hasPhone
          && <div className={styles.phone}>
            {phone}
          </div>}
      </div>
    </RowLayout>
  );
};

export default Stub;
