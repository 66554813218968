import PlainIcon, { iconType, iconColor } from 'app/common/ui-next/plain-icon'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import { OrgGroup } from 'app/dataTypes/org/orgGroup'
import { OrgsData } from 'app/dataTypes/org'

import EditOrgGroupModal from './editOrgGroup/EditOrgGroupModal'
import DeleteGroup from './editOrgGroup/DeleteGroup'
import OrgGroupFilling from './editOrgGroup/OrgGroupFilling'

import styles from './GroupHeaderToolbar.scss'


type Props = {
  ID: string
  orgGroups?: Map<string, OrgGroup>
  orgsData: OrgsData
  locked?: boolean
  onDeleteGroup: (ID: string) => Promise<boolean>
  onEditGroup: (orgGroupID: string, title: string) => Promise<boolean>
  onAddRemoveOrgs: (
    orgGroupID: string,
    addOrgs: Array<string>,
    removeOrgs: Array<string>,
  ) => void
  errors: Record<string, string>
  resetErrors: () => void
}

const GroupHeaderToolbar = ({
  ID,
  orgGroups,
  orgsData,
  locked,
  onDeleteGroup,
  onEditGroup,
  onAddRemoveOrgs,
  errors,
  resetErrors,
}: Props) => {
  const title = orgGroups?.get(ID)?.title || 'Неизвестная группа'

  return (
    <div className={styles.root}>
      <OrgGroupFilling
        ID={ID}
        orgsData={orgsData}
        onAddRemoveOrgs={onAddRemoveOrgs}
      >
        {toggle => (
          <PlainButtonAsLink disabled={locked} onClick={toggle}>
            {'Редактировать список заведений'}
          </PlainButtonAsLink>
        )}
      </OrgGroupFilling>

      <EditOrgGroupModal
        ID={ID}
        submit={onEditGroup}
        errors={errors}
        resetErrors={resetErrors}
        value={title}
      >
        {toggle => (
          <PlainButtonAsLink onClick={toggle} disabled={locked}>
            <PlainIcon
              type={iconType.PEN}
              color={iconColor.COLOR_DARKGRAY}
              width={16}
              height="auto"
              disabled={locked}
            />
          </PlainButtonAsLink>
        )}
      </EditOrgGroupModal>

      <DeleteGroup
        ID={ID}
        title={title}
        onDeleteGroup={onDeleteGroup}
        disabled={locked}
      >
        <PlainIcon
          type={iconType.LIGHT_CROSS}
          color={iconColor.COLOR_DARKGRAY}
          width={16}
          height="auto"
          disabled={locked}
        />
      </DeleteGroup>
    </div>
  )
}

export default GroupHeaderToolbar
