import { ChangeEvent, ReactNode, useCallback } from 'react'

import useCallbackRef from 'app/common/hooks/useCallbackRef'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'

import styles from './StatusHeader.scss'


type Props = {
  children: ReactNode
  onClick: (checked: boolean) => void
  checked: boolean
  showSelectAllCheckbox: boolean
}

export const StatusHeader = ({ children, onClick, checked, showSelectAllCheckbox }: Props) => {
  const onClickRef = useCallbackRef(onClick)

  const handleClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked: chckd } = e.currentTarget
    onClickRef.current?.(chckd)
  }, [onClickRef])

  return (
    <div>
      <label htmlFor="all">
        {children}
      </label>

      {showSelectAllCheckbox
        && <PlainCheckbox
          id="all"
          value="all"
          className={styles.checkbox}
          label={children}
          checked={checked}
          onChange={handleClick}
          hideLabel
        />}
    </div>
  )
}
