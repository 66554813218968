// @flow
import React from 'react';
import classNames from 'classnames';

import styles from './UndefinedFile.scss';


type Props = {
  className?: string,
}

const UndefinedFile = ({ className }: Props) => (
  <span className={classNames(styles.root, className)}>
    &nbsp;
  </span>
);

export default UndefinedFile;
