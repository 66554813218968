import cn from 'classnames/bind'

import RenderValue from './render-value'
import SceletonItem from '../../page-template/skeleton-item'

import styles from './summary-item.scss'


const cx = cn.bind(styles)
const skeletonStyle = { height: '46px' }
const skeletonStyleTitle = { height: '20px' }

type Props = {
  className?: string
  title: string
  value: string | Array<string>
  pending?: boolean
  danger?: boolean
}

const SummaryItem = ({ title, value, pending, className, danger }: Props) => {
  return (
    <div>
      <div className={cx(styles.root, { danger }, className)}>
        {!title && pending
          ? <SceletonItem style={skeletonStyleTitle} />
          : <div className={styles.title}>{title}</div>}
        {pending
          ? <SceletonItem style={skeletonStyle} />
          : <RenderValue value={value} />}
      </div>
    </div>
  )
}

export default SummaryItem
