// @flow
import { useMemo } from 'react';
import { reduce, prop } from 'ramda';
import moment from 'moment-timezone';

import { useFilterData } from 'app/common/ui-components/filter';
import { reportTypes, type ReportsPerformFinancesEMoneyLegacyParams, dateFormat } from 'app/common/api/requests/reports/perform';
import mailFilter, { readyToUse, onlyMail } from 'app/components/common/reports/filters/mail';
// import detailsFilter from 'app/components/common/reports/filters/details';

import periodFilter, { dateFormat as periodDateFormat } from '../ReportFilter/filters/period';


export type Params = ReportsPerformFinancesEMoneyLegacyParams | null

export default function useFilterParams(): [Params, boolean] {
  const [,,, { getAppliedFilterActive }, filterParams] = useFilterData();

  const res: [Params, boolean] = useMemo(() => {
    const data: Array<[string, Array<string>]> | null = getAppliedFilterActive();
    if (!data) {
      return [null, false];
    }

    const emailParam = (filterParams && prop('email', filterParams)) || null;
    const hasEmail = !!emailParam;

    const { dateFrom, dateTo, onlyToMail, email } = reduce((acc, [key, keyData]) => {
      if (key === periodFilter && keyData[0] && keyData[1]) {
        const [from, to] = keyData;
        acc.dateFrom = moment(from, periodDateFormat).format(dateFormat);
        acc.dateTo = moment(to, periodDateFormat).format(dateFormat);
      }
      if (hasEmail && key === mailFilter && keyData[0]) {
        acc.onlyToMail = onlyMail(keyData[0]);
        acc.email = readyToUse(keyData[0]) ? emailParam : null;
      }
      return acc;
    }, {}, data);

    if (!(dateFrom && dateTo)) return [null, false];

    const base = {
      report_type: reportTypes.REPORT_FINANCES_E_MONEY,
      params: {
        date_from: dateFrom,
        date_to: dateTo,
      },
    };

    if (!email) {
      return [base, false];
    }

    return [{
      ...base,
      options: {
        send_to_email: email,
      },
    }, onlyToMail];
  }, [getAppliedFilterActive, filterParams]);

  return res;
}
