// @flow
import type { State } from 'app/common/ui-components/filter';

import { flags as org } from '../org';
import { flags as sellType } from '../sellType';


const foodModeActivity = (state: State) => {
  return (
    org.filterActive(state)
    && org.singleOrgSelected(state)
    && sellType.onlyComplexSelected(state)
  );
};

export default foodModeActivity;
