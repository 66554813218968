import { MENU_KITS_SUCCESS } from 'app/actions/orgs/menu';

const defaultState = {};

const kits = (state = defaultState, action) => {
  if (action.type === MENU_KITS_SUCCESS) {
    return { ...action.payload };
  }
  return state;
};

export default kits;
