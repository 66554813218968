// @flow
import isEmpty from 'ramda/es/isEmpty';

import { getStateCurrentValue, type State } from 'app/common/ui-components/filter';

import detailsFilter from '../details';


const mailActivity = (state: State) => {
  const detailsIds = getStateCurrentValue(detailsFilter, state);

  return (
    !isEmpty(detailsIds)
  );
};

export default mailActivity;
