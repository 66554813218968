// @flow
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import useEmail from 'app/components/common/email/useEmail'


export type Checks = {
  copySettings: boolean,
  changeSoldItems: boolean,
  copyComplexPhoto: boolean,
  keepComplexPrice: boolean,
  copyExtendedSettings: boolean,
  keepGrocerySetPrice: boolean,
}

const defaultChecks: Checks = {
  copySettings: false,
  changeSoldItems: false,
  copyComplexPhoto: false,
  keepComplexPrice: false,
  copyExtendedSettings: false,
  keepGrocerySetPrice: false,
}

type State = {
  checks: Checks,
  email: string,
  emailError: string,
}

type Actions = {
  setChecks: Dispatch<SetStateAction<Checks>>,
  emailChange: (e: ChangeEvent<HTMLInputElement>) => void,
  checkEmailErrors: () => boolean,
}

type Options = {
  enable: boolean,
}

export default function useOptions({ enable }: Options): [State, Actions] {
  const [checks, setChecks] = useState(defaultChecks)

  const { email: userEmail }: { email: string } = useSelector((state: any) => state.user, shallowEqual)

  const [
    [email, handleEmailChange],
    [emailError, checkEmailErrors],
  ] = useEmail({ enable, defaultEmail: userEmail })

  return [{
    checks,
    email,
    emailError,
  }, {
    setChecks,
    emailChange: handleEmailChange,
    checkEmailErrors,
  }]
}
