// @flow
import { createContext } from 'react';

import {
  type State,
  type ApiHandlers,
  defaultState,
  defaultApiHandlers,
} from './useOrgs';

export type OrgsContextType = [
  State,
  ApiHandlers,
]

const defaultContext: OrgsContextType = [
  defaultState,
  defaultApiHandlers,
];

export default createContext<OrgsContextType>(defaultContext);
