// @flow
import React from 'react';

import Footer, { useFooterData, Copyright, Stores } from 'app/common/components-next/footer';
import { VersionView } from 'app/common/components-next/service-worker';


const AppFooter = () => {
  const { company, phone, androidAppUrl, iosAppUrl } = useFooterData();

  return (
    <Footer>
      <div>
        <Copyright phone={phone} company={company} />
        <VersionView />
      </div>
      <Stores androidAppUrl={androidAppUrl} iosAppUrl={iosAppUrl} />
    </Footer>
  );
};

export default AppFooter;
