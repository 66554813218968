// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';
import * as actions from 'app/common/constants/identifiers/actions';

export const IDENTIFIER_UPDATE_REQUEST = 'employee/IDENTIFIER_UPDATE_REQUEST';
export const IDENTIFIER_UPDATE_SUCCESS = 'employee/IDENTIFIER_UPDATE_SUCCESS';
export const IDENTIFIER_UPDATE_FAILURE = 'employee/IDENTIFIER_UPDATE_FAILURE';


const getActByProc = (proc) => {
  if (proc === actions.BLOCK) return 'заблокировать';
  if (proc === actions.UNBLOCK) return 'разблокировать';
  return 'изъять';
};

export const identifierUpdateRequest = (payload: Object) => ({
  type: IDENTIFIER_UPDATE_REQUEST,
  payload,
});

export const identifierUpdateSuccess = (payload: Object) => ({
  type: IDENTIFIER_UPDATE_SUCCESS,
  payload,
});

export const identifierUpdateFailure = (payload: Object) => ({
  type: IDENTIFIER_UPDATE_FAILURE,
  payload,
});

export const identifierUpdate = (
  employeeId: number | string,
  cardId: number | string,
  proc: $Values<typeof actions>,
  reason?: string,
) => async (dispatch: Function) => {
  dispatch(identifierUpdateRequest({ employeeId, cardId }));
  const data = proc === actions.BLOCK ? { data: { reason } } : {};
  try {
    const res = await api(`food/employee/${employeeId}/card/${cardId}/${proc}`, 'put', data);
    dispatch(identifierUpdateSuccess(get(res, ['data', 'cards'], {})));
    return true;
  } catch (error) {
    const act = getActByProc(proc);
    dispatch(identifierUpdateFailure({ err: `Не удалось ${act} идентификатор`, error }));
    return false;
  }
};
