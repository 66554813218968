// @flow
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment-timezone';
import compose from 'recompose/compose';
import path from 'ramda/es/path';
import isEmpty from 'ramda/es/isEmpty';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import unnest from 'ramda/es/unnest';

import PlainNav from 'app/common/ui/PlainNav';
import Button, { ButtonsBar } from 'app/common/ui/Button';
import ModalExtended, { ModalExtendedWSearch } from 'app/common/components/ModalExtended';
import EditList, { withFlagsSelected } from 'app/common/components/EditList';
import PlainTable from 'app/common/components/PlainTable';
import Dish, { DishesTableHeader } from 'app/components/common/Dish';
import DishesKit, { DishesKitTableHeader } from 'app/components/common/DishesKit';

import withOrgsDishes from 'app/containers/OrgsList/withOrgsDishes';
import withOrgsKits from 'app/containers/OrgsList/withOrgsKits';
import withDishEditFieldsActions from 'app/containers/OrgsList/withDishEditFieldsActions';
import { withMenuMenuRights, withCurrentOrgRights } from 'app/containers/rights';
import { withWriteAccess } from 'app/common/containers/withRights';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import BuildingsData, { BuildingsView, type Building } from 'app/components/common/BuildingsData';
import OrgsDataProvider from 'app/dataProviders/org/OrgsDataProvider';
import { RowLayoutWithSticky, RowLayoutContent, Header } from 'app/common/layouts/RowLayout';
import Loading from 'app/common/components/Loader';

import { HeaderLvl2 } from 'app/common/layouts/markup';
import ControlsBar, { ControlsBarSpace } from 'app/common/ui-next/controls-bar';
import { getMonthInGenitiveCase } from 'app/i18n';
import type { OrgItem } from 'app/common/api/requests/food/schools';
import MenuTable, { MenuTableHeader } from './MenuTable';
import enhanceOrgsMenu from './enhanceOrgsMenu';
import OrgsMenuEditGear from './OrgsMenuEditGear';
import DateNavigation from '../DateNavigation';
import CopyMenuFromToOrgs from './CopyMenuFromToOrgs';
// import CopyMenuFromToSellPoints from './CopyMenuFromToSellPoints';
import useSellPointsData from '../commonHooks/useSellPointsData';
import selectItems from './MenuTable/selectItems';
import selectKits from './MenuTable/selectKits';

import styles from './OrgsMenu.scss';


const DishesTable = withOrgsDishes(withFlagsSelected(PlainTable));
const KitsTable = withOrgsKits(withFlagsSelected(PlainTable));
const LocalDish = withDishEditFieldsActions(Dish);

const writeAccess = compose(withCurrentOrgRights, withWriteAccess, withMenuMenuRights, withWriteAccess);

// const ModalAccessed = writeAccess(Modal);
const ModalExtendedWSearchAccessed = writeAccess(ModalExtendedWSearch);
const ModalExtendedAccessed = writeAccess(ModalExtended);

const CopyMenuFromToOrgsAccessed = writeAccess(CopyMenuFromToOrgs);
// const CopyMenuFromToSellPointsAccessed = writeAccess(CopyMenuFromToSellPoints);

const orgsFilterCb = (item: OrgItem): boolean => {
  const { deleted, approved } = item;
  const actual = !deleted && approved;
  return actual;
};

const Toolbar = writeAccess(() => (
  <ButtonsBar
    className={styles.buttons}
    left
  >
    <Button key="addDish" value="Добавить продукцию" href={`${window.location.pathname}/edititems`} />
    <Button key="addCmp" value="Добавить комплект" href={`${window.location.pathname}/editkits`} />
  </ButtonsBar>
));

type Props = {
  orgId: string,
  basePath: string,
  date: string,
  dateFormat: string,
  mDate: typeof moment, // deprecated
  sellPointId: string,
  redirectToDate: (day: string, format: string) => void,
  act?: string,
  returnTo: string,
  dishesState: Object,
  kitsState: Object,
  orgsMenuItemsState: Object,
  orgsMenuKitsState: Object,
  handleSubmitItems: (Array<number>, Array<number>) => void,
  handleSubmitKits: (Array<number>, Array<number>) => void,
  settings: Object,
  history: Object,
};

const OrgsMenu = ({
  orgId: sOrgId,
  basePath,
  date,
  dateFormat,
  mDate,
  sellPointId,
  redirectToDate,
  act,
  returnTo,
  dishesState,
  kitsState,
  orgsMenuItemsState,
  orgsMenuKitsState,
  handleSubmitItems,
  handleSubmitKits,
  settings,
  history,
}: Props) => {
  const orgId = parseInt(sOrgId, 10);

  const header = useMemo(() => {
    if (!mDate || !mDate.isValid()) {
      return 'Меню буфета';
    }

    const day = Number(mDate.format('D'));
    const monthNumber = Number(mDate.format('M'));
    const year = mDate.format('YYYY');

    const monthInGenitiveCase = getMonthInGenitiveCase(monthNumber);
    return `Меню буфета на ${day} ${monthInGenitiveCase} ${year}`;
  }, [mDate]);


  // Buildings
  const [selectedBuilding, setSelectedBuilding] = useState < Building | null >(null);
  const buildingId = selectedBuilding ? selectedBuilding.id : null;


  // SellPoints
  const [
    [{ itemsCurrent: sellPointsActive, sellPoints: sellPointsState }, sellPointsLoading],
  ] = useSellPointsData(orgId, selectedBuilding && selectedBuilding.id);

  const sellPoints = useMemo(() => sellPointsActive.map(({ id, title }) => ({
    id,
    title: title || '???',
    path: `${basePath}/${date}/${id}`,
  })), [basePath, date, sellPointsActive]);


  // Заголовки
  const [currentSellPointTitle, currentSellPointName] = useMemo(() => {
    const name = path([sellPointId, 'title'], sellPointsState) || '...';
    return [`Точка продаж: «${name}»`, name];
  }, [sellPointsState, sellPointId]);


  useEffect(() => {
    if (isEmpty(sellPointsState)) return;
    const notValidSellPoint = !sellPointId || !sellPointsState[sellPointId];
    if (notValidSellPoint) {
      const newSellPointId = notValidSellPoint
        ? String(path([0, 'id'], sellPointsActive))
        : sellPointId;
      history.push(`${basePath}/${date}/${newSellPointId}`);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    sellPointId,
    sellPointsState,
    sellPointsActive,
  ]);
  /* eslint-enable */

  const dishes = useSelector(selectItems);
  const kits = useSelector(selectKits);

  const allDishesIds = useMemo(() => {
    return map(prop('id'), unnest(dishes));
  }, [dishes]);

  const allKitsIds = useMemo(() => {
    return map(prop('id'), kits);
  }, [kits]);

  // ждем права
  const { loading } = useSelector(state => state.orgs.current, shallowEqual);

  if (loading) {
    return (
      <Loading />
    );
  }

  const sellPointsNav = (
    sellPoints.length > 1
      ? (<PlainNav
        items={sellPoints}
        title="Точки продаж"
        className={styles.nav}
        loading={sellPointsLoading}
        scrollable
      />)
      : null
  );

  const copyMenuFromToOrgs = (
    <CopyMenuFromToOrgsAccessed
      orgId={parseInt(orgId, 10)}
      buildingId={buildingId}
      sellPointId={parseInt(sellPointId, 10) || null}
      date={date}
    >
      {toggleModal => (<PlainButtonAsLink onClick={toggleModal}>
        {'Копирование меню'}
      </PlainButtonAsLink>)}
    </CopyMenuFromToOrgsAccessed>
  );

  return (
    <BuildingsData
      orgId={orgId}
      selectorModalTitle="Выберите корпус ОУ"
      onChangeSelected={setSelectedBuilding}
    >
      <OrgsDataProvider 
        orgId={orgId} 
        filterCb={orgsFilterCb}
        sameType
      >
        <RowLayoutWithSticky
          number={3}
          content={
            <RowLayoutContent borderBottom flex>
              <Header>{header}</Header>
              <BuildingsView title="Корпус ОУ:" className={styles.buildingsView} />

              <DateNavigation
                date={date}
                dateFormat={dateFormat}
                onDateChange={redirectToDate}
              />
            </RowLayoutContent>
          }
        >
          <RowLayoutContent className={styles.contentShift}>

            {sellPoints.length > 1
              ? <>
                <ControlsBar className={styles.navContainer} top>
                  {sellPointsNav}
                  {copyMenuFromToOrgs}
                </ControlsBar>
                <HeaderLvl2>{currentSellPointTitle}</HeaderLvl2>
              </>
              : <ControlsBar>
                <HeaderLvl2>{currentSellPointTitle}</HeaderLvl2>
                <ControlsBarSpace />
                {copyMenuFromToOrgs}
              </ControlsBar>}

          </RowLayoutContent>

          <RowLayoutContent className={styles.contentShift} paddingfree>
            <Toolbar />

            <MenuTableHeader
              orgId={orgId}
              sellPointId={parseInt(sellPointId, 10)}
              sellPointTitle={currentSellPointName}
              date={date}
              dishesToRemove={allDishesIds}
              kitsToRemove={allKitsIds}
            />

            <MenuTable
              orgId={sOrgId}
              date={date}
              sellPointId={sellPointId}
              items={dishes}
              kits={kits}
            />
          </RowLayoutContent>

          {/* Добавление/удаление блюд в меню */}
          <ModalExtendedWSearchAccessed
            isOpen={act === 'edititems'}
            closePath={returnTo}
            className={styles.addremove}
            header="Редактирование меню буфета школы — продукция"
            extra={<OrgsMenuEditGear />}
          >
            {searchString => (<EditList
              items={dishesState}
              existingItems={orgsMenuItemsState.items}
              closePath={returnTo}
              submitBtTitle="Сохранить в меню"
              alreadyLegendLabel="Продукция уже в меню буфета"
              selectedLegendLabel="Продукция будет добавлена"
              onSubmit={handleSubmitItems}
              tooltipText="Необходимо выбрать/удалить хотя бы одну продукцию"
            >
              {(
                addList,
                removeList,
                addToSelection,
                removeFromSelection,
                addToSelectionAll,
                removeFromSelectionAll,
                selectedAll,
              ) => (<DishesTable
                orgId={orgId}
                header={DishesTableHeader}
                searchString={searchString}
                existingItems={orgsMenuItemsState.items}
                addList={addList}
                removeList={removeList}
                settings={settings}
                emptyWarn="Нет продукции, удовлетворяющей условиям"
                selectedAll={selectedAll}
                onSelectAll={addToSelectionAll}
                onUnselectAll={removeFromSelectionAll}
              >
                {(itemProps, isLoading) => (<LocalDish
                  {...itemProps}
                  locked
                  settings={settings}
                  loading={isLoading}
                  dishesAddToSelection={addToSelection}
                  dishesRemoveFromSelection={removeFromSelection}
                />)}
              </DishesTable>)}
            </EditList>)}
          </ModalExtendedWSearchAccessed>

          {/* Добавление/удаление комплектов в меню */}
          <ModalExtendedAccessed
            isOpen={act === 'editkits'}
            closePath={returnTo}
            className={styles.addremove}
            header="Редактирование меню школы — комплекты"
          >
            <EditList
              items={kitsState}
              existingItems={orgsMenuKitsState}
              closePath={returnTo}
              submitBtTitle="Сохранить в меню"
              alreadyLegendLabel="Комплект уже в меню"
              selectedLegendLabel="Комплект будет добавлен"
              onSubmit={handleSubmitKits}
              tooltipText="Необходимо выбрать/удалить хотя бы один комплект"
            >
              {(
                addList,
                removeList,
                addToSelection,
                removeFromSelection,
                addToSelectionAll,
                removeFromSelectionAll,
                selectedAll,
              ) => (<KitsTable
                orgId={orgId}
                date={date}
                header={DishesKitTableHeader}
                existingItems={orgsMenuKitsState}
                addList={addList}
                removeList={removeList}
                emptyWarn="Нет комплектов, удовлетворяющих условиям"
                selectedAll={selectedAll}
                onSelectAll={addToSelectionAll}
                onUnselectAll={removeFromSelectionAll}
                skipEmpty
              >
                {(itemProps, isLoading) => (<DishesKit
                  {...itemProps}
                  loading={isLoading}
                  kitAddToSelection={addToSelection}
                  kitRemoveFromSelection={removeFromSelection}
                />)}
              </KitsTable>)}
            </EditList>
          </ModalExtendedAccessed>

        </RowLayoutWithSticky>
      </OrgsDataProvider>
    </BuildingsData>
  );
};

export default enhanceOrgsMenu(OrgsMenu);
