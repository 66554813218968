// @flow
import { path } from 'ramda';

import { getStateItemsAndCurrent, type State } from 'app/common/ui-components/filter';
import { dateFormat } from 'app/common/api/requests/reports/schoolyears_list';

import { filterId } from './filterId';


export { dateFormat };

const getCurrentYearPeriod = (state: State): [?string, ?string] => {
  const [items, currentYearState] = getStateItemsAndCurrent(filterId, state) || [];
  if (currentYearState) {
    const [yearId] = currentYearState;
    const from = path([yearId, 'date_start'], items);
    const to = path([yearId, 'date_finish'], items);
    if (from && to) {
      return [from, to];
    }
  }

  return [null, null];
};

export default getCurrentYearPeriod;
