import { ReactNode, useCallback, useMemo } from 'react'
import { reduce } from 'ramda'

import { HandleModalDisplayed } from 'app/common/ui-next/plain-modal'
import PlainItemsModal from 'app/common/ui-next/plain-selector/plain-items-modal'
import { OrgsData } from 'app/dataTypes/org'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


type Item = {
  id: string,
  title: string,
  disabled: boolean,
  hasGroup: boolean,
}

type Props = {
  ID: string
  children: (x: HandleModalDisplayed) => ReactNode
  orgsData: OrgsData
  onAddRemoveOrgs: (orgGroupID: string, addOrgs: Array<string>, removeOrgs: Array<string>) => void
}

const OrgGroupFilling = ({
  ID,
  children,
  orgsData,
  onAddRemoveOrgs,
}: Props) => {
  const onAddRemoveOrgsRef = useCallbackRef(onAddRemoveOrgs)

  const { items, selected } = useMemo(() => {
    return reduce((acc, { ID: id, title, orgGroupID }) => {
      const item = {
        id,
        title,
        disabled: false,
        hasGroup: false, // only for handleAddRemove
      }
      if (orgGroupID) {
        acc.selected.push(id)
        item.hasGroup = true
        item.disabled = orgGroupID !== ID
      }
      acc.items.push(item)
      return acc
    }, {
      items: [] as Array<Item>,
      selected: [] as Array<string>,
    }, Array.from(orgsData.items.values()))
  }, [orgsData, ID])

  const handleAddRemove = useCallback(async (orgIDs: Array<string>) => {
    const orgIDsSet = new Set(orgIDs)
    const { add, remove } = reduce((acc, val) => {
      const { id, disabled, hasGroup } = val
      if (!disabled) {
        if (hasGroup && !orgIDsSet.has(id)) {
          acc.remove.push(id)
        }
        if (!hasGroup && orgIDsSet.has(id)) {
          acc.add.push(id)
        }
      }
      return acc
    }, {
      add: [] as Array<string>,
      remove: [] as Array<string>,
    }, items)

    onAddRemoveOrgsRef.current?.(ID, add, remove)
  }, [ID, onAddRemoveOrgsRef, items])

  return (
    <PlainItemsModal
      onSubmit={handleAddRemove}
      header="Список заведений"
      items={items}
      itemsSelected={selected}
      multiple
    >
      {children}
    </PlainItemsModal>
  )
}

export default OrgGroupFilling
