// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { RadioButtonGroup } from 'app/common/ui/RadioButton';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';
import { options as opt } from 'app/actions/reports/filters/mail';
import { DotsLoader } from 'app/common/components/Loader';
import type { SendTo } from 'app/actions/reports/filters/mail';

import withMail from './hocs/withMail';
import styles from './FilterItems.scss';


const txtHeader = 'Отправить отчет на';
const txtNeedEmail = 'В профиле e-mail не указан. ';

type Props = {
  sendTo: SendTo,
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  email: string,
  options: Array<[string, string]>,
  loading: boolean,
  isPack: boolean,
  disabledById: {
    [key: string]: boolean,
  }
}

const MailFilter = ({
  sendTo,
  handleChange,
  email,
  options,
  loading,
  isPack,
  disabledById = {},
}: Props) => (loading

  ? <div className={styles.filter}>
    <div className={styles.label}>
      {txtHeader}
      &nbsp;&nbsp;&nbsp;
      <DotsLoader simple />
    </div>
  </div>

  : <div className={styles.filter}>
    <div className={styles.label}>
      {txtHeader}
      {email
        ? <span className={styles.email}>{` ${email}:`}</span>
        : ' email:'}
    </div>
    <RadioButtonGroup
      id="select_mail"
      name="select_mail"
      valueSelected={sendTo}
      onChange={handleChange}
      className={styles.radioGroup}
    >
      {options.map(([id, label]) => (<PlainRadioButton
        name={id}
        key={id}
        label={label}
        value={id}
        disabled={
          (!email && id !== opt.DO_NOT_MAIL)
            || (isPack && id === opt.DO_NOT_MAIL)
            || !!disabledById[id]
        }
      />))}
    </RadioButtonGroup>

    {!email
      && <div className={styles.comment}>
        <span className={styles.warning}>{txtNeedEmail}</span>
        <Link to="/profile" className={styles.link}>{'Перейти в профиль'}</Link>
      </div>}
  </div>
);

export default withMail(MailFilter);
