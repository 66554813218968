// @flow
import prop from 'ramda/es/prop';
import api from 'app/actions/api';

export const ORGS_REQUEST = 'ORGS_REQUEST';
export const ORGS_SUCCESS = 'ORGS_SUCCESS';
export const ORGS_FAILURE = 'ORGS_FAILURE';

/**
* Получение списка образовательных организаций
*/

export const orgsRequest = () => ({
  type: ORGS_REQUEST,
});

export const orgsSuccess = (payload: Object) => ({
  type: ORGS_SUCCESS,
  payload,
});

export const orgsFailure = (payload: Object) => ({
  type: ORGS_FAILURE,
  payload,
});

type ParamsProps = {
  limit?: number,
  offset?: number,
  'include[]'?: string | Array<string>,
};

export const orgsFetch = (params?: ParamsProps) => async (dispatch: Function) => {
  dispatch(orgsRequest());
  try {
    const res = await api('food/schools', 'get', params ? { params } : {});
    dispatch(orgsSuccess(prop('data', res)));
    return true;
  } catch (error) {
    dispatch(orgsFailure({ err: 'Запрос образовательных организаций не удался', error }));
    return false;
  }
};
