/* eslint-disable ft-flow/no-types-missing-file-annotation */
import React from 'react';
import moment from 'moment-timezone';

import type { ModifyInfoType } from 'app/reducers/employees/employee/settings';
import noDatenoRender from '../noDateNoRender';
import Label from '../Label';
import Big from '../Big';


type Props = {
  title: string,
  info: ModifyInfoType,
  className?: string,
}

const IpInfo = noDatenoRender(({
  title,
  info: { datetime, ip },
  className,
}: Props) => (<div className={className}>
  <div><Label general>{title}</Label></div>
  <div>
    <Big>{moment(datetime).format('D.MM.YYYY HH:mm')}</Big>
    {ip && <span>
      {' c '}
      <Big>
        {'IP: '}
        {ip}
      </Big>
    </span>}
  </div>
</div>));

export default IpInfo;
