// @flow
import { useCallback, useState } from 'react';

import { api } from 'app/api';
import { GET_CAFETERIA_ORDERS_DETAILS, type Sale } from 'app/common/api/requests/food/schools/cafeteria_orders';


type Options = {
  orgId: number,
}

type State = Array<Sale>

const defaultState: State = [];

type Actions = {
  request: (orderId: number) => Promise<void> | void,
}

export default function useProductList({ orgId }: Options): [State, Actions] {
  const [productList, setProductList] = useState<State>(defaultState);

  const handleRequest = useCallback(async (orderId: number) => {
    const res = await api.request(GET_CAFETERIA_ORDERS_DETAILS, {
      error: 'Не удалось получить подробные данные заказа',
      requestPathParams: {
        orgId: String(orgId),
        orderId: String(orderId),
      },
    });

    if (res) {
      setProductList(res);
    }
  }, [orgId]);


  return [productList, {
    request: handleRequest,
  }];
}
