// @flow
import type { Request } from '../../index';


export type ProceedImportFileParams = {
  file_id: number,
  email: string,
  headers: boolean, // посторонние заголовки в файле
  data_types: Array<string | null>, // типы данных
  options: {
    all_schools: boolean, // сохранить во всех школах
    all_menu_single: boolean, // сохранить в меню, применяя к продукции
    all_menu_complex: boolean // сохранить в меню, применяя к комплектам
  }
}

export type ProceedImportFileResult = 'success'

export const POST_PROCEED_IMPORT_FILE: Request<
  ProceedImportFileParams,
  ProceedImportFileResult
> = [
  'food/items/proceed_import_file',
  'post',
  ['data', 'result'],
];
