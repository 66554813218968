// @flow
import { useRef, useEffect, type Ref } from 'react';


export default function useCombinedRefs(...refs: Array<?Ref<any>>) {
  const targetRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else if (typeof ref === 'object') {
        ref.current = targetRef.current; // eslint-disable-line no-param-reassign
      }
    });
  }, [refs]);

  return targetRef;
}
