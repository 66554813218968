// @flow
export const BALANCETYPES_FILTER_INIT = 'reports/BALANCETYPES_FILTER_INIT';
export const BALANCETYPES_FILTER_CLEAR = 'reports/BALANCETYPES_FILTER_CLEAR';
export const BALANCETYPES_FILTER_DELETE = 'reports/BALANCETYPES_FILTER_DELETE';
export const BALANCETYPES_FILTER_SET = 'reports/BALANCETYPES_FILTER_SET';


const data = {
  neg: {
    id: 'neg',
    title: 'Отрицательный',
  },
  zero: {
    id: 'zero',
    title: 'Нулевой',
  },
  pos: {
    id: 'pos',
    title: 'Положительный',
  },
};

export const balanceTypesFilterInit = () => ({
  type: BALANCETYPES_FILTER_INIT,
  payload: data,
});

export const balanceTypesFilterClear = () => ({
  type: BALANCETYPES_FILTER_CLEAR,
});

export const balanceTypesFilterDelete = (payload: Array<string | number>) => ({
  type: BALANCETYPES_FILTER_DELETE,
  payload,
});

export const balanceTypesFilterSet = (payload: Object) => ({
  type: BALANCETYPES_FILTER_SET,
  payload,
});
