// @flow
import moment from 'moment-timezone';
import values from 'ramda/es/values';
import find from 'ramda/es/find';
import comparator from 'ramda/es/comparator';
import last from 'ramda/es/last';
import sort from 'ramda/es/sort';
import pipe from 'ramda/es/pipe';
import isEmpty from 'ramda/es/isEmpty';

import type { Request } from '../index';


export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

export type SchoolyearsParams = {
  school_id: number,
}

export type SchoolyearItem = {
  id: number,
  title: string,
  date_start: string,
  date_finish: string,
}

export type SchoolyearsResult = {
  [id: string]: SchoolyearItem
}

export const GET_SCHOOLYEARS: Request<
  SchoolyearsParams,
  SchoolyearsResult,
  void
> = [
    'reports/schoolyears_list',
    'get',
    ['data', 'schoolyears'],
  ];

export const getCurrentSchoolYear = (res: SchoolyearsResult): SchoolyearItem | null => {
  if (isEmpty(res)) {
    return null;
  }
  const itemsArray = values(res);
  const byToday = find(
    ({ date_start: dateStart, date_finish: dateFinish }) => (
      moment().isBetween(dateStart, dateFinish, null, '[]')
    ),
    itemsArray,
  );
  const currentYear = byToday || pipe(
    sort(comparator((a, b) => moment(a.date_finish).isBefore(b.date_finish))),
    last,
  )(itemsArray);

  return currentYear;
};
