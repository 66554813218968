// @flow
import React, { useEffect } from 'react';

import SideNavigation, {
  SideNavigationContainer,
  SideNavigationTargetContent,
  SideNavigationItem,
} from 'app/common/ui-components/side-navigation';

import CSSSticky from 'app/common/ui-components/css-sticky';
import PlainIcon from 'app/common/ui-next/plain-icon';

import { sections, useAvailableSections, getSectionTitle, getSectionIcon } from './sections';
import AuthorizationDataSection from './AuthorizationDataSection/AuthorizationDataSection';
import LinkedUsers from './LinkedUsers/LinkedUsers';

import styles from './Profile.scss';


type Props = {
  baseUrl: string,
  currentSection: string,
  redirectToDefault: () => void,
}

const Profile = ({ baseUrl, currentSection, redirectToDefault }: Props) => {
  const availableSections = useAvailableSections();

  useEffect(() => {
    if (currentSection && !availableSections.includes(currentSection)) {
      redirectToDefault();
    }
  }, [currentSection, availableSections]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SideNavigationContainer>
      <CSSSticky number={2} className={styles.stickySide}>

        <SideNavigation>
          {availableSections.map(s => (
            <SideNavigationItem
              key={s}
              title={getSectionTitle(s)}
              to={`${baseUrl}/${s}`}
              active={currentSection === s}
            >
              <PlainIcon type={getSectionIcon(s)} />
            </SideNavigationItem>
          ))}
        </SideNavigation>

      </CSSSticky>
      <SideNavigationTargetContent>

        {currentSection === sections.AUTHORIZATION_DATA
          && <AuthorizationDataSection
            header={getSectionTitle(currentSection)}
            baseUrl={`${baseUrl}/${currentSection}`}
          />}

        {currentSection === sections.LINKED_USERS
          && <LinkedUsers
            header={getSectionTitle(currentSection)}
          />}

      </SideNavigationTargetContent>
    </SideNavigationContainer>
  );
};

export default Profile;
