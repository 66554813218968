// @flow
import React, { type Node } from 'react';

import styles from './legend-pointer.scss';


type Props = {
  children: Node,
}

const LegendPointer = ({ children }: Props): Node => {
  return (
    <div className={styles.root}>
      <div className={styles.arrow} />
      {children}
    </div>
  );
};

export default LegendPointer;
