// @flow
import React from 'react';

import IconLightCross from 'app/common/icons/IconLightCross';
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import useKitDelete from './useKitDelete';
import styles from '../MenuTable.scss';


type Props = {
  id: number,
  orgId: number,
  date: string,
  sellPointId: number,
  title: string,
  cost: string,
  className?: string,
  writeAccess: boolean
}

const MenuItemKit = ({ id, title, orgId, date, sellPointId, cost, className, writeAccess }: Props) => {
  const deleteKit = useKitDelete({ kitId: id, orgId, date, sellPointId });

  return (
    <div className={className}>
      <div>{title}</div>
      <div>&nbsp;</div>
      <div className={styles.right}>{cost}</div>
      <div>
        {writeAccess
          && <ConfirmationDialog
            onConfirm={deleteKit}
            text={`Вы уверены, что хотите удалить «${title}» из меню?`}
            confirmButtonText="Удалить"
            confirmButtonProps={{ error: true }}
          >
            {handleConfirm => (<PlainButtonAsLink onClick={handleConfirm}>
              <span className="visuallyHidden">{'Удалить'}</span>
              <IconLightCross />
            </PlainButtonAsLink>)}
          </ConfirmationDialog>}
      </div>
    </div>
  );
};

export default MenuItemKit;
