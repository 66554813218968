// @flow
import React, { Fragment, useState, useCallback } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import Loader from 'app/common/components/Loader';
import { useCurrencySign } from 'app/common/components-next/currency';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import { fundTypes } from 'app/common/api/requests/reports/perform';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';
import EmptyReportCSS from 'app/components/common/reports/EmptyReportCSS';
import ErrorReportCSS from 'app/components/common/reports/ErrorReportCSS';
import HistoryLink, { reportTypes } from 'app/components/common/reports/history/HistoryLink';

import HeaderBar from './HeaderBar';
import Summary from './Summary';
import useReportData, { type ReportData } from './useReportData';
import DetailsBudget from './DetailsBudget';
import DetailsParents from './DetailsParents';

import styles from './Report.scss';


const canBeData = (reportData: ReportData) => ({
  summary: null,
  details: null,
  funds: null,
  pending_orders_exists: false,
  ...reportData,
});

type Props = {
  reportId: number | null,
}

const Report = ({ reportId }: Props) => {
  const currency = useCurrencySign();

  const [fs, changeFullScreen] = useState(false);
  const toggleFS = useCallback((e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    changeFullScreen(fullscreen => !fullscreen);
  }, []);

  const [data, loading] = useReportData(reportId);
  const { summary, details, funds, pending_orders_exists: pendingOrderExist } = canBeData(data);

  const nothing = !(summary || details) || isEmpty(details);

  if (pendingOrderExist) {
    return (
      <ErrorReportCSS
        stickyNumber={2}
      />
    );
  }

  if (!reportId) {
    return (
      <EmptyReportCSS
        stickyNumber={2}
        headerBar={
          <HistoryLink reportType={reportTypes.REPORT_PURCHASES_BY_FUNDS} />
        }
      />
    );
  }

  return (
    <ReportSectionCSS
      stickyNumber={2}
      header="Отчет «Продажи по видам средств»"
      headerBar={
        <HeaderBar
          onFullscreen={toggleFS}
          reportId={reportId}
        />
      }
      fullscreen={fs}
      wideContent
    >
      {loading
        ? <Loader />

        : <Fragment>
          {summary
            && <Summary
              summary={summary}
              loading={loading}
              currencySign={currency}
            />}

          {/* Далее таблицы с детализациями */}
          {details
            && <DetailsHeader reportTitle="Продажи по видам средств" />}

          {details
            && !nothing
            && funds === fundTypes.FUND_BUDGET
            // $FlowFixMe
            && <DetailsBudget details={details} />}

          {details
            && !nothing
            && funds === fundTypes.FUND_PARENTS
            // $FlowFixMe
            && <DetailsParents details={details} />}

          {/* Нет обозначенных данных */}
          {nothing
            && <RowLayoutContent borderTop paddingfree>
              <EmptyWarn className={styles.empty}>
                {'Данные отсутствуют'}
              </EmptyWarn>
            </RowLayoutContent>}

        </Fragment>}
    </ReportSectionCSS>
  );
};

export default Report;
