import { createSelector } from 'reselect';
import values from 'ramda/es/values';
import omit from 'ramda/es/omit';
import pipe from 'ramda/es/pipe';


const selectRoles = createSelector(
  [
    state => state.admin.roles,
  ],
  pipe(
    omit(['expired']),
    values,
  ),
);

export default selectRoles;
