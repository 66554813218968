import { OrgsLimitsType } from 'app/dataTypes/org/limits'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'


export const defaultLimits: OrgsLimitsType = {
  cafeteriaLimitsReadonlyForSchools: false,
  maxCafeOrderMinus: 0,
  maxCafeMinus: 0,
  cafeDayLimit: 0,
  maxCampCafeOrderMinus: 0,
  splitStudentDayLimit: false,
}

type Options = {
  limits: OrgsLimitsType | null
}

export const useChangeState = ({ limits }: Options) => {
  const [modified, setModified] = useState<boolean>(false)
  const [data, setData] = useState<OrgsLimitsType>(defaultLimits)

  const handleUnModify = useCallback(() => setModified(false), [])

  const asKeyOfLimits = useCallback((key: string): keyof OrgsLimitsType | null => {
    return (
      limits && limits[(key as keyof OrgsLimitsType)] !== undefined
        ? key as keyof OrgsLimitsType
        : null
    )
  }, [limits])

  const handleClickCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>, name: keyof OrgsLimitsType) => {
    const { checked } = e.currentTarget
    setData(prev => ({ ...prev, [name]: checked }))
    setModified(true)
  }, [])

  const handleChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    const limitKey = asKeyOfLimits(name)
    if (limitKey) {
      const val = parseInt(value, 10)
      if (value === '' || !Number.isNaN(val)) {
        const curValue = value === '' ? '' : val
        setData(prevData => (prevData ? { ...prevData, [limitKey]: curValue } : prevData))
        setModified(true)
      }
    }
  }, [asKeyOfLimits])

  useEffect(() => {
    if (limits) {
      setData(limits)
      setModified(false)
    }
  }, [limits])

  return {
    data,
    modified,
    handleUnModify,
    changeCheckbox: handleClickCheckbox,
    changeInput: handleChangeInput,
  }
}
