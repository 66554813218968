// @flow
import React, { useMemo, useState, useCallback, useEffect, type Node } from 'react';
import values from 'ramda/es/values';
import prop from 'ramda/es/prop';
import isEmpty from 'ramda/es/isEmpty';
import head from 'ramda/es/head';

import Modal, { ModalHeader, ModalContent } from 'app/common/components/Modal';
import PlainButton from 'app/common/ui-next/plain-button';
import { useSettings } from 'app/common/components-next/settings-data';

import BuildingsContext from './BuildingsContext';
import useBuildingsData from './useBuildingsData';
import type { Building } from './BuildingsTypes';
import styles from './BuildingsData.scss';


type Props = {
  children: Node,
  orgId: number,
  selectorModalTitle: string,
  onChangeSelected?: (selected: Building) => void,
}

const BuildingsData = ({ children, orgId, selectorModalTitle, onChangeSelected }: Props) => {
  const [savingSettings, setSavingSettings] = useState(false);
  const [[settings, loadingSettings], settingsActions] = useSettings('common');


  const [showSelector, setShowSelector] = useState(false);
  const [selected, setSelected] = useState<Building | null>(null);

  const [buildings, loadingBuildings] = useBuildingsData(orgId);

  const loading = loadingSettings || loadingBuildings;

  // обновление данных корпусов
  // назначение текущим
  useEffect(() => {
    if (loading || isEmpty(buildings)) return;
    const savedBuildingId = settings.common.currentBuildingId;
    const savedBuilding = buildings[String(savedBuildingId)];

    const newSelected = savedBuilding || head(values(buildings));
    if (newSelected) {
      setSelected(newSelected);
      if (onChangeSelected) {
        onChangeSelected(newSelected);
      }
    }
  }, [loading, settings, onChangeSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggleSelector = useCallback(() => {
    setShowSelector(state => !state);
  }, []);


  const handleSelect = useCallback(async (e: SyntheticEvent<HTMLElement>) => {
    if (savingSettings) return;

    const { id } = e.currentTarget;
    const newBuilding = prop(id, buildings) || null;

    if (newBuilding) {
      setSavingSettings(true);
      await settingsActions.saveSettingsKeyValue('common', 'currentBuildingId', newBuilding.id);
      setSavingSettings(false);
      setSelected(newBuilding);
      if (onChangeSelected) {
        onChangeSelected(newBuilding);
      }
      handleToggleSelector();
    }
  }, [buildings, handleToggleSelector, onChangeSelected, settingsActions, savingSettings]);


  const contextValue = useMemo(() => ({
    buildings,
    loading,
    selected,
    toggleSelector: handleToggleSelector,
  }), [
    buildings,
    loading,
    selected,
    handleToggleSelector,
  ]);


  return (
    <BuildingsContext.Provider value={contextValue}>
      {children}
      <Modal isOpen={showSelector} toggleModal={handleToggleSelector}>
        <ModalHeader className={styles.header}>{selectorModalTitle}</ModalHeader>
        <ModalContent className={styles.content}>
          {values(buildings).map(({ id, title: buildingName }: Building) => (
            <PlainButton
              key={id}
              id={String(id)}
              className={styles.button}
              onClick={handleSelect}
              disabled={Boolean(selected && id === selected.id)}
              outline
            >
              {buildingName}
            </PlainButton>
          ))}
        </ModalContent>
      </Modal>
    </BuildingsContext.Provider>
  );
};

export default BuildingsData;
