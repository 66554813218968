import { useCallback, useEffect, useState } from 'react'

import { api } from 'app/api'
import { GET_ORG_GROUPS, convertParams, convertResult } from 'app/common/api/requests/food/school_groups/get'
import { OrgGroupsByOrgType } from 'app/dataTypes/org/orgGroup'


export default function useOrgGroups() {
  const [loading, setLoading] = useState(true)

  const [orgGroupsByOrgType, setOrgGroupsByOrgType] = useState({} as OrgGroupsByOrgType)

  const request = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_ORG_GROUPS, {
      error: 'Не удалось получить данные групп заведений',
      convertions: {
        convertParams,
        convertResult,
      },
    })
    setLoading(false)

    if (res) {
      setOrgGroupsByOrgType(res)
    }
  }, [])

  useEffect(() => {
    request()
  }, [request])

  return {
    orgGroupsByOrgType,
    loading,
    request,
    updateOrgGroups: setOrgGroupsByOrgType,
  }
}
