
// @flow
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import last from 'ramda/es/last';
import map from 'ramda/es/map';
import isEmpty from 'ramda/es/isEmpty';
import prop from 'ramda/es/prop';
import filter from 'ramda/es/filter';
import propEq from 'ramda/es/propEq';
import pipe from 'ramda/es/pipe';
import keys from 'ramda/es/keys';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray, isSingle } from 'app/common/lib/object';

import {
  orgsFilterFetch,
  orgsFilterClear,
  // orgsFilterDelete,
  orgsFilterSet,
} from 'app/actions/reports/filters/orgs';

import styles from './FilterItems.scss';


const emptyObj = {};

type Props = {
  resetStatus: boolean,
  skipRequestData?: boolean,
}

const OrgsFilter = ({ resetStatus, skipRequestData }: Props) => {
  const dispatch = useDispatch();
  const items = useSelector(state => state.reports.filters.orgs.data, shallowEqual);
  const selectedItems = useSelector(state => state.reports.filters.orgs.selected, shallowEqual);
  const selectedOrgsTypes = useSelector(state => state.reports.filters.orgsTypes.selected, shallowEqual);
  const selectedArr = mapObjPropToArray('id')(selectedItems);
  const loading = useSelector(state => state.reports.filters.orgs.loading, shallowEqual);

  const isEnabled = !isEmpty(selectedOrgsTypes);
  const currentOrgsType = isEnabled ? keys(selectedOrgsTypes)[0] : null;

  const itemsObj = useMemo(() => pipe(
    filter(propEq('type', currentOrgsType)), // фильтр по типу заведения
    map(({ id, title, periods }) => {
      const deleted = periods && !isEmpty(periods) && prop('to', last(periods));
      return ({
        id,
        title: `${title}${deleted ? ' (удалена)' : ''}`,
        periods, // нужно оставить, используется в фильтре DateFilter
      });
    }),
  )(items), [items, currentOrgsType]);


  const handleReset = useCallback(() => {
    dispatch(orgsFilterSet(emptyObj));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(orgsFilterSet(submitted));
  }, [dispatch]);


  // автодобавление единственного заведения в выбранные
  useEffect(() => {
    if (isSingle(itemsObj)) {
      handleChange(itemsObj);
      return;
    }
    handleReset();
  }, [itemsObj]); // eslint-disable-line react-hooks/exhaustive-deps


  // загрузка данных
  useEffect(() => {
    const clearData = () => {
      dispatch(orgsFilterClear());
    };
    if (!skipRequestData) return clearData;

    dispatch(orgsFilterFetch());

    return clearData;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // начальная инициализация после загрузки данных
  useEffect(() => {
    if (!loading) {
      handleReset();
      if (isSingle(items)) { // если заведение одно, то сразу его выбираем
        dispatch(orgsFilterSet(items));
      }
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps


  // сброс по внешнему изменению resetStatus
  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE
   */

  useEffect(() => {
    if (!isEnabled) {
      handleReset();
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!isEnabled) {
    return null;
  }

  return (
    <PlainFilterComplete
      id="orgs"
      className={styles.filter}
      title="Заведение:"
      modalTitle="Выбор заведения"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll={!isEmpty(itemsObj)}
      loading={loading}
      emptyListMessage="Отсутствуют заведения выбранного типа"
    />
  );
};

export default OrgsFilter;
