// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { withTranslation } from 'react-i18next';
import { profileClearKey, lockedTimeToProfile } from 'app/common/actions/profile/password';
import { phoneCode, changePhoneProcess } from 'app/common/actions/profile/phone';

export default compose(
  withTranslation('profile'),
  connect(
    (state, { t }) => ({
      requesting: state.profile.requesting,
      saving: state.profile.saving,
      resultTo: state.profile.resultTo
        && t(/@/.test(state.profile.resultTo) ? 'code_sent_to_email' : 'code_sent_to_phone', {
          target: state.profile.resultTo,
        }),
      requestCodeError: state.profile.requestCodeError,
      codeError: state.profile.codeError,
      lockedUntil: state.profile.lockedUntil,
      phone: state.user.phone,
    }),
  ),


  withState('requestCode', 'changeRequestCode', ''),
  withState('savedValue', 'changeSavedValue', ''), // сохраняем для запроса
  withState('code', 'changeCode', ''),
  withState('requestLocked', 'changeRequestLocked', 0),


  withHandlers({
    // Изменение полей
    change: ({
      dispatch,
      changeRequestCode,
      changeCode,
      requestCodeError,
      codeError,
    }) => (e, clearedValue) => {
      const { name, value } = e.target;
      switch (name) {
        case 'requestcode': {
          changeRequestCode(clearedValue);
          if (requestCodeError) {
            dispatch(profileClearKey('requestCodeError'));
          }
          break;
        }
        case 'code': {
          changeCode(value);
          if (codeError) {
            dispatch(profileClearKey('codeError'));
          }
          break;
        }
        default:
      }
    },

    // Запрос кода
    request: ({ dispatch, requestCode, changeSavedValue, requestCodeError }) => (e) => {
      e.preventDefault();
      changeSavedValue(requestCode);
      if (requestCodeError) {
        dispatch(profileClearKey('requestCodeError'));
      }
      dispatch(phoneCode(requestCode));
    },

    // Запррос на смену
    submit: ({ dispatch, code, savedValue, close }) => (e) => {
      e.preventDefault();
      dispatch(changePhoneProcess(savedValue, code, () => {
        close();
      }));
    },
  }),


  lifecycle({
    componentDidMount() {
      this.props.dispatch(lockedTimeToProfile());
      if (this.props.lockedUntil) {
        const time = this.props.lockedUntil - Date.now();
        this.props.changeRequestLocked((time > 0) ? Math.round(time / 1000) : 0);
      }
      this.timer = setInterval(() => {
        const t = this.props.lockedUntil - Date.now();
        // console.log('interval:', this.props.lockedUntil, Date.now(), t, this.props.requestLocked);
        this.props.changeRequestLocked((t > 0) ? Math.round(t / 1000) : 0);
      }, 1000);
    },

    // $FlowFixMe
    componentDidUpdate(prevProps) {
      const { lockedUntil, changeRequestLocked } = this.props;
      if (lockedUntil !== prevProps.lockedUntil) {
        const time = lockedUntil - Date.now();
        // console.log('willreceive:', time, this.props.requestLocked);
        changeRequestLocked((time > 0) ? Math.round(time / 1000) : 0);
      }
    },

    componentWillUnmount() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
  }),

);
