// @flow
import React from 'react';

import PlainRadioButton from 'app/common/ui-next/plain-radio-button';
import { getTitle, lang } from 'app/actions/reports/filters/group';

import withGroup from './hocs/withGroup';
import styles from './FilterItems.scss';


type Props = {
  group: $Keys<typeof lang>,
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  pair: Array<$Keys<typeof lang>>
}

const GroupFilter = ({ group, handleChange, pair }: Props) => (
  <div className={styles.filter}>
    <div className={styles.label}>{'Группировать по:'}</div>
    <div className={styles.ingroup}>
      {pair.map(key => (
        <PlainRadioButton
          key={key}
          name={key}
          label={getTitle(key)}
          checked={group === key}
          value={key}
          onChange={handleChange}
        />
      ))}
    </div>
  </div>
);

export default withGroup(GroupFilter);
