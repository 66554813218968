// @flow
import React, { type Node } from 'react';

import RightsByOrgContext from './RightsByOrgContext';
import useRightsByOrg from './useRightsByOrg';


type Props = {
  children: Node,
}

const RightsByOrgProvider = ({ children }: Props) => {
  const value = useRightsByOrg();

  return (
    <RightsByOrgContext.Provider value={value}>
      {children}
    </RightsByOrgContext.Provider>
  );
};

export default RightsByOrgProvider;
