// @flow
import * as React from 'react';


type Props = {
  className?: string,
  onClickOutside: (e: SyntheticEvent<HTMLElement>) => void,
  children: any,
}

export default class ClickOutside extends React.Component<Props> {
  constructor() {
    super();
    this.refC = this.refC.bind(this);
    this.handle = this.handle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handle, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handle, true);
  }

  refC(ref: React.ElementRef<'span'> | null) {
    this.container = ref;
  }

  handle(e: SyntheticEvent<HTMLElement>) {
    const { onClickOutside } = this.props;
    const el = this.container;
    if (e.target instanceof window.HTMLElement) {
      if (el && !el.contains(e.target)) {
        onClickOutside(e);
      }
    }
  }

  container: React.ElementRef<'span'> | null;

  render() {
    const { children, onClickOutside, ...props } = this.props;
    return <span {...props} ref={this.refC}>{children}</span>;
  }
}
