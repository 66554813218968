// @flow
import { createContext } from 'react';

import {
  defaultImportedFileData,
  type ImportedFileData,
  type ApiHandlers,
  type ModifyHandlers,
} from './useImportedFileData';


const emptyFunc = () => {};
const emptyFuncBoolean = () => false;

type ImportXLSContextType = {
  content: ImportedFileData,
  api: ApiHandlers,
  modify: ModifyHandlers,
}

const defaultContext: ImportXLSContextType = {
  content: defaultImportedFileData,
  api: {
    requestFileData: emptyFunc,
    submit: emptyFuncBoolean,
  },
  modify: {
    reset: emptyFunc,
    invalidate: emptyFunc,
    optionUpdate: emptyFunc,
    setDataTypeByPosition: emptyFunc,
    emailUpdate: emptyFunc,
  },
};

export default createContext<ImportXLSContextType>(defaultContext);
