// @flow
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { useOrgsData, useDateFrom, useOrgsMapFromOrgs } from 'app/dataProviders/org/OrgsDataProvider';
import PlainInputDate from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import OrgsAndGroupsSelector from 'app/components/common/OrgsAndGroupsSelector';

import useCopyTo from './useCopyTo';
import type { Checks } from '../CopyMenuFromToOrgs';
import useTargetsString from '../useTargetsString';
import useOrgsSelector from './useOrgsSelector';
import type { Errors } from '../errors';

import {
  MenuSellPointSelector,
  MenuBuildingSelector,
  useMenuTargetItemState,
  useMultipleBooleanState,
} from '../../MenuTargetSelectors';

import styles from './CopyTo.scss';


export const dateFormat = 'YYYY-MM-DD';
const targetPhraseOptions = { delimiter: ', ', delimiterLast: ' и ' };
const targetsFromArray = ['корпус', 'точку продаж', 'категорию меню'];
const targetsToArray = ['дату', 'заведение', 'корпус', 'точку продаж', 'категорию меню'];
const cx = classNames.bind(styles);

type Props = {
  orgId: number,
  buildingId: number | null,
  sellPointId: number | null,
  date: string,
  email: string,
  hideControls?: boolean,
  formRef: {
    current: ?HTMLFormElement,
  },
  optionsChecks: Checks,
  onSubmitting?: (status: boolean) => void,
  onClose: (e?: SyntheticEvent<HTMLElement>) => void,
  onMoreThanOneSelectedOrgs: (gt: boolean) => void,
  onLoading?: (loading: boolean) => void,
  resetErrors: () => void,
  validate: () => boolean,
  errors: Errors,
}

const CopyTo = ({
  orgId,
  buildingId,
  sellPointId,
  date,
  email,
  hideControls,
  formRef,
  optionsChecks,
  onSubmitting,
  onClose,
  onMoreThanOneSelectedOrgs,
  onLoading,
  resetErrors,
  validate,
  errors,
}: Props) => {
  const [dateValue, setDateValue] = useState(date);
  const handleDateChange = useCallback((newDate: string) => {
    setDateValue(newDate);
    resetErrors();
  }, [resetErrors]);

  const [{ orgs: orgsState, loading: loadingOrgs, currentOrg }] = useOrgsData();
  const orgsMap = useOrgsMapFromOrgs(orgsState);

  const actualFromDate = useDateFrom(orgsState, { orgId });
  const { type } = currentOrg;

  const orgID = String(orgId);
  const ignoreCurrent = useCallback(({ ID }: { ID: string }) => {
    return orgID !== ID;
  }, [orgID]);

  const [selectorProps] = useOrgsSelector(orgsState, { date: dateValue, orgId });
  const { selection: orgsSelected, selection, defaultSelection, onChange } = selectorProps;

  // состояния из/в куда копировать
  const sellPointFromState = useMenuTargetItemState();
  const sellPointToState = useMenuTargetItemState();
  const buildingFromState = useMenuTargetItemState();
  const buildingToState = useMenuTargetItemState();

  const [sellPointIdFrom, sellPointFromSingle] = sellPointFromState;
  const [, sellPointToSingle] = sellPointToState;
  const [buildingIdFrom, buildingFromSingle] = buildingFromState;
  const [, buildingToSingle] = buildingToState;


  // генерация строки подзаголовка "откуда"
  const targetsFromAllowances = useMemo(() => (
    [!buildingFromSingle, !sellPointFromSingle]
  ), [buildingFromSingle, sellPointFromSingle]);

  const targetsFromString = useTargetsString(
    targetsFromArray,
    targetsFromAllowances,
    targetPhraseOptions,
  );


  // генерация строки подзаголовка "куда"
  const targetsToAllowances = useMemo(() => (
    [true, true, !buildingToSingle, !sellPointToSingle]
  ), [buildingToSingle, sellPointToSingle]);

  const targetsToString = useTargetsString(
    targetsToArray,
    targetsToAllowances,
    targetPhraseOptions,
  );

  const {
    handleSubmit,
    saving,
    formId,
  } = useCopyTo({
    optionsChecks,
    orgsSelected,
    currentDate: dateValue,
    orgId,
    date,
    email,
    onClose,
    onSubmitting,
    availableOrgs: orgsState,
    sellPointIdFrom,
    validate,
  });

  const disabledDays = useCallback((dayFormatted: string, format: string, unit?: string = 'day') => (
    actualFromDate
      ? !moment(dayFormatted, format).isAfter(actualFromDate, unit)
      : false
  ), [actualFromDate]);

  // эффект изменения количества (>1) выбранных заведений
  const moreThanOneSelectedOrgs = orgsSelected.length > 1;
  useEffect(() => {
    onMoreThanOneSelectedOrgs(moreThanOneSelectedOrgs);
  }, [moreThanOneSelectedOrgs]); // eslint-disable-line react-hooks/exhaustive-deps

  const [loading, setLoading] = useMultipleBooleanState(loadingOrgs);

  useEffect(() => {
    if (onLoading) {
      onLoading(loading);
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit} ref={formRef} id={formId}>
        {targetsFromString
          && <div className={cx(styles.subHeader, styles.subHeader__top)}>
            {`Выберите ${targetsFromString} в текущем заведении, откуда копировать меню`}
          </div>}

        <MenuBuildingSelector
          orgId={orgId}
          buildingState={buildingFromState}
          direction="from"
          disabled={saving || loading}
          onLoading={setLoading}
          defaultState={buildingId}
        />

        <MenuSellPointSelector
          orgId={orgId}
          buildingId={buildingIdFrom}
          sellPointState={sellPointFromState}
          direction="from"
          disabled={saving || loading}
          onLoading={setLoading}
          defaultState={sellPointId}
        />

        <div className={styles.subHeader}>
          {`Выберите ${targetsToString}, куда копировать меню`}
        </div>

        <PlainLabel htmlFor="date_to">
          {'На дату'}
        </PlainLabel>

        <PlainInputDate
          id="date_to"
          value={dateValue}
          onDateChange={handleDateChange}
          dateFormat={dateFormat}
          disabled={loading || saving}
          dateDisabledStatus={disabledDays}
          error={Boolean(errors.date)}
          calendarCloseOnClick
          displayAsBlock
        />

        {errors.date
          && <PlainHelperText overflowVisible error>
            {errors.date}
          </PlainHelperText>}

        <OrgsAndGroupsSelector
          orgType={type}
          label="В заведения"
          header="Копирование меню во все корпуса и точки продаж"
          pleaseSelectText="Выберите заведение"
          loading={loading}
          disabled={saving}
          orgs={orgsMap}
          multiple
          selectedAllOnEmptySelection={false}
          selection={selection}
          defaultSelection={defaultSelection}
          onChange={onChange}
          filterFunc={ignoreCurrent}
        />

        <div className={styles.warning}>{'Копирование меню во все корпуса и точки продаж'}</div>

        <ControlsBar className={cx(styles.buttons, { hideControls })} right>
          <PlainButton outline onClick={onClose}>
            {'Отмена'}
          </PlainButton>
          <PlainButton type="submit" name="submit" disabled={loading || saving}>
            {saving ? 'Копирование...' : 'Копировать'}
          </PlainButton>
        </ControlsBar>

      </form>
    </div>
  );
};

export default CopyTo;
