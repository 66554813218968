// @flow
import { createSelector } from 'reselect';
import prop from 'ramda/es/prop';

import { tableColumnSort } from 'app/common/lib/sort';
import { toCamelCase } from 'app/common/lib/object';


type IncomingProps = {
  schoolId: number,
  schoolTitle: string,
  count: number,
  pos: number,
  neg: number,
  negPercents: number,
  total: number,
}

const selectConverted = createSelector(
  (_, { data }) => data,
  toCamelCase,
);

type Selector = (state: Object, props: Object) => Array<IncomingProps>

const selectSchools: Selector = createSelector(
  [
    (_, { sort: { sortDirection } }) => sortDirection,
    (_, { sort: { sortKey } }) => sortKey,
    selectConverted,
  ],
  (dir, key, items) => tableColumnSort(dir, prop(key), items),
);

export default selectSchools;
