
import MaskedInput from 'react-maskedinput';
import withProps from 'recompose/withProps';

import PlainInput from './PlainInput';


const PlainInputW26 = withProps({
  component: MaskedInput,
  mask: '111/11111',
})(PlainInput);

export default PlainInputW26;
