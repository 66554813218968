// @flow
import { connect } from 'react-redux';
import assocPath from 'ramda/es/assocPath';
import assoc from 'ramda/es/assoc';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';

import { changeFIO } from 'app/common/actions/profile/fio';


const defaultState = {
  name: {
    first: '',
    last: '',
    middle: '',
  },
  saving: false,
};

export default compose(
  connect(
    state => ({
      originalName: state.user.name,
    }),
  ),

  withState(
    'state',
    'setState',
    ({ originalName }) => ({ ...defaultState, name: originalName }),
  ),

  withHandlers({
    change: ({ setState }) => (e) => {
      const { name, value } = e.currentTarget;
      setState(assocPath(['name', name], value));
    },
    submit: ({ dispatch, setState, state, close }) => async (e) => {
      e.preventDefault();
      const { name } = state;
      setState(assoc('saving', true));
      const res = await dispatch(changeFIO({ ...name }));
      setState(assoc('saving', false));
      if (res && close) {
        close();
      }
    },
  }),

  mapProps(({ setState, state, ...props }) => ({ ...props, ...state })),
);
