import { ReactNode } from 'react'

import styles from './MultilineCell.scss'


type Props = {
  children: ReactNode
}

export const MultilineCell = ({ children }: Props) => {
  return (
    <span className={styles.root}>{children}</span>
  )
}
