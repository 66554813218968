// @flow
import React, { useCallback } from 'react';
import { isEmpty } from 'ramda';

import type { TimeRestrictionItemStrict, ArrangeFoodModes } from 'app/dataTypes/OrgSettingsTimeRestrictions.types';
import { foodModes as fm } from 'app/dataTypes/foodModes';
import * as dmt from 'app/dataTypes/DayModes.types';

import { useGenertalSettings } from './RequestsData';
import TimeRestrictionsFoodMode from './TimeRestrictionsFoodMode';
import styles from './TimeRestrictionsGroup.scss';


const allFoodModes: ArrangeFoodModes = [fm.Breakfast, fm.Dinner, fm.Afternoon];

type Props = {
  disabled?: boolean,
  group: Array<TimeRestrictionItemStrict>,
  groupNumber: number,
  onUpdateTime: (gNum: number, fmNum: number, value: Date) => void,
  onUpdateDayMode: (gNum: number, fmNum: number, value: dmt.Today | dmt.Before) => void,
}

const TimeRestrictionsGroup = ({ group, disabled, groupNumber, onUpdateTime, onUpdateDayMode }: Props) => {
  const { loading, state: { food_modes: foodModesAvailable } } = useGenertalSettings();

  const enabledByIndex = useCallback((index: number) => {
    if (isEmpty(foodModesAvailable) && index === 0) return true;
    return foodModesAvailable.includes(allFoodModes[index]);
  }, [foodModesAvailable]);

  return (
    <div className={styles.root}>
      {group.map((foodModeContent, i) => {
        const { time, dayMode } = foodModeContent;

        return (
          enabledByIndex(i)
            ? <TimeRestrictionsFoodMode
              key={i}
              fmNumber={i}
              disabled={disabled || loading}
              groupNumber={groupNumber}
              time={time}
              dayMode={dayMode}
              onUpdateTime={onUpdateTime}
              onUpdateDayMode={onUpdateDayMode}
            />
            : null
        );
      })}
    </div>
  );
};

export default TimeRestrictionsGroup;
