import { controls } from './formParts/AppliesForm'


export const checkErrorsApplies = (err: Map<any, any>, formData: FormData) => {
  if (
    formData.get(controls.hasApplies)
    && !(formData.get(`${controls.cafeteria}1`)
      || formData.get(`${controls.cafeteria}2`))
  ) {
    err.set('applies', 'Выберите хотя бы один пункт')
  }
}
