// @flow
import React, { type Node, useState, useCallback, Fragment } from 'react';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import Modal, { ModalHeader } from 'app/common/components/Modal';

import ReservationHistory from './reservation-history';


type Props = {
  children: Node,
  customerId: number,
  name: string,
  group: string,
}

const ReservationHistoryModal = ({ children, customerId, name, group }: Props) => {
  const [open, setOpen] = useState(false);

  const handleToggleModal = useCallback(() => {
    setOpen(state => !state);
  }, []);

  return (
    <Fragment>
      <PlainButtonAsLink onClick={handleToggleModal}>
        {children}
      </PlainButtonAsLink>
      {open && <Modal toggleModal={handleToggleModal}>
        <ModalHeader>
          {`История резервирования — ${name}${group ? ', ' : ''}${group}`}
        </ModalHeader>

        <ReservationHistory
          customerId={customerId}
        />
      </Modal>}
    </Fragment>
  );
};

export default ReservationHistoryModal;
