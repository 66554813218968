// @flow
import { prop } from 'ramda';

import { emptyObject } from 'app/common/lib/empty';
import { type ReportsPerformBalancesParams } from 'app/common/api/requests/reports/perform';
import mailFilter, { readyToUse, onlyMail } from 'app/components/common/reports/filters/mail';
import { getValue } from 'app/common/ui-components/filter';

import type { FilterParams } from '../FilterParams.type';


type State = { [key: string]: any }
type Res = $PropertyType<ReportsPerformBalancesParams, 'options'>

const generateParamsOptions = (state: State, filterParams: FilterParams): [Res, boolean] => {
  const email: string | void = filterParams && prop('email', filterParams);

  const [mailFilterValue] = getValue(mailFilter, state);

  const onlymail = Boolean(email && onlyMail(mailFilterValue));
  const mailReady = readyToUse(mailFilterValue);
  const options = mailReady ? {
    send_to_email: email,
  } : emptyObject;

  return [options, onlymail];
};

export default generateParamsOptions;
