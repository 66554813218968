// @flow
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import path from 'ramda/es/path';
import clone from 'ramda/es/clone';
import prop from 'ramda/es/prop';
import cond from 'ramda/es/cond';
import equals from 'ramda/es/equals';
import always from 'ramda/es/always';
import T from 'ramda/es/T';

import api from 'app/actions/api';
import action from 'app/common/lib/action';
import * as types from 'app/common/constants/identifiers/types';


export default function useRequests(personId: number) {
  const { t } = useTranslation('identifiers-next');
  const dispatch = useDispatch();

  /**
   *  ЗАПРОС ДАННЫХ
   */
  const handleDataRequest = useCallback(async () => {
    const requestPath = `food/employee/${personId}/cards`;
    const type = 'get';
    try {
      const res = await api(requestPath, type);
      return path(['data', 'cards'], res) || {};
    } catch (error) {
      const errorSave = {
        err: t('request_identifiers_failed'),
        path: requestPath,
        type,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
      return null;
    }
  }, [dispatch, t, personId]);

  /**
   *  БЛОКИРОВКА ИДЕНТИФИКАТОРА
   */
  const handleBlockIdentifier = useCallback(async (
    identifierId: number,
    reason: string,
  ) => {
    const requestPath = `food/employee/${personId}/card/${identifierId}/disable`;
    const type = 'put';
    const rest = { data: { reason } };
    try {
      const res = await api(requestPath, type, rest);
      return path(['data', 'cards'], res) || {};
    } catch (error) {
      const errorSave = {
        err: t('request_identifier_block_failed'),
        path: requestPath,
        type,
        rest,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
      return null;
    }
  }, [dispatch, t, personId]);

  /**
   *  РАЗБЛОКИРОВКА ИДЕНТИФИКАТОРА
   */
  const handleUnblockIdentifier = useCallback(async (identifierId: number) => {
    const requestPath = `food/employee/${personId}/card/${identifierId}/enable`;
    const type = 'put';
    try {
      const res = await api(requestPath, type);
      return path(['data', 'cards'], res) || {};
    } catch (error) {
      const errorSave = {
        err: t('request_identifier_unblock_failed'),
        path: requestPath,
        type,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
      return null;
    }
  }, [dispatch, t, personId]);

  /**
   *  ПЕРЕНАЗНАЧЕНИЕ ИДЕНТИФИКАТОРА (в текущем кабинете не используется)
   */
  // const handleReassignIdentifier = useCallback(async (identifierId: number, studentId: number) => {
  //   const requestPath = 'cabinet/identifiers/reassign';
  //   try {
  //     const res = await api(requestPath, 'post', { data: { id: identifierId, student_id: studentId } });
  //     const { result, ...identifiers } = prop('data', res) || {};
  //     return identifiers || {};
  //   } catch (error) {
  //     const errorSave = {
  //       err: t('request_identifier_reassign_failed'),
  //       path: requestPath,
  //       error: clone(error),
  //     };
  //     dispatch(action('REQUEST_FAILURE', errorSave));
  //     return null;
  //   }
  // }, []);


  /**
   *  ОБНОВЛЕНИЕ АТТРИБУТОВ ИДЕНТИФИКАТОРА
   */
  const handleChangeIdentifierAttribute = useCallback(async (
    identifierId: number,
    attributeName: string,
    attributeValue: mixed,
  ) => {
    const requestPath = `food/employee/${personId}/card/${identifierId}/attributes`;
    const type = 'put';
    const rest = {
      data: {
        id: identifierId,
        [attributeName]: attributeValue,
      },
    };
    try {
      const res = await api(requestPath, type, rest);
      const { cards } = prop('data', res) || {};
      return cards || {};
    } catch (error) {
      const errorSave = {
        err: t('request_identifier_attribute_change_failed'),
        path: requestPath,
        type,
        rest,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
      return null;
    }
  }, [dispatch, t, personId]);

  /**
   *  ДОБАВЛЕНИЕ ИДЕНТИФИКАТОРА
   */
  const handleSubmitNewIdentifier = useCallback(async (
    type: $Values<typeof types>,
    number: string,
  ) => {
    const requestPath = `food/employee/${personId}/card`;
    const requestType = 'post';
    const rest = {
      data: {
        type,
        number,
      },
    };

    try {
      const { data } = await api(requestPath, requestType, rest);
      const cards = prop('cards', data);
      return cards || {};
    } catch (error) {
      const errorCodePhrase = path(['response', 'data', 'message'], error);
      const err = cond([
        [equals('wrong card number format'), t('request_add_identifier_invalid_format')],
        [equals('wrong card type'), t('request_add_identifier_invalid_type')],
        [equals('card number already used'), t('request_add_identifier_already_used')],
        [equals('card number not in school white list'), t('request_add_identifier_blocked')],
        [T, always('request_add_identifier_fail')],
      ])(errorCodePhrase);

      const errorSave = {
        err,
        path: requestPath,
        type: requestType,
        rest,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
      return null;
    }
  }, [dispatch, t, personId]);

  return {
    handleDataRequest,
    handleBlockIdentifier,
    handleUnblockIdentifier,
    // handleReassignIdentifier,
    handleChangeIdentifierAttribute,
    handleSubmitNewIdentifier,
  };
}
