// @flow
import React from 'react';

import CSSSticky from 'app/common/ui-components/css-sticky';
import Pager from 'app/common/ui/Paging';

import styles from './OrgsOrdersFooter.scss';


type Props = {
  total: number,
  pageSize: number,
  currentPage: number,
  currentPageSize: number,
  onPage?: (e: SyntheticEvent<HTMLAnchorElement>) => void,
}

const OrgsOrdersFooter = ({ total, pageSize, currentPage, currentPageSize, onPage }: Props) => {
  return (
    <CSSSticky
      number={-1}
      shadowClassName={styles.stickyBottom}
      className={styles.footer}
    >
      <Pager
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        currentPageSize={currentPageSize}
        onPage={onPage}
      />
    </CSSSticky>
  );
};

export default OrgsOrdersFooter;
