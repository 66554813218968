import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';

import { SUBSIDIES_DATA_CLEAR, subsidiesDataFetch } from 'app/actions/reports/subsidies/data';
import { subsidiesReportViewParamsCreate, SUBSIDIES_RESET_PARAMS } from 'app/actions/reports/subsidies';
import action from 'app/common/lib/action';


export default compose(
  connect(
    state => ({
      reportId: state.reports.subsidies.params.reportId,
    }),
  ),

  withState('loading', 'setLoading', false),

  lifecycle({
    async componentDidUpdate(prevProps) {
      const { reportId, dispatch, setLoading } = this.props;
      if (reportId !== prevProps.reportId && reportId) {
        setLoading(true);
        await dispatch(subsidiesDataFetch(reportId));
        dispatch(subsidiesReportViewParamsCreate());
        setLoading(false);
      }
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(action(SUBSIDIES_DATA_CLEAR));
      dispatch(action(SUBSIDIES_RESET_PARAMS));
    },
  }),
);
