// @flow
import { createContext } from 'react';

import { defaultSaleTypesState, type SaleTypesState } from './useSaleTypes';


const defaultContext: SaleTypesState = [
  defaultSaleTypesState,
  true,
];

export default createContext<SaleTypesState>(defaultContext);
