// @flow
import React, { type Ref } from 'react';
import classNames from 'classnames/bind';

import { Tr, Th, getPropsId } from 'app/common/ui-components/table';
import type { Settings } from 'app/reducers/settings/orgsOrders';

import type { Columns } from './columns';
import styles from './OrgsOrdersTableTHead.scss';


const cx = classNames.bind(styles);

type Props = {
  reference?: Ref<'tr'>,
  hidden?: boolean,
  dummy?: boolean,
  titles: { [key: $Keys<Settings>]: string },
  columnIds: Columns,
}

const OrgsOrdersTableTHead = ({
  reference,
  hidden,
  dummy,
  titles,
  columnIds,
}: Props) => {
  const cellProps = getPropsId(dummy);

  return (
    <Tr
      reference={reference}
      hidden={hidden}
      header
    >
      {columnIds.map(id => (
        <Th
          key={id}
          alignLeft
          contentClassName={cx({ statusHeader: id === 'id' })}
          {...cellProps(id)}
        >
          {titles[id]}
        </Th>
      ))}
    </Tr>

  );
};

export default OrgsOrdersTableTHead;
