// @flow
import { api } from 'app/api';
import { GET_VENDING_MACHINE_SINGLE, type Machine } from 'app/common/api/requests/data/vending/machines/get';


const requestMachineParams = async (machineId: string): Promise<Machine | null> => {
  const res = await api.request(GET_VENDING_MACHINE_SINGLE, {
    error: 'Не удалось получить параметры модели аппарата',
    requestPathParams: {
      machineId,
    },
  });

  return res;
};

export default requestMachineParams;
