// @flow


export const extractIndex = (className: string): number | null => {
  const res = className.match(/index_(\d+)/);
  if (!res) return null;

  const index = parseInt(res[1], 10);
  if (Number.isNaN(index)) return null;

  return index;
};

export const extractIndexByBase = (classNameBase: string, className: string): number | null => {
  const re = new RegExp(`${classNameBase}(\\d+)`);
  const res = className.match(re);
  if (!res) return null;

  const index = parseInt(res[1], 10);
  if (Number.isNaN(index)) return null;

  return index;
};
