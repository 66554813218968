import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import { BY_USERS } from 'app/actions/reports/filters/group';

import {
  sortFilterSet,
  sortFilterDefault,
  BY_CLASSES,
  BY_ALPHABET,
} from 'app/actions/reports/filters/sort';
import withReset from 'app/components/Reports/hocs/withReset';

import { selectIsEnableDefault as selectIsEnabledGroup } from './withGroup';
import { selectCustomerId } from './commonSelectors';


const selectIsEnabled = createSelector(
  [
    state => state.reports.filters.details,
    selectIsEnabledGroup,
    state => state.reports.filters.group.current,
  ],
  (details, enabledGroup, group) => (
    details
    && enabledGroup
    && group === BY_USERS
  ),
);

export default compose(
  connect(
    state => ({
      sort: state.reports.filters.sort,
      enabled: selectIsEnabled(state),
      customerId: selectCustomerId(state),
    }),
    dispatch => ({
      handleChange: (e) => {
        const { value, checked } = e.currentTarget;
        if (![BY_CLASSES, BY_ALPHABET].includes(value)) return;
        if (checked) {
          dispatch(sortFilterSet(value));
        }
      },
      reset: () => {
        dispatch(sortFilterDefault());
      },
    }),
  ),

  withReset,

  branch(({ enabled }) => !enabled, renderNothing),
);
