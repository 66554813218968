

export const emptyFunc = () => {}
const hidden = { display: 'none' }

type Props = {
  name?: string
  selection?: Array<string>
  multiple?: boolean
  items: Array<{ ID: string }>
}

const HTMLSelect = ({
  name,
  selection = [],
  multiple,
  items,
}: Props) => {
  const value = multiple ? selection : selection[0]

  return (
    <select
      name={name}
      multiple={multiple}
      value={value}
      style={hidden}
      onChange={emptyFunc}
      disabled={!value}
    >
      {items.map(({ ID }) => (
        <option value={ID} key={ID} />
      ))}
    </select>
  )
}

export default HTMLSelect
