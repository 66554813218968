// @flow
import { useEffect } from 'react';
import path from 'ramda/es/path';

import { useModels } from 'app/dataProviders/VendingDataProvider';


export default function useModelById(modelId: number): string {
  const [models,, request] = useModels();

  useEffect(() => {
    request();
  }, [request]);

  return path([String(modelId), 'title'], models) || '';
}
