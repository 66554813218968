// @flow
import { orgsOrUser } from 'app/dataTypes/reports/orgsOrUser';


const sortItems = () => ([[{
  id: orgsOrUser.ByOrgs,
  title: 'по заведениям',
}, {
  id: orgsOrUser.ByUser,
  title: 'по данным пользователя',
}], [orgsOrUser.ByOrgs]]);

export default sortItems;
