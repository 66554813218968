import { ReactNode, useState } from 'react'

import styles from './panel-header.scss'


type Props = {
  ID: string
  title: string | ReactNode
  className?: string
  titleClassName?: string
  titleStyle?: Record<string, number | string>
  onClick: (ID: string) => void
  locked?: boolean
  style?: Record<string, number | string>
  children: (hover: boolean) => ReactNode
}

const PanelHeader = ({
  ID,
  title,
  className,
  titleClassName,
  titleStyle,
  onClick,
  locked,
  style,
  children,
}: Props) => {
  const [hover, setHover] = useState(false)

  return (
    <div
      className={className}
      style={style}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <button
        type="button"
        className={styles.button}
        onClick={() => onClick(ID)}
        disabled={locked}
      >
        <span style={titleStyle} className={titleClassName}>
          {title}
        </span>
      </button>

      {children(hover)}
    </div>
  )
}

export default PanelHeader
