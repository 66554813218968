// @flow
import React, { type Ref, Fragment } from 'react';
import classNames from 'classnames/bind';

import {
  Tr,
  Th,
} from 'app/common/ui-components/table';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';

import styles from './ClassesAndOrdersTHead.scss';


const cx = classNames.bind(styles);

const getHeaderCellProps = (dummy?: boolean) => (id: string) => {
  return dummy ? {} : {
    id: `header_${id}`,
  };
};


type Props = {
  dummy?: boolean,
  rowRefFirst?: Ref<'tr'>,
  rowRefSecond?: Ref<'tr'>,
  hidden?: boolean,
  viewCommon?: boolean,
  viewPersonal?: boolean,
}

const ClassesAndOrdersTHead = ({
  dummy,
  rowRefFirst,
  rowRefSecond,
  hidden,
  viewCommon = true,
  viewPersonal = true,
}: Props) => {
  const cellProps = getHeaderCellProps(dummy);
  const bothView = viewCommon && viewPersonal;

  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <thead>
      <Tr header reference={rowRefFirst} hidden={hidden}>
        <Th
          scope="col"
          rowSpan={2}
          className={cx({ cellTitle: bothView }, styles.borderRight)}
          alignLeft
          {...cellProps('title')}
        >
          {'Класс'}
        </Th>

        {viewCommon
          && <Th
            scope="colgroup"
            colSpan={2}
            className={cx({ cellCommon: bothView, borderRight: viewPersonal })}
            {...cellProps('common')}
          >
            {'Общая справка'}
          </Th>}

        {viewPersonal
          && <Th
            scope="colgroup"
            colSpan={3}
            className={cx({ cellPersonal: bothView })}
            {...cellProps('personal')}
          >
            {'Индивидуальная справка'}
          </Th>}
      </Tr>
      <Tr header reference={rowRefSecond} hidden={hidden}>
        {viewCommon
          && <Fragment>
            <Th
              scope="col"
              className={styles.cellCommonCount}
              {...cellProps('common_count')}
            >
              {'Кол-во'}
            </Th>
            <Th
              scope="col"
              className={cx({ borderRight: viewPersonal })}
              {...cellProps('common_sum')}
            >
              {`Сумма${currency}`}
            </Th>
          </Fragment>}

        {viewPersonal
          && <Fragment>
            <Th
              scope="col"
              alignLeft
              {...cellProps('personal_category')}
            >
              {'Категория'}
            </Th>
            <Th
              scope="col"
              {...cellProps('personal_count')}
            >
              {'Кол-во'}
            </Th>
            <Th
              scope="col"
              {...cellProps('personal_sum')}
            >
              {`Сумма${currency}`}
            </Th>
          </Fragment>}
      </Tr>
    </thead>

  );
};

export default ClassesAndOrdersTHead;
