import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import mapProps from 'recompose/mapProps';
import path from 'ramda/es/path';
import omit from 'ramda/es/omit';
import append from 'ramda/es/append';
import either from 'ramda/es/either';
import pipe from 'ramda/es/pipe';
import curry from 'ramda/es/curry';
import { createStructuredSelector } from 'reselect';

// полный доступ для пользователей типа user
const getSU = state => (((process.env.APP_NAME || process.env.REACT_APP_NAME) === 'food') && (state.user.type === 'user'));

const toRead = append('read');
const toEdit = append('edit');

// путь к правам сразу или через функцию/селектор
const getStatePath = curry((state, props) => {
  const { statePath } = props;
  return (typeof statePath === 'function'
    ? statePath(state, props)
    : statePath
  );
});

export const getReadAccess = (state, props) => either(
  getSU,
  pipe(getStatePath, toRead, path)(state, props),
)(state, props);

export const getWriteAccess = (state, props) => either(
  getSU,
  pipe(getStatePath, toEdit, path)(state, props),
)(state, props);

// тест без промежуточной функции для доступа к правам
// export const getReadAccess = (state, { statePath }) => either(getSU, path(toRead(statePath)))(state);
// export const getWriteAccess = (state, { statePath }) => either(getSU, path(toEdit(statePath)))(state);

export const getAdminReadAccess = getSU;

const makeSelectRights = () => createStructuredSelector({
  readAccess: getReadAccess,
  writeAccess: getWriteAccess,
});

const makeSelectReadRights = () => createStructuredSelector({
  readAccess: getReadAccess,
});

const selectAdminReadAccess = createStructuredSelector({
  readAccess: getAdminReadAccess,
});

// добавляет свойства props доступа readAccess, writeAccess
export const withRights = statePath => compose(
  connect(
    () => {
      const selectRights = makeSelectRights();
      return (state, props) => selectRights(state, { ...props, statePath });
    },
  ),
);

// блокирует render компонента если нет доступа на чтение
export const withAccess = statePath => compose(
  connect(
    () => {
      const selectReadRights = makeSelectReadRights();
      return (state, props) => selectReadRights(state, { ...props, statePath });
    },
  ),
  branch(({ readAccess }) => !readAccess, renderNothing),
  mapProps(omit(['readAccess', 'dispatch'])),
);

// блокирует рендер компонента по writeAccess свойству
export const withWriteAccess = compose(
  branch(({ writeAccess }) => !writeAccess, renderNothing),
  mapProps(omit(['dispatch', 'writeAccess', 'readAccess'])),
);

// блокирует рендер компонента по readAccess свойству
export const withReadAccess = compose(
  branch(({ readAccess }) => !readAccess, renderNothing),
  mapProps(omit(['dispatch', 'writeAccess', 'readAccess'])),
);

// блокирует рендер компонента по двум свойствам
export const withReadAndWriteAccess = compose(
  branch(({ readAccess }) => !readAccess, renderNothing),
  branch(({ writeAccess }) => !writeAccess, renderNothing),
  mapProps(omit(['dispatch', 'writeAccess', 'readAccess'])),
);

// права админа
export const withAdminRights = compose(
  connect(
    state => selectAdminReadAccess(state),
  ),
  mapProps(({ readAccess, ...props }) => ({
    ...props,
    readAccess,
    writeAccess: readAccess,
  })),
);

// доступ только админу
export const withAdminAccess = compose(
  connect(
    state => selectAdminReadAccess(state),
  ),
  branch(({ readAccess }) => !readAccess, renderNothing),
  mapProps(omit(['readAccess', 'dispatch'])),
);

// один из 2х HOC на входе разрешает доступ на запись (||)
export const eitherWriteAccess = (arg1, arg2) => {
  let pass1 = false;
  let pass2 = false;
  return compose(
    arg1,
    mapProps(({ writeAccess, ...props }) => {
      pass1 = writeAccess;
      return props;
    }),
    arg2,
    mapProps(({ writeAccess, ...props }) => {
      pass2 = writeAccess;
      return props;
    }),
    branch(() => !(pass1 || pass2), renderNothing),
  );
};

// смешение 2х прав (&&)
export const bothRights = (arg1, arg2) => {
  let write = false;
  let read = false;
  return compose(
    arg1,
    mapProps(({ writeAccess, readAccess, ...props }) => {
      write = writeAccess;
      read = readAccess;
      return props;
    }),
    arg2,
    mapProps(({ writeAccess, readAccess, ...props }) => ({
      writeAccess: writeAccess && write,
      readAccess: readAccess && read,
      ...props,
    })),
  );
};

// смешение 2х прав (||)
export const anyRights = (arg1, arg2) => {
  let write = false;
  let read = false;
  return compose(
    arg1,
    mapProps(({ writeAccess, readAccess, ...props }) => {
      write = writeAccess;
      read = readAccess;
      return props;
    }),
    arg2,
    mapProps(({ writeAccess, readAccess, ...props }) => ({
      writeAccess: writeAccess || write,
      readAccess: readAccess || read,
      ...props,
    })),
  );
};
