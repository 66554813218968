import { createSelector } from 'reselect';
import omit from 'ramda/es/omit';

import {
  DOCUMENT_PUBLIC_OFFER,
  DOCUMENT_PRIVACY_POLICY,
  DOCUMENT_PRIVACY_CONSENT,
} from 'app/common/constants/documents';
import { defaultState } from 'app/common/reducers/documents';


const selectDocuments = createSelector(
  [
    state => state.documents,
    state => state.documents.result,
  ],
  (documents, result) => {
    if (result !== 'success') return null;
    const docs = omit([Object.keys(defaultState)], documents);
    const notAcceptedKeys = Object.keys(docs).filter(key => docs[key].accepted === false);
    if (!notAcceptedKeys.length) return null;
    // offer
    const offerKey = notAcceptedKeys.find(key => docs[key].type === DOCUMENT_PUBLIC_OFFER);
    if (offerKey) return docs[offerKey];
    // privacy
    const policyKey = notAcceptedKeys.find(key => docs[key].type === DOCUMENT_PRIVACY_POLICY);
    if (!policyKey) return null;
    const consentKey = notAcceptedKeys.find(key => docs[key].type === DOCUMENT_PRIVACY_CONSENT);
    if (!consentKey) return null;
    return {
      ids: [docs[policyKey].id, docs[consentKey].id],
      type: DOCUMENT_PRIVACY_POLICY,
      title: docs[policyKey].title,
      changes: docs[policyKey].changes + docs[consentKey].changes,
      urls: [docs[policyKey].url, docs[consentKey].url],
    };
  },
);

export default selectDocuments;
