import { has, range, reduce, reduced, reject, values, map } from 'ramda'

import { Accrual, CafeteriaSettings, Schema } from 'app/dataTypes/subsidy/settings'

import { mustBePair } from '../helpers'


export type CafeteriaSettingsAPI = {
  shift_1_accrual_customized: boolean, // флаг индивидуальных настроек для первой смены
  shift_1_accrual_on_complexes: Array<number>, // список комплектов для первой смены
  shift_1_accrual_on_additional_complexes?: Array<number>, // список доп. комплектов для первой смены
  shift_1_accrual_on_sum: null | number, // сумма для первой смены
  shift_1_default_complex_id: null | number, // id комплекта по умолчанию для первой смены
  shift_1_accrual_type: 'complexes' | 'chosen_complex', // тип начисления для первой смены, complexes - множество, chosen_complex - один из множества

  shift_2_accrual_customized: boolean, // флаг индивидуальных настроек для второй смены
  shift_2_accrual_on_complexes: Array<number>, // список комплектов для второй смены
  shift_2_accrual_on_additional_complexes?: Array<number>, // список доп. комплектов для второй смены
  shift_2_accrual_on_sum: null | number, // сумма для второй смены
  shift_2_default_complex_id: number,
  shift_2_accrual_type: 'complexes' | 'chosen_complex',

  shift_3_accrual_customized: boolean, // флаг индивидуальных настроек для третьей смены
  shift_3_accrual_on_complexes: Array<number>, // список комплектов для третьей смены
  shift_3_accrual_on_additional_complexes?: Array<number>, // список доп. комплектов для третьей смены
  shift_3_accrual_on_sum: null | number, // сумма для третьей смены
  shift_3_default_complex_id: number,
  shift_3_accrual_type: 'complexes' | 'chosen_complex',

  for_single_sales_enabled: boolean, // флаг отображения начисления льгот при личных продажах
  for_single_sales_checked: boolean, // флаг принудительного выбора начисления льгот при личных продажах
  for_klass_orders_enabled: boolean, // флаг отображения начисления льгот при продажах в классе
  for_klass_orders_checked: boolean, // флаг принудительного выбора начисления льгот при продажах в классе
  for_day_care_enabled: boolean, // флаг отображения начисления льгот при продажах в ГПД
  for_day_care_checked: boolean, // флаг принудительного выбора начисления льгот при продажах в ГПД

  include_saturday_enabled: boolean, // флаг отображения начисления льгот при продажах по субботам
  include_saturday_checked: boolean, // флаг принудительного выбора начисления льгот при продажах по субботам

  // только индивидуальная справка
  applies_to_cafeteria_enabled?: boolean, // флаг отображения применения льготного питания
  applies_to_cafeteria_checked?: boolean, // флаг принудительного выбора применения льготного питания

  // только общая справка
  schema_common_enabled?: boolean, // флаг отображения пункта использования только общей справки
  schema_common_checked?: boolean, // флаг принудительного выбора пункта использования только общей справки
  schema_individual_enabled?: boolean, // флаг отображения пункта исключения общей справки для учащихся с индивидуальными справками
  schema_individual_checked?: boolean, // флаг принудительного пункта исключения общей справки для учащихся с индивидуальными справками
  schema_both_enabled?: boolean, // флаг отображения пункта использования общей справки параллельно с индивидуальной
  schema_both_checked?: boolean, // флаг принудительного выбора пункта использования общей справки параллельно с индивидуальной
}

export const MAX_SHIFTS = 3

export const convertAsCafeteria = (
  settings: CafeteriaSettingsAPI,
  complexesMap: Map<number, string>,
  common: boolean,
): CafeteriaSettings => {
  const accruals = reduce((acc, val) => {
    if (!has(`shift_${val}_accrual_customized`, settings)) {
      reduced(acc)
    }
    const active: boolean = settings[`shift_${val}_accrual_customized`]
    const complexesIDs: Array<number> = settings[`shift_${val}_accrual_on_complexes`]
    const additionalIDs: Array<number> = settings[`shift_${val}_accrual_on_additional_complexes`]
    const sum: null | number = parseFloat(settings[`shift_${val}_accrual_on_sum`])
    const type: 'complexes' | 'chosen_complex' = settings[`shift_${val}_accrual_type`]
    const defaultComplexID: null | number = settings[`shift_${val}_default_complex_id`]

    acc.push({
      num: val,
      active,
      complexes: complexesIDs.map(key => ({
        ID: String(key),
        title: complexesMap.get(key) || String(key),
      })),
      ...(additionalIDs ? {
        additional: additionalIDs.map(key => ({
          ID: String(key),
          title: complexesMap.get(key) || String(key),
        })),
      } : {}),
      sum: typeof sum === 'number' && !Number.isNaN(sum) ? String(sum) : '',
      type,
      defaultComplexID: typeof defaultComplexID === 'number' ? String(defaultComplexID) : null,
    })
    return acc
  }, [] as Array<Accrual>, range(1, MAX_SHIFTS + 1))


  const accrualsFor = {
    single: mustBePair([settings.for_single_sales_enabled, settings.for_single_sales_checked]),
    class: mustBePair([settings.for_klass_orders_enabled, settings.for_klass_orders_checked]),
    dayCare: mustBePair([settings.for_day_care_enabled, settings.for_day_care_checked]),
  }

  const include = {
    saturdays: mustBePair([settings.include_saturday_enabled, settings.include_saturday_checked]),
  }

  const applies = !common ? {
    cafeteria: mustBePair([settings.applies_to_cafeteria_enabled, settings.applies_to_cafeteria_checked]),
  } : undefined

  const schema = common ? {
    common: mustBePair([settings.schema_common_enabled, settings.schema_common_checked]),
    individual: mustBePair([settings.schema_individual_enabled, settings.schema_individual_checked]),
    both: mustBePair([settings.schema_both_enabled, settings.schema_both_checked]),
  } : undefined

  if (schema && !schema.common[1] && !schema.individual[1] && !schema.both[1]) {
    schema.common[1] = true
  }

  return reject(value => (value === undefined), {
    app: 'cafeteria' as const,
    accrualsShiftsAmount: MAX_SHIFTS,
    accruals,
    accrualsFor,
    include,
    applies,
    schema,
  })
}

export const convertAsCafeteriaPOST = (settings: CafeteriaSettings) => {
  const {
    accruals,
    accrualsFor,
    include,
    applies,
    schema: schemaSource,
  } = settings

  const settingsAccruals = reduce((acc, {
    num,
    active,
    complexes,
    additional,
    sum,
    type,
    defaultComplexID,
  }) => {
    acc[`shift_${num}_accrual_customized`] = active
    acc[`shift_${num}_accrual_on_complexes`] = complexes.map(({ ID }) => parseInt(ID, 10))
    if (additional) {
      acc[`shift_${num}_accrual_on_additional_complexes`] = additional.map(({ ID }) => parseInt(ID, 10))
    }
    acc[`shift_${num}_accrual_on_sum`] = sum ? parseFloat(sum) : null
    acc[`shift_${num}_default_complex_id`] = defaultComplexID ? parseInt(defaultComplexID, 10) : null
    acc[`shift_${num}_accrual_type`] = type
    return acc
  }, {} as CafeteriaSettingsAPI, accruals)

  const settingsAccrualsFor = {
    for_single_sales_enabled: accrualsFor.single[0],
    for_single_sales_checked: accrualsFor.single[1],
    for_klass_orders_enabled: accrualsFor.class[0],
    for_klass_orders_checked: accrualsFor.class[1],
    for_day_care_enabled: accrualsFor.dayCare[0],
    for_day_care_checked: accrualsFor.dayCare[1],
  }

  const settingsInclude = {
    include_saturday_enabled: include.saturdays[0],
    include_saturday_checked: include.saturdays[1],
  }

  const settingsApplies = applies ? {
    applies_to_cafeteria_enabled: applies.cafeteria[0],
    applies_to_cafeteria_checked: applies.cafeteria[1],
  } : {}

  const needFix = reduce((_, pair) => (
    (pair[1] && !pair[0]) ? reduced(true) : false
  ), false, values(schemaSource || []))

  const schema: Schema | undefined = (
    needFix && schemaSource
      ? map<any, any>(([, v2]) => ([false, v2]), schemaSource)
      : schemaSource
  )

  const settingsSchema = schema ? {
    schema_common_enabled: schema.common[0],
    schema_common_checked: schema.common[1],
    schema_individual_enabled: schema.individual[0],
    schema_individual_checked: schema.individual[1],
    schema_both_enabled: schema.both[0],
    schema_both_checked: schema.both[1],
  } : {}

  return {
    ...settingsAccruals,
    ...settingsAccrualsFor,
    ...settingsInclude,
    ...settingsApplies,
    ...settingsSchema,
  }
}
