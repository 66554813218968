// @flow
import { filter, map } from 'ramda';

import { type ReportsPerformBalancesParams } from 'app/common/api/requests/reports/perform';
import { getValue } from 'app/common/ui-components/filter';
import orgsFilter from 'app/components/common/reports/filters/org';
import customerTypeFilter from 'app/components/common/reports/filters/customerType';
import studentStatusFilter from 'app/components/common/reports/filters/studentStatus';
import studentFilter from 'app/components/common/reports/filters/student';
import classFilter from 'app/components/common/reports/filters/class';
import employeeFilter from 'app/components/common/reports/filters/employee';
import detailsFilter from 'app/components/common/reports/filters/details';
import currentStateDateFilter from 'app/components/common/reports/filters/currentStateDate';
import balanceTypeFilter from 'app/components/common/reports/filters/balanceType';

import commonBalanceFilter, { commonBalanceTypes } from '../ReportFilter/filters/commonBalance';
import groupFilter from '../ReportFilter/filters/group';


type State = { [key: string]: any }
type Res = $PropertyType<ReportsPerformBalancesParams, 'params'>

const toNumbers = map(val => parseInt(val, 10));

const generateParamsByOrgs = (state: State): Res => {
  const [detailsFilterValue] = getValue(detailsFilter, state);
  const [balanceType] = getValue(balanceTypeFilter, state);

  const limits = filter(Boolean, {
    customer_type: getValue(customerTypeFilter, state),
    students_statuses: getValue(studentStatusFilter, state),
    school_ids: toNumbers(getValue(orgsFilter, state)),
    klass_ids: toNumbers(getValue(classFilter, state)),
    student_ids: toNumbers(getValue(studentFilter, state)),
    employee_ids: toNumbers(getValue(employeeFilter, state)),
    balance_type: balanceType,
  });

  const [groupFilterValue] = getValue(groupFilter, state);
  const [current, date] = getValue(currentStateDateFilter, state);
  const [commonBalance, min, max] = getValue(commonBalanceFilter, state);

  const ranges = (
    commonBalance === commonBalanceTypes.BALANCE_USER
      ? { min: parseFloat(min), max: parseFloat(max) }
      : undefined
  );

  const sign = (
    commonBalance !== commonBalanceTypes.BALANCE_USER
    && commonBalance !== commonBalanceTypes.BALANCE_ALL
      ? [commonBalance]
      : undefined
  );

  return filter(Boolean, {
    limits,
    details: detailsFilterValue,
    details_group: groupFilterValue,
    date,
    current: current === 'current',
    balance_sign: sign,
    balance_ranges: ranges,
  });
};

export default generateParamsByOrgs;
