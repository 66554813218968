// @flow
import React, { useMemo, Fragment } from 'react';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryRow } from 'app/components/common/reports/summary';

import type { ReportTotal } from './useReportData';


type Props = {
  summary: ReportTotal | null,
  loading?: boolean,
  currencySign: string,
  unspent: boolean,
}

const Summary = ({ summary, loading, currencySign, unspent }: Props) => {
  const rows = useMemo(() => {
    const {
      single_sales_sum: ssSum,
      single_sales_count: ssCount,
      order_sales_sum: osSum,
      order_sales_count: osCount,
      duty_sum: dutySum,
      duty_count: dutyCount,
      total_sales_sum: tsSum,
      total_sales_count: tsCount,
    } = summary || {};

    const ssSumText = `${toFixedValue(ssSum)} ${currencySign}`;
    const ssCountText = String(ssCount);
    const osSumText = `${toFixedValue(osSum)} ${currencySign}`;
    const osCountText = String(osCount);
    const dutySumText = `${toFixedValue(dutySum)} ${currencySign}`;
    const dutyCountText = String(dutyCount);
    const tsSumText = `${toFixedValue(tsSum)} ${currencySign}`;
    const tsCountText = String(tsCount);
    const pCount = String(ssCount + osCount);

    const Row1 = [
      ['Личные продажи', [ssSumText, ssCountText]],
      ['Комплексное питание', [osSumText, osCountText]],
    ];

    const Row2 = [
      ['Количество порций', pCount],
      ['Итого', [tsSumText, tsCountText]],
    ];

    const Row3 = unspent ? [
      ['Непроведенные заявки', [dutySumText, dutyCountText]],
    ] : [];

    return [Row1, Row2, Row3];
  }, [summary, currencySign, unspent]);

  return (
    <Fragment>
      {rows.map((row, i) => (
        <SummaryRow key={i}>
          {row.map(([title, value]) => (
            <SummaryItem
              key={title}
              title={title}
              value={value}
              danger={title === 'Непроведенные заявки'}
              loading={loading || !summary}
            />
          ))}
        </SummaryRow>
      ))}
    </Fragment>
  );
};

export default Summary;
