// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import length from 'ramda/es/length';
import { useSelector, shallowEqual } from 'react-redux';

import toFixedValue from 'app/common/lib/toFixedValue';


type Totals = {
  common_order: {
    count: number,
    sum: number,
  },
  personal_order: Array<{
    allowance: string,
    count: number,
    sum: number,
  }>,
}

export type TotalsView = {
  title: string,
  common: {
    count: number,
    sum: string,
  },
  personal: Array<{
    category: string,
    count: number,
    sum: string,
  }>,
  personalCount: number,
}

export default function useActualTotals(): TotalsView {
  const totals: Totals = useSelector(
    state => state.reports.subsidies.data.totals,
    shallowEqual,
  );

  const totalsActual = useMemo(() => {
    const personal = prop('personal_order', totals) || [];
    const personalCount = length(personal);

    return ({
      title: 'Всего',
      common: {
        count: path(['common_order', 'count'], totals),
        sum: toFixedValue(path(['common_order', 'sum'], totals)),
      },
      personal: map(({ allowance, count, sum }) => ({
        category: allowance,
        count,
        sum: toFixedValue(sum),
      }), personal),
      personalCount,
    });
  }, [totals]);


  return totalsActual;
}
