// @flow
import React, { useState, useCallback, type Node, useEffect } from 'react';
import { not } from 'ramda';

import { RowLayoutWithSticky, RowLayoutContent, Header, Space } from 'app/common/layouts/RowLayout';
import { Planogram, Legend, LegendContainer, createPlanogramDataExt } from 'app/common/ui-components/planograms';
import PlainButton from 'app/common/ui-next/plain-button';
import Loading from 'app/common/components/Loader';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import ControlsBar from 'app/common/ui-next/controls-bar';
import type { ParamsPOST } from 'app/dataTypes/vending/PlanogramCopy.types';

import usePlanogramData from '../_planogram/usePlanogramData';
import usePlanorgamSubmit from './usePlanorgamSubmit';
import CopySlotsButton from './copySlots/CopySlotsButton';
import styles from './PlanogramSection.scss';


const planogramDragDisabled = {
  dragDisabled: true,
};

type Props = {
  header: string,
  loading?: boolean,
  machineId: string | null,
}

const PlanogramSection = ({ header, loading: loadingExternal, machineId }: Props): Node => {
  const [editMode, setEditMode] = useState(false);
  const editToggle = useCallback(() => {
    setEditMode(not);
  }, []);

  const {
    data,
    loading: loadingData,
    setPlanogramData,
    request: requestPlanogram,
  } = usePlanogramData(machineId, createPlanogramDataExt);

  useEffect(() => {
    requestPlanogram();
  }, [requestPlanogram]);

  const submitData = usePlanorgamSubmit(machineId, setPlanogramData);

  const handleCopySlotsSuccess = useCallback(({ toMachineId }: ParamsPOST) => {
    if (toMachineId === machineId) {
      requestPlanogram();
    }
  }, [machineId, requestPlanogram]);

  const loading = loadingData || loadingExternal;

  return (
    <RowLayoutWithSticky
      number={3}
      content={
        <RowLayoutContent borderBottom>
          <Header>
            {header}
          </Header>
        </RowLayoutContent>
      }
    >
      <RowLayoutContent>
        <ControlsBar className={styles.controls} spaceBetween>
          <PlainButton
            onClick={editToggle}
            danger={editMode}
            disabled={loading}
          >
            {editMode
              ? 'Выйти из режима редактирования'
              : 'Редактировать'}
          </PlainButton>

          <CopySlotsButton
            disabled={loading || !machineId}
            currentMachineId={machineId}
            onSuccess={handleCopySlotsSuccess}
          />
        </ControlsBar>

        <Space />

        <LegendContainer
          legend={
            loading || data.empty
              ? null
              : <Legend className={styles.legend} />
          }
        >
          <Planogram
            data={data}
            editMode={editMode}
            editOptions={planogramDragDisabled}
            onChangeData={submitData}
          >
            {loading
              ? <Loading />
              : <EmptyWarn skipLeftPadding>
                {'Данные планограммы отсутствуют для данного аппарата'}
              </EmptyWarn>}
          </Planogram>
        </LegendContainer>
      </RowLayoutContent>
    </RowLayoutWithSticky>
  );
};

export default PlanogramSection;
