// @flow
import path from 'ramda/es/path';
import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const GPDS_FILTER_REQUEST = 'reports/GPDS_FILTER_REQUEST';
export const GPDS_FILTER_SUCCESS = 'reports/GPDS_FILTER_SUCCESS';
export const GPDS_FILTER_FAILURE = 'reports/GPDS_FILTER_FAILURE';
export const GPDS_FILTER_CLEAR = 'reports/GPDS_FILTER_CLEAR';
export const GPDS_FILTER_DELETE = 'reports/GPDS_FILTER_DELETE';
export const GPDS_FILTER_SET = 'reports/GPDS_FILTER_SET';
export const GPDS_FILTER_UPDATE = 'reports/GPDS_FILTER_UPDATE';


export const gpdsFilterFetch = (orgId: number, yearId: number) => async (dispatch: Function) => {
  const params = { school_id: orgId, schoolyear_id: yearId };
  dispatch(action(GPDS_FILTER_REQUEST));
  try {
    const res = await api('reports/day_cares_list', 'get', { params });
    const gpds = path(['data', 'day_cares'], res);
    dispatch(action(GPDS_FILTER_SUCCESS, gpds));
    return gpds;
  } catch (error) {
    dispatch(action(GPDS_FILTER_FAILURE, {
      err: 'Не удалось получить данные фильтра по группам',
      error,
    }));
    return null;
  }
};
