// @flow
import { useState, useCallback, useEffect } from 'react';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import prop from 'ramda/es/prop';
import head from 'ramda/es/head';
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import pipe from 'ramda/es/pipe';

import { GET_SCHOOLYEARS } from 'app/common/api/requests/reports/schoolyears_list';
import { api } from 'app/api';


export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

const getStart = (year: number) => (`${year}-09-01`);
const getFinish = (year: number) => (`${year}-05-31`);

export const getDefaultStartFinish = (): [string, string] => {
  const now = new Date();
  const currentMonth = now.getMonth() + 1;

  const start = (currentMonth > 8
    ? getStart(now.getFullYear())
    : getStart(now.getFullYear() - 1));

  const finish = (currentMonth > 8
    ? getFinish(now.getFullYear() + 1)
    : getFinish(now.getFullYear()));

  return [start, finish];
};

export default function useDateLimits(orgId: number): [
  boolean,
  [string, string]
] {
  const [limits, setLimits] = useState<[string, string]>(getDefaultStartFinish());
  const [loading, setLoading] = useState(true);

  const requestLimits = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_SCHOOLYEARS, {
      error: 'Не удалось получить данные учебных лет',
      params: {
        school_id: orgId,
      },
    });
    if (res && !isEmpty(res)) {
      const items = pipe(
        values,
        sort(descend(prop('title'))),
      )(res);

      const { date_start: s, date_finish: f } = head(items);
      setLimits([s, f]);
    }
    setLoading(false);
  }, [orgId]);

  useEffect(() => {
    requestLimits();
  }, [requestLimits]);

  return [loading, limits];
}
