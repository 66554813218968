// @flow
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import trim from 'ramda/es/trim';

import { newKit } from 'app/actions/orgs/kits/new';
import { kitPriceEdit } from 'app/actions/orgs/kits/kitPriceEdit';

import { POST_NEW_COMPLEX } from 'app/common/api/requests/food/schools/complexes';
import { api } from 'app/api';

/**
 *  Hook создания/редактирования комплекта
 */

type Props = {
  kitId?: number,
  orgId: number,
  fields: {
    title: string,
    fixedPrice: string,
    fixedPriceEnabled: boolean,
  },
  setErrors: ([string, string] | ([string, string]) => [string, string]) => void,
}

export default function useNewEditKitFormSubmit({
  kitId,
  orgId,
  fields,
  setErrors,
}: Props): [
  (overrideOrgId?: number) => boolean | Promise<boolean>,
  () => boolean,
] {
  const { fixedPriceEnabled, fixedPrice, title } = fields;
  const dispatch = useDispatch();

  const checkErrors = useCallback(() => {
    const titleTrimmed = trim(title);
    if (!titleTrimmed) {
      setErrors(error => ['Введите название комплекта', error[1]]);
      return false;
    }
    return true;
  }, [title, setErrors]);

  const handleSubmit = useCallback(async (overrideOrgId?: number) => {
    const titleTrimmed = trim(title);

    // Создание нового комплекта
    if (!kitId) {
      const id = overrideOrgId || orgId;
      // создание комплекта для текущей организации
      if (id === orgId) {
        const err = await dispatch(newKit(orgId, {
          title: titleTrimmed,
          isFixedPrice: fixedPriceEnabled,
          fixedPrice,
        }));
        // setSaving(false);
        if (err === 422) {
          setErrors(error => ['Комплект с таким именем уже существует', error[1]]);
          return false;
        }
        if (!err) {
          return true;
        }
        return false;
      }
      // создание комплекта для другой организации
      // данные по комплектам текущей организации не будут изменены
      const res = await api.request(POST_NEW_COMPLEX, {
        error: 'Не удалось создать комплект',
        params: {
          title: titleTrimmed,
          fixed_price_enabled: fixedPriceEnabled,
          fixed_price: fixedPrice,
        },
        requestPathParams: {
          orgId: id,
        },
      });
      return Boolean(res);
    }

    // Редактирование существующего комплекта
    if (kitId) {
      const res = await dispatch(kitPriceEdit(orgId, kitId, {
        title,
        fixed_price_enabled: fixedPriceEnabled,
        fixed_price: fixedPrice,
      }));
      if (res) {
        return true;
      }
    }
    return false;
  }, [
    dispatch,
    fixedPrice,
    fixedPriceEnabled,
    kitId,
    orgId,
    setErrors,
    title,
  ]);

  return [handleSubmit, checkErrors];
}
