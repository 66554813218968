// @flow
import * as React from 'react';
import { Trans } from 'react-i18next';

import Checkbox from 'app/common/ui/Checkbox';

import LineInput from '../../ui/LineInput';
import Button from '../../ui/Button';
import styles from './Register.scss';


export const REG_BY_EMAIL: 'email' = 'email';
export const REG_BY_PHONE: 'phone' = 'phone';

type Fields = {
  login: string,
  firstName: string,
  lastName: string,
  middleName: string,
  privacy: boolean,
  terms: boolean,
};

type Docs = {
  terms_of_use_document_url: string,
  privacy_consent_document_url: string,
  privacy_policy_document_url: string,
};

type Props = {
  handleBack: (e: SyntheticEvent<HTMLElement>) => void,
  submitNewLogin: (e: SyntheticEvent<HTMLElement>) => void,
  submitting: boolean,
  wat: string,
  fields: Fields,
  handleFieldChange: (e: SyntheticEvent<HTMLElement>) => void,
  handleCheckChange: (e: SyntheticEvent<HTMLElement>) => void,
  handleShow: (e: SyntheticEvent<HTMLElement>) => void,
  readyToNext: boolean,
  docs: Docs,
  error: string,
  termsAcceptRequired: boolean,
  exampleFormat: string,
  availableRegTypes: Array<typeof REG_BY_EMAIL | typeof REG_BY_PHONE>,
  t: (s: string) => string,
};

const Register = ({
  handleBack,
  submitNewLogin,
  submitting,
  wat,
  fields,
  handleFieldChange,
  handleCheckChange,
  handleShow,
  readyToNext,
  docs,
  error,
  termsAcceptRequired,
  exampleFormat,
  availableRegTypes,
  t,
}: Props) => (<div className={styles.root}>
  <a href="back" className={styles.back} onClick={handleBack}>{'←'}</a>
  <div className={styles.header}>
    {(process.env.APP_NAME || process.env.REACT_APP_NAME) === 'cabinet'
      ? <Trans i18nKey="login:register_title">
        {'Регистрация в Личном кабинете родителя и учащегося'}
      </Trans>
      : <Trans i18nKey="login:register_not_yet_available">
        {'Регистрация для данного кабинета пока не реализована'}
      </Trans>}
  </div>
  {(process.env.APP_NAME || process.env.REACT_APP_NAME) === 'cabinet'
      && <form onSubmit={submitNewLogin}>
        <LineInput
          className={styles.input}
          name="login"
          placeholder={`${t('login:register_enter')} ${wat}*`}
          type="text"
          isError={!!error}
          errorText={error}
          onChange={handleFieldChange}
          value={fields.login}
          autoComplete="login"
          autoFocus
          comment={availableRegTypes.includes(REG_BY_PHONE)
            ? `${t('login:login_description')} ${exampleFormat}`
            : undefined}
          wide
        />
        <div className={styles.comment}>
          <Trans i18nKey="login:register_info" wat={wat}>
            {'В дальнейшем ваш '}
            {{ wat }}
            {' будет являться логином для авторизации в Личном кабинете и Мобильном приложении.'}
          </Trans>
        </div>
        <LineInput
          className={styles.input}
          name="lastName"
          placeholder={`${t('login:register_last_name')}*`}
          type="text"
          onChange={handleFieldChange}
          value={fields.lastName}
          autoComplete="family-name"
        />
        <LineInput
          className={styles.input}
          name="firstName"
          placeholder={`${t('login:register_name')}*`}
          type="text"
          onChange={handleFieldChange}
          value={fields.firstName}
          autoComplete="given-name"
        />
        <LineInput
          className={styles.input}
          name="middleName"
          placeholder={t('login:register_middle_name')}
          type="text"
          onChange={handleFieldChange}
          value={fields.middleName}
          autoComplete="additional-name"
        />
        {termsAcceptRequired
          && <div className={styles.check}>
            <Checkbox
              handleChange={handleCheckChange}
              checked={fields.terms}
              value="terms"
              label={
                <Trans i18nKey="login:register_agreement_accept">
                  {'Я принимаю условия '}
                  <a
                    href="terms_of_use"
                    className={styles.link}
                    onClick={handleShow}
                    name="terms_of_use_document_url"
                  >
                    {'пользовательского соглашения'}
                  </a>
                </Trans>
              }
            />
          </div>}
        <div className={styles.check}>
          <Checkbox
            handleChange={handleCheckChange}
            checked={fields.privacy}
            value="privacy"
            label={
              <Trans i18nKey="login:register_privacy_accept">
                {'Регистрируясь в Личном кабинете, я подтверждаю свою дееспособность и даю согласие на обработку своих персональных данных в соответствии с '}
                <a
                  href={docs.privacy_policy_document_url}
                  className={styles.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'политикой конфиденциальности и обработки персональных данных'}
                </a>
              </Trans>
            }
          />
        </div>

        <Button
          type="submit"
          className={styles.submit}
          disabled={submitting || !readyToNext}
        >
          {submitting
            ? t('login:register_submit_bt_loading')
            : t('login:register_submit_bt')}
        </Button>
      </form>}

</div>);

export default Register;
