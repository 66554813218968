// @flow
import { useState, useCallback, useEffect } from 'react';

import { api } from 'app/api';
import { GET_CHANGES, convertParams, convertResult } from 'app/common/api/requests/food/schools/changes';
import type { Change, Params } from 'app/dataTypes/OrgSettingsChanges.types';


export type State = [null | Change, () => Promise<void> | void]
export const defaultState: State = [null, () => {}];

export default function useLastChange(orgId: string | number, section: $PropertyType<Params, 'section'>): State {
  const [lastChange, setLastChange] = useState<null | Change>(null);

  const request = useCallback(async () => {
    const res = await api.request(GET_CHANGES, {
      error: 'Не удалось получить данные по изменениям настроек',
      params: {
        orgId,
        limit: 1,
        section,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      const [changes, count] = res;
      setLastChange(count ? changes[0] : null);
    }
  }, [orgId, section]);

  // сброс при перекоючении section
  useEffect(() => {
    setLastChange(null);
  }, [section]);

  return [lastChange, request];
}
