// @flow
import React from 'react';
import cn from 'classnames';

import styles from './CellGoodsAmount.scss';


const getAmountProps = (amount: number) => {
  if (amount > 80) {
    return {
      desc: 'Много',
      descClassName: styles.descMany,
      lineClassName: styles.lineMany,
    };
  }
  if (amount > 40) {
    return {
      desc: 'Средне',
      descClassName: styles.descMiddle,
      lineClassName: styles.lineMiddle,
    };
  }
  if (amount > 0) {
    return {
      desc: 'Мало',
      descClassName: styles.descLittle,
      lineClassName: styles.lineLittle,
    };
  }
  return {
    desc: 'Отсутствует',
    descClassName: styles.descNothing,
    lineClassName: styles.lineNothing,
  };
};

type Props = {
  amount: number,
}

const CellGoodsAmount = ({ amount = 100 }: Props) => {
  const { desc, lineClassName, descClassName } = getAmountProps(amount);

  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <div className={cn(styles.textDesc, descClassName)}>{desc}</div>
        <div>{`${amount}%`}</div>
      </div>
      <div className={styles.line}>
        <div
          className={cn(styles.lineColored, lineClassName)}
          style={{ width: `${amount}%` }}
        />
      </div>
    </div>
  );
};

export default CellGoodsAmount;
