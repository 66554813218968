// @flow
import { useState, useCallback, useMemo } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';

import { type Params as State } from 'app/common/api/requests/data/vending/machines/get';


const clearEmptyFields: (params: State) => State = filter((value) => {
  if (value instanceof Array) {
    if (isEmpty(value)) return false;
    if (value[0] === 0) return false;
  }
  if (value === 0) {
    return true;
  }
  return !!value;
});

export type VendingMachinesParams = [State, (State) => void]
export type { State };

const defaultState: State = Object.freeze({
  sort_by_school: true, // использовать при переключении типа группировки
});
export const defaultVendingMachinesParams: VendingMachinesParams = [defaultState, () => {}];

export default function useVendingMachinesParams(): VendingMachinesParams {
  const [state, setState] = useState<State>(defaultState);

  const updateState = useCallback((params: State) => {
    setState(currentParams => ({ ...currentParams, ...clearEmptyFields(params) }));
  }, []);

  const memoized: VendingMachinesParams = useMemo(() => [state, updateState], [state, updateState]);

  return memoized;
}
