// @flow
import reduce from 'ramda/es/reduce';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';


export type StateDataItem = interface {
  id: string,
  title: string,
  leftShiftLevel?: number,
  sortable?: number | string,
  disabled?: boolean,
}

export type StateDataItems = {
  [id: string]: StateDataItem
}

export type StateDataCurrent = Array<string>

export const defaultStateDataItems: StateDataItems = {}; // пустой фильтр
export const defaultStateDataCurrent: StateDataCurrent = []; // выбор фильтра по умолчанию, означает 'все'

export type State = {
  [filterId: string]: {
    filterId: string,
    items: StateDataItems, // возможные значения
    current: StateDataCurrent, // текущее состояние фильтра, только id (пустой массив - все)
    defaultCurrent: StateDataCurrent, // состояние фильтра по умолчанию
    active: boolean, // отображать ли фильтр
  }
}

export const emptyState: State = {};

export const getDefaultState = (filterIds: Array<string>) => {
  return reduce((acc, val) => {
    acc[val] = {
      filterId: val,
      items: defaultStateDataItems,
      current: defaultStateDataCurrent,
      defaultCurrent: defaultStateDataCurrent,
      active: false,
    };
    return acc;
  }, {}, filterIds);
};

export const getStateCurrentValue = (filterId: string, state: State): Array<string> => (
  path([filterId, 'current'], state)
);

export const getStateItems = (filterId: string, state: State): StateDataItems => (
  path([filterId, 'items'], state)
);

export const getStateIsFilterActive = (filterId: string, state: State): boolean => (
  path([filterId, 'active'], state)
);

export const getStateItemsAndCurrent = (filterId: string, state: State): [StateDataItems, Array<string>] => {
  const current = path([filterId, 'current'], state);
  const items = path([filterId, 'items'], state);
  return [items, current];
};

export const createStateCurrentValueFromFirstArray = (items: Array<StateDataItem>) => {
  const id = path([0, 'id'], items);
  return id ? [String(id)] : defaultStateDataCurrent;
};

export const getValue = <T=string>(filterId: string, applied: {}): Array<T> => (
  prop(filterId, applied) || []
);
