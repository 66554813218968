import rfilter from 'ramda/es/filter';
import { popup } from 'app/common/actions/popup';
import api from 'app/actions/api';


export const DISHES_ADD_REQUEST = 'DISHES_ADD_REQUEST';
export const DISHES_ADD_SUCCESS = 'DISHES_ADD_SUCCESS';
export const DISHES_ADD_FAILURE = 'DISHES_ADD_FAILURE';

const dishesAddRequest = () => ({
  type: DISHES_ADD_REQUEST,
});

const dishesAddSuccess = payload => ({
  type: DISHES_ADD_SUCCESS,
  payload,
});

const dishesAddFailure = payload => ({
  type: DISHES_ADD_FAILURE,
  payload,
});

export const DISHES_ADD_CHANGE_FIELD = 'DISHES_ADD_CHANGE_FIELD';
export const DISHES_ADD_CLEAR = 'DISHES_ADD_CLEAR';

export const dishesAddChangeField = payload => ({
  type: DISHES_ADD_CHANGE_FIELD,
  payload,
});

export const dishesAddClear = () => ({
  type: DISHES_ADD_CLEAR,
});


export const dishesAdd = () => async (dispatch, getState) => {
  const fields = rfilter(o => o, getState().dishes.add.fields);
  dispatch(dishesAddRequest());
  try {
    const res = await api('food/items', 'post', { data: fields });
    dispatch(dishesAddSuccess(res.data));
    dispatch(popup('Блюдо успешно создано'));
    dispatch(dishesAddClear());
  } catch (error) {
    dispatch(dishesAddFailure({ err: 'Не удалось создать блюдо', error }));
  }
};
