import { useCallback, useState } from 'react'

import { checkErrorsAccrualsFor } from './checkErrorsAccrualsFor'
import { checkErrorsAccruals } from './checkErrorsAccruals'
import { checkErrorsInclude } from './checkErrorsInclude'
import { checkErrorsApplies } from './checkErrorsApplies'


export type Errors = Map<string, string>

const emptyErrors: Errors = new Map()

export function useCafeteriaErrors(accrualsShiftsAmount: number) {
  const [errors, setErrors] = useState<Errors>(emptyErrors)

  const resetErrors = useCallback(() => {
    setErrors(emptyErrors)
  }, [])

  const checkErrors = useCallback((formData: FormData) => {
    const err = new Map()

    checkErrorsAccruals(err, formData, accrualsShiftsAmount)
    checkErrorsAccrualsFor(err, formData)
    checkErrorsInclude(err, formData)
    checkErrorsApplies(err, formData)

    if (err.size) {
      setErrors(err)
      return false
    }
    return true
  }, [accrualsShiftsAmount])

  return {
    errors,
    resetErrors,
    checkErrors,
  }
}
