// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';

import * as statuses from 'app/common/constants/identifiers/statuses';

import styles from './markup.scss';


type NumberProps = {
  children: Node,
}

export const Number = ({ children }: NumberProps) => (
  <span className={styles.number}>{children}</span>
);

type StatusProps = {
  children: Node,
  status: $Values<typeof statuses>
}

export const Status = ({ children, status }: StatusProps) => (
  <span className={classNames(styles[status])}>{children}</span>
);
