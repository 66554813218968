// @flow
import { useMemo } from 'react';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import fromPairs from 'ramda/es/fromPairs';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import isEmpty from 'ramda/es/isEmpty';
import complement from 'ramda/es/complement';
import anyPass from 'ramda/es/anyPass';
import isNil from 'ramda/es/isNil';

import { useFilterData } from 'app/common/ui-components/filter';
import { reportTypes, type ReportsPerformFinancesCafeteriaCashParams } from 'app/common/api/requests/reports/perform';

import orgsFilter from 'app/components/common/reports/filters/org';
import mailFilter, { readyToUse, onlyMail } from 'app/components/common/reports/filters/mail';
import detailsFilter from 'app/components/common/reports/filters/details';

import periodFilter from '../ReportFilter/filters/period';
import paymentSource from '../ReportFilter/filters/paymentSource';


const toInt = (val: string) => parseInt(val, 10);
const convertIntengers = (key: string) => pipe(prop(key), map(toInt));
const convertBoolean = (key: string) => pipe(path([key, 0]), Boolean);

type Source = {
  [key: string]: Array<string>
}

const convertionLimits = {
  school_ids: convertIntengers(orgsFilter),
  sources: prop(paymentSource),
};

const convertionParams = {
  details: convertBoolean(detailsFilter),
  from_date: path([periodFilter, 0]),
  to_date: path([periodFilter, 1]),
};

const convertionEmail = (email: ?string) => ({
  send_to_email: (source: Source) => (
    email && readyToUse(path([mailFilter, 0], source))
      ? email
      : null
  ),
  onlymail: (source: Source) => (
    Boolean(email && onlyMail(path([mailFilter, 0], source)))
  ),
});

const converter = (source: Source) => {
  return pipe(
    map(func => func(source)),
    filter(complement(anyPass([isNil, isEmpty]))),
  );
};

export type Params = ReportsPerformFinancesCafeteriaCashParams | null

export default function useGenerateParams() {
  const [,,, { getAppliedFilterActive }, filterParams] = useFilterData();

  const res: [Params, boolean] = useMemo(() => {
    const data = getAppliedFilterActive();
    if (!data) {
      return [null, false];
    }

    const source: Source = fromPairs(data);
    const email = filterParams && prop('email', filterParams);

    const convert = converter(source);

    const limits = convert(convertionLimits);
    const params = { ...convert(convertionParams), limits };
    const { onlymail, ...options } = convert(convertionEmail(email));
    const hasOptions = !isEmpty(options);

    return [{
      report_type: reportTypes.REPORT_FINANCES_CAFETERIA_CASH,
      params,
      ...(hasOptions ? { options } : Object.freeze({})),
    }, onlymail];
  }, [getAppliedFilterActive, filterParams]);

  return res;
}
