import get from 'app/common/lib/get';
import api from 'app/actions/api';

export const COUNTERPARTIES_FAILURE = 'props/COUNTERPARTIES_FAILURE';

export const counterpartiesFailure = payload => ({
  type: COUNTERPARTIES_FAILURE,
  payload,
});

// Запрос контрагентов, в редакс не используется
export const counterpartiesFetch = () => async (dispatch) => {
  try {
    const res = await api('food/org_types', 'get');
    return get(res, ['data', 'org_types'], []);
  } catch (error) {
    dispatch(counterpartiesFailure({ err: 'Не удалось получить контрагентов', error }));
    return null;
  }
};
