export const EMPLOYEES_FILTER_SET = 'EMPLOYEES_FILTER_SET';
export const EMPLOYEES_FILTER_CLEAR = 'EMPLOYEES_FILTER_CLEAR';


export const employeesFilterSet = payload => ({
  type: EMPLOYEES_FILTER_SET,
  payload,
});

export const employeesFilterClear = () => ({
  type: EMPLOYEES_FILTER_CLEAR,
});
