// @flow
import * as React from 'react';
import PlainInput from 'app/common/ui/PlainInput';
import withBalance from './hocs/withBalance';

import styles from './FilterItems.scss';


type Props = {
  min: string;
  max: string;
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  handleBlur: (e: SyntheticEvent<HTMLElement>) => void,
};

const BalanceFilter = ({ min, max, handleChange, handleBlur }: Props) => (<div className={styles.filter}>
  <div className={styles.label}>{'Сумма баланса:'}</div>
  {'от'}
&nbsp;
  <PlainInput
    name="min"
    value={min}
    onChange={handleChange}
    onBlur={handleBlur}
    className={styles.limitInput}
    hideErrorString
  />
    &nbsp;&nbsp;&nbsp;
  {'до'}
&nbsp;
  <PlainInput
    name="max"
    value={max}
    onChange={handleChange}
    onBlur={handleBlur}
    className={styles.limitInput}
    hideErrorString
  />
</div>);

export default withBalance(BalanceFilter);
