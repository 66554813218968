// @flow
import {
  SUBSIDIES_SET_REPORT_ID,
  SUBSIDIES_SET_XLS_FILE_ID,
  SUBSIDIES_RESET_PARAMS,
  SUBSIDIES_VIEW_PARAMS_CREATE,
} from 'app/actions/reports/subsidies';

import * as viewTypesAvailable from 'app/actions/reports/filters/viewTypesAvailable';
import * as subsidyTypesAvailable from 'app/actions/reports/filters/subsidyTypesAvailable';


export type SubsidiesParamsType = {
  viewType: $Values<typeof viewTypesAvailable>,
  subsidyType: $Values<typeof subsidyTypesAvailable>, // тип справки
  reportId: number,
  xlsFileId: number,
  details: boolean,
}

const defaultState: SubsidiesParamsType = {
  viewType: viewTypesAvailable.VIEW_TYPE_SUM,
  subsidyType: subsidyTypesAvailable.SUBSIDY_TYPE_ALL,
  reportId: 0,
  xlsFileId: 0,
  details: false, // используется для показа отчета на скачку только при детализированном запросе отчета
};

const params = (state: SubsidiesParamsType = defaultState, { type, payload }: Object) => {
  if (type === SUBSIDIES_RESET_PARAMS) {
    return defaultState;
  }
  if (type === SUBSIDIES_SET_REPORT_ID) {
    return { ...state, reportId: payload, xlsFileId: 0 };
  }
  if (type === SUBSIDIES_SET_XLS_FILE_ID) {
    return { ...state, xlsFileId: payload };
  }
  if (type === SUBSIDIES_VIEW_PARAMS_CREATE) {
    return { ...state, ...payload };
  }
  return state;
};

export default params;
