// @flow
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import fromPairs from 'ramda/es/fromPairs';
import uniqid from 'uniqid';

import toFixedValue from 'app/common/lib/toFixedValue';
import api from 'app/actions/api';
import action from 'app/common/lib/action';
import normalizeString from 'app/common/lib/normalizeString';


export const SUMSKITS_FILTER_REQUEST = 'reports/SUMSKITS_FILTER_REQUEST';
export const SUMSKITS_FILTER_SUCCESS = 'reports/SUMSKITS_FILTER_SUCCESS';
export const SUMSKITS_FILTER_FAILURE = 'reports/SUMSKITS_FILTER_FAILURE';
export const SUMSKITS_FILTER_CLEAR = 'reports/SUMSKITS_FILTER_CLEAR';
export const SUMSKITS_FILTER_DELETE = 'reports/SUMSKITS_FILTER_DELETE';
export const SUMSKITS_FILTER_SET = 'reports/SUMSKITS_FILTER_SET';
export const SUMSKITS_FILTER_UPDATE = 'reports/SUMSKITS_FILTER_UPDATE';

const prepareSums = pipe(
  data => path(['data', 'sums'], data) || [],
  map((sum) => {
    const id = uniqid.time('sum_');
    const title = toFixedValue(sum);
    return ([id, { id, title }]);
  }),
  fromPairs,
);

const prepareKits = pipe(
  data => path(['data', 'complexes'], data) || [],
  map(({ id, title }) => ([id, { id, title: normalizeString(title) }])),
  fromPairs,
);

export const sumsAndKitsFetch = (orgId: number) => async (dispatch: Function) => {
  const params = { school_id: orgId };
  dispatch(action(SUMSKITS_FILTER_REQUEST));
  try {
    const [res1, res2] = await Promise.all([
      api('reports/school_subsidy_orders_sums', 'get', { params }),
      api('reports/school_subsidy_complexes', 'get', { params }),
    ]);
    dispatch(action(SUMSKITS_FILTER_SUCCESS, {
      ...prepareSums(res1),
      ...prepareKits(res2),
    }));
  } catch (error) {
    dispatch(action(SUMSKITS_FILTER_FAILURE, {
      err: 'Не удалось получить данные фильтра по суммам/комплектам льготного питания',
      error,
    }));
  }
};
