// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames/bind';

import { Table, Tr, Th, Td } from 'app/common/ui-components/table';

import type { Multiple } from '../useReportData';
import styles from './MiddleMiddleContent.scss';


const cx = cn.bind(styles);

type Props = {
  className?: string,
  dates: $PropertyType<Multiple, 'dates'>,
  titles: $PropertyType<Multiple, 'titles'>,
  totalsColumns: $PropertyType<Multiple, 'totalsColumns'>,
  tableRef?: Ref<'table'>,
  trRef?: Ref<'tr'>,
  tbodyRef?: Ref<'tbody'>,
  thClassName?: string,
  tdClassName?: string,
  sameRowBase?: string,
  sameColumnBase?: string,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  children: (rowIndex: number, colIndex: number, rowKey: string, colKey: string) => Node,
}

const MiddleMiddleContent = ({
  className,
  dates,
  titles,
  totalsColumns,
  tableRef,
  trRef,
  tbodyRef,
  thClassName,
  tdClassName,
  sameRowBase,
  sameColumnBase,
  onMouseEnter,
  onMouseLeave,
  onCellMouseEnter,
  onCellMouseLeave,
  children,
}: Props): Node => {
  return (
    <Table
      reference={tableRef}
      className={className}
    >
      <thead>
        <Tr header hidden reference={trRef}>
          {dates.map(({ key, date }) => (
            <Th
              scope="col"
              key={key}
              className={thClassName}
              alignRight
            >
              {date}
            </Th>
          ))}
        </Tr>
      </thead>
      <tbody ref={tbodyRef}>
        {titles.map(({ key: rowKey }, i) => (
          <Tr
            key={rowKey}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={cn(sameRowBase && `${sameRowBase}${i}`)}
          >
            {dates.map(({ key: colKey }, j) => (
              <Td
                key={colKey}
                headers={cn(colKey, rowKey)}
                className={cx(
                  tdClassName,
                  sameColumnBase && `${sameColumnBase}${j}`,
                  { tinted: children(i, j, rowKey, colKey) === '0.00' },
                )}
                onMouseEnter={onCellMouseEnter}
                onMouseLeave={onCellMouseLeave}
                alignRight
              >
                {children(i, j, rowKey, colKey)}
              </Td>
            ))}
          </Tr>
        ))}
      </tbody>
      <tfoot>
        <Tr hidden>
          {totalsColumns.map(({ key, value }) => (
            <Td key={key}>{value}</Td>
          ))}
        </Tr>
      </tfoot>
    </Table>
  );
};

export default MiddleMiddleContent;
