// @flow
import { useMemo } from 'react';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import prop from 'ramda/es/prop';
import moment from 'moment-timezone';

import { getLastFirst, type Name } from 'app/common/lib/names';
import { type Employees, type Employee } from 'app/common/api/requests/food/employees';
import config from 'app/config';

import * as employeeTypes from '../../employeeTypes';


export type Item = {
  id: number,
  photo: string,
  birthdate: string,
  name: Name,
  nameLastFirst: string,
  nameMiddle: string,
  jobPlace: string,
  position: string,
  phone: string,
  email: string,
  ident: string,
  employeeType: $Values<typeof employeeTypes>,
  // inviteDate: string,
}

export default function useEAWTableItems(orders: Employees, employeeType: $Values<typeof employeeTypes>) {
  const items: Array<Item> = useMemo(() => pipe(
    values,
    sort(descend(prop('date'))),
    map(({
      id,
      photo,
      birthdate,
      name,
      gender,
      school_title: schoolTitle,
      role_title: roleTitle,
      phone,
      email,
      card,
      // invite_date: inviteDate,
    }: Employee) => {
      const mBirthdate: null | moment = birthdate && moment(birthdate);
      const nameValid = name && typeof name === 'object';
      const first = name.first || '';
      const last = name.last || '';
      const middle = name.middle || '';
      // const mInviteDate: null | moment = inviteDate && moment(inviteDate);

      return ({
        id,
        photo: photo || '',
        birthdate: (mBirthdate && mBirthdate.isValid()
          ? mBirthdate.format(config.dateFormatPresentative)
          : ''),
        name: { first, last, middle, gender: gender || 'androgynous' },
        nameLastFirst: (nameValid && getLastFirst(name)) || '',
        nameMiddle: (nameValid && name.middle) || '',
        jobPlace: schoolTitle || '—',
        position: roleTitle || '—',
        phone: phone || '—',
        email: email || '—',
        ident: card ? 'Есть' : '—',
        // inviteDate: (mInviteDate && mInviteDate.isValid()
        //   ? mInviteDate.format(config.dateFormatPresentative)
        //   : '—'),
        employeeType,
      }: Item);
    }),
  )(orders), [orders, employeeType]);

  return items;
}
