import { CompensationInfo, CompensationInfoStatus } from 'app/dataTypes/compensation/info'
import { cancelAvailable, recordAvailable } from 'app/dataProviders/compensation/status'

import { Column } from '../columns'
import { Status } from './Status'
import { Price } from './Price'
import { Action } from './Action'
import { InProgress } from './InProgress'


type Props = {
  columnKey: Column
  compensationInfo: CompensationInfo
  date: string
  onAction: (action: 'record' | 'cancel' | 'interrupt') => Promise<boolean>
  selectionMode: null | 'record' | 'cancel'
  selection: Set<string>
  onChangeSelection: (orgID: string, checked: boolean) => void
}

export const Cell = ({
  columnKey,
  compensationInfo,
  date,
  onAction,
  selectionMode,
  selection,
  onChangeSelection,
}: Props) => {
  const { status, orgTitle } = compensationInfo

  if (columnKey === Column.Title) {
    const { orgID } = compensationInfo
    const handlerID = (
      compensationInfo.status === CompensationInfoStatus.InProgress
      || compensationInfo.status === CompensationInfoStatus.Error
        ? compensationInfo.handlerID
        : undefined
    )

    const enableSelection = (
      (selectionMode === 'record' && recordAvailable(status))
      || (selectionMode === 'cancel' && cancelAvailable(status))
    )

    return (
      <Status
        status={status}
        title={orgTitle}
        handlerID={handlerID}
        orgID={orgID}
        selectionMode={enableSelection}
        selected={selection.has(orgID)}
        onClick={onChangeSelection}
      />
    )
  }

  if (columnKey === Column.Date) {
    return <div>{date}</div>
  }

  if (columnKey === Column.Action) {
    return <Action status={status} orgTitle={orgTitle} onAction={onAction} />
  }

  if (status === CompensationInfoStatus.InProgress) {
    if (columnKey === Column.Sum) {
      const { progress } = compensationInfo
      return <InProgress amount={progress} />
    }
  }

  if (status === CompensationInfoStatus.Ready) {
    if (columnKey === Column.Sum) {
      const { totalSum } = compensationInfo
      return <Price value={totalSum} />
    }

    if (columnKey === Column.Children) {
      const { studentsCount } = compensationInfo
      return <div>{studentsCount}</div>
    }
  }

  return <div>{'—'}</div>
}
