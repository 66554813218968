import { createSelector } from 'reselect';
import pick from 'ramda/es/pick';
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import filter from 'ramda/es/filter';


const selectMenuSellPoints = createSelector(
  [
    state => state.orgs.menu.sellPoints,
  ],
  sellPoints => pipe(
    values,
    filter(({ deleted }) => !deleted),
    map(pick(['id', 'title'])),
  )(sellPoints),
);

export default selectMenuSellPoints;
