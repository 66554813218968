// @flow
import React, { type Node, type Ref } from 'react';
import classNames from 'classnames/bind';

import Link from 'app/common/lib/link';
import { emptyObject } from 'app/common/lib/empty';

import styles from './plain-link-as-button.scss';


const cx = classNames.bind(styles);

export type PlainLinkAsButtonProps = {
  children: Node,
  to: string,
  outline?: boolean,
  disabled?: boolean,
  ariaLabel?: string,
  className?: string,
  labelClassName?: string,
  reference?: Ref<any>,
  openInSameWindow?: boolean,
  anchorForce?: boolean,
  tabIndex?: string,
}

const PlainLinkAsButton = ({
  children,
  to,
  outline,
  disabled,
  ariaLabel,
  className,
  labelClassName,
  reference,
  openInSameWindow,
  anchorForce,
  tabIndex,
}: PlainLinkAsButtonProps) => {
  const isFullPath = /^https?:\/\//.test(to);

  const props = {
    'area-label': ariaLabel,
    className: cx(styles.root, {
      default: !outline && !disabled,
      outline,
      disabled,
    }, className),
    ref: reference,
    ...(tabIndex ? { tabIndex } : emptyObject),
  };

  const content = (
    <span className={cx(styles.label, labelClassName)}>
      {children}
    </span>
  );

  if (disabled) {
    return (
      <a
        role="link"
        aria-disabled="true"
        {...props}
      >
        {content}
      </a>
    );
  }

  if (isFullPath || anchorForce) {
    return (
      <a
        href={to}
        target={openInSameWindow ? '_self' : '_blank'}
        rel={isFullPath && !openInSameWindow ? 'noopener noreferrer' : ''}
        {...props}
      >
        {content}
      </a>
    );
  }

  return (
    <Link to={to} {...props}>
      {content}
    </Link>
  );
};

export default PlainLinkAsButton;
