import { useCallback, useState, ReactNode } from 'react'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal'


type Props = {
  ID: string
  title: string
  onDeleteGroup: (ID: string) => Promise<boolean>
  disabled?: boolean
  children: ReactNode
}

const DeleteGroup = ({ ID, title, onDeleteGroup, disabled, children }: Props) => {
  const [confirmation, setConfirmation] = useState(false)
  const toggleConfirmation = useCallback(() => setConfirmation(state => !state), [])

  const handleDelete = useCallback(() => {
    setConfirmation(false)
    onDeleteGroup(ID)
  }, [ID, onDeleteGroup])

  return (
    <>
      <PlainButtonAsLink onClick={toggleConfirmation} disabled={disabled}>
        {children}
      </PlainButtonAsLink>

      {confirmation
        && <PlainConfirmModal
          messageText={`Вы уверены? Удалить группу «${title}»?`}
          onConfirm={handleDelete}
          onCancel={toggleConfirmation}
          critical
        />}
    </>
  )
}

export default DeleteGroup
