// @flow
import React, { useState, useCallback, type Node, Fragment } from 'react';

/**
 *  Временный компонент для открытия модальных окон,
 *  до того как эти окна будут переделаны в PlainModal
 */

type Props = {
  children: (open: () => void) => Node,
  startModal: (close: (e: SyntheticEvent<HTMLInputElement>) => void) => Node,
}

const TmpModalStarter = ({ children, startModal }: Props) => {
  const [opened, setOpened] = useState(false);

  const open = useCallback(() => setOpened(true), []);
  const close = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    if (typeof e === 'object' && e.preventDefault) {
      e.preventDefault();
    }
    setOpened(false);
  }, []);

  return (
    <Fragment>
      {children(open)}
      {opened && startModal(close)}
    </Fragment>
  );
};

export default TmpModalStarter;
