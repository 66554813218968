// @flow
import React from 'react';

import type { RenderCellContent } from 'app/common/ui-components/history-modal';

import FileHistoryOptions from './FileHistoryOptions';


const renderFileHistoryCellContent: RenderCellContent = (columnId, item) => {
  if (columnId === 'options') {
    return <FileHistoryOptions historyItem={item} />;
  }
  return item[columnId];
};

export default renderFileHistoryCellContent;
