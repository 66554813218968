import { useState, useCallback, useEffect } from 'react'
import moment from 'moment-timezone'
import { assocPath, pipe, evolve, map } from 'ramda'

import { api } from 'app/api'

import {
  defaultSubsidyOrdersRestrictions,
  GET_SUBSIDY_ORDERS_RESTRICTIONS,
  POST_SUBSIDY_ORDERS_RESTRICTIONS,
  type SubsidyOrdersRestrictions,
  dateFormat as apiDateFormat,
} from 'app/common/api/requests/food/schools/subsidy_orders_restrictions'

import { dateFormat } from './useDateLimits'
import useDateDefaults from './useDateDefaults'


export type { SubsidyOrdersRestrictions }

const date2api = <T extends Array<string>>(args: [...T]): T => {
  return map(date => moment(date, dateFormat).format(apiDateFormat), args) as T
}

const validateAndConvert = (date: string, format: string, defaultValue: string) => {
  const mDate = moment(date, format)
  if (date && mDate.isValid()) {
    const year = moment(defaultValue, dateFormat).get('year')
    return mDate.set('year', year).format(dateFormat)
  }
  return defaultValue
}

const api2date = (periodDefault: [string, string]) => (period: [string, string]): [string, string] => {
  const [default1, default2] = periodDefault
  const [date1, date2] = period
  return [
    validateAndConvert(date1, apiDateFormat, default1),
    validateAndConvert(date2, apiDateFormat, default2),
  ]
}

const state2api = (some: SubsidyOrdersRestrictions): SubsidyOrdersRestrictions => {
  const { common } = some
  return {
    ...some,
    common: {
      ...common,
      period_1: date2api(common.period_1),
      period_2: date2api(common.period_2),
    },
  }
}

type Oprions = {
  orgId: number,
}

type State = {
  state: SubsidyOrdersRestrictions,
  modified: boolean,
  // periodsLimit: [string, string],
  // limitsLoading: boolean,
}

type Actions = {
  request: () => Promise<void>,
  submit: (orgIds?: Array<number>) => Promise<boolean>,
  submitCommon: (orgIds?: Array<number>) => Promise<boolean>,
  submitPersonal: (orgIds?: Array<number>) => Promise<boolean>,
  change: (key: 'common_enabled' | 'common_type' | 'personal' | 'period_1' | 'period_2', value: any) => void,
  resetDates: () => void,
}

export default function useSubsidyOrdersRestrictionsOpt({ orgId }: Oprions): [State, Actions] {
  const [state, setState] = useState<SubsidyOrdersRestrictions>(defaultSubsidyOrdersRestrictions)
  const [modifiedCommon, setModifiedCommon] = useState(false)
  const [modifiedPersonal, setModifiedPersonal] = useState(false)

  // ограничения убраны, при возвращении учесть async
  // const [limitsLoading, periodsLimit] = useDateLimits(orgId)
  // const [limitStart, limitFinish] = periodsLimit
  const datePeriodsDefault = useDateDefaults()


  // даты из api, непривязанные к уч. году, привязываются к текущему учебному году
  const api2state = useCallback((state_: SubsidyOrdersRestrictions) => {
    return evolve({
      common: {
        period_1: api2date(datePeriodsDefault[0]),
        period_2: api2date(datePeriodsDefault[1]),
      },
    }, state_)
  }, [datePeriodsDefault])


  useEffect(() => {
    if (!modifiedCommon) {
      const [period1, period2] = datePeriodsDefault
      setState(pipe(
        assocPath(['common', 'period_1'], period1),
        assocPath(['common', 'period_2'], period2),
      ))
    }
  }, [datePeriodsDefault]) // eslint-disable-line react-hooks/exhaustive-deps


  const request = useCallback(async () => {
    const res: SubsidyOrdersRestrictions | null = await api.request(GET_SUBSIDY_ORDERS_RESTRICTIONS, {
      error: 'Не удалось получить настройки ограничений по созданию справок о льготах',
      requestPathParams: {
        orgId,
      },
    })
    if (res) {
      setState(api2state(res))
      setModifiedCommon(false)
      setModifiedPersonal(false)
    }
  }, [orgId, api2state])


  const submit = useCallback(async (overrideOrgIds?: Array<number>) => {
    const orgIds = overrideOrgIds || [orgId]
    const res = await api.request(POST_SUBSIDY_ORDERS_RESTRICTIONS, {
      error: 'Не удалось сохранить настройки ограничений справок периодами действий в течении учебного года',
      params: {
        schools_ids: orgIds.map(String),
        restrictions: state2api(state),
      },
    })
    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true
    }
    if (res) {
      setModifiedCommon(false)
      setModifiedPersonal(false)
    }
    return !!res
  }, [orgId, state])

  const submitCommon = useCallback(async (overrideOrgIds?: Array<number>) => {
    const orgIds = overrideOrgIds || [orgId]
    const res = await api.request(POST_SUBSIDY_ORDERS_RESTRICTIONS, {
      error: 'Не удалось сохранить настройки ограничений общих справок периодами действий в течении учебного года',
      params: {
        schools_ids: orgIds.map(String),
        restrictions: {
          common: state2api(state).common,
        },
      },
    })
    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true
    }
    if (res) {
      setModifiedCommon(false)
    }
    return !!res
  }, [orgId, state])

  const submitPersonal = useCallback(async (overrideOrgIds?: Array<number>) => {
    const orgIds = overrideOrgIds || [orgId]
    const res = await api.request(POST_SUBSIDY_ORDERS_RESTRICTIONS, {
      error: 'Не удалось сохранить настройки ограничений индивидуальных справок периодами действий в течении учебного года',
      params: {
        schools_ids: orgIds.map(String),
        restrictions: {
          personal: state.personal,
        },
      },
    })
    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true
    }
    if (res) {
      setModifiedPersonal(false)
    }
    return !!res
  }, [orgId, state])

  const change = useCallback((
    key: 'common_enabled' | 'common_type' | 'personal' | 'period_1' | 'period_2',
    value: any,
  ) => {
    if (key === 'common_enabled') {
      setState(assocPath(['common', 'enabled'], value))
      setModifiedCommon(true)
      return
    }
    if (key === 'common_type') {
      setState(assocPath(['common', 'type'], value))
      setModifiedCommon(true)
      return
    }
    if (key === 'personal') {
      setState(assocPath([key, 'enabled'], value))
      setModifiedPersonal(true)
      return
    }
    if (key === 'period_1' || key === 'period_2') {
      if (value[0] && value[1]) {
        setModifiedCommon(true)
        setState(assocPath(['common', key], value))
      }
    }
  }, [])


  const resetDates = useCallback(() => {
    const [period1, period2] = datePeriodsDefault
    setState(pipe(
      assocPath(['common', 'period_1'], period1),
      assocPath(['common', 'period_2'], period2),
    ))
  }, [datePeriodsDefault])


  return [{
    state,
    modified: modifiedCommon || modifiedPersonal,
    // periodsLimit,
    // limitsLoading,
  }, {
    request,
    submit,
    submitCommon,
    submitPersonal,
    change,
    resetDates,
  }]
}
