import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import config from 'app/config';


const orgsDishesDefaultState = {
  rows: config.perPageItems,
  title: true,
  photo: true,
  portion: true,
  cost: true,
  saleType: true,
  category: true,
  setup: true,
};

export default makeSettingsReducer('orgsDishes', orgsDishesDefaultState);
