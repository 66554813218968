import moment from 'moment-timezone';


import {
  DATE_FILTER_SET,
  DATE_FILTER_CLEAR,
  dateFormat,
} from 'app/actions/reports/filters/date';


const defaultState = {
  date: moment().format(dateFormat),
  current: true,
};

const date = (state = defaultState, { type, payload }) => {
  if (type === DATE_FILTER_SET) {
    return ({ ...state, ...payload });
  }
  if (type === DATE_FILTER_CLEAR) {
    return defaultState;
  }
  return state;
};

export default date;
