// @flow
import type { Request } from '../index';


type GetAllowancesResult = Array<string>

export const GET_ALLOWANCES: Request<
  void,
  GetAllowancesResult,
  void
> = [
  'food/allowances',
  'get',
  ['data', 'allowances'],
];
