// @flow
import React, { type Node, useMemo } from 'react';

import { FilterDataProvider } from 'app/common/ui-components/filter';
import type { Params } from 'app/dataTypes/OrgSettingsChanges.types';

import HistoryFilterParams from './HistoryFilterParams';
import type { FilterParams } from './FIlterParams.type';
import filterItems from './filterItems';


type Props = {
  children: (Params) => Node,
  ...FilterParams,
}

const HistoryFilterProvider = ({ children, ...others }: Props) => {
  const { orgId, section } = others;
  const params = useMemo(() => ({
    orgId,
    section,
  }), [orgId, section]);

  return (
    <FilterDataProvider
      filters={filterItems}
      params={params}
    >
      <HistoryFilterParams {...others}>
        {p => children(p)}
      </HistoryFilterParams>
    </FilterDataProvider>
  );
};

export default HistoryFilterProvider;
