// @flow
import React, { memo } from 'react';

import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import FiltersPlainLayout, { Column } from 'app/common/layouts/FiltersPlainLayout';
import { FilterItem, useFilterData } from 'app/common/ui-components/filter';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import ResetFilterLink from 'app/components/common/reports/ResetFilterLink';
import ReportSectionCSS from 'app/components/common/reports/ReportSectionCSS';

import orgTypesFilter from 'app/components/common/reports/filters/orgType';
import orgsFilter from 'app/components/common/reports/filters/org';
import buildingFilter from 'app/components/common/reports/filters/building';
import sellPointFilter from 'app/components/common/reports/filters/sellPoint';
import yearFilter from 'app/components/common/reports/filters/year';
// import customerTypeFilter from 'app/components/common/reports/filters/customerType';
import classFilter from 'app/components/common/reports/filters/class';
// import studentSubsidyTypeFilter from 'app/components/common/reports/filters/studentSubsidyType';
import detailsFilter from 'app/components/common/reports/filters/details';
import mailFilter from 'app/components/common/reports/filters/mail';
import studentFilter from 'app/components/common/reports/filters/student';
import employeeFilter from 'app/components/common/reports/filters/employee';
import orderTypeFilter from 'app/components/common/reports/filters/orderType';
import subsidyFilter from 'app/components/common/reports/filters/subsidy';
import sellTypeFilter from 'app/components/common/reports/filters/sellType';
import productionTypeFilter from 'app/components/common/reports/filters/productionType';
import foodModeFilter from 'app/components/common/reports/filters/foodMode';
// import useExtraText from 'app/components/common/reports/useExtraText';

// import periodFilter, { flags as period } from './filters/period';
import periodFilter from './filters/period';

import styles from './ReportFilter.scss';


type Props = {
  loading?: boolean,
}

const ReportFilter = memo < Props >(({ loading }: Props) => {
  const [, , , { resetAll, applyFilters }] = useFilterData();
  // const [, current,, { resetAll, applyFilters }] = useFilterData();

  // const extraText = useExtraText(period.finalDateInPreviosMonthOrBefore(current));
  const extraText = false;

  const extraContent = (
    extraText
      ? <PlainHelperText>{extraText}</PlainHelperText>
      : null
  );

  return (
    <ReportSectionCSS
      header="Фильтры отчета «Продажи по виду продукции»"
      stickyNumber={1}
      skipNextShift
      headerBar={
        <ResetFilterLink
          title="Сбросить все фильтры"
          onClick={resetAll}
        />
      }
    >
      {/* Окно с фильтром */}
      <RowLayoutContent paddingfree>
        <FiltersPlainLayout
          onApplyFilter={applyFilters}
          onResetAll={resetAll}
          loading={loading}
          extraContent={extraContent}
          skipHeader
        >
          <Column className={styles.column}>
            <FilterItem filterId={orgTypesFilter} />
            <FilterItem filterId={orgsFilter} />
            <FilterItem filterId={buildingFilter} />
            <FilterItem filterId={sellPointFilter} />
            {/* <FilterItem filterId={customerTypeFilter} /> */}
            <FilterItem filterId={classFilter} />
            <FilterItem filterId={studentFilter} />
            <FilterItem filterId={employeeFilter} />
            {/* <FilterItem filterId={studentSubsidyTypeFilter} /> */}
            <FilterItem filterId={detailsFilter} />
          </Column>
          <Column className={styles.column}>
            <FilterItem filterId={yearFilter} />
            <FilterItem filterId={periodFilter} />
            <FilterItem filterId={orderTypeFilter} />
            <FilterItem filterId={subsidyFilter} />
            <FilterItem filterId={sellTypeFilter} />
            <FilterItem filterId={productionTypeFilter} />
            <FilterItem filterId={foodModeFilter} />
            <FilterItem filterId={mailFilter} />
          </Column>
        </FiltersPlainLayout>
      </RowLayoutContent>
    </ReportSectionCSS>
  );
});

export default ReportFilter;
