import {
  CURRENT_ORG_REQUEST,
  CURRENT_ORG_SUCCESS,
  CURRENT_ORG_FAILURE,
} from 'app/actions/orgs/current';


const defaultState = {
  loading: false,
  schools: {},
  result: null,
  expired: 0,
};

const current = (state = defaultState, action) => {
  switch (action.type) {
    case CURRENT_ORG_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case CURRENT_ORG_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case CURRENT_ORG_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
  }
  return state;
};

export default current;
