// @flow
import isProduction from 'app/common/lib/isProduction';

import { getDomain } from '../lib/params';


// ОСНОВНОЙ API

export const generalApi = (
  isProduction()
    ? `https://api.${getDomain()}/rest/`
    : `https://api0.${getDomain('sms-dnevnik.com')}/rest/`
);

// API МАГАЗИНА

export const shopApi = (
  isProduction()
    ? 'https://apistore.sms-dnevnik.com/api/'
    : 'https://apistore0.sms-dnevnik.com/api/'
);

// API СОКЕТОВ

export const wsApi = (
  isProduction()
    ? 'wss://new-lk0.sms-dnevnik.com/ws'
    : 'wss://new-lk0.sms-dnevnik.com/ws'
);

// API ELJUR

export const eljurLoginApi = (
  isProduction()
    ? `https://auth.eljur.ru/auth/innovat/?redirect=${document.location.origin}/eljur`
    : `https://auth.eljur.ru/auth/innovat-test/?redirect=${document.location.origin}/eljur`
);

export const eljurApi = (
  isProduction()
    ? `${document.location.origin}/eljur/api/`
    : 'https://new-lk0.sms-dnevnik.com/eljur/api/'
);

// API ДЛЯ ОПРЕДЕЛЕНИЯ КИРГИЗСКОГО ПОЛЬЗОВАТЕЛЯ
// больше не используется
// export const apiUrlKG = 'https://api.elmektep.kg/rest/';
