// @flow
import React, { type Node } from 'react';

import FlagKg from './FlagKg';
import FlagRu from './FlagRu';
import FlagBua from './FlagBua';
import FlagKk from './FlagKk';


type Props = {
  lng: string,
  className?: string,
}

const Flag = ({ lng, className }: Props): Node => {
  switch (lng) {
    case 'ky':
      return <FlagKg className={className} />;
    case 'bua':
      return <FlagBua className={className} />;
    case 'kk':
      return <FlagKk className={className} />;
    default:
  }

  return <FlagRu className={className} />;
};

export default Flag;
