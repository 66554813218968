// @flow
import React from 'react';

import IconCross from 'app/common/icons/IconCrossBu';

import styles from './CrossButton.scss';


type Props = {
  id?: number | string,
  onCrossClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
}

const CrossButton = ({ id, onCrossClick }: Props) => (
  <button
    className={styles.crossButton}
    type="button"
    id={id}
    onClick={onCrossClick}
  >
    <IconCross className={styles.crossIcon} />
  </button>
);

export default CrossButton;
