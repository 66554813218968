// @flow
import { createSelector } from 'reselect';
import flatten from 'ramda/es/flatten';
import pipe from 'ramda/es/pipe';
import groupBy from 'ramda/es/groupBy';
import values from 'ramda/es/values';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';

import { tableColumnSort, tableColumnSortClass } from 'app/common/lib/sort';
import { getLastFirstM } from 'app/common/lib/names';


const selectConverted = createSelector(
  (_, { data }) => data,
  arr => arr.map((item) => {
    const {
      available_for_refund: availableForRefund,
      customer_id: customerId,
      customer_type: customerType,
      klass,
      role,
      name,
      ...others
    } = item;
    return ({
      availableForRefund,
      customerId,
      customerType,
      name: getLastFirstM(name),
      group: klass || role,
      ...others,
    });
  }),
);

export type IncomingProps = {
  id: number,
  name: string,
  group: string,
  balance: number,
  customerId: number,
  availableForRefund: number,
}

type Selector = (state: Object, props: Object) => Array<IncomingProps>

const group = groupBy(({ group: gp }) => gp);

const selectUsersByGroup: Selector = createSelector(
  [
    (_, { sort: { sortDirection1 } }) => sortDirection1,
    (_, { sort: { sortKey1 } }) => sortKey1,
    (_, { sort: { sortDirection2 } }) => sortDirection2,
    (_, { sort: { sortKey2 } }) => sortKey2,
    selectConverted,
  ],
  (dir1: string, key1: string, dir2: string, key2: string, users: Array<IncomingProps>) => pipe(
    group,
    values,
    tableColumnSortClass(dir1, path([0, key1])),
    map(tableColumnSort(dir2, prop(key2))),
    flatten,
  )(users),
);

export default selectUsersByGroup;
