import { useCallback, useEffect } from 'react'


type Ref = {
  current: HTMLElement | null
}

export default function useCorrectPosition(baseRef: Ref, targetRef: Ref, switching: boolean) {
  const correctDDPosition = useCallback(() => {
    const {
      top: rootTop = 0,
      height: rootHeight = 0,
    } = baseRef.current ? baseRef.current.getBoundingClientRect() : {}

    if (targetRef.current) {
      targetRef.current.setAttribute('style', `top: ${rootTop + rootHeight}px`)
    }
  }, [baseRef, targetRef])

  useEffect(() => {
    correctDDPosition()
  }, [switching]) // eslint-disable-line react-hooks/exhaustive-deps
}
