import { ReactNode } from 'react'

import useSubsidies from './useSubsidies'
import SubsidiesContext from './SubsidiesContext'


type Props = {
  children: ReactNode
}

const SubsidiesData = ({ children }: Props) => {
  const value = useSubsidies()

  return (
    <SubsidiesContext.Provider value={value}>
      {children}
    </SubsidiesContext.Provider>
  )
}

export default SubsidiesData
