import React from 'react';
import cond from 'ramda/es/cond';
import equals from 'ramda/es/equals';
import always from 'ramda/es/always';
import T from 'ramda/es/T';

import RestoreLink from './RestoreLink';


export const lang = {
  errorExist: 'Точка продаж уже существует',
  errorExistDeleted: 'Точка продаж уже существует в удаленных. ',
  restoreButtonText: 'Восстановить',
  restoreButtonTextInProcess: 'Восстановление...',
  popupRestoreItemSuccess: 'Точка продаж успешно восстановлена',
};

const renderAddSellPointError = (error, value, orgId) => (
  cond([
    [equals('exist'), always(lang.errorExist)],
    [equals('exist_deleted'), always(
      <RestoreLink value={value} orgId={orgId} />,
    )],
    // [equals('other'), always(null)],
    [T, always(null)],
  ])(error)
);

export default renderAddSellPointError;
