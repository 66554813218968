// @flow
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { useOrgsData, useDateFrom, useOrgsMapFromOrgs } from 'app/dataProviders/org/OrgsDataProvider';
import PlainInputDate from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import OrgsAndGroupsSelector from 'app/components/common/OrgsAndGroupsSelector';

import useOrgsSelector from './useOrgsSelector';
import type { Errors } from './errors';


export const dateFormat = 'YYYY-MM-DD';

type Props = {
  orgIdFrom: number,
  initialDateTo: string,
  disabled: boolean,
  onChangeOrgsSelected: (ids: Array<string>) => void,
  onChangeDateTo: (date: string) => void,
  errors: Errors,
  resetErrors: () => void,
}

const CopyKitFormOtherOrgs = ({
  orgIdFrom: orgId,
  initialDateTo,
  disabled,
  onChangeOrgsSelected,
  onChangeDateTo,
  errors,
  resetErrors,
}: Props) => {
  const [dateValue, setDateValue] = useState(initialDateTo);
  const handleDateChange = useCallback((newDate: string) => {
    setDateValue(newDate);
    onChangeDateTo(newDate);
    resetErrors();
  }, [onChangeDateTo, resetErrors]);

  const [{ orgs: orgsState, loading: loadingOrgs, currentOrg }] = useOrgsData();
  const actualFromDate = useDateFrom(orgsState, { orgId });
  const { type } = currentOrg;

  const orgsMap = useOrgsMapFromOrgs(orgsState);

  const orgID = String(orgId);
  const ignoreCurrent = useCallback(({ ID }: { ID: string }) => {
    return orgID !== ID;
  }, [orgID]);

  const [selectorProps] = useOrgsSelector(orgsState, { date: dateValue, orgId });
  const { selection: orgsSelected, selection, defaultSelection, onChange } = selectorProps;

  const disabledDays = useCallback((dayFormatted: string, format: string, unit?: string = 'day') => (
    actualFromDate
      ? !moment(dayFormatted, format).isAfter(actualFromDate, unit)
      : false
  ), [actualFromDate]);

  useEffect(() => {
    onChangeOrgsSelected(orgsSelected);
  }, [orgsSelected]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Fragment>
      <PlainLabel htmlFor="date_to" skipUpperSpace>
        {'На дату'}
      </PlainLabel>

      <PlainInputDate
        id="date_to"
        value={dateValue}
        onDateChange={handleDateChange}
        dateFormat={dateFormat}
        disabled={disabled}
        loading={loadingOrgs}
        dateDisabledStatus={disabledDays}
        error={Boolean(errors.date)}
        calendarCloseOnClick
        displayAsBlock
      />

      <PlainHelperText overflowVisible error>
        {errors.date}
      </PlainHelperText>

      <OrgsAndGroupsSelector
        orgType={type}
        label="В заведения"
        header="Выберите куда копировать комплект"
        pleaseSelectText="Выберите заведение"
        loading={loadingOrgs}
        disabled={disabled}
        orgs={orgsMap}
        multiple
        selectedAllOnEmptySelection={false}
        selection={selection}
        defaultSelection={defaultSelection}
        onChange={onChange}
        filterFunc={ignoreCurrent}
      />

      {errors.org
        && <PlainHelperText overflowVisible error>
          {errors.org}
        </PlainHelperText>}
    </Fragment>
  );
};

export default CopyKitFormOtherOrgs;
