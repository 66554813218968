import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { api } from 'app/api'
import { GET_SCHOOLYEARS, convertParams, convertResult } from 'app/common/api/requests/reports/schoolyears_list/get'
import { SchoolYearListType } from 'app/dataTypes/schoolYearsList'
import { ItemType as ItemOriginal } from 'app/common/ui-next/plain-selector'

import SimpleDate from 'app/common/classes/simple-date'

import { orgsFilterSelection } from './orgsFilter'


type Item = ItemOriginal & { dateStart: SimpleDate, dateFinish: SimpleDate }

type SchoolYearsFilter = Array<Item>

class SchoolYearFilterDataBox extends DataBox<SchoolYearsFilter> { }

export const defaultSchoolYear: SchoolYearsFilter = []
export const schoolYearFilter = createDataBox('schoolYearFilter', defaultSchoolYear, SchoolYearFilterDataBox)

export const defaultSchoolYearSelection: Array<string> = []
export const schoolYearFilterSelection = createDataBoxDefault<Array<string>>('schoolYearFilterSelection', defaultSchoolYearSelection)


const convertFromExtended = (schoolYear: SchoolYearListType): SchoolYearsFilter => {
  return schoolYear.map(({ ID, current, ...others }) => {
    if (current) {
      defaultSchoolYearSelection.pop()
      defaultSchoolYearSelection.push(ID)
    }
    return ({ id: ID, current, ...others })
  })
}

export const defaultSchoolYearDataReceiver = async (orgsSelection?: Array<string>) => {
  const [schoolID] = orgsSelection || orgsFilterSelection.getData() || [null]
  if (!schoolID) return []

  const res = await api.request(GET_SCHOOLYEARS, {
    error: 'Не удалось получить данные учебных лет',
    params: {
      schoolID,
    },
    convertions: {
      convertParams,
      convertResult,
    },
  })

  return res ? convertFromExtended(res) : []
}
