// @flow
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { not } from 'ramda';

// import FiltersPlainLayout, { Column } from 'app/common/layouts/FiltersPlainLayout';
import { FilterItem, useFilterActions, useFilterAppliedRaw } from 'app/common/ui-components/filter';
import FiltersDropDownLayout, { Column } from 'app/common/layouts/FiltersDropDownLayout';
import ControlsBar from 'app/common/ui-next/controls-bar';
import { OpenFilterButton } from 'app/common/ui-next/plain-button';

import filterPeriod from './period';
import filterSource from './source';
import filterActor from './actor';

import styles from './FilterWindow.scss';


type Props = {
  loading: boolean,
}

const FilterWindow = ({ loading }: Props) => {
  const { filtersLoad, resetAll, applyFilters } = useFilterActions();
  const { applied } = useFilterAppliedRaw();

  useEffect(() => {
    if (!loading) {
      filtersLoad();
    }
  }, [loading]);

  const [displayed, setDisplayed] = useState(false);
  const toggleDisplayed = useCallback(() => setDisplayed(not), []);

  return (
    <Fragment>
      <ControlsBar>
        {/* Кнопка открытия фильтров */}
        <OpenFilterButton
          checked={!!applied}
          opened={displayed}
          onClick={toggleDisplayed}
        />
      </ControlsBar>

      {displayed
        && <FiltersDropDownLayout
          onClose={toggleDisplayed}
          className={styles.filterContainer}
          arrowClassName={styles.filterArrow}
          loading={loading}
          onApplyFilter={applyFilters}
          onResetAll={resetAll}
        >
          <Column>
            <FilterItem filterId={filterPeriod} />
          </Column>
          <Column>
            <FilterItem filterId={filterSource} />
          </Column>
          <Column>
            <FilterItem filterId={filterActor} />
          </Column>
        </FiltersDropDownLayout>}
    </Fragment>
  );
};

export default FilterWindow;
