// @flow
import React, { ReactNode } from 'react'
import moment from 'moment-timezone'
import compose from 'recompose/compose'
import { isEmpty } from 'ramda'

import { withKitsKitsRights, withCurrentOrgRights /* , omitExtra */ } from 'app/containers/rights'
import { withWriteAccess } from 'app/common/containers/withRights'
import PlainButton from 'app/common/ui-next/plain-button'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainStatusFilter from 'app/common/ui-next/plain-status-filter'
import ControlsBar, { ControlsBarSpace } from 'app/common/ui-next/controls-bar'
import { PageTemplateContent, Header2 } from 'app/common/templates-next/page-template'
import Sticky from 'app/common/templates-next/sticky'
import { useGeneralSettings } from 'app/dataProviders/generalSettings/hooks'

import KitsListSimple from './KitsList/KitsListSimple'
import KitsListExt from './KitsList/KitsListExt'
import NewEditKit from './NewEditKit'
import DateNavigation from '../DateNavigation'
import { status2TranslationMap, status2ColorMap } from './statusProps'
import { CopyKitModal } from './CopyKit'
import useKits from './useKits'
import useKitsIDs from './useKitsIDs'
import useKitsTestFixedPriceFlag from './useKitsTestFixedPriceFlag'
import { HeaderOrgsKits } from './HeaderOrgsKits'

import styles from './OrgsKits.scss'

// type OrgsTypes = typeof import('app/common/constants/schools/types');

const writeAccess = compose(withCurrentOrgRights, withWriteAccess, withKitsKitsRights, withWriteAccess)
// const AccessedButton = writeAccess(omitExtra(Button));
const AccessedNewEditKit = writeAccess(NewEditKit)

type Props = {
  orgID: string,
  orgType: string,
  kitID?: string,
  date: string,
  dateFormat: string,
  filter?: string,
  redirectToDate: (day: string, format: string) => void,
  redirectToFilter: (filter: string) => void,
  basePath: string,
}

const OrgsKits = ({
  orgID,
  orgType,
  kitID,
  date,
  dateFormat,
  filter,
  redirectToDate,
  redirectToFilter,
  basePath,
}: Props): ReactNode => {
  const mDate = moment(date)
  const kits = useKits()
  const fixedPriceEnabled = useKitsTestFixedPriceFlag(kits)
  const { generalSettings } = useGeneralSettings()
  const kitIDs = useKitsIDs(kits)

  return (
    <div className={styles.root}>
      <Sticky>
        {ref => (
          <PageTemplateContent className={styles.hasPopup} reference={ref} horizontalLayout>
            <Header2 skipMargin wide>
              <HeaderOrgsKits action={filter} mDate={mDate} />
            </Header2>
            <DateNavigation
              date={date}
              dateFormat={dateFormat}
              onDateChange={redirectToDate}
            />
          </PageTemplateContent>
        )}
      </Sticky>

      <Sticky>
        {(ref, _, shadow) => (
          <PageTemplateContent reference={ref} shadowBottom={shadow}>
            <ControlsBar>
              {/* Добавление нового комплекта или редактирование */}
              {filter !== 'deleted'
                && filter !== 'hidden'
                && <AccessedNewEditKit orgId={parseInt(orgID, 10)}>
                  {toggleModal => (
                    <PlainButton onClick={toggleModal}>{'Создать комплект'}</PlainButton>
                  )}
                </AccessedNewEditKit>}

              {/* Копировать все комплекты */}
              {filter !== 'deleted'
                && filter !== 'hidden'
                && !isEmpty(kitIDs)
                && <CopyKitModal
                  orgID={orgID}
                  kitID={kitIDs}
                  header="Копирование всех комплектов"
                  date={date}
                  fixedPriceEnabled={fixedPriceEnabled}
                >
                  {toggle => (
                    <PlainButtonAsLink onClick={toggle}>{'Копировать все комплекты'}</PlainButtonAsLink>
                  )}
                </CopyKitModal>}

              <ControlsBarSpace />

              <PlainStatusFilter
                currentStatus={filter ?? ''}
                onClickItem={redirectToFilter}
                status2ColorMap={status2ColorMap}
                status2TranslationMap={status2TranslationMap}
                title="Показать"
              />
            </ControlsBar>
          </PageTemplateContent>
        )}
      </Sticky>

      <PageTemplateContent>
        {(filter === 'deleted' || filter === 'hidden')

          ? <KitsListSimple
            act={filter}
            orgID={orgID}
          />

          : <KitsListExt
            action={filter}
            orgID={orgID}
            orgType={orgType}
            useGrocerySetPrice={generalSettings.useGrocerySetPrice}
            kitID={kitID}
            date={date}
            basePath={basePath}
          />}
      </PageTemplateContent>
    </div>
  )
}

export default OrgsKits
