import { map, pipe, reject } from 'ramda'

import { Org, OrgsData } from 'app/dataTypes/org'
import { sortByTitle } from 'app/common/lib/sort'

import useRequestOrgs, { Options } from './useRequestOrgs'


type SimpleOrgItem = {
  ID: string
  title: string
}

type SimpleOrgsData = {
  items: Array<SimpleOrgItem>
  total: number
}

const defaultState: SimpleOrgsData = {
  items: [],
  total: 0,
}

const transform = ({ items, total }: OrgsData): SimpleOrgsData => {
  return {
    items: pipe(
      reject<Org>(({ deleted }) => deleted),
      map(({ ID, title }) => ({ ID, title })),
      sortByTitle,
    )(Array.from(items.values())),
    total,
  }
}

export function useOrgsDataSimple(options: Omit<Options<SimpleOrgsData>, 'transform' | 'defaultState'>) {
  const res = useRequestOrgs({
    ...options,
    transform,
    defaultState,
  })
  return res
}
