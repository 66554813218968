// @flow
import React, { ChangeEvent, Fragment, SyntheticEvent, useCallback } from 'react'
import keys from 'ramda/es/keys'
import assoc from 'ramda/es/assoc'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import PlainFieldset from 'app/common/ui-next/plain-fieldset'
import { EmailForm } from 'app/components/common/email'
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider'

import { type Checks } from './useOptions'


type Props = {
  email: string,
  emailEnabled: boolean,
  emailError: string,
  disabled: boolean,
  checks: Checks,
  changeSoldItemsEnabled: boolean,
  copySettingsEnabled: boolean,
  keepGrocerySetPriceEnabled: boolean,
  keepComplexPriceEnabled: boolean,
  keepComplexPriceLabel?: string,
  onChangeChecks: (ch: ((ch: Checks) => Checks) | Checks) => void,
  onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void,
}

export const CopyKitFormOptions = ({
  email,
  emailEnabled,
  emailError,
  disabled,
  checks,
  changeSoldItemsEnabled,
  copySettingsEnabled,
  keepGrocerySetPriceEnabled,
  keepComplexPriceEnabled,
  keepComplexPriceLabel = 'Не изменять стоимость комплекта',
  onChangeChecks,
  onChangeEmail,
}: Props) => {
  const [{ loading }] = useOrgsData()

  const handleChangeOp = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget
    if (keys(checks).includes(value as keyof Checks)) {
      onChangeChecks(assoc(value as keyof Checks, checked))
    }
  }, [checks, onChangeChecks])

  return (
    <Fragment>
      <PlainFieldset
        name="options"
        legend="Опции"
      >
        {copySettingsEnabled
          && <PlainCheckbox
            label="Копировать настройки комплекта"
            value="copySettings"
            disabled={disabled || loading}
            checked={checks.copySettings}
            onChange={handleChangeOp}
          />}
        {copySettingsEnabled
          && <PlainCheckbox
            label="Копировать дополнительные настройки комплекта"
            value="copyExtendedSettings"
            disabled={disabled || loading}
            checked={checks.copyExtendedSettings}
            onChange={handleChangeOp}
          />}
        {changeSoldItemsEnabled
          && <PlainCheckbox
            label="Внести изменения в уже созданные заявки"
            value="changeSoldItems"
            disabled={disabled || loading}
            checked={checks.changeSoldItems}
            onChange={handleChangeOp}
          />}
        <PlainCheckbox
          label="Копировать фотографию комплекта"
          value="copyComplexPhoto"
          disabled={disabled || loading}
          checked={checks.copyComplexPhoto}
          onChange={handleChangeOp}
        />
        {keepComplexPriceEnabled
          && <PlainCheckbox
            label={keepComplexPriceLabel}
            value="keepComplexPrice"
            disabled={disabled || loading}
            checked={checks.keepComplexPrice}
            onChange={handleChangeOp}
          />}
        {keepGrocerySetPriceEnabled && <PlainCheckbox
          label="Не изменять стоимость продуктового набора"
          value="keepGrocerySetPrice"
          disabled={disabled || loading}
          checked={checks.keepGrocerySetPrice}
          onChange={handleChangeOp}
        />}

      </PlainFieldset>

      {emailEnabled
        && <EmailForm
          label="Копирование может занять продолжительное время. После подтверждения вы будете перенаправлены в раздел «Комплекты», а по завершении на указанный ниже e-mail будет выслан отчёт."
          disabled={disabled || loading}
          value={email}
          error={emailError}
          onChange={onChangeEmail}
        />}
    </Fragment>
  )
}
