// @flow
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import usePopup from 'app/common/hooks/usePopup';
import { kitDelete } from 'app/actions/orgs/kits/delete';
import { kitHide } from 'app/actions/orgs/kits/hide';
import { kitRestore } from 'app/actions/orgs/kits/restore';


type Actions = {
  deleteKit: () => Promise<void>,
  hideKit: () => Promise<void>,
  restoreKit: () => Promise<void>,
}

export default function useKitActions(orgId: number, id: number): [Actions, boolean] {
  const dispatch = useDispatch();
  const popup = usePopup();
  const [processing, setProcessing] = useState(false);

  const handleDelete = useCallback(async () => {
    setProcessing(true);
    const res = await dispatch(kitDelete(orgId, id));
    if (res) {
      popup('Комплект успешно удален');
    }
    setProcessing(false);
  }, [dispatch, popup, id, orgId]);

  const handleHide = useCallback(async () => {
    setProcessing(true);
    const res = await dispatch(kitHide(orgId, id));
    if (res) {
      popup('Комплект успешно скрыт');
    }
    setProcessing(false);
  }, [dispatch, popup, id, orgId]);

  const handleRestore = useCallback(async () => {
    setProcessing(true);
    const res = await dispatch(kitRestore(orgId, id));
    if (res) {
      popup('Комплект успешно восстановлен');
    }
    setProcessing(false);
  }, [dispatch, popup, id, orgId]);

  const actions = {
    deleteKit: handleDelete,
    hideKit: handleHide,
    restoreKit: handleRestore,
  };

  return [actions, processing];
}
