import { reduce } from 'ramda'

import { CompensationDetailsParams, CompensationDetails } from 'app/dataTypes/compensation/details'
import Person from 'app/common/classes/person'
import { v4 as uuid } from 'uuid'

import type { Request } from '../../../index'
import { UrlParams } from '../_common'


type ApiParams = {
  year: number
  month: number
  allowance: string
}

export const convertParams = (params: CompensationDetailsParams): [ApiParams, UrlParams] => {
  const { orgID, year, month, allowanceID } = params
  return [{
    year: parseInt(year, 10),
    month: parseInt(month, 10),
    allowance: allowanceID,
  }, {
    orgID,
  }]
}

type ApiResult = Array<{
  klass_title: string
  student_name: {
    last: string
    first: string
    middle: string
  },
  days_count: number
  sum: number
}>

export const convertResult = (students: ApiResult): CompensationDetails => {
  return reduce((acc, val) => {
    const { klass_title: classTitle, student_name: name, days_count: days, sum } = val
    acc.students.push({
      ID: uuid(),
      classTitle,
      name: new Person(name),
      days,
      sum,
    })
    acc.total.students += 1
    acc.total.days += days
    acc.total.sum += sum
    return acc
  }, { students: [], total: { students: 0, days: 0, sum: 0.0 } } as CompensationDetails, students)
}

export const GET_COMPENSATION_DETAILS: Request<CompensationDetailsParams, CompensationDetails, UrlParams> = [
  'food/compensation/{orgID}/details',
  'get',
  ['data', 'students'],
]
