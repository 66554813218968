import { ReportParams } from 'app/dataTypes/reports'
import { ReportStatus } from 'app/dataTypes/reports/status'

import type { Request } from '../../index'


type ApiParams = {
  report_id: number,
}

export const convertParams = (params: ReportParams): [ApiParams, null] => {
  const { reportID } = params
  return [{ report_id: parseInt(reportID, 10) }, null]
}

export const convertResult = (res: number): ReportStatus => {
  return res
}

export const GET_REPORTS_STATUS: Request<
  ReportParams,
  ReportStatus,
  void
> = ['reports/status', 'get', ['data', 'status_in_percents']]
