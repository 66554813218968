// @flow
import React, { Fragment } from 'react';


type Props = {
  lines: Array<string>
}

const CellMultiline = ({ lines }: Props) => {
  return (
    <Fragment>
      {lines.map((s, i) => (
        <div key={i}>{s}</div>
      ))}
    </Fragment>
  );
};

export default CellMultiline;
