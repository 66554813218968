// @flow
import * as React from 'react';
import values from 'ramda/es/values';

import PlainInput from 'app/common/ui/PlainInput';
import RadioButton, { RadioButtonGroup } from 'app/common/ui/RadioButton';
import { BALANCE_USER } from 'app/actions/reports/filters/commonBalance';

import withCommonBalance from './hocs/withCommonBalance';
import styles from './FilterItems.scss';


type Props = {
  min: string,
  max: string,
  data: Object,
  selected: string,
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  handleBlur: (e: SyntheticEvent<HTMLElement>) => void,
  handleSwitch: (e: SyntheticEvent<HTMLElement>) => void,
};

const CommonBalanceFilter = ({
  min,
  max,
  data,
  selected,
  handleChange,
  handleBlur,
  handleSwitch,
}: Props) => (<div className={styles.filter}>
  <div className={styles.label}>{'Значение баланса:'}</div>
  <RadioButtonGroup
    id="select_limits"
    name="select_limits"
    valueSelected={selected}
    onChange={handleSwitch}
    className={styles.radioGroup}
  >
    {values(data).map(({ id, title }) => (id === BALANCE_USER
      ? <RadioButton
        key={id}
        label={title}
        additional={
          <span className={styles.dip}>
              &nbsp;&nbsp;&nbsp;
            {'от'}
&nbsp;
            <PlainInput
              name="min"
              value={min}
              onChange={handleChange}
              onBlur={handleBlur}
              className={styles.limitInputShort}
              disabled={selected !== BALANCE_USER}
              hideErrorString
            />
              &nbsp;&nbsp;&nbsp;
            {'до'}
&nbsp;
            <PlainInput
              name="max"
              value={max}
              onChange={handleChange}
              onBlur={handleBlur}
              className={styles.limitInputShort}
              disabled={selected !== BALANCE_USER}
              hideErrorString
            />
          </span>
        }
        value={id}
      />
      : <RadioButton
        key={id}
        label={title}
        value={id}
      />))}
  </RadioButtonGroup>

</div>);

export default withCommonBalance(CommonBalanceFilter);
