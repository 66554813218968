// @flow
import React, { useCallback, useState, memo, useEffect } from 'react';
import all from 'ramda/es/all';
import equals from 'ramda/es/equals';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
} from 'app/common/ui-next/plain-modal';

import OrgsDataProvider from 'app/dataProviders/org/OrgsDataProvider';
import { POST_COPY_ITEMS_BETWEEN_SCHOOLS } from 'app/common/api/requests/food/items/copy_items_between_schools';
import { api } from 'app/api';
import usePopup from 'app/common/hooks/usePopup';
import getNumEnding from 'app/common/lib/getNumEnding';
import * as orgsTypes from 'app/common/constants/schools/types';
import type { OrgItem } from 'app/common/api/requests/food/schools';

import CopyCheckedForm from './CopyCheckedForm';
import CopyCheckedControls from './CopyCheckedControls';
import useCheckedOrgs from './useCheckedOrgs';


const orgsFilterCb = (item: OrgItem): boolean => {
  const { cafeteria_enabled: cafeteriaEnabled, type } = item;
  if (type === orgsTypes.ENTERPRISE) return cafeteriaEnabled;
  return true;
};

type Props = {
  orgId: number,
  show: boolean,
  names: Array<string>,
  ids: Array<number>,
  onDisplayStatusChange: (boolean) => void,
}

const CopyCheckedModal = ({
  orgId,
  show,
  names,
  ids,
  onDisplayStatusChange,
}: Props) => {
  const popup = usePopup();
  const [saving, setSaving] = useState(false);
  const [checkedOrgs, changeCheckedOrgs, resetCheckedOrgs] = useCheckedOrgs();
  const nothingSelected = checkedOrgs.size === 0;

  useEffect(() => {
    if (!show) {
      resetCheckedOrgs();
    }
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = useCallback(async () => {
    setSaving(true);
    const orgsTo = Array.from(checkedOrgs);
    const res = await Promise.all(orgsTo.map(id => api.request(POST_COPY_ITEMS_BETWEEN_SCHOOLS, {
      error: `Не удалось скопировать продукцию в\u00A0заведение с\u00A0идентификатором ${id}`,
      params: {
        school_id_from: orgId,
        school_id_to: id,
        items_ids: ids,
      },
    })));
    setSaving(false);

    if (all(equals('success'), res)) {
      const ending = getNumEnding(orgsTo.length, ['заведение', 'заведения', 'заведений']);
      popup(`Продукция успешно скопирована в\u00A0${orgsTo.length}\u00A0${ending}`);
      return true;
    }
    return false;
  }, [checkedOrgs, ids, orgId, popup]);

  return (
    <OrgsDataProvider
      orgId={orgId}
      filterCb={orgsFilterCb}
      excludeCurrent
      sameType
    >
      <PlainModal
        formId="copyTo"
        show={show}
        onSubmit={submit}
        onDisplayStatusChange={onDisplayStatusChange}
      >
        <PlainModalHeader>
          {'Копировать в другое заведение'}
        </PlainModalHeader>
        <PlainModalContent>
          <CopyCheckedForm
            names={names}
            checkedOrgs={checkedOrgs}
            onChange={changeCheckedOrgs}
            disabled={saving}
          />
        </PlainModalContent>
        <PlainModalFooter>
          {toggleModal => (
            <CopyCheckedControls
              toggleModal={toggleModal}
              saving={saving}
              disableSubmit={nothingSelected}
            />
          )}
        </PlainModalFooter>
      </PlainModal>
    </OrgsDataProvider>
  );
};

export default memo<Props>(CopyCheckedModal);
