// @flow
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as actions from 'app/actions/reports/filters/foodModes';
import { allFoodModes } from 'app/dataTypes/foodModes';
import * as availableSalesTypes from 'app/actions/reports/filters/salesTypesAvailable';

import { selectOrgId, selectSalesType } from './hocs/commonSelectors';
import dataObjectCreate, { type DataObject } from './helpers/dataObjectCreate';
import styles from './FilterItems.scss';

/**
 *  Фильтр по режимам питания
 *  Актуален при выборе типа продаж "Комплексное питание"
 */

const availableFoodModes: Array<string> = (allFoodModes: any);
const translate = {
  breakfast: 'Завтрак',
  dinner: 'Обед',
  afternoon: 'Полдник',
};
const itemsObj = dataObjectCreate(availableFoodModes, translate);

const selectIsEnableDefault = createSelector(
  selectOrgId,
  selectSalesType,
  (orgId, salesType) => Boolean(
    orgId
    && salesType === availableSalesTypes.SALES_TYPE_COMPLEX,
  ),
);

type Props = {
  resetStatus: boolean,
}

const FoodModesFilter = ({ resetStatus }: Props) => {
  const dispatch = useDispatch();

  // выбранное
  const selectedItems: DataObject = useSelector(state => state.reports.filters.foodModes.selected, shallowEqual);

  // и массив с выбранным
  const selectedArr: Array<string> = mapObjPropToArray('id')(selectedItems);

  // id выбранной организации
  const orgId = useSelector(selectOrgId);

  // reset
  const handleReset = useCallback(() => {
    dispatch(action(actions.FOODMODES_FILTER_SET, {}));
  }, [dispatch]);

  // осуществление выбора
  const handleChange = useCallback((submitted) => {
    dispatch(action(actions.FOODMODES_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(actions.FOODMODES_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE и запрос данных
   */

  const isEnable = useSelector(selectIsEnableDefault);

  useEffect(() => {
    dispatch(action(actions.FOODMODES_FILTER_UPDATE, { active: isEnable }));

    if (isEnable) {
      handleReset();
    }
  }, [isEnable, orgId]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!isEnable) {
    return null;
  }

  return (
    <PlainFilterComplete
      id="foodModes"
      className={styles.filter}
      title="Режим питания:"
      modalTitle="Выбор режима питания"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default FoodModesFilter;
