import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import equals from 'ramda/es/equals';
import isEmpty from 'ramda/es/isEmpty';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import fromPairs from 'ramda/es/fromPairs';

import {
  customersFilterFetch,
  customersFilterClear,
  customersFilterDelete,
  customersFilterSet,
} from 'app/actions/reports/filters/customers';

import { isSingle } from 'app/common/lib/object';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import * as orgsTypes from 'app/common/constants/schools/types';
import * as customers from 'app/common/constants/schools/customers';

import { selectOrgsType } from './commonSelectors';


export const selectIsAll = createSelector(
  [
    state => state.reports.filters.customers.data,
    state => state.reports.filters.customers.selected,
  ],
  // (data, selected) => (!isEmpty(data) && equals(data, selected))
  (data, selected) => (isEmpty(selected) || equals(data, selected)),
);

// некоторые типы покупателей на самом деле должны быть другими в зависимости от типа заведения
// это для отчета остаток средств, для другого отчета нужно будет делать через props, если этот вариант не подойдет
const makeConvertCustomersByOrgsType = (orgsType: $Values<typeof orgsTypes>) => {
  if (orgsType === orgsTypes.ENTERPRISE) {
    return pipe(
      map(customer => (customer === customers.EMPLOYEE || customer === customers.EMPLOYEE_COMMON
        ? [customers.EMPLOYEE_COMMON, { id: customers.EMPLOYEE_COMMON, title: 'Сотрудники предприятия' }]
        : [customer, { id: customer, title: customer }]
      )),
      fromPairs,
    );
  }

  return pipe(
    map(customer => (customer === customers.EMPLOYEE || customer === customers.EMPLOYEE_COMMON
      ? [customers.EMPLOYEE_COMMON, { id: customers.EMPLOYEE_COMMON, title: 'Сотрудники OУ' }]
      : [customer, { id: customer, title: customer }]
    )),
    fromPairs,
  );
};

export default compose(
  connect(
    (state) => {
      const orgsType = selectOrgsType(state);

      return ({
        items: state.reports.filters.customers.data,
        selectedItems: state.reports.filters.customers.selected,
        loading: state.reports.filters.customers.loading,
        isSelectedAllState: selectIsAll(state),
        convertCustomersByOrgsType: makeConvertCustomersByOrgsType(orgsType),
      });
    },
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(customersFilterSet({}));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset();
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems, reset }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        reset();
        return;
      }
      dispatch(customersFilterDelete([name]));
    },
    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(customersFilterSet(submitted));
    },
  }),

  lifecycle({
    async componentDidMount() {
      const { dispatch, reset, convertCustomersByOrgsType } = this.props;
      const res = await dispatch(customersFilterFetch(convertCustomersByOrgsType));
      if (res) reset();
    },
    componentDidUpdate(nextProps) {
      resetOnStatusChange(this.props, nextProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(customersFilterClear());
    },
  }),
);
