// @flow
import { iconTypes } from 'app/common/ui-next/plain-icon';


const GENERAL: 'general' = 'general';
const PLANOGRAM: 'planogram' = 'planogram';
const STORAGE: 'storage' = 'storage';
const PURCHASES: 'purchases' = 'purchases';
const MONITORING: 'monitoring' = 'monitoring';
const PRODUCTION: 'production' = 'production';
const SETTING: 'setting' = 'setting';

export const sections = {
  GENERAL,
  PLANOGRAM,
  STORAGE,
  PURCHASES,
  MONITORING,
  PRODUCTION,
  SETTING,
};

export const availableSections = [
  GENERAL,
  PLANOGRAM,
  STORAGE,
  PURCHASES,
  MONITORING,
  PRODUCTION,
  SETTING,
];

export const defaultSection = sections.GENERAL;

const sectionTitles = {
  [GENERAL]: 'Об аппарате',
  [PLANOGRAM]: 'Планограмма',
  [STORAGE]: 'Склад аппарата',
  [PURCHASES]: 'Покупки',
  [MONITORING]: 'Мониторинг',
  [PRODUCTION]: 'Продукция',
  [SETTING]: 'Настройки',
};

export const getSectionTitle = (sect: string): string => {
  return sectionTitles[sect] || '';
};

export const sectionIcons: {
  [$Values<typeof sections>]: $Values<typeof iconTypes>
} = {
  [GENERAL]: iconTypes.INFO,
  [PLANOGRAM]: iconTypes.STORAGE_FULL,
  [STORAGE]: iconTypes.STORAGE_HALF,
  [PURCHASES]: iconTypes.SHOPING_FILLED,
  [MONITORING]: iconTypes.SHOPING_FILLED,
  [SETTING]: iconTypes.GEAR_SPARSE,
};
