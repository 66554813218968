// @flow
import { useState, useCallback, useEffect } from 'react';
import prop from 'ramda/es/prop';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';

import { api } from 'app/api';

import {
  type CafeOrders,
  type CafeOrder,
  roles,
  type Role,
  foodModes,
  statuses,
  type Status,
  GET_CAFETERIA_ORDERS,
} from 'app/common/api/requests/food/schools/cafeteria_orders';


export type { CafeOrders, CafeOrder, Role, Status };
export { roles, foodModes, statuses };

const clearParams = filter((val) => {
  const type = typeof val;
  return (
    type === 'number'
    || type === 'string'
    || (type === 'object' && !isEmpty(val))
  );
});

type State = {
  orders: CafeOrders,
  count: number,
  loading: boolean,
}

type ApiHandlers = {
  request: () => Promise<void> | void,
}

type Params = {
  limit: number,
  offset: number,
  id?: number | null,
  year_id?: number | null,
  month?: number | null,
  food_mode?: Array<string>,
  klasses_ids?: Array<number>,
  status?: Array<number>,
}

export default function useOrdersData(
  orgId: number,
  params: Params,
  resetPagination: () => void,
): [State, ApiHandlers] {
  const [orders, setOrders] = useState<CafeOrders>({});

  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);


  const handleRequest = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_CAFETERIA_ORDERS, {
      error: 'Не удалось получить данные по заявкам',
      params: clearParams(params),
      requestPathParams: {
        orgId: String(orgId),
      },
    });

    if (res) {
      const ordersRes = prop('orders', res);
      const countRes = prop('count', res);
      if (countRes && isEmpty(ordersRes)) {
        resetPagination();
        return;
      }
      setOrders(ordersRes);
      setCount(countRes);
    }
    setLoading(false);
  }, [resetPagination, orgId, params]);


  useEffect(() => {
    handleRequest();
  }, [handleRequest]);


  return [{
    orders,
    count,
    loading,
  }, {
    request: handleRequest,
  }];
}
