// @flow
import { map, filter } from 'ramda';

import type { Dishes, Params } from 'app/dataTypes/vending/production/Dishes.types';

import type { Request } from '../../../index';


type ParamsApi = {
  place_id: string,
  category_id?: string,
  id?: Array<string>,
  food_item_id?: Array<string>,
  search?: string,
  limit: string,
  offset?: string,
  deleted?: boolean,
}

type ResultApi = {
  data: Array<{
    id: number,
    place_id: number,
    category_id: number,
    food_item_id: number,
    title: string,
    photoUrl: string | null,
    portion: string | null,
    cost: string | null,
    saleType: string | null,
    deleted: boolean,
  }>,
  meta: {
    available: number,
    limit: number,
    offset: number,
    selected: number,
  }
}

export const convertParams = ({
  placeId,
  categoryId,
  ids,
  foodItemIds,
  search,
  limit,
  offset,
  deleted,
}: Params): [ParamsApi, null] => {
  return [filter(x => (typeof x !== 'boolean' ? Boolean(x) : true), {
    place_id: placeId,
    category_id: categoryId,
    id: ids,
    food_item_id: foodItemIds,
    search,
    limit: limit ? String(limit) : '1000',
    offset: String(offset),
    deleted,
  }), null];
};

export const convertResult = (resApi: ResultApi): Dishes => {
  const { data, meta } = resApi;
  const { available: total = 0 } = meta;

  return {
    data: map(({
      id,
      place_id: placeId,
      category_id: categoryId,
      food_item_id: foodItemId,
      title,
      photoUrl,
      portion,
      cost,
      saleType,
      deleted,
    }) => ({
      id: String(id),
      placeId: String(placeId),
      categoryId: String(categoryId),
      foodItemId: String(foodItemId),
      title,
      photoURL: photoUrl || null,
      portion: portion || '',
      cost: cost || '',
      saleType: saleType || null,
      deleted: deleted || false,
    }), data),
    total,
  };
};

export const GET_VENDING_ITEMS: Request<Params, Dishes, void> = [
  'data/vending/items',
  'get',
  ['data'],
];
