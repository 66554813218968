// @flow
import type { Request } from '../../index';


export type CopyMenuJob = {
  school_id_from?: number,
  school_id_to?: number,
  date_from: string,
  date_to: string,
  from_building_id?: number,
  to_building_id?: number,
  from_sale_point_id?: number, // уникальные для разных школ, можно не использовать school_id_(from/to) и (from/to)_building_id
  to_sale_point_id?: number, // уникальные для разных школ, можно не использовать school_id_(from/to) и (from/to)_building_id
  items: boolean,
  complexes: boolean,
  copy_complex_photo?: boolean,
  copy_settings?: boolean, // копировать настройки продукции/комлпектов
  content_only?: boolean, // используется только с комплектами, копирование только состава комплектов, без постановки в меню
  complexes_ids?: Array<number>, // опционально, для указания конкретных комплектов
  change_sold_items?: boolean, // используется только с комплектами, внести изменения в состав проданных комплектов на выбранную дату
  source_complex_id?: number, // из комплекта (комплект -> комплект)
  target_complex_id?: number, // в комплект (комплект -> комплект)
  keep_complex_price?: boolean, // не изменять цену комплектов при копировании
  copy_extended_settings?: boolean, // копировать дополнительные настройки комплекта
}

export type CopyMenuPostRequestParams = {
  jobs: Array<CopyMenuJob>,
  email?: string,
}

export type CopyMenuPostRequestResult = 'success'

export const POST_COPY_MENU_JOBS: Request<
  CopyMenuPostRequestParams,
  CopyMenuPostRequestResult,
  void
> = [
  'food/menu/copy_menu_between_schools',
  'post',
  ['data', 'result'],
];
