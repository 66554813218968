import api from 'app/actions/api';
import get from 'app/common/lib/get';


export const JOBPLACES_SUBMIT_REQUEST = 'employee/JOBPLACES_SUBMIT_REQUEST';
export const JOBPLACES_SUBMIT_SUCCESS = 'employee/JOBPLACES_SUBMIT_SUCCESS';
export const JOBPLACES_SUBMIT_FAILURE = 'employee/JOBPLACES_SUBMIT_FAILURE';

const jobplacesSubmitRequest = payload => ({
  type: JOBPLACES_SUBMIT_REQUEST,
  payload,
});

const jobplacesSubmitSuccess = payload => ({
  type: JOBPLACES_SUBMIT_SUCCESS,
  payload,
});

const jobplacesSubmitFailure = payload => ({
  type: JOBPLACES_SUBMIT_FAILURE,
  payload,
});

export const jobplacesSubmit = id => async (dispatch, getState) => {
  const schools = getState().employees.employee.jobplaces;
  dispatch(jobplacesSubmitRequest(id));
  try {
    const res = await api(`food/employees/${id}/schools`, 'post', { data: { schools } });
    const result = get(res, ['data', 'schools'], {});
    dispatch(jobplacesSubmitSuccess(result));
    return result;
  } catch (error) {
    dispatch(jobplacesSubmitFailure({ err: 'Сохранение должностной информации не удалось', error }));
    return null;
  }
};
