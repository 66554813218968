import React, { useCallback, useEffect, useState } from 'react'
import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { subscibeTo } from 'app/common/data-box-store'
import { OrgsOrUserIDs, customerTypeFilter, customerTypeFilterSelection, defaultCustomerTypeData, defaultCustomerTypeSelection, orgOrUserFilterSelection, orgsFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

export const CustomerTypeFilter = ({ id, pending }: Props) => {
  const [isReady, setReady] = useState<boolean>(false)
  const [filterVisibility, setFilterVisibility] = useState<boolean>(true)

  const filterVisibleHandler = useCallback((data: string[]) => {
    if (data.length === 1) {
      customerTypeFilter.updateData(
        defaultCustomerTypeData,
      )
      customerTypeFilter.setStatusReady()
      setReady(true)
      return
    }
    customerTypeFilter.resetToDefault()
    setReady(false)
  }, [])

  const orgOrUserFilterHandler = useCallback((data: OrgsOrUserIDs) => {
    if (data === OrgsOrUserIDs.ByOrgs) {
      setFilterVisibility(true)
      return
    }
    customerTypeFilter.resetToDefault()
    setFilterVisibility(false)
    setReady(false)
  }, [])

  useEffect(() => {
    const orgsUnsubsribe = subscibeTo<string[]>(orgsFilterSelection.id, filterVisibleHandler)
    const orgOrUserUnsubsribe = subscibeTo<OrgsOrUserIDs>(orgOrUserFilterSelection.id, orgOrUserFilterHandler)

    return (() => {
      orgsUnsubsribe()
      orgOrUserUnsubsribe()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    filterVisibility
      && isReady
      && <PlainItemsSelectorBox
        id={id}
        label="Тип покупателя"
        header="Выбор типа покупателя"
        dataBoxData={customerTypeFilter}
        dataBoxSelection={customerTypeFilterSelection}
        pleaseSelectText="Нет данных"
        selectedAllOnEmptySelection
        disabled={pending}
        multiple
        defaultSelection={defaultCustomerTypeSelection}
      />
  )
}
