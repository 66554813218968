// @flow
import React from 'react';

import IconPerson from './icons/IconPerson';
import IconJournal from './icons/IconJournal';
import IconI from './icons/IconI';
import IconGear from './icons/IconGear';

import * as sections from './sections';


type Props = {
  section: string,
}

const IconBySection = ({ section }: Props) => {
  switch (section) {
    case sections.GENERAL_SECTION: {
      return (<IconPerson />);
    }
    case sections.IDENTIFIERS_SECTION: {
      return (<IconJournal />);
    }
    case sections.JOBPLACES_SECTION: {
      return (<IconI />);
    }
    case sections.SETTINGS_SECTION: {
      return (<IconGear />);
    }
    default:
  }
  return (
    <IconPerson />
  );
};

export default IconBySection;
