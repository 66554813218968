// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { employeeformError, employeeformErrorClear } from 'app/actions/employees/employee/validation';


export type ErrorFields = {
  'name.first'?: boolean,
  'name.middle'?: boolean,
  'name.last'?: boolean,
  gender?: boolean,
  birthdate?: boolean,
  'passport.series'?: boolean,
  'passport.num'?: boolean,
  'passport.date'?: boolean,
  'passport.place'?: boolean,
  'passport.birthplace'?: boolean,
  snils?: boolean,
  inn?: boolean,
  medical?: boolean,
  phone?: boolean,
  workphone?: boolean,
  homephone?: boolean,
  email?: boolean,
  'address.country'?: boolean,
  'address.subject'?: boolean,
  'address.town'?: boolean,
  'address.street'?: boolean,
  'address.index'?: boolean,
  'address.house'?: boolean,
  'address.corp'?: boolean,
  'address.apart'?: boolean,
}

export type ErrorGroups = {
  general?: string,
  documents?: string,
  contacts?: string,
  address?: string,
}

export type Errors = {
  ...ErrorFields,
  ...ErrorGroups
}

type Actions = {
  resetErrors: () => void,
  setErrors: (f: ErrorFields, g: ErrorGroups) => void,
}

export default function useErrors(): [Errors, Actions] {
  const dispatch = useDispatch();
  const errors: Errors = useSelector(state => state.employees.employee.validation, shallowEqual);

  const resetErrors = useCallback(() => {
    dispatch(employeeformErrorClear());
  }, [dispatch]);

  const setErrors = useCallback((f: ErrorFields, g: ErrorGroups) => {
    dispatch(employeeformError({ ...f, ...g }));
  }, [dispatch]);

  return [errors, {
    resetErrors,
    setErrors,
  }];
}
