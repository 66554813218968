// @flow
import React, { useCallback, useEffect } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import complement from 'ramda/es/complement';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import Checkbox from 'app/common/ui/Checkbox';
import { detailsFilterSet } from 'app/actions/reports/filters/details';

import styles from './FilterItems.scss';


const selectIsEnableDefault = createSelector(
  state => state.reports.filters.orgsTypes.selected,
  complement(isEmpty),
);

type Props = {
  resetStatus: boolean,
  selectIsEnable?: (state: Object) => boolean,
}

const DetailsFilter = ({ resetStatus, selectIsEnable }: Props) => {
  const dispatch = useDispatch();
  const currentState = useSelector(state => state.reports.filters.details, shallowEqual);
  const isEnabled = useSelector(selectIsEnable || selectIsEnableDefault);

  const handleChange = useCallback((e) => {
    const { checked } = e.currentTarget;
    dispatch(detailsFilterSet(checked));
  }, [dispatch]);

  const handleReset = useCallback(() => {
    dispatch(detailsFilterSet(false));
  }, [dispatch]);

  // сброс по внешнему изменению resetStatus
  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE
   */

  useEffect(() => {
    if (!isEnabled) {
      handleReset();
    }
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!isEnabled) {
    return null;
  }


  return (
    <div className={styles.filter}>
      <Checkbox
        value="detailsFilter"
        label="Детализированный отчет"
        checked={currentState}
        onChange={handleChange}
        className={styles.ingroup}
      />
    </div>
  );
};

export default DetailsFilter;
