import { ReactNode, useCallback, useEffect, useState } from 'react'

import { ReportSection, ResetLink } from 'app/common/templates-next/report-template'
import ReportFilterForm from 'app/common/templates-next/report-template/report-filter-form'
import ReportFilterLayout from 'app/common/templates-next/report-template/report-filter-layout'
import { ID } from 'app/common/ui-next/_filter-compilations/predefined-ids'
import { Header2, PageTemplateContent } from 'app/common/templates-next/page-template'
import { CompensationTransactionsParams as Params } from 'app/dataTypes/compensation/transations'
import SimpleDate from 'app/common/classes/simple-date'

import { useDataBoxSelector } from 'app/common/data-box-store'
import { customerSearchFilter } from 'app/dataProviders/filter-items'
import { CustomerSearchFilter, PeriodFilter, dateFormat } from './filterItems'
import { filterInit, resetToDefaultAll } from './filter-init'
import styles from './Filters.scss'


type Props = {
  onSubmit: (params: Params) => void
  children: ReactNode
  footerBar?: ReactNode
}

export const Filters = ({ onSubmit, children, footerBar }: Props) => {
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
  const customer = useDataBoxSelector(customerSearchFilter, () => customerSearchFilter.data)
  const resetAll = () => {
    resetToDefaultAll()
  }

  const perform = useCallback(async (formData: FormData) => {
    const periodFrom = formData.get(`${ID.periodFrom}From`) as string
    const periodTo = formData.get(`${ID.periodFrom}To`) as string
    const customerSearch = (formData.get(ID.customerSearch)) as string

    if (!customerSearch) return
    onSubmit({
      studentID: customerSearch,
      dateFrom: new SimpleDate(periodFrom, dateFormat),
      dateTo: new SimpleDate(periodTo, dateFormat),
    })
  }, [onSubmit])

  const performWithAdditionalData = useCallback(async (formData: FormData) => {
    const res = await perform(formData)
    return res
  }, [perform])


  useEffect(() => {
    setSubmitDisabled(!customer?.ID)
  }, [customer])

  useEffect(() => {
    return filterInit()
  }, [])

  return (
    <>
      <PageTemplateContent>
        <Header2 skipMargin>
          {'Получатели компенсации'}
        </Header2>
      </PageTemplateContent>

      <ReportSection
        header="Фильтры"
        headerBar={
          <ResetLink onClick={resetAll}>
            {'Сбросить все фильтры'}
          </ResetLink>
        }
        resetStickyShift={false}
        footerBar={footerBar}
        skipPadding
      >
        <div className={styles.formContent}>
          <ReportFilterForm
            onSubmit={performWithAdditionalData}
            disabled={submitDisabled}
            submitButtonTitle="Показать"
          >
            {pending => (
              <ReportFilterLayout
                column1={
                  <CustomerSearchFilter
                    id={ID.customerSearch}
                    pending={pending}
                  />
                }
                column2={
                  <PeriodFilter
                    id={ID.periodFrom}
                    pending={pending}
                  />
                }
              />
            )}
          </ReportFilterForm>
        </div>

      </ReportSection>
      {children}
    </>
  )
}
