import { reduce, isEmpty } from 'ramda'

import { type SplitItem, AllocationType } from 'app/common/api/requests/food/subsidy_split'

/**
 *  Извлечение ошибок из одной записи splits
 *  Для использования в reduce
 */

export type ErrorsState = { [splitTypeId: string]: Record<string, string> }

const checkErrors = (obj: SplitItem, fieldNames: Array<string>) => (
  reduce((acc, fieldName) => {
    if (obj[fieldName] || obj[fieldName] === 0) {
      return acc
    }
    acc[fieldName] = 'Заполните поле'
    return acc
  }, {}, fieldNames)
)

const allFieldsEmpty = (obj: SplitItem, fieldNames: Array<string>) => (
  reduce((acc, fieldName) => {
    return acc && !obj[fieldName]
  }, true, fieldNames)
)

const extractSplitsAllErrors = (acc: ErrorsState, val: SplitItem) => {
  const {
    split_type_id: splitTypeId,
    allocation_type_id: allocationTypeId,
    part1_fixed: part1Fixed,
  } = val

  const mustBeFilledAlways = ['part1_title', 'part2_title']
  const mustBeFilledFixed = part1Fixed ? ['part1_sum'] : ['part2_sum']
  const mustBeFilledEstimated = ['part1_percent', 'part2_percent']

  const mustBeFilled = (allocationTypeId === AllocationType.Estimated
    ? [...mustBeFilledAlways, ...mustBeFilledEstimated]
    : [...mustBeFilledAlways, ...mustBeFilledFixed])

  if (allFieldsEmpty(val, mustBeFilled)) {
    // если все поля пустые, то заполнять блок не требуется
    return acc
  }

  const errors = checkErrors(val, mustBeFilled)

  if (!isEmpty(errors)) {
    acc[String(splitTypeId)] = errors
  }

  return acc
}

export default extractSplitsAllErrors
