// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';

import type { ReportTotalOnDate } from './useReportData';
import { displayableDataTotal, type DisplayableData } from './displayableData';


export type TotalDisplayableItem = {
  date: string,
  data: DisplayableData,
}

export default function useTotals(data: Array<ReportTotalOnDate>): Array<TotalDisplayableItem> {
  const res = useMemo(() => {
    return map(({ date, data: dataOnDate }: ReportTotalOnDate) => ({
      date,
      data: displayableDataTotal(dataOnDate),
    }), data);
  }, [data]);

  return res;
}
