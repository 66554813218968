import omit from 'ramda/es/omit';

import {
  DISHES_SUCCESS,
  DISHES_STATE_CLEAR,
} from 'app/actions/orgs/dishes';

import {
  DISHES_FIELD_EDIT_SUCCESS,
} from 'app/actions/orgs/dishes/field';

import {
  DISHES_REMOVE_ITEMS_SUCCESS,
  DISHES_MOVE_ITEMS_SUCCESS,
} from 'app/actions/orgs/dishes/selection';

const defaultState = {};

const items = (state = defaultState, action) => {
  if (action.type === DISHES_SUCCESS) {
    return ({ ...action.payload.items });
  }
  if (action.type === DISHES_STATE_CLEAR) {
    return defaultState;
  }
  if (action.type === DISHES_FIELD_EDIT_SUCCESS) {
    return ({ ...state, ...action.payload });
  }
  if (action.type === DISHES_REMOVE_ITEMS_SUCCESS || action.type === DISHES_MOVE_ITEMS_SUCCESS) {
    return omit(action.payload, state);
  }
  return state;
};

export default items;
