import type { FoodMode } from 'app/dataTypes/foodModes'

export type GeneralSettings = {
  allow_cash_sales: boolean, // продажи за наличный расчет
  allow_bank_card_sales: boolean, // продажи за наличный расчет
  allow_guess_sales: boolean, // продажи гостю
  cafeteria_enabled: boolean, // Использовать как место питания
  cafeteria_settings_readonly_for_schools: boolean, // редактирование в кабинете школы запрещено
  enable_subsidies_for_orders: boolean, // начислять дотации на заявки
  enable_subsidies_for_single_sales: boolean, // начислять дотации на личные продажи
  enabled_allow_cash_sales: boolean,
  food_modes: Array<FoodMode>, // доступные режимы питания
  free_cafe_order_day: boolean, // разрешить создавать заявки не в учебные дни
  order_targets_enabled: boolean, // указывать комплекты при создании заявки
  use_food_mode: boolean, // использовать режим питания в комплексном питании
  use_pin: boolean, // использовать пин-коды
  one_order_per_day_mode: boolean, // создавать не больше одной заявки на каждый режим питания
  allow_edit_cafeteria_order_items_with_supplier_confirmation: boolean, // Разрешить редактировать комплекты/продукцию в проведенных заявках, с подтверждением сотрудниками ПП
  disable_past_days_purchases: boolean, // запретить создавать продажи за прошлые дни
  disable_edit_reject_cafeteria_subsidies_period: boolean, // Запретить управлять периодом не начисления дотаций
  prevent_change_settings: boolean, // запретить изменение настроек
  allow_parents_to_participate_in_orders: boolean, // разрешить родителям участвовать в формировании заявок
  allow_students_to_participate_in_orders: boolean, // разрешить учащимся участвовать в формировании заявок
  camp_subsidy_orders_use_presets: boolean, // использовать заданные значения возмещения для лагерей
  camp_subsidy_orders_preset_values: Array<number>, // заданные значения возмещения для лагерей
  camp_enabled: boolean,
  disable_orders_for_5_to_11_parallels: boolean, // Отключить заявки для 5-11 классов
  use_qr_instead_of_barcode: boolean, // Использовать QR-код вместо штрих кода
  order_second_step_auto_select: boolean // Автоматический выбор комплектов на втором этапе создания заявок
  count_personal_sales_with_complex_as_complex_sales: boolean // Личные продажи с комплектами считать за комплексное питание
  disable_desktop_orders: boolean // Запретить создавать и редактировать заявки в настольном приложении
  display_date_time_and_place_of_printing_in_receipts: boolean // Отображать в чеках дату, время и место печати
}

export type ParamsPOST = {
  settings: Partial<GeneralSettings>,
}

export type ParamsMassPOST = {
  schools_ids: Array<string | number>,
  settings: Partial<GeneralSettings>,
}

export enum SettingsKey {
  allow_cash_sales = 'allow_cash_sales',
  allow_bank_card_sales = 'allow_bank_card_sales',
  allow_guess_sales = 'allow_guess_sales',
  cafeteria_enabled = 'cafeteria_enabled',
  cafeteria_settings_readonly_for_schools = 'cafeteria_settings_readonly_for_schools',
  enable_subsidies_for_orders = 'enable_subsidies_for_orders',
  enable_subsidies_for_single_sales = 'enable_subsidies_for_single_sales',
  enabled_allow_cash_sales = 'enabled_allow_cash_sales',
  food_modes = 'food_modes',
  free_cafe_order_day = 'free_cafe_order_day',
  order_targets_enabled = 'order_targets_enabled',
  use_food_mode = 'use_food_mode',
  use_pin = 'use_pin',
  one_order_per_day_mode = 'one_order_per_day_mode',
  allow_edit_cafeteria_order_items_with_supplier_confirmation = 'allow_edit_cafeteria_order_items_with_supplier_confirmation',
  disable_past_days_purchases = 'disable_past_days_purchases',
  disable_edit_reject_cafeteria_subsidies_period = 'disable_edit_reject_cafeteria_subsidies_period',
  prevent_change_settings = 'prevent_change_settings',
  allow_parents_to_participate_in_orders = 'allow_parents_to_participate_in_orders',
  allow_students_to_participate_in_orders = 'allow_students_to_participate_in_orders',
  camp_subsidy_orders_use_presets = 'camp_subsidy_orders_use_presets',
  camp_subsidy_orders_preset_values = 'camp_subsidy_orders_preset_values',
  camp_enabled = 'camp_enabled',
  disable_orders_for_5_to_11_parallels = 'disable_orders_for_5_to_11_parallels',
  use_qr_instead_of_barcode = 'use_qr_instead_of_barcode',
  order_second_step_auto_select = 'order_second_step_auto_select',
  count_personal_sales_with_complex_as_complex_sales = 'count_personal_sales_with_complex_as_complex_sales',
  disable_desktop_orders = 'disable_desktop_orders',
  display_date_time_and_place_of_printing_in_receipts = 'display_date_time_and_place_of_printing_in_receipts'
}
