// @flow
import path from 'ramda/es/path';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';

import api from 'app/actions/api';
import { getLastFirstM } from 'app/common/lib/names';


export const EMPLOYEES_FILTER_REQUEST = 'reports/EMPLOYEES_FILTER_REQUEST';
export const EMPLOYEES_FILTER_SUCCESS = 'reports/EMPLOYEES_FILTER_SUCCESS';
export const EMPLOYEES_FILTER_FAILURE = 'reports/EMPLOYEES_FILTER_FAILURE';
export const EMPLOYEES_FILTER_CLEAR = 'reports/EMPLOYEES_FILTER_CLEAR';
export const EMPLOYEES_FILTER_DELETE = 'reports/EMPLOYEES_FILTER_DELETE';
export const EMPLOYEES_FILTER_SET = 'reports/EMPLOYEES_FILTER_SET';
export const EMPLOYEES_FILTER_UPDATE = 'reports/EMPLOYEES_FILTER_UPDATE';


const employeesFilterRequest = () => ({
  type: EMPLOYEES_FILTER_REQUEST,
});

const employeesFilterSuccess = (payload: Object) => ({
  type: EMPLOYEES_FILTER_SUCCESS,
  payload,
});

const employeesFilterFailure = (payload: Object) => ({
  type: EMPLOYEES_FILTER_FAILURE,
  payload,
});

export const employeesFilterClear = () => ({
  type: EMPLOYEES_FILTER_CLEAR,
});

export const employeesFilterDelete = (payload: Array<string | number>) => ({
  type: EMPLOYEES_FILTER_DELETE,
  payload,
});

export const employeesFilterSet = (payload: Object) => ({
  type: EMPLOYEES_FILTER_SET,
  payload,
});

export const employeesFilterUpdate = (payload: Object) => ({
  type: EMPLOYEES_FILTER_UPDATE,
  payload,
});

export const employeesFilterFetch = (orgId: number, customer: string) => async (dispatch: Function) => {
  const params = { school_id: orgId, customer_type: customer };
  dispatch(employeesFilterRequest());
  try {
    const res = await api('reports/employees_list', 'get', { params });
    const convert = pipe(
      path(['data', 'employees']),
      map((item) => {
        const name = prop('name', item);
        return { ...item, title: getLastFirstM(name) };
      }),
    );
    dispatch(employeesFilterSuccess(convert(res)));
  } catch (error) {
    dispatch(employeesFilterFailure({ err: 'Не удалось получить данные фильтра по сотрудникам', error }));
  }
};
