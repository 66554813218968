// @flow
import React from 'react';

import ErrorString from 'app/common/ui/ErrorString';

import PlainInput from './PlainInput';
import styles from './PlainInput.scss';

/**
 * Серия и номер паспорта
 */
type Props = {
  className?: string,
  label?: string,
  name1: string,
  name2: string,
  id?: string,
  value1: string,
  value2: string,
  placeholder1?: string,
  placeholder2?: string,
  required?: boolean,
  labelStyle?: Object,
  isError?: boolean,
  errorText?: string,
  loading?: boolean,
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  disabled?: boolean,
};

const PlainInputDouble = ({
  className,
  label,
  name1,
  name2,
  id,
  value1,
  value2,
  placeholder1,
  placeholder2,
  required,
  labelStyle,
  isError,
  errorText,
  ...other
}: Props) => (
  <div className={className}>
    {label
      && <label htmlFor={id || name1} className={styles.label} style={labelStyle}>
        {label}
        {required && <span className={styles.star}>{'&nbsp;*'}</span>}
      </label>}
    <div className={styles.double}>
      <PlainInput
        label=""
        name={name1}
        id={id || name1}
        value={value1}
        placeholder={placeholder1}
        isError={isError}
        hideErrorString
        {...other}
      />
      <PlainInput
        label=""
        name={name2}
        id={id || name2}
        value={value2}
        placeholder={placeholder2}
        isError={isError}
        hideErrorString
        {...other}
      />
    </div>
    <ErrorString>{errorText}</ErrorString>
  </div>
);

export default PlainInputDouble;
