import { useCallback } from 'react'
import { isEmpty } from 'ramda'

import useErrors from 'app/common/hooks/useErrors'

import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { Options as JobsOptions } from './useJobs'


export const errorsDesc = [{
  fromKey: 'dateTo',
  errKey: 'date',
  validate: (date?: string) => !!date,
  text: 'Требуется правильная дата',
}, {
  fromKey: 'orgIDTo',
  errKey: 'org',
  validate: (selectedOrgs: Array<string>) => !isEmpty(selectedOrgs),
  text: 'Выберите хотя бы одно заведение',
}, {
  fromKey: 'dateToArray',
  errKey: 'date',
  validate: (date: Array<string>) => !isEmpty(date),
  text: 'Выберите хотя бы одну дату',
}, {
  fromKey: 'targetComplexID',
  errKey: 'targetComplex',
  validate: (id?: string) => !!id,
  text: 'Выберите комплект куда копировать',
}]

type Options = {
  tabIndex: number
  jobsOptions: JobsOptions
  checkEmail: () => boolean
}

export default function useJobsValidate({ tabIndex, jobsOptions, checkEmail }: Options) {
  const [errors, validate, resetErrors] = useErrors(errorsDesc)

  const { orgIDTo, dateTo, targetComplexID } = jobsOptions

  const validateOthersOrgs = useCallback(() => {
    return validate({ orgIDTo, dateTo }) && checkEmail()
  }, [validate, orgIDTo, dateTo, checkEmail])
  const validateOthersOrgsRef = useCallbackRef(validateOthersOrgs)

  const validateSameOrg = useCallback(() => {
    return validate({ dateToArray: dateTo }) && checkEmail()
  }, [validate, dateTo, checkEmail])
  const validateSameOrgRef = useCallbackRef(validateSameOrg)

  const validateOtherKit = useCallback(() => {
    return validate({ targetComplexID })
  }, [validate, targetComplexID])
  const validateOtherKitRef = useCallbackRef(validateOtherKit)

  const handleValidate = useCallback(() => {
    switch (tabIndex) {
      case 1:
        return !!validateOthersOrgsRef.current?.()
      case 2:
        return !!validateSameOrgRef.current?.()
      case 3:
        return !!validateOtherKitRef.current?.()
      default:
    }
    return true
  }, [tabIndex, validateOthersOrgsRef, validateSameOrgRef, validateOtherKitRef])

  return {
    errors,
    validate: handleValidate,
    resetErrors,
  }
}
