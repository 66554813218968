import api from 'app/actions/api';


/**
* Запрос категорий блюд
*/

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';

export const categoriesRequest = () => ({
  type: CATEGORIES_REQUEST,
});

const categoriesSuccess = payload => ({
  type: CATEGORIES_SUCCESS,
  payload,
});

const categoriesFailure = payload => ({
  type: CATEGORIES_FAILURE,
  payload,
});

export const categoriesFetch = () => async (dispatch) => {
  dispatch(categoriesRequest());
  try {
    const { data } = await api('food/menu/categories', 'get');
    dispatch(categoriesSuccess(data));
  } catch (error) {
    dispatch(categoriesFailure({ err: 'Запрос категорий блюд не удался', error }));
  }
};
