// @flow
import type { Machine as MachineApi } from 'app/common/api/requests/data/vending/machines/get';
import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';


const api2machineState = (res: MachineApi): MachineSingle => {
  const {
    id,
    title,
    number,
    model_id: modelId,
    school_id: orgId,
    version_id: versionId,
    place_id: placeId,
  } = res;

  return {
    id,
    title,
    number,
    modelId,
    orgId,
    versionId,
    placeId: String(placeId),
  };
};

export default api2machineState;
