// @flow
import VendingMachine from './VendingMachine';
import { sections, defaultSection } from './sections';

import { dataKey as availableDishesEditListDataKey } from './_externalProduction/AvailableDishesTable/dataKey';

import {
  defaultColumnVisibility as defaultColumnVisibilityAvailableDishesEditList,
  type ColumnVisibility as ColumnVisibilityAvailableDishesEditList,
} from './_externalProduction/AvailableDishesTable/columns';

import { dataKey as vendingDishesDataKey } from './ProductionSection/Content/Products/table/dataKey';

import {
  defaultColumnVisibility as defaultColumnVisibilityVendingDishes,
  type ColumnVisibility as ColumnVisibilityVendingDishes,
} from './ProductionSection/Content/Products/table/columns';


export default VendingMachine;

export {
  sections,
  defaultSection,
  availableDishesEditListDataKey,
  defaultColumnVisibilityAvailableDishesEditList,
  vendingDishesDataKey,
  defaultColumnVisibilityVendingDishes,
};

export type {
  ColumnVisibilityAvailableDishesEditList,
  ColumnVisibilityVendingDishes,
};
