// @flow
import { useMemo } from 'react';
import { map, prop } from 'ramda';

import toFixedValue from 'app/common/lib/toFixedValue';
import { tableColumnSort } from 'app/common/lib/sort';

import {
  sumToFixedValue,
  headerByTimeMode,
} from '../itsTimeMode';

import type {
  School,
  Daily,
  Monthly,
  DailyTotals,
  MonthlyTotals,
} from '../types';


type SchoolViewType = {
  id: number,
  schoolTitle: string,
  data: Array<string>,
  total: number,
  totalString: string,
}

/**
 *  Получение данных школ, подготовленных для таблицы
 *  с сортировкой
 */

export default function useActualSchools(
  schools: Array<School<Daily>> | Array<School<Monthly>>,
  totals: Array<DailyTotals> | Array<MonthlyTotals>,
  sortKey: string,
  sortDirection: 'up' | 'down',
): [
  Array<string>,
  Array<SchoolViewType>,
  Array<string>,
] {
  // подготовка основных данных для отображения в таблице
  const schoolsActual = useMemo(() => (
    map(({ school_id: id, school_title: schoolTitle, total, data }) => ({
      id,
      schoolTitle,
      data: map(sumToFixedValue, data),
      total, // нужно для сортировки
      totalString: toFixedValue(total),
    }), schools)
  ), [schools]);

  // сортировка после -- так оптимальнее
  const schoolsSorted = useMemo(
    () => tableColumnSort(sortDirection, prop(sortKey), schoolsActual),
    [sortDirection, sortKey, schoolsActual],
  );

  // подготовка данных для итоговой строки по всем выбранным школам
  const schoolsTotals = useMemo(() => map(sumToFixedValue, totals), [totals]);

  // подготовка заголовков, в зависимости от типа периодов
  const headers = useMemo(() => map(headerByTimeMode, totals), [totals]);

  return [headers, schoolsSorted, schoolsTotals];
}
