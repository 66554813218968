
import { useEffect, useMemo } from 'react'
import { reject } from 'ramda'

import { ReportDetailsRoot } from 'app/common/templates-next/report-template'
import { useStatementStatuses, useStatements } from 'app/dataProviders/compensation/statement/hooks'
import { useStatementStatusesActions } from 'app/dataProviders/compensation/statement/hooks/useStatementStatusesActions'
import { Params, Statement } from 'app/dataTypes/compensation/statement'
import { isKnownStatementStatus, KnownStatementStatus } from 'app/dataTypes/compensation/statement/status'

import { StatementsTable } from './statementsTable'


type Props = {
  params: Params
  offset: number
  perPage: number
  onTotal: (total: number) => void
}

export const Result = ({
  params,
  offset,
  perPage,
  onTotal,
}: Props) => {
  // useMemo because compare: 'shallow'
  const paramsWithPaging = useMemo(() => ({ ...params, offset, limit: perPage }), [params, offset, perPage])
  const { data, loading: loadingStatements, request } = useStatements({ params: paramsWithPaging, compare: 'shallow' })
  const { statements, total } = data

  const { statuses, loading: loadingStatuses } = useStatementStatuses()
  const { changeStatus, pendingIDs } = useStatementStatusesActions({
    onChangeStatusSuccess: request as () => void,
  })

  const loading = loadingStatuses || loadingStatements

  const statementsFiltered = useMemo(() => {
    return reject(({ statusID }: Statement) => (
      isKnownStatementStatus(statusID)
      && statusID === KnownStatementStatus.Draft
    ), statements)
  }, [statements])

  useEffect(() => {
    onTotal(total)
  }, [total]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReportDetailsRoot header="Список заявок на вывод компенсации">
      <StatementsTable
        statuses={statuses}
        statements={statementsFiltered}
        onChangeStatus={changeStatus}
        loading={loading}
        pendingIDs={pendingIDs}
      />
    </ReportDetailsRoot>
  )
}
