// @flow
import React, { type Node } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';

import EditMachineModal from './EditMachineModal';


type Props = {
  loading: boolean,
  onDeleteSuccess: () => void,
}

const EditMachineButton = ({ loading, onDeleteSuccess }: Props): Node => {
  return (
    <ControlsBar right>
      <EditMachineModal onDeleteSuccess={onDeleteSuccess}>
        {toggle => (
          <PlainButton onClick={toggle} disabled={loading}>
            {'Редактировать'}
          </PlainButton>
        )}
      </EditMachineModal>
    </ControlsBar>
  );
};

export default EditMachineButton;
