// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as org } from '../org';


const customerTypeActivity = (state: State): boolean => {
  return (
    org.filterActive(state)
    && org.singleOrgSelected(state)
  );
};

export default customerTypeActivity;
