export enum Theme {
  primaryColor = 'primary-color',
  activePrimaryColor = 'active-primary-color',
  hoverPrimaryColor = 'hover-primary-color',
  subitemActivePrimaryColor = 'subitem-active-primary-color',
  activeSecondaryColor = 'active-secondary-color',
  linkColor = 'link-color',
  buttonPrimary = 'button-primary',
  buttonPrimaryHover = 'button-primary-hover'
}
