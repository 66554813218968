// @flow
import moment from 'moment-timezone';

import type { PlainDatePeriodRestProps } from 'app/common/ui-components/filter';
import { dateFormat } from 'app/common/api/requests/reports/perform';


const disabledDays = (dayFormatted: string, format: string, unit?: string = 'day') => (
  moment(dayFormatted, format).isBefore('2022-05-01', unit)
);

const periodProps = (): PlainDatePeriodRestProps => {
  return ({
    label: 'Период времени',
    buttonResetLabel: 'Сбросить выбор',
    fromLabel: 'от',
    toLabel: 'до',
    dateFormat,
    dateDisabledStatus: disabledDays,
    // periodLimit: 1,
    // periodLimitUnit: 'months',
  });
};

export default periodProps;
