import { not } from 'ramda'
import { useCallback, useState } from 'react'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal'

import styles from './AccordionItemHeader.scss'


type Props = {
  item?: { ID: string, title: string, deletable: boolean }
  hover: boolean
  deleteCategory: (ID: string) => Promise<boolean>
  locked?: boolean
}

const AccordionItemHeader = ({ item, hover, deleteCategory, locked }: Props) => {
  const { ID, deletable, title } = item || {}

  const [pending, setPending] = useState(false)

  const [confirm, setConfirm] = useState(false)
  const toggleConfirm = useCallback(() => {
    setConfirm(not)
  }, [])

  const handleDelete = useCallback(async () => {
    if (!ID) return
    setPending(true)
    const res = await deleteCategory(ID)
    setPending(false)
    if (res) {
      setConfirm(not)
    }
  }, [deleteCategory, ID])

  if (!hover || !deletable || locked) {
    return null
  }

  return (
    <>
      {confirm
        && <PlainConfirmModal
          messageText={`Удалить категорию льгот «${title}»?`}
          onCancel={toggleConfirm}
          onConfirm={handleDelete}
          confirmButtonProps={{ disabled: pending }}
          cancelButtonProps={{ disabled: pending }}
          confirmButtonTitle={pending ? 'Удаление...' : 'Удалить'}
          critical
        />}
      <PlainButtonAsLink className={styles.root} onClick={toggleConfirm}>
        {'Удалить категорию льготы'}
      </PlainButtonAsLink>
    </>
  )
}

export default AccordionItemHeader
