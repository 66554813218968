// @flow
import type { Request } from '../../index';


export const DELETE_LINKED_EMPLOYEE: Request<
  void,
  void,
  void
> = [
  'food/employees/linked_employee',
  'delete',
  ['data'],
];
