// @flow
import { useMemo, useCallback, useState } from 'react';
import sort from 'ramda/es/sort';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import { type OrgsSchools } from 'app/dataProviders/org/OrgsDataProvider';
// import getFirstId from 'app/components/common/getFirstId';


/**
 *  Управление ids организаций куда копировать и default value
 */

const defaultSelection = [];

type Options = {
  orgId?: number,
  date?: string | null,
}

type SelectorProps = {
  // items: ActualOrgs,
  selection: Array<string>,
  defaultSelection: Array<string>,
  onChange?: (values: Array<string>) => void,
  sortFunc?: (Array<Object>) => Array<Object>,
}

export default function useOrgsSelector(orgsState: OrgsSchools, options: Options): [SelectorProps] {
  // const orgs = useActualOrgs2Selector(orgsState, options);

  const [orgIdFrom, setOrgIdFrom] = useState<Array<string>>(defaultSelection);
  const handleChangeOrg = useCallback((values: Array<string>) => {
    setOrgIdFrom(values);
  }, [setOrgIdFrom]);

  const selectorProps = useMemo(() => ({
    // items: orgs,
    selection: orgIdFrom,
    defaultSelection,
    onChange: handleChangeOrg,
    sortFunc: sort(ascend(prop('title'))),
  }), [handleChangeOrg, orgIdFrom]);

  return [selectorProps];
}
