// @flow
import React from 'react';
import classNames from 'classnames';

import { HeaderCell, HeaderRow } from 'app/common/components/Table';
import { defSettings } from 'app/selectors/OrgsList/selectOrgsDishes';
import type { SettingsType } from 'app/selectors/OrgsList/selectOrgsDishes';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';

import styles from './DishesTableHeader.scss';


type Props = {
  settings: SettingsType,
  className?: string,
  showCheckbox?: boolean,
  checked?: boolean,
  onChangeSelectAll?: (e: SyntheticEvent<HTMLInputElement>) => void,
};

const DishesTableHeader = ({
  settings = defSettings,
  className,
  showCheckbox,
  checked = false,
  onChangeSelectAll,
}: Props) => {
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <HeaderRow className={classNames(styles.root, className)}>
      {settings.selectable
        && <HeaderCell className={styles.cell} grow={0} width={50}>
          {showCheckbox && onChangeSelectAll
            ? <PlainCheckbox
              label="Выбрать все"
              className={styles.check}
              value="checkAll"
              onChange={onChangeSelectAll}
              checked={checked}
              hideLabel
            />
            : <span>&nbsp;</span>}
        </HeaderCell>}
      <HeaderCell className={styles.cell} grow={5}>
        {'Наименование'}
      </HeaderCell>
      {settings.photo
        && <HeaderCell className={styles.cell} alignMiddle grow={3}>
          {'Фото'}
        </HeaderCell>}
      {settings.restrictions
        && <HeaderCell className={styles.cell} alignMiddle grow={3}>
          {'Ограничения'}
        </HeaderCell>}
      {settings.portion
        && <HeaderCell className={styles.cell} alignMiddle grow={2}>
          {'Порция'}
        </HeaderCell>}
      {settings.cost
        && <HeaderCell className={styles.cell} alignMiddle grow={2}>
          {`Цена${currency}`}
        </HeaderCell>}
      {settings.saleType
        && <HeaderCell className={styles.cell} alignLeft grow={4}>
          {'Тип\u00A0продукции'}
        </HeaderCell>}
      {settings.category
        && <HeaderCell className={styles.cell} alignRight grow={2}>
          {'Категория'}
        </HeaderCell>}
      {settings.setup
        && <HeaderCell className={styles.cell} grow={1}>
          &nbsp;
        </HeaderCell>}
    </HeaderRow>
  );
};

export default DishesTableHeader;
