import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { filter } from 'ramda'


type Item = { title: string }

export default function useSearch<T extends Item>(items: Array<T>) {
  const [search, setSearch] = useState('')

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setSearch(value)
  }, [])

  const handleClearSearch = useCallback(() => {
    setSearch('')
  }, [])

  const itemsFiltered = useMemo(() => {
    const reg = new RegExp(search, 'i')
    return filter(({ title }) => (
      reg.test(title)
    ), items)
  }, [items, search])

  return {
    value: search,
    itemsFiltered,
    search: handleSearch,
    clearSearch: handleClearSearch,
  }
}
