import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import type { HOC } from 'recompose';

import { CHOOSEN_DATE, kitClear } from 'app/actions/orgs/kits/kitClear';
import { popup } from 'app/common/actions/popup';


type Props = {
  orgId: string,
  date: string,
  kitId: number,
}

const enhanceClearKitForm: HOC<*, Props> = compose(
  connect(),
  withState('saving', 'setSaving', false),
  withState('extended', 'setExtended', CHOOSEN_DATE),

  withHandlers({
    handleChange: ({ setExtended }) => (e) => {
      const { value } = e.currentTarget;
      setExtended(value);
    },

    handleSubmit: ({
      dispatch,
      extended,
      setSaving,
      orgId,
      date,
      kitId,
      onSuccess,
    }) => async () => {
      setSaving(true);
      const res = await dispatch(kitClear(orgId, {
        date,
        kitId,
        extended,
      }));
      if (res) {
        setSaving(false);
        dispatch(popup('Комплект успешно очищен'));
        onSuccess();
        return true;
      }
      setSaving(false);
      return false;
    },
  }),
);

export default enhanceClearKitForm;
