import api from 'app/actions/api';


export const DISHES_LIB_REQUEST = 'DISHES_LIB_REQUEST';
export const DISHES_LIB_SUCCESS = 'DISHES_LIB_SUCCESS';
export const DISHES_LIB_FAILURE = 'DISHES_LIB_FAILURE';
export const DISHES_LIB_CLEAR = 'DISHES_LIB_CLEAR';

export const dishesLibRequest = () => ({
  type: DISHES_LIB_REQUEST,
});

const dishesLibSuccess = payload => ({
  type: DISHES_LIB_SUCCESS,
  payload,
});

const dishesLibFailure = payload => ({
  type: DISHES_LIB_FAILURE,
  payload,
});

export const dishesLibClear = () => ({
  type: DISHES_LIB_CLEAR,
});

// пока это список похожих блюд
export const dishesLibFetch = someParams => async (dispatch, getState) => {
  if (someParams === 'stop') return;
  const { title } = getState().dishes.add.fields;
  dispatch(dishesLibRequest());
  try {
    const params = { limit: 10, search: title, ...someParams };
    const res = await api('food/items', 'get', { params });
    dispatch(dishesLibSuccess(res.data));
  } catch (error) {
    dispatch(dishesLibFailure({ err: 'Запрос похожих блюд не удался', error }));
  }
};
