// @flow
import { useMemo, useEffect } from 'react';

import { useOrgsAvailableTypes } from 'app/dataProviders/org/orgTypes/OrgsAvailableTypesProvider';
import { useVendingStatus, vendingStatuses } from 'app/dataProviders/VendingDataProvider';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import find from 'ramda/es/find';
import map from 'ramda/es/map';

import { orgTypes } from 'app/components/OrgsList';
import { dataKey as vendingDataKey } from 'app/components/OrgsList/Vending';
import isProduction from 'app/common/lib/isProduction';

import getBaseUrl from './getBaseUrl';


const baseUrl = getBaseUrl();

// пути
const MENU_LINK_SCHOOL = `${baseUrl}/${orgTypes.SCHOOL}`;
const MENU_LINK_PRESCHOOL = `${baseUrl}/${orgTypes.PRESCHOOL}`;
const MENU_LINK_HIGH_SCHOOL = `${baseUrl}/${orgTypes.HIGH_SCHOOL}`;
const MENU_LINK_ENTERPRISE = `${baseUrl}/${orgTypes.ENTERPRISE}`;
const MENU_LINK_UNKNOWN = `${baseUrl}/${orgTypes.UNKNOWN}`;
const MENU_LINK_VENDING = `${baseUrl}/${vendingDataKey}`;

// заголовки
const titles: {
  [key: string]: string,
} = {
  [MENU_LINK_SCHOOL]: 'ОУ',
  [MENU_LINK_PRESCHOOL]: 'ДОУ',
  [MENU_LINK_HIGH_SCHOOL]: 'ВУЗы',
  [MENU_LINK_ENTERPRISE]: 'Предприятия',
  [MENU_LINK_UNKNOWN]: 'Остальные',
  [MENU_LINK_VENDING]: 'Вендинговые аппараты',
};

const arrange = (
  !isProduction()
    ? [
      MENU_LINK_SCHOOL,
      MENU_LINK_PRESCHOOL,
      MENU_LINK_HIGH_SCHOOL,
      MENU_LINK_ENTERPRISE,
      MENU_LINK_UNKNOWN,
      MENU_LINK_VENDING,
    ]
    : [
      MENU_LINK_SCHOOL,
      MENU_LINK_PRESCHOOL,
      MENU_LINK_HIGH_SCHOOL,
      MENU_LINK_ENTERPRISE,
      MENU_LINK_UNKNOWN,
      // MENU_LINK_VENDING,
    ]
);

export type NavItemProps = {
  key: string,
  to: string,
  children: string,
}

const makeMenuItems = (
  vendingStatus: $Values<typeof vendingStatuses>,
  availableTypes: Array<string>,
): Array<NavItemProps> => pipe(
  filter((path: string) => {
    if (path === MENU_LINK_VENDING && vendingStatus !== vendingStatuses.VENDING_DISABLED) {
      return true;
    }
    return !!find(key => RegExp(`${key}$`).test(path), availableTypes);
  }),
  map((path: string) => ({
    key: path,
    to: path,
    children: titles[path],
  })),
)(arrange);

type Options = {
  onUpdateItems: (items: Array<NavItemProps>) => void,
}

export default function useOrgsListNavigation({ onUpdateItems }: Options): [Array<NavItemProps>, boolean] {
  const [availableTypes, availableTypesLoading] = useOrgsAvailableTypes();
  const [status, statusLoading] = useVendingStatus();

  const loading = availableTypesLoading || statusLoading;

  const menuItems: Array<NavItemProps> = useMemo(() => {
    if (loading) {
      return [];
    }
    return makeMenuItems(status, availableTypes);
  }, [status, availableTypes, loading]);

  useEffect(() => {
    onUpdateItems(menuItems);
  }, [menuItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return [menuItems, loading];
}
