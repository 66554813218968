// @flow
import React from 'react';
import Checkbox from 'app/common/ui/Checkbox';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';
import SettingsGear from 'app/common/components/Settings';

import styles from './OrgsMenuEditGear.scss';


export type DishesTableSettings = {
  rows: number,
  title: boolean,
  photo: boolean,
  portion: boolean,
  cost: boolean,
  saleType: boolean,
  category: boolean,
  setup: boolean,
}

const OrgsDishesGear = () => (
  // <div className={styles.gearRoot}>
  <div>
    <SettingsGear dataKey="orgsMenuEdit">
      {({ saveSettings }) => (<div>
        <div className={styles.gearHeader}>{'Столбцы:'}</div>
        {/* Чекбоксы в настройках */}
        <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="Фото"
          name="photo"
          saveSettings={saveSettings}
          className={styles.gearCheck}
          settingsKey="orgsMenuEdit"
        />
        <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="Порция"
          name="portion"
          saveSettings={saveSettings}
          className={styles.gearCheck}
          settingsKey="orgsMenuEdit"
        />
        <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="Стоимость"
          name="cost"
          saveSettings={saveSettings}
          className={styles.gearCheck}
          settingsKey="orgsMenuEdit"
        />
      </div>)}
    </SettingsGear>
  </div>
);

export default OrgsDishesGear;
