// @flow
import React from 'react';
import { Table, HeaderRow, HeaderCell } from 'app/common/components/Table';

import styles from './SubsidiesSheet.scss';


const SheetsHeader = () => (
  <Table className={styles.header}>
    <HeaderRow className={styles.headerRow}>
      <HeaderCell grow={5}>{'Номер'}</HeaderCell>
      <HeaderCell grow={4}>{'Дата'}</HeaderCell>
      <HeaderCell grow={5}>&nbsp;</HeaderCell>
      <HeaderCell grow={9}>{'Размер льгот / Комплекты'}</HeaderCell>
      <HeaderCell grow={3}>{'Итого, ₽'}</HeaderCell>
    </HeaderRow>
  </Table>
);

export default SheetsHeader;
