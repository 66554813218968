import type { Request } from '../../index'


type URLParams = {
  fileName: string,
}

export const convertParams = (params: { fileName: string }): [null, URLParams] => {
  const { fileName } = params
  return [null, { fileName }]
}

export const GET_IMAGES_TMP: Request<{ fileName: string }, string, URLParams> = [
  'images/tmp/{fileName}',
  'get',
  [],
]
