import api from 'app/actions/api';


export const ORGS_DEBTS_SUCCESS = 'ORGS_DEBTS_SUCCESS';
export const ORGS_DEBTS_CLEAR = 'ORGS_DEBTS_CLEAR';
export const ORGS_DEBTS_FAILURE = 'ORGS_DEBTS_FAILURE';

const orgsDebtsSuccess = payload => ({
  type: ORGS_DEBTS_SUCCESS,
  payload,
});

export const orgsDebtsClear = () => ({
  type: ORGS_DEBTS_CLEAR,
});

const orgsDebtsFailure = payload => ({
  type: ORGS_DEBTS_FAILURE,
  payload,
});

export const orgsDebtsFetch = id => async (dispatch) => {
  try {
    const { data } = await api(`food/school/${id}/debts`, 'get');
    dispatch(orgsDebtsSuccess({ [id]: (data && data.sum) || '-' }));
  } catch (error) {
    dispatch(orgsDebtsFailure({ error }));
  }
};
