
import { useEffect, useState } from 'react'

import { CafeteriaSettings } from 'app/dataTypes/subsidy/settings'
import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs'
import { isCommonAllowance } from 'app/dataTypes/subsidy/allowances'

import { Errors } from './useCafeteriaErrors'
import AccrualForm from './formParts/AccrualForm'
import AccuralsForForm from './formParts/AccuralsForForm'
import IncludeForm from './formParts/IncludeForm'
import AppliesForm from './formParts/AppliesForm'
import AccrualFormCommon from './formParts/AccrualFormCommon'
import SchemaForm from './formParts/SchemaForm'
import { FormSection } from './FormSection'


const tabTitles = ['Первая смена', 'Вторая смена', 'Третья смена']
const app = 'cafeteria' as const

type Props = {
  ID: string
  orgID: string
  formID: string
  settings: CafeteriaSettings
  disabled?: boolean
  errors: Errors
  resetErrors: () => void
  updateSize?: () => void
}

export const CategoryCafeteriaSettingsForm = ({
  ID,
  orgID,
  formID,
  settings,
  disabled,
  errors,
  resetErrors,
  updateSize,
}: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(1)

  const { accruals, accrualsFor, include, applies, schema } = settings

  useEffect(() => {
    if (errors.size) {
      const tabNumber = parseInt(Array.from(errors.keys())[0].replace(/\D/g, ''), 10)
      if (!Number.isNaN(tabNumber)) {
        setActiveTabIndex(tabNumber)
      }
    }
  }, [errors])

  useEffect(() => {
    updateSize?.()
  }, [activeTabIndex]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormSection>
        <Tabs value={activeTabIndex} onChange={setActiveTabIndex} skipUpperSpace>
          {accruals.map((_, i) => (
            <Tab
              index={i + 1}
              key={i} // eslint-disable-line react/no-array-index-key
            >
              {tabTitles[i]}
            </Tab>
          ))}
        </Tabs>

        <TabPanels value={activeTabIndex}>
          {accruals.map((accrual, i) => {
            const common = isCommonAllowance(ID)
            const props = {
              ID,
              orgID,
              app,
              data: accrual,
              formID,
              disabled,
              errors,
              resetErrors,
              updateSize,
            }
            return (
              <TabPanel
                index={i + 1}
                key={i} // eslint-disable-line react/no-array-index-key
              >
                {common
                  ? <AccrualFormCommon {...props} />
                  : <AccrualForm {...props} />}
              </TabPanel>
            )
          })}
        </TabPanels>
      </FormSection>

      <FormSection>
        <AccuralsForForm
          data={accrualsFor}
          disabled={disabled}
          formID={formID}
          errors={errors}
          resetErrors={resetErrors}
        />
      </FormSection>

      <FormSection>
        <IncludeForm
          data={include}
          disabled={disabled}
          formID={formID}
          errors={errors}
          resetErrors={resetErrors}
        />
      </FormSection>

      {applies
        && <FormSection>
          <AppliesForm
            data={applies}
            disabled={disabled}
            formID={formID}
            errors={errors}
            resetErrors={resetErrors}
          />
        </FormSection>}

      {schema
        && <FormSection>
          <SchemaForm
            data={schema}
            disabled={disabled}
            formID={formID}
          />
        </FormSection>}
    </>
  )
}
