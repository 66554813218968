// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames';

import { Table, Tr, Th } from 'app/common/ui-components/table';

import type { Multiple } from '../useReportData';


type Props = {
  dates: $PropertyType<Multiple, 'dates'>,
  tableRef?: Ref<'table'>,
  trRef?: Ref<'tr'>,
  thClassName?: string,
  sameColumnBase?: string,
  onCellMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  children: (content: string, tooltip: string) => Node,
}

const HeaderMiddleContent = ({
  dates,
  tableRef,
  trRef,
  thClassName,
  sameColumnBase,
  onCellMouseEnter,
  onCellMouseLeave,
  children,
}: Props): Node => {
  return (
    <Table reference={tableRef}>
      <thead>
        <Tr header reference={trRef}>
          {dates.map(({ key, date, dateFull }, i) => (
            <Th
              scope="col"
              id={key}
              key={key}
              className={cn(thClassName, sameColumnBase && `${sameColumnBase}${i}`)} // `${styles.headerDate} ${sameColumnBase}${i}`}
              onMouseEnter={onCellMouseEnter}
              onMouseLeave={onCellMouseLeave}
              alignRight
            >
              {children(date, dateFull)}
            </Th>
          ))}
        </Tr>
      </thead>
    </Table>
  );
};

export default HeaderMiddleContent;
