import Table from './table'
import Tr, { type TrProps } from './tr'
import { ThLabel } from './th-label'
import Th, { type ThProps } from './th'
import { TdLabel } from './td-label'
import Td, { type TdProps } from './td'
import { TrSelectable } from './tr-selectable'
import TrClickable from './tr-clickable'
import { TrExpandable } from './tr-expandable'
import useColumnsWidthsTo from './use-columns-widths-to'
import useCopyColumnsWidths from './use-copy-columns-widths'
import useSyncRowsHeights from './use-sync-rows-heights'
import useHover from './use-hover'
import useCopyContentWidths from './use-copy-content-widths'
import useHoverByClass from './use-hover-by-class'
import { getPropsId, getPropsHeader, getHeaderAttrsDefault } from './getProps'
import { CheckStateProvider, useCheckStateContext } from './check-state-provider'
import useTopShift from './use-top-shift'
import useTopShiftState from './use-top-shift-state'
import FancyRowHeader from './fancy-row-header'
import fancyRowStyles from './fancy-row-header.scss'
import useFancyRowExpandedStyle from './use-fancy-row-expanded-style'
import { TrExpandedContent } from './tr-expanded-content'
import { StopPropagation } from './stop-propagation'


const fancyRowRootClassName = fancyRowStyles.fancyTable
const fancyRowExpandedHeaderClassName = fancyRowStyles.fancyExpandedHeader
const fancyRowExpandedContentClassName = fancyRowStyles.fancyExpandedContent

export {
  Table,
  Tr,
  ThLabel,
  Th,
  TdLabel,
  Td,
  TrSelectable,
  TrClickable,
  TrExpandable,
  CheckStateProvider,
  useCheckStateContext,
  useColumnsWidthsTo,
  useCopyColumnsWidths,
  useSyncRowsHeights,
  useHover,
  useCopyContentWidths,
  useHoverByClass,
  getPropsId,
  getPropsHeader,
  getHeaderAttrsDefault,
  useTopShift,
  useTopShiftState,
  FancyRowHeader,
  fancyRowRootClassName,
  fancyRowExpandedHeaderClassName,
  fancyRowExpandedContentClassName,
  useFancyRowExpandedStyle,
  TrExpandedContent,
  StopPropagation,
}

export type { TrProps, ThProps, TdProps }
