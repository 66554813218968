// @flow
import React, { Fragment, useState, useCallback, type Node, useMemo } from 'react';

import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';
import { POST_COMPLEX_UPDATE_SOLD_ITEMS } from 'app/common/api/requests/food/schools/complexes/update_sold_items';
import { api } from 'app/api';
import usePopup from 'app/common/hooks/usePopup';


type Props = {
  orgId: number,
  complexId: number,
  date: string,
  message: string,
  title: string,
  children: (showConfirmation: () => void) => Node
}

const RefreshOrders = ({ orgId, complexId, date, message, title, children }: Props) => {
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const popup = usePopup();


  const toggleConfirmation = useCallback(() => {
    setConfirmationVisible(state => !state);
  }, []);

  const handleRefreshOrders = useCallback(async () => {
    setRefreshing(true);
    const res = await api.request(POST_COMPLEX_UPDATE_SOLD_ITEMS, {
      error: `Не удалось oбновить комплект «${title}»`,
      params: {
        date,
      },
      requestPathParams: {
        orgId,
        complexId,
      },
    });
    setRefreshing(false);

    if (res) {
      setConfirmationVisible(false);
      popup(`Комплект «${title}» успешно обновлен`);
    }
  }, [complexId, orgId, popup, title, date]);

  const confirmButtonProps = useMemo(() => ({
    disabled: refreshing,
  }), [refreshing]);


  return (
    <Fragment>
      {children(toggleConfirmation)}
      {confirmationVisible
        && <PlainConfirmModal
          messageText={message}
          cancelButtonTitle="Отмена"
          confirmButtonTitle={refreshing ? 'Обновление...' : 'Обновить'}
          onCancel={toggleConfirmation}
          onConfirm={handleRefreshOrders}
          confirmButtonProps={confirmButtonProps}
        />}
    </Fragment>
  );
};

export default RefreshOrders;
