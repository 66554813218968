import { useState, useCallback, useEffect } from 'react'
import { has } from 'ramda'

import { useDataBoxSelector, useDataBoxStatus } from 'app/common/data-box-store'
import { GeneralSettings } from 'app/dataTypes/generalSettings'

import { generalSettings, defaultGeneralSettings, GeneralSettingsDataBox } from '../dataBox'


export function useEditGeneralSettings() {
  const [state, setState] = useState<GeneralSettings>(defaultGeneralSettings)
  const [modified, setModified] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const data = useDataBoxSelector(generalSettings, () => generalSettings.data)
  const loading = useDataBoxStatus(generalSettings, () => generalSettings.pending)

  useEffect(() => {
    setState(data)
  }, [data])

  useEffect(() => {
    generalSettings.dataReceive()
  }, [])

  const update = useCallback((name: string, value: boolean | Array<number>) => {
    const optionAvailable = has(name, defaultGeneralSettings)
    if (optionAvailable) {
      setState(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setModified(true)
    }
  }, [])

  const request = useCallback(() => {
    generalSettings.dataReceive()
    setModified(false)
  }, [])

  const submit = useCallback(async () => {
    setSubmitting(true)
    const res = await GeneralSettingsDataBox.submitData(state)
    setModified(false)
    setSubmitting(false)
    return res
  }, [state])

  return {
    generalSettings: state,
    loading,
    submitting,
    modified,
    request,
    submit,
    update,
  }
}
