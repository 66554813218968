import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  PROFILE_CLEAR_KEY,
  PROFILE_RESET,
} from 'app/common/actions/profile/password';

import {
  CODE_EMAIL_REQUEST,
  CODE_EMAIL_SUCCESS,
  CODE_EMAIL_FAILURE,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILURE,
} from 'app/common/actions/profile/email';

import {
  CODE_LOGIN_REQUEST,
  CODE_LOGIN_SUCCESS,
  CODE_LOGIN_FAILURE,
  CHANGE_LOGIN_REQUEST,
  CHANGE_LOGIN_SUCCESS,
  CHANGE_LOGIN_FAILURE,
} from 'app/common/actions/profile/login';

import {
  CODE_PHONE_REQUEST,
  CODE_PHONE_SUCCESS,
  CODE_PHONE_FAILURE,
  CHANGE_PHONE_REQUEST,
  CHANGE_PHONE_SUCCESS,
  CHANGE_PHONE_FAILURE,
} from 'app/common/actions/profile/phone';

import { PROFILE_CHANGE_KEY } from 'app/common/actions/profile';

// TODO некоторые вещи надо повыносить отсюда в локальный стэйт
const defaultState = {
  saving: false,
  requesting: false,
  resultTo: '',
  lockedUntil: 0,
  oldPassError: '',
  requestCodeError: '',
  codeError: '',
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case PROFILE_RESET: {
      return { ...defaultState, ...action.payload };
    }

    case PROFILE_CHANGE_KEY: {
      return { ...state, ...action.payload };
    }

    case CHANGE_EMAIL_REQUEST:
    case CHANGE_LOGIN_REQUEST:
    case CHANGE_PHONE_REQUEST:
    case CHANGE_PASSWORD_REQUEST: {
      return { ...state, saving: true };
    }

    case CHANGE_EMAIL_SUCCESS:
    case CHANGE_LOGIN_SUCCESS:
    case CHANGE_PHONE_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS: {
      return { ...state, saving: false };
    }

    case CHANGE_EMAIL_FAILURE:
    case CHANGE_LOGIN_FAILURE:
    case CHANGE_PHONE_FAILURE:
    case CHANGE_PASSWORD_FAILURE: {
      return { ...state, ...action.payload, saving: false };
    }

    case PROFILE_CLEAR_KEY: {
      return { ...state, [action.payload]: '' };
    }

    case CODE_EMAIL_REQUEST:
    case CODE_LOGIN_REQUEST:
    case CODE_PHONE_REQUEST: {
      return { ...state, requesting: true };
    }

    case CODE_EMAIL_SUCCESS:
    case CODE_LOGIN_SUCCESS:
    case CODE_PHONE_SUCCESS: {
      return { ...state, ...action.payload, requesting: false };
    }

    case CODE_EMAIL_FAILURE:
    case CODE_LOGIN_FAILURE:
    case CODE_PHONE_FAILURE: {
      return { ...state, ...action.payload, requesting: false };
    }

    default:
      return state;
  }
}
