import classNames from 'classnames'
import moment from 'moment-timezone'

import MonthSelector from './month-selector'
import styles from './month-switcher.scss'


const cx = classNames

type Props = {
  className?: string
  monthDate: string
  dateFormat: string
  hideYear?: boolean
  disableMonthModal?: boolean
  onPrev?: () => void
  onNext?: () => void
  onMonth: (m: number) => void
  onYear: (y: number) => void
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean
}

const MonthSwitcher = ({
  className,
  monthDate,
  dateFormat,
  hideYear,
  disableMonthModal,
  onPrev,
  onNext,
  onMonth,
  onYear,
  dateDisabledStatus,
}: Props) => {
  const title = (
    hideYear
      ? moment(monthDate, dateFormat).format('MMMM')
      : moment(monthDate, dateFormat).format('MMMM YYYY')
  )

  return (
    <div className={classNames(styles.root, className)}>
      {onPrev
        && <button
          type="button"
          className={cx(styles.button, styles.button__prev, 'doNotCloseDropdown')}
          onClick={onPrev}
        >
          <span className="visuallyHidden">
            {'Предыдущий месяц'}
          </span>
        </button>}

      {hideYear || disableMonthModal
        ? <div className={styles.month}>{title}</div>
        : <MonthSelector
          monthDate={monthDate}
          dateFormat={dateFormat}
          onMonth={onMonth}
          onYear={onYear}
          dateDisabledStatus={dateDisabledStatus}
        >
          {toggleSelector => (
            <button
              type="button"
              className={cx(styles.button, styles.button__monthYear, 'doNotCloseDropdown')}
              onClick={toggleSelector}
            >
              <div className={styles.monthYear}>{title}</div>
            </button>)}
        </MonthSelector>}

      {onNext
        && <button
          type="button"
          className={cx(styles.button, styles.button__next, 'doNotCloseDropdown')}
          onClick={onNext}
        >
          <span className="visuallyHidden">
            {'Следующий месяц'}
          </span>
        </button>}
    </div>
  )
}

export default MonthSwitcher
