// @flow
import React from 'react';

import PlainLink, { type PlainLinkProps } from 'app/common/ui-next/plain-link';
import Tooltip from 'app/common/ui-next/plain-tooltip';


const PlainLinkTooltiped = (props: PlainLinkProps) => {
  const { disabled } = props;

  if (disabled) {
    return (
      <Tooltip text="Необходимо наличие продукции в комплекте">
        <PlainLink {...props} />
      </Tooltip>
    );
  }

  return <PlainLink {...props} />;
};

export default PlainLinkTooltiped;
