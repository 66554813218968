// @flow
/**
 * Типы пользователя GET account (type)
 * Зависят от типа приложения REACT_APP_NAME
 * cabinet - parent, student
 * food - supplier, employee
 */

export const USER_TYPE_PARENT: 'parent' = 'parent';
export const USER_TYPE_STUDENT: 'student' = 'student';
export const USER_TYPE_SUPPLIER: 'supplier' = 'supplier';
export const USER_TYPE_EMPLOYEE: 'employee' = 'employee';
export const USER_TYPE_SUPERVISOR: 'user' = 'user';

export type UserType =
  | typeof USER_TYPE_PARENT
  | typeof USER_TYPE_STUDENT
  | typeof USER_TYPE_SUPPLIER
  | typeof USER_TYPE_EMPLOYEE
  | typeof USER_TYPE_SUPERVISOR
