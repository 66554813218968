import {
  dishesAddToSelection as dishesAddToSelectionAction,
  dishesRemoveFromSelection as dishesRemoveFromSelectionAction,
  dishesRemoveItems as dishesRemoveItemsAction,
} from 'app/actions/orgs/dishes/selection';
import {
  dishesFieldEdit as dishesFieldEditAction,
  dishesFieldEditOriginal as dishesFieldEditOriginalAction,
} from 'app/actions/orgs/dishes/field';
import {
  hideConfirmation as hideConfirmationAction,
} from 'app/common/actions/confirmation';


// возвращает функции для работы с апи и данными блюд
// дополнительные свойства (orgId) передаются самому объекту при вызове
const connectActions = (dispatch, { id, orgId }) => ({
  dishesRemoveItem: (e) => {
    e.preventDefault();
    dispatch(dishesRemoveItemsAction(orgId, [id]));
    dispatch(hideConfirmationAction());
  },
  dishesFieldEdit: (...params) => dispatch(dishesFieldEditAction(orgId, ...params)),
  dishesFieldEditOriginal: (...params) => dispatch(dishesFieldEditOriginalAction(...params)),
  dishesAddToSelection: theId => dispatch(dishesAddToSelectionAction(theId)),
  dishesRemoveFromSelection: theId => dispatch(dishesRemoveFromSelectionAction(theId)),
});

export default connectActions;
