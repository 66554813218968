// @flow
import React, { Fragment, useState, useCallback, type Node } from 'react';
import not from 'ramda/es/not';

import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';

import useDeleteUser from './useDeleteUser';


type Props = {
  children: (() => void) => Node,
  onSuccess: () => void,
  userId: number | string,
}

const DeleteUser = ({ children, onSuccess, userId }: Props) => {
  const [confirm, setConfirm] = useState(false);
  const toggleDeleteConfirmation = useCallback(() => {
    setConfirm(not);
  }, []);

  const [deleting, deleteUser] = useDeleteUser({ userId, onSuccess });

  return (
    <Fragment>
      {children(toggleDeleteConfirmation)}
      {confirm
        && <PlainConfirmModal
          messageText="Удалить сотрудника?"
          cancelButtonTitle={deleting ? 'Закрыть' : 'Отмена'}
          confirmButtonTitle={deleting ? 'Удаление…' : 'Удалить'}
          confirmButtonProps={{ disabled: deleting }}
          onCancel={toggleDeleteConfirmation}
          onConfirm={deleteUser}
          critical
        />}
    </Fragment>
  );
};

export default DeleteUser;
