// @flow
import Api, { apiTypes, requestsShop, requestsGeneral, appTypes } from 'app/common/api';

import store from './store';

export const api: Api = new Api(apiTypes.API_GENERAL, null, store);
api.addHeaders({ 'Argus-School-Id': localStorage.getItem('schoolId') || '' });

// export const shopApi: Api = new Api(apiTypes.API_SHOP, null, store);

export {
  requestsShop,
  requestsGeneral,
  appTypes,
};
