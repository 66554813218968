import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { ItemType } from 'app/common/ui-next/plain-selector'


/**
 * Data
*/

export type OperationAge = Array<ItemType>

class OperationAgeFilterDataBox extends DataBox<OperationAge> { }

export const defaultOperationAge: OperationAge = []
export const operationAgeFilter = createDataBox('operationAgeFilter', defaultOperationAge, OperationAgeFilterDataBox)

export const defaultOperationAgeData = () => {
  return [
    { id: 'all', title: 'Все' },
    { id: 'selected', title: 'Выбранный период' },
  ]
}

operationAgeFilter.updateData(defaultOperationAgeData)
operationAgeFilter.setStatusReady()

/**
 * Selection
 */

export const defaultOperationAgeSelection: Array<string> = ['all']
export const operationAgeFilterSelection = createDataBoxDefault<Array<string>>('operationAgeFilterSelection', defaultOperationAgeSelection)
