
import { AccrualsFor } from 'app/dataTypes/subsidy/settings'

import { controls } from './formParts/AccuralsForForm'


export default function extractAccrualsFor(formData: FormData): AccrualsFor {
  return {
    single: [
      !!formData.get(`${controls.single}1`),
      !!formData.get(`${controls.single}2`),
    ],
    class: [
      !!formData.get(`${controls.class}1`),
      !!formData.get(`${controls.class}2`),
    ],
    dayCare: [
      !!formData.get(`${controls.dayCare}1`),
      !!formData.get(`${controls.dayCare}2`),
    ],
  }
}
