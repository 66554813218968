import CustomerSearch from 'app/common/ui-next/_filter-compilations/customer-search'
import { customerSearchFilter, customerSearchPhraseFilter, defaultCustomerPhrase, defaultCustomerSearch } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}
export const CustomerSearchFilter = ({ id, pending }: Props) => {
  return (
    <CustomerSearch
      id={id}
      loading={pending}
      dataBoxSearchPhrase={customerSearchPhraseFilter}
      dataBoxCustomer={customerSearchFilter}
      defaultCustomer={defaultCustomerSearch}
      defaultSearchPhrase={defaultCustomerPhrase}
      label="Укажите л/с или телефон"
    />
  )
}
