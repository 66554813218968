// @flow
import React, { type Ref, type Node } from 'react';
import classNames from 'classnames/bind';

import useBlurOnMouseEventRef from 'app/common/hooks/useBlurOnMouseEventRef';

import styles from './plain-checkbox.scss';


const cx = classNames.bind(styles);

export type Props = {
  name?: string,
  value: string,
  label?: string | Node,
  hideLabel?: boolean, // скрыт, но доступен для скрин-ридеров
  skipLabel?: boolean,
  checked?: boolean,
  defaultChecked?: boolean,
  id?: string,
  disabled?: boolean,
  className?: string,
  reference?: Ref<'input'>,
  level?: number,
  style?: Object,
  form?: string,

  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
}

const PlainCheckbox = ({
  name,
  value,
  label,
  hideLabel,
  skipLabel,
  checked,
  defaultChecked,
  id,
  disabled,
  className,
  reference,
  level,
  style,
  form,

  onChange,
  onFocus,
  onBlur,
}: Props): Node => {
  const currentId = id || `${name || ''}_${value}`;
  // TODO remove ввиду focus-visible
  const [elemRef, handleChange] = useBlurOnMouseEventRef(reference, onChange);

  const levelClassName = { [`level${level || ''}`]: level };
  const rootClassName = cx(styles.root, { disabled, hideLabel, ...levelClassName }, className);
  const checkboxClassName = cx(styles.check, { disabled, skipLabel, hideLabel });
  const labelClassName = cx(styles.label, { hidden: hideLabel, disabled });

  const containerClassName = (
    skipLabel
      ? cx(styles.container, { skipLabel, ...levelClassName }, className)
      : cx(styles.container, { hideLabel })
  );

  const renderControl = (
    <span className={containerClassName}>
      <input
        id={currentId}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        className={styles.input}
        ref={elemRef}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        form={form}
      />

      <span className={checkboxClassName} />

      {label
        && !skipLabel
        && <span className={labelClassName}>
          {label}
        </span>}
    </span>
  );

  if (skipLabel) {
    return (
      renderControl
    );
  }

  return (
    <label className={rootClassName} style={style}>
      {renderControl}
    </label>
  );
};

export default PlainCheckbox;
