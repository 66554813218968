import { OrgGroupsByOrgType } from 'app/dataTypes/org/orgGroup'

import { Request } from '../../index'
import { convertResult } from './common'


export { convertResult }

export const convertParams = (): [null, null] => {
  return ([null, null])
}

export const GET_ORG_GROUPS: Request<void, OrgGroupsByOrgType, void> = [
  'food/school_groups',
  'get',
  ['data', 'school_groups'],
]
