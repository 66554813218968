import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  SUBSIDY_TYPES_FILTER_CLEAR,
  SUBSIDY_TYPES_FILTER_DELETE,
  SUBSIDY_TYPES_FILTER_SET,
  SUBSIDY_TYPES_FILTER_UPDATE,
} from 'app/actions/reports/filters/subsidyTypes';

import * as subsidyTypesAvailable from 'app/actions/reports/filters/subsidyTypesAvailable';


const defaultState = {
  data: {
    [subsidyTypesAvailable.SUBSIDY_TYPE_COMMON]: {
      id: subsidyTypesAvailable.SUBSIDY_TYPE_COMMON,
      title: 'Общая',
    },
    [subsidyTypesAvailable.SUBSIDY_TYPE_PERSONAL]: {
      id: subsidyTypesAvailable.SUBSIDY_TYPE_PERSONAL,
      title: 'Индивидуальная',
    },
  },
  selected: {},
  active: false,
};

const subsidyTypes = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SUBSIDY_TYPES_FILTER_CLEAR: {
      return defaultState;
    }
    case SUBSIDY_TYPES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case SUBSIDY_TYPES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case SUBSIDY_TYPES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default subsidyTypes;
