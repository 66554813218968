// @flow
import React from 'react';
import classNames from 'classnames';

import styles from './Stub.scss';


type Props = {
  title?: string,
}

const Stub = ({ title = 'Выберите фильтры и нажмите кнопку «Сформировать»' }: Props) => (<div className={styles.root}>
  <svg className={styles.icon} viewBox="3500 9000 23500 6000" width="290mm" height="328px">
    <g id="Слой_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <path className={styles.fil0} d="M22667 13803c0,83 38,172 83,208 54,43 96,88 184,95 166,15 385,3 557,3 115,0 469,16 546,-14 13,-5 8,-3 21,-8l12 -4c12,-5 8,-4 15,-8 43,-21 48,-29 87,-58 2,-1 3,-3 5,-3 102,-79 104,-203 117,-228 27,-8 36,-3 60,-8 18,-3 25,-9 49,-17 32,-11 60,-29 85,-46 140,-95 195,-303 95,-463l-31 -48c-179,-168 -256,-104 -319,-128 -33,-12 -116,-230 -419,-240 -181,-6 -347,69 -441,218 -81,125 -17,105 -120,146 -71,27 -141,81 -190,138 -8,9 -19,25 -28,37 -82,118 -14,110 -130,136 -133,29 -238,145 -238,292zm341 255c184,0 859,14 1002,-18 88,-19 185,-109 208,-212 66,-295 -278,-468 -497,-294 -24,20 -32,39 -67,40 -51,-82 128,-136 164,-152 -15,-99 -232,-217 -400,-208 -60,3 -174,45 -222,81 -196,149 -142,247 -198,261 -54,14 -146,1 -218,92 -10,13 -19,30 -31,43 -53,64 -47,367 259,367zm380 -893c113,-5 216,7 312,61 91,50 126,99 187,182 29,1 99,-2 124,6l95 37c176,97 173,247 184,258 46,45 213,-65 248,-139 22,-47 38,-106 31,-159 -4,-29 -6,-56 -18,-83 -28,-61 -51,-92 -109,-138 -44,-35 0,-5 -31,-19 -72,-31 -186,-49 -269,-18l-9 4c-1,1 -3,2 -4,3l-35 22c-21,16 -38,32 -56,47 -32,26 -38,17 -61,-7 32,-81 156,-115 176,-130 -1,-60 -112,-129 -161,-156 -150,-86 -332,-60 -472,46 -37,28 -134,120 -132,183z" />
      <path className={styles.fil1} d="M5850 12292c-326,50 -313,370 -151,476 87,56 152,51 278,51 214,0 446,-8 659,1 79,4 135,-7 193,-48 91,-63 108,-102 139,-189 10,-28 10,-44 9,-77 -1,-118 -106,-246 -211,-275 -77,-22 -64,-16 -147,-24 -67,-143 -254,-212 -423,-199 -62,5 -181,59 -219,95 -16,15 -21,23 -38,37 -37,31 -76,107 -89,152zm808 -20c-72,0 -132,12 -178,56 -22,21 -16,29 -57,24 -5,-51 46,-80 93,-105 15,-8 12,-7 23,-13l27 -14c-50,-135 -419,-295 -625,2 -65,94 -17,120 -111,128 -72,6 -125,44 -154,91 -40,63 -54,116 -23,191 80,192 339,140 573,140l344 1c53,0 163,-2 210,-29 57,-33 97,-88 119,-134 52,-111 -8,-338 -241,-338z" />
      <path className={styles.fil0} d="M8751 11040l-1 176c0,26 14,126 25,148 69,134 34,74 122,179l21 22c94,81 237,84 356,84 320,0 640,0 960,0 114,0 371,-12 455,23 377,158 289,644 51,740 -72,29 -92,47 -191,47l-2088 0c-85,0 -185,4 -250,40 -52,29 -90,47 -133,93 -179,193 -163,567 29,752 105,101 222,141 376,141 765,0 1202,0 1601,0 -1,-3 -2,-7 -2,-11l0 -26c0,-3 1,-6 2,-9 -325,1 -692,2 -1308,2 -259,0 -561,42 -712,-231 -72,-131 -73,-346 -28,-481 15,-44 108,-145 177,-179 16,-7 28,-10 42,-16 76,-34 183,-29 279,-29l1935 0c189,0 265,-12 376,-106 35,-29 82,-93 103,-140 31,-68 41,-124 40,-215 0,-153 -55,-244 -143,-332 -33,-33 -29,-26 -67,-49 -96,-56 -186,-60 -302,-60l-1275 0c-139,0 -243,-49 -322,-152 -95,-123 -66,-232 -72,-413l-24 -17c-16,5 -19,9 -32,19zm1713 2446c348,0 735,0 1346,0 28,73 62,154 183,153 26,0 82,-13 104,-26l20 -23c26,-34 50,-54 51,-116 6,-229 -307,-255 -354,-40 -643,0 -1019,1 -1352,3 2,4 4,9 4,14l-1 26c0,3 0,6 -1,9z" />
      <path className={styles.fil0} d="M20076 11188c-143,-2 -394,-13 -523,22 -319,85 -434,470 -303,694 128,220 288,226 511,226 224,0 962,-19 1081,22 165,58 295,244 239,469l-22 65c-1,1 -1,3 -2,4 -1,2 -2,3 -2,5 -27,46 -80,104 -127,135 -92,63 -155,70 -295,70 -94,0 -696,-12 -733,9l0 30c47,16 635,6 741,6 163,0 303,-30 403,-153 14,-18 26,-36 38,-58l31 -65c67,-164 11,-376 -118,-481 -36,-30 -99,-63 -130,-76 -9,-4 -8,-3 -18,-6 -111,-33 -97,-22 -221,-21 -221,0 -990,16 -1109,-19 -54,-15 -79,-37 -117,-62 -36,-24 -67,-59 -94,-98 -87,-128 -73,-331 7,-457l53 -72c47,-62 183,-136 292,-142l470 -1 257 0c-3,-5 -4,-10 -4,-15l0 -29 0 0c-101,0 -198,-1 -305,-2zm723 44l151 0c384,-1 980,-1 1018,6 21,36 11,78 89,118 2,1 27,14 29,15 81,30 250,-10 240,-185 -5,-87 -85,-157 -176,-158 -32,0 -45,-2 -68,10 -125,70 -98,129 -119,145 -11,8 -479,8 -857,7 -115,0 -215,-1 -305,-1 1,2 1,3 1,4l-1 29c0,4 -1,7 -2,10z" />
      <path className={styles.fil2} d="M10089 13419c-14,1 -25,13 -25,28l-1 29c0,14 11,26 25,28l21 3c4,13 9,25 16,37l-14 17c-9,11 -9,27 2,38l20 20c10,11 26,12 38,3l17 -13c12,7 24,12 38,16l2 22c1,15 13,26 28,26l29 0c14,0 27,-10 28,-25l3 -21c14,-3 28,-8 41,-16l16 13c11,10 28,9 38,-1l21 -21c10,-10 11,-26 2,-37l-12 -16c8,-13 13,-26 17,-40l19 -2c15,-2 26,-14 26,-28l0 -29c1,-15 -10,-27 -25,-29l-18 -2c-4,-14 -9,-28 -16,-40l12 -14c9,-12 8,-28 -2,-38l-20 -21c-10,-10 -26,-11 -38,-2l-14 10c-13,-8 -27,-14 -41,-18l-2 -18c-2,-14 -14,-25 -28,-25l-29 -1c-15,0 -27,11 -29,25l-2 18c-15,4 -30,10 -43,18l-14 -12c-12,-9 -28,-8 -38,2l-21 20c-10,10 -12,26 -3,38l13 16c-7,12 -13,26 -17,40l-20 2zm187 -33c41,0 74,35 74,76 -1,41 -35,75 -76,74 -42,0 -75,-34 -74,-76 0,-41 34,-74 76,-74z" />
      <path className={styles.fil0} d="M11991 13282c101,0 183,81 183,183 0,101 -82,183 -183,183 -101,0 -183,-82 -183,-183 0,-102 82,-183 183,-183zm0 50c73,0 133,59 133,133 0,73 -60,132 -133,132 -73,0 -132,-59 -132,-132 0,-74 59,-133 132,-133z" />
      <path className={styles.fil2} d="M20407 11162c-15,1 -26,13 -26,28l0 29c-1,14 10,26 25,28l21 3c3,13 8,25 15,37l-14 17c-9,11 -8,27 2,38l20 20c10,11 26,12 38,3l17 -13c12,7 25,12 38,16l2 22c2,15 14,26 28,26l29 0c15,0 27,-10 29,-25l2 -21c15,-3 28,-8 41,-16l16 13c12,10 28,9 38,-1l21 -21c10,-10 11,-26 3,-37l-13 -16c8,-13 14,-26 18,-40l19 -2c14,-2 25,-14 25,-28l1 -29c0,-15 -11,-27 -25,-29l-19 -2c-3,-14 -9,-28 -15,-40l11 -14c9,-12 9,-28 -2,-38l-20 -21c-10,-10 -26,-11 -38,-2l-14 10c-13,-8 -26,-14 -41,-18l-2 -18c-1,-14 -13,-25 -28,-25l-29 -1c-14,0 -27,11 -28,25l-3 18c-15,4 -29,10 -43,18l-14 -12c-11,-9 -28,-8 -38,2l-21 20c-10,10 -11,26 -2,38l12 16c-7,12 -13,26 -16,40l-20 2zm186 -33c41,0 75,35 74,76 0,41 -34,75 -76,74 -41,0 -75,-34 -74,-76 0,-41 35,-74 76,-74z" />
      <path className={styles.fil0} d="M22148 11021c101,0 183,82 183,183 0,101 -82,183 -183,183 -101,0 -183,-82 -183,-183 0,-101 82,-183 183,-183zm0 64c65,0 118,53 118,119 0,65 -53,118 -118,118 -65,0 -118,-53 -118,-118 0,-66 53,-119 118,-119z" />
      <path className={styles.fil0} d="M15260 12382c-326,50 -314,371 -151,476 86,56 152,52 277,52l660 1c79,3 135,-8 193,-49 92,-63 150,-153 148,-266 -2,-118 -107,-245 -211,-275 -77,-21 -64,-16 -148,-24 -67,-143 -253,-212 -422,-199 -63,5 -181,59 -219,95 -16,15 -21,24 -38,37 -37,31 -76,107 -89,152zm805 -31c-71,0 -130,24 -175,67 -22,21 -16,29 -57,25 -5,-52 45,-81 93,-106 15,-8 11,-6 22,-12l27 -15c-49,-135 -418,-295 -624,2 -66,95 -18,120 -111,129 -72,6 -125,44 -154,90 -41,64 -54,116 -23,191 16,39 40,69 70,90 28,21 62,34 100,43 16,4 34,9 55,9 210,6 593,6 793,6 108,0 203,-98 238,-172 52,-110 -21,-347 -254,-347z" />
      <path className={styles.fil2} d="M9148 10617c1,0 1,-1 2,-1 1,-2 2,-4 3,-6 0,0 1,0 1,-1 1,-3 2,-5 2,-8 0,-1 0,-1 0,-2 0,0 0,0 0,0 0,0 0,0 0,0 0,-1 0,-1 0,-1 0,-3 0,-6 -1,-9 0,-1 -1,-1 -1,-2 -1,-2 -2,-5 -4,-7 0,0 -1,-1 -2,-2 -1,-1 -3,-3 -5,-4 -1,-1 -2,-1 -3,-2 0,0 -1,0 -2,-1l-1543 -644c-11,-4 -24,-1 -32,7 -8,9 -10,22 -4,33l248 469 -190 423c0,1 0,1 0,1 -2,2 -3,4 -4,6 -1,1 -1,2 -2,3 -1,2 -1,5 -1,8 0,0 -1,1 -1,1 0,0 0,1 0,1 1,0 1,1 1,2 0,2 0,4 1,6 0,0 0,1 0,1 0,0 0,1 1,1 0,2 1,4 2,5 1,1 1,2 2,3 2,1 3,3 6,5l0 0c0,0 0,0 0,0 0,0 1,1 1,1 7,4 15,5 22,4l376 4 150 360c2,6 6,10 10,13 3,2 5,3 8,4 9,2 17,0 24,-5l931 -662c1,-1 2,-2 3,-2 0,0 0,-1 0,-1 1,0 1,-1 1,-1zm-371 -35l-840 29c-2,0 -3,0 -4,1 -1,0 -1,0 -2,0 -1,0 -1,0 -2,1 -1,0 -2,0 -2,1 -2,0 -4,1 -6,3 0,0 0,0 -1,0 0,0 0,0 0,0l-206 165 141 -317 922 117zm-1058 269l207 -166 55 132 16 37 -140 -1 -138 -2zm1206 -307l-1069 -135 -211 -399 1280 534zm-716 673l-199 -478 -31 -73 1056 -37 -826 588z" />
      <path className={styles.fil2} d="M19713 12644c0,0 0,-1 0,-2 1,-2 1,-4 1,-6 0,-1 0,-1 1,-2 0,-3 -1,-5 -2,-8 0,-1 0,-1 0,-2 0,0 0,0 0,0 0,0 0,0 0,0 -1,0 -1,-1 -1,-1 -1,-3 -3,-5 -5,-8 0,0 -1,0 -1,-1 -2,-2 -4,-3 -6,-4 -1,-1 -2,-1 -3,-2 -2,-1 -4,-1 -6,-1 -1,-1 -2,-1 -3,-1 -1,0 -2,0 -3,0l-1672 19c-12,1 -23,8 -27,19 -3,12 0,24 9,32l414 332 -7 464c0,1 0,1 0,1 0,3 -1,5 -1,7 0,1 0,2 0,3 0,3 1,6 1,8 1,1 0,1 1,2 0,0 0,0 0,0 0,1 1,1 1,2 1,2 2,4 3,5 0,0 1,1 1,1 0,0 1,0 1,1 2,1 3,3 5,4 1,0 1,1 2,1 2,1 5,2 7,3l0 0c1,0 1,0 1,0 0,0 1,0 1,0 8,2 15,-1 22,-5l346 -145 281 272c4,3 9,6 14,7 3,1 6,1 9,0 9,-1 16,-6 21,-13l592 -977c1,-1 1,-2 2,-3 0,0 0,-1 0,-1 0,0 0,-1 1,-1zm-356 115l-759 359c-2,0 -3,1 -4,2 0,0 -1,1 -1,1 -1,0 -1,1 -2,2 -1,0 -1,1 -2,1 -1,2 -2,3 -3,5 -1,0 -1,1 -1,1 0,0 0,0 0,0l-125 233 5 -347 892 -257zm-864 666l124 -235 103 99 29 29 -129 53 -127 54zm986 -760l-1035 299 -352 -283 1387 -16zm-391 902l-373 -361 -56 -54 954 -452 -525 867z" />
      <path className={styles.fil2} d="M13824 12653c-6,-22 -26,-36 -48,-36l-788 0 -216 -216c-49,-49 -129,-49 -179,0 -24,25 -37,57 -37,90l0 0c0,32 13,65 37,89l179 178 -215 686c0,2 -1,5 -1,7l0 379c0,28 22,50 50,51l1417 0c28,-1 50,-23 50,-51l0 -379 0 0c0,-2 -1,-5 -1,-7l-248 -791zm146 636l43 137 -215 0c71,-25 132,-73 172,-137l0 0zm-441 136c48,-49 114,-131 122,-204 61,32 172,79 267,56 -85,122 -287,145 -389,148zm-292 -380l42 -43 43 128 -128 -43 43 -42zm-18 -54l-215 -214c-10,-10 -25,-10 -35,0 -10,10 -10,26 0,36l214 214 -36 36 -357 -358 36 -35 71 71c7,6 16,9 25,7 8,-3 15,-10 17,-18 3,-9 0,-18 -6,-25l-72 -71 36 -36 358 358 -36 35zm-501 -357l108 -107 35 35 -53 54 -54 54 -36 -36zm-89 -197c30,-29 78,-29 107,0l54 54 -107 107 -54 -54c-29,-29 -29,-77 0,-107zm501 679c2,3 5,5 9,6 0,0 0,0 0,0l167 56c5,2 11,3 16,3 16,0 31,-8 41,-21 9,-14 12,-31 7,-46l-56 -167c0,0 0,0 0,0 -1,-4 -3,-7 -6,-9l-270 -270 738 0 170 542c-113,73 -312,-57 -314,-58 -10,-7 -22,-6 -31,2 -9,7 -11,20 -6,30 33,65 -87,194 -139,242l-840 0 196 -627 318 317zm185 714l-708 0 0 -50 227 0c14,0 26,-11 26,-25 0,-14 -12,-26 -26,-26l-227 0 0 -50 960 0c14,0 26,-12 26,-26 0,-13 -12,-25 -26,-25l-960 0 0 -50 227 0c14,0 26,-12 26,-26 0,-14 -12,-25 -26,-25l-227 0 0 -50 880 0c4,0 9,0 14,0 5,0 9,0 14,0l507 0 0 50 -960 0c-14,0 -25,11 -25,25 0,14 11,26 25,26l960 0 0 50 -227 0c-14,0 -25,12 -25,25 0,14 11,26 25,26l227 0 0 50 -960 0c-14,0 -25,12 -25,26 0,14 11,25 25,25l960 0 0 50 -707 0z" />
      <g id="_869239632">
        <path className={styles.fil2} d="M23715 11392l0 -1188c0,-19 -16,-35 -35,-35l-524 0 0 70 489 0 0 1118 -908 0 0 -1118 70 0 0 -70 -105 0c-19,0 -35,16 -35,35l0 1188c0,19 16,34 35,34l978 0c19,0 35,-15 35,-34z" />
        <path className={styles.fil2} d="M23924 11601l0 -1187c0,-20 -15,-35 -35,-35l-104 0 0 70 70 0 0 1117 -908 0 0 -70 -70 0 0 105c0,19 15,35 35,35l977 0c20,0 35,-16 35,-35z" />
        <path className={styles.fil2} d="M24099 10588l-105 0 0 70 70 0 0 1118 -908 0 0 -70 -70 0 0 105c0,19 16,34 35,34l978 0c19,0 35,-15 35,-34l0 -1188c0,-19 -16,-35 -35,-35z" />
        <polygon className={styles.fil2} points="23261,10414 23575,10414 23575,10344 23261,10344 " />
        <polygon className={styles.fil2} points="23156,10658 23575,10658 23575,10588 23156,10588 " />
        <polygon className={styles.fil2} points="22807,10798 23575,10798 23575,10728 22807,10728 " />
        <polygon className={styles.fil2} points="22807,10938 23575,10938 23575,10868 22807,10868 " />
        <polygon className={styles.fil2} points="22807,11077 23575,11077 23575,11007 22807,11007 " />
        <polygon className={styles.fil2} points="22807,11252 23051,11252 23051,11182 22807,11182 " />
        <polygon className={styles.fil2} points="23331,11252 23575,11252 23575,11182 23331,11182 " />
        <path className={styles.fil2} d="M22982 10658c57,0 104,-47 104,-105l0 -488 -70 0 0 488c0,20 -15,35 -34,35 -20,0 -35,-15 -35,-35l0 -488c0,-39 31,-70 69,-70l70 0c39,0 70,31 70,70l0 34 70 0 0 -34c0,-78 -63,-140 -140,-140l-70 0c-77,0 -139,62 -139,140l0 488c0,58 47,105 105,105z" />
      </g>
      <path className={classNames(styles.fil3, styles.str0)} d="M18098 13574l0 319c0,305 -250,555 -555,555l-1029 0m-421 0l-1028 0c-306,0 -556,-250 -556,-555l0 -59c0,-305 26,-555 -307,-555" />
      <circle className={styles.fil4} cx="11991" cy="13465" r="132" />
      <path className={styles.fil0} d="M18098 13482c101,0 183,82 183,183 0,101 -82,183 -183,183 -101,0 -183,-82 -183,-183 0,-101 82,-183 183,-183zm0 51c73,0 132,59 132,132 0,73 -59,133 -132,133 -73,0 -132,-60 -132,-133 0,-73 59,-132 132,-132z" />
      <circle className={styles.fil4} cx="18098" cy="13665" r="132" />
      <path className={styles.fil2} d="M16119 14404c-14,1 -25,13 -25,28l-1 29c0,14 11,27 25,28l21 3c4,13 9,25 15,37l-13 17c-9,11 -9,27 1,38l21 20c10,11 26,12 37,3l17 -13c12,7 25,13 38,17l3 21c1,15 13,26 28,26l29 0c14,1 26,-10 28,-25l3 -20c14,-4 28,-9 41,-16l16 13c11,9 27,8 38,-2l20 -20c11,-10 12,-27 3,-38l-12 -16c7,-13 13,-26 17,-40l19 -2c15,-1 26,-13 26,-28l0 -29c0,-14 -10,-27 -25,-29l-18 -2c-4,-14 -9,-27 -16,-40l12 -14c9,-11 8,-28 -2,-38l-20 -21c-10,-10 -27,-11 -38,-2l-14 10c-13,-7 -27,-13 -42,-18l-1 -18c-2,-14 -14,-25 -28,-25l-29 0c-15,-1 -27,10 -29,24l-2 18c-15,4 -30,10 -43,18l-15 -12c-11,-9 -27,-8 -37,2l-21 20c-11,10 -12,27 -3,38l12 16c-7,12 -12,26 -16,40l-20 2zm186 -33c42,1 75,35 75,76 -1,42 -35,75 -76,74 -42,0 -75,-34 -75,-76 1,-41 35,-74 76,-74z" />
      <circle className={styles.fil4} cx="22148" cy="11204" r="132" />
    </g>
  </svg>
  <div className={styles.label}>{title}</div>
</div>);

export default Stub;
