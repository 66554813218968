import { reduce } from 'ramda'

import { AgreementDocumentKind, AgreementDocumentListType } from 'app/dataTypes/agreement/documents'
import { FileType } from 'app/common/api/flowTypes'

import type { Request } from '../../../index'


export const convertKind = (id: 2 | 4) => {
  if (id === 2) return AgreementDocumentKind.PublicOffer
  if (id === 4) return AgreementDocumentKind.PersonalDataAgreement
  return null as never
}

type APIResult = Array<{
  id: number
  kind_id: 2 | 4
  pdf_file: FileType | null
}>

export const convertResult = (data: APIResult): AgreementDocumentListType => {
  return reduce((acc, val) => {
    const {
      id,
      kind_id: kindId,
      pdf_file: pdfFile,
    } = val
    if (id && pdfFile) {
      acc.push({
        ID: String(id),
        kind: convertKind(kindId),
        pdfFile,
      })
    }
    return acc
  }, [] as AgreementDocumentListType, data)
}

export const GET_AGREEMENT_DOCUMENTS: Request<void, AgreementDocumentListType, void> = [
  'food/agreement/documents',
  'get',
  ['data', 'documents'],
]
