import { GET_AGREEMENT_DOCUMENTS, convertResult } from 'app/common/api/requests/food/agreement/documents/get'

import { useCommonDataProvider, Options } from 'app/dataProviders/useCommonDataProvider'
import { AgreementDocumentListType } from 'app/dataTypes/agreement/documents'


const defaultState: AgreementDocumentListType = []

type Op = Omit<Options<
  AgreementDocumentListType,
  void
>, 'requestValue' | 'convertions' | 'defaultState'>

export function useAgreementDocuments(options: Op = {} as Op) {
  const res = useCommonDataProvider({
    ...options,
    params: undefined,
    requestValue: GET_AGREEMENT_DOCUMENTS,
    error: 'Не удалось получить список документов',
    convertions: {
      convertResult,
    },
    defaultState,
  })

  return res
}
