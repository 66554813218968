// @flow
import React, { type Node, useCallback } from 'react';

import {
  PlainDraggableListProvider,
  PlainDraggableList,
  type OnDragEndResponder,
} from 'app/common/ui-components/plain-draggable-list';

import type { Category, Categories as CategoriesType } from 'app/dataTypes/vending/production/Categories.types';

import CategoryItem from './CategoryItem';


type Props = {
  categories: CategoriesType,
  loading?: boolean,
  currentCategoryId: string,
  onDeleteCategory: (categoryId: string) => Promise<boolean>,
  onMoveItem: (from: number, to: number) => void,
  redirectToCategory: (id: string, writeHistory?: boolean) => void,
}

const Categories = ({
  categories,
  loading,
  currentCategoryId,
  onDeleteCategory,
  onMoveItem,
  redirectToCategory,
}: Props): Node => {
  const handleDragEnd: OnDragEndResponder = useCallback(({ destination, source }) => {
    if (!destination) return;
    const { index: indexDestination } = destination;
    const { index: indexSource } = source;
    onMoveItem(indexSource, indexDestination);
  }, [onMoveItem]);

  return (
    <PlainDraggableListProvider onDragEnd={handleDragEnd}>
      <PlainDraggableList
        droppableId="categories"
        injectDraggableStateSnapshot
      >
        {categories.map((props: Category, i) => {
          const { id, deletable } = props;

          return (
            <CategoryItem
              key={id}
              first={i === 0}
              isDragDisabled={i === 0}
              active={Boolean(currentCategoryId && currentCategoryId === id)}
              {...props}
              deletable={deletable}
              loading={loading}
              onDeleteCategory={onDeleteCategory}
              onClickCategory={redirectToCategory}
            />
          );
        })}

      </PlainDraggableList>
    </PlainDraggableListProvider>
  );
};

export default Categories;
