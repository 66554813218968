// @flow
// import keys from 'ramda/es/keys';
import action from 'app/common/lib/action';

// import * as salesTypesAvailable from 'app/actions/reports/filters/salesTypesAvailable';
import getDetailsGroup from './getDetailsGroup';


export const SALES_RESET_PARAMS = 'reports/SALES_RESET_PARAMS';
export const SALES_SET_REPORT_ID = 'reports/SALES_SET_REPORT_ID';
export const SALES_SET_XLS_FILE_ID = 'reports/SALES_SET_XLS_FILE_ID';
export const SALES_VIEW_PARAMS_CREATE = 'reports/SALES_VIEW_PARAMS_CREATE';

export const salesResetParams = (payload: number) => ({
  type: SALES_RESET_PARAMS,
  payload,
});

export const salesSetReportId = (payload: number) => ({
  type: SALES_SET_REPORT_ID,
  payload,
});

export const salesSetXlsReportId = (payload: number) => ({
  type: SALES_SET_XLS_FILE_ID,
  payload,
});

// Фильтры, которые влияют на отбражение присланных данных
// выносятся в отдельное место, чтоб при изменении параметров фильтра,
// он не влиял на отображение текущих данных
export const salesReportViewParamsCreate = () => (dispatch: Function, getState: Function) => {
  const state = getState();

  const groupType = getDetailsGroup(state.reports.filters);
  const { details } = state.reports.filters;

  const params = {
    enableReportDownload: !!details,
    groupType,
  };
  dispatch(action(SALES_VIEW_PARAMS_CREATE, params));
};
