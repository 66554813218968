export enum MailID {
  DoNotMail = 'do_not_mail',
  MailAndView = 'mail_and_view',
  MailOnly = 'mail_only'
}

export enum ID {
  details = 'details',
  city = 'city',
  district = 'district',
  orgType = 'orgType',
  org = 'org',
  periodFrom = 'periodFrom',
  periodTo = 'periodTo',
  periodDateFormat = 'periodDateFormat',
  email = 'email',
  mailing = 'mailing',
  gender = 'gender',
  identifier = 'identifier',
  attend = 'attend',
  subsidy = 'subsidy',
  orgsOrUser = 'orgsOrUser',
  customerType = 'customerType',
  customerSearch = 'customerSearch',
  classesList = 'classesList',
  studentList = 'studentList',
  schoolYear = 'schoolYear',
  statementStatuses = 'statementStatuses',
  operationsType = 'operationsType',
  operationAge = 'operationAge',
  year = 'year',
  month = 'month'
}
