// @flow
import { useCallback, useMemo, useEffect } from 'react';
import { assoc } from 'ramda';

import {
  type ModelsState,
  useMachineForm,
  useMachineValidate,
  useModels,
} from 'app/dataProviders/VendingDataProvider';


export default function useActualModels(): {
    models: ModelsState,
    loadingModels: boolean,
    modelSelection: Array<string>,
    changeCurrentModel: Array<string> => void,
    } {
  const [{ modelId }, { update }] = useMachineForm();
  const [,, resetErrors] = useMachineValidate();

  const [models, loadingModels, requestModels] = useModels();

  const modelSelection = useMemo(() => (
    modelId
      ? [String(modelId)]
      : []
  ), [modelId]);

  const changeCurrentModel = useCallback(([modelId_]: Array<string>) => {
    resetErrors();
    update(assoc('modelId', parseInt(modelId_, 10) || 0));
  }, [update, resetErrors]);


  useEffect(() => {
    requestModels();
  }, [requestModels]);

  return {
    models,
    loadingModels,
    modelSelection,
    changeCurrentModel,
  };
}
