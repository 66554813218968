import cn from 'classnames'

import EditableProp from 'app/common/ui/EditableProp'

import styles from './EditablePropWithDisable.scss'


type Props = {
  className?: string
  disabled?: boolean
  right?: boolean
  value: string
  name: string
  children: any
}

export const EditablePropWithDisable = ({
  className,
  disabled,
  right,
  value,
  name,
  children,
}: Props) => (disabled
  ? <div className={cn(styles.locked, className)}>
    {value}
  </div>

  : <EditableProp
    className={className}
    right={right}
    value={value}
    name={name}
  >
    {children}
  </EditableProp>
)
