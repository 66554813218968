// @flow
import { createSelector } from 'reselect';
import prop from 'ramda/es/prop';

import { tableColumnSort, tableColumnSortClass } from 'app/common/lib/sort';
import { getLastFirstM } from 'app/common/lib/names';


const selectConverted = createSelector(
  (_, { data }) => data,
  arr => arr.map((item) => {
    const {
      available_for_refund: availableForRefund,
      customer_id: customerId,
      customer_type: customerType,
      klass,
      role,
      name,
      ...others
    } = item;
    return ({
      availableForRefund,
      customerId,
      customerType,
      name: getLastFirstM(name),
      group: klass || role,
      ...others,
    });
  }),
);

export type IncomingProps = {
  id: number,
  name: string,
  group: string,
  balance: number,
  customerId: number,
  availableForRefund: number,
}

type Selector = (state: Object, props: Object) => Array<IncomingProps>

const selectUsers: Selector = createSelector(
  [
    (_, { sort: { sortDirection } }) => sortDirection,
    (_, { sort: { sortKey } }) => sortKey,
    selectConverted,
  ],
  (dir, key, items) => (key === 'group'
    ? tableColumnSortClass(dir, prop(key), items)
    : tableColumnSort(dir, prop(key), items)),
);

export default selectUsers;
