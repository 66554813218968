// https://github.com/okonet/react-dropzone
import React from 'react';
import cn from 'classnames';
import styles from './PlainFile.scss';

import getDataTransferItems from './getDataTransferItems';
import accepts from './attr-accept';

const supportMultiple = ((typeof document !== 'undefined' && document && document.createElement)
  ? 'multiple' in document.createElement('input')
  : true);

// const propTypes = {
//   id: React.PropTypes.string,
//   label: React.PropTypes.string,
//   isError: React.PropTypes.bool,
//   errorText: React.PropTypes.string,
//   style: React.PropTypes.object,
//   labelStyle: React.PropTypes.object,
//   comment: React.PropTypes.string,

//   multiple: React.PropTypes.bool, // Allow dropping multiple files
//   disablePreview: React.PropTypes.bool, // Enable/disable preview generation
//   disableClick: React.PropTypes.bool, // Disallow clicking on the dropzone container
//   onFileDialogCancel: React.PropTypes.func, // on clicking the cancel button of the file dialog
//   accept: React.PropTypes.string, // Allow specific types of files.
//   // See https://github.com/okonet/attr-accept for more information
//   maxSize: React.PropTypes.number,
//   minSize: React.PropTypes.number,
//   children: React.PropTypes.oneOfType([
//     React.PropTypes.node,
//     React.PropTypes.func,
//   ]),
//   disabled: React.PropTypes.bool,

//   onDragStart: React.PropTypes.func,
//   onDragEnter: React.PropTypes.func,
//   onDragOver: React.PropTypes.func,
//   onDragLeave: React.PropTypes.func,
//   onDrop: React.PropTypes.func,
//   onDropAccepted: React.PropTypes.func,
//   onDropRejected: React.PropTypes.func,
//   onClick: React.PropTypes.func,
// };

type Props = {
  id: string,
  accept: string,
  label?: string,
  comment?: string,
  multiple?: boolean,
  disablePreview?: boolean,
  disableClick?: boolean,
  maxSize: number,
  minSize: number,
  disabled?: boolean,
  onInvalidFormat: (name: string) => void,
  children: React.Node | ({ isDragActive: boolean, isDragReject: boolean }) => React.Node,
  className?: string,
  classNameLabel?: string,
  onDrop: (acceptedFiles: Array<File>, rejectedFiles: Array<File>, e: any) => Promise<void>,
  onExceedSize: (name: string) => void,
}

type State = {
  isDragActive: boolean,
  isDragReject: boolean,
}

const defaultProps = {
  label: '',
  multiple: true,
  disablePreview: false,
  disableClick: false,
  maxSize: Infinity,
  minSize: 0,
};

class PlainFile extends React.Component<Props, State> {
  static renderChildren(children, isDragActive, isDragReject) {
    if (typeof children === 'function') {
      return children({ isDragActive, isDragReject });
    }
    return children;
  }

  state = {
    isDragActive: false,
  };

  componentDidMount() {
    this.enterCounter = 0;
    // Tried implementing addEventListener, but didn't work out
    document.body.onfocus = this.onFileDialogCancel;
  }

  componentWillUnmount() {
    // Can be replaced with removeEventListener, if addEventListener works
    document.body.onfocus = null;
  }

  onDragStart = (e) => {
    const { onDragStart } = this.props;
    if (onDragStart) {
      onDragStart(e);
    }
  };


  onDragEnter = (e) => {
    e.preventDefault();
    const { multiple, disabled, onDragEnter } = this.props;
    // Count the dropzone and any children that are entered.
    this.enterCounter += 1;
    const allFilesAccepted = this.allFilesAccepted(getDataTransferItems(e, multiple));

    this.setState({
      isDragActive: allFilesAccepted,
      isDragReject: !allFilesAccepted || disabled,
    });

    if (onDragEnter) {
      onDragEnter(e);
    }
  };

  onDragOver = (e) => { // eslint-disable-line class-methods-use-this
    e.preventDefault();
    e.stopPropagation();
    const { disabled, onDragOver } = this.props;

    try {
      if (disabled) {
        e.dataTransfer.dropEffect = 'none'; // eslint-disable-line no-param-reassign
      } else {
        e.dataTransfer.dropEffect = 'copy'; // eslint-disable-line no-param-reassign
      }
    } catch (err) {
      // continue regardless of error
    }

    if (onDragOver) {
      onDragOver(e);
    }
    return false;
  };

  onDragLeave = (e) => {
    e.preventDefault();
    const { onDragLeave } = this.props;

    // Only deactivate once the dropzone and all children was left.
    this.enterCounter -= 1;
    if (this.enterCounter > 0) {
      return;
    }

    this.setState({
      isDragActive: false,
      isDragReject: false,
    });

    if (onDragLeave) {
      onDragLeave(e);
    }
  };

  onDrop = (e) => {
    const { onDrop, onDropAccepted, onDropRejected, multiple, disablePreview, disabled } = this.props;
    if (disabled) return;
    const fileList = getDataTransferItems(e, multiple);
    const acceptedFiles = [];
    const rejectedFiles = [];

    // Stop default browser behavior
    e.preventDefault();

    // Reset the counter along with the drag on a drop.
    this.enterCounter = 0;
    this.isFileDialogActive = false;

    fileList.forEach((file) => {
      if (!disablePreview) {
        file.preview = window.URL.createObjectURL(file); // eslint-disable-line no-param-reassign
      }

      if (this.fileAccepted(file) && this.fileMatchSize(file)) {
        acceptedFiles.push(file);
      } else {
        rejectedFiles.push(file);
      }
    });

    if (onDrop) {
      onDrop(acceptedFiles, rejectedFiles, e);
    }

    if (rejectedFiles.length > 0 && onDropRejected) {
      onDropRejected(rejectedFiles, e);
    }

    if (acceptedFiles.length > 0 && onDropAccepted) {
      onDropAccepted(acceptedFiles, e);
    }

    // Reset drag state
    this.setState({
      isDragActive: false,
      isDragReject: false,
    });
  };

  onClick = (/* e */) => {
    // const { onClick, disableClick } = this.props;
    // if (!disableClick) {
    //   e.stopPropagation();
    //   this.open();
    //   if (onClick) {
    //     onClick(e);
    //   }
    // }
  };

  onFileDialogCancel = () => {
    // timeout will not recognize context of this method
    const { onFileDialogCancel } = this.props;
    const { fileInputEl } = this;
    let { isFileDialogActive } = this;
    // execute the timeout only if the onFileDialogCancel is defined and FileDialog
    // is opened in the browser
    if (onFileDialogCancel && isFileDialogActive) {
      setTimeout(() => {
        // Returns an object as FileList
        const FileList = fileInputEl.files;
        if (!FileList.length) {
          isFileDialogActive = false;
          onFileDialogCancel();
        }
      }, 300);
    }
  };

  setRef = (ref) => {
    this.node = ref;
  };

  fileAccepted = (file: File) => {
    const { accept, onInvalidFormat } = this.props;
    if (!accepts(file, accept)) {
      if (onInvalidFormat) {
        onInvalidFormat(file.name);
      }
      return false;
    }
    return true;
  };

  fileMatchSize = (file) => {
    const { maxSize, minSize, onExceedSize, onInsufficientSize } = this.props;
    if (file.size > maxSize) {
      if (onExceedSize) {
        onExceedSize(file.name);
      }
      return false;
    }
    if (file.size < minSize) {
      if (onInsufficientSize) {
        onInsufficientSize(file.name);
      }
      return false;
    }
    return true;
  };

  allFilesAccepted = files => files.every(this.fileAccepted);

  open = () => {
    this.isFileDialogActive = true;
    this.fileInputEl.value = null;
    this.fileInputEl.click();
  };

  render() {
    const {
      id,
      accept,
      multiple,
      label,
      style,
      isError,
      errorText,
      labelStyle,
      comment,
      children,
      className,
      classNameLabel,
    } = this.props;
    const commentElements = [];
    const { isDragActive, isDragReject } = this.state;

    if (comment) {
      comment.split('\n').forEach((el, i) => {
        commentElements.push(el);
        commentElements.push(<br key={i} />);
      });
    }

    return (
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events
        style={style}
        className={className}
        onClick={this.onClick}
        onDragStart={this.onDragStart}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        ref={this.setRef}
      >
        {label
          && <label htmlFor={id} className={cn(styles.label, classNameLabel)} style={labelStyle}>
            {label}
          </label>}
        {PlainFile.renderChildren(children, isDragActive, isDragReject)}
        <input
          id={id}
          accept={accept}
          type="file"
          style={{ display: 'none' }}
          multiple={supportMultiple && multiple}
          ref={(c) => { this.fileInputEl = c; }}
          onChange={this.onDrop}
        />
        {isError && <div className={styles.errorText}>{errorText}</div>}
        {comment && <div className={styles.comment}>{commentElements}</div>}
      </div>
    );
  }
}

// PlainFile.propTypes = propTypes;
PlainFile.defaultProps = defaultProps;
export default PlainFile;
