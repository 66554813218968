// @flow
import * as subsidyTypesAvailable from 'app/actions/reports/filters/subsidyTypesAvailable';

import type { SummaryType } from './enhanceSummary';


const getSummaryParams = ({
  count,
  count_common: countCommon,
  count_personal: countPersonal,

  students_count: studentsCount,
  students_common_count: studentsCommonCount,
  students_personal_count: studentsPersonalCount,

  total,
  total_common: totalCommon,
  total_personal: totalPersonal,
}: SummaryType, subsidyType: $Values<typeof subsidyTypesAvailable>) => {
  switch (subsidyType) {
    case subsidyTypesAvailable.SUBSIDY_TYPE_COMMON: {
      return [countCommon, studentsCommonCount, totalCommon];
    }
    case subsidyTypesAvailable.SUBSIDY_TYPE_PERSONAL: {
      return [countPersonal, studentsPersonalCount, totalPersonal];
    }
    default: {
      return [count, studentsCount, total];
    }
  }
};

export default getSummaryParams;
