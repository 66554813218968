import { ChangeEvent, useCallback, useState } from 'react'
import { compose, isEmpty } from 'ramda'

import { Header2, PageTemplateContent, SkeletonLoading } from 'app/common/templates-next/page-template'
import { Tabs, Tab } from 'app/common/ui/PlainTabs'
import PlainButton from 'app/common/ui-next/plain-button'
import ControlsBar from 'app/common/ui-next/controls-bar'
import { AgreementDocumentKind, AgreementDocumentListType } from 'app/dataTypes/agreement/documents'
import usePopup from 'app/common/hooks/usePopup'

import { useAgreementDocuments, useAgreementDocumentsActions } from 'app/dataProviders/agreement/documents/hooks'
import { AddFiles } from './AddFiles'
import { useDocumentsState } from './useDocumentsState'
import { CurrentFile } from './CurrentFile'

import styles from './Documents.scss'


enum TabIndex {
  Offer = 1,
  Agreement = 2
}

const getKindByTabIndex = (ti: TabIndex) => {
  if (ti === TabIndex.Offer) return AgreementDocumentKind.PublicOffer
  if (ti === TabIndex.Agreement) return AgreementDocumentKind.PersonalDataAgreement
  return null as never
}

const getCurrentFileByTabIndex = (ti: TabIndex, currentFiles: AgreementDocumentListType) => {
  const file = currentFiles.find(({ kind }) => (
    getKindByTabIndex(ti) === kind
  ))
  return file || null
}

const formID = 'uploadPDFFile'

export const Documents = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(TabIndex.Offer)
  const [saving, setSaving] = useState(false)
  const { files, reset, addFiles, removeFile } = useDocumentsState()

  const popup = usePopup()
  const successMessageAdd = () => popup('Файл успешно добавлен')
  const successMessageDelete = () => popup('Файл успешно удален')

  const { data: currentFiles, loading, setData } = useAgreementDocuments()
  const { submitDocument, deleteDocument } = useAgreementDocumentsActions({
    onSubmitSuccess: compose(reset, successMessageAdd),
    onDeleteSuccess: compose(reset, successMessageDelete),
    setData,
  })
  const currentFile = getCurrentFileByTabIndex(activeTabIndex, currentFiles)

  const submit = useCallback(async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { id } = e.currentTarget
    if (id !== formID) return

    const [{ id: attachmentId }] = files
    setSaving(true)
    await submitDocument({
      ID: currentFile ? currentFile.ID : null,
      kind: getKindByTabIndex(activeTabIndex),
      attachmentFileID: String(attachmentId),
    })
    setSaving(false)
  }, [files, activeTabIndex, submitDocument, currentFile])

  const hasFileInState = !isEmpty(files)

  return (
    <>
      <PageTemplateContent>
        <Header2 skipMargin>{'Документы'}</Header2>
      </PageTemplateContent>

      <PageTemplateContent>
        <form onSubmit={submit} id={formID}>
          <Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
            <Tab index={TabIndex.Offer}>{'Оферта для вывода компенсации'}</Tab>
            <Tab index={TabIndex.Agreement}>{'Согласие на обработку ПДн'}</Tab>
          </Tabs>

          {loading && <SkeletonLoading className={styles.loading} rows={1} />}

          {!loading
            && currentFile
            && currentFile.pdfFile
            && <CurrentFile
              ID={currentFile.ID}
              fileName={currentFile.pdfFile.name}
              onDelete={deleteDocument}
            />}

          <AddFiles
            label={currentFile ? 'Заменить на:' : 'Добавить файл:'}
            disabled={saving}
            onAddFiles={addFiles}
            onRemoveFile={removeFile}
            files={files}
          />

          {hasFileInState
            && <ControlsBar right>
              <PlainButton onClick={reset} outline>
                {'Отмена'}
              </PlainButton>
              <PlainButton type="submit" disabled={saving || loading}>
                {'Сохранить'}
              </PlainButton>
            </ControlsBar>}
        </form>
      </PageTemplateContent>
    </>
  )
}
