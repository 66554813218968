// @flow
import React, { Fragment, useRef, useCallback, useState, useMemo, useEffect } from 'react';
import cn from 'classnames';

import PlainToggle from 'app/common/ui-next/plain-toggle';
import * as settings from 'app/common/constants/schools/settings';
import { DotsLoader } from 'app/common/components/Loader';
import * as orgsTypes from 'app/common/constants/schools/types';
import RowLayout, { Header, HeaderContent, RowLayoutContent } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import Prompt from 'app/components/common/Prompt';
import SaveCancelButtons from 'app/components/common/SaveCancelButtons';
import { useWriteAccess } from 'app/rights/RightsForOrg';
import { useLastChange } from 'app/dataProviders/OrgSettingsHistoryProvider';
import { useGeneralSettings as useGeneralSettingsGlobal } from 'app/dataProviders/generalSettings/hooks'

import MultipleSubmitSettings from '../MultipleSubmitSettings';
import OptionsSet from './OptionsSet';
import useGeneralSettings from './useGeneralSettings';
import { rightsPath } from '../rightsPath';
import HistoryLink from '../HistoryLink';
import sections, { sectionTitles } from '../sections';
import MultipleModalAdditionalContent from './MultipleModalAdditionalContent';

import styles from './General.scss';


const currentSection = sections.ORGS_SETTINGS_GENERAL;
const header = sectionTitles[currentSection];

// set0 Продажи
const set0 = [
  settings.USE_PIN,
  settings.ALLOW_CASH_SALES,
  settings.ALLOW_BANK_CARD_SALES,
  settings.ALLOW_GUESS_SALES,
];

// set1 Комплексное питание
const set1Learning = [
  settings.FREE_CAFE_ORDER_DAY,
  settings.ORDER_TARGETS_ENABLED,
];

const set1Enterprise = [
  settings.ORDER_TARGETS_ENABLED,
];

const set1Schools = [
  ...set1Learning,
  settings.ALLOW_STUDENTS_TO_PARTICIPATE_IN_ORDERS,
  settings.ALLOW_PARENTS_TO_PARTICIPATE_IN_ORDERS,
];

const getSet1ByOrgType = (orgType: $Values<typeof orgsTypes>) => {
  if (orgType === orgsTypes.ENTERPRISE) {
    return set1Enterprise;
  }
  if (orgType === orgsTypes.SCHOOL) {
    return set1Schools;
  }
  return set1Learning;
};

// set2 Дотации
const set2 = [
  settings.ENABLE_SUBSIDIES_FOR_ORDERS,
  settings.ENABLE_SUBSIDIES_FOR_SINGLE_SALES,
];

/**
 *  Форма "Общие настройки"
 */

type Props = {
  orgId: string,
  orgType: $Values<typeof orgsTypes>,
}

const General = ({ orgId, orgType }: Props) => {
  const {
    loading: loadingGeneralSettingsGlobal,
    generalSettings: {
      eMoneyEnabled,
    },
  } = useGeneralSettingsGlobal();

  const [, updateLastChange] = useLastChange();

  const [{
    data: dataOriginal,
    loading: loadingGeneralSettings,
    modified,
  }, {
    submit,
    request,
  }, change] = useGeneralSettings(orgId);

  const data = useMemo(() => {
    if (eMoneyEnabled) {
      return ({
        ...dataOriginal,
        [`enabled_${settings.ALLOW_BANK_CARD_SALES}`]: false,
      });
    }
    return dataOriginal;
  }, [dataOriginal, eMoneyEnabled]);

  const formRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);

  const handleSave = useCallback((/* e: SyntheticEvent<HTMLButtonElement> */) => {
    if (formRef.current) {
      formRef.current.submit.click();
    }
  }, []);

  const writeAccess = useWriteAccess(rightsPath);
  const loading = loadingGeneralSettings || loadingGeneralSettingsGlobal;

  return (
    <RowLayout paddingfree>

      <CSSSticky
        number={3}
        className={styles.stickable}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
      >
        <HeaderContent
          className={cn(styles.topBorderOnSticky, styles.headerContent)}
          borderBottom
        >
          <div>
            <Header>{header}</Header>
            <HistoryLink sectionTitle={header} />
          </div>
          {modified
            && <SaveCancelButtons
              onSave={handleSave}
              onCancel={request}
              disabled={loading || submitting}
              saving={submitting}
              cancelling={modified && loading}
            />}
        </HeaderContent>
      </CSSSticky>

      <RowLayoutContent>
        <MultipleSubmitSettings
          additionalContent={<MultipleModalAdditionalContent />}
          onSubmitArray={submit}
          onChangeSubmitState={setSubmitting}
          onSuccess={updateLastChange}
          formId="general_settings_form"
        >
          {(toggleMultipleSubmitModal, multipleSubmit) => (
            <form onSubmit={multipleSubmit} ref={formRef} id="general_settings_form">
              {(loading || data[settings.CAFETERIA_SETTINGS_READONLY_FOR_SCHOOLS] === undefined)
                ? <DotsLoader simple />
                : <PlainToggle
                  checked={data[settings.CAFETERIA_SETTINGS_READONLY_FOR_SCHOOLS]}
                  prefix=""
                  suffix="Разрешить редактирование настроек в других кабинетах"
                  suffixClassName={styles.toggleLabel}
                  value={settings.CAFETERIA_SETTINGS_READONLY_FOR_SCHOOLS}
                  onChange={change.checkbox}
                  disabled={!writeAccess}
                  coloredReverse
                />}

              <div className={styles.items}>
                <div className={styles.label}>{'Продажи:'}</div>
                <OptionsSet
                  set={set0}
                  disabled={!writeAccess || loading || submitting}
                  data={data}
                  onChange={change.checkbox}
                />
                {orgType !== orgsTypes.ENTERPRISE
                  && <Fragment>
                    <div className={styles.label}>{'Комплексное питание:'}</div>
                    <OptionsSet
                      set={getSet1ByOrgType(orgType)}
                      disabled={!writeAccess || loading || submitting}
                      data={data}
                      onChange={change.checkbox}
                    />
                    <div className={styles.label}>{'Дотации:'}</div>
                    <OptionsSet
                      set={set2}
                      disabled={!writeAccess || loading || submitting}
                      data={data}
                      onChange={change.checkbox}
                    />
                  </Fragment>}
              </div>

              <button type="submit" name="submit" className={styles.hidden}>
                {'Сохранить'}
              </button>
            </form>
          )}
        </MultipleSubmitSettings>

        <Prompt
          when={modified}
          message="Вы не сохранили изменения, перейти в любом случае?"
        />
      </RowLayoutContent>
    </RowLayout>
  );
};

export default General;
