import {
  ORGS_DEBTS_SUCCESS,
  ORGS_DEBTS_CLEAR,
} from 'app/actions/orgs/debts';


const defaultState = {
};

const debts = (state = defaultState, action) => {
  switch (action.type) {
    case ORGS_DEBTS_SUCCESS: {
      return { ...state, ...action.payload };
    }
    case ORGS_DEBTS_CLEAR: {
      return defaultState;
    }
    default:
  }
  return state;
};

export default debts;
