// @flow
import { useMemo } from 'react';
import { reduce } from 'ramda';

import type { Categories, ParamsPUT } from 'app/dataTypes/vending/production/Categories.types';


const getWrongIndexes = (categories: Categories): Map<string, ParamsPUT> => {
  let i = 0;
  return reduce((acc, { id, index }) => {
    if (i > 0 && index !== i) {
      acc.set(id, { categoryId: id, index: i });
    }
    i += 1;
    return acc;
  }, new Map<string, ParamsPUT>(), categories);
};

export default function useWrongCategoryIndexes(categories: Categories): Map<string, ParamsPUT> {
  const res = useMemo(() => {
    return getWrongIndexes(categories);
  }, [categories]); // eslint-disable-line react-hooks/exhaustive-deps

  return res;
}
