// @flow
import map from 'ramda/es/map';
import addIndex from 'ramda/es/addIndex';

import type { TimeRestrictions, TimeRestrictionsStrict } from 'app/dataTypes/OrgSettingsTimeRestrictions.types';
import { BEFORE } from 'app/dataTypes/dayModes';


const defaultRestrictions: TimeRestrictions = [[{
  time: new Date(2000, 0, 1, 8), // 08:00
  dayMode: BEFORE,
}, {
  time: new Date(2000, 0, 1, 11), // 11:00
  dayMode: BEFORE,
}, {
  time: new Date(2000, 0, 1, 13), // 13:00
  dayMode: BEFORE,
}], [{ // 2-я смена
  time: new Date(2000, 0, 1, 12), // 12:00
  dayMode: BEFORE,
}, {
  time: new Date(2000, 0, 1, 15), // 15:00
  dayMode: BEFORE,
}, {
  time: new Date(2000, 0, 1, 17), // 17:00
  dayMode: BEFORE,
}], [{ // 3-я смена
  time: new Date(2000, 0, 1, 12), // 12:00
  dayMode: BEFORE,
}, {
  time: new Date(2000, 0, 1, 12), // 12:00
  dayMode: BEFORE,
}, {
  time: new Date(2000, 0, 1, 12), // 12:00
  dayMode: BEFORE,
}]];

const mapx = addIndex(map);

export const setNullsToDefault = (groups: TimeRestrictions): TimeRestrictionsStrict => (
  mapx(
    (foodModes, gIndex) => mapx(
      ({ time, dayMode }, fIndex) => (time ? { time, dayMode } : defaultRestrictions[gIndex][fIndex]),
      foodModes,
    ),
    groups,
  )
);
