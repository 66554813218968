import { useCallback, ReactNode, MouseEvent } from 'react'
import classNames from 'classnames/bind'

import Link from 'app/common/lib/link'

import styles from './menu-item.scss'


const cx = classNames.bind(styles)

type Props = {
  children: ReactNode
  to: string
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  active?: boolean
  disabled?: boolean
}

const MenuItem = ({ children, to, onClick, active, disabled }: Props) => {
  const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      e.preventDefault()
      return
    }
    if (onClick) {
      onClick(e)
    }
  }, [onClick, disabled])

  return (
    <li className={styles.root}>
      <Link
        to={to}
        onClick={handleClick}
        className={cx(styles.link, { active, disabled })}
      >
        {children}
      </Link>
    </li>
  )
}

export default MenuItem
