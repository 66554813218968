// @flow
import React, { Fragment, useRef } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import { Prompt } from 'react-router-dom';

import RowLayout, { Header } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import Loading from 'app/common/components/Loader';

import JobPlacesSubmitBar from './JobPlacesSubmitBar';
import useAtWorkJobPlaces from './useAtWorkJobPlaces';
import JobPlacesAtWork from './JobPlacesAtWork';
import JobPlacesOffice from './JobPlacesOffice';
import useSubmitOfficeEmployee from './useSubmitOfficeEmployee';
import useRoles from './useRoles';
import useEmployeeData from './useEmployeeData';
import * as employeeTypes from '../../employeeTypes';

import styles from './SectionJobPlaces.scss';


type Props = {
  title: string,
  personId: number,
  locked: boolean,
  baseUrl: string,
}

const SectionJobPlaces = ({
  title,
  personId,
  locked,
}: Props) => {
  const formRef = useRef(null);

  const [
    {
      loading: loadingAtWork,
      saving: savingAtWork,
      formModified: atWorkFormModified,
      places,
    },
    atWorkSubmit,
  ] = useAtWorkJobPlaces(personId); // TODO сделать как office, без redux
  const officeEmployee = isEmpty(places);

  const [{
    [employeeTypes.OFFICE]: officeRoles,
    [employeeTypes.ATWORK]: atworkRoles,
  }, loadingRoles] = useRoles();

  const {
    loading: loadingEmployee,
    modified: officeModified,
    resetModified,
    selectedRoleId,
    changeSelectedRoleId,
  } = useEmployeeData(personId);

  const [savingOffice, officeSubmit] = useSubmitOfficeEmployee({
    personId,
    roleId: selectedRoleId,
    onSuccess: resetModified,
  });

  const loading = loadingAtWork || loadingRoles;
  const saving = savingAtWork || savingOffice;
  const modified = atWorkFormModified || officeModified;

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Fragment>
      <CSSSticky
        number={3}
        stickyClassName={styles.sticky}
        shadowClassName={styles.shadow}
      >
        <RowLayout first flex>
          <Header>{title}</Header>
          {!locked
            && <JobPlacesSubmitBar
              formRef={formRef}
              disabled={loading || saving || !modified}
            >
              {saving ? 'Сохранение...' : 'Сохранить'}
            </JobPlacesSubmitBar>}
        </RowLayout>
      </CSSSticky>

      <RowLayout className={styles.main}>
        {officeEmployee

          ? <JobPlacesOffice
            formRef={formRef}
            roles={officeRoles}
            currentRoleId={selectedRoleId}
            onChangeRole={changeSelectedRoleId}
            onSubmit={officeSubmit}
            locked={locked || savingOffice || loadingEmployee}
          />

          : <JobPlacesAtWork
            formRef={formRef}
            places={places}
            roles={atworkRoles}
            personId={personId}
            locked={locked || savingAtWork || loadingAtWork}
            submitDisabled={!atWorkFormModified}
            onSubmit={atWorkSubmit}
          />}
      </RowLayout>

      <Prompt
        when={modified}
        message="Вы не сохранили изменения, перейти в любом случае?"
      />
    </Fragment>
  );
};

export default SectionJobPlaces;
