// @flow
import { useState, useMemo } from 'react';

import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';


const defaultState: MachineSingle = {
  id: 0,
  title: '',
  number: '',
  modelId: 0,
  versionId: 0,
  orgId: 0,
  placeId: '',
};

export type VendingMachineSingle = [
  MachineSingle,
  ((MachineSingle => MachineSingle) | MachineSingle) => void,
  () => void
]
export const defaultVendingMachineSingle: VendingMachineSingle = [defaultState, () => {}, () => {}];

export default function useVendingMachineSingle(): VendingMachineSingle {
  const [state, setState] = useState(defaultState);

  const memoized = useMemo(() => {
    const clear = () => {
      setState(defaultState);
    };

    return [state, setState, clear];
  }, [state, setState]);

  return memoized;
}
