import { ReactNode } from 'react'
import classNames from 'classnames/bind'

import isProduction from 'app/common/lib/isProduction'

import styles from './plain-fieldset.scss'


const cx = classNames.bind(styles)

export type FieldsetProps = {
  children: ReactNode
  className?: string
  legend?: ReactNode
  name?: string
  ariaLabelledby?: string
  legendClassName?: string
  skipUpperSpace?: boolean
  strong?: boolean
}

const PlainFieldset = ({
  children,
  className,
  legend,
  name,
  ariaLabelledby,
  legendClassName,
  skipUpperSpace,
  strong,
}: FieldsetProps) => {
  if (!isProduction() && !legend && !ariaLabelledby) {
    console.error('Не указан один из обязательных параметров `legend` или `ariaLabelledby`')
  }

  return (
    <fieldset
      className={cx(styles.root, { skipUpperSpace }, className)}
      name={name}
      aria-labelledby={ariaLabelledby}
    >
      {legend
        && <legend className={cx(styles.legend, { strong }, legendClassName)}>
          {legend}
        </legend>}
      {children}
    </fieldset>
  )
}

export default PlainFieldset
