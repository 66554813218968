// @flow
import React, { useCallback, useState, useRef } from 'react';
import append from 'ramda/es/append';
import isEmpty from 'ramda/es/isEmpty';
import classNames from 'classnames/bind';

import PlainFile from 'app/common/ui/PlainFile';
import ProgressBar from 'app/common/ui/ProgressBar';
import config from 'app/config';
import OverflowedText from 'app/common/components/OveflowedText';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import CrossButton from './CrossButton';
import { useImportXLSData } from '../ImportXLSData';

import styles from './AddFiles.scss';

/**
 *  Обертка над PlainFile для загрузки XLS-файла
 */

const supportedFiles = 'XLS, XLSX';
const supportedAttachments = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const cx = classNames.bind(styles);

type Props = {
  disabled?: boolean,
  className?: string
}

const AddFiles = ({ disabled, className }: Props) => {
  const { content: { fileName }, api, modify } = useImportXLSData();
  // TODO disabled while submitting
  const [progress, setProgress] = useState<number>(0);
  const [loadingFileName, setLoadingFileName] = useState<string>('');
  const [exceedFiles, setExceedFiles] = useState<Array<string>>([]);
  const [invalidFiles, setInvalidFiles] = useState<Array<string>>([]);
  const dropzone = useRef(null);


  const handleProgress = useCallback(({ loaded, total }) => {
    setProgress(Math.round((loaded * 100) / total));
  }, []);

  const handleOpenClick = useCallback(() => {
    if (dropzone.current) {
      dropzone.current.open();
    }
  }, []);

  const handleAddFile = useCallback(async (acceptedFiles = []) => {
    if (acceptedFiles.length) {
      // нужен только один файл
      const actualFile = acceptedFiles[0];
      setLoadingFileName(actualFile.name);
      await api.requestFileData(actualFile, handleProgress);
      setLoadingFileName('');
    }
  }, [handleProgress, api]);


  const handleAddInvalidFormatFile = useCallback((name: string) => {
    setInvalidFiles(append(name));
  }, []);

  const handleClearInvalidFormat = useCallback(() => {
    setInvalidFiles([]);
  }, []);

  const handleAddExceedSizeFile = useCallback((name: string) => {
    setExceedFiles(append(name));
  }, []);

  const handleClearExceedSize = useCallback(() => {
    setExceedFiles([]);
  }, []);

  const dropBlocked = Boolean(fileName) || disabled || Boolean(loadingFileName);
  const showCrossWhileBlocked = Boolean(fileName) && !disabled && !loadingFileName;


  return (
    <PlainFile
      id="files"
      label="Файл"
      className={className}
      comment={`Поддерживаемые форматы: ${supportedFiles};\nМаксимальный \
      размер файла: ${Math.round(config.maxFileSize / 1050578)} Мб.`}
      ref={dropzone}
      accept={supportedAttachments.join(', ')}
      maxSize={config.maxFileSize}
      onDrop={handleAddFile}
      disablePreview
      disabled={disabled}
      onInvalidFormat={handleAddInvalidFormatFile}
      onExceedSize={handleAddExceedSizeFile}
    >
      {dragState => (
        <div>

          {loadingFileName
            && <div className={styles.loading}>
              <ProgressBar progress={progress} />
              <OverflowedText className={styles.loadingFileName}>
                {loadingFileName}
              </OverflowedText>
            </div>}

          {!isEmpty(exceedFiles)
            && <div className={styles.unAccepted}>
              {`Файл ${exceedFiles[0]} не принят. Превышен максимальный размер файла`}
              <CrossButton onCrossClick={handleClearExceedSize} />
            </div>}

          {!isEmpty(invalidFiles)
            && <div className={styles.unAccepted}>
              {`Файл ${invalidFiles[0]} не принят. Неверный формат файла`}
              <CrossButton onCrossClick={handleClearInvalidFormat} />
            </div>}

          {dropBlocked
            ? <div className={cx(styles.dropArea, { blocked: dropBlocked })}>
              <div>
                {fileName}
                {showCrossWhileBlocked
                  && <CrossButton onCrossClick={modify.reset} />}
              </div>
            </div>
            : <div className={cx(styles.dropArea, { ...dragState })}>
              <div>
                <PlainButtonAsLink
                  onClick={handleOpenClick}
                  ariaLabel="Выберите файл"
                >
                  {'Выберите файл'}
                </PlainButtonAsLink>
                <span>{' на компьютере или перетащите его в это поле'}</span>
              </div>
            </div>}

        </div>
      )}
    </PlainFile>
  );
};

export default AddFiles;
