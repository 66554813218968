// @flow
import { useState, useCallback } from 'react';
import F from 'ramda/es/F';

import { api } from 'app/api';
import { PUT_VENDING_MACHINES } from 'app/common/api/requests/data/vending/machines/put';

import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';


export type MachineDelete = [boolean, () => boolean | Promise<boolean>]
export const defaultMachineDelete: MachineDelete = [false, F];

export default function useMachineDelete(machine: MachineSingle): MachineDelete {
  const { id } = machine;
  const [deleting, setDeleting] = useState(false);

  const deleteMachine = useCallback(async () => {
    const { title, number, modelId, orgId, versionId } = machine;
    setDeleting(true);

    const res = await api.request(PUT_VENDING_MACHINES, {
      error: `Не удалось удалить аппарат «${title}»`,
      params: {
        title,
        number,
        model_id: modelId,
        school_id: orgId,
        version_id: versionId,
        deleted: true,
      },
      requestPathParams: {
        machineId: id,
      },
    });

    setDeleting(false);
    return !!res;
  }, [machine, id]);

  return [deleting, deleteMachine];
}
