// @flow
import React from 'react';

import Tooltip from 'app/common/ui/Tooltip';
import IconIndividual from 'app/common/icons/individual.svg';
import IconCollective from 'app/common/icons/collective.svg';

import styles from './Individual.scss';


type Props = {
  individual: boolean,
}

const Individual = ({ individual }: Props) => {
  return (individual
    ? <Tooltip text="Индивидуальная покупка">
      <IconIndividual className={styles.icon} />
    </Tooltip>
    : <Tooltip text="Коллективная покупка">
      <IconCollective className={styles.icon} />
    </Tooltip>
  );
};

export default Individual;
