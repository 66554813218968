import { all, difference, equals, isEmpty } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'


type Options = {
  type: 'orgs' | 'orgGroups'
  getLinkedOrgs: (orgGroupID: string) => Array<string> | undefined
  orgs: Map<string, { ID: string }>
  orgGroups: Map<string, { ID: string }>
  externalState: Array<string>
}

export default function useSelection({
  type,
  getLinkedOrgs,
  orgs,
  orgGroups,
  externalState = [],
}: Options) {
  const [orgsSelection, setOrgsSelection] = useState(new Set<string>())
  const [orgGroupsSelection, setOrgGroupsSelection] = useState(new Set<string>())

  useEffect(() => {
    if (isEmpty(externalState)) {
      setOrgsSelection(new Set<string>())
      setOrgGroupsSelection(new Set<string>())
      return
    }
    setOrgsSelection((previosState) => {
      const notChanged = (
        isEmpty(difference(Array.from(previosState.values()), externalState))
      )
      return notChanged ? previosState : new Set(externalState)
    })
  }, [externalState])

  const selectedAll = useMemo(() => {
    return (
      type === 'orgs'
        ? orgsSelection.size === orgs.size
        : orgGroupsSelection.size === orgGroups.size
    )
  }, [type, orgs.size, orgGroups.size, orgsSelection.size, orgGroupsSelection.size])

  const toggleOrg = useCallback((ID: string) => {
    setOrgsSelection((prevState) => {
      const nextState = new Set(prevState)
      if (prevState.has(ID)) {
        nextState.delete(ID)
      } else {
        nextState.add(ID)
      }
      return nextState
    })
  }, [])

  const toggleOrgsGroup = useCallback((ID: string) => {
    setOrgGroupsSelection((prevState) => {
      const nextState = new Set(prevState)
      if (prevState.has(ID)) {
        nextState.delete(ID)
      } else {
        nextState.add(ID)
      }
      return nextState
    })
  }, [])

  const toggleItem = useCallback((ID: string) => {
    if (type === 'orgGroups') {
      toggleOrgsGroup(ID)
      return
    }
    toggleOrg(ID)
  }, [toggleOrg, toggleOrgsGroup, type])

  const toggleAll = useCallback(() => {
    if (type === 'orgs') {
      setOrgsSelection(selectedAll ? new Set<string>() : new Set(Array.from(orgs.keys())))
    }
    if (type === 'orgGroups') {
      setOrgGroupsSelection(selectedAll ? new Set<string>() : new Set(Array.from(orgGroups.keys())))
    }
  }, [selectedAll, orgs, orgGroups, type])

  const getRelativeOrgsSelection = useCallback(() => {
    const relativeOrgsSelection = new Set<string>()
    for (const orgGroupID of orgGroupsSelection) {
      const linkedOrgIDs = getLinkedOrgs(orgGroupID)
      if (linkedOrgIDs) linkedOrgIDs.forEach(orgID => relativeOrgsSelection.add(orgID))
    }
    return relativeOrgsSelection
  }, [getLinkedOrgs, orgGroupsSelection])

  const getRelativeOrgGroupsSelection = useCallback(() => {
    const relativeOrgGroupsSelection = new Set<string>()
    for (const orgGroup of orgGroups) {
      const [, { ID: orgGroupID }] = orgGroup
      const linkedOrgIDs = getLinkedOrgs(orgGroupID)
      if (linkedOrgIDs) {
        const allRelativeOrgsSelected = all(
          orgID => orgsSelection.has(orgID),
          linkedOrgIDs
        )
        if (allRelativeOrgsSelected) relativeOrgGroupsSelection.add(orgGroupID)
      }
    }
    return relativeOrgGroupsSelection
  }, [getLinkedOrgs, orgGroups, orgsSelection])

  useEffect(() => {
    if (type === 'orgGroups') {
      const nextOrgsSelection = getRelativeOrgsSelection()
      if (!equals(nextOrgsSelection, orgsSelection)) {
        setOrgsSelection(nextOrgsSelection)
      }
    }
    if (type === 'orgs') {
      const nextOrgGroupsSelection = getRelativeOrgGroupsSelection()
      if (!equals(nextOrgGroupsSelection, orgGroupsSelection)) {
        setOrgGroupsSelection(nextOrgGroupsSelection)
      }
    }
  }, [type, orgsSelection, orgGroupsSelection])

  return {
    orgsSelection,
    selection: type === 'orgGroups' ? orgGroupsSelection : orgsSelection,
    toggleItem,
    selectedAll,
    toggleAll,
  }
}
