// @flow
import React, { Fragment, useState, useCallback, useMemo } from 'react';

import Dropdown from 'app/common/ui/Dropdown';
import i18n, { changeLanguage, availableLanguages, type Lang } from 'app/i18n';
import { popupErr } from 'app/common/actions/popup';

import LangItem, { type LangItemProps } from './LangItem';
import Flag from './flags/Flag';

import styles from './LangSwitcher.scss';


const langs: LangItemProps[] = availableLanguages.map(key => ({
  key,
  id: key,
}));

const defaultOptions = {
  dx: 7,
  dy: 10,
};

type Props = {
  className?: string,
}

const LangSwitcher = ({ className }: Props) => {
  const [isOpen, setOpenState] = useState(false);
  const toggle = useCallback(() => {
    setOpenState(state => !state);
  }, []);

  const [activeId, setActiveId] = useState(i18n.language);

  const [activeItemProps, items] = useMemo(() => ([
    langs.find(({ id }) => (id === activeId)),
    langs.filter(({ id }) => (id !== activeId)),
  ]), [activeId]);

  const handleChangeLng = useCallback(async (lng: Lang) => {
    try {
      await changeLanguage(lng);
      setActiveId(lng);
    } catch (error) {
      console.error(error);
      popupErr(i18n.t('ui:lang_change_error'));
    }
    toggle();
  }, [toggle]);

  return (
    <div className={className}>
      <div className={styles.selector}>
        <div className={styles.label}>
          {i18n.t('ui:lang_change_label')}
          {':'}
        </div>
        <Dropdown
          isOpen={isOpen}
          toggle={toggle}
          options={defaultOptions}
          contentClassName={styles.content}
          content={<Fragment>
            <LangItem {...activeItemProps} onClick={toggle} active />
            {items.map(props => (
              // $FlowFixMe
              <LangItem {...props} onClick={handleChangeLng} />
            ))}
          </Fragment>}
        >
          <Flag lng={activeId} />
        </Dropdown>
      </div>
    </div>
  );
};

export default LangSwitcher;
