import { useCallback } from 'react'
import classNames from 'classnames/bind'

import styles from './days-grid-item.scss'


const cx = classNames.bind(styles)

type Props = {
  day: number,
  dateTimeFormatted: string,
  today: boolean,
  holyday: boolean,
  selected: boolean,
  outrange: boolean,
  disabled: boolean,
  dateFormat: string,
  mark?: boolean,
  onClick: (dayFarmatted: string, format: string) => void,
}

const DaysGridItem = ({
  day,
  dateTimeFormatted,
  today,
  holyday,
  selected,
  outrange,
  disabled,
  dateFormat,
  mark,
  onClick,
}: Props) => {
  const handleDayClick = useCallback(() => {
    onClick(dateTimeFormatted, dateFormat)
  }, [onClick, dateTimeFormatted, dateFormat])

  return (
    <button
      type="button"
      onClick={handleDayClick}
      disabled={disabled}
      className={cx(styles.root, {
        today,
        selected,
        holyday,
        outrange,
        disabled,
        mark,
      })}
    >
      <time dateTime={dateTimeFormatted}>{day}</time>
    </button>
  )
}

export default DaysGridItem
