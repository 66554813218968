// @flow

export default function lightenDarkenColor(col: string, amt: number): string {
  const num = parseInt(col.slice(1), 16);
  const r = (num >> 16) + amt; // eslint-disable-line no-bitwise
  const b = ((num >> 8) & 0x00FF) + amt; // eslint-disable-line no-bitwise
  const g = (num & 0x0000FF) + amt; // eslint-disable-line no-bitwise
  const newColor = g | (b << 8) | (r << 16); // eslint-disable-line no-bitwise

  return `#${newColor.toString(16)}`;
}
