import { ReactNode } from 'react'

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal'

import styles from './CompensationDetailsModal.scss'


type Props = {
  title: string
  children: (cb: HandleModalDisplayed) => ReactNode
  compensationDetailsTable: ReactNode
  compensationDetailsTableFooter: ReactNode
}

export const CompensationDetailsModal = ({
  title,
  children,
  compensationDetailsTable,
  compensationDetailsTableFooter,
}: Props) => {
  return (
    <PlainModal
      className={styles.root}
      controlBy={children}
    >
      <PlainModalHeader>{title}</PlainModalHeader>
      <PlainModalContent paddingFree>
        {compensationDetailsTable}
      </PlainModalContent>
      <PlainModalFooter paddingFree>
        {compensationDetailsTableFooter}
      </PlainModalFooter>
    </PlainModal>
  )
}
