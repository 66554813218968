import { useCallback, useMemo, ReactNode } from 'react'
import moment from 'moment-timezone'
import cn from 'classnames/bind'

import styles from './plain-date-switcher.scss'


const defaultDateFormat = 'DD.MM.YYYY' as const
const cx = cn.bind(styles)
const simbolPrev = '❮'
const simbolNext = '❯'

export type PlainDateSwitcherProps = {
  className?: string
  date?: string
  dateFormat?: string
  displayDateFormat?: string
  stepType?: 'day' | 'month'
  onDateChange?: (date: string, format: string) => void
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean
  children?: ReactNode
}

export const PlainDateSwitcher = ({
  className,
  date,
  dateFormat = defaultDateFormat,
  displayDateFormat = defaultDateFormat,
  stepType = 'day',
  onDateChange,
  dateDisabledStatus,
  children,
}: PlainDateSwitcherProps) => {
  const displayDate = useMemo(() => (
    displayDateFormat === dateFormat
      ? date
      : moment(date, dateFormat).format(displayDateFormat)
  ), [date, dateFormat, displayDateFormat])

  const [prev, next] = useMemo(() => ([
    moment(date, dateFormat).subtract(1, stepType).format(dateFormat),
    moment(date, dateFormat).add(1, stepType).format(dateFormat),
  ]), [date, dateFormat, stepType])

  const handleClickPrev = useCallback(() => {
    if (onDateChange) {
      onDateChange(prev, dateFormat)
    }
  }, [onDateChange, prev, dateFormat])

  const handleClickNext = useCallback(() => {
    if (onDateChange) {
      onDateChange(next, dateFormat)
    }
  }, [onDateChange, next, dateFormat])

  const [prevEnabled, nextEnabled] = useMemo(() => {
    if (dateDisabledStatus) {
      return [
        !dateDisabledStatus(prev, dateFormat, stepType),
        !dateDisabledStatus(next, dateFormat, stepType),
      ]
    }
    return [true, true]
  }, [dateDisabledStatus, prev, next, dateFormat, stepType])

  return (
    <div className={cx(styles.root, className)}>
      {prevEnabled
        ? <button type="button" className={styles.button} onClick={handleClickPrev}>
          {simbolPrev}
        </button>
        : <span className={styles.fakeButton}>
          {simbolPrev}
        </span>}

      <div className={styles.date}>{displayDate}</div>

      {nextEnabled
        ? <button
          type="button"
          className={cx(styles.button, { hasChildren: children })}
          onClick={handleClickNext}
        >
          {simbolNext}
        </button>
        : <span className={cx(styles.fakeButton, { hasChildren: children })}>
          {simbolNext}
        </span>}

      {children}
    </div>
  )
}
