import React from 'react';
import classNames from 'classnames';

import ReactPageNav from './ReactPageNav';
import styles from './Paging.scss';


ReactPageNav.default.prevHtml = '<';
ReactPageNav.default.nextHtml = '>';

type Props = {
  className?: string,
  total?: number,
  pageSize?: number,
  currentPage?: number,
  currentPageSize?: number,
  onPage?: (e: SyntheticEvent<HTMLAnchorElement>) => void,
}

class Paging extends React.Component<Props> {
  props: Props;

  renderItem = (units) => {
    const { onPage } = this.props;
    return (
      <div className={styles.navItem}>
        {units.map(item => (
          <a
            href={item.html}
            className={styles[item.class]}
            name={item.page}
            onClick={onPage}
            key={item.html}
          >
            <span>{item.html}</span>
          </a>
        ))}
      </div>
    );
  };

  render() {
    // TODO стоит тут сделать memorize?
    const { currentPage, total, pageSize, className, currentPageSize } = this.props;
    const left = total - ((currentPage - 1) * pageSize);
    const begin = ((currentPage - 1) * pageSize) + 1 || false;
    const N = currentPageSize || (left > pageSize ? pageSize : left);
    return (
      <div className={classNames(styles.root, className)}>
        {begin && <div>
          {N === 1 && `Показанa одна запись (всего ${total})`}
          {N === 2 && `Показаны две записи (всего ${total})`}
          {N > 2 && `Показаны записи с ${begin} по ${(begin + N) - 1} (всего ${total})`}
        </div>}
        {N !== total
          && <ReactPageNav
            page={currentPage}
            total={total}
            pageSize={pageSize}
            maxLink={7}
            render={this.renderItem}
          />}
      </div>
    );
  }
}

export default Paging;
