// @flow
import React, { type Node, useState } from 'react';

import AddRemoveItemsModalTemplate from 'app/common/templates/AddRemoveItemsModalTemplate';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';

import VendingDishesEditListGear from '../../../_externalProduction/VendingDishesEditListGear';
import AvailableDishes from '../../../_externalProduction/AvailableDishes';
import type { Item } from '../../../_externalProduction/useAddRemoveState';
import useSubmit from './useSubmit';
import useAlreadyAdded from './useAlreadyAdded';

import styles from './AddRemoveProducts.scss';


type Props = {
  categoryId: string,
  placeId: string,
  children: (() => void) => Node,
  onSuccess: (category: Category) => void,
}

const AddRemoveProducts = ({ categoryId, placeId, children, onSuccess }: Props): Node => {
  const [search, setSearch] = useState('');
  const [willAddItems, setWillAddItems] = useState<Array<Item>>([]);
  const [willRemoveItems, setWillRemoveItems] = useState<Array<Item>>([]);
  const [loadedProductionIds, setLoadedProductionIds] = useState<Array<string>>([]);

  const [handleSubmit, submitting] = useSubmit(categoryId, willAddItems, willRemoveItems, onSuccess);

  const [alreadyAdded, disabledItems, alreadyAddedLoading] = useAlreadyAdded({
    placeId,
    categoryId,
    foodItemIds: loadedProductionIds,
  });

  const headerContent = (
    <VendingDishesEditListGear />
  );

  const mainContent = (
    <AvailableDishes
      alreadyAdded={alreadyAdded}
      disabledItems={disabledItems}
      search={search}
      locked={submitting || alreadyAddedLoading}
      onChangeItemsForAdd={setWillAddItems}
      onChangeItemsForRemove={setWillRemoveItems}
      onProductionLoaded={setLoadedProductionIds}
    />
  );

  return (
    <AddRemoveItemsModalTemplate
      headerContent={headerContent}
      mainContent={mainContent}
      mainContentClassName={styles.mainContent}
      onSearch={setSearch}
      onSubmit={handleSubmit}
      willAddItems={willAddItems}
      willRemoveItems={willRemoveItems}
    >
      {children}
    </AddRemoveItemsModalTemplate>
  );
};

export default AddRemoveProducts;
