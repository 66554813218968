import { DotsLoader } from 'app/common/components/Loader'
import PlainNav, { NavItem } from 'app/common/ui/PlainNav'
import Sticky from 'app/common/templates-next/sticky'

import useOrgsListNavigation, { type NavItemProps } from './useOrgsListNavigation'
import styles from './OrgListNavigation.scss'


type Props = {
  onUpdateItems: (items: Array<NavItemProps>) => void
}

const OrgsListNavigation = (props: Props) => {
  const [items, loading] = useOrgsListNavigation(props)
  const hide = items && items.length === 1

  if (hide) {
    return <span>&nbsp;</span>
  }

  return (
    <Sticky>
      {ref => (
        <div ref={ref} className={styles.hasBackground}>
          {loading
            && <span>
              <DotsLoader simple />
              &nbsp;
            </span>}

          <PlainNav>
            {items && items.map(item => (
              <NavItem {...item} key={item.key} />
            ))}
          </PlainNav>
        </div>
      )}
    </Sticky>
  )
}

export default OrgsListNavigation
