// @flow
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import fromPairs from 'ramda/es/fromPairs';

/**
 *  Функция трансформирующая массив со строками в объект DataObject подходящий для PlainFilterComplete
 *  Можно задать объект с переводом ключей для title
 */

type Translate = {
  [key: string]: string,
}

export type DataObject = {
  [key: string]: {
    id: string,
    title: string,
  }
}

const dataObjectCreate = (fromArray: Array<string>, translate?: Translate = {}): DataObject => pipe(
  map(customerName => [customerName, {
    id: customerName,
    title: translate[customerName] || customerName,
  }]),
  fromPairs,
)(fromArray);

export default dataObjectCreate;
