import { useReportPerform } from 'app/dataProviders/reports/hooks'
import { FinanceReportType } from 'app/dataTypes/reports/finances'
import React, { useCallback, useEffect } from 'react'
import { ReportSection, ResetLink } from 'app/common/templates-next/report-template'
import ReportFilterForm from 'app/common/templates-next/report-template/report-filter-form'
import ReportFilterLayout from 'app/common/templates-next/report-template/report-filter-layout'
import { ID } from 'app/common/ui-next/_filter-compilations/predefined-ids'
import { DetailsBox, detailsBoxID } from 'app/common/ui-next/_filter-compilations/details'
import { canMailingOnly, MailingBox } from 'app/common/ui-next/_filter-compilations/mailing'
import { useUser } from 'app/dataProviders/user/hooks'
import styles from './Filters.scss'
import { filterInit, resetToDefaultAll } from './filter-init'
import { PeriodFilter } from './filterItems'


const isMailingOnly = (formData: FormData) => !!canMailingOnly(formData)

type Props = {
  onReportID: (reportID: string | null) => void,
}

export const Filters = ({ onReportID }: Props) => {
  const { email, loading } = useUser()
  const { perform } = useReportPerform({
    onReportID,
    isMailingOnly,
  })

  const resetAll = () => {
    resetToDefaultAll()
  }

  const performWithAdditionalData = useCallback(async (formData: FormData) => {
    if (email) {
      formData.append(ID.email, email)
    }
    await perform({ formData, reportType: FinanceReportType.EmoneySales })
  }, [perform, email])

  useEffect(() => {
    return filterInit()
  }, [])

  return (
    <ReportSection
      header="Фильтры"
      headerBar={
        <ResetLink onClick={resetAll}>
          {'Сбросить все фильтры'}
        </ResetLink>
      }
      resetStickyShift={false}
      skipPadding
    >
      <div className={styles.formContent}>
        <ReportFilterForm
          onSubmit={performWithAdditionalData}
          submitButtonTitle="Показать"
        >
          {pending => (
            <ReportFilterLayout
              column1={
                <>
                  <PeriodFilter
                    id={ID.periodDateFormat}
                    pending={pending}
                  />
                  <DetailsBox
                    id={ID.details}
                    disabled={pending}
                  />
                </>
              }
              column2={
                <MailingBox
                  visibleFromBox={detailsBoxID}
                  email={email}
                  disabled={pending || loading}
                />
              }
            />
          )}
        </ReportFilterForm>
      </div>
    </ReportSection>
  )
}
