import React from 'react';

import Checkbox from 'app/common/ui/Checkbox';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';
import SettingsGear from 'app/common/components/Settings';
import { settingsKey } from 'app/reducers/settings/employeesAtWork';

import styles from './SettingsGear.scss';


const EmployeesGearAtWork = () => (<SettingsGear dataKey={settingsKey}>
  {({ saveSettings }) => (<div>
    <div className={styles.header}>{'Столбцы:'}</div>
    {/* Чекбоксы в настройках */}
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Фото"
      name="photo"
      saveSettings={saveSettings}
      className={styles.check}
      settingsKey={settingsKey}
    />
    {/* <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="ФИО, дата рождения"
          name="fio"
          saveSettings={saveSettings}
          className={styles.check}
          settingsKey={settingsKey}
        /> */}
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Место работы"
      name="jobPlace"
      saveSettings={saveSettings}
      className={styles.check}
      settingsKey={settingsKey}
    />
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Должность"
      name="position"
      saveSettings={saveSettings}
      className={styles.check}
      settingsKey={settingsKey}
    />
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Телефон"
      name="phone"
      saveSettings={saveSettings}
      className={styles.check}
      settingsKey={settingsKey}
    />
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Email"
      name="email"
      saveSettings={saveSettings}
      className={styles.check}
      settingsKey={settingsKey}
    />
    <WithHOC
      component={Checkbox}
      hoc={CheckboxSettings}
      label="Идентификатор"
      name="ident"
      saveSettings={saveSettings}
      className={styles.check}
      settingsKey={settingsKey}
    />
    {/* <WithHOC
          component={Checkbox}
          hoc={CheckboxSettings}
          label="Дата приема"
          name="inviteDate"
          saveSettings={saveSettings}
          className={styles.check}
          settingsKey={settingsKey}
        /> */}
  </div>)}
</SettingsGear>);

export default EmployeesGearAtWork;
