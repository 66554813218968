// @flow
import { useState, useCallback, useEffect } from 'react';
import prop from 'ramda/es/prop';

import { getLastFirstM } from 'app/common/lib/names';
import { GET_LINKED_EMPLOYEE } from 'app/common/api/requests/food/employees/linked_employee.get';
import { DELETE_LINKED_EMPLOYEE } from 'app/common/api/requests/food/employees/linked_employee.delete';
import { api } from 'app/api';


type State = {
  fio: string,
}

const defaultState: State = {
  fio: '',
};

export default function useLinkedEmployee(): [State, boolean, () => Promise<boolean>] {
  const [state, setState] = useState(defaultState);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_LINKED_EMPLOYEE, {
      error: 'Не удалось получить связанного сотрудника',
    });
    setLoading(false);

    if (res) {
      const linked = prop('linked', res);
      if (linked) {
        const name = prop('name', res) || {};
        const fio = getLastFirstM(name);
        setState({ fio });
      }
    }
  }, []);

  useEffect(() => {
    request();
  }, [request]);

  const deleteLink = useCallback(async () => {
    const res = await api.request(DELETE_LINKED_EMPLOYEE, {
      error: 'Не удалось удалить связь',
    });
    if (res) {
      request();
    }
    return !!res;
  }, [request]);

  return [state, loading, deleteLink];
}
