// @flow
import React, { type Node, useCallback, Fragment } from 'react';
import cn from 'classnames/bind';

import PlainInput from 'app/common/ui-next/plain-input';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import HelperText from 'app/common/ui-next/plain-helper-text';

import styles from './CellChangeable.scss';


const cx = cn.bind(styles);

type Props = {
  slotId: string,
  value?: number,
  invert?: boolean,
  max: number,
  increase: (slotId: string) => void,
  decrease: (slotId: string) => void,
  change: (slotId: string, value: number) => void,
}

const CellChangeable = ({
  slotId,
  value,
  invert,
  max,
  increase,
  decrease,
  change,
}: Props): Node => {
  const handleDecrease = useCallback(() => {
    decrease(slotId);
  }, [decrease, slotId]);

  const handleIncrease = useCallback(() => {
    increase(slotId);
  }, [increase, slotId]);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.currentTarget;
    const num = parseInt(inputValue, 10);
    if (!Number.isNaN(num)) {
      change(slotId, Math.abs(num));
    }
  }, [change, slotId]);

  const disabledDecrease = value === undefined || value < 1;
  const disabledIncrease = value === undefined || value > max - 1;
  const hasError = value !== undefined && value > max;

  return (
    <Fragment>
      <div className={styles.root}>
        <PlainButtonAsLink
          linkClassName={cx(styles.change, { invert, disabled: disabledDecrease })}
          onClick={handleDecrease}
          disabled={disabledDecrease}
        >
          {'–'}
        </PlainButtonAsLink>

        <PlainInput
          className={styles.value}
          value={String(value === undefined ? '' : value)}
          onChange={handleChange}
          error={hasError}
          disabled={max === 0}
        />

        <PlainButtonAsLink
          linkClassName={cx(styles.change, { invert, disabled: disabledIncrease })}
          onClick={handleIncrease}
          disabled={disabledIncrease}
        >
          {'+'}
        </PlainButtonAsLink>
      </div>

      {hasError
        && <HelperText error>{`Макс. знач. ${max}`}</HelperText>}
    </Fragment>
  );
};

export default CellChangeable;
