// @flow
import { useState, useCallback, useEffect } from 'react';

import { api } from 'app/api';
import { GET_REPORTS_DATA_ORDER_PURCHASES } from 'app/common/api/requests/reports/data';

import apiResult2PurchaseItems, { type PurchaseItem } from './apiResult2PurchaseItems';


export default function useOOPGenerateItems(reportId: ?number): [Array<PurchaseItem>, boolean] {
  const [items, setItems] = useState<Array<PurchaseItem>>([]);
  const [loading, setLoading] = useState(false);

  const requestItems = useCallback(async () => {
    if (!reportId) return;

    setLoading(true);
    const res = await api.request(GET_REPORTS_DATA_ORDER_PURCHASES, {
      error: 'Не удалось загрузить данные отчета',
      params: {
        report_id: reportId,
      },
    });

    if (res) {
      setItems(apiResult2PurchaseItems(res));
    }
    setLoading(false);
  }, [reportId]);


  useEffect(() => {
    requestItems();
  }, [requestItems]);

  return [items, loading];
}
