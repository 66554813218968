import { memo } from 'react'

import {
  TrExpandable,
  Td,
  useCheckStateContext,
  useFancyRowExpandedStyle,
} from 'app/common/ui-components/table'

import { Statement } from 'app/dataTypes/compensation/statement'
import { ChangeStatusParams, StatementStatusMap } from 'app/dataTypes/compensation/statement/status'

import { Column } from './columns'
import { Cell } from './cell'
import { getStatusColor } from './statusColors'


type Props = {
  columns: Array<Column>
  columnProps: Record<Column, {
    alignLeft?: boolean
    alignRight?: boolean
    alignCenter?: boolean
  }>
  statement: Statement
  index: number
  calcTopShift: (index: number) => number
  trElem: HTMLTableRowElement | null
  updateRowNode: (el: HTMLTableRowElement | null, index: number) => void
  onChangeStatus: (params: ChangeStatusParams) => Promise<boolean>
  disabled?: boolean
  statuses: StatementStatusMap
}

export const TableRow = memo(({
  columns,
  columnProps,
  statement,
  // index,
  // calcTopShift,
  // trElem,
  // updateRowNode,
  onChangeStatus,
  disabled,
  statuses,
}: Props) => {
  const { ID, statusID } = statement

  const [/* expandedID */, setExpandedID] = useCheckStateContext()
  // const expanded = expandedID === ID
  // const tinted = Boolean(expandedID && expandedID !== ID)

  const color = getStatusColor(statusID)
  const fancyStyle = useFancyRowExpandedStyle(color)

  // const reference = useCallback((el: HTMLTableRowElement | null) => {
  //   updateRowNode(el, index)
  // }, [index, updateRowNode])

  // return (
  //   <tr>
  //     {columns.map(key => (
  //       <td key={key}>
  //         <ActionButtonWithPrompt
  //           title="Отменить проведение"
  //           confirmText="Введите причину для отмены заявки с номером ***?"
  //           onConfirm={setExpandedID as any}
  //         >
  //           {toggle => (
  //             <PlainButton
  //               onClick={toggle}
  //               compact
  //             >
  //               {'Отменить проведение'}
  //             </PlainButton>
  //           )}
  //         </ActionButtonWithPrompt>
  //       </td>
  //     ))}
  //   </tr>
  // )

  return (
    <TrExpandable
      // classNameExpanded={fancyRowExpandedHeaderClassName}
      style={fancyStyle}
      // styleExpanded={fancyStyle}
      // reference={reference}
      id={ID}
      expandedContent={null}
      expanded={false}
      onExpand={setExpandedID}
      tinted={false} // tinted
      columnsAmount={columns.length}
      disableExpand
      disabled={disabled}
      interlineBorder
      alignTop
    >
      {columns.map(key => (
        <Td key={key} {...columnProps[key]} last={key === Column.Action}>
          <Cell
            columnKey={key}
            statement={statement}
            onChangeStatus={onChangeStatus}
            disabled={disabled}
            statuses={statuses}
          />
        </Td>
      ))}

      {/* <FancyRowHeader
        expanded={expanded}
        requestTopShift={calcTopShift}
        rowIndex={index}
        color={color}
        trElem={trElem}
      /> */}
    </TrExpandable>
  )
})
