import { Switch, Route } from 'react-router'

import { Accruals } from 'app/components/compensation/Accruals'
import { Statements } from 'app/components/compensation/Statements'
import { Recipients } from 'app/components/compensation/Recipients'

import { ProjectPageLayout } from '../_common/ProjectPageLayout'
import { CompensationPageNavigation } from './CompensationPageNavigation'
import { getURL, getSection, SectionRoute } from './router'
import { SettingsPage } from './SettingsPage'
import { pathMask as pathMaskSettings } from './SettingsPage/router'


const root = getURL()
const accruals = getURL({ section: SectionRoute.Accruals })
const statements = getURL({ section: SectionRoute.Statements })
const recipients = getURL({ section: SectionRoute.Recipients })
const settings = getURL({ section: SectionRoute.Settings })

export const CompensationPageSection = (props) => {
  const section = getSection(props)

  return (
    <ProjectPageLayout header="Компенсация">
      <CompensationPageNavigation baseURL={root} activeSection={section} />
      <Switch>
        <Route path={accruals} component={Accruals} exact />
        <Route path={statements} component={Statements} exact />
        <Route path={recipients} component={Recipients} exact />
        <Route path={settings} component={SettingsPage} exact />
        <Route path={pathMaskSettings} component={SettingsPage} exact />
      </Switch>
    </ProjectPageLayout>
  )
}
