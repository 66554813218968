// @flow
import { useCallback, useState } from 'react';
import { reduce, pipe, assoc, toPairs, sort, ascend, prop, take, map } from 'ramda';

/**
 * Расчет отступа сверху развернутой строки таблицы
 * путем сложения высот всех ячеек сверхку от текущей
 *
 * Более медленный, но стабильный вариант.
 * Если есть обновления в таблице, например фильтр
 */

type State = {
  [index: string]: HTMLTableRowElement | null
}

export default function useTopShift(): [
  (el: HTMLTableRowElement | null, index: number) => void,
  (index: number) => number,
  State,
  ] {
  const [rowNodes, setRowNodes] = useState<State>({});

  const updateRowNode = useCallback((el: HTMLTableRowElement | null, index: number) => {
    setRowNodes(assoc(String(index), el));
  }, []);

  const calcTopShift = useCallback((index: number) => {
    const heightsSum = pipe(
      toPairs,
      map(([i, el]) => ({ i: parseInt(i, 10), el })),
      sort(ascend(prop('i'))),
      take(index),
      reduce((acc, { el }) => {
        if (el) {
          const { top, bottom } = el.getBoundingClientRect();
          const height = bottom - top;
          return acc + height;
        }
        return acc;
      }, 0),
    )(rowNodes);

    return heightsSum;
  }, [rowNodes]);

  return [updateRowNode, calcTopShift, rowNodes];
}
