// @flow
import React from 'react';
import cn from 'classnames';

import withSortable, { withSortableStateless } from './withSortable';
import styles from './Table.scss';


type Props = {
  children?: any,
  className?: string,
  handleSort: (e: SyntheticEvent<HTMLElement>) => void,
  currentSortKey: string,
  currentSortDirection: string,
};

const HeaderRowSortable = ({
  children,
  className,
  handleSort,
  currentSortKey,
  currentSortDirection,
}: Props) => {
  if (!children) return null;
  const itemProps = {
    handleSort,
    currentSortKey,
    currentSortDirection,
  };
  return (
    <div className={cn(styles.headerRow, className)}>
      {Object.prototype.toString.call(children) === '[object Array]'
        ? children.map(child => child && React.cloneElement(child, itemProps))
        : React.cloneElement(children, itemProps)}
    </div>
  );
};

export default withSortable(HeaderRowSortable);
export const HeaderRowSortableStateless = withSortableStateless(HeaderRowSortable);
