// @flow
import React, { type Node } from 'react';

import Tooltip from 'app/common/ui/Tooltip';

import styles from './complex-tooltip.scss';


export type DishItem = {
  id: number,
  title: string,
  portion: string,
  index: number,
}

type Props = {
  header: string,
  headerSuffix?: string,
  items: Array<DishItem>,
  children: Node,
}

const ComplexTooltip = ({ header, headerSuffix, items, children }: Props) => {
  const content = (<div className={styles.root}>
    <div className={styles.header}>
      <span>{header}</span>
      <span>{headerSuffix}</span>
    </div>
    <ul className={styles.list}>
      {items.map(({ title, portion }) => (
        <li className={styles.listItem} key={`${title}${portion}`}>
          <span>{title}</span>
          <span>{portion}</span>
        </li>
      ))}
    </ul>
  </div>);

  return (
    <Tooltip content={content}>
      {children}
    </Tooltip>
  );
};

export default ComplexTooltip;
