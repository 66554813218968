// @flow
import path from 'ramda/es/path';
import moment from 'moment-timezone';

import { getStateCurrentValue, getStateItems, type State } from 'app/common/ui-components/filter';
import convertDatesFormat from 'app/common/lib/convertDatesFormat';

import yearFilter, { dateFormat as requestDateFormat } from 'app/components/common/reports/filters/year';


export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

const dummyItems = [{ id: 'dummy', title: 'dummy' }];

const dateItems = (state: State) => {
  const yearCurrentIds = getStateCurrentValue(yearFilter, state);
  const years = getStateItems(yearFilter, state);
  const selectedYearId = yearCurrentIds[0];

  const [dateStart, dateFinish] = convertDatesFormat(requestDateFormat, dateFormat, [
    path([selectedYearId, 'date_start'], years) || null,
    path([selectedYearId, 'date_finish'], years) || null,
  ]);

  const mToday = moment();
  const today = mToday.format(dateFormat);

  if (dateStart && dateFinish) {
    if (mToday.isBetween(dateStart, dateFinish, 'day', '[]')) {
      return ([dummyItems, [today]]);
    }
    return ([dummyItems, [dateFinish]]);
  }

  return ([dummyItems, [today]]);
};

export default dateItems;
