// @flow
export const SORT_FILTER_SET = 'reports/SORT_FILTER_SET';
export const SORT_FILTER_DEFAULT = 'reports/SORT_FILTER_DEFAULT';

export const BY_CLASSES = 'by_classes';
export const BY_ALPHABET = 'by_alphabet';

export type SortBy = 'by_classes' | 'by_alphabet';

export const sortFilterSet = (payload: SortBy) => ({
  type: SORT_FILTER_SET,
  payload,
});

export const sortFilterDefault = () => ({
  type: SORT_FILTER_DEFAULT,
});
