// @flow
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import filter from 'ramda/es/filter';
import prop from 'ramda/es/prop';
import toLower from 'ramda/es/toLower';


type Item = {
  id: number | string,
  title: string,
}

type Props = {
  items: Array<Item> | { [id: string]: Item },
  search: string,
}

const selectItemsBySearch = createSelector(
  [
    ({ items }: Props) => (items
      || (console.log('Данные items не приходят в withFilteredItemsBySearch') || [])), // eslint-disable-line no-console
    ({ search }: Props) => search,
  ],
  (items, search) => filter(
    item => toLower(prop('title', item)).includes(toLower(search)),
    items,
  ),
);

export default compose(
  withState('search', 'setSearch', ''),

  withHandlers({
    handleSearch: ({ setSearch }) => (e) => {
      const { value } = e.currentTarget;
      setSearch(value);
    },
    handleClearSearch: ({ setSearch }) => (e) => {
      if (e && typeof e.preventDefault === 'function') {
        e.preventDefault();
      }
      setSearch('');
    },
  }),

  mapProps(props => ({
    ...props,
    // фильтрация по поисковой строке
    items: selectItemsBySearch(props),
  })),
);
