// @flow
import React from 'react';

import { DotsLoader } from 'app/common/components/Loader';

import IconSave from './icons/icon-save';
import PlainButtonAsLink from '../plain-button-as-link';
import styles from './save-button.scss';


type Props = {
  id: string,
  saving?: boolean,
  className?: string,
}

const SaveButton = ({ id, saving, className }: Props) => {
  return (
    saving
      ? <DotsLoader className={className} simple />
      : <PlainButtonAsLink id={id} type="submit" className={className}>
        <IconSave className={styles.icon} />
      </PlainButtonAsLink>
  );
};

export default SaveButton;
