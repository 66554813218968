// @flow
import React, { type Node } from 'react';
import { slice } from 'ramda';

import Checkbox from 'app/common/ui/Checkbox';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';
import SettingsGear from 'app/common/components/Settings';

import { columns } from './table/columns';
import { dataKey } from './table/dataKey';
import styles from './VendingDishesGear.scss';


const settingsIdentifiers = slice(2, Infinity, columns);
const settingsTitles = ['Фото', 'Порция', 'Стоимость', 'Тип продукции', 'Категория', 'Действия'];

const VendingDishesGear = ({ className }: { className?: string }): Node => (<SettingsGear dataKey={dataKey} className={className}>
  {({ saveSettings }) => (<div>
    <div className={styles.gearHeader}>{'Столбцы:'}</div>
    {settingsIdentifiers.map((id, i) => (
      <WithHOC
        key={id}
        component={Checkbox}
        hoc={CheckboxSettings}
        label={settingsTitles[i]}
        name={id}
        saveSettings={saveSettings}
        className={styles.gearCheck}
        settingsKey={dataKey}
      />
    ))}
  </div>)}
</SettingsGear>);

export default VendingDishesGear;
