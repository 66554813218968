import { ReactNode } from 'react'
import { createPortal } from 'react-dom'


type Props = {
  children: ReactNode
}

const Portal = ({ children }: Props) => {
  const formMountNode = document.getElementById('modal')
  const formPortal = (
    formMountNode
      ? createPortal(children, formMountNode)
      : null
  )

  return (
    formPortal
  )
}

export default Portal
