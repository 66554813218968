// @flow
import isEmpty from 'ramda/es/isEmpty';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';

import type { VendingMachinesState } from './useVendingMachines';


export const getRelatedOrgIds = (machines: VendingMachinesState): Array<number> | null => {
  const { data } = machines;
  if (isEmpty(data)) {
    return null;
  }

  return map(prop('school_id'), data);
};
