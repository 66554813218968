import { useCallback, useEffect, useState } from 'react'
import { prop } from 'ramda'

import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { DataBox, subscibeTo } from 'app/common/data-box-store'
import { sortClasses } from 'app/common/lib/sort'
import { OrgsOrUserIDs, defaultSchoolYearDataReceiver, defaultSchoolYearSelection, orgOrUserFilterSelection, orgsFilterSelection, schoolYearFilter, schoolYearFilterSelection } from 'app/dataProviders/filter-items'
import { ItemType } from 'app/common/ui-next/plain-selector'


type Props = {
  id: string
  pending: boolean
}

const sortByTitle = sortClasses('up', prop('title'))

const schoolYearFilterForSelector = schoolYearFilter

export const SchoolYearFilter = ({ id, pending }: Props) => {
  const [isReady, setReady] = useState<boolean>(false)
  const [filterVisibility, setFilterVisibility] = useState<boolean>(true)

  const orgsFilterHandler = useCallback((data: string[]) => {
    if (data.length === 1) {
      schoolYearFilter.addDataReceiver(
        defaultSchoolYearDataReceiver,
      )
      schoolYearFilter.dataReceive()
      setReady(true)
      return
    }
    schoolYearFilter.resetToDefault()
    setReady(false)
  }, [])

  const orgOrUserFilterHandler = useCallback((data: OrgsOrUserIDs) => {
    if (data === OrgsOrUserIDs.ByOrgs) {
      setFilterVisibility(true)
      return
    }
    schoolYearFilter.resetToDefault()
    setFilterVisibility(false)
    setReady(false)
  }, [])


  useEffect(() => {
    const orgsUnsubsribe = subscibeTo<string[]>(orgsFilterSelection.id, orgsFilterHandler)
    const orgOrUserUnsubsribe = subscibeTo<OrgsOrUserIDs>(orgOrUserFilterSelection.id, orgOrUserFilterHandler)

    return (() => {
      orgsUnsubsribe()
      orgOrUserUnsubsribe()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    filterVisibility && isReady && <PlainItemsSelectorBox
      id={id}
      label="Учебный год"
      header="Выбор учебного года"
      dataBoxData={schoolYearFilterForSelector as DataBox<Array<unknown>> as DataBox<Array<ItemType>>}
      dataBoxSelection={schoolYearFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      disabled={pending}
      sortFunc={sortByTitle}
      defaultSelection={defaultSchoolYearSelection}
    />
  )
}
