// @flow
import { useState, useCallback, useEffect } from 'react'
import { isEmpty, path } from 'ramda'

import { api } from 'app/api'
import { GET_DAY_CARES, type DayCaresResult } from 'app/common/api/requests/reports/day_cares_list'


const defaultDayCares = {}

export default function useDayCareTitleById(dayCareIds: Array<string>) {
  const [loading, setLoading] = useState(true)
  const [dayCares, setDayCares] = useState<DayCaresResult>(defaultDayCares)

  const handleRequest = useCallback(async () => {
    if (isEmpty(dayCareIds)) return
    setLoading(true)

    const res: DayCaresResult | null = await api.request(GET_DAY_CARES, {
      error: 'Не удалось получить данные ГПД',
      params: {
        ids: dayCareIds,
      },
    })

    if (res) {
      setDayCares(res)
    }
    setLoading(false)
  }, [dayCareIds])

  useEffect(() => {
    handleRequest()
  }, [handleRequest])


  const handleGetDayCareTitle = useCallback((id: string) => {
    if (loading) {
      return 'загружается...'
    }
    return path([id, 'title'], dayCares) || '[Неизвестная ГПД]'
  }, [dayCares, loading])


  return handleGetDayCareTitle
}
