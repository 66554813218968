import cn from 'classnames/bind'
import { Table, Tr, Th, Td, ThProps } from 'app/common/ui-components/table'

import { HeaderItem, OperationType, Org, TotalDataItem } from 'app/dataTypes/reports/data'
import { useCurrencySign } from 'app/common/components-next/currency'
import styles from './MainTable.scss'
import { DualLineDataCell } from './DualLineDataCell'


const cx = cn.bind(styles)

const renderValue = (val: string | null) => (
  val === null ? '—' : String(val)
)

type Props = {
  className?: string
  sameRowClassBase?: string
  headers?: Array<HeaderItem>
  orgs?: Array<Org>
  operationType?: OperationType
  total?: Array<TotalDataItem>
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
}

const MainTable = ({
  className,
  sameRowClassBase = 'row_',
  headers,
  orgs,
  operationType,
  total,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const currency = useCurrencySign()
  const middlePosition = !!orgs

  const thProps = { scope: 'col', bgFilled: true } as Partial<ThProps>
  const thPropsSub = { ...thProps, alignRight: true }

  const shiftRowClassName = (hasLeftBorder: boolean) => cx(styles.column, { hasLeftBorder })

  return (
    <Table className={className}>
      {headers
        && <thead>
          <Tr header hidden={middlePosition}>
            {headers?.map((item, i) => (
              <Th
                {...thPropsSub}
                className={cx(shiftRowClassName(i !== 0), { heightRow: !middlePosition })}
                key={item.ID}
                ignoreFirstChild
              >
                {item.date.getString('DD.MM')}
              </Th>
            ))}
          </Tr>
        </thead>}

      {orgs
        && <tbody>
          {orgs.map(({ ID, data }, rowIndex) => (
            <Tr
              key={ID}
              className={`${sameRowClassBase}${rowIndex}`}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              interlineBorder
            >
              {data.map(({ ID: dataID, correctionsValue, value }, i) => (
                <Td
                  key={dataID}
                  ignoreFirstChild={i === 0}
                  className={cx(shiftRowClassName(i !== 0), styles.heightRow)}
                  alignRight
                >
                  <DualLineDataCell
                    line1={`${renderValue(value)} ${currency}`}
                    line2Link={`${renderValue(correctionsValue)} ${currency}`}
                    date={dataID}
                    schoolID={ID}
                    operationType={operationType}
                  />
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>}

      {total
        && <tfoot>
          <Tr>
            {total.map(({ ID, value }, i) => (
              <Td
                className={cx(
                  styles.column,
                  styles.summary,
                  shiftRowClassName(i !== 0),
                )}
                key={ID}
                ignoreFirstChild
                alignRight
                bgFilled
              >
                {`${renderValue(value)} ${currency}`}
              </Td>
            ))}
          </Tr>
        </tfoot>}
    </Table>
  )
}

export default MainTable
