import { map, reduce, reject, values } from 'ramda'

import { Org, OrgsData, Params } from 'app/dataTypes/org'
import { OrgType, isOrgType } from 'app/dataTypes/org/types'

import SimpleDate from 'app/common/classes/simple-date'
import { Request } from '../../index'


const dateFormat = 'YYYY-MM-DD' as const

export type ApiResult = {
  schools: Record<string, {
    id: number
    type: string // GET /food/school_types "school", "preschool", "enterprise", "high_school"
    title: string
    deleted: boolean
    approved: boolean
    from: string | null // "2018-06-13", // дата начала работы
    building_count: number
    menu_categories_count: number
    sale_points_count: number
    role_id?: number // id роли пользователя в поле roles
    district_id: number
    city_id: number
    cafeteria_enabled: boolean
    food_place_for_guests: boolean
    today_sales_sum: number
    month_sales_sum: number
    school_group_id: number
  }>
  count: number
}

export const convertResult = ({ schools, count }: ApiResult): OrgsData => {
  return {
    items: reduce((acc, val) => {
      const { id, title, deleted, from, type, school_group_id: orgGroupID } = val
      const ID = String(id)
      acc.set(ID, {
        ID,
        title,
        deleted,
        availableFrom: from ? new SimpleDate(from, dateFormat) : null,
        orgType: isOrgType(type) ? type as OrgType : OrgType.Unknown,
        orgGroupID: orgGroupID ? String(orgGroupID) : null,
      })
      return acc
    }, new Map() as Map<string, Org>, values(schools)),
    total: count,
  }
}

type ApiParams = {
  limit?: number
  offset?: number
  id?: number | Array<number>
  include?: ['roles']
  type?: string
  search?: string // поиск по имени организации
  deleted?: boolean
  city_id?: Array<number>
  district_id?: Array<number>
  has_today_menu?: boolean
  school_group_id?: number
}

const itMayBeSingleArrayOrNothing = (ID: string | Array<string> | void) => {
  if (ID instanceof Array) {
    return map(parseInt, ID)
  }
  if (ID) {
    return parseInt(ID, 10)
  }
  return undefined
}

export const convertParams = (params: Params): [ApiParams, null] => {
  const {
    limit,
    offset,
    ID,
    orgType,
    search,
    deleted,
    cityIDs,
    districtIDs,
    hasTodayMenu,
    orgGroupID,
  } = params
  return ([reject(val => (val === undefined), {
    limit,
    offset,
    id: itMayBeSingleArrayOrNothing(ID),
    type: orgType === OrgType.HighSchool ? 'high_school' : orgType,
    search,
    deleted,
    city_id: cityIDs ? map(parseInt, cityIDs) : undefined,
    district_id: districtIDs ? map(parseInt, districtIDs) : undefined,
    has_today_menu: hasTodayMenu,
    school_group_id: orgGroupID ? parseInt(orgGroupID, 10) : undefined,
  }), null])
}

export const GET_ORGS: Request<Params, OrgsData, void> = [
  'food/schools',
  'get',
  ['data'],
]
