import { filter, equals, identity } from 'ramda'


export default function partialUpdate<T>(data: Partial<T>) {
  if (typeof data !== 'object' || data === null) {
    console.warn('partialUpdate можно применить только для объектов')
    return identity
  }
  return (current: T) => {
    const nextState = filter(val => (val !== undefined), {
      ...current,
      ...data,
    })
    if (!equals(current, nextState)) {
      return nextState
    }
    return current
  }
}
