import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import omitProps from 'app/common/containers/omitProps';


export default compose(
  withState('resetStatus', 'setResetStatus', false),
  withHandlers({
    handleResetAll: ({ setResetStatus }) => (e) => {
      e.preventDefault();
      setResetStatus(true);
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { resetStatus, setResetStatus } = this.props;
      if (resetStatus !== prevProps.resetStatus && resetStatus) {
        setResetStatus(false);
      }
    },
  }),
  omitProps(['setResetStatus']),
);
