// @flow
import { isEmpty } from 'ramda';

import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';

import { filterId } from './filterId';
import { customerTypes } from './customerTypeItems';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const onlyStudentCustomerSelected = (state: State) => {
  const customerIds = getStateCurrentValue(filterId, state);
  return (
    customerIds.length === 1
    && customerIds[0] === customerTypes.STUDENTS
  );
};

export const onlyEmployeeCustomerSelected = (state: State) => {
  const customerIds = getStateCurrentValue(filterId, state);
  return (
    customerIds.length === 1
    && customerIds[0] === customerTypes.EMPLOYEES
  );
};

export const allCustomersSelected = (state: State) => {
  const customerIds = getStateCurrentValue(filterId, state);
  return isEmpty(customerIds);
};

export const nonStudentCustomerSelected = (state: State) => {
  const customerIds = getStateCurrentValue(filterId, state);
  return (
    customerIds.length === 1
    && customerIds[0] !== customerTypes.STUDENTS
  );
};
