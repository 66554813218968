import { connect } from 'react-redux';

import { popup } from 'app/common/actions/popup';
import { itemsEdit } from 'app/actions/orgs/kits/itemsEdit';
import { kitsNeedUpdate } from 'app/actions/orgs/kits/options';
import { router } from 'app/selectors/router';


export default connect(
  state => ({
    items: state.orgs.dishes.items,
  }),
  (dispatch, props) => ({
    onSubmit: async (addList, removeList) => {
      const { orgId, kitId, date } = props;
      const res = await dispatch(itemsEdit(
        orgId,
        {
          date,
          kitId,
        },
        addList,
        removeList,
      ));
      if (res) {
        dispatch(kitsNeedUpdate(true));
        dispatch(popup('Комплект успешно отредактирован'));
        router.locationReplace(2);
      }
    },
  }),
);
