// @flow
import { foodModes, type FoodMode } from 'app/dataTypes/foodModes';

import type { Request } from '../../index';


/**
 *  Запрос заявок
 */

export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

// food modes

export type { FoodMode };
export { foodModes };

// statuses

const STATUS_DELETED: -1 = -1;
const STATUS_PENDING_ISSUE: 0 = 0;
const STATUS_PENDING: 1 = 1;
const STATUS_SPENT: 2 = 2;

export const statuses = {
  STATUS_DELETED,
  STATUS_PENDING_ISSUE,
  STATUS_PENDING,
  STATUS_SPENT,
};

export type Status = $Values<typeof statuses>

// roles

const ROLE_CAFETERIA: 'cafeteria' = 'cafeteria'; // сотрудник столовой
const ROLE_DEALER: 'dealer' = 'dealer'; // сотрудник оператора
const ROLE_SCHOOL: 'school' = 'school'; // сотрудник ОУ
const ROLE_ADMIN: 'admin' = 'admin'; // администратор системы
const ROLE_TEMPLATE: 'template' = 'template'; // создано по шаблону

export const roles = {
  ROLE_CAFETERIA,
  ROLE_DEALER,
  ROLE_SCHOOL,
  ROLE_ADMIN,
  ROLE_TEMPLATE,
};

export type Role = $Values<typeof roles>

// groups

const GROUP_CLASS: 'Klass' = 'Klass';
const GROUP_DAYCARE: 'DayCare' = 'DayCare';

export const groups = {
  GROUP_CLASS,
  GROUP_DAYCARE,
};

export type Group = $Values<typeof groups>

export type CafeOrdersParams = {
  limit: number,
  offset: number,
  year_id?: number, // учебный год, опционально
  month?: number, // номер месяца, опционально
  date_from?: string,
  date_to?: string,
  klasses_ids?: Array<number>, // массив классов (из учебного года), опционально
  day_cares_ids?: Array<number>, // массив ГПД (из учебного года), опционально, можно комбинировать вместе с классами
  status?: Array<Status>, // массив статусов, опционально
  id?: number, // выбор по ID
}

export type CafeOrder = {
  id: number,
  status: ?Status,
  date: ?string,
  created_user_name: ?string,
  created_user_role: ?Role,
  food_mode: ?FoodMode, // breakfast | dinner | afternoon
  students_count: ?number,
  total: ?number,
  group_type: ?Group, // Klass | DayCare
  group_title: ?string,
}

export type CafeOrders = {
  [key: string]: CafeOrder,
}

export type CafeOrdersResult = {
  result: 'success',
  orders: CafeOrders,
  count: number,
}

type CafeOrdersPathParams = {
  orgId: string,
}

export const GET_CAFETERIA_ORDERS: Request<
  CafeOrdersParams,
  CafeOrdersResult,
  CafeOrdersPathParams,
> = [
  'food/schools/{orgId}/cafeteria_orders',
  'get',
  ['data'],
];

type DishItem = {
  id: number,
  title: ?string,
  portion: ?string,
}

export type Sale = {
  id: number,
  title: ?string,
  cost: ?number,
  portion: ?string,
  count: ?number,
  item_type: 'complex' | 'item',
  complex_list?: Array<DishItem>,
}

type CafeOrderDetailsResult = Array<Sale>

type CafeOrderDetailsPathParams = {
  orgId: string,
  orderId: string,
}

export const GET_CAFETERIA_ORDERS_DETAILS: Request<
  void,
  CafeOrderDetailsResult,
  CafeOrderDetailsPathParams,
> = [
  'food/schools/{orgId}/cafeteria_orders/{orderId}',
  'get',
  ['data', 'sales'],
];
