import React from 'react';
import pipe from 'ramda/es/pipe';
import split from 'ramda/es/split';
import replace from 'ramda/es/replace';
import adjust from 'ramda/es/adjust';
import addIndex from 'ramda/es/addIndex';
import map from 'ramda/es/map';

import styles from './SummaryItem.scss';


const mapIndexed = addIndex(map);
const newLines = pipe(
  split('\n'),
  mapIndexed((item, i) => <div key={i}>{item}</div>),
);

const makePair = (variable) => {
  const num = Number(variable);
  const str = num.toFixed(2);
  return pipe(
    split('.'),
    adjust(0, replace(/\B(?=(\d{3})+(?!\d))/g, ' ')),
    adjust(0, replace(/-/, '−')),
  )(str);
};

type Props = {
  title?: string,
  value?: number,
  unit?: string,
  whole?: boolean, // целое
  value2?: number,
  unit2?: number,
}

const SummaryItem = ({ title, value, unit, whole, value2, unit2 }: Props) => {
  const [str1, str2] = makePair(value);
  const [str3, str4] = value2 ? makePair(value2) : ['', ''];

  const isValue2 = !!value2;

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        {newLines(title)}
      </div>
      <div>
        <span className={styles.valueMain}>{str1}</span>
        {!whole && str2 && <span className={styles.valueFraction}>{`.${str2}`}</span>}
        {unit && <span className={styles.valueUnit}>{unit}</span>}
        {isValue2 && <span className={styles.valueMain}>{' / '}</span>}
        {str3 && isValue2 && <span className={styles.valueMain}>{str3}</span>}
        {!whole && str4 && isValue2 && <span className={styles.valueFraction}>{`.${str4}`}</span>}
        {unit2 && isValue2 && <span className={styles.valueUnit}>{unit2}</span>}
      </div>
    </div>
  );
};

SummaryItem.defaultProps = {
  value: 0.00,
  title: 'Нет данных',
};

export default SummaryItem;
