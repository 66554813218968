// @flow
import { dataKey as vendingDataKey } from 'app/components/OrgsList/Vending';

import getOrgsBaseUrl from '../OrgsListPage/getBaseUrl';


const getParentBaseUrl = () => {
  return getOrgsBaseUrl({ orgType: vendingDataKey });
};

export default getParentBaseUrl;
