// @flow
import React, { useCallback } from 'react';

import { PlainInputSearch } from 'app/common/ui/PlainInput';
import useDebouncedSearch from 'app/common/hooks/useDebouncedSearch';


type Props = {
  onChangeSearch: (string) => void,
}

const DishesSearch = ({ onChangeSearch }: Props) => {
  const handleReset = useCallback(() => onChangeSearch(''), [onChangeSearch]);

  const [searchString, handleSearch, handleClearSearch] = useDebouncedSearch(onChangeSearch, handleReset);

  return (
    <PlainInputSearch
      searchString={searchString}
      handleSearch={handleSearch}
      handleClearSearch={handleClearSearch}
    />
  );
};

export default DishesSearch;
