import { range } from 'ramda'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'

import PlainInput, { PlainInputNumber } from 'app/common/ui-next/plain-input'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import { intRe } from 'app/common/constants/regex'
import SupportContactFormTemplate from './SupportContactFormTemplate'
import SupportContactFormValue from './SupportContactFormValue'

import {
  getSupportContactsError,
  getSupportContactsPositionError,
  getSupportContactsKindError,
  getSupportContactsValueError,
} from '../../errors/checkSupportContactsErrors'

import styles from './SupportContactForm.scss'

type TimeoutIdType = {
  [k: string]: NodeJS.Timeout | null
}


const timeoutContactFormId: TimeoutIdType = {}

const clearTimeoutHandler = (formID: string) => {
  const resId = timeoutContactFormId[formID]
  if (resId !== null && resId !== undefined) {
    clearTimeout(resId)
  }
}

type Props = {
  ID: string
  className?: string
  position: string
  kind: string
  values: Array<{ ID: string, value: string }>
  minimumValues?: number
  disabled?: boolean
  errors: Record<string, string>
  onChange?: (ID: string, fieldName: 'position' | 'kind', value: string) => void
  onChangeValue?: (ID: string, valueIndex: number, value: string, onCheckError?: boolean) => void
  onRemove?: (ID: string) => void
  onCheckError?: () => void
}

const SupportContactForm = ({
  ID,
  className,
  position,
  kind,
  values,
  minimumValues = 2,
  disabled,
  errors,
  onChange,
  onChangeValue,
  onRemove,
  onCheckError,
}: Props) => {
  const indexes = useMemo(() => {
    return range(0, minimumValues)
  }, [minimumValues])

  const onChangeRef = useCallbackRef(onChange)
  const onRemoveRef = useCallbackRef(onRemove)
  const onChangeValueRef = useCallbackRef(onChangeValue)
  const onCheckErrorRef = useCallbackRef(onCheckError)
  const [isFocus, setFocus] = useState<boolean>(true)

  const handleChangePosition = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (intRe.test(value)) {
      onChangeRef.current?.(ID, 'position', value)
    }
  }, [onChangeRef, ID])

  const handleChangeKind = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    onChangeRef.current?.(ID, 'kind', value)
  }, [onChangeRef, ID])

  const handleDelete = useCallback(() => {
    onRemoveRef.current?.(ID)
  }, [onRemoveRef, ID])

  const handleChangeValue = useCallback((index: number, value: string) => {
    onChangeValueRef.current?.(ID, index, value)
  }, [ID, onChangeValueRef])

  const onBlurHandler = useCallback(() => {
    clearTimeoutHandler(ID)
    timeoutContactFormId[ID] = setTimeout(() => {
      setFocus(false)
    }, 300)
  }, [ID])


  const onFocusHandler = useCallback(() => {
    clearTimeoutHandler(ID)
    setFocus(true)
  }, [ID])

  useEffect(() => {
    if (!isFocus) {
      onCheckErrorRef.current?.()
    }
  }, [isFocus, onCheckErrorRef])

  const errorText = !isFocus && getSupportContactsError(ID, errors)

  return (
    <div className={cn(className, getScrollToErrorClassName(!!errorText))}>

      <SupportContactFormTemplate>
        <PlainInputNumber
          value={position}
          onChange={handleChangePosition}
          disabled={disabled}
          error={!!getSupportContactsPositionError(ID, errors)}
          hideArrows
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
        />
        <PlainInput
          value={kind}
          onChange={handleChangeKind}
          disabled={disabled}
          error={!!getSupportContactsKindError(ID, errors)}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
        />
        {indexes.map(index => (
          <SupportContactFormValue
            key={index}
            index={index}
            value={values[index]?.value || ''}
            onChange={handleChangeValue}
            disabled={disabled}
            error={index === 0 && !!getSupportContactsValueError(ID, errors)}
            onBlur={onBlurHandler}
            onFocus={onFocusHandler}
          />
        ))}
        <PlainButtonAsLink
          onClick={handleDelete}
          disabled={disabled}
        >
          {'Удалить вид'}
        </PlainButtonAsLink>
      </SupportContactFormTemplate>

      {!!errorText
        && <PlainHelperText className={styles.error} error>
          {errorText}
        </PlainHelperText>}
    </div>
  )
}

export default SupportContactForm
