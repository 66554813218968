import { RefCallback } from 'react'

import { CompensationDetails } from 'app/dataTypes/compensation/details'
import { Table, Td, Tr } from 'app/common/ui-components/table'
import toFixedValue from 'app/common/lib/toFixedValue'

import styles from './DetailsTableFooter.scss'


type Props = {
  compensationDetails: CompensationDetails
  trRef: RefCallback<'tr'>
}

export const DetailsTableFooter = ({ compensationDetails, trRef }: Props) => {
  const { total: { students, days, sum } } = compensationDetails

  return (
    <Table className={styles.root} wide>
      <tfoot>
        <Tr reference={trRef} header>
          <Td className="index_0" alignRight>
            <span className={styles.totalHeader}>
              {'Итого:'}
            </span>
          </Td>
          <Td className="index_1">{`${students} чел.`}</Td>
          <Td className="index_2">{days}</Td>
          <Td className="index_3">{toFixedValue(sum)}</Td>
        </Tr>
      </tfoot>
    </Table>
  )
}
