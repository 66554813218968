// @flow
import React, { type Node } from 'react';

import useSticky from './use-sticky';

/**
 *  Комопнент прилипания заголовков при скролинге. Поддерживает любой уровень вложенности.
 *  Необходимо указать number - номер вложенности. Если перепутать, то смещение сверху расщитается неверно.
 *  Отрицательные number - прилипание к низу экрана.
 */

type Props = {
  number: number,
  className?: string,
  stickyClassName?: string,
  shadowClassName?: string,
  skipNextShift?: boolean,
  children: Node,
  Component?: string
}

const CssSticky = ({
  number,
  className,
  stickyClassName,
  shadowClassName,
  // пропустить высоту этого элемента, при учете сдвига последующих
  skipNextShift, // работает только с верхним прилипанием. TODO для нижнего
  children,
  // Component = 'div', // можно добавить, когда исправят работу position: sticky в таблицах для brave и safari
}: Props) => {
  const elRef = useSticky({ number, stickyClassName, shadowClassName, skipNextShift });

  return (
    <div
      ref={elRef}
      id={String(number)}
      className={className}
      style={{ position: 'sticky' }}
    >
      {children}
    </div>
  );
};

export default CssSticky;
