// @flow

/**
 *  Запрос данных фильтра по наличию меню на сегодня
 */

const MENU_PRESENCE_ALL: 'all' = 'all';
const MENU_PRESENCE_TRUE: 'true' = 'true';
const MENU_PRESENCE_FALSE: 'false' = 'false';
export const menuPresenceValues = {
  MENU_PRESENCE_ALL,
  MENU_PRESENCE_TRUE,
  MENU_PRESENCE_FALSE,
};

const filterGetItemsMenuPresence = () => {
  return ([[{
    id: menuPresenceValues.MENU_PRESENCE_ALL,
    title: 'Все',
  }, {
    id: menuPresenceValues.MENU_PRESENCE_TRUE,
    title: 'Имеется',
  }, {
    id: menuPresenceValues.MENU_PRESENCE_FALSE,
    title: 'Отсутствует',
  }], [
    menuPresenceValues.MENU_PRESENCE_ALL,
  ]]);
};

export default filterGetItemsMenuPresence;
