// @flow
import React, { useState, useCallback, type Node } from 'react';
import classNames from 'classnames/bind';
import { not } from 'ramda';

import type { DraggableStateSnapshot } from 'app/common/ui-components/plain-draggable-list';
import ReorderIcon from 'app/common/icons/reorder.svg';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';
import PlainTooltip from 'app/common/ui-next/plain-tooltip';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import type { Category } from 'app/dataTypes/vending/production/Categories.types';
import isProduction from 'app/common/lib/isProduction';

import styles from './CategoryItem.scss';


const cx = classNames.bind(styles);

type Props = {
  ...Category,
  loading?: boolean,
  active: boolean,
  first: boolean,
  draggableStateSnapshot?: DraggableStateSnapshot,
  isDragDisabled?: boolean,
  onDeleteCategory: (categoryId: string) => Promise<boolean> | boolean,
  onClickCategory: (id: string, writeHistory?: boolean) => void,
}

const CategoryItem = ({
  id,
  title,
  index,
  quantity,
  deletable,
  loading,
  active,
  first,
  draggableStateSnapshot,
  isDragDisabled,
  onDeleteCategory,
  onClickCategory,
}: Props): Node => {
  const deleteConfirmText = `Вы уверены, что хотите удалить категорию «${title}»?`;
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const toggleDeleteConfirmation = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) e.preventDefault();
    setDeleteConfirmationVisible(not);
  }, []);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    const res = await onDeleteCategory(id);
    if (res) {
      setDeleteConfirmationVisible(false);
    }
    setDeleting(false);
  }, [id, onDeleteCategory]);

  const handleClickCategory = useCallback(() => {
    onClickCategory(id, true);
  }, [onClickCategory, id]);

  const { isDragging } = draggableStateSnapshot || {};

  const rootClassName = cx(styles.root, {
    active,
    first,
    dragging: isDragging,
    loading: loading || deleting,
  });

  return (
    <div // eslint-disable-line jsx-a11y/click-events-have-key-events
      className={rootClassName}
      onClick={handleClickCategory}
    >
      {/* index для отладки */}
      {!isProduction()
        && <span style={{
          position: 'absolute',
          left: 5,
          color: 'lightgray',
          fontSize: '12px',
        }}
        >
          {String(index)}
        </span>}

      <button
        type="button"
        className={styles.title}
        onClick={handleClickCategory}
      >
        {title}
      </button>
      <span className={styles.filler} />
      <span className={styles.count}>{quantity}</span>

      {isDragDisabled
        ? <span className={styles.reorderPlaceholder} />
        : <ReorderIcon className={styles.reorder} />}

      <span className={styles.delete}>
        {deletable
          && <PlainButtonAsLink onClick={toggleDeleteConfirmation}>
            <PlainTooltip text="Удалить категорию">
              <PlainIcon
                width={12}
                height={12}
                className={styles.iconCross}
                type={iconTypes.LIGHT_CROSS}
                colorType={colorTypes.COLOR_DARKGRAY}
              />
            </PlainTooltip>
          </PlainButtonAsLink>}
      </span>

      {deleteConfirmationVisible
        && <PlainConfirmModal
          messageText={deleteConfirmText}
          cancelButtonTitle="Отмена"
          confirmButtonTitle={deleting ? 'Удаление…' : 'Удалить'}
          confirmButtonProps={{ disabled: deleting }}
          onCancel={toggleDeleteConfirmation}
          onConfirm={handleDelete}
          critical
        />}
    </div>
  );
};

export default CategoryItem;
