import { KnownStatementStatus } from 'app/dataTypes/compensation/statement/status'


export const getStatusColor = (status: string) => {
  if (status === KnownStatementStatus.Draft) return 'dodgerblue'
  if (status === KnownStatementStatus.Consideration) return 'orange'
  if (status === KnownStatementStatus.Processing) return 'magenta'
  if (status === KnownStatementStatus.Approved) return 'limegreen'
  if (status === KnownStatementStatus.Rejected) return 'orangered'
  if (status === KnownStatementStatus.Deleted) return 'slategray'

  return 'gainsboro'
}
