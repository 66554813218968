// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import PlainButtonAsLink from '../../ui-next/plain-button-as-link';
import useVersion from './use-version';

/**
 *  Версия приложения в подвале
 *  Данные из ServiceWorkerLoader
 */


type Props = {
  className?: string
}

const VersionView = ({ className }: Props) => {
  const { buildVersion, buildDate, needUpdateType, handleUpdate } = useVersion();
  const { t } = useTranslation('sw');

  return (
    <div className={className}>
      {t('footer_version_text', { buildVersion, buildDate })}
      {' '}
      {needUpdateType
        && <PlainButtonAsLink
          onClick={handleUpdate}
        >
          {t('update_link', 'Обновить')}
        </PlainButtonAsLink>}
    </div>
  );
};

export default VersionView;
