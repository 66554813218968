// @flow
import React from 'react';

import type { DisplayableData } from '../displayableData';
import DoubleEntries from './DoubleEntries';
import styles from './MultiLineCellDoubleStub.scss';


type Props = {
  data: DisplayableData,
}

const MultiLineCellDoubleStub = ({ data }: Props) => {
  const { singleSum, orderSum } = data;

  return (
    <DoubleEntries>
      <div className={styles.item}>{singleSum}</div>
      <div className={styles.item}>{orderSum}</div>
    </DoubleEntries>
  );
};

export default MultiLineCellDoubleStub;
