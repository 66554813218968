// @flow

export const getPropsId = (dummy?: boolean, prefix?: string = 'header_') => (id: string) => (dummy
  ? {}
  : { id: `${prefix}${id}` }
);

export const getPropsHeader = (dummy?: boolean, prefix?: string = 'header_') => (id: string) => (dummy
  ? {}
  : { headers: `${prefix}${id}` }
);

export const getHeaderAttrsDefault = getPropsHeader();
