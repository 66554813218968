// @flow
import React, { Fragment } from 'react';

import { PlainDateSelector } from 'app/common/ui-next/plain-selector';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import SubHeader from './SubHeader';


type Props = {
  loading: boolean,
  disabled: boolean,
  error?: string,
  getDisabledDay: (day: string) => boolean,
  onDatesChange: (selection: Array<string>, selectionDateFormat: string) => void,
}

const CopyKitFormSameOrg = ({
  loading,
  disabled,
  error,
  getDisabledDay,
  onDatesChange,
}: Props) => {
  return (
    <Fragment>
      <SubHeader>
        {'Выберите дату или даты в текущем заведении, на которую копировать комплект'}
      </SubHeader>

      <PlainDateSelector
        label="На дату"
        loading={loading}
        disabled={disabled}
        getDisabledDay={getDisabledDay}
        onChange={onDatesChange}
        multiple
      />

      {error
        && <PlainHelperText overflowVisible error>
          {error}
        </PlainHelperText>}
    </Fragment>
  );
};

export default CopyKitFormSameOrg;
