import {
  operationAgeFilter,
  operationAgeFilterSelection,
  operationsTypesFilter,
  operationsTypesFilterSelection,
  orgTypesFilter,
  orgTypesFilterSelection,
  orgsFilter,
  orgsFilterSelection,
  periodFilter,
} from 'app/dataProviders/filter-items'


export const filterInit = () => {
  orgTypesFilterSelection.subscribeDataBox(orgsFilter)
  orgTypesFilter.dataReceive()

  return () => {
    orgsFilterSelection.reset()
    orgTypesFilterSelection.reset()
    operationsTypesFilterSelection.reset()
    operationAgeFilterSelection.reset()

    orgsFilter.reset()
    orgTypesFilter.reset()
    periodFilter.reset()
    operationsTypesFilter.reset()
    operationAgeFilter.reset()
  }
}

export const resetToDefaultAll = () => {
  orgsFilterSelection.resetToDefault()
  orgTypesFilterSelection.resetToDefault()
  operationsTypesFilterSelection.resetToDefault()
  operationAgeFilterSelection.resetToDefault()
  periodFilter.resetToDefault()
}
