// @flow
import * as React from 'react';

import styles from './HeaderLvl2.scss';

type Props = {
  children: React.Node,
}

const HeaderLvl2 = ({ children }: Props) => (
  <h2 className={styles.root}>
    {children}
  </h2>
);

export default HeaderLvl2;
