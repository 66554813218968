import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  SALES_TYPES_FILTER_CLEAR,
  SALES_TYPES_FILTER_DELETE,
  SALES_TYPES_FILTER_SET,
  SALES_TYPES_FILTER_UPDATE,
} from 'app/actions/reports/filters/salesTypes';

import * as salesTypesAvailable from 'app/actions/reports/filters/salesTypesAvailable';


export const personalData = {
  [salesTypesAvailable.SALES_TYPE_PERSONAL]: {
    id: salesTypesAvailable.SALES_TYPE_PERSONAL,
    title: 'Личные продажи',
  },
};

export const complexData = {
  [salesTypesAvailable.SALES_TYPE_COMPLEX]: {
    id: salesTypesAvailable.SALES_TYPE_COMPLEX,
    title: 'Комплексное питание',
  },
};

const defaultState = {
  data: {
    ...personalData,
    ...complexData,
  },
  selected: {},
  active: false,
};

const salesTypes = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SALES_TYPES_FILTER_CLEAR: {
      return defaultState;
    }
    case SALES_TYPES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case SALES_TYPES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case SALES_TYPES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default salesTypes;
