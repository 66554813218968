// @flow
import React from 'react';

import { checkEmployeeStatuses } from 'app/common/api/requests/food/check_employee_exists';
import PlainLink from 'app/common/ui-next/plain-link';


type Props = {
  id?: number,
  status: $Values<typeof checkEmployeeStatuses>,
  name?: string,
}

const PhoneExist = ({ id, status, name }: Props) => {
  if (status === checkEmployeeStatuses.EMPLOYEE_THIS_SUPPLIER) {
    return (
      <span>
        {'Этот номер телефона уже используется другим пользователем в вашей базе: '}
        <PlainLink to={`/settings/employees/current/employee/${id || 'none'}`}>
          {name || 'неизвестным'}
        </PlainLink>
      </span>
    );
  }
  if (status === checkEmployeeStatuses.EMPLOYEE_OTHER_SUPPLIER) {
    return (
      <span>
        {'Этот сотрудник уже есть в базе другого поставщика питания'}
      </span>
    );
  }
  if (status === checkEmployeeStatuses.EMPLOYEE_OTHER_DEALER) {
    return (
      <span>
        {'Телефон используется другим пользователем'}
      </span>
    );
  }
  return null;
};

export default PhoneExist;
