// @flow
import React from 'react';
import cn from 'classnames';

import PlainTooltip from 'app/common/ui-next/plain-tooltip';

import styles from './CellTitle.scss';


const STATUS_OFF: 'off' = 'off';
const STATUS_ON: 'on' = 'on';
export const statuses = { STATUS_ON, STATUS_OFF };

type Props = {
  title: string,
  status: $Values<typeof statuses>,
}

const CellTitle = ({ title, status }: Props) => {
  return (
    <div className={styles.root}>
      <PlainTooltip text="Работает. Вполне определенно. Но это не точно">
        <div className={cn(styles.status, styles[status])} />
      </PlainTooltip>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default CellTitle;
