import { ReactNode } from 'react'

import { Header2 } from 'app/common/templates-next/page-template'

import styles from './report-details-root.scss'


type Props = {
  children: ReactNode
  header?: string
}

const ReportDetailsRoot = ({ children, header }: Props) => {
  return (
    <>
      {header
        && <Header2 className={styles.header}>
          {header}
        </Header2>}
      {children}
    </>
  )
}

export default ReportDetailsRoot
