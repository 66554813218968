import { useCallback, useState } from 'react'
import cn from 'classnames/bind'

import PlainIcon, { iconColor, iconType } from 'app/common/ui-next/plain-icon'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import styles from './OrgListItem.scss'


const cx = cn.bind(styles)

type Props = {
  className?: string
  ID: string
  title: string
  disabled?: boolean
  onCrossClick?: (ID: string) => Promise<boolean>
}

const OrgListItem = ({
  className,
  ID,
  title,
  disabled,
  onCrossClick,
}: Props) => {
  const [pending, setPending] = useState(false)

  const onCrossClickRef = useCallbackRef(onCrossClick)

  const handleClick = useCallback(async () => {
    setPending(true)
    const res = await onCrossClickRef.current?.(ID)
    if (!res) setPending(false)
  }, [onCrossClickRef, ID])

  return (
    <li className={cx(styles.root, { pending }, className)}>
      <span className={cx({ pending })}>{title}</span>
      {!pending
        && <PlainButtonAsLink onClick={handleClick} disabled={pending || disabled}>
          <PlainIcon
            type={iconType.LIGHT_CROSS}
            color={iconColor.COLOR_DARKGRAY}
            width={14}
            height="auto"
            disabled={pending}
          />
        </PlainButtonAsLink>}
    </li>
  )
}

export default OrgListItem
