// @flow
import React, { type Node } from 'react';

import ImportXLSContext from './ImportXLSContext';
import useImportedFileData from './useImportedFileData';


type Props = {
  children: Node
}

const ImportXLSData = ({ children }: Props) => {
  const [importedFileData, api, modify] = useImportedFileData();

  const value = {
    content: importedFileData,
    api,
    modify,
  };

  return (
    <ImportXLSContext.Provider value={value}>
      {children}
    </ImportXLSContext.Provider>
  );
};

export default ImportXLSData;
