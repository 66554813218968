// @flow
import type { CashBoxResult } from 'app/dataTypes/reports/finances/CashBox.types.js';

import type { Request } from '../index';
import type { ReportsDataOrderPurchasesResult } from './data.orders_purchases';
import type { ReportsDataFinancesEMoneyLegacyResult } from './data.finances_e_money_legacy';
import type { ReportsDataFinancesEMoneyResult } from './data.finances_e_money';
import type { ReportsDataAccountingCustomersResult } from './data.accounting_customers';
import type { ReportsDataPurchasesByFundsResult } from './data.purchases_by_funds';
import type { ReportsDataFinancesBySellTypeResult } from './data.finances_by_sale_types';
import type { ReportsDataBalancesResult } from './data.balances';
import type { ReportsDataPurchasesResult } from './data.purchases';
import type { ReportsDataPurchasesByGroupComplexes2Result } from './data.purchases_by_group_complexes2';
import type { ReportsDataPurchasesByPaymentModeResult } from './data.purchases_by_payment_mode';

// DEPRECATED

type ReportsDataParams = {
  report_id: number,
}

// Далее запросы, отличаются только типы данных

const request = [
  'reports/data',
  'get',
  ['data', 'data'],
];

export const GET_REPORTS_DATA_BALANCES: Request<
  ReportsDataParams,
  ReportsDataBalancesResult,
  void
> = request;

export const GET_REPORTS_DATA_PURCHASES: Request<
  ReportsDataParams,
  ReportsDataPurchasesResult,
  void
> = request;

export const GET_REPORTS_DATA_PURCHASES_BY_GROUP_COMPLEXES_2: Request<
  ReportsDataParams,
  ReportsDataPurchasesByGroupComplexes2Result,
  void
> = request;

export const GET_REPORTS_DATA_PURCHASES_BY_PAYMENT_MODE: Request<
  ReportsDataParams,
  ReportsDataPurchasesByPaymentModeResult,
  void
> = request;

export const GET_REPORTS_DATA_ORDER_PURCHASES: Request<
  ReportsDataParams,
  ReportsDataOrderPurchasesResult,
  void
> = request;

export const GET_REPORTS_DATA_FINANCES_E_MONEY_LEGACY: Request<
  ReportsDataParams,
  ReportsDataFinancesEMoneyLegacyResult,
  void
> = request;

export const GET_REPORTS_DATA_FINANCES_E_MONEY: Request<
  ReportsDataParams,
  ReportsDataFinancesEMoneyResult,
  void
> = request;

export const GET_REPORTS_DATA_ACCOUNTING_CUSTOMERS: Request<
  ReportsDataParams,
  ReportsDataAccountingCustomersResult,
  void
> = request;

export const GET_REPORTS_DATA_PURCHASES_BY_FUNDS: Request<
  ReportsDataParams,
  ReportsDataPurchasesByFundsResult,
  void
> = request;

export const GET_REPORTS_DATA_FINANCES_BY_SELL_TYPE: Request<
  ReportsDataParams,
  ReportsDataFinancesBySellTypeResult,
  void
> = request;

export const GET_REPORTS_DATA_FINANCES_CAFETERIA_CASH: Request<
  ReportsDataParams,
  CashBoxResult,
  void
> = request;

// 422 report error - при формировании произошла ошибка
// 422 report is not ready - отчет еще формируется
