
// @flow
import type { Request } from '../../index';

type MenuPresenceUrlParams = {
  orgId: number | string,
}

export const GET_MENU_PRESENCE: Request<
  void,
  boolean,
  MenuPresenceUrlParams
> = [
  'food/school/{orgId}/menu_presence',
  'get',
  ['data', 'presence'],
];
