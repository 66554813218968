// @flow
import React, { type Node } from 'react';
import cn from 'classnames/bind';

import multiLineCellTypes from './multiLineCellTypes';
import styles from './MultiLineContainer.scss';


const cx = cn.bind(styles);

type Props = {
  className?: string,
  children: Array<Node>,
  type?: $Values<typeof multiLineCellTypes>,
  bottomSpace?: boolean,
  borderRight?: boolean,
  restrictWidth?: number,
}

const MultiLineContainer = ({
  className,
  children,
  type,
  bottomSpace,
  borderRight,
  restrictWidth,
}: Props) => {
  const classes = {
    root: true,
    totalType: type === multiLineCellTypes.TOTAL,
    bottomSpace,
    borderRight,
  };

  const styleProps = (
    typeof restrictWidth === 'number'
      ? { style: { width: restrictWidth } }
      : {}
  );

  return (
    <div {...styleProps} className={cx(className, classes)}>
      {children}
    </div>
  );
};

export default MultiLineContainer;
