// @flow
import path from 'ramda/es/path';
import api from 'app/actions/api';
import action from 'app/common/lib/action';

import { stringListToObject } from 'app/common/lib/object';


export const SUBSIDY_FILTER_REQUEST = 'reports/SUBSIDY_FILTER_REQUEST';
export const SUBSIDY_FILTER_SUCCESS = 'reports/SUBSIDY_FILTER_SUCCESS';
export const SUBSIDY_FILTER_FAILURE = 'reports/SUBSIDY_FILTER_FAILURE';
export const SUBSIDY_FILTER_CLEAR = 'reports/SUBSIDY_FILTER_CLEAR';
export const SUBSIDY_FILTER_DELETE = 'reports/SUBSIDY_FILTER_DELETE';
export const SUBSIDY_FILTER_SET = 'reports/SUBSIDY_FILTER_SET';
export const SUBSIDY_FILTER_UPDATE = 'reports/SUBSIDY_FILTER_UPDATE';


export const subsidiesFilterFetch = () => async (dispatch: Function): Object => {
  dispatch(action(SUBSIDY_FILTER_REQUEST));
  try {
    const res = await api('food/allowances', 'get', { params: { dealer: true, order_type: 'cafeteria' } });
    const allowances = path(['data', 'allowances'], res);
    dispatch(action(SUBSIDY_FILTER_SUCCESS, stringListToObject(allowances)));
    return allowances;
  } catch (error) {
    dispatch(action(SUBSIDY_FILTER_FAILURE, {
      err: 'Не удалось получить данные фильтра по категориям льгот',
      error,
    }));
    return null;
  }
};
