// @flow
import React, { type Node } from 'react';

import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';

import styles from './EnterpriseTitle.scss';


type Props = {
  children: Node,
  forGuestIcon?: boolean,
}

const EnterpriseTitle = ({ children, forGuestIcon }: Props): Node => {
  return (
    <span className={styles.root}>
      <span className={styles.text}>{children}</span>
      {forGuestIcon
        && <PlainIcon
          width={20}
          height="auto"
          type={iconTypes.MEN}
          color={colorTypes.COLOR_PRIMARY}
          className={styles.icon}
        />}
    </span>
  );
};

export default EnterpriseTitle;
