import Link from 'app/common/lib/link'
import Tabs, { TabItem } from 'app/common/ui/Tabs'

import { WithRights } from 'app/rights/Rights'
import { rootRights, specificRights } from 'app/rights'

import * as employeeTypes from './employeeTypes'


const adminRights = [rootRights.SU, specificRights.SU] as const

type Props = {
  type: typeof employeeTypes[keyof typeof employeeTypes]
  baseUrl: string,
}

const EmployeesTabs = ({ type, baseUrl }: Props) => {
  return (
    <Tabs>
      <TabItem active={type === employeeTypes.ATWORK}>
        <Link to={`${baseUrl}/${employeeTypes.ATWORK}`}>
          {'Сотрудники в ОУ'}
        </Link>
      </TabItem>

      <WithRights pathTo={adminRights}>
        <TabItem active={type === employeeTypes.OFFICE}>
          <Link to={`${baseUrl}/${employeeTypes.OFFICE}`}>
            {'Сотрудники в офисе ПП'}
          </Link>
        </TabItem>
      </WithRights>
    </Tabs>
  )
}

export default EmployeesTabs
