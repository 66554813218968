import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';
import api from 'app/actions/api';

export const PROPS_SUBMIT_REQUEST = 'props/PROPS_SUBMIT_REQUEST';
export const PROPS_SUBMIT_SUCCESS = 'props/PROPS_SUBMIT_SUCCESS';
export const PROPS_SUBMIT_FAILURE = 'props/PROPS_SUBMIT_FAILURE';

export const propsSubmitRequest = () => ({
  type: PROPS_SUBMIT_REQUEST,
});

export const propsSubmitSuccess = payload => ({
  type: PROPS_SUBMIT_SUCCESS,
  payload,
});

export const propsSubmitFailure = payload => ({
  type: PROPS_SUBMIT_FAILURE,
  payload,
});

export const propsSubmit = () => async (dispatch, getState) => {
  const { data } = getState().props;
  dispatch(propsSubmitRequest());
  try {
    const res = await api('food/requisites', 'post', { data });
    const submited = omit(['result'], prop('data', res)) || {};
    dispatch(propsSubmitSuccess(submited));
    return true;
  } catch (error) {
    dispatch(propsSubmitFailure({ err: 'Не удалось сохранить реквизиты', error }));
    return false;
  }
};
