// @flow
import { useState, useCallback, useEffect } from 'react';
import prop from 'ramda/es/prop';

import { api } from 'app/api';

import { GET_ORG_GENERAL_SETTINGS } from 'app/common/api/requests/food/schools/general_settings';


export default function useFoodModeFlag(orgId: number) {
  const [foodMode, setFoodMode] = useState<boolean>(false);

  const requestGeneralSettings = useCallback(async () => {
    const res = await api.request(GET_ORG_GENERAL_SETTINGS, {
      error: 'Не удалось получить данные режима питания',
      requestPathParams: {
        orgId,
      },
    });

    if (res) {
      const foodModeEnable = prop('use_food_mode', res);
      setFoodMode(foodModeEnable);
    }
  }, [orgId]);

  useEffect(() => {
    requestGeneralSettings();
  }, [requestGeneralSettings]);

  return foodMode;
}
