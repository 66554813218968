// @flow
import React, { useCallback } from 'react';

import Profile, { defaultSection } from 'app/components/Profile';

import getBaseUrl from './getBaseUrl';


const baseUrl = getBaseUrl();
export const baseDefaultSection = getBaseUrl({ section: defaultSection });

type Props = {
  match: {
    params: {
      section?: string,
    },
  },
  history: {
    push: (path: string) => void,
    replace: (path: string) => void,
  }
}

const ProfilePage = ({ match, history }: Props) => {
  const { params: { section } } = match;
  const { push } = history;

  const redirectToDefault = useCallback(() => {
    push(baseDefaultSection);
  }, [push]);

  const currentSection = section || '';

  return (
    <Profile
      baseUrl={baseUrl}
      currentSection={currentSection}
      redirectToDefault={redirectToDefault}
    />
  );
};

export default ProfilePage;
