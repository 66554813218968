import mapProps from 'recompose/withProps';
import styles from './Main.scss';


export default mapProps((props) => {
  const { src } = props;
  return (/empty.png$/.test(src)
    ? props
    : { ...props, className: styles.logo });
})('img');
