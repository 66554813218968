// @flow
import React, { Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { PlainGroup, PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainInput, { PlainInputPhoneCC, PlainInputSNILS } from 'app/common/ui/PlainInput';
import PlainGenderSelect from 'app/common/ui/PlainGenderSelect';
import PlainInputDate from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import { dateFormat } from 'app/actions/employees/hireform';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import type { OptionsState } from './useHireFormOptions';
import UserExist from './Existing/UserExist';
import PhoneExist from './Existing/PhoneExist';
import EmailExist from './Existing/EmailExist';
import SnilsExist from './Existing/SnilsExist';


type Props = {
  options: OptionsState,
  loading: boolean,
  onChangeField: (e: SyntheticEvent<HTMLInputElement>) => void,
  onChangeDateField: (date: string, id?: string) => void
}

const Step1Form = ({ options, loading, onChangeField, onChangeDateField }: Props) => {
  const form = useSelector(state => state.employees.hireform, shallowEqual);
  const {
    required,
    errorRequired,
    errorUser,
    errorPhone,
    errorEmail,
    errorSnils,
    errorBirhdate,
    userExist,
    snilsExist,
    phoneExist,
    emailExist,
  } = options;

  return (
    <Fragment>
      <PlainGroup
        header="Основная информация"
        error={
          errorRequired
          || errorUser
          || errorSnils
          || (userExist && <UserExist {...userExist} />)
          || (snilsExist && <SnilsExist {...snilsExist} />)
          || errorBirhdate
        }
        isError={!!(errorRequired || errorUser || errorSnils || snilsExist || userExist)}
      >
        <PlainColumns>

          <PlainColumn div={3}>
            <PlainInput
              label="Фамилия"
              name="lastname"
              value={form.lastname}
              onChange={onChangeField}
              disabled={loading}
              isError={(required.includes('lastname') && errorRequired) || !!errorUser || userExist}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Имя"
              name="firstname"
              value={form.firstname}
              onChange={onChangeField}
              disabled={loading}
              isError={(required.includes('firstname') && errorRequired) || !!errorUser || userExist}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="Отчество"
              name="middlename"
              value={form.middlename}
              onChange={onChangeField}
              disabled={loading}
              isError={(required.includes('middlename') && errorRequired) || !!errorUser || userExist}
            />
          </PlainColumn>

          <PlainColumn div={4}>
            <PlainGenderSelect
              label="Пол"
              name="gender"
              value={form.gender}
              onChange={onChangeField}
              disabled={loading}
            />
          </PlainColumn>

          <PlainColumn div={4}>
            <PlainLabel htmlFor="birthdate" skipUpperSpace>
              {'Дата рождения'}
            </PlainLabel>

            <PlainInputDate
              id="birthdate"
              value={form.birthdate || ''}
              onDateChange={onChangeDateField}
              dateFormat={dateFormat}
              disabled={loading}
              error={!!(
                (required.includes('birthdate') && errorRequired)
                || errorUser
                || userExist
                || errorBirhdate
              )}
              calendarCloseOnClick
            />

            <PlainHelperText oldUpperSpace>
              &nbsp;
            </PlainHelperText>
          </PlainColumn>

          <PlainColumn div={4}>
            <PlainInputSNILS
              label="Номер СНИЛС"
              value={form.snils || ''}
              name="snils"
              onChange={onChangeField}
              disabled={loading}
              isError={(required.includes('snils') && !!errorRequired) || !!snilsExist || snilsExist}
            />
          </PlainColumn>

        </PlainColumns>
      </PlainGroup>

      <PlainGroup
        header="Контакты"
        error={
          errorPhone
          || errorEmail
          || (!!phoneExist && <PhoneExist {...phoneExist} />)
          || (!!emailExist && <EmailExist {...emailExist} />)
        }
        isError={!!errorPhone || !!errorEmail || !!phoneExist || !!emailExist}
      >
        <PlainColumns>

          <PlainColumn div={3}>
            <PlainInputPhoneCC
              label="Мобильный телефон"
              name="phone"
              value={form.phone}
              onChange={onChangeField}
              disabled={loading}
              isError={errorPhone || !!phoneExist}
            />
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="E-mail"
              name="email"
              value={form.email}
              onChange={onChangeField}
              disabled={loading}
              isError={errorEmail || !!emailExist}
            />
          </PlainColumn>

        </PlainColumns>
      </PlainGroup>

      <PlainGroup
        header="Другая информация"
      >
        <PlainColumns>

          <PlainColumn div={4}>
            <PlainLabel htmlFor="invite_date" skipUpperSpace>
              {'Дата приема'}
            </PlainLabel>

            <PlainInputDate
              id="invite_date"
              value={form.invite_date || ''}
              onDateChange={onChangeDateField}
              dateFormat={dateFormat}
              disabled={loading}
              calendarCloseOnClick
            />

            <PlainHelperText oldUpperSpace>
              &nbsp;
            </PlainHelperText>
          </PlainColumn>

          <PlainColumn div={3}>
            <PlainInput
              label="№ приказа"
              name="order_num"
              value={form.order_num}
              onChange={onChangeField}
              disabled={loading}
            />
          </PlainColumn>

          <PlainColumn div={4}>
            <PlainLabel htmlFor="order_date" skipUpperSpace>
              {'Дата приказа'}
            </PlainLabel>

            <PlainInputDate
              id="order_date"
              value={form.order_date || ''}
              onDateChange={onChangeDateField}
              dateFormat={dateFormat}
              disabled={loading}
              calendarCloseOnClick
            />

            <PlainHelperText oldUpperSpace>
              &nbsp;
            </PlainHelperText>

          </PlainColumn>

        </PlainColumns>
      </PlainGroup>
    </Fragment>
  );
};

export default Step1Form;
