import { filter } from 'ramda'
import { useMemo } from 'react'


const mustBeArray = (s: Array<string> | string | undefined) => {
  if (!s) return undefined
  if (typeof s === 'string') return [s]
  return s
}

export type Job = {
  orgIDFrom?: string,
  orgIDTo?: string,
  dateFrom: string,
  dateTo: string,
  buildingIDFrom?: string,
  buildingIDTo?: string,
  salePointIDFrom?: string, // уникальные для разных школ, можно не использовать school_id_(from/to) и (from/to)_building_id
  salePointIDTo?: string, // уникальные для разных школ, можно не использовать school_id_(from/to) и (from/to)_building_id
  items: boolean,
  complexes: boolean,
  copyComplexPhoto?: boolean,
  copySettings?: boolean, // копировать настройки продукции/комлпектов
  contentOnly?: boolean, // используется только с комплектами, копирование только состава комплектов, без постановки в меню
  changeSoldItems?: boolean, // используется только с комплектами, внести изменения в состав проданных комплектов на выбранную дату
  keepComplexPrice?: boolean, // не изменять цену комплектов при копировании
  copyExtendedSettings?: boolean,
  keepGrocerySetPrice?: boolean, // не изменять стоимость продуктового набора

  complexesIDs?: Array<string>, // опционально, для указания конкретных комплектов
  sourceComplexID?: string, // из комплекта (комплект -> комплект)
  targetComplexID?: string, // в комплект (комплект -> комплект)
}

export type Options = {
  orgIDFrom?: string
  orgIDTo?: string | Array<string>
  dateFrom: string
  dateTo: string | Array<string>
  buildingIDFrom?: string
  buildingIDTo?: string
  salePointIDFrom?: string
  salePointIDTo?: string
  items?: boolean
  complexes?: boolean
  copyComplexPhoto?: boolean
  copySettings?: boolean
  contentOnly?: boolean
  changeSoldItems?: boolean
  keepComplexPrice?: boolean
  copyExtendedSettings?: boolean
  keepGrocerySetPrice?: boolean
  complexesIDs?: string | Array<string>
  sourceComplexID?: string
  targetComplexID?: string
}

// по умолчанию для копирования комплектов в заведениях
const defaultOptions: Partial<Job> = {
  items: false,
  complexes: true,
  contentOnly: true,
}

export default function useJobs({
  orgIDFrom,
  orgIDTo,
  dateFrom,
  dateTo,
  buildingIDFrom,
  buildingIDTo,
  salePointIDFrom,
  salePointIDTo,
  items,
  complexes,
  copyComplexPhoto,
  copySettings,
  contentOnly,
  changeSoldItems,
  keepComplexPrice,
  copyExtendedSettings,
  keepGrocerySetPrice,
  complexesIDs,
  sourceComplexID,
  targetComplexID,
}: Options): Array<Job> {
  const createdJobs = useMemo(() => {
    const orgsToMultiple = orgIDTo instanceof Array ? orgIDTo : [orgIDTo]
    const datesToMultiple = dateTo instanceof Array ? dateTo : [dateTo]
    const commonOptions: Job = {
      ...defaultOptions,
      ...filter(val => (val !== undefined), {
        orgIDFrom,
        orgIDTo: orgsToMultiple[0],
        dateFrom,
        dateTo: datesToMultiple[0],
        buildingIDFrom,
        buildingIDTo,
        salePointIDFrom,
        salePointIDTo,
        items,
        complexes,
        copyComplexPhoto,
        copySettings,
        contentOnly,
        changeSoldItems,
        keepComplexPrice,
        copyExtendedSettings,
        keepGrocerySetPrice,
        complexesIDs: mustBeArray(complexesIDs),
        sourceComplexID,
        targetComplexID,
      }) as Job,
    }
    const result = new Set<Job>()
    datesToMultiple.forEach((date) => {
      orgsToMultiple.forEach((org) => {
        result.add({ ...commonOptions, dateTo: date, orgIDTo: org })
      })
    })

    return Array.from(result.values())
  }, [
    orgIDFrom,
    orgIDTo,
    dateFrom,
    dateTo,
    buildingIDFrom,
    buildingIDTo,
    salePointIDFrom,
    salePointIDTo,
    items,
    complexes,
    copyComplexPhoto,
    copySettings,
    contentOnly,
    changeSoldItems,
    keepComplexPrice,
    copyExtendedSettings,
    keepGrocerySetPrice,
    complexesIDs,
    sourceComplexID,
    targetComplexID,
  ])

  return createdJobs
}
