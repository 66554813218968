import cn from 'classnames/bind'

import Sticky from 'app/common/templates-next/sticky'
import { useHoverByClass } from 'app/common/ui-components/table'
import { ReportCorrectionsData } from 'app/dataTypes/reports/data'

import HScrollbarsWShadow, {
  useSyncScrolls,
  HScrollContainer,
  HScrollableContent,
} from 'app/common/ui-components/h-scrollbars-w-shadow'

import LeftTable from './LeftTable'
import MainTable from './MainTable'

import styles from './TableLayout.scss'


export type Ref = HTMLInputElement
const cx = cn.bind(styles)
const sameRowClassBase = 'row_'

type Props = {
  data: ReportCorrectionsData | null
}

const TableLayout = ({ data }: Props) => {
  const [
    topScrollerProps,
    middleScrollerProps,
    bottomScrollerProps,
  ] = useSyncScrolls()
  const [handleMouseEnter, handleMouseLeave] = useHoverByClass(sameRowClassBase)

  if (!data) return null
  const { headers, operationType, orgs, total } = data

  return (
    <>
      <Sticky>
        {(ref, sticky, shadow) => (
          <HScrollContainer ref={ref} className={cx({ shadow, sticky })}>

            <LeftTable
              className={cx(styles.columnFixed, styles.height)}
            />

            <HScrollableContent>
              <HScrollbarsWShadow
                hideVerticalScrollBar
                hideHorizontalScrollBar
                absolutelyHideHorizontal_FixFF
                {...topScrollerProps}
              >
                <MainTable
                  headers={headers}
                  className={cx(styles.columnScrollable, styles.height)}
                />

              </HScrollbarsWShadow>
            </HScrollableContent>
          </HScrollContainer>
        )}
      </Sticky>

      <div>
        <HScrollContainer>

          <LeftTable
            className={styles.columnFixed}
            data={orgs}
            operationType={operationType}
            sameRowClassBase={sameRowClassBase}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />

          <HScrollableContent>
            <HScrollbarsWShadow
              className={styles.shadowContainer}
              hideVerticalScrollBar
              hideHorizontalScrollBar
              absolutelyHideHorizontal_FixFF
              {...middleScrollerProps}
            >
              <MainTable
                className={styles.columnScrollable}
                headers={headers}
                orgs={orgs}
                operationType={operationType}
                sameRowClassBase={sameRowClassBase}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />

            </HScrollbarsWShadow>
          </HScrollableContent>
        </HScrollContainer>

        <Sticky stickyBottom>
          {(ref, stuck, shadow) => (
            <HScrollContainer ref={ref} className={cx({ shadowTop: shadow, borderTop: !stuck })}>

              <LeftTable
                className={styles.columnFixed}
                total={total}
              />

              <HScrollableContent>
                <HScrollbarsWShadow
                  hideVerticalScrollBar
                  {...bottomScrollerProps}
                >
                  <MainTable
                    className={styles.columnScrollable}
                    total={total}
                  />
                </HScrollbarsWShadow>
              </HScrollableContent>

            </HScrollContainer>
          )}
        </Sticky>
      </div>
    </>
  )
}

export default TableLayout
