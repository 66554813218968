/* eslint-disable ft-flow/no-types-missing-file-annotation */
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import petrovich from 'petrovich';
import type { HOC } from 'recompose';

import Loader from 'app/common/components/Loader';
import type { ImageParamsType } from 'app/common/components/ImagePrepare/ImagePrepare';
import { showConfirmation, hideConfirmation } from 'app/common/actions/confirmation';
import { popup } from 'app/common/actions/popup';
import { employeeSubmit } from 'app/actions/employees/employee/edit';
import { getFIO } from 'app/common/lib/names';
import { employeeFetch, employeeClear } from 'app/actions/employees/employee';

import selectIsFormNotModified from './selectIsFormNotModified';


type Props = {
  id: string,
};

const enhanceGeneral: HOC<*, Props> = compose(
  connect(
    (state, props) => ({
      isFormNotModified: selectIsFormNotModified(state, props),
      loading: state.employees.employee.options.loading,
      photo: state.employees.employee.employeeform.photo_url,
      employee: state.employees.employee.fields,
      saving: state.employees.employee.options.sending,
    }),
  ),

  lifecycle({
    componentDidMount() {
      const { dispatch, personId } = this.props;
      dispatch(employeeFetch(personId));
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(employeeClear());
    },
  }),

  withHandlers({
    handleSaveImage: ({ dispatch, personId, photo }) => async (imageParams: ImageParamsType) => {
      const res = await dispatch(employeeSubmit(personId, { photo: imageParams }));
      if (res) {
        dispatch(popup(`Фотография успешно ${photo ? 'изменена' : 'добавлена'}`));
      }
      return res;
    },
    handleDeleteImage: ({ dispatch, employee, personId }) => (e) => {
      e.preventDefault();
      const name = getFIO(petrovich({ ...employee.name, gender: employee.gender }, 'genitive'));
      const onDelete = async (event) => {
        event.preventDefault();
        dispatch(hideConfirmation());
        const res = await dispatch(employeeSubmit(personId, { photo: null }));
        if (res) {
          popup('Изображение успешно удалено');
        }
      };
      const onCancel = (event) => {
        event.preventDefault();
        dispatch(hideConfirmation());
      };
      dispatch(showConfirmation({
        text: `Вы уверены? Удалить фотографию ${name}?`,
        buttons: [{
          value: 'Удалить',
          error: true,
          onClick: onDelete,
          key: 'remove',
        }, {
          value: 'Отмена',
          outline: true,
          onClick: onCancel,
          key: 'cancel',
        }],
      }));
    },
  }),

  branch(
    props => props.loading,
    renderComponent(Loader),
  ),
);

export default enhanceGeneral;
