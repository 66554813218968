// @flow
import React, { Fragment, useMemo, useCallback, useRef } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import reduce from 'ramda/es/reduce';
import take from 'ramda/es/take';

import { Table, CheckStateProvider, useCopyColumnsWidths } from 'app/common/ui-components/table';
import CSSSticky from 'app/common/ui-components/css-sticky';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import Loading from 'app/common/components/Loader';
import { type Settings } from 'app/reducers/settings/orgsOrders';

import OrgsOrdersTableTHead from './OrgsOrdersTableTHead';
import OrgsOrderTableRow from './OrgsOrderTableRow';
import type { Item } from './useOrgsOrdersTableItems';
import type { Columns } from './columns';

import styles from './OrgsOrdersTable.scss';


type Nodes = Array<?HTMLTableRowElement>

type Props = {
  orgId: number,
  items: Array<Item>,
  loading: boolean,
  titles: { [key: $Keys<Settings>]: string },
  columnIds: Columns,
}

const OrgsOrdersTable = ({ orgId, items, loading, titles, columnIds }: Props) => {
  const rowNodes = useRef<Nodes>([]);
  const nothing = isEmpty(items);

  const updateKey = useMemo(() => ([]), [items, columnIds]); // eslint-disable-line react-hooks/exhaustive-deps
  const [refFrom, refTo] = useCopyColumnsWidths(updateKey);

  const updateRowNode = useCallback((el: ?HTMLTableRowElement, index: number) => {
    rowNodes.current[index] = el;
  }, []);

  const calcTopShift = useCallback((index: number) => {
    const elements = rowNodes.current;
    const overlyingElements = take(index, elements);
    const heightsSum = reduce((acc, el) => {
      if (el) {
        const { top, bottom } = el.getBoundingClientRect();
        const height = bottom - top;
        return acc + height;
      }
      return acc;
    }, 0, overlyingElements);

    return heightsSum;
  }, []);


  return (
    <Fragment>
      <CSSSticky
        number={4}
        className={styles.stickable}
        shadowClassName={styles.shadow}
      >
        <div className={styles.additionalBorder} />
        <Table className={styles.table}>
          <thead>
            <OrgsOrdersTableTHead
              columnIds={columnIds}
              reference={refTo}
              titles={titles}
            />
          </thead>
        </Table>

        {loading
          && !nothing
          && <Loading className={styles.loadingOverContent} />}
      </CSSSticky>

      {nothing
        && loading
        && <Loading />}

      {nothing
        && !loading
        && <EmptyWarn>{'Нет записей, удовлетворяющих условиям'}</EmptyWarn>}

      {!nothing
        && <Table className={styles.table}>
          <thead>
            <OrgsOrdersTableTHead
              columnIds={columnIds}
              reference={refFrom}
              titles={titles}
              hidden
            />
          </thead>
          <tbody className={styles.rowsContainer}>
            <CheckStateProvider>
              {items.map((item, index) => (
                <OrgsOrderTableRow
                  key={item.id}
                  index={index}
                  orgId={orgId}
                  disabled={loading}
                  columnIds={columnIds}
                  updateRowNode={updateRowNode}
                  node={rowNodes.current[index]}
                  requestTopShift={calcTopShift}
                  item={item}
                />
              ))}
            </CheckStateProvider>
          </tbody>
        </Table>}
    </Fragment>
  );
};

export default OrgsOrdersTable;
