
import MaskedInput from 'react-maskedinput';
import withProps from 'recompose/withProps';

import PlainInput from './PlainInput';


const w34validate = char => /[a-fA-F0-9]/.test(char);
const w34transform = char => char.toUpperCase();

const PlainInputW34 = withProps({
  component: MaskedInput,
  mask: 'XX XX XX XX',
  formatCharacters: {
    X: {
      validate: w34validate,
      transform: w34transform,
    },
  },
})(PlainInput);

export default PlainInputW34;
