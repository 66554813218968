import React from 'react';
import withHandlers from 'recompose/withHandlers';
import classNames from 'classnames/bind';

import styles from './NumberSwitcher.scss';

const cx = classNames.bind(styles);

// Пока только для использования в настройках
const NumberSwitcher = ({ title, data, name, current, handleChange }) => (<div>
  {title}
  {':'}
  <br />
  {data.map(item => (<Item
    key={item}
    value={item}
    name={name}
    onClick={handleChange}
    className={cx({ link: true, active: item === current })}
  />))}
</div>);

// NumberSwitcher.propTypes = {
//   title: React.PropTypes.string.isRequired,
//   data: React.PropTypes.array.isRequired,
//   current: React.PropTypes.number.isRequired,
//   name: React.PropTypes.string.isRequired,
//   handleChange: React.PropTypes.func.isRequired,
// };


const enhance = withHandlers({
  onClick: props => (event) => {
    event.preventDefault();
    if (typeof props.onClick === 'function') {
      props.onClick({ [props.name]: props.value });
    }
  },
});

const Item = enhance(({ value, onClick, className }) => (<a
  href={`#${value}`}
  onClick={onClick}
  className={className}
>
  {value}
</a>));

export default NumberSwitcher;
