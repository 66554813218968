// @flow
import React, { type Ref } from 'react';

import { Tr, Td } from 'app/common/ui-components/table';

import type { SchoolDisplayableItem } from './useActualSchoolsData';
import { MultiLineCell, MultiLineCellDouble, CellRestriction, restrictions } from '../MultiLineCell';


type Props = {
  reference: Ref<'tbody'>,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  data: Array<SchoolDisplayableItem>,
  sameRowClassName: string,
  showDuty?: boolean,
  sellTypeGroupped?: boolean,
}

const RightColumnBody = ({
  reference,
  onMouseEnter,
  onMouseLeave,
  data,
  sameRowClassName,
  showDuty,
  sellTypeGroupped,
}: Props) => {
  const Cell = sellTypeGroupped ? MultiLineCellDouble : MultiLineCell;
  const cellRestriction = sellTypeGroupped ? restrictions.WIDE : restrictions.TIGHT;

  return (
    <tbody ref={reference}>
      {data.map(({ id, total }, i) => (
        <Tr
          key={id}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={`${sameRowClassName}${i}`}
        >
          <Td
            stripPadding
            headers={`column_total row_school_${i}`}
          >
            <CellRestriction type={cellRestriction}>
              <Cell
                data={total}
                showDuty={showDuty}
              />
            </CellRestriction>
          </Td>
        </Tr>
      ))}
    </tbody>
  );
};

export default RightColumnBody;
