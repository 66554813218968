import cn from 'classnames/bind'
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import PlainFieldset from 'app/common/ui-next/plain-fieldset'
import useOrgAllowances from 'app/dataProviders/subsidy/allowances/hooks/useOrgAllowances'
import { Loading } from 'app/common/templates-next/page-template'

import { reduce } from 'ramda'
import styles from './CategoriesCheckBoxes.scss'


const cx = cn.bind(styles)

type Props = {
  locked?: boolean
  orgID: string
  subsidyType: 'common' | 'personal'
  app: 'camp' | 'cafeteria'
  setLoading: Dispatch<SetStateAction<boolean>>
}

const CategoriesCheckBoxes = ({
  locked,
  orgID,
  subsidyType,
  app,
  setLoading,
}: Props) => {
  const [checked, setChecked] = useState<Set<string>>(new Set())

  const [allowancesAvailable, loadingAvailable] = useOrgAllowances({ orgID, app, subsidyType })
  const [allowancesUsed, loadingUsed] = useOrgAllowances({ orgID, app, used: true, subsidyType })

  useEffect(() => {
    setChecked(new Set(allowancesUsed.map(({ ID }) => ID)))
  }, [allowancesUsed])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked: nextItemState, value } = e.currentTarget
    setChecked((prevState) => {
      const nextState = new Set(prevState)
      if (nextItemState) {
        nextState.add(value)
      } else {
        nextState.delete(value)
      }
      return nextState
    })
  }, [])

  const loading = loadingAvailable || loadingUsed

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  const [remove, add] = useMemo(() => {
    const ad = new Set<string>(checked)
    const rm = reduce((acc, { ID }) => {
      if (ad.has(ID)) {
        ad.delete(ID)
      } else {
        acc.add(ID)
      }
      return acc
    }, new Set<string>(), allowancesUsed)
    return [rm, ad]
  }, [allowancesUsed, checked])

  if (loading && !allowancesAvailable.length) {
    return (
      <div className={styles.root}>
        <Loading />
      </div>
    )
  }

  return (
    <PlainFieldset
      legend="Категории льгот:"
      name="categories"
      className={styles.root}
      legendClassName={styles.legend}
    >
      {allowancesAvailable.map(({ ID, title, deletable }) => (
        <div
          key={ID}
          className={cx(styles.item, { remove: remove.has(ID), add: add.has(ID) })}
        >
          <PlainCheckbox
            label={title}
            value={ID}
            name="categories"
            disabled={!deletable || locked || loading}
            onChange={handleChange}
            checked={checked.has(ID)}
          />
        </div>
      ))}
    </PlainFieldset>
  )
}

export default CategoriesCheckBoxes
