// @flow
import React, { useCallback } from 'react';
import { Redirect } from 'react-router';

import OrgsDishes, { dataKey } from 'app/components/OrgsList/OrgsDishes';
import { withDishesOrgDishesRights, withDishesCategoriesRights } from 'app/containers/rights';
import { anyRights } from 'app/common/containers/withRights';

import OrgsPageTemplateOld from '../OrgsPageTemplateOld';
import AccessDenied from '../AccessDenied';
import getBaseUrl from '../getBaseUrl';


export { dataKey };

type Props = {
  match: {
    params: {
      orgId: string,
      categoryId?: string,
      process?: string,
    },
  },
  readAccess: boolean,
  history: {
    push: Function,
  }
}

const OrgsDishesPage = ({ match, readAccess, history }: Props) => {
  const { params: { orgId, categoryId, process } } = match;
  const { push } = history;


  const baseUrl = getBaseUrl({ orgId, section: dataKey });
  const redirectUrl = `${baseUrl}/all`;

  const handleCategoryInvalid = useCallback(() => {
    push(redirectUrl);
  }, [push, redirectUrl]);


  if (!readAccess) {
    return (
      <AccessDenied />
    );
  }

  if (!categoryId) {
    return (
      <Redirect to={redirectUrl} />
    );
  }

  return (
    <OrgsPageTemplateOld orgID={orgId}>
      {type => (
        <OrgsDishes
          orgID={orgId}
          orgType={type}
          basePath={baseUrl}
          process={process}
          categoryId={categoryId}
          pathname={window.location.pathname}
          onCategoryInvalid={handleCategoryInvalid}
        />
      )}
    </OrgsPageTemplateOld>
  );
};

export default anyRights(withDishesOrgDishesRights, withDishesCategoriesRights)(OrgsDishesPage);
