// @flow
import { useState, useEffect } from 'react';

import { useFilterData } from 'app/common/ui-components/filter';
import { flags as details } from 'app/components/common/reports/filters/details';


/**
 *  Управление отображением данных
 */

export type DisplaySettings = {
  showDetails: boolean,
}

const defaultState: DisplaySettings = {
  showDetails: false,
};

export default function useDisplaySettings(ready: boolean): DisplaySettings {
  const [,,, { getApplied }] = useFilterData();
  const [settings, setSettings] = useState(defaultState);

  useEffect(() => {
    if (ready) {
      const applied = getApplied();

      if (applied) {
        setSettings({
          showDetails: details.detailsApplied(applied),
        });
      }
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  return settings;
}
