// @flow
import React from 'react';
import classNames from 'classnames';

import ru from 'app/common/images/flag-ru.png';

import styles from './flags.scss';


type Props = {
  className?: string,
}

const FlagRu = ({ className }: Props) => (
  <div className={classNames(styles.root, className)}>
    <img src={ru} className={styles.ru} alt="flag_ru" />
  </div>
);

export default FlagRu;
