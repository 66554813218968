import { useCallback } from 'react'

import { PlainConfirm } from 'app/common/ui-next/plain-confirm-modal'

import CrossButton from './FileView/CrossButton'
import styles from './CurrentFile.scss'


type Props = {
  ID: string
  fileName: string
  onDelete: (params: { ID: string }) => Promise<boolean>
}

export const CurrentFile = ({ ID, fileName, onDelete }: Props) => {
  const handleDelete = useCallback(async () => {
    const res = await onDelete({ ID })
    return res
  }, [ID, onDelete])

  return (
    <div className={styles.root}>
      {'Текущий файл:\u00A0'}
      <strong className={styles.strong}>{fileName}</strong>
      <PlainConfirm
        messageText="Удалить данный документ"
        onConfirm={handleDelete}
      >
        {beginConfirmation => (
          <CrossButton onCrossClick={beginConfirmation} />
        )}
      </PlainConfirm>
    </div>
  )
}
