// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as org } from '../org';


const buildingActivity = (state: State) => {
  return (
    org.singleOrgSelected(state)
  );
};

export default buildingActivity;
