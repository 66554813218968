import { customerSearchFilter, customerSearchPhraseFilter, getCustomerSearchDataReceiver, periodFilter } from 'app/dataProviders/filter-items'


export const filterInit = () => {
  customerSearchFilter.addDataReceiver(
    getCustomerSearchDataReceiver,
  )
  customerSearchFilter.dataReceive()
  customerSearchFilter.setStatusReady()

  periodFilter.setStatusReady()

  return () => {
    customerSearchPhraseFilter.reset()

    customerSearchFilter.reset()
    periodFilter.reset()
  }
}

export const resetToDefaultAll = () => {
  customerSearchPhraseFilter.resetToDefault()
  customerSearchFilter.resetToDefault()
  periodFilter.resetToDefault()
}
