import reduce from 'ramda/es/reduce';
import path from 'ramda/es/path';

// типы событий (kind_id)
// c 1 000 до 9 999
export const LOG_AUTHORIZE = 1000;
export const LOG_NAVIGATE = 1001;

// события (action_id)
const logMatches = {
  // События навигации
  // c 10 000 до 999 999 -- id навигации
  // cо 100 000 до 999 999 -- общие подразделы для всех кабинетов
  food: {
    about: {
      id: 100000,
      title: 'О приложении',
    },
    profile: {
      id: 200000,
      title: 'Профиль',
    },
    orgs: {
      id: 10000,
      title: 'Образовательные организации',
      paramsNum: 1,
      menu: {
        id: 11000,
        title: 'Меню',
      },
      kits: {
        id: 12000,
        title: 'Комплекты',
      },
      dishes: {
        id: 13000,
        title: 'Блюда',
      },
    },
    reports: {
      id: 20000,
      title: 'Отчеты',
      balance: {
        id: 21000,
        title: 'Остаток средств',
      },
    },
    finances: {
      id: 40000,
      title: 'Финансы',
      emoney: {
        id: 41000,
        title: 'ЭДС',
      },
    },
    settings: {
      id: 30000,
      title: 'Настройки',
      dishes: {
        id: 31000,
        title: 'Блюда',
      },
      categories: {
        id: 32000,
        title: 'Категории меню',
      },
      employees: {
        id: 33000,
        title: 'Сотрудники',
        current: {
          id: 33100,
          title: 'Текущие',
        },
        waiting: {
          id: 33200,
          title: 'Ожидающие приема',
        },
        fired: {
          id: 33300,
          title: 'Уволенные',
        },
      },
      admin: {
        id: 34000,
        title: 'Администрирование',
      },
      props: {
        id: 35000,
        title: 'Реквизиты',
      },
    },
  },
  cabinet: {},
  journal: {},
  dealer: {},
};

export const logNavigation = logMatches[(process.env.APP_NAME || process.env.REACT_APP_NAME)];

export const getIdByPath = (pathTo) => {
  const p = reduce((acc, item) => {
    const next = acc.concat(item);
    return (path(next, logNavigation)
      ? next
      : acc
    );
  }, [], pathTo);
  return path(p.concat('id'), logNavigation);
};

export default logMatches;
