import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import type { HOC } from 'recompose';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';

import { popup } from 'app/common/actions/popup';
import { itemsEdit } from 'app/actions/orgs/menu/itemsEdit';
import { kitsEdit } from 'app/actions/orgs/menu/kitsEdit';

import selectProps from './selectProps';


type Props = {
  orgId: string,
  basePath: string,
  date: string,
  sellPointId: string,
  mDate: typeof moment,
};

const enhanceOrgsMenu: HOC<*, Props> = compose(
  connect(selectProps),

  withRouter,

  withHandlers({
    handleSubmitItems: ({ dispatch, returnTo, orgId, date, sellPointId, history }) => async (addList: Array<number>, removeList: Array<number>) => {
      const res = await dispatch(itemsEdit(orgId, {
        date,
        sellPointId,
      }, addList, removeList));
      if (res) {
        dispatch(popup('Меню успешно отредактировано'));
        history.push(returnTo);
      }
    },
    handleSubmitKits: ({ dispatch, returnTo, orgId, date, sellPointId, history }) => async (addList, removeList) => {
      const res = await dispatch(kitsEdit(orgId, {
        date,
        sellPointId,
      }, addList, removeList));
      if (res) {
        dispatch(popup('Меню успешно отредактировано'));
        history.push(returnTo);
      }
    },
  }),
);

export default enhanceOrgsMenu;
