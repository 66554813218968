// @flow
import { useState, useEffect, useCallback, useMemo } from 'react';
import { map, T, F, all, equals } from 'ramda';


export type State = Map<string, boolean>

const duplicateState = (valueModify: ((id: string) => boolean) | [string, boolean]) => (currentState: State): State => {
  const pairs = Array.from(currentState.entries());
  if (typeof valueModify === 'function') {
    return new Map(map(([id]) => ([id, valueModify(id)]), pairs));
  }
  return new Map([...pairs, valueModify]);
};

export default function useSelectionState(defaultState: State): {
  selection: State,
  selectItem: (id: string, checked: boolean) => void,
  selectedAll: boolean,
} {
  const [selection, setSelection] = useState<State>(defaultState);

  const selectItem = useCallback((id: string, checked: boolean) => {
    if (id === 'all') {
      setSelection(duplicateState(checked ? T : F));
      return;
    }
    setSelection(duplicateState([id, checked]));
  }, []);

  const selectedAll = useMemo(() => {
    return !!selection.size && all(equals(true), Array.from(selection.values()));
  }, [selection]);

  useEffect(() => {
    setSelection(defaultState);
  }, [defaultState]);

  return {
    selection,
    selectItem,
    selectedAll,
  };
}
