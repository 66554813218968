import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';
import sortBy from 'ramda/es/sortBy';
import toLower from 'ramda/es/toLower';
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import ascend from 'ramda/es/ascend';
import curry from 'ramda/es/curry';
import groupBy from 'ramda/es/groupBy';
import test from 'ramda/es/test';
import reduce from 'ramda/es/reduce';
import filter from 'ramda/es/filter';
import includes from 'ramda/es/includes';
import concat from 'ramda/es/concat';
import isEmpty from 'ramda/es/isEmpty';


export const sortByTitle = sortBy(pipe(prop('title'), toLower));
export const sortByProp = propName => sortBy(pipe(prop(propName), toLower));
export const sortByPath = arrayPath => sortBy(pipe(path(arrayPath), toLower));

/**
 * Вспомогательная функция для переключения направления сортировки
 * @param {'up'|'down'} dir направление
 */
const direction = dir => (dir === 'up' ? descend : ascend);

/**
 * Сортировка для таблицы с поддержкой сортировки колонок
 * @param {'up'|'down'} sortDirection направление сортировки
 * @param {Function} fn функция для получение поля, по которому ведется сортировка (например prop('a'))
 * @param {Array} items массив данных для сортировки
 */
export const tableColumnSort = curry((sortDirection, fn, items) => sort(direction(sortDirection)(fn), items));

export const sortDirectionWith = tableColumnSort;

/**
 * Частный случай предыдущей функции с поддержкой правильной сортировки номеров классов в виде строк
 */
export const tableColumnSortClass = curry((sortDirection, fn, items) => sort(
  (val1, val2) => {
    const a = fn(val1); // prop(sortKey, val1);
    const b = fn(val2); // prop(sortKey, val2);
    const aa = parseInt(a, 10) || 0;
    const bb = parseInt(b, 10) || 0;
    return ((aa !== bb) // eslint-disable-line no-nested-ternary
      ? sortDirection === 'down' ? aa - bb : bb - aa
      : sortDirection === 'down' // eslint-disable-line no-nested-ternary
        ? (a < b ? -1 : a > b ? 1 : 0) // eslint-disable-line no-nested-ternary
        : (a > b ? -1 : a < b ? 1 : 0)); // eslint-disable-line no-nested-ternary
  },
  items,
));

export const sortClasses = tableColumnSortClass;

/**
 * Сортировка чисел с предварительным преобразованием из строки
 */
export const sortStringsAsNumbers = curry((sortDirection, fn, items) => sort(
  (val1, val2) => {
    const a = parseFloat(fn(val1)) || 0;
    const b = parseFloat(fn(val2)) || 0;
    return sortDirection === 'down' ? a - b : b - a;
  },
  items,
));

/**
 * Сортировка с предварительным разделением на группы чисел и текста)
 */
export const sortNumbersAndText = curry((sortDirection, fn, items) => {
  const { numbers = [], strings = [] } = groupBy(
    item => (test(/^\d*[.|,]*\d$/, fn(item)) ? 'numbers' : 'strings'),
  )(items);

  return [
    ...sortStringsAsNumbers(sortDirection, fn, numbers),
    ...sort(direction(sortDirection)(fn), strings),
  ];
});

/**
 * Сортировка по определенному массиву
 */

export const arrangeByArray = curry((arrange, fn, items) => {
  if (isEmpty(items)) {
    return items;
  }
  const groupped = groupBy(fn, items);
  const arranged = reduce((acc, val) => {
    if (groupped[val] && groupped[val][0]) {
      acc.push(groupped[val][0]);
    }
    return acc;
  }, [], arrange);

  if (arrange.length < items.length) {
    const restUnarranged = filter(
      item => !includes(fn(item), arrange),
      items,
    );
    return concat(arranged, restUnarranged);
  }
  return arranged;
});
