import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import evolve from 'ramda/es/evolve';
import assoc from 'ramda/es/assoc';

import withModal from 'app/common/containers/withModal';
import { reportStatus } from 'app/actions/reports/status';
import { isReportView } from 'app/actions/reports/filters/mail';
import { popup } from 'app/common/actions/popup';
import action from 'app/common/lib/action';


/**
  type IncommingProps = {
    actionGenerate: () => void,
    actionSetReportId: string,
    onProcessCancel: (string | number) => Promise<void>
  }
*/

const INTERVAL = 3000;

const defaultState = {
  progress: 0.0,
  timer: 0,
  reportId: 0,
  loading: false,
  isError: false,
};

export default compose(
  connect(
    state => ({
      viewReport: isReportView(state.reports.filters.mail),
    }),
  ),

  withModal,

  withState('state', 'setState', defaultState),

  withHandlers({
    handleGenerateStart: ({
      toggleModal,
      dispatch,
      setState,
      viewReport,
      actionGenerate,
      actionSetReportId,
    }) => async (e) => {
      e.preventDefault();
      setState(assoc('loading', true));
      const reportId = await dispatch(actionGenerate());
      // const reportId = 12405 // для теста 520 ошибки
      if (!reportId) {
        setState(assoc('loading', false));
        return;
      }
      if (!viewReport) {
        setState(assoc('loading', false));
        dispatch(popup('Отчет успешно добавлен в очередь на генерацию и после завершения будет отправлен на ваш e-mail'));
        return;
      }
      setState(assoc('reportId', reportId));

      // рекурсивный таймер
      const setProgress = async () => {
        const progress = await dispatch(reportStatus(reportId));
        setState(assoc('progress', progress));
        if (parseInt(progress, 10) >= 100) {
          setTimeout(() => {
            toggleModal();
            dispatch(action(actionSetReportId, reportId));
            setState(defaultState);
          }, 1000);
          return;
        }
        if (progress < 0) {
          setState(assoc('isError', true));
          return;
        }
        setState(evolve({
          progress: progressOld => (progress || progressOld),
          timer: () => setTimeout(setProgress, INTERVAL),
        }));
      };

      setState(assoc('timer', setTimeout(setProgress, 500)));
      setState(assoc('loading', false));
      toggleModal();
    },

    handleGenerateStop: ({ toggleModal, state: { timer, reportId }, setState, onProcessCancel }) => (e) => {
      e.preventDefault();
      if (timer) {
        clearTimeout(timer);
      }
      if (reportId && onProcessCancel) {
        onProcessCancel(reportId);
      }
      setState(defaultState);
      toggleModal();
    },
  }),

  lifecycle({
    componentWillUnmount() {
      const { state: { timer } } = this.props;
      if (timer) {
        clearTimeout(timer);
      }
    },
  }),

  // mapProps((props) => {
  //   console.log(props);
  //   return props;
  // }),

  mapProps(({ state: { progress, loading, isError, reportId }, ...others }) => ({
    ...others,
    progress,
    loading,
    isError,
    reportId,
  })),


);
