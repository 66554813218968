// @flow
import React, { type Node, Fragment } from 'react';
import values from 'ramda/es/values';
import { Redirect } from 'react-router';

import {
  dataKey,
  ordersSections,
  defaultSection,
  OrgsOrders,
  OrgsOrdersProduction,
} from 'app/components/OrgsList/OrgsOrders';

import * as rootRights from 'app/common/constants/rights/rootRights';
import * as specificRights from 'app/common/constants/rights/specificRights';

import OrgsPageTemplate from '../OrgsPageTemplate';
import getBaseUrl from '../getBaseUrl';


export { dataKey };

export const rights = [rootRights.ORDERS, specificRights.ORDERS];

const availableOrdersSections = values(ordersSections);

type Props = {
  match: {
    params: {
      orgId: string,
      ordersSection?: string,
    },
  },
}

const OrgsOrdersPage = ({ match }: Props): Node => {
  const { params: { orgId: orgIdString, ordersSection } } = match;
  const orgId = parseInt(orgIdString, 10);
  const baseUrl = getBaseUrl({ orgId, section: dataKey });

  const redirectUrl = `${baseUrl}/${defaultSection}`;

  if (!ordersSection || !availableOrdersSections.includes(ordersSection)) {
    return (
      <Redirect to={redirectUrl} />
    );
  }

  return (
    <OrgsPageTemplate orgId={orgId} rightsPath={rights}>
      {(orgType, placeForGuests) => (
        <Fragment>
          {ordersSection === ordersSections.ORDERS
            && <OrgsOrders
              orgId={orgId}
              orgType={orgType}
              placeForGuests={placeForGuests}
              baseUrl={baseUrl}
            />}

          {ordersSection === ordersSections.PRODUCTION
            && <OrgsOrdersProduction
              orgId={orgId}
              baseUrl={baseUrl}
            />}
        </Fragment>
      )}
    </OrgsPageTemplate>
  );
};

export default OrgsOrdersPage;
