// @flow
import { useMemo } from 'react';
import { map, over, lensProp, replace } from 'ramda';

import { useCurrencySign } from 'app/common/components-next/currency';


export type HeaderCells = Array<{
  key: string,
  value: string,
  alignLeft?: boolean,
  alignRight?: boolean,
  alignCenter?: boolean,
}>

export const cells: HeaderCells = [{
  key: 'name',
  value: 'Наименование',
  alignLeft: true,
}, {
  key: 'title',
  value: 'Комплект',
  alignLeft: true,
}, {
  key: 'number',
  value: 'Количество',
  alignLeft: true,
}, {
  key: 'price',
  value: 'Цена,\u00A0%currency%',
  alignLeft: true,
}, {
  key: 'cost',
  value: 'Стоимость,\u00A0%currency%',
  alignLeft: true,
}, {
  key: 'sum',
  value: 'Сумма,\u00A0%currency%',
  alignCenter: true,
}];

export default function useHeaderCellsData(): HeaderCells {
  const currencySign = useCurrencySign();

  const cellsWithCurrencySign = useMemo(() => {
    return map(over(lensProp('value'), replace('%currency%', currencySign)), cells);
  }, [currencySign]);

  return cellsWithCurrencySign;
}
