// @flow
import React, { Fragment } from 'react';

import PlainLabel from 'app/common/ui-next/plain-label';
import PlainInput from 'app/common/ui/PlainInput';

import styles from './EmailForm.scss';


type Props = {
  label: string,
  value: string,
  error: string,
  disabled?: boolean,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
}

const EmailForm = ({ onChange, value, error, label, disabled }: Props) => {
  return (
    <Fragment>
      <PlainLabel
        className={styles.emailLabel}
        htmlFor="email"
      >
        {label}
      </PlainLabel>
      <PlainInput
        className={styles.emailInput}
        onChange={onChange}
        id="email"
        value={value}
        disabled={disabled}
        isError={Boolean(error)}
        errorText={error}
      />
    </Fragment>
  );
};

export default EmailForm;
