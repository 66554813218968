// @flow
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import prop from 'ramda/es/prop';
import complement from 'ramda/es/complement';
import propEq from 'ramda/es/propEq';
import filter from 'ramda/es/filter';

import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';

import filterGetItemsYears from '../../filterCommon/filterGetItemsYears';
import filterGetItemsFoodModes from '../../filterCommon/filterGetItemsFoodModes';
import filterGetItemsClasses from '../../filterCommon/filterGetItemsClasses';
import filterGetItemsStatuses, { statuses } from '../../filterCommon/filterGetItemsStatuses';
import type { FilterParams } from '../../filterCommon/FIlterParams';

import filterGetItemsPeriod from './filterGetItemsPeriod';
import filterGetPropsPeriod from './filterGetPropsPeriod';


const exeptDeleted = (getAllStatuses: typeof filterGetItemsStatuses): typeof filterGetItemsStatuses => {
  return () => {
    const [allStatuses, defStatuses] = getAllStatuses();
    return [
      filter(complement(propEq('id', String(statuses.STATUS_DELETED))), allStatuses),
      defStatuses,
    ];
  };
};

const filterItems: Descriptions<FilterParams> = [{

  /* фильтр по учебному году */
  filterId: 'year',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Учебный год',
    header: 'Выберите учебный год',
    sortFunc: sort(descend(prop('title'))),
  },
  getItemsAndCurrent: filterGetItemsYears,
  relations: ['class', 'period'], // фильтры по классу и месяцам зависят от состояния этого фильтра
}, {

  /* фильтр по периоду */
  filterId: 'period',
  filterType: filterTypes.PLAIN_PERIOD,
  multiple: false,
  getProps: filterGetPropsPeriod,
  getItemsAndCurrent: filterGetItemsPeriod,
}, {

  /* фильтр по режиму питания */
  filterId: 'foodMode',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Режим питания',
    header: 'Выберите режим питания',
  },
  getItemsAndCurrent: filterGetItemsFoodModes,
}, {

  /* фильтр по классам */
  filterId: 'class',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Класс',
    header: 'Выберите класс',
  },
  getItemsAndCurrent: filterGetItemsClasses,
}, {

  /* фильтр по статусу */
  filterId: 'status',
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Статус',
    header: 'Выберите статус',
  },
  getItemsAndCurrent: exeptDeleted(filterGetItemsStatuses),
}];

export default filterItems;
