// @flow
import type { GeneralSettings, ParamsPOST, ParamsMassPOST } from 'app/dataTypes/org/settings/GeneralSettings';

import type { Request } from '../../index';


type UrlParams = {
  orgId: number | string,
}


export const GET_ORG_GENERAL_SETTINGS: Request<
  void,
  GeneralSettings,
  UrlParams
> = [
  'food/schools/{orgId}/general_settings',
  'get',
  ['data', 'settings'],
];


export const POST_ORG_GENERAL_SETTINGS: Request<
  ParamsPOST,
  GeneralSettings,
  UrlParams
> = [
  'food/schools/{orgId}/general_settings',
  'post',
  ['data', 'settings'],
];


export const POST_ORG_GENERAL_SETTINGS_MASS: Request<
  ParamsMassPOST,
  'success',
  void
> = [
  'food/schools/general_settings',
  'post',
  ['data', 'result'],
];
