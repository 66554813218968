// @flow
import React, { type Node } from 'react';

import Pdf32 from 'app/common/icons/pdf32.svg';
import UndefinedFile from './UndefinedFile';


const picFormats = ['.png', '.jpg', '.jpeg'];
// const docFormats = ['.doc', '.docx', '.pdf', '.xls', '.xlsx'];

const getSrc = (format: ?string, url: ?string): string | Node => {
  if (picFormats.includes(format) && url) return url;
  if (format === '.pdf') return <Pdf32 />;
  return <UndefinedFile />;
};

export default getSrc;
