// @flow
import React from 'react';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import renderComponent from 'recompose/renderComponent';

import { DotsLoader } from 'app/common/components/Loader';
import styles from './OpenModal.scss';


type OpenModalProps = {
  toggleModal: (e: SyntheticEvent<HTMLElement>) => void,
  children: any,
  loading: boolean,
  noData?: boolean,
};

const Loader = () => <DotsLoader simple />;
const enhance = compose(
  branch(({ noData }) => noData, renderNothing),
  branch(({ loading }) => loading, renderComponent(Loader)),
);

const OpenModal = enhance(({ toggleModal, children }: OpenModalProps) => (
  <span>
    <a href="select" className={styles.link} onClick={toggleModal}>{'Выбрать'}</a>
    {children}
  </span>
));

export default OpenModal;
