
export enum OrgType {
  Preschool = 'preschool',
  School = 'school',
  HighSchool = 'high_school',
  Enterprise = 'enterprise',
  Unknown = 'unknown'
}

export const orgTitlesList: { [key in OrgType]: string } = {
  [OrgType.Preschool]: 'Дошкольное образовательное учреждение (ДОУ)',
  [OrgType.School]: 'Общеобразовательное учреждение (ОУ)',
  [OrgType.HighSchool]: 'Образовательное учреждение высшего образования (ВУЗ)',
  [OrgType.Enterprise]: 'Предприятие',
  [OrgType.Unknown]: 'Неизвестный тип организации',
}

export type OrgTypesList = {
  ID: OrgType;
  title: typeof orgTitlesList[OrgType];
}

export const isOrgType = (value: any): value is OrgType[keyof OrgType] => {
  return Object.values(OrgType).includes(value)
}
