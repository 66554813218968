// @flow
import React, { Fragment, useCallback, useRef, useState } from 'react';

import RowLayout, { Header } from 'app/common/layouts/RowLayout';
import ImageSelector from 'app/common/ui/ImageSelector';
import type { ImageParamsType } from 'app/common/components/ImagePrepare/ImagePrepare';
import PlainButton from 'app/common/ui-next/plain-button';
import CSSSticky from 'app/common/ui-components/css-sticky';

import enhanceGeneral from './enhanceGeneral';
import EmployeeForm from './EmployeeForm';
import styles from './SectionGeneral.scss';


type Props = {
  title: string,
  photo: string,
  handleDeleteImage: (e: SyntheticEvent<HTMLElement>) => void,
  handleSaveImage: (ImageParamsType) => boolean,
  locked: boolean,
  isFormNotModified: boolean,
  saving: boolean,
}

const SectionGeneral = ({
  title,
  photo,
  handleDeleteImage,
  handleSaveImage,
  locked,
  isFormNotModified,
  saving,
}: Props) => {
  const [submitting, setSubmitting] = useState(false);

  const formRef = useRef(null);
  const handleClickSaveButton = useCallback(() => {
    if (formRef.current) {
      formRef.current.submit.click();
    }
  }, []);


  return (
    <Fragment>
      <CSSSticky
        number={3}
        stickyClassName={styles.sticky}
        shadowClassName={styles.shadow}
      >
        <RowLayout first flex>
          <Header>{title}</Header>
          {!locked
            && <PlainButton
              disabled={isFormNotModified || saving || submitting}
              onClick={handleClickSaveButton}
            >
              {submitting ? 'Сохранение...' : 'Сохранить'}
            </PlainButton>}
        </RowLayout>
      </CSSSticky>

      <RowLayout className={styles.main}>
        <ImageSelector
          photo={photo}
          linkText="Загрузить"
          onSaveImage={handleSaveImage}
          onClickDelete={handleDeleteImage}
          showPlaceholder
          showExtensions
          locked={locked}
          className={styles.photo}
        />
        <div className={styles.content}>
          <EmployeeForm
            reference={formRef}
            locked={locked}
            submitting={submitting}
            onChangeSubmittingStatus={setSubmitting}
          />
        </div>
      </RowLayout>
    </Fragment>
  );
};

export default enhanceGeneral(SectionGeneral);
