// @flow
import { map } from 'ramda';


export default function duplicateMapState<T>(valueModify?: ((id: string, val: T) => T) | [string, T]) {
  return (currentState: Map<string, T>): Map<string, T> => {
    const pairs = Array.from(currentState.entries());
    if (typeof valueModify === 'function') {
      return new Map(map(([id, val]) => ([id, valueModify(id, val)]), pairs));
    }
    if (valueModify instanceof Array) {
      return new Map([...pairs, valueModify]);
    }
    return new Map(pairs);
  };
}
