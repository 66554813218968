// @flow
import { type ComponentType } from 'react';

import MultilineCell from '../Cells/MultilineCell';


export type ColumnProps = {
  id: string,
  title: string,
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
  },
  cellComponent: ComponentType<any> | string
}

const TITLE: 'title' = 'title';
const STUDENTS_COUNT: 'students_count' = 'students_count';
const SUM: 'sum' = 'sum';
const TOTAL: 'total' = 'total';

export const ids = {
  TITLE,
  STUDENTS_COUNT,
  SUM,
  TOTAL,
};

const getColumns: (cSign: string) => Array<ColumnProps> = cSign => ([{
  id: TITLE,
  title: 'Наименование',
  props: {
    alignLeft: true,
  },
  cellComponent: 'span',
}, {
  id: SUM,
  title: `Размер возмещения,\u00A0${cSign}`,
  props: {
    alignRight: true,
  },
  cellComponent: MultilineCell,
}, {
  id: STUDENTS_COUNT,
  title: 'Кол-во',
  props: {
    alignRight: true,
  },
  cellComponent: MultilineCell,
}, {
  id: TOTAL,
  title: `Всего,\u00A0${cSign}`,
  props: {
    alignRight: true,
  },
  cellComponent: MultilineCell,
}]);

export default getColumns;
