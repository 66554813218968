// @flow
import moment from 'moment-timezone';

import { dateFormat } from 'app/common/ui-next/plain-date-period';


const dummyItems = [{ id: 'dummy', title: 'dummy' }];

const periodItems = () => {
  const yesterday = moment().subtract(1, 'day').format(dateFormat);
  return ([dummyItems, [yesterday, yesterday]]);
};

export default periodItems;

export { dateFormat };
