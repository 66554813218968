import { ReactNode } from 'react'

import styles from './SummaryWrap.scss'


type Props = {
  children: ReactNode
}

const SummaryWrap = ({ children }: Props) => {
  return (
    <div className={styles.root}>{children}</div>
  )
}

export default SummaryWrap
