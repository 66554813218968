// @flow
import React, { type Node } from 'react';
import cn from 'classnames/bind';

import styles from './CellRestriction.scss';


const cx = cn.bind(styles);

const WIDE: 'wide' = 'wide';
const WIDE_PADDING_ARROW: 'wide_padding_arrow' = 'wide_padding_arrow';
const TIGHT: 'tight' = 'tight';
const TIGHT_PADDING_ARROW: 'tight_padding_arrow' = 'tight_padding_arrow';
const COMMON: 'common' = 'common';
const COMMON_PADDING: 'common_padding' = 'common_padding';
const COMMON_PADDING_ARROW: 'common_padding_arrow' = 'common_padding_arrow';

export const restrictions = {
  WIDE,
  WIDE_PADDING_ARROW,
  TIGHT,
  TIGHT_PADDING_ARROW,
  COMMON,
  COMMON_PADDING,
  COMMON_PADDING_ARROW,
};

type Props = {
  className?: string,
  children: Node,
  type: $Values<typeof restrictions>,
}

const CellRestriction = ({ className, children, type }: Props) => {
  const classes = {
    wide: type === restrictions.WIDE,
    widePaddingArrow: type === restrictions.WIDE_PADDING_ARROW,
    tight: type === restrictions.TIGHT,
    tightPaddingArrow: type === restrictions.TIGHT_PADDING_ARROW,
    common: type === restrictions.COMMON,
    commonPadding: type === restrictions.COMMON_PADDING,
    commonPaddingArrow: type === restrictions.COMMON_PADDING_ARROW,
  };

  return (
    <div className={cx(className, classes)}>
      {children}
    </div>
  );
};

export default CellRestriction;
