// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames';

import PlainTooltip from 'app/common/ui-next/plain-tooltip';

import styles from './CellContentOverflow.scss';


type Props = {
  className?: string,
  children?: Node,
}

const CellContentOverflow = ({ className, children }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <PlainTooltip content={children} hideWithoutOverflow>
      {(ref: Ref<'any'>) => (<span ref={ref} className={cn(styles.content, className)}>
        {children}
      </span>)}
    </PlainTooltip>
  );
};

export default CellContentOverflow;
