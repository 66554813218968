// @flow
import * as React from 'react';
import path from 'ramda/es/path';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames/bind';

import { Table } from 'app/common/components/Table';
import Paging from 'app/common/ui/Paging';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import CSSSticky from 'app/common/ui-components/css-sticky';

import ts from './NinePartsTable.scss';


const cx = classNames.bind(ts);
const renderNoScroll = () => <span />;
let lastKnownScrollPosition = 0;
let ticking = false;

/**
 * Таблица из 9 частей, со скролингом и прилипанием DEPRECATED
 */

type Props = {
  handlePage: (e: SyntheticEvent<HTMLAnchorElement>) => void,
  currentPage: number,
  total: number,
  rows: number,
  currentPageSize: number,
  topLeftContent: React.Node,
  topCenterContent: React.Node,
  topRightContent?: React.Node,
  middleLeftContent: React.Node,
  middleCenterContent: React.Node,
  middleRightContent?: React.Node,
  bottomLeftContent?: React.Node,
  bottomCenterContent?: React.Node,
  bottomRightContent?: React.Node,
  leftWidth: number,
  rightWidth?: number,
  empty?: boolean,
  stickyNumber?: number,
  noVShadows?: boolean,
  stopUseGPU?: boolean,
}

const NinePartsTable = ({
  handlePage,
  currentPage,
  total,
  rows,
  currentPageSize,
  topLeftContent,
  topCenterContent,
  topRightContent,
  middleLeftContent,
  middleCenterContent,
  middleRightContent,
  bottomLeftContent,
  bottomCenterContent,
  bottomRightContent,
  leftWidth,
  rightWidth = 0,
  empty,
  stickyNumber = 1,
  noVShadows,
  // stopUseGPU,
}: Props) => {
  const t1Style = { width: leftWidth + 1 };
  const t3Style = { width: rightWidth + 1 };

  // скролинг
  const [headerScrollableEl, refHeaderScrollable] = React.useState(null);
  const [middleScrollableEl, refMiddleScrollable] = React.useState(null);
  const [bottomScrollableEl, refBottomScrollable] = React.useState(null);

  // состояния вертикальных теней
  const [leftShadow, setLeftShadow] = React.useState(false);
  const [rightShadow, setRightShadow] = React.useState(!noVShadows);
  const [oldScrollLeft, setOldScrollLeft] = React.useState(true);

  // side effect -- изменение noVShadows (если true, то верт. тени не нужны)
  React.useEffect(() => {
    setRightShadow(!noVShadows);
  }, [noVShadows]);

  // управление вертикальными тенями при скролинге
  const handleScrollFrame = React.useCallback(({ left, scrollLeft }) => {
    if (noVShadows || oldScrollLeft === scrollLeft) return;
    setOldScrollLeft(scrollLeft);
    if (leftShadow && left === 0) setLeftShadow(false);
    if (!leftShadow && left > 0) setLeftShadow(true);
    if (rightShadow && left === 1) setRightShadow(false);
    if (!rightShadow && left < 1) setRightShadow(true);
  }, [leftShadow, rightShadow, oldScrollLeft, noVShadows]);

  // синхронизация горизонтального скролинга для различных частей таблицы
  const handleScroll = React.useCallback( // eslint-disable-line react-hooks/exhaustive-deps
    (e) => {
      e.stopImmediatePropagation();
      if (!e.currentTarget) return;
      const { scrollLeft } = e.currentTarget;
      lastKnownScrollPosition = scrollLeft;
      const id = path(['currentTarget', 'parentNode', 'id'], e);
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (headerScrollableEl && id !== 'hs') {
            headerScrollableEl.view.scrollLeft = lastKnownScrollPosition;
          }
          if (middleScrollableEl && id !== 'ms') {
            middleScrollableEl.view.scrollLeft = lastKnownScrollPosition;
          }
          if (bottomScrollableEl && id !== 'bs') {
            bottomScrollableEl.view.scrollLeft = lastKnownScrollPosition;
          }
          ticking = false;
        });
        ticking = true;
      }
    }, [bottomScrollableEl, headerScrollableEl, middleScrollableEl]);

  // видимость отдельных частей таблицы
  const showTopRight = !!topRightContent;
  const showMiddleRight = !!middleRightContent;
  const showBottomLeft = !!bottomLeftContent;
  const showBottomCenter = !!bottomCenterContent;
  const showBottomRight = !!bottomRightContent;

  return (
    <React.Fragment>

      {/* Заголовок таблицы с прилипанием */}
      <CSSSticky
        number={stickyNumber}
        stickyClassName={ts.border}
        shadowClassName={ts.shadow}
        className={ts.stickyHeader}
      >
        <div className={ts.rowGroup}>

          {/* Левая часть таблицы */}
          <Table className={ts.table1} style={t1Style}>
            {topLeftContent}
          </Table>

          <Scrollbars
            className={cx(ts.scrollbars, { leftShadow, rightShadow })}
            renderTrackHorizontal={renderNoScroll}
            onScroll={handleScroll}
            ref={refHeaderScrollable}
            id="hs"
          >
            <div className={ts.table2}>
              <Table>
                {topCenterContent}
              </Table>
            </div>
          </Scrollbars>

          {/* Правая часть таблицы */}
          {showTopRight
            && <Table className={ts.table3} style={t3Style}>
              {topRightContent}
            </Table>}
        </div>
      </CSSSticky>
      {/* Конец прилипания и заголовка таблицы */}

      <div className="stickStart">

        {/* Середина таблицы */}
        <div className={ts.rowGroup}>

          {empty

            ? <EmptyWarn>{'Нет записей, удовлетворяющих условиям'}</EmptyWarn>

            : <React.Fragment>

              {/* Левая часть таблицы */}
              <Table className={ts.table1} style={t1Style}>
                {middleLeftContent}
              </Table>

              {/* *** *** *** ***  */}
              {/* Середина таблицы */}
              <Scrollbars
                className={cx(ts.scrollbars, { leftShadow, rightShadow })}
                onScroll={handleScroll}
                renderTrackHorizontal={renderNoScroll}
                ref={refMiddleScrollable}
                id="ms"
              >
                <div className={ts.table2}>
                  <Table>
                    {middleCenterContent}
                  </Table>
                </div>
              </Scrollbars>

              {/* Правая часть таблицы */}
              {showMiddleRight
                && <Table className={ts.table3} style={t3Style}>
                  {middleRightContent}
                </Table>}

            </React.Fragment>}
        </div>

        {/* Подвал с прилипанием */}
        <CSSSticky
          number={-1}
          className={ts.stickyFooter}
          shadowClassName={ts.bottomShadow}
        >
          <div className={ts.rowGroup}>
            {/* Левая часть таблицы */}
            {showBottomLeft
              && <Table className={ts.table1} style={t1Style}>
                {bottomLeftContent}
              </Table>}

            {/* Середина таблицы */}
            {showBottomCenter
              && <Scrollbars
                className={ts.scrollbars}
                onScroll={handleScroll}
                ref={refBottomScrollable}
                id="bs"
                onScrollFrame={handleScrollFrame}
              >
                <div className={ts.table2}>
                  <Table>
                    {bottomCenterContent}
                  </Table>
                </div>
              </Scrollbars>}

            {/* Правая часть таблицы */}
            {showBottomRight
              && <Table className={ts.table3} style={t3Style}>
                {bottomRightContent}
              </Table>}
          </div>

          {/* Постарничная навигация */}
          <Paging
            className={ts.pager}
            total={total}
            pageSize={rows}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            onPage={handlePage}
          />
        </CSSSticky>

      </div>
      {/* end className="stickStart" */}
    </React.Fragment>
  );
};

export default NinePartsTable;
