// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';
import identity from 'ramda/es/identity';

import usePaging, { type Paging } from 'app/common/hooks/usePaging';
import { api } from 'app/api';

import {
  GET_FOOD_ITEMS,
  type FoodItem,
} from 'app/common/api/requests/food/items';

import convertDishesApiData from '../DishesData/helpers/convertDishesApiData';


export type { FoodItem, Paging };

export type State = {
  dishes: Array<FoodItem>,
  total: number,
  limit: number,
  loading: boolean,
}

export const defaultState: State = {
  dishes: [],
  total: 0,
  limit: 25,
  loading: true,
};

export type Options = {
  limit?: number,
  search?: string,
  nomenclature?: string,
}

const defaultOptions: {
  limit: number,
} = {
  limit: 25,
};

const defaultDishes = [];

export default function useAddingDishes(options?: Options = {}): [State, Paging] {
  const { limit, search, nomenclature } = { ...defaultOptions, ...options };
  const [dishes, setDishes] = useState(defaultDishes);

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const paging = usePaging();
  const { currentPage, setCurrentPage } = paging;


  // запрос данных
  const handleRequestDishes = useCallback(async () => {
    const actualParams = filter(identity, { search, nomenclature });

    if (isEmpty(actualParams)) { // ничего не показывать, если нет параметров
      setCurrentPage(1);
      setDishes(defaultDishes);
      return;
    }

    setLoading(true);
    const res = await api.request(GET_FOOD_ITEMS, {
      cancelable: true,
      error: 'Запрос похожих блюд не удался',
      params: {
        ...actualParams,
        limit,
        offset: (currentPage - 1) * limit,
      },
    });
    setLoading(false);

    if (res) {
      const [items, totalCount] = convertDishesApiData(res);
      if (isEmpty(items) && totalCount && currentPage > 1) {
        // нет данных на странице > 1, редирект на первую
        setCurrentPage(1);
      }
      setDishes(items);
      setTotal(totalCount);
    }
  }, [limit, search, currentPage, setCurrentPage, nomenclature]);


  // сброс номера страницы
  useEffect(() => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  // запрос блюд
  useEffect(() => {
    handleRequestDishes();
  }, [handleRequestDishes]);


  const state = useMemo(() => ({
    dishes,
    total,
    limit,
    loading,
  }), [dishes, total, limit, loading]);

  return [state, paging];
}
