// @flow
import pipe from 'ramda/es/pipe';
import toPairs from 'ramda/es/toPairs';
import map from 'ramda/es/map';
import fromPairs from 'ramda/es/fromPairs';
import values from 'ramda/es/values';
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';
import length from 'ramda/es/length';
import keys from 'ramda/es/keys';
import reduce from 'ramda/es/reduce';
import curry from 'ramda/es/curry';
import append from 'ramda/es/append';
import cond from 'ramda/es/cond';
import head from 'ramda/es/head';
import pick from 'ramda/es/pick';
import indexBy from 'ramda/es/indexBy';
import of from 'ramda/es/of';
import T from 'ramda/es/T';


// Конвертация имен полей объекта в стиль camelCase из snake_case
// $FlowFixMe
export const camelCase = str => str.replace(
  /[-_]([a-z])/g,
  m => m[1].toUpperCase(),
);

// $FlowFixMe
export const snakeCase = str => str.replace(
  /([A-Z])/g,
  g => `_${g[0].toLowerCase()}`,
);

// $FlowFixMe
export const renameObjKeys = (cb, cb1) => pipe(
  toPairs,
  map(([key, item]) => ([
    cb1(key),
    cb(item),
  ])),
  fromPairs,
);

// $FlowFixMe
export const toCamelCase = val => cond([
  [item => item instanceof Array, map(toCamelCase)],
  [item => item instanceof Object, renameObjKeys(toCamelCase, camelCase)],
  [T, value => value],
])(val);

// $FlowFixMe
export const toSnakeCase = val => cond([
  [item => item instanceof Array, map(toCamelCase)],
  [item => item instanceof Object, renameObjKeys(toSnakeCase, snakeCase)],
  [T, value => value],
])(val);

// Конвертация массива строк в набор объектов { id, title }
export const stringListToObject: (Array<string>) => Object | (Array<number>) => Object = pipe(
  map(id => ([id, {
    id,
    title: id,
  }])),
  fromPairs,
);

// Состоит ли объект из одного подобъекта
export const isSingle = pipe(
  keys,
  items => length(items) === 1,
);

// Состоит ли объект из одного подобъекта
// Дополнительно проверка значения id этого объекта
// $FlowFixMe
export const isSingleWithId = target => pipe(
  values,
  items => (target
    ? length(items) === 1 && path([0, 'id'], items) === target
    : length(items) === 1
  ),
);

// количество own ключ/значение в объекте
// $FlowFixMe
export const itemsNum = obj => length(keys(obj));

/**
 * Преобразование объекта в массив
 * в соответствии с последовательностью ключей,
 * заданной в первом параметре
 */
export const objToArrayArangedBy = curry((arr, obj) => reduce((acc, key) => append(prop(key, obj), acc), [], arr));

/**
 * Создание объекта из другого,
 * values исходного становятся keys результирующего
 */
export const objTransformValuesToKeys = curry((def, obj) => pipe(
  values,
  map(name => ([name, def])),
  fromPairs,
)(obj));

/**
 * Извлечение из набора объектов массива определенного поля
 */
// $FlowFixMe
export const mapObjPropToArray = propName => pipe(
  values,
  map(prop(propName)),
);

/**
 * Извлечение из набора объектов одного поля из первого объекта
 */
// $FlowFixMe
export const objProp = propName => pipe(
  values,
  head,
  prop(propName),
);

/**
 * Создает объект { prop1: { prop1, prop2, ... } } из { prop1, prop2, ...}
 * например: { 548: { id: 548, title: 'Петрович' } } из { id: 548, title: 'Петрович', ...}
 */
// $FlowFixMe
export const objIndexed = propNamesArr => pipe(
  pick(propNamesArr),
  of,
  indexBy(prop(head(propNamesArr))),
);

/**
 * Переименование keys объекта в соответствии keyMap
 */

// $FlowFixMe
export const renameKeys = keyMap => obj => reduce((acc, key) => {
  keyMap[key] // eslint-disable-line no-unused-expressions
    ? acc[keyMap[key]] = obj[key]
    : acc[key] = obj[key];
  return acc;
}, {}, keys(obj));
