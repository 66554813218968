// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import PlainModal, { PlainModalContent } from 'app/common/ui-next/plain-modal';

import PlainButton from '../../ui-next/plain-button';
import ControlsBar from '../../ui-next/controls-bar';
import PlainLinkAsButton from '../../ui-next/plain-link-as-button';

import { getVersionsHistoryLink } from './versions';
import styles from './recently-updated-modal.scss';


type Props = {
  version: string,
  onClose: () => void,
  recentlyUpdateDescriptionLink?: string
}

const RecentlyUpdatedModal = ({ version, onClose, recentlyUpdateDescriptionLink }: Props) => {
  const { t } = useTranslation('sw');
  const externalLink = recentlyUpdateDescriptionLink || getVersionsHistoryLink();

  return (
    <PlainModal show>
      <PlainModalContent>
        <div className={styles.content}>
          <div className={styles.header}>
            {t('recently_update_modal_header', 'Обновление прошло успешно!')}
          </div>
          <div className={styles.text}>
            {t('recently_update_modal_version_text', { version })}
          </div>
        </div>
        <ControlsBar className={styles.controls} middle>
          <PlainLinkAsButton
            to={externalLink}
            disabled={!externalLink}
            anchorForce
            outline
          >
            {t('recently_update_modal_bt_history', 'Ознакомиться с обновлениями')}
          </PlainLinkAsButton>
          <PlainButton onClick={onClose}>
            {t('recently_update_modal_bt_ok', 'OK')}
          </PlainButton>
        </ControlsBar>
      </PlainModalContent>
    </PlainModal>
  );
};

export default RecentlyUpdatedModal;
