import PlainInput from 'app/common/ui-next/plain-input'
import PlainLabel from 'app/common/ui-next/plain-label'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'

import { FormField } from './OrgGroupFormFields'
import styles from './OrgGroupForm.scss'


type Props = {
  onValueExist?: (hasValue: boolean) => void
  onResetErrors?: () => void
  error?: string
  defaultValue?: string
}

const OrgGroupForm = ({ onValueExist, error, onResetErrors, defaultValue = '' }: Props) => {
  return (
    <>
      <PlainLabel htmlFor="name" displayAsBlock skipUpperSpace>
        {'Наименование группы'}
      </PlainLabel>

      <PlainInput
        id="name"
        name={FormField.Name}
        className={styles.nameInput}
        onValueExist={onValueExist}
        onChange={onResetErrors}
        defaultValue={defaultValue}
      />

      <PlainHelperText error>{error}</PlainHelperText>
    </>
  )
}

export default OrgGroupForm
