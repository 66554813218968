// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import always from 'ramda/es/always';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as st from 'app/actions/reports/filters/subsidyTypes';
import * as availableSubsidyTypes from 'app/actions/reports/filters/subsidyTypesAvailable';

// import { selectOrgId } from './hocs/commonSelectors';
import styles from './FilterItems.scss';


const emptyObj = Object.freeze({});
const selectIsEnableDefault = always(true);

type SubsidyTypes = {
  [key: string]: {
    id: $Values<typeof availableSubsidyTypes>,
    title: string,
  }
}

type Props = {
  resetStatus: boolean,
  selectIsEnable?: () => boolean,
}

const SubsidyTypesFilter = ({ resetStatus, selectIsEnable = selectIsEnableDefault }: Props) => {
  const dispatch = useDispatch();
  const itemsObj: SubsidyTypes = useSelector(state => state.reports.filters.subsidyTypes.data, shallowEqual);

  // выбранное
  const selectedItems: SubsidyTypes = useSelector(state => state.reports.filters.subsidyTypes.selected, shallowEqual);
  // и массив с выбранным
  const selectedArr: Array<$Values<typeof availableSubsidyTypes>> = mapObjPropToArray('id')(selectedItems);

  const handleReset = useCallback(() => {
    dispatch(action(st.SUBSIDY_TYPES_FILTER_SET, emptyObj));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(st.SUBSIDY_TYPES_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(st.SUBSIDY_TYPES_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  to ENABLE or not to ENABLE
   */

  const isEnable = useSelector(selectIsEnable);
  useEffect(() => {
    dispatch(action(st.SUBSIDY_TYPES_FILTER_UPDATE, { active: isEnable }));
  }, [isEnable]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isEnable) {
    return null;
  }

  return (
    <PlainFilterComplete
      id="subsidyTypes"
      className={styles.filter}
      title="Вид справки:"
      modalTitle="Выбор вида справки"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default SubsidyTypesFilter;
