// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray, isSingleWithId } from 'app/common/lib/object';
import * as statuses from 'app/common/constants/students/statuses';
import action from 'app/common/lib/action';
import * as sa from 'app/actions/reports/filters/studentStatuses';

import { selectOrgId } from './hocs/commonSelectors';
import styles from './FilterItems.scss';


const emptyObj = {};

const selectIsEnable = createSelector(
  [
    selectOrgId,
    state => state.reports.filters.customers.selected,
    state => state.reports.filters.date.current,
  ],
  (orgId, customers, current) => !!(
    orgId
    && isSingleWithId('Учащиеся')(customers)
    && current
  ),
);

type StatusesType = {
  [key: string]: {
    id: $Values<typeof statuses>,
    title: string,
  }
}

type Props = {
  resetStatus: boolean,
}

const StudentStatusFilter = ({ resetStatus }: Props) => {
  const dispatch = useDispatch();
  const itemsObj: StatusesType = useSelector(state => state.reports.filters.studentStatuses.data, shallowEqual);

  // выбранное
  const selectedItems: StatusesType = useSelector(state => state.reports.filters.studentStatuses.selected, shallowEqual);
  // и массив с выбранным
  const selectedArr: Array<$Values<typeof statuses>> = mapObjPropToArray('id')(selectedItems);


  const handleReset = useCallback(() => {
    dispatch(action(sa.STUDENT_STATUSES_FILTER_SET, emptyObj));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(sa.STUDENT_STATUSES_FILTER_SET, submitted));
  }, [dispatch]);


  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(sa.STUDENT_STATUSES_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   *  to ENABLE or not to ENABLE
   */

  const isEnable = useSelector(selectIsEnable);
  useEffect(() => {
    dispatch(action(sa.STUDENT_STATUSES_FILTER_UPDATE, { active: isEnable }));
  }, [isEnable]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isEnable) {
    return null;
  }

  return (
    <PlainFilterComplete
      id="studentStatus"
      className={styles.filter}
      title="Статус учащегося:"
      modalTitle="Выбор статуса учащегося"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default StudentStatusFilter;
