import { Pair } from 'app/dataTypes/subsidy/settings'


export const mustBePair = (value: [boolean | undefined, boolean | undefined]): Pair => {
  const [val1, val2] = value
  return [
    typeof val1 === 'boolean' ? val1 : false,
    typeof val2 === 'boolean' ? val2 : false,
  ]
}
