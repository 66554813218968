import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import PlainFieldset from 'app/common/ui-next/plain-fieldset'
import PlainLabel from 'app/common/ui-next/plain-label'
import { SkeletonLoading } from 'app/common/templates-next/page-template'
import PlainRadioButton from 'app/common/ui-next/plain-radio-button'


type Props = {
  name: string
  items: Array<{ ID: string, title: string }>
  enabledItemIDs: Set<string>
  selected: string | null
  loading?: boolean
  form?: string
  hideLabel?: boolean
  disabled?: boolean
}

export const ComplexesDefaults = ({
  name,
  items,
  enabledItemIDs,
  selected,
  loading,
  form,
  hideLabel,
  disabled,
}: Props) => {
  const [checked, setChecked] = useState(selected)
  const handleCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked: newState } = e.currentTarget
    if (newState) {
      setChecked(value)
    }
  }, [])

  useEffect(() => {
    if (!checked) {
      setChecked(enabledItemIDs.values().next()?.value || null)
      return
    }
    if (checked && !enabledItemIDs.has(checked)) {
      setChecked(null)
    }
  }, [enabledItemIDs, checked])

  const legend = 'Выбор комплекта по умолчанию:'

  if (loading) {
    return (
      <SkeletonLoading />
    )
  }

  return (
    <>
      <PlainLabel id={name} hidden={hideLabel}>{legend}</PlainLabel>

      <PlainFieldset
        skipUpperSpace={!hideLabel}
        ariaLabelledby={name}
        name={name}
      >
        {items.map(({ ID, title }) => (
          <PlainRadioButton
            key={ID}
            label={title}
            name={name}
            value={ID}
            checked={checked === ID}
            onChange={handleCheck}
            disabled={disabled || !enabledItemIDs.has(ID)}
            form={form}
          />
        ))}
      </PlainFieldset>
    </>
  )
}
