import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import {
  dishesRemoveItems as dishesRemoveItemsAction,
  dishesMoveItems as dishesMoveItemsAction,
} from 'app/actions/orgs/dishes/selection';

import selectNumbers from './selectNumbers';
import selectItemsList from './selectItemsList';
import selectCategories from './selectCategories';


const enhanceChangeItems = compose(
  connect(
    (state, props) => ({
      selection: state.orgs.dishes.selection.items,
      numText: selectNumbers(state, props),
      // itemsText: selectItemsText(state, props),
      itemsList: selectItemsList(state, props),
      categories: selectCategories(state, props),
    }),
    (dispatch, { orgId }) => ({
      dishesRemoveItems: arr => dispatch(dishesRemoveItemsAction(orgId, arr)),
      dishesMoveItems: (categoryId, items) => dispatch(dishesMoveItemsAction(orgId, categoryId, items)),
    }),
  ),

  withState('isDropdown', 'setDropdown', false),
  withState('isMoveModal', 'setMoveModal', false),
  withState('isDeleteModal', 'setDeleteModal', false),
  withState('currentCategoryId', 'setCurrentCategoryId', 0),

  withHandlers({
    handleToggleMenu: ({ isDropdown, setDropdown }) => (e) => {
      e.preventDefault();
      setDropdown(!isDropdown);
    },
    handleCloseMenu: ({ setDropdown }) => () => {
      setDropdown(false);
    },
    handleMoveModalToggle: ({ isMoveModal, setMoveModal, setDropdown }) => (e) => {
      e.preventDefault();
      setDropdown(false);
      setMoveModal(!isMoveModal);
    },
    handleChangeCategory: ({ setCurrentCategoryId }) => (e) => {
      setCurrentCategoryId(parseInt(e.currentTarget.value, 10));
    },
    handleMove: ({
      currentCategoryId,
      selection,
      dishesMoveItems,
      setMoveModal,
      updateCategories,
    }) => async (e) => {
      e.preventDefault();
      await dishesMoveItems(currentCategoryId, selection);
      updateCategories();
      setMoveModal(false);
    },
    handleDeleteModalToggle: ({ isDeleteModal, setDeleteModal, setDropdown }) => (e) => {
      e.preventDefault();
      setDropdown(false);
      setDeleteModal(!isDeleteModal);
    },
    handleDropdownToggle: ({ setDropdown }) => () => {
      setDropdown(false);
    },
    handleDelete: ({ selection, dishesRemoveItems, setDeleteModal, updateCategories }) => async (e) => {
      e.preventDefault();
      await dishesRemoveItems(selection);
      updateCategories();
      setDeleteModal(false);
    },
  }),
);

export default enhanceChangeItems;
