import cn from 'classnames/bind'

import Th, { type ThProps } from './th'
import styles from './th-label.scss'


/**
 * UI COMPONENTS v2
 *
 * Ячейка таблицы c функцией label
 * ------
 * Для случаев, когда нажатие на строку таблицы
 * должно переключить чек-бокс или radio
 */

const cx = cn.bind(styles)

type Props = {
  htmlFor: string,
} & ThProps

export const ThLabel = ({
  children,
  className,
  htmlFor,
  ...props
}: Props) => (
  <Th className={cx(styles.root, className)} {...props}>
    {children}
    <label // eslint-disable-line jsx-a11y/label-has-associated-control
      className={styles.label}
      htmlFor={htmlFor}
    />
  </Th>
)
