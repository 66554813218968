import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import lifecycle from 'recompose/lifecycle';
import renderNothing from 'recompose/renderNothing';
import isEmpty from 'ramda/es/isEmpty';
import includes from 'ramda/es/includes';

import {
  GROUP_FILTER_SET,
  GROUP_FILTER_UPDATE,
} from 'app/actions/reports/filters/group';

import { itemsNum } from 'app/common/lib/object';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import action from 'app/common/lib/action';

import { selectCustomerId } from './commonSelectors';


export const selectIsEnableDefault = createSelector(
  [
    state => state.reports.filters.orgs.selected,
    state => state.reports.filters.customers.data,
    state => state.reports.filters.customers.selected,
    selectCustomerId,
    state => state.reports.filters.details,
  ],
  (orgsSelected, customers, customersSelected, customerId, details) => (
    details
    && itemsNum(orgsSelected) === 1
    && (
      customerId === 'Учащиеся'
      || customerId === 'Сотрудники'
      || itemsNum(customersSelected) === itemsNum(customers)
      || isEmpty(customersSelected)
    )
  ),
);

export default compose(
  connect(
    (state, { selectIsEnable }) => {
      const selectIsEnableStatus = selectIsEnable || selectIsEnableDefault;

      return ({
        group: state.reports.filters.group.current,
        enabled: selectIsEnableStatus(state),
      });
    },
    (dispatch, { pair }) => ({
      handleChange: (e) => {
        const { value, checked } = e.currentTarget;
        if (!includes(value, pair)) return;
        if (checked) {
          dispatch(action(GROUP_FILTER_SET, value));
        }
      },
      reset: () => {
        dispatch(action(GROUP_FILTER_SET, pair[0]));
      },
      dispatch,
    }),
  ),

  lifecycle({
    componentDidMount() {
      const { reset } = this.props;
      reset();
    },
    componentDidUpdate(prevProps) {
      const { enabled, dispatch } = this.props;
      if (enabled !== prevProps.enabled) {
        dispatch(action(GROUP_FILTER_UPDATE, { active: enabled }));
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { reset } = this.props;
      reset();
    },
  }),

  branch(({ enabled }) => !enabled, renderNothing),
);
