// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import IconOk from './icons/IconOk';
import EyeIcon from './icons/EyeIcon';
import EyeOffIcon from './icons/EyeOffIcon';
import styles from './LineInput.scss';


const cx = classNames.bind(styles);

type Props = {
  value: string,
  onChange: (e: SyntheticEvent<HTMLElement>) => void,
  name?: string,
  placeholder?: string,
  isError?: boolean,
  errorText?: string,
  isValid?: boolean,
  type?: string,
  disabled?: boolean,
  locked?: boolean,
  className?: string,
  bold?: boolean,
  icon?: any,
  align?: string,
  focused?: string,
  [key: string]: mixed,
  comment?: string,
  wide?: boolean,
};

type State = {
  isPassShow: boolean,
  isFocus: boolean,
};

class LineInput extends React.Component<Props, State> {
  static defaultProps = {
    isError: false,
    isValid: false,
  };

  state = {
    isPassShow: false,
    isFocus: false,
  };

  handleToggle = (e: Object) => {
    e.preventDefault();
    this.setState(
      ({ isPassShow }) => ({ isPassShow: !isPassShow }),
    );
    if (this.input) {
      this.input.focus();
    }
  };

  handleFocus = () => {
    this.setState({ isFocus: true });
  };

  handleBlur = () => {
    this.setState({ isFocus: false });
  };

  input: ?HTMLInputElement;

  render() {
    const {
      value,
      onChange,
      name,
      placeholder,
      isError,
      errorText,
      isValid,
      type,
      disabled,
      locked,
      className,
      bold,
      icon,
      align,
      focused,
      comment,
      wide,
      errorClassName,
      ...other
    } = this.props;
    const { isFocus, isPassShow } = this.state;
    const alignClass = align || 'center';
    return (
      <div className={className}>
        <div className={styles.main}>
          <label
            htmlFor={name}
            className={cx({
              label: true,
              focused: isFocus || value || typeof value === 'number',
              [alignClass]: true,
              disabled,
            })}
          >
            {placeholder}
          </label>
          <input
            ref={(r) => { this.input = r; }}
            className={cx({
              input: true,
              iconed: type === 'password' || icon,
              disabled,
              [alignClass]: true,
            })}
            name={name}
            id={name}
            value={value}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            type={(type === 'password' && !isPassShow) ? 'password' : 'text'}
            disabled={disabled}
            placeholder={isFocus ? focused : null}
            {...other}
          />
          {isValid && <div className={styles.ok}><IconOk /></div>}
          <div className={styles.icons}>
            {type === 'password'
              && <a href="passShow" onClick={this.handleToggle}>
                {isPassShow
                  ? <EyeOffIcon className={styles.eye} />
                  : <EyeIcon className={styles.eye} />}
              </a>}
            {icon}
          </div>
        </div>
        {!locked
          && <div
            className={cx({
              line: true,
              bold,
              active: !disabled && (isFocus || value),
              error: isError,
            })}
          />}
        <div
          className={cx(
            styles.errorText,
            styles[alignClass],
            {
              errorColor: isError,
              comment: comment && !errorText,
              error: isError,
              wide,
            },
          )}
        >
          {isError && errorText && errorText}
          {!errorText && comment}
          {!errorText && !comment && '\xa0'}
        </div>
      </div>
    );
  }
}

export default LineInput;
