// @flow
import { useEffect, useCallback, Fragment, ChangeEvent } from 'react'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import { foodModes as fm } from 'app/dataTypes/foodModes'

import { Restrictions } from './useRestrictions'
import useDayCareTitleById from './useDayCareTitleById'
import useCampTitleById from './useCampTitleById'
import RestrictionsAllowances from './RestrictionsAllowances'
import Shift from './Shift'
import CheckGroup from './CheckGroup'
import useMemoKeys from './useMemoKeys'


const getFoodModeTitle = (key: string) => {
  switch (key) {
    case fm.Afternoon:
      return 'Полдник'
    case fm.Breakfast:
      return 'Завтрак'
    case fm.Dinner:
      return 'Обед'
    default:
  }
  return '[Неизвестный режим]'
}


const getShiftTitle = (key: string) => {
  switch (key) {
    case 'shift_1':
      return 'I смена'
    case 'shift_2':
      return 'II смена'
    case 'shift_3':
      return 'III смена'
    default:
  }
  return '[Неизвестный режим]'
}

type Props = {
  orgID: string
  disabled: boolean
  restrictions: Restrictions
  onLoadFormData: () => Promise<void>
  onChange: (key: string, checked: boolean) => void
  onUpdateAllowances: (updateFunc: (a: Array<string>) => Array<string>) => void
}

const RestrictionsSettingsForm = ({
  orgID,
  disabled,
  restrictions,
  onLoadFormData,
  onChange,
  onUpdateAllowances,
}: Props) => {
  useEffect(() => {
    onLoadFormData()
  }, [onLoadFormData])

  const {
    for_school_employees: forSchoolEmployees,
    for_supplier_employees: forSupplierEmployees,
    for_students: forStudents,
    for_students_without_subsidies: forStudentsWithoutSubsidies,
    for_allowances: forAllowances,
    allowances,
    for_parallels: forParallels,
    parallels,
    for_food_modes: forFoodModes,
    food_modes: foodModes,
    for_day_cares: forDayCares,
    day_cares: dayCares,
    for_ages: forAges,
    ages,
    for_camps: forCamps,
    camps,
    for_shifts: forShifts,
    shifts,
  } = restrictions as Restrictions

  const dayCareIds = useMemoKeys(dayCares)
  const getDayCareTitle = useDayCareTitleById(dayCareIds)

  const campIds = useMemoKeys(camps)
  const getCampTitle = useCampTitleById(campIds)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget
    onChange(value, checked)
  }, [onChange])

  return (
    <Fragment>
      <PlainCheckbox
        value="for_school_employees"
        id="for_school_employees"
        label="Для сотрудников ОУ"
        disabled={disabled}
        checked={forSchoolEmployees}
        onChange={handleChange}
      />
      <PlainCheckbox
        value="for_supplier_employees"
        id="for_supplier_employees"
        label="Для сотрудников ПП"
        disabled={disabled}
        checked={forSupplierEmployees}
        onChange={handleChange}
      />
      <PlainCheckbox
        value="for_students"
        id="for_students"
        label="Для учеников"
        disabled={disabled}
        checked={forStudents}
        onChange={handleChange}
      />

      {forStudents
        && <Shift>
          <PlainCheckbox
            value="for_students_without_subsidies"
            id="for_students_without_subsidies"
            label="БЕЗ льгот"
            disabled={disabled}
            checked={forStudentsWithoutSubsidies}
            onChange={handleChange}
          />

          <RestrictionsAllowances
            label="имеющих льготы"
            value="allowances"
            orgID={orgID}
            allowances={allowances}
            allowancesChecked={forAllowances}
            disabled={disabled}
            onChangeRoot={onChange}
            onUpdateAllowance={onUpdateAllowances}
          />

          {/* <RestrictionsAllowances
            type="camp"
            label="имеющих льготы для питания в лагере"
            value="allowances_camp"
            orgID={orgID}
            allowances={allowancesCamp}
            allowancesChecked={forAllowancesCamp}
            disabled={disabled}
            onChange={onChange}
            onUpdateAllowance={onUpdateAllowances}
          /> */}

          {/* Параллели */}

          <CheckGroup
            title="из параллели"
            checked={forParallels}
            disabled={disabled}
            onChange={handleChange}
            data={parallels}
            dataKey="parallels"
          />

          {/* возраст */}

          <CheckGroup
            title="возраста"
            checked={forAges}
            disabled={disabled}
            onChange={handleChange}
            data={ages}
            dataKey="ages"
          />

          {/* ГПД */}

          <CheckGroup
            title="из ГПД"
            checked={forDayCares}
            disabled={disabled}
            onChange={handleChange}
            data={dayCares}
            dataKey="day_cares"
            getSubTitle={getDayCareTitle}
          />

          {/* Лагеря */}

          <CheckGroup
            title="из групп лагеря"
            checked={forCamps}
            disabled={disabled}
            onChange={handleChange}
            data={camps}
            dataKey="camps"
            getSubTitle={getCampTitle}
          />

          {/* Режим питания */}
          <CheckGroup
            title="с режимом питания"
            checked={forFoodModes}
            disabled={disabled}
            onChange={handleChange}
            data={foodModes}
            dataKey="food_modes"
            getSubTitle={getFoodModeTitle}
          />

          {/* Смены */}
          <CheckGroup
            title="из смен"
            checked={forShifts}
            disabled={disabled}
            onChange={handleChange}
            data={shifts}
            dataKey="shifts"
            getSubTitle={getShiftTitle}
          />
        </Shift>}
    </Fragment>
  )
}

export default RestrictionsSettingsForm
