// @flow
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';


const detailsItems = () => ([[{
  id: 'details',
  title: 'Детализированный отчет',
}], defaultStateDataCurrent]);

export default detailsItems;
