import { useCallback, ReactNode } from 'react'
import cn from 'classnames/bind'
import { isEmpty } from 'ramda'

import { DotsLoader } from 'app/common/templates-next/page-template'

import PlainButtonAsLink from '../plain-button-as-link'
import PlainBadge, { PlainBadgesGroup } from '../plain-badge'
import useSelectorState from './use-selector-state'
import HTMLSelect from './html-select'

import styles from './plain-selector.scss'

/**
 *  Компонент-основа для различного вида селекторов (PlainItemsSelector)
 *  Содержит кнопки-ссылки "Выбрать", "Сбросить выбор"
 *  и блок выбранного в виде набора PlainBadges.
 *  Имеет внутреннее состояние
 */

const cx = cn.bind(styles)

export type PlainSelectorProps = {
  id?: string
  className?: string
  classNameLabel?: string
  classNameBadges?: string
  loading?: boolean
  disabled?: boolean
  label?: string
  buttonResetLabel?: string
  colorInverse?: boolean
  selectedAllOnEmptySelection?: boolean
  selectedAllText?: string
  pleaseSelectText?: string
  selection?: Array<string>
  defaultSelection?: Array<string>
  skipUpperSpace?: boolean
  onChange?: (items: Array<string>) => void
  // функции makeSortable и makeTitle для получения соответствующих полей для badges
  // так как при инициализации выбранного используется массив только id (поле value)
  makeSortable?: (val: string) => number | string
  makeTitle?: (val: string) => string
  children: (
    items: Array<string>,
    update: (x: Array<string>) => void,
  ) => ReactNode
  hideButtonReset?: boolean
  hideBadgesIfNothingSelected?: boolean
  hideLegendIfSelection?: boolean
  badgeMaxWidth?: number
  onBadgeCrossClick?: (ID: string, removeItemFn: (ID: string) => void) => void
  onBadgeTextClick?: (ID: string) => void
  multiple?: boolean
}

const PlainSelector = ({
  id,
  className,
  classNameLabel,
  classNameBadges,
  loading,
  disabled,
  label = 'Селектор',
  buttonResetLabel = 'Сбросить выбор',
  colorInverse,
  selectedAllOnEmptySelection = true,
  selectedAllText = 'Все',
  pleaseSelectText = 'Выберите',
  selection,
  defaultSelection,
  skipUpperSpace,
  onChange,
  makeSortable,
  makeTitle,
  children,
  hideButtonReset = false,
  hideBadgesIfNothingSelected = false,
  hideLegendIfSelection = false,
  badgeMaxWidth,
  onBadgeCrossClick,
  onBadgeTextClick,
  multiple,
}: PlainSelectorProps) => {
  const {
    itemsSelected,
    valuesSelected,
    update,
    remove,
    reset,
  } = useSelectorState({
    makeSortable,
    makeTitle,
    defaultSelection,
    selection,
    onChange,
  })

  const handleCrossClick = useCallback((_: any, ID: string) => {
    if (onBadgeCrossClick) {
      onBadgeCrossClick(ID, remove)
      return
    }
    remove(ID)
  }, [remove, onBadgeCrossClick])

  const handleTextClick = useCallback((_: any, ID: string) => {
    if (onBadgeTextClick) onBadgeTextClick(ID)
  }, [onBadgeTextClick])

  const nothingSelected = isEmpty(itemsSelected)
  const hideBadges = hideBadgesIfNothingSelected && nothingSelected
  const hideLegend = hideLegendIfSelection && !nothingSelected

  return (
    <div className={cx(styles.root, { skipUpperSpace }, className)}>
      <div className={cx(styles.layoutDefault, { hideBadges, hideLegend })}>
        <fieldset className={styles.fieldset}>
          <legend className={cx(styles.label, classNameLabel)}>{label}</legend>

          {loading && <DotsLoader />}

          {/* children c кнопкой Выбрать и модальным окном, вызываемым на эту кнопку */}
          {!loading
            && (
              <div className={styles.btSelect}>
                {children(valuesSelected, update)}
              </div>
            )}

          {/* bt Сбросить выбор */}
          {buttonResetLabel
            && !hideButtonReset
            && (
              <PlainButtonAsLink
                className={styles.btReset}
                linkClassName={styles.btResetLink}
                disabled={disabled}
                onClick={reset}
              >
                {buttonResetLabel}
              </PlainButtonAsLink>
            )}

          {!hideBadges
            && <PlainBadgesGroup className={cx(styles.badgesView, classNameBadges)}>
              {/* Выберите */}
              {nothingSelected
                && !selectedAllOnEmptySelection
                && (
                  <PlainBadge
                    light={colorInverse}
                    id="nothingSelected"
                    title={pleaseSelectText}
                    disabled={disabled}
                  />
                )}

              {/* 'Все' */}
              {nothingSelected
                && selectedAllOnEmptySelection
                && (
                  <PlainBadge
                    light={colorInverse}
                    id="all"
                    title={selectedAllText}
                    disabled={disabled}
                  />
                )}

              {itemsSelected.map(({ value, title }) => (
                <PlainBadge
                  key={value}
                  light={colorInverse}
                  id={value}
                  title={title}
                  width={badgeMaxWidth}
                  disabled={disabled}
                  onClose={handleCrossClick}
                  onClick={onBadgeTextClick ? handleTextClick : undefined}
                />
              ))}
            </PlainBadgesGroup>}
        </fieldset>
      </div>

      <HTMLSelect
        name={id}
        selection={valuesSelected}
        multiple={multiple}
        items={itemsSelected}
      />
    </div>
  )
}

export default PlainSelector
