import {
  EMPLOYEE_HISTORY_SUCCESS,
  EMPLOYEE_HISTORY_CLEAR,
} from 'app/actions/employees/employee/settings/history';

const defaultState = {
  changes: {},
  changesCount: 0,
};

const history = (state = defaultState, { type, payload }) => {
  switch (type) {
    case EMPLOYEE_HISTORY_SUCCESS: {
      return { ...defaultState, ...payload };
    }
    case EMPLOYEE_HISTORY_CLEAR: {
      return defaultState;
    }
    default:
  }
  return state;
};

export default history;
