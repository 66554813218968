// @flow
import { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import evolve from 'ramda/es/evolve';
import append from 'ramda/es/append';
import map from 'ramda/es/map';
import filter from 'ramda/es/filter';


export type RequiredState = {
  general: Array<string>,
  documents: Array<string>,
  contacts: Array<string>,
  address: Array<string>,
}

const defaultRequiredByGroup: RequiredState = {
  general: ['name.first', 'name.last'],
  documents: [],
  contacts: [],
  address: [],
};

export default function useRequired() {
  const [requiredByGroup, setRequiredByGroup] = useState(defaultRequiredByGroup);

  const snilsRequired = useSelector(state => state.dealer.testFields, shallowEqual);

  useEffect(() => {
    // добавление snils в required, если установлен флаг testFields
    setRequiredByGroup(evolve({ documents: append('snils') }));
  }, [snilsRequired]);

  // возвращает объект на основе состояния, но только с пустыми (ошибочными) полями
  const testRequired = useCallback((testIsEmpty: (field: string) => boolean): RequiredState => {
    return map(filter(testIsEmpty), requiredByGroup);
  }, [requiredByGroup]);

  return testRequired;
}
