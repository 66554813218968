// @flow
import { filter, map, isEmpty } from 'ramda';
import moment from 'moment-timezone';

import { getValue } from 'app/common/ui-components/filter';
import orgsFilter from 'app/components/common/reports/filters/org';
import classFilter from 'app/components/common/reports/filters/class';
import customerTypeFilter from 'app/components/common/reports/filters/customerType';
import studentFilter from 'app/components/common/reports/filters/student';
import employeeFilter from 'app/components/common/reports/filters/employee';
import sellTypeFilter from 'app/components/common/reports/filters/sellType';
import detailsFilter from 'app/components/common/reports/filters/details';
import buildingFilter from 'app/components/common/reports/filters/building';
import sellPointFilter from 'app/components/common/reports/filters/sellPoint';
import foodModeFilter from 'app/components/common/reports/filters/foodMode';
// import studentSubsidyTypeFilter from 'app/components/common/reports/filters/studentSubsidyType';
import orderTypeFilter from 'app/components/common/reports/filters/orderType';
import yearFilter from 'app/components/common/reports/filters/year';
import { orderTypes } from 'app/dataTypes/reports/orderTypes';
import subsidyFilter from 'app/components/common/reports/filters/subsidy';
import { dateFormat } from 'app/common/api/requests/reports/perform';

import periodFilter, { dateFormat as dateFormatPeriod } from '../ReportFilter/filters/period';
import type { RequestParams } from './types';


type State = { [key: string]: any }

const toNumbers = map(val => parseInt(val, 10));

const generateParamsByOrgs = (state: State): RequestParams => {
  const [detailsFilterValue] = getValue(detailsFilter, state);
  const [from, to] = getValue(periodFilter, state);

  const customerType = getValue(customerTypeFilter, state);

  const limits = filter(Boolean, {
    school_ids: toNumbers(getValue(orgsFilter, state)),
    klass_ids: toNumbers(getValue(classFilter, state)),
    customer_type: isEmpty(customerType) ? undefined : customerType,
    student_ids: toNumbers(getValue(studentFilter, state)),
    employee_ids: toNumbers(getValue(employeeFilter, state)),
    sale_type: getValue(sellTypeFilter, state)[0],
    building_id: toNumbers(getValue(buildingFilter, state)),
    sale_point_id: toNumbers(getValue(sellPointFilter, state)),
    food_mode: getValue(foodModeFilter, state),
    // subsidies: getValue(studentSubsidyTypeFilter, state),
    order_type: getValue(orderTypeFilter, state)[0] || orderTypes.All,
    allowances: getValue(subsidyFilter, state),
    schoolyear_id: parseInt(getValue(yearFilter, state)[0], 10),
  });

  const mDateFrom = moment(from, dateFormatPeriod);
  const mDateTo = moment(to, dateFormatPeriod);

  return filter(Boolean, {
    from: mDateFrom.format(dateFormat),
    to: mDateTo.format(dateFormat),
    limits,
    details: detailsFilterValue,
    details_group: 'by_group_complexes',
  });
};

export default generateParamsByOrgs;
