import { useCallback, useMemo } from 'react'
import equals from 'ramda/es/equals'
import all from 'ramda/es/all'


type Requests = Array<(ids?: Array<number>) => Promise<boolean>>

export default function useOverallSubmit(
  requestsNonMemoized: Requests
): (ids?: Array<number>) => Promise<boolean> {
  const requestsMemoized = useMemo(() => (
    requestsNonMemoized
  ), requestsNonMemoized) // eslint-disable-line react-hooks/exhaustive-deps

  const submitAll = useCallback(async (overrideOrgIds?: Array<number>) => {
    const res = await Promise.all(requestsMemoized.map(cb => cb(overrideOrgIds)))
    return all(equals(true), res)
  }, [requestsMemoized])

  return submitAll
}
