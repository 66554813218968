// @flow
import React from 'react';

import { Tr, Th } from 'app/common/ui-components/table';
import { useCurrencySign, options } from 'app/common/components-next/currency';

import { MultiLineTotalTitles, CellRestriction, restrictions } from '../MultiLineCell';
import { titleColumnId, totalRowId } from './columnsAndRowsIds';


const emptyProps = Object.freeze({});

type Props = {
  hidden?: boolean,
  showDuty?: boolean,
}

const LeftColumnFooter = ({ hidden, showDuty }: Props) => {
  const commaCurrency = useCurrencySign(options.COMMA_SPACE_BEFORE);

  const props = hidden ? emptyProps : {
    headers: titleColumnId,
    id: totalRowId,
    alignRight: true,
    scope: 'row',
  };

  return (
    <tfoot>
      <Tr
        hidden={hidden}
        footer
      >
        <Th stripPadding {...props}>
          <CellRestriction type={restrictions.COMMON}>
            <MultiLineTotalTitles
              showDuty={showDuty}
              title2={`Без учета дотаций${commaCurrency}:`}
              title3={`Дотации${commaCurrency}:`}
              title4={`Итого${commaCurrency}:`}
            />
          </CellRestriction>
        </Th>
      </Tr>
    </tfoot>
  );
};

export default LeftColumnFooter;
