// @flow
import React, { type Node, useState, useCallback, useMemo, useEffect } from 'react';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  type HandleModalDisplayed,
} from 'app/common/ui-next/plain-modal';

import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs';
import usePopup from 'app/common/hooks/usePopup';
import type { ParamsPOST } from 'app/dataTypes/vending/PlanogramCopy.types';

import useCopySlots from './useCopySlots';
import useMachinesState from './useMachinesState';
import CopyFrom from './CopyFrom';
import useCopySlotsParams from './useCopySlotsParams';
import useErrorState from './useErrorState';
import { ERROR_FROM, ERROR_TO } from './errors';

import styles from './CopySlotsModal.scss';


const TAB_FROM: 1 = 1;
const TAB_TO: 2 = 2;

type Props = {
  children: (HandleModalDisplayed) => Node,
  currentMachineId: ?string,
  onSuccess?: (ParamsPOST) => void
}

const CopySlotsModal = ({ children, onSuccess, currentMachineId }: Props): Node => {
  const popup = usePopup();
  const [tab, setTab] = useState<number>(TAB_FROM);

  const direction = tab === TAB_FROM ? 'from' : 'to';
  const machinesErrorCode = tab === TAB_FROM ? ERROR_FROM : ERROR_TO;
  const [selectedMachineIds, machinesStateHandlers] = useMachinesState(machinesErrorCode);

  const {
    updateMachineIds,
    checkErrors: checkMachinesErrors,
    reset: resetSelectedMachine,
  } = machinesStateHandlers;

  const params = useCopySlotsParams(selectedMachineIds, direction);
  const [pending, submit] = useCopySlots(params, onSuccess);

  const errorCheckHandlers = useMemo(() => ([checkMachinesErrors]), [checkMachinesErrors]);
  const [errors, checkErrors, resetErrors] = useErrorState(errorCheckHandlers);

  useEffect(() => {
    resetErrors();
  }, [selectedMachineIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetErrors();
    resetSelectedMachine();
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitModal = useCallback(async () => {
    if (!checkErrors()) return false;
    const res = await submit();
    if (res) {
      popup('Планограмма успешно скопирована');
    }
    return res;
  }, [submit, popup, checkErrors]);

  return (
    <PlainModal
      formId="copy_machine_slots"
      controlBy={children}
      onSubmit={submitModal}
      className={styles.root}
    >
      <PlainModalHeader>
        {'Копирование планограммы'}
      </PlainModalHeader>

      <PlainModalContent className={styles.tabContent}>
        <Tabs value={tab} onChange={setTab}>
          <Tab index={TAB_FROM}>{'Из другого аппарата'}</Tab>
          <Tab index={TAB_TO}>{'В другой аппарат'}</Tab>
        </Tabs>
      </PlainModalContent>

      <PlainModalContent className={styles.mainContent}>
        <div className={styles.bordered}>
          <TabPanels value={tab}>
            <TabPanel index={TAB_FROM}>
              <CopyFrom
                currentMachineId={currentMachineId}
                selectedMachineIds={selectedMachineIds}
                onSelectMachineIds={updateMachineIds}
                error={errors[ERROR_FROM]}
                selectorLabel="Из аппарата"
                selectorHeader="Выберите откуда копировать планограмму"
              />
            </TabPanel>

            <TabPanel index={TAB_TO}>
              <CopyFrom
                currentMachineId={currentMachineId}
                selectedMachineIds={selectedMachineIds}
                onSelectMachineIds={updateMachineIds}
                error={errors[ERROR_TO]}
                selectorLabel="В аппарат"
                selectorHeader="Выберите куда копировать планограмму"
              />
            </TabPanel>
          </TabPanels>
        </div>
      </PlainModalContent>

      <PlainModalFooter>
        {toggleModal => (
          <ControlsBar right>
            <PlainButton
              onClick={toggleModal}
              outline
            >
              {'Отмена'}
            </PlainButton>

            <PlainButton
              type="submit"
              disabled={pending}
            >
              {pending ? 'Копирование...' : 'Копировать'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  );
};

export default CopySlotsModal;
