import { combineReducers } from 'redux';

import orgs from './orgs';
import customers from './customers';
import years from './years';
import classes from './classes';
import students from './students';
import balanceTypes from './balanceTypes';
import balance from './balance';
import date from './date';
import details from './details';
import group from './group';
import sort from './sort';
import pack from './pack';
import employees from './employees';
import mail from './mail';
import commonBalance from './commonBalance';
import educationLevels from './educationLevels';
import subsidies from './subsidies';
import sumsAndKits from './sumsAndKits';
import dateFromTo from './dateFromTo';
import subsidySplit from './subsidySplit';
import studentStatuses from './studentStatuses';
import classTypes from './classTypes';
import subsidyTypes from './subsidyTypes';
import viewTypes from './viewTypes';
import orgsTypes from './orgsTypes';
import buildings from './buildings';
import sellPoints from './sellPoints';
import gpds from './gpds';
import salesTypes from './salesTypes';
import foodModes from './foodModes';
import groupSpecial from './groupSpecial';
import studentSubsidyTypes from './studentSubsidyTypes';


export default combineReducers({
  orgs,
  customers,
  years,
  classes,
  students,
  balanceTypes,
  balance,
  date,
  details,
  group,
  sort,
  pack,
  employees,
  mail,
  commonBalance,
  educationLevels,
  subsidies,
  sumsAndKits,
  dateFromTo,
  subsidySplit,
  studentStatuses,
  classTypes,
  subsidyTypes,
  viewTypes,
  orgsTypes,
  buildings,
  sellPoints,
  gpds,
  salesTypes,
  foodModes,
  groupSpecial,
  studentSubsidyTypes,
});
