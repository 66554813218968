// @flow
import React from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import LineInput from 'app/common/login/ui/LineInput';
import Modal from 'app/common/components/Modal';
import enhanceChangeLogin from './enhanceChangeLogin';
import styles from './ChangeLogin.scss';


type Props ={
  requesting: boolean,
  saving: boolean,
  requestCodeError: string,
  codeError: string,
  requestCode: string,
  code: string,
  handleRequestCode: (e: SyntheticEvent<HTMLElement>) => void,
  handleSubmitCode: (e: SyntheticEvent<HTMLElement>) => void,
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  handleLogout: (e: SyntheticEvent<HTMLElement>) => void,
  resultTo: string,
  requestLocked: number,
  emailForceAuth: boolean,
  t: (string, string | Object) => string,
};

const ChangeLogin = ({
  requesting,
  saving,
  requestCodeError,
  codeError,
  requestCode,
  code,
  handleRequestCode,
  handleSubmitCode,
  handleChange,
  handleLogout,
  resultTo,
  requestLocked,
  emailForceAuth,
  t,
}: Props) => {
  return (
    <Modal className={styles.root} modalClassName={styles.modal} overlayClassName={styles.overlay}>
      <a
        href="back"
        className={styles.back}
        onClick={handleLogout}
        title={t('return_to_login', 'Вернуться к вводу логина')}
      >
        {'←'}
      </a>
      <h1 className={styles.header}>
        {t('login_modal_header', 'Смена логина')}
      </h1>
      <p className={styles.info}>
        {t('temp_login_warning_line1', 'Вы вошли используя временный логин.')}
        <br />
        {t('temp_login_warning_line2', 'Для продолжения работы необходимо указать новый.')}
        <br />
      </p>
      <form onSubmit={handleRequestCode} className={styles.form}>
        <LineInput
          placeholder={emailForceAuth
            ? t('enter_email_placeholder', 'E-mail')
            : t('enter_email_or_phone_placeholder', 'Номер телефона или e-mail')}
          name="requestcode"
          value={requestCode}
          onChange={handleChange}
          isError={!!requestCodeError}
          errorText={requestCodeError}
        />
        <ControlsBar middle>
          <PlainButton
            type="submit"
            disabled={requesting || !requestCode || !!requestLocked}
          >
            {requestLocked
              ? t('next_request_over', { requestLocked }) // Повторный запрос через ${requestLocked} сек.
              : t('get_the_code', 'Получить код')}
          </PlainButton>
        </ControlsBar>
      </form>
      <form onSubmit={handleSubmitCode} className={styles.form}>
        <div className={styles.label}>
          {resultTo // Код выслан на адрес ${resultTo}
            && t('the_code_sent_to', { resultTo })}
        </div>
        <LineInput
          placeholder={t('enter_code_placeholder', 'Введите код')}
          name="code"
          value={code}
          onChange={handleChange}
          isError={!!codeError}
          errorText={codeError}
        />
        <ControlsBar middle>
          <PlainButton
            onClick={handleLogout}
            className={styles.close}
            outline
          >
            {t('logout', 'Выйти')}
          </PlainButton>
          <PlainButton
            type="submit"
            disabled={saving || !code}
          >
            {saving
              ? t('bt_login_submitting', 'Обновление...')
              : t('bt_login_submit', 'Обновить')}
          </PlainButton>
        </ControlsBar>
      </form>
    </Modal>
  );
};

export default enhanceChangeLogin(ChangeLogin);
