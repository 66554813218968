import partialUpdate from './partial-update'


enum Status {
  INIT = 'init',
  READY = 'ready',
  PENDING = 'pending'
}

const doNothing = () => { }

export const dataBoxes = new Map()

export default class DataBox<Data = any> {
  private _data: Data

  private _defaultData: Data

  private _id: string

  private _status: Status

  static Status = Status

  private _subscribers: Set<() => void> = new Set()

  private _statusSubscribers: Set<() => void> = new Set()

  private _beforeUnsubscribeCallbacks: Set<() => void> = new Set()

  // вызывается при реагировании на подписанный dataBox, но так же можно вызвать напрямую
  // для размещения запроса данных
  dataReceive: (dataReceived?: any, idFrom?: string) => void

  dataReceiveStop: () => void

  // вызывается после обновления через updateData
  // для размещения запроса на отправку данных
  dataTransmit: () => void

  constructor(id: string, data: Data) {
    this._data = data
    this._defaultData = data
    this._id = id
    this._status = DataBox.Status.INIT
    this.dataReceive = doNothing
    this.dataReceiveStop = doNothing
    this.dataTransmit = doNothing
  }

  static arrayToRecords<T extends { id: string, [key: string]: any }>(arr: Array<T>): Record<string, T> {
    return arr.reduce((acc, val) => {
      const { id } = val
      acc[id] = val
      return acc
    }, {})
  }

  subscribe = (callback: () => void, beforeUnsubscribe?: () => void) => {
    this._subscribers.add(callback)
    if (beforeUnsubscribe) this._beforeUnsubscribeCallbacks.add(beforeUnsubscribe)
    return () => {
      if (beforeUnsubscribe) beforeUnsubscribe()
      this._subscribers.delete(callback)
    }
  }

  updateSubscribers = () => {
    this._subscribers.forEach(callback => callback())
  }

  subscribeOnStatus = (callback: () => void) => {
    this._statusSubscribers.add(callback)
    return () => {
      this._subscribers.delete(callback)
    }
  }

  updateStatusSubscribers = () => {
    this._statusSubscribers.forEach(callback => callback())
  }

  protected setData(data: Data) {
    if (data !== this._data) {
      this._data = data
      this.updateSubscribers()
    }
  }

  resetToDefault() {
    this.setData(this._defaultData)
    this._status = DataBox.Status.INIT
  }

  setDefault(newDefData: Data) {
    this._defaultData = newDefData
  }

  reset() {
    this.dataReceive = doNothing
    this.dataReceiveStop = doNothing
    this.dataTransmit = doNothing
    this._data = this._defaultData
    this._beforeUnsubscribeCallbacks.forEach(cb => cb())
    this._subscribers = new Set()
    this._statusSubscribers = new Set()
  }

  protected setStatus = (s: Status): void => {
    if (this._status !== s) {
      this._status = s
      this.updateStatusSubscribers()
    }
  }

  setStatusReady = () => {
    this.setStatus(DataBox.Status.READY)
  }

  setStatusPending = () => {
    this.setStatus(DataBox.Status.PENDING)
  }

  getData = (): Data => {
    return this._data
  }

  get status() {
    return this._status
  }

  get pending(): boolean {
    return this._status === DataBox.Status.PENDING
  }

  get ready(): boolean {
    return this._status === DataBox.Status.READY
  }

  get init(): boolean {
    return this._status === DataBox.Status.INIT
  }

  get data() {
    return this._data
  }

  get id() {
    return this._id
  }

  getID() {
    return this.id
  }

  updateDataAsync = async (
    dataTransform: (data: Data) => Promise<Data | null>,
    cancelPrevios?: () => void,
  ) => {
    if (cancelPrevios && this.pending) {
      cancelPrevios()
    }
    this.setStatus(DataBox.Status.PENDING)
    const res = await dataTransform(this.data)
    if (res) {
      this.setData(res)
      this.setStatus(DataBox.Status.READY)
    }
  }

  updateData = (dataTransform: (data: Data) => Data) => {
    const res = dataTransform(this.data)
    if (res !== undefined) {
      this.setData(res)
      this.dataTransmit()
    }
  }

  setDataAndTransmit = async (data: Data) => {
    this.setData(data)
    const res = await this.dataTransmit()
    return res
  }

  partialUpdateData = (data: Partial<Data>) => {
    this.updateData(partialUpdate(data))
  }

  addDataReceiver<DataReceived>(
    dataReceiveAsync: (dataReceived: DataReceived, dataCurrent?: Data) => Promise<Data | null>,
    cancelPrevios?: () => void,
  ) {
    this.dataReceive = async function dataReceive(dataReceived: DataReceived) {
      this.updateDataAsync(
        (dataCurrent: Data) => dataReceiveAsync(dataReceived, dataCurrent),
        cancelPrevios,
      )
    }
    if (cancelPrevios) {
      this.dataReceiveStop = function dataReceiveStop() {
        cancelPrevios()
      }
    }

    return () => {
      this.dataReceive = doNothing
      this.dataReceiveStop = doNothing
    }
  }

  removeDataReceiver() {
    this.dataReceive = doNothing
    this.dataReceiveStop = doNothing
  }

  subscribeDataBox(box: DataBox) {
    // по умолчанию подписка не работает, пока не назначен dataReceive с помощью addDataReceiver
    const unsubscribe = this.subscribe(() => {
      box.dataReceive(this.data, this.id)
    }, box.dataReceiveStop)
    return unsubscribe
  }

  addDataTransmitter(dataTransmitAsync: (data: Data) => Promise<Data | null>) {
    this.dataTransmit = () => {
      this.updateDataAsync(dataTransmitAsync)
    }
  }

  removeDataTransmitter() {
    this.dataTransmit = doNothing
  }
}
