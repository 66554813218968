// @flow
import React from 'react';
import moment from 'moment-timezone';
import petrovich from 'petrovich';

import type { ModifyInfoType } from 'app/reducers/employees/employee/settings';
import { getFIO } from 'app/common/lib/names';

import Label from '../Label';
import Big from '../Big';
import noDatenoRender from '../noDateNoRender';
import styles from './ModifyInfo.scss';


type Props = {
  title: string,
  info: ModifyInfoType,
  className?: string,
}

const ModifyInfo = noDatenoRender(({
  title,
  className,
  info: {
    datetime,
    source,
    actor,
    description,
  },
}: Props) => (<div className={className}>
  <div><Label general>{title}</Label></div>
  <div><Big>{moment(datetime).format('D.MM.YYYY HH:mm')}</Big></div>
  <div className={styles.row}>
    <span>{getFIO(petrovich(actor, 'instrumental')) || 'неизвестным'}</span>
    <span>{' в '}</span>
    <span>{source || 'неизвестном месте'}</span>
  </div>
  {description
      && <div className={styles.row}>
        {description}
      </div>}
</div>));

export default ModifyInfo;
