// @flow
import React, { type Node } from 'react';

import styles from './CommentContainer.scss';


type Props = {
  children: Node,
}

const CommentContainer = ({ children }: Props) => {
  return (
    <div className={styles.root}>{children}</div>
  );
};

export default CommentContainer;
