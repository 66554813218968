import {
  ABOUT_REQUEST,
  ABOUT_SUCCESS,
  ABOUT_STATE_CLEAR,
} from 'app/common/actions/about';

const defaultState = {
  loading: true,
  provider: {},
  admin: {},
};

const about = (state = defaultState, action) => {
  switch (action.type) {
    case ABOUT_REQUEST: {
      return { ...state, loading: true };
    }

    case ABOUT_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }

    case ABOUT_STATE_CLEAR: {
      return defaultState;
    }
    default:
  }
  return state;
};

export default about;
