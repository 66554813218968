import { isCommonAllowance } from 'app/dataTypes/subsidy/allowances'

import { controls, selection } from './formParts/accrualFormControls'


export const checkErrorsAccruals = (err: Map<any, any>, formData: FormData, accrualsShiftsAmount: number) => {
  const allowanceID = formData.get('allowanceID') as string
  if (!allowanceID) throw new Error('Нужен параметр allowanceID в formData (checkErrorsAccruals)')
  const common = isCommonAllowance(allowanceID)

  for (let i = 0; i < accrualsShiftsAmount; i++) { // eslint-disable-line no-plusplus
    const num = i + 1
    const complexes = formData.getAll(`${controls.complexesList}${num}`)

    if (formData.get(`${controls.activeCheckbox}${num}`)) {
      if (common) {
        if (complexes.length === 0) {
          err.set(`${controls.complexesList}${num}`, 'Выберите хотя бы один комплект:')
        }
      }
      if (formData.get(`${controls.selectionRadio}${num}`) === selection.sum) {
        const sum: File | string | null = formData.get(`${controls.sumInput}${num}`)
        if (sum instanceof File || !sum || parseFloat(sum) <= 0) {
          err.set(`${controls.sumInput}${num}`, 'Требуется положительное ненулевое значение')
        }
        if (formData.get(`${controls.sumForComplexesCheckbox}${num}`)) {
          if (complexes.length === 0) {
            err.set(`${controls.complexesList}${num}`, 'Выберите хотя бы один комплект:')
          }
        }
      }
      if (formData.get(`${controls.selectionRadio}${num}`) === selection.complexes) {
        if (complexes.length === 0) {
          err.set(`${controls.complexesList}${num}`, 'Выберите хотя бы один комплект:')
        }
      }
    }
  }
}
