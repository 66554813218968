import { useEffect } from 'react'

import useSubsidySettings from 'app/dataProviders/subsidy/settings/hooks/useSubsidySettings'

import { CategoryCafeteriaSettings } from './CategoryCafeteriaSettings'
import { CategoryCampSettings } from './CategoryCampSettings'


type Props = {
  ID: string
  orgID: string,
  app: 'cafeteria' | 'camp'
  onDataUpdate: () => void
  onCreateFormID: (ID: string) => string
  updateSize?: () => void
  onSuccess?: () => void
  onPending?: (pending: boolean) => void
}

export const CategorySettings = ({
  ID,
  orgID,
  app,
  onDataUpdate,
  onCreateFormID,
  updateSize,
  onSuccess,
  onPending,
}: Props) => {
  const [settings, loading] = useSubsidySettings({ orgID, app, allowanceID: ID })

  useEffect(() => {
    onDataUpdate()
  }, [settings]) // eslint-disable-line react-hooks/exhaustive-deps

  if (settings.app === 'cafeteria') {
    return (
      <CategoryCafeteriaSettings
        ID={ID}
        orgID={orgID}
        settings={settings}
        loading={loading}
        onDataUpdate={onDataUpdate}
        onCreateFormID={onCreateFormID}
        updateSize={updateSize}
        onSuccess={onSuccess}
        onPending={onPending}
      />
    )
  }

  if (settings.app === 'camp') {
    return (
      <CategoryCampSettings
        ID={ID}
        orgID={orgID}
        settings={settings}
        loading={loading}
        onDataUpdate={onDataUpdate}
        onCreateFormID={onCreateFormID}
        updateSize={updateSize}
        onSuccess={onSuccess}
        onPending={onPending}
      />
    )
  }

  return null as never
}
