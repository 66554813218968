// @flow
import { useMemo } from 'react';
import { map, prop, addIndex } from 'ramda';

import toFixedValue from 'app/common/lib/toFixedValue';
import { tableColumnSortClass } from 'app/common/lib/sort';

import {
  sumToFixedValue,
  headerByTimeMode,
} from '../itsTimeMode';

import type {
  Group,
  Daily,
  Monthly,
  DailyTotals,
  MonthlyTotals,
} from '../types';


const mapIndexed = addIndex(map);

type GroupViewType = {
  id: number,
  title: string,
  data: Array<string>,
  total: number,
  totalString: string,
}

/**
 *  Получение данных групп (классы в основном), подготовленных для таблицы
 *  с сортировкой
 */

export default function useGroups(
  groups: Array<Group<Daily>> | Array<Group<Monthly>>,
  totals: Array<DailyTotals> | Array<MonthlyTotals>,
  sortKey: string,
  sortDirection: 'up' | 'down',
): [
  Array<string>,
  Array<GroupViewType>,
  Array<string>,
] {
  // подготовка основных данных для отображения в таблице
  const groupsActual = useMemo(() => (
    mapIndexed(({ klass_id: id, klass_title: title, customer_type: customer, total, data }, i) => ({
      id: id || i,
      title: title || customer || '',
      data: map(sumToFixedValue, data),
      total, // нужно для сортировки
      totalString: toFixedValue(total),
    }), groups)
  ), [groups]);

  // сортировка после -- так оптимальнее
  const groupsSorted = useMemo(
    () => tableColumnSortClass(sortDirection, prop(sortKey), groupsActual),
    [sortDirection, sortKey, groupsActual],
  );

  // подготовка данных для итоговой строки по всем выбранным школам
  const groupsTotals = useMemo(() => map(sumToFixedValue, totals), [totals]);

  // подготовка заголовков, в зависимости от типа периодов
  const headers = useMemo(() => map(headerByTimeMode, totals), [totals]);

  return [headers, groupsSorted, groupsTotals];
}
