import { createSelector } from 'reselect';
import { selectOrgId } from 'app/components/Reports/FilterItems/hocs/commonSelectors';

import { isSingle } from 'app/common/lib/object';


const selectIsEnableStudentsFilter = createSelector(
  [
    selectOrgId,
    state => state.reports.filters.classes.selected,
  ],
  (orgId, classId) => !!(
    orgId
    && isSingle(classId)
  ),
);

export default selectIsEnableStudentsFilter;
