import { ReactNode } from 'react'

import PlainModal, { PlainModalContent, PlainModalFooter, PlainModalHeader } from 'app/common/ui-next/plain-modal'
import PlainButton from 'app/common/ui-next/plain-button'
import ControlsBar from 'app/common/ui-next/controls-bar'

import useSubmit from './useSubmit'
import AdditionalSettingsForm from './AdditionalSettingsForm'


type Props = {
  orgID: string
  complexID: string
  buffetZone: boolean
  hotMealsZone: boolean
  denyParentMoneySales: boolean
  denyMoreThanOneSales: boolean
  useAsAdditional: boolean
  children: (toggle: () => void) => ReactNode
}

const AdditionalSettingsModal = ({
  orgID,
  complexID,
  buffetZone,
  hotMealsZone,
  denyParentMoneySales,
  denyMoreThanOneSales,
  useAsAdditional,
  children,
}: Props) => {
  const [submitting, submit] = useSubmit({ orgID, complexID })

  return (
    <PlainModal
      formId="complex_additional_settings"
      onSubmit={submit}
      controlBy={children}
    >
      <PlainModalHeader>{'Дополнительные настройки'}</PlainModalHeader>
      <PlainModalContent>
        <AdditionalSettingsForm
          buffetZone={buffetZone}
          hotMealsZone={hotMealsZone}
          denyParentMoneySales={denyParentMoneySales}
          denyMoreThanOneSales={denyMoreThanOneSales}
          useAsAdditional={useAsAdditional}
        />
      </PlainModalContent>
      <PlainModalFooter>
        {toggle => (
          <ControlsBar right>
            <PlainButton
              outline
              onClick={toggle}
            >
              {'Отмена'}
            </PlainButton>

            <PlainButton
              type="submit"
              disabled={submitting}
            >
              {submitting ? 'Сохранение...' : 'Сохранить'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  )
}

export default AdditionalSettingsModal
