// @flow
import React, { type Node } from 'react';

import useSelection from './useSelection';
import SelectionDataContext from './SelectionDataContext';


type Props = {
  children: Node,
}

const SelectionData = ({ children }: Props) => {
  const value = useSelection();

  return (
    <SelectionDataContext.Provider value={value}>
      {children}
    </SelectionDataContext.Provider>
  );
};

export default SelectionData;
