// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray, isSingleWithId } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as st from 'app/actions/reports/filters/studentSubsidyTypes';
import type { State } from 'app/reducers/reports/filters/studentSubsidyTypes';

import { selectOldPeriodAndDetailsSpecial } from './hocs/commonSelectors';
import styles from './FilterItems.scss';


const emptyObj = Object.freeze({});
const { studentSubsidyTypes } = st;

const selectIsEnable = createSelector(
  [
    state => state.reports.filters.customers.active,
    state => state.reports.filters.customers.selected,
    selectOldPeriodAndDetailsSpecial,
  ],
  (active, selected, special) => !!(
    active
    && isSingleWithId('Учащиеся')(selected)
    && !special
  ),
);

type Props = {
  resetStatus: boolean,
}

const StudentSubsidyTypeFilter = ({ resetStatus }: Props) => {
  const dispatch = useDispatch();
  const { data: itemsObj, selected: selectedItems }: State = useSelector(state => state.reports.filters.studentSubsidyTypes, shallowEqual);
  const selectedArr: Array<$Values<typeof studentSubsidyTypes>> = mapObjPropToArray('id')(selectedItems);

  const handleReset = useCallback(() => {
    dispatch(action(st.STUDENT_SUBSIDY_TYPES_FILTER_SET, emptyObj));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(st.STUDENT_SUBSIDY_TYPES_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(st.STUDENT_SUBSIDY_TYPES_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  to ENABLE or not to ENABLE
   */

  const isEnable = useSelector(selectIsEnable);
  useEffect(() => {
    dispatch(action(st.STUDENT_SUBSIDY_TYPES_FILTER_UPDATE, { active: isEnable }));
  }, [isEnable]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isEnable) {
    return null;
  }

  return (
    <PlainFilterComplete
      id="studentSubsidyType"
      className={styles.filter}
      title="Начисление льгот:"
      modalTitle="Выбор типа начисления льгот"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default StudentSubsidyTypeFilter;
