import { Rights } from 'app/common/constants/rights'

import useRightsData from './useRightsData'
import { getAccessObject, type RightsPath } from '../types'


const deniedAccess = { read: false, write: false, su: false }
const fullAccess = { read: true, write: true, su: true }
const readOnly = { read: true, write: false, su: false }

type Options<T extends keyof Rights> = {
  pathTo: RightsPath<T>,
  deniedWhileLoading?: boolean
}

export function useRightsByPath<T extends keyof Rights>({
  pathTo,
  deniedWhileLoading = false,
}: Options<T>) {
  const [{ su, rights, ready }] = useRightsData()

  if (!ready) {
    return deniedWhileLoading ? deniedAccess : readOnly
  }

  if (su) {
    return fullAccess
  }

  const { edit, read } = getAccessObject(pathTo, rights)

  return {
    read,
    write: edit,
    su,
  }
}
