import always from 'ramda/es/always';
import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  BALANCETYPES_FILTER_INIT,
  BALANCETYPES_FILTER_CLEAR,
  BALANCETYPES_FILTER_DELETE,
  BALANCETYPES_FILTER_SET,
} from 'app/actions/reports/filters/balanceTypes';


const defaultState = {
  data: {},
  selected: {},
};

const balanceTypes = (state = defaultState, { type, payload }) => {
  switch (type) {
    case BALANCETYPES_FILTER_INIT: {
      return evolve({
        data: always(payload),
      }, state);
    }
    case BALANCETYPES_FILTER_CLEAR: {
      return defaultState;
    }
    case BALANCETYPES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case BALANCETYPES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    default:
  }
  return state;
};

export default balanceTypes;
