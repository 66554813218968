// @flow
import type { Request } from '../../index';


export type Result = {
  result: 'success',
  linked: true, // есть ли связь
  name?: { // в случае, если есть связь
    last: ?string,
    first: ?string,
    middle: ?string
  }
}

export const GET_LINKED_EMPLOYEE: Request<
  void,
  Result,
  void
> = [
  'food/employees/linked_employee',
  'get',
  ['data'],
];
