import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import lifecycle from 'recompose/lifecycle';
import equals from 'ramda/es/equals';
import isEmpty from 'ramda/es/isEmpty';

import { isSingle } from 'app/common/lib/object';

import {
  subsidiesFilterFetch,
  SUBSIDY_FILTER_CLEAR,
  SUBSIDY_FILTER_DELETE,
  SUBSIDY_FILTER_SET,
  SUBSIDY_FILTER_UPDATE,
} from 'app/actions/reports/filters/subsidies';

import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import action from 'app/common/lib/action';
import * as availableSubsidyTypes from 'app/actions/reports/filters/subsidyTypesAvailable';

import { selectSubsidyType, selectSubsidyTypeActivity } from './commonSelectors';


const selectIsAll = createSelector(
  [
    state => state.reports.filters.subsidies.data,
    state => state.reports.filters.subsidies.selected,
  ],
  (data, selected) => (isEmpty(selected) || equals(data, selected)),
);

const selectIsEnabled = createSelector(
  [
    selectSubsidyTypeActivity,
    selectSubsidyType,
  ],
  (subsidyTypeActivity, subsidyType) => (
    subsidyTypeActivity
    && subsidyType === availableSubsidyTypes.SUBSIDY_TYPE_PERSONAL
  ),
);

export default compose(
  connect(
    state => ({
      items: state.reports.filters.subsidies.data,
      selectedItems: state.reports.filters.subsidies.selected,
      loading: state.reports.filters.subsidies.loading,
      isSelectedAllState: selectIsAll(state),
      isEnabled: selectIsEnabled(state),
    }),
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(action(SUBSIDY_FILTER_SET, {}));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset();
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems, reset }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        reset();
        return;
      }
      dispatch(action(SUBSIDY_FILTER_DELETE, [name]));
    },
    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(action(SUBSIDY_FILTER_SET, submitted));
    },
  }),

  lifecycle({
    async componentDidMount() {
      const { isEnabled, dispatch, reset } = this.props;
      dispatch(action(SUBSIDY_FILTER_UPDATE, { active: isEnabled }));
      const res = await dispatch(subsidiesFilterFetch());
      if (res) reset();
    },
    componentDidUpdate(prevProps) {
      const { isEnabled, dispatch } = this.props;
      if (isEnabled !== prevProps.isEnabled) {
        dispatch(action(SUBSIDY_FILTER_UPDATE, { active: isEnabled }));
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(action(SUBSIDY_FILTER_CLEAR));
    },
  }),

  branch(({ isEnabled }) => !isEnabled, renderNothing),
);
