// @flow
import { useState, useCallback, useEffect } from 'react';

import { api } from 'app/api';
import { GET_REPORTS_DATA_PURCHASES } from 'app/common/api/requests/reports/data';
import type { ReportsDataPurchasesResult } from 'app/common/api/requests/reports/data.purchases';


export type ReportData = ReportsDataPurchasesResult | null

export default function useReportData(reportId: ?number): [ReportData, boolean] {
  const [reportData, setReportData] = useState<ReportData>(null);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    if (!reportId) return;

    setLoading(true);
    const res = await api.request(GET_REPORTS_DATA_PURCHASES, {
      error: 'Не удалось загрузить данные отчета',
      params: {
        report_id: reportId,
      },
    });

    if (res) {
      setReportData(res);
    }
    setLoading(false);
  }, [reportId]);


  useEffect(() => {
    request();
  }, [request]);

  return [reportData, loading];
}
