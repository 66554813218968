// @flow
import type { Request } from '../index';

type DownloadFileParams = {
  report_id: number,
}

export const GET_DOWNLOAD_FILE: Request<
  DownloadFileParams,
  void,
  void
> = [
  'reports/download_file',
  'get',
  [],
];

// 422 report error - в процессе формирования файла произошла ошибка
// 422 report is not ready - файл еще не готов


// DEPRECATED use src/common/api/requests/reports/download_file/get