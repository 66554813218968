// @flow
import { useEffect, useCallback, useState } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import reduce from 'ramda/es/reduce';

import { api } from 'app/api';
import { GET_SCHOOLS, type School } from 'app/common/api/requests/data/schools.get';

/**
 *  Организации используемые в запрошенных vendingMachines или machineSingle
 *  (зависит от параметра orgIds)
 */

type RelatedOrgsState = {
  [id: string]: { id: number, title: string }
}
const defaultState: RelatedOrgsState = Object.freeze({});

export type RelatedOrgs = RelatedOrgsState
export const defaultRelatedOrgs: RelatedOrgs = defaultState;

export default function useRelatedOrgs(orgIds: Array<number>): RelatedOrgs {
  const [state, setState] = useState(defaultState);

  const request = useCallback(async () => {
    if (isEmpty(orgIds)) {
      setState(defaultState);
      return;
    }
    const res = await api.request(GET_SCHOOLS, {
      error: 'Не удалось получить образовательные учереждения связанные с аппаратами',
      params: {
        school_id: orgIds,
      },
    });
    if (res && res.data && res.data instanceof Array) {
      const { data } = res;
      const relatedOrgs = reduce((acc, { id, title }: School) => {
        acc[String(id)] = { id, title };
        return acc;
      }, {}, data);
      setState(relatedOrgs);
    }
  }, [orgIds]);

  useEffect(() => {
    request();
  }, [request]);

  return state;
}
