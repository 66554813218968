// @flow
import React from 'react';

import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow';

import ImportOptions from './ImportOptions';
import AddFiles from './AddFiles';
import ReadFileFailed from './ReadFileFailed';
import ImportReportEmail from './ImportReportEmail';
import ImportPreview from './ImportPreview';
import ImportXLSData, { ImportedDataReady } from '../ImportXLSData';
import ImportXLSForm from './ImportXLSForm';

import styles from './ImportXLS.scss';

/**
 *  Импорт готового файла XLS с блюдами
 */

type Props = {
  onClose: () => void,
  onSuccess: () => void | Promise<void>,
}

const ImportXLS = ({ onClose, onSuccess }: Props) => {
  return (
    <ScrollbarsWShadow fillAllSpace>
      <ImportXLSData>
        <ImportXLSForm className={styles.content} onClose={onClose} onSuccess={onSuccess}>
          <AddFiles className={styles.dropArea} />

          <ImportedDataReady>
            <ImportPreview />
            <ImportOptions className={styles.item} />
            <ImportReportEmail className={styles.item} />
          </ImportedDataReady>

          <ReadFileFailed />
        </ImportXLSForm>
      </ImportXLSData>
    </ScrollbarsWShadow>
  );
};

export default ImportXLS;
