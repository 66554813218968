// @flow
import type { Request } from '../../index';


export type DropPersonalLimitsParams = {
  schools_ids: Array<string | number>,
  orders: boolean,
  personal: boolean,
}

export const POST_DROP_PERSONAL_LIMITS: Request<
  DropPersonalLimitsParams,
  'success',
  void,
> = [
  'food/schools/drop_personal_limits',
  'post',
  ['data', 'result'],
];
