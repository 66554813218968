// @flow
import React, { Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames/bind';

import {
  Table,
  Tr,
  Th,
  Td,
} from 'app/common/ui-components/table';

import Paging from 'app/common/ui/Paging';
import type { SortHandle, SortState } from 'app/common/containers/withSortState';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { ReservationHistoryModal } from 'app/common/components-next/reservation-history';
import { FundReturnModal } from 'app/common/components-next/fund-return';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import enhanceUsers from './enhanceUsers';
import type { UserType } from './selectActual';

import styles from './Users.scss';


const cx = classNames.bind(styles);

type Props = {
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  users: Array<UserType>,
  currentPage: number,
  total: number,
  rows: number,
  sort: SortState,
  handleSort: SortHandle,
  onInvalidateReport: () => void,
}

const Users = ({
  handlePage,
  users,
  currentPage,
  total,
  rows,
  sort: {
    sortDirection,
    sortKey,
  },
  handleSort,
  onInvalidateReport,
}: Props) => {
  const current: boolean = useSelector(state => state.reports.balance.params.current, shallowEqual);
  const { e_money_enabled: eMoneyEnabled } = useSelector(state => state.dealer, shallowEqual);
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <Fragment>
      <DetailsHeader reportTitle="Остаток средств" />

      {/* ЗАГОЛОВОК */}
      <CSSSticky
        number={2}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <Tr header>
              <Th
                id="empty1"
                className={cx(styles.headerCell, { cellEmpty: !current, cellEmptySmall: current })}
              >
                &nbsp;
              </Th>

              <Th
                id="header1"
                className={cx(styles.headerCell, styles.cellName)}
                sortKey="name"
                sortDirection={sortKey === 'name' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignLeft
              >
                {'ФИО'}
              </Th>

              <Th
                id="header2"
                className={cx(styles.headerCell, styles.cellGroup)}
                sortKey="group"
                sortDirection={sortKey === 'group' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignLeft
              >
                {'Класс / Должность'}
              </Th>

              <Th
                id="header3"
                className={cx(styles.headerCell, styles.cellBalance)}
                sortKey="balance"
                sortDirection={sortKey === 'balance' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignRight
              >
                {`Баланс${currency}`}
              </Th>

              <Th
                id="header4"
                className={cx(styles.headerCell, styles.cellLinks)}
                alignLeft
              >
                {'Дополнительно'}
              </Th>

              <Th
                id="empty2"
                className={cx(styles.headerCell, { cellEmpty: !current, cellEmptySmall: current })}
              >
                &nbsp;
              </Th>
            </Tr>
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <tbody>
          {users.map(({ id, name, group, balance, customerId }) => (
            <Tr key={id}>
              <Td
                headers="empty1"
                className={cx({ cellEmpty: !current, cellEmptySmall: current })}
              >
                &nbsp;
              </Td>

              <Td
                headers="header1"
                className={styles.cellName}
              >
                {name}
              </Td>

              <Td
                headers="header2"
                className={styles.cellGroup}
              >
                {group}
              </Td>

              <Td
                headers="header3"
                className={styles.cellBalance}
                alignRight
              >
                {balance}
              </Td>

              {current
                && <Td
                  headers="header4"
                  className={styles.cellLinks}
                >
                  <ReservationHistoryModal
                    customerId={customerId}
                    name={name}
                    group={group}
                  >
                    {'Резерв-ние'}
                  </ReservationHistoryModal>
                  {' '}
                  {parseFloat(balance) > 0
                    && !eMoneyEnabled
                    && <FundReturnModal
                      customerId={customerId}
                      name={name}
                      group={group}
                      onRefund={onInvalidateReport}
                    >
                      {'Возврат'}
                    </FundReturnModal>}
                </Td>}

              <Td
                headers="empty2"
                className={cx({ cellEmpty: !current, cellEmptySmall: current })}
              >
                &nbsp;
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>

      {(total > rows)
        && <Paging
          className={styles.pager}
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={users.length}
          onPage={handlePage}
        />}

    </Fragment>
  );
};

export default enhanceUsers(Users);
