import { Switch, Route, Redirect } from 'react-router'

import { Documents } from 'app/components/compensation/Settings'

import { getURL, getSection, SettingsRoute } from './router'
import useSideNavItems from './useSideNavItems'
import { SideNavLayout } from '../../_layouts/SideNavLayout'


const root = getURL()
const documents = getURL({ subSection: SettingsRoute.Documents })

export const SettingsPage = (props) => {
  const subSectionActive = getSection(props)
  const sideNavItems = useSideNavItems()

  return (
    <SideNavLayout items={sideNavItems} activeID={subSectionActive}>
      <Switch>
        <Route path={documents} component={Documents} exact />
        <Redirect from={root} to={documents} />
      </Switch>
    </SideNavLayout>
  )
}
