import api from 'app/actions/api';
import path from 'ramda/es/path';

/**
* Запрос категорий блюд
*/

export const CATEGORIES_ADD_REQUEST = 'CATEGORIES_ADD_REQUEST';
export const CATEGORIES_ADD_SUCCESS = 'CATEGORIES_ADD_SUCCESS';
export const CATEGORIES_ADD_FAILURE = 'CATEGORIES_ADD_FAILURE';
export const CATEGORIES_ADD_CLEAR_ERROR = 'CATEGORIES_ADD_CLEAR_ERROR';

export const categoriesAddRequest = () => ({
  type: CATEGORIES_ADD_REQUEST,
});

const categoriesAddSuccess = payload => ({
  type: CATEGORIES_ADD_SUCCESS,
  payload,
});

const categoriesAddFailure = payload => ({
  type: CATEGORIES_ADD_FAILURE,
  payload,
});

export const categoriesAddClearError = () => ({
  type: CATEGORIES_ADD_CLEAR_ERROR,
});

export const addCategory = data => async (dispatch) => {
  dispatch(categoriesAddRequest());
  try {
    const res = await api('food/menu/categories', 'post', { data });
    const { result, ...payload } = res.data;
    dispatch(categoriesAddSuccess(payload));
    return true;
  } catch (error) {
    const message = path(['response', 'data', 'message'], error);
    if (message && message === 'category exist') {
      dispatch(categoriesAddFailure({
        err: 'Категория уже существует, попробуйте другое название',
        error,
      }));
      return false;
    }
    if (message && message === 'category deleted') {
      dispatch(categoriesAddFailure({
        err: 'Категория уже существует в удаленных',
        error,
      }));
      return false;
    }
    dispatch(categoriesAddFailure({ err: 'Не удалось создать категорию', error }));
    return false;
  }
};
