// @flow
import React from 'react';
import classNames from 'classnames/bind';

import styles from './extended-label.scss';


const cx = classNames.bind(styles);

type Props = {
  active: boolean,
  title: string,
}

const ExtendedLabel = ({ active, title }: Props) => (
  <span className={cx(styles.root, { active })}>{title}</span>
);

export default ExtendedLabel;
