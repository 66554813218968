// @flow

/**
 *  Выбор PromptRouter зависит от кабинета
 *  В кабинете родителя роутер v3, поэтому общие компоненты должны импортировать
 *  компонент из локальной папки app/components/common/Prompt,
 *  в которой уже импортируется нужная версия
 */

import PromptRouter4 from 'app/common/ui-components/prompt-router-custom';

export default PromptRouter4;
