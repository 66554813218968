// @flow
import { useCallback, useState } from 'react';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import assocPath from 'ramda/es/assocPath';
import pipe from 'ramda/es/pipe';
import when from 'ramda/es/when';
import filter from 'ramda/es/filter';
import propEq from 'ramda/es/propEq';

import {
  GET_OLD_SUBSIDY_ORDERS_RESTRICTIONS,
  POST_OLD_SUBSIDY_ORDERS_RESTRICTIONS,
  convertResult,
  convertParams,
  convertParamsPOST,
} from 'app/common/api/requests/food/schools/old_subsidy_orders_restrictions';

import { api } from 'app/api';
import type { RestrictionByRoleMap, OrderType, RestrictionByRole } from 'app/dataTypes/OrgSettingsSubsidies.types';
import type { SubsidyOrdersRestrictions } from 'app/common/api/requests/food/schools/subsidy_orders_restrictions';

import extractRBRErrors, { emptyErrors, type Errors } from './extractRBRErrors';
import * as ot from './orderTypes';


const availableOrderTypes = values(ot);
const getParamsRestrictions = (
  restrictions: RestrictionByRoleMap,
  orderType?: OrderType,
): Array<RestrictionByRole> => {
  return pipe(
    values,
    when(
      () => availableOrderTypes.includes(orderType),
      filter(propEq('orderType', orderType)),
    ),
  )(restrictions);
};

type State = {
  restrictionsByRole: RestrictionByRoleMap,
  errors: Errors,
  modified: boolean,
}

type Actions = {
  request: () => Promise<void>,
  submitCommon: (orgIds?: Array<number>) => Promise<boolean>,
  submitPersonal: (orgIds?: Array<number>) => Promise<boolean>,
  submit: (orgIds?: Array<number>) => Promise<boolean>,
  checkErrorsCommon: () => boolean,
  checkErrorsPersonal: () => boolean,
  checkErrors: () => boolean,
  restrictionEnableChange: (checked: boolean, id: string) => void,
  daysChange: (value: number | null, id: string) => void,
}

export default function useRestrictionsByRole(
  orgId: number,
  subsidyOrdersRestrictions: SubsidyOrdersRestrictions,
): [State, Actions] {
  const [restrictionsByRole, setRestrictionsByRole] = useState<RestrictionByRoleMap>({});
  const [modified, setModified] = useState(false);
  const [errors, setErrors] = useState<Errors>(emptyErrors);

  const request = useCallback(async () => {
    const res = await api.request(GET_OLD_SUBSIDY_ORDERS_RESTRICTIONS, {
      error: 'Не удалось получить настройки ограничений на редактирование старых справок о льготах',
      params: {
        orgId,
      },
      convertions: {
        convertResult,
        convertParams,
      },
    });

    if (res) {
      setRestrictionsByRole(res);
      setModified(false);
      setErrors(emptyErrors);
    }
  }, [orgId]);

  const makeSubmit = useCallback((type?: OrderType) => async (overrideOrgIds?: Array<number>) => {
    const orgIds = overrideOrgIds || [orgId];
    const res = await api.request(POST_OLD_SUBSIDY_ORDERS_RESTRICTIONS, {
      error: 'Не удалось сохранить настройки ограничений на редактирование старых справок о льготах',
      params: {
        orgIds: orgIds.map(String),
        restrictions: getParamsRestrictions(restrictionsByRole, type),
      },
      convertions: {
        convertParams: convertParamsPOST,
      },
    });

    if (res) {
      setModified(false);
      setErrors(emptyErrors);
    }
    return !!res;
  }, [orgId, restrictionsByRole]);

  const submitCommon = useCallback(makeSubmit(ot.orderCommon), [makeSubmit]);
  const submitPersonal = useCallback(makeSubmit(ot.orderPersonal), [makeSubmit]);
  const submit = useCallback(makeSubmit(), [makeSubmit]);

  const makeCheckErrors = useCallback((type?: OrderType) => () => {
    const errorsExtracted = extractRBRErrors(restrictionsByRole, type);
    setErrors(errorsExtracted);
    return isEmpty(errorsExtracted);
  }, [restrictionsByRole]);

  const checkErrors = useCallback(() => {
    const personalChecked = subsidyOrdersRestrictions.personal.enabled;
    const commonChecked = subsidyOrdersRestrictions.common.enabled;
    if (!personalChecked && !commonChecked) return true;
    if (personalChecked) {
      return makeCheckErrors(ot.orderPersonal)();
    }
    if (commonChecked) {
      return makeCheckErrors(ot.orderCommon)();
    }
    return makeCheckErrors()();
  }, [subsidyOrdersRestrictions, makeCheckErrors]);

  const checkErrorsCommon = useCallback(makeCheckErrors(ot.orderCommon), [makeCheckErrors]);
  const checkErrorsPersonal = useCallback(makeCheckErrors(ot.orderPersonal), [makeCheckErrors]);

  const restrictionEnableChange = useCallback((checked: boolean, id: string) => {
    setRestrictionsByRole(
      assocPath([id, 'pastPeriodWithoutRestrictions'], checked),
    );
    setModified(true);
    setErrors(emptyErrors);
  }, []);

  const daysChange = useCallback((value: number | null, id: string) => {
    setRestrictionsByRole(
      assocPath([id, 'pastPeriodDays'], value),
    );
    setModified(true);
    setErrors(emptyErrors);
  }, []);

  return [{
    restrictionsByRole,
    errors,
    modified,
  }, {
    request,
    submitCommon,
    submitPersonal,
    submit,
    checkErrorsCommon,
    checkErrorsPersonal,
    checkErrors,
    restrictionEnableChange,
    daysChange,
  }];
}
