// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State, type StateByFilterId } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const accountNumberSelected = (state: State): boolean => {
  const current = getStateCurrentValue(filterId, state) || [];
  return current.includes('customer_account_number');
};

export const phoneSelected = (state: State): boolean => {
  const current = getStateCurrentValue(filterId, state) || [];
  return current.includes('customer_phone');
};

export const accountNumberApplied = (applied: StateByFilterId | null): boolean => {
  if (applied && applied[filterId]) {
    return applied[filterId].includes('customer_account_number');
  }
  return false;
};

export const phoneApplied = (applied: StateByFilterId | null): boolean => {
  if (applied && applied[filterId]) {
    return applied[filterId].includes('customer_phone');
  }
  return false;
};
