// @flow
import ResizeObserver from 'resize-observer-polyfill';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import omit from 'ramda/es/omit';
import update from 'ramda/es/update';
import map from 'ramda/es/map';
import addIndex from 'ramda/es/addIndex';
import take from 'ramda/es/take';
import takeLast from 'ramda/es/takeLast';

import { MAX_STICKY_NESTING } from './helpers';


const mapIndexed = addIndex(map);

// если прилипает какой-то элемент, то все предыдущие считаются прилипшими
// и соответственно, когда элемент отлипает, то все следующие отлипают
const lastIndex = MAX_STICKY_NESTING - 1;
const updateStickyState = (index, itemStickyNewState, state) => {
  // кроме последнего (это подвал)
  if (index === lastIndex) {
    return update(index, itemStickyNewState, state);
  }
  return (itemStickyNewState
    ? [...mapIndexed((item, i) => (i <= index), take(lastIndex, state)), ...takeLast(1, state)]
    : [...mapIndexed((item, i) => !(i >= index), take(lastIndex, state)), ...takeLast(1, state)]
  );
};

const withElHeightArray = compose(
  // высоты блоков с прилипанием
  withState('elHeights', 'setElHeight', [0, 0, 0, 0, 0, 0]),
  // состояние прилипания
  withState('itemsSticky', 'setItemsSticky', [false, false, false, false, false, false]),

  withHandlers({
    // отслеживание размеров
    onItemResize: ({ setElHeight }) => (entries) => {
      entries.forEach(({ contentRect, target }) => {
        const number = parseInt(target.id, 10);
        const { height } = contentRect;
        // height может быть 0 даже когда элемент вовсе не 0 высотой
        // и это проблема FIXIT
        // может ввести prop 'eq0' ???
        if (height >= 0 && number && number > 0) {
          setElHeight(update(number - 1, height));
          // return;
        }
        // setElHeight(update(number - 1, 0));
      });
    },
    onStickyToggle: ({ setItemsSticky }) => (
      num: number,
      stickyNewState: boolean,
      considering: boolean = false,
    ) => {
      if (considering) {
        setItemsSticky(state => (
          state[num - 1] === stickyNewState
            ? state
            : updateStickyState(num - 1, stickyNewState, state)
        ));
      } else {
        setItemsSticky(update(num - 1, stickyNewState));
      }
    },
  }),

  // для отслеживания размеров
  withState('ro', 'setRo', ({ onItemResize }) => new ResizeObserver(onItemResize)),

  mapProps(props => omit(['setElHeight', 'onItemResize', 'setRo'], props)),
);

export default withElHeightArray;
