import { ChangeEvent } from 'react'
import { createPortal } from 'react-dom'


export function useFormPortal(formID: string, onSubmit: (e: ChangeEvent<HTMLFormElement>) => Promise<void>) {
  const formMountNode = document.getElementById('modal')
  const formPortal = (
    formMountNode
      ? createPortal(<form id={formID} onSubmit={onSubmit} />, formMountNode)
      : null
  )

  return formPortal
}
