// @flow
import { Workbox } from 'workbox-window';

/**
 *  Инициализация service-worker
 */

const wb = 'serviceWorker' in navigator ? new Workbox('/service-worker.js') : null;

type Options = {
  onWaiting?: (event: Object/* ExtendableEvent */) => void
}

export default function serviceWorkerInit(options?: Options = {}) {
  const { onWaiting } = options;
  if (wb) {
    wb.addEventListener('controlling', () => {
      window.location.reload();
    });

    // wb.addEventListener('installed', (event) => {
    //   if (!event.isUpdate) {
    //     // First-installed code goes here...
    //   }
    // });

    // wb.addEventListener('activated', (event) => {
    //   // `event.isUpdate` will be true if another version of the service
    //   // worker was controlling the page when this version was registered.
    //   if (!event.isUpdate) {
    //     console.log('Service worker activated for the first time!');

    //     // If your service worker is configured to precache assets, those
    //     // assets should all be available now.
    //   }
    // });

    if (onWaiting) {
      wb.addEventListener('waiting', onWaiting);
    }

    wb.register();
  }
}

// периодически вызывается где-то в приложении
export const serviceWorkerUpdate = () => {
  if (wb) {
    wb.update();
  }
};
