// @flow
import type { Request } from '../../index';

export type MenuSalePointsParams = {
  building_id?: number,
}

export type SalePointItem = {
  id: number,
  title: string,
  deleted: boolean,
  for_order: boolean, // точка для заявок
}

export type MenuSalePointsResult = {
  [id: string]: SalePointItem
}

type MenuSalePointsUrlParams = {
  orgId: number | string,
}

export const GET_MENU_SALE_POINTS: Request<
  MenuSalePointsParams,
  MenuSalePointsResult,
  MenuSalePointsUrlParams
> = [
  'food/schools/{orgId}/sale_points',
  'get',
  ['data', 'sale_points'],
];
