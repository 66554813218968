import {
  type Requisites,
  type SingleRequisites,
  paymentOrderKinds,
  reportAddresses,
  paymentOrderMethods,
  paymentOrderPurposes,
} from 'app/dataTypes/requisites'


export const defaultSingle: SingleRequisites = {
  kind: paymentOrderKinds.school,
  orgType: '',
  title: '',
  lawAddress: '',
  address: '',
  postAddress: '',
  reportAddress: reportAddresses.law_address,
  inn: '',
  kpp: '',
  orgn: '',
  okpo: '',
  bik: '',
  bank: '',
  bankAddress: '',
  corr: '',
  rc: '',
  oktmo: '',
  kbk: '',
  lc: '',
  paymentOrderMethod: paymentOrderMethods.Offline,
  paymentOrderServiceCode: '',
  paymentOrderPurpose: new Set([paymentOrderPurposes.FoodType]),
  paymentOrderCreateMethods: new Set(),
}

export const defaultState: Requisites = {
  person: {
    name: {
      first: '',
      last: '',
      middle: '',
    },
    phone: '',
    email: '',
    comment: '',
  },
  accountant: {
    name: {
      first: '',
      last: '',
      middle: '',
    },
    phone: '',
    email: '',
    comment: '',
  },
  requisites: new Map([[paymentOrderKinds.school, defaultSingle]]),
  supportContacts: [],
  supportSchedule: {
    workday: {
      formID: 'workday',
      keyStart: '',
      keyFinish: '',
      keyTimeless: false,
    },
    lunch: {
      formID: 'lunch',
      keyStart: '',
      keyFinish: '',
      keyTimeless: false,
    },
    saturday: {
      formID: 'saturday',
      keyStart: '',
      keyFinish: '',
      keyTimeless: false,
    },
    sunday: {
      formID: 'sunday',
      keyStart: '',
      keyFinish: '',
      keyTimeless: false,
    },
  },
}
