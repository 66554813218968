// @flow
import React, { useCallback, useState, Fragment } from 'react';
import classNames from 'classnames/bind';

import PlainInput from 'app/common/ui-next/plain-input';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import OveflowedText from 'app/common/components/OveflowedText';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import styles from './RoleItem.scss';

/**
 *  Элемент ограничений для определенной роли
 */

const makeDaysValid = (value: string, max: number, min: number): number | null => {
  if (!value) {
    return null;
  }
  const days = parseInt(value, 10);
  if (Number.isNaN(days)) {
    return null;
  }
  if (days > max) return max;
  if (days < min) return min;
  return days;
};

const cx = classNames.bind(styles);
const additionalErrorClass = (error: boolean) => (error ? 'scrollToError' : null);

type Props = {
  id: string,
  className?: string,
  title: string,
  days: number | null,
  withoutRestrictions: boolean,
  disabled?: boolean,
  maxDays?: number,
  minDays?: number,
  error?: string,
  onChangeDays: (days: number | null, id: string) => void,
  onChangeRestriction: (checked: boolean, id: string) => void,
}

const RoleItem = ({
  id,
  className,
  title,
  days,
  withoutRestrictions,
  disabled,
  maxDays = 365,
  minDays = 0,
  error,
  onChangeDays,
  onChangeRestriction,
}: Props) => {
  const [showMaxWarn, setMaxWarnEnabled] = useState(false);
  const handleFocus = useCallback(() => { setMaxWarnEnabled(true); }, []);
  const handleBlur = useCallback(() => { setMaxWarnEnabled(false); }, []);

  const disabledDaysEntering = withoutRestrictions || disabled;
  const hasError = !!error;

  const handleChangeDays = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    onChangeDays(makeDaysValid(value, maxDays, minDays), id);
  }, [maxDays, minDays, onChangeDays, id]);

  const handleChangeCheckbox = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    onChangeRestriction(checked, id);
  }, [onChangeRestriction, id]);

  const value = typeof days === 'number' ? String(days) : '';

  return (
    <Fragment>
      <div className={cx(className, { errorBorder: hasError }, additionalErrorClass(hasError))}>
        <div className={styles.row}>
          <label
            htmlFor={id}
            className={styles.label}
          >
            <OveflowedText>{`${title}:`}</OveflowedText>
          </label>

          <div className={styles.inputContainer}>
            <PlainInput
              id={id}
              className={styles.input}
              value={value}
              onChange={handleChangeDays}
              disabled={disabledDaysEntering}
              onFocus={disabledDaysEntering ? undefined : handleFocus}
              onBlur={handleBlur}
              error={hasError}
            />
            <PlainHelperText>
              {showMaxWarn ? `от ${minDays} до ${maxDays} дней` : ''}
            </PlainHelperText>
          </div>

          <PlainCheckbox
            value={`for_${id}`}
            className={styles.checkbox}
            label="без ограничений"
            checked={withoutRestrictions}
            disabled={disabled}
            onChange={handleChangeCheckbox}
          />
        </div>
      </div>
      {hasError
        && <PlainHelperText error>
          {error}
        </PlainHelperText>}
    </Fragment>
  );
};

export default RoleItem;
