// @flow
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import type { HOC } from 'recompose';
import { createSelector } from 'reselect';
import moment from 'moment-timezone';

import config from 'app/config';

import {
  ONLY_CHOSEN_SCHOOL_FOR_CHOSEN_DATE,
  ONLY_CHOSEN_SCHOOL_FOR_FUTURE,
  ALL_SCHOOLS_FOR_CHOSEN_DATE,
  ALL_SCHOOLS_FOR_FUTURE,
  UPDATE_ALL_LISTS,
  // ONE_SCHOOL,
  ALL_SCHOOLS,
} from 'app/constants/orgs/menu/fieldEdit';

import { popup } from 'app/common/actions/popup';
import { fieldEdit } from 'app/actions/orgs/menu/fieldEdit';


type Props = {
  orgId: string,
  date: string,
};

const selectProps = createSelector(
  [
    state => state.orgs.current.schools,
    (_, { orgId }: Props) => orgId,
    (_, { date }: Props) => date,
  ],
  (orgs, orgId, date) => ({
    schoolName: orgs[orgId] ? orgs[orgId].title : '???',
    dateString: date
      ? moment(date, config.dateFormat).format(config.dateFormatPresentative)
      : '???',
  }),
);

type HOCProps = {
  orgId: string,
  date: string,
  onClose: (Event) => void,
  value: string,
  property: string,
};

const enhanceValueSaveOptionsDialog: HOC<*, HOCProps> = compose(
  connect(selectProps),

  withState('saving', 'setSaving', false),
  withState('allSchools', 'setAllSchools', false),
  withState('extended', 'setExtended', ONLY_CHOSEN_SCHOOL_FOR_CHOSEN_DATE),
  withState('last', 'setLast', ALL_SCHOOLS_FOR_CHOSEN_DATE), // сохранение значения при блокировки группы
  withState('updateAllLists', 'setUpdateAll', false),

  withHandlers({
    handleSubmit: ({
      dispatch,
      onClose,
      orgId,
      id,
      date,
      value,
      property,
      extended,
      updateAllLists,
      setSaving,
    }) => async (e) => {
      e.preventDefault();
      setSaving(true);
      const res = await dispatch(fieldEdit(orgId, id, {
        date,
        property,
        value,
        extended,
        updateAllLists,
      }));
      setSaving(false);
      if (res) {
        dispatch(popup('Значение успешно изменено'));
        onClose(e);
      }
    },

    handleChange: ({ setAllSchools, extended, setExtended, last, setLast, setUpdateAll }) => (e) => {
      const { value, checked } = e.currentTarget;
      switch (value) {
      // case ONE_SCHOOL: всегда включено
        case ALL_SCHOOLS: {
          setAllSchools(checked);
          const sLast = last;
          setLast(extended);
          setExtended(sLast);
          break;
        }
        case ONLY_CHOSEN_SCHOOL_FOR_CHOSEN_DATE:
        case ONLY_CHOSEN_SCHOOL_FOR_FUTURE:
        case ALL_SCHOOLS_FOR_CHOSEN_DATE:
        case ALL_SCHOOLS_FOR_FUTURE: {
          setExtended(value);
          break;
        }
        case UPDATE_ALL_LISTS: {
          setUpdateAll(checked);
          break;
        }
        default:
      }
    },
  }),
);

export default enhanceValueSaveOptionsDialog;
