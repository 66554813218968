// @flow
import React, { useCallback } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import {
  PlainDraggableListProvider,
  PlainDraggableList,
  type OnDragEndResponder,
} from 'app/common/ui-components/plain-draggable-list';

import Loading from 'app/common/components/Loader';

import CategoryItem from './CategoryItem';
import type { CategoryListItem } from './CategoryListItem.type';


type Props = {
  currentCategoryId?: string,
  writeAccess: boolean,
  categoryList: Array<CategoryListItem>,
  loading: boolean,
  moveItem: (number, number) => void,
  onDeleteCategory: (categoryId: string) => Promise<boolean>,
}

const Categories = ({
  currentCategoryId,
  writeAccess,
  categoryList,
  loading,
  moveItem,
  onDeleteCategory,
}: Props) => {
  const handleDragEnd: OnDragEndResponder = useCallback(({ destination, source }) => {
    if (!destination) return;
    const { index: indexDestination } = destination;
    const { index: indexSource } = source;
    moveItem(indexSource, indexDestination);
  }, [moveItem]);

  const nothing = isEmpty(categoryList);

  if (loading && nothing) {
    return (
      <Loading />
    );
  }

  return (
    <PlainDraggableListProvider onDragEnd={handleDragEnd}>
      <PlainDraggableList
        droppableId="categories"
        injectDraggableStateSnapshot
      >
        {categoryList.map((props: CategoryListItem, i) => {
          const { id, deletable } = props;

          return (
            <CategoryItem
              key={id}
              first={i === 0}
              isDragDisabled={i === 0}
              active={Boolean(currentCategoryId && currentCategoryId === id)}
              {...props}
              deletable={writeAccess && deletable}
              loading={loading}
              onDeleteCategory={onDeleteCategory}
            />
          );
        })}
      </PlainDraggableList>
    </PlainDraggableListProvider>
  );
};

export default Categories;
