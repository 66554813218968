// @flow
import React, { type Node, useCallback, useState } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';

import PlainModal, {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
} from 'app/common/ui-next/plain-modal';

import AddNewEnterprise from './AddNewEnterprise';
import useSubmit from './useSubmit';
import styles from './AddNewEnterpriseModal.scss';


type Props = {
  onSuccess?: () => void,
}

const AddNewEnterpriseModal = ({ onSuccess }: Props): Node => {
  const [error, setError] = useState<string | void>();
  const clearError = useCallback(() => setError(), []);

  const [submitting, setSubmitting] = useState(false);
  const submit = useSubmit({ onSuccess, setError });

  return (
    <PlainModal
      formId="newEnterprise"
      onSubmit={submit}
      className={styles.root}
      onSubmitStatusChange={setSubmitting}
      controlBy={
        toggle => (
          <PlainButton onClick={toggle}>
            {'Добавить предприятие'}
          </PlainButton>
        )
      }
    >
      <PlainModalHeader>{'Добавление предпириятия'}</PlainModalHeader>
      <PlainModalContent>
        <AddNewEnterprise
          error={error}
          disabled={submitting}
          onMount={clearError}
          onChange={clearError}
        />
      </PlainModalContent>
      <PlainModalFooter>
        {toggleModal => (
          <ControlsBar right>
            <PlainButton
              onClick={toggleModal}
              outline
            >
              {'Отмена'}
            </PlainButton>

            <PlainButton
              type="submit"
              disabled={submitting}
            >
              {submitting ? 'Добавление...' : 'Добавить'}
            </PlainButton>
          </ControlsBar>
        )}
      </PlainModalFooter>
    </PlainModal>
  );
};

export default AddNewEnterpriseModal;
