// @flow
import React, { type Node } from 'react';

import ContentWithCategoriesTemplate from 'app/common/templates/ContentWithCategoriesTemplate';
import { useVendingMachineSingle } from 'app/dataProviders/VendingDataProvider';
import Loading from 'app/common/components/Loader';

import Content from './Content';
import AddCategory from './AddCategory';
import Categories from './Categories';
import useCategories from './useCategories';
import useCategoryDelete from './useCategoryDelete';
import useCategoryChange from './useCategoryChange';
import useWrongCategoryIndexes from './useWrongCategoryIndexes';
import useWrongIndexesFix from './useWrongIndexesFix';
import useValidationCurrentCategory from './useValidationCurrentCategory';
import useContentHeader from './useContentHeader';
import useCurrentCategory from './useCurrentCategory';

import styles from './ProductionSection.scss';


type Props = {
  header: string,
  loading?: boolean,
  currentCategoryId?: string,
  defaultCategoryId?: string,
  redirectToCategory: (id: string, history?: boolean) => void,
}

const ProductionSection = ({
  // header,
  loading: loadingExternal,
  currentCategoryId,
  defaultCategoryId = 'all',
  redirectToCategory,
}: Props): Node => {
  const [{ placeId }] = useVendingMachineSingle();

  const {
    categories,
    loading: loadingCategories,
    newCategoryAdd,
    setCategories,
    moveCategory,
    updateCategoryQuantity,
  } = useCategories(placeId, defaultCategoryId);

  const loading = loadingCategories || loadingExternal;

  const currentCategory = useCurrentCategory(currentCategoryId, categories);
  // const currentCategoryNotValid = currentCategory === null;

  useValidationCurrentCategory({
    category: currentCategory,
    defaultCategoryId,
    ready: !loading,
    redirectToCategory,
  });

  const deleteCategory = useCategoryDelete(setCategories);
  const [, categoryBatchChange] = useCategoryChange(setCategories);
  const wrongIndexesMap = useWrongCategoryIndexes(categories);
  const fixingWrongIndexes = useWrongIndexesFix(wrongIndexesMap, categoryBatchChange);
  const contentHeader = useContentHeader({ currentCategory, defaultCategoryId });

  const loadingClock = loading && categories.length === 1;


  return (
    <ContentWithCategoriesTemplate
      sideTop={
        <AddCategory
          className={styles.addCategory}
          placeId={placeId}
          onSuccess={newCategoryAdd}
          loading={loading}
        />
      }
      sideMain={
        loadingClock
        || !currentCategoryId
          ? <Loading />
          : <Categories
            categories={categories}
            loading={loading || fixingWrongIndexes}
            currentCategoryId={currentCategoryId}
            onDeleteCategory={deleteCategory}
            onMoveItem={moveCategory}
            redirectToCategory={redirectToCategory}
          />
      }
    >
      <Content
        category={loading ? null : currentCategory}
        categories={categories}
        placeId={placeId}
        header={contentHeader}
        allDishesCategoryId={defaultCategoryId}
        onSuccessAddRemoveProducts={updateCategoryQuantity}
      />
    </ContentWithCategoriesTemplate>
  );
};

export default ProductionSection;
