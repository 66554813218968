// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import defaultProps from 'recompose/defaultProps';
import componentFromProp from 'recompose/componentFromProp';

import Link from 'app/common/lib/link';

import IconRwb from './icons/IconRwb';
import IconChild from './icons/IconChild';
import enhanceSideNav from './enhanceSideNav';
import pickByFilters from './pickByFilters';
import styles from './SideNav.scss';


const cx = classNames.bind(styles);
const enhance = defaultProps({ component: 'span' });
const IconMain = enhance(componentFromProp('component'));


type Item = {
  string: string,
  Icon?: React.ElementType,
  title: string
};

type Props = {
  items?: Array<Item>,
  baseUrl: string,
  lock?: boolean,
  activeName: string,
  isWide: boolean,
  onCollapse: Function,
  className?: string,
};

const SideNav = ({
  items,
  baseUrl,
  lock,
  activeName,
  isWide,
  onCollapse,
  className,
}: Props) => (
  <div className={cx({ root: true, collapsed: !isWide }, className)}>
    {!lock
      && <a className={styles.collapse} onClick={onCollapse} href="collapse">
        <IconRwb className={cx({ rw: true, flip: !isWide })} />
      </a>}
    {items instanceof Array
      && items.map(({ string, Icon, title }) => (
        <Link
          key={string}
          className={cx({ item: true, active: string === activeName })}
          to={`${baseUrl}/${string}`}
        >
          {Icon ? <div className={styles.icon}>
            <IconMain component={Icon} className={cx({ active: string === activeName })} />
          </div> : null}
          <div className={cx({ title: true, hidden: !isWide })}>
            {title}
          </div>
        </Link>))}
  </div>
);

SideNav.defaultProps = {
  items: [{
    title: 'Нет записей',
    string: 'empty',
    Icon: IconChild,
  }],
};
export default enhanceSideNav(pickByFilters(SideNav));
