import { GeneralSettings } from 'app/dataTypes/generalSettings'

import type { Request } from '../../index'


export type GeneralSettingsApi = {
  use_nomenclature: boolean,
  parallels_for_common_order: Array<number>,
  e_money_enabled: boolean,
  use_grocery_set_price: boolean,
  use_compensations: boolean,
  use_common_order_complex_choose: boolean,
}

export const convertResult = (apiData: GeneralSettingsApi): GeneralSettings => {
  return {
    useNomenclature: apiData.use_nomenclature,
    parallelsForCommonOrder: apiData.parallels_for_common_order,
    eMoneyEnabled: apiData.e_money_enabled,
    useGrocerySetPrice: apiData.use_grocery_set_price,
    useСompensations: apiData.use_compensations,
    useCommonOrderComplexChoose: apiData.use_common_order_complex_choose,
  }
}

export const GET_GENERAL_SETTINGS: Request<null, GeneralSettings, void> = [
  'food/general_settings',
  'get',
  ['data', 'settings'],
]
