import { ReactNode, useState } from 'react'
import cn from 'classnames/bind'

import { Header2, PageTemplateContent } from 'app/common/templates-next/page-template'
import Sticky from 'app/common/templates-next/sticky'

import ReportSectionFullscreen, { FullscreenDefaultButton } from './report-section-fullscreen'
import styles from './report-section.scss'
import ReportHeaderBarItems from './report-header-bar-items'


const cx = cn.bind(styles)

type Props = {
  children: ReactNode
  header?: string
  headerBar?: ReactNode
  headerContentReplaceBy?: ReactNode
  headerContentClassName?: string
  skipPadding?: boolean
  fullscreenEnabled?: boolean
  footerBar?: ReactNode
  skipFooterPadding?: boolean
  resetStickyShift?: boolean
}

const ReportSection = ({
  children,
  header,
  headerBar,
  headerContentReplaceBy,
  headerContentClassName,
  skipPadding,
  fullscreenEnabled,
  footerBar,
  skipFooterPadding,
  resetStickyShift = true,
}: Props) => {
  const [fullscreen, setFullscreen] = useState(false)

  const content = (
    <>
      {(header || headerBar || headerContentReplaceBy)
          && <Sticky resetShift={resetStickyShift}>
            {(ref, stuck, shadow) => (
              <PageTemplateContent
                borderBottom={stuck}
                shadowBottom={shadow}
                reference={ref}
                className={cx(styles.header, headerContentClassName)}
              >
                {headerContentReplaceBy}
                {!headerContentReplaceBy
                  && <>
                    <Header2 className={styles.headerMain}>{header}</Header2>
                    <div className={styles.space} />
                    {headerBar}
                    {fullscreenEnabled
                      && <ReportHeaderBarItems>
                        <FullscreenDefaultButton
                          className={styles.iconFullscreen}
                          toggleFullscreenState={setFullscreen}
                        />
                      </ReportHeaderBarItems>}
                  </>}
              </PageTemplateContent>
            )}
          </Sticky>}

      <PageTemplateContent
        skipPadding={skipPadding}
        className={cx({ content: !skipPadding })}
      >
        {children}
      </PageTemplateContent>

      {footerBar
        && <Sticky stickyBottom>
          {(ref, stuck, shadow) => (
            <PageTemplateContent
              borderBottom={!stuck}
              shadowTop={shadow}
              reference={ref}
              skipPadding={skipFooterPadding}
            >
              {footerBar}
            </PageTemplateContent>
          )}
        </Sticky>}
    </>
  )

  return (
    fullscreenEnabled
      ? <ReportSectionFullscreen fullscreen={fullscreen}>
        {content}
      </ReportSectionFullscreen>
      : content
  )
}

export default ReportSection
