import { ChangeEvent, useCallback, Dispatch, SetStateAction } from 'react'
import { reject } from 'ramda'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import PlainFieldset from 'app/common/ui-next/plain-fieldset'
import PlainLabel from 'app/common/ui-next/plain-label'
import { SkeletonLoading } from 'app/common/templates-next/page-template'


type Props = {
  label: string
  name: string
  items: Array<{ ID: string, title: string }>
  checked: Set<string>
  setChecked: Dispatch<SetStateAction<Set<string>>>
  loading?: boolean
  form?: string
  hideLabel?: boolean
  disabled?: boolean
}

const Complexes = ({
  label,
  name,
  items,
  checked,
  setChecked,
  loading,
  form,
  hideLabel,
  disabled,
}: Props) => {
  // const [checked, setChecked] = useState(new Set(selected.map(prop('ID'))))
  const handleCheck = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked: newState } = e.currentTarget
    setChecked(prev => new Set(
      newState
        ? [...Array.from(prev), value]
        : reject(key => (key === value), Array.from(prev)),
    ))
  }, [setChecked])

  if (loading) {
    return (
      <SkeletonLoading />
    )
  }

  return (
    <>
      <PlainLabel id={name} hidden={hideLabel}>{label}</PlainLabel>

      <PlainFieldset
        skipUpperSpace={!hideLabel}
        ariaLabelledby={name}
        name={name}
      >
        {items.map(({ ID, title }) => (
          <PlainCheckbox
            key={ID}
            label={title}
            name={name}
            value={ID}
            checked={checked.has(ID)}
            onChange={handleCheck}
            disabled={disabled}
            form={form}
          />
        ))}
      </PlainFieldset>
    </>
  )
}

export default Complexes
