// @flow
import { useMemo } from 'react';
import { map } from 'ramda';

import type { ActualSlotData } from './useActualSlotsData';
import { cells } from './useHeaderCellsData';


export type RowCell = {
  key: string,
  value: string,
  alignLeft?: boolean,
  alignRight?: boolean,
  alignCenter?: boolean,
  className?: string,
  extra?: {
    max: number,
  }
}

const createRow = (
  getModifyDiff: (amount: number, amountMax: number) => number,
  getModifyRest: (amount: number, amountMax: number) => number,
) => (slot: ActualSlotData): Array<RowCell> => {
  const { slotId, slotName, productTitle, productPhoto, amount, amountMax } = slot;
  const difference = getModifyDiff(amount, amountMax);
  const rest = getModifyRest(amount, amountMax);

  return ([{
    key: cells[0].key,
    value: slotId,
    alignLeft: true,
  }, {
    key: cells[1].key,
    value: slotName,
    alignLeft: true,
  }, {
    key: cells[2].key,
    value: productTitle,
    alignLeft: true,
  }, {
    key: cells[3].key,
    value: productPhoto || '',
    alignCenter: true,
  }, {
    key: cells[4].key,
    value: String(amount),
    alignCenter: true,
  }, {
    key: cells[5].key,
    value: String(difference),
    alignCenter: true,
    extra: {
      max: difference,
    },
  }, {
    key: cells[6].key,
    value: String(rest),
    alignCenter: true,
  }]);
};

export default function useRowCellsData(
  slots: Array<ActualSlotData>,
  getModifyDiff: (amount: number, amountMax: number) => number,
  getModifyRest: (amount: number, amountMax: number) => number,
): Array<Array<RowCell>> {
  const res = useMemo(() => {
    return map(createRow(getModifyDiff, getModifyRest), slots);
  }, [slots, getModifyDiff, getModifyRest]);

  return res;
}
