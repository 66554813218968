// @flow
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import toLower from 'ramda/es/toLower';
import trim from 'ramda/es/trim';
import pipe from 'ramda/es/pipe';
import find from 'ramda/es/find';
import prop from 'ramda/es/prop';
import values from 'ramda/es/values';

import {
  changeSellPoint,
} from 'app/actions/orgs/settings/sellpoints/change';

import { popup } from 'app/common/actions/popup';

import { lang } from '.';
import styles from './RestoreLink.scss';


const enhance = compose(
  connect(
    state => ({
      items: state.orgs.settings.sellpoints.items,
    }),
  ),

  withState('restoring', 'setRestoring', false),

  withHandlers({
    handleRestore: ({ dispatch, items, value, orgId, setRestoring }) => async (e) => {
      e.preventDefault();
      const testValue = toLower(trim(value));
      const id = pipe(
        values,
        find(({ title, deleted }) => (deleted && toLower(title) === testValue)),
        prop('id'),
      )(items);
      setRestoring(true);
      const success = await dispatch(changeSellPoint(orgId, { id, deleted: false }));
      if (success) {
        dispatch(popup(lang.popupRestoreItemSuccess));
      }
      setRestoring(false);
    },
  }),
);

type Props = {
  handleRestore: (e: SyntheticEvent<HTMLElement>) => void,
  restoring: boolean,
}

const RestoreLink = enhance(({ handleRestore, restoring }: Props) => (
  <span>
    {lang.errorExistDeleted}
    <a
      href="restore"
      onClick={handleRestore}
      className={styles.restoreLink}
    >
      {restoring ? lang.restoreButtonTextInProcess : lang.restoreButtonText}
    </a>
  </span>
));

export default RestoreLink;
