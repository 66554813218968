// применить ко всем одиночным блюдам с указанным id на выбранную дату в выбранной школе
export const ONLY_CHOSEN_SCHOOL_FOR_CHOSEN_DATE = 'only_chosen_school_for_chosen_date';
// применить ко всем одиночным блюдам с указанным id на выбранную дату и все будущие дни,
// начиная с выбранной даты в выбранной школе
export const ONLY_CHOSEN_SCHOOL_FOR_FUTURE = 'only_chosen_school_for_future';
// применить ко всем одиночным блюдам с указанным id на выбранную дату во всех школах, где оно используется
export const ALL_SCHOOLS_FOR_CHOSEN_DATE = 'all_schools_for_chosen_date';
// применить ко всем одиночным блюдам с указанным id на выбранную дату и все будущие дни,
// начиная с выбранной даты, во всех школах, где оно используется
export const ALL_SCHOOLS_FOR_FUTURE = 'all_schools_for_future';
// обновить во всех списках блюд школ и в списке блюд поставщика
export const UPDATE_ALL_LISTS = 'update_all_lists';
// группы
export const ONE_SCHOOL = 'one_school';
export const ALL_SCHOOLS = 'all_schools';
