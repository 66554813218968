import { useCallback } from 'react'

import { api } from 'app/api'
import { POST_COMPENSATION_ACTION, convertParams, convertResult } from 'app/common/api/requests/food/compensation/action/post'
import { CompensationAction, isCompensationAction } from 'app/dataTypes/compensation/action'


const getErrorText = (action: CompensationAction['action'], orgID: string) => {
  if (action === 'record') {
    return `Не удалось провести фиксацию для заведения с идентификатором ${orgID}`
  }
  if (action === 'cancel') {
    return `Не удалось провести удаление фиксации для заведения с идентификатором ${orgID}`
  }
  if (action === 'interrupt') {
    return `Не удалось прервать фиксацию для заведения с идентификатором ${orgID}`
  }

  return 'Неизвестная ошибка в запросе POST_COMPENSATION_ACTION'
}

export function useCompensationAction(
  paramsCommon: Partial<CompensationAction> | null,
  onSuccess?: (orgID: string) => void,
) {
  const { orgID, year, month } = paramsCommon || {}

  const handleAction = useCallback(async (paramsLocal: Partial<CompensationAction>) => {
    const params = { orgID, year, month, ...paramsLocal }

    if (!isCompensationAction(params)) return false

    const res = await api.request(POST_COMPENSATION_ACTION, {
      error: () => getErrorText(params.action, params.orgID),
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res && onSuccess) onSuccess(params.orgID)

    return !!res
  }, [orgID, year, month, onSuccess])

  return handleAction
}
