// @flow
import React, { Fragment, useCallback, useState, type ComponentType } from 'react';
import isEmpty from 'ramda/es/isEmpty';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import PlainTooltip from 'app/common/ui-next/plain-tooltip';
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';

import { writeConsist } from './rights';
import useMenuClearAll from './useMenuClearAll';


const pendingProps = {
  confirmButtonTitle: 'Очистка...',
  confirmButtonProps: { disabled: true },
};

const emptyProps = Object.freeze({});

type Props = {
  orgId: number,
  sellPointId: number,
  sellPointTitle: string,
  date: string,
  dishesToRemove: Array<number>,
  kitsToRemove: Array<number>,
}

const MenuTableClearAll = ({ orgId, sellPointId, sellPointTitle, date, dishesToRemove, kitsToRemove }: Props) => {
  const [confirmDisplayed, setConfirmDisplayed] = useState(false);
  const [pending, setPending] = useState(false);

  const showModal = useCallback(() => {
    setConfirmDisplayed(true);
  }, []);

  const hideModal = useCallback(() => {
    setConfirmDisplayed(false);
  }, []);

  const requestPending = useCallback((p: boolean) => {
    if (!p) {
      setConfirmDisplayed(false);
    }
    setPending(p);
  }, []);

  const clearAll = useMenuClearAll({
    orgId,
    sellPointId,
    date,
    dishesToRemove,
    kitsToRemove,
    onRequestPending: requestPending,
  });

  const confirmModalProps = pending ? pendingProps : emptyProps;
  const nothingToDelete = isEmpty(dishesToRemove) && isEmpty(kitsToRemove);

  if (nothingToDelete) {
    return null;
  }

  const title = `Очистить меню для точки продаж «${sellPointTitle}»`;

  return (
    <Fragment>
      <PlainButtonAsLink onClick={showModal}>
        <span className="visuallyHidden">
          {title}
        </span>
        <PlainTooltip text={title}>
          <PlainIcon
            width={14}
            height="auto"
            type={iconTypes.LIGHT_CROSS}
            colorType={colorTypes.COLOR_BLACK}
          />
        </PlainTooltip>
      </PlainButtonAsLink>

      {confirmDisplayed
        && <PlainConfirmModal
          messageText={`${title}?`}
          onConfirm={clearAll}
          onCancel={hideModal}
          critical
          {...confirmModalProps}
        />}
    </Fragment>
  );
};

const RenderIfAccessed: ComponentType<Props> = writeConsist(MenuTableClearAll);

export default RenderIfAccessed;
