
export const CONFIRMATION_SHOW = 'CONFIRMATION_SHOW';
export const CONFIRMATION_HIDE = 'CONFIRMATION_HIDE';
export const CONFIRMATION_UPDATE_BUTTONS = 'CONFIRMATION_UPDATE_BUTTONS';

export const showConfirmation = payload => ({
  type: CONFIRMATION_SHOW,
  payload,
});

export const updateConfirmationButtons = payload => ({
  type: CONFIRMATION_UPDATE_BUTTONS,
  payload,
});

export const hideConfirmation = () => ({
  type: CONFIRMATION_HIDE,
});
