import { ReactNode } from 'react'
import cn from 'classnames/bind'

import styles from './content-group.scss'


const cx = cn.bind(styles)

type Props = {
  children: ReactNode
  className?: string
  header?: string | ReactNode
  transparentBackground?: boolean
}

const ContentGroup = ({ children, className, header, transparentBackground }: Props) => {
  return (
    <div className={cx(styles.root, { background: !transparentBackground }, className)}>
      {header
        && <h3 className={styles.header}>{header}</h3>}
      {children}
    </div>
  )
}

export default ContentGroup
