import api from 'app/actions/api';
import get from 'app/common/lib/get';


/**
* Запрос должностей
*/

export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_FAILURE = 'ROLES_FAILURE';
export const ROLES_CLEAR = 'ROLES_CLEAR';

export const rolesRequest = () => ({
  type: ROLES_REQUEST,
});

const rolesSuccess = payload => ({
  type: ROLES_SUCCESS,
  payload,
});

const rolesFailure = payload => ({
  type: ROLES_FAILURE,
  payload,
});

export const rolesClear = () => ({
  type: ROLES_CLEAR,
});

export const rolesFetch = () => async (dispatch) => {
  dispatch(rolesRequest());
  try {
    const res = await api('food/roles', 'get');
    dispatch(rolesSuccess(get(res, ['data', 'roles'], {})));
    return true;
  } catch (error) {
    dispatch(rolesFailure({ err: 'Запрос должностей не удался', error }));
    return false;
  }
};
