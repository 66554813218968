// @flow


const groupItems = () => ([[{
  id: 'by_groups_and_types',
  title: 'типам пользователя и классам',
}, {
  id: 'by_users',
  title: 'пользователям',
}], ['by_groups_and_types']]);

export default groupItems;
