// @flow
import React from 'react';
import classNames from 'classnames/bind';
import isEmpty from 'ramda/es/isEmpty';

import Tooltip from 'app/common/ui/Tooltip';
import Loading from 'app/common/components/Loader';

import enhanceMenuTable from './enhanceMenuTable';
import KitTooltip from './KitTooltip';
import MenuItemDish from './MenuItemDish';
import MenuItemKit from './MenuItemKit';
import EmptyStub from './EmptyStub';

import cellsStyles from './Cells.scss';
import styles from './MenuTable.scss';


const cx = classNames.bind(styles);

type Props = {
  items: Array<Array<Object>>,
  kits: Array<Object>,
  total: string,

  orgId: string,
  date: string,
  sellPointId: string,
  // categoryId: string,
  writeAccess: boolean,
  loading: boolean,
};

const MenuTable = ({
  items,
  kits,
  total,
  orgId,
  date,
  sellPointId,
  // categoryId,
  writeAccess,
  loading,
}: Props) => {
  const nothing = isEmpty(items) && isEmpty(kits);

  if (loading && nothing) {
    return (
      <Loading />
    );
  }

  if (!loading && nothing) {
    return (
      <EmptyStub />
    );
  }

  return (
    <div className={cx(styles.root)}>
      {loading && <Loading className={styles.loadingOverContent} />}
      <div className={cx({ loading })}>

        {/* Dishes */}
        {items.map((item) => {
          const categoryId = item[0].dishCategoryId;
          const categoryTitle = item[0].dishCategoryTitle;

          return (
            <div className={cellsStyles.row} key={categoryId}>
              <div className={styles.category}>{categoryTitle}</div>
              <div>
                {item.map(subitem => (
                  <MenuItemDish
                    key={subitem.id}
                    orgId={orgId}
                    date={date}
                    sellPointId={sellPointId}
                    // categoryId={categoryId}
                    className={classNames(cellsStyles.rowLvl2, styles.item)}
                    writeAccess={writeAccess}
                    {...subitem}
                  />
                ))}
              </div>
              <div>&nbsp;</div>
            </div>
          );
        })}

        {/* Complexes */}
        {!isEmpty(kits)
          && <div className={cellsStyles.row} key="kits">
            <div className={styles.category}>{'Комплекты'}</div>
            <div>
              {kits.map(kit => (
                <Tooltip
                  content={<KitTooltip items={kit.dishes} />}
                  key={kit.id}
                >
                  <MenuItemKit
                    key={kit.id}
                    orgId={orgId}
                    date={date}
                    sellPointId={sellPointId}
                    // categoryId={categoryId}
                    className={classNames(cellsStyles.rowLvl2, styles.item)}
                    writeAccess={writeAccess}
                    {...kit}
                  />
                </Tooltip>
              ))}
            </div>
            <div>&nbsp;</div>
          </div>}

        {/* TOTAL */}
        <div className={classNames(cellsStyles.row, styles.total)} key="total">
          <div className={styles.category}>&nbsp;</div>
          <div>
            <div className={cellsStyles.rowLvl2}>
              <div>{'ИТОГО'}</div>
              <div>&nbsp;</div>
              <div className={styles.right}>{total}</div>
              <div>&nbsp;</div>
            </div>
          </div>
          <div>&nbsp;</div>
        </div>

      </div>
    </div>
  );
};

export default enhanceMenuTable(MenuTable);
