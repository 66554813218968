// @flow
import React, { Fragment } from 'react';

import * as settings from 'app/common/constants/schools/settings';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import type { GeneralSettings } from 'app/dataTypes/org/settings/GeneralSettings';


const names = {
  [settings.CAFETERIA_SETTINGS_READONLY_FOR_SCHOOLS]: 'Разрешить редактирование настроек в других кабинетах',
  [settings.USE_PIN]: 'Использовать ПИН-коды',
  [settings.FREE_CAFE_ORDER_DAY]: 'Разрешить создавать заявки не в учебное время',
  [settings.ORDER_TARGETS_ENABLED]: 'Указывать комплекты при создании заявки',
  [settings.ENABLE_SUBSIDIES_FOR_ORDERS]: 'Начислять дотации по комплексному питанию',
  [settings.ENABLE_SUBSIDIES_FOR_SINGLE_SALES]: 'Начислять дотации по продажам',
  [settings.ALLOW_CASH_SALES]: 'Разрешать продажи за наличный расчет',
  [settings.ALLOW_BANK_CARD_SALES]: 'Разрешить продажи по банковской карте',
  [settings.ALLOW_GUESS_SALES]: 'Разрешать продавать «Гостям»',
  [settings.ALLOW_STUDENTS_TO_PARTICIPATE_IN_ORDERS]: 'Разрешить учащимся участвовать в формировании заявок',
  [settings.ALLOW_PARENTS_TO_PARTICIPATE_IN_ORDERS]: 'Разрешить родителям учащихся участвовать в формировании заявок',
};

type RenderSetProps = {
  set: Array<string>,
  disabled: boolean,
  data: GeneralSettings,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
}

const OptionsSet = ({
  set,
  disabled,
  data,
  onChange,
}: RenderSetProps) => (
  <Fragment>
    {set.map(key => (
      <PlainCheckbox
        // className={styles.checkbox}
        key={key}
        value={key}
        id={key}
        label={names[key] || '???'}
        disabled={
          disabled
          || data[key] === undefined
          || data[`enabled_${key}`] === false
        }
        checked={data[key]}
        onChange={onChange}
      />
    ))}
  </Fragment>
);

export default OptionsSet;
