import { useEffect, useState, ReactNode } from 'react'
import { values } from 'ramda'
import { useSelector, shallowEqual } from 'react-redux'

import { DotsLoader } from 'app/common/components/Loader'
import { RightsForOrgProvider } from 'app/rights/RightsForOrg'
import { useOrg } from 'app/dataProviders/org/OrgsDataProvider'
import type { RightsObject, RightsPath } from 'app/rights/types'
import * as orgsTypes from 'app/common/constants/schools/types'
import Sticky from 'app/common/templates-next/sticky'
import PageTemplate, { Header } from 'app/common/templates-next/page-template'
import { Rights } from 'app/common/constants/rights'
import { OrgType } from 'app/dataTypes/org/types'

import AccessDenied from './AccessDenied'
import Navigation from './Navigation'
import useNavigationAdditionalLinks from './useNavigationAdditionalLinks'
import getParentBaseUrl from '../getBaseUrl'

import styles from './OrgsPageTemplateV3.scss'


/**
 *  Шаблон страниц раздела с общей навигацией
 */

const emptyLinks = []
const availableOrgTypes = values(orgsTypes)


const stringAsOrgType = (val: string): OrgType | null => {
  if ((availableOrgTypes as string[]).includes(val)) return val as OrgType
  return null
}

type Props<T extends keyof Rights> = {
  children: ReactNode | ((type: OrgType, placeForGuests: boolean) => ReactNode)
  orgID: string
  date?: string
  rightsPath?: RightsPath<T>
}

export default function OrgsPageTemplateV3<T extends keyof Rights>({
  children,
  orgID,
  date,
  rightsPath,
}: Props<T>) {
  const [loading, setLoading] = useState(true)
  const orgId = parseInt(orgID, 10)
  // данные текущего заведения для заголовка и пр.
  const [orgProps, requestOrg] = useOrg(orgId)
  const { title: header, deleted, type, food_place_for_guests: placeForGuests } = orgProps
  const headerText = deleted ? `${header} (удалена)` : header

  useEffect(() => {
    (async () => {
      setLoading(true)
      await requestOrg()
      setLoading(false)
    })()
  }, [requestOrg])

  const parentBaseUrl = getParentBaseUrl()
  const links = useNavigationAdditionalLinks(parentBaseUrl, type)
  const additionalNavLinks = loading ? emptyLinks : links

  // доступ на просмотр раздела
  // пока данные по доступу не готовы, страница разблокирована,
  // это для того чтоб не было AccessDenied, при обновлении страницы
  const [access, setAccess] = useState<RightsObject | null>(null)
  const { read: readAccess } = access || { read: true }

  const { project_name: projectName } = useSelector((state: any) => state.dealer, shallowEqual)

  if (!readAccess) {
    return (
      <AccessDenied />
    )
  }

  const orgType = stringAsOrgType(type)

  if (!orgType) {
    return null
  }

  return (
    <RightsForOrgProvider
      orgId={orgId}
      preferred={rightsPath}
      onUpdatePreferredRights={setAccess}
    >
      <PageTemplate
        title={projectName ? `${headerText} - ${projectName}` : headerText}
      >
        <Sticky>
          {ref => (
            <div ref={ref} className={styles.hasBackground}>
              <Header>
                {header ? headerText : <DotsLoader simple />}
              </Header>
            </div>
          )}
        </Sticky>

        <Sticky>
          {ref => (
            <Navigation
              ref={ref}
              className={styles.hasBackground}
              orgID={orgID}
              date={date}
              orgType={orgType}
              additionalLinks={additionalNavLinks}
            />
          )}
        </Sticky>

        {typeof children === 'function'
          ? children(orgType, placeForGuests)
          : children}
      </PageTemplate>
    </RightsForOrgProvider>
  )
}
