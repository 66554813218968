import cn from 'classnames/bind'

import PlainTooltip from 'app/common/ui-next/plain-tooltip'

import Td, { type TdProps } from './td'
import styles from './td-label.scss'


/**
 * UI COMPONENTS v2
 *
 * Ячейка таблицы c функцией label
 * ------
 * Для случаев, когда нажатие на строку таблицы
 * должно переключить чек-бокс или radio
 */

const cx = cn.bind(styles)

type Props = {
  htmlFor: string
  tooltip?: string
} & TdProps

export const TdLabel = ({
  children,
  className,
  htmlFor,
  tooltip,
  ...props
}: Props) => {
  const labelContent = (
    <label // eslint-disable-line jsx-a11y/label-has-associated-control
      className={styles.label}
      htmlFor={htmlFor}
    />
  )

  return (
    <Td className={cx(styles.root, className)} {...props}>
      {children}
      {tooltip
        ? <PlainTooltip text={tooltip}>{labelContent}</PlainTooltip>
        : labelContent}
    </Td>
  )
}
