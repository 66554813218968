import { useCallback, type ReactNode, memo, ChangeEvent } from 'react'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'

import { optionKey } from './useRejectSubsidiesPeriodOpt'
import CopySettingsContainer from '../common/CopySettingsContainer'


type Props = {
  currentState: boolean
  onChange: (val: boolean) => void
  disabled?: boolean
  children: ReactNode
  hasTopMargin?: boolean
}

const RejectSubsidiesPeriodOpt = memo<Props>(({
  currentState,
  onChange,
  disabled,
  children,
  hasTopMargin,
}: Props) => {
  const changeOption = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    onChange(checked)
  }, [onChange])

  return (
    <CopySettingsContainer hasTopMargin={hasTopMargin}>
      <PlainCheckbox
        value={optionKey}
        label="Запретить управлять периодом неначисления дотаций"
        disabled={disabled}
        checked={currentState}
        onChange={changeOption}
      />

      {!disabled && children}
    </CopySettingsContainer>
  )
})

export default RejectSubsidiesPeriodOpt
