// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';
import { saleTypes } from 'app/dataTypes/reports/saleTypes';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const onlyComplexSelected = (state: State) => {
  const sellTypesSelected = getStateCurrentValue(filterId, state);
  return (
    sellTypesSelected.length === 1
    && sellTypesSelected[0] === saleTypes.Complex
  );
};
