// @flow
import keys from 'ramda/es/keys';
import find from 'ramda/es/find';


export default function useFlagSelectedAll(
  items: Object,
  existingItems: Object,
  addList: { [id: string]: string },
) {
  const itemsKeys = keys(items);
  return !find(key => !(addList[key] || existingItems[key]), itemsKeys);
}
