import { ReactNode, useCallback } from 'react'
import moment from 'moment-timezone'

import { ReportDetailsRoot, ReportSection } from 'app/common/templates-next/report-template'
import { CompensationInfo, CompensationInfoByOrgID } from 'app/dataTypes/compensation/info'
import { useCompensationActionsAll, useCompensationStatusAll } from 'app/dataProviders/compensation/hooks'
import { CompensationAction } from 'app/dataTypes/compensation/action'

import { Header } from './Header'
import { TableLoading } from './TableLoading'
import { DataTable } from './dataTable'
import { useSelection } from './useSelection'
import { SelectionActions } from './SelectionActions'

import { Summary } from '../summary'


type Props = {
  data: CompensationInfoByOrgID
  year: string
  month: string
  loading: boolean
  lockSelectionMode: boolean
  currency: string
  onAction: (params: Partial<CompensationAction>) => Promise<boolean>
  onRowDataUpdateRequired: (orgID: string) => void
  onRequestInfo: (orgID: string) => Promise<CompensationInfo | null>
  compensationDetailsTable: (params: { orgID: string, allowanceID: string }) => ReactNode
  compensationDetailsTableFooter: () => ReactNode
}

export const Result = ({
  data,
  year,
  month,
  loading,
  lockSelectionMode,
  currency,
  onAction,
  onRowDataUpdateRequired,
  onRequestInfo,
  compensationDetailsTable,
  compensationDetailsTableFooter,
}: Props) => {
  const {
    selection,
    selectionMode,
    setSelectionMode,
    changeSelection,
    reset,
    selectedAll,
    changeSelectionAll,
  } = useSelection(data)

  const date = `${moment().month(parseInt(month, 10) - 1).format('MMMM')} ${year}`

  const { hasItemsToRecord, hasItemsToCancel } = useCompensationStatusAll(data)
  const actionsAll = useCompensationActionsAll(onAction, selection, selectionMode)

  const handleClickAction = useCallback(async () => {
    reset()
    const res = await actionsAll()
    return res
  }, [reset, actionsAll])

  return (
    <ReportSection
      skipPadding
      footerBar={
        !!selectionMode
          && <SelectionActions
            selectionNumber={selection.size}
            onClickCancel={reset}
            onClickAction={handleClickAction}
            actionTitle={
              selectionMode === 'record'
                ? 'Зафиксировать выбранные'
                : 'Удалить фиксацию с выбранных'
            }
            confirmMessage={
              selectionMode === 'record'
                ? 'Зафиксировать все выбранные?'
                : 'Удалить фиксацию с выбранных?'
            }
          />
      }
    >
      <Summary
        data={data}
        currency={currency}
        loading={lockSelectionMode}
      />

      <ReportDetailsRoot header="Список заведений">
        <Header
          showRecordButton={hasItemsToRecord}
          showCancelButton={hasItemsToCancel}
          onSelectionMode={setSelectionMode}
          disabled={lockSelectionMode}
        />

        {loading
          && <TableLoading />}

        {!loading
          && <DataTable
            data={data}
            date={date}
            currency={currency}
            onAction={onAction}
            onRowDataUpdateRequired={onRowDataUpdateRequired}
            onRequestInfo={onRequestInfo}
            compensationDetailsTable={compensationDetailsTable}
            compensationDetailsTableFooter={compensationDetailsTableFooter}
            selectionMode={selectionMode}
            selection={selection}
            onChangeSelection={changeSelection}
            onChangeSelectionAll={changeSelectionAll}
            selectedAll={selectedAll}
            showSelectAllCheckbox={!!selectionMode}
          />}
      </ReportDetailsRoot>
    </ReportSection>
  )
}
