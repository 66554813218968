// @flow
import React, { type Node, useCallback } from 'react';

import { TrClickable, Td, getPropsHeader } from 'app/common/ui-components/table';
import { sections } from 'app/components/VendingMachine';

import columns, { type ColumnVisibility } from './columns';
import CellNumberAndModel from './cells/CellNumberAndModel';
import CellTitle, { statuses } from './cells/CellTitle';
import CellGoodsAmount from './cells/CellGoodsAmount';
import CellDate from './cells/CellDate';
// import CellErrors from './cells/CellErrors';
import CellActions from './cells/CellActions';
import CellPlace from './cells/CellPlace';
import ButtonRedirect from './cells/ButtonRedirect';


export type TableRowProps = {
  className: string,
  id: number,
  title: string,
  org: string,
  number: string,
  model: string,
  goodsAmountInPercent: number,
  loadedAt: string,
  lastSellAt: string,
  errors: number,
  sameOrg: boolean,
  rowSpan: number,
}

type Props = {
  loading?: boolean,
  settings: ColumnVisibility,
  onMouseEnter: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onRedirectToVendingMachine: (id: string, section?: string) => void,
} & TableRowProps

const TBodyRow = ({
  loading,
  settings,
  className,
  onMouseEnter,
  onMouseLeave,
  onRedirectToVendingMachine,
  id,
  title,
  org,
  number,
  model,
  goodsAmountInPercent,
  loadedAt,
  lastSellAt,
  // errors,
  sameOrg,
  rowSpan,
}: Props): Node => {
  const headers = getPropsHeader();

  const gotoMachine = useCallback((machineId: string) => {
    onRedirectToVendingMachine(machineId);
  }, [onRedirectToVendingMachine]);

  const gotoMachineProduction = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRedirectToVendingMachine(String(id), sections.PRODUCTION);
  }, [onRedirectToVendingMachine, id]);

  const gotoMachineStorage = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRedirectToVendingMachine(String(id), sections.STORAGE);
  }, [onRedirectToVendingMachine, id]);

  const gotoMachinePurchases = useCallback((e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRedirectToVendingMachine(String(id), sections.PURCHASES);
  }, [onRedirectToVendingMachine, id]);

  const contents = [
    // Место размещения
    <CellPlace name={org}>
      <ButtonRedirect onClick={gotoMachineProduction}>
        {'Продукция'}
      </ButtonRedirect>
    </CellPlace>,

    // Наименование
    <CellTitle
      title={title}
      status={statuses.STATUS_ON}
    />,

    // Номер и модель
    <CellNumberAndModel
      number={number}
      model={model}
    />,

    // Кол-во товара
    <CellGoodsAmount
      amount={goodsAmountInPercent}
    />,

    // Последняя загрузка
    <CellDate value={loadedAt}>
      <ButtonRedirect onClick={gotoMachineStorage}>
        {'Склад аппарата'}
      </ButtonRedirect>
    </CellDate>,

    // Последняя покупка
    <CellDate value={lastSellAt}>
      <ButtonRedirect onClick={gotoMachinePurchases}>
        {'Покупки'}
      </ButtonRedirect>
    </CellDate>,

    // *
    <CellActions />,
  ];

  return (
    <TrClickable
      id={String(id)}
      loading={loading}
      hoverHighlighted={false}
      onClick={gotoMachine}
      interlineBorder={!sameOrg}
    >
      {columns.map(({ id: columnId, props }, i) => (
        (sameOrg && i === 0)
        || !settings[columnId]
          ? null
          : <Td
            key={columnId}
            className={i !== 0 ? className : undefined}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            rowSpan={i === 0 ? rowSpan : 1}
            ignoreFirstChild={i === 1 && sameOrg}
            {...props}
            {...headers(columnId)}
          >
            {contents[i]}
          </Td>
      ))}
    </TrClickable>
  );
};

export default TBodyRow;
