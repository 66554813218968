// @flow
import React from 'react';

type Props = {
  children: any,
  className?: string,
}

const SubsidiesSheet = ({ children, className }: Props) => (
  <div className={className}>
    {children}
  </div>
);

export default SubsidiesSheet;
