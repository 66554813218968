import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import type { HOC } from 'recompose';

import {
  showConfirmation as showConfirmationAction,
  hideConfirmation as hideConfirmationAction,
} from 'app/common/actions/confirmation';


type Props = {
  text?: string,
  confirmButtonText?: string,
  cancelButtonText?: string,
  confirmButtonProps?: Object,
  onConfirm: (param: any) => void,
  confirmParams?: any,
  children: (Function) => React.ElementType | React.ElementType,
  stopPropagation: boolean,
}

const enhance: HOC<*, Props> = compose(
  connect(
    null,
    (dispatch, props) => ({
      handleConfirm: (e) => {
        e.preventDefault();
        if (typeof props.onConfirm === 'function') {
          props.onConfirm(props.confirmParams);
        }
        dispatch(hideConfirmationAction());
      },
      showConfirmation: payload => dispatch(showConfirmationAction(payload)),
      hideConfirmation: (e) => {
        e.preventDefault();
        dispatch(hideConfirmationAction());
      },
    }),
  ),

  withHandlers({
    handleConfirmation: ({
      handleConfirm,
      text,
      confirmButtonText,
      cancelButtonText,
      showConfirmation,
      hideConfirmation,
      confirmButtonProps,
      stopPropagation,
    }) => (e) => {
      if (e) {
        e.preventDefault();
      }
      if (e && stopPropagation && e.stopPropagation) {
        e.stopPropagation();
      }
      showConfirmation({
        text: text || 'Вы уверены?',
        buttons: [{
          value: confirmButtonText || 'Ок',
          onClick: handleConfirm,
          key: 'ok',
          ...confirmButtonProps,
        }, {
          value: cancelButtonText || 'Отмена',
          outline: true,
          onClick: hideConfirmation,
          key: 'cancel',
        }],
      });
    },

  }),
);

const ConfirmationDialog = ({ handleConfirmation, children }) => (
  typeof children === 'function'
    ? children(handleConfirmation)
    : React.Children.map(children, child => React.cloneElement(child, {
      onClick: handleConfirmation,
    }))
);

export default enhance(ConfirmationDialog);
