import { useCallback, useMemo } from 'react'

import useCallbackRef from 'app/common/hooks/useCallbackRef'

import PlainDropdownSurface, { SurfaceType } from '../plain-dropdown-surface'
import PlainIcon, { iconType, iconColor } from '../plain-icon'
import { dateFormat as dateFormatDefault } from '../plain-calendar/date-format'
import { PlainCalendarDouble } from '../plain-calendar/plain-calendar-double'
import { daysFromPeriod } from './days-from-period'

import styles from './plain-date-picker-double.scss'
import { addDayToRange } from './add-day-to-range'


export type PlainDatePickerDoubleProps = {
  className?: string
  dateFrom: string | null
  dateTo: string | null
  dateFormat?: string
  closeOnClick?: boolean
  weekSelection?: boolean
  onChange?: (from: string | null, to: string | null, format: string) => void
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean
}

export const PlainDatePickerDouble = ({
  className,
  dateFrom,
  dateTo,
  dateFormat = dateFormatDefault,
  closeOnClick = true,
  weekSelection,
  onChange,
  dateDisabledStatus,
}: PlainDatePickerDoubleProps) => {
  const onChangeRef = useCallbackRef(onChange)

  const dateClick = useCallback((toggle: () => void) => (date_: string, format_: string) => {
    const range = addDayToRange(
      { day: date_, format: format_ },
      { from: dateFrom, to: dateTo, format: dateFormat },
      dateFormat,
    )

    onChangeRef.current?.(range.from, range.to, dateFormat)

    if (closeOnClick) {
      toggle()
    }
  }, [onChangeRef, closeOnClick, dateTo, dateFrom, dateFormat])

  const daysSelected = useMemo(() => {
    return daysFromPeriod(dateFrom, dateTo, dateFormat)
  }, [dateFrom, dateTo, dateFormat])

  const renderCalendar = useCallback((toggle: () => void) => (
    <PlainCalendarDouble
      onDayClick={dateClick(toggle)}
      daysSelected={daysSelected}
      dateFormat={dateFormat}
      dateDisabledStatus={dateDisabledStatus}
      weekSelection={weekSelection}
    />
  ), [dateClick, dateFormat, daysSelected, dateDisabledStatus, weekSelection])

  return (
    <PlainDropdownSurface
      id="dateDouble"
      type={SurfaceType.Grid}
      surfaceContent={renderCalendar}
      className={className}
    >
      {(onClick, opened, areaProps) => (
        <button
          type="button"
          onClick={onClick}
          className={styles.button}
          {...areaProps}
        >
          <PlainIcon
            type={iconType.CALENDAR}
            colorType={opened ? iconColor.COLOR_PRIMARY : iconColor.COLOR_GRAY}
            width={18}
            height="auto"
          />
        </button>
      )}
    </PlainDropdownSurface>
  )
}
