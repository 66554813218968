import pipe from 'ramda/es/pipe';
import omit from 'ramda/es/omit';
import prop from 'ramda/es/prop';

import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const SELLPOINTS_CHANGE_REQUEST = 'orgs/settings/SELLPOINTS_CHANGE_REQUEST';
export const SELLPOINTS_CHANGE_SUCCESS = 'orgs/settings/SELLPOINTS_CHANGE_SUCCESS';
export const SELLPOINTS_CHANGE_FAILURE = 'orgs/settings/SELLPOINTS_CHANGE_FAILURE';

export const changeSellPoint = (orgId, data) => async (dispatch) => {
  dispatch(action(SELLPOINTS_CHANGE_REQUEST, { orgId, data }));
  try {
    const res = await api(`food/schools/${orgId}/sale_points`, 'put', { data });
    const sellpoints = pipe(prop('data'), omit(['result']))(res);
    dispatch(action(SELLPOINTS_CHANGE_SUCCESS, sellpoints));
    return true;
  } catch (error) {
    dispatch(action(SELLPOINTS_CHANGE_FAILURE, {
      err: 'Не удалось модифицировать точку продаж',
      error,
      orgId,
      data,
    }));
    return false;
  }
};
