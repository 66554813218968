import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';

import passwords from 'app/common/containers/passwords';
import { logout as logoutAction } from 'app/common/actions/user';
import { changeTemporaryPassword as changeTemporaryPasswordAction } from 'app/common/actions/profile/password';


export default compose(
  connect(
    state => ({
      saving: state.profile.saving,
    }),
  ),

  passwords,

  withHandlers({
    handleLogout: ({ dispatch }) => (e) => {
      e.preventDefault();
      dispatch(logoutAction());
    },

    handleChange: ({ changeNewPassword1, changeNewPassword2 }) => (e) => {
      const { name, value } = e.target;
      switch (name) {
        case 'newpassword1': {
          changeNewPassword1(value);
          break;
        }
        case 'newpassword2': {
          changeNewPassword2(value);
          break;
        }
        default:
      }
    },

    handleSubmit: ({ dispatch, newPassword1 }) => (e) => {
      e.preventDefault();
      dispatch(changeTemporaryPasswordAction(newPassword1));
    },


  }),
);
