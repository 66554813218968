// @flow
import { getStateCurrentValue, type State, getStateIsFilterActive } from 'app/common/ui-components/filter';
import { balanceTypes } from 'app/dataTypes/reports/balanceTypes';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const cafeteriaSelected = (state: State): boolean => {
  const [radioState] = getStateCurrentValue(filterId, state) || [];
  return radioState === balanceTypes.Cafeteria;
};
