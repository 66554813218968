import { useEffect } from 'react'

import { CompensationInfoStatus } from 'app/dataTypes/compensation/info'


type Props = {
  status: CompensationInfoStatus
  onChangeStatus: () => void
}

export const SkipConfirmation = ({ status, onChangeStatus }: Props) => {
  useEffect(() => {
    onChangeStatus()
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    null
  )
}
