import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import iframeResizer from 'iframe-resizer/js/iframeResizer';
import { withTranslation } from 'react-i18next';

import Button from '../../../ui/Button';
import styles from './Text.scss';


type Props = {
  handleHide: (e: SyntheticEvent<HTMLElement>) => void,
  url: string,
  title?: string,
  t: Function,
};

class Text extends React.Component<Props> {
  frameRef = (item) => {
    iframeResizer({ checkOrigin: false }, item);
  };

  props: Props;

  render() {
    const { title, url, handleHide, t } = this.props;
    const header = title || t('agreenent_title');

    return (
      <div className={styles.root}>
        <form onSubmit={handleHide}>
          <div className={styles.header}>
            {header}
          </div>
          <Scrollbars style={{ width: '100%', height: 400, display: 'block' }} className={styles.text}>
            <iframe
              title={header}
              src={`${url}?iframeSizer=true`}
              frameBorder="0"
              scrolling="no"
              ref={this.frameRef}
              className={styles.frame}
            />
          </Scrollbars>
          <Button className={styles.submit} type="submit">{t('agreenent_back_bt')}</Button>
        </form>
      </div>

    );
  }
}

export default withTranslation('login')(Text);
