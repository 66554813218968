// @flow
import React, { type Node, type Ref, useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './tr.scss';


/**
 * UI COMPONENTS v2
 *
 * Строка таблицы
 * ------
 * Семантически верная таблица. Строка таблицы (tr)
 */

const cx = classNames.bind(styles);

export type TrProps = {
  children: Node,
  className?: string,
  stripStyles?: boolean,
  hoverHighlighted?: boolean,
  alignTop?: boolean,
  interlineBorder?: boolean,
  hover?: boolean,
  header?: boolean,
  headerHasBorderBottom?: boolean,
  footer?: boolean,
  reference?: Ref<'tr' |  HTMLTableRowElement>,
  hidden?: boolean,
  disabled?: boolean,
  loading?: boolean, // для совместимости
  role?: string,
  tabIndex?: string,
  style?: {},
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
  onKeyUp?: (e: SyntheticKeyboardEvent<HTMLElement>) => void,
  onUnmount?: (node: ?HTMLElement) => void,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
}

const Tr = ({
  children,
  className,
  stripStyles,
  hoverHighlighted = true,
  alignTop,
  interlineBorder,
  hover,
  header,
  headerHasBorderBottom = true,
  footer,
  reference,
  hidden,
  disabled,
  loading,
  role,
  tabIndex,
  style,
  onClick,
  onKeyUp,
  onUnmount,
  onMouseEnter,
  onMouseLeave,
}: TrProps) => {
  const cn = {
    root: !stripStyles,
    hoverHighlighted: !header && !footer && hoverHighlighted,
    alignTop,
    interlineBorder,
    hover,
    header,
    headerHasBorderBottom,
    footer,
    hidden,
    disabled: disabled || loading,
  };

  const props = {
    role,
    tabIndex,
    onClick,
    onKeyUp,
    onMouseEnter,
    onMouseLeave,
    style,
  };

  useEffect(() => () => {
    if (onUnmount) {
      onUnmount();
    }
  }, []);

  return (
    <tr className={cx(cn, className)} ref={reference} {...props}>
      {children}
    </tr>
  );
};

export default Tr;
