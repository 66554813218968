// @flow
import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';

import mailFilter, { mailItems, mailProps, mailActivity } from 'app/components/common/reports/filters/mail';
import detailsFilter, { detailsItems } from 'app/components/common/reports/filters/details';

import periodFilter, { periodItems, periodProps } from './period';


type Params = {
  email: string | null
}

const filterItems: Descriptions<Params> = [{

  /* фильтр по дате (период) */
  filterId: periodFilter,
  filterType: filterTypes.PLAIN_PERIOD,
  multiple: false,
  getProps: periodProps,
  getItemsAndCurrent: periodItems,
}, {

  /* фильтр детализация */
  filterId: detailsFilter,
  filterType: filterTypes.PLAIN_CHECKBOX_GROUP,
  props: {
    label: null, // 'Детализированный отчет' из items
    keepPadding: true,
  },
  getItemsAndCurrent: detailsItems,
}, {

  /* фильтр отправки на email */
  filterId: mailFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  getProps: mailProps,
  getItemsAndCurrent: mailItems,
  activity: mailActivity,
}];

export default filterItems;
