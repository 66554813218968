import { Params, Complexes } from 'app/dataTypes/subsidy/complexes'

import type { Request } from '../../../index'


const isApiCommonAllowance = (val: string) => {
  return val === 'Общая справка'
}

type ParamsAPI = {
  order_type: 'cafeteria' | 'camp' | 'common'
  additional?: boolean
  allowance?: string
}

type PathParams = {
  orgID: string
}

export const convertParams = ({
  orgID,
  app,
  allowanceID,
}: Params): [ParamsAPI, PathParams] => {
  const paramsURL = { orgID }

  if (app === 'additional') return [{ order_type: 'common', additional: true }, paramsURL]
  if (isApiCommonAllowance(allowanceID)) return [{ order_type: 'common' }, paramsURL]

  return [{
    order_type: app,
    allowance: allowanceID,
  }, { orgID }]
}

type ResultAPI = {
  complexes: Array<{
    id: number
    title: string
  }>
}

export const convertResult = ({ complexes }: ResultAPI): Complexes => {
  return complexes.map(({ id, title }) => ({
    ID: String(id),
    title,
  }))
}

export const GET_SCHOOL_SUBSIDY_ORDERS_COMPLEXES: Request<Params, Complexes, PathParams> = [
  'food/schools/{orgID}/subsidy_orders_complexes',
  'get',
  ['data'],
]
