import { useCallback } from 'react'

import { POST_AGREEMENT_DOCUMENTS, convertResult, convertParams } from 'app/common/api/requests/food/agreement/documents/post'
import { DELETE_AGREEMENT_DOCUMENTS, convertParams as convertParamsDelete, convertResult as convertResultDelete } from 'app/common/api/requests/food/agreement/documents/delete'
import { AddAgreementDocumnetParams, DeleteAgreementDocumnetParams, AgreementDocumentType } from 'app/dataTypes/agreement/documents'

import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { api } from 'app/api'


type Options = {
  onSubmitSuccess?: (res: AgreementDocumentType) => void
  onDeleteSuccess?: (ID: string) => void
}

export function useAgreementDocumentsActionsBase(options: Options = {} as Options) {
  const { onSubmitSuccess, onDeleteSuccess } = options
  const onSubmitSuccessRef = useCallbackRef(onSubmitSuccess)
  const onDeleteSuccessRef = useCallbackRef(onDeleteSuccess)

  const submitDocument = useCallback(async (params: AddAgreementDocumnetParams) => {
    const res = await api.request(POST_AGREEMENT_DOCUMENTS, {
      error: 'Не удалось добавить документ',
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    })
    if (res) {
      onSubmitSuccessRef.current?.(res)
    }
    return res
  }, [onSubmitSuccessRef])

  const deleteDocument = useCallback(async (params: DeleteAgreementDocumnetParams) => {
    const { ID } = params
    const res = await api.request(DELETE_AGREEMENT_DOCUMENTS, {
      error: 'Не удалось удалить документ',
      params,
      convertions: {
        convertParams: convertParamsDelete,
        convertResult: convertResultDelete,
      },
    })
    if (res) {
      onDeleteSuccessRef.current?.(ID)
    }
    return !!res
  }, [onDeleteSuccessRef])

  return {
    submitDocument,
    deleteDocument,
  }
}
