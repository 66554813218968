import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import Message from 'app/common/ui/Message';
import RowLayout, { Header, Space } from 'app/common/layouts/RowLayout';


class Empty extends React.Component {
  state = {
    opened: true,
  };

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;
    if (prevProps.location.pathname !== pathname) {
      this.setState({ opened: true }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  close = (e) => {
    e.preventDefault();
    this.setState({ opened: false });
  };

  render() {
    const { opened } = this.state;
    return (
      <Fragment>
        <Space />
        <RowLayout>
          <Header last>{'Раздел еще не готов...'}</Header>
        </RowLayout>
        {opened
          && <Message
            text={'Данный раздел находится в стадии разработки.\nИзвините\nза предоставленные неудобства!'}
            buttons={[{
              value: 'OK',
              key: 'OK',
              onClick: this.close,
            }]}
          />}
      </Fragment>
    );
  }
}

export default withRouter(Empty);
