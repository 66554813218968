import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

import Header from './header'


type Props = {
  title?: string
  header?: string
  children: ReactNode
}

const PageTemplate = ({ title, header, children }: Props) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {header && <Header>{header}</Header>}
      {children}
    </>
  )
}

export default PageTemplate
