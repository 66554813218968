// @flow
import React, { Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames/bind';

import {
  Table,
  Tr,
  Th,
  Td,
  useHoverByClass,
} from 'app/common/ui-components/table';

import Paging from 'app/common/ui/Paging';
import type { SortHandle, SortState } from 'app/common/containers/withSortStateDouble';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { ReservationHistoryModal } from 'app/common/components-next/reservation-history';
import { FundReturnModal } from 'app/common/components-next/fund-return';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import enhanceUsersByGroup from './enhanceUsersByGroup';
import type { UserByGroupType } from './selectActual';

import ts from '../Table.scss';
import styles from './UsersByGroup.scss';


const cx = classNames.bind(styles);
const sameRowCN = 'row_';

type Props = {
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  users: Array<Array<UserByGroupType>>,
  currentPage: number,
  total: number,
  rows: number,
  sort: SortState,
  handleSort1: SortHandle,
  handleSort2: SortHandle,
  onInvalidateReport: () => void,
}

const UsersByGroup = ({
  handlePage,
  users,
  currentPage,
  total,
  rows,
  sort: {
    sortKey1,
    sortKey2,
    sortDirection1,
    sortDirection2,
  },
  handleSort1,
  handleSort2,
  onInvalidateReport,
}: Props) => {
  const current: boolean = useSelector(state => state.reports.balance.params.current, shallowEqual);
  const [onCellMouseEnter, onCellMouseLeave] = useHoverByClass(sameRowCN);
  const { e_money_enabled: eMoneyEnabled } = useSelector(state => state.dealer, shallowEqual);
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <Fragment>
      <DetailsHeader reportTitle="Остаток средств" />

      {/* ЗАГОЛОВОК */}
      <CSSSticky
        number={2}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <Tr header>
              <Th
                id="empty1"
                className={cx(styles.headerCell, { cellEmpty: !current, cellEmptySmall: current })}
              >
                &nbsp;
              </Th>

              <Th
                id="header1"
                className={cx(styles.headerCell, styles.cellGroup)}
                sortKey="group"
                sortDirection={sortKey1 === 'group' ? sortDirection1 : undefined}
                onSortArrowClick={handleSort1}
                alignLeft
              >
                {'Класс\u00A0/ Должность'}
              </Th>

              <Th
                id="header2"
                className={cx(styles.headerCell, styles.cellName)}
                sortKey="name"
                sortDirection={sortKey2 === 'name' ? sortDirection2 : undefined}
                onSortArrowClick={handleSort2}
                alignLeft
              >
                {'ФИО'}
              </Th>

              <Th
                id="header3"
                className={cx(styles.headerCell, styles.cellBalance)}
                sortKey="balance"
                sortDirection={sortKey2 === 'balance' ? sortDirection2 : undefined}
                onSortArrowClick={handleSort2}
                alignRight
              >
                {`Баланс${currency}`}
              </Th>

              <Th
                id="header4"
                className={cx(styles.headerCell, styles.cellLinks)}
                alignLeft
              >
                {'Дополнительно'}
              </Th>

              <Th
                id="empty2"
                className={cx(styles.headerCell, { cellEmpty: !current, cellEmptySmall: current })}
              >
                &nbsp;
              </Th>
            </Tr>
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <tbody>
          {users.map((gp, j) => {
            const rowSpan = gp.length;

            return gp.map(({ id, group, name, balance, customerId }, i) => (
              <Tr key={id}>
                {i === 0
                  && <Td
                    headers="empty1"
                    rowSpan={rowSpan}
                    className={cx({ cellEmpty: !current, cellEmptySmall: current }, `${sameRowCN}${j}`)}
                    onMouseEnter={onCellMouseEnter}
                    onMouseLeave={onCellMouseLeave}
                  >
                    &nbsp;
                  </Td>}

                {i === 0
                  && <Td
                    headers="header1"
                    rowSpan={rowSpan}
                    className={cx(styles.cellGroup, `${sameRowCN}${j}`)}
                    onMouseEnter={onCellMouseEnter}
                    onMouseLeave={onCellMouseLeave}
                  >
                    {group}
                  </Td>}

                <Td
                  headers="header2"
                  className={cx(styles.cellName, styles.overridePadding, `${sameRowCN}${j}`)}
                  onMouseEnter={onCellMouseEnter}
                  onMouseLeave={onCellMouseLeave}
                >
                  {name}
                </Td>

                <Td
                  headers="header3"
                  className={cx(styles.cellBalance, styles.overridePadding, `${sameRowCN}${j}`)}
                  onMouseEnter={onCellMouseEnter}
                  onMouseLeave={onCellMouseLeave}
                  alignRight
                >
                  {balance}
                </Td>

                {current
                  && <Td
                    headers="header4"
                    className={cx(styles.cellLinks, `${sameRowCN}${j}`)}
                    onMouseEnter={onCellMouseEnter}
                    onMouseLeave={onCellMouseLeave}
                  >
                    <ReservationHistoryModal
                      customerId={customerId}
                      name={name}
                      group={group}
                    >
                      {'Резерв-ние'}
                    </ReservationHistoryModal>
                    {' '}
                    {parseFloat(balance) > 0
                      && !eMoneyEnabled
                      && <FundReturnModal
                        customerId={customerId}
                        name={name}
                        group={group}
                        onRefund={onInvalidateReport}
                      >
                        {'Возврат'}
                      </FundReturnModal>}
                  </Td>}

                {i === 0
                  && <Td
                    headers="empty2"
                    rowSpan={rowSpan}
                    className={cx({ cellEmpty: !current, cellEmptySmall: current }, `${sameRowCN}${j}`)}
                    onMouseEnter={onCellMouseEnter}
                    onMouseLeave={onCellMouseLeave}
                  >
                    &nbsp;
                  </Td>}
              </Tr>
            ));
          })}
        </tbody>
      </Table>

      {(total > rows)
        && <Paging
          className={ts.pager}
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={users.length}
          onPage={handlePage}
        />}

    </Fragment>
  );
};

export default enhanceUsersByGroup(UsersByGroup);
