import { controls } from './formParts/AccuralForFormCamp'


export const checkErrorsCampAccrualsFor = (err: Map<string, string>, formData: FormData) => {
  if (!(
    formData.get(`${controls.camp}1`)
    || formData.get(`${controls.camp}2`)
  )) {
    err.set('accrualCampFor', 'Выберите хотя бы один пункт')
  }
}
