// @flow
import { useMemo, useEffect } from 'react';
import prop from 'ramda/es/prop';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import defaultTo from 'ramda/es/defaultTo';
import sortBy from 'ramda/es/sortBy';
import reduce from 'ramda/es/reduce';
import groupBy from 'ramda/es/groupBy';
import toPairs from 'ramda/es/toPairs';
import addIndex from 'ramda/es/addIndex';

import {
  type VendingMachinesState,
  useRelatedOrgs,
  useModels,
  useVersions,
} from 'app/dataProviders/VendingDataProvider';

import machine2TableRowProps from './machine2TableRowProps';
import { type TableRowProps } from './TBodyRow';


const empty = [];
export const sameGroup = 'group_';
const reduceIndexed = addIndex(reduce);

export default function useTableItems(machines: VendingMachinesState): Array<TableRowProps> {
  const [models,, requestModels] = useModels();
  const [versions,, requestVersions] = useVersions();
  const relatedOrgs = useRelatedOrgs();

  useEffect(() => {
    requestModels();
  }, [requestModels]);

  useEffect(() => {
    requestVersions();
  }, [requestVersions]);

  const res = useMemo(() => pipe(
    prop('data'),
    defaultTo(empty),
    map(machine2TableRowProps(relatedOrgs, models, versions)),
    groupBy(prop('org')),
    toPairs,
    sortBy(prop(0)),
    reduceIndexed((acc, [, sameOrgArray], i) => {
      const rowSpan = sameOrgArray.length;

      sameOrgArray.forEach((value, idx) => {
        const className = `${sameGroup}${i + 1}${idx + 1}`;
        acc.push(
          idx === 0
            ? { ...value, className, rowSpan }
            : { ...value, className, sameOrg: true },
        );
      });
      return acc;
    }, []),
  )(machines), [machines, relatedOrgs, models, versions]);

  return res;
}
