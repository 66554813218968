import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import replace from 'ramda/es/replace';
import toString from 'ramda/es/toString';

import { balanceFilterSet, balanceFilterClear } from 'app/actions/reports/filters/balance';
import { priceRe } from 'app/common/constants/regex';
import withReset from 'app/components/Reports/hocs/withReset';


export default compose(
  connect(
    state => ({
      min: state.reports.filters.balance.min,
      max: state.reports.filters.balance.max,
    }),
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(balanceFilterClear());
    },
    handleChange: ({ dispatch }) => (e) => {
      const { name, value } = e.currentTarget;
      if (name !== 'min' && name !== 'max') return;
      const replacedDot = replace(/,/, '.', value);
      const replaced = replace(/[-−\-–]/, '-', replacedDot);
      if (priceRe.test(replaced)) {
        dispatch(balanceFilterSet({ name, value: replaced }));
      }
    },
    handleBlur: ({ dispatch, min, max }) => (e) => {
      const { name, value } = e.currentTarget;
      if (value === '') return;
      const parsedMin = parseFloat(min);
      const parsedMax = parseFloat(max);
      if (name === 'min') {
        dispatch(balanceFilterSet({
          name,
          value: toString(parsedMin > parsedMax ? parsedMax : parsedMin) }));
        return;
      }
      if (name === 'max') {
        dispatch(balanceFilterSet({
          name,
          value: toString(parsedMax < parsedMin ? parsedMin : parsedMax) }));
      }
    },
  }),

  mapProps(({ min, max, ...rest }) => ({
    min: replace(/-/, '−', min),
    max: replace(/-/, '−', max),
    ...rest,
  })),

  withReset,
);
