// @flow
import moment from 'moment-timezone';

import { dateFormat as defaultDateFormat } from './date-format';

/**
 *  Расчет периода с ограничением или без,
 *  от начала периода (день, месяц или год),
 *  назад от 'сегодня'
 */

const calcPeriodFromStart = (
  dateStart: ?string,
  dateFinish: ?string,
  period?: 'month' | 'day' | 'year' = 'month',
  format?: string = defaultDateFormat,
): Array<string> => {
  const mDateStart = moment(dateStart);
  const mDateFinish = moment(dateFinish);

  const dateStartValid = !!dateStart && mDateStart.isValid();
  const dateFinishValid = !!dateFinish && mDateFinish.isValid();

  const mToday = moment();

  const [mSelectedDateStart, mSelectedDateFinish] = (() => {
    // обе даты ограничения
    if (dateStartValid && dateFinishValid) {
      // mToday входит в период
      if (mToday.isBetween(mDateStart, mDateFinish, 'day', [])) {
        const mTodayStartPeriod = mToday.clone().startOf(period);
        if (mTodayStartPeriod.isBetween(mDateStart, mDateFinish, 'day', [])) {
          return [mTodayStartPeriod, mToday];
        }
        return [mDateStart, mToday];
      }
      // вылет вперед
      if (mToday.isAfter(mDateFinish)) {
        return [mDateFinish.clone().startOf(period), mDateFinish];
      }
      // вылет назад
      return [mDateStart, mDateStart.clone().endOf(period)];
    }
    // только стартовая дата ограничения
    if (dateStartValid) {
      // вылет вперед
      if (mToday.isBefore(mDateStart, 'day')) {
        return [mDateStart, mDateStart.clone().endOf(period)];
      }
      // входит в ограничение
      const mTodayStartPeriod = mToday.clone().startOf(period);
      if (mTodayStartPeriod.isBefore(mDateStart, 'day')) {
        return [mDateStart, mDateStart.clone().endOf(period)];
      }
      return [mTodayStartPeriod, mToday];
    }
    // только конечная дата ограничения
    if (dateFinishValid) {
      // вылет назад
      if (mToday.isAfter(mDateStart, 'day')) {
        return [mDateFinish.clone().startOf(period), mDateFinish];
      }
      // входит в ограничение
      const mTodayStartPeriod = mToday.clone().startOf(period);
      return [mTodayStartPeriod, mToday];
    }
    // без ограничений
    const mTodayStartPeriod = mToday.clone().startOf(period);

    return [mTodayStartPeriod, mToday];
  })();

  return [
    mSelectedDateStart.format(format),
    mSelectedDateFinish.format(format),
  ];
};

export default calcPeriodFromStart;
