import api from 'app/actions/api';
import { popup } from 'app/common/actions/popup';
/**
* Выделение элементов
*/

export const DISHES_ADD_TO_SELECTION = 'DISHES_ADD_TO_SELECTION';
export const DISHES_REMOVE_FROM_SELECTION = 'DISHES_REMOVE_FROM_SELECTION';
export const DISHES_REPLACE_SELECTION_ARR = 'DISHES_REPLACE_SELECTION_ARR';
export const DISHES_REMOVE_FROM_SELECTION_ALL = 'DISHES_REMOVE_FROM_SELECTION_ALL';

export const dishesAddToSelection = id => ({
  type: DISHES_ADD_TO_SELECTION,
  payload: id,
});

export const dishesRemoveFromSelection = id => ({
  type: DISHES_REMOVE_FROM_SELECTION,
  payload: id,
});

/**
* Удаление элементов
*/

export const DISHES_REMOVE_ITEMS_REQUEST = 'DISHES_REMOVE_ITEMS_REQUEST';
export const DISHES_REMOVE_ITEMS_SUCCESS = 'DISHES_REMOVE_ITEMS_SUCCESS';
export const DISHES_REMOVE_ITEMS_FAILURE = 'DISHES_REMOVE_ITEMS_FAILURE';

const dishesRemoveItemsRequest = () => ({
  type: DISHES_REMOVE_ITEMS_REQUEST,
});

const dishesRemoveItemsSuccess = items => ({
  type: DISHES_REMOVE_ITEMS_SUCCESS,
  payload: items,
});

const dishesRemoveItemsFailure = payload => ({
  type: DISHES_REMOVE_ITEMS_FAILURE,
  payload,
});

export const dishesRemoveItems = someItems => async (dispatch, getState) => {
  if (someItems) {
    if (someItems instanceof Array) {
      someItems.forEach(item => dispatch(dishesAddToSelection(item)));
    } else {
      dispatch(dishesAddToSelection(someItems));
    }
  }
  const items = someItems || getState().dishes.selection.items;
  dispatch(dishesRemoveItemsRequest());
  try {
    const data = { item_id: items };
    await api('food/items', 'delete', { data });
    dispatch(dishesRemoveItemsSuccess(items));
    dispatch(popup(items.length > 1 ? `Блюда (${items.length}) успешно удалены` : 'Блюдо успешно удалено'));
    return true;
  } catch (error) {
    dispatch(dishesRemoveItemsFailure({ err: 'Удаление элементов не удалось', error }));
    return false;
  }
};

/**
* Копирование элементов
*/

export const DISHES_COPY_ITEMS_REQUEST = 'DISHES_COPY_ITEMS_REQUEST';
export const DISHES_COPY_ITEMS_SUCCESS = 'DISHES_COPY_ITEMS_SUCCESS';
export const DISHES_COPY_ITEMS_FAILURE = 'DISHES_COPY_ITEMS_FAILURE';

const dishesCopyItemsRequest = () => ({
  type: DISHES_COPY_ITEMS_REQUEST,
});

const dishesCopyItemsSuccess = payload => ({
  type: DISHES_COPY_ITEMS_SUCCESS,
  payload,
});

const dishesCopyItemsFailure = payload => ({
  type: DISHES_COPY_ITEMS_FAILURE,
  payload,
});

export const dishesCopyItems = () => async (dispatch, getState) => {
  const { dishes } = getState();
  dispatch(dishesCopyItemsRequest());
  try {
    const { items } = dishes.selection;
    const { data } = await api('food/items/copy', 'post', { data: { items } });
    dispatch(dishesCopyItemsSuccess(data));
    dispatch(popup(items.length > 1 ? `Блюда (${items.length}) успешно скопированы` : 'Блюдо успешно скопировано'));
    return true;
  } catch (error) {
    dispatch(dishesCopyItemsFailure({ err: 'Копирование блюд не удалось', error }));
    return false;
  }
};
