// @flow
import React, { type Node } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

/**
 *  Группа Badges в три строки по умолчанию, со скролингом, если превышает
 */

const rowHeight = 36;
const renderNoScroll = () => <span />;

export type PlainBadgesGroupProps = {
  className?: string,
  maxRows?: number,
  children: Node,
}

const PlainBadgesGroup = ({ className, maxRows = 3, children }: PlainBadgesGroupProps) => {
  return (
    <Scrollbars
      className={className}
      autoHeight
      autoHeightMin={0}
      autoHeightMax={maxRows * rowHeight}
      renderTrackHorizontal={renderNoScroll}
    >
      {children}
    </Scrollbars>
  );
};

export default PlainBadgesGroup;
