import { useMemo } from 'react'
import { reduce } from 'ramda'

import { OrgsByOrgGroupID, OrgsData } from 'app/dataTypes/org'


const transform = (data: OrgsData['items']) => {
  return reduce((acc, val) => {
    const { orgGroupID } = val
    if (orgGroupID) {
      const targetArray = acc.get(orgGroupID) || []
      targetArray.push(val)
      if (targetArray.length === 1) {
        acc.set(orgGroupID, targetArray)
      }
    }
    return acc
  }, new Map() as OrgsByOrgGroupID, Array.from(data.values()))
}

export function useOrgsByOrgGroupID(data: OrgsData['items']) {
  const res = useMemo(() => {
    return transform(data)
  }, [data])

  return res
}
