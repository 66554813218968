// @flow
import { useState, useCallback } from 'react';

/**
 *  Состояние для хранения выбранной категории, точки продаж или корпуса.
 *  Содержит
 *    + id,
 *    + флаг единственности (выбран из нескольких - false, был доступен он один - true),
 *    + handle для обновления состояния
 */

export type MenuTargetItemState = [
  number | null,
  boolean,
  ({ id: number | null, single: boolean }) => void
]

export default function useMenuTargetItemState(): MenuTargetItemState {
  const [state, setState] = useState<number | null>(null);
  const [singleAvailable, setSingleAvailable] = useState<boolean>(true);

  const handleUpdateState = useCallback(({ id, single }) => {
    setState(id);
    setSingleAvailable(single);
  }, []);

  return [state, singleAvailable, handleUpdateState];
}
