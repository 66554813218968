// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as org } from 'app/components/common/reports/filters/org';

import { flags as group } from './group';


const productionActivity = (state: State) => {
  return (
    org.singleOrgSelected(state)
    && group.filterActive(state)
    && group.byAnyComplexesNotSelected(state)
  );
};

export default productionActivity;
