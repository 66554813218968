import { useCallback, ComponentType, forwardRef } from 'react'
import cn from 'classnames'

import nope from 'app/common/lib/nope'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import PlainInput, { type PlainInputProps } from '../plain-input'
import styles from './plain-input-search.scss'


export type Ref = HTMLInputElement

export type PlainInputSearchProps = {
  clearSearch?: () => void,
  clearSearchA11yTitle?: string,
  ContainerComponent?: ComponentType<any> | string,
  className?: string,
} & Omit<PlainInputProps, 'type'>

const PlainInputSearch = forwardRef<Ref, PlainInputSearchProps>(({
  clearSearch,
  clearSearchA11yTitle = 'Очистить поиск',
  ContainerComponent = 'form',
  className,
  ...others
}, ref) => {
  const clearSearchRef = useCallbackRef(clearSearch)
  const handleClearSearch = useCallback(() => {
    clearSearchRef.current?.()
  }, [clearSearchRef])

  const props = (
    ContainerComponent === 'form'
      ? { role: 'search', onSubmit: nope }
      : {}
  )

  return (
    <ContainerComponent className={cn(styles.root, className)} {...props}>
      <PlainInput
        {...others}
        className={styles.input}
        inputClassName={styles.inputContent}
        type="text"
        ref={ref}
      />
      {others.value // TODO одностороннее связывание
        && clearSearch
        && <button
          type="button"
          className={styles.clearSearch}
          onClick={handleClearSearch}
        >
          <span className={styles.clearSearchA11yTitle}>
            {clearSearchA11yTitle}
          </span>
          {'×'}
        </button>}
    </ContainerComponent>
  )
})

export default PlainInputSearch
