// @flow
import React, { Fragment, useState, useCallback } from 'react';
import not from 'ramda/es/not';

import ImagePrepare from 'app/common/components/ImagePrepare';
import ImageClickable from 'app/common/ui/ImageClickable';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';
import { type PhotoNewValue } from '../DishesData';

import styles from './DishesTablePhoto.scss';

/**
 *  Ячейка таблицы с фотографией
 */

type Props = {
  title: string,
  photoUrl?: string,
  locked?: boolean,
  onDelete: () => boolean | Promise<boolean>,
  onPhoto: (photo: PhotoNewValue) => boolean | Promise<boolean>,
}

const DishesTablePhoto = ({ title, photoUrl, locked, onDelete, onPhoto }: Props) => {
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const toggleDeleteConfirmation = useCallback((e?: SyntheticEvent<HTMLElement>) => {
    if (e) {
      e.preventDefault();
    }
    setDeleteConfirmationVisible(not);
  }, []);

  const handleDeleteImage = useCallback(async () => {
    setDeleting(true);
    const res = await onDelete();
    setDeleting(false);

    if (res) {
      toggleDeleteConfirmation();
    }
  }, [onDelete, toggleDeleteConfirmation]);


  return (
    <Fragment>
      <ImagePrepare
        onSubmit={onPhoto}
        rectMinSizeWidth={100}
        rectMinSizeHeight={133}
        rectProportion={0.75}
      >
        {handleFileOpen => (
          !photoUrl
            ? <PlainButtonAsLink
              onClick={handleFileOpen}
              className={styles.buttonAdd}
              linkClassName={styles.buttonAdd__link}
            >
              {locked ? '' : '+\u00A0Добавить фото'}
            </PlainButtonAsLink>
            : <ImageClickable
              src={photoUrl}
              locked={locked}
              className={styles.photo}
              onReplace={handleFileOpen}
              onDelete={toggleDeleteConfirmation}
            />
        )}
      </ImagePrepare>

      {deleteConfirmationVisible
        && <PlainConfirmModal
          messageText={`Вы уверены? Удалить изображение из блюда "${title}"?`}
          cancelButtonTitle="Отмена"
          confirmButtonTitle={deleting ? 'Удаление…' : 'Удалить'}
          confirmButtonProps={{ disabled: deleting }}
          onCancel={toggleDeleteConfirmation}
          onConfirm={handleDeleteImage}
          critical
        />}
    </Fragment>
  );
};

export default DishesTablePhoto;
