// @flow
import { useCallback } from 'react';
import { over, lensProp } from 'ramda';

import { api } from 'app/api';
import usePopup from 'app/common/hooks/usePopup';
import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Dish as CommonDish } from 'app/dataTypes/Dishes.types';
import { productionSaveType } from 'app/dataTypes/vending/production/productionSaveType';

import {
  PUT_FOOD_ITEM_FIELD,
  type FoodItemFieldRequestResult,
  type PhotoNewValue,
} from 'app/common/api/requests/food/item/field';

import {
  PUT_VENDING_ITEMS_FIELD,
  convertParams,
  convertResult,
} from 'app/common/api/requests/data/vending/items/field/put';


const errorMessageByFieldType = (field: string, value: string | PhotoNewValue | null): string => {
  if (field === 'photo') {
    if (!value) {
      return 'Не удалось удалить фотографию';
    }
    return 'Не удалось изменить фотографию';
  }
  if (field === 'saleType') {
    return 'Не удалось изменить тип продукции';
  }
  return 'Не удалось изменить значение поля';
};

const successMessageByFieldType = (field: string, value: string | PhotoNewValue | null): string => {
  if (field === 'photo') {
    if (!value) {
      return 'Фотография успешно удалена';
    }
    return 'Фотография успешно изменена';
  }
  if (field === 'saleType') {
    return 'Тип продукции успешно изменен';
  }
  return 'Значение поля успешно изменено';
};

type Options = {
  id: string,
  foodItemId: string,
  updateDish: (d: Dish) => void,
  updateDishAsCommon: (d: CommonDish) => void,
}

export default function useFieldSubmit({
  id,
  foodItemId,
  updateDish,
  updateDishAsCommon,
}: Options): [
  (field: "photo" | "saleType", value: string | PhotoNewValue | null) => Promise<boolean>,
  (field: string, value: string) => Promise<boolean>
] {
  const popup = usePopup();

  const fieldSubmit = useCallback(async (field: 'photo' | 'saleType', newValue: string | PhotoNewValue | null) => {
    const res: null | FoodItemFieldRequestResult = await api.request(PUT_FOOD_ITEM_FIELD, {
      error: errorMessageByFieldType(field, newValue),
      params: {
        id: foodItemId,
        field,
        new_value: newValue,
      },
    });

    if (res && res[foodItemId]) {
      const dish = res[foodItemId];
      updateDishAsCommon(over(lensProp('id'), String, dish));
      popup(successMessageByFieldType(field, newValue));
      return true;
    }
    return false;
  }, [popup, foodItemId, updateDishAsCommon]);


  const fieldSubmitPlaceBased = useCallback(async (field: string, newValue: string) => {
    const res = await api.request(PUT_VENDING_ITEMS_FIELD, {
      error: errorMessageByFieldType(field, newValue),
      params: {
        field,
        id,
        newValue,
        productionSaveTypes: [productionSaveType.Place],
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      updateDish(res);
      popup(successMessageByFieldType(field, newValue));
      return true;
    }
    return false;
  }, [popup, id, updateDish]);


  return [fieldSubmit, fieldSubmitPlaceBased];
}
