import { useCallback, useState, useMemo, ChangeEvent } from 'react'


const emptyFunc = () => { }

export type Paging = {
  currentPage: number,
  pageSize: number,
  offset: number,
  total: number,
  onPage: (e: ChangeEvent<HTMLAnchorElement> | number) => void,
  resetPagination: () => void,
}

export const defaultPaging: Paging = {
  currentPage: 1,
  pageSize: 0,
  offset: 0,
  total: 0,
  onPage: emptyFunc,
  resetPagination: emptyFunc,
}

export default function usePagingExtended(pageSize: number, total: number = 0): Paging {
  const [currentPage, setCurrentPage] = useState(1)
  const onPage = useCallback((e: ChangeEvent<HTMLAnchorElement> | number) => {
    if (typeof e === 'number') {
      setCurrentPage(e + 1) // as index
      return
    }
    e.preventDefault()
    const { name } = e.currentTarget
    const page = parseInt(name, 10)
    setCurrentPage(page)
  }, [])

  const offset = (currentPage - 1) * pageSize

  const resetPagination = useCallback(() => {
    setCurrentPage(1)
  }, [setCurrentPage])

  const res = useMemo(() => ({
    currentPage,
    pageSize,
    offset,
    total,
    onPage,
    resetPagination,
  }), [
    currentPage,
    pageSize,
    offset,
    total,
    onPage,
    resetPagination,
  ])

  return res
}
