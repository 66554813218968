// @flow
import { useState, useCallback, useMemo } from 'react';

import { api } from 'app/api';

import {
  GET_VENDING_MACHINES,
  type Machine,
  type Result as VendingMachinesState,
  type Params,
  defaultVendingMachines as defaultState,
} from 'app/common/api/requests/data/vending/machines/get';


export type { Machine, VendingMachinesState };

export type VendingMachines = [VendingMachinesState, boolean, () => void | Promise<void>]
export const defaultVendingMachines: VendingMachines = [defaultState, true, () => {}];

const emptyParams: Params = Object.freeze({});

export default function useVendingMachines(params?: Params = emptyParams): VendingMachines {
  const [loading, setLoading] = useState(true);
  const [vendingMachines, setVendingMachines] = useState<VendingMachinesState>(defaultState);

  const handleRequest = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_VENDING_MACHINES, {
      error: 'Не удалось получить вендинговые аппараты',
      params,
    });

    if (res && res.data && res.meta && res.data instanceof Array && typeof res.meta === 'object') {
      setVendingMachines(res);
    }
    setLoading(false);
  }, [params]);

  const memoized = useMemo(() => [
    vendingMachines,
    loading,
    handleRequest,
  ], [
    vendingMachines,
    loading,
    handleRequest,
  ]);

  return memoized;
}
