import { useState, useCallback } from 'react'
import { pick, assoc, isEmpty, last, sort, remove, over, lensProp, update } from 'ramda'

import { defaultGeneralSettings } from 'app/dataProviders/org/generalSettingsOrg'
import { GeneralSettings, SettingsKey } from 'app/dataTypes/org/settings/GeneralSettings'
import { api } from 'app/api'
import { POST_ORG_GENERAL_SETTINGS_MASS } from 'app/common/api/requests/food/schools/general_settings'


const optionKeys = [
  SettingsKey.camp_subsidy_orders_use_presets,
  SettingsKey.camp_subsidy_orders_preset_values,
] as const

export type CampSubsidyOrdersState = Pick<GeneralSettings, typeof optionKeys[number]>

const defaultSettings: CampSubsidyOrdersState = pick<GeneralSettings, string>(
  optionKeys,
  defaultGeneralSettings,
)

const presetValuesTarget = lensProp<CampSubsidyOrdersState, SettingsKey.camp_subsidy_orders_preset_values>(
  SettingsKey.camp_subsidy_orders_preset_values,
)

export type ChangePresetAction = {
  type: 'add'
} | {
  type: 'remove'
  index: number
} | {
  type: 'edit'
  index: number
  value: number
}

export default function useCampSubsidyOrders(
  orgId: number,
  requestGeneralSettings: () => Promise<GeneralSettings | null>,
) {
  const [state, setState] = useState<CampSubsidyOrdersState>(defaultSettings)
  const [modified, setModified] = useState(false)
  const [modifyRef, setModifyRef] = useState([])

  const request = useCallback(async () => {
    const res = await requestGeneralSettings()
    if (res) {
      const value: CampSubsidyOrdersState = pick<GeneralSettings, string>(optionKeys, res)
      setState(value)
      setModified(false)
      setModifyRef([])
    }
  }, [requestGeneralSettings])

  const changeUsingOfPresets = useCallback((presetsUsing: boolean) => {
    setState(assoc(SettingsKey.camp_subsidy_orders_use_presets, presetsUsing))
    setModified(true)
    setModifyRef([])
  }, [])

  const changePresets = useCallback((action: ChangePresetAction) => {
    if (action.type === 'add') {
      setState((prevState) => {
        const { camp_subsidy_orders_preset_values: values } = prevState
        if (isEmpty(values)) {
          return assoc(SettingsKey.camp_subsidy_orders_preset_values, [20], prevState)
        }
        const lastValue = last(sort((a, b) => (a - b), values))
        if (lastValue && lastValue < 100) {
          return assoc(
            SettingsKey.camp_subsidy_orders_preset_values,
            [...values, lastValue < 80 ? lastValue + 20 : lastValue + 1],
            prevState,
          )
        }
        return assoc(
          SettingsKey.camp_subsidy_orders_preset_values,
          [...values, 50],
          prevState,
        )
      })
    }
    if (action.type === 'remove') {
      const { index } = action
      setState(over(presetValuesTarget, remove(index, 1)))
    }
    if (action.type === 'edit') {
      const { index, value } = action
      setState(over(presetValuesTarget, update(index, value)))
    }
    setModified(true)
    setModifyRef([])
  }, [])

  const submit = useCallback(async (overrideOrgIds?: Array<number>) => {
    const orgIds = overrideOrgIds || [orgId]
    const res = await api.request(POST_ORG_GENERAL_SETTINGS_MASS, {
      error: 'Не удалось сохранить настройки для питания в пришкольном лагере',
      params: {
        schools_ids: orgIds.map(String),
        settings: (
          state.camp_subsidy_orders_use_presets
            ? state
            : pick(['camp_subsidy_orders_use_presets'], state)
        ),
      },
    })

    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true
    }

    if (res) {
      setModified(false)
      setModifyRef([])
    }
    return !!res
  }, [orgId, state])

  return {
    state,
    modified,
    modifyRef,
    request,
    submit,
    changeUsingOfPresets,
    changePresets,
  }
}
