import { Params, MutationParams, Allowances } from 'app/dataTypes/subsidy/allowances'

import type { Request } from '../../../index'


type ParamsAPI = {
  order_type?: 'cafeteria' | 'camp'
  add?: string
  remove?: string
  allowances?: Array<string>
}

type PathParams = {
  orgID: string
}

export const convertParams = ({
  orgID,
  app,
  allowanceIDs,
  allowanceAddID,
  allowanceRemoveID,
}: MutationParams): [ParamsAPI, PathParams] => {
  if (!allowanceIDs && !allowanceAddID && !allowanceRemoveID) {
    throw new Error('Не все параметры получены для запроса POST food/schools/{orgID}/subsidy_orders_allowances')
  }
  const res = {
    order_type: app,
  } as ParamsAPI

  if (allowanceIDs) {
    res.allowances = allowanceIDs
  } else if (allowanceAddID) {
    res.add = allowanceAddID
  } else if (allowanceRemoveID) {
    res.remove = allowanceRemoveID
  }
  return [res, { orgID }]
}

type ResultAPI = { allowances: Array<string> }

export const convertResult = ({ allowances }: ResultAPI): Allowances => {
  return allowances.map(key => ({ ID: key, title: key, deletable: true }))
}

export const POST_SCHOOL_ALLOWANCES: Request<Params, Allowances, PathParams> = [
  'food/schools/{orgID}/subsidy_orders_allowances',
  'post',
  ['data'],
]
