// @flow
import React, { type Node, useState, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';

import PlainButton from 'app/common/ui-next/plain-button';
import IconLightCross from 'app/common/icons/IconLightCross';

import styles from './FiltersDropDownLayout.scss';


type Props = {
  className?: string,
  contentClassName?: string,
  onApplyFilter: (e: SyntheticEvent<HTMLElement>) => void,
  onClose?: (e: SyntheticEvent<HTMLElement>) => void,
  onResetAll: () => void,
  loading?: boolean,
  arrowClassName?: string,
  arrowLeftShift?: number,
  arrowHide?: boolean,
  children: Node | (resetStatus: boolean) => Node,
}

const FiltersDropDownLayout = ({
  className,
  contentClassName,
  onApplyFilter,
  onClose,
  onResetAll,
  loading,
  arrowClassName,
  arrowLeftShift,
  arrowHide,
  children,
}: Props) => {
  const [resetStatus, setResetStatus] = useState(false);

  const handleResetAll = useCallback((e) => {
    e.preventDefault();
    setResetStatus(true);
    if (typeof onResetAll === 'function') {
      onResetAll();
    }
  }, [onResetAll]);

  const handleSubmitFilter = useCallback((e) => {
    e.preventDefault();
    if (e.target.id !== 'filter') return;
    onApplyFilter(e);
  }, [onApplyFilter]);

  useEffect(() => {
    if (resetStatus) {
      setResetStatus(false);
    }
  }, [resetStatus]);

  const refRoot = useRef(null);

  useEffect(() => {
    if (arrowLeftShift && refRoot.current) {
      const rootDiv = refRoot.current;
      rootDiv.style.setProperty('--arrow-left-shift', `${arrowLeftShift}px`);
    }
  }, [arrowLeftShift]);

  return (
    <div className={cn(styles.root, className)} ref={refRoot}>
      {!arrowHide
        && <div className={styles.arrowContainer}>
          <span className={cn(styles.arrowUp, arrowClassName)} />
        </div>}
      <form onSubmit={handleSubmitFilter} id="filter">
        <div className={styles.filterContainer}>

          <div className={styles.filterContainerMain}>
            <button onClick={onClose} className={styles.close} type="button">
              <IconLightCross />
            </button>
            <div className={cn(styles.content, contentClassName)}>
              {typeof children === 'function'
                ? children(resetStatus)
                : children}
            </div>
          </div>

          <div className={styles.filterContainerButtons}>
            <PlainButton
              onClick={handleResetAll}
              className={styles.outlineBt}
              outline
            >
              {'Сбросить все фильтры'}
            </PlainButton>
            <PlainButton disabled={loading} type="submit">
              {'Применить'}
            </PlainButton>
          </div>

        </div>
      </form>
    </div>
  );
};

export default FiltersDropDownLayout;
