import { ChangeEvent, useCallback } from 'react'
import cn from 'classnames'

import LineInput from 'app/common/ui/LineInput'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import styles from './EstimatedAllocationControls.scss'


type Props = {
  groupID: string
  partName: string
  partSuffix?: string
  label: string
  placeholder: string
  focusedLabel: string
  disabled?: boolean
  value1: string | null
  value2: string | null
  onChange: (value: string, fieldName: string) => void
  error1?: string
  error2?: string
}

const EstimatedAllocationControls = ({
  groupID,
  partName,
  partSuffix = 'percent',
  label,
  placeholder,
  focusedLabel,
  disabled,
  value1,
  value2,
  onChange,
  error1,
  error2,
}: Props) => {
  const fieldName1 = `${partName}1_${partSuffix}_${groupID}`
  const fieldName2 = `${partName}2_${partSuffix}_${groupID}`

  const onChangeRef = useCallbackRef(onChange)

  const handleChangePercent = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget
    const count = parseInt(name.replace(/^\D+/, ''), 10)
    const valueWithDot = value.replace(',', '.')
    onChangeRef.current?.(valueWithDot, `${partName}${count}_${partSuffix}`)
  }, [onChangeRef, partName, partSuffix])

  return (
    <div className={cn(styles.root, styles.column5)}>
      <div className={styles.label}>{label}</div>
      <div className={getScrollToErrorClassName(!!(error1 || error2))} />
      <div className={styles.row}>
        <LineInput
          placeholder={placeholder}
          focused={focusedLabel}
          value={value1}
          name={fieldName1}
          onChange={handleChangePercent}
          autoComplete="off"
          isError={!!error1}
          errorText={error1}
          disabled={disabled}
        />
        <span className={styles.unit}>{'%'}</span>
      </div>

      <div className={styles.row}>
        <LineInput
          placeholder={placeholder}
          focused={focusedLabel}
          value={value2}
          name={fieldName2}
          onChange={handleChangePercent}
          autoComplete="off"
          isError={!!error2}
          errorText={error2}
          disabled={disabled}
        />
        <span className={styles.unit}>{'%'}</span>
      </div>
    </div>
  )
}

export default EstimatedAllocationControls
