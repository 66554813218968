// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';
import { orgsOrUser } from 'app/dataTypes/reports/orgsOrUser';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const byOrgsSelected = (state: State): boolean => {
  const [type] = getStateCurrentValue(filterId, state) || [];
  return type === orgsOrUser.ByOrgs;
};

export const byUserSelected = (state: State): boolean => {
  const [type] = getStateCurrentValue(filterId, state) || [];
  return type === orgsOrUser.ByUser;
};
