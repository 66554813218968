// @flow
export const STUDENT_SUBSIDY_TYPES_FILTER_CLEAR: 'reports/STUDENT_SUBSIDY_TYPES_FILTER_CLEAR' = 'reports/STUDENT_SUBSIDY_TYPES_FILTER_CLEAR';
export const STUDENT_SUBSIDY_TYPES_FILTER_DELETE: 'reports/STUDENT_SUBSIDY_TYPES_FILTER_DELETE' = 'reports/STUDENT_SUBSIDY_TYPES_FILTER_DELETE';
export const STUDENT_SUBSIDY_TYPES_FILTER_SET: 'reports/STUDENT_SUBSIDY_TYPES_FILTER_SET' = 'reports/STUDENT_SUBSIDY_TYPES_FILTER_SET';
export const STUDENT_SUBSIDY_TYPES_FILTER_UPDATE: 'reports/STUDENT_SUBSIDY_TYPES_FILTER_UPDATE' = 'reports/STUDENT_SUBSIDY_TYPES_FILTER_UPDATE';

const WITH_SUBSIDIES: 'with_subsidies' = 'with_subsidies';
const WITHOUT_SUBSIDIES: 'without_subsidies' = 'without_subsidies';

export const studentSubsidyTypes = {
  WITH_SUBSIDIES,
  WITHOUT_SUBSIDIES,
};
