import { Transaction } from 'app/dataTypes/compensation/transations'

import { Column } from '../columns'
import { Sum } from './Sum'
import { OperationType } from './OperationType'


type Props = {
  columnKey: Column
  transaction: Transaction
}

export const Cell = ({
  columnKey,
  transaction,
}: Props) => {
  const { date, value, valueStatus, operationType, comment } = transaction

  if (columnKey === Column.Sum) {
    return <Sum value={value} status={valueStatus} />
  }

  if (columnKey === Column.Date) {
    return date.getString('DD.MM.YYYY HH:mm')
  }

  if (columnKey === Column.Type) {
    return <OperationType operationType={operationType} />
  }

  if (columnKey === Column.Comment) {
    return comment
  }

  return (
    <div>{'—'}</div>
  )
}
