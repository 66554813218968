// @flow
import React, { type Node } from 'react';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';

import styles from './ButtonRedirect.scss';


type Props = {
  children: string,
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
}

const ButtonRedirect = ({ children, onClick }: Props): Node => {
  return (
    <PlainButtonAsLink onClick={onClick} className={styles.root}>
      {children}
    </PlainButtonAsLink>
  );
};

export default ButtonRedirect;
