// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as orgsOrUser } from '../orgsOrUser';


const customerSearchActivity = (state: State): boolean => {
  return (
    orgsOrUser.byUserSelected(state)
  );
};

export default customerSearchActivity;
