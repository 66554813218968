import lensPath from 'ramda/es/lensPath';
import set from 'ramda/es/set';

import { CHANGE_FIO_SUCCESS } from 'app/common/actions/profile/fio';
import { CHANGE_EMAIL_SUCCESS } from 'app/common/actions/profile/email';
import { CHANGE_PHONE_SUCCESS } from 'app/common/actions/profile/phone';
import { CHANGE_PASSWORD_SUCCESS } from 'app/common/actions/profile/password';
import { CHANGE_LOGIN_SUCCESS } from 'app/common/actions/profile/login';
import { SAVE_TOKEN_SUCCESS, SAVE_TOKEN_REQUEST, SAVE_TOKEN_FAILURE } from 'app/common/actions/user/tokens';
import { BALANCE_SUCCESS } from 'app/common/actions/user/balance';
import * as t from 'app/common/actions/user';


export const name = {
  first: '',
  middle: '',
  last: '',
};

const defaultState = {
  err: null,
  loading: true,
  result: 'none',
  id: null,
  parent: null,
  student: null,
  employee: null,
  update: {
    password: false,
    phone: false,
  },
  name,
  avatar: '',
  committees: [],
  email: null,
  phone: null,
  expired: 0,
  savingToken: false,
  balance: {
    primary: null,
    cafeteria: null,
  },
  role_id: null,
  type: '',
  shop: {
    enabled: true,
    user_id: null,
    user_token: null,
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case t.USER_LOGOUT: {
      return defaultState;
    }

    case t.USER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        err: null,
        loading: false,
      };
    }

    case t.USER_REQUEST: {
      return {
        ...state,
        loading: true,
        err: null,
      };
    }

    case t.USER_FAIL: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case CHANGE_FIO_SUCCESS:
    case CHANGE_EMAIL_SUCCESS:
    case CHANGE_PHONE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SAVE_TOKEN_REQUEST: {
      return {
        ...state,
        savingToken: true,
      };
    }

    case SAVE_TOKEN_SUCCESS: {
      return {
        ...state,
        tokens: {
          ...state.tokens,
          ...action.payload,
        },
        savingToken: false,
      };
    }

    case SAVE_TOKEN_FAILURE: {
      return {
        ...state,
        savingToken: false,
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      const lens = lensPath(['update', 'password']);
      return set(lens, false, state);
    }

    case CHANGE_LOGIN_SUCCESS: {
      const newLogin = action.payload.login;
      const { isEmail } = action.payload;
      return {
        ...state,
        ...(isEmail ? { email: newLogin } : {}),
        ...(!isEmail ? { phone: newLogin } : {}),
        update: {
          ...state.update,
          phone: false,
        },
      };
    }

    case BALANCE_SUCCESS: {
      return {
        ...state,
        balance: action.payload,
      };
    }

    default:
      return state;
  }
}
