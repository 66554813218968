// @flow
import React from 'react';

import PlainLinkAsButton from 'app/common/ui-next/plain-link-as-button';
import { WithRights } from 'app/rights/Rights';
import * as rootRights from 'app/common/constants/rights/rootRights';
import * as specificRights from 'app/common/constants/rights/specificRights';


const addButtonRightsPath = [rootRights.DISHES, specificRights.PROVIDER_DISHES];

type Props = {
  baseUrl: string,
}

const AddButton = ({ baseUrl }: Props) => {
  return (
    <WithRights pathTo={addButtonRightsPath} readOnlyHidden>
      <PlainLinkAsButton to={`${baseUrl}/add`}>
        {'Добавить продукцию'}
      </PlainLinkAsButton>
    </WithRights>
  );
};

export default AddButton;
