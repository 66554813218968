// @flow
import React from 'react';

import { RowLayoutWithSticky, RowLayoutContent, Header } from 'app/common/layouts/RowLayout';
import ProfileCommon from 'app/common/components/Profile';


type Props = {
  header: string,
  baseUrl: string,
}

const AuthorizationDataSection = ({ header, baseUrl }: Props) => {
  return (
    <RowLayoutWithSticky
      number={3}
      content={
        <RowLayoutContent borderBottom>
          <Header>
            {header}
          </Header>
        </RowLayoutContent>
      }
    >
      <RowLayoutContent>
        <ProfileCommon
          baseUrl={baseUrl}
          showFio={false}
        />
      </RowLayoutContent>
    </RowLayoutWithSticky>
  );
};

export default AuthorizationDataSection;
