import { useState, useCallback } from 'react'
import { assoc, dissoc, ifElse, has, reduce } from 'ramda'

import { emptyObject } from 'app/common/lib/empty'


type State = {
  [key: string]: string,
}

type Modify = {
  toggle: (key: string) => void,
  update: (keys: Array<string>) => void,
}

const defaultState: State = emptyObject

type Options = {
  multiple: boolean,
}

export default function useItemsModalState({ multiple }: Options): [
  State,
  Modify
] {
  const [state, setState] = useState<State>(defaultState)

  const itemToggle = useCallback((key: string) => {
    if (multiple) {
      setState(ifElse(has(key), dissoc(key), assoc(key, key)))
      return
    }
    setState(assoc(key, key, defaultState))
  }, [multiple])

  const itemsUpdate = useCallback((keys: Array<string>) => {
    setState(reduce((acc, val) => {
      acc[val] = val
      return acc
    }, {}, keys))
  }, [])

  return [state, {
    toggle: itemToggle,
    update: itemsUpdate,
  }]
}
