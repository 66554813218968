import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { defaultOrgsSelection, orgsFilter, orgsFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

export const OrgsFilter = ({ id, pending }: Props) => {
  return (
    <PlainItemsSelectorBox
      id={id}
      label="Заведение"
      header="Выбор заведения"
      dataBoxData={orgsFilter}
      dataBoxSelection={orgsFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      disabled={pending}
      multiple
      defaultSelection={defaultOrgsSelection}
    />
  )
}
