// @flow
import type { Request } from '../../index';


export type OldOrdersRestrictionsItem = {
  id: number,
  role_type: 'supplier' | 'school', // supplier | school, cотрудники ПП/ОУ
  role_title: string,
  role_id: number,
  current_period_without_restrictions: boolean, // без ограничений по текущему периоду
  current_period_days: number | null, // ограничения по текущему периоду
  past_period_without_restrictions: boolean, // без ограничений по прошлому периоду
  past_period_days: number | null // ограничения по прошлому периоду
}

export type OldOrdersRestrictionsResult = {
  [id: string]: OldOrdersRestrictionsItem,
}

type OldOrdersRestrictionsUrlParams = {
  schoolId: number | string,
}

export const GET_OLD_ORDERS_RESTRICTIONS: Request<
  void,
  OldOrdersRestrictionsResult,
  OldOrdersRestrictionsUrlParams
> = [
  'food/schools/{schoolId}/old_orders_restrictions',
  'get',
  ['data', 'restrictions'],
];

export type OldOrdersRestrictionsParamsItem = {
  role_id: number,
  role_title: string,
  current_period_without_restrictions?: boolean,
  current_period_days?: ?number,
  past_period_without_restrictions?: boolean,
  past_period_days?: ?number,
}

export type PostOldOrdersRestrictionsParams = {
  schools_ids: Array<string> | Array<number>,
  [id: string]: OldOrdersRestrictionsParamsItem
}

export const POST_OLD_ORDERS_RESTRICTIONS: Request<
  PostOldOrdersRestrictionsParams,
  'success',
  void
> = [
  'food/schools/old_orders_restrictions',
  'post',
  ['data', 'result'],
];
