// @flow
import makeSettingsReducer from 'app/common/reducers/settings/makeSettingsReducer';

import config from 'app/config';


export const settingsKey: 'orgsOrders' = 'orgsOrders';

export type Settings = {
  rows: number,
  id: boolean,
  date: boolean,
  who: boolean,
  group: boolean,
  sch: boolean,
  num: boolean,
  total: boolean,
  opt: boolean,
}

const orgsOrdersDefaultState: Settings = {
  rows: config.perPageItems,
  id: true,
  date: true,
  who: true,
  group: true,
  sch: true,
  num: true,
  total: true,
  opt: true,
};

export default makeSettingsReducer(settingsKey, orgsOrdersDefaultState);
