import { ReactNode } from 'react'

import { CONTENT_MARGIN, CONTENT_MARGIN_COLLAPSED } from './constants'
import { useSideNavigationGlobalState } from './use-side-navigation-global-state'

import styles from './side-navigation-target-content.scss'


type Props = {
  children: ReactNode
}

const SideNavigationTargetContent = ({ children }: Props) => {
  const { status } = useSideNavigationGlobalState()

  return (
    <div
      className={styles.contentblock}
      style={{ marginLeft: status === 'wide' ? CONTENT_MARGIN : CONTENT_MARGIN_COLLAPSED }}
    >
      {children}
    </div>
  )
}

export default SideNavigationTargetContent
