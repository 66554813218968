// @flow
import cn from 'classnames/bind';

import styles from './colors.scss';


const cx = cn.bind(styles);

const COLOR_PRIMARY: 'Primary' = 'Primary';
const COLOR_SUCCESS: 'Success' = 'Success';
const COLOR_WARNING: 'Warning' = 'Warning';
const COLOR_ERROR: 'Error' = 'Error';
const COLOR_GRAY: 'Gray' = 'Gray';
const COLOR_LIGHTGRAY: 'LightGray' = 'LightGray';
const COLOR_DARKGRAY: 'DarkGray' = 'DarkGray';
const COLOR_WHITE: 'White' = 'White';
const COLOR_BLACK: 'Black' = 'Black';
const COLOR_TEXT: 'Text' = 'Text';

export const colorTypes = {
  COLOR_PRIMARY,
  COLOR_SUCCESS,
  COLOR_ERROR,
  COLOR_WARNING,
  COLOR_GRAY,
  COLOR_LIGHTGRAY,
  COLOR_DARKGRAY,
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_TEXT,
};

export const classByColoringType = (
  coloringType: 'stroke' | 'fill' | 'none',
  color: $Values<typeof colorTypes>,
  disabled?: boolean,
): string => {
  if (coloringType === 'none') {
    return '';
  }
  const className = `${coloringType}${color}`;
  return cx({ [className]: true, disabled });
};
