// @flow
import React, { type Node, useMemo } from 'react';
import values from 'ramda/es/values';
import pipe from 'ramda/es/pipe';
import filter from 'ramda/es/filter';
import propEq from 'ramda/es/propEq';
import isEmpty from 'ramda/es/isEmpty';

import type { RestrictionByRoleMap, RestrictionByRole, OrderType } from 'app/dataTypes/OrgSettingsSubsidies.types';
import Loading from 'app/common/components/Loader';

import CopySettingsContainer from '../common/CopySettingsContainer';
import CommentContainer from '../common/CommentContainer';
import RoleItem from '../common/RoleItem';
import * as ot from './orderTypes';
import type { Errors } from './extractRBRErrors';


const commentCommon = 'Запретить работать с общими справками. Укажите количество дней, в течение которых сотрудникам можно работать с общими справками после завершения отчетного периода:';
const commentPersonal = 'Запретить работать с индивидуальными справками. Укажите количество дней, в течение которых сотрудникам можно работать с индивидуальными справками после завершения отчетного периода:';

type Props = {
  children: Node,
  orderType: OrderType,
  disabled?: boolean,
  loading?: boolean,
  restrictions: RestrictionByRoleMap,
  errors: Errors,
  onChangeDays: (days: number | null, id: string) => void,
  onChangeRestriction: (checked: boolean, id: string) => void,
}

const RestrictionsByRoleOpt = ({
  children,
  orderType,
  disabled,
  loading: loadingData,
  restrictions,
  errors,
  onChangeDays,
  onChangeRestriction,
}: Props) => {
  const data: Array<RestrictionByRole> = useMemo(() => {
    return pipe(
      values,
      filter(propEq('orderType', orderType)),
    )(restrictions);
  }, [restrictions, orderType]);

  const loading = loadingData && isEmpty(data);

  return (
    <CopySettingsContainer>
      <div>
        <CommentContainer>
          {orderType === ot.orderCommon && commentCommon}
          {orderType === ot.orderPersonal && commentPersonal}
        </CommentContainer>

        {loading && <Loading />}

        {data.map(({ id, roleTitle, pastPeriodDays, pastPeriodWithoutRestrictions }) => (
          <RoleItem
            key={id}
            id={String(id)}
            title={roleTitle}
            days={pastPeriodDays}
            disabled={disabled}
            error={errors ? errors[String(id)] : undefined}
            onChangeDays={onChangeDays}
            onChangeRestriction={onChangeRestriction}
            withoutRestrictions={pastPeriodWithoutRestrictions}
          />
        ))}
      </div>

      {!disabled && children}
    </CopySettingsContainer>
  );
};

export default RestrictionsByRoleOpt;
