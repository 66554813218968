import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { assoc, isEmpty, prop } from 'ramda'

import { Accrual } from 'app/dataTypes/subsidy/settings'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import useComplexes from 'app/dataProviders/subsidy/complexes/hooks/useComplexes'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'
import PlainRadioButton from 'app/common/ui-next/plain-radio-button'

import { useGeneralSettings } from 'app/dataProviders/generalSettings/hooks'
import classNames from 'classnames/bind'
import Complexes from './Complexes'
import { ComplexesDefaults } from './ComplexesDefaults'
import { useLost } from './useLost'
import LostItemsWarning from './LostItemsWarning'
import { controls, selection } from './accrualFormControls'

import styles from './AccrualForm.scss'


export type Errors = Map<string, string>
const cx = classNames.bind(styles)

type Props = {
  ID: string
  orgID: string
  app: 'camp' | 'cafeteria'
  data: Accrual
  formID: string
  disabled?: boolean
  errors: Errors
  resetErrors: () => void
  updateSize?: () => void
}

const AccrualFormCommon = ({
  ID,
  orgID,
  app,
  data,
  formID,
  disabled,
  errors,
  resetErrors,
  updateSize,
}: Props) => {
  const [{ num, active, complexes, additional, defaultComplexID }, setData] = useState(data)
  const { generalSettings } = useGeneralSettings()
  const handleActive = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    setData(assoc('active', checked))
    resetErrors()
  }, [resetErrors])

  const [availableComplexes, loadingComplexes] = useComplexes({ orgID, app, allowanceID: ID })
  const [complexesSelected, setComplexesSelected] = useState(new Set(complexes.map(prop('ID'))))

  const [additionalComplexes, loadingAdditionalComplexes] = useComplexes({
    orgID,
    app: 'additional',
    autoload: !!additional,
    allowanceID: ID,
  })
  const [additionalSelected, setAdditionalSelected] = useState(new Set((additional || []).map(prop('ID'))))

  const errorComplexes = errors.get(`${controls.complexesList}${num}`)

  const [select, setSelect] = useState(data.type)
  const handleSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget
    if (checked && (value === 'complexes' || value === 'chosen_complex')) {
      setSelect(value)
      resetErrors()
    }
  }, [resetErrors])

  const lostItems = useLost({
    loading: loadingComplexes,
    selected: complexes,
    items: availableComplexes,
    onLost: updateSize,
  })

  useEffect(() => {
    setData(data)
    resetErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    updateSize?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, availableComplexes, active, select])

  const columnsRootClass = cx(
    styles.columns,
    { hideRadioButtons: !generalSettings.useCommonOrderComplexChoose },
  )

  return (
    <div className={styles.root}>
      <PlainCheckbox
        label="Индивидуальные настройки"
        name={`${controls.activeCheckbox}${num}`}
        value="active"
        checked={active}
        onChange={handleActive}
        disabled={disabled}
        form={formID}
      />

      <div className={columnsRootClass}>
        <div>
          <PlainRadioButton
            label="Комплекты (множество)"
            name={`${controls.selectionRadio}${num}`}
            disabled={disabled || !active}
            value={selection.complexes}
            checked={select === selection.complexes}
            onChange={handleSelect}
            form={formID}
          />
        </div>
        {generalSettings.useCommonOrderComplexChoose && <div>
          <PlainRadioButton
            label="Комплекты (один из множества)"
            name={`${controls.selectionRadio}${num}`}
            disabled={disabled || !active}
            value={selection.chosen_complex}
            checked={select === selection.chosen_complex}
            onChange={handleSelect}
            form={formID}
          />

        </div>}
      </div>

      {errorComplexes
        && <PlainHelperText
          className={getScrollToErrorClassName()}
          error
        >
          {errorComplexes}
        </PlainHelperText>}

      <div className={styles.columns}>
        <div>
          {active
            && <Complexes
              label="Комплекты на которые начисляются льготы:"
              name={`${controls.complexesList}${num}`}
              items={availableComplexes}
              checked={complexesSelected}
              setChecked={setComplexesSelected}
              loading={loadingComplexes}
              form={formID}
              disabled={disabled}
            />}

          {active
            && !isEmpty(lostItems)
            && <LostItemsWarning items={lostItems} />}
        </div>

        <div>
          {active
            && select === selection.chosen_complex
            && <ComplexesDefaults
              name={`${controls.complexesListDefaults}${num}`}
              items={availableComplexes}
              enabledItemIDs={complexesSelected}
              selected={defaultComplexID}
              loading={loadingComplexes}
              form={formID}
              disabled={disabled}
            />}
        </div>
      </div>

      <div className={styles.columns}>
        <div>
          {active
            && select === selection.chosen_complex
            && <Complexes
              label="Дополнительно:"
              name={`${controls.additionalList}${num}`}
              items={additionalComplexes}
              checked={additionalSelected}
              setChecked={setAdditionalSelected}
              loading={loadingAdditionalComplexes}
              form={formID}
              disabled={disabled}
            />}

        </div>
        <div />
      </div>
    </div>
  )
}

export default AccrualFormCommon
