import api from 'app/actions/api';
import get from 'app/common/lib/get';

export const KIT_PRICE_EDIT_REQUEST = 'orgs/kits/KIT_PRICE_EDIT_REQUEST';
export const KIT_PRICE_EDIT_SUCCESS = 'orgs/kits/KIT_PRICE_EDIT_SUCCESS';
export const KIT_PRICE_EDIT_FAILURE = 'orgs/kits/KIT_PRICE_EDIT_FAILURE';
export const KIT_GROCERY_PRICE_EDIT = 'orgs/kits/KIT_GROCERY_PRICE_EDIT';

export const kitPriceEditRequest = payload => ({
  type: KIT_PRICE_EDIT_REQUEST,
  payload,
});

export const kitPriceEditSuccess = payload => ({
  type: KIT_PRICE_EDIT_SUCCESS,
  payload,
});

export const kitGroceryPriceEdit = payload => ({
  type: KIT_GROCERY_PRICE_EDIT,
  payload,
});

export const kitPriceEditFailure = payload => ({
  type: KIT_PRICE_EDIT_FAILURE,
  payload,
});

type Data = {
  fixed_price_enabled: boolean,
  fixed_price: string,
};

export const kitPriceEdit = (orgId: string, kitId: string, data: Data) => async (dispatch) => {
  dispatch(kitPriceEditRequest()); // ошибка, отсутствует аргумент
  try {
    const res = await api(`food/schools/${orgId}/complexes/${kitId}`, 'put', { data });
    const kitRes = get(res, ['data', 'complexes'], []);
    dispatch(kitPriceEditSuccess(kitRes));
    return kitRes;
  } catch (error) {
    dispatch(kitPriceEditFailure({ err: 'Не удалось изменить цену комплекта', error }));
    return false;
  }
};
