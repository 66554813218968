// @flow
import React from 'react';

import { FilterDataProvider } from 'app/common/ui-components/filter';

import EmployeesAtWorkMain, { type Props } from './EmployeesAtWorkMain';
import filterItems from './EmployeesAtWorkFilter/filterItems';


const EmployeesAtWork = (props: Props) => {
  return (
    <FilterDataProvider filters={filterItems}>
      <EmployeesAtWorkMain {...props} />
    </FilterDataProvider>
  );
};

export default EmployeesAtWork;
