// @flow
import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';

import { jobplacesSubmit } from 'app/actions/employees/employee/jobplaces/submit';
import usePopup from 'app/common/hooks/usePopup';
import { employeeSchoolsFetch, employeeSchoolsClear } from 'app/actions/employees/employee/jobplaces';


export type Place = {
  id: number,
  title: string,
  roleId: number,
  inviteDate: string,
  orderDate: string,
  orderNum: string,
}

type State = {
  saving: boolean,
  loading: boolean,
  formModified: boolean,
  places: Array<Place>,
}

export default function useAtWorkJobPlaces(personId: number): [
  State,
  (e: SyntheticEvent<HTMLFormElement>) => Promise<void>,
] {
  const dispatch = useDispatch();
  const popup = usePopup();

  const { sending: saving, loading, formModified } = useSelector(
    state => state.employees.employee.options,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(employeeSchoolsFetch(personId));
  }, [dispatch, personId]);

  useEffect(() => () => {
    dispatch(employeeSchoolsClear());
  }, [dispatch]);

  const jobplaces = useSelector(state => state.employees.employee.jobplaces, shallowEqual);
  const places: Array<Place> = useMemo(() => pipe(
    values,
    map(({
      id,
      title,
      role_id: roleId,
      invite_date: inviteDate,
      order_date: orderDate,
      order_num: orderNum,
    }) => ({
      id,
      title,
      roleId,
      inviteDate,
      orderDate,
      orderNum,
    })),
  )(jobplaces), [jobplaces]);

  const submitJobPlaces = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await dispatch(jobplacesSubmit(personId));
    if (res) {
      popup('Должностная информация успешно сохранена');
    }
  }, [dispatch, popup, personId]);

  return [{ saving, loading, formModified, places }, submitJobPlaces];
}
