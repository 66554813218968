import { useCallback, useMemo, useState } from 'react'
import { omit, values } from 'ramda'

import type { FileType } from 'app/common/api'


const defaultState = {} as Record<string, FileType>

export function useDocumentsState() {
  const [files, setFiles] = useState(defaultState)

  const reset = useCallback(() => {
    setFiles(defaultState)
  }, [])

  const addFiles = useCallback((addItems: Record<string, FileType>) => {
    setFiles(items => ({ ...items, ...addItems }))
  }, [])

  const removeFile = useCallback((ID: string) => {
    setFiles(omit([ID]))
  }, [])

  const filesAsArray = useMemo(() => values(files), [files])


  return {
    files: filesAsArray,
    reset,
    addFiles,
    removeFile,
  }
}
