import { defaultStateDataCurrent, type StateDataCurrent } from 'app/common/ui-components/filter'
import { PaymentSource } from 'app/dataTypes/reports/finances/paymentSource'


type Item = { id: string, title: string }

const cash = {
  id: PaymentSource.Cash,
  title: 'Наличные на кассе',
}

const sberbank = {
  id: PaymentSource.Sberbank,
  title: 'Банковская карта (Сбербанк)',
}

const gazprombank = {
  id: PaymentSource.Gazprombank,
  title: 'Банковская карта (Газпромбанк)',
}

const rosbank = {
  id: PaymentSource.Rosbank,
  title: 'Банковская карта (Росбанк)',
}

const paymentSourceItems = (): [Array<Item>, StateDataCurrent] => (
  [[cash, sberbank, gazprombank, rosbank], defaultStateDataCurrent]
)

export default paymentSourceItems
