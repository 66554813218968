// @flow
import { useEffect, useCallback, type Node } from 'react';

import { api, appTypes } from 'app/api';
import { GET_CABINET_COLORS, type ColorsResult } from 'app/common/api/requests/cabinet/colors';

import './color-theme-variables.scss';


/**
 *  Провайдер цветовой темы. Получает ключевые цвета с сервера и назначает их в CSS переменные
 */

type Props = {
  children: Node
}

const ColorThemeProvider = ({ children }: Props) => {
  const applyTheme = useCallback((colors: ColorsResult) => {
    const root = document.documentElement;
    if (root) {
      root.style.setProperty('--primary-color', colors['primary-color']);
      root.style.setProperty('--active-primary-color', colors['active-primary-color']);
      root.style.setProperty('--hover-primary-color', colors['hover-primary-color']);
      root.style.setProperty('--subitem-active-primary-color', colors['subitem-active-primary-color']);
      root.style.setProperty('--active-secondary-color', colors['active-secondary-color']);
      root.style.setProperty('--link-color', colors['link-color']);
      root.style.setProperty('--button-primary', colors['button-primary']);
      root.style.setProperty('--button-primary-hover', colors['button-primary-hover']);
    }
  }, []);


  const handleRequestTheme = useCallback(async () => {
    // only cabinet app
    if ((process.env.APP_NAME || process.env.REACT_APP_NAME) !== appTypes.APP_TYPE_CABINET) return;

    const colorsRes = await api.request(GET_CABINET_COLORS, {});
    if (colorsRes) {
      applyTheme(colorsRes);
    }
  }, [applyTheme]);


  useEffect(() => {
    handleRequestTheme();
  }, [handleRequestTheme]);


  return (children);
};

export default ColorThemeProvider;
