import { useCallback } from 'react'
import { path } from 'ramda'

import { api } from 'app/api'
import { POST_ORG_GROUP, convertParams, convertResult } from 'app/common/api/requests/food/school_groups/post'
import { DELETE_ORG_GROUP, convertParams as convertParamsDelete, convertResult as convertResultDelete } from 'app/common/api/requests/food/school_groups/delete'
import { PUT_ORG_GROUP, convertParams as convertParamsPut, convertResult as convertResultPut } from 'app/common/api/requests/food/school_groups/put'
import { POST_ORG_GROUP_ADD_ORGS, convertParams as convertParamsAddOrgs, convertResult as convertResultAddOrgs } from 'app/common/api/requests/food/school_groups/add_schools/post'
import { POST_ORG_GROUP_REMOVE_ORGS, convertParams as convertParamsRemoveOrgs, convertResult as convertResultRemoveOrgs } from 'app/common/api/requests/food/school_groups/remove_schools/post'
import { OrgGroupsByOrgType } from 'app/dataTypes/org/orgGroup'
import { OrgType } from 'app/dataTypes/org/types'
import useCallbackRef from 'app/common/hooks/useCallbackRef'


type Options = {
  onSuccess?: (groups: OrgGroupsByOrgType) => void
  onError?: (key: string, value: string) => void
  onLockOrgGroup?: (ID: string, lock: boolean) => void
  onSuccessDeleteGroup?: (orgGroupID: string) => void
}

export default function useOrgGroupsActions(orgType?: OrgType, options?: Options) {
  const { onSuccess, onError, onLockOrgGroup, onSuccessDeleteGroup } = options || {}

  const onSuccessRef = useCallbackRef(onSuccess)
  const onErrorRef = useCallbackRef(onError)
  const onLockOrgGroupRef = useCallbackRef(onLockOrgGroup)
  const onSuccessDeleteGroupRef = useCallbackRef(onSuccessDeleteGroup)

  const submitGroup = useCallback(async (groupName: string) => {
    if (!orgType) return false
    const res = await api.request(POST_ORG_GROUP, {
      error: (error: Error) => {
        const message = path(['response', 'data', 'message'], error)
        if (message === 'group with same title exists') {
          onErrorRef.current?.('title', 'Группа с таким именем уже существует')
          return 'Не удалось создать группу. Группа с таким именем уже существует'
        }
        return 'Не удалось создать группу'
      },
      params: {
        orgType,
        title: groupName,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      onSuccessRef.current?.(res)
      return true
    }
    return false
  }, [orgType, onSuccessRef, onErrorRef])

  const deleteGroup = useCallback(async (orgGroupID: string) => {
    onLockOrgGroupRef.current?.(orgGroupID, true)
    const res = await api.request(DELETE_ORG_GROUP, {
      error: 'Не удалось удалить группу',
      params: {
        orgGroupID,
      },
      convertions: {
        convertParams: convertParamsDelete,
        convertResult: convertResultDelete,
      },
    })

    if (res) {
      onSuccessRef.current?.(res)
      onSuccessDeleteGroupRef.current?.(orgGroupID)
      return true
    }
    onLockOrgGroupRef.current?.(orgGroupID, false)
    return false
  }, [onSuccessRef, onLockOrgGroupRef, onSuccessDeleteGroupRef])

  const editGroup = useCallback(async (orgGroupID: string, title: string) => {
    onLockOrgGroupRef.current?.(orgGroupID, true)
    const res = await api.request(PUT_ORG_GROUP, {
      error: 'Не удалось изменить группу',
      params: {
        orgGroupID,
        title,
      },
      convertions: {
        convertParams: convertParamsPut,
        convertResult: convertResultPut,
      },
    })

    onLockOrgGroupRef.current?.(orgGroupID, false)

    if (res) {
      onSuccessRef.current?.(res)
      return true
    }
    return false
  }, [onSuccessRef, onLockOrgGroupRef])

  const addOrgsToGroup = useCallback(async (orgGroupID: string, orgIDs: Array<string>) => {
    onLockOrgGroupRef.current?.(orgGroupID, true)
    const res = await api.request(POST_ORG_GROUP_ADD_ORGS, {
      error: 'Не удалось добавить заведения в группу',
      params: {
        orgGroupID,
        orgIDs,
      },
      convertions: {
        convertParams: convertParamsAddOrgs,
        convertResult: convertResultAddOrgs,
      },
    })

    onLockOrgGroupRef.current?.(orgGroupID, false)

    if (res) {
      return true
    }
    return false
  }, [onLockOrgGroupRef])


  const removeOrgsFromGroup = useCallback(async (orgGroupID: string, orgIDs: Array<string>) => {
    onLockOrgGroupRef.current?.(orgGroupID, true)
    const res = await api.request(POST_ORG_GROUP_REMOVE_ORGS, {
      error: 'Не удалось удалить заведения из группы',
      params: {
        orgGroupID,
        orgIDs,
      },
      convertions: {
        convertParams: convertParamsRemoveOrgs,
        convertResult: convertResultRemoveOrgs,
      },
    })

    onLockOrgGroupRef.current?.(orgGroupID, false)

    if (res) {
      return true
    }
    return false
  }, [onLockOrgGroupRef])

  return {
    submitGroup,
    deleteGroup,
    editGroup,
    addOrgsToGroup,
    removeOrgsFromGroup,
  }
}
