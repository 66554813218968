import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Button from 'app/common/ui/Button';
import Modal from 'app/common/components/Modal';
import PlainInput, { PlainInputPhoneCC } from 'app/common/ui/PlainInput';

import enhancePhoneChange from './enhancePhoneChange';
import styles from './PhoneChange.scss';

type Props = {
  requesting: boolean,
  saving: boolean,
  requestCodeError: string,
  codeError: string,
  requestCode: string,
  code: string,
  request: (e: SyntheticEvent<HTMLElement>) => void,
  submit: (e: SyntheticEvent<HTMLElement>) => void,
  close: (e: SyntheticEvent<HTMLElement>) => void,
  change: (e: SyntheticEvent<HTMLInputElement>) => void,
  resultTo: string,
  requestLocked: number,
  phone: string,
};

const PhoneChange = ({
  requesting,
  saving,
  requestCodeError,
  codeError,
  requestCode,
  code,
  request,
  submit,
  close,
  change,
  resultTo,
  requestLocked,
  phone,
}: Props) => {
  const { t } = useTranslation('profile');

  return (
    <Modal close={close}>
      <div className={styles.root}>
        <form onSubmit={request}>
          <h2>
            {phone
              ? <Trans i18nKey="profile:phone_modal_header">
                {'Смена телефона'}
              </Trans>
              : <Trans i18nKey="profile:phone_modal_header_new">
                {'Добавление телефона'}
              </Trans>}
          </h2>
          <PlainInputPhoneCC
            label={phone
              ? t('phone_modal_input_label_new')
              : t('phone_modal_input_label')}
            name="requestcode"
            placeholder={t('phone_modal_input_placeholder')}
            value={requestCode}
            onChange={change}
            isError={!!requestCodeError}
            errorText={requestCodeError}
          />
          <div className={styles.buttons}>
            <Button
              submit
              value={requestLocked
                ? t('receive_code_locked_bt', { requestLocked })
                : t('receive_code_bt')}
              disabled={requesting || !requestCode || !!requestLocked}
            />
          </div>
        </form>
        <form onSubmit={submit}>
          <div className={styles.label}>
            {resultTo}
          </div>
          <PlainInput
            label={t('code_label')}
            name="code"
            placeholder={t('code_placeholder')}
            value={code}
            onChange={change}
            isError={!!codeError}
            errorText={codeError}
          />
          <div className={styles.buttons}>
            <Button
              outline
              value={t('back_bt')}
              className={styles.button}
              onClick={close}
            />
            <Button
              submit
              value={phone ? t('submit_change_bt') : t('submit_add_bt')}
              disabled={saving || !code}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default enhancePhoneChange(PhoneChange);
