import moment from 'moment-timezone'


type Inclusivity = '()' | '[]' | '(]' | '[)'

export enum Units {
  year = 'year',
  month = 'month',
  week = 'week',
  isoWeek = 'isoWeek',
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  second = 'second'
}

enum DiffUnits {
  years = 'years',
  months = 'months',
  weeks = 'weeks',
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds'
}

type Options = {
  timezone: string
}

export default class SimpleDate {
  #date: string

  #dateFormat: string | null

  #valid: boolean

  #unix: number

  #timezone: string | null

  static units = Units

  static diffUnits = DiffUnits

  static defaultDateFormat = 'YYYY-MM-DD'

  constructor(date: string, dateFormat?: string, options?: Options) {
    const { timezone } = options || {}

    this.#date = date
    this.#dateFormat = dateFormat || null
    this.#timezone = timezone || null

    const mDate = this.createMoment()

    this.#valid = mDate.isValid()
    this.#unix = this.#valid ? mDate.unix() : 0
  }

  createMoment() {
    const mDate = this.#dateFormat ? moment(this.#date, this.#dateFormat) : moment(this.#date)
    return this.#timezone ? mDate.tz(this.#timezone) : mDate
  }

  static browserTimezone(ignoreCache?: boolean): string {
    return moment.tz.guess(ignoreCache)
  }

  static getSimpleDateUnit(unitMayBe?: string): Units {
    return unitMayBe ? SimpleDate.units[unitMayBe] : SimpleDate.units.day
  }

  toString(): string { // используется для сортировки
    return `${this.#unix}`
  }

  equals(sd: SimpleDate): boolean { // используется ramda
    if (!this.getIsValid()) return false
    return this.toString() === sd.toString()
  }

  getIsValid(): boolean {
    return this.#valid
  }

  getString(format: string): string {
    if (!this.#valid) return ''
    return (
      format === this.#dateFormat
        ? this.#date
        : this.createMoment().format(format)
    )
  }

  getStringFormatted(format: string): string {
    return this.getString(format)
  }

  getAsISO8601(): string {
    if (!this.#valid) return ''
    return this.createMoment().toISOString()
  }

  getFormat(): string | null {
    return this.#dateFormat
  }

  getValue(): string {
    return this.#date
  }

  getValueAndFormat(): [string, string] {
    if (!this.#dateFormat) {
      return [this.createMoment().format(SimpleDate.defaultDateFormat), SimpleDate.defaultDateFormat]
    }
    return [this.#date, this.#dateFormat]
  }

  getDaysInMonth(): number {
    return this.#valid ? this.createMoment().daysInMonth() : 0
  }

  getWeekday(): number {
    return this.#valid ? this.createMoment().weekday() : 0
  }

  getMonthDay(): number {
    return this.#valid ? this.createMoment().date() : 0
  }

  getPeriod(unit: Units, format: string): [string, string] {
    return [
      this.createMoment().startOf(unit).format(format),
      this.createMoment().endOf(unit).format(format),
    ]
  }

  isBetween(
    from: string | SimpleDate,
    to: string | SimpleDate,
    units_: Units = Units.day,
    inclusivity: Inclusivity = '[]',
  ): boolean {
    return this.createMoment().isBetween(
      from instanceof SimpleDate ? from.createMoment() : from,
      to instanceof SimpleDate ? to.createMoment() : to,
      units_,
      inclusivity,
    )
  }

  isSameOrBefore(
    date: string | SimpleDate,
    units_: Units = Units.day,
  ): boolean {
    return this.createMoment().isSameOrBefore(
      date instanceof SimpleDate ? date.createMoment() : date,
      units_,
    )
  }

  isBefore(
    date: string | SimpleDate,
    units_: Units = Units.day,
  ): boolean {
    return this.createMoment().isBefore(
      date instanceof SimpleDate ? date.createMoment() : date,
      units_,
    )
  }

  isSameOrAfter(
    date: string | SimpleDate,
    units_: Units = Units.day,
  ): boolean {
    return this.createMoment().isSameOrAfter(
      date instanceof SimpleDate ? date.createMoment() : date,
      units_,
    )
  }

  isAfter(
    date: string | SimpleDate,
    units_: Units = Units.day,
  ): boolean {
    return this.createMoment().isAfter(
      date instanceof SimpleDate ? date.createMoment() : date,
      units_,
    )
  }

  isSame(
    date: string | SimpleDate,
    units_: Units = Units.day,
  ): boolean {
    return this.createMoment().isSame(
      date instanceof SimpleDate ? date.createMoment() : date,
      units_,
    )
  }

  isToday(): boolean {
    return this.createMoment().isSame(moment(), SimpleDate.units.day)
  }

  isYesterdayOrBefore(): boolean {
    return this.createMoment().isSameOrBefore(
      moment().subtract(1, DiffUnits.days),
      SimpleDate.units.day,
    )
  }

  diff(
    target: string | SimpleDate,
    diffUnits_: DiffUnits = DiffUnits.days,
  ): number {
    return this.createMoment().diff(
      target instanceof SimpleDate ? target.createMoment() : target,
      diffUnits_,
    )
  }

  previosWeek(): [string, string] {
    const format = this.#dateFormat || SimpleDate.defaultDateFormat
    return [
      this.createMoment().weekday(-7).format(format),
      format,
    ]
  }

  nextWeek(): [string, string] {
    const format = this.#dateFormat || SimpleDate.defaultDateFormat
    return [
      this.createMoment().weekday(7).format(format),
      format,
    ]
  }

  startOfWeek(): [string, string] {
    const format = this.#dateFormat || SimpleDate.defaultDateFormat
    return [
      this.createMoment().startOf(SimpleDate.units.week).format(format),
      format,
    ]
  }

  endOfWeek(): [string, string] {
    const format = this.#dateFormat || SimpleDate.defaultDateFormat
    return [
      this.createMoment().endOf(SimpleDate.units.week).format(format),
      format,
    ]
  }

  nextMonth(): [string, string] {
    const format = this.#dateFormat || SimpleDate.defaultDateFormat
    return [
      this.createMoment().add(1, DiffUnits.months).format(format),
      format,
    ]
  }
}
