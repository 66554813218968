
export type StatementStatusItem = {
  ID: string
  title: string
}

export type StatementStatusMap = Map<string, StatementStatusItem>

export enum KnownStatementStatus {
  Draft = 'Draft',
  Consideration = 'Consideration',
  Processing = 'Processing',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
  Canceled = 'Canceled'
}

export const isKnownStatementStatus = (val: any): val is KnownStatementStatus => {
  return Object.values(KnownStatementStatus).includes(val)
}

export type ChangeStatusParams = {
  statementID: string
  newStatusID: KnownStatementStatus
  comment?: string
}
