import { createSelector } from 'reselect';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import sortBy from 'ramda/es/sortBy';
import toLower from 'ramda/es/toLower';
import trim from 'ramda/es/trim';

import toFixedValue from 'app/common/lib/toFixedValue';


const dishes = pipe(values, map(dish => ({
  id: dish.id,
  title: dish.title,
  portion: dish.portion,
})));

const prepare = map(kit => ({
  id: kit.id,
  title: kit.title ? trim(kit.title) : '???',
  cost: toFixedValue(parseFloat(kit.fixed_price_enabled ? kit.fixed_price : kit.cost)) || '',
  dishes: kit.items ? dishes(kit.items) : [],
}));

const sortByTitle = sortBy(pipe(prop('title'), toLower));

const selectItems = createSelector(
  [
    state => state.orgs.menu.kits,
  ],
  items => pipe(
    values,
    prepare,
    sortByTitle,
  )(items),
);

export default selectItems;
