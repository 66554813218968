// @flow
import {
  type StateDataItem,
  type StateDataCurrent,
} from 'app/common/ui-components/filter';

import { saleTypes } from 'app/dataTypes/reports/saleTypes';

import itemsDefault from './itemsDefault';


const sellTypeOnlyComplexItems = (): [Array<StateDataItem>, StateDataCurrent] => ([
  itemsDefault(),
  [saleTypes.Complex],
]);

export default sellTypeOnlyComplexItems;
