// @flow
import React, { type Node, Fragment, type ComponentType } from 'react';

import SideNav, { SideNavItem, enhanceIndent } from 'app/common/ui/SideNav';
import CSSSticky from 'app/common/ui-components/css-sticky';

import * as sections from './sections';
import IconBySection from './IconBySection';
import styles from './StickySideNav.scss';


const sideMenuArrange = [
  sections.GENERAL_SECTION,
  sections.IDENTIFIERS_SECTION,
  sections.JOBPLACES_SECTION,
  sections.SETTINGS_SECTION,
];

const sideMenuTitles = {
  [sections.GENERAL_SECTION]: 'Общая информация',
  [sections.IDENTIFIERS_SECTION]: 'Идентификаторы',
  [sections.JOBPLACES_SECTION]: 'Должностная информация',
  [sections.SETTINGS_SECTION]: 'Настройки',
};

const defaultTitle = 'Неизвестная категория';

type Props = {
  currentSection: string,
  children: Node,
  baseUrl: string,
}

type PropsWithIdent = {
  ...Props,
  leftIndent: number,
}

const StickySideNav: ComponentType<Props> = enhanceIndent(({
  currentSection,
  children,
  baseUrl,
  leftIndent,
}: PropsWithIdent) => (
  <Fragment>
    <CSSSticky
      className={styles.stickable}
      number={2}
    >
      <div className={styles.side}>
        <SideNav>
          {sideMenuArrange.map(section => (
            <SideNavItem
              key={section}
              title={sideMenuTitles[section] || defaultTitle}
              to={`${baseUrl}/${section}`}
              active={section === currentSection}
            >
              <IconBySection section={section} />
            </SideNavItem>
          ))}
        </SideNav>
      </div>
    </CSSSticky>

    <div style={{ marginLeft: leftIndent }} className={styles.right}>
      {children}
    </div>
  </Fragment>
));

export default StickySideNav;
