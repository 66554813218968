import { find, pipe, values } from 'ramda'
import { useMemo } from 'react'

import { Complexes } from './useKits'


export default function useKitsTestFixedPriceFlag(kits: Complexes) {
  const hasFlag = useMemo(() => {
    return !!pipe(
      values<Complexes, string>,
      find(({ fixed_price_enabled: flag }) => flag)
    )(kits)
  }, [kits])

  return hasFlag
}
