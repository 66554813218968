// @flow
import React from 'react';
import classNames from 'classnames/bind';

import Cell, { type Props } from './Cell';
import styles from './CellLabel.scss';


const cx = classNames.bind(styles);

type CellLabelProps = {
  ...$Exact<Props>,
  htmlFor: string,
}

const CellLabel = ({
  children,
  className,
  htmlFor,
  ...props
}: CellLabelProps) => (
  <Cell className={cx(styles.root, className)} {...props}>
    {children}
    <label // eslint-disable-line jsx-a11y/label-has-associated-control
      className={styles.label}
      htmlFor={htmlFor}
    />
  </Cell>
);

export default CellLabel;
