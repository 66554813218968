// @flow
import React, { type Node, useCallback, Fragment, useState, useMemo } from 'react';

import AddRemoveItemsModalTemplate from 'app/common/templates/AddRemoveItemsModalTemplate';
import { PUT_PLANORGAM_SLOTS_CONTENT, convertParams, convertResult } from 'app/common/api/requests/data/vending/machines/slots/content/put';
import { api } from 'app/api';
import type { PlanogramContent } from 'app/dataTypes/vending/PlanogramContent.types';

import VendingDishesEditListGear from '../_externalProduction/VendingDishesEditListGear';
import AvailableDishes from '../_externalProduction/AvailableDishes';
import type { Item } from '../_externalProduction/useAddRemoveState';
import { createRequestVendingDishes } from '../_planogram/requestVendingDishes';

import styles from './AddProduct.scss';


const empty = new Map();

type Props = {
  children: ((slotId: string) => void) => Node,
  machineId: string | null,
  placeId: string,
  onSuccess: (PlanogramContent) => void,
}

const AddProduct = ({ children, machineId, placeId, onSuccess }: Props): Node => {
  const [currentSlotId, setCurrentSlotId] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const [willAddItems, setWillAddItems] = useState<Array<Item>>([]);

  const handleDisplayStatusChange = useCallback((display: boolean) => {
    if (!display) {
      setCurrentSlotId(null);
    }
  }, []);


  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    const productId = willAddItems[0] ? willAddItems[0].id : null;
    if (!productId || !currentSlotId || !machineId) return false;

    setSubmitting(true);
    const res = await api.request(PUT_PLANORGAM_SLOTS_CONTENT, {
      error: 'Не удалось добавить товар',
      params: {
        machineId,
        data: [{
          slotId: currentSlotId,
          productId,
          amount: 0,
        }],
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      setSubmitting(false);
      onSuccess(res);
      return true;
    }
    setSubmitting(false);
    return false;
  }, [currentSlotId, machineId, willAddItems, onSuccess]);

  const dishesRequest = useMemo(() => {
    return createRequestVendingDishes(placeId);
  }, [placeId]);

  const headerContent = (
    <VendingDishesEditListGear />
  );

  const mainContent = (
    <AvailableDishes
      alreadyAdded={empty}
      disabledItems={empty}
      search={search}
      locked={submitting}
      onChangeItemsForAdd={setWillAddItems}
      externalRequest={dishesRequest}
      singleSelectable
    />
  );

  return (
    <Fragment>
      {children(setCurrentSlotId)}

      <AddRemoveItemsModalTemplate
        header="Добавление товара"
        submitButtonTitle="Добавить товар"
        submitButtonTitleInProcess="Добавление..."
        headerContent={headerContent}
        mainContent={mainContent}
        mainContentClassName={styles.mainContent}
        onSearch={setSearch}
        onSubmit={handleSubmit}
        willAddItems={willAddItems}
        show={!!currentSlotId}
        onDisplayStatusChange={handleDisplayStatusChange}
        hideWillRemove
        hideLegend
      />
    </Fragment>
  );
};

export default AddProduct;
