// @flow
import path from 'ramda/es/path';

import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const STATUS_REQUEST = 'reports/STATUS_REQUEST';
export const STATUS_SUCCESS = 'reports/STATUS_SUCCESS';
export const STATUS_FAILURE = 'reports/STATUS_FAILURE';

export const reportStatus = (reportId: number) => async (dispatch: Function) => {
  const params = { report_id: reportId };
  dispatch(action(STATUS_REQUEST, params));
  try {
    const res = await api('reports/status', 'get', { params });
    const progress = path(['data', 'status_in_percents'], res);
    dispatch(action(STATUS_SUCCESS, progress));
    return progress;
  } catch (error) {
    const errorCode = path(['response', 'status'], error) || 400;
    if (errorCode === 520) {
      dispatch(action(STATUS_FAILURE, { err: 'Ошибка в процессе генерации отчета', error }));
      return -2;
    }
    dispatch(action(
      STATUS_FAILURE,
      { err: 'Не удалось получить статус генерации отчета', error },
    ));
    return -1;
  }
};
