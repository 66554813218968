// @flow
import React from 'react';
import cn from 'classnames';

import PlainInput from 'app/common/ui-next/plain-input';

import styles from './label-with-inputs.scss';


export const textPlaceholder = '%text%';
export const placeholdersNumber = (label: string): number => (
  label.match(new RegExp(textPlaceholder, 'g')) || []
).length;

type Props = {
  id: string,
  disabled?: boolean,
  inputClassName?: string,
  label: string,
  values: Array<string>,
  onChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  onBlur: (e: SyntheticEvent<HTMLInputElement>) => void,
}

const LabelWithInputs = ({
  id,
  disabled,
  inputClassName,
  label,
  values,
  onChange,
  onBlur,
}: Props) => {
  const splitted = label.split(textPlaceholder);
  const last = splitted.length - 1;

  return (
    <span>
      {splitted.map((labelText, i) => {
        const itemId = `${id}_${i}`;
        return (
          <span key={itemId}>
            {labelText}
            {i !== last
              && <PlainInput
                id={itemId}
                className={cn(inputClassName, styles.input)}
                value={values[i]}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
              />}
          </span>
        );
      })}
    </span>
  );
};

export default LabelWithInputs;
