import toUpper from 'ramda/es/toUpper'
import test from 'ramda/es/test'


const W26 = 'w26' as const
const W34 = 'w34' as const
const W58 = 'w58' as const
const ANY = 'any' as const

const formats = {
  W26,
  W34,
  W58,
  ANY,
} as const

type Format = typeof formats[keyof typeof formats]

export default formats

export const identifiersNames = {
  [W26]: 'Wiegand26',
  [W34]: 'Wiegand34',
  [W58]: 'Wiegand58',
  [ANY]: 'Произвольный',
} as const

export const identifiersRegex = {
  [W26]: /^\d{3}\/\d{5}$/g,
  [W34]: /^(?:[0-9A-F]{2}\s){3}[0-9A-F]{2}$/g,
  [W58]: /^(?:[0-9A-F]{2}\s){6}[0-9A-F]{2}$/g,
  [ANY]: /^.+$/g,

  validate(id: Format | string, value: string): boolean {
    const currentRegex = this[id] || this[ANY]
    const res = test(currentRegex, value)
    return res
  },
}

const validate3458 = (char: string) => test(/[a-fA-F0-9]/, char)

// посимвольная валидация
export const identifiersValidators = {
  [W26]: null,
  [W34]: validate3458,
  [W58]: validate3458,
  [ANY]: null,
}

export const identifiersMasks = {
  [W26]: '111/11111',
  [W34]: 'XX XX XX XX',
  [W58]: 'XX XX XX XX XX XX XX',
  [ANY]: null,
}

// посимвольная трансформация
export const identifiersTransforms = {
  [W26]: null,
  [W34]: toUpper,
  [W58]: toUpper,
  [ANY]: null,
}

// подготовка для запросов
export const identifiersReplace = {
  [W26]: [/\//, ' '],
  [W34]: [/\s/g, ''],
  [W58]: [/\s/g, ''],

  replace(id: Format | string, value: string): string {
    const currentRegex = this[id]
    return (currentRegex
      ? value.replace(currentRegex[0], currentRegex[1])
      : value)
  },
}
