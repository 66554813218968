// @flow
import toFixedValue from 'app/common/lib/toFixedValue';

import type { Total, ReportTotal } from './useReportData';


export type DisplayableData = {
  hasDuty: boolean,
  dutySum: string,
  orderCount: string,
  orderSum: string,
  singleCount: string,
  singleSum: string,
  totalCount: string,
  totalSum: string,
  subsidiesSum?: string,
  totalWithoutSubsidiesSum?: string,
  singleSubsidiesSum?: string,
  orderSubsidiesSum?: string,
  totalSingleWithoutSubsidiesSum?: string,
  totalOrderWithoutSubsidiesSum?: string,
}

export const displayableData = ({
  // duty_count: dutyCount,
  duty_sum: dutySum,
  order_sales_count: orderCount,
  order_sales_sum: orderSum,
  single_sales_count: singleCount,
  single_sales_sum: singleSum,
  total_sales_count: totalCount,
  total_sales_sum: totalSum,
}: Total): DisplayableData => {
  return ({
    hasDuty: Boolean(dutySum),
    dutySum: dutySum ? `${toFixedValue(dutySum)} долг` : 'без долга',
    orderCount: `${orderCount} п.`,
    orderSum: toFixedValue(orderSum),
    singleCount: `${singleCount} п.`,
    singleSum: toFixedValue(singleSum),
    totalCount: `${totalCount} п.`,
    totalSum: toFixedValue(totalSum),
  });
};

export const displayableDataTotal = ({
  single_subsidies_sum: singleSubsidiesSum,
  order_subsidies_sum: orderSubsidiesSum,
  ...others
}: ReportTotal): DisplayableData => {
  const {
    total_sales_sum: totalSalesSum,
    order_sales_sum: orderSalesSum,
    single_sales_sum: singleSalesSum,
  } = others;

  const hasSingleSubsidiesSum = typeof singleSubsidiesSum === 'number';
  const hasOrderSubsidiesSum = typeof orderSubsidiesSum === 'number';
  const hasSubsidiesSum = hasSingleSubsidiesSum && hasOrderSubsidiesSum;

  return {
    ...displayableData(others),
    subsidiesSum: (
      hasSubsidiesSum
        ? toFixedValue(singleSubsidiesSum + orderSubsidiesSum)
        : ''
    ),
    totalWithoutSubsidiesSum: (
      hasSubsidiesSum
        ? toFixedValue(totalSalesSum - (singleSubsidiesSum + orderSubsidiesSum))
        : ''
    ),
    singleSubsidiesSum: (
      hasSingleSubsidiesSum
        ? toFixedValue(singleSubsidiesSum)
        : ''
    ),
    orderSubsidiesSum: (
      hasOrderSubsidiesSum
        ? toFixedValue(orderSubsidiesSum)
        : ''
    ),
    totalSingleWithoutSubsidiesSum: (
      hasSingleSubsidiesSum
        ? toFixedValue(singleSalesSum - singleSubsidiesSum)
        : ''
    ),
    totalOrderWithoutSubsidiesSum: (
      hasOrderSubsidiesSum
        ? toFixedValue(orderSalesSum - orderSubsidiesSum)
        : ''
    ),
  };
};
