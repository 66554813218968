// @flow
import React, { Fragment, useState, useCallback, type Node, useRef, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import assoc from 'ramda/es/assoc';
import has from 'ramda/es/has';
import isEmpty from 'ramda/es/isEmpty';

import Modal, { ModalHeader, ModalContent, ModalFooter } from 'app/common/components/Modal';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import { useOrgsData, useActualOrgs } from 'app/dataProviders/org/OrgsDataProvider';
import { useEmail, EmailForm } from 'app/components/common/email';
import useErrors from 'app/common/hooks/useErrors';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import CopyFrom from './CopyFrom';
import CopyTo from './CopyTo';
import CopyInside from './CopyInside';
import { getMapperByTabNum } from './errors';

import styles from './CopyMenuFromToOrgs.scss';


export type Checks = {
  copyitems: boolean,
  copykits: boolean,
  copysettings: boolean,
}

const defaultCopyOptionsState: Checks = {
  copyitems: true,
  copykits: true,
  copysettings: false,
};

type Props = {
  orgId: number,
  buildingId: number | null,
  sellPointId: number | null,
  date: string,
  children: (() => void) => Node,
}

const CopyMenuFromToOrgs = ({
  orgId,
  buildingId,
  sellPointId,
  date,
  children,
}: Props) => {
  const { email: userEmail } = useSelector(state => state.user, shallowEqual);

  const [{ orgs: orgsState, loading: loadingOrgs }] = useOrgsData();
  const orgs = useActualOrgs(orgsState, { orgId });
  const singleOrg = loadingOrgs || isEmpty(orgs);

  const [checks, setChecks] = useState<Checks>(defaultCopyOptionsState);

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState(singleOrg ? 3 : 1);
  const [errors, validate, resetErrors] = useErrors(getMapperByTabNum(activeTabIndex));

  useEffect(() => {
    setActiveTabIndex(singleOrg ? 3 : 1);
  }, [singleOrg]);


  const [emailEnabled, setEmailEnabled] = useState(false);

  const [
    [email, handleEmailChange],
    [emailError, checkEmailErrors],
  ] = useEmail({ enable: emailEnabled, defaultEmail: userEmail });


  const handleChangeOp = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;

    if (!has(value, checks)) return;

    // нельзя снЯть чекбокс с copykits и copyitems одновременно
    if (!checked && (value === 'copyitems' || value === 'copykits')) {
      setChecks(current => ({
        ...current,
        copyitems: value === 'copykits',
        copykits: value === 'copyitems',
      }));
      return;
    }

    // в остальных случаях
    setChecks(assoc(value, checked));
  }, [setChecks, checks]);


  const [appear, setAppear] = useState(false);
  const toggleModal = useCallback(() => {
    setAppear(appear_ => !appear_);
  }, []);

  const copyFromRef = useRef(null);
  const copyToRef = useRef(null);
  const copyInsideRef = useRef(null);


  // отправка события в форму для submit
  const handleSubmit = useCallback(() => {
    if (activeTabIndex === 1 && copyFromRef.current) {
      copyFromRef.current.submit.click();
    }
    if (activeTabIndex === 2) {
      if (checkEmailErrors() && copyToRef.current) {
        copyToRef.current.submit.click();
      }
    }
    if (activeTabIndex === 3 && copyInsideRef.current) {
      copyInsideRef.current.submit.click();
    }
  }, [activeTabIndex, checkEmailErrors]);


  useEffect(() => {
    // скрытие email при переключении таба (email появляется при взаимодействии внутри таба)
    setEmailEnabled(false);
  }, [activeTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Fragment>
      {children(toggleModal)}

      {appear
        && <Modal toggleModal={toggleModal}>
          <ModalHeader>{'Копирование меню'}</ModalHeader>

          <ModalContent className={styles.tabs}>
            <Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
              {!singleOrg && <Tab index={1}>{'Из другого заведения'}</Tab>}
              {!singleOrg && <Tab index={2}>{'В другие заведения'}</Tab>}
              <Tab index={3}>{'В пределах заведения'}</Tab>
            </Tabs>
          </ModalContent>

          <div className={styles.content}>
            <ModalContent maxHeight={250} className={styles.contentInside}>
              <TabPanels value={activeTabIndex} className={styles.panels} unmountHidden>

                {!singleOrg
                  && <TabPanel index={1}>
                    <CopyFrom
                      orgId={orgId}
                      buildingId={buildingId}
                      sellPointId={sellPointId}
                      onClose={toggleModal}
                      onLoading={setLoading}
                      date={date}
                      formRef={copyFromRef}
                      onSubmitting={setSaving}
                      optionsChecks={checks}
                      resetErrors={resetErrors}
                      validate={validate}
                      errors={errors}
                      hideControls
                    />
                  </TabPanel>}

                {!singleOrg
                  && <TabPanel index={2}>
                    <CopyTo
                      orgId={orgId}
                      buildingId={buildingId}
                      sellPointId={sellPointId}
                      onClose={toggleModal}
                      onLoading={setLoading}
                      date={date}
                      email={email}
                      formRef={copyToRef}
                      onSubmitting={setSaving}
                      optionsChecks={checks}
                      onMoreThanOneSelectedOrgs={setEmailEnabled}
                      resetErrors={resetErrors}
                      validate={validate}
                      errors={errors}
                      hideControls
                    />
                  </TabPanel>}

                <TabPanel index={3}>
                  <CopyInside
                    orgId={orgId}
                    buildingId={buildingId}
                    date={date}
                    formRef={copyInsideRef}
                    optionsChecks={checks}
                    onClose={toggleModal}
                    resetErrors={resetErrors}
                    validate={validate}
                    errors={errors}
                    onSubmitting={setSaving}
                    hideControls
                  />
                </TabPanel>
              </TabPanels>
            </ModalContent>
          </div>

          {(errors.org || errors.datesTo)
            && <PlainHelperText className={styles.error} overflowVisible error>
              {errors.org || errors.datesTo}
            </PlainHelperText>}

          <ModalFooter className={styles.footer}>
            <PlainFieldset
              name="options"
              legend="Опции"
              className={styles.options}
              legendClassName={styles.optionsLegend}
            >
              <PlainCheckbox
                label="Копировать одиночную продукцию"
                value="copyitems"
                disabled={loading || saving}
                checked={checks.copyitems}
                onChange={handleChangeOp}
              />
              <PlainCheckbox
                label="Копировать комплекты"
                value="copykits"
                id="copykits"
                disabled={loading || saving}
                checked={checks.copykits}
                onChange={handleChangeOp}
              />
              {activeTabIndex !== 3
                && <PlainCheckbox
                  label="Копировать настройки продукции и комплектов"
                  value="copysettings"
                  disabled={loading || saving}
                  checked={checks.copysettings}
                  onChange={handleChangeOp}
                />}
            </PlainFieldset>

            {emailEnabled
              && <EmailForm
                label="Копирование может занять продолжительное время. После подтверждения вы будете перенаправлены в раздел «Меню», а по завершении на указанный ниже e-mail будет выслан отчёт."
                disabled={saving}
                value={email}
                error={emailError}
                onChange={handleEmailChange}
              />}

            <ControlsBar className={styles.buttons} right>
              <PlainButton outline onClick={toggleModal}>
                {'Отмена'}
              </PlainButton>
              <PlainButton onClick={handleSubmit} disabled={loading || saving}>
                {saving ? 'Копирование...' : 'Копировать'}
              </PlainButton>
            </ControlsBar>
          </ModalFooter>

        </Modal>}
    </Fragment>
  );
};

export default CopyMenuFromToOrgs;
