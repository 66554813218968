// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames/bind';

import styles from './CellWho.scss';


const cx = classNames.bind(styles);

type Props = {
  name: string,
  role: string,
}

const CellWho = ({ name, role }: Props) => {
  return (
    <Fragment>
      <div className={styles.name}>{name}</div>
      <div className={cx(styles.role, { small: !!name })}>{role}</div>
    </Fragment>
  );
};

export default CellWho;
