import {
  periodFilter,
} from 'app/dataProviders/filter-items'


export const filterInit = () => {
  return () => {
    periodFilter.reset()
  }
}

export const resetToDefaultAll = () => {
  periodFilter.resetToDefault()
}
