// @flow
import { defaultStateDataCurrent, type StateDataCurrent } from 'app/common/ui-components/filter';
import { studentStatuses } from 'app/common/api/requests/reports/perform';


export { studentStatuses };

type Item = { id: string, title: string };

const active = {
  id: studentStatuses.ACTIVE,
  title: 'Текущие',
};

const expelled = {
  id: studentStatuses.EXPELLED,
  title: 'Выбывшие',
};

const graduated = {
  id: studentStatuses.GRADUATED,
  title: 'Выпускники',
};

const pending = {
  id: studentStatuses.PENDING,
  title: 'Ожидающие приема',
};

const studentStatusItems = (): [Array<Item>, StateDataCurrent] => (
  [[active, expelled, graduated, pending], defaultStateDataCurrent]
);

export const exceptPending = (): [Array<Item>, StateDataCurrent] => (
  [[active, expelled, graduated], defaultStateDataCurrent]
);

export default studentStatusItems;
