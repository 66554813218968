// @flow
import React, { type Node, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import Tooltip from 'app/common/ui/Tooltip';

import useProcessGenerate from './use-process-generate';
import DefaultProgressModal from './default-progress-modal';
import getProcessMessagesDefault from './get-process-messages';

import styles from './generate-file.scss';


const cx = classNames.bind(styles);

type Props = {
  className?: string,
  labelClassName?: string,
  label: string,
  generateAndDownloadPrompt: string,
  downloadPrompt: string,
  labelAsLink?: boolean,
  onProcessRequest: () => Promise<number | string | null>,
  onProcessCheckStatus: (reportId: number) => Promise<number | string>,
  onFileDownload: (reportId: number) => Promise<void>,
  getProcessMessages?: (processId: ?number) => [string, string],
  children: Node,
}

const GenerateFile = ({
  className,
  labelClassName,
  label,
  generateAndDownloadPrompt,
  downloadPrompt,
  labelAsLink,
  onProcessRequest,
  onProcessCheckStatus,
  onFileDownload,
  getProcessMessages = getProcessMessagesDefault,
  children,
}: Props) => {
  const [ready, setReady] = useState(null); // начать генерацию
  const [loading, setLoading] = useState(false); // процесс загрузки


  const download = useCallback(async (reportId: number) => {
    setLoading(true);
    await onFileDownload(reportId);
    setLoading(false);
  }, [onFileDownload]);


  const [generateState, generateHandlers] = useProcessGenerate({
    ready,
    onProcessRequest,
    onProcessCheckStatus,
  });

  const {
    generatedReportId,
    progress,
    processStatus,
    processId,
  } = generateState;

  const [message, messageError] = getProcessMessages(processId);
  const { onCancel } = generateHandlers;

  const handleClick = useCallback(() => {
    if (!generatedReportId) {
      setReady([]);
      return;
    }
    download(generatedReportId);
  }, [generatedReportId, download]);

  const handleCancel = useCallback(() => {
    setReady(null);
    onCancel();
  }, [onCancel]);

  const prompt = generatedReportId ? downloadPrompt : generateAndDownloadPrompt;


  useEffect(() => {
    if (generatedReportId) {
      // если файл сгенерировался, то сразу его загрузка
      download(generatedReportId);
    }
  }, [generatedReportId]); // eslint-disable-line react-hooks/exhaustive-deps


  const labelContent = (label
    ? (<legend className={cx(styles.legend, labelClassName)}>
      {label}
    </legend>)
    : null);


  return (
    <div className={className}>
      <div className={styles.defaultLayout}>
        <fieldset className={styles.fieldset}>

          {!labelAsLink && labelContent}

          <Tooltip text={prompt}>
            <PlainButtonAsLink
              className={cx({ buttonLoading: loading })}
              disabled={loading}
              onClick={handleClick}
              ariaLabel={prompt}
            >
              {labelAsLink
                ? <div className={styles.labelAsLink}>
                  {labelContent}
                  {children}
                </div>
                : children}
            </PlainButtonAsLink>
          </Tooltip>

        </fieldset>
      </div>

      {processStatus !== 'hidden'
        && <DefaultProgressModal
          progress={progress}
          isError={processStatus === 'error'}
          onCancel={handleCancel}
          message={message}
          messageError={messageError}
        />}
    </div>
  );
};

export default GenerateFile;
