import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import * as orgTypes from 'app/common/constants/schools/types';

import {
  ORGS_TYPES_FILTER_CLEAR,
  ORGS_TYPES_FILTER_DELETE,
  ORGS_TYPES_FILTER_SET,
  ORGS_TYPES_FILTER_UPDATE,
} from 'app/actions/reports/filters/orgsTypes';


const defaultState = {
  data: {
    [orgTypes.SCHOOL]: {
      id: orgTypes.SCHOOL,
      title: 'Общеобразовательное учреждение (ОУ)',
    },
    [orgTypes.PRESCHOOL]: {
      id: orgTypes.PRESCHOOL,
      title: 'Дошкольное образовательное учреждение (ДОУ)',
    },
    [orgTypes.HIGH_SCHOOL]: {
      id: orgTypes.HIGH_SCHOOL,
      title: 'Образовательное учреждение высшего образования (ВУЗ)',
    },
    [orgTypes.ENTERPRISE]: {
      id: orgTypes.ENTERPRISE,
      title: 'Предприятие',
    },
  },
  selected: {},
  loading: true,
  // active: true, // всегда активный
};

const orgs = (state = defaultState, { type, payload }) => {
  switch (type) {
    case ORGS_TYPES_FILTER_CLEAR: {
      return defaultState;
    }
    case ORGS_TYPES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case ORGS_TYPES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case ORGS_TYPES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default orgs;
