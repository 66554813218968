// @flow
import * as React from 'react';
import classNames from 'classnames/bind';

import { DotsLoader } from 'app/common/components/Loader';
import InsideMenu from './InsideMenu';
import type { MenuItemType } from './InsideMenu/InsideMenu';

import styles from './PlainNav.scss';


type Props = {
  items?: Array<MenuItemType>,
  scrollable?: boolean,
  title?: string,
  loading?: boolean,
  className?: string,
  children?: any,
  ref?: React.ElementRef<any>,
};
const cx = classNames.bind(styles);
const PlainNav = ({ items, scrollable, title, loading, className, children, ref }: Props) => (
  <div
    className={cx(styles.root, className, { scrollable: scrollable && !loading })}
    ref={ref}
  >
    {title
      && <div className={cx(styles.title, { titleFix: scrollable })}>
        {`${title}:`}
      </div>}
    {loading
      ? <DotsLoader />
      : <InsideMenu
        items={items}
        scrollable={scrollable}
        className={cx(styles.items, { heightFix: scrollable })}
      >
        {children}
      </InsideMenu>}
  </div>
);

export default PlainNav;
