// @flow
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';


const excludeOVZItems = () => ([[{
  id: 'excludeOVZ',
  title: 'Исключить категории «Дети с ОВЗ», «Адаптированные программы, ОВЗ»',
}], defaultStateDataCurrent]);

export default excludeOVZItems;
