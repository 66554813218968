// @flow
import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import Button from '../Button';
import styles from './Message.scss';


// DEPRECATED use ui-next/plain-message-modal

type Props = {
  text: string,
  buttons: Array<Object>,
  overlayClassName?: string,
  isOpen?: boolean,
};

ReactModal.setAppElement('#root');
const getRoot = () => document.querySelector('#modal');

class Message extends React.Component<Props> {
  componentDidMount() {
    const body = document.getElementsByTagName('body')[0];
    const origW = body.offsetWidth;
    body.classList.add(styles.noscroll);
    const scrollW = body.offsetWidth - origW;
    body.style.paddingRight = `${scrollW}px`;
  }

  componentWillUnmount() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove(styles.noscroll);
    body.style.paddingRight = '0';
  }

  handleClick = (e: SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  // props: Props;

  render() {
    const { text, buttons, isOpen, overlayClassName } = this.props;

    return (
      <ReactModal
        isOpen={isOpen === undefined ? true : isOpen}
        parentSelector={getRoot}
        className={styles.win}
        overlayClassName={classNames(styles.root, overlayClassName)}
        contentLabel="Message"
      >
        <div className={styles.win}>
          <div className={styles.text}>
            {text.split('\n').map((str, i) => <div key={i}>{str}</div>)}
          </div>
          <div className={styles.buttons}>
            {buttons.map(props => <Button {...props} />)}
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default Message;
