import getNumEnding from 'app/common/lib/getNumEnding'
import ControlsBar from 'app/common/ui-next/controls-bar'
import PlainButton from 'app/common/ui-next/plain-button'
import { PlainConfirm } from 'app/common/ui-next/plain-confirm-modal'

import styles from './SelectionActions.scss'


type Props = {
  onClickCancel: () => void
  onClickAction: () => Promise<boolean>
  selectionNumber: number
  actionTitle: string
  confirmMessage: string
}

export const SelectionActions = ({
  onClickCancel,
  onClickAction,
  selectionNumber,
  actionTitle,
  confirmMessage,
}: Props) => {
  const itemName = getNumEnding(selectionNumber, ['заведение', 'заведения', 'заведений'])

  return (
    <ControlsBar right>
      <div className={styles.text}>{`Выбрано: ${selectionNumber} ${itemName}`}</div>
      <PlainButton onClick={onClickCancel} outline>{'Отмена'}</PlainButton>
      <PlainConfirm
        messageText={confirmMessage}
        onConfirm={onClickAction}
        closeRightAway
      >
        {onBeginConfirmation => (
          <PlainButton onClick={onBeginConfirmation} disabled={selectionNumber === 0}>
            {actionTitle}
          </PlainButton>
        )}
      </PlainConfirm>
    </ControlsBar>
  )
}
