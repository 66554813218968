import { createSelector } from 'reselect';

import { selectOrgId, selectYearId, selectClassType, selectOrgsType } from 'app/components/Reports/FilterItems/hocs/commonSelectors';
import * as availableClassTypes from 'app/actions/reports/filters/classTypesAvailable';
import { isSingle } from 'app/common/lib/object';
import * as orgsTypes from 'app/common/constants/schools/types';


const selectLevelsReady = createSelector(
  [
    state => state.reports.filters.orgs.selected,
    state => state.reports.filters.educationLevels.loading,
    state => state.reports.filters.years.loading,
  ],
  (orgsSelected, levelsLoading, yearsLoading) => (
    isSingle(orgsSelected)
    && !(levelsLoading || yearsLoading)
  ),
);

const selectIsEnableClassesFilter = createSelector(
  [
    selectOrgId,
    selectClassType,
    selectYearId,
    selectLevelsReady,
    selectOrgsType,
  ],
  (orgId, classType, yearId, levelsReady, orgsType) => {
    // на предприятии нет классов или групп
    if (orgsType === orgsTypes.ENTERPRISE) {
      return false;
    }
    // школы
    if (orgsType === orgsTypes.SCHOOL) {
      return Boolean(
        orgId
        && classType === availableClassTypes.CLASS_TYPE_COMMON
        && yearId
        && levelsReady,
      );
    }
    // у остальных типов заведений нет типа классов и уровня образования
    // но данный фильтр выводится, как выбор группы
    return Boolean(orgId && yearId);
  },
);

export default selectIsEnableClassesFilter;
