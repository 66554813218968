import PlainNav, { NavItem } from 'app/common/ui-next/plain-nav'
import Link from 'app/common/lib/link'

import { SectionRoute } from './router'


type Props = {
  baseURL: string
  activeSection: SectionRoute | null
}

export const CompensationPageNavigation = ({ baseURL, activeSection }: Props) => {
  return (
    <PlainNav NavComponent={Link}>
      <NavItem
        to={`${baseURL}/${SectionRoute.Accruals}`}
        active={activeSection === SectionRoute.Accruals}
      >
        {'Начисления'}
      </NavItem>
      <NavItem
        to={`${baseURL}/${SectionRoute.Statements}`}
        active={activeSection === SectionRoute.Statements}
      >
        {'Заявления'}
      </NavItem>
      <NavItem
        to={`${baseURL}/${SectionRoute.Recipients}`}
        active={activeSection === SectionRoute.Recipients}
      >
        {'Получатели компенсации'}
      </NavItem>
      <NavItem
        to={`${baseURL}/${SectionRoute.Settings}`}
        active={activeSection === SectionRoute.Settings}
      >
        {'Настройки'}
      </NavItem>
    </PlainNav>
  )
}
