import path from 'ramda/es/path';
import map from 'ramda/es/map';

import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const MENUCATEGORIES_REQUEST = 'orgs/settings/MENUCATEGORIES_REQUEST';
export const MENUCATEGORIES_SUCCESS = 'orgs/settings/MENUCATEGORIES_SUCCESS';
export const MENUCATEGORIES_FAILURE = 'orgs/settings/MENUCATEGORIES_FAILURE';
export const MENUCATEGORIES_CLEAR = 'orgs/settings/MENUCATEGORIES_CLEAR';

export const MENUCATEGORIES_CHANGE = 'orgs/settings/MENUCATEGORIES_CHANGE';
export const MENUCATEGORIES_CHANGE_ALL = 'orgs/settings/MENUCATEGORIES_CHANGE_ALL';

export const fetchMenuCategoriesSettings = orgId => async (dispatch) => {
  dispatch(action(MENUCATEGORIES_REQUEST, { orgId }));
  try {
    const [res1, res2] = await Promise.all([
      api('food/menu/categories', 'get', { params: { deleted: false } }),
      api(`food/schools/${orgId}/menu_categories`, 'get'),
    ]);
    const allCategories = path(['data', 'categories'], res1);
    const selectedCategories = path(['data', 'categories'], res2);
    const categories = map(({ id, title }) => ({
      id,
      title,
      enabled: !!selectedCategories[id],
    }), allCategories);
    dispatch(action(MENUCATEGORIES_SUCCESS, categories));
  } catch (error) {
    dispatch(action(MENUCATEGORIES_FAILURE, {
      err: 'Не удалось загрузить настройки категорий меню',
      error,
      orgId,
    }));
  }
};
