import evolve from 'ramda/es/evolve';
import add from 'ramda/es/add';

import {
  DISHES_FILTER_SET,
  DISHES_FILTER_CLEAR,
  DISHES_SEARCH,
  DISHES_SET_PAGE,
  DISHES_NEED_UPDATE,
} from 'app/actions/orgs/dishes/options';

import {
  DISHES_REQUEST,
  DISHES_SUCCESS,
  DISHES_FAILURE,
  DISHES_CANCEL,
  DISHES_STATE_CLEAR,
} from 'app/actions/orgs/dishes';

import {
  DISHES_REMOVE_ITEMS_SUCCESS,
  DISHES_MOVE_ITEMS_SUCCESS,
} from 'app/actions/orgs/dishes/selection';

const defaultState = {
  searchString: '',
  page: 1,
  count: 0,
  filter: {},
  loading: true,
  needUpdate: false,
};

const options = (state = defaultState, action) => {
  if (action.type === DISHES_REQUEST) {
    return ({ ...state, loading: true });
  }
  if (action.type === DISHES_SUCCESS) {
    return ({ ...state, count: action.payload.count, loading: false });
  }
  if (action.type === DISHES_FAILURE || action.type === DISHES_CANCEL) {
    return ({ ...state, loading: false });
  }
  if (action.type === DISHES_STATE_CLEAR) {
    return ({ ...defaultState, filter: state.filter });
  }
  if (action.type === DISHES_SET_PAGE) {
    return ({ ...state, page: action.payload });
  }
  if (action.type === DISHES_SEARCH) {
    return ({ ...state, searchString: action.payload });
  }
  if (action.type === DISHES_FILTER_SET) {
    return ({ ...state, filter: { ...action.payload } }); // TODO сделать добавление вместо замены всего фильтра?
  }
  if (action.type === DISHES_FILTER_CLEAR) {
    return ({ ...state, filter: { ...action.payload } });
  }
  if (action.type === DISHES_REMOVE_ITEMS_SUCCESS || action.type === DISHES_MOVE_ITEMS_SUCCESS) {
    return evolve({ count: add(-action.payload.length) }, state);
  }
  if (action.type === DISHES_NEED_UPDATE) {
    return ({ ...state, needUpdate: action.payload });
  }
  return state;
};

export default options;
