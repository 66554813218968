// @flow
import React from 'react';
import styles from './ProgressBar.scss';


type Props = {
  progress: number;
};

const ProgressBar = ({ progress, ...other }: Props) =>
  // $FlowFixMe
  (<div className={styles.root} {...other}>
    <div className={styles.bar} style={{ width: `${progress}%` }} />
  </div>);

export default ProgressBar;
