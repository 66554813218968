import cn from 'classnames/bind'

import styles from './header2.scss'


const cx = cn.bind(styles)

type Props = {
  children: React.ReactNode
  className?: string
  skipMargin?: boolean
  wide?: boolean
}

const Header2 = ({ children, className, skipMargin, wide = true }: Props) => {
  return (
    <h2 className={cx(styles.header2, className, { skipMargin, wide })}>
      {children}
    </h2>
  )
}

export default Header2
