// @flow
import React, { type Node } from 'react';

import styles from './h-scrollable-content.scss';


type Props = {
  children: Node,
}

const HScrollableContent = ({ children }: Props) => {
  return (
    <div className={styles.root}>
      {children}
    </div>
  );
};

export default HScrollableContent;
