import { useCallback, useState } from 'react'

import { CompensationInfoStatus } from 'app/dataTypes/compensation/info'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import { PlainConfirm } from 'app/common/ui-next/plain-confirm-modal'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import { cancelAvailable } from 'app/dataProviders/compensation/status'

import { SkipConfirmation } from './SkipConfirmation'


const getButtonTitle = (status: CompensationInfoStatus) => {
  if (cancelAvailable(status)) {
    return 'Удалить фиксацию'
  }
  if (status === CompensationInfoStatus.InProgress) {
    return 'Отменить подсчет'
  }

  return 'Зафиксировать'
}

const getConfirmationText = (status: CompensationInfoStatus, orgTitle: string) => {
  if (cancelAvailable(status)) {
    return `Фиксация для заведения «${orgTitle}» будет удалена. Продолжить?`
  }
  if (status === CompensationInfoStatus.InProgress) {
    return `Подсчет для заведения «${orgTitle}» будет отменен. Продолжить?`
  }

  return `Будет произведена фиксация для заведения «${orgTitle}». Продолжить?`
}

const getActionByStatus = (status: CompensationInfoStatus) => {
  if (cancelAvailable(status)) {
    return 'cancel'
  }
  if (status === CompensationInfoStatus.InProgress) {
    return 'interrupt'
  }

  return 'record'
}

type Props = {
  status: CompensationInfoStatus
  orgTitle: string
  onAction: (action: 'record' | 'cancel' | 'interrupt') => Promise<boolean>
}

export const Action = ({ status, orgTitle, onAction }: Props) => {
  const onActionRef = useCallbackRef(onAction)
  const [disabled, setDisabled] = useState(false)

  const handleConfirm = useCallback(async () => {
    setDisabled(true)
    const res = await onActionRef.current?.(getActionByStatus(status))
    setDisabled(false)
    return !!res
  }, [status, onActionRef])

  return (
    <PlainConfirm
      messageText={getConfirmationText(status, orgTitle)}
      onConfirm={handleConfirm}
      closeRightAway
    >
      {(beginConfirmation, stopConfirmation) => (
        <PlainButtonAsLink
          onClick={beginConfirmation}
          disabled={
            disabled
            || status === CompensationInfoStatus.Pending
            || status === CompensationInfoStatus.Error
          }
        >
          {disabled ? 'Загрузка...' : getButtonTitle(status)}
          <SkipConfirmation status={status} onChangeStatus={stopConfirmation} />
        </PlainButtonAsLink>
      )}
    </PlainConfirm>
  )
}
