// @flow
import api from 'app/actions/api';


export const PRIVACY_ACCEPT_REQUEST = 'PRIVACY_ACCEPT_REQUEST';
export const PRIVACY_ACCEPT_SUCCESS = 'PRIVACY_ACCEPT_SUCCESS';
export const PRIVACY_ACCEPT_FAILURE = 'PRIVACY_ACCEPT_FAILURE';

export const privacyAcceptRequest = () => ({
  type: PRIVACY_ACCEPT_REQUEST,
});

export const privacyAcceptSuccess = (data: Array<number>) => ({
  type: PRIVACY_ACCEPT_SUCCESS,
  payload: data,
});

export const privacyAcceptFailure = (payload: Object) => ({
  type: PRIVACY_ACCEPT_FAILURE,
  payload,
});


/**
* Принятие согласия обработки личных данных
*/
export const acceptPrivacy = (ids: Array<number>) => async (dispatch: Function) => {
  dispatch(privacyAcceptRequest());
  try {
    await api('account/agreement/accept', 'post', { data: { id: ids } });
    dispatch(privacyAcceptSuccess(ids));
  } catch (error) {
    dispatch(privacyAcceptFailure({ err: 'Не удалось отправить согласие с политикой по обработке личных данных', error }));
  }
};
