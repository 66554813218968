export const EMPLOYEES_SEARCH = 'EMPLOYEES_SEARCH';
export const EMPLOYEES_SET_PAGE = 'EMPLOYEES_SET_PAGE';
export const EMPLOYEES_NEED_UPDATE = 'EMPLOYEES_NEED_UPDATE';


export const employeesNeedUpdate = payload => ({
  type: EMPLOYEES_NEED_UPDATE,
  payload,
});

export const employeesSearch = payload => ({
  type: EMPLOYEES_SEARCH,
  payload,
});

export const employeesSetPage = payload => ({
  type: EMPLOYEES_SET_PAGE,
  payload,
});
