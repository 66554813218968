
import { Applies } from 'app/dataTypes/subsidy/settings'

import { controls } from './formParts/AppliesForm'


export default function extractApplies(formData: FormData): Applies | null {
  if (formData.get(controls.hasApplies)) {
    return {
      cafeteria: [
        !!formData.get(`${controls.cafeteria}1`),
        !!formData.get(`${controls.cafeteria}2`),
      ],
    }
  }

  return null
}
