// @flow
import length from 'ramda/src/length';
import reduce from 'ramda/src/reduce';
import reduced from 'ramda/src/reduced';
import addIndex from 'ramda/src/addIndex';
import drop from 'ramda/src/drop';
import remove from 'ramda/src/remove';
import includes from 'ramda/src/includes';
import take from 'ramda/src/take';

/**
 *  Накладывание чистого value на маску
 */

type Mapping = {
  [key: string]: any,
}

const mappingDefault: Mapping = {
  _: true,
};

const getWhileNotNumber = (index, mask, mapping) => {
  let skip = 0;
  const skippedMask = drop(index, mask);
  const filler = reduce((acc, item) => {
    skip += 1;
    if (!mapping[item]) return `${acc}${item}`;
    return reduced(acc);
  }, '', skippedMask);
  return { skip, filler };
};

const reduceIndexed = addIndex(reduce);

const transformByMask = (
  value: string,
  mask: string,
  prefix: string,
  mapping?: Mapping = mappingDefault,
): string => {
  if (!value) return '';

  const dropLen = reduceIndexed((acc, item, i) => (
    includes(take(i + 1, value), prefix)
      ? ++acc // eslint-disable-line no-param-reassign
      : reduced(acc)
  ), 0, value);

  const newValue = remove(0, dropLen, value);

  let idx = length(prefix);
  return reduce((acc, item) => {
    idx += 1;
    if (item) {
      if (mapping[mask[idx]]) {
        return `${acc}${item}`;
      }
      const { skip, filler } = getWhileNotNumber(idx, mask, mapping);
      idx = idx + skip - 1;
      return `${acc}${item}${filler}`;
    }
    return acc;
  }, prefix, newValue);
};

export default transformByMask;
