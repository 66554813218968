// @flow
import * as React from 'react';

import ParamsContext from './ParamsContext';
import calcBaseUrl from './calcBaseUrl';


/**
 * Извлекает следующие свойства из контекста (WithRouterParamsProvider)
 * все что описаны в ParamsContext:
 *    params: Object,
 *    pathname: string,
 *    push: Function,
 *    pathMask: string,
 */

const withRouterParams = (Component: React.ComponentType<any>) => (props: Object) => (
  <ParamsContext.Consumer>
    {contexts => <Component {...props} {...contexts} />}
  </ParamsContext.Consumer>
);

/**
 * Извлекает из контекста то же что и withRouterParams плюс baseUrl
 * на основе except, в except перечислены имена params или просто имена
 * компонентов пути, которые не должны входить в baseUrl
 */

export const withRouterParamsE = (except: Array<string>) => (Component: React.ComponentType<any>) => (props: Object) => (
  <ParamsContext.Consumer>
    {(contexts) => {
      const baseUrl = calcBaseUrl(contexts, except);
      return (
        <Component {...props} {...contexts} baseUrl={baseUrl} />
      );
    }}
  </ParamsContext.Consumer>
);

export default withRouterParams;
