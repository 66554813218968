import { combineReducers } from 'redux';

import orgs from './orgs';
import current from './current';
import sells from './sells';
import debts from './debts';
import presence from './presence';
import dishes from './dishes';
import menu from './menu';
import kits from './kits';
import settings from './settings';

export default combineReducers({
  orgs,
  sells,
  debts,
  presence,
  current,
  dishes,
  menu,
  kits,
  settings,
});
