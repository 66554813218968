// @flow
import React from 'react';

type Props = {
  children: any,
  className?: string,
  style?: Object,
};

const Table = ({ children, className, style }: Props) => <div className={className} style={style}>{children}</div>;

export default Table;
