// @flow
import lensPath from 'ramda/es/lensPath';
import set from 'ramda/es/set';

import { EMPLOYEE_SUCCESS } from 'app/actions/employees/employee';
import { EMPLOYEE_EDIT_SUCCESS } from 'app/actions/employees/employee/edit';
import { EMPLOYEEFORM_CHANGE_FIELD, EMPLOYEEFORM_SUBMIT_SUCCESS } from 'app/actions/employees/employee/employeeform';

import { defaultState as incoming } from './fields';
import type { EmployeeFields } from './fields';


export type EmployeeForm = EmployeeFields;

const defaultState: EmployeeForm = {
  ...incoming,
};

const employeeform = (state: EmployeeForm = defaultState, action: any) => {
  if (action.type === EMPLOYEE_EDIT_SUCCESS) {
    return ({ ...defaultState, ...action.payload });
  }
  if (action.type === EMPLOYEE_SUCCESS) {
    return ({ ...defaultState, ...action.payload });
  }
  if (action.type === EMPLOYEEFORM_CHANGE_FIELD) {
    const key = Object.keys(action.payload)[0];
    const lens = lensPath(key.split('.'));
    return set(lens, action.payload[key], state);
  }
  if (action.type === EMPLOYEEFORM_SUBMIT_SUCCESS) {
    return ({ ...defaultState, ...action.payload });
  }
  return state;
};

export default employeeform;
