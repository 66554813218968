// @flow
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import omit from 'ramda/es/omit';
import prop from 'ramda/es/prop';
import clone from 'ramda/es/clone';
import replace from 'ramda/es/replace';
import pipe from 'ramda/es/pipe';
import path from 'ramda/es/path';
import cond from 'ramda/es/cond';
import equals from 'ramda/es/equals';
import always from 'ramda/es/always';
import T from 'ramda/es/T';

import api from 'app/actions/api';
import action from 'app/common/lib/action';
import { popup } from 'app/common/actions/popup';
import normalizeString from 'app/common/lib/normalizeString';

import { type SellPoints } from '../../commonHooks/useSellPointsData';
import lang from './SellPoints.lang';


const defaultResult = {};
// type ResultUpdateFunc = ((SellPoints => SellPoints) | SellPoints) => void
type OnResultUpdateFunc = (res: SellPoints) => void

const prepareSuccessString = (langStr: string, selectionPhrase: string) => pipe(
  replace('%target%', selectionPhrase),
  normalizeString,
)(langStr);

export default function useSellPointsOperate(
  orgId: number,
  buildingId: ?number,
  selection: Array<number>,
  selectionPhrase: string,
  onResultUpdate: OnResultUpdateFunc,
  onSuccess: () => Promise<void> | void,
) {
  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [addingError, setAddingError] = useState<string>('');
  const [previosSellPointTtile, setPreviosSellPointTtile] = useState('');

  /**
   *  Запрос для операций с точками
   */

  const handleRequest = useCallback(async (
    data: Object,
    errorString: string,
    isArray?: boolean = false,
  ) => {
    const requestPath = isArray
      ? `food/schools/${orgId}/sale_points/deleted`
      : `food/schools/${orgId}/sale_points`;
    const type = 'put';
    const rest = {
      data,
    };

    try {
      setProcessing(true);
      const { data: res } = await api(requestPath, type, rest);
      const sellPointsRes: SellPoints = (isArray
        ? prop('sale_points', res)
        : omit(['result'], res)
      ) || defaultResult;
      onResultUpdate(sellPointsRes);
      setProcessing(false);
      onSuccess();
      return true;
    } catch (error) {
      const errorSave = {
        err: errorString,
        path: requestPath,
        type,
        rest,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));
      return false;
    }
  }, [dispatch, orgId, onResultUpdate, onSuccess]);

  /**
   *  Запрос создания точки продаж
   */

  const handleAddSellPoint = useCallback(async (title: string) => {
    if (!buildingId) return false;
    const requestPath = `food/schools/${orgId}/sale_points`;
    const type = 'post';
    const rest = {
      data: {
        title,
        building_id: buildingId,
      },
    };

    try {
      setProcessing(true);
      const { data: res } = await api(requestPath, type, rest);
      const sellPointsRes: SellPoints = omit(['result'], res) || defaultResult;
      onResultUpdate(sellPointsRes);
      setProcessing(false);
      dispatch(popup(replace(/%target%/, title, lang.popupAddSuccess)));
      setPreviosSellPointTtile('');
      setAddingError('');
      onSuccess();
      return true;
    } catch (error) {
      const message = path(['response', 'data', 'message'], error);

      const errorString = cond([
        [equals('sale point exist'), always('Точка продаж уже существует, попробуйте другое название')],
        [equals('sale point deleted'), always('Точка продаж уже существует в удаленных. Вы можете восстановить ее')],
        [T, always('Не удалось создать точку продаж')],
      ])(message);

      const errorSave = {
        err: errorString,
        path: requestPath,
        type,
        error: clone(error),
      };
      dispatch(action('REQUEST_FAILURE', errorSave));

      setPreviosSellPointTtile(title);
      setAddingError(cond([
        [equals('sale point exist'), always('exist')],
        [equals('sale point deleted'), always('exist_deleted')],
        [T, always('other')],
      ])(message));
      return false;
    }
  }, [dispatch, orgId, onResultUpdate, buildingId, onSuccess]);

  /**
   *  Остальные запросы
   */

  const handleDeleteSellPoint = useCallback((id: number) => {
    if (handleRequest({ id, deleted: true }, 'Не удалось удалить точку продаж')) {
      dispatch(popup(lang.popupDeleteItemSuccess));
    }
  }, [dispatch, handleRequest]);

  const handleRestoreSellPoint = useCallback((id: number) => {
    if (handleRequest({ id, deleted: false }, 'Не удались восстановить точку продаж')) {
      dispatch(popup(lang.popupRestoreItemSuccess));
    }
  }, [dispatch, handleRequest]);

  const handleDeleteSellPoints = useCallback(() => {
    if (handleRequest({ id: selection, deleted: true }, 'Не удалось удалить точки продаж', true)) {
      dispatch(popup(prepareSuccessString(lang.popupDeleteArraySuccess, selectionPhrase)));
    }
  }, [dispatch, handleRequest, selection, selectionPhrase]);

  const handleRestoreSellPoints = useCallback(() => {
    if (handleRequest({ id: selection, deleted: false }, 'Не удалось востановить точки продаж', true)) {
      dispatch(popup(prepareSuccessString(lang.popupRestoreArraySuccess, selectionPhrase)));
    }
  }, [dispatch, handleRequest, selection, selectionPhrase]);

  const handleReplaceSellPointName = useCallback(({ id, title }: { id: number, title: string }) => {
    if (handleRequest({ id, title }, 'Не удалось изменить имя точки продаж')) {
      dispatch(popup(lang.popupTitleSuccess));
    }
  }, [dispatch, handleRequest]);


  return {
    processing,
    handleSellPointUpdateRequest: handleRequest,
    handleAddSellPoint,
    handleDeleteSellPoint,
    handleRestoreSellPoint,
    handleDeleteSellPoints,
    handleRestoreSellPoints,
    handleReplaceSellPointName,
    addingError,
    resetAddingError: setAddingError,
    previosSellPointTtile,
  };
}
