// @flow
import { type CountryCode } from './types';


const mask = {
  ru: '+7__________',
  kz: '+7__________',
  kg: '+996_________',
  ua: '+380_________',

  apply(str: string, type?: CountryCode = 'ru'): string {
    const msk = this[type];
    const lenCorrection = msk.length - str.length;
    return msk.split('').reduceRight((acc, value, index) => (
      (value === '_' ? str[index - lenCorrection] : value) + acc
    ), '');
  },
};

export default mask;
