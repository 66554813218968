// @flow
import React from 'react';

import { Tr, Td } from 'app/common/ui-components/table';

import type { PurchaseItem } from './apiResult2PurchaseItems';
import { ids } from './columns';


type Props = {
  disabled: boolean,
  ...PurchaseItem,
}

const OOPTableRow = ({ disabled, ...purchaseItemProps }: Props) => {
  return (
    <Tr disabled={disabled}>
      {ids.map(colId => (
        <Td key={colId}>
          {purchaseItemProps[colId]}
        </Td>
      ))}
    </Tr>
  );
};

export default OOPTableRow;
