import { find, reduce } from 'ramda'
import { useCallback, useState } from 'react'

import { SupportContactsType } from 'app/dataTypes/requisites'


export default function useContactRemoveConfirmation(
  data: SupportContactsType,
  removeContact: (targetID: string) => void
) {
  const [removeConfirm, setRemoveConfirm] = useState<null | string>(null)
  const [removedKind, setRemovedKind] = useState('')

  const handleRemove = useCallback((targetID: string) => {
    const { kind, samePositionValues = [] } = find(({ ID }) => (targetID === ID), data) || {}
    const confirmRequired = !!(kind || reduce((acc, { value: v }) => !!(acc || v), false, samePositionValues))
    if (confirmRequired) {
      setRemovedKind(kind || '')
      setRemoveConfirm(targetID)
      return
    }
    removeContact(targetID)
  }, [removeContact, data])

  const handleCancel = useCallback(() => {
    setRemoveConfirm(null)
  }, [])

  const handleConfirm = useCallback(() => {
    if (removeConfirm) {
      removeContact(removeConfirm)
    }
    setRemoveConfirm(null)
  }, [removeConfirm, removeContact])

  return {
    showRemoveConfirm: !!removeConfirm,
    removedItemKind: removedKind,
    remove: handleRemove,
    removeCancel: handleCancel,
    removeConfirm: handleConfirm,
  }
}
