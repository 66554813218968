// @flow
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import fromPairs from 'ramda/es/fromPairs';

import { orgsFetch } from 'app/actions/orgs';
import action from 'app/common/lib/action';
import { HIREFORM_CHANGE_FIELD } from 'app/actions/employees/hireform';

import useHireFormOptions, { type OptionsState } from './useHireFormOptions';
import useValidateStep1 from './useValidateStep1';


type Step = 1 | 2

type Actions = {
  submitStep1: () => boolean | Promise<boolean>,
  changeField: (e: SyntheticEvent<HTMLInputElement>, v?: string) => void,
  changeDateField: (date: string, id?: string) => void
}

export default function useHireForm(): [Step, OptionsState, Actions, boolean] {
  const dispatch = useDispatch();
  const [step, setStep] = useState<Step>(1);
  const [submitting, setSubmitting] = useState(false);

  const [options, updateOption, resetOptions] = useHireFormOptions();

  const validateStep1Form = useValidateStep1(options, updateOption);

  const submitStep1 = useCallback(async () => {
    setSubmitting(true);
    const validStep1 = await validateStep1Form();
    if (validStep1) {
      // возможно это стоит перенести в след шаг
      const orgsReady = await dispatch(orgsFetch());
      if (orgsReady) {
        setStep(2);
      }
    }
    setSubmitting(false);
    return false; // всегда false, окно не закрывается
  }, [validateStep1Form, dispatch]);

  const handleChangeField = useCallback((e: SyntheticEvent<HTMLInputElement>, clearedValue?: string) => {
    const { name, value } = e.currentTarget;
    resetOptions();
    if (name === 'phone' && clearedValue) {
      dispatch(action(HIREFORM_CHANGE_FIELD, fromPairs([[name, clearedValue]])));
      return;
    }
    dispatch(action(HIREFORM_CHANGE_FIELD, fromPairs([[name, value]])));
  }, [dispatch, resetOptions]);

  const handleDateValueChange = useCallback((date: string, id?: string) => {
    resetOptions();
    if (id) {
      dispatch(action(HIREFORM_CHANGE_FIELD, { [id]: date }));
    }
  }, [dispatch, resetOptions]);


  return [step, options, {
    submitStep1,
    changeField: handleChangeField,
    changeDateField: handleDateValueChange,
  }, submitting];
}
