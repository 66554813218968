// @flow
import { useCallback, useState, useEffect } from 'react';
import prop from 'ramda/es/prop';
import isEmpty from 'ramda/es/isEmpty';

import { api } from 'app/api';

import {
  GET_EMPLOYEES,
  type GetEmployeesRequest,
  type Employees,
} from 'app/common/api/requests/food/employees';


type State = {
  employees: Employees,
  count: number,
  loading: boolean,
  loadingItem: null | number,
}

type Actions = {
  updateEmployees: (employeeId?: number) => Promise<void> | void,
  requestEmployees: () => Promise<void>
}

type Options = {
  params: ?GetEmployeesRequest,
  onPageReset: () => void,
}

export default function useEmployeesData({
  params,
  onPageReset,
}: Options): [State, Actions] {
  const [employees, setEmployees] = useState<Employees>({});

  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingItem, setLoadingItem] = useState<number | null>(null);


  const handleRequestEmployees = useCallback(async () => {
    if (!params) return;
    setLoading(true);
    const res = await api.request(GET_EMPLOYEES, {
      error: 'Не удалось получить данные сотрудников',
      params,
    });

    if (res) {
      const { offset } = params;
      const employeesRes = prop('employees', res);
      const countRes = prop('count', res);
      if (offset && countRes && isEmpty(employeesRes)) {
        onPageReset();
        return;
      }
      setEmployees(employeesRes);
      setCount(countRes);
    }
    setLoading(false);
  }, [params, onPageReset]);


  useEffect(() => {
    handleRequestEmployees();
  }, [handleRequestEmployees]);


  const handleUpdateEmployees = useCallback(async (employeeId?: number) => {
    if (!employeeId) {
      handleRequestEmployees();
      return;
    }
    setLoadingItem(employeeId);
    const res = await api.request(GET_EMPLOYEES, {
      error: 'Не удалось обновить таблицу, после внесенных изменений',
      params: { id: employeeId },
    });

    if (res) {
      const employeesRes = prop('employees', res);
      setEmployees(state => ({ ...state, ...employeesRes }));
    }
    setLoadingItem(null);
  }, [handleRequestEmployees]);

  return [{
    employees,
    count,
    loading,
    loadingItem,
  }, {
    updateEmployees: handleUpdateEmployees,
    requestEmployees: handleRequestEmployees,
  }];
}
