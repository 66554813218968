// @flow
import { useState, useCallback, useEffect } from 'react';
import pipe from 'ramda/es/pipe';
import when from 'ramda/es/when';
import propEq from 'ramda/es/propEq';
import assoc from 'ramda/es/assoc';
import omit from 'ramda/es/omit';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';

import { api } from 'app/api';
import { GET_COMPLEXES, type GetComplexesParams } from 'app/common/api/requests/food/schools/complexes';
import { POST_COMPLEX_RESTORE } from 'app/common/api/requests/food/schools/complexes/restore';
import { POST_COMPLEX_TOGGLE_VISIBILITY } from 'app/common/api/requests/food/schools/complexes/toggle_visibility';
import usePopup from 'app/common/hooks/usePopup';
import { useCurrencySign } from 'app/common/components-next/currency';

import kits2SimpleItems, { type Item } from './kits2SimpleItems';

/**
 *  Данные для простого отображения комплектов (удаленные и скрытые)
 */

type Options = {
  type: 'hidden' | 'deleted',
}

type Actions = {
  restore: (id: number, title: string) => Promise<boolean>,
}

export default function useKitsSimple(orgId: string, options: Options): [Array<Item>, boolean, Actions] {
  const { type } = options;
  const popup = usePopup();
  const currency = useCurrencySign();

  const [items, setItems] = useState<Array<Item>>([]);
  const [loading, setLoading] = useState(true);

  const handleRequest = useCallback(async () => {
    const params: GetComplexesParams = pipe(
      when(propEq('type', 'deleted'), assoc('deleted', true)),
      when(propEq('type', 'hidden'), assoc('visible', false)),
      omit(['type']),
    )({ type });

    setLoading(true);
    const res = await api.request(GET_COMPLEXES, {
      error: 'Не удалось получить данные по комплектам',
      requestPathParams: {
        orgId,
      },
      params,
    });
    setLoading(false);

    if (res) {
      setItems(kits2SimpleItems(res, currency));
    }
  }, [type, orgId, currency]);

  useEffect(() => {
    handleRequest();
  }, [handleRequest]);


  const handleRestore = useCallback(async (id: number, title: string) => {
    // если в списке удаленных
    if (type === 'deleted') {
      const res = await api.request(POST_COMPLEX_RESTORE, {
        error: `Не удалось восстановить комплект «${title}»`,
        requestPathParams: {
          orgId,
          complexId: id,
        },
      });
      if (res) {
        setItems(filter(complement(propEq('id', id))));
        popup(`Комплект «${title}» успешно восстановлен`);
      }
      return Boolean(res);
    }
    // если в списке скрытых
    if (type === 'hidden') {
      const res = await api.request(POST_COMPLEX_TOGGLE_VISIBILITY, {
        error: `Не удалось восстановить комплект «${title}»`,
        requestPathParams: {
          orgId,
          complexId: id,
        },
      });
      if (res) {
        setItems(filter(complement(propEq('id', id))));
        popup(`Комплект «${title}» успешно восстановлен`);
      }
      return Boolean(res);
    }
    return false;
  }, [type, orgId, popup]);


  return [
    items,
    loading, {
      restore: handleRestore,
    }];
}
