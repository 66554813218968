/* eslint-disable ft-flow/no-types-missing-file-annotation */
import { EMPLOYEE_SUCCESS } from 'app/actions/employees/employee';
import { EMPLOYEE_EDIT_SUCCESS } from 'app/actions/employees/employee/edit';
import { EMPLOYEEFORM_SUBMIT_SUCCESS } from 'app/actions/employees/employee/employeeform';


export const defaultState = {
  id: null,
  photo_url: '',
  name: {
    first: '',
    middle: '',
    last: '',
  },
  gender: 'male',
  birthdate: '',
  passport: {
    series: '',
    num: '',
    date: '',
    place: '',
    birthplace: '',
  },
  inn: '',
  snils: '',
  medical: '',
  phone: '',
  workphone: '',
  homephone: '',
  email: '',
  address: {
    country: '',
    subject: '',
    town: '',
    street: '',
    index: '',
    house: '',
    corp: '',
    apart: '',
  },
};

const fields = (state = defaultState, action) => {
  if (action.type === EMPLOYEE_EDIT_SUCCESS) {
    return ({ ...defaultState, ...action.payload });
  }
  if (action.type === EMPLOYEE_SUCCESS) {
    return ({ ...defaultState, ...action.payload });
  }
  if (action.type === EMPLOYEEFORM_SUBMIT_SUCCESS) {
    return ({ ...defaultState, ...action.payload });
  }
  return state;
};

export default fields;
type EmployeeFields = typeof defaultState;
export type { EmployeeFields };
