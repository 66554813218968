// @flow
import { useState, useCallback } from 'react';


export type Sort = {
  sortDirection: 'up' | 'down',
  sortKey: string,
}

export default function useSortState(defaultSortDirection: 'up' | 'down', defaultSortKey: string): [
  Sort,
  (direction: 'up' | 'down', key: string) => void,
] {
  const [sort, setSort] = useState<Sort>({
    sortKey: defaultSortKey,
    sortDirection: defaultSortDirection,
  });

  const handleSort = useCallback((direction: 'up' | 'down', key: string) => {
    setSort({
      sortKey: key,
      sortDirection: direction,
    });
  }, []);


  return [sort, handleSort];
}

export const sortRequest = (key: string, sort: Sort) => {
  const { sortKey } = sort;
  if (sortKey === key) {
    return sort;
  }
  return { sortKey: key };
};
