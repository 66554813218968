// @flow
import { useState, useEffect } from 'react';

import { useFilterData } from 'app/common/ui-components/filter';
import { flags as details } from 'app/components/common/reports/filters/details';

import { flags as account } from '../ReportFilter/filters/account';
import { flags as group } from '../ReportFilter/filters/group';
import { flags as groupComplexesSpecial } from '../ReportFilter/filters/groupComplexesSpecial';


/**
 *  Управление отображением данных
 */

export type DisplaySettings = {
  showAccountNumber: boolean,
  showPhone: boolean,
  byGroupComplexes: boolean,
  byCampComplexes: boolean,
}

const defaultState: DisplaySettings = {
  showAccountNumber: false,
  showPhone: false,
  byGroupComplexes: false,
  byCampComplexes: false,
};

export default function useDisplaySettings(ready: boolean): DisplaySettings {
  const [, , , { getApplied }] = useFilterData();
  const [settings, setSettings] = useState(defaultState);

  useEffect(() => {
    if (ready) {
      const applied = getApplied();

      if (applied) {
        setSettings({
          showAccountNumber: account.accountNumberApplied(applied),
          showPhone: account.phoneApplied(applied),
          byGroupComplexes: (
            group.byGroupComplexesApplied(applied)
            || groupComplexesSpecial.byGroupComplexesSpecialApplied(applied)
          ),
          byCampComplexes: (
            group.byCampComplexesApplied(applied)
            || groupComplexesSpecial.byCampComplexesSpecialApplied(applied)
          ),
          details: details.detailsApplied(applied)
        });
      }
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  return settings;
}
