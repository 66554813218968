// @flow
import { reportTypes as subReports } from 'app/common/api/requests/reports/perform';

import { dataKey as accountingDataKey } from './_Accounting/dataKey';
import { dataKey as balanceDataKey } from './_Balance/dataKey';
import { dataKey as salesDataKey } from './_Sales/dataKey';
import { dataKey as salesByFundsDataKey } from './_SalesByFunds/dataKey';
import { dataKey as subsidiesDataKey } from './_Subsidies/dataKey';
import { dataKey as salesByGroupComplexesDataKey } from './_SalesByGroupComplexes/dataKey';
import { dataKey as purchasesByPaymentModeDataKey } from './_PurchasesByPaymentMode/dataKey';
import { dataKey as correctionsDataKey } from './_Corrections/dataKey';

import {
  getSubReports as getSubsidiesSubReports,
  getSubreportTitles as getSubsidiesSubReportTitles,
} from './_Subsidies/getSubReports';


const reports = new Map([
  [accountingDataKey, {
    key: accountingDataKey,
    title: 'Учет выдачи',
    description: 'В отчете «Учет выдачи» можно посмотреть информацию по количеству и сумме выданной продукции с применением различных фильтров и за любой период времени. Доступна информация о непроведенных заявках сотрудниками заведений. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
  [balanceDataKey, {
    key: balanceDataKey,
    title: 'Остаток средств',
    description: 'В отчете «Остаток средств» можно посмотреть состояние лицевых счетов пользователей как на текущий момент, так и на момент любой даты с предыдущих дней с применением различных фильтров. Доступно несколько видов группировок информации. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
  [salesDataKey, {
    key: salesDataKey,
    title: 'Продажи',
    description: 'В отчете «Продажи» можно посмотреть информацию по всем видам продаж с применением различных фильтров и за любой период времени. Доступно несколько видов группировок информации. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
  [salesByGroupComplexesDataKey, {
    key: salesByGroupComplexesDataKey,
    title: 'Продажи по виду продукции',
    description: 'В отчете «Продажи по виду продукции» можно посмотреть информацию по продажам типа «Комплексное питание» с применением различных фильтров и за любой период времени. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
  [salesByFundsDataKey, {
    key: salesByFundsDataKey,
    title: 'Продажи по видам средств',
    description: 'В отчете «Продажи по видам средств» можно посмотреть информацию для формирования группового талона, с разбивкой сумм продаж на родительские и бюджетные средства. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
  [subsidiesDataKey, {
    key: subsidiesDataKey,
    title: 'Дотации',
    description: 'В отчете «Дотации» можно посмотреть информацию по льготному питанию с применением различных фильтров и за любой период времени. Доступно несколько видов группировок информации. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
  [purchasesByPaymentModeDataKey, {
    key: purchasesByPaymentModeDataKey,
    title: 'Продажи по способу расчета',
    description: 'В отчете «Продажи по способу расчета» можно посмотреть продажи по каждому способу расчета: “Внутренний счет”, “Наличные” и “Банковская карта”. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
  [correctionsDataKey, {
    key: correctionsDataKey,
    title: 'Корректировки',
    description: 'В отчете «Корректировки» можно посмотреть изменения по продажам и дотациям, сделанные задним числом. Имеется возможность выгрузить результат в файл в формате excel.',
  }],
]);


export const reportsArrange = [
  balanceDataKey,
  subsidiesDataKey,
  salesDataKey,
  salesByGroupComplexesDataKey,
  accountingDataKey,
  salesByFundsDataKey,
  purchasesByPaymentModeDataKey,
  correctionsDataKey,
];


export const getReportTitle = (report: string): string => {
  const reportInfo = reports.get(report);
  return reportInfo ? reportInfo.title : 'Неизвестный';
};

export const getReportDescription = (report: string): string => {
  const reportInfo = reports.get(report);
  return reportInfo ? reportInfo.description : '…';
};


const titles = {};

reports.forEach(({ title }, key) => {
  titles[key] = title;
});

export const getReportTitlesAll = () => titles;


export const getSubReportsInReport = (report: string): Array<string> => {
  if (report === accountingDataKey) {
    return [subReports.REPORT_ACCOUNTING_CUSTOMERS];
  }
  if (report === balanceDataKey) {
    return [subReports.REPORT_BALANCES];
  }
  if (report === salesDataKey) {
    return [subReports.REPORT_PURCHASES];
  }
  if (report === salesByGroupComplexesDataKey) {
    return [subReports.REPORT_PURCHASES_BY_GROUP_COMPLEXES_2];
  }
  if (report === salesByFundsDataKey) {
    return [subReports.REPORT_PURCHASES_BY_FUNDS];
  }
  if (report === purchasesByPaymentModeDataKey) {
    return [subReports.REPORT_PURCHASES_BY_PAYMENT_MODE];
  }
  if (report === correctionsDataKey) {
    return [correctionsDataKey]; // TODO convert to ts & use enum ReportType for new reports
  }
  if (report === subsidiesDataKey) {
    return getSubsidiesSubReports();
  }
  return [];
};

export const getSubReportsInReportTitles = (report: string): Array<string> => {
  if (report === subsidiesDataKey) {
    return getSubsidiesSubReportTitles();
  }
  return [];
};
