import { useCallback, useEffect } from 'react'

import { Loading } from 'app/common/templates-next/page-template'
import { CampSettings } from 'app/dataTypes/subsidy/settings'

import { CategoryCampSettingsForm } from './CategoryCampSettingsForm'
import { useCampErrors } from './useCampErrors'
import { extractCampAccrualFor } from './extractCampAccrualFor'
import { extractCampAccrual } from './extractCampAccrual'
import { useCategorySettingsSubmit } from './useCategorySettingsSubmit'
import { useFormPortal } from './useFormPortal'


type Props = {
  ID: string
  orgID: string,
  settings: CampSettings
  loading?: boolean
  onDataUpdate: () => void
  onCreateFormID: (ID: string) => string
  updateSize?: () => void
  onSuccess?: () => void
  onPending?: (pending: boolean) => void
}

export const CategoryCampSettings = ({
  ID,
  orgID,
  settings,
  loading,
  onDataUpdate,
  onCreateFormID,
  updateSize,
  onSuccess,
  onPending,
}: Props) => {
  const formID = onCreateFormID(ID)

  const { errors, checkErrors, resetErrors } = useCampErrors()

  const extractData = useCallback((formData: FormData) => {
    const accrual = extractCampAccrual(formData)
    const accrualFor = extractCampAccrualFor(formData)
    return {
      app: 'camp' as const,
      accrual,
      accrualFor,
    }
  }, [])

  const { submit, submitting } = useCategorySettingsSubmit({
    allowanceID: ID,
    orgID,
    formID,
    onSuccess,
    onPending,
    onCheckErrors: checkErrors,
    onExtractData: extractData,
  })

  useEffect(() => {
    onDataUpdate()
  }, [settings]) // eslint-disable-line react-hooks/exhaustive-deps

  const formPortal = useFormPortal(formID, submit)

  return (
    <div>
      {loading
        ? <Loading />
        : <>
          <CategoryCampSettingsForm
            ID={ID}
            orgID={orgID}
            formID={formID}
            settings={settings}
            disabled={submitting}
            errors={errors}
            resetErrors={resetErrors}
            updateSize={updateSize}
          />
          {formPortal}
        </>}
    </div>
  )
}
