import { useSyncExternalStore } from 'react'


class SideNavigationState {
  private _subscribers: Set<() => void> = new Set()

  constructor(
    private _status: 'wide' | 'narrow',
    private _lock = false,
  ) {}

  subscribe = (callback: () => void) => {
    this._subscribers.add(callback)
    return () => {
      this._subscribers.delete(callback)
    }
  }

  updateSubscribers = () => {
    this._subscribers.forEach(callback => callback())
  }

  getStatus = () => this._status

  getLock = () => this._lock

  setStatus = (status: 'wide' | 'narrow') => {
    this._status = status
    this.updateSubscribers()
  }

  toggleStatus = () => {
    this._status = this._status === 'wide' ? 'narrow' : 'wide'
    this.updateSubscribers()
  }

  setLock = (lock: boolean) => {
    this._lock = lock
    this.updateSubscribers()
  }
}

const sideNavigationState = new SideNavigationState('narrow', false)

export function useSideNavigationGlobalState() {
  const status = useSyncExternalStore<'wide' | 'narrow'>(
    sideNavigationState.subscribe,
    () => sideNavigationState.getStatus(),
  )
  const lock = useSyncExternalStore<boolean>(
    sideNavigationState.subscribe,
    () => sideNavigationState.getLock(),
  )

  return {
    status,
    lock,
    setStatus: sideNavigationState.setStatus,
    toggleStatus: sideNavigationState.toggleStatus,
    setLock: sideNavigationState.setLock,
  }
}
