// @flow

export const ERROR_FROM = 'machineId_from';
export const ERROR_TO = 'machineId_to';

const errors: {
  [key: string]: string,
} = {
  [ERROR_FROM]: 'Выберите аппарат откуда копировать',
  [ERROR_TO]: 'Выберите аппарат куда копировать',
};

export const getErrorText = (key: string): string => (
  errors[key] || 'Неизвестная ошибка'
);
