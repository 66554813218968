// @flow
import { useMemo } from 'react';
import moment from 'moment-timezone';

import { useFilterData, getValue } from 'app/common/ui-components/filter';
import { reportTypes, type ReportsPerformPurchasesParams } from 'app/common/api/requests/reports/perform';

import orgsOrUserFilter from 'app/components/common/reports/filters/orgsOrUser';
import { orgsOrUser } from 'app/dataTypes/reports/orgsOrUser';

import generateParamsByOrgs from './generateParamsByOrgs';
import generateParamsByUser from './generateParamsByUser';
import generateParamsOptions from './generateParamsOptions';
import type { FilterParams } from '../FilterParams.type';
import periodFilter, { dateFormat as dateFormatPeriod } from '../ReportFilter/filters/period';


export type Params = ReportsPerformPurchasesParams | null

export default function useGenerateParams(): [Params, boolean] {
  const [,,, { getApplied }, filterParams] = useFilterData<FilterParams>();

  const res: [Params, boolean] = useMemo(() => {
    const applied = getApplied();

    if (applied) {
      const [orgsOrUserTypeSelected] = getValue(orgsOrUserFilter, applied);

      const [options, onlymail] = generateParamsOptions(applied, filterParams);

      const params = (
        orgsOrUserTypeSelected === orgsOrUser.ByOrgs
          ? generateParamsByOrgs(applied)
          : generateParamsByUser(applied)
      );

      const [, to] = getValue(periodFilter, applied);
      const mDateTo = moment(to, dateFormatPeriod);
      const aggregated = (
        params.details_group === 'by_group_complexes'
        && !mDateTo.isSame(moment(), 'month')
      );

      return [{
        report_type: (
          aggregated
            ? reportTypes.REPOST_PURCHASES_BY_GROUP_COMPLEXES_AGGREGATED
            : reportTypes.REPORT_PURCHASES
        ),
        params,
        options,
      }, onlymail];
    }
    return [null, false];
  }, [getApplied, filterParams]);

  return res;
}
