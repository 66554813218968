// @flow
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray } from 'app/common/lib/object';
import action from 'app/common/lib/action';
import * as bds from 'app/actions/reports/filters/buildings';

import { selectOrgId } from './hocs/commonSelectors';
import styles from './FilterItems.scss';


const selectIsEnableDefault = createSelector(
  [
    selectOrgId,
  ],
  orgId => Boolean(
    orgId, // выбрана школа (одна)
  ),
);

const selectBuildingsDefault = state => state.reports.filters.buildings.data;

type BuildingsTypes = {
  [key: string]: {
    id: number,
    title: string,
  }
}

type Props = {
  resetStatus: boolean,
  selectIsEnable?: (state: Object) => boolean,
}

const BuildingsFilter = ({ resetStatus, selectIsEnable }: Props) => {
  const dispatch = useDispatch();

  // данные корпусов
  const itemsObj: BuildingsTypes = useSelector(selectBuildingsDefault, shallowEqual);
  const loading = useSelector(state => state.reports.filters.buildings.loading, shallowEqual);

  // выбранное
  const selectedItems: BuildingsTypes = useSelector(state => state.reports.filters.buildings.selected, shallowEqual);

  // и массив id с выбранным
  const selectedArr: Array<number> = mapObjPropToArray('id')(selectedItems);

  // дополнительные параметры для запроса
  const orgId = useSelector(selectOrgId);


  const filterTitle = 'Корпус:';
  const filterModalTitle = 'Выбор корпуса';


  const handleReset = useCallback(() => {
    dispatch(action(bds.BUILDINGS_FILTER_SET, {}));
  }, [dispatch]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(bds.BUILDINGS_FILTER_SET, submitted));
  }, [dispatch]);

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(bds.BUILDINGS_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE и запрос данных
   */

  const isEnable = useSelector(selectIsEnable || selectIsEnableDefault);

  useEffect(() => {
    dispatch(action(bds.BUILDINGS_FILTER_UPDATE, { active: isEnable }));

    if (isEnable) {
      (async () => {
        const res = await dispatch(bds.buildingsFilterFetch(orgId));
        if (res) {
          handleReset();
        }
      })();
    }
  }, [isEnable, orgId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isEnable) {
    return null;
  }


  return (
    <PlainFilterComplete
      id="buildings"
      loading={loading}
      className={styles.filter}
      title={filterTitle}
      modalTitle={filterModalTitle}
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default BuildingsFilter;
