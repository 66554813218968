// @flow
import React, { type Ref } from 'react';

import { Tr, Td, getPropsHeader } from 'app/common/ui-components/table';

import type { ColumnProps } from './columns';
import { type ActualItem } from './useActualItems';

import styles from './Row.scss';


type Props = {
  reference?: Ref<'tr'>,
  columns: Array<ColumnProps>,
  getColumnDataById: (id: $Keys<ActualItem>) => string,
}

const Row = ({ reference, columns, getColumnDataById }: Props) => {
  const headerLinkProps = getPropsHeader();

  return (
    <Tr reference={reference} className={styles.row}>
      {columns.map(({ id, props }) => (
        <Td
          key={id}
          {...headerLinkProps(id)}
          {...props}
        >
          {getColumnDataById(id)}
        </Td>
      ))}
    </Tr>
  );
};

export default Row;
