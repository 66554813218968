import { forwardRef } from 'react'
import { Tr, Th } from 'app/common/ui-components/table'

import { Column } from './columns'
import { OrgsListGear } from '../CompensationStatementsGear'

import styles from './TableHeader.scss'


type Props = {
  columns: Array<Column>
  titles: Record<Column, string>
  stuck: boolean
  shadow: boolean
  columnProps: Record<Column, {
    className?: string
    alignLeft?: boolean
    alignRight?: boolean
    alignCenter?: boolean
  }>
}

export const TableHeader = forwardRef<'tr', Props>(({
  columns,
  titles,
  stuck,
  shadow,
  columnProps,
}: Props, ref) => {
  return (
    <Tr reference={ref} header>
      {columns.map((key, index) => (
        <Th
          key={key}
          bgFilled={stuck}
          borderShadowBottom={shadow}
          className={`index_${index}`}
          {...columnProps[key]}
        >
          {key === Column.Action
            ? <div className={styles.gearCell}>
              {titles[key]}
              <OrgsListGear className={styles.gear} />
            </div>
            : titles[key]}
        </Th>
      ))}
    </Tr>
  )
})
