// @flow
import React, { type Node, Fragment, useState, useCallback } from 'react';

import { PUT_PLANORGAM_SLOTS_CONTENT, convertParams, convertResult } from 'app/common/api/requests/data/vending/machines/slots/content/put';
import { api } from 'app/api';
import type { PlanogramContent } from 'app/dataTypes/vending/PlanogramContent.types';
import type { ProductData, SlotData } from 'app/common/ui-components/planograms';

import ProductContainsModalTemplate from './ProductContainsModalTemplate';
import { defaultProduct, defaultSlot, extractNumber } from './helpers';


const extractDisableReason = (currentValue: number | null, amountMax: number): [boolean, string] => {
  if (currentValue === null) return [true, 'Введите количество добавляемой продукции'];
  if (currentValue > amountMax) return [true, 'Количество добавляемой продукции превышает возможное значение'];
  return [false, ''];
};

type Props = {
  children: ((product: ProductData, slot: SlotData) => void) => Node,
  machineId: string | null,
  onSuccess: (PlanogramContent) => void,
}

const IncreaseProduct = ({ children, machineId, onSuccess }: Props): Node => {
  const [currentSlot, setCurrentSlot] = useState<SlotData | null>(null);
  const [currentProduct, setCurrentProduct] = useState<ProductData | null>(null);

  const [currentValue, setCurrentValue] = useState<number | null>(null);
  const amountMax = extractNumber('amountMax', currentSlot);
  const amount = extractNumber('amount', currentSlot);

  const handleClickIncreaseProduct = useCallback((product: ProductData, slot: SlotData) => {
    setCurrentSlot(slot);
    setCurrentProduct(product);
  }, []);

  const handleDisplayStatusChange = useCallback((display: boolean) => {
    if (!display) {
      setCurrentSlot(null);
      setCurrentProduct(null);
    }
  }, []);

  const fillСompletely = useCallback(() => {
    setCurrentValue(amountMax - amount);
  }, [amountMax, amount]);

  const handleSubmit = useCallback(async () => {
    if (currentValue === null || !currentProduct || !currentSlot || !machineId) {
      return false;
    }

    const res = await api.request(PUT_PLANORGAM_SLOTS_CONTENT, {
      error: 'Не удалось подгрузить товар',
      params: {
        machineId,
        data: [{
          slotId: currentSlot.id,
          productId: currentProduct.id,
          amount: amount + currentValue,
        }],
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      onSuccess(res);
      setCurrentValue(null);
      return true;
    }
    return false;
  }, [currentProduct, currentSlot, machineId, onSuccess, currentValue, amount]);

  const [disableSubmit, disableSubmitReason] = extractDisableReason(currentValue, amountMax);

  const newAmount = currentValue !== null ? amount + currentValue : 0;
  const valueError = newAmount > amountMax || newAmount < 0;

  return (
    <Fragment>
      {children(handleClickIncreaseProduct)}

      <ProductContainsModalTemplate
        header="Подгрузить"
        onSubmit={handleSubmit}
        show={!!currentSlot}
        slotData={currentSlot || defaultSlot}
        productData={currentProduct || defaultProduct}
        disableSubmit={disableSubmit}
        disableSubmitReason={disableSubmitReason}
        value={currentValue}
        willBeValue={amount + (currentValue || 0)}
        valueError={valueError}
        specialButtonTitle="Заполнить на 100%"
        onChange={setCurrentValue}
        onClickSpecialButton={fillСompletely}
        onDisplayStatusChange={handleDisplayStatusChange}
      />
    </Fragment>
  );
};

export default IncreaseProduct;
