import { useCallback } from 'react'


const scrollToClass = 'scrollToError' as const

export const getScrollToErrorClassName = (error: boolean = true) => {
  if (error) {
    return scrollToClass as string
  }
  return undefined
}

const useScrollToError = () => {
  const scrollToError = useCallback(() => {
    setTimeout(() => {
      const erElements = document.getElementsByClassName(scrollToClass)
      if (erElements && erElements[0]) {
        erElements[0].scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' })
      }
    }, 500)
  }, [])

  return scrollToError
}

export default useScrollToError
