import api from 'app/actions/api';
import { LOG_NAVIGATE } from 'app/common/constants/logMatches';

export const LOG_SAVE_REQUEST = 'LOG_SAVE_REQUEST';
export const LOG_SAVE_SUCCESS = 'LOG_SAVE_SUCCESS';
export const LOG_SAVE_FAILURE = 'LOG_SAVE_FAILURE';


const byAppType = {
  food: 'food',
};

const logSaveRequest = payload => ({
  type: LOG_SAVE_REQUEST,
  payload,
});

const logSaveSuccess = payload => ({
  type: LOG_SAVE_SUCCESS,
  payload,
});

const logSaveFailure = payload => ({
  type: LOG_SAVE_FAILURE,
  payload,
});

const saveLog = (kindId, actionId) => async (dispatch) => {
  const data = {
    kind_id: kindId,
    action_id: actionId,
  };

  dispatch(logSaveRequest(data));
  try {
    await api(`${byAppType[(process.env.APP_NAME || process.env.REACT_APP_NAME)] || (process.env.APP_NAME || process.env.REACT_APP_NAME)}/actions`, 'post', { data });
    dispatch(logSaveSuccess(data));
  } catch (error) {
    dispatch(logSaveFailure({ data, error }));
    // do nothing
  }
};

export const saveNavigationLog = actionId => saveLog(LOG_NAVIGATE, actionId);
