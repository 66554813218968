// @flow
import React, { type Node, useState, useCallback } from 'react';
import classNames from 'classnames/bind';
import prop from 'ramda/es/prop';
import map from 'ramda/es/map';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';

import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainModal, { PlainModalHeader, PlainModalContent, PlainModalFooter } from 'app/common/ui-next/plain-modal';
import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import nope from 'app/common/lib/nope';
import { type OrgsSchools } from 'app/common/api/requests/food/schools';

import styles from './MultipleSubmit.scss';


const cx = classNames.bind(styles);

/**
 *  Окно массовой отправки запросов.
 *  В окне выбираются организации, для которых будут вызванны переданные запросы
 */

const defaultChoosenOrgsState: Array<number> = [];

type Props = {
  orgs: OrgsSchools, // use OrgsDataProvider and useOrgs
  header: string,
  buttonOkTitle?: string,
  buttonOkTitleSubmitting?: string,
  buttonCancelTitle?: string,
  emptyOrgsMessage?: string,
  orgsSelectorTitle?: string,
  checkboxSaveOnlyLabel?: string, // если не задано, то первый чекбокс не появится
  checkboxRevealOrgsSelectorLabel?: string, // если не задано, то второй чекбокс не появится, но выбор организации появится
  additionalContent?: Node,
  disableMultiple?: boolean,
  multipleAfterContent?: Node,
  submitting?: boolean,
  loading?: boolean,
  skipSelection?: boolean,
  onClickOk: ((arr: Array<number>) => void) | Promise<void>,
  onClickCancel: () => void,
}

const MultipleSubmit = ({
  orgs,
  header,
  buttonOkTitle = 'Применить',
  buttonOkTitleSubmitting = 'Применяется...',
  buttonCancelTitle = 'Отмена',
  emptyOrgsMessage = 'Заведения отсутствуют',
  orgsSelectorTitle = 'Заведения:',
  checkboxSaveOnlyLabel,
  checkboxRevealOrgsSelectorLabel,
  additionalContent,
  disableMultiple,
  multipleAfterContent,
  submitting,
  loading,
  skipSelection,
  onClickOk,
  onClickCancel,
}: Props) => {
  const [chosenOrgs, setChosenOrgs] = useState<Array<number>>(defaultChoosenOrgsState);
  const nothingSelected = isEmpty(chosenOrgs) && isEmpty(orgs) && !skipSelection;

  // управление видимостью селектора организаций
  const [showOrgsSelector, setShowOrgsSelector] = useState<boolean>(!checkboxRevealOrgsSelectorLabel);
  const handleShowOrgsSelector = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setShowOrgsSelector(checked);
  }, []);


  // управление списком выбранного
  const handleChangeOrgs = useCallback((submitted) => {
    setChosenOrgs(map(prop('id'), values(submitted)));
  }, []);

  const handleResetOrgs = useCallback(() => {
    setChosenOrgs(defaultChoosenOrgsState);
  }, []);


  // отправка выбранного
  const handleSubmit = useCallback(() => {
    // сохранить только в текущей org (не нажата галка)
    if (!showOrgsSelector) {
      onClickOk([]);
      return false;
    }
    // сохранить во всех (нажата галка, но ничего не выбранно, по умолчанию 'все')
    if (isEmpty(chosenOrgs) && !isEmpty(orgs)) {
      onClickOk(map(prop('id'), values(orgs)));
      return false;
    }
    // сохранить в выбранных (что-то выбрано)
    onClickOk(chosenOrgs);
    return false;
  }, [chosenOrgs, orgs, onClickOk, showOrgsSelector]);


  return (
    <PlainModal
      show
      className={styles.modalRoot}
      onSubmit={handleSubmit}
      formId="multiple_copy_form"
    >
      <PlainModalHeader onCrossClick={onClickCancel}>
        {header}
      </PlainModalHeader>

      {!skipSelection
        && <PlainModalContent>
          {checkboxSaveOnlyLabel
            && <PlainCheckbox
              label={checkboxSaveOnlyLabel}
              value="only"
              checked
              onChange={nope}
              disabled
            />}

          {checkboxRevealOrgsSelectorLabel
            && <PlainCheckbox
              label={checkboxRevealOrgsSelectorLabel}
              value="saveAll"
              checked={showOrgsSelector}
              onChange={handleShowOrgsSelector}
              disabled={disableMultiple}
            />}

          {showOrgsSelector
            && <PlainFilterComplete
              id="orgs"
              className={cx({ orgsSelectorPadded: !!checkboxRevealOrgsSelectorLabel })}
              title={checkboxRevealOrgsSelectorLabel ? '' : orgsSelectorTitle}
              modalTitle="Выбор заведения"
              itemsObj={orgs}
              selectedArr={chosenOrgs}
              onChangeRaw={handleChangeOrgs}
              onReset={handleResetOrgs}
              light={false}
              enableSelectAll
              nothingIsAll={!isEmpty(orgs)}
              loading={loading}
              disabled={submitting}
              emptyListMessage={emptyOrgsMessage}
            />}

          {multipleAfterContent}
        </PlainModalContent>}

      {additionalContent
        && <PlainModalContent className={cx({ border: !skipSelection })}>
          {additionalContent}
        </PlainModalContent>}

      <PlainModalFooter>
        <ControlsBar middle>
          <PlainButton
            onClick={onClickCancel}
            outline
          >
            {buttonCancelTitle}
          </PlainButton>
          <PlainButton
            type="submit"
            disabled={submitting || loading || nothingSelected}
          >
            {submitting ? buttonOkTitleSubmitting : buttonOkTitle}
          </PlainButton>
        </ControlsBar>
      </PlainModalFooter>
    </PlainModal>
  );
};

export default MultipleSubmit;
