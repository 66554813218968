// @flow
import * as appTypes from './appTypes';


type AppType = $Values<typeof appTypes>

class ApiString {
  defURL: string = '';

  overrides: { [key: AppType]: string } = {};

  replaces: { [key: string]: string } = {};

  localStorageReplacers: Array<string> = [];

  constructor(
    url: string,
    // для переопределения адреса api в соответствии кабинету
    overrides?: { [key: AppType]: string },
  ) {
    this.defURL = url;
    if (overrides) {
      this.overrides = overrides;
    }
  }

  get value() {
    const appName: AppType | void = (((process.env.APP_NAME || process.env.REACT_APP_NAME): any): AppType);

    let i = 0;
    while (this.localStorageReplacers[i]) {
      const replaceUrl = localStorage.getItem(this.localStorageReplacers[i++]);
      if (replaceUrl) return replaceUrl;
    }

    if (appName && this.replaces[document.location.hostname]) {
      return this.replaces[document.location.hostname];
    }

    if (appName && this.overrides[appName]) {
      return this.overrides[appName];
    }

    return this.defURL;
  }

  addReplacer(key: string, value: string) {
    this.replaces = { ...this.replaces, [key]: value };
  }

  addLocalStorageReplacer(lsKey: string) {
    this.localStorageReplacers = [...this.localStorageReplacers, lsKey];
  }
}

export default ApiString;
