// @flow
import React, { type Node } from 'react';

import Stub from 'app/common/layouts/Stub';

import ReportSection from '../ReportSection';


type Props = {
  headerBar?: Node,
}

const EmptyReport = ({ headerBar }: Props) => (<ReportSection headerBar={headerBar} paddingFree>
  <Stub />
</ReportSection>);

export default EmptyReport;
