// @flow
import React from 'react';
import cn from 'classnames';

import ErrorStubSvg from './ErrorStub.svg';
import styles from './ErrorStub.scss';


type Props = {
  className?: string,
  title?: string,
  text?: string,
}

const ErrorStub = ({
  className,
  title = 'Невозможно сформировать отчет!',
  text = 'Имеются непроведенные заявки на комплексное питание!',
}: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      <ErrorStubSvg className={styles.svg} />
      <div className={styles.content}>
        <div className={styles.header}>{title}</div>
        <div>{text}</div>
      </div>
    </div>
  );
};

export default ErrorStub;
