// @flow

/**
* Возвращает объект параметров из адресной строки
*/

const getParams = (): any => window
  .location
  .search
  .replace('?', '')
  .split('&')
  .reduce((p, e) => {
    if (!e) return p;
    const a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]); // eslint-disable-line no-param-reassign
    return p;
  }, {});

/**
 * Возвращает домен
 */

export const getDomain = (defDomain: string = 'sms-dnevnik.com'): string => {
  const parts = document.location.hostname.split('.');
  return (parts[0] === 'localhost' ? defDomain : parts.slice(1).join('.'));
};

/**
 * Удаляет параметр из адресной строки браузера без перезагрузки страницы
 */

export const removeParam = (keyToDelete: string): void => {
  const url = document.createElement('a');
  url.href = window.location.href;

  if (typeof URLSearchParams !== 'undefined') {
    const params = new URLSearchParams(url.search);
    params.delete(keyToDelete);
    url.search = params.toString();
  }

  window.history.pushState({}, '', url.href);
};

export default getParams;
