import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
// import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import length from 'ramda/es/length';
import join from 'ramda/es/join';
import remove from 'ramda/es/remove';
import pipe from 'ramda/es/pipe';
import replace from 'ramda/es/replace';
import insert from 'ramda/es/insert';
import take from 'ramda/es/take';
import takeLast from 'ramda/es/takeLast';
import test from 'ramda/es/test';

import setCaretPosition from 'app/common/lib/caretPosition';
import omitProps from 'app/common/containers/omitProps';

import PlainInput from './PlainInput';


const placeholder = '__:__';
const transformValue = pipe(
  replace(/\D/, ''),
  take(4),
  (v) => {
    const len = length(v);
    if (len === 4) {
      const hours = parseInt(take(2, v), 10) || 0;
      const minutes = parseInt(takeLast(2, v), 10) || 0;
      return `${hours < 10 ? '0' : ''}${hours > 23 ? '00' : hours}:${minutes < 10 ? '0' : ''}${minutes > 59 ? '00' : minutes}`;
    }
    return (len > 1 ? join('', insert(2, ':', v)) : v);
  },
);

export default compose(
  // mapProps(({ value, ...others }) => ({
  //   value: transformValue(value),
  //   ...others,
  // })),
  withState('caretPos', 'setCaretPos', null),
  withState('el', 'setEl', null),
  withHandlers({
    onChange: ({ onChange }) => (e) => {
      const { value } = e.currentTarget;
      if (onChange) {
        const clearedValue = transformValue(value);
        e.persist();
        e.currentTarget.value = clearedValue;
        onChange(e, clearedValue);
      }
    },
    onKeyDown: ({ el, setCaretPos }) => (e) => {
      const { key } = e;
      // частный случай удаления
      const { value } = e.currentTarget;
      if (key === 'Backspace' && el) {
        const { selectionStart, selectionEnd } = el;
        // если нажали Backspace без выделения и удаляемый символ - заполняющий символ маски
        // нужно удалить все заполняющие начиная с этого симола до символа вписаного в маску
        // включая его самого (отчет к началу)
        if (selectionStart === length(value) && placeholder[selectionStart - 1] !== '_') {
          e.preventDefault();
          let currentPos = selectionStart;
          let number = 1;
          while (currentPos > 1 && placeholder[currentPos - 1] !== '_') {
            currentPos -= 1;
            number += 1;
          }
          const newValue = join('', remove(currentPos - 1, number, value));
          el.value = newValue; // eslint-disable-line no-param-reassign
          // случай когда курсор не в конце -- просто его двигаем, не удаляя заполняющий символ
          // если символ вписанный -- то обработка по умолчанию
        } else if (placeholder[selectionStart - 1] !== '_' && selectionStart === selectionEnd) {
          e.preventDefault();
          setCaretPosition(el, selectionStart - 1);
        }
      }
      if (test(/\d/, key) && el) {
        const { selectionStart } = el;
        const pos = selectionStart === 2 ? 3 : selectionStart;
        setCaretPos(pos);
      }
      // e.preventDefault();
    },
    reference: ({ setEl }) => (el) => {
      if (el) {
        setEl(el);
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { value, el, caretPos } = this.props;
      if (prevProps.value !== value) {
        setCaretPosition(el, caretPos + 1);
      }
    },
  }),
  omitProps(['el', 'setEl', 'clearFromMaskReg', 'setCaretPos', 'caretPos']),
)(PlainInput);
