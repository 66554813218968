// @flow
import React from 'react';
import classNames from 'classnames';

import kk from 'app/common/images/flag-kz.png';

import styles from './flags.scss';


type Props = {
  className?: string,
}

const FlagKk = ({ className }: Props) => (
  <div className={classNames(styles.root, className)}>
    <img src={kk} className={styles.kk} alt="kk" />
  </div>
);

export default FlagKk;
