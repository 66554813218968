// @flow
import React from 'react';
import { Redirect } from 'react-router';

/**
 *   Компонент который отображается при отсутствии доступа к странице
 */

const AccessDenied = () => {
  return (
    <Redirect to="/orgs" />
  );
};

export default AccessDenied;
