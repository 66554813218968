// @flow
export const PACK_FILTER_SET = 'reports/PACK_FILTER_SET';
export const PACK_FILTER_CLEAR = 'reports/PACK_FILTER_CLEAR';

export const packFilterSet = (payload: boolean) => ({
  type: PACK_FILTER_SET,
  payload,
});

export const packFilterClear = () => ({
  type: PACK_FILTER_CLEAR,
});
