// @flow
import { useCallback } from 'react';

import { api } from 'app/api';
import { GET_GENERATE_FILE, formats } from 'app/common/api/requests/reports/generate_file';
import { GET_DOWNLOAD_FILE } from 'app/common/api/requests/reports/download_file';
import { GET_REPORTS_STATUS } from 'app/common/api/requests/reports/status';


type Handlers = {
  onProcessRequest: () => Promise<number | string | null>,
  onProcessCheckStatus: (reportId: number) => Promise<number | string>,
  onFileDownload: (reportId: number) => Promise<void>,
}

export default function useGenerateFileRequests(
  reportId: number | null,
  filename: string,
  fileFormat: $Values<typeof formats> = formats.XLSX,
): Handlers {
  const onProcessRequest = useCallback(async () => {
    if (!reportId) return null;
    const error = 'Не удалось запустить генерацию файла';
    const res = await api.request(GET_GENERATE_FILE, {
      error,
      params: {
        report_id: reportId,
        format: fileFormat,
      },
    });
    if (res) {
      return res;
    }
    return error;
  }, [reportId, fileFormat]);

  const onProcessCheckStatus = useCallback(async (reportId_: number) => {
    const error = 'Не удалось получить статус генерации файла';
    const res = await api.request(GET_REPORTS_STATUS, {
      error,
      params: {
        report_id: reportId_,
      },
    });

    if (typeof res === 'number') {
      return res;
    }
    return error;
  }, []);

  const onFileDownload = useCallback(async (reportId_: number) => {
    await api.download(GET_DOWNLOAD_FILE, {
      error: 'Не удалось загрузить файл',
      filename: `${filename}.${fileFormat}`,
      params: {
        report_id: reportId_,
      },
    });
  }, [filename, fileFormat]);

  return {
    onProcessRequest,
    onProcessCheckStatus,
    onFileDownload,
  };
}
