// @flow
import React, { type Node } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import PlainButton from '../../ui-next/plain-button';
import AppBar, { LeftBarContent, Logo } from '../../ui-components/app-bar';
import Footer from '../footer/footer';
import Copyright from '../footer/copyright';
import Stores from '../footer/stores';

import VersionView from './version-view';
import styles from './critical-update-dummy.scss';


type Props = {
  onUpdateButtonClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
  logoUrl: string,
  logoDescription: string,
  company: string,
  phone: string,
  androidAppUrl: string,
  iosAppUrl: string,
  updateIllustration: Node,
}

const CriticalUpdateDummy = ({
  onUpdateButtonClick,
  logoUrl,
  logoDescription,
  company,
  phone,
  androidAppUrl,
  iosAppUrl,
  updateIllustration,
}: Props): Node => {
  const { t } = useTranslation('sw');

  return (
    <section className={styles.root}>
      <AppBar>
        <LeftBarContent>
          <Logo logoUrl={logoUrl} alt={logoDescription} />
        </LeftBarContent>
      </AppBar>

      <div className={classNames(styles.container, styles.main)}>
        <div className={styles.leftContent}>
          <h1 className={styles.header}>
            {t('new_version_dummy_header', 'Доступна новая версия!')}
          </h1>
          <p>
            {t('new_version_dummy_text', 'Пожалуйста, обновите сервис для корректной работы.')}
          </p>
          <PlainButton onClick={onUpdateButtonClick}>
            {t('new_version_dummy_update_button', 'Обновить')}
          </PlainButton>
        </div>
        {updateIllustration}
      </div>

      <Footer>
        <div>
          <Copyright
            company={company}
            phone={phone}
          />
          <VersionView />
          <Stores androidAppUrl={androidAppUrl} iosAppUrl={iosAppUrl} />
        </div>
      </Footer>
    </section>
  );
};

export default CriticalUpdateDummy;
