// @flow
import React, { useCallback, useState, type Node } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';

import { useImportXLSData } from '../ImportXLSData';


type Props = {
  className?: string,
  children: Node,
  onClose: () => void,
  onSuccess: () => void | Promise<void>,
}

const ImportXLSForm = ({ className, children, onClose, onSuccess }: Props) => {
  const { content: { fileId, dataTypes: currentSelected }, api: { submit } } = useImportXLSData();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    const res = await submit();
    setSubmitting(false);

    if (res) {
      onClose();
      onSuccess();
    }
  }, [submit, onClose, onSuccess]);

  const formReady = currentSelected.some(item => !!(item));

  return (
    <form onSubmit={handleSubmit} className={className}>
      {children}

      {Boolean(fileId)
        && <PlainButton type="submit" disabled={!formReady || submitting}>
          {submitting ? 'Импорт...' : 'Импортировать'}
        </PlainButton>}
    </form>
  );
};

export default ImportXLSForm;
