// @flow
import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { PlainFilterComplete } from 'app/common/ui/PlainFilter';
import { mapObjPropToArray } from 'app/common/lib/object';
import action from 'app/common/lib/action';

import * as cmrs from 'app/actions/reports/filters/customers';
import * as orgsTypes from 'app/common/constants/schools/types';

import { selectOrgId, selectOrgsType } from './hocs/commonSelectors';
import dataObjectCreate from './helpers/dataObjectCreate';
import styles from './FilterItems.scss';

/**
 *  Фильтр по типам покупателей
 *  Для предприятий скрывается и кстанавливается выбранным "Сотрудники ОУ"
 */

type CustomerTypes = {
  [key: string]: {
    id: string,
    title: string,
  }
}

const selectIsEnableDefault = createSelector(
  selectOrgId,
  orgId => orgId,
);

const selectIsHidden = createSelector(
  selectOrgsType,
  orgsType => orgsType === orgsTypes.ENTERPRISE,
);

type Props = {
  resetStatus: boolean,
  availableCustomers?: Array<string>,
  selectIsEnable?: () => boolean,
}

const CustomersFilter = ({
  resetStatus,
  availableCustomers = ['Учащиеся', 'Сотрудники'],
  selectIsEnable = selectIsEnableDefault,
}: Props) => {
  const dispatch = useDispatch();

  // объект со всеми доступными типами
  const itemsObj: CustomerTypes = useMemo(() => {
    return dataObjectCreate(availableCustomers);
  }, [availableCustomers]);

  // выбранное
  const selectedItems: CustomerTypes = useSelector(state => state.reports.filters.customers.selected, shallowEqual);

  // и массив с выбранным
  const selectedArr: Array<string> = mapObjPropToArray('id')(selectedItems);

  // тип организации
  const orgsType = useSelector(selectOrgsType);

  // скрываем, если тип заведения Предприятие
  const isHidden = useSelector(selectIsHidden);

  const handleReset = useCallback(() => {
    // для предприятий устанавливается выбранным "Сотрудники ОУ"
    if (orgsType === orgsTypes.ENTERPRISE) {
      dispatch(action(cmrs.CUSTOMERS_FILTER_SET, dataObjectCreate(['Сотрудники ОУ'])));
      return;
    }
    // для остальных
    if (availableCustomers.length === 1) {
      dispatch(action(cmrs.CUSTOMERS_FILTER_SET, itemsObj));
      return;
    }
    dispatch(action(cmrs.CUSTOMERS_FILTER_SET, {}));
  }, [dispatch, orgsType, itemsObj, availableCustomers]);

  const handleChange = useCallback((submitted) => {
    dispatch(action(cmrs.CUSTOMERS_FILTER_SET, submitted));
  }, [dispatch]);

  useEffect(() => {
    if (availableCustomers && availableCustomers.length === 1) {
      dispatch(action(cmrs.CUSTOMERS_FILTER_SET, itemsObj));
    }
  }, [availableCustomers]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  Очистка при размонтировании
   */

  useEffect(() => () => {
    dispatch(action(cmrs.CUSTOMERS_FILTER_CLEAR));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  RESET по сигналу от предка
   */

  useEffect(() => {
    handleReset();
  }, [resetStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  ENABLE or not ENABLE и запрос данных
   */

  const isEnable = useSelector(selectIsEnable);

  useEffect(() => {
    dispatch(action(cmrs.CUSTOMERS_FILTER_UPDATE, { active: isEnable }));
    if (isEnable) {
      handleReset();
    }
  }, [isEnable]); // eslint-disable-line react-hooks/exhaustive-deps


  if (!isEnable || isHidden) {
    return null;
  }

  return (
    <PlainFilterComplete
      id="customerType"
      className={styles.filter}
      title="Тип покупателя:"
      modalTitle="Выбор типа покупателя"
      itemsObj={itemsObj}
      selectedArr={selectedArr}
      onChangeRaw={handleChange}
      onReset={handleReset}
      light={false}
      enableSelectAll
      nothingIsAll
    />
  );
};

export default CustomersFilter;
