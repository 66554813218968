import prop from 'ramda/es/prop';
import omit from 'ramda/es/omit';
import api from 'app/actions/api';

export const PROPS_REQUEST = 'props/PROPS_REQUEST';
export const PROPS_SUCCESS = 'props/PROPS_SUCCESS';
export const PROPS_FAILURE = 'props/PROPS_FAILURE';
export const PROPS_CLEAR = 'props/PROPS_CLEAR';
export const PROPS_EDIT = 'props/PROPS_EDIT';

export const propsRequest = () => ({
  type: PROPS_REQUEST,
});

export const propsSuccess = payload => ({
  type: PROPS_SUCCESS,
  payload,
});

export const propsFailure = payload => ({
  type: PROPS_FAILURE,
  payload,
});

export const propsClear = () => ({
  type: PROPS_CLEAR,
});

export const propsEdit = payload => ({
  type: PROPS_EDIT,
  payload,
});

export const propsFetch = () => async (dispatch) => {
  dispatch(propsRequest());
  try {
    const res = await api('food/requisites', 'get');
    const props = omit(['result'], prop('data', res)) || {};
    dispatch(propsSuccess(props));
    return true;
  } catch (error) {
    dispatch(propsFailure({ err: 'Не удалось получить реквизиты', error }));
    return false;
  }
};
