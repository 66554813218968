
import { OrgsLimitsType, Params } from 'app/dataTypes/org/limits'
import type { Request } from '../../../index'


type UrlParams = {
  id: number,
}

export const convertParams = ({ orgId }: Params): [null, UrlParams] => {
  return [null, { id: orgId }]
}

type ApiResult = {
  cafeteria_limits_readonly_for_schools: boolean,
  max_cafe_order_minus: number, // минусы хранятся по модулю
  max_cafe_minus: number,
  cafe_day_limit: number,
  max_camp_cafe_order_minus: number,
  split_student_day_limit: true // включено распределение лимита по точкам продаж
}

export const convertResult = (apiResult: ApiResult): OrgsLimitsType => {
  return {
    cafeteriaLimitsReadonlyForSchools: apiResult.cafeteria_limits_readonly_for_schools,
    maxCafeOrderMinus: apiResult.max_cafe_order_minus,
    maxCafeMinus: apiResult.max_cafe_minus,
    cafeDayLimit: apiResult.cafe_day_limit,
    maxCampCafeOrderMinus: apiResult.max_camp_cafe_order_minus,
    splitStudentDayLimit: apiResult.split_student_day_limit,
  }
}

export const GET_SCHOOL_LIMITS: Request<Params, OrgsLimitsType, void> = [
  'food/schools/{id}/limits',
  'get',
  ['data', 'limits'],
]
