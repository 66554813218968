// @flow
import keys from 'ramda/es/keys';
import action from 'app/common/lib/action';

import * as subsidyTypesAvailable from 'app/actions/reports/filters/subsidyTypesAvailable';


export const SUBSIDIES_RESET_PARAMS = 'reports/SUBSIDIES_RESET_PARAMS';
export const SUBSIDIES_SET_REPORT_ID = 'reports/SUBSIDIES_SET_REPORT_ID';
export const SUBSIDIES_SET_XLS_FILE_ID = 'reports/SUBSIDIES_SET_XLS_FILE_ID';
export const SUBSIDIES_VIEW_PARAMS_CREATE = 'reports/SUBSIDIES_VIEW_PARAMS_CREATE';


// Фильтры, которые влияют на отбражение присланных данных
// выносятся в отдельное место, чтоб при изменении параметров фильтра,
// он не влиял на отображение текущих данных
export const subsidiesReportViewParamsCreate = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const params = {
    details: state.reports.filters.details, // используется для показа отчета на скачку только при детализированном запросе отчета
    viewType: keys(state.reports.filters.viewTypes.selected)[0],
    subsidyType: (keys(state.reports.filters.subsidyTypes.selected)[0]
        || subsidyTypesAvailable.SUBSIDY_TYPE_ALL),
  };
  dispatch(action(SUBSIDIES_VIEW_PARAMS_CREATE, params));
};
