// @flow
import { useMemo } from 'react';

import type { Settings } from 'app/reducers/settings/orgsOrders';
import * as orgsTypes from 'app/common/constants/schools/types';


export type Columns = Array<$Keys<Settings>>

type Options = {
  settings: Settings,
  foodModeEnable: boolean,
  orgType: $Values<typeof orgsTypes>,
  placeForGuests?: boolean,
}

export function useColumnsArrangedIds({
  settings,
  foodModeEnable,
  orgType,
  placeForGuests,
}: Options): [Columns, Columns] {
  const columns = useMemo(() => {
    const allColumns = ['id', 'date', 'who', 'group', 'sch', 'num', 'total', 'opt'];
    return allColumns.filter((key) => {
      if (orgType === orgsTypes.ENTERPRISE) {
        if (!placeForGuests && key === 'group') return false;
        if (key === 'num') return false;
      }
      if (key === 'sch' && !foodModeEnable) return false;

      return true;
    });
  }, [foodModeEnable, orgType, placeForGuests]);

  const visibleColumns = useMemo(() => columns.filter(key => settings[key]), [columns, settings]);

  return [columns, visibleColumns];
}

export const useColumnsTitles = (currency: string, orgType: $Values<typeof orgsTypes>): {
  [$Keys<Settings>]: string
} => {
  const titles = useMemo(() => {
    const defaults = {
      id: '№',
      date: 'Дата',
      who: 'Кто создал',
      group: 'Класс/Группа',
      sch: 'Режим питания',
      num: 'Кол-во питающихся',
      total: `Общая стоимость,\u00A0${currency}`,
      opt: '',
    };
    if (orgType === orgsTypes.ENTERPRISE) {
      return {
        ...defaults,
        group: 'Заведение',
        total: 'Кол-во порций и стоимость',
      };
    }
    return defaults;
  }, [currency, orgType]);

  return titles;
};

export const useSettingsTitles = (orgType: $Values<typeof orgsTypes>): {
  [$Keys<Settings>]: string
} => {
  const titles = useMemo(() => {
    const defaults = {
      id: '№',
      date: 'Дата',
      who: 'Кто создал',
      group: 'Класс/Группа',
      sch: 'Режим питания',
      num: 'Кол-во питающихся',
      total: 'Общая стоимость',
      opt: 'Действия',
    };
    if (orgType === orgsTypes.ENTERPRISE) {
      return {
        ...defaults,
        group: 'Заведение',
        total: 'Кол-во порций и стоимость',
      };
    }
    return defaults;
  }, [orgType]);

  return titles;
};
