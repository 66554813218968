// @flow
import React, { type Node, useMemo } from 'react';

import SideNav, { SideNavItem } from 'app/common/ui/SideNav';
import CSSSticky from 'app/common/ui-components/css-sticky';
import OrgsDataProvider from 'app/dataProviders/org/OrgsDataProvider';
import PlainIcon from 'app/common/ui-next/plain-icon';
import * as orgsTypes from 'app/common/constants/schools/types';
import type { OrgItem } from 'app/common/api/requests/food/schools';

import ContentView from './ContentView';
import sections, { availableSections, sectionTitles, sectionIcons } from './sections';

import styles from './OrgsSettings.scss';


const orgsFilterCb = (item: OrgItem): boolean => {
  const { deleted, approved } = item;
  const actual = !deleted && approved;
  return actual;
};

type Props = {
  orgId: number,
  type: string,
  orgType: $Values<typeof orgsTypes>,
  option?: string,
  basePath: string,
}

const OrgsSettings = ({ orgId, type, orgType, option, basePath }: Props): Node => {
  const actualSections = useMemo(() => {
    return availableSections.filter((section) => {
      return (
        orgType === orgsTypes.ENTERPRISE
          ? (section !== sections.ORGS_SETTINGS_LIMITS && section !== sections.ORGS_SETTINGS_SUBSIDIES)
          : section !== sections.ORGS_SETTINGS_CAFETERIA
      );
    });
  }, [orgType]);

  return (
    <div className={styles.container}>
      <CSSSticky className={styles.stickable} number={3}>
        <SideNav>
          {actualSections.map(key => (
            <SideNavItem
              key={key}
              title={sectionTitles[key]}
              to={`${basePath}/${key}`}
              active={type === key}
            >
              <PlainIcon type={sectionIcons[key]} />
            </SideNavItem>
          ))}
        </SideNav>
      </CSSSticky>

      <div className={styles.content}>
        <OrgsDataProvider 
          orgId={orgId} 
          sameType
          excludeCurrent
          filterCb={orgsFilterCb}
        >
          <ContentView
            type={type}
            orgId={orgId}
            orgType={orgType}
            option={option} // only for SellPoints
            basePath={`${basePath}/${type}`} // only for SellPoints
          />
        </OrgsDataProvider>
      </div>
    </div>
  );
};

export default OrgsSettings;
