// @flow
import map from 'ramda/es/map';
import moment from 'moment-timezone';

// DEPRECATED
const convertDatesFormat = (
  fromFormat: string,
  toFormat: string,
  dates: Array<string | null>,
): Array<string | null> => {
  if (fromFormat === toFormat) {
    return dates;
  }
  return map((date) => {
    return (date ? moment(date, fromFormat).format(toFormat) : date);
  }, dates);
};

export default convertDatesFormat;
