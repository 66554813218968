import { useState, useCallback, useRef, ChangeEvent } from 'react'
import toPairs from 'ramda/es/toPairs'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import Prompt from 'app/components/common/Prompt'
import usePopup from 'app/common/hooks/usePopup'
import SaveCancelButtons from 'app/components/common/SaveCancelButtons'
import { PageTemplateContent, Header2 } from 'app/common/templates-next/page-template'
import Sticky from 'app/common/templates-next/sticky'

import useReportsList from './useReportsList'
import styles from './SectionReports.scss'


const optionLabels = {
  food_cabinet_sales_report_use: 'Продажи',
  food_cabinet_subsidies_report_use: 'Дотации',
  food_cabinet_balances_report_use: 'Остаток средств',
  food_cabinet_accounting_report_use: 'Учет выдачи',
  food_cabinet_purchases_by_funds_report_use: 'Продажи по видам средств',
  food_cabinet_purchases_by_group_complexes_2_report_use: 'Продажи по виду продукции',
  food_cabinet_purchases_by_payment_mode_report_use: 'Продажи по способу расчета',
  food_cabinet_corrections_report_use: 'Корректировки',
}

type Props = {
  disabled?: boolean
}

const SectionReports = ({ disabled }: Props) => {
  const [
    { reportsList, loading, modified },
    { request, submit, update },
  ] = useReportsList()

  const [saving, setSaving] = useState(false)
  const popup = usePopup()

  const reportsItems = toPairs(reportsList)

  const handleSubmit = useCallback(async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSaving(true)
    const res = await submit()
    setSaving(false)

    if (res) {
      popup('Настройки отчетов успешно сохранены')
    }
  }, [submit, popup])

  const formRef = useRef<HTMLFormElement | null>(null)
  const handleSave = useCallback(() => {
    if (formRef.current) {
      const submitButton = (formRef.current.submit as unknown) as HTMLButtonElement
      submitButton.click()
    }
  }, [])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget
    update(value, checked)
  }, [update])

  return (
    <>
      <Sticky>
        {(ref, _, shadow) => (
          <PageTemplateContent horizontalLayout shadowBottom={shadow} reference={ref}>
            <Header2 skipMargin>{'Отчеты'}</Header2>
            {modified
              && !disabled
              && <SaveCancelButtons
                onSave={handleSave}
                onCancel={request}
                disabled={loading || saving}
                saving={saving}
                cancelling={modified && loading}
              />}
          </PageTemplateContent>
        )}
      </Sticky>

      <PageTemplateContent>
        <div className={styles.preText}>{'Выберите необходимые отчеты'}</div>

        <form onSubmit={handleSubmit} ref={formRef}>
          {reportsItems.map(([id, value]) => (
            optionLabels[id]
              ? <PlainCheckbox
                key={id}
                value={id}
                label={optionLabels[id]}
                disabled={loading || saving || disabled}
                checked={value}
                onChange={handleChange}
              />
              : null
          ))}

          <button type="submit" name="submit" className={styles.hidden} disabled={disabled}>
            {'Сохранить'}
          </button>
        </form>

        <Prompt
          when={modified}
          message="Вы не сохранили изменения, перейти в любом случае?"
        />

      </PageTemplateContent>
    </>
  )
}

export default SectionReports
