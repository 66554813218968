import { type FileType } from 'app/common/api'


export enum AgreementDocumentKind {
  PublicOffer = 'PublicOffer', // публичная оферта
  PersonalDataAgreement = 'PersonalDataAgreement' // согласие на обработку персональных данных
}

export type AgreementDocumentType = {
  ID: string
  kind: AgreementDocumentKind
  pdfFile: FileType // TODO FileAttachmentType (../attachments/file)
}

export type AgreementDocumentListType = Array<AgreementDocumentType>

export type AddAgreementDocumnetParams = {
  ID: string | null
  kind: AgreementDocumentKind
  attachmentFileID: string
}

export type DeleteAgreementDocumnetParams = {
  ID: string
}
