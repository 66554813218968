// @flow
import React, { useCallback, useMemo } from 'react';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import pipe from 'ramda/es/pipe';
import sort from 'ramda/es/sort';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import Loading from 'app/common/components/Loader';
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider';

import styles from './CopyCheckedForm.scss';


type Props = {
  names: Array<string>,
  checkedOrgs: Set<number>,
  onChange: (id: number, checked: boolean) => void,
  disabled: boolean,
}

const CopyCheckedForm = ({ names, checkedOrgs, onChange, disabled }: Props) => {
  const [{ orgs: orgsState, loading }] = useOrgsData();
  const orgs = useMemo(() => pipe(
    values,
    sort(ascend(prop('title'))),
  )(orgsState), [orgsState]);
  const empty = isEmpty(orgs);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    const checkedId = parseInt(value, 10);
    if (checkedId && orgsState[value]) {
      onChange(checkedId, checked);
    }
  }, [orgsState, onChange]);

  return (
    <div className={styles.root}>
      <label>
        {'Следующая продукция будет скопирована:'}
        <div className={styles.names}>
          <ul className={styles.namesList}>
            {names.map(name => <li key={name}>{name}</li>)}
          </ul>
        </div>
      </label>

      {loading && empty
        ? <Loading />
        : <PlainFieldset
          legend="Выберите заведения, куда копировать продукцию:"
          name="orgsTo"
        >
          {orgs.map(({ id, title }) => {
            const itemId = `org_to_${id}`;

            return (
              <PlainCheckbox
                key={id}
                id={itemId}
                label={title}
                value={String(id)}
                onChange={handleChange}
                checked={checkedOrgs.has(id)}
                disabled={loading || disabled}
              />
            );
          })}
        </PlainFieldset>}
    </div>
  );
};

export default CopyCheckedForm;
