// @flow
import OrgsOrders from './OrgsOrders';
import { dataKey } from './dataKey';
import { ordersSections } from './OrgOrdersOrProductionTabs';
import OrgsOrdersProduction from './OrgsOrdersProduction/OrgsOrdersProduction';


const defaultSection = ordersSections.ORDERS;

export {
  dataKey,
  ordersSections,
  defaultSection,
  OrgsOrdersProduction,
  OrgsOrders,
};
