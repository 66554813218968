import React, { useState, useCallback, Fragment, ReactNode } from 'react'
import { isEmpty } from 'ramda'

import usePopup from 'app/common/hooks/usePopup'
import MultipleSubmit, { MultipleSubmitIndicator } from 'app/components/common/MultipleSubmit'
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider'

/**
 *  Выбор организаций и процесс массового сброса личных лимитов
 */

type Props = {
  multipleAfterContent?: ReactNode,
  additionalContent?: ReactNode,
  disableMultiple?: boolean,
  onSuccess: (res: Array<boolean>) => void,
  onReset: (orgIds: Array<number>) => boolean | Promise<boolean>,
  children: (begin: () => void, submitting: boolean) => ReactNode,
}

const PersonalLimitsMultipleReset = ({
  multipleAfterContent,
  additionalContent,
  disableMultiple,
  onSuccess,
  onReset,
  children,
}: Props) => {
  const popup = usePopup()
  const [optionsModalDisplayed, setOptionsModalDisplayed] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [{ orgs, loading, currentOrg }] = useOrgsData()
  const { id: currentOrgId, title: currentOrgTitle } = currentOrg


  const handleMassResetLimits = useCallback(async (orgIds: Array<number>) => {
    setOptionsModalDisplayed(false)

    setSubmitting(true)
    const res = await onReset([currentOrgId, ...orgIds])
    setSubmitting(false)

    if (res) {
      onSuccess([true])
      popup('Личные лимиты успешно сброшены')
    }
  }, [onReset, popup, currentOrgId, onSuccess])


  const handleToggleOptionsModal = useCallback(() => {
    setOptionsModalDisplayed(current => !current)
  }, [])


  const handleBeginProcess = useCallback(() => {
    // если нет других заведений в кабинете,
    // то сразу запускаем с пустым массивом вместо выбранных заведений
    if (isEmpty(orgs)) {
      handleMassResetLimits([])
      return
    }
    // окно выбора других заведений
    setOptionsModalDisplayed(true)
  }, [orgs, handleMassResetLimits])


  return (
    <Fragment>
      {children(handleBeginProcess, submitting)}

      {optionsModalDisplayed
        && (
          <MultipleSubmit
            orgs={orgs}
            loading={loading}
            header="Сброс личных лимитов"
            buttonOkTitle="Сбросить"
            buttonOkTitleSubmitting="Сбрасывается..."
            orgsSelectorTitle="Сбросить личные лимиты для следуюших заведений:"
            onClickOk={handleMassResetLimits}
            onClickCancel={handleToggleOptionsModal}
            checkboxSaveOnlyLabel={`Сбросить только для ${currentOrgTitle}`}
            checkboxRevealOrgsSelectorLabel="Сбросить для всех доступных вам заведений типа ОУ"
            disableMultiple={disableMultiple}
            multipleAfterContent={multipleAfterContent}
            additionalContent={additionalContent}
          />
        )}

      <MultipleSubmitIndicator
        show={submitting}
        message={'Подождите,\nидет подготовка к\u00A0сбросу\nличных лимитов'}
      />
    </Fragment>
  )
}

export default PersonalLimitsMultipleReset
