// @flow
import { useState, useMemo, useCallback } from 'react';
import F from 'ramda/es/F';
import keys from 'ramda/es/keys';
import reduce from 'ramda/es/reduce';
import isEmpty from 'ramda/es/isEmpty';

import type { MachineSingle } from 'app/dataTypes/vending/Machines.types';


type Errors = {
  title?: string,
  modelId?: string,
  versionId?: string,
  orgId?: string,
  number?: string,
}

const availableErrors: Errors = {
  title: 'Введите наименование аппарата',
  modelId: 'Требуется модель аппарата',
  versionId: 'Требуется версия аппарата',
  orgId: 'Требуется место размещения аппарата',
  number: 'Требуется номер аппарата',
};

const defaultErrors: Errors = Object.freeze({});

export type MachineValidate = [Errors, () => boolean, () => void]
export const defaultMachineValidate: MachineValidate = [defaultErrors, F, () => {}];


export default function useMachineValidate(machine: MachineSingle): MachineValidate {
  const [errors, setErrors] = useState<Errors>(defaultErrors);

  const validate = useCallback(() => {
    const newErrors: Errors = reduce((acc, key) => {
      if (!machine[key]) { // здесь может быть своя функция валидации для конкретного поля
        acc[key] = availableErrors[key];
      }
      return acc;
    }, {}, keys(availableErrors));
    setErrors(newErrors);

    return isEmpty(newErrors);
  }, [machine]);

  const reset = useCallback(() => {
    setErrors(defaultErrors);
  }, []);

  const res = useMemo(() => [errors, validate, reset], [errors, validate, reset]);
  return res;
}
