// @flow
import React, { type Node, Fragment, useState, useMemo } from 'react';

import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';
import type { Category, Categories } from 'app/dataTypes/vending/production/Categories.types';

import DishesDeleteModal from './dishesBatchActions/DishesDeleteModal';
import DishesMoveModal from './dishesBatchActions/DishesMoveModal';


const doing = {
  Move: 'move',
  Delete: 'delete',
  Nothing: null,
};

type Props = {
  dishes: Array<Dish>,
  relatedCategories: Map<string, Category>,
  allCategories: Categories,
  deleteSelection: () => Promise<boolean>,
  moveSelection: (categoryId: string) => Promise<boolean>,
  children: ({
    onMoveDishes: () => void,
    onDeleteDishes: () => void,
    onClose: () => void,
  }) => Node,
}

const DishesBatchActions = ({
  dishes,
  relatedCategories,
  allCategories,
  deleteSelection,
  moveSelection,
  children,
}: Props): Node => {
  const [show, setShow] = useState(doing.Nothing);

  const actions = useMemo(() => ({
    onMoveDishes: () => setShow(doing.Move),
    onDeleteDishes: () => setShow(doing.Delete),
    onClose: () => setShow(doing.Nothing),
  }), []);

  return (
    <Fragment>
      {children(actions)}

      {show === doing.Move
        && <DishesMoveModal
          dishes={dishes}
          categories={allCategories}
          moveSelection={moveSelection}
          relatedCategories={relatedCategories}
          onClose={actions.onClose}
        />}

      {show === doing.Delete
        && <DishesDeleteModal
          dishes={dishes}
          relatedCategories={relatedCategories}
          deleteSelection={deleteSelection}
          onClose={actions.onClose}
        />}
    </Fragment>
  );
};

export default DishesBatchActions;
