// @flow

/**
 * Типы разделения дотаций
 */
export const ALL: 'all' = 'all';
export const SEPARATE: 'separate' = 'separate';

export type SubsidySplittingType =
  | typeof ALL
  | typeof SEPARATE
  | null

// export type { SubsidySplittingType };
