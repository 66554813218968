// @flow
import { useCallback, useState, useMemo } from 'react';


const emptyFunc = () => { };

export type Paging = {
  currentPage: number,
  handlePage: (e: SyntheticEvent<HTMLAnchorElement>) => void,
  setCurrentPage: (page: number) => void,
}

export const defaultPaging: Paging = {
  currentPage: 1,
  handlePage: emptyFunc,
  setCurrentPage: emptyFunc,
};

const usePaging = (): Paging => {
  const [currentPage, setCurrentPage] = useState(1);
  const handlePage = useCallback((e) => {
    e.preventDefault();
    const { name } = e.currentTarget;
    const page = parseInt(name, 10);
    setCurrentPage(page);
  }, []);

  const paging = useMemo(() => ({
    currentPage,
    handlePage,
    setCurrentPage,
  }), [currentPage, handlePage, setCurrentPage]);

  return paging;
};

export default usePaging;
