// @flow
import React from 'react';

import styles from './RowLayout.scss';


const Space = () => (
  <div className={styles.space} />
);

export default Space;
