// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'ramda/es/isEmpty';
import moment from 'moment-timezone';
import path from 'ramda/es/path';

import { PlainGroup, PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainSelect from 'app/common/ui/PlainSelect';
import Loader from 'app/common/components/Loader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { ModalHeader, ModalFooter } from 'app/common/components/Modal';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';
import PlainLinkAsButton from 'app/common/ui-next/plain-link-as-button';
import PlainInputDate from 'app/common/ui-next/plain-input-date';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import useErrors from 'app/common/hooks/useErrors';
import history from 'app/history';

import { errorsMapping } from './errors';
import selectProps from './selectProps';
import useSellPointControl from './useSellPointControl';
import useDataLoad from './useDataLoad';
import useSubmit from './useSubmit';

import styles from './Dishes2MenuForm.scss';

/**
 * Добавление блюд в меню
 * Используется так же в OrgsDishes
 */
const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';
const initialDate = () => moment().format(dateFormat);

type Item = {
  id: number,
  title: string,
}

type Props = {
  orgId: string,
  returnTo2: string,
  dishes?: Array<Item>, // need dishes or kitId
  kitId?: number, // need dishes or kitId
  date?: string,
};

const Dishes2MenuForm = ({
  orgId,
  returnTo2: returnPath,
  dishes: dishesIn,
  kitId,
  date,
}: Props) => {
  const closeModal = useCallback(() => history.push(returnPath), [returnPath]);

  const [errors, validate, resetErrors] = useErrors(errorsMapping);

  const [dateValue, setDateValue] = useState(initialDate());
  const handleDateChange = useCallback((newDate: string) => {
    setDateValue(newDate);
    resetErrors();
  }, [resetErrors]);

  const { dishes, sellPoints } = useSelector(state => selectProps(state, { kitId, dishes: dishesIn }));

  const singleSellPoint: null | number = (sellPoints.length === 1 && path([0, 'id'], sellPoints)) || null;
  const [sellPoint, changeSellPoint] = useSellPointControl({
    defaultSellPoint: singleSellPoint,
    onChange: resetErrors,
  });

  const loading = useDataLoad({ orgId });
  const [submit, saving] = useSubmit({
    sellPointId: sellPoint,
    onClose: closeModal,
    dishes,
    orgId,
    date: date || dateValue,
    validate,
  });

  useEffect(() => {
    if (!dishes || isEmpty(dishes)) {
      closeModal();
    }
  }, [dishes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      <ModalHeader>{'Добавление продукции в меню'}</ModalHeader>
      <form onSubmit={submit}>
        <PlainGroup
          className={styles.content}
        >

          <PlainColumns>
            <PlainColumn>
              <label htmlFor="dishes">{'Следующая продукция будет добавлена:'}</label>
              <div className={styles.dishes}>
                <Scrollbars
                  autoHeight
                  autoHeightMin={20}
                  autoHeightMax={200}
                >
                  <ul id="dishes" className={styles.dishesList}>
                    {dishes.map(dish => <li key={dish.id}>{dish.title}</li>)}
                  </ul>
                </Scrollbars>
              </div>
            </PlainColumn>

            {!date
              && !loading
              && <PlainColumn>
                <PlainLabel htmlFor="date">
                  {'в меню на дату:'}
                </PlainLabel>

                <PlainInputDate
                  id="date"
                  value={dateValue}
                  onDateChange={handleDateChange}
                  disabled={loading || saving}
                  error={Boolean(errors.date)}
                  dateFormat={dateFormat}
                  calendarCloseOnClick
                  displayAsBlock
                />

                <PlainHelperText overflowVisible error>
                  {errors.date}
                </PlainHelperText>
              </PlainColumn>}

            {(sellPoints.length > 1
              && (date || dateValue)
              && !loading)
              && <PlainColumn>
                <PlainSelect
                  name="sellPoint"
                  placeholder="Выберите точку продаж"
                  label={!date ? 'Точка продажи' : 'в меню точки продажи:'}
                  onChange={changeSellPoint}
                  data={sellPoints}
                  current={sellPoint}
                  disable={loading || saving}
                  hideErrorString
                  uni
                />
                <PlainHelperText overflowVisible error>
                  {errors.sellPoint}
                </PlainHelperText>
              </PlainColumn>}
          </PlainColumns>

          {loading && <Loader className={styles.loader} />}

        </PlainGroup>

        <ModalFooter>
          <ControlsBar right>
            <PlainButton
              type="submit"
              disabled={loading || saving || !(date || dateValue)}
            >
              {saving ? 'Добавление...' : 'Добавить'}
            </PlainButton>
            <PlainLinkAsButton outline to={returnPath}>{'Отмена'}</PlainLinkAsButton>
          </ControlsBar>
        </ModalFooter>
      </form>
    </div>
  );
};

export default Dishes2MenuForm;
