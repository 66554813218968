
import { useCallback } from 'react'

import {
  POST_SCHOOL_SUBSIDY_ORDERS_SETTINGS_COPY,
  convertParams as convertParamsCopy,
  convertResult as convertResultCopy,
} from 'app/common/api/requests/food/schools/subsidy_orders_settings_copy/post'

import { api } from 'app/api'


type Options = {
  orgID: string
  subsidyCategoriesType: 'common' | 'camp' | 'cafeteria'
}

export default function useSubsidySettingsCopy({
  orgID,
  subsidyCategoriesType,
}: Options) {
  const copyAllSettings = useCallback(async (targetIDs: Array<number>) => {
    const res = await api.request(POST_SCHOOL_SUBSIDY_ORDERS_SETTINGS_COPY, {
      error: 'Не удалось скопировать настройки льгот',
      params: {
        orgID,
        to: targetIDs.map(String),
        subsidyCategoriesType,
      },
      convertions: {
        convertParams: convertParamsCopy,
        convertResult: convertResultCopy,
      },
    })
    return !!res
  }, [orgID, subsidyCategoriesType])


  return copyAllSettings
}
