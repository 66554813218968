// @flow
import React, { type Node, Fragment, useState, useCallback } from 'react';

import AddRemoveItemsModalTemplate from 'app/common/templates/AddRemoveItemsModalTemplate';
import { PlanogramData, PlanogramItem } from 'app/common/ui-components/planograms';
import { PUT_PLANORGAM_SLOTS_CONTENT, convertParams, convertResult } from 'app/common/api/requests/data/vending/machines/slots/content/put';
import { api } from 'app/api';
import type { PlanogramContent } from 'app/dataTypes/vending/PlanogramContent.types';

import SlotsDishes from './SlotsDishes';
import ModifyContent from './ModifyContent';
import styles from './MassIncreaseProduct.scss';


const tableHeaders = ['', '№\u00A0ячейки', 'Наименование', 'Фото', 'Кол-во на\u00A0остатке', 'Будет изъято', 'Станет после изъятия'];
const getModifyDiff = (amount: number) => amount;
const getModifyRest = () => 0;

type Props = {
  children: (() => void) => Node,
  data: PlanogramData<PlanogramItem>,
  placeId: string,
  machineId: string,
  onSuccess: (PlanogramContent) => void,
}

const MassDecreaseProduct = ({ children, data, placeId, machineId, onSuccess }: Props): Node => {
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [selectionData, setSelectionData] = useState([]);
  const nothingSelected = !selectionData.length;

  const handleModalDisplay = useCallback(() => {
    setModalDisplayed(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    const res = await api.request(PUT_PLANORGAM_SLOTS_CONTENT, {
      error: 'Не удалось изъять товары',
      params: {
        machineId,
        data: selectionData.map(({
          slotId,
          productId,
          amount,
          amountModify,
        }) => {
          if (amount === null || productId === null) {
            return ({
              slotId,
              productId: null,
              amount: null,
            });
          }
          const newAmount = amount - amountModify;
          return ({
            slotId,
            productId: newAmount ? productId : null,
            amount: newAmount || null,
          });
        }),
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      onSuccess(res);
      return true;
    }
    return false;
  }, [machineId, selectionData, onSuccess]);

  const mainContent = (
    <SlotsDishes
      data={data}
      placeId={placeId}
      onSelect={setSelectionData}
      headers={tableHeaders}
      getModifyDiff={getModifyDiff}
      getModifyRest={getModifyRest}
    />
  );

  const modifyContent = (
    <ModifyContent
      title="Будет изъято:"
      items={selectionData}
    />
  );

  return (
    <Fragment>
      {children(handleModalDisplay)}

      <AddRemoveItemsModalTemplate
        header="Изъять весь товар"
        submitButtonTitle="Изъять продукцию"
        submitButtonTitleInProcess="Изъятие..."
        mainContent={mainContent}
        mainContentClassName={styles.mainContent}
        onSubmit={handleSubmit}
        show={modalDisplayed}
        onDisplayStatusChange={setModalDisplayed}
        modifyContent={modifyContent}
        disableSubmit={nothingSelected}
        disableSubmitReason="Выберите хотя бы одну продукцию"
        hideLegend
      />
    </Fragment>
  );
};

export default MassDecreaseProduct;
