// @flow
import path from 'ramda/es/path';
import api from 'app/actions/api';


export const ORGS_FILTER_REQUEST = 'reports/ORGS_FILTER_REQUEST';
export const ORGS_FILTER_SUCCESS = 'reports/ORGS_FILTER_SUCCESS';
export const ORGS_FILTER_FAILURE = 'reports/ORGS_FILTER_FAILURE';
export const ORGS_FILTER_CLEAR = 'reports/ORGS_FILTER_CLEAR';
export const ORGS_FILTER_DELETE = 'reports/ORGS_FILTER_DELETE';
export const ORGS_FILTER_SET = 'reports/ORGS_FILTER_SET';


const orgsFilterRequest = () => ({
  type: ORGS_FILTER_REQUEST,
});

const orgsFilterSuccess = (payload: Object) => ({
  type: ORGS_FILTER_SUCCESS,
  payload,
});

const orgsFilterFailure = (payload: Object) => ({
  type: ORGS_FILTER_FAILURE,
  payload,
});

export const orgsFilterClear = () => ({
  type: ORGS_FILTER_CLEAR,
});

export const orgsFilterDelete = (payload: Array<string | number>) => ({
  type: ORGS_FILTER_DELETE,
  payload,
});

export const orgsFilterSet = (payload: Object) => ({
  type: ORGS_FILTER_SET,
  payload,
});

export const orgsFilterFetch = () => async (dispatch: Function): Object => {
  dispatch(orgsFilterRequest());
  try {
    const res = await api('food/schools', 'get');
    const schools = path(['data', 'schools'], res);
    dispatch(orgsFilterSuccess(schools));
    return schools;
  } catch (error) {
    dispatch(orgsFilterFailure({ err: 'Не удалось получить данные фильтра по образовательным организациям', error }));
    return null;
  }
};
