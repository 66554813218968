// @flow
import React from 'react';

import DiningRoom from './icons/dining_room.svg';
import Passing from './icons/passing.svg';
import Lockers from './icons/lockers.svg';
import Vending from './icons/vending.svg';
import Transport from './icons/transport.svg';
import Intercom from './icons/intercom.svg';
import { moduleTypes } from '../../identifiers-data';


type Props = {
  type: $Values<typeof moduleTypes>,
  className?: string,
}

const ModulesSwitcherIcon = ({ type, className }: Props) => {
  switch (type) {
    case moduleTypes.DININGROOM: {
      return <DiningRoom className={className} />;
    }
    case moduleTypes.PASSING: {
      return <Passing className={className} />;
    }
    case moduleTypes.LOCKERS: {
      return <Lockers className={className} />;
    }
    case moduleTypes.VENDING: {
      return <Vending className={className} />;
    }
    case moduleTypes.TRANSPORT: {
      return <Transport className={className} />;
    }
    case moduleTypes.INTERCOM: {
      return <Intercom className={className} />;
    }
    default:
      return null;
  }
};

export default ModulesSwitcherIcon;
