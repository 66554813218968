import { logout } from 'app/common/actions/user';

export const DISCONNECTED = 'DISCONNECTED';
export const CONNECT = 'CONNECT';
export const SAVE_ROUTER_PARAMS = 'SAVE_ROUTER_PARAMS';
export const NEED_REFRESH = 'NEED_REFRESH';


// !!! DEPRICATED
export const disconnected = () => ({
  type: DISCONNECTED,
});

// !!! DEPRICATED
export const connect = () => ({
  type: CONNECT,
});

export const saveRouterParams = payload => ({
  type: SAVE_ROUTER_PARAMS,
  payload,
});

export const needRefresh = payload => ({
  type: NEED_REFRESH,
  payload,
});

/**
 * Проверка на наличие ответа в ошибке, дисконнект - запрос закончился неудачно и без статуса ошибки
 * @param error
 * @param dispatch
 * !!! DEPRICATED
 */
export const handleDisconnect = (error, dispatch) => {
  if (!(error && error.response)) { // disconnect
    dispatch(disconnected());
    return false;
  }
  // 401 - не найдена учетная запись соответсвующая токену
  if (error.response.status === 401) {
    dispatch(logout());
    return false;
  }
  return true;
};
