import api from 'app/actions/api';

export const SAVE_SETTINGS_REQUEST = 'SAVE_SETTINGS_REQUEST';
export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAILURE = 'SAVE_SETTINGS_FAILURE';

export const saveSettingsRequest = (key, obj) => ({
  type: SAVE_SETTINGS_REQUEST,
  payload: { key, obj },
});

export const saveSettingsSuccess = data => ({
  type: SAVE_SETTINGS_SUCCESS,
  payload: data,
});

export const saveSettingsFailure = payload => ({
  type: SAVE_SETTINGS_FAILURE,
  payload,
});

/**
* Сохранение всех настроек, пока не используется
*/
export const saveSettings = () => (
  async (dispatch, getState) => {
    const {
      current, // eslint-disable-line
      ...other
    } = getState().settings;
    dispatch(saveSettingsRequest());
    try {
      const responce = await api('settings', 'post', {
        data: { ...other },
      });
      dispatch(saveSettingsSuccess(responce));
    } catch (error) {
      dispatch(saveSettingsFailure({ error }));
    }
  }
);

/**
* Сохранение определенных значений для определенного раздела
* на request данные сразу отправляются в store и происходит попытка сохранения на сервере
* на сервере данные дописываются к существующим
*/
export const saveSettingsKey = (key, obj) => (
  async (dispatch) => {
    dispatch(saveSettingsRequest(key, obj));
    try {
      const response = await api('settings', 'post', {
        data: { [key]: { ...obj } },
      });
      dispatch(saveSettingsSuccess(response));
    } catch (error) {
      dispatch(saveSettingsFailure({ err: 'Не удалось сохранить настройки на сервер', error }));
    }
  }
);
