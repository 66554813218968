// @flow
import React, { type Node, useCallback, Fragment, useMemo } from 'react';
import { values, identity } from 'ramda';
import cn from 'classnames/bind';

import PlainFieldset from '../plain-fieldset';
import PlainRadioButton from './plain-radio-button';

import styles from './plain-radio-group.scss';


const cx = cn.bind(styles);

type Item = {
  id: string,
  title: string | Node,
  disabled?: boolean,
  level?: number,
}

export type Props = {
  id: string,
  className?: string,
  label: Node,
  current?: string,
  disabled?: boolean,
  keepPadding?: boolean,
  items: { [key: string]: Item } | Array<Item>,
  afterContent?: Node,
  skipUpperSpace?: boolean,
  inlineItems?: boolean,
  onChange?: (string) => void,
  mapItemPropsBeforeRender?: (Item) => Item,
}

const PlainRadioGroup = ({
  id,
  className,
  label,
  current,
  disabled,
  keepPadding,
  items,
  afterContent,
  skipUpperSpace,
  inlineItems,
  onChange,
  mapItemPropsBeforeRender = identity,
}: Props) => {
  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (checked && onChange) {
      onChange(value);
    }
  }, [onChange]);

  const itemsList = useMemo(() => {
    return (items instanceof Array ? items : values(items));
  }, [items]);

  const renderItems = itemsList.map((itemProps) => {
    const { id: itemId, title, disabled: dd, level } = mapItemPropsBeforeRender(itemProps);
    return (
      <PlainRadioButton
        key={itemId}
        className={cx({ inlineItems })}
        label={title}
        value={itemId}
        name={id}
        disabled={disabled || dd}
        onChange={handleChange}
        checked={itemId === current}
        level={level}
      />
    );
  });

  if (label) {
    const legend = (typeof label === 'string'
      ? `${label}:`
      : (<span>
        {label}
        {':'}
      </span>));

    return (
      <Fragment>
        <PlainFieldset
          legend={legend}
          name={id}
          skipUpperSpace={skipUpperSpace}
          className={cx(styles.root, className)}
          legendClassName={styles.legend}
        >
          {renderItems}
        </PlainFieldset>
        {afterContent}
      </Fragment>
    );
  }

  return (
    <div className={cx(styles.rootUngroupped, { keepPadding, skipUpperSpace }, className)}>
      {renderItems}
      {afterContent}
    </div>
  );
};

export default PlainRadioGroup;
