// @flow
import { type Theme } from '../_parts/planogram-theme';


export const defaultTheme: Theme = {
  ruleThickness: '22px',
  unitHeight: '96px',
  unitWidth: '60px',
  edgingThickness: '5px',
  colorDanger: '#facdcd',
  colorMiddle: '#fdf3da',
  colorFull: '#e3e9eb',
  colorNeutral: '#e3e9eb',
  colorButton: '#899fae',
  colorButtonHover: '#5f798b',
  selectionTint: 0,
};
