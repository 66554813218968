import { ReactNode, forwardRef } from 'react'
import classNames from 'classnames/bind'

import styles from './controls-bar.scss'


const cx = classNames.bind(styles)

export type ControlsBarProps = {
  left?: boolean
  right?: boolean
  middle?: boolean
  top?: boolean
  bottom?: boolean
  spaceBetween?: boolean
  className?: string
  nowrap?: boolean
  fillPrimary?: boolean
  children?: ReactNode
}

const ControlsBar = forwardRef<HTMLDivElement, ControlsBarProps>(({
  left,
  right,
  middle,
  top,
  bottom,
  spaceBetween,
  className,
  nowrap,
  fillPrimary,
  children,
}, ref) => {
  const classes = cx(styles.root, {
    left: left || (!right && !middle),
    right,
    middle,
    top,
    bottom,
    spaceBetween,
    nowrap,
    fillPrimary,
  }, className)

  return (
    <div className={classes} ref={ref}>
      {children}
    </div>
  )
})

export default ControlsBar
