import { useState, useEffect } from 'react'

import { useVendingMachines, useVendingMachinesParams } from 'app/dataProviders/VendingDataProvider'
import { useFilterData } from 'app/common/ui-components/filter'
import { PageTemplateContent } from 'app/common/templates-next/page-template'
import ControlsBar from 'app/common/ui-next/controls-bar'
import Sticky from 'app/common/templates-next/sticky'

import VendingFilterBar from './VendingFilterBar'
import VendingAddMachineBar from './VendingAddMachineBar'
import FilterWindow from './filter/FilterWindow'
import VendingMachinesTable from './table/VendingMachinesTable'
import filter2MachinesRequestParams from './filter2MachinesRequestParams'


type Props = {
  onRedirectToVendingMachine: (id: string, section?: string) => void,
}

const VendingMain = ({ onRedirectToVendingMachine }: Props) => {
  const [search, setSearch] = useState<string>('')
  const [filterActive, setFilterActive] = useState(false)

  const [{ applied: filterApplied }] = useFilterData()
  const [, updateParams] = useVendingMachinesParams()

  useEffect(() => {
    updateParams(filter2MachinesRequestParams(filterApplied, { search }))
  }, [filterApplied, search]) // eslint-disable-line react-hooks/exhaustive-deps

  const [, loading] = useVendingMachines()

  return (
    <>
      <Sticky>
        {(ref, stuck, shadow) => (
          <>
            <PageTemplateContent
              reference={ref}
              borderBottom={!filterActive}
              shadowBottom={shadow}
              horizontalLayout
            >
              <ControlsBar spaceBetween>
                <VendingFilterBar
                  onSearch={setSearch}
                  onFilterToggle={setFilterActive}
                  filterActive={filterActive}
                />
                <VendingAddMachineBar />
              </ControlsBar>
            </PageTemplateContent>
            <PageTemplateContent skip={!filterActive} skipPadding>
              <FilterWindow
                onFilterToggle={setFilterActive}
                filterActive={filterActive}
                loading={loading}
                hideArrow={stuck}
              />
            </PageTemplateContent>
          </>
        )}
      </Sticky>
      <PageTemplateContent skipPadding>
        <VendingMachinesTable
          onRedirectToVendingMachine={onRedirectToVendingMachine}
        />
      </PageTemplateContent>
    </>
  )
}

export default VendingMain
