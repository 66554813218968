// @flow
import { useState, useCallback, useMemo } from 'react';
import reduce from 'ramda/es/reduce';

import { api } from 'app/api';
import { GET_VENDING_MACHINES_MODELS } from 'app/common/api/requests/data/vending/machines/models/get';


type Item = {
  id: string,
  title: string,
}

export type ModelsState = {
  [key: string]: Item,
}
const defaultState: ModelsState = {};

export type Models = [ModelsState, boolean, () => void | Promise<void>]
export const defaultModels: Models = [defaultState, true, () => {}];

export default function useModels(): Models {
  const [models, setModels] = useState<ModelsState>(defaultState);
  const [loading, setLoading] = useState(true);

  const request = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_VENDING_MACHINES_MODELS, {
      error: 'Не удалось получить модели вендинговых аппаратов',
    });

    if (res && res.data) {
      const { data } = res;
      setModels(reduce((acc, { id: id_, title }) => {
        const id = String(id_);
        acc[id] = { id, title };
        return acc;
      }, {}, data));
    }
    setLoading(false);
  }, []);

  const res: Models = useMemo(() => [models, loading, request], [models, loading, request]);
  return res;
}
