// @flow
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import { type Descriptions, filterTypes } from 'app/common/ui-components/filter';
import { arrangeByArray, sortClasses } from 'app/common/lib/sort';

import orgsOrUserFilter, { orgsOrUserItems } from 'app/components/common/reports/filters/orgsOrUser';
import customerSearchFilter, { customerSearchItems, customerSearchActivity } from 'app/components/common/reports/filters/customerSearch';
import orgTypesFilter, { arrange, orgTypeItems, orgTypeActivity } from 'app/components/common/reports/filters/orgType';
import orgsFilter, { orgItems, orgActivity } from 'app/components/common/reports/filters/org';
import buildingFilter, { buildingItems, buildingActivity } from 'app/components/common/reports/filters/building';
import sellPointFilter, { sellPointItems, sellPointActivity } from 'app/components/common/reports/filters/sellPoint';
import yearFilter, { yearItems, yearActivity } from 'app/components/common/reports/filters/year';
import customerTypeFilter from 'app/components/common/reports/filters/customerType';
import classFilter, { classItems } from 'app/components/common/reports/filters/class';
import studentSubsidyTypeFilter, { studentSubsidyTypeItems } from 'app/components/common/reports/filters/studentSubsidyType';
import detailsFilter, { detailsItems, detailsActivity } from 'app/components/common/reports/filters/details';
import mailFilter, { mailItems, mailProps, mailActivity } from 'app/components/common/reports/filters/mail';
import studentFilter, { studentItems, studentActivity } from 'app/components/common/reports/filters/student';
import employeeFilter, { employeeItems, employeeActivity } from 'app/components/common/reports/filters/employee';
import orderTypeFilter, { orderTypeItems, orderTypeActivity } from 'app/components/common/reports/filters/orderType';
import subsidyFilter, { subsidyItems, subsidyActivity } from 'app/components/common/reports/filters/subsidy';
import sellTypeFilter, { sellTypeItems } from 'app/components/common/reports/filters/sellType';
import foodModeFilter, { foodModeItems } from 'app/components/common/reports/filters/foodMode';
import productionTypeFilter, { productionTypeItems } from 'app/components/common/reports/filters/productionType';

import groupFilter, { groupItems, groupActivity } from './group';
import groupComplexesSpecialFilter, { groupComplexesSpecialItems, groupComplexesSpecialActivity } from './groupComplexesSpecial';
import classActivity from './classActivity';
import studentSubsidyTypeActivity from './studentSubsidyTypeActivity';
import periodFilter, { periodProps, periodItems } from './period';
import customerTypeActivity from './customerTypeActivity';
import customerTypeItems from './customerTypeItems';
import sellTypeActivity from './sellTypeActivity';
import sellTypeOnlyComplex, { sellTypeOnlyComplexItems, sellTypeOnlyComplexActivity } from './sellTypeOnlyComplex';
import accountFilter, { accountItems, accountActivity } from './account';
import productionActivity from './productionActivity';
import foodModeActivity from './foodModeActivity';
import excludeOVZFilter, { excludeOVZActivity, excludeOVZItems } from './excludeOVZ';


type Params = {
  email: string | null
}

const filterItems: Descriptions<Params> = [{
  filterId: orgsOrUserFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  props: {
    label: 'Выборка покупателя',
    inlineItems: true,
  },
  getItemsAndCurrent: orgsOrUserItems,
}, {

  /* фильтр по покупателю через его поиск */
  filterId: customerSearchFilter,
  filterType: filterTypes.CUSTOMER_SEARCH,
  props: {
    label: 'Укажите л/с или телефон',
  },
  getItemsAndCurrent: customerSearchItems,
  activity: customerSearchActivity,
}, {

  /* фильтр по типу заведения */
  filterId: orgTypesFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Тип заведения',
    header: 'Выбор типа заведения',
    sortFunc: arrangeByArray(arrange, prop('id')),
  },
  getItemsAndCurrent: orgTypeItems,
  activity: orgTypeActivity,
  relations: [orgsFilter],
}, {

  /* фильтр по заведению */
  filterId: orgsFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Заведение',
    header: 'Выбор заведения',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: orgItems,
  activity: orgActivity,
  relations: [yearFilter, buildingFilter, customerTypeFilter],
}, {

  /* фильтр по корпусу */
  filterId: buildingFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Корпус',
    header: 'Выбор корпуса',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: buildingItems,
  relations: [sellPointFilter],
  activity: buildingActivity,
}, {

  /* фильтр по точке продаж */
  filterId: sellPointFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Точка продаж',
    header: 'Выбор точки продаж',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: sellPointItems,
  activity: sellPointActivity,
}, {

  /* фильтр по типу покупателей */
  filterId: customerTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Тип покупателя',
    header: 'Выбор типа покупателя',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: customerTypeItems,
  activity: customerTypeActivity,
}, {

  /* фильтр по сотруднику */
  filterId: employeeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Сотрудники',
    header: 'Выбор сотрудников',
    sortFunc: sort(descend(prop('title'))),
  },
  getItemsAndCurrent: employeeItems,
  activity: employeeActivity,
}, {

  /* фильтр по учебному году */
  filterId: yearFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: false,
  props: {
    label: 'Учебный год',
    header: 'Выбор учебного года',
    sortFunc: sort(descend(prop('title'))),
  },
  getItemsAndCurrent: yearItems,
  relations: [classFilter],
  activity: yearActivity,
}, {

  /* фильтр по классу */
  filterId: classFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Класс',
    header: 'Выбор класса',
    sortFunc: sortClasses('down', prop('title')),
  },
  getItemsAndCurrent: classItems,
  relations: [studentFilter],
  activity: classActivity,
}, {

  /* фильтр по типу начисления льгот */
  filterId: studentSubsidyTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Начисление льгот',
    header: 'Выбор типа начисления льгот',
  },
  getItemsAndCurrent: studentSubsidyTypeItems,
  relations: [orderTypeFilter],
  activity: studentSubsidyTypeActivity,
}, {

  /* фильтр по ученику */
  filterId: studentFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Учащиеся',
    header: 'Выбор учащихся',
    sortFunc: sort(ascend(prop('title'))),
  },
  getItemsAndCurrent: studentItems,
  activity: studentActivity,
}, {

  /* фильтр детализация */
  filterId: detailsFilter,
  filterType: filterTypes.PLAIN_CHECKBOX_GROUP,
  props: {
    label: null,
    keepPadding: true,
  },
  getItemsAndCurrent: detailsItems,
  activity: detailsActivity,
}, {

  /* фильтр группировки */
  filterId: groupFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  props: {
    label: 'Группировать по',
  },
  getItemsAndCurrent: groupItems,
  relations: [
    customerTypeFilter,
    accountFilter,
  ],
  activity: groupActivity,
}, {

  /* спец. фильтр группировки для нескольких заведений */
  filterId: groupComplexesSpecialFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  props: {
    label: 'Группировать по',
  },
  getItemsAndCurrent: groupComplexesSpecialItems,
  activity: groupComplexesSpecialActivity,
}, {

  /* фильтр по периоду */
  filterId: periodFilter,
  filterType: filterTypes.PLAIN_PERIOD,
  getProps: periodProps,
  relations: [customerTypeFilter, studentSubsidyTypeFilter, classFilter],
  getItemsAndCurrent: periodItems,
}, {

  /* фильтр по виду справки */
  filterId: orderTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Вид справки',
    header: 'Выбор вида справки',
  },
  getItemsAndCurrent: orderTypeItems,
  relations: [subsidyFilter],
  activity: orderTypeActivity,
}, {

  /* фильтр по категории льгот */
  filterId: subsidyFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Категория льгот',
    header: 'Выбор категории льгот',
  },
  getItemsAndCurrent: subsidyItems,
  relations: [],
  activity: subsidyActivity,
}, {

  /* фильтр тип продаж */
  filterId: sellTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Тип продаж',
    header: 'Выбор типа продаж',
  },
  getItemsAndCurrent: sellTypeItems,
  activity: sellTypeActivity,
}, {

  /* фильтр тип продаж, но ТОЛЬКО комплекты */
  filterId: sellTypeOnlyComplex,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Тип продаж',
    header: 'Выбор типа продаж',
  },
  getItemsAndCurrent: sellTypeOnlyComplexItems,
  activity: sellTypeOnlyComplexActivity,
}, {

  /* фильтр исключения категории Дети с ОВЗ */
  filterId: excludeOVZFilter,
  filterType: filterTypes.PLAIN_CHECKBOX_GROUP,
  props: {
    label: null,
    keepPadding: true,
  },
  getItemsAndCurrent: excludeOVZItems,
  activity: excludeOVZActivity,
}, {

  /* фильтр по режиму питания */
  filterId: foodModeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Режим питания',
    header: 'Выбор режима питания',
  },
  getItemsAndCurrent: foodModeItems,
  relations: [],
  activity: foodModeActivity,
}, {

  /* фильтр отображений данных аккаунта */
  filterId: accountFilter,
  filterType: filterTypes.PLAIN_CHECKBOX_GROUP,
  props: {
    label: 'Отображать',
  },
  getItemsAndCurrent: accountItems,
  activity: accountActivity,
}, {

  /* фильтр тип продукции */
  filterId: productionTypeFilter,
  filterType: filterTypes.PLAIN_ITEMS_SELECTOR,
  multiple: true,
  props: {
    label: 'Вид продукции',
    header: 'Выбор вида продукции',
  },
  getItemsAndCurrent: productionTypeItems,
  relations: [],
  activity: productionActivity,
}, {

  /* фильтр отправки на email */
  filterId: mailFilter,
  filterType: filterTypes.PLAIN_RADIO_GROUP,
  getProps: mailProps,
  getItemsAndCurrent: mailItems,
  activity: mailActivity,
}];

export default filterItems;
