// @flow
import { useState, useCallback, useEffect } from 'react';
import test from 'ramda/es/test';

import { emailRe } from 'app/common/constants/regex';

/**
 *  input Email
 *    + управление полем
 *    + валидация и ошибки
 */

type Options = {
  enable: boolean,
  defaultEmail?: string,
}

export default function useEmail({ enable, defaultEmail = '' }: Options): [
  [string, (e: SyntheticEvent<HTMLInputElement>) => void],
  [string, () => boolean]
] {
  const [email, setEmail] = useState(defaultEmail);
  const [error, setError] = useState<string>('');

  const handleChangeEmail = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setError('');
    setEmail(value);
  }, []);

  const checkErrors = useCallback(() => {
    const required = enable && !email;
    if (required) {
      setError('Введите email, на который придет отчет');
      return false;
    }
    const invalid = enable && !test(emailRe, email);
    if (invalid) {
      setError('Email имеет неверный формат');
      return false;
    }
    setError('');
    return true;
  }, [enable, email]);

  useEffect(() => {
    if (!enable) {
      // сброс ошибки при скрытии поля
      setError('');
    }
  }, [enable]);

  useEffect(() => {
    // сброс значения при изменении видимости поля
    // или смены значения по умолчанию, если оно было пустым
    setEmail(state => (!state && defaultEmail ? defaultEmail : state));
  }, [enable, defaultEmail]);


  return [
    [email, handleChangeEmail],
    [error, checkErrors],
  ];
}
