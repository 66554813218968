import { createSelector } from 'reselect';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import slice from 'ramda/es/slice';

import toFixedValue from 'app/common/lib/toFixedValue';


export type UserType = {
  id: string,
  name: string,
  group: string,
  balance: string,
  customerId: number,
  availableForRefund: number,
}

const selectUsers = createSelector(
  [
    ({ users }) => users,
    ({ currentPage, rows }) => ((currentPage - 1) * rows),
    ({ currentPage, rows }) => (currentPage * rows),
  ],
  (users, from, to) => pipe(
    slice(from, to),
    map(({ balance, ...others }) => ({
      ...others,
      balance: toFixedValue(balance),
    }: UserType)),
  )(users),
);

export default selectUsers;
