// @flow
import React, { Fragment, useMemo, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import path from 'ramda/es/path';

import {
  Table,
  useCopyColumnsWidths,
  useHover,
  useCopyContentWidths,
} from 'app/common/ui-components/table';

import CSSSticky from 'app/common/ui-components/css-sticky';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import Loading from 'app/common/components/Loader';
import HScrollbarsWShadow, { useSyncScrolls } from 'app/common/ui-components/h-scrollbars-w-shadow';
import { RowLayoutContent } from 'app/common/layouts/RowLayout';
import Paging from 'app/common/ui/Paging';

import EAWTableTHeadFixed from './EAWTableTHeadFixed';
import EAWTableTHeadMain from './EAWTableTHeadMain';
import { type Item } from './useEAWTableItems';
import EAWTableRowFixed from './EAWTableRowFixed';
import EAWTableRowMain from './EAWTableRowMain';

import styles from './EAWTable.scss';


const cn = classNames;

type Props = {
  items: Array<Item>,
  total: number,
  currentPage: number,
  baseUrl: string,
  loading: boolean,
  loadingRow: number | null,
  rowsPerPage: number,
  settingsKey: string,
  writeAccess: boolean,
  onUpdateEmployees: (employeeId?: number) => Promise<void> | void,
  onPageClick: (e: SyntheticEvent<HTMLAnchorElement>) => void,
}

const EAWTable = ({
  items,
  total,
  currentPage,
  baseUrl,
  loading,
  loadingRow,
  rowsPerPage,
  settingsKey,
  writeAccess,
  onUpdateEmployees,
  onPageClick,
}: Props) => {
  const scrollerRef = useRef < HTMLElement | null > (null);
  const nothing = total === 0;

  const tableSettings: { [string]: boolean } = useSelector(path(['settings', settingsKey]), shallowEqual);
  const updateKey = useMemo(() => ([]), [tableSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const [refFromMain, refToMain] = useCopyColumnsWidths(updateKey);

  const [refTableBody, onMouseEnter, onMouseLeave] = useHover();

  const [
    topScrollerProps,
    middleScrollerProps,
    bottomScrollerProps,
    updateBottomScroll,
  ] = useSyncScrolls();

  const [elementSource, elementsTarget] = useCopyContentWidths(1, updateBottomScroll);
  const [elementSourceFixed, elementsTargetFixed] = useCopyContentWidths(0, updateBottomScroll);

  // на изменение размера контента внутри скролируемой по горизонтали части (Main)
  // нужно скрыть горизотальный скролинг, когда контент не выходит за границы
  const handleChangeContentSize = useCallback((isContentBiggerThanContainer: boolean) => {
    const node = scrollerRef.current;
    if (node) {
      if (isContentBiggerThanContainer) {
        // вкл. скролбара в футере
        node.classList.remove(styles.hidden);
      } else {
        // откл. скролбара в футере
        node.classList.add(styles.hidden);
      }
    }
  }, []);

  return (
    <Fragment>
      <CSSSticky
        number={3}
        className={styles.stickable}
        shadowClassName={styles.shadow}
      >
        <div className={styles.hScrollableContainer}>
          <Table className={styles.table}>
            <thead>
              <EAWTableTHeadFixed
                settings={tableSettings}
                writeAccess={writeAccess}
              />
            </thead>
          </Table>

          <div className={styles.hScrollableContent}>
            <HScrollbarsWShadow
              hideVerticalScrollBar
              hideHorizontalScrollBar
              absolutelyHideHorizontal_FixFF
              {...topScrollerProps}
            >
              <Table className={styles.table} reference={elementsTarget}>
                <thead>
                  <EAWTableTHeadMain
                    settings={tableSettings}
                    reference={refToMain}
                  />
                </thead>
              </Table>
            </HScrollbarsWShadow>
          </div>
        </div>
      </CSSSticky>

      {loading
        && <Loading />}

      {nothing
        && !loading
        && <EmptyWarn>{'Нет записей, удовлетворяющих условиям'}</EmptyWarn>}

      {!nothing
        && <div className={styles.hScrollableContainer}>
          <Table className={styles.table} reference={elementSourceFixed}>
            <thead>
              <EAWTableTHeadFixed
                settings={tableSettings}
                hidden
                dummy
              />
            </thead>
            <tbody ref={refTableBody}>
              {items.map((item, i) => {
                const { id } = item;
                const loadingCurrent = loadingRow === id;

                return (
                  <EAWTableRowFixed
                    key={id}
                    className={cn(styles.tableRowFixed, `index_${i}`)}
                    settings={tableSettings}
                    baseUrl={baseUrl}
                    disabled={loading || loadingCurrent}
                    onUpdateEmployees={onUpdateEmployees}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    {...item}
                  />
                );
              })}
            </tbody>
          </Table>

          <div className={styles.hScrollableContent}>
            <HScrollbarsWShadow
              hideVerticalScrollBar
              hideHorizontalScrollBar
              absolutelyHideHorizontal_FixFF
              onChangeContentSize={handleChangeContentSize}
              {...middleScrollerProps}
            >
              <Table className={styles.table} reference={elementSource}>
                <thead>
                  <EAWTableTHeadMain
                    settings={tableSettings}
                    reference={refFromMain}
                    hidden
                    dummy
                  />
                </thead>
                <tbody ref={refTableBody}>
                  {items.map((item, i) => {
                    const { id } = item;
                    const loadingCurrent = loadingRow === id;

                    return (
                      <EAWTableRowMain
                        key={id}
                        className={cn(styles.tableRowMain, `index_${i}`)}
                        settings={tableSettings}
                        disabled={loading || loadingCurrent}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        {...item}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </HScrollbarsWShadow>
          </div>
        </div>}

      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        {!nothing
          && <div className={styles.hScrollerContent} ref={scrollerRef}>
            <div ref={elementsTargetFixed} />
            <div className={styles.hScrollableContent}>
              <HScrollbarsWShadow
                hideVerticalScrollBar
                disableShadow
                {...bottomScrollerProps}
              >
                <div ref={elementsTarget} className={styles.hScroller} />
              </HScrollbarsWShadow>
            </div>
          </div>}
        <RowLayoutContent paddingfree>
          <Paging
            total={total}
            pageSize={rowsPerPage}
            currentPage={currentPage}
            currentPageSize={items.length}
            onPage={onPageClick}
          />
        </RowLayoutContent>
      </CSSSticky>
    </Fragment>
  );
};

export default EAWTable;
