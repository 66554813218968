// @flow
import React, { useCallback } from 'react';
import classNames from 'classnames/bind';

import styles from './indicator.scss';


const cx = classNames.bind(styles);

const points = [
  'm38.6.9 1 11.3 2.7-.1.1-11.4z',
  'm48.9 1.3-1.8 11.2 2.6.6 2.9-11z',
  'm58.8 4.3-4.6 10.4 2.5 1.2L62.2 6z',
  'M68.1 10 61 18.9l2 1.8 7.9-8.2z',
  'm75.2 17.5-9.1 6.8 1.6 2.2 9.6-5.9z',
  'm80.3 26.5-10.5 4.3.9 2.6 10.9-3.3z',
  'm82.9 36.5-11.2 1.6.2 2.7 11.4-.6z',
  'm83 46.8-11.3-1.3-.4 2.8 11.1 2.2z',
  'm80.6 56.8-10.7-4-1 2.5 10.2 5z',
  'm75.7 65.9-9.3-6.5-1.7 2.2 8.7 7.3z',
  'm68.7 73.5-7.4-8.6-2.1 1.7 6.5 9.3z',
  'M60.1 79.2 55 69l-2.5 1.1 4.1 10.6z',
  'm50.3 82.5-2.4-11.1-2.7.4 1.3 11.3z',
  'm40 83.3.5-11.4-2.8-.2-1.5 11.2z',
  'm29.8 81.5 3.3-10.9-2.6-.9-4.3 10.5z',
  'm20.4 77.2 5.9-9.7L24 66l-6.7 9.1z',
  'm12.3 70.8 8.2-8-1.9-2-8.8 7.1z',
  'm6.1 62.5 9.9-5.7-1.3-2.4-10.3 4.7z',
  'm2.2 53 10.9-3.1-.6-2.7-11.2 2.1z',
  'm.7 42.7 11.4-.2v-2.8L.8 38.9z',
  'M1.8 32.5 12.9 35l.7-2.6-10.7-3.6z',
  'M5.5 22.8 15.6 28l1.3-2.3-9.5-6.2z',
  'm11.4 14.3 8.5 7.6 1.9-2-7.8-8.3z',
  'm19.2 7.6 6.3 9.4 2.4-1.4-5.4-10z',
  'm28.5 3 3.8 10.7 2.6-.8-2.8-11z',
];

type Props = {
  progress: number, // процент
  className?: string,
}

const Indicator = ({ progress, className }: Props) => {
  const progressPoints = progress / 4;

  const getPointClass = useCallback(index => cx({
    error: progress < 0,
    active: progress > 0 && index < progressPoints,
  }), [progress, progressPoints]);

  return (
    <div className={classNames(styles.root, className)}>
      <svg width="84px" height="84px" viewBox="0 0 84 84" className={styles.svg}>
        {progress
          ? points.map((item, i) => (
            <path
              key={i} // eslint-disable-line react/no-array-index-key
              d={item}
              className={getPointClass(i)}
            />
          ))
          : points.map((item, i) => (
            <path
              key={i} // eslint-disable-line react/no-array-index-key
              d={item}
            />
          ))}
      </svg>
      <div className={styles.label}>
        {`${progress < 0 ? 0 : progress}%`}
      </div>
    </div>
  );
};

export default Indicator;
