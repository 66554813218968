import { connect } from 'react-redux';
import compose from 'recompose/compose';
import renderNothing from 'recompose/renderNothing';
import branch from 'recompose/branch';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import isEmpty from 'ramda/es/isEmpty';
import length from 'ramda/es/length';
import assoc from 'ramda/es/assoc';

import withPaging from 'app/common/containers/withPaging';
import withSortState from 'app/common/containers/withSortState';

import selectSchools from './selectSchools';
import selectActual from './selectActual';


const defaultSortKey = 'schoolTitle';
const defaultSortDirection = 'down';

export default compose(
  withSortState(defaultSortDirection, defaultSortKey),

  connect(
    (state, props) => {
      const schools = selectSchools(state, props);
      return ({
        schools,
        totals: state.reports.subsidies.data.totals,
        rows: state.settings.reports.rows,
        total: length(schools),
      });
    },
  ),

  branch(
    ({ schools }) => isEmpty(schools),
    renderNothing,
  ),

  withPaging(),
  // setCurrentPage,
  // handlePage,
  // currentPage

  mapProps(props => assoc('schools', selectActual(props), props)),

  lifecycle({
    componentDidUpdate(prevProps) {
      const { rows, setCurrentPage } = this.props;
      if (rows !== prevProps.rows) {
        setCurrentPage(1);
      }
    },
  }),
);
