// @flow
import { useState, useEffect } from 'react';

import { useFilterData } from 'app/common/ui-components/filter';
import { getFromApplied as getOrgsOrUser } from 'app/components/common/reports/filters/orgsOrUser';
import { orgsOrUser } from 'app/dataTypes/reports/orgsOrUser';

import { getFromApplied as getSortFromApplied, type Sort, sortTypes } from '../ReportFilter/filters/sort';
import { getBooleansFromApplied } from '../ReportFilter/filters/commonBalance';

/**
 *  Управление отображением данных
 */

export { sortTypes };

export type CommonBalanceParams = {
  balanceNegative: boolean,
  balancePositive: boolean,
  balanceZero: boolean,
}

type Settings = {
  commonBalance: ?CommonBalanceParams,
  sort: ?Sort,
  byUserReport: boolean,
  byOrgsReport: boolean,
}

const defaultState: Settings = {
  commonBalance: null,
  sort: null,
  byUserReport: false,
  byOrgsReport: false,
};

export default function useDisplaySettings(ready: boolean): Settings {
  const [,,, { getApplied }] = useFilterData();
  const [settings, setSettings] = useState(defaultState);

  useEffect(() => {
    if (ready) {
      const applied = getApplied();
      if (applied) {
        setSettings({
          commonBalance: getBooleansFromApplied(applied),
          sort: getSortFromApplied(applied) || sortTypes.BY_ALPHABET,
          byUserReport: getOrgsOrUser(applied) === orgsOrUser.ByUser,
          byOrgsReport: getOrgsOrUser(applied) === orgsOrUser.ByOrgs,
        });
      }
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  return settings;
}
