// @flow
import type { Request } from '../../../index';

export type Params = {
  title: string,
  number: string,
  model_id: number,
  version_id: number,
  school_id: number,
}

export type Machine = {
  id: number,
  title: string,
  // number: string,
  model_id: number,
  version_id: number,
  school_id: number,
  photo_url: string,
  deleted: null | boolean,
}

export type Result = {
  data: Machine,
  result?: 'success',
}

export const POST_VENDING_MACHINES: Request<Params, Result, void> = [
  'data/vending/machines',
  'post',
  ['data'],
];
