// @flow
import { dataKey as profileDataKey } from 'app/components/Profile';


type Options = {
  section?: string,
}

const emptyOptions: Options = Object.freeze({});

const getBaseUrl = (options: Options = emptyOptions) => {
  const { section } = options;

  if (section) {
    return `/${profileDataKey}/${section}`;
  }

  return `/${profileDataKey}`;
};

export default getBaseUrl;
