// @flow
import { foodModes } from 'app/dataTypes/foodModes';
import { defaultStateDataCurrent, type StateDataItem } from 'app/common/ui-components/filter';


const foodModeItems = (): [Array<StateDataItem>, Array<string>] => ([[{
  id: foodModes.Breakfast,
  title: 'Завтрак',
}, {
  id: foodModes.Dinner,
  title: 'Обед',
}, {
  id: foodModes.Afternoon,
  title: 'Полдник',
}], defaultStateDataCurrent]);

export default foodModeItems;
