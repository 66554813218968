// @flow
import React from 'react';
// import values from 'ramda/es/values';

import { ButtonsBar } from 'app/common/ui/Button';
import { PlainGroup, PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainSelect from 'app/common/ui/PlainSelect';
// import Checkbox, { CheckboxGroup } from 'app/common/ui/Checkbox';
import Loader from 'app/common/components/Loader';

import enhanceKit2MenuForm from './enhanceKit2MenuForm';
import styles from './Kit2MenuForm.scss';


type Item = {
  id: number,
  title: string,
};

type Props = {
  saving: boolean,
  loading: boolean,
  returnTo2: string,
  kitName: string,
  sellPoints: Array<Item>,
  // categories: Array<Item>,
  handleChange: (Event | Object) => void,
  // handleCheck: (Event) => void,
  handleSubmit: (Event) => void,
  current: {
    sellPoint: ?number,
    // category: number[],
  },
};

const Kit2MenuForm = ({
  saving,
  loading,
  returnTo2,
  kitName,
  sellPoints,
  // categories,
  handleChange,
  // handleCheck,
  handleSubmit,
  current,
}: Props) => (<div className={styles.root}>
  <h2 className={styles.header}>{'Добавление комплекта в меню'}</h2>
  <form onSubmit={handleSubmit}>
    <PlainGroup
      className={styles.content}
    >
      <PlainColumns>
        <PlainColumn>
          <label htmlFor="dishes">{`Комплект «${kitName}» будет добавлен`}</label>
        </PlainColumn>
        {(sellPoints.length > 1 && !loading)
            && <PlainColumn>
              <PlainSelect
                name="sellPoint"
                placeholder="Выберите точку продаж"
                label="в меню точки продажи:"
                onChange={handleChange}
                // $FlowFixMe
                data={sellPoints}
                current={current.sellPoint}
                disable={loading || saving}
                uni
              />
            </PlainColumn>}
      </PlainColumns>

      {loading && <Loader className={styles.loader} />}

      {/* {(current.sellPoint || sellPoints.length < 2)
          && !loading
          && <PlainColumns>
            <CheckboxGroup
              label={sellPoints.length > 1
                ? 'Выберите категорию меню:'
                : 'в категорию меню:'}
            >
              {categories.map(category => (
                // $FlowFixMe
                <Checkbox
                  key={category.id}
                  label={category.title}
                  value={category.id}
                  disabled={loading || saving}
                  checked={current.category[category.id]}
                  onChange={handleCheck}
                />
              ))}
            </CheckboxGroup>
          </PlainColumns>} */}
    </PlainGroup>

    <ButtonsBar
      className={styles.buttons}
      right={[{
        key: 'cancel',
        value: 'Отмена',
        href: returnTo2,
        outline: true,
      }, {
        key: 'copy',
        value: saving ? 'Добавление...' : 'Добавить',
        // disabled: loading || saving || !values(current.category).find(a => a),
        disabled: loading || saving,
        submit: true,
      }]}
    />
  </form>
</div>);

export default enhanceKit2MenuForm(Kit2MenuForm);
