// @flow
import React, { type Node, useState, useCallback } from 'react';

import Modal from 'app/common/components/Modal';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';


type Props = {
  className?: string,
  children: Node,
  modalContentFunc: (toggleModal: () => void) => Node,
}

const CellContentModalByClickLink = ({ className, children, modalContentFunc }: Props) => {
  const [modalVisible, setModalVisibility] = useState(false);
  const toggleModalVisibility = useCallback(() => {
    setModalVisibility(state => !state);
  }, []);

  if (!children || !modalContentFunc) {
    return null;
  }

  return (
    <div className={className}>
      <PlainButtonAsLink onClick={toggleModalVisibility}>
        {children}
      </PlainButtonAsLink>
      {modalVisible
        && <Modal toggleModal={toggleModalVisibility}>
          {modalContentFunc(toggleModalVisibility)}
        </Modal>}
    </div>
  );
};

export default CellContentModalByClickLink;
