export const controls = {
  activeCheckbox: 'active',
  selectionRadio: 'selection',
  sumInput: 'sum',
  sumForComplexesCheckbox: 'sum_for_complexes',
  complexesList: 'complexes',
  additionalList: 'additional',
  complexesListDefaults: 'complexes_defaults',
} as const

export const selection = {
  complexes: 'complexes',
  chosen_complex: 'chosen_complex',
  sum: 'sum',
} as const
