// @flow
import { type State } from 'app/common/ui-components/filter';
import { employeeActivity as employeeAvtivityDefault } from 'app/components/common/reports/filters/employee';
import { flags as currentStateDateFlags } from 'app/components/common/reports/filters/currentStateDate';


const employeeActivity = (state: State) => {
  return (
    employeeAvtivityDefault(state)
    && currentStateDateFlags.currentStateRadioSelected(state)
  );
};

export default employeeActivity;
