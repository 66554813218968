// @flow
import React, { type ElementType, type Node, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import componentFromProp from 'recompose/componentFromProp';
import classNames from 'classnames/bind';
import length from 'ramda/es/length';

import Pager from 'app/common/ui/Paging';
import withShadows from 'app/common/containers/withShadows';

import styles from './PlainTable.scss';

// ----------------
// Простая таблица с данными и заголовком
// ----------------

type Props = {
  className?: string,
  header?: ElementType,
  items?: Array<Object>,
  children?: (Object, ?boolean) => Node,
  emptyWarn?: string,
  loading?: boolean,
  // Постраничная навигация, use: app/common/containers/withPaging
  total: number,
  rows: number,
  currentPage: number,
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  // Тени, use: app/common/containers/shadows
  updateScroll: (Object) => void,
  isTopShadow: boolean,
  isBottomShadow: boolean,
  settings?: Object,
  selectedAll?: boolean,
  onSelectAll?: () => void,
  onUnselectAll?: () => void,
}

const Header = componentFromProp('header');
const cx = classNames.bind(styles);

const PlainTable = ({
  className,
  header,
  items,
  children,
  emptyWarn,
  loading,

  total,
  rows,
  currentPage,
  handlePage,

  updateScroll,
  isTopShadow,
  isBottomShadow,

  settings,
  selectedAll,
  onSelectAll,
  onUnselectAll,
}: Props) => {
  const handleChangeSelectAll = useCallback(() => {
    if (selectedAll && onUnselectAll) {
      onUnselectAll();
      return;
    }
    if (!selectedAll && onSelectAll) {
      onSelectAll();
    }
  }, [selectedAll, onSelectAll, onUnselectAll]);


  return (
    <div className={cx(styles.root, className)}>
      <Header
        header={header}
        settings={settings}
        checked={selectedAll}
        onChangeSelectAll={handleChangeSelectAll}
        showCheckbox
      />
      <div className={styles.items}>
        <Scrollbars
          className={cx({ isTopShadow, isBottomShadow })}
          onUpdate={updateScroll}
        >
          {(items && length(items))
            ? items.map(itemsProps => (typeof children === 'function'
              && children(itemsProps, loading)))
            : <div className={styles.empty}>{emptyWarn}</div>}
        </Scrollbars>
      </div>
      {(total > rows)
        && <div className={styles.pager}>
          <Pager
            total={total}
            pageSize={rows}
            currentPage={currentPage}
            currentPageSize={length(items)}
            onPage={handlePage}
          />
        </div>}
    </div>
  );
};

PlainTable.defaulProps = {
  items: [],
  emptyText: 'Нет записей, удовлетворяющих условиям',
};

export default withShadows(PlainTable);
