// @flow
import React, { type Node, type Ref, useCallback, useState, useRef, useEffect } from 'react';
import cn from 'classnames/bind';

import useTooltip from './use-tooltip';
import useCorrectPosition from './use-correct-position';

import styles from './plain-tooltip.scss';


const cx = cn.bind(styles);

export type PlainTooltipProps = {
  className?: string,
  popupContentClassName?: string,
  popupArrowClassName?: string,
  text?: ?string,
  content?: ?Node,
  children?: Node | ((ref: Ref<'any'>) => Node),
  leftEdge?: boolean,
  rightEdge?: boolean,
  hideWithoutOverflow?: boolean,
  style?: Object,
  enableOverflow?: boolean,
}

const PlainTooltip = ({
  className,
  popupContentClassName: pcClassName,
  popupArrowClassName: paClassName,
  children,
  text,
  content,
  leftEdge,
  rightEdge,
  hideWithoutOverflow,
  style: st,
  enableOverflow,
}: PlainTooltipProps): Node => {
  const [{ show, style }, mouseHandlers] = useTooltip();
  const [tooltipRef, leftShift, rightShift, dropBottom, appear] = useCorrectPosition(!show);

  const [overflowed, setOverflowed] = useState(false);
  const elementRef = useRef(null);

  const handleElement = useCallback((el: ?HTMLElement) => {
    elementRef.current = el;
    if (!el) return;
    setOverflowed(el ? el.scrollWidth > el.clientWidth : false);
  }, []);

  useEffect(() => {
    const el = elementRef.current;
    if (!el) return;
    setOverflowed(el ? el.scrollWidth > el.clientWidth : false);
  }, [children]);

  const popupClassName = cx(styles.tooltip, {
    dropBottom,
    appear,
  });

  const popupContentClassName = cx(
    styles.content,
    pcClassName,
    {
      fixed: !!text,
      leftEdge: leftEdge || leftShift,
      rightEdge: rightEdge || rightShift,
      dropBottom,
    },
  );

  const arrowUpClassName = cx(styles.arrowUp, paClassName);
  const arrowClassName = cx(styles.arrow, paClassName);

  const empty = !(text || content);

  return (
    <span
      {...mouseHandlers}
      className={cx({ asBlock: typeof children !== 'function', enableOverflow }, className)}
      ref={typeof children === 'function' ? null : handleElement}
      style={st}
    >
      {show
        && !empty
        && (hideWithoutOverflow ? overflowed : true)
        && <div
          className={styles.container}
          style={style}
        >
          <div className={popupClassName}>
            {dropBottom && <div className={arrowUpClassName} />}

            <div ref={tooltipRef} className={popupContentClassName}>
              {text || content}
            </div>

            {!dropBottom && <div className={arrowClassName} />}
          </div>
        </div>}

      {typeof children === 'function'
        ? children(handleElement)
        : children}
    </span>
  );
};

export default PlainTooltip;
