import assoc from 'ramda/es/assoc';
import always from 'ramda/es/always';
import evolve from 'ramda/es/evolve';

import {
  SELLPOINTS_REQUEST,
  SELLPOINTS_SUCCESS,
  SELLPOINTS_FAILURE,
  SELLPOINTS_CLEAR,
  SELLPOINTS_CHANGE_SELECTION,
} from 'app/actions/orgs/settings/sellpoints';

import {
  SELLPOINTS_CHANGE_REQUEST,
  SELLPOINTS_CHANGE_SUCCESS,
  SELLPOINTS_CHANGE_FAILURE,
} from 'app/actions/orgs/settings/sellpoints/change';

import {
  SELLPOINTS_CHANGE_ARRAY_REQUEST,
  SELLPOINTS_CHANGE_ARRAY_SUCCESS,
  SELLPOINTS_CHANGE_ARRAY_FAILURE,
} from 'app/actions/orgs/settings/sellpoints/changeArray';

import { SELLPOINTS_ADD_SUCCESS } from 'app/actions/orgs/settings/sellpoints/add';

const defaultState = {
  items: {},
  loading: true,
  selected: [],
  selectedTypeIsDeleted: false,
};

const sellpoints = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SELLPOINTS_CHANGE_REQUEST:
    case SELLPOINTS_CHANGE_ARRAY_REQUEST:
    case SELLPOINTS_REQUEST: {
      return assoc('loading', true, state);
    }
    case SELLPOINTS_SUCCESS: {
      return evolve({
        loading: always(false),
        items: always(payload),
        selected: always([]),
      }, state);
    }
    case SELLPOINTS_CHANGE_SELECTION: {
      return { ...state, ...payload };
    }
    case SELLPOINTS_CLEAR: {
      return defaultState;
    }
    case SELLPOINTS_CHANGE_SUCCESS:
    case SELLPOINTS_CHANGE_ARRAY_SUCCESS:
    case SELLPOINTS_ADD_SUCCESS: {
      return evolve({
        loading: always(false),
        items: items => ({ ...items, ...payload }),
        selected: always([]),
      }, state);
    }
    case SELLPOINTS_FAILURE:
    case SELLPOINTS_CHANGE_ARRAY_FAILURE:
    case SELLPOINTS_CHANGE_FAILURE: {
      return assoc('loading', false, state);
    }
    default:
  }
  return state;
};
export default sellpoints;
