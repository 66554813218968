
const lang = {
  confirmArrayDeleteText: 'Вы уверены, что хотите перемесить %confirmText% в удаленные?',
  confirmArrayRestoreText: 'Вы уверены, что хотите восстановить %confirmText%?',
  tooltipArrayDeleteText: 'Необходимо выбрать действующую точку продаж',
  tooltipArrayRestoreText: 'Необходимо выбрать удаленную точку продаж',
  removeArrayButtonText: 'Удалить',
  restoreArrayButtonText: 'Восстановить',
  namesByNumber: ['точку продаж', 'точки продаж', 'точек продаж'],
  popupDeleteItemSuccess: 'Точка продаж успешно перенесена в удаленные',
  popupRestoreItemSuccess: 'Точка продаж успешно восстановлена',
  popupTitleSuccess: 'Название точки продаж успешно изменено',
  popupDeleteArraySuccess: '%target% удалось успешно перенести в удаленные',
  popupRestoreArraySuccess: '%target% удалось успешно восстановить',
  popupAddSuccess: 'Точка продаж «%target%» успешно создана',
};

export default lang;
