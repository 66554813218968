import { isEmpty } from 'ramda'
import { ReactI18NextChild } from 'react-i18next'
import { useMemo } from 'react'

import EmptyWarn from 'app/common/layouts/EmptyWarn'
import Loading from 'app/common/components/Loader'
import SimpleDate from 'app/common/classes/simple-date'

import useOrgsKitsExtended from './useOrgsKitsExtended'
import { KitDishesExtended } from './DishesKitExtended/KitDishesExtended'
import { ValidityByDateProvider } from '../../commonComponents/ValidityByDate'


type Props = {
  orgID: string,
  orgType: string,
  action?: string,
  kitID?: string,
  useGrocerySetPrice: boolean,
  date: string,
  basePath: string,
}

const KitsListExt = ({
  action,
  orgType,
  orgID,
  kitID,
  useGrocerySetPrice,
  date,
  basePath,
}: Props): ReactI18NextChild => {
  const [items, loading] = useOrgsKitsExtended(orgID, { date })
  const nothing = isEmpty(items)

  const currentDate = useMemo(() => {
    return new SimpleDate(date)
  }, [date])

  if (loading && nothing) {
    return (
      <Loading />
    )
  }

  if (nothing) {
    return (
      <EmptyWarn>
        {'Нет комплектов для выбранного дня'}
      </EmptyWarn>
    )
  }

  return (
    <ValidityByDateProvider orgID={orgID} currentDate={currentDate}>
      {items.map(item => (
        <KitDishesExtended
          {...item}
          key={item.id}
          orgID={orgID}
          orgType={orgType}
          kitId={kitID}
          useGrocerySetPrice={useGrocerySetPrice}
          date={date}
          action={action}
          loading={loading}
          basePath={basePath}
        />))}
    </ValidityByDateProvider>
  )
}

export default KitsListExt
