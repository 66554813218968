// @flow
import { getStateCurrentValue, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const singleYearSelected = (state: State) => {
  const yearIds = getStateCurrentValue(filterId, state) || [];
  return yearIds.length === 1;
};
