import cn from 'classnames/bind'

import styles from './Sum.scss'


const cx = cn.bind(styles)

type Props = {
  value: string
  status: 'negative' | 'positive'
}

export const Sum = ({ value, status }: Props) => {
  return (
    <span className={cx({ [status]: true })}>
      {value}
    </span>
  )
}
