import { DETAILS_FILTER_SET } from 'app/actions/reports/filters/details';


const defaultState = false;

const details = (state = defaultState, { type, payload }) => {
  if (type === DETAILS_FILTER_SET) {
    return payload;
  }
  return state;
};

export default details;
