// @flow
import React, { useState, useCallback, type Node, Fragment, useEffect } from 'react';

import Modal from 'app/common/components/Modal';
import ContrlolsBar from 'app/common/ui-next/controls-bar';
import PlainButton from 'app/common/ui-next/plain-button';

import styles from './plain-message-modal.scss';


/**
 *  Модальное окно с сообщением и кнопкой ОК
 */
export type PlainMessageModalProps = {
  defaultState?: boolean,
  externalState?: boolean,
  onClose?: (close?: boolean) => void,
  messageText?: string,
  closeButtonTitle?: string,
  children?: Node | (toggleMessage: () => void) => Node
}

const PlainMessageModal = ({
  defaultState = false,
  externalState,
  onClose,
  messageText = 'Сообщение не задано',
  closeButtonTitle = 'OK',
  children,
}: PlainMessageModalProps) => {
  const [appear, setUppear] = useState(defaultState);

  const toggleMessage = useCallback(() => {
    setUppear(appear_ => !appear_);
    if (externalState && onClose) {
      onClose(false);
    }
  }, [onClose, externalState]);

  useEffect(() => {
    if (typeof externalState === 'boolean') {
      setUppear(externalState);
    }
  }, [externalState]);

  return (
    <Fragment>
      {typeof children === 'function'
        ? children(toggleMessage)
        : children}
      {appear
        && <Modal className={styles.root}>
          <div className={styles.messageText}>
            {messageText.split('\n').map((str, i) => (<Fragment key={String(i)}>
              {str}
              <br />
            </Fragment>))}
          </div>
          <ContrlolsBar middle>
            <PlainButton onClick={toggleMessage}>
              {closeButtonTitle}
            </PlainButton>
          </ContrlolsBar>
        </Modal>}
    </Fragment>
  );
};

export default PlainMessageModal;
