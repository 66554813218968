// @flow
import React, { Fragment, useState, useCallback, type Node } from 'react';
import not from 'ramda/es/not';

import PlainButton from 'app/common/ui-next/plain-button';
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal';
import usePopup from 'app/common/hooks/usePopup';


type Props = {
  className?: string,
  disabled?: boolean,
  title: string,
  onDelete: () => boolean | Promise<boolean>,
  onDeleteSuccess: () => void,
}

const DeleteMachineButton = ({
  className,
  disabled,
  title,
  onDelete,
  onDeleteSuccess,
}: Props): Node => {
  const popup = usePopup();

  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const toggleDeleteConfirmation = useCallback(() => {
    setDeleteConfirmationVisible(not);
  }, []);

  const deleteConfirmed = useCallback(async () => {
    setDeleteConfirmationVisible(false);
    const res = await onDelete();

    if (res) {
      popup(`Аппарат «${title}» успешно удален`);
      onDeleteSuccess();
    }
  }, [onDelete, onDeleteSuccess, popup, title]);

  return (
    <Fragment>
      <PlainButton
        className={className}
        onClick={toggleDeleteConfirmation}
        disabled={disabled}
        danger
      >
        {'Удалить'}
      </PlainButton>

      {deleteConfirmationVisible
        && <PlainConfirmModal
          messageText={`Вы уверены? Удалить вендинговый аппарат «${title}»?`}
          cancelButtonTitle="Отмена"
          confirmButtonTitle="Удалить"
          onCancel={toggleDeleteConfirmation}
          onConfirm={deleteConfirmed}
          critical
        />}
    </Fragment>
  );
};

export default DeleteMachineButton;
