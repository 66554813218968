// @flow
import { useMemo } from 'react';
import path from 'ramda/es/path';

import type { OrgsSchools } from './useOrgs';


// DEPRECATED

type Options = {
  orgId?: number,
}

export default function useDateFrom(orgs: OrgsSchools, options: Options) {
  const { orgId } = options;

  const from = useMemo(() => {
    return path([orgId, 'from'], orgs);
  }, [orgs, orgId]);

  return from;
}
