// @flow
import { reduce, map, pipe, unnest } from 'ramda';

import Grid, { type IGrid } from 'app/common/classes/grid';

import PlanogramItem, { type RawDataItem } from './planogram-item';
import { createDefaultSlotNaming } from './create-default-slot-naming';
import { createContains } from './create-contains';
import { createGrid } from './create-grid';
import { createPlanogramItem } from './create-planogram-item';


export type Contains = {
  productId: string | null,
  amount: number | null,
}

const defaultContains: Contains = {
  productId: null,
  amount: null,
};

export type IcommingContains = {
  productId: string | null,
  amount: number | null,
}

export interface IPlanogramData<T: PlanogramItem> extends IGrid<T> {
  naming: Array<Array<string>>,
  contains: Array<Array<Contains>>,
  duplicate(newInstance?: Array<RawDataItem>): IPlanogramData<T>,
  setNaming(naming: Array<Array<string>>): IPlanogramData<T>,
  setContains(containsBySlotId: Map<string, IcommingContains>): IPlanogramData<T>,
  getChangedNames(startIndex: number, items: Array<T>): Array<T>,
  asListNaming(): $ReadOnlyArray<string>,
  asListContains(): $ReadOnlyArray<Contains>,
  forEachSlot((T) => void): void,
}

export default class PlanogramData<T: PlanogramItem = PlanogramItem>
  extends Grid<T> implements IPlanogramData<T> {
  #naming: Array<Array<string>>;

  #contains: Array<Array<Contains>>;

  constructor(
    items?: Array<Array<T>> = [],
    naming?: Array<Array<string>>,
    contains?: Array<Array<Contains>>,
  ) {
    const columns = PlanogramData.calcColumnsNumber(items);
    const rows = items.length;
    super(items, columns, rows);
    this.#naming = naming || createDefaultSlotNaming(columns, rows);
    this.#contains = contains || createContains(columns, rows, () => defaultContains);
  }

  static calcColumnsNumber(items: Array<Array<T>>): number {
    const firstRow = items[0];
    if (!firstRow) return 0;
    return reduce((acc, { width }) => (acc + width), 0, firstRow);
  }

  static updateColumnProp(startColumn: number, items: Array<T>): Array<T> {
    let columnAcc = startColumn;
    return map((item) => {
      const column = columnAcc;
      columnAcc += item.width;
      return item.duplicate(() => ({ column }));
    }, items);
  }

  get naming(): Array<Array<string>> {
    return this.#naming;
  }

  get contains(): Array<Array<Contains>> {
    return this.#contains;
  }

  getGridMap(): Map<number, Map<number, T>> {
    return reduce((acc, val) => {
      if (val && val[0]) {
        const rowNum = val[0].row;
        const rowMap = reduce((accR, item) => {
          const { column } = item;
          accR.set(column, item);
          return accR;
        }, new Map(), val);
        acc.set(rowNum, rowMap);
      }
      return acc;
    }, new Map(), this.items);
  }

  duplicate(rawItems?: Array<RawDataItem>): PlanogramData<T> {
    const grid: Array<Array<T>> = rawItems ? pipe(
      map(createPlanogramItem),
      x => createGrid<T>(x),
    )(rawItems) : this.items;

    return new PlanogramData(grid, this.#naming, this.#contains);
  }

  setNaming(naming: Array<Array<string>>): PlanogramData<T> {
    this.#naming = naming;
    return this;
  }

  setContains(goodsNumberBySlotId: Map<string, IcommingContains>): PlanogramData<T> {
    const grid = this.getGridMap();
    this.#contains = createContains(this.columns, this.rows, (col, row) => {
      const rowItems = grid.get(row);
      if (rowItems) {
        const { id } = rowItems.get(col) || {};
        const { productId, amount } = goodsNumberBySlotId.get(id) || defaultContains;
        return { productId, amount };
      }
      return defaultContains;
    });
    this.updateSubscribers();
    return this;
  }

  getChangedNames(startIndex: number, items: Array<T>): Array<T> {
    let index = startIndex;
    return items.map((planogramItem) => {
      const newItem = planogramItem.duplicate(({ row }) => ({
        nonTrustedSlotName: this.#naming[row][index],
      }));
      const { width } = planogramItem;
      index += width;
      return newItem;
    });
  }

  asListNaming(): $ReadOnlyArray<string> {
    return unnest<string>(this.#naming);
  }

  asListContains(): $ReadOnlyArray<Contains> {
    return unnest<Contains>(this.#contains);
  }

  forEachSlot(cb: (T) => void): void {
    this.items.forEach((row) => {
      row.forEach(cb);
    });
  }
}
