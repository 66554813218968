// @flow
import api from 'app/actions/api';
import get from 'app/common/lib/get';


export const EMPLOYEE_SCHOOLS_REQUEST = 'EMPLOYEE_SCHOOLS_REQUEST';
export const EMPLOYEE_SCHOOLS_SUCCESS = 'EMPLOYEE_SCHOOLS_SUCCESS';
export const EMPLOYEE_SCHOOLS_FAILURE = 'EMPLOYEE_SCHOOLS_FAILURE';
export const EMPLOYEE_SCHOOLS_CLEAR = 'EMPLOYEE_SCHOOLS_CLEAR';
export const EMPLOYEE_SCHOOLS_ADD = 'EMPLOYEE_SCHOOLS_ADD';
export const EMPLOYEE_SCHOOLS_CHANGE_FIELD = 'EMPLOYEE_SCHOOLS_CHANGE_FIELD';

export const dateFormat = 'YYYY-MM-DD';

const employeeSchoolsRequest = payload => ({
  type: EMPLOYEE_SCHOOLS_REQUEST,
  payload,
});

const employeeSchoolsSuccess = payload => ({
  type: EMPLOYEE_SCHOOLS_SUCCESS,
  payload,
});

const employeeSchoolsFailure = payload => ({
  type: EMPLOYEE_SCHOOLS_FAILURE,
  payload,
});

export const employeeSchoolsClear = () => ({
  type: EMPLOYEE_SCHOOLS_CLEAR,
});

export const employeeSchoolsAdd = (payload: Object) => ({
  type: EMPLOYEE_SCHOOLS_ADD,
  payload,
});

export const employeeSchoolsChangeField = (payload: Object) => ({
  type: EMPLOYEE_SCHOOLS_CHANGE_FIELD,
  payload,
});

export const employeeSchoolsFetch = (id: number | string): Object => async (dispatch) => {
  dispatch(employeeSchoolsRequest(id));
  try {
    const res = await api(`food/employees/${id}/schools`, 'get');
    const schools = get(res, ['data', 'schools'], {});
    dispatch(employeeSchoolsSuccess(schools));
    return schools;
  } catch (error) {
    dispatch(employeeSchoolsFailure({ err: 'Запрос мест работы для сотрудника не удался', error }));
    return null;
  }
};
