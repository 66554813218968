import { createSelector } from 'reselect';
import { getTokenIsExternal } from 'app/common/lib/token';


const selectIsChangePassword = createSelector(
  state => state.user.update,
  update => !!(update && update.password) && !getTokenIsExternal(),
);

export default selectIsChangePassword;
