// @flow
import React from 'react';

import nm from 'app/common/lib/getNumEnding';

import styles from './MultipleModalBlockInfo.scss';


type Props = {
  remaining?: number,
}

const MultipleModalBlockInfo = ({ remaining }: Props) => {
  if (!remaining) {
    return null;
  }

  const hours = Math.floor(remaining / 3600000);
  const minutes = Math.floor(remaining % 3600000 / 60000);

  const sHours = hours ? `${hours} ${nm(hours, ['час', 'часа', 'часов'])} ` : '';
  const sMinutes = minutes ? `${minutes} ${nm(minutes, ['минута', 'минуты', 'минут'])}` : '';

  return (
    <span className={styles.root}>
      {`Повторное применение, во множествe заведений, возможно через ${sHours}${sMinutes}`}
    </span>
  );
};

export default MultipleModalBlockInfo;
