// @flow
import React, { type Node, useMemo, useState, useCallback, useEffect, type ComponentType } from 'react';
import { isEmpty, omit, assocPath, map, evolve } from 'ramda';

import * as types from 'app/common/constants/identifiers/types';

import { type Identifiers, type ReassignTarget } from './identifiers-types';
import IdentifiersContext, { defaultIdentifiers, defaultReassignTargets } from './identifiers-context';
import moduleType2AttributeName from './module-type-2-attribute-name';
import * as moduleTypes from './module-types';
import maskW58 from './maskW58';


const emptyArr = [];
const emptyObj = {};
// $FlowFixMe
const masking: (Identifiers) => Identifiers = map(evolve({ w58: maskW58 }));

/**
 *  ИДЕНТИФИКАТОРЫ -- IdentifiersData
 *
 *  Компонент данных для идентификаторов. Содержит данные идентификаторов
 *  и методы для работы с этими данными. Все это доступно через контекст
 *  во всех дочерних компонентах.
 *
 */

export type IdentifiersDataProps = {
  personId: number, // id пользователя (обычно customer_id на бэкэнде)
  locked?: boolean, // вывести только для чтения
  hiddenModuleSwitchers?: Array<$Values<typeof moduleTypes>>, // скрытые кнопки переключения модулей
  children: Node,
  additional?: Identifiers,

  // Запросы зависимые от кабинета для операций и получения данных идентификаторов
  onDataRequest: (personId: number) => Promise<?Identifiers>,
  onBlockIdentifier: (identifierId: number, reason: string, personId?: number) => Promise<?Identifiers>,
  onUnblockIdentifier: (identifierId: number, personId?: number) => Promise<?Identifiers>,
  onReassignIdentitier?: (
    identifierId: number,
    studentId: number,
    personId?: number
  ) => Promise<?Identifiers>,
  onChangeIdentifierAttribute?: (
    identifierId: number,
    attributeName: string,
    attributeValue: mixed
  ) => Promise<?Identifiers>,
  onAddIdentifier?: (type: $Values<typeof types>, number: string) => Promise<?Identifiers>,

  timezone?: string,

  // reassign - это преназначение
  // идентификатора другому пользователю
  reassignEnabled?: boolean,
  reassignTargets?: Array<ReassignTarget>, // пользователи кому можно переназначить
  reassignItemView?: ComponentType<any>, // компонент для отображения пользователя-цели в списке выбора
}

const IdentifiersData = ({
  personId,
  locked = false,
  children,
  hiddenModuleSwitchers = emptyArr,
  additional = emptyObj,

  onDataRequest,
  onBlockIdentifier,
  onUnblockIdentifier,
  onReassignIdentitier,
  onChangeIdentifierAttribute,
  onAddIdentifier,

  timezone = 'Europe/Moscow',
  reassignEnabled,
  reassignTargets,
  reassignItemView,
}: IdentifiersDataProps): Node => {
  const [identifiers, setIdentifiers] = useState<Identifiers>(defaultIdentifiers);
  const [loadingIdentifiers, setLoadingIdentifiers] = useState(true);
  const [addingIdentifier, setAddingIdentifier] = useState(false);

  const addition = useCallback((add: Identifiers) => {
    const { result, ...others } = add;
    setIdentifiers(i9s => ({ ...i9s, ...masking(others) }));
  }, []);

  useEffect(() => {
    if (!isEmpty(additional)) {
      addition(additional);
    }
  }, [additional]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  Запрос данных по идентификаторам
   */

  const requestIdentifiers = useCallback(async () => {
    setLoadingIdentifiers(true);
    const res: Identifiers = await onDataRequest(personId) || defaultIdentifiers;
    setIdentifiers(masking(res));
    setLoadingIdentifiers(false);
  }, [personId, onDataRequest]);

  // автозагрузка при монтировании и изменении входящих данных
  useEffect(() => {
    requestIdentifiers();
  }, [requestIdentifiers]);

  /**
   *  блокировка идентификатора
   */

  const requestBlockIdentifier = useCallback(async (identifierId: number, reason: string) => {
    const res = await onBlockIdentifier(identifierId, reason, personId);
    if (res) {
      addition(res);
      return true;
    }
    return false;
  }, [onBlockIdentifier, personId, addition]);

  /**
   *  разблокировка идентификатора
   */

  const requestUnblockIdentifier = useCallback(async (identifierId: number) => {
    const res = await onUnblockIdentifier(identifierId, personId);

    if (res) {
      addition(res);
      return true;
    }
    return false;
  }, [onUnblockIdentifier, personId, addition]);

  /**
   *  переназначение идентификатора
   */

  const requestReassignIdentifier = useCallback(async (identifierId: number, studentId: number) => {
    if (!onReassignIdentitier) return false;
    const res = await onReassignIdentitier(identifierId, studentId);

    if (res) {
      setIdentifiers(omit([String(identifierId)]));
      return true;
    }
    return false;
  }, [onReassignIdentitier]);

  /**
   *  Изменение атрибутов идентификатора
   */

  const requestSwitchIdentifierModule = useCallback(async (
    identifierId: number,
    moduleType: $Values<typeof moduleTypes>,
    newValue: boolean,
  ) => {
    if (!onChangeIdentifierAttribute) return false;
    const attributeName = moduleType2AttributeName[moduleType];

    setIdentifiers(assocPath([identifierId, attributeName], newValue));

    const res: Identifiers = await onChangeIdentifierAttribute(
      identifierId,
      attributeName,
      newValue,
    ) || identifiers;

    addition(res);
    if (res === identifiers) {
      return false;
    }
    return true;
  }, [onChangeIdentifierAttribute, identifiers, addition]);

  /**
   *  добавление нового идентификатора
   */

  const requestAddNewIdentifier = useCallback(async (type: $Values<typeof types>, number: string) => {
    if (!onAddIdentifier) return false;
    setAddingIdentifier(true);
    const res = await onAddIdentifier(type, number);
    setAddingIdentifier(false);

    if (res) {
      addition(res);
      return true;
    }
    return false;
  }, [onAddIdentifier, addition]);

  const contextValue = useMemo(() => ({
    content: {
      personId,
      locked,
      identifiers,
      timezone,
      reassignEnabled: reassignEnabled || false,
      reassignTargets: reassignTargets || defaultReassignTargets,
      hiddenModuleSwitchers,
    },
    loading: {
      identifiers: loadingIdentifiers,
      newIdentifier: addingIdentifier,
    },
    request: {
      addition,
      identifiers: requestIdentifiers,
      block: requestBlockIdentifier,
      unblock: requestUnblockIdentifier,
      reassign: requestReassignIdentifier,
      moduleSwitch: requestSwitchIdentifierModule,
      addNewIdentifier: requestAddNewIdentifier,
    },
    views: {
      reassignItemView: reassignItemView || null,
    },
  }), [
    personId,
    locked,
    addition,
    identifiers,
    timezone,
    hiddenModuleSwitchers,
    loadingIdentifiers,
    requestIdentifiers,
    requestBlockIdentifier,
    requestUnblockIdentifier,
    requestReassignIdentifier,
    requestSwitchIdentifierModule,
    reassignEnabled,
    reassignTargets,
    reassignItemView,
    requestAddNewIdentifier,
    addingIdentifier,
  ]);

  return (
    <IdentifiersContext.Provider value={contextValue}>
      {children}
    </IdentifiersContext.Provider>
  );
};

export default IdentifiersData;
