// @flow
import React, { type Node, Fragment, useEffect, useState, useCallback } from 'react';
import cn from 'classnames';

import RowLayout, { Header, HeaderContent, RowLayoutContent } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import Prompt from 'app/components/common/Prompt';
import SaveCancelButtons from 'app/components/common/SaveCancelButtons';
import { useWriteAccess } from 'app/rights/RightsForOrg';
import { useLastChange } from 'app/dataProviders/OrgSettingsHistoryProvider';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import * as settings from 'app/common/constants/schools/settings';
import { PlainItemsSelector } from 'app/common/ui-next/plain-selector';
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider';
import usePopup from 'app/common/hooks/usePopup';

// import MultipleSubmitSettings from '../MultipleSubmitSettings';
import { rightsPath } from '../rightsPath';
import HistoryLink from '../HistoryLink';
import sections, { sectionTitles } from '../sections';
import useGeneralSettings from './useGeneralSettings';
import useFoodPlaceGuests from './useFoodPlaceGuests';

import styles from './Cafeteria.scss';


const currentSection = sections.ORGS_SETTINGS_CAFETERIA;
const header = sectionTitles[currentSection];

type Props = {
  orgId: number,
}

const Cafeteria = ({ orgId: orgIdNum }: Props): Node => {
  const writeAccess = useWriteAccess(rightsPath);
  const [, updateLastChange] = useLastChange();
  const [{ orgs, loading: loadingOrgs }] = useOrgsData();
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const popup = usePopup();

  const orgId = String(orgIdNum);

  const {
    request: requestCafeteriaEnabled,
    submit: submitCafeteriaEnabled,
    cafeteriaEnabled,
    handleChange,
  } = useGeneralSettings(orgId, setModified);

  const {
    request: requestFoodPlaceGuests,
    submit: submitFoodPlaceGuests,
    orgsSelection,
    handleChangeOrgsSelection,
  } = useFoodPlaceGuests(orgId, setModified);

  const requestData = useCallback(async () => {
    setLoading(true);
    await Promise.all([requestFoodPlaceGuests(), requestCafeteriaEnabled()]);
    setLoading(false);
  }, [requestFoodPlaceGuests, requestCafeteriaEnabled]);

  const submitData = useCallback(async () => {
    setSaving(true);
    const [res1, res2] = await Promise.all([submitCafeteriaEnabled(), submitFoodPlaceGuests()]);
    setSaving(false);
    if (res1 && res2) {
      updateLastChange();
      popup('Настройки успешно сохранены');
    }
  }, [submitCafeteriaEnabled, submitFoodPlaceGuests, popup, updateLastChange]);

  useEffect(() => {
    requestData();
  }, []);

  return (
    <RowLayout paddingfree>

      <CSSSticky
        number={3}
        className={styles.stickable}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
      >
        <HeaderContent
          className={cn(styles.topBorderOnSticky, styles.headerContent)}
          borderBottom
        >
          <div>
            <Header>{header}</Header>
            <HistoryLink sectionTitle={header} />
          </div>
          {modified
              && <SaveCancelButtons
                onSave={submitData}
                onCancel={requestData}
                disabled={loading || loadingOrgs || saving}
                saving={saving}
                cancelling={modified && loading}
              />}
        </HeaderContent>
      </CSSSticky>

      <RowLayoutContent>
        <PlainCheckbox
          value={settings.CAFETERIA_ENABLED}
          label="Использовать предприятие как место питания"
          checked={cafeteriaEnabled}
          onChange={handleChange}
          disabled={!writeAccess || loading || loadingOrgs || saving}
        />

        <PlainItemsSelector
          items={orgs}
          selection={orgsSelection}
          loading={loading}
          onChange={handleChangeOrgsSelection}
          className={styles.selector}
          disabled={!writeAccess || !cafeteriaEnabled || loading || loadingOrgs || saving}
          label="Прочие заведения, сотрудники которых могут питаться здесь"
          header="Выберите заведения, сотрудники которых могут питаться здесь"
          selectedAllOnEmptySelection={false}
          pleaseSelectText="Заведения не выбраны"
          multiple
        />

        <Prompt
          when={modified}
          message="Вы не сохранили изменения, перейти в любом случае?"
        />
      </RowLayoutContent>
    </RowLayout>

  );
};

export default Cafeteria;
