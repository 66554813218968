// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

import Checkbox from 'app/common/ui/Checkbox';
import withPack from './hocs/withPack';

import styles from './FilterItems.scss';


const txtNeedEmail = 'Для отправки детализированного отчета необходимо указать e-mail в вашем профиле. ';

type Props = {
  pack: boolean,
  handleChange: (e: SyntheticEvent<HTMLElement>) => void,
  isEmail: boolean,
}

const DetailsFilter = ({ pack, handleChange, isEmail }: Props) => (<div className={styles.filter}>
  <div className={styles.ingroup}>
    <Checkbox
      value="packFilter"
      label="Запаковать отчет в архив вместе с детализацией по каждой школе"
      checked={pack}
      onChange={handleChange}
      disabled={!isEmail}
    />
  </div>
  {!isEmail
      && <div className={styles.comment}>
        {txtNeedEmail}
        <Link to="/profile" className={styles.link}>{'Перейти в профиль'}</Link>
      </div>}
</div>);

export default withPack(DetailsFilter);
