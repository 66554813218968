import { reduce } from 'ramda'

import { SupportContactsType } from 'app/dataTypes/requisites'

import { SupportErrors } from './types'


const errorText = 'Заполните выделенные поля или удалите вид'
const createErrorPath = (ID: string, fieldName: string) => `supportContacts.${ID}${fieldName}`

export const checkSupportContactsErrors = (supportContacts: SupportContactsType) => {
  return reduce((acc, { ID, position, kind, samePositionValues }) => {
    if (!position) {
      acc[createErrorPath(ID, 'position')] = errorText
    }
    if (!kind) {
      acc[createErrorPath(ID, 'kind')] = errorText
    }
    if (reduce((acc_, { value: v }) => (acc_ && !v), true, samePositionValues)) {
      acc[createErrorPath(ID, 'samePositionValues.0')] = errorText
    }
    return acc
  }, {} as SupportErrors, supportContacts)
}

export const getSupportContactsPositionError = (ID: string, errors: SupportErrors) => (
  errors[createErrorPath(ID, 'position')]
)

export const getSupportContactsKindError = (ID: string, errors: SupportErrors) => (
  errors[createErrorPath(ID, 'kind')]
)

export const getSupportContactsValueError = (ID: string, errors: SupportErrors) => (
  errors[createErrorPath(ID, 'samePositionValues.0')]
)

export const getSupportContactsError = (ID: string, errors: SupportErrors) => (
  getSupportContactsPositionError(ID, errors)
  || getSupportContactsKindError(ID, errors)
  || getSupportContactsValueError(ID, errors)
)
