// @flow
import type { Request } from '../index';

export type Params = {
  school_id?: Array<number>,
  city_id?: Array<number>,
}

export type School = {
  id: number,
  city_id: number,
  title: string,
  deleted: boolean,
  periods: {
    Семестр?: number,
    Триместр?: number,
    Четверть?: number,
  },
  created_at: string,
  updated_at: string,
}

export type Meta = {
  available: number,
  limit: number,
  offset: number,
  selected: number,
}

export type Result = {
  data: Array<School>,
  meta: Meta,
  result?: 'success',
}

export const GET_SCHOOLS: Request<Params, Result, void> = [
  'data/schools',
  'get',
  ['data'],
];
