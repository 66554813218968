// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';
import moment from 'moment-timezone';

import type { ReportTotalOnDate } from './useReportData';


export type DisplayableDate = {
  date: string,
  title: string,
}

export default function useDates(data: Array<ReportTotalOnDate>): Array<DisplayableDate> {
  const res = useMemo(() => {
    return map(({ date }) => ({
      date,
      title: moment(date).format('DD.MM'),
    }), data);
  }, [data]);

  return res;
}
