import { useState, useCallback, MouseEvent } from 'react'
import not from 'ramda/es/not'

import ImagePrepare from 'app/common/components/ImagePrepare'
import ImageClickable from 'app/common/ui/ImageClickable'
import type { ImageParamsType } from 'app/common/components/ImagePrepare/ImagePrepare'
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainConfirmModal from 'app/common/ui-next/plain-confirm-modal'

import styles from './CellContentPhoto.scss'


const emptyFunc = () => false

type Props = {
  photoUrl?: string
  className?: string
  deleteConfirmText: string
  onSaveImage?: (iParams: ImageParamsType, fileName: string) => Promise<boolean> | boolean
  onDeleteImage?: () => Promise<boolean> | boolean
  disabled?: boolean
}

const CellContentPhoto = ({
  photoUrl,
  className,
  deleteConfirmText,
  onSaveImage = emptyFunc,
  onDeleteImage = emptyFunc,
  disabled,
}: Props) => {
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const toggleDeleteConfirmation = useCallback((e?: MouseEvent<HTMLElement>) => {
    if (e) e.preventDefault()
    setDeleteConfirmationVisible(not)
  }, [])

  const handleDeleteImage = useCallback(async () => {
    setDeleting(true)
    const res = await onDeleteImage()
    setDeleting(false)

    if (res) {
      setDeleteConfirmationVisible(false)
    }
  }, [onDeleteImage, setDeleteConfirmationVisible])


  return (
    <div className={className}>
      <ImagePrepare
        onSubmit={onSaveImage}
        rectMinSizeWidth={100}
        rectMinSizeHeight={133}
        rectProportion={0.75}
      >
        {handleFileOpen => (
          <>
            {!photoUrl
              && !disabled
              && <PlainButtonAsLink
                onClick={handleFileOpen}
                className={styles.buttonAdd}
                linkClassName={styles.buttonAdd__link}
              >
                {'+\u00A0Добавить фото'}
              </PlainButtonAsLink>}
            {photoUrl
              && <ImageClickable
                locked={disabled}
                src={photoUrl}
                className={styles.photo}
                onReplace={handleFileOpen}
                onDelete={toggleDeleteConfirmation}
              />}
          </>
        )}
      </ImagePrepare>

      {deleteConfirmationVisible
        && <PlainConfirmModal
          messageText={deleteConfirmText}
          cancelButtonTitle="Отмена"
          confirmButtonTitle={deleting ? 'Удаление…' : 'Удалить'}
          confirmButtonProps={{ disabled: deleting }}
          onCancel={toggleDeleteConfirmation}
          onConfirm={handleDeleteImage}
          critical
        />}

    </div>
  )
}

export default CellContentPhoto
