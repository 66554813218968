import React, { useCallback, useEffect, useState } from 'react'
import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { subscibeTo } from 'app/common/data-box-store'
import { OrgsOrUserIDs, classesListFilterSelection, defaultStudentListDataReceiver, orgOrUserFilterSelection, studentListFilter, studentListFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

export const StudentListFilter = ({ id, pending }: Props) => {
  const [isReady, setReady] = useState<boolean>(false)
  const [filterVisibility, setFilterVisibility] = useState<boolean>(true)

  const classListHandler = useCallback((data: string[]) => {
    if (data.length === 1) {
      studentListFilter.addDataReceiver(
        defaultStudentListDataReceiver,
      )
      studentListFilter.dataReceive()
      studentListFilter.setStatusReady()
      setReady(true)
      return
    }
    studentListFilter.resetToDefault()
    setReady(false)
  }, [])

  const orgOrUserFilterHandler = useCallback((data: OrgsOrUserIDs) => {
    if (data === OrgsOrUserIDs.ByOrgs) {
      setFilterVisibility(true)
      return
    }
    studentListFilter.resetToDefault()
    setFilterVisibility(false)
    setReady(false)
  }, [])

  useEffect(() => {
    const classesListUnsubsribe = subscibeTo<string[]>(classesListFilterSelection.id, classListHandler)
    const orgOrUserUnsubsribe = subscibeTo<OrgsOrUserIDs>(orgOrUserFilterSelection.id, orgOrUserFilterHandler)
    return (() => {
      classesListUnsubsribe()
      orgOrUserUnsubsribe()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    filterVisibility && isReady
    && <PlainItemsSelectorBox
      id={id}
      label="Учащиеся"
      header="Выбор учащихся"
      dataBoxData={studentListFilter}
      dataBoxSelection={studentListFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      disabled={pending}
      multiple
    />
  )
}
