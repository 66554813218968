import assoc from 'ramda/es/assoc';

import { BALANCE_FILTER_SET, BALANCE_FILTER_CLEAR } from 'app/actions/reports/filters/balance';


const defaultState = {
  min: '',
  max: '',
};

const balance = (state = defaultState, { type, payload }) => {
  switch (type) {
    case BALANCE_FILTER_SET: {
      const { name, value } = payload;
      return assoc(name, value, state);
    }
    case BALANCE_FILTER_CLEAR: {
      return defaultState;
    }
    default:
  }
  return state;
};

export default balance;
