// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames/bind';

import {
  Table,
  Tr,
  Th,
  Td,
  useCopyColumnsWidths,
  useHover,
  useCopyContentWidths,
  useHoverByClass,
} from 'app/common/ui-components/table';

import CSSSticky from 'app/common/ui-components/css-sticky';
import useSortState from 'app/common/hooks/useSortState';
import HScrollbarsWShadow, { useSyncScrolls } from 'app/common/ui-components/h-scrollbars-w-shadow';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import CellContentOverflow from '../../../common/CellContent/CellContentOverflow';
import useActualGroups from './useActualGroups';
import type { Group, Daily, Monthly, DailyTotals, MonthlyTotals } from '../types';

import styles from './Groups.scss';

/**
 *  Отчет с детализацией и группировкой "по типам пользователя и классам"
 */

const sameColumnBase = 'column_';
const cx = classNames.bind(styles);

type Props = {
  groups: Array<Group<Daily>> | Array<Group<Monthly>>,
  totals: Array<DailyTotals> | Array<MonthlyTotals>,
}

const Groups = ({ groups, totals }: Props) => {
  const [{ sortDirection, sortKey }, handleSort] = useSortState('down', 'title');
  const [headers, groupsActual, totalsActual] = useActualGroups(groups, totals, sortKey, sortDirection);

  const [refFrom, refTo] = useCopyColumnsWidths(/* updateKey */);
  const [topScrollerProps, middleScrollerProps, bottomScrollerProps, updateBottomScroll] = useSyncScrolls();
  const [elementSource, elementsTarget] = useCopyContentWidths(1, updateBottomScroll);

  const [refTableBody, onMouseEnter, onMouseLeave] = useHover({ numberOfChunks: 3 });
  const [onCellMouseEnter, onCellMouseLeave] = useHoverByClass(sameColumnBase);


  return (
    <Fragment>
      <DetailsHeader reportTitle="Продажи" />

      <CSSSticky
        number={3}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        {/* ПЕРВЫЙ СЛОЙ -- ЗАГОЛОВОК */}
        <Table className={styles.table__fixed1}>
          <thead>
            <Tr header>
              <Th
                scope="col"
                id="header_title"
                sortKey="title"
                sortDirection={sortKey === 'title' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignLeft
              >
                {'Класс'}
              </Th>
            </Tr>
          </thead>
        </Table>

        <div className={styles.hScrollableContent}>
          <HScrollbarsWShadow
            hideVerticalScrollBar
            hideHorizontalScrollBar
            absolutelyHideHorizontal_FixFF
            {...topScrollerProps}
          >
            <Table reference={elementsTarget}>
              <thead>
                <Tr header reference={refTo}>
                  {headers.map((headerTitle, i) => (
                    <Th
                      scope="col"
                      id={`header_date_${i}`}
                      key={headerTitle}
                      className={`${sameColumnBase}${i}`}
                      onMouseEnter={onCellMouseEnter}
                      onMouseLeave={onCellMouseLeave}
                      alignRight
                    >
                      {headerTitle}
                    </Th>
                  ))}
                </Tr>
              </thead>
            </Table>
          </HScrollbarsWShadow>
        </div>

        <Table className={styles.table__fixed2}>
          <thead>
            <Tr header>
              <Th
                scope="col"
                id="header_total"
                sortKey="total"
                sortDirection={sortKey === 'total' ? sortDirection : undefined}
                onSortArrowClick={handleSort}
                alignRight
              >
                {'Всего'}
              </Th>
            </Tr>
          </thead>
        </Table>
      </CSSSticky>


      {/* ВТОРОЙ СЛОЙ -- КОНТЕНТ */}
      <div className={styles.content}>
        <Table className={styles.table__fixed1}>
          <thead>
            <Tr header hidden>
              <Th
                scope="col"
              // id="header_title"
              >
                {'Школа'}
              </Th>
            </Tr>
          </thead>

          <tbody ref={refTableBody}>
            {groupsActual.map(({ id, title }, i) => (
              <Tr
                key={id}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={`index_${i}`}
              >
                <Th scope="row" alignLeft headers="header_title">
                  <CellContentOverflow className={styles.cellTitle}>
                    {title}
                  </CellContentOverflow>
                </Th>
              </Tr>
            ))}
          </tbody>
          <tfoot>
            <Tr hidden>
              <Th
                scope="row"
                alignRight
              >
                {'Итого:'}
              </Th>
            </Tr>
          </tfoot>
        </Table>

        <div className={styles.hScrollableContent}>
          <HScrollbarsWShadow
            hideVerticalScrollBar
            hideHorizontalScrollBar
            absolutelyHideHorizontal_FixFF
            {...middleScrollerProps}
          >
            <Table
              reference={elementSource}
              className={styles.tableMain} // иначе таблица скролится по вертикали
            >
              <thead>
                <Tr header hidden reference={refFrom}>
                  {headers.map(headerTitle => (
                    <Th
                      scope="col"
                      key={headerTitle}
                      // id={`header_date_${i}`}
                      className={styles.oneLine}
                      alignRight
                    >
                      {headerTitle}
                    </Th>
                  ))}
                </Tr>
              </thead>
              <tbody ref={refTableBody}>
                {groupsActual.map(({ id, data }, i) => (
                  <Tr
                    key={id}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    className={`index_${i}`}
                  >
                    {data.map((value, j) => (
                      <Td
                        key={j}
                        headers={`header_date_${j}`}
                        className={cx(`${sameColumnBase}${j}`, { tinted: value === '0.00' })}
                        onMouseEnter={onCellMouseEnter}
                        onMouseLeave={onCellMouseLeave}
                        alignRight
                      >
                        {value}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </tbody>
              <tfoot>
                <Tr hidden>
                  {totalsActual.map((value, i) => (
                    <Td
                      key={i}
                    // headers={`header_date_${j}`}
                    >
                      {value}
                    </Td>
                  ))}
                </Tr>
              </tfoot>
            </Table>
          </HScrollbarsWShadow>
        </div>

        <Table className={styles.table__fixed2}>
          <thead>
            <Tr header hidden>
              <Th
                scope="col"
              // id="header_total"
              >
                {'Всего'}
              </Th>
            </Tr>
          </thead>
          <tbody ref={refTableBody}>
            {groupsActual.map(({ id, totalString }, i) => (
              <Tr
                key={id}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={`index_${i}`}
              >
                <Td
                  headers="header_total"
                  alignRight
                >
                  <CellContentOverflow className={styles.cellTotal}>
                    {totalString}
                  </CellContentOverflow>
                </Td>
              </Tr>
            ))}
          </tbody>
          <tfoot>
            <Tr hidden>
              <Td>
                &nbsp;
              </Td>
            </Tr>
          </tfoot>
        </Table>
      </div>


      {/* ТРЕТИЙ СЛОЙ -- ПОДВАЛ */}
      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        <div className={styles.content}>
          <Table className={styles.table__fixed1}>
            <tfoot>
              <Tr>
                <Th
                  headers="header_title"
                  scope="row"
                  alignRight
                >
                  {'Итого:'}
                </Th>
              </Tr>
            </tfoot>
          </Table>

          <div className={styles.hScrollableContent}>
            <HScrollbarsWShadow
              hideVerticalScrollBar
              {...bottomScrollerProps}
            >
              <Table reference={elementsTarget}>
                <tfoot>
                  <Tr reference={refTo} className={styles.footerRow}>
                    {totalsActual.map((value, i) => (
                      <Td
                        key={i}
                        headers={`header_date_${i}`}
                        className={`${sameColumnBase}${i}`}
                        onMouseEnter={onCellMouseEnter}
                        onMouseLeave={onCellMouseLeave}
                        alignRight
                      >
                        {value}
                      </Td>
                    ))}
                  </Tr>
                </tfoot>
              </Table>
            </HScrollbarsWShadow>
          </div>

          <Table className={styles.table__fixed2}>
            <tfoot>
              <Tr>
                <Td>
                  &nbsp;
                </Td>
              </Tr>
            </tfoot>
          </Table>
        </div>
      </CSSSticky>
    </Fragment>
  );
};

export default Groups;
