// @flow
import { map } from 'ramda';

import type { ParamsPUT, PlanogramContent, PlanogramSlotContent } from 'app/dataTypes/vending/PlanogramContent.types';

import type { Request } from '../../../../../index';


type ResultApi = Array<{
  slot_id: number,
  item_id: number | null,
  amount: number | null,
}>;

type ParamsApi = {
  data: Array<{
    slot_id: number,
    item_id: number | null,
    amount: number | null,
  }>
};

type UrlParams = {
  machineId: string,
}

export const convertParams = ({ data, machineId }: ParamsPUT): [ParamsApi, UrlParams] => {
  const items = data instanceof Map ? Array.from(data.values()) : data;
  return [{
    data: items.map(({ slotId, productId, amount }) => {
      const itemId = parseInt(productId, 10);
      return {
        slot_id: parseInt(slotId, 10),
        item_id: Number.isNaN(itemId) ? null : itemId,
        amount,
      };
    }),
  }, { machineId }];
  // return [{}, { machineId }];
};

export const convertResult = (data: ResultApi): PlanogramContent => {
  return new Map(map(({
    slot_id: id,
    item_id: productId,
    amount,
  }): [string, PlanogramSlotContent] => {
    const slotId = String(id);

    return [slotId, {
      slotId,
      productId: productId ? String(productId) : null,
      amount: typeof amount === 'number' ? amount : null,
    }];
  }, data));
};

export const PUT_PLANORGAM_SLOTS_CONTENT: Request<
  ParamsPUT,
  PlanogramContent,
  UrlParams
> = [
  'data/vending/machines/{machineId}/slots/content',
  'put',
  ['data', 'data'],
];
