import { MouseEvent, ReactNode } from 'react'

/**
 * Для остановки всплытия стобытий в ячейках TrExpandable, когда в них размещены кнопки
 * или другие интерактивные елементы. Иначе срабатывает ненужный фокус на строку таблицы
 */


const handleStop = (e: MouseEvent<HTMLSpanElement>) => {
  e.stopPropagation()
}

type Props = {
  className?: string
  children: ReactNode
}

export const StopPropagation = ({ className, children }: Props) => {
  return (
    <span // eslint-disable-line
      className={className}
      onClick={handleStop}
    >
      {children}
    </span>
  )
}
