import {
  EMPLOYEE_SESSIONS_SUCCESS,
  EMPLOYEE_SESSIONS_CLEAR,
} from 'app/actions/employees/employee/settings/sessions';


const defaultState = {
  sessions: {},
  sessionsCount: 0,
};

const history = (state = defaultState, { type, payload }) => {
  switch (type) {
    case EMPLOYEE_SESSIONS_SUCCESS: {
      return { ...defaultState, ...payload };
    }
    case EMPLOYEE_SESSIONS_CLEAR: {
      return defaultState;
    }
    default:
  }
  return state;
};

export default history;
