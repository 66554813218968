// @flow
import React, { type Node, Fragment, useCallback } from 'react';

import { PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainInput from 'app/common/ui/PlainInput';
import PlainTextArea from 'app/common/ui/PlainTextArea';
import { reportAddresses, type SingleRequisites } from 'app/dataTypes/requisites';
import PlainRadioButton from 'app/common/ui-next/plain-radio-button';

import CounterpartiesSelect from './CounterpartiesSelect';
import styles from './LegalForm.scss';


type Props = {
  value: SingleRequisites,
  valueBasePath: string,
  onChange: (string, string) => void,
  loading?: boolean,
  locked?: boolean,
}

const LegalForm = ({
  value,
  valueBasePath,
  onChange,
  loading,
  locked,
}: Props): Node => {
  const showExtraFields = value.orgType === 'Бюджетная организация';

  const handleChangeField = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: val, name } = e.currentTarget;
    onChange(`${valueBasePath}.${name}`, val);
  }, [valueBasePath, onChange]);

  const handleChangeRadio = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: val, checked } = e.currentTarget;
    if (checked) {
      onChange(`${valueBasePath}.reportAddress`, val);
    }
  }, [valueBasePath, onChange]);

  return (
    <Fragment>
      {/* ROW 4 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={4}>
          <CounterpartiesSelect
            label="Тип контрагента"
            name="orgType"
            current={value.orgType}
            placeholder="Не указано"
            onChange={handleChangeField}
            disabled={loading || locked}
            uni
          />
        </PlainColumn>
      </PlainColumns>

      {/* ROW 5 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={4}>
          <PlainInput
            label="ИНН"
            name="inn"
            value={value.inn}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="КПП"
            name="kpp"
            value={value.kpp}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="ОГРН"
            name="orgn"
            value={value.orgn}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>

        <PlainColumn div={4}>
          <PlainInput
            label="ОКПО"
            name="okpo"
            value={value.okpo}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
      </PlainColumns>

      {/* 1st ROW для "Бюджетная организация" */}
      {showExtraFields
        && <PlainColumns className={styles.row}>
          <PlainColumn div={4}>
            <PlainInput
              label="ОКТМО"
              name="oktmo"
              value={value.oktmo}
              onChange={handleChangeField}
              loading={loading}
              disabled={locked}
              placeholder="Не указано"
            />
          </PlainColumn>
          <PlainColumn div={4}>
            <PlainInput
              label="КБК"
              name="kbk"
              value={value.kbk}
              onChange={handleChangeField}
              loading={loading}
              disabled={locked}
              placeholder="Не указано"
            />
          </PlainColumn>
        </PlainColumns>}

      {/* ROW 6 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={1}>
          <PlainInput
            label="Полное наименование"
            name="title"
            value={value.title}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
      </PlainColumns>

      {/* ROW 7 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={2}>
          <PlainTextArea
            label="Юридический адрес"
            name="lawAddress"
            value={value.lawAddress}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
        <PlainColumn div={2}>
          <PlainRadioButton
            className={styles.radioUseForReports}
            label="использовать в отчетности"
            name={`${valueBasePath}.reportAddress`}
            value={reportAddresses.law_address}
            onChange={handleChangeRadio}
            checked={value.reportAddress === reportAddresses.law_address}
            disabled={loading || locked}
          />
        </PlainColumn>
      </PlainColumns>

      {/* inserted */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={2}>
          <PlainTextArea
            label="Фактический адрес"
            name="address"
            value={value.address}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
        <PlainColumn div={2}>
          <PlainRadioButton
            className={styles.radioUseForReports}
            label="использовать в отчетности"
            name={`${valueBasePath}.reportAddress`}
            value={reportAddresses.address}
            onChange={handleChangeRadio}
            checked={value.reportAddress === reportAddresses.address}
            disabled={loading || locked}
          />
        </PlainColumn>
      </PlainColumns>

      {/* ROW 8 */}
      <PlainColumns className={styles.row}>
        <PlainColumn div={2}>
          <PlainTextArea
            label="Почтовый адрес"
            name="postAddress"
            value={value.postAddress}
            onChange={handleChangeField}
            loading={loading}
            disabled={locked}
            placeholder="Не указано"
          />
        </PlainColumn>
        <PlainColumn div={2}>
          <PlainRadioButton
            className={styles.radioUseForReports}
            label="использовать в отчетности"
            name={`${valueBasePath}.reportAddress`}
            value={reportAddresses.post_address}
            onChange={handleChangeRadio}
            checked={value.reportAddress === reportAddresses.post_address}
            disabled={loading || locked}
          />
        </PlainColumn>

      </PlainColumns>
    </Fragment>
  );
};

export default LegalForm;
