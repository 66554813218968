// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import assoc from 'ramda/es/assoc';
import evolve from 'ramda/es/evolve';

import { PlainGroup, PlainColumns, PlainColumn } from 'app/common/ui/PlainGrid';
import PlainInput from 'app/common/ui/PlainInput';
import Checkbox, { CheckboxGroup } from 'app/common/ui/Checkbox';
import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import { pricePositiveRe } from 'app/common/constants/regex';
import usePopup from 'app/common/hooks/usePopup';
import OrgsDataProvider from 'app/dataProviders/org/OrgsDataProvider';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';

import selectKit from './selectKit';
import useNewEditKitFormSubmit from './useNewEditKitFormSubmit';
import MultipleCreateKit from './MultipleCreateKit';
import styles from './NewEditKitForm.scss';

/**
 *  Форма создание/редактирование комплекта
 */

const getNewBtTitle = saving => (saving ? 'Создание...' : 'Создать');
const getEditBtTitle = saving => (saving ? 'Сохранение...' : 'Сохранить');
const defaultErrorState = ['', ''];

type Props = {
  kitId?: number,
  orgId: number,
  toggleModal: () => void,
}

const NewEditKitForm = ({
  kitId,
  orgId,
  toggleModal,
}: Props) => {
  const popup = usePopup();
  const { fixedPriceEnabled, fixedPrice, title, enableSubsidyOrders } = useSelector(state => selectKit(state, { kitId }));

  const [fields, setFields] = useState({ title, fixedPrice, fixedPriceEnabled });
  const [errors, setErrors] = useState(defaultErrorState);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (kitId) {
      setFields(evolve({ title, fixedPrice, fixedPriceEnabled }));
    }
  }, [fixedPrice, title, fixedPriceEnabled, kitId]);

  const handleIsFixed = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setFields(assoc('fixedPriceEnabled', checked));
  }, []);

  const handleFieldChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (name !== 'title' && name !== 'fixedPrice') return;
    let newValue = value;
    if (name === 'fixedPrice' && newValue.includes(',')) {
      newValue = newValue.replace(',', '.');
    }
    if (name === 'fixedPrice' && !pricePositiveRe.test(newValue) || newValue[0] === '0') {
      return;
    }
    setFields(assoc(name, newValue));
  }, []);

  const [submit, checkErrors] = useNewEditKitFormSubmit({
    kitId,
    orgId,
    fields,
    setErrors,
  });

  // редактирование комплекта, нет мультизапросов
  const handleSubmit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (checkErrors()) {
      setSubmitting(true);
      const res = await submit();
      setSubmitting(false);

      if (res) {
        popup('Комплект успешно создан');
        toggleModal();
      }
    }
  }, [submit, checkErrors, popup, toggleModal]);

  // создание/мультисоздание комплекта
  const handleSubmitOneOfMultiple = useCallback(async (orgId_: number) => {
    if (checkErrors()) {
      const res = await submit(orgId_);
      return res;
    }
    return false;
  }, [submit, checkErrors]);

  // успешное создание/мультисоздание комплекта
  const handleCreateKitSuccess = useCallback(() => {
    toggleModal();
  }, [toggleModal]);


  const form = submit_ => (
    <form onSubmit={submit_}>
      <PlainGroup
        className={styles.content}
        header="Укажите название и цену комплекта"
      >
        <PlainColumns>
          <PlainColumn>
            <PlainInput
              name="title"
              label="Наименование"
              onChange={handleFieldChange}
              value={fields.title}
              disabled={enableSubsidyOrders}
              isError={!!errors[0]}
              errorText={errors[0]}
            />
            {enableSubsidyOrders
              && <PlainHelperText overflowVisible error>
                {'Запрещено редактирование наименования комплекта, который используется в справках на льготное питание'}
              </PlainHelperText>}
          </PlainColumn>

          <CheckboxGroup className={styles.checkbox}>
            {/* $FlowFixMe */}
            <Checkbox
              label="Фиксированная цена"
              checked={fields.fixedPriceEnabled}
              onChange={handleIsFixed}
            />
          </CheckboxGroup>
          <PlainColumn>
            <PlainInput
              name="fixedPrice"
              label="Цена"
              onChange={handleFieldChange}
              value={fields.fixedPrice}
              disabled={!fields.fixedPriceEnabled}
              isError={!!errors[1]}
              errorText={errors[1]}
            />
          </PlainColumn>
        </PlainColumns>
      </PlainGroup>

      <ControlsBar right className={styles.buttons}>
        <PlainButton outline onClick={toggleModal}>
          {'Отмена'}
        </PlainButton>
        <PlainButton
          type="submit"
          disabled={submitting || (!kitId && !fields.title)}
        >
          {!kitId
            ? getNewBtTitle(submitting)
            : getEditBtTitle(submitting)}
        </PlainButton>
      </ControlsBar>
    </form>
  );

  if (kitId) {
    return form(handleSubmit);
  }

  return (
    <OrgsDataProvider orgId={orgId} sameType excludeCurrent>
      <MultipleCreateKit
        onSubmit={handleSubmitOneOfMultiple}
        onChangeSubmitState={setSubmitting}
        onSuccess={handleCreateKitSuccess}
      >
        {form}
      </MultipleCreateKit>
    </OrgsDataProvider>
  );
};

export default NewEditKitForm;
