// @flow
import React, { type Node } from 'react';

import styles from './app-bar.scss';


type Props = {
  children: Node,
}

const AppBar = ({ children }: Props) => (
  <div className={styles.root}>
    <nav className={styles.content}>
      {children}
    </nav>
  </div>
);

export default AppBar;
