// @flow
import { api } from 'app/api';
import { GET_PLANORGAM_SLOTS, convertParams, convertResult } from 'app/common/api/requests/data/vending/machines/slots/get';
import type { PlanogramRawData } from 'app/dataTypes/vending/Planogram.types';


const requestPlanogramSlots = async (machineId: string): Promise<PlanogramRawData | null> => {
  const res = await api.request(GET_PLANORGAM_SLOTS, {
    error: 'Не удалось получить данные планограммы',
    params: {
      machineId,
    },
    convertions: {
      convertParams,
      convertResult,
    },
  });

  return res;
};

export default requestPlanogramSlots;
