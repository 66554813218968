// @flow
import React, { useMemo, type Ref, SyntheticEvent } from 'react';
import NumberFormat from 'react-number-format';

import { emptyObject } from 'app/common/lib/empty';

import PlainInput, { type PlainInputProps } from '../plain-input';
import { getPredefinedParams } from './mask-predefined';
import { maskTypes } from './mask-types';


export type NumberFormatValues = {
  floatValue: number | void,
  formattedValue: string,
  value: string,
}

export type SourceInfo = {
  event: SyntheticEvent<HTMLInputElement>,
  source: 'prop' | 'event',
}

export type PlainInputMaskedProps = {
  allowEmptyFormatting?: boolean,
  isNumericString?: boolean, // установить в true, если входящее value number
  format?: string | (inputValue: string) => string,
  displayType?: 'text' | 'input',
  mask?: string | Array<string>, // single char | array of single chars
  predefinedMaskType?: $Values<typeof maskTypes>,
  prefix?: string,
  onValueChange?: (NumberFormatValues, SourceInfo) => void,
  isAllowed?: (NumberFormatValues) => boolean, // при возврате false, onChange и onValueChange не будут срабатывать
  removeFormatting?: (string) => string,
  renderText?: (formattedValue: string) => Node,
  getInputRef?: ((el: HTMLInputElement | null) => void) | Ref<'input'>,
  // ...$Exact<>
} & PlainInputProps

const PlainInputMasked = ({
  value,
  allowEmptyFormatting,
  isNumericString,
  format,
  displayType,
  mask,
  predefinedMaskType,
  prefix,
  onValueChange,
  isAllowed,
  removeFormatting,
  renderText,
  ...others // PlainInput props
}: PlainInputMaskedProps) => {
  const predefinedParams = useMemo(() => {
    return predefinedMaskType ? getPredefinedParams(predefinedMaskType) : emptyObject;
  }, [predefinedMaskType]);

  return (
    <NumberFormat
      value={value}
      onValueChange={onValueChange}
      customInput={PlainInput}
      format={format}
      prefix={prefix}
      isAllowed={isAllowed}
      removeFormatting={removeFormatting}
      isNumericString={isNumericString}
      displayType={displayType}
      mask={mask}
      allowEmptyFormatting={allowEmptyFormatting}
      renderText={renderText}
      thousandSeparator=" "
      {...predefinedParams}
      {...others}
    />
  );
};

export default PlainInputMasked;
