
import PlainModal, { type PlainModalProps } from './plain-modal'
import PlainModalHeader from './plain-modal-header'
import PlainModalContent from './plain-modal-content'
import PlainModalFooter from './plain-modal-footer'
import PlainModalContainer from './plain-modal-container'
import type { HandleModalDisplayed } from './use-modal-displayed'
import useModalState from './use-modal-state'
import ContentScrollbars from './content-scrollbars'


export default PlainModal

export {
  PlainModalHeader,
  PlainModalContent,
  PlainModalFooter,
  PlainModalContainer,
  useModalState,
  ContentScrollbars,
}

export type { HandleModalDisplayed, PlainModalProps }
