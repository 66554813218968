// @flow
import { useMemo } from 'react';
import { map, over, lensProp, replace, pipe, filter } from 'ramda';

import { useCurrencySign } from 'app/common/components-next/currency';
import { columns, type ColumnVisibility } from './columns';


export type HeaderCells = Array<{
  key: string,
  value: string,
  alignLeft?: boolean,
  alignRight?: boolean,
  alignCenter?: boolean,
}>

export const cells: HeaderCells = [{
  key: columns[0],
  value: '',
  alignLeft: true,
}, {
  key: columns[1],
  value: 'Наименование',
  alignLeft: true,
}, {
  key: columns[2],
  value: 'Фото',
  alignCenter: true,
}, {
  key: columns[3],
  value: 'Порция',
  alignCenter: true,
}, {
  key: columns[4],
  value: 'Стоимость,\u00A0%currency%',
  alignCenter: true,
}];

const getCellsBySettings = (columnSettings: ColumnVisibility): HeaderCells => (
  filter(({ key }) => (columnSettings[key] || columnSettings[key] === undefined), cells)
);

export default function useHeaderCellsData(columnSettings: ColumnVisibility): HeaderCells {
  const currencySign = useCurrencySign();

  const cellsWithCurrencySign = useMemo(() => {
    return pipe(
      map(over(lensProp('value'), replace('%currency%', currencySign))),
    )(getCellsBySettings(columnSettings));
  }, [currencySign, columnSettings]);

  return cellsWithCurrencySign;
}
