import { connect } from 'react-redux';
import length from 'ramda/es/length';
import assoc from 'ramda/es/assoc';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';

import withSortState from 'app/common/containers/withSortState';
import withPaging from 'app/common/containers/withPaging';

import selectUsers from './selectUsers';
import selectActual from './selectActual';


const defaultSortKey = 'name';
const defaultSortDirection = 'down';

export default compose(
  withSortState(defaultSortDirection, defaultSortKey),

  connect(
    (state, props) => {
      const users = selectUsers(state, props);
      return ({
        users,
        rows: state.settings.reports.rows,
        total: length(users),
      });
    },
  ),

  withPaging(),
  // setCurrentPage,
  // handlePage,
  // currentPage

  mapProps(props => assoc('users', selectActual(props), props)),

  lifecycle({
    componentDidUpdate(prevProps) {
      const { rows, setCurrentPage } = this.props;
      if (rows !== prevProps.rows) {
        setCurrentPage(1);
      }
    },
  }),

);
