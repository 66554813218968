// @flow
import { useState, useCallback, useMemo } from 'react';
import { omit, filter } from 'ramda';

import { api } from 'app/api';

import {
  GET_ORGS,
  type OrgsSchools,
  type OrgItem,
  defaultOrgItem,
} from 'app/common/api/requests/food/schools';

import useOrg from './useOrg';

/**
 *  Hook заведений. Текущее заведение хранится отдельно.
 *  По умолчанию запрашиваются с типом, соответствующим текущему заведению. (нет)
 */

export type { OrgsSchools, OrgItem };

export type State = {
  orgs: OrgsSchools,
  currentOrg: OrgItem,
  loading: boolean,
}

export const defaultState = {
  orgs: {},
  currentOrg: defaultOrgItem,
  loading: true,
};

export type ApiHandlers = {
  request: () => void | Promise<void>,
  requestCurrent: () => null | Promise<null | OrgItem>,
}

export const defaultApiHandlers = {
  request: () => {},
  requestCurrent: () => null,
};

export type Options = {
  sameType?: boolean,
  excludeCurrent?: boolean,
  filterCb?: (item: OrgItem) => boolean,
}

const defaultOptions: Options = {
  sameType: false,
  excludeCurrent: false,
};

export default function useOrgs(orgId: number, options?: Options = defaultOptions): [State, ApiHandlers] {
  const { sameType, excludeCurrent, filterCb } = { ...defaultOptions, ...options };
  const [orgs, setOrgs] = useState<OrgsSchools>({});
  // const [currentOrg, setCurrentOrg] = useState<OrgItem>(defaultOrgItem);
  const [loading, setLoading] = useState(true);


  const [currentOrg, handleRequestCurrentOrg] = useOrg(orgId);


  const handleRequestOrgs = useCallback(async () => {
    setLoading(true);
    const org = await handleRequestCurrentOrg();
    const params = org && sameType ? { type: org.type } : undefined;
    const excludeId = org && excludeCurrent ? org.id : undefined;

    const res = await api.request(GET_ORGS, {
      error: 'Не удалось получить данные заведений',
      params,
    });

    setLoading(false);

    if (res) {
      const { schools } = res;
      const schoolsFiltered = filterCb ? filter(filterCb, schools) : schools;
      setOrgs(excludeId ? omit([String(excludeId)], schoolsFiltered) : schoolsFiltered);
    }
  }, [handleRequestCurrentOrg, sameType, excludeCurrent, filterCb]);


  const memoized = useMemo(() => ([{
    orgs,
    currentOrg,
    loading,
  }, {
    request: handleRequestOrgs,
    requestCurrent: handleRequestCurrentOrg,
  }]), [
    orgs,
    currentOrg,
    loading,
    handleRequestOrgs,
    handleRequestCurrentOrg,
  ]);


  return memoized;
}
