import { combineReducers } from 'redux';

import current from 'app/common/reducers/settings/current';

import dishes from './dishes';
import employeesAtWork from './employeesAtWork';
import employeesOffice from './employeesOffice';
import fav from './fav';
import history from './history';
import identifiers from './identifiers';
import jobplaces from './jobplaces';
import orgsDishes from './orgsDishes';
import orgsDishesEditList from './orgsDishesEditList';
import orgsMenuEdit from './orgsMenuEdit';
import orgsOrders from './orgsOrders';
import reports from './reports';
import schools from './schools';
import sessions from './sessions';
import sidemenu from './sidemenu';
import limits from './limits';
import vending from './vending';
import vendingDishes from './vendingDishes';
import vendingDishesEditList from './vendingDishesEditList';
import compensationStatements from './compensationStatements';


export default combineReducers({
  current,
  dishes,
  employeesAtWork,
  employeesOffice,
  fav,
  history,
  identifiers,
  jobplaces,
  orgsDishes,
  orgsDishesEditList,
  orgsMenuEdit,
  orgsOrders,
  reports,
  schools,
  sessions,
  sidemenu,
  limits,
  vending,
  vendingDishes,
  vendingDishesEditList,
  compensationStatements,
});
