// @flow
import React from 'react';

import IconRecovery from 'app/common/icons/IconRecovery';

import styles from './CallOpt.scss';


const CellOpt = () => {
  return (
    <IconRecovery className={styles.icon} />
  );
};

export default CellOpt;
