import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';


export default compose(
  withState(
    'currentSortKey',
    'setCurrentSortKey',
    ({ currentSortKey }) => currentSortKey,
  ),
  withState(
    'currentSortDirection',
    'setCurrentSortDirection',
    ({ currentSortDirection }) => currentSortDirection,
  ),
  withHandlers({
    handleSort: ({
      currentSortKey,
      currentSortDirection,
      setCurrentSortKey,
      setCurrentSortDirection,
      onSort,
    }) => (e) => {
      const { name } = e.currentTarget;
      e.preventDefault();
      const newSortDirection = (name === currentSortKey && currentSortDirection === 'up'
        ? 'down'
        : 'up'
      );
      setCurrentSortDirection(newSortDirection);
      if (name !== currentSortKey) {
        setCurrentSortKey(name);
      }
      onSort(newSortDirection, name);
    },
  }),
);

export const withSortableStateless = withHandlers({
  handleSort: ({
    currentSortKey,
    currentSortDirection,
    onSort,
  }) => (e) => {
    const { name } = e.currentTarget;
    e.preventDefault();
    const newSortDirection = (name === currentSortKey && currentSortDirection === 'up'
      ? 'down'
      : 'up'
    );
    onSort(newSortDirection, name);
  },
});
