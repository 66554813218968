// @flow
import { useCallback } from 'react';
import { identity } from 'ramda';

import usePopup from 'app/common/hooks/usePopup';
import { POST_ORG, convertParams } from 'app/common/api/requests/food/school/post';
import { api } from 'app/api';

import type { FormFields } from './AddNewEnterprise';


type Options = {
  onSuccess?: () => void,
  setError: (errorText: string) => void,
}

export default function useSubmit({
  onSuccess,
  setError,
}: Options): ((form: HTMLFormElement) => Promise<boolean>) {
  const popup = usePopup();

  const handleSubmit = useCallback(async (form: HTMLFormElement & FormFields) => {
    const {
      titleShort: { value: titleShort },
      titleComplete: { value: titleComplete },
    } = form;

    if (!titleShort) {
      setError('Введите название предприятия');
      return false;
    }

    const res = await api.request(POST_ORG, {
      error: 'Не удалось создать предприятие',
      params: { titleShort, titleComplete },
      convertions: { convertParams, convertResult: identity },
    });

    if (res) {
      if (onSuccess) {
        onSuccess();
      }
      popup('Предприятие успешно создано');
      return true;
    }
    return false;
  }, [onSuccess, popup, setError]);

  return ((handleSubmit: any): (form: HTMLFormElement) => Promise<boolean>);
}
