// @flow
import React, { useContext, useMemo, type Node } from 'react';
import classNames from 'classnames';
import groupBy from 'ramda/es/groupBy';
import prop from 'ramda/es/prop';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import { useTranslation } from 'react-i18next';

import Loading from 'app/common/components/Loader';
import RowLayout from 'app/common/layouts/RowLayout';
import * as types from 'app/common/constants/identifiers/types';

import { IdentifierItemEmpty } from './identifier-item';
import { IdentifiersContext, type Identifiers } from './identifiers-data';
import IdentifiersGroup from './identifiers-group';


const availableIdentifiers: Array<$Values<typeof types>> = values(types);

type Props = {
  className?: string,
  additionalExternalIdentifiers?: Identifiers, // deprecated, need to remove
}

const IdentifiersList = ({ className, additionalExternalIdentifiers }: Props) => {
  const { t } = useTranslation('identifiers-next');

  const {
    content: { identifiers },
    loading: { identifiers: loading },
  } = useContext(IdentifiersContext);

  const groupped = useMemo(() => {
    const readyForGroup = (additionalExternalIdentifiers && !isEmpty(additionalExternalIdentifiers)
      ? { ...identifiers, ...additionalExternalIdentifiers }
      : identifiers);
    return groupBy(prop('type'), values(readyForGroup));
  }, [identifiers, additionalExternalIdentifiers]);

  const nothingToDisplay = isEmpty(identifiers);

  if (loading && nothingToDisplay) {
    return (
      <RowLayout className={className}>
        <Loading />
      </RowLayout>
    );
  }

  if (!loading && nothingToDisplay) {
    return (
      <RowLayout className={className}>
        <IdentifierItemEmpty
          title={t('empty')}
          type={types.IDENTIFIER_VIRTUAL_CARD}
        />
      </RowLayout>
    );
  }

  return (availableIdentifiers
    .filter(type => !!groupped[type])
    .map<Node>((type, i) => (
      <IdentifiersGroup
        className={classNames(i === 0 ? className : null)}
        key={type}
        type={type}
        identifiersByType={groupped[type]}
      />
    ))
  );
};

export default IdentifiersList;
