// @flow
import { useState, useCallback, useEffect } from 'react';
import reduce from 'ramda/es/reduce';
import map from 'ramda/es/map';
import always from 'ramda/es/always';
import assoc from 'ramda/es/assoc';
import has from 'ramda/es/has';
import when from 'ramda/es/when';
import all from 'ramda/es/all';
import equals from 'ramda/es/equals';
import values from 'ramda/es/values';


type Selection = {
  [id: string]: boolean,
}
const defaultSelection = {};
const defaultFunc = () => {};

export type State = {
  selection: Selection,
  allSelected: boolean,
}

export const defaultState: State = {
  selection: defaultSelection,
  allSelected: false,
};

export type Actions = {
  init: (ids: Array<string> | Array<number>) => void,
  update: (id: string | number, selected: boolean) => void,
  updateAll: (val: boolean) => void,
}

export const defaultActions: Actions = {
  init: defaultFunc,
  update: defaultFunc,
  updateAll: defaultFunc,
};

export default function useSelection(): [State, Actions] {
  const [selection, setSelection] = useState<Selection>(defaultSelection);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [invalidate, setInvalidate] = useState<boolean>(false);

  useEffect(() => {
    if (invalidate) {
      setInvalidate(false);
      setAllSelected(all(equals(true), values(selection)));
    }
  }, [invalidate, selection]);

  const handleInit = useCallback((idItems: Array<string> | Array<number>) => {
    setSelection(reduce((acc, val) => {
      acc[String(val)] = false;
      return acc;
    }, {}, idItems));
    setAllSelected(false);
  }, []);

  const handleUpdate = useCallback((id: string | number, selected: boolean) => {
    setSelection(when(has(String(id)), assoc(String(id), selected)));
    if (allSelected) {
      setAllSelected(selected);
      return;
    }
    // в этом случае вычисляем на следующей перерисовке,
    // чтоб не замедлить текущее нажатие чекбокса
    setInvalidate(true);
  }, [allSelected]);

  const handleUpdateAll = useCallback((val: boolean) => {
    setSelection(map(always(val)));
    setAllSelected(val);
  }, []);


  return [{
    selection,
    allSelected,
  }, {
    init: handleInit,
    update: handleUpdate,
    updateAll: handleUpdateAll,
  }];
}
