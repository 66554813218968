// @flow
import React from 'react';
import { Trans } from 'react-i18next';

import Copyright from '../Main/Copyright';
import Logo from '../Main/Logo';
import styles from './Blocked.scss';


type Props = {
  logo: string,
  company: string,
  phone: string,
  handleBack: (e: SyntheticEvent<HTMLElement>) => void,
}

const Blocked = ({ logo, company, phone, handleBack }: Props) => (
  <div className={styles.root}>
    <Logo src={logo} />
    <div className={styles.wat}>
      <Trans i18nKey="login:blocked_title">
        {'Внимание!'}
        <br />
        {'Ваш аккаунт заблокирован!'}
      </Trans>
    </div>
    <div className={styles.todo}>
      <Trans i18nKey="login:blocked_todo">
        {'Для разблокировки аккаунта обратитесь к'}
&nbsp;
        {'поставщику услуг'}
      </Trans>
      {(phone || company) ? ':' : ''}
    </div>
    {company && <div className={styles.company}>{company}</div>}
    {phone && <div className={styles.phone}>{phone}</div>}
    <div className={styles.back}>
      <a href="back" onClick={handleBack}>
        <Trans i18nKey="login:blocked_back_bt">
          {'Вернуться к странице авторизации'}
        </Trans>
      </a>
    </div>
    <Copyright company={company} />
  </div>
);

export default Blocked;
