// @flow
import pipe from 'ramda/es/pipe';
import prop from 'ramda/es/prop';
import assoc from 'ramda/es/assoc';
import map from 'ramda/es/map';
import values from 'ramda/es/values';
import filter from 'ramda/es/filter';
import propEq from 'ramda/es/propEq';

import {
  defaultStateDataCurrent,
  getStateCurrentValue,
  type State,
  type StateDataItems,
  type StateDataItem,
} from 'app/common/ui-components/filter';

import { api } from 'app/api';
import { GET_CHANGES_FILTERS, convertParams, convertResult } from 'app/common/api/requests/food/schools/changes/filters';

import filterPeriod from '../period';
import type { FilterParams } from '../FIlterParams.type';


const addType = (type: string) => map(assoc('type', type));

const filterDataItems = async (state: State, params?: FilterParams) => {
  const { orgId, section } = params || {};
  const [from, to] = getStateCurrentValue(filterPeriod, state);

  if (orgId && section && from) {
    const res = await api.request(GET_CHANGES_FILTERS, {
      error: 'Не удалось получить фильтры',
      params: {
        orgId,
        section,
        from,
        to,
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      const [sources, actors] = res;
      return ([[
        ...addType('source')(sources),
        ...addType('actor')(actors),
      ], [
        String(sources.length),
        String(actors.length),
      ]]);
    }
  }

  return [[], defaultStateDataCurrent];
};

const parse = n => parseInt(n, 10);
export const getSourcesDataLengthFromCurrent: (Array<string>) => number = pipe(prop(0), parse);
export const getActorsDataLengthFromCurrent: (Array<string>) => number = pipe(prop(1), parse);

export const getSourcesData: (StateDataItems) => Array<StateDataItem> = pipe(values, filter(propEq('type', 'source')));
export const getActorsData: (StateDataItems) => Array<StateDataItem> = pipe(values, filter(propEq('type', 'actor')));

export default filterDataItems;
