import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { setToken, setTokenExternal } from 'app/common/lib/token';
import 'url-search-params-polyfill';
import 'focus-visible';

import 'app/images/pattern.png'; // файл оформления конкретного кабинета
import 'app/images/loader-clock.svg'; // файл оформления конкретного кабинета
import getParams, { removeParam } from 'app/common/lib/params';
import 'app/i18n'; // файл локализации, свой для каждого кабинета

import Bundle from './Bundle';


const MOUNT_NODE = document.getElementById('root');


// если есть токен в адресной строке, то берем его
const params = getParams();
if (params.token) {
  setToken(params.token, true);
} else {
  setTokenExternal(false);
}

removeParam('token');

const root = createRoot(MOUNT_NODE);
root.render(<Bundle />);

// ReactDOM.render(
//   <Bundle />,
//   MOUNT_NODE,
// );
