// @flow
import React, { type Node } from 'react';

import CellWho from './tableCells/CellWho';
import CellOpt from './tableCells/CellOpt';
import CellStatus from './tableCells/CellStatus';
import type { Columns } from '../columns';
import type { Item } from '../useOrgsOrdersTableItems';


export default function useCellsContent(columnsIds: Columns, item: Item): Array<Node> {
  const { id, status, statusTitle, date, name, role, group, foodMode, students, total } = item;

  return columnsIds.map((columnId) => {
    switch (columnId) {
      case 'id':
        return <CellStatus number={id} status={status} statusTitle={statusTitle} />;

      case 'date':
        return date;

      case 'who':
        return <CellWho name={name} role={role} />;

      case 'group':
        return group;

      case 'sch':
        return foodMode;

      case 'num':
        return students;

      case 'total':
        return total;

      case 'ops':
        return <CellOpt />;

      default:
        return null;
    }
  });
}
