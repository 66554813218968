// @flow
import { useSelector, shallowEqual } from 'react-redux';


type FooterData = {
  company: string,
  phone: string,
  androidAppUrl: string,
  iosAppUrl: string,
  loading: boolean,
}

export default function useFooterData(): FooterData {
  const {
    company = '',
    phone = '',
    android_app_url: androidAppUrl = '',
    ios_app_url: iosAppUrl = '',
    loading,
  } = useSelector(state => state.dealer, shallowEqual);

  return {
    company,
    phone,
    androidAppUrl,
    iosAppUrl,
    loading,
  };
}
