// @flow
import { createSelector } from 'reselect';
import prop from 'ramda/es/prop';

import { tableColumnSort, tableColumnSortClass } from 'app/common/lib/sort';


export type IncomingProps = {
  id: number,
  customerType: string,
  klassTitle: string,
  count: number,
  pos: number,
  neg: number,
  negPercents: number,
  total: number,
}

const selectConverted = createSelector(
  (_, { data }) => data,
  arr => arr.map((item, i) => {
    const {
      customer_type: customerType,
      klass_id: klassId,
      klass_title: klassTitle,
      neg_percents: negPercents,
      ...others
    } = item;
    return ({
      id: i,
      customerType,
      klassTitle,
      klassId,
      negPercents,
      ...others,
    });
  }),
);

type Selector = (state: Object, props: Object) => Array<IncomingProps>

const selectGroups: Selector = createSelector(
  [
    (_, { sort: { sortDirection } }) => sortDirection,
    (_, { sort: { sortKey } }) => sortKey,
    selectConverted,
  ],
  (dir, key, items) => (key === 'klassTitle'
    ? tableColumnSortClass(dir, prop(key), items)
    : tableColumnSort(dir, prop(key), items)),
);

export default selectGroups;
