import { ReactNode } from 'react'
import classNames from 'classnames/bind'

import { AvailableDisplayName } from './available-display-name'
import { type HandleModalDisplayed } from './use-modal-displayed'
import ContentScrollbars from './content-scrollbars'

import styles from './plain-modal-content.scss'


const cx = classNames.bind(styles)

type Props = {
  children: ReactNode | ((toggle: HandleModalDisplayed) => ReactNode)
  className?: string
  containerClassName?: string
  handleModalDisplayed?: HandleModalDisplayed
  maxHeight?: number | string
  paddingFree?: boolean
  hideHorizontalScrollBar?: boolean
  borderTop?: boolean
  borderBottom?: boolean
  unscrollable?: boolean
}

const PlainModalContent = ({
  children,
  className,
  containerClassName,
  handleModalDisplayed,
  maxHeight = '60vh',
  paddingFree,
  hideHorizontalScrollBar,
  borderTop,
  borderBottom,
  unscrollable,
}: Props): ReactNode => {
  const content = (
    <div className={cx({ modalContent: !paddingFree, borderBottom, borderTop }, className)}>
      {typeof children === 'function'
        && handleModalDisplayed
        && children(handleModalDisplayed)}

      {typeof children !== 'function' && children}
    </div>
  )

  if (unscrollable) return content

  return (
    <ContentScrollbars
      maxHeight={maxHeight}
      className={containerClassName}
      hideHorizontalScrollBar={hideHorizontalScrollBar}
    >
      {content}
    </ContentScrollbars>
  )
}

PlainModalContent.displayName = AvailableDisplayName.PlainModalContent

export default PlainModalContent
