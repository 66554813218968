// @flow
import React from 'react';
import Sticky from 'react-sticky-el';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';
import { Prompt } from 'react-router-dom';

import SelectRoles from 'app/components/ui/SelectRoles';
import Button from 'app/common/ui/Button';
import RolesTable from 'app/common/components/RolesTable';
import IconView from 'app/common/icons/IconView';
import IconEdit from 'app/common/icons/IconEdit';
import Loader from 'app/common/components/Loader';
import Tooltip from 'app/common/ui/Tooltip';

import Menu from '../Menu';
import enhanceAdmin from './enhanceAdmin';

import styles from './Admin.scss';


const arrange = ['Клиент', 'WEB'];

const replace = {
  Блюда: 'Продукция',
  'Категории блюд': 'Категории продукции',
  'Блюда школы': 'Продукция школы',
  'Блюда поставщика': 'Продукция поставщика',
};

const arrangeRows = {
  Настройки: ['Реквизиты', 'Дотации', 'Дополнительно', 'Настройки ОУ', 'Данные', 'Сеть', 'Устройства', 'Система'],
};


type Props = {
  handleChangeCurrentRole: (Object) => void,
  adminSubmit: (SyntheticEvent<HTMLElement>) => void,
  handleCheck: (SyntheticEvent<HTMLElement>) => void,
  loading: boolean,
  rolesLoading: boolean,
  saving: boolean,
  currentRole: ?number,
  isModified: boolean,
  rights: Object,
  handleRolesLoad: (number) => void,
};

const Admin = ({
  handleChangeCurrentRole,
  adminSubmit,
  handleCheck,
  loading,
  rolesLoading,
  saving,
  currentRole,
  isModified,
  rights,
  handleRolesLoad,
}: Props) => (<div>
  <Sticky className={styles.sticky} stickyClassName={styles.stickyTop}>
    <div className={styles.menu}>
      <Menu />
    </div>
    <div className={styles.contentTop}>
      <div className={styles.legend}>
        <div className={styles.info}>
          <IconView />
          <span>{'\u00A0— просмотр'}</span>
        </div>
        <div className={styles.info}>
          <IconEdit />
          <span>{'\u00A0— редактирование'}</span>
        </div>
      </div>
      <div className={styles.selector}>
        <div className={styles.dropdown}>
          <SelectRoles
            labelStyle={{ marginTop: 0 }}
            id="usertype"
            label="Тип пользователя"
            placeholder={loading ? 'Тип пользователя' : 'Загрузка...'}
            onLoad={handleRolesLoad}
            current={currentRole}
            onChange={handleChangeCurrentRole}
            uni
          />
        </div>
        <Tooltip text={!isModified ? 'Необходимо внести изменения в права доступа' : null}>
          <Button
            value={!saving ? 'Сохранить' : 'Сохранение...'}
            onClick={adminSubmit}
            disabled={!isModified || rolesLoading || loading || saving}
          />
        </Tooltip>
      </div>
    </div>
  </Sticky>
  <div className={styles.content}>
    {loading && isEmpty(rights) && <Loader />}
    <div className={styles.contraction}>
      {values(rights).map(({ key, subsections }) => (<RolesTable
        key={key}
        title={key}
        data={subsections}
        arrangeRows={arrangeRows[key]}
        arrange={arrange}
        onChange={handleCheck}
        loading={loading || saving}
        replace={replace}
      />))}
    </div>
  </div>
  <Prompt
    when={isModified}
    message="Вы не сохранили изменения, перейти в любом случае?"
  />
</div>);

export default enhanceAdmin(Admin);
