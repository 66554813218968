
// @flow
import { when } from 'ramda';

import type { ParamsPOST } from 'app/dataTypes/org/add';

import type { Request } from '../../index';


type ApiParams = {
  title: string,
  full_title?: string,
}

export const convertParams = (params: ParamsPOST): [ApiParams, null] => {
  const { titleShort, titleComplete } = params;

  return [when(
    () => !!titleComplete,
    p => ({ ...p, full_title: titleComplete }),
  )({ title: titleShort }), null];
};

export const POST_ORG: Request<
  ParamsPOST,
  'success',
  void
> = [
  'food/school',
  'post',
  ['data', 'result'],
];
