import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import equals from 'ramda/es/equals';
import isEmpty from 'ramda/es/isEmpty';

import {
  employeesFilterFetch,
  employeesFilterClear,
  employeesFilterDelete,
  employeesFilterSet,
  employeesFilterUpdate,
} from 'app/actions/reports/filters/employees';

import { isSingle } from 'app/common/lib/object';
import { resetOnStatusChange } from 'app/components/Reports/hocs/withReset';
import * as customerTypes from 'app/common/constants/schools/customers';

import { selectOrgId, selectCustomerId } from './commonSelectors';


const selectIsAll = createSelector(
  [
    state => state.reports.filters.employees.data,
    state => state.reports.filters.employees.selected,
  ],
  // (data, selected) => (!isEmpty(data) && equals(data, selected))
  (data, selected) => (isEmpty(selected) || equals(data, selected)),
);

const selectIsEnableDefault = createSelector(
  [
    selectOrgId,
    selectCustomerId,
    state => state.reports.filters.date.current,
  ],
  (orgId, customerId, dateCurrent) => Boolean(
    orgId
    && customerId
    && customerId !== customerTypes.STUDENT
    && dateCurrent,
  ),
);

export default compose(
  connect(
    (state, { override, selectIsEnable }) => {
      const { customerId: overrideCustomerId } = override || {};
      const selectEnableStatus = selectIsEnable || selectIsEnableDefault;

      return ({
        items: state.reports.filters.employees.data,
        selectedItems: state.reports.filters.employees.selected,
        loading: state.reports.filters.employees.loading,
        orgId: selectOrgId(state),
        customerId: overrideCustomerId || selectCustomerId(state),
        isSelectedAllState: selectIsAll(state),
        isEnabled: selectEnableStatus(state),
        noData: !state.reports.filters.employees.loading && isEmpty(state.reports.filters.employees.data),
        active: state.reports.filters.employees.active,
      });
    },
  ),

  withHandlers({
    reset: ({ dispatch }) => () => {
      dispatch(employeesFilterSet({}));
    },
  }),

  withHandlers({
    onReset: ({ reset }) => (e) => {
      e.preventDefault();
      reset();
    },
    onItemRemove: ({ dispatch, toggleModal, selectedItems, reset }) => (e) => {
      e.preventDefault();
      const { name } = e.currentTarget;
      if (name === 'all' && typeof toggleModal === 'function') {
        toggleModal();
        return;
      }
      if (isSingle(selectedItems)) {
        reset();
        return;
      }
      dispatch(employeesFilterDelete([name]));
    },
    onSubmit: ({ dispatch }) => (submitted) => {
      dispatch(employeesFilterSet(submitted));
    },
  }),

  lifecycle({
    componentDidMount() {
      const { isEnabled, dispatch } = this.props;
      if (isEnabled) {
        dispatch(employeesFilterUpdate({ active: isEnabled }));
      }
    },
    async componentDidUpdate(prevProps) {
      const { dispatch, orgId, customerId, reset, isEnabled } = this.props;
      if (orgId !== prevProps.orgId || customerId !== prevProps.customerId) {
        if (orgId && customerId) {
          await dispatch(employeesFilterFetch(
            orgId,
            customerId,
          ));
          reset();
        }
      }
      if (isEnabled !== prevProps.isEnabled) {
        dispatch(employeesFilterUpdate({ active: isEnabled }));
      }
      resetOnStatusChange(this.props, prevProps);
    },
    componentWillUnmount() {
      const { dispatch } = this.props;
      dispatch(employeesFilterClear());
    },
  }),

  branch(({ isEnabled }) => !isEnabled, renderNothing),
);
