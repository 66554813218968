import { useMemo } from 'react'

import { dataKey as menuDataKey } from 'app/components/OrgsList/OrgsMenu'
import { dataKey as kitsDataKey } from 'app/components/OrgsList/OrgsKits'
import { dataKey as dishesDataKey } from 'app/components/OrgsList/OrgsDishes'
import { dataKey as ordersDataKey } from 'app/components/OrgsList/OrgsOrders'
import { dataKey as settingsDataKey } from 'app/components/OrgsList/OrgsSettings'

import { PageType, pageTypes } from './pageTypes'
import getBaseUrl from './getBaseUrl'


export default function useUrlBases(orgId?: string): Map<PageType, string> {
  const res = useMemo(() => {
    return new Map([
      [pageTypes.menu, getBaseUrl({ orgId, section: menuDataKey })],
      [pageTypes.kits, getBaseUrl({ orgId, section: kitsDataKey })],
      [pageTypes.dishes, getBaseUrl({ orgId, section: dishesDataKey })],
      [pageTypes.orders, getBaseUrl({ orgId, section: ordersDataKey })],
      [pageTypes.settings, getBaseUrl({ orgId, section: settingsDataKey })],
    ])
  }, [orgId])

  return res
}
