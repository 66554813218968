// @flow
import React, { type Node } from 'react';
import { slice } from 'ramda';

import Checkbox from 'app/common/ui/Checkbox';
import WithHOC from 'app/common/components/WithHOC';
import CheckboxSettings from 'app/containers/Settings/Checkbox';
import SettingsGear from 'app/common/components/Settings';

import { dataKey } from './AvailableDishesTable/dataKey';
import { columns } from './AvailableDishesTable/columns';
import styles from './VendingDishesEditListGear.scss';


const settingsIdentifiers = slice(2, Infinity, columns);
const settingsTitles = ['Фото', 'Порция', 'Стоимость'];

type Props = {
  className?: string,
  hideRows?: boolean,
}

const VendingDishesEditListGear = ({ className, hideRows }: Props): Node => (<SettingsGear dataKey={dataKey} className={className} hideRows={hideRows}>
  {({ saveSettings }) => (<div>
    <div className={styles.gearHeader}>{'Столбцы:'}</div>
    {settingsIdentifiers.map((id, i) => (
      <WithHOC
        key={id}
        component={Checkbox}
        hoc={CheckboxSettings}
        label={settingsTitles[i]}
        name={id}
        saveSettings={saveSettings}
        className={styles.gearCheck}
        settingsKey={dataKey}
      />
    ))}
  </div>)}
</SettingsGear>);

export default VendingDishesEditListGear;
