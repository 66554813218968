// @flow
import React, { useMemo } from 'react';

import { SummaryItem, SummaryRow, SummaryTitle } from 'app/components/common/reports/summary';
import toFixedValue from 'app/common/lib/toFixedValue';

import type { Summary } from '../useReportData';


type Props = {
  summary: Summary,
  currency: string,
  loading: boolean,
}

const SummaryComponent = ({ summary, currency, loading }: Props) => {
  const [title, value] = useMemo(() => {
    const totalTitleText = 'Баланс:';

    const { total } = summary || {};
    const totalValueText = `${toFixedValue(total)} ${currency}`;

    return [totalTitleText, totalValueText];
  }, [summary, currency]);


  return (
    <SummaryRow baseline>
      <SummaryTitle>
        {title}
      </SummaryTitle>
      <SummaryItem
        title=""
        value={value}
        loading={loading}
      />
    </SummaryRow>
  );
};

export default SummaryComponent;
