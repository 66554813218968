// @flow
import type { Request } from '../index';


export type PutEmployeeParams = {
  id: number,
  photo?: { // фото, опционально
    file: string,
    w: number, // ширина новой фотографии
    o?: number, // ???
    // обрезка фотографии (после изменения размера)
    x1: number, // обрезка от правого края
    y1: number, // обрезка от верхнего края
    x2: number, // правая граница обрезки
    y2: number, // нижняя граница обрезки
  } | null,
  name?: {
    first: string,
    last: string,
    middle: ?string,
  },
  gender?: 'male' | 'female',
  birthdate?: ?string,
  passport?: {
    series: ?string,
    num: ?string,
    date: ?string,
    place: ?string,
    birthplace: ?string,
  },
  inn?: ?string,
  snils?: ?string,
  medical?: ?string,
  phone?: ?string,
  homephone?: ?string,
  workphone?: ?string,
  email?: ?string,
  role_id?: ?number,
  address?: {
    country: ?string,
    subject: ?string,
    town: ?string,
    street: ?string,
    index: ?string,
    house: ?string,
    corp: ?string,
    apart: ?string
  }
}

type PutEmployeeResult = {
  id: number,
  photo_url: ?string,
  name: {
    first: string,
    last: string,
    middle: ?string,
  },
  gender: 'male' | 'female' | null,
  birthdate: ?string,
  passport: {
    series: ?string,
    num: ?string,
    date: ?string,
    place: ?string,
    birthplace: ?string,
  },
  inn: ?string,
  snils: ?string,
  medical: ?string,
  phone: ?string,
  homephone: ?string,
  workphone: ?string,
  email: ?string,
  role_id: ?number,
  address: {
    country: ?string,
    subject: ?string,
    town: ?string,
    street: ?string,
    index: ?string,
    house: ?string,
    corp: ?string,
    apart: ?string
  }
}

export const PUT_EMPLOYEE: Request<
  PutEmployeeParams,
  PutEmployeeResult,
  void
> = [
  'food/employee',
  'put',
  ['data', 'fields'],
];
