import { KeyboardEvent, MouseEvent, ReactNode, forwardRef } from 'react'
import classNames from 'classnames/bind'

import { SurfaceType } from '../plain-dropdown-surface'
import styles from './plain-button.scss'


const cx = classNames.bind(styles)

export type PlainButtonProps = {
  children: ReactNode,
  type?: 'button' | 'submit', // 'reset' type forbidden, do not use
  id?: string,
  name?: string,
  disabled?: boolean,
  outline?: boolean,
  className?: string,
  danger?: boolean,
  success?: boolean,
  warning?: boolean,
  autoFocus?: boolean,
  compact?: boolean,
  form?: string,
  onClick?: (e: MouseEvent<HTMLElement>) => void | Promise<void>,
  onKeyUp?: (e: KeyboardEvent<HTMLButtonElement>) => void | Promise<void>,
  ariaLabel?: string,
  ariaHaspopup?: SurfaceType,
  ariaExpanded?: 'true' | 'false',
}

const PlainButton = forwardRef<HTMLButtonElement, PlainButtonProps>(({
  children,
  type,
  id,
  name,
  disabled,
  outline,
  className,
  danger,
  success,
  warning,
  autoFocus,
  compact,
  form,
  onClick,
  onKeyUp,
  ariaLabel,
  ariaHaspopup,
  ariaExpanded,
}: PlainButtonProps, ref) => {
  const props = {
    id,
    name,
    'aria-label': ariaLabel,
    'aria-haspopup': ariaHaspopup,
    'aria-expanded': ariaExpanded,
    ref,
    onClick,
    onKeyUp,
    disabled,
    autoFocus,
    className: cx(styles.root, {
      default: !outline && !disabled,
      outline,
      disabled,
      danger,
      success,
      warning,
      compact,
    }, className),
    form,
  }

  // type must be static:
  if (type === 'submit') {
    return (
      <button type="submit" {...props}>
        <span className={styles.label}>
          {children}
        </span>
      </button>
    )
  }

  // default type: "button"
  return (
    <button type="button" {...props}>
      <span className={styles.label}>
        {children}
      </span>
    </button>
  )
})

export default PlainButton
