// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import Tab from './Tab';
import styles from './Tabs.scss';


const cx = classNames.bind(styles);

type Props = {
  children: Node,
  onChange: (index: number, e: SyntheticEvent<HTMLElement>) => void,
  value: number,
  vertical?: boolean,
  skipUpperSpace?: boolean,
  className?: string,
}

const Tabs = ({ children, onChange, skipUpperSpace, value, vertical, className }: Props) => (
  <div className={cx(styles.root, { vertical, skipUpperSpace }, className)}>
    {React.Children.map(children, (comp) => {
      if (!comp) return null;
      const { props: { index, ...others } } = comp;
      return (
        <Tab
          className={cx({ row: !vertical, column: vertical })}
          active={index === value}
          onClick={onChange}
          index={index}
          {...others}
        />
      );
    })}
  </div>
);

export default Tabs;
