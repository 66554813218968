import { EMPLOYEE_IDENTIFIERS_SUCCESS } from 'app/actions/employees/employee/identifiers';
import { ADDIDENTIFIER_SUBMIT_SUCCESS } from 'app/actions/employees/employee/addIdentifierForm';
import { IDENTIFIER_UPDATE_SUCCESS } from 'app/actions/employees/employee/identifiers/update';


const defaultState = {};

const identifiers = (state = defaultState, action) => {
  if (action.type === EMPLOYEE_IDENTIFIERS_SUCCESS) {
    return ({ ...action.payload });
  }
  if (action.type === ADDIDENTIFIER_SUBMIT_SUCCESS
    || action.type === IDENTIFIER_UPDATE_SUCCESS) {
    return ({ ...state, ...action.payload });
  }
  return state;
};

export default identifiers;
