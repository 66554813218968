import { ChangeEvent, useCallback } from 'react'
import cn from 'classnames'

import PlainRadioButton from 'app/common/ui-next/plain-radio-button'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import styles from './TypeSwitcher.scss'


type Props = {
  className?: string
  type: 'orgs' | 'orgGroups'
  onChange?: (type: 'orgs' | 'orgGroups') => void
}

const TypeSwitcher = ({ className, type, onChange }: Props) => {
  const onChangeRef = useCallbackRef(onChange)
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    if (value === 'orgs' || value === 'orgGroups') {
      onChangeRef.current?.(value as 'orgs' | 'orgGroups')
    }
  }, [onChangeRef])

  return (
    <div className={cn(styles.root, className)}>
      <PlainRadioButton
        value="orgs"
        name="type"
        label="Выбор по наименованию заведения"
        onChange={handleChange}
        checked={type === 'orgs'}
      />
      <PlainRadioButton
        value="orgGroups"
        name="type"
        label="Выбор по группам заведений"
        onChange={handleChange}
        checked={type === 'orgGroups'}
      />
    </div>
  )
}

export default TypeSwitcher
