// @flow
import React from 'react';
import Popup from 'app/common/components/Popup';
import { useTranslation } from 'react-i18next';

import ConnectionTimeOut from './ConnectionTimeOut';
import enhanceFailureHandler from './enhanceFailureHandler';


type Props = {
  messages: Array<Object>,
  handleRemoveItem: (number) => void,
  isDisconnected: boolean,
  isMaintenance: boolean,
};

const FailureHandler = ({
  messages,
  handleRemoveItem,
  isDisconnected,
  isMaintenance,
}: Props) => {
  const { t } = useTranslation('failure');

  return (
    <div>
      {messages.map((item, i) => (<Popup
        key={item.id}
        type={item.type}
        style={{ bottom: (125 * i) + 50 }}
        onClose={handleRemoveItem}
        idx={i}
      >
        {item.msg}
      </Popup>))}

      <ConnectionTimeOut
        isOpen={[isDisconnected]}
        title={isMaintenance ? t('503_title') : undefined}
        text={isMaintenance ? t('503_description') : undefined}
      />

      {/* <ConnectionTimeOut
        isOpen={isMaintenance}
        title={t('503_title')}
        text={t('503_description')}
      /> */}
    </div>
  );
};

export default enhanceFailureHandler(FailureHandler);
