// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import ReportSection from 'app/components/Reports/common/ReportSection';
import Tabs, { TabItem } from 'app/common/ui/Tabs';


type Props = {
  currentSubReport: string,
  subReports: Array<string>,
  tabTitles: Array<string>,
  baseUrl: string,
}

const ReportTabs = ({ currentSubReport, baseUrl, tabTitles, subReports }: Props) => (
  <ReportSection top paddingFree>
    <Tabs>
      {subReports.map((report, i) => (
        <TabItem active={currentSubReport === report} key={report}>
          <Link to={`${baseUrl}/${report}`}>
            {tabTitles[i]}
          </Link>
        </TabItem>
      ))}
    </Tabs>
  </ReportSection>
);

export default ReportTabs;
