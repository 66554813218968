import api from 'app/actions/api';

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE';

const settingsRequest = () => ({
  type: SETTINGS_REQUEST,
});

const settingsSuccess = settings => ({
  type: SETTINGS_SUCCESS,
  payload: settings,
});

const settingsFailure = payload => ({
  type: SETTINGS_FAILURE,
  payload,
});

/**
* Запрос настроек приложений с сервера. Так же приходят настройки дилера
*/
export const fetchSettings = () => async (dispatch) => {
  dispatch(settingsRequest());
  try {
    const res = await api('settings', 'get');
    dispatch(settingsSuccess(res.data.settings));
  } catch (error) {
    dispatch(settingsFailure({ err: 'Не удалось получить настройки с сервера', error }));
  }
};
