// @flow
import React, { type Node, useCallback, useEffect, useState, useRef } from 'react';

import setInputSelection from 'app/common/lib/setInputSelection';

import styles from './number-input.scss';


type NumberInputProps = {
  id: string,
  value: number,
  onChange: (val: number) => number,
  onBeginEdit: (id: string) => void,
  onStopEdit: (id: string) => void,
}

export const NumberInput = ({
  id,
  value,
  onChange,
  onBeginEdit,
  onStopEdit,
}: NumberInputProps): Node => {
  const [localValue, setLocalValue] = useState(value || 0);
  const savedValue = useRef(null);
  const resetOnBlur = useRef(false);

  useEffect(() => {
    setLocalValue(value);
    savedValue.current = value;
  }, [value]);

  const commitValue = useCallback(() => {
    if (savedValue.current !== localValue) {
      const commited = onChange(localValue);
      setLocalValue(commited);
    }
  }, [onChange, localValue]);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value: v } = e.currentTarget;
    if (!v) {
      setLocalValue(0);
    }
    const valueNumber = parseInt(v, 10);
    if (!Number.isNaN(valueNumber)) {
      setLocalValue(valueNumber);
    }
  }, []);

  const handleFocus = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const input = e.currentTarget;
    const { value: v } = input;
    onBeginEdit(id);
    setInputSelection(0, v.length, input);
  }, [onBeginEdit, id]);

  const handleBlur = useCallback(() => {
    onStopEdit(id);
    if (resetOnBlur.current && savedValue.current) {
      setLocalValue(savedValue.current);
      resetOnBlur.current = false;
      return;
    }
    commitValue();
  }, [onStopEdit, commitValue, id]);

  const handleKeyDown = useCallback((e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const input = e.currentTarget;
    if (key === 'Escape') {
      resetOnBlur.current = true;
      input.blur();
    }
    if (key === 'Enter') {
      input.blur();
    }
  }, []);

  const elementId = `plItem${id}`;

  return (
    <label htmlFor={elementId}>
      <span className="visuallyHidden">
        {'Максимальное количество товара:'}
      </span>
      <input
        id={elementId}
        className={styles.root}
        value={localValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    </label>
  );
};


type NumberStaticProps = {
  value: number,
}

export const NumberStatic = ({ value }: NumberStaticProps): Node => (
  <span className={styles.root}>
    {value}
  </span>
);
