// @flow
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import iframeResizer from 'iframe-resizer/js/iframeResizer';
import { withTranslation } from 'react-i18next';

import Modal from 'app/common/components/Modal';
import Button from 'app/common/ui/Button';
import styles from './Agreement.scss';


type Props = {
  close: (e: SyntheticEvent<HTMLElement>) => void,
  url: string,
  t: (s: string) => string,
};

class Agreement extends React.Component<Props> {
  frameRef = (item: any) => {
    iframeResizer({ checkOrigin: false }, item);
  };

  props: Props;

  render() {
    const { close, url, t } = this.props;

    return (
      <Modal close={close}>
        <div className={styles.content}>

          <div className={styles.header}>{t('agreement_header')}</div>
          <Scrollbars style={{ width: '100%', height: 400, display: 'block' }} className={styles.text}>
            <iframe
              src={`${url}?iframeSizer=true`}
              frameBorder="0"
              scrolling="no"
              ref={this.frameRef}
              className={styles.frame}
              title="agreement"
            />
          </Scrollbars>

          <div className={styles.buttons}>
            <Button value={t('close')} onClick={close} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation('about')(Agreement);
