import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { api } from 'app/api'
import { GET_FIND_CUSTOMER, convertResult, convertParams } from 'app/common/api/requests/reports/find_customer/get'
import { Customer } from 'app/common/ui-next/_filter-compilations/customer-search/types'


export type CustomerPhrase = string

class CustomerSearchPhraseDataBox extends DataBox<string> { }

export const defaultCustomerPhrase: string = ''
export const customerSearchPhraseFilter = createDataBox('customerSearchFilter', defaultCustomerPhrase, CustomerSearchPhraseDataBox)

export const defaultCustomerSearch: Customer | null = null
export const customerSearchFilter = createDataBoxDefault<Customer | null>('customerSearchFilter', defaultCustomerSearch)


export const getCustomerSearchDataReceiver = async () => {
  const searchPhrase = customerSearchPhraseFilter.getData()
  if (!searchPhrase) return defaultCustomerSearch
  const res = await api.request(GET_FIND_CUSTOMER, {
    error: 'Поиск не удалось осуществить',
    params: {
      search: searchPhrase,
    },
    convertions: {
      convertResult,
      convertParams,
    },
  })
  customerSearchPhraseFilter.updateData(() => defaultCustomerPhrase)
  return res ?? {} as Customer
}
