export const emailRe = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
export const phoneFormRe = /^\+\d{1}\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}$/; // depricated do not use
export const phoneRe = /^(\+7|8)?[\s-]*\d{3}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}$/;
export const phoneReKG = /^\+?(996)?[\s-]*\d{3}[\s-]*\d{3}[\s-]*\d{3}$/;
export const phoneReKZ = /^(\+7|8)?[\s-]*\d{3}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}$/;
export const phoneReUA = /^\+?(380)?[\s-]*\d{2}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}$/;
export const snilsRe = /^\d{3}-\d{3}-\d{3}\s\d{2}$/;

/**
 * Для удаления символов, которые не должны встречаться в данных типах полей
 */
export const phoneReplaceRe = /[^\d\s-+()]|(-|\)|\(|\+|\s)/g;
export const snilsReplaceRe = /[^\d\s-]|(-|\s)/g;

/**
 * Для теста "на лету"
 */
export const priceRe = /^-?\d*\.?\d{0,2}$/; // на лету, поэтому точку в конце можно
export const pricePositiveRe = /^\d*(\.\d{0,2})?$/; // на лету, поэтому точку в конце можно
export const intRe = /^-?\d*$/;
export const priceUnsignedRe = /^\d*\.?\d{0,2}$/;
export const snilsFlyRe = /^(\d{0,3}[-\s]?){0,3}(\d{0,2})$/; // snils
