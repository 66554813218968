import { ReactNode, RefCallback, memo, useCallback, useEffect } from 'react'

import {
  TrExpandable,
  Td,
  useCheckStateContext,
  fancyRowExpandedHeaderClassName,
  useFancyRowExpandedStyle,
  FancyRowHeader,
  TrExpandedContent,
} from 'app/common/ui-components/table'

import { CompensationInfo, CompensationInfoStatus } from 'app/dataTypes/compensation/info'

import { Column } from './columns'
import { Cell } from './cell'
import { getStatusColor } from './statusColors'
import { SubTableInfo } from './subTableInfo'


type Props = {
  ID: string
  columns: Array<Column>
  disabled?: boolean
  columnProps: Record<Column, {
    alignLeft?: boolean
    alignRight?: boolean
    alignCenter?: boolean
  }>
  compensationInfo: CompensationInfo
  date: string
  index: number
  calcTopShift: (index: number) => number
  trElem: HTMLTableRowElement | null
  updateRowNode: (el: HTMLTableRowElement | null, index: number) => void
  onAction: (params: {
    orgID: string
    action: 'record' | 'cancel' | 'interrupt'
  }) => Promise<boolean>
  onUpdateRequired: (ID: string) => void
  onRequestInfo: (orgID: string) => Promise<CompensationInfo | null>
  innerTableHeaderRef: RefCallback<'tr'>
  onRowDataUpdated: () => void
  compensationDetailsTable: (params: { orgID: string, allowanceID: string }) => ReactNode
  compensationDetailsTableFooter: () => ReactNode
  selectionMode: null | 'record' | 'cancel'
  selection: Set<string>
  onChangeSelection: (orgID: string, checked: boolean) => void
}

export const TableRow = memo(({
  ID,
  columns,
  disabled,
  columnProps,
  compensationInfo,
  date,
  index,
  calcTopShift,
  trElem,
  updateRowNode,
  onAction,
  onUpdateRequired,
  onRequestInfo,
  innerTableHeaderRef,
  onRowDataUpdated,
  compensationDetailsTable,
  compensationDetailsTableFooter,
  selectionMode,
  selection,
  onChangeSelection,
}: Props) => {
  const [expandedID, setExpandedID] = useCheckStateContext()
  const expanded = expandedID === ID
  const tinted = Boolean(expandedID && expandedID !== ID)

  const { status } = compensationInfo
  const color = getStatusColor(status)
  const fancyStyle = useFancyRowExpandedStyle(color)

  const reference = useCallback((el: HTMLTableRowElement | null) => {
    updateRowNode(el, index)
  }, [index, updateRowNode])

  const handleAction = useCallback(async (action: 'record' | 'cancel' | 'interrupt') => {
    const res = await onAction({ orgID: ID, action })
    return res
  }, [ID, onAction])

  useEffect(() => {
    if (compensationInfo.status === CompensationInfoStatus.InProgress) {
      setTimeout(() => onUpdateRequired(ID), 1000)
    }
    onRowDataUpdated()
  }, [compensationInfo]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TrExpandable
      classNameExpanded={fancyRowExpandedHeaderClassName}
      style={fancyStyle}
      styleExpanded={fancyStyle}
      reference={reference}
      id={ID}
      expandedContent={
        <TrExpandedContent fancy>
          {({ beforeLoadData, afterLoadData, loading }) => (
            <SubTableInfo
              orgID={ID}
              date={date}
              beforeLoadData={beforeLoadData}
              afterLoadData={afterLoadData}
              loading={loading}
              onDataRequest={onRequestInfo}
              compensationInfo={compensationInfo}
              compensationDetailsTable={compensationDetailsTable}
              compensationDetailsTableFooter={compensationDetailsTableFooter}
              headerRef={innerTableHeaderRef}
            />
          )}
        </TrExpandedContent>
      }
      expanded={expanded}
      onExpand={setExpandedID}
      disabled={disabled}
      disableExpand={status !== CompensationInfoStatus.Ready && status !== CompensationInfoStatus.Pending}
      tinted={tinted}
      hoverHighlighted={!expanded && status === CompensationInfoStatus.Ready}
      columnsAmount={columns.length}
      interlineBorder
      alignTop
    >
      {columns.map(key => (
        <Td key={key} {...columnProps[key]}>
          <Cell
            columnKey={key}
            compensationInfo={compensationInfo}
            date={date}
            onAction={handleAction}
            selectionMode={selectionMode}
            selection={selection}
            onChangeSelection={onChangeSelection}
          />
        </Td>
      ))}

      <FancyRowHeader
        expanded={expanded}
        requestTopShift={calcTopShift}
        rowIndex={index}
        color={color}
        trElem={trElem}
      />
    </TrExpandable>
  )
})
