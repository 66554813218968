// @flow
import React, { useCallback, useRef, type Node } from 'react';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';


type Props = {
  id?: string,
  label: string | Node,
  checked?: boolean,
  disabled?: boolean,
  onChange?: (e: SyntheticEvent<HTMLInputElement>, isInputChecked: boolean) => void,
  className?: string,
  value: string,
  style?: Object,
  handleChange?: (e: SyntheticEvent<HTMLInputElement>) => void,
  name?: string, // add from PlainCheckbox
  hideLabel?: boolean, // add from PlainCheckbox
}

// !!1 deprecated, use PlainCheckbox

const Checkbox = ({
  onChange,
  handleChange,
  disabled,
  ...other
}: Props) => {
  const refChekbox = useRef(null);

  const handleChangeCheckbox = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    if (!onChange || disabled || !refChekbox.current) return;
    const isInputChecked = refChekbox.current.checked;
    onChange(e, isInputChecked);
  }, [disabled, onChange]);

  return (
    <PlainCheckbox
      reference={refChekbox}
      onChange={handleChange || handleChangeCheckbox}
      disabled={disabled}
      {...other}
    />
  );
};

export default Checkbox;
