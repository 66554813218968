// @flow
import pipe from 'ramda/es/pipe';
import values from 'ramda/es/values';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import trim from 'ramda/es/trim';
import prepend from 'ramda/es/prepend';
import sortWith from 'ramda/es/sortWith';
import ascend from 'ramda/es/ascend';
import reduce from 'ramda/es/reduce';

import {
  type ItemCategoriesResult,
  type CategoryItem as ApiCategoryItem,
} from 'app/common/api/requests/food/schools/item_categories';

import type { CategoryListItem } from './CategoryListItem.type';


export const makeMapFunc = (pathTemplate: string) => ({
  id: incommingId,
  title,
  dishes_count: count,
  deletable,
  index,
}: ApiCategoryItem): CategoryListItem => {
  const id = incommingId === -1 ? 'all' : String(incommingId);
  return ({
    id,
    title: trim(title || '???'),
    count,
    path: pathTemplate.replace(':categoryId', id),
    deletable,
    index,
  });
};

const api2CategoryItems = (
  categories: ItemCategoriesResult,
  mapFunc: (ApiCategoryItem) => CategoryListItem,
): Array<CategoryListItem> => pipe(
  values,
  map(mapFunc),
  sortWith([
    ascend(prop('index')),
    ascend(prop('title')),
  ]),
)(categories);

export const prependTotal = (mapFunc: (ApiCategoryItem) => CategoryListItem) => (categoryList: Array<CategoryListItem>): Array<CategoryListItem> => prepend(mapFunc({
  id: -1,
  title: 'Все',
  dishes_count: reduce((acc, { count }) => (acc + count), 0, categoryList),
  deletable: false,
  index: -1,
}))(categoryList);

export default api2CategoryItems;
