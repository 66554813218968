import { AccrualsFor } from 'app/dataTypes/subsidy/settings'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import { FormGridRow, FormGridTemplate } from './FormGridTemplate'


export type Errors = Map<string, string>

export const controls = {
  single: 'single',
  class: 'class',
  dayCare: 'dayCare',
} as const

const defaultTtiles = {
  single: 'Начислять льготы при личных продажах',
  class: 'Начислять льготы при комплексных продажах в классе',
  dayCare: 'Начислять льготы при комплексных продажах в ГПД',
} as const

type Props = {
  data: AccrualsFor
  disabled?: boolean
  formID: string
  errors: Errors
  titles?: Record<keyof typeof controls, string>
  resetErrors: () => void
}

const AccuralsForForm = ({
  data,
  disabled,
  formID,
  errors,
  titles = defaultTtiles,
  resetErrors,
}: Props) => {
  const error = errors.get('accrualsFor')

  return (
    <>
      <FormGridTemplate
        title="При каких типах продаж начислять льготы:"
      >
        {data.single
          && <FormGridRow
            name={controls.single}
            title={titles[controls.single]}
            data={data.single}
            disabled={disabled}
            formID={formID}
            onModify={resetErrors}
          />}

        {data.class
          && <FormGridRow
            name={controls.class}
            title={titles[controls.class]}
            data={data.class}
            disabled={disabled}
            formID={formID}
            onModify={resetErrors}
          />}

        {data.dayCare
          && <FormGridRow
            name={controls.dayCare}
            title={titles[controls.dayCare]}
            data={data.dayCare}
            disabled={disabled}
            formID={formID}
            onModify={resetErrors}
          />}
      </FormGridTemplate>

      <PlainHelperText
        className={getScrollToErrorClassName(!!error)}
        error
      >
        {error}
      </PlainHelperText>
    </>
  )
}

export default AccuralsForForm
