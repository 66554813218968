// @flow
import React from 'react';

import Underconstruction from './Underconstruction';
import SectionGeneral from './SectionGeneral';
import SectionJobPlaces from './SectionJobPlaces';
import SectionIdentifiers from './SectionIdentifiers';
import Settings from './SectionSettings';
import * as sections from './sections';


type Props = {
  section: string,
  title: string,
  personId: number,
  locked: boolean,
  baseUrl: string,
}

const EmployeeSection = ({ section, ...props }: Props) => {
  if (section === sections.GENERAL_SECTION) return <SectionGeneral {...props} />;
  if (section === sections.IDENTIFIERS_SECTION) return <SectionIdentifiers {...props} />;
  if (section === sections.JOBPLACES_SECTION) return <SectionJobPlaces {...props} />;
  if (section === sections.SETTINGS_SECTION) return <Settings {...props} />;
  return <Underconstruction />;
};

export default EmployeeSection;
