// @flow
import React, { type Node, Fragment, useState, useCallback } from 'react';

import { PUT_PLANORGAM_SLOTS_CONTENT, convertParams, convertResult } from 'app/common/api/requests/data/vending/machines/slots/content/put';
import { api } from 'app/api';
import type { PlanogramContent } from 'app/dataTypes/vending/PlanogramContent.types';
import type { ProductData, SlotData } from 'app/common/ui-components/planograms';

import ProductContainsModalTemplate from './ProductContainsModalTemplate';
import { defaultProduct, defaultSlot, extractNumber } from './helpers';


const extractDisableReason = (currentValue: number | null, amount: number): [boolean, string] => {
  if (currentValue === null) return [true, 'Введите количество изымаемой продукции'];
  if (currentValue > amount) return [true, 'Количество изымаемой продукции превышает текущее количество'];
  return [false, ''];
};

type Props = {
  children: ((product: ProductData, slot: SlotData) => void) => Node,
  machineId: string | null,
  onSuccess: (PlanogramContent) => void,
}

const DecreaseProduct = ({ children, machineId, onSuccess }: Props): Node => {
  const [currentSlot, setCurrentSlot] = useState<SlotData | null>(null);
  const [currentProduct, setCurrentProduct] = useState<ProductData | null>(null);

  const [currentValue, setCurrentValue] = useState<number | null>(null);
  const amountMax = extractNumber('amountMax', currentSlot);
  const amount = extractNumber('amount', currentSlot);

  const handleClickIncreaseProduct = useCallback((product: ProductData, slot: SlotData) => {
    setCurrentSlot(slot);
    setCurrentProduct(product);
  }, []);

  const handleDisplayStatusChange = useCallback((display: boolean) => {
    if (!display) {
      setCurrentSlot(null);
      setCurrentProduct(null);
    }
  }, []);

  const extractСompletely = useCallback(() => {
    setCurrentValue(amount);
  }, [amount]);

  const handleSubmit = useCallback(async () => {
    if (currentValue === null || !currentProduct || !currentSlot || !machineId) {
      return false;
    }

    const newAmount = amount - currentValue;

    const res = await api.request(PUT_PLANORGAM_SLOTS_CONTENT, {
      error: 'Не удалось изъять товар',
      params: {
        machineId,
        data: [{
          slotId: currentSlot.id,
          productId: newAmount <= 0 ? null : currentProduct.id,
          amount: newAmount,
        }],
      },
      convertions: {
        convertParams,
        convertResult,
      },
    });

    if (res) {
      onSuccess(res);
      setCurrentValue(null);
      return true;
    }
    return false;
  }, [currentProduct, currentSlot, machineId, onSuccess, currentValue, amount]);

  const [disableSubmit, disableSubmitReason] = extractDisableReason(currentValue, amount);

  const newAmount = currentValue !== null ? amount - currentValue : 0;
  const valueError = newAmount > amountMax || newAmount < 0;

  return (
    <Fragment>
      {children(handleClickIncreaseProduct)}

      <ProductContainsModalTemplate
        header="Изъять"
        onSubmit={handleSubmit}
        show={!!currentSlot}
        slotData={currentSlot || defaultSlot}
        productData={currentProduct || defaultProduct}
        disableSubmit={disableSubmit}
        disableSubmitReason={disableSubmitReason}
        value={currentValue}
        willBeValue={amount - (currentValue || 0)}
        valueError={valueError}
        specialButtonTitle="Изъять все"
        onChange={setCurrentValue}
        onClickSpecialButton={extractСompletely}
        onDisplayStatusChange={handleDisplayStatusChange}
      />
    </Fragment>
  );
};

export default DecreaseProduct;
