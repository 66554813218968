import { ReactNode } from 'react'
import cn from 'classnames/bind'

import styles from './status-message.scss'


const cx = cn.bind(styles)

type Props = {
  className?: string
  children: ReactNode
  skipPadding?: boolean
  asTableRow?: boolean
  colSpan?: number
  comment?: string | ReactNode
  error?: boolean
}

const StatusMessage = ({
  className,
  children,
  skipPadding,
  asTableRow,
  colSpan = 1,
  comment,
  error,
}: Props) => {
  const mainClassName = cx(styles.root, { skipPadding, error }, className)

  const content = (
    <>
      <div className={styles.text}>{children}</div>
      <div className={styles.comment}>{comment}</div>
    </>
  )

  if (asTableRow) {
    return (
      <tr>
        <td colSpan={colSpan} className={mainClassName}>
          {content}
        </td>
      </tr>
    )
  }

  return (
    <div className={mainClassName}>
      {content}
    </div>
  )
}

export default StatusMessage
