// @flow
import * as React from 'react';
import isEmpty from 'ramda/es/isEmpty';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import compose from 'recompose/compose';

import Modal from 'app/common/components/Modal';
import Button, { ButtonsBar } from 'app/common/ui/Button';
import Checkbox from 'app/common/ui/Checkbox';
import RadioButton from 'app/common/ui/RadioButton';
import PlainInputFocused from 'app/common/ui/PlainInput/PlainInputFocused';
import PlainInputIcon from 'app/common/ui/PlainInputIcon';
import omitProps from 'app/common/containers/omitProps';
import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow';

import enhanceFilterOptionsModal, { idPrefix } from './enhanceFilterOptionsModal';
import styles from './FilterOptionsModal.scss';


const CheckElement = compose(
  branch(({ radio }) => radio, renderComponent(RadioButton)),
  omitProps(['radio']),
)(Checkbox);

type Props = {
  header: string,
  isOpen: boolean,
  items: Array<Object>,
  selectedItemsLocal: Object,
  search: string,
  toggleModal: (e: SyntheticEvent<HTMLElement>) => void,
  handleCheck: (e: SyntheticEvent<HTMLElement>) => void,
  handleSelectAll: (e: SyntheticEvent<HTMLElement>) => void,
  handleSearch: (e: SyntheticInputEvent<HTMLElement>) => void,
  handleClearSearch: (e: SyntheticEvent<HTMLElement>) => void,
  handleSubmit: (e: SyntheticEvent<HTMLElement>) => void,
  enableSelectAll?: true,
  isAllSelected: boolean,
  singleSelectable: boolean,
  hideSearch?: boolean,
  emptyListMessage?: string,
}

const FilterOptionsModal = ({
  header,
  isOpen,
  items,
  selectedItemsLocal,
  search,
  toggleModal,
  handleCheck,
  handleSelectAll,
  handleSearch,
  handleClearSearch,
  handleSubmit,
  enableSelectAll,
  isAllSelected,
  singleSelectable,
  hideSearch,
  emptyListMessage,
}: Props) => {
  const isEmptyItems = isEmpty(items);
  return (
    <Modal
      isOpen={isOpen}
      close={toggleModal}
      className={styles.root}
    >
      <h2 className={styles.header}>{header}</h2>
      <form onSubmit={handleSubmit} name="filterOptionsModal">
        <div className={styles.content}>
          {!hideSearch && <PlainInputFocused
            className={styles.search}
            component={PlainInputIcon}
            placeholder="Поиск"
            onChange={handleSearch}
            value={search}
            icon={search
              && <a href="clear" className={styles.clearSearch} onClick={handleClearSearch}>{'×'}</a>}
          />}
          {enableSelectAll
            && !singleSelectable
            && !isEmptyItems
            && <Checkbox
              label="Выбрать все"
              value="all"
              onChange={handleSelectAll}
              className={styles.checkboxAll}
              checked={isAllSelected}
            />}
          <ScrollbarsWShadow
            autoHeight
            autoHeightMin={100}
            autoHeightMax={210}
          >
            <div className={styles.items}>
              {!isEmptyItems
                ? items.map(({ id, title, leftShiftLevel = 0 }) => (<CheckElement
                  key={id}
                  radio={singleSelectable}
                  label={title}
                  value={`${idPrefix}${id}`}
                  onChange={handleCheck}
                  className={styles.checkbox}
                  style={{ ...(leftShiftLevel ? { marginLeft: 30 * leftShiftLevel } : {}) }}
                  checked={!!selectedItemsLocal[id]}
                />))
                : <div className={styles.empty}>
                  {emptyListMessage || 'Нет записей, удовлетворяющих условиям'}
                </div>}
            </div>
          </ScrollbarsWShadow>
        </div>
        <ButtonsBar className={styles.footer} right>
          <Button onClick={toggleModal} outline>{'Отмена'}</Button>
          <Button submit>{'Выбрать'}</Button>
        </ButtonsBar>
      </form>
    </Modal>
  );
};


export default enhanceFilterOptionsModal(FilterOptionsModal);
