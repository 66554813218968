// @flow
import { useRef, useEffect, useState } from 'react';

import type { ParamsPUT } from 'app/dataTypes/vending/production/Categories.types';


export default function useWrongIndexesFix(
  wrongIndexes: Map<string, ParamsPUT>,
  categoryBatchChange: (paramsArray: Array<ParamsPUT>) => Promise<boolean>,
): boolean {
  const [pending, setPending] = useState(false);
  const alreadyHasOneTry = useRef(false);
  const inProcess = useRef(false);

  useEffect(() => {
    const hasWrongIndexes = wrongIndexes.size > 0;

    if (!hasWrongIndexes) {
      alreadyHasOneTry.current = false;
      return;
    }

    if (inProcess.current) {
      return;
    }

    if (alreadyHasOneTry.current) {
      // popup Успешный запрос на изменение все-таки оставил неверные индексы
      alreadyHasOneTry.current = false;
      return;
    }

    (async () => {
      setPending(true);
      inProcess.current = true;
      const res = await categoryBatchChange(Array.from(wrongIndexes.values()));
      if (res) {
        alreadyHasOneTry.current = true;
      }
      inProcess.current = false;
      setPending(false);
    })();
  }, [wrongIndexes]); // eslint-disable-line react-hooks/exhaustive-deps

  return pending;
}
