// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import prop from 'ramda/es/prop';
import length from 'ramda/es/length';
import values from 'ramda/es/values';
import path from 'ramda/es/path';
import last from 'ramda/es/last';

import { dateFormat, dateFilterSet } from 'app/actions/reports/filters/date';
import RadioButton, { RadioButtonGroup } from 'app/common/ui/RadioButton';
import ErrorString from 'app/common/ui/ErrorString';
import PlainInputDate from 'app/common/ui-next/plain-input-date';

import withDate from './hocs/withDate';
import styles from './FilterItems.scss';


type Props = {
  date: string,
  current: boolean,
  handleChangeDate: (a: string, b?: string) => void,
  disabledDays: (dayFormatted: string, format: string, unit?: string) => boolean,
  handleSwitchType: (e: SyntheticEvent<HTMLElement>) => void,
}

const DateFilter = ({
  date,
  current,
  handleChangeDate,
  disabledDays,
  handleSwitchType,
}: Props) => {
  const dispatch = useDispatch();
  const selectedOrgs = useSelector(state => state.reports.filters.orgs.selected, shallowEqual);

  useEffect(() => {
    const yearsArr = values(selectedOrgs);
    if (length(yearsArr) === 1) {
      const periods = path([0, 'periods'], yearsArr);
      if (!periods) return;
      const lastPeriod = last(periods);
      if (lastPeriod) {
        const to = prop('to', lastPeriod);
        if (to) {
          dispatch(dateFilterSet({ date: to }));
          dispatch(dateFilterSet({ current: false }));
        }
      }
    }
  }, [selectedOrgs, dispatch]);

  return (
    <div className={styles.filter}>
      <div className={styles.label}>{'Дата:'}</div>
      <div className={styles.radioGroup}>
        <RadioButtonGroup
          id="select_date"
          name="select_date"
          valueSelected={current ? 'current' : 'date'}
          onChange={handleSwitchType}
        >
          <RadioButton
            name="current"
            key="current"
            label="Текущее состояние"
            value="current"
          />
          <RadioButton
            name="date"
            key="date"
            label="Выбор даты "
            value="date"
            className={styles.dateRadio}
            additional={
              <span className={styles.dip}>
                &nbsp;
                <PlainInputDate
                  id="date"
                  value={date}
                  onDateChange={handleChangeDate}
                  dateFormat={dateFormat}
                  disabled={current}
                  dateDisabledStatus={disabledDays}
                  calendarCloseOnClick
                />
              </span>
            }
          />
        </RadioButtonGroup>
        <ErrorString messageMode className={styles.message}>
          {'Остаток на начало дня'}
        </ErrorString>
      </div>
    </div>
  );
};

export default withDate(DateFilter);
