// @flow
import { useMemo, useEffect } from 'react';
import find from 'ramda/es/find';
import propEq from 'ramda/es/propEq';

import type { CategoryListItem } from './CategoryListItem.type';

/**
 *  заголовок текущей категории
 */

type Options = {
  categoryList: Array<CategoryListItem>,
  currentCategoryId?: string,
  onCategoryInvalid: () => void,
}

export default function useCategoryHeader({
  categoryList,
  currentCategoryId,
  onCategoryInvalid,
}: Options) {
  const header = useMemo(() => {
    if (currentCategoryId) {
      if (currentCategoryId === 'all') {
        return 'Вся продукция';
      }
      const { title } = find(propEq('id', currentCategoryId), categoryList) || {};
      if (title) {
        return `Категория продукции: ${title}`;
      }
    }
    return '';
  }, [currentCategoryId, categoryList]);

  useEffect(() => {
    if (currentCategoryId && currentCategoryId !== 'all' && !header) {
      onCategoryInvalid();
    }
  }, [header, currentCategoryId]); // eslint-disable-line react-hooks/exhaustive-deps

  return header;
}
