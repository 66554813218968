// @flow
import map from 'ramda/es/map';
import trim from 'ramda/es/trim';


// trim всех строк в объекте любой вложенности
const trimParams = (params: Object) => {
  return map((param) => {
    if (typeof param === 'string') {
      return trim(param);
    }
    if (typeof param === 'object' && param !== null) {
      return trimParams(param);
    }
    return param;
  }, params);
};

export default trimParams;
