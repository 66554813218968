// @flow

const columns = [
  'title',
  'portion',
  'cost',
  'n',
  'sum',
];

const columnsNoPortion = [
  'title',
  'cost',
  'n',
  'sum',
];

const columnsSpecial = [
  'title',
  'sum',
  'n',
];

export const getColumns = ({
  special,
  skipPortion,
}: {
  special?: boolean,
  skipPortion?: boolean,
}): Array<string> => {
  if (special) {
    return columnsSpecial;
  }
  if (skipPortion) {
    return columnsNoPortion;
  }
  return columns;
};

export const getTitles = (currencySuffix: string) => ({
  title: 'Наименование',
  portion: 'Порция',
  cost: `Цена${currencySuffix}`,
  n: 'Кол-во',
  sum: `Сумма${currencySuffix}`,
});

export const cellProps = {
  title: { alignLeft: true },
  portion: { alignRight: true },
  cost: { alignRight: true },
  n: { alignRight: true },
  sum: { alignRight: true },
};
