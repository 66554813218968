// @flow
import split from 'ramda/es/split';
import prop from 'ramda/es/prop';

import * as appTypes from '../../api/appTypes';

import versionFile from '../../../version.json';


export const requestVersion = () => {
  return new Promise<string>((resolve, reject) => {
    const rawFile = new XMLHttpRequest();
    rawFile.overrideMimeType('application/json');
    rawFile.open('GET', '/version.json', true);
    rawFile.setRequestHeader('Cache-Control', 'no-cache, no-store, must-revalidate'); // !!! нельзя брать из кэша
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4 && rawFile.status === 200) {
        const { version } = JSON.parse(rawFile.responseText) || {};
        if (version) {
          resolve(version);
        }
      }
    };
    rawFile.onerror = () => {
      reject(new Error('network error'));
    };
    rawFile.send(null);
  });
};

export const compareVersions = (ver1: string = '', ver2: string = ''): 'failed' | 'critical' | 'minor' | null => {
  const splitted1 = split('.', ver1);
  const splitted2 = split('.', ver2);

  if (!splitted1.length || !splitted2.length || splitted1.length < 3 || splitted2.length < 3) {
    return 'failed';
  }

  if (
    parseInt(splitted1[0], 10) !== parseInt(splitted2[0], 10)
    || parseInt(splitted1[1], 10) !== parseInt(splitted2[1], 10)
  ) {
    return 'critical';
  }

  if (
    parseInt(splitted1[2], 10) !== parseInt(splitted2[2], 10)
  ) {
    return 'minor';
  }

  return null;
};


export const buildVersion = prop('version', versionFile);
export const buildDate = prop('date', versionFile);

if (!buildVersion || !buildDate) {
  console.error('!!! Невозможно прочитать файл или неаерный формат app/version.json');
}


export const getVersionsHistoryLink = () => {
  let externalLink = '';

  if ((process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_CABINET) {
    externalLink = 'https://innovat.gitbook.io/updates-web-roditely-i-uchaschegosya/';
  }
  if ((process.env.APP_NAME || process.env.REACT_APP_NAME) === appTypes.APP_TYPE_FOOD) {
    externalLink = 'https://innovat.gitbook.io/updates-web-pp/';
  }

  return externalLink;
};
