// @flow

/**
 *  Установка выделения в input элементе
 */

const setInputSelection = (from: number, to: number, elem: HTMLInputElement): void => {
  setTimeout(() => { // for safari
    elem.setSelectionRange(from, to);
  }, 0);
};

export default setInputSelection;
