import moment from 'moment'

import { useCallback } from 'react'
import { periodFilter } from 'app/dataProviders/filter-items'

import { PlainDatePeriodBox } from 'app/common/ui-next/_filter-compilations/plain-date-period'


export const dateFormat = 'DD-MM-YYYY'

const getYesterdayDate = () => {
  const yesterday = moment().subtract(1, 'days').format(dateFormat)

  const defaultDateFrom = yesterday
  const currentDateTo = yesterday

  return [defaultDateFrom, currentDateTo]
}


type Props = {
  id: string
  pending: boolean
}

export const PeriodFilter = ({ id, pending }: Props) => {
  const changePeriodToDefault = useCallback(() => {
    return getYesterdayDate()
  }, [])

  return (
    <PlainDatePeriodBox
      id={id}
      label="Период времени"
      loading={pending}
      dateFormat={dateFormat}
      dataBoxData={periodFilter}
      getDefaultState={changePeriodToDefault}
      periodLimit={30}
    />
  )
}
