// @flow
import sections from 'app/components/OrgsList/OrgsSettings/sections';


export const timeFormat: 'DD.MM.YYYY HH:mm' = 'DD.MM.YYYY HH:mm';
export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

export type Params = {
  orgId: number | string,
  limit?: number,
  offset?: number,
  from?: string,
  to?: string,
  sourceId?: string,
  actorId?: string,
  section?: $Values<typeof sections>,
}

export type Change = {
  id: string,
  created: string,
  createdSeconds: number,
  source: string,
  actor: string,
  description: string,
}

export type Count = number
export type ChangesData = Array<Change>
