import { useCallback, useState } from 'react'

import { api } from 'app/api'
import { GET_COMPENSATION_DETAILS, convertParams, convertResult } from 'app/common/api/requests/food/compensation/details/get'
import { CompensationDetails, CompensationDetailsParams, isCompensationDetailsParams } from 'app/dataTypes/compensation/details'


const defaultCompensationDetails: CompensationDetails = {
  students: [],
  total: {
    students: 0,
    days: 0,
    sum: 0,
  },
}

export function useCompensationDetails(paramsCommon: Partial<CompensationDetailsParams>) {
  const { orgID, year, month, allowanceID } = paramsCommon || {}

  const [details, setDetails] = useState<CompensationDetails>(defaultCompensationDetails)

  const request = useCallback(async (paramsLocal?: Partial<CompensationDetailsParams>) => {
    const params = { orgID, year, month, allowanceID, ...paramsLocal }

    if (!isCompensationDetailsParams(params)) return

    const res = await api.request(GET_COMPENSATION_DETAILS, {
      error: 'Не удалось получить детализацию по категории льгот',
      params,
      convertions: {
        convertParams,
        convertResult,
      },
    })

    if (res) {
      setDetails(res)
    }
  }, [orgID, year, month, allowanceID])

  return { request, details }
}
