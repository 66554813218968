// @flow
import { studentSubsidyTypes } from 'app/dataTypes/reports/studentSubsidyTypes';
import { defaultStateDataCurrent } from 'app/common/ui-components/filter';


export { studentSubsidyTypes };

const studentSubsidyTypeItems = () => ([[{
  id: studentSubsidyTypes.WithoutSubsidies,
  title: 'Без начисления',
}, {
  id: studentSubsidyTypes.WithSubsidies,
  title: 'С начислением',
}], defaultStateDataCurrent]);

export default studentSubsidyTypeItems;
