// @flow
import reduce from 'ramda/src/reduce';

import { emailRe } from 'app/common/constants/regex';


export const phoneRe = /^\+?(7|8|7)?[\s-]*\d{3}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}$/;
export const phoneReKG = /^\+?(996)?[\s-]*\d{3}[\s-]*\d{3}[\s-]*\d{3}$/;
export const phoneReKZ = /^(\+7|8|7)?[\s-]*\d{3}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}$/;
export const phoneReUA = /^\+?(380)?[\s-]*\d{2}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}$/;

export const phoneReUAOnlyNumbers = /^380\d{9}$/;
export const phoneReKGOnlyNumbers = /^996\d{9}$/;
export const phoneReRUOnlyNumbers = /^(8|7)?\d{10}$/;

export const EMAIL_MIN_LENGTH = 7;
export const LOGIN_MIN_LENGTH = 1;
export const PASSWORD_MIN_LENGTH = 1;
export const CODE_LENGTH = 6;

const testByRe = (login) => {
  const numbers = login.replace(/\D+/g, '');

  return (
    phoneReRUOnlyNumbers.test(numbers)
    || phoneReKGOnlyNumbers.test(numbers)
    || phoneReUAOnlyNumbers.test(numbers)
  );
};

export const validateLoginAsPhone = (login: string) => testByRe(login);
export const validateLoginAsEmail = (login: string) => (
  emailRe.test(login)
  && login.length >= EMAIL_MIN_LENGTH
);

type Options = {
  types?: Array<'email' | 'phone'>,
  strict?: boolean,
}

const defaultOptions: Options = {
  types: ['email', 'phone'],
  strict: false,
};

export const validateLogin = (
  countryISOCode: string,
  login: string,
  options?: Options,
) => {
  const { types, strict } = { ...defaultOptions, ...(options || {}) };

  // Нельзя верить countryISOCode - как оказалось логиниться могут с разных доменов
  // поэтому игнорируем

  // для этих кабинетов, если не указана строгая проверка, любой логин валидный
  if (!strict && ((process.env.APP_NAME || process.env.REACT_APP_NAME) === 'food' || (process.env.APP_NAME || process.env.REACT_APP_NAME) === 'dealer')) {
    return (login.length >= LOGIN_MIN_LENGTH);
  }

  return reduce((acc, val) => {
    if (val === 'email') {
      return acc || validateLoginAsEmail(login);
    }
    if (val === 'phone') {
      return acc || validateLoginAsPhone(login);
    }
    return acc;
  }, false, types);
};


export const isLoginKG = (login: string) => phoneReKG.test(login);

export const validatePassword = (password: string) => (password.length >= PASSWORD_MIN_LENGTH);

export const codeRegEx = new RegExp(`^\\d{0,${CODE_LENGTH}}$`);

export const validateCode = (code: string) => codeRegEx.test(code);
