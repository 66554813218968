// @flow
import React, { type Node, Fragment } from 'react';


type Props = {
  value: string,
  children: Node,
}

const CellDate = ({ value, children }: Props): Node => {
  const lines = value.split(' ');

  return (
    <Fragment>
      {lines.map(str => <div key={str}>{str}</div>)}
      {children}
    </Fragment>
  );
};

export default CellDate;
