// @flow
import React, { type Node, useCallback } from 'react';

import Restrictions from 'app/common/components/Restrictions';
import ChangePassword from 'app/common/components/ChangePassword';
import selectIsChangePassword from 'app/common/components/ChangePassword/selectIsChangePassword';
import ChangeLogin from 'app/common/components/ChangeLogin';
import selectIsChangeLogin from 'app/common/components/ChangeLogin/selectIsChangeLogin';
import Confirmation from 'app/common/components/Confirmation';
import AnotherUserLoggedIn from 'app/common/components-next/another-user-logged-in';

import FailureHandler from 'app/common/components/FailureHandler';
import AppBar, { LeftBarContent, RightBarContent } from 'app/common/ui-components/app-bar';
import { appTypes } from 'app/common/api';
import Pages from 'app/pages';

import { /* RsnEmail, isRsnEmail, */RsnDocument, isRsnDocument } from './restrictions';
import UserMenuLocal from './UserMenuLocal';
import enhanceApp from './enhanceApp';
import Logo from './Logo';
import AppFooter from './AppFooter';
import AppMenuItems from './AppMenuItems';
import FavoritesBar from './FavoritesBar';

import styles from './App.scss';


const loginRestrictions = {
  condition: selectIsChangeLogin,
  component: ChangeLogin,
};

const passwordRestrictions = {
  condition: selectIsChangePassword,
  component: ChangePassword,
};

const documentsRestrictions = {
  condition: isRsnDocument,
  component: RsnDocument,
};

type Props = {
  location: {
    pathname: string,
  },
  history: {
    replace: (string) => void,
  },
}

const App = ({ location, history: { replace } }: Props): Node => {
  const { pathname } = location;

  const disabledDocumentsByCabinetType = (
    process.env.APP_NAME || process.env.REACT_APP_NAME
  ) === appTypes.APP_TYPE_FOOD;

  const restrictions = disabledDocumentsByCabinetType
    ? [loginRestrictions, passwordRestrictions]
    : [loginRestrictions, passwordRestrictions, documentsRestrictions];

  const handleAnotherUser = useCallback(() => {
    replace('/');
  }, [replace]);

  return (
    <div className={styles.root}>
      <AnotherUserLoggedIn onAnotherUser={handleAnotherUser} />
      <AppBar>
        <LeftBarContent>
          <Logo />
          <AppMenuItems
            pathname={pathname} // TODO внутрь
          />
        </LeftBarContent>
        <RightBarContent>
          <UserMenuLocal />
        </RightBarContent>
      </AppBar>
      <FavoritesBar />
      <div className={styles.body}>
        <Pages />
      </div>
      <AppFooter />
      <Confirmation />
      <Restrictions
        location={location}
        restrictions={restrictions}
      />
      <FailureHandler />
    </div>
  );
};

export default enhanceApp(App);
