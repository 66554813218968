// @flow
import path from 'ramda/es/path';
import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const EDULEVELS_FILTER_REQUEST = 'reports/EDULEVELS_FILTER_REQUEST';
export const EDULEVELS_FILTER_SUCCESS = 'reports/EDULEVELS_FILTER_SUCCESS';
export const EDULEVELS_FILTER_FAILURE = 'reports/EDULEVELS_FILTER_FAILURE';
export const EDULEVELS_FILTER_CLEAR = 'reports/EDULEVELS_FILTER_CLEAR';
export const EDULEVELS_FILTER_DELETE = 'reports/EDULEVELS_FILTER_DELETE';
export const EDULEVELS_FILTER_SET = 'reports/EDULEVELS_FILTER_SET';
export const EDULEVELS_FILTER_UPDATE = 'reports/EDULEVELS_FILTER_UPDATE';

export const educationLevelsFilterFetch = (orgId: number, yearId: number) => async (dispatch: Function) => {
  const params = { school_id: orgId, schoolyear_id: yearId };
  dispatch(action(EDULEVELS_FILTER_REQUEST));
  try {
    const res = await api('reports/klasses_chains', 'get', { params });
    dispatch(action(EDULEVELS_FILTER_SUCCESS, path(['data', 'chains'], res)));
  } catch (error) {
    dispatch(action(EDULEVELS_FILTER_FAILURE, {
      err: 'Не удалось получить данные фильтра по уровням образования',
      error,
    }));
  }
};
