// @flow
import React from 'react';
import cn from 'classnames';

import styles from './ModifyList.scss';


type Item = { id: string, title: string }

type Props = {
  header: string,
  items: Array<Item>,
  className?: string,
}

const ModifyList = ({ header, items, className }: Props) => {
  return (
    <div className={cn(styles.root, className)}>
      <div>
        {header}
        &nbsp;&nbsp;
        <span className={styles.num}>{items.length}</span>
      </div>
      <div className={styles.list}>
        {items.map(({ id, title }) => (
          <div key={id}>{title}</div>
        ))}
      </div>
    </div>
  );
};

export default ModifyList;
