import assoc from 'ramda/es/assoc';
import always from 'ramda/es/always';
import evolve from 'ramda/es/evolve';
import assocPath from 'ramda/es/assocPath';
import map from 'ramda/es/map';

import {
  MENUCATEGORIES_REQUEST,
  MENUCATEGORIES_SUCCESS,
  // MENUCATEGORIES_FAILURE,
  MENUCATEGORIES_CLEAR,
  MENUCATEGORIES_CHANGE,
  MENUCATEGORIES_CHANGE_ALL,
} from 'app/actions/orgs/settings/menucategories';

import {
  MENUCATEGORIES_SAVE_REQUEST,
  MENUCATEGORIES_SAVE_SUCCESS,
  MENUCATEGORIES_SAVE_FAILURE,
} from 'app/actions/orgs/settings/menucategories/save';


const defaultState = {
  loading: true,
  saving: false,
  notSaved: false,
  data: {},
};

const menucategories = (state = defaultState, { type, payload }) => {
  switch (type) {
    case MENUCATEGORIES_REQUEST: {
      return assoc('loading', true, state);
    }

    case MENUCATEGORIES_SUCCESS: {
      return evolve({
        loading: always(false),
        data: always(payload),
        notSaved: always(false),
      }, state);
    }

    case MENUCATEGORIES_CLEAR: {
      return defaultState;
    }

    case MENUCATEGORIES_CHANGE: {
      return evolve({
        data: assocPath([payload.value, 'enabled'], payload.checked),
        notSaved: always(true),
      }, state);
    }

    case MENUCATEGORIES_CHANGE_ALL: {
      return evolve({
        data: map(assoc('enabled', payload.checked)),
        notSaved: always(true),
      }, state);
    }

    case MENUCATEGORIES_SAVE_REQUEST: {
      return assoc('saving', true, state);
    }

    case MENUCATEGORIES_SAVE_SUCCESS: {
      return evolve({
        saving: always(false),
        notSaved: always(false),
      }, state);
    }

    case MENUCATEGORIES_SAVE_FAILURE: {
      return evolve({
        saving: always(false),
        notSaved: always(false),
      }, state);
    }

    default:
  }
  return state;
};

export default menucategories;
