// @flow
import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import path from 'ramda/es/path';

import usePaging from 'app/common/hooks/usePaging';
import { settingsKey } from 'app/reducers/settings/orgsOrders';


export default function useOrgsOrdersPagination() {
  const { currentPage, handlePage, setCurrentPage } = usePaging();
  const limit = useSelector(path(['settings', settingsKey, 'rows']), shallowEqual);
  const offset = (currentPage - 1) * limit;

  const resetPagination = useCallback(() => {
    setCurrentPage(1);
  }, [setCurrentPage]);

  return { limit, offset, currentPage, handlePage, resetPagination };
}
