import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon'
import { rootRights, specificRights } from 'app/rights'
import { WithRightsForOrg } from 'app/rights/RightsForOrg'

import { ValidityByDate } from '../../../../commonComponents/ValidityByDate'
import EditGroceryKit from '../../../EditGroceryKit'
import styles from './HeaderGroceryPrice.scss'


const complexesRigths = [rootRights.COMPLEXES, specificRights.COMPLEXES] as const

type Props = {
  id: number
  orgID: string
  date: string
  groceryPrice: string | null | '—'
  currency: string
}

export const HeaderGroceryPrice = ({ id, orgID, date, groceryPrice, currency }: Props) => {
  return (
    <div className={styles.root}>
      <span className={styles.header}>{'Стоимость продуктового набора:'}</span>

      <div className={styles.price}>
        {groceryPrice ? <span>{groceryPrice}</span> : '—'}
        {groceryPrice && <span>{currency}</span>}
      </div>

      <div className={styles.edit}>
        <WithRightsForOrg pathTo={complexesRigths} editAffect="hide">
          <ValidityByDate affect="hide">
            <EditGroceryKit orgId={parseInt(orgID, 10)} kitId={id} date={date}>
              {toggleNewEditKit => (
                <PlainButtonAsLink
                  ariaLabel="Редактирование названия и стоимости комплекта"
                  onClick={toggleNewEditKit}
                >
                  <PlainIcon
                    width={15}
                    height={15}
                    type={iconTypes.PEN}
                    colorType={colorTypes.COLOR_DARKGRAY}
                  />
                </PlainButtonAsLink>
              )}
            </EditGroceryKit>
          </ValidityByDate>
        </WithRightsForOrg>
      </div>
    </div>
  )
}
