// @flow
import React, { type Node, Children } from 'react';

import TabPanel from './TabPanel';


type Props = {
  children: Node,
  value: number,
  unmountHidden?: boolean,
  className?: string,
}

const TabPanels = ({
  children,
  value,
  className,
  unmountHidden,
}: Props) => (
  <div className={className}>
    {Children.map(children, (comp) => {
      if (!comp) return null;
      const { props: { index, ...others } } = comp;
      const active = (index === value);

      if (!active && unmountHidden) {
        return null;
      }

      return (
        <TabPanel
          {...others}
          active={active}
          index={index}
        />
      );
    })}
  </div>
);

export default TabPanels;
