import {
  DISHES_TYPES_REQUEST,
  DISHES_TYPES_SUCCESS,
  DISHES_TYPES_FAILURE,
} from 'app/actions/dishes/types';


const defaultState = {
  loading: false,
  saleTypes: {},
  result: null,
};

const types = (state = defaultState, action) => {
  switch (action.type) {
    case DISHES_TYPES_REQUEST: {
      return { ...state, loading: true };
    }

    case DISHES_TYPES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case DISHES_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
  }
  return state;
};

export default types;
