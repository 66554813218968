// @flow
import React from 'react';

import type { RenderCellContent } from 'app/common/ui-components/history-modal';

import CellMultiline from './CellMultiline';


const renderFileHistoryCellContent: RenderCellContent = (columnId, values) => {
  if (columnId === 'description') {
    return <CellMultiline lines={values[columnId]} />;
  }
  return values[columnId];
};

export default renderFileHistoryCellContent;
