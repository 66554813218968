// @flow
import React from 'react';

import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog';

import styles from './JPlaceHeader.scss';


type Props = {
  handleDelete: () => Promise<void>,
  deleteAvailable: boolean,
}

const JPlaceHeader = ({ handleDelete, deleteAvailable }: Props) => (
  <div className={styles.root}>
    <div>{'Место работы'}</div>
    {deleteAvailable
      && <div>
        <ConfirmationDialog
          onConfirm={handleDelete}
          text="Вы уверены, что хотите удалить данное место работы?"
          confirmButtonText="Удалить"
          confirmButtonProps={{ error: true }}
        >
          {handleConfirm => (<a href="delete" onClick={handleConfirm} className={styles.deleteLink}>
            {'Удалить'}
          </a>)}
        </ConfirmationDialog>
      </div>}
  </div>
);

export default JPlaceHeader;
