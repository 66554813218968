// @flow
import { paymentOrderMethods } from 'app/dataTypes/requisites';


export const paymentOrderMethodItems = [{
  id: paymentOrderMethods.SberOnline,
  title: 'Он-лайн Сбербанк',
}, {
  id: paymentOrderMethods.SberOffline,
  title: 'Офф-лайн Сбербанк',
}, {
  id: paymentOrderMethods.Offline,
  title: 'Офф-лайн',
}];
