import { dropLast, last, pipe, reduce, reduced, split } from 'ramda'


export const createPath = (props: {}, mask: string) => {
  return pipe(
    split(':'),
    reduce((acc, val) => {
      if (!val) return acc
      const pathItems = val.split('/')
      const key = pathItems[0]
      if (key && props[key]) {
        pathItems[0] = props[key]
        return acc + pathItems.join('/')
      }
      if (key && !props[key]) {
        return reduced(last(acc) === '/' ? dropLast(1, acc) : acc)
      }
      return acc + val
    }, ''),
  )(mask)
}
