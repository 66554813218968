// @flow
import type { Node } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import toLower from 'ramda/es/toLower';

import { maskTypes } from './mask-types';


const maskTypeByIsoCode: {
  [string]: $Values<typeof maskTypes>,
} = {
  ru: maskTypes.MASK_PHONE_RU,
  kg: maskTypes.MASK_PHONE_KG,
  kz: maskTypes.MASK_PHONE_KZ,
  ua: maskTypes.MASK_PHONE_UA,
};

type Props = {
  children: (predefinedMaskType: $Values<typeof maskTypes>) => Node,
}

const DealerPhoneMask = ({ children }: Props): Node => {
  const { country_iso_code: isoCode = 'ru' } = useSelector(state => state.dealer, shallowEqual);
  const iso = toLower(isoCode);
  const maskType: $Values<typeof maskTypes> = maskTypeByIsoCode[iso] || maskTypes.MASK_PHONE_RU;

  return (
    children(maskType)
  );
};

export default DealerPhoneMask;
