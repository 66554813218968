// @flow
import React, { type Node } from 'react';
import classNames from 'classnames/bind';

import SortArrowControl from './sort-arrow-control';
import styles from './th.scss';


/**
 * UI COMPONENTS v2
 *
 * Строка таблицы
 * ------
 * Семантически верная таблица. Строка таблицы (tr)
 */

const cx = classNames.bind(styles);

export type ThProps = {
  children: Node,
  className?: string,
  contentClassName?: string,
  stripStyles?: boolean,
  stripPadding?: boolean,
  alignLeft?: boolean,
  alignCenter?: boolean,
  alignRight?: boolean,
  colSpan?: number,
  rowSpan?: number,
  id?: string,
  hidden?: boolean,
  sortKey?: string,
  sortDirection?: 'up' | 'down',
  scope?: 'col' | 'row' | 'colgroup' | 'rowgroup',
  headers?: string,
  sortDummy?: boolean,
  style?: { [key: string]: string },
  borderBottom?: boolean,
  borderShadowBottom?: boolean,
  borderTopCompensate?: boolean,
  borderLeft?: boolean,
  borderRight?: boolean,
  bgFilled?: boolean,
  ignoreFirstChild?: boolean,
  ignoreLastChild?: boolean,
  onSortArrowClick?: (direction: 'up' | 'down', key: string) => void,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
}

const Th = ({
  children,
  className,
  contentClassName,
  stripStyles,
  stripPadding,
  alignLeft,
  alignCenter,
  alignRight,
  colSpan,
  rowSpan,
  id,
  hidden,
  sortKey,
  sortDirection,
  scope,
  headers,
  sortDummy,
  style,
  borderBottom,
  borderShadowBottom,
  borderTopCompensate,
  borderLeft,
  borderRight,
  bgFilled,
  ignoreFirstChild,
  ignoreLastChild,
  onSortArrowClick,
  onMouseEnter,
  onMouseLeave,
}: ThProps) => {
  const rootClassName = cx({
    root: !stripStyles,
    rootPadding: !stripStyles && !stripPadding,
    alignLeft,
    alignCenter,
    alignRight,
    hidden,
    borderBottom,
    borderShadowBottom,
    borderTopCompensate,
    borderLeft,
    borderRight,
    bgFilled,
    ignoreFirstChild,
    ignoreLastChild,
  }, className);

  const noneSortableClassName = cx({ sortDummy }, contentClassName);
  const sortableClassName = cx(styles.content, contentClassName);

  if (hidden) {
    return null;
  }

  const props = {
    id,
    colSpan,
    rowSpan,
    onMouseEnter,
    onMouseLeave,
    scope,
    headers,
    style,
  };

  return (
    <th className={rootClassName} {...props}>
      {sortKey && !sortDummy
        ? <div className={sortableClassName}>
          {children}
          <SortArrowControl
            sortKey={sortKey}
            sortDirection={sortDirection}
            onClick={onSortArrowClick}
          />
        </div>
        : <div className={noneSortableClassName}>
          {children}
        </div>}
    </th>
  );
};

export default Th;
