// @flow
import type { Request } from '../../../index';
import type { Machine } from './get';


export type Result = Machine

export type Params = {
  title: string,
  number: string,
  model_id: number,
  version_id: number,
  school_id: number,
  deleted?: boolean,
}

type UrlParams = {
  machineId: number | string,
}

export const PUT_VENDING_MACHINES: Request<Params, Machine, UrlParams> = [
  'data/vending/machines/{machineId}',
  'put',
  ['data', 'data'],
];
