// import React from 'react';
// import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// import Message from 'app/components/_ui/Message';
// import { logout as logoutAction } from 'app/common/actions/user';
import Documents from 'app/common/components/Documents';

// import isProduction from 'app/common/lib/isProduction';


// тест почты ПЕРЕНЕСЕН из ЛК родителей без изменений

// const enhance = connect(null, dispatch => ({
//   logout: (e) => {
//     if (e && typeof e.preventDefault === 'function') {
//       e.preventDefault();
//     }
//     dispatch(logoutAction());
//   },
// }));


// /**
// /* Обязательный e-mail
// */

// export const RsnEmail = enhance(({ logout }) =>
//   <Message
//     text={'Для того чтобы продолжить работу,\nнеобходимо указать e-mail в вашем профиле'}
//     buttons={[
//       {
//         href: '',
//         handleClick: logout,
//         value: 'Выйти',
//         outline: true,
//         key: 'logout',
//       }, {
//         href: '/profile/change/email0',
//         value: 'Указать e-mail',
//         key: 'change',
//       },
//     ]}
//   />
// );

// export const isRsnEmail = createSelector(
//   [
//     state => state.user,
//     (_, props) => props.location.pathname,
//   ],
//   (user, pathname) => (
//     !!(user.result === 'success' && !user.email && user.code_to_mail)
//     && (pathname !== '/profile/change/email' && pathname !== '/profile/change/email0')

//     // && !isProduction()
//   )
// );


/**
/* Принятие договоров оферты и обработки личных данных
*/

export const RsnDocument = Documents;

export const isRsnDocument = createSelector(
  [
    state => state.documents,
    state => (state.documents.result === 'success' && state.user.result === 'success'),
    state => !!state.user.student,
  ],
  (documents, isLoadSuccess, isStudent) => (
    (isLoadSuccess && !isStudent)
      ? !!Object.keys(documents).find(key => !documents[key].accepted)
      : false
  ),
);
