// @flow
import { values } from 'ramda';

import { getStateItemsAndCurrent, getStateIsFilterActive, type State } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const filterActive = (state: State): boolean => {
  return getStateIsFilterActive(filterId, state);
};

export const singleOrgSelected = (state: State): boolean => {
  const [orgsIdsTotal, orgIdsSelected] = getStateItemsAndCurrent(filterId, state);
  return orgIdsSelected.length === 1 || values(orgsIdsTotal).length === 1;
};

export const multipleOrgsSelected = (state: State): boolean => {
  const [orgsIdsTotal, orgIdsSelected] = getStateItemsAndCurrent(filterId, state);
  return values(orgsIdsTotal).length > 1 && orgIdsSelected.length !== 1;
};
