// @flow
import { useMemo } from 'react';
import { map, filter, pipe } from 'ramda';

import type { Dish } from 'app/dataTypes/vending/production/Dishes.types';

import { cells } from './useHeaderCellsData';
import { type ColumnVisibility } from './columns';


export type RowCell = {
  key: string,
  value: string,
  alignLeft?: boolean,
  alignRight?: boolean,
  alignCenter?: boolean,
  className?: string,
  extra?: Dish
}

const createRow = (categoryTitlesMapById: Map<string, string>) => (dish: Dish): Array<RowCell> => {
  const { id, title, photoURL, portion, cost, saleType, categoryId } = dish;

  return ([{
    key: cells[0].key,
    value: id,
    alignLeft: true,
  }, {
    key: cells[1].key,
    value: title,
    alignLeft: true,
  }, {
    key: cells[2].key,
    value: photoURL || '',
    alignCenter: true,
  }, {
    key: cells[3].key,
    value: portion || '',
    alignCenter: true,
  }, {
    key: cells[4].key,
    value: cost || '',
    alignCenter: true,
  }, {
    key: cells[5].key,
    value: saleType || '',
    alignLeft: true,
  }, {
    key: cells[6].key,
    value: categoryTitlesMapById.get(categoryId) || '',
    alignLeft: true,
  }, {
    key: cells[7].key,
    value: id,
    extra: dish,
    alignRight: true,
  }]);
};

const updateRowBySettings = (columnSettings: ColumnVisibility) => (
  filter(({ key }) => (columnSettings[key] || columnSettings[key] === undefined))
);

export default function useRowCellsData(
  dishes: Array<Dish>,
  columnSettings: ColumnVisibility,
  categoryTitlesMapById: Map<string, string>,
): Array<Array<RowCell>> {
  const res = useMemo(() => {
    return map(pipe(
      createRow(categoryTitlesMapById),
      updateRowBySettings(columnSettings),
    ), dishes);
  }, [dishes, columnSettings, categoryTitlesMapById]);

  return res;
}
