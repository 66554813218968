import React from 'react';

import Cell from 'app/components/ui/Cell';
import { useCurrencySign } from 'app/common/components-next/currency';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import { HeaderCell, HeaderRow } from 'app/common/components/Table';

import styles from './DishesKitTableHeader.scss';


type Props = {
  showCheckbox?: boolean,
  checked?: boolean,
  onChangeSelectAll?: (e: SyntheticEvent<HTMLInputElement>) => void,
}

const DishesKitTableHeader = ({ showCheckbox, checked, onChangeSelectAll }: Props) => {
  const currency = useCurrencySign();

  return (
    <HeaderRow className={styles.root}>
      <HeaderCell className={styles.cell} grow={0} width={50}>
        {showCheckbox && onChangeSelectAll
          ? <PlainCheckbox
            label="Выбрать все"
            className={styles.check}
            value="checkAll"
            onChange={onChangeSelectAll}
            checked={checked}
            hideLabel
          />
          : <span>&nbsp;</span>}
      </HeaderCell>
      <HeaderCell className={styles.cell} grow={3}>
        {'Наименование'}
      </HeaderCell>
      <HeaderCell grow={8} className={styles.cellContainer}>
        <Cell className={styles.cell} grow={2}>
          {'Блюда'}
        </Cell>
        <Cell className={styles.cell} grow={1} alignRight>
          {'Порция'}
        </Cell>
        <Cell className={styles.cell} grow={1} alignRight>
          {`Цена, ${currency}`}
        </Cell>
      </HeaderCell>
      <HeaderCell className={styles.cell} grow={2} alignRight>
        {`Цена комплекта, ${currency}`}
      </HeaderCell>
    </HeaderRow>
  );
};

export default DishesKitTableHeader;
