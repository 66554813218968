import moment from 'moment'

import { months } from 'app/i18n'
import { DataBox, createDataBox, createDataBoxDefault } from 'app/common/data-box-store'
import { ItemType } from 'app/common/ui-next/plain-selector'


export type Month = Array<ItemType>


class MonthFilterDataBox extends DataBox<Month> { }

export const defaultMonth: Month = []
export const monthFilter = createDataBox('monthFilter', defaultMonth, MonthFilterDataBox)

export const defaultMonthData = (): Array<ItemType> => {
  return months.ru.map((month, index) => ({ id: (index + 1).toString(), title: month }))
}

export const getPrevMonthId = (): string[] => {
  const prevMonthIndex = moment().subtract(1, 'months').month()
  const curMonth = months.ru[prevMonthIndex]
  const curId = defaultMonthData().find((filteredItem) => {
    if (filteredItem.title === curMonth) {
      return filteredItem.id
    }
    return false
  })
  return [curId?.id ?? '0']
}

// export const defaultMonthDataReceiverAbort = getOrgsApi.abort

// selection

export const defaultMonthSelection: Array<string> = getPrevMonthId()

export const monthFilterSelection = createDataBoxDefault<Array<string>>('monthFilterSelection', defaultMonthSelection)
