// @flow
import React from 'react';

import OveflowedText from 'app/common/components/OveflowedText';
import Link from 'app/common/lib/link';

import styles from './CellContentFIO.scss';


type Props = {
  className?: string,
  linkTo: string,
  nameLastFirst: string,
  nameMiddle: string,
  birthdate: ?string,
}

const CellContentFIO = ({ className, linkTo, nameLastFirst, nameMiddle, birthdate }: Props) => (
  <div className={className}>
    <Link
      to={linkTo}
      className={styles.link}
    >
      <OveflowedText>
        {nameLastFirst}
      </OveflowedText>
      <OveflowedText>
        {nameMiddle}
      </OveflowedText>
    </Link>

    <div className={styles.birthday}>
      {birthdate}
    </div>
  </div>
);


export default CellContentFIO;
