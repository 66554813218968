// @flow
import React, { Fragment, type ElementRef } from 'react';
import classNames from 'classnames/bind';

import { Table, Row, Cell, HeaderRowSortable, HeaderCell } from 'app/common/components/Table';
import Paging from 'app/common/ui/Paging';
import type { SortHandle, SortState } from 'app/common/containers/withSortState';
import conglomerationTableSizes from 'app/common/containers/conglomerationTableSizes';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';

import enhanceGroups from './enhanceGroups';
import type { GroupType } from './selectActual';
import ts from '../Table.scss';
import styles from './Groups.scss';


const cx = classNames.bind(ts);

type Settings = {
  klassTitle: boolean,
  customerType: boolean,
  count: boolean,
  pos: boolean,
  neg: boolean,
  negPercents: boolean,
  total: boolean,
}

type Props = {
  handlePage: (e: SyntheticEvent<HTMLElement>) => void,
  refTd: (el: ElementRef<'td'> | null) => void,
  groups: Array<GroupType>,
  currentPage: number,
  total: number,
  rows: number,
  sort: SortState,
  handleSort: SortHandle,
  settings: Settings,
  sizes: Object,
}

const Groups = ({
  handlePage,
  refTd,
  groups,
  currentPage,
  total,
  rows,
  sort,
  handleSort,
  settings,
  sizes,
}: Props) => {
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);

  return (
    <Fragment>
      <DetailsHeader reportTitle="Остаток средств" />

      {/* ЗАГОЛОВОК */}
      <CSSSticky
        number={2}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <HeaderRowSortable
            className={ts.header}
            onSort={handleSort}
            currentSortKey={sort.sortKey}
            currentSortDirection={sort.sortDirection}
          >
            {settings.klassTitle
              && <HeaderCell
                className={ts.cell}
                width={sizes.klassTitle}
                key="klassTitle"
                sortKey="klassTitle"
              >
                {'Класс'}
              </HeaderCell>}
            {settings.customerType
              && <HeaderCell
                className={ts.cell}
                width={sizes.customerType}
                key="customerType"
                sortKey="customerType"
              >
                {'Тип покупателя'}
              </HeaderCell>}
            {settings.count
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.count}
                key="count"
                sortKey="count"
              >
                {'Кол-во человек'}
              </HeaderCell>}
            {settings.pos
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.pos}
                key="pos"
                sortKey="pos"
              >
                {`Положительный баланс${currency}`}
              </HeaderCell>}
            {settings.neg
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.neg}
                key="neg"
                sortKey="neg"
              >
                {`Отрицательный баланс${currency}`}
              </HeaderCell>}
            {settings.negPercents
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.negPercents}
                key="negPercents"
                sortKey="negPercents"
              >
                {'%\u00A0с\u00A0отриц. балансом'}
              </HeaderCell>}
            {settings.total
              && <HeaderCell
                className={ts.cell}
                alignRight
                width={sizes.total}
                key="total"
                sortKey="total"
              >
                {`ИТОГО${currency}`}
              </HeaderCell>}
          </HeaderRowSortable>
          {/* </Sticky> */}
        </Table>
      </CSSSticky>
      <Table className={styles.table}>
        {groups.map(({
          id,
          klassTitle,
          customerType,
          count,
          pos,
          neg,
          negHighlight,
          negPercents,
          total: balance,
        }) => (
          <Row key={id}>
            {settings.klassTitle
              && <Cell className={ts.cell} width={sizes.klassTitle}>
                {klassTitle}
              </Cell>}
            {settings.customerType
              && <Cell className={ts.cell} width={sizes.customerType}>
                {customerType}
              </Cell>}
            {settings.count
              && <Cell className={ts.cell} alignRight width={sizes.count}>
                {count}
              </Cell>}
            {settings.pos
              && <Cell className={ts.cell} alignRight width={sizes.pos}>
                {pos}
              </Cell>}
            {settings.neg
              && <Cell className={ts.cell} alignRight width={sizes.neg}>
                <span className={cx({ highlight: negHighlight })}>{neg}</span>
              </Cell>}
            {settings.negPercents
              && <Cell className={ts.cell} alignRight width={sizes.negPercents}>
                {negPercents}
              </Cell>}
            {settings.total
              && <Cell className={ts.cell} alignRight width={sizes.total}>
                {balance}
              </Cell>}
          </Row>))}
      </Table>
      {(total > rows)
        && <Paging
          className={ts.pager}
          total={total}
          pageSize={rows}
          currentPage={currentPage}
          currentPageSize={groups.length}
          onPage={handlePage}
        />}

      {/* Скрытая таблица для расчета ширины колонок */}
      <table className={ts.hiddenTable}>
        <thead>
          <tr>
            <td className={ts.cell} ref={refTd} name="klassTitle">
              {'Класс'}
            </td>
            <td className={ts.cell} ref={refTd} name="customerType">
              {'Тип покупателя'}
            </td>
            <td className={ts.cell} ref={refTd} name="count">
              {'Кол-во человек'}
            </td>
            <td className={ts.cell} ref={refTd} name="pos">
              {`Положительный баланс${currency}`}
            </td>
            <td className={ts.cell} ref={refTd} name="neg">
              {`Отрицательный баланс${currency}`}
            </td>
            <td className={ts.cell} ref={refTd} name="negPercents">
              {'%\u00A0с\u00A0отриц. балансом'}
            </td>
            <td className={ts.cell} ref={refTd} name="total">
              {`ИТОГО${currency}`}
            </td>
          </tr>
        </thead>
        <tbody>
          {groups.map(({
            id,
            klassTitle,
            customerType,
            count,
            pos,
            neg,
            negPercents,
            total: balance,
          }) => (
            <tr key={id}>
              <td className={ts.cell}>{klassTitle}</td>
              <td className={ts.cell}>{customerType}</td>
              <td className={ts.cell}>{count}</td>
              <td className={ts.cell}>{pos}</td>
              <td className={ts.cell}>{neg}</td>
              <td className={ts.cell}>{negPercents}</td>
              <td className={ts.cell}>{balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default enhanceGroups(conglomerationTableSizes(Groups));
