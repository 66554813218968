// @flow
import React, { type Ref, useCallback } from 'react';

import { Tr, Th } from 'app/common/ui-components/table';
import { useCurrencySign, options as co } from 'app/common/components-next/currency';

import { cellProps, getTitles } from './columns';

/**
 *  Заголовочная строка таблицы
 */

const getHeaderCellProps = (dummy?: boolean) => (id: string) => {
  return dummy ? {} : {
    id: `header_${id}`,
  };
};

type Props = {
  dummy?: boolean,
  reference?: Ref<'tr'>,
  hidden?: boolean,
  sortKey?: string,
  sortDirection?: 'up' | 'down',
  onSort?: (direction: "up" | "down", key: string) => void,
  columns: Array<string>,
  sortable?: boolean,
}

const ItemsHeaderRow = ({
  dummy,
  reference,
  hidden,
  sortKey,
  sortDirection,
  onSort,
  columns,
  sortable,
}: Props) => {
  const idProps = getHeaderCellProps(dummy);
  const currency = useCurrencySign(co.COMMA_SPACE_BEFORE);
  const titles = getTitles(currency);

  const sortProps = useCallback((key: string) => {
    if (sortable && sortKey) {
      return ({
        sortKey: key,
        sortDirection: sortKey === key ? sortDirection : undefined,
        onSortArrowClick: onSort,
        sortDummy: dummy,
      });
    }
    if (sortable && !sortKey) {
      return ({
        sortDummy: dummy,
      });
    }
    return ({});
  }, [sortKey, sortDirection, onSort, dummy, sortable]);

  return (
    <Tr
      reference={reference}
      hidden={hidden}
      header
    >
      {columns.map(key => (
        <Th
          scope="col"
          key={key}
          {...sortProps(key)}
          {...cellProps[key]}
          {...idProps('title')}
        >
          {titles[key]}
        </Th>
      ))}
    </Tr>
  );
};

export default ItemsHeaderRow;
