import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

import isProduction from 'app/common/lib/isProduction';

import createRootReducer from './reducers';
import history from './history';


function configureStore() {
  if (isProduction()) {
    return createStore(createRootReducer(history), applyMiddleware(thunk));
  }

  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
  /* eslint-enable */
}

export default configureStore();
