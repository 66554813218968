// @flow
import React from 'react';
import classNames from 'classnames/bind';
import mapProps from 'recompose/mapProps';

import Link from 'app/common/lib/link';
import IconDoubleMan from 'app/common/icons/IconDoubleMan';
import IconPerson from 'app/common/icons/IconPerson';
import { Row, Cell } from 'app/common/components/Table';
import * as statuses from 'app/common/constants/students/subsidies/statuses';
import nope from 'app/common/lib/nope';
import ConfirmationDialog from 'app/common/components/Confirmation/ConfirmationDialog';

import styles from './SubsidiesSheet.scss';


const cx = classNames.bind(styles);

// ссылка с возможностью блокировки
const DLink = mapProps(({ disabled, onClick, ...others }) => ({
  ...others,
  onClick: disabled ? nope : onClick,
  className: cx(styles.link, { disabledLink: disabled }),
}))(Link);

type Options = {
  forSingleSales: boolean,
  forKlassOrders: boolean,
  forDayCare: boolean,
  includeSaturday: boolean,
}

export type FoodOrderProps = {
  id: number,
  status: $Values<typeof statuses>,
  number: string,
  to: string,
  from: string,
  options: Options,
  description: string,
  total: string,
  calculated: boolean,
  stoppable: boolean,
  deletable: boolean,
}

type Props = {
  ...$Exact<FoodOrderProps>,
  options: Options,
  status: $Values<typeof statuses>,
  processing?: boolean,
  handleStopFoodorder?: () => void,
  handleDeleteFoodorder?: () => void,
}

const SheetsItem = ({
  status,
  number,
  to,
  from,
  options: {
    forSingleSales,
    forKlassOrders,
    forDayCare,
    includeSaturday,
  },
  description,
  total,
  calculated,
  stoppable,
  deletable,
  processing,
  handleStopFoodorder,
  handleDeleteFoodorder,
}: Props) => (
  <div className={cx(styles.item, { processing })}>
    <div className={styles.itemPre}>
      {/* $FlowFixMe */}
      <div className={cx(styles.itemStatus, { [status]: true })} />
    </div>
    {/* $FlowFixMe */}
    <Row className={cx(styles.itemContent, { [status]: true })}>
      <Cell grow={5} className={styles.numberCell}>
        {number}
      </Cell>

      <Cell grow={4} className={styles.dateCell}>
        <div>{from}</div>
        <div>{to}</div>
      </Cell>

      <Cell grow={5} className={styles.optionsCell}>
        {forSingleSales && <IconPerson />}
        {forKlassOrders && <IconDoubleMan />}
        {includeSaturday && ' Сбт'}
        {forDayCare && ' ГПД'}
      </Cell>

      <Cell grow={9} className={styles.descriptionCell}>
        {description}
      </Cell>

      <Cell grow={3} className={styles.totalCell}>
        {total}
      </Cell>
    </Row>
    <div className={styles.itemPost}>
      {!calculated && <div>{'В процессе...'}</div>}
      {status === statuses.ACTIVE
          && handleStopFoodorder
          && stoppable
          && <ConfirmationDialog
            onConfirm={handleStopFoodorder}
            text="Приостановить действие справки? Отменить это действие будет невозможно"
            confirmButtonText="Приостановить"
          >
            {handleConfirm => (<DLink
              to="stop"
              disabled={processing}
              onClick={handleConfirm}
            >
              {'Приостановить'}
            </DLink>)}
          </ConfirmationDialog>}

      {status === statuses.ACTIVE && <br />}
      {(status === statuses.ACTIVE || status === statuses.PENDING)
          && handleDeleteFoodorder
          && deletable
          && <ConfirmationDialog
            onConfirm={handleDeleteFoodorder}
            text="Удалить справку? Отменить это действие будет невозможно"
            confirmButtonText="Удалить"
          >
            {handleConfirm => (<DLink
              to="delete"
              disabled={processing}
              onClick={handleConfirm}
            >
              {'Удалить'}
            </DLink>)}
          </ConfirmationDialog>}
    </div>
  </div>
);

export default SheetsItem;
