import { Fragment, useCallback, ChangeEvent } from 'react'
import append from 'ramda/es/append'
import filter from 'ramda/es/filter'

import PlainCheckbox from 'app/common/ui-next/plain-checkbox'
import useOrgAllowances from 'app/dataProviders/subsidy/allowances/hooks/useOrgAllowances'

import Shift from './Shift'
import styles from './RestrictionsAllowances.scss'


type Props = {
  label: string
  value: string
  orgID: string
  allowances: boolean
  allowancesChecked: Array<string>
  disabled: boolean
  onChangeRoot: (key: string, checked: boolean) => void
  onUpdateAllowance: (updateFunc: (a: Array<string>) => Array<string>) => void
}

const RestrictionsAllowances = ({
  label,
  value,
  orgID,
  allowances,
  allowancesChecked,
  disabled,
  onChangeRoot,
  onUpdateAllowance,
}: Props) => {
  const [allowancesAvailable, loadingAllowances] = useOrgAllowances({
    orgID,
    app: 'cafeteria',
    used: true,
  })

  const [allowancesCampAvailable, loadingAllowancesCamp] = useOrgAllowances({
    orgID,
    app: 'camp',
    used: true,
    subsidyType: 'personal',
  })

  const loading = loadingAllowances || loadingAllowancesCamp

  const handleChangeRoot = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget
    onChangeRoot(value, checked)
  }, [onChangeRoot, value])

  const handleUpdateAllowances = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value: v, checked } = e.currentTarget
    onUpdateAllowance(checked ? append(v) : filter(key => (key !== v)))
  }, [onUpdateAllowance])

  return (
    <Fragment>
      <PlainCheckbox
        value={value}
        id={value}
        label={label}
        disabled={loading || disabled}
        checked={allowances}
        onChange={handleChangeRoot}
      />

      {allowances
        && (
          <Shift>
            <div className={styles.label}>
              {'Категории льгот для школьного питания'}
            </div>
            {allowancesAvailable.map(({ ID, title }) => (
              <PlainCheckbox
                key={ID}
                value={ID}
                id={ID}
                label={title}
                disabled={loading || disabled}
                checked={allowancesChecked.includes(ID)}
                onChange={handleUpdateAllowances}
              />
            ))}
            <div className={styles.label}>
              {'Категории льгот для питания в лагере'}
            </div>
            {allowancesCampAvailable.map(({ ID, title }) => (
              <PlainCheckbox
                key={ID}
                value={ID}
                id={ID}
                label={title}
                disabled={loading || disabled}
                checked={allowancesChecked.includes(ID)}
                onChange={handleUpdateAllowances}
              />
            ))}
          </Shift>
        )}
    </Fragment>
  )
}

export default RestrictionsAllowances
