// @flow
import React from 'react';

import { MessageOk } from 'app/common/ui/Message';
import { checkEmployeeStatuses } from 'app/common/api/requests/food/check_employee_exists';
import PlainLink from 'app/common/ui-next/plain-link';
import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';


type Props = {
  id?: number,
  status: $Values<typeof checkEmployeeStatuses>,
}

const UserExist = ({ id, status }: Props) => {
  if (status === checkEmployeeStatuses.EMPLOYEE_THIS_SUPPLIER) {
    return (
      <span>
        {'Этот сотрудник уже есть в базе текущего поставщика питания. '}
        <PlainLink to={`/settings/employees/current/employee/${id || 'none'}`}>
          {'Посмотреть подробнее'}
        </PlainLink>
      </span>
    );
  }
  if (status === checkEmployeeStatuses.EMPLOYEE_OTHER_SUPPLIER) {
    return (
      <MessageOk
        text={'Данный раздел находится в стадии разработки.\nИзвините\nза предоставленные неудобства!'}
      >
        {handleClick => (<span>
          {'Этот сотрудник уже есть в базе другого поставщика питания. '}
          <PlainButtonAsLink onClick={handleClick}>
            {'Предложить работу по совместительству'}
          </PlainButtonAsLink>
        </span>)}
      </MessageOk>
    );
  }
  return null;
};

export default UserExist;
