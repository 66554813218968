import { forwardRef, ReactNode } from 'react'
import cn from 'classnames'

import styles from './h-scroll-container.scss'


type Props = {
  children: ReactNode,
  className?: string,
}

const HScrollContainer = forwardRef<HTMLDivElement, Props>(({ children, className }, ref) => {
  return (
    <div className={cn(styles.root, className)} ref={ref}>
      {children}
    </div>
  )
})

export default HScrollContainer
