import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';


export default (toggleValueName, handleName) => compose(
  withState(
    toggleValueName || 'isOpen',
    'setToggleValue',
    props => (toggleValueName
      ? (props[toggleValueName] || false)
      : (props.isOpen || false)
    ),
  ),
  withHandlers({
    [handleName || 'handleToggle']: ({ setToggleValue, externalToggle }) => (e) => {
      if (e && typeof e.preventDefault === 'function') e.preventDefault();
      if (externalToggle) {
        externalToggle();
        return;
      }
      setToggleValue(prev => !prev);
    },
  }),
);
