// @flow
import React from 'react';

import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';

import styles from './CellActions.scss';


const CellActions = () => {
  return (
    <div className={styles.root}>
      <PlainIcon
        className={styles.icon}
        type={iconTypes.GEAR_LIGHT}
        colorType={colorTypes.COLOR_DARKGRAY}
        width={20}
        height={20}
      />
    </div>
  );
};

export default CellActions;
