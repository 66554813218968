// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import path from 'ramda/es/path';

import { api } from 'app/api';
import { GET_ITEM_CATEGORIES } from 'app/common/api/requests/food/schools/item_categories';

import {
  PUT_ITEM_CATEGORY,
  POST_ITEM_CATEGORY,
  DELETE_ITEM_CATEGORY,
} from 'app/common/api/requests/food/menu/item_category';

import { makeMapFunc } from './api2CategoryList';
import useCategoriesState from './useCategoriesState';
import type { CategoryListItem } from './CategoryListItem.type';


type State = {
  categoryList: Array<CategoryListItem>,
  loading: boolean,
}

type Handlers = {
  request: () => Promise<void>,
  moveItem: (number, number) => Promise<void>,
  createCategory: (string, (string) => void) => Promise<boolean>,
  deleteCategory: (string) => Promise<boolean>,
}

type Options = {
  orgId: number,
  pathTemplate: string
}

export default function useCategories({
  orgId,
  pathTemplate,
}: Options): [State, Handlers] {
  const [loading, setLoading] = useState(true);


  // отправка нового индекса для итема
  const submitNewPosition = useCallback(async (categoryId: number, index: number) => {
    const res = await api.request(PUT_ITEM_CATEGORY, {
      // error: 'Не удалось сохранить новую позицию категории',
      params: {
        school_id: orgId,
        category_id: categoryId,
        index,
      },
    });
    if (res) {
      return true;
    }
    return false;
  }, [orgId]);

  const api2CategoryListItem = useMemo(() => makeMapFunc(pathTemplate), [pathTemplate]);


  // CATEGORY STATE
  const [
    { categoryList },
    { initCategories, moveItem, addCategories, deleteCategory },
  ] = useCategoriesState({
    api2CategoryListItem,
    onItemUpdateIndex: submitNewPosition,
  });


  // запрос и инициализация состояния категорий
  const handleRequest = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_ITEM_CATEGORIES, {
      error: 'Не удалось получить категории',
      requestPathParams: {
        orgId,
      },
    });

    if (res) {
      initCategories(res);
    }
    setLoading(false);
  }, [orgId, initCategories]);

  useEffect(() => {
    handleRequest();
  }, [orgId]); // eslint-disable-line react-hooks/exhaustive-deps


  // handle для перемещения с сохранением и восстановлением
  const handleMoveItem = useCallback(async (indexFrom: number, indexTo: number) => {
    setLoading(true);
    await moveItem(indexFrom, indexTo);
    setLoading(false);
  }, [
    moveItem,
  ]);


  // создание категории
  const handleCreateCategory = useCallback(async (newCategoryName: string, setError: (string) => void) => {
    const res = await api.request(POST_ITEM_CATEGORY, {
      error: (error: Error) => {
        const responseStatus = path(['response', 'status'], error);
        if (responseStatus === 422) {
          const errorText = 'Такая категория уже существует';
          setError(errorText);
          return errorText;
        }
        const errorText = 'Не удалось cоздать категорию';
        setError(errorText);
        return errorText;
      },
      params: {
        school_id: orgId,
        title: newCategoryName,
        index: 0,
      },
    });

    if (res) {
      addCategories(res);
      return true;
    }
    return false;
  }, [orgId, addCategories]);


  // удаление категории
  const handleDeleteCategory = useCallback(async (categoryId: string) => {
    const res = await api.request(DELETE_ITEM_CATEGORY, {
      error: 'Не удалось удалить категорию',
      params: {
        school_id: orgId,
        category_id: parseInt(categoryId, 10),
      },
    });

    if (res) {
      deleteCategory(categoryId);
      return true;
    }
    return false;
  }, [orgId, deleteCategory]);


  return [{
    categoryList,
    loading,
  }, {
    request: handleRequest,
    moveItem: handleMoveItem,
    createCategory: handleCreateCategory,
    deleteCategory: handleDeleteCategory,
  }];
}
