// @flow
import React from 'react';

import { FilterDataProvider } from 'app/common/ui-components/filter';

import EmployeesOfficeMain, { type Props } from './EmployeesOfficeMain';
import filterItems from './EmployeesOfficeFilter/filterItems';


const EmployeesOffice = (props: Props) => {
  return (
    <FilterDataProvider filters={filterItems}>
      <EmployeesOfficeMain {...props} />
    </FilterDataProvider>
  );
};

export default EmployeesOffice;
