import { combineReducers } from 'redux';

// import general from './general';
// import limits from './limits';
import menucategories from './menucategories';
import sellpoints from './sellpoints';

export default combineReducers({
  // general,
  // limits,
  menucategories,
  sellpoints,
});
