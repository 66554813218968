// @flow
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Button from 'app/common/ui/Button';
import LineInput from 'app/common/login/ui/LineInput';
import Modal from 'app/common/components/Modal';

import enhanceEmailChange from './enhanceEmailChange';
import styles from './EmailChangeMaterial.scss';


type Props = {
  requesting: boolean,
  saving: boolean,
  requestCodeError: string,
  codeError: string,
  requestCode: string,
  code: string,
  request: (e: SyntheticEvent<HTMLElement>) => void,
  submit: (e: SyntheticEvent<HTMLElement>) => void,
  close: (e: SyntheticEvent<HTMLElement>) => void,
  change: (e: SyntheticEvent<HTMLInputElement>) => void,
  resultTo: string,
  requestLocked: number,
  email: string,
};

const EmailChange = ({
  requesting,
  saving,
  requestCodeError,
  codeError,
  requestCode,
  code,
  request,
  submit,
  close,
  change,
  resultTo,
  requestLocked,
  email,
}: Props) => {
  const { t } = useTranslation('profile');

  return (
    <Modal close={close}>
      <div className={styles.root}>
        <h2 className={styles.header}>
          {email
            ? <Trans i18nKey="profile:email_modal_header">
              {'Смена адреса электронной почты'}
            </Trans>
            : <Trans i18nKey="profile:email_modal_header_new">
              {'Добавление адреса электронной почты'}
            </Trans>}
        </h2>
        <div className={styles.content}>
          <form onSubmit={request}>
            <div className={styles.input}>
              <LineInput
                placeholder={email
                  ? t('email_modal_input_label_new')
                  : t('email_modal_input_label')}
                name="requestcode"
                value={requestCode}
                onChange={change}
                isError={!!requestCodeError}
                errorText={requestCodeError}
              />
            </div>
            <div className={styles.timer}>
              {!!requestLocked && t('receive_code_locked_bt', { requestLocked })}
            </div>
            <div className={styles.buttons}>
              <Button
                submit
                value={requestLocked
                  ? t('email_modal_code_was_sent')
                  : t('receive_code_bt')}
                disabled={requesting || !requestCode || !!requestLocked}
              />
            </div>
          </form>
          <form onSubmit={submit}>
            <div className={styles.label}>
              <Trans i18nKey="profile:email_modal_code_invite">
                {'Получите код и введите его в форму ниже'}
              </Trans>
            </div>
            <div className={styles.resultTo}>
              {resultTo}
            </div>
            <div className={styles.input}>
              <LineInput
                placeholder={t('email_modal_enter_the_code_label')}
                name="code"
                value={code}
                onChange={change}
                isError={!!codeError}
                errorText={codeError}
              />
            </div>
            <div className={styles.buttons}>
              <Button
                submit
                value={email ? t('submit_change_bt') : t('submit_add_bt')}
                disabled={saving || !code}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default enhanceEmailChange(EmailChange);
