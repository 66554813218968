import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { defaultOperationsTypesSelection, operationsTypesFilter, operationsTypesFilterSelection } from 'app/dataProviders/filter-items'


type Props = {
  id: string
  pending: boolean
}

export const OperationsTypesFilter = ({ id, pending }: Props) => {
  return (
    <PlainItemsSelectorBox
      id={id}
      label="Тип операции"
      header="Выбор типа операции"
      dataBoxData={operationsTypesFilter}
      dataBoxSelection={operationsTypesFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      defaultSelection={defaultOperationsTypesSelection}
      disabled={pending}
    />

  )
}
