import { reduce, toPairs } from 'ramda'


const translate = {
  form_files_comment: 'Поддерживаемый формат: {{supportedFiles}}\nМаксимальный размер файла: {{maxSize}} Мб.',
  form_files_limit_exceeded: 'Файл загружен',
  form_files_select_1: 'Выберите файлы',
  form_files_select_2: 'на компьютере или перетащите их в это поле',
  form_error_unaccepted_size_message: 'Файл(ы) {{files}} не принят(ы). Превышен максимальный размер файла',
  form_error_unaccepted_format_message: 'Файл(ы) {{files}} не принят(ы). Неверный формат файла',
} as const

export const t = (key: keyof typeof translate, params?: Record<string, string>): string => {
  if (params) {
    return reduce((acc, [changeKey, changeValue]) => {
      if (changeKey) {
        return acc.replace(`{{${changeKey}}}`, changeValue)
      }
      return acc
    }, translate[key] as string, toPairs(params))
  }
  return translate[key]
}
