import pipe from 'ramda/es/pipe';
import omit from 'ramda/es/omit';
import prop from 'ramda/es/prop';
import path from 'ramda/es/path';

import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const SELLPOINTS_ADD_REQUEST = 'orgs/settings/SELLPOINTS_ADD_REQUEST';
export const SELLPOINTS_ADD_SUCCESS = 'orgs/settings/SELLPOINTS_ADD_SUCCESS';
export const SELLPOINTS_ADD_FAILURE = 'orgs/settings/SELLPOINTS_ADD_FAILURE';

export const addSellPoint = (orgId, title) => async (dispatch) => {
  dispatch(action(SELLPOINTS_ADD_REQUEST, { orgId, title }));
  try {
    const res = await api(`food/schools/${orgId}/sale_points`, 'post', { data: { title } });
    const sellpoints = pipe(prop('data'), omit(['result']))(res);
    dispatch(action(SELLPOINTS_ADD_SUCCESS, sellpoints));
    return {};
  } catch (error) {
    const message = path(['response', 'data', 'message'], error);
    if (message && message === 'sale point exist') {
      dispatch(action(SELLPOINTS_ADD_FAILURE, {
        err: 'Точка продаж уже существует, попробуйте другое название',
        error,
        orgId,
        title,
      }));
      return { err: 'exist' };
    }
    if (message && message === 'sale point deleted') {
      dispatch(action(SELLPOINTS_ADD_FAILURE, {
        err: 'Точка продаж уже существует в удаленных. Вы можете восстановить ее',
        error,
        orgId,
        title,
      }));
      return { err: 'exist_deleted' };
    }
    dispatch(action(SELLPOINTS_ADD_FAILURE, {
      err: 'Не удалось создать точку продаж',
      error,
      orgId,
      title,
    }));
    return { err: 'other' };
  }
};
