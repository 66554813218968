import React from 'react';
import classNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';

import type { passValidType } from 'app/common/lib/validate/password';
import Circle from 'app/common/ui/Circle';
import container from './container';
import Button from '../../ui/Button';
import LineInput from '../../ui/LineInput';
import styles from './NewPass.scss';


const cx = classNames.bind(styles);
type Props = {
  change: (Event) => void,
  pass1ValidProps: passValidType,
  newPassword1: string,
  newPassword2: string,
  submit: (Event) => void,
  pass1Valid: boolean,
  pass2Valid: boolean,
  loading: boolean,
  error: string,
  newPassTitle?: string,
};

const NewPass = ({
  change,
  pass1ValidProps: { lowercase, uppercase, numbers, amount },
  newPassword1,
  newPassword2,
  submit,
  pass1Valid,
  pass2Valid,
  loading,
  error,
  newPassTitle,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <h3 className={styles.header}>{newPassTitle || t('login:new_password_header')}</h3>
      <div className={styles.label}>
        <Trans i18nKey="login:pass_req_label">
          {'Пароль должен содержать'}
        </Trans>
        {':'}
      </div>
      <div className={styles.strings}>
        <div className={cx({ valid: lowercase })}>
          <Circle success={lowercase} error={!lowercase} />
          &nbsp;
          <Trans i18nKey="login:pass_req_lowercase">
            {'Английские маленькие буквы'}
          </Trans>
        </div>
        <div className={cx({ valid: uppercase })}>
          <Circle success={uppercase} error={!uppercase} />
          &nbsp;
          <Trans i18nKey="login:pass_req_uppercase">
            {'Английские большие буквы'}
          </Trans>
        </div>
        <div className={cx({ valid: numbers })}>
          <Circle success={numbers} error={!numbers} />
          &nbsp;
          <Trans i18nKey="login:pass_req_numbers">
            {'Минимум одну цифру'}
          </Trans>
        </div>
        <div className={cx({ valid: amount })}>
          <Circle success={amount} error={!amount} />
          &nbsp;
          <Trans i18nKey="login:pass_req_amount">
            {'Более 8 символов'}
          </Trans>
        </div>
      </div>
      <form onSubmit={submit} className={styles.form}>
        <div className={styles.input}>
          <LineInput
            name="newpassword1"
            placeholder={t('login:pass_new_label')}
            value={newPassword1}
            onChange={change}
            type="password"
            isValid={pass1Valid}
          />
        </div>
        <div className={styles.input}>
          <LineInput
            name="newpassword2"
            placeholder={t('login:pass_new_label_repeat')}
            value={newPassword2}
            onChange={change}
            type="password"
            isValid={pass2Valid}
          />
        </div>
        {error && <div className={styles.errorLine}>
          {error}
        </div>}
        {newPassword1 && !pass1Valid
          && <div className={styles.errorLine}>
            <Trans i18nKey="login:pass_req_fail_safe">
              {'Не выполнены условия безопасного пароля!'}
            </Trans>
          </div>}
        {newPassword2 && !pass2Valid
          && <div className={styles.errorLine}>
            <Trans i18nKey="login:pass_req_fail_same">
              {'Пароли не совпадают!'}
            </Trans>
          </div>}
        <div className={styles.button}>
          <Button type="submit" disabled={loading || !pass1Valid || !pass2Valid}>
            {loading
              ? t('login:pass_req_submit_bt_loading')
              : t('login:pass_req_submit_bt')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default container(NewPass);
