// @flow
import React, { useRef, useEffect, type ElementRef, useCallback } from 'react';
import classNames from 'classnames';

import StickyContext from './StickyContext';
import { sumTo, MAX_STICKY_NESTING } from './helpers';


type StProps = {
  children: any,
  ro: {
    observe: (el?: ElementRef<any>) => void,
    unobserve: (el?: ElementRef<any>) => void,
  },
  number: number,
  topShift: number,
  onStickyToggle: (n: number, r: boolean, c: ?boolean) => void,
  stickyClassName?: string,
  shadowClassName?: string,
  className?: string,
  itemsSticky: Array<boolean>,
  stopUseGPU?: boolean,
  mode?: 'bottom',
  elHeights: Array<number>,
}

const St = ({
  children,
  ro,
  number: num,
  topShift,
  onStickyToggle,
  stickyClassName,
  shadowClassName,
  className,
  itemsSticky,
  stopUseGPU, // eslint-disable-line no-unused-vars
  mode,
  elHeights,
}: StProps) => {
  const ref = useRef<HTMLElement | null>(null);
  const intersectObserver = useRef<IntersectionObserver | null>(null);
  const number = num === -1 ? MAX_STICKY_NESTING : num;


  // событие пересечения с краем вьюпорта
  const handleIntersect = useCallback((entries) => {
    const entrie = entries[0];
    const sticked: boolean = entrie.intersectionRatio < 1;
    onStickyToggle(number, sticked, true);

    // назначение класса при прилипании (работает некорректно и не быстрее, чем через state)
    const currentNode = entrie.target;
    if (stickyClassName) {
      if (sticked) {
        currentNode.className = `${currentNode.className} ${stickyClassName}`;
      } else {
        currentNode.className = currentNode
          .className
          .split(' ')
          .filter(classN => (classN !== stickyClassName))
          .join(' ');
      }
    }
  }, [number, onStickyToggle, stickyClassName]);


  useEffect(() => {
    const currentNode = ref.current;
    if (!currentNode) {
      return () => {};
    }

    if (ro) ro.observe(currentNode);

    // unmount
    return () => {
      if (intersectObserver.current) intersectObserver.current.unobserve(currentNode);
      if (ro) ro.unobserve(currentNode);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    // если нет размера у элемента, выходим
    if (!elHeights[number - 1]) return;

    const currentNode = ref.current;
    if (!currentNode) return;

    // благодаря таким параметрам, прилипший элемент считается пересекающим край вьюпорта
    // что используется в IntersectionObserver
    const rootMargin = mode === 'bottom'
      ? `0px 0px ${-10}px 0px`
      : `${-topShift - 10}px 0px 0px 0px`;
    intersectObserver.current = new IntersectionObserver(handleIntersect, {
      threshold: 1,
      rootMargin,
      // delay: 100,
    });
    if (intersectObserver.current) {
      intersectObserver.current.observe(currentNode);
    }
  }, [elHeights]); // eslint-disable-line react-hooks/exhaustive-deps


  // если текущий прилип - класс прилипания
  const stickyCN = itemsSticky[number - 1] ? stickyClassName : null;
  // если текущий прилип, а следующий - нет - класс тени
  const shadowCN = itemsSticky[number - 1] && !itemsSticky[number] ? shadowClassName : null;


  return (
    <div
      ref={ref}
      id={number}
      className={classNames(className, shadowCN, stickyCN)}
      style={{
        position: 'sticky',
        ...(mode === 'bottom' ? { bottom: 0 } : { top: topShift }),
      }}
    >
      {children}
    </div>
  );
};


type StickyProps = {
  children: any,
  number?: number,
  trackState?: boolean, // не используется в CssSticky
  mode?: 'bottom',
}

const CssSticky = ({ children, number = 1, trackState, mode, ...props }: StickyProps) => (
  <StickyContext.Consumer>
    {({ elHeights, ro, itemsSticky, onStickyToggle }) => {
      const topShift = sumTo(number, elHeights);
      return (
        // $FlowFixMe
        <St
          ro={ro}
          topShift={topShift}
          number={number}
          itemsSticky={itemsSticky}
          onStickyToggle={onStickyToggle}
          elHeights={elHeights}
          mode={mode}
          {...props}
        >
          {typeof children === 'function'
            ? children(itemsSticky[number > 0 ? number - 1 : MAX_STICKY_NESTING - 1])
            : children}
        </St>
      );
    }}
  </StickyContext.Consumer>
);

export default CssSticky;
