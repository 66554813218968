// @flow
import { useSelector, shallowEqual } from 'react-redux';

import getSignByCode, { options } from './get-sign-by-code';


export default function useCurrencySign(op?: $Values<typeof options>): string {
  const { country_iso_code: iso = 'ru' } = useSelector(state => state.dealer, shallowEqual);

  return getSignByCode(iso, op);
}
