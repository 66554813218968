// @flow
import React, { type Node, Fragment, useCallback, memo } from 'react';
import cn from 'classnames/bind';

import { TrSelectable, TdLabel, Td, getPropsHeader } from 'app/common/ui-components/table';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import Picture from 'app/common/ui-next/picture';

import type { RowCell } from './useRowCellsData';
import CellChangeable from './CellChangeable';
import styles from './Row.scss';


const cx = cn.bind(styles);

type Props = {
  id: string,
  title: string,
  alreadyAdded?: boolean,
  checked: boolean,
  disabledItem?: boolean,
  disabled: boolean,
  cells: Array<RowCell>,
  onChange: (id: string, chkd: boolean, title: string) => void,
  amountAdd: number,
  increase: (slotId: string) => void,
  decrease: (slotId: string) => void,
  change: (slotId: string, value: number) => void,
}

// $FlowFixMe
const Row = memo<Props>(({
  id,
  title,
  alreadyAdded,
  checked,
  disabledItem,
  disabled,
  cells,
  onChange,
  amountAdd,
  increase,
  decrease,
  change,
}: Props): Node => {
  const getHeaderProps = getPropsHeader();

  const handleClick = useCallback((identificator: string, chk: boolean) => {
    onChange(identificator, chk, title);
  }, [title, onChange]);

  const htmlFor = `product_${id}`;

  return (
    <TrSelectable
      id={id}
      onClick={handleClick}
      className={cx({ alreadyAdded, currentlyAdded: !alreadyAdded && checked, disabledItem })}
      selected={disabledItem || checked}
      disabled={disabled}
      interlineBorder
      alignTop
    >
      {checkboxProps => (
        <Fragment>
          {cells.map(({ key, value, extra, ...props }) => {
            switch (key) {
              case 'id':
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    className={styles.cellId}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    <PlainCheckbox
                      name={id}
                      label={cells[1].value}
                      skipLabel
                      {...checkboxProps}
                      id={htmlFor}
                      disabled={disabledItem || disabled}
                    />
                  </TdLabel>
                );

              case 'photo':
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    {value
                      ? <Picture
                        className={styles.photo}
                        alt="Фото продукции"
                        src={value}
                        width="100px"
                        height="auto"
                      />
                      : ' '}
                  </TdLabel>
                );

              case 'amountAdd':
                return (
                  <Td
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    <CellChangeable
                      slotId={id}
                      value={amountAdd}
                      invert={checkboxProps.checked}
                      increase={increase}
                      decrease={decrease}
                      change={change}
                      max={extra ? extra.max : Infinity}
                    />
                  </Td>
                );

              default:
                return (
                  <TdLabel
                    htmlFor={htmlFor}
                    key={key}
                    {...getHeaderProps(key)}
                    {...props}
                  >
                    {value}
                  </TdLabel>
                );
            }
          })}
        </Fragment>
      )}
    </TrSelectable>
  );
});

export default Row;
