// @flow
import React, { useMemo, useEffect } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import moment from 'moment-timezone';

import {
  Table,
  Tr,
  Th,
  Td,
} from 'app/common/ui-components/table';

import Loading from 'app/common/components/Loader';
import usePaging from 'app/common/hooks/usePaging';
import Paging from 'app/common/ui/Paging';
import ScrollbarsWShadow from 'app/common/ui-components/scrollbars-w-shadow';
import { balanceReservesStatuses as bs, type BalanceReservesRequestParams } from 'app/common/api/general.types/cafe.balance.reserves';
import EmptyWarn from 'app/common/layouts/EmptyWarn';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainDateInput, { defaultDateFormat } from 'app/common/ui/PlainDateInput';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainSelect from 'app/common/ui/PlainSelect';

import useReservationList from './use-reservation-list';
import useReservationFilter, { statusAny } from './use-reservation-filter';
import Status from './status';

import styles from './reservation-history.scss';


const dropdownItems = [{
  id: 999,
  title: 'Все',
}, {
  id: bs.wait,
  title: 'В ожидании',
}, {
  id: bs.processed,
  title: 'Заявка проведена',
}, {
  id: bs.deleted,
  title: 'Удалено',
}];

type Props = {
  customerId: number,
}

const ReservationHistory = ({ customerId }: Props) => {
  const { currentPage, handlePage, setCurrentPage } = usePaging();
  const [
    [status, setStatus],
    [dateFrom, setDateFrom],
    [dateTo, setDateTo],
  ] = useReservationFilter(
    moment().startOf('month').format(defaultDateFormat),
    moment().format(defaultDateFormat),
  );
  const perPage = 10;

  useEffect(() => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  }, [perPage, status, dateFrom, dateTo]); // eslint-disable-line react-hooks/exhaustive-deps

  const params: BalanceReservesRequestParams = useMemo(() => ({
    customer_id: customerId,
    limit: perPage,
    offset: (currentPage - 1) * perPage,
    // $FlowFixMe
    status: status === statusAny ? undefined : status,
    from_date: dateFrom,
    to_date: dateTo,
  }), [customerId, currentPage, perPage, status, dateFrom, dateTo]);

  const [reservationsList, count, loading] = useReservationList(params);

  const nothing = isEmpty(reservationsList);


  return (
    <div className={styles.root}>
      <ControlsBar className={styles.controlsBar}>
        <PlainLabel className={styles.statusLabel} htmlFor="status">
          {'Статус:'}
        </PlainLabel>
        <PlainSelect
          outsideClassName={styles.status}
          // $FlowFixMe
          data={dropdownItems}
          name="status"
          current={status}
          placeholder="Все"
          onChange={setStatus}
          loading={loading}
          hideErrorString
          uni
        />

        <PlainLabel className={styles.fromToLabel} htmlFor="from">
          {'Период времени:'}
        </PlainLabel>
        <PlainDateInput
          name="from"
          value={dateFrom}
          onChangeDate={setDateFrom}
          // disabledDaysInPicker={disabledDays}
          className={styles.date}
          loading={loading}
          hideErrorString
        />
        <span className={styles.tire}>{'—'}</span>
        <PlainDateInput
          name="to"
          value={dateTo}
          onChangeDate={setDateTo}
          // disabledDaysInPicker={disabledDays}
          className={styles.date}
          loading={loading}
          hideErrorString
        />
      </ControlsBar>
      <Table className={styles.table}>
        <thead>
          <Tr header className={styles.headerRow}>
            <Th className={styles.colCreatedAt} alignLeft>{'Дата\u00A0/ время резервирования'}</Th>
            <Th className={styles.colSumFromBalance} alignLeft>{'Сумма (рублей) с\u00A0баланса\u00A0/ с\u00A0дотаций'}</Th>
            <Th alignLeft>{'Причина резервирования'}</Th>
            <Th className={styles.colCanceledAt} alignLeft>{'Снятие резервирования'}</Th>
            <Th className={styles.colStatus} alignLeft>{'Статус'}</Th>
          </Tr>
        </thead>
      </Table>

      {loading
        && <Loading className={styles.loading} />}

      {nothing
        && !loading
        && <EmptyWarn className={styles.emptyWarn}>
          {'Записи отcутствуют'}
        </EmptyWarn>}

      {!nothing
        && !loading
        && <ScrollbarsWShadow
          autoHeight
          autoHeightMin={300}
          autoHeightMax={300}
        >
          <Table className={styles.table}>
            <tbody>
              {reservationsList.map(({
                id,
                createdAt,
                sumFromBalance,
                comment,
                canceledAt,
                status: statusKey,
              }) => (
                <Tr key={id}>
                  <Td className={styles.colCreatedAt}>{createdAt}</Td>
                  <Td className={styles.colSumFromBalance}>{sumFromBalance}</Td>
                  <Td>{comment}</Td>
                  <Td className={styles.colCanceledAt}>{canceledAt}</Td>
                  <Td className={styles.colStatus}><Status statusKey={statusKey} /></Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </ScrollbarsWShadow>}

      {(count > perPage)
        && <Paging
          className={styles.pager}
          total={count}
          pageSize={perPage}
          currentPage={currentPage}
          currentPageSize={reservationsList.length}
          onPage={handlePage}
        />}
    </div>
  );
};

export default ReservationHistory;
