// @flow
import React, { type Node, type Ref, useCallback } from 'react';

import type { HandleModalDisplayed } from 'app/common/ui-next/plain-modal';

import styles from './Form.scss';


const formId = 'addRemoveProduction';

type Props = {
  reference: Ref<'form'>,
  children: Node,
  displayModal: HandleModalDisplayed,
  onSubmit: () => Promise<boolean>,
  onSubmitStatusChange: (loading: boolean) => void,
}

const Form = ({ reference, children, displayModal, onSubmit, onSubmitStatusChange }: Props): Node => {
  const handleSubmit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formEvent = e.target;
    const formElement = ((formEvent: any): HTMLFormElement);

    if (formId) {
      const { id } = formElement;
      // эта ли форма сабмитится
      if (id !== formId) {
        return;
      }
    }

    if (onSubmit) {
      onSubmitStatusChange(true);
      const res = await onSubmit();
      onSubmitStatusChange(false);

      if (res) {
        displayModal(false);
      }
    }
  }, [displayModal, onSubmit, onSubmitStatusChange]);

  return (
    <form onSubmit={handleSubmit} ref={reference} id={formId}>
      {children}
      <button className={styles.hidden} type="submit" name="submit">
        {'submitButtonTitle'}
      </button>
    </form>
  );
};

export default Form;
