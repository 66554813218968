// @flow
import React, { type Ref } from 'react';
import cn from 'classnames';

import { Tr, Td } from 'app/common/ui-components/table';

import {
  multiLineCellTypes,
  MultiLineCellTotal,
  MultiLineCellStub,
  MultiLineCellDoubleTotal,
  MultiLineCellDoubleStub,
} from '../MultiLineCell';

import type { TotalDisplayableItem } from '../useTotals';
import { getColumnId, totalRowId } from './columnsAndRowsIds';

import styles from './ContentFooter.scss';


const emptyProps = Object.freeze({});

type Props = {
  hidden?: boolean,
  totals: Array<TotalDisplayableItem>,
  reference?: Ref<'tr'>,
  onCellMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onCellMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  sameColumnClassName?: string,
  showDuty?: boolean,
  sellTypeGroupped?: boolean,
}

const ContentFooter = ({
  hidden,
  totals,
  reference,
  onCellMouseEnter,
  onCellMouseLeave,
  sameColumnClassName,
  showDuty,
  sellTypeGroupped,
}: Props) => {
  const maxIndex = totals.length - 1;
  const Cell = sellTypeGroupped ? MultiLineCellDoubleTotal : MultiLineCellTotal;
  const CellStub = sellTypeGroupped ? MultiLineCellDoubleStub : MultiLineCellStub;

  return (
    <tfoot>
      <Tr
        hidden={hidden}
        reference={reference}
        className={styles.row}
        footer
      >
        {totals.map(({ date, data }, i) => {
          const props = hidden ? emptyProps : {
            headers: `${getColumnId(i)} ${totalRowId}`,
          };

          return (
            <Td
              key={date}
              stripPadding
              onMouseEnter={onCellMouseEnter}
              onMouseLeave={onCellMouseLeave}
              className={cn(sameColumnClassName && `${sameColumnClassName}${i}`)}
              {...props}
            >
              {hidden
                ? <CellStub data={data} />
                : <Cell
                  data={data}
                  showDuty={showDuty}
                  type={multiLineCellTypes.TOTAL}
                  borderRight={i < maxIndex}
                />}
            </Td>
          );
        })}
      </Tr>
    </tfoot>
  );
};

export default ContentFooter;
