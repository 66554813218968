import { DeleteAgreementDocumnetParams } from 'app/dataTypes/agreement/documents'

import type { Request } from '../../../index'


type UrlParams = {
  id: string
}

export const convertParams = (params: DeleteAgreementDocumnetParams): [null, UrlParams] => {
  const { ID } = params
  return [null, { id: ID }]
}

export const convertResult = (): true => {
  return true
}

export const DELETE_AGREEMENT_DOCUMENTS: Request<void, true, UrlParams> = [
  'food/agreement/documents/{id}',
  'delete',
  ['data'],
]
