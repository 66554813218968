import { useMemo } from 'react'

import { Org } from 'app/dataTypes/org'


export default function useFilter(filterFunc: (org: Org) => boolean, orgsMap: Map<string, Org>) {
  const res = useMemo(() => {
    const filtered = new Map<string, Org>()
    for (const item of orgsMap) {
      const [ID, org] = item
      if (filterFunc(org)) {
        filtered.set(ID, org)
      }
    }
    return filtered
  }, [orgsMap, filterFunc])

  return res
}
