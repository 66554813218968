import {
  SORT_FILTER_SET,
  SORT_FILTER_DEFAULT,
  BY_ALPHABET,
} from 'app/actions/reports/filters/sort';


const defaultState = BY_ALPHABET;

const sort = (state = defaultState, { type, payload }) => {
  if (type === SORT_FILTER_SET) {
    return payload;
  }
  if (type === SORT_FILTER_DEFAULT) {
    return defaultState;
  }
  return state;
};

export default sort;
