// @flow
import { useCallback } from 'react';
import { map, cond, equals } from 'ramda';

import { PlanogramDataExt, PlanogramItem } from '../_parts/planogram-data-class';


type Options = {
  data: PlanogramDataExt<PlanogramItem>,
  onChangeData?: (Array<PlanogramItem>) => Promise<void> | void,
  pendingAdd: (val: Array<string>, pause?: number) => void,
  pendingRemove: (val: Array<string>) => void,
}

export default function useChangeItemWidth({
  data,
  onChangeData,
  pendingAdd,
  pendingRemove,
}: Options): (
  action: 'mergeLeft' | 'mergeRight' | 'split',
  item: PlanogramItem
) => Promise<void> {
  const handleChangeItemWidth = useCallback(async (action, { column, row }) => {
    if (!onChangeData) return;

    const resultItems: Array<PlanogramItem> = cond([
      [equals('mergeLeft'), () => data.getMergedLeft(column, row)],
      [equals('mergeRight'), () => data.getMergedRight(column, row)],
      [equals('split'), () => data.getSplitted(column, row)],
    ])(action);

    const pendingIds = map(({ id }) => id, resultItems);

    pendingAdd(pendingIds, 0);
    await onChangeData(resultItems);
    pendingRemove(pendingIds);
  }, [data, onChangeData, pendingAdd, pendingRemove]);

  return handleChangeItemWidth;
}
