import { AccrualCampFor } from 'app/dataTypes/subsidy/settings'
import PlainHelperText from 'app/common/ui-next/plain-helper-text/plain-helper-text'
import { getScrollToErrorClassName } from 'app/common/hooks/useScrollToError'

import { FormGridRow, FormGridTemplate } from './FormGridTemplate'


export type Errors = Map<string, string>

export const controls = {
  camp: 'camp',
} as const

const defaultTtiles = {
  camp: 'Начислять льготы при комплексных продажах в лагере',
} as const

type Props = {
  data: AccrualCampFor
  disabled?: boolean
  formID: string
  errors: Errors
  titles?: Record<keyof typeof controls, string>
  resetErrors: () => void
}

export const AccuralForFormCamp = ({
  data,
  disabled,
  formID,
  errors,
  titles = defaultTtiles,
  resetErrors,
}: Props) => {
  const error = errors.get('accrualCampFor')

  return (
    <>
      <FormGridTemplate
        title="При каких типах продаж начислять льготы:"
      >
        {data.camp
          && <FormGridRow
            name={controls.camp}
            title={titles[controls.camp]}
            data={data.camp}
            disabled={disabled}
            formID={formID}
            onModify={resetErrors}
          />}
      </FormGridTemplate>

      <PlainHelperText
        className={getScrollToErrorClassName(!!error)}
        error
      >
        {error}
      </PlainHelperText>
    </>
  )
}
