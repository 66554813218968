import { ReactNode } from 'react'
import classNames from 'classnames/bind'
import PlainLink from 'app/common/ui-next/plain-link'

import IconChild from './icons/IconChild'
import styles from './side-navigation-item.scss'


const cx = classNames.bind(styles)

type Props = {
  children?: ReactNode,
  title: string,
  to: string,
  active?: boolean,
  collapsed?: boolean,
  hideIcon?: boolean,
  disabled?: boolean,
}

const SideNavigationItem = ({
  children,
  title,
  to,
  active,
  collapsed,
  hideIcon,
  disabled,
}: Props) => (
  <PlainLink
    className={cx(styles.root, { active, disabled })}
    disabled={disabled}
    to={to}
  >
    {!hideIcon && <div className={styles.icon}>
      {children || <IconChild />}
    </div>}

    <div className={cx(styles.title, { collapsed })}>
      {title}
    </div>
  </PlainLink>
)

export default SideNavigationItem
