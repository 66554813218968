import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import renderNothing from 'recompose/renderNothing';

import {
  DOCUMENT_PUBLIC_OFFER,
  DOCUMENT_PRIVACY_POLICY,
} from 'app/common/constants/documents';
import { logout as logoutAction } from 'app/common/actions/user';
import { acceptOffer as acceptOfferAction } from 'app/common/actions/documents/acceptOffer';
import { acceptPrivacy as acceptPrivacyAction } from 'app/common/actions/documents/acceptPrivacy';

import selectUnacceptedDocument from './selectUnacceptedDocument';
import Offer from './Offer';
import Privacy from './Privacy';


const enhanceDocuments = compose(
  connect(
    (state, props) => ({
      doc: selectUnacceptedDocument(state, props),
    }),
  ),

  withState('accepted', 'setAccepted', false),
  withState('accepting', 'setAccepting', false),

  withHandlers({
    handleChangeAccepted: ({ setAccepted }) => (e) => {
      setAccepted(e.currentTarget.checked);
    },
    handleLogout: ({ dispatch }) => (e) => {
      e.preventDefault();
      dispatch(logoutAction());
    },
    handleAcceptOffer: ({ dispatch, doc, setAccepting, setAccepted }) => async (e) => {
      e.preventDefault();
      if (!doc || !doc.id) return;
      setAccepting(true);
      await dispatch(acceptOfferAction(doc.id));
      setAccepting(false);
      setAccepted(false);
    },
    handleAcceptPrivacy: ({ dispatch, doc, setAccepting, setAccepted }) => async (e) => {
      e.preventDefault();
      if (!doc || !doc.ids) return;
      setAccepting(true);
      await dispatch(acceptPrivacyAction(doc.ids));
      setAccepting(false);
      setAccepted(false);
    },
  }),

  branch(
    props => (props.doc && props.doc.type === DOCUMENT_PUBLIC_OFFER),
    renderComponent(Offer),
  ),

  branch(
    props => (props.doc && props.doc.type === DOCUMENT_PRIVACY_POLICY),
    renderComponent(Privacy),
  ),

  renderNothing,

)();

export default enhanceDocuments;
