import config from 'app/config';

import {
  SAVE_SETTINGS_REQUEST,
} from 'app/common/actions/settings/save';

import {
  SETTINGS_SUCCESS,
} from 'app/common/actions/settings/load';

const ds = {
  rows: config.perPageItems,
};

const makeSettingsReducer = (settingsKey, defaultState = ds) => (state = defaultState, action) => {
  if (
    action.type === SAVE_SETTINGS_REQUEST
      && action.payload.key
      && action.payload.key === settingsKey
  ) {
    const newState = { ...state, ...action.payload.obj };
    return newState;
  }
  if (action.type === SETTINGS_SUCCESS && action.payload[settingsKey]) {
    const newState = { ...defaultState, ...action.payload[settingsKey] };
    return newState;
  }

  return state;
};

export default makeSettingsReducer;
