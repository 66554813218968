// @flow
import React, { type Node, useMemo } from 'react';

import VendingDataContext from './VendingDataContext';
import useVendingStatus from './status/useVendingStatus';
import useVendingMachinesParams from './machines/useVendingMachinesParams';
import useVendingMachines from './machines/useVendingMachines';
import { getRelatedOrgIds as relatedFromMachines } from './machines/getRelatedOrgIds';

import useVendingMachineSingle from './machineSingle/useVendingMachineSingle';
import useMachineValidate from './machineSingle/useMachineValidate';
import useMachineSubmit from './machineSingle/useMachineSubmit';
import useMachineRequest from './machineSingle/useMachineRequest';
import { getRelatedOrgIds as relatedFromSingle } from './machineSingle/getRelatedOrgIds';
import useMachineDelete from './machineSingle/useMachineDelete';
import useMachineForm from './machineSingle/useMachineForm';

import useRelatedOrgs from './orgs/useRelatedOrgs';
import useAvailableOrgs from './orgs/useAvailableOrgs';
import useModels from './models/useModels';
import useVersions from './versions/useVersions';


type Props = {
  children: Node,
}

const VendingDataProvider = ({ children }: Props) => {
  const status = useVendingStatus();

  const machinesParams = useVendingMachinesParams();
  const [params] = machinesParams;
  const machines = useVendingMachines(params);

  const machineSingle = useVendingMachineSingle();
  const [machine, setMachine] = machineSingle;
  const machineForm = useMachineForm(machine);
  const [form] = machineForm;
  const machineValidate = useMachineValidate(form);
  const [, validate] = machineValidate;
  const machineSubmit = useMachineSubmit(form, validate, setMachine);
  const machineRequest = useMachineRequest(setMachine);
  const machineDelete = useMachineDelete(form);

  const relatedOrgsIds = useMemo(() => {
    return (
      relatedFromMachines(machines[0])
      || relatedFromSingle(machineSingle[0])
      || []
    );
  }, [machines, machineSingle]);

  const relatedOrgs = useRelatedOrgs(relatedOrgsIds);
  const availableOrgs = useAvailableOrgs();
  const models = useModels();
  const versions = useVersions();

  const value = {
    status,
    machines,
    machinesParams,
    machineSingle,
    machineForm,
    machineValidate,
    machineSubmit,
    machineRequest,
    machineDelete,
    relatedOrgs,
    availableOrgs,
    models,
    versions,
  };

  return (
    <VendingDataContext.Provider value={value}>
      {children}
    </VendingDataContext.Provider>
  );
};

export default VendingDataProvider;
