import api from 'app/actions/api';
import action from 'app/common/lib/action';


export const MENUCATEGORIES_SAVE_REQUEST = 'orgs/settings/MENUCATEGORIES_SAVE_REQUEST';
export const MENUCATEGORIES_SAVE_SUCCESS = 'orgs/settings/MENUCATEGORIES_SAVE_SUCCESS';
export const MENUCATEGORIES_SAVE_FAILURE = 'orgs/settings/MENUCATEGORIES_SAVE_FAILURE';

export const saveMenuCategoriesSettings = (orgId, data) => async (dispatch) => {
  dispatch(action(MENUCATEGORIES_SAVE_REQUEST, { orgId, data }));
  try {
    await api(`food/schools/${orgId}/menu_categories`, 'put', { data });
    dispatch(action(MENUCATEGORIES_SAVE_SUCCESS, { orgId, data }));
    return true;
  } catch (error) {
    dispatch(action(MENUCATEGORIES_SAVE_FAILURE, {
      err: 'Не удалось изменить настройки категорий меню',
      error,
      orgId,
      data,
    }));
    return false;
  }
};
