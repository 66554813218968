// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as details } from 'app/components/common/reports/filters/details';
import { flags as org } from 'app/components/common/reports/filters/org';

import { flags as group } from '../group';


const accountActivity = (state: State) => {
  return (
    details.detailsChecked(state)
    && org.singleOrgSelected(state)
    && group.byPurchasesSelected(state)
  );
};

export default accountActivity;
