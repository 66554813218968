// @flow
import React, { type Node } from 'react';

import { Table, Tr, Th } from 'app/common/ui-components/table';


export const columnHeaderText = 'Всего';
export const columnId = 'header_total';

type Props = {
  className: string,
}

const HeaderRightContent = ({ className }: Props): Node => {
  return (
    <Table className={className}>
      <thead>
        <Tr header>
          <Th
            scope="col"
            id={columnId}
            alignRight
          >
            {columnHeaderText}
          </Th>
        </Tr>
      </thead>
    </Table>
  );
};

export default HeaderRightContent;
