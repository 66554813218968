import { ReactNode, ChangeEvent } from 'react'

import PlainInput from 'app/common/ui/PlainInput'
import PlainFieldset from 'app/common/ui-next/plain-fieldset'

import styles from './OptionsSet.scss'


type RenderSetProps = {
  className?: string,
  label: string,
  name: string,
  value: number,
  loading: boolean,
  saving: boolean,
  disabled: boolean,
  currency: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  children?: ReactNode,
}

const OptionsSet = ({
  className,
  label,
  name,
  value,
  loading,
  saving,
  disabled,
  currency,
  onChange,
  children,
}: RenderSetProps) => (
  <PlainFieldset
    name={name}
    className={className}
    legend={label}
  >
    <PlainInput
      name={name}
      value={String(value)}
      onChange={onChange}
      disabled={disabled || saving}
      loading={loading}
      className={styles.input}
      hideErrorString
    />
    <div className={styles.inputUnits}>{currency}</div>
    <div className={styles.children}>{children}</div>
  </PlainFieldset>
)

export default OptionsSet
