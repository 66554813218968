import { useMemo } from 'react'

import styles from './columns.scss'


export const idsFixed: [
  'photo',
  'fio'
] = [
  'photo',
  'fio',
]

export const idsMain: [
  'jobPlace',
  'position',
  'phone',
  'email',
  'ident'
  // 'inviteDate',
] = [
  'jobPlace',
  'position',
  'phone',
  'email',
  'ident',
  // 'inviteDate',
]

export const titlesFixed = [
  'Фото',
  'ФИО, дата рождения',
]

export const titlesMain = [
  'Место работы',
  'Должность',
  'Телефон',
  'Email',
  'Идентификатор',
  // 'Дата приема',
]

export const useColumnPropsFixed = () => {
  const res = useMemo(() => {
    return {
      photo: {
        alignCenter: true,
        className: styles.columnPhoto,
      },
      fio: {
        alignLeft: true,
        className: styles.columnFIO,
      },
    }
  }, [])

  return res
}

export const useColumnPropsMain = () => {
  const res = useMemo(() => {
    return {
      alignCenter: true,
      className: styles.columnPhoto,
    }
  }, [])

  return res
}
