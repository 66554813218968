// @flow
import compose from 'recompose/compose';

import { withMenuMenuRights, withCurrentOrgRights } from 'app/containers/rights';
import { withWriteAccess, bothRights } from 'app/common/containers/withRights';


export const writeConsist = compose(
  bothRights(withMenuMenuRights, withCurrentOrgRights),
  withWriteAccess,
);
