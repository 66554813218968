// @flow
import { useState, useCallback, useEffect } from 'react';

/**
 *  Boolean состояниe (для loading) на бесконечное количество эл-тов
 *  state1 || state2 || state3 || ... || stateN
 */

export default function useMultipleBooleanState(additionalState?: boolean) {
  const [count, setCount] = useState(0);

  const handleSetBooleanState = useCallback((newState: boolean) => {
    setCount(current => (newState ? current + 1 : current - 1));
  }, []);

  if (count < 0) {
    console.log('%c👻 Возможно неверное использование useMultipleBooleanState', 'color: LawnGreen');
  }

  // устранение дребезга для loading false (count < 1)
  const [loadingDeb, setLoadingDeb] = useState(false);
  useEffect(() => {
    if (count < 1) {
      setTimeout(() => {
        setLoadingDeb(false);
      }, 120);
      return;
    }
    setLoadingDeb(true);
  }, [count]);

  return [additionalState || loadingDeb || count > 0, handleSetBooleanState];
}
