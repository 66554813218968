import { ChangeEvent } from 'react'
import cn from 'classnames/bind'

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link'
import PlainTooltip from 'app/common/ui-next/plain-tooltip'
import PlainIcon, { iconTypes } from 'app/common/ui-next/plain-icon'

import styles from './report-file-button.scss'


const cx = cn.bind(styles)

type Props = {
  tooltipPrompt?: string
  label?: string
  labelClassName?: string
  loading?: boolean
  onClick?: (e: ChangeEvent<HTMLAnchorElement>) => void
}

const ReportFile = ({
  tooltipPrompt,
  label,
  labelClassName,
  loading,
  onClick,
}: Props) => {
  return (
    <div className={styles.defaultLayout}>
      <fieldset className={styles.fieldset}>

        {label
          && <legend className={cx(styles.legend, labelClassName)}>
            {label}
          </legend>}

        <PlainTooltip text={tooltipPrompt}>
          <PlainButtonAsLink
            className={cx({ buttonLoading: loading })}
            disabled={loading}
            onClick={onClick}
            ariaLabel={tooltipPrompt}
          >
            <PlainIcon
              width={20}
              height="auto"
              type={iconTypes.EXCEL}
            />
          </PlainButtonAsLink>
        </PlainTooltip>
      </fieldset>
    </div>
  )
}

export default ReportFile
