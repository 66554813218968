import { AccrualCamp } from 'app/dataTypes/subsidy/settings'

import { controls, selection } from './formParts/accrualFormCampControls'


const defaultAccrual: AccrualCamp = {
  active: false,
  type: 'complexes_grocery_set_cost',
  percent: '',
  complexes: [],
}

export function extractCampAccrual(formData: FormData): AccrualCamp {
  const active = formData.get(controls.activeCheckbox)
  const complexes = formData.getAll(controls.complexesList) as (Array<string> | undefined)
  const complexesIDs = complexes ? complexes.map(key => ({ ID: key })) : []
  const currentSelection = formData.get(controls.selectionRadio)

  if (active) {
    if (currentSelection === selection.complexes) {
      return { ...defaultAccrual, active: true, complexes: complexesIDs }
    }
  }

  if (currentSelection === selection.percent) {
    const currentPersent = (formData.get(controls.percentInput) as string | undefined) || ''
    if (currentPersent) {
      return {
        ...defaultAccrual,
        active: true,
        percent: currentPersent,
        complexes: complexesIDs,
        type: 'percent',
      }
    }
  }

  return { ...defaultAccrual }
}
