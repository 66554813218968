import Person from 'app/common/classes/person'


export type CompensationDetailsParams = {
  orgID: string
  year: string
  month: string
  allowanceID: string
}

export const isCompensationDetailsParams = (params: any): params is CompensationDetailsParams => {
  const { orgID, year, month, allowanceID } = params
  return !!(orgID && year && month && allowanceID)
}

export type CompensationStudentDetails = {
  ID: string
  classTitle: string
  name: Person
  days: number
  sum: number
}

export type CompensationDetails = {
  students: Array<CompensationStudentDetails>
  total: {
    students: number
    days: number
    sum: number
  }
}
