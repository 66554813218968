// @flow
import { useCallback } from 'react';

import usePopupErr from 'app/common/hooks/usePopupErr';
import { POST_COMPLEXES_ADD_REMOVE, convertParams as convertParamsKits } from 'app/common/api/requests/food/menu/complexes_add_remove';
import { menuNeedUpdate } from 'app/actions/orgs/menu/options';
import { api } from 'app/api';


type Options = {
  kitId: number,
  orgId: number,
  date: string,
  sellPointId: number,
}

export default function useKitDelete({ kitId, orgId, sellPointId, date }: Options) {
  const [, popup, dispatch] = usePopupErr();

  const deleteKit = useCallback(async () => {
    const res = await api.request(POST_COMPLEXES_ADD_REMOVE, {
      error: 'Не удалось удалить комплект из меню',
      params: {
        orgId,
        sellPointId,
        date,
        add: [],
        remove: [kitId],
      },
      convertions: {
        convertParams: convertParamsKits,
      },
    });

    if (res) {
      dispatch(menuNeedUpdate(true));
      popup('Комплект был успешно удален из меню');
    }
  }, [
    orgId,
    sellPointId,
    date,
    kitId,
    dispatch,
    popup,
  ]);

  return deleteKit;
}
