import mapProps from 'recompose/mapProps';
import classNames from 'classnames/bind';

import styles from './SectionSettings.scss';


const cx = classNames.bind(styles);

export default mapProps(({ disabled, error, success, middle, className, ...props }) => ({
  ...props,
  className: cx(styles.big, { disabled, error, success, middle }, className),
}))('span');
