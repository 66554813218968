// @flow
import { useSelector, shallowEqual } from 'react-redux';

import { iconTypes } from 'app/common/ui-next/plain-icon';


const AUTHORIZATION_DATA: 'authorization_data' = 'authorization_data';
const LINKED_USERS: 'related_users' = 'related_users';

export const sections = {
  AUTHORIZATION_DATA,
  LINKED_USERS,
};

export const defaultSection = sections.AUTHORIZATION_DATA;

export const useAvailableSections = (): Array<$Values<typeof sections>> => {
  const { employee, loading } = useSelector(state => state.user, shallowEqual);
  if (loading || !employee) {
    return [AUTHORIZATION_DATA];
  }
  return [
    AUTHORIZATION_DATA,
    LINKED_USERS,
  ];
};

const sectionTitles = {
  [AUTHORIZATION_DATA]: 'Данные авторизации',
  [LINKED_USERS]: 'Связанные пользователи',
};

export const getSectionTitle = (key: string): string => {
  return sectionTitles[key] || '';
};

export const getSectionIcon = (key: string): $Values<typeof iconTypes> => {
  if (key === AUTHORIZATION_DATA) {
    return iconTypes.AUTHORIZATION_DATA;
  }
  return iconTypes.LINKED_USERS;
};
