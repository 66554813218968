// @flow
import React, { useCallback, type Node } from 'react';

import PlainButton from 'app/common/ui-next/plain-button';


type Props = {
  formRef: { current: HTMLFormElement | null },
  disabled?: boolean,
  children: Node,
}

const JobPlacesSubmitBar = ({ formRef, disabled, children }: Props) => {
  const submit = useCallback(() => {
    if (formRef.current) {
      formRef.current.submit.click();
    }
  }, [formRef]);

  return (
    <PlainButton
      onClick={submit}
      disabled={disabled}
    >
      {children}
    </PlainButton>
  );
};

export default JobPlacesSubmitBar;
