import { reduce, values } from 'ramda'

import { useMemo } from 'react'

import { Org } from 'app/dataTypes/org'
import { OrgType, isOrgType } from 'app/dataTypes/org/types'
import SimpleDate from 'app/common/classes/simple-date'

import { OrgsSchools } from './useOrgs'


// DEPRECATED
const convert = (orgs: OrgsSchools) => {
  return reduce((acc, { id, title, type, deleted, from, school_group_id: orgGroupID }) => {
    acc.set(String(id), {
      ID: String(id),
      title,
      deleted,
      availableFrom: from ? new SimpleDate(from, 'YYYY-MM-DD') : null,
      orgGroupID: orgGroupID ? String(orgGroupID) : null,
      orgType: isOrgType(type) ? type as OrgType : ( // eslint-disable-line no-nested-ternary
        type === 'high_school' ? OrgType.HighSchool : OrgType.Unknown
      ),
    })
    return acc
  }, new Map<string, Org>(), values(orgs))
}

export default function useOrgsMapFromOrgs(orgs: OrgsSchools) {
  const res = useMemo(() => {
    return convert(orgs)
  }, [orgs])

  return res
}
