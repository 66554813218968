// @flow
import { useState, useCallback, useEffect } from 'react';

import { api } from 'app/api';
import { GET_REPORTS_DATA_ACCOUNTING_CUSTOMERS } from 'app/common/api/requests/reports/data';

import type {
  ReportsDataAccountingCustomersResult,
  Total,
  ReportTotal,
  ReportTotalOnDate,
  ReportSchool,
  ReportKlass,
  ReportEmployee,
} from 'app/common/api/requests/reports/data.accounting_customers';


export type {
  Total,
  ReportTotal,
  ReportTotalOnDate,
  ReportSchool,
  ReportKlass,
  ReportEmployee,
};

export type ReportData = ReportsDataAccountingCustomersResult | null

export default function useReportData(reportId: ?number): [ReportData, boolean] {
  const [reportData, setReportData] = useState<ReportData>(null);
  const [loading, setLoading] = useState(false);

  const requestItems = useCallback(async () => {
    if (!reportId) return;

    setLoading(true);
    const res = await api.request(GET_REPORTS_DATA_ACCOUNTING_CUSTOMERS, {
      error: 'Не удалось загрузить данные отчета',
      params: {
        report_id: reportId,
      },
    });

    if (res) {
      setReportData(res);
    }
    setLoading(false);
  }, [reportId]);


  useEffect(() => {
    requestItems();
  }, [requestItems]);

  return [reportData, loading];
}
