// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as org } from 'app/components/common/reports/filters/org';
import { flags as customerType } from 'app/components/common/reports/filters/customerType';
import { flags as studentStatus } from 'app/components/common/reports/filters/studentStatus';
import { flags as currentStateDate } from 'app/components/common/reports/filters/currentStateDate';
import { flags as balanceType } from 'app/components/common/reports/filters/balanceType';


const classActivity = (state: State) => {
  return (
    org.filterActive(state)
    && org.singleOrgSelected(state)
    && customerType.onlyStudentCustomerSelected(state)
    && (
      (studentStatus.studentStatusActive(state)
      && studentStatus.majorStudentStatusSelected(state))
      || !currentStateDate.currentStateRadioSelected(state)
    )
    && (
      !balanceType.filterActive(state)
      || balanceType.cafeteriaSelected(state)
    )
  );
};

export default classActivity;
