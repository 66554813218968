// @flow
import React from 'react';
import classNames from 'classnames/bind';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import IconLightCross from 'app/common/icons/IconLightCross';
import ImageClickable from 'app/common/ui/ImageClickable';
import IconSettings from 'app/common/icons/IconSettings';
import PlainSelect from 'app/common/ui/PlainSelect';
import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import Tooltip from 'app/common/ui/Tooltip';
import EditableProp from 'app/common/ui/EditableProp';
import ImagePrepare from 'app/common/components/ImagePrepare';
import type { DishesTableSettings } from 'app/components/Settings/SettingsGear/DishesGear';

import { CellLabel, Cell, Row } from 'app/common/components/Table';

import enchanceDish from './enchanceDish';
import styles from './Dish.scss';


// ----------------
// Универсальный компонет блюда для таблицы блюд
// Через контейнер задаются actions для работы с блюдом,
// определенные для конкретного случая использования
// ----------------
// TODO требует рефакторинга (container)
// TODO deprecated

const cx = classNames.bind(styles);

const Photo = branch(({ src }) => !src, renderNothing)('img');

export type DishItemProps = {
  id: number,
  title: string,
  photo: string,
  restrictions?: string,
  portion: number | string,
  cost: number | string,
  saleType?: string,
  category?: string,
};

type Props = {
  id: number,
  groupName: string,
  settings: DishesTableSettings,
  params: DishItemProps,
  types: Array<Object>,
  loading: boolean,
  toggleItem: (e: SyntheticEvent<HTMLElement>) => void,
  checked: boolean, // выделен (чекбокс и желтый фон)
  handleDelete: (e: SyntheticEvent<HTMLElement>) => void,
  handleEdit?: (e: SyntheticEvent<HTMLElement>) => void,
  handleSaveProp: (Object) => void,
  handleSaleType: (e: Object) => void,
  handleSaveImage: (e: SyntheticEvent<HTMLElement>) => void,
  handleDeleteImage: (e: SyntheticEvent<HTMLElement>) => void,
  loadingSaleType: boolean,
  locked?: boolean, // поля не редактируются
  lockPhoto?: boolean,
  selectable?: boolean, // чекбокс для выделения отсутствует
  success?: boolean, // выделен зеленым (только фон), сhecked не меняет цвет фона
  className?: string,
  classes?: Object,
  disableDishGear?: boolean,
};

const Dish = ({
  id,
  groupName = '',
  settings,
  types,
  params: {
    title,
    photo,
    restrictions,
    portion,
    cost,
    saleType,
    category,
  },
  loading,
  toggleItem,
  checked,
  handleDelete,
  handleEdit,
  handleSaveProp,
  handleSaleType,
  handleSaveImage,
  handleDeleteImage,
  loadingSaleType,
  locked,
  lockPhoto = false,
  selectable = true,
  success,
  className,
  classes = {},
  disableDishGear,
}: Props) => {
  const htmlFor = `${groupName}_${id}`;

  return (
    <Row loading={loading} className={cx(styles.content, { active: checked, success }, className)}>
      {selectable
        && <CellLabel htmlFor={htmlFor} className={cx(styles.cell, classes.checkbox)} width={50} grow={0}>
          <PlainCheckbox
            id={htmlFor}
            value={String(id)}
            onChange={toggleItem}
            checked={checked}
            skipLabel
          />
        </CellLabel>}

      <CellLabel htmlFor={htmlFor} grow={5} className={styles.cell}>
        {title}
      </CellLabel>

      {settings.photo
        && (lockPhoto
          ? <CellLabel htmlFor={htmlFor} alignMiddle grow={3} className={styles.cell}>
            <Photo src={photo} className={styles.photo} />
          </CellLabel>
          : <Cell alignMiddle grow={3} className={styles.cell}>
            <ImagePrepare
              onSubmit={handleSaveImage}
              rectMinSizeWidth={100}
              rectMinSizeHeight={133}
              rectProportion={0.75}
            >
              {handleFileOpen => (!photo
                ? <a href="open" onClick={handleFileOpen} className={styles.link}>
                  {'+ Добавить фото'}
                </a>
                : <ImageClickable
                  src={photo}
                  className={styles.photo}
                  onReplace={handleFileOpen}
                  onDelete={handleDeleteImage}
                />)}
            </ImagePrepare>
          </Cell>
        )}

      {settings.restrictions
        && <CellLabel htmlFor={htmlFor} className={styles.cell} alignMiddle grow={3}>
          {restrictions}
        </CellLabel>}

      {settings.portion
        && (locked
          ? <CellLabel htmlFor={htmlFor} alignMiddle grow={2} className={cx(styles.cell, classes.portion)}>
            {portion}
          </CellLabel>
          : <Cell alignMiddle grow={2} className={cx(styles.cell, classes.portion)}>
            <EditableProp
              right
              value={String(portion)}
              handleSave={handleSaveProp}
              name="portion"
            />
          </Cell>
        )}

      {settings.cost
        && (locked
          ? <CellLabel htmlFor={htmlFor} alignMiddle grow={2} className={cx(styles.cell, classes.cost)}>
            {cost}
          </CellLabel>
          : <Cell alignMiddle grow={2} className={cx(styles.cell, classes.cost)}>
            <EditableProp
              className={styles.wp}
              right
              value={String(cost)}
              handleSave={handleSaveProp}
              name="cost"
              disabled
            />
          </Cell>
        )}

      {settings.saleType
        && (locked
          ? <CellLabel htmlFor={htmlFor} grow={4} className={styles.cell}>
            {(types.find(({ id: cid }) => (cid === saleType)) || {}).title}
          </CellLabel>
          : <Cell grow={4} className={styles.cell}>
            <PlainSelect
              name="saleType"
              data={types}
              current={saleType}
              onChange={handleSaleType}
              disabled={loading || loadingSaleType || locked}
              className={styles.fixDD}
              hideErrorString
              uni
            />
          </Cell>
        )}


      {settings.category
        && <CellLabel htmlFor={htmlFor} grow={2} className={styles.cell}>
          {category}
        </CellLabel>}

      {settings.setup
        && (locked
          ? <CellLabel htmlFor={htmlFor} grow={1} className={cx(styles.cell, classes.setup)}>
            &nbsp;
          </CellLabel>
          : <Cell grow={1} className={cx(styles.cell, classes.setup)}>
            <div className={styles.links}>
              {handleEdit
                && !disableDishGear
                && <a href="setup" onClick={handleEdit}>
                  <Tooltip text="Настроить блюдо">
                    <IconSettings className={styles.iconSettings} />
                  </Tooltip>
                </a>}
              {handleDelete
                && <a href="delete" onClick={handleDelete}>
                  <Tooltip text="Удалить блюдо">
                    <IconLightCross className={styles.iconCross} />
                  </Tooltip>
                </a>}
            </div>
          </Cell>
        )}
    </Row>
  );
};

export default enchanceDish(Dish);
