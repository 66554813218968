import { Ref, useCallback, useState, ChangeEvent, ReactNode, useMemo } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import classNames from 'classnames/bind'

import styles from './h-scrollbars-w-shadow.scss'


const absolutelyHidden = { display: 'none' }
const renderNoScroll = () => <span style={absolutelyHidden} />
const createRenderView = ({ right, bottom }: { right: boolean, bottom: boolean }) => (
  ({ style, ...props }: any) => {
    const { marginRight, marginBottom } = style
    const finalStyle = { ...style }
    if (bottom && marginBottom === 0) { finalStyle.marginBottom = -100 }
    if (right && marginRight === 0) { finalStyle.marginRight = -100 }
    return <div style={finalStyle} {...props} />
  }
)

const cx = classNames.bind(styles)

type Props = {
  id?: string
  children: ReactNode
  className?: string
  style?: Object
  hideVerticalScrollBar?: boolean
  hideHorizontalScrollBar?: boolean
  reference?: Ref<Scrollbars>
  disableShadow?: boolean
  onScroll?: (e: ChangeEvent<HTMLElement>) => void
  onChangeContentSize?: (isContentBiggerContainer: boolean) => void
  // 2 фикса для FireFox, когда размер скрола определяется как 0
  // !!! увеличивает скролируемую область в противоположном измерении на 100
  // осторожно, в составных таблицах, когда скролинг управляется внешним елементом
  absolutelyHideHorizontal_FixFF?: boolean
  absolutelyHideVertical_FixFF?: boolean
}

const HScrollbarsWShadow = ({
  id,
  children,
  className,
  style,
  hideVerticalScrollBar,
  hideHorizontalScrollBar,
  reference,
  disableShadow,
  onScroll,
  onChangeContentSize,
  absolutelyHideHorizontal_FixFF,
  absolutelyHideVertical_FixFF,
}: Props) => {
  const [isLeftShadow, setLeftShadow] = useState(false)
  const [isRightShadow, setRightShadow] = useState(false)

  const handleUpdate = useCallback((values) => {
    // специально для onChangeContentSize
    if (onChangeContentSize) {
      if ((values.scrollWidth - 1) <= values.clientWidth) {
        onChangeContentSize(false)
      } else {
        onChangeContentSize(true)
      }
    }

    // начальная позиция
    if (values.left === 0) {
      if ((values.scrollWidth - 1) <= values.clientWidth) {
        // контент внутри скрола меньше или равен контейнеру
        if (isRightShadow) setRightShadow(false)
      } else {
        if (!isRightShadow) setRightShadow(true) // eslint-disable-line no-lonely-if
      }
      if (isLeftShadow) setLeftShadow(false)
      return
    }

    // остальные позиции
    let leftS = false
    let rightS = false
    if ((values.scrollWidth - 1) > values.clientWidth) {
      leftS = true
      rightS = true
      if (values.left === 0) {
        leftS = false
      } else if (values.left > 0.98) {
        rightS = false
      }
    }
    if (isLeftShadow !== leftS) setLeftShadow(leftS)
    if (isRightShadow !== rightS) setRightShadow(rightS)
  }, [isRightShadow, isLeftShadow, onChangeContentSize])

  const renderView = useMemo(() => {
    return createRenderView({
      right: !!absolutelyHideVertical_FixFF,
      bottom: !!absolutelyHideHorizontal_FixFF,
    })
  }, [absolutelyHideVertical_FixFF, absolutelyHideHorizontal_FixFF])

  return (
    <Scrollbars
      id={id}
      onScroll={onScroll as Function as React.UIEventHandler<any>}
      className={cx(className, { leftShadow: isLeftShadow, rightShadow: isRightShadow })}
      renderTrackHorizontal={hideHorizontalScrollBar ? renderNoScroll : undefined}
      renderTrackVertical={hideVerticalScrollBar ? renderNoScroll : undefined}
      renderView={renderView}
      style={style}
      ref={reference}
      onUpdate={disableShadow ? undefined : handleUpdate}
    >
      {children}
    </Scrollbars>
  )
}

export default HScrollbarsWShadow
