// @flow
import React, { type Node, useRef, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Scrollbars } from 'react-custom-scrollbars-2';
import classNames from 'classnames';

import styles from './Modal.scss';


type Props = {
  className?: string,
  children: Node,
  maxHeight?: number,
  scrollDownOnResize?: boolean,
}

const ModalContent = ({ className, children, maxHeight, scrollDownOnResize }: Props) => {
  const scrollbars = useRef<Scrollbars>(null);

  const onResizeContent = useCallback(() => {
    if (scrollbars.current && scrollDownOnResize) {
      scrollbars.current.scrollToBottom();
    }
  }, [scrollDownOnResize]);

  // наблюдение за изменением размера контента
  const observer = useRef<ResizeObserver>(new ResizeObserver(onResizeContent));
  const handleRefContent = useCallback((node) => {
    if (node) {
      observer.current.observe(node);
    }
  }, []);

  return (
    <Scrollbars
      ref={scrollbars}
      autoHeight
      autoHeightMin={10}
      autoHeightMax={maxHeight || 400}
    >
      <div
        ref={handleRefContent}
        className={classNames(styles.modalContent, className)}
      >
        {children}
      </div>
    </Scrollbars>
  );
};

export default ModalContent;
