import { OperationType } from 'app/dataTypes/reports/data'
import styles from './DualLineDataCell.scss'
import { LinkWithDetailsPurchases } from './LinkWithDetailsPurchases'
import { LinkWithDetailsSubsidies } from './LinkWithDetailsSubsidies'


type Props = {
  line1: string
  line2Link?: string
  date: string
  schoolID: string
  operationType?: OperationType
}

export const DualLineDataCell = ({ line1, line2Link, date, schoolID, operationType }: Props) => {
  return (
    <div>
      <div className={styles.line}>{line1}</div>
      {operationType === 'purchases'
        ? <LinkWithDetailsPurchases
          toggleButtonTitle={line2Link}
          date={date}
          schoolID={schoolID}
          linkClassName={styles.link}
        />
        : <LinkWithDetailsSubsidies
          toggleButtonTitle={line2Link}
          date={date}
          schoolID={schoolID}
          linkClassName={styles.link}
        />}
    </div>
  )
}
