// @flow
import { useCallback, useState, useEffect } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import reduce from 'ramda/es/reduce';
import { has } from 'ramda';


type Mapper = Array<{
  fromKey: string,
  errKey?: string,
  validate: (any) => boolean,
  text: string,
}>

type ValidateOptions = {
  [key: string]: any
}

type Errors = {
  [key: string]: string
}

export type ValidateFunc = (any) => boolean
const defaultErrors: Object = Object.freeze({});


export default function useErrors(errorsMapper: Mapper): [Errors, ValidateFunc, () => void] {
  const [errors, setErrors] = useState<Errors>(defaultErrors);

  const validate = useCallback((options: ValidateOptions) => {
    const errorsLocal: Errors = reduce((acc, { fromKey, validate: validate_, errKey, text }) => {
      if (!has(fromKey, options) || validate_(options[fromKey])) {
        return acc;
      }
      return {
        ...acc,
        [errKey || fromKey]: text,
      };
    }, {}, errorsMapper);

    setErrors(errorsLocal);
    return isEmpty(errorsLocal);
  }, [errorsMapper]);

  const reset = useCallback(() => {
    setErrors(defaultErrors);
  }, []);

  useEffect(() => {
    reset();
  }, [errorsMapper]); // eslint-disable-line react-hooks/exhaustive-deps

  return [errors, validate, reset];
}
