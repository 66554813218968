import React from 'react';
import { Switch, Route } from 'react-router';

import Admin from 'app/components/Settings/Admin';


const AdminPage = () => (<Switch>
  <Route path="/settings/admin" component={Admin} />
</Switch>);

export default AdminPage;
