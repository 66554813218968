// @flow
import React from 'react';
import { Switch, Route } from 'react-router';

import ReportPage from './ReportPage';
import ReportsRootPage from './ReportsRootPage';
import getBaseUrl from './getBaseUrl';


const rootUrl = getBaseUrl();
const baseUrlWithSection = getBaseUrl({ report: ':report' });
const baseUrlWithSectionAndReport = getBaseUrl({ report: ':report', subReport: ':subReport' });

const ReportsPageRoot = () => {
  return (
    <Switch>
      <Route path={rootUrl} component={ReportsRootPage} exact />
      <Route path={baseUrlWithSection} component={ReportPage} exact />
      <Route path={baseUrlWithSectionAndReport} component={ReportPage} exact />
    </Switch>
  );
};

export default ReportsPageRoot;
