// @flow
import path from 'ramda/es/path';
import prop from 'ramda/es/prop';

import api from 'app/actions/api';
import { PASS_SEND_TO_FILE } from '.';
import type { /* PassSendToType, */ ActiveType } from '.';

export const ACTIVATE_REQUEST = 'ACTIVATE_REQUEST';
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS';
export const ACTIVATE_FAILURE = 'ACTIVATE_FAILURE';

const activateRequest = payload => ({
  type: ACTIVATE_REQUEST,
  payload,
});

const activateSuccess = payload => ({
  type: ACTIVATE_SUCCESS,
  payload,
});

const activateFailure = payload => ({
  type: ACTIVATE_FAILURE,
  payload,
});

export const activate = (id: number | string, type: string/* PassSendToType */, act: ActiveType) => // возможно тут иногда не то что нужно передается во втором параметре
  async (dispatch: Function) => {
    dispatch(activateRequest({ id, type }));
    try {
      const res = await api(`food/employees/${id}/auth/password/${type}`, 'post', { responseType: 'arraybuffer' });
      if (type === PASS_SEND_TO_FILE) {
        const filename = 'activation.xlsx';
        const format = path(['headers', 'content-type'], res);
        const file = prop('data', res);

        const blob = new Blob([file], { type: format });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        const { body } = document;
        if (body) {
          body.appendChild(link);
          link.click();
          body.removeChild(link);
        }
      }
      dispatch(activateSuccess({ id, type }));
      return true;
    } catch (error) {
      dispatch(activateFailure({
        err: `Запрос ${act === 'activate' ? 'активации' : 'обновления'} не удался`,
        error,
      }));
      return false;
    }
  };
