// @flow
import React, { type Node, Fragment } from 'react';

import {
  useCopyColumnsWidths,
  useHover,
  useCopyContentWidths,
  useHoverByClass,
} from 'app/common/ui-components/table';

import PlainTooltip from 'app/common/ui-next/plain-tooltip';
import CSSSticky from 'app/common/ui-components/css-sticky';
import HScrollbarsWShadow, { useSyncScrolls } from 'app/common/ui-components/h-scrollbars-w-shadow';
import CellContentOverflow from 'app/components/Reports/common/CellContent/CellContentOverflow';

import type { Multiple } from '../useReportData';
import HeaderLeftContent from './HeaderLeftContent';
import HeaderMiddleContent from './HeaderMiddleContent';
import HeaderRightContent from './HeaderRightContent';
import MiddleLeftContent from './MiddleLeftContent';
import MiddleMiddleContent from './MiddleMiddleContent';
import MiddleRightContent from './MiddleRightContent';
import FooterLeftContent from './FooterLeftContent';
import FooterMiddleContent from './FooterMiddleContent';
import FooterRightContent from './FooterRightContent';

import styles from './DetailsMultiple.scss';


const sameColumnBase = 'column_';
const sameRowBase = 'index_';

type Props = {
  multiple: Multiple,
}

const DetailsMultiple = ({ multiple }: Props): Node => {
  const { dates, titles, totalsRows, totalsColumns, values } = multiple;

  const [refFrom, refTo] = useCopyColumnsWidths(/* updateKey */);
  const [topScrollerProps, middleScrollerProps, bottomScrollerProps, updateBottomScroll] = useSyncScrolls();
  const [elementSource, elementsTarget] = useCopyContentWidths(1, updateBottomScroll);

  const [tbodyRef, onMouseEnter, onMouseLeave] = useHover({
    numberOfChunks: 3,
    classNameBase: sameRowBase,
  });

  const [onCellMouseEnter, onCellMouseLeave] = useHoverByClass(sameColumnBase);

  return (
    <Fragment>
      <CSSSticky
        number={3}
        className={styles.stickableTableHeader}
        stickyClassName={styles.tableSticked}
        shadowClassName={styles.shadow}
      >
        <HeaderLeftContent className={styles.table__fixed1} />

        <div className={styles.hScrollableContent}>
          <HScrollbarsWShadow
            hideVerticalScrollBar
            hideHorizontalScrollBar
            absolutelyHideHorizontal_FixFF
            {...topScrollerProps}
          >
            <HeaderMiddleContent
              dates={dates}
              tableRef={elementsTarget}
              trRef={refTo}
              sameColumnBase={sameColumnBase}
              onCellMouseEnter={onCellMouseEnter}
              onCellMouseLeave={onCellMouseLeave}
            >
              {(content, fullContent) => (
                <PlainTooltip
                  className={styles.unwrappable}
                  text={fullContent}
                >
                  {content}
                </PlainTooltip>
              )}
            </HeaderMiddleContent>
          </HScrollbarsWShadow>
        </div>

        <HeaderRightContent className={styles.table__fixed2} />
      </CSSSticky>

      {/* Второй слой */}
      <div className={styles.content}>
        <MiddleLeftContent
          className={styles.table__fixed1}
          titles={titles}
          tbodyRef={tbodyRef}
          sameRowBase={sameRowBase}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {content => (
            <CellContentOverflow className={styles.cellSchoolName}>
              {content}
            </CellContentOverflow>
          )}
        </MiddleLeftContent>

        <div className={styles.hScrollableContent}>
          <HScrollbarsWShadow
            hideVerticalScrollBar
            hideHorizontalScrollBar
            absolutelyHideHorizontal_FixFF
            {...middleScrollerProps}
          >
            <MiddleMiddleContent
              className={styles.tableMain}
              dates={dates}
              titles={titles}
              totalsColumns={totalsColumns}
              tableRef={elementSource}
              trRef={refFrom}
              tbodyRef={tbodyRef}
              thClassName={styles.unwrappable}
              tdClassName={styles.unwrappable}
              sameRowBase={sameRowBase}
              sameColumnBase={sameColumnBase}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onCellMouseEnter={onCellMouseEnter}
              onCellMouseLeave={onCellMouseLeave}
            >
              {(rowIndex, colIndex) => values[rowIndex][colIndex]}
            </MiddleMiddleContent>
          </HScrollbarsWShadow>
        </div>

        <MiddleRightContent
          className={styles.table__fixed2}
          totalsRows={totalsRows}
          tbodyRef={tbodyRef}
          sameRowBase={sameRowBase}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {content => (
            <CellContentOverflow className={styles.cellTotal}>
              {content}
            </CellContentOverflow>
          )}
        </MiddleRightContent>
      </div>

      {/* Третий слой */}
      <CSSSticky
        number={-1}
        shadowClassName={styles.stickyBottom}
        className={styles.footer}
      >
        <div className={styles.content}>
          <FooterLeftContent className={styles.table__fixed1} />

          <div className={styles.hScrollableContent}>
            <HScrollbarsWShadow
              hideVerticalScrollBar
              {...bottomScrollerProps}
            >
              <FooterMiddleContent
                trClassName={styles.footerRow}
                totalsColumns={totalsColumns}
                tableRef={elementsTarget}
                trRef={refTo}
                sameColumnBase={sameColumnBase}
                onCellMouseEnter={onCellMouseEnter}
                onCellMouseLeave={onCellMouseLeave}
              >
                {content => content}
              </FooterMiddleContent>
            </HScrollbarsWShadow>
          </div>

          <FooterRightContent className={styles.table__fixed2} />
        </div>
      </CSSSticky>
    </Fragment>
  );
};

export default DetailsMultiple;
