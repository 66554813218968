import React from 'react';

const IconGear = props => (<svg viewBox="0 0 22 22" width="22px" height="22px" {...props}>
  <path d="M20.902,9.895c-0.032-0.281-0.358-0.491-0.642-0.491c-0.913,0-1.723-0.536-2.063-1.366
    	c-0.347-0.85-0.123-1.84,0.558-2.464c0.214-0.196,0.24-0.524,0.061-0.752c-0.468-0.594-0.998-1.129-1.579-1.594
    	c-0.227-0.183-0.56-0.157-0.758,0.061c-0.593,0.658-1.66,0.903-2.484,0.559c-0.857-0.361-1.398-1.23-1.346-2.163
    	c0.018-0.293-0.197-0.548-0.489-0.583c-0.746-0.086-1.496-0.089-2.242-0.006c-0.289,0.032-0.503,0.281-0.493,0.57
    	C9.457,2.59,8.909,3.444,8.06,3.792C7.244,4.125,6.185,3.882,5.593,3.23C5.397,3.016,5.07,2.989,4.84,3.167
    	c-0.597,0.469-1.14,1.005-1.612,1.594C3.044,4.99,3.072,5.322,3.289,5.518c0.693,0.628,0.917,1.627,0.557,2.487
    	C3.501,8.824,2.65,9.352,1.677,9.352c-0.316-0.01-0.541,0.202-0.576,0.489c-0.088,0.748-0.088,1.51-0.004,2.265
    	c0.031,0.282,0.367,0.491,0.653,0.491c0.868-0.022,1.701,0.515,2.051,1.364c0.349,0.851,0.124,1.841-0.558,2.465
    	c-0.213,0.197-0.24,0.522-0.061,0.751c0.463,0.589,0.994,1.126,1.576,1.595c0.229,0.185,0.561,0.158,0.759-0.06
    	c0.597-0.66,1.663-0.903,2.484-0.559c0.86,0.359,1.401,1.229,1.349,2.162c-0.018,0.293,0.197,0.549,0.488,0.582
    	c0.381,0.045,0.764,0.066,1.148,0.066c0.363,0,0.729-0.02,1.093-0.06c0.289-0.032,0.503-0.281,0.493-0.571
    	c-0.033-0.924,0.516-1.776,1.363-2.125c0.82-0.335,1.875-0.09,2.468,0.562c0.198,0.214,0.523,0.24,0.752,0.063
    	c0.597-0.467,1.139-1.003,1.613-1.594c0.184-0.228,0.157-0.561-0.06-0.758c-0.694-0.627-0.919-1.627-0.56-2.485
    	c0.339-0.808,1.157-1.351,2.038-1.351l0.124,0.004c0.286,0.023,0.55-0.197,0.584-0.49C20.986,11.411,20.987,10.648,20.902,9.895
    	L20.902,9.895z M11.015,14.346c-1.833,0-3.324-1.491-3.324-3.324c0-1.833,1.491-3.325,3.324-3.325c1.833,0,3.323,1.491,3.323,3.325
    	C14.338,12.854,12.849,14.346,11.015,14.346L11.015,14.346z M11.015,14.346"
  />
</svg>);

export default IconGear;
