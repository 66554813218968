// @flow
import { useCallback, useState } from 'react';
import prop from 'ramda/es/prop';

import { api } from 'app/api';
import { POST_ORG_GENERAL_SETTINGS_MASS } from 'app/common/api/requests/food/schools/general_settings';
import { defaultGeneralSettings } from 'app/dataProviders/org/generalSettingsOrg'

import type { GeneralSettings } from 'app/dataTypes/org/settings/GeneralSettings';


export const optionKey = 'disable_edit_reject_cafeteria_subsidies_period';

type State = {
  state: boolean,
  modified: boolean,
}

type Actions = {
  request: () => Promise<void>,
  submit: (orgIds?: Array<number>) => Promise<boolean>,
  change: (boolean) => void,
}

export default function useRejectSubsidiesPeriod(
  orgId: number,
  requestGeneralSettings: () => Promise<GeneralSettings | null>,
): [State, Actions] {
  const [state, setState] = useState < boolean > (prop(optionKey, defaultGeneralSettings));
  const [modified, setModified] = useState(false);


  const request = useCallback(async () => {
    const res = await requestGeneralSettings();
    if (res) {
      const value = prop(optionKey, res);
      if (typeof value === 'boolean') {
        setState(value);
        setModified(false);
      }
    }
  }, [requestGeneralSettings]);


  const submit = useCallback(async (overrideOrgIds?: Array<number>) => {
    const orgIds = overrideOrgIds || [orgId];
    const res = await api.request(POST_ORG_GENERAL_SETTINGS_MASS, {
      error: 'Не удалось сохранить настройки управления периодом неначисления дотаций',
      params: {
        schools_ids: orgIds.map(String),
        settings: {
          [optionKey]: state,
        },
      },
    });

    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true;
    }

    if (res) {
      setModified(false);
    }
    return !!res;
  }, [orgId, state]);


  const change = useCallback((newValue: boolean) => {
    setModified(true);
    setState(newValue);
  }, []);


  return [{
    state,
    modified,
  }, {
    request,
    submit,
    change,
  }];
}
