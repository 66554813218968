// @flow
import React, { type Node, useCallback, useState, useRef, useEffect } from 'react';
import cn from 'classnames/bind';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import PlainIcon, { iconTypes, colorTypes } from 'app/common/ui-next/plain-icon';

import { PlanogramItem } from '../_parts/planogram-data-class';
import styles from './planogram-item-controls.scss';


const cx = cn.bind(styles);

export type Props = {
  item: PlanogramItem,
  children: Node,
  className?: string,
  showControls?: boolean,
  enableSplit?: boolean,
  enableLeftMerge?: boolean,
  enableRightMerge?: boolean,
  onMouseEnter?: (id: string) => void,
  onMouseLeave?: (id: string) => void,
  onChangeItemWidth?: (
      action: 'mergeLeft' | 'mergeRight' | 'split',
      item: PlanogramItem
    ) => Promise<void>,
}

const PlanogramItemContols = ({
  item,
  children,
  className,
  showControls: show = true,
  enableSplit,
  enableLeftMerge,
  enableRightMerge,
  onMouseEnter,
  onMouseLeave,
  onChangeItemWidth,
}: Props): Node => {
  const { id } = item;

  const root = useRef(null);
  const visible = true;

  const [hasFocused, setHasFocused] = useState(false);
  const showControls = show || hasFocused;

  const hasFocusedElement = useCallback(() => {
    const rootDiv = root.current;
    if (rootDiv) {
      const focusedElement = document.activeElement;
      return !![...rootDiv.querySelectorAll('button')].find(el => (el === focusedElement));
    }
    return false;
  }, []);

  useEffect(() => {
    if (show) {
      setHasFocused(true);
      return;
    }
    if (!hasFocusedElement()) {
      setHasFocused(false);
    }
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleMouseEnter = useCallback(() => {
    if (onMouseEnter) {
      onMouseEnter(id);
    }
  }, [onMouseEnter, id]);

  const handleMouseLeave = useCallback(() => {
    if (onMouseLeave) {
      onMouseLeave(id);
    }
  }, [onMouseLeave, id]);

  const handleLeftButtonBlur = useCallback(() => {
    if (!enableRightMerge) {
      setHasFocused(false);
    }
  }, [enableRightMerge]);

  const handleButtonBlur = useCallback(() => {
    setHasFocused(false);
  }, []);


  const handleLeftButtonClick = useCallback(() => {
    if (onChangeItemWidth) {
      onChangeItemWidth('mergeLeft', item);
    }
  }, [onChangeItemWidth, item]);

  const handleRightButtonClick = useCallback(() => {
    if (onChangeItemWidth) {
      onChangeItemWidth('mergeRight', item);
    }
  }, [onChangeItemWidth, item]);

  const handleButtonClick = useCallback(() => {
    if (onChangeItemWidth) {
      onChangeItemWidth('split', item);
    }
  }, [onChangeItemWidth, item]);


  return (
    <div
      ref={root}
      className={cn(styles.root, className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}

      {enableLeftMerge
        && showControls
        && <PlainButtonAsLink
          className={cx(styles.bt, styles.btTopLeft, { visible })}
          linkClassName={styles.btContent}
          onClick={handleLeftButtonClick}
          onBlur={handleLeftButtonBlur}
        >
          <span className="visuallyHidden">
            {'Объединить с левой ячейкой'}
          </span>
          <PlainIcon
            className={styles.mirror}
            type={iconTypes.ADD_TO_RIGHT}
            colorType={colorTypes.COLOR_WHITE}
          />
        </PlainButtonAsLink>}

      {enableRightMerge
        && showControls
        && <PlainButtonAsLink
          className={cx(styles.bt, styles.btTopRight, { visible })}
          linkClassName={styles.btContent}
          onClick={handleRightButtonClick}
          onBlur={handleButtonBlur}
        >
          <span className="visuallyHidden">
            {'Объединить с правой ячейкой'}
          </span>
          <PlainIcon
            type={iconTypes.ADD_TO_RIGHT}
            colorType={colorTypes.COLOR_WHITE}
          />
        </PlainButtonAsLink>}

      {enableSplit
        && showControls
        && <PlainButtonAsLink
          className={cx(styles.bt, styles.btTop, { visible })}
          linkClassName={styles.btContent}
          onClick={handleButtonClick}
          onBlur={handleButtonBlur}
        >
          <span className="visuallyHidden">
            {'Разбить на 2 ячейки'}
          </span>
          <PlainIcon
            type={iconTypes.SPLIT}
            colorType={colorTypes.COLOR_WHITE}
          />
        </PlainButtonAsLink>}
    </div>
  );
};

export default PlanogramItemContols;
