// @flow
import React, { useMemo, Fragment } from 'react';
import prop from 'ramda/es/prop';

import toFixedValue from 'app/common/lib/toFixedValue';
import { SummaryItem, SummaryWrap } from 'app/components/common/reports/summary';

import type { Summary } from './useReportData';


type Props = {
  summary: Summary,
  loading?: boolean,
  currencySign: string,
}

const SummaryComponent = ({ summary, loading, currencySign }: Props) => {
  const [souces, main] = useMemo(() => {
    const { count, total: t, sources } = summary;

    const total = toFixedValue(t);
    const totalText = total ? `${toFixedValue(t)} ${currencySign}` : '';
    const countText = typeof count === 'number' ? String(count) : '';

    const row0 = sources.map(({ title, total, count }) => ([title, total, count]));
    const row = [
      ['Итого', totalText],
      ['Кол-во платежей', countText],
    ];

    return [row0, row.filter(prop(1))];
  }, [summary, currencySign]);

  return (
    <Fragment>
      <SummaryWrap>
        {souces.map(([title, value]) => (
          <SummaryItem
            key={title}
            title={title}
            value={value}
          />
        ))}
      </SummaryWrap>
      <SummaryWrap>
        {main.map(([title, value]) => (
          <SummaryItem
            key={title}
            title={title}
            value={value}
          />
        ))}
      </SummaryWrap>
    </Fragment>
  );
};

export default SummaryComponent;
