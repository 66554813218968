import { MouseEvent } from 'react'

import PlainModal, { PlainModalContent } from 'app/common/ui-next/plain-modal'
import ContrlolsBar from 'app/common/ui-next/controls-bar'
import PlainButton, { type PlainButtonProps } from 'app/common/ui-next/plain-button'

import styles from './plain-confirm-modal.scss'

/**
 *  Диалоговое окно подтверждения действия
 */

export type ButtonProps = Omit<PlainButtonProps, 'children'>

export type PlainConfirmModalProps = {
  messageText: string,
  cancelButtonTitle?: string,
  confirmButtonTitle?: string,
  confirmButtonProps?: ButtonProps,
  cancelButtonProps?: ButtonProps,
  onCancel: (e?: MouseEvent<HTMLElement>) => void,
  onConfirm: (e?: MouseEvent<HTMLElement>) => void | Promise<void>,
  critical?: boolean,
  reverseOrderButton?: boolean,
}

export const PlainConfirmModal = ({
  messageText,
  cancelButtonTitle = 'Отмена',
  confirmButtonTitle = 'ОК',
  confirmButtonProps,
  cancelButtonProps,
  onCancel,
  onConfirm,
  critical = false,
}: PlainConfirmModalProps) => {
  return (
    <PlainModal className={styles.root} show>
      <PlainModalContent>
        <div className={styles.messageText}>{messageText}</div>
        <ContrlolsBar middle>
          <PlainButton
            onClick={onCancel}
            outline
            {...cancelButtonProps}
          >
            {cancelButtonTitle}
          </PlainButton>

          <PlainButton
            onClick={onConfirm}
            danger={critical}
            {...confirmButtonProps}
          >
            {confirmButtonTitle}
          </PlainButton>
        </ContrlolsBar>
      </PlainModalContent>
    </PlainModal>
  )
}
