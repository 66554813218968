// @flow
import React from 'react';

import { Tr, Th } from 'app/common/ui-components/table';

import { CellRestriction, restrictions } from '../MultiLineCell';


const emptyProps = Object.freeze({});

type Props = {
  sortKey?: string,
  sortDirection?: 'up' | 'down',
  onSort?: (direction: "up" | "down", key: string) => void,
  hidden?: boolean,
}

const LeftColumnHead = ({ sortKey, sortDirection, onSort, hidden }: Props) => {
  const props = hidden ? emptyProps : {
    id: 'column_school_title',
    sortKey,
    sortDirection,
    onSortArrowClick: onSort,
    alignLeft: true,
  };

  return (
    <thead>
      <Tr
        hidden={hidden}
        header
      >
        <Th
          scope="col"
          sortDummy={hidden}
          {...props}
        >
          <CellRestriction type={restrictions.COMMON_PADDING_ARROW}>
            {'Школа'}
          </CellRestriction>
        </Th>
      </Tr>
    </thead>
  );
};

export default LeftColumnHead;
