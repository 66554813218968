// @flow
import { useMemo } from 'react';
import moment from 'moment-timezone';
import filter from 'ramda/es/filter';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import complement from 'ramda/es/complement';
import equals from 'ramda/es/equals';
import always from 'ramda/es/always';

import type { OrgsSchools, OrgItem } from './useOrgs';
import hasSingleTarget from './hasSingleTarget';

/**
 *  Извлекает массив актуальных заведений
 *  (не удаленных и подходящих по дате и если указан orgId заведения, то без этого заведения)
 *  Вариант для компонента-селектора
 */

type ActualOrg = {
  id: string,
  title: string,
  hasSingleTarget: boolean,
}

export type ActualOrgs = {
  [id: string]: ActualOrg
}

type Options = {
  orgId?: number,
  date?: string | null,
}

export default function useActualOrgs2Selector(orgs: OrgsSchools, options: Options) {
  const { orgId, date } = options;

  const res: ActualOrgs = useMemo(() => {
    const byOrgId = orgId ? complement(equals(orgId)) : always(true);

    const mDate = moment(date);
    const isValidDate = Boolean(date && mDate.isValid());
    const byDate = (isValidDate
      ? (from: string | null) => (from ? mDate.isAfter(from, 'day') : true)
      : always(true));

    return pipe(
      filter(({ deleted, from, id }: OrgItem) => (
        !deleted
          && byOrgId(id)
          && byDate(from)
      )),
      map((orgItem: OrgItem) => {
        const { id, title } = orgItem;

        return ({
          id: String(id),
          title,
          hasSingleTarget: hasSingleTarget(orgItem),
        });
      }),
    )(orgs);
  }, [orgs, orgId, date]);

  return res;
}
