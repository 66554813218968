import { connect } from 'react-redux';
import isEmpty from 'ramda/es/isEmpty';
import assoc from 'ramda/es/assoc';
import length from 'ramda/es/length';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';

import withPaging from 'app/common/containers/withPaging';
import withSortState from 'app/common/containers/withSortState';

import selectGroups from './selectGroups';
import selectActual from './selectActual';


const defaultSortKey = 'klassTitle';
const defaultSortDirection = 'down';

export default compose(
  withSortState(defaultSortDirection, defaultSortKey),

  connect(
    (state, props) => {
      const groups = selectGroups(state, props);
      const { balanceNegative, balanceZero, balancePositive } = props.settings;
      return ({
        groups,
        rows: state.settings.reports.rows,
        total: length(groups),
        settings: {
          klassTitle: true,
          customerType: true,
          count: true,
          pos: !balanceNegative && !balanceZero,
          neg: !balancePositive && !balanceZero,
          negPercents: !balanceNegative && !balanceZero && !balancePositive,
          total: !balanceNegative && !balancePositive && !balanceZero,
        },
      });
    },
  ),

  branch(
    ({ groups }) => isEmpty(groups),
    renderNothing,
  ),

  withPaging(),
  // setCurrentPage,
  // handlePage,
  // currentPage

  mapProps(props => assoc('groups', selectActual(props), props)),

  lifecycle({
    componentDidUpdate(prevProps) {
      const { rows, setCurrentPage } = this.props;
      if (rows !== prevProps.rows) {
        setCurrentPage(1);
      }
    },
  }),
);
