export type OrgsLimitsType = {
  cafeteriaLimitsReadonlyForSchools: boolean,
  maxCafeOrderMinus: number, // минусы хранятся по модулю
  maxCafeMinus: number,
  cafeDayLimit: number,
  maxCampCafeOrderMinus: number,
  splitStudentDayLimit: boolean
}

export type Params = {
  orgId: number,
}

export type ParamsPOST = {
  schoolsIds: Array<number | string>
  orgLimits: OrgsLimitsType
}

export enum OrgsLimitsID {
  cafeteriaLimitsReadonlyForSchools = 'cafeteriaLimitsReadonlyForSchools',
  maxCafeOrderMinus = 'maxCafeOrderMinus', // минусы хранятся по модулю
  maxCafeMinus = 'maxCafeMinus',
  cafeDayLimit = 'cafeDayLimit',
  maxCampCafeOrderMinus = 'maxCampCafeOrderMinus',
  resetPersonalLimits = 'resetPersonalLimits',
  splitStudentDayLimit = 'splitStudentDayLimit'
}

export enum OrgsLimitsTxt {
  cafeteriaLimitsReadonlyForSchools = 'Разрешить редактирование настроек лимитов в других кабинетах',
  maxCafeOrderMinus = 'Максимальный минус по заявкам', // минусы хранятся по модулю
  maxCafeMinus = 'Максимальный минус по личным продажам',
  cafeDayLimit = 'Ежедневный лимит расходов учащихся',
  maxCampCafeOrderMinus = 'Максимальный минус по заявкам',
  resetPersonalLimits = 'Сбросить личные лимиты',
  splitStudentDayLimit = 'Распределять лимит на все точки продаж'
}
