// @flow
import { map, reject } from 'ramda';

import type { Category, ParamsPUT } from 'app/dataTypes/vending/production/Categories.types';

import type { Request } from '../../../../index';


type ParamsURL = {
  categoryId: number | string,
}

type ParamsApi = {
  title?: string,
  index?: number,
  add_food_items?: Array<number>,
  remove_food_items?: Array<number>,
}

type ResultApi = {
  id: number,
  place_id: number,
  title: string,
  index: number | null,
  deleted: true,
  deletable: boolean,
  quantity: number,
}

export const convertParams = (params: ParamsPUT): [ParamsApi, ParamsURL] => {
  const {
    categoryId,
    title,
    index,
    addFoodItems,
    removeFoodItems,
  } = params;

  return [reject(k => (k === '' || k === undefined || k === null), {
    title,
    index,
    add_food_items: addFoodItems && map(parseInt, addFoodItems),
    remove_food_items: removeFoodItems && map(parseInt, removeFoodItems),
  }), {
    categoryId,
  }];
};

export const convertResult = (data: ResultApi): Category => {
  const {
    id,
    place_id: placeId,
    title,
    index,
    deleted,
    deletable,
    quantity,
  } = data;

  return {
    id: String(id),
    placeId: String(placeId),
    title,
    index,
    deleted,
    deletable,
    quantity,
  };
};

export const PUT_VENDING_ITEMS_CATEGORIES: Request<ParamsPUT, Category, ParamsURL> = [
  'data/vending/items/categories/{categoryId}',
  'put',
  ['data', 'data'],
];
