// @flow
import api from 'app/actions/api';
import type { HireFields } from 'app/reducers/employees/hireform';
import { EMPLOYEE_NOT_FOUND } from 'app/constants/employees/existing';

export const HIREFORM_CHANGE_FIELD = 'HIREFORM_CHANGE_FIELD';
export const HIREFORM_CLEAR = 'HIREFORM_CLEAR';
export const dateFormat: 'YYYY-MM-DD' = 'YYYY-MM-DD';

export const hireformChangeField = (payload: Object) => ({
  type: HIREFORM_CHANGE_FIELD,
  payload,
});

export const hireformClear = () => ({
  type: HIREFORM_CLEAR,
});

export const CHECK_EXIST_REQUEST = 'hireform/CHECK_EXIST_REQUEST';
export const CHECK_EXIST_SUCCESS = 'hireform/CHECK_EXIST_SUCCESS';
export const CHECK_EXIST_FAILURE = 'hireform/CHECK_EXIST_FAILURE';

const checkExistRequest = payload => ({
  type: CHECK_EXIST_REQUEST,
  payload,
});

const checkExistSuccess = payload => ({
  type: CHECK_EXIST_SUCCESS,
  payload,
});

const checkExistFailure = payload => ({
  type: CHECK_EXIST_FAILURE,
  payload,
});

type QueryType = 'full' | 'phone' | 'email' | 'name'; // name: only first and last name
type Params = {
  firstname: string,
  middlename: string,
  lastname: string,
  gender: 'male' | 'female',
  birthdate: number,
};

export const employeeCheckExist = (queryType: QueryType, params: Params) => async (dispatch: Function) => {
  if (queryType === 'full') {
    if (!params.firstname || !params.lastname || !params.middlename || !params.gender || !params.birthdate) {
      // если какого-то параметра не указано, то пропускаем
      return {
        result: 'success',
        status: EMPLOYEE_NOT_FOUND,
      };
    }
  }

  const sendParams = { ...params, query_type: queryType };
  dispatch(checkExistRequest(sendParams));
  try {
    const res = await api('food/check_employee_exists', 'get', { params: sendParams }) || {};
    dispatch(checkExistSuccess(res.data));
    return res.data;
  } catch (error) {
    dispatch(checkExistFailure({ err: 'Запрос проверки сотрудника не удался', error }));
    return false;
  }
};

export const dateToApiFormat = (date: string) => date;


export const HIRE_REQUEST = 'hireform/HIRE_REQUEST';
export const HIRE_SUCCESS = 'hireform/HIRE_SUCCESS';
export const HIRE_FAILURE = 'hireform/HIRE_FAILURE';

const hireRequest = payload => ({
  type: HIRE_REQUEST,
  payload,
});

const hireSuccess = payload => ({
  type: HIRE_SUCCESS,
  payload,
});

const hireFailure = payload => ({
  type: HIRE_FAILURE,
  payload,
});

export const hire = (formData: HireFields) => async (dispatch: Function) => {
  const data = {
    ...formData,
    firstname: (formData.firstname || '').trim(),
    middlename: (formData.middlename || '').trim(),
    lastname: (formData.lastname || '').trim(),
    phone: (formData.phone || '').replace(/\s+/g, ''),
    order_num: formData.order_num.trim(),
  };
  dispatch(hireRequest(data));
  try {
    const res = await api('food/employee/invite', 'post', { data });
    dispatch(hireSuccess(res.data));
    return true;
  } catch (error) {
    dispatch(hireFailure({ err: 'Запрос приема сотрудника не удался', error }));
    return false;
  }
};
