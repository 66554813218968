// @flow
import React from 'react';
import classNames from 'classnames';
import styles from './Tabs.scss';


type Props = {
  className?: string,
  children?: any,
}

const Tabs = ({ className, children }: Props) => (
  <div className={classNames(styles.root, className)}>
    {children}
  </div>
);

export default Tabs;
