// @flow
import type { Request } from '../../index';


export type CategoryItem = {
  id: number,
  title: string,
  deletable: boolean,
  dishes_count: number, // количество блюд в категории
  index: number // для упорядочивания
}

export type ItemCategoriesResult = {
  [id: string]: CategoryItem
}

type UrlParams = {
  orgId: number | string,
}

export const GET_ITEM_CATEGORIES: Request<
  void,
  ItemCategoriesResult,
  UrlParams
> = [
  'food/schools/{orgId}/item_categories',
  'get',
  ['data', 'categories'],
];
