import { ChangeEvent, ReactNode, useCallback, useRef, useState, useEffect } from 'react'

import PlainButton from 'app/common/ui-next/plain-button'
import useCallbackRef from 'app/common/hooks/useCallbackRef'

import styles from './report-filter-form.scss'


type Props = {
  id?: string
  submitButtonTitle?: string
  disabled?: boolean
  children: (pending: boolean) => ReactNode
  onSubmit?: (formData: FormData) => Promise<void>
  onDismount?: () => void
}

const ReportFilterForm = ({ id = 'filter_form', submitButtonTitle, disabled = false, children, onSubmit, onDismount }: Props) => {
  const [pending, setPending] = useState(false)

  const formRef = useRef(null)
  const onSubmitRef = useCallbackRef(onSubmit)

  const handleSubmit = useCallback(async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (e.target.id !== id) return
    if (formRef.current && onSubmitRef.current) {
      setPending(true)
      await onSubmitRef.current?.(new FormData(formRef.current))
      setPending(false)
    }
  }, [onSubmitRef, id])

  useEffect(() => () => {
    onDismount?.()
  }, [])

  return (
    <form onSubmit={handleSubmit} ref={formRef} id={id}>
      {children(pending)}
      <PlainButton type="submit" className={styles.submit} disabled={disabled || pending}>
        {submitButtonTitle || 'Сформировать'}
      </PlainButton>
    </form>
  )
}

export default ReportFilterForm
