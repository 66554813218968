// @flow
// @flow-runtime warn
import { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import toPairs from 'ramda/es/toPairs';
import pipe from 'ramda/es/pipe';
import map from 'ramda/es/map';
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import path from 'ramda/es/path';

import { api, requestsGeneral } from 'app/api';
import type { BalanceReservesRequestParams, BalanceReservesRequestResult } from 'app/common/api/general.types/cafe.balance.reserves';
import toFixedValue from 'app/common/lib/toFixedValue';


type ReserveItem = {
  id: string,
  createdAt: string,
  sumFromBalance: string,
  comment: string,
  canceledAt: string,
  status: number,
}

export type Reservations = $Rest<BalanceReservesRequestResult, { count: number }>

export default function useReservationList(params: BalanceReservesRequestParams) {
  const [reservations, setReservations] = useState<Reservations>({});
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleRequestReservations = useCallback(async () => {
    setLoading(true);
    const reservationsRes: BalanceReservesRequestResult | null = await api.request(requestsGeneral.Cafe_GET_balance_reserves, {
      error: 'Не удалось получить историю резервирования',
      params,
    });
    setLoading(false);
    if (reservationsRes) {
      const { count: c, ...others } = reservationsRes;
      setReservations(others);
      setCount(c);
      return true;
    }
    return false;
  }, [params]);

  useEffect(() => {
    handleRequestReservations();
  }, [handleRequestReservations]);

  const reservationsList: Array<ReserveItem> = useMemo(() => pipe(
    toPairs,
    sort(descend(path([1, 'created_at']))),
    map(([id, {
      created_at: createdAt,
      sum_from_balance: sumFromBalance = '',
      comment = '',
      canceled_at: canceledAt,
      status,
    }]) => ({
      id,
      createdAt: createdAt ? moment(createdAt).format('DD.MM.YYYY hh:mm') : '',
      sumFromBalance: toFixedValue(sumFromBalance),
      comment,
      canceledAt: canceledAt ? moment(canceledAt).format('DD.MM.YYYY hh:mm') : '',
      status,
    })),
  )(reservations), [reservations]);

  return [reservationsList, count, loading];
}
