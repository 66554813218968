// @flow
import { useEffect } from 'react';
import path from 'ramda/es/path';

import { useVersions } from 'app/dataProviders/VendingDataProvider';


export default function useVersionById(versionId: number): string {
  const [versions,, request] = useVersions();

  useEffect(() => {
    request();
  }, [request]);

  return path([String(versionId), 'title'], versions) || '';
}
