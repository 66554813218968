// @flow
import { useState, useCallback } from 'react';
import reduce from 'ramda/es/reduce';
import addIndex from 'ramda/es/addIndex';
import isEmpty from 'ramda/es/isEmpty';
import map from 'ramda/es/map';
import fromPairs from 'ramda/es/fromPairs';
import pipe from 'ramda/es/pipe';
import set from 'ramda/es/set';
import lensIndex from 'ramda/es/lensIndex';
import drop from 'ramda/es/drop';
import concat from 'ramda/es/concat';
import filter from 'ramda/es/filter';
import complement from 'ramda/es/complement';
import propEq from 'ramda/es/propEq';
import move from 'ramda/es/move';

import type { ItemCategoriesResult, CategoryItem as ApiCategoryItem } from 'app/common/api/requests/food/schools/item_categories';

import api2CategoryList, { prependTotal } from './api2CategoryList';
import type { CategoryListItem } from './CategoryListItem.type';


const reduceIndexed = addIndex(reduce);
type InvalidIndexes = Array<[number, number]>

type State = {
  categoryList: Array<CategoryListItem>
}

type Handlers = {
  initCategories: (ItemCategoriesResult) => Promise<void>,
  addCategories: (ItemCategoriesResult) => Promise<void>,
  deleteCategory: (categoryId: string) => Promise<void>,
  swapItems: (index1: number, index2: number) => Promise<void>,
  moveItem: (indexFrom: number, indexTo: number) => Promise<void>,
}

type Options = {
  onItemUpdateIndex: (itemId: number, newIndex: number) => Promise<boolean>,
  api2CategoryListItem: (ApiCategoryItem) => CategoryListItem,
}


export default function useCategoriesState({
  onItemUpdateIndex,
  api2CategoryListItem,
}: Options): [State, Handlers] {
  const [categoryList, setCategoryList] = useState<Array<CategoryListItem>>([]);
  const [saved, setSaved] = useState<Array<CategoryListItem> | null>(null);


  // проверка индексов
  const checkIndexes = useCallback((categories_: Array<CategoryListItem>): InvalidIndexes => {
    return reduceIndexed((acc, { id, index }: CategoryListItem, i) => {
      if (index !== i && i !== 0) { // игнорим в том числе элемент первый элемент ('Все')
        acc.push([id, i]);
      }
      return acc;
    }, [], categories_);
  }, []);


  // обновление невалидных индексов через апи
  const updateInvalidIndexes = useCallback(async (invalidIndexes: InvalidIndexes) => {
    if (isEmpty(invalidIndexes)) return;
    const indexesUpdated = await Promise.all(invalidIndexes.map(([id, i]) => onItemUpdateIndex(id, i)));
    if (isEmpty(indexesUpdated) && saved) {
      // неудачно, возвращаем состояние
      setCategoryList(saved);
      return;
    }
    const successfullUpdated = fromPairs(invalidIndexes.filter((_, i) => indexesUpdated[i]));
    setCategoryList(map((props) => {
      const { id } = props;
      return (
        successfullUpdated[id]
          ? { ...props, index: successfullUpdated[id] }
          : props
      );
    }));
  }, [onItemUpdateIndex, saved]);


  // Начальная инициализация категорий
  const initCategories = useCallback(async (categories_: ItemCategoriesResult) => {
    // -> сброс сохраненного состояния
    setSaved(null);
    // -> список
    const catList0 = api2CategoryList(categories_, api2CategoryListItem);
    // -> добавление элемента 'Все'
    const catList = prependTotal(api2CategoryListItem)(catList0);
    // -> в state
    setCategoryList(catList);
    // -> проверка на валидность индексов
    const invalidIndexes = await checkIndexes(catList);
    // -> переназначение неверных индексов
    await updateInvalidIndexes(invalidIndexes);
  }, [checkIndexes, updateInvalidIndexes, api2CategoryListItem]);


  // Добавление категорий
  const addCategories = useCallback(async (categories_: ItemCategoriesResult) => {
    // -> сброс сохраненного состояния
    setSaved(null);
    // -> список
    const catList0 = api2CategoryList(categories_, api2CategoryListItem);
    // -> добавление к списку из состояния и обновление элемента 'Все'
    const catList = pipe(
      drop(1), // del old 'Все'
      concat(catList0), // add new elements
      prependTotal(api2CategoryListItem), // add new 'Все'
    )(categoryList);
    // -> в state
    setCategoryList(catList);
    // -> проверка на валидность индексов
    const invalidIndexes = await checkIndexes(catList);
    // -> переназначение неверных индексов через api
    await updateInvalidIndexes(invalidIndexes);
  }, [categoryList, api2CategoryListItem, checkIndexes, updateInvalidIndexes]);


  const deleteCategory = useCallback(async (categoryId: string) => {
    // -> сброс сохраненного состояния
    setSaved(null);
    // ->  удаление из списка категории и обновление элемента 'Все'
    const catList = pipe(
      drop(1), // del old 'Все'
      filter(complement(propEq('id', categoryId))), // remove target item
      prependTotal(api2CategoryListItem), // add new 'Все'
    )(categoryList);
    // -> в state
    setCategoryList(catList);
    // -> проверка на валидность индексов
    const invalidIndexes = await checkIndexes(catList);
    // -> переназначение неверных индексов через api
    await updateInvalidIndexes(invalidIndexes);
  }, [categoryList, api2CategoryListItem, checkIndexes, updateInvalidIndexes]);


  // перемена мест 2х итемов с определенными индексами
  // не используется
  const swapItems = useCallback(async (index1: number, index2: number) => {
    if (index1 < 1 || index2 < 1 || index1 > categoryList.length - 1 || index2 > categoryList.length - 1) {
      // 0-й элемент не двигается и на 0е место нельзя поставить,
      // а так же эл-ты вне списка не существуют, а значит и не двигаются
      return;
    }
    // -> сохранение текущего состояния
    setSaved(categoryList);
    // -> swap
    const value1 = categoryList[index1];
    const value2 = categoryList[index2];
    const catList = pipe(
      set(lensIndex(index1), value2),
      set(lensIndex(index2), value1),
    )(categoryList);
    // -> в state
    setCategoryList(catList);
    // -> проверка на валидность индексов
    const invalidIndexes = await checkIndexes(catList);
    // -> переназначение неверных индексов
    await updateInvalidIndexes(invalidIndexes);
  }, [categoryList, checkIndexes, updateInvalidIndexes]);


  // движение элемента с индексом indexFrom на позицию indexTo
  const moveItem = useCallback(async (indexFrom: number, indexTo: number) => {
    if (
      indexFrom < 1
      || indexTo < 1
      || indexFrom > categoryList.length - 1
      || indexTo > categoryList.length - 1
    ) {
      // 0-й элемент не двигается и на 0е место нельзя поставить,
      // а так же эл-ты вне списка не существуют, а значит и не двигаются
      return;
    }
    // -> сохранение текущего состояния
    setSaved(categoryList);
    // -> move
    const catList = move(indexFrom, indexTo, categoryList);
    // -> в state
    setCategoryList(catList);
    // -> проверка на валидность индексов
    const invalidIndexes = await checkIndexes(catList);
    // -> переназначение неверных индексов
    await updateInvalidIndexes(invalidIndexes);
  }, [categoryList, checkIndexes, updateInvalidIndexes]);


  return [{
    categoryList,
  }, {
    initCategories,
    addCategories,
    deleteCategory,
    swapItems,
    moveItem,
  }];
}
