// @flow
import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector, shallowEqual } from 'react-redux';
import { path } from 'ramda';

import RowLayout, { /* Header, */HeaderContent, RowLayoutContent } from 'app/common/layouts/RowLayout';
import CSSSticky from 'app/common/ui-components/css-sticky';
import { useCurrencySign } from 'app/common/components-next/currency';
import * as orgsTypes from 'app/common/constants/schools/types';
import { type Settings, settingsKey } from 'app/reducers/settings/orgsOrders';

import OrgsOrdersTable from './OrgsOrdersTable';
import OrgsOrdersFooter from './OrgsOrdersFooter';
import FilterWindow from './filter/FilterWindow';
import useOrdersData from './useOrdersData';
import useOrgsOrdersTableItems from './useOrgsOrdersTableItems';
import FilterBar from './FilterBar';
import useOrgsOrdersPagination from './useOrgsOrdersPagination';
import OrgOrdersOrProductionTabs, { ordersSections } from './OrgOrdersOrProductionTabs';
import OrgsOrdersGear from './OrgsOrdersGear';
import useFilterParams from './useFilterParams';
import { useColumnsArrangedIds, useColumnsTitles, useSettingsTitles } from './columns';

import styles from './OrgsOrdersMain.scss';


const cx = classNames;

type Props = {
  orgId: number,
  orgType: $Values<typeof orgsTypes>,
  placeForGuests: boolean,
  foodModeEnable: boolean,
  baseUrl: string,
}

const OrgsOrdersMain = ({ orgId, placeForGuests, orgType, foodModeEnable, baseUrl }: Props) => {
  const currency = useCurrencySign();
  const titles = useColumnsTitles(currency, orgType);
  const settingsTitles = useSettingsTitles(orgType);

  const { limit, offset, currentPage, handlePage, resetPagination } = useOrgsOrdersPagination();
  const [search, setSearch] = useState<string>('');
  const settings: Settings = useSelector(path(['settings', settingsKey]), shallowEqual);

  const [columnIds, columnsIdsVisible] = useColumnsArrangedIds({
    settings,
    foodModeEnable,
    orgType,
    placeForGuests,
  });

  const filterParams = useFilterParams({
    limit,
    offset,
    id: search.length ? parseInt(search, 10) || 0 : null,
  }, foodModeEnable);

  const [{ orders, count, loading }] = useOrdersData(orgId, filterParams, resetPagination);

  const [filterActive, setFilterActive] = useState(false);
  const items = useOrgsOrdersTableItems(orders, orgType, currency);

  return (
    <RowLayout paddingfree>

      <CSSSticky
        number={3}
        className={styles.stickable}
        shadowClassName={styles.shadow}
        stickyClassName={styles.sticky}
      >
        <HeaderContent
          paddingfree
          className={cx(styles.headerContent, styles.topBorderOnSticky)}
        >
          <OrgOrdersOrProductionTabs
            className={styles.headerContentTabs}
            baseUrl={baseUrl}
            type={ordersSections.ORDERS}
          />
          {/* <Header>{'Заявки'}</Header> */}
        </HeaderContent>

        <RowLayoutContent className={styles.actionsBar}>
          <FilterBar
            onSearch={setSearch}
            onFilterToggle={setFilterActive}
            filterActive={filterActive}
          />
          <OrgsOrdersGear
            settingsTitles={settingsTitles}
            ids={columnIds}
          />
        </RowLayoutContent>
      </CSSSticky>

      {/* Окно с фильтром */}
      <FilterWindow
        onFilter={setFilterActive}
        filterActive={filterActive}
        foodModeEnable={foodModeEnable}
        loading={loading}
      />

      {/* Таблица с данными */}
      <OrgsOrdersTable
        orgId={orgId}
        items={items}
        loading={loading}
        titles={titles}
        columnIds={columnsIdsVisible}
      />

      {/* Подвал с пагинацией */}
      <OrgsOrdersFooter
        total={count}
        pageSize={limit}
        currentPage={currentPage}
        currentPageSize={items.length}
        onPage={handlePage}
      />

    </RowLayout>
  );
};

export default OrgsOrdersMain;
