// @flow
import React, { type Node, useState, useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';

import Checkbox from 'app/common/ui/Checkbox';
import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs';

import CopySettings from '../CopySettings';
import { useRequestsData } from './RequestsData';
import TimeRestrictionsGroup from './TimeRestrictionsGroup';
import styles from './TimeRestrictionsForm.scss';

/**
 *  Форма ограничений по времени
 */

const tabTitles = ['Первая смена', 'Вторая смена', 'Третья смена'];

type Props = {
  disabled: boolean,
  onlyOneRestrictGroup?: boolean,
  className?: String,
}

const TimeRestrictionsForm = ({ disabled: disabledExternal, onlyOneRestrictGroup, className }: Props): Node => {
  const {
    content: {
      timeRestrictions: {
        restrictions,
        enabled,
        loading,
        // error,
      },
      submitting,
    },
    request: {
      timeRestrictions: {
        request,
        submit,
      },
    },
    modify: {
      timeRestrictions: {
        updateTime,
        updateEnabled,
        updateDayMode,
      },
    },
  } = useRequestsData();

  const changeEnableLimits = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    updateEnabled(checked);
  }, [updateEnabled]);


  // запрос данных при монтировании
  useEffect(() => {
    request();
  }, [request]);


  const disabled = disabledExternal || loading || submitting;

  const [activeTabIndex, setActiveTabIndex] = useState(1);

  const actualRestrictions = useMemo(() => {
    return onlyOneRestrictGroup && restrictions[0] ? [restrictions[0]] : restrictions;
  }, [onlyOneRestrictGroup, restrictions]);

  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.enableLimitsContainer}>
        <Checkbox
          className={styles.enableLimitsCheckbox}
          value="enabled"
          label="Ограничить создание и редактирование заявок до:"
          disabled={disabled}
          checked={enabled}
          onChange={changeEnableLimits}
        />
        {!disabled
          && <CopySettings onSubmit={submit} />}
      </div>

      {enabled
        && actualRestrictions.length > 1
        && <Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
          {actualRestrictions.map((_, i) => (
            <Tab index={i + 1} key={i}>{tabTitles[i]}</Tab>
          ))}
        </Tabs>}

      {enabled
        && <TabPanels value={activeTabIndex}>
          {actualRestrictions.map((group, i) => (
            <TabPanel index={i + 1} key={i}>
              <TimeRestrictionsGroup
                disabled={!enabled || disabled}
                group={group}
                groupNumber={i}
                onUpdateTime={updateTime}
                onUpdateDayMode={updateDayMode}
              />
            </TabPanel>
          ))}
        </TabPanels>}
    </div>
  );
};

export default TimeRestrictionsForm;
