// @flow

export const errorsMapping = [{
  fromKey: 'date',
  validate: (date: string) => Boolean(date),
  text: 'Требуется правильная дата',
}, {
  fromKey: 'sellPointId',
  errKey: 'sellPoint',
  validate: (sellPointId: number) => Boolean(sellPointId),
  text: 'Выберите точку продаж',
}];

export type Errors = {
  date?: string,
  sellPoint?: string,
}
