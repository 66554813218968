import { test } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind'
import { CSSTransition } from 'react-transition-group'

import Link from 'app/common/lib/link'

import { type Fav } from './useFav'
import styles from './FavPanelView.scss'


const cx = classNames.bind(styles)

const transitions = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  enterDone: styles.enterDone,
  exit: styles.exit,
  exitActive: styles.exitActive,
  exitDone: styles.exitDone,
}

const timeout = {
  enter: 300,
  exit: 100,
}

type Props = {
  fav: Array<Fav>
  showConditionReg?: RegExp
  darkTheme?: boolean
}

const FavPanelView = ({ fav, showConditionReg, darkTheme }: Props) => {
  const [state, setState] = useState(false)
  const { pathname } = window.location
  const nodeRef = useRef(null)

  useEffect(() => {
    const showFav = !!fav.length && (!showConditionReg || test(showConditionReg, pathname))
    setState(showFav)
  }, [fav.length, showConditionReg, pathname])

  return (
    <CSSTransition
      in={state}
      classNames={transitions}
      nodeRef={nodeRef}
      timeout={timeout}
    >
      <div className={styles.root} ref={nodeRef}>
        <div className={styles.container}>
          {fav.map(({ link, title, active, props = {} }) => (
            <Link
              key={link}
              to={link}
              className={cx(styles.item, {
                active: active && !darkTheme,
                aciveLighten: active && darkTheme,
              })}
              {...props}
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
    </CSSTransition>
  )
}

export default FavPanelView
