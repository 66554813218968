// @flow
import { connect } from 'react-redux';
import type { HOC } from 'recompose';

import selectItems from './selectItems';


type Props = {
  title: string,
  items: Array<number>,
};

const enhanceInfoItem: HOC<*, Props> = connect(
  (state, props) => ({
    items: selectItems(state, props),
  }),
);

export default enhanceInfoItem;
