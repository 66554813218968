// @flow
import { useMemo } from 'react';
import pipe from 'ramda/es/pipe';
import addIndex from 'ramda/es/addIndex';
import filter from 'ramda/es/filter';
import cond from 'ramda/es/cond';
import propEq from 'ramda/es/propEq';
import always from 'ramda/es/always';
import identity from 'ramda/es/identity';
import join from 'ramda/es/join';
import intersperse from 'ramda/es/intersperse';
import update from 'ramda/es/update';
import T from 'ramda/es/T';


const filterIndexed = addIndex(filter);

type Options = {
  delimiter: string,
  delimiterLast?: string,
}

export default function useTargetsString(
  items: Array<string>,
  enabled: Array<boolean>,
  options: Options,
) {
  const { delimiter } = options;
  const delimiterLast = options.delimiterLast || delimiter;

  const targetString = useMemo(() => pipe(
    filterIndexed((item, i) => enabled[i]),
    cond([
      [propEq('length', 0), always('')],
      [propEq('length', 1), identity],
      [propEq('length', 2), join(delimiterLast)],
      [T, pipe(
        intersperse(delimiter),
        update(-2, delimiterLast),
        join(''),
      )],
    ]),
  )(items), [delimiter, delimiterLast, enabled, items]);

  return targetString;
}
