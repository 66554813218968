import { ChangeEvent, useCallback, useState } from 'react'

import useSubsidySettingsActions from 'app/dataProviders/subsidy/settings/hooks/useSubsidySettingsActions'
import useCallbackRef from 'app/common/hooks/useCallbackRef'
import usePopup from 'app/common/hooks/usePopup'
import useScrollToError from 'app/common/hooks/useScrollToError'
import { Settings } from 'app/dataTypes/subsidy/settings'


type Options = {
  allowanceID: string
  orgID: string
  formID: string
  onSuccess?: () => void
  onPending?: (pending: boolean) => void
  onCheckErrors: (formData: FormData) => boolean
  onExtractData: (formData: FormData) => Settings
}

export function useCategorySettingsSubmit({
  allowanceID,
  orgID,
  formID,
  onSuccess,
  onPending,
  onCheckErrors,
  onExtractData,
}: Options) {
  const { submit } = useSubsidySettingsActions({ orgID, allowanceID })

  const submitRef = useCallbackRef(submit)
  const [submitting, setSubmitting] = useState(false)

  const onSuccessRef = useCallbackRef(onSuccess)
  const onPendingRef = useCallbackRef(onPending)

  const popup = usePopup()
  const scrollToError = useScrollToError()

  const handleSubmit = useCallback(async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formEvent = e.target

    if (formID) {
      const { id } = formEvent
      if (id !== formID) return
    }

    const formData = new FormData(formEvent)
    formData.append('allowanceID', allowanceID)

    if (!onCheckErrors(formData)) {
      scrollToError()
      return
    }

    setSubmitting(true)
    onPendingRef.current?.(true)
    const res = await submitRef.current?.(onExtractData(formData))

    setSubmitting(false)
    onPendingRef.current?.(false)

    if (res) {
      popup(`Настройки категории «${allowanceID}» успешно сохранены`)
      onSuccessRef.current?.()
    }
  }, [
    allowanceID,
    formID,
    onCheckErrors,
    submitRef,
    onSuccessRef,
    onPendingRef,
    onExtractData,
    popup,
    scrollToError,
  ])

  return {
    submit: handleSubmit,
    submitting,
  }
}
