// @flow
export const EMPLOYEEFORM_ERROR = 'EMPLOYEEFORM_ERROR';
export const EMPLOYEEFORM_ERROR_CLEAR = 'EMPLOYEEFORM_ERROR_CLEAR';

export const employeeformError = (payload: Object) => ({
  type: EMPLOYEEFORM_ERROR,
  payload,
});

export const employeeformErrorClear = () => ({
  type: EMPLOYEEFORM_ERROR_CLEAR,
});
