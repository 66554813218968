// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';
import prop from 'ramda/es/prop';
import pipe from 'ramda/es/pipe';
import toString from 'ramda/es/toString';

import toFixedValue from 'app/common/lib/toFixedValue';

import type { BudgetRecord } from '../useReportData';
import { ids } from './columns';


export type ActualItem = {
  title: string,
  students_count: Array<string>,
  sum: Array<string>,
  total: Array<string>,
}

export default function useActualItems(items: Array<BudgetRecord>): Array<ActualItem> {
  const res = useMemo(() => {
    return map(({ title, entries }: BudgetRecord) => ({
      [ids.TITLE]: title,
      [ids.STUDENTS_COUNT]: map(pipe(prop('students_count'), toString), entries),
      [ids.SUM]: map(pipe(prop('sum'), toFixedValue), entries),
      [ids.TOTAL]: map(pipe(prop('total'), toFixedValue), entries),
    }), items);
  }, [items]);

  return res;
}
