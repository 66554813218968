import { useMemo } from 'react'

import { CompensationInfoByOrgID, CompensationInfoStatus } from 'app/dataTypes/compensation/info'


export function useCompensationStatusAll(data: CompensationInfoByOrgID) {
  const res = useMemo(() => {
    const statusesAll = {
      hasItemsToRecord: false,
      hasItemsToCancel: false,
    }
    Array.from(data, ([, { status }]) => {
      if (status === CompensationInfoStatus.Ready) {
        statusesAll.hasItemsToCancel = true
        return true
      }
      if (status !== CompensationInfoStatus.Pending) {
        statusesAll.hasItemsToRecord = true
      }
      return true
    })
    return statusesAll
  }, [data])

  return res
}
