// @flow
import fromPairs from 'ramda/es/fromPairs';
import evolve from 'ramda/es/evolve';
import isEmpty from 'ramda/es/isEmpty';
import filter from 'ramda/es/filter';
import pipe from 'ramda/es/pipe';
import has from 'ramda/es/has';


const onlyConvertionKeys = (convertion: Object) => filter(([key]) => has(key, convertion));

const appliedFilterConvert = (
  convertion: Object,
  source: Array<[string, Array<string>]>,
) => pipe(
  onlyConvertionKeys(convertion),
  fromPairs,
  evolve(convertion),
  filter(val => (val && !isEmpty(val))),
)(source);

export default appliedFilterConvert;
