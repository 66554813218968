import { useCallback } from 'react'

import PlainLabel from 'app/common/ui-next/plain-label'
import { ScheduleItemType, SupportScheduleType } from 'app/dataTypes/requisites'

import { ScheduleItemForm } from './form/SupportScheduleForm/ScheduleItemForm'
import { ChangeHandlerType } from './useData'


type Props = {
  value: SupportScheduleType
  errors: Record<string, string>
  loading?: boolean
  locked?: boolean
  onChange: ChangeHandlerType
}

export function SupportSchedule({ value, onChange, errors, loading, locked }: Props) {
  const convertValue = useCallback((keyValue: string | boolean) => {
    if (typeof keyValue === 'string' && keyValue.length === 0) {
      return ''
    } if (typeof keyValue === 'string') {
      return keyValue.replace(/\s/g, '')
    }
    return keyValue
  }, [])

  const supportScheduleHandler = useCallback(
    (
      formId: string,
      keyField: keyof ScheduleItemType,
      newValue: string | boolean,
      onCheckError?: boolean
    ) => {
      const resValue = convertValue(newValue)
      onChange(`supportSchedule.${formId}.${keyField}`, resValue, onCheckError)
    },
    [convertValue, onChange]
  )

  return (
    <div>
      <PlainLabel htmlFor="workingTime">{'Часы работы:'}</PlainLabel>
      <ScheduleItemForm
        formID="workday"
        label="ПН-ПТ"
        scheduleValue={value.workday}
        divLabel={10}
        disabled={locked}
        loading={loading}
        errors={errors}
        checkBoxLabel="Нет"
        onChange={supportScheduleHandler}
      />
      <ScheduleItemForm
        formID="lunch"
        label="Обед"
        scheduleValue={value.lunch}
        divLabel={10}
        disabled={locked}
        loading={loading}
        errors={errors}
        checkBoxLabel="Нет"
        onChange={supportScheduleHandler}
      />
      <ScheduleItemForm
        formID="saturday"
        label="СБ"
        scheduleValue={value.saturday}
        divLabel={10}
        disabled={locked}
        loading={loading}
        errors={errors}
        checkBoxLabel="Выходной"
        onChange={supportScheduleHandler}
      />
      <ScheduleItemForm
        formID="sunday"
        label="ВС"
        scheduleValue={value.sunday}
        divLabel={10}
        disabled={locked}
        loading={loading}
        errors={errors}
        checkBoxLabel="Выходной"
        onChange={supportScheduleHandler}
      />
    </div>
  )
}
