// @flow
import React, { useState, useCallback, Fragment, type Node } from 'react';
import isEmpty from 'ramda/es/isEmpty';
import all from 'ramda/es/all';
import equals from 'ramda/es/equals';

import usePopup from 'app/common/hooks/usePopup';
import MultipleSubmit from 'app/components/common/MultipleSubmit';
import { useOrgsData } from 'app/dataProviders/org/OrgsDataProvider';

/**
 *  Создание комплектов сразу в нескольких организациях
 */

type Props = {
  onSubmit: (orgId: number) => boolean | Promise<boolean>,
  onChangeSubmitState: (submitting: boolean) => void,
  onSuccess: () => void,
  children: ((e: SyntheticEvent<HTMLFormElement>) => void) => Node,
}

const MultipleCreateKit = ({
  onSubmit,
  onChangeSubmitState,
  onSuccess,
  children,
}: Props) => {
  const popup = usePopup();

  const [optionsModalDisplayed, setOptionsModalDisplayed] = useState(false);

  const [{ orgs, loading, currentOrg }] = useOrgsData();
  const { id: currentOrgId, title: currentOrgTitle } = currentOrg;


  const handleMassSubmitSettings = useCallback(async (orgIds: Array<number>) => {
    setOptionsModalDisplayed(false);

    onChangeSubmitState(true);
    const res = await Promise.all([currentOrgId, ...orgIds].map(id => onSubmit(id)));
    onChangeSubmitState(false);

    if (!isEmpty(res) && all(equals(true), res)) {
      onSuccess();
      popup('Комплект успешно создан');
    }
  }, [onSubmit, onChangeSubmitState, popup, currentOrgId, onSuccess]);


  const handleToggleOptionsModal = useCallback((e?: SyntheticEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }
    if (isEmpty(orgs)) {
      handleMassSubmitSettings([]);
      return;
    }
    setOptionsModalDisplayed(current => !current);
  }, [orgs, handleMassSubmitSettings]);


  return (
    <Fragment>
      {children(handleToggleOptionsModal)}
      {optionsModalDisplayed
        && <MultipleSubmit
          orgs={orgs}
          loading={loading}
          header="Создание комплекта"
          buttonOkTitle="Создать"
          buttonOkTitleSubmitting="Создается..."
          onClickOk={handleMassSubmitSettings}
          onClickCancel={handleToggleOptionsModal}
          checkboxSaveOnlyLabel={`Создать только в ${currentOrgTitle}`}
          checkboxRevealOrgsSelectorLabel="Создать во всех доступных вам заведениях типа ОУ"
        />}
    </Fragment>
  );
};

export default MultipleCreateKit;
