// @flow
import { useCallback, useState } from 'react';

import { PUT_EMPLOYEE } from 'app/common/api/requests/food/employee';
import { api } from 'app/api';
import usePopup from 'app/common/hooks/usePopup';


type Options = {
  personId: number,
  roleId: number | null,
  onSuccess?: () => void,
}

export default function useSubmitOfficeEmployee({ personId, roleId, onSuccess }: Options): [
  boolean,
  () => Promise<void>
] {
  const popup = usePopup();
  const [pending, setPending] = useState(false);

  const submit = useCallback(async () => {
    setPending(true);
    const res = await api.request(PUT_EMPLOYEE, {
      error: 'Сохранение должностной информации не удалось',
      params: {
        id: personId,
        role_id: roleId,
      },
    });
    setPending(false);

    if (res) {
      if (onSuccess) {
        onSuccess();
      }
      popup('Должностная информация успешно сохранена');
    }
  }, [roleId, personId, popup, onSuccess]);

  return [pending, submit];
}
