// @flow
import React, { useCallback } from 'react';

import { api } from 'app/api';
import { ReportsHistoryDefaultOptions } from 'app/common/ui-components/history-modal';
import { GET_SEND_FILE } from 'app/common/api/requests/reports/send_file';
import { GET_DOWNLOAD_FILE } from 'app/common/api/requests/reports/download_file';
import { GET_REPORTS_STATUS } from 'app/common/api/requests/reports/status';
import { formats } from 'app/common/api/requests/reports/generate_file';
import usePopup from 'app/common/hooks/usePopup';

import type { HistoryItem } from './convertApiHistoryItems';

/**
 *  Ячейка с кнопками загрузки и отправки по email в таблице истории
 */

type Props = {
  historyItem: HistoryItem,
}

const FileHistoryOptions = ({ historyItem }: Props) => {
  const { id, email, status } = historyItem;
  const reportId = parseInt(id, 10);

  const popup = usePopup();

  const sendFileToEmail = useCallback(async (reportId_: number, email_: string) => {
    const res = await api.request(GET_SEND_FILE, {
      error: 'Не удалось отправить файл',
      params: {
        report_id: reportId_,
        email: email_,
      },
    });

    if (res) {
      popup(`Файл успешно отправлен на почту: ${email_}`);
    }
  }, [popup]);


  const downloadFile = useCallback(async (reportId_: number) => {
    await api.download(GET_DOWNLOAD_FILE, {
      error: 'Не удалось загрузить файл',
      filename: `orders_production_${reportId_}.${formats.XLSX}`,
      params: {
        report_id: reportId_,
      },
    });
  }, []);


  const checkStatus = useCallback(async (reportId_: number) => {
    const res = await api.request(GET_REPORTS_STATUS, {
      params: {
        report_id: reportId_,
      },
    });

    if (typeof res === 'number') {
      return res;
    }
    return null;
  }, []);

  return (
    <ReportsHistoryDefaultOptions
      reportId={reportId}
      email={email}
      status={status}
      onSendFileToEmail={sendFileToEmail}
      onDownloadFile={downloadFile}
      onCheckStatus={checkStatus}
    />
  );
};

export default FileHistoryOptions;
