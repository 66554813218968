import { createSelector } from 'reselect'
import path from 'ramda/es/path'


const selectKit = createSelector(
  [
    (state, { kitId }) => path(['orgs', 'kits', 'items', kitId], state) || {},
  ],
  ({
    grocery_set_price: grocerySetPrice = '',
    keep_grocery_set_price: keepGrocerySetPrice = false,
  }) => ({
    grocerySetPrice,
    keepGrocerySetPrice,
  })
)

export default selectKit
