import { useState, useCallback } from 'react'
import moment from 'moment-timezone'
import { keys } from 'ramda'

import DaysGrid from './days-grid'
import WeekdaysGrid from './weekdays-grid'
import MonthSwitcher from './month-switcher'
import type { DaysState } from './use-days-slected-state'
import { dateFormat as dateFormatDefault } from './date-format'

import styles from './plain-calendar.scss'

/**
 *  Компонент календаря
 */

export type PlainCalendarProps = {
  className?: string,
  daysSelected?: DaysState,
  dateFormat?: string,
  markedDatesFrom?: string,
  markedDatesTo?: string,
  hideYear?: boolean,
  weekSelection?: boolean,
  onDayClick: (date: string, format: string) => void,
  dateDisabledStatus?: (dayFormatted: string, format: string, unit?: string) => boolean,
}

const PlainCalendar = ({
  className,
  daysSelected = {},
  dateFormat = dateFormatDefault,
  markedDatesFrom,
  markedDatesTo,
  hideYear,
  weekSelection,
  onDayClick,
  dateDisabledStatus,
}: PlainCalendarProps) => {
  const [monthDate, setMonthDate] = useState(
    keys(daysSelected)[0]
    || moment().format(dateFormat),
  )

  const handlePrevMonth = useCallback(() => {
    setMonthDate(date => moment(date, dateFormat)
      .subtract(1, 'month')
      .format(dateFormat))
  }, [dateFormat])

  const handleNextMonth = useCallback(() => {
    setMonthDate(date => moment(date, dateFormat)
      .add(1, 'month')
      .format(dateFormat))
  }, [dateFormat])

  const handleMonth = useCallback((newMonth: number) => {
    setMonthDate(date => moment(date, dateFormat)
      .month(newMonth)
      .format(dateFormat))
  }, [dateFormat])

  const handleYear = useCallback((newYear: number) => {
    setMonthDate(date => moment(date, dateFormat)
      .year(newYear)
      .format(dateFormat))
  }, [dateFormat])

  return (
    <div className={className}>
      <MonthSwitcher
        monthDate={monthDate}
        dateFormat={dateFormat}
        onPrev={handlePrevMonth}
        onNext={handleNextMonth}
        onMonth={handleMonth}
        onYear={handleYear}
        dateDisabledStatus={dateDisabledStatus}
        hideYear={hideYear}
      />
      <WeekdaysGrid className={styles.weekdays} />
      <DaysGrid
        className={styles.days}
        daysSelected={daysSelected}
        onClickDay={onDayClick}
        monthDate={monthDate}
        dateFormat={dateFormat}
        dateDisabledStatus={dateDisabledStatus}
        markedDatesFrom={markedDatesFrom}
        markedDatesTo={markedDatesTo}
        weekSelection={weekSelection}
      />
    </div>
  )
}

export default PlainCalendar
