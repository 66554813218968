// @flow
import React from 'react';
import classNames from 'classnames';

import kg from 'app/common/images/flag-kg.png';

import styles from './flags.scss';


type Props = {
  className?: string,
}

const FlagKg = ({ className }: Props) => (
  <div className={classNames(styles.root, className)}>
    <img src={kg} className={styles.kg} alt="flag_kg" />
  </div>
);

export default FlagKg;
