// @flow
import { useMemo } from 'react';
import map from 'ramda/es/map';
import pipe from 'ramda/es/pipe';
import sortWith from 'ramda/es/sortWith';
import ascend from 'ramda/es/ascend';
import prop from 'ramda/es/prop';

import { type Sale } from 'app/common/api/requests/food/schools/cafeteria_orders';
import toFixedValue from 'app/common/lib/toFixedValue';


const hasValue = (value: any): boolean => (
  typeof value === 'string' || typeof value === 'number'
);

type Dish = {
  id: number,
  title: string,
  portion: string,
  index: number,
}

type Item = {
  id: number,
  title: string,
  cost: string,
  count: string,
  total: string,
  type: 'item' | 'complex',
  dishes?: Array<Dish>,
}

export default function useProductListTableData(data: Array<Sale>): Array<Item> {
  const res = useMemo(() => {
    return map(({ id, title, cost, count, complex_list: dishes, item_type: type }: Sale) => {
      const item: Item = {
        id,
        title: title || '',
        cost: hasValue(cost) ? toFixedValue(cost) : '—',
        count: hasValue(count) ? String(count) : '—',
        total: toFixedValue((cost || 0) * (count || 1)),
        type,
      };

      if (type === 'complex' && dishes instanceof Array) {
        return ({
          ...item,
          dishes: pipe(
            sortWith([
              ascend(prop('index')),
              ascend(prop('title')),
            ]),
            map(({ title: t, portion, index }) => ({
              id,
              title: t || '',
              portion: portion || '',
              index,
            })),
          )(dishes),
        });
      }

      return item;
    }, data);
  }, [data]);

  return res;
}
