import HelperText from 'app/common/ui-next/plain-helper-text'


type Props = {
  items: Array<{ ID: string, title?: string }>
}

const LostItemsWarning = ({ items }: Props) => {
  return (
    <HelperText overflowVisible>
      {'Некоторые комплекты теперь отсутвуют в списке и будут удалены из выбранных после сохранения формы: '}
      <strong>
        {items.map(({ ID, title }) => title || ID).join(', ')}
      </strong>
    </HelperText>
  )
}

export default LostItemsWarning
