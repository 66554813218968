// @flow
import type { Request } from '../../../index';


export type Params = {
  search?: string,
  city_id?: Array<number>,
  district_id?: Array<number>,
  school_id?: Array<number>,
  model_id?: Array<number>,
  version_id?: Array<number>,
  sort_by_school?: boolean,
  limit?: number,
  offset?: number,
}

export type Machine = {
  id: number,
  title: string,
  number: string,
  model_id: number,
  version_id: number,
  school_id: number,
  place_id: number,
  deleted: null | boolean,
  photo_url: string,
}

export type Meta = {
  available: number,
  limit: number,
  offset: number,
  selected: number,
}

export type Result = {
  data: Array<Machine>,
  meta: Meta,
  result?: 'success',
}

export const defaultVendingMachines: Result = {
  data: [],
  meta: {
    available: 0,
    limit: 1000,
    offset: 0,
    selected: 0,
  },
};

export const GET_VENDING_MACHINES: Request<Params, Result, void> = [
  'data/vending/machines',
  'get',
  ['data'],
];

type UrlParams = {
  machineId: number | string,
}

export const GET_VENDING_MACHINE_SINGLE: Request<void, Machine, UrlParams> = [
  'data/vending/machines/{machineId}',
  'get',
  ['data', 'data'],
];
