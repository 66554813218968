import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import history from 'app/history';


// type Props = {
//   onSubmit: (value: string) => Promise<boolean>,
//   error?: any,
//   returnPath: string,
//   resetError: (error: string) => void,
// }

export default compose(

  withState('value', 'setValue', ''),
  withState('saving', 'setSaving', false),

  withHandlers({
    handleChange: ({ setValue, error, resetError }) => (e) => {
      const { value } = e.currentTarget;
      setValue(value);
      if (error) resetError('');
    },

    handleSubmit: ({ onSubmit, value, setSaving, returnPath }) => async (e) => {
      e.preventDefault();
      if (!onSubmit) return;
      setSaving(true);
      const res = await onSubmit(value);
      setSaving(false);
      if (res) {
        history.push(returnPath);
      }
    },
  }),
);
