// @flow
import React, { type Node, useCallback } from 'react';
import { any, range, propEq } from 'ramda';
import { useSet } from 'react-use';

import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

import usePending from './use-pending';
import { NumberStatic } from './number-input';
import MaxGoodsControl from './max-goods-control';
import useReplacedItemsInProcess from './use-replaced-items-in-process';
import useChangeItemWidth from './use-change-item-width';
import useDragEnd from './use-drag-end';
import PlanogramItemControls, { type Props as ControlsProps } from './planogram-item-controls';
import ItemTemplate from './item-template';
import Rulers from '../_parts/rulers';
import { type Theme, useTheme } from '../_parts/planogram-theme';
import { defaultTheme } from './default-theme';
import { PlanogramDataExt, PlanogramItem } from '../_parts/planogram-data-class';

import styles from './planogram.scss';


const emptyData = new PlanogramDataExt();
const defaultEmptyDataContent = <div>{'Планограмма: данные отсутствуют'}</div>;
const NoControls = ({ children }: ControlsProps) => <div>{children}</div>;

type EditOptions = {
  dragDisabled?: boolean,
}

const defaultEditOptions: EditOptions = {
  dragDisabled: false,
};

export type PlanogramProps = {
  className?: string,
  data?: PlanogramDataExt<PlanogramItem>,
  editMode?: boolean,
  editOptions?: EditOptions,
  onChangeData?: (Array<PlanogramItem>) => Promise<void> | void,
  theme?: Theme,
  children?: Node,
}

const Planogram = ({
  className,
  data = emptyData,
  editMode,
  editOptions = defaultEditOptions,
  onChangeData,
  theme = defaultTheme,
  children = defaultEmptyDataContent,
}: PlanogramProps): Node => {
  const [focused, focusedActions] = useSet();
  const [hovered, hoveredActions] = useSet();
  const [rowActive, { add: rowActiveAdd, reset: rowActiveReset }] = useSet();
  const [pending, { add: pendingAdd, remove: pendingRemove }] = usePending();
  const [replacedAdd, replacedRemove, getReplaced] = useReplacedItemsInProcess();

  const { dragDisabled } = { ...defaultEditOptions, ...editOptions };

  const handleChangeItem = useCallback(async (item) => {
    const { id } = item;
    if (onChangeData) {
      pendingAdd([id]);
      await onChangeData([item]);
      pendingRemove([id]);
    }
  }, [onChangeData, pendingAdd, pendingRemove]);

  const handleChangeItemWidth = useChangeItemWidth({
    data,
    onChangeData,
    pendingAdd,
    pendingRemove,
  });

  const handleDragStart = useCallback(({ source }) => {
    const { droppableId } = source;
    const rowIndex = parseInt(droppableId.replace(/\D+/, ''), 10);
    if (Number.isNaN(rowIndex)) return;
    rowActiveAdd(rowIndex);
  }, [rowActiveAdd]);

  const handleDragEnd = useDragEnd({
    data,
    onChangeData,
    pendingAdd,
    pendingRemove,
    replacedAdd,
    replacedRemove,
    rowActiveReset,
  });

  const containerWithThemeRef = useTheme(theme);

  if (data.empty) {
    return children;
  }

  const columnIndexes = range(0, data.columns);
  const rowIndexes = range(0, data.rows);

  return (
    <Rulers className={className} columns={data.columns} rows={data.rows} reference={containerWithThemeRef}>
      <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        {rowIndexes.map((rowIndex) => {
          const rowData = data.items[rowIndex];
          const rowHasNonEmptyItems = any(Boolean, data.contains[rowIndex]); // !!find(pipe(prop('goods'), Boolean), row); // !!!
          const rowLocked = rowActive.size && !rowActive.has(rowIndex);

          return (
            <Droppable key={rowIndex} droppableId={`row${rowIndex}`} direction="horizontal">
              {({ droppableProps, innerRef: droppableRef, placeholder }) => (
                <div {...droppableProps} ref={droppableRef} className={styles.row}>
                  {columnIndexes.map((columnIndex) => {
                    const item = (
                      getReplaced(rowIndex, columnIndex)
                      || data.findItemInRow(propEq('column', columnIndex), rowData)
                    );
                    if (!item || item === 'skip') return null;

                    const { id, maxGoods, width } = item;
                    const { amount } = data.contains[rowIndex][columnIndex];
                    const goods = amount || 0;
                    const itemHovered = hovered.has(id);
                    const itemFocused = focused.has(id);
                    const tinted = rowLocked || !!focused.size && !itemFocused;
                    const showControls = itemHovered && !tinted || itemFocused;
                    const pendingState = pending.has(id);
                    const editable = editMode && !tinted && !pendingState;

                    const Controls = editable ? PlanogramItemControls : NoControls;

                    return (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={data.findIndexInRow(propEq('column', columnIndex), rowData)}
                        isDragDisabled={rowHasNonEmptyItems || !editable || dragDisabled}
                      >
                        {({ innerRef: draggableRef, draggableProps, dragHandleProps }) => (
                          <div
                            ref={draggableRef}
                            {...draggableProps}
                            {...dragHandleProps}
                          >
                            <Controls
                              item={item}
                              enableSplit={data.canBeSplitted(columnIndex, rowIndex)}
                              enableLeftMerge={data.canBeMergedLeft(columnIndex, rowIndex)}
                              enableRightMerge={data.canBeMergedRight(columnIndex, rowIndex)}
                              showControls={showControls}
                              onMouseEnter={hoveredActions.add}
                              onMouseLeave={hoveredActions.remove}
                              onChangeItemWidth={handleChangeItemWidth}
                            >
                              <ItemTemplate
                                goods={goods}
                                bottomText={data.naming[rowIndex][columnIndex]}
                                doubleWidth={width === 2}
                                pending={pendingState}
                                tinted={tinted}
                                colored={editMode && goods > 0}
                              >
                                {editMode
                                  ? <MaxGoodsControl
                                    item={item}
                                    goods={goods}
                                    onChange={handleChangeItem}
                                    onBeginEdit={focusedActions.add}
                                    onStopEdit={focusedActions.reset}
                                  />
                                  : <NumberStatic value={maxGoods} />}
                              </ItemTemplate>
                            </Controls>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </DragDropContext>
    </Rulers>
  );
};

export default Planogram;
