// @flow
import moment from 'moment-timezone';

import { dateFormat } from 'app/common/api/requests/reports/perform';
import { getValue } from 'app/common/ui-components/filter';
import customerSearchFilter, { customerTypes as ctSearch } from 'app/components/common/reports/filters/customerSearch';
import { customerTypes as ctReport } from 'app/components/common/reports/filters/customerType';

import periodFilter, { dateFormat as dateFormatPeriod } from '../ReportFilter/filters/period';
import type { RequestParams } from './types';


const search2ReportMap: { [$Values<typeof ctSearch>]: $Values<typeof ctReport> } = {
  [ctSearch.Employee]: ctReport.EMPLOYEES,
  [ctSearch.Student]: ctReport.STUDENTS,
};

type State = { [key: string]: any }

const generateParamsByUser = (state: State): RequestParams => {
  const [customerId, customerType] = getValue(customerSearchFilter, state);
  const [from, to] = getValue(periodFilter, state);

  const limits = (
    customerType === ctSearch.Student ? {
      student_ids: [parseInt(customerId, 10)],
      customer_type: [search2ReportMap[customerType]],
    } : {
      employee_ids: [parseInt(customerId, 10)],
      customer_type: [search2ReportMap[customerType]],
    }
  );

  return {
    from: moment(from, dateFormatPeriod).format(dateFormat),
    to: moment(to, dateFormatPeriod).format(dateFormat),
    limits,
    customer_search: 'by_customer_data',
    details: true,
    details_group: 'by_purchases',
  };
};

export default generateParamsByUser;
