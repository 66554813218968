import { Fragment } from 'react'

import Subsidies from 'app/components/Settings/Subsidies'
import CSSSticky from 'app/common/ui-components/css-sticky'
import { rootRights, specificRights } from 'app/rights'
import { useRightsByPath } from 'app/rights/Rights'

import Navigation from '../Navigation'
import styles from './SubsidiesPage.scss'


const subsidiesRights = [rootRights.SETTINGS, specificRights.SUBSIDIES] as const

/**
 *  Страница Настройки/Дотации
 */

const SubsidiesPage = () => {
  const { write, read } = useRightsByPath({ pathTo: subsidiesRights })

  return (
    <Fragment>
      <CSSSticky // TODO replace to Sticky and inside Subsidies
        number={1}
        className={styles.stickableMenu}
      >
        <Navigation />
      </CSSSticky>

      {read && <Subsidies writeAccess={write} />}
    </Fragment>
  )
}

export default SubsidiesPage
