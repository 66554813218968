import { useState, useCallback, useEffect } from 'react'
import { reduce, isEmpty } from 'ramda'

import type { CampSubsidyOrdersState } from './useCampSubsidyOrders'


const emptyError = 'Значение не может быть пустым'
const alreadyExistError = 'Значение повторяется'
const formatError = 'Значение должно быть в диапазоне 1 до 100 включительно'

const count = (value: number, list: Array<number>) => (
  reduce((acc, val) => (val === value ? acc + 1 : acc), 0, list)
)

export type Errors = {
  [index: string]: string
}

const defaultErrors: Errors = {}

export default function useCampSubsidyOrdersErrors(
  ordersState: CampSubsidyOrdersState,
  modifyRef: Array<never>,
) {
  const [errors, setErrors] = useState<Errors>(defaultErrors)

  const checkErrors = useCallback(() => {
    if (!ordersState.camp_subsidy_orders_use_presets) return true
    let index = -1
    const someErrors: Errors = {}
    for (const value of ordersState.camp_subsidy_orders_preset_values) { // eslint-disable-line no-restricted-syntax
      index += 1
      if (Number.isNaN(value)) {
        someErrors[String(index)] = emptyError
      }
      if (count(value, ordersState.camp_subsidy_orders_preset_values) > 1) {
        someErrors[String(index)] = alreadyExistError
      }
      if (value < 1 || value > 100) {
        someErrors[String(index)] = formatError
      }
    }
    setErrors(someErrors)
    return isEmpty(someErrors)
  }, [ordersState])

  useEffect(() => {
    setErrors(defaultErrors)
  }, [modifyRef])

  return [errors, checkErrors] as const
}
