import {
  SALES_DATA_SUCCESS,
  SALES_DATA_CLEAR,
} from 'app/actions/reports/sales/data';

const defaultState = {
  summary: {},
  totals: [],
  time_mode: 'daily',
  // purchases: [],
  // schools: [],
  // groups: [],
  // items: [],
  // complexes: [],
};

const data = (state = defaultState, { type, payload }) => {
  if (type === SALES_DATA_SUCCESS) {
    return ({ ...defaultState, ...payload });
  }
  if (type === SALES_DATA_CLEAR) {
    return defaultState;
  }
  return state;
};

export default data;
