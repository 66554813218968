// @flow
import React, { useState } from 'react';

import PlainInput from 'app/common/ui-next/plain-input';
import PlainLabel from 'app/common/ui-next/plain-label';
import PlainButton from 'app/common/ui-next/plain-button';
import ControlsBar from 'app/common/ui-next/controls-bar';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import type { Customer } from 'app/dataTypes/reports/Customer.types';

import useActions from './use-actions';
import CustomerView from './customer-view';


export type CustomerSearchProps = {
  id: string,
  current: Array<string>,
  label: string,
  loading?: boolean,
  skipUpperSpace?: boolean,
  className?: string,
  onUpdate: (id: string, values: Array<string>) => void,
}

const CustomerSearch = ({
  id,
  label,
  loading,
  skipUpperSpace,
  className,
  onUpdate,
}: CustomerSearchProps) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [customer, setCustomer] = useState<Customer | null | 'not_found'>(null);

  const actionsParams = { searchPhrase, setSearchPhrase, setCustomer, onCustomer: onUpdate, id };
  const [pending, handleChangeSearchPhrase, handleKeyPress, request] = useActions(actionsParams);

  return (
    <div className={className}>
      <PlainLabel
        htmlFor={id}
        skipUpperSpace={skipUpperSpace}
        displayAsBlock
      >
        {label}
      </PlainLabel>
      <ControlsBar>
        <PlainInput
          id={id}
          value={searchPhrase}
          loading={loading}
          onChange={handleChangeSearchPhrase}
          onKeyDown={handleKeyPress}
          error={customer === 'not_found'}
          disabled={pending}
        />

        <PlainButton
          disabled={!searchPhrase || pending}
          onClick={request}
        >
          {pending ? 'Поиск...' : 'Найти'}
        </PlainButton>
      </ControlsBar>

      {customer === 'not_found'
        && <PlainHelperText error>
          {'Пользователь не найден'}
        </PlainHelperText>}

      {customer
        && customer !== 'not_found'
        && <CustomerView {...customer} />}
    </div>
  );
};

export default CustomerSearch;
