// @flow
import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';

import PlainCheckbox from 'app/common/ui-next/plain-checkbox';
import PlainFieldset from 'app/common/ui-next/plain-fieldset';
import PlainHelperText from 'app/common/ui-next/plain-helper-text';
import { allFoodModes, type FoodMode } from 'app/dataTypes/foodModes';

import CopySettings from '../CopySettings';
import { useRequestsData } from './RequestsData';

import styles from './GeneralSettingsForm.scss';

/**
 *  Часть формы отвечающая за опцию
 *  "Использовать режим питания в комплексном питании"
 */

const cx = classNames;

const names = {
  breakfast: 'Завтрак',
  dinner: 'Обед',
  afternoon: 'Полдник',
};

const useFoodModeDefaultModes = ['breakfast', 'dinner'];

type Props = {
  disabled: boolean,
  className?: String,
}

const GeneralSettingsForm = ({ disabled, className }: Props) => {
  const {
    content: {
      generalSettings: {
        state,
        error,
        loading,
      },
      submitting,
    },
    request: {
      generalSettings: {
        request,
        submit,
      },
    },
    modify: {
      generalSettings: {
        updateField,
      },
    },
  } = useRequestsData();

  const {
    use_food_mode: useFoodMode,
    food_modes: foodModes,
    one_order_per_day_mode: oneOrderPerDayMode,
    disable_past_days_purchases: disablePastDaysPurchases,
    disable_orders_for_5_to_11_parallels: disableOrdersFor_5To_11Parallels,
    use_qr_instead_of_barcode: useQrInsteadOfBarcode,
    display_date_time_and_place_of_printing_in_receipts: displayDateTimeAndPlaceOfPrintingInReceipts,
    // order_second_step_auto_select: orderSecondStepAutoSelect,
  } = state;

  useEffect(() => {
    request();
  }, [request]);

  const handleCheckboxChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget;
    if (value === 'use_food_mode') {
      updateField('use_food_mode', checked);
      updateField('food_modes', checked ? useFoodModeDefaultModes : []);
      return;
    }
    updateField(value, checked);
  }, [updateField]);

  const handleChangeFoodModes = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget;
    if (!allFoodModes.includes(value)) return;
    updateField(
      'food_modes',
      checked ? [...foodModes, ((value: any): FoodMode)] : foodModes.filter(key => (key !== value)),
    );
  }, [updateField, foodModes]);

  const submitFoodModes = useCallback((orgIds: Array<number>) => {
    return submit(orgIds, ['use_food_mode', 'food_modes']);
  }, [submit]);

  const submitOneOrderPerDay = useCallback((orgIds: Array<number>) => {
    return submit(orgIds, ['one_order_per_day_mode']);
  }, [submit]);

  const submitDisablePastDaysPurchases = useCallback((orgIds: Array<number>) => {
    return submit(orgIds, ['disable_past_days_purchases']);
  }, [submit]);

  const submitDisableOrdersFor_5To_11Parallels = useCallback((orgIds: Array<number>) => {
    return submit(orgIds, ['disable_orders_for_5_to_11_parallels']);
  }, [submit]);

  const submitUseQrInsteadOfBarcode = useCallback((orgIds: Array<number>) => {
    return submit(orgIds, ['use_qr_instead_of_barcode']);
  }, [submit]);

  const submitDisplayDateTimeAndPlaceOfPrintingInReceipts = useCallback((orgIds: Array<number>) => {
    return submit(orgIds, ['display_date_time_and_place_of_printing_in_receipts']);
  }, [submit]);
  // const submitOrderSecondStepAutoSelect = useCallback((orgIds: Array<number>) => {
  //   return submit(orgIds, ['order_second_step_auto_select']);
  // }, [submit]);

  const errorClassName = error ? `${styles.errorBorder} scrollToError` : '';


  return (
    <div className={className}>
      <div className={errorClassName}>
        <div className={styles.copySettingsContainer}>
          <PlainCheckbox
            value="use_food_mode"
            id="use_food_mode"
            label="Использовать режим питания в комплексном питании:"
            className={styles.useFoodMode}
            disabled={disabled || loading || submitting}
            checked={useFoodMode}
            onChange={handleCheckboxChange}
          />

          {!disabled
            && <CopySettings onSubmit={submitFoodModes} />}
        </div>

        <PlainFieldset
          ariaLabelledby="use_food_mode"
          className={styles.level2}
          name="food_modes"
        >
          {allFoodModes.map(foodMode => (
            <PlainCheckbox
              key={foodMode}
              label={names[foodMode]}
              id={foodMode}
              value={foodMode}
              checked={foodModes.includes(foodMode)}
              onChange={handleChangeFoodModes}
              disabled={disabled || !useFoodMode || loading || submitting}
            />
          ))}
        </PlainFieldset>
      </div>

      {error
        && <PlainHelperText error>
          {error}
        </PlainHelperText>}

      <div className={cx(styles.copySettingsContainer, styles.singleOptionContainer)}>
        <PlainCheckbox
          value="one_order_per_day_mode"
          id="one_order_per_day_mode"
          label="Создавать не больше одной заявки на каждый режим питания"
          disabled={disabled || loading || submitting}
          checked={oneOrderPerDayMode}
          onChange={handleCheckboxChange}
          className={styles.singleOption}
        />

        {!disabled
          && <CopySettings onSubmit={submitOneOrderPerDay} />}
      </div>

      <div className={cx(styles.copySettingsContainer, styles.singleOptionContainer)}>
        <PlainCheckbox
          value="disable_past_days_purchases"
          id="disable_past_days_purchases"
          label="Запретить создавать продажи за прошлые даты"
          disabled={disabled || loading || submitting}
          checked={disablePastDaysPurchases}
          onChange={handleCheckboxChange}
          className={styles.singleOption}
        />

        {!disabled
          && <CopySettings onSubmit={submitDisablePastDaysPurchases} />}
      </div>

      <div className={cx(styles.copySettingsContainer, styles.singleOptionContainer)}>
        <PlainCheckbox
          value="disable_orders_for_5_to_11_parallels"
          id="disable_orders_for_5_to_11_parallels"
          label="Отключить заявки для 5-11 классов (работает в совокупности с терминалами самообслуживания)"
          disabled={disabled || loading || submitting}
          checked={disableOrdersFor_5To_11Parallels}
          onChange={handleCheckboxChange}
          className={styles.singleOption}
        />

        {!disabled
          && <CopySettings onSubmit={submitDisableOrdersFor_5To_11Parallels} />}
      </div>

      <div className={cx(styles.copySettingsContainer, styles.singleOptionContainer)}>
        <PlainCheckbox
          value="use_qr_instead_of_barcode"
          id="use_qr_instead_of_barcode"
          label="Использовать QR-код вместо штрих кода (для чеков из терминалов самообслуживания)"
          disabled={disabled || loading || submitting}
          checked={useQrInsteadOfBarcode}
          onChange={handleCheckboxChange}
          className={styles.singleOption}
        />

        {!disabled
          && <CopySettings onSubmit={submitUseQrInsteadOfBarcode} />}
      </div>

      <div className={cx(styles.copySettingsContainer, styles.singleOptionContainer)}>
        <PlainCheckbox
          value="display_date_time_and_place_of_printing_in_receipts"
          id="display_date_time_and_place_of_printing_in_receipts"
          label="Отображать в чеках дату, время и место печати"
          disabled={disabled || loading || submitting}
          checked={displayDateTimeAndPlaceOfPrintingInReceipts}
          onChange={handleCheckboxChange}
          className={styles.singleOption}
        />

        {!disabled
          && <CopySettings onSubmit={submitDisplayDateTimeAndPlaceOfPrintingInReceipts} />}
      </div>

      {/* Временно отключено */}
      {/* <div className={cx(styles.copySettingsContainer, styles.singleOptionContainer)}>
        <PlainCheckbox
          value="order_second_step_auto_select"
          id="order_second_step_auto_select"
          label="Автоматический выбор комплектов на втором этапе создания заявок"
          disabled={disabled || loading || submitting}
          checked={orderSecondStepAutoSelect}
          onChange={handleCheckboxChange}
          className={styles.singleOption}
        />

        {!disabled
          && <CopySettings onSubmit={submitOrderSecondStepAutoSelect} />}
      </div> */}

    </div>
  );
};

export default GeneralSettingsForm;
