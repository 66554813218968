import { CompensationInfoError, CompensationInfoPending, CompensationInfoStatus } from 'app/dataTypes/compensation/info'


export const createCompensationInfoPending = (ID: string, title: string): CompensationInfoPending => ({
  orgID: ID,
  orgTitle: title,
  status: CompensationInfoStatus.Pending,
})

export const createCompensationInfoError = (ID: string, title: string): CompensationInfoError => ({
  orgID: ID,
  orgTitle: title,
  status: CompensationInfoStatus.Error,
})
