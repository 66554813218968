// @flow
import React from 'react';

import CommonPage from 'app/common/templates/CommonPage';
import About from 'app/common/components/About';


const AboutPage = () => (
  <CommonPage header="О приложении">
    <About />
  </CommonPage>
);

export default AboutPage;
