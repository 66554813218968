// @flow

const getCustomerName = (customer: string): string => {
  if (customer === 'Student') {
    return 'Учащийся';
  }
  if (customer === 'Employee') {
    return 'Сотрудник';
  }

  return '';
};

export default getCustomerName;
