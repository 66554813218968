// @flow
import React, { useCallback } from 'react';
import cn from 'classnames/bind';
import { values, reject, equals } from 'ramda';

import PlainFieldset from '../plain-fieldset';
import PlainCheckbox from './plain-checkbox';
import styles from './plain-checkbox-group.scss';


const cx = cn.bind(styles);

type Item = {
  id: string,
  title: string,
  level?: number,
}

export type Props = {
  id: string,
  className?: string,
  label?: string,
  current?: Array<string>,
  disabled?: boolean,
  keepPadding?: boolean,
  items: {
    [key: string]: Item,
  } | Array<Item>,
  skipUpperSpace?: boolean,
  onChange?: (string, boolean) => void,
  onValue?: (value: Array<string>, e: SyntheticEvent<HTMLInputElement>) => void,
  singleMustChecked?: boolean,
}

const PlainCheckboxGroup = ({
  id,
  className,
  label,
  current,
  disabled,
  keepPadding,
  items,
  skipUpperSpace,
  onChange,
  onValue,
  singleMustChecked,
}: Props) => {
  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget;
    if (onValue && current) {
      onValue(
        checked
          ? [...current, value]
          : reject(equals(value), current),
        e,
      );
    }
    if (onChange) {
      onChange(value, checked);
    }
  }, [onChange, onValue, current]);

  const theItems = items instanceof Array ? items : values(items);

  const renderItems = theItems.map(({ id: itemId, title, level }) => {
    const checked = !!current && current.includes(itemId);
    const disabledSpecial = singleMustChecked && current && current.length === 1 && checked;

    return (
      <PlainCheckbox
        key={itemId}
        label={title}
        value={itemId}
        name={id}
        disabled={disabled || disabledSpecial}
        onChange={handleChange}
        checked={checked}
        level={level}
      />
    );
  });

  if (label) {
    return (
      <PlainFieldset
        legend={`${label}:`}
        name={id}
        className={cn(styles.root, className)}
        legendClassName={styles.legend}
        skipUpperSpace={skipUpperSpace}
      >
        {renderItems}
      </PlainFieldset>
    );
  }

  return (
    <div className={cx(styles.rootUngroupped, { keepPadding, skipUpperSpace }, className)}>
      {renderItems}
    </div>
  );
};

export default PlainCheckboxGroup;
