import { useState, useCallback, useEffect, useMemo } from 'react'

import { api } from 'app/api'
import { GET_ORG_TYPES } from 'app/common/api/requests/food/school_types'


export type OrgsTypesState = [
  Array<string>,
  boolean,
  () => Promise<void> | void
]

export default function useOrgTypes(): OrgsTypesState {
  const [orgTypes, setOrgTypes] = useState<Array<string>>([])
  const [loading, setLoading] = useState<boolean>(true)

  const handleRequest = useCallback(async () => {
    setLoading(true)
    const res = await api.request(GET_ORG_TYPES, {
      error: 'Не удалось получить типы заведений',
    })

    if (res) {
      setOrgTypes(res as Array<string>)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    handleRequest()
  }, [handleRequest])


  const res = useMemo(() => ([
    orgTypes,
    loading,
    handleRequest,
  ] as OrgsTypesState), [orgTypes, loading, handleRequest])

  return res
}
