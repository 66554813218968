// @flow
import { createSelector } from 'reselect';

import getSignByCode from './get-sign-by-code';

/**
 *  Получение знака валюты в зависимости от настроек дилера
 *  !!! depreceted, используйте useCurrencySign там где возможно
 */

// $FlowFixMe
export default createSelector(
  state => state.dealer,
  ({ country_iso_code: iso = 'ru' }) => {
    return getSignByCode(iso);
  },
);
