// https://github.com/zxdong262/react-pagenav
// remastered and some errors corrected
/* eslint-disable */
import React from 'react';

type Props = {
  page: number,
  total: number,
  pageSize: number,
  maxLink: number,
  onLinkClick: Function,
  unitRender: Function,
  render: Function,
  lang: Object,
  createPageUrl: Function,
};

class ReactPagenav extends React.Component<Props> {
  static default = {
    page: 1,
    total: 0,
    pageSize: 10,
    prevHtml: '«',
    nextHtml: '»',
    prevSrHtml: 'Предыдущая страница',
    nextSrHtml: 'Следующая страница',
    dotsHtml: '...',
    createPageUrl(unit) {
      return unit.page === 1 ? '#' : `#p=${unit.page}`;
    },
    lang: {
      total: 'total',
    },
    buildUnits(props) {
      const option = ReactPagenav.default;
      let page = props.page || option.page;
      const pageSize = props.pageSize || option.pageSize;
      const total = props.total || option.total;
      const maxLink = props.maxLink > 5 ? props.maxLink : 5;

      const linksCount = Math.ceil(total / pageSize);

      if (page > linksCount) {
        page = linksCount + 0;
      }

      const hasPrev = page > 1;
      const hasNext = page < linksCount;
      const realMaxLink = maxLink > linksCount ? linksCount : maxLink;
      // let len1, len2, len3, shouldInsertDots12, shouldInsertDots23;
      // let len2Start, len3Start;

      const units = [];
      const arr = computeLens(); // eslint-disable-line

      units.push({
        class: hasPrev ? '' : 'disabled',
        page: hasPrev ? page - 1 : page,
        isPager: true,
        isPrev: true,
        isNext: false,
        html: option.prevHtml,
        srHtml: option.prevSrHtml,
        ariaLabel: option.prevSrHtml,
      });

      const dotUnit = {
        class: 'disabled',
        page,
        isPager: false,
        isPrev: false,
        isNext: true,
        html: option.dotsHtml,
      };

      for (let i = 0, len = arr.length; i < len; i += 1) {
        pushUnit(arr[i]); // eslint-disable-line
      }

      units.push({
        class: hasNext ? '' : 'disabled',
        page: hasNext ? page + 1 : page,
        isPager: true,
        isPrev: false,
        isNext: true,
        html: option.nextHtml,
        srHtml: option.nextSrHtml,
        ariaLabel: option.nextSrHtml,
      });

      function pushUnit(i) {
        if (typeof i === 'number') {
          units.push({
            page: i,
            isPrev: false,
            isPager: false,
            disabled: false,
            class: i === page ? 'active' : '',
            isNext: false,
            html: i,
          });
        } else {
          units.push(dotUnit);
        }
      }

      function computeLens() {
        const a4 = Math.floor((realMaxLink - 2) / 2);
        const a5 = realMaxLink - 3 - a4;
        let s2 = page - a4;
        const s3 = page + a5;
        if (s2 < 2) {
          s2 = 2;
        } else if (s3 > linksCount) {
          s2 = linksCount - (realMaxLink - 2);
        }
        const arr = [1]; // eslint-disable-line
        if (s2 > 2) {
          arr.push('dot');
        }
        let it;
        for (
          let i = 0, len = realMaxLink - 2 < 1 ? realMaxLink - 1 : realMaxLink - 2;
          i < len;
          i += 1
        ) {
          it = i + s2;
          arr.push(it);
        }
        if (it < linksCount - 1) arr.push('dot');
        if (it < linksCount) arr.push(linksCount);
        return arr;
      }

      return units;
      // end unit
    },
  }

  unitRender = (unit, index) => {
    const stats = {
      ...ReactPagenav.default,
      ...this.props,
    };
    const span = unit.isPager
      ? <span ariaHidden dangerouslySetInnerHTML={{ __html: unit.html }} />
      : <span dangerouslySetInnerHTML={{ __html: unit.html }} />;

    const sr = unit.isPager
      ? <span className="sr-only" dangerouslySetInnerHTML={{ __html: unit.srHtml }} />
      : null;

    const url = stats.createPageUrl(unit);

    return (
      <li key={index} className={`page-item ${unit.class}`}>
        <a
          onClick={this.handleClick.bind(this, unit.page, url)}
          className="page-link"
          href={url}
          aria-label={unit.ariaLabel}
        >
          {span}
          {sr}
        </a>
      </li>
    );
  }

  handleClick(page, url, e) {
    const { onLinkClick } = this.props;
    if (onLinkClick) {
      onLinkClick(page, url, e);
    }
  }

  render() {
    const { props } = this;
    const units = ReactPagenav.default.buildUnits(props);
    const lang = {
      ...ReactPagenav.default.lang,
      ...props.lang,
    };
    // let opts = {
    //   ...ReactPagenav.default,
    //   ...props,
    // };
    if (props.render) {
      return props.render(units, props);
    }
    const { total } = this.props;
    return (
      <nav className="zpagenav">
        <span className="pagination page-link m-r-1">
          {`${lang.total}: ${total}`}
        </span>
        <ul className="pagination">
          {units.map((props.unitRender || this.unitRender).bind(this))}
        </ul>
      </nav>
    );
  }
}

export default ReactPagenav;
