import PlainButton, { type PlainButtonProps } from './plain-button'
import PlainButtonFilterControl from '../plain-button-filter-control/plain-button-filter-control'


export default PlainButton

export type { PlainButtonProps }

export type Props = PlainButtonProps

export { PlainButtonFilterControl as OpenFilterButton } // deprecated, use PlainButtonFilterControl
