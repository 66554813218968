// @flow
import { prop } from 'ramda';

import { filterId } from './filterId';
import { commonBalanceTypes } from './commonBalanceItems';


type AppliedState = {
  [string]: Array<string>,
  ...
}

const getFromApplied = (applied: AppliedState): {
  type: $Values<typeof commonBalanceTypes>,
  min: number,
  max: number,
} => {
  const [type, min, max] = prop(filterId, applied) || [];

  return ({
    // $FlowFixMe
    type,
    min: parseFloat(min),
    max: parseFloat(max),
  });
};

export const getBooleansFromApplied = (applied: AppliedState): {
  balanceNegative: boolean,
  balancePositive: boolean,
  balanceZero: boolean,
} => {
  const { type, min, max } = getFromApplied(applied);

  return ({
    balanceNegative: ((
      type === commonBalanceTypes.BALANCE_NEGATIVE
        || type === commonBalanceTypes.BALANCE_USER
    ) && min < 0 && max < 0
    ),
    balancePositive: ((
      type === commonBalanceTypes.BALANCE_POSITIVE
        || type === commonBalanceTypes.BALANCE_USER
    ) && min >= 0 && max >= 0
    ),
    balanceZero: ((
      type === commonBalanceTypes.BALANCE_ZERO
        || type === commonBalanceTypes.BALANCE_USER
    ) && min === 0 && max === 0
    ),
  });
};

export default getFromApplied;
