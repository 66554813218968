// @flow
export default {

  // Склады
  GET_Storages: ['app/stock_locations', 'get', ['data', 'data']],
  POST_Storages: ['app/stock_locations', 'post', ['data', 'data']],
  PUT_Storages: ['app/stock_locations/{storageId}', 'put', ['data', 'data']],

  // Атрибуты [shop.types/attributes]
  GET_Attributes: ['app/prototypes', 'get', ['data', 'data']], // result: AttributesType
  POST_Attributes: ['app/prototypes', 'post', ['data', 'data']], // params: AttributeForSubmitType, result: AttributesType
  PUT_Attributes: ['app/prototypes/{attributeId}', 'put', ['data', 'data']], // params: AttributeForSubmitType, result: AttributeType

  // Категории
  GET_Categories: ['app/categories', 'get', ['data', 'data']],
  GET_Category: ['app/categories/{categoryId}', 'get', ['data', 'data']],
  POST_Category: ['app/categories', 'post', ['data', 'data']],
  PUT_Category: ['app/categories/{categoryId}', 'put', ['data', 'data']],
  DELETE_Category: ['app/categories/{categoryId}', 'delete', ['data', 'status']],
  POST_CategoryImage: ['app/categories/{categoryId}/category_images', 'post', ['data', 'data']],
  DELETE_CategoryImage: ['app/categories/{categoryId}/category_images/{imageId}', 'delete', ['data', 'data']],

  // Товары [shop.types/products]
  GET_Products: ['app/products', 'get', ['data']],

  // Товар [shop.types/product]
  GET_Product: ['app/products/{productId}', 'get', ['data', 'data']],
  POST_Product: ['app/products', 'post', ['data', 'data']],
  PUT_Product: ['app/products/{productId}', 'put', ['data', 'data']],
  DELETE_Product: ['app/products/{productId}', 'delete', ['data', 'status']],
  POST_ProductImage: ['app/products/{productId}/product_images', 'post', ['data', 'data']],
  PUT_ProductImage: ['app/products/{productId}/product_images/{imageId}', 'put', ['data', 'data']],
  DELETE_ProductImage: ['app/products/{productId}/product_images/{imageId}', 'delete', ['data', 'data']],

  // Похожие товары
  GET_SuggestProducts: ['app/products/suggest', 'get', ['data']],
};
