// @flow
import { type State } from 'app/common/ui-components/filter';

import { flags as org } from '../org';
import { flags as customerType } from '../customerType';


const classActivity = (state: State) => {
  return (
    org.filterActive(state)
    && org.singleOrgSelected(state)
    && customerType.filterActive(state)
    && customerType.onlyStudentCustomerSelected(state)
  );
};

export default classActivity;
