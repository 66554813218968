import React from 'react';

const IconChild = props => (<svg viewBox="0 0 20 20" width="20px" height="20px" {...props}>
  <path d="M4.146 10.63c0.548 0.966 1.351 1.844 2.283 2.492c1.113 0.774 2.347 1.185 3.566 1.188l0 0c0.002 0 0.004 0 0.005 0 c0.002 0 0.004 0 0.005 0l0 0c1.22-0.004 2.453-0.414 3.567-1.188c0.933-0.647 1.734-1.525 2.282-2.492 c0.538-0.032 0.979-0.251 1.285-0.638c0.304-0.385 0.458-0.909 0.458-1.56c0-0.784-0.242-1.436-0.665-1.861 c0 0-0.331-0.299-0.546-0.391c-0.35-1.334-1.142-2.583-2.25-3.54C12.909 1.583 11.408 1 9.909 1C8.416 1 6.945 1.586 5.77 2.65 C4.726 3.594 3.971 4.844 3.624 6.19c-0.21 0.09-0.396 0.22-0.559 0.382C2.645 6.998 2.402 7.649 2.402 8.433 c0 0.65 0.155 1.175 0.459 1.56C3.166 10.379 3.608 10.598 4.146 10.63z M4.104 6.95l0.221-0.058 c0.605-0.065 1.174-0.18 1.696-0.32C7.591 6.151 8.729 5.5 9.141 5.243c0.11-0.069 0.234-0.073 0.309 0.003 C9.623 5.42 9.843 5.798 9.438 6.404C9.424 6.421 9.411 6.44 9.399 6.459C9.398 6.461 9.397 6.462 9.396 6.464 c0 0-0.001 0.002-0.002 0.005c-0.016 0.028-0.025 0.06-0.031 0.093C9.359 6.571 9.357 6.576 9.357 6.577 c0.001 0 0.002 0.001 0.002 0.002C9.357 6.595 9.355 6.611 9.355 6.627c0 0.192 0.156 0.348 0.348 0.348 c0.017 0 0.032-0.002 0.048-0.004l0.001 0c1.309-0.196 2.021-1.208 2.386-2.029c0.078-0.175 0.23-0.152 0.321-0.037 c0.696 0.891 1.304 1.362 1.8 1.604c0.538 0.263 0.844 0.321 1.416 0.383l0.22 0.058c0.768 0.198 0.827 1.185 0.827 1.483 c0 0.88-0.34 1.326-1.01 1.326c-0.015 0-0.038-0.002-0.062-0.004l-0.313-0.023l-0.133 0.257c-0.854 1.657-2.847 3.44-5.205 3.443 c-2.358-0.003-4.352-1.786-5.206-3.443L4.661 9.732L4.351 9.755C4.327 9.757 4.304 9.759 4.289 9.759 c-0.67 0-1.011-0.446-1.011-1.326C3.278 8.135 3.339 7.148 4.104 6.95z" />
  <path d="M7.882 9.762c0.541 0 0.979-0.438 0.979-0.979c0-0.541-0.438-0.98-0.979-0.98s-0.979 0.439-0.979 0.98 C6.902 9.323 7.341 9.762 7.882 9.762z M8.082 8.401c0.277 0 0.502 0.226 0.502 0.502c0 0.278-0.225 0.503-0.502 0.503 S7.58 9.181 7.58 8.903C7.58 8.627 7.805 8.401 8.082 8.401z" />
  <path d="M12.118 9.762c0.542 0 0.98-0.439 0.98-0.979c0-0.541-0.439-0.98-0.98-0.98c-0.54 0-0.979 0.439-0.979 0.98 C11.14 9.324 11.578 9.762 12.118 9.762z M11.918 8.401c0.276 0 0.502 0.226 0.502 0.502c0 0.278-0.226 0.503-0.502 0.503 c-0.277 0-0.501-0.225-0.501-0.503C11.417 8.627 11.641 8.401 11.918 8.401z" />
  <path d="M13.929 14.577c-0.307 0-1.224 0.003-1.427 0.004c-0.004 0-0.007-0.001-0.011-0.001c-0.005 0-0.01 0.001-0.014 0.001 c-0.01 0-0.017 0-0.017 0l-0.001 0.001c-0.147 0.01-0.277 0.089-0.354 0.209h-0.001c0 0-0.004 0.009-0.013 0.023 c-0.001 0.001-0.002 0.003-0.003 0.004c-0.168 0.286-1.56 2.631-2.098 2.648c-0.537-0.018-1.931-2.362-2.098-2.648 c-0.001-0.001-0.001-0.003-0.002-0.004c-0.009-0.015-0.014-0.023-0.014-0.023H7.877c-0.075-0.12-0.204-0.199-0.353-0.209 l-0.002-0.001c0 0-0.006 0-0.017 0c-0.004 0-0.007-0.001-0.012-0.001s-0.008 0.001-0.012 0.001 c-0.203-0.001-1.12-0.004-1.426-0.004c-1.38 0-3.229 1.258-3.229 2.86s0 2.563 0 2.563h7.159h0.006h0.007h7.158 c0 0 0-0.96 0-2.563S15.308 14.577 13.929 14.577z" />
</svg>);

export default IconChild;
