import { useMemo } from 'react'


type Options<T> = {
  orgItems: Map<string, T>
  orgsGroupItems: Map<string, T>
  type: 'orgs' | 'orgGroups'
}

export default function useActualItemsList<T>({
  orgItems,
  orgsGroupItems,
  type,
}: Options<T>) {
  const res = useMemo(() => {
    return (
      type === 'orgGroups'
        ? Array.from(orgsGroupItems.values())
        : Array.from(orgItems.values())
    )
  }, [
    orgItems,
    orgsGroupItems,
    type,
  ])

  return res
}
