// @flow
import { useMemo } from 'react';
import filter from 'ramda/es/filter';
import anyPass from 'ramda/es/anyPass';
import propEq from 'ramda/es/propEq';

import { useCurrencySign } from 'app/common/components-next/currency';
import { saleTypes, type SaleType } from 'app/dataTypes/reports/saleTypes';

import type { ActualItem } from './useActualItems';


export type ColumnProps = {
  id: $Keys<ActualItem>,
  title: string,
  props: {
    alignLeft?: boolean,
    alignCenter?: boolean,
    alignRight?: boolean,
  },
}

const TITLE: 'title' = 'title';
const COMPLEX: 'complex' = 'complex';
const PERSONAL: 'personal' = 'personal';
const TOTAL: 'total' = 'total';

export const ids = {
  TITLE,
  COMPLEX,
  PERSONAL,
  TOTAL,
};

export function useColumns(sellType: null | SaleType): Array<ColumnProps> {
  const currencySign = useCurrencySign();

  const res: Array<ColumnProps> = useMemo(() => {
    const columns = [{
      id: TITLE,
      title: 'Заведение',
      props: {
        alignLeft: true,
      },
    }, {
      id: COMPLEX,
      title: `Списано за\u00A0компл. питание,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: PERSONAL,
      title: `Списано за\u00A0личные продажи,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }, {
      id: TOTAL,
      title: `Итого списано,\u00A0${currencySign}`,
      props: {
        alignRight: true,
      },
    }];

    if (sellType === saleTypes.Complex) {
      return filter(anyPass([propEq('id', TITLE), propEq('id', COMPLEX)]), columns);
    }
    if (sellType === saleTypes.Personal) {
      return filter(anyPass([propEq('id', TITLE), propEq('id', PERSONAL)]), columns);
    }
    return columns;
  }, [currencySign, sellType]);

  return res;
}
