import { useCallback } from 'react'
import { any } from 'ramda'

import { CompensationAction } from 'app/dataTypes/compensation/action'


export function useCompensationActionsAll(
  actionCb: (paramsLocal: Partial<CompensationAction>) => Promise<boolean>,
  selection: Set<string> | Array<string>,
  action: null | 'record' | 'cancel' | 'interrupt',
) {
  const actionAll = useCallback(async () => {
    if (!action) return false
    const array = Array.from(selection)
    const res = await Promise.all(array.map(ID => actionCb({ orgID: ID, action })))
    return any(Boolean, res)
  }, [actionCb, selection, action])

  return actionAll
}
