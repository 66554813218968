// @flow
import React, { type Node, type Ref } from 'react';
import cn from 'classnames/bind';

import PlainTooltip from 'app/common/ui-next/plain-tooltip';
import { iconTypes } from 'app/common/ui-next/plain-icon';

import Iconic from './Iconic';
import multiLineCellTypes from './multiLineCellTypes';
import styles from './Entry.scss';


export { iconTypes };

const cx = cn.bind(styles);

type Props = {
  children: Node,
  duty?: boolean,
  type?: $Values<typeof multiLineCellTypes>,
  dipper?: boolean,
  summary?: boolean,
  alignLeft?: boolean,
  alignRight?: boolean,
  borderLeft?: boolean,
  skipPadding?: boolean,
  iconic?: boolean,
  icon?: $Values<typeof iconTypes>,
  sameWidthAs?: string,
}

const Entry = ({
  children,
  duty,
  type = multiLineCellTypes.COMMON,
  dipper,
  summary,
  alignLeft,
  alignRight,
  borderLeft,
  skipPadding,
  iconic,
  icon,
  sameWidthAs,
}: Props) => {
  const classes = {
    padding: !skipPadding,
    commonType: type === multiLineCellTypes.COMMON,
    totalType: type === multiLineCellTypes.TOTAL,
    duty,
    dutyAbsent: duty === false,
    dipper,
    summary,
    alignLeft,
    alignRight,
    borderLeft,
  };

  const renderChildren = (
    iconic
      ? (<Iconic icon={icon} sameWidthAs={sameWidthAs}>
        {children}
      </Iconic>)
      : children
  );

  return (
    <PlainTooltip content={children} hideWithoutOverflow>
      {(ref: Ref<'any'>) => (<span className={cx(styles.root, classes)} ref={ref}>
        {renderChildren}
      </span>)}
    </PlainTooltip>
  );
};

export default Entry;
