import { SummaryItem } from 'app/common/templates-next/report-template/summary'
import { SummaryRow } from 'app/components/common/reports/summary'


type Props = {
  sum: string
  currency: string
  loading?: boolean
}

export const Summary = ({ sum, currency, loading }: Props) => {
  return (
    <SummaryRow baseline={false}>
      <SummaryItem
        key="CompensationInfoSummary"
        title="Общая сумма компенсаций"
        value={`${sum} ${currency}`}
        pending={loading}
      />
    </SummaryRow>
  )
}
