// @flow
import { getStateCurrentValue, type State, type StateByFilterId } from 'app/common/ui-components/filter';

import { filterId } from './filterId';


export const detailsChecked = (state: State): boolean => {
  const [checked] = getStateCurrentValue(filterId, state) || [];
  return !!checked;
};

export const detailsApplied = (applied: StateByFilterId | null): boolean => {
  if (applied && applied[filterId]) {
    return applied[filterId].includes('details');
  }
  return false;
};
