// @flow
import action from 'app/common/lib/action';


export const BALANCE_RESET_PARAMS = 'reports/BALANCE_RESET_PARAMS';
export const BALANCE_SET_REPORT_ID = 'reports/BALANCE_SET_REPORT_ID';
export const BALANCE_SET_XLS_FILE_ID = 'reports/BALANCE_SET_XLS_FILE_ID';
export const BALANCE_VIEW_PARAMS_CREATE = 'reports/BALANCE_VIEW_PARAMS_CREATE';

export const balanceResetParams = (payload: number) => ({
  type: BALANCE_SET_XLS_FILE_ID,
  payload,
});

export const balanceSetReportId = (payload: number) => ({
  type: BALANCE_SET_REPORT_ID,
  payload,
});

export const balanceSetXlsReportId = (payload: number) => ({
  type: BALANCE_SET_XLS_FILE_ID,
  payload,
});

// Фильтры, которые влияют на отбражение присланных данных
// выносятся в отдельное место, чтоб при изменении параметров фильтра,
// он не влиял на отображение текущих данных
export const balanceReportViewParamsCreate = () => (dispatch: Function, getState: Function) => {
  const state = getState();
  const params = {
    balanceFilter: state.reports.filters.commonBalance.selected,
    balanceMin: state.reports.filters.commonBalance.min,
    balanceMax: state.reports.filters.commonBalance.max,
    // group: state.reports.filters.group.current,
    sort: state.reports.filters.sort,
    current: state.reports.filters.date.current,
  };
  dispatch(action(BALANCE_VIEW_PARAMS_CREATE, params));
};
