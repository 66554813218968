import PlainItemsSelectorBox from 'app/common/ui-next/_filter-compilations/plain-items-selector-box'
import { orgTypesFilter, orgTypesFilterSelection } from 'app/dataProviders/filter-items'
import { OrgType } from 'app/dataTypes/org'


type Props = {
  id: string
  pending: boolean
}

export const OrgTypesFilter = ({ id, pending }: Props) => {
  return (
    <PlainItemsSelectorBox
      id={id}
      label="Тип заведения"
      header="Выбор типа заведения"
      dataBoxData={orgTypesFilter}
      dataBoxSelection={orgTypesFilterSelection}
      pleaseSelectText="Нет данных"
      selectedAllOnEmptySelection
      defaultSelection={[OrgType.School]}
      disabled={pending}
    />
  )
}
