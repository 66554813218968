// @flow
import { useState, useCallback } from 'react';
import { mergeLeft, reduce, omit } from 'ramda';

import { PlanogramItem } from '../_parts/planogram-data-class';


const MINIMAL_WIDTH = 1;

const createAnchor = (row: number, column: number): string => {
  return `${row}_${column}`;
};

type State = {
  [anchor: string]: PlanogramItem | 'skip',
}

export default function useReplacedItemsInProcess(): [
  (Array<PlanogramItem>) => void,
  (Array<PlanogramItem>) => void,
  (number, number) => ?PlanogramItem | 'skip',
  ] {
  const [replaced, setReplaced] = useState<State>({});

  const handleAdd = useCallback((items: Array<PlanogramItem>) => {
    setReplaced(
      mergeLeft(
        reduce((acc, val) => {
          const { row, column, width } = val;
          acc[createAnchor(row, column)] = val;
          if (width > MINIMAL_WIDTH) {
            for (let x = MINIMAL_WIDTH; x < width; x++) {
              acc[createAnchor(row, column + x)] = 'skip';
            }
          }
          return acc;
        }, {}, items),
      ),
    );
  }, []);

  const handleRemove = useCallback((items: Array<PlanogramItem>) => {
    setReplaced(
      omit(
        reduce((acc, val) => {
          const { row, column, width } = val;
          acc.push(createAnchor(row, column));
          if (width > MINIMAL_WIDTH) {
            for (let x = MINIMAL_WIDTH; x < width; x++) {
              acc.push(createAnchor(row, column + x));
            }
          }
          return acc;
        }, [], items),
      ),
    );
  }, []);

  const handleGetItem = useCallback((row: number, column: number) => {
    return replaced[createAnchor(row, column)];
  }, [replaced]);

  return [handleAdd, handleRemove, handleGetItem];
}
