import { useMemo } from 'react'
import { range } from 'ramda'

import { Tr, Td } from 'app/common/ui-components/table'

import SkeletonLoading from './skeleton-loading'


type Props = {
  rows: number
  columns: number
  height?: number
  onlyRows?: boolean
  itemWidth?: 'random' | number
  alignByColumnNum?: ReadonlyArray<'left' | 'right' | 'center'>
}

export const SkeletonLoadingTBody = ({
  rows,
  columns,
  height = 17.25,
  onlyRows,
  itemWidth = 100,
  alignByColumnNum = [],
}: Props) => {
  const items = useMemo(() => range(0, rows), [rows])
  const columnsItems = useMemo(() => range(0, columns), [columns])

  const rowItems = items.map(item => (
    <Tr key={item} alignTop interlineBorder>
      {columnsItems.map((column, index) => (
        <Td key={column}>
          <SkeletonLoading
            rows={1}
            itemHeight={height}
            itemWidth={itemWidth}
            align={alignByColumnNum[index] || 'left'}
            skipPadding
          />
        </Td>
      ))}
    </Tr>
  ))

  return (
    onlyRows ? rowItems : <tbody>{rowItems}</tbody>
  )
}
