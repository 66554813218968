// @flow
import { useCallback } from 'react';

import { api } from 'app/api';
import { POST_CANCEL } from 'app/common/api/requests/reports/cancel';


export default function useReportCancel() {
  const onProcessCancel = useCallback(async (reportId) => {
    await api.request(POST_CANCEL, {
      params: {
        report_id: reportId,
      },
    });
  }, []);

  return onProcessCancel;
}
