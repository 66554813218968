// @flow
import mapObjIndexed from 'ramda/es/mapObjIndexed';
import api from 'app/actions/api';
import get from 'app/common/lib/get';
import incommingMapper from './incommingMapper';


export const EMPLOYEEFORM_CHANGE_FIELD = 'EMPLOYEEFORM_CHANGE_FIELD';
export const dateFormat = 'YYYY-MM-DD';

export const employeeformChangeField = (payload: Object) => ({
  type: EMPLOYEEFORM_CHANGE_FIELD,
  payload,
});

export const EMPLOYEEFORM_SUBMIT_REQUEST = 'EMPLOYEEFORM_SUBMIT_REQUEST';
export const EMPLOYEEFORM_SUBMIT_SUCCESS = 'EMPLOYEEFORM_SUBMIT_SUCCESS';
export const EMPLOYEEFORM_SUBMIT_FAILURE = 'EMPLOYEEFORM_SUBMIT_FAILURE';

const employeeformSubmitRequest = payload => ({
  type: EMPLOYEEFORM_SUBMIT_REQUEST,
  payload,
});

export const employeeformSubmitSuccess = (payload: any) => ({
  type: EMPLOYEEFORM_SUBMIT_SUCCESS,
  payload,
});

export const employeeformSubmitFailure = (payload: any) => ({
  type: EMPLOYEEFORM_SUBMIT_FAILURE,
  payload,
});

export const employeeformSubmit = (fields: Object) => async (dispatch: Function) => {
  const dataToSend = {
    ...fields,
    phone: fields.phone.replace(/\s+/g, ''),
    workphone: fields.workphone.replace(/\s+/g, ''),
    homephone: fields.homephone.replace(/\s+/g, ''),
  };
  dispatch(employeeformSubmitRequest());
  try {
    const res = await api('food/employee', 'put', { data: dataToSend });
    const result = mapObjIndexed(incommingMapper, get(res, ['data', 'fields'], {}));
    dispatch(employeeformSubmitSuccess(result));
    return true;
  } catch (error) {
    dispatch(employeeformSubmitFailure({ err: 'Не удалось обновить данные сотрудника', error }));
    return false;
  }
};
