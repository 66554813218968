import { useMemo, useCallback } from 'react'


export default function useOverallCheckErrors(checkErrorsNonMemoized: Array<() => boolean>) {
  const checkErrorsMemoized = useMemo(() => (
    checkErrorsNonMemoized
  ), checkErrorsNonMemoized) // eslint-disable-line react-hooks/exhaustive-deps

  const checkErrors = useCallback(() => {
    for (const check of checkErrorsMemoized) { // eslint-disable-line no-restricted-syntax
      if (!check()) {
        setTimeout(() => { // TODO вынести в отдельную функцию
          const erElements = document.getElementsByClassName('scrollToError')
          if (erElements && erElements[0]) {
            erElements[0].scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' })
          }
        }, 500)
        return false
      }
    }
    return true
  }, [checkErrorsMemoized])

  return checkErrors
}
