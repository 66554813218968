// @flow
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import isEmpty from 'ramda/es/isEmpty';
import find from 'ramda/es/find';

import CommonPage from 'app/common/templates/CommonPage';
import OrgsList from 'app/components/OrgsList';
import Vending, { dataKey as vendingDataKey } from 'app/components/OrgsList/Vending';

import OrgsListNavigation from './OrgsListNavigation';
import getChildPageBaseUrl from './OrgsPage/getBaseUrl';
import type { NavItemProps } from './useOrgsListNavigation';
import getVendingMachineBaseUrl from '../VendingMachinePage/getBaseUrl';


type Props = {
  history: {
    push: (path: string) => void,
  }
}

const OrgsListPage = ({ history: { push } }: Props) => {
  const { orgType } = useParams();
  const history = useHistory();
  const [menuItems, setMenuItems] = useState<Array<NavItemProps>>([]);

  useEffect(() => {
    const hasMenuItems = !isEmpty(menuItems);
    // нет меню (еще или совсем)
    if (!hasMenuItems) return;
    // не выбран тип организации
    if (!orgType && hasMenuItems) {
      const { to } = menuItems[0];
      history.replace(to); // редирект на первый элемент
    }
    // текущий тип организации отсутствует в меню
    if (orgType && hasMenuItems && !find(({ to }) => to.includes(orgType), menuItems)) {
      const { to } = menuItems[0];
      history.replace(to); // редирект на первый элемент
    }
  }, [orgType, menuItems, history]);

  const gotoVendingMachine = useCallback((id: string, section?: string) => {
    push(getVendingMachineBaseUrl({ id, section }));
  }, [push]);


  return (
    <CommonPage header="Заведения">
      <OrgsListNavigation onUpdateItems={setMenuItems} />

      {orgType === vendingDataKey
        ? <Vending onRedirectToVendingMachine={gotoVendingMachine} />
        : <OrgsList
          currentOrgType={orgType}
          getChildPageBaseUrl={getChildPageBaseUrl}
        />}
    </CommonPage>
  );
};

export default OrgsListPage;
