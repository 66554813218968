// @flow
import { useState, useCallback, useMemo } from 'react';
import has from 'ramda/es/has';
import assoc from 'ramda/es/assoc';
import pick from 'ramda/es/pick';

import { api } from 'app/api';
import { GET_ORG_GENERAL_SETTINGS, POST_ORG_GENERAL_SETTINGS_MASS } from 'app/common/api/requests/food/schools/general_settings';
import { defaultGeneralSettings } from 'app/dataProviders/org/generalSettingsOrg';
import type { GeneralSettings } from 'app/dataTypes/org/settings/GeneralSettings';
import type { FoodMode } from 'app/dataTypes/foodModes';


/**
 *  ОБЩИЕ НАСТРОЙКИ
 */

export type State = {
  state: GeneralSettings,
  loading: boolean,
  modified: boolean,
  error: string,
}

export const defaultState: State = {
  state: defaultGeneralSettings,
  loading: true,
  modified: false,
  error: '',
};

export type ApiHandlers = {
  request: () => void | Promise<void>,
  submit: (overrideOrgId?: Array<number>, onlyThisFields?: Array<string>) => boolean | Promise<boolean>,
  checkErrors: () => boolean,
}

export type ModifyHandlers = {
  updateField: (name: string, value: boolean | Array<FoodMode>) => void,
}

type Options = {
  onModify?: (boolean) => void,
}

export default function useGeneralSettings(orgId: number, options?: Options): [
  State,
  ApiHandlers,
  ModifyHandlers
] {
  const { onModify } = options || {};
  const [modified, setModified] = useState(false);

  const [generalSettings, setGeneralSettings] = useState < GeneralSettings > (defaultGeneralSettings);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');


  // запрос данных
  const requestGeneralSettings = useCallback(async () => {
    setLoading(true);
    const res = await api.request(GET_ORG_GENERAL_SETTINGS, {
      error: 'Не удалось получить данные режима питания в комплексном питании',
      requestPathParams: {
        orgId,
      },
    });
    setLoading(false);

    if (res) {
      setGeneralSettings(res);
      setError('');
      setModified(false);
      if (onModify) {
        onModify(false);
      }
    }
  }, [orgId, onModify]);


  // отправка данных
  const submitGeneralSettings = useCallback(async (
    overrideOrgIds?: Array<number>,
    onlyThisFields?: Array<string>,
  ) => {
    const orgIds = overrideOrgIds || [orgId];

    const res = await api.request(POST_ORG_GENERAL_SETTINGS_MASS, {
      error: 'Не удалось сохранить общие настройки',
      params: {
        schools_ids: orgIds.map(String),
        settings: (onlyThisFields
          ? pick(onlyThisFields, generalSettings)
          : generalSettings),
      },
    });

    if (res && overrideOrgIds && !overrideOrgIds.find(id => (id === orgId))) {
      // были изменены данные другой организации, не влияет на текущую форму
      return true;
    }

    if (res) {
      setError('');
      setModified(false);
      if (onModify) {
        onModify(false);
      }
      return true;
    }
    return false;
  }, [generalSettings, orgId, setError, onModify]);


  // проверка ошибок
  const handleCheckErrors = useCallback(() => {
    if (generalSettings.use_food_mode && generalSettings.food_modes.length < 2) {
      setError('Выберите минимум 2 позиции');
      return false;
    }
    return true;
  }, [generalSettings]);


  // изменение данных
  const handleUpdateField = useCallback((name: string, value: boolean | Array<FoodMode>) => {
    if (has(name, defaultGeneralSettings)) {
      setModified(true);
      if (onModify) {
        onModify(true);
      }
      setError('');
      setGeneralSettings(assoc(name, value));
    }
  }, [onModify]);


  const state = useMemo(() => ({
    state: generalSettings,
    loading,
    modified,
    error,
  }), [generalSettings, loading, modified, error]);

  const apiHandlers = useMemo(() => ({
    request: requestGeneralSettings,
    submit: submitGeneralSettings,
    checkErrors: handleCheckErrors,
  }), [requestGeneralSettings, submitGeneralSettings, handleCheckErrors]);

  const modifyHandlers = useMemo(() => ({
    updateField: handleUpdateField,
  }), [handleUpdateField]);

  return [state, apiHandlers, modifyHandlers];
}
