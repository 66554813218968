// @flow
import type { State, StateDataItem, StateDataCurrent } from './filter-state';
import { filterItemTypes } from './filter-item-types';

/**
 *  Тип для описания фильтра
 */

export type FilterItemDescription<T = void> = {
  filterId: string, // идентификатор данного элемента фильтра
  filterType: $Values<typeof filterItemTypes>, // тип ui-элемента фильтра
  getItemsAndCurrent: (state: State, params?: T) => // возвращает данные для наполнения фильтра и текущее состояние по умолчанию
    | Promise<[Array<StateDataItem>, StateDataCurrent]>
    | [Array<StateDataItem>, StateDataCurrent],
  getItemsDefault?: (state: State, params?: T) => Array<StateDataItem>, // возвращает данные для наполнения фильтра при reset
  props?: Object, // props ui-элемента фильтра
  getProps?: (state: State, params?: T) => Object, // функция для получения props в зависимости от state и params
  multiple?: boolean, // множественный выбор
  relations?: Array<string>, // зависимые фильтры (filterIds) от состояния (current) этого фильтра
  activity?: (state: State, params?: T) => boolean, // функция отображения фильтра, по умолчанию фильтр отображается всегда
}

export type Descriptions<T = void> = Array<FilterItemDescription<T>>

type Params = mixed

export const defaultDescriptions: Descriptions<Params> = [];
