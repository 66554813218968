// @flow
import { useCallback, useState, useEffect, useMemo } from 'react';

import { api, requestsGeneral } from 'app/api';
import type { BalanceRequestParams, BalanceRequestResult } from 'app/common/api/general.types/cafe.balance';
import type { BalanceRefundPOSTRequestParams, BalanceRefundPOSTRequestResult } from 'app/common/api/general.types/cafe.balance.refund';


export type BalanceType = BalanceRequestResult | {
  available_for_refund: null,
  balance: null,
}

export default function useBalance(
  params: BalanceRequestParams,
  onRefund?: () => void,
): [[BalanceType, boolean], {
  refund: (p: BalanceRefundPOSTRequestParams) => Promise<boolean>
}] {
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState<BalanceType>({
    available_for_refund: null,
    balance: null,
  });

  const handleRequest = useCallback(async () => {
    setLoading(true);
    const balanceRes: BalanceRequestResult | null = await api.request(requestsGeneral.Cafe_GET_balance, {
      error: 'Не удалось получить баланс и доступный лимит возврата',
      params,
    });
    setLoading(false);
    if (balanceRes) {
      setBalance(balanceRes);
      return true;
    }
    return false;
  }, [params]);

  const handleRefund = useCallback(async (postParams: BalanceRefundPOSTRequestParams) => {
    const balanceRes: BalanceRefundPOSTRequestResult | null = await api.request(requestsGeneral.Cafe_POST_balance_refund, {
      error: 'Не удалось осуществить возврат средств',
      params: postParams,
    });
    if (balanceRes) {
      setBalance(balanceRes);
      if (onRefund) {
        onRefund();
      }
      return true;
    }
    return false;
  }, [onRefund]);

  useEffect(() => {
    handleRequest();
  }, [params]);

  const memoized = useMemo(() => [[balance, loading], {
    refund: handleRefund,
  }], [balance, handleRefund, loading]);


  return memoized;
}
