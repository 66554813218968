import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PlainModal, { PlainModalHeader, PlainModalContent, PlainModalFooter } from 'app/common/ui-next/plain-modal'
import PlainButton from 'app/common/ui-next/plain-button'
import ControlsBar from 'app/common/ui-next/controls-bar'
import OrgsDataProvider from 'app/dataProviders/org/OrgsDataProvider'
import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs'
import type { OrgItem } from 'app/common/api/requests/food/schools'
import * as orgsTypes from 'app/common/constants/schools/types'
import { kitsNeedUpdate } from 'app/actions/orgs/kits/options'

import CopyKitFormOtherOrgs from './CopyKitFormOtherOrgs'
import CopyKitFormOtherKit from './CopyKitFormOtherKit'
import CopyKitFormSameOrg from './CopyKitFormSameOrg'
import { CopyKitFormOptions } from './CopyKitFormRuleOptions'
import TabState from './TabState'
import useOptions from './useOptions'

import useJobs, { Options } from './useJobs'
import useJobsSubmit from './useJobsSubmit'
import useJobsDateState from './useJobsDateState'
import useJobsDateRestriction from './useJobsDateRestriction'
import useJobsKitsState from './useJobsKitsState'
import useJobsValidate from './useJobsValidate'

import styles from './CopyKit.scss'


const orgsFilterCb = (item: OrgItem): boolean => {
  const { cafeteria_enabled: cafeteriaEnabled, type, deleted, approved } = item
  const actual = !deleted && approved
  if (type === orgsTypes.ENTERPRISE) return actual && cafeteriaEnabled
  return actual
}

type Props = {
  orgID: string
  kitID: string | Array<string>
  header: string
  date: string
  fixedPriceEnabled: boolean
  onClose: () => void
}

const CopyKit = ({ orgID, kitID, header, date: initialDate, fixedPriceEnabled, onClose }: Props) => {
  const orgId = parseInt(orgID, 10)
  const multipleSource = kitID instanceof Array

  const [selectedOrgs, setSelectedOrgs] = useState<Array<string>>([])

  const emailEnabled = selectedOrgs.length > 1

  const [{
    checks,
    email,
    emailError,
  }, {
    setChecks,
    emailChange,
    checkEmailErrors,
  }] = useOptions({
    enable: emailEnabled,
  })

  const [tabIndex, setTabIndex] = useState(1)

  const { dateTo, setDateTo, setDateToMultiple } = useJobsDateState({ initialDate, multiple: tabIndex === 2 })
  const getDisabledDay = useJobsDateRestriction(initialDate)

  const [targetComplexIDs, setTargetComplexIDs] = useJobsKitsState()

  const jobsOptions: Options = {
    orgIDFrom: orgID,
    orgIDTo: tabIndex === 1 ? selectedOrgs : orgID,
    dateFrom: initialDate,
    dateTo,
    copyComplexPhoto: checks.copyComplexPhoto,
    copySettings: tabIndex === 1 ? checks.copySettings : undefined,
    copyExtendedSettings: tabIndex === 1 ? checks.copyExtendedSettings : undefined,
    changeSoldItems: tabIndex !== 3
      && fixedPriceEnabled ? checks.changeSoldItems : undefined,
    keepComplexPrice: tabIndex === 1 || tabIndex === 3 ? checks.keepComplexPrice : undefined,
    keepGrocerySetPrice: tabIndex === 1 || tabIndex === 3 ? checks.keepGrocerySetPrice : undefined,
    complexesIDs: tabIndex !== 3 ? kitID : undefined,
    sourceComplexID: tabIndex === 3 && typeof kitID === 'string' ? kitID : undefined,
    targetComplexID: tabIndex === 3 && targetComplexIDs[0] ? targetComplexIDs[0] : undefined,
  }
  const jobs = useJobs(jobsOptions)

  const { errors, validate, resetErrors } = useJobsValidate({
    tabIndex,
    jobsOptions,
    checkEmail: checkEmailErrors,
  })

  useEffect(() => {
    resetErrors()
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    selectedOrgs,
    tabIndex,
    dateTo,
    jobsOptions.targetComplexID,
    jobsOptions.orgIDTo,
  ])

  const dispatch = useDispatch()
  const handleSuccess = useCallback(() => {
    onClose()
    if (tabIndex === 3) {
      dispatch(kitsNeedUpdate(true))
    }
  }, [onClose, dispatch, tabIndex])

  const [submit, submitting] = useJobsSubmit(jobs, {
    email,
    onSuccess: handleSuccess,
    onValidate: validate,
    failMessage: multipleSource ? 'Не удалось скопировать комплекты' : 'Не удалось скопировать комплект',
    successMessage: multipleSource ? 'Комплекты были успешно скопированы' : 'Комплект был успешно скопирован',
  })

  return (
    <OrgsDataProvider orgId={orgId} filterCb={orgsFilterCb} sameType>
      <TabState orgID={orgID} onChangeTabIndex={setTabIndex}>
        {(activeTabIndex, setActiveTabIndex, hasOtherOrgs, loadingOrgs) => (
          <PlainModal
            formId="copy_kits"
            onSubmit={submit}
            className={styles.root}
            show
          >
            <PlainModalHeader
              onCrossClick={onClose}
            >
              {header}
            </PlainModalHeader>

            <PlainModalContent className={styles.tabsContent}>
              <Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
                {hasOtherOrgs
                  && <Tab index={1}>{'В другие заведения'}</Tab>}
                <Tab index={2}>{'В пределах заведения'}</Tab>
                {typeof kitID === 'string'
                  && <Tab index={3}>{'В другой комплект'}</Tab>}
              </Tabs>
            </PlainModalContent>

            <PlainModalContent
              className={styles.content}
            >
              <div className={styles.mainContent}>
                <TabPanels value={activeTabIndex} className={styles.panels} unmountHidden>
                  {hasOtherOrgs
                    && <TabPanel index={1}>
                      <CopyKitFormOtherOrgs
                        orgIdFrom={orgId}
                        initialDateTo={initialDate}
                        disabled={submitting}
                        onChangeOrgsSelected={setSelectedOrgs}
                        onChangeDateTo={setDateTo}
                        errors={errors}
                        resetErrors={resetErrors}
                      />
                    </TabPanel>}

                  <TabPanel index={2}>
                    <CopyKitFormSameOrg
                      loading={loadingOrgs}
                      disabled={submitting}
                      getDisabledDay={getDisabledDay}
                      onDatesChange={setDateToMultiple}
                      error={errors.date}
                    />
                  </TabPanel>

                  {typeof kitID === 'string'
                    && <TabPanel index={3}>
                      <CopyKitFormOtherKit
                        orgId={orgId}
                        date={initialDate}
                        currentKitID={kitID}
                        selection={targetComplexIDs}
                        onTargetKitChange={setTargetComplexIDs}
                        error={errors.targetComplex}
                        disabled={submitting}
                      />
                    </TabPanel>}

                </TabPanels>
              </div>

              <CopyKitFormOptions
                email={email}
                emailEnabled={emailEnabled}
                emailError={emailError}
                disabled={submitting}
                checks={checks}
                copySettingsEnabled={activeTabIndex === 1}
                keepGrocerySetPriceEnabled={activeTabIndex === 1 || activeTabIndex === 3}
                changeSoldItemsEnabled={activeTabIndex === 1 && fixedPriceEnabled}
                keepComplexPriceEnabled={activeTabIndex === 3 || (fixedPriceEnabled && activeTabIndex === 1)}
                keepComplexPriceLabel={
                  fixedPriceEnabled && activeTabIndex === 1
                    ? 'Не изменять стоимость комплекта (для фиксированной цены)'
                    : undefined
                }
                onChangeChecks={setChecks}
                onChangeEmail={emailChange}
              />
            </PlainModalContent>

            <PlainModalFooter>
              <ControlsBar right>
                <PlainButton
                  onClick={onClose}
                  outline
                >
                  {'Отмена'}
                </PlainButton>
                <PlainButton
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? 'Копируется...' : 'Копировать'}
                </PlainButton>
              </ControlsBar>
            </PlainModalFooter>
          </PlainModal>
        )}
      </TabState>
    </OrgsDataProvider>
  )
}

export default CopyKit
