// @flow
import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import mapItems from '../mapItems';
// import mapChildren from '../mapChildren';
import styles from './InsideMenu.scss';


export type MenuItemType = {
  id: string | number,
  title: string,
  path?: string,
  activeClassName?: string,
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
  active?: boolean,
  component?: React.Element<any>
}

type Props = {
  items?: Array<MenuItemType>,
  className?: string,
  scrollable?: boolean,
  children?: any,
};

const InsideMenu = ({ items, className, scrollable, children }: Props) => (scrollable
  ? <Scrollbars className={className}>
    <div className={styles.container}>
      {items && mapItems(items)}
      {children}
    </div>
  </Scrollbars>
  : <div className={className}>
    {items && mapItems(items)}
    {children}
  </div>
);

export default InsideMenu;
