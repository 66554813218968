import { useCallback } from 'react'

import { ChangeStatusParams, KnownStatementStatus } from 'app/dataTypes/compensation/statement/status'
import PlainButton from 'app/common/ui-next/plain-button'
import { StopPropagation } from 'app/common/ui-components/table'

import { ActionButtonWithConfirm } from './ActionButtonWithConfirm'
import { ActionButtonWithPrompt } from './ActionButtonWithPrompt'

import styles from './Actions.scss'


type Props = {
  ID: string
  disabled?: boolean
  statusID: string
  onChangeStatus: (params: ChangeStatusParams) => Promise<boolean>
}

export const Actions = ({
  ID,
  disabled,
  statusID,
  onChangeStatus,
}: Props) => {
  const handleProcessing = useCallback(async () => {
    return onChangeStatus({ statementID: ID, newStatusID: KnownStatementStatus.Processing })
  }, [ID, onChangeStatus])

  const handleApprove = useCallback(async () => {
    return onChangeStatus({ statementID: ID, newStatusID: KnownStatementStatus.Approved })
  }, [ID, onChangeStatus])

  const handleReject = useCallback((comment: string) => {
    return onChangeStatus({ statementID: ID, newStatusID: KnownStatementStatus.Rejected, comment })
  }, [ID, onChangeStatus])

  const handleCancel = useCallback((comment: string) => {
    return onChangeStatus({ statementID: ID, newStatusID: KnownStatementStatus.Canceled, comment })
  }, [ID, onChangeStatus])

  return (
    <StopPropagation className={styles.root}>
      {statusID === KnownStatementStatus.Consideration
        && <ActionButtonWithConfirm
          title="В работу"
          disabled={disabled}
          confirmText={`Взять в работу заявку с номером ${ID}?`}
          onConfirm={handleProcessing}
        />}

      {statusID === KnownStatementStatus.Processing
        && <ActionButtonWithConfirm
          title="Проведена"
          disabled={disabled}
          confirmText={`Провести заявку с номером ${ID}?`}
          onConfirm={handleApprove}
        />}

      {(statusID === KnownStatementStatus.Consideration
        || statusID === KnownStatementStatus.Processing)
        && <ActionButtonWithPrompt
          title="Отказано"
          confirmText={`Введите причину для отказа заявки с номером ${ID}?`}
          onConfirm={handleReject}
        >
          {toggle => (
            <PlainButton
              onClick={toggle}
              disabled={disabled}
              compact
            >
              {'Отказано'}
            </PlainButton>
          )}
        </ActionButtonWithPrompt>}

      {statusID === KnownStatementStatus.Approved
        && <ActionButtonWithPrompt
          title="Отменить проведение"
          confirmText={`Введите причину для отмены проведения заявки с номером ${ID}?`}
          onConfirm={handleCancel}
        >
          {toggle => (
            <PlainButton
              onClick={toggle}
              disabled={disabled}
              compact
            >
              {'Отменить проведение'}
            </PlainButton>
          )}
        </ActionButtonWithPrompt>}
    </StopPropagation>
  )
}
