// @flow
import classNames from 'classnames/bind';
import mapProps from 'recompose/mapProps';
import styles from './SectionSettings.scss';

const cx = classNames.bind(styles);
export default mapProps(({ className, general, ...props }) => ({
  ...props,
  className: cx(styles.label, { general }),
}))('span');
