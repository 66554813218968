import { ReactNode, Fragment, Children, ReactElement } from 'react'

import { Tabs, Tab, TabPanels, TabPanel } from 'app/common/ui/PlainTabs'

import styles from './TabsPaymentOrderKinds.scss'


const titles = ['Питание в школе', 'Питание в лагере', 'Питание']

type Props = {
  children: ReactNode
  activeTabIndex: number
  setActiveTabIndex: (index: number) => void
  skip: boolean
  addidionalContent?: ReactNode
}

const TabsPaymentOrderKinds = ({
  children,
  activeTabIndex,
  setActiveTabIndex,
  skip,
  addidionalContent,
}: Props) => {
  if (skip) {
    return children
  }

  return (
    <Fragment>
      <div className={styles.tabLine}>
        <Tabs value={activeTabIndex} onChange={setActiveTabIndex}>
          {Children.map(children, (child, index) => (child ? (
            <Tab key={(child as ReactElement).key} index={index + 1}>
              {titles[index > titles.length - 1 ? titles.length - 1 : index]}
            </Tab>
          ) : null))}
        </Tabs>
        <div className={styles.space} />
        {addidionalContent}
      </div>

      <TabPanels value={activeTabIndex}>
        {Children.map(children, (child, index) => (child ? (
          <TabPanel index={index + 1} key={(child as ReactElement).key}>
            {child}
          </TabPanel>
        ) : null))}
      </TabPanels>
    </Fragment>
  )
}

export default TabsPaymentOrderKinds
