// @flow
import React, { type Node } from 'react';

import PlainButtonAsLink from 'app/common/ui-next/plain-button-as-link';
import ControlsBar from 'app/common/ui-next/controls-bar';
import type { ParamsPOST } from 'app/dataTypes/vending/PlanogramCopy.types';

import CopySlotsModal from './CopySlotsModal';


type Props = {
  disabled?: boolean,
  currentMachineId: ?string,
  onSuccess?: (ParamsPOST) => void
}

const EditMachineButton = ({ disabled, onSuccess, currentMachineId }: Props): Node => {
  return (
    <ControlsBar right>
      <CopySlotsModal onSuccess={onSuccess} currentMachineId={currentMachineId}>
        {toggle => (
          <PlainButtonAsLink onClick={toggle} disabled={disabled}>
            {'Копировать планограмму'}
          </PlainButtonAsLink>
        )}
      </CopySlotsModal>
    </ControlsBar>
  );
};

export default EditMachineButton;
