// @flow
import React, { type Node, useState, useCallback, useEffect } from 'react';
import cn from 'classnames/bind';
import { not } from 'ramda';

import RowLayoutContent, { type RowLayoutContentProps } from './RowLayoutContent';
import styles from './RowLayoutFullscreenContent.scss';


const cx = cn.bind(styles);

type Props = {
  ...RowLayoutContentProps,
  children: (toggle: () => void) => Node,
}

const RowLayoutFullscreenContent = ({ children, className, ...others }: Props): Node => {
  const [fullscreen, setFullscreen] = useState(false);
  const toggle = useCallback(() => {
    setFullscreen(not);
  }, []);

  useEffect(() => {
    const eventListener = window.addEventListener('keydown', (e: KeyboardEvent) => {
      const { keyCode } = e;
      if (keyCode === 27) {
        setFullscreen(false);
      }
    });

    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  }, []);

  return (
    <RowLayoutContent className={cx({ fullscreen, className })} {...others}>
      {children(toggle)}
    </RowLayoutContent>
  );
};

export default RowLayoutFullscreenContent;
