import { useMemo } from 'react'

import { toRGBA } from 'app/common/lib/color'


type Styles = Record<string, string | number>

export const emptyStyle = {} as Styles

export const emptyStyleBorder = {
  border: '1px solid transparent',
} as Styles

export default function useFancyStyles(color?: string, colorTitle?: string) {
  const res = useMemo(() => {
    return {
      fancyStyleHeader: color ? {
        backgroundColor: `rgba(${toRGBA(color, 0.05).join(',')})`,
        border: `1px solid ${color}`,
        borderRadius: '4px 4px 0 0',
      } : emptyStyleBorder,
      facyStylePanel: color ? {
        border: `1px solid ${color}`,
        borderTop: 0,
        borderRadius: '0 0 4px 4px',
      } : emptyStyleBorder,
      fancyStyleTitle: colorTitle ? {
        color: colorTitle,
      } : emptyStyle,
    }
  }, [color, colorTitle])

  return res
}
