// @flow
import type { StateDataItem } from 'app/common/ui-components/filter';
import { productionTypes } from 'app/dataTypes/reports/productionTypes';


const productionTypeDefault = (): Array<StateDataItem> => [{
  id: productionTypes.Dish,
  title: 'Продукция',
}, {
  id: productionTypes.Complex,
  title: 'Комплекты',
}];

export default productionTypeDefault;
