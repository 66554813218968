// @flow
import { useContext } from 'react';

import VendingDataContext, {
  type VendingStatus,
  type VendingMachines,
  type VendingMachinesParams,
  type VendingMachineSingle,
  type MachineForm,
  type MachineValidate,
  type MachineSubmit,
  type MachineRequest,
  type MachineDelete,
  type RelatedOrgs,
  type AvailableOrgs,
  type Models,
  type Versions,
} from './VendingDataContext';


export const useVendingStatus = (): VendingStatus => {
  const { status } = useContext(VendingDataContext);
  return status;
};

export const useVendingMachines = (): VendingMachines => {
  const { machines } = useContext(VendingDataContext);
  return machines;
};

export const useVendingMachinesParams = (): VendingMachinesParams => {
  const { machinesParams } = useContext(VendingDataContext);
  return machinesParams;
};

export const useVendingMachineSingle = (): VendingMachineSingle => {
  const { machineSingle } = useContext(VendingDataContext);
  return machineSingle;
};

export const useMachineForm = (): MachineForm => {
  const { machineForm } = useContext(VendingDataContext);
  return machineForm;
};

export const useMachineValidate = (): MachineValidate => {
  const { machineValidate } = useContext(VendingDataContext);
  return machineValidate;
};

export const useMachineSubmit = (): MachineSubmit => {
  const { machineSubmit } = useContext(VendingDataContext);
  return machineSubmit;
};

export const useMachineRequest = (): MachineRequest => {
  const { machineRequest } = useContext(VendingDataContext);
  return machineRequest;
};

export const useMachineDelete = (): MachineDelete => {
  const { machineDelete } = useContext(VendingDataContext);
  return machineDelete;
};

export const useRelatedOrgs = (): RelatedOrgs => {
  const { relatedOrgs } = useContext(VendingDataContext);
  return relatedOrgs;
};

export const useAvailableOrgs = (): AvailableOrgs => {
  const { availableOrgs } = useContext(VendingDataContext);
  return availableOrgs;
};

export const useModels = (): Models => {
  const { models } = useContext(VendingDataContext);
  return models;
};

export const useVersions = (): Versions => {
  const { versions } = useContext(VendingDataContext);
  return versions;
};
