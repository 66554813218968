import { ChangeEvent, useCallback, useMemo } from 'react'
import { isEmpty, reduce } from 'ramda'

import { Loading } from 'app/common/templates-next/page-template'
import OveflowedText from 'app/common/components/OveflowedText'
import PlainCheckbox from 'app/common/ui-next/plain-checkbox'

import CopySettings from '../../CopySettings'
import CopySettingsContainer from '../../common/CopySettingsContainer'
import { useRequestsData } from '../RequestsData'
import useAllowSubmitOrdersFromWeb, { Data } from './useAllowSubmitOrdersFromWeb'

import styles from './AllowSubmitOrdersFromWeb.scss'


type Props = {
  disabled: boolean
}

const AllowSubmitOrdersFromWeb = ({ disabled }: Props) => {
  const {
    content: {
      oldOrdersRestrictions: {
        loading,
        school,
      },
    },
    modify: {
      oldOrdersRestrictions: {
        updateField,
      },
    },
  } = useRequestsData()

  const actualItems = useMemo(() => {
    return school.filter(({ role_title: title }) => (
      /Администратор/.test(title)
      || /Ответственный за питание/.test(title)
    ))
  }, [school])

  const requestItems = useMemo(() => {
    return reduce((acc, { id, allow_submit_orders_from_web: v, role_id }) => {
      acc[String(id)] = { allow_submit_orders_from_web: !!v, role_id, role_type: 'school' }
      return acc
    }, {} as Data, actualItems)
  }, [actualItems])

  const submit = useAllowSubmitOrdersFromWeb({ data: requestItems })

  const handleChangeCheckbox = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.currentTarget
    updateField(parseInt(value, 10), 'allow_submit_orders_from_web', checked)
  }, [updateField])

  const nothing = isEmpty(school)

  return (
    <CopySettingsContainer>
      <div>
        {'Разрешить проводить заявки, в\u00A0web-приложении «Личный кабинет сотрудника\u00A0ОУ», вместо сотрудников\u00A0ПП:'}

        {loading && nothing && <Loading />}

        {actualItems
          .map(({ id, allow_submit_orders_from_web: checked, role_title: title }) => (
            <div className={styles.row} key={id}>
              <label
                htmlFor={String(id)}
                className={styles.label}
              >
                <OveflowedText>{`${title}:`}</OveflowedText>
              </label>
              <PlainCheckbox
                id={String(id)}
                value={String(id)}
                className={styles.checkbox}
                label="без ограничений"
                checked={checked}
                disabled={disabled}
                onChange={handleChangeCheckbox}
              />
            </div>
          ))}
      </div>

      {!disabled
        && <CopySettings onSubmit={submit} />}
    </CopySettingsContainer>
  )
}

export default AllowSubmitOrdersFromWeb
