// @flow
import React, { type Node } from 'react';
import cn from 'classnames/bind';

import styles from './SummaryRow.scss';


const cx = cn.bind(styles);

type Props = {
  children: Node,
  top?: boolean,
  bottom?: boolean,
  baseline?: boolean,
}

const SummaryRow = ({ children, top, bottom, baseline }: Props): Node => {
  return (
    <div className={cx(styles.root, { bottom, top, baseline })}>
      {children}
    </div>
  );
};

export default SummaryRow;
