// @flow
import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  STUDENT_SUBSIDY_TYPES_FILTER_CLEAR,
  STUDENT_SUBSIDY_TYPES_FILTER_DELETE,
  STUDENT_SUBSIDY_TYPES_FILTER_SET,
  STUDENT_SUBSIDY_TYPES_FILTER_UPDATE,
  studentSubsidyTypes,
} from 'app/actions/reports/filters/studentSubsidyTypes';


export type StudentType = {
  [key: $Values<typeof studentSubsidyTypes>]: {
    id: $Values<typeof studentSubsidyTypes>,
    title: string,
  }
}

export type State = {
  data: StudentType,
  selected: StudentType,
  active: boolean,
}

const defaultState: State = {
  data: {
    [studentSubsidyTypes.WITH_SUBSIDIES]: {
      id: studentSubsidyTypes.WITH_SUBSIDIES,
      title: 'С начислением',
    },
    [studentSubsidyTypes.WITHOUT_SUBSIDIES]: {
      id: studentSubsidyTypes.WITHOUT_SUBSIDIES,
      title: 'Без начисления',
    },
  },
  selected: {},
  active: false,
};

const studentStatuses = (state: State = defaultState, { type, payload }: Object) => {
  switch (type) {
    case STUDENT_SUBSIDY_TYPES_FILTER_CLEAR: {
      return defaultState;
    }
    case STUDENT_SUBSIDY_TYPES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case STUDENT_SUBSIDY_TYPES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case STUDENT_SUBSIDY_TYPES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default studentStatuses;
