import { ReactNode, useCallback, useEffect } from 'react'
import { reject } from 'ramda'

import { ReportSection, ResetLink } from 'app/common/templates-next/report-template'
import ReportFilterForm from 'app/common/templates-next/report-template/report-filter-form'
import ReportFilterLayout from 'app/common/templates-next/report-template/report-filter-layout'
import { ID } from 'app/common/ui-next/_filter-compilations/predefined-ids'
import { Header2, PageTemplateContent } from 'app/common/templates-next/page-template'
import { Params } from 'app/dataTypes/compensation/statement'
import SimpleDate from 'app/common/classes/simple-date'

import { ClassesListFilter, CustomerSearchFilter, CustomerTypeFilter, OrgTypesFilter, OrgsFilter, OrgsOrUserFilter, PeriodFilter, SchoolYearFilter, StatementStatusesFilter, StudentListFilter, dateFormat } from './filterItems'
import styles from './Filters.scss'
import { filterInit, resetToDefaultAll } from './filter-init'


const geFormDataFromSelector = (formItem: ID, formData: FormData) => {
  const value = formData.getAll(formItem) as (Array<string> | undefined)
  if (value && value.length) return value
  return undefined
}

type Props = {
  onSubmit: (params: Params) => void
  children: ReactNode
  footerBar?: ReactNode
}

export const Filters = ({ onSubmit, children, footerBar }: Props) => {
  const resetAll = () => {
    resetToDefaultAll()
  }

  const perform = useCallback(async (formData: FormData) => {
    const orgIDs = geFormDataFromSelector(ID.org, formData)
    const classIDs = geFormDataFromSelector(ID.classesList, formData)
    const studentIDs = geFormDataFromSelector(ID.studentList, formData)
    const periodFrom = formData.get(`${ID.periodFrom}From`) as string
    const periodTo = formData.get(`${ID.periodFrom}To`) as string
    const customerSearch = (formData.get(ID.customerSearch)) as string
    const statusIDs = geFormDataFromSelector(ID.statementStatuses, formData)

    onSubmit(reject(val => val === undefined, {
      updateKey: [],
      orgIDs,
      classIDs,
      studentIDs: studentIDs ?? (customerSearch ? [customerSearch] : undefined),
      statusIDs,
      dateFrom: new SimpleDate(periodFrom, dateFormat),
      dateTo: new SimpleDate(periodTo, dateFormat),
    }))
  }, [onSubmit])

  const performWithAdditionalData = useCallback(async (formData: FormData) => {
    const res = await perform(formData)
    return res
  }, [perform])

  useEffect(() => {
    return filterInit()
  }, [])

  return (
    <>
      <PageTemplateContent>
        <Header2 skipMargin>
          {'Заявления от родителей'}
        </Header2>
      </PageTemplateContent>

      <ReportSection
        header="Фильтры"
        headerBar={
          <ResetLink onClick={resetAll}>
            {'Сбросить все фильтры'}
          </ResetLink>
        }
        resetStickyShift={false}
        footerBar={footerBar}
        skipPadding
      >
        <div className={styles.formContent}>
          <ReportFilterForm
            onSubmit={performWithAdditionalData}
            submitButtonTitle="Показать"
          >
            {pending => (
              <ReportFilterLayout
                column1={
                  <>
                    <OrgsOrUserFilter
                      id={ID.orgsOrUser}
                      pending={pending}
                    />
                    <OrgTypesFilter
                      id={ID.orgType}
                      pending={pending}
                    />
                    <OrgsFilter
                      id={ID.org}
                      pending={pending}
                    />
                    <CustomerTypeFilter
                      id={ID.customerType}
                      pending={pending}
                    />
                    <ClassesListFilter
                      id={ID.classesList}
                      pending={pending}
                    />
                    <StudentListFilter
                      id={ID.studentList}
                      pending={pending}
                    />
                    <CustomerSearchFilter
                      id={ID.customerSearch}
                      pending={pending}
                    />
                  </>
                }
                column2={
                  <>
                    <SchoolYearFilter
                      id={ID.schoolYear}
                      pending={pending}
                    />
                    <PeriodFilter
                      id={ID.periodFrom}
                      pending={pending}
                    />
                    <StatementStatusesFilter
                      id={ID.statementStatuses}
                      pending={pending}
                    />
                  </>
                }
              />
            )}
          </ReportFilterForm>
        </div>

        {children}
      </ReportSection>
    </>
  )
}
