import { range, reduce } from 'ramda'

import { Accrual } from 'app/dataTypes/subsidy/settings'
import { isCommonAllowance } from 'app/dataTypes/subsidy/allowances'

import { controls, selection } from './formParts/accrualFormControls'


const defaultAccrual: Accrual = {
  num: 0,
  active: false,
  complexes: [],
  sum: '',
  type: 'complexes',
  defaultComplexID: null,
}

export default function extractAccruals(formData: FormData, accrualsShiftsAmount: number): Array<Accrual> {
  const allowanceID = formData.get('allowanceID') as string
  if (!allowanceID) throw new Error('Нужен параметр allowanceID в formData (extractAccruals)')
  const common = isCommonAllowance(allowanceID)

  return reduce((acc, value) => {
    const num = value + 1
    const active = formData.get(`${controls.activeCheckbox}${num}`)
    const complexes = formData.getAll(`${controls.complexesList}${num}`) as (Array<string> | undefined)
    const complexesIDs = complexes ? complexes.map(key => ({ ID: key })) : []
    const additionalComplexes = formData.getAll(`${controls.additionalList}${num}`) as (Array<string> | undefined)
    const additionalComplexesIDs = additionalComplexes ? additionalComplexes.map(key => ({ ID: key })) : null
    const currentSelection = formData.get(`${controls.selectionRadio}${num}`)

    if (active) {
      if (common) {
        if (currentSelection === selection.chosen_complex) {
          const defaultComplexID = formData.get(`${controls.complexesListDefaults}${num}`) as string | null
          acc.push({
            ...defaultAccrual,
            num,
            active: true,
            complexes: complexesIDs,
            ...(additionalComplexesIDs ? { additional: additionalComplexesIDs } : {}),
            type: 'chosen_complex',
            defaultComplexID,
          })
          return acc
        }
        acc.push({
          ...defaultAccrual,
          num,
          active: true,
          complexes: complexesIDs,
        })
        return acc
      }

      if (currentSelection === selection.complexes) {
        acc.push({ ...defaultAccrual, num, active: true, complexes: complexesIDs })
        return acc
      }

      if (currentSelection === selection.sum) {
        const currentSum = (formData.get(`${controls.sumInput}${num}`) as string | undefined) || ''
        const sumForComplexes = formData.get(`${controls.sumForComplexesCheckbox}${num}`)
        if (currentSum) {
          if (!sumForComplexes) {
            acc.push({ ...defaultAccrual, num, active: true, sum: currentSum })
            return acc
          }
          if (sumForComplexes) {
            acc.push({ ...defaultAccrual, num, active: true, sum: currentSum, complexes: complexesIDs })
            return acc
          }
        }
      }
    }
    acc.push({ ...defaultAccrual, num })
    return acc
  }, [] as Array<Accrual>, range(0, accrualsShiftsAmount))
}
