import get from 'app/common/lib/get';
import set from 'ramda/es/set';
import lensProp from 'ramda/es/lensProp';

import {
  EMPLOYEES_SUCCESS,
  EMPLOYEES_SUCCESS_ONE,
  EMPLOYEES_CLEAR,
} from 'app/actions/employees';


const defaultState = {};

const items = (state = defaultState, action) => {
  if (action.type === EMPLOYEES_SUCCESS) {
    return ({
      ...get(action, ['payload', 'employees'], {}),
    });
  }
  if (action.type === EMPLOYEES_SUCCESS_ONE) {
    return set(lensProp(action.payload.id), action.payload, state);
  }
  if (action.type === EMPLOYEES_CLEAR) {
    return defaultState;
  }
  return state;
};

export default items;
