import CellContentPhoto from 'app/components/common/cellContents/CellContentPhoto'
import { WithRightsForOrg } from 'app/rights/RightsForOrg'
import * as rootRights from 'app/common/constants/rights/rootRights'
import * as specificRights from 'app/common/constants/rights/specificRights'

import SubsidyFeeding from './SubsidyFeeding'
import usePhotoActions from './usePhotoActions'
import usePhoto from './usePhoto'
import styles from './LeftBarContent.scss'


const complexesRights = [rootRights.COMPLEXES, specificRights.COMPLEXES] as const

type Props = {
  id: number
  orgID: string
  title: string
  date: string
  subsidyOrders: boolean
  updatePhotoKey?: any
}

const LeftBarContent = ({ id, orgID, title, date, subsidyOrders, updatePhotoKey }: Props) => {
  const params = { orgId: orgID, complexId: String(id), date }
  const [photoUrl, setPhoto] = usePhoto(params, updatePhotoKey)
  const { saveImage, deleteImage } = usePhotoActions(params, setPhoto)

  return (
    <div>
      {subsidyOrders
        && <SubsidyFeeding
          className={styles.subsidyFeeding}
          title="для льготного питания"
        />}

      <WithRightsForOrg pathTo={complexesRights} readAffect="ignore" editAffect="ignore">
        <CellContentPhoto
          photoUrl={photoUrl}
          className={styles.photo}
          deleteConfirmText={`Вы уверены? Удалить фотографию ${title}?`}
          onSaveImage={saveImage}
          onDeleteImage={deleteImage}
        />
      </WithRightsForOrg>
    </div>
  )
}

export default LeftBarContent
