// @flow
import React, { type Node, Fragment } from 'react';

import {
  Table,
  useCopyColumnsWidths,
  useHoverByClass,
} from 'app/common/ui-components/table';

import CSSSticky from 'app/common/ui-components/css-sticky';
import DetailsHeader from 'app/components/common/reports/DetailsHeader';
import { emptyObject } from 'app/common/lib/empty';

import type { Groups as GroupsType } from '../types';
import HeaderRow from './HeaderRow';
import useHeaderCellsData from './useHeaderCellsData';
import Row from './Row';
import useRowCellsData, { sameGroup } from './useRowCellsData';

import styles from './Groups.scss';


type Props = {
  groups: GroupsType,
}

const Groups = ({ groups }: Props): Node => {
  const headerCellsData = useHeaderCellsData();
  const [rowCellsData, rowCellsDataFooter] = useRowCellsData(groups);

  const [refFrom, refTo] = useCopyColumnsWidths(/* updateKey */);

  const [onMouseEnter, onMouseLeave] = useHoverByClass(sameGroup);

  return (
    <Fragment>
      <DetailsHeader reportTitle="Продажи по способу расчета" />

      <CSSSticky
        number={3}
        className={styles.stickable}
        stickyClassName={styles.sticked}
        shadowClassName={styles.shadow}
      >
        <Table className={styles.table}>
          <thead>
            <HeaderRow
              data={headerCellsData}
              reference={refTo}
            />
          </thead>
        </Table>
      </CSSSticky>

      <Table className={styles.table}>
        <thead>
          <HeaderRow
            data={headerCellsData}
            reference={refFrom}
            hidden
            dummy
          />
        </thead>
        <tbody>
          {rowCellsData.map((data, i) => (
            <Row
              key={i}
              data={data}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          ))}
        </tbody>
        <tfoot>
          {rowCellsDataFooter.map((data, i) => (
            <Row
              key={i}
              data={data}
              {...(i === 0 ? { reference: refTo } : emptyObject)}
              hilighted
            />
          ))}
        </tfoot>
      </Table>
    </Fragment>
  );
};

export default Groups;
