// @flow
import { map, values } from 'ramda';

import type { Params, FoodPlaceGuests } from 'app/dataTypes/org/settings/FoodPlaceGuests.types';

import type { Request } from '../../../index';


type UrlParams = {
  orgId: string,
}

export const convertParams = (params: Params): [null, UrlParams] => {
  return [null, params];
};

type ApiResult = {
  [id: string]: {
    id: number,
    title: string,
  }
}

export const convertResult = (res: ApiResult): FoodPlaceGuests => {
  return map(({ id }) => String(id), values(res));
};

export const GET_FOOD_PLACE_GUESTS: Request<
  Params,
  FoodPlaceGuests,
  UrlParams
> = [
  'food/school/{orgId}/food_place_guests',
  'get',
  ['data', 'food_place_guests'],
];
