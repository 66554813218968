// @flow
import React, { useMemo } from 'react';
import identity from 'ramda/es/identity';
import filter from 'ramda/es/filter';
import values from 'ramda/es/values';
import isEmpty from 'ramda/es/isEmpty';

import type { FormErrorState } from './useAddingForm';
import styles from './AddingFormErrors.scss';


type Props = {
  errors: FormErrorState,
  additional?: string,
}

const AddingFormErrors = ({ errors, additional }: Props) => {
  const errRows = useMemo(() => {
    return filter(identity, values(errors));
  }, [errors]);

  if (isEmpty(errRows) && !additional) {
    return null;
  }

  return (
    <ul className={styles.root}>
      {additional && <li className={styles.item} key={additional}>{additional}</li>}
      {errRows.map(text => (
        <div className={styles.item} key={text}>
          {text}
        </div>
      ))}
    </ul>
  );
};

export default AddingFormErrors;
