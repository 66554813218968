import evolve from 'ramda/es/evolve';
import omit from 'ramda/es/omit';
import assoc from 'ramda/es/assoc';

import {
  CLASS_TYPES_FILTER_CLEAR,
  CLASS_TYPES_FILTER_DELETE,
  CLASS_TYPES_FILTER_SET,
  CLASS_TYPES_FILTER_UPDATE,
} from 'app/actions/reports/filters/classTypes';

import * as availableClassTypes from 'app/actions/reports/filters/classTypesAvailable';


const defaultState = {
  data: {
    [availableClassTypes.CLASS_TYPE_COMMON]: {
      id: availableClassTypes.CLASS_TYPE_COMMON,
      title: 'Класс',
    },
    [availableClassTypes.CLASS_TYPE_GPDS]: {
      id: availableClassTypes.CLASS_TYPE_GPDS,
      title: 'Группа продленного дня',
    },
  },
  selected: {},
  active: false,
};

const studentStatuses = (state = defaultState, { type, payload }) => {
  switch (type) {
    case CLASS_TYPES_FILTER_CLEAR: {
      return defaultState;
    }
    case CLASS_TYPES_FILTER_DELETE: {
      return evolve({ selected: omit(payload) }, state);
    }
    case CLASS_TYPES_FILTER_SET: {
      return assoc('selected', payload, state);
    }
    case CLASS_TYPES_FILTER_UPDATE: {
      return { ...state, ...payload };
    }
    default:
  }
  return state;
};

export default studentStatuses;
