import DataBox, { dataBoxes } from './data-box'


export function createDataBox<Data, C extends DataBox<Data>>(
  id: string,
  defaultData: Data,
  DataBoxIns: new (id_: string, data: Data) => C
) {
  const db = new DataBoxIns(id, defaultData)
  dataBoxes.set(id, db)
  return db
}

export function createDataBoxDefault<Data>(id: string, defaultData: Data) {
  const db = new DataBox(id, defaultData)
  dataBoxes.set(id, db)
  return db
}

export function subscibeTo<Data>(boxID: string, cb: (data: Data) => void) {
  const targetBoxID: DataBox | void = dataBoxes.get(boxID)
  if (targetBoxID) {
    const unsubsribe = targetBoxID.subscribe(() => {
      cb(targetBoxID.data)
    })
    return unsubsribe
  }
  return () => {}
}

export function getDataBoxByID(boxID: string): DataBox {
  const box = dataBoxes.get(boxID)
  if (!box) throw (new Error(`getDataBoxByID: DataBox ${boxID} не найден!`))
  return dataBoxes.get(boxID)
}

export function resetToDefault(boxID: string | Array<string>) {
  const ids = boxID instanceof Array ? boxID : [boxID]
  ids.forEach((id) => {
    const targetBoxID: DataBox | void = dataBoxes.get(id)
    if (targetBoxID) {
      targetBoxID.resetToDefault()
    }
  })
}
