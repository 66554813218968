import { useRef, useCallback } from 'react'


let IDCounter = 0

export default function useID() {
  const ID = useRef(0)

  const getID = useCallback((stickyBottom?: boolean) => {
    if (stickyBottom) { // TODO IDs for botom sticky
      return -1
    }
    if (!ID.current) {
      ID.current = ++IDCounter
    }
    return ID.current
  }, [])

  return getID
}
