// @flow
import React from 'react';
import cn from 'classnames/bind';

import { Column } from 'app/common/layouts/FiltersDropDownLayout';
import { FilterItem } from 'app/common/ui-components/filter';
import CommonFilterWindow from 'app/components/common/filter/CommonFilterWindow';

import styles from './FilterWindow.scss';


const cx = cn.bind(styles);

type Props = {
  onFilterToggle: (b: ((a: boolean) => boolean) | boolean) => void,
  filterActive: boolean,
  loading?: boolean,
  hideArrow?: boolean,
}

const FilterWindow = ({ onFilterToggle, filterActive, loading, hideArrow }: Props) => {
  if (!filterActive) {
    return null;
  }

  return (
    <CommonFilterWindow
      arrowClassName={cx(styles.filterArrow, { hideArrow })}
      onFilterToggle={onFilterToggle}
      loading={loading}
    >
      <Column>
        <FilterItem filterId="city" />
        <FilterItem filterId="district" />
      </Column>
      <Column>
        <FilterItem filterId="model" />
      </Column>
    </CommonFilterWindow>
  );
};

export default FilterWindow;
