import nest from 'recompose/nest';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import sort from 'ramda/es/sort';
import descend from 'ramda/es/descend';
import prop from 'ramda/es/prop';

import PlainFilter, { FilterOptionsModal } from 'app/common/ui/PlainFilter';
import withModal from 'app/common/containers/withModal';

import withYears from './hocs/withYears';
import OpenModal from './OpenModal';
import styles from './FilterItems.scss';


const filterProps = {
  className: styles.filter,
  title: 'Учебный год:',
  maxRows: 3,
};

const modalProps = {
  header: 'Выбор учебного года',
  singleSelectable: true,
  nothingIsAll: true,
  sortFunc: sort(descend(prop('dateStart'))),
};

const TheYearsFilter = nest(
  withProps(filterProps)(PlainFilter),
  withProps(modalProps)(
    nest(OpenModal, FilterOptionsModal),
  ),
);

export default compose(
  withModal,
  withYears,
)(TheYearsFilter);
