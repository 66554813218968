import { OrgTypesList, orgTitlesList } from 'app/dataTypes/org/types'
import type { Request } from '../../index'


export type OrgTypesResultApi = Array<string>


export const convertResult = (apiData: OrgTypesResultApi): OrgTypesList[] => {
  const res: OrgTypesList[] = apiData.map((id) => {
    return ({
      ID: id as keyof typeof orgTitlesList,
      title: orgTitlesList[id as keyof typeof orgTitlesList],
    })
  })
  return res
}


export const GET_ORG_TYPES: Request<void, OrgTypesResultApi, void> = [
  'food/school_types',
  'get',
  ['data', 'school_types'],
]
